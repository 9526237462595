import React from 'react';
import {
  withGoogleMap,
  withScriptjs,
  Marker,
  GoogleMap,
  Polygon 
} from "react-google-maps";

export const GMap = withScriptjs(withGoogleMap(
  
    props => {
      const {        
        onMapClick,
        onMarkerClick,
        markers,
        defaultZoom,
        defaultCenter,
        iconMap
      } = props;
        const pathCoordinates = [
          { lat: 4.756704, lng: -74.031106 },
          { lat: 5.756704, lng: -75.031106 }
      ];
      const iconMarker = new window.google.maps.MarkerImage(
        iconMap,
        null, /* size is determined at runtime */
        null, /* origin is 0,0 */
        null, /* anchor is bottom center of the scaled image */
        new window.google.maps.Size(45, 45)
    );
  
      const putMarkers = () => {
        if (!markers) {
          return;
        }
  
        if (markers instanceof Array) {
          return markers.map((mrk, i) => (
            <Marker
              key={`mrk${i}`}
              position={mrk}
              // onClick={e => onMarkerClick(e)}
              icon={iconMarker}
            />
          )
          )
        }
  
        return (<Marker
          key={`mrk`}
          position={markers}
          // onClick={e => onMarkerClick(e)}
        />)
      }
  
      return (
        <GoogleMap
          defaultZoom={defaultZoom}
          defaultCenter={defaultCenter}
          // onClick={e => onMapClick(e)}
        >
          <Polygon
            path={markers}
            key={1}
            options={{
                fillColor: "#000",
                fillOpacity: 0.4,
                strokeColor: "#000",
                strokeOpacity: 1,
                strokeWeight: 1
            }}
           />
          {putMarkers()}
        </GoogleMap>
      )
    }
  ));
  
  export default GMap;