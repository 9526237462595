import React, { useState, useEffect } from 'react';
import { styles } from './../../EstilosGrid';
import Grid from '@material-ui/core/Grid';
import { PimsCreate } from './../Create';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CancelIcon from '@material-ui/icons/Cancel';
import { withStyles } from '@material-ui/core';
import { ListButton, FileInput, FileField } from 'react-admin';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
// import ProgressBar from 'react-bootstrap/Button';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Filter, List, SimpleForm, Datagrid, ReferenceInput, Toolbar, BooleanInput, SelectInput, NumberField, EditButton, ShowButton } from 'react-admin';
import { BulkDeleteWithConfirmButton, required, Loading } from 'react-admin';
import AddIcon from '@material-ui/icons/Add';
import PropTypes, { array, element } from 'prop-types';
//icons
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import IconButton from '@material-ui/core/IconButton';
import StopIcon from '@material-ui/icons/Stop';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Dropzone from "react-dropzone";
import CircularProgress from '@material-ui/core/CircularProgress';
import config from '../../config/config';
import { translate } from 'react-admin';
let urlAppjs = config.UrlServer;
let urlAppjsFront = config.UrlFront;

export const CargaMasivaCreate = translate(withStyles(styles)(({ classes, basePath, ...props }) => {
    const { translate } = props;
    const [fileNames, setFileNames] = useState([]);
    const [files, setFiles] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [openEmpty, setOpenEmpty] = React.useState(false);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [nameGroup, setNameGroup] = React.useState("");
    const [indexSelected, setIndexSelected] = React.useState(0);    
    const [idiomaProject, setIdiomaProject] = React.useState("");

    useEffect(() => {
        let proyectSelected = window.localStorage.getItem('id_project2');
        fetch(urlAppjs + proyectSelected, {
            method: 'GET',
            headers:{
                'authorization': 'Bearer '+ localStorage.getItem('token')
            }
        })
        .then(res => res.json())
        .then((successProject) => {
            let idiomaProject = "es";
            if (successProject.idioma) {
                idiomaProject = successProject.idioma;
            }  
            fetch(urlAppjs + "/idiomas?region=" + idiomaProject, {
                method: 'GET',
                headers:{
                    'authorization': 'Bearer '+ localStorage.getItem('token')
                }
            })
            .then(res => res.json())
            .then((success) => {
                if (success['hydra:member'].length > 0) {
                    setIdiomaProject(success['hydra:member'][0].nombreIdioma);
                }else{
                    setIdiomaProject("Español");
                }
            });
        });
    }, []);

    const [state, setState] = React.useState({
        UploadPercentage: 0,
        selectedFile: null
    });
    const handleClickOpen = (indexTo) => {
        setIndexSelected(indexTo);
        setModalOpen(true);
        setOpen(true);
    };
    const handleClose = value => {
        setOpen(false);
    };
    const handleCloseEmpty = value => {
        setOpenEmpty(false);
    };
    const handleDrop = acceptedFiles =>{
        fileNames.map((file, index) => {
            let elemento = document.getElementById(index)
            elemento.style.background = '#a10a0a';
        });
        let arrayFiles = acceptedFiles.map(file => {
            return {rutaArchivo: file.name}
        });
        setFileNames(arrayFiles);
        setFiles(acceptedFiles);
        let disableDrop = document.getElementById("dropzoneId")
        let disableReload = document.getElementById("cargueNuevo")
        if(disableDrop){
            disableDrop.style.display = "none";
            disableReload.style.display = "block";
        }
    };
    const saveNameGroup = (value) => {
        setNameGroup(value.target.value)
    };

    const saveForm = () => {
        if (nameGroup != "") {
            let validateFilesFilter = 0;
            fileNames.map(object => {
                if(object.hasOwnProperty('sistema') == false){
                    validateFilesFilter++;
                }
            })
            if (validateFilesFilter == 0) {
                document.getElementById("playFiles").style.display = 'none';
                document.getElementById("circleProgressFiles").style.display = '';
                var formData = new FormData();
                files.map((file, index) => {
                    formData.append(`files[${index}]`, file);
                });
                formData.append(`nombreGrupo`, nameGroup);

                fetch(urlAppjs + '/cargaUpload', {
                    method: 'POST',
                    body: formData,
                    headers:{
                        'authorization': 'Bearer '+ localStorage.getItem('token')
                    }
                })
                .then(res => res.json())
                .then((success) => {
                    if(success == "Success"){
                        let arraySend = {
                            nombreGrupo: nameGroup,
                            estado: 1,
                            cargaMasivaArchivos:fileNames
                        }
                        let data = JSON.stringify(arraySend);
                        fetch(urlAppjs + '/carga_masivas', {
                            method: 'POST',
                            body: data,
                            headers:{
                                'authorization': 'Bearer '+ localStorage.getItem('token'),
                                'Content-Type': 'application/json'
                            }
                        })
                        .then(response => response.json())
                        .then(success => {
                            if(success.hasOwnProperty('@id')){
                                window.location.href = urlAppjsFront + basePath.replace('/', '');
                            }
                        });
                    }
                });
            }else{
                setOpenEmpty(true);
            }
        }else{
            // alert("No se encuentra información en el campo nombre grupo");
            setOpenEmpty(true);
        }
    };

    const reloadPage = () => {
        window.location.reload();
    }

    // const deleteFile = (indexFiles) => {
    //     let arrayFiles = fileNames;
    //     arrayFiles.splice(indexFiles, 1);
    //     setFileNames(arrayFiles);
    // }

    return (
        <PimsCreate title='dynatest.resources.carga_masivas.title.createTitle' actions={false} basePath='/carga_masivas' {...props} >
            <div>
                <Card variant="outlined">
                    <CardContent>
                        <Grid item xs={12} align={'right'}>
                            <ListButton basePath={basePath} />
                        </Grid>
                    </CardContent>
                    <CardContent>
                        <Grid item xs={12} >
                            <Grid >
                                <TextField id="input-with-icon-textfield" label={translate("resources.carga_masivas.fields.nombreGrupo")} onChange={saveNameGroup} fullWidth required/>
                                <br></br>
                                <br></br>
                                <Dropzone onDrop={handleDrop} accept='application/zip,application/x-zip,application/x-zip-compressed,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'>
                                    {({ acceptedFiles, getRootProps, getInputProps }) => (
                                    <div {...getRootProps({ className: "dropzone" })} id="dropzoneId">
                                        <input {...getInputProps()}/>
                                        <p>{translate("resources.carga_masivas.fields.arrastrarSoltar")}</p>
                                    </div>
                                    )}
                                </Dropzone>
                                <div id="cargueNuevo" style={{display:'none'}}>
                                    <Button 
                                        style={{ color: '#3f51b5', border:'1px solid #3f51b5' }} 
                                        onClick={reloadPage}>
                                        {translate('dynatest.resources.carga_masivas.button.cargarArcdeNuevo')}
                                    </Button>
                                    <br></br>
                                    <br></br>
                                </div>
                                <Grid >
                                    {fileNames.map((fileName, index) => (
                                            <div>
                                                <Grid container>
                                                    <Grid item xs={5}>
                                                        <Grid container xs={12} id={index} style={{padding:'2%', borderRadius:'15px', color:'white', background:'#a10a0a'}}>
                                                            <Grid container>
                                                                <Grid item xs={10} className={'padding-left-1px'} style={{whiteSpace:'nowrap', overflow: 'hidden', textOverflow:'ellipsis', paddingLeft: '2%'}}>
                                                                    {fileName.rutaArchivo}
                                                                </Grid>
                                                                <Grid item xs={1}>
                                                                    {/* <EditIcon onClick={() => { alert("Test"); }}> </EditIcon> */}
                                                                </Grid>
                                                                <Grid item xs={1} style={{cursor:'pointer', marginLeft:'-5%'}}>
                                                                    <EditIcon onClick={() => { handleClickOpen(index) }} id={"EditIcon_" + index}> </EditIcon>
                                                                </Grid>
                                                                {/* <Grid item xs={1} style={{cursor:'pointer'}}>
                                                                    <DeleteIcon onClick={() => { deleteFile(index) }}> </DeleteIcon>
                                                                </Grid> */}
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <hr></hr>
                                            </div>
                                        ))
                                    }
                                    {modalOpen &&
                                        <SimpleDialog open={open} onClose={handleClose} indexFile={indexSelected} validateOneFile={fileNames} nameGroup={nameGroup} translate={translate} idiomaProject={idiomaProject}
                                        returnValueCallback={(value) => {
                                            let arrayFile = fileNames[indexSelected];
                                            value = {...arrayFile, ...value};
                                            fileNames[indexSelected] = value;
                                            let elemento = document.getElementById(indexSelected)
                                            elemento.style.background = '#95CCAC';
                                        }}/>
                                    }
                                    <SimpleDialogFilterEmpty open={openEmpty} onClose={handleCloseEmpty} indexFile={indexSelected} validateOneFile={fileNames} nameGroup={nameGroup} translate={translate}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                    {fileNames.length > 0 && 
                        <CardContent style={{background:'aliceblue'}}>
                            <Grid container >
                                <Grid xs={12} style={{textAlign:'center'}}>
                                    <PlayArrowIcon id='playFiles' onClick={() => { saveForm(); }} style={{cursor:'pointer', fontSize: '36px'}} />
                                    <CircularProgress id='circleProgressFiles' size={24} style={{display: 'none'}} />
                                </Grid>
                            </Grid>
                        </CardContent>
                    }
                </Card>
            </div>
        </PimsCreate>
    )
}));

function SimpleDialog(props) {
    const { translate } = props;
    const { onClose, selectedValue, open, returnValueCallback } = props;
    const [proyectoSave, setProyectoSave] = useState('');
    const [rutaSave, setRutaSave] = useState('');
    const [unidadSave, setUnidadSave] = useState('');
    const [tramoSave, setTramoSave] = useState('');
    const [sistemaSave, setSistemaSave] = useState('');
    const [subSistemaSave, setSubSistemaSave] = useState('');
    const [imagenesSave, setImagenesSave] = useState('');
    const [validateFiles, setValidateFiles] = useState(props.validateOneFile);    

    const handleClose = () => {
        onClose(selectedValue);
    };

    const saveProyecto = (value) => {
        setProyectoSave(window.localStorage.getItem('id_project2'))
    }

    const saveRuta = (value) => {
        setRutaSave(value)
    }

    const saveUnidad = (value) => {
        setUnidadSave(value)
    }

    const saveTramo = (value) => {
        setTramoSave(value)
    }

    const saveSistema = (value) => {
        setSistemaSave(value)
    }

    const saveSubSistema = (value) => {
        setSubSistemaSave(value)
    }

    const saveImagenes = (value) => {
        if(value != undefined){
            setImagenesSave(value)
        }
    }

    const handleListItemClick2 = () => {
        if (proyectoSave != "" && rutaSave != "" && unidadSave != "" && tramoSave != "" && sistemaSave != "") {
            if (sistemaSave == '/sistemas/PTS' && subSistemaSave == "") {
                return alert("Formulario incompleto");
            }else{
                let imagenesArray = (imagenesSave == "") ? false : true;
                let validateOneFile = 0;
                validateFiles.map(object => {
                    if(object.hasOwnProperty('sistema')){
                        if(object.sistema == sistemaSave){
                            if (object.imagenes == imagenesArray) {
                                if (sistemaSave == '/sistemas/PTS') {
                                    if (object.subSistema == subSistemaSave) {
                                        if (validateFiles[props.indexFile]['rutaArchivo'] != object.rutaArchivo) {
                                            validateOneFile++;
                                        }
                                    }
                                }else{
                                    if (validateFiles[props.indexFile]['rutaArchivo'] != object.rutaArchivo) {
                                        validateOneFile++;
                                    }
                                }
                            }
                        }
                    }
                })
                if (validateOneFile == 0) {
                    let array = {proyecto: proyectoSave, ruta: rutaSave, unidadFuncional: unidadSave, tramo: tramoSave, sistema: sistemaSave, subSistema: subSistemaSave, imagenes: imagenesArray, estado: 1 }
                    returnValueCallback(array);
                    onClose(false);
                }else{
                    alert("Ya se encuentra otro archivo con los mismos datos de sistema");
                }
            }
        }else{
            alert("Formulario incompleto");
        }
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} fullWidth={'fullWidth'} maxWidth={'md'} keepMounted={true}>
            <DialogTitle id="simple-dialog-title"><strong>{props.nameGroup}</strong><br></br> <div style={{fontSize:'smaller'}}>{validateFiles[props.indexFile]['rutaArchivo']}</div>
            </DialogTitle>
            <SimpleForm toolbar={false}>
                <Grid container spacing={24}>
                    <Grid item xs={4}>
                        <ReferenceInput disabled source="proyecto" label="resources.carga_masivas.fields.proyecto" reference="proyectos" validate={[required(), saveProyecto]} defaultValue={window.localStorage.getItem('id_project2')}>
                            <SelectInput optionText="codigo" fullWidth />
                        </ReferenceInput>
                    </Grid>
                    <Grid item xs={4}>
                        <ReferenceInput source="ruta" label="resources.carga_masivas.fields.ruta" reference="rutas" validate={[required(), saveRuta]} defaultValue={validateFiles[props.indexFile]['ruta']} filter={{ proyecto: proyectoSave }} sort={{ field: 'codigo', order: 'ASC' }}>
                            <SelectInput optionText="codigo" fullWidth />
                        </ReferenceInput>
                    </Grid>
                    {rutaSave &&
                        <Grid item xs={4}>
                            <ReferenceInput source="unidadSave" label="resources.carga_masivas.fields.unidadSave" reference="unidad_funcionals" validate={[required(), saveUnidad]} defaultValue={validateFiles[props.indexFile]['unidadFuncional']} filter={{ ruta: rutaSave }} sort={{ field: 'codigo', order: 'ASC' }}>
                                <SelectInput optionText="codigo" fullWidth />
                            </ReferenceInput>
                        </Grid>
                    }
                    {unidadSave &&
                        <Grid item xs={4}>
                            <ReferenceInput source="tramo" label="resources.carga_masivas.fields.tramo" reference="tramos" validate={[required(), saveTramo]} defaultValue={validateFiles[props.indexFile]['tramo']} filter={{ unidadFuncional: unidadSave }} sort={{ field: 'codigo', order: 'ASC' }}>
                                <SelectInput optionText="codigo" fullWidth />
                            </ReferenceInput>
                        </Grid>
                    }
                    {tramoSave &&
                        <Grid item xs={4}>
                            <ReferenceInput source="sistema" label="resources.carga_masivas.fields.sistema" reference="sistemas" validate={[required(), saveSistema]} defaultValue={validateFiles[props.indexFile]['sistema']} sort={{ field: 'nombre', order: 'ASC' }} filter={{ visibleOnPolicies: 1 }}>
                                <SelectInput optionText="nombre" fullWidth />
                            </ReferenceInput>
                        </Grid>
                    }
                    {sistemaSave == '/sistemas/PTS' &&
                        <Grid item xs={4}>
                            <SelectInput label="resources.carga_masivas.fields.subSistema" source="subSistema" validate={[required(), saveSubSistema]} defaultValue={validateFiles[props.indexFile]['subSistema']} fullWidth
                                choices={[
                                    { id: 'CONT', name: 'dynatest.resources.pimsMenu.subMenu.Contencion' },
                                    { id: 'PTON', name: 'dynatest.resources.pimsMenu.subMenu.Ponton' },
                                    { id: 'PNTE', name: 'dynatest.resources.pimsMenu.subMenu.Puentes' },
                                    { id: 'TNEL', name: 'dynatest.resources.pimsMenu.subMenu.Tuneles' }
                                ]}
                            />
                        </Grid>
                    }
                    {sistemaSave &&
                        <Grid item xs={4}>
                            <BooleanInput label="dynatest.resources.carga_masivas.boolean.imagenes" source="imagenes" validate={[saveImagenes]} defaultValue={validateFiles[props.indexFile]['imagenes']} fullWidth />
                        </Grid>
                    }
                </Grid>
                <Grid style={{width:'auto'}}>
                    <strong>{translate('dynatest.resources.carga_masivas.message.dialogMsgIdioma')} "{props.idiomaProject}"</strong>
                </Grid>
            </SimpleForm>
            <Card variant="outlined">
                <CardContent>
                    <Button variant="secondary" id="guardarFiltro" onClick={handleListItemClick2} style={{ backgroundColor: '#3f51b5', color: '#fff' }}>
                        {translate('dynatest.resources.carga_masivas.button.guardar')}
                    </Button>
                    &nbsp;
                    &nbsp;
                    <Button id="guardarFiltro" style={{ backgroundColor: '#e36c6c', color: '#fff' }} onClick={handleClose}>
                        {translate('dynatest.resources.carga_masivas.button.cancelar')}
                    </Button>
                </CardContent>
            </Card>
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
    returnValueCallback: PropTypes.func.isRequired,
};

function SimpleDialogFilterEmpty(props) {
    const { translate } = props;
    const { onClose, selectedValue, open, returnValueCallback } = props;

    const handleClose = () => {
        onClose(selectedValue);
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} fullWidth={'fullWidth'} maxWidth={'sm'} keepMounted={true}>
            {/* <DialogTitle id="simple-dialog-title" align="center"><strong>{props.nameGroup}</strong><br></br> <div style={{fontSize:'smaller'}}>{props.dataSource.rutaArchivo}</div>
            </DialogTitle> */}
            <SimpleForm toolbar={false}>
                <Grid container spacing={24}>
                    <Grid item xs={12} align="center">
                        <ErrorOutlineIcon style={{fontSize:'xxx-large', color:'#ebce00'}}/>
                        <br></br>
                        <br></br>
                        {translate('dynatest.resources.carga_masivas.message.dialogFilterEmpty')}
                    </Grid>
                </Grid>
            </SimpleForm>
            <Card variant="outlined">
                <CardContent align="center">
                    <Button id="guardarFiltro" style={{ backgroundColor: '#0F78BC', color: '#fff' }} onClick={handleClose}>
                        {translate('dynatest.resources.carga_masivas.button.confirmar')}
                    </Button>
                </CardContent>
            </Card>
        </Dialog>
    );
}

SimpleDialogFilterEmpty.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
    returnValueCallback: PropTypes.func.isRequired,
};