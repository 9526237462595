import React, {useState} from 'react';
import { Filter, List, Datagrid, TextField, NumberField, EditButton, ShowButton, CloneButton, SelectInput, ReferenceInput, ReferenceField, AutocompleteInput
} from 'react-admin';
import Button from '@material-ui/core/Button';
import {  BulkDeleteWithConfirmButton, Loading  } from 'react-admin';
import { CardActions, CreateButton, ExportButton } from 'react-admin';
import { Fragment } from 'react';
import { translate } from 'react-admin';
import config from '../../config/config';
let urlAppjs = config.UrlServer;

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props} />
    </Fragment>
);
const ListAction = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
        <ExportButton basePath={basePath}/>
    </CardActions>
);
const ListAction2 = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
    </CardActions>
);

const FiltroIndicadorComplementario = translate((props) => {
    const { translate } = props;
    const [uf, setUf] = useState('');
    const ufSet = (value) => {
        setUf(value);
        //console.log(value);
    }
    
    const [tramo, setTramo] = useState('none');
    const tramoSet = (value) => {
        setTramo(value);
    }

    return (
        <Filter {...props}>
            <ReferenceInput
                id="sistema"
                label="resources.indicador_complementarios.fields.sistema"
                source="sistema"
                reference="sistemas"
                filter={{ id: ["ALR", "CLZ", "DRE", "OTR", "PTS", "RDS", "SEG", "SEN"]}}
                filterToQuery={searchText => ({ nombre: searchText })}
                sort={{ field: 'nombre', order: 'asc' }}
                validate={[ufSet]}
                alwaysOn
                fullWidth
            >
                <AutocompleteInput options={{ fullWidth: true }} optionText="nombre" />
            </ReferenceInput>


            {  (uf == '/sistemas/OTR') ?
                <ReferenceInput
                    id="tipoElemento"
                    label="resources.indicador_complementarios.fields.tipoElemento"
                    source="tipoElemento"
                    reference="tipo_elementos"
                    filter={{puntos: 1, sistema: "OTR"}}
                    filterToQuery={searchText => ({ nombre: searchText })}
                    sort={{ field: 'nombre', order: 'asc' }}
                    alwaysOn
                    fullWidth
                >
                    <AutocompleteInput options={{ fullWidth: true }} optionText="nombre" />
                </ReferenceInput>

                :

                (uf == '/sistemas/SEN') ?
                <ReferenceInput
                    id="tipoElemento"
                    label="resources.indicador_complementarios.fields.tipoElementoSenal"
                    source="tipoElemento"
                    reference="tipo_elemento_senals"
                    filterToQuery={searchText => ({ nombre: searchText })}
                    sort={{ field: 'nombre', order: 'asc' }}
                    alwaysOn
                    fullWidth
                    >
                    <AutocompleteInput options={{ fullWidth: true }} optionText="nombre" />
                </ReferenceInput> 

                :

                <ReferenceInput
                    id="tipoElemento"
                    label="resources.indicador_complementarios.fields.tipoElemento"
                    source="tipoElemento"
                    reference="tipo_elementos"
                    filter={{ sistema: uf }}
                    filterToQuery={searchText => ({ nombre: searchText })}
                    sort={{ field: 'nombre', order: 'asc' }}
                    alwaysOn
                    fullWidth
                >
                    <AutocompleteInput options={{ fullWidth: true }} optionText="nombre" />
                </ReferenceInput>
                }

            <SelectInput label="resources.indicador_complementarios.fields.estado" source="estado" choices={[
                            { id: translate('dynatest.resources.indicador_complementarios.select.estado.Activo'), name: 'dynatest.resources.indicador_complementarios.select.estado.Activo' },
                            { id: translate('dynatest.resources.indicador_complementarios.select.estado.Inactivo'), name: 'dynatest.resources.indicador_complementarios.select.estado.Inactivo' },
                        ]}  fullWidth alwaysOn
            />

        </Filter>
    )
});

export const IndicadorComplementarioList = ({ permissions, ...props }) => {

    if (!permissions) return <Loading />;  
    return (  
        <List title="dynatest.resources.indicador_complementarios.title.listTitle" 
            {...props}  
            sort={{ field: 'id', order: 'DESC' }} 
            actions={permissions['indicadorComplementario']  == 1 ? <ListAction /> : permissions['indicadorComplementario']  == 2 ? <ListAction2 />: null} 
            filters={<FiltroIndicadorComplementario/>} 
            bulkActionButtons={permissions['indicadorComplementario']  == 1 ? <PostBulkActionButtons />: null}>
            <Datagrid>
                <TextField label="resources.indicador_complementarios.fields.nombre" source="nombre" linkType={false} fullWidth sortable />
                <ReferenceField label="resources.indicador_complementarios.fields.sistema" source="sistema" reference="sistemas" linkType={false} >
                    <TextField source="nombre" />
                </ReferenceField>
              
                <TextField
                label="resources.indicador_complementarios.fields.tipoElemento"
                source="genericTipoElemento"
                linkType={false}
                fullWidth
                />
                <TextField label="resources.indicador_complementarios.fields.estado" source="estado" linkType={false} fullWidth sortable />
                <NumberField label="resources.indicador_complementarios.fields.umbralFallo" source="umbralFallo" linkType={false} fullWidth sortable />
                {permissions['indicadorComplementario'] == 1 ? <CloneButton />: null}
                {permissions['indicadorComplementario'] == 1 || permissions['indicadorComplementario'] == 2 || permissions['indicadorComplementario'] == 4 ? <ShowButton/>: null}
                {permissions['indicadorComplementario'] == 1 || permissions['indicadorComplementario'] == 2 ? <EditButton/>: null}
            </Datagrid>
        </List>
)};