import React, {useState} from 'react';
import { withStyles} from '@material-ui/core/styles';
import {BulkDeleteWithConfirmButton, List, Datagrid, TextField, EditButton, ShowButton, ReferenceInput, 
    Filter,  FormDataConsumer,SelectInput, AutocompleteInput, Loading
} from 'react-admin';
import { Fragment } from 'react';
import { PRField } from './../../../fields/PRField';
import { PField } from './../../../fields/PField';
import { translate } from 'react-admin';
import { CardActions, CreateButton, ExportButton } from 'react-admin';
const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);
const ListAction = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
        <ExportButton basePath={basePath}/>
    </CardActions>
);
const ListAction2 = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
    </CardActions>
);
const styles = {
    ocultar: {
        display:"none",
    },
};
const FiltroSenalizacion = translate(withStyles(styles)(({classes, ...props})=>{
    const { translate } = props;
    const [uf, setUf] = useState('none');
    const ufSet = (value) => {
        setUf(value);
    }
    const [tramo, setTramo] = useState('none');
    const tramoSet = (value) => {
        setTramo(value);
    }
    const [elemento, setElemento] = useState('');
    const ValidarElemento = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setElemento(valor)
    }
    return(
        <Filter {...props}>
            <ReferenceInput
                id="unidadFuncional"
                label="resources.elemento_senalizacions.fields.unidadFuncional"
                source="unidadFuncional"
                reference="unidad_funcionals"
                filterToQuery={searchText => ({ codigo: searchText })}
                sort={{ field: 'codigo', order: 'asc' }}
                validate={[ufSet]}
                alwaysOn
                fullWidth
            >
                <AutocompleteInput options={{ fullWidth: true }} optionText="fullCodigos" />
            </ReferenceInput>
            <ReferenceInput
                label="resources.elemento_senalizacions.fields.tramo"
                source="tramo"
                alwaysOn
                reference="tramos"
                filter={{ unidadFuncional: uf }}
                validate={[tramoSet]}
                filterToQuery={searchText => ({ codigo: searchText })}
                sort={{
                    field: 'codigo',
                    order: 'ASC',

                }}>
                <AutocompleteInput options={{ fullWidth: true }} optionText="fullCodigos" />
            </ReferenceInput>

            <ReferenceInput
                label="resources.elemento_senalizacions.fields.segmento"
                source="segmento"
                reference="segmentos"
                alwaysOn
                sort={{ field: 'codigo', order: 'ASC' }}
                filter={{ tramo: tramo }}
                filterToQuery={searchText => ({ codigo: searchText })}
            >
                <AutocompleteInput options={{ fullWidth: true }} optionText="fullCodigos" />
            </ReferenceInput>
            <ReferenceInput 
                label="resources.elemento_senalizacions.fields.tipoElementoSenal" 
                source="tipoElementoSenal" 
                reference="tipo_elemento_senals" 
                alwaysOn
                sort={{ field: 'nombre', order: 'ASC' }}
                onChange={ValidarElemento}
                filterToQuery={searchText => ({ nombre: searchText })} 
            >
                <AutocompleteInput  options={{fullWidth : true}} optionText="nombre"  />
            </ReferenceInput>
           
            <FormDataConsumer className={classes.ocultar} alwaysOn>
            {
                ({ formData, ...rest }) => (
                    <ReferenceInput 
                        label="resources.elemento_senalizacions.fields.tipoSenal" 
                        source="tipoSenal"  
                        reference="tipo_senals" 
                        filter={{tipoElementoSenal: formData.tipoElementoSenal}} 
                        sort={{ field: 'nombre', order: 'ASC' }}
                        {...rest}
                    >
                        <SelectInput  optionText="nombre" fullWidth />
                    </ReferenceInput>
                    )
            }
            </FormDataConsumer>   
            <ReferenceInput 
                // {...rest }
                label="resources.elemento_senalizacions.fields.tipoSenal" 
                source="tipoSenal"  
                reference="tipo_senals" 
                filter={{ tipoElementoSenal: elemento }}
                sort={{ field: 'nombre', order: 'ASC' }}
                filterToQuery={searchText => ({ nombre: searchText })}
                alwaysOn
            >
                <AutocompleteInput  options={{fullWidth : true}} optionText="nombre"/>
            </ReferenceInput>   
            
            <SelectInput label="resources.elemento_senalizacions.fields.estados" source="estados" choices={[
                            { id: translate('dynatest.resources.elemento_senalizacions.select.estados.Activo'), name: "dynatest.resources.elemento_senalizacions.select.estados.Activo" },
                            { id: translate('dynatest.resources.elemento_senalizacions.select.estados.Inactivo'), name: "dynatest.resources.elemento_senalizacions.select.estados.Inactivo" },
                        ]}  fullWidth alwaysOn
            />
            
        </Filter>
)}));

export const ElementoSenalizacionList = ({ permissions, ...props }) => {
    if (!permissions) return <Loading />;  
    return (
        <List {...props} 
            sort={{ field: 'updatedAt', order: 'DESC' }} 
            filters={<FiltroSenalizacion/>} 
            actions={permissions['senalizacion']  == 1 ? <ListAction /> : permissions['senalizacion']  == 2 ? <ListAction2 />: null}
            bulkActionButtons={permissions['senalizacion']  == 1 ? <PostBulkActionButtons />: null} 
            filter={{tipoElemento: {sistema: "DRE"}, proyecto: {id:1}}}>
            <Datagrid>
                <TextField label="resources.elemento_senalizacions.fields.smartCode" source="smartCode" sortable/>
                <TextField label="resources.elemento_senalizacions.fields.tipoElementoSenal" source="tipoElementoSenal_.nombre" sortable={false}/>
                {/* <ReferenceField label="senal" source="senal" reference="senals">
                    <TextField source="nombre" />
                </ReferenceField> */}
                <TextField label="resources.elemento_senalizacions.fields.tipoSenal" source="tipoSenal_.nombre" sortable={false}/>
                <TextField label="resources.elemento_senalizacions.fields.modelo" source="senal_.nombre" sortable={false}/>
                <TextField label="resources.elemento_senalizacions.fields.material" source="material" />
                <PRField label="resources.elemento_senalizacions.fields.abInicioShow" source="abInicio" />
                <PField label="resources.elemento_senalizacions.fields.pInicioShow" source="pInicio" />
                <TextField label="resources.elemento_senalizacions.fields.margen" source="margen" />
                <TextField label="resources.elemento_senalizacions.fields.estados" source="estados" />
                {permissions['senalizacion'] == 1 || permissions['senalizacion'] == 2 || permissions['senalizacion'] == 4 ? <ShowButton/>: null}
                {permissions['senalizacion'] == 1 || permissions['senalizacion'] == 2 ? <EditButton/>: null}                        
            </Datagrid>
        </List>
)};
