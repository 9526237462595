
import PropTypes from 'prop-types';
import aFormatter from './../format/AFormat';

/**
 * 
 * , isNumericString:true
 */

export const AField = ({ source, record = {}, props }) => aFormatter({value: record[source], displayType:'text', ...props});

AField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

AField.defaultProps = {
    addLabel: true,
};
