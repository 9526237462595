import React, {useState} from 'react';
import {  TabbedShowLayout, TabbedForm ,Toolbar, Tab, Datagrid, ShowController, ShowView,TextField, DateField,ReferenceField, BooleanField, FormTab, ArrayField,ChipField,SingleFieldList,NumberField,ListButton,EditButton, SimpleForm} from 'react-admin';
import { Show } from './../../../Show';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Grupo1Show from './../tabs/show/grupo1';
import Grupo2Show from './../tabs/show/grupo2';
import Grupo3Show from './../tabs/show/grupo3';
import RetroreflectividadShow from './../tabs/show/retroreflectividad';
import DecoloracionShow from './../tabs/show/decoloracion';
import loader from './../../../../loader10.gif';


const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <span>.</span>
        
    </Toolbar>
));

const styles = {
    grid_cont12: { width: '100%', marginBottom: '20px' },
    grid_cont6_f: { display: 'inline-block', width: '48%' },
    grid_cont6_s: { display: 'inline-block', width: '48%', marginLeft: '20px'},
    
    grid_cont4_f: { display: 'inline-block', width: '31%' },
    grid_cont4_s: { display: 'inline-block', width: '31%', marginLeft: '20px' },

    grid_cont3_f: { display: 'inline-block', width: '22%' },
    grid_cont3_s: { display: 'inline-block', width: '22%', marginLeft: '20px'},

    grid_cont2_f: { display: 'inline-block', width: '15%'},
    grid_cont2_s: { display: 'inline-block', width: '15%', marginLeft: '20px'},

    medicion_global: { display: 'inline-block', width: '31%', marginLeft: '20px' },
    tipo: {width: '90%', fontWeight: 'bold'},
    ocultar: {display: 'none'}
};

export const EvaluacionSVShow = withStyles(styles)(({ classes, ...props }) => {

    return(
        <Show title="Mostrar Evaluación"  {...props}>
        <SimpleForm submitOnEnter={false} toolbar={<CustomToolbar/>}>            
            <TextField label="Unidad Funcional" source="unidadcodigo" formClassName={classes.grid_cont2_f} fullWidth/>
            <TextField label="Tramo" source="tramocodigo" formClassName={classes.grid_cont2_f} fullWidth/>
            <TextField label="Segmento" source="segmentocodigo" formClassName={classes.grid_cont2_f} fullWidth/>
            {/* <ReferenceField label="Unidad Funcional" source="unidadFuncional" reference="unidad_funcionals" formClassName={classes.grid_cont2_f} fullWidth linkType={false}>
                <TextField  source="codigo" fullWidth />
            </ReferenceField>    
            <ReferenceField label="Tramo" source="tramo" reference="tramos"  formClassName={classes.grid_cont2_s}  fullWidth linkType={false}>
                <TextField  source="codigo" fullWidth />
            </ReferenceField>                   
            <ReferenceField label="Segmento" source="segmento" reference="segmentos"  formClassName={classes.grid_cont2_s}  fullWidth linkType={false}>
                <TextField  source="codigo" fullWidth />
            </ReferenceField> */}
            <ReferenceField label="Elemento" source="tipoElementoSenal" fullWidth reference="tipo_elemento_senal_perus"  formClassName={classes.grid_cont2_s} linkType={false}>
                <TextField  source="nombre" fullWidth />
            </ReferenceField>
            {/* <ReferenceField label="Tipo de elemento" source="tipoSenal" fullWidth reference="tipo_senal_perus"  formClassName={classes.grid_cont2_s} linkType={false}>
                <TextField  source="nombre" fullWidth />
            </ReferenceField>  */}
            <TextField label="Tipo de elemento" source="tipoSenal_.nombre" fullWidth reference="tipo_senal_perus"  formClassName={classes.grid_cont2_s} linkType={false}/>
            {/* <ReferenceField label="Modelo" source="nombre" fullWidth reference="senals"  formClassName={classes.grid_cont4_f} linkType={false}>
                <TextField  source="nombre" fullWidth />
            </ReferenceField>                                  */}
            <TextField label="Modelo" source="nombre_.nombre" fullWidth  formClassName={classes.grid_cont4_f}/>
            <ReferenceField label="Elemento Señalizacion" source='elemento_senalizacion' reference="elemento_senalizacion_perus" formClassName={classes.grid_cont4_s} linkType={false}>
                <TextField  source="fullCodigo" fullWidth />
            </ReferenceField>
            <div formClassName={classes.grid_cont12} fullWidth></div>
            <DateField source="fecha" label="Fecha" formClassName={classes.grid_cont4_f}/>
            <TextField source="medicion_global" label="Medición Global" formClassName={classes.grid_cont4_s}/>
            <Divider fullWidth style={{width: "100%"}}/>
            <TabbedForm className="Evaluaciones-tabs">
                <FormTab label="Deterioro de Placa o Panel">
                    <Typography variant="b" component="b" formClassName={classes.grid_cont6_f} style={{marginTop: '10px'}}>
                        Pregunta
                    </Typography>
                    <Typography variant="b" component="b" formClassName={classes.grid_cont6_f} style={{marginTop: '10px'}}>
                        Calificación
                    </Typography>
                    <WithProps>{({record,...props})=>{
                 if(record && record.svGrupo){
                  return(  <Grupo1Show record={record}/>)
                 }
                 else{
                     return(<div className="Reload2">
                                <img src={loader} />
                            </div> 
                           )
                    }
                }
                    }
                </WithProps>
                </FormTab>
                <FormTab label="Deterioro de los Elementos de Fijación">
                <Typography variant="b" component="b" formClassName={classes.grid_cont6_f} style={{marginTop: '10px'}}>
                        Pregunta
                    </Typography>
                    <Typography variant="b" component="b" formClassName={classes.grid_cont6_f} style={{marginTop: '10px'}}>
                        Calificación
                    </Typography>
                    <WithProps>{({record,...props})=>{
                 if(record && record.svGrupo){
                  return(  <Grupo2Show record={record}/>)
                 }
                 else{
                     return(<div className="Reload2">
                                <img src={loader} />
                            </div> 
                           )
                    }
                }
                    }
                </WithProps>
                </FormTab>
                <FormTab label="Deterioro de los Soportes/Estructuras de Soportes">
                <Typography variant="b" component="b" formClassName={classes.grid_cont6_f} style={{marginTop: '10px'}}>
                        Pregunta
                    </Typography>
                    <Typography variant="b" component="b" formClassName={classes.grid_cont6_f} style={{marginTop: '10px'}}>
                        Calificación
                    </Typography>
                    <WithProps>{({record,...props})=>{
                 if(record && record.svGrupo){
                  return(  <Grupo3Show record={record}/>)
                 }
                 else{
                     return(<div className="Reload2">
                                <img src={loader} />
                            </div> 
                           )
                    }
                }
                    }
                </WithProps>
                </FormTab>
                <FormTab label="RETRO-REFLECTIVIDAD">
                <WithProps>{({record,...props})=>                
                <RetroreflectividadShow record={record}/>}
                </WithProps>
                </FormTab>
                <FormTab label="DECOLORACIÓN">
                <WithProps>{({record,...props})=>
                <DecoloracionShow record={record}/>}
                </WithProps>
                </FormTab>
            </TabbedForm>
            </SimpleForm>
        </Show>
    )
});
const WithProps = ({children,...props}) => children(props);