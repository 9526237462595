import React, {Fragment} from 'react';
import { TabbedForm, FormTab, DisabledInput, BooleanInput, 
        NumberInput, ReferenceInput, SelectInput, FormDataConsumer, 
        ArrayInput, SimpleFormIterator, AutocompleteInput 
} from 'react-admin';

import { PimsElementoActions } from './../PimsElementoActions';

import { PInput } from '../../../inputs/PInput';
import { GMapInput } from '../../../inputs/GMapInput';

import { withStyles } from '@material-ui/core';
import { Edit } from './../../Edit';

import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';

import {
    required,
    minValue,
    maxValue
} from 'react-admin';

const styles = {    
    verySmallField: { display: 'inline-flex', marginRight: '1%', width:  '9%' },
    smallField:     { display: 'inline-flex', marginRight: '1%', width: '19%' },
    middleField:    { display: 'inline-flex', marginRight: '1%', width: '39%' },
    bigField:       { display: 'inline-flex', marginRight: '1%', width: '99%' },
    innerField:     { width: '100%'}
};



const ElementoCalzadaTitle = ({ record }) => {
    return <span>Calzada {record ? `${record.fullCode}` : ''}</span>;
};

export const ElementoCalzadaEdit = withStyles(styles)(({ classes, ...props }) => (
    <Edit title={<ElementoCalzadaTitle />} actions={<PimsElementoActions/>} {...props}>
        <TabbedForm submitOnEnter={false} redirect="list">
            <FormTab label="Basica">
                <Grid container spacing={24}>
                    <Grid item xs={8}>
                        <Grid container spacing={24}>
                            <Grid item xs={6}>
                                <DisabledInput label="Segmento" source="segmento_.fullCode" fullWidth/>
                            </Grid>
                            <Grid item xs={6}>
                                <DisabledInput label="Tipo elemento" source="tipoElemento_.nombre" fullWidth/>
                            </Grid>
                            <Grid item xs={6}>
                                <PInput label="Abscisa inicial" source="pInicio" fullWidth disabled/>
                            </Grid>
                            <Grid item xs={6}>
                                <PInput label="Abscisa final" source="pFin" fullWidth disabled/>
                            </Grid>
                            <Grid item xs={6}>
                                <DisabledInput label="Departamento" source="segmento_.municipio_.departamento_.nombre" fullWidth/>
                            </Grid>
                            <Grid item xs={6}>
                                <DisabledInput label="Municipio" source="segmento_.municipio_.nombre" fullWidth/>
                            </Grid>
                            <Grid item xs={6}>
                                <DisabledInput label="Tipo calzada" source="segmento_.tipoCalzada_.nombre" disabled fullWidth/>                                
                            </Grid>
                            <Grid item xs={6}>
                                <DisabledInput label="Tipo segmento" source="segmento_.tipoSegmento_.nombre" disabled fullWidth/>                                
                            </Grid>
                            <Grid item xs={6}>
                                <DisabledInput label="Tipo superficie" source="segmento_.tipoSuperficie_.nombre" disabled fullWidth/>
                            </Grid> 
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid container spacing={24}>                                          
                            <Grid item xs={12}>
                                <GMapInput
                                    defaultZoom={12}                                    
                                    defaultCenter={{ lat: 4.7567039, lng: -74.0311056 }}
                                    source="ubicaciones_"
                                    multipleMarkers="true"
                                    googleKey="AIzaSyCivTXzJBYRBK8nosBYRFXnv-hGXIbhnYY"
                                />
                            </Grid>                            
                            <Grid item xs={12}>
                                <NumberInput label="Elevación" source="elevacion" fullWidth validate={[required()]}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>                
            </FormTab>
            <FormTab label="Detalles">
                
            </FormTab>
            <FormTab label="Geometria">
                <Grid container spacing={24}>
                    <Grid item xs={8}>
                        <Grid container spacing={24}>
                            <Grid item xs={4}>
                                <NumberInput label="Largo (m)" source="largo" fullWidth validate={[required(), minValue(0), maxValue(2000)]}/>
                            </Grid>
                            <Grid item xs={4}>
                                <NumberInput label="Ancho de calzada (m)" source="ancho" fullWidth validate={[required(), minValue(3), maxValue(40)]}/>
                            </Grid>
                            <Grid item xs={4}>
                                <NumberInput label="Número de carriles" source="numeroCarriles" fullWidth validate={[required(), minValue(1), maxValue(5)]}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={4}>
                                <NumberInput label="Ancho por carril (m)" source="anchoCarril" fullWidth validate={[required(), minValue(1), maxValue(5)]}/>
                            </Grid>
                            <Grid item xs={4}>
                                <NumberInput label="Ancho de berma (m)" source="anchoBerma" fullWidth validate={[required(), minValue(0), maxValue(10)]}/>
                            </Grid>                            
                            <Grid item xs={4}>
                                <NumberInput label="Área verdadera carriles (m2)" source="areaCarril" fullWidth validate={[required(), minValue(0), maxValue(8000)]}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={4}>
                                <NumberInput label="Pendiente longitudinal" source="pendienteLongitudinal" fullWidth validate={[required(), minValue(0), maxValue(20)]}/>
                            </Grid>
                            <FormDataConsumer>
                                {({ formData, ...rest }) => (formData.tipoElemento === '/tipo_elementos/PART'||formData.tipoElemento === '/tipo_elementos/PFLEX') &&
                                    <Fragment>
                                        <Grid item xs={4}>
                                            <NumberInput label="Pendiente longitudinal máxima" source="pendienteLongitudinalMaxima" fullWidth validate={[required(), minValue(0), maxValue(20)]}/>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <NumberInput label="Pendiente longitudinal mínima" source="pendienteLongitudinalMinima" fullWidth validate={[required(), minValue(0), maxValue(20)]}/>
                                        </Grid>
                                    </Fragment>
                                }
                            </FormDataConsumer>
                            <Grid item xs={4}>
                                <NumberInput label="Pendiente transversal" source="pendienteTransversal" fullWidth validate={[required(), minValue(0), maxValue(5)]}/>
                            </Grid>                            
                            <FormDataConsumer>
                                {({ formData, ...rest }) => (formData.tipoElemento === '/tipo_elementos/PART' || formData.tipoElemento === '/tipo_elementos/PFLEX') &&
                                    <Fragment>
                                        <Grid item xs={8}></Grid>
                                        <Grid item xs={4}>
                                            <NumberInput label="Radio mínimo (m)" source="radioMinimo" fullWidth validate={[required(), minValue(0), maxValue(5)]}/>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <NumberInput label="Radio máximo (m)" source="radioMaximo" fullWidth validate={[required(), minValue(0), maxValue(5000)]}/>
                                        </Grid>
                                    </Fragment>
                                }
                            </FormDataConsumer>                            
                            <Grid item xs={4}>
                                <NumberInput label="Radio de curvatura (°/km)" source="radioCurvatura" fullWidth validate={[required(), minValue(0), maxValue(5000)]}/>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <BooleanInput label="¿Con separador?" source="separador" fullWidth/>
                            </Grid>
                            <Grid item xs={4}>
                                <FormDataConsumer>
                                    {({ formData, ...rest }) => formData.separador &&
                                        <NumberInput label="Ancho separador (m)" source="anchoSeparador" fullWidth validate={[required(), minValue(0), maxValue(50)]} {...rest}/>
                                    }
                                </FormDataConsumer>                                
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={4}>
                                <NumberInput label="Velocidad de diseño (km/h)" source="velocidad" fullWidth validate={[required(), minValue(10), maxValue(150)]}/>
                            </Grid>
                            <FormDataConsumer>
                                {({ formData, ...rest }) => formData.tipoElemento === '/tipo_elementos/PART' &&
                                    <Fragment>
                                        <Grid item xs={12}><Divider /></Grid>
                                        <Grid item xs={4}>
                                            <NumberInput label="Largo de adoquín (cm)" source="largoAdoquin" fullWidth validate={[required(), minValue(0), maxValue(100)]}/>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <NumberInput label="Ancho de adoquín (cm)" source="anchoAdoquin" fullWidth validate={[required(), minValue(0), maxValue(100)]}/>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <NumberInput label="Alto adoquín (cm)" source="altoAdoquin" fullWidth validate={[required(), minValue(0), maxValue(100)]}/>
                                        </Grid>
                                    </Fragment>
                                }
                            </FormDataConsumer>
                            <FormDataConsumer>
                                {({ formData, ...rest }) => formData.tipoElemento === '/tipo_elementos/PRIGD' &&
                                    <Fragment>
                                        <Grid item xs={12}><Divider /></Grid>
                                        <Grid item xs={12}><Typography component="h5" variant="body1">Dimensiones de losa tipica</Typography></Grid>
                                        <Grid item xs={6}>
                                            <NumberInput label="Largo (m)" source="largoLosa" fullWidth validate={[required(), minValue(1), maxValue(5)]}/>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <NumberInput label="Ancho (m)" source="anchoLosa" fullWidth validate={[required(), minValue(1), maxValue(5)]}/>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <NumberInput label="Número de losas" source="numeroLosas" fullWidth validate={[required(), minValue(1), maxValue(100)]}/>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <NumberInput label="Longitud de juntas (m)" source="longitudLosas" fullWidth validate={[required(), minValue(1), maxValue(5)]}/>
                                        </Grid>
                                    </Fragment>
                                }
                            </FormDataConsumer>
                        </Grid>
                    </Grid>
                </Grid>
            </FormTab>
            <FormTab label="Estructura">
                <ArrayInput source="estructuras" fullWidth>
                    <SimpleFormIterator fullWidth>      
                        <NumberInput label="Espesor (mm)" source="espesor" formClassName={classes.smallField} fullWidth validate={[required(), minValue(1), maxValue(2000)]}/>
                        <NumberInput label="Módulo (MPa)" source="modulo" formClassName={classes.smallField} fullWidth validate={[required(), minValue(1), maxValue(100000)]}/>
                        <NumberInput label="Desviación Estandar (Std)" source="desviacionEstandar" formClassName={classes.smallField} fullWidth validate={[required(), minValue(0.01), maxValue(4)]}/>
                        <ReferenceInput label="Material/Estructura/Especificación" source="material" reference="material_estructuras" formClassName={classes.middleField} fullWidth validate={[required()]}>
                            <AutocompleteInput optionText="fullCode" options={{fullWidth: true}}/>
                        </ReferenceInput>
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
            <FormTab label="Deterioros">
                <ArrayInput source="deterioros" fullWidth>
                    <SimpleFormIterator fullWidth> 
                        <FormDataConsumer formClassName={classes.middleField}>
                            {({ formData, ...rest }) => <ReferenceInput label="Deterioro" source="tipoDeterioro" 
                                    reference="tipo_deterioros" filter={{tipoelemento: formData.tipoElemento}}
                                    formClassName={classes.middleField} fullWidth validate={[required()]}>
                                    <SelectInput optionText="fullCode" fullWidth/>
                                </ReferenceInput>
                            }
                        </FormDataConsumer> 

                        <NumberInput label="Severidad baja (Longitud o Área)" source="baja" formClassName={classes.verySmallField} fullWidth validate={[required(), minValue(0), maxValue(2000)]}/>
                        <NumberInput label="Severidad baja (%)" source="bajaPorcentaje" formClassName={classes.verySmallField} fullWidth validate={[required(), minValue(0), maxValue(100)]}/>
                        
                        <NumberInput label="Severidad media (Longitud o Área)" source="media" formClassName={classes.verySmallField} fullWidth validate={[required(), minValue(0), maxValue(2000)]}/>
                        <NumberInput label="Severidad media (%)" source="mediaPorcentaje" formClassName={classes.verySmallField} fullWidth validate={[required(), minValue(0), maxValue(100)]}/>

                        <NumberInput label="Severidad media (Longitud o Área)" source="alta" formClassName={classes.verySmallField} fullWidth validate={[required(), minValue(0), maxValue(2000)]}/>
                        <NumberInput label="Severidad media (%)" source="altaPorcentaje" formClassName={classes.verySmallField} fullWidth validate={[required(), minValue(0), maxValue(100)]}/>
                          
                    </SimpleFormIterator>  
                </ArrayInput>
            </FormTab>
        </TabbedForm>
    </Edit>
));