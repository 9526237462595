import React, { Component, useState } from "react";
import ItemsCarousel from 'react-items-carousel';
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import BugReportIcon from '@material-ui/icons/BugReport';
import CancelIcon from '@material-ui/icons/Cancel';

export default ({ permissions, basePath, ...props }) => {
  const { translate } = props;
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const chevronWidth = 40;
  const heightField = 190;
  return (
    // <div style={{ padding: `0 ${chevronWidth}px`, width:'60em' }}>
    // <Wrapper>
      <ItemsCarousel
        requestToChangeActive={setActiveItemIndex}
        activeItemIndex={activeItemIndex}
        numberOfCards={3}
        gutter={20}
        leftChevron={<Button style={{height: heightField}}><ChevronLeftIcon/></Button>}
        rightChevron={<Button style={{height: heightField}}><ChevronRightIcon/></Button>}
        outsideChevron={false}
        showSlither={true}
        firstAndLastGutter={true}
        chevronWidth={chevronWidth}
        style={{width: '100%'}}
      >
        {
          props.dataCard.map((dataCard, i) => (
            <Card style={{ height: heightField, marginBottom: '1px' }}>
              <CardContent style={{ background: '#EEE' }}>
                    <div>{translate('resources.carga_masivas.fields.proyectoList')} {dataCard.proyecto}</div>
                    <div>{translate('resources.carga_masivas.fields.rutaList')} {dataCard.ruta}</div>
                    <div>{translate('resources.carga_masivas.fields.unidadFuncional')} {dataCard.unidadFuncional}</div>
                    <div>{translate('resources.carga_masivas.fields.tramoList')} {dataCard.tramo}</div>
                    { dataCard.sistema !== 'Puentes y estructuras' &&
                      <div>{translate('resources.carga_masivas.fields.sistemaList')} {dataCard.sistema}</div>
                    }
                    { dataCard.sistema === 'Puentes y estructuras' &&
                      (() => {
                        if (dataCard.subSistema == "CONT") {
                          return (
                            <div>{translate('resources.carga_masivas.fields.sistemaEstrucCont')}</div>
                          )
                        } else if (dataCard.subSistema == "PNTE") {
                          return (
                            <div>{translate('resources.carga_masivas.fields.sistemaPuentes')}</div>
                          )
                        } else if (dataCard.subSistema == "PTON") {
                          return (
                            <div>{translate('resources.carga_masivas.fields.sistemaPontones')}</div>
                          )
                        } else if (dataCard.subSistema == "TNEL") {
                          return (
                            <div>{translate('resources.carga_masivas.fields.sistemaTuneles')}</div>
                          )
                        }
                      })()
                    }
                    <div>{translate('resources.carga_masivas.fields.imagenes')} {dataCard.imagenes == true ? "Si" : "No"}</div>
              </CardContent>
              <CardActions>
                <Grid item xs={10} className="negrilla" align={'left'} style={{whiteSpace:'nowrap', overflow: 'hidden', textOverflow:'ellipsis'}}>
                  {dataCard.rutaArchivo}
                </Grid>
                <Grid item xs={2} align={'right'}>
                    <Grid container>
                        <Grid item xs={12}>
                          {(() => {
                            if (dataCard.estado == 1) {
                              return (
                                <CircularProgress size={18}/>
                              )
                            } else if (dataCard.estado == 2) {
                              return (
                                <CheckCircleIcon style={{color:'#1daf03'}} size={18}/>
                              )
                            } else if (dataCard.estado == 3) {
                              return (
                                <ErrorIcon style={{color:'#ebce00'}} size={18}/>
                              )
                            } else if (dataCard.estado == 4) {
                              return (
                                <CancelIcon style={{color:'#e10202'}} size={18}/>
                              )
                            }
                          })()}
                        </Grid>
                    </Grid>
                </Grid>
              </CardActions>
            </Card>
          ))
        }
      </ItemsCarousel>
    // </Wrapper>
  );
};
