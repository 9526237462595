import React from 'react';
import { TextField, ReferenceField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import config from '../../../config/config';
import loader from '../../../loader10.gif';
import { translate } from 'react-admin';
let urlAppjs = config.UrlServer;

class VistaDatosShow extends React.Component {
    constructor(props) {
        super(props)
        this.state = { datosCarga: [], record: [] }

    }
    componentDidMount() {
        this.setState({ record: this.props.record });
        let abini = parseFloat(this.props.record.abini);
        let abfin = parseFloat(this.props.record.abfin) + 0.1;
        let idcarga = this.props.record.cargaInformacionId;
        //console.log(abini + " " + abfin);
        if (this.props.record.indicador && this.props.record.indicador === "/parametros/1") {
            fetch(urlAppjs + "/indicadores4_g_e1s?cargaInformacionId=" + idcarga)//?property[<lt|gt|lte|gte|between>]=value
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.setState({ datosCarga: response })
                    //console.log(grupo1)
                })
        } else if (this.props.record.indicador && this.props.record.indicador === "/parametros/2") {
            fetch(urlAppjs + "/indicadores4_g_e2s?cargaInformacionId=" + idcarga)//?property[<lt|gt|lte|gte|between>]=value
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.setState({ datosCarga: response })
                    //console.log(grupo1)
                })
        } else if (this.props.record.indicador && this.props.record.indicador === "/parametros/3") {
            fetch(urlAppjs + "/indicadores4_g_e3s?cargaInformacionId=" + idcarga)//?property[<lt|gt|lte|gte|between>]=value
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.setState({ datosCarga: response })
                    //console.log(grupo1)
                })
        } else if (this.props.record.indicador && this.props.record.indicador === "/parametros/4") {
            fetch(urlAppjs + "/indicadores4_g_e4s?cargaInformacionId=" + idcarga)//?property[<lt|gt|lte|gte|between>]=value
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.setState({ datosCarga: response })
                    //console.log(grupo1)
                })
        } else if (this.props.record.indicador && this.props.record.indicador === "/parametros/5") {
            fetch(urlAppjs + "/indicadores4_g_e5s?cargaInformacionId=" + idcarga)//?property[<lt|gt|lte|gte|between>]=value
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.setState({ datosCarga: response })
                    //console.log(grupo1)
                })
        } else if (this.props.record.indicador && this.props.record.indicador === "/parametros/6") {
            fetch(urlAppjs + "/indicadores4_g_e6s?cargaInformacionId=" + idcarga)//?property[<lt|gt|lte|gte|between>]=value
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.setState({ datosCarga: response })
                    //console.log(grupo1)
                })
        } else if (this.props.record.indicador && this.props.record.indicador === "/parametros/7") {
            fetch(urlAppjs + "/indicadores4_g_e7s?cargaInformacionId=" + idcarga)//?property[<lt|gt|lte|gte|between>]=value
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.setState({ datosCarga: response })
                    //console.log(grupo1)
                })
        } else if (this.props.record.indicador && this.props.record.indicador === "/parametros/8") {
            fetch(urlAppjs + "/indicadores4_g_e8s?cargaInformacionId=" + idcarga)//?property[<lt|gt|lte|gte|between>]=value
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.setState({ datosCarga: response })
                    //console.log(grupo1)
                })
        } else if (this.props.record.indicador && this.props.record.indicador === "/parametros/9") {
            fetch(urlAppjs + "/indicadores4_g_e10s?cargaInformacionId=" + idcarga)//?property[<lt|gt|lte|gte|between>]=value
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.setState({ datosCarga: response })
                    //console.log(grupo1)
                })
        } else if (this.props.record.indicador && this.props.record.indicador === "/parametros/10") {
            fetch(urlAppjs + "/indicadores4_g_e11s?cargaInformacionId=" + idcarga)//?property[<lt|gt|lte|gte|between>]=value
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.setState({ datosCarga: response })
                    //console.log(grupo1)
                })
        } else if (this.props.record.indicador && this.props.record.indicador === "/parametros/11") {
            fetch(urlAppjs + "/indicadores4_g_e12s?cargaInformacionId=" + idcarga)//?property[<lt|gt|lte|gte|between>]=value
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.setState({ datosCarga: response })
                    //console.log(grupo1)
                })
        } else if (this.props.record.indicador && this.props.record.indicador === "/parametros/12") {
            fetch(urlAppjs + "/indicadores4_g_e13s?cargaInformacionId=" + idcarga)//?property[<lt|gt|lte|gte|between>]=value
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.setState({ datosCarga: response })
                    //console.log(grupo1)
                })
        } else if (this.props.record.indicador && this.props.record.indicador === "/parametros/13") {
            fetch(urlAppjs + "/indicadores4_g_e14s?cargaInformacionId=" + idcarga)//?property[<lt|gt|lte|gte|between>]=value
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.setState({ datosCarga: response })
                    //console.log(grupo1)
                })
        } else if (this.props.record.indicador && this.props.record.indicador === "/parametros/15") {
            fetch(urlAppjs + "/indicadores4_g_e16s?cargaInformacionId=" + idcarga)//?property[<lt|gt|lte|gte|between>]=value
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.setState({ datosCarga: response })
                    //console.log(grupo1)
                })
        } else if (this.props.record.indicador && this.props.record.indicador === "/parametros/16") {
            fetch(urlAppjs + "/indicadorespcis?cargaInformacionId=" + idcarga)//?property[<lt|gt|lte|gte|between>]=value
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.setState({ datosCarga: response })
                    //console.log(grupo1)
                })
        } else if (this.props.record.indicador && this.props.record.indicador === "/parametros/17") {
            fetch(urlAppjs + "/indicadoresastms?cargaInformacionId=" + idcarga)//?property[<lt|gt|lte|gte|between>]=value
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.setState({ datosCarga: response })
                    //console.log(grupo1)
                })
        } else if (this.props.record.indicador && this.props.record.indicador === "/parametros/21") {
            fetch(urlAppjs + "/indicadores4_g_e22s?cargaInformacionId=" + idcarga)//?property[<lt|gt|lte|gte|between>]=value
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.setState({ datosCarga: response })
                    //console.log(grupo1)
                })
        } else if (this.props.record.indicador && this.props.record.indicador === "/parametros/22") {
            fetch(urlAppjs + "/indicadores4_g_e24s?pagination=off&cargaInformacionId=" + idcarga)//?property[<lt|gt|lte|gte|between>]=value
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.setState({ datosCarga: response })
                    //console.log(grupo1)
                })
        } else if (this.props.record.indicador && this.props.record.indicador === "/parametros/23") {
            fetch(urlAppjs + "/indicadores4_g_e25s?pagination=off&cargaInformacionId=" + idcarga)//?property[<lt|gt|lte|gte|between>]=value
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.setState({ datosCarga: response })
                    //console.log(grupo1)
                })
        }
    }
    formatoabscisa(valor) {
        if (this.props.record.indicador === "/parametros/1" || this.props.record.indicador === "/parametros/16") {
            let valor2 = valor - parseInt(valor);
            valor2 = parseFloat(valor2).toFixed(1);
            return "K" + parseInt(valor) + "+" + valor2.replace("0.", "") + "00";
        }
        else if (this.props.record.indicador === "/parametros/2" || this.props.record.indicador === "/parametros/4" || this.props.record.indicador === "/parametros/5") {
            let valor2 = valor - parseInt(valor);
            valor2 = parseFloat(valor2).toFixed(2);
            return "K" + parseInt(valor) + "+" + valor2.replace("0.", "") + "0";
        }
        else {
            let valor2 = valor - parseInt(valor);
            valor2 = parseFloat(valor2).toFixed(3);
            return "K" + parseInt(valor) + "+" + valor2.replace("0.", "");
        }
    }
    formatovalores(valor, decimal) {
        let value = parseFloat(valor).toFixed(decimal).replace(".", ",");
        return value;
    }
    render() {
        const { translate } = this.props;
        const { datosCarga } = this.state;
        //console.log(datosCarga);
        if (datosCarga['hydra:member'] && this.props.record.indicador === "/parametros/1") {


            return (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} colSpan="2">{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.abscisa')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} colspan="4">{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.carrilIzquierdoDescendente')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} colspan="4">{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.carrilDerechoAscendente')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} rowSpan="2">{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.cumplimientoIndicadorCalzada')}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.AbscisaInicio')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.AbscisaFinal')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.IRImm')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CumplimientoIndicPuntual')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Evento')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Singularidad')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>I{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.IRImm_m')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CumplimientoIndicPuntual')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Evento')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Singularidad')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {datosCarga['hydra:member'].map((row, i) => (
                            <TableRow>
                                <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(row['abini'])}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(row['abfin'])}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(row['iriCizq'], 1)}</TableCell>
                                {row['cumplimientoCizq'] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ color: "red", textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                }
                                {row['cumplimientoCizq'] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                }
                                {row['cumplimientoCizq'] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica')}</TableCell>
                                }
                                <TableCell style={{ textAlign: "left" }}>{row['eventoCizq']}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{row['singularidadCizq']}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(row['iriCder'], 1)}</TableCell>
                                {row['cumplimientoCder'] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ color: "red", textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                }
                                {row['cumplimientoCder'] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                }
                                {row['cumplimientoCder'] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica')}</TableCell>
                                }
                                <TableCell style={{ textAlign: "left" }}>{row['eventoCder']}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{row['singularidadCder']}</TableCell>
                                {row['cumplimientoCalzada'] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ color: "red", textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                }
                                {row['cumplimientoCalzada'] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                }
                                {row['cumplimientoCalzada'] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica')}</TableCell>
                                }
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

            )
        } else if (datosCarga['hydra:member'] && this.props.record.indicador === "/parametros/2") {
            return (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} colSpan="2">{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.abscisa')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} colspan="4">{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.carrilIzquierdoDescendente')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} colspan="4">{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.carrilDerechoAscendente')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} rowSpan="2">{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.cumplimientoIndicadorCalzada')}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.AbscisaInicio')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.AbscisaFinal')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Ahuellamiento_mm')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CumplimientoIndicPuntual')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Evento')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Singularidad')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Ahuellamiento_mm')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CumplimientoIndicPuntual')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Evento')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Singularidad')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {datosCarga['hydra:member'].map((row, i) => (
                            <TableRow>
                                <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(row['abini'])}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(row['abfin'])}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(row['ahuellamientoCizq'], 1)}</TableCell>
                                {row['cumplimientoCizq'] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ color: "red" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                }
                                {row['cumplimientoCizq'] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                }
                                {row['cumplimientoCizq'] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica')}</TableCell>
                                }
                                <TableCell style={{ textAlign: "left" }}>{row['eventoCizq']}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{row['singularidadCizq']}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(row['ahuellamientoCder'], 1)}</TableCell>
                                {row['cumplimientoCder'] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ color: "red", textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                }
                                {row['cumplimientoCder'] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                }
                                {row['cumplimientoCder'] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica')}</TableCell>
                                }
                                <TableCell style={{ textAlign: "left" }}>{row['eventoCder']}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{row['singularidadCder']}</TableCell>
                                {row['cumplimientoCalzada'] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ color: "red", textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                }
                                {row['cumplimientoCalzada'] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                }
                                {row['cumplimientoCalzada'] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica')}</TableCell>
                                }
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

            )
        } else if (datosCarga['hydra:member'] && this.props.record.indicador === "/parametros/3") {


            return (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.AbscisaFisura')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.TipoFisura')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Severidad')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Largo_m')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Ancho_m')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Area_m')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {datosCarga['hydra:member'].map((row, i) => (
                            <TableRow>
                                <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(row['abscisa'])}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{row['tipoFisura']}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{row['severidad']}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(row['largo'], 2)}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(row['ancho'], 2)}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(row['area'], 2)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

            )
        } else if (datosCarga['hydra:member'] && this.props.record.indicador === "/parametros/4") {
            return (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} colSpan="2">{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.abscisa')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} colspan="4">{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.carrilIzquierdoDescendente')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} colspan="4">{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.carrilDerechoAscendente')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} rowSpan="2">{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.cumplimientoIndicadorCalzada')}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.AbscisaInicio')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.AbscisaFinal')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CFT')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CumplimientoIndicPuntual')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Evento')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Singularidad')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CFT')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CumplimientoIndicPuntual')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Evento')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Singularidad')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {datosCarga['hydra:member'].map((row, i) => (
                            <TableRow>
                                <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(row['abini'])}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(row['abfin'])}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(row['cftCizq'], 2)}</TableCell>
                                {row['cumplimientoCizq'] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ color: "red" }}>{row['cumplimientoCizq']}</TableCell>
                                }
                                {row['cumplimientoCizq'] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                }
                                {row['cumplimientoCizq'] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica')}</TableCell>
                                }
                                <TableCell style={{ textAlign: "left" }}>{row['eventoCizq']}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{row['singularidadCizq']}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(row['cftCder'], 2)}</TableCell>
                                {row['cumplimientoCder'] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ color: "red", textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                }
                                {row['cumplimientoCder'] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                }
                                {row['cumplimientoCder'] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica')}</TableCell>
                                }
                                <TableCell style={{ textAlign: "left" }}>{row['eventoCder']}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{row['singularidadCder']}</TableCell>
                                {row['cumplimientoCalzada'] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ color: "red", textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                }
                                {row['cumplimientoCalzada'] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                }
                                {row['cumplimientoCalzada'] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica')}</TableCell>
                                }
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

            )
        } else if (datosCarga['hydra:member'] && this.props.record.indicador === "/parametros/5") {


            return (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} colSpan="2">{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.abscisa')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} colspan="4">{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.carrilIzquierdoDescendente')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} colspan="4">{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.carrilDerechoAscendente')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} rowSpan="2">{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.cumplimientoIndicadorCalzada')}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.AbscisaInicio')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.AbscisaFinal')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.MTDmmASTME1845')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CumplimientoIndicPuntual')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Evento')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Singularidad')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.MTDmmASTME1845')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CumplimientoIndicPuntual')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Evento')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Singularidad')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {datosCarga['hydra:member'].map((row, i) => (
                            <TableRow>
                                <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(row['abini'])}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(row['abfin'])}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(row['mtdCizq'], 2)}</TableCell>
                                {row['cumplimientoCizq'] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ color: "red" }}>{row['cumplimientoCizq']}</TableCell>
                                }
                                {row['cumplimientoCizq'] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                }
                                {row['cumplimientoCizq'] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica')}</TableCell>
                                }
                                <TableCell style={{ textAlign: "left" }}>{row['eventoCizq']}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{row['singularidadCizq']}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(row['mtdCder'], 2)}</TableCell>
                                {row['cumplimientoCder'] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ color: "red", textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                }
                                {row['cumplimientoCder'] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                }
                                {row['cumplimientoCder'] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica')}</TableCell>
                                }
                                <TableCell style={{ textAlign: "left" }}>{row['eventoCder']}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{row['singularidadCder']}</TableCell>
                                {row['cumplimientoCalzada'] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ color: "red", textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                }
                                {row['cumplimientoCalzada'] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                }
                                {row['cumplimientoCalzada'] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica')}</TableCell>
                                }
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

            )
        } else if (datosCarga['hydra:member'] && this.props.record.indicador === "/parametros/6") {
            return (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.AbscisaFisura')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Ancho_m')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Largo_m')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Area_m')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Profundidadcm')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Observaciones')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Carril')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {datosCarga['hydra:member'].map((row, i) => (
                            <TableRow>
                                <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(row['abscisa'])}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(row['ancho'], 2)}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(row['largo'], 2)}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(row['area'], 2)}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(row['profundidad'], 2)}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{row['observaciones']}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{row['carril']}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            )
        } else if (datosCarga['hydra:member'] && this.props.record.indicador === "/parametros/7") {
            return (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.AbscisaFisura')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Ancho_m')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Largo_m')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Area_m')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Profundidadcm')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Observaciones')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Carril')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {datosCarga['hydra:member'].map((row, i) => (
                            <TableRow>
                                <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(row['abscisa'])}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(row['ancho'], 2)}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(row['largo'], 2)}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(row['area'], 2)}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(row['profundidad'], 2)}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{row['observaciones']}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{row['carril']}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            )
        } else if (datosCarga['hydra:member'] && this.props.record.indicador === "/parametros/8") {
            return (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.AbscisaFisura')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Medida1_cm')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Medida2_cm')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Medida3_cm')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Medida4_cm')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Medida5_cm')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.LimpiezaGenCalzada')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.LaVegetacionAfecta')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.NumeroBasuras')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Observaciones')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {datosCarga['hydra:member'].map((row, i) => (
                            <TableRow>
                                <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(row['abscisa'])}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(row['medida1'], 2)}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(row['medida2'], 2)}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(row['medida3'], 2)}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(row['medida4'], 2)}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(row['medida5'], 2)}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{row['limpiezaGeneral'] ? translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.adecuado') : translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.inadecuado')}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{row['vegetacionSeguridad'] ? translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.booleanSi') : translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.booleanNo')}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(row['numeroBasuras'], 2)}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{row['observaciones']}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            )
        } else if (datosCarga['hydra:member'] && this.props.record.indicador === "/parametros/9") {
            return (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Elemento')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.SeccionHidraulColmatada')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Observaciones')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {datosCarga['hydra:member'].map((row, i) => (
                            <TableRow>
                                <TableCell style={{ textAlign: "right" }}>{row['elementoDrenaje']['fullCodigoConsecutivo']}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{row['seccionHidraulica']}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{row['observaciones']}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            )
        } else if (datosCarga['hydra:member'] && this.props.record.indicador === "/parametros/10") {
            return (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Elemento')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.RetroreflectividadColorPrin')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.RetroreflectividadColorSec')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.LegibilidadSenal')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Localización')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Estabilidad')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.EstadoLimpieza')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Observaciones')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {datosCarga['hydra:member'].map((row, i) => (
                            <TableRow>
                                <TableCell style={{ textAlign: "right" }}>{row['elementoSenalizacion']['fullCodigoConsecutivo']}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{row['retroPrincipal']}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{row['retroSecundario']}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{row['legibilidad'] ? translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.adecuado') : translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.inadecuado')}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{row['localizacion'] ? translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.adecuado') : translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.inadecuado')}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{row['estabilidad'] ? translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.adecuado') : translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.inadecuado')}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{row['estadoLimpieza'] ? translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.adecuado') : translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.inadecuado')}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{row['observaciones']}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            )
        } else if (datosCarga['hydra:member'] && this.props.record.indicador === "/parametros/11") {
            return (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.AbscisaFisura')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.MedLineaBlanBordeDerecho')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.MedLineaBlanBordeIzquierdo')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.MedidalineaAmarilla')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CumplimientoDelineadPiso')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.TipoEquipoGeometria_cm')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {datosCarga['hydra:member'].map((row, i) => (
                            <TableRow>
                                <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(row['abscisa'])}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{row['lineaBlancaDerecho']}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{row['lineaBlancaIzquierdo']}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{row['lineaAmarilla']}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{row['delineadorPiso'] ? translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.booleanSi') : translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.booleanNo')}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{i <= 0 ? row['tipoEquipo'] : ""}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            )
        } else if (datosCarga['hydra:member'] && this.props.record.indicador === "/parametros/12") {
            return (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Elemento')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Alineacion')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CondicionEstructural')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Estabilidad')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.LongitudIncumplimiento')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Observaciones')} </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {datosCarga['hydra:member'].map((row, i) => (
                            <TableRow>
                                <TableCell style={{ textAlign: "left" }}>{row['elementoContencion']['fullCodigoConsecutivo']}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{row['alineacion']}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{row['condicionEstructural']}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{row['estabilidad']}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{row['longitudIncumplimiento']}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{row['observaciones']}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            )
        } else if (datosCarga['hydra:member'] && this.props.record.indicador === "/parametros/13") {
            return (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Elemento')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.FuncionamientoLuminaria')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.EstadofisicoLuminaria')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.EstadoSoportes')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.EstadoCentroMando')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.EstadoTableroControl')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Observaciones')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {datosCarga['hydra:member'].map((row, i) => (
                            <TableRow>
                                <TableCell style={{ textAlign: "right" }}>{row['elementoRed']['fullCodigoConsecutivo']}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{row['cumplimiento']}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{row['estadoFisico']}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{row['estadoSoporte']}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{row['estadoCentro']}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{row['estadoTablero']}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{row['observaciones']}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            )
        } else if (datosCarga['hydra:member'] && this.props.record.indicador === "/parametros/15") {
            return (
                <Table aria-label="simple table" style={{ width: "50%" }}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} >Nivel de Tráfico: {datosCarga['hydra:member'][0]['nivelTrafico']}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.AbscisaFisura')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CarrilIzquierdo')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CarrilDerecho')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {datosCarga['hydra:member'].map((row, i) => (
                            <TableRow>
                                <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(row['abscisa'])}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(row['carrilIzquierdo'], 2)}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(row['carrilDerecho'], 2)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            )
        } else if (datosCarga['hydra:member'] && this.props.record.indicador === "/parametros/16") {
            return (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} colSpan="2">{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.abscisa')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} rowSpan="2">{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.PCI')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} rowSpan="2">{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Observaciones')}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.AbscisaInicio')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.AbscisaFinal')}</TableCell>
                            <TableCell align="right"></TableCell>
                            <TableCell align="right"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {datosCarga['hydra:member'].map((row, i) => (
                            <TableRow>
                                <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(row['abini'])}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(row['abfin'])}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{row['pci']}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{row['observaciones']}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

            )
        } else if (datosCarga['hydra:member'] && this.props.record.indicador === "/parametros/17") {
            return (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.AbscisaInicio')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.AbscisaFinal')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CodigoASTM')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Alto')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Medio')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Bajo')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {datosCarga['hydra:member'].map((row, i) => (
                            <TableRow>
                                <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(row['abini'])}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(row['abfin'])}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{row['codigo']}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(row['alto'], 2)}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(row['medio'], 2)}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(row['bajo'], 2)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

            )
        } else if (datosCarga['hydra:member'] && this.props.record.indicador === "/parametros/21") {
            return (
                <Table aria-label="simple table" style={{ width: "40%" }}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.AbscisaFisura')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Escalonamiento')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Observaciones')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {datosCarga['hydra:member'].map((row, i) => (
                            <TableRow>
                                <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(row['abscisa'])}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(row['escalonamiento'], 2)}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{row['observaciones']}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

            )
        } else if (datosCarga['hydra:member'] && this.props.record.indicador === "/parametros/22") {
            return (
                <Table aria-label="simple table" style={{ width: "40%" }}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.AbscisaFisura')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.ExtensionDesportillam')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Observaciones')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {datosCarga['hydra:member'].map((row, i) => (
                            <TableRow>
                                <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(row['abscisa'])}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(row['alturaDesportillamiento'], 2)}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{row['observaciones']}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

            )
        } else if (datosCarga['hydra:member'] && this.props.record.indicador === "/parametros/23") {
            return (
                <Table aria-label="simple table" style={{ width: "40%" }}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.AbscisaFisura')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.LongitudJuntasAfectadas')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Observaciones')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {datosCarga['hydra:member'].map((row, i) => (
                            <TableRow>
                                <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(row['abscisa'])}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(row['longitudJuntaAfectada'], 2)}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{row['observaciones']}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

            )
        }
        else {
            return (
                <div className="Reload2">
                    <img src={loader} />
                </div>
            )
        }
    }
}

export default translate(VistaDatosShow);