import React from 'react';
import { WithPermissions,ListButton, TabbedShowLayout,ReferenceField, EditButton, Tab, TextField, DateField, CardActions,ReferenceArrayField, 
         ShowController, ShowView, NumberField
} from 'react-admin';
import { ArrayField, SingleFieldList, ChipField } from 'react-admin';
import { Show } from './../Show';
import { FormDataConsumer } from 'ra-core';
import {ShowToolbar} from './../componentes/ShowToolbar';
import { translate } from 'react-admin';

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

export const Contacto_emergenciaShow = translate(props => (
    <ShowController title="Información segmento flexible" {...props}>
        {controllerProps =>
        <ShowView {...props} {...controllerProps} title="dynatest.resources.contacto_emergencias.title.showTitle" actions={<ShowToolbar />}>
                <TabbedShowLayout>
                    <Tab label="dynatest.resources.contacto_emergencias.tabs.informacionBasica">
                    <ReferenceField source="proyecto" reference="proyectos" linkType={false} >
                    <TextField source="nombre" />
                </ReferenceField>  
                <br/><br/>
                    <TextField source="grupo" />
                    {controllerProps.record && controllerProps.record.grupo  === "Servicios médicos" &&
                        <TextField label="Nivel" source="fullNivell" />
                    }
                    
                     <FormDataConsumer>
                    {({ formData, ...rest }) =>
                      formData.grupo === 'Servicios médicos' && (
                        <TextField label="Nivel" source="fullNivell" />
                        )
                    }
                    </FormDataConsumer>                
                    <span></span>
                    
                    <TextField source="identificacion" />
                    <NumberField source="numero_identificacion" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} />
                    <TextField source="empresa" />

                    <TextField source="nombre" />
                    <TextField source="apellido" />
                    <TextField source="cargo" />

                    <TextField source="direccion" />
                    <TextField label="resources.contacto_emergencias.fields.departamento" source="departamento_.nombre"/>
                    {/* <TextField label="Municipio" source="municipio_.nombre"/> */}                    
                    <ReferenceArrayField reference="municipios" source="municipio">
                <SingleFieldList>
                    <ChipField source="nombre" />
                </SingleFieldList>
                </ReferenceArrayField>               
                    <TextField source="telefono_1" />
                    <TextField source="telefono_2" />
                    <br /> <br /> <br /> <br /> 
                    <TextField source="correo" />  
                    <br /> <br /> 
                    <TextField source="observaciones" />
                    
                    </Tab>
                    <Tab label="dynatest.resources.tipo_deterioro_pers.tabs.auditoria">
                        <DateField source="createdAt" showTime/> 
                        <TextField source="createdBy" />
                        <span></span>               
                        <DateField source="updatedAt" showTime/>
                        <TextField source="updatedBy" />
                        <span></span>   
                    </Tab>
                </TabbedShowLayout>
            </ShowView>
        }
    </ShowController>
));