import React from 'react';
import Typography from '@material-ui/core/Typography';
import {
    REDUX_FORM_NAME, DateInput, BooleanInput, required, TabbedForm, FormTab, AutocompleteInput, SimpleFormIterator,
    ArrayInput, NumberInput, SelectInput, ReferenceInput, Button, TextInput, FormDataConsumer, List, CheckboxGroupInput
} from 'react-admin';
import NumberFormat from 'react-number-format';
import { change } from 'redux-form';
import PropTypes from 'prop-types';
import { minValue, maxValue, minLength, maxLength } from 'ra-core';
import { PRInput } from '../../../inputs/PRInput';
import { PInput } from '../../../inputs/PInput';
import { DInput } from '../../../inputs/DInput';
import { DEInput } from '../../../inputs/DEInput';
import { AInput } from '../../../inputs/AInput';
import { styles } from '../../../EstilosGrid';
import { withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { PField } from '../../../fields/PField';
import { PRField } from '../../../fields/PRField';
import {
    abValidationInicio, abValidationFin, validarDosDecimales, validarUnDecimal, validarAB, isFechaInvalida,
    Altitud, latMininima, lngMininima
} from '../../../../src/resources/validacionesInputs';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ButtonMaterial from '@material-ui/core/Button';
import { translate } from 'react-admin';


import config from '../../../config/config';
let urlAppjs = config.UrlServer;
let Url = config.UrlFront;


class TablaTransformacionSegmentos extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            countCodeSeg: 0,
            countRenderPage: 0,
            record: [],
            tipo: "",
            datos: {
                departamentos: [],
                municipios: [],
                tipo_calzadas: [],
                tipo_segmentos: [],
                tipo_elementos: []
            },
            defaultData: "",
            tranformarSegIds : [],
            checkeados: {},
            departamentos: {},
            defaultCheck: false,
            dataGet: [],
            dataValidation: this.props.dataValidate
        }

        // this.departamentoDefault = this.props.departamentoDefault
        // this.fetcher.bind(this);
    }

    SetInicialDefault() {
        let codigoDefault = "";
        let AbsIniDefault = "";
        let AbsFinDefault = "";
        let PrIniDefault = "";
        let PrFinDefault = "";
        let LongIniDefault = "";
        let LongFinDefault = "";
        let LatIniDefault = "";
        let LatFinDefault = "";
        let AltIniDefault = "";
        let AltFinDefault = "";
        let MunicipioDefault = "";
        let DepartamentoDefault = "";

        let countLastItemArray = this.state.dataValidation.length - 1;
        this.state.dataValidation.forEach((element, j ) => {
            this.state.tranformarSegIds.push(element.id);
            if (j == 0) {
                codigoDefault = element.fullCodigo;
                AbsIniDefault = element.abInicio;
                PrIniDefault = element.pInicio;
                LongIniDefault = element.longitud;
                LatIniDefault = element.latitud;
                AltIniDefault = element.alturaIni;
                MunicipioDefault = element.municipio;
            }
            if (j == countLastItemArray) {
                AbsFinDefault = element.abFin;
                PrFinDefault = element.pFin;
                LongFinDefault = element.longitud2;
                LatFinDefault = element.latitud2;
                AltFinDefault = element.alturaFin;
            }
        });

        if (codigoDefault != "") {
            codigoDefault = codigoDefault.replace("::", "");
            codigoDefault = codigoDefault.split(/::(.+)/)[1];
        }

        this.state.defaultData = {
            fullCodigo: codigoDefault,
            abInicio: AbsIniDefault,
            abFin: AbsFinDefault,
            pInicio: PrIniDefault,
            pFin: PrFinDefault,
            departamentos: "",
            municipios: MunicipioDefault,
            latitud: LatIniDefault,
            latitud2: LatFinDefault,
            longitud: LongIniDefault,
            longitud2: LongFinDefault,
            alturaIni: AltIniDefault,
            alturaFin: AltFinDefault,
        };

        fetch(urlAppjs + MunicipioDefault, {headers: {'authorization': 'Bearer ' + localStorage.getItem('token')}})
        .then((response) => {
            return response.json()
        })
        .then((departamentoGet) => {
            DepartamentoDefault = departamentoGet['departamento']['@id'];
            this.state.defaultData.departamentos = DepartamentoDefault;
        });
    }
    componentDidMount() {
        this.setState({ record: this.props.record })
        this.setState({ tipo: this.props.tipo })
        if (this.state.defaultData === "") {
            this.SetInicialDefault();
        }
    }

    render() {
        const { translate } = this.props;
        
        return (
            <div style={{overflowX: 'auto', width: "80vw"}}>
                <FormDataConsumer style={{ width: "100%" }}>
                    {({ formData, dispatch, selected, record, resource, id,  onToggleItem, ...rest}) => {
                        const alphabet = ["A","B","C","D","E","F","G","H","I","J","K","L","M","N","O","P","Q","R","S","T","U","V","W","X","Y","Z"];
                        const CalzadaCodigo = (value, index) => {
                            debugger
                        }
                        if (formData != undefined) {
                            if (formData['table'] === undefined) {
                                formData['table'] = [];
                            }else{
                                // ERROR FIRST SET
                                if (this.state.countRenderPage <= 2) {
                                    if (this.state.countCodeSeg == 1) {
                                        formData['table'] = this.state.dataGet;
                                        this.state.countRenderPage++;
                                    }
                                } else {
                                    this.state.dataGet = formData['table'];
                                }
                            }
                        }

                        const RemoveRowTable = (i) => {
                            let dataState = formData['table'];
                            this.setState({ dataGet: "" })
                            var removed = dataState.splice(i, 1);
                            dataState.sort();
                            this.setState({ dataGet: dataState })
                            formData['table'] = dataState;
                        }
                
                        const addRowTable = (source) => {
                            let dataState = formData['table'];
                            let defaultSetData = this.state.defaultData;
                            this.setState({ dataGet: "" })
                            let dataStateAdd = {
                                fullCodigo: defaultSetData.fullCodigo + alphabet[this.state.countCodeSeg],
                                abInicio: "",
                                abFin: "",
                                pInicio: "",
                                pFin: "",
                                departamentos: defaultSetData.departamentos,
                                municipios: defaultSetData.municipios,
                                latitud: "",
                                latitud2: "",
                                longitud: "",
                                longitud2: "",
                                alturaIni: "",
                                alturaFin: "",
                                tipoCalzada: "",
                                tipoSegmento: "",
                                tipoSuperficie: "",
                                segmentoNombre: "",
                                calzadaNombre: "",
                                superficieNombre: "",
                            };
                            if (this.state.countCodeSeg == 0) {
                                dataStateAdd.latitud = defaultSetData.latitud;
                                dataStateAdd.longitud = defaultSetData.longitud;
                                dataStateAdd.alturaIni = defaultSetData.alturaIni;
                            } else {
                                let countLastdataget = dataState.length - 1;
                                dataStateAdd.latitud = dataState[countLastdataget].latitud2;
                                dataStateAdd.longitud = dataState[countLastdataget].longitud2;
                                dataStateAdd.alturaIni = dataState[countLastdataget].alturaFin;
                            }

                            dataState.push(dataStateAdd);
                            this.setState({ dataGet: dataState }) 
                            formData['table'] = dataState;
                            this.state.countCodeSeg++;
                        }

                        const saveTable = () => {
                            let firstSegSave = 0;
                            let dataTable = formData['table'];
                            let tramoSelected = formData['tramo'];
                            let uniFuncSelected = formData['unidadFuncional'];
                            let countLastItemDataTable = dataTable.length - 1;
                            let idsSend = this.state.tranformarSegIds;
                            dataTable.forEach((element, j ) => {
                                let codigo = element['fullCodigo'].split("::").pop();
                                let absIni = element['absSegIni'].replace("K", "").replace("+", ".");
                                let absFin = element['absSegFin'].replace("K", "").replace("+", ".");
                                let prIni = element['prSegIni'].replace("PR", "").replace("+", ".");
                                let prFin = element['prSegFin'].replace("PR", "").replace("+", ".");
                                let arraySave = {
                                    "codigo": codigo,
                                    "pInicio": prIni,
                                    "pFin": prFin,
                                    "abInicio": absIni,
                                    "abFin": absFin,
                                    "tipoCalzada": element['tipoCalzada'],
                                    "tipoSuperficie": element['tipoSuperficie'],
                                    "municipio": element['municipios'],
                                    "tramo": tramoSelected,
                                    "tipoElemento": "SGM",
                                    "ubicaciones": [
                                      {lng: element['longitud'], lat: element['latitud']},
                                      {lng: element['longitud2'], lat: element['latitud2']}
                                    ],
                                    "tipoSegmento": element['tipoSegmento'],
                                    "alturaIni": element['alturaIni'],
                                    "alturaFin": element['alturaFin'],
                                    "estado": true,
                                    "segmentoNombre":  element['segmentoNombre'],
                                    "calzadaNombre":  element['calzadaNombre'],
                                    "superficieNombre":  element['superficieNombre'],
                                  };

                                let requestOptions = {
                                    method: 'POST',
                                    headers: { 'Content-Type': 'application/json' , 'authorization': 'Bearer ' + localStorage.getItem('token')},
                                    body: JSON.stringify(arraySave)
                                };
                                fetch(urlAppjs + "/segmentos", requestOptions)
                                    .then(response => response.json())
                                    .then((dataRequest) => {
                                        if (j == 0) {
                                            firstSegSave = dataRequest.id;
                                            const dataSend = new FormData();
                                            dataSend.append('idNew', firstSegSave);
                                            dataSend.append('idReplaces', idsSend);
                                            let requestOptionsReplace = {
                                                method: 'POST',
                                                headers: {'authorization': 'Bearer ' + localStorage.getItem('token')},
                                                body: dataSend
                                            };
                                            fetch(urlAppjs + "/tranformationSeg", requestOptionsReplace)
                                                .then(response => response.json())
                                                .then((dataRequestInventario) => {
                                                    if (dataRequestInventario == "Inventario reasignado") {
                                                        return window.location.replace(Url + 'segmentos');
                                                    }
                                                });
                                        }
                                    });
                            });                            
                        }

                        return (
                            <div>
                                <Grid container spacing={24}>
                                    <br/><br/>
                                    <Grid item xs={12}>
                                        <Button variant="outlined" color="primary" label="dynatest.resources.transformacion_segmentos.button.generar"  onClick={saveTable}></Button>                        
                                    </Grid>
                                </Grid>

                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width="15%" style={{ width: '15%' }} component="th" align="right" >{translate('dynatest.resources.transformacion_segmentos.table.nameColumns.codigo')}</TableCell>
                                            <TableCell style={{ width: '5%' }} component="th" align="right">{translate('dynatest.resources.transformacion_segmentos.table.nameColumns.absInicio')}</TableCell>
                                            <TableCell style={{ width: '5%' }} component="th" align="right">{translate('dynatest.resources.transformacion_segmentos.table.nameColumns.absFinal')}</TableCell>
                                            {/* <TableCell style={{ width: '4%' }} component="th" align="right">Longitud</TableCell> */}
                                            <TableCell style={{ width: '5%' }} component="th" align="right">{translate('dynatest.resources.transformacion_segmentos.table.nameColumns.prInicio')}</TableCell>
                                            <TableCell style={{ width: '5%' }} component="th" align="right">{translate('dynatest.resources.transformacion_segmentos.table.nameColumns.prFinal')}</TableCell>
                                            <TableCell style={{ width: '10%' }} component="th" align="right">{translate('dynatest.resources.transformacion_segmentos.table.nameColumns.departamento')}</TableCell>
                                            <TableCell style={{ width: '10%' }} component="th" align="right">{translate('dynatest.resources.transformacion_segmentos.table.nameColumns.municipio')}</TableCell>
                                            <TableCell style={{ width: '5%' }} component="th" align="right">{translate('dynatest.resources.transformacion_segmentos.table.nameColumns.latitudInicial')}</TableCell>
                                            <TableCell style={{ width: '5%' }} component="th" align="right">{translate('dynatest.resources.transformacion_segmentos.table.nameColumns.longitudInicial')}</TableCell>
                                            <TableCell style={{ width: '5%' }} component="th" align="right">{translate('dynatest.resources.transformacion_segmentos.table.nameColumns.alturaInicial')}</TableCell>
                                            <TableCell style={{ width: '5%' }} component="th" align="right">{translate('dynatest.resources.transformacion_segmentos.table.nameColumns.latitudFinal')}</TableCell>
                                            <TableCell style={{ width: '5%' }} component="th" align="right">{translate('dynatest.resources.transformacion_segmentos.table.nameColumns.longitudFinal')}</TableCell>
                                            <TableCell style={{ width: '5%' }} component="th" align="right">{translate('dynatest.resources.transformacion_segmentos.table.nameColumns.alturaFinal')}</TableCell>
                                            <TableCell style={{ width: '5%' }} component="th" align="right">{translate('dynatest.resources.transformacion_segmentos.table.nameColumns.tipoCalzada')}</TableCell>
                                            <TableCell style={{ width: '5%' }} component="th" align="right">{translate('dynatest.resources.transformacion_segmentos.table.nameColumns.tipoSegmento')}</TableCell>
                                            <TableCell style={{ width: '5%' }} component="th" align="right">{translate('dynatest.resources.transformacion_segmentos.table.nameColumns.tipoSuperficie')}</TableCell>
                                            <TableCell style={{ width: '3%' }} component="th" align="right"></TableCell>
                                            <TextInput style={{ display: 'none' }} source={`cant_segmentos`} />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {
                                        this.state.dataGet.map((data, i) => (
                                            <TableRow>
                                                <TableCell align="right" style={{ width: '15%' }}>
                                                    <TextInput label=" "  defaultValue={data.fullCodigo} id={`table[${i}].fullCodigo`} source={`table[${i}].fullCodigo`} style={{ marginLeft: '-5%', paddingRight:'25%', width:'200px' }} />
                                                    {/* <input type="text" label=" " class="form-control" value={data.fullCodigo} id={`table[${i}].fullCodigo`} source={`table[${i}].fullCodigo`} style={{ marginLeft: '-5%', paddingRight:'25%', width:'200px' }} /> */}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <PRInput label=" " minValue="4" maxValue="7" defaultValue={data.abInicio} id={`table[${i}].absSegIni`} source={`table[${i}].absSegIni`} fullWidth  />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <PRInput label=" " defaultValue={data.abFin} id={`table[${i}].absSegFin`} source={`table[${i}].absSegFin`} fullWidth  />
                                                </TableCell>
                                                {/* <TableCell align="right">
                                                    {data.pFin != "" && data.pInicio != "" &&
                                                        <NumberFormat
                                                        source={`table[${i}].longitudCalc`}
                                                        label=" "
                                                        value={(data.pFin - data.pInicio).toFixed(1)}
                                                        displayType={'text'}
                                                    />
                                                    }
                                                </TableCell> */}
                                                <TableCell align="right">
                                                    <PInput label=" " defaultValue={data.pInicio} id={`table[${i}].prSegIni`} source={`table[${i}].prSegIni`} fullWidth  />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <PInput label=" " defaultValue={data.pFin} id={`table[${i}].prSegFin`} source={`table[${i}].prSegFin`} fullWidth  />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <ReferenceInput label=" " source={`table[${i}].departamentos`} reference="departamentos" fullWidth 
                                                        sort={{ field: 'nombre', order: 'ASC' }} filterToQuery={searchText => ({ nombre: searchText })}>
                                                        <AutocompleteInput optionText="nombre" defaultValue={data.departamentos}/>
                                                    </ReferenceInput>
                                                </TableCell>
                                                <TableCell align="right">
                                                    {data.departamentos != "" &&
                                                        <ReferenceInput label=" " source={`table[${i}].municipios`} reference="municipios" filter={{ departamento:  data.departamentos}}
                                                            sort={{ field: 'nombre', order: 'ASC' }} fullWidth filterToQuery={searchText => ({ nombre: searchText })}>
                                                            <AutocompleteInput optionText="nombre" defaultValue={data.municipios}/>
                                                        </ReferenceInput>
                                                    }
                                                    
                                                    {/* <TextInput label=" " defaultValue={data.municipios} id={`table[${i}].municipios`} source={`table[${i}].municipios`} fullWidth /> */}
                                                </TableCell>
                                                <TableCell align="right">
                                                    <NumberInput label=" " defaultValue={data.latitud} id={`table[${i}].latitud`} source={`table[${i}].latitud`} fullWidth/>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <NumberInput label=" " defaultValue={data.longitud} id={`table[${i}].longitud`} source={`table[${i}].longitud`} fullWidth />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <NumberInput label=" " defaultValue={data.alturaIni} id={`table[${i}].alturaIni`} source={`table[${i}].alturaIni`} fullWidth />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <NumberInput label=" " defaultValue={data.latitud2} id={`table[${i}].latitud2`} source={`table[${i}].latitud2`} fullWidth />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <NumberInput label=" " defaultValue={data.longitud2} id={`table[${i}].longitud2`} source={`table[${i}].longitud2`} fullWidth />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <NumberInput label=" " defaultValue={data.alturaFin} id={`table[${i}].alturaFin`} source={`table[${i}].alturaFin`} fullWidth />
                                                </TableCell>
                                                <TableCell align="right">
                                                    <ReferenceInput
                                                        source={`table[${i}].tipoCalzada`}
                                                        reference="tipo_calzadas"
                                                        label=" "
                                                        sort={{ field: 'customOrder', order: 'ASC' }}
                                                    >
                                                        <SelectInput optionText="nombre" value={data.tipoCalzada}
                                                            inputProps={{
                                                                onChange: (e) => {
                                                                dispatch(change(REDUX_FORM_NAME, `table[${i}].tipoCalzada`, e.target.value));
                                                                dispatch(change(REDUX_FORM_NAME, `table[${i}].calzadaNombre`, e.currentTarget.innerText));
                                                                }
                                                            }}
                                                        />
                                                    </ReferenceInput>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <ReferenceInput
                                                        source={`table[${i}].tipoSegmento`}
                                                        reference="tipo_segmentos"
                                                        label=" "
                                                        sort={{ field: 'customOrder', order: 'ASC' }}
                                                    >
                                                        <SelectInput optionText="nombre" value={data.tipoSegmento}
                                                            inputProps={{
                                                                onChange: (e) => {
                                                                  dispatch(change(REDUX_FORM_NAME, `table[${i}].tipoSegmento`, e.target.value));
                                                                  dispatch(change(REDUX_FORM_NAME, `table[${i}].segmentoNombre`, e.currentTarget.innerText));
                                                                }
                                                              }}
                                                        />
                                                    </ReferenceInput>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <ReferenceInput
                                                        source={`table[${i}].tipoSuperficie`}
                                                        reference="tipo_elementos"
                                                        label=" "
                                                        filter={{ sistema: 'CLZ' }}
                                                        sort={{ field: 'customOrder', order: 'ASC' }}
                                                        // onChange={[CalzadaCodigo(i)]}
                                                        
                                                    >
                                                        <SelectInput optionText="nombre" value={data.tipoSuperficie} 
                                                            inputProps={{
                                                                onChange: (e) => {
                                                                  dispatch(change(REDUX_FORM_NAME, `table[${i}].tipoSuperficie`, e.target.value));
                                                                  dispatch(change(REDUX_FORM_NAME, `table[${i}].superficieNombre`, e.currentTarget.innerText));
                                                                }
                                                              }}
                                                        />
                                                    </ReferenceInput>
                                                </TableCell>
                                                <TableCell padding="none">
                                                    <ButtonMaterial type="button" id={`table[${i}].check`} name={`check`} onClick={() => RemoveRowTable(i)} ><RemoveCircleOutlineIcon/> {translate('dynatest.resources.transformacion_segmentos.button.remover')} </ButtonMaterial>

                                                    {/* <ButtonMaterial type="button" onChange={RemoveRowTable(i)}><RemoveCircleOutlineIcon/></ButtonMaterial> */}
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    }
                                    </TableBody>
                                </Table>


                                <ButtonMaterial type="button" onClick={addRowTable}>
                                    <AddCircleOutlineIcon/> {translate('dynatest.resources.transformacion_segmentos.button.agregar')}
                                </ButtonMaterial>
                                {/* <Button onClick={() => addFileTable}></Button> */}
                            </div>
                        )
                    }

                }
            </FormDataConsumer >
        </div>               
        )

    }
}

export default translate(TablaTransformacionSegmentos);