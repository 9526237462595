export const sources = {
    ZINT: "elemento_alrededors", SEPD: "elemento_alrededors", OBST: "elemento_alrededors", MARG: "elemento_alrededors",
    COMR: "elemento_alrededors", ACCS: "elemento_alrededors", OCPN: "elemento_alrededors",

    PART: "adoquin_detalles", POTR: "otro_detalles", PFLEX: "flexible_detalles", PRIGD: "rigido_detalles",

    ZCOR: "elemento_drenajes", POZT: "elemento_drenajes", DREN: "elemento_drenajes", DESC: "elemento_drenajes",
    CUNT: "elemento_drenajes", BOXC: "elemento_drenajes", BAJT: "elemento_drenajes", ALCT: "elemento_drenajes",

    INIV: "otros_elementos", IDES: "otros_elementos", CASC: "otros_elementos",

    PNTE: "puentes", PNTEP: "puentes", MCON: "elemento_contencions", ESTE: "elemento_contencions", CONT: "elemento_contencions",
    TNEL: "tuneles", PTON: "elemento_pontons",

    SOS: "elemento_reds", SEM: "elemento_reds", PST: "elemento_reds", PMZ: "elemento_reds", LUM: "elemento_reds",
    ETD: "elemento_reds", ENL: "elemento_reds", CTV: "elemento_reds", CSP: "elemento_reds", BDP: "elemento_reds",
    ANT: "elemento_reds", DCT: "elemento_reds", REE: "elemento_reds",

    TPVH: "seguridads", PRTP: "seguridads", DEFMT: "seguridads", CERC: "seguridads", CAPT: "seguridads",
    BRRJ: "seguridads", BRRF: "seguridads", BRRC: "seguridads", BORD: "seguridads",

    DEM: "elemento_senalizacions", OTR: "elemento_senalizacions", SH: "elemento_senalizacions", SV: "elemento_senalizacions",

    TRAF: "elemento_traficos",


    ALR: "elemento_alrededors",
    DRE: "elemento_drenajes",
    OTR: "otros_elementos",
    RDS: "elemento_reds",
    SEG: "seguridads",
    SEN: "elemento_senalizacions",

}