import React from 'react';
import NumberFormat from 'react-number-format';


export default function deFormatter(props){
    const { inputRef, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            
            decimalSeparator=','
            decimalScale={3}
            fixedDecimalScale={true}
            allowNegative={false}            
        />
      );
}