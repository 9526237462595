import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    BulkDeleteWithConfirmButton, ReferenceArrayInput, AutocompleteArrayInput, TextInput, ExportButton, SimpleForm, Toolbar, CardActions, List, Datagrid, TextField, EditButton, ShowButton, ReferenceInput, Filter, FormDataConsumer,
    SelectInput, CreateButton
} from 'react-admin';
import { Fragment } from 'react';
import { PRField } from './../../../fields/PRField';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import { PRInput } from '../../../inputs/PRInput';
import config from '../../../config/config';
// import FiltroPeru from '../../../filtro/FiltroPeru'
import { ModalMapa } from '../../Mapa/ModalMapa'
import GetAppIcon from '@material-ui/icons/GetApp';
import PublicIcon from '@material-ui/icons/Public';

let urlAppjs = config.UrlServer;


// filters={<FiltroSenalizacion/>
export const ElementoSenalizacionPeruList = props => {
    let UnidadFuncionalFiltro = [];
    let Tramo = [];
    let SegmentoA = '';
    let Elemento = '';
    let TipoElemento = '';
    let SentidoTransito = '';
    let AbInicioA = '';
    let AbFinA = '';
    let AbInicioReports = '';
    let AbFinReports = '';
    let ProyectId = localStorage.getItem('id_project');

    function SimpleDialog(props) {

        const { onClose, open } = props;

        const handleClose = value => {
            onClose(value);
        };

        const handleListItemClick = value => {
            onClose(value);
        };
        const [unidads, setUnidad] = useState('');
        const ValidarUnidad = (value) => {
            if (value != undefined) {
                setUnidad(value)
            }
        }
        const [unidads2, setUnidad2] = useState('');
        const ValidarUnidad2 = (value) => {
            if (value != undefined) {
                setUnidad2(value)
            }
        }
        const [unidads3, setUnidad3] = useState('');
        const ValidarUnidad3 = (value) => {
            if (value != undefined) {
                setUnidad3(value)
            }
        }
        const Unidades = { unidadFuncional: unidads };
        const Unidades2 = { unidadFuncional: unidads2 };
        const Unidades3 = { unidadFuncional: unidads3 };

        const [tramos, setTramo] = useState('');
        const ValidarTramo = (value) => {
            if (value != undefined) {
                setTramo(value)
            }
        }
        const [tramos2, setTramo2] = useState('');
        const ValidarTramo2 = (value) => {
            if (value != undefined) {
                setTramo2(value)
            }
        }
        const Tramoss = { tramo: tramos };
        const Tramoss2 = { tramo: tramos2 };
        const [segmentos, setSegmento] = useState('');
        const ValidarSegmento = (value) => {
            if (value != undefined) {
                setSegmento(value)
            }
        }
        const [segmentos2, setSegmento2] = useState('');
        const ValidarSegmento2 = (value) => {
            if (value != undefined) {
                setSegmento2(value)
            }
        }

        const [tipoElementoR, setTipoElementoR] = useState('');
        const ValidarTipoElemento = (value) => {
            if (value != undefined) {
                setTipoElementoR(value)
            } else {
                setTipoElementoR("")
            }
        }

        const [reportCheck, setReportCheck] = useState();
        const ValidateReport = (value) => {
            if (value != undefined) {
                if (typeof value === 'string') {
                    setReportCheck(value)
                } else {
                    let lenghtObject = Object.keys(value);
                    let valor = '';
                    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
                    setReportCheck(valor)
                }
            }
        }

        const [absIni, setAbsIni] = useState('');
        const ValidateAbsIni = (value) => {
            if (value != undefined) {
                let absIniComparate = value['target']['value'];
                if (absIniComparate != "") {
                    absIniComparate = absIniComparate.split("K");
                    absIniComparate = absIniComparate[1].replace("+", ".");
                }
                setAbsIni(absIniComparate)
            }
            return;
        }

        const [absFin, setAbsFin] = useState('');
        const ValidateAbsFin = (value) => {
            if (value != undefined) {
                let absFinComparate = value['target']['value'];
                if (absFinComparate != "") {
                    absFinComparate = absFinComparate.split("K");
                    absFinComparate = absFinComparate[1].replace("+", ".");
                }
                setAbsFin(absFinComparate)
            }
            return;
        }

        const CustomToolbar = ({ basePath, data, ...props }) => (
            <Toolbar {...props} >
                <Button variant="secondary" id="generarInforme" onClick={handleListItemClick2} style={{ backgroundColor: '#3f51b5', color: '#fff' }}>
                    <AddIcon /> Generar Reporte
                </Button>

            </Toolbar>
        );


        const handleListItemClick2 = props => {
            const dataPost = new FormData();
            if (reportCheck === "Report1") {
                if (unidads == "") {
                    alert("Debe seleccionar la Unidad Funcional");
                    return;
                }
                if (tramos == "") {
                    alert("Debe seleccionar el tramo");
                    return;
                }
                if (segmentos == "") {
                    alert("Debe seleccionar el segmentos");
                    return;
                }
                dataPost.append('reportCheck', reportCheck);
                dataPost.append('unidads', unidads);
                dataPost.append('tramos', tramos);
                dataPost.append('segmentos', segmentos);

            } else if (reportCheck === "Report2") {
                if (unidads2 == "") {
                    alert("Debe seleccionar la Unidad Funcional");
                    return;
                }
                if (absIni == "") {
                    alert("Debe agregar el inicio de las progresivas");
                    return;
                }
                if (absFin == "") {
                    alert("Debe agregar el inicio de las progresivas");
                    return;
                } else {
                    let absIniComparate = document.getElementById('absIni').value;
                    let absFinComparate = document.getElementById('absFin').value;

                    absIniComparate = absIniComparate.split("K");
                    absIniComparate = absIniComparate[1].replace("+", ".");
                    absFinComparate = absFinComparate.split("K");
                    absFinComparate = absFinComparate[1].replace("+", ".");
                    absIniComparate = parseFloat(absIniComparate);
                    absFinComparate = parseFloat(absFinComparate);
                    if (absIniComparate > absFinComparate) {
                        alert("La progresiva final no debe ser mayor que la progresiva inicial");
                        return;
                    } else {
                        setAbsIni(absIniComparate);
                        setAbsFin(absFinComparate);
                    }
                }

                dataPost.append('reportCheck', reportCheck);
                dataPost.append('unidads2', unidads2);
                dataPost.append('absIni', absIni);
                dataPost.append('absFin', absFin);
            } else {
                if (unidads3 == "") {
                    alert("Debe seleccionar la Unidad Funcional");
                    return;
                }
                if (tramos2 == "") {
                    alert("Debe seleccionar el tramo");
                    return;
                }
                if (segmentos2 == "") {
                    alert("Debe seleccionar el segmentos");
                    return;
                }
                dataPost.append('reportCheck', reportCheck);
                dataPost.append('unidads', unidads3);
                dataPost.append('tramos', tramos2);
                dataPost.append('segmentos', segmentos2);
                dataPost.append('tipoElemento', tipoElementoR);
            }

            fetch(urlAppjs + '/reportSenalizacion/', {
                method: 'POST',
                body: dataPost
            })
                .then(res => res.text())
                .then(datos => {
                    if (datos == "Sin datos") {
                        alert("No se encontraron datos.");
                    } else {
                        var myWindow = window.open('', '', 'width=800,height=600');
                        if (myWindow == null || typeof (myWindow) == 'undefined') {
                            alert('Por favor deshabilita el bloqueador de ventanas emergentes y vuelve a hacer clic en "Generar Reporte".');
                        }
                        else {
                            myWindow.document.write(datos);
                            myWindow.document.close();
                            myWindow.focus();
                            setTimeout(function () { myWindow.print(); }, 2000);
                        }
                    }
                });

        };
        if (props.tipo === 'MAPA') {

            return (
                <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} fullWidth={'fullWidth'} maxWidth={'md'}>
                    <DialogTitle id="simple-dialog-title">Mapa general</DialogTitle>
                    <SimpleForm toolbar={false}>
                        <ModalMapa
                            unidadFuncional={UnidadFuncionalFiltro}
                            tramo={Tramo}
                            segmento={SegmentoA}
                            AbInicio={AbInicioA}
                            AbFin={AbFinA}
                            Elemento={Elemento}
                            TipoElemento={TipoElemento}
                            SentidoTransito={SentidoTransito}
                        />
                    </SimpleForm>
                </Dialog>
            )
        } else if (props.tipo === 'REPORTES') {
            return (
                <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} fullWidth={'fullWidth'} maxWidth={'md'}>
                    <DialogTitle id="simple-dialog-title">Crear Reporte</DialogTitle>
                    <SimpleForm toolbar={<CustomToolbar />}>
                        <Grid container spacing={24}>
                            <Grid item xs={4} style={{ textAlign: "right" }}>
                            </Grid>
                            <Grid item xs={4}>
                                <SelectInput label="Tipo de reportes" source="reporte" validate={[ValidateReport]} fullWidth
                                    choices={[
                                        { id: "Report1", name: 'Reporte por Segmentos' },
                                        { id: "Report2", name: 'Reporte por Progresivas' },
                                        { id: "Report5", name: 'Reporte de condición global' },
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={4}>
                            </Grid>
                            <br></br>
                            {reportCheck == "Report1" &&
                                <Grid item xs={4}>
                                    <ReferenceInput label="Unidad Funcional" source="unidadFuncional" reference="unidad_funcionals"
                                        validate={[ValidarUnidad]}
                                        sort={{
                                            field: "codigo",
                                            order: "ASC"
                                        }}
                                    >
                                        <SelectInput optionText="codigo" fullWidth />
                                    </ReferenceInput>
                                </Grid>
                            }
                            {reportCheck == "Report1" &&
                                <Grid item xs={4}>
                                    {unidads &&
                                        <ReferenceInput label="Tramo" source="tramo" reference="tramos" validate={[ValidarTramo]}
                                            filter={Unidades} >
                                            <SelectInput optionText="codigo" fullWidth />
                                        </ReferenceInput>
                                    }
                                </Grid>
                            }
                            {reportCheck == "Report1" &&
                                <Grid item xs={4}>
                                    {tramos &&
                                        <ReferenceInput label="Segmento" source="segmento" reference="segmentos" validate={[ValidarSegmento]}
                                            filter={Tramoss} sort={{ field: 'codigo', order: 'ASC' }} >
                                            <SelectInput optionText="codigo" fullWidth />
                                        </ReferenceInput>
                                    }
                                </Grid>
                            }
                            {reportCheck == "Report1" &&
                                <br></br>
                            }
                            {reportCheck == "Report2" &&
                                <Grid item xs={4}>
                                    <ReferenceInput label="Unidad Funcional" source="unidadFuncional2" reference="unidad_funcionals"
                                        validate={[ValidarUnidad2]}
                                        sort={{
                                            field: "codigo",
                                            order: "ASC"
                                        }}
                                    >
                                        <SelectInput optionText="codigo" fullWidth />
                                    </ReferenceInput>
                                </Grid>
                            }
                            {reportCheck == "Report2" &&
                                <Grid item xs={4}>
                                    <PRInput label="Progresiva Inicial" id="absIni" onChange={ValidateAbsIni} source="absIni"
                                        fullWidth />
                                </Grid>
                            }
                            {reportCheck == "Report2" &&
                                <Grid item xs={4}>
                                    <PRInput label="Progresiva Final" id="absFin" onChange={ValidateAbsFin} source="absFin"
                                        fullWidth />
                                </Grid>
                            }
                            {reportCheck == "Report5" &&
                                <Grid item xs={4}>
                                    <ReferenceInput label="Unidad Funcional" source="unidadFuncional3" reference="unidad_funcionals"
                                        validate={[ValidarUnidad3]}
                                        sort={{
                                            field: "codigo",
                                            order: "ASC"
                                        }}
                                    >
                                        <SelectInput optionText="codigo" fullWidth />
                                    </ReferenceInput>
                                </Grid>
                            }
                            {reportCheck == "Report5" &&
                                <Grid item xs={4}>
                                    {unidads3 &&
                                        <ReferenceInput label="Tramo" source="tramo2" reference="tramos" validate={[ValidarTramo2]}
                                            filter={Unidades3} >
                                            <SelectInput optionText="codigo" fullWidth />
                                        </ReferenceInput>
                                    }
                                </Grid>
                            }
                            {reportCheck == "Report5" &&
                                <Grid item xs={4}>
                                    {tramos2 &&
                                        <ReferenceInput label="Segmento" source="segmento2" reference="segmentos" validate={[ValidarSegmento2]}
                                            filter={Tramoss2} sort={{ field: 'codigo', order: 'ASC' }} >
                                            <SelectInput optionText="codigo" fullWidth />
                                        </ReferenceInput>
                                    }
                                </Grid>
                            }
                            {reportCheck == "Report5" &&
                                <br></br>
                            }
                            {reportCheck == "Report5" &&
                                <Grid item xs={4}>
                                    <ReferenceInput label="Tipo de elemento" source="tipoSenalPeru2" reference="tipo_senal_perus" sort={{ field: 'nombre', order: 'ASC' }}
                                        validate={[ValidarTipoElemento]} allowEmpty>
                                        <SelectInput optionText="nombre" fullWidth />
                                    </ReferenceInput>
                                </Grid>
                            }
                        </Grid>
                    </SimpleForm>
                </Dialog>
            );
        } else {
            return (<div></div>)
        }

    }

    SimpleDialog.propTypes = {
        onClose: PropTypes.func.isRequired,
        open: PropTypes.bool.isRequired,
    };

    const PostBulkActionButtons = props => (
        <Fragment>
            <BulkDeleteWithConfirmButton {...props} />
        </Fragment>
    );

    const styles = {
        ocultar: {
            display: "none",
        },
    };
    const FiltroSenalizacion = withStyles(styles)(
        ({ classes, ...props }) => {



            return (
                <Filter {...props}>
                    <ReferenceInput label="Elemento" source="tipoElementoSenalPeru" reference="tipo_elemento_senals" alwaysOn>
                        <SelectInput optionText="nombre" />
                    </ReferenceInput>
                    <FormDataConsumer className={classes.ocultar} alwaysOn>
                        {
                            ({ formData, ...rest }) => (
                                <ReferenceInput label="Tipo de elemento" source="tipoSenal" reference="tipo_senals" filter={{ tipoElementoSenalPeru: formData.tipoElementoSenalPeru }} {...rest}>
                                    <SelectInput optionText="nombre" fullWidth />
                                </ReferenceInput>
                            )
                        }
                    </FormDataConsumer>
                    <ReferenceInput label="Tipo de elemento" source="tipoSenal" reference="tipo_senals" alwaysOn>
                        <SelectInput optionText="nombre" fullWidth />
                    </ReferenceInput>

                    <SelectInput source="estado" choices={[
                        { id: "Existente", name: 'Existente' },
                        { id: "Nuevo", name: 'Nuevo' },
                        { id: "Reemplazo", name: 'Reemplazo' },
                        { id: "Bueno", name: 'Bueno' },
                        { id: "Malo", name: 'Malo' },
                        { id: "Regular", name: 'Regular' },
                    ]} fullWidth alwaysOn
                    />

                    {/* <PRInput id='abInicio' label="Progresiva inicio" fullWidth source='abInicio' validate={AbInicio}/> */}

                </Filter>
            )
        });


    let url = window.location.hash;

    //EXPORTAR EXCEL
    const exporter = (records, fetchRelatedRecords) => {
        const data = new FormData();
        data.append('UnidadFuncionalFiltro', UnidadFuncionalFiltro);
        data.append('Tramo', Tramo);
        data.append('SegmentoA', SegmentoA)
        data.append('Elemento', Elemento);
        data.append('TipoElemento', TipoElemento);
        data.append('SentidoTransito', SentidoTransito);
        data.append('AbInicioA', AbInicioA);
        data.append('AbFinA', AbFinA);
        data.append('ProyectId', ProyectId);

        fetch(urlAppjs + '/exportExcel/', {
            method: 'POST',
            body: data
        })
            .then(res => res.json())
            .then(datos => {
                const URL = urlAppjs + "/" + datos
                window.location.href = URL;
            });
    };

    const PostEditActions = ({ basePath, data, resource }) => {
        const [open, setOpen] = React.useState(false);
        const [tipo, setTipo] = React.useState('');
        const [conteo, setConteo] = React.useState(0);

        if (conteo === 0 && window.location.hash === '#/elemento_senalizacion_perus?map') {
            setOpen(true);
            setTipo('MAPA')
            setConteo(1)
        }
        const handleClickOpen = (value) => {
            let valor = value.target.innerText
            setOpen(true);
            setTipo(valor)
        };

        const handleClose = value => {
            setOpen(false);
        };

        return (
            <CardActions>
                <Button color="primary" open={true} tipo={'MAPA'} onClick={handleClickOpen} open={true} tipo={'MAPA'} value='MAPA'>
                    <PublicIcon /> Mapa
                    </Button>
                <CreateButton basePath={basePath} style={{ paddingTop: "13px", marginRight: "20px" }} />
                <Button color="primary" onClick={handleClickOpen} value='reporte'>
                    <AddIcon /> Reportes
                    </Button>
                <SimpleDialog open={open} tipo={tipo} onClose={handleClose} />
                <ExportButton basePath={basePath} onClick={exporter} />
            </CardActions>
        )



    };
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = value => {
        setOpen(false);
    };


    // const AbInicio = (value)  => {

    //     if(value !== null && value !== '' && value !== undefined){
    //          let valor=  value;
    //         //  valor =valor.value;
    //          let valor1 = valor.split('K')            
    //          valor1 =  valor1[1].split('+')            
    //          valor = valor1[0] +'.'+ valor1[1]
    //          setAbInicio(valor)                      

    //     }                
    //  }
    const [abInicio, setAbInicio] = useState();
    const AbInicio = (value) => {
        AbInicioA = value;
        if (value != undefined) {
            AbInicioReports = value.replace(".", "+");
        }

        // let pro = abInicio;
        // if(value !== null && value !== '' && value !== undefined){
        //         let valor=  value;
        //     //  valor =valor.value;
        //         let valor1 = valor.split('K')            
        //         valor1 =  valor1[1].split('+')            
        //         valor = valor1[0] +'.'+ valor1[1]
        //         setAbInicio(valor)    
        //         AbInicioA = valor;                                                      
        // } 

    }

    const [abFin, setAbFin] = useState();
    const AbFin = (value) => {
        AbFinA = value;
        if (value != undefined) {
            AbFinReports = value.replace(".", "+");
        }

        //  if(value !== null && value !== ''&& value !== undefined ){
        //     let valor=  value;
        // //  valor =valor.value;
        //     let valor1 = valor.split('K')            
        //     valor1 =  valor1[1].split('+')            
        //     valor = valor1[0] +'.'+ valor1[1]
        //     setAbFin(valor)     
        //     AbFinA = valor;    
        //  }   

    }
    const [reset, isReset] = useState(false)
    const Borrador = () => {
        console.log(UnidadFuncionalFiltro, Tramo)
        setAbFin()
        setAbInicio()
        UnidadFuncionalFiltro = [];
        Tramo = [];
        SegmentoA = '';
        Elemento = '';
        TipoElemento = '';
        SentidoTransito = '';
        AbInicioA = '';
        AbFinA = '';
        AbInicioReports = '';
        AbFinReports = '';
        console.log(UnidadFuncionalFiltro, Tramo)
    }

    const FiltroPeru = (props) => {
        const [uf, setUf] = useState();
        const ufSet = (value) => {
            let filter = [];

            if (value !== undefined) {
                setUf(value);
                UnidadFuncionalFiltro = value;
            }

        }
        const [tramo, setTramo] = useState();
        const tramoSet = (value) => {
            let filter = [];
            if (value !== undefined) {
                setTramo(value);
                Tramo = value;
            }
        }

        const segmentoSet = (value) => {
            SegmentoA = value
        }

        const elementoSet = (value) => {
            Elemento = value
        }

        const tipoElementoSet = (value) => {
            TipoElemento = value
        }

        const transitoSet = (value) => {
            SentidoTransito = value
        }


        return (
            <div>
                <Filter {...props} id='Filtros' >
                    <ReferenceArrayInput
                        id="unidadFuncional"
                        label="Unidad Funcional"
                        source="unidadFuncional"
                        reference="unidad_funcionals"
                        filterToQuery={searchText => ({ codigo: searchText })}
                        sort={{ field: 'codigo', order: 'asc' }}
                        validate={[ufSet]}
                        alwaysOn
                        fullWidth

                    >
                        <AutocompleteArrayInput options={{ fullWidth: true }} optionText="codigo" />
                    </ReferenceArrayInput>
                    <ReferenceArrayInput
                        id="tramo"
                        label="Tramo"
                        source="tramo"
                        alwaysOn
                        reference="tramos"
                        filter={{ unidadFuncional: uf }}
                        validate={[tramoSet]}
                        filterToQuery={searchText => ({ codigo: searchText })}
                        sort={{ field: 'codigo', order: 'ASC', }}>
                        <AutocompleteArrayInput options={{ fullWidth: true }} optionText="codigo" />
                    </ReferenceArrayInput>
                    <ReferenceArrayInput
                        id="segmento"
                        label="Segmento"
                        source="segmento"
                        reference="segmentos"
                        alwaysOn
                        sort={{ field: 'codigo', order: 'ASC' }}
                        filter={{ tramo: tramo }}
                        filterToQuery={searchText => ({ codigo: searchText })}
                        validate={[segmentoSet]}
                    >
                        <AutocompleteArrayInput options={{ fullWidth: true }} optionText="codigo" />
                    </ReferenceArrayInput>

                    <ReferenceInput
                        id="elemento"
                        label="Elemento"
                        source="tipoElementoSenalPeru"
                        reference="tipo_elemento_senal_perus"
                        fullWidth
                        filter={{ complementaria: true }}
                        sort={{ field: 'nombre', order: 'ASC' }}
                        alwaysOn
                        validate={[elementoSet]}
                    >
                        <SelectInput optionText="nombre" fullWidth />
                    </ReferenceInput>
                    <ReferenceInput
                        id="tipoSenalPeru"
                        label="Tipo de elemento"
                        source="tipoSenalPeru"
                        reference="tipo_senal_perus"
                        fullWidth
                        sort={{ field: 'nombre', order: 'ASC' }}
                        alwaysOn
                        validate={[tipoElementoSet]}

                    >
                        <SelectInput optionText="nombre" />
                    </ReferenceInput>
                    <SelectInput id="estado" source="estado" label="Sentido del tránsito" source="sentidoTransito" choices={[
                        { id: "Únicamente Creciente (UC)", name: 'Únicamente Creciente (UC)' },
                        { id: "Únicamente Decreciente (UD)", name: 'Únicamente Decreciente (UD)' },
                    ]} fullWidth alwaysOn validate={[transitoSet]} />
                    <TextInput id="progresivaInicio" label="Progresiva inicio" fullWidth source='abInicio[gte]' validate={[AbInicio]} alwaysOn />
                    {/* <NumberInput  label="Progresiva inicio" fullWidth source='abInicio[gt]'alwaysOn /> */}
                    <TextInput id="progresivaFin" label="Progresiva fin" fullWidth source='abInicio[lte]' validate={[AbFin]} alwaysOn />

                </Filter>
                <Button color="primary" onMouseDown={() => props.setFilters({
                    'abInicio[gte]': '',
                    abFin: '',
                    tipoSenalPeru: '',
                    segmento: '',
                    tramo: '',
                    unidadFuncional: ''
                })} onMouseUp={Borrador} >
                    Limpiar Filtros
            </Button>
            </div>
        )

    }

    const ButtonFicha = ({ record }) => {
        function fichaList(idSenal) {
            var senalId = record['@id'];
            let senalIdReplace = parseInt(senalId.replace("/elemento_senalizacion_perus/", ""));
            const dataPost = new FormData();
            dataPost.append('senalId', senalIdReplace);
            fetch(urlAppjs + '/reportFicha/', {
                method: 'POST',
                body: dataPost
            })
                .then(res => res.text())
                .then(datos => {
                    if (datos == "Sin datos") {
                        alert("No se encontraron datos.");
                    } else {
                        var myWindow = window.open('', '', 'width=800,height=600');
                        if (myWindow == null || typeof (myWindow) == 'undefined') {
                            alert('Por favor deshabilita el bloqueador de ventanas emergentes y vuelve a hacer clic en "Generar Reporte".');
                        }
                        else {
                            myWindow.document.write(datos);
                            myWindow.document.close();
                            myWindow.focus();
                            setTimeout(function () { myWindow.print(); }, 2000);
                        }
                    }
                });

        }
        return (
            <Button variant="secondary" id="generarInforme" onClick={fichaList} style={{ color: '#3f51b5' }}>
                <GetAppIcon /> Ficha
            </Button>
        )
    };

    return (
        <List {...props}
            sort={{ field: 'id', order: 'DESC' }}
            filters={<FiltroPeru />}
            perPage={5} title="Lista de señales"
            actions={<PostEditActions />}
            bulkActionButtons={<PostBulkActionButtons />}
        // filter={{tipoElemento: {sistema: "DRE"}, proyecto: {id:1} , ubicaciones : null }}
        >
            {/* 'abInicio[gt]' :  abInicio,  */}
            <Datagrid>
                <TextField label="Ruta" source="fullCodigo" sortable={false} />
                {/* <ReferenceField label="Elemento" source="tipoElementoSenalPeru" reference="tipo_elemento_senal_perus" sortable={false} linkType={false}>
                    <TextField source="nombre" />
                </ReferenceField>
                <ReferenceField label="Tipo de elemento" source="tipoSenalPeru" reference="tipo_senal_perus" sortable={false} linkType={false}>
                    <TextField source="nombre" />
                </ReferenceField>                                 */}
                <TextField label="Significado de señal" source="senalVerticalPeruA_.nombre" sortable={false} />
                {/* <TextField label="Material" source="material" /> */}
                <PRField label="Progresiva" source="abInicio" />
                <TextField label="Margen" source="margen" />
                <TextField label="Estado" source="estado" />
                <ButtonFicha />
                <ShowButton />
                <EditButton />
            </Datagrid>
        </List>
    )
};

