import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { DisabledInput } from 'react-admin';

class DatosDeterioros extends React.Component {

    Delete(e) {
        const elemento = document.getElementById('Fila' + e)
        elemento.remove()
    }

    render() {
        return (
            <tr id={'Fila' + this.props.IdFila}>
                <td style={{ padding: '5px' }}>
                    <DisabledInput label=" " source={'deterioro_d' + this.props.IdFila} defaultValue={this.props.DeterioroD} style={{ display: 'none' }} />
                    {this.props.DeterioroD}
                </td>
                <td>
                    <DisabledInput label=" " source={'unidad_d' + this.props.IdFila} defaultValue={this.props.UnidadD} style={{ display: 'none' }} />
                    {this.props.UnidadD}
                </td>
                <td>
                    <DisabledInput label=" " source={'sbaja_s' + this.props.IdFila} defaultValue={this.props.SbajaD} style={{ display: 'none' }} />
                    {this.props.SbajaD}
                </td>
                <td>
                    <DisabledInput label=" " source={'sbaja_dt' + this.props.IdFila} defaultValue={this.props.SbajaDT} style={{ display: 'none' }} />
                    {this.props.SbajaDT}
                </td>
                <td>
                    <DisabledInput label=" " source={'smedia_d' + this.props.IdFila} defaultValue={this.props.SmediaD} style={{ display: 'none' }} />
                    {this.props.SmediaD}
                </td>
                <td>
                    <DisabledInput label=" " source={'smedia_dt' + this.props.IdFila} defaultValue={this.props.SmediaDT} style={{ display: 'none' }} />
                    {this.props.SmediaDT}
                </td>
                <td>
                    <DisabledInput label=" " source={'salta_d' + this.props.IdFila} defaultValue={this.props.SaltaD} style={{ display: 'none' }} />
                    {this.props.SaltaD}
                </td>
                <td>
                    <DisabledInput label=" " source={'salta_dt' + this.props.IdFila} defaultValue={this.props.SaltaDT} style={{ display: 'none' }} />
                    {this.props.SaltaDT}
                </td>
                <td>
                    <DisabledInput label=" " source={'total' + this.props.IdFila} defaultValue={this.props.TotalD} style={{ display: 'none' }} />
                    {this.props.TotalD}
                </td>
                <td style={{ color: 'red', cursor: 'pointer' }}>
                    <DeleteForeverIcon onClick={this.Delete.bind(this, this.props.IdFila)} />
                </td>
            </tr>
        )
    }

}
export default DatosDeterioros;

