import React, { useState, Fragment } from 'react';
import {
    FormDataConsumer, NumberInput, SelectInput, ReferenceInput, AutocompleteArrayInput, ReferenceArrayInput,
    TextInput, number, AutocompleteInput, REDUX_FORM_NAME, email
} from 'react-admin';
import { Edit } from './../Edit';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
} from 'react-admin';
import { change } from 'redux-form';
import { SimpleForm } from './../SimpleForm';
import { translate } from 'react-admin';



export const Contacto_emergenciaEdit = translate((props) => {
    const { translate } = props;

    const [departamentoss, setDepartamento] = useState('');

    const ValidarDepartamento = (value) => {
        // let lenghtObject = Object.keys(value);
        // let valor = '';        
        // for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                
        setDepartamento(value)
    }

    const Departamento = { departamento: departamentoss };

    const [grupo, setGrupo] = useState('');

    const ValidarGrupo = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setGrupo(value)
    }
    return (
        <Edit title="dynatest.resources.contacto_emergencias.title.editTitle" {...props} >
            <SimpleForm submitOnEnter={false} redirect="list" >
                <Grid container spacing={24}>
                    <Grid item xs={4}>
                        <ReferenceInput
                            label="resources.contacto_emergencias.fields.proyecto"
                            source="proyecto"
                            reference="proyectos"
                            disabled
                            fullWidth
                            validate={[required(), ValidarDepartamento]}
                            filterToQuery={searchText => ({ nombre: searchText })}
                        >
                            <AutocompleteInput options={{ fullWidth: true, disabled: true }} optionText="nombre" fullWidth />
                        </ReferenceInput>
                    </Grid>
                    <Grid item xs={4}>
                        <SelectInput id="grupoIngreso" label="resources.contacto_emergencias.fields.grupo" source="grupo" allowEmpty emptyValue="" fullWidth choices={[
                            { id: 'Alcaldía', name: 'dynatest.resources.contacto_emergencias.select.grupo.alcaldia' },
                            { id: 'Bomberos', name: 'dynatest.resources.contacto_emergencias.select.grupo.bomberos' },
                            { id: 'Centro educativo', name: 'dynatest.resources.contacto_emergencias.select.grupo.centroEducativo' },
                            { id: 'Defensa civil', name: 'dynatest.resources.contacto_emergencias.select.grupo.defensaCivil' },
                            { id: 'Ejército', name: 'dynatest.resources.contacto_emergencias.select.grupo.ejercito' },
                            { id: 'Emisora radial', name: 'dynatest.resources.contacto_emergencias.select.grupo.emisoraRadial' },
                            { id: 'Interventoría', name: 'dynatest.resources.contacto_emergencias.select.grupo.interventoria' },
                            { id: 'Maquinaria', name: 'dynatest.resources.contacto_emergencias.select.grupo.maquinaria' },
                            { id: 'Otros', name: 'dynatest.resources.contacto_emergencias.select.grupo.otros' },
                            { id: 'POB-Administrativa', name: 'dynatest.resources.contacto_emergencias.select.grupo.pOB_Administrativa' },
                            { id: 'POB-Ambulancia', name: 'dynatest.resources.contacto_emergencias.select.grupo.pOB_Ambulancia' },
                            { id: 'POB-Carro taller', name: 'dynatest.resources.contacto_emergencias.select.grupo.pOB_CarroTaller' },
                            { id: 'POB-Grúas', name: 'dynatest.resources.contacto_emergencias.select.grupo.pOB_Gruas' },
                            { id: 'POB-Peajes', name: 'dynatest.resources.contacto_emergencias.select.grupo.pOB_Peajes' },
                            { id: 'POB-Radio Operación', name: 'dynatest.resources.contacto_emergencias.select.grupo.pOB_RadioOperacion' },
                            { id: 'POB-Supervisión vial', name: 'dynatest.resources.contacto_emergencias.select.grupo.pOB_SupervisionVial' },
                            { id: 'Policía', name: 'dynatest.resources.contacto_emergencias.select.grupo.policia' },
                            { id: 'Servicios médicos', name: 'dynatest.resources.contacto_emergencias.select.grupo.serviciosMedicos' },
                            { id: 'Talleres', name: 'dynatest.resources.contacto_emergencias.select.grupo.talleres' },
                        ]} validate={[required()]} validate={ValidarGrupo} />

                    </Grid>
                    <Grid item xs={4}>
                        {grupo === 'Servicios médicos' &&
                            <NumberInput source="nivel" label="resources.contacto_emergencias.fields.nivel" fullWidth validate={[minValue(1), maxValue(5)]} />

                        }

                    </Grid>


                    <Grid item xs={4}>
                        <SelectInput label="resources.contacto_emergencias.fields.identificacion" source="identificacion" allowEmpty emptyValue="" choices={[
                            { id: 'CC', name: 'dynatest.resources.contacto_emergencias.select.identificacion.CC' },
                            { id: 'NIT', name: 'dynatest.resources.contacto_emergencias.select.identificacion.NIT' },
                            { id: 'Otro', name: 'dynatest.resources.contacto_emergencias.select.identificacion.Otro' },

                        ]} fullWidth />
                    </Grid>
                    <Grid item xs={4}>
                        <NumberInput label="resources.contacto_emergencias.fields.numero_identificacion" source="numero_identificacion" fullWidth validate={[required(), minLength(2), maxLength(10), minValue(11111)]} />
                    </Grid>
                    <Grid item xs={4}>
                        <TextInput label="resources.contacto_emergencias.fields.empresa" source="empresa" fullWidth validate={[required(), minLength(2), maxLength(100)]} />
                    </Grid>
                    <Grid item xs={4}>
                        <TextInput label="resources.contacto_emergencias.fields.nombre" source="nombre" fullWidth validate={[minLength(2), maxLength(100)]} />
                    </Grid>
                    <Grid item xs={4}>
                        <TextInput label="resources.contacto_emergencias.fields.apellido" source="apellido" fullWidth validate={[minLength(2), maxLength(100)]} />
                    </Grid>
                    <Grid item xs={4}>
                        <TextInput label="resources.contacto_emergencias.fields.cargo" source="cargo" fullWidth validate={[minLength(1), maxLength(20)]} />
                    </Grid>
                    <Grid item xs={4}>
                        <TextInput label="resources.contacto_emergencias.fields.direccion" source="direccion" fullWidth validate={[required(), minLength(2), maxLength(100)]} />
                    </Grid>
                    {/* <Grid item xs={4}>
                    
                </Grid> */}
                    <Grid item xs={8}>
                        <FormDataConsumer>
                            {
                                ({ formData, dispatch, ...rest }) => (
                                    <Fragment>
                                        <Grid container spacing={24}>
                                            <Grid item xs={6}>
                                                <ReferenceInput
                                                    id="departamento"
                                                    value=""
                                                    label="resources.contacto_emergencias.fields.departamento"
                                                    source="departamento"
                                                    reference="departamentos"
                                                    fullWidth
                                                    sort={{ field: "nombre", order: "ASC" }}
                                                    filterToQuery={searchText => ({ nombre: searchText })}
                                                    onChange={value => dispatch(
                                                        change(REDUX_FORM_NAME, 'municipio', null)
                                                    )}
                                                    validate={[required(), ValidarDepartamento]}
                                                >
                                                    <AutocompleteInput options={{ fullWidth: true }} optionText="nombre" fullWidth />
                                                </ReferenceInput>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <ReferenceArrayInput
                                                    label="resources.contacto_emergencias.fields.municipio"
                                                    source="municipio"
                                                    reference="municipios"
                                                    validate={[required()]}
                                                    sort={{ field: "nombre", order: "ASC" }}
                                                    filterToQuery={searchText => ({ nombre: searchText })}
                                                    filter={{
                                                        departamento: departamentoss
                                                    }}
                                                >
                                                    <AutocompleteArrayInput options={{ fullWidth: true }} optionText="nombre" fullWidth />
                                                </ReferenceArrayInput>
                                            </Grid>
                                        </Grid>
                                    </Fragment>
                                )
                            }
                        </FormDataConsumer>
                    </Grid>


                    <Grid item xs={6}>
                        <TextInput label="resources.contacto_emergencias.fields.telefono_1" source="telefono_1" fullWidth className="labelNumber" validate={[required(), number("Solo Números")]} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput label="resources.contacto_emergencias.fields.telefono_2" source="telefono_2" fullWidth className="labelNumber" validate={[number("Solo Números")]} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput label="resources.contacto_emergencias.fields.correo" source="correo" fullWidth validate={[maxLength(100)], email()} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput label="resources.contacto_emergencias.fields.observaciones" source="observaciones" fullWidth validate={[maxLength(200)]} />
                    </Grid>

                </Grid>
            </SimpleForm>
        </Edit>
    )
});