import React, { useState, Fragment } from 'react';
import {
    minValue, ReferenceInput, maxValue, Toolbar, SaveButton, DateInput, FormTab, TabbedForm, required,
    TextInput, Query, Loading, NumberInput, FormDataConsumer, ArrayInput, ReferenceField, SimpleFormIterator, AutocompleteInput
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { styles } from './../../../EstilosGrid';
import { Edit } from './../../Edit';
import Grid from '@material-ui/core/Grid';
import CancelIcon from '@material-ui/icons/Cancel';
import { withStyles } from '@material-ui/core';
import config from '../../../config/config';
import { Entero } from './../../../helpers/validacionesInputs';
import { translate } from 'react-admin';

import EditElements from './../EditElements';
let urlAppjs = config.UrlServer;

export const FlujoCajaEdit = translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;

    const LinkCancelar = () => {
        let redirect = "elemento_reds"
        return <a href={urlAppjs + redirect} class="cancelar" ><CancelIcon style={{ width: '0.8em', paddingRight: '8px' }} />CANCELAR</a>;
    }

    return (
        <Edit  {...props} title="dynatest.resources.flujo_cajas.title.editTitle" redirect="list" undoable={false}>
            <TabbedForm
                redirect="list"
            //toolbar={<CustomToolbar />}
            >
                {/* ------ ELEMENTO DE FLUJO DE CAJA ------ */}
                <FormTab label="dynatest.resources.flujo_cajas.tabs.identificacion" redirect="list" >

                    <Grid container justify="flex-start" alignItems="flex-end" spacing={24}>
                        <Grid item xs={4}>
                            <ReferenceInput
                                label="resources.flujo_cajas.fields.unidadFuncional"
                                source="unidadFuncional"
                                reference="unidad_funcionals"
                                disabled
                                validate={[required()]}
                            >
                                <AutocompleteInput optionText="fullCodigos" options={{ fullWidth: true, disabled: true }} fullWidth />
                            </ReferenceInput>
                        </Grid>
                        <Grid item xs={4}>
                            <ReferenceInput
                                label="resources.flujo_cajas.fields.nombrePolitica"
                                source="nombrePolitica"
                                reference="politicas_nombres"
                                disabled
                                validate={[required()]}
                            >
                                <AutocompleteInput optionText="nombre" options={{ fullWidth: true, disabled: true }} fullWidth />
                            </ReferenceInput>
                        </Grid>
                        <Grid item xs={4}>
                            <NumberInput label="resources.flujo_cajas.fields.tasaInteres" source="tasaInteres" fullWidth />
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" alignItems="flex-end" spacing={24}>
                        <Grid item xs={6}>
                            <DateInput label="resources.flujo_cajas.fields.fechaInicio" source="fechaInicio" validate={[required(), minValue("2000-01-01", "La fecha minima debe ser 01-01-2000")]} fullWidth />
                        </Grid>
                        <Grid item xs={6}>
                            <NumberInput
                                label="resources.flujo_cajas.fields.years"
                                source="years"
                                validate={[required(), Entero, minValue(0, "Mínimo 1 año"), maxValue(30, "Máximo 30 años")]}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    {/* <Grid container spacing={24}>
                        <Grid item xs={12}>
                        <Typography variant="h4" component="h4" align="center">Al editar valores, la matriz y archivo de excel generados (anteriores a la modificación de datos), seran inválidos. Al consultar nuevamente este registro, será generada una nueva matriz.</Typography> 
                        </Grid>
                    </Grid> */}
                </FormTab>
            </TabbedForm>
        </Edit>
    )

}));
