import React, { useState, Fragment } from 'react';
import { SimpleForm, FormTab, TextInput, DateInput, ReferenceInput, SelectInput, FormDataConsumer, NumberInput, BooleanInput, SimpleFormIterator, ArrayInput, Query, Loading, Error, Datagrid } from 'react-admin';
import { TabbedForm } from './../TabbedForm';
import { PimsCreate } from './../Create';
import { PInput } from './../../inputs/PInput';
import { PRInput } from './../../inputs/PRInput';
import { withStyles } from '@material-ui/core';
import { styles } from './../../EstilosGrid';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { FInput } from './../../inputs/FInput';
import Parametros from './parametros';
import {
    required,

} from 'react-admin';
import {
    abValidationInicio,
    ValorMaximoAbsCreate,
    latMininima,
    lngMininima,

} from './../validacionesInputs';
import { Toolbar, SaveButton } from 'react-admin';
import CancelIcon from '@material-ui/icons/Cancel';
import { translate } from 'react-admin';

export const ParametrosCreate = translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;
    const [tipoParametro, setTipoParametro] = useState('');
    const validarTipoParametro = (value) => {
        setTipoParametro(value);
    }
    let idproyecto = localStorage.getItem("id_project2");
    return (
        <PimsCreate  {...props} >
            <SimpleForm submitOnEnter={false} redirect="list" >
                <Typography component="b" variant="b" style={{ textAlign: "center" }} formClassName={classes.grid_cont12} fullWidth>
                    {translate('resources.parametros_evaluacion.fields.titleParametros')}
                </Typography>
                <Divider formClassName={classes.grid_cont12} fullWidth />
                <SelectInput label="dynatest.resources.parametros_evaluacion.select.indicadores.indicadoresMessage" choices={[
                    { id: "All", name: "dynatest.resources.parametros_evaluacion.select.indicadores.todosParam" },
                    { id: "PFLEX", name: "dynatest.resources.parametros_evaluacion.select.indicadores.indiPavFlex" },
                    { id: "PRIG", name: "dynatest.resources.parametros_evaluacion.select.indicadores.indiPavRig" },
                    { id: "PRIGYFLEX", name: "dynatest.resources.parametros_evaluacion.select.indicadores.indiPavRigFlex" },
                    { id: "PUENTYEST", name: "dynatest.resources.parametros_evaluacion.select.indicadores.indiPuentesEstr" },
                    { id: "TUN", name: "dynatest.resources.parametros_evaluacion.select.indicadores.indiTuneles" },
                    { id: "OPER", name: "dynatest.resources.parametros_evaluacion.select.indicadores.indiOperac" },

                ]} formClassName={classes.grid_cont12} fullWidth validate={validarTipoParametro} defaultValue="All" />
                <Parametros aplicaind={tipoParametro} tipo="crear" />
                <TextInput source="proyecto" defaultValue={idproyecto} formClassName={classes.ocultar} />
            </SimpleForm>
        </PimsCreate>
    )
}));