import React from 'react';
import { TabbedShowLayout, TextField,Tab, DateField , SelectField} from 'react-admin';

import { Show } from './../../../Show';
import { withStyles } from '@material-ui/core';

const styles = {
    first_field: { color: 'blue !important' }
};
const choises = [
    { id: '1', name: 'dynatest.resources.granulometrias.select.tipoMaterial.adoquinEnArcilla' },
        { id: '2', name: 'dynatest.resources.granulometrias.select.tipoMaterial.amadoquinDeConcreto' },
        { id: '3', name: 'dynatest.resources.granulometrias.select.tipoMaterial.afirmado' },
        { id: '4', name: 'dynatest.resources.granulometrias.select.tipoMaterial.baseAsfaltica' },
        { id: '5', name: 'dynatest.resources.granulometrias.select.tipoMaterial.baseDeConcHidraulico' },
        { id: '6', name: 'dynatest.resources.granulometrias.select.tipoMaterial.baseEstOtros' },
        { id: '7', name: 'dynatest.resources.granulometrias.select.tipoMaterial.baseEstbAsfalto' },
        { id: '8', name: 'dynatest.resources.granulometrias.select.tipoMaterial.baseEstEmulsion' },
        { id: '9', name: 'dynatest.resources.granulometrias.select.tipoMaterial.baseGranular' },
        { id: '10', name: 'dynatest.resources.granulometrias.select.tipoMaterial.baseTratada' },
        { id: '11', name: 'dynatest.resources.granulometrias.select.tipoMaterial.concHidraulico' },
        { id: '12', name: 'dynatest.resources.granulometrias.select.tipoMaterial.lechAsfaltica' },
        { id: '13', name: 'dynatest.resources.granulometrias.select.tipoMaterial.mezAbierCaliente' },
        { id: '14', name: 'dynatest.resources.granulometrias.select.tipoMaterial.mezAbierFrio' },
        { id: '15', name: 'dynatest.resources.granulometrias.select.tipoMaterial.mezAsfalGraCaucho' },
        { id: '16', name: 'dynatest.resources.granulometrias.select.tipoMaterial.mezAsfalModPolimeros' },
        { id: '17', name: 'dynatest.resources.granulometrias.select.tipoMaterial.mezDenCaliente' },
        { id: '18', name: 'dynatest.resources.granulometrias.select.tipoMaterial.mezDenFrio' },
        { id: '19', name: 'dynatest.resources.granulometrias.select.tipoMaterial.mezDrenante' },
        { id: '20', name: 'dynatest.resources.granulometrias.select.tipoMaterial.microagloCaliente' },
        { id: '21', name: 'dynatest.resources.granulometrias.select.tipoMaterial.subBaseAsfalEspumado' },
        { id: '22', name: 'dynatest.resources.granulometrias.select.tipoMaterial.subBaseEstabOtros' },
        { id: '23', name: 'dynatest.resources.granulometrias.select.tipoMaterial.subBaseEstabAsfalto' },
        { id: '24', name: 'dynatest.resources.granulometrias.select.tipoMaterial.subBaseEstabCemento' },
        { id: '25', name: 'dynatest.resources.granulometrias.select.tipoMaterial.subBaseEstabGranular' },
        { id: '26', name: 'dynatest.resources.granulometrias.select.tipoMaterial.subraEstabCal' },
        { id: '27', name: 'dynatest.resources.granulometrias.select.tipoMaterial.subraEstabCemento' },
        { id: '28', name: 'dynatest.resources.granulometrias.select.tipoMaterial.subraEstabGeomalla' },
        { id: '29', name: 'dynatest.resources.granulometrias.select.tipoMaterial.subraEstabGeotextil' },
        { id: '30', name: 'dynatest.resources.granulometrias.select.tipoMaterial.subraFina' },
        { id: '31', name: 'dynatest.resources.granulometrias.select.tipoMaterial.subraGranular' },
        { id: '32', name: 'dynatest.resources.granulometrias.select.tipoMaterial.subraCemento' },
];
export const GranulometriaShow = withStyles(styles)(({ classes,record, ...props }) => (
    <Show title="dynatest.resources.granulometrias.title.showTitle" {...props}>
        <TabbedShowLayout>
            <Tab label="dynatest.resources.granulometrias.tabs.informacionBasica">
                <SelectField source="tipo" choices={choises} linkType={false}/>
                <TextField source="especificacion" linkType={false}/>
                <TextField source="granulometria" /> 
            </Tab>
            <Tab label="dynatest.resources.granulometrias.tabs.auditoria" >
                <DateField source="createdAt" fullWidth formClassName={classes.grid_cont4} showTime/> 
                <TextField source="createdBy" fullWidth formClassName={classes.grid_cont4}/>
                <span></span>               
                <DateField source="updatedAt" fullWidth formClassName={classes.grid_cont4} showTime/>
                <TextField source="updatedBy" fullWidth formClassName={classes.grid_cont4}/>
                <span></span>   
            </Tab>
        </TabbedShowLayout>
    </Show>
));