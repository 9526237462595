import React from 'react';
import {FileInput, TextInput, FileField, FormDataConsumer, required} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import config from '../../../config/config';
import loader from '../../../loader10.gif';
import Grid from '@material-ui/core/Grid';
import { translate } from 'react-admin';
import  extractGoogleCoords from '../../Mapa/localhelpers/extractGoogleCoords';
let urlAppjs = config.UrlServer;

class CargarArchivoKml extends React.Component {
    constructor(props) {
        super(props)
        this.state = {loaded: false, unidades: [], codproyecto: "", documentos: []}
        this.validarArchivo = this.validarArchivo.bind(this);
    }
    componentDidMount() {
        if(this.props.proyecto){
            let id=this.props.proyecto.replace("/proyectos/", "")
            fetch(urlAppjs+"/unidad_funcionals?ruta.proyecto="+id).then(respuesta=>respuesta.json()).then(data=>{
                this.setState({ loaded: true, unidades:  data['hydra:member']})
                //console.log(data['hydra:member']);
            })
            fetch(urlAppjs+"/proyecto_mapas?proyecto="+id).then(data=>data.json()).then(data=>{
                this.setState({documentos: data['hydra:member']})
            })
        }
        if(this.props.codproyecto){
            this.state.codproyecto=this.props.codproyecto
        }
    }
    componentWillReceiveProps() {
        if(this.props.proyecto){
            let id=this.props.proyecto.replace("/proyectos/", "")
            fetch(urlAppjs+"/proyecto_mapas?proyecto="+id).then(data=>data.json()).then(data=>{
                this.setState({documentos: data['hydra:member']})
            })
        }
    }
    validarArchivo(archivo){
    }
    render(){
        const { translate } = this.props;
        if(this.state.loaded===false){
            return (
                <div className="Reload2">
                    <img src={loader} />
                </div>
            )
       }else{
           return(
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                    <Typography variant="h4" gutterBottom>
                        {translate('resources.proyectos.fields.uniFuncionalesProyec')}
                    </Typography>
                    </Grid>
                    {this.state.unidades.map((valor, i)=>(
                    <Grid item xs={6}>
                    <Typography variant="overline" display="block" gutterBottom>
                        {valor.codigo}
                    </Typography>
                    <FileInput source={"archivoCarga"+i} label="resources.proyectos.fields.archivoKml" accept=".kml" multiple={false} validate={this.validarArchivo}>
                        <FileField source="src" title="title" />
                    </FileInput>
                    <TextInput source={"unidadArchivo"+i} defaultValue={valor["@id"].replace("/unidad_funcionals/", "")} style={{display: "none"}}/>
                    <TextInput source={"codUnidadArchivo"+i} defaultValue={valor.codigo} style={{display: "none"}}/>
                    {this.state.documentos.map(doc=>{
                        if(doc.unidadFuncional['@id']==valor['@id'])
                            return (
                                <a href={urlAppjs+"/"+doc.archivo} target="_blank" style={{textDecoration:"none"}}>
                                    <span>{translate('resources.proyectos.fields.descargarkml')}</span>
                                </a>
                            )
                    })
                    }
                    </Grid>
                   ))

                   }
                   <br></br>
                   <span id='MensajeGuardado' style={{marginLeft:"32%", fontSize:"18px", fontWeight:"bold"}}></span>
                </Grid>
           )
       }
    }
}

export default translate(CargarArchivoKml);