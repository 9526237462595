import React from 'react';
import Typography from '@material-ui/core/Typography';
import { SelectInput, DisabledInput, FormDataConsumer, required} from 'react-admin';
import loader from './../../../../../loader10.gif';
import config from '../../../../../config/config';
let urlAppjs = config.UrlServer;

class Grupo3Edit extends React.Component{

    constructor(props){
        super(props)
        this.state = { grupo3: [], datoEva: [], cumple: "", grupo3Peso: [] }
    }

    componentDidMount(){

        let url_elemento_aux = document.location.hash;
        let croed=url_elemento_aux.split("/");
        let crearoeditar="";
        let componente =""
        if(croed[2]==="create"){
            crearoeditar=1;
        }
        else{
        let url_elemento = url_elemento_aux.split("/%2F");
        componente = url_elemento[1];
        componente = componente.replace("/2", "");
        componente = componente.replace("%2F", "/");
        crearoeditar=2;
        }

        var urlApi = urlAppjs+"/preguntas?elemento_indicador=3.json";
        
        // console.log(urlAppjs+"/"+componente)
        if(crearoeditar===2){
            this.setState({ datoEva: this.props.record })
            // fetch(urlAppjs+"/"+componente)
        // .then((response) => {
        //     return response.json()
        // })
        // .then((datoEva) => {
        //     this.setState({ datoEva: datoEva })
        // })
    }
        fetch(urlAppjs+"/preguntas?elemento_indicador=3.json")
        .then((response) => {
            return response.json()
        })
        .then((grupo3) => {
            this.setState({ grupo3: grupo3 })
        })

        fetch(urlAppjs+"/preguntas/26")
        .then((response) => {
            return response.json()
        })
        .then((grupo3Peso) => {
            this.setState({ grupo3Peso: grupo3Peso })
        })
    
    }

    render(){

        const { grupo3 } = this.state;       
        const { grupo3Peso } = this.state;       
        const { datoEva } = this.state;        
        const {cumple}= this.state;
        let valoredit="";
        let pesoTab3=grupo3Peso.peso;
        const ValidarGrupo3=(value)=>{
            let largo=0;
            if(grupo3['hydra:member']){
            largo=grupo3['hydra:member'].length;
            }
            let resultado =[];
            let cumpleaux=0;
            let cumplea="No Cumple";
            for(let j=0; j<largo; j++){
                let valor = document.getElementById('gp3_res_'+j);
                if(valor && valor.value!=""){
                    resultado.push(valor.value);
                }
            }
            if(resultado.length===largo){
                resultado.map((data, i)=>{
                    if(data==="Cumple"){
                        cumpleaux++;
                    }
                })
            }
            if(cumpleaux===largo){
                cumplea="Cumple"
            }
            else{
                cumplea="No Cumple"
            }
            valoredit=cumplea;
        }
        // if(datoEva.svGrupo && datoEva.calificacionG3 && cumple===""){
        //     valoredit=datoEva.calificacionG3
            
        // }
        if(cumple==="" && valoredit===""){
        //valoredit="No Cumple";
        
        ValidarGrupo3();
        }
        else{
            valoredit=cumple

        }
        if(grupo3['hydra:member']){
            
            let response3 = [];
            if(datoEva.svGrupo){
                grupo3['hydra:member'].map((dataGrupo) => {
                    datoEva.svGrupo.map( responseSV => {
                        if(dataGrupo.pregunta === responseSV["preguntas_"].pregunta && responseSV["preguntas_"].elemento_indicador==="/elemento_indicadors/3"){
                            response3.push(responseSV.calificacion)
                        }
                    })
                })
            }
            let grupo3b=[];
            let opcionesgrupo3=[];
            let pesosGrupo3b=[];
            grupo3['hydra:member'].map((dato, i) => {            
            if(dato.pregunta==="Sin Daños"){
            
            }
            else{
                grupo3b.push(dato);
                pesosGrupo3b.push(dato.peso);
                if(dato.pregunta==="Tipo de Deterioro en Postes"){
                    let valor=[
                    {id: "Sin Daño", name:"Sin Daño"},  
                    {id: "Tipo 1", name:"Tipo 1"},  
                    {id: "Tipo 2", name:"Tipo 2"},  
                    {id: "Tipo 1 y 2", name:"Tipo 1 y 2"},  
                    { id: 'No Corresponde', name: 'No Corresponde' },                    
                    {id: "-", name:"-"}, 
                ]
                    opcionesgrupo3.push(valor)
                }else if(dato.pregunta==="Estado de Poste"){
                    let valor=[
                        {id: "Estado", name:"Estado"},  
                        {id: "Cumple", name:"Cumple"},  
                        {id: "No Cumple", name:"No Cumple"},  
                        {id: "No Corresponde", name:"No Corresponde"},  
                        {id: "-", name:"-"},  
                    ]
                    opcionesgrupo3.push(valor)
                }else{
                    let valor=[
                        {id: "-", name:"-"},  
                        { id: 'Cumple', name: 'Cumple' },
                        { id: 'No Cumple', name: 'No Cumple' }, 
                    ]
                    opcionesgrupo3.push(valor)
                }
            }
            
            })
            //console.log(response3)
            return(
                <React.Fragment>                                       
                    {
                        grupo3b.map((data, i) => (
                                <React.Fragment key={i}>                                
                                <Typography
                                    variant="strong"                                
                                    color="inherit"
                                    style={{width:'50%', display: 'inline-block'}}                                    
                                >
                                    {data.pregunta}
                                </Typography>
                                {/* <TextInput source={`gp1_res${i}`} label=" " style={{width:'30%', display: 'inline-block', marginLeft: '20px'}} fullWidth/>                                 */}
                                {i>=4 &&
                                <SelectInput source={`gp3_res_${i+1}`} id={`gp3_res_${i+1}`} allowEmpty emptyValue="" label=" " 
                                    choices={opcionesgrupo3[i]}
                                style={{width:'30%', display: 'inline-block', marginLeft: '20px'}} fullWidth defaultValue={response3[i+1]} onChange={ValidarGrupo3} validate={[required()]}/>
                                }
                                {i<4 &&
                                <SelectInput source={`gp3_res_${i}`} id={`gp3_res_${i}`} allowEmpty emptyValue="" label=" " 
                                    choices={opcionesgrupo3[i]}
                                style={{width:'30%', display: 'inline-block', marginLeft: '20px'}} fullWidth defaultValue={response3[i]} onChange={ValidarGrupo3} validate={[required()]}/>
                                }
                                {i>=4 &&
                                <DisabledInput source={`gp3_id_${i+1}`} defaultValue={data["@id"]} style={{display: 'none'}} />                                
                                }
                                {i<4 &&
                                <DisabledInput source={`gp3_id_${i}`} defaultValue={data["@id"]} style={{display: 'none'}} />                                
                                }
                            </React.Fragment>
                        
                        ))

                    }    
                    
                     <React.Fragment key={12}>
                        <Typography
                            variant="strong"
                            color="inherit"
                            style={{width:'50%', display: 'none'}}
                        >
                            {grupo3['hydra:member'][4]['pregunta']}
                        </Typography>
                        <DisabledInput source="calificacionG3d" label=" "  defaultValue={valoredit} style={{width:'30%', display: 'none', marginLeft: '20px'}} fullWidth />
                         <DisabledInput source={`gp3_id_4`} defaultValue={grupo3['hydra:member'][4]["@id"]} style={{display: 'none'}}/>
                    </React.Fragment>
                    {/* <hr />
                    <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '-30px'}}>
                        <strong style={{display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}>Condición: </strong><DisabledInput source="calificacionG3d" label=" " defaultValue={valoredit} />
                    </div>               */}
                    <DisabledInput source="gp3_length" label=" " defaultValue={grupo3['hydra:member'].length} style={{display: 'none'}}/>  
                    <DisabledInput source="gp3_calificacion" label=" " style={{display: 'none'}}/>
                    <FormDataConsumer style={{display: "none"}}>
                                {({formData, ...rest}) => {
                                    if(formData){
                                 let largo=0;
                                 if(grupo3['hydra:member']){
                                 largo=grupo3['hydra:member'].length-1;
                                 }
                                 let resultado =[];
                                 let cumpleaux=0;
                                 let cumpleauxpeso=0;
                                 let cumplea="No Cumple";
                                 for(let j=0; j<largo; j++){
                                     let valor = formData["gp3_res_"+j];
                                     if(valor){
                                         resultado.push(valor);
                                     }
                                 }
                                 if(resultado.length===largo-1 || resultado.length===largo){
                                     resultado.map((data, i)=>{
                                         
                                         if(data==="Cumple" || data==="-" || data==="Existe" || data===""){
                                             cumpleaux++;
                                         }
                                         else{
                                            cumpleauxpeso+=pesosGrupo3b[i];
                                        }
                                     })
                                 }
                                 if(cumpleaux===largo){
                                     cumplea="Cumple"
                                 }
                                 else{
                                     cumplea="No Cumple"
                                 }
                                 valoredit=cumplea;
                                 //console.log(valoredit)
                                 //console.log("cumple Aux ->"+cumpleaux+ " largo -> "+largo+ " resultado ->"+resultado.length)
                                 //formData.calificacionG3d=valoredit;
                                 if(cumpleauxpeso===0){
                                    formData.gp3_calificacion="0";
                                 }else{
                                 formData.gp3_calificacion=cumpleauxpeso;
                                 }
                                 if(cumpleauxpeso<pesoTab3){
                                    formData.calificacionG3d="Cumple"
                                 }
                                 else{
                                    formData.calificacionG3d="No Cumple"
                                 }
                                 
                                 console.log("Peso Total3: "+cumpleauxpeso);
                                 //this.setState({cumple: cumplea})
                                }
                                }}
                    </FormDataConsumer>
                </React.Fragment>
            )          
                     
        }else{                      
            return( 
                <div className="Reload2">
                    <img src={loader} />
                </div> 
            )
        }
            
    }    
}

export default Grupo3Edit;