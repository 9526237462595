import React from 'react';
import aFormatter from './../format/AFormat';

import { TextInput } from 'react-admin';


export const AInput = (props) => (
    <TextInput 
        {...props} 
        InputProps={{
            inputComponent: aFormatter,
        }}
    />
);

