import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
    Query, BooleanInput, ReferenceInput, SelectInput, DateInput, AutocompleteInput,
    TextInput, NumberInput, ArrayInput, SimpleFormIterator, Loading, FormDataConsumer
} from 'react-admin';
import { LinearProgress } from '@material-ui/core';
import { styles } from './../../../EstilosGrid';
import { MapWithAMarker } from './../../../inputs/GMapInventario'
import config from '../../../config/config';
import extractGoogleCoords from './extractGoogleCoords'
let urlAppjs = config.UrlServer;

/**
 * @param tipoElemento = la entidad a apuntar en el fetcher
 * @param abInicio = la abscisa inicial para el filtro
 * @param abFin = la abscisa final para el filtro
 * 
 */

class Mapa extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            informacionGeneral: [],
            redirect: [],
            isLoadedElements: 0,
            isLoadedIndicador: 0,
            coordenadasProyec: [],
            kmlmapa: [],
            cargaKml: false,
            indicador: [],
            largokml: 0,
        }
        this.sources = {
            ZINT: "elemento_alrededors", SEPD: "elemento_alrededors", OBST: "elemento_alrededors", MARG: "elemento_alrededors",
            COMR: "elemento_alrededors", ACCS: "elemento_alrededors", OCPN: "elemento_alrededors",

            PART: "adoquin_detalles",

            POTR: "otro_detalles",

            ZCOR: "elemento_drenajes", POZT: "elemento_drenajes", DREN: "elemento_drenajes", DESC: "elemento_drenajes",
            CUNT: "elemento_drenajes", BOXC: "elemento_drenajes", BAJT: "elemento_drenajes", ALCT: "elemento_drenajes",

            INIV: "otros_elementos", IDES: "otros_elementos", CASC: "otros_elementos",

            PNTE: "puentes", PNTEP: "puentes", MCON: "elemento_contencions", ESTE: "elemento_contencions", CONT: "elemento_contencions",

            SOS: "elemento_reds", SEM: "elemento_reds", PST: "elemento_reds", PMZ: "elemento_reds", LUM: "elemento_reds",
            ETD: "elemento_reds", ENL: "elemento_reds", CTV: "elemento_reds", CSP: "elemento_reds", BDP: "elemento_reds",
            ANT: "elemento_reds", DCT: "elemento_reds", REE: "elemento_reds",

            TPVH: "seguridads", PRTP: "seguridads", DEFMT: "seguridads", CERC: "seguridads", CAPT: "seguridads",
            BRRJ: "seguridads", BRRF: "seguridads", BRRC: "seguridads", BORD: "seguridads",

            ZESC: "elemento_senalizacions", SVERV: "elemento_senalizacions", SVERR: "elemento_senalizacions", SVERP: "elemento_senalizacions",
            SVERI: "elemento_senalizacions", SHL: "elemento_senalizacions", SHCT: "elemento_senalizacions", SHBD: "elemento_senalizacions",
            REC: "elemento_senalizacions", PPT: "elemento_senalizacions",

            TRAF: "elemento_traficos",
        }
        this.stagedProps = {
            unidadFuncional: this.props.unidadFuncional,
            tramo: this.props.tramo,
            segmento: this.props.segmento,
            flexibleDetalles: this.props.flexibleDetalles,
            rigidoDetalles: this.props.rigidoDetalles,
            adoquinDetalles: this.props.adoquinDetalles,
            otroDetalles: this.props.otroDetalles,
            seguridads: this.props.seguridads,
            tipoElementoSeguridad: this.props.tipoElementoSeguridad,
            elementoDrenajes: this.props.elementoDrenajes,
            tipoElementoDrenaje: this.props.tipoElementoDrenaje,
            elementoSenalizacion: this.props.elementoSenalizacion,
            tipoElementoSenal: this.props.tipoElementoSenal,
            tipoSenal: this.props.tipoSenal,
            tipoSenalVertical: this.props.tipoSenalVertical,
            elementoAlrededors: this.props.elementoAlrededors,
            tipoElementoAlrededor: this.props.tipoElementoAlrededor,
            tuneles: this.props.tuneles,
            elementoContencions: this.props.elementoContencions,
            elementoPontons: this.props.elementoPontons,
            puentes: this.props.puentes,
            tipoPuente: this.props.tipoPuente,
            otrosElementos: this.props.otrosElementos,
            tipoElementoOtro: this.props.tipoElementoOtro,
            elementoReds: this.props.elementoReds,
            tipoElementoRed: this.props.tipoElementoRed,
            positions: this.propsdasProyec,
            estw: this.props.estw,
            indInv: this.props.indInv
        }
        this.payload = {
            elemento_alrededors: {
                pagination: { page: 1, perPage: 1000000 },
                sort: { field: 'lat', order: 'ASC' },
                filter: {
                    'properties[0]': 'tipoElemento',
                    'properties[1]': 'latitudInicial',
                    'properties[2]': 'longitudInicial',
                    'properties[3]': 'abInicio',
                    'properties[4]': 'unidadFuncional',
                    'properties[5]': 'tramo',
                    'properties[6]': 'segmento',
                    'properties[7]': 'codigo',
                    unidadFuncional: this.props.unidadFuncional,
                    tramo: this.props.tramos,
                    segmento: this.props.segmentos,
                    tipoElemento: this.props.tipoElementoAlrededor
                }
            },
            flexible_detalles: {
                pagination: { page: 1, perPage: 1000000 },
                sort: { field: 'lat', order: 'ASC' },
                filter: {
                    'properties[0]': 'tipoElemento',
                    'properties[1]': 'latitudInicial',
                    'properties[2]': 'longitudInicial',
                    'properties[3]': 'abInicio',
                    'properties[4]': 'unidadFuncional',
                    'properties[5]': 'tramo',
                    'properties[6]': 'segmento',
                    'properties[7]': 'codigo',
                    'properties[8]': 'tipoElementoSenal',
                    'properties[9]': 'tipoSenal',
                    'properties[10]': 'senal',
                    unidadFuncional: this.props.unidadFuncional,
                    tramo: this.props.tramos,
                    segmento: this.props.segmentos,
                }
            },
            rigido_Detalles: {
                pagination: { page: 1, perPage: 1000000 },
                sort: { field: 'lat', order: 'ASC' },
                filter: {
                    'properties[0]': 'tipoElemento',
                    'properties[1]': 'latitudInicial',
                    'properties[2]': 'longitudInicial',
                    'properties[3]': 'abInicio',
                    'properties[4]': 'unidadFuncional',
                    'properties[5]': 'tramo',
                    'properties[6]': 'segmento',
                    'properties[7]': 'codigo',
                    'properties[8]': 'tipoElementoSenal',
                    'properties[9]': 'tipoSenal',
                    'properties[10]': 'senal',
                    unidadFuncional: this.props.unidadFuncional,
                    tramo: this.props.tramos,
                    segmento: this.props.segmentos,
                }
            },
            adoquin_detalles: {
                pagination: { page: 1, perPage: 1000000 },
                sort: { field: 'lat', order: 'ASC' },
                filter: {
                    'properties[0]': 'tipoElemento',
                    'properties[1]': 'latitudInicial',
                    'properties[2]': 'longitudInicial',
                    'properties[3]': 'abInicio',
                    'properties[4]': 'unidadFuncional',
                    'properties[5]': 'tramo',
                    'properties[6]': 'segmento',
                    'properties[7]': 'codigo',
                    'properties[8]': 'tipoElementoSenal',
                    'properties[9]': 'tipoSenal',
                    'properties[10]': 'senal',
                    unidadFuncional: this.props.unidadFuncional,
                    tramo: this.props.tramos,
                    segmento: this.props.segmentos,
                }
            },
            otro_detalles: {
                pagination: { page: 1, perPage: 1000000 },
                sort: { field: 'lat', order: 'ASC' },
                filter: {
                    'properties[0]': 'tipoElemento',
                    'properties[1]': 'latitudInicial',
                    'properties[2]': 'longitudInicial',
                    'properties[3]': 'abInicio',
                    'properties[4]': 'unidadFuncional',
                    'properties[5]': 'tramo',
                    'properties[6]': 'segmento',
                    'properties[7]': 'codigo',
                    'properties[8]': 'tipoElementoSenal',
                    'properties[9]': 'tipoSenal',
                    'properties[10]': 'senal',
                    unidadFuncional: this.props.unidadFuncional,
                    tramo: this.props.tramos,
                    segmento: this.props.segmentos,
                }
            },
            elemento_drenajes: {
                pagination: { page: 1, perPage: 1000000 },
                sort: { field: 'lat', order: 'ASC' },
                filter: {
                    'properties[0]': 'tipoElemento',
                    'properties[1]': 'latitudInicial',
                    'properties[2]': 'longitudInicial',
                    'properties[3]': 'abInicio',
                    'properties[4]': 'unidadFuncional',
                    'properties[5]': 'tramo',
                    'properties[6]': 'segmento',
                    'properties[7]': 'codigo',
                    unidadFuncional: this.props.unidadFuncional,
                    tramo: this.props.tramos,
                    segmento: this.props.segmentos,
                    tipoElemento: this.props.tipoElementoDrenaje
                }
            },
            otros_elementos: {
                pagination: { page: 1, perPage: 1000000 },
                sort: { field: 'lat', order: 'ASC' },
                filter: {
                    'properties[0]': 'tipoElemento',
                    'properties[1]': 'latitudInicial',
                    'properties[2]': 'longitudInicial',
                    'properties[3]': 'abInicio',
                    'properties[4]': 'unidadFuncional',
                    'properties[5]': 'tramo',
                    'properties[6]': 'segmento',
                    'properties[7]': 'codigo',
                    unidadFuncional: this.props.unidadFuncional,
                    tramo: this.props.tramos,
                    segmento: this.props.segmentos,
                    tipoElemento: this.props.tipoElementoOtro
                }
            },
            elemento_contencions: {
                pagination: { page: 1, perPage: 1000000 },
                sort: { field: 'lat', order: 'ASC' },
                filter: {
                    'properties[0]': 'tipoElemento',
                    'properties[1]': 'latitudInicial',
                    'properties[2]': 'longitudInicial',
                    'properties[3]': 'abInicio',
                    'properties[4]': 'unidadFuncional',
                    'properties[5]': 'tramo',
                    'properties[6]': 'segmento',
                    'properties[7]': 'codigo',
                    'properties[8]': 'tipoElementoSenal',
                    'properties[9]': 'tipoSenal',
                    'properties[10]': 'senal',
                    unidadFuncional: this.props.unidadFuncional,
                    tramo: this.props.tramos,
                    segmento: this.props.segmentos,
                }
            },
            elemento_reds: {
                pagination: { page: 1, perPage: 1000000 },
                sort: { field: 'lat', order: 'ASC' },
                filter: {
                    'properties[0]': 'tipoElemento',
                    'properties[1]': 'latitudInicial',
                    'properties[2]': 'longitudInicial',
                    'properties[3]': 'abInicio',
                    'properties[4]': 'unidadFuncional',
                    'properties[5]': 'tramo',
                    'properties[6]': 'segmento',
                    'properties[7]': 'codigo',
                    unidadFuncional: this.props.unidadFuncional,
                    tramo: this.props.tramos,
                    segmento: this.props.segmentos,
                    tipoElemento: this.props.tipoElementoRed
                }
            },
            seguridads: {
                pagination: { page: 1, perPage: 1000000 },
                sort: { field: 'lat', order: 'ASC' },
                filter: {
                    'properties[0]': 'tipoElemento',
                    'properties[1]': 'latitudInicial',
                    'properties[2]': 'longitudInicial',
                    'properties[3]': 'abInicio',
                    'properties[4]': 'unidadFuncional',
                    'properties[5]': 'tramo',
                    'properties[6]': 'segmento',
                    'properties[7]': 'codigo',
                    unidadFuncional: this.props.unidadFuncional,
                    tramo: this.props.tramos,
                    segmento: this.props.segmentos,
                    tipoElemento: this.props.tipoElementoSeguridad
                }
            },
            puentes: {
                pagination: { page: 1, perPage: 1000000 },
                sort: { field: 'lat', order: 'ASC' },
                filter: {
                    'properties[0]': 'tipoElemento',
                    'properties[1]': 'latitudInicial',
                    'properties[2]': 'longitudInicial',
                    'properties[3]': 'abInicio',
                    'properties[4]': 'unidadFuncional',
                    'properties[5]': 'tramo',
                    'properties[6]': 'segmento',
                    'properties[7]': 'codigo',
                    'properties[8]': 'elementos',
                    unidadFuncional: this.props.unidadFuncional,
                    tramo: this.props.tramos,
                    segmento: this.props.segmentos,
                    tipoElemento: this.props.tipoPuente
                }
            },
            elemento_senalizacions: {
                pagination: { page: 1, perPage: 1000000 },
                sort: { field: 'lat', order: 'ASC' },
                filter: {
                    'properties[0]': 'tipoElemento',
                    'properties[1]': 'latitudInicial',
                    'properties[2]': 'longitudInicial',
                    'properties[3]': 'abInicio',
                    'properties[4]': 'unidadFuncional',
                    'properties[5]': 'tramo',
                    'properties[6]': 'segmento',
                    'properties[7]': 'codigo',
                    'properties[8]': 'tipoElementoSenal',
                    'properties[9]': 'tipoSenal',
                    'properties[10]': 'senal',
                    unidadFuncional: this.props.unidadFuncional,
                    tramo: this.props.tramos,
                    segmento: this.props.segmentos,
                    tipoElementoSenal: this.props.tipoElementoSenal,
                    tipoSenal: this.props.tipoSenal,
                    estw: this.props.estw
                }
            },
        }
        this.projectId = window.localStorage.getItem('id_project');
        this.token = window.localStorage.getItem('token')
        this.timeOutVar = "";
        this.fetcher.bind(this);
        this.removerFiltros.bind(this);
    }
    componentDidMount() {
        // console.log("didMount")     
        if (this.state.coordenadasProyec.length == 0) {
            fetch(urlAppjs + "/proyectos?id=" + this.projectId, { headers: { 'authorization': 'Bearer ' + localStorage.getItem('token') } })
                .then((response) => { return response.json() })
                .then((Elementos) => { this.state.coordenadasProyec = Elementos["hydra:member"][0].ubicaciones })
        }   
        // console.log(this.stagedProps.unidadFuncional); 
        this.state.cargaKml = true;
        if (this.state.cargaKml == true) {
            
            if (this.stagedProps.unidadFuncional && this.stagedProps.tramo && this.stagedProps.indInv) {
                // console.log(this.stagedProps.unidadFuncional);
                
                var unidadR = this.stagedProps.unidadFuncional;
                var unidadR2 = this.stagedProps.unidadFuncional.replace("/unidad_funcionals/", "");
                var tramoR = this.stagedProps.tramo;
                if(this.stagedProps.segmento){
                    var segmentoR = this.stagedProps.segmento.replace("/segmentos/", "");
                }else{
                    var segmentoR = '00';
                }
                
                
                fetch(urlAppjs + "/proyecto_mapas?order[unidadFuncional]=DESC&proyecto=" + this.projectId+"&unidadFuncional="+unidadR2).then(res => res.json()).then(res2 => {
                    let resultados = res2['hydra:member'];
                    let cuenta2 = resultados.length;
                    if (resultados) {
                        for (let resultado of resultados) {
                            fetch(urlAppjs + "/BuscarArchivo" + resultado.archivo.replace("MapaKML", "")).then(respuesta => respuesta.json()).then(data => extractGoogleCoords(data.contenido)).then(coords => {
                                this.state.kmlmapa.push({ "unidadFuncional": unidadR, "valores": coords.polygons, "indicadores": [] }) 
                                this.setState({ isLoadedIndicador: 1 })
                                if (this.props.indInv === 1) {
                                    fetch(urlAppjs + unidadR).then(uf => uf.json()).then(datos => {
                                        let tramos = datos.tramos;
                                        let indicadores = "IriMapa";
                                        let cuenta = tramos.length;
                                        for (let tramo of tramos) {
                                            fetch(urlAppjs + "/buscarIndicadoresVisualizacionMapa/" + unidadR2 + "/" + indicadores + "/" + tramoR.replace("/tramos/", "")+ "/" + segmentoR)
                                                .then(dataIndicadores => dataIndicadores.json())
                                                .then(datosRes => {
                                                    // console.log(datosRes)
                                                    if (datosRes.resultado) {
                                                        let Datosind = datosRes.resultado;
                                                        this.state.indicador.push({ "unidadFuncional": unidadR, "tramo": tramoR, "segmento": segmentoR, "valores": Datosind })
                                                        
                                                        cuenta--
                                                        if (cuenta == 0) {
                                                            cuenta2--;
                                                        }
                                                        if (cuenta === 0 && cuenta2 === 0) {
                                                            this.arregloIndicador();
                                                        }
                                                    }
                                                })
                                        }
                                    })
                                }                  

                            })
                        }
                    }
                })
            }
        }
        if (this.props.indInv === 2 && typeof this.props.flexibleDetalles !== "undefined" && this.props.flexibleDetalles.length > 0) { this.fetcher(this.props.flexibleDetalles[0]) }
        else this.removerFiltros("flexible_detalles")
        if (this.props.indInv === 2 && typeof this.props.rigidoDetalles !== "undefined" && this.props.rigidoDetalles.length > 0) { this.fetcher(this.props.rigidoDetalles[0]) }
        else this.removerFiltros("rigido_detalles")
        if (this.props.indInv === 2 && typeof this.props.adoquinDetalles !== "undefined" && this.props.adoquinDetalles.length > 0) { this.fetcher(this.props.adoquinDetalles[0]) }
        else this.removerFiltros("adoquin_detalles")
        if (this.props.indInv === 2 && typeof this.props.otroDetalles !== "undefined" && this.props.otroDetalles.length > 0) { this.fetcher(this.props.otroDetalles[0]) }
        else this.removerFiltros("otro_detalles")
        if (this.props.indInv === 2 && typeof this.props.seguridads !== "undefined" && this.props.seguridads.length > 0) { this.fetcher(this.props.seguridads[0]) }
        else this.removerFiltros("seguridads")
        if (this.props.indInv === 2 && typeof this.props.elementoDrenajes !== "undefined" && this.props.elementoDrenajes.length > 0) { this.fetcher(this.props.elementoDrenajes[0]) }
        else this.removerFiltros("elemento_drenajes")
        if (this.props.indInv === 2 && typeof this.props.elementoSenalizacion !== "undefined" && this.props.elementoSenalizacion.length > 0) { this.fetcher(this.props.elementoSenalizacion[0]) }
        else this.removerFiltros("elemento_senalizacion")
        if (this.props.indInv === 2 && typeof this.props.elementoAlrededors !== "undefined" && this.props.elementoAlrededors.length > 0) { this.fetcher(this.props.elementoAlrededors[0]) }
        else this.removerFiltros("elemento_alrededors")
        if (this.props.indInv === 2 && typeof this.props.tuneles !== "undefined" && this.props.tuneles.length > 0) { this.fetcher(this.props.tuneles[0]) }
        else this.removerFiltros("tuneles")
        if (this.props.indInv === 2 && typeof this.props.elementoContencions !== "undefined" && this.props.elementoContencions.length > 0) { this.fetcher(this.props.elementoContencions[0]) }
        else this.removerFiltros("elemento_contencions")
        if (this.props.indInv === 2 && typeof this.props.elementoPontons !== "undefined" && this.props.elementoPontons.length > 0) { this.fetcher(this.props.elementoPontons[0]) }
        else this.removerFiltros("elemento_pontons")
        if (this.props.indInv === 2 && typeof this.props.puentes !== "undefined" && this.props.puentes.length > 0) { this.fetcher(this.props.puentes[0]) }
        else this.removerFiltros("puentes")
        if (this.props.indInv === 2 && typeof this.props.otrosElementos !== "undefined" && this.props.otrosElementos.length > 0) { this.fetcher(this.props.otrosElementos[0]) }
        else this.removerFiltros("otros_elementos")
        if (this.props.indInv === 2 && typeof this.props.elementoReds !== "undefined" && this.props.elementoReds.length > 0) { this.fetcher(this.props.elementoReds[0]) }
        else this.removerFiltros("elemento_reds")
    }
    componentDidUpdate() {
        if (this.timeOutVar !== "") {
            clearTimeout(this.timeOutVar)
        }
        this.timeOutVar = setTimeout(() => {
            if (
                this.stagedProps.unidadFuncional != this.props.unidadFuncional ||
                this.stagedProps.tramo != this.props.tramo ||
                this.stagedProps.segmento != this.props.segmento ||
                this.stagedProps.flexibleDetalles != this.props.flexibleDetalles ||
                this.stagedProps.rigidoDetalles != this.props.rigidoDetalles ||
                this.stagedProps.adoquinDetalles != this.props.adoquinDetalles ||
                this.stagedProps.otroDetalles != this.props.otroDetalles ||
                this.stagedProps.seguridads != this.props.seguridads ||
                this.stagedProps.tipoElementoSeguridad != this.props.tipoElementoSeguridad ||
                this.stagedProps.elementoDrenajes != this.props.elementoDrenajes ||
                this.stagedProps.tipoElementoDrenaje != this.props.tipoElementoDrenaje ||
                this.stagedProps.elementoSenalizacion != this.props.elementoSenalizacion ||
                this.stagedProps.tipoElementoSenal != this.props.tipoElementoSenal ||
                this.stagedProps.tipoSenal != this.props.tipoSenal ||
                this.stagedProps.tipoSenalVertical != this.props.tipoSenalVertical ||
                this.stagedProps.elementoAlrededors != this.props.elementoAlrededors ||
                this.stagedProps.tipoElementoAlrededor != this.props.tipoElementoAlrededor ||
                this.stagedProps.tuneles != this.props.tuneles ||
                this.stagedProps.elementoContencions != this.props.elementoContencions ||
                this.stagedProps.elementoPontons != this.props.elementoPontons ||
                this.stagedProps.puentes != this.props.puentes ||
                this.stagedProps.tipoPuente != this.props.tipoPuente ||
                this.stagedProps.otrosElementos != this.props.otrosElementos ||
                this.stagedProps.tipoElementoOtro != this.props.tipoElementoOtro ||
                this.stagedProps.elementoReds != this.props.elementoReds ||
                this.stagedProps.tipoElementoRed != this.props.tipoElementoRed ||
                this.stagedProps.positions != this.props.positions ||
                this.stagedProps.estw != this.props.estw ||
                this.stagedProps.indInv != this.props.indInv
            ) {
                this.stagedProps.unidadFuncional = this.props.unidadFuncional
                this.stagedProps.tramo = this.props.tramo
                this.stagedProps.segmento = this.props.segmento
                this.stagedProps.flexibleDetalles = this.props.flexibleDetalles
                this.stagedProps.rigidoDetalles = this.props.rigidoDetalles
                this.stagedProps.adoquinDetalles = this.props.adoquinDetalles
                this.stagedProps.otroDetalles = this.props.otroDetalles
                this.stagedProps.seguridads = this.props.seguridads
                this.stagedProps.tipoElementoSeguridad = this.props.tipoElementoSeguridad
                this.stagedProps.elementoDrenajes = this.props.elementoDrenajes
                this.stagedProps.tipoElementoDrenaje = this.props.tipoElementoDrenaje
                this.stagedProps.elementoSenalizacion = this.props.elementoSenalizacion
                this.stagedProps.tipoElementoSenal = this.props.tipoElementoSenal
                this.stagedProps.tipoSenal = this.props.tipoSenal
                this.stagedProps.tipoSenalVertical = this.props.tipoSenalVertical
                this.stagedProps.elementoAlrededors = this.props.elementoAlrededors
                this.stagedProps.tipoElementoAlrededor = this.props.tipoElementoAlrededor
                this.stagedProps.tuneles = this.props.tuneles
                this.stagedProps.elementoContencions = this.props.elementoContencions
                this.stagedProps.elementoPontons = this.props.elementoPontons
                this.stagedProps.puentes = this.props.puentes
                this.stagedProps.tipoPuente = this.props.tipoPuente
                this.stagedProps.otrosElementos = this.props.otrosElementos
                this.tipoElementoOtro = this.props.tipoElementoOtro
                this.stagedProps.elementoReds = this.props.elementoReds
                this.stagedProps.tipoElementoRed = this.props.tipoElementoRed
                this.stagedProps.positions = this.props.positions
                this.stagedProps.estw = this.props.estw
                this.stagedProps.indInv = this.props.indInv
                this.state.informacionGeneral = [];

                this.state.isLoadedElements = 0;

                this.componentDidMount();
            }

        }, (typeof this.props.elementoSenalizacion !== "undefined" && this.props.elementoSenalizacion.length > 0) ? 3000 : 1000)
    }

    fetcher(target) {
        this.setState({ isLoadedElements: this.state.isLoadedElements + 1 })
        var urlPart = "/" + target + "?aprobacion=Aprobado&pagination=off" +
            "&properties[tipoElemento]" +
            "&properties[latitudInicial]" +
            "&properties[longitudInicial]" +
            "&properties[abInicio]" +
            "&properties[unidadFuncional]" +
            "&properties[tramo]" +
            "&properties[segmento]" +
            "&properties[codigo]" +
            "&unidadFuncional.ruta.proyecto=" + this.projectId;

        if (target == "elemento_senalizacions") {
            urlPart = urlPart + "&properties[tipoElementoSenal]" +
                "&properties[tipoSenal]" +
                "&properties[senal]";
        }
        if (target == "puentes") {
            urlPart = urlPart + "&segmento.tramo.unidadFuncional.ruta.proyecto=" + this.projectId;
            urlPart = urlPart + "&properties[elementos]";
            urlPart = urlPart + "&properties[unidad_funcional]";
        }

        if (typeof this.props.unidadFuncional !== "undefined") {
            urlPart = urlPart + "&unidadFuncional=" + this.props.unidadFuncional;
        }

        if (typeof this.props.tramo !== "undefined") {
            urlPart = urlPart + "&tramo=" + this.props.tramo;
        }

        if (typeof this.props.segmento !== "undefined") {
            urlPart = urlPart + "&segmento=" + this.props.segmento;
        }

        if (target == "elemento_senalizacions") {
            if (typeof this.props.tipoElementoSenal !== "undefined") {
                urlPart = urlPart + "&tipoElementoSenal=" + this.props.tipoElementoSenal;
            }
            if (typeof this.props.tipoSenal !== "undefined") {
                urlPart = urlPart + "&tipoSenal=" + this.props.tipoSenal;
            }
            if (typeof this.props.senal !== "undefined") {
                urlPart = urlPart + "&senal=" + this.props.tipoSenal;
            }
        }

        if (target == "seguridads" && typeof this.props.tipoElementoSeguridad !== "undefined" && this.props.tipoElementoSeguridad != "") {
            urlPart = urlPart + "&in[tipoElemento]=" + this.props.tipoElementoSeguridad;
        }
        if (target == "elemento_drenajes" && typeof this.props.tipoElementoDrenaje !== "undefined" && this.props.tipoElementoDrenaje != "") {
            urlPart = urlPart + "&in[tipoElemento]=" + this.props.tipoElementoDrenaje;
        }
        if (target == "elemento_alrededors" && typeof this.props.tipoElementoAlrededor !== "undefined" && this.props.tipoElementoAlrededor != "") {
            urlPart = urlPart + "&in[tipoElemento]=" + this.props.tipoElementoAlrededor;
        }
        if (target == "otros_elementos" && typeof this.props.tipoElementoOtro !== "undefined" && this.props.tipoElementoOtro != "") {
            urlPart = urlPart + "&in[tipoElemento]=" + this.props.tipoElementoOtro;
        }
        if (target == "elemento_reds" && typeof this.props.tipoElementoRed !== "undefined" && this.props.tipoElementoRed != "") {
            urlPart = urlPart + "&in[tipoElemento]=" + this.props.tipoElementoRed;
        }
        if (target == "puentes" && typeof this.props.tipoPuente !== "undefined" && this.props.tipoPuente != "") {
            urlPart = urlPart + "&elementos=" + this.props.tipoPuente;
        }

        fetch(urlAppjs + urlPart,
            {
                headers: {
                    'authorization': 'Bearer ' + this.token
                }
            })
            .then((response) => {
                return response.json()
            })
            .then((Elementos) => {
                var datos = [];
                var redirect = []
                let redirectA = "";
                Elementos["hydra:member"].map(item => {
                    switch (target) {
                        case "flexible_detalles":
                            datos = {
                                ubicaciones: { lat: item.segmento.latitud, lng: item.segmento.longitud },
                                icon: "/imagenes/senalesColombia/Elementos/segmento_inventaro/Flexible.png",
                                elemento: 'Segmento flexible',
                                codigo: item.segmento.fullCodigos,
                                abInicio: item.segmento.abInicioA,
                                tipoElemento: 'SegmentoFlexible',
                                unidadFuncional: item.unidadFuncional.fullCodigos,
                                tramo: item.tramo.fullCodigos,
                                segmento: item.segmento.fullCodigos,
                                url: item["@id"],
                            }
                            redirectA = item["@id"]
                            redirectA = redirectA.split('/');
                            redirectA = redirectA[2];
                            redirect.push(redirectA)
                            break;
                        case "rigido_detalles":
                            datos = {
                                ubicaciones: { lat: item.segmento.latitud, lng: item.segmento.longitud },
                                icon: "/imagenes/senalesColombia/Elementos/segmento_inventaro/Rigido.png",
                                elemento: 'Segmento Rígido',
                                codigo: item.segmento.fullCodigos,
                                abInicio: item.segmento.abInicioA,
                                tipoElemento: 'SegmentoFlexible',
                                unidadFuncional: item.unidadFuncional.fullCodigos,
                                tramo: item.tramo.fullCodigos,
                                segmento: item.segmento.fullCodigos,
                                url: item["@id"]
                            }
                            redirectA = item["@id"]
                            redirectA = redirectA.split('/');
                            redirectA = redirectA[2];
                            redirect.push(redirectA)
                            break;
                        case "adoquin_detalles":
                            datos = {
                                ubicaciones: { lat: item.segmento.latitud, lng: item.segmento.longitud },
                                icon: "/imagenes/senalesColombia/Elementos/segmento_inventaro/Adoquin.png",
                                elemento: 'Segmento Adoquin',
                                codigo: item.segmento.fullCodigos,
                                abInicio: item.segmento.abInicioA,
                                tipoElemento: 'SegmentoFlexible',
                                unidadFuncional: item.unidadFuncional.fullCodigos,
                                tramo: item.tramo.fullCodigos,
                                segmento: item.segmento.fullCodigos,
                                url: item["@id"]
                            }
                            redirectA = item["@id"]
                            redirectA = redirectA.split('/');
                            redirectA = redirectA[2];
                            redirect.push(redirectA)
                            break;
                        case "otro_detalles":
                            datos = {
                                ubicaciones: { lat: item.segmento.latitud, lng: item.segmento.longitud },
                                icon: "/imagenes/senalesColombia/Elementos/segmento_inventaro/Otros.png",
                                elemento: 'Segmento Otro',
                                codigo: item.segmento.fullCodigos,
                                abInicio: item.segmento.abInicioA,
                                tipoElemento: 'SegmentoFlexible',
                                unidadFuncional: item.unidadFuncional.fullCodigos,
                                tramo: item.tramo.fullCodigos,
                                segmento: item.segmento.fullCodigos,
                                url: item["@id"]
                            }
                            redirectA = item["@id"]
                            redirectA = redirectA.split('/');
                            redirectA = redirectA[2];
                            redirect.push(redirectA)
                            break;
                        case "seguridads":
                            datos = {
                                ubicaciones: { lat: item.latitudInicial, lng: item.longitudInicial },
                                icon: item.tipoElemento.src,
                                elemento: item.tipoElemento.nombre || 'Seguridad',
                                codigo: item.codigo,
                                abInicio: item.abInicio,
                                tipoElemento: item.tipoElemento.nombre,
                                unidadFuncional: item.unidadFuncional.fullCodigos,
                                tramo: item.tramo.fullCodigos,
                                segmento: item.segmento.fullCodigos,
                                url: item["@id"]
                            }
                            redirectA = item["@id"]
                            redirectA = redirectA.split('/');
                            redirectA = redirectA[2];
                            redirect.push(redirectA)
                            break;
                        case "elemento_alrededors":
                            datos = {
                                ubicaciones: { lat: item.latitudInicial, lng: item.longitudInicial },
                                icon: item.tipoElemento.src,
                                elemento: item.tipoElemento.nombre || 'Alrededores',
                                codigo: item.codigo,
                                abInicio: item.abInicio,
                                tipoElemento: item.tipoElemento.nombre,
                                unidadFuncional: item.unidadFuncional.fullCodigos,
                                tramo: item.tramo.fullCodigos,
                                segmento: item.segmento.fullCodigos,
                                url: item["@id"]
                            }
                            redirectA = item["@id"]
                            redirectA = redirectA.split('/');
                            redirectA = redirectA[2];
                            redirect.push(redirectA)
                            break;
                        case "elemento_drenajes":
                            datos = {
                                ubicaciones: { lat: item.latitudInicial, lng: item.longitudInicial },
                                icon: item.tipoElemento.src,
                                elemento: item.tipoElemento.nombre || 'Drenajes',
                                codigo: item.codigo,
                                abInicio: item.abInicio,
                                tipoElemento: item.tipoElemento.nombre,
                                unidadFuncional: item.unidadFuncional.fullCodigos,
                                tramo: item.tramo.fullCodigos,
                                segmento: item.segmento.fullCodigos,
                                url: item["@id"]
                            }
                            redirectA = item["@id"]
                            redirectA = redirectA.split('/');
                            redirectA = redirectA[2];
                            redirect.push(redirectA)
                            break;
                        case "elemento_senalizacions":
                            if (item.latitudInicial !== null && item.latitudInicial !== undefined) {
                                if (item.tipoElementoSenal['@id'] === '/tipo_elemento_senals/SV') {
                                    datos = {
                                        ubicaciones: { lat: item.latitudInicial, lng: item.longitudInicial },
                                        icon: item.senal.url,
                                        elemento: item.senal.nombre,
                                        codigo: item.codigo,
                                        abInicio: item.abInicio,
                                        tipoElemento: item.senal.nombre,
                                        unidadFuncional: item.unidadFuncional.fullCodigos,
                                        tramo: item.tramo.fullCodigos,
                                        segmento: item.segmento.fullCodigos,
                                        url: item["@id"]
                                    }
                                    redirectA = item["@id"]
                                    redirectA = redirectA.split('/');
                                    redirectA = redirectA[2];
                                    redirect.push(redirectA)
                                } else {
                                    datos = {
                                        ubicaciones: { lat: item.latitudInicial, lng: item.longitudInicial },
                                        icon: item.tipoSenal.url,
                                        elemento: item.tipoSenal.nombre,
                                        codigo: item.codigo,
                                        abInicio: item.abInicio,
                                        tipoElemento: item.tipoSenal.nombre,
                                        unidadFuncional: item.unidadFuncional.fullCodigos,
                                        tramo: item.tramo.fullCodigos,
                                        segmento: item.segmento.fullCodigos,
                                        url: item["@id"]
                                    }
                                    redirectA = item["@id"]
                                    redirectA = redirectA.split('/');
                                    redirectA = redirectA[2];
                                    redirect.push(redirectA)
                                }
                            }
                            break;
                        case "tuneles":
                            datos = {
                                ubicaciones: { lat: item.latitudInicial, lng: item.longitudInicial },
                                icon: 'imagenes/senalesColombia/Elementos/tunel/TNEL.png',
                                elemento: 'Señal',
                                codigo: item.codigo,
                                abInicio: item.abInicio,
                                tipoElemento: 'Tunel',
                                unidadFuncional: item.unidadFuncional.fullCodigos,
                                tramo: item.tramo.fullCodigos,
                                segmento: item.segmento.fullCodigos,
                                url: item["@id"]
                            }
                            redirectA = item["@id"]
                            redirectA = redirectA.split('/');
                            redirectA = redirectA[2];
                            redirect.push(redirectA)
                            break;
                        case "elemento_contencions":
                            datos = {
                                ubicaciones: { lat: item.latitudInicial, lng: item.longitudInicial },
                                icon: item.tipoElemento.src,
                                elemento: 'Señal',
                                codigo: item.codigo,
                                abInicio: item.abInicio,
                                tipoElemento: 'Tunel',
                                unidadFuncional: item.unidadFuncional.fullCodigos,
                                tramo: item.tramo.fullCodigos,
                                segmento: item.segmento.fullCodigos,
                                url: item["@id"]
                            }
                            redirectA = item["@id"]
                            redirectA = redirectA.split('/');
                            redirectA = redirectA[2];
                            redirect.push(redirectA)
                            break;
                        case "elemento_pontons":
                            datos = {
                                ubicaciones: { lat: item.latitudInicial, lng: item.longitudInicial },
                                icon: 'imagenes/senalesColombia/Elementos/ponton/PTON.png',
                                elemento: 'Pontón',
                                codigo: item.codigo,
                                abInicio: item.abInicio,
                                tipoElemento: 'Pontón',
                                unidadFuncional: item.unidadFuncional.fullCodigos,
                                tramo: item.tramo.fullCodigos,
                                segmento: item.segmento.fullCodigos,
                                url: item["@id"]
                            }
                            redirectA = item["@id"]
                            redirectA = redirectA.split('/');
                            redirectA = redirectA[2];
                            redirect.push(redirectA)
                            break;
                        case "puentes":
                            datos = {
                                ubicaciones: { lat: item.latitudInicial, lng: item.longitudInicial },
                                icon: item.elementos.src,
                                elemento: item.elementos.nombre || 'Puente',
                                codigo: item.codigo,
                                abInicio: item.abInicio,
                                tipoElemento: item.elementos.nombre,
                                tramo: item.tramo.fullCodigos,
                                segmento: item.segmento.fullCodigos,
                                unidadFuncional: item.unidad_funcional.fullCodigos,
                                url: item["@id"]

                                /*icon: '/imagenes/senalesColombia/Elementos/puentes/PNTE.png',
                                elemento: 'Puente',
                                codigo: item.codigo,
                                abInicio: item.abInicio,
                                tipoElemento: 'Puente',
                                tramo: item.tramo.fullCodigos,
                                segmento: item.segmento.fullCodigos,
                                url: item["@id"]*/
                            }
                            redirectA = item["@id"]
                            redirectA = redirectA.split('/');
                            redirectA = redirectA[2];
                            redirect.push(redirectA)
                            break;
                        case "otros_elementos":
                            datos = {
                                ubicaciones: { lat: item.latitudInicial, lng: item.longitudInicial },
                                icon: item.tipoElemento.src,
                                elemento: item.tipoElemento.nombre,
                                codigo: item.codigo,
                                abInicio: item.abInicio,
                                tipoElemento: 'Otros Elementos',
                                unidadFuncional: item.unidadFuncional.fullCodigos,
                                tramo: item.tramo.fullCodigos,
                                segmento: item.segmento.fullCodigos,
                                url: item["@id"]
                            }
                            redirectA = item["@id"]
                            redirectA = redirectA.split('/');
                            redirectA = redirectA[2];
                            redirect.push(redirectA)
                            break;
                        case "elemento_reds":
                            datos = {
                                ubicaciones: { lat: item.latitudInicial, lng: item.longitudInicial },
                                icon: item.tipoElemento.src,
                                elemento: item.tipoElemento.nombre,
                                codigo: item.codigo,
                                abInicio: item.abInicio,
                                tipoElemento: 'Redes',
                                unidadFuncional: item.unidadFuncional.fullCodigos,
                                tramo: item.tramo.fullCodigos,
                                segmento: item.segmento.fullCodigos,
                                url: item["@id"]
                            }
                            redirectA = item["@id"]
                            redirectA = redirectA.split('/');
                            redirectA = redirectA[2];
                            redirect.push(redirectA)
                            break;
                    }
                    datos.tipoFiltro = target
                    this.state.informacionGeneral.push(datos);
                    this.state.redirect.push(redirect)
                })
            }).finally(() => {
                this.setState({ isLoadedElements: this.state.isLoadedElements - 1 })
            })
            // .catch(function (error) {
            //     console.log(error)
            // });
    }
    removerFiltros(target) {
        let filtros = this.state.informacionGeneral;
        filtros.filter(item => item.tipoFiltro != target)
        this.setState({ informacionGeneral: filtros })
    }
    arregloIndicador() {
        const { indicador, kmlmapa } = this.state;
        // console.log(indicador)
        let largo = 0;
        let nuevokmlmapa = kmlmapa.map(uf => {
            let ordenValores = uf.valores.sort(function ordenarKml(a, b) {
                if (a.informacion.abscisaIni > b.informacion.abscisaIni) {
                    return 1;
                }
                if (a.informacion.abscisaIni < b.informacion.abscisaIni) {
                    return -1;
                }
                return 0;
            })
            return { "indicadores": [], "valores": ordenValores, "unidadFuncional": uf.unidadFuncional }
        })
        nuevokmlmapa.map(trazoPorUf => {
            trazoPorUf.valores.map(datokml => {
                largo++;
                if (!datokml.indicador) {
                    datokml['indicador'] = []
                }
                indicador.map(indiPorUf => {
                    if (trazoPorUf.unidadFuncional === indiPorUf.unidadFuncional) {
                        indiPorUf.valores.map(valor => {
                            if (datokml.informacion.abscisaIni >= (parseFloat(valor.abscisa_inicial) * 1000)
                                && datokml.informacion.abscisaFin <= (parseFloat(valor.abscisa_final) * 1000)
                            ) {//Se multiplica la abcisa de IRI por 1000 debido a que esta viene en Km y la del kml viene en metros
                                datokml.indicador.push(valor)
                            }
                        })
                    }
                })
            })
        })
        this.state.kmlmapa = nuevokmlmapa
        this.state.largokml = largo
        this.setState({ isLoadedIndicador: 1 })
        //console.log(nuevokmlmapa)
    }
    render() {
        const { classes } = this.props;
        const { redirect, coordenadasProyec, kmlmapa } = this.state;
        // let coordenadaMapa = { lat: 4.56, lng: -74 };
        let coordenadaMapa = { lat: 1.56, lng: -70 };
        // let GoogleMapstoken = "AIzaSyDnpZTyYCu_WV19ztwMGY90hvOQ-nEcLXs"; //old
        let GoogleMapstoken = "AIzaSyAgv5BQ_0nFzgvvOrEun9MK_pQOzJB5-sE"; //new 
        if (coordenadasProyec.length > 0) {
                coordenadaMapa = coordenadasProyec[0]
        }
        return (
            <Fragment>
                {this.state.isLoadedElements > 0 &&
                    <LinearProgress />
                }
                {coordenadasProyec.length > 0 &&
                    <MapWithAMarker
                        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GoogleMapstoken}&v=3.exp&libraries=geometry, rawing,places`}
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: document.documentElement.clientHeight + `px` }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                        // defaultCenter={{ lat: 4.56, lng: -74 }}
                        defaultCenter={coordenadaMapa}
                        info={this.state.informacionGeneral}
                        redirect={redirect}
                        positions={coordenadasProyec}
                        kmlmapa={kmlmapa}
                        numIntervalos={this.props.numIntervalos}
                        umbrales={this.props.umbrales}
                        indInv={this.props.indInv}
                        largokml={this.state.largokml}
                    />
                }
            </Fragment>
        )
    }
}

Mapa.propTypes = {
    classes: PropTypes.object.isRequired,
}
const MapaGeneral = withStyles(styles)(Mapa);


export default MapaGeneral;