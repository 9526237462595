import React from 'react';
import { TextInput} from 'react-admin';
import { SimpleForm } from './../../../SimpleForm';
import { withStyles } from '@material-ui/core';
import { PimsCreate } from './../../../Create';
import { required } from 'react-admin';
    
const styles = {
    first_field: { display: 'inline-block', width: '30%' },
    last_field: { display: 'inline-block', marginLeft: 32, width: '30%' }    
};

export const NivelesCreate = withStyles(styles)(({ classes, ...props }) => (
    <PimsCreate {...props} title="Crear nivel">
        <SimpleForm redirect="list">
            <TextInput source="tipo" label="Nivel" validate={[required()]}/>                
        </SimpleForm>
    </PimsCreate>
));