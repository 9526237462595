import React from 'react';
import { TabbedShowLayout,Tab, TextField, DateField,  NumberField,SelectField} from 'react-admin';
import { Show } from './../../Show';
import {  BulkDeleteWithConfirmButton  } from 'react-admin';
import { Fragment } from 'react';
import { translate } from 'react-admin';

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);


export const ReparacionPersShow = translate((props) => (
    <Show title="dynatest.resources.reparacion_localizadas.title.showTitle" {...props} bulkActionButtons={<PostBulkActionButtons />}>
        <TabbedShowLayout>            
             <Tab label="dynatest.resources.reparacion_localizadas.tabs.reparacionPers">   
                <SelectField source='tipoReparacion' choices={[
                    { id:'PFLEX', name:'dynatest.resources.reparacion_localizadas.select.tipoReparacion.PFLEX'},
                    { id:'PRIGD', name:'dynatest.resources.reparacion_localizadas.select.tipoReparacion.PRIGD'},
                    { id:'PART', name:'dynatest.resources.reparacion_localizadas.select.tipoReparacion.PART'},
                    { id:'POTR', name:'dynatest.resources.reparacion_localizadas.select.tipoReparacion.POTR'}, 
                ]}/>
                <div></div><div></div>
                <TextField source="nombre" />
                <TextField source="unidad" />        
                <NumberField source="costo" /> 
                <TextField source="observaciones" /> 
            </Tab> 
            <Tab label="dynatest.resources.reparacion_localizadas.tabs.auditoria">   
                <DateField source="createdAt" showTime/> 
                <TextField source="createdBy"/>
                <div></div>               
                <DateField source="updatedAt" showTime/>
                <TextField source="updatedBy"/>
                <div></div>    
            </Tab> 
        </TabbedShowLayout>
    </Show>
));