import React from 'react';
import NumberFormat from 'react-number-format';


export default function prFormatter(props){
    const { inputRef, ...other } = props;
    return (
        <NumberFormat  
            {...other}
            getInputRef={inputRef}
            prefix="K"
            decimalSeparator='+'
            decimalScale={3}
            fixedDecimalScale={true}
            allowNegative={false}        
        />
      );
}