import React from 'react';
import { List, Datagrid, EditButton, ShowButton,TextField,SelectField,NumberField } from 'react-admin';
import {  BulkDeleteWithConfirmButton, Loading  } from 'react-admin';
import { Fragment } from 'react';
import { translate } from 'react-admin';
import { CardActions, CreateButton, ExportButton } from 'react-admin';
const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);
const ListAction = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
        <ExportButton basePath={basePath}/>
    </CardActions>
);
const ListAction2 = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
    </CardActions>
);

export const ReparacionPersList = translate(({ permissions, ...props }) => {
    const { translate } = props;
    if (!permissions) return <Loading />;  
    return (
    <List {...props} 
        sort={{ field: 'updatedAt', order: 'DESC' }} 
        actions={permissions['reparaciones']  == 1 ? <ListAction /> : permissions['reparaciones']  == 2 ? <ListAction2 />: null}
        bulkActionButtons={permissions['reparaciones']  == 1 ? <PostBulkActionButtons />: null} >
        <Datagrid >
            <SelectField source='tipoReparacion' choices={[
                { id:'PFLEX', name:'dynatest.resources.reparacion_localizadas.select.tipoReparacion.PFLEX'},
                { id:'PRIGD', name:'dynatest.resources.reparacion_localizadas.select.tipoReparacion.PRIGD'},
                { id:'PART', name:'dynatest.resources.reparacion_localizadas.select.tipoReparacion.PART'},
                { id:'POTR', name:'dynatest.resources.reparacion_localizadas.select.tipoReparacion.POTR'},
            ]}/>
            <TextField source="nombre" />
            <TextField source="unidad" />        
            <NumberField source="costo" />  
            {permissions['reparaciones'] == 1 || permissions['reparaciones'] == 2 || permissions['reparaciones'] == 4 ? <ShowButton/>: null}
            {permissions['reparaciones'] == 1 || permissions['reparaciones'] == 2 ? <EditButton/>: null}
        </Datagrid>
    </List>
)});