import React from 'react';
import { TextField, ReferenceField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import config from '../../../config/config';
import loader from '../../../loader10.gif';
import { translate } from 'react-admin';
let urlAppjs = config.UrlServer;

class EvaluacionPorSegmento extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            datosCarga: [],
            record: [],
            parametros: [],
            fetched: []
        }
        this.calculoAreaAfectada.bind(this);

    }
    componentDidMount() {
        this.setState({ record: this.props.record });
        //console.log(this.props.record);
        let abini = parseFloat(this.props.record.abini);
        let abfin = parseFloat(this.props.record.abfin) + 0.1;
        let idcarga = this.props.record.cargaInformacionId;
        //console.log(abini + " " + abfin);
        if (this.props.record.indicador && this.props.record.indicador === "/parametros/1") {
            fetch(urlAppjs + "/indicadores4_g_e1s?cargaInformacionId=" + idcarga)//?property[<lt|gt|lte|gte|between>]=value
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.setState({ datosCarga: response })
                    //console.log(grupo1)
                })
        } else if (this.props.record.indicador && this.props.record.indicador === "/parametros/2") {
            fetch(urlAppjs + "/indicadores4_g_e2s?cargaInformacionId=" + idcarga)//?property[<lt|gt|lte|gte|between>]=value
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.setState({ datosCarga: response })
                    //console.log(grupo1)
                })
        } else if (this.props.record.indicador && this.props.record.indicador === "/parametros/3") {
            fetch(urlAppjs + "/indicadores4_g_e3s?cargaInformacionId=" + idcarga)//?property[<lt|gt|lte|gte|between>]=value
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.setState({ datosCarga: response })
                    //console.log(grupo1)
                })
        } else if (this.props.record.indicador && this.props.record.indicador === "/parametros/4") {
            fetch(urlAppjs + "/indicadores4_g_e4s?cargaInformacionId=" + idcarga)//?property[<lt|gt|lte|gte|between>]=value
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.setState({ datosCarga: response })
                    //console.log(grupo1)
                })
        } else if (this.props.record.indicador && this.props.record.indicador === "/parametros/5") {
            fetch(urlAppjs + "/indicadores4_g_e5s?cargaInformacionId=" + idcarga)//?property[<lt|gt|lte|gte|between>]=value
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.setState({ datosCarga: response })
                    //console.log(grupo1)
                })
        } else if (this.props.record.indicador && this.props.record.indicador === "/parametros/6") {
            fetch(urlAppjs + "/indicadores4_g_e6s?cargaInformacionId=" + idcarga)//?property[<lt|gt|lte|gte|between>]=value
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.setState({ datosCarga: response })
                    //console.log(grupo1)
                })
        } else if (this.props.record.indicador && this.props.record.indicador === "/parametros/7") {
            fetch(urlAppjs + "/indicadores4_g_e7s?cargaInformacionId=" + idcarga)//?property[<lt|gt|lte|gte|between>]=value
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.setState({ datosCarga: response })
                    //console.log(grupo1)
                })
        } else if (this.props.record.indicador && this.props.record.indicador === "/parametros/8") {
            fetch(urlAppjs + "/indicadores4_g_e8s?cargaInformacionId=" + idcarga)//?property[<lt|gt|lte|gte|between>]=value
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.setState({ datosCarga: response })
                    //console.log(grupo1)
                })
        } else if (this.props.record.indicador && this.props.record.indicador === "/parametros/9") {
            fetch(urlAppjs + "/indicadores4_g_e10s?cargaInformacionId=" + idcarga)//?property[<lt|gt|lte|gte|between>]=value
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.setState({ datosCarga: response })
                    //console.log(grupo1)
                })
        } else if (this.props.record.indicador && this.props.record.indicador === "/parametros/10") {
            fetch(urlAppjs + "/indicadores4_g_e11s?cargaInformacionId=" + idcarga)//?property[<lt|gt|lte|gte|between>]=value
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.setState({ datosCarga: response })
                    //console.log(grupo1)
                })
        } else if (this.props.record.indicador && this.props.record.indicador === "/parametros/11") {
            fetch(urlAppjs + "/indicadores4_g_e12s?cargaInformacionId=" + idcarga)//?property[<lt|gt|lte|gte|between>]=value
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.setState({ datosCarga: response })
                    //console.log(grupo1)
                })
        } else if (this.props.record.indicador && this.props.record.indicador === "/parametros/12") {
            fetch(urlAppjs + "/indicadores4_g_e13s?cargaInformacionId=" + idcarga)//?property[<lt|gt|lte|gte|between>]=value
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.setState({ datosCarga: response })
                    //console.log(grupo1)
                })
        } else if (this.props.record.indicador && this.props.record.indicador === "/parametros/13") {
            fetch(urlAppjs + "/indicadores4_g_e14s?cargaInformacionId=" + idcarga)//?property[<lt|gt|lte|gte|between>]=value
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.setState({ datosCarga: response })
                    //console.log(grupo1)
                })
        } else if (this.props.record.indicador && this.props.record.indicador === "/parametros/15") {
            fetch(urlAppjs + "/indicadores4_g_e16s?cargaInformacionId=" + idcarga)//?property[<lt|gt|lte|gte|between>]=value
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.setState({ datosCarga: response })
                    //console.log(grupo1)
                })
        } else if (this.props.record.indicador && this.props.record.indicador === "/parametros/16") {
            fetch(urlAppjs + "/indicadorespcis?cargaInformacionId=" + idcarga)//?property[<lt|gt|lte|gte|between>]=value
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.setState({ datosCarga: response })
                    //console.log(grupo1)
                })
        } else if (this.props.record.indicador && this.props.record.indicador === "/parametros/17") {
            fetch(urlAppjs + "/indicadoresastms?cargaInformacionId=" + idcarga)//?property[<lt|gt|lte|gte|between>]=value
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.setState({ datosCarga: response })
                    //console.log(grupo1)
                })
        } else if (this.props.record.indicador && this.props.record.indicador === "/parametros/21") {
            let idproyecto = localStorage.getItem('id_project');
            let indicador = this.props.record.id.replace("/indicadores_cargar_informacions/", "");
            fetch(urlAppjs + "/indicadores4_g_e22s?indicadorCargaInformacionId=" +indicador+"&cargaInformacionId=" + idcarga+"&segmento.tramo.unidadFuncional.ruta.proyecto="+idproyecto)//?property[<lt|gt|lte|gte|between>]=value
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.setState({ datosCarga: response })
                    //console.log(grupo1)
                })
        } else if (this.props.record.indicador && this.props.record.indicador === "/parametros/22") {
            fetch(urlAppjs + "/indicadores4_g_e24s?pagination=off&cargaInformacionId=" + idcarga)//?property[<lt|gt|lte|gte|between>]=value
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.setState({ datosCarga: response })
                    //console.log(grupo1)
                })
        } else if (this.props.record.indicador && this.props.record.indicador === "/parametros/23") {
            fetch(urlAppjs + "/indicadores4_g_e25s?pagination=off&cargaInformacionId=" + idcarga)
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.setState({ datosCarga: response })
                    //console.log(grupo1)
                })
        }

        let idproyecto = localStorage.getItem('id_project');
        fetch(urlAppjs + "/parametros_grupos?proyecto=" + idproyecto)
            .then((response) => {
                return response.json()
            })
            .then((response) => {
                let resultado = [];
                if (response) {
                    resultado = response['hydra:member'][0]
                    this.setState({ parametros: resultado })
                    // console.log(resultado)
                }

            })

    }
    formatoabscisa(valor) {
        if (this.props.record.indicador === "/parametros/1") {
            let valor2 = valor - parseInt(valor);
            valor2 = parseFloat(valor2).toFixed(2);
            return "K" + parseInt(valor) + "+" + valor2.replace("0.", "") + "0";
        }else if (this.props.record.indicador === "/parametros/16") {
            let valor2 = valor - parseInt(valor);
            valor2 = parseFloat(valor2).toFixed(1);
            return "K" + parseInt(valor) + "+" + valor2.replace("0.", "") + "00";
        }
        else if (this.props.record.indicador === "/parametros/2" || this.props.record.indicador === "/parametros/4" || this.props.record.indicador === "/parametros/5") {
            let valor2 = valor - parseInt(valor);
            valor2 = parseFloat(valor2).toFixed(2);
            return "K" + parseInt(valor) + "+" + valor2.replace("0.", "") + "0";
        }
        else {
            let valor2 = valor - parseInt(valor);
            valor2 = parseFloat(valor2).toFixed(3);
            return "K" + parseInt(valor) + "+" + valor2.replace("0.", "");
        }
    }
    formatovalores(valor, decimal) {
        let value = parseFloat(valor).toFixed(decimal).replace(".", ",");
        return value;
    }
    ColorPCI(valor) {
        if (valor >= 86) {
            return "rgb(0, 176, 80)"
        } else if (valor >= 71 && valor < 86) {
            return "rgb(146, 208, 80)"
        } else if (valor >= 56 && valor < 71) {
            return "rgb(255, 255, 0)"
        } else if (valor >= 41 && valor < 56) {
            return "rgb(255, 0, 0)"
        } else if (valor >= 26 && valor < 41) {
            return "rgb(192, 0, 0)"
        } else if (valor >= 11 && valor < 26) {
            return "rgb(148, 54, 52)"
        } else if (valor >= 0 && valor < 11) {
            return "rgb(166, 166, 166)"
        }
    }

    calculoAreaAfectada(baseEnSegmento, registradaEnIndicador) {
        // console.log(baseEnSegmento)
        // console.log(registradaEnIndicador)
        return (parseFloat(registradaEnIndicador) / parseFloat(baseEnSegmento)) * 100;
    }
    render() {
        const { translate } = this.props;
        const { datosCarga } = this.state;
        const { parametros } = this.state;
        let valorpromedio = 3;
        let valorpuntual = 1;
        if (parametros && parametros.evaluacion) {
            if (this.props.record.indicador === "/parametros/1") { valorpromedio = parametros.evaluacion[0].valormaxMedio; valorpuntual = parametros.evaluacion[0].valormaxPuntual; }
            else if (this.props.record.indicador === "/parametros/2") { valorpromedio = parametros.evaluacion[1].valormaxMedio; valorpuntual = parametros.evaluacion[1].valormaxPuntual; }
            else if (this.props.record.indicador === "/parametros/3") { valorpromedio = parametros.evaluacion[2].valormaxMedio; valorpuntual = parametros.evaluacion[2].valormaxPuntual; }
            else if (this.props.record.indicador === "/parametros/4") { valorpromedio = parametros.evaluacion[3].valormaxMedio; valorpuntual = parametros.evaluacion[3].valormaxPuntual; }
            else if (this.props.record.indicador === "/parametros/6") { valorpromedio = parametros.evaluacion[5].valormaxMedio; valorpuntual = parametros.evaluacion[5].valormaxPuntual; }
            else if (this.props.record.indicador === "/parametros/7") { valorpromedio = parametros.evaluacion[6].valormaxMedio; valorpuntual = parametros.evaluacion[6].valormaxPuntual; }
            else if (this.props.record.indicador === "/parametros/8") { valorpromedio = parametros.evaluacion[7].valormaxPuntual; valorpuntual = parametros.evaluacion[7].valormaxMedio; }
            else if (this.props.record.indicador === "/parametros/9") { valorpromedio = parametros.evaluacion[8].valormaxPuntual; valorpuntual = parametros.evaluacion[8].valormaxMedio; }
            else if (this.props.record.indicador === "/parametros/10") { valorpromedio = parametros.evaluacion[9].valormaxPuntual; valorpuntual = parametros.evaluacion[9].valormaxMedio; }
            else if (this.props.record.indicador === "/parametros/11") { valorpromedio = parametros.evaluacion[10].valormaxPuntual; valorpuntual = parametros.evaluacion[10].valormaxMedio; }
            else if (this.props.record.indicador === "/parametros/12") { valorpromedio = parametros.evaluacion[11].valormaxPuntual; valorpuntual = parametros.evaluacion[11].valormaxMedio; }
            else if (this.props.record.indicador === "/parametros/13") { valorpromedio = parametros.evaluacion[12].valormaxPuntual;}
            else if (this.props.record.indicador === "/parametros/21") { valorpromedio = parametros.evaluacion[20].valormaxMedio; valorpuntual = parametros.evaluacion[20].valormaxPuntual; }
            else if (this.props.record.indicador === "/parametros/22") { valorpromedio = parametros.evaluacion[21].valormaxMedio; valorpuntual = parametros.evaluacion[21].valormaxPuntual; }
            else if (this.props.record.indicador === "/parametros/23") { valorpromedio = parametros.evaluacion[22].valormaxMedio; valorpuntual = parametros.evaluacion[22].valormaxPuntual; }

        }
        if (datosCarga['hydra:member'] && parametros && parametros != [] && this.props.record.indicador === "/parametros/1") {
            let registros = datosCarga['hydra:member'];
            let segmentos = [];
            let promedioI = [];
            let promedioD = [];
            let promedioC = [];
            let promedioCalzada = [];
            let cumplimientoI = [];
            let cumplimientoD = [];
            let cumplimientoIndicador = [];
            let cumplimientoPromCalzada = []
            let cumplimientoPuntCalzada = []
            let aux = -1;
            let datosprueba = [];
            //console.log(registros)
            registros.map((data, i) => {
                if (data.segmento.codigo != aux) {
                    segmentos.push(data.segmento.fullCodigo);
                    aux = data.segmento.codigo;
                }
            })
            registros.map((data, i) => {
                for (let i = 0; i < segmentos.length; i++) {
                    if (!datosprueba[i]) {
                        datosprueba[i] = {
                            iriCizq: [],
                            iriCder: [],
                            cumplimientoI: [],
                            cumplimientoD: [],
                            cumplimientoC: [],
                            abscisaini: [],
                            abscisafin: [],
                            eventoCizq: [],
                            singularidadCizq: [],
                            eventoCder: [],
                            singularidadCder: [],
                            contSingularidadIzq: [],
                            contSingularidadDer: [],
                        };
                    }
                    if (data.segmento.fullCodigo === segmentos[i]) {
                        if(data.singularidadCizq == ""){
                            datosprueba[i]['contSingularidadIzq'].push(data.iriCizq);
                        }else{
                            datosprueba[i]['contSingularidadIzq'].push(0);
                        }
                        if(data.singularidadCder == ""){
                            datosprueba[i]['contSingularidadDer'].push(data.iriCder);
                        }else{
                            datosprueba[i]['contSingularidadDer'].push(0);
                        }
                        datosprueba[i]['iriCizq'].push(data.iriCizq);
                        datosprueba[i]['iriCder'].push(data.iriCder);
                        datosprueba[i]['cumplimientoI'].push(data.cumplimientoCizq);
                        datosprueba[i]['cumplimientoD'].push(data.cumplimientoCder);
                        datosprueba[i]['cumplimientoC'].push(data.cumplimientoCalzada);
                        datosprueba[i]['abscisaini'].push(data.abini);
                        datosprueba[i]['abscisafin'].push(data.abfin);
                        datosprueba[i]['eventoCizq'].push(data.eventoCizq);
                        datosprueba[i]['singularidadCizq'].push(data.singularidadCizq);
                        datosprueba[i]['eventoCder'].push(data.eventoCder);
                        datosprueba[i]['singularidadCder'].push(data.singularidadCder);

                    }
                }
            })
            //console.log(datosprueba)
            for (let i = 0; i < datosprueba.length; i++) {
                let promedioIzq = 0;
                let contIriIzq = 0;
                let contIriDer = 0;
                let promedioDer = 0;
                let cumplimientoder = 0;
                let cumplimientoizq = 0;
                let cumplimientoCal = 0;
                for (let j = 0; j < datosprueba[i]['singularidadCizq'].length; j++) {
                    if(datosprueba[i]['singularidadCizq'][j] === ""){
                        contIriIzq++;
                    }
                }
                for (let j = 0; j < datosprueba[i]['singularidadCder'].length; j++) {
                    if(datosprueba[i]['singularidadCder'][j] === ""){
                        contIriDer++;
                    }
                }
                for (let j = 0; j < datosprueba[i]['contSingularidadIzq'].length; j++) { 
                    promedioIzq += datosprueba[i]['contSingularidadIzq'][j]; 
                }
                for (let j = 0; j < datosprueba[i]['contSingularidadDer'].length; j++) {
                    promedioDer += datosprueba[i]['contSingularidadDer'][j];
                }
                for (let j = 0; j < datosprueba[i]['cumplimientoI'].length; j++) {
                    if (datosprueba[i]['cumplimientoI'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')) {
                        cumplimientoizq++
                    }
                }
                for (let j = 0; j < datosprueba[i]['cumplimientoD'].length; j++) {
                    if (datosprueba[i]['cumplimientoD'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')) {
                        cumplimientoder++
                    }
                }
                for (let j = 0; j < datosprueba[i]['cumplimientoC'].length; j++) {
                    if (datosprueba[i]['cumplimientoC'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')) {
                        cumplimientoCal++
                    }
                }
                if(contIriIzq == 0){
                    promedioI[i] = 0;
                }else{
                    promedioI[i] = promedioIzq / (contIriIzq);
                }
                if(contIriDer == 0){
                    promedioD[i]= 0;
                }else{
                    promedioD[i] = promedioDer / (contIriDer);
                }
                
                promedioCalzada[i] = Math.max(promedioI[i], promedioD[i]);
                if (promedioCalzada[i] > valorpromedio) {
                    cumplimientoPromCalzada[i] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')
                }
                else {
                    cumplimientoPromCalzada[i] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')
                }

                cumplimientoI[i] = cumplimientoizq;
                cumplimientoD[i] = cumplimientoder;
                promedioC[i] = cumplimientoCal;
                if (promedioC[i] > 0) {
                    cumplimientoPuntCalzada[i] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')
                }
                else {
                    cumplimientoPuntCalzada[i] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')
                }
                if (promedioCalzada[i] > valorpromedio || promedioC[i] > 0) {
                    cumplimientoIndicador[i] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')
                }
                else {
                    cumplimientoIndicador[i] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')
                }
            }

            //console.log(segmentos)
            //console.log(promedioI)
            const MostrarDetalles = (valor, valor2) => {
                //alert("Prueba"+valor);
                //console.log(valor)
                let det = document.getElementById('detalles_' + valor)
                let most = document.getElementById('mostrar_' + valor)
                if (det) {
                    let disp = det.style.display;
                    if (disp != "none") {
                        det.style.display = "none"
                    }
                    else {
                        det.style.display = ""
                    }
                }
                if (most) {
                    let html = most.innerHTML;
                    if (html != translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')) {
                        most.innerHTML = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')
                    }
                    else {
                        most.innerHTML = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.ocultar')
                    }
                }

                //console.log(valor2)
            }
            return (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.SEGMENTO')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.PromedioCarrilI')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.IncumplPuntualesCarrilI')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.PromedioCarrilD')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.IncumplPuntualesCarrilD')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.ValorMedioIRI_mm')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CumplimientoValorPromedio')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CumplimientoValorPuntual')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CumplimientoIndicadorSegmento')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Visualizar')}</TableCell>
                        </TableRow>
                    </TableHead>
                    {promedioI.map((row, i) => (
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ textAlign: "left" }}>{segmentos[i]}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>
                                    {
                                       
                                     this.formatovalores(row, 2)
                                    }
                                </TableCell>
                                <TableCell style={{ textAlign: "right" }}>{cumplimientoI[i]}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(promedioD[i], 2)}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{cumplimientoD[i]}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(promedioCalzada[i], 2)}</TableCell>
                                {cumplimientoPromCalzada[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left", color: "red" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                }
                                {cumplimientoPromCalzada[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                }
                                {cumplimientoPromCalzada[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica')}</TableCell>
                                }
                                {cumplimientoPuntCalzada[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left", color: "red" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                }
                                {cumplimientoPuntCalzada[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                }
                                {cumplimientoPuntCalzada[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica')}</TableCell>
                                }
                                {cumplimientoIndicador[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left", color: "red" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                }
                                {cumplimientoIndicador[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                }
                                {cumplimientoIndicador[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica')}</TableCell>
                                }

                                <TableCell style={{ textAlign: "right" }}><span id={"mostrar_" + i} style={{ color: "blue", cursor: "pointer" }} onClick={(evt) => MostrarDetalles(i, evt)}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')}</span></TableCell>
                            </TableRow>
                            <TableRow style={{ display: "none" }} id={"detalles_" + i}>
                                <TableCell colSpan="10">
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ textAlign: "center" }} colSpan="2">{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.abscisa')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }} colspan="4">{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.carrilIzquierdoDescendente')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }} colspan="4">{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.carrilDerechoAscendente')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }} rowSpan="2">{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.cumplimientoIndicadorCalzada')}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.AbscisaInicio')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.AbscisaFinal')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.IRImm')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CumplimientoIndicPuntual')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Evento')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Singularidad')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.IRImm_m')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CumplimientoIndicPuntual')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Evento')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Singularidad')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {datosprueba[i]['cumplimientoI'].map((data, j) => (
                                                <TableRow>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(datosprueba[i]['abscisaini'][j])}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(datosprueba[i]['abscisafin'][j])}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['iriCizq'][j], 2)}</TableCell>
                                                    
                                                    {datosprueba[i]['singularidadCizq'][j] != "" &&
                                                        <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica')}</TableCell>
                                                    }
                                                    
                                                    {datosprueba[i]['iriCizq'][j] > valorpuntual && datosprueba[i]['singularidadCizq'][j] === "" &&
                                                        <TableCell style={{ color: "red", textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                                    }
                                                    {datosprueba[i]['iriCizq'][j] <= valorpuntual && datosprueba[i]['singularidadCizq'][j] === "" &&
                                                        <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                                    }
                                                    
                            

                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['eventoCizq'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['singularidadCizq'][j]}</TableCell>
                                                    
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['iriCder'][j], 2)}</TableCell>

                                                    {datosprueba[i]['singularidadCder'][j] != "" &&
                                                        <TableCell style={{textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica')}</TableCell>
                                                    }
                                                    {datosprueba[i]['iriCder'][j] > valorpuntual && datosprueba[i]['singularidadCder'][j] === "" &&
                                                        <TableCell style={{ color: "red", textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                                    }
                                                    {datosprueba[i]['iriCder'][j] <= valorpuntual && datosprueba[i]['singularidadCder'][j] === "" &&
                                                        <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                                    }

                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['eventoCder'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['singularidadCder'][j]}</TableCell>
                                                    {datosprueba[i]['cumplimientoC'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                                        <TableCell style={{ color: "red", textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                                    }
                                                    {datosprueba[i]['cumplimientoC'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple') &&
                                                        <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                                    }
                                                    {datosprueba[i]['cumplimientoC'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica') &&
                                                        <TableCell style={{color: "red", textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica')}</TableCell>
                                                    }
                                                </TableRow>
                                            ))
                                            }
                                        </TableBody>
                                    </Table>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ))}

                </Table>

            )
        } else if (datosCarga['hydra:member'] && parametros && parametros != [] && this.props.record.indicador === "/parametros/2") {
            let registros = datosCarga['hydra:member'];
            let segmentos = [];
            let promedioI = [];
            let promedioD = [];
            let promedioC = [];
            let promedioCalzada = [];
            let cumplimientoI = [];
            let cumplimientoD = [];
            let cumplimientoIndicador = [];
            let cumplimientoPromCalzada = []
            let cumplimientoPuntCalzada = []
            let aux = -1;
            let datosprueba = [];
            //console.log(registros)
            registros.map((data, i) => {
                if (data.segmento.codigo != aux) {
                    segmentos.push(data.segmento.fullCodigo);
                    aux = data.segmento.codigo;
                }
            })
            registros.map((data, i) => {
                for (let i = 0; i < segmentos.length; i++) {
                    if (!datosprueba[i]) {
                        datosprueba[i] = {
                            iriCizq: [],
                            iriCder: [],
                            cumplimientoI: [],
                            cumplimientoD: [],
                            cumplimientoC: [],
                            abscisaini: [],
                            abscisafin: [],
                            eventoCizq: [],
                            singularidadCizq: [],
                            eventoCder: [],
                            singularidadCder: [],
                            contSingularidadIzq:[],
                            contSingularidadDer:[]
                        };
                    }
                    if (data.segmento.fullCodigo === segmentos[i]) {
                        
                        if(data.singularidadCizq == ""){
                            datosprueba[i]['contSingularidadIzq'].push(data.ahuellamientoCizq);
                        }else{
                            datosprueba[i]['contSingularidadIzq'].push(0);
                        }
                        if(data.singularidadCder == ""){
                            datosprueba[i]['contSingularidadDer'].push(data.ahuellamientoCder);
                        }else{
                            datosprueba[i]['contSingularidadDer'].push(0);
                        }
                        datosprueba[i]['iriCizq'].push(data.ahuellamientoCizq);
                        datosprueba[i]['iriCder'].push(data.ahuellamientoCder);
                        datosprueba[i]['cumplimientoI'].push(data.cumplimientoCizq);
                        datosprueba[i]['cumplimientoD'].push(data.cumplimientoCder);
                        datosprueba[i]['cumplimientoC'].push(data.cumplimientoCalzada);
                        datosprueba[i]['abscisaini'].push(data.abini);
                        datosprueba[i]['abscisafin'].push(data.abfin);
                        datosprueba[i]['eventoCizq'].push(data.eventoCizq);
                        datosprueba[i]['singularidadCizq'].push(data.singularidadCizq);
                        datosprueba[i]['eventoCder'].push(data.eventoCder);
                        datosprueba[i]['singularidadCder'].push(data.singularidadCder);

                    }
                }
            })
            //console.log(datosprueba)
            for (let i = 0; i < datosprueba.length; i++) {
                let promedioIzq = 0;
                let contIriIzq = 0;
                let contIriDer = 0;
                let promedioDer = 0;
                let cumplimientoder = 0;
                let cumplimientoizq = 0;
                let cumplimientoCal = 0;

                for (let j = 0; j < datosprueba[i]['singularidadCizq'].length; j++) {
                    if(datosprueba[i]['singularidadCizq'][j] === ""){
                        contIriIzq++;
                    }
                }
                for (let j = 0; j < datosprueba[i]['singularidadCder'].length; j++) {
                    if(datosprueba[i]['singularidadCder'][j] === ""){
                        contIriDer++;
                    }
                }
                for (let j = 0; j < datosprueba[i]['contSingularidadIzq'].length; j++) { 
                    promedioIzq += datosprueba[i]['contSingularidadIzq'][j]; 
                }
                for (let j = 0; j < datosprueba[i]['contSingularidadDer'].length; j++) {
                    promedioDer += datosprueba[i]['contSingularidadDer'][j];
                }
                for (let j = 0; j < datosprueba[i]['cumplimientoI'].length; j++) {
                    if (datosprueba[i]['cumplimientoI'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')) {
                        cumplimientoizq++
                    }
                }
                for (let j = 0; j < datosprueba[i]['cumplimientoD'].length; j++) {
                    if (datosprueba[i]['cumplimientoD'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')) {
                        cumplimientoder++
                    }
                }
                for (let j = 0; j < datosprueba[i]['cumplimientoC'].length; j++) {
                    if (datosprueba[i]['cumplimientoC'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')) {
                        cumplimientoCal++
                    }
                }
                if(contIriIzq == 0){
                    promedioI[i] = 0;
                }else{
                    promedioI[i] = promedioIzq / (contIriIzq);
                }
                if(contIriDer == 0){
                    promedioD[i]= 0;
                }else{
                    promedioD[i] = promedioDer / (contIriDer);
                }
                // promedioI[i] = promedioIzq / (datosprueba[i]['iriCizq'].length);
                // promedioD[i] = promedioDer / (datosprueba[i]['iriCder'].length);
                promedioCalzada[i] = Math.max(promedioI[i], promedioD[i]);
                if (promedioCalzada[i] > valorpromedio) {
                    cumplimientoPromCalzada[i] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')
                }
                else {
                    cumplimientoPromCalzada[i] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')
                }

                cumplimientoI[i] = cumplimientoizq;
                cumplimientoD[i] = cumplimientoder;
                promedioC[i] = cumplimientoCal;
                if (promedioC[i] > 0) {
                    cumplimientoPuntCalzada[i] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')
                }
                else {
                    cumplimientoPuntCalzada[i] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')
                }
                if (promedioCalzada[i] > valorpromedio || promedioC[i] > 0) {
                    cumplimientoIndicador[i] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')
                }
                else {
                    cumplimientoIndicador[i] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')
                }
            }

            //console.log(segmentos)
            //console.log(promedioI)
            const MostrarDetalles = (valor, valor2) => {
                //alert("Prueba"+valor);
                //console.log(valor)
                let det = document.getElementById('detalles_' + valor)
                let most = document.getElementById('mostrar_' + valor)
                if (det) {
                    let disp = det.style.display;
                    if (disp != "none") {
                        det.style.display = "none"
                    }
                    else {
                        det.style.display = ""
                    }
                }
                if (most) {
                    let html = most.innerHTML;
                    if (html != translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')) {
                        most.innerHTML = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')
                    }
                    else {
                        most.innerHTML = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.ocultar')
                    }
                }

                //console.log(valor2)
            }
            return (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.SEGMENTO')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.PromedioCarrilI')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.IncumplPuntualesCarrilI')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.PromedioCarrilD')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.IncumplPuntualesCarrilD')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.ValorMedAhuellamiento_mm')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CumplimientoValorPromedio')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CumplimientoValorPuntual')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CumplimientoIndicadorSegmento')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Visualizar')}</TableCell>
                        </TableRow>
                    </TableHead>
                    {promedioI.map((row, i) => (
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ textAlign: "left" }}>{segmentos[i]}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(row, 2)}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{cumplimientoI[i]}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(promedioD[i], 2)}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{cumplimientoD[i]}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(promedioCalzada[i], 2)}</TableCell>
                                {cumplimientoPromCalzada[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left", color: "red" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                }
                                {cumplimientoPromCalzada[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                }
                                {cumplimientoPromCalzada[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica') &&
                                    <TableCell style={{ textAlign: "left", color: "red" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica')}</TableCell>
                                }
                                {cumplimientoPuntCalzada[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left", color: "red" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                }
                                {cumplimientoPuntCalzada[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                }
                                {cumplimientoPuntCalzada[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica') &&
                                    <TableCell style={{ textAlign: "left", color: "red" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica')}</TableCell>
                                }
                                {cumplimientoIndicador[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left", color: "red" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                }
                                {cumplimientoIndicador[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                }
                                {cumplimientoIndicador[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica') &&
                                    <TableCell style={{ textAlign: "left", color: "red" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica')}</TableCell>
                                }
                                <TableCell style={{ textAlign: "right" }}><span id={"mostrar_" + i} style={{ color: "blue", cursor: "pointer" }} onClick={(evt) => MostrarDetalles(i, evt)}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')}</span></TableCell>
                            </TableRow>
                            <TableRow style={{ display: "none" }} id={"detalles_" + i}>
                                <TableCell colSpan="10">
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ textAlign: "center" }} colSpan="2">{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.abscisa')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }} colspan="4">{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.carrilIzquierdoDescendente')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }} colspan="4">{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.carrilDerechoAscendente')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }} rowSpan="2">{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.cumplimientoIndicadorCalzada')}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.AbscisaInicio')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.AbscisaFinal')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Ahuellamiento_mm')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CumplimientoIndicPuntual')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Evento')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Singularidad')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Ahuellamiento_mm')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CumplimientoIndicPuntual')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Evento')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Singularidad')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {datosprueba[i]['cumplimientoI'].map((data, j) => (
                                                <TableRow>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(datosprueba[i]['abscisaini'][j])}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(datosprueba[i]['abscisafin'][j])}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['iriCizq'][j], 2)}</TableCell>
                                                    {datosprueba[i]['cumplimientoI'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                                        <TableCell style={{ color: "red", textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                                    }
                                                    {datosprueba[i]['cumplimientoI'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple') &&
                                                        <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                                    }
                                                    {datosprueba[i]['cumplimientoI'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica') &&
                                                        <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica')}</TableCell>
                                                    }
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['eventoCizq'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['singularidadCizq'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['iriCder'][j], 2)}</TableCell>
                                                    {datosprueba[i]['cumplimientoD'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                                        <TableCell style={{ color: "red", textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                                    }
                                                    {datosprueba[i]['cumplimientoD'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple') &&
                                                        <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                                    }
                                                    {datosprueba[i]['cumplimientoD'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica') &&
                                                        <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica')}</TableCell>
                                                    }
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['eventoCder'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['singularidadCder'][j]}</TableCell>
                                                    {datosprueba[i]['cumplimientoC'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                                        <TableCell style={{ color: "red", textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                                    }
                                                    {datosprueba[i]['cumplimientoC'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple') &&
                                                        <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                                    }
                                                    {datosprueba[i]['cumplimientoC'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica') &&
                                                        <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica')}</TableCell>
                                                    }
                                                </TableRow>
                                            ))
                                            }
                                        </TableBody>
                                    </Table>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ))}

                </Table>

            )
        } else if (datosCarga['hydra:member'] && parametros && parametros != [] && this.props.record.indicador === "/parametros/3") {
            let registros = datosCarga['hydra:member'];
            let segmentos = [];
            let AreaCalzada = [];
            let AreaAfectadaTotal = [];
            let AreaAfectadaPorcentaje = [];
            let cumplimiento = [];
            let aux = -1;
            let datosprueba = [];
            //console.log(registros)
            registros.map((data, i) => {
                if (data.segmento.codigo != aux) {
                    segmentos.push(data.segmento.fullCodigo);
                    aux = data.segmento.codigo;
                }
            })
            registros.map((data, i) => {
                for (let i = 0; i < segmentos.length; i++) {
                    if (!datosprueba[i]) {
                        datosprueba[i] = {
                            abscisa: [],
                            tipofisura: [],
                            severidad: [],
                            largo: [],
                            ancho: [],
                            area: [],
                            areaverdadera: []
                        };
                    }
                    if (data.segmento.fullCodigo === segmentos[i]) {
                        datosprueba[i]['abscisa'].push(data.abscisa);
                        datosprueba[i]['tipofisura'].push(data.tipoFisura);
                        datosprueba[i]['severidad'].push(data.severidad);
                        datosprueba[i]['largo'].push(data.largo);
                        datosprueba[i]['ancho'].push(data.ancho);
                        datosprueba[i]['area'].push(data.area);
                        let largotemp = data.flexibleDetalles.geometriaSeg[0].geometriaLargo
                        let anchotemp = data.flexibleDetalles.geometriaSeg[0].geometriaAncho
                        let anchoctemp = data.flexibleDetalles.geometriaSeg[0].geometriaAnchoCarril
                        // let areatemp = largotemp * anchotemp * anchoctemp;
                        let areatemp = largotemp * anchotemp;
                        datosprueba[i]['areaverdadera'].push(areatemp);
                    }
                }
            })
            //console.log(datosprueba)
            for (let i = 0; i < datosprueba.length; i++) {
                console.log("datos");
                
                let areaAfectada = 0;
                let areaAfectadaPorc = 0;
                let sumaArea = 0;
                let areaCumplimiento = "No Cumple";
                for (let j = 0; j < datosprueba[i]['area'].length; j++) {
                    if (datosprueba[i]['severidad'][j] == "3" || datosprueba[i]['severidad'][j] == "FC-3") {
                        // if (datosprueba[i]['ancho'][j] == "0.6" && datosprueba[i]['severidad'][j] == "3") {
                        sumaArea += parseFloat(datosprueba[i]['area'][j]);
                    }
                    console.log(datosprueba[i])
                }
                areaAfectadaPorc = (sumaArea / datosprueba[i]['areaverdadera'][0]) * 100;
                //console.log("areaafect="+areaAfectadaPorc+" puntual="+valorpuntual)
                if (parseFloat(areaAfectadaPorc) < parseFloat(valorpuntual)) {
                    areaCumplimiento = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')
                } else {
                    areaCumplimiento = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')
                }
                cumplimiento.push(areaCumplimiento);
                AreaAfectadaPorcentaje.push(parseFloat(areaAfectadaPorc).toFixed(2));
                AreaCalzada.push(datosprueba[i]['areaverdadera'][0])
                AreaAfectadaTotal.push(sumaArea);

            }

            //            console.log(AreaAfectadaTotal)
            //console.log(promedioI)
            const MostrarDetalles = (valor, valor2) => {
                //alert("Prueba"+valor);
                //console.log(valor)
                let det = document.getElementById('detalles_' + valor)
                let most = document.getElementById('mostrar_' + valor)
                if (det) {
                    let disp = det.style.display;
                    if (disp != "none") {
                        det.style.display = "none"
                    }
                    else {
                        det.style.display = ""
                    }
                }
                if (most) {
                    let html = most.innerHTML;
                    if (html != translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')) {
                        most.innerHTML = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')
                    }
                    else {
                        most.innerHTML = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.ocultar')
                    }
                }

                //console.log(valor2)
            }
            return (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.SEGMENTO')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.areaCalzada')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.areaAfectada_m')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.areaAfectada')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.cumplimientoIndicador')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Visualizar')}</TableCell>
                        </TableRow>
                    </TableHead>
                    {segmentos.map((row, i) => (
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ textAlign: "left" }}>{segmentos[i]}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{AreaCalzada[i]}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(AreaAfectadaTotal[i], 2)}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(AreaAfectadaPorcentaje[i], 2)}</TableCell>
                                {cumplimiento[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left", color: "red" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                }
                                {cumplimiento[i] != translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                }
                                <TableCell style={{ textAlign: "right" }}><span id={"mostrar_" + i} style={{ color: "blue", cursor: "pointer" }} onClick={(evt) => MostrarDetalles(i, evt)}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')}</span></TableCell>
                            </TableRow>
                            <TableRow style={{ display: "none" }} id={"detalles_" + i}>
                                <TableCell colSpan="6">
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.AbscisaFisura')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.TipoFisura')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Severidad')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Largo_m')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Ancho_m')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Area_m')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {datosprueba[i]['area'].map((data, j) => (
                                                <TableRow>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(datosprueba[i]['abscisa'][j])}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['tipofisura'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['severidad'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['largo'][j], 2)}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['ancho'][j], 2)}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['area'][j], 2)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ))}

                </Table>

            )
        } else if (datosCarga['hydra:member'] && parametros && parametros != [] && this.props.record.indicador === "/parametros/4") {
            let registros = datosCarga['hydra:member'];
            let segmentos = [];
            let promedioI = [];
            let promedioD = [];
            let promedioC = [];
            let promedioCalzada = [];
            let cumplimientoI = [];
            let cumplimientoD = [];
            let cumplimientoIndicador = [];
            let cumplimientoPromCalzada = []
            let cumplimientoPuntCalzada = []
            let aux = -1;
            let datosprueba = [];
            //console.log(registros)
            registros.map((data, i) => {
                if (data.segmento.codigo != aux) {
                    segmentos.push(data.segmento.fullCodigo);
                    aux = data.segmento.codigo;
                }
            })
            registros.map((data, i) => {
                for (let i = 0; i < segmentos.length; i++) {
                    if (!datosprueba[i]) {
                        datosprueba[i] = {
                            iriCizq: [],
                            iriCder: [],
                            cumplimientoI: [],
                            cumplimientoD: [],
                            cumplimientoC: [],
                            abscisaini: [],
                            abscisafin: [],
                            eventoCizq: [],
                            singularidadCizq: [],
                            eventoCder: [],
                            singularidadCder: [],
                            contSingularidadIzq:[],
                            contSingularidadDer:[],
                        };
                    }
                    if (data.segmento.fullCodigo === segmentos[i]) {
                        if(data.singularidadCizq == ""){
                            datosprueba[i]['contSingularidadIzq'].push(data.cftCizq);
                        }else{
                            datosprueba[i]['contSingularidadIzq'].push(0);
                        }
                        if(data.singularidadCder == ""){
                            datosprueba[i]['contSingularidadDer'].push(data.cftCder);
                        }else{
                            datosprueba[i]['contSingularidadDer'].push(0);
                        }
                        datosprueba[i]['iriCizq'].push(data.cftCizq);
                        datosprueba[i]['iriCder'].push(data.cftCder);
                        datosprueba[i]['cumplimientoI'].push(data.cumplimientoCizq);
                        datosprueba[i]['cumplimientoD'].push(data.cumplimientoCder);
                        datosprueba[i]['cumplimientoC'].push(data.cumplimientoCalzada);
                        datosprueba[i]['abscisaini'].push(data.abini);
                        datosprueba[i]['abscisafin'].push(data.abfin);
                        datosprueba[i]['eventoCizq'].push(data.eventoCizq);
                        datosprueba[i]['singularidadCizq'].push(data.singularidadCizq);
                        datosprueba[i]['eventoCder'].push(data.eventoCder);
                        datosprueba[i]['singularidadCder'].push(data.singularidadCder);

                    }
                }
            })
            //console.log(datosprueba)
            for (let i = 0; i < datosprueba.length; i++) {
                let promedioIzq = 0;
                let contIriIzq = 0;
                let contIriDer = 0;
                let promedioDer = 0;
                let cumplimientoder = 0;
                let cumplimientoizq = 0;
                let cumplimientoCal = 0;
                for (let j = 0; j < datosprueba[i]['singularidadCizq'].length; j++) {
                    if(datosprueba[i]['singularidadCizq'][j] === ""){
                        contIriIzq++;
                    }
                }
                for (let j = 0; j < datosprueba[i]['singularidadCder'].length; j++) {
                    if(datosprueba[i]['singularidadCder'][j] === ""){
                        contIriDer++;
                    }
                }
                for (let j = 0; j < datosprueba[i]['contSingularidadIzq'].length; j++) { 
                    promedioIzq += datosprueba[i]['contSingularidadIzq'][j]; 
                }
                for (let j = 0; j < datosprueba[i]['contSingularidadDer'].length; j++) {
                    promedioDer += datosprueba[i]['contSingularidadDer'][j];
                }
                for (let j = 0; j < datosprueba[i]['cumplimientoI'].length; j++) {
                    if (datosprueba[i]['cumplimientoI'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')) {
                        cumplimientoizq++
                    }
                }
                for (let j = 0; j < datosprueba[i]['cumplimientoD'].length; j++) {
                    if (datosprueba[i]['cumplimientoD'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')) {
                        cumplimientoder++
                    }
                }
                for (let j = 0; j < datosprueba[i]['cumplimientoC'].length; j++) {
                    if (datosprueba[i]['cumplimientoC'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')) {
                        cumplimientoCal++
                    }
                }
                if(contIriIzq == 0){
                    promedioI[i] = 0;
                }else{
                    promedioI[i] = promedioIzq / (contIriIzq);
                }
                if(contIriDer == 0){
                    promedioD[i]= 0;
                }else{
                    promedioD[i] = promedioDer / (contIriDer);
                }
                // promedioI[i] = promedioIzq / (datosprueba[i]['iriCizq'].length);
                // promedioD[i] = promedioDer / (datosprueba[i]['iriCder'].length);
                promedioCalzada[i] = Math.min(promedioI[i], promedioD[i]);
                if (promedioCalzada[i] < valorpromedio) {
                    cumplimientoPromCalzada[i] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')
                }
                else {
                    cumplimientoPromCalzada[i] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')
                }

                cumplimientoI[i] = cumplimientoizq;
                cumplimientoD[i] = cumplimientoder;
                promedioC[i] = cumplimientoCal;
                if (promedioC[i] > 0) {
                    cumplimientoPuntCalzada[i] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')
                }
                else {
                    cumplimientoPuntCalzada[i] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')
                }
                if (promedioCalzada[i] < valorpromedio || promedioC[i] > 0) {
                    cumplimientoIndicador[i] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')
                }
                else {
                    cumplimientoIndicador[i] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')
                }
            }

            //console.log(segmentos)
            //console.log(promedioI)
            const MostrarDetalles = (valor, valor2) => {
                //alert("Prueba"+valor);
                //console.log(valor)
                let det = document.getElementById('detalles_' + valor)
                let most = document.getElementById('mostrar_' + valor)
                if (det) {
                    let disp = det.style.display;
                    if (disp != "none") {
                        det.style.display = "none"
                    }
                    else {
                        det.style.display = ""
                    }
                }
                if (most) {
                    let html = most.innerHTML;
                    if (html != translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')) {
                        most.innerHTML = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')
                    }
                    else {
                        most.innerHTML = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.ocultar')
                    }
                }

                //console.log(valor2)
            }
            return (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.SEGMENTO')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.PromedioCarrilI')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.IncumplPuntualesCarrilI')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.PromedioCarrilD')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.IncumplPuntualesCarrilD')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.ValorMedioCFT')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CumplimientoValorPromedio')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CumplimientoValorPuntual')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CumplimientoIndicadorSegmento')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Visualizar')}</TableCell>
                        </TableRow>
                    </TableHead>
                    {promedioI.map((row, i) => (
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ textAlign: "left" }}>{segmentos[i]}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(row, 2)}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{cumplimientoI[i]}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(promedioD[i], 2)}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{cumplimientoD[i]}</TableCell>   
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(promedioCalzada[i], 2)}</TableCell>
                                {cumplimientoPromCalzada[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left", color: "red" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                }
                                {cumplimientoPromCalzada[i] != translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                }
                                {cumplimientoPuntCalzada[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left", color: "red" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                }
                                {cumplimientoPuntCalzada[i] != translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                }
                                {cumplimientoIndicador[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left", color: "red" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                }
                                {cumplimientoIndicador[i] != translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                }
                                <TableCell style={{ textAlign: "right" }}><span id={"mostrar_" + i} style={{ color: "blue", cursor: "pointer" }} onClick={(evt) => MostrarDetalles(i, evt)}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')}</span></TableCell>
                            </TableRow>
                            <TableRow style={{ display: "none" }} id={"detalles_" + i}>
                                <TableCell colSpan="10">
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ textAlign: "center" }} colSpan="2">{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.abscisa')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }} colspan="4">{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.carrilIzquierdoDescendente')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }} colspan="4">{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.carrilDerechoAscendente')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }} rowSpan="2">{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.cumplimientoIndicadorCalzada')}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.AbscisaInicio')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.AbscisaFinal')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CFT')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CumplimientoIndicPuntual')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Evento')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Singularidad')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CFT')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CumplimientoIndicPuntual')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Evento')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Singularidad')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {datosprueba[i]['cumplimientoI'].map((data, j) => (
                                                <TableRow>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(datosprueba[i]['abscisaini'][j])}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(datosprueba[i]['abscisafin'][j])}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['iriCizq'][j], 2)}</TableCell>
                                                    {datosprueba[i]['cumplimientoI'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                                        <TableCell style={{ color: "red", textAlign: "right" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                                    }
                                                    {datosprueba[i]['cumplimientoI'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple') &&
                                                        <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                                    }
                                                    {datosprueba[i]['cumplimientoI'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica') &&
                                                        <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica')}</TableCell>
                                                    }
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['eventoCizq'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['singularidadCizq'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['iriCder'][j], 2)}</TableCell>
                                                    {datosprueba[i]['cumplimientoD'][j] === "No Cumple" &&
                                                        <TableCell style={{ color: "red", textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                                    }
                                                    {datosprueba[i]['cumplimientoD'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple') &&
                                                        <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                                    }
                                                    {datosprueba[i]['cumplimientoD'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica') &&
                                                        <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica')}</TableCell>
                                                    }
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['eventoCder'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['singularidadCder'][j]}</TableCell>
                                                    {datosprueba[i]['cumplimientoC'][j] === "No Cumple" &&
                                                        <TableCell style={{ color: "red", textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                                    }
                                                    {datosprueba[i]['cumplimientoC'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple') &&
                                                        <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                                    }
                                                    {datosprueba[i]['cumplimientoC'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica') &&
                                                        <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica')}</TableCell>
                                                    }
                                                </TableRow>
                                            ))
                                            }
                                        </TableBody>
                                    </Table>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ))}

                </Table>

            )
        } else if (datosCarga['hydra:member'] && parametros && parametros != [] && this.props.record.indicador === "/parametros/5") {
            let registros = datosCarga['hydra:member'];
            let segmentos = [];
            let promedioI = [];
            let promedioD = [];
            let promedioC = [];
            let promedioCalzada = [];
            let cumplimientoI = [];
            let cumplimientoD = [];
            let cumplimientoIndicador = [];
            let cumplimientoPromCalzada = []
            let cumplimientoPuntCalzada = []
            let aux = -1;
            let datosprueba = [];
            //console.log(registros)
            registros.map((data, i) => {
                if (data.segmento.codigo != aux) {
                    segmentos.push(data.segmento.fullCodigo);
                    aux = data.segmento.codigo;
                }
            })
            registros.map((data, i) => {
                for (let i = 0; i < segmentos.length; i++) {
                    if (!datosprueba[i]) {
                        datosprueba[i] = {
                            iriCizq: [],
                            iriCder: [],
                            cumplimientoI: [],
                            cumplimientoD: [],
                            cumplimientoC: [],
                            abscisaini: [],
                            abscisafin: [],
                            eventoCizq: [],
                            singularidadCizq: [],
                            eventoCder: [],
                            singularidadCder: [],
                            contSingularidadIzq:[],
                            contSingularidadDer:[]
                        };
                    }
                    if (data.segmento.fullCodigo === segmentos[i]) {
                        console.log(data)
                        if(data.singularidadCizq == ""){
                            datosprueba[i]['contSingularidadIzq'].push(data.mtdCizq);
                        }else{
                            datosprueba[i]['contSingularidadIzq'].push(0);
                        }
                        if(data.singularidadCder == ""){
                            datosprueba[i]['contSingularidadDer'].push(data.mtdCder);
                        }else{
                            datosprueba[i]['contSingularidadDer'].push(0);
                        }
                        datosprueba[i]['iriCizq'].push(data.mtdCizq);
                        datosprueba[i]['iriCder'].push(data.mtdCder);
                        datosprueba[i]['cumplimientoI'].push(data.cumplimientoCizq);
                        datosprueba[i]['cumplimientoD'].push(data.cumplimientoCder);
                        datosprueba[i]['cumplimientoC'].push(data.cumplimientoCalzada);
                        datosprueba[i]['abscisaini'].push(data.abini);
                        datosprueba[i]['abscisafin'].push(data.abfin);
                        datosprueba[i]['eventoCizq'].push(data.eventoCizq);
                        datosprueba[i]['singularidadCizq'].push(data.singularidadCizq);
                        datosprueba[i]['eventoCder'].push(data.eventoCder);
                        datosprueba[i]['singularidadCder'].push(data.singularidadCder);

                    }
                }
            })
            //console.log(datosprueba)
            for (let i = 0; i < datosprueba.length; i++) {
                let promedioIzq = 0;
                let contIriIzq = 0;
                let contIriDer = 0;
                let promedioDer = 0;
                let cumplimientoder = 0;
                let cumplimientoizq = 0;
                let cumplimientoCal = 0;
                for (let j = 0; j < datosprueba[i]['singularidadCizq'].length; j++) {
                    if(datosprueba[i]['singularidadCizq'][j] === ""){
                        contIriIzq++;
                    }
                }
                for (let j = 0; j < datosprueba[i]['singularidadCder'].length; j++) {
                    if(datosprueba[i]['singularidadCder'][j] === ""){
                        contIriDer++;
                    }
                }
                for (let j = 0; j < datosprueba[i]['contSingularidadIzq'].length; j++) { 
                    promedioIzq += datosprueba[i]['contSingularidadIzq'][j]; 
                }
                for (let j = 0; j < datosprueba[i]['contSingularidadDer'].length; j++) {
                    promedioDer += datosprueba[i]['contSingularidadDer'][j];
                }
                for (let j = 0; j < datosprueba[i]['cumplimientoI'].length; j++) {
                    if (datosprueba[i]['cumplimientoI'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')) {
                        cumplimientoizq++
                    }
                }
                for (let j = 0; j < datosprueba[i]['cumplimientoD'].length; j++) {
                    if (datosprueba[i]['cumplimientoD'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')) {
                        cumplimientoder++
                    }
                }
                for (let j = 0; j < datosprueba[i]['cumplimientoC'].length; j++) {
                    if (datosprueba[i]['cumplimientoC'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')) {
                        cumplimientoCal++
                    }
                }
                if(contIriIzq == 0){
                    promedioI[i] = 0;
                }else{
                    promedioI[i] = promedioIzq / (contIriIzq);
                }
                if(contIriDer == 0){
                    promedioD[i]= 0;
                }else{
                    promedioD[i] = promedioDer / (contIriDer);
                }
                // promedioI[i] = promedioIzq / (datosprueba[i]['iriCizq'].length);
                // promedioD[i] = promedioDer / (datosprueba[i]['iriCder'].length);
                promedioCalzada[i] = Math.max(promedioI[i], promedioD[i]);
                if (promedioCalzada[i] < valorpromedio) {
                    cumplimientoPromCalzada[i] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')
                }
                else {
                    cumplimientoPromCalzada[i] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')
                }

                cumplimientoI[i] = cumplimientoizq;
                cumplimientoD[i] = cumplimientoder;
                promedioC[i] = cumplimientoCal;
                if (promedioC[i] > 0) {
                    cumplimientoPuntCalzada[i] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')
                }
                else {
                    cumplimientoPuntCalzada[i] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')
                }
                if (promedioCalzada[i] < valorpromedio || promedioC[i] > 0) {
                    cumplimientoIndicador[i] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')
                }
                else {
                    cumplimientoIndicador[i] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')
                }
            }

            //console.log(segmentos)
            //console.log(promedioI)
            const MostrarDetalles = (valor, valor2) => {
                //alert("Prueba"+valor);
                //console.log(valor)
                let det = document.getElementById('detalles_' + valor)
                let most = document.getElementById('mostrar_' + valor)
                if (det) {
                    let disp = det.style.display;
                    if (disp != "none") {
                        det.style.display = "none"
                    }
                    else {
                        det.style.display = ""
                    }
                }
                if (most) {
                    let html = most.innerHTML;
                    if (html != translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')) {
                        most.innerHTML = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')
                    }
                    else {
                        most.innerHTML = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.ocultar')
                    }
                }

                //console.log(valor2)
            }
            return (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.SEGMENTO')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.IncumplPuntualesCarrilI')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.IncumplPuntualesCarrilD')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CumplimientoIndicadorSegmento')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Visualizar')}</TableCell>
                        </TableRow>
                    </TableHead>
                    {promedioI.map((row, i) => (
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ textAlign: "left" }}>{segmentos[i]}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{cumplimientoI[i]}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{cumplimientoD[i]}</TableCell>
                                {cumplimientoPuntCalzada[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left", color: "red" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                }
                                {cumplimientoPuntCalzada[i] != translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                }

                                <TableCell style={{ textAlign: "right" }}><span id={"mostrar_" + i} style={{ color: "blue", cursor: "pointer" }} onClick={(evt) => MostrarDetalles(i, evt)}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')}</span></TableCell>
                            </TableRow>
                            <TableRow style={{ display: "none" }} id={"detalles_" + i}>
                                <TableCell colSpan="10">
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ textAlign: "center" }} colSpan="2">{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.abscisa')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }} colspan="4">{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.carrilIzquierdoDescendente')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }} colspan="4">{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.carrilDerechoAscendente')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }} rowSpan="2">{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.cumplimientoIndicadorCalzada')}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.AbscisaInicio')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.AbscisaFinal')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.MTDmmASTME1845')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CumplimientoIndicPuntual')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Evento')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Singularidad')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.MTDmmASTME1845')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CumplimientoIndicPuntual')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Evento')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Singularidad')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {datosprueba[i]['cumplimientoI'].map((data, j) => (
                                                <TableRow>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(datosprueba[i]['abscisaini'][j])}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(datosprueba[i]['abscisafin'][j])}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['iriCizq'][j], 2)}</TableCell>
                                                    {datosprueba[i]['cumplimientoI'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                                        <TableCell style={{ color: "red", textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                                    }
                                                    {datosprueba[i]['cumplimientoI'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple') &&
                                                        <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                                    }
                                                    {datosprueba[i]['cumplimientoI'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica') &&
                                                        <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica')}</TableCell>
                                                    }
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['eventoCizq'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['singularidadCizq'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['iriCder'][j], 2)}</TableCell>
                                                    {datosprueba[i]['cumplimientoD'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                                        <TableCell style={{ color: "red", textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                                    }
                                                    {datosprueba[i]['cumplimientoD'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple') &&
                                                        <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                                    }
                                                    {datosprueba[i]['cumplimientoD'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica') &&
                                                        <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica')}</TableCell>
                                                    }
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['eventoCder'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['singularidadCder'][j]}</TableCell>
                                                    {datosprueba[i]['cumplimientoC'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                                        <TableCell style={{ color: "red", textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                                    }
                                                    {datosprueba[i]['cumplimientoC'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple') &&
                                                        <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                                    }
                                                    {datosprueba[i]['cumplimientoC'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica') &&
                                                        <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica')}</TableCell>
                                                    }
                                                </TableRow>
                                            ))
                                            }
                                        </TableBody>
                                    </Table>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ))}

                </Table>

            )
        } else if (datosCarga['hydra:member'] && parametros && parametros != [] && this.props.record.indicador === "/parametros/6") {
            let registros = datosCarga['hydra:member'];
            let segmentos = [];
            let cumplimientoCalzada = []
            let incumplimientos = [];
            let aux = -1;
            let datosprueba = [];
            //console.log(registros)
            registros.map((data, i) => {
                if (data.segmento.codigo != aux) {
                    segmentos.push(data.segmento.fullCodigo);
                    aux = data.segmento.codigo;
                }
            })
            registros.map((data, i) => {
                for (let i = 0; i < segmentos.length; i++) {
                    if (!datosprueba[i]) {
                        datosprueba[i] = {
                            ancho: [],
                            largo: [],
                            area: [],
                            profundidad: [],
                            observaciones: [],
                            carril: [],
                            abscisa: [],
                        };
                    }
                    if (data.segmento.fullCodigo === segmentos[i]) {
                        datosprueba[i]['abscisa'].push(data.abscisa);
                        datosprueba[i]['ancho'].push(data.ancho);
                        datosprueba[i]['largo'].push(data.largo);
                        datosprueba[i]['area'].push(data.area);
                        datosprueba[i]['profundidad'].push(data.profundidad);
                        datosprueba[i]['observaciones'].push(data.observaciones);
                        datosprueba[i]['carril'].push(data.carril);
                    }
                }
            })
            //console.log(datosprueba)
            for (let i = 0; i < datosprueba.length; i++) {
                let cumplimiento = "No Cumple";
                let sumaIncumplimientosCizq = 0;
                let sumaIncumplimientosCder = 0;
                for (let j = 0; j < datosprueba[i]['ancho'].length; j++) {
                    let area = datosprueba[i]['area'][j];
                    let profundidad = datosprueba[i]['profundidad'][j];
                    let carril = datosprueba[i]['carril'][j];
                    if (parseFloat(area) > 0.05 && parseFloat(profundidad) > 2.5 && carril === "Izquierdo") {
                        sumaIncumplimientosCizq++;
                    }
                    if (parseFloat(area) > 0.05 && parseFloat(profundidad) > 2.5 && carril === "Derecho") {
                        sumaIncumplimientosCder++;
                    }
                }
                if (sumaIncumplimientosCder + sumaIncumplimientosCizq > valorpuntual) {
                    cumplimiento = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')
                }
                else {
                    cumplimiento = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')
                }
                cumplimientoCalzada.push(cumplimiento);
                incumplimientos.push(sumaIncumplimientosCder + sumaIncumplimientosCizq);
            }

            //console.log(segmentos)
            //console.log(promedioI)
            const MostrarDetalles = (valor, valor2) => {
                //alert("Prueba"+valor);
                //console.log(valor)
                let det = document.getElementById('detalles_' + valor)
                let most = document.getElementById('mostrar_' + valor)
                if (det) {
                    let disp = det.style.display;
                    if (disp != "none") {
                        det.style.display = "none"
                    }
                    else {
                        det.style.display = ""
                    }
                }
                if (most) {
                    let html = most.innerHTML;
                    if (html != translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')) {
                        most.innerHTML = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')
                    }
                    else {
                        most.innerHTML = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.ocultar')
                    }
                }

                //console.log(valor2)
            }
            return (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.SEGMENTO')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.IncumplPuntualesCalzada')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CumplimientoIndicadorSegmento')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Visualizar')}</TableCell>
                        </TableRow>
                    </TableHead>
                    {segmentos.map((row, i) => (
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ textAlign: "left" }}>{segmentos[i]}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{incumplimientos[i]}</TableCell>
                                {cumplimientoCalzada[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left", color: "red" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                }
                                {cumplimientoCalzada[i] != translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                }
                                <TableCell style={{ textAlign: "right" }}><span id={"mostrar_" + i} style={{ color: "blue", cursor: "pointer" }} onClick={(evt) => MostrarDetalles(i, evt)}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')}</span></TableCell>
                            </TableRow>
                            <TableRow style={{ display: "none" }} id={"detalles_" + i}>
                                <TableCell colSpan="4">
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.AbscisaFisura')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Ancho_m')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Largo_m')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Area_m')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Profundidadcm')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Observaciones')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Carril')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {datosprueba[i]['abscisa'].map((row, j) => (
                                                <TableRow>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(datosprueba[i]['abscisa'][j])}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['ancho'][j], 2)}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['largo'][j], 2)}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['area'][j], 2)}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['profundidad'][j], 2)}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['observaciones'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['carril'][j]}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ))}

                </Table>

            )
        } else if (datosCarga['hydra:member'] && parametros && parametros != [] && this.props.record.indicador === "/parametros/7") {
            let registros = datosCarga['hydra:member'];
            let segmentos = [];
            let cumplimientoCalzada = []
            let AreaVerdaderaCal = [];
            let AreaAfect = [];
            let AreaPorc = [];
            let aux = -1;
            let datosprueba = [];
            //console.log(registros)
            registros.map((data, i) => {
                if (data.segmento.codigo != aux) {
                    segmentos.push(data.segmento.fullCodigo);
                    aux = data.segmento.codigo;
                }
            })
            registros.map((data, i) => {
                for (let i = 0; i < segmentos.length; i++) {
                    if (!datosprueba[i]) {
                        datosprueba[i] = {
                            ancho: [],
                            largo: [],
                            area: [],
                            profundidad: [],
                            observaciones: [],
                            carril: [],
                            abscisa: [],
                            areaverdadera: []
                        };
                    }
                    if (data.segmento.fullCodigo === segmentos[i]) {
                        datosprueba[i]['abscisa'].push(data.abscisa);
                        datosprueba[i]['ancho'].push(data.ancho);
                        datosprueba[i]['largo'].push(data.largo);
                        datosprueba[i]['area'].push(data.area);
                        datosprueba[i]['profundidad'].push(data.profundidad);
                        datosprueba[i]['observaciones'].push(data.observaciones);
                        datosprueba[i]['carril'].push(data.carril);
                        let largotemp = data.flexibleDetalles.geometriaSeg[0].geometriaLargo
                        let anchotemp = data.flexibleDetalles.geometriaSeg[0].geometriaAncho
                        let anchoctemp = data.flexibleDetalles.geometriaSeg[0].geometriaAnchoCarril
                        let areatemp = largotemp * anchotemp * anchoctemp;
                        datosprueba[i]['areaverdadera'].push(areatemp);
                    }
                }
            })
            //console.log(datosprueba)
            for (let i = 0; i < datosprueba.length; i++) {
                let cumplimiento = "No Cumple";
                let sumaAreasCizq = 0;
                let sumaAreasCder = 0;
                let SumaIncumplimiento = 0;
                for (let j = 0; j < datosprueba[i]['ancho'].length; j++) {
                    let area = datosprueba[i]['area'][j];
                    let profundidad = datosprueba[i]['profundidad'][j];
                    let carril = datosprueba[i]['carril'][j];
                    if (carril === "Izquierdo" && parseFloat(profundidad) >= 2) {
                        sumaAreasCizq += parseFloat(datosprueba[i]['area'][j]);
                    }
                    if (carril === "Derecho" && parseFloat(profundidad) >= 2) {
                        sumaAreasCder += parseFloat(datosprueba[i]['area'][j]);

                    }
                }

                let areaVerdad = datosprueba[i]['areaverdadera'][0]
                let areaAfectada = (sumaAreasCder + sumaAreasCizq).toFixed(2);
                //console.log(sumaAreasCizq+" + "+sumaAreasCder+" = "+areaAfectada)
                let porcentaje = parseFloat((areaAfectada / areaVerdad) * 100).toFixed(2);
                //console.log("valor punt = "+valorpuntual)
                if (porcentaje < valorpuntual) {
                    cumplimiento = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')
                } else {
                    cumplimiento = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')
                }

                cumplimientoCalzada.push(cumplimiento);
                AreaVerdaderaCal.push(areaVerdad);
                AreaAfect.push(areaAfectada);
                AreaPorc.push(porcentaje);
            }

            //console.log(segmentos)
            //console.log(promedioI)
            const MostrarDetalles = (valor, valor2) => {
                //alert("Prueba"+valor);
                //console.log(valor)
                let det = document.getElementById('detalles_' + valor)
                let most = document.getElementById('mostrar_' + valor)
                if (det) {
                    let disp = det.style.display;
                    if (disp != "none") {
                        det.style.display = "none"
                    }
                    else {
                        det.style.display = ""
                    }
                }
                if (most) {
                    let html = most.innerHTML;
                    if (html != translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')) {
                        most.innerHTML = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')
                    }
                    else {
                        most.innerHTML = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.ocultar')
                    }
                }

                //console.log(valor2)
            }
            return (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.SEGMENTO')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.areaVerdadera')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.areaAfectada_m')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.areaAfectada')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CumplimientoIndicadorSegmento')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Visualizar')}</TableCell>
                        </TableRow>
                    </TableHead>
                    {segmentos.map((row, i) => (
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ textAlign: "left" }}>{segmentos[i]}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{AreaVerdaderaCal[i]}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(AreaAfect[i], 2)}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(AreaPorc[i], 2)}</TableCell>
                                {cumplimientoCalzada[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left", color: "red" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                }
                                {cumplimientoCalzada[i] != translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                }

                                <TableCell style={{ textAlign: "right" }}><span id={"mostrar_" + i} style={{ color: "blue", cursor: "pointer" }} onClick={(evt) => MostrarDetalles(i, evt)}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')}</span></TableCell>
                            </TableRow>
                            <TableRow style={{ display: "none" }} id={"detalles_" + i}>
                                <TableCell colSpan="6">
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.AbscisaFisura')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Ancho_m')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Largo_m')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Area_m')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Profundidadcm')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Observaciones')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Carril')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {datosprueba[i]['abscisa'].map((row, j) => (
                                                <TableRow>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(datosprueba[i]['abscisa'][j])}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['ancho'][j], 2)}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['largo'][j], 2)}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['area'][j], 2)}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['profundidad'][j], 2)}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['observaciones'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['carril'][j]}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ))}

                </Table>

            )
        } else if (datosCarga['hydra:member'] && parametros && parametros != [] && this.props.record.indicador === "/parametros/8") {
            let registros = datosCarga['hydra:member'];
            let segmentos = [];
            let cumplimientoSegmento = []
            let incumplimientosPuntuales = [];
            let aux = -1;
            let datosprueba = [];
            //console.log(registros)
            registros.map((data, i) => {
                if (data.segmento.codigo != aux) {
                    segmentos.push(data.segmento.fullCodigo);
                    aux = data.segmento.codigo;
                }
            })
            registros.map((data, i) => {
                for (let i = 0; i < segmentos.length; i++) {
                    if (!datosprueba[i]) {
                        datosprueba[i] = {
                            abscisa: [],
                            medida1: [],
                            medida2: [],
                            medida3: [],
                            medida4: [],
                            medida5: [],
                            limpiezaGeneral: [],
                            vegetacionSeguridad: [],
                            numeroBasuras: [],
                            observaciones: [],
                            cumplimiento: []
                        };
                    }
                    if (data.segmento.fullCodigo === segmentos[i]) {
                        datosprueba[i]['abscisa'].push(data.abscisa);
                        datosprueba[i]['medida1'].push(data.medida1);
                        datosprueba[i]['medida2'].push(data.medida2);
                        datosprueba[i]['medida3'].push(data.medida3);
                        datosprueba[i]['medida4'].push(data.medida4);
                        datosprueba[i]['medida5'].push(data.medida5);
                        datosprueba[i]['limpiezaGeneral'].push(data.limpiezaGeneral);
                        datosprueba[i]['vegetacionSeguridad'].push(data.vegetacionSeguridad);
                        datosprueba[i]['numeroBasuras'].push(data.numeroBasuras);
                        datosprueba[i]['observaciones'].push(data.observaciones);
                    }
                }
            })
            //console.log(datosprueba)
            for (let i = 0; i < datosprueba.length; i++) {
                let cumplimiento = "No Cumple";
                let maximomedida = valorpromedio;
                let maxvegetacion = valorpuntual;
                let cuentaCumplimiento = 0;
                let cuentacumplimientoSeg = 0;
                for (let j = 0; j < datosprueba[i]['abscisa'].length; j++) {
                    cuentaCumplimiento = 0;
                    if (datosprueba[i]['medida1'][j] > maximomedida || datosprueba[i]['medida2'][j] > maximomedida
                        || datosprueba[i]['medida3'][j] > maximomedida || datosprueba[i]['medida4'][j] > maximomedida
                        || datosprueba[i]['medida5'][j] > maximomedida) {
                        cuentaCumplimiento++
                    }
                    if (!datosprueba[i]['limpiezaGeneral'][j]) {
                        cuentaCumplimiento++
                    }
                    if (datosprueba[i]['vegetacionSeguridad'][j]) {
                        cuentaCumplimiento++
                    }
                    if (datosprueba[i]['numeroBasuras'][j] >= maxvegetacion) {
                        cuentaCumplimiento++
                    }
                    if (cuentaCumplimiento > 0) {
                        cuentacumplimientoSeg++;
                        datosprueba[i]['cumplimiento'][j] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')
                    } else {
                        datosprueba[i]['cumplimiento'][j] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')
                    }
                }
                if (cuentacumplimientoSeg > 0) {
                    cumplimiento = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')
                } else {
                    cumplimiento = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')
                }
                cumplimientoSegmento.push(cumplimiento);
                incumplimientosPuntuales.push(cuentacumplimientoSeg)
            }


            const MostrarDetalles = (valor, valor2) => {
                let det = document.getElementById('detalles_' + valor)
                let most = document.getElementById('mostrar_' + valor)
                if (det) {
                    let disp = det.style.display;
                    if (disp != "none") {
                        det.style.display = "none"
                    }
                    else {
                        det.style.display = ""
                    }
                }
                if (most) {
                    let html = most.innerHTML;
                    if (html != translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')) {
                        most.innerHTML = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')
                    }
                    else {
                        most.innerHTML = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.ocultar')
                    }
                }

                //console.log(valor2)
            }
            return (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.SEGMENTO')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.incumplimientosPuntuales')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CumplimientoIndicadorSegmento')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Visualizar')}</TableCell>
                        </TableRow>
                    </TableHead>
                    {segmentos.map((row, i) => (
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ textAlign: "left" }}>{segmentos[i]}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{incumplimientosPuntuales[i]}</TableCell>
                                {cumplimientoSegmento[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left", color: "red" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                }
                                {cumplimientoSegmento[i] != translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                }
                                <TableCell style={{ textAlign: "right" }}><span id={"mostrar_" + i} style={{ color: "blue", cursor: "pointer" }} onClick={(evt) => MostrarDetalles(i, evt)}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')}</span></TableCell>
                            </TableRow>
                            <TableRow style={{ display: "none" }} id={"detalles_" + i}>
                                <TableCell colSpan="4">
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.AbscisaFisura')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Medida1_cm')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Medida2_cm')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Medida3_cm')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Medida4_cm')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Medida5_cm')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.LimpiezaGenCalzada')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.LaVegetacionAfecta')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.NumeroBasuras')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Observaciones')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumplimiento')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {datosprueba[i]['abscisa'].map((row, j) => (
                                                <TableRow>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(datosprueba[i]['abscisa'][j])}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['medida1'][j], 2)}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['medida2'][j], 2)}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['medida3'][j], 2)}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['medida4'][j], 2)}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['medida5'][j], 2)}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['limpiezaGeneral'][j] ? translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.adecuado') : translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.inadecuado')}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['vegetacionSeguridad'][j] ? translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.booleanSi') : translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.booleanNo')}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['numeroBasuras'][j], 2)}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['observaciones'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['cumplimiento'][j]}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ))}

                </Table>

            )
        } else if (datosCarga['hydra:member'] && parametros && parametros != [] && this.props.record.indicador === "/parametros/9") {
            let registros = datosCarga['hydra:member'];
            let segmentos = [];
            let cumplimientoSegmento = [];
            let cumplimientoPuntual = [];
            let aux = -1;
            let datosprueba = [];
            //console.log(registros)
            registros.map((data, i) => {
                if (data.segmento.codigo != aux) {
                    segmentos.push(data.segmento.fullCodigo);
                    aux = data.segmento.codigo;
                }
            })
            registros.map((data, i) => {
                for (let i = 0; i < segmentos.length; i++) {
                    if (!datosprueba[i]) {
                        datosprueba[i] = {
                            elemento: [],
                            seccionHidraulica: [],
                            observaciones: [],
                            cumplimiento: []
                        };
                    }
                    if (data.segmento.fullCodigo === segmentos[i]) {
                        datosprueba[i]['elemento'].push(data.elementoDrenaje);
                        datosprueba[i]['seccionHidraulica'].push(data.seccionHidraulica);
                        datosprueba[i]['observaciones'].push(data.observaciones);
                    }
                }
            })
            for (let i = 0; i < datosprueba.length; i++) {
                let cumplimiento = "No Cumple";
                let cuenta = 0;
                let cuenta2 = 0;
                let valormaxhidraulica = valorpromedio;
                for (let j = 0; j < datosprueba[i]['elemento'].length; j++) {
                    cuenta = 0
                    if (datosprueba[i]['seccionHidraulica'][j] > valormaxhidraulica) cuenta++
                    if (cuenta > 0) { datosprueba[i]['cumplimiento'][j] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple'); cuenta2++; }
                    else datosprueba[i]['cumplimiento'][j] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')
                }
                if (cuenta2 > 0) cumplimientoSegmento.push(translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple'))
                else cumplimientoSegmento.push(translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple'))
                cumplimientoPuntual.push(cuenta2)

            }
            const MostrarDetalles = (valor, valor2) => {
                let det = document.getElementById('detalles_' + valor)
                let most = document.getElementById('mostrar_' + valor)
                if (det) {
                    let disp = det.style.display;
                    if (disp != "none") {
                        det.style.display = "none"
                    }
                    else {
                        det.style.display = ""
                    }
                }
                if (most) {
                    let html = most.innerHTML;
                    if (html != translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')) {
                        most.innerHTML = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')
                    }
                    else {
                        most.innerHTML = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.ocultar')
                    }
                }
            }
            return (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.SEGMENTO')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.IncumpPuntuales')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CumplimientoIndicSegmento')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Visualizar')}</TableCell>
                        </TableRow>
                    </TableHead>
                    {segmentos.map((row, i) => (
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ textAlign: "left" }}>{segmentos[i]}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{cumplimientoPuntual[i]}</TableCell>
                                {cumplimientoSegmento[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left", color: "red" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                }
                                {cumplimientoSegmento[i] != translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                }
                                <TableCell style={{ textAlign: "right" }}><span id={"mostrar_" + i} style={{ color: "blue", cursor: "pointer" }} onClick={(evt) => MostrarDetalles(i, evt)}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')}</span></TableCell>
                            </TableRow>
                            <TableRow style={{ display: "none" }} id={"detalles_" + i}>
                                <TableCell colSpan="4">
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Elemento')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.SeccionHidraulColmatada')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Observaciones')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumplimiento')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {datosprueba[i]['elemento'].map((row, j) => (
                                                <TableRow>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['elemento'][j]['fullCodigoConsecutivo']}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['seccionHidraulica'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['observaciones'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['cumplimiento'][j]}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ))}

                </Table>

            )
        } else if (datosCarga['hydra:member'] && parametros && parametros != [] && this.props.record.indicador === "/parametros/10") {
            let registros = datosCarga['hydra:member'];
            let segmentos = [];
            let cumplimientoSegmento = [];
            let cumplimientoPuntual = [];
            let aux = -1;
            let datosprueba = [];
            //console.log(registros)
            registros.map((data, i) => {
                if (data.segmento.codigo != aux) {
                    segmentos.push(data.segmento.fullCodigo);
                    aux = data.segmento.codigo;
                }
            })
            registros.map((data, i) => {
                for (let i = 0; i < segmentos.length; i++) {
                    if (!datosprueba[i]) {
                        datosprueba[i] = {
                            elemento: [],
                            retroPrincipal: [],
                            retroSecundario: [],
                            legibilidad: [],
                            localizacion: [],
                            estabilidad: [],
                            estadoLimpieza: [],
                            observaciones: [],
                            cumplimiento: []
                        };
                    }
                    if (data.segmento.fullCodigo === segmentos[i]) {
                        datosprueba[i]['elemento'].push(data.elementoSenalizacion);
                        datosprueba[i]['retroPrincipal'].push(data.retroPrincipal);
                        datosprueba[i]['retroSecundario'].push(data.retroSecundario);
                        datosprueba[i]['legibilidad'].push(data.legibilidad);
                        datosprueba[i]['localizacion'].push(data.localizacion);
                        datosprueba[i]['estabilidad'].push(data.estabilidad);
                        datosprueba[i]['estadoLimpieza'].push(data.estadoLimpieza);
                        datosprueba[i]['observaciones'].push(data.observaciones);

                    }
                }
            })
            //console.log(datosprueba)
            for (let i = 0; i < datosprueba.length; i++) {
                let cumplimiento = "No Cumple";
                let cuenta = 0;
                let cuenta2 = 0;
                for (let j = 0; j < datosprueba[i]['elemento'].length; j++) {
                    cuenta = 0;
                    let retro_elemento = parseFloat(datosprueba[i]['elemento'][j]['retroflectividadInicial']) * (valorpromedio / 100);
                    let retro2_elemento = parseFloat(datosprueba[i]['elemento'][j]['retroflectividadFinal']) * (valorpromedio / 100);
                    if (parseFloat(datosprueba[i]['retroPrincipal'][j]) <= retro_elemento && retro_elemento != 0) {
                        cuenta++;
                    }
                    if (parseFloat(datosprueba[i]['retroSecundario'][j]) <= retro2_elemento && retro2_elemento != 0) {
                        cuenta++;
                    }
                    if (datosprueba[i]['legibilidad'][j] && datosprueba[i]['localizacion'][j] && datosprueba[i]['estabilidad'][j] && datosprueba[i]['estadoLimpieza'][j]) {

                    }
                    else {
                        cuenta++;
                    }
                    if (cuenta > 0) {
                        cuenta2++;
                        datosprueba[i]['cumplimiento'][j] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')
                    } else {
                        datosprueba[i]['cumplimiento'][j] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')
                    }
                }
                if (cuenta2 >= 2) {
                    cumplimiento = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')
                } else {
                    cumplimiento = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')
                }
                cumplimientoSegmento.push(cumplimiento);
                cumplimientoPuntual.push(cuenta2);
            }

            //console.log(segmentos)
            //console.log(promedioI)
            const MostrarDetalles = (valor, valor2) => {
                //alert("Prueba"+valor);
                //console.log(valor)
                let det = document.getElementById('detalles_' + valor)
                let most = document.getElementById('mostrar_' + valor)
                if (det) {
                    let disp = det.style.display;
                    if (disp != "none") {
                        det.style.display = "none"
                    }
                    else {
                        det.style.display = ""
                    }
                }
                if (most) {
                    let html = most.innerHTML;
                    if (html != translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')) {
                        most.innerHTML = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')
                    }
                    else {
                        most.innerHTML = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.ocultar')
                    }
                }

                //console.log(valor2)
            }
            return (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.SEGMENTO')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.incumplimientosPuntuales')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CumplimientoIndicadorSegmento')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Visualizar')}</TableCell>
                        </TableRow>
                    </TableHead>
                    {segmentos.map((row, i) => (
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ textAlign: "left" }}>{segmentos[i]}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{cumplimientoPuntual[i]}</TableCell>
                                {cumplimientoSegmento[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left", color: "red" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                }
                                {cumplimientoSegmento[i] != translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                }
                                <TableCell style={{ textAlign: "right" }}><span id={"mostrar_" + i} style={{ color: "blue", cursor: "pointer" }} onClick={(evt) => MostrarDetalles(i, evt)}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')}</span></TableCell>
                            </TableRow>
                            <TableRow style={{ display: "none" }} id={"detalles_" + i}>
                                <TableCell colSpan="5">
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Elemento')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.RetroreflectividadColorPrin')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.RetroreflectividadColorSec')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.LegibilidadSenal')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Localización')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Estabilidad')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.EstadoLimpieza')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Observaciones')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumplimiento')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {datosprueba[i]['elemento'].map((row, j) => (
                                                <TableRow>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['elemento'][j]['fullCodigoConsecutivo']}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['retroPrincipal'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['retroSecundario'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['legibilidad'][j] ? translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.adecuado') : translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.inadecuado')}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['localizacion'][j] ? translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.adecuado') : translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.inadecuado')}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['estabilidad'][j] ? translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.adecuado') : translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.inadecuado')}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['estadoLimpieza'][j] ? translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.adecuado') : translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.inadecuado')}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['observaciones'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['cumplimiento'][j]}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ))}

                </Table>

            )
        } else if (datosCarga['hydra:member'] && parametros && parametros != [] && this.props.record.indicador === "/parametros/11") {
            let registros = datosCarga['hydra:member'];
            let segmentos = [];
            let cumplimientoSegmento = [];
            let cumplimientoPuntual = [];
            let aux = -1;
            let datosprueba = [];
            //console.log(registros)
            registros.map((data, i) => {
                if (data.segmento.codigo != aux) {
                    segmentos.push(data.segmento.fullCodigo);
                    aux = data.segmento.codigo;
                }
            })
            registros.map((data, i) => {
                for (let i = 0; i < segmentos.length; i++) {
                    if (!datosprueba[i]) {
                        datosprueba[i] = {
                            abscisa: [],
                            lineaBlancaDerecho: [],
                            lineaBlancaIzquierdo: [],
                            lineaAmarilla: [],
                            delineadorPiso: [],
                            tipoEquipo: [],
                            cumplimiento: []
                        };
                    }
                    if (data.segmento.fullCodigo === segmentos[i]) {
                        datosprueba[i]['abscisa'].push(data.abscisa);
                        datosprueba[i]['lineaBlancaDerecho'].push(data.lineaBlancaDerecho);
                        datosprueba[i]['lineaBlancaIzquierdo'].push(data.lineaBlancaIzquierdo);
                        datosprueba[i]['lineaAmarilla'].push(data.lineaAmarilla);
                        datosprueba[i]['delineadorPiso'].push(data.delineadorPiso);
                        datosprueba[i]['tipoEquipo'].push(data.tipoEquipo);
                    }
                }
            })
            //console.log(datosprueba)
            for (let i = 0; i < datosprueba.length; i++) {
                let cumplimiento = "No Cumple";
                let cuenta = 0;
                let valorlineablancaderecho = valorpromedio;
                let valorlineablancaizquierdo = valorpromedio;
                let valorlineaamarilla = valorpuntual;
                let cuentaTemporal = 0;
                let cuentaTemporal2 = 0;
                for (let j = 0; j < datosprueba[i]['abscisa'].length; j++) {
                    cuentaTemporal = 0;
                    // if (parseFloat(datosprueba[i]['tipoEquipo'][j]) === 30) {
                    //     valorlineablancaderecho = 140;
                    //     valorlineablancaizquierdo = 140;
                    //     valorlineaamarilla = 120;
                    // } else {
                    //     valorlineablancaderecho = 160;
                    //     valorlineablancaizquierdo = 160;
                    //     valorlineaamarilla = 140;
                    // }
                    if (parseFloat(datosprueba[i]['lineaBlancaDerecho'][j]) < valorlineablancaderecho) {
                        cuenta++;
                        cuentaTemporal++;
                    }
                    if (parseFloat(datosprueba[i]['lineaBlancaIzquierdo'][j]) < valorlineablancaizquierdo) {
                        cuenta++;
                        cuentaTemporal++;
                    }
                    if (parseFloat(datosprueba[i]['lineaAmarilla'][j]) < valorlineaamarilla) {
                        cuenta++;
                        cuentaTemporal++;
                    } if (!datosprueba[i]['delineadorPiso'][j]) {
                        cuenta++;
                        cuentaTemporal++;
                    }
                    if (cuentaTemporal < 1) {
                        datosprueba[i]['cumplimiento'][j] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')
                    }
                    else {
                        cuentaTemporal2++;
                        datosprueba[i]['cumplimiento'][j] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')
                    }
                }

                if (cuenta < 1) {
                    cumplimiento = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')
                } else {
                    cumplimiento = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')
                }

                cumplimientoSegmento.push(cumplimiento);
                cumplimientoPuntual.push(cuentaTemporal2);
            }

            //console.log(segmentos)
            //console.log(promedioI)
            const MostrarDetalles = (valor, valor2) => {
                //alert("Prueba"+valor);
                //console.log(valor)
                let det = document.getElementById('detalles_' + valor)
                let most = document.getElementById('mostrar_' + valor)
                if (det) {
                    let disp = det.style.display;
                    if (disp != "none") {
                        det.style.display = "none"
                    }
                    else {
                        det.style.display = ""
                    }
                }
                if (most) {
                    let html = most.innerHTML;
                    if (html != translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')) {
                        most.innerHTML = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')
                    }
                    else {
                        most.innerHTML = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.ocultar')
                    }
                }

                //console.log(valor2)
            }
            return (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.SEGMENTO')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.IncumpPuntuales')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CumplimientoIndicSegmento')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Visualizar')}</TableCell>
                        </TableRow>
                    </TableHead>
                    {segmentos.map((row, i) => (
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ textAlign: "left" }}>{segmentos[i]}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{cumplimientoPuntual[i]}</TableCell>
                                {cumplimientoSegmento[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left", color: "red" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                }
                                {cumplimientoSegmento[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                }
                                {cumplimientoSegmento[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica')}</TableCell>
                                }
                                <TableCell style={{ textAlign: "right" }}><span id={"mostrar_" + i} style={{ color: "blue", cursor: "pointer" }} onClick={(evt) => MostrarDetalles(i, evt)}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')}</span></TableCell>
                            </TableRow>
                            <TableRow style={{ display: "none" }} id={"detalles_" + i}>
                                <TableCell colSpan="5">
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.AbscisaFisura')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.MedLineaBlanBordeDerecho')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.MedLineaBlanBordeIzquierdo')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.MedidalineaAmarilla')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CumplimientoDelineadPiso')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.TipoEquipoGeometria_cm')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CumplimientoIndicador')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {datosprueba[i]['abscisa'].map((row, j) => (
                                                <TableRow>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(datosprueba[i]['abscisa'][j])}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['lineaBlancaDerecho'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['lineaBlancaIzquierdo'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['lineaAmarilla'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['delineadorPiso'][j] ? translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.booleanSi') : translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.booleanNo')}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{j <= 0 ? datosprueba[i]['tipoEquipo'][j] : ""}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['cumplimiento'][j]}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ))}

                </Table>

            )
        } else if (datosCarga['hydra:member'] && parametros && parametros != [] && this.props.record.indicador === "/parametros/12") {
            let registros = datosCarga['hydra:member'];
            let segmentos = [];
            let cumplimientoSegmento = [];
            let cumplimientoPuntual = [];
            let aux = -1;
            let datosprueba = [];
            //console.log(registros)
            registros.map((data, i) => {
                if (data.segmento.codigo != aux) {
                    segmentos.push(data.segmento.fullCodigo);
                    aux = data.segmento.codigo;
                }
            })
            registros.map((data, i) => {
                for (let i = 0; i < segmentos.length; i++) {
                    if (!datosprueba[i]) {
                        datosprueba[i] = {
                            elemento: [],
                            alineacion: [],
                            condicionEstructural: [],
                            estabilidad: [],
                            longitudIncumplimiento: [],
                            observaciones: [],
                            cumplimiento: []
                        };
                    }
                    if (data.segmento.fullCodigo === segmentos[i]) {
                        datosprueba[i]['elemento'].push(data.elementoContencion);
                        datosprueba[i]['alineacion'].push(data.alineacion);
                        datosprueba[i]['condicionEstructural'].push(data.condicionEstructural);
                        datosprueba[i]['estabilidad'].push(data.estabilidad);
                        datosprueba[i]['longitudIncumplimiento'].push(data.longitudIncumplimiento);
                        datosprueba[i]['observaciones'].push(data.observaciones);
                    }
                }
            })
            for (let i = 0; i < datosprueba.length; i++) {
                let cuenta = 0;
                let cuenta2 = 0;
                let valorminlongitud = valorpromedio;
                for (let j = 0; j < datosprueba[i]['elemento'].length; j++) {
                    cuenta = 0
                    if (datosprueba[i]['longitudIncumplimiento'][j] >= valorminlongitud) cuenta++
                    if (cuenta > 0) { datosprueba[i]['cumplimiento'][j] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple'); cuenta2++; }
                    else datosprueba[i]['cumplimiento'][j] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')
                }
                if (cuenta2 >= 2) cumplimientoSegmento.push(translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple'))
                else cumplimientoSegmento.push(translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple'))
                cumplimientoPuntual.push(cuenta2)
            }
            const MostrarDetalles = (valor, valor2) => {
                let det = document.getElementById('detalles_' + valor)
                let most = document.getElementById('mostrar_' + valor)
                if (det) {
                    let disp = det.style.display;
                    if (disp != "none") {
                        det.style.display = "none"
                    }
                    else {
                        det.style.display = ""
                    }
                }
                if (most) {
                    let html = most.innerHTML;
                    if (html != translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')) {
                        most.innerHTML = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')
                    }
                    else {
                        most.innerHTML = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.ocultar')
                    }
                }
            }
            return (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.SEGMENTO')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.IncumpPuntuales')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CumplimientoIndicSegmento')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Visualizar')}</TableCell>
                        </TableRow>
                    </TableHead>
                    {segmentos.map((row, i) => (
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ textAlign: "left" }}>{segmentos[i]}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{cumplimientoPuntual[i]}</TableCell>
                                {cumplimientoSegmento[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left", color: "red" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                }
                                {cumplimientoSegmento[i] != translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                }
                                <TableCell style={{ textAlign: "right" }}><span id={"mostrar_" + i} style={{ color: "blue", cursor: "pointer" }} onClick={(evt) => MostrarDetalles(i, evt)}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')}</span></TableCell>
                            </TableRow>
                            <TableRow style={{ display: "none" }} id={"detalles_" + i}>
                                <TableCell colSpan="5">
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Elemento')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Alineacion')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CondicionEstructural')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Estabilidad')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.LongitudIncumplimiento')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Observaciones')} </TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumplimiento')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {datosprueba[i]['elemento'].map((row, j) => (
                                                <TableRow>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['elemento'][j]['fullCodigoConsecutivo']}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['alineacion'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['condicionEstructural'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['estabilidad'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['longitudIncumplimiento'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['observaciones'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['cumplimiento'][j]}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ))}

                </Table>

            )
        } else if (datosCarga['hydra:member'] && parametros && parametros != [] && this.props.record.indicador === "/parametros/13") {
            let registros = datosCarga['hydra:member'];
            let totalElementos = [];
            let totalElementosNoCumplen = [];
            let cumplimientoIndicador = [];
            let porcentaje = [];
            let segmentos = [];
            let aux = -1;
            let datosprueba = [];
            //console.log(registros)
            registros.map((data, i) => {
                if (data.segmento.codigo != aux) {
                    segmentos.push(data.segmento.fullCodigo);
                    aux = data.segmento.codigo;
                }
            })
            registros.map((data, i) => {
                for (let i = 0; i < segmentos.length; i++) {
                    if (!datosprueba[i]) {
                        datosprueba[i] = {
                            elemento: [],
                            cumplimiento: [],
                            estadoFisico: [],
                            estadoSoporte: [],
                            estadoCentro: [],
                            estadoTablero: [],
                            observaciones: [],
                            valorCumplimiento: [],
                            
                        };
                    }
                    if (data.segmento.fullCodigo === segmentos[i]) {
                        datosprueba[i]['elemento'].push(data.elementoRed);
                        datosprueba[i]['cumplimiento'].push(data.cumplimiento);
                        datosprueba[i]['estadoFisico'].push(data.estadoFisico);
                        datosprueba[i]['estadoSoporte'].push(data.estadoSoporte);
                        datosprueba[i]['estadoCentro'].push(data.estadoCentro);
                        datosprueba[i]['estadoTablero'].push(data.estadoTablero);
                        datosprueba[i]['observaciones'].push(data.observaciones);
  
                    }
                }
            })
            let totalElementoss =0;
            
            
            for (let i = 0; i < datosprueba.length; i++) {
                let totalElementosNo =0;
                let resultado = 0;
                totalElementos.push(datosprueba[i]['elemento'].length)
                totalElementoss = datosprueba[i]['elemento'].length;
                
                datosprueba[i]['elemento'].map((row, j) => {
                    if (datosprueba[i]['cumplimiento'][j] == translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.estadoa.Malo') || 
                        datosprueba[i]['estadoFisico'][j] == translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.estadoa.Malo') || 
                        datosprueba[i]['estadoSoporte'][j] == translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.estadoa.Malo') || 
                        datosprueba[i]['estadoCentro'][j] == translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.estadoa.Malo') ||
                        datosprueba[i]['estadoTablero'][j] == translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.estadoa.Malo')){
                        totalElementosNo ++ 
                        
                    }
                    if(totalElementosNo == 0){
                         resultado = 0;
                    }else{
                         resultado = (totalElementosNo / totalElementoss) * 100;
                    }
                    
                });
                totalElementosNoCumplen.push(totalElementosNo)
                porcentaje.push(resultado);
                if (porcentaje[i] > valorpromedio) {
                    cumplimientoIndicador[i] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')
                }
                else {
                    cumplimientoIndicador[i] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')
                }
            }
            
            const MostrarDetalles = (valor, valor2) => {
                let det = document.getElementById('detalles_' + valor)
                let most = document.getElementById('mostrar_' + valor)
                if (det) {
                    let disp = det.style.display;
                    if (disp != "none") {
                        det.style.display = "none"
                    }
                    else {
                        det.style.display = ""
                    }
                }
                if (most) {
                    let html = most.innerHTML;
                    if (html != translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')) {
                        most.innerHTML = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')
                    }
                    else {
                        most.innerHTML = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.ocultar')
                    }
                }
            }
            return (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.SEGMENTO')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.elementosQueNoCumplen')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.totalElementos')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.porcentajeNoCumple')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CumplimientoIndicSegmento')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Visualizar')}</TableCell>
                        </TableRow>
                    </TableHead>
                    {segmentos.map((row, i) => (
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ textAlign: "left" }}>{segmentos[i]}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{totalElementosNoCumplen[i]}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{totalElementos[i]}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{porcentaje[i].toFixed(0)} %</TableCell>                             
                                {cumplimientoIndicador[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') ?
                                    <TableCell style={{ textAlign: "right", color: "red" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                    :
                                    <TableCell style={{ textAlign: "right" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                }                               
                                <TableCell style={{ textAlign: "right" }}><span id={"mostrar_" + i} style={{ color: "blue", cursor: "pointer" }} onClick={(evt) => MostrarDetalles(i, evt)}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')}</span></TableCell>
                            </TableRow>
                            <TableRow style={{ display: "none" }} id={"detalles_" + i}>
                                <TableCell colSpan="5">
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Elemento')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.FuncionamientoLuminaria')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.EstadofisicoLuminaria')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.EstadoSoportes')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.EstadoCentroMando')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.EstadoTableroControl')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Observaciones')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumplimiento')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {datosprueba[i]['elemento'].map((row, j) => (
                                                <TableRow>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['elemento'][j]['fullCodigoConsecutivo']}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['cumplimiento'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['estadoFisico'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['estadoSoporte'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['estadoCentro'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['estadoTablero'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['observaciones'][j]}</TableCell>
                                                    {datosprueba[i]['cumplimiento'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.estadoa.Malo') || 
                                                     datosprueba[i]['estadoFisico'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.estadoa.Malo') || 
                                                     datosprueba[i]['estadoSoporte'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.estadoa.Malo') || 
                                                     datosprueba[i]['estadoCentro'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.estadoa.Malo') ||
                                                     datosprueba[i]['estadoTablero'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.estadoa.Malo') ?
                                                        <TableCell style={{ textAlign: "left", color: "red" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                                        :
                                                        <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                                    }
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ))}

                </Table>

            )
        }else if (datosCarga['hydra:member'] && parametros && parametros != [] && this.props.record.indicador === "/parametros/15") {
            let registros = datosCarga['hydra:member'];
            let segmentos = [];
            let cumplimientoCalzada = []
            let promedioCarrilI = [];
            let promedioCarrilD = [];
            let incumplimientosPuntuales = [];

            let aux = -1;
            let datosprueba = [];
            //console.log(registros)
            registros.map((data, i) => {
                if (data.segmento.codigo != aux) {
                    segmentos.push(data.segmento.fullCodigo);
                    aux = data.segmento.codigo;
                }
            })
            registros.map((data, i) => {
                for (let i = 0; i < segmentos.length; i++) {
                    if (!datosprueba[i]) {
                        datosprueba[i] = {
                            carrilIzq: [],
                            carrilDer: [],
                            abscisa: [],
                            valormedio: [],
                            niveltrafico: []
                        };
                    }
                    if (data.segmento.fullCodigo === segmentos[i]) {
                        datosprueba[i]['abscisa'].push(data.abscisa);
                        datosprueba[i]['carrilIzq'].push(data.carrilIzquierdo);
                        datosprueba[i]['carrilDer'].push(data.carrilDerecho);
                        datosprueba[i]['valormedio'].push(data.valorMedio);
                        datosprueba[i]['niveltrafico'].push(data.nivelTrafico);
                    }
                }
            })
            //console.log(datosprueba)
            for (let i = 0; i < datosprueba.length; i++) {
                let cumplimiento = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple');
                let cumplimientoIzq = 0;
                let cumplimientoDer = 0;
                let cuenta = 0;
                let averageizq = 0;
                let averageder = 0;
                let valormedioTemp = datosprueba[i]['valormedio'][0];
                for (let j = 0; j < datosprueba[i]['abscisa'].length; j++) {
                    cuenta++;
                    averageizq += parseFloat(datosprueba[i]['carrilIzq'][j]);
                    averageder += parseFloat(datosprueba[i]['carrilDer'][j]);
                    cuenta++;
                    if (parseFloat(datosprueba[i]['carrilIzq'][j]) > valormedioTemp) {
                        cumplimientoIzq++;
                    }
                    if (parseFloat(datosprueba[i]['carrilDer'][j]) > valormedioTemp) {
                        cumplimientoDer++;
                    }
                }
                if ((cumplimientoIzq + cumplimientoDer) <= 0) {
                    cumplimiento = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')
                }

                promedioCarrilI.push((averageizq / cuenta).toFixed(2));
                promedioCarrilD.push((averageder / cuenta).toFixed(2));
                incumplimientosPuntuales.push(cumplimientoIzq + cumplimientoDer);
                cumplimientoCalzada.push(cumplimiento);
            }

            //console.log(segmentos)
            //console.log(promedioI)
            const MostrarDetalles = (valor, valor2) => {
                //alert("Prueba"+valor);
                //console.log(valor)
                let det = document.getElementById('detalles_' + valor)
                let most = document.getElementById('mostrar_' + valor)
                if (det) {
                    let disp = det.style.display;
                    if (disp != "none") {
                        det.style.display = "none"
                    }
                    else {
                        det.style.display = ""
                    }
                }
                if (most) {
                    let html = most.innerHTML;
                    if (html != translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')) {
                        most.innerHTML = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')
                    }
                    else {
                        most.innerHTML = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.ocultar')
                    }
                }

                //console.log(valor2)
            }
            return (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.SEGMENTO')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.PromedioCarrilIzquierdo')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.PromedioCarrilDerecho')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.IncumplimientosPuntuales')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CumplimientoIndicSegmento')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Visualizar')}</TableCell>
                        </TableRow>
                    </TableHead>
                    {segmentos.map((row, i) => (
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ textAlign: "left" }}>{segmentos[i]}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(promedioCarrilI[i], 2)}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(promedioCarrilD[i], 2)}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{incumplimientosPuntuales[i]}</TableCell>
                                {cumplimientoCalzada[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left", color: "red" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                }
                                {cumplimientoCalzada[i] != translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                }

                                <TableCell style={{ textAlign: "right" }}><span id={"mostrar_" + i} style={{ color: "blue", cursor: "pointer" }} onClick={(evt) => MostrarDetalles(i, evt)}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')}</span></TableCell>
                            </TableRow>
                            <TableRow style={{ display: "none" }} id={"detalles_" + i}>
                                <TableCell colSpan="6">
                                    <Table aria-label="simple table" style={{ width: "50%" }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ textAlign: "center" }} >Nivel de Tráfico: {datosprueba[i]['niveltrafico'][0]}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.AbscisaFisura')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CarrilIzquierdo')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CarrilDerecho')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {datosprueba[i]['abscisa'].map((row, j) => (
                                                <TableRow>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(datosprueba[i]['abscisa'][j])}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['carrilIzq'][j].toFixed(2)}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['carrilDer'][j].toFixed(2)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ))}

                </Table>

            )
        } else if (datosCarga['hydra:member'] && parametros && parametros != [] && this.props.record.indicador === "/parametros/16") {
            let registros = datosCarga['hydra:member'];
            let segmentos = [];
            let promedioI = [];
            let aux = -1;
            let datosprueba = [];
            //console.log(registros)
            registros.map((data, i) => {
                if (data.segmento.codigo != aux) {
                    segmentos.push(data.segmento.fullCodigo);
                    aux = data.segmento.codigo;
                }
            })
            registros.map((data, i) => {
                for (let i = 0; i < segmentos.length; i++) {
                    if (!datosprueba[i]) {
                        datosprueba[i] = {
                            indicador: [],
                            abscisaini: [],
                            abscisafin: [],
                            observaciones: [],
                        };
                    }
                    if (data.segmento.fullCodigo === segmentos[i]) {
                        datosprueba[i]['indicador'].push(data.pci);
                        datosprueba[i]['abscisaini'].push(data.abini);
                        datosprueba[i]['abscisafin'].push(data.abfin);
                        datosprueba[i]['observaciones'].push(data.observaciones);
                    }
                }
            })
            return (
                <Table aria-label="simple table" style={{ width: "70%" }}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.SEGMENTO')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.PCI')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CodigoColor')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {segmentos.map((row, i) => (
                            <TableRow>
                                <TableCell style={{ textAlign: "left" }}>{segmentos[i]}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['indicador']}</TableCell>
                                <TableCell style={{ textAlign: "right", backgroundColor: this.ColorPCI(datosprueba[i]['indicador']) }}></TableCell>
                            </TableRow>
                        ))
                        }
                    </TableBody>
                </Table>
            )
        } else if (datosCarga['hydra:member'] && parametros && parametros != [] && this.props.record.indicador === "/parametros/21") {
            let registros = datosCarga['hydra:member'];
            let segmentos2 = [];
            let cumplimientoSegmento = [];
            let incumplimientosPuntuales = [];
            let aux = -1;
            let datosprueba = [];
            
            registros.map((data, i) => {
                if (data.segmento.codigo != aux) {
                    segmentos2.push(data.segmento.fullCodigo);
                    aux = data.segmento.codigo;
                }
            })
            let segmentos = segmentos2.filter((item,index)=>{
                return segmentos2.indexOf(item) === index;
            })
            
            registros.map((data, i) => {
                for (let i = 0; i < segmentos.length; i++) {
                    if (!datosprueba[i]) {
                        datosprueba[i] = {
                            indicador: [],
                            abscisa: [],
                            observaciones: [],
                        };
                    }
                    if (data.segmento.fullCodigo === segmentos[i]) {
                        datosprueba[i]['indicador'].push(data.escalonamiento);
                        datosprueba[i]['abscisa'].push(data.abscisa);
                        datosprueba[i]['observaciones'].push(data.observaciones);
                    }
                }
            })
            for (let i = 0; i < datosprueba.length; i++) {
                let cumplimiento = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple');
                let cuenta = 0;
                for (let j = 0; j < datosprueba[i]['abscisa'].length; j++) {
                    if (parseFloat(datosprueba[i]['indicador'][j]) > valorpuntual) {
                        cuenta++;
                    }
                }
                if (cuenta === 0) {
                    cumplimiento = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple');
                }
                cumplimientoSegmento.push(cumplimiento);
                incumplimientosPuntuales.push(cuenta);
            }

            //console.log(segmentos)
            //console.log(promedioI)
            const MostrarDetalles = (valor, valor2) => {
                //alert("Prueba"+valor);
                //console.log(valor)
                let det = document.getElementById('detalles_' + valor)
                let most = document.getElementById('mostrar_' + valor)
                if (det) {
                    let disp = det.style.display;
                    if (disp != "none") {
                        det.style.display = "none"
                    }
                    else {
                        det.style.display = ""
                    }
                }
                if (most) {
                    let html = most.innerHTML;
                    if (html != translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')) {
                        most.innerHTML = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')
                    }
                    else {
                        most.innerHTML = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.ocultar')
                    }
                }

                //console.log(valor2)
            }
            return (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.SEGMENTO')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.IncumpPuntuales')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumplimiento')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Visualizar')}</TableCell>
                        </TableRow>
                    </TableHead>
                    {segmentos.map((row, i) => (
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ textAlign: "left" }}>{segmentos[i]}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{incumplimientosPuntuales[i]}</TableCell>
                                {cumplimientoSegmento[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left", color: "red" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                }
                                {cumplimientoSegmento[i] != translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                }

                                <TableCell style={{ textAlign: "right" }}><span id={"mostrar_" + i} style={{ color: "blue", cursor: "pointer" }} onClick={(evt) => MostrarDetalles(i, evt)}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')}</span></TableCell>
                            </TableRow>
                            <TableRow style={{ display: "none" }} id={"detalles_" + i}>
                                <TableCell colSpan="6">
                                    <Table aria-label="simple table" style={{ width: "50%" }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.AbscisaFisura')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Escalonamiento')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Observaciones')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {datosprueba[i]['abscisa'].map((row, j) => (
                                                <TableRow>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(datosprueba[i]['abscisa'][j])}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['indicador'][j], 2)}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['observaciones'][j]}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ))}

                </Table>
            )
        } else if (datosCarga['hydra:member'] && parametros && parametros != [] && this.props.record.indicador === "/parametros/22") {
            let registros = datosCarga['hydra:member'];
            let segmentos = [];
            let cumplimientoSegmento = [];
            let incumplimientosPuntuales = [];
            let aux = -1;
            let datosprueba = [];
            // console.log(registros)
            registros.map((data, i) => {
                if (data.segmento != null && data.segmento.codigo != aux) {
                    // console.log(segmentos.includes(data.segmento.fullCodigo), data.segmento.fullCodigo)
                    if (!segmentos.includes(data.segmento.fullCodigo)) {
                        segmentos.push(data.segmento.fullCodigo);
                        aux = data.segmento.codigo;
                    }
                }
            })
            // console.log(segmentos)
            registros.map((data, i) => {
                for (let i = 0; i < segmentos.length; i++) {
                    if (!datosprueba[i]) {
                        datosprueba[i] = {
                            indicador: [],
                            abscisa: [],
                            observaciones: [],
                        };
                    }
                    if (data.segmento != null && data.segmento.fullCodigo === segmentos[i]) {
                        datosprueba[i]['indicador'].push(data.alturaDesportillamiento);
                        datosprueba[i]['abscisa'].push(data.abscisa);
                        datosprueba[i]['observaciones'].push(data.observaciones);
                    }
                }
            })
            for (let i = 0; i < datosprueba.length; i++) {
                let cumplimiento = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple');
                let cuenta = 0;
                for (let j = 0; j < datosprueba[i]['abscisa'].length; j++) {
                    if (parseFloat(datosprueba[i]['indicador'][j]) > valorpuntual) {
                        cuenta++;
                    }
                }
                if (cuenta < valorpromedio) {
                    cumplimiento = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple');
                }
                cumplimientoSegmento.push(cumplimiento);
                incumplimientosPuntuales.push(cuenta);
            }

            // console.log(segmentos)
            //console.log(promedioI)
            const MostrarDetalles = (valor, valor2) => {
                //alert("Prueba"+valor);
                //console.log(valor)
                let det = document.getElementById('detalles_' + valor)
                let most = document.getElementById('mostrar_' + valor)
                if (det) {
                    let disp = det.style.display;
                    if (disp != "none") {
                        det.style.display = "none"
                    }
                    else {
                        det.style.display = ""
                    }
                }
                if (most) {
                    let html = most.innerHTML;
                    if (html != translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')) {
                        most.innerHTML = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')
                    }
                    else {
                        most.innerHTML = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.ocultar')
                    }
                }

                //console.log(valor2)
            }
            return (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.SEGMENTO')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.IncumpPuntuales')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumplimiento')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Visualizar')}</TableCell>
                        </TableRow>
                    </TableHead>
                    {segmentos.map((row, i) => (
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ textAlign: "left" }}>{segmentos[i]}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{incumplimientosPuntuales[i]}</TableCell>
                                {cumplimientoSegmento[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left", color: "red" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                }
                                {cumplimientoSegmento[i] != translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                }

                                <TableCell style={{ textAlign: "right" }}><span id={"mostrar_" + i} style={{ color: "blue", cursor: "pointer" }} onClick={(evt) => MostrarDetalles(i, evt)}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')}</span></TableCell>
                            </TableRow>
                            <TableRow style={{ display: "none" }} id={"detalles_" + i}>
                                <TableCell colSpan="6">
                                    <Table aria-label="simple table" style={{ width: "50%" }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.AbscisaFisura')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.ExtensionDesportillam')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Observaciones')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumplimiento')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {datosprueba[i]['abscisa'].map((row, j) => (
                                                <TableRow>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(datosprueba[i]['abscisa'][j])}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['indicador'][j], 2)}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['observaciones'][j]}</TableCell>
                                                    {datosprueba[i]['indicador'][j] > valorpuntual &&
                                                        <TableCell style={{ textAlign: "left", color: "red" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                                    }
                                                    {datosprueba[i]['indicador'][j] <= valorpuntual &&
                                                        <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                                    }
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ))}

                </Table>
            )
        } else if (datosCarga['hydra:member'] && parametros && parametros != [] && this.props.record.indicador === "/parametros/23") {
            let registros = datosCarga['hydra:member'];
            let segmentos = [];
            let segmentoLongJuntas = [];
            let cumplimientoSegmento = [];
            let incumplimientosPuntuales = [];
            let areaAfectada = [];
            let aux = -1;
            let datosprueba = [];
            console.log(registros)
            registros.map((data, i) => {
                if (data.segmento.codigo != aux) {
                    if (!segmentos.includes(data.segmento.fullCodigo)) {
                        segmentos.push(data.segmento.fullCodigo);
                        segmentoLongJuntas.push({
                            fullCodigo: data.segmento.fullCodigo,
                            longJuntas: data.rigidoDetalles.geometriaSegmentos[0].geometriaDltLongjuntas,
                            areaCarrilesVerdadera: data.rigidoDetalles.geometriaSegmentos[0].areaCarrilesVerdadera
                        })
                        aux = data.segmento.codigo;
                    }
                }
            })

            // console.log(segmentos)
            console.log(segmentoLongJuntas)
            registros.map((data, i) => {
                for (let i = 0; i < segmentos.length; i++) {
                    if (!datosprueba[i]) {
                        datosprueba[i] = {
                            fullCodigo: "",
                            indicador: [],
                            abscisa: [],
                            observaciones: [],
                            longJuntaSegmento: 0,
                            longJuntasAcum: 0

                        };
                    }
                    if (data.segmento.fullCodigo === segmentos[i]) {
                        datosprueba[i]["fullCodigo"] = data.segmento.fullCodigo;
                        datosprueba[i]['indicador'].push(data.longitudJuntaAfectada);
                        datosprueba[i]['abscisa'].push(data.abscisa);
                        datosprueba[i]['observaciones'].push(data.observaciones);
                        datosprueba[i]['longJuntaSegmento'] = segmentoLongJuntas.find(x => x.fullCodigo == data.segmento.fullCodigo).longJuntas;
                        datosprueba[i]['areaCarrilesVerdadera'] = segmentoLongJuntas.find(x => x.fullCodigo == data.segmento.fullCodigo).areaCarrilesVerdadera;
                        datosprueba[i]['longJuntasAcum'] += data.longitudJuntaAfectada;
                    }
                }
            })
            //cumplimiento de area afectada
            // console.log(datosprueba);
            let cuenta = 0;
            let criteria = "";
            for (let i = 0; i < datosprueba.length; i++) {
                let cumplimiento = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple');
                criteria = this.calculoAreaAfectada(datosprueba[i]['longJuntaSegmento'], datosprueba[i]['longJuntasAcum']);
                if (criteria < valorpuntual) {
                    cumplimiento = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple');
                    cuenta++;
                }
                cumplimientoSegmento.push(cumplimiento);
                areaAfectada.push(criteria)
            }

            incumplimientosPuntuales.push(cuenta);
            // console.log(segmentos)
            //console.log(promedioI)
            const MostrarDetalles = (valor, valor2) => {
                //alert("Prueba"+valor);
                //console.log(valor)
                let det = document.getElementById('detalles_' + valor)
                let most = document.getElementById('mostrar_' + valor)
                if (det) {
                    let disp = det.style.display;
                    if (disp != "none") {
                        det.style.display = "none"
                    }
                    else {
                        det.style.display = ""
                    }
                }
                if (most) {
                    let html = most.innerHTML;
                    if (html != translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')) {
                        most.innerHTML = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')
                    }
                    else {
                        most.innerHTML = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.ocultar')
                    }
                }

                //console.log(valor2)
            }
            return (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.SEGMENTO')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.areaAfectada')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumplimiento')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Visualizar')}</TableCell>
                        </TableRow>
                    </TableHead>
                    {segmentos.map((row, i) => (
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ textAlign: "left" }}>{segmentos[i]}</TableCell>
                                {/* <TableCell style={{ textAlign: "right" }}>{incumplimientosPuntuales[i]}</TableCell> */}
                                <TableCell style={{ textAlign: "right" }}>{areaAfectada[i].toFixed(2)}%</TableCell>
                                {cumplimientoSegmento[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left", color: "red" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                }
                                {cumplimientoSegmento[i] != translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                }

                                <TableCell style={{ textAlign: "right" }}><span id={"mostrar_" + i} style={{ color: "blue", cursor: "pointer" }} onClick={(evt) => MostrarDetalles(i, evt)}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')}</span></TableCell>
                            </TableRow>
                            <TableRow style={{ display: "none" }} id={"detalles_" + i}>
                                <TableCell colSpan="6">
                                    <Table aria-label="simple table" style={{ width: "50%" }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.AbscisaFisura')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.LongitudJuntasAfectadas')})</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Observaciones')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {datosprueba[i]['abscisa'].map((row, j) => (
                                                <TableRow>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(datosprueba[i]['abscisa'][j])}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['indicador'][j], 2)}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['observaciones'][j]}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ))}

                </Table>
            )
        } 
        else if (datosCarga['hydra:member'] && parametros && parametros != [] && (this.props.record.indicador === "/parametros/17")) {
            return (
                <div>
                    <h2>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableMessege')}</h2>
                </div>
            )
        }
        else {
            return (
                <div className="Reload2">
                    <img src={loader} />
                </div>
            )
        }
    }
}

export default translate(EvaluacionPorSegmento);