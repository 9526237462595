import React, { useState, Fragment, useEffect } from 'react';
import {
  DateInput, BooleanInput, required, TabbedForm, FormTab, AutocompleteInput, NumberInput, SelectInput, ImageField, ImageInput,
  ReferenceInput, SimpleFormIterator, ArrayInput, TextInput, FormDataConsumer, TextField
} from 'react-admin';
import { Edit } from './../Edit';
import {
  abValidationInicio, abValidationFin, validarDosDecimales, validarUnDecimal, validarAB, isFechaInvalida
} from '../../../src/resources/validacionesInputs';
import { withStyles } from '@material-ui/core';
import { styles } from './../../EstilosGrid';
import Grid from '@material-ui/core/Grid';
import { minValue, maxValue, minLength, maxLength } from 'ra-core';
import Typography from '@material-ui/core/Typography';
import { REDUX_FORM_NAME } from 'react-admin';
import { change } from 'redux-form';
import config from '../../config/config';
import Divider from '@material-ui/core/Divider';
import { PRInput } from '../../inputs/PRInput';
import { DInput } from '../../inputs/DInput';
import { DEInput } from '../../inputs/DEInput';
import CoordenadasProyecto from '../CoordenadasInventario';
import AbsSegmento from '../AbsSegmento';
import { latMininima, lngMininima, Entero } from '../validacionesInputs';
//import Reparaciones from './campos/reparaciones';
import { ButtonCarousel } from './../images/ButtonCarouselPeru';
import { PimsElementoActions } from '../elementos/PimsElementoActions';
import { ArrayInputImagesEdit } from './../images/ArrayInputImagesEdit';
import { translate } from 'react-admin';
// import '../../App.css';
let urlAppjs = config.UrlServer;

export const IndicadorComplementarioEdit = translate(withStyles(styles)(({ classes, ...props }) => {
  const { translate } = props;

  /* NEW EDER */
  const [sistema, setSistema] = useState(null);
  const ValidarSistema = (value) => {
    let lenghtObject = Object.keys(value);
    let valor = '';
    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setSistema(value);
  }

  const [tipo_elementos, setTipoElementos] = useState('');
  const [tipo_acceso, setTipoAcceso] = useState('');

  const ValidarTipoElementos = (value) => {
    let lenghtObject = Object.keys(value);
    let valor = '';
    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setTipoElementos(value);
  }
  const modal = () => {
    console.log('click...');
  }

  const [peso, setPeso] = useState(0);
  const updatePeso = (e) => {
    //console.log(e);
  }

const validarUmbral2 = (value) => {
  //debugger
  var errorMsg = [];
  var returnMsg = "";
  let tam = document.getElementsByClassName("peso").length;
  let umbral = document.getElementsByName("umbralFallo")[0].value;
  let suma = 0.0;
  for (let i = 0; i < tam; i++) {
      let elements = document.getElementsByName("preguntaIndicadorComplementario["+i+"].peso");
      let dato = document.getElementsByName("preguntaIndicadorComplementario["+i+"].peso")[0].defaultValue;
      suma += parseFloat(dato);
  }
  //debugger
  if (umbral > suma) {
      errorMsg.push(translate('resources.validaciones.umbralSumatoria') );
    }
  if (errorMsg.length > 0) {
    for (var i = 0; i < errorMsg.length; i++) {
        returnMsg = returnMsg + (i + 1) + ")." + errorMsg[i];
    }
    // errorMsg.map( msg => {returnMsg = returnMsg + msg})
    return returnMsg;
  }
}

  /* END EDER */
  const validarUmbralnuevo = (value) => {
    var array = []; 
    var errorMsg = [];
    var returnMsg = "";
    if (typeof value !== 'undefined') {
        for (var i = 0; i < value.length; i++) {
          let pesopregunta = value[i].peso;
          if(value[i].tipoCampo != 'Selección Múltiple')
          {
            
            //document.getElementById("preguntaIndicadorComplementario["+i+"].respuestaIndicadorComplementario").style.display = "none";
          }else
          {
            
            //document.getElementById("preguntaIndicadorComplementario["+i+"].respuestaIndicadorComplementario").style.display = "unset";
            if (typeof value[i].respuestaIndicadorComplementario !== 'undefined') {
              let pesoTotal=0;
              let pesorespuesta=0;
              let numero = i +1;
              for (var j = 0; j < value[i].respuestaIndicadorComplementario.length; j++) {
                pesorespuesta = value[i].respuestaIndicadorComplementario[j].peso;
                pesoTotal=pesoTotal+pesorespuesta;
                if (pesorespuesta > pesopregunta) {
                  errorMsg.push(translate('resources.validaciones.pesoRespuesta') + numero + "." );
                   
                 //console.log(value[i].respuestaRegistroNovedad[j].peso)
                }
                }   
                
            }
          }
       
          
          //console.log(value[i].peso)

          //debugger
        }
    }
    if (errorMsg.length > 0) {
      for (var i = 0; i < errorMsg.length; i++) {
          returnMsg = returnMsg + (i + 1) + ")." + errorMsg[i];
      }
      // errorMsg.map( msg => {returnMsg = returnMsg + msg})
      return returnMsg;
    }
 
  }



  const [latitudMinimaArray, setLatitudArray] = useState('');
  const LatitudMinimaArray = (value) => {
    setLatitudArray(value)
  }
  const [longitudMinimaArray, setLongitudArray] = useState('');
  const LongitudMinimaArray = (value) => {
    setLongitudArray(value)
  }
  const [longitudMaximaArray, setLongitudMaxdArray] = useState('');
  const LongitudMaximaArray = (value) => {
    setLongitudMaxdArray(value)
  }
  const [latitudMaximaArray, setLatitudMaxdArray] = useState('');
  const LatitudMaximaArray = (value) => {
    setLatitudMaxdArray(value)
  }
  const [elemento, setElemento] = useState('');
  const [latMin, setLatMin] = useState('');
  const [lngMin, setLngMin] = useState('');
  const [latMax, setLatMax] = useState('');
  const [lngMax, setLngMax] = useState('');


  const ValidarTipoAcceso = (value) => {
    let lenghtObject = Object.keys(value);
    let valor = '';
    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setTipoAcceso(value);
  }

  const ValidarSegmento = (value) => {
    let lenghtObject = Object.keys(value);
    let valor = '';
    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setSegmento(value)
  }
  const LngIni = (value) => {
    if (lngMax !== '') {
      if (parseFloat(value) > parseFloat(lngMin) || parseFloat(value) < parseFloat(lngMax)) {
        return translate('resources.validaciones.rangoEntre') + lngMin + " y " + lngMax;
      }
    }
  }
  const LatIni = (value) => {
    if (latMax !== '') {
      if (parseFloat(value) < parseFloat(latMin) || parseFloat(value) > parseFloat(latMax)) {
        return translate('resources.validaciones.rangoEntre') + latMin + " y " + latMax;
      }
    }
  }
  const [estados, setEstado] = useState('');
  const ValidarEstado = (value) => {
    setEstado(value)
  }
  //const Unidades = { unidadFuncional: unidads };

  const [unidads, setUnidad] = useState('');
  const ValidarUnidad = (value) => {
    //let lenghtObject = Object.keys(value);
    //let valor = '';
    //for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setUnidad(value)
  }

  const [tramos, setTramo] = useState('');

  const ValidarTramo = (value) => {
    let lenghtObject = Object.keys(value);
    let valor = '';
    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setTramo(value)
  }
  const [segmentos, setSegmento] = useState('');


  const [variante, setVariante] = useState('');
  

  const [tipo, setTipo] = useState('');
  const ValidarTipo = (value) => {

    // let lenghtObject = Object.keys(value);
    // let valor = '';

    // for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setTipo(value)

  }
  const [tipoa, setTipoa] = useState('');
  const ValidarTipoa = (value) => {

    // let lenghtObject = Object.keys(value);
    // let valor = '';

    // for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setTipoa(value)

  }
  const [totaltablero, setTotaltablero] = useState(0);
  const Longitu = (value) => {
    // console.log(value);
    let valAncho = 0;
    valAncho = document.getElementById('1').value;
    if (valAncho) { if (valAncho !== '') { valAncho = valAncho } }
    // console.log(parseFloat (valAncho) + parseFloat(value));
    //setTotaltablero(valAncho * value);
    let gtr = totaltablero;
    setTotaltablero(0);

  }


  /*VALIDAR IMÁGENES ACTUALES */
  const [imagenesOpen, setImagenesOpen] = useState('');
  const ImagenesEdit = (value) => {
    setImagenesOpen(value);

  }

  /*OPCIONES PARA TIPO DE IMÁGENES*/
  const [opcionTipoImagen, setOpcionTipoImagen] = useState([{ id: 'Cargando...', name: 'Cargando...' }]);
  const OpcionTipoImagen = (value) => {
    if (value !== '/tipo_elementos/DESC') {
      setOpcionTipoImagen([
        { id: '/tipo_images/1', name: 'Panorámica' },
        { id: '/tipo_images/7', name: 'Elemento completo' },
        { id: '/tipo_images/8', name: 'Detalle 1' },
        { id: '/tipo_images/9', name: 'Detalle 2' },
        { id: '/tipo_images/5', name: 'Deterioro 1' },
        { id: '/tipo_images/6', name: 'Deterioro 2' },
      ])
    }
  }


  const ValidarElemento = (value) => {
      setElemento(value)
      //debugger
  }

  const [shouldShowOnReduxChange, setShouldShowOnReduxChange] = useState(true);

  return (
    
    <Edit title="dynatest.resources.indicador_complementarios.title.editTitle"  {...props} submitOnEnter={[validarUmbral2]}>
   
      <TabbedForm redirect="list" >
        {/* ......:::.... IDENTIFICACION ......:::....*/}
        <FormTab label="dynatest.resources.indicador_complementarios.tabs.identificacion"  >
          <Grid  container spacing={24} >
            <Grid item xs={4}>
              
              <TextInput label="resources.indicador_complementarios.fields.nombre"  source="nombre" fullWidth validate={[required(), minLength(1), maxLength(100)]} formClassName={classes.grid_cont3} />
            </Grid>
            <Grid item xs={4}>
              <SelectInput source="estado" label="resources.indicador_complementarios.fields.estado" validate={[required()]} fullWidth choices={[
                { id: translate('dynatest.resources.indicador_complementarios.select.estado.Activo'), name: 'dynatest.resources.indicador_complementarios.select.estado.Activo' },
                { id: translate('dynatest.resources.indicador_complementarios.select.estado.Inactivo'), name: 'dynatest.resources.indicador_complementarios.select.estado.Inactivo' },
              ]} />
            </Grid>
            <Grid item xs={4}>
              {/* <DInput label="Umbral de fallo" source="umbralFallo" id="umbralFallo" className="align-number" fullWidth validate={[required(), minValue(1), maxValue(5), validateDecimal]} formClassName={classes.grid_cont3} /> */}
              <NumberInput label="resources.indicador_complementarios.fields.umbralFallo"  className="umbralfa" source="umbralFallo"  fullWidth validate={[required(), validarDosDecimales,validarUmbral2, minValue(0)]} formClassName={classes.grid_cont3} />
            </Grid>
            <Grid item xs={4}>
              <ReferenceInput label="resources.indicador_complementarios.fields.proyecto" source="proyecto" id="proyecto" reference="proyectos" fullWidth validate={[required()]}  defaultValue={window.localStorage.getItem('id_project2')}>
                <SelectInput optionText="nombre" disabled/>
              </ReferenceInput>
            </Grid>
            <FormDataConsumer>
            {
              ({ formData, dispatch, ...rest }) => (
                <Fragment>
                  <Grid item xs={4}>
                    <ReferenceInput 
                      label="resources.indicador_complementarios.fields.sistema" 
                      source="sistema" 
                      reference="sistemas" 
                      id="sistema"
                      filter={{ id: ["ALR", "CLZ", "DRE", "OTR", "PTS", "RDS", "SEG", "SEN"]}} 
                      onChange={value => {
                        dispatch(change(REDUX_FORM_NAME, 'tipoElemento', null))
                        setShouldShowOnReduxChange(false)
                      }}
                      fullWidth validate={[required(), ValidarTipoElementos, ValidarSistema]}
                    >
                      <SelectInput optionText="nombre" />
                    </ReferenceInput>
                  </Grid>
                  {(sistema != null) && (
                    <Fragment>
                      {shouldShowOnReduxChange ?
                        <Grid item xs={4}>
                          {(sistema == '/sistemas/OTR') ?
                          <ReferenceInput 
                            label="resources.indicador_complementarios.fields.tipoElemento" 
                            id="tipoElemento"
                            source="tipoElemento" 
                            reference="tipo_elementos" 
                            fullWidth filter={{puntos: 1, sistema: "OTR"}} 
                            validate={[required()]} 
                            sort={{ field: 'nombre', order: 'asc' }}>
                            <SelectInput optionText="nombre" />
                          </ReferenceInput>
                          :
                            (sistema == '/sistemas/SEN') ?
                            <ReferenceInput label="resources.indicador_complementarios.fields.tipoElementoSenal" source="tipoElementoSenal" reference="tipo_elemento_senals" fullWidth validate={[required(), ValidarElemento]} sort={{ field: 'nombre', order: 'asc' }}>
                              <SelectInput optionText="nombre" />
                            </ReferenceInput>
                            :
                            <ReferenceInput 
                              label="resources.indicador_complementarios.fields.tipoElemento" 
                              allowEmpty emptyValue="" 
                              id="tipoElemento"
                              source="tipoElemento" 
                              reference="tipo_elementos" 
                              fullWidth filter={{ sistema: tipo_elementos }} 
                              validate={[required()]} 
                              sort={{ field: 'nombre', order: 'asc' }}>
                              <SelectInput optionText="nombre" />
                            </ReferenceInput>
                          }
                        </Grid>
                        :
                        setShouldShowOnReduxChange(true)
                      }
                    </Fragment>
                  )}
                </Fragment>
              )}
            </FormDataConsumer>
            {(sistema != null) && (sistema == '/sistemas/SEN') && (elemento) && (
              <Grid item xs={4}>
                <ReferenceInput label="resources.indicador_complementarios.fields.tipoSenal" source="tipoSenal" reference="tipo_senals" fullWidth filter={{ tipoElementoSenal: elemento }} validate={[required()]} sort={{ field: 'nombre', order: 'asc' }}>
                  <SelectInput optionText="nombre" />
                </ReferenceInput>
              </Grid>
            )}

          </Grid>
        </FormTab>
                {/* ......:::.... PREGUNTAS ......:::....*/}
        <FormTab label="dynatest.resources.indicador_complementarios.tabs.preguntas" >
          <Typography style={{ marginBottom: "-25px", marginTop: "5px" }}><b>{translate("dynatest.resources.indicador_complementarios.subTitle.preguntas")}</b></Typography>
         
          <ArrayInput fullWidth label=" " source="preguntaIndicadorComplementario" validate={[validarUmbralnuevo]} defaultValue={[
            {
              pregunta: "", peso: "", tipoCampo: "", estado: "",
            }
          ]}>
           
            <SimpleFormIterator>
            
              <TextInput label="resources.indicador_complementarios.fields.pregunta" source="pregunta" id="pregunta" fullWidth validate={[required(), minLength(1), maxLength(60)]} formClassName={classes.grid_cont3} />
              {/* <DInput label="Peso" source="peso" id="peso" className="align-number" fullWidth validate={[required(), minValue(1), maxValue(5), validateDecimal]} formClassName={classes.grid_cont3} /> */}
              <NumberInput label="resources.indicador_complementarios.fields.peso" source="peso"  className="peso" fullWidth validate={[required(), validarDosDecimales, minValue(0)]} formClassName={classes.grid_cont3} />
              <SelectInput label="resources.indicador_complementarios.fields.tipoCampo" source="tipoCampo" id="tipoCampo" allowEmpty emptyValue="" validate={[required()]} choices={[
                { id: translate('dynatest.resources.indicador_complementarios.select.tipoCampo.seleccionMultiple'), name: "dynatest.resources.indicador_complementarios.select.tipoCampo.seleccionMultiple" },
                { id: translate('dynatest.resources.indicador_complementarios.select.tipoCampo.numerico'), name: "dynatest.resources.indicador_complementarios.select.tipoCampo.numerico" },
              ]} fullWidth formClassName={classes.grid_cont3} />
              <SelectInput label="resources.indicador_complementarios.fields.estado" source="estado" id="estado" allowEmpty emptyValue="" validate={[required()]} choices={[
                { id: translate('dynatest.resources.indicador_complementarios.select.estado.Activo'), name: 'dynatest.resources.indicador_complementarios.select.estado.Activo' },
                { id: translate('dynatest.resources.indicador_complementarios.select.estado.Inactivo'), name: 'dynatest.resources.indicador_complementarios.select.estado.Inactivo' },
              ]} fullWidth formClassName={classes.grid_cont3} />
              <FormDataConsumer style={{ width: '50%' }}>
                {
                  ({ formData, scopedFormData, getSource, ...rest }) => (scopedFormData != null &&
                   scopedFormData.tipoCampo != null && scopedFormData.tipoCampo === translate("dynatest.resources.indicador_complementarios.select.tipoCampo.numerico") &&
                   <NumberInput defaultValue="0" label="resources.indicador_complementarios.fields.decimales" source={getSource("decimales")} validate={[required(), Entero, minValue(0)]}  style={{ width: '370px' }} />
                  )
                }
              </FormDataConsumer>
              <FormDataConsumer style={{ width: '33%' }}>
                {
                  ({ formData, scopedFormData, getSource, ...rest }) => (scopedFormData != null &&
                   scopedFormData.tipoCampo != null && scopedFormData.tipoCampo === translate("dynatest.resources.indicador_complementarios.select.tipoCampo.numerico") &&
                    <Typography style={{marginTop: '1rem'}}><b>{translate("dynatest.resources.indicador_complementarios.subTitle.consideracionesPeso")}</b></Typography>
                  )
                }
              </FormDataConsumer>
              <FormDataConsumer style={{ width: '33%' }}>
                {
                  ({ formData, scopedFormData, getSource, ...rest }) => (scopedFormData != null &&
                   scopedFormData.tipoCampo != null && scopedFormData.tipoCampo === translate("dynatest.resources.indicador_complementarios.select.tipoCampo.numerico") &&
                    <Fragment>
                      <NumberInput defaultValue="0" label="resources.indicador_complementarios.fields.rangoInicial" source={getSource("rangoInicial")} validate={[required(), Entero, minValue(0)]} formClassName={classes.grid_cont3} style={{marginRight: '0.5rem'}} />
                      <NumberInput defaultValue="0" label="resources.indicador_complementarios.fields.rangoFinal" source={getSource("rangoFinal")} validate={[required(), Entero, minValue(0)]} formClassName={classes.grid_cont3s} />
                    </Fragment>
                  )
                }
              </FormDataConsumer>

              <FormDataConsumer style={{ width: '33%' }}>
                {
                  ({ formData, scopedFormData, getSource, ...rest }) => (scopedFormData != null &&
                   scopedFormData.tipoCampo != null && scopedFormData.tipoCampo === translate("dynatest.resources.indicador_complementarios.select.tipoCampo.seleccionMultiple") &&
                    <Typography><b>{translate("dynatest.resources.indicador_complementarios.subTitle.respuestas")}</b></Typography>
                  )
                }
              </FormDataConsumer>
      
              <FormDataConsumer style={{ width: '33%' }}>
                {
                  ({ formData, scopedFormData, getSource, ...rest }) => (scopedFormData != null &&
                    scopedFormData.tipoCampo != null && scopedFormData.tipoCampo === translate("dynatest.resources.indicador_complementarios.select.tipoCampo.seleccionMultiple") &&
                    
                    <ArrayInput fullWidth label=" " source={getSource("respuestaIndicadorComplementario")} defaultValue={[
                      {
                        respuesta: "0", peso: "0",
                      }
                    ]}>
                      <SimpleFormIterator fullWidth>
                        <TextInput label="resources.indicador_complementarios.fields.respuesta" source="respuesta" id="respuesta" fullWidth validate={[required(), minLength(1), maxLength(60)]} formClassName={classes.grid_cont6} />
                        <NumberInput label="resources.indicador_complementarios.fields.peso" source="peso" className="align-number" fullWidth validate={[required(), validarDosDecimales, minValue(0)]} formClassName={classes.grid_cont6} />
                      </SimpleFormIterator>
                    </ArrayInput>
                  )
                }
              </FormDataConsumer>
              <hr fullWidth style={{ marginBottom: '-2px', border:'2' }}></hr>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
     

    </Edit>
  )
}));
const WithProps = ({ children, ...props }) => children(props);