import React from 'react';
import {
    TabbedShowLayout, Tab, TextField, DateField, ReferenceField, NumberField, SelectField
} from 'react-admin';
import { Show } from './../Show';
import { BulkDeleteWithConfirmButton } from 'react-admin';
import { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import { styles } from '../../EstilosGrid';
import { withStyles } from '@material-ui/core';
import { translate } from 'react-admin';
import Divider from '@material-ui/core/Divider';

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props} />
    </Fragment>
);

/* const opciones = [
    { id: 0, name: 'No Definido' },
    { id: null, name: 'No Definido' },
    { id: 1, name: translate('dynatest.resources.atributos.select.acceso.acceso_completo') },
    { id: 2, name: 'Acceso Parcial' },
    { id: 3, name: 'Sin Acceso' },
    { id: 4, name: 'Visualiza' }
]; */

export const UsuarioShow = translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;
    const opciones = [
        { id: 0, name: translate('dynatest.resources.atributos.select.acceso.noDefinido') },
        { id: null, name: translate('dynatest.resources.atributos.select.acceso.noDefinido') },
        { id: 1, name: translate('dynatest.resources.atributos.select.acceso.acceso_completo') },
        { id: 2, name: translate('dynatest.resources.atributos.select.acceso.acceso_parcial') },
        { id: 3, name: translate('dynatest.resources.atributos.select.acceso.sin_acceso') },
        { id: 4, name: translate('dynatest.resources.atributos.select.acceso.visualiza')  }
    ];
    return (
    <Show title="dynatest.resources.usuarios.title.showTitle"  {...props} bulkActionButtons={<PostBulkActionButtons />}>
        <TabbedShowLayout>
            <Tab label="dynatest.resources.usuarios.tabs.informacionUsuario">
                <TextField source="nombres" />
                <TextField source="apellidos" />
                <div></div>
                {/* <TextField label="resources.usuarios.fields.tipo_documento" source="tipo_documento" /> */}
                <NumberField source="identificacion" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} />
                <ReferenceField source="admin" reference="atributos" linkType={false} >
                    <TextField source="adminNombre" />
                </ReferenceField>
                <div></div>
                <TextField label="dynatest.uniqueEmail.email" style={{ width: '300%' }} source="username" />
                <div></div><div></div><div></div>
                <Typography className=" Probando " variant="h3" component="h3" style={{ color: '#4d4d91', textAlign: 'center', marginBottom: '30px' }}>
                {translate('dynatest.resources.usuarios.tabs.acceso_usuario_app')}
                </Typography>
                <div></div>
                <div></div>
                <Typography variant="h3" component="h3" style={{ textAlign: 'center', marginBottom: '30px' }}>
                {translate('dynatest.resources.usuarios.tabs.administracion')}
                </Typography>
                <div></div>
                <Divider />
                <Divider />
                <Divider />
                <ReferenceField label="resources.usuarios.fields.sections.tipo_rol" source="admin" reference="atributos" linkType={false} >
                    <TextField source="tipoRol" />
                </ReferenceField>
                <ReferenceField label="resources.usuarios.fields.sections.usuarios" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="usuario" />
                </ReferenceField>
                <ReferenceField label="resources.usuarios.fields.sections.perfiles" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="perfiles" />
                </ReferenceField>
                {/* <ReferenceField label="resources.usuarios.fields.sections.asignar_clave" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="asigclaves" />
                </ReferenceField> */}
                <Typography variant="h3" component="h3" style={{ width: '300%' }}>
                {translate('dynatest.resources.usuarios.tabs.configGeneral')}
                </Typography>
                <div></div>
                <div></div>
                <Divider />
                <Divider />
                <Divider />
                <ReferenceField label="resources.usuarios.fields.sections.idioma" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="idioma" />
                </ReferenceField>
                <ReferenceField label="resources.usuarios.fields.sections.sistema" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="configSistema" />
                </ReferenceField>
                <div></div>

                <Typography variant="h3" component="h3" style={{ width: '300%' }}>
                {translate('dynatest.resources.usuarios.tabs.configReports')}
                </Typography>
                <div></div>
                <div></div>
                <Divider />
                <Divider />
                <Divider />
                <ReferenceField label="resources.atributos.fields.reportesConfig" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="reportesConfig" />
                </ReferenceField>
                <div></div>
                <div></div>

                <Typography variant="h3" component="h3" style={{ width: '300%' }}>
                {translate('dynatest.resources.usuarios.tabs.configAudit')}
                </Typography>
                <div></div>
                <div></div>
                <Divider />
                <Divider />
                <Divider />
                <ReferenceField label="resources.atributos.fields.audit" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="audit" />
                </ReferenceField>

                <div></div>
                <div></div>
                <div></div>
                <Typography variant="h3" component="h3" style={{ textAlign: 'center', marginBottom: '30px' }}>
                {translate('dynatest.resources.usuarios.tabs.proyectosMay')}
                </Typography>
                <div></div>

                <Typography variant="h3" component="h3" style={{ width: '300%' }}>
                {translate('dynatest.resources.usuarios.tabs.proyectosMin')}
                </Typography>
                <div></div>
                <div></div>
                <Divider />
                <Divider />
                <Divider />
                <ReferenceField label="resources.usuarios.fields.sections.proyectos" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="proyecto" />
                </ReferenceField>
                <ReferenceField label="resources.usuarios.fields.sections.rutas" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="rutas" />
                </ReferenceField>
                <ReferenceField label="resources.usuarios.fields.sections.unidades_funcionales" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="unifuncionales" />
                </ReferenceField>
                <ReferenceField label="resources.usuarios.fields.sections.tramos" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="tramos" />
                </ReferenceField>
                <ReferenceField label="resources.usuarios.fields.sections.segmentos" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="segmento" />
                </ReferenceField>
                <div></div>

                <Typography variant="h3" component="h3" style={{ width: '300%' }}>
                {translate('dynatest.resources.usuarios.tabs.confMateriales')}
                </Typography>
                <div></div><div></div>
                <Divider />
                <Divider />
                <Divider />
                <ReferenceField label="resources.usuarios.fields.sections.materiales" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="materiales" />
                </ReferenceField>
                <ReferenceField label="resources.usuarios.fields.sections.granulometria" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="ganulometria" />
                </ReferenceField>
                <div></div>

                <Typography variant="h3" component="h3" style={{ width: '300%' }}>
                {translate('dynatest.resources.usuarios.tabs.confIndicadores')}
                </Typography>
                <div></div><div></div>
                <Divider />
                <Divider />
                <Divider />
                <ReferenceField label="resources.usuarios.fields.sections.parametros_evaluacion" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="parametrosEvaluacion" />
                </ReferenceField>
                <ReferenceField label="resources.usuarios.fields.sections.diagrama_colorimetrico" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="diagramaColorimetrico" />
                </ReferenceField>
                <ReferenceField label="resources.usuarios.fields.sections.indicadorComplementario" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="indicadorComplementario" />
                </ReferenceField>

                <Typography variant="h3" component="h3" style={{ width: '300%' }}>
                {translate('dynatest.resources.usuarios.tabs.configNovedades')}
                </Typography>
                <div></div><div></div>
                <Divider />
                <Divider />
                <Divider />
                <ReferenceField label="resources.usuarios.fields.sections.novedades" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="registroNovedad" />
                </ReferenceField>
                <div></div><div></div>
                <Typography variant="h3" component="h3" style={{ width: '300%' }}>
                {translate('dynatest.resources.usuarios.tabs.confDeterioro')}
                </Typography>
                <div></div><div></div>
                <Divider />
                <Divider />
                <Divider />
                <ReferenceField label="resources.usuarios.fields.sections.reparaciones" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="reparaciones" />
                </ReferenceField>
                <ReferenceField label="resources.usuarios.fields.sections.deterioros" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="deterioros" />
                </ReferenceField>
                <div></div>

                <Typography variant="h3" component="h3" style={{ width: '300%' }}>
                {translate('dynatest.resources.usuarios.tabs.contactos')}
                </Typography>
                <div></div>
                <div></div>
                <Divider />
                <Divider />
                <Divider />
                <ReferenceField label="resources.usuarios.fields.sections.contacto_emergencia" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="contemergencia" />
                </ReferenceField>
                <ReferenceField label="resources.usuarios.fields.sections.proveedores" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="proveedores" />
                </ReferenceField>
                <ReferenceField label="resources.usuarios.fields.sections.servicios_publicos" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="serpublicos" />
                </ReferenceField>

                <div></div>
                <Typography variant="h3" component="h3" style={{ textAlign: 'center', marginTop: '20px', marginBottom: '30px' }}>
                {translate('dynatest.resources.usuarios.tabs.elem_inventario')}
                </Typography>
                <div></div>

                <ReferenceField label="resources.usuarios.fields.sections.mapa" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="mapa" />
                </ReferenceField>
                <div></div>
                <div></div>

                <Typography variant="h3" component="h3" style={{ width: '300%' }}>
                {translate('dynatest.resources.usuarios.tabs.cargaMasiva')}
                </Typography>
                <div></div>
                <div></div>
                <Divider />
                <Divider />
                <Divider />
                <ReferenceField label="resources.usuarios.fields.sections.cargaMasiva" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="cargaMasiva" />
                </ReferenceField>
                
                <div></div><div></div>
                <Typography variant="h3" component="h3" style={{ width: '300%' }}>
                {translate('dynatest.resources.usuarios.tabs.segmentos_cal')}
                </Typography>
                <div></div>
                <div></div>
                <Divider />
                <Divider />
                <Divider />
                <ReferenceField label="resources.usuarios.fields.sections.flexible" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="flexible" />
                </ReferenceField>
                <ReferenceField label="resources.usuarios.fields.sections.rigido" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="rigido" />
                </ReferenceField>
                <ReferenceField label="resources.usuarios.fields.sections.adoquin" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="adoquin" />
                </ReferenceField>
                <ReferenceField label="resources.usuarios.fields.sections.otro" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="otro" />
                </ReferenceField>
                <div></div>
                <div></div>

                <Typography variant="h3" component="h3" style={{ width: '300%' }}>
                {translate('dynatest.resources.usuarios.tabs.inventario')}
                </Typography>
                <div></div>
                <div></div>
                <Divider />
                <Divider />
                <Divider />

                <ReferenceField label="resources.usuarios.fields.sections.sistemas_contencion" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="seguridad" />
                </ReferenceField>
                <ReferenceField label="resources.usuarios.fields.sections.drenaje" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="drenaje" />
                </ReferenceField>
                <ReferenceField label="resources.usuarios.fields.sections.señalizacion" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="senalizacion" />
                </ReferenceField>
                <ReferenceField label="resources.usuarios.fields.sections.alrededores" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="alrededores" />
                </ReferenceField>
                <ReferenceField label="resources.usuarios.fields.sections.otros_elementos" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="otroElemento" />
                </ReferenceField>
                <ReferenceField label="resources.usuarios.fields.sections.redes" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="redes" />
                </ReferenceField>
                <br /><br /><br />

                <Typography variant="h3" component="h3" style={{ width: '300%' }}>
                {translate('dynatest.resources.usuarios.tabs.puentes_estructuras')}
                </Typography>
                <div></div><div></div>
                <Divider style={{ width: '300%' }} />
                <div></div><div></div>

                <ReferenceField label="resources.usuarios.fields.sections.puentes" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="puentes" />
                </ReferenceField>
                <ReferenceField label="resources.usuarios.fields.sections.contencion" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="contencion" />
                </ReferenceField>

                <ReferenceField label="resources.usuarios.fields.sections.ponton" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="ponton" />
                </ReferenceField>
                <ReferenceField label="resources.usuarios.fields.sections.tuneles" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="tunel" />
                </ReferenceField>
                <br /><br /><br />


                <Typography variant="h3" component="h3" style={{ textAlign: 'center', marginTop: '20px', marginBottom: '30px' }}>
                {translate('dynatest.resources.usuarios.tabs.transito')}
                </Typography>
                <div></div>

                <ReferenceField label="resources.usuarios.fields.sections.inventario_peajes" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="inventario" />
                </ReferenceField>
                <ReferenceField label="resources.usuarios.fields.sections.transito_historico" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="transitoMensual" />
                </ReferenceField>
                <ReferenceField label="resources.usuarios.fields.sections.inv_est_conteo" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="inventarioa" />
                </ReferenceField>

                <div></div>
                <Typography variant="h3" component="h3" style={{ textAlign: 'center', marginTop: '20px', marginBottom: '30px' }}>
                {translate('dynatest.resources.usuarios.tabs.indicadoresMay')}
                </Typography>
                <div></div>

                <Typography variant="h3" component="h3" style={{ width: '300%' }}>
                {translate('dynatest.resources.usuarios.tabs.indicadoresMin')}
                </Typography>
                <div></div>
                <div></div>
                <Divider />
                <Divider />
                <Divider />
                <ReferenceField label="resources.usuarios.fields.sections.indicadores_4g" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="indicadores4g" />
                </ReferenceField>
                <ReferenceField label="resources.usuarios.fields.sections.datos_basicos" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="datosBasicos" />
                </ReferenceField>
                <ReferenceField label="resources.usuarios.fields.sections.deterioro" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="colaDePeaje" />
                </ReferenceField>
                <ReferenceField label="resources.usuarios.fields.sections.calificacion_puentes" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="calificacionPuentes" />
                </ReferenceField>
                <ReferenceField label="resources.usuarios.fields.sections.calificacion_pontones"source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="calificacionPontones" />
                </ReferenceField>
                <ReferenceField label="resources.usuarios.fields.sections.complementarios"source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="respuestaEvaluacionComplementario" />
                </ReferenceField>
                <ReferenceField label="resources.usuarios.fields.sections.disp_vias" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="disponibilidadVia" />
                </ReferenceField>
                <ReferenceField label="resources.usuarios.fields.sections.cola_peaje" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="colaDePeajea" />
                </ReferenceField>

                <div></div>
                <div></div>
                <Typography variant="h3" component="h3" style={{ textAlign: 'center', marginTop: '20px', marginBottom: '30px' }}>
                {translate('dynatest.resources.usuarios.tabs.gestion')}   
                </Typography>
                <div></div>

                <Typography variant="h3" component="h3" style={{ width: '300%' }}>
                {translate('dynatest.resources.usuarios.tabs.programacion')} 
                </Typography>
                <div></div>
                <div></div>
                <Divider /><Divider /><Divider />
                <ReferenceField label="resources.usuarios.fields.sections.parametros_planeacion" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="parametrosAnalisis" />
                </ReferenceField>
                <div></div>
                <div></div>

                <Typography variant="h3" component="h3" style={{ width: '300%' }}>
                {translate('dynatest.resources.usuarios.tabs.otros_sistemas')}
                </Typography>
                <div></div>
                <div></div>
                <Divider /><Divider /><Divider />
                <ReferenceField label="resources.usuarios.fields.sections.politica_general" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="politicaGeneral" />
                </ReferenceField>
                <ReferenceField label="resources.usuarios.fields.sections.politica_particular" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="politicaParticular" />
                </ReferenceField>
                <div></div>

                <Typography variant="h3" component="h3" style={{ width: '300%' }}>
                {translate('dynatest.resources.usuarios.tabs.pavimento_pers')} 
                </Typography>
                <div></div>
                <div></div>
                <Divider /><Divider /><Divider />
                <ReferenceField label="resources.usuarios.fields.sections.alternativa_intervencion" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="alternativaIntervencion" />
                </ReferenceField>
                <ReferenceField label="resources.usuarios.fields.sections.repa_menores" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="reparacionesMenores" />
                </ReferenceField>
                <ReferenceField label="resources.usuarios.fields.sections.exportar_pers" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="exportarPers" />
                </ReferenceField>
                <ReferenceField label="resources.usuarios.fields.sections.reportes_pavimentos" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="reportesPers" />
                </ReferenceField>
                <div></div>
                <div></div>

                <Typography variant="h3" component="h3" style={{ width: '300%' }}>
                {translate('dynatest.resources.usuarios.tabs.registroCostos')}
                </Typography>
                <div></div>
                <div></div>
                <Divider /><Divider /><Divider />
                <ReferenceField label="resources.usuarios.fields.sections.costo" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="costo" />
                </ReferenceField>

                <div></div>
                <div></div>
                <Typography variant="h3" component="h3" style={{ width: '300%' }}>
                {translate('dynatest.resources.usuarios.tabs.flujo_cajaTitle')}
                </Typography>
                <div></div>
                <div></div>
                <Divider /><Divider /><Divider />
                <ReferenceField label="resources.usuarios.fields.sections.flujo_caja" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="flujoCaja" />
                </ReferenceField>
                <div></div>
                <div></div>

                <div></div>
                <Typography variant="h3" component="h3" style={{ textAlign: 'center', marginTop: '20px', marginBottom: '30px' }}>
                {translate('dynatest.resources.usuarios.tabs.aplicacionMovil')}   
                </Typography>
                <div></div>

                <ReferenceField label="resources.usuarios.fields.sections.respuestaEvaluacionNovedad" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="respuestaEvaluacionNovedad" />
                </ReferenceField>
                <ReferenceField label="resources.usuarios.fields.sections.aprobacion" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="aprobacion" />
                </ReferenceField>

                <div></div>
                <div></div>
                
                <Typography variant="h3" component="h3" style={{ textAlign: 'center', marginTop: '20px', marginBottom: '30px' }}>
                {translate('dynatest.resources.atributos.formTabs.reportesQS')}
                </Typography>
                <div></div>
                
                <ReferenceField label="resources.atributos.fields.reportesPersQ" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="reportesPersQ" />
                </ReferenceField>
                <ReferenceField label="resources.atributos.fields.reportesInventarioQ" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="reportesInventarioQ" />
                </ReferenceField>
                <ReferenceField label="resources.atributos.fields.reportesIndicadoresQ" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="reportesIndicadoresQ" />
                </ReferenceField>
                <ReferenceField label="resources.atributos.fields.reportesCostosQ" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="reportesCostosQ" />
                </ReferenceField>
                <ReferenceField label="resources.atributos.fields.reportesTransitoQ" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="reportesTransitoQ" />
                </ReferenceField>
                <ReferenceField label="resources.atributos.fields.reportesContactosQ" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="reportesContactosQ" />
                </ReferenceField>
                <ReferenceField label="resources.atributos.fields.reportesModelacionQ" source="admin" reference="atributos" linkType={false} >
                    <SelectField choices={opciones} source="reportesModelacionQ" />
                </ReferenceField>

            </Tab>
            <Tab label="dynatest.resources.usuarios.tabs.auditoria">
                <DateField source="createdAt" showTime/>
                <TextField source="createdBy" />
                <div></div>
                <DateField source="updatedAt" showTime/>
                <TextField source="updatedBy" />
                <div></div>
            </Tab>
        </TabbedShowLayout>
    </Show>
    )
}));