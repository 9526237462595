import React, { useState } from 'react';
import {
    Toolbar, CardActions, NumberField, ShowController, ShowView, TextField, DateField, ReferenceField, BooleanField,
    TabbedForm, ListButton, EditButton, TextInput, FormTab, FunctionField, SelectField
} from 'react-admin';
import { GMapField } from '../../fields/GMapField';
import { PRField } from './../../fields/PRField';
import { PField } from './../../fields/PField';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { styles } from './../../EstilosGrid';
import { withStyles } from '@material-ui/core';
import { ButtonCarousel } from './../images/ButtonCarousel';
import { translate } from 'react-admin';
const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};


const PostShowActions = ({ basePath, data }) => (
    <CardActions style={cardActionStyle}>
        <EditButton basePath={basePath} record={data} />
        {/* Add your custom actions */}
        <ListButton basePath={basePath} />
    </CardActions>
);

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <span></span>

    </Toolbar>
));

const defensachoices =
    [
        { id: '1', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniAbatidaEsviadaTerFinAbatida' },
        { id: '2', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniAbatidaEsviadaTerFinAbatidaEsviada' },
        { id: '3', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniAbatEsviadaTermFinEnterradaTalud' },
        { id: '4', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniAbatEsviadaTermFinColaPez' },
        { id: '5', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniAbatTerminalFinAbatida' },
        { id: '6', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniAbatTerminalFinAbatidaEsviada' },
        { id: '7', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniAbatTerminalFinColaPez' },
        { id: '17', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniAbatTerminalFinSalidaAerea' },
        { id: '8', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniAbatTerminalFinEnterradaTalud' },
        { id: '18', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniAbatTerminalFinIncrustadaObraArte' },
        { id: '10', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniColaPezTermiFinAbatidaEsviada' },
        { id: '9', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniColaPezTermiFinAbatida' },
        { id: '11', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniColaPezTermiFinColaPez' },
        { id: '12', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniColaPezTermiFinEnterradaTalud' },
        { id: '19', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniAmortiguadorImpacTermFinAbatida' },
        { id: '21', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniAmortiguadorImpacTermFinColaPez' },
        { id: '22', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniAmortiguadorImpacTermFinSalAerea' },
        { id: '20', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniAmortiguadorImpacTermFinEnterradaTalud' },
        { id: '23', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniAmortiguadorImpacTermFinIncrusObraArte' },
        { id: '14', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniEnterrTaludTermFinAbatEsviada' },
        { id: '13', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniEnterrTaludTermFinAbatida' },
        { id: '15', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniEnterrTaludTermFinColaPez' },
        { id: '16', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniEnterrTaludTermFinEnterrTalud' },
        { id: '24', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniEsviadaTermFinAbatida' },
        { id: '25', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniEsviadaTermFinSalidaAerea' },
        { id: '26', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniEsviadaTermFinEnterradaTalud' },
        { id: '27', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniEsviadaTermFinIncrustadaObraArte' },
        { id: '28', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniIncrustObraArteTermFinalAbatida' },
        { id: '29', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniIncrustObraArteTermFinalSalidaAerea' },
        { id: '30', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniIncrustObraArteTermFinalEnterradaTalud' },
        { id: '31', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniIncrustObraArteTermFinalEsviada' }, 
    ]

export const SeguridadShow = translate(withStyles(styles)(({ classes, record, ...props }) => {
    const { translate } = props;

    const [imagen, setImagen] = useState(true);
    const ValidarImagen = (value) => {
        if (value !== null && value !== undefined && value !== 'undefined') {
            let lenghtObject = Object.keys(value);
            let valor = '';
            for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
            setImagen(value)
            // console.log(value + "Aqui est la imagen ")
        }
    }
    return (
        <ShowController {...props} >
            {controllerProps =>
                <ShowView {...props} {...controllerProps} title="dynatest.resources.seguridads.title.showTitle" actions={<ButtonCarousel />}>
                    <TabbedForm toolbar={false}>
                        <FormTab label="dynatest.resources.seguridads.tabs.tabsTitle.identificacion">
                            <TextField label="resources.seguridads.fields.smartCode" source="smartCode" fullWidth formClassName={classes.grid_cont12} />
                            <ReferenceField label="resources.seguridads.fields.tipoElemento" source="tipoElemento" reference="tipo_elementos" linkType={false} fullWidth formClassName={classes.grid_cont4s} >
                                <TextField source="nombre" />
                            </ReferenceField>
                            <TextField source="margen" label="resources.seguridads.fields.margen" fullWidth formClassName={classes.grid_cont4s} />
                            <SelectField label="resources.seguridads.fields.sentido" source='sentido' choices={[
                                { id: translate('dynatest.resources.seguridads.select.sentido.norteId'), name: 'dynatest.resources.seguridads.select.sentido.norteName' },
                                { id: translate('dynatest.resources.seguridads.select.sentido.surId'), name: 'dynatest.resources.seguridads.select.sentido.surName' },
                                { id: translate('dynatest.resources.seguridads.select.sentido.esteId'), name: 'dynatest.resources.seguridads.select.sentido.esteName' },
                                { id: translate('dynatest.resources.seguridads.select.sentido.oesteId'), name: 'dynatest.resources.seguridads.select.sentido.oesteName' }
                            ]}
                            fullWidth
                            formClassName={classes.grid_cont4_f}/>
                            <TextInput source="tipoElemento_.src" validate={ValidarImagen} style={{ display: 'none' }} />
                            <span></span>
                            {/* ------- LOCALIZACIÓN --------- */}
                            <Typography variant="b" component="b">{translate('dynatest.resources.seguridads.subTitle.localizacion')}</Typography>
                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <ReferenceField label="resources.seguridads.fields.unidadFuncional" source="unidadFuncional" reference="unidad_funcionals" linkType={false} fullWidth formClassName={classes.grid_cont4s}>
                                <TextField source="fullCodigos" />
                            </ReferenceField>
                            <ReferenceField label="resources.seguridads.fields.tramo" source="tramo" reference="tramos" linkType={false} fullWidth formClassName={classes.grid_cont4s}>
                                <TextField source="fullCodigos" />
                            </ReferenceField>
                            <ReferenceField label="resources.seguridads.fields.segmento" source="segmento" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont4s}>
                                <TextField source="fullCodigos" />
                            </ReferenceField>
                            {/* <PField label="PR Inicio" source="pInicio" />
                        <PField label="PR Fin"  source="pFin" /> */}
                            <PRField label="resources.seguridads.fields.abInicio" source="abInicio" fullWidth formClassName={classes.grid_cont4s} />
                            <PRField label="resources.seguridads.fields.abFin" source="abFin" fullWidth formClassName={classes.grid_cont4s} />
                            <NumberField label="resources.seguridads.fields.fullResta" source="fullResta" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4s} />
                            <PField label="resources.seguridads.fields.pInicio" source="pInicio" fullWidth formClassName={classes.grid_cont4s} />
                            <PField label="resources.seguridads.fields.pFin" source="pFin" fullWidth formClassName={classes.grid_cont4s} />
                            <Typography variant="b" component="b">{translate('dynatest.resources.seguridads.subTitle.coordenadasIniciales')}</Typography>
                            <Divider fullWidth style={{ width: '300%', marginTop: '10px' }} />
                            <NumberField label="resources.seguridads.fields.latitudInicial" source="latitudInicial" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4s} />
                            <NumberField label="resources.seguridads.fields.longitudInicial" source="longitudInicial" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4s} />
                            <span></span>
                            <Typography variant="b" component="b">{translate('dynatest.resources.seguridads.subTitle.coordenadasFinales')}</Typography>
                            <Divider fullWidth style={{ width: '300%', marginTop: '10px' }} />
                            <NumberField label="resources.seguridads.fields.latitudFinal" source="latitudFinal" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4s} />
                            <NumberField label="resources.seguridads.fields.longitudFinal" source="longitudFinal" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4s} />
                            <span></span>
                            <GMapField style={{ width: "100%", }}
                                defaultZoom={12}
                                defaultCenter={{ lat: 4.7567039, lng: -74.0311056 }}
                                source="ubicaciones1"
                                multipleMarkers="true"
                                googleKey="AIzaSyCivTXzJBYRBK8nosBYRFXnv-hGXIbhnYY"
                                icon={imagen}
                            />
                        </FormTab>
                        <FormTab label="dynatest.resources.seguridads.tabs.tabsTitle.caracteristicas">
                            {/* PRTP PERFILES DE PUENTES */}
                            {/** MATERIAL */}
                            <TextField label="resources.seguridads.fields.material" source="material" fullWidth formClassName={classes.grid_cont4s} />


                            {/** Tipo de perfil */}
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/DEFMT" &&
                                <TextField fullWidth label="resources.seguridads.fields.perfil" source="perfil" formClassName={classes.grid_cont4s} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/PRTP" &&
                                <TextField fullWidth label="resources.seguridads.fields.perfil" source="perfil" formClassName={classes.grid_cont4s} />
                            }


                            {/** Tipo de defensa */}
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/DEFMT" &&
                                // <SelectField fullWidth label="Tipo de defensa" source="defensa" cohices={defensachoices} formClassName={classes.grid_cont4s}/>                  
                                <SelectField label="resources.seguridads.fields.defensa" source="defensa" choices={defensachoices} formClassName={classes.grid_cont4s} fullWidth />
                            }


                            {/** Tipo de tope */}
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/TPVH" &&
                                <TextField fullWidth label="resources.seguridads.fields.tope" source="tope" formClassName={classes.grid_cont4s} />
                            }


                            {/** seccion */}
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/TPVH" &&
                                <TextField fullWidth label="resources.seguridads.fields.seccion" source="seccion" formClassName={classes.grid_cont4s} />
                            }


                            {/** Salto de linea para tope vehicular */}
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/TPVH" && <br />}


                            {/** Cantidad de Elementos */}
                            {controllerProps.record && controllerProps.record.tipoElemento !== "/tipo_elementos/DEFMT"
                                && controllerProps.record.tipoElemento !== "/tipo_elementos/PRTP"
                                && controllerProps.record.tipoElemento !== "/tipo_elementos/CAPT"
                                && controllerProps.record.tipoElemento !== "/tipo_elementos/TPVH" &&
                                <NumberField label="resources.seguridads.fields.cant_elem" source="cant_elem" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4s} />
                            }


                            {/** Salto de linea para pretiles de puente */}
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/PRTP" && <br />}


                            {/** Numero de Postes */}
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/DEFMT" &&
                                <NumberField label="resources.seguridads.fields.postes" source="postes" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4s} />
                            }
                            {/** Numero de Captafaros */}
                            {controllerProps.record && controllerProps.record.tipoElemento !== "/tipo_elementos/TPVH" &&
                                controllerProps.record.tipoElemento !== "/tipo_elementos/CERC" &&
                                controllerProps.record.tipoElemento !== "/tipo_elementos/BORD" &&
                                <NumberField label="resources.seguridads.fields.captafaros" source="captafaro" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4s} />
                            }
                            {/** salto de linea para captafaros */}
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/CAPT" && <br />}


                            {/** largo (demas elementos) */}
                            {controllerProps.record && controllerProps.record.tipoElemento !== "/tipo_elementos/TPVH"
                                && controllerProps.record.tipoElemento !== "/tipo_elementos/DEFMT"
                                && controllerProps.record.tipoElemento !== "/tipo_elementos/PRTP" &&
                                <NumberField label="resources.seguridads.fields.cant_largo" source="cant_largo" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4s} />
                            }
                            {/** Ancho (mm) */}
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/PRTP" &&
                                <NumberField label="resources.seguridads.fields.ca_largo" source="ca_largo" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4s} />
                            }
                            {/** Ancho (mm) */}
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/PRTP" &&
                                <NumberField label="resources.seguridads.fields.ca_ancho" source="ca_ancho" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4s} />
                            }
                            {/** Altura (mm) */}
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/PRTP" &&
                                <NumberField label="resources.seguridads.fields.ca_altura" source="ca_altura" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4s} />
                            }

                            {/** largo (defensa) */}
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/DEFMT" &&
                                <NumberField label="resources.seguridads.fields.largo" source="largo" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4s} />
                            }
                            {/** Calibre (mm) */}
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/PRTP" &&
                                <NumberField label="resources.seguridads.fields.calibre_mm" source="calibre_mm" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4s} />
                            }
                            {/** Ancho */}
                            {controllerProps.record && controllerProps.record.tipoElemento !== "/tipo_elementos/DEFMT"
                                && controllerProps.record.tipoElemento !== "/tipo_elementos/PRTP"
                                && controllerProps.record.tipoElemento !== "/tipo_elementos/TPVH" &&
                                <NumberField label="resources.seguridads.fields.cant_ancho" source="cant_ancho" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4s} />
                            }


                            {/** diametro base */}
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/TPVH" &&
                                <NumberField label="resources.seguridads.fields.diametroShow" source="diametro" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4s} />
                            }


                            {/** Altura */}
                            {controllerProps.record && controllerProps.record.tipoElemento !== "/tipo_elementos/DEFMT"
                                && controllerProps.record.tipoElemento !== "/tipo_elementos/PRTP" &&
                                <NumberField label="resources.seguridads.fields.altura" source="altura" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4s} />
                            }


                            {/** Salto de linea para tope vehicular */}
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/TPVH" && <br />}


                            {/** Cantidad de topes*/}
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/TPVH" &&
                                <NumberField label="resources.seguridads.fields.topes" source="topes" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4s} />
                            }


                            {/** Longitud de Tramo */}
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/TPVH" &&
                                <NumberField label="resources.seguridads.fields.tramos" source="tramos" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4s} />
                            }


                            {/**Numero de Unidades */}
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/DEFMT" &&
                                <NumberField label="resources.seguridads.fields.unidades" source="unidades" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4s} />
                            }


                            {/**Numero de Terminales */}
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/DEFMT" &&
                                <NumberField label="resources.seguridads.fields.terminales" source="terminales" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4s} />
                            }

                            {/**Numero de Tornillos */}
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/DEFMT" &&
                                <NumberField label="resources.seguridads.fields.tornillos" source="tornillos" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4s} />
                            }

                            {/**Altura Defensas Metalicas*/}
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/DEFMT" &&
                                <NumberField label="resources.seguridads.fields.altura" source="altura" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4s} />
                            }

                            {/**Numero de Tornillos */}
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/DEFMT" &&
                                <NumberField label="resources.seguridads.fields.posteMetalico" source="posteMetalico" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4s} />
                            }


                        </FormTab>
                        <FormTab label="dynatest.resources.seguridads.tabs.tabsTitle.detalles">
                            <Divider />
                            <TextField fullWidth label="resources.seguridads.fields.condicionElemento" source="condicionElemento" formClassName={classes.grid_cont4s} />

                            {controllerProps.record && controllerProps.record.condicionElemento === translate('dynatest.resources.seguridads.select.condicionElemento.reemplazo') &&
                                <TextField fullWidth label="resources.seguridads.fields.motivoReemplazo" source="motivoReemplazo" formClassName={classes.grid_cont4s} />
                            }

                            {controllerProps.record && controllerProps.record.condicionElemento === translate('dynatest.resources.seguridads.select.condicionElemento.reemplazo') &&
                                <ReferenceField
                                    label="resources.seguridads.fields.elementoReemplazo_id"
                                    source="elementoReemplazo_id"
                                    reference="seguridads"
                                    linkType={false}
                                    fullWidth
                                    formClassName={classes.grid_cont4s}
                                >
                                    <TextField source="smartCode" />
                                </ReferenceField>
                                // <FunctionField 
                                //     label="Elemento de Reemplazo"
                                //     source="elementoReemplazo_id"
                                //     render={
                                //         record => `
                                //                     ${record.unidadFuncional_.fullCodigos}::SEG
                                //                     ${record.tramo_.fullCodigos}::
                                //                     ${record.segmento_.fullCodigos}::
                                //                     ${record.tipoElemento_.id.replace("/tipo_elementos/", "")}::
                                //                     ${record.elementoReemplazo_id}
                                //                     `
                                //     }
                                //     formClassName={classes.grid_cont4s}
                                //     />
                            }
                            <br />
                            
                            <SelectField label="resources.seguridads.fields.estado" source="estado" choices={[
                                { id: translate('dynatest.resources.seguridads.select.estado.activo'), name: 'dynatest.resources.seguridads.select.estado.activo' },
                                { id: translate('dynatest.resources.seguridads.select.estado.inactivo'), name: 'dynatest.resources.seguridads.select.estado.inactivo' },
                            ]} fullWidth
                                formClassName={classes.grid_cont4s}
                            />
                            <br />
                            <DateField label="resources.seguridads.fields.fechaInstalacion" source="fechaInstalacion" formClassName={classes.grid_cont4s} />

                            <TextField fullWidth label="resources.seguridads.fields.proveedor" source="proveedor_.razonSocial" formClassName={classes.grid_cont4} />

                            <NumberField label="resources.seguridads.fields.garantia" source="garantia" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4} />

                            {/* <TextField fullWidth source="elementos"/> */}
                            <br />
                            {/* <BooleanField label="Status" source="status" fullWidth formClassName={classes.grid_cont4s} /> */}
                            <BooleanField label="dynatest.resources.seguridads.boolean.mantenimiento" source="mantenimiento" fullWidth formClassName={classes.grid_cont6} />
                            <TextField source="observaciones" label="resources.seguridads.fields.observaciones" fullWidth formClassName={classes.grid_cont12} />
                            <span></span>
                        </FormTab>
                        <FormTab label="dynatest.resources.seguridads.tabs.tabsTitle.auditoria">
                            <DateField source="createdAt" label="resources.seguridads.fields.createdAt" formClassName={classes.grid_cont4s} showTime/>
                            <TextField source="createdBy" label="resources.seguridads.fields.createdBy" formClassName={classes.grid_cont4s} />
                            <TextField source="createdIn" label="resources.seguridads.fields.createdEn" fullWidth formClassName={classes.grid_cont3} />
                            <span></span>
                            <DateField source="updatedAt" label="resources.seguridads.fields.updatedAt" formClassName={classes.grid_cont4s} showTime/>
                            <TextField source="updatedBy" label="resources.seguridads.fields.updatedBy" formClassName={classes.grid_cont4s} />
                            <TextField source="updatedIn" label="resources.seguridads.fields.updatedEn" fullWidth formClassName={classes.grid_cont3} />
                            <span></span>
                        </FormTab>
                    </TabbedForm>
                </ShowView>
            }
        </ShowController>
    )
}));