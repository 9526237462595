import React from 'react';
import { BooleanInput, DateInput, TabbedForm, FormTab, NumberInput, ReferenceInput, SelectInput } from 'react-admin';
import { Edit } from './../../Edit';
import { FInput } from './../../../inputs/FInput';
import Grid from '@material-ui/core/Grid';

import {
  required,
  minLength,
  maxLength,
  minValue,
  maxValue
} from 'react-admin';

import {
  Toolbar,
  SaveButton,

} from 'react-admin';
import { withStyles } from '@material-ui/core';

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const CustomToolbar = withStyles(toolbarStyles)(props => (
  <Toolbar {...props}>
    <SaveButton />

  </Toolbar>
));

export const phora = () => {

  /*VALORES HORA INICIO*/
  let PrInicioBase = "0:00";
  let ingresoPrInicio = document.getElementById('ingresohoraI').value;
  let sePrInicioBase = ingresoPrInicio.split(":");
  let PrFinBase = "24:59";
  let sePrFinBase = PrFinBase.split(":");



  /*VALIDACIONES PR INICIO*/
  if (parseInt(sePrInicioBase[0]) < parseInt(sePrFinBase[0]) || parseInt(sePrInicioBase[0]) === parseInt(sePrFinBase[0])) {

    if (parseInt(sePrInicioBase[1]) < parseInt(sePrFinBase[1]) || parseInt(sePrInicioBase[1]) === parseInt(sePrFinBase[1])) {
      console.log(sePrInicioBase[0] + ":" + sePrInicioBase[1]);
    } else {
      return "Error el hora debe estar entre " + PrInicioBase + " y " + PrFinBase;
    }

  } else {
    return "Error el hora debe estar entre " + PrInicioBase + " y " + PrFinBase;
  }



}

export const pfin = () => {

  /*VALORES HORA INICIO*/
  let PrInicioBase = "0:00";
  let ingresoPrInicio = document.getElementById('ingresofin').value;
  let sePrInicioBase = ingresoPrInicio.split(":");
  let PrFinBase = "24:59";
  let sePrFinBase = PrFinBase.split(":");



  /*VALIDACIONES PR INICIO*/
  if (parseInt(sePrInicioBase[0]) < parseInt(sePrFinBase[0]) || parseInt(sePrInicioBase[0]) === parseInt(sePrFinBase[0])) {

    if (parseInt(sePrInicioBase[1]) < parseInt(sePrFinBase[1]) || parseInt(sePrInicioBase[1]) === parseInt(sePrFinBase[1])) {
      console.log(sePrInicioBase[0] + ":" + sePrInicioBase[1]);
    } else {
      return "Error el hora debe estar entre " + PrInicioBase + " y " + PrFinBase;
    }

  } else {
    return "Error el hora debe estar entre " + PrInicioBase + " y " + PrFinBase;
  }



}


export const PeajescolajeEdit = props => (
  <Edit title="Cola de Peaje" {...props} redirect="list" toolbar={<CustomToolbar />}>
    <TabbedForm>
      <FormTab label="detalles">
        <Grid container spacing={24}>
          <Grid item xs={4}>
            <DateInput label="Fecha medición" source="date" />
          </Grid>
          <Grid item xs={4}>
            <NumberInput label="Consecutivo de Campaña" source="consecutivo" fullWidth validate={[required(), minLength(1), maxLength(11), minValue(1), maxValue(99999999999)]} />
          </Grid>
          <Grid item xs={4}>
            <span></span>
          </Grid>
          <Grid item xs={4}>
            <ReferenceInput label="Peaje" source="inventario_peajes" reference="inventariopeajes" validate={required()} fullWidth>
              <SelectInput optionText="nombre" />
            </ReferenceInput>
          </Grid>
          <Grid item xs={4}>
            <SelectInput label="Sentido" source="sentido" choices={[
              { id: 'ascendente', name: 'Ascendente' },
              { id: 'descendente', name: 'Descendente' }
            ]} fullWidth />
          </Grid>
          <Grid item xs={4}>
            <span></span>
          </Grid>
          <Grid item xs={4}>
            <FInput label="Hora Inicio" source="hora_inicio" id="ingresohoraI" fullWidth validate={[phora, required()]} />
          </Grid>
          <Grid item xs={4}>
            <FInput label="Hora Final" source="hora_final" id="ingresofin" fullWidth validate={[pfin, required()]} />
          </Grid>
          <Grid item xs={4}>
            <span></span>
          </Grid>
          <Grid item xs={4}>
            <NumberInput label="N° Estaciones Activas" source="estacion_activa" fullWidth validate={[required(), minLength(1), maxLength(11), minValue(0), maxValue(99999999999)]} />
          </Grid>
          <Grid item xs={4}>
            <NumberInput label="N° de Vehículos por Carril" source="numero_carril" fullWidth validate={[required(), minLength(1), maxLength(11), minValue(0), maxValue(99999999999)]} />
          </Grid>
          <Grid item xs={4}>
            <span></span>
          </Grid>
          <Grid item xs={4}>
            <BooleanInput label="Cumple Indicador O3" source="indicador" fullWidth />
          </Grid>
        </Grid>
      </FormTab>
    </TabbedForm>
  </Edit>
);