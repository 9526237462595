import React, { useState } from 'react';
import {
    NumberInput, TextInput, BooleanInput, Query, AutocompleteArrayInput, AutocompleteInput, number,FormDataConsumer, email
} from 'react-admin';
import { ReferenceInput, SelectInput, minValue } from 'react-admin';
import { FormTab } from 'react-admin';
import { PimsCreate } from './../Create';
import { required, minLength, maxLength, } from 'react-admin';
import { ReferenceArrayInput, SelectArrayInput, } from 'react-admin'
import { TabbedForm } from './../TabbedForm';
import Grid from '@material-ui/core/Grid';
import { translate } from 'react-admin';
import { withStyles } from '@material-ui/core';
import { styles } from './../../EstilosGrid';

export const ProveedorCreate = translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;

    const [departamentoss, setDepartamento] = useState('');
    const [mantenimiento, setMantenimiento] = useState(false);
    const [suministros, setSuministros] = useState(false);

    const ValidarDepartamento = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setDepartamento(valor)
    }

    /*VALOR PARA ORDENAMIETO DE SISTEMA*/

    const [sistemaNombre, setSistemaNombre] = useState('');
    const SistemaNombre = (value) => {
        setSistemaNombre(value)
    }

    const validaMantenimiento = (value) => {
        var mante = document.getElementById("mantenimiento").checked;
        var sumin = document.getElementById("suministros").checked;
        if(mante == false && sumin == false){
           return translate('resources.validaciones.mantenimientoOSuministro');
        }        
    }

    const validaSuministro = (value) => {
        var mante = document.getElementById("mantenimiento").checked;
        var sumin = document.getElementById("suministros").checked;
        if(mante == false && sumin == false){
           return translate('resources.validaciones.mantenimientoOSuministro');
        }
    }
    
    return (
        <PimsCreate {...props} >
            <TabbedForm submitOnEnter={false} redirect="list"  >
                <FormTab label="dynatest.resources.proveedors.tabs.proyecto"  >
                    <Grid container spacing={24}>
                        <Grid item xs={4}>
                            <ReferenceInput label="resources.proveedors.fields.proyecto" source="proyecto" reference="proyectos" validate={[required()]} defaultValue={window.localStorage.getItem('id_project2')}>
                                <SelectInput optionText="nombre" disabled fullWidth />
                            </ReferenceInput>
                        </Grid>
                        <Grid item xs={4}>
                            <BooleanInput validate={[validaMantenimiento]} label="dynatest.resources.proveedors.boolean.mantenimiento" source="mantenimiento" id="mantenimiento" fullWidth/>
                        </Grid>
                        <Grid item xs={4}>
                            <BooleanInput validate={[validaSuministro]} label="dynatest.resources.proveedors.boolean.suministros" source="suministros" id="suministros" fullWidth />
                        </Grid>
                        <FormDataConsumer formClassName={classes.ocultar}>
                            {({ formData, dispatch, ...rest }) => {
                                if (typeof formData != 'undefined') {
                                /*if(formData.mantenimiento == "No cumple" || cumplimientoChecks == "No cumple" || cumplimientoLugarDet == "No cumple"){
                                    formData.cumplimiento = "No cumple"
                                }else{
                                    formData.cumplimiento = "Cumple"
                                }*/
                                console.log("mantenimiento = " + mantenimiento)
                                console.log("formData.mantenimiento = " + formData.mantenimiento)
                                //console.log("form cumplimiento = " + formData.cumplimiento)
                                //console.log("cumplimientoPorcentaje = " +cumplimientoPorcentaje+ " ;; cumplimientoChecks = " + cumplimientoChecks + " ;; cumplimientoLugarDet = " + cumplimientoLugarDet)
                                }
                            }}
                        </FormDataConsumer>
                        <Grid item xs={12}>
                            <ReferenceInput
                                label="resources.proveedors.fields.sistema"
                                source="sistema"
                                reference="sistemas"
                                sort={{ field: "nombre", order: "ASC" }}
                                validate={[required(), SistemaNombre]}
                                filter={{ visibleOnPolicies: 1 }} 
                            >
                                <SelectInput optionText="nombre" fullWidth />
                            </ReferenceInput>
                        </Grid>
                        {sistemaNombre &&
                            <Query type="GET_ONE" resource="sistemas" payload={{ id: sistemaNombre }} >
                                {({ data, loading, error }) => {
                                    if (data) {
                                        return <TextInput
                                            style={{ display: 'none' }}
                                            label="resources.proveedors.fields.nombreSistema"
                                            defaultValue={data.nombre}
                                            source="nombreSistema"
                                            fullWidth validate={[required()]}
                                        />;
                                    }
                                    return <div></div>;
                                }}
                            </Query>
                        }
                        <Grid item xs={3}>
                            <SelectInput label="resources.proveedors.fields.identificacion" source="identificacion" allowEmpty emptyValue="" choices={[
                                { id: 'CC', name: 'dynatest.resources.proveedors.select.identificacion.CC' },
                                { id: 'NIT', name: 'dynatest.resources.proveedors.select.identificacion.NIT' },
                                { id: 'Otro', name: 'dynatest.resources.proveedors.select.identificacion.Otro' },

                            ]} fullWidth validate={[required()]} />
                        </Grid>
                        <Grid item xs={4}>
                            <TextInput label="resources.proveedors.fields.numeroIdentificacion" source="numeroIdentificacion" fullWidth validate={[required(), minLength(5), maxLength(25), number("El valor debe ser numérico.")]} />
                        </Grid>
                        <Grid item xs={5}>
                            <TextInput label="resources.proveedors.fields.razonSocial" source="razonSocial" fullWidth validate={[required(), minLength(2), maxLength(250)]} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput label="resources.proveedors.fields.nombre" source="nombre" fullWidth validate={[minLength(2), maxLength(250)]} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput label="resources.proveedors.fields.apellido" source="apellido" fullWidth validate={[maxLength(250)]} />
                        </Grid>
                        <Grid item xs={4}>
                            <TextInput label="resources.proveedors.fields.direccion" source="direccion" fullWidth validate={[required(), minLength(2), maxLength(250)]} />
                        </Grid>

                        <Grid item xs={4}>
                            <ReferenceInput
                                label="resources.proveedors.fields.departamento"
                                source="departamento"
                                reference="departamentos"
                                filterToQuery={searchText => ({ nombre: searchText })}
                                sort={{ field: 'nombre', order: 'asc' }}
                                onChange={ValidarDepartamento}
                                fullWidth
                                validate={[required()]}
                            >
                                <AutocompleteInput options={{ fullWidth: true }} optionText="nombre" />
                            </ReferenceInput>
                        </Grid>
                        <Grid item xs={4}>
                            {/* MUNICIPIOS POR DEPARTAMENTO */}
                            {
                                departamentoss === '/departamentos/05' && (
                                    // <ReferenceArrayInput 
                                    //  label="Municipio" source="municipio1" 
                                    //  reference="municipios"  
                                    //   fullWidth>
                                    //     <SelectArrayInput optionText="nombre" fullWidth/>
                                    // </ReferenceArrayInput>

                                    <ReferenceArrayInput
                                        label="resources.proveedors.fields.municipio1"
                                        source="municipio1"
                                        reference="municipios"
                                        filter={{ departamento: '/departamentos/05' }}
                                        filterToQuery={searchText => ({ nombre: searchText })}
                                        sort={{ field: 'nombre', order: 'asc' }}
                                        fullWidth
                                        validate={[required()]}
                                    >
                                        <AutocompleteArrayInput options={{ fullWidth: true }} optionText="nombre" />
                                    </ReferenceArrayInput>
                                )
                            }
                            {
                                departamentoss === '/departamentos/08' && (
                                    <ReferenceArrayInput
                                        label="resources.proveedors.fields.municipio1"
                                        source="municipio2"
                                        reference="municipios"
                                        filter={{ departamento: '/departamentos/08' }}
                                        filterToQuery={searchText => ({ nombre: searchText })}
                                        sort={{ field: 'nombre', order: 'asc' }}
                                        fullWidth
                                        validate={[required()]}
                                    >
                                        <AutocompleteArrayInput options={{ fullWidth: true }} optionText="nombre" />
                                    </ReferenceArrayInput>
                                )
                            }
                            {
                                departamentoss === '/departamentos/11' && (
                                    <ReferenceArrayInput
                                        label="resources.proveedors.fields.municipio1"
                                        source="municipio3"
                                        reference="municipios"
                                        filter={{ departamento: '/departamentos/11' }}
                                        filterToQuery={searchText => ({ nombre: searchText })}
                                        sort={{ field: 'nombre', order: 'asc' }}
                                        fullWidth
                                        validate={[required()]}
                                    >
                                        <AutocompleteArrayInput options={{ fullWidth: true }} optionText="nombre" />
                                    </ReferenceArrayInput>
                                )
                            }
                            {
                                departamentoss === '/departamentos/13' && (
                                    <ReferenceArrayInput
                                        label="resources.proveedors.fields.municipio1"
                                        source="municipio4"
                                        reference="municipios"
                                        filter={{ departamento: '/departamentos/13' }}
                                        filterToQuery={searchText => ({ nombre: searchText })}
                                        sort={{ field: 'nombre', order: 'asc' }}
                                        fullWidth
                                        validate={[required()]}
                                    >
                                        <AutocompleteArrayInput options={{ fullWidth: true }} optionText="nombre" />
                                    </ReferenceArrayInput>
                                )
                            }
                            {
                                departamentoss === '/departamentos/15' && (
                                    <ReferenceArrayInput
                                        label="resources.proveedors.fields.municipio1"
                                        source="municipio5"
                                        reference="municipios"
                                        filter={{ departamento: '/departamentos/15' }}
                                        filterToQuery={searchText => ({ nombre: searchText })}
                                        sort={{ field: 'nombre', order: 'asc' }}
                                        fullWidth
                                        validate={[required()]}
                                    >
                                        <AutocompleteArrayInput options={{ fullWidth: true }} optionText="nombre" />
                                    </ReferenceArrayInput>
                                )
                            }
                            {
                                departamentoss === '/departamentos/17' && (
                                    <ReferenceArrayInput
                                        label="resources.proveedors.fields.municipio1"
                                        source="municipio6"
                                        reference="municipios"
                                        filter={{ departamento: '/departamentos/17' }}
                                        filterToQuery={searchText => ({ nombre: searchText })}
                                        sort={{ field: 'nombre', order: 'asc' }}
                                        fullWidth
                                        validate={[required()]}
                                    >
                                        <AutocompleteArrayInput options={{ fullWidth: true }} optionText="nombre" />
                                    </ReferenceArrayInput>
                                )
                            }
                            {
                                departamentoss === '/departamentos/18' && (
                                    <ReferenceArrayInput
                                        label="resources.proveedors.fields.municipio1"
                                        source="municipio7"
                                        reference="municipios"
                                        filter={{ departamento: '/departamentos/18' }}
                                        filterToQuery={searchText => ({ nombre: searchText })}
                                        sort={{ field: 'nombre', order: 'asc' }}
                                        fullWidth
                                        validate={[required()]}
                                    >
                                        <AutocompleteArrayInput options={{ fullWidth: true }} optionText="nombre" />
                                    </ReferenceArrayInput>
                                )
                            }
                            {
                                departamentoss === '/departamentos/19' && (
                                    <ReferenceArrayInput
                                        label="resources.proveedors.fields.municipio1"
                                        source="municipio8"
                                        reference="municipios"
                                        filter={{ departamento: '/departamentos/19' }}
                                        filterToQuery={searchText => ({ nombre: searchText })}
                                        sort={{ field: 'nombre', order: 'asc' }}
                                        fullWidth
                                        validate={[required()]}
                                    >
                                        <AutocompleteArrayInput options={{ fullWidth: true }} optionText="nombre" />
                                    </ReferenceArrayInput>
                                )
                            }
                            {
                                departamentoss === '/departamentos/20' && (
                                    <ReferenceArrayInput
                                        label="resources.proveedors.fields.municipio1"
                                        source="municipio9"
                                        reference="municipios"
                                        filter={{ departamento: '/departamentos/20' }}
                                        filterToQuery={searchText => ({ nombre: searchText })}
                                        sort={{ field: 'nombre', order: 'asc' }}
                                        fullWidth
                                        validate={[required()]}
                                    >
                                        <AutocompleteArrayInput options={{ fullWidth: true }} optionText="nombre" />
                                    </ReferenceArrayInput>
                                )
                            }
                            {
                                departamentoss === '/departamentos/23' && (
                                    <ReferenceArrayInput
                                        label="resources.proveedors.fields.municipio1"
                                        source="municipio10"
                                        reference="municipios"
                                        filter={{ departamento: '/departamentos/23' }}
                                        filterToQuery={searchText => ({ nombre: searchText })}
                                        sort={{ field: 'nombre', order: 'asc' }}
                                        fullWidth
                                        validate={[required()]}
                                    >
                                        <AutocompleteArrayInput options={{ fullWidth: true }} optionText="nombre" />
                                    </ReferenceArrayInput>
                                )
                            }
                            {
                                departamentoss === '/departamentos/25' && (
                                    <ReferenceArrayInput
                                        label="resources.proveedors.fields.municipio1"
                                        source="municipio11"
                                        reference="municipios"
                                        filter={{ departamento: '/departamentos/25' }}
                                        filterToQuery={searchText => ({ nombre: searchText })}
                                        sort={{ field: 'nombre', order: 'asc' }}
                                        fullWidth
                                        validate={[required()]}
                                    >
                                        <AutocompleteArrayInput options={{ fullWidth: true }} optionText="nombre" />
                                    </ReferenceArrayInput>
                                )
                            }
                            {
                                departamentoss === '/departamentos/27' && (
                                    <ReferenceArrayInput
                                        label="resources.proveedors.fields.municipio1"
                                        source="municipio12"
                                        reference="municipios"
                                        filter={{ departamento: '/departamentos/27' }}
                                        filterToQuery={searchText => ({ nombre: searchText })}
                                        sort={{ field: 'nombre', order: 'asc' }}
                                        fullWidth
                                        validate={[required()]}
                                    >
                                        <AutocompleteArrayInput options={{ fullWidth: true }} optionText="nombre" />
                                    </ReferenceArrayInput>
                                )
                            }
                            {
                                departamentoss === '/departamentos/41' && (
                                    <ReferenceArrayInput
                                        label="resources.proveedors.fields.municipio1"
                                        source="municipio13"
                                        reference="municipios"
                                        filter={{ departamento: '/departamentos/41' }}
                                        filterToQuery={searchText => ({ nombre: searchText })}
                                        sort={{ field: 'nombre', order: 'asc' }}
                                        fullWidth
                                        validate={[required()]}
                                    >
                                        <AutocompleteArrayInput options={{ fullWidth: true }} optionText="nombre" />
                                    </ReferenceArrayInput>
                                )
                            }
                            {
                                departamentoss === '/departamentos/44' && (
                                    <ReferenceArrayInput
                                        label="resources.proveedors.fields.municipio1"
                                        source="municipio14"
                                        reference="municipios"
                                        filter={{ departamento: '/departamentos/44' }}
                                        filterToQuery={searchText => ({ nombre: searchText })}
                                        sort={{ field: 'nombre', order: 'asc' }}
                                        fullWidth
                                        validate={[required()]}
                                    >
                                        <AutocompleteArrayInput options={{ fullWidth: true }} optionText="nombre" />
                                    </ReferenceArrayInput>
                                )
                            }
                            {
                                departamentoss === '/departamentos/47' && (
                                    <ReferenceArrayInput
                                        label="resources.proveedors.fields.municipio1"
                                        source="municipio15"
                                        reference="municipios"
                                        filter={{ departamento: '/departamentos/47' }}
                                        filterToQuery={searchText => ({ nombre: searchText })}
                                        sort={{ field: 'nombre', order: 'asc' }}
                                        fullWidth
                                        validate={[required()]}
                                    >
                                        <AutocompleteArrayInput options={{ fullWidth: true }} optionText="nombre" />
                                    </ReferenceArrayInput>
                                )
                            }
                            {
                                departamentoss === '/departamentos/50' && (
                                    <ReferenceArrayInput
                                        label="resources.proveedors.fields.municipio1"
                                        source="municipio16"
                                        reference="municipios"
                                        filter={{ departamento: '/departamentos/50' }}
                                        filterToQuery={searchText => ({ nombre: searchText })}
                                        sort={{ field: 'nombre', order: 'asc' }}
                                        fullWidth
                                        validate={[required()]}
                                    >
                                        <AutocompleteArrayInput options={{ fullWidth: true }} optionText="nombre" />
                                    </ReferenceArrayInput>
                                )
                            }
                            {
                                departamentoss === '/departamentos/52' && (
                                    <ReferenceArrayInput
                                        label="resources.proveedors.fields.municipio1"
                                        source="municipio17"
                                        reference="municipios"
                                        filter={{ departamento: '/departamentos/52' }}
                                        filterToQuery={searchText => ({ nombre: searchText })}
                                        sort={{ field: 'nombre', order: 'asc' }}
                                        fullWidth
                                        validate={[required()]}
                                    >
                                        <AutocompleteArrayInput options={{ fullWidth: true }} optionText="nombre" />
                                    </ReferenceArrayInput>
                                )
                            }
                            {
                                departamentoss === '/departamentos/54' && (
                                    <ReferenceArrayInput
                                        label="resources.proveedors.fields.municipio1"
                                        source="municipio18"
                                        reference="municipios"
                                        filter={{ departamento: '/departamentos/54' }}
                                        filterToQuery={searchText => ({ nombre: searchText })}
                                        sort={{ field: 'nombre', order: 'asc' }}
                                        fullWidth
                                        validate={[required()]}
                                    >
                                        <AutocompleteArrayInput options={{ fullWidth: true }} optionText="nombre" />
                                    </ReferenceArrayInput>
                                )
                            }
                            {
                                departamentoss === '/departamentos/63' && (
                                    <ReferenceArrayInput
                                        label="resources.proveedors.fields.municipio1"
                                        source="municipio19"
                                        reference="municipios"
                                        filter={{ departamento: '/departamentos/63' }}
                                        filterToQuery={searchText => ({ nombre: searchText })}
                                        sort={{ field: 'nombre', order: 'asc' }}
                                        fullWidth
                                        validate={[required()]}
                                    >
                                        <AutocompleteArrayInput options={{ fullWidth: true }} optionText="nombre" />
                                    </ReferenceArrayInput>
                                )
                            }
                            {
                                departamentoss === '/departamentos/66' && (
                                    <ReferenceArrayInput
                                        label="resources.proveedors.fields.municipio1"
                                        source="municipio20"
                                        reference="municipios"
                                        filter={{ departamento: '/departamentos/66' }}
                                        filterToQuery={searchText => ({ nombre: searchText })}
                                        sort={{ field: 'nombre', order: 'asc' }}
                                        fullWidth
                                        validate={[required()]}
                                    >
                                        <AutocompleteArrayInput options={{ fullWidth: true }} optionText="nombre" />
                                    </ReferenceArrayInput>
                                )
                            }
                            {
                                departamentoss === '/departamentos/68' && (
                                    <ReferenceArrayInput
                                        label="resources.proveedors.fields.municipio1"
                                        source="municipio21"
                                        reference="municipios"
                                        filter={{ departamento: '/departamentos/68' }}
                                        filterToQuery={searchText => ({ nombre: searchText })}
                                        sort={{ field: 'nombre', order: 'asc' }}
                                        fullWidth
                                        validate={[required()]}
                                    >
                                        <AutocompleteArrayInput options={{ fullWidth: true }} optionText="nombre" />
                                    </ReferenceArrayInput>
                                )
                            }
                            {
                                departamentoss === '/departamentos/70' && (
                                    <ReferenceArrayInput
                                        label="resources.proveedors.fields.municipio1"
                                        source="municipio22"
                                        reference="municipios"
                                        filter={{ departamento: '/departamentos/70' }}
                                        filterToQuery={searchText => ({ nombre: searchText })}
                                        sort={{ field: 'nombre', order: 'asc' }}
                                        fullWidth
                                        validate={[required()]}
                                    >
                                        <AutocompleteArrayInput options={{ fullWidth: true }} optionText="nombre" />
                                    </ReferenceArrayInput>
                                )
                            }
                            {
                                departamentoss === '/departamentos/73' && (
                                    <ReferenceArrayInput
                                        label="resources.proveedors.fields.municipio1"
                                        source="municipio23"
                                        reference="municipios"
                                        filter={{ departamento: '/departamentos/73' }}
                                        filterToQuery={searchText => ({ nombre: searchText })}
                                        sort={{ field: 'nombre', order: 'asc' }}
                                        fullWidth
                                        validate={[required()]}
                                    >
                                        <AutocompleteArrayInput options={{ fullWidth: true }} optionText="nombre" />
                                    </ReferenceArrayInput>
                                )
                            }
                            {
                                departamentoss === '/departamentos/76' && (
                                    <ReferenceArrayInput
                                        label="resources.proveedors.fields.municipio1"
                                        source="municipio24"
                                        reference="municipios"
                                        filter={{ departamento: '/departamentos/76' }}
                                        filterToQuery={searchText => ({ nombre: searchText })}
                                        sort={{ field: 'nombre', order: 'asc' }}
                                        fullWidth
                                        validate={[required()]}
                                    >
                                        <AutocompleteArrayInput options={{ fullWidth: true }} optionText="nombre" />
                                    </ReferenceArrayInput>
                                )
                            }
                            {
                                departamentoss === '/departamentos/81' && (
                                    <ReferenceArrayInput
                                        label="resources.proveedors.fields.municipio1"
                                        source="municipio25"
                                        reference="municipios"
                                        filter={{ departamento: '/departamentos/81' }}
                                        filterToQuery={searchText => ({ nombre: searchText })}
                                        sort={{ field: 'nombre', order: 'asc' }}
                                        fullWidth
                                        validate={[required()]}
                                    >
                                        <AutocompleteArrayInput options={{ fullWidth: true }} optionText="nombre" />
                                    </ReferenceArrayInput>
                                )
                            }
                            {
                                departamentoss === '/departamentos/85' && (
                                    <ReferenceArrayInput
                                        label="resources.proveedors.fields.municipio1"
                                        source="municipio26"
                                        reference="municipios"
                                        filter={{ departamento: '/departamentos/85' }}
                                        filterToQuery={searchText => ({ nombre: searchText })}
                                        sort={{ field: 'nombre', order: 'asc' }}
                                        fullWidth
                                        validate={[required()]}
                                    >
                                        <AutocompleteArrayInput options={{ fullWidth: true }} optionText="nombre" />
                                    </ReferenceArrayInput>
                                )
                            }
                            {
                                departamentoss === '/departamentos/86' && (
                                    <ReferenceArrayInput
                                        label="resources.proveedors.fields.municipio1"
                                        source="municipio27"
                                        reference="municipios"
                                        filter={{ departamento: '/departamentos/86' }}
                                        filterToQuery={searchText => ({ nombre: searchText })}
                                        sort={{ field: 'nombre', order: 'asc' }}
                                        fullWidth
                                        validate={[required()]}
                                    >
                                        <AutocompleteArrayInput options={{ fullWidth: true }} optionText="nombre" />
                                    </ReferenceArrayInput>
                                )
                            }
                            {
                                departamentoss === '/departamentos/88' && (
                                    <ReferenceArrayInput
                                        label="resources.proveedors.fields.municipio1"
                                        source="municipio28"
                                        reference="municipios"
                                        filter={{ departamento: '/departamentos/88' }}
                                        filterToQuery={searchText => ({ nombre: searchText })}
                                        sort={{ field: 'nombre', order: 'asc' }}
                                        fullWidth
                                        validate={[required()]}
                                    >
                                        <AutocompleteArrayInput options={{ fullWidth: true }} optionText="nombre" />
                                    </ReferenceArrayInput>
                                )
                            }
                            {
                                departamentoss === '/departamentos/91' && (
                                    <ReferenceArrayInput
                                        label="resources.proveedors.fields.municipio1"
                                        source="municipio29"
                                        reference="municipios"
                                        filter={{ departamento: '/departamentos/91' }}
                                        filterToQuery={searchText => ({ nombre: searchText })}
                                        sort={{ field: 'nombre', order: 'asc' }}
                                        fullWidth
                                        validate={[required()]}
                                    >
                                        <AutocompleteArrayInput options={{ fullWidth: true }} optionText="nombre" />
                                    </ReferenceArrayInput>
                                )
                            }
                            {
                                departamentoss === '/departamentos/94' && (
                                    <ReferenceArrayInput
                                        label="resources.proveedors.fields.municipio1"
                                        source="municipio30"
                                        reference="municipios"
                                        filter={{ departamento: '/departamentos/94' }}
                                        filterToQuery={searchText => ({ nombre: searchText })}
                                        sort={{ field: 'nombre', order: 'asc' }}
                                        fullWidth
                                        validate={[required()]}
                                    >
                                        <AutocompleteArrayInput options={{ fullWidth: true }} optionText="nombre" />
                                    </ReferenceArrayInput>
                                )
                            }
                            {
                                departamentoss === '/departamentos/95' && (
                                    <ReferenceArrayInput
                                        label="resources.proveedors.fields.municipio1"
                                        source="municipio31"
                                        reference="municipios"
                                        filter={{ departamento: '/departamentos/95' }}
                                        filterToQuery={searchText => ({ nombre: searchText })}
                                        sort={{ field: 'nombre', order: 'asc' }}
                                        fullWidth
                                        validate={[required()]}
                                    >
                                        <AutocompleteArrayInput options={{ fullWidth: true }} optionText="nombre" />
                                    </ReferenceArrayInput>
                                )
                            }
                            {
                                departamentoss === '/departamentos/97' && (
                                    <ReferenceArrayInput
                                        label="resources.proveedors.fields.municipio1"
                                        source="municipio32"
                                        reference="municipios"
                                        filter={{ departamento: '/departamentos/97' }}
                                        filterToQuery={searchText => ({ nombre: searchText })}
                                        sort={{ field: 'nombre', order: 'asc' }}
                                        fullWidth
                                        validate={[required()]}
                                    >
                                        <AutocompleteArrayInput options={{ fullWidth: true }} optionText="nombre" />
                                    </ReferenceArrayInput>
                                )
                            }
                            {
                                departamentoss === '/departamentos/99' && (
                                    <ReferenceArrayInput
                                        label="resources.proveedors.fields.municipio1"
                                        source="municipio33"
                                        reference="municipios"
                                        filter={{ departamento: '/departamentos/99' }}
                                        filterToQuery={searchText => ({ nombre: searchText })}
                                        sort={{ field: 'nombre', order: 'asc' }}
                                        fullWidth
                                        validate={[required()]}
                                    >
                                        <AutocompleteArrayInput options={{ fullWidth: true }} optionText="nombre" />
                                    </ReferenceArrayInput>
                                )
                            }
                        </Grid>
                        {/* <Grid item xs={2}>                   
                   <ReferenceInput label="Indicativo" source="departamento" reference="departamentos" >
                       <SelectInput  optionText="indicativo" disabled fullWidth />
                   </ReferenceInput>                  
               </Grid> */}
                        <Grid item xs={6}>
                            <TextInput label="resources.proveedors.fields.telefono" source="telefono" fullWidth className="labelNumber" validate={[required(), number("Solo Números")]} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput label="resources.proveedors.fields.telefono2" source="telefono2" fullWidth className="labelNumber" validate={[number("Solo Números")]} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput label="resources.proveedors.fields.correo" source="correo" fullWidth validate={[minLength(2), maxLength(100), email()]} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput label="resources.proveedors.fields.observaciones" source="observaciones" fullWidth validate={[minLength(2), maxLength(200)]} />
                        </Grid>
                    </Grid>
                </FormTab>
            </TabbedForm>
        </PimsCreate>
    )
}));