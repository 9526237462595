import React , {useState} from 'react';
import {  TextInput, SaveButton,Toolbar, ReferenceInput, SelectInput, PimsToolbarActions } from 'react-admin';
import { PimsCreate } from './../Create';
import { PInput } from './../../inputs/PInput';
import { SimpleForm } from './../SimpleForm';
import Divider from '@material-ui/core/Divider';
import {
    required,
    minLength,
    
    maxLength,
} from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { withStyles} from '@material-ui/core';
import {styles}  from './../../EstilosGrid';
import { translate } from 'react-admin';
import config from '../../config/config';
let urlAppjs = config.UrlServer;

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

export const RutaCreate =  translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;
    const [proyectos , setProyectos] = useState('');
    const Proyecto = (value) =>{
    if(value == undefined || value === null || value === '')
    {
        return 'Error correcto'
    }else{
        return 'Hola'
    }
      
    }

    const CustomToolbar = ({ basePath, data,...props}) => (
        <Toolbar {...props} >
            <SaveButton onClick={Proyecto}/>
    
        </Toolbar>
    );

const  [acceso, setAcceso] = useState(0);

const [codigoIngreso , setCodigoIgreso] =useState('')
const  [pInicio, setPinicio] = useState('');
const  [pFin, setPfin] = useState('');
const  [prInicio, setPrinicio] = useState('');
const  [prFin, setPrfin] = useState('');

const ErrorRuta = (value) =>{    
    setCodigoIgreso(value)        
}   

const ValorMaximoPrCreate =(value, props) =>{
    if(value !== undefined)
    {
        value = value.split("PR")
        if(value[1] !== undefined)
        {
            value=value[1].split("+")
            if(value[0] > 999)
            {
                return translate('dynatest.resources.validaciones.ValorMaximoPrCreate');
            }
        }
    }
}
const validarLogicaPrFinCreate=(value, props)=>{
    if(value !== undefined){
        
        let prInicio = document.getElementById('ingresoPrInicio').value;
        prInicio = prInicio.split('PR')
        if(prInicio[1] !== undefined)
        {
            prInicio = prInicio[1].split('+')
              
            value = value.split('PR')
            value = value[1].split('+')
        
            if(parseInt(value[0]) < parseInt(prInicio[0]))
            {   
                return translate('dynatest.resources.validaciones.validarLogicaPrFinCreate');
            }
            if(parseInt(value[0]) === parseInt(prInicio[0]) && parseInt(value[1]) === parseInt(prInicio[1]))
            {
                return translate('dynatest.resources.validaciones.validarLogicaPrFinCreate');
            }
            if(parseInt(value[0]) === parseInt(prInicio[0]) && parseInt(value[1]) < parseInt(prInicio[1]))
            {
                return translate('dynatest.resources.validaciones.validarLogicaPrFinCreate');
            }
           
        }

        
        
    }
}

const PrInicial = (valor) =>{
    // let valor = document.getElementById('ingresoPrInicio');

    valor = valor.split('PR');    
    valor =valor[1].split('+');
    valor = valor[0] +'.'+ valor[1];
    setPinicio(valor);

}

const PrFinal = (value) =>{

    if(value !== ''){
        value = value.split('PR'); 
        value =value[1].split('+');
        value = value[0] +'.'+ value[1];    
        setPfin(value);

    }
    
    
}

const CodigoRuta = () =>{
    
    if(codigoIngreso !==0 ){
        fetch(urlAppjs+"/codigoRuta/"+codigoIngreso)
        .then(function(response){
            return response.json()
        })
        .then(function(myJson){
            
            let prInicio=myJson[0]
            let prFin=myJson[0]
            
            if(prInicio !== undefined){
                
                prInicio=prInicio['pFin']
                // prFin=prFin['pFin']
                setPrinicio(prInicio)
                // setPrfin(prFin)                
            }                           
            // if(codigoIngreso !== 0 && prInicio > prFin ){
            //     setAcceso(0)
                
            // }else if(codigoIngreso !== 0 && prInicio <= prFin && prInicio !== null ){                
            //     setAcceso(1)
                
            // }else if((prInicio === null && prFin === null) || (prInicio === null)){
            //     setAcceso(0)
               
            // } 
            
        })
    }   
}

const Validacion = () =>{
    if(pInicio < prInicio){
        return translate('resources.validaciones.mayorA') + prInicio;
    }
}
    

const PimsToolbarActions = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <SaveButton />
        {/* <BackButton /> */}
    </Toolbar>
));
return(
    <PimsCreate  {...props}>
        <SimpleForm submitOnEnter={false} redirect="list" >
            <Grid container spacing={24}>                
                <Grid item xs={12}>
                    <ReferenceInput label="resources.rutas.fields.proyecto" source="proyecto" reference="proyectos" validate={[required()]} defaultValue={window.localStorage.getItem('id_project2')}>
                        <SelectInput  optionText="nombre" fullWidth disabled />
                    </ReferenceInput>
                </Grid>
                <Grid item xs={6}>
                    <TextInput label="resources.rutas.fields.codigo" onBlur={CodigoRuta} source="codigo"  id="codigo"  fullWidth validate={[required(), minLength(2), maxLength(10), ErrorRuta]}/>
                </Grid>
                <Grid item xs={6}>
                    <SelectInput label="resources.rutas.fields.categoriaRuta" source="categoriaRuta" choices={[
                            { id: 1, name: 'dynatest.resources.rutas.select.categoria.priOrden' },
                            { id: 2, name: 'dynatest.resources.rutas.select.categoria.segOrden' },
                            { id: 3, name: 'dynatest.resources.rutas.select.categoria.terOrden' },
                        ]}  fullWidth validate={[required()]}                       
                    />
                </Grid>                
                <Grid item xs={6}>
                    <PInput label="resources.rutas.fields.pInicio" id="ingresoPrInicio" source="pInicio" fullWidth validate={[required(),ValorMaximoPrCreate, PrInicial, Validacion]}/>
                </Grid>
                <Grid item xs={6}>
                    <PInput label="resources.rutas.fields.pFin"  id="ingresoPrFin" source="pFin" fullWidth validate={[required(),ValorMaximoPrCreate, PrFinal, validarLogicaPrFinCreate]}/>
                </Grid>                                                
                <Grid item xs={12}>                    
                    <b>{translate('dynatest.resources.rutas.title.inforInvias')}</b>                    
                </Grid>
                <Grid item xs={12}>
                    <Divider />                    
                </Grid>
                <Grid item xs={6}>
                    <TextInput label="resources.rutas.fields.tramo" source="tramo"  fullWidth validate={[required(), minLength(3), maxLength(100)]}/>
                </Grid>
                <Grid item xs={6}>
                    <TextInput label="resources.rutas.fields.sector" source="sector"  fullWidth validate={[required(), minLength(3), maxLength(100)]}/>
                </Grid>
                <Grid item xs={6}>
                    <TextInput label="resources.rutas.fields.origen" source="origen"  fullWidth validate={[required(), minLength(3), maxLength(100)]}/>
                </Grid>
                <Grid item xs={6}>
                    <TextInput label="resources.rutas.fields.destino" source="destino" fullWidth validate={[required(), minLength(3), maxLength(100)]}/>
                </Grid>
            
            </Grid>
        </SimpleForm>
    </PimsCreate>
)
}));