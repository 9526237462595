import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
    Loading, SelectInput
} from 'react-admin';

import { Divider } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import config from '../../config/config';
import AlertDialog from './../../helpers/AlertDialog';
import { styles } from './../../EstilosGrid';
import { translate } from 'react-admin';

let urlAppjs = config.UrlServer;

class ShowElement extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            Elementos: [],
            datos: {
                rutinario: [],
                periodico: [],
                rehabilitacion: [],
                cambio: [],
                unidadMedida: [],
                tipoElementos: [],
                tipoElementoChoices: [{
                    id: "todo",
                    name: "Todo"
                }]

            },
            tipoElementoSelected: "todo"
        }
        this.fetcher.bind(this);
        this.returnComponent.bind(this);
        this.formatFecha.bind(this);

    }

    componentDidMount() {
        let urlTipoElemento = (this.props.sistema != "/sistemas/SEN") ?
            `tipo_elementos.json?pagination=off&sistema=${this.props.sistema}&visibleOnPolicies=1` :
            "tipo_elemento_senals.json?pagination=off";
        fetch(urlAppjs + "/unidad_medidas.json?pagination=off",
            {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                return response.json()
            })
            .then((unidades) => {
                this.state.datos.unidadMedida = unidades;
            })
        fetch(urlAppjs + `/${urlTipoElemento}`,
            {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                return response.json()
            })
            .then((tipoElementos) => {
                this.state.datos.tipoElementos = tipoElementos;
                tipoElementos.map(index => {
                    this.state.datos.tipoElementoChoices.push({
                        id: index["id"],
                        name: index["nombre"]
                    });
                })
                // console.log(this.state.datos.tipoElementoChoices);


                this.fetcher("politicas_elementos", "rutinario");
                this.fetcher("politicas_elemento_periodicos", "periodico");
                this.fetcher("politicas_elemento_rehabilitacions", "rehabilitacion");
                this.fetcher("politicas_elemento_cambios", "cambio");
            })
    }

    fetcher(target, container) {
        const { translate } = this.props;
        fetch(urlAppjs + "/" + target + ".json?pagination=off&politicasMantenimiento=" + this.props.politica,
            {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                return response.json()
            })
            .then((Elementos) => {
                this.setState({ Elementos: Elementos })
                var datos = [];
                var unidad = "";
                var nombreElemento = "";
                
                if (this.state.Elementos.hasOwnProperty("length")) {
                    this.state.Elementos.map(index => {
                        this.state.datos.unidadMedida.map(record => {
                            if (record.id == index.unidadMedida.split("/")[2]) {
                                unidad = record.abreviatura;
                            }
                        })
                        this.state.datos.tipoElementos.map(record => {
                            if (index.tipoElemento) {
                                if (record.id == index.tipoElemento.split("/")[2]) {
                                    nombreElemento = record.nombre;
                                }
                            } else if (index.tipoElementoSenal) {
                                if (record.id == index.tipoElementoSenal.split("/")[2]) {
                                    nombreElemento = record.nombre;
                                }
                            }
                        })
                        datos = {
                            sistema: (index.sistema) ? index.sistema : translate('dynatest.resources.politicas_mantenimientos.tabs.tabsTitle.noDiligenciado'),
                            tipoElemento: (index.tipoElemento) ? index.tipoElemento : translate('dynatest.resources.politicas_mantenimientos.tabs.tabsTitle.noDiligenciado'),
                            nombreElemento: (nombreElemento) ? nombreElemento : translate('dynatest.resources.politicas_mantenimientos.tabs.tabsTitle.noDiligenciado'),
                            operacion: (index.operacion) ? index.operacion : translate('dynatest.resources.politicas_mantenimientos.tabs.tabsTitle.noDiligenciado'),
                            seRealiza: (index.seRealiza) ? index.seRealiza : translate('dynatest.resources.politicas_mantenimientos.tabs.tabsTitle.noDiligenciado'),
                            unidadMedida: (unidad) ? unidad : translate('dynatest.resources.politicas_mantenimientos.tabs.tabsTitle.noDiligenciado'),
                            periocidad: (index.periocidad) ? index.periocidad : translate('dynatest.resources.politicas_mantenimientos.tabs.tabsTitle.noDiligenciado'),
                            valorU: (index.valorU) ? index.valorU : translate('dynatest.resources.politicas_mantenimientos.tabs.tabsTitle.noDiligenciado'),
                            fechaU: (index.fechaU) ? index.fechaU : translate('dynatest.resources.politicas_mantenimientos.tabs.tabsTitle.noDiligenciado'),
                            fuente: (index.fuente) ? index.fuente : translate('dynatest.resources.politicas_mantenimientos.tabs.tabsTitle.noDiligenciado'),
                            fecha: (index.fecha) ? index.fecha : translate('dynatest.resources.politicas_mantenimientos.tabs.tabsTitle.noDiligenciado'),
                            descripcion: (index.descripcion) ? index.descripcion : translate('dynatest.resources.politicas_mantenimientos.tabs.tabsTitle.noDiligenciado')
                        }
                        this.state.datos[container].push(datos);
                        this.forceUpdate();

                    })
                } else {
                    datos = {
                        sistema: this.state.Elementos.sistema,
                        tipoElemento: this.state.Elementos.tipoElemento,
                        nombreElemento: this.state.Elementos.nombreElemento,
                        seRealiza: this.state.Elementos.seRealiza,
                        unidadMedida: this.state.Elementos.unidadMedida,
                        periocidad: this.state.Elementos.periocidad,
                        valorU: this.state.Elementos.valorU,
                        fechaU: this.state.Elementos.fechaU,
                        fuente: this.state.Elementos.fuente,
                        fecha: this.state.Elementos.fecha,
                        descripcion: this.state.Elementos.descripcion
                    }
                    this.state.datos[container].push(datos);
                    this.forceUpdate();
                }
            })
    }

    formatFecha(fecha) {
        var day = "";
        var month = "";
        var year = "";
        var store = "";
        if (fecha != null) {
            store = fecha.split("T");
            store = store[0].split("-");
            year = store[0];
            month = store[1];
            day = store[2];

            return day + "/" + month + "/" + year;
        }
    }

    returnComponent(source, classes, tipoElemento = "todo", mode = "default") {
        const { translate } = this.props;
        // console.log("source: ", source);
        var index = [];
        var returnedComponent = "";
        var unidadPeriocidad = {
            mes: " Mes",
            meses: " Meses",
            anos: " Años"
        }
        // console.log(this.state.datos[source]);
        switch (mode) {
            case "single":
                this.state.datos[source].map((map) => {
                    if (map.tipoElemento == "/tipo_elementos/" + tipoElemento) {
                        index = map;
                    }
                })
                returnedComponent =
                    <Fragment>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={4}>{translate('resources.politicas_mantenimientos.fields.FechaIniPeriodicidad')}</TableCell>
                                    <TableCell colSpan={2}>
                                        {this.state.datos[source][0] && this.formatFecha(this.state.datos[source][0].fecha)}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.TbCellCol1} align="left">{translate('resources.politicas_mantenimientos.fields.SeRealiza')}</TableCell>
                                    <TableCell className={classes.TbCellCol1} align="left">{translate('resources.politicas_mantenimientos.fields.unidadMedida')}</TableCell>
                                    <TableCell className={classes.TbCellCol1} align="right">{translate('resources.politicas_mantenimientos.fields.periocidad')}</TableCell>
                                    <TableCell className={classes.TbCellCol1} align="right">{translate('resources.politicas_mantenimientos.fields.Valor_U')}</TableCell>
                                    <TableCell className={classes.TbCellCol1} align="right">{translate('resources.politicas_mantenimientos.fields.Fecha_U')}</TableCell>
                                    <TableCell className={classes.TbCellCol3} align="center">{translate('resources.politicas_mantenimientos.fields.fuente')}</TableCell>
                                    <TableCell className={classes.TbCellCol4} align="center">{translate('resources.politicas_mantenimientos.fields.descripcion')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {index.length !== 0 &&
                                    <TableRow>
                                        {/* <TableCell className={classes.TbCellCol1} align="left">{index.nombreElemento}</TableCell> */}
                                        <TableCell className={classes.TbCellCol1} align="left">
                                            {index.seRealiza == true &&
                                                <CheckIcon />
                                            }
                                            {index.seRealiza == false &&
                                                <ClearIcon />
                                            }
                                        </TableCell>
                                        <TableCell className={classes.TbCellCol1} align="left">{index.unidadMedida}</TableCell>
                                        <TableCell className={classes.TbCellCol1} align="right">
                                            {index.periocidad.toString().replace(".", ",")}
                                            {index.periocidad == 1 && source != "rehabilitacion" && source != "cambio" &&
                                                unidadPeriocidad.mes
                                            }
                                            {index.periocidad !== 1 && source !== "rehabilitacion" && source !== "cambio" &&
                                                unidadPeriocidad.meses
                                            }
                                            {index.periocidad !== 1 && source !== "rutinario" && source !== "periodico" &&
                                                unidadPeriocidad.anos
                                            }
                                        </TableCell>
                                        <TableCell className={classes.TbCellCol1} align="right">
                                            {index.valorU != null && index.valorU !== translate('dynatest.resources.politicas_mantenimientos.tabs.tabsTitle.noDiligenciado') && "$" + index.valorU}
                                            {index.valorU != null && index.valorU === translate('dynatest.resources.politicas_mantenimientos.tabs.tabsTitle.noDiligenciado') && index.valorU}
                                        </TableCell>
                                        <TableCell className={classes.TbCellCol1} align="justify">{index.fechaU == translate('dynatest.resources.politicas_mantenimientos.tabs.tabsTitle.noDiligenciado') ? index.fechaU : this.formatFecha(index.fechaU)}</TableCell>
                                        <TableCell className={classes.TbCellCol3} align="justify">{index.fuente}</TableCell>
                                        <TableCell className={classes.TbCellCol3} align="justify">{index.descripcion}</TableCell>
                                    </TableRow>

                                }
                                {index.length === 0 &&
                                    <TableCell colSpan={7} align="center">
                                        <div style={{ textAlign: "center", width: "100%" }}>
                                            <Typography component="h3" variant="h3">
                                                Ninguna Política Definida
                                                </Typography>
                                        </div>
                                    </TableCell>
                                }
                            </TableBody>
                        </Table>
                    </Fragment>
                break;
            case "default":
                returnedComponent =
                    <Fragment>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={5}>{translate('resources.politicas_mantenimientos.fields.FechaIniPeriodicidad')}</TableCell>
                                    <TableCell colSpan={2}>
                                        {this.state.datos[source][0] && this.formatFecha(this.state.datos[source][0].fecha)}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className={classes.TbCellCol1} align="left">{translate('resources.politicas_mantenimientos.fields.tipoElemento')}</TableCell>
                                    <TableCell className={classes.TbCellCol1} align="left">{translate('resources.politicas_mantenimientos.fields.SeRealiza')}</TableCell>
                                    <TableCell className={classes.TbCellCol1} align="left">{translate('resources.politicas_mantenimientos.fields.unidadMedida')}</TableCell>
                                    <TableCell className={classes.TbCellCol1} align="right">{translate('resources.politicas_mantenimientos.fields.periocidad')}</TableCell>
                                    <TableCell className={classes.TbCellCol1} align="right">{translate('resources.politicas_mantenimientos.fields.Valor_U')}</TableCell>
                                    <TableCell className={classes.TbCellCol1} align="right">{translate('resources.politicas_mantenimientos.fields.Fecha_U')}</TableCell>
                                    <TableCell className={classes.TbCellCol3} align="center">{translate('resources.politicas_mantenimientos.fields.fuente')}</TableCell>
                                    <TableCell className={classes.TbCellCol3} align="center">{translate('resources.politicas_mantenimientos.fields.descripcion')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.datos[source].map((index) => (
                                    <TableRow>
                                        {/* {console.log(index)} */}
                                        <TableCell className={classes.TbCellCol1} align="left">{index.nombreElemento}</TableCell>
                                        <TableCell className={classes.TbCellCol1} align="left">
                                            {index.seRealiza == true &&
                                                <CheckIcon />
                                            }
                                            {index.seRealiza == false &&
                                                <ClearIcon />
                                            }
                                        </TableCell>
                                        <TableCell className={classes.TbCellCol1} align="left">{index.unidadMedida}</TableCell>
                                        <TableCell className={classes.TbCellCol1} align="right">
                                            {index.periocidad.toString().replace(".", ",")}
                                            {index.periocidad == 1 && source != "rehabilitacion" && source != "cambio" &&
                                                unidadPeriocidad.mes
                                            }
                                            {index.periocidad !== 1 && source !== "rehabilitacion" && source !== "cambio" &&
                                                unidadPeriocidad.meses
                                            }
                                            {index.periocidad !== 1 && source !== "rutinario" && source !== "periodico" &&
                                                unidadPeriocidad.anos
                                            }
                                        </TableCell>
                                        <TableCell className={classes.TbCellCol1} align="right">
                                            {index.valorU != null && index.valorU !== translate('dynatest.resources.politicas_mantenimientos.tabs.tabsTitle.noDiligenciado') && "$" + index.valorU}
                                            {index.valorU != null && index.valorU === translate('dynatest.resources.politicas_mantenimientos.tabs.tabsTitle.noDiligenciado') && index.valorU}
                                        </TableCell>
                                        <TableCell className={classes.TbCellCol1} align="justify">{index.fechaU == translate('dynatest.resources.politicas_mantenimientos.tabs.tabsTitle.noDiligenciado') ? index.fechaU : this.formatFecha(index.fechaU)}</TableCell>
                                        <TableCell className={classes.TbCellCol3} align="justify">{index.fuente}</TableCell>
                                        <TableCell className={classes.TbCellCol3} align="justify">{index.descripcion}</TableCell>
                                    </TableRow>
                                ))}
                                {this.state.datos[source].length === 0 &&
                                    <TableCell colSpan={8} align="center">
                                        <div style={{ textAlign: "center", width: "100%" }}>
                                            <Typography component="h3" variant="h3">
                                            {translate('dynatest.resources.politicas_mantenimientos.messages.NingunaPolDefinida')}
                                                </Typography>
                                        </div>
                                    </TableCell>
                                }
                            </TableBody>
                        </Table>
                    </Fragment>
                break;
        }
        return returnedComponent;

    }

    render() {
        const { translate } = this.props;
        const { classes } = this.props;
        const setElemento = (value) => {
            let lenghtObject = Object.keys(value);
            let valor = '';
            for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; };
            this.setState({ tipoElementoSelected: valor });
        }

        if (this.state.datos["rutinario"].length ||
            this.state.datos["periodico"].length ||
            this.state.datos["rehabilitacion"].length ||
            this.state.datos["cambio"].length
        ) {
            return (
                <Fragment>
                    <SelectInput
                        label="resources.politicas_mantenimientos.fields.tipoElemento"
                        source="tipoElemento"
                        fullWidth
                        onChange={setElemento}
                        formClassName={classes.fieldCol12}
                        choices={this.state.datos.tipoElementoChoices}
                    />
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography >{translate('dynatest.resources.politicas_mantenimientos.expansionPanel.ElemenMantenRutinario')}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            {this.state.tipoElementoSelected === "todo" &&
                                this.returnComponent("rutinario", classes)
                            }
                            {this.state.tipoElementoSelected !== "todo" &&
                                this.returnComponent("rutinario", classes, this.state.tipoElementoSelected, "single")
                            }
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography >{translate('dynatest.resources.politicas_mantenimientos.expansionPanel.ElemenMantenPeriodico')}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            {this.state.tipoElementoSelected === "todo" &&
                                this.returnComponent("periodico", classes)
                            }
                            {this.state.tipoElementoSelected !== "todo" &&
                                this.returnComponent("periodico", classes, this.state.tipoElementoSelected, "single")
                            }
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography >{translate('dynatest.resources.politicas_mantenimientos.expansionPanel.ElemenMantenRehabilitacion')}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            {this.state.tipoElementoSelected === "todo" &&
                                this.returnComponent("rehabilitacion", classes)
                            }
                            {this.state.tipoElementoSelected !== "todo" &&
                                this.returnComponent("rehabilitacion", classes, this.state.tipoElementoSelected, "single")
                            }
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography >{translate('dynatest.resources.politicas_mantenimientos.expansionPanel.ElemenMantenReconstruccion_Cambio')}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            {this.state.tipoElementoSelected === "todo" &&
                                this.returnComponent("cambio", classes)
                            }
                            {this.state.tipoElementoSelected !== "todo" &&
                                this.returnComponent("cambio", classes, this.state.tipoElementoSelected, "single")
                            }
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Fragment>
            )
        } else {
            return <Loading />
        }

    }
}

ShowElement.propTypes = {
    classes: PropTypes.object.isRequired,
}
const ShowElements = withStyles(styles)(ShowElement);


export default translate(ShowElements);