import React from 'react';
import {
    TabbedForm, FormTab, TextField, ReferenceField, NumberField, ArrayField, SingleFieldList, Datagrid,
    DateField, BooleanField, ShowController, Toolbar

} from 'react-admin';
import { PField } from './../../../../fields/PField';
import { PRField } from './../../../../fields/PRField';
import { PercentageField } from './../../../../fields/PercentageField';
import { Show } from './../../../Show';
import { GMapInput } from '../../../../inputs/GMapInput';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { styles } from '../../../../EstilosGrid';
import { ButtonCarousel } from './../../../images/ButtonCarousel';
import { translate } from 'react-admin';

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <span></span>

    </Toolbar>
));

export const ElementoOtroSegShow = translate(withStyles(styles)(({ classes, record, ...props }) => {
    const { translate } = props;
    return (
        <ShowController title="Información Segmento Adoquín" {...props}>
            {controllerProps =>
                <Show title="dynatest.resources.otro_detalles.title.showTitle" {...props} actions={<ButtonCarousel />}>
                    <TabbedForm toolbar={<CustomToolbar />}>
                        <FormTab label="dynatest.resources.otro_detalles.tabs.informacionBasica">
                            <ReferenceField source="unidadFuncional" reference="unidad_funcionals" linkType={false} fullWidth formClassName={classes.grid_cont3}>
                                <TextField label="" source="fullCodigos" />
                            </ReferenceField>
                            <ReferenceField source="tramo" reference="tramos" linkType={false} fullWidth formClassName={classes.grid_cont3}>
                                <TextField label="" source="fullCodigos" />
                            </ReferenceField>
                            <TextField label="resources.otro_detalles.fields.segmento" source="segmento_.codigo" fullWidth formClassName={classes.grid_cont3} />
                            <br/>
                            <ReferenceField label="resources.otro_detalles.fields.absInicio" source="segmento" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont3}>
                                <PRField label="" source="abInicio" />
                            </ReferenceField>
                            <ReferenceField label="resources.otro_detalles.fields.absFin" source="segmento" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont3}>
                                <PRField label="" source="abFin" />
                            </ReferenceField><NumberField label="resources.otro_detalles.fields.longitudShow" source="segmento_.fullResta" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3} />
                            <br/>
                            <ReferenceField label="resources.otro_detalles.fields.prInicio" source="segmento" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont3} >
                                <PField label="" source="pInicio" />
                            </ReferenceField>
                            <ReferenceField label="resources.otro_detalles.fields.prFin" source="segmento" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont3}>
                                <PField label="" source="pFin" />
                            </ReferenceField><span></span>

                            <b>{translate('resources.otro_detalles.fields.localizacion')}</b>
                            <Divider fullWidth style={{ marginTop: '10px' }} />

                            <TextField label="resources.otro_detalles.fields.departamento" source="segmento_.municipio_.departamento_.nombre"  fullWidth formClassName={classes.grid_cont3} />  
                            <TextField label="resources.otro_detalles.fields.municipio" source="segmento_.municipio_.nombre"  fullWidth formClassName={classes.grid_cont3}/> 
                            
                            <Typography variant="b" component="b">{translate('resources.otro_detalles.fields.coordenadasIniciales')}</Typography>
                            <Divider fullWidth style={{ marginTop: '10px' }} />
                            <NumberField label="resources.otro_detalles.fields.latitud" source="segmento_.latitud" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3} />
                            <NumberField label="resources.otro_detalles.fields.longitud" source="segmento_.longitud" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3} />
                            <NumberField label="resources.otro_detalles.fields.altura" source="segmento_.alturaIni" style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} fullWidth formClassName={classes.grid_cont3} />
                            <Typography variant="b" component="b">{translate('resources.otro_detalles.fields.coordenadasFinales')}</Typography>
                            <Divider fullWidth style={{ marginTop: '10px' }} />
                            <NumberField label="resources.otro_detalles.fields.latitud" source="segmento_.latitud2" style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} fullWidth formClassName={classes.grid_cont3} />
                            <NumberField label="resources.otro_detalles.fields.longitud" source="segmento_.longitud2" style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} fullWidth formClassName={classes.grid_cont3} />
                            <NumberField label="resources.otro_detalles.fields.altura" source="segmento_.alturaFin" style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} fullWidth formClassName={classes.grid_cont3} />

                            {/* <b>Características</b>

                    <Divider fullWidth />
                    <ReferenceField label="Tipo Calzada" source="segmento_.tipoCalzada" reference="tipo_calzadas" linkType={false}  formClassName={classes.grid_cont3}>
                        <TextField source="nombre" />
                    </ReferenceField>

                    <ReferenceField label="Tipo Segmento" source="segmento_.tipoSegmento" reference="tipo_segmentos" linkType={false}  formClassName={classes.grid_cont3}>
                        <TextField source="nombre" />
                    </ReferenceField>

                    <ReferenceField label="Tipo de superficie" source="segmento_.tipoSuperficie" reference="tipo_elementos" linkType={false}  formClassName={classes.grid_cont3}>
                        <TextField source="nombre" />
                    </ReferenceField>

                    <BooleanField  source="segmento_.estado" fullWidth formClassName={classes.grid_cont3}/> 
                    <span></span> */}

                            <GMapInput
                                defaultZoom={8}
                                defaultCenter={{ lat: 4.7567039, lng: -74.0311056 }}
                                source="segmento_.ubicacionMapa"
                                multipleMarkers="true"
                                googleKey="AIzaSyCivTXzJBYRBK8nosBYRFXnv-hGXIbhnYY"
                                icon={"/imagenes/senalesColombia/Elementos/segmento_inventaro/Otros.png"}
                            />
                        </FormTab>
                        <FormTab label="dynatest.resources.otro_detalles.tabs.detalles">
                            <br />
                            <ArrayField source="detallesSegmentos" label="resources.otro_detalles.fields.sentido" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="sentido" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="detallesSegmentos" linkType={false} label="resources.otro_detalles.fields.estadoDetalles" formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="estadoDetalles" />
                                </SingleFieldList>
                            </ArrayField>
                            {controllerProps.record && controllerProps.record.detallesSegmentos[0].estadoDetalles === translate('dynatest.resources.otro_detalles.select.estadoDetalles.Inactivo') &&
                                <ArrayField source="detallesSegmentos" label="resources.otro_detalles.fields.estadoRazon" linkType={false} formClassName={classes.grid_cont3}>
                                    <SingleFieldList>
                                        <TextField source="estadoRazon" />
                                    </SingleFieldList>
                                </ArrayField>
                            }
                             <ArrayField source="detallesSegmentos" label="dynatest.resources.otro_detalles.boolean.elementoMantenimiento"  fullWidth linkType={false} formClassName={classes.grid_cont3}>
                                    <SingleFieldList>
                             <BooleanField source="elementoMantenimiento" />
                           </SingleFieldList>
                           </ArrayField>
                            <br />
                            <ArrayField source="detallesSegmentos" label="resources.otro_detalles.fields.dateInicioOperacion" linkType={false}  formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <DateField source="dateInicioOperacion" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="detallesSegmentos" label="resources.otro_detalles.fields.proveedor" linkType={false}  formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="proveedor_.razonSocial" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="detallesSegmentos" label="resources.otro_detalles.fields.garantiaMeses"  linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="garantiaMeses" />
                                </SingleFieldList>
                            </ArrayField>
                            <br />
                            <strong>{translate('resources.otro_detalles.fields.trafico')}</strong>
                            <br />
                            <Divider fullWidth /><Divider fullWidth /><Divider fullWidth />
                            <ArrayField source="detallesSegmentos" label="resources.otro_detalles.fields.esalTime" linkType={false} fullWidth  style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}}formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="esalTime" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="detallesSegmentos" label="resources.otro_detalles.fields.nivelTraficoDetalles" linkType={false}  formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="nivelTrafico" />
                                </SingleFieldList>
                            </ArrayField>
                            <br />
                            <ArrayField source="detallesSegmentos" label="resources.otro_detalles.fields.esalTime10" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="esalTime10" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="detallesSegmentos" label="resources.otro_detalles.fields.esalTime20" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="esalTime20" />
                                </SingleFieldList>
                            </ArrayField>
                            <br />
                            <Divider fullWidth /><Divider fullWidth /><Divider fullWidth />
                            <ArrayField source="detallesSegmentos" linkType={false} label="resources.otro_detalles.fields.detallesObservaciones" formClassName={classes.grid_cont12}>
                                <SingleFieldList>
                                    <TextField source="detallesObservaciones" />
                                </SingleFieldList>
                            </ArrayField>
                        </FormTab>
                        <FormTab label="dynatest.resources.otro_detalles.tabs.geometria">
                            <br />
                            <strong>{translate('resources.otro_detalles.fields.detallesCalzada')}</strong>
                            <br />
                            <Divider fullWidth /><Divider fullWidth /><Divider fullWidth />
                            <ArrayField source="geometriaSegmentos" label="resources.otro_detalles.fields.geometriaLargo" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaLargo" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmentos" label="resources.otro_detalles.fields.geometriaAncho" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaAncho" />
                                </SingleFieldList>
                            </ArrayField>
                            <br />
                            <strong>{translate('resources.otro_detalles.fields.detallesCarriles')}</strong>
                            <br />
                            <Divider fullWidth /><Divider fullWidth /><Divider fullWidth />
                            <ArrayField source="geometriaSegmentos" label="resources.otro_detalles.fields.geometriaNumCarriles" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaNumCarriles" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmentos" label="resources.otro_detalles.fields.geometriaAnchoCarril" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaAnchoCarril" />
                                </SingleFieldList>
                            </ArrayField>
                            <br />
                            <ArrayField source="geometriaSegmentos" label="resources.otro_detalles.fields.geometriaTipoBerma" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="geometriaTipoBerma" />
                                </SingleFieldList>
                            </ArrayField>
                            {controllerProps.record && controllerProps.record.geometriaSegmentos[0].geometriaTipoBerma !== "Inexistente" &&
                            <ArrayField source="geometriaSegmentos" label="resources.otro_detalles.fields.geometriaAnchoBerma" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaAnchoBerma" />
                                </SingleFieldList>
                            </ArrayField>
                            }
                            {controllerProps.record && controllerProps.record.geometriaSegmentos[0].geometriaTipoBerma !== "Inexistente" &&
                                <ArrayField source="geometriaSegmentos" label="resources.otro_detalles.fields.geometriaCondicionBerma" linkType={false} formClassName={classes.grid_cont3}>
                                    <SingleFieldList>
                                        <TextField source="geometriaCondicionBerma" />
                                    </SingleFieldList>
                                </ArrayField>
                            }
                            <br />
                            <ArrayField source="geometriaSegmentos" label="resources.otro_detalles.fields.sumaAreaCarriles" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="sumaAreaCarriles" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmentos" label="resources.otro_detalles.fields.areaCarrilesVerdadera" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="areaCarrilesVerdadera" />
                                </SingleFieldList>
                            </ArrayField>
                            <br />
                            <strong>{translate('resources.otro_detalles.fields.propiedadesGeometricas')}</strong>
                            <br />
                            <Divider fullWidth /><Divider fullWidth /><Divider fullWidth />
                            <ArrayField source="geometriaSegmentos" label="resources.otro_detalles.fields.geometriaLongitudinal" linkType={false}  fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <PercentageField source="geometriaLongitudinal" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmentos" label="resources.otro_detalles.fields.geometriaLongitudinalMa" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <PercentageField source="geometriaLongitudinalMa" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmentos" label="resources.otro_detalles.fields.geometriaLongitudinalMi" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <PercentageField source="geometriaLongitudinalMi" />
                                </SingleFieldList>
                            </ArrayField>
                            <br />
                            <ArrayField source="geometriaSegmentos" label="resources.otro_detalles.fields.geometriaTransversal" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <PercentageField source="geometriaTransversal" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmentos" label="resources.otro_detalles.fields.geometriaRadioMi" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}}  formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaRadioMi" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmentos" label="resources.otro_detalles.fields.geometriaRadioMa" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaRadioMa" />
                                </SingleFieldList>
                            </ArrayField>
                            <br />
                            <ArrayField source="geometriaSegmentos" label="resources.otro_detalles.fields.geometriaRadioCurvatura" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}}  formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaRadioCurvatura" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmentos" label="resources.otro_detalles.fields.geometriaVelDiseno" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaVelDiseno" />
                                </SingleFieldList>
                            </ArrayField>
                            <br />
                            <ArrayField source="geometriaSegmentos" label="resources.otro_detalles.fields.geometriaSeparador" linkType={false}  formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="geometriaSeparador" />
                                </SingleFieldList>
                            </ArrayField>
                {controllerProps.record && controllerProps.record.geometriaSegmentos[0].geometriaSeparador === translate('dynatest.resources.otro_detalles.select.geometriaSeparador.Si')  &&
                            <ArrayField source="geometriaSegmentos" label="resources.otro_detalles.fields.geometriaSeparadorAncho" fullWidth linkType={false} style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaSeparadorAncho" />
                                </SingleFieldList>
                            </ArrayField>
                }
                            <br />
                            <ArrayField source="geometriaSegmentos" label="resources.otro_detalles.fields.geometriaCondicionesGeometrica" linkType={false}  formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="geometriaCondicionesGeometrica" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmentos" label="resources.otro_detalles.fields.geometriaAlineamiento" linkType={false}  formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="geometriaAlineamiento" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmentos" label="resources.otro_detalles.fields.geometriaTipoTerreno" linkType={false}  formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="geometriaTipoTerreno" />
                                </SingleFieldList>
                            </ArrayField>
                            <br />
                            <Divider fullWidth /><Divider fullWidth /><Divider fullWidth />
                            <ArrayField source="geometriaSegmentos" label="resources.otro_detalles.fields.detallesGeometria" linkType={false}  formClassName={classes.grid_cont12}>
                                <SingleFieldList>
                                    <TextField source="detallesGeometria"   formClassName={classes.grid_cont12} />
                                </SingleFieldList>
                            </ArrayField>
                            
                        </FormTab>
                        <FormTab label="dynatest.resources.otro_detalles.tabs.estructura">
                            <br />
                            <Typography variant="b" component="b">{translate('resources.otro_detalles.fields.informacionEstructura')}</Typography>
                            <Divider fullWidth /><Divider fullWidth /><Divider fullWidth />
                            <ArrayField source="tablaSegmentos" style={{ width: '100%' }} label=" " linkType={false} className="inventariosSegmentos" formClassName={classes.grid_cont12}>
                                <Datagrid>
                                    <NumberField source="estructuraEspesor" label="resources.otro_detalles.fields.estructuraEspesor" />
                                    <NumberField source="estructuraModulo" label="resources.otro_detalles.fields.estructuraModulo" />
                                    <NumberField source="estructuraDs" label="resources.otro_detalles.fields.estructuraDs" />
                                    <TextField label="resources.otro_detalles.fields.material" source="material_.material" />
                                    <TextField label="resources.otro_detalles.fields.materialEsp" source="material_.especificacion" />
                                    <TextField label="resources.otro_detalles.fields.granulometria" source="granulometria_.granulometria" />
                                </Datagrid>
                            </ArrayField>
                            <br />
                            <TextField label="resources.otro_detalles.fields.espesorTotal" source="espensorTotal" formClassName={classes.grid_cont3} />
                            <br />
                            <ArrayField source="estructuraSegmentos" label="resources.otro_detalles.fields.iggEstructura" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="igg" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="estructuraSegmentos" label="resources.otro_detalles.fields.conceito" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="conceito" />
                                </SingleFieldList>
                            </ArrayField>
                            <br/>
                            <div className={classes.subtittle}><strong>{translate('resources.otro_detalles.fields.capacidadEstructural')}</strong></div>
                            <br />
                            <Divider fullWidth /><Divider fullWidth /><Divider fullWidth />
                            <ArrayField source="estructuraSegmentos" label="resources.otro_detalles.fields.estructuraNumDiseno" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="estructuraNumDiseno" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="estructuraSegmentos" label="resources.otro_detalles.fields.estructuraFecha" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <DateField source="estructuraFecha" />
                                </SingleFieldList>
                            </ArrayField>
                            <br />
                            <ArrayField source="estructuraSegmentos" label="resources.otro_detalles.fields.estructuraNumero" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="estructuraNumero" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="estructuraSegmentos" label="resources.otro_detalles.fields.estructuraNumeroFecha" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <DateField source="estructuraNumeroFecha" />
                                </SingleFieldList>
                            </ArrayField>
                            <br />
                            <ArrayField source="estructuraSegmentos" label="resources.otro_detalles.fields.estructuraCbr" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <PercentageField source="estructuraCbr" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="estructuraSegmentos" label="resources.otro_detalles.fields.estructuraCbrFecha" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <DateField source="estructuraCbrFecha" />
                                </SingleFieldList>
                            </ArrayField>
                            <br />
                            <strong>{translate('resources.otro_detalles.fields.fechasImportantes')}</strong>
                            <br />
                            <ArrayField source="estructuraSegmentos" label="resources.otro_detalles.fields.estructuraFechaConst" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <DateField source="estructuraFechaConst" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="estructuraSegmentos" label="resources.otro_detalles.fields.estructuraFechaRehab" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <DateField source="estructuraFechaRehab" />
                                </SingleFieldList>
                            </ArrayField>
                            <br />
                            <Divider fullWidth /><Divider fullWidth /><Divider fullWidth />
                            <ArrayField source="estructuraSegmentos" label="resources.otro_detalles.fields.detallesEstructura" linkType={false} formClassName={classes.grid_cont12}>
                                <SingleFieldList>
                                    <TextField source="detallesEstructura" />
                                </SingleFieldList>
                            </ArrayField>
                        </FormTab>

                        <FormTab label="dynatest.resources.otro_detalles.tabs.auditoria">
                            <DateField source="createdAt" label="resources.otro_detalles.fields.createdAt" fullWidth formClassName={classes.grid_cont3} showTime/>
                            <TextField source="createdBy" label="resources.otro_detalles.fields.createdBy" fullWidth formClassName={classes.grid_cont3} />
                            <TextField source="createdIn" label="resources.otro_detalles.fields.createdEn" fullWidth formClassName={classes.grid_cont3} />
                            <span></span>
                            <DateField source="updatedAt" label="resources.otro_detalles.fields.updatedAt" fullWidth formClassName={classes.grid_cont3} showTime/>
                            <TextField source="updatedBy" label="resources.otro_detalles.fields.updatedBy" fullWidth formClassName={classes.grid_cont3} />
                            <TextField source="updatedIn" label="resources.otro_detalles.fields.updatedEn" fullWidth formClassName={classes.grid_cont3} />
                            <span></span>
                        </FormTab>



                    </TabbedForm>
                </Show>
            }
        </ShowController>
    )
}));