import React, {useState} from 'react';
import {Filter,List, Datagrid, TextField, EditButton, ShowButton, BulkDeleteWithConfirmButton, ReferenceInput, 
    AutocompleteInput,SelectInput, Loading
} from 'react-admin';
import { Fragment } from 'react';
import { PField } from './../../fields/PField';
import { translate } from 'react-admin';
import { CardActions, CreateButton, ExportButton } from 'react-admin';
const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);
const ListAction = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
        <ExportButton basePath={basePath}/>
    </CardActions>
);
const ListAction2 = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
    </CardActions>
);
const Filtros= translate((props) => {
    const { translate } = props;
    const [uf, setUf] = useState('none');
    const ufSet = (value) => {
        setUf(value);
    }
    const [tramo, setTramo] = useState('none');
    const tramoSet = (value) => {
        setTramo(value);
    }
        
    return(
    <Filter {...props}>
       <ReferenceInput 
            id="unidadFuncional"
            label="resources.tuneles.fields.unidadFuncional" 
            source="unidadFuncional"  
            reference="unidad_funcionals" 
            filterToQuery={searchText => ({ codigo: searchText })} 
            sort={{field : 'codigo', order : 'asc'}}
            validate={[ufSet]}
            alwaysOn
            fullWidth 
        >
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
        <ReferenceInput 
            label="resources.tuneles.fields.tramo" 
            source="tramo" 
            alwaysOn 
            reference="tramos" 
            filter={{ unidadFuncional: uf}}
            validate={[tramoSet]}
            filterToQuery={searchText => ({ codigo: searchText })} 
            sort={{ 
                field: 'codigo', 
                order: 'ASC' ,
                
            }}>
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
        
        <ReferenceInput 
            label="resources.tuneles.fields.segmento" 
            source="segmento" 
            reference="segmentos" 
            alwaysOn 
            sort={{ field: 'codigo', order: 'ASC' }}
            filter={{tramo : tramo}}
            filterToQuery={searchText => ({ codigo: searchText })} 
        >
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
          
        <SelectInput label="resources.tuneles.fields.estados" source="estados" choices={[
                { id: translate('dynatest.resources.tuneles.select.estados.Activo'), name: "dynatest.resources.tuneles.select.estados.Activo" },
                { id: translate('dynatest.resources.tuneles.select.estados.Inactivo'), name: "dynatest.resources.tuneles.select.estados.Inactivo" },
            ]}  fullWidth alwaysOn
        />
            
    </Filter>
    )
});

export const TunelList = ({ permissions, ...props }) => {
    if (!permissions) return <Loading />;  
    return (
        <List {...props}  
            sort={{ field: 'updatedAt', order: 'DESC' }} 
            filters={<Filtros/>} 
            actions={permissions['tunel']  == 1 ? <ListAction /> : permissions['tunel']  == 2 ? <ListAction2 />: null}
            bulkActionButtons={permissions['tunel']  == 1 ? <PostBulkActionButtons />: null}>
            <Datagrid>
                <TextField label="resources.tuneles.fields.smartCode" source="fullCodigoConsecutivo"/>
                <TextField label="resources.tuneles.fields.clasificacionList" source="clasificacion" />
                <TextField label="resources.tuneles.fields.sentidoList" source="sentido"  />
                <PField label="resources.tuneles.fields.pInicio" source="pInicio"/>
                <TextField label="resources.tuneles.fields.estados" source="estados" sortable />
                {permissions['tunel'] == 1 || permissions['tunel'] == 2 || permissions['tunel'] == 4 ? <ShowButton/>: null}
                {permissions['tunel'] == 1 || permissions['tunel'] == 2 ? <EditButton/>: null}                  
            </Datagrid>
        </List>
)};
