import React, { Fragment, useState } from 'react';
import { List, Datagrid, Toolbar, SelectInput, BooleanInput, ReferenceInput, TextField, SimpleForm, EditButton, CardActions, RadioButtonGroupInput, ShowButton } from 'react-admin';
import { BulkDeleteWithConfirmButton, required, Loading, CreateButton, SaveButton } from 'react-admin';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import VisibilityIcon from '@material-ui/icons/Visibility';
import SendIcon from '@material-ui/icons/Send';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import config from '../../../../config/config';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core';
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import ListColores from '../colores/color/ListColores';
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import Modal from '@material-ui/core/Modal';
import { translate } from 'react-admin';
let urlAppjs = config.UrlServer;

const styles = {
     
};

// const useStyles = makeStyles(theme) => ({
//     appBar: {
//       position: "relative"
//     },
//     title: {
//       marginLeft: theme.spacing(2),
//       flex: 1
//     }
//   }));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
function FullScreenDialog(props) {
    const { onClose, selectedValue, open } = props;
    const { translate } = props;
    // const classes = useStyles();
    // const [open1,setOpen1] = React.useState("none");
    // const [abrir, setAbrir] = React.useState();
    // const [cerrar, setCerrar] = React.useState();

    const handleClose = () => {
        onClose(selectedValue);
    };
    //   const handleOpen = () => {
    //     if(open1 === ""){   
    //     setOpen1("none");
    //     }
    //     else{
    //         setOpen1("");
    //     }
    // };
    // const checkout1 = () => {
    //     if(abrir === "" || abrir === false){   
    //     setAbrir(true);
    //     setCerrar(false);
    //     }
    //     else{
    //         setAbrir(false);
    //         setCerrar(true);
    //     }
    // };
    // const checkout2 = () => {
    //     if(cerrar === "" || cerrar === false){   
    //     setCerrar(true);
    //     setAbrir(false);
    //     }
    //     else{
    //         setCerrar(false);
    //         setAbrir(true);
    //     }
    // };
    //     const handleListItemClick = value => {
    //         this.setState({ record: this.props.record })
    //         //this.setState({ tipo: this.props.edit })}
    //         fetch(urlAppjs+"/controladorColorimetria")
    //         .then((response) => {
    //             return response.json()
    //         })
    //         .then((colores) => {
    //             this.setState({ colores: colores })
    //             onClose(value);
    //         })

    //     };
    // const handleClickOpen = () => {
    //     setOpen(true);
    // };
    const toolbarStyles = {
        toolbar: {
            display: 'flex',
            justifyContent: 'space-between',
        },
    };
    const CustomToolbar = withStyles(toolbarStyles)(props => (
        <Toolbar {...props}>
            <SaveButton style={{ display: "none" }} />

        </Toolbar>

    ));
    //   const CustomToolbar = ({ basePath, data,...props}) => (
    //       <Toolbar {...props} >
    //           <Button variant="secondary" id="generarInforme" onClick={handleListItemClick2} style={{backgroundColor: '#3f51b5', color:'#fff'}}>
    //           <SendIcon/>  Guardar 
    //           </Button>
    //       </Toolbar>
    //   )

    return (
        <div>
            <Dialog onClose={handleClose} TransitionComponent={Transition} aria-labelledby="simple-dialog-title" open={open} maxWidth={'xl'}  >
                <AppBar style={{ backgroundColor: '#3f51b5', position: "relative" }}>
                    <Toolbar style={{ backgroundColor: '#3f51b5' }}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography color="inherit" component="h2" variant="title" style={{ textAlign: "center" }} > {translate('dynatest.resources.colores.title.inforDiagColor')}</Typography>
                    </Toolbar>
                </AppBar>
                <SimpleForm {...props} toolbar={<CustomToolbar />}>
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <WithProps>{({ record, ...props }) =>
                                <ListColores record={record} translate={translate} />}
                            </WithProps>
                        </Grid>
                    </Grid>
                </SimpleForm>
            </Dialog>
        </div>
    );
}

const WithProps = ({ children, ...props }) => children(props);

FullScreenDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props} />
    </Fragment>
);

const PostEditActions = translate(({ basePath, data, resource, ...props }) => {
    const { translate } = props;
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = value => {
        setOpen(false);
    };
    return (
        <CardActions>
            <CreateButton basePath={basePath} style={{ paddingTop: "13px", marginRight: "20px" }} />
            <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                <CheckIcon /> {translate('dynatest.resources.colores.title.diagrama')}
            </Button>
            <FullScreenDialog open={open} onClose={handleClose} translate={translate}/>
        </CardActions>
    );

});
const PostEditActions2 = translate(({ basePath, data, resource, ...props }) => {
    const { translate } = props;
    return (
        <CardActions>
            <CreateButton basePath={basePath} style={{ paddingTop: "13px", marginRight: "20px" }} />
        </CardActions>
    );

});
export const ColorList = translate(withStyles(styles)(({ classes,permissions, ...props }) => {
    const { translate } = props;
    const choices = [
        { id: 'programming', name: 'Programming' },
        { id: 'lifestyle', name: 'Lifestyle' },
        { id: 'photography', name: 'Photography' },
    ]
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = value => {
        setOpen(false);
    };
    if (!permissions) return <Loading />;

    return (
        <List {...props} 
            sort={{ field: 'id', order: 'DESC' }} 
            actions={permissions['diagramaColorimetrico']  == 1 ? <PostEditActions /> : permissions['diagramaColorimetrico']  == 2 ? <PostEditActions2 />: null} 
            bulkActionButtons={permissions['diagramaColorimetrico'] == 1 ? <PostBulkActionButtons /> : null}>
            <Datagrid>
                <TextField source="color" fullWidth />
                {/* <RadioButtonGroupInput label="Botones" source="botones"  choices={choices} /> */}
                {permissions['diagramaColorimetrico'] == 1 || permissions['diagramaColorimetrico'] == 2 || permissions['diagramaColorimetrico'] == 4 ? <ShowButton/>: null}
                {permissions['diagramaColorimetrico'] == 1 || permissions['diagramaColorimetrico'] == 2 ? <EditButton/>: null}    
            </Datagrid>
        </List>
    )
}));

