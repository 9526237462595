import React, {useState} from 'react';
import { Query,TextInput, SelectInput, ReferenceInput, AutocompleteInput} from 'react-admin';
import { withStyles } from '@material-ui/core';
import { PimsCreate } from './../../../Create';
import { required, maxLength } from 'react-admin';
import { SimpleForm } from './../../../SimpleForm';
import UniqueGranulometry from './../../../componentes/UniqueGranulometry';

const styles = {
    first_field: { display: 'inline-block', width: '30%' },
    last_field: { display: 'inline-block', marginLeft: 32, width: '30%' }    
};

export const GranulometriaCreate = withStyles(styles)(({ classes, ...props }) => {

    const [filtrom , setFiltrom]= useState('');

    const tipoMaterial = (value) => {
        setFiltrom(value)
    }

    const Material = { 'tipo': filtrom };

    const choises = [
        { id: '1', name: 'dynatest.resources.granulometrias.select.tipoMaterial.adoquinEnArcilla' },
        { id: '2', name: 'dynatest.resources.granulometrias.select.tipoMaterial.amadoquinDeConcreto' },
        { id: '3', name: 'dynatest.resources.granulometrias.select.tipoMaterial.afirmado' },
        { id: '4', name: 'dynatest.resources.granulometrias.select.tipoMaterial.baseAsfaltica' },
        { id: '5', name: 'dynatest.resources.granulometrias.select.tipoMaterial.baseDeConcHidraulico' },
        { id: '6', name: 'dynatest.resources.granulometrias.select.tipoMaterial.baseEstOtros' },
        { id: '7', name: 'dynatest.resources.granulometrias.select.tipoMaterial.baseEstbAsfalto' },
        { id: '8', name: 'dynatest.resources.granulometrias.select.tipoMaterial.baseEstEmulsion' },
        { id: '9', name: 'dynatest.resources.granulometrias.select.tipoMaterial.baseGranular' },
        { id: '10', name: 'dynatest.resources.granulometrias.select.tipoMaterial.baseTratada' },
        { id: '11', name: 'dynatest.resources.granulometrias.select.tipoMaterial.concHidraulico' },
        { id: '12', name: 'dynatest.resources.granulometrias.select.tipoMaterial.lechAsfaltica' },
        { id: '13', name: 'dynatest.resources.granulometrias.select.tipoMaterial.mezAbierCaliente' },
        { id: '14', name: 'dynatest.resources.granulometrias.select.tipoMaterial.mezAbierFrio' },
        { id: '15', name: 'dynatest.resources.granulometrias.select.tipoMaterial.mezAsfalGraCaucho' },
        { id: '16', name: 'dynatest.resources.granulometrias.select.tipoMaterial.mezAsfalModPolimeros' },
        { id: '17', name: 'dynatest.resources.granulometrias.select.tipoMaterial.mezDenCaliente' },
        { id: '18', name: 'dynatest.resources.granulometrias.select.tipoMaterial.mezDenFrio' },
        { id: '19', name: 'dynatest.resources.granulometrias.select.tipoMaterial.mezDrenante' },
        { id: '20', name: 'dynatest.resources.granulometrias.select.tipoMaterial.microagloCaliente' },
        { id: '21', name: 'dynatest.resources.granulometrias.select.tipoMaterial.subBaseAsfalEspumado' },
        { id: '22', name: 'dynatest.resources.granulometrias.select.tipoMaterial.subBaseEstabOtros' },
        { id: '23', name: 'dynatest.resources.granulometrias.select.tipoMaterial.subBaseEstabAsfalto' },
        { id: '24', name: 'dynatest.resources.granulometrias.select.tipoMaterial.subBaseEstabCemento' },
        { id: '25', name: 'dynatest.resources.granulometrias.select.tipoMaterial.subBaseEstabGranular' },
        { id: '26', name: 'dynatest.resources.granulometrias.select.tipoMaterial.subraEstabCal' },
        { id: '27', name: 'dynatest.resources.granulometrias.select.tipoMaterial.subraEstabCemento' },
        { id: '28', name: 'dynatest.resources.granulometrias.select.tipoMaterial.subraEstabGeomalla' },
        { id: '29', name: 'dynatest.resources.granulometrias.select.tipoMaterial.subraEstabGeotextil' },
        { id: '30', name: 'dynatest.resources.granulometrias.select.tipoMaterial.subraFina' },
        { id: '31', name: 'dynatest.resources.granulometrias.select.tipoMaterial.subraGranular' },
        { id: '32', name: 'dynatest.resources.granulometrias.select.tipoMaterial.subraCemento' },
    ];
    const [especificacion, setEspecificacion] = useState('');
    const validateEspecificacion = (value) =>{
        let  especificacion = [
            "",
            "",
            "INV-311-13",
            "INV-450-13",
            "INV-505-13",
            "INV-13",
            "",
            "INV-340-13",
            "INV-330-13",
            "INV-351-13",
            "INV-500-13",
            "INV-433-13",
            "INV-451-13",
            "INV-441-13",
            "INV-413-13",
            "INV-414-13",
            "INV-450-13",
            "INV-440-13",
            "INV-453-13",
            "INV-452-13",
            "",
            "",
            "",
            "",
            "INV-320-13",
            "INV-236-13",
            "INV-235-13",
            "INV-233-13",
            "INV-232-13",
            "",
            "",
            "INV-350-13"
            
        ];
        setEspecificacion(especificacion[parseInt(value)-1]);
    } 
    console.log(especificacion)
    const [materialCodigo, setNombreMaterial] = useState('')
    const EspecificacionMaterial = (value) =>{
        setNombreMaterial(value)
    } 
    return(
        <PimsCreate {...props} >
            <SimpleForm redirect="list" >
            <SelectInput source="tipo" choices={choises} fullWidth validate={[required(),validateEspecificacion]}  formClassName={classes.first_field}/>
                {/* <SelectInput source=" " label="Tipo de Material" reference="materials" choices={choises} fullWidth alwaysOn validate={[tipoMaterial,validateEspecificacion]} formClassName={classes.first_field}/> */}
                
                {/* <ReferenceInput 
                    label="Especificación" 
                    source="material" 
                    reference="materials" 
                    formClassName={classes.first_field} 
                    validate={[required(), EspecificacionMaterial]}
                    filter={Material} 
                    sort={{field : 'especificacion', order: 'ASC'}}
                    filterToQuery={searchText => ({ especificacion: searchText })} 
                    formClassName={classes.first_field}
                >
                    <AutocompleteInput optionText="especificacion" />
                </ReferenceInput>      */}
               <TextInput source="especificacion" fullWidth validate={[maxLength(20),EspecificacionMaterial]} defaultValue={especificacion}  formClassName={classes.last_field}/>  
                <UniqueGranulometry 
                        id="granulometria"
                        label="resources.granulometrias.fields.granulometria" 
                        source="granulometria" 
                        operation="default"
                        style={{width: '100%'}} 
                        fullWidth 
                        formClassName={classes.last_field}
                    />

                {/* {                                    
                    <Query type="GET_ONE" resource="materials" payload={{ id: materialCodigo}} formClassName={classes.last_field} >
                        {({ data, loading, error }) => {                                                
                            if( data){
                                return <TextInput style={{display:'none'}}   label="Nombre Material" defaultValue={data.tipo} source="especificacion" fullWidth validate={[required()]}/>;
                            }
                            return <div></div>;                                              
                        }}
                    </Query>     
                    //                      
                } */}
            </SimpleForm>
        </PimsCreate>
)});