import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { TabbedForm } from './../TabbedForm';
import {
  required, FormTab, Button, Title, TextInput, FileInput, FileField, SelectInput, SimpleForm, FormDataConsumer
} from 'react-admin';
import { PimsCreate } from '../Create';
import { styles } from '../../EstilosGrid';
import { withStyles } from '@material-ui/core';
import { CargueIdioma } from '../files/CargueIdioma';
import { translate } from 'react-admin';
import config from '../../config/config';
import { saveAs } from 'file-saver';
import Dropzone from "react-dropzone";
let urlAppjs = config.UrlServer;

export const IdiomaCreate = translate(withStyles(styles)(({ classes, basePath, ...props }) => {
  const { translate } = props;

  const UrlField = ({ record, source }) =>
    <a href="idiomas" style={{ textDecoration: "none" }}>
      <Button label="resources.idiomas.fields.descargar" onClick={() => { createFile() }} type="submit" formClassName={classes.grid_cont2s} variant="outlined" color="primary"></Button>
    </a>;
    

  const createFile = () => {
    console.log("Succes Button")
    const blob = new Blob(['...'], { type: 'text/plain;charset=utf-8' })
    saveAs(blob, 'nuevofile.txt')

  }

  const handleDrop = acceptedFiles => {
    console.log(acceptedFiles, "formatos de archivos")
  };

  return (
    <PimsCreate {...props}>
      <SimpleForm redirect="list" basePath='/idiomas'>
        <Typography component="h2" variant="title" >
          {translate('dynatest.resources.idiomas.formTabs.seleccionIdiomaBase')}
        </Typography>
        <TextInput source="cargueIdioma" style={{ display: 'none' }} defaultValue={"success"} fullWidth />
        <TextInput source="estado" style={{ display: 'none' }} defaultValue={"Activo"} fullWidth />
        <TextInput label="resources.idiomas.fields.idioma" source="idiomaBase" formClassName={classes.grid_cont4} fullWidth validate={[required()]} />
        <TextInput label="resources.idiomas.fields.region" source="region" formClassName={classes.grid_cont4} fullWidth validate={[required()]} />
        <TextInput label="resources.idiomas.fields.nombreIdioma" source="nombreIdioma" formClassName={classes.grid_cont4} fullWidth validate={[required()]} />
        {/* <SelectInput source="estado" formClassName={classes.grid_cont4} sort={{ field: 'estado', order: 'ASC' }} choices={[
          { id: 'Activo', name: 'Activo' },
          { id: 'Inactivo', name: 'Inactivo' },
        ]} fullWidth validate={[required()]} /> */}
        <UrlField variant="outlined" color="primary" />
        <br></br>
        <Typography component="h2" variant="title" >{translate('dynatest.resources.idiomas.formTabs.cargueArchivos')}</Typography>
        <br></br>
        {/* <Dropzone onDrop={handleDrop} uploadMultiple={false} createImageThumbnails='true'
          fullWidth >
          {({ acceptedFiles, getRootProps, getInputProps }) => (
            <div {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} />
              <p>Arrastra un archivo para empezar la subida, o presiona aquí para seleccionarlo.</p>
            </div>
          )}
        </Dropzone> */}
        <br></br>
        <FileInput source="manual_usuario" accept=".pdf" multiple={false} >
          <FileField source="src" title="title" />
        </FileInput>
        {/* <FileInput label="dynatest.resources.idiomas_config.modulo_administracion" source="modulo_administracion" accept=".js" multiple={false} >
          <FileField source="src" title="title" />
        </FileInput> */}
        {/* <FileInput source="modulo_inventario" accept=".csv" multiple={false} >
          <FileField source="src" title="title" />
        </FileInput> */}
      </SimpleForm>
    </PimsCreate>
  )
}));