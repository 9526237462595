import React, { useState } from 'react';
import {
  DateInput, required, TabbedForm, FormTab, AutocompleteInput, SelectInput,
  ReferenceInput, SimpleFormIterator, ArrayInput, TextInput, FormDataConsumer, SelectArrayInput, NumberInput
} from 'react-admin';
import { Edit } from './../Edit';
import { withStyles } from '@material-ui/core';
import { styles } from './../../EstilosGrid';
import Grid from '@material-ui/core/Grid';
import { PRInput } from './../../inputs/PRInput';
import { minValue, maxValue, minLength, maxLength } from 'ra-core';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import PesoTotal from './preguntasRespuestas/complementoPesoTotal';
import AbsSegmento from '../AbsSegmento';
import {
  validarUnDecimal, validarDosDecimales, abValidationFin, validacionmaxfechaact, isFechaInvalida, Entero,
  validarLogicaAbsFinCreate, validarAbsEntreSegmento, latMininima, lngMininima
} from '../validacionesInputs';
import { PimsElementoActions } from './../../helpers/PimsElementoActions';
import { translate } from 'react-admin';

export const RespuestaEvaluacionNovedadesEdit = translate(withStyles(styles)(({ classes, ...props }) => {
  const { translate } = props;
  const { pesoTotal } = props;
  /* NEW EDER */
  const [prValidador, setPrValidador] = useState('');
  const [AbValidador, setAbValidador] = useState('');
  const [sistema, setSistema] = useState(null);
  const ValidarSistema = (value) => {
    let lenghtObject = Object.keys(value);
    let valor = '';
    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setSistema(value);
  }

  const [elemento, setElemento] = useState('');

  const [tipo_elementos, setTipoElementos] = useState('');
  const [tipo_acceso, setTipoAcceso] = useState('');

  const ValidarTipoElementos = (value) => {
    let lenghtObject = Object.keys(value);
    let valor = '';
    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setTipoElementos(value);
  }

  const ValidarSegmento = (value) => {
    let lenghtObject = Object.keys(value);
    let valor = '';
    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setSegmento(value)
  }

  const [unidads, setUnidad] = useState('');
  const ValidarUnidad = (value) => {
    //let lenghtObject = Object.keys(value);
    //let valor = '';
    //for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setUnidad(value)
  }

  const [tramos, setTramo] = useState('');

  const ValidarTramo = (value) => {
    let lenghtObject = Object.keys(value);
    let valor = '';
    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setTramo(value)
  }
  const [segmentos, setSegmento] = useState('');

  /*OPCIONES PARA TIPO DE IMÁGENES*/
  const [opcionTipoImagen, setOpcionTipoImagen] = useState([{ id: 'Cargando...', name: 'Cargando...' }]);
  const OpcionTipoImagen = (value) => {
    if (value !== '/tipo_elementos/DESC') {
      setOpcionTipoImagen([
        { id: '/tipo_images/1', name: 'Panorámica' },
        { id: '/tipo_images/7', name: 'Elemento completo' },
        { id: '/tipo_images/8', name: 'Detalle 1' },
        { id: '/tipo_images/9', name: 'Detalle 2' },
        { id: '/tipo_images/5', name: 'Deterioro 1' },
        { id: '/tipo_images/6', name: 'Deterioro 2' },
      ])
    }
  }
  const Unidades = { unidadFuncional: unidads };
  const Tramoss = { tramo: tramos };

  const ValidarElemento = (value) => {
    setElemento(value)
    //debugger
  }

  const [areaAfect, setAreaAfect] = useState('');
  const [severidad, setSeveridad] = useState('');
  const ValidarArea = (value) => {
    setAreaAfect(value.areaAfectada);
    setSeveridad(value.severidad);
  }

  const [tipoRegistro, setTipoRegistro] = useState('');
  const ValidarTipoRegistro = (value) => {
    setTipoRegistro(value);
  }

  /*VALIDAR IMÁGENES ACTUALES */
  const [imagenesOpen, setImagenesOpen] = useState('');
  const ImagenesEdit = (value) => {
    setImagenesOpen(value);

  }

  const [latMin, setLatMin] = useState('');
  const LatMin = (value) => {
    setLatMin(value)
  }

  const [lngMin, setLngMin] = useState('');
  const LngMin = (value) => {
    setLngMin(value)
  }

  return (

    <Edit title="dynatest.resources.respuesta_evaluacion_novedads.title.editTitle"  {...props} actions={<PimsElementoActions />}>

      <TabbedForm redirect="list" >
        {/* ......:::.... Filtro ......:::....*/}
        <FormTab label="dynatest.resources.respuesta_evaluacion_novedads.tabs.filtro"  >
          <TextInput source="tipoRegistro" style={{ display: 'none' }} validate={ValidarTipoRegistro} />
          <Grid container spacing={24} >
            <br />
            <Grid item xs={4}>
              <ReferenceInput label="resources.respuesta_evaluacion_novedads.fields.sistema" source="sistema" reference="sistemas" filter={{ id: ["ALR", "CLZ", "DRE", "OTR", "PTS", "RDS", "SEG", "SEN"] }} fullWidth validate={[required(), ValidarTipoElementos, ValidarSistema]}>
                <SelectInput optionText="nombre" disabled />
              </ReferenceInput>
            </Grid>
            {(sistema != null) && (
              <Grid item xs={4}>
                {(sistema == '/sistemas/OTR') ?
                  <ReferenceInput label="resources.respuesta_evaluacion_novedads.fields.tipoElemento" source="tipoElemento" reference="tipo_elementos" fullWidth filter={{ puntos: 1, sistema: "OTR" }} validate={[required()]} sort={{ field: 'nombre', order: 'asc' }}>
                    <SelectInput optionText="nombre" disabled />
                  </ReferenceInput>
                  :
                  (sistema == '/sistemas/SEN') ?
                    <ReferenceInput label="resources.respuesta_evaluacion_novedads.fields.tipoElementoSenal" source="tipoElementoSenal" reference="tipo_elemento_senals" fullWidth validate={[required(), ValidarElemento]} sort={{ field: 'nombre', order: 'asc' }}>
                      <SelectInput optionText="nombre" disabled />
                    </ReferenceInput>
                    :
                    <ReferenceInput label="resources.respuesta_evaluacion_novedads.fields.tipoElemento" source="tipoElemento" reference="tipo_elementos" fullWidth filter={{ sistema: tipo_elementos }} validate={[required()]} sort={{ field: 'nombre', order: 'asc' }}>
                      <SelectInput optionText="nombre" disabled />
                    </ReferenceInput>
                }
              </Grid>
            )}
            {(tipoRegistro === "Medición") &&
              (
                <Grid item xs={4}>
                  <TextInput
                    label="resources.respuesta_evaluacion_novedads.fields.tipoRegistro"
                    source="tipoRegistro"
                    disabled
                    fullWidth
                  />
                </Grid>
              )
            }
            <Grid item xs={12}>
              <Typography variant="h3" component="h3"><b>{translate('dynatest.resources.respuesta_evaluacion_novedads.subTitle.localizacion')}</b></Typography>
              <br />
              <Divider fullWidth style={{ marginTop: '10px' }} />
            </Grid>
            <Grid item xs={4}>
              <ReferenceInput
                label="resources.respuesta_evaluacion_novedads.fields.unidadFuncional"
                source="unidadFuncional"
                reference="unidad_funcionals"
                disabled
                filterToQuery={searchText => ({ codigo: searchText })}
                sort={{ field: 'codigo', order: 'asc' }}
                validate={[required(), ValidarUnidad]}
                fullWidth
              >
                <AutocompleteInput options={{ fullWidth: true, disabled: true }} optionText="fullCodigos" autoComplete="new-password" />
              </ReferenceInput>
            </Grid>
            <Grid item xs={4}>
              <ReferenceInput
                label="resources.respuesta_evaluacion_novedads.fields.tramo"
                source="tramo"
                reference="tramos"
                filter={Unidades}
                filterToQuery={searchText => ({ codigo: searchText })}
                sort={{ field: 'codigo', order: 'ASC', }}
                validate={[required(), ValidarTramo]}
                formClassName={classes.grid_cont4_s}
              >
                <AutocompleteInput options={{ fullWidth: true, disabled: true }} optionText="fullCodigos" />
              </ReferenceInput>
            </Grid>
            <Grid item xs={4}>
              <ReferenceInput
                label="resources.respuesta_evaluacion_novedads.fields.segmento"
                source="segmento"
                reference="segmentos"
                sort={{ field: 'codigo', order: 'ASC' }}
                filter={Tramoss}
                filterToQuery={searchText => ({ codigo: searchText })}
                validate={[required(), ValidarSegmento]}
                formClassName={classes.grid_cont4_s}
              >

                <AutocompleteInput options={{ fullWidth: true, disabled: true }} optionText="fullCodigos" />
              </ReferenceInput>
            </Grid>
            {(tipoRegistro !== "Medición") && (
              <Grid item xs={12}>
                <Typography variant="b" component="b"><b>{translate('dynatest.resources.respuesta_evaluacion_novedads.subTitle.coordIniciales')}</b></Typography>
                <br />
                <Divider fullWidth style={{ marginTop: '10px' }} />
              </Grid>)}
            {(tipoRegistro !== "Medición") && (
              <Grid item xs={4}>
                <PRInput
                  label="resources.respuesta_evaluacion_novedads.fields.abscisaIni" id="ingresoAbIni"
                  source="abscisaIni"
                  disabled
                  fullWidth /* onChange={NoSobreponerAbs} */
                /* validate={[abValidationFin, required()]}*/ />
              </Grid>)}
            {(tipoRegistro !== "Medición") && (
              <Grid item xs={4}>
                <NumberInput
                  label="resources.respuesta_evaluacion_novedads.fields.latitudInicial"
                  source="latitudInicial"
                  disabled
                  fullWidth
                  validate={[required(), LatMin, latMininima]} />
              </Grid>)}
            {(tipoRegistro !== "Medición") && (
              <Grid item xs={4}>
                <NumberInput
                  label="resources.respuesta_evaluacion_novedads.fields.longitudInicial"
                  source="longitudInicial"
                  disabled
                  fullWidth
                  validate={[required(), LngMin, lngMininima]} />
              </Grid>)
            }

            <FormDataConsumer style={{ width: '33%' }}>
              {
                ({ formData, scopedFormData, getSource, ...rest }) => (formData != null &&
                  formData.ubicacionMapa != null && formData.ubicacionMapa.length > 1 &&

                  <Grid item xs={12}>
                    <Typography variant="b" component="b"><b>{translate('dynatest.resources.respuesta_evaluacion_novedads.subTitle.coordFinales')}</b></Typography>
                    <br />
                    <Divider fullWidth style={{ marginTop: '10px' }} />
                  </Grid>
                )
              }
            </FormDataConsumer>
            <FormDataConsumer style={{ width: '33%' }}>
              {
                ({ formData, scopedFormData, getSource, ...rest }) => (formData != null &&
                  formData.ubicacionMapa != null && formData.ubicacionMapa.length > 1 &&
                  <Grid item xs={4}>
                    <PRInput
                      label="resources.respuesta_evaluacion_novedads.fields.abscisaFin"
                      id="ingresoAbFin"
                      source="abscisaFin"
                      disabled
                      fullWidth /* onChange={NoSobreponerAbs} */
                      /* validate={[abValidationFin]} */ />
                  </Grid>
                )
              }
            </FormDataConsumer>
            <FormDataConsumer style={{ width: '33%' }}>
              {
                ({ formData, scopedFormData, getSource, ...rest }) => (formData != null &&
                  formData.ubicacionMapa != null && formData.ubicacionMapa.length > 1 &&
                  <Grid item xs={4}>
                    <NumberInput
                      label="resources.respuesta_evaluacion_novedads.fields.latitudFinal"
                      source="latitudFinal"
                      fullWidth
                      disabled
                      validate={[LngMin, lngMininima]} />
                  </Grid>
                )
              }
            </FormDataConsumer>
            <FormDataConsumer style={{ width: '33%' }}>
              {
                ({ formData, scopedFormData, getSource, ...rest }) => (formData != null &&
                  formData.ubicacionMapa != null && formData.ubicacionMapa.length > 1 &&
                  <Grid item xs={4}>
                    <NumberInput
                      label="resources.respuesta_evaluacion_novedads.fields.longitudFinal"
                      source="longitudFinal"
                      disabled
                      fullWidth
                      validate={[LngMin, lngMininima]} />
                  </Grid>
                )
              }
            </FormDataConsumer>
          </Grid>
        </FormTab>
        {/* ......:::.... REGISTRO ......:::....*/}
        <FormTab label="dynatest.resources.respuesta_evaluacion_novedads.tabs.registro">
          {sistema != null && sistema === '/sistemas/CLZ' && elemento !== '/tipo_elemento_senals/SV' &&
            <TextInput label="resources.respuesta_evaluacion_novedads.fields.deterioro" source="respuestaNovedadSegmento_" style={{ display: 'none' }} validate={ValidarArea} />
          }
          <DateInput
            label="resources.respuesta_evaluacion_novedads.fields.fechaRegistro"
            source="fechaRegistro"
            disabled
            fullWidth
            formClassName={classes.grid_cont4_s}
            // validate={[validacionmaxfechaact, isFechaInvalida, required()]}
          />
 
          {sistema != null && sistema === '/sistemas/CLZ' && elemento !== '/tipo_elemento_senals/SV' &&
            <ReferenceInput
              label="resources.respuesta_evaluacion_novedads.fields.deterioro"
              source="respuestaNovedadSegmento_.deterioro"
              reference="tipo_deterioro_pers"
              filterToQuery={searchText => ({ codigo: searchText })}
              formClassName={classes.grid_cont4_s}
              fullWidth
            >
              <AutocompleteInput options={{ fullWidth: true, disabled: true }} optionText="nombre" />
            </ReferenceInput>
          }
          {sistema != null && sistema === '/sistemas/CLZ' && elemento !== '/tipo_elemento_senals/SV' && severidad !== null && severidad !== "" &&
            <TextInput label="resources.respuesta_evaluacion_novedads.fields.severidad" source="respuestaNovedadSegmento_.severidad" fullWidth disabled formClassName={classes.grid_cont3_s} />
          }
          {sistema != null && sistema === '/sistemas/CLZ' && elemento !== '/tipo_elemento_senals/SV' &&
            <TextInput label="resources.respuesta_evaluacion_novedads.fields.longitud" source="respuestaNovedadSegmento_.longitud" fullWidth disabled formClassName={classes.grid_cont4_s} />
          }
          {sistema != null && sistema === '/sistemas/CLZ' && elemento !== '/tipo_elemento_senals/SV' && areaAfect != null && areaAfect !== "" &&
            <TextInput label="resources.respuesta_evaluacion_novedads.fields.ancho" source="respuestaNovedadSegmento_.ancho" fullWidth disabled formClassName={classes.grid_cont4_s} />
          }
          {sistema != null && sistema === '/sistemas/CLZ' && elemento !== '/tipo_elemento_senals/SV' && areaAfect != null && areaAfect !== "" &&
            <TextInput label="resources.respuesta_evaluacion_novedads.fields.areaAfectada" source="respuestaNovedadSegmento_.areaAfectada" fullWidth disabled formClassName={classes.grid_cont3_s} />
          }
          <br />
          <SelectInput label='resources.respuesta_evaluacion_novedads.fields.estado' fullWidth source='estado' formClassName={classes.grid_cont4_s} choices={[
            { id: translate('dynatest.resources.respuesta_evaluacion_novedads.select.estado.activo'), name: "dynatest.resources.respuesta_evaluacion_novedads.select.estado.activo" },
            { id: translate('dynatest.resources.respuesta_evaluacion_novedads.select.estado.proceso'), name: "dynatest.resources.respuesta_evaluacion_novedads.select.estado.proceso" },
            { id: translate('dynatest.resources.respuesta_evaluacion_novedads.select.estado.cerrado'), name: 'dynatest.resources.respuesta_evaluacion_novedads.select.estado.cerrado' }
          ]} />
          <TextInput label="resources.respuesta_evaluacion_novedads.fields.aprobacion" source="aprobacion" disabled fullWidth formClassName={classes.grid_cont4_s} />
          <TextInput label="resources.respuesta_evaluacion_novedads.fields.observaciones" source="observaciones" fullWidth formClassName={classes.grid_cont3_s} />
          <br></br>
          {sistema != null && sistema !== '/sistemas/CLZ' && elemento !== '/tipo_elemento_senals/SV' &&
            <span><b>{translate('dynatest.resources.respuesta_evaluacion_novedads.subTitle.resultados')}</b></span>
          }
          {sistema != null && sistema !== '/sistemas/CLZ' && elemento !== '/tipo_elemento_senals/SV' &&
            <PesoTotal pesoTotal={pesoTotal}/>
          }

          <br />
          {sistema != null && sistema !== '/sistemas/CLZ' && elemento !== '/tipo_elemento_senals/SV' &&
            <Typography variant="b" component="b"><b>{translate('dynatest.resources.respuesta_evaluacion_novedads.subTitle.deterioro')}</b></Typography>
          }
          {sistema != null && sistema !== '/sistemas/CLZ' && elemento !== '/tipo_elemento_senals/SV' &&
            <Divider fullWidth style={{ width: '300%', marginTop: '10px' }} />
          }
          {/* <Divider fullWidth style={{ width: '300%', marginTop: '10px' }} /> */}

          {sistema != null && sistema !== '/sistemas/CLZ' && elemento === '/tipo_elemento_senals/SV' && tipoRegistro === "Medición" &&
            <Typography variant="b" component="b"><b>{translate('dynatest.resources.respuesta_evaluacion_novedads.subTitle.mediciones')}</b></Typography>
          }
          {sistema != null && sistema !== '/sistemas/CLZ' && elemento === '/tipo_elemento_senals/SV' && tipoRegistro === "Medición" &&
            <ArrayInput fullWidth label=" " source="respuestaMediciones" >
              <SimpleFormIterator disableAdd disableRemove>
                <ReferenceInput
                  label="resources.respuesta_evaluacion_novedads.fields.tipoElemento"
                  source="tipoElemento"
                  reference="tipo_senals"
                  filterToQuery={searchText => ({ codigo: searchText })}
                  formClassName={classes.grid_cont4_s}
                >
                  <AutocompleteInput options={{ fullWidth: true, disabled: true }} optionText="nombre" />
                </ReferenceInput>
                <ReferenceInput
                  label="resources.respuesta_evaluacion_novedads.fields.modelo"
                  source="modelo"
                  reference="senals"
                  filterToQuery={searchText => ({ codigo: searchText })}
                  formClassName={classes.grid_cont4_s}
                >
                  <AutocompleteInput options={{ fullWidth: true, disabled: true }}optionText="nombre" />
                </ReferenceInput>
                <TextInput
                  label="resources.respuesta_evaluacion_novedads.fields.codigo"
                  source="codigo"
                  disabled
                  validate={[required(), minLength(1), maxLength(60)]}
                  formClassName={classes.grid_cont3}
                  style={{ marginBottom: '20px' }}
                />
                <NumberInput
                  label="resources.respuesta_evaluacion_novedads.fields.retroreflectColorPrincipal"
                  source="retroreflectColorPrincipal"
                  disabled
                  validate={[required(), minLength(1), maxLength(60)]}
                  formClassName={classes.grid_cont4_s}
                  style={{ marginBottom: '20px' }}
                />
                <NumberInput
                  label="resources.respuesta_evaluacion_novedads.fields.disminucionPrincipal"
                  source="disminucionPrincipal"
                  disabled
                  formClassName={classes.grid_cont4_s}
                  style={{ marginBottom: '20px' }}
                />
                <br></br>
                <NumberInput
                  label="resources.respuesta_evaluacion_novedads.fields.retroreflectColorSecundario"
                  source="retroreflectColorSecundario"
                  disabled
                  validate={[required(), minLength(1), maxLength(60)]}
                  formClassName={classes.grid_cont4_s}
                  style={{ marginBottom: '20px' }}
                />
                <NumberInput
                  label="resources.respuesta_evaluacion_novedads.fields.disminucionSecundario"
                  source="disminucionSecundario"
                  disabled
                  formClassName={classes.grid_cont4_s}
                  style={{ marginBottom: '20px' }}
                />
                <br></br>
                <TextInput
                  label="resources.respuesta_evaluacion_novedads.fields.posicion"
                  source="posicion"
                  disabled
                  validate={[required(), minLength(1), maxLength(60)]}
                  formClassName={classes.grid_cont4_s}
                  style={{ marginBottom: '20px' }}
                />
                <TextInput
                  label="resources.respuesta_evaluacion_novedads.fields.legibilidad"
                  source="legibilidad"
                  disabled
                  validate={[required(), minLength(1), maxLength(60)]}
                  formClassName={classes.grid_cont4_s}
                  style={{ marginBottom: '20px' }}
                />
                <TextInput
                  label="resources.respuesta_evaluacion_novedads.fields.observaciones"
                  source="observaciones"
                  validate={[maxLength(60)]}
                  formClassName={classes.grid_cont3}
                  style={{ marginBottom: '20px' }}
                />
              </SimpleFormIterator>
            </ArrayInput>
          }
          {sistema != null && sistema !== '/sistemas/CLZ' && elemento !== '/tipo_elemento_senals/SV' &&
            <span><b>{translate('dynatest.resources.respuesta_evaluacion_novedads.subTitle.indicadores')}</b></span>
          }
          {sistema != null && sistema !== '/sistemas/CLZ' && elemento !== '/tipo_elemento_senals/SV' &&
            
            <ArrayInput fullWidth label=" " source="respuestasPreguntasNovedades" >
              <SimpleFormIterator disableAdd disableRemove>

                <FormDataConsumer>
                  {
                    ({ formData, scopedFormData, getSource, ...rest }) => (scopedFormData != null &&
                      scopedFormData.pregunta_.tipoCampo === translate("dynatest.resources.respuesta_evaluacion_novedads.condiciones.numerico") &&
                      <React.Fragment>
                        <TextInput
                          label="resources.respuesta_evaluacion_novedads.fields.novedades"
                          source={getSource('pregunta_.registroNovedad_.nombre')}
                          disabled
                          validate={[required(), minLength(1), maxLength(60)]}
                          formClassName={classes.grid_cont4}
                          style={{ marginLeft: '10px', width: '30%', marginBottom: '20px' }}
                        />
                        <TextInput
                          label="resources.respuesta_evaluacion_novedads.fields.pregunta"
                          source={getSource('pregunta_.pregunta')}
                          id="pregunta"
                          disabled
                          validate={[required(), minLength(1), maxLength(60)]}
                          formClassName={classes.grid_cont4}
                          style={{ marginLeft: '10px', width: '30%', marginBottom: '20px' }}
                        />
                        <NumberInput
                          label="resources.respuesta_evaluacion_novedads.fields.respuesta"
                          source={getSource('respuesta')}
                          record={scopedFormData}
                          validate={[minLength(1), maxLength(60)]}
                          
                          required
                          formClassName={classes.grid_cont4}
                          style={{ marginLeft: '20px', width: '30%', marginBottom: '20px' }}
                        />
                      </React.Fragment>
                    )
                  }
                </FormDataConsumer>

                <FormDataConsumer>
                  {
                    ({ formData, scopedFormData, getSource, ...rest }) => {
                      let arrayRespuestasNew = [];
                      let respuestaDefault = scopedFormData.respuesta;
                      if (scopedFormData != null &&
                        scopedFormData.pregunta_.tipoCampo === translate("dynatest.resources.respuesta_evaluacion_novedads.condiciones.seleccionMultiple")) {
                        scopedFormData.pregunta_.respuestaRegistroNovedad.forEach(element => {
                          arrayRespuestasNew.push({ id: element.originId + "", respuesta: element.respuesta })
                          if (element.originId == respuestaDefault) {
                            respuestaDefault = element.respuesta;
                          }
                        })

                        return (
                          <React.Fragment>
                            <TextInput
                              label="resources.respuesta_evaluacion_novedads.fields.novedades"
                              source={getSource('pregunta_.registroNovedad_.nombre')}
                              disabled
                              validate={[required(), minLength(1), maxLength(60)]}
                              formClassName={classes.grid_cont4}
                              style={{ marginLeft: '10px', width: '30%', marginBottom: '20px' }}
                            />
                            <TextInput
                              label="resources.respuesta_evaluacion_novedads.fields.pregunta"
                              source={getSource('pregunta_.pregunta')}
                              id="pregunta"
                              disabled
                              validate={[required(), minLength(1), maxLength(60)] /* ValidarPregunta */}
                              formClassName={classes.grid_cont6}
                              style={{ marginLeft: '10px', width: '30%', marginBottom: '20px' }}
                            />
                            <SelectInput
                              label="resources.respuesta_evaluacion_novedads.fields.respuesta"
                              source={getSource('respuesta')}
                              choices={arrayRespuestasNew}
                              optionText="respuesta"
                              optionValue="id"
                              
                              required
                              style={{ marginLeft: '20px', width: '30%', marginBottom: '20px' }}
                            />
                          </React.Fragment>
                        )
                      }
                    }
                  }
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
          }
          {sistema != null && sistema !== '/sistemas/CLZ' && elemento === '/tipo_elemento_senals/SV' && tipoRegistro === translate("resources.respuesta_evaluacion_novedads.fields.novedad") &&
            <ArrayInput fullWidth label=" " source="respuestasPreguntasNovedades" >
              <SimpleFormIterator disableAdd disableRemove>

                <FormDataConsumer>
                  {
                    ({ formData, scopedFormData, getSource, ...rest }) => (scopedFormData != null &&
                      scopedFormData.pregunta_.tipoCampo === translate("dynatest.resources.respuesta_evaluacion_novedads.condiciones.numerico") &&
                      <React.Fragment>
                        <TextInput
                          label="resources.respuesta_evaluacion_novedads.fields.novedades"
                          source={getSource('pregunta_.registroNovedad_.nombre')}
                          disabled
                          validate={[required(), minLength(1), maxLength(60)]}
                          formClassName={classes.grid_cont4}
                          style={{ marginLeft: '10px', width: '30%', marginBottom: '20px' }}
                        />
                        <TextInput
                          label="resources.respuesta_evaluacion_novedads.fields.pregunta"
                          source={getSource('pregunta_.pregunta')}
                          id="pregunta"
                          disabled
                          validate={[required(), minLength(1), maxLength(60)]}
                          formClassName={classes.grid_cont4}
                          style={{ marginLeft: '10px', width: '30%', marginBottom: '20px' }}
                        />
                        <NumberInput
                          label="resources.respuesta_evaluacion_novedads.fields.respuesta"
                          source={getSource('respuesta')}
                          record={scopedFormData}
                          validate={[minLength(1), maxLength(60)]}
                          
                          required
                          formClassName={classes.grid_cont4}
                          style={{ marginLeft: '20px', width: '30%', marginBottom: '20px' }}
                        />
                      </React.Fragment>
                    )
                  }
                </FormDataConsumer>

                <FormDataConsumer>
                  {
                    ({ formData, scopedFormData, getSource, ...rest }) => {
                      let arrayRespuestasNew = [];
                      let respuestaDefault = scopedFormData.respuesta;
                      if (scopedFormData != null &&
                        scopedFormData.pregunta_.tipoCampo === translate("dynatest.resources.respuesta_evaluacion_novedads.condiciones.seleccionMultiple")) {
                        scopedFormData.pregunta_.respuestaRegistroNovedad.forEach(element => {
                          arrayRespuestasNew.push({ id: element.originId + "", respuesta: element.respuesta })
                          if (element.originId == respuestaDefault) {
                            respuestaDefault = element.respuesta;
                          }
                        })

                        return (
                          <React.Fragment>
                            <TextInput
                              label="resources.respuesta_evaluacion_novedads.fields.novedades"
                              source={getSource('pregunta_.registroNovedad_.nombre')}
                              disabled
                              validate={[required(), minLength(1), maxLength(60)]}
                              formClassName={classes.grid_cont4}
                              style={{ marginLeft: '10px', width: '30%', marginBottom: '20px' }}
                            />
                            <TextInput
                              label="resources.respuesta_evaluacion_novedads.fields.pregunta"
                              source={getSource('pregunta_.pregunta')}
                              id="pregunta"
                              disabled
                              validate={[required(), minLength(1), maxLength(60)] /* ValidarPregunta */}
                              formClassName={classes.grid_cont6}
                              style={{ marginLeft: '10px', width: '30%', marginBottom: '20px' }}
                            />
                            <SelectInput
                              label="resources.respuesta_evaluacion_novedads.fields.respuesta"
                              source={getSource('respuesta')}
                              choices={arrayRespuestasNew}
                              optionText="respuesta"
                              optionValue="id"
                              
                              required
                              style={{ marginLeft: '20px', width: '30%', marginBottom: '20px' }}
                            />
                          </React.Fragment>
                        )
                      }
                    }
                  }
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
          }
        </FormTab>
      </TabbedForm>
    </Edit >
  )
}));
const WithProps = ({ children, ...props }) => children(props);