import React, { Fragment } from 'react';
import { List, Datagrid,SelectField, TextField, EditButton, ShowButton, Filter,  SelectInput} from 'react-admin';
import {  BulkDeleteWithConfirmButton, Loading  } from 'react-admin';
import EditButtonPims from '../../../EditButton'
import { CardActions, CreateButton, ExportButton } from 'react-admin';
const choises2 = [
    { id: '1', name: 'dynatest.resources.materials.selectMaterials.adoquinEnArcilla' },
        { id: '2', name: 'dynatest.resources.materials.selectMaterials.amadoquinDeConcreto' },
        { id: '3', name: 'dynatest.resources.materials.selectMaterials.afirmado' },
        { id: '4', name: 'dynatest.resources.materials.selectMaterials.baseAsfaltica' },
        { id: '5', name: 'dynatest.resources.materials.selectMaterials.baseDeConcHidraulico' },
        { id: '6', name: 'dynatest.resources.materials.selectMaterials.baseEstOtros' },
        { id: '7', name: 'dynatest.resources.materials.selectMaterials.baseEstbAsfalto' },
        { id: '8', name: 'dynatest.resources.materials.selectMaterials.baseEstEmulsion' },
        { id: '9', name: 'dynatest.resources.materials.selectMaterials.baseGranular' },
        { id: '10', name: 'dynatest.resources.materials.selectMaterials.baseTratada' },
        { id: '11', name: 'dynatest.resources.materials.selectMaterials.concHidraulico' },
        { id: '12', name: 'dynatest.resources.materials.selectMaterials.lechAsfaltica' },
        { id: '13', name: 'dynatest.resources.materials.selectMaterials.mezAbierCaliente' },
        { id: '14', name: 'dynatest.resources.materials.selectMaterials.mezAbierFrio' },
        { id: '15', name: 'dynatest.resources.materials.selectMaterials.mezAsfalGraCaucho' },
        { id: '16', name: 'dynatest.resources.materials.selectMaterials.mezAsfalModPolimeros' },
        { id: '17', name: 'dynatest.resources.materials.selectMaterials.mezDenCaliente' },
        { id: '18', name: 'dynatest.resources.materials.selectMaterials.mezDenFrio' },
        { id: '19', name: 'dynatest.resources.materials.selectMaterials.mezDrenante' },
        { id: '20', name: 'dynatest.resources.materials.selectMaterials.microagloCaliente' },
        { id: '21', name: 'dynatest.resources.materials.selectMaterials.subBaseAsfalEspumado' },
        { id: '22', name: 'dynatest.resources.materials.selectMaterials.subBaseEstabOtros' },
        { id: '23', name: 'dynatest.resources.materials.selectMaterials.subBaseEstabAsfalto' },
        { id: '24', name: 'dynatest.resources.materials.selectMaterials.subBaseEstabCemento' },
        { id: '25', name: 'dynatest.resources.materials.selectMaterials.subBaseEstabGranular' },
        { id: '26', name: 'dynatest.resources.materials.selectMaterials.subraEstabCal' },
        { id: '27', name: 'dynatest.resources.materials.selectMaterials.subraEstabCemento' },
        { id: '28', name: 'dynatest.resources.materials.selectMaterials.subraEstabGeomalla' },
        { id: '29', name: 'dynatest.resources.materials.selectMaterials.subraEstabGeotextil' },
        { id: '30', name: 'dynatest.resources.materials.selectMaterials.subraFina' },
        { id: '31', name: 'dynatest.resources.materials.selectMaterials.subraGranular' },
        { id: '32', name: 'dynatest.resources.materials.selectMaterials.subraCemento' },
];

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);
const ListAction = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
        <ExportButton basePath={basePath}/>
    </CardActions>
);
const ListAction2 = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
    </CardActions>
);
export const MaterialesList = ({ permissions, ...props }) => {
    if (!permissions) return <Loading />;  
    return (
    <List {...props} 
        actions={permissions['materiales']  == 1 ? <ListAction /> : permissions['materiales']  == 2 ? <ListAction2 />: null}
        sort={{ field:"updatedAt", order: 'DESC' }} 
        bulkActionButtons={permissions['materiales']  == 1 ? <PostBulkActionButtons />: null}>
        <Datagrid>    
            <TextField source="material"  />
            <TextField source="especificacion"  />
            <SelectField source="tipo"  choices={choises2} optionText="name"/>
            {permissions['materiales'] == 1 || permissions['materiales'] == 2 || permissions['materiales'] == 4 ? <ShowButton/>: null}
            {permissions['materiales'] == 1 || permissions['materiales'] == 2 ? <EditButton/>: null}
        </Datagrid>
    </List>
)};