import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
    Loading, ReferenceField, TextField
} from 'react-admin';

import { Divider } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import config from '../../config/config';
import AlertDialog from './../../helpers/AlertDialog';
import { styles } from './../../EstilosGrid';
import { translate } from 'react-admin';

let urlAppjs = config.UrlServer;

class ShowElementParticular extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            Elementos: [],
            datos: {
                rutinario: [],
                periodico: [],
                rehabilitacion: [],
                cambio: [],
                items: [],
                unidadMedida: [],
                tipoElementos: [],
                tipoElementoChoices: [{
                    id: "todo",
                    name: "Todo"
                }]

            },
            tipoElementoSelected: "todo",
            isLoaded: {
                rutinario: false,
                periodico: false,
                rehabilitacion: false,
                cambio: false,
                items: false
            }
        }
        this.fetcher.bind(this);
        this.returnComponent.bind(this);
        this.formatFecha.bind(this);

    }

    componentDidMount() {
        this.fetcher("politicas_elemento_particulars", "rutinario");
        this.fetcher("politicas_elemento_periodico_particulars", "periodico");
        this.fetcher("politicas_elemento_rehabilitacion_particulars", "rehabilitacion");
        this.fetcher("politicas_elemento_cambio_particulars", "cambio");
        this.fetcher("politicas_particulares_items", "items");
    }

    fetcher(target, container) {
        switch (target) {
            default:
                fetch(urlAppjs + "/" + target + ".json?pagination=off&politicasMantenimientoParticular=" + this.props.politica,
                    {
                        headers: {
                            'authorization': 'Bearer ' + localStorage.getItem('token')
                        }
                    })
                    .then((response) => {
                        return response.json()
                    })
                    .then((Elementos) => {
                        this.setState({ Elementos: Elementos })
                        var datos = [];
                        this.state.isLoaded[container] = true;
                        if (this.state.Elementos.hasOwnProperty("length")) {
                            this.state.Elementos.map(index => {
                                if (index.seRealiza) {
                                    datos = {
                                        periocidad: index.periocidad,
                                        valorU: index.valorU,
                                        fechaU: index.fechaU,
                                        fuente: index.fuente,
                                        fecha: index.fecha,
                                        descripcion: index.descripcion
                                    }
                                    this.state.datos[container].push(datos);
                                }
                                this.forceUpdate();
                            })
                        }
                    })
                break;

            case "politicas_particulares_items":
                fetch(urlAppjs + "/" + target + ".json?pagination=off&politicasMantenimientoParticular=" + this.props.politica,
                    {
                        headers: {
                            'authorization': 'Bearer ' + localStorage.getItem('token')
                        }
                    })
                    .then((response) => {
                        return response.json()
                    })
                    .then((Elementos) => {
                        this.setState({ Elementos: Elementos })
                        var datos = [];
                        this.state.isLoaded[container] = true;
                        if (this.state.Elementos.hasOwnProperty("length")) {
                            var segmento = "";
                            var abInic = "";
                            var abFin = "";
                            this.state.Elementos.map(index => {
                                fetch(urlAppjs + "/" + index.elementoInventarioId.split("/")[1] +
                                    ".json?id=" + index.elementoInventarioId,
                                    {
                                        headers: {
                                            'authorization': 'Bearer ' + localStorage.getItem('token')
                                        }
                                    })
                                    .then((response) => {
                                        return response.json()
                                    })
                                    .then((Elementos) => {
                                        segmento = Elementos[0].segmento["fullCodigo"];
                                        abInic = index.abInicio;
                                        abFin = index.abFin;
                                        datos = {
                                            segmento: segmento,
                                            id: Elementos[0].smartCode,
                                            abInic: abInic,
                                            abFin: abFin,
                                            seRealiza: index.seRealiza
                                        }
                                        this.state.datos[container].push(datos);
                                        this.forceUpdate();
                                    })
                            })
                        }
                    })
                break;
        }

    }

    formatFecha(fecha) {
        var day = "";
        var month = "";
        var year = "";
        var store = "";
        if (fecha != null) {
            store = fecha.split("T");
            store = store[0].split("-");
            year = store[0];
            month = store[1];
            day = store[2];

            return day + "/" + month + "/" + year;
        }
    }

    returnComponent(source, classes) {
        const { translate } = this.props;
        // console.log("source: ", source);
        var index = [];
        var returnedComponent = "";
        var unidadPeriocidad = {
            mes: translate('dynatest.resources.politicas_mantenimiento_particulars.table.periocidad.mes'),
            meses: translate('dynatest.resources.politicas_mantenimiento_particulars.table.periocidad.meses'),
            anos: translate('dynatest.resources.politicas_mantenimiento_particulars.table.periocidad.anos')
        }

        switch (source) {
            default:
                returnedComponent =
                    <Fragment>
                        {this.state.datos[source].length !== 0 &&
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.TbCellCol1} align="right">
                                            {translate('dynatest.resources.politicas_mantenimiento_particulars.table.nameColumns.Periodicidad')}</TableCell>
                                        <TableCell className={classes.TbCellCol1} align="right">
                                            {translate('dynatest.resources.politicas_mantenimiento_particulars.table.nameColumns.ValorU')}</TableCell>
                                        <TableCell className={classes.TbCellCol1} align="right">
                                            {translate('dynatest.resources.politicas_mantenimiento_particulars.table.nameColumns.FechaU')}</TableCell>
                                        <TableCell className={classes.TbCellCol3} align="center">
                                            {translate('dynatest.resources.politicas_mantenimiento_particulars.table.nameColumns.Fuente')}</TableCell>
                                        <TableCell className={classes.TbCellCol1} align="left">
                                            {translate('dynatest.resources.politicas_mantenimiento_particulars.table.nameColumns.FechaIniPeriodicidad')}</TableCell>
                                        <TableCell className={classes.TbCellCol3} align="center">
                                            {translate('dynatest.resources.politicas_mantenimiento_particulars.table.nameColumns.Descripcion')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.datos[source].map((index) => (
                                        <TableRow>
                                            <TableCell className={classes.TbCellCol1} align="right">
                                                {index.periocidad.toString().replace(".", ",")}
                                                {index.periocidad == 1 && source != "rehabilitacion" && source != "cambio" &&
                                                    unidadPeriocidad.mes
                                                }
                                                {index.periocidad !== 1 && source !== "rehabilitacion" && source !== "cambio" &&
                                                    unidadPeriocidad.meses
                                                }
                                                {index.periocidad !== 1 && source !== "rutinario" && source !== "periodico" &&
                                                    unidadPeriocidad.anos
                                                }
                                            </TableCell>
                                            <TableCell className={classes.TbCellCol1} align="right">
                                                {index.valorU != null &&
                                                    "$" + index.valorU
                                                }
                                            </TableCell>
                                            <TableCell className={classes.TbCellCol1} align="right">
                                                {index.fechaU != null ? this.formatFecha(index.fechaU) : "Valor no diligenciado"}
                                            </TableCell>
                                            <TableCell className={classes.TbCellCol3} align="justify">{index.fuente}</TableCell>
                                            <TableCell className={classes.TbCellCol1} align="left">{this.formatFecha(index.fecha)}</TableCell>
                                            <TableCell className={classes.TbCellCol3} align="justify">{index.descripcion}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        }
                        {this.state.datos[source].length === 0 &&
                            <div style={{ textAlign: "center", width: "100%" }}>
                                <Typography component="h4">
                                {translate('dynatest.resources.politicas_mantenimiento_particulars.messages.SinPoliticaPartiDilig')}
                            </Typography>
                            </div>
                        }
                    </Fragment>

                break;

            case "items":
                returnedComponent =
                    <Fragment>
                        {this.state.datos[source].length !== 0 &&
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell className={classes.TbCellCol4} align="right">{translate('dynatest.resources.politicas_mantenimiento_particulars.table.nameColumns.idInventario')}</TableCell>
                                        <TableCell className={classes.TbCellCol3} align="center">{translate('dynatest.resources.politicas_mantenimiento_particulars.table.nameColumns.absInicial')}</TableCell>
                                        <TableCell className={classes.TbCellCol3} align="center">{translate('dynatest.resources.politicas_mantenimiento_particulars.table.nameColumns.absFinal')}</TableCell>
                                        <TableCell className={classes.TbCellCol2} align="center">{translate('dynatest.resources.politicas_mantenimiento_particulars.table.nameColumns.politicaParticular')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.datos[source].map((index) => (
                                        <TableRow>
                                            <TableCell className={classes.TbCellCol4} align="right">
                                                {index.id}
                                            </TableCell>
                                            <TableCell className={classes.TbCellCol3} align="center">{index.abInic ? "K" + index.abInic.split(".")[0] + "+" + index.abInic.split(".")[1] : "Valor no disponible en el elemento"}</TableCell>
                                            <TableCell className={classes.TbCellCol3} align="center">{index.abFin ? "K" + index.abFin.split(".")[0] + "+" + index.abFin.split(".")[1] : "Valor no disponible en el elemento"}</TableCell>
                                            <TableCell className={classes.TbCellCol2} align="left">
                                                {index.seRealiza == true && <CheckIcon />}
                                                {index.seRealiza == false && <ClearIcon />}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        }
                        {this.state.datos[source].length === 0 &&
                            <div style={{ textAlign: "center", width: "100%" }}>
                                <Typography component="h4">
                                    {translate('dynatest.resources.politicas_mantenimiento_particulars.messages.SinElementos')}
                            </Typography>
                            </div>
                        }
                    </Fragment>
                break;
        }
        return returnedComponent;

    }

    render() {
        const { translate } = this.props;
        const { classes } = this.props;
        const setElemento = (value) => {
            let lenghtObject = Object.keys(value);
            let valor = '';
            for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; };
            this.setState({ tipoElementoSelected: valor });
        }

        if (this.state.isLoaded["rutinario"] &&
            this.state.isLoaded["periodico"] &&
            this.state.isLoaded["rehabilitacion"] &&
            this.state.isLoaded["cambio"] &&
            this.state.isLoaded["items"]
        ) {
            return (
                <Fragment>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography >{translate('dynatest.resources.politicas_mantenimiento_particulars.expansionPanel.Elementos')}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            {this.returnComponent("items", classes)}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography >{translate('dynatest.resources.politicas_mantenimiento_particulars.expansionPanel.MantenimientoRutinario')}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            {this.returnComponent("rutinario", classes)}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography >{translate('dynatest.resources.politicas_mantenimiento_particulars.expansionPanel.MantenimientoPeriodico')}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            {this.returnComponent("periodico", classes)}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography >{translate('dynatest.resources.politicas_mantenimiento_particulars.expansionPanel.MantenimientoRehabilitacion')}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            {this.returnComponent("rehabilitacion", classes)}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography >{translate('dynatest.resources.politicas_mantenimiento_particulars.expansionPanel.MantenimientoCambio')}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            {this.state.tipoElementoSelected === "todo" && this.returnComponent("cambio", classes)}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Fragment>
            )
        } else {
            return <Loading />
        }

    }
}

ShowElementParticular.propTypes = {
    classes: PropTypes.object.isRequired,
}
const ShowElementsParticular = withStyles(styles)(ShowElementParticular);


export default translate(ShowElementsParticular);