import React, { Fragment } from 'react';
import {
    AutocompleteInput, CloneButton, List, Datagrid, BooleanField, EditButton, ShowButton, Filter, ReferenceField,
    SelectInput, ReferenceInput, BulkDeleteWithConfirmButton, TextField, Loading
} from 'react-admin';
import { CardActions, CreateButton, ExportButton } from 'react-admin';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { stringify } from 'query-string';
import ListIcon from '@material-ui/icons/List';
import { translate } from 'react-admin';

const Clone = ({ record }) => (
    <Button
        component={Link}
        to={{
            pathname: 'politicas_mantenimientos/create',
            state: { record: { id: record.id } },
        }}
    >
        Clonar
    </Button>
);

//--------LIST LINE ACTIONS
const ListarPlan = (props) => (
    <Button
        {...props}
        size="small"
        color="primary"
        component={Link}
        to={{
            pathname: '/plan_mantenimiento_detalles',
            search: stringify({
                page: 1,
                perPage: 25,
                sort: 'id',
                order: 'DESC',
                filter: JSON.stringify({ planMantenimientos: props.record.id }),
            }),
        }}
    >
        <ListIcon />
    </Button>
)


//-----FIN DEL LIST ACTIONS

const PostBulkActionButtons = (props) => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props} label="¿Borrar?" />
    </Fragment>
);
const ListAction = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
        <ExportButton basePath={basePath}/>
    </CardActions>
);
const ListAction2 = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
    </CardActions>
);
const Filtro = translate((props) => (
    <Filter {...props}>
        <ReferenceInput
            label="resources.politicas_mantenimientos.fields.Politica"
            source="nombre"
            reference="politicas_nombres"
            fullWidth
            alwaysOn
        >
            <SelectInput optionText="nombre" fullWidth />
        </ReferenceInput>
        <ReferenceInput
            label="resources.politicas_mantenimientos.fields.unidadFuncional"
            source="unidadFuncional"
            reference="unidad_funcionals"
            alwaysOn
        >
            <AutocompleteInput optionText="fullCodigos" />
        </ReferenceInput>
        <ReferenceInput
            label="resources.politicas_mantenimientos.fields.sistema"
            source="sistema"
            reference="sistemas"
            filter={{
                visibleOnPolicies: 1
            }}
            fullWidth
            alwaysOn
        >
            <SelectInput optionText="nombreEnPoliticas" fullWidth />
        </ReferenceInput>
    </Filter>
));
export const PoliticasMantenimientoList = ({ permissions, ...props }) => {
    if (!permissions) return <Loading />;
    return (
        <List {...props}
            sort={{ field: 'updatedAt', order: 'DESC' }}
            title="dynatest.resources.politicas_mantenimientos.title.listTitle"
            filters={<Filtro />}
            actions={permissions['politicaGeneral']  == 1 ? <ListAction /> : permissions['politicaGeneral']  == 2 ? <ListAction2 />: null}
            bulkActionButtons={permissions['politicaGeneral'] == 1 ? <PostBulkActionButtons /> : null}
        >
            <Datagrid>
                <ReferenceField
                    label="resources.politicas_mantenimientos.fields.nombrePolitica"
                    source="nombre"
                    reference="politicas_nombres"
                    linkType={false}
                    fullWidth
                >
                    <TextField label="" source="nombre" />
                </ReferenceField>
                <TextField label="resources.politicas_mantenimientos.fields.unidadFuncional" source="unidadFuncional_.fullCodigos" />
                <ReferenceField
                    label="resources.politicas_mantenimientos.fields.sistema"
                    source="sistema"
                    reference="sistemas"
                    linkType={false}    
                >
                    <TextField label="" source="nombreEnPoliticas" />
                </ReferenceField>
                {permissions['politicaGeneral'] == 1 ? <CloneButton label="resources.politicas_mantenimientos.fields.Clonar" />: null}
                {permissions['politicaGeneral'] == 1 || permissions['politicaGeneral'] == 2 || permissions['politicaGeneral'] == 4 ? <ShowButton/>: null}
                {permissions['politicaGeneral'] == 1 || permissions['politicaGeneral'] == 2 ? <EditButton/>: null}
            </Datagrid>
        </List>
    )
};