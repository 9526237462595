import React, { useState } from 'react';
import { List, Datagrid,Toolbar,SelectInput,BooleanInput, ReferenceInput, TextField,SimpleForm, EditButton, CardActions,RadioButtonGroupInput,ShowButton} from 'react-admin';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import config from '../../../../../config/config';
import { translate } from 'react-admin';
// import { translate } from 'react-admin';
let urlAppjs = config.UrlServer;
class ListColores extends React.Component{


    constructor(props){
        super(props)
        this.state = {colores: [],record: [],edit: "",value: '' };
        this.array = {
            valoresx1 : 0,valoresx2 : 0,valoresx3 : 0,valoresx4 : 0, maximo: 0,minimo: 0,maximo1: 0,minimo1: 0,
        }
        this.validatenum = this.validatenum.bind(this);
    }

    componentDidMount(){
        this.setState({ record: this.props.record })
        //this.setState({ tipo: this.props.edit })}
        fetch(urlAppjs+"/colores.json")
        .then((response) => {
            return response.json()
        })
        .then((colores) => {
            this.setState({ colores: colores })
        })

    }

    // rectay(event){
    //     for(let i=0; i<4; i++){
    //         //$x=($a2-$a1)/($b1-$b2);
    //         //$y=($b1*$x)+$a1;
    //     let j=i-1; //j=1 i=2
    //         if(j<0){
    //             let j=3;
    //         }
    //         let y =(b[i]*x)+a[i];
    //        let putosy,[i]=y;
    //         console.log( "X-> "+x+"\n");
    //         }
    //     }

    validatenum = translate((valor) =>{
        let n = valor;
        if (n <= 1) {
          console.log(n)
        } else if(n > 0){
          console.log(n)
        }
        else{
        return translate('resources.validaciones.valNumMayorA0');
        }
      })
        

    render(){
        const { colores } = this.state;
        const { record } = this.state;
        const {edit} = this.state;
        const { translate } = this.props;
        // debugger
        
        const recta=()=>{
        let a = [];
        let b= [];
        let puntosx=[]
        let puntosy=[]
        for(let i=0; i<4; i++){
        let valor_a=document.getElementById('valora_'+i)
        let valor_b=document.getElementById('valorb_'+i)
        if(valor_a && valor_b && valor_a.value && valor_b.value){
            a.push(valor_a.value)
            b.push(valor_b.value)
        }
        }
        if(a.length===4 && b.length===4){
            let x=0;
            let y=0;
            let j=0;
            for(let i=0; i<4; i++){
                //$x=($a2-$a1)/($b1-$b2);
                //$y=($b1*$x)+$a1;
            let j=i-1; //j=1 i=2
                if(j<0){
                    j=3;
                }
                x=(a[i]-a[j])/(b[j]-b[i]);

                y =(b[i]*x)+a[i];
                puntosx[i] =x;
                puntosy[i] =y;

                }
                console.log(puntosx)
                console.log(puntosy)
        this.array.maximo = Math.max(...puntosx)
        this.array.minimo = Math.min(...puntosy)
        this.array.minimo1 = Math.min(...puntosx)
        this.array.maximo1 = Math.max(...puntosy)
            }
         this.array.valoresx1="x="+puntosx[0]+" y="+puntosy[0];
         this.array.valoresx2="x="+puntosx[1]+" y="+puntosy[1];
         this.array.valoresx3="x="+puntosx[2]+" y="+puntosy[2];
         this.array.valoresx4="x="+puntosx[3]+" y="+puntosy[3];


         //this.array.maximo = Math.max(...myArray);
          // 99
         //var minValue = Math.min(...myArray); // 1

        }

        // let colores2=["Recta Superior","Recta Derecha","Recta Inferior","Recta Izquierda"];

      
        // let colores=this.props.record
        // console.log(this.props.record);
        // console.log(colores);

            //

// if(colores){
    return(
        <React.Fragment>

<Table aria-label="simple table" fullWidth  style={{width: "100%"}}>
                <TableHead>
              <TableRow style={{color:'#fff'}} >
                <TableCell align="right" component="th" ><h3>{translate('dynatest.resources.colores.title.color')}</h3></TableCell>
                <TableCell align="right" component="th" ><h3>{translate('dynatest.resources.colores.title.punto1')}</h3></TableCell>
                <TableCell align="right" component="th"><h3>{translate('dynatest.resources.colores.title.punto2')}</h3></TableCell>
                <TableCell align="right" component="th"><h3>{translate('dynatest.resources.colores.title.punto3')}</h3></TableCell>
                <TableCell align="right" component="th" ><h3>{translate('dynatest.resources.colores.title.punto4')}</h3></TableCell>
                <TableCell align="right" component="th" ><h3>{translate('dynatest.resources.colores.title.valorMinCump')}</h3></TableCell>
              </TableRow>
            </TableHead>
            
                   {
                         colores.map((data, i) => (
                        <TableBody>  
                        <TableRow > 
                        <TableCell align="right" component="th" fullWidth>
                        <h3 style={{marginBottom: 0}}>{data.color}</h3>                    
                        </TableCell>
    
                      <TableCell align="right" >{data.p1} <span></span></TableCell>  
                    <TableCell align="right" > <span >{data.p2}</span> </TableCell>  
                      <TableCell align="right" > <span >{data.p3}</span></TableCell>  
                      <TableCell align="right" > <span >{data.p4}</span></TableCell> 
                      <TableCell align="right" style={{textAlign:'right'}}> <span >{data.valor}</span></TableCell>     
                    </TableRow>
                    </TableBody>    
                       )) 
                }
                   
                        </Table>
        </React.Fragment>
    )
            // }
            // else{
            //     return(
            //         <React.Fragment></React.Fragment>
            //         )
            // }
        }
}




export default translate(ListColores);