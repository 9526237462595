import React  from 'react';
import { TextInput, SelectInput,NumberInput,minValue,maxValue } from 'react-admin';
import { withStyles, Grid } from '@material-ui/core';
import { PimsCreate } from './../../../Create';
import { SimpleForm } from './../../../SimpleForm';
import { styles } from './../../../../EstilosGrid';
import { required, maxLength } from 'react-admin';
import { abValidationInicio,Entero,validarUnDecimal,validateDecima,validarDecimales,validarAB, abValidationFin, validarDosDecimales, validarPorcentaje, validarDecimales2 } from '../../../validacionesInputs';
import UniqueMaterial from './../../../componentes/UniqueMaterial'
import { useState } from 'react';





export const MaterialesCreate = withStyles(styles)(({ classes, ...props }) => {
    const styles = {
        first_field: { width: '100%' }    
    };
    
    const choises2 = [
        { id: '1', name: 'dynatest.resources.materials.selectMaterials.adoquinEnArcilla' },
        { id: '2', name: 'dynatest.resources.materials.selectMaterials.amadoquinDeConcreto' },
        { id: '3', name: 'dynatest.resources.materials.selectMaterials.afirmado' },
        { id: '4', name: 'dynatest.resources.materials.selectMaterials.baseAsfaltica' },
        { id: '5', name: 'dynatest.resources.materials.selectMaterials.baseDeConcHidraulico' },
        { id: '6', name: 'dynatest.resources.materials.selectMaterials.baseEstOtros' },
        { id: '7', name: 'dynatest.resources.materials.selectMaterials.baseEstbAsfalto' },
        { id: '8', name: 'dynatest.resources.materials.selectMaterials.baseEstEmulsion' },
        { id: '9', name: 'dynatest.resources.materials.selectMaterials.baseGranular' },
        { id: '10', name: 'dynatest.resources.materials.selectMaterials.baseTratada' },
        { id: '11', name: 'dynatest.resources.materials.selectMaterials.concHidraulico' },
        { id: '12', name: 'dynatest.resources.materials.selectMaterials.lechAsfaltica' },
        { id: '13', name: 'dynatest.resources.materials.selectMaterials.mezAbierCaliente' },
        { id: '14', name: 'dynatest.resources.materials.selectMaterials.mezAbierFrio' },
        { id: '15', name: 'dynatest.resources.materials.selectMaterials.mezAsfalGraCaucho' },
        { id: '16', name: 'dynatest.resources.materials.selectMaterials.mezAsfalModPolimeros' },
        { id: '17', name: 'dynatest.resources.materials.selectMaterials.mezDenCaliente' },
        { id: '18', name: 'dynatest.resources.materials.selectMaterials.mezDenFrio' },
        { id: '19', name: 'dynatest.resources.materials.selectMaterials.mezDrenante' },
        { id: '20', name: 'dynatest.resources.materials.selectMaterials.microagloCaliente' },
        { id: '21', name: 'dynatest.resources.materials.selectMaterials.subBaseAsfalEspumado' },
        { id: '22', name: 'dynatest.resources.materials.selectMaterials.subBaseEstabOtros' },
        { id: '23', name: 'dynatest.resources.materials.selectMaterials.subBaseEstabAsfalto' },
        { id: '24', name: 'dynatest.resources.materials.selectMaterials.subBaseEstabCemento' },
        { id: '25', name: 'dynatest.resources.materials.selectMaterials.subBaseEstabGranular' },
        { id: '26', name: 'dynatest.resources.materials.selectMaterials.subraEstabCal' },
        { id: '27', name: 'dynatest.resources.materials.selectMaterials.subraEstabCemento' },
        { id: '28', name: 'dynatest.resources.materials.selectMaterials.subraEstabGeomalla' },
        { id: '29', name: 'dynatest.resources.materials.selectMaterials.subraEstabGeotextil' },
        { id: '30', name: 'dynatest.resources.materials.selectMaterials.subraFina' },
        { id: '31', name: 'dynatest.resources.materials.selectMaterials.subraGranular' },
        { id: '32', name: 'dynatest.resources.materials.selectMaterials.subraCemento' },
    ];
    const optionRenderer = choice => `${choice.id}`;
    const choises3 = [
        { id: '1', name: 'dynatest.resources.materials.selectOption.otros' },
        { id: '2', name: 'dynatest.resources.materials.selectOption.otros' },
        { id: '3', name: 'dynatest.resources.materials.selectOption.noLigado' },
        { id: '4', name: 'dynatest.resources.materials.selectOption.bituminoso' },
        { id: '5', name: 'dynatest.resources.materials.selectOption.cementado' },
        { id: '6', name: 'dynatest.resources.materials.selectOption.noLigado' },
        { id: '7', name: 'dynatest.resources.materials.selectOption.bituminoso' },
        { id: '8', name: 'dynatest.resources.materials.selectOption.bituminoso' },
        { id: '9', name: 'dynatest.resources.materials.selectOption.noLigado' },
        { id: '10', name: 'dynatest.resources.materials.selectOption.cementado' },
        { id: '11', name: 'dynatest.resources.materials.selectOption.cementado' },
        { id: '12', name: 'dynatest.resources.materials.selectOption.bituminoso' },
        { id: '13', name: 'dynatest.resources.materials.selectOption.bituminoso' },
        { id: '14', name: 'dynatest.resources.materials.selectOption.bituminoso' },
        { id: '15', name: 'dynatest.resources.materials.selectOption.bituminoso' },
        { id: '16', name: 'dynatest.resources.materials.selectOption.bituminoso' },
        { id: '17', name: 'dynatest.resources.materials.selectOption.bituminoso' },
        { id: '18', name: 'dynatest.resources.materials.selectOption.bituminoso' },
        { id: '19', name: 'dynatest.resources.materials.selectOption.bituminoso' },
        { id: '20', name: 'dynatest.resources.materials.selectOption.bituminoso' },
        { id: '21', name: 'dynatest.resources.materials.selectOption.noLigado' },
        { id: '22', name: 'dynatest.resources.materials.selectOption.noLigado' },
        { id: '23', name: 'dynatest.resources.materials.selectOption.noLigado' },
        { id: '24', name: 'dynatest.resources.materials.selectOption.noLigado' },
        { id: '25', name: 'dynatest.resources.materials.selectOption.noLigado' },
        { id: '26', name: 'dynatest.resources.materials.selectOption.noLigado' },
        { id: '27', name: 'dynatest.resources.materials.selectOption.noLigado' },
        { id: '28', name: 'dynatest.resources.materials.selectOption.noLigado' },
        { id: '29', name: 'dynatest.resources.materials.selectOption.noLigado' },
        { id: '30', name: 'dynatest.resources.materials.selectOption.noLigado' },
        { id: '31', name: 'dynatest.resources.materials.selectOption.noLigado' },
        { id: '32', name: 'dynatest.resources.materials.selectOption.noLigado' }
    ];
    const [especificacion, setEspecificacion] = useState('');
    const validateEspecificacion = (value) =>{
        let  especificacion = [
            "",
            "",
            "INV-311-13",
            "INV-450-13",
            "INV-505-13",
            "INV-13",
            "",
            "INV-340-13",
            "INV-330-13",
            "INV-351-13",
            "INV-500-13",
            "INV-433-13",
            "INV-451-13",
            "INV-441-13",
            "INV-413-13",
            "INV-414-13",
            "INV-450-13",
            "INV-440-13",
            "INV-453-13",
            "INV-452-13",
            "",
            "",
            "",
            "",
            "INV-320-13",
            "INV-236-13",
            "INV-235-13",
            "INV-233-13",
            "INV-232-13",
            "",
            "",
            "INV-350-13"
            
        ];
        setEspecificacion(especificacion[parseInt(value)-1]);
    } 
    


    return(
    <PimsCreate 
        {...props} 
        // title="Crear Material"
    >
        <SimpleForm redirect="list" >
                    <UniqueMaterial 
                        label="resources.materials.fields.materialCreate" 
                        source="material" 
                        operation="default"
                        style={{width: '100%'}} 
                        fullWidth 
                        className={classes.first_field} 
                        validate={[required(), maxLength(70)]}
                        formClassName={classes.grid_cont4}
                    />
                    <SelectInput 
                        source="tipo"  
                        choices={choises2} 
                        fullWidth 
                        validate={[required(),validateEspecificacion]}  
                        formClassName={classes.grid_cont4}
                    />
                    <SelectInput 
                        label="resources.materials.fields.comportamiento" 
                        source="tipo" 
                        fullWidth validate={[required()]} 
                        disabled choices={choises3}  
                        formClassName={classes.grid_cont3}
                    />
                    <TextInput 
                        source="especificacion"  
                        fullWidth 
                        validate={[maxLength(20)]} 
                        defaultValue={especificacion}  
                        formClassName={classes.grid_cont4}
                    />  
                    <NumberInput  
                        source="refmodulus" 
                        fullWidth 
                        validate={[required(),minValue(0) ,maxValue(100000)]} 
                        formClassName={classes.grid_cont4} 
                    />
                    <NumberInput 
                        source="minmodulus" 
                        fullWidth 
                        validate={[required(),minValue(0) ,maxValue(100000)]} 
                        formClassName={classes.grid_cont3}
                    />
                    <NumberInput 
                        source="stdfactor" 
                        fullWidth 
                        validate={[required(),minValue(0) ,validarUnDecimal]} 
                        formClassName={classes.grid_cont4}
                    />
                    <NumberInput 
                        source="poissons"
                        fullWidth 
                        validate={[required(),validarUnDecimal]} formClassName={classes.grid_cont4}
                    />
                    {/* <TextInput label="Material Name" source="materialname" fullWidth formClassName={classes.grid_cont3}/> */}
                    <TextInput 
                        source="observaciones" 
                        fullWidth 
                        formClassName={classes.grid_cont4}
                    />
                                
        </SimpleForm>
    </PimsCreate>
)});