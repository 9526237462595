import React from 'react';
import { EditButton } from 'react-admin';

class EditButtonPims extends React.Component{

    render() {
        return(
           <EditButton />
        )    
    }
}
export default EditButtonPims;