import React, { Fragment, useState } from 'react';
import { ReferenceInput,SelectInput,Filter,WithPermissions,BulkDeleteWithConfirmButton, List, 
    Datagrid, TextField, EditButton, ShowButton, ReferenceArrayField, SingleFieldList, ChipField, CardActions, CreateButton, 
    ExportButton, AutocompleteInput, Loading
} from 'react-admin';
import { translate } from 'react-admin';

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);
const ListAction = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
        <ExportButton basePath={basePath}/>
    </CardActions>
);
const ListAction2 = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
    </CardActions>
);
const styles = {
    ocultar: {
        display:"none",
    },
};
    
const VariosFilterList = (props) => {

    const [departamento , setDepartamento]= useState('-');
    
    const ValidarDepartamento = (value)  => {
        let lenghtObject = Object.keys(value);
        let valor = '';        
        for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                
        setDepartamento(valor) 
    }

    return (
            <Filter {...props}>
                <ReferenceInput 
                    label="resources.contacto_emergencias.fields.departamento" 
                    source="departamento" 
                    reference="departamentos"
                    onChange={ValidarDepartamento}
                    alwaysOn 
                    filterToQuery={searchText => ({ nombre: searchText })}  
                    sort={{field:"nombre", order:"ASC"}}
                >
                    <AutocompleteInput  optionText="nombre" fullWidth />
                </ReferenceInput>
                                        
                <ReferenceInput 
                    label="resources.contacto_emergencias.fields.municipio" 
                    source="municipio" 
                    reference="municipios" 
                    alwaysOn 
                    filterToQuery={searchText => ({ nombre: searchText })} 
                    sort={{field:"nombre", order:"ASC"}}
                    filter={{departamento : departamento}}
                >
                     <AutocompleteInput  optionText="nombre" fullWidth />  
                </ReferenceInput>
        
            <SelectInput id="NivelMax" label="resources.contacto_emergencias.fields.grupo" source="grupo" allowEmpty emptyValue=""  choices={[
                        { id: 'Alcaldía', name: 'dynatest.resources.contacto_emergencias.select.grupo.alcaldia' },
                        { id: 'Bomberos', name: 'dynatest.resources.contacto_emergencias.select.grupo.bomberos' },
                        { id: 'Centro educativo', name: 'dynatest.resources.contacto_emergencias.select.grupo.centroEducativo' },
                        { id: 'Defensa civil', name: 'dynatest.resources.contacto_emergencias.select.grupo.defensaCivil' },
                        { id: 'Ejército', name: 'dynatest.resources.contacto_emergencias.select.grupo.ejercito' },
                        { id: 'Emisora radial', name: 'dynatest.resources.contacto_emergencias.select.grupo.emisoraRadial' },
                        { id: 'Interventoría', name: 'dynatest.resources.contacto_emergencias.select.grupo.interventoria' },
                        { id: 'Maquinaria', name: 'dynatest.resources.contacto_emergencias.select.grupo.maquinaria' },
                        { id: 'Otros', name: 'dynatest.resources.contacto_emergencias.select.grupo.otros' },
                        { id: 'POB-Administrativa', name: 'dynatest.resources.contacto_emergencias.select.grupo.pOB_Administrativa' },
                        { id: 'POB-Ambulancia', name: 'dynatest.resources.contacto_emergencias.select.grupo.pOB_Ambulancia' },
                        { id: 'POB-Carro taller', name: 'dynatest.resources.contacto_emergencias.select.grupo.pOB_CarroTaller' },
                        { id: 'POB-Grúas', name: 'dynatest.resources.contacto_emergencias.select.grupo.pOB_Gruas' },
                        { id: 'POB-Peajes', name: 'dynatest.resources.contacto_emergencias.select.grupo.pOB_Peajes' },
                        { id: 'POB-Radio Operación', name: 'dynatest.resources.contacto_emergencias.select.grupo.pOB_RadioOperacion' },
                        { id: 'POB-Supervisión vial', name: 'dynatest.resources.contacto_emergencias.select.grupo.pOB_SupervisionVial' },
                        { id: 'Policía', name: 'dynatest.resources.contacto_emergencias.select.grupo.policia' },
                        { id: 'Servicios médicos', name: 'dynatest.resources.contacto_emergencias.select.grupo.serviciosMedicos' },
                        { id: 'Talleres', name: 'dynatest.resources.contacto_emergencias.select.grupo.talleres' },
                    ]} alwaysOn/>
            </Filter>
           
    )
};
    
export const Contacto_emergenciaList = translate(({ permissions, ...props }) => {
    const { translate } = props;
    if (!permissions) return <Loading />;  
    return (
        <List {...props} 
        sort={{ field: 'id', order: 'DESC' }} 
        filters={<VariosFilterList/>} 
        actions={permissions['contemergencia']  == 1 ? <ListAction /> : permissions['contemergencia']  == 2 ? <ListAction2 />: null} 
        bulkActionButtons={permissions['contemergencia']  == 1 ? <PostBulkActionButtons />: null}>
            <Datagrid>            
                <ReferenceArrayField reference="municipios" label="resources.contacto_emergencias.fields.municipio" source="municipio" sortable={false}>
                    <SingleFieldList>
                        <ChipField source="nombre" />
                    </SingleFieldList>
                </ReferenceArrayField>
                <TextField source="grupo" />
                <TextField source="empresa" />         
            
                <TextField source="nombre" />
                <TextField source="telefono_1" sortable={false} className="Listar_telefono" />
                {permissions['contemergencia'] == 1 || permissions['contemergencia'] == 2 || permissions['contemergencia'] == 4 ? <ShowButton/>: null}
                {permissions['contemergencia'] == 1 || permissions['contemergencia'] == 2 ? <EditButton/>: null}                           
            </Datagrid>
        </List>
)});