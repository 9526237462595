import React from 'react';
import { TextInput} from 'react-admin';
import { SimpleForm } from './../../../SimpleForm';
import { withStyles } from '@material-ui/core';
import { PimsCreate } from './../../../Create';

import { required } from 'react-admin';
    
const styles = {
    first_field: { display: 'inline-block', width: '30%' },
    last_field: { display: 'inline-block', marginLeft: 32, width: '30%' }    
};

export const MedidoPorCreate = withStyles(styles)(({ classes, ...props }) => (
        <PimsCreate {...props} title="Crear usuario">
            <SimpleForm redirect="list">
                <TextInput source="nombre" label="Medido por" validate={[required()]}/>                
            </SimpleForm>
        </PimsCreate>
));