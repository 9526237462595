import React from 'react';
import { ReferenceInput, DateInput, TabbedForm, FormTab, TextInput, NumberInput, SelectInput } from 'react-admin';
import { Edit } from './../../Edit';
import { DInput } from './../../../inputs/DInput';
import { EInput } from './../../../inputs/EInput';
import {
  required,
  minLength,
  maxLength,
  minValue,
  maxValue,

} from 'react-admin';

import Grid from '@material-ui/core/Grid';




import {
  Toolbar,
  SaveButton,


} from 'react-admin';
import { withStyles } from '@material-ui/core';

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};


const CustomToolbar = withStyles(toolbarStyles)(props => (
  <Toolbar {...props}>
    <SaveButton />

  </Toolbar>
));

export const EjesEdit = props => (
  <Edit title=" Editar Ejes Equivalentes" {...props} redirect="list" toolbar={<CustomToolbar />}>
    <TabbedForm redirect="list">
      <FormTab label="detalles">
        <Grid container spacing={24}>
          <Grid xs={6}>
            <ReferenceInput label="Nombre" source="estacion_conteo" reference="estacion_conteos" fullWidth validate={[required()]} linkType={false}>
              <SelectInput optionText="nombre" />
            </ReferenceInput>
          </Grid>
          <Grid item xs={6}>
            <DateInput label="Fecha medición" source="date" fullWidth validate={[required()]} />
          </Grid>
          <Grid item xs={6}>
            <TextInput label="Consultor" source="consultor" fullWidth validate={[required()]} />
          </Grid>
          <Grid item xs={6}>
            <TextInput label="Informe" source="informe" fullWidth validate={[required()]} />
          </Grid>

          <Grid item xs={12}>
            <h3 style={{ marginBottom: 0 }}>Resumen de Resultados</h3>
            <hr></hr>
          </Grid>

          <Grid item xs={4}>
            <DInput label="Factor direccional" source="direccional" fullWidth className="align-number" validate={[required(), minLength(1), maxLength(11), minValue(0.00), maxValue(1.00)]} />
          </Grid>
          <Grid item xs={4}>
            <DInput label="Factor Carril" source="carril" fullWidth className="align-number" validate={[required(), minLength(1), maxLength(11), minValue(0.00), maxValue(1.00)]} />
          </Grid>
          <Grid item xs={4}>
            <NumberInput label="Tránsito Promedio Diario" source="tpd" fullWidth className="align-number" validate={[required(), minLength(1), maxLength(9), minValue(1), maxValue(999999999)]} />
          </Grid>
        </Grid>
      </FormTab>
      <FormTab label="Categoría">
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <h3 style={{ marginBottom: 0 }}>Autos</h3>
            <hr></hr>

          </Grid>
          <Grid item xs={3}>
            <EInput label="Factor Daño" source="auto_dano" fullWidth className="align-number" validate={[required(), minLength(1), maxLength(11), minValue(0.00), maxValue(50.00)]} />
          </Grid>
          <Grid item xs={3}>
            <NumberInput label="Volumen" source="auto_volumen" fullWidth className="align-number" validate={[required(), minLength(1), maxLength(9), minValue(1), maxValue(999999999)]} />
          </Grid>

          <Grid item xs={12}>
            <h3 style={{ marginBottom: 0 }}>Buses</h3>
            <hr></hr>

          </Grid>
          <Grid item xs={3}>
            <EInput label="Factor Daño" source="buses_dano" fullWidth className="align-number" validate={[required(), minLength(1), maxLength(11), minValue(0.00), maxValue(50.00)]} />
          </Grid>
          <Grid item xs={3}>
            <NumberInput label="Volumen" source="buses_volumen" fullWidth className="align-number" validate={[required(), minLength(1), maxLength(9), minValue(1), maxValue(999999999)]} />
          </Grid>

          <Grid item xs={12}>
            <h3 style={{ marginBottom: 0 }}>C2-P</h3>
            <hr></hr>

          </Grid>
          <Grid item xs={3}>
            <EInput label="Factor Daño" source="c2_p_dano" fullWidth className="align-number" validate={[required(), minLength(1), maxLength(11), minValue(0.00), maxValue(50.00)]} />
          </Grid>
          <Grid item xs={3}>
            <NumberInput label="Volumen" source="c2_p_volumen" fullWidth className="align-number" validate={[required(), minLength(1), maxLength(9), minValue(1), maxValue(999999999)]} />
          </Grid>
          <Grid item xs={12}>
            <h3 style={{ marginBottom: 0 }}>C2-G</h3>
            <hr></hr>

          </Grid>
          <Grid item xs={3}>
            <EInput label="Factor Daño" source="c2_g_dano" fullWidth className="align-number" validate={[required(), minLength(1), maxLength(11), minValue(0.00), maxValue(50.00)]} />
          </Grid>
          <Grid item xs={3}>
            <NumberInput label="Volumen" source="c2_g_volumen" fullWidth className="align-number" validate={[required(), minLength(1), maxLength(9), minValue(1), maxValue(999999999)]} />
          </Grid>
          <Grid item xs={12}>
            <h3 style={{ marginBottom: 0 }}>C3-C4</h3>
            <hr></hr>

          </Grid>
          <Grid item xs={3}>
            <EInput label="Factor Daño" source="c3_dano" fullWidth className="align-number" validate={[required(), minLength(1), maxLength(11), minValue(0.00), maxValue(50.00)]} />
          </Grid>
          <Grid item xs={3}>
            <NumberInput label="Volumen" source="c3_volumen" fullWidth className="align-number" validate={[required(), minLength(1), maxLength(9), minValue(1), maxValue(999999999)]} />
          </Grid>
          <Grid item xs={12}>
            <h3 style={{ marginBottom: 0 }}>C5</h3>
            <hr></hr>

          </Grid>
          <Grid item xs={3}>
            <EInput label="Factor Daño" source="c5_dano" fullWidth className="align-number" validate={[required(), minLength(1), maxLength(11), minValue(0.00), maxValue(50.00)]} />
          </Grid>
          <Grid item xs={3}>
            <NumberInput label="Volumen" source="c5_volumen" fullWidth className="align-number" validate={[required(), minLength(1), maxLength(9), minValue(1), maxValue(999999999)]} />
          </Grid>
          <Grid item xs={12}>
            <h3 style={{ marginBottom: 0 }}>>C5</h3>
            <hr></hr>

          </Grid>
          <Grid item xs={3}>
            <EInput label="Factor Daño" source="c5_1_dano" fullWidth className="align-number" validate={[required(), minLength(1), maxLength(11), minValue(0.00), maxValue(50.00)]} />
          </Grid>
          <Grid item xs={3}>
            <NumberInput label="Volumen" source="c5_1_volumen" fullWidth className="align-number" validate={[required(), minLength(1), maxLength(9), minValue(1), maxValue(999999999)]} />
          </Grid>
        </Grid>
      </FormTab>
    </TabbedForm>
  </Edit>
);