import React from 'react';

import { TextInput, NumberInput, SimpleForm, SaveButton, Toolbar} from 'react-admin';

import { withStyles } from '@material-ui/core';
import { Edit } from './../../../Edit';


import { required } from 'react-admin';

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
));

const styles = {
    first_field: { display: 'inline-block', width: '30%' },    
};

export const FisuraEdit = withStyles(styles)(({ classes, ...props }) => (
        <Edit title="Editar fisura" {...props} undoable={false}>
            <SimpleForm submitOnEnter={false} redirect="list" toolbar={<CustomToolbar />} >
                <TextInput source="tipo" label="Fisura" validate={[required()]}/>                      
                <NumberInput source="factor" label="Factor" validate={[required()]}/>             
            </SimpleForm>
        </Edit>
))