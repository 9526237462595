import React , {useState} from 'react';
import { required, TextInput, SelectInput,ReferenceInput,Query} from 'react-admin';
import { Edit } from './../Edit';
import { PRInput } from './../../inputs/PRInput';
import { PInput } from './../../inputs/PInput';
import Grid from '@material-ui/core/Grid';
import { SimpleForm } from './../SimpleForm';
import { withStyles } from '@material-ui/core';
import config from '../../config/config';
import {SaveButton, Toolbar } from 'react-admin';
import {translate} from 'react-admin';
let urlAppjs = config.UrlServer;


const styles = {
    ocultar: {
        display:"none",
    },

};


export const UnidadFuncionalEdit  = translate(withStyles(styles)(({ classes, ...props }) => {
    const {translate} = props;
    const [rutass , setRutas]= useState('');
    const [prValidador, setPrValidador] = useState('');
    const [AbValidador, setAbValidador] = useState('0');
    const [contador, setContador] =useState(0);
    const [abInicio, setAbInicio] =useState();
    const [abFin, setAbFin] =useState();
    const [separadore, setSeparador] =useState();
    const [idUnidade, setIdUnidad] =useState();
/*VALIDAR  QUE LAS UNIDADES F NO SE SOBREPISEN*/

    const ValidarRutas = (value)  => { 
        let urlUnidad = window.location.hash;
        setIdUnidad(urlUnidad.split("%2Funidad_funcionals%2F"))
        let lenghtObject = Object.keys(value);
        let valor = '';   
        for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                  
        setRutas(value) 
        let separador = value.split('/');
        setSeparador(separador[2]);     
    }
        
    const toolbarStyles = {
        toolbar: {
            display: 'flex',
            justifyContent: 'space-between',
        },
    };
    
    
    const SimpleFormActions = withStyles(toolbarStyles)(props => (
        <Toolbar {...props}>
            <SaveButton />
            {/* <BackButton onClick={ValidarAbsFin} /> */}
        </Toolbar>
    ));
    

/* FIN DE VALIDAR  QUE LAS UNIDADES F NO SE SOBREPISEN*/

/*Validación para que los valores de PR y ABS no se soblepongan*/
const NoSobreponerAbs = () =>{
    // rutass  id de la rita contra la que se valida
    // abInicio valor de la abscisa inicial ingresada
    // abFin valor de la abscisa final ingresada
    // tipoConsulta valida si es editar o crear 
    let IdRuta = rutass.substr(7);        
    let abInicioA = document.getElementById('ingresoAbInicio');
    let abFinA = document.getElementById('ingresoAbFin');
    if(abInicioA && abFinA)
    {
        abInicioA = abInicioA.value
        abFinA = abFinA.value
        abInicioA = abInicioA.substr(1, abInicioA.lastIndexOf('+')-1)+'.'+abInicioA.substr(abInicioA.lastIndexOf('+')+1,abInicioA.length);
        abFinA = abFinA.substr(1, abFinA.lastIndexOf('+')-1)+'.'+abFinA.substr(abFinA.lastIndexOf('+')+1,abFinA.length);
        fetch(urlAppjs+'/AbsUnidadFuncional/'+IdRuta+'/'+abInicioA +'/'+ abFinA +'/'+ +idUnidade[1] +'/Editar')
        .then(function(response) { 
            return response.text();
        })
        .then(function(myJson) {  
            setAbValidador(myJson)   
            // setContador(1)
            if(AbValidador === '1'){
                return translate('resources.validaciones.rangoNoValido')
            }                
        })
    }        
    if(AbValidador === '1'){
        return translate('resources.validaciones.rangoNoValido')
    }
}
const MansajeValidadorAb = () =>{
    if(AbValidador === '1'){
    
        return translate('resources.validaciones.rangoNoValido')
    }
}
const ValorMaximoAbsEditInicio =(props) =>{
    
    let value = document.getElementById('ingresoAbInicio').value;
    value = value.split("K")
    if(value[1] !== undefined)
    {
        value=value[1].split("+")
        
        if(value[0] > 9999)
        {
            return translate('resources.validaciones.mayorK9');
        }
    }
    
} 
const ValorMaximoAbsEditFin =(props) =>{
    
    let value = document.getElementById('ingresoAbFin').value;
    value = value.split("K")
    if(value[1] !== undefined)
    {
        value=value[1].split("+")
       
        if(value[0] > 9999)
        {
            return translate('resources.validaciones.mayorK9');
        }
    }
} 
const validarLogicaAbsFinEdit= (value, props)=>{
 
    let prInicio = document.getElementById('ingresoAbInicio').value;
    prInicio = prInicio.split('K')
    if(prInicio[1] !== undefined)
    {
        prInicio = prInicio[1].split('+')
        let prFin = document.getElementById('ingresoAbFin').value;
        if(prFin !== null && prFin !== '' && prFin !== undefined){
            prFin = prFin.split('K')
            prFin = prFin[1].split('+')
        }

        if(parseInt(prFin[0]) < parseInt(prInicio[0]))
        {   
            return translate('resources.validaciones.abscisaFin')
        }
        if(parseInt(prFin[0]) === parseInt(prInicio[0]) && parseInt(prFin[1]) === parseInt(prInicio[1]))
        {
            return translate('resources.validaciones.abscisaFin') 
        }
        if(parseInt(prFin[0]) === parseInt(prInicio[0]) && parseInt(prFin[1]) < parseInt(prInicio[1]))
        {
            return translate('resources.validaciones.abscisaFin') 
        }

    } 
}
const prValidationInicio =(value, props) =>{
    
    /*VALORES PR INICIO*/
    let PrInicioBase = document.getElementById('IdprInicio');
    if(PrInicioBase){
        PrInicioBase = PrInicioBase.value;
        let ingresoPrInicio = document.getElementById('ingresoPrInicio').value;
        let separadorInicio = ingresoPrInicio.split("+");
        let separadorInicio1 = separadorInicio[0].split("PR");
        let sePrInicioBase = PrInicioBase.split(".");
        let PrFinBase = document.getElementById('IdprFin').value;
        let sePrFinBase = PrFinBase.split(".");

        /*VALIDACIONES PR INICIO*/
        if(parseInt(separadorInicio1[1] ) < parseInt(PrInicioBase) || parseInt(separadorInicio1[1] )  > parseInt(PrFinBase) )
        {
            return translate('dynatest.resources.validaciones.prValidationInicio') + PrInicioBase +translate('dynatest.resources.validaciones.prValIniy') + PrFinBase;
        }
        if(parseInt(separadorInicio1[1] ) === parseInt(PrInicioBase))
        {
            if(parseInt(separadorInicio[1]) < parseInt(sePrInicioBase[1]))

            return translate('dynatest.resources.validaciones.prValidationInicio') + PrInicioBase +translate('dynatest.resources.validaciones.prValIniy') + PrFinBase;
        }
        if(parseInt(separadorInicio1[1] ) === parseInt(sePrFinBase[0]))
        {
            if(parseInt(separadorInicio[1]) > parseInt(sePrFinBase[1]))

            return translate('dynatest.resources.validaciones.prValidationInicio') + PrInicioBase +translate('dynatest.resources.validaciones.prValIniy') + PrFinBase;
        }
    }
    
}
const ValorMaximoPrEditInicio =(props) =>{
    
    let value = document.getElementById('ingresoPrInicio').value;
    value = value.split("PR")
    if(value[1] !== undefined)
    {
        value=value[1].split("+")
        
        if(value[0] > 999)
        {
            return translate('resources.validaciones.mayorPR9')
        }
    }
}
const prValidationFin =(value, props) =>{
    
    /*VALORES ABS FIN */
    let PrFinBase = document.getElementById('IdprFin');
    let ingresoPrFin = document.getElementById('ingresoPrFin');
    if(PrFinBase && ingresoPrFin)
    {
        PrFinBase = PrFinBase.value;
        let ingresoPrFin = document.getElementById('ingresoPrFin').value;
        let separadorFin = ingresoPrFin.split("+");
        let separadorFin1 = separadorFin[0].split("PR");
        let sePrFinBase = PrFinBase.split(".");
        let PrInicioBase = document.getElementById('IdprInicio').value;
        let sePrInicioBase = PrInicioBase.split(".");
        /*VALIDACIONES PRFIN*/
        if(parseInt(separadorFin1[1] ) > parseInt(PrFinBase) || parseInt(separadorFin1[1] )  < parseInt(PrInicioBase) )
        {
            return translate('dynatest.resources.validaciones.prValidationFin') + PrInicioBase +translate('dynatest.resources.validaciones.prValnFiny') + PrFinBase;
        }
        if(parseInt(separadorFin1[1] ) === parseInt(PrFinBase))
        {
            if(parseInt(separadorFin[1]) > parseInt(sePrFinBase[1]))
        
                return translate('dynatest.resources.validaciones.prValidationFin') + PrInicioBase +translate('dynatest.resources.validaciones.prValnFiny') + PrFinBase;
        }
        if(parseInt(separadorFin1[1] ) === parseInt(sePrInicioBase[0]))
        {
            if(parseInt(separadorFin[1]) < parseInt(sePrInicioBase[1]))
        
                return translate('dynatest.resources.validaciones.prValidationFin') + PrInicioBase +translate('dynatest.resources.validaciones.prValnFiny') + PrFinBase;
        }
    }    
}
const ValorMaximoPrEditFin =(props) =>{
    
    let value = document.getElementById('ingresoPrFin').value;
    value = value.split("PR")
    if(value[1] !== undefined)
    {
        value=value[1].split("+")
        
        if(value[0] > 999)
        {
            return translate('resources.validaciones.mayorPR9')
        }
    }
} 
const validarLogicaPrFinEdit= (value, props)=>{ 
    let prInicio = document.getElementById('ingresoPrInicio').value;
    prInicio = prInicio.split('PR')
    if(prInicio[1] !== undefined)
    {
        prInicio = prInicio[1].split('+')
        let prFin = document.getElementById('ingresoPrFin').value;
        prFin = prFin.split('PR')
        prFin = prFin[1].split('+')

        if(parseInt(prFin[0]) < parseInt(prInicio[0]))
        {   
            return translate("resources.validaciones.prFin");
        }
        if(parseInt(prFin[0]) === parseInt(prInicio[0]) && parseInt(prFin[1]) === parseInt(prInicio[1]))
        {
            return translate('resources.validaciones.prFin')
        }
        if(parseInt(prFin[0]) === parseInt(prInicio[0]) && parseInt(prFin[1]) < parseInt(prInicio[1]))
        {
            return translate('resources.validaciones.prFin')
        }
    }
}
const NoSobreponerPr = () =>{
    // rutass  id de la rita contra la que se valida
    // abInicio valor de la abscisa inicial ingresada
    // abFin valor de la abscisa final ingresada
    // tipoConsulta valida si es editar o crear 
    let IdRuta = rutass.substr(7);      
    let abInicioA = document.getElementById('ingresoPrInicio');
    let abFinA = document.getElementById('ingresoPrFin'); 

    abInicioA = abInicioA.value
    abFinA = abFinA.value
    abInicioA = abInicioA.substr(2, abInicioA.lastIndexOf('+')-2)+'.'+abInicioA.substr(abInicioA.lastIndexOf('+')+1,abInicioA.length);
    abFinA = abFinA.substr(2, abFinA.lastIndexOf('+')-2)+'.'+abFinA.substr(abFinA.lastIndexOf('+')+1,abFinA.length);
    fetch(urlAppjs+'/PrUnidadFuncional/'+IdRuta+'/'+abInicioA +'/'+ abFinA+'/'+idUnidade[1]+'/Editar')
    .then(function(response) {
        
        return response.text();
    })
    .then(function(myJson) {  
        setPrValidador(myJson)   
        // setContador(1)
        if(AbValidador === '1'){
    
            return translate('resources.validaciones.rangoNoValido')
        }                
    })
    if(prValidador === '1'){
    
        return translate('resources.validaciones.rangoNoValido')
    }
}

const MansajeValidadorPb = () =>{
    if(prValidador === '1'){
    
        return translate('resources.validaciones.rangoNoValido')
    }
}
return(
    <Edit title="dynatest.resources.unidad_funcionals.title.editTitle" {...props} >
        <SimpleForm submitOnEnter={false} actions={<SimpleFormActions/>} redirect="list" >            
            <Grid container spacing={24}>
                <Grid item xs={6}>               
                    {/* <ValidarAbs/>   */}
                    <TextInput label="Código" source="CODIGAAS" style={{display : 'none'}} id="codigo" defaultValue={AbValidador} fullWidth disabled />
                    <TextInput label="Código" source="CODIGAASA" style={{display : 'none'}} id="codigo" defaultValue={prValidador}  fullWidth disabled />
                    {/* <ValidarAbsFin/>    */}
                    <ReferenceInput label="resources.unidad_funcionals.fields.ruta" source="ruta" disabled reference="rutas" fullWidth  validate={[required(), ValidarRutas]}>
                        <SelectInput optionText="fullCode" id="IdRuta" />
                     </ReferenceInput>             
                </Grid>
                <Grid item xs={6}>
                    <TextInput label="resources.unidad_funcionals.fields.codigo" source="codigo" id="codigo" fullWidth disabled />
                </Grid>                                          
                <Grid item xs={6}>
                    <PRInput label="resources.unidad_funcionals.fields.abInicio" id="ingresoAbInicio" source="abInicio" onChange={NoSobreponerAbs} fullWidth  validate={[ValorMaximoAbsEditInicio, required()]}   />
                </Grid>
                <Grid item xs={6}>
                    <PRInput label="resources.unidad_funcionals.fields.abFin" id="ingresoAbFin" source="abFin" onChange={NoSobreponerAbs}  validate={[ValorMaximoAbsEditFin,validarLogicaAbsFinEdit, required()]} fullWidth/>
                </Grid>
                {rutass &&             
                    <Grid item xs={6}>
                        <PInput label="resources.unidad_funcionals.fields.pInicio" source="pInicio"  id="ingresoPrInicio" onChange={NoSobreponerPr} fullWidth validate={[prValidationInicio,required(),  ValorMaximoPrEditInicio,MansajeValidadorPb]}/>
                    </Grid> 
                }
                {rutass &&     
                    <Grid item xs={6}>
                        <PInput label="resources.unidad_funcionals.fields.pFin" source="pFin"   id="ingresoPrFin" onChange={NoSobreponerPr} fullWidth validate={[prValidationFin,required(), ValorMaximoPrEditFin, validarLogicaPrFinEdit,MansajeValidadorPb]}/>
                    </Grid>
                }
               
                {rutass &&                           
                    <Query type="GET_ONE" resource="rutas" payload={{ id: rutass}} >
                        {({ data, loading, error }) => {                                                
                            if( data){
                                return (
                                    <Grid item xs={3}>
                                        <TextInput  id="IdprInicio"  style={{display : 'none'}} label="pr Inicio" defaultValue={data.PInicio} source="pInicios" fullWidth />
                                        <TextInput id="IdprFin"   style={{display : 'none'}}  label="PrFin" defaultValue={data.PFin} source="pFins" fullWidth />
                                    </Grid>
                                )
                            }
                            return <div></div>;                                                
                        }}
                    </Query>                            
                }  
            </Grid>
        </SimpleForm>
    </Edit>
    )
    }));