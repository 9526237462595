import React, {useState} from 'react';
import {
  withGoogleMap,
  withScriptjs,
  Marker,
  GoogleMap,
  Polygon,
  InfoWindow
} from "react-google-maps";
import config from '../config/config';
let urlAppjs = config.UrlFront;
// const { compose, withProps, withStateHandlers } = require("recompose");

export const MapWithAMarker = withScriptjs(withGoogleMap(

  props => {
    const {        
      pr,
      prf,
      url,
      defaultCenter,
      tipoSeñal,
      redirect,
      positions,
      info,
      demarcacion
    } = props;

    
    
  const [ windows , setWindow] = useState(false); 
  const [ markerKey , setMarkerKey] = useState(''); 

  const ShowWindow = (i) =>{
    setWindow(true);
    setMarkerKey(i)
    // debugger
  }

  const HiddenWindow = () =>{
    setWindow(false);
  }
  let redirects = [];
  let posicion1 = [ demarcacion[0] , demarcacion[1] ,demarcacion[2] ,demarcacion[3]]
  

  let pathCoordinates = '';
  if(positions !== undefined)
  {
     pathCoordinates = positions.map((mrk, i) => {     
     
      // redirects =  redirect[i]
      return({            
        position: mrk,  
        datos : {pr : pr , prf : prf , info: info[i]},  
        icono:{icon:url[i]}, 
        nombre:{nom:tipoSeñal[i]},     
        demarcacion : demarcacion  
      })                 
    })   
  }
      
  
    let urlAppjs1 = urlAppjs + "elemento_senalizacion_perus/%2Felemento_senalizacion_perus%2F" ;

    const putMarkers = () => { 
      
      if(pathCoordinates !== undefined && pathCoordinates !== '')
      {
        return(          
          pathCoordinates.map((mrk, i) => {
              
              return(
                <div>
                  <Marker 
                      key={`mrk${i}`}
                      position={mrk.position}
                      icon={new window.google.maps.MarkerImage(
                        mrk.icono.icon,
                        null, /* size is determined at runtime */
                        null, /* origin is 0,0 */
                        null, /* anchor is bottom center of the scaled image */
                        new window.google.maps.Size(45, 45)
                    )}
                     onClick={ ()=> {ShowWindow(i)} }
                  >
                  
                  {windows && markerKey === i &&
                  <div id='mapaProyect'>
                   <InfoWindow key={`mrk${i}`} id='mapaProyect' className='mapeos' onCloseClick={HiddenWindow}> 
                      <div >
                        <h3>{mrk.nombre.nom}</h3>
                        <p><strong>Código de señal: </strong>{mrk.datos.info.id}</p>
                        <p><strong>Unidad Funcional: </strong>{mrk.datos.info.uf}</p>
                        <p><strong>Tramo: </strong>{mrk.datos.info.tra}</p>
                        <p><strong>Segmento: </strong>{mrk.datos.info.seg}</p>
                        <p><strong>Sentido del transito: </strong>{mrk.datos.info.sentidoTransito}</p>
                        <p><strong>Progresiva: </strong>{mrk.datos.info.pr}</p>
                        <p><strong>Latitud: </strong>{mrk.position.lat}</p>
                        <p><strong>Longitud: </strong>{mrk.position.lng}</p>
                        <p><strong>Altura (m.s.n.m): </strong>{mrk.datos.info.alt}</p>
                        <p><strong>Lado: </strong>{mrk.datos.info.margen}</p>
                        <p><strong>Tipo: </strong>{mrk.datos.info.tipo}</p>
                        <p><strong>Condición global: </strong>{mrk.datos.info.condicion}</p>
                        <a href={urlAppjs1 + redirect[i] + '/show'} target="_blank">Ver más...</a>
                      </div>                                                
                    </InfoWindow> 
                    </div>
                    
                    }
                    
                  </Marker>                                        
                </div>
              )
              
          })
      )

      }else{
        
        return(
        <Marker 
          key={`mrk`}
        // position={mrk.position}
          icon={true}
          // onClick={ ()=> {ShowWindow(i)} }
        />
        )
      }
        
    }

    return (
        <GoogleMap
          defaultZoom={12}
          defaultCenter={defaultCenter}
        >
        
          <Polygon
            path={posicion1}
            key={1}
            options={{
                fillColor: "#000",
                fillOpacity: 0.2,
                strokeColor: "#000",
                strokeOpacity: 1, 
                strokeWeight: 1
            }}
          />
          
          {putMarkers()}
        </GoogleMap>
        )

  }
  ));

  export default MapWithAMarker;
  
// class MapWithAMarker1 extends React.Component{
// 	state = {
//     position : null,
//     positions : []
// 	}

// 	handleToggleOpen(item){
// 		this.setState({
// 			position : {
// 				lat : item.lat,
// 				lng : item.lng
// 			}			
// 		})
//   }
  
    

// 	render(){  
// 		return (
//       <GoogleMap
//         defaultZoom={12}
//         defaultCenter={ {lat: 4.756704, lng: -74.031106}}
//       >
// 				{this.props.positions.map((item , i)  => ( 
// 					<Marker
//               key={`mrk${i}`}
// 					    position={item}
// 					    onClick={() => this.handleToggleOpen(item)}
// 					/>
// 				))}				
// 				{this.state.position && 
// 					 <InfoWindow position={this.state.position}>
// 					     <h1>{this.props.location.venue.name}</h1>
// 					 </InfoWindow>
// 				 }
// 			</GoogleMap>
// 		)
// 	}	
// }



//   export const MapWithAMarker = withScriptjs(withGoogleMap( 
    
//       <MapWithAMarker1/>
    

//   ))