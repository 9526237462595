import React from 'react';
import Typography from '@material-ui/core/Typography';
import {
    DateInput, BooleanInput, required, TabbedForm, FormTab, AutocompleteInput, SimpleFormIterator,
    ArrayInput, NumberInput, SelectInput, ReferenceInput, TextInput, FormDataConsumer
  } from 'react-admin';
import { minValue, maxValue, minLength, maxLength } from 'ra-core';
import { PRInput } from '../../../inputs/PRInput';
import { DInput } from '../../../inputs/DInput';
import { DEInput } from '../../../inputs/DEInput';
import { AInput} from '../../../inputs/AInput';
import { styles } from './../../../EstilosGrid';
import { withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { translate } from 'react-admin';

class Analisis extends React.Component{

    constructor(props){
        console.log(props)
        super(props)
        this.state = { 
            titulos: [], record: [], tipo: "",totalSum:[],total:0,
            years1: props.years1,
            years2: props.years2,
            titulos2: [],
            dataYears: {}
    
    }
        this.array = {
            valoriza : 0
        }
    }
    

    componentDidMount(){
        //this.setState({ datoEva: this.props.record })   
        this.setState({ record: this.props.record })  
        this.setState({ tipo: this.props.tipo })  
       // console.log(this.props.record) 
       if(this.props.record.analisis){

       this.props.record.analisis.map((data, i) => {
        //console.log(data.total)
            this.setState((prevState) => {
                return{ totalSum: {...prevState.totalSum, [i]: data.total}}
            })
        })
    }
       this.allYears()

    }
    componentWillReceiveProps() {
        // if(this.props.years1 != this.props.years2 && parseInt(this.props.years2) > parseInt(this.props.years1)){
            console.log();
            this.setState({years1:this.props.years1, years2:this.props.years2})
        this.allYears()
        
      }

    allYears(){
        const {totalSum, dataYears} = this.state
        const years = []
        const total = totalSum
        let years1 = this.props.years1
        let years2 = this.props.years2
        //console.log("Hasta acá llegamos equisde");
        for (let index = years1; index <= years2; index++) {
            years.push(parseInt(index))
            //total.push("")
        }
        //console.log(this.props.years2)
        this.setState({
            titulos2: years,
        })
        
    }

    totaliza = (i, anio,) => {
        let dtY = {};
        const {years1, years2, dataYears, titulos2} = this.state;
        dtY[titulos2[i]] = {'precioa': {}, 'preciob': {}}; 

        // const total = totalSum

        // if(total.length === 0) {
        //    for (let index = years1; index <= years2; index++) {
        //         total.push("")
        //     }
        // }
        let valor_a=parseInt((document.getElementById('precioa_'+i).value).replace("$","") )
        let valor_b=parseInt((document.getElementById('preciob_'+i).value).replace("$",""))
        const a =  valor_a ? valor_a : 0
        const b =  valor_b ? valor_b : 0
        
        dtY[titulos2[i]].precioa = valor_a;
        dtY[titulos2[i]].preciob = valor_b;
        //console.log(dtY)
        this.setState(prevState => {
            return {dataYears: {...prevState.dataYears, [titulos2[i]]: dtY}};
        });
        const suma = a + b

        //total[i] = suma
        
        // this.setState(prevState => ({
        //     totalSum: [
        //       ...prevState.totalSum[i] = suma,
        //     ]
        //   }));

        //console.log(total)
        
        this.setState(prevState => {
           return {totalSum: {...prevState.totalSum, [i]: suma}}
        })

    }

    render(){
        const { translate } = this.props;
        const { titulos } = this.state;
        const {record} = this.state;
        const {tipo} = this.state;
        const {titulos2} = this.state;
        const  {dataYears} = this.state;
        const {total} = this.state;
        //console.log(dataYears)
        // let campos1 = []
        // let campos2 = []
        // let campos3 = []
        // titulos2.map((data, i)=>{
        //     titulos2.push(data.titulos2);
        //     campos1.push(data.campos1);
        //     campos2.push(data.campos2);
        //     campos3.push(data.campos3);
        // })
//          const totaliza=(e, i)=>{
//              console.log(e,i)

//             return 
//          let a = [];
//         let b= [];
//         let valortotal=[];
//         for(let i=0; i<2; i++){
//         let valor_a=document.getElementById('precioa_'+i)
//         let valor_b=document.getElementById('preciob_'+i)
//         if(valor_a && valor_b){
//             a.push(parseFloat(valor_a.value));
//             b.push(parseFloat(valor_b.value));
//         }
//         }
    
// //     if (a.length===2 && b.length===2){
// //        x = a + b;
// //     }
// //     this.array.total=x;
// // }
//         if(a.length===2 && b.length===2){
//             let x=0;
//             //console.log(a);
//             //console.log(b);
//             for(let i=0; i<2; i++){
    
//                 x=a[i]+b[i];
//                 console.log(x)
//                 valortotal[i] = parseFloat(x);

//                 this.array.valoriza=valortotal[i];
//                 }
                
//             }
//         }
        // const  titulos2=[
        //     { id: '2000', name: '2000' },
        //     { id: '2001', name: '2001' },
        //     { id: '2002', name: '2002' },
        //     { id: '2003', name: '2003' },
        //     { id: '2004', name: '2004' },
        //     { id: '2005', name: '2005' },
        //     { id: '2006', name: '2006' },
        //     { id: '2007', name: '2007' },
        //     { id: '2008', name: '2008' },
        //     { id: '2009', name: '2009' },
        //     { id: '2010', name: '2010' },
        //     { id: '2011', name: '2011' },
        //     { id: '2012', name: '2012' },
        //     { id: '2013', name: '2013' },
        //     { id: '2014', name: '2014' },
        //     { id: '2015', name: '2015' },
        //     { id: '2016', name: '2016' },
        //     { id: '2017', name: '2017' },
        //     { id: '2018', name: '2018' },
        //     { id: '2019', name: '2019' },
        //     { id: '2020', name: '2020' },
        //     { id: '2021', name: '2021' },
        //     { id: '2022', name: '2022' },
        //     { id: '2023', name: '2023' },
        //     { id: '2024', name: '2024' },
        //     { id: '2025', name: '2025' },
        //     { id: '2026', name: '2026' },
        //     { id: '2027', name: '2027' },
        //     { id: '2028', name: '2028' },
        //     { id: '2029', name: '2029' },
        //     { id: '2030', name: '2030' }
        // ];
  
   
        // let analisis=this.props.record.analisis
        // if(tipo==="tipo"){
        //     analisis.map((data, i)=>{
        //         if(analisis[i]){
        //         }
        //         else{
        //             analisis[i]={
        //                 precio: null,
        //                 precio2: null,
        //                 tipoPrecio: null,
        //                 id: null
        //             }
        //         }
        //     })
        // }
        // let analisis=[]
        // if(record && record.analisis){
        //     let eva=record.analisis;
        //     console.log(eva)
        //     eva.map((data, i)=>{
                
        //         analisis[i]={
        //             precio: data.precio,
        //             precio2: data.precio2,
        //             total: data.total,
        //             id: data.id
        //         }
        //     })
        // }
        let analisis=this.props.record.analisis
        //console.log(this.state.totalSum)
        if(tipo==="editar" &&  analisis){
            analisis.map((data, i)=>{
                if(analisis[i]){
                }
                else{
                    analisis[i]={
                        precio: null,
                        precio2: null,
                        total: this.state.totalSum[i],
                        tipoPrecio: null
                    }
                }
            })
        }
        if(tipo==="tipo"&& analisis){
            
            return(
                <React.Fragment>
                    <div style={{textAlign : "center", marginBottom: '25px'}}>
              <Typography variant="h3" component="h3">
                {translate('dynatest.resources.parametros_analises.table.titleTable')}
              </Typography>
          </div>
          <span></span>
                   <Table aria-label="simple table"  >
                        <TableHead>
              <TableRow>
                <TableCell align="right">{translate('dynatest.resources.parametros_analises.table.nameColumns.Anio')}</TableCell>
                <TableCell align="right">{translate('dynatest.resources.parametros_analises.table.nameColumns.PresupMantenPavimento')}</TableCell>
                <TableCell align="right">{translate('dynatest.resources.parametros_analises.table.nameColumns.PresupMantenOtrosElemen')}</TableCell>
                <TableCell align="right">{translate('dynatest.resources.parametros_analises.table.nameColumns.PresupTotal')}</TableCell>
                
              </TableRow>
            </TableHead>
            
                  
                        <TableBody>
                        {
                    titulos2.map((data, i) => (
                        <TableRow > 
                        <TableCell align="center"  >
                        <h3 style={{marginBottom: 0}}>{data}</h3>                    
                        </TableCell>
                      <TableCell align="right"><AInput label=" "  source={`precio_${i}`} id={`precioa_${i}`} onChange={ ()=> this.totaliza(i)} className="align-number" defaultValue={analisis[i].precio} fullWidth /></TableCell>  
                      <TableCell align="right"><AInput label=" "  source={`precio2_${i}`} id={`preciob_${i}`} onChange={() =>this.totaliza(i)} className="align-number" defaultValue={analisis[i].precio2} fullWidth /></TableCell>
                      
                      <TableCell align="right"><AInput label=" "  source={`total_${i}`} className="align-number" disabled fullWidth defaultValue={this.state.totalSum[i]} /></TableCell>  
        
                      <NumberInput style={{display: 'none'}} source={`tipoPrecio${i}`}  defaultValue={i}/>
                      {/* <NumberInput style={{display: "none"}} source={"id_"+i} defaultValue={analisis[i].id}/> */}
                    </TableRow>
                     ))                        
                    }
                    </TableBody>     
                
                       
                   
                        </Table>
                </React.Fragment>
            )
        }else{
            return(
                <React.Fragment>
                     <div style={{textAlign : "center", marginBottom: '25px'}}>
              <Typography variant="h3" component="h3">
              {translate('dynatest.resources.parametros_analises.table.titleTable')}
              </Typography>
          </div>
          <span></span>
                   <Table aria-label="simple table"  >
                        <TableHead>
              <TableRow>
                <TableCell align="right">{translate('dynatest.resources.parametros_analises.table.nameColumns.Anio')}</TableCell>
                <TableCell align="right">{translate('dynatest.resources.parametros_analises.table.nameColumns.PresupMantenPavimento')}</TableCell>
                <TableCell align="right">{translate('dynatest.resources.parametros_analises.table.nameColumns.PresupMantenOtrosElemen')}</TableCell>
                <TableCell align="right">{translate('dynatest.resources.parametros_analises.table.nameColumns.PresupTotal')}</TableCell>
                
              </TableRow>
            </TableHead>
            
                  
                        <TableBody>
                        {
                    titulos2.map((data, i) => (
                        <TableRow > 
                        <TableCell align="center"  >
                        <h3 style={{marginBottom: 0}}>{titulos2[i]}</h3>                    
                        </TableCell>
            
                     
                      <TableCell align="right"><AInput  label= " " source={`precio_${i}`} id={`precioa_${i}`} onChange={ ()=> this.totaliza(i)}  className="align-number"  fullWidth /></TableCell>  
                      <TableCell align="right"><AInput  label= " "  source={`precio2_${i}`} id={`preciob_${i}`} onChange={() =>this.totaliza(i)} className="align-number" fullWidth /></TableCell>  
                      <TableCell align="right"><AInput  label= " " source={`total_${i}`} className="align-number" disabled fullWidth defaultValue={this.state.totalSum[i]} /></TableCell>  
                      <NumberInput style={{display: 'none'}} source={`tipoPrecio${i}`}  defaultValue={i}/>
                    </TableRow>
                     ))                        
                    }
                    </TableBody>     
                
                       
                   
                        </Table>
                </React.Fragment>
            )
        }
        
            

    }
}

export default translate(Analisis);