import React, { useState, useEffect } from 'react';
import {
    TextInput, DateInput, SelectInput, NumberInput, ReferenceInput, TabbedForm, AutocompleteInput, ImageInput, ImageField,
    Toolbar, FormTab, SaveButton, required, minValue, minLength, maxLength, BooleanInput, SimpleFormIterator, FormDataConsumer,
    ArrayInput, maxValue
} from 'react-admin';
import { PRInput } from './../../../inputs/PRInput';
import Typography from '@material-ui/core/Typography';
import { PimsCreate } from './../../Create';
import { withStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import CancelIcon from '@material-ui/icons/Cancel';
import {
    latMininima, lngMininima, prValidationInicio, prValidationFin, abValidationInicio, abValidationFin, validacionmaxfechaact, isFechaInvalida
} from '../../validacionesInputs';
import { styles } from './../../../EstilosGrid';
import { Edit } from './../../Edit';
import CoordenadasProyecto from '../../CoordenadasInventario';
import AbsSegmento from '../../AbsSegmento';
import { ButtonCarousel } from './../../images/ButtonCarouselPeru';
import { ArrayInputImagesEdit } from './../../images/ArrayInputImagesEdit';
import { PInput } from '../../../inputs/PInput';
import { translate } from 'react-admin';


const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
));

export const OtrosElementosEdit = translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;

    const [elemento, setElemento] = useState(' ');
    const [unidads, setUnidad] = useState('');
    const [tramos, setTramo] = useState('');


    const asignarEstado = (value) => {
        setTipoEstado(value)
    }
    //ASIGNAR ELEMENTO
    const asignarElemento = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length); index++) { valor += value[index]; }
        setElemento(value)
    }

    //VALIDAR NUMERO CON 1 DECIMALES
    const valNum2Decimales = (value) => {
        if (value !== undefined || value !== null) {
            if (value !== 0) {
                if (value % 1 !== 0) {
                    var pattern = new RegExp("^[0-9]+([.][0-9]{2})$");
                    if (!pattern.test(value)) { return translate('dynatest.resources.otros_elementos.messagesVal.valNum2Decimales'); }
                }
            }
        }
    }

    //DECIMAL
    const decimal = (value) => {
        if (value % 1 == 0) {
            return translate('dynatest.resources.otros_elementos.messagesVal.decimal');
        }
    }

    //ASIGNAR EL ESTADO
    const [tipoEstado, setTipoEstado] = useState('');

    //VALIDAR NUMERO ENTERO
    const valEntero = (value) => {
        if (value !== undefined || value !== null) {
            if (value % 1 !== 0) { return translate('dynatest.resources.otros_elementos.messagesVal.valEntero'); }
        }
    }

    const ValidarUnidad = (value) => {
        setUnidad(value)
    }
    const ValidarTramo = (value) => {
        setTramo(value)
    }
    const Unidades = { unidadFuncional: unidads };
    const Tramoss = { tramo: tramos };
    /* VALIDAR LOS RANGOS QUE PUEDEN TENER LAS LATITUDES Y LONGITUDES */

    const [segmentos, setSegmento] = useState('');
    const ValidarSegmento = (value) => {
        setSegmento(value)

    }

    /* FIN DE VALIDACIÓN DE LOS RANGOS QUE PUEDEN TENER LAS LATITUDES Y LONGITUDES */

    /*VALORES POR DEFECTO DEL ARRAY DE COORDENADAS*/
    const [latitudMinimaArray, setLatitudArray] = useState('');
    const LatitudMinimaArray = (value) => {
        setLatitudArray(value)
    }
    const [longitudMinimaArray, setLongitudArray] = useState('');
    const LongitudMinimaArray = (value) => {
        setLongitudArray(value)
    }
    const [longitudMaximaArray, setLongitudMaxdArray] = useState('');
    const LongitudMaximaArray = (value) => {
        setLongitudMaxdArray(value)
    }
    const [latitudMaximaArray, setLatitudMaxdArray] = useState('');
    const LatitudMaximaArray = (value) => {
        setLatitudMaxdArray(value)
    }
    /*FIN DE LOS VALORES POR DEFECTO DEL ARRAY DE COORDENADAS*/

    const validarEnteroNT = (value) => {
        if (value) {
            if (!/^([0-9]+)*$/.test(value)) { return translate('dynatest.resources.otros_elementos.messagesVal.validarEnteroNT'); }
        }
    }

    const validarLogicaAbsFinCreate = (value) => {

        if (value !== undefined) {

            let prInicio = document.getElementById('ingresoAbInicio').value;
            prInicio = prInicio.split('K')
            if (prInicio[1] !== undefined && typeof (value) === "string") {
                prInicio = prInicio[1].split('+')
                value = value.split('K')
                value = value[1].split('+')

                if (parseFloat(value[0]) < parseFloat(prInicio[0])) {
                    return translate('dynatest.resources.otros_elementos.messagesVal.abscisaFinMayorInicio')
                }
                if (parseFloat(value[0]) === parseFloat(prInicio[0]) && parseFloat(value[1]) === parseFloat(prInicio[1])) {
                    return translate('dynatest.resources.otros_elementos.messagesVal.abscisaFinMayorInicio')
                }
                if (parseFloat(value[0]) === parseFloat(prInicio[0]) && parseFloat(value[1]) < parseFloat(prInicio[1])) {
                    return translate('dynatest.resources.otros_elementos.messagesVal.abscisaFinMayorInicio')
                }
            }

        }
    }

    /*VALIDAR IMÁGENES ACTUALES */
    const [imagenesOpen, setImagenesOpen] = useState('');
    const ImagenesEdit = (value) => {
        setImagenesOpen(value);

    }
    useEffect(() => {
        isFechaInvalida();
    }, [])
    return (
        <Edit title="dynatest.resources.otros_elementos.title.editTitle" {...props} undoable={false}>
            <TabbedForm redirect="list" >
                {/* ------ ELEMENTOS OTROS ------ */}
                <FormTab label="dynatest.resources.otros_elementos.tabs.tabsTitle.identificacion" redirect="list" >
                    <Grid container spacing={24}>
                        <CoordenadasProyecto />
                        {segmentos &&
                            <AbsSegmento segmento={segmentos} />
                        }
                        <Grid item xs={4}>
                            <ReferenceInput disabled label="resources.otros_elementos.fields.tipoElemento" source="tipoElemento" reference="tipo_elementos" fullWidth filter={{ puntos: 1, sistema: 'OTR' }} sort={{ field: 'nombre', order: 'ASC' }} validate={[required(), asignarElemento]}>
                                <SelectInput optionText="nombre" />
                            </ReferenceInput>
                        </Grid>
                        <Grid item xs={4}>
                            <SelectInput source="margen" label="resources.otros_elementos.fields.margen" choices={[
                                { id: translate('dynatest.resources.otros_elementos.select.margen.Derecha'), name: 'dynatest.resources.otros_elementos.select.margen.Derecha' },
                                { id: translate('dynatest.resources.otros_elementos.select.margen.Izquierda'), name: 'dynatest.resources.otros_elementos.select.margen.Izquierda' },
                                { id: translate('dynatest.resources.otros_elementos.select.margen.Centro'), name: 'dynatest.resources.otros_elementos.select.margen.Centro' },
                            ]} formClassName={classes.last_field} validate={[required()]} fullWidth />
                        </Grid>
                        <Grid item xs={4}>
                            <SelectInput label="resources.otros_elementos.fields.sentido" source='sentido' choices={[
                                { id: translate('dynatest.resources.otros_elementos.select.sentido.NORTEId'), name: 'dynatest.resources.otros_elementos.select.sentido.NORTEName' },
                                { id: translate('dynatest.resources.otros_elementos.select.sentido.SURId'), name: 'dynatest.resources.otros_elementos.select.sentido.SURName' },
                                { id: translate('dynatest.resources.otros_elementos.select.sentido.ESTEId'), name: 'dynatest.resources.otros_elementos.select.sentido.ESTEName' },
                                { id: translate('dynatest.resources.otros_elementos.select.sentido.OESTEId'), name: 'dynatest.resources.otros_elementos.select.sentido.OESTEName' }
                                ]}
                                fullWidth
                                formClassName={classes.grid_cont4}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="b" component="b">{translate('dynatest.resources.otros_elementos.subTitle.localizacion')}</Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Divider fullWidth />
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container spacing={24}>
                                <Grid item xs={4}>
                                    <ReferenceInput
                                        label="resources.otros_elementos.fields.unidadFuncional"
                                        source="unidadFuncional"
                                        reference="unidad_funcionals"
                                        filter={{ "properties[]": "fullCodigos" }}
                                        filterToQuery={searchText => ({ codigo: searchText })}
                                        sort={{ field: 'codigo', order: 'asc' }}
                                        validate={[required(), ValidarUnidad]}
                                        disabled
                                        fullWidth
                                    >
                                        <AutocompleteInput options={{ fullWidth: true, disabled: true }} optionText="fullCodigos" />
                                    </ReferenceInput>
                                </Grid>

                                {unidads &&
                                    <Grid item xs={4}>
                                        <ReferenceInput
                                            label="resources.otros_elementos.fields.tramo"
                                            source="tramo"
                                            alwaysOn
                                            reference="tramos"
                                            filter={{ unidadFuncional: unidads, "properties[]": "fullCodigos" }}
                                            filterToQuery={searchText => ({ codigo: searchText })}
                                            sort={{ field: 'codigo', order: 'ASC', }}
                                            validate={[required(), ValidarTramo]}
                                            formClassName={classes.grid_cont4}
                                            disabled
                                        >
                                            <AutocompleteInput options={{ fullWidth: true, disabled: true }} optionText="fullCodigos" />
                                        </ReferenceInput>
                                    </Grid>
                                }
                                {tramos &&
                                    <Grid item xs={4}>
                                        <ReferenceInput
                                            label="resources.otros_elementos.fields.segmento"
                                            source="segmento"
                                            reference="segmentos"
                                            sort={{ field: 'codigo', order: 'ASC' }}
                                            filter={{ tramo: tramos, "properties[]": "fullCodigos" }}
                                            filterToQuery={searchText => ({ codigo: searchText })}
                                            validate={[required(), ValidarSegmento]}
                                            formClassName={classes.grid_cont4}
                                            disabled
                                        >

                                            <AutocompleteInput options={{ fullWidth: true, disabled: true }} optionText="fullCodigos" />
                                        </ReferenceInput>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    {segmentos &&
                        <Grid container spacing={24}>
                            <Typography variant="h3" component="h3" className={classes.coord}>{translate('dynatest.resources.otros_elementos.subTitle.coordenadasIniciales')}</Typography>
                            <Divider fullWidth formClassName={classes.grid_cont_div} />
                        </Grid>
                    }
                    {segmentos && <PRInput label="resources.otros_elementos.fields.abscisa" id="ingresoAbInicio" source="abInicio" fullWidth validate={[abValidationInicio, required()]} formClassName={classes.grid_cont3s} />}
                    {segmentos && <PInput label="resources.otros_elementos.fields.PR" id="ingresoPrInicio" source="pInicio" fullWidth validate={[prValidationInicio]} formClassName={classes.grid_cont3} />}
                    {segmentos && <NumberInput label="resources.otros_elementos.fields.latitud" id="LatitudInicial" source="ubicaciones[0][lat]" fullWidth validate={[required(), latMininima, LatitudMinimaArray]} formClassName={classes.grid_cont3} />}
                    {segmentos && <NumberInput label="resources.otros_elementos.fields.longitud" id="LongitudInicial" source="ubicaciones[0][lng]" fullWidth validate={[required(), lngMininima, LongitudMinimaArray]} formClassName={classes.grid_cont3} />}
                    {segmentos &&
                        <Grid container spacing={24}>
                            <Typography variant="h3" component="h3" className={classes.coord}>{translate('dynatest.resources.otros_elementos.subTitle.coordenadasFinales')}</Typography>
                            <Divider fullWidth formClassName={classes.grid_cont_div} />
                        </Grid>
                    }
                    {segmentos && <PRInput label="resources.otros_elementos.fields.abscisa" id="ingresoAbFin" source="abFin" fullWidth validate={[abValidationFin, validarLogicaAbsFinCreate]} formClassName={classes.grid_cont3s} />}
                    {segmentos && <PInput label="resources.otros_elementos.fields.PR" id="ingresoPrFin" source="pFin" fullWidth validate={[prValidationFin]} formClassName={classes.grid_cont3} />}
                    {segmentos && <NumberInput label="resources.otros_elementos.fields.latitud" source="ubicaciones[1][lat]" fullWidth validate={[LatitudMaximaArray, latMininima]} formClassName={classes.grid_cont3} />}
                    {segmentos && <NumberInput label="resources.otros_elementos.fields.longitud" source="ubicaciones[1][lng]" fullWidth validate={[lngMininima, LongitudMaximaArray]} formClassName={classes.grid_cont3} />}
                </FormTab>
                {/* ---------- CARACTERÍSTICAS ------------- */}
                <FormTab label="dynatest.resources.otros_elementos.tabs.tabsTitle.caracteristicas">
                    <SelectInput source="interseccion" label="resources.otros_elementos.fields.interseccion" choices={[
                        { id: translate('dynatest.resources.otros_elementos.select.interseccionIDES.DeprimeIntersecta'), name: 'dynatest.resources.otros_elementos.select.interseccionIDES.DeprimeIntersecta' },
                        { id: translate('dynatest.resources.otros_elementos.select.interseccionIDES.DeprimeVia'), name: 'dynatest.resources.otros_elementos.select.interseccionIDES.DeprimeVia' },
                        { id: translate('dynatest.resources.otros_elementos.select.interseccionIDES.ElevaIntersecta'), name: 'dynatest.resources.otros_elementos.select.interseccionIDES.ElevaIntersecta' },
                        { id: translate('dynatest.resources.otros_elementos.select.interseccionIDES.ElevaVia'), name: 'dynatest.resources.otros_elementos.select.interseccionIDES.ElevaVia' },
                    ]} formClassName={classes.first_field} validate={[required()]} fullWidth />
                    <SelectInput source="material" label="resources.otros_elementos.fields.material" choices={[
                        { id: translate('dynatest.resources.otros_elementos.select.materialINIV.Adoquin'), name: 'dynatest.resources.otros_elementos.select.materialINIV.Adoquin' },
                        { id: translate('dynatest.resources.otros_elementos.select.materialINIV.Afirmado'), name: 'dynatest.resources.otros_elementos.select.materialINIV.Afirmado' },
                        { id: translate('dynatest.resources.otros_elementos.select.materialINIV.Concreto'), name: 'dynatest.resources.otros_elementos.select.materialINIV.Concreto' },
                        { id: translate('dynatest.resources.otros_elementos.select.materialINIV.Flexible'), name: 'dynatest.resources.otros_elementos.select.materialINIV.Flexible' },
                        { id: translate('dynatest.resources.otros_elementos.select.materialINIV.Otro'), name: 'dynatest.resources.otros_elementos.select.materialINIV.Otro' },
                    ]} formClassName={classes.last_field} validate={[required()]} fullWidth />
                    <SelectInput source="flujo_peatonal" label="resources.otros_elementos.fields.flujo_peatonal" choices={[
                        { id: translate('dynatest.resources.otros_elementos.select.flujo_peatonal.Alto'), name: 'dynatest.resources.otros_elementos.select.flujo_peatonal.Alto' },
                        { id: translate('dynatest.resources.otros_elementos.select.flujo_peatonal.Medio'), name: 'dynatest.resources.otros_elementos.select.flujo_peatonal.Medio' },
                        { id: translate('dynatest.resources.otros_elementos.select.flujo_peatonal.Bajo'), name: 'dynatest.resources.otros_elementos.select.flujo_peatonal.Bajo' },
                    ]} formClassName={classes.last_field} validate={[required()]} fullWidth />
                    <SelectInput source="clase_via_intersecta" label="resources.otros_elementos.fields.clase_via_intersecta" choices={[
                        { id: translate('dynatest.resources.otros_elementos.select.clase_via_intersecta.Urbana'), name: 'dynatest.resources.otros_elementos.select.clase_via_intersecta.Urbana' },
                        { id: translate('dynatest.resources.otros_elementos.select.clase_via_intersecta.Rural'), name: 'dynatest.resources.otros_elementos.select.clase_via_intersecta.Rural' },
                    ]} formClassName={classes.first_field} validate={[required()]} fullWidth />
                    <SelectInput source="tipo_via_intersecta" label="resources.otros_elementos.fields.tipo_via_intersecta" choices={[
                        { id: translate('dynatest.resources.otros_elementos.select.tipo_via_intersecta.Primaria'), name: 'dynatest.resources.otros_elementos.select.tipo_via_intersecta.Primaria' },
                        { id: translate('dynatest.resources.otros_elementos.select.tipo_via_intersecta.Secundaria'), name: 'dynatest.resources.otros_elementos.select.tipo_via_intersecta.Secundaria' },
                        { id: translate('dynatest.resources.otros_elementos.select.tipo_via_intersecta.Terciaria'), name: 'dynatest.resources.otros_elementos.select.tipo_via_intersecta.Terciaria' },
                    ]} formClassName={classes.last_field} validate={[required()]} fullWidth />
                    <SelectInput source="reductores_velocidad" label="resources.otros_elementos.fields.reductores_velocidad" choices={[
                        { id: translate('dynatest.resources.otros_elementos.select.reductores_velocidad.Si'), name: 'dynatest.resources.otros_elementos.select.reductores_velocidad.Si' },
                        { id: translate('dynatest.resources.otros_elementos.select.reductores_velocidad.No'), name: 'dynatest.resources.otros_elementos.select.reductores_velocidad.No' },
                    ]} formClassName={classes.last_field} validate={[required()]} fullWidth />
                    {/* DES NIVEL */}
                    {elemento === "/tipo_elementos/IDES" &&
                        <div className={classes.subtittle}><strong>{translate('dynatest.resources.otros_elementos.subTitle.caracteristicasPasoDesnivel')}</strong></div>
                    }
                    {elemento === "/tipo_elementos/IDES" &&
                        <Divider fullWidth />
                    }
                    {elemento === "/tipo_elementos/IDES" &&
                        <NumberInput label="resources.otros_elementos.fields.largo" source="largo" validate={[required(), minValue(0), valNum2Decimales]} formClassName={classes.first_field} fullWidth />
                    }
                    {elemento === "/tipo_elementos/IDES" &&
                        <NumberInput label="resources.otros_elementos.fields.carriles" source="carriles" validate={[required(), minValue(0), valEntero]} formClassName={classes.last_field} fullWidth />
                    }
                    {elemento === "/tipo_elementos/IDES" &&
                        <SelectInput label="resources.otros_elementos.fields.sentidoCarac" source="sentidoDesnivel" choices={[
                            { id: translate('dynatest.resources.otros_elementos.select.sentidoCarac.Ascendente'), name: 'dynatest.resources.otros_elementos.select.sentidoCarac.Ascendente' },
                            { id: translate('dynatest.resources.otros_elementos.select.sentidoCarac.Descendente'), name: 'dynatest.resources.otros_elementos.select.sentidoCarac.Descendente' },
                            { id: translate('dynatest.resources.otros_elementos.select.sentidoCarac.Doble'), name: 'dynatest.resources.otros_elementos.select.sentidoCarac.Doble' },
                        ]} formClassName={classes.last_field} validate={[required()]} fullWidth />
                    }

                </FormTab>
                {/* ------ DETALLES ---------- */}
                <FormTab label="dynatest.resources.otros_elementos.tabs.tabsTitle.detalles" >
                    <Divider fullWidth />
                    <SelectInput source="estado" label="resources.otros_elementos.fields.estado" choices={[
                        { id: translate('dynatest.resources.otros_elementos.select.estado.Nuevo'), name: 'dynatest.resources.otros_elementos.select.estado.Nuevo' },
                        { id: translate('dynatest.resources.otros_elementos.select.estado.Existente'), name: 'dynatest.resources.otros_elementos.select.estado.Existente' },
                        { id: translate('dynatest.resources.otros_elementos.select.estado.Reemplazo'), name: 'dynatest.resources.otros_elementos.select.estado.Reemplazo' },
                    ]} formClassName={classes.grid_cont4_f}
                        validate={[required(), asignarEstado]} fullWidth
                    />
                    {tipoEstado === translate('dynatest.resources.otros_elementos.select.estado.Reemplazo') &&
                        <SelectInput source="motivo" label="resources.otros_elementos.fields.motivo" choices={[
                            { id: translate('dynatest.resources.otros_elementos.select.motivo.Instalacion'), name: 'dynatest.resources.otros_elementos.select.motivo.Instalacion' },
                            { id: translate('dynatest.resources.otros_elementos.select.motivo.ReemplazoProgramado'), name: 'dynatest.resources.otros_elementos.select.motivo.ReemplazoProgramado' },
                            { id: translate('dynatest.resources.otros_elementos.select.motivo.Accidente'), name: 'dynatest.resources.otros_elementos.select.motivo.Accidente' },
                            { id: translate('dynatest.resources.otros_elementos.select.motivo.Vandalismo'), name: 'dynatest.resources.otros_elementos.select.motivo.Vandalismo' },
                            { id: translate('dynatest.resources.otros_elementos.select.motivo.Otro'), name: 'dynatest.resources.otros_elementos.select.motivo.Otro' },
                        ]} formClassName={classes.grid_cont4_s}
                            validate={[required()]} fullWidth />
                    }
                    {tipoEstado === translate('dynatest.resources.otros_elementos.select.estado.Reemplazo') &&
                        <FormDataConsumer formClassName={classes.grid_cont4}>
                            {({ formData, ...rest }) =>
                                <ReferenceInput
                                    label="resources.otros_elementos.fields.elementoReemplazo_id"
                                    source="elementoReemplazo_id"
                                    reference="otros_elementos"
                                    filter={{
                                        tipoElemento: elemento,
                                        estados: "Activo",
                                        unidadFuncional: unidads,
                                        tramo: tramos,
                                        segmento: segmentos,
                                        exclude: (formData) ? formData.id : null
                                    }}
                                    fullWidth
                                >
                                    <AutocompleteInput options={{ fullWidth: true }} optionText="fullCodigoConsecutivo" />
                                </ReferenceInput>
                            }
                        </FormDataConsumer>
                    }
                    <br />
                    
                    <SelectInput label="resources.otros_elementos.fields.estados" source="estados" allowEmpty emptyValue="" choices={[
                        { id: translate('dynatest.resources.otros_elementos.select.estados.Activo'), name: "dynatest.resources.otros_elementos.select.estados.Activo" },
                        { id: translate('dynatest.resources.otros_elementos.select.estados.Inactivo'), name: "dynatest.resources.otros_elementos.select.estados.Inactivo" },
                    ]} validate={[required()]} fullWidth formClassName={classes.grid_cont4_f} />
                    <div></div>
                    <Grid container spacing={24}>
                        <Grid item xs={4}>
                            <DateInput label="resources.otros_elementos.fields.fecha_instalacion" autoFocus source="fecha_instalacion" fullWidth formClassName={classes.grid_cont4s} validate={[validacionmaxfechaact]} />
                        </Grid>
                        <Grid item xs={4}>
                            <ReferenceInput
                                label="resources.otros_elementos.fields.proveedor"
                                source="proveedor"
                                reference="proveedors"
                                filter={{ proyecto: props.proyecto, sistema: 'OTR' }}
                                sort={{ field: 'razonSocial', order: 'ASC' }}
                                filterToQuery={searchText => ({ razonSocial: searchText })}
                                formClassName={classes.grid_cont4}
                                validate={[required()]}
                            >
                                <AutocompleteInput options={{ fullWidth: true }} optionText="razonSocial" />
                            </ReferenceInput>
                        </Grid>
                        <Grid item xs={4}>
                            <NumberInput label="resources.otros_elementos.fields.garantia" source="garantia" fullWidth validate={[required(), minValue(0), maxValue(99999999), validarEnteroNT]} formClassName={classes.grid_cont4} />
                        </Grid>
                    </Grid><Grid item xs={6}>
                        <BooleanInput label="dynatest.resources.otros_elementos.boolean.mantenimiento" source="mantenimiento" fullWidth />
                    </Grid>
                    <div></div>
                    <TextInput source="observaciones" label="resources.otros_elementos.fields.observaciones" validate={[required(), minLength(0), maxLength(200)]} fullWidth formClassName={classes.first_field2} />
                    <FormDataConsumer>
                        {({ formData, ...rest }) => { formData.updatedIn = "api"; }}
                    </FormDataConsumer>
                </FormTab>
                <FormTab label="dynatest.resources.otros_elementos.tabs.tabsTitle.fotografias">
                    <Grid container spacing={24}>
                        <Grid item xs={8}>
                            {/* <ArrayInput label="" source="images" validate={ImagenesEdit} >
                                <SimpleFormIterator disableAdd disableRemove >
                                    <ImageInput multiple={true} source="images" label=" " maxSize={1000000000} accept="image/jpeg, image/png">
                                        <ImageField source="src" title="title" />
                                    </ImageInput>                           
                                    <ReferenceInput disabled label="Tipo de elemento" source="tipoImagen" reference="tipo_images" fullWidth validate={[required()]} sort={{ field: 'nombre', order: 'ASC' }}  formClassName={classes.grid_cont6}>
                                        <SelectInput  optionText="nombre"  />
                                    </ReferenceInput>                          
                                </SimpleFormIterator> 
                            </ArrayInput>             */}
                            <FormDataConsumer>
                                {({ formData, ...rest }) =>
                                    <ArrayInputImagesEdit record={formData} {...rest} />
                                }
                            </FormDataConsumer>
                        </Grid>
                        <Grid item xs={4}>
                            <FormDataConsumer>
                                {({ formData, ...rest }) =>
                                    <ButtonCarousel record={formData} {...rest} />
                                }
                            </FormDataConsumer>
                        </Grid>
                    </Grid>
                </FormTab>
            </TabbedForm>
        </Edit>
    )
}));