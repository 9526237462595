
import React from 'react';
import { List, Datagrid, EditButton, ShowButton,TextField,ReferenceField,NumberField, Loading, Filter, ReferenceInput, AutocompleteInput } from 'react-admin';
import {  BulkDeleteWithConfirmButton  } from 'react-admin';
import { Fragment } from 'react';
import { translate } from 'react-admin';
import { CardActions, CreateButton, ExportButton } from 'react-admin';
const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);
const ListAction = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
        <ExportButton basePath={basePath}/>
    </CardActions>
);
const ListAction2 = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
    </CardActions>
);
const Filtro = translate((props) => (
    <Filter {...props}>
        <ReferenceInput
                label="resources.modelo_costos_pers.fields.unidadFuncional"
                source="unidadFuncional"
                reference="unidad_funcionals"
                alwaysOn
            >
                <AutocompleteInput optionText="fullCodigos" />
            </ReferenceInput>
    </Filter>
));


export const ModeloCostosPersList = translate(({ permissions, ...props }) => {
    if (!permissions) return <Loading />;  
    return (
        <List {...props} 
            title="dynatest.resources.modelo_costos_pers.title.listTitle"  
            sort={{ field: 'updatedAt', order: 'DESC' }} 
            actions={permissions['reparacionesMenores']  == 1 ? <ListAction /> : permissions['reparacionesMenores']  == 2 ? <ListAction2 />: null} 
            filters={<Filtro />} 
            bulkActionButtons={ permissions['reparacionesMenores']  == 1 ? <PostBulkActionButtons /> : null} >
            <Datagrid >
                <ReferenceField label="resources.modelo_costos_pers.fields.unidadFuncional" source="unidadFuncional" reference="unidad_funcionals" linkType={false}>
                    <TextField source="fullCodigos" />
                </ReferenceField>
                <NumberField label="resources.modelo_costos_pers.fields.variableAFlex" source="variableAFlex" />
                <NumberField label="resources.modelo_costos_pers.fields.variableBFlex" source="variableBFlex" />
                <NumberField label="resources.modelo_costos_pers.fields.variableXFlex" source="variableXFlex" />
                <NumberField label="resources.modelo_costos_pers.fields.variableYFlex" source="variableYFlex" /> 
                {permissions['reparacionesMenores'] == 1 || permissions['reparacionesMenores'] == 2 || permissions['reparacionesMenores'] == 4 ? <ShowButton/>: null}
                {permissions['reparacionesMenores'] == 1 || permissions['reparacionesMenores'] == 2 ? <EditButton/>: null}
            </Datagrid>
        </List>
)});