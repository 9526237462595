import React from 'react';
import poFormatter from './../format/POFormat';

import { TextInput } from 'react-admin';


export const POInput = (props) => (
    <TextInput 
        {...props} 
        InputProps={{
            inputComponent: poFormatter,
        }}
        style={{textAlign:'right'}}
    />
);

