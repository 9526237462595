import React, { useState } from 'react';
import { CardActions, TextInput, TabbedForm, Toolbar, Datagrid, ShowController, ShowView, TextField, DateField, ReferenceField, BooleanField, FormTab, ArrayField, NumberField, ListButton, EditButton } from 'react-admin';
// import CardActions from '@material-ui/core/CardActions';
import { PRField } from './../../../fields/PRField';
import { PField } from './../../../fields/PField';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core';
import { styles } from './../../../EstilosGrid';
import { GMapField } from '../../../fields/GMapField';
import { ButtonCarousel } from './../../images/ButtonCarousel';
import { translate } from 'react-admin';



export const ElementoAlrededorShow = translate(withStyles(styles)(({ classes, record, ...props }) => {
    const { translate } = props;
    const [imagen, setImagen] = useState(true);
    const ValidarImagen = (value) => {
        // console.log(value + "Aqui est la imagen ")
        if (value !== null && value !== undefined && value !== 'undefined') {
            let lenghtObject = Object.keys(value);
            let valor = '';
            for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
            setImagen(value)
            // console.log(value + "Aqui est la imagen ")
        }
    }
    //let elemento = "/tipo_elementos/COMR";
    return (
        <ShowController {...props} title="Información Elemento Alrededor">
            {controllerProps =>
                <ShowView {...props} {...controllerProps} title="dynatest.resources.elemento_alrededors.title.showTitle" actions={<ButtonCarousel />}>
                    <TabbedForm toolbar={false}>
                        <FormTab label="dynatest.resources.elemento_alrededors.tabs.tabsTitle.identificacion">
                            <TextField label="resources.elemento_alrededors.fields.smartCode" source="smartCode" fullWidth formClassName={classes.grid_cont4} />
                            {/* <NumberField label="Consecutivo" source="fullConsecutivo" fullWidth formClassName={[classes.grid_cont4]} /> */}
                            <TextInput source="tipoElemento_.src" validate={ValidarImagen} style={{ display: 'none' }} />
                            <ReferenceField label="resources.elemento_alrededors.fields.tipoElemento" source="tipoElemento" reference="tipo_elementos" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <TextField source="nombre" />
                            </ReferenceField>
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/CUNT" &&
                                <TextField label="resources.elemento_alrededors.fields.tipoCuneta" source="tipoCuneta" fullWidth formClassName={classes.grid_cont4s} />
                            }
                            <TextField label="resources.elemento_alrededors.fields.margen" source="margen" fullWidth formClassName={classes.grid_cont4} />
                            <TextField label="resources.elemento_alrededors.fields.sentido" source="sentido" fullWidth formClassName={classes.grid_cont3} />

                            <Typography variant="b" component="b">{translate('dynatest.resources.elemento_alrededors.subTitle.localizacion')}</Typography>
                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <ReferenceField
                                label="resources.elemento_alrededors.fields.unidadFuncional"
                                source="unidadFuncional"
                                reference="unidad_funcionals"
                                linkType={false}
                                fullWidth
                                formClassName={classes.grid_cont4}
                            >
                                <TextField source="fullCodigos" />
                            </ReferenceField>
                            <ReferenceField label="resources.elemento_alrededors.fields.tramo" source="tramo" reference="tramos" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <TextField source="fullCodigos" />
                            </ReferenceField>
                            <ReferenceField label="resources.elemento_alrededors.fields.segmento" source="segmento" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont3}>
                                <TextField source="fullCodigos" />
                            </ReferenceField>
                            <PRField label="resources.elemento_alrededors.fields.abInicio" source="abInicio" fullWidth formClassName={classes.grid_cont4} />
                            <PRField label="resources.elemento_alrededors.fields.abFin" source="abFin" fullWidth formClassName={classes.grid_cont4} />
                            <NumberField label="resources.elemento_alrededors.fields.fullResta" source="fullResta" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                            <br/>
                            <PField label="resources.elemento_alrededors.fields.pInicio" source="pInicio" fullWidth formClassName={classes.grid_cont4} />
                            <PField label="resources.elemento_alrededors.fields.pFin" source="pFin" fullWidth formClassName={classes.grid_cont4} />
                            <br/>
                            <Typography variant="b" component="b">{translate('dynatest.resources.elemento_alrededors.subTitle.coordenadasIniciales')}</Typography>
                            <Divider fullWidth style={{ width: '300%', marginTop: '10px' }} />
                            <NumberField label="resources.elemento_alrededors.fields.latitudInicial" source="latitudInicial" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={[classes.grid_cont4]} />
                            <NumberField label="resources.elemento_alrededors.fields.longitudInicial" source="longitudInicial" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={[classes.grid_cont3]} />
                            <br />
                            <Typography variant="b" component="b">{translate('dynatest.resources.elemento_alrededors.subTitle.coordenadasFinales')}</Typography>
                            <Divider fullWidth style={{ width: '300%', marginTop: '10px' }} />
                            <NumberField label="resources.elemento_alrededors.fields.latitudFinal" source="latitudFinal" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4} />
                            <NumberField label="resources.elemento_alrededors.fields.longitudFinal" source="longitudFinal" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                            <GMapField style={{ width: "100%" }}
                                defaultZoom={12}
                                defaultCenter={{ lat: 4.7567039, lng: -74.0311056 }}
                                source="ubicacionMapa"
                                multipleMarkers="true"
                                googleKey="AIzaSyCivTXzJBYRBK8nosBYRFXnv-hGXIbhnYY"
                                icon={imagen}
                            />
                        </FormTab>
                        <FormTab label="dynatest.resources.elemento_alrededors.tabs.tabsTitle.caracteristicas">
                            <br></br>
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/COMR" &&
                                <TextField label="resources.elemento_alrededors.fields.tipoServicio" source="tipoServicio" fullWidth formClassName={classes.grid_cont3} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/COMR" &&
                                <TextField label="resources.elemento_alrededors.fields.tipoAcceso" source="tipoAcceso" fullWidth formClassName={classes.grid_cont3} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/COMR" &&
                                <TextField label="resources.elemento_alrededors.fields.estacionamiento" fullWidth source="estacionamiento" formClassName={classes.grid_cont3} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/COMR" &&
                                <br />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/COMR" &&
                                <NumberField label="resources.elemento_alrededors.fields.largo" source="largo" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/COMR" &&
                                <NumberField label="resources.elemento_alrededors.fields.ancho" source="ancho" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/COMR" &&
                                <NumberField label="resources.elemento_alrededors.fields.area" source="area" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                            }

                            {(controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/COMR" && controllerProps.record && controllerProps.record.tipoServicio === "Barraca") &&
                                <TextField label="resources.elemento_alrededors.fields.identificacion" fullWidth source="identificacion" style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont6} />
                            }
                            {(controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/COMR" && controllerProps.record && controllerProps.record.tipoServicio === "Barraca") &&
                                <TextField label="resources.elemento_alrededors.fields.propietario" fullWidth source="propietario" formClassName={classes.grid_cont6} />
                            }
                            {(controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/COMR" && controllerProps.record && controllerProps.record.tipoServicio === "Barraca") &&
                                <TextField label="resources.elemento_alrededors.fields.emailPropietario" fullWidth source="emailPropietario" style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont6} />
                            }
                            {(controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/COMR" && controllerProps.record && controllerProps.record.tipoServicio === "Barraca") &&
                                <NumberField label="resources.elemento_alrededors.fields.telefonoPropietario" source="telefonoPropietario" fullWidth formClassName={classes.grid_cont6} />
                            }

                            {/* ------ Elemento = Márgenes, Separadores ------- */}

                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/MARG" &&
                                <TextField label="resources.elemento_alrededors.fields.tipoServicioCarac" source="tipoServicio" fullWidth formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/MARG" &&
                                <br></br>
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/MARG" &&
                                <NumberField label="resources.elemento_alrededors.fields.largoM" source="largo" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/MARG" &&
                                <NumberField label="resources.elemento_alrededors.fields.anchoM" source="ancho" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/MARG" &&
                                <NumberField label="resources.elemento_alrededors.fields.areaM" source="area" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/SEPD" &&
                                <TextField label="resources.elemento_alrededors.fields.tipoServicioCarac" source="tipoServicio" fullWidth formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/SEPD" &&
                                <br></br>
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/SEPD" &&
                                <NumberField label="resources.elemento_alrededors.fields.largoM" source="largo" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/SEPD" &&
                                <NumberField label="resources.elemento_alrededors.fields.anchoM" source="ancho" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/SEPD" &&
                                <NumberField label="resources.elemento_alrededors.fields.areaM" source="area" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                            }
                            {/* ------ Elemento = Márgenes, Separadores ------- */}
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/OBST" &&
                                <TextField label="resources.elemento_alrededors.fields.tipoServicioObs" fullWidth source="tipoServicio" formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/OBST" &&
                                <NumberField label="resources.elemento_alrededors.fields.longitudAfectacion" source="longitudAfectacion" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/OBST" &&
                                <br></br>
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/OBST" &&
                                <NumberField label="resources.elemento_alrededors.fields.largoM" source="largo" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/OBST" &&
                                <NumberField label="resources.elemento_alrededors.fields.anchoM" source="ancho" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/OBST" &&
                                <NumberField label="resources.elemento_alrededors.fields.areaOb" source="area" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                            }

                            {/* ------ Elemento = Zona inestable ------- */}
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/ZINT" &&
                                <TextField label="resources.elemento_alrededors.fields.tipoServicioZINT" source="tipoServicio" fullWidth formClassName={classes.grid_cont3} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/ZINT" &&
                                <TextField label="resources.elemento_alrededors.fields.riesgo" source="riesgo" fullWidth formClassName={classes.grid_cont3} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/ZINT" &&
                                <TextField label="resources.elemento_alrededors.fields.seccion" source="seccion" fullWidth formClassName={classes.grid_cont3} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/ZINT" &&
                                <br></br>
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/ZINT" &&
                                <NumberField label="resources.elemento_alrededors.fields.longitudAfectacionZINT" source="longitudAfectacion" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont6} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/ZINT" &&
                                <TextField label="resources.elemento_alrededors.fields.tipoTerraplen" source="tipoTerraplen" fullWidth formClassName={classes.grid_cont3} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/ZINT" &&
                                <TextField label="resources.elemento_alrededors.fields.cantidadBermas" source="cantidadBermas" fullWidth formClassName={classes.grid_cont3} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/ZINT" &&
                                <br></br>
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/ZINT" &&
                                <NumberField label="resources.elemento_alrededors.fields.alturaPromedioBermas" source="alturaPromedioBermas" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont6} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/ZINT" &&
                                <br></br>
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/ZINT" &&
                                <Typography variant="h3" component="h3" className={classes.coord}>{translate('dynatest.resources.elemento_alrededors.subTitle.TramosPendVariable')}</Typography>
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/ZINT" &&
                                <Divider fullWidth style={{ marginTop: '10px' }} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/ZINT" &&
                                <ArrayField label=" " fullWidth source="tramosPendientes">
                                    <Datagrid>
                                        <NumberField source="tramoPendiente" label="resources.elemento_alrededors.fields.tramoPendiente" fullWidth formClassName={classes.grid_cont3} />
                                        <PRField label="resources.elemento_alrededors.fields.abInicio" id="ingresoAbFin" source="abInicio" fullWidth formClassName={classes.grid_cont3} />
                                        <NumberField source="pendTransversal" label="resources.elemento_alrededors.fields.pendTransversal" fullWidth formClassName={classes.grid_cont3} style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} />
                                    </Datagrid>
                                </ArrayField>
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/ZINT" &&
                                <div></div>
                            }
                            {controllerProps.record && controllerProps.record.obraEstabilizacion &&
                                <Divider fullWidth />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/ZINT" &&
                                <BooleanField label="resources.elemento_alrededors.fields.obraEstabilizacion" source="obraEstabilizacion" fullWidth formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/ZINT" &&
                                <div></div>
                            }
                            {controllerProps.record && controllerProps.record.obraEstabilizacion &&
                                <TextField label="resources.elemento_alrededors.fields.tipoObra" fullWidth source="tipoObra" formClassName={classes.grid_cont6} />
                            }
                            {controllerProps.record && controllerProps.record.obraEstabilizacion &&
                                <div></div>
                            }
                            {controllerProps.record && controllerProps.record.obraEstabilizacion &&
                                <NumberField label="resources.elemento_alrededors.fields.largoM" source="largo" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                            }
                            {controllerProps.record && controllerProps.record.obraEstabilizacion &&
                                <NumberField label="resources.elemento_alrededors.fields.anchoM" source="ancho" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                            }
                            {controllerProps.record && controllerProps.record.obraEstabilizacion &&
                                <NumberField label="resources.elemento_alrededors.fields.areaOb" source="area" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                            }

                            {controllerProps.record && controllerProps.record.obraEstabilizacion &&
                                <Divider fullWidth />
                            }
                            {controllerProps.record && controllerProps.record.obraEstabilizacion &&
                                <BooleanField label="resources.elemento_alrededors.fields.fichaInspeccion" source="fichaInspeccion" fullWidth formClassName={classes.grid_cont6} />
                            }
                            {controllerProps.record && controllerProps.record.fichaInspeccion &&
                                <div></div>
                            }
                            {controllerProps.record && controllerProps.record.fichaInspeccion &&
                                <TextField label="resources.elemento_alrededors.fields.inspector" source="inspector" fullWidth formClassName={classes.grid_cont3} />
                            }
                            {controllerProps.record && controllerProps.record.fichaInspeccion &&
                                <DateField label="resources.elemento_alrededors.fields.fechaInspeccion" source="fechaInspeccion" fullWidth formClassName={classes.grid_cont3} />
                            }
                            {controllerProps.record && controllerProps.record.fichaInspeccion &&
                                <TextField label="resources.elemento_alrededors.fields.nombreFicha" source="nombreFicha" fullWidth formClassName={classes.grid_cont3} />
                            }
                            {controllerProps.record && controllerProps.record.fichaInspeccion &&
                                <Divider fullWidth />
                            }
                            {controllerProps.record && controllerProps.record.fichaInspeccion &&
                                <BooleanField label="resources.elemento_alrededors.fields.estudioEstabilidad" source="EstudioEstabilidad" formClassName={classes.grid_cont6} />
                            }
                            {console.log(controllerProps.record, "controlador")}
                            {controllerProps.record && controllerProps.record.EstudioEstabilidad &&
                                <div></div>
                            }
                            {controllerProps.record && controllerProps.record.EstudioEstabilidad &&
                                <TextField label="resources.elemento_alrededors.fields.consultor" source="consultor" fullWidth formClassName={classes.grid_cont3} />
                            }
                            {controllerProps.record && controllerProps.record.EstudioEstabilidad &&
                                <DateField label="resources.elemento_alrededors.fields.fechaEstudio" source="fechaEstudio" fullWidth formClassName={classes.grid_cont3} />
                            }
                            {controllerProps.record && controllerProps.record.EstudioEstabilidad &&
                                <TextField label="resources.elemento_alrededors.fields.codigoEstudio" source="codigoEstudio" fullWidth formClassName={classes.grid_cont3} />
                            }
                            {controllerProps.record && controllerProps.record.EstudioEstabilidad &&
                                <br/>
                            }
                            {controllerProps.record && controllerProps.record.EstudioEstabilidad &&
                                <TextField label="resources.elemento_alrededors.fields.nombreEstudio" source="nombreEstudio" fullWidth formClassName={classes.grid_cont3} />
                            }

                            {/* ------ Elemento Ocupación ------- */}

                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/OCPN" &&
                                <TextField label="resources.elemento_alrededors.fields.tipoServicioOCPN" source="tipoServicio" fullWidth formClassName={classes.grid_cont6} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/OCPN" &&
                                <TextField label="resources.elemento_alrededors.fields.identificacion" source="identificacion" fullWidth formClassName={classes.grid_cont6} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/OCPN" &&
                                <TextField label="resources.elemento_alrededors.fields.propietario" source="propietario" fullWidth formClassName={classes.grid_cont6} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/OCPN" &&
                                <TextField label="resources.elemento_alrededors.fields.emailPropietario" source="emailPropietario" fullWidth formClassName={classes.grid_cont6} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/OCPN" &&
                                <TextField label="resources.elemento_alrededors.fields.telefonoPropietario" source="telefonoPropietario" fullWidth formClassName={classes.grid_cont6} />
                            }

                            {/* ------ Elemento Acceso ------- */}

                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/ACCS" &&
                                <TextField label="resources.elemento_alrededors.fields.tipoServicioObs" source="tipoServicio" fullWidth formClassName={classes.grid_cont6} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/ACCS" &&
                                <TextField label="resources.elemento_alrededors.fields.longitudAcceso" source="longitudAcceso" fullWidth formClassName={classes.grid_cont6} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/ACCS" &&
                                <TextField label="resources.elemento_alrededors.fields.anchoAcceso" source="anchoAcceso" fullWidth formClassName={classes.grid_cont6} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/ACCS" &&
                                <TextField label="resources.elemento_alrededors.fields.identificacion" source="identificacion" fullWidth formClassName={classes.grid_cont6} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/ACCS" &&
                                <TextField label="resources.elemento_alrededors.fields.propietario" source="propietario" fullWidth formClassName={classes.grid_cont6} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/ACCS" &&
                                <TextField label="resources.elemento_alrededors.fields.emailPropietario" source="emailPropietario" fullWidth formClassName={classes.grid_cont6} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/ACCS" &&
                                <TextField label="resources.elemento_alrededors.fields.telefonoPropietario" source="telefonoPropietario" fullWidth formClassName={classes.grid_cont6} />
                            }
                            

                        </FormTab>
                        <FormTab label="dynatest.resources.elemento_alrededors.tabs.tabsTitle.detalles">
                            <br></br>
                            {/* {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/COMR" &&
                                <TextField label="resources.elemento_alrededors.fields.estado" fullWidth source="estado" fullWidth formClassName={classes.grid_cont4} />
                            } */}
                            {/* ------ Elemento = Márgenes, Separadores ------- */}
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/MARG" &&
                                <TextField label="resources.elemento_alrededors.fields.estado" fullWidth source="estado" formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/SEPD" &&
                                <TextField label="resources.elemento_alrededors.fields.estado" fullWidth source="estado" formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/ZINT" &&
                                <TextField label="resources.elemento_alrededors.fields.estado" fullWidth source="estado" formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.estado === translate('dynatest.resources.elemento_alrededors.select.estado.Reemplazo') &&
                                <TextField label="resources.elemento_alrededors.fields.condicion" source="condicion" fullWidth formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.estado === translate('dynatest.resources.elemento_alrededors.select.estado.Reemplazo') &&

                                <ReferenceField
                                    label="resources.elemento_alrededors.fields.elementoReemplazo"
                                    source="elementoReemplazo"
                                    reference="elemento_alrededors"
                                    linkType={false}
                                    fullWidth
                                    formClassName={classes.grid_cont3}
                                >
                                    <TextField source="fullCodigoConsecutivo" />
                                </ReferenceField>
                            }
                            {controllerProps.record && controllerProps.record.estado !== translate('dynatest.resources.elemento_alrededors.select.estado.Reemplazo') &&
                                <div></div>
                            }

                            {/* --------ZONA INESTABLE -----------*/}

                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/ZINT" &&
                                <DateField label="resources.elemento_alrededors.fields.fechaConstruccion" source="fechaConstruccion" fullWidth formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/ZINT" &&
                                <TextField label="resources.elemento_alrededors.fields.proveedor" source="proveedor_.razonSocial" fullWidth formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/ZINT" &&
                                <NumberField label="resources.elemento_alrededors.fields.garantia" source="garantia" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                            }
                            <br />
                            
                            
                            <TextField label="resources.elemento_alrededors.fields.estados" source="estados" fullWidth formClassName={classes.grid_cont4} />
                            <br />
                            <BooleanField label="resources.elemento_alrededors.fields.mantenimiento" source="mantenimiento" formClassName={classes.grid_cont12s} />
                            <TextField label="resources.elemento_alrededors.fields.observaciones" source="observaciones" formClassName={classes.grid_cont12s} />

                        </FormTab>
                        <FormTab label="dynatest.resources.elemento_alrededors.tabs.tabsTitle.auditoria">
                            <br></br>
                            <DateField source="createdAt" label="resources.elemento_alrededors.fields.createdAt" fullWidth formClassName={classes.grid_cont4} showTime/>
                            <TextField source="createdBy" label="resources.elemento_alrededors.fields.createdBy" fullWidth formClassName={classes.grid_cont4} />
                            <TextField source="createdIn" label="resources.elemento_alrededors.fields.createdEn" fullWidth formClassName={classes.grid_cont3} />
                            <span></span>
                            <DateField source="updatedAt" label="resources.elemento_alrededors.fields.updatedAt" fullWidth formClassName={classes.grid_cont4} showTime/>
                            <TextField source="updatedBy" label="resources.elemento_alrededors.fields.updatedBy" fullWidth formClassName={classes.grid_cont4} />
                            <TextField source="updatedIn" label="resources.elemento_alrededors.fields.updatedEn" fullWidth formClassName={classes.grid_cont3} />
                            <span></span>
                        </FormTab>

                    </TabbedForm>
                </ShowView>
            }
        </ShowController>
    )
}));