import React, {useState, Fragment} from 'react';
import { List, Datagrid, TextField, EditButton, ShowButton, Filter, ReferenceInput, AutocompleteInput,
    BulkDeleteWithConfirmButton,SelectInput, Loading
} from 'react-admin';
import { PRField } from './../../../fields/PRField';
import { PField } from './../../../fields/PField';
import { translate } from 'react-admin';
import { CardActions, CreateButton, ExportButton } from 'react-admin';
const FiltroAlrededor= translate((props) => {
    const { translate } = props;
    const [uf, setUf] = useState('none');
    const ufSet = (value) => {
        setUf(value);
    }
    const [tramo, setTramo] = useState('none');
    const tramoSet = (value) => {
        setTramo(value);
    }
        
    return(
    <Filter {...props}>
        <ReferenceInput 
            id="unidadFuncional"
            label="resources.elemento_pontons.fields.unidadFuncional" 
            source="unidadFuncional"  
            reference="unidad_funcionals" 
            filterToQuery={searchText => ({ codigo: searchText })} 
            sort={{field : 'codigo', order : 'asc'}}
            validate={[ufSet]}
            alwaysOn
            fullWidth 
        >
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
        <ReferenceInput 
            label="resources.elemento_pontons.fields.tramo" 
            source="tramo" 
            alwaysOn 
            reference="tramos" 
            filter={{ unidadFuncional: uf}}
            validate={[tramoSet]}
            filterToQuery={searchText => ({ codigo: searchText })} 
            sort={{ 
                field: 'codigo', 
                order: 'ASC' ,
                
            }}>
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
        
        <ReferenceInput 
            label="resources.elemento_pontons.fields.segmento" 
            source="segmento" 
            alwaysOn 
            reference="segmentos" 
            sort={{ field: 'codigo', order: 'ASC' }}
            filter={{tramo : tramo}}
            filterToQuery={searchText => ({ codigo: searchText })} 
        >
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
        <SelectInput label="resources.elemento_pontons.fields.estados" source="estados" choices={[
                { id: translate('dynatest.resources.elemento_pontons.select.estados.Activo'), name: "dynatest.resources.elemento_pontons.select.estados.Activo" },
                { id: translate('dynatest.resources.elemento_pontons.select.estados.Inactivo'), name: "dynatest.resources.elemento_pontons.select.estados.Inactivo" },
            ]}  fullWidth alwaysOn
            />
    </Filter>
    )
});
const PostBulkActionButtons  = (props) => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props} label="resources.elemento_pontons.fields.Borrar" />
    </Fragment>
);
const ListAction = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
        <ExportButton basePath={basePath}/>
    </CardActions>
);
const ListAction2 = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
    </CardActions>
);
export const ElementoPontonList = ({ permissions, ...props }) => {
    if (!permissions) return <Loading />;  
    return (
    <List {...props} 
        bulkActionButtons={permissions['ponton']  == 1 ? <PostBulkActionButtons />: null} 
        title="dynatest.resources.elemento_pontons.title.listTitle" 
        sort={{ field: 'updatedAt', order: 'DESC' }} 
        filters={<FiltroAlrededor/>}
        actions={permissions['ponton']  == 1 ? <ListAction /> : permissions['ponton']  == 2 ? <ListAction2 />: null} 
    >
        <Datagrid>
            <TextField label="resources.elemento_pontons.fields.fullCodigo" source="smartCode"/>
            <TextField  label="resources.elemento_pontons.fields.tipoPonton" source="tipoPonton" sortable />
            <PRField label="resources.elemento_pontons.fields.abInicio" source="abInicio"/>
            <PField label="resources.elemento_pontons.fields.pInicio" source="pInicio"/>
            <TextField label="resources.elemento_pontons.fields.margen" source="margen" sortable/>
            <TextField label="resources.elemento_pontons.fields.estados" source="estados" sortable/>
            {permissions['ponton'] == 1 || permissions['ponton'] == 2 || permissions['ponton'] == 4 ? <ShowButton/>: null}
            {permissions['ponton'] == 1 || permissions['ponton'] == 2 ? <EditButton/>: null}                             
        </Datagrid>
    </List>
)};