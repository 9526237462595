import React from 'react';
import { WithPermissions,ListButton,TabbedShowLayout, 
    EditButton, Tab, TextField, DateField, CardActions,NumberField,
    ArrayField, SingleFieldList,ReferenceField,SelectField,FormTab,ReferenceInput,SelectInput,AutocompleteInput, ChipField,ReferenceArrayField} from 'react-admin';
import { Show } from './../Show';
import { translate } from 'react-admin';


const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

export const Servicio_publicoShow = translate(props => (
    <Show  {...props} title="dynatest.resources.servicios_publicos.title.showTitle" >
        <TabbedShowLayout>
            <Tab label="dynatest.resources.servicios_publicos.tabs.informacionBasica">
            <ReferenceField label="resources.servicios_publicos.fileds.proyecto" source="proyecto" reference="proyectos" linkType={false} >
                    <TextField source="nombre" />
                </ReferenceField>  
                <br/><br/> 
            <TextField label="resources.servicios_publicos.fileds.grupo" source="grupo" />
            <span></span>
            <span></span>
            <TextField label="resources.servicios_publicos.fileds.identificacion" source="identificacion" />
            <NumberField label="resources.servicios_publicos.fileds.numero_identificacion" source="numero_identificacion" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}}/>
            <TextField label="resources.servicios_publicos.fileds.empresa" source="empresa" />
            <TextField label="resources.servicios_publicos.fileds.nombre" source="nombre" />
            <TextField label="resources.servicios_publicos.fileds.apellido" source="apellido" />
            <span></span>
            <TextField label="resources.servicios_publicos.fileds.direccion" source="direccion"/>
            <TextField label="resources.servicios_publicos.fileds.departamento" source="departamento_.nombre"/>            
            <ReferenceArrayField label="resources.servicios_publicos.fileds.municipio" reference="municipios" source="municipio">
                <SingleFieldList>
                    <ChipField source="nombre" />
                </SingleFieldList>
                </ReferenceArrayField>                
            <TextField label="resources.servicios_publicos.fileds.telefono_1" source="telefono_1" />
            <TextField label="resources.servicios_publicos.fileds.telefono_2" source="telefono_2" />
            <span></span>
            <TextField label="resources.servicios_publicos.fileds.correo" source="correo" />
            <span></span>     <span></span>   
            <TextField label="resources.servicios_publicos.fileds.observaciones" source="observaciones" />
            </Tab>
            <Tab label="dynatest.resources.servicios_publicos.tabs.auditoria">
                <DateField source="createdAt" label="resources.servicios_publicos.fileds.createdAt" showTime/> 
                <TextField source="createdBy" label="resources.servicios_publicos.fileds.createdBy"/>
                <span></span>               
                <DateField source="updatedAt" label="resources.servicios_publicos.fileds.updatedAt" showTime/>
                <TextField source="updatedBy" label="resources.servicios_publicos.fileds.updatedBy"/>
                <span></span>   
            </Tab>
        </TabbedShowLayout>
    </Show>
));