import React from 'react';
import {
    ShowController, TabbedForm, FormTab, ShowView,
    TextField, ReferenceField, NumberField, ArrayField, SingleFieldList, Datagrid,
    DateField, Toolbar
} from 'react-admin';
import { PField } from './../../../../fields/PField';
import { PRField } from './../../../../fields/PRField';
import { PercentageField } from './../../../../fields/PercentageField';
import { withStyles } from '@material-ui/core';
import { GMapInput } from '../../../../inputs/GMapInput';
import Divider from '@material-ui/core/Divider';
import { styles } from '../../../../EstilosGrid';
import Typography from '@material-ui/core/Typography';
import { ButtonCarousel } from './../../../images/ButtonCarousel';
import { translate } from 'react-admin';


const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <span></span>

    </Toolbar>
));

export const ElementoFlexibleShow = translate(withStyles(styles)(({ classes, record, ...props }) => {
    const { translate } = props;

    return (
        <ShowController title="Información Segmento Flexible" {...props}>
            {controllerProps =>
                <ShowView {...props} {...controllerProps} title="dynatest.resources.flexible_detalles.title.showTitle" actions={<ButtonCarousel />} >
                    <TabbedForm toolbar={<CustomToolbar />}>
                        <FormTab label="dynatest.resources.flexible_detalles.tabs.informacionBasica">
                            <ReferenceField source="unidadFuncional" reference="unidad_funcionals" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <TextField label="" source="fullCodigos" />
                            </ReferenceField>
                            <ReferenceField source="tramo" reference="tramos" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <TextField label="" source="fullCodigos" />
                            </ReferenceField>
                            <ReferenceField source="segmento" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont3}>
                                <TextField label="" source="codigo" />
                            </ReferenceField>
                            <br/>
                            <ReferenceField label="resources.flexible_detalles.fields.absInicio" source="segmento" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <PRField label="" source="abInicio" />
                            </ReferenceField>
                            <ReferenceField label="resources.flexible_detalles.fields.absFin" source="segmento" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <PRField label="" source="abFin" />
                            </ReferenceField><NumberField label="resources.flexible_detalles.fields.longitudShow" source="segmento_.fullResta" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3} />
                            <ReferenceField label="resources.flexible_detalles.fields.prInicio" source="segmento" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont4} >
                                <PField label="" source="pInicio" />
                            </ReferenceField>
                            <ReferenceField label="resources.flexible_detalles.fields.prFin" source="segmento" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <PField label="" source="pFin" />
                            </ReferenceField><span></span>

                            <b>{translate('resources.flexible_detalles.fields.localizacion')}</b>
                            <Divider fullWidth style={{ marginTop: '10px' }} />

                            <TextField label="resources.flexible_detalles.fields.departamento" source="segmento_.municipio_.departamento_.nombre"  fullWidth formClassName={classes.grid_cont4} />  
                            <TextField label="resources.flexible_detalles.fields.municipio" source="segmento_.municipio_.nombre"  fullWidth formClassName={classes.grid_cont4}/> 
                            <Typography variant="b" component="b">{translate('resources.flexible_detalles.fields.coordenadasIniciales')}</Typography>
                            <Divider fullWidth style={{ marginTop: '10px' }} />
                            <NumberField label="resources.flexible_detalles.fields.latitud" source="segmento_.latitud" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4} />
                            <NumberField label="resources.flexible_detalles.fields.longitud" source="segmento_.longitud" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4} />
                            <NumberField label="resources.flexible_detalles.fields.altura" source="segmento_.alturaIni"fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}}  formClassName={classes.grid_cont3} />
                            <Typography variant="b" component="b">{translate('resources.flexible_detalles.fields.coordenadasFinales')}</Typography>
                            <Divider fullWidth style={{ marginTop: '10px' }} />
                            <NumberField label="resources.flexible_detalles.fields.latitud" source="segmento_.latitud2" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4} />
                            <NumberField label="resources.flexible_detalles.fields.longitud" source="segmento_.longitud2" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4} />
                            <NumberField label="resources.flexible_detalles.fields.altura" source="segmento_.alturaFin" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3} />

                            {/* <b>Características</b>

                            <Divider fullWidth />
                            <ReferenceField label="Tipo Calzada" source="segmento_.tipoCalzada" reference="tipo_calzadas" linkType={false}  formClassName={classes.grid_cont3}>
                                <TextField source="nombre" />
                            </ReferenceField>

                            <ReferenceField label="Tipo Segmento" source="segmento_.tipoSegmento" reference="tipo_segmentos" linkType={false}  formClassName={classes.grid_cont3}>
                                <TextField source="nombre" />
                            </ReferenceField>

                            <ReferenceField label="Tipo de superficie" source="segmento_.tipoSuperficie" reference="tipo_elementos" linkType={false}  formClassName={classes.grid_cont3}>
                                <TextField source="nombre" />
                            </ReferenceField>

                            <BooleanField  source="segmento_.estado" fullWidth formClassName={classes.grid_cont4}/> 
                            <span></span> */}

                            <GMapInput
                                defaultZoom={8}
                                defaultCenter={{ lat: 4.7567039, lng: -74.0311056 }}
                                source="segmento_.ubicacionMapa"
                                multipleMarkers="true"
                                googleKey="AIzaSyCivTXzJBYRBK8nosBYRFXnv-hGXIbhnYY"
                                icon={"/imagenes/senalesColombia/Elementos/segmento_inventaro/Flexible.png"}
                            />
                        </FormTab>
                        <FormTab label="dynatest.resources.flexible_detalles.tabs.detalles">
                            <div></div><div></div><div></div>
                            <ArrayField source="detallesSegmento" label="resources.flexible_detalles.fields.sentido" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="sentido" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="detallesSegmento" label="resources.flexible_detalles.fields.estadoDetalles" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="estadoDetalles" />
                                </SingleFieldList>
                            </ArrayField>
                            {controllerProps.record  &&  controllerProps.record.detallesSegmento[0].estado_detalles === translate('dynatest.resources.flexible_detalles.select.estadoDetalles.Inactivo') &&
                                <ArrayField source="detallesSegmento" label="resources.flexible_detalles.fields.estadoRazon" linkType={false} formClassName={classes.grid_cont3}>
                                    <SingleFieldList>
                                        <TextField source="estadoRazon" />
                                    </SingleFieldList>
                                </ArrayField>
                            }
                            <br />
                            <ArrayField source="detallesSegmento" label="resources.flexible_detalles.fields.dateInicioOperacion" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <DateField source="dateInicioOperacion" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="detallesSegmento" label="resources.flexible_detalles.fields.proveedor" formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <ReferenceField linkType={false}  source="proveedor" reference="proveedors" >
                                        <TextField source="razonSocial" />
                                    </ReferenceField >       
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="detallesSegmento" label="resources.flexible_detalles.fields.garantiaMeses" formClassName={classes.grid_cont3} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}}>
                                <SingleFieldList >
                                    <NumberField source="garantiaMeses" />
                                </SingleFieldList>
                            </ArrayField>
                            <br />
                            <strong>{translate('resources.flexible_detalles.fields.trafico')}</strong>
                            <br/>
                            <Divider fullWidth /><Divider fullWidth /><Divider fullWidth />
                            <br/>
                            <ArrayField source="detallesSegmento" label="resources.flexible_detalles.fields.esalTime" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="esalTime" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="detallesSegmento" label="resources.flexible_detalles.fields.nivelTraficoDetalles" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="nivelTrafico" />
                                </SingleFieldList>
                            </ArrayField>
                            <bt />
                            <ArrayField source="detallesSegmento" label="resources.flexible_detalles.fields.esalTime10" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="esalTime10" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="detallesSegmento" label="resources.flexible_detalles.fields.esalTime20" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="esalTime20" />
                                </SingleFieldList>
                            </ArrayField>
                            <br />
                            <Divider fullWidth /><Divider fullWidth /><Divider fullWidth />
                            <br/>
                            <ArrayField source="detallesSegmento" label="resources.flexible_detalles.fields.detallesObservaciones" linkType={false} formClassName={classes.grid_cont12}>
                                <SingleFieldList>
                                    <TextField source="detallesObservaciones" />
                                </SingleFieldList>
                            </ArrayField>
                        </FormTab>
                        <FormTab label="dynatest.resources.flexible_detalles.tabs.geometria">
                            <div></div><div></div><div></div>
                            <div><strong>{translate('resources.flexible_detalles.fields.detallesCalzada')}</strong></div><div></div><div></div>
                            <Divider fullWidth /><Divider fullWidth /><Divider fullWidth />
                            <ArrayField source="geometriaSegmento" label="resources.flexible_detalles.fields.geometriaLargo" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaLargo" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmento" label="resources.flexible_detalles.fields.geometriaAncho" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaAncho" />
                                </SingleFieldList>
                            </ArrayField>
                            <br />
                            <div></div>
                            <div><strong>{translate('resources.flexible_detalles.fields.detallesCarriles')}</strong></div><div></div><div></div>
                            <Divider fullWidth /><Divider fullWidth /><Divider fullWidth />
                            <ArrayField source="geometriaSegmento" label="resources.flexible_detalles.fields.geometriaNumCarriles" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaNumCarriles" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmento" label="resources.flexible_detalles.fields.geometriaAnchoCarril" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaAnchoCarril" />
                                </SingleFieldList>
                            </ArrayField>
                            <br/>
                            <ArrayField source="geometriaSegmento" label="resources.flexible_detalles.fields.geometriaTipoBerma" linkType={false} fullWidth formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="geometriaTipoBerma" />
                                </SingleFieldList>
                            </ArrayField>
                            {controllerProps.record && controllerProps.record.geometriaSegmento[0].geometriaTipoBerma !== "Inexistente" &&
                            <ArrayField source="geometriaSegmento" label="resources.flexible_detalles.fields.geometriaAnchoBerma" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaAnchoBerma" />
                                </SingleFieldList>
                            </ArrayField>
                            }
                            {controllerProps.record && controllerProps.record.geometriaSegmento[0].geometriaTipoBerma !== "Inexistente" &&
                                <ArrayField source="geometriaSegmento" label="resources.flexible_detalles.fields.geometriaCondicionBerma" linkType={false} formClassName={classes.grid_cont3}>
                                    <SingleFieldList>
                                        <TextField source="geometriaCondicionBerma" />
                                    </SingleFieldList>
                                </ArrayField>
                            }
                            {controllerProps.record && controllerProps.record.geometriaSegmento[0].geometriaTipoBerma !== "Inexistente" &&
                                <div></div>
                            }
                            <br/>
                            <ArrayField source="geometriaSegmento" label="resources.flexible_detalles.fields.aux1" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="sumaAreaCarriles" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmento" label="resources.flexible_detalles.fields.areaCarrilesVerdadera" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="areaCarrilesVerdadera" />
                                </SingleFieldList>
                            </ArrayField>
                            <br/>
                            <div></div>
                            <div><strong>{translate('resources.flexible_detalles.fields.propiedadesGeometricas')}</strong></div><div></div><div></div>
                            <Divider fullWidth /><Divider fullWidth /><Divider fullWidth />
                            <ArrayField source="geometriaSegmento" label="resources.flexible_detalles.fields.geometriaLongitudinal" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <PercentageField source="geometriaLongitudinal" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmento" label="resources.flexible_detalles.fields.geometriaLongitudinalMa" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <PercentageField source="geometriaLongitudinalMa" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmento" label="resources.flexible_detalles.fields.geometriaLongitudinalMi" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <PercentageField source="geometriaLongitudinalMi" />
                                </SingleFieldList>
                            </ArrayField>
                            <br/>
                            {/* -----------RADIO--------- */}
                            <ArrayField source="geometriaSegmento" label="resources.flexible_detalles.fields.geometriaTransversal" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <PercentageField source="geometriaTransversal" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmento" label="resources.flexible_detalles.fields.geometriaRadioMi" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}> 
                                <SingleFieldList>
                                    <NumberField source="geometriaRadioMi" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmento" label="resources.flexible_detalles.fields.geometriaRadioMa" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaRadioMa" />
                                </SingleFieldList>
                            </ArrayField>
                            <br/>
                            {/* -------------------- */}
                            <ArrayField source="geometriaSegmento" label="resources.flexible_detalles.fields.geometriaRadioCurvatura" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaRadioCurvatura" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmento" label="resources.flexible_detalles.fields.geometriaVelDiseno" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaVelDiseno" />
                                </SingleFieldList>
                            </ArrayField>
                            <br/>
                            <ArrayField label="resources.flexible_detalles.fields.geometriaSeparador" source="geometriaSegmento" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="geometriaSeparador" />
                                </SingleFieldList>
                            </ArrayField>
                {controllerProps.record && controllerProps.record.geometriaSegmento[0].geometriaSeparador === translate('dynatest.resources.flexible_detalles.select.geometriaSeparador.Si') && 
                            <ArrayField source="geometriaSegmento" label="resources.flexible_detalles.fields.geometriaSeparadorAncho" fullWidth linkType={false} style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaSeparadorAncho" />
                                </SingleFieldList>
                            </ArrayField>
                }
                            <div></div>
                            <br />
                            <ArrayField label="resources.flexible_detalles.fields.geometriaCondicionesGeometrica" source="geometriaSegmento" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList >
                                    <TextField source="geometriaCondicionesGeometrica" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmento" label="resources.flexible_detalles.fields.geometriaAlineamiento" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="geometriaAlineamiento" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmento" label="resources.flexible_detalles.fields.geometriaTipoTerreno" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="geometriaTipoTerreno" />
                                </SingleFieldList>
                            </ArrayField>
                            <br/>
                            <Divider fullWidth /><Divider fullWidth /><Divider fullWidth />
                            <ArrayField source="geometriaSegmento" linkType={false} label="resources.flexible_detalles.fields.detallesGeometria">
                                <SingleFieldList>
                                    <TextField source="detallesGeometria" />
                                </SingleFieldList>
                            </ArrayField>
                        </FormTab>
                        <FormTab label="dynatest.resources.flexible_detalles.tabs.estructura">
                            <div></div><div></div><div></div>
                            <div><strong>{translate('resources.flexible_detalles.fields.informacionEstructura')}</strong></div><div></div><div></div>
                            <Divider fullWidth /><Divider fullWidth /><Divider fullWidth />
                            <ArrayField
                                source="tablaSegmento"
                                style={{ width: '100%' }}
                                label=" "
                                className="inventariosSegmento"
                            >
                                <Datagrid>
                                    <NumberField source="estructuraEspesor" label="resources.flexible_detalles.fields.estructuraEspesor" />
                                    <NumberField source="estructuraModulo" label="resources.flexible_detalles.fields.estructuraModulo" />
                                    <NumberField source="estructuraDs" label="resources.flexible_detalles.fields.estructuraDs" />
                                    
                                    <ReferenceField linkType={false} label="resources.flexible_detalles.fields.material" source="material" reference="materials" >
                                        <TextField source="material" />
                                    </ReferenceField > 

                                    <ReferenceField linkType={false} label="resources.flexible_detalles.fields.materialEsp" source="material" reference="materials" >
                                        <TextField source="especificacion" />
                                    </ReferenceField >
                                {controllerProps.record && controllerProps.record.tablaSegmento[0].granulometria === null &&
                                    // <p>No Definida</p>
                                    <TextField label="resources.flexible_detalles.fields.granulometria" source="granulometria"/>
                                }
                                {controllerProps.record && controllerProps.record.tablaSegmento[0].granulometria !== null &&
                                    <ReferenceField linkType={false} label="resources.flexible_detalles.fields.granulometria" source="granulometria" reference="granulometrias" >
                                        <TextField source="granulometria" />
                                    </ReferenceField >
                                }

                                </Datagrid>
                            </ArrayField>
                            {/* <TablaSegmentos tabla="flexible_detalles" id={IdSegmento} />              */}
                            <div></div><div></div>
                            <NumberField label="resources.flexible_detalles.fields.espesorTotal" source="espensorTotal" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "60%"}} />
                            <br/>
                            <ArrayField source="estructuraSegmento" label="resources.flexible_detalles.fields.iggEstructura" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="igg" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="estructuraSegmento" label="resources.flexible_detalles.fields.conceito" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="conceito" />
                                </SingleFieldList>
                            </ArrayField>
                            <br/>
                            <div></div><div></div>
                            <div className={classes.subtittle}><strong>{translate('resources.flexible_detalles.fields.capacidadEstructural')}</strong></div><div></div><br />
                            <Divider fullWidth /><Divider fullWidth /><Divider fullWidth />
                            <ArrayField source="estructuraSegmento" label="resources.flexible_detalles.fields.estructuraNumDiseno" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="estructuraNumDiseno" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="estructuraSegmento" label="resources.flexible_detalles.fields.estructuraFecha" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <DateField source="estructuraFecha" />
                                </SingleFieldList>
                            </ArrayField>
                            <br/>
                            <ArrayField source="estructuraSegmento" label="resources.flexible_detalles.fields.estructuraNumero" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="estructuraNumero" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="estructuraSegmento" label="resources.flexible_detalles.fields.estructuraNumeroFecha" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <DateField source="estructuraNumeroFecha" />
                                </SingleFieldList>
                            </ArrayField>
                            <br/>
                            <ArrayField source="estructuraSegmento" label="resources.flexible_detalles.fields.estructuraCbr"  linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <PercentageField source="estructuraCbr" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="estructuraSegmento" label="resources.flexible_detalles.fields.estructuraCbrFecha" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <DateField source="estructuraCbrFecha" />
                                </SingleFieldList>
                            </ArrayField>
                            
                            <div></div>
                            <div><strong>{translate('resources.flexible_detalles.fields.fechasImportantes')}</strong></div><div></div><div></div>
                            <Divider fullWidth /><Divider fullWidth /><Divider fullWidth />
                            <ArrayField source="estructuraSegmento" label="resources.flexible_detalles.fields.estructuraFechaConst" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <DateField source="estructuraFechaConst" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="estructuraSegmento" label="resources.flexible_detalles.fields.estructuraFechaRehab" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <DateField source="estructuraFechaRehab" />
                                </SingleFieldList>
                            </ArrayField>
                            <div></div>
                            <Divider fullWidth /><Divider fullWidth /><Divider fullWidth />
                            <ArrayField source="estructuraSegmento" label="resources.flexible_detalles.fields.detallesEstructura"linkType={false} >
                                <SingleFieldList>
                                    <TextField source="detallesEstructura" />
                                </SingleFieldList>
                            </ArrayField>
                        </FormTab>
                        <FormTab label="dynatest.resources.flexible_detalles.tabs.auditoria">
                            <DateField source="createdAt" label="resources.flexible_detalles.fields.createdAt" fullWidth formClassName={classes.grid_cont4} showTime/>
                            <TextField source="createdBy" label="resources.flexible_detalles.fields.createdBy" fullWidth formClassName={classes.grid_cont4} />
                            <TextField source="createdIn" label="resources.flexible_detalles.fields.createdEn" fullWidth formClassName={classes.grid_cont3} />
                            <span></span>
                            <DateField source="updatedAt" label="resources.flexible_detalles.fields.updatedAt" fullWidth formClassName={classes.grid_cont4} showTime/>
                            <TextField source="updatedBy" label="resources.flexible_detalles.fields.updatedBy" fullWidth formClassName={classes.grid_cont4} />
                            <TextField source="updatedIn" label="resources.flexible_detalles.fields.updatedEn" fullWidth formClassName={classes.grid_cont3} />
                            <span></span>
                        </FormTab>
                    </TabbedForm>
                </ShowView>
            }
        </ShowController>
    )
}));