import React, { useState, Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import {
    DateField, BooleanField, required, TabbedForm, FormTab, AutocompleteInput, SimpleFormIterator,
    ArrayField, NumberField, SelectField, ReferenceField, TextField, FormDataConsumer, Datagrid, ShowController, ShowView
} from 'react-admin';
import { PimsCreate } from './../Create';
import { styles } from './../../EstilosGrid';
import {
    abValidationInicio, validarAB, abValidationFin, validarDosDecimales, validarUnDecimal, isFechaInvalida
} from '../../../src/resources/validacionesInputs';
import { latMininima, lngMininima, Entero } from '../validacionesInputs';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { minValue, maxValue, minLength, maxLength } from 'ra-core';
import { PRInput } from '../../inputs/PRInput';
import { DInput } from '../../inputs/DInput';
import { DEInput } from '../../inputs/DEInput';
import AbsSegmento from '../AbsSegmento';
import config from '../../config/config';
import { ImagenesInventario } from '../images/ImagenesInventario';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { ButtonCarousel } from './../images/ButtonCarousel';
import { translate } from 'react-admin';

let urlAppjs = config.UrlServer;


export const CalificacionPuenteShow = translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;

    const styles = {
        ocultar: { display: "none" },

    };

    const [area_puentes, setPuentes] = useState('');
    const ValidarPuentes = (value) => {
        if (value != null) {
            let lenghtObject = Object.keys(value);
            fetch(urlAppjs + value + "?properties[geometria]")
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    setPuentes(parseFloat(response.geometria[0].area_tab))
                    sumDato(parseFloat(response.geometria[0].area_tab))
                })
        }

    }

    let titulos2 = [];
    titulos2[0] = { "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.tablero.TableroTitle'), "data": [] };
    titulos2[0].data[0] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.tablero.SuperficieGrietas'), "dato": "1", 
                            "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.tablero.Area'), "decimales_dato": "1", "sum_dato": "1", "lugar_detalle": "0", "source": "calificacionPuentesSuperficieGrietas" };
    titulos2[0].data[1] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.tablero.SuperficieDanada'), "dato": "1", 
                            "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.tablero.Area'), "decimales_dato": "1", "sum_dato": "1", "lugar_detalle": "0", "source": "calificacionPuentesSuperficieDanada" };
    titulos2[0].data[2] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.tablero.SuperficieDesconchados'), "dato": "1", 
                            "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.tablero.Area'), "decimales_dato": "1", "sum_dato": "1", "lugar_detalle": "0", "source": "calificacionPuentesSuperficieDesconchados" };
    titulos2[0].data[3] = { "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.tablero.TotalAreaAfectada'), "dato": "1", 
                            "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.tablero.SumaAreas'), "decimales_dato": "1", "disabled_dato": "1", "default_value_dato": "0", "lugar_detalle": "0", "source": "calificacionPuentesTotalAreaAfectada" };
    titulos2[0].data[4] = { "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.tablero.PorcentajeAreaAfectada'), "dato": "1", 
                            "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.tablero.Calculo'), "decimales_dato": "1", "disabled_dato": "1", "default_value_dato": "0", "lugar_detalle": "0", "source": "calificacionPuentesPorcentajeAreaAfectada" };
  
  
  titulos2[1] = { "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.juntasExpansion.juntasExpansionTitle'), "data": [] };
  titulos2[1].data[0] = { "subtitulos": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.juntasExpansion.NumeroJuntasExpan'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato') , "dato": "1", "decimales_dato": "0", "lugar_detalle": "0", "source": "calificacionPuentesNroJuntasExpansion" };
  titulos2[1].data[1] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.juntasExpansion.ObstruccionSello'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "1", "agregar": "1", "source": "calificacionPuentesObstruccionSello" };
  titulos2[1].data[2] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.juntasExpansion.RupturaSello'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "1", "agregar": "1", "source": "calificacionPuentesRupturaSello" };
  titulos2[1].data[3] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.juntasExpansion.AusenciaSello'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "1", "agregar": "1", "source": "calificacionPuentesAusenciaSello" };
  titulos2[1].data[4] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.juntasExpansion.AgrietamientoRoturaSoldad'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "1", "agregar": "1", "source": "calificacionPuentesAgrietamientoRoturaSoldaduras" };
  titulos2[1].data[5] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.juntasExpansion.AusenciaAnclajes'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "1", "agregar": "1", "source": "calificacionPuentesAuenciaAnclajes" };
  titulos2[1].data[6] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.juntasExpansion.PerfilesDefectuosos'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "1", "agregar": "1", "source": "calificacionPuentesPerfilesDefectuosos" };
  titulos2[1].data[7] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.juntasExpansion.PerfilesSueltos'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "1", "agregar": "1", "source": "calificacionPuentesPerfilesSueltos" };
  titulos2[1].data[8] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.juntasExpansion.DesgasteGuardacantos'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "1", "agregar": "1", "source": "calificacionPuentesDesgasteGuardacantos" };
  titulos2[1].data[9] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.juntasExpansion.DesportillamientoGuardacantos'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "1", "agregar": "1", "source": "calificacionPuentesDesportillamientoGuardacantos" };
  titulos2[1].data[10] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.juntasExpansion.FisuramientoGuardacantos'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "1", "agregar": "1", "source": "calificacionPuentesFisuramientoGuradacantos" };
  titulos2[1].data[11] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.juntasExpansion.JuntaFuncional'), "decimales_dato": "0", "lugar_detalle": "0", "source": "calificacionPuentesJuntaNoFuncional" };
  
  
  titulos2[2] = { "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.AndenesBordillos.AndenesBordillosTitle'), "data": [] };
  titulos2[2].data[0] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.AndenesBordillos.Desportillamiento'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "2", "agregar": "1", "source": "calificacionPuentesDesportillamiento" };
  titulos2[2].data[1] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.AndenesBordillos.AceroRefuerzoExpuesto'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "2", "agregar": "1", "source": "calificacionPuentesAceroRefuerzoExpuesto" };
  titulos2[2].data[2] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.AndenesBordillos.DimensionesInsuficientes'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "2", "agregar": "1", "source": "calificacionPuentesDimensionesInsuficientes" };
  
  
  titulos2[3] = { "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.BarrSegBaranPretilMetal.Title'), "data": [] };
  titulos2[3].data[0] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.BarrSegBaranPretilMetal.ConexionesMalEstado'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "2", "agregar": "1", "source": "calificacionPuentesConexionesMalEstado" };
  titulos2[3].data[1] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.BarrSegBaranPretilMetal.ProteccionesMalEstado'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "2", "agregar": "1", "source": "calificacionPuentesProteccionesMalEstado" };
  
  titulos2[3].data[2] = { "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.BarrSegBaranPretilMetal.Pintura'), "decimales_dato": "0", "subtitulos": "1", "lugar_detalle": "0"/*, "source": "calificacionPuentesPintura" */};
  titulos2[3].data[3] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.BarrSegBaranPretilMetal.Delaminacion'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "2", "agregar": "1", "source": "calificacionPuentesPinturaDelaminacion" };
  titulos2[3].data[4] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.BarrSegBaranPretilMetal.Ausencia'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "2", "agregar": "1", "source": "calificacionPuentesPinturaAusencia" };
  titulos2[3].data[5] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.BarrSegBaranPretilMetal.Deterioro'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "2", "agregar": "1", "source": "calificacionPuentesPinturaDeterioro" };
  
  titulos2[3].data[6] = { "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.Postes.titlePostes'), "decimales_dato": "0", "subtitulos": "1", "lugar_detalle": "0"/*, "source": "calificacionPuentesPostes" */};
  titulos2[3].data[7] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.Postes.Fracturamiento'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "2", "agregar": "1", "source": "calificacionPuentesPostesFracturamiento" };
  titulos2[3].data[8] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.Postes.Ausencia'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "2", "agregar": "1", "source": "calificacionPuentesPostesAusencia" };
  titulos2[3].data[9] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.Postes.GolpesImpacto'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "2", "agregar": "1", "source": "calificacionPuentesPostesGolpesImpacto" };
  titulos2[3].data[10] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.Postes.Grietas5mm'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "2", "agregar": "1", "source": "calificacionPuentesPostesGrietas" };
  titulos2[3].data[11] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.Postes.Desconchados10mm'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "2", "agregar": "1", "source": "calificacionPuentesPostesDesconchados" };
  
  titulos2[3].data[12] = { "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.Pasamanos.titlePasamanos'), "decimales_dato": "0", "subtitulos": "1", "lugar_detalle": "0"/*, "source": "calificacionPuentesPasamanos" */};
  titulos2[3].data[13] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.Pasamanos.Corrosión'), 
                            "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                            "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "2", "agregar": "1", "source": "calificacionPuentesPasamanosCorrosion" };
  titulos2[3].data[14] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.Pasamanos.AusenciaElementos'), 
                            "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                            "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "2", "agregar": "1", "source": "calificacionPuentesPasamanosAusenciaElementos" };
  titulos2[3].data[15] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.Pasamanos.GolpesImpacto'), 
                            "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                            "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "2", "agregar": "1", "source": "calificacionPuentesPasamanosGolpesImpacto" };
  titulos2[3].data[16] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.Pasamanos.Grietas5mm'), 
                            "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                            "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "2", "agregar": "1", "source": "calificacionPuentesPasamanosGrietas" };
  titulos2[3].data[17] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.Pasamanos.Desconchados10mm'), 
                            "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                            "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "2", "agregar": "1", "source": "calificacionPuentesPasamanosDesconchados" };
  
  
  
  titulos2[4] = { "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.Title'), "data": [] };
  titulos2[4].data[1] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.Subestructura'), "decimales_dato": "0", "agregar": "1", "subtitulos": "1" };
  titulos2[4].data[2] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.FisurasFlexion'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "3", "agregar": "1", "source": "calificacionPuentesFisurasFlexion" };
  titulos2[4].data[3] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.FisurasCortante'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "3", "agregar": "1", "source": "calificacionPuentesFisurasCortante" };
  titulos2[4].data[4] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.FisurasTorsion'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "3", "agregar": "1", "source": "calificacionPuentesFisurasTorsion" };
  titulos2[4].data[5] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.AplastamientoLocal'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "3", "agregar": "1", "source": "calificacionPuentesAplastamientoLocal" };
  titulos2[4].data[6] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.Asentamientos'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "3", "agregar": "1", "source": "calificacionPuentesAsentamientos" };
  titulos2[4].data[7] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.Volcamiento'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "3", "agregar": "1", "source": "calificacionPuentesVolcamiento" };
  titulos2[4].data[8] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.Segregación'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "3", "agregar": "1", "source": "calificacionPuentesSegregacion" };
  titulos2[4].data[9] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.Hormigueros'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "3", "agregar": "1", "source": "calificacionPuentesHormigueros" };
  titulos2[4].data[10] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.FisurasRetraccion'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "3", "agregar": "1", "source": "calificacionPuentesFisurasRetraccion" };
  titulos2[4].data[11] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.RecubrimientoInadecuado'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "3", "agregar": "1", "source": "calificacionPuentesRecubrimientoInadecuado" };
  titulos2[4].data[12] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.ExposicionAceroRef'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "3", "agregar": "1", "source": "calificacionPuentesExposicionAceroRefuerzo" };
  titulos2[4].data[13] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.Infiltracion'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "3", "agregar": "1", "source": "calificacionPuentesInfiltracion" };
  titulos2[4].data[14] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.Eflorescencias'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "3", "agregar": "1", "source": "calificacionPuentesEflorencias" };
  titulos2[4].data[15] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.Carbonatacion'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "3", "agregar": "1", "source": "calificacionPuentesCarbonatacion" };
  titulos2[4].data[16] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.CorrosionArmadura'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "3", "agregar": "1", "source": "calificacionPuentesCorrosionArmadura" };
  titulos2[4].data[17] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.FallasImpacto'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "3", "agregar": "1", "source": "calificacionPuentesFallasImpacto" };
  titulos2[4].data[18] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.Socavacion'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "3", "agregar": "1", "source": "calificacionPuentesSocavacion" };
  titulos2[4].data[19] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.Erosion'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "3", "agregar": "1", "source": "calificacionPuentesErosion" };
  
  titulos2[4].data[20] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.DanosEstrucMetalicas'), "decimales_dato": "0", "lugar_detalle": "0", "agregar": "1", "subtitulos": "1" };
  titulos2[4].data[21] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.CorrosionLeve'), 
                            "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                            "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "3", "agregar": "1", "source": "calificacionPuentesCorrosionLeve" };
  titulos2[4].data[22] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.CorrosionMedia'), 
                            "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                            "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "3", "agregar": "1", "source": "calificacionPuentesCorrosionMedia" };
  titulos2[4].data[23] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.CorrosionSevera'), 
                            "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                            "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "3", "agregar": "1", "source": "calificacionPuentesCorrosionSevera" };
  titulos2[4].data[24] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.PinturaDeteriorada'), 
                            "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                            "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "3", "agregar": "1", "source": "calificacionPuentesPinturaDeteriorada" };
  
  titulos2[4].data[25] = { "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.CablesPendolones'), "decimales_dato": "0", "lugar_detalle": "0", "subtitulos": "1"/*, "source": "calificacionPuentesCablesPendolones" */};
  titulos2[4].data[26] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.PerdidaRecubriCables'), 
                           "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                           "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "0", "source": "calificacionPuentesPerdidaRecubrimientoCables" };
  titulos2[4].data[27] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.FaltaTensionCablesPendol'), 
                           "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                           "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "0", "source": "calificacionPuentesFaltaTensionCablesPendolones" };
  titulos2[4].data[28] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.FisurasAlambres'), 
                           "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                           "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "0", "source": "calificacionPuentesFisurasAlambres" };
  titulos2[4].data[29] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.FisurasMuertosAnclajes'), 
                           "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                           "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "0", "source": "calificacionPuentesFisurasMuertosAnclajes" };
  titulos2[4].data[30] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.ContaminacionZonaAnclajes'), 
                           "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                           "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "0", "source": "calificacionPuentesContaminacionZonaAnclajes" };
  
  titulos2[4].data[31] = { "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.PerfMetalTorresMiembrosArmad'), "decimales_dato": "0", "lugar_detalle": "0", "subtitulos": "1"/*, "source": "calificacionPuentesPerfilesMetalicosTorres" */};
  titulos2[4].data[32] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.PandeoLocal'), 
                           "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                           "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "0", "source": "calificacionPuentesPandeoLocal" };
  titulos2[4].data[33] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.PandeoGeneralLateral'), 
                           "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                           "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "0", "source": "calificacionPuentesPandeoGeneralLateral" };
  titulos2[4].data[34] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.FisurasVigas'), 
                           "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                           "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "0", "source": "calificacionPuentesFisurasVigas" };
  titulos2[4].data[35] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.FallasImpacto'), 
                           "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                           "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "0", "source": "calificacionPuentesFallasImpactoPerfiles" };
  titulos2[4].data[36] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.DeflexionExcesiva'), 
                           "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                           "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "0", "source": "calificacionPuentesDeflexionExcesiva" };
  
  titulos2[4].data[37] = { "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.Conexiones'), "decimales_dato": "0", "subtitulos": "1", "lugar_detalle": "0", /*"source": "calificacionPuentesConexiones" */};
  titulos2[4].data[38] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.AusenciaConectores'), 
                           "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                           "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "0", "source": "calificacionPuentesAusenciaConectores" };
  titulos2[4].data[39] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.Excentricidades'), 
                           "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                           "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "0", "source": "calificacionPuentesExcentricidades" };
  titulos2[4].data[40] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.FallasTensionPlatina'), 
                           "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                           "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "0", "source": "calificacionPuentesFallasTensionPlatina" };
  titulos2[4].data[41] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.AplastamientoPlatina'), 
                           "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                           "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "0", "source": "calificacionPuentesAplastamientoPlatina" };
  titulos2[4].data[42] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.FallasDesgarramiento'), 
                           "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                           "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "0", "source": "calificacionPuentesFallasDesgarramiento" };
  titulos2[4].data[43] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.FallaCorteConector'), 
                           "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                           "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "0", "source": "calificacionPuentesFallaCorteConector" };
  titulos2[4].data[44] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.FallaBloqueCortante'), 
                           "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                           "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "0", "source": "calificacionPuentesFallaBloqueCortante" };
  titulos2[4].data[45] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.RoturaSoldadura'), 
                           "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                           "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "0", "source": "calificacionPuentesRoturaSoldadura" };
  
  
  titulos2[5] = { "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.Drenaje'), "data": [] };
  titulos2[5].data[1] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.SuperficieErosionada'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "2", "agregar": "1", "source": "calificacionPuentesSuperficieErosionada" };
  titulos2[5].data[2] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.DanoEstructural'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "2", "agregar": "1", "source": "calificacionPuentesDanoEstructural" };
  titulos2[5].data[3] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.Taponamiento'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "2", "agregar": "1", "source": "calificacionPuentesTaponamiento" };
  titulos2[5].data[4] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.Ausencia'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "2", "agregar": "1", "source": "calificacionPuentesAusencia" };
  titulos2[5].data[5] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.LongitudInsuficiente'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "2", "agregar": "1", "source": "calificacionPuentesLongitudInsuficiente" };

    let lugar_detalle = [[], 
    [{ id: "Entrada del puente", name: translate('dynatest.resources.calificacion_puentes.inspeccion.lugar_detalle.EntradaPuente') }, 
      { id: "Salida del puente", name: translate('dynatest.resources.calificacion_puentes.inspeccion.lugar_detalle.SalidaPuente') }, 
      { id: "Junta intermedia", name: translate('dynatest.resources.calificacion_puentes.inspeccion.lugar_detalle.JuntaIntermedia') }], 
    [{ id: "Costado derecho", name: translate('dynatest.resources.calificacion_puentes.inspeccion.lugar_detalle.CostadoDerecho') }, 
      { id: "Costado izquierdo", name: translate('dynatest.resources.calificacion_puentes.inspeccion.lugar_detalle.CostadoIzquierdo') }], 
    [{ id: "Losa", name: translate('dynatest.resources.calificacion_puentes.inspeccion.lugar_detalle.Losa') }, 
      { id: "Vigas", name: translate('dynatest.resources.calificacion_puentes.inspeccion.lugar_detalle.Vigas') }, 
      { id: "Riostras", name: translate('dynatest.resources.calificacion_puentes.inspeccion.lugar_detalle.Riostras') }, 
      { id: "Apoyos", name: translate('dynatest.resources.calificacion_puentes.inspeccion.lugar_detalle.Apoyos') }, 
      { id: "Arcos", name: translate('dynatest.resources.calificacion_puentes.inspeccion.lugar_detalle.Arcos') }, 
      { id: "Cimentación", name: translate('dynatest.resources.calificacion_puentes.inspeccion.lugar_detalle.Cimentacion') }, 
      { id: "Rótulas", name: translate('dynatest.resources.calificacion_puentes.inspeccion.lugar_detalle.Rotulas') }], 
    [{ id: "Arcos metálicos", name: translate('dynatest.resources.calificacion_puentes.inspeccion.lugar_detalle.ArcosMetalicos') }, 
      { id: "Perfiles metálicos", name: translate('dynatest.resources.calificacion_puentes.inspeccion.lugar_detalle.PerfilesMetalicos') }, 
      { id: "Armaduras", name: translate('dynatest.resources.calificacion_puentes.inspeccion.lugar_detalle.Armaduras') }, 
      { id: "Conexiones", name: translate('dynatest.resources.calificacion_puentes.inspeccion.lugar_detalle.Conexiones') }, 
      { id: "Cables", name: translate('dynatest.resources.calificacion_puentes.inspeccion.lugar_detalle.Cables') }, 
      { id: "Pendolones", name: translate('dynatest.resources.calificacion_puentes.inspeccion.lugar_detalle.Pendolones') }, 
      { id: "Torres", name: translate('dynatest.resources.calificacion_puentes.inspeccion.lugar_detalle.Torres') }]];

    const sumDato = (area_puentes2) => {
        let porcentaje = 0;
        let val = [];
        let total = 0;

        if (document.getElementById('calificacionPuentesSuperficieGrietas[0].dato') !== undefined && document.getElementById('calificacionPuentesSuperficieGrietas[0].dato') != null) {
            val[0] = document.getElementById('calificacionPuentesSuperficieGrietas[0].dato').innerHTML;
        } else {
            val[0] = 0;
        }

        if (document.getElementById('calificacionPuentesSuperficieDanada[0].dato') !== undefined && document.getElementById('calificacionPuentesSuperficieDanada[0].dato') != null) {
            val[1] = document.getElementById('calificacionPuentesSuperficieDanada[0].dato').innerHTML;
        } else {
            val[1] = 0;
        }

        if (document.getElementById('calificacionPuentesSuperficieDesconchados[0].dato') !== undefined && document.getElementById('calificacionPuentesSuperficieDesconchados[0].dato') != null) {
            val[2] = document.getElementById('calificacionPuentesSuperficieDesconchados[0].dato').innerHTML;
        } else {
            val[2] = 0;
        }
        console.log(parseFloat(val[0] ? val[0] : 0) +"+"+ parseFloat(val[1] ? val[1] : 0) +"+"+ parseFloat(val[2] ? val[2] : 0))
        total = parseFloat(val[0] ? val[0] : 0) + parseFloat(val[1] ? val[1] : 0) + parseFloat(val[2] ? val[2] : 0);
        if (document.getElementById('calificacionPuentesTotalAreaAfectada[0].dato') !== undefined && document.getElementById('calificacionPuentesTotalAreaAfectada[0].dato') != null) {
            document.getElementById('calificacionPuentesTotalAreaAfectada[0].dato').innerHTML = total;
        }

        if (area_puentes2 > 0) {
            porcentaje = (total / area_puentes2) * 100;
        } else {
            porcentaje = 0;
        }

        if (document.getElementById('calificacionPuentesPorcentajeAreaAfectada[0].dato') !== undefined && document.getElementById('calificacionPuentesPorcentajeAreaAfectada[0].dato') != null) {
            document.getElementById('calificacionPuentesPorcentajeAreaAfectada[0].dato').innerHTML = porcentaje;
        }

        if (porcentaje > 5) {
            if (document.getElementById('cumplimiento') !== undefined && document.getElementById('cumplimiento') != null) {
                document.getElementById('cumplimiento').value = "No cumple";
            }
        } else {
            if (document.getElementById('cumplimiento') !== undefined && document.getElementById('cumplimiento') != null) {
                document.getElementById('cumplimiento').value = "Cumple";
            }
        }
    }

    /*OPCIONES PARA TIPO DE IMÁGENES*/
    const [defaultTipoImagen, setDefaultTipoImagen] = useState([
        {
            tipoImagen: '/tipo_images/16',
        },
        {
            tipoImagen: '/tipo_images/17',
        },
        {
            tipoImagen: '/tipo_images/18',
        },
        {
            tipoImagen: '/tipo_images/19',
        },
        {
            tipoImagen: '/tipo_images/20',
        }
    ]);
    const OpcionTipoImagen = (value) => {
        setDefaultTipoImagen([
            {
                tipoImagen: '/tipo_images/16',
            },
            {
                tipoImagen: '/tipo_images/17',
            },
            {
                tipoImagen: '/tipo_images/18',
            },
            {
                tipoImagen: '/tipo_images/19',
            },
            {
                tipoImagen: '/tipo_images/20',
            }
        ])
    }

    

    return (
        <ShowController {...props} title="Información Calificación Puentes" >
            {controllerProps =>
                <ShowView {...props} {...controllerProps} title="dynatest.resources.calificacion_puentes.title.showTitle" actions={<ButtonCarousel />} >
                    <TabbedForm toolbar={false}>
                        <FormTab label="dynatest.resources.calificacion_puentes.tabs.tabsTitle.detalles">
                            <TextField label="resources.calificacion_puentes.fields.smartCode" source="smartCode" linkType={false} fullWidth formClassName={classes.grid_cont12} />
                            <ReferenceField label="resources.calificacion_puentes.fields.puentes" source="puentes" reference="puentes" linkType={false} fullWidth formClassName={classes.grid_cont4s}>
                                <TextField source="smartCode" />
                            </ReferenceField>
                            <DateField label="resources.calificacion_puentes.fields.fechaInicioInspeccion" source="fechaInicioInspeccion" linkType={false} fullWidth formClassName={classes.grid_cont4} />
                            <TextField label="resources.calificacion_puentes.fields.nombreInspector" source="nombreInspector" linkType={false} fullWidth formClassName={classes.grid_cont4} />
                            <TextField label="resources.calificacion_puentes.fields.nombreReporteInspector" source="nombreReporteInspector" linkType={false} fullWidth formClassName={classes.grid_cont4s} />
                            <DateField label="resources.calificacion_puentes.fields.fechaReporteInspeccion" source="fechaReporteInspeccion" linkType={false} fullWidth formClassName={classes.grid_cont4} />
                            <SelectField source="medidoPor" label="resources.calificacion_puentes.fields.medidoPor" linkType={false} fullWidth formClassName={classes.grid_cont4} choices={[
                                { id: 'Interventoría', name: 'Interventoría' },
                                { id: 'Mantenimiento', name: 'Mantenimiento' },
                            ]} />
                            <SelectField source="estado" label="resources.calificacion_puentes.fields.estado" linkType={false} fullWidth formClassName={classes.grid_cont4s} choices={[
                                { id: 'Por revisar', name: 'Por revisar' },
                                { id: 'Revisión realizada', name: 'Revisión realizada' },
                                { id: 'Publicada', name: 'Publicada' },
                            ]} />
                            <SelectField source="tipoMedicion" label="resources.calificacion_puentes.fields.tipoMedicion" linkType={false} fullWidth formClassName={classes.grid_cont4} choices={[
                                { id: 'Periódica', name: 'Periódica' },
                                { id: 'De corrección', name: 'De corrección' },
                            ]} />
                            <TextField label="resources.calificacion_puentes.fields.cumplimiento" id={`cumplimiento`} source={`cumplimiento`} linkType={false} fullWidth formClassName={classes.grid_cont4} />
                        </FormTab>

                        <FormTab label="dynatest.resources.calificacion_puentes.tabs.tabsTitle.Inspeccion">
                            <React.Fragment>
                                {
                                    titulos2.map((data, i) => (
                                        <ExpansionPanel>
                                            <ExpansionPanelSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                            >
                                                <Typography><b>{titulos2[i].nombre}</b></Typography>
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails>
                                                <Table size="small">
                                                    <TableBody>
                                                        <TableRow>
                                                            <TableCell style={{width: '30%'}} align="right"></TableCell>
                                                            <TableCell style={{width: '20%'}} align="right"><b>{translate('dynatest.resources.calificacion_puentes.tableShow.titleTable.Activo')}</b></TableCell>
                                                            <TableCell style={{width: '20%'}} align="right"><b>{translate('dynatest.resources.calificacion_puentes.tableShow.titleTable.Cantidad')}</b></TableCell>
                                                            <TableCell style={{width: '30%'}} align="right"><b>{translate('dynatest.resources.calificacion_puentes.tableShow.titleTable.LugarDetalle')}</b></TableCell>
                                                         </TableRow>
                                                        {
                                                            titulos2[i].data.map((info, z) => (
                                                                (!info.hasOwnProperty("source")) ?
                                                                    <TableRow>
                                                                        <TableCell style={{width: '30%'}} component="th" scope="row">
                                                                            {(!info.hasOwnProperty("subtitulos")) ? info.nombre : <b>{info.nombre}</b>}
                                                                        </TableCell>
                                                                        <TableCell style={{width: '20%'}} align="right"></TableCell>
                                                                        <TableCell style={{width: '20%'}} align="right"></TableCell>
                                                                        <TableCell style={{width: '30%'}} align="right"></TableCell>
                                                                    </TableRow>

                                                                    :
                                                                    ((info.hasOwnProperty("agregar")) ?
                                                                        controllerProps.record && controllerProps.record[info.source] &&
                                                                        controllerProps.record[info.source].map((data, f) => (
                                                                            <TableRow>
                                                                                <TableCell style={{width: '30%'}} component="th" scope="row">
                                                                                    {(!info.hasOwnProperty("subtitulos")) ? (parseInt(f) + 1) + " - " + info.nombre : <b>{info.nombre}</b>}
                                                                                </TableCell>
                                                                                <TableCell style={{width: '20%'}} align="right">{(!info.hasOwnProperty("activo")) ? "" : ((data.activo) ? translate('resources.calificacion_puentes.fields.activo') : translate('resources.calificacion_puentes.fields.inactivo'))}</TableCell>
                                                                                <TableCell id={info.nombre + "[" + f + "].dato"} align="right" style={{width: '20%', textAlign: "end" }}>{(!info.hasOwnProperty("dato")) ? "" : data.dato}</TableCell>
                                                                                <TableCell align="right">{(info.lugar_detalle == 0) ? "" : data.lugarDetalle}</TableCell>
                                                                            </TableRow>
                                                                        )) 
                                                                        :
                                                                        controllerProps.record && controllerProps.record[info.source] &&
                                                                        controllerProps.record[info.source].map((data, f) => (
                                                                            <TableRow>
                                                                                <TableCell style={{width: '30%'}} component="th" scope="row">
                                                                                    {(!info.hasOwnProperty("subtitulos")) ? (parseInt(f) + 1) + " - " + info.nombre : <b>{info.nombre}</b>}
                                                                                </TableCell>
                                                                                <TableCell style={{width: '20%'}} align="right">{(!info.hasOwnProperty("activo")) ? "" : ((data.activo) ? translate('resources.calificacion_puentes.fields.activo') : translate('resources.calificacion_puentes.fields.inactivo'))}</TableCell>
                                                                                <TableCell id={info.source + "[" + f + "].dato"} align="right" style={{width: '20%', textAlign: "end" }}>{(!info.hasOwnProperty("dato")) ? "" : data.dato}</TableCell>
                                                                                <TableCell style={{width: '30%'}} align="right">{(info.lugar_detalle == 0) ? "" : data.lugarDetalle}</TableCell>
                                                                            </TableRow>
                                                                        ))
                                                                    )
                                                            ))
                                                        }
                                                    </TableBody>
                                                </Table>
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    ))
                                }
                            </React.Fragment>
                        </FormTab>

                    </TabbedForm>
                </ShowView>
            }
        </ShowController >
    )
}));
