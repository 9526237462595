let urlser = window.location.hostname;
let urlAppjs = "https://dynatest-backend.soluciones.software";
let urlAppjs2 = "https://iris-license-be.soluciones.software/";
let urlAppFront = "https://dynatest.soluciones.software/#/";
let country = "CO";
let languajeQuickSight = "es-ES";
switch (urlser) {
  case 'localhost':
    urlAppjs = "http://localhost:8000";
    //licenciamiento
    urlAppjs2 = "http://localhost:8001";
    // GET PORT FRONT
    let port = window.location.port;
    urlAppFront = "http://localhost:" + port + "/#/";
    country = "CO";
    break;
  case 'pims.soluciones.software':
    urlAppjs = "https://pims-backend.soluciones.software";
    //licenciamiento
    urlAppFront = "https://pims.soluciones.software/#/";
    country = "PE";
    break;
  case 'iris-pe.soluciones.software':
    urlAppjs = "https://iris-pe-be.soluciones.software";
    //licenciamiento
    urlAppFront = "https://iris-pe.soluciones.software/#/";
    country = "PE";
    break;
  case 'iris-co.soluciones.software':
    urlAppjs = "https://iris-co-be.soluciones.software";
    //licenciamiento
    urlAppFront = "https://iris-co.soluciones.software/#/";
    country = "CO";
    break;
  case 'iris-test.soluciones.software':
    urlAppjs = "https://iris-test-be.soluciones.software";
    //licenciamiento
    urlAppFront = "https://iris-test.soluciones.software/#/";
    country = "CO";
    break;
  case 'iris-br.soluciones.software':
    urlAppjs = "https://iris-br-be.soluciones.software";
    //licenciamiento
    urlAppFront = "https://iris-br.soluciones.software/#/";
    country = "BR";
    languajeQuickSight = "pt-BR";
    break;
}

const config = {
  UrlServer: urlAppjs,
  //licenciamiento
  UrlServer2: urlAppjs2,
  UrlFront: urlAppFront,
  Country: country,
  LanguajeQuickSight: languajeQuickSight
}

export default config;
