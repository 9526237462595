import React, { useState } from 'react';
import { ArrayInput, SimpleFormIterator, TextInput, ImageInput, ImageField, ReferenceInput, SelectInput, required } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import config from '../../config/config';
import { translate } from 'react-admin';


export const ImagenesLogos = translate(({ type, defaultTipoImagen }) => {
    /*VALIDAR IMÁGENES ACTUALES */
    const [imagenesOpen, setImagenesOpen] = useState('');
    const ImagenesEdit = (value) => {
        setImagenesOpen(value);
    }
    let urlAppjs = config.UrlServer;
    if (type === 'edit') {
        return (
            <Grid container spacing={24}>
                <Grid item xs={8}>
                    {defaultTipoImagen && defaultTipoImagen.length > 0 &&
                        <ArrayInput label="" source="images" validate={ImagenesEdit} defaultValue={defaultTipoImagen} >
                            <SimpleFormIterator disableAdd disableRemove >
                                <ReferenceInput disabled label="dynatest.resources.imagenesInventario.titleImagen" source="tipoImagen" reference="tipo_images" fullWidth validate={[required()]} sort={{ field: 'nombre', order: 'ASC' }} >
                                    <SelectInput optionText="nombre" />
                                </ReferenceInput>
                                <ImageInput multiple={true} label=" " source="images" labelMultiple="dynatest.resources.imagenesInventario.descripcionImagen" maxSize={1000000000} accept="image/jpeg, image/png">
                                    <ImageField source="src" title="title" />
                                </ImageInput>
                            </SimpleFormIterator>
                        </ArrayInput>
                    }

                </Grid>
            </Grid>
        )
    } else if (type === 'create') {
        return (
            <Grid container spacing={24}>
                <Grid item xs={8}>
                    {defaultTipoImagen && defaultTipoImagen.length > 0 &&
                        <ArrayInput label="" source="images" validate={ImagenesEdit} defaultValue={defaultTipoImagen} >
                            <SimpleFormIterator disableAdd disableRemove >
                                <ReferenceInput disabled label="dynatest.resources.imagenesInventario.titleImagen" source="tipoImagen" reference="tipo_images" fullWidth validate={[required()]} sort={{ field: 'nombre', order: 'ASC' }} >
                                    <SelectInput optionText="nombre" />
                                </ReferenceInput>
                                <ImageInput multiple={true} label=" " source="images" labelMultiple="dynatest.resources.imagenesInventario.descripcionImagen" maxSize={1000000000} accept="image/jpeg, image/png">
                                    <ImageField source="src" title="title" />
                                </ImageInput>
                            </SimpleFormIterator>
                        </ArrayInput>
                    }

                </Grid>
            </Grid>
        )
    }

});
