import React ,{useState} from 'react';
import { ReferenceInput,DateInput,ArrayInput,SimpleFormIterator, AutocompleteInput ,BooleanInput,TabbedForm,FormTab, TextInput, NumberInput, SelectInput} from 'react-admin';
import { PimsCreate } from './../../Create';
import { styles } from './../../../EstilosGrid';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { abValidationInicio,Entero,validarUnDecimal,validateDecima,validarDecimales,validarAB, abValidationFin, validarDosDecimales, validarPorcentaje, validarDecimales2 } from '../../../../src/resources/validacionesInputs';
import { DInput } from '../../../inputs/DInput';
import { withStyles } from '@material-ui/core';
import { PRInput } from './../../../inputs/PRInput';
import AbsSegmento from '../../AbsSegmento';
import CoordenadasProyecto from '../../CoordenadasInventario';
import {latMininima,lngMininima} from '../../validacionesInputs';
import UniqueYears from './validacion/unicyears';
import {
  required,
  minLength,
  maxLength,
  minValue,
  FormDataConsumer,
  maxValue
} from 'react-admin';
import {PercentageInput} from '../../../inputs/PercentageInput';
import Tasas from './views/tasas';
import { translate } from 'react-admin';
export const EstacionInventarioCreate = translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;
    const styles = {    
        ocultar: { display: "none" },
      
      };
      const abValidationInicio1 =() =>{
    
        /*VALORES ABS INICIO*/
        let AbInicioBase = document.getElementById('IdAbInicio');
        if(AbInicioBase)
        {
           
            AbInicioBase = AbInicioBase.value
            let ingresoAbInicio = document.getElementById('ingresoAbInicio1').value;
            let separadorInicio = ingresoAbInicio.split("+");
            let separadorInicio1 = separadorInicio[0].split("K");
            let seAbInicioBase = AbInicioBase.split(".");
            let AbFinBase = document.getElementById('IdAbFin').value;
            let seAbFinBase = AbFinBase.split(".");
         
         /*VALIDACIONES PR INICIO*/
         if(parseInt(separadorInicio1[1] ) < parseInt(AbInicioBase) || parseInt(separadorInicio1[1] ) > parseInt(AbFinBase) )
         {
             
             return translate("resources.validaciones.rangoEntre") + AbInicioBase +" y " + AbFinBase;
         }
         if(parseInt(separadorInicio1[1] ) === parseInt(AbInicioBase))
         {
             if(parseInt(separadorInicio[1]) < parseInt(seAbInicioBase[1]))
         
             return translate("resources.validaciones.rangoEntre") + AbInicioBase +" y " + AbFinBase;
         }
         if(parseInt(separadorInicio1[1] ) === parseInt(seAbFinBase[0]))
         {
             
             if(parseInt(separadorInicio[1]) > parseInt(seAbFinBase[1]))
         
             return translate("resources.validaciones.rangoEntre") + AbInicioBase +" y " + AbFinBase;
         }
       }
         }
         const abValidationInicio2 =() =>{
           
          /*VALORES ABS INICIO*/
        let AbInicioBase = document.getElementById('IdAbInicio');
        if(AbInicioBase)
        {
           
            AbInicioBase = AbInicioBase.value
            let ingresoAbInicio = document.getElementById('ingresoAbInicio2').value;
            let separadorInicio = ingresoAbInicio.split("+");
            let separadorInicio1 = separadorInicio[0].split("K");
            let seAbInicioBase = AbInicioBase.split(".");
            let AbFinBase = document.getElementById('IdAbFin').value;
            let seAbFinBase = AbFinBase.split(".");
         
         
         /*VALIDACIONES PR INICIO*/
         if(parseInt(separadorInicio1[1] ) < parseInt(AbInicioBase) || parseInt(separadorInicio1[1] ) > parseInt(AbFinBase) )
         {
             
             return translate("resources.validaciones.rangoEntre") + AbInicioBase +" y " + AbFinBase;
         }
         if(parseInt(separadorInicio1[1] ) === parseInt(AbInicioBase))
         {
             if(parseInt(separadorInicio[1]) < parseInt(seAbInicioBase[1]))
         
             return translate("resources.validaciones.rangoEntre") + AbInicioBase +" y " + AbFinBase;
         }
         if(parseInt(separadorInicio1[1] ) === parseInt(seAbFinBase[0]))
         {
             
             if(parseInt(separadorInicio[1]) > parseInt(seAbFinBase[1]))
         
             return translate("resources.validaciones.rangoEntre") + AbInicioBase +" y " + AbFinBase;
         }
       }
         }
         const validarAB =() => {
           var ingresoAbInicio = document.getElementById("ingresoAbInicio1");
           var ingresoAbFin = document.getElementById("ingresoAbInicio2");
           if (ingresoAbInicio && ingresoAbFin) {
       
               let Abinc = ingresoAbInicio.value.split("+");
               Abinc[0] = Abinc[0].replace("K", "");
               ingresoAbInicio = Abinc[0] + "." + Abinc[1];
       
               let Abfin = ingresoAbFin.value.split("+");
               Abfin[0] = Abfin[0].replace("K", "");
               ingresoAbFin = Abfin[0] + "." + Abfin[1];
       
               if (ingresoAbInicio > ingresoAbFin) {
                translate("resources.validaciones.abscisaInicialDebeSerMenor")
               }
           }
       }
        const [segmentos, setSegmento] = useState('');
          const [unidads, setUnidad] = useState('');
          const ValidarUnidad = (value) => {
              let lenghtObject = Object.keys(value);
              let valor = '';
              for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
              setUnidad(value)
          }
          const [tramos, setTramo] = useState('');
          const ValidarTramo = (value) => {
              let lenghtObject = Object.keys(value);
              let valor = '';
              for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
              setTramo(value)
          }
      
        const [latMin, setLatMin] = useState('');
        const [lngMin, setLngMin] = useState('');
        const [latMax, setLatMax] = useState('');
        const [lngMax, setLngMax] = useState('');
      
        const ValidarSegmento = (value) => {
          let lenghtObject = Object.keys(value);
          let valor = '';
          for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
          setSegmento(valor)
          let idSegmento = valor;
          idSegmento = idSegmento.split('/');
          let redirect = "elemento_drenajes"
          let urlser = window.location.hostname;
          let urlAppjs = "https://dynatest.soluciones.software/#/" + redirect;
      
          switch (urlser) {
            case 'localhost':
              urlAppjs = "http://localhost:3000/#/" + redirect;
              break;
            case 'pims.soluciones.software':
              urlAppjs = "https://pims.soluciones.software/#/" + redirect;
          }
          // fetch(urlAppjs + '/segmentos/' + idSegmento[2] + '.json')
          //   .then(function (response) {
          //     return response.json();
          //   })
          //   .then(function (myJson) {
          //     let comprobacion = myJson["ubicacionMapa"]
          //     if (comprobacion !== undefined) {
          //       let comprobar = myJson["ubicacionMapa"][0]
          //       if (comprobar !== undefined) {
          //         let latMin = myJson["ubicacionMapa"][0]['lat'];
          //         let lngMin = myJson["ubicacionMapa"][0]['lng'];
          //         let latMax = myJson["ubicacionMapa"][1]['lat'];
          //         let lngMax = myJson["ubicacionMapa"][1]['lng'];
          //         setLatMin(latMin);
          //         setLngMin(lngMin);
          //         setLatMax(latMax);
          //         setLngMax(lngMax);
      
          //       }
          //     }
          //   })
      
        }
        const [latitudMinimaArray, setLatitudArray] = useState('');
        const LatitudMinimaArray = (value) => {
          setLatitudArray(value)
        }
        const [longitudMinimaArray, setLongitudArray] = useState('');
        const LongitudMinimaArray = (value) => {
          setLongitudArray(value)
        }
        const [longitudMaximaArray, setLongitudMaxdArray] = useState('');
        const LongitudMaximaArray = (value) => {
          setLongitudMaxdArray(value)
        }
        const [latitudMaximaArray, setLatitudMaxdArray] = useState('');
        const LatitudMaximaArray = (value) => {
          setLatitudMaxdArray(value)
        }
        
        const validarfecha=(value)=>{
          var today = new Date();
          var dd = today.getDate();
          var mm = today.getMonth()+1; //January is 0!
          var yyyy = today.getFullYear();
           if(dd<10){
                  dd='0'+dd
              } 
              if(mm<10){
                  mm='0'+mm
              } 
          
          today = yyyy+'-'+mm+'-'+dd;
          if(document.getElementById("datefield")){
              document.getElementById("datefield").setAttribute("max", today);
          }
          }
  const validarfechaact=(value)=>{
      let fecha=new Date(value+"T00:00:00");
      let fechaact=new Date();
      if(fecha>fechaact){
          return translate('resources.estacion_conteos.input.fechaEstudio');
      }
  } 
  const [totaliza, setTotaliza] = useState('');
  const sumar = (valor)=> {
    let val1 = 0;
    let val2 = 0;
    let val3 = 0;
    let val4 = 0;
    let val5 = 0;
    let val6 = 0;
    val1 = document.getElementById('2').value;
    val2 = document.getElementById('3').value;
    val3 = document.getElementById('4').value;
    val4 = document.getElementById('5').value;
    val5 = document.getElementById('6').value;
    val6 = document.getElementById('7').value;
    
    if (val1 != null && valor != null && val2 != null && val3 != null && val4 != null && val5 != null && val6 != null) {
    }
    
      setTotaliza(parseInt(val1) + parseInt(val2) + parseInt(valor) + parseInt(val3) + parseInt(val4) + parseInt(val5) + parseInt(val6)) ;
      console.log('spTotal');
}
const [years1, setYears1] = useState('');
const validarAño1 = (value) => {
    let lenghtObject = Object.keys(value);
    let valor = '';

    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
   
    setYears1(valor)


}
const [years2, setYears2] = useState('');
const validarAño2 = (value) => {
    let lenghtObject = Object.keys(value);
    let valor = '';

    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    
    setYears2(valor)


}
const [nombre, setNombre] = useState('');
const validarNombre = (value) => {
    setNombre(value)


}
const [years, setYears] = useState('');
const validarAño = (value) => {
    
    
    setYears(value)


}
const choices=[
  { id: '2000', name: '2000' },
  { id: '2001', name: '2001' },
  { id: '2002', name: '2002' },
  { id: '2003', name: '2003' },
  { id: '2004', name: '2004' },
  { id: '2005', name: '2005' },
  { id: '2006', name: '2006' },
  { id: '2007', name: '2007' },
  { id: '2008', name: '2008' },
  { id: '2009', name: '2009' },
  { id: '2010', name: '2010' },
  { id: '2011', name: '2011' },
  { id: '2012', name: '2012' },
  { id: '2013', name: '2013' },
  { id: '2014', name: '2014' },
  { id: '2015', name: '2015' },
  { id: '2016', name: '2016' },
  { id: '2017', name: '2017' },
  { id: '2018', name: '2018' },
  { id: '2019', name: '2019' },
  { id: '2020', name: '2020' },
  { id: '2021', name: '2021' },
  { id: '2022', name: '2022' },
  { id: '2023', name: '2023' },
  { id: '2024', name: '2024' },
  { id: '2025', name: '2025' },
  { id: '2026', name: '2026' },
  { id: '2027', name: '2027' },
  { id: '2028', name: '2028' },
  { id: '2029', name: '2029' },
  { id: '2030', name: '2030' }
]
const validateYears = (valor) => {
  let years1 = 0;
  let years2 = 0;
  years1 =  document.getElementById('12').value;
  years2 =  document.getElementById('13').value;
  console.log(years1);
  console.log(years2);
  if(parseInt(years1) === parseInt(years2) ){
    return translate("resources.validaciones.anioIgual")
  }
  else if(parseInt(years2) < parseInt(years1) ){
    return translate("resources.validaciones.anioMayorFinal")
  }


}
      
const validateMinimo = (valor) => {

  var n = parseInt(valor);
  if (n <= 100) {
    console.log(n)
    if (n >= -100) {
    } else {
      return translate('resources.estacion_conteos.input.tasaCrecimientoMayor');
    }

  } else {
    return translate('resources.estacion_conteos.input.tasaCrecimientoMenor');
  }
}
    return(
<PimsCreate {...props}>
<TabbedForm  redirect="list">
            <FormTab label="dynatest.resources.estacion_conteos.tabs.tabsTitle.identificacion">
                <Grid container spacing={24}>
                  <Grid item xs={4}>
                  <CoordenadasProyecto/>
                        <TextInput label="resources.estacion_conteos.fields.nombre" source="nombre" validate={[required(),validarNombre]}fullWidth/>
                    </Grid>
                     {/* ------- LOCALIZACIÓN --------- */}
                     <Grid item xs={12}>
              <h3 style={{ marginBottom: 0 }}>{translate('dynatest.resources.estacion_conteos.subTitle.localizacion')}</h3>
              <hr></hr>
            </Grid>
            <Grid item xs={4}>
              <ReferenceInput
                label="resources.estacion_conteos.fields.unidad_funcional"
                source="unidad_funcional"
                id="unidad_id"
                reference="unidad_funcionals"
                filterToQuery={searchText => ({ codigo: searchText })}
                sort={{ field: 'codigo', order: 'asc' }}
                validate={[required(), ValidarUnidad]}
                fullWidth
              >
                <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
              </ReferenceInput>
            </Grid>

            {unidads &&
              <Grid item xs={4}>
                <ReferenceInput
                  label="resources.estacion_conteos.fields.tramo"
                  source="tramo"
                  id="tramo_id"
                  alwaysOn
                  reference="tramos"
                  filter={{ unidadFuncional: unidads }}
                  filterToQuery={searchText => ({ codigo: searchText })}
                  sort={{ field: 'codigo', order: 'ASC', }}
                  validate={[required(), ValidarTramo]}
                  formClassName={classes.grid_cont4}
                >
                  <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
                </ReferenceInput>
              </Grid>
            }
            {tramos &&
              <Grid item xs={4}>
                <ReferenceInput
                  label="resources.estacion_conteos.fields.segmento"
                  source="segmento"
                  id="segmento_id"
                  reference="segmentos"
                  sort={{ field: 'codigo', order: 'ASC' }}
                  filter={{ tramo: tramos }}
                  filterToQuery={searchText => ({ codigo: searchText })}
                  onChange={ValidarSegmento}
                  validate={[required()]}
                  formClassName={classes.grid_cont4}
                >

                  <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
                </ReferenceInput>
                {/* <ReferenceInput label="Segmento" source="segmento" reference="segmentos" className={classes.ocultar} >
                  <SelectInput optionText="fullAbinicio" id="IdAbInicio" />
                </ReferenceInput>
                <ReferenceInput label="Segmento" source="segmento" reference="segmentos" className={classes.ocultar} >
                  <SelectInput optionText="fullAbfin" id="IdAbFin" />
                </ReferenceInput> */}
              </Grid>
            }

          </Grid>
          {segmentos && <AbsSegmento segmento={segmentos} />}
          {segmentos &&
            <Grid container spacing={24}>
            <Grid item xs={12}>
              <Typography variant="h3" component="h3" className={classes.coord}>{translate('dynatest.resources.estacion_conteos.subTitle.coordenadas')}</Typography>
              <Divider fullWidth style={{ marginTop: '10px' }} />
            </Grid>
          </Grid>
          }
          {segmentos && <PRInput label="resources.estacion_conteos.fields.abscisa" id="ingresoAbInicio" source="abInicio" fullWidth validate={[abValidationInicio, required()]} formClassName={classes.grid_cont4s} />}
          {segmentos && <NumberInput label="resources.estacion_conteos.fields.latitud" id="LatitudInicial" source="latitudInicial" fullWidth validate={[required(), latMininima, LatitudMinimaArray]} formClassName={classes.grid_cont4} />}
          {segmentos && <NumberInput label="resources.estacion_conteos.fields.longitud" id="LongitudInicial" source="longitudInicial" fullWidth validate={[required(), lngMininima, LongitudMinimaArray]} formClassName={classes.grid_cont4} />}
          {segmentos &&
           
          
            <ArrayInput label="" source="ubicaciones" style={{
              display:
                "none"
            }}
              defaultValue={[
                {
                  lat: latitudMinimaArray,
                  lng: longitudMinimaArray
                }/* ,
                {
                  lat: latitudMaximaArray,
                  lng: longitudMaximaArray
                } */
              ]}
            >
              <SimpleFormIterator disableAdd disableRemove >
                <NumberInput label="resources.estacion_conteos.fields.latitud" source="lat" fullWidth />
                <DInput label="resources.estacion_conteos.fields.longitud" source="lng" fullWidth />
              </SimpleFormIterator>
            </ArrayInput>
          }
             
                <Grid item xs={6}>
                      <BooleanInput label="dynatest.resources.estacion_conteos.boolean.operativa" source="operativa" fullWidth/>
                      </Grid>
                
            </FormTab>
            <FormTab label="dynatest.resources.estacion_conteos.tabs.tabsTitle.detalles">

            <TextInput label="resources.estacion_conteos.fields.consultor" source="consultor" validate={[required(), minLength(1), maxLength(40)]} formClassName={classes.grid_cont4s}/>
            <TextInput label="resources.estacion_conteos.fields.informe" source="informe" validate={[required(), minLength(1), maxLength(40)]} formClassName={classes.grid_cont4s}/>
            <DateInput label="resources.estacion_conteos.fields.date" source="date" validate={[required(),validarfecha, validarfechaact]} formClassName={classes.grid_cont4_f}/>
             
            <Typography variant="h3" component="h3" className={classes.coord}>{translate('dynatest.resources.estacion_conteos.subTitle.abscisasAfectacion')}</Typography>
              <Divider fullWidth style={{ marginTop: '10px' }} />  
              <ArrayInput className="estilos_array_puentes" label=" " fullWidth source="tablaEstacion">
            <SimpleFormIterator>
              <ReferenceInput
                label="resources.estacion_conteos.fields.unidad_funcional"
                source="unidadFuncionala"
                reference="unidad_funcionals"
                filterToQuery={searchText => ({ codigo: searchText })}
                sort={{ field: 'codigo', order: 'asc' }}
                validate={[required(), ValidarUnidad]}
                fullWidth formClassName={classes.grid_cont3}
              >
                <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
              </ReferenceInput>
              <PRInput label="resources.estacion_conteos.fields.abscisaInicial"id="ingresoAbInicio1" source="abInicioa" fullWidth validate={[abValidationInicio1,required()]}formClassName={classes.grid_cont3}/>
            <PRInput label="resources.estacion_conteos.fields.abscisaFinal" id="ingresoAbInicio2" source="abFina" fullWidth validate={[abValidationInicio2,validarAB,required()]}formClassName={classes.grid_cont3}/>
                 </SimpleFormIterator>
                 </ArrayInput>
            </FormTab>
            <FormTab label="dynatest.resources.estacion_conteos.tabs.tabsTitle.ejesEquivalentes">
            <NumberInput label="resources.estacion_conteos.fields.direccional" source="direccional" validate={[required(),validarDosDecimales, minValue(0), maxValue(1)]} formClassName={classes.grid_cont4}/>
            <NumberInput label="resources.estacion_conteos.fields.carril" source="carril" validate={[required(),validarDosDecimales, minValue(0), maxValue(1)]} formClassName={classes.grid_cont4}/>
            <UniqueYears label="resources.estacion_conteos.fields.years" source="years" nombre={nombre} years={years} fullWidth validate={[required(),validarAño]} formClassName={classes.grid_cont3}/>
            {/* <UniqueYears label="Año" source="years" ufuncional={unidads.replace("/unidad_funcionals/","")} utramo={tramos.replace("/tramos/","")} usegmentos={segmentos.replace("/segmentos/","")}  choices={choices} fullWidth validate={required()} formClassName={classes.grid_cont3}/> */}
            
            <div style={{display: 'flex', justifyContent: 'center', margin: '0px 250px', marginTop: '20px'}}>
            <strong style={{ display: 'flex', alignItems: 'flex-end', marginRight: '150px', marginBottom: '15px'}}>{translate('dynatest.resources.estacion_conteos.table.titles.categoria')}</strong> 
            <span style={{margin: '0px -10px'}}>
            </span>
            <strong style={{ display: 'flex', alignItems: 'flex-end', marginRight: '150px', marginBottom: '15px'}}>{translate('dynatest.resources.estacion_conteos.table.titles.volumen')}</strong> 
            <span style={{margin: '0px 50px'}}>
            </span>
             <strong style={{display: 'flex', alignItems: 'flex-end', marginRight: '150px', marginBottom: '15px'}}>{translate('dynatest.resources.estacion_conteos.table.titles.factor')}</strong>
             </div>
             <div style={{display: 'flex', justifyContent: 'center', margin: '0px 250px', marginTop: '-15px'}}>
            <strong style={{ display: 'flex', alignItems: 'flex-end', marginRight: '300px', marginBottom: '15px'}}>{translate('dynatest.resources.estacion_conteos.table.rows.autos')}</strong> 
            <NumberInput label=" " source="aut1" validate={[required(),Entero, minValue(0),sumar]} id="1" style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}/>
            <span style={{margin: '0px 150px'}}>
            </span>
             <NumberInput label=" " source="aut2" validate={[required(),validarUnDecimal,minValue(0)]}  style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}/>
             </div>
             <div style={{display: 'flex', justifyContent: 'center', margin: '0px 250px', marginTop: '-15px'}}>
            <strong style={{ display: 'flex', alignItems: 'flex-end', marginRight: '300px', marginBottom: '15px'}}>{translate('dynatest.resources.estacion_conteos.table.rows.buses')}</strong> 
            <NumberInput label=" " source="bus1" validate={[required(),Entero ,minValue(0)]} id="2" style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}/>
            <span style={{margin: '0px 150px'}}>
            </span>
             <NumberInput label=" " source="bus2" validate={[required(),validarUnDecimal,minValue(0)]}  style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}/>
             </div>
             <div style={{display: 'flex', justifyContent: 'center', margin: '0px 250px', marginTop: '-15px'}}>
            <strong style={{ display: 'flex', alignItems: 'flex-end', marginRight: '320px', marginBottom: '15px'}}>{translate('resources.estacion_conteos.fields.c2p')}</strong> 
            <NumberInput label=" " validate={[required(),Entero ,minValue(0)]}  source="c1volumen" id="3" style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}/>
            <span style={{margin: '0px 150px'}}>
            </span>
             <NumberInput label=" " validate={[required(),validarUnDecimal, minValue(0)]}  source="c2factor" style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}/>
             </div>
             <div style={{display: 'flex', justifyContent: 'center', margin: '0px 250px', marginTop: '-15px'}}>
            <strong style={{ display: 'flex', alignItems: 'flex-end', marginRight: '320px', marginBottom: '15px'}}>{translate('resources.estacion_conteos.fields.c2g')}</strong> 
            <NumberInput label=" " validate={[required(), Entero,minValue(0)]} id="4" source="cgvolumen" style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}/>
            <span style={{margin: '0px 150px'}}>
            </span>
             <NumberInput label=" " validate={[required(),validarUnDecimal, minValue(0)]}  source="cgfactor" style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}/>
             </div>
             <div style={{display: 'flex', justifyContent: 'center', margin: '0px 250px', marginTop: '-15px'}}>
            <strong style={{ display: 'flex', alignItems: 'flex-end', marginRight: '310px', marginBottom: '15px'}}>{translate('resources.estacion_conteos.fields.c3yc4')}</strong> 
            <NumberInput label=" " validate={[required(),Entero ,minValue(0)]} id="5"  source="c3volumen" style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}/>
            <span style={{margin: '0px 150px'}}>
            </span>
             <NumberInput label=" " validate={[required(),validarUnDecimal, minValue(0)]}  source="c3factor" style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}/>
             </div>
             <div style={{display: 'flex', justifyContent: 'center', margin: '0px 250px', marginTop: '-15px'}}>
            <strong style={{ display: 'flex', alignItems: 'flex-end', marginRight: '330px', marginBottom: '15px'}}>{translate('resources.estacion_conteos.fields.c5')}</strong> 
            <NumberInput label=" " validate={[required(),Entero ,minValue(0)]} id="6"  source="c5volumen" style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}/>
            <span style={{margin: '0px 150px'}}>
            </span>
             <NumberInput label=" " validate={[required(),validarUnDecimal, minValue(0)]}  source="c5factor" style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}/>
             </div>
             <div style={{display: 'flex', justifyContent: 'center', margin: '0px 250px', marginTop: '-15px'}}>
            <strong style={{ display: 'flex', alignItems: 'flex-end', marginRight: '320px', marginBottom: '15px'}}>{translate('resources.estacion_conteos.fields.c52')}</strong> 
            <NumberInput label=" " validate={[required(),Entero ,minValue(0)]} id="7"  source="mayorc5volumen" style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}/>
            <span style={{margin: '0px 150px'}}>
            </span>
             <NumberInput label=" " validate={[required(),validarUnDecimal, minValue(0)]}  source="mayorc5factor" style={{ display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}/>
             </div>
             <div style={{display: 'flex', justifyContent: 'center', margin: '0px 190px', marginTop: '-15px'}}>
            <strong style={{ display: 'flex', alignItems: 'flex-end', marginRight: '320px', marginBottom: '15px'}}>{translate('resources.estacion_conteos.fields.tpd')}</strong> 
            <NumberInput label=" " disabled source="tpd" defaultValue={totaliza} style={{ display: 'flex', alignItems: 'flex-end', marginRight: '200px', marginBottom: '15px'}}/>
             </div>
            </FormTab>
            <FormTab label="dynatest.resources.estacion_conteos.tabs.tabsTitle.tasasCrecimiento">
              <NumberInput label="resources.estacion_conteos.fields.esal1" source="esal1"  validate={[required(),Entero ,minValue(0)]} fullWidth formClassName={classes.grid_cont4}/>
              <NumberInput label="resources.estacion_conteos.fields.esal2" source="esal2" validate={[required(),Entero ,minValue(0)]} fullWidth formClassName={classes.grid_cont4}/>
              <NumberInput label="resources.estacion_conteos.fields.esal3" source="esal3" validate={[required(),Entero ,minValue(0)]} fullWidth formClassName={classes.grid_cont3}/>
              <NumberInput label="resources.estacion_conteos.fields.tpdactual" source="tpdactual" validate={[required(),Entero ,minValue(0)]} fullWidth formClassName={classes.grid_cont4}/>
              <PercentageInput label="resources.estacion_conteos.fields.tasacrecimiento" source="tasacrecimiento" validate={[required(),validateMinimo]} className="align-number" fullWidth formClassName={classes.grid_cont4}/>
              <br/>
            <SelectInput id="12" label="resources.estacion_conteos.fields.years1" allowEmpty  source="years1" onChange={validarAño1} choices={choices} fullWidth formClassName={classes.grid_cont3}/>
         
            <SelectInput id="13" label="resources.estacion_conteos.fields.years2" allowEmpty  source="years2" onChange={validarAño2} choices={choices} fullWidth formClassName={classes.grid_cont3}/>
            <span style={{color:'orange'}} >{translate('dynatest.resources.estacion_conteos.messagesVal.valFechaAntFin')}</span>
            {years1 && years2 &&
                    <WithProps formClassName={classes.grid_cont_div}>{({record,...props})=>
                <Tasas record={{nombre:nombre}} years1={years1}  years2={years2} tipo="tipo"/> }
                </WithProps> 
}
            </FormTab>
      </TabbedForm>
        </PimsCreate>
    
)}));
const WithProps = ({ children, ...props }) => children(props);