import React from 'react';
import {FileInput, TextInput, TextField, FormDataConsumer, required} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import config from '../../../config/config';
import loader from '../../../loader10.gif';
import Grid from '@material-ui/core/Grid';
import { translate } from 'react-admin';
import  extractGoogleCoords from '../../Mapa/localhelpers/extractGoogleCoords';
let urlAppjs = config.UrlServer;
let codGeneral = "";

const array_cod = {
    ACCS: "elemento_alrededors",
    OCPN: "elemento_alrededors",
    ZINT: "elemento_alrededors",
    SEPD: "elemento_alrededors",
    OBST: "elemento_alrededors",
    MARG: "elemento_alrededors",
    COMR: "elemento_alrededors",

    PART: "adoquin_detalles",
    POTR: "otro_detalles",
    PFLEX: "flexible_detalles",
    PRIGD: "rigido_detalles",

    ZCOR: "elemento_drenajes",
    POZT: "elemento_drenajes",
    DREN: "elemento_drenajes",
    DESC: "elemento_drenajes",
    CUNT: "elemento_drenajes",
    BOXC: "elemento_drenajes",
    BAJT: "elemento_drenajes",
    ALCT: "elemento_drenajes",

    INIV: "otros_elementos",
    IDES: "otros_elementos",
    CASC: "otros_elementos",

    PNTE: "puentes",
    PNTEP: "puentes",
    MCON: "elemento_contencions",
    ESTE: "elemento_contencions",
    CONT: "elemento_contencions",
    TNEL: "tuneles",
    PTON: "elemento_pontons",

    SOS: "elemento_reds",
    SEM: "elemento_reds",
    PST: "elemento_reds",
    PMZ: "elemento_reds",
    LUM: "elemento_reds",
    ETD: "elemento_reds",
    ENL: "elemento_reds",
    CTV: "elemento_reds",
    CSP: "elemento_reds",
    BDP: "elemento_reds",
    ANT: "elemento_reds",

    TPVH: "seguridads",
    PRTP: "seguridads",
    DEFMT: "seguridads",
    CERC: "seguridads",
    CAPT: "seguridads",
    BRRJ: "seguridads",
    BRRF: "seguridads",
    BRRC: "seguridads",
    BORD: "seguridads",

    DEM: "elemento_senalizacions",
    OTR: "elemento_senalizacions",
    SH: "elemento_senalizacions",
    SV: "elemento_senalizacions",

    TRAF: "elemento_traficos",
}

class Codigo extends React.Component {
    constructor(props) {
        super(props)
        this.state = {loaded: false, codigo: ""}
        this.state.codigo = this.props.record.codigo;
        this.state.tipoElemento = this.props.record.tipoElemento;
        this.state.tipoElementoSenal = this.props.record.tipoElementoSenal;
    }
    componentDidMount() {
        let tipoElmnt = this.state.tipoElemento == null ? null : this.state.tipoElemento.split('/');
        let tipoElmntSenal = this.state.tipoElementoSenal == null ? null : this.state.tipoElementoSenal.split('/');
        let codigoTipoElmnt = tipoElmnt != null ? "/"+array_cod[this.state.tipoElemento.split("/")[2]]+"/"+this.state.codigo : null;
        let codigoTipoElmntSenal = tipoElmntSenal != null ? "/"+array_cod[this.state.tipoElementoSenal.split("/")[2]]+"/"+this.state.codigo : null;
        let cod_Show = codigoTipoElmnt ? codigoTipoElmnt : codigoTipoElmntSenal;
        if(cod_Show){
            fetch(urlAppjs + cod_Show)
            .then((response) => {
                return response.json()
            }).then((response) => {
                codGeneral = response.smartCode;
                return (
                    <>
                        <TextField label="Código 1" linkType={false} fullWidth />
                    </>
                );
            }); 
            return (
                <div>
                    <p>{codGeneral}</p>
                </div>
            );
        }
    }

    render(){
        const { translate } = this.props;
        setTimeout(10000);
        if(this.state.loaded===false){
            //console.log(this.state, codGeneral, "123456" );
            return (
                <div>
                    <TextField label="Código elemento" linkType={false} fullWidth sortable />
                    {/* {this.state.codigo} */}
                    {codGeneral}
                </div>
            )
       }else{
           return(
                <Grid container spacing={24}>
                    {this.state.codigo.map((valor, i)=>(
                        <Grid item xs={6}>
                        <Typography variant="overline" display="block" gutterBottom>
                            {valor.codigo}
                        </Typography>
                        <TextField label="prueba campo 1" />
                        
                        </Grid>
                   ))

                   }
                </Grid>
           )
       }
    }
}

export default Codigo;