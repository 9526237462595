import React , {useState} from 'react';
import { TextInput, ReferenceInput, AutocompleteInput, FormDataConsumer,Query, required, SelectInput
} from 'react-admin';
import { PimsCreate } from './../Create';
import { PInput } from './../../inputs/PInput';
import { PRInput } from './../../inputs/PRInput';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { SimpleForm } from './../SimpleForm';
import UniqueUF from './../componentes/UniqueUF';
import config from '../../config/config';
import {translate} from 'react-admin';
let urlAppjs = config.UrlServer;

const styles = {
    ocultar: {
        display:"none",
    },

};


export const UnidadFuncionalCreate = translate(withStyles(styles)(({ classes, ...props }) => {
    const {translate} = props;
    const [rutass , setRutas]= useState('');
    const [PrValidador, setPrValidador] = useState('');
    const [AbValidador, setAbValidador] = useState('');

/*VALIDAR  QUE LAS UNIDADES F NO SE SOBREPISEN*/
    const ValidarRutas = (value)  => {      
        let lenghtObject = Object.keys(value);
        let valor = '';        
        for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                  
        setRutas(valor) 
        let separador = valor.split('/');
        separador = separador[2];
        fetch(urlAppjs+'/validarPrUnidadCreate/'+separador)
        .then(function(response) {
            return response.json();
        })
        .then(function(myJson) {
            let prMaximo = myJson[0]["prMaximo"]      
            let AbMaximo = myJson[0]["abMaxia"]
            // setPrValidador(prMaximo)    
            // setAbValidador(AbMaximo)   
        })
    }
/* FIN DE VALIDAR  QUE LAS UNIDADES F NO SE SOBREPISEN*/

//VALIDAR RANGO DE LAS ABBSCISAS*/
    const [abInicio, setAbInicio] = useState('');
    const [abFin, setAbFin] = useState('');
    const [intento, setIntento] = useState(0);
    // const [totales, setIntento] = useState(0);

    const AbInicio = (value)  =>{
        if(value !== undefined)
        setAbInicio(value.substr(1, value.lastIndexOf('+')-1)+'.'+value.substr(value.lastIndexOf('+')+1,value.length))
    } 

    const AbFin = (value)  =>{
        if(value !== undefined)
        setAbFin(value.substr(1, value.lastIndexOf('+')-1)+'.'+value.substr(value.lastIndexOf('+')+1,value.length))
    } 

     const ValorMaximoAbsCreate = (value, props) =>{
        if(value !== undefined){
            value = value.split("K")
            if(value[1] !== undefined){
                value=value[1].split("+")
                if(value[0] > 999){
                    /* return 'El valor ingresado no puede ser mayor a PR999+999' */
                    return translate('dynatest.resources.validaciones.ValorMaximoAbsCreate')
                }
            }
        }
    }

    const validarLogicaAbsFinCreate=()=>{
        //console.log(translate[2].translate)
        let value = document.getElementById('ingresoAbFin');
        if(value)
        {
            let prInicio = document.getElementById('ingresoAbInicio').value;
            value = document.getElementById('ingresoAbFin').value;
            prInicio = prInicio.split('K')
            if(prInicio[1] !== undefined)
            {
                prInicio = prInicio[1].split('+')
                  
                value = value.split('K')
                if(value[1]){
                    value = value[1].split('+')
                
                    if(parseFloat(value[0]) < parseFloat(prInicio[0]))
                    {   
                        return translate('dynatest.resources.validaciones.validarLogicaAbsFinCreate')
                    }
                    if(parseFloat(value[0]) === parseFloat(prInicio[0]) && parseFloat(value[1]) === parseFloat(prInicio[1]))
                    {
                        return translate('dynatest.resources.validaciones.validarLogicaAbsFinCreate')
                    }
                    if(parseFloat(value[0]) === parseFloat(prInicio[0]) && parseFloat(value[1]) < parseFloat(prInicio[1]))
                    {
                        return translate('dynatest.resources.validaciones.validarLogicaAbsFinCreate')
                    }
                }
            }
            
        }
    }
     const ValorMaximoPrCreate =(value, props) =>{
        if(value !== undefined) {
            value = value.split("PR")
            if(value[1] !== undefined)
            {
                value=value[1].split("+")
                if(value[0] > 999)
                {
                    return translate('dynatest.resources.validaciones.ValorMaximoPrCreate');
                }
            }
        }
    }
     const validarLogicaPrFinCreate=(value, props)=>{
        if(value !== undefined){
            
            let prInicio = document.getElementById('ingresoPrInicio').value;
            prInicio = prInicio.split('PR')
            if(prInicio[1] !== undefined)
            {
                prInicio = prInicio[1].split('+')
                  
                value = value.split('PR')
                value = value[1].split('+')
            
                if(parseInt(value[0]) < parseInt(prInicio[0]))
                {   
                    return translate('dynatest.resources.validaciones.validarLogicaPrFinCreate');
                }
                if(parseInt(value[0]) === parseInt(prInicio[0]) && parseInt(value[1]) === parseInt(prInicio[1]))
                {
                    return translate('dynatest.resources.validaciones.validarLogicaPrFinCreate');
                }
                if(parseInt(value[0]) === parseInt(prInicio[0]) && parseInt(value[1]) < parseInt(prInicio[1]))
                {
                    return translate('dynatest.resources.validaciones.validarLogicaPrFinCreate');
                }
               
            }
    
            
            
        }
    }
    const [prInicio, setPrInicio] = useState('');
    const [prFin, setPrFin] = useState('');
    const PrInicio = (value)  =>{
        if(value !== undefined)
        setPrInicio(value.substr(2, value.lastIndexOf('+')-1)+'.'+value.substr(value.lastIndexOf('+')+1,value.length))
    } 

    const PrFin = (value)  =>{
        if(value !== undefined)
        setPrFin(value.substr(2, value.lastIndexOf('+')-1)+'.'+value.substr(value.lastIndexOf('+')+1,value.length))
    } 

    // const payload = {
    //     // filter :{
    //     //     'abInicio[gte]' : abInicio , 
    //     //     // 'abInicio[lte]' : abFin ,  
    //     //     'abInicio[lte]' : abFin,
    //     //     ruta : rutass,
    //     // },
    //     pagination: { page: 1, perPage: 10 },
    //  };
    const payload = {
        pagination: { page: 1, perPage: 10 },
        sort: { field: 'id', order: 'ASC' },
        filter :{
            'abInicio[gte]' : abInicio , 
            // 'abInicio[lte]' : abFin ,  
            'abInicio[lte]' : abFin,
            ruta : rutass,
        },
     };
     const payload2 = {
        filter :{
            'abFin[gte]' : abInicio , 
            // 'abInicio[lte]' : abFin ,  
            'abFin[lte]' : abFin,
            ruta : rutass,
        },
        pagination: { page: 1, perPage: 10 },
     };
     const payload3 = {
        filter :{
            'abInicio[lte]' : abInicio , 
            // 'abInicio[lte]' : abFin ,  
            'abFin[gte]' : abFin,
            ruta : rutass,
        },
        pagination: { page: 1, perPage: 10 },
     };

/*Validación para que los valores de PR y ABS no se soblepongan*/
    const NoSobreponerAbs = () =>{
        // rutass  id de la rita contra la que se valida
        // abInicio valor de la abscisa inicial ingresada
        // abFin valor de la abscisa final ingresada
        // tipoConsulta valida si es editar o crear 
        let IdRuta = rutass.substr(7);        
        let abInicioA = document.getElementById('ingresoAbInicio');
        let abFinA = document.getElementById('ingresoAbFin'); 
        if(abInicioA && abFinA)
        {
            abInicioA = abInicioA.value
            abFinA = abFinA.value
            abInicioA = abInicioA.substr(1, abInicioA.lastIndexOf('+')-1)+'.'+abInicioA.substr(abInicioA.lastIndexOf('+')+1,abInicioA.length);
            abFinA = abFinA.substr(1, abFinA.lastIndexOf('+')-1)+'.'+abFinA.substr(abFinA.lastIndexOf('+')+1,abFinA.length);
            fetch(urlAppjs+'/validar/rango/pr/abs/crear/'+IdRuta+'/'+abInicioA +'/'+ abFinA+'/0')
            .then(function(response) {
                
                return response.text();
            })
            .then(function(myJson) {  
                setAbValidador(myJson)   
                // setContador(1)
                if(AbValidador === '1'){
            
                    return translate('resources.validaciones.rangoNoValido')
                }                
            })
        }        
        if(AbValidador === '1'){
        
            return translate('resources.validaciones.rangoNoValido')
        }
    }

    const NoSobreponerPr = () =>{
        // rutass  id de la rita contra la que se valida
        // abInicio valor de la abscisa inicial ingresada
        // abFin valor de la abscisa final ingresada
        // tipoConsulta valida si es editar o crear 
        let IdRuta = rutass.substr(7);      
        let abInicioA = document.getElementById('ingresoPrInicio');
        let abFinA = document.getElementById('ingresoPrFin'); 

        abInicioA = abInicioA.value
        abFinA = abFinA.value
        abInicioA = abInicioA.substr(2, abInicioA.lastIndexOf('+')-2)+'.'+abInicioA.substr(abInicioA.lastIndexOf('+')+1,abInicioA.length);
        abFinA = abFinA.substr(2, abFinA.lastIndexOf('+')-2)+'.'+abFinA.substr(abFinA.lastIndexOf('+')+1,abFinA.length);
        fetch(urlAppjs+'/validar/rango/pr/crear/'+IdRuta+'/'+abInicioA +'/'+ abFinA+'/0')
        .then(function(response) {
            
            return response.text();
        })
        .then(function(myJson) {  
            setPrValidador(myJson)   
            // setContador(1)
            if(AbValidador === '1'){
        
                return translate('resources.validaciones.rangoNoValido')
            }                
        })
        if(PrValidador === '1'){
        
            return translate('resources.validaciones.rangoNoValido')
        }
    }

    const MansajeValidadorAb = () =>{
        if(AbValidador === '1'){
        
            return translate('resources.validaciones.rangoNoValido')
        }
    }

    const MansajeValidadorPb = () =>{
        if(PrValidador === '1'){
        
            return translate('resources.validaciones.rangoNoValido')
        }
    }

    return(
        <PimsCreate {...props}>
            <SimpleForm redirect="list" >            
                <Grid container spacing={24}>                
                    <Grid item xs={6}>
                        <ReferenceInput 
                            label="resources.unidad_funcionals.fields.ruta" 
                            source="ruta"  
                            reference="rutas" 
                            filterToQuery={searchText => ({ codigo: searchText })} 
                            sort={{field : 'codigo', order : 'asc'}}
                            onChange={ValidarRutas}
                            alwaysOn 
                            fullWidth 
                            validate={[required()]}
                        >
                            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
                        </ReferenceInput>
                    </Grid>
                    <Grid item xs={6}>
                        <UniqueUF
                            label="resources.unidad_funcionals.fields.codigo" 
                            source="codigo"
                        />
                    </Grid>
                    <TextInput label="Código" source="CODIGAAS"  id="codigo" style={{display : 'none'}} defaultValue={AbValidador}  fullWidth disabled />
                    <TextInput label="Código" source="PrValidador"  id="codigo" style={{display : 'none'}} defaultValue={PrValidador} fullWidth disabled />
                    <Grid item xs={6}>
                        <PRInput label="resources.unidad_funcionals.fields.abInicio" id="ingresoAbInicio" source="abInicio" fullWidth onChange={NoSobreponerAbs} validate={[AbInicio,required(),ValorMaximoAbsCreate,MansajeValidadorAb]}/>
                    </Grid>
                    <Grid item xs={6}>
                        <PRInput label="resources.unidad_funcionals.fields.abFin" id="ingresoAbFin" source="abFin" fullWidth onChange={NoSobreponerAbs} validate={[AbFin,required(),validarLogicaAbsFinCreate,MansajeValidadorAb]}/>
                    </Grid>
                                                      
                    <FormDataConsumer>
                        {({ formData, ...rest }) => (rutass &&
                                <Grid item xs={6}>
                                    <PInput label="resources.unidad_funcionals.fields.pInicio" source="pInicio"  id="ingresoPrInicio" fullWidth  onChange={NoSobreponerPr} validate={[required(),ValorMaximoPrCreate,MansajeValidadorPb,PrInicio]}/>
                                </Grid>
                            )
                        }
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => (rutass &&
                                <Grid item xs={6}>
                                    <PInput label="resources.unidad_funcionals.fields.pFin" source="pFin" id="ingresoPrFin" fullWidth  onChange={NoSobreponerPr} validate={[required(),validarLogicaPrFinCreate,MansajeValidadorPb,PrFin]}/>
                                </Grid>
                            )
                        }
                    </FormDataConsumer>
                    {                                    
                        <Query type="GET_ONE" resource="rutas" payload={{ id: rutass}} >
                            {({ data, loading, error }) => {                                                
                                if( data){
                                    return (
                                        <Grid item xs={3}>
                                            <TextInput  id="IdprInicio"  style={{display : 'none'}} label="pr Inicio" defaultValue={data.PInicio} source="pInicios" fullWidth />
                                            <TextInput id="IdprFin"   style={{display : 'none'}}  label="PrFin" defaultValue={data.PFin} source="pFins" fullWidth />
                                        </Grid>
                                    )
                                }
                                return <div></div>;                                                
                            }}
                        </Query>                            
                    }   
                </Grid>
            </SimpleForm>
        </PimsCreate>
        )
        }
));