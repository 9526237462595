import React, { useState } from 'react';
import {
    Toolbar, CardActions, Tab, NumberField, ShowController, ShowView, TextField, DateField,
    ReferenceField, BooleanField, TabbedForm, ListButton, EditButton, ArrayField, FormDataConsumer,
    SingleFieldList, FormTab, RichTextField, ReferenceArrayField, Datagrid
} from 'react-admin';
import ShowElements from './../ShowElements';
import { Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/core';
import { styles } from './../../../EstilosGrid';
import config from '../../../config/config';
import { translate } from 'react-admin';
let urlAppjs = config.UrlServer;


const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};


const PostShowActions = ({ basePath, data }) => (
    <CardActions style={cardActionStyle}>
        <EditButton basePath={basePath} record={data} />
        {/* Add your custom actions */}
        <ListButton basePath={basePath} />
    </CardActions>
);

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <span></span>

    </Toolbar>
));

export const PoliticasMantenimientoShow = translate(withStyles(styles)(({ classes, record, ...props }) => {

    return (
        <ShowController {...props}
        // title="Información Política de Mantenimiento"
        >
            {controllerProps =>
                <ShowView {...props} {...controllerProps}
                    title="dynatest.resources.politicas_mantenimientos.title.showTitle"
                    actions={<PostShowActions />}
                >
                    <TabbedForm toolbar={<CustomToolbar />}>
                        <FormTab label="dynatest.resources.politicas_mantenimientos.tabs.tabsTitle.INFORMACION">
                            <ReferenceField
                                label="resources.politicas_mantenimientos.fields.nombre"
                                source="nombre"
                                reference="politicas_nombres"
                                linkType={false}
                                formClassName={classes.grid_cont4}
                                fullWidth
                            >
                                <TextField label="" source="nombre" />
                            </ReferenceField>
                            <ReferenceField
                                label="resources.politicas_mantenimientos.fields.unidadFuncional"
                                source="unidadFuncional"
                                reference="unidad_funcionals"
                                linkType={false}
                                formClassName={classes.grid_cont4}
                                fullWidth
                            >
                                <TextField label="" source="fullCodigos" />
                            </ReferenceField>
                            <ReferenceField
                                label="resources.politicas_mantenimientos.fields.sistema"
                                source="sistema"
                                reference="sistemas"
                                linkType={false}
                                fullWidth
                                formClassName={classes.grid_cont3}
                            >
                                <TextField label="" source="nombre" />
                            </ReferenceField>
                            {controllerProps.record &&
                                <ShowElements politica={controllerProps.record.id} sistema={controllerProps.record.sistema} />
                            }


                        </FormTab>
                        <FormTab
                            label="dynatest.resources.politicas_mantenimientos.tabs.tabsTitle.auditoria"
                        >
                            <DateField
                                source="createdAt"
                                label="resources.politicas_mantenimientos.fields.createdAt"
                                fullWidth formClassName={classes.grid_cont4} showTime
                            />
                            <TextField
                                source="createdBy"
                                label="resources.politicas_mantenimientos.fields.createdBy"
                                fullWidth formClassName={classes.grid_cont4}
                            />
                            <span></span>
                            <DateField
                                source="updatedAt"
                                label="resources.politicas_mantenimientos.fields.updatedAt"
                                fullWidth formClassName={classes.grid_cont4} showTime
                            />
                            <TextField
                                source="updatedBy"
                                label="resources.politicas_mantenimientos.fields.updatedBy"
                                fullWidth formClassName={classes.grid_cont4}
                            />
                            <span></span>
                        </FormTab>
                    </TabbedForm>
                </ShowView>
            }
        </ShowController>
    )
}));