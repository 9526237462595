import React, { useState } from 'react';
import {
    NumberInput, SelectInput, ReferenceInput, TextInput, AutocompleteArrayInput, email,
    ReferenceArrayInput, AutocompleteInput, number
} from 'react-admin';
import { PimsCreate } from './../Create';
import { SimpleForm } from './../SimpleForm';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
} from 'react-admin';

import Grid from '@material-ui/core/Grid';
import { translate } from 'react-admin';

export const fooValidators = [minValue(5, 'valor minimo 5')];

export const Servicio_publicoCreate = translate(props => {
    const { translate } = props;
    const [departamentoss, setDepartamento] = useState('');

    const ValidarDepartamento = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setDepartamento(value)
    }

    const Departamento = { departamento: departamentoss };
    return (
        <PimsCreate {...props} >
            <SimpleForm submitOnEnter={false} redirect="list" >
                <Grid container spacing={24}>
                    <Grid item xs={4}>
                        <ReferenceInput label="resources.servicios_publicos.fileds.proyecto" source="proyecto" reference="proyectos" validate={[required()]} defaultValue={window.localStorage.getItem('id_project2')}>
                            <SelectInput optionText="nombre" disabled fullWidth />
                        </ReferenceInput>
                    </Grid>
                    <Grid item xs={4}>
                        <SelectInput label="resources.servicios_publicos.fileds.grupo" source="grupo" choices={[
                            { id: 'Acueducto', name: 'dynatest.resources.servicios_publicos.select.grupo.acueducto' },
                            { id: 'Aseo', name: 'dynatest.resources.servicios_publicos.select.grupo.aseo' },
                            { id: 'Energía', name: 'dynatest.resources.servicios_publicos.select.grupo.energia' },
                            { id: 'Gas natural', name: 'dynatest.resources.servicios_publicos.select.grupo.gasNatural' },
                            { id: 'Otros', name: 'dynatest.resources.servicios_publicos.select.grupo.otros' },
                            { id: 'Telecomunicaciones', name: 'dynatest.resources.servicios_publicos.select.grupo.telecomunicaciones' },
                        ]} fullWidth validate={[required()]} />
                    </Grid>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={3}>
                        <SelectInput label="resources.servicios_publicos.fileds.identificacion" fullWidth source="identificacion" allowEmpty emptyValue="" choices={[
                            { id: 'CC', name: 'dynatest.resources.servicios_publicos.select.identificacion.CC' },
                            { id: 'NIT', name: 'dynatest.resources.servicios_publicos.select.identificacion.NIT' },
                            { id: 'Otro', name: 'dynatest.resources.servicios_publicos.select.identificacion.Otro' },

                        ]} validate={[required()]} />
                    </Grid>
                    <Grid item xs={3}>
                        <NumberInput label="resources.servicios_publicos.fileds.numero_identificacion" source="numero_identificacion" fullWidth validate={[required(), minLength(2), maxLength(10), minValue(1), maxValue(9999999999)]} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput label="resources.servicios_publicos.fileds.empresa" source="empresa" fullWidth validate={[required(), minLength(2), maxLength(100)]} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput label="resources.servicios_publicos.fileds.nombre" source="nombre" fullWidth validate={[minLength(2), maxLength(100)]} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput label="resources.servicios_publicos.fileds.apellido" source="apellido" fullWidth validate={[minLength(2), maxLength(100)]} />
                    </Grid>

                    <Grid item xs={4}>
                        <TextInput label="resources.servicios_publicos.fileds.direccion" source="direccion" fullWidth validate={[required(), minLength(2), maxLength(100), minValue(1)]} />
                    </Grid>
                    <Grid item xs={4}>
                        <ReferenceInput
                            label="resources.servicios_publicos.fileds.departamento"
                            source="departamento"
                            reference="departamentos"
                            sort={{ field: "nombre", order: "ASC" }}
                            filterToQuery={searchText => ({ nombre: searchText })}
                            validate={[required(), ValidarDepartamento]}
                        >
                            <AutocompleteInput options={{ fullWidth: true }} optionText="nombre" fullWidth />
                        </ReferenceInput>
                    </Grid>
                    <Grid item xs={4}>
                        {/* MUNICIPIOS POR DEPARTAMENTO */}
                        {
                            departamentoss === '/departamentos/05' && (
                                <ReferenceArrayInput 
                                    label="resources.servicios_publicos.fileds.municipio1" 
                                    source="municipio1" 
                                    reference="municipios" 
                                    sort={{ field: "nombre", order: "ASC" }} 
                                    validate={[required()]} 
                                    filter={{ departamento: '/departamentos/05' }} 
                                    filterToQuery={searchText => ({ nombre: searchText })} 
                                    fullWidth>
                                    <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                </ReferenceArrayInput>
                            )
                        }
                        {
                            departamentoss === '/departamentos/08' && (
                                <ReferenceArrayInput 
                                    label="resources.servicios_publicos.fileds.municipio1" 
                                    source="municipio2" 
                                    reference="municipios" 
                                    sort={{ field: "nombre", order: "ASC" }} 
                                    validate={[required()]} 
                                    filter={{ departamento: '/departamentos/08' }} 
                                    filterToQuery={searchText => ({ nombre: searchText })} 
                                    fullWidth>
                                    <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                </ReferenceArrayInput>
                            )
                        }
                        {
                            departamentoss === '/departamentos/11' && (
                                <ReferenceArrayInput 
                                    label="resources.servicios_publicos.fileds.municipio1" 
                                    source="municipio3" 
                                    reference="municipios" 
                                    sort={{ field: "nombre", order: "ASC" }} 
                                    validate={[required()]} 
                                    filter={{ departamento: '/departamentos/11' }} 
                                    filterToQuery={searchText => ({ nombre: searchText })} 
                                    fullWidth>
                                    <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                </ReferenceArrayInput>
                            )
                        }
                        {
                            departamentoss === '/departamentos/13' && (
                                <ReferenceArrayInput 
                                    label="resources.servicios_publicos.fileds.municipio1" 
                                    source="municipio4"
                                    reference="municipios" 
                                    sort={{ field: "nombre", order: "ASC" }} 
                                    validate={[required()]} 
                                    filter={{ departamento: '/departamentos/13' }} 
                                    filterToQuery={searchText => ({ nombre: searchText })} 
                                    fullWidth>
                                    <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                </ReferenceArrayInput>
                            )
                        }
                        {
                            departamentoss === '/departamentos/15' && (
                                <ReferenceArrayInput 
                                    label="resources.servicios_publicos.fileds.municipio1" 
                                    source="municipio5" 
                                    reference="municipios" 
                                    sort={{ field: "nombre", order: "ASC" }} 
                                    validate={[required()]} 
                                    filter={{ departamento: '/departamentos/15' }} 
                                    filterToQuery={searchText => ({ nombre: searchText })} 
                                    fullWidth>
                                    <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                </ReferenceArrayInput>
                            )
                        }
                        {
                            departamentoss === '/departamentos/17' && (
                                <ReferenceArrayInput 
                                    label="resources.servicios_publicos.fileds.municipio1" 
                                    source="municipio6" 
                                    reference="municipios" 
                                    sort={{ field: "nombre", order: "ASC" }} 
                                    validate={[required()]} 
                                    filter={{ departamento: '/departamentos/17' }} 
                                    filterToQuery={searchText => ({ nombre: searchText })} 
                                    fullWidth>
                                    <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                </ReferenceArrayInput>
                            )
                        }
                        {
                            departamentoss === '/departamentos/18' && (
                                <ReferenceArrayInput 
                                    label="resources.servicios_publicos.fileds.municipio1" 
                                    source="municipio7" 
                                    reference="municipios" 
                                    sort={{ field: "nombre", order: "ASC" }} 
                                    validate={[required()]} 
                                    filter={{ departamento: '/departamentos/18' }} 
                                    filterToQuery={searchText => ({ nombre: searchText })} 
                                    fullWidth>
                                    <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                </ReferenceArrayInput>
                            )
                        }
                        {
                            departamentoss === '/departamentos/19' && (
                                <ReferenceArrayInput 
                                    label="resources.servicios_publicos.fileds.municipio1" 
                                    source="municipio8" 
                                    reference="municipios" 
                                    sort={{ field: "nombre", order: "ASC" }} 
                                    validate={[required()]} 
                                    filter={{ departamento: '/departamentos/19' }} 
                                    filterToQuery={searchText => ({ nombre: searchText })} 
                                    fullWidth>
                                    <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                </ReferenceArrayInput>
                            )
                        }
                        {
                            departamentoss === '/departamentos/20' && (
                                <ReferenceArrayInput 
                                    label="resources.servicios_publicos.fileds.municipio1" 
                                    source="municipio9" 
                                    reference="municipios" 
                                    sort={{ field: "nombre", order: "ASC" }} 
                                    validate={[required()]} 
                                    filter={{ departamento: '/departamentos/20' }} 
                                    filterToQuery={searchText => ({ nombre: searchText })} 
                                    fullWidth>
                                    <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                </ReferenceArrayInput>
                            )
                        }
                        {
                            departamentoss === '/departamentos/23' && (
                                <ReferenceArrayInput 
                                    label="resources.servicios_publicos.fileds.municipio1" 
                                    source="municipio10" 
                                    reference="municipios" 
                                    sort={{ field: "nombre", order: "ASC" }} 
                                    validate={[required()]} 
                                    filter={{ departamento: '/departamentos/23' }} 
                                    filterToQuery={searchText => ({ nombre: searchText })} 
                                    fullWidth>
                                    <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                </ReferenceArrayInput>
                            )
                        }
                        {
                            departamentoss === '/departamentos/25' && (
                                <ReferenceArrayInput 
                                    label="resources.servicios_publicos.fileds.municipio1" 
                                    source="municipio11" 
                                    reference="municipios" 
                                    sort={{ field: "nombre", order: "ASC" }} 
                                    validate={[required()]} 
                                    filter={{ departamento: '/departamentos/25' }}
                                    filterToQuery={searchText => ({ nombre: searchText })} 
                                    fullWidth>
                                    <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                </ReferenceArrayInput>
                            )
                        }
                        {
                            departamentoss === '/departamentos/27' && (
                                <ReferenceArrayInput 
                                    label="resources.servicios_publicos.fileds.municipio1" 
                                    source="municipio12" 
                                    reference="municipios" 
                                    sort={{ field: "nombre", order: "ASC" }} 
                                    validate={[required()]} 
                                    filter={{ departamento: '/departamentos/27' }} 
                                    filterToQuery={searchText => ({ nombre: searchText })} 
                                    fullWidth>
                                    <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                </ReferenceArrayInput>
                            )
                        }
                        {
                            departamentoss === '/departamentos/41' && (
                                <ReferenceArrayInput 
                                    label="resources.servicios_publicos.fileds.municipio1" 
                                    source="municipio13" 
                                    reference="municipios" 
                                    sort={{ field: "nombre", order: "ASC" }} 
                                    validate={[required()]} 
                                    filter={{ departamento: '/departamentos/41' }} 
                                    filterToQuery={searchText => ({ nombre: searchText })} 
                                    fullWidth>
                                    <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                </ReferenceArrayInput>
                            )
                        }
                        {
                            departamentoss === '/departamentos/44' && (
                                <ReferenceArrayInput 
                                    label="resources.servicios_publicos.fileds.municipio1" 
                                    source="municipio14" 
                                    reference="municipios" 
                                    sort={{ field: "nombre", order: "ASC" }} 
                                    validate={[required()]} 
                                    filter={{ departamento: '/departamentos/44' }} 
                                    filterToQuery={searchText => ({ nombre: searchText })} 
                                    fullWidth>
                                    <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                </ReferenceArrayInput>
                            )
                        }
                        {
                            departamentoss === '/departamentos/47' && (
                                <ReferenceArrayInput 
                                    label="resources.servicios_publicos.fileds.municipio1" 
                                    source="municipio15" 
                                    reference="municipios" 
                                    sort={{ field: "nombre", order: "ASC" }} 
                                    validate={[required()]} 
                                    filter={{ departamento: '/departamentos/47' }} 
                                    filterToQuery={searchText => ({ nombre: searchText })} 
                                    fullWidth>
                                    <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                </ReferenceArrayInput>
                            )
                        }
                        {
                            departamentoss === '/departamentos/50' && (
                                <ReferenceArrayInput 
                                    label="resources.servicios_publicos.fileds.municipio1" 
                                    source="municipio16" 
                                    reference="municipios" 
                                    sort={{ field: "nombre", order: "ASC" }} 
                                    validate={[required()]} 
                                    filter={{ departamento: '/departamentos/50' }} 
                                    filterToQuery={searchText => ({ nombre: searchText })} 
                                    fullWidth>
                                    <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                </ReferenceArrayInput>
                            )
                        }
                        {
                            departamentoss === '/departamentos/52' && (
                                <ReferenceArrayInput 
                                    label="resources.servicios_publicos.fileds.municipio1" 
                                    source="municipio17" 
                                    reference="municipios" 
                                    sort={{ field: "nombre", order: "ASC" }} 
                                    validate={[required()]} 
                                    filter={{ departamento: '/departamentos/52' }} 
                                    filterToQuery={searchText => ({ nombre: searchText })} 
                                    fullWidth>
                                    <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                </ReferenceArrayInput>
                            )
                        }
                        {
                            departamentoss === '/departamentos/54' && (
                                <ReferenceArrayInput 
                                    label="resources.servicios_publicos.fileds.municipio1" 
                                    source="municipio18" 
                                    reference="municipios" 
                                    sort={{ field: "nombre", order: "ASC" }} 
                                    validate={[required()]} 
                                    filter={{ departamento: '/departamentos/54' }} 
                                    filterToQuery={searchText => ({ nombre: searchText })} 
                                    fullWidth>
                                    <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                </ReferenceArrayInput>
                            )
                        }
                        {
                            departamentoss === '/departamentos/63' && (
                                <ReferenceArrayInput 
                                    label="resources.servicios_publicos.fileds.municipio1" 
                                    source="municipio19" 
                                    reference="municipios" 
                                    sort={{ field: "nombre", order: "ASC" }} 
                                    validate={[required()]} 
                                    filter={{ departamento: '/departamentos/63' }} 
                                    filterToQuery={searchText => ({ nombre: searchText })} 
                                    fullWidth>
                                    <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                </ReferenceArrayInput>
                            )
                        }
                        {
                            departamentoss === '/departamentos/66' && (
                                <ReferenceArrayInput 
                                    label="resources.servicios_publicos.fileds.municipio1" 
                                    source="municipio20" 
                                    reference="municipios" 
                                    sort={{ field: "nombre", order: "ASC" }} 
                                    validate={[required()]} 
                                    filter={{ departamento: '/departamentos/66' }} 
                                    filterToQuery={searchText => ({ nombre: searchText })} 
                                    fullWidth>
                                    <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                </ReferenceArrayInput>
                            )
                        }
                        {
                            departamentoss === '/departamentos/68' && (
                                <ReferenceArrayInput 
                                    label="resources.servicios_publicos.fileds.municipio1" 
                                    source="municipio21" 
                                    reference="municipios" 
                                    sort={{ field: "nombre", order: "ASC" }} 
                                    validate={[required()]} 
                                    filter={{ departamento: '/departamentos/68' }} 
                                    filterToQuery={searchText => ({ nombre: searchText })} 
                                    fullWidth>
                                    <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                </ReferenceArrayInput>
                            )
                        }
                        {
                            departamentoss === '/departamentos/70' && (
                                <ReferenceArrayInput 
                                    label="resources.servicios_publicos.fileds.municipio1" 
                                    source="municipio22" 
                                    reference="municipios" 
                                    sort={{ field: "nombre", order: "ASC" }} 
                                    validate={[required()]} 
                                    filter={{ departamento: '/departamentos/70' }} 
                                    filterToQuery={searchText => ({ nombre: searchText })} 
                                    fullWidth>
                                    <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                </ReferenceArrayInput>
                            )
                        }
                        {
                            departamentoss === '/departamentos/73' && (
                                <ReferenceArrayInput 
                                    label="resources.servicios_publicos.fileds.municipio1" 
                                    source="municipio23" 
                                    reference="municipios" 
                                    sort={{ field: "nombre", order: "ASC" }} 
                                    validate={[required()]} 
                                    filter={{ departamento: '/departamentos/73' }} 
                                    filterToQuery={searchText => ({ nombre: searchText })} 
                                    fullWidth>
                                    <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                </ReferenceArrayInput>
                            )
                        }
                        {
                            departamentoss === '/departamentos/76' && (
                                <ReferenceArrayInput 
                                    label="resources.servicios_publicos.fileds.municipio1" 
                                    source="municipio24" 
                                    reference="municipios" 
                                    sort={{ field: "nombre", order: "ASC" }} 
                                    validate={[required()]} 
                                    filter={{ departamento: '/departamentos/76' }} 
                                    filterToQuery={searchText => ({ nombre: searchText })} 
                                    fullWidth>
                                    <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                </ReferenceArrayInput>
                            )
                        }
                        {
                            departamentoss === '/departamentos/81' && (
                                <ReferenceArrayInput 
                                    label="resources.servicios_publicos.fileds.municipio1" 
                                    source="municipio25" 
                                    reference="municipios" 
                                    sort={{ field: "nombre", order: "ASC" }} 
                                    validate={[required()]} 
                                    filter={{ departamento: '/departamentos/81' }} 
                                    filterToQuery={searchText => ({ nombre: searchText })} 
                                    fullWidth>
                                    <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                </ReferenceArrayInput>
                            )
                        }
                        {
                            departamentoss === '/departamentos/85' && (
                                <ReferenceArrayInput 
                                    label="resources.servicios_publicos.fileds.municipio1" 
                                    source="municipio26" 
                                    reference="municipios" 
                                    sort={{ field: "nombre", order: "ASC" }} 
                                    validate={[required()]} 
                                    filter={{ departamento: '/departamentos/85' }} 
                                    filterToQuery={searchText => ({ nombre: searchText })} 
                                    fullWidth>
                                    <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                </ReferenceArrayInput>
                            )
                        }
                        {
                            departamentoss === '/departamentos/86' && (
                                <ReferenceArrayInput 
                                    label="resources.servicios_publicos.fileds.municipio1" 
                                    source="municipio27" 
                                    reference="municipios" 
                                    sort={{ field: "nombre", order: "ASC" }} 
                                    validate={[required()]} 
                                    filter={{ departamento: '/departamentos/86' }} 
                                    filterToQuery={searchText => ({ nombre: searchText })} 
                                    fullWidth>
                                    <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                </ReferenceArrayInput>
                            )
                        }
                        {
                            departamentoss === '/departamentos/88' && (
                                <ReferenceArrayInput 
                                    label="resources.servicios_publicos.fileds.municipio1" 
                                    source="municipio28" 
                                    reference="municipios" 
                                    sort={{ field: "nombre", order: "ASC" }} 
                                    validate={[required()]} 
                                    filter={{ departamento: '/departamentos/88' }} 
                                    filterToQuery={searchText => ({ nombre: searchText })} 
                                    fullWidth>
                                    <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                </ReferenceArrayInput>
                            )
                        }
                        {
                            departamentoss === '/departamentos/91' && (
                                <ReferenceArrayInput 
                                    label="resources.servicios_publicos.fileds.municipio1" 
                                    source="municipio29" 
                                    reference="municipios" 
                                    sort={{ field: "nombre", order: "ASC" }} 
                                    validate={[required()]} 
                                    filter={{ departamento: '/departamentos/91' }} 
                                    filterToQuery={searchText => ({ nombre: searchText })} 
                                    fullWidth>
                                    <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                </ReferenceArrayInput>
                            )
                        }
                        {
                            departamentoss === '/departamentos/94' && (
                                <ReferenceArrayInput 
                                    label="resources.servicios_publicos.fileds.municipio1" 
                                    source="municipio30" 
                                    reference="municipios" 
                                    sort={{ field: "nombre", order: "ASC" }} 
                                    validate={[required()]} 
                                    filter={{ departamento: '/departamentos/94' }} 
                                    filterToQuery={searchText => ({ nombre: searchText })} 
                                    fullWidth>
                                    <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                </ReferenceArrayInput>
                            )
                        }
                        {
                            departamentoss === '/departamentos/95' && (
                                <ReferenceArrayInput 
                                    label="resources.servicios_publicos.fileds.municipio1" 
                                    source="municipio31" 
                                    reference="municipios" 
                                    sort={{ field: "nombre", order: "ASC" }} 
                                    validate={[required()]} 
                                    filter={{ departamento: '/departamentos/95' }} 
                                    filterToQuery={searchText => ({ nombre: searchText })} 
                                    fullWidth>
                                    <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                </ReferenceArrayInput>
                            )
                        }
                        {
                            departamentoss === '/departamentos/97' && (
                                <ReferenceArrayInput 
                                    label="resources.servicios_publicos.fileds.municipio1" 
                                    source="municipio32" 
                                    reference="municipios" 
                                    sort={{ field: "nombre", order: "ASC" }} 
                                    validate={[required()]} 
                                    filter={{ departamento: '/departamentos/97' }} 
                                    filterToQuery={searchText => ({ nombre: searchText })} 
                                    fullWidth>
                                    <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                </ReferenceArrayInput>
                            )
                        }
                        {
                            departamentoss === '/departamentos/99' && (
                                <ReferenceArrayInput 
                                    label="resources.servicios_publicos.fileds.municipio1" 
                                    source="municipio33" 
                                    reference="municipios" 
                                    sort={{ field: "nombre", order: "ASC" }} 
                                    validate={[required()]} 
                                    filter={{ departamento: '/departamentos/99' }} 
                                    filterToQuery={searchText => ({ nombre: searchText })} 
                                    fullWidth>
                                    <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                </ReferenceArrayInput>
                            )
                        }
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput label="resources.servicios_publicos.fileds.telefono_1" source="telefono_1" fullWidth className="labelNumber" validate={[required(), number("Solo Números")]} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput label="resources.servicios_publicos.fileds.telefono_2" source="telefono_2" fullWidth className="labelNumber" validate={[number("Solo Números")]} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput label="resources.servicios_publicos.fileds.correo" source="correo" fullWidth validate={[minLength(2), maxLength(100), email()]} />
                    </Grid>

                    <Grid item xs={12}>
                        <TextInput label="resources.servicios_publicos.fileds.observaciones" source="observaciones" fullWidth validate={[minLength(2), maxLength(250)]} />
                    </Grid>

                </Grid>
            </SimpleForm>
        </PimsCreate>
    )
});