import React from 'react';
import { ReferenceField,ReferenceInput,SelectInput,Filter,BooleanField,TextField,List, Datagrid, EditButton, ShowButton } from 'react-admin';
import {  BulkDeleteWithConfirmButton,NumberField , Loading } from 'react-admin';
import { Fragment } from 'react';
import { translate } from 'react-admin';
import { CardActions, CreateButton, ExportButton } from 'react-admin';
const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);
const ListAction = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
        <ExportButton basePath={basePath}/>
    </CardActions>
);
const ListAction2 = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
    </CardActions>
);
const InvetarioFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput label="resources.estacion_conteos.fields.unidad_funcional" source="unidadFuncional" reference="unidad_funcionals" alwaysOn>                
            <SelectInput optionText="fullCodigos" />                
        </ReferenceInput>
    </Filter>
);



export const EstacionInventarioList = ({ permissions, ...props }) => {
    if (!permissions) return <Loading />;  
    return (
        <List  {...props} 
            sort={{ field: 'id', order: 'DESC' }} 
            filters={<InvetarioFilter/>}  
            actions={permissions['inventarioa']  == 1 ? <ListAction /> : permissions['inventarioa']  == 2 ? <ListAction2 />: null}
            bulkActionButtons={permissions['inventarioa']  == 1 ? <PostBulkActionButtons />: null} >
            <Datagrid >
                <TextField label="resources.estacion_conteos.fields.nombre" source="nombre"/>
                <ReferenceField label="resources.estacion_conteos.fields.unidad_funcional" source="unidad_funcional" reference="unidad_funcionals" linkType={false}>                
                        <TextField source="fullCodigos" />                
                </ReferenceField>
                {/* <ReferenceField label="Unidad Funcional" source="segmento" reference="segmentos" linkType={false}>                
                    <TextField source="tramo_.unidadFuncional_.codigo" />                
                </ReferenceField> */}
                
                <ReferenceField label="resources.estacion_conteos.fields.segmento" source="segmento" reference="segmentos" fullWidth linkType={false}>
                        <TextField source="codigo" />
                </ReferenceField>
                <NumberField label="resources.estacion_conteos.fields.years" source="years" fullWidth linkType={false}/>
                <BooleanField label="dynatest.resources.estacion_conteos.boolean.operativa" source="operativa" />
                {permissions['inventarioa'] == 1 || permissions['inventarioa'] == 2 || permissions['inventarioa'] == 4 ? <ShowButton/>: null}
                {permissions['inventarioa'] == 1 || permissions['inventarioa'] == 2 ? <EditButton/>: null}  
            </Datagrid>
        </List>
)};