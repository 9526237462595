import React from 'react';
import { CardActions, ListButton } from 'react-admin';

export const PimsActionsWithoutImage = ({
    basePath,
    className,
    data,
    hasList,
    hasShow,
    resource,
}) => (
    <CardActions className={className}>
        {hasList && <ListButton basePath={basePath} />}            
    </CardActions>
);