import React, {useState} from 'react';
import {   ReferenceInput, SelectInput, NumberInput,TextInput, SimpleFormIterator, ArrayInput,NotFound } from 'react-admin';
import { PimsCreate } from './../Create';
import { SimpleForm } from './../SimpleForm';
import {
    required,
    minLength,
    maxLength,
    maxValue,
    minValue
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { withStyles} from '@material-ui/core';
import {validatLatitud, validarLongitud, Entero, AlturaMayor, CoordenadasMaximasLat, CoordenadasMaximasLng } from './../validacionesInputs';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import UniqueProject from './../componentes/UniqueProject';
import { translate } from 'react-admin';

const styles={

    img:{
        height: 128,
    },
    number:{
        textAlign: 'right',
    },
    ocultar:{
        display:'none'
    }
}


export const ProyectoCreate = translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;

    const [latMax , setLatMax]= useState('');
    const LatMax =(value) =>{
        let lenghtObject = Object.keys(value);
        let valor = '';        
        for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                
        setLatMax(value) 
    }

    const [lngMax , setLngMax]= useState('');
    const LngMax =(value) =>{
        let lenghtObject = Object.keys(value);
        let valor = '';        
        for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                
        setLngMax(value) 
    }

    const [lngMin , setLngMin]= useState('');
    const LngMin =(value) =>{
        let lenghtObject = Object.keys(value);
        let valor = '';        
        for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                
        setLngMin(value) 
    }

    const [latMin , setLatMin]= useState('');
    const LatMin =(value) =>{
        let lenghtObject = Object.keys(value);
        let valor = '';        
        for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                
        setLatMin(value) 
    }
    return(
    <PimsCreate {...props} >
        <SimpleForm submitOnEnter={false} redirect="list" >      
            <Grid container spacing={24}>
                <Grid item xs={2}>
                    <UniqueProject 
                        source="codigo" 
                        label="resources.proyectos.fields.codigo" 
                        fullWidth 
                        validate={[required(), minLength(2), maxLength(10)]}
                    />
                </Grid>
                <Grid item xs={10}>
                    <TextInput label="resources.proyectos.fields.nombre" source="nombre" fullWidth validate={[required(), minLength(2), maxLength(100)]}/>
                    <TextInput label="idioma" source="idioma" fullWidth defaultValue="es" className={classes.ocultar}/>
                </Grid>
                <Grid item xs={12}>
                    <ReferenceInput 
                        label="resources.proyectos.fields.licencia" 
                        source="licencia" 
                        reference="licencias" 
                        fullWidth 
                        sort={{ field : "claveLicencia", order: "ASC"}}
                        validate={[required()]}
                    >
                        <SelectInput optionText="claveLicencia" />
                    </ReferenceInput>
                </Grid>
                <Grid item xs={12}>
                    <TextInput label="resources.proyectos.fields.concesionario" source="concesionario" fullWidth validate={[required(), minLength(2), maxLength(100)]}/> 
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h3" component="h3">{translate('dynatest.resources.proyectos.title.coordMinimas')}</Typography>
                    <Divider style={{marginTop : '10px' }} />
                </Grid>
                <Grid item xs={6}>
                    <NumberInput label="resources.proyectos.fields.latitud"  id="LatitudInicial" source="latitud1"  fullWidth validate={[required(), LatMin , maxValue(90), minValue(-90), validatLatitud ]}/>
                </Grid> 
                <Grid item xs={6}>
                    <NumberInput  label="resources.proyectos.fields.longitud" id="LongitudInicial" source="longitud1"  fullWidth validate={[required(), LngMin, validarLongitud , maxValue(180), minValue(-180)]}/> 
                </Grid>
                
                <Grid item xs={12}>
                    <Typography variant="h3" component="h3">{translate('dynatest.resources.proyectos.title.coordMaximas')}</Typography>
                    <Divider style={{marginTop : '10px' }} />
                </Grid>        
                <Grid item xs={6}>
                    <NumberInput label="resources.proyectos.fields.latitud" source="latitud2"  fullWidth validate={[CoordenadasMaximasLat,required(), LatMax, maxValue(90), minValue(-90), validatLatitud ]}/> 
                </Grid>
                <Grid item xs={6}>
                    <NumberInput label="resources.proyectos.fields.longitud" source="longitud2"  fullWidth validate={[CoordenadasMaximasLng,required(),  LngMax, validarLongitud, maxValue(180), minValue(-180) ]}/>
                </Grid> 
                <Grid item xs={12}>
                    <Typography variant="h3" component="h3">{translate('dynatest.resources.proyectos.title.altura')}</Typography>
                    <Divider style={{marginTop : '10px' }} />
                </Grid> 
                <Grid item xs={6}>
                    <NumberInput  label="resources.proyectos.fields.alturaIni"  source="alturaIni"  fullWidth id="alturaIni" validate={[maxValue(8700), minValue(-450, 'Debe ser al menos -450'),Entero]}/> 
                </Grid>   
                <Grid item xs={6}>
                    <NumberInput label="resources.proyectos.fields.alturaFin" source="alturaFin"  fullWidth validate={[ maxValue(8700), minValue(-450, 'Debe ser al menos -450'),  AlturaMayor, Entero]}/>
                </Grid>
                
                <Grid item xs={12}>
                    <ArrayInput label="resources.proyectos.fields.ubicaciones" source="ubicaciones"  className={classes.ocultar}
                        defaultValue={[
                            {
                                lat: latMax,
                                lng: lngMax
                            },
                            {
                                lat: latMax,
                                lng: lngMin
                            },
                            {
                                lat: latMin,
                                lng: lngMin
                            },
                            {
                                lat: latMin,
                                lng: lngMax
                            }
                            ]}
                    >
                        <SimpleFormIterator disableAdd disableRemove >
                                <TextInput label="resources.proyectos.fields.latitud" source="lat" fullWidth validate={[required()]}/>
                                <TextInput label="resources.proyectos.fields.longitud" source="lng" fullWidth validate={[required()]}/>
                        </SimpleFormIterator>
                    </ArrayInput>
                </Grid>
            </Grid>
        </SimpleForm>
    </PimsCreate>

)

}));
