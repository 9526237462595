import React, { useState } from 'react';
import { TextInput, NumberField, ShowController, ShowView, TextField, DateField, ReferenceField, BooleanField, TabbedForm, ListButton, EditButton,  FormTab } from 'react-admin';
import { GMapInput } from '../../../inputs/GMapInput';
import { PRField } from './../../../fields/PRField';
import { PField } from './../../../fields/PField';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core';
import { styles } from './../../../EstilosGrid';
import config from '../../../config/config';
import { ButtonCarousel } from './../../images/ButtonCarousel';
import { translate } from 'react-admin';
let urlAppjs = config.UrlServer;


export const ElementoRedShow = translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;
    // const [segmento, setSegmento] = useState('');
    // const [tabla, setTabla] = useState('flexible_detalles');

    // const ValidarSegmento = (value) => {


    //     if (value) {

    //         var Datos = []

    //         let urlApi = urlAppjs + value;

    //         fetch(urlApi)
    //             .then((response) => {
    //                 console.log("REST");
    //                 return response.json()
    //             })
    //             .then((TablaEstructura) => {
    //                 console.log(TablaEstructura);
    //                 setSegmento(TablaEstructura["tipoSuperficie"].id);
    //                 console.log(TablaEstructura["tipoSuperficie"].id);
    //             })

    //         console.log("HOOK: " + segmento)

    //         if (segmento === "PFLEX") { setTabla("flexible_detalles") }
    //         if (segmento === "PRIGD") { setTabla("rigido_detalles") }
    //         if (segmento === "CLZ") { setTabla("adoquin_detalles") }
    //         if (segmento === "POTR") { setTabla("otro_detalles") }

    //     }

    // }

    //let elemento = "/tipo_elementos/COMR";
    const [imagen , setImagen] =useState(true);
    const ValidarImagen =  (value) =>{
        // console.log(value + "Aqui est la imagen ")
        if(value !== null && value !== undefined && value !== 'undefined')
        {
            let lenghtObject = Object.keys(value);
            let valor = '';        
            for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                
            setImagen(value) 
            // console.log(value + "Aqui est la imagen ")
        }
     }
    return (
        <ShowController {...props} title="dynatest.resources.redes.title.showTitle">
            {controllerProps =>
                <ShowView {...props} {...controllerProps} title="dynatest.resources.redes.title.showTitle" actions={<ButtonCarousel/>}>
                    <TabbedForm toolbar={false}>
                        <FormTab label="dynatest.resources.redes.tabs.tabsTitle.identificacion">
                            <TextField label="resources.redes.fields.codigo" source="smartCode" formClassName={classes.grid_cont12} sortable={false}/>
                            {/* <TextField label="Consecutivo" source="fullConsecutivo" formClassName={classes.grid_cont4_f} /> */}
                            <ReferenceField label="resources.redes.fields.tipoElemento" source="tipoElemento" reference="tipo_elementos" linkType={false} formClassName={classes.grid_cont4_f}>
                                <TextField source="nombre" fullWidth />
                            </ReferenceField>
                            <TextInput source="tipoElemento_.src" validate={ValidarImagen} style={{display : 'none'}}/>
                            <TextField label="resources.redes.fields.margen" source="margen" fullWidth formClassName={classes.grid_cont4_f} />
                            <TextField label="resources.redes.fields.sentidoB" source="sentido" fullWidth formClassName={classes.grid_cont4} />
                            <Typography variant="b" component="b" fullWidth formClassName={classes.grid_cont12}>{translate('dynatest.resources.redes.title.localizacion')}</Typography>
                            <Divider fullWidth formClassName={classes.grid_cont12} />

                            <ReferenceField label="resources.redes.fields.unidadFuncional" source="unidadFuncional" reference="unidad_funcionals" linkType={false} fullWidth formClassName={classes.grid_cont4_f}>
                                <TextField source="fullCodigos" />
                            </ReferenceField>
                            <ReferenceField label="resources.redes.fields.tramo" source="tramo" reference="tramos" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <TextField source="fullCodigos" />
                            </ReferenceField>
                            <ReferenceField label="resources.redes.fields.segmento" source="segmento" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <TextField source="fullCodigos" />
                            </ReferenceField>
                            <Typography variant="b" component="b">{translate('dynatest.resources.redes.title.coordenadas')}</Typography>
                            <Divider fullWidth style={{width:'300%', marginTop :'10px'}} /> 
                            <PRField label="resources.redes.fields.abscisa" id="ingresoAbFin" source="abInicio" fullWidth formClassName={classes.grid_cont3_f}/>
                            <PField label="resources.redes.fields.pr" id="ingresoPrInicio" source="pInicio" fullWidth formClassName={classes.grid_cont3} />
                            <NumberField label="resources.redes.fields.latitud" source="latitudInicial" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/>
                            <NumberField label="resources.redes.fields.longitud" source="longitudInicial" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/>
                            {controllerProps.record && (controllerProps.record.tipoElemento === "/tipo_elementos/DCT" || controllerProps.record.tipoElemento === "/tipo_elementos/REE") &&
                                <Typography variant="b" component="b">{translate('dynatest.resources.redes.title.coordenadasFinales')}</Typography>
                            }
                            {controllerProps.record && (controllerProps.record.tipoElemento === "/tipo_elementos/DCT" || controllerProps.record.tipoElemento === "/tipo_elementos/REE") &&
                                <PRField label="resources.redes.fields.abscisa" id="ingresoAbFin" source="abFin" fullWidth formClassName={classes.grid_cont3_f}/>
                            }
                            {controllerProps.record && (controllerProps.record.tipoElemento === "/tipo_elementos/DCT" || controllerProps.record.tipoElemento === "/tipo_elementos/REE") &&
                                <PField label="resources.redes.fields.pr" id="ingresoPrInicio" source="pFin" fullWidth formClassName={classes.grid_cont3} />
                            }
                            {controllerProps.record && (controllerProps.record.tipoElemento === "/tipo_elementos/DCT" || controllerProps.record.tipoElemento === "/tipo_elementos/REE") &&
                                <NumberField label="resources.redes.fields.latitud" source="latitudFinal" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/>
                            }
                            {controllerProps.record && (controllerProps.record.tipoElemento === "/tipo_elementos/DCT" || controllerProps.record.tipoElemento === "/tipo_elementos/REE") &&
                                <NumberField label="resources.redes.fields.longitud" source="longitudFinal" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/>
                            }
                            <GMapInput style={{width : "100%"}}
                                defaultZoom={12}                                    
                                defaultCenter={{ lat: 4.7567039, lng: -74.0311056 }}
                                source="ubicacionMapa"
                                multipleMarkers="true"
                                googleKey="AIzaSyCivTXzJBYRBK8nosBYRFXnv-hGXIbhnYY"
                                icon= {imagen} 
                            />
                            </FormTab>
                        <FormTab label="dynatest.resources.redes.tabs.tabsTitle.caracteristicas">
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/LUM" &&
                                <TextField label="resources.redes.fields.entornoVia" source="entornoVia" fullWidth formClassName={classes.grid_cont4_f} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/LUM" &&
                                <TextField label="resources.redes.fields.claseIluminacion" source="claseIluminacion" formClassName={classes.grid_cont4_s}/>
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/LUM" &&
                                <TextField label="resources.redes.fields.tipoLampara" source="tipoLampara" fullWidth formClassName={classes.grid_cont3_s} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/LUM" && <br/>}

                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/LUM" &&
                                <TextField label="resources.redes.fields.brazoTipo" source="brazoTipo" fullWidth formClassName={classes.grid_cont4_f} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/LUM" &&
                                <TextField label="resources.redes.fields.disposicionIluminaria" source="disposicionIluminaria" fullWidth formClassName={classes.grid_cont4_s} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/LUM" &&
                                <NumberField label="resources.redes.fields.luminancia" source="valorIluminancia" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3_s} />
                            }

                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/LUM" && <br/>}

                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/LUM" &&
                                <NumberField label="resources.redes.fields.angulo" source="anguloInclinacion" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_f} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/LUM" &&
                                <NumberField label="resources.redes.fields.largo" source="largo"  fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s}/>
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/LUM" &&
                                <NumberField label="resources.redes.fields.altura" source="altura"  fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3_s}/>
                            }

                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/LUM" && <br/>}

                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/LUM" &&
                                <TextField label="resources.redes.fields.empresa" source="empresa_.empresa" reference="servicios_publicos" linkType={false} fullWidth formClassName={classes.grid_cont12} />
                            }

                            {/* ---------ELEMENTO = SEMAFORO -------- */}
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/SEM" &&
                                <TextField label="resources.redes.fields.clase" source="entornoVia" fullWidth formClassName={classes.grid_cont4_f} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/SEM" &&
                                <TextField label="resources.redes.fields.clasificacionSoporte" source="claseIluminacion" fullWidth formClassName={classes.grid_cont4_s} />
                            }
                            
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/SEM" && <br/>}

                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/SEM" &&
                                <NumberField label="resources.redes.fields.largoLuminoso" source="largo" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_f} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/SEM" &&
                                <NumberField label="resources.redes.fields.anchoLuminoso" source="ancho" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/SEM" &&
                                <NumberField label="resources.redes.fields.altura" source="altura" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3_s} />
                            }
                            
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/SEM" && <br/>}

                            {/* --------- ELEMENTOS BÁSCULA DE PESAJE  ----------- */}
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/BDP" &&
                                <TextField label="resources.redes.fields.marca" source="entornoVia" fullWidth formClassName={classes.grid_cont4_f} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/BDP" &&
                                <NumberField label="resources.redes.fields.capacidadT" source="altura" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s} />
                            }
                            
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/BDP" && <br/>}

                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/BDP" &&
                                <NumberField label="resources.redes.fields.largo" source="largo" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_f} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/BDP" &&
                                <NumberField label="resources.redes.fields.ancho" source="ancho" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s} />
                            }

                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/BDP" && <br/>}
                            
                            {/* {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/BDP" &&
                                <ReferenceField label="Nivel de tráfico" source="nivelTrafico" reference="flexible_detalles" linkType={false}  fullWidth formClassName={classes.grid_cont6_f}>
                                    <TextField source="nivel_trafico" />
                                </ReferenceField>
                            } */}
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/BDP" &&
                                <TextField label="resources.redes.fields.nivelTraficoDetalles" source="nivelTrafico" fullWidth formClassName={classes.grid_cont4_f} />
                            }

                            {/* --------- ELEMENTOS DUCTOS Y REDES DE ENERGÍA ELECTRICA  ----------- */}
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/DCT" &&
                                <TextField label="resources.redes.fields.tipo" source="tipoRed" fullWidth formClassName={classes.grid_cont4_f} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/DCT" &&
                                <TextField label="resources.redes.fields.material" source="material" fullWidth formClassName={classes.grid_cont4} />
                            }
                            
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/DCT" && <br/>}

                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/DCT" &&
                                <NumberField label="resources.redes.fields.longitudM" source="longitud" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_f} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/DCT" &&
                                <NumberField label="resources.redes.fields.diametro" source="diametro" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/REE" &&
                                <TextField label="resources.redes.fields.tipo" source="tipoRed" fullWidth formClassName={classes.grid_cont4_f} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/REE" &&
                                <TextField label="resources.redes.fields.material" source="material" fullWidth formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/REE" && <br/>}

                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/REE" &&
                                <NumberField label="resources.redes.fields.longitudM" source="longitud" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_f} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/REE" &&
                                <NumberField label="resources.redes.fields.diametro" source="diametro" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s} />
                            }


                            {/* --------- ELEMENTOS RESTANTES  ----------- */}
                            {controllerProps.record && controllerProps.record.tipoElemento !== "/tipo_elementos/SEM" && controllerProps.record.tipoElemento !== "/tipo_elementos/LUM" && controllerProps.record.tipoElemento !== "/tipo_elementos/BDP" &&
                                controllerProps.record.tipoElemento !== "/tipo_elementos/DCT" && controllerProps.record.tipoElemento !== "/tipo_elementos/REE" &&
                                <TextField label="resources.redes.fields.material" source="entornoVia" fullWidth formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento !== "/tipo_elementos/SEM" && controllerProps.record.tipoElemento !== "/tipo_elementos/LUM" && controllerProps.record.tipoElemento !== "/tipo_elementos/BDP" &&
                                controllerProps.record.tipoElemento !== "/tipo_elementos/DCT" && controllerProps.record.tipoElemento !== "/tipo_elementos/REE" &&
                                <TextField label="resources.redes.fields.seccion" source="claseIluminacion" fullWidth formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento !== "/tipo_elementos/SEM" && controllerProps.record.tipoElemento !== "/tipo_elementos/LUM" && controllerProps.record.tipoElemento !== "/tipo_elementos/BDP" &&
                                controllerProps.record.tipoElemento !== "/tipo_elementos/DCT" && controllerProps.record.tipoElemento !== "/tipo_elementos/REE" &&
                                <br/>
                            }
                            {/* <br/> */}
                            {controllerProps.record && controllerProps.record.tipoElemento !== "/tipo_elementos/SEM" && controllerProps.record.tipoElemento !== "/tipo_elementos/LUM" && controllerProps.record.tipoElemento !== "/tipo_elementos/BDP" &&
                                controllerProps.record.tipoElemento !== "/tipo_elementos/DCT" && controllerProps.record.tipoElemento !== "/tipo_elementos/REE" &&
                                <NumberField label="resources.redes.fields.largoDiametro" source="largo" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento !== "/tipo_elementos/SEM" && controllerProps.record.tipoElemento !== "/tipo_elementos/LUM" && controllerProps.record.tipoElemento !== "/tipo_elementos/BDP" &&
                                controllerProps.record.tipoElemento !== "/tipo_elementos/DCT" && controllerProps.record.tipoElemento !== "/tipo_elementos/REE" &&
                                <NumberField label="resources.redes.fields.base" source="ancho" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento !== "/tipo_elementos/SEM" && controllerProps.record.tipoElemento !== "/tipo_elementos/LUM" && controllerProps.record.tipoElemento !== "/tipo_elementos/BDP" &&
                                controllerProps.record.tipoElemento !== "/tipo_elementos/DCT" && controllerProps.record.tipoElemento !== "/tipo_elementos/REE" &&
                                <NumberField label="resources.redes.fields.altura" source="altura" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento !== "/tipo_elementos/SEM" && controllerProps.record.tipoElemento !== "/tipo_elementos/LUM" && controllerProps.record.tipoElemento !== "/tipo_elementos/BDP" &&
                                <TextField label="resources.redes.fields.empresa" source="empresa_.empresa" reference="servicios_publicos" linkType={false} fullWidth formClassName={classes.grid_cont4} />
                            }


                        </FormTab>
                        <FormTab label="dynatest.resources.redes.tabs.tabsTitle.detalles">

                            <TextField label="resources.redes.fields.estado" fullWidth source="estado" formClassName={classes.grid_cont4_f} />
                            {controllerProps.record && controllerProps.record.estado != translate('dynatest.resources.redes.select.estado.Reemplazo') &&
                            <div formClassName={classes.grid_cont8}></div>
                            }
                            {controllerProps.record && controllerProps.record.estado === translate('dynatest.resources.redes.select.estado.Reemplazo') &&
                                <TextField label="resources.redes.fields.motivoReemplazo" source="motivoReemplazo" fullWidth formClassName={classes.grid_cont4_s} />
                            }
                            {controllerProps.record && controllerProps.record.estado === translate('dynatest.resources.redes.select.estado.Reemplazo') &&
                                <ReferenceField
                                label="resources.redes.fields.elementoReemplazo"
                                    source="elementoReemplazo_id"
                                    reference="elemento_reds"
                                    fliter={{"properties[]" : "fullCodigoConsecutivo",}}
                                    linkType={false}
                                    fullWidth
                                    formClassName={classes.grid_cont3}
                                >
                                    <TextField source="fullCodigoConsecutivo" />
                                </ReferenceField>
                            
                            }
                            <br/>
                            
                            <TextField label="resources.redes.fields.estados" source="estados" fullWidth formClassName={classes.grid_cont4_f}/>
                            <br/>
                            <DateField label="resources.redes.fields.fecha" source="fechaInstalacion" fullWidth formClassName={classes.grid_cont4_f} />
                            <ReferenceField filter={{"properties[]" : "razonSocial",}} label="resources.redes.fields.proveedor"  source="proveedor" reference="proveedors" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <TextField source="razonSocial" />
                            </ReferenceField>
                            <NumberField label="resources.redes.fields.garantiaMeses" source="garantia" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s} />
                            <BooleanField label="resources.redes.fields.mantenimiento" source="mantenimiento" fullWidth formClassName={classes.grid_cont4_f} />
                            <TextField label="resources.redes.fields.observaciones" source="observaciones" formClassName={classes.grid_cont12} />
                           
                        </FormTab>
                        <FormTab label="dynatest.resources.redes.tabs.tabsTitle.auditoria">
                            <DateField source="createdAt" label="resources.redes.fields.createdAt" fullWidth formClassName={classes.grid_cont4} showTime/>
                            <TextField source="createdBy" label="resources.redes.fields.createdBy" fullWidth formClassName={classes.grid_cont4} />
                            <TextField source="createdIn" label="resources.redes.fields.createdEn" fullWidth formClassName={classes.grid_cont3} />
                            <span></span>
                            <DateField source="updatedAt" label="resources.redes.fields.updatedAt" fullWidth formClassName={classes.grid_cont4} showTime/>
                            <TextField source="updatedBy" label="resources.redes.fields.updatedBy" fullWidth formClassName={classes.grid_cont4} />
                            <TextField source="updatedIn" label="resources.redes.fields.updatedEn" fullWidth formClassName={classes.grid_cont3} />
                            <span></span>
                        </FormTab>
                    </TabbedForm>
                </ShowView>
            }
        </ShowController>
    )
}));