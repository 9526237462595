import React, { useState, Fragment } from 'react';
import {REDUX_FORM_NAME} from 'react-admin';
import {change} from 'redux-form';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
    FormDataConsumer, AutocompleteInput, required, SelectInput
} from 'react-admin';
import { styles } from './../EstilosGrid';
import Grid from '@material-ui/core/Grid';


import config from './../config/config';
import { translate } from 'react-admin';
let urlAppjs = config.UrlServer;

/**
 * @param tipoElemento = la entidad a apuntar en el fetcher
 * @param abInicio = la abscisa inicial para el filtro
 * @param abFin = la abscisa final para el filtro
 * 
 */

class DepartamentosMunicipio extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            datos : {
                departamentos: [],
                municipios: [],
            }
        }
        
        this.fetcher.bind(this);

    }

    componentDidMount() {

        this.fetcher("departamentos", "order[nombre]=ASC");
        // this.fetcher("municipios", "order[nombre]=ASC")
        
    }
    componentDidUpdate(){
        
    }

    fetcher(target, otherFilters = null) {

        var urlPart = "/" + target + "?pagination=off";

        if(Array.isArray(otherFilters)) {
            otherFilters.map(filter => {
                urlPart = urlPart + "&" + filter;
            })
        } else if(otherFilters !== null) {
            urlPart = urlPart + "&" + otherFilters;
        }

        fetch(urlAppjs + urlPart,
            {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                return response.json()
            })
            .then((Elementos) => {
                var datos = [];
                Elementos["hydra:member"].map(item => {
                    datos.push({
                        id : item["@id"],
                        name : item.nombre
                    })
                })
                // console.log(datos);
                this.state.datos[target] = datos;
            }).then(()=> {
                this.forceUpdate();
            })
    }
    

    render() {
        const { translate } = this.props;
        const { classes } = this.props;
        const changedDepartament = (value) => {
            let lenghtObject = Object.keys(value);
            let valor = '';        
            for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; } 
            // console.log(valor);
            this.fetcher("municipios", [
                "order[nombre]=ASC",
                "departamento=" + valor
            ])
        }
        return(
            <FormDataConsumer >
                {({formData, dispatch, ...rest}) => (
                    <Grid container spacing={24}>
                        <Grid item xs={6}>
                            <SelectInput 
                                label="resources.segmentos.fields.departamento" 
                                source="departamento" 
                                options={{fullWidth : true}}
                                choices={this.state.datos.departamentos}
                                onChange={value => dispatch(
                                    change(REDUX_FORM_NAME, "municipio", null)
                                ), changedDepartament}
                                validate={required()}
                                perPage={34}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <SelectInput 
                                label="resources.segmentos.fields.municipio" 
                                source="municipio" 
                                options={{fullWidth : true}}
                                choices={this.state.datos.municipios}
                                validate={required()}
                                perPage={160}
                            />
                        </Grid>
                    </Grid>
                )}
                </FormDataConsumer>
        )
    }
}

DepartamentosMunicipio.propTypes = {
    classes: PropTypes.object.isRequired,
}
const DepartamentosMunicipios = withStyles(styles)(DepartamentosMunicipio);


export default translate(DepartamentosMunicipios);