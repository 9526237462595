import React, { useState } from 'react';
import {
  DateInput, required, TabbedForm, FormTab, AutocompleteInput, SelectInput,
  ReferenceInput, SimpleFormIterator, ArrayInput, TextInput, FormDataConsumer, SelectArrayInput
} from 'react-admin';
import { Edit } from './../Edit';
import { withStyles } from '@material-ui/core';
import { styles } from './../../EstilosGrid';
import Grid from '@material-ui/core/Grid';
import { minValue, maxValue, minLength, maxLength } from 'ra-core';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import AbsSegmento from '../AbsSegmento';
import { translate } from 'react-admin';
import PesoTotal from './preguntasRespuestas/complementoPesoTotal';
import {
  validarUnDecimal, validarDosDecimales, abValidationFin, validacionmaxfechaact, isFechaInvalida, Entero,
} from '../validacionesInputs';
import '../../App.css';
export const RespuestaEvaluacionComplementariosEdit = translate(withStyles(styles)(({ classes, ...props }) => {
  const { translate } = props;
  const { pesoTotal } = props;
  /* NEW EDER */
  const [sistema, setSistema] = useState(null);
  const ValidarSistema = (value) => {
    let lenghtObject = Object.keys(value);
    let valor = '';
    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setSistema(value);
  }

  const [elemento, setElemento] = useState('');

  const [tipo_elementos, setTipoElementos] = useState('');
  const [tipo_acceso, setTipoAcceso] = useState('');

  const ValidarTipoElementos = (value) => {
    let lenghtObject = Object.keys(value);
    let valor = '';
    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setTipoElementos(value);
  }

  const ValidarSegmento = (value) => {
    let lenghtObject = Object.keys(value);
    let valor = '';
    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setSegmento(value)
  }

  const [unidads, setUnidad] = useState('');
  const ValidarUnidad = (value) => {
    //let lenghtObject = Object.keys(value);
    //let valor = '';
    //for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setUnidad(value)
  }

  const [tramos, setTramo] = useState('');

  const ValidarTramo = (value) => {
    let lenghtObject = Object.keys(value);
    let valor = '';
    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setTramo(value)
  }
  const [segmentos, setSegmento] = useState('');

  /*OPCIONES PARA TIPO DE IMÁGENES*/
  const [opcionTipoImagen, setOpcionTipoImagen] = useState([{ id: 'Cargando...', name: 'Cargando...' }]);
  const OpcionTipoImagen = (value) => {
    if (value !== '/tipo_elementos/DESC') {
      setOpcionTipoImagen([
        { id: '/tipo_images/1', name: 'Panorámica' },
        { id: '/tipo_images/7', name: 'Elemento completo' },
        { id: '/tipo_images/8', name: 'Detalle 1' },
        { id: '/tipo_images/9', name: 'Detalle 2' },
        { id: '/tipo_images/5', name: 'Deterioro 1' },
        { id: '/tipo_images/6', name: 'Deterioro 2' },
      ])
    }
  }
  const Unidades = { unidadFuncional: unidads };
  const Tramoss = { tramo: tramos };

  const ValidarElemento = (value) => {
    setElemento(value)
    //debugger
  }
  /*VALIDAR IMÁGENES ACTUALES */
  const [imagenesOpen, setImagenesOpen] = useState('');
  const ImagenesEdit = (value) => {
    setImagenesOpen(value);

  }

  return (

    <Edit title="dynatest.resources.respuesta_evaluacion_complementarios.title.editTitle"  {...props} >

      <TabbedForm redirect="list" >
        {/* ......:::.... Filtro ......:::....*/}
        <FormTab label="dynatest.resources.respuesta_evaluacion_complementarios.tabs.filtro"  >
          <Grid container spacing={24} >
            <Grid item xs={4}>
              <ReferenceInput
                label="resources.respuesta_evaluacion_complementarios.fields.unidadFuncional"
                source="unidadFuncional"
                reference="unidad_funcionals"
                disabled
                filterToQuery={searchText => ({ codigo: searchText })}
                sort={{ field: 'codigo', order: 'asc' }}
                validate={[required(), ValidarUnidad]}
                fullWidth
              >
                <AutocompleteInput options={{ fullWidth: true, disabled: true }} optionText="fullCodigos" autoComplete="new-password" />
              </ReferenceInput>
            </Grid>
            <Grid item xs={4}>
              <ReferenceInput
                label="resources.respuesta_evaluacion_complementarios.fields.tramo"
                source="tramo"
                reference="tramos"
                filter={Unidades}
                filterToQuery={searchText => ({ codigo: searchText })}
                sort={{ field: 'codigo', order: 'ASC', }}
                validate={[required(), ValidarTramo]}
                formClassName={classes.grid_cont4_s}
              >
                <AutocompleteInput options={{ fullWidth: true, disabled: true }} optionText="fullCodigos" />
              </ReferenceInput>
            </Grid>
            <Grid item xs={4}>
              <ReferenceInput
                label="resources.respuesta_evaluacion_complementarios.fields.segmento"
                source="segmento"
                reference="segmentos"
                sort={{ field: 'codigo', order: 'ASC' }}
                filter={Tramoss}
                filterToQuery={searchText => ({ codigo: searchText })}
                validate={[required(), ValidarSegmento]}
                formClassName={classes.grid_cont4_s}
              >

                <AutocompleteInput options={{ fullWidth: true, disabled: true }} optionText="fullCodigos" />
              </ReferenceInput>
            </Grid>
            <Grid item xs={4}>
              <ReferenceInput label="resources.respuesta_evaluacion_complementarios.fields.sistema" source="sistema" reference="sistemas" filter={{ id: ["ALR", "CLZ", "DRE", "OTR", "PTS", "RDS", "SEG", "SEN"] }} fullWidth validate={[required(), ValidarTipoElementos, ValidarSistema]}>
                <SelectInput optionText="nombre" disabled />
              </ReferenceInput>
            </Grid>
            {(sistema != null) && (
              <Grid item xs={4}>
                {(sistema == '/sistemas/OTR') ?
                  <ReferenceInput label="resources.respuesta_evaluacion_complementarios.fields.tipoElemento" source="tipoElemento" reference="tipo_elementos" fullWidth filter={{ puntos: 1, sistema: "OTR" }} validate={[required()]} sort={{ field: 'nombre', order: 'asc' }}>
                    <SelectInput optionText="nombre" disabled />
                  </ReferenceInput>
                  :
                  (sistema == '/sistemas/SEN') ?
                    <ReferenceInput label="resources.respuesta_evaluacion_complementarios.fields.tipoElementoSenal" source="tipoElementoSenal" reference="tipo_elemento_senals" fullWidth validate={[required(), ValidarElemento]} sort={{ field: 'nombre', order: 'asc' }}>
                      <SelectInput optionText="nombre" disabled />
                    </ReferenceInput>
                    :
                    <ReferenceInput label="resources.respuesta_evaluacion_complementarios.fields.tipoElemento" source="tipoElemento" reference="tipo_elementos" fullWidth filter={{ sistema: tipo_elementos }} validate={[required()]} sort={{ field: 'nombre', order: 'asc' }}>
                      <SelectInput optionText="nombre" disabled />
                    </ReferenceInput>
                }
              </Grid>
            )}

          </Grid>
        </FormTab>
        {/* ......:::.... REGISTRO ......:::....*/}
        <FormTab label="dynatest.resources.respuesta_evaluacion_complementarios.tabs.registro">
          <DateInput
            label="resources.respuesta_evaluacion_complementarios.fields.fechaMedicion"
            source="fechaMedicion"
            fullWidth
            disabled
            formClassName={classes.grid_cont4_s}
            validate={[required(), validacionmaxfechaact]}
          />
          {/* <TextInput
            label="Acción"
            source="accion"
            validate={[maxLength(10), required()]}
            fullWidth
            formClassName={classes.grid_cont4_s}
          /> */}
          <SelectInput
            label="resources.respuesta_evaluacion_complementarios.fields.accion"
            source="accion"
            sort={{ field: 'name', order: 'ASC' }}
            validate={[required()]}
            choices={[
              { id: translate("dynatest.resources.respuesta_evaluacion_complementarios.select.accion.Nada"), name: "dynatest.resources.respuesta_evaluacion_complementarios.select.accion.Nada" },
              { id: translate("dynatest.resources.respuesta_evaluacion_complementarios.select.accion.Mantenimiento"), name: "dynatest.resources.respuesta_evaluacion_complementarios.select.accion.Mantenimiento" },
              { id: translate("dynatest.resources.respuesta_evaluacion_complementarios.select.accion.Reparar"), name: "dynatest.resources.respuesta_evaluacion_complementarios.select.accion.Reparar" },
              { id: translate("dynatest.resources.respuesta_evaluacion_complementarios.select.accion.Reemplazar"), name: "dynatest.resources.respuesta_evaluacion_complementarios.select.accion.Reemplazar" },
            ]}
            fullWidth
            formClassName={classes.grid_cont4_s}
            alwaysOn
          />
          <br></br>
           <SelectInput label="resources.respuesta_evaluacion_complementarios.fields.estado" source="estado" choices={[
               { id: translate('dynatest.resources.respuesta_evaluacion_complementarios.select.estado.Activo'), name: "dynatest.resources.respuesta_evaluacion_complementarios.select.estado.Activo" },
               { id: translate('dynatest.resources.respuesta_evaluacion_complementarios.select.estado.enProceso'), name: "dynatest.resources.respuesta_evaluacion_complementarios.select.estado.enProceso" },
               { id: translate('dynatest.resources.respuesta_evaluacion_complementarios.select.estado.Cerrado'), name: 'dynatest.resources.respuesta_evaluacion_complementarios.select.estado.Cerrado' },
              ]} fullWidth formClassName={classes.grid_cont4s} allowEmpty emptyValue="" />
          <TextInput label="resources.respuesta_evaluacion_complementarios.fields.aprobacion" source="aprobacion" disabled fullWidth formClassName={classes.grid_cont4_s} />
          <TextInput
            label="resources.respuesta_evaluacion_complementarios.fields.observaciones"
            source="observaciones"
            fullWidth
            formClassName={classes.grid_cont4_s}
          />
          <br></br>
          <span><b>{translate('dynatest.resources.respuesta_evaluacion_complementarios.subTitle.resultados')}</b></span>
          <PesoTotal pesoTotal={pesoTotal}/>
          <span></span>
          <br />
          <Typography variant="b" component="b"><b>{translate('dynatest.resources.respuesta_evaluacion_complementarios.subTitle.indicadores')}</b></Typography>
          <hr fullWidth style={{ marginBottom: '-12px', border:'1' }}></hr>
          <Divider fullWidth style={{ width: '300%', marginTop: '10px' }} />

          <ArrayInput fullWidth label=" " source="respuestasPreguntasComplementarios" >
            <SimpleFormIterator disableAdd disableRemove>

              <FormDataConsumer>
                {
                  ({ formData, scopedFormData, getSource, ...rest }) => (scopedFormData != null &&
                    scopedFormData.pregunta_.tipoCampo === translate("dynatest.resources.respuesta_evaluacion_complementarios.condiciones.numerico") &&
                    <React.Fragment>
                      <TextInput
                        label="resources.respuesta_evaluacion_complementarios.fields.indicadores"
                        source={getSource('pregunta_.indicadorComplementario_.nombre')}
                        disabled
                        validate={[required(), minLength(1), maxLength(60)]}
                        formClassName={classes.grid_cont4}
                        style={{ marginLeft: '10px', width: '30%', marginBottom: '20px' }}
                      />
                      <TextInput
                        label="resources.respuesta_evaluacion_complementarios.fields.pregunta"
                        source={getSource('pregunta_.pregunta')}
                        id="pregunta" 
                        disabled
                        validate={[required(), minLength(1), maxLength(60)]}
                        formClassName={classes.grid_cont4}
                        style={{ marginLeft: '10px', width: '30%', marginBottom: '20px' }}
                      />
                      <TextInput
                        label="resources.respuesta_evaluacion_complementarios.fields.respuesta"
                        source={getSource('respuesta')}
                        record={scopedFormData}
                        validate={[minLength(1), maxLength(60)]}
                        required
                        formClassName={classes.grid_cont4}
                        style={{ marginLeft: '20px', width: '30%', marginBottom: '20px' }}
                      />
                    </React.Fragment>
                  )
                }
              </FormDataConsumer>

              <FormDataConsumer>
                {
                  ({ formData, scopedFormData, getSource, ...rest }) => {
                    let arrayRespuestasNew = [];
                    let respuestaDefault = scopedFormData.respuesta;
                    if (scopedFormData != null &&
                      scopedFormData.pregunta_.tipoCampo === translate("dynatest.resources.respuesta_evaluacion_complementarios.condiciones.seleccionMultiple")) {
                      scopedFormData.pregunta_.respuestaIndicadorComplementario.forEach(element => {
                        arrayRespuestasNew.push({ id: element.originId + "", respuesta: element.respuesta })
                        if (element.originId == respuestaDefault) {
                          respuestaDefault = element.respuesta;
                        }
                      })

                      return (
                        <React.Fragment>
                          <TextInput
                            label="resources.respuesta_evaluacion_complementarios.fields.indicadores"
                            source={getSource('pregunta_.indicadorComplementario_.nombre')}
                            disabled
                            validate={[required(), minLength(1), maxLength(60)]}
                            formClassName={classes.grid_cont4}
                            style={{ marginLeft: '10px', width: '30%', marginBottom: '20px' }}
                          />
                          <TextInput
                            label="resources.respuesta_evaluacion_complementarios.fields.pregunta"
                            source={getSource('pregunta_.pregunta')}
                            id="pregunta"
                            disabled
                            validate={[required(), minLength(1), maxLength(60)] /* ValidarPregunta */}
                            formClassName={classes.grid_cont6}
                            style={{ marginLeft: '10px', width: '30%', marginBottom: '20px' }}
                          />
                          <SelectInput
                            label="resources.respuesta_evaluacion_complementarios.fields.respuesta"
                            source={getSource('respuesta')}
                            choices={arrayRespuestasNew}
                            optionText="respuesta"
                            optionValue="id"
                            required
                            style={{ marginLeft: '20px', width: '30%', marginBottom: '20px' }}
                          />
                        </React.Fragment>
                      )
                    }
                  }
                }
              </FormDataConsumer>
              <hr fullWidth style={{ marginBottom: '-2px', border:'2' }}></hr>
            </SimpleFormIterator>
          </ArrayInput>
          
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}));
const WithProps = ({ children, ...props }) => children(props);