import React, {Component} from 'react';
import { Loading  } from 'react-admin';
import * as QuickSightEmbedding from 'amazon-quicksight-embedding-sdk';
import * as AWSSDK from 'aws-sdk';
import * as Quicksight from 'aws-sdk/clients/quicksight';
import config from '../../config/config';
var apigClientFactory = require('aws-api-gateway-client').default;
let LanguajeQuickSight = config.LanguajeQuickSight;
let urlAppjs = config.UrlServer;

export class ReportesTransitoQuicksight extends Component {
        _isMounted = false;
        constructor(props) {
            super(props);
            this.state = { 
                isOnline: null
            };
            this.errores = "";
            this.embedUrl = "";
            this.dashboardRef = React.createRef();
          }
    
        async componentDidMount(){
            let AWS = AWSSDK;
            let EmbedUrl = "";
            let Errores = "";
            let apigClient = "";
            var pathParams = {};
            var pathTemplate = '/embbendingurl'
            var method = 'GET';
            var body = {};
            let defaultDashboard = "";
            await fetch(urlAppjs + '/reporte_quicksights?environment=Production', {
                method: 'GET',
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then(res => res.json())
            .then((success) => {
                if(success.hasOwnProperty("hydra:member")){
                    if(success["hydra:member"].length >= 1){
                        let resultado = success["hydra:member"];
                        resultado.map((item) => {
                            if (item.nameReport == "ReportesTránsito") {
                                defaultDashboard = item.uuidReport
                            }
                        })
                    }
                }
            })
            var additionalParams = {
                headers: {},
                queryParams: {
                    "accountId": '305658496569', 
                    "dashboardId": defaultDashboard, 
                    "userArn": "arn:aws:quicksight:us-east-1:305658496569:user/default/RolQuickSight/rafael.chaparroTest", 
                    "resetDisabled" : "false", 
                    "undoRedoDisabled" : "false"
                }
            };
    
            AWS.config.region = 'us-east-1'; // Región
            AWS.config.credentials = await new AWS.CognitoIdentityCredentials({
                IdentityPoolId: 'us-east-1:f446234b-5221-4128-bdcf-972fb559ca3f',
            });
    
            let awsCredentials = {
                region: "us-east-1",
                accessKeyId: "AKIAUOKVKQY4YPMWTBP7",
                secretAccessKey: "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"
            };
            AWS.config.update(awsCredentials);
    
            let sts = new AWS.STS();
                
            await sts.assumeRole({
                RoleArn: "arn:aws:iam::305658496569:role/RolQuickSightCognito",
                RoleSessionName: "rafael.chaparroTest"
                }, function(err, data) {
                if (err) {
                    Errores = "No se tiene respuesta al assumir el usuario";
                } else {
                    AWS.config.update({
                        accessKeyId: data.Credentials.AccessKeyId,
                        secretAccessKey: data.Credentials.SecretAccessKey,
                        sessionToken: data.Credentials.SessionToken
                    });
    
                    apigClient = apigClientFactory.newClient({
                        invokeUrl:'https://50cyapve5a.execute-api.us-east-1.amazonaws.com/Dev',
                        region: 'us-east-1',
                        accessKey: data.Credentials.AccessKeyId,
                        secretKey: data.Credentials.SecretAccessKey,
                        sessionToken: data.Credentials.SessionToken                                                          //           function. Uses axios-retry plugin. Default is no delay.
                    });
                    
                }
            });
            apigClient = apigClientFactory.newClient({
                invokeUrl:'https://50cyapve5a.execute-api.us-east-1.amazonaws.com/Dev',
                region: 'us-east-1'
            });
            await apigClient.invokeApi(pathParams, pathTemplate, method, additionalParams, body )
                .then(function(result){
                    EmbedUrl = result.data;
                }).catch( function(result){
                    Errores = "No se tiene respuesta al obtener la URL del Dashboard";
                });
                
            if (EmbedUrl != "") {
                this.embedUrl = EmbedUrl;
            }
            if (Errores != "") {
                this.errores = Errores;
            }
    
            const embed = () => {
                var element = document.getElementById("LoadingPage");
                element.parentNode.removeChild(element);
    
                const options = {
                    url: this.embedUrl,
                    container: this.dashboardRef.current,
                    parameters: {
                        country: "United States",
                        states: [
                            "N. Virginia"
                        ]
                    },
                    scrolling: "no",
                    height: "700px",
                    width: "100%",
                    locale: LanguajeQuickSight,
                    footerPaddingEnabled: true,
                    defaultEmbeddingVisualType: 'TABLE'
                };
                QuickSightEmbedding.embedDashboard(options)
            };
            if (this.embedUrl != "") {
                embed();
            }
        }
    
        render(){
            return (
                <div>
                    {this.embedUrl == "" 
                        ? (this.errores != "" 
                            ? <div class="content-msj-error-list"> <h3> {this.errores} </h3></div> 
                            : <div ref={this.dashboardRef}> <div id="LoadingPage"><Loading /></div> </div>
                        ) : <div ref={this.dashboardRef}></div> 
                    }
                </div>
            );
        }
}

export default ReportesTransitoQuicksight;