import React, { Component, useState } from "react";
import { BooleanInput } from 'react-admin';
import ItemsCarousel from 'react-items-carousel';
import styled from "styled-components";
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import BugReportIcon from '@material-ui/icons/BugReport';
import CancelIcon from '@material-ui/icons/Cancel';
import BackupIcon from '@material-ui/icons/Backup';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import FileDownloadIcon from '@material-ui/icons/FileDownload';
import config from '../../config/config';
import { translate } from 'react-admin';
let urlAppjs = config.UrlServer;
let urlAppjsFront = config.UrlFront;


const Slider = ({ permissions, ...props }) => {
  const { translate } = props;
  const { basePath } = props;
  console.log(props, "propiedades")
  //console.log('Propiedades de Sidebar', props.dataCard);
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const chevronWidth = 40;
  const heightField = 230;

  const [checkedActive, setCheckedActive] = React.useState(false);

  /* const handleChangeActive = (value) => {
    let idNew = document.getElementById('idCheckbox')
    console.log("estado", idNew)
    //setCheckedActive(value);
  }; */

  const idNew = document.getElementById('idCheckbox');

  if (idNew) {
    idNew.addEventListener('change', (event) => {
      console.log(event, idNew, "evento de list")
    });
  }

  const ElimianrIdioma = (value) => {
    console.log(value, "id")
    const dataSend = new FormData();
    dataSend.append('id', value);
    let requestOptionsReplace = {
      method: 'DELETE',
      headers: { 'authorization': 'Bearer ' + localStorage.getItem('token') }
    };
    fetch(urlAppjs + "/idiomas/" + value, requestOptionsReplace)
      .then(response => response)
      .then((dataRequestInventario) => {
        window.location.reload();
      });
  }

  return (
    // <div style={{ padding: `0 ${chevronWidth}px`, width:'60em' }}>
    // <Wrapper>
    <ItemsCarousel
      requestToChangeActive={setActiveItemIndex}
      activeItemIndex={activeItemIndex}
      numberOfCards={3}
      gutter={40}
      leftChevron={<Button style={{ height: heightField }}><ChevronLeftIcon /></Button>}
      rightChevron={<Button style={{ height: heightField }}><ChevronRightIcon /></Button>}
      outsideChevron={false}
      showSlither={true}
      firstAndLastGutter={true}
      chevronWidth={chevronWidth}
      style={{ width: '100%' }}
    >
      {
        props.dataCard.map((dataCard, i) => (
          <Card style={{ height: 'auto', marginBottom: '1rem', paddingBottom: '1.5rem' }}>
            <CardContent style={{ background: '#EEE' }}>
              <a href={urlAppjsFront + "idiomas/%2Fidiomas%2F" + dataCard.id.replace("/idiomas/", "")} style={{ display: 'flex', textDecoration: 'none', color: 'rgba(0, 0, 0, 0.54)' }}>
                <strong>{dataCard.nombreIdioma}</strong>
              </a>
            </CardContent>
            <CardActions>
              <Grid item xs={12} className="negrilla" align={'left'} >
                {/* <Button
                  onClick={() => {
                    ElimianrIdioma(dataCard.id)
                  }}
                  variant="outlined"
                  style={{
                    fontSize: '0.875rem', color: 'rgba(0, 0, 0, 0.54)', border: 0,
                    display: 'inline-flex', alignItems: 'center', width: '16.7rem', justifyContent: 'left',
                    padding: 0, textTransform: 'capitalize', fontWeight: 400,
                    fontFamily: '"Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif'
                  }}
                >
                  <CancelIcon style={{ width: '1rem', paddingRight: '0.4rem' }} />
                  {translate('resources.idiomas.fields.eliminar')}
                </Button> */}
                {/* <a href="#" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'rgba(0, 0, 0, 0.54)' }} >
                  <BackupIcon style={{ width: '1rem', paddingRight: '0.4rem', textDecoration: 'none', color: 'rgba(0, 0, 0, 0.54)' }} />
                  {translate('dynatest.resources.idiomas_config.cards.cargarInfoIdioma')}
                </a> */}
                <a href={urlAppjs + "/Manuales/" + dataCard.manual} target='_blank' style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'rgba(0, 0, 0, 0.54)', marginTop: '0.5rem', }} >
                  <CloudDownloadIcon style={{ width: '1rem', paddingRight: '0.4rem', textDecoration: 'none', color: 'rgba(0, 0, 0, 0.54)' }} />
                  {translate('dynatest.resources.idiomas_config.cards.descManualUsuario')}
                </a>
                {/* <a href="#" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'rgba(0, 0, 0, 0.54)', marginTop: '0.5rem', }} >
                  <FileDownloadIcon style={{ width: '1rem', paddingRight: '0.4rem', textDecoration: 'none', color: 'rgba(0, 0, 0, 0.54)' }} />
                  {translate('dynatest.resources.idiomas_config.cards.descargar')}
                </a> */}
                <label style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'rgba(0, 0, 0, 0.54)', marginTop: '0.5rem', }}>
                  <input
                    type="checkbox"
                    //value="true"
                    defaultChecked={dataCard.estado == "Activo" ? true : false}
                    //onclick={handleChangeActive(dataCard.id)}
                    //checked={handleChangeActive(dataCard.id)}
                    id="idCheckbox"
                    disabled
                    style={{
                      width: '1rem', paddingRight: '0.4rem', textDecoration: 'none',
                      color: 'rgba(0, 0, 0, 0.54)', margin: '0 0.3rem 0 0',
                    }}
                  />
                  {translate('dynatest.resources.idiomas_config.cards.activo')}
                </label>
              </Grid>
            </CardActions>
          </Card>
        ))
      }
    </ItemsCarousel>
    // </Wrapper>
  );
};


export default translate(Slider);