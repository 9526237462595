import React from 'react';
import { SimpleForm, NumberInput, SelectInput, DisabledInput, TextInput, ReferenceInput, DateInput, 
    ArrayInput, SimpleFormIterator, AutocompleteInput
} from 'react-admin';

import { withStyles } from '@material-ui/core';
import { Edit } from './../../Edit';

import {
    required,
    minValue,
    maxValue
} from 'react-admin';

const styles = {
    first_field: { display: 'inline-block', width: '30%' },
    last_filed: { display: 'inline-block', marginLeft: 32, width: '30%' },
    separador: { marginTop: 40, marginLeft: 100, marginRight: 100, marginBottom: 0 },
    subtittle: { marginTop: "15px" },
};

export const ElementoDeterioroEdit = withStyles(styles)(({ classes, ...props }) => {

    const dias = ["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado"];
    const mes = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

    //OBTENER FECHA ACTUAL (Calculo Fecha Próxima)
    const FechaActual = (value) => {

        // if(value){                                  
        //     let e = new Date(value.replace(/-/g, '\/'))    
        //     e.setMonth(e.getMonth());            
        //     setFecha(dias[e.getDay()]+" "+(e.getDate())+" "+mes[e.getMonth()]+" del "+e.getFullYear());
        //     setFechaAux(e.getFullYear()+"-"+(e.getMonth()+1)+"-"+(e.getDate()));
        // }        

    }

    //OBTENER PERIOCIDAD (Calculo Fecha Próxima)
    const PeriodicidadMes = (value) => {

        // if(Fecha !== "dd / mm / aaaa"){
        //     let d = new Date(FechaAux.replace(/-/g, '\/'));            
        //     d.setMonth(d.getMonth() + parseInt(value));           
        //     setFecha(dias[d.getDay()]+" "+(d.getDate())+" "+mes[d.getMonth()]+" del "+d.getFullYear());                        
        // }

    };

    //OBTENER VALOR SEVERIDAD BAJA
    //---------------------------------->
    const Severidad_Baja = (value) => {


    }

    //OBTENER VALOR SEVERIDAD MEDIA
    //---------------------------------->
    const Severidad_Media = (value) => {


    }

    //OBTENER VALOR SEVERIDAD ALTA
    //---------------------------------->
    const Severidad_Alta = (value) => {


    }


    return (
        <Edit title="Editar Deterioro" {...props} undoable={false}>
            <SimpleForm submitOnEnter={false} redirect="list" >
                <ReferenceInput
                    label="segmento"
                    source="segmento"
                    reference="segmentos"
                    filterToQuery={searchText => ({ codigo: searchText })}
                    sort={{
                        field: 'codigo',
                        order: 'ASC',

                    }}>
                    <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
                </ReferenceInput>
                <div></div>
                <NumberInput source="deterioros_pci" label="PCI" validate={[required(), minValue(0), maxValue(100)]} />
                <div></div>
                <DateInput source="deterioros_fecha_ultima" label="Fecha de última evaluación" formClassName={classes.first_field} validate={[required(), FechaActual]} />
                <SelectInput source="deterioros_periocidad" label="Periodicidad de medición (mes)" choices={[
                    { id: '1', name: '1' },
                    { id: '2', name: '2' },
                    { id: '3', name: '3' },
                    { id: '4', name: '4' },
                    { id: '5', name: '5' },
                ]} formClassName={classes.last_filed} validate={[required(), PeriodicidadMes]} />
                <DisabledInput label="Fecha próxima medición" formClassName={classes.last_filed} />
                <NumberInput source="deterioros_ancho_long" label="Ancho longitudinales(m)" />
                <div></div>
                <div className={classes.subtittle}><strong>Deterioro</strong></div>
                <hr fullWidth />
                <ArrayInput source="informacionDeterioros" label=" " fullWidth>
                    <SimpleFormIterator>
                        <SelectInput source="deterioros_list" label="Deterioro" choices={[
                            { id: 'Fisura de Piel de Cocodrilo o Falla por Fatiga', name: 'Fisura de Piel de Cocodrilo o Falla por Fatiga' },
                            { id: 'Sangrado o Exudación', name: 'Sangrado o Exudación' },
                            { id: 'Agrietamiento en bloque', name: 'Agrietamiento en bloque' },
                            { id: 'Abultamientos y hundimientos', name: 'Abultamientos y hundimientos' },
                            { id: 'Corrugación ', name: 'Corrugación ' },
                            { id: 'Depresión', name: 'Depresión' },
                            { id: 'Fisura de borde', name: 'Fisura de borde' },
                            { id: 'Fisura de reflexion de junta', name: 'Fisura de reflexion de junta' },
                            { id: 'Desnivel carril/ berma', name: 'Desnivel carril/ berma' },
                            { id: 'Fisura longitudinal y transversal', name: 'Fisura longitudinal y transversal' },
                            { id: 'Parche', name: 'Parche' },
                            { id: 'Pulimiento de agregados', name: 'Pulimiento de agregados' },
                            { id: 'Bache', name: 'Bache' },
                            { id: 'Cruce de vía ferrea', name: 'Cruce de vía ferrea' },
                            { id: 'Ahuellamiento ', name: 'Ahuellamiento ' },
                            { id: 'Desplazamiento ', name: 'Desplazamiento ' },
                            { id: 'Fisura parabolica', name: 'Fisura parabolica' },
                            { id: 'Hinchamiento ', name: 'Hinchamiento ' },
                            { id: 'Desprendimiento de agregados', name: 'Desprendimiento de agregados' },
                            { id: 'Envejecimiento', name: 'Envejecimiento' },
                        ]} formClassName={classes.first_field} />
                        <SelectInput source="deterioros_unidad" label="Unidad" choices={[
                            { id: 'und', name: 'und' },
                            { id: 'm', name: 'm' },
                            { id: 'm²', name: 'm²' },
                        ]} formClassName={classes.last_filed} />
                        <div></div>
                        <NumberInput source="deterioros_sbaja_la" label="Severidad baja  (Longitud o Área)" formClassName={classes.first_field} validate={[minValue(0)]} />
                        <DisabledInput label="Severidad baja (%)" formClassName={classes.last_filed} />
                        <div></div>
                        <NumberInput source="deterioros_smedia_la" label="Severidad media  (Longitud o Área)" formClassName={classes.first_field} validate={[minValue(0)]} />
                        <DisabledInput label="Severidad Media(%)" formClassName={classes.last_filed} />
                        <div></div>
                        <NumberInput source="deterioros_salta_la" label="Severidad Alta (Longitud o Área)" formClassName={classes.first_field} validate={[minValue(0)]} />
                        <DisabledInput label="Severidad Alta(%)" formClassName={classes.last_filed} />
                        <div></div>
                        <DisabledInput label="Suma % afectado" id="totalSumaAfectado" defaultValue={0} />
                    </SimpleFormIterator>
                </ArrayInput>
                <TextInput source="deterioros_observaciones" label="Observaciones" />
            </SimpleForm>
        </Edit>
    )
});

