export default {
  ra: {
    action: {
      add_filter: 'Adicionar Filtro',
      add: 'Adicionar',
      back: 'Voltar',
      bulk_actions: '1 elemento selecionado |||| %{smart_count} elementos selecionados',
      cancel: 'Cancelar',
      clear_input_value: 'Limpar campo',
      clone: 'Duplicar',
      confirm: 'Confirmar',
      create: 'Criar',
      delete: 'Remover',
      edit: 'Editar',
      export: 'Exportar',
      list: 'Listar',
      refresh: 'Atualizar',
      remove_filter: 'Cancelar filtro',
      remove: 'Remover',
      save: 'Salvar',
      search: 'Procurar',
      show: 'Exibir',
      sort: 'Ordenar',
      undo: 'Desfazer',
      expand: 'Expandir',
      close: 'Fechar',
      image: 'Imagem',
      carouselImage: 'GALERIA',
      imageActuales: 'Imagens atuais',
      imageEliminar: 'REMOVER',
      save_add: 'SALVAR E ADICIONAR',
      save_end: 'SALVAR E FINALIZAR',
    },
    boolean: {
      true: 'Sim',
      false: 'Não',
    },
    page: {
      create: 'Criar %{name}',
      dashboard: 'Painel de Controle',
      edit: '%{name} #%{id}',
      error: 'Um erro ocorreu',
      list: 'Listar %{name}',
      loading: 'Carregando',
      not_found: 'Não encontrado',
      show: '%{name} #%{id}',
    },
    input: {
      file: {
        upload_several:
          'Arraste alguns arquivos para fazer o upload, ou clique para selecioná-los.',
        upload_single: 'Arraste o arquivo para fazer o upload, ou clique para selecioná-lo.',
      },
      image: {
        upload_several: 'Arraste algumas imagens para fazer o upload ou clique para selecioná-las',
        upload_single: 'Arraste um arquivo para upload ou clique em selecionar arquivo.',
      },
      references: {
        all_missing: 'Não foi possível encontrar os dados das referencias.',
        many_missing: 'Pelo menos uma das referências passadas não está mais disponível.',
        single_missing: 'A referência passada aparenta não estar mais disponível.',
      },
    },
    message: {
      about: 'Sobre',
      are_you_sure: 'Tem certeza?',
      bulk_delete_content: 'Você tem certeza que deseja remover %{name}? |||| Você tem certeza que deseja remover estes %{smart_count} itens?',
      bulk_delete_title: 'Remover %{name} |||| Remover %{smart_count} %{name} itens',
      delete_content: 'Você tem certeza que deseja Remover?',
      delete_title: 'Remover %{name} #%{id}',
      details: 'Detalhes',
      error: 'Um erro ocorreu e a sua requisição não pôde ser completada.',
      invalid_form: 'Este formulário não está valido. Certifique-se de corrigir os erros',
      loading: 'A página está carregando. Um momento, por favor',
      no: 'Não',
      not_found: 'Foi digitada uma URL inválida, ou o link pode estar quebrado.',
      yes: 'Sim',
    },
    navigation: {
      no_results: 'Nenhum resultado encontrado',
      no_more_results: 'A página numero %{page} está fora dos limites. Tente a página anterior.',
      page_out_of_boundaries: 'Página %{page} fora o limite',
      page_out_from_end: 'Não é possível ir após a última página',
      page_out_from_begin: 'Não é possível ir antes da primeira página',
      page_range_info: '%{offsetBegin}-%{offsetEnd} de %{total}',
      page_rows_per_page: 'Resultados por página:',
      next: 'Próximo',
      prev: 'Anterior',
    },
    auth: {
      auth_check_error: 'Por favor, faça login para continuar',
      user_menu: 'Perfil',
      username: 'Usuário',
      password: 'Senha',
      sign_in: 'Entrar',
      sign_in_error: 'Erro na autenticação, tente novamente.',
      logout: 'Sair',
    },
    notification: {
      updated: 'Elemento atualizado com sucesso |||| %{smart_count} elementos foram atualizados com sucesso',
      created: 'Elemento criado com sucesso',
      deleted: 'Elemento removido com sucesso! |||| %{smart_count} elementos foram removidos com sucesso',
      bad_item: 'Elemento incorreto',
      item_doesnt_exist: 'Esse elemento não existe mais',
      http_error: 'Erro na comunicação com servidor',
      data_provider_error: 'Erro interno do servidor. Entre em contato',
      i18n_error: 'Não foi possível carregar as traduções para o idioma especificado',
      canceled: 'Ação cancelada',
      logged_out: 'Sua sessão foi encerrada. Por favor, reconecte'
    },
    validation: {
      required: 'Obrigatório',
      minLength: 'Deve conter pelo menos %{min} caracteres',
      maxLength: 'Deve conter no máximo %{max} caracteres',
      minValue: 'O valor inserido deve ser igual ou maior que %{min}',
      maxValue: 'O valor inserido deve ser menor ou igual a %{max}',
      number: 'O caractere deve ser numérico',
      email: 'O e-mail inserido não é válido',
      oneOf: 'Deve ser uma das seguintes opções: %{options}',
      regex: 'Deve ter o formato específico (regexp): %{pattern}',
    },
  },
  resources: {
    inicio:{
      titleProyecto:'Seleção do projeto',
      mensaje:'A página está carregando, aguarde um momento por favor.',
      seleccionar:'Selecionar',
    },
    usuarios: {
      name: 'Usuário |||| Usuários',
      fields: {
        nombres: 'Nomes',
        apellidos: 'Sobrenomes',
        tipo_documento: 'Tipo de documento',
        identificacion: 'Número de identificação',
        admin: 'Papel',
        password: 'Senha',
        plainPassword: 'Confirmar a senha',
        idioma:'Linguagem',
        createdAt: 'Data de criação',
        createdBy: 'Criado por',
        updatedAt: 'Data de atualização',
        updatedBy: 'Atualizado por',
        sections: {
          tipo_rol: 'Tipo de papel',
          usuarios: 'Usuários',
          perfiles: 'Perfis de usuário',
          asignar_clave: 'Atribuir Senha',
          proyectos: 'Projetos',
          rutas: 'Rodovias',
          unidades_funcionales: 'Subtrecho',
          tramos: 'Seções',
          segmentos: 'Segmentos',
          materiales: 'Materiais',
          granulometria: 'Granulometria ',
          parametros_evaluacion: 'Parâmetros de avaliação',
          diagrama_colorimetrico: 'Diagrama cromático',
          reparaciones: 'Reparações ',
          deterioros: 'Defeitos',
          contacto_emergencia: 'Contato de Emergência',
          proveedores: 'Fornecedores',
          servicios_publicos: 'Serviços públicos',
          mapa: 'Mapa',
          flexible: 'Flexível',
          rigido: 'Rígido ',
          adoquin: 'Bloco Intertravado',
          otro: 'Outro',
          sistemas_contencion: 'Dispositivos de contenção viária',
          drenaje: 'Drenagem',
          señalizacion: 'Sinalização',
          alrededores: 'Faixa de dominio',
          otros_elementos: 'Outros Elementos',
          redes: 'Redes ',
          puentes: 'Pontes',
          contencion: 'Terraplenos',
          ponton: 'Pontilhão',
          tuneles: 'Tuneis',
          inventario_peajes: 'Inventário de Pedagios',
          transito_historico: 'Tráfego Histórico',
          inv_est_conteo: 'Inventário de Estações de contagem',
          indicadores_4g: 'Índices de desempenho ',
          datos_basicos: 'Dados básicos ',
          deterioro: 'Defeito ',
          calificacion_puentes: 'Avaliação de pontes ',
          calificacion_pontones: 'Avaliação de pontilhoes ',
          disp_vias: 'Disponibilidad de Vías ',
          cola_peaje: 'Cola de peaje ',
          parametros_planeacion: 'Parâmetros de planejamento ',
          politica_general: 'Políticas gerais ',
          politica_particular: 'Políticas especificicas ',
          alternativa_intervencion: 'Alternativas de intervenção',
          repa_menores: 'Reparações menores',
          exportar_pers: 'Exportar base PERS ',
          reportes_pavimentos: 'Importar base PERS',
          flujo_caja: 'Fluxo de caixa',
          idioma: 'Língua',
          sistema: 'Sistema',
          indicadorComplementario: 'Índice complementar',
          novedades: 'Ocorrência',
          cargaMasiva: 'Carregamento em massa',
          complementarios: 'Complementares',
          costo: 'Registro de Custos',
          respuestaEvaluacionNovedad: 'Ocorrência',
          aprobacion: 'Aprovação'
        }
      }
    },
    atributos: {
      name: 'Facultade do usuario |||| Facultades do usuario ',
      fields: {
        nombre: 'Nome do papel',
        tipoRol: 'Tipo de papel',
        usuario: 'Usuários',
        perfiles: 'Perfis de usuário',
        asigclaves: 'Atribuir senha ',
        proyecto: 'Projetos',
        rutas: 'Rodovias',
        unifuncionales: 'Subtrecho',
        tramos: 'Seções',
        segmento: 'Segmentos',
        materiales: 'Materiais',
        ganulometria: 'Granulometria',
        parametrosEvaluacion: 'Parâmetros de avaliação',
        diagramaColorimetrico: 'Diagrama cromático',
        reparaciones: 'Reparações ',
        deterioros: 'Defeitos ',
        contemergencia: 'Contatos de Emergência',
        proveedores: 'Fornecedores ',
        serpublicos: 'Serviços públicos',
        mapa: 'Mapa ',
        flexible: 'Flexível',
        rigido: 'Rígido ',
        adoquin: 'Bloco Intertravado ',
        otro: 'Outro ',
        seguridad: 'Elementos de Segurança Rodoviária',
        drenaje: 'Drenagem',
        senalizacion: 'Sinalização',
        alrededores: 'Faixa de dominio',
        otroElemento: 'Outros Elementos',
        redes: 'Redes ',
        puentes: 'Pontes',
        contencion: 'Terraplenos',
        ponton: 'Pontilhão',
        tunel: 'Tuneis',
        inventario: 'Inventário de Pedagios',
        transitoMensual: 'Tráfego histórico',
        inventarioa: 'Inventário de Estações de contagem',
        indicadores4g: 'Índices de desempenho',
        datosBasicos: 'Dados básicos',
        colaDePeaje: 'Cola de peaje',
        calificacionPuentes: 'Avaliação pontes',
        calificacionPontones: 'Avaliação pontilhões',
        disponibilidadVia: 'Disponibilidad de Vías ',
        colaDePeajea: 'Cola de Peaje ',
        parametrosAnalisis: 'Parâmetros de planejamento',
        politicaGeneral: 'Políticas gerais',
        politicaParticular: 'Políticas específicas',
        alternativaIntervencion: 'Alternativas de intervenção',
        reparacionesMenores: 'Reparações menores',
        exportarPers: 'Exportar base PERS',
        reportesPers: 'Importar base PERS',
        flujoCaja: 'Fluxo de caixa',
        reportesConfig: 'Configurações Relatórios',
        reportesPersQ: 'Relatórios PERS',
        reportesInventarioQ: 'Relatórios Inventário',
        reportesIndicadoresQ: 'Relatórios Índices de desempenho',
        reportesCostosQ: 'Relatórios Custos',
        reportesTransitoQ: 'Relatórios Tráfego',
        reportesContactosQ: 'Outros Relatórios',
        reportesModelacionQ: 'Relatórios Modelagem',
        createdAt: 'Data de criação',
        createdBy: 'Criado por',
        updatedAt: 'Data de atualização',
        updatedBy: 'Atualizado por',
        novedades: 'Ocorrência',
        configSistema: 'Sistema',
        audit: 'Auditoria',
        costo: 'Registro de Custos',
      }
    },
    proyectos: {
      name: 'Projeto |||| Projetos',
      fields: {
        codigo: 'Código do projeto',
        nombre: 'Nome do projeto',
        licencia: 'Licença',
        concesionario: 'Concessionária',
        latitud: 'Latitude',
        longitud: 'Longitude',
        alturaIni: 'Elevação mínima (m.a.n.m)',
        alturaFin: 'Elevação máxima (m.a.n.m',
        ubicaciones: 'Adicionar localização',
        proyecto: 'Projeto',
        createdAt: 'Data de criação',
        createdBy: 'Criado por',
        updatedAt: 'Data de atualização',
        updatedBy: 'Atualizado por',
        uniFuncionalesProyec: 'Seções do projeto',
        archivoKml: 'Arquivo .kml',
        ArchCargadoUniFuncional: 'Arquivo(s) carregado(s) com sucesso',
        descargarkml:'Baixar arquivo kml',
        noSubido:'Arquivo KML bruto',
        cargarArchivo: 'Carregar Arquivo',
      }

    },
    rutas: {
      name: 'Rodovia |||| Rodovias',
      fields: {
        proyecto: 'Projeto',
        codigo: 'Código ',
        categoriaRuta: 'Classe',
        pInicio: 'km inicial',
        pFin: 'km final',
        tramo: 'Seção',
        sector: 'Setor',
        origen: 'Origem',
        destino: 'Destino',
        createdAt: 'Data de criação',
        createdBy: 'Criado por',
        updatedAt: 'Data de atualizaçaõ',
        updatedBy: 'Atualizado por',
      }
    },
    unidad_funcionals: {
      name: 'Subtrecho |||| Subtrechos',
      fields: {
        ruta: 'Rodovia',
        codigo: 'Nome do subtrecho',
        abInicio: 'Estaca inicial',
        abFin: 'Estaca final',
        pInicio: 'km inicial',
        pFin: 'km final',
        fullResta: 'Estensão (km)',
        createdAt: 'Data de criação',
        createdBy: 'Criado por',
        updatedAt: 'Data de atualizaçaõ',
        updatedBy: 'Atualizado por',
      }
    },
    tramos: {
      name: 'Seção |||| Seções',
      fields: {
        unidadFuncional: 'Subtrecho',
        codigo: 'Nome da seção',
        abInicio: 'Estaca inicial',
        abFin: 'Estaca final',
        pInicio: 'km inicial',
        pFin: 'km final',
        inicio: 'Início ',
        final: 'Fim',
        fullResta: 'Comprimento (km)',
        createdAt: 'Data de criação',
        createdBy: 'Criado por',
        updatedAt: 'Data de atualizaçaõ',
        updatedBy: 'Atualizado por',
      }
    },
    segmentos: {
      name: 'Segmento |||| Segmentos',
      fields: {
        tipoElemento: 'Tipo',
        segmentoId: 'Segmento',
        codigo: 'Nome do segmento',
        identificacionRamal: 'Identificação do ramo',
        codigoRR: 'Código',
        absSgm: 'Estaca do segmento',
        lado: 'Lado',
        abInicio: 'Estaca inicial',
        abFin: 'Estaca final',
        pInicio: 'Estaca inicial',
        pFin: 'Estaca final',
        departamento: 'Estado',
        municipio: 'Municipio',
        latitud: 'Latitude',
        longitud: 'longitude',
        altura: 'Elevação (m.a.n.m',
        ubicaciones: 'Localizações',
        tipoCalzada: 'Tipo de pista',
        calzadaCodigo: 'Nome da pista',
        tipoSegmento: 'Tipo de Segmento',
        tipoSuperficie: 'Tipo de pavimento',
        estado: 'Estado',
        unidadFuncional: 'Subtrecho',
        tramo: 'Seção',
        fullResta: 'Extensão (km)',
        createdAt: 'Data de criação',
        createdBy: 'Criado por',
        updatedAt: 'Data de atualizaçaõ',
        updatedBy: 'Atualizado por',
      }
    },
    materials: {
      name: 'Informações materiais',
      editar:'Editar materiais',
      fields: {
        material: 'Codigo do material',
        tipo: 'Tipo',
        comportamiento: 'Comportamento',
        especificacion: 'Especificação',
        refmodulus: 'Módulo teórico',
        minmodulus: 'Módulo mínimo',
        stdfactor: 'Desvio padrão',
        poissons: 'Coeficiente de Poisson',
        observaciones: 'Observações',
        createdAt: 'Data de criação',
        createdBy: 'Criado por',
        updatedAt: 'Data de atualizaçaõ',
        updatedBy: 'Atualizado por',
        materialCreate: 'Material'
      }
    },
    granulometrias: {
      name: 'Granulomentria |||| Granulometrias',
      fields: {
        tipo: 'Tipo de material',
        granulometria: 'Granulometria',
        especificacion: 'Padrão de especificação',
        createdAt: 'Data de criação',
        createdBy: 'Criado por',
        updatedAt: 'Data de atualizaçaõ',
        updatedBy: 'Atualizado por',
      }
    },
    generacion_masiva_segmentos: {
      name: 'Generação em massa de segmentos |||| Generação em massa de segmentos',
      fields: {
        unidadFuncional: 'Subtrecho',
        tramo: 'Seção',
        absSegIni: 'Estaca inicial do segmento ',
        absSegFin: 'Estaca final do segmento ',
        longTotal: 'Extensão total ',
        latitud: 'Latitude ',
        longitud: 'Longitude ',
        altura: 'Elevação (m.a.n.m)',
        tipoCalzada: 'Tipo de Pista',
        tipoSegmento: 'Tipo de Segmento',
        tipoSuperficie: 'Tipo de Superficie',
      }
    },
    transformacion_segmentos: {
      name: 'Transformação de segmento |||| Transformação de segmento',
      fields: {
        unidadFuncional: 'Subtrecho',
        tramo: 'Seção',
        absSegIni: 'Estaca inicial do segmento',
        absSegFin: 'Estaca final do segmento',
        longTotal: 'Extensão total',
      }
    },
    idiomas: {
      name: 'Língua |||| Línguas',
      idiomaActual:'Idioma atual: ',
      fields: {
        idioma: 'Língua',
        region: 'Região',
        nombreIdioma: 'Nome da Língua',
        manual_usuario: 'Manual do usuário',
        modulo_administracion: 'Módulo de administração',
        modulo_inventario: 'Módulo de inventário',
        estado: 'Estado',
        eliminar: 'Remover',
        cargueIdioma: 'Cargue de língua',
        descargar: 'Exportar dados',
        descargar_manual_usuario: 'Expotar o manual do usuário',
        informacionIdioma: 'Informações da língua',
        auditoria: 'Auditoria',
        createdAt: 'Data de criação',
        createdBy: 'Criado por',
        updatedAt: 'Data de atualização',
        updatedBy: 'Atualizado por'
      }
    },
    reparacion_localizadas: {
      name: 'Reparação |||| Reparações',
      fields: {
        tipoReparacion: 'Tipo de reparo',
        nombre: 'Nome do reparo',
        unidad: 'Unidade de medida',
        costo: 'Custo',
        unidadPlaca:'$/placa',
        observaciones: 'Observações',
        createdAt: 'Data de criação',
        createdBy: 'Criado por',
        updatedAt: 'Data de atualização',
        updatedBy: 'Atualizado por'
      }
    },
    tipo_deterioro_pers: {
      name: 'Defeito |||| Defeitos ',
      fields: {
        tipoSuperficie: 'Tipo de Pavimento ',
        codigoAstm: 'Código ASTM ',
        nivelSeveridad: 'Níveis de severidade ',
        estado: 'Esatdo ',
        nombre: 'Nome ',
        unidad: 'Unidad ',
        losa:'Placa',
        tipoReparacionMedia: 'Tipo de reparo - Severidade média ',
        tipoReparacionAlta: 'Tipo de reparo - Severidade alta ',
        createdAt: 'Data de criação ',
        createdBy: 'Criado por ',
        updatedAt: 'Data de atualização ',
        updatedBy: 'Atualizado por '
      }
    },
    contacto_emergencias: {
      name: 'Contato de Emergência |||| Contatos de Emergência ',
      fields: {
        proyecto: 'Projeto ',
        grupo: 'Grupo',
        numero_identificacion: 'Número de identificação ',
        nivel: 'Nível ',
        identificacion: 'Tipo de identificação ',
        empresa: 'Empresa ',
        nombre: 'Nomes',
        apellido: 'Sobrenomes ',
        cargo: 'Posição ',
        direccion: 'Endereço ',
        departamento: 'Estado',
        municipio: 'Municipio ',
        municipio1: 'Municipio ',
        municipio2: 'Municipio ',
        municipio3: 'Municipio ',
        municipio4: 'Municipio ',
        municipio5: 'Municipio ',
        municipio6: 'Municipio ',
        municipio7: 'Municipio ',
        municipio8: 'Municipio ',
        municipio9: 'Municipio ',
        municipio10: 'Municipio ',
        municipio11: 'Municipio ',
        municipio12: 'Municipio ',
        municipio13: 'Municipio ',
        municipio14: 'Municipio ',
        municipio15: 'Municipio ',
        municipio16: 'Municipio ',
        municipio17: 'Municipio ',
        municipio18: 'Municipio ',
        municipio19: 'Municipio ',
        municipio20: 'Municipio ',
        municipio21: 'Municipio ',
        municipio22: 'Municipio ',
        municipio23: 'Municipio ',
        municipio24: 'Municipio ',
        municipio25: 'Municipio ',
        municipio26: 'Municipio ',
        municipio27: 'Municipio ',
        municipio28: 'Municipio ',
        municipio29: 'Municipio ',
        municipio30: 'Municipio ',
        municipio31: 'Municipio ',
        municipio32: 'Municipio ',
        municipio33: 'Municipio ',
        telefono_1: 'Teléfono 1 ',
        telefono_2: 'Teléfono 2 ',
        correo: 'E-mail ',
        observaciones: 'Observações ',
        createdAt: 'Data de criação ',
        createdBy: 'Criado por ',
        updatedAt: 'Data de atualização ',
        updatedBy: 'Atualizado por '
      },
    },
    proveedors: {
      name: 'Fornecedor |||| Fornecedores ',
      fields: {
        proyecto: 'Projeto ',
        sistema: 'Sistema ',
        nombreSistema: 'Nome do sistema ',
        sistemaNombre: 'Sistema ',
        identificacion: 'Tipo de identificação ',
        numeroIdentificacion: 'Número de identificação',
        razonSocial: 'Empresa ',
        nombre: 'Nomes',
        apellido: 'Sobrenomes ',
        direccion: 'Endereço ',
        departamento: 'Estado ',
        municipio: 'Municipio ',
        municipio1: 'Municipio ',
        telefono: 'Telefone 1 ',
        telefono2: 'Telefone 2 ',
        correo: 'E-mail ',
        observaciones: 'Observações ',
        createdAt: 'Data de criação ',
        createdBy: 'Criado por ',
        updatedAt: 'Data de atualização ',
        updatedBy: 'Atualizado por ',
        calificacion: 'Avaliação ',
        fechaCalificacion: 'Data de avaliação ',
        usuario_id: 'Usuário ',
        promedioCal: 'Media '
      }
    },
    calificacion_proveedors: {
      fields: {
        proveedor: 'Fornecedor',
        calificacion: 'Avaliação ',
        fechaCalificacion: 'Data de avaliação ',
        usuario_id: 'Usuário '
      }
    },
    servicios_publicos: {
      name: 'Serviço público  |||| Serviços públicos ',
      fileds: {
        proyecto: 'Projeto ',
        grupo: 'Grupo ',
        identificacion: 'Tipo de identificação ',
        numero_identificacion: 'Número de identificação ',
        empresa: 'Empresa ',
        nombre: 'Nomes ',
        apellido: 'Sobrenomes ',
        direccion: 'Endereço ',
        departamento: 'Estado ',
        municipio1: 'Municipio ',
        municipio: 'Municipio ',
        municipios: 'Municipios ',
        telefono_1: 'Telefone 1 ',
        telefono_2: 'Telefone 2 ',
        correo: 'E-mail',
        observaciones: 'Observações ',
        createdAt: 'Data de criação ',
        createdBy: 'Criado por ',
        updatedAt: 'Data de atualização',
        updatedBy: 'Atualizado por ',
      }
    },
    parametros_evaluacion: {
      name: 'Parámetros de avaliaçaõ |||| Parámetros de avaliações',
      fields: {
        valorAlerta_: 'Valor de alerta',
        datosminModelacion_: 'Dados mínimos para modelagem',
        tiempoAntesAlertaMedicion_: 'Quanto tempo antes de gerar o alerta de medição? (meses)',
        proyectoNombre: 'Projeto',
        indicadores: 'Índices de desempenho aplicáveis: ',
        titleParametros: 'Parámetros de avaliaçaõ',
        proyecto: 'Nome do projeto',
      }
    },
    colores: {
      name: 'Cor |||| Cores',
      fields: {
        color: 'Cor',
        valorA: 'Valor A',
        valorB: 'Valor B',
        valorMin: 'Valor mínimo de Cumprimento',
        createdAt: 'Data de criação',
        createdBy: 'Criado por',
        updatedAt: 'Data de atualização',
        updatedBy: 'Atualizado por',
      }
    },
    elementos: {
      name: 'Mapa geral |||| Mapas gerales',
      fields: {
        codigo:'Código',
        elemento:'Elemento',
        unidadFuncional: 'Subtrecho',
        tramo: 'Seção',
        segmento: 'Segmento',
        absInicial:'Estaca Inicial',
        verMas:'Ver mais',
        tipoMapa: 'Função do Mapa',
        tipoElementoSeguridad: 'Tipo de Elemento',
        tipoElementoDrenaje: 'Tipo de Elemento',
        tipoElementoSenal: 'Tipo de Sinal',
        tipoSenal: 'Tipo de Elemento',
        tipoSenalVertical: 'Tipo Modelo',
        tipoElementoAlrededor: 'Tipo de Elemento',
        tipoPuente: 'Tipo de Ponte',
        tipoElementoOtro: 'Tipo de Elemento',
        tipoElementoRed: 'Tipo de Elemento',
        numIntervalos: 'No. de intervalos',
        umbral: 'Limite',
      }
    },
    flexible_detalles: {
      name: 'Segmento Flexível |||| Segmentos Flexiveis',
      fields: {
        unidadFuncional: 'Subtrecho',
        tramo: 'Seção',
        segmento: 'Segmento',
        sentido: 'Sentido',
        estadoDetalles: 'Estado',
        estadoRazon: 'Motivo',
        dateInicioOperacion: 'Fecha de inicío da operação',
        proveedor: 'Fornecedor',
        garantiaMeses: 'Garantia (meses)',
        trafico: 'Tráfego',
        esalTime: 'ESAL (Ano)',
        nivelTrafico: 'Niveis de tráfego',
        nivelTraficoDetalles: 'Nível de Tráfego',
        esalTime10: 'ESAL (Projeção para 10 anos)',
        esalTime20: 'ESAL (Projeção para 20 anos)',
        detallesObservaciones: 'Observações',
        detallesCalzada: 'Detalhes da pista',
        geometriaLargo: 'Extensão (m)',
        geometriaAncho: 'Largura da pista (m)',
        geometriaNumCarriles: 'Número de faixas',
        geometriaAnchoCarril: 'Largura por faixa (m)',
        geometriaTipoBerma: 'Tipo de acostamento',
        geometriaAnchoBerma: 'Largura do acostamento (m)',
        geometriaCondicionBerma: 'Condição do acostamento',
        aux1: 'Área das faixas(m²)',
        areaCarrilesVerdadera: 'Área real das faixas (m²)',
        geometriaLongitudinal: 'Inclinação longitudinal',
        geometriaLongitudinalMa: 'Inclinação longitudinal máxima',
        geometriaLongitudinalMi: 'Inclinação longitudinal mínima',
        geometriaTransversal: 'Inclinação transversal',
        geometriaRadioMi: 'Raio mínimo (m)',
        geometriaRadioMa: 'Raio máximo (m)',
        geometriaRadioCurvatura: 'Raio de curvatura (°/km)',
        geometriaVelDiseno: 'Velocidade de projeto (km/h)',
        geometriaSeparador: 'Tem canteiro central?',
        geometriaSeparadorAncho: 'Lagura do canteiro (m)',
        geometriaCondicionesGeometrica: 'Condições geometricas',
        geometriaAlineamiento: 'Greide',
        geometriaTipoTerreno: 'Tipo de relevo',
        detallesGeometria: 'Observações',
        informacionEstructura: 'Informações da estrutura',
        propiedadesGeometricas: 'Propriedades geométricas',
        detallesCarriles: 'Detalhes das faixas',
        estructuraEspesor: 'Espessura (mm)',
        estructuraModulo: 'Módulo (MPa)',
        estructuraDs: 'Desvio padrão (Std)',
        material: 'Material',
        materialEsp: 'Especificação',
        granulometria: 'Granulometria',
        espesorTotal: 'Espessura total (mm)',
        igg: 'Índice de Gravidade Global (IGG)',
        iggEstructura: 'Índice de Gravidade Global',
        conceito: 'Conceito',
        capacidadEstructural: 'Capacidade estrutural',
        estructuraNumDiseno: 'Número estrutural projeto',
        estructuraFecha: 'Data',
        estructuraNumero: 'Número estrutural efetivo',
        estructuraNumeroFecha: 'Data',
        estructuraCbr: 'CBR (%)',
        estructuraCbrFecha: 'Data',
        estructuraFechaConst: 'Data de construção',
        estructuraFechaRehab: 'Data da última reabilitação',
        detallesEstructura: 'Observações',
        fechasImportantes: 'Datas importantes',
        smartCode: 'Código',
        absInicio: 'Estaca Inicial',
        absFin: 'Estaca Final',
        longitudShow: 'Extensão(km)',
        prInicio: 'km Inicial',
        prFin: 'km Final',
        departamento: 'Estado',
        municipio: 'Municipio',
        latitud: 'Latitude',
        longitud: 'Longitude',
        altura: 'Elevação (m.a.n.m)',
        createdAt: 'Data de criação',
        createdBy: 'Criado por',
        updatedAt: 'Data de atualização',
        updatedBy: 'Atualizado por',
        createdEn: 'Criado em',
        updatedEn: 'Atualizado em',
        localizacion: 'Localização',
        coordenadasIniciales: 'Coordenadas Iniciais',
        coordenadasFinales: 'Coordenadas Finais'
      }
    },
    rigido_detalles: {
      name: 'Segmento Rígido|||| Segmentos Rígidos',
      fields: {
        unidadFuncional: 'Subtrecho',
        tramo: 'Seção',
        segmento: 'Segmento',
        sentido: 'Sentido',
        estadoDetalles: 'Estado',
        estadoRazon: 'Motivo',
        dateInicioOperacion: 'Data de inicio de operação',
        proveedor: 'Fornecedor',
        garantiaMeses: 'Garantia (meses)',
        trafico: 'Tráfego',
        esalTime: 'ESAL (Ano)',
        nivelTrafico: 'Nivel de tráfego',
        nivelTraficoDetalles: 'Nivel de Tráfego',
        esalTime10: 'ESAL (Projeção para 10 anos)',
        esalTime20: 'ESAL (Projeção para 20 anos)',
        detallesObservaciones: 'Observações',
        detallesCalzada: 'Detalhes da pista',
        geometriaLargo: 'Extensão (m)',
        geometriaAncho: 'Largura da pista (m)',
        geometriaNumCarriles: 'Número de faixas',
        geometriaAnchoCarril: 'Largura por faixa (m)',
        geometriaTipoBerma: 'Tipo de acostamento',
        geometriaAnchoBerma: 'Largura do acostamento (m)',
        geometriaCondicionBerma: 'Condição do acostamento',
        aux1: 'Área das faixas(m²)',
        areaCarrilesVerdadera: 'Área real das faixas (m²)',
        geometriaLongitudinal: 'Inclinação longitudinal',
        geometriaLongitudinalMa: 'Inclinação longitudinal máxima',
        geometriaLongitudinalMi: 'Inclinação longitudinal mínima',
        geometriaTransversal: 'Inclinação transversal',
        geometriaRadioMi: 'Raio mínimo (m)',
        geometriaRadioMa: 'Raio máximo (m)',
        geometriaRadioCurvatura: 'Raio de curvatura (°/km)',
        geometriaVelDiseno: 'Velocidade de projeto (km/h)',
        geometriaSeparador: 'Tem canteiro central?',
        geometriaSeparadorAncho: 'Largura do canteiro (m)',
        geometriaCondicionesGeometrica: 'Condições geometricas',
        geometriaAlineamiento: 'Greide',
        geometriaTipoTerreno: 'Tipo de relevo',
        dimensionesLosaTipica: 'Dimensões típicas da placa',
        geometriaDltLargo: 'Comprimento (m)',
        geometriaDltAncho: 'Largura (m)',
        geometria_dlt_area: 'Área (m²)',
        geometriaDltNumlosas: 'No. de placas',
        geometriaDltLongjuntas: 'Extensão de juntas (m)',
        detallesGeometria: 'Observações',
        informacionEstructura: 'Informações da estrutura',
        propiedadesGeometricas: 'Propriedades geométricas',
        detallesCarriles: 'Detalhes das faixas',
        estructuraEspesor: 'Espessura (mm)',
        estructuraModulo: 'Módulo (MPa)',
        estructuraDs: 'Desvio padrão (Std)',
        material: 'Material',
        materialEsp: 'Especificação',
        granulometria: 'Granulometria',
        espesorTotal: 'Espessura total (mm)',
        igg: 'Índice de Gravidade Global (IGG)',
        iggEstructura: 'Índice de Gravidade Global',
        conceito: 'Conceito',
        capacidadEstructural: 'Capacidade estrutural',
        estructuraNumDiseno: 'Número estrutural projeto',
        estructuraFecha: 'Data',
        estructuraNumero: 'Número estrutural efetivo',
        estructuraNumeroFecha: 'Data',
        estructuraCbr: 'CBR (%)',
        estructuraCbrFecha: 'Data',
        estructuraVacios: 'Vazios (%)',
        kjoint: 'k-joint (kPa/mm)',
        jsdf: 'SDF',
        kcenter: 'k-center (kPa/mm)',
        csdf: 'SDF',
        estructuraFechaConst: 'Data de construção',
        estructuraFechaRehab: 'Data da última reabilitação',
        detallesEstructura: 'Observações',
        fechasImportantes: 'Datas importantes',
        smartCode: 'Código',
        absInicio: 'Estaca Inicial',
        absFin: 'Estaca Final',
        longitudShow: 'Extensão(km)',
        prInicio: 'km Inicial',
        prFin: 'km Final',
        departamento: 'Estado',
        municipio: 'Municipio',
        latitud: 'Latitude',
        longitud: 'Longitude',
        altura: 'Elevação (m.a.n.m)',
        createdAt: 'Data de criação',
        createdBy: 'Criado por',
        updatedAt: 'Data de atualização',
        updatedBy: 'Atualizado por',
        createdEn: 'Criado em',
        updatedEn: 'Atualizado em',
        localizacion: 'Localização',
        coordenadasIniciales: 'Coordenadas Iniciais',
        coordenadasFinales: 'Coordenadas Finais'
      }
    },
    adoquin_detalles: {
      name: 'Segmento Bloco Intertravado |||| Segmentos Blocos Intertravados',
      fields: {
        unidadFuncional: 'Subtrecho',
        tramo: 'Seção',
        segmento: 'Segmento',
        sentido: 'Sentido',
        estadoDetalles: 'Estado',
        estadoRazon: 'Motivo',
        dateInicioOperacion: 'Data de inicio de operação',
        proveedor: 'Fornecedor',
        garantiaMeses: 'Garantia (meses)',
        trafico: 'Tráfego',
        esalTime: 'ESAL (Ano)',
        nivelTrafico: 'Nivel de tráfego',
        nivelTraficoDetalles: 'Nivel de Tráfego',
        esalTime10: 'ESAL (Projeção para 10 anos)',
        esalTime20: 'ESAL (Projeção para 20 anos)',
        detallesObservaciones: 'Observações',
        detallesCalzada: 'Detalhes da pista',
        geometriaLargo: 'Extensão (m)',
        geometriaAncho: 'Largura da pista (m)',
        geometriaNumCarriles: 'Número de faixas',
        geometriaAnchoCarril: 'Largura por faixa (m)',
        geometriaTipoBerma: 'Tipo de acostamento',
        geometriaAnchoBerma: 'Largura do acostamento (m)',
        geometriaCondicionBerma: 'Condição do acostamento',
        aux1: 'Área das faixas(m²)',
        areaCarrilesVerdadera: 'Área real das faixas (m²)',
        geometriaLongitudinal: 'Inclinação longitudinal',
        geometriaLongitudinalMa: 'Inclinação longitudinal máxima',
        geometriaLongitudinalMi: 'Inclinação longitudinal mínima',
        geometriaTransversal: 'Inclinação transversal',
        geometriaRadioMi: 'Raio mínimo (m)',
        geometriaRadioMa: 'Raio máximo (m)',
        geometriaRadioCurvatura: 'Raio de curvatura (°/km)',
        geometriaVelDiseno: 'Velocidade de projeto (km/h)',
        geometriaSeparador: 'Tem canteiro central?',
        geometriaSeparadorAncho: 'Largura do canteiro (m)',
        geometriaCondicionesGeometrica: 'Condições geometricas',
        geometriaAlineamiento: 'Greide',
        geometriaTipoTerreno: 'Tipo de relevo',
        dimAdoquinTipico: 'Dimensões típicas do bloco',
        geometriaAdoLargo: 'Comprimento (m)',
        geometriaAdoAncho: 'Largura (m)',
        geometriaAdoAlto: 'Alto (m)',
        detallesGeometria: 'Observações',
        informacionEstructura: 'Informação da estrutura',
        propiedadesGeometricas: 'Propriedades geométricas',
        detallesCarriles: 'Detalhes das faixas',
        estructuraEspesor: 'Espessura (mm)',
        estructuraModulo: 'Módulo (MPa)',
        estructuraDs: 'Desvio padrão (Std)',
        material: 'Material',
        materialEsp: 'Especificação',
        granulometria: 'Granulometria',
        espesorTotal: 'Espessura total (mm)',
        igg: 'Índice de Gravidade Global (IGG)',
        iggEstructura: 'Índice de Gravidade Global',
        conceito: 'Conceito',
        capacidadEstructural: 'Capacidade estrutural',
        estructuraNumDiseno: 'Número estrutural projeto',
        estructuraFecha: 'Data',
        estructuraNumero: 'Número Estrutural Efetivo',
        estructuraNumeroFecha: 'Data',
        estructuraCbr: 'CBR (%)',
        estructuraCbrFecha: 'Data',
        estructuraFechaConst: 'Data de construção',
        estructuraFechaRehab: 'Data da última reabilitação',
        detallesEstructura: 'Observações',
        fechasImportantes: 'Datas importantes',
        smartCode: 'Código',
        absInicio: 'Estaca Inicial',
        absFin: 'Estaca Final',
        longitudShow: 'Extensão(km)',
        prInicio: 'km Inicial',
        prFin: 'km Final',
        departamento: 'Estado',
        municipio: 'Municipio',
        latitud: 'Latitude',
        longitud: 'Longitude',
        altura: 'Elevação (m.a.n.m)',
        createdAt: 'Data de criação',
        createdBy: 'Criado por',
        updatedAt: 'Data de atualização',
        updatedBy: 'Atualizado por',
        createdEn: 'Criado em',
        updatedEn: 'Atualizado em',
        localizacion: 'Localização',
        coordenadasIniciales: 'Coordenadas Iniciais',
        coordenadasFinales: 'Coordenadas Finais',
        tipoElmentoFotografia: 'Tipo de elemento'
      },
    },
    otro_detalles: {
      name: 'Segmento Outro |||| Segmentos Outros ',
      fields: {
        unidadFuncional: 'Subtrecho',
        tramo: 'Seção',
        segmento: 'Segmento',
        sentido: 'Sentido',
        estadoDetalles: 'Estado',
        estadoRazon: 'Motivo',
        dateInicioOperacion: 'Data de inicio de operação',
        proveedor: 'Fornecedor',
        garantiaMeses: 'Garantia (meses)',
        trafico: 'Tráfego',
        esalTime: 'ESAL (Ano)',
        nivelTrafico: 'Nivel de tráfego',
        nivelTraficoDetalles: 'Nivel de Tráfego',
        esalTime10: 'ESAL (Projeção para 10 anos)',
        esalTime20: 'ESAL (Projeção para 20 anos)',
        detallesObservaciones: 'Observações',
        detallesCalzada: 'Detalhes da pista',
        geometriaLargo: 'Extensão (m)',
        geometriaAncho: 'Largura da pista (m)',
        geometriaNumCarriles: 'Número de faixas',
        geometriaAnchoCarril: 'Largura por faixa (m)',
        geometriaTipoBerma: 'Tipo de acostamento',
        geometriaAnchoBerma: 'Largura do acostamento (m)',
        geometriaCondicionBerma: 'Condição do acostamento',
        aux1: 'Área das faixas(m²)',
        sumaAreaCarriles: 'Área das faixas (m²)',
        areaCarrilesVerdadera: 'Área real das faixas (m²)',
        geometriaLongitudinal: 'Inclinação longitudinal',
        geometriaLongitudinalMa: 'Inclinação longitudinal máxima',
        geometriaLongitudinalMi: 'Inclinação longitudinal mínima',
        geometriaTransversal: 'Inclinação transversal',
        geometriaRadioMi: 'Raio mínimo (m)',
        geometriaRadioMa: 'Raio máximo (m)',
        geometriaRadioCurvatura: 'Raio de curvatura (°/km)',
        geometriaVelDiseno: 'Velocidade de projeto (km/h)',
        geometriaSeparador: 'Tem canteiro central?',
        geometriaSeparadorAncho: 'Largura do canteiro (m)',
        geometriaCondicionesGeometrica: 'Condições geometricas',
        geometriaAlineamiento: 'Greide',
        geometriaTipoTerreno: 'Tipo de relevo',
        detallesGeometria: 'Observações',
        informacionEstructura: 'Informações da estrutura',
        propiedadesGeometricas: 'Propriedades geométricas',
        detallesCarriles: 'Detalhes das faixas',
        estructuraEspesor: 'Espessura (mm)',
        estructuraModulo: 'Módulo (MPa)',
        estructuraDs: 'Desvio padrão (Std)',
        material: 'Material',
        materialEsp: 'Especificação',
        granulometria: 'Granulometria',
        espesorTotal: 'Espessura total (mm)',
        igg: 'Índice de Gravidade Global (IGG)',
        iggEstructura: 'Índice de Gravidade Global',
        conceito: 'Conceito',
        capacidadEstructural: 'Capacidade estrutural',
        estructuraNumDiseno: 'Número estrutural projeto',
        estructuraFecha: 'Data',
        estructuraNumero: 'Número Estrutural Efetivo',
        estructuraNumeroFecha: 'Data',
        estructuraCbr: 'CBR (%)',
        estructuraCbrFecha: 'Data',
        estructuraFechaConst: 'Data de construção',
        estructuraFechaRehab: 'Data da última reabilitação',
        detallesEstructura: 'Observações',
        fechasImportantes: 'Datas importantes',
        smartCode: 'Código',
        absInicio: 'Estaca Inicial',
        absFin: 'Estaca Final',
        longitudShow: 'Extensão(km)',
        prInicio: 'km Inicial',
        prFin: 'km Final',
        departamento: 'Estado',
        municipio: 'Municipio',
        latitud: 'Latitude',
        longitud: 'Longitude',
        altura: 'Elevação (m.a.n.m)',
        createdAt: 'Data de criação',
        createdBy: 'Criado por',
        updatedAt: 'Data de atualização',
        updatedBy: 'Atualizado por',
        createdEn: 'Criado em',
        updatedEn: 'Atualizado em',
        localizacion: 'Localização',
        coordenadasIniciales: 'Coordenadas Iniciais',
        coordenadasFinales: 'Coordenadas Finais'
      }
    },
    seguridads: {
      name: 'Elementos de Segurança Rodoviária |||| Elementos de Segurança Rodoviária',
      fields: {
        tipoElemento: 'Elemento',
        margen: 'Lado',
        sentido: 'Sentido',
        unidadFuncional: 'Subtrecho',
        tramo: 'Seção',
        segmento: 'Segmento',
        abscisa: 'Estaca',
        pr: 'km',
        latitud: 'Latitude',
        longitud: 'Longitude',
        material: 'Material',
        cant_elem: 'Número de elementos',
        cant_capta: 'No. de refletivos',
        cant_largo: 'Extensão (m)',
        ca_largo: 'Extensão (m)',
        largo: 'Extensão(m)',
        cant_ancho: 'Largura (m)',
        ca_ancho: 'Largura (m)',
        altura: 'Altura (m)',
        ca_altura: 'Altura (m)',
        perfil: 'Tipo de perfil',
        defensa: 'Tipo de defensa',
        postes: 'No. Postes',
        captafaros: 'No. de refletivos',
        cant_capta: 'No. de refletivos',
        unidades: 'No. Unidades',
        terminales: 'No. Terminales',
        tornillos: 'No. de parafusos',
        posteMetalico: 'Poste Metálico',
        calibre_mm: 'Calibre (mm)',
        tope: 'Tipo de tope',
        seccion: 'Seção',
        diametro: 'Diâmetro/Base (m)',
        diametroShow: 'Diâmetro Base',
        topes: 'Número de tachas',
        tramos: 'Extensão do trecho (m)',
        motivoReemplazo: 'Motivo da substituição',
        condicionElemento: 'Condição do elemento',
        elementoReemplazo_id: 'Elemento substituído',
        estado: 'Estado',
        fechaInstalacion: 'Data da instalação',
        proveedor: 'Fornecedor',
        garantia: 'Garantia (meses)',
        observaciones: 'Observações',
        smartCode: 'Código',
        abInicio: 'Estaca Inicial',
        abFin: 'Estaca Final',
        fullResta: 'Extensão (km)',
        pInicio: 'km Inicial',
        pFin: 'km Final',
        latitudInicial: 'Latitude',
        longitudInicial: 'Longitude',
        latitudFinal: 'Latitude',
        longitudFinal: 'Longitude',
        createdAt: 'Data de criação',
        createdBy: 'Criado por',
        updatedAt: 'Data de atualização',
        updatedBy: 'Atualizado por',
        createdEn: 'Criado em',
        updatedEn: 'Atualizado em',
        tipoElementoDesc: 'Elementos',
      },
    },
    elemento_drenajes: {
      name: 'Elemento Drenagem |||| Elementos Drenagem',
      fields: {
        tipoElemento: 'Tipo de Elemento',
        margen: 'Lado',
        sentido: 'Sentido',
        tipoCuneta: 'Tipo de Sarjeta',
        unidadFuncional: 'Subtrecho',
        tramo: 'Seção',
        segmento: 'Segmento',
        abscisa: 'Estaca',
        pr: 'km',
        latitud: 'Latitude',
        longitud: 'Longitude',
        fullResta: 'Extensão(km)',
        abInicio: 'Estaca Inicial',
        abFin: 'Estaca Final',
        pInicio: 'km Inicial',
        pFin: 'km Final',
        material: 'Material',
        seccion: 'Seção',
        tipoDelElemento: 'Tipo del Elemento',
        largo: 'Comprimento (m)',
        ancho: 'Largura/Diâmetro (m)',
        altura: 'Altura (m)',
        numeroTramos: 'Número de Trechos',
        seccionHidraulica: 'Seção Hidráulica (m²)',
        pendienteTransversal: 'Inclinação transversal (%)',
        pendienteTransversalShow: 'Inclinação transversal (%)',
        pendienteLongitudinal: 'Inclinação longitudinal (%)',
        pendienteLongitudinalShow: 'Inclinação longitudinal (%)',
        numeroDuctos: 'Numero de dutos',
        tipoPlanta: 'Tipo de Planta',
        direccion: 'Direção',
        areaAla: 'Área ala (m²)',
        areaCabezal: 'Área testa (m²)',
        areaCalzada: 'Área Calçada (m²)',
        areaLateral: 'Área lateral (m²)',
        estado: 'Condição do elemento',
        motivoReemplazo: 'Motivo da substituição',
        elementoReemplazo_id: 'Elemento substituído',
        estados: 'Estado',
        estadoRazon: 'Ração',
        fechaInstalacion: 'Data da instalação',
        proveedor: 'Fornecedor',
        garantia: 'Garantia (Meses)',
        observaciones: 'Observações',
        fullCodigoConsecutivo: 'Código',
        smartCode: 'Código',
        createdAt: 'Data de criação',
        createdBy: 'Criado por',
        updatedAt: 'Data de atualização',
        updatedBy: 'Atualizado por',
        createdEn: 'Criado em',
        updatedEn: 'Atualizado em',
        tipoImagen: 'Tipo de elemento'
      }
    },
    elemento_senalizacions: {
      name: 'Elemento Sinal |||| Elementos Sinais',
      fields: {
        tipoElementoSenal: 'Elemento',
        margen: 'Lado',
        sentido: 'Sentido',
        tipoSenal: 'Tipo de elemento',
        senal: 'Código da sinal',
        modelo: 'Sinal',
        unidadFuncional: 'Subtrecho',
        tramo: 'Seção',
        segmento: 'Segmento',
        abscisa: 'Estaca',
        pr: 'km',
        latitud: 'Latitude',
        longitud: 'Longitude',
        materialPlaca: 'Material da chapa',
        material: 'Material',
        tipoPapel: 'Tipo papel',
        tipoLamina: 'Tipo de chapa',
        tipoTablero: 'Forma da chapa',
        dimensionTablero: 'Dimensões da chapa',
        dimensionTablero: 'Dimensões da chapa',
        anchoTablero: 'Largura/Diâmetro da chapa (m)',
        altoTablero: 'Altura da chapa (m)',
        estadoTablero: 'Estado da chapa',
        tipoSoporte: 'Tipo de suporte',
        estadoSoporte: 'Estado do suporte',
        materialSoporte: 'Material do suporte',
        velocidadDimension: 'Velocidade associada com a dimensão',
        altura: 'Altura da sinal (m)',
        valorLimite: 'Valor límite',
        valorLimiteDescrip: 'Aplica ao sinal R-19',
        ABNT: 'Valor Mínimo ABNT',
        color: 'Cor principal',
        colorOtrosElem: 'Cor',
        retroflectividadInicial: 'Retrorreflectividade cor principal (cd/lux/m²)',
        colorSecundario: 'Cor secundária',
        retroflectividadFinal: 'Retrorreflectividad color secundária (cd/lux/m²)',
        colorTerciario: 'Color terciária',
        retroflectividadTerciaria: 'Retro-reflectividad color terciária (cd/lux/m²)',
        numeroTachas: 'Número de tachas',
        ancho: 'Largura (m)',
        area: 'Área (m²)',
        cantidad: 'Quantidade',
        espaciamientoPromedio: 'Espaçamento médio (m)',
        estado: 'Condição do elemento',
        condicion: 'Condição',
        motivoReemplazo: 'Motivo da substituição',
        elementoReemplazo_id: 'Elemento substituído',
        estados: 'Estado',
        fechaInstalacion: 'Data da instalação',
        proveedor: 'Fornecedor',
        garantia: 'Garantia (meses)',
        observaciones: 'Observações',
        smartCode: 'Código',
        abInicio: 'Estaca Inicial',
        abInicioShow: 'Estaca Inicial',
        abFin: 'Estaca Final',
        fullResta: 'Extensão (km)',
        pInicio: 'km Inicial',
        pInicioShow: 'km Inicial',
        pFin: 'km Final',
        latitudFinal: 'Latitude',
        longitudFinal: 'Longitude',
        fullMotivoReemplazo: 'Motivo da substituição',
        fullCodigo: 'Elemento substituído',
        createdAt: 'Data de criação',
        createdBy: 'Criado por',
        updatedAt: 'Data de atualização',
        updatedBy: 'Atualizado por',
        createdEn: 'Criado em',
        updatedEn: 'Atualizado em',
        tipoImagen: 'Tipo de elemento'
      } 
    },
    elemento_alrededors: {
      name: 'Elemento Arredor |||| Elementos Alrredores',
      fields: {
        tipoElemento: 'Tipo Elemento',
        tipoElementoShow: 'Elemento',
        margen: 'Lado',
        sentido: 'Sentido',
        unidadFuncional: 'Subtrecho',
        tramo: 'Seção',
        segmento: 'Segmento',
        abscisa: 'Estaca',
        pr: 'PR',
        latitud: 'Latitude',
        longitud: 'Longitude',
        tipoServicio: 'Tipo de serviço',
        tipoServicioCarac: 'Superficie característica',
        tipoServicioObs: 'Tipo',
        tipoServicioZINT: 'Tipo de afetação',
        tipoServicioOCPN: 'Tipo de elemento',
        tipoAcceso: 'Tipo de acesso',
        estacionamiento: 'Estacionamento',
        largo: 'Extensão do acceso (m)',
        ancho: 'Largura do acceso (m)',
        area: 'Área do acesso (m²)',
        identificacion: 'Identificação',
        propietario: 'Proprietário',
        emailPropietario: 'Email proprietário',
        telefonoPropietario: 'Telefone propietario',
        largoM: 'Comprimento (m)',
        anchoM: 'Largura(m)',
        areaM: 'Área (m²)',
        areaOb: 'Altura (m)',
        longitudAfectacion: 'Extensão da afetação (m)',
        longitudAfectacionZINT: 'Extensão da pista afetada (m)',
        riesgo: 'Nível de risco',
        seccion: 'Localização',
        tipoTerraplen: 'Tipo Terrapleno',
        cantidadBermas: 'Quantidade de Bermas',
        alturaPromedioBermas: 'Altura média das bermas (m)',
        tramoPendiente: 'Trechos de inclinação variável',
        pendTransversal: 'Inclinação Transversal H:V',
        obraEstabilizacion: 'Obras de estabilização',
        tipoObra: 'Tipo de obra',
        fichaInspeccion: 'Formulario Inspeção de obra',
        inspector: 'Inspetor',
        fechaInspeccion: 'Data do estudo',
        nombreFicha: 'Nome do formulario',
        estudioEstabilidad: 'Estudos de estabilidade do local',
        consultor: 'Consultora',
        fechaEstudio: 'Data do estudo',
        codigoEstudio: 'Código do estudo',
        nombreEstudio: 'Nome do estudo',
        longitudAcceso: 'Extensão do acesso (m)',
        anchoAcceso: 'Largura do acesso (m)',
        estado: 'Condição do elemento',
        condicion: 'Motivo da substituição',
        elementoReemplazo: 'Elemento substituído',
        fechaConstruccion: 'Data da instalação',
        proveedor: 'Fornecedor',
        garantia: 'Garantia (meses)',
        estados: 'Estado',
        mantenimiento: '¿Incluir este elemento no plano do manuntena un plan de manutenção?',
        observaciones: 'Observações',
        smartCode: 'Código',
        tipoCuneta: 'Tipo de sarjeta',
        abInicio: 'Estaca Inicial',
        abInicioList: 'Estaca Inicial',
        abFin: 'Estaca final',
        fullResta: 'Longitude (km)',
        pInicio: 'km Inicial',
        pInicioList: 'km inicial',
        pFin: 'km Final',
        latitudInicial: 'Latitude',
        longitudInicial: 'Longitude',
        latitudFinal: 'Latitude',
        longitudFinal: 'Longitude',
        createdAt: 'Data de criação',
        createdBy: 'Criado por',
        updatedAt: 'Data de atualização',
        updatedBy: 'Atualizado por',
        createdEn: 'Criado em',
        updatedEn: 'Atualizado em',
      }
    },
    tuneles: {
      name: 'Elemento Túnel |||| Elementos Túneis',
      fields: {
        elementos: 'Elementos',
        TNEL: 'Túnel',
        sentido: 'Sentido',
        clasificacion: 'Classificação',
        cobertura: 'De acordo com a cobertura máxima',
        carriles: 'Número de faixas',
        carrilesShow: 'Faixas',
        unidadFuncional: 'Subtrecho',
        tramo: 'Seção',
        segmento: 'Segmento',
        abscisa: 'Estaca',
        pr: 'km',
        latitud: 'Latitude',
        longitud: 'Longitude',
        ano: 'Ano',
        area: 'Área (m²)',
        diseno: 'Manual de projeto',
        disenador: 'Projetista',
        informe: 'Relatório do projeto',
        anoa: 'Ano',
        areaa: 'Área (m²)',
        disenoa: 'Norma de diseño',
        disenadora: 'Diseñador',
        informea: 'Manual de projeto',
        observacionesa: 'Observações',
        anob: 'Data do estudo',
        consultor: 'Consultor',
        estudio: 'Código do estudo',
        nom_estudio: 'Nome do estudo',
        anoc: 'Data do estudo',
        consultora: 'Consultor',
        estudioa: 'Código do estudo',
        nom_estudioa: 'Nome do estudo',
        propietario: 'Propietário',
        vial: 'Administração Rodoviária',
        proyectista: 'Planejador',
        opacidad: 'Opacidade do projeto (x10¯³ m¯¹) ',
        concentracion: 'Concentração de CO do desenho (ppm)',
        existe: 'Existe uma variante',
        long_km: 'Extensão da variante (km)',
        estadoa: 'Estado',
        observacioness: 'Observações',
        radio_min: 'Raio mínimo de curvatura (m)',
        radio_max: 'Raio máximo de curvatura (m)',
        ancho_carril: 'Largura da faixa (m)',
        pend_longi: 'Inclinação longitudinal (%)',
        radio_min_curva: 'Raio mínimo das curvas verticais (m)',
        radio_max_curva: 'Raio máximo das curvas verticais (m)',
        long_tunel: 'Extensão do túnel (m)',
        galibo: 'Altura libre (m)',
        ancho_anden: 'Largura da faixa para pedestre (m)',
        ancho_berma: 'Largura da Acostamento (m)',
        soporte: 'Sistema de suporte',
        tipologia: 'Tipos de túneis',
        observacioness: 'Observações',
        codigo_soporte: 'Código',
        codigo_soporteName: 'Revestimento definitivo',
        estado: 'Condição do elemento',
        mot_remplazo: 'Motivo da substituição',
        elemnto_remplazo: 'Elemento substituído',
        estados: 'Estado',
        fecha: 'Data de instalação',
        proveedor: 'Fornecedor',
        garantia: 'Garantía (meses)',
        observaciones: 'Observações',
        smartCode: 'Código',
        abInicio: 'Estaca inicial',
        abFin: 'Estaca final',
        fullResta: 'Extensão (km)',
        pInicio: 'km Inicial',
        pFin: 'km Final',
        createdAt: 'Data de criação',
        createdBy: 'Criado por',
        updatedAt: 'Data de atualização',
        updatedBy: 'Atualizado por',
        createdEn: 'Criado em',
        updatedEn: 'Atualizado em',
        clasificacionList: 'Tipo',
        sentidoList: 'Margemn',
        sentidoB: 'Sentido'
      }
    },
    elemento_contencions: {
      name: 'Elemento Contención |||| Elementos Contención',
      fields: {
        tipoElemento: 'Tipo de Elemento',
        margen: 'Margem',
        sentido: 'Sentido',
        tipo: 'Classificação',
        funcionalidad: 'Funcionalidade',
        unidadFuncional: 'Subtrecho',
        tramo: 'Seção',
        segmento: 'Segmento',
        abscisa: 'Estaca',
        pr: 'km',
        latitud: 'Latitude',
        longitud: 'Longitude',
        largo: ' Extensão (m)',
        ancho: 'Largura (m)',
        altura: 'Altura (m)',
        consultor: 'Consultor',
        fechaEstudio: 'Data do estudo',
        codigoEstudio: 'Código do estudo',
        nombreEstudio: 'Nome do estudo',
        estado: 'Condição do elemento',
        motivoReemplazo: 'Motivo da substituição',
        elementoReemplazo: 'Elemento substituído',
        estados: 'Estado',
        fechaInstalacion: 'Data de instalação',
        proveedor: 'Fornecedor',
        garantia: 'Garantía (meses)',
        observaciones: 'Observações',
        smartCode: 'Código',
        abInicio: 'Estaca inicial',
        abInicioList: 'Estaca inicial',
        abFin: 'Estaca final',
        pInicio: 'km Inicial',
        pInicioList: 'km inicial',
        pFin: 'km Final',
        createdAt: 'Data de criação',
        createdBy: 'Criado por',
        updatedAt: 'Data de atualização',
        updatedBy: 'Atualizado por',
        createdEn: 'Criado em',
        updatedEn: 'Atualizado em',
        tipoList: 'Elemento',
      }
    },
    elemento_pontons: {
      name: 'Elemento Pontilhão |||| Elementos Pontilhões',
      fields: {
        tipoElemento: 'Elemento',
        margen: 'Margen',
        sentidoB: 'Sentido',
        tipoPonton: 'Tipo',
        numeroCarriles: 'Número de faixas',
        unidadFuncional: 'Subtrecho',
        tramo: 'Seção',
        segmento: 'Segmento',
        abscisa: 'Estaca',
        PR: 'km',
        latitud: 'Latitude',
        longitud: 'Longitude',
        ano: 'Ano',
        area: 'Área (m²)',
        norma: 'Manual de projeto',
        disenador: 'Projetista',
        info_di: 'Relatório do projeto',
        anoa: 'Ano',
        areaa: 'Área (m²)',
        normaa: 'Manual de projeto',
        disenadora: 'Projetista',
        info_dia: 'Relatório do projeto',
        obser_dia: 'Observações',
        anob: 'Data de coleta de dados',
        sentido: 'Direção do estacamento',
        estacion_conteo: 'Estação de contagem',
        inspector: 'Inspetor',
        inspeccion: 'Requisitos de Inspeção',
        numinspeccion: 'Número de seções de inspeção',
        propietario: 'Proprietário',
        admon_vial: 'Administração Rodoviária',
        proyectista: 'Planejador',
        coeficiente: 'Coeficiente de aceleração sísmica (Aa)',
        cauce: 'Passagem por curso de água',
        variante: 'Existe uma variante',
        long_variante: 'Extensão da variante (km)',
        esta: 'Estado',
        vehi_dise: 'Veículo de projeto',
        distri_carga: 'Classe de distribuição de carga',
        capa_carga: 'Capacidade de Carga (t)',
        luz_critica: 'Extensão de luz crítica (m)',
        fac_clasi: 'Fator de classificação',
        fue_cor: 'Força de cisalhamento (t)',
        momento: 'Momento (t.m)',
        linea_carga: 'Linha de Carga de Rodas (t)',
        obs_1: 'Observações',
        pon_terra: 'Pontilhão de terraplenagem',
        pon_c: 'Pontilhão en Curvatura/Tangente',
        long_2: 'Extensão (m)',
        ancho_2: 'Largura da laje superior (m)',
        espe_2: 'Espessura losa superior (m)',
        altu_2: 'Altura inferior (m)',
        ancho_3: 'Largura da laje inferior (m)',
        espe_3: 'Espessura da laje inferior (m)',
        pro_3: 'Profundidade do aterro (m)',
        espe_4: 'Espessura dos muros (m)',
        sepa_4: 'Separação entre muros (m)',
        estado: 'Condição do elemento',
        motivoReemplazo: 'Motivo da substituição',
        elementoReemplazo: 'Elemento substituído',
        estados: 'Estado',
        fecha: 'Data de instalação',
        proveedor: 'Fornecedor',
        garantia: 'Garantia (meses)',
        observaciones: 'Observações',
        fullCodigo: 'Código',
        abInicio: 'Estaca inicial',
        abFin: 'Estaca final',
        fullResta: 'Extensão (km)',
        pInicio: 'km Inicial',
        pFin: 'km Final',
        createdAt: 'Data de criação',
        createdBy: 'Criado por',
        updatedAt: 'Data de atualização',
        updatedBy: 'Atualizado por',
        createdEn: 'Criado em',
        updatedEn: 'Atualizado em',
        Borrar: '¿Eliminar?',
        abInicio: 'Estaca inicial',
        pInicio: 'km inicial',
      }
    },
    puentes: {
      name: 'Elemento Pontes |||| Elemento Pontes',
      fields: {
        elementos: 'Elemento',
        margen: 'Margem',
        tipo: 'Tipo de Ponte',
        codigo:'Código',
        carriles: 'Número de faixas',
        unidad_funcional: 'Subtrecho',
        tramo: 'Seção',
        segmento: 'Segmento',
        abscisa: 'Estaca',
        PR: 'km',
        latitud: 'Latitude',
        longitud: 'Longitude',
        ingresoAbFin: 'Estaca Inicial',
        ingresoAbFin: 'Estaca Final',
        pInicio: 'km Inicial',
        pFin: 'km Final',
        years: 'Ano',
        area: 'Área (m²)',
        norma_diseno: 'Manual de projeto',
        disenador: 'Projetista',
        info_di: 'Relatório de Desenho',
        yearsb: 'Ano',
        areab: 'Área (m²)',
        norma_disenob: 'Padrão de projeto',
        disenadorb: 'Projetista',
        info_dib: 'Relatório do projeto',
        fecha: 'Data de coleta de dados',
        inspector: 'Inspetor',
        inspeccion: 'Exigências de inspeção',
        direccion_abscisado: 'Direção das Estacas',
        estacion_conteo: 'Estação de contagem',
        numinspeccion: 'Número de seções de inspeção',
        propietario: 'Proprietário',
        admon_vial: 'Administração Rodoviária',
        proyectista: 'Planejador',
        coe_sismo: 'Coeficiente de aceleração sísmica (Aa)',
        paso_cauce: 'Passagem por curso de água',
        estado_op: 'Estado',
        exis_variante: 'Existe uma variante',
        long_km: 'Extensão da variante (km)',
        vehi_di: 'Veículo de projeto',
        distri_carga: 'Classe de distribuição de carga',
        capacidad: 'Capacidade de Carga (t)',
        luz_critica: 'Extensão da luz Crítica (m)',
        fac_clas: 'Fator de classificação',
        fac_cort: 'Força de cisalhamento (t)',
        momento: 'Momento (t.m)',
        car_rue: 'Linha de Carga de Rodas (t)',
        observacioness: 'Observações',
        curva: 'Ponte em Curvatura/Tangente',
        terraplen: 'Ponte de terraplenagem',
        esviajamiento: 'Esviajamento (gra)',
        luces: 'Número de Vãos',
        luz_menor: 'Extensão Vão menor (m)',
        luz_mayor: 'Extensão Vão maior (m)',
        long_total: 'Extensão total (m)',
        ancho_tab: 'Largura da laje (m)',
        area_tab: 'Área da laje (m²)',
        ancho_sep: 'Largura de canteiro (m)',
        ancho_anden_izq: 'Largura da faixa para pedestre esquerda (m)',
        ancho_anden_der: 'Largura da faixa para pedestre direita (m)',
        ancho_calzada: 'Largura da pista (m)',
        ancho_bordillo: 'Largura entre os meios-fios (m)',
        alt_pilas: 'Altura do pilar (m)',
        long_pilas: 'Extensão do suporte dos pilares (m)',
        alt_estribos: 'Altura de estribos (m)',
        long_estribos: 'Extensão do suporte dos estribos (m)',
        dise_tipo: 'Desenho tipo',
        material: 'Material',
        transversarlCod: 'Código',
        transversarl: 'Tipo de estruturação transversal',
        longitudCod: 'Código',
        longitudinal: 'Tipo de estruturação longitudinal',
        car_max: 'Carga máxima',
        velo_max: 'Velocidade máxima (km/h)',
        galibo_maximo: 'Altura libre máximo (m)',
        otra: 'Outra',
        barandaCod: 'Código',
        baranda: 'Detalhes de guarda-corpo',
        expantionCod: 'Código',
        expantion: 'Junta de dilatação',
        proteccion_alta_tension: 'Proteção em redes de alta tensãon',
        tipo_acceso: 'Tipo de Acesso',
        huella: 'Piso do degrau (cm)',
        contrahuella: 'Espelho do degrau (cm)',
        logitud_remapa: 'Comprimento do rampa (m)',
        pendiente: 'Inclinação (%)',
        nro_tramos: 'Número de trechos',
        tipoestribos: 'Tipo',
        estriCod: 'Código',
        estriMat: 'Material',
        tipo_cimen: 'Tipo de fundaçao',
        tippilasCod: 'Código',
        tippilas: 'Tipo',
        forma_pilaCod: 'Código',
        forma_pila: 'Forma',
        cimen_pila: 'Tipo de fundaçao',
        estrisaCod: 'Código',
        estrisa: 'Material',
        apoyo_fijoCod: 'Código',
        apoyo_fijo: 'Apoios fixos nos encontros',
        pila_movilCod: 'Código',
        pila_movil: 'Apoios móveis em encontros',
        pila_pilaCod: 'Código',
        pila_pila: 'Apoios fixos em pilares',
        apoyo_movilCod: 'Código',
        apoyo_movil: 'Apoios móveis em pilares',
        pila_vigasCod: 'Código',
        pila_vigas: 'Apoios fixos em vigas',
        movil_vigasCod: 'Código',
        movil_vigas: 'Apoios móveis em vigas',
        estado: 'Condição do elemento',
        mot_remplazo: 'Motivo da substituição',
        elemnto_remplazo: 'Elemento substituído',
        estados: 'Estado',
        fecha: 'Data de instalação',
        proveedor: 'Fornecedor',
        garantia: 'Garantía (meses)',
        observaciones: 'Observações',
        observacionesDetalles: 'Detalhes e observações',
        createdAt: 'Data de criação',
        createdBy: 'Criado por',
        updatedAt: 'Data de atualização',
        updatedBy: 'Atualizado por',
        createdEn: 'Criado em',
        updatedEn: 'Atualizado em',
      }
    },
    otros_elementos: {
      name: 'Outro Elemento |||| Outros Elementos',
      fields: {
        tipoElemento: 'Elemento',
        margen: 'Margem',
        sentido: 'Sentido',
        unidadFuncional: 'Subtrecho',
        tramo: 'Seção',
        segmento: 'Segmento',
        abscisa: 'Estaca',
        PR: 'km',
        latitud: 'Latitude',
        longitud: 'Longitude',
        interseccion: 'Tipo de interseção',
        material: 'Material',
        flujo_peatonal: 'Fluxo de pedestres',
        clase_via_intersecta: 'Classe de rodovía de interseção',
        tipo_via_intersecta: 'Tipo  de rodovía de interseção',
        reductores_velocidad: 'Há Redutores de velocidade na rodovia',
        largo: 'Extensão (m)',
        carriles: 'No. de faixas',
        estado: 'Condição do elemento',
        motivo: 'Motivo da substituição',
        elementoReemplazo_id: 'Elemento substituído',
        estados: 'Estado',
        fecha_instalacion: 'Data de instalação',
        proveedor: 'Fornecedor',
        garantia: 'Garantía (meses)',
        observaciones: 'Observações',
        sentidoCarac: 'Sentido',
        fullCodigoConsecutivo: 'Código',
        smartCode: 'Código',
        abInicio: 'Estaca inicial',
        abFin: 'Estaca Final',
        fullResta: 'Extensão (km)',
        pInicio: 'km Inicio',
        pFin: 'km Fin',
        latitudShow: 'Latitude',
        longitudShow: 'Longitude',
        createdAt: 'Data de criação',
        createdBy: 'Criado por',
        updatedAt: 'Data de atualização',
        updatedBy: 'Atualizado por',
        createdEn: 'Criado em',
        updatedEn: 'Atualizado em',
      }
    },
    redes: {
      fields: {
        tipoElemento: 'Elemento',
        estado: 'Condição do elemento',
        motivoReemplazo: 'Motivo da substituição',
        elementoReemplazo: 'Elemento substituído',
        estados: 'Estado',
        fecha: 'Data de instalação',
        proveedor: 'Fornecedor',
        unidadFuncional: 'Subtrecho',
        tramo: 'Seção',
        abscisa: 'Estaca',
        fullCodigo: 'Código',
        Borrar: '¿Eliminar?',
        margen: 'Margen',
        sentidoB: 'Sentido',
        segmento: 'Segmento',
        latitud: 'Latitude',
        longitud: 'Longitude',
        entornoVia:'Ambiente de rastreamento',
        claseIluminacion:'Aula de iluminação',
        tipoLampara:'Tipo de lâmpada',
        brazoTipo:'Tipo de braço',
        disposicionIluminaria:'Disposição das luminárias',
        garantiaMeses: 'Garantía (meses)',
        mantenimiento: '¿Incluir este elemento no plano do manuntena un plan de manutenção?',
        observaciones: 'Observações',
        empresa: 'Empresa ',
        diametro: 'Diâmetro (m)',
        longitudM: 'Longitud (m)',
        altura: 'Altura (m)',
        largo: 'Extensão (m)',
        ancho: 'Largura (m)',
        base:'Base (m)',
        largoDiametro: 'Extensão/Diâmetro (m)',
        capacidadT:'Capacidade (t)',
        marca: 'Marca',
        angulo:'Ângulo de inclinação do braço (°)',
        luminancia:'Valor médio de luminância (cd/m²)',
        anchoLuminoso:'Largura do módulo de luz (m)',
        largoLuminoso:'Comprimento do módulo de luz (m)',
        material: 'Material',
        tipo:'Tipo',
        seccion: 'Seção',
        nivelTraficoDetalles: 'Nivel de Tráfego',
        pr:'PR',
        clase:'Clase',
        clasificacionSoporte:'Classificação de mídia',
        codigo:'Código',
        createdAt: 'Data de criação',
        createdBy: 'Criado por',
        updatedAt: 'Data de atualização',
        updatedBy: 'Atualizado por',
        createdEn: 'Criado em',
        updatedEn: 'Atualizado em',
      }
    },
    inventariopeajes: {
      name: 'Inventário de Pedágio |||| Inventários de Pedágios',
      fields: {
        nombre: 'Nome do Pedágio',
        codigo: 'Código',
        estacion_manual: 'N° Estações manuais',
        estacion_manual_show: 'Estações manuais',
        estacion_automatica: 'Estações automáticas',
        estacion: 'Total Estações',
        unidad_funcional: 'Subtrecho',
        tramo: 'Seção',
        segmento: 'Segmento',
        Abscisa: 'Estaca',
        Latitud: 'Latitude',
        Longitud: 'Longitude',
        createdAt: 'Data de criação',
        createdBy: 'Criado por',
        updatedAt: 'Data de atualização',
        updatedBy: 'Atualizado por',
        estado: 'Estado',
        absciaShow: 'Estaca (km)',
      }
    },
    mensuals: {
      name: 'Tráfego Histórico |||| Tráfego Histórico',
      fields: {
        inventario_peajes: 'Pedágio',
        sentido: 'Sentido',
        ano: 'Ano',
        mes: 'Mês',
        categoria_a: 'Categoria I',
        categoria_b: 'Categoria II',
        categoria_c: 'Categoria III',
        categoria_d: 'Categoria IV',
        categoria_e: 'Categoria V',
        categoriaA: 'Cat. I',
        categoriaB: 'Cat. II',
        categoriaC: 'Cat. III',
        categoriaD: 'Cat. IV',
        categoriaE: 'Cat. V',   
        categoria_aShow: 'Categoria I:',
        categoria_bShow: 'Categoria II:',
        categoria_cShow: 'Categoria III:',
        categoria_dShow: 'Categoria IV:',
        categoria_eShow: 'Categoria V:',
        codigo: 'Código',
        Unidad_Funcional: 'Subtrecho',
        Tramo: 'Seção',
        Segmento: 'Segmento',
        Abscisa: 'Estaca',
        año: 'Por ano consolidado',
        categoria: 'Por categoría',
        desde: 'A partir de',
        hasta: 'Até',
        Transito: 'Tráfego',
        TPM: 'TPM',
        historico: 'Histórico',
        createdAt: 'Data de criação',
        createdBy: 'Criado por',
        updatedAt: 'Data de atualização',
        updatedBy: 'Atualizado por',
      }
    },
    estacion_conteos: {
      name: 'Estação de contagem |||| Estações de contagem',
      fields: {
        nombre: 'Nome',
        unidad_funcional: 'Subtrecho',
        tramo: 'Seção',
        segmento: 'Segmento',
        abscisa: 'Estaca',
        latitud: 'Latitude',
        longitud: 'Longitude',
        categoria:'Categoria',
        consultor: 'Consultor',
        informe: 'Relatório',
        date: 'Data do estudo',
        abscisaInicial: 'Estaca inicial',
        abscisaFinal: 'Estaca final',
        operativa:'Operativo',
        direccional: 'Fator direcional',
        carril: 'Fator de faixa',
        years: 'Ano',
        factorCamionPonderado: 'Fator caminhão',
        c2p:'C₂P',
        c2_p:'C2-p',
        c2g:'C₂G',
        c2_g:'C2-g',
        c3yc4:'C₃yC₄',
        c3c4:'C3-C4',
        c5:'C₅',
        c52:'>C₅',
        tpd:'TPD',
        esal1: 'ESAL (Projeção a 5 anos)',
        esal2: 'ESAL (Projeção a 10 anos)',
        esal3: 'ESAL (Projeção a 20 anos)',
        tpdactual: 'VDM atual',
        tasacrecimiento: 'Taxa de crescimento',
        valores:'Valores',
        datosTotal:'dados totais',
        years1: 'A partir de',
        years2: 'Até',
        createdAt: 'Data de criação',
        createdBy: 'Criado por',
        updatedAt: 'Data de atualização',
        updatedBy: 'Atualizado por',
      },
      input:{
        fechaEstudio:'A data não pode ser maior que a data atual',
        tasaCrecimientoMenor:'O número inserido deve ser menor que 100',
        tasaCrecimientoMayor:'O número inserido deve ser maior que -100',
      }
    },
    indicadores_cargar_informacions: {
      name: 'Carregamento de dados |||| Carregamento de dados',
      title:'Exibir indicadores',
      cerrar:'Fechar',
      visualizacion:'Visualização',
      detalle:'Detalhes',
      fields: {
        indicador: 'Índice de desempenho',
        unidadFuncional: 'Subtrecho',
        tramo: 'Seção',
        abInicios: 'Estaca inicial',
        abFins: 'Estaca final',
        abini: 'Estaca Inicial',
        abfin: 'Estaca final',
        fechaMedicion: 'Data de medição',
        medidoPor: 'Medido Por',
        estado: 'Estado',
        tipoMedicion: 'Tipo de medição',
        archivoCarga: 'Arquivos de carga',
        AbscisaInicio: 'Estaca inicial',
        AbscisaFin: 'Estaca Final',
        disponibilidadVia:'Disponibilidade através',
        tiempoAtencion:'Tempo de atenção',
        ocupacionCarril:'ocupação da pista',
        VisualizarIndicadores: 'Visualizar Índices de desempenho'

      }
    },
    calificacion_puentes: {
      name: 'Avalição de Ponte |||| Avalição de Pontes',
      fields: {
        smartCode: 'Número de medição',
        puentes: 'Ponte',
        fechaInicioInspeccion: 'Data de início da inspeção',
        nombreInspector: 'Inspetor',
        nombreReporteInspector: 'Nome do relatório de inspeção',
        fechaReporteInspeccion: 'Data do relatório de inspeção',
        medidoPor: 'Medido por',
        estado: 'Estado',
        tipoMedicion: 'Tipo de medição',
        cumplimiento: 'Cumplimiento',
        unidadFuncional: 'Subtrecho',
        tramo: 'Seção',
        medicion: 'Medição',
        activo:'Ativo',
        inactivo:'Inativo',
      }
    },
    disponibilidad_vias: {
      name: 'Disponibilidade da rodovia |||| Disponibilidade da rodovia',
      fields: {
        consecutivo: 'Consecutivo',
        fecha: 'Data',
        tipoEvento: 'Tipo de Evento',
        tipoCierre: 'Tipo de Cierre',
        unidadFuncional: 'Subtrecho',
        AbscisaInicial: 'Estaca Inicial',
        AbscisaFinal: 'Estaca final',
        margen: 'Margem',
        lugar: 'Sítio',
        longitudAfect: 'Comprimento Afetado (km)',
        segmentosAfect: 'Segmentos Afetados',
        segmentosAfectId: 'Segmentos Afetados',
        latitud: 'Latitude',
        longitud: 'Longitude',
        fechaReporte: 'Data da sinalização ',
        horaReporte: 'Hora de relatório',
        fechaSenal: 'Data da sinalização',
        horaSenal: 'Hora da sinalização',
        tiempSenal: 'Tempo de Sinalização',
        horaFinal: 'Hora Final',
        fuenteRep: 'Fonte do Relatório',
        causa: 'Causa',
        elementoAfect: 'Elemento Afetado',
        observaciones: 'Observações',
        serviciosDirectorio: 'lista telefônica',
        grupoEmergencias: 'Grupo',
        sistema: 'Sistema',
        grupo: 'Grupo',
        servElemento: 'Elemento',
        longitudCola: 'Comprimento de retenção (m)',
        indicadorO2: 'Índice de desempenho comprimento de retenção O2',
        observacionesTrabajos: 'Observaçoes',
        volumenDerrumbe: 'Volumen del Derrumbe (m³)',
        ElementoAfectado: 'Elemento Afectado',
        observacionesDerrumbe: 'Observações de retenção',
        tipoIncidenteOtro: 'Tipo de Incidente',
        tipoVehiculoOtro: 'Tipo de Veículo',
        observacionesOtro: 'Observaciões',
        tipoAccidente: 'Tipo de Acidente',
        CodigoCausa: 'Código Causa',
        CausaProbable: 'Causa Provável',
        clasePolicia: 'Clase',
        cantidadHeridos: 'Cantidade',
        tipoHeridos: 'Tipo',
        generoHeridos: 'Gênero',
        edadHeridos: 'Idade',
        totalHeridos: 'Feridos Totais',
        cantidadVictimasf: 'Cantidade',
        tipoVictimasf: 'Tipo',
        generoVictimasf: 'Género',
        edadVictimasf: 'Idade',
        totalVictimasFatales: 'Vítimas Fatales Totais',
        cantidadVehiculos: 'Cantidade',
        categoriaVehiculos: 'Categoría',
        tipoVehiculos: 'Tipo',
        marcaVehiculos: 'Marca',
        lineaVehiculos: 'Linha',
        modeloVehiculos: 'Modelo',
        placaVehiculos: 'Placa',
        colorVehiculos: 'Cor',
        observacionesAccidente: 'Observações',
        ambulancia: 'Para cadastrar a data e hora da ambulância, activar o botão.',
        fechaDespeje: 'Data de fechamento',
        horaDespeje: 'Hora de fechamento',
        diasDespeje: 'Duração do evento (Dias)',
        tiempoDespejeFin: 'Duração do evento (h:mm)',
        observacionesDespeje: 'Observações de Fechamento',
        fechaFinalizacion: 'Data de Finalização',
        horaFinalizacion: 'Hora de Finalização',
        tiempoServicio: 'Tempo de Serviço',
        fechaLlegada: 'Data de Chegada',
        horaLlegada: 'Hora de Chegada',
        tiempoRespuesta: 'Tempo de resposta',
        indicadorO5: 'Tempo de resposta a acidentes',
        fecha_puestaservicio: 'Data de entrada em serviço',
        diasCierre: 'No. de dias Com fechamento',
        indicadorE17: 'Disponibilidade da rodovia',
        observacionesCierre: 'Observações finais',
        createdAt: 'Data de criação',
        createdBy: 'Criado por',
        updatedAt: 'Data de atualização',
        updatedBy: 'Atualizado por',
        tiempoFinalizacion: 'Tempo de finalização',
        finalizar: 'Finalizado',
        yearact: 'Ano',
        mesact: 'Mes',
        fechaAmbulancia:'Data de chegada da ambulância',
        tiempoRespuestaAmbulancia:'Tempo de resposta da ambulância',
        grua:'Para gravar a data e hora do guindaste, ative o botão',
        fechaGrua:'Data de chegada do guindaste',
        tiempoRespuestaGrua:'Tempo de resposta do guindaste',
      }
    },
    parametros_analises: {
      name: 'Parâmetros de planejamento |||| Parâmetros de planejamento',
      fields: {
        unidad_funcional: 'Subtrecho',
        plan: 'Plano de manutenção',
        num_analisis: 'Número de anos de análise',
        years1: 'Ano de analiação',
        descripcion: 'Descrição do cenário',
        tasa_crecimiento: 'Taxa de crescimento do tráfego',
        tasa_interes: 'Taxa de juros',
        coin: 'Moeda',
        per_analisis: 'Custo de reparação estrutural',
        nom_analisis: 'Custo de reparação da camada superficial',
        years2: 'Até',
        createdAt: 'Data de criação',
        createdBy: 'Criado por',
        updatedAt: 'Data de atualização',
        updatedBy: 'Atualizado por',
      }
    },
    politicas_mantenimientos: {
      name: 'Política de Manutenção |||| Políticas de Manutenção',
      fields: {
        nombre: 'Nome',
        unidadFuncional: 'Subtrecho',
        sistema: 'Sistema',
        FechaIniPeriodicidad: 'Data de início da periodicidade',
        sistema: 'Sistema',
        fecha: 'Data',
        unidadMedida: 'Unidade de medida',
        periocidad: 'Periodicidade',
        valorU: 'Valor Unitário',
        fechaU: 'Data valor unitário',
        fuente: 'Fonte',
        descripcion: 'Descrição',
        prolVidaUtil: 'Vida Útil',
        operacion: 'Tipo de Operação',
        tipoElemento: 'Tipo de Elemento',
        Valor_U: 'Valor.',
        Fecha_U: 'Data.',
        nombrePolitica: 'Nome da Política',
        createdAt: 'Data de criação',
        createdBy: 'Criado por',
        updatedAt: 'Data de atualização',
        updatedBy: 'Atualizado por',
        SeRealiza: 'Aplicar',
        Politica: 'Política',
        Clonar: 'Dobrar',
      },
    },
    politicas_mantenimiento_particulars: {
      name: 'Políticas de Manutenção específicas |||| Criar Políticas de Manutenção específicas',
      fields: {
        nombre: 'Nome da Política',
        sistema: 'Sistema',
        tipoElemento: 'Nome',
        tipoElementoSenal: 'Nome',
        unidadFuncional: 'Subtrecho',
        tramo: 'Seção',
        abInic: 'Estaca Inicial',
        abFin: 'Estaca final',
        codigoConsecutivo: 'ID no Inventário',
        nombrePoliticaString: 'Nome da Política',
        nombrePolitica: 'Nome da Política ID',
        periocidad2: 'Periodicidade',
        valorU2: 'Valor Unitário',
        fechaU2: 'Data Valor Unitário',
        fuente2: 'Fonte',
        fecha2: 'Data de início da periodicidade',
        prolVidaUtil2: 'Extensão da Vida útil',
        descripcion2: 'Descrição',
        periocidad: 'Periodicidade',
        valorU: '"Valor Unitário',
        fechaU: 'Data Valor Unitário',
        fuente: 'Fonte',
        fecha: 'Data de início da periodicidade',
        prolVidaUtil: 'Extensão da Vida útil',
        VidaUtil: 'Vida útil',
        descripcion: 'Descrição',
        createdAt: 'Data de criação',
        createdBy: 'Criado por',
        updatedAt: 'Data de atualização',
        updatedBy: 'Atualizado por',
        tipoElementoShow: 'Elemento',
        tipoElementoShowTable: 'Tipo de Elemento',
        Clonar: 'Dobrar',
      }
    },
    trayectorias: {
      name: 'Alternativas |||| Alternativas',
      fields: {
        unidad_funcional: 'Subtrecho',
        trafico: 'Limite de tráfego',
        nombre: 'Nome da alternativa',
        precio: 'Custo total (R$/m²)',
        preciocar: 'Custo camada de rodadura (R$/m²)',
        vida: 'Vida esperada (anos)',
        espesor: 'Espessura removida (mm)',
        createdAt: 'Data de criação',
        createdBy: 'Criado por',
        updatedAt: 'Data de atualização',
        updatedBy: 'Atualizado por',
        clonar: 'Clonar',
      }
    },
    modelo_costos_pers: {
      name: 'Reparações Menores |||| Reparações Menores',
      fields: {
        areaSegmento: 'Área do segmento m²',
        unidadFuncional: 'Subtrecho',
        createdAt: 'Data de criação',
        createdBy: 'Criado por',
        updatedAt: 'Data de atualização',
        updatedBy: 'Atualizado por',
        variableAFlex: 'Variável A',
        variableBFlex: 'Variável B',
        variableXFlex: 'Variável X',
        variableYFlex: 'Variável Y',
      }
    },
    pers_exports: {
      unidadSave: 'Código Subtrecho',
      parAnalisis: 'Parâmetros de análises',
      persImport: 'Documento base importado',
      unidad_funcional: 'Código',
      year: 'Ano',
      unidadFuncional: 'Subtrecho',
      version: 'Versão',
      document: 'Documento',
    },
    pers_imports: {
      name: 'Importação de PERS |||| Importação de PERS',
      fields: {
        unidadFuncional: 'Subtrecho',
        persExport: 'Arquivo de referência de exportação',
        fecha: 'Data',
        archivoCarga: 'Arquivos relacionados',
        fechaImp: 'Data de importação',
        document: 'Documento',
        cargarArchivo:'Upload de arquivo',
      }
    },
    costos: {
      name: 'Custos |||| Custos',
      indicarElementos:'Você deve indicar elementos para o registro de custos.',
      seleccioneElementos:'Selecionar elementos para registro de custos',
      definirCosto:'Definir Custo Global?',
      globalNoDisponible:'O custo global não está disponível se você inserir um custo nos valores padrão',
      sinDiligenciar:'Deixar vazio se não for necessário',
      paso3:'Os dados serão inseridos na etapa 3.',
      diligenciarDatos:'Preencher os dados',
      seleccionarElementos:'Selecionar elementos',
      valoresPredeterminados:'Indicar os valores padrão',
      unElemento:'Selecionar pelo menos um elemento',
      elementosADiligenciar:'Selecionar os elementos a serem preenchidos para fazer um registro de custo',
      completeAsistente:'Completar todas as etapas para finalizar o registro',
      seleccioneFiltro:'Selecionar filtros válidos para preencher as políticas específicas dos elementos correspondentes.',
      algunosElementos:'Alguns elementos não correspondem aos filtros ou às validações de seu tipo de dados.',
      noCrear:'O registro não pode ser criado, se nenhum elemento válido foi carregado.',
      valorItem:'O valor deve ser positivo',
      obsObligatoria:'Observação é obrigatória',
      codigoRepetido:'O código é repetido',
      noCrear2:'O registro não pode ser criado até que a validação dos elementos seja concluída.',
      fields: {
        elementos:'Elementos',
        unidadFuncional: 'Subtrecho',
        tramo: 'Seção',
        segmento: 'Segmento',
        abInicio: 'Estaca Inicial',
        abFin: 'Estaca final',
        sistema: 'Sistema',
        tipoElementoSenal: 'Elemento',
        tipoSenal: 'Tipo de Elemento',
        tipoElemento: 'Elemento',
        isMasiveLoading: 'Carregamento em massa',
        valorGlobal: 'Soma Total',
        tipoMantenimiento: 'Tipo de manutenção',
        fechaMantenimiento: 'Data de manutenção',
        costoMantenimiento: 'Custo de manutenção',
        proveedor: 'Fornecedor',
        calificacion: 'Classificação',
        observaciones: 'Observações',
        codigo: 'Código em Inventario',
        genericTipoElemento: 'Elemento',
        costoTotal: 'Custo Total',
        createdAt: 'Data de criação',
        createdBy: 'Criado por',
        updatedAt: 'Data de atualização',
        updatedBy: 'Atualizado por',
        fechaInicial: 'Data Inicial',
        fechaFinal: 'Data Final',
        consolidaror: 'Consolidar Por',
        id: 'ID',
        fecha: 'Data',
        sinResultados:'Sem resultados',
        registroCostos:'Registro de custos',
        pasoAnterior:'Voltar a etapa anterior', 
      }
    },
    flujo_cajas: {
      name: 'Fluxo de Caixa |||| Fluxos de Caixa',
      generarExcel:'Gerar Excel',
      generarExcel2:'Gerar Excel?',
      generarArchivo:'Gerar arquivo Excel com Matriz?',
      borrarMatriz:'Remover matriz?',
      fields: {
        unidadFuncional: 'Subtrecho',
        nombrePolitica: 'Nome da Políticas',
        tasaInteres: 'Taxa de juros',
        fechaInicio: 'Data de início do cálculo',
        years: 'Periodicidade',
        id: 'ID',
        createdAt: 'Data de criação',
        createdBy: 'Criado por',
        updatedAt: 'Data de atualização',
        updatedBy: 'Atualizado por',
        fecha: 'Data',
        status: 'Status',
        acciones: 'Ações',
      }
    },
    indicador_complementarios: {
      name: 'Conf. Índ. Complementar |||| Conf. Índ. Complementares',
      fields: {
        nombre: 'Nome do Índice',
        estado: 'Estado',
        umbralFallo: 'Limiar de falha',
        proyecto: 'Projeto',
        sistema: 'Sistema',
        tipoElemento: 'Elemento',
        tipoElementoSenal: 'Elemento',
        tipoSenal: 'Tipo de elemento',

        pregunta: 'Pergunta',
        peso: 'Peso',
        tipoCampo: 'Tipo Campo',
        decimales: 'Precisão de casas decimais',
        respuesta: 'Resposta',
        rangoInicial: 'Valor inicial do intervalo',
        rangoFinal: 'Valor final do intervalo',
        respuestasMult: 'Escolha Múltipla',
      }
    },
    respuesta_evaluacion_complementarios: {
      name: 'Índice Complementar |||| Índice Complementares',
      fields: {
        unidadFuncional: 'Subtrecho',
        estado: 'Estado',
        aprobacion: 'Aprovação',
        tramo: 'Seção',
        segmento: 'Segmento',
        sistema: 'Sistema',
        tipoElemento: 'Tipo elemento',
        codigo:'Código',
        codigoElemento:'Código do elemento',
        tipoElementoSenal: 'Tipo elemento',
        elemento: 'Elemento',
        fechaMedicion: 'Data da medição',
        accion: 'Ação',
        observaciones: 'Observações',
        indicadores: 'Índices de desempenho',
        pregunta: 'Pergunta',
        respuesta: 'Resposta',
        peso:'Peso',
        pesoTotal:'Peso total',
        uFallo:'limiar de falha',
        estadoVal:'Status de validação',
        smartCode: 'Código',
        createdAt: 'Data de criação',
        createdBy: 'Criado por',
        updatedAt: 'Data de atualização',
        updatedBy: 'Atualizado por',
      }
    },
    registro_novedads: {
      name: 'Configuração das Ocorrências |||| Configuração das Ocorrências',
      fields: {
        nombre: 'Nome da Ocorrência',
        estado: 'Estado',
        umbralFallo: 'Limiar de falha',
        proyecto: 'Projeto',
        sistema: 'Sistema',
        tipoElemento: 'Elemento',
        tipoElementoSenal: 'Elemento',
        tipoSenal: 'Tipo de elemento',

        pregunta: 'Pergunta',
        peso: 'Peso',
        tipoCampo: 'Tipo Campo',
        decimales: 'Precisão de casas decimais',
        respuesta: 'Resposta',
        rangoInicial: 'Valor inicial do intervalo',
        rangoFinal: 'Valor final do intervalo',
        respuestasMult: 'Escolha Múltipla',
      }
    },
    respuesta_evaluacion_novedads: {
      name: 'Reg. Ocorrência - Medição |||| Reg. Ocorrências - Medições',
      fields: {
        sistema: 'Sistema',
        tipoElemento: 'Tipo elemento',
        tipoElementoSenal: 'Tipo elemento',
        tipoRegistro: 'Tipo de Registro',
        unidadFuncional: 'Subtrecho',
        tramo: 'Seção',
        segmento: 'Segmento',
        elemento: 'Elemento',
        abscisaIni: 'Estaca inicial',
        abscisaFin: 'Estaca final',
        latitudInicial: 'Latitude inicial',
        latitudFinal: 'Latitude final',
        longitudInicial: 'Longitude inicial',
        longitudFinal: 'Longitude final',
        deterioro: 'Defeito',
        fechaRegistro: 'Data de cadastro',
        severidad: 'Severidade',
        longitud: 'Extensão (m)',
        ancho: 'Largura (m)',
        areaAfectada: 'Área afetada (m²)',
        estado: 'Estado',
        aprobacion: 'Aprovação',
        modelo: 'Modelo',
        codigo: 'Código',
        retroreflectColorPrincipal: 'Retro-reflectividade cor principal (cd/lux/m²)',
        disminucionPrincipal:'Diminuir cor principal (%)',
        retroreflectColorSecundario: 'Retro-reflectividade cor secundário (cd/lux/m²)',
        disminucionSecundario:'Diminuir cor secundário(%)',
        posicion: 'Posição',
        legibilidad: 'Legibilidade',
        novedad:'Ocorrência',
        novedades: 'Ocorrência',
        pregunta: 'Pergunta',
        respuesta: 'Resppsta',
        genericTipoElemento: 'Elemento',
        observaciones: 'Observações',
        pesoTotal:'Peso total',
        uFallo:'limiar de falha',
        estadoVal:'Status de validação',
        smartCode: 'Código Elemento',
        createdAt: 'Data de criação',
        createdBy: 'Criado por',
        updatedAt: 'Data de atualização',
        updatedBy: 'Atualizado por',
      }
    },
    aprobacions: {
      name: 'Aprovação |||| Aprovações',
      title:'Lista de Aprovações',
      fields: {
        unidadFuncional: 'Subtrecho',
        tipoRegistro: 'Tipo de Registro',
        aprobacion: 'Aprovação',
        tipoRegistro: 'Tipo',
        actividad: 'Actividade',
        fecha: 'Data',
        unidadFuncional: 'Subtrecho',
        usuario: 'Usuário',
      }
    },
    carga_masivas: {
          name: 'Carregamento em massa |||| Carregamentos em massa',
          fields: {
            nombreGrupo: 'Nome do grupo',
            cargados: 'Carga completada',
            enProceso: 'Em curso',
            proyecto: 'Projeto',
            proyectoList: 'Projeto:',
            rutaList: 'Rodovia:',
            ruta: 'Rodovia:',
            unidadFuncional: 'Subtrecho:',
            tramoList: 'Seção:',
            tramo: 'Seção:',
            sistemaList: 'Sistema',
            sistema: 'Sistema:',
            subSistema: 'Sub sistema',
            sistemaEstrucCont: 'Sistema: Terraplenos',
            sistemaPuentes: 'Sistema: Pontes',
            sistemaPontones: 'Sistema: Pontilhão',
            sistemaTuneles: 'Sistema: Túneis',
            imagenes: 'Imagens:',
            nombreGrupo: 'Nome Grupo',
            proyecto: 'Projeto',
            ruta: 'Rodovia',
            unidadSave: 'Subtrecho',
            tramo: 'Seção',
            sistema: 'Sistema',
            arrastrarSoltar:'Arraste e solte arquivos ou clique para selecionar arquivos',
          }
        },
    validaciones:{
      fechaFutura:'A data inserida não pode ser no futuro',
      fechaAPartir:'A data inserida não pode ser anterior ao ano 2000',
      abscisaInicial:'A Estaca inicial deve estar entre.',
      abscisaInicialDebeSerMenor:'A Estaca Inicial deve ser menor que a Estaca Final.',
      abscisaFinal:'A Estaca Final deve estar entre.',
      abscisaFinalDebeSerMayor:'A Estaca Final deve ser maior que a Estaca Inicial.',
      enteroPositivo:'O número deve ser um número inteiro positivo.',
      max10:'Máximo 10 caracteres.',
      max15:'Máximo 15 caracteres.',
      max40:'Máximo 40 caracteres.',
      max20:'Máximo 20 caracteres.',
      max50:'Máximo de caracteres excedido: 50 max.',
      max90:'O valor máximo deve ser 90.',
      min90:'O valor mínimo deve ser -90.',
      mayor90:'O valor inserido deve ser maior que -90.',
      max180:'O valor máximo deve ser 180.',
      min180:'O valor mínimo deve ser -180.',
      max200:'Máximo 200 caracteres.',
      edadEntre:'A idade deve estar entre 0 e 122.',
      cantidadEntre:'O valor deve estar entre 0 e 100.',
      sinAcciones:'Nenhuma ação disponível.',
      millardos:'O custo inserido deve ser menor ou igual a 100 bilhões',
      fechaInicio:'A data de início não pode ser maior que a data de término (verifique a data de início).',
      planComenzar:'O plano deve começar pelo menos hoje. (Verifique a data de início).',
      planComenzar2:'O plano deve começar pelo menos hoje.',
      entero:'Deve ser um número inteiro.',
      coordenadaValida:'Insira uma coordenada válida.',
      distanciaAbscisa:'A distância entre as Estacas deve ser inferior a 1.999',
      altitudMaxima:'A altitude máxima deve ser maior que a altitude mínima.',
      decimales1:'O valor inserido pode ter apenas um decimal.',
      decimales2:'O valor inserido pode ter apenas 2 casas decimais.',
      decimales3:'O valor inserido só pode ter até 3 casas decimais.',
      distanciaPr:'A distância entre o km deve ser inferior a 1.999.',
      distanciaPrMenor:'A distância entre o km deve ser de pelo menos 1.000.',
      rangoEntre:'O intervalo deve estar entre.',
      mayorK9:'O valor inserido não pode ser maior que K999+999.',
      abscisaFin:'O final da Estaca deve ser maior que o início da Estaca.',
      mayorPR9:'O valor inserido não pode ser maior que km 999+999.',
      prFin:'O fim do km deve ser maior que o início do km.',
      coordenadasDecimal:'O valor inserido nas coordenadas deve ser numérico.',
      decimal:'O valor inserido deve ser numérico.',
      latitudMaxima:'A latitude máxima deve ser maior que a latitude mínima.',
      longitudMaxima:'A Longitude máximo deve ser maior que a Longitude mínimo.',
      datoDigitado:'Os dados inseridos já estão em uso.',
      correoInvalido:'E-mail inválido.',
      correoEnUso:'Os dados inseridos já estão em uso.',
      anioIgual:'Os anos não podem ser os mesmos.',
      anioMayorFinal:'O ano inicial não pode ser maior que o ano final.',
      mayor_a_0:'O número inserido deve ser maior que 0.',
      mayor_a_10:'O valor deve ser maior que 10.',
      menor_a_5:'O número inserido deve ser menor que 5.',
      menor_a_30:'O valor inserido deve ser inferior a 30',
      umbralSumatoria:'O limiar de falha não pode ser maior que a soma do peso das perguntas.',
      pesoRespuesta:'O peso da resposta não pode ser maior que o valor do peso da pergunta nº.', 
      noIntersectando:'O número não está se cruzando.',
      noNegativo:'O número não deve ser negativo.',
      valNumMayorA0:'O número inserido deve estar entre 0 e 1',
      cargasIndicador:'Nenhum carregamento de dados publicado foi encontrado para o indicador.',
      absInicialDeCorreccionMenor:'A Estaca inicial de correção não pode ser menor que a Estaca inicial.',
      absInicialDeCorreccionMayor:'A Estaca inicial de correção não pode ser maior que a Estaca final.',
      absFinalDeCorreccionMenor:'A Estaca final de correção não pode ser menor que a Estaca inicial.',
      absFinalDeCorreccionMayor:'A Estaca final da correção não pode ser maior que a Estaca final.',
      unElemento:'Escolher pelo menos um elemento.',
      politicaGeneral:'Usar uma política geral como base para uma política específica. ',
      todosLosDatos:'Preencher todos os dados.',
      filtrosValidos:'Selecionar filtros válidos para preencher as políticas específicas dos elementos correspondentes.',
      sinPoliticasAsociadas:'Sem Políticas Associadas.',
      politicasDiligencias:'Garantir que os 4 tipos de políticas gerais estejam preenchidos para o elemento.',
      mantenimientoOSuministro:'Selecionar uma das duas opções (manutenção ou fornecimento).',
      abscisasTrazos:'Estacas não foram encontradas em um ou mais dos traços.',
      coordenadaDevuelta:'Pelo menos um dos grupos de coordenadas foi retornado sem informações. Garantir que esteja usando KML gerado pelo ArcGIS.',
      noCoincide:'O Subtrecho no arquivo não corresponde ao Subtrecho selecionado.',
      trazosDivididos:'Os traços não são divididos corretamente.',
      errorArchivo:'Ocorreu um erro ao ler o arquivo.',
      mayorA:'Deve ser maior que.',
      rangoNoValido:'Intervalo inválido.',
      contraseniaNoCoindice:'A senha não corresponde.',
      valNombre:'O nome é inválido',
      valApellido:'sobrenome não é válido',
      valEliminacion:'Não pode ser excluído',
      valRegistroAsociado:'Existem registros de inventário associados a este item. Por favor, valide.',
      valBorrar:'Tem certeza de que deseja excluir este item?',
      borrar:'REMOVER',
      valBorrarUnidadFuncional:'Excluir unidade funcional.',
      valBorrarProyecto:'Excluir projeto',
      valBorrarRuta:'Excluir rota.',
      valBorrarTramo:'Excluir Seções.',
      valBorrarSegmentos:'Excluir segmento',
    },
    audits:{
      name: 'Auditoría |||| Auditorías',
      fields:{
        type: 'Actividad',
        tableOrigin: 'Tabla',
        old: 'Información anterior',
        new: 'Información nueva',
        alteredValue: 'Información afectada', 
        user: 'Usuario',
        idElement: 'ID Elemento',
        module: 'Módulo',
        functionality: 'Funcionalidad',
        subElement: 'Elemento',
        smartCode: 'Código inteligente',
        createdIn: 'Creado en',
        dateIn: 'Fecha',
        tableMaster: 'Tabla principal',
        fechaInicial: 'Fecha Inicial',
        fechaFinal: 'Fecha Final',
      },
      tabs:{
        title: "Información auditoría",
        tabDetalles: "Detalle",
        tabIdentificacion: "Identificación",
        tableShow:{
          titleField: "Campo afectado",
          titleOld: "Información anterior",
          titleNew: "Información nueva",
        },
      }
    }
  },
  dynatest: {
    resources: {
      imagenesInventario: {
        titleImagen: 'Tipo de elemento',
        descripcionImagen: 'Arraste algumas imagens para iniciar o carregamento ou clique aqui para escolher uma',
      },
      pimsMenu: {
        fields: {
          inicio:'Início',
          administracion: "Administração",
          inventario: "Inventário",
          transito: "Tráfego",
          indicadores: "Índices de desempenho",
          gestion: "Gestão",
          reportesQS: "Relatórios"
        },
        subMenu: {
          usuarios: 'Usuarios',
          atributos: 'Facultade do usuario',
          proyectos: 'Projetos',
          DisenarProyecto: 'Desenhar um Projeto',
          Rutas: 'Rodovias',
          UnidadesFuncionales: 'Subtrechos',
          Tramos: 'Seções',
          Segmentos: 'Segmentos',
          Configuraciones: 'Configurações',
          Materiales: 'Materiais',
          Granulometría: 'Granulo - metria',
          Indicadores: 'Índices de desempenho',
          ParamEvalRangosAlerta: 'Parâmetros Avaliação e Intervalos de Alerta',
          DiagramaColorimetrico: 'Diagrama colori - métrico',
          IndicadoresComplementarios:'Índices Comple - mentares',
          DeterioroPERS: 'Defeitos PERS',
          Reparaciones: 'Reparação',
          Deterioros: 'Defeitos',
          Contactos: 'Contatos',
          Emergencias: 'Emergências',
          Proveedores: 'Fornecedores',
          ServiciosPublicos: 'Serviços Públicos',
          Mapa: 'Mapa',
          Segmentos: 'Segmentos',
          Flexible: 'Flexível',
          Rigido: 'Rígido',
          Adoquin: 'Bloco Intertravado',
          Otro: 'Outro',
          SistemasContencionVehicular: 'Elementos de Segurança Rodoviária',
          Drenaje: 'Drenagem',
          Senalizacion: 'Sinalização',
          Alrededores: 'Faixa de dominio',
          PuentesEstructurasEspe: 'Pontes e estruturas especiais',
          Tuneles: 'Túneis',
          Contencion: 'Terraplenos',
          Ponton: 'Pontilhão',
          Puentes: 'Pontes',
          OtrosElementos: 'Outros elementos',
          Redes: 'Redes',
          Peajes: 'Pedagios',
          Inventario: 'Inventário',
          TransitoHistorico: 'Tráfego Histórico',
          EstacionConteo: 'Estação de contagem',
          Indicadores4G: 'Indicadores 4G',
          CargarInformacion: 'Visualizar e carregar dados',
          E15Puentes: 'Avaliação de pontes',
          Complementarios:'Índices Complementares',
          DisponibilidadVia: 'Disponibilidade da rodovia',
          Programacion: 'Programação',
          ParametrosPlaneacion: 'Parâmetros de planejamento',
          OtrosSistemas: 'Outros Sistemas',
          PoliticasGenerales: 'Políticas Gerais',
          PoliticasParticulares: 'Políticas específicas',
          PavimentosPERS: 'Pavimentos - PERS',
          AlternativasIntervencion: 'Alternativas de intervenção',
          ReparacionesMenores: 'Reparações menores',
          ExportarBasePers: 'Exportar base PERS',
          ReportesPavimentos: 'Carregar base PERS',
          RegistroCostos: 'Registro de Custos',
          FlujoCaja: 'Fluxo de Caixa',
          ReportesConfig: 'Configurações Relatórios',
          ReportesPers: 'Relatórios PERS',
          ReportesInventario: 'Relatórios Inventário',
          ReportesIndicadores: 'Relatórios Índices de desempenho',
          ReportesCostosMantenimiento: 'Relatório Custos - Manutenção',
          ReportesTransito: 'Relatórios Tráfego',
          ReportesContactos: 'Outros Relatórios',
          ReportesModelacion: 'Relatórios Modelação',
          AplicacionMovil: 'Aplicação móvel',
          Novedades: 'Ocorrências',
          Aprobacion: 'Aprovação',
          ConfiguracionGeneral: 'Configuração Geral',
          Idioma: 'Língua',
          Sistema: 'Sistema',
          Audit: 'Auditoria',
          cargaMasiva: 'Carregamento em massa',
        }
      },
      idiomas: {
        formTabs: {
          seleccionIdiomaBase: 'Seleção da língua base',
          cargueArchivos: 'Importação de arquivos'
        }
      },
      usuarios: {
        title: {
          editTitle: 'Editar Usuário',
          showTitle: 'Informações do usuário'
        },
        formTabs: {
          identificacionTitle: 'Identificação',
        },
        select: {
          documento: {
            cedula_ciudadania: 'RG',
            cedula_extrangeria: 'RNM',
            nit: 'CPF',
          }
        },
        tabs: {
          informacionUsuario: 'Informações do usuário ',
          informacionBasica: 'Informações básicas',
          auditoria: 'Auditoria',
          acceso_usuario_app: 'ACESSO DO USUÁRIO AO SISTEMA',
          administracion: 'ADMINISTRAÇÃO',
          proyectosMay: 'PROJETOS',
          proyectosMin: 'Projetos',
          confMateriales: 'Configurações - Materiais',
          confIndicadores: 'Configurações - Índices de desempenho',
          confDeterioro: 'Configurações - Defeitos',
          contactos: 'Contatos',
          elem_inventario: 'ELEMENTOS INVENTÁRIO',
          segmentos_cal: 'Segmentos (Pista) ',
          inventario: 'Inventários',
          puentes_estructuras: 'Pontes e estruturas especiais',
          transito: 'TRÁFEGO',
          indicadoresMay: 'ÍNDICES DE DESEMPENHO',
          indicadoresMin: 'Índice de desempenho',
          gestion: 'GESTÃO',
          programacion: 'Programação',
          otros_sistemas: 'Outros Sistemas',
          pavimento_pers: 'Pavimento PERS ',
          flujo_cajaTitle: 'Fluxo de caixa',
          idiomas: 'Línguas ',
          configGeneral: 'Configuração Geral',
          configReports: 'Configuração Relatórios',
          configAudit: 'Auditoria',
          configNovedades : 'Configurações - Ocorrências',
          cargaMasiva : 'Carregamento em massa',
          registroCostos: 'Registro de Custos',
          aplicacionMovil: 'APLICAÇÃO MÓVEL'
        }
      },
      atributos: {
        formTabs: {
          administracion: 'Administração',
          proyecto: 'Projeto',
          inventario: 'Inventário',
          transito: 'Tráfego',
          indicadores: 'Índices de desempenho',
          gestion: 'Gestão',
          reportesQS: 'Relatórios',
          auditoria: "Auditoria ",
          aplicacionMovil: 'Aplicação móvel',
        },
        select: {
          acceso: {
            acceso_completo: 'Acesso Total',
            acceso_parcial: 'Acesso Parcial ',
            sin_acceso: 'Sem acesso',
            visualiza: 'Visualização',
            noDefinido: 'Indefinido'
          },
          tipo_rol: {
            administrativo: 'Administrativo ',
            campo: 'Campo ',
            oficina: 'Escritorio',
          }
        },
        title: {
          proyectos: 'Projetos',
          configMateriales: 'Configurações - Materiais',
          configIndicadores: 'Configurações - Índices de desempenho',
          configDeterioroPers: 'Configurações- Defeitos',
          contactos: 'Contatos',
          segmentos: 'Segmentos (Pista) ',
          elemInventario: 'Elementos Inventário',
          puentesEstructurasEspec: 'Pontes e Estruturas Especiais',
          programacion: 'Programação',
          otrosSistemas: 'Outros Sistemas ',
          pavimentoPers: 'Pavimento PERS ',
          flujoCaja: 'Fluxo de caixa',
          editTitle: 'Attributes',
          showTitle: 'Attributes',
          configNovedades: 'Configurações - Ocorrências',
          complementarios: 'Complementares',
          respuestaEvaluacionNovedad: 'Ocorrências',
          novedades: 'Ocorrências',
          aprobacion: 'Aprovação',
          configGeneral: 'Configuração Geral',
          configReports: 'Configuração Relatórios',
          configAudit: 'Auditoria',
          registroCostos: 'Registro de Custos',
          cargaMasiva: 'Carregamento em massa',
        }
      },
      proyectos: {
        title: {
          coordMinimas: 'Coordenadas mínimas',
          coordMaximas: 'Coordenadas máximas',
          altura: 'Elevação (m.a.n.m)',
          editTitle: 'Editar Projeto',
          showTitle: 'Informações do Projeto'
        },
        tabs: {
          informacionBasica: 'Informações básicas',
          auditoria: 'Auditoria ',
        },
        buttons: {
          anadirKML: 'Adicionar KML'
        }
      },
      rutas: {
        title: {
          inforInvias: 'Informações da rodovia',
          editTitle: 'Editar Rodovia',
          showTitle: 'Informação de Rodovia'
        },
        select: {
          categoria: {
            priOrden: 'Rodovia Radial',
            segOrden: 'Rodovia longitudinal',
            terOrden: 'Rodovia transversal',
          }
        },
        tabs: {
          informacionBasica: 'Informações básicas',
          auditoria: 'Auditoria ',
        }
      },
      unidad_funcionals: {
        title: {
          editTitle: 'Editar Subtrecho',
          showTitle: 'Informação do Subtrecho'
        },
        tabs: {
          informacionBasica: 'Informações básicas',
          auditoria: 'Auditoria ',
        }
      },
      tramos: {
        title: {
          inforTramo: 'Informação da Seção',
          genMasvSeg: 'GERAÇÃO EM MASSA SEG.',
          editTitle: 'Editar Seção',
          showTitle: 'Informação da Seção'
        },
        tabs: {
          informacionBasica: 'Informações básicas',
          auditoria: 'Auditoria',
        }
      },
      segmentos: {
        title: {
          localizacion: 'Localização',
          caracteristicas: 'Características',
          coorIniciales: 'Coordenadas Iniciais',
          coorFinales: 'Coordenadas Finais',
          showTitleRamal: 'Informação de Ramal',
          showTitleSegmento: 'Informação de Segmento',
          listTitle: 'Lista dos Segmentos - Ramales',
          createTitleRamal: 'Criar Ramo',
          createTitleSegmento: 'Criar Segmento',
          createTitle: 'Criar',
          editTitleRamal: 'Editar Ramo',
          editTitleSegmento: 'Editar Segmento',
          editTitle: 'Editar',
          tranSegmento: 'Transformação'
        },
        tabs: {
          informacionBasica: 'Informações básicas',
          auditoria: 'Auditoria',
        },
        select: {
          ramal: 'Ramo',
          segmento: 'Segmento',
          izq: 'Esquerda',
          der: 'Direita'
        },
        messagesVal: {
          abscisaSegmento: 'Erro, o valor inserido deve estar entre ',
          prIngresado:'O km inserido deve ser maior que ',
          absInicial:'A Estaca inicial deve ser maior que',
          rango:'Intervalo inválido',
          prRango:'Intervalo km inválido',
        }
      },
      materials: {
        selectMaterials: {
          adoquinEnArcilla: 'Bloco intertravado de cerâmica',
          amadoquinDeConcreto: 'Bloco intertravado de concreto',
          afirmado: 'Compactação',
          baseAsfaltica: 'Base asfáltica',
          baseDeConcHidraulico: 'Base de concreto hidráulico',
          baseEstOtros: 'Base estabilizada - outros',
          baseEstbAsfalto: 'Base estabilizada com espuma de asfalto',
          baseEstEmulsion: 'Base estabilizada com emulsão asfáltica',
          baseGranular: 'Base granular',
          baseTratada: 'Base tratada com cimento',
          concHidraulico: 'Concreto de cimento Portland',
          lechAsfaltica: 'Lama asfáltica',
          mezAbierCaliente: 'Pré-misturado a quente granulometria aberta',
          mezAbierFrio: 'Pré-misturado a frio granulometria aberta',
          mezAsfalGraCaucho: 'Mistura asfáltica modificada com borracha',
          mezAsfalModPolimeros: 'Mistura asfáltica modificada com polímeros',
          mezDenCaliente: 'Pré-misturado a quente',
          mezDenFrio: 'Pré-misturado a frio',
          mezDrenante: 'Mistura asfáltica drenante',
          microagloCaliente: 'Microrevestimento a quente',
          subBaseAsfalEspumado: 'Sub-base com espuma de asfalto',
          subBaseEstabOtros: 'Sub-base estabilizada - Outros',
          subBaseEstabAsfalto: 'Sub-base estabilizada com betume',
          subBaseEstabCemento: 'Sub-base estabilizada com cimento',
          subBaseEstabGranular: 'Sub-base granular',
          subraEstabCal: 'Subleito estabilizado com cal',
          subraEstabCemento: 'Subleito estabilizado com cimento',
          subraEstabGeomalla: 'Subleito estabilizado com geomalha',
          subraEstabGeotextil: 'Subleito estabilizado com geotextil',
          subraFina: 'Subleito granulometria fina',
          subraGranular: 'Subleito granular',
          subraCemento: 'Solo Cimento',
        },
        selectOption: {
          otros: 'Outro',
          noLigado: 'No Ligado',
          bituminoso: 'Bituminoso',
          cementado: 'Cementado'
        },
        tabs: {
          informacionBasica: 'Informações básicas',
          auditoria: 'Auditoria',
        }
      },
      granulometrias: {
        title: {
          showTitle: 'Informação Granulometria ',
          ediTitle: 'Editar Granulometria',
        },
        select: {
          tipoMaterial: {
            adoquinEnArcilla: 'Bloco intertravado de cerâmica',
            amadoquinDeConcreto: 'Bloco intertravado de concreto',
            afirmado: 'Compactação',
            baseAsfaltica: 'Base asfáltica',
            baseDeConcHidraulico: 'Base de concreto hidráulico',
            baseEstOtros: 'Base estabilizada - outros',
            baseEstbAsfalto: 'Base estabilizada com espuma de asfalto',
            baseEstEmulsion: 'Base estabilizada com emulsão asfáltica',
            baseGranular: 'Base granular',
            baseTratada: 'Base tratada com cimento',
            concHidraulico: 'Concreto de cimento Portland',
            lechAsfaltica: 'Lama asfáltica',
            mezAbierCaliente: 'Pré-misturado a quente granulometria aberta',
            mezAbierFrio: 'Pré-misturado a frio granulometria aberta',
            mezAsfalGraCaucho: 'Mistura asfáltica modificada com borracha',
            mezAsfalModPolimeros: 'Mistura asfáltica modificada com polímeros',
            mezDenCaliente: 'Pré-misturado a quente',
            mezDenFrio: 'Pré-misturado a frio',
            mezDrenante: 'Mistura asfáltica drenante',
            microagloCaliente: 'Microrevestimento a quente',
            subBaseAsfalEspumado: 'Sub-base com espuma de asfalto',
            subBaseEstabOtros: 'Sub-base estabilizada - Outros',
            subBaseEstabAsfalto: 'Sub-base estabilizada com betume',
            subBaseEstabCemento: 'Sub-base estabilizada com cimento',
            subBaseEstabGranular: 'Sub-base granular',
            subraEstabCal: 'Subleito estabilizado com cal',
            subraEstabCemento: 'Subleito estabilizado com cimento',
            subraEstabGeomalla: 'Subleito estabilizado com geomalha',
            subraEstabGeotextil: 'Subleito estabilizado com geotextil',
            subraFina: 'Subleito granulometria fina',
            subraGranular: 'Subleito granular',
            subraCemento: 'Solo Cimento',
          }
        },
        tabs: {
          informacionBasica: 'Informação básica ',
          auditoria: 'Auditoria ',
        }
      },
      generacion_masiva_segmentos: {
        title: {
          validSeg: '¡Validação de segmentos!',
          uniFuncTram: '¡A Subtrecho e a seção já possuem segmentos, eles não podem ser criados em massa!',
          coorIniciales: 'Coordenadas iniciales',
          coorFinales: 'Coordenadas Finales',
          caracteristicas: 'Características'
        },
        button: {
          asigValSegmentos: 'Atribuir valores padrão aos segmentos',
          generSegmentos: 'Gerar Segmentos',
          cancelar: 'Cancelar',
        },
        table: {
          nameColumns: {
            absInicio: 'Estaca Inicial',
            absFinal: 'Estaca Final',
            longitud: 'Extensão(km)',
            prInicio: 'km Inicio',
            prFinal: 'km Final',
            departamento: 'Estado',
            municipio: 'Municipio',
            latitudInicial: 'Latitude Inicial',
            longitudInicial: 'Longitude Inicial',
            alturaInicial: 'Elevação Inicial (m.a.n.m)',
            latitudFinal: 'Latitude Final',
            longitudFinal: 'Longitude Final',
            alturaFinal: 'Elevação Final (m.a.n.m)',
            tipoCalzada: 'Tipo Calzada',
            tipoSegmento: 'Tipo Segmento',
            tipoSuperficie: 'Tipo Superficie',
          },
          nameRows: {
            departamento: 'Departamento',
            municipio: 'Municipio',
            latitudInicial: 'Latitud Inicial',
            longitudInicial: 'Longitud Inicial',
            alturaInicial: 'Elevação Inicial (m.s.n.m)',
            latitudFinal: 'Latitud Final',
            longitudFinal: 'Longitud Final',
            alturaFinal: 'Elevação Final (m.s.n.m)',
            tipoCalzada: 'Tipo de pista',
            tipoSegmento: 'Tipo de Segmento',
            tipoSuperficie: 'Tipo de pavimento',
          }
        }
      },
      transformacion_segmentos:{
        title:{
         validSeg: '¡Validando segmentos!',
         uniFuncTram: '¡O Subtrecho y a seção já possui segmentos, não é possivel criar segmentos em massa!',
         coorIniciales: 'Coordenadas iniciais',
         transformarSegmentos: 'Transformar segmentos',
        },
        button:{
          generSegmentos: 'Gerar Segmentos',
          cancelar: 'Cancelar',
          filtrar: 'Filtragem',
          transformar: 'Transformar',
          confirmar:'Confirme',
          generar:'GERAR',
          agregar:'ADICIONAR',
          remover:'MEXER',
        },
        radioSel: {
          modificarSegmentos: 'Modificar segmentos e suas características',
          modificarTrazado: 'Modificar projeto (Substituir segmentos)',
        },
        table: {
          nameColumns: {
            codigo: 'Código',
            absInicio: 'Estaca Inicial',
            absFinal: 'Estaca Final',
            longitud: 'Extensão',
            longitudCol: 'Extensão (km)',
            prInicio: 'km Inicio',
            prFinal: 'km Final',
            departamento: 'Departamento',
            municipio: 'Municipio',
            latitudInicial: 'Latitude Inicial',
            longitudInicial: 'Longitude Inicial',
            alturaInicial: 'Elevação Inicial',
            latitudFinal: 'Latitude Final',
            longitudFinal: 'Longitude Final',
            alturaFinal: 'Elevação Final',
            tipoCalzada: 'Tipo de pista',
            tipoSegmento: 'Tipo Segmento',
            tipoSuperficie: 'Tipo Superfície',
          },
          nameRows: {
            departamento: 'Estado',
            municipio: 'Municipio',
            latitudInicial: 'Latitude Inicial',
            longitudInicial: 'Longitude Inicial',
            alturaInicial: 'Elevação Inicial (m.s.n.m)',
            latitudFinal: 'Latitude Final',
            longitudFinal: 'Longitude Final',
            alturaFinal: 'Elevação Final (m.s.n.m)',
            tipoCalzada: 'Tipo de pista',
            tipoSegmento: 'Tipo Segmento',
            tipoSuperficie: 'Tipo Superfície',
          }
        }
      },
      reparacion_localizadas: {
        title: {
          editTitle: 'Editar Reparação de PERS',
          showTitle: 'Exibir Reparação de PERS',
        },
        select: {
          tipoReparacion: {
            PFLEX: 'Flexível',
            PRIGD: 'Rígido',
            PART: 'Bloco Intertravado',
            POTR: 'Outro',
          }
        },
        tabs: {
          reparacionPers: 'Reparação PERS',
          auditoria: 'Auditoria'
        }
      },
      tipo_deterioro_pers: {
        title: {
          editTitle: 'Editar Defeitos PERS ',
          showTitle: 'Exibir Defeitos PERS '
        },
        select: {
          tipoSuperficie: {
            PFLEX: 'Flexível',
            PRIGD: 'Rígido ',
            PART: 'Bloco Intertravado',
            POTR: 'Outro',
          },
          nivelSeveridad: {
            1: '1 ',
            3: '3 '
          },
          unidad: {
            m2: 'm² ',
            m: 'm ',
            losa: 'laje '
          }
        },
        tabs: {
          reparacionPers: 'Reparação PERS ',
          auditoria: 'Auditoria '
        }
      },
      contacto_emergencias: {
        title: {
          editTitle: 'Editar informação de contacto',
          showTitle: 'Exibir informação de contacto'
        },
        select: {
          grupo: {
            alcaldia: 'Prefeitura',
            bomberos: 'Bombeiros',
            centroEducativo: 'Centro educativo',
            defensaCivil: 'Defensa civil',
            ejercito: 'Exército',
            emisoraRadial: 'Estação de rádio',
            interventoria: 'Fiscalizaçao',
            maquinaria: 'Maquinaria',
            otros: 'Outros',
            pOB_Administrativa: 'Concessionária - Administrativa',
            pOB_Ambulancia: 'Concessionária - Ambulância ',
            pOB_CarroTaller: 'Concessionária - Carro de suporte mecânico',
            pOB_Gruas: 'Concessionária - Guindaste ',
            pOB_Peajes: 'Concessionária - Pedágios ',
            pOB_RadioOperacion: 'Concessionária - Raio Operação ',
            pOB_SupervisionVial: 'Concessionária - Fiscalização rodoviária',
            policia: 'Polícia',
            serviciosMedicos: 'Serviços médicos',
            talleres: 'Oficina de reparação',
          },
          identificacion: {
            CC: 'RG',
            NIT: 'CPFJ',
            Otro: 'Outro '
          }
        },
        tabs: {
          informacionBasica: 'Informação básica ',
          auditoria: 'Auditoria '
        }
      },
      colores: {
        title: {
          createTitle: 'Criar Diagrama colorimétrico',
          editTitle: 'Editar Cor',
          showTitle: 'Informação Cor',
          punto1: 'Ponto 1: ',
          punto2: 'Ponto 2: ',
          punto3: 'Ponto 3: ',
          punto4: 'Ponto 4: ',
          valorMinX: 'Valor Mínimo x: ',
          valorMinY: 'Valor Mínimo y: ',
          valorMaxX: 'Valor Máximo x: ',
          valorMaxY: 'Valor Máximo y: ',
          valRetroReflec: 'Valores de Retro-Reflectividade',
          rectSuper: 'Reta Superior',
          rectDer: 'Reta Direita',
          rectInfe: 'Reta Inferior',
          rectIzq: 'Reta Esquerda',
          paramAreaColor: 'Parâmetros Área de Cor',
          inforDiagColor: 'Informação Diagrama Colorimétrico',
          color: 'Cores',
          valorMinCump: 'Valor mínimo de cumprimento',
          diagrama: 'Diagrama',
        },
        formTabs: {
          identificacion: 'Identificação',
          auditoria: 'Auditoria'
        }
      },
      proveedors: {
        title: {
          editTitle: 'Editar Fornecedor',
          showTitle: 'Informação Fornecedor'
        },
        tabs: {
          proyecto: 'PROJETO',
          informacionProveedor: 'Informação básica',
          calificacion: 'QUALIFICAÇÃO',
          auditoria: 'Auditoria'
        },
        select: {
          identificacion: {
            CC: 'RG',
            NIT: 'CPFJ',
            Otro: 'Otro'
          },
        },
        boolean: {
          mantenimiento: 'Manutenção',
          suministros: 'Fornecimentos',
        },
        buttons: {
          calificarProveedor: 'Qualificar fornecedor'
        }
      },
      calificacion_proveedors: {
        title: {
          qualifyTitle: 'Qualificar fornecedor',
        },
        select: {
          calificacion: {
            1: '1 ',
            2: '2 ',
            3: '3 ',
            4: '4 ',
            5: '5 '
          }
        },
        buttons: {
          save: 'SALVAR ',
          saveAndEnd: 'Salvar e avaliar outro fornecedor'
        },
        span: {
          descripcionCalificacion: 'Avalie o fornecedor numa escala de 1 a 5, onde 5 significa "muito alto" e 1 significa "muito baixo"'
        }
      },
      servicios_publicos: {
        title: {
          editTitle: 'Editar Serviço Público ',
          showTitle: 'Informação Serviço Público '
        },
        select: {
          grupo: {
            acueducto: 'Empresa de saneamento ',
            aseo: 'Companhia de limpieza',
            energia: 'Companhia de Energia ',
            gasNatural: 'Gás natural ',
            otros: 'Outros ',
            telecomunicaciones: 'Telecomunicações '
          },
          identificacion: {
            CC: 'RG',
            NIT: 'CPFJ',
            Otro: 'Outro',
          }
        },
        tabs: {
          informacionBasica: 'Informação básica ',
          auditoria: 'Auditoria '
        }
      },
      parametros_evaluacion: {
        select: {
          indicadores: {
            indicadoresMessage: 'Índices de desempenho aplicáveis a: ',
            todosParam: "Todos os Parâmetros",
            indiPavFlex: "Índices de desempenho únicos para pavimento flexível",
            indiPavRig: "Índices de desempenho únicos para Pavimento Rígido",
            indiPavRigFlex: "Índices de desempenho únicos para Pavimento Rigido e Flexível",
            indiPuentesEstr: "Índices de desempenho únicos para Pontes e Estruturas",
            indiTuneles: "Índices de desempenho únicos para Túneis",
            indiOperac: "Índices de funcionamiento",
          }
        },
        title: {
          grupoParamCrea: 'Já tem uma série de parâmetros criados',
          editTitle: 'Editar parâmetros de avaliação',
          showTitle: 'Exibir parâmetros de avaliação'
        },
        table: {
          titleColumns: {
            frecuenciaMedicion:'Frequência de medição (meses).',
            tiempoCorreccion:'Tempo de correção (meses).',
            valormaxPuntual:'Valor máximo do ponto (mm/m).',
            valormaxMedio:'Valor máximo médio (mm/m).',
            indicador: 'Índice de desempenho',
            aplica: 'Aplica',
            valorAlerta: 'Valor de alerta',
            datosMinmodelacion: 'Dados mínimos para modelagem',
            tiempoGenerarAlerta: 'Quanto tempo antes de gerar um alerta de medição?',
          },
          si: 'Sim',
          no: 'Não',
        }
      },
      elementos: {
        title: {
          listTitle: 'Mapa geral',
        },
        tilteFiltros: {
          filtros: 'Filtros',
          calzadas: 'Pistas',
          sistemasContencion: 'Elementos de Segurança rodoviária',
          drenajes: 'Drenagens',
          senalizacion: 'Sinalização',
          alrededores: 'Faixa de dominio',
          puentesEstrucEsp: 'Pontes e estruturas especiais',
          otrosElementos: 'Outros Elementos',
          redes: 'Redes'
        },
        titleIndicadores: {
          rangosColores: 'Intervalos de Cores',
          iri: 'IRI',
          umbrales: 'Limites',
          colores: 'Cores',
          visualizacion: 'Visualização',
          pantallaConfiguración: 'Pantalla de configuração'
        },
        checkboxs: {
          flexible_detalles: 'Segmento Flexível',
          rigido_detalles: 'Segmento Rígido',
          adoquin_detalles: 'Segmento de bloco intertravado ',
          otro_detalles: 'Segmento Outros',
          seguridads: 'Elementos de Segurança rodoviária',
          elemento_drenajes: 'Drenagens',
          elemento_senalizacions: 'Sinalização',
          elemento_alrededors: 'Faixa de dominio',
          tuneles: 'Túneis',
          elemento_contencions: 'Terrapleno',
          elemento_pontons: 'Pontilhão',
          puentes: 'Pontes',
          otros_elementos: 'Outros Elementos',
          elemento_reds: 'Redes',
          Otro: 'Outro',
         
        },
        select: {
          tipoMapa: {
            1: 'Índices de desempenho',
            2: 'Inventário',
          },
          numIntervalos: {
            1: '1',
            2: '2',
            3: '3',
            4: '4',
            5: '5',
            6: '6',
            7: '7',
            8: '8',
          },
        },
      },
      flexible_detalles: {
        title: {
          editTitle: 'Editar Segmento Flexível',
          showTitle: 'Informação Segmento Flexível'
        },
        select: {
          sentido: {
            'NORTEName': 'Norte',
            NORTEId: 'NORTE',
            'SURName': 'Sul',
            SURId: 'SUR',
            'ESTEName': 'Leste',
            ESTEId: 'ESTE',
            'OESTEName': 'Oeste',
            OESTEId: 'OESTE'
          },
          estadoDetalles: {
            Activo: 'Ativo',
            Inactivo: 'Inativo'
          },
          nivelTrafico: {
            NT1: 'NT1',
            NT2: 'NT2',
            NT3: 'NT3',
          },
          geometriaTipoBerma: {
            bermaCuneta: 'Acostamento sarjeta',
            flexible: 'Flexível',
            inexistente: 'Inexistente',
            rigida: 'Rígida'
          },
          geometriaCondicionBerma: {
            bueno: 'Bom',
            regular: 'Regular',
            deficiente: 'Ruim'
          },
          geometriaSeparador: {
            Si: 'Sim',
            No: 'Não'
          },
          geometriaCondicionesGeometrica: {
            bueno: 'Bom',
            regular: 'Regular',
            deficiente: 'Ruim'
          },
          geometriaAlineamiento: {
            curvo: 'Curvo',
            predominantementeCurvo: 'Predominantemente Curvo',
            predominantementeRecto: 'Predominantemente Plano',
            recto: 'Reto'
          },
          geometriaTipoTerreno: {
            plano: 'Plano',
            ondulado: 'Ondulado',
            montañoso: 'Montanhoso',
            escarpado: 'Escarpado'
          }
        },
        tabs: {
          detalles: 'Detalhes',
          geometria: 'Geometria',
          estructura: 'Estrutura',
          fotografias: 'FOTOGRAFIAS',
          informacionBasica: 'Informação Básica',
          auditoria: 'Auditoria'
        },
        messagesVal: {
          value20: "Deve ser maior 'ESAL (Proyeção 10 anos)'",
          valNumDecimals: 'O valor inserido só pode ter 2 casas decimais',
          valNumDecimal: 'O valor inserido só pode ter 1 casa decimal',
          valNumEntero: 'O valor inserido deve ser inteiro',
          valLongitudMin0: 'Deve ser pelo menos 0',
          valLongitudMax5: 'Deve ser 5 ou menos',
          valLongitudMax20: 'Deve ser 20 ou menos',
          valMinCapa: 'Mínimo 1 camada',
          messageIniCapa: 'A espessura na camada ',
          messageFinCapa: ' (A última camada), deve ser 0',
        }
      },
      rigido_detalles: {
        title: {
          editTitle: 'Editar Segmento Rígido',
          showTitle: 'Informação Segmento Rígido'
        },
        messagesVal: {
          value20: "Deve ser maior 'ESAL (Proyeção 10 anos)'",
          valNumDecimals: 'O valor inserido só pode ter 2 casas decimais',
          valNumDecimal: 'O valor inserido só pode ter 1 casa decimal',
          valNumEntero: 'O valor inserido deve ser inteiro',
          valLongitudMin0: 'Deve ser pelo menos 0',
          valLongitudMax5: 'Deve ser 5 ou menos',
          valLongitudMax20: 'Deve ser 20 ou menos',
          valMinCapa: 'Mínimo 1 camada',
          messageIniCapa: 'A espessura na camada ',
          messageFinCapa: ' (A última camada), deve ser 0',
        },
        select: {
          estadoDetalles: {
            Activo: 'Ativo',
            Inactivo: 'Inativo'
          },
          nivelTrafico: {
            NT1: 'NT1',
            NT2: 'NT2',
            NT3: 'NT3',
          },
          geometriaSeparador: {
            Si: 'Sim',
            No: 'Não',
            SiId: 'Si',
            NoId: 'No'
          },
          sentido: {
            'NORTEName': 'Norte',
            NORTEId: 'NORTE',
            'SURName': 'Sul',
            SURId: 'SUL',
            'ESTEName': 'Leste',
            ESTEId: 'LESTE',
            'OESTEName': 'Oeste',
            OESTEId: 'OESTE'
          },
          geometriaTipoBerma: {
            bermaCuneta: 'Acostamento sarjeta',
            flexible: 'Flexível',
            inexistente: 'Inexistente',
            rigida: 'Rígida'
          },
          geometriaCondicionBerma: {
            bueno: 'Bom',
            regular: 'Regular',
            deficiente: 'Ruim'
          },
          geometriaCondicionesGeometrica: {
            bueno: 'Bom',
            regular: 'Regular',
            deficiente: 'Ruim'
          },
          geometriaAlineamiento: {
            curvo: 'Curvo',
            predominantementeCurvo: 'Predominantemente Curvo',
            predominantementeRecto: 'Predominantemente Reto',
            recto: 'Reto'
          },
          geometriaTipoTerreno: {
            plano: 'Plano',
            ondulado: 'Ondulado',
            montañoso: 'Montanhoso',
            escarpado: 'Acidentado'
          }
        },
        tabs: {
          detalles: 'Detalhes',
          geometria: 'Geometria',
          estructura: 'Estrutura',
          fotografias: 'FOTOGRAFIAS',
          informacionBasica: 'Informação Básica',
          auditoria: 'Auditoria'
        },
      },
      adoquin_detalles: {
        title: {
          editTitle: 'Editar Segmento bloco intertravado',
          showTitle: 'Informação Segmento bloco intertravado'
        },
        messagesVal: {
          value20: "Deve ser maior 'ESAL (Proyeção 10 anos)'",
          valNumDecimals: 'O valor inserido só pode ter 2 casas decimais',
          valNumDecimal: 'O valor inserido só pode ter 1 casa decimal',
          valNumEntero: 'O valor inserido deve ser inteiro',
          valLongitudMin0: 'Deve ser pelo menos 0',
          valLongitudMax5: 'Deve ser 5 ou menos',
          valLongitudMax20: 'Deve ser 20 ou menos',
          valMinCapa: 'Mínimo 1 camada',
          messageIniCapa: 'A espessura na camada ',
          messageFinCapa: ' (A última camada), deve ser 0',
          maxDosDecimales: 'Máximo 2 casas decimais',
        },
        select: {
          estadoDetalles: {
            Activo: 'Ativo',
            Inactivo: 'Inativo'
          },
          nivelTrafico: {
            NT1: 'NT1',
            NT2: 'NT2',
            NT3: 'NT3',
          },
          geometriaSeparador: {
            Si: 'Sim',
            No: 'Não'
          },
          sentido: {
            'NORTEName': 'Norte',
            NORTEId: 'NORTE',
            'SURName': 'Sul',
            SURId: 'SUL',
            'ESTEName': 'Leste',
            ESTEId: 'LESTE',
            'OESTEName': 'Oeste',
            OESTEId: 'OESTE'
          },
          geometriaTipoBerma: {
            bermaCuneta: 'Acostamento sarjeta',
            flexible: 'Flexível',
            inexistente: 'Inexistente',
            rigida: 'Rígida'
          },
          geometriaCondicionBerma: {
            bueno: 'Bom',
            regular: 'Regular',
            deficiente: 'Ruim'
          },
          geometriaCondicionesGeometrica: {
            bueno: 'Bom',
            regular: 'Regular',
            deficiente: 'Deficiente'
          },
          geometriaAlineamiento: {
            curvo: 'Curvo',
            predominantementeCurvo: 'Predominantemente Curvo',
            predominantementeRecto: 'Predominantemente Reto',
            recto: 'Reto'
          },
          geometriaTipoTerreno: {
            plano: 'Plano',
            ondulado: 'Ondulado',
            montañoso: 'Montanhoso',
            escarpado: 'Acidentado'
          }
        },
        tabs: {
          detalles: 'Detalhes',
          geometria: 'Geometria',
          estructura: 'Estrutura',
          fotografias: 'FOTOGRAFIAS',
          informacionBasica: 'Informação Básica',
          auditoria: 'Auditoria'
        },
        boolean: {
          elementoMantenimiento: '¿Incluir este elemento no plano de manutenção?'
        },
      },
      otro_detalles: {
        title: {
          editTitle: 'Editar Segmento Outro',
          showTitle: 'Informação Segmento Outro'
        },
        select: {
          estadoDetalles: {
            Activo: 'Ativo',
            Inactivo: 'Inativo'
          },
          nivelTrafico: {
            NT1: 'NT1',
            NT2: 'NT2',
            NT3: 'NT3',
          },
          geometriaSeparador: {
            Si: 'Sim',
            No: 'Não'
          },
          sentido: {
            NORTEName: 'Norte',
            NORTEId: 'NORTE',
            SURName: 'Sul',
            SURId: 'SUL',
            ESTEName: 'Leste',
            ESTEId: 'LESTE',
            OESTEName: 'Oeste',
            OESTEId: 'OESTE'
          },
          geometriaTipoBerma: {
            bermaCuneta: 'Acostamento sarjeta',
            flexible: 'Flexível',
            inexistente: 'Inexistente',
            rigida: 'Rígida'
          },
          geometriaCondicionBerma: {
            bueno: 'Bom',
            regular: 'Regular',
            deficiente: 'Ruim'
          },
          geometriaCondicionesGeometrica: {
            bueno: 'Bom',
            regular: 'Regular',
            deficiente: 'Deficiente'
          },
          geometriaAlineamiento: {
            curvo: 'Curvo',
            predominantementeCurvo: 'Predominantemente Curvo',
            predominantementeRecto: 'Predominantemente Reto',
            recto: 'Reto'
          },
          geometriaTipoTerreno: {
            plano: 'Plano',
            ondulado: 'Ondulado',
            montañoso: 'Montanhoso',
            escarpado: 'Acidentado'
          }
        },
        messagesVal: {
          value20: "Deve ser maior 'ESAL (Proyeção 10 anos)'",
          valNumDecimals: 'O valor inserido só pode ter 2 casas decimais',
          valNumDecimal: 'O valor inserido só pode ter 1 casa decimal',
          valNumEntero: 'O valor inserido deve ser inteiro',
          valLongitudMin0: 'Deve ser pelo menos 0',
          valLongitudMax5: 'Deve ser 5 ou menos',
          valLongitudMax20: 'Deve ser 20 ou menos',
          valMinCapa: 'Mínimo 1 camada',
          messageIniCapa: 'A espessura na camada ',
          messageFinCapa: ' (A última camada), deve ser 0',
          maxDosDecimales: 'Máximo 2 casas decimais',
          sinDecimales: 'Sem casas decimais.'
        },
        boolean: {
          elementoMantenimiento: '¿Incluir este elemento no plano de manutenção?'
        },
        tabs: {
          detalles: 'Detalhes',
          geometria: 'Geometria',
          estructura: 'Estrutura',
          fotografias: 'FOTOGRAFÍAS',
          informacionBasica: 'Informação Básica',
          auditoria: 'Auditoria'
        },
      },
      seguridads: {
        title: {
          showTitle: "Lista de elementos de segurança rodoviária",
          editTitle: 'Editar elementos de segurança rodoviária',
          showTitle: 'Informação elementos de segurança rodoviária'
        },
        tabs: {
          tabsTitle: {
            identificacion: 'Identificação',
            caracteristicas: 'Características',
            detalles: 'Detalhes',
            fotografias: 'FOTOGRAFIAS',
            auditoria: 'Auditoria'
          },
        },
        select: {
          tipodeDefensa: {
            termIniAbatidaEsviadaTerFinAbatida: 'Terminal de entrada abatida e defletida e Terminal de saída abatida',
            termIniAbatidaEsviadaTerFinAbatidaEsviada: 'Terminal de entrada abatida e defletida e Terminal de saída abatida e defletida',
            termIniAbatEsviadaTermFinEnterradaTalud: 'Terminal de entrada abatida e defletida e Terminal de saída ancoragem em talude',
            termIniAbatEsviadaTermFinColaPez: 'Terminal de entrada abatido e defletida e Terminal de saída em rabo de peixe',
            termIniAbatTerminalFinAbatida: 'Terminal de entrada abatida e Terminal de saída abatida',
            termIniAbatTerminalFinAbatidaEsviada: 'Terminal de entrada abatida e Terminal de saída abatida e defletida',
            termIniAbatTerminalFinColaPez: 'Terminal de entrada abatida e Terminal de saída em rabo de peixe',
            termIniAbatTerminalFinSalidaAerea: 'Terminal de entrada abatida e Terminal de saída aérea',
            termIniAbatTerminalFinEnterradaTalud: 'Terminal de entrada abatida e Terminal de saída ancoragem em talude',
            termIniAbatTerminalFinIncrustadaObraArte: 'Terminal de entrada abatida e Terminal de saída na OAE engastado',
            termIniColaPezTermiFinAbatidaEsviada: 'Terminal de entrada em rabo de peixe e Terminal de saída abatida e defletida',
            termIniColaPezTermiFinAbatida: 'Terminal de entrada em rabo de peixe e Terminal de saída abatida',
            termIniColaPezTermiFinColaPez: 'Terminal de entrada em rabo de peixe e Terminal de saída em rabo de peixe',
            termIniColaPezTermiFinEnterradaTalud: 'Terminal de entrada em rabo de peixe e Terminal de saída ancoragem em talude',
            termIniAmortiguadorImpacTermFinAbatida: 'Terminal de entrada com absorvedor de energia e Terminal de saída abatida',
            termIniAmortiguadorImpacTermFinColaPez: 'Terminal de entrada com absorvedor de energia e Terminal de saída em rabo de peixe',
            termIniAmortiguadorImpacTermFinSalAerea: 'Terminal de entrada com absorvedor de energia e Terminal de saída con salida aérea',
            termIniAmortiguadorImpacTermFinEnterradaTalud: 'Terminal de entrada com absorvedor de energia e Terminal de saída ancoragem em talude',
            termIniAmortiguadorImpacTermFinIncrusObraArte: 'Terminal de entrada com absorvedor de energia e Terminal de saída na OAE engastado',
            termIniEnterrTaludTermFinAbatEsviada: 'Terminal de entrada ancoragem em talude e Terminal de saída abatida e defletida',
            termIniEnterrTaludTermFinAbatida: 'Terminal de entrada ancoragem em talude e Terminal de saída abatida',
            termIniEnterrTaludTermFinColaPez: 'Terminal de entrada ancoragem em talude e Terminal de saída em rabo de peixe',
            termIniEnterrTaludTermFinEnterrTalud: 'Terminal de entrada ancoragem em talude e Terminal de saída ancoragem em talude',
            termIniEsviadaTermFinAbatida: 'Terminal de entrada defletida e Terminal de saída abatida',
            termIniEsviadaTermFinSalidaAerea: 'Terminal de entrada defletida e Terminal de saída con salida aérea',
            termIniEsviadaTermFinEnterradaTalud: 'Terminal de entrada defletida e Terminal de saída ancoragem em talude',
            termIniEsviadaTermFinIncrustadaObraArte: 'Terminal de entrada defletida e Terminal de saída na OAE engastado',
            termIniIncrustObraArteTermFinalAbatida: 'Terminal de entrada na OAE engastado e Terminal de saída abatida',
            termIniIncrustObraArteTermFinalSalidaAerea: 'Terminal de entrada na OAE engastado e Terminal de saída con salida aérea',
            termIniIncrustObraArteTermFinalEnterradaTalud: 'Terminal de entrada na OAE engastado e Terminal de saída ancoragem em talude',
            termIniIncrustObraArteTermFinalEsviada: 'Terminal de entrada na OAE engastado e Terminal de saída defletida',
          },
          margen: {
            derechaName: 'Direita',
            derechaId: 'Direita',
            izquierdaName: 'Esquerda',
            izquierdaId: 'Esquerda',
            centroName: 'Centro',
            centroId: 'Centro',
          },
          sentido: {
            norteName: 'Norte',
            norteId: 'NORTE',
            surName: 'Sul',
            surId: 'SUL',
            esteName: 'Leste',
            esteId: 'LESTE',
            oesteName: 'Oeste',
            oesteId: 'OESTE',
          },
          material: {
            barreraJerseyPlastica: 'Barreira jersey plástica',
            barreraJerseyPlasticaId: 'Barrera jersey plástica',
            concreto: 'Concreto',
            malla: 'Malha',
            metalica: 'Metálica',
            metalicaId: 'Metálica',
            plastica: 'Plástica',
            plasticaId: 'Plástica',
            polisombra: 'Tela de proteção em polipropileno',
            prefabricado: 'Pré-moldado',
          },
          perfil: {
            perfilC: 'Perfil em C',
            perfilU: 'Perfil em U',
            perfilW_DobleOnda: 'Perfil W - Dupla onda',
            simple: 'Simples'
          },
          posteMetalico: {
            siName: 'Sim',
            siId: 'SIM',
            noName: 'NÃO',
            noId: 'Não'
          },
          tope: {
            horizontal: 'Horizontal',
            vertical: 'Vertical'
          },
          seccion: {
            circular: 'Circular',
            rectangular: 'Retangular',
          },
          condicionElemento: {
            existente: 'Existente',
            reemplazo: 'Substituição',
            nuevo: 'Criar',
          },
          motivoReemplazo: {
            accidente: 'Acidente',
            instalacion: 'Instalação',
            instalacionId: 'Instalação',
            reemplazoProgramado: 'Substituição planejada',
            vandalismo: 'Vandalismo',
            otro: 'Outro',
          },
          estado: {
            activo: 'Ativo',
            inactivo: 'Inativo'
          },
          tipoElementoDesc: {
            barreraConcretoPendSimple: 'Barreira de concreto',
            barreraPerfilF: 'Barreira de perfil F',
            barreraJersey: 'Barreira Jersey',
            bordillo: 'meio-fio',
            captafaros: 'Refletor',
            cerramientoProvisional: 'Fechamento provisório',
            defensa: 'Defensa',
            pretilesPuentes: 'Parapeitos de pontes',
            topeVehicular: 'Redutores de velocidade',
          }
        },
        subTitle: {
          localizacion: 'Localização',
          coordenadasIniciales: 'Coordenadas Iniciais',
          coordenadasFinales: 'Coordenadas Finais',
        },
        boolean: {
          mantenimiento: '¿Incluir este elemento no plano de manutenção?'
        },
        messageWarning: {
          observaciones: 'Máximo 200 caracteres.'
        }
      },
      elemento_drenajes: {
        title: {
          editTitle: 'Editar Elemento Drenagem',
          showTitle: 'Informação Elemento Drenagem',
          listTitle: 'Lista Elementos Drenagens'
        },
        tabs: {
          tabsTitle: {
            identificacion: 'Identificação',
            caracteristicas: 'Caraterísticas',
            detalles: 'Detalhes',
            fotografias: 'FOTOGRAFIAS',
            auditoria: 'Auditoria'
          },
        },
        select: {
          sentido: {
            NORTEName: 'Norte',
            NORTEId: 'NORTE',
            SURName: 'Sul',
            SURId: 'SUL',
            ESTEName: 'Leste',
            ESTEId: 'LESTE',
            OESTEName: 'Oeste',
            OESTEId: 'OESTE'
          },
          margen: {
            derecha: 'Direita',
            izquierda: 'Esquerda',
            centro:'Centro',
          },
          tipoCuneta: {
            bajoAccesosPrediosInterseccionVias: 'Baixo Acessos a Instalações ou Interseção de rodovias',
            enZonasUrbanasSemiurbanas: 'Em áreas Urbanas e Semi-urbanas',
            tipoCarretera: 'Tipo de rodovia',
          },
          material: {
            acero: 'Aço',
            concreto: 'Concreto',
            concretoAcero: 'Concreto e aço',
            mamposteria: 'Alvenaria',
            polipropileno:'Polipropileno',
            cobre:'Cobre',
            otro: 'Outro',
            PEAD: 'PEAD',
            PVC: 'PVC',
            HDPE:'HDPE',
          },
          seccion: {
            circular: 'Circular',
            cuadrada: 'Quadrada',
            rectangular: 'Retangular',
            trapezoidal: 'Trapezoidal',
            triangular: 'Triangular',
            hexagonal:'Hexagonal',
            perfilH:'Perfil H',
            otro:'Outro',
          },
          tipoDelElemento: {
            AlcantarDobleconcreto: 'Bueiro duplo de concreto',
            AlcantarDoblePVC: 'Bueiro duplo de PVC',
            AlcantarDobleMetalica: 'Bueiro duplo metálico',
            AlcantarSimpleConcreto: 'Bueiro simples de concreto',
            AlcantarSimplePVC: 'Bueiro simples de PVC',
            AlcantarSimpleMetalica: 'Bueiro simples metálico',
            AlcantarTripleConcreto: 'Bueiro triplo de concreto',
            AlcantarTriplePVC: 'Bueiro triplo de PVC',
            AlcantarTripleMetalica: 'Bueiro triplo metálico',
            boxCulvertDobleConcreto: 'Box culvert duplo de concreto',
            boxCulvertEspecial: 'Box culvert especial',
            boxCulvertSimpleConcreto: 'Box culvert simples de concreto',
            boxCulvertTripleConcreto: 'Box culvert triplo de concreto',
          },
          tipoPlanta: {
            Normal: 'Normal',
            Esconso: 'Esconso',
          },
          direccion: {
            Jusante: 'Jusante',
            Montante: 'Montante',
          },
          estado: {
            existente: 'Existente',
            reemplazo: 'Substituição',
            nuevo: 'Criar',
          },
          motivoReemplazo: {
            accidente: 'Acidente',
            otro: 'Outro',
            reemplazoProgramado: 'Substituição planejada',
            vandalismo: 'Vandalismo',
          },
          estados: {
            Activo: 'Ativo',
            Inactivo: 'Inativo',
          }
        },
        subTitle: {
          localizacion: 'Localização',
          coordenadasIniciales: 'Coordenadas Iniciais',
          coordenadasFinales: 'Coordenadas Finais',
        },
        boolean: {
          mantenimiento: '¿Incluir este elemento no plano de manutenção?'
        },
      },
      elemento_senalizacions: {
        title: {
          editTitle: 'Editar Elemento Sinal',
          showTitle: 'Informação Elemento Sinal',
          listTitle: ''
        },
        tabs: {
          tabsTitle: {
            identificacion: 'Identificação',
            caracteristicas: 'Características',
            detalles: 'Detalhes',
            fotografias: 'FOTOGRAFÍAS',
            auditoria: 'Auditoria'
          },
        },
        messagesVal: {
          valNumEntero: 'Deve ser um número inteiro',
          rangoLongitud: 'O intervalo deve ser entre '
        },
        select: {
          margen: {
            derecha: 'Direita',
            izquierda: 'Esquerda',
            centro: 'Centro'
          },
          sentido: {
            NORTEName: 'Norte',
            NORTEId: 'NORTE',
            SURName: 'Sul',
            SURId: 'SUL',
            ESTEName: 'Leste',
            ESTEId: 'LESTE',
            OESTEName: 'Oeste',
            OESTEId: 'OESTE'
          },
          choice: {
            amarillo: 'Amarelo',
            amariFluorescente: 'Amarelo fluorescente',
            amariVerdeFluores: 'Amarelo-verde fluorescente',
            azul: 'Azul',
            blanco: 'Branco',
            marrón: 'Marrom',
            naranja: 'Laranja',
            naranjaFluorescente: 'Laranja fluorescente',
            rojo: 'Vermelho',
            verde: 'Verde',
            noAplica: 'Não aplicável',
          },
          choicess: {
            amarillo: 'Amarelo',
            amariFluorescente: 'Amarelo fluorescente',
            amariVerdeFluores: 'Amarelo-verde fluorescente',
            azul: 'Azul',
            blanco: 'Branco',
            marrón: 'Marrom',
            naranja: 'Laranja',
            naranjaFluorescente: 'Laranja fluorescente',
            negro: 'Preto',
            rojo: 'Vermelho',
            verde: 'Verde',
            noAplica: 'Não aplicável',
          },
          material: {
            aceroGalvanizado: 'Aço galvanizado',
            aluminio: 'Aluminio',
            fibra: 'Fibra',
            cintasPreformadas: 'Pré-formados',
            pinturas: 'Tintas',
            plasticosFrio: 'MMA (Plástico aplicação a frio)',
            termoplasticas: 'Termoplásticos',
          },
          tipoPapel: {
            altaAmarillo: 'Alta amarelo',
            altaBlanco: 'Alta branco',
            diamanteAmariVerdeFluores: 'Diamante Amarelo-verde fluorescente',
            diamanteAzul: 'Diamante azul',
            diamanteBlanco: 'Diamante branco',
            diamanteMarron: 'Diamante marrom',
            diamanteRojo: 'Diamante vermelho',
            diamanteVerde: 'Diamante verde',
            ingenieriaAmarillo: 'Amarelo- Engenharia',
            ingenieriaBlanco: 'Branco- Engenharia',
            t11Amarillo700: 'T11 amarelo 700',
          },
          tipoTablero: {
            circular: 'Circular',
            cuadrada: 'Quadrada',
            rectangular: 'Retangular',
            otro: 'Outro',
          },
          dimensionTablero: {
            x80: '80 x 80 cm',
            x100: '100 x 100 cm',
            x120: '120 x 120 cm',
            x140: '140 x 140 cm',
            Otra: 'Outra'
          },
          estadoTablero: {
            Afectada: 'Danificado',
            Buena: 'Bom',
          },
          tipoSoporte: {
            Bandera: 'Semipórtico',
            Pasacalle: 'Pórtico',
            Vastago: 'Coluna simples o dupla',
          },
          estadoSoporte: {
            Afectada: 'Danificado',
            Buena: 'Bom',
          },
          materialSoporte: {
            Acero: 'Aço',
            Madera: 'Madeira'
          },
          colorOtrosElem: {
            Amarilla: 'Amarelo',
            Blanca: 'Branco',
          },
          estado: {
            existente: 'Existente',
            reemplazo: 'Substituição',
            nuevo: 'Criar',
            bueno: 'Bom',
            malo: 'Ruim',
            regular: 'Regular',
          },
          estados: {
            Activo: 'Ativo',
            Inactivo: 'Inativo',
          },
          motivoReemplazo: {
            accidente: 'Acidente',
            instalacion: 'Instalação',
            otro: 'Outro',
            reemplazoProgramado: 'Substituição planejada',
            vandalismo: 'Vandalismo',
          },
          condicion: {
            instalacion: 'Instalação',
            mantenimiento: 'Manutenção',
          }
        },
        subTitle: {
          localizacion: 'Localização',
          coordenadas: 'Coordenadas',
          coordenadasIniciales: 'Coordenadas Iniciais',
          coordenadasFinales: 'Coordenadas Finais',
        },
        messageWarning: {
          warningMensajeVariable: 'Módulo não disponível para o tipo de sinal "Mensagem variável"',
          altura: 'O valor inserido deve ser igual ou maior que 0',
          numEntero: 'O valor inserido deve ser um número inteiro',
          rangolng: 'O intervalo inserido deve estar entre '
        },
        boolean: {
          mantenimiento: '¿Incluir este elemento no plano de manutenção?'
        },
      },
      elemento_alrededors: {
        title: {
          editTitle: 'Editar Elemento Arredor',
          showTitle: 'Informação Elemento Arredor',
          listTitle: ''
        },
        tabs: {
          tabsTitle: {
            identificacion: 'Instalação',
            caracteristicas: 'Características',
            detalles: 'Detalhes',
            fotografias: 'FOTOGRAFÍAS',
            auditoria: 'Auditoria'
          },
        },
        select: {
          margen: {
            derecha: 'Direita',
            izquierda: 'Esquerda',
            centro: 'Centro'
          },
          sentido: {
            NORTEName: 'Norte',
            NORTEId: 'NORTE',
            SURName: 'Sul',
            SURId: 'SUL',
            ESTEName: 'Leste',
            ESTEId: 'LESTE',
            OESTEName: 'Oeste',
            OESTEId: 'OESTE'
          },
          tipoServicio: {
            barraca: 'Barraca',
            centroEducativo: 'Centro educativo',
            estaciónCombustible: 'Posto de combustível',
            hospitalCentroSalud: 'Hospital/Centro de saúde',
            hotel: 'Hotel',
            montallantas: 'Borracharia',
            restaurante: 'Restaurante',
            tallerAutomotriz: 'Oficina de mecánica',
            otros: 'Outros',
            /* Margen */
            concretoAdoquin: 'Concreto/Bloco Intertravado',
            noAplica: 'Não aplicável',
            talud: 'Talude',
            vegetación: 'Vegetação',
            otro: 'Outro',
            /* Obstaculo */
            cercado: 'Cercado',
            construccion: 'Construição',
            otros: 'Outros',
            rio: 'Río',
            zonaVerde: 'Zona verde',
            /* Zona Inestable */
            caida: 'Quedas',
            deslizamiento: 'Escorregamentos',
            flujos: 'Fluxos ou corridas',
            movimientoComplejo: 'Complexos',
            volcamiento: 'Tombamentos',
            /* Ocupación */
            Cerca: 'Cerca',
            Edificacion: 'Edificação',
            Outdoor: 'Outdoor',
            Placa: 'Placa',
            /* Accesos */
            Municipal: 'Municipal',
            Particular: 'Particular',
          },
          tipoAcceso: {
            conCarrilAcelDesa: 'Com faixa de aceleração/desaceleração',
            sinCarril: 'Sem faixa'
          },
          estacionamiento: {
            enFachada: 'Em fachada',
            enVia: 'Na rua',
            interno: 'Interno',
          },
          identificacion: {
            Regular: 'Regular',
            Irregular: 'Irregular',
          },
          riesgo: {
            baja: 'Baixo',
            media: 'Médio',
            alta: 'Alto',
            muyAlta: 'Muito Alto',
          },
          seccion: {
            taludSuperior: 'Talude superior',
            taludInferior: 'Talude inferior',
          },
          tipoTerraplen: {
            Corte: 'Corte',
            Relleno: 'Relleno'
          },
          tipoObra: {
            anclajesRoca: 'Grampos de rochas',
            cercadosRedesContencion: 'Cercas e redes de contenção',
            EscudoContraCaidaRocas: 'Barreira de proteção contra queda de rochas',
            muroAnclado: 'Muro ancorado',
            muroConcretoReforzado: 'muro de reforçado de concreto',
            muroEncofrado: 'Muro de cofragem',
            muroGaviones: 'Muro de Gabião',
            muroGravedadConcreto: 'Muro de gravedade de concreto',
            muroTierraReforzada: 'Aterro reforçado',
            murosContencion: 'Muro de contenção',
            pantallasAncladas: 'Telas ancoradas',
            pilotesCaissons: 'Tubuloes e estacas',
            reconformacion: 'Reconformação',
            revestimientoFlexible: 'Revestimento flexível',
            revestimientoVegetal: 'Cobertura vegetal',
            sueloEmpernado: 'Solo grampeado',
            otros: 'Outros',

          },
          estado: {
            Activo: 'Ativo',
            Inactivo: 'Inativo',
            Existente: 'Existente',
            Reemplazo: 'Substituição',
            Nuevo: 'Criar',
          },
          condicion: {
            accidente: 'Acidente',
            instalacion: 'Instalação',
            otro: 'Outro',
            reemplazoProgramado: 'Substituição planejada',
            vandalismo: 'Vandalismo',
          },
          estados: {
            Activo: 'Ativo',
            Inactivo: 'Inativo',
          }
        },
        subTitle: {
          localizacion: 'Localização',
          coordenadas: 'Coordenadas',
          coordenadasIniciales: 'Coordenadas Iniciais',
          coordenadasFinales: 'Coordenadas Finais',
          TramosPendVariable: 'Seções de inclinação variável',
        },
        messagesVal: {
          valNumNegativos: 'O valor ingresado não pode ser negativo',
          valNumEntero: 'O valor ingresado deve ser un número inteiro',
          rangoLongitud: 'O intervalo deve ser entre '
        },
      },
      tuneles: {
        title: {
          editTitle: 'Editar Elemento Túneis',
          showTitle: 'Informação Elemento Túneis',
          listTitle: ''
        },
        tabs: {
          tabsTitle: {
            identificacion: 'Identificação',
            caracteristicas: 'Características',
            datosAdministrativos: 'Dados Administrativos',
            geometria: 'Geometría',
            detalles: 'Detalhes',
            fotografias: 'FOTOGRAFIAS',
            auditoria: 'Auditoria'
          },
        },
        select: {
          sentido: {
            Ascendente: 'Crescente',
            Descendente: 'Decrescente',
          },
          sentidoB: {
            NORTEName: 'Norte',
            NORTEId: 'NORTE',
            SURName: 'Sul',
            SURId: 'SUR',
            ESTEName: 'Leste',
            ESTEId: 'ESTE',
            OESTEName: 'Oeste',
            OESTEId: 'OESTE'
          },
          clasificacion: {
            ClaseA: 'Classe A',
            ClaseB: 'Classe B',
            ClaseC: 'Classe C',
            ClaseD: 'Classe D',
            ClaseE: 'Classe E',
          },
          cobertura: {
            Somero: 'Breve',
            Semiprofundo: 'Semiprofundo',
            Profundo: 'Profundo',
          },
          existe: {
            Si: 'Sim',
            No: 'Não',
          },
          estadoa: {
            Bueno: 'Bom',
            Regular: 'Regular',
            Malo: 'Ruim',
          },
          soporte: {
            ArcMetalicReticulado: 'Arcos metálicos ou treliçados',
            ConcretoLanzado: 'Shotcrete',
            ElementosDuctiles: 'Elementos dúcteis',
            MejoramientoTerreno: 'Melhoramento do terreno',
            PernosAnclaje: 'Parafusos de âncora',
            RefuerzoTerreno: 'Reforço do terreno',
          },
          codigo_soporte: {
            RCSname: 'Revestimiento de camada única',
            RCCname: 'Revestimiento composto',
            RCDname: 'Revestimiento em dupla camada',
          },
          tipologia: {
            EnVoEncajonado: 'No "V" o encaixotado',
            EnLoMediaLadera: 'No "L" Meia encosta',
            EnCoSesgados: 'No "C" os enviazados',
            EnToPerpendiculares: 'No "T" os perpendiculares',
            EnUoHerradura: 'No "U" os ferraduras',
            EnEoEstructurales: 'No "E" o estruturais',
          },
          estado: {
            Existente: 'Existente',
            Reemplazo: 'Substituição',
            Nuevo: 'Criar',
          },
          mot_remplazo: {
            ReemplazoProgramado: 'Substituição planejada',
            Accidente: 'Acidente',
            Vandalismo: 'Vandalismo',
            Otro: 'Outro',
          },
          estados: {
            Activo: 'Ativo',
            Inactivo: 'Inativo',
          }
        },
        subTitle: {
          localizacion: 'Localização',
          coordenadas: 'Coordenadas',
          coordenadasIniciales: 'Coordenadas Iniciais',
          coordenadasFinales: 'Coordenadas Finais',
          detallesConstructivos: 'Detalhes construtivos',
          detRefuerzoRehabilit: 'Detalhes de reforço ou reabilitação',
          estudioHidrogeologico: 'Estudo Hidrogeológico',
          estProyeccionTrafico: 'Estudo de projeção de tráfego',
          miembrosInteresados: 'Membros Interessados',
          caractOperacionales: 'Características operacionais',
          disenoPlanta: 'Planta baixa',
          disenoPerfil: 'Perfil lonogitudinal',
          SeccTransverServicio: 'Seções transversais de projeto',
          InfoComplementaria: 'Informações complementares',
        },
        table: {
          titles: {
            Costado: 'Lado',
            Cantidad: 'Quantidade',
            Separacion: 'Separação (m)',
            Longitud: 'Extensão (m)',
            Ancho: 'Largura (m)',
            Altura: ' Altura (m)',
            Pendiente: 'Inclinação (%)',
          },
          nameRows: {
            BahiasParqueo: 'Baias de estacionamento',
            CarrilEmergencia: 'Faixa de emergência',
            GaleriasEvacuacion: 'Saídas de emergência',
            NichoEmergenciaSOS: 'Ponto de emergência SOS',
            NichoControlIncendios: 'Ponto de controle de incêndio',
            NichoEquiposElectricos: 'Ponto de painéis elétricos'
          },
          choicesCostado: {
            Izquierdo: 'Esquerda',
            Derecho: 'Direita',
            Ambos: 'Ambas',
          },
          message: 'Carregando tabela...',
        },
        boolean: {
          incluir: '¿Incluir este elemento no plano de manutenção?'
        },
        messagesVal: {
          valNumEntero: 'O valor inserido deve ser um número inteiro',
          valNumMayorA1: 'O valor inserido deve ser maior a 1',
          valNumMayorA0: 'O valor inserido deve ser maior a 0',
          valNumMenorA90: 'O valor inserido deve ser menor a 90',
          valNumMenorA100: 'O valor inserido deve ser menor a 100',
        },
      },
      elemento_contencions: {
        title: {
          editTitle: 'Editar Elemento Terrapleno',
          showTitle: 'Informação Elemento Terrapleno',
          listTitle: 'Lista de Elementos Terrapleno'
        },
        tabs: {
          tabsTitle: {
            identificacion: 'Identificação',
            caracteristicas: 'Características',
            detalles: 'Detalhes',
            fotografias: 'FOTOGRAFIAS',
            auditoria: 'Auditoria'
          },
        },
        select: {
          margen: {
            Derecha: 'Direita',
            Izquierda: 'Esquerda',
          },
          sentido: {
            NORTEName: 'Norte',
            NORTEId: 'NORTE',
            SURName: 'Sul',
            SURId: 'SUL',
            ESTEName: 'Leste',
            ESTEId: 'LESTE',
            OESTEName: 'Oeste',
            OESTEId: 'OESTE'
          },
          tipo: {
            anclajesRoca: 'Grampos de rochas',
            cercadosRedesContencion: 'Cercas e redes de contenção',
            escudoContraCaidaRocas: 'Barreira de proteção contra queda de rochas',
            muroAnclado: 'Muro ancorado',
            muroConcretoReforzado: 'Muro de reforçado de concreto',
            muroEncofrado: 'Muro de cofragem',
            muroGaviones: 'Muro de Gabião',
            muroGravedadConcreto: 'Muro de Gravidade de concreto',
            muroTierraReforzada: 'Aterro reforçado',
            murosContencion: 'Muro de contenção',
            pantallasAncladas: 'Telas ancoradas',
            pilotesCaissons: 'Tubuloes e estacas',
            reconformacion: 'Reconformação',
            revestimientoFlexible: 'Revestimento flexível',
            revestimientoVegetal: 'Cobertura vegetal',
            sueloEmpernado: 'Solo grampeado',
            otros: 'Outros',
          },
          funcionalidad: {
            controlCauces: 'Controle de Curso de água',
            estabilidadBanca: 'Estabilidade de bancada',
            estabilidadTaludes: 'Estabilidade de taludes',
            proteccionInfraest: 'Proteção da infra-estrutura',
            otros: 'Outros',
          },
          estado: {
            Existente: 'Existente',
            Reemplazo: 'Substituição',
            Nuevo: 'Criar',
          },
          motivoReemplazo: {
            Instalacion: 'Instalação',
            ReemplazoProgramado: 'Substituição planejada',
            Accidente: 'Acidente',
            Vandalismo: 'Vandalismo',
            Otro: 'Outro',
          },
          estados: {
            Activo: 'Ativo',
            Inactivo: 'Inativo',
          }
        },
        subTitle: {
          localizacion: 'Localização',
          coordenadas: 'Coordenadas',
          coordenadasIniciales: 'Coordenadas Iniciais',
          coordenadasFinales: 'Coordenadas Finais',
        },
        boolean: {
          estudioEstabilidad: 'Estudos de estabilidade do local',
          mantenimiento: '¿Incluir este elemento no plano de manutenção?'
        }
      },
      elemento_pontons: {
        title: {
          createTitle: 'Criar Elemento Pontilhões',
          editTitle: 'Editar Elemento Pontilhões',
          showTitle: 'Informação Elemento Pontilhões',
          listTitle: 'Lista Elementos Pontilhões'
        },
        tabs: {
          tabsTitle: {
            identificacion: 'Identificação',
            datosAdministrativos: 'Dados Administrativos',
            geometria: 'Geometria',
            reparaciones: 'Reparações',
            detalles: 'Detalhes',
            fotografias: 'FOTOGRAFIAS',
            auditoria: 'Auditoria'
          },
        },
        select: {
          tipoElemento: {
            PTON: 'Pontilhão'
          },
          margen: {
            Derecha: 'Direita',
            Izquierda: 'Esquerda',
            Centro: 'Centro',
          },
          sentidoB: {
            NORTEName: 'Norte',
            NORTEId: 'NORTE',
            SURName: 'Sul',
            SURId: 'SUL',
            ESTEName: 'Leste',
            ESTEId: 'LESTE',
            OESTEName: 'Oeste',
            OESTEId: 'OESTE'
          },
          tipoPonton: {
            Concreto: 'Concreto',
            MetalicosMixtos: 'Metálicos ou mistos',
          },
          selectSorN: {
            S: 'Sim',
            N: 'Não'
          },
          esta: {
            b: 'Bom',
            r: 'Regular',
            m: 'Ruim'
          },
          sentido: {
            S: 'Sul',
            N: 'Norte',
            E: 'Leste',
            O: 'Oeste',
          },
          pon_c: {
            T: 'T',
            C: 'C'
          },
          estado: {
            Existente: 'Existente',
            Reemplazo: 'Substituição',
            Nuevo: 'Criar',
          },
          motivoReemplazo: {
            Instalacion: 'Instalação',
            ReemplazoProgramado: 'Substituição planejada',
            Accidente: 'Acidente',
            Vandalismo: 'Vandalismo',
            Otro: 'Outro',
          },
          estados: {
            Activo: 'Ativo',
            Inactivo: 'Inativo',
          }
        },
        messagesVal: {
          valNumEntero: 'O valor inserido deve ser inteiro',
          valRangoLatLong: 'O intervalo deve ser entre ',
          CargandoTabla: 'Carregando tabela...'
        },
        subTitle: {
          localizacion: 'Localização',
          coordenadas: 'Coordenadas',
          coordenadasIniciales: 'Coordenadas Iniciais',
          coordenadasFinales: 'Coordenadas Finais',
          detallesConstructivos: 'Detalhes construtivos',
          detRefuerzoRehabilit: 'Detalhes de reforço ou reabilitação',
          detallesInspeccion: 'Detalhes de Inspeção',
          miembrosInteresados: 'Membros Interessados',
          caractOperacionales: 'Características operacionais',
          capacidadCargaTransitoLegal: 'Capacidade de carga - veiculos padrão',
          capacidadCargaTranspEspeciales: 'Capacidade de carga para transportes especiales',
          general: 'Geral',
          detallesEstructura: 'Detalhes da estrutura',
        },
        table: {
          fieldsHead: {
            Componente: 'Componente',
            Aplica: 'Aplica',
            FechaReparacion: 'Data reparação',
            Tipo: 'Tipo',
            Cantidad: 'Quantidade',
            Anio: 'Ano',
            Costo: 'Custo',
          },
          titleRows: {
            SuperficiePonton: 'Superfície do Pontilhão',
            AndenesBordillos: 'Faixa de pedestres / Meio-fio',
            Barandas: 'Guarda-corpo',
            ConosTaludes: 'Conos /Taludes',
            ElementosArmadura: 'Elementos de reforço',
            Cauce: 'Curso de água',
            LosaSuperior: 'Laje superior',
            LosaInferior: 'Laje inferior',
            Muros: 'Muros',
            OtrosElementos: 'Outros elementos',
            PontonGeneral: 'Pontilhão em geral',

          }
        },
        boolean: {
          mantenimiento: '¿Incluir este elemento no plano de manutenção?'
        }
      },
      puentes: {
        title: {
          editTitle: 'Editar Elemento Pontes',
          showTitle: 'Informação Elemento Pontes',
          listTitle: 'Lista Elementos de ponte'
        },
        tabs: {
          tabsTitle: {
            identificacion: 'Identificação',
            datosAdministrativos: 'Dados Administrativos',
            geometria: 'Geometría',
            estructura: 'Estrutura',
            subestructura: 'Subestrutura',
            reparaciones: 'Reparações',
            detalles: 'Detalhes',
            fotografias: 'FOTOGRAFIAS',
            auditoria: 'Auditoria'
          },
        },
        select: {
          margen: {
            Derecha: 'Direita',
            Izquierda: 'Esquerda',
            Centro: 'Centro',
          },
          tipo: {
            Concreto: 'Concreto',
            MetalicosMixtos: 'Metálico ou mistos',
          },
          material: {
            Acero: 'Aço',
            Concreto: 'Concreto',
            Madera: 'Madeira',
            Mixto: 'Misto',
            Otro: 'Outro',
          },
          tipo_acceso: {
            Escalera: 'Escadaria',
            Rampa: 'Rampa',
          },
          tipo_cimen: {
            Profunda: 'Profunda',
            Superficial: 'Superficial',
          },
          cimen_pila: {
            Profunda: 'Profunda',
            Superficial: 'Superficial',
          },
          estado: {
            Existente: 'Existente',
            Reemplazo: 'Substituição',
            Nuevo: 'Criar',
          },
          mot_remplazo: {
            Accidente: 'Acidente',
            ReemplazoProgramado: 'Substituição planejada',
            Vandalismo: 'Vandalismo',
            Otro: 'Outro',
          },
          estados: {
            Activo: 'Ativo',
            Inactivo: 'Inativo',
          },
          choices: {
            Circular: 'Circular',
            Rectangular: 'Retangular',
            Otra: 'Outra',
          },
          choises3: {
            ApoyoFijo: 'Apoio fixo',
            Balancin: 'Apoio balancin',
            Basculante: 'Basculante',
            Rodillo: 'Rótula',
            PlacaNeopreno: 'Placa em neoprene',
            NoAplica: 'Não se aplica',
            Otros: 'Outros',
          },
          opcion:{
            si:'Sim',
            no:'Não',
          },
          sentido: {
            S: 'S',
            N: 'N',
            E: 'E',
            O: 'O',
          },
          esta: {
            b: 'B',
            r: 'R',
            m: 'M'
          },
          pon_c: {
            T: 'T',
            C: 'C'
          },
        },
        subTitle: {
          localizacion: 'Localização',
          coordenadas: 'Coordenadas',
          coordenadasIniciales: 'Coordenadas Iniciais',
          coordenadasFinales: 'Coordenadas Finais',
          detallesConstructivos: 'Detalhes Construtivos',
          detRefuerzoRehabilit: 'Detalhes de reforço o reabilitação',
          detallesInspeccion: 'Detalhes de Inspeção',
          miembrosInteresados: 'Membros Interessados',
          caractOperacionales: 'Características operacionais',
          capacidadCargaTransitoLegal: 'Capacidade de carga - veiculos padrão',
          capacidadCargaTranspEspeciales: 'Capacidade de carga - veiculos especiais',
          general: 'Geral',
          estructura: 'Estrutura',
          subestructura: 'Subestrutura',
          detallesEstructura: 'Detalhes da estrutura',
          Principal: 'Principal',
          Secundario: 'Secundário',
          Senales: 'Sinais',
          Detalles: 'Detalhes',
          Estribos: 'Encontros',
          Pilas: 'Pilares',
          Apoyos: 'Apoios',
        },
        messagesVal: {
          ElementoNoRequiereInfo: 'O elemento selecionado não requer informações sobre a subestrutura.',
        },
        boolean: {
          mantenimiento: '¿Incluir este elemento no plano de manutenção?'
        },
        reparaciones: {
          titulos2: {
            SuperficiePuente: 'Superfície da ponte',
            JuntasExpansion: 'Junta de dilatasão',
            AndenesBordillos: 'Faixa de pedestre/ meio-fio',
            Barandas: 'Guarda-corpos',
            ConosTaludes: 'Conos /Taludes',
            Aletas: 'Aletas',
            Estribos: 'Encontros',
            Pilas: 'Pilares',
            Apoyos: 'Apoios',
            Losa: 'Laje',
            VigasLarguerosDiafragmas: 'Vigas/ Viga longitudinal/ Diafragmas',
            ElementosArco: 'Elementos de arco',
            CablesPendolonesTorresMacizos: 'Cabos/ Treliças/ Torres / Maciços',
            ElementosArmadura: 'Elementos de armadura',
            Cauce: 'Curso de água',
            OtrosElementos: 'Outros elementos',
            PuenteGeneral: 'Ponte em geral',
          },
          titleFields: {
            Aplica: 'Aplica',
            FechaReparacion: 'Data de reparação',
            Tipo: 'Tipo',
            Cantidad: 'Quantidade',
            Anio: 'Ano',
            Costo: 'Custo',
          }
        }
      },
      otros_elementos: {
        title: {
          editTitle: 'Editar Outro Elemento',
          showTitle: 'Informação Outro Elemento',
          listTitle: ''
        },
        tabs: {
          tabsTitle: {
            identificacion: 'Identificação',
            caracteristicas: 'Características',
            detalles: 'Detalhes',
            fotografias: 'FOTOGRAFIAS',
            auditoria: 'Auditoria'
          },
        },
        messagesVal: {
          valNum2Decimales: 'O valor inserido só pode ter 2 casas decimais',
          decimal: 'O valor inserido deve ser decimal',
          valEntero: 'O valor inserido deve ser inteiro',
          validarEnteroNT: 'O valor inserido deve ser um número inteiro',
          validacionmaxfechaact: 'A data não pode ser posterior à data atual',
          abscisaFinMayorInicio: 'A Estaca final deve ser maior que a Estaca inicial'
        },
        select: {
          margen: {
            Derecha: 'Direita',
            Izquierda: 'Esquerda',
            Centro: 'Centro',
          },
          sentido: {
            NORTEName: 'Norte',
            NORTEId: 'NORTE',
            SURName: 'Sul',
            SURId: 'SUL',
            ESTEName: 'Leste',
            ESTEId: 'LESTE',
            OESTEName: 'Oeste',
            OESTEId: 'OESTE'
          },
          interseccionIDES: {
            DeprimeIntersecta: 'Passagem superior rodovia principal',
            DeprimeVia: 'Passagem Inferior rodovia principal',
            ElevaIntersecta: 'Passagem Inferior',
            ElevaVia: 'Passagem superior'
          },
          interseccionINIV: {
            Glorieta: 'Rotunda',
            ConPare: 'Com Pare',
            Semaforizada: 'Com Semáforos',
          },
          materialINIV: {
            Adoquin: 'Bloco Intertravado',
            Afirmado: 'Compactação',
            Concreto: 'Concreto',
            Flexible: 'Flexível',
            Otro: 'Outro',
          },
          flujo_peatonal: {
            Alto: 'Alto',
            Medio: 'Meio',
            Bajo: 'Baixo',
          },
          clase_via_intersecta: {
            Urbana: 'Urbana',
            Rural: 'Rural',
          },
          tipo_via_intersecta: {
            Primaria: 'Primária',
            Secundaria: 'Secundária',
            Terciaria: 'Terciária',
          },
          reductores_velocidad: {
            Si: 'Sim',
            No: 'Não',
          },
          sentidoCarac: {
            Ascendente: 'Crescente',
            Descendente: 'Decrescente',
            Doble: 'Double',
          },
          estado: {
            Nuevo: 'Criar',
            Existente: 'Existente',
            Reemplazo: 'Substituição',
          },
          motivo: {
            Instalacion: 'Instalação',
            ReemplazoProgramado: 'Substituição planejada',
            Accidente: 'Acidente',
            Vandalismo: 'Vandalismo',
            Otro: 'Outro',
          },
          estados: {
            Activo: 'Ativo',
            Inactivo: 'Inativo',
          }
        },
        subTitle: {
          localizacion: 'Localização',
          coordenadas: 'Coordenadas',
          coordenadasIniciales: 'Coordenadas Iniciais',
          coordenadasFinales: 'Coordenadas Finais',
          caracteristicasPasoDesnivel: 'Características de Interseções em níveis diferentes',
        },
        boolean: {
          mantenimiento: '¿Incluir este elemento no plano de manutenção?'
        }
      },
      redes: {
        title: {
          createTitle: 'Criar Elemento Redes',
          editTitle: 'Editar Elemento Redes',
          showTitle: 'Informação Elemento Redes',
          listTitle: 'Lista Elementos Redes',
          localizacion: 'Localização',
          coordenadas:'Coordenadas',
          coordenadasIniciales: 'Coordenadas Iniciais',
          coordenadasFinales: 'Coordenadas Finais'
        },
        tabs: {
          tabsTitle: {
            identificacion: 'Identificação',
            datosAdministrativos: 'Dados Administrativos',
            geometria: 'Geometria',
            reparaciones: 'Reparações',
            detalles: 'Detalhes',
            fotografias: 'FOTOGRAFIAS',
            auditoria: 'Auditoria',
            caracteristicas: 'Características',
          },
        },
        select: {
          margen: {
            Derecha: 'Direita',
            Izquierda: 'Esquerda',
            Centro: 'Centro',
          },
          sentido: {
            NORTEName: 'Norte',
            NORTEId: 'NORTE',
            SURName: 'Sul',
            SURId: 'SUL',
            ESTEName: 'Leste',
            ESTEId: 'LESTE',
            OESTEName: 'Oeste',
            OESTEId: 'OESTE'
          },
          tipoRedes: {
            gas:'Gás',
            acueducto:'Adutora',
            alcantarillado:'Esgoto',
            fibraOptica:'Fibra óptica',
            longitudinal:'Longitudinal',
            poste:'Poste',
            travesia:'Travessia'
          },   
          claseIluminacion: {
            M1: 'M1',
            M2: 'M2',
            M3: 'M3',
            M4: 'M4',
            M5: 'M5',
          },
          brazoTipo: {
            A: 'A',
            B: 'B',
            C: 'C',
            D: 'D',
            E: 'E',
          },
          material: {  
            concreto: 'Concreto',
            mamposteria: 'Alvenaria',
            prefabricado: 'Pré-moldado',
            otro:'Outro',
            acero: 'Aço',
            cobre:'Cobre',
            concretoAcero: 'Concreto e aço',
            HDEP:'HDPE',               
            polipropileno:'Polipropileno',
            PVC: 'PVC',
            PEAD: 'PEAD',
            barreraJerseyPlastica: 'Barreira jersey plástica',
            barreraJerseyPlasticaId: 'Barrera jersey plástica',
            malla: 'Malha',
            metalica: 'Metálica',
            metalicaId: 'Metálica',
            plastica: 'Plástica',
            plasticaId: 'Plástica',
            polisombra: 'Tela de proteção em polipropileno',
          },
          seccion: {
            circular: 'Circular',
            cuadrada: 'Quadrada',
            rectangular: 'Retangular',
            trapezoidal: 'Trapezoidal',
            triangular: 'Triangular',
            hexagonal:'Hexagonal',
            perfilH:'Perfil H',
            otro:'Outro',
          },
           estado: {
            Existente: 'Existente',
            Reemplazo: 'Substituição',
            Nuevo: 'Criar',
          },
          nivelTrafico: {
            NT1: 'Alto',
            NT2: 'Médio',
            NT3: 'Baixo',
          },
          entornoVia: {
            claro: 'Claro',
            oscuro: 'Escuro',
          },
          tipoLampara:{
            descarga:'Lâmpada de descarga de gás',
            incandescentes:'Lâmpada incandescente',
            vaporMercurioAltaPresion:'Lâmpada de vapor de mercúrio',
            vaporSodioAltaPresion:'Lâmpada de vapor de sódio alta pressão',
            VaporSodioBajaPresion:'Lâmpada de vapor de sódio baixa pressão',
            otro:'Outro',
          },
          disposicionLuminarias:{
            bilateralPareada:'Arranjo unilateral',
            centralDobleBrazo:'Arranjo bilateral alternado',
            combinaciónBrazosDoblesTresbolill:'Arranjo bilateral oposto',
            unilateralCalzadasDiferenciadas:'Arranjo empregado em vias com canteiro central',
            unilateralLadoExterior:'Outro',
          },
          claseSemaforo:{
            semaforoEspecial:'Semáforos especiais',
            semaforosControlTránsitoVehículos:'Semáforos para controle de tráfego de veículos',
            semaforosPasosPeatonales:'Semáforos para passagens de pedestres',
            semaforosSonoros:'Semáforos sonoros',
          },
          clasificacionSoporte:{
            postes:'Coluna simples',
            cablesSuspención:'Cabos de suspensão',
            estructurasPórtico:'Estruturas tipo pórtico',
            mensulasCortas:'Mísulas curtas',
            mensulasLargasPostesLaterales:'Mísulas longas',
            postesPedestalesIslas:'Braço projetado',
          },
          condicionElemento: {
            existente: 'Existente',
            reemplazo: 'Substituição',
            nuevo: 'Criar',
          },
          motivoReemplazo: {
            accidente: 'Acidente',
            instalacion: 'Instalação',
            instalacionId: 'Instalação',
            reemplazoProgramado: 'Substituição planejada',
            vandalismo: 'Vandalismo',
            otro: 'Outro',
          },
          estados: {
            Activo: 'Ativo',
            Inactivo: 'Inativo',
          }
        },
      },
      inventariopeajes: {
        title: {
          editTitle: 'Editar Inventário de Pedágio',
          showTitle: 'Informação Inventário de pedágio',
          listTitle: 'Lista Inventário de Pedágio'
        },
        tabs: {
          tabsTitle: {
            identificacion: 'Identificação',
            auditoria: 'Auditoria'
          },
        },
        select: {
          estado: {
            Activo: 'Ativo',
            Inactivo: 'Inativo',
          },
        },
        messagesVal: {
          valEntero: 'O valor inserido deve ser inteiro',
        },
        subTitle: {
          detalles: 'Detalhes',
          localizacion: 'Localização',
          coordenadas: 'Coordenadas',
        },
        boolean: {
          estado: 'Estado'
        },
      },
      mensuals: {
        title: {
          editTitle: 'Editar Tráfego Histórico',
          showTitle: 'Informação Tráfego Histórico',
          listTitle: 'Lista Tráfego Histórico'
        },
        tabs: {
          tabsTitle: {
            identificacion: 'Identificação',
            historico: 'Histórico',
            InformacionBasica: 'Informação básica',
            InformacionAnual: 'Informação anual',
            Auditoria:'Auditoria',
          }
        },
        select: {
          sentido: {
            Ascendente: 'Crescente',
            Descendente: 'Decrescente',
          },
          mes: {
            Enero: 'Janeiro',
            Febrero: 'Fevereiro',
            Marzo: 'Março',
            Abril: 'Abril',
            Mayo: 'Maio',
            Junio: 'Junho',
            Julio: 'Julho',
            Agosto: 'Agosto',
            Septiembre: 'Setembro',
            Octubre: 'Outubro',
            Noviembre: 'Novembro',
            Diciembre: 'Dezembro',
          }
        },
        subTitle: {
          VolVehicCategoria: 'Volumen de Veículos por categoria',
          AniosEvaluación: 'Anos de avaliação',
          total:'Total',
        },
        graph: {
          title: 'Histórico',
          subTitle: 'Dados totais',
          Valores: 'Valores',
        }
      },
      estacion_conteos: {
        title: {
          editTitle: 'Editar Estação de contagem',
          showTitle: 'Informação Estação de contagem',
          listTitle: ''
        },
        tabs: {
          tabsTitle: {
            identificacion: 'Identificação',
            detalles: 'Detalhes',
            ejesEquivalentes: 'Eixos equivalentes',
            tasasCrecimiento: 'Taxas de crescimento',
            InformacionBasica: 'Informação básica',
            auditoria: 'Auditoria',
          }
        },
        subTitle: {
          detalles: 'Detalhes',
          localizacion: 'Localização',
          coordenadas: 'Coordenadas',

          abscisasAfectacion: 'Estacas de afetação',
        },
        messagesVal: {
          valRango: 'Erro, o intervalo deve estar entre ',
          valFechaAntFin: 'A data de início deve ser antes da data final.',
          SinResultados: 'Sem Resultados.'
        },
        boolean: {
          operativa: 'Operativa',
        },
        table: {
          titles: {
            categoria: 'Categoria',
            volumen: 'Volume',
            factor: 'Fator',
            distribucionPorcentual: 'Distribuição percentual',
          },
          rows: {
            autos: 'Automóveis',
            buses: 'Ônibus',
          }
        }
      },
      indicadores_cargar_informacions: {
        title: {
          editTitle: 'Editar Avaliação',
          showTitle: 'Exibir Avaliação',
          listTitle: ''
        },
        subTitle: {
          SeleccionIndicador: 'Seleção do Índice de desempenho',
          Detalles: 'Detalhes',
          CargarArchivo: 'Carregar Arquivo',
          RangoCorreccion: 'O intervalo de correção é'
        },
        tabs: {
          IndicadorSegmento: 'Índice de desempenho do Segmento',
          ResumenRegistros: 'Resumo dos registros',
        },
        select: {
          medidoPor: {
            Interventoria: 'Fiscalização',
            Mantenimiento: 'Manutenção',
          },
          estado: {
            PorRevisar: 'Para Revisar',
            Publicada: 'Publicada',
            RevisionRealizada: 'Revisão realizada',
          },
          tipoMedicion: {
            periodica: 'Periódica',
            deCorreccion: 'Correção',
          }
        },
        table: {
          tableHead: {
            SEGMENTO: 'SEGMENTO',
            IncumpPuntuales: 'Inconformidade ocasional',
            CumplimientoIndicSegmento: 'Conformidade com o Índice de desempenho no segmento',
            Visualizar: 'Visualizar',

            abscisa: 'ESTACA',
            carrilIzquierdoDescendente: 'FAIXA ESQUERDA (DECRESCENTE)',
            carrilDerechoAscendente: 'FAIXA DIREITA (CRESCENTE)',
            cumplimientoIndicadorCalzada: 'Cumprimento Índice de desempenho da pista',

            PromedioCarrilI: 'Média Faixa I',
            IncumplPuntualesCarrilI: 'Inconformidade ocasional Faixa I',
            PromedioCarrilD: 'Média Faixa D',
            IncumplPuntualesCarrilD: 'Inconformidade ocasional Faixa D',
            ValorMedioIRI_mm: 'Valor Médio IRI (mm/m)',
            ValorMedAhuellamiento_mm: 'Valor Médio Afundamento de trilha de roda (mm)',
            CumplimientoValorPromedio: 'Cumprimento valor médio',
            CumplimientoValorPuntual: 'Cumprimento valor ocasional',
            CumplimientoIndicadorSegmento: 'Cumprimento do Índice de desempenho no segmento',
            IncumplPuntualesCalzada: 'Inconformidade ocasional na pista',
            incumplimientosPuntuales: 'Inconformidade ocasional',

            AbscisaInicio: 'Estaca inicial',
            AbscisaFinal: 'Estaca Final',
            IRImm: 'IRI (mm/m)',
            Ahuellamiento_mm: 'Afundamento de trilha de roda (mm)',
            ValorMedioCFT: 'Valor Médio Atrito',
            CumplimientoIndicPuntual: 'Cumprimento Índice de desempenho ocasional',
            Evento: 'Evento',
            Singularidad: 'Singularidade',
            IRImm_m: 'IRI (mm/m)',
            CFT: 'Atrito',
            MTDmmASTME1845: 'MTD (mm) ASTM E1845',

            areaCalzada: 'Área da pista (m²)',
            areaAfectada: 'Área afetada (m²)',
            areaAfectada: 'Área afetada (%)',
            cumplimientoIndicador: 'Cumprimento do Índice de desempenho',

            AbscisaFisura: 'Estaca',
            TipoFisura: 'Tipo de trincas',
            Severidad: 'Severidade',
            Largo_m: 'Comprimento (m)',
            Largo_cm: 'Comprimento (cm)',
            Ancho_m: 'Largura (m)',
            Ancho_cm: 'Largura (cm)',
            Area_m: 'Área (m²)',

            Profundidadcm: 'Profundidade (cm)',
            Observaciones: 'Observações',
            Carril: 'Faixa',

            areaVerdadera: 'Área real',
            areaAfectada_m: 'Área afetada (m²)',
            areaAfectada: 'Área afetada (%)',

            Medida1_cm: 'Medida 1 (cm)',
            Medida2_cm: 'Medida 2 (cm)',
            Medida3_cm: 'Medida 3 (cm)',
            Medida4_cm: 'Medida 4 (cm)',
            Medida5_cm: 'Medida 5 (cm)',
            LimpiezaGenCalzada: 'Limpeza geral da pista',
            LaVegetacionAfecta: '¿A vegetação afeta a segurança?',
            NumeroBasuras: 'Número de lixo',
            Cumplimiento: 'Cumprimento',

            Elemento: 'Elemento',
            SeccionHidraulColmatada: 'Seção hidráulica obstruída (%)',

            RetroreflectividadColorPrin: 'Retroreflectividade (cd/lux/m²) cor principal',
            RetroreflectividadColorSec: 'Retroreflectividade (cd/lux/m²) cor secundario',
            LegibilidadSenal: 'Legibilidade do sinal',
            Localización: 'Localização',
            Estabilidad: 'Estabilidade',
            EstadoLimpieza: 'Estado de limpeza',

            MedLineaBlanBordeDerecho: 'Medição linha branca borda direita',
            MedLineaBlanBordeIzquierdo: 'Medição linha branca borda esquerda',
            MedidalineaAmarilla: 'Medição linha amarela',
            CumplimientoDelineadPiso: 'Cumprimento tachões',
            TipoEquipoGeometria_cm: 'Tipo de equipamento com geometria (cm)',
            CumplimientoIndicador: 'Cumprimento do Índice de desempenho',

            Alineacion: 'Alinhamento',
            CondicionEstructural: 'Condição estrutural',
            Estabilidad: 'Estabilidade',
            LongitudIncumplimiento: 'Comprimento com Inconformidade (m)',

            PromedioCarrilIzquierdo: 'Média faixa Esquerda',
            PromedioCarrilDerecho: 'Média faixa Direita',
            IncumplimientosPuntuales: 'Inconformidade ocasional',

            areaAfectada: 'Área Afetada (%)',

            CarrilIzquierdo: 'Faixa esquerda',
            CarrilDerecho: 'Faixa direita',

            PCI: 'PCI (%)',
            CodigoColor: 'Código de Cor',

            Escalonamiento: 'Escalonamento ou degrau nas juntas (mm)',
            ExtensionDesportillam: 'Extensão de esborcinamento de juntas (mm)',

            LongitudJuntasAfectadas: 'Comprimento das juntas afetadas (m)',

            FuncionamientoLuminaria: 'Funcionamento da luminária',
            EstadofisicoLuminaria: 'Condição física da luminária',
            EstadoSoportes: 'Estado dos suportes',
            EstadoCentroMando: 'Estado de centro de controle',
            EstadoTableroControl: 'Estado painel de controle',
            porcentajeNoCumple:'Porcentagem de elementos que não cumprem',
            totalElementos:'Total de Itens',
            elementosQueNoCumplen:'Itens não conformes',

            CodigoASTM: 'Código ASTM',
            Alto: 'Alto',
            Medio: 'Médio',
            Bajo: 'Baixo',
            mostrar:'Exibir',
            ocultar:'Ocultar',
            noCumple:'Não atende',
            Cumple:'Atende',
            noAplica:'Não aplicável',
            adecuado:'Adequada',
            inadecuado:'Inadequada',
            booleanSi:'Sim',
            booleanNo:'Não',
            estadoa: {
              Bueno: 'Bom',
              Regular: 'Regular',
              Malo: 'Ruim',
            },
          },
          tableVisualizacion:{
            SEGMENTO: 'SEGMENTO',
            fechaEvaluacion:'Data de avaliação',
            estado:'Estado',
            medidoPor:'Medido por',
            porcentajeElemento:'Porcentagem de elementos defeituosos (%)',
            cumplimientoIndicador:'Conformidade com o indicador no segmento',
            visualizar:'Visualizar',
            elemento:'Elemento',
            funcionamientoLuminaria: 'Funcionamento da luminária',
            estadofisicoLuminaria: 'Condição física da luminária',
            estadoSoportes: 'Estado dos suportes',
            estadoCentroMando: 'Estado de centro de controle',
            estadoTableroControl: 'Estado painel de controle',
            observaciones: 'Observações',
            cumplimiento: 'Atendimento',
            puente:'Pontes',
            fechaInspeccion:'Data de inspecção', 
            totalAreaAfectada:'Área total afetada',
            porcentajeAreaAfectada:'Porcentagem de área afetada',
            deterioroJuntas:'Deterioração nas juntas de dilatação',
            deterioroAndenes:'Deterioração em plataformas e meios-fios',
            deterioroBarreras:'Danos às barreiras de segurança',
            fisuraEstructura:'Rachaduras na estrutura',
            exposicionAcero:'Exposição de Aço Reforçado',
            corrosion:'Corrosão',
            deterioroDrenaje:'Dano de drenagem',
            promedioCarrilIzquierdo:'Média faixa Esquerda',
            promedioCarrilDerecho:'Média faixa Direita',
            incumplimientosPuntuales:'Inconformidade pontual',
            nivelTrafico:'Nível de tráfego:',
            abscisa:'Estaca',
            carrilIzquierdo:'Faixa esquerda',
            carrilDerecho:'Faixa direita',
            Escalonamiento:'Escalonamento (mm)',
            ExtensionDesportillam: 'Extensão de esborcinamento de juntas (mm)',
            areaAfectada: 'Área Afetada (%)',
            LongitudJuntasAfectadas: 'Comprimento das juntas afetadas (m)',
            Alineacion: 'Alinhamento',
            CondicionEstructural: 'Condição estrutural',
            Estabilidad: 'Estabilidade',
            LongitudIncumplimiento: 'Comprimento com Inconformidade (m)',
            medidaLineaBlancaDerecho:'Medida da borda direita da linha branca',
            medidaLineaBlancaIzquierdo:'Medida da borda esquerda da linha branca',
            medidalineaAmarilla:'Medida da linha amarela',
            cumplimientoDelineadores:'Tachões a conformidade',
            TipoEquipoGeometria_cm: 'Tipo de geometria do equipamento (cm)',
            PromedioCarrilI: 'Média Faixa Esquerda',
            IncumplPuntualesCarrilI: 'Inconformidade pontual Faixa Esquerda',
            PromedioCarrilD: 'Média Faixa Direita',
            IncumplPuntualesCarrilD: 'Inconformidade pontual Faixa Direita',
            ValorMedioIRI_mm: 'Valor Médio IRI (mm/m)',
            ValorMedAhuellamiento_mm: 'Valor Médio ATR (mm)',
            ValorMedioCFT: 'Valor Médio Atrito',
            CumplimientoValorPromedio: 'Atendimento valor médio',
            CumplimientoValorPuntual: 'Atendimento valor pontual',
          },
          tableMessege: 'A visualização por segmentos não aplica para este Índice de desempenho',
          noTieneVisualizacion:'Não há Visualização para este indicador.',
          noSeEncontro:'Nenhum dado encontrado.',
        },
        messagesVal: {
          fechaMedicion:'A data não pode ser maior que a data atual',
        }
      },
      calificacion_puentes: {
        title: {
          editTitle: 'Editar Qualificação de Pontes',
          showTitle: 'Informação Qualificação de Pontes',
        },
        tabs: {
          tabsTitle: {
            detalles: 'Detalhes',
            Inspeccion: 'Inspeção',
            fotografias: 'FOTOGRAFÍAS',
            auditoria: 'Auditoria',
          }
        },
        select: {
          medidoPor: {
            Interventoria: 'Fiscalização',
            Mantenimiento: 'Manutenção',
          },
          estado: {
            PorRevisar: 'Para revisar',
            RevisionRealizada: 'Revisão realizada',
            Publicada: 'Publicada',
          },
          tipoMedicion: {
            Periodica: 'Periódica',
            DeCorreccion: 'Correção',
          }
        },
        tableShow: {
          titleTable: {
            Activo: 'Ativo',
            Cantidad: 'Quantidade',
            LugarDetalle: 'Localização específica',
          },
        },
        inspeccion: {
          dato: 'Valor',
          LugarDetalle: 'Localização específica',
          tablero: {
            TableroTitle: 'Painel',
            SuperficieGrietas: 'Superfície com trincas (m²)',
            Area: 'Área (m²)',
            SuperficieDanada: 'Superfície danificada (m²)',
            SuperficieDesconchados: 'Superfície com desplacamento (m²)',
            TotalAreaAfectada: 'Área total afetada (m²)',
            PorcentajeAreaAfectada: 'Porcentagem área afetada',
            SumaAreas: 'Soma das áreas',
            Calculo: 'Cálculo (%)'
          },
          juntasExpansion: {
            juntasExpansionTitle: 'Juntas de Dilatação',
            NumeroJuntasExpan: 'Número de juntas de Dilatação',
            ObstruccionSello: 'Obstrução do selo (m)',
            RupturaSello: 'Rotura do selo (m)',
            AusenciaSello: 'Ausência do selo (m)',
            AgrietamientoRoturaSoldad: 'Trincas na soldagem (m)',
            AusenciaAnclajes: 'Ausência de ancoragens (m)',
            PerfilesDefectuosos: 'Perfis defeituosos (m)',
            PerfilesSueltos: 'Perfiles soltos (m)',
            DesgasteGuardacantos: 'Desgaste nas proteções laterais (m)',
            DesportillamientoGuardacantos: 'Esborcinamento nas proteções laterais (m)',
            FisuramientoGuardacantos: 'Fisuração nas proteções laterais (m)',
            JuntaFuncional: 'Junta não funcional',

          },
          AndenesBordillos: {
            AndenesBordillosTitle: 'Faixa de pedestre / Meios-fios',
            Desportillamiento: 'Esborcinamento (m)',
            AceroRefuerzoExpuesto: 'Aço de reforço exposto (m)',
            DimensionesInsuficientes: 'Dimensões insuficientes (m)',
          },
          BarrSegBaranPretilMetal: {
            Title: 'Guarda-corpos metálicos',
            ConexionesMalEstado: 'Conexões  (m)',
            ProteccionesMalEstado: 'Protecciones em mau estado (m)',
            Pintura: 'Pintura',
            Delaminacion: 'Delaminação (m)',
            Ausencia: 'Ausência (m)',
            Deterioro: 'Deterioração (m)',
          },
          Postes: {
            titlePostes: 'Postagens',
            Fracturamiento: 'Fracturamento (m)',
            Ausencia: 'Ausência (m)',
            GolpesImpacto: 'Golpes por impacto (m)',
            Grietas5mm: 'Trincas > 5 mm (m)',
            Desconchados10mm: 'Desplacamentos > 10 mm (m)',
          },
          Pasamanos: {
            titlePasamanos: 'Corrimão',
            Corrosión: 'Corrosão (m)',
            AusenciaElementos: 'Ausência de elementos (m)',
            GolpesImpacto: 'Impactos (m)',
            Grietas5mm: 'Trincas > 5 mm (m)',
            Desconchados10mm: 'Desplacamentos > 10 mm (m)',
          },
          DanosEstrucConcreto: {
            Title: 'Danos à estrutura de concreto',
            Subestructura: 'Subestrutura',
            FisurasFlexion: 'Trincas por flexão (m)',
            FisurasCortante: 'Trincas de cisalhamento (m)',
            FisurasTorsion: 'Fisuras por torção (m)',
            AplastamientoLocal: 'Esmagamento local (Und)',
            Asentamientos: 'Assentamentos (Und)',
            Volcamiento: 'Viragem (Und)',
            Segregación: 'Segregação (m²)',
            Hormigueros: 'Segregação (Bicheira) (m²)',
            FisurasRetraccion: 'Trincas por retracção (m²)',
            RecubrimientoInadecuado: 'Revestimento inadequado (m²)',
            ExposicionAceroRef: 'Exposição do aço de reforço (m²)',
            Infiltracion: 'Infiltração (m²)',
            Eflorescencias: 'Eflorescência (m²)',
            Carbonatacion: 'Carbonatação (m²)',
            CorrosionArmadura: 'Corrosão de armaduras (m/m²)',
            FallasImpacto: 'Falhas de impacto (m²)',
            Socavacion: 'Erosão local em torno de apoios (m²)',
            Erosion: 'Erosão (m²)',

            DanosEstrucMetalicas: 'Danos às estruturas metálicas',
            CorrosionLeve: 'Corrosão leve (Und)',
            CorrosionMedia: 'Corrosão média (Und)',
            CorrosionSevera: 'Corrosão severa (Und)',
            PinturaDeteriorada: 'Pintura deteriorada (%)',

            CablesPendolones: 'Cabos e pendurais',
            PerdidaRecubriCables: 'Perda do revestimento de cabos (m)',
            FaltaTensionCablesPendol: 'Falta de tensão em cabos e pendurais (Und)',
            FisurasAlambres: 'Trincas nos fios  (Und)',
            FisurasMuertosAnclajes: 'Trincas em blocos de ancoragem (Und)',
            ContaminacionZonaAnclajes: 'Contaminação na área de ancoragem (Und)',

            PerfMetalTorresMiembrosArmad: 'Perfis metálicos',
            PandeoLocal: 'Encurvamento local (Und)',
            PandeoGeneralLateral: 'Encurvamento geral lateral (Und)',
            FisurasVigas: 'Trincas em vigas (Und)',
            FallasImpacto: 'Falhas de impacto (Und)',
            DeflexionExcesiva: 'Deflexão excessiva (Und)',

            Conexiones: 'Conexões',
            AusenciaConectores: 'Ausência de conectores (Und)',
            Excentricidades: 'Excentricidades (Und)',
            FallasTensionPlatina: 'Falha por tensão na chapa de aço (Und)',
            AplastamientoPlatina: 'Esmagamento na chapa de aço (Und)',
            FallasDesgarramiento: 'Falha por desprendimento (Und)',
            FallaCorteConector: 'Falha devido ao cisalhamento na conexão (Und)',
            FallaBloqueCortante: 'Falha devido ao bloco de cortante (Und)',
            RoturaSoldadura: 'Rotura da soldagem (Und)',

            Drenaje: 'Drenagem',
            SuperficieErosionada: 'Superficie desgastada (Und)',
            DanoEstructural: 'Dano estrutural (Und)',
            Taponamiento: 'Obstrução (Und)',
            Ausencia: 'Ausência (Und)',
            LongitudInsuficiente: 'Comprimento insuficiente (Und)',

          },
          lugar_detalle: {
            EntradaPuente: 'Entrada da ponte',
            SalidaPuente: 'Saída da ponte',
            JuntaIntermedia: 'Junta intermédia',
            CostadoDerecho: 'Lado direito',
            CostadoIzquierdo: 'Lado esquerdo',
            Losa: 'Laje',
            Vigas: 'Vigas',
            Riostras: 'Diagonales',
            Apoyos: 'Apoios',
            Arcos: 'Arcos',
            Cimentacion: 'Fundação',
            Rotulas: 'Rótulas',
            ArcosMetalicos: 'Arcos metálicos',
            PerfilesMetalicos: 'Perfis metálicos',
            Armaduras: 'Armaduras',
            Conexiones: 'Conexões',
            Cables: 'Cabos',
            Pendolones: 'Treliças',
            Torres: 'Torres',
          },
        }
      },
      disponibilidad_vias: {
        title: {
          editTitle: 'Editar Disponibilidade da rodovia',
          showTitle: 'Informação de Disponibilidade da rodovia',
          listTitle: 'Lista de Disponibilidade da rodovia'
        },
        tabs: {
          tabsTitle: {
            identificacion: 'IDENTIFICAÇÃO',
            detalles: 'DETALHES',
            caracteristicas: 'CARACTERÍSTICAS',
            fotografias: 'FOTOGRAFIAS',
            InformacionBasica: 'Informação básica',
            auditoria: 'Auditoria',
            finalizar: 'FINALIZAR'
          }
        },
        select: {
          tipoEvento: {
            Incidente: 'Incidente',
            Accidente: 'Acidente',
            Cierre: 'Cierre',
          },
          tipoCierre: {
            Calzada: 'Pista',
            Carril: 'Faixa',
          },
          margen: {
            Izquierda: 'Esquerda',
            Derecha: 'Direita',
          },
          fuenteRep: {
            Llamada: 'Chamada',
            Email: 'E-mail',
            Recorredor: 'Operador da concessão',
          },
          causa: {
            Accidente: 'Acidente',
            Derrumbe: 'Deslizamento de terra',
            TrabajosVia: 'Trabalhos na via',
            Manifestacion: 'Manifestações',
            OrdenPublico: 'Ordem pública',
            Otro: 'Outro',
          },
          elementoAfect: {
            Calzada: 'Pista',
            Puente: 'Ponte',
            Tunel: 'Túnel',
            Bermas: 'Acostamentos',
          },
          serviciosDirectorio: {
            Emergencias: 'Emergências',
            Proveedores: 'Fornecedores',
            ServiciosPublicos: 'Serviços públicos',
          },
          grupoEmergencias: {
            POBSupervisionVial: 'Concessionária- Supervisão rodoviária',
            POBAdministrativa: 'Concessionária- Administrativa',
            POBRadioOperacion: 'Concessionária- Raio Operação',
            POBGruas: 'Concessionária- Guindaste',
            POBCarroTaller: 'Concessionária- Carro de suporte mecânico',
            POBAmbulancia: 'Concessionária- Ambulância',
            POBPeajes: 'Concessionária- Pedágios',
            Interventoria: 'Fiscalização',
            ServiciosMedicos: 'Serviços médicos',
            Policia: 'Polícia',
            Ejercito: 'Exército Militar',
            DefensaCivil: 'Defensa civil',
            Bomberos: 'Bombeiros',
            CentroEducativo: 'Centro educativo',
            Alcaldia: 'Prefeitura',
            EmisoraRadial: 'Estação de rádio',
            Talleres: 'Oficina de reparação',
            Maquinaria: 'Maquinaria',
            Otros: 'Outros',
          },
          grupo: {
            Acueducto: 'Empresa de saneamento',
            GasNatural: 'Gás natural',
            Energia: 'Companhia de Energia',
            Telecomunicaciones: 'Telecomunicações',
            Aseo: 'Companhia de limpieza',
            Otros: 'Outros',
          },
          tipoAccidente: {
            Simple: 'Simples',
            Heridos: 'Feridos',
            Muertos: 'Mortos',
          },
          tipoHeridos: {
            Peaton: 'Pedestre',
            Ciclista: 'Ciclista',
            Motociclista: 'Motociclista',
            Conductor: 'Condutor',
            Pasajero: 'Passageiro',
            Otro: 'Outro',
          },
          generoHeridos: {
            Hombre: 'Homem',
            Mujer: 'Mulher',
          },
          categoriaVehiculos: {
            Peaton: 'Pedestre',
            Bicicleta: 'Bicicleta',
            Motocicleta: 'Motocicleta',
            Automovil: 'Automóvel',
            Taxi: 'Táxi',
            Bus: 'Ônibus',
            Camion: 'Caminhão',
            MaquinaConstr: 'Maquinaria de construção',
            MaquinariaAgricola: 'Maquinaria agrícola',
            ObjetoFijo: 'Objeto fixo',
            ObjetoMovil: 'Objeto móvel',
            Otros: 'Outros',
          },
          fechaSenal: {
            Enero: 'Janeiro',
            Febrero: 'Fevereiro',
            Marzo: 'Março',
            Abril: 'Abril',
            Mayo: 'Maio',
            Junio: 'Junho',
            Julio: 'Julho',
            Agosto: 'Agosto',
            Septiembre: 'Setembro',
            Octubre: 'Outubro',
            Noviembre: 'Novembro',
            Diciembre: 'Dezembro',
          }
        },
        subTitle: {
          localizacion: 'Localização',
          AsociarServicios: 'Asociar Servicios',
          JuicioPolicia: 'Julgamento policial',
          Heridos: 'Feridos',
          VictimasFatales: 'Vítimas Fatais',
          VehiculosImplicados: 'Veículos envolvidos',
          fechaAmbulanciaRegistrada: 'A data da ambulância já foi registrada',
          NoSegmentosCierre: 'No. Segmentos trancáveis',
          fechaGruaRegistrada:'A data do guindaste já foi registrada',
          disponibilidadVia:'O registro na faixa atual já foi finalizada',
          finalizar:'Finalizar',
          directorio:'Diretório',
          grupoSistema:'Conjunto / Sistema',
          elemento:'Elemento',
        }
      },
      parametros_analises: {
        title: {
          editTitle: 'Editar Parâmetros de planejamento',
          showTitle: 'Informação Parâmetros de planejamento',
          listTitle: ''
        },
        tabs: {
          tabsTitle: {
            InformacionBasica: 'Informação básica',
            auditoria: 'Auditoria',
          }
        },
        table: {
          titleTable: 'Orçamento disponível',
          nameColumns: {
            Anio: 'Ano',
            PresupMantenPavimento: 'Orçamento de manutenção do pavimento',
            PresupMantenOtrosElemen: 'Orçamento de manutenção outros elementos',
            PresupTotal: 'Orçamento Total',
          }
        }
      },
      politicas_mantenimientos: {
        title: {
          editTitle: 'Editar a Política de manutenção',
          showTitle: 'Informação Política de manutenção',
          listTitle: 'Lista Políticas de manutenção',
        },
        tabs: {
          tabsTitle: {
            identificacion: 'IDENTIFICAÇÃO',
            auditoria: 'Auditoria',
            INFORMACION: 'INFORMAÇÂO',
            actualizarNombre:'Atualizar nome da política',
            politica:'Selecionar política',
            nuevoNombre:'Criar nome',
            noDiligenciado:'Não preenchido',
          }
        },
        expansionPanel: {
          ElemenMantenRutinario: 'Elementos para Manutenção de rotina',
          ElemenMantenPeriodico: 'Elementos para Manutenção periódica',
          ElemenMantenRehabilitacion: 'Elementos para Manutenção de reabilitação',
          ElemenMantenReconstruccion_Cambio: 'Elementos para Manutenção reconstrução/substituição',
        },
        boolean: {
          seRealiza: 'É realizado',
        },
        select: {
          periocidad: {
            Mes1: '1 Mês',
            Meses3: '3 Meses',
            Meses6: '6 Meses',
            Meses9: '9 Meses',
            Meses12: '12 Meses',
            Meses18: '18 Meses',
            Meses24: '24 Meses',
            Meses36: '36 Meses',
            anios2: '2 Anos',
            anios2_5: '2,5 Anos',
            anios3: '3 Anos',
            anios3_5: '3,5 Anos',
            anios4: '4 Anos',
            anios4_5: '4,5 Anos',
            anios5: '5 Anos',
            anios5_5: '5,5 Anos',
            anios6: '6 Anos',
            anios6_5: '6,5 Anos',
            anios7: '7 Anos',
            anios7_5: '7,5 Anos',
            anios8: '8 Anos',
            anios8_5: '8,5 Anos',
            anios9: '9 Anos',
            anios9_5: '9,5 Anos',
            anios10: '10 Anos',
            anios11: '11 Anos',
            anios12: '12 Anos',
            anios13: '13 Anos',
            anios14: '14 Anos',
            anios15: '15 Anos',
            anios16: '16 Anos',
            anios17: '17 Anos',
            anios18: '18 Anos',
            anios19: '19 Anos',
            anios20: '20 Anos',
            anios21: '21 Anos',
            anios22: '22 Anos',
            anios23: '23 Anos',
            anios24: '24 Anos',
            anios25: '25 Anos',
            anios26: '26 Anos',
            anios27: '27 Anos',
            anios28: '28 Anos',
            anios29: '29 Anos',
            anios30: '30 Anos',
          },
          operacion: {
            reconstruccion: 'Reconstrução',
            cambio: 'Substituição',
          }
        },
        messages: {
          NingunaPolDefinida: 'Sem política definida',
        },
      },
      politicas_mantenimiento_particulars: {
        title: {
          editTitle: 'Editar a política de Manutenção Privada',
          showTitle: 'Informação da Política de Manutenção Particular',
          listTitle: 'Lista de Políticas de Manutenção Particular',
        },
        tabs: {
          tabsTitle: {
            identificacion: 'IDENTIFICAÇÃO',
            auditoria: 'Auditoria',
            informacion: 'INFORMAÇÂO',
          }
        },
        select: {
          rutinario: {
            Mes1: '1 Mês',
            Meses3: '3 Meses',
            Meses6: '6 Meses',
            Meses9: '9 Meses',
            Meses12: '12 Meses',
            Meses18: '18 Meses',
          },
          periodico: {
            Mes1: '1 Mês',
            Meses3: '3 Meses',
            Meses6: '6 Meses',
            Meses9: '9 Meses',
            Meses12: '12 Meses',
            Meses18: '18 Meses',
            Meses24: '24 Meses',
            Meses36: '36 Meses',
          },
          rehabilitacion: {
            anios2: '2 Anos',
            anios2_5: '2,5 Anos',
            anios3: '3 Anos',
            anios3_5: '3,5 Anos',
            anios4: '4 Anos',
            anios4_5: '4,5 Anos',
            anios5: '5 Anos',
            anios5_5: '5,5 Anos',
            anios6: '6 Anos',
            anios6_5: '6,5 Anos',
            anios7: '7 Anos',
            anios7_5: '7,5 Anos',
            anios8: '8 Anos',
            anios8_5: '8,5 Anos',
            anios9: '9 Anos',
            anios9_5: '9,5 Anos',
            anios10: '10 Anos',
          },
          cambio: {
            anios3: '3 Anos',
            anios4: '4 Anos',
            anios5: '5 Anos',
            anios6: '6 Anos',
            anios7: '7 Anos',
            anios8: '8 Anos',
            anios9: '9 Anos',
            anios10: '10 Anos',
            anios11: '11 Anos',
            anios12: '12 Anos',
            anios13: '13 Anos',
            anios14: '14 Anos',
            anios15: '15 Anos',
            anios16: '16 Anos',
            anios17: '17 Anos',
            anios18: '18 Anos',
            anios19: '19 Anos',
            anios20: '20 Anos',
            anios21: '21 Anos',
            anios22: '22 Anos',
            anios23: '23 Anos',
            anios24: '24 Anos',
            anios25: '25 Anos',
            anios26: '26 Anos',
            anios27: '27 Anos',
            anios28: '28 Anos',
            anios29: '29 Anos',
            anios30: '30 Anos',
          }
        },
        boolean: {
          seRealiza: 'Aplica-se Política Particular',
          seRealizaModif: 'Alterar',
        },
        expansionPanel: {
          Elementos: 'Elementos',
          MantenimientoRutinario: 'Manutenção particular de Rotina',
          MantenimientoPeriodico: 'Manutenção Periódica',
          MantenimientoRehabilitacion: 'Manutenção de reabilitação',
          MantenimientoCambio: 'Manutenção particular Reconstrução/Mudança',
        },
        messages: {
          SinResultados: 'Sem resultados.',
          SinPoliticaPartiDilig: 'A política particular não foi cadastrada',
          SinElementos:'Nenhum elemento associado',
          tramo:'Sem seção definida.',
          absInicial:'Sem Estaca inicial definida.',
          absFinal:'Sem Estaca final definida.',
        },
        table: {
          nameColumns: {
            Periodicidad: 'Periodicidade',
            ValorU: 'Valor U.',
            FechaU: 'Data de valor U.',
            Fuente: 'Fonte',
            FechaIniPeriodicidad: 'Data de início da periodicidade',
            Descripcion: 'Descrição',
            idInventario:'ID do elemento',
            absInicial:'Estaca Inicial',
            absFinal:'Fim de Estaca',
            politicaParticular:'Aplica a Política Privada',
          },
          periocidad: {
            mes: ' Mês',
            meses: ' Meses',
            anos: ' Anos',
          }
        }
      },
      trayectorias: {
        title: {
          editTitle: 'Editar Alternativas',
          showTitle: 'Informação Alternativas',
          listTitle: 'Lista Alternativas'
        },
        tabs: {
          Alternativas: 'Alternativas',
          auditoria: 'Auditoria'
        },
        tableEspesor: {
          nameColumns: {
            Espesores: 'Espessura',
            CodigoMaterial: 'Código do material',
          },
          nameRows: {
            EspesorCapa1: 'Espessura camada 1 (mm)',
            EspesorCapa2: 'Espessura camada 2 (mm)',
            EspesorCapa3: 'Espessura camada 3 (mm)',
            EspesorCapa4: 'Espessura camada 4 (mm)',
            EspesorCapa1Show: 'Espessura camada 1',
            EspesorCapa2Show: 'Espessura camada 2',
            EspesorCapa3Show: 'Espessura camada 3',
            EspesorCapa4Show: 'Espessura camada 4',
          }
        },
        tableIndicador: {
          nameColumns: {
            Indicador: 'Índice de desempenho',
            Mejorar: 'Melhorar',
            Limite: 'Limite',
          },
          nameRows: {
            IRI: 'IRI(mm/m)',
            Friccion: 'Fricção',
            Ahuellamiento: 'Afundamento de trilha de roda (mm)',
            PCI: 'PCI(%)',
          }
        },
        boolean: {
          rigido: 'Rígido',
          flexible: 'Flexível',
        },
        messages:{
          precioTotalIgual:'O preço total não pode ser igual ao preço da camada de rolamento',
          precioTotalMayor:'O preço total deve ser maior que o preço da camada de rolamento',
        }
      },
      modelo_costos_pers: {
        title: {
          editTitle: 'Editar Defeitos PERS',
          showTitle: 'Modelo de custos PERS',
          listTitle: 'Reparações simples'
        },
        tabs: {
          identificacion: 'Identificação',
          auditoria: 'Auditoria'
        },
        buttons: {
          guadarAgregar: 'SALVAR E ADICIONAR',
          guadarFinalizar: 'SALVAR E FINALIZAR',
        },
        modalFormulas: {
          btnFormulas: 'Fórmulas',
          titleModal: 'Fórmulas de Cálculo',
          CostoReparacion: 'Custo da reparação = a*PCI-b*ÁREA SEÇÃO (m2)',
          Costo1: 'Custo = 0 se o PCI for maior que x',
          Costo2: 'Custo = 0 se o custo de reparo do segmento for inferior a y',
        },
        tableVariable: {
          title: 'Variável',
          nameColumns: {
            Flexible: 'Flexível',
            Rigido: 'Rígido',
          },
          nameRows: {
            variableA: 'Variável a',
            variableB: 'Variável b',
            variableX: 'Variável x',
            variableY: 'Variável y',
          }
        },
        tableFlexible: {
          titleTable: 'Flexível',
          nameColumns: {
            PCI: 'PCI',
            CostoBase: 'Custo base',
            CostoConsidRestr: 'Custo considerando restrições',
          }
        },
        tableRigido: {
          titleTable: 'Rígido',
          nameColumns: {
            PCI: 'PCI',
            CostoBase: 'Custo Base',
            CostoConsidRestr: 'Custo considerando restrições',
          }
        },
        grafica: {
          titleGraph: 'Reparações menores',
          axisY: 'Custo considerando restrições (Reais R$)',
          axisX: 'Valor do PCI',
          serieFlexible: 'Flexível',
          serieRigido: 'Rígido',
        }
      },
      pers_exports: {
        btnGenerarInf: 'Gerar base de dados',
        title:'Listar PERS',
        btnDescargar:'Baixar',
        btnCrearInforme:'Criar base de dados',
        titleCrearInforme:'Criar base de dados PERS',
        modal: {
          title: 'Criar base de dados PERS',
        }
      },
      costos: {
        title: {
          editTitle: 'Editar Custos',
          showTitle: 'Informação sobre Custos'
        },
        tabs: {
          identificacion: 'IDENTIFICAÇÃO',
          auditoria: 'Auditoria',
          informacion: 'Informação'
        },
        boolean: {
          rango: '¿Por Intervalo de Estacas?'
        },
        modal: {
          visualizarCostos:'Visualizar custos',
          btnPlantilla: 'Planilha.csv',
          titleModal: 'Planilha para Carregamento em massa',
          text1: 'La carga masiva requiere de una primera línea con los nombres de las columnas como se indica a continuación:',
          nameColumns: 'codigo;tipo de mantenimiento;fecha mantenimiento;costo mantenimiento;calificacion;observaciones;proveedor',
          text2: 'Os seguintes dados devem corresponder a estas colunas. Por exemplo:',
          ejemplo1: 'UF1A::DRE::TR1::SG03::ZCOR::001;pe;2/01/2020;2;2;observaciones;90012345678',
          ejemplo2: 'UF1A::DRE::TR1::SG01::POZT::001;re;3/01/2020;3;3;observaciones;90012345678',
          ejemplo3: 'UF1A::DRE::TR1::SG01::CUNT::001;ca;4/01/2020;4;4;observaciones;90012345678',
          text3: 'Os dados inseridos são de acordo com sua origem, exceto para o tipo de manutenção, que deve ser indicado usando as abreviações [ru | pe | re | ca] para indicar os tipos de manutenção Rotina, Periódica, Reabilitação e Substituição',
          nota: 'NOTA: el valor de proveedor se indica con el Número de Identificación registrado en la aplicación.',
          text4: 'Faça o Download da Planilha CSV com exemplos de valores usando o link a seguir:',
          enlace: 'Link da planilha',
        },
        exportar:{
          datos:'Dados',
          exportarBusqueda:'Exportar informação filtrada',
          busquedaActual:'Exportar informação filtrada atual como um arquivo do Excel?',
          enlace:'Link para Download',
        },
        select: {
          tipoMantenimientos: {
            Rutinario: 'Rotina',
            Periodico: 'Periódica',
            Rehabilitacion: 'Reabilitação',
            Cambio: 'Substituição',
          }
        },
        tableDatos: {
          titleTable: 'Dados',
          columnNames: {
            codigo: 'Código',
            tipoMantenimiento: 'Tipo de manutenção',
            fechaMantenimiento2: 'Data de manutenção',
            costoMantenimiento: 'Custo',
            razonSocial: 'Fornecedor',
            calificacion: 'Qualif.',
            observaciones: 'Observações',
            tipoMantenShow: 'Tipo de Manut.',
            fechaMantenShow: 'Data de Manut.',
            tipo:'Tipo',
            fecha:'Data',
            costo:'Custo',
            proveedor:'Fornecedor',
            calificacion:'Qualificação',
            rutinario:'Custos totais de manutenção de rotina',
            periodico:'Custos totais de manutenção periódica',
            rehabilitacion:'Custos Totais Manutenção Reabilitação',
            cambio:'Custos Totais de Manutenção substituição',
            costoPorMantenimiento:'Custos de manutenção',
            costoEn:'Custo em R$',
            tiempo:'Tempo',
          }
        },
        modalEdit: {
          title: 'Editar ',
        },
        modalVisual: {
          btnVisual: 'Visualização',
        }
      },
      flujo_cajas: {
        title: {
          editTitle: 'Editar Fluxo de Caixa',
          showTitle: 'Informação Fluxo de Caixa',
          listTitle: 'Lista Fluxo de Caixas',
        },
        tabs: {
          identificacion: 'IDENTIFICAÇÃO',
          informacion: 'Informação',
          auditoria: 'Auditoria'
        },
        messages: {
          ConsInfoMatriz: 'Consultando as informações na base de dados',
          generarMatriz:'Gerar Matriz',
          generarMatriz2:'¿Gerar uma matriz?',
          confirmacionMatriz:'¿Tem certeza que deseja gerar uma matriz?',
        }
      },
      validaciones: {
        ValorMaximoAbsCreate: 'O valor inserido não deve ser maior a km999+999',
        ValorMaximoPrCreate: 'O valor inserido não deve ser maior a km999+999',
        validarLogicaAbsFinCreate: 'A Estaca final deve ser maior a Estaca inicial',
        AbsRamal: 'A distância entre as Estacas deve ser inferior a 3.000',
        validarLogicaPrFinCreate: 'km final deve ser maior a km inicial',
        latMininima: 'O intervalo deve ser entre',
        latMininimay: ' e ',
        lngMininima: 'O intervalo deve ser entre',
        lngMininimay: ' e ',
        altitud: 'O intervalo deve ser entre',
        altitudy: ' e ',
        isFechaInvalida2000: 'A data deve ser a partir do ano 2000',
        isFechaInvalidaSup: 'A data indicada não pode ser no futuro.',
        Entero: 'O valor inserido deve ser um número inteiro',
        abValidationInicio: 'Erro, O valor inserido deve estar entre ',
        abValInicioy: ' e ',
        abValidationFin: 'Erro, O valor inserido deve estar entre ',
        abValFiny: ' e ',
        prValidationInicio: 'Erro, O valor inserido deve estar entre ',
        prValIniy: ' e ',
        prValidationFin: 'Erro, O valor inserido deve estar entre ',
        prValnFiny: ' e ',
        MansajeValidadorAb: 'Valor inválido'
      },
      indicador_complementarios: {
        title: {
          editTitle: 'Editar Conf. Índ. Complementar',
          showTitle: 'Informação Conf. Índ. Complementar',
          listTitle: 'Lista de Conf. Índ. Complementar',
        },
        subTitle: {
          preguntas: 'Perguntas:',
          respuestas: 'Respostas:',
          consideracionesPeso: 'Considerações do Peso'
        },
        tabs: {
          identificacion: 'IDENTIFICAÇÃO',
          informacion: 'Informação',
          auditoria: 'Auditoria',
          preguntas: 'Perguntas'
        },
        select: {
          estado: {
            Activo: 'Ativo',
            Inactivo: 'Inativo'
          },
          tipoCampo: {
            seleccionMultiple: 'Escolha Múltipla',
            numerico: 'Numérico',
          }
        },
      },
      respuesta_evaluacion_complementarios: {
        title: {
          list:'Lista de Indicadores Complementares',
          editTitle: 'Editar Índice Complementar',
          showTitle: 'Informação Índice Complementar',
          listTitle: 'Lista de Conf. Índ. Complementares',
        },
        subTitle: {
          indicadores: 'Detalhes Indicadores Complementares',
          resultados:'Resultados de Indicadores Complementares',
        },
        tabs: {
          filtro: 'FILTRAGEM',
          registro: 'Registro',
          auditoria: 'Auditoria',
          preguntas: 'Perguntas'
        },
        select: {
          accion: {
            Nada: 'Nada',
            Mantenimiento: 'Manutenção',
            Reparar: 'Reparação',
            Reemplazar: 'Substituição'
          },
          estado: {
            Activo: 'Ativo',
            enProceso: 'Em curso',
            Cerrado: 'Cerrado',
          },
        },
        condiciones: {
          numerico: 'Numérico',
          seleccionMultiple: 'Selección Múltiple'
        }
      },
      registro_novedads: {
        title: {
          editTitle: 'Editar Configuração de Ocorrência',
          showTitle: 'Informação Conf. de Ocorrência',
          listTitle: 'Lista Configuração de Ocorrência',
        },
        subTitle: {
          preguntas: 'Perguntas:',
          respuestas: 'Respostas:',
          consideracionesPeso: 'Considerações Peso'
        },
        tabs: {
          identificacion: 'IDENTIFICAÇÃO',
          informacion: 'Informação',
          auditoria: 'Auditoria',
          preguntas: 'Perguntas'
        },
        select: {
          estado: {
            Activo: 'Ativo',
            Inactivo: 'Inativo'
          },
          tipoCampo: {
            seleccionMultiple: 'Escolha Múltipla',
            numerico: 'Numérico',
          }
        },
      },
      respuesta_evaluacion_novedads: {
        title: {
          editTitle: 'Editar Reg. de Ocorrência - Medição',
          showTitle: 'Info. Reg. de Ocorrência - Medição',
          listTitle: 'Lista de Reg. Ocorrência - Medição',
        },
        subTitle: {
          localizacion: 'Localização',
          coordIniciales: 'Coordenadas Iniciais',
          coordFinales: 'Coordenadas Finais',
          deterioro: 'Defeitos',
          mediciones: 'Medições',
          indicadores: 'Detalhes Registros Ocorrências',
          resultados:'Resultados Registros Ocorrências',
        },
        tabs: {
          filtro: 'FILTRAGEM',
          registro: 'Registro',
          auditoria: 'Auditoria',
          preguntas: 'Perguntas'
        },
        select: {
          estado: {
            activo: 'Ativo',
            proceso: 'Em andamento',
            cerrado: 'Fechado',
          },
          tipoRegistro: {
            novedad: 'Ocorrência',
            medicion: 'Medição',
            medicionId: 'Medição',
          }
        },
        condiciones: {
          numerico: 'Numérico',
          seleccionMultiple: 'Escolha Múltipla'
        },
        tableMediciones: {
          columns: {
            mediciones: 'Medições',
            tipoElemento: 'Tipo de elemento',
            modelo: 'Modelo',
            codigo: 'Código',
            retroReflecColorPrin: 'Retro-reflectividade cor principal (cd/lux/m²)',
            disminucionPrincipal:'Diminuir cor principal (%)',
            retroReflecColorSec: 'Retro-reflectividade cor secundário (cd/lux/m²)',
            disminucionSecundario:'Diminuir cor secundário(%)',
            posicion: 'Posição',
            legibilidad: 'Legibilidade',
            observaciones: 'Observações',
          }
        },
        mensajes: {
          borrar: '¿Remover?'
        }
      },
      aprobacions: {
        title: {
          listTitle: 'Lista Aprovações',
        },
        select: {
          tipoRegistro: {
            novedad: 'Ocorrência',
            medicion: 'Medição',
            medicionId: 'Medição',
            Inventario: 'Inventário',
            IndicadorComplementario: 'Índice complementar'
          },
          aprobacion: {
            aprobado: 'Aprovado',
            rechazado: 'Rejeitado'
          }
        },
        buttons: {
          aprobar: 'APROVAR',
          rechazar: 'REJEITAR',
          mostrar: 'EXIBIR'
        },
        modalTitle: "Registro duplicado",
        modalMessaje: "Um registro semelhante foi encontrado com status pendente, deseja aprovar ou rejeitar este registro?",
      },
      config_sistema: {
        title: {
          listTitle: 'Configuração Geral',
        },
        title_cards: 'Sistema',
        titles_cards: {
          idioma: 'Língua do sistema',
          moneda: 'Moeda',
          logos: 'Logotipo'
        },
        btnEditar: 'Editar',
            btnCancelar: 'Cancelar',
            btnAceptar: 'Aceitar',
            btnConfirmar: 'Comfirmar',
            btnEspanol:'ESPANHOL',
            btnIngles:'INGLÊS',
            btnPortugues:'PORTUGUÊS',
            cards: {
              pesos: 'Reais ($R)',
              logo: 'Formato PNG - máx 800x600',
              dropzone: 'Arraste e solte a imagem ou clique para selecionar uma imagem'
            },
            mensajesModals: {
              titulo: 'Alterar idioma do sistema',
              idioma: 'Você tem certeza de que quer mudar a língua do sistema?',
              titleLogo: 'Mudar logotipo do projeto',
              logo: 'Você tem certeza de que quer mudar o logotipo do projeto?'
            }
      },
      idiomas_config: {
        title: 'Línguas',
        editTitle:'Editar línguas',
        showTitle:'Línguas informação',
        fields:{
          idioma:'Linguagem',
          region:'Região',
          nombreIdioma:'Nome do idioma adicionado',
        },
        cards: {
          eliminar: 'Remover',
          cargarInfoIdioma: 'Carregar informação língua',
          descManualUsuario: 'Fazer Download do manual do usuário',
          descargar: 'Fazer Download',
          activo: 'Ativo'
        },
        modulo_administracion: 'Arquivo de tradução',
      },
      carga_masivas: {
        title: {
          listTitle: 'Lista carregamento em massa',
          createTitle: 'Crear carregamento em massa',
          editTitle: 'Visualização carregamento em massa'
        },
        button: {
          verTodos: 'Ver tudo',
          cargarArcdeNuevo: 'Carregar arquivos novamente',
          cancelar: 'Cancelar',
          guardar: 'Salvar',
          confirmar: 'Comfirmar',
          alertas: 'Alertas',
          errores: 'Erros',
          descargar: 'Fazer Download',
          siConfirmar: 'Sim, continuar'
        },
        boolean: {
          imagenes: '¿Arquivo de imagens?'
        },
        message: {
          dialogFilterEmpty: 'Para continuar com o carregamento dos arquivos, digite os dados do sistema a partir do ícone de edição e o nome do grupo.',
          dialogErrores: 'Foram encontrados erros, verificá-los no arquivo original e fazer o upload novamente.',
          dialogAlerts: 'Os alertas foram encontrados, você pode confirmá-los e continuar carregando o arquivo.',
          dialogAddAlert: '*Não é possível confirmar os alertas porque foram encontrados erros, verificá-los no arquivo original e voltar a carregá-lo.',
          dialogConfirmAlert: 'Você tem certeza de que quer continuar carregando mesmo com alertas?',
          dialogStopQ: 'Você tem certeza de que quer interromper a carga?',
          dialogStop: 'Se você interromper o upload, seu progresso atual será perdido.',
          dialogMsgIdioma: 'O idioma do projeto é definido como '
        }
      }
    },
    uniqueEmail: {
      email: 'E-mail '
    },
  }
}

