import React from 'react';
import { List, Datagrid, EditButton, ShowButton,TextField,SelectField,NumberField } from 'react-admin';
import {  BulkDeleteWithConfirmButton, Loading  } from 'react-admin';
import { Fragment } from 'react';
import { translate } from 'react-admin';
import { CardActions, CreateButton, ExportButton } from 'react-admin';
const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);
const ListAction = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
        <ExportButton basePath={basePath}/>
    </CardActions>
);
const ListAction2 = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
    </CardActions>
);

export const DeterioroPersList = translate(({ permissions, ...props }) => {
    const { translate } = props;
    if (!permissions) return <Loading />;  
    return (
    <List {...props} 
        sort={{ field: 'updatedAt', order: 'DESC' }} 
        actions={permissions['deterioros']  == 1 ? <ListAction /> : permissions['deterioros']  == 2 ? <ListAction2 />: null}
        bulkActionButtons={permissions['deterioros']  == 1 ? <PostBulkActionButtons />: null} >
        <Datagrid >
            <SelectField source='tipoSuperficie' choices={[
                { id:'PFLEX', name:'dynatest.resources.tipo_deterioro_pers.select.tipoSuperficie.PFLEX'},
                { id:'PRIGD', name:'dynatest.resources.tipo_deterioro_pers.select.tipoSuperficie.PRIGD'},
                { id:'PART', name:'dynatest.resources.tipo_deterioro_pers.select.tipoSuperficie.PART'},
                { id:'POTR', name:'dynatest.resources.tipo_deterioro_pers.select.tipoSuperficie.POTR'},
            ]}/>
            <TextField label="resources.tipo_deterioro_pers.fields.nombre" source="nombre" />
            <TextField label="resources.tipo_deterioro_pers.fields.unidad" source="unidad" />        
            <TextField label="resources.tipo_deterioro_pers.fields.tipoReparacionMedia" source="tipoReparacionMedia_.nombre" />
            <TextField label="resources.tipo_deterioro_pers.fields.tipoReparacionAlta" source="tipoReparacionAlta_.nombre" />                    
            {permissions['deterioros'] == 1 || permissions['deterioros'] == 2 || permissions['deterioros'] == 4 ? <ShowButton/>: null}
            {permissions['deterioros'] == 1 || permissions['deterioros'] == 2 ? <EditButton/>: null}
        </Datagrid>
    </List>
)});