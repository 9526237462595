import React from 'react';
import Typography from '@material-ui/core/Typography';
import {
    Datagrid, DateField, BooleanField, required, TabbedForm, FormTab, AutocompleteInput, SimpleFormIterator,
    ArrayInput, NumberInput, SelectInput, ReferenceInput, TextInput, FormDataConsumer
} from 'react-admin';
import { minValue, maxValue, minLength, maxLength } from 'ra-core';
import { PRInput } from '../../../inputs/PRInput';
import { DInput } from '../../../inputs/DInput';
import { DEInput } from '../../../inputs/DEInput';
import { AInput } from '../../../inputs/AInput';
import { styles } from './../../../EstilosGrid';
import { withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import { translate } from 'react-admin';


class Reparaciones extends React.Component {

    constructor(props) {
        super(props)
        this.state = { titulos: [], record: [] }
    }

    componentDidMount() {
        //this.setState({ datoEva: this.props.record })   
        this.setState({ record: this.props.record })


    }

    render() {
        const { translate } = this.props;
        const { titulos } = this.state;
        const { record } = this.state;
        //console.log(this.state)
        let titulos2 = [
            translate('dynatest.resources.puentes.reparaciones.titulos2.SuperficiePuente'), 
            translate('dynatest.resources.puentes.reparaciones.titulos2.JuntasExpansion'), 
            translate('dynatest.resources.puentes.reparaciones.titulos2.AndenesBordillos'), 
            translate('dynatest.resources.puentes.reparaciones.titulos2.Barandas'), 
            translate('dynatest.resources.puentes.reparaciones.titulos2.ConosTaludes'), 
            translate('dynatest.resources.puentes.reparaciones.titulos2.Aletas'), 
            translate('dynatest.resources.puentes.reparaciones.titulos2.Estribos'), 
            translate('dynatest.resources.puentes.reparaciones.titulos2.Pilas'), 
            translate('dynatest.resources.puentes.reparaciones.titulos2.Apoyos'), 
            translate('dynatest.resources.puentes.reparaciones.titulos2.Losa'), 
            translate('dynatest.resources.puentes.reparaciones.titulos2.VigasLarguerosDiafragmas'), 
            translate('dynatest.resources.puentes.reparaciones.titulos2.ElementosArco'), 
            translate('dynatest.resources.puentes.reparaciones.titulos2.CablesPendolonesTorresMacizos'), 
            translate('dynatest.resources.puentes.reparaciones.titulos2.ElementosArmadura'), 
            translate('dynatest.resources.puentes.reparaciones.titulos2.Cauce'), 
            translate('dynatest.resources.puentes.reparaciones.titulos2.OtrosElementos'), 
            translate('dynatest.resources.puentes.reparaciones.titulos2.PuenteGeneral')];

        const choises4 = [
            { id: '2000', name: '2000' },
            { id: '2001', name: '2001' },
            { id: '2002', name: '2002' },
            { id: '2003', name: '2003' },
            { id: '2004', name: '2004' },
            { id: '2005', name: '2005' },
            { id: '2006', name: '2006' },
            { id: '2007', name: '2007' },
            { id: '2008', name: '2008' },
            { id: '2009', name: '2009' },
            { id: '2010', name: '2010' },
            { id: '2011', name: '2011' },
            { id: '2012', name: '2012' },
            { id: '2013', name: '2013' },
            { id: '2014', name: '2014' },
            { id: '2015', name: '2015' },
            { id: '2016', name: '2016' },
            { id: '2017', name: '2017' },
            { id: '2018', name: '2018' },
            { id: '2019', name: '2019' },
            { id: '2020', name: '2020' },
            { id: '2021', name: '2021' },
            { id: '2022', name: '2022' },
            { id: '2023', name: '2023' },
            { id: '2024', name: '2024' },
            { id: '2025', name: '2025' },
            { id: '2026', name: '2026' },
            { id: '2027', name: '2027' },
            { id: '2028', name: '2028' },
            { id: '2029', name: '2029' },
            { id: '2030', name: '2030' }
        ];
        //let reparaciones=record.reparaciones


        let reparaciones = this.props.record.reparaciones
        let aplica = [];
        let date = [];


        if (reparaciones) {
            reparaciones.map((data) => {
                if (data.aplica === true) {
                    aplica.push('Sí')
                }
                else {
                    aplica.push('No')
                }
                if (data.date != null && typeof (data.date) === "string") {
                    let date2 = data.date.split('T')
                    let date3 = date2[0].split('-')
                    date.push(date3[2] + "-" + date3[1] + "-" + date3[0])
                }
                else {
                    date.push('')
                }
            })
        }
        if (reparaciones) {
            return (

                <React.Fragment style={{ width: "100%" }}>
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <Table aria-label="simple table" fullWidth style={{ width: "100%" }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="right" >{translate('dynatest.resources.puentes.reparaciones.titleFields.Aplica')}</TableCell>
                                        <TableCell align="right" colspan="2" >{translate('dynatest.resources.puentes.reparaciones.titleFields.FechaReparacion')}</TableCell>
                                        <TableCell align="right" >{translate('dynatest.resources.puentes.reparaciones.titleFields.Tipo')}</TableCell>
                                        <TableCell align="right" >{translate('dynatest.resources.puentes.reparaciones.titleFields.Cantidad')}</TableCell>
                                        <TableCell align="right" >{translate('dynatest.resources.puentes.reparaciones.titleFields.Anio')}</TableCell>
                                        <TableCell align="right" >{translate('dynatest.resources.puentes.reparaciones.titleFields.Costo')}</TableCell>
                                    </TableRow>
                                </TableHead>

                                {
                                    titulos2.map((data, i) => (
                                        (this.props.record.elementos == '/tipo_elementos/PNTEP' && (titulos2[i] == 'Andenes / Bordillos' || titulos2[i] == 'Conos /Taludes' || titulos2[i] == 'Cauce'))? null : 
                                        <TableBody>
                                            <TableRow >
                                                <TableCell component="th" colSpan="7" fullWidth>
                                                    <h3 style={{ marginBottom: 0 }}>{titulos2[i]}</h3>
                                                </TableCell>
                                            </TableRow>

                                            <TableRow >

                                                <TableCell align="center" > <span > {aplica[i]} </span></TableCell>
                                                <TableCell align="center" colspan="2" > <span>{date[i]}</span></TableCell>
                                                <TableCell align="center" > <span >{reparaciones[i].tipo}</span> </TableCell>
                                                <TableCell align="right" style={{ textAlign: "end" }}> <span >{reparaciones[i].cant}</span></TableCell>
                                                <TableCell align="right" style={{ textAlign: "end" }}> <span >{reparaciones[i].years}</span></TableCell>
                                                <TableCell align="right" style={{ textAlign: "end" }}> <span >{reparaciones[i].precio}</span></TableCell>
                                                <span style={{ display: 'none' }}>{i}</span>
                                            </TableRow>
                                        </TableBody>

                                    ))
                                }

                            </Table>
                        </Grid>
                    </Grid>
                </React.Fragment>

            )

        }
        else {
            return (
                <React.Fragment></React.Fragment>
            )
        }
    }
}

export default translate(Reparaciones);