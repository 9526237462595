import React, { useState } from 'react';
import { Toolbar, CardActions, NumberField, TextInput, ImageField, ShowController, ShowView, TextField, DateField, ReferenceField, BooleanField, TabbedForm, ListButton, EditButton, ArrayField, ChipField, SingleFieldList, FormTab } from 'react-admin';
import { GMapInput } from '../../../inputs/GMapInput';
import { PRField } from './../../../fields/PRField';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { styles } from './../../../EstilosGrid';
import { withStyles } from '@material-ui/core';
import { ButtonCarousel } from './../../images/ButtonCarousel';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import config from '../../../config/config';
// import { ButtonCarousel } from './../../images/ButtonCarousel';
let urlAppjs = config.UrlServer;

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};


const PostShowActions = ({ basePath, data }) => (
    <CardActions style={cardActionStyle}>
        <EditButton basePath={basePath} record={data} />
        {/* Add your custom actions */}
        <ListButton basePath={basePath} />
    </CardActions>
);

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <span>.</span>

    </Toolbar>
));

export const ElementoSenalizacionPeruShow = withStyles(styles)(({ classes, record, ...props }) => {

    const [imagen, setImagen] = useState(true);
    const [senalId, setSenalId] = useState(0);
    const ValidarImagen = (value) => {
        // console.log(value + "Aqui est la imagen ")
        if (value !== null && value !== undefined && value !== 'undefined') {
            let lenghtObject = Object.keys(value);
            let valor = '';
            for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
            setImagen(value)
            // console.log(value + "Aqui est la imagen ")
        }
    }

    const ButtonFicha = ({ record }) => {
        setSenalId(record['@id']);
        return (
            <Button variant="secondary" id="generarInforme" onClick={ButtonFichaOnclick} style={{ backgroundColor: '#3f51b5', color: '#fff' }}>
                <GetAppIcon /> Ficha evaluación
            </Button>
        )
    };

    const ButtonFichaOnclick = ({ record }) => {
        let senalId1 = senalId;
        let senalIdReplace = parseInt(senalId1.replace("/elemento_senalizacion_perus/", ""));
        const dataPost = new FormData();
        dataPost.append('senalId', senalIdReplace);
        fetch(urlAppjs + '/reportFicha/', {
            method: 'POST',
            body: dataPost
        })
            .then(res => res.text())
            .then(datos => {
                if (datos == "Sin datos") {
                    alert("No se encontraron datos.");
                } else {
                    var myWindow = window.open('', '', 'width=800,height=600');
                    if (myWindow == null || typeof (myWindow) == 'undefined') {
                        alert('Por favor deshabilita el bloqueador de ventanas emergentes y vuelve a hacer clic en "Generar Reporte".');
                    }
                    else {
                        myWindow.document.write(datos);
                        myWindow.document.close();
                        myWindow.focus();
                        setTimeout(function () { myWindow.print(); }, 2000);
                    }
                }
            });
    };

    return (
        <ShowController {...props} >
            {controllerProps =>
                <ShowView  {...props} {...controllerProps} title="Elemento Señal" actions={<ButtonCarousel />}>
                    <TabbedForm toolbar={false}>
                        <FormTab label="IDENTIFICACIÓN">
                            <TextField label="Código" source="fullCodigo" fullWidth formClassName={classes.grid_cont4s} />
                            <ButtonFicha source="fullCodigo" />
                            <div></div>
                            <ReferenceField label="Elemento" source="tipoElementoSenalPeru" reference="tipo_elemento_senal_perus" linkType={false} fullWidth formClassName={classes.grid_cont4s}>
                                <TextField source="nombre" />
                            </ReferenceField>
                            <TextField label="Lado" source="margen" fullWidth formClassName={classes.grid_cont4} />

                            {/* <ImageField source="images" src="images"  /> */}

                            <TextInput source="senalVerticalPeruA_.url" validate={ValidarImagen} formClassName={classes.ocultar} />
                            <ReferenceField label="Tipo de elemento" source="tipoSenalPeru" reference="senal_vertical_perus" linkType={false} fullWidth formClassName={classes.grid_cont4} >
                                <TextField source="nombre" />
                            </ReferenceField>
                            {controllerProps.record && controllerProps.record.tipoElementoSenalPeru === '/tipo_elemento_senal_perus/SV' &&
                                <div></div>
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenalPeru === '/tipo_elemento_senal_perus/SV' &&
                                <ReferenceField label="Código de señal" source="senalVerticalPeru" disabled reference="senal_vertical_perus" linkType={false} fullWidth formClassName={classes.grid_cont4s}>
                                    <TextField source="ids" />
                                </ReferenceField>
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenalPeru === '/tipo_elemento_senal_perus/SV' &&
                                <ReferenceField label="Significado de señal" source="senalVerticalPeru" reference="senal_vertical_perus" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                    <TextField source="nombre" />
                                </ReferenceField>
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenalPeru === '/tipo_elemento_senal_perus/SV' &&
                                <TextField label="Descripción del mensaje" source="descripcionMensaje" fullWidth formClassName={classes.grid_cont4} />
                            }

                            <TextField label="Sentido del tránsito" source="sentidoTransito" fullWidth />
                            <div></div><div></div>
                            <span></span>
                            <Typography variant="h3" component="h3">Localización</Typography>
                            <span></span>
                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <ReferenceField label="Unidad Funcional" source="unidadFuncional" reference="unidad_funcionals" linkType={false} fullWidth formClassName={classes.grid_cont4s}>
                                <TextField source="fullCodigos" />
                            </ReferenceField>
                            <ReferenceField label="Tramo" source="tramo" reference="tramos" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <TextField source="fullCodigos" />
                            </ReferenceField>
                            <ReferenceField label="Segmento" source="segmento" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont3}>
                                <TextField source="fullCodigos" />
                            </ReferenceField>

                            {controllerProps.record && controllerProps.record.tipoElementoSenalPeru === '/tipo_elemento_senal_perus/SV' &&
                                <Typography variant="b" component="b">Coordenadas</Typography>
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenalPeru === '/tipo_elemento_senal_perus/SV' &&
                                <Divider fullWidth style={{ width: '300%', marginTop: '10px' }} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenalPeru === '/tipo_elemento_senal_perus/SV' &&
                                <PRField label="Progresiva" source="abInicio" fullWidth formClassName={classes.grid_cont3s} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenalPeru === '/tipo_elemento_senal_perus/SV' &&
                                <NumberField label="Latitud" style={{ textAlign: 'rigth' }} source="ubicacionMapa[0]['lat']" fullWidth formClassName={classes.grid_cont3} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenalPeru === '/tipo_elemento_senal_perus/SV' &&
                                <NumberField label="Longitud" source="ubicacionMapa[0]['lng']" fullWidth formClassName={classes.grid_cont3} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenalPeru === '/tipo_elemento_senal_perus/SV' &&
                                <NumberField label="Altura (m.s.n.m)" source="alturaIni" fullWidth formClassName={classes.grid_cont3} />
                            }

                            <span></span>
                            <GMapInput
                                defaultZoom={16}
                                defaultCenter={{ lat: 4.7567039, lng: -74.0311056 }}
                                source="ubicacionMapa"
                                multipleMarkers="true"
                                googleKey="AIzaSyCivTXzJBYRBK8nosBYRFXnv-hGXIbhnYY"
                                icon={imagen}
                            />
                        </FormTab>
                        <FormTab label="CARACTERÍSTICAS">

                            {controllerProps.record && controllerProps.record.tipoElementoSenalPeru !== '/tipo_elemento_senal_perus/OTR' &&

                                <TextField label=" Tipo de Panel" source="materialPlaca" fullWidth formClassName={classes.grid_cont4s} />

                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenalPeru === '/tipo_elemento_senal_perus/SV' &&

                                <TextField label="Tipo de poste" source="tipoPoste" fullWidth formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenalPeru === '/tipo_elemento_senal_perus/SV' && controllerProps.record.tipoSenalPeru === '/tipo_senal_perus/SI' &&

                                <TextField label="Tipo de soporte" source="tipoSoporte" fullWidth formClassName={classes.grid_cont3} />
                            }

                            {controllerProps.record && controllerProps.record.tipoElementoSenalPeru === '/tipo_elemento_senal_perus/SV' &&

                                <span></span>
                            }
                            {(controllerProps.record && controllerProps.record.tipoElementoSenalPeru === '/tipo_elemento_senal_perus/SV' && controllerProps.record.tipoSenalPeru === '/tipo_senal_perus/SI' &&
                                <Typography variant="h3" component="h3" className={classes.coord}>Dimensiones Estructura de Soporte Señales Informativas</Typography>
                            )}
                            {controllerProps.record && controllerProps.record.tipoElementoSenalPeru === '/tipo_elemento_senal_perus/SV' &&

                                <span></span>
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenalPeru === '/tipo_elemento_senal_perus/SV' &&

                                <span></span>
                            }
                            {(controllerProps.record && controllerProps.record.tipoElementoSenalPeru === '/tipo_elemento_senal_perus/SV' && controllerProps.record.tipoSenalPeru === '/tipo_senal_perus/SI' &&
                                <Divider fullWidth style={{ marginTop: '10px' }} />
                            )}

                            {(controllerProps.record && controllerProps.record.tipoElementoSenalPeru === '/tipo_elemento_senal_perus/SV' && controllerProps.record.tipoSenalPeru === '/tipo_senal_perus/SI' &&
                                <NumberField label="Ancho (m)" source="anchoSoporte" fullWidth formClassName={classes.grid_cont4s} />
                            )}
                            {(controllerProps.record && controllerProps.record.tipoElementoSenalPeru === '/tipo_elemento_senal_perus/SV' && controllerProps.record.tipoSenalPeru === '/tipo_senal_perus/SI' &&
                                <NumberField label="Altura (m)" source="alturaSoporte" fullWidth formClassName={classes.grid_cont4} />
                            )}
                            {(controllerProps.record && controllerProps.record.tipoElementoSenalPeru === '/tipo_elemento_senal_perus/SV' && controllerProps.record.tipoSenalPeru === '/tipo_senal_perus/SI' &&
                                <TextField label="Dimensión (pulg)" source="dimensionSoporte" fullWidth formClassName={classes.grid_cont4} />
                            )}
                            {(controllerProps.record && controllerProps.record.tipoElementoSenalPeru === '/tipo_elemento_senal_perus/SV' &&

                                <Typography variant="h3" component="h3" className={classes.coord}>Dimensiones de Pedestal</Typography>
                            )}
                            {controllerProps.record && controllerProps.record.tipoElementoSenalPeru === '/tipo_elemento_senal_perus/SV' &&

                                <sapn></sapn>
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenalPeru === '/tipo_elemento_senal_perus/SV' &&

                                <sapn></sapn>
                            }
                            {(controllerProps.record && controllerProps.record.tipoElementoSenalPeru === '/tipo_elemento_senal_perus/SV' &&
                                <Divider fullWidth style={{ marginTop: '10px' }} />
                            )}
                            {(controllerProps.record && controllerProps.record.tipoElementoSenalPeru === '/tipo_elemento_senal_perus/SV' &&
                                <NumberField label="Ancho (m)" source="anchoPedestal" fullWidth formClassName={classes.grid_cont4s} />
                            )}
                            {(controllerProps.record && controllerProps.record.tipoElementoSenalPeru === '/tipo_elemento_senal_perus/SV' &&
                                <NumberField label="Altura (m)" source="alturaPedestal" fullWidth formClassName={classes.grid_cont4} />
                            )}
                            {(controllerProps.record && controllerProps.record.tipoElementoSenalPeru === '/tipo_elemento_senal_perus/SV' &&
                                <NumberField label="Largo (m)" source="largoPedestal" fullWidth formClassName={classes.grid_cont4} />
                            )}
                            {(controllerProps.record && controllerProps.record.tipoElementoSenalPeru === '/tipo_elemento_senal_perus/SV' &&

                                <Typography variant="h3" component="h3" className={classes.coord}>Dimensiones de Panel</Typography>

                            )}
                            {controllerProps.record && controllerProps.record.tipoElementoSenalPeru === '/tipo_elemento_senal_perus/SV' &&

                                <sapn></sapn>
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenalPeru === '/tipo_elemento_senal_perus/SV' &&

                                <sapn></sapn>
                            }
                            {(controllerProps.record && controllerProps.record.tipoElementoSenalPeru === '/tipo_elemento_senal_perus/SV' &&
                                <Divider fullWidth style={{ marginTop: '10px' }} />
                            )}
                            {(controllerProps.record && controllerProps.record.tipoElementoSenalPeru === '/tipo_elemento_senal_perus/SV' &&
                                <NumberField label="Ancho (m)" source="anchoPanel" fullWidth formClassName={classes.grid_cont4s} />
                            )}
                            {(controllerProps.record && controllerProps.record.tipoElementoSenalPeru === '/tipo_elemento_senal_perus/SV' &&
                                <NumberField label="Altura (m)" source="alturaPanel" fullWidth formClassName={classes.grid_cont4} />
                            )}

                            {controllerProps.record && controllerProps.record.tipoElementoSenalPeru === '/tipo_elemento_senal_perus/SH' &&

                                <TextField label="Número de tachas" source="numeroTachas" fullWidth formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenalPeru === '/tipo_elemento_senal_perus/SH' &&

                                <sapn></sapn>
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenalPeru === '/tipo_elemento_senal_perus/DEM' &&

                                <sapn></sapn>
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenalPeru === '/tipo_elemento_senal_perus/OTR' &&

                                <TextField label="Cantidad" source="cantidad" fullWidth formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenalPeru === '/tipo_elemento_senal_perus/OTR' &&

                                <TextField label="Espaciamiento promedio (m)" source="espaciamientoPromedio" fullWidth formClassName={classes.grid_cont4} />
                            }
                            <span></span>

                        </FormTab>

                        <FormTab label="Detalles">

                            <TextField source="estado" fullWidth formClassName={classes.grid_cont4s} />
                            {controllerProps.record && controllerProps.record.estado === "Nuevo" &&
                                <TextField label="Condición" source="condicion" fullWidth formClassName={classes.grid_cont5} />
                            }
                            {controllerProps.record && controllerProps.record.estado !== 'Nuevo' &&

                                <div></div>
                            }
                            <DateField label="Fecha de instalación" source="fechaInstalacion" fullWidth formClassName={classes.grid_cont4s} />
                            <ReferenceField label="Proveedor" source="proveedor" reference="proveedors" linkType={false} fullWidth formClassName={classes.grid_cont4s}>
                                <TextField source="razonSocial" />
                            </ReferenceField>
                            {/* <NumberField label="Garantía" source="garantia"  fullWidth formClassName={classes.grid_cont4}/> */}
                            <BooleanField source="mantenimiento" fullWidth />
                            <TextField source="observaciones" fullWidth />

                        </FormTab>
                        <FormTab label="AuditorÍa">
                            <DateField source="createdAt" label="Fecha creación" fullWidth formClassName={classes.grid_cont4} showTime/>
                            <TextField source="createdBy" label="Creado por" fullWidth formClassName={classes.grid_cont4} />
                            <span></span>
                            <DateField source="updatedAt" label="Fecha actualización" fullWidth formClassName={classes.grid_cont4} showTime/>
                            <TextField source="updatedBy" label="Actualizado por" fullWidth formClassName={classes.grid_cont4} />
                            <span></span>
                        </FormTab>
                    </TabbedForm>
                </ShowView>
            }
        </ShowController>
    )
});
