import React from 'react';
import Typography from '@material-ui/core/Typography';
import {
    REDUX_FORM_NAME, DateField, BooleanField, required, TabbedForm, FormTab, AutocompleteField, SimpleFormIterator,
    ArrayField, NumberField, SelectField, ReferenceField, ReferenceInput, SelectInput, TextField, FormDataConsumer, List, CheckboxGroupField
} from 'react-admin';
import NumberFormat from 'react-number-format';
import { change } from 'redux-form';
import PropTypes from 'prop-types';
import { minValue, maxValue, minLength, maxLength } from 'ra-core';
import { PRInput } from '../../../inputs/PRInput';
import { DInput } from '../../../inputs/DInput';
import { DEInput } from '../../../inputs/DEInput';
import { AInput } from '../../../inputs/AInput';
import { styles } from '../../../EstilosGrid';
import { withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { PField } from '../../../fields/PField';
import { PRField } from '../../../fields/PRField';
import {
    abValidationInicio, abValidationFin, validarDosDecimales, validarUnDecimal, validarAB, isFechaInvalida,
    Altitud, latMininima, lngMininima
} from '../../../../src/resources/validacionesInputs';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import { translate } from 'react-admin';

import config from '../../../config/config';
let urlAppjs = config.UrlServer;

class TablaTransformacion extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            titulos: [],
            record: [],
            tipo: "",
            datos: {
                departamentos: [],
                municipios: [],
                tipo_calzadas: [],
                tipo_segmentos: [],
                tipo_elementos: []
            },
            default: {
                departamentos: "",
                municipios: "",
            },
            checkeados: {},
            departamentos: {},
            defaultCheck: false,
            dataGet: this.props.data
        }

        // this.departamentoDefault = this.props.departamentoDefault
        // this.fetcher.bind(this);
    }

    componentDidMount() {
        //this.setState({ datoEva: this.props.record })   
        this.setState({ record: this.props.record })
        this.setState({ tipo: this.props.tipo })
        // console.log(this.props.tipoElementos) 
    }

    render() {
        const { titulos } = this.state;
        const { record } = this.state;
        const { tipo } = this.state;
        const { classes } = this.props;
        const { input } = this.props;
        const { translate } = this.props;

        const handleChange = (i, checked) => {
            let statusChecked = document.getElementById('all_check');
            if (statusChecked != null) {
                if (statusChecked.checked == true) {
                    document.getElementById('all_check').checked = false;
                }
            }
        }
        const allChecked = (source) => {
            if (document.getElementById('all_check') != null) {
                let statusChecked = document.getElementById('all_check').checked;
                let checkboxes = document.getElementsByName('check');
                for(var i=0, n=checkboxes.length;i<n;i++) {
                    checkboxes[i].checked = statusChecked;
                }
            }
        }
        return (
                <div style={{overflowX: 'auto', width: "80vw"}}>
                        <FormDataConsumer style={{ width: "100%" }}>
                            {({ formData, dispatch, selected, record, resource, id,  onToggleItem, basePath, ...rest}) => {

                                return (
                                    <Table
                                    //  style={(this.props.displayGenSegmento == true ? { width: '100%' } : { display: "none" })} 
                                     >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ width: '3%' }} component="th">
                                                    {/* <input type="checkbox" source={`all_check`} id={`all_check`} name={`all_check`} onChange={allChecked} /> */}
                                                </TableCell>
                                                <TableCell style={{ width: '5%' }} component="th">{translate('dynatest.resources.transformacion_segmentos.table.nameColumns.codigo')}</TableCell>
                                                <TableCell style={{ width: '5%' }} component="th">{translate('dynatest.resources.transformacion_segmentos.table.nameColumns.absInicio')}</TableCell>
                                                <TableCell style={{ width: '5%' }} component="th">{translate('dynatest.resources.transformacion_segmentos.table.nameColumns.absFinal')}</TableCell>
                                                <TableCell style={{ width: '4%' }} component="th">{translate('dynatest.resources.transformacion_segmentos.table.nameColumns.longitudCol')}</TableCell>
                                                <TableCell style={{ width: '5%' }} component="th">{translate('dynatest.resources.transformacion_segmentos.table.nameColumns.prInicio')}</TableCell>
                                                <TableCell style={{ width: '5%' }} component="th">{translate('dynatest.resources.transformacion_segmentos.table.nameColumns.prFinal')}</TableCell>
                                                <TableCell style={{ width: '10%' }} component="th">{translate('dynatest.resources.transformacion_segmentos.table.nameColumns.departamento')}</TableCell>
                                                <TableCell style={{ width: '10%' }} component="th">{translate('dynatest.resources.transformacion_segmentos.table.nameColumns.municipio')}</TableCell>
                                                <TableCell style={{ width: '5%' }} component="th">{translate('dynatest.resources.transformacion_segmentos.table.nameColumns.latitudInicial')}</TableCell>
                                                <TableCell style={{ width: '5%' }} component="th">{translate('dynatest.resources.transformacion_segmentos.table.nameColumns.longitudInicial')}</TableCell>
                                                <TableCell style={{ width: '5%' }} component="th">{translate('dynatest.resources.transformacion_segmentos.table.nameColumns.alturaInicial')}</TableCell>
                                                <TableCell style={{ width: '5%' }} component="th">{translate('dynatest.resources.transformacion_segmentos.table.nameColumns.latitudFinal')}</TableCell>
                                                <TableCell style={{ width: '5%' }} component="th">{translate('dynatest.resources.transformacion_segmentos.table.nameColumns.longitudFinal')}</TableCell>
                                                <TableCell style={{ width: '5%' }} component="th">{translate('dynatest.resources.transformacion_segmentos.table.nameColumns.alturaFinal')}</TableCell>
                                                <TableCell style={{ width: '5%' }} component="th">{translate('dynatest.resources.transformacion_segmentos.table.nameColumns.tipoCalzada')}</TableCell>
                                                <TableCell style={{ width: '5%' }} component="th">{translate('dynatest.resources.transformacion_segmentos.table.nameColumns.tipoSegmento')}</TableCell>
                                                <TableCell style={{ width: '5%' }} component="th">{translate('dynatest.resources.transformacion_segmentos.table.nameColumns.tipoSuperficie')}</TableCell>
                                                <TextField style={{ display: 'none' }} source={`cant_segmentos`} />
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {
                                            this.state.dataGet.map((data, i) => (
                                                    <TableRow>
                                                        <TableCell padding="none">
                                                            <input type="checkbox" label=" " value={data.id} id={`check_${i}`} name={`check`} onChange={() => handleChange(i)} />
                                                        </TableCell>
                                                        <TableCell>
                                                            {data.fullCodigo}
                                                        </TableCell>
                                                        <TableCell style={{ textAlign: 'right' }} >
                                                            <NumberFormat  
                                                                value={data.abInicio}
                                                                prefix="K"
                                                                decimalSeparator='+'
                                                                decimalScale={3}
                                                                fixedDecimalScale={true}
                                                                allowNegative={false}    
                                                                displayType={'text'}    
                                                            />
                                                            {/* <PRField defaultValue={data.abInicio} /> */}
                                                        </TableCell>
                                                        <TableCell style={{ textAlign: 'right' }}>
                                                            <NumberFormat
                                                                prefix="K"
                                                                decimalSeparator='+'
                                                                decimalScale={3}
                                                                fixedDecimalScale={true}
                                                                allowNegative={false}    
                                                                displayType={'text'} 
                                                                value={data.abFin}
                                                            />
                                                        </TableCell>
                                                        <TableCell style={{ textAlign: 'right' }}>
                                                            <NumberFormat
                                                                value={(data.pFin - data.pInicio).toFixed(1)}
                                                                displayType={'text'}
                                                            />
                                                        </TableCell>
                                                        <TableCell style={{ textAlign: 'right' }}>
                                                            <NumberFormat
                                                                value={data.pInicio}
                                                                prefix="PR"
                                                                decimalSeparator='+'
                                                                decimalScale={3}
                                                                fixedDecimalScale={true}
                                                                allowNegative={false}    
                                                                displayType={'text'} 
                                                            />
                                                        </TableCell>
                                                        <TableCell style={{ textAlign: 'right' }}>
                                                            <NumberFormat
                                                                value={data.pFin}
                                                                prefix="PR"
                                                                decimalSeparator='+'
                                                                decimalScale={3}
                                                                fixedDecimalScale={true}
                                                                allowNegative={false}    
                                                                displayType={'text'} 
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            {data.departamentos}
                                                        </TableCell>
                                                        <TableCell>
                                                            {data.municipios}
                                                        </TableCell>
                                                        <TableCell style={{ textAlign: 'right' }}>
                                                            <NumberFormat
                                                                value={data.latitud}
                                                                displayType={'text'}
                                                            />
                                                        </TableCell>
                                                        <TableCell style={{ textAlign: 'right' }}>
                                                            <NumberFormat
                                                                value={data.longitud}
                                                                displayType={'text'}
                                                            />
                                                        </TableCell>
                                                        <TableCell style={{ textAlign: 'right' }}>
                                                            <NumberFormat
                                                                value={data.alturaIni}
                                                                displayType={'text'}
                                                            />
                                                        </TableCell>
                                                        <TableCell style={{ textAlign: 'right' }}>
                                                            <NumberFormat
                                                                value={data.latitud2}
                                                                displayType={'text'}
                                                            />
                                                        </TableCell>
                                                        <TableCell style={{ textAlign: 'right' }}>
                                                            <NumberFormat
                                                                value={data.longitud2}
                                                                displayType={'text'}
                                                            />
                                                        </TableCell>
                                                        <TableCell style={{ textAlign: 'right' }}>
                                                            <NumberFormat
                                                                value={data.alturaFin}
                                                                displayType={'text'}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <ReferenceInput
                                                                source="tipoCalzada"
                                                                reference="tipo_calzadas"
                                                                label=" "
                                                                sort={{ field: 'customOrder', order: 'ASC' }}
                                                            >
                                                                <SelectInput optionText="nombre" value={data.tipoCalzada} options={{ disabled:true,  readOnly: true }}/>
                                                            </ReferenceInput>
                                                        </TableCell>
                                                        <TableCell>
                                                            <ReferenceInput
                                                                source="tipoSegmento"
                                                                reference="tipo_segmentos"
                                                                label=" "
                                                                sort={{ field: 'customOrder', order: 'ASC' }}
                                                            >
                                                                <SelectInput optionText="nombre" value={data.tipoSegmento} options={{ disabled:true,  readOnly: true }}/>
                                                            </ReferenceInput>
                                                        </TableCell>
                                                        <TableCell>
                                                            <ReferenceInput
                                                                source="tipoSuperficie"
                                                                reference="tipo_elementos"
                                                                label=" "
                                                                filter={{ sistema: 'CLZ' }}
                                                                sort={{ field: 'customOrder', order: 'ASC' }}
                                                            >
                                                                <SelectInput optionText="nombre" value={data.tipoSuperficie} options={{ disabled:true,  readOnly: true }}/>
                                                            </ReferenceInput>
                                                        </TableCell>
                                                    </TableRow>
                                            ))
                                        }
                                        </TableBody>

                                    </Table>
                                )
                            }

                            }
                        </FormDataConsumer >
                    </div>
        )

    }
}

export default translate(TablaTransformacion);