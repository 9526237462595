import React, { Component, Fragment } from 'react';

import Drawer from '@material-ui/core/Drawer';

import IconImageEye from '@material-ui/icons/RemoveRedEye';
import IconKeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { Button } from 'react-admin';

export class ReferenceImageButton extends Component {
    state = { showPanel: false };

    handleClick = () => {
        this.setState({ showPanel: true });
    };

    handleCloseClick = () => {
        this.setState({ showPanel: false });
    };

    render() {
        const { showPanel } = this.state;
        // const { id } = this.props;
        return (
            <Fragment>
                <Button onClick={this.handleClick} label="Imagen">
                    <IconImageEye />
                </Button>
                <Drawer
                    anchor="right"
                    open={showPanel}
                    onClose={this.handleCloseClick}
                >
                    <div>
                        <Button label="Close" onClick={this.handleCloseClick}>
                            <IconKeyboardArrowRight />
                        </Button>
                    </div>
                    <img src="https://ich.cl/wp-content/uploads/2018/09/pavimento-de-concreto-losas-cortas.jpg" alt="Logo" />
                </Drawer>
            </Fragment>
        );
    }
}

export default ReferenceImageButton;