import React from 'react';

import { TextInput, SimpleForm, SaveButton, Toolbar} from 'react-admin';

import { withStyles } from '@material-ui/core';
import { Edit } from './../../../Edit';

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
));

const styles = {
    first_field: { display: 'inline-block', width: '30%' },    
};

export const TipoDominioEdit = withStyles(styles)(({ classes, ...props }) => (
        <Edit title="Editar tipo de dominio" {...props} undoable={false}>
            <SimpleForm submitOnEnter={false} redirect="list" toolbar={<CustomToolbar />} >
            <TextInput source="regla" label="Tipo de dominio"/>                
            </SimpleForm>
        </Edit>
))