import React, { Fragment } from 'react';
import { List, Datagrid, TextField, EditButton } from 'react-admin';
import {  BulkDeleteWithConfirmButton  } from 'react-admin';

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);

export const MedidoPorList = props => (
    <List {...props} title="Lista usuarios" bulkActionButtons={<PostBulkActionButtons />}>
        <Datagrid>          
            <TextField source="nombre" label="Medido por"/>                      
            <TextField label=" "/>                      
            <TextField label=" "/>                                  
            <EditButton />
        </Datagrid>
    </List>
);