import PropTypes from 'prop-types';
import prFormatter from './../format/PRFormat';

/**
 * 
 * , isNumericString:true
 */

export const PRField = ({ source, record = {}, props }) => prFormatter({value: record[source], displayType:'text', ...props});

PRField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

PRField.defaultProps = {
    addLabel: true,
};
