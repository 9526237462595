import React from 'react';
import { SimpleForm,Toolbar,SaveButton} from 'react-admin';
import { PimsCreate } from './../../Create';
import  {styles}  from './../../../EstilosGrid';
import { withStyles } from '@material-ui/core';
import BackButton from '../../../BotonCancelar'
import { translate } from 'react-admin';

import {ComponenteDeterioroPers} from '../componentes/ComponeteReparacionPers';

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const PimsToolbarActions = translate(withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <SaveButton redirect={false} label='ra.action.save_add' />
        <SaveButton redirect='list' label='ra.action.save_end' />
        <BackButton />
    </Toolbar>
)));

export const ReparacionPersCreate = translate(withStyles(styles)(({ classes, ...props }) => (
    <PimsCreate {...props}>
        <SimpleForm redirect="list" toolbar={<PimsToolbarActions/>} >
            <ComponenteDeterioroPers/>
        </SimpleForm>
    </PimsCreate>
)));