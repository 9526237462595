export default function extractGoogleCoords(plainText) {
  let parser = new DOMParser()
  let newPlainText = plainText.replace(/xsi.*"/, "")
  let xmlDoc = parser.parseFromString(newPlainText, "text/xml")
  let googlePolygons = []
  let googleMarkers = []
  if (xmlDoc.documentElement.nodeName == "kml") {
    for (const item of xmlDoc.getElementsByTagName('Placemark')) {
      let InfoPoly = [];
      if (item.getElementsByTagName('description').length > 0) {
        let desc = item.getElementsByTagName('description')[0].childNodes[0].data;
        //Con el html interno de cada placemark se obtienen los datos necesarios // solo formato ArcGIS????
        let descParser = new DOMParser()
        let htmlDoc = descParser.parseFromString(desc, "text/html")
        let tdDatos = htmlDoc.getElementsByTagName('td')
        //console.log(tdDatos)
        if (tdDatos && tdDatos.length >= 16) {
          try {
            InfoPoly['unidadFuncional'] = tdDatos[7].childNodes[0].data ? tdDatos[7].childNodes[0].data.trim() : "";
            InfoPoly['segmento'] = tdDatos[9].childNodes[0].data ? tdDatos[9].childNodes[0].data.trim() : "";
            InfoPoly['abscisaIni'] = tdDatos[11].childNodes[0].data ? tdDatos[11].childNodes[0].data.trim() : "";
            InfoPoly['abscisaFin'] = tdDatos[13].childNodes[0].data ? tdDatos[13].childNodes[0].data.trim() : "";
            InfoPoly['carril'] = tdDatos[15].childNodes[0].data ? tdDatos[15].childNodes[0].data.trim() : "";
          } catch (error) {
            console.log(error)
          }
        }
      }
      //--------------------------------------------------------
      let polygons = item.getElementsByTagName('Polygon');
      /** POLYGONS PARSE **/
      for (const polygon of polygons) {
        let coords = polygon.getElementsByTagName('coordinates')[0].childNodes[0].nodeValue.trim()
        let points = coords.split(" ")
        let googlePolygonsPaths = []
        for (const point of points) {
          let coord = point.split(",")
          googlePolygonsPaths.push({ lat: +coord[1], lng: +coord[0] })
        }
        googlePolygons.push({ 'arreglo': googlePolygonsPaths, 'informacion': InfoPoly })
      }
    }
  } else {
    throw "error while parsing"
  }

  return { markers: googleMarkers, polygons: googlePolygons }

}