import React, { useState, Fragment } from 'react';
import {
    Query, Loading, ImageField, ArrayInput, SimpleFormIterator, ImageInput,
    ReferenceInput, required, SelectInput, TextInput
} from 'react-admin';

import { translate } from 'react-admin';

import Drawer from '@material-ui/core/Drawer';

import IconImageEye from '@material-ui/icons/RemoveRedEye';
import IconKeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { Button } from 'react-admin';
// import { CarouselImage } from './CarouselImagePeru';

export const ArrayInputImagesEdit = translate(({
    record,
    ImagenesOpen
}) => {

    if (typeof record !== 'undefined' && record.images !== [] && record.images !== null && record.images !== '' && record.images.length != 0) {
        if (record.images.length > 0 && typeof record.images[0] === "string") {
            const resource = record.images[0].split("/")[1];
            const payload = {
                pagination: { page: 1, perPage: 10 },
                sort: { field: 'id', order: 'ASC' },
                filter: {
                    recurso: record.id
                }
            };

            const preverntSave = (value) => {
                return "Espere mientras la información de imagenes se carga."
            }
            return (
                <Query type="GET_LIST" resource={resource} payload={payload}>
                    {({ data, loading, error }) => {
                        if (loading) {
                            return (
                                <Fragment>
                                    {/* <ArrayInput label="" validate={preverntSave}> */}
                                    <Loading />
                                    {/* </ArrayInput> */}
                                </Fragment>
                            );
                        }
                        if (error) { return <p>ERROR</p>; }

                        var arrayDefaults = [];
                        data.map(item => arrayDefaults.push({
                            tipoImagen: item.tipoImagen,
                            images: [item.src],
                            "@id": item.id,
                            id: item.id,
                        }));
                        // console.log(arrayDefaults);

                        return (
                            <ArrayInput label="" source="images2" defaultValue={arrayDefaults} >
                                <SimpleFormIterator disableAdd disableRemove >
                                    <ReferenceInput disabled label="dynatest.resources.imagenesInventario.titleImagen" source="tipoImagen" reference="tipo_images" fullWidth validate={[required()]} sort={{ field: 'nombre', order: 'ASC' }}>
                                        <SelectInput optionText="nombre" />
                                    </ReferenceInput>
                                    <ImageInput multiple={true}  labelMultiple="dynatest.resources.imagenesInventario.descripcionImagen" source="images" label=" " maxSize={1000000000} accept="image/jpeg, image/png">
                                        <ImageField source="" title="title" />
                                    </ImageInput>
                                </SimpleFormIterator>
                            </ArrayInput>
                        )
                    }}
                </Query>
            );
        } else if (typeof record.images !== 'undefined' && record.images !== [] && record.images !== null && record.images !== '') {
            if (record.images.length > 0) {
                var arrayDefaults = [];
                record.images.map(item => arrayDefaults.push({
                    tipoImagen: item.tipoImagen,
                    images: [item.src],
                    "@id": item.id,
                    id: item.id,
                }));

                return (
                    <ArrayInput label="" source="images2" defaultValue={arrayDefaults} >
                        <SimpleFormIterator disableAdd disableRemove >
                            <ReferenceInput disabled label="dynatest.resources.imagenesInventario.titleImagen" source="tipoImagen" reference="tipo_images" fullWidth validate={[required()]} sort={{ field: 'nombre', order: 'ASC' }}>
                                <SelectInput optionText="nombre" />
                            </ReferenceInput>
                            <ImageInput multiple={true}  labelMultiple="dynatest.resources.imagenesInventario.descripcionImagen" source="images" label=" " maxSize={1000000000} accept="image/jpeg, image/png">
                                <ImageField source="src" title="title" />
                            </ImageInput>
                        </SimpleFormIterator>
                    </ArrayInput>
                );
            }
        } else {
            return (<div></div>)
        }
    }
    else if (typeof ImagenesOpen !== 'undefined' && ImagenesOpen !== [] && ImagenesOpen !== null && ImagenesOpen !== '') {
        console.log("imagen open");
        if (ImagenesOpen.length > 0) {
            var arrayDefaults = [];
            ImagenesOpen.map(item => arrayDefaults.push({ tipoImagen: item.tipoImagen }));
            //console.log("legacy", arrayDefaults);
            return (

                <ArrayInput label="" source="images2" defaultValue={arrayDefaults} >
                    <SimpleFormIterator disableAdd disableRemove >
                        <ReferenceInput disabled label="dynatest.resources.imagenesInventario.titleImagen" filter={{ "properties[]": "nombre" }} source="tipoImagen" reference="tipo_images" fullWidth validate={[required()]} sort={{ field: 'nombre', order: 'ASC' }}>
                            <SelectInput optionText="nombre" />
                        </ReferenceInput>
                        <ImageInput multiple={true}  labelMultiple="dynatest.resources.imagenesInventario.descripcionImagen" source="images" label=" " maxSize={1000000000} accept="image/jpeg, image/png">
                            <ImageField source="src" title="title" />
                        </ImageInput>
                    </SimpleFormIterator>
                </ArrayInput>
            );
        }
        else {

            return (<div></div>)
        }
    }
    else {
        return (<div></div>)
    }
});