import React, { useState } from 'react';
import { List, Datagrid, TextField,  EditButton, ShowButton, NumberField, Filter, ReferenceInput, SelectInput,
        AutocompleteInput, Confirm 
} from 'react-admin';
import { PRField } from './../../fields/PRField';
import config from '../../config/config';
import {  WithPermissions,BulkDeleteWithConfirmButton, Loading  } from 'react-admin';
import { Fragment } from 'react';
import { PField } from './../../fields/PField';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import { translate } from 'react-admin';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconCancel from '@material-ui/icons/Cancel';
import { CardActions, CreateButton, ExportButton } from 'react-admin';
let urlAppjs = config.UrlServer;

export const UnidadFuncionalList = translate(({ permissions, ...props }) => {
    const { translate } = props;
    const [open, setOpen] = React.useState(false);
    const handleClose = value => {
        setOpen(false);
    };

    const UnidadFuncionalFilter = (props) => (
        <Filter {...props}>
            <ReferenceInput 
                label="resources.unidad_funcionals.fields.ruta" 
                source="ruta"  
                reference="rutas" 
                filterToQuery={searchText => ({ codigo: searchText })} 
                sort={{field : 'codigo', order : 'asc'}}
                alwaysOn
                fullWidth 
            >
                <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
            </ReferenceInput>
        </Filter>
    );
        
    const EliminarRegistro = (props) => {
        let datos = Object.values(props);
        console.log(datos)
        datos[3].forEach(element => {
            let ids      = element.replace(/\D/g,"");
            let elemen   = element.replace("/unidad_funcionals/", "");
            let elemento = elemen.replace(/\d/g,""); 
            
            const dataSend = new FormData();
            dataSend.append('id', ids);
            dataSend.append('elemento', 'unidad_funcional');
            let requestOptionsReplace = {
                method: 'POST',
                headers: { 'authorization': 'Bearer ' + localStorage.getItem('token') },
                body: dataSend
            };
            fetch(urlAppjs + "/eliminarRegistro/", requestOptionsReplace)
            .then(response => response.json())
            .then((dataRequestInventario) => {
                if(dataRequestInventario == "Success"){
                    console.log(dataRequestInventario, "OK")
                    window.location.reload();
                }
            }).catch((error) => {
                setOpen(true);
                console.log(error)
            });
        });
    } 
    
    const PostBulkActionButtons = (props) => { 
        const [open, setOpen] = useState(false);
        const handleClick = () => setOpen(true);
        const handleDialogClose = () => setOpen(false);
        const handleConfirm = () => {
            EliminarRegistro(props);
            setOpen(false);
        };

        return (
            <>
                <Button label="Update Posts" onClick={handleClick} style={{
                    fontSize: '0.8125rem', color: '#f44336', border: 0,
                    display: 'inline-flex', alignItems: 'center',
                    width: '7rem', height: '2rem'
                }}>
                    <DeleteIcon style={{ width: '1.3rem', paddingRight: '0.3rem' }} />{translate('resources.validaciones.borrar')}
                </Button>
                
                <Confirm
                    isOpen={open}
                    title="resources.validaciones.valBorrarUnidadFuncional"
                    content="resources.validaciones.valBorrar"
                    onConfirm={handleConfirm}
                    onClose={handleDialogClose}
                />
            </>
        );
                    
    };
    function SimpleDialog(props) {
        const { open } = props;
        const handleClose = () => {
            setOpen(false);
        };
    
        return (
            <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle id="simple-dialog-title">{translate('resources.validaciones.valEliminacion')}</DialogTitle>
                <DialogContent>
                    {translate('resources.validaciones.valRegistroAsociado')}
                </DialogContent>
                <DialogActions>
                    <Button label="Cerrar" onClick={handleClose}>
                        <IconCancel />
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
    const ListAction = ({ basePath, data, resource }) => (
        <CardActions>
            <CreateButton basePath={basePath} /> 
            <ExportButton basePath={basePath}/>
        </CardActions>
    );
    const ListAction2 = ({ basePath, data, resource}) => (
        <CardActions>
            <CreateButton basePath={basePath} /> 
        </CardActions>
    );
    if (!permissions) return <Loading />;  
    return (
        <Fragment>
            <SimpleDialog open={open} onClose={handleClose} />
            <List {...props} 
                actions={permissions['unifuncionales']  == 1 ? <ListAction /> : permissions['unifuncionales']  == 2 ? <ListAction2 />: null} 
                sort={{ field: 'updatedAt', order: 'DESC' }}
                filters={<UnidadFuncionalFilter/>}  
                bulkActionButtons={permissions['unifuncionales']  == 1 ? <PostBulkActionButtons />: null}>
                <Datagrid> 
                    <TextField source="codigo" />   
                    <PField source="pInicio"/>
                    <PField source="pFin"/>                  
                    <PRField  source="abInicio"/>
                    <PRField  source="abFin"/>  
                    <NumberField source="fullResta" sortable={false}/> 
                    {permissions['unifuncionales'] == 1 || permissions['unifuncionales'] == 2 || permissions['unifuncionales'] == 4 ? <ShowButton/>: null}
                    {permissions['unifuncionales'] == 1 || permissions['unifuncionales'] == 2 ? <EditButton/>: null}    
                </Datagrid>
            </List>
        </Fragment>
)});