import PropTypes from 'prop-types';
import pFormatter from './../format/PFormat';

/**
 * 
 * , isNumericString:true
 */

export const PField = ({ source, record = {}, props }) => pFormatter({value: record[source], displayType:'text', ...props});

PField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

PField.defaultProps = {
    addLabel: true,
};
