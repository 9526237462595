import React from 'react';
import NumberFormat from 'react-number-format';



export default function pFormatter(props){
    const { inputRef, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            prefix="PR"
            decimalSeparator='+'
            decimalScale={3}
            fixedDecimalScale={true}
            allowNegative={false}            
        />
      );
}