import React, {useState} from 'react';
import { NumberInput, ReferenceInput, ArrayInput, SimpleFormIterator, AutocompleteInput,
        SelectInput, FormDataConsumer,  DateInput, ImageInput, ImageField,
        TextInput, BooleanInput, maxLength
    } from 'react-admin';
import { PRInput } from '../../inputs/PRInput';
import { PInput } from '../../inputs/PInput';
import Grid from '@material-ui/core/Grid';
import { Edit } from './../Edit';
import { withStyles } from '@material-ui/core';
import {latMininima, lngMininima,abValidationInicio, abValidationFin, validarUnDecimal, Entero, 
         validarDosDecimales,validarAB, isFechaInvalida, prValidationInicio, validarPR, prValidationFin, validacionmaxfechaact
    } from '../validacionesInputs';
import Divider from '@material-ui/core/Divider';
import  {styles}  from './../../EstilosGrid';
import {
    required,
    FormTab,
    
    maxValue
} from 'react-admin';
import { minValue } from 'ra-core';
import Typography from '@material-ui/core/Typography';
import { ButtonCarousel } from './../images/ButtonCarouselPeru';

import TabbedForm from '../TabbedForm'
import CoordenadasProyecto from '../CoordenadasInventario';
import AbsSegmento from '../AbsSegmento';
import { ArrayInputImagesEdit } from './../images/ArrayInputImagesEdit';
import { translate } from 'react-admin';


const defensachoices = 
[
    { id: '1', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniAbatidaEsviadaTerFinAbatida' },
    { id: '2', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniAbatidaEsviadaTerFinAbatidaEsviada' },
    { id: '3', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniAbatEsviadaTermFinEnterradaTalud' },
    { id: '4', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniAbatEsviadaTermFinColaPez' },
    { id: '5', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniAbatTerminalFinAbatida' },
    { id: '6', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniAbatTerminalFinAbatidaEsviada' },
    { id: '7', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniAbatTerminalFinColaPez' },
    { id: '17', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniAbatTerminalFinSalidaAerea' },
    { id: '8', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniAbatTerminalFinEnterradaTalud' },
    { id: '18', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniAbatTerminalFinIncrustadaObraArte' },
    { id: '10', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniColaPezTermiFinAbatidaEsviada' },
    { id: '9', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniColaPezTermiFinAbatida' },
    { id: '11', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniColaPezTermiFinColaPez' },
    { id: '12', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniColaPezTermiFinEnterradaTalud' },
    { id: '19', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniAmortiguadorImpacTermFinAbatida' },
    { id: '21', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniAmortiguadorImpacTermFinColaPez' },
    { id: '22', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniAmortiguadorImpacTermFinSalAerea' },
    { id: '20', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniAmortiguadorImpacTermFinEnterradaTalud' },
    { id: '23', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniAmortiguadorImpacTermFinIncrusObraArte' },
    { id: '14', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniEnterrTaludTermFinAbatEsviada' },
    { id: '13', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniEnterrTaludTermFinAbatida' },
    { id: '15', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniEnterrTaludTermFinColaPez' },
    { id: '16', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniEnterrTaludTermFinEnterrTalud' },
    { id: '24', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniEsviadaTermFinAbatida' },
    { id: '25', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniEsviadaTermFinSalidaAerea' },
    { id: '26', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniEsviadaTermFinEnterradaTalud' },
    { id: '27', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniEsviadaTermFinIncrustadaObraArte' },
    { id: '28', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniIncrustObraArteTermFinalAbatida' },
    { id: '29', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniIncrustObraArteTermFinalSalidaAerea' },
    { id: '30', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniIncrustObraArteTermFinalEnterradaTalud' },
    { id: '31', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniIncrustObraArteTermFinalEsviada' },                                                  
]
const optionRendererDefensa = choice => `${choice.name}`;

export const SeguridadEdit= translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;

const [elemento , setElemento]= useState('');
const ValidarElemento =(value) =>{  
    setElemento(value)
}
  
const [unidads , setUnidad] = useState('');
const [tramos , setTramo]= useState('');
const [segmentos , setSegmento]= useState('');

const ValidarSegmento = (value)  => {             
    setSegmento(value);
}

const ValidarUnidad = (value) => {
    let lenghtObject = Object.keys(value);
    let valor = '';
    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setUnidad(value);
}

const Unidades = { unidadFuncional: unidads };

const ValidarTramo = (value) => {
    let lenghtObject = Object.keys(value);
    let valor = '';
    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setTramo(value);
}
const Tramoss = { tramo: tramos };

/*VALORES POR DEFECTO DEL ARRAY DE COORDENADAS*/
    const [latitudMinimaArray , setLatitudArray]= useState('');
    const LatitudMinimaArray =(value) =>{           
        setLatitudArray(value) 
    }
    const [longitudMinimaArray , setLongitudArray]= useState('');
    const LongitudMinimaArray =(value) =>{           
        setLongitudArray(value) 
    }
    const [longitudMaximaArray , setLongitudMaxdArray]= useState('');
    const LongitudMaximaArray =(value) =>{           
        setLongitudMaxdArray(value) 
    }
    const [latitudMaximaArray , setLatitudMaxdArray]= useState('');
    const LatitudMaximaArray =(value) =>{           
        setLatitudMaxdArray(value) 
    }
/*FIN DE LOS VALORES POR DEFECTO DEL ARRAY DE COORDENADAS*/

    const [estados, setEstado] = useState('');
    const ValidarEstado =(value) =>{                
        setEstado(value)
        
    }
/*VALIDAR IMÁGENES ACTUALES */
    const [imagenesOpen,setImagenesOpen] = useState('');
    const ImagenesEdit = (value) =>{
        setImagenesOpen(value);
        
    }
    /*OPCIONES PARA TIPO DE IMÁGENES*/
    const [defaultTipoImagen,setDefaultTipoImagen] = useState([{id: 'Cargando...' ,name :'Cargando...'}]);
    const OpcionTipoImagen = (value) =>{
        if(value !== '/tipo_elementos/CAPT' && value !== '/tipo_elementos/TPVH' && value !== '/tipo_elementos/CERC' && value !== '/tipo_elementos/BORD' ){
            setDefaultTipoImagen([
                {
                    tipoImagen : '/tipo_images/1',
                },
                {
                    tipoImagen : '/tipo_images/7',
                },
                {
                    tipoImagen : '/tipo_images/8',
                },
                {
                    tipoImagen : '/tipo_images/9',
                },
                {
                    tipoImagen : '/tipo_images/5',
                },
                {
                    tipoImagen : '/tipo_images/6',
                }
            ])
        }else if(value === '/tipo_elementos/CERC'){
            setDefaultTipoImagen([
                {
                    tipoImagen : '/tipo_images/1',
                },
                {
                    tipoImagen : '/tipo_images/7',
                },            
            ])
        }else{
            setDefaultTipoImagen([
                {
                    tipoImagen : '/tipo_images/1',
                },
                {
                    tipoImagen : '/tipo_images/7',
                },
                {
                    tipoImagen : '/tipo_images/5',
                },
                {
                    tipoImagen : '/tipo_images/6',
                }
            ])
        }       
    }

  return(
    <Edit  {...props} title="dynatest.resources.seguridads.title.editTitle" undoable={false}>                   
       <TabbedForm redirect="list" >
            <FormTab label="dynatest.resources.seguridads.tabs.tabsTitle.identificacion">
            <Grid container spacing={24}>
             
                    <CoordenadasProyecto/>
               
                <Grid item xs={4}>
                    <ReferenceInput 
                        label="resources.seguridads.fields.tipoElemento" 
                        disabled 
                        source="tipoElemento" 
                        reference="tipo_elementos" 
                        validate={[required(), ValidarElemento, OpcionTipoImagen]} 
                        filter={{sistema: 'SEG'}} 
                        fullWidth 
                        sort={{ 
                            field: 'nombre', 
                            order: 'ASC'
                        }} 
                    >                               
                        <SelectInput  optionText="nombre"   />
                    </ReferenceInput>
                </Grid>
                <Grid item xs={4}>
                    <SelectInput fullWidth label="resources.seguridads.fields.margen" source="margen" validate={[required()]}  choices={[
                        { id: translate('dynatest.resources.seguridads.select.margen.derechaId'), name: 'dynatest.resources.seguridads.select.margen.derechaName' },
                        { id: translate('dynatest.resources.seguridads.select.margen.izquierdaId'), name: 'dynatest.resources.seguridads.select.margen.izquierdaName' },
                        { id: translate('dynatest.resources.seguridads.select.margen.centroId'), name: 'dynatest.resources.seguridads.select.margen.centroName' },
                       ]}
                    />
                </Grid>
                <Grid item xs={4}>
                    <SelectInput label="resources.seguridads.fields.sentido" source='sentido' choices={[
                        { id: translate('dynatest.resources.seguridads.select.sentido.norteId'), name: 'dynatest.resources.seguridads.select.sentido.norteName' },
                        { id: translate('dynatest.resources.seguridads.select.sentido.surId'), name: 'dynatest.resources.seguridads.select.sentido.surName' },
                        { id: translate('dynatest.resources.seguridads.select.sentido.esteId'), name: 'dynatest.resources.seguridads.select.sentido.esteName' },
                        { id: translate('dynatest.resources.seguridads.select.sentido.oesteId'), name: 'dynatest.resources.seguridads.select.sentido.oesteName' }
                        ]}
                        fullWidth
                        formClassName={classes.grid_cont4}/>
                </Grid>
                <Grid item xs={12}>
                    <h3 style={{marginBottom: 0}}>{translate('dynatest.resources.seguridads.subTitle.localizacion')}</h3>                    
                    <hr></hr>
                </Grid>        
                        <Grid item xs={4}>
                            <ReferenceInput 
                                label="resources.seguridads.fields.unidadFuncional" 
                                source="unidadFuncional"  
                                reference="unidad_funcionals" 
                                filterToQuery={searchText => ({ codigo: searchText })} 
                                sort={{field : 'codigo', order : 'asc'}}
                                validate={[required(), ValidarUnidad]}
                                fullWidth
                                disabled
                            >
                                <AutocompleteInput options={{fullWidth : true, disabled: true}}  optionText="fullCodigos"/>
                            </ReferenceInput>
                        </Grid>
                        <Grid item xs={4}>     
                            <ReferenceInput 
                                label="resources.seguridads.fields.tramo" 
                                source="tramo" 
                                reference="tramos" 
                                filter={Unidades} 
                                filterToQuery={searchText => ({ codigo: searchText })} 
                                sort={{ field: 'codigo', order: 'ASC' ,}}
                                validate={[required(), ValidarTramo]}
                                formClassName={classes.grid_cont4}
                                disabled
                            >
                                <AutocompleteInput  options={{fullWidth : true, disabled: true}} optionText="fullCodigos" />
                            </ReferenceInput>                           
                        </Grid>   
                        <Grid item xs={4}> 
                            <ReferenceInput 
                                label="resources.seguridads.fields.segmento" 
                                source="segmento" 
                                reference="segmentos" 
                                sort={{ field: 'codigo', order: 'ASC' }}
                                filter={Tramoss}  
                                filterToQuery={searchText => ({ codigo: searchText })} 
                                validate={[required(), ValidarSegmento]}
                                formClassName={classes.grid_cont4}
                            >
                            
                                <AutocompleteInput  options={{fullWidth : true, disabled: true}} optionText="fullCodigos" disabled />
                            </ReferenceInput>
                        </Grid>
                         
                    </Grid> 
                {segmentos &&
                        <AbsSegmento segmento={segmentos}/>
                }
                {segmentos &&                
                   <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <Typography variant="h3" component="h3" className={classes.coord}>{translate('dynatest.resources.seguridads.subTitle.coordenadasIniciales')}</Typography>
                            <Divider fullWidth style={{marginTop : '10px'}} />
                        </Grid>
                    </Grid>              
                }           
                {segmentos && <PRInput label="resources.seguridads.fields.abscisa" id="ingresoAbInicio" source="abInicio" fullWidth validate={[abValidationInicio,required(), validarAB]}  formClassName={classes.grid_cont3}/>}
                {segmentos && <PInput label="resources.seguridads.fields.pr" id="ingresoPrInicio" source="pInicio" fullWidth validate={[prValidationInicio, validarPR]} formClassName={classes.grid_cont3} />}
                {segmentos && <NumberInput label="resources.seguridads.fields.latitud" id="LatitudInicial" source="ubicaciones[0][lat]"  fullWidth validate={[required(), latMininima,LatitudMinimaArray ]} formClassName={classes.grid_cont3}/>}     
                {segmentos && <NumberInput label="resources.seguridads.fields.longitud" id="LongitudInicial" source="ubicaciones[0][lng]"  fullWidth validate={[required(), lngMininima, LongitudMinimaArray]} formClassName={classes.grid_cont3}/>}
                {segmentos &&           
                    <Grid container spacing={24}>     
                        <Grid item xs={12}>
                            <Typography variant="h3" component="h3" className={classes.coord}>{translate('dynatest.resources.seguridads.subTitle.coordenadasFinales')}</Typography>
                            <Divider fullWidth style={{marginTop : '10px'}} />
                        </Grid>    
                    </Grid>            
                } 
                {/* validarLogicaAbsFinEdit */}
                {segmentos && <PRInput label="resources.seguridads.fields.abscisa" id="ingresoAbFin" source="abFin" fullWidth validate={[abValidationFin, validarAB]} formClassName={classes.grid_cont3}/>}
                {segmentos && <PInput label="resources.seguridads.fields.pr" id="ingresoPrFin" source="pFin" fullWidth validate={[prValidationFin, validarPR]} formClassName={classes.grid_cont3} />}
                {segmentos && <NumberInput label="resources.seguridads.fields.latitud" source="ubicaciones[1][lat]"  fullWidth validate={[LatitudMaximaArray, latMininima]} formClassName={classes.grid_cont3}/>}     
                {segmentos && <NumberInput label="resources.seguridads.fields.longitud" source="ubicaciones[1][lng]"  fullWidth validate={[lngMininima, LongitudMaximaArray]} formClassName={classes.grid_cont3}/>}
                        
            </FormTab>
          
            <FormTab label="dynatest.resources.seguridads.tabs.tabsTitle.caracteristicas">
                
                <FormDataConsumer>
                  {({ formData, ...rest }) => (  formData !== null && formData.tipoElemento === "/tipo_elementos/BRRC"  &&
                    <Grid container spacing={24}>
                        <Grid item xs={4}>
                        <SelectInput fullWidth label="resources.seguridads.fields.material" source="material" allowEmpty emptyValue="" validate={[required()]}  choices={[
                           { id: translate('dynatest.resources.seguridads.select.material.barreraJerseyPlasticaId'), name: 'dynatest.resources.seguridads.select.material.barreraJerseyPlastica' },
                           { id: translate('dynatest.resources.seguridads.select.material.concreto'), name: 'dynatest.resources.seguridads.select.material.concreto' },
                           { id: translate('dynatest.resources.seguridads.select.material.malla'), name: 'dynatest.resources.seguridads.select.material.malla' },
                           { id: translate('dynatest.resources.seguridads.select.material.metalicaId'), name: 'dynatest.resources.seguridads.select.material.metalica' },
                           { id: translate('dynatest.resources.seguridads.select.material.plasticaId'), name: 'dynatest.resources.seguridads.select.material.plastica' },
                           { id: translate('dynatest.resources.seguridads.select.material.polisombra'), name: 'dynatest.resources.seguridads.select.material.polisombra' },
                           { id: translate('dynatest.resources.seguridads.select.material.prefabricado'), name: 'dynatest.resources.seguridads.select.material.prefabricado' }                          
                       ]}/>
                        </Grid> 
                        <Grid item xs={4}>
                            <NumberInput label="resources.seguridads.fields.cant_elem" source="cant_elem" fullWidth validate={[required(),minValue(0),maxValue(99999999),Entero]}/>
                        </Grid> 
                        <Grid item xs={4}>
                            <NumberInput label="resources.seguridads.fields.cant_capta" source="cant_capta" fullWidth validate={[required(),minValue(0),maxValue(99999999),Entero]}/>
                        </Grid> 
                        <Grid item xs={4}>
                          <NumberInput label="resources.seguridads.fields.cant_largo" source="cant_largo" fullWidth validate={[required(),minValue(0),maxValue(99999999),validarUnDecimal]}/>
                        </Grid>  
                        <Grid item xs={4}>
                          <NumberInput label="resources.seguridads.fields.cant_ancho" source="cant_ancho" fullWidth validate={[required(),minValue(0),maxValue(99999999),validarUnDecimal]}/>
                        </Grid>  
                        <Grid item xs={4}>
                          <NumberInput label="resources.seguridads.fields.altura" source="altura" fullWidth validate={[minValue(0),maxValue(99999999),validarUnDecimal]}/>
                        </Grid>   
                    </Grid>
                    )}
                  </FormDataConsumer>
                  <FormDataConsumer>
                  {({ formData, ...rest }) => (  formData !== null && formData.tipoElemento === "/tipo_elementos/BRRF"  &&
                    <Grid container spacing={24}>
                        <Grid item xs={4}>
                        <SelectInput fullWidth label="resources.seguridads.fields.material" source="material" allowEmpty emptyValue="" validate={[required()]}  choices={[
                           { id: translate('dynatest.resources.seguridads.select.material.barreraJerseyPlasticaId'), name: 'dynatest.resources.seguridads.select.material.barreraJerseyPlastica' },
                           { id: translate('dynatest.resources.seguridads.select.material.concreto'), name: 'dynatest.resources.seguridads.select.material.concreto' },
                           { id: translate('dynatest.resources.seguridads.select.material.malla'), name: 'dynatest.resources.seguridads.select.material.malla' },
                           { id: translate('dynatest.resources.seguridads.select.material.metalicaId'), name: 'dynatest.resources.seguridads.select.material.metalica' },
                           { id: translate('dynatest.resources.seguridads.select.material.plasticaId'), name: 'dynatest.resources.seguridads.select.material.plastica' },
                           { id: translate('dynatest.resources.seguridads.select.material.polisombra'), name: 'dynatest.resources.seguridads.select.material.polisombra' },
                           { id: translate('dynatest.resources.seguridads.select.material.prefabricado'), name: 'dynatest.resources.seguridads.select.material.prefabricado' }
                           
                       ]}/>
                        </Grid> 
                        <Grid item xs={4}>
                        <NumberInput label="resources.seguridads.fields.cant_elem" source="cant_elem" fullWidth validate={[required(),minValue(0),maxValue(99999999),Entero]}/>
                        </Grid> 
                        <Grid item xs={4}>
                        <NumberInput label="resources.seguridads.fields.cant_capta" source="cant_capta" fullWidth validate={[required(),minValue(0),maxValue(99999999),Entero]}/>
                        </Grid> 
                        <Grid item xs={4}>
                          <NumberInput label="resources.seguridads.fields.cant_largo" source="cant_largo" fullWidth validate={[required(),minValue(0),maxValue(99999999),validarUnDecimal]}/>
                        </Grid>  
                        <Grid item xs={4}>
                          <NumberInput label="resources.seguridads.fields.cant_ancho" source="cant_ancho" fullWidth validate={[required(),minValue(0),maxValue(99999999),validarUnDecimal]}/>
                        </Grid>  
                        <Grid item xs={4}>
                          <NumberInput label="resources.seguridads.fields.altura" source="altura" fullWidth validate={[required(),minValue(0),maxValue(99999999),validarUnDecimal]}/>
                        </Grid>   
                    </Grid>
                    )}
                  </FormDataConsumer>                
                  <FormDataConsumer>
                  {
                    ({ formData, ...rest }) => (  formData !== null && formData.tipoElemento === "/tipo_elementos/BRRJ"  &&
                    <Grid container spacing={24}>
                           
                         
                        
                    <Grid item xs={4}>
                    <SelectInput fullWidth label="resources.seguridads.fields.material" source="material" allowEmpty emptyValue="" validate={[required()]}  choices={[
                       { id: translate('dynatest.resources.seguridads.select.material.barreraJerseyPlasticaId'), name: 'dynatest.resources.seguridads.select.material.barreraJerseyPlastica' },
                       { id: translate('dynatest.resources.seguridads.select.material.concreto'), name: 'dynatest.resources.seguridads.select.material.concreto' },
                       { id: translate('dynatest.resources.seguridads.select.material.malla'), name: 'dynatest.resources.seguridads.select.material.malla' },
                       { id: translate('dynatest.resources.seguridads.select.material.metalicaId'), name: 'dynatest.resources.seguridads.select.material.metalica' },
                       { id: translate('dynatest.resources.seguridads.select.material.plasticaId'), name: 'dynatest.resources.seguridads.select.material.plastica' },
                       { id: translate('dynatest.resources.seguridads.select.material.polisombra'), name: 'dynatest.resources.seguridads.select.material.polisombra' },
                       { id: translate('dynatest.resources.seguridads.select.material.prefabricado'), name: 'dynatest.resources.seguridads.select.material.prefabricado' }
                       
                   ]}/>
                    </Grid> 
                    <Grid item xs={4}>
                    <NumberInput label="resources.seguridads.fields.cant_elem" source="cant_elem" fullWidth validate={[required(),minValue(0),maxValue(99999999),Entero]}/>
                    </Grid> 
                    <Grid item xs={4}>
                    <NumberInput label="resources.seguridads.fields.cant_capta" source="cant_capta" fullWidth validate={[required(),minValue(0),maxValue(99999999),Entero]}/>
                    </Grid> 
                    <Grid item xs={4}>
                      <NumberInput label="resources.seguridads.fields.cant_largo" source="cant_largo" fullWidth validate={[required(),minValue(0),maxValue(99999999),validarUnDecimal]}/>
                    </Grid>  
                    <Grid item xs={4}>
                      <NumberInput label="resources.seguridads.fields.cant_ancho" source="cant_ancho" fullWidth validate={[required(),minValue(0),maxValue(99999999),validarUnDecimal]}/>
                    </Grid>  
                    <Grid item xs={4}>
                      <NumberInput label="resources.seguridads.fields.altura" source="altura" fullWidth validate={[required(),minValue(0),maxValue(99999999),validarUnDecimal]}/>
                    </Grid>   
                    </Grid>
                    )}
                  </FormDataConsumer>
                  <FormDataConsumer>
                  {
                    ({ formData, ...rest }) => (  formData !==null && formData.tipoElemento==="/tipo_elementos/BORD"  &&
                    <Grid container spacing={24}>

                        <Grid item xs={4}>
                        <SelectInput fullWidth label="resources.seguridads.fields.material" source="material" allowEmpty emptyValue="" validate={[required()]}  choices={[
                           { id: translate('dynatest.resources.seguridads.select.material.barreraJerseyPlasticaId'), name: 'dynatest.resources.seguridads.select.material.barreraJerseyPlastica' },
                           { id: translate('dynatest.resources.seguridads.select.material.concreto'), name: 'dynatest.resources.seguridads.select.material.concreto' },
                           { id: translate('dynatest.resources.seguridads.select.material.malla'), name: 'dynatest.resources.seguridads.select.material.malla' },
                           { id: translate('dynatest.resources.seguridads.select.material.metalicaId'), name: 'dynatest.resources.seguridads.select.material.metalica' },
                           { id: translate('dynatest.resources.seguridads.select.material.plasticaId'), name: 'dynatest.resources.seguridads.select.material.plastica' },
                           { id: translate('dynatest.resources.seguridads.select.material.polisombra'), name: 'dynatest.resources.seguridads.select.material.polisombra' },
                           { id: translate('dynatest.resources.seguridads.select.material.prefabricado'), name: 'dynatest.resources.seguridads.select.material.prefabricado' }
                           
                       ]}/>
                        </Grid> 
                        <Grid item xs={4}>
                        <NumberInput label="resources.seguridads.fields.cant_elem" source="cant_elem" fullWidth validate={[required(),minValue(0),maxValue(99999999),Entero]}/>
                        </Grid> 
                        <Grid item xs={4}>
                          <NumberInput label="resources.seguridads.fields.cant_largo" source="cant_largo" fullWidth validate={[required(),minValue(0),maxValue(99999999),validarUnDecimal]}/>
                        </Grid>  
                        <Grid item xs={4}>
                          <NumberInput label="resources.seguridads.fields.cant_ancho" source="cant_ancho" fullWidth validate={[required(),minValue(0),maxValue(99999999),validarUnDecimal]}/>
                        </Grid>  
                        <Grid item xs={4}>
                          <NumberInput label="resources.seguridads.fields.altura" source="altura" fullWidth validate={[required(),minValue(0),maxValue(99999999),validarUnDecimal]}/>
                        </Grid>   
                    </Grid>
                    )}
                  </FormDataConsumer>
                 
                  <FormDataConsumer>
                  {
                    ({ formData, ...rest }) => (  formData !==null && formData.tipoElemento==="/tipo_elementos/CERC"  &&
                    <Grid container spacing={24}>

                        <Grid item xs={4}>
                        <SelectInput fullWidth label="resources.seguridads.fields.material" source="material" allowEmpty emptyValue="" validate={[required()]}  choices={[
                           { id: translate('dynatest.resources.seguridads.select.material.barreraJerseyPlasticaId'), name: 'dynatest.resources.seguridads.select.material.barreraJerseyPlastica' },
                           { id: translate('dynatest.resources.seguridads.select.material.concreto'), name: 'dynatest.resources.seguridads.select.material.concreto' },
                           { id: translate('dynatest.resources.seguridads.select.material.malla'), name: 'dynatest.resources.seguridads.select.material.malla' },
                           { id: translate('dynatest.resources.seguridads.select.material.metalicaId'), name: 'dynatest.resources.seguridads.select.material.metalica' },
                           { id: translate('dynatest.resources.seguridads.select.material.plasticaId'), name: 'dynatest.resources.seguridads.select.material.plastica' },
                           { id: translate('dynatest.resources.seguridads.select.material.polisombra'), name: 'dynatest.resources.seguridads.select.material.polisombra' },
                           { id: translate('dynatest.resources.seguridads.select.material.prefabricado'), name: 'dynatest.resources.seguridads.select.material.prefabricado' }
                           
                       ]}/>
                        </Grid> 
                        <Grid item xs={4}>
                        <NumberInput label="resources.seguridads.fields.cant_elem" source="cant_elem" fullWidth validate={[required(),minValue(0),maxValue(99999999),Entero]}/>
                        </Grid> 
 
                        <Grid item xs={4}>
                          <NumberInput label="resources.seguridads.fields.cant_largo" source="cant_largo" fullWidth validate={[required(),minValue(0),maxValue(99999999),validarUnDecimal]}/>
                        </Grid>  
                        <Grid item xs={4}>
                          <NumberInput label="resources.seguridads.fields.cant_ancho" source="cant_ancho" fullWidth validate={[required(),minValue(0),maxValue(99999999),validarUnDecimal]}/>
                        </Grid>  
                        <Grid item xs={4}>
                          <NumberInput label="resources.seguridads.fields.altura" source="altura" fullWidth validate={[required(),minValue(0),maxValue(99999999),validarUnDecimal]}/>
                        </Grid>      
                    </Grid>
                    )}
                  </FormDataConsumer>
                  <FormDataConsumer>
                  {
                    ({ formData, ...rest }) => (  formData !==null && formData.tipoElemento==="/tipo_elementos/CAPT"  &&
                    <Grid container spacing={24}>                            
                        <Grid item xs={6}>
                            <SelectInput fullWidth label="resources.seguridads.fields.material" source="material" allowEmpty emptyValue="" validate={[required()]}  choices={[
                                { id: translate('dynatest.resources.seguridads.select.material.barreraJerseyPlasticaId'), name: 'dynatest.resources.seguridads.select.material.barreraJerseyPlastica' },
                                { id: translate('dynatest.resources.seguridads.select.material.concreto'), name: 'dynatest.resources.seguridads.select.material.concreto' },
                                { id: translate('dynatest.resources.seguridads.select.material.malla'), name: 'dynatest.resources.seguridads.select.material.malla' },
                                { id: translate('dynatest.resources.seguridads.select.material.metalicaId'), name: 'dynatest.resources.seguridads.select.material.metalica' },
                                { id: translate('dynatest.resources.seguridads.select.material.plasticaId'), name: 'dynatest.resources.seguridads.select.material.plastica' },
                                { id: translate('dynatest.resources.seguridads.select.material.polisombra'), name: 'dynatest.resources.seguridads.select.material.polisombra' },
                                { id: translate('dynatest.resources.seguridads.select.material.prefabricado'), name: 'dynatest.resources.seguridads.select.material.prefabricado' }
                            
                            ]}/>
                        </Grid> 
                        <Grid item xs={6}>
                            <NumberInput label="resources.seguridads.fields.cant_capta" source="captafaros" fullWidth validate={[required(),minValue(0),maxValue(99999999),Entero]}/>
                        </Grid>
                        <Grid item xs={4}>
                          <NumberInput label="resources.seguridads.fields.cant_largo" source="cant_largo" fullWidth validate={[required(),minValue(0),maxValue(99999999),validarUnDecimal]}/>
                        </Grid>  
                        <Grid item xs={4}>
                          <NumberInput label="resources.seguridads.fields.cant_ancho" source="cant_ancho" fullWidth validate={[required(),minValue(0),maxValue(99999999),validarUnDecimal]}/>
                        </Grid>  
                        <Grid item xs={4}>
                          <NumberInput label="resources.seguridads.fields.altura" source="altura" fullWidth validate={[required(),minValue(0),maxValue(99999999),validarUnDecimal]}/>
                        </Grid>   
                    </Grid>
                    )}
                  </FormDataConsumer>
                  <FormDataConsumer>
                  {
                    ({ formData, ...rest }) => (  formData !==null && formData.tipoElemento==="/tipo_elementos/DEFMT"  &&
                    <Grid container spacing={24}>                           
                        <Grid item xs={4}>
                        <SelectInput fullWidth label="resources.seguridads.fields.material" source="material" allowEmpty emptyValue="" validate={[required()]}  choices={[
                           { id: translate('dynatest.resources.seguridads.select.material.barreraJerseyPlasticaId'), name: 'dynatest.resources.seguridads.select.material.barreraJerseyPlastica' },
                           { id: translate('dynatest.resources.seguridads.select.material.concreto'), name: 'dynatest.resources.seguridads.select.material.concreto' },
                           { id: translate('dynatest.resources.seguridads.select.material.malla'), name: 'dynatest.resources.seguridads.select.material.malla' },
                           { id: translate('dynatest.resources.seguridads.select.material.metalicaId'), name: 'dynatest.resources.seguridads.select.material.metalica' },
                           { id: translate('dynatest.resources.seguridads.select.material.plasticaId'), name: 'dynatest.resources.seguridads.select.material.plastica' },
                           { id: translate('dynatest.resources.seguridads.select.material.polisombra'), name: 'dynatest.resources.seguridads.select.material.polisombra' },
                           { id: translate('dynatest.resources.seguridads.select.material.prefabricado'), name: 'dynatest.resources.seguridads.select.material.prefabricado' }
                           
                       ]}/>
                       </Grid>
                       <Grid item xs={4}>                       
                       <SelectInput fullWidth label="resources.seguridads.fields.perfil" source="perfil" allowEmpty emptyValue="" validate={[required()]}  choices={[
                           { id: translate('dynatest.resources.seguridads.select.perfil.perfilC'), name: 'dynatest.resources.seguridads.select.perfil.perfilC' },
                           { id: translate('dynatest.resources.seguridads.select.perfil.perfilU'), name: 'dynatest.resources.seguridads.select.perfil.perfilU' },
                           { id: translate('dynatest.resources.seguridads.select.perfil.perfilW_DobleOnda'), name: 'dynatest.resources.seguridads.select.perfil.perfilW_DobleOnda' },
                           { id: translate('dynatest.resources.seguridads.select.perfil.simple'), name: 'dynatest.resources.seguridads.select.perfil.simple' },                        
                       ]}/>
                        </Grid>  
                        <Grid item xs={4}>   
                            <SelectInput 
                                fullWidth 
                                label="resources.seguridads.fields.defensa" 
                                source="defensa" 
                                choices={defensachoices} 
                                optionText={optionRendererDefensa} 
                                allowEmpty
                                emptyValue="" 
                                validate={[required()]}  
                            />
                        </Grid>  
                        <Grid item xs={4}>
                        <NumberInput label="resources.seguridads.fields.postes" source="postes" fullWidth validate={[required(),minValue(0),maxValue(99999999),Entero]}/>
                        </Grid>
                        <Grid item xs={4}>
                        <NumberInput label="resources.seguridads.fields.captafaros" source="captafaros" fullWidth validate={[required(),minValue(0),maxValue(99999999),Entero]}/>
                        </Grid>
                        <Grid item xs={4}>
                        <NumberInput label="resources.seguridads.fields.unidades" source="unidades" fullWidth validate={[required(),minValue(0),maxValue(99999999),Entero]}/>
                        </Grid>
                        <Grid item xs={4}>
                        <NumberInput label="resources.seguridads.fields.terminales" source="terminales" fullWidth validate={[required(),minValue(0),maxValue(99999999),Entero]}/>
                        </Grid>
                        <Grid item xs={4}>
                        <NumberInput label="resources.seguridads.fields.tornillos" source="tornillos" fullWidth validate={[required(),minValue(0),maxValue(99999999),Entero]}/>
                        </Grid>
                        <Grid item xs={4}>
                        <NumberInput label="resources.seguridads.fields.cant_largo" source="largo" fullWidth validate={[required(),minValue(0),maxValue(99999999),validarUnDecimal]}/>
                        </Grid>
                        <Grid item xs={4}>
                        <NumberInput label="resources.seguridads.fields.altura" source="altura" fullWidth validate={[required(),minValue(0),maxValue(99999999),validarDosDecimales]}/>
                        </Grid>
                        <Grid item xs={4}>   
                            <SelectInput 
                                fullWidth 
                                label="resources.seguridads.fields.posteMetalico" 
                                source="posteMetalico" 
                                choices={[
                                    { id: translate('dynatest.resources.seguridads.select.posteMetalico.siId'), name: 'dynatest.resources.seguridads.select.posteMetalico.siName' },
                                    { id: translate('dynatest.resources.seguridads.select.posteMetalico.noId'), name: 'dynatest.resources.seguridads.select.posteMetalico.noName' },
                                ]}
                                allowEmpty
                                emptyValue="" 
                            />
                        </Grid>
                    </Grid>
                    )}
                  </FormDataConsumer>
                  <FormDataConsumer>
                  {
                    ({ formData, ...rest }) => (  formData !==null && formData.tipoElemento==="/tipo_elementos/PRTP"  &&
                    <Grid container spacing={24}>                           
                        <Grid item xs={6}>
                        <SelectInput fullWidth label="resources.seguridads.fields.material" source="material" allowEmpty emptyValue="" validate={[required()]}  choices={[
                           { id: translate('dynatest.resources.seguridads.select.material.barreraJerseyPlasticaId'), name: 'dynatest.resources.seguridads.select.material.barreraJerseyPlastica' },
                           { id: translate('dynatest.resources.seguridads.select.material.concreto'), name: 'dynatest.resources.seguridads.select.material.concreto' },
                           { id: translate('dynatest.resources.seguridads.select.material.malla'), name: 'dynatest.resources.seguridads.select.material.malla' },
                           { id: translate('dynatest.resources.seguridads.select.material.metalicaId'), name: 'dynatest.resources.seguridads.select.material.metalica' },
                           { id: translate('dynatest.resources.seguridads.select.material.plasticaId'), name: 'dynatest.resources.seguridads.select.material.plastica' },
                           { id: translate('dynatest.resources.seguridads.select.material.polisombra'), name: 'dynatest.resources.seguridads.select.material.polisombra' },
                           { id: translate('dynatest.resources.seguridads.select.material.prefabricado'), name: 'dynatest.resources.seguridads.select.material.prefabricado' }
                           
                       ]}/>
                       </Grid>
                       <Grid item xs={6}>                       
                       <SelectInput fullWidth label="resources.seguridads.fields.perfil" source="perfil" allowEmpty emptyValue="" validate={[required()]}  choices={[
                           { id: translate('dynatest.resources.seguridads.select.perfil.perfilC'), name: 'dynatest.resources.seguridads.select.perfil.perfilC' },
                           { id: translate('dynatest.resources.seguridads.select.perfil.perfilU'), name: 'dynatest.resources.seguridads.select.perfil.perfilU' },
                           { id: translate('dynatest.resources.seguridads.select.perfil.perfilW_DobleOnda'), name: 'dynatest.resources.seguridads.select.perfil.perfilW_DobleOnda' },
                           { id: translate('dynatest.resources.seguridads.select.perfil.simple'), name: 'dynatest.resources.seguridads.select.perfil.simple' },                           
                       ]}/>
                        </Grid>  

                        <Grid item xs={4}>
                          <NumberInput label="resources.seguridads.fields.ca_largo" source="ca_largo" fullWidth validate={[required(),minValue(0),validarUnDecimal]}/>
                        </Grid>  
                        <Grid item xs={4}>
                                <NumberInput 
                                    label="resources.seguridads.fields.ca_ancho" 
                                    source="ca_ancho" 
                                    fullWidth 
                                    validate={[required(), minValue(0), validarUnDecimal]} 
                                    formClassName={classes.grid_cont4}
                                    />
                    </Grid>
                    <Grid item xs={4}>
                                <NumberInput 
                                    label="resources.seguridads.fields.ca_altura" 
                                    source="ca_altura" 
                                    fullWidth 
                                    validate={[required(), minValue(1), validarUnDecimal]} 
                                    formClassName={classes.grid_cont4}
                                    />
                    </Grid>
                        <Grid item xs={6}>
                            <NumberInput label="resources.seguridads.fields.cant_capta" source="cant_capta" fullWidth validate={[required(),minValue(0),maxValue(99999999),Entero]}/>
                        </Grid>
                        <Grid item xs={6}>
                          <NumberInput label="resources.seguridads.fields.calibre_mm" source="calibre_mm" fullWidth validate={[required(),minValue(0),validarUnDecimal]}/>
                        </Grid>  
                        {/*NOTA: en el excel, en el item Pertiles de Puentes, en caracteristicas, 
                            solo se envian largo y no. captafaros*/}
                        {/* <Grid item xs={4}>
                          <NumberInput label="Ancho (m)" source="cant_ancho" fullWidth validate={[required(),minValue(0),validarUnDecimal]}/>
                        </Grid>  
                        <Grid item xs={4}>
                          <NumberInput label="Altura (m)" source="altura" fullWidth validate={[required(),minValue(0),validarUnDecimal]}/>
                        </Grid>    */}
       
                    </Grid>
                    )}
                  </FormDataConsumer>
                  
                  <FormDataConsumer>
                  {
                    ({ formData, ...rest }) => (  formData !==null && formData.tipoElemento==="/tipo_elementos/TPVH"  &&
                    <Grid container spacing={24}>                           
                        <Grid item xs={4}>
                        <SelectInput fullWidth label="resources.seguridads.fields.material" source="material" allowEmpty emptyValue="" validate={[required()]}  choices={[
                           { id: translate('dynatest.resources.seguridads.select.material.barreraJerseyPlasticaId'), name: 'dynatest.resources.seguridads.select.material.barreraJerseyPlastica' },
                           { id: translate('dynatest.resources.seguridads.select.material.concreto'), name: 'dynatest.resources.seguridads.select.material.concreto' },
                           { id: translate('dynatest.resources.seguridads.select.material.malla'), name: 'dynatest.resources.seguridads.select.material.malla' },
                           { id: translate('dynatest.resources.seguridads.select.material.metalicaId'), name: 'dynatest.resources.seguridads.select.material.metalica' },
                           { id: translate('dynatest.resources.seguridads.select.material.plasticaId'), name: 'dynatest.resources.seguridads.select.material.plastica' },
                           { id: translate('dynatest.resources.seguridads.select.material.polisombra'), name: 'dynatest.resources.seguridads.select.material.polisombra' },
                           { id: translate('dynatest.resources.seguridads.select.material.prefabricado'), name: 'dynatest.resources.seguridads.select.material.prefabricado' }
                           
                       ]}/>
                       </Grid>
                       <Grid item xs={4}>                       
                            <SelectInput fullWidth label="resources.seguridads.fields.tope" source="tope" allowEmpty emptyValue="" validate={[required()]}  choices={[
                                { id: translate('dynatest.resources.seguridads.select.tope.horizontal'), name: 'dynatest.resources.seguridads.select.tope.horizontal' },
                                { id: translate('dynatest.resources.seguridads.select.tope.vertical'), name: 'dynatest.resources.seguridads.select.tope.vertical' },                    
                            ]}/>
                        </Grid>  
                        <Grid item xs={4}>
                            <SelectInput fullWidth label="resources.seguridads.fields.seccion" source="seccion" allowEmpty emptyValue="" validate={[required()]}  choices={[
                                { id: translate('dynatest.resources.seguridads.select.seccion.circular'), name: 'dynatest.resources.seguridads.select.seccion.circular' },
                                { id: translate('dynatest.resources.seguridads.select.seccion.rectangular'), name: 'dynatest.resources.seguridads.select.seccion.rectangular' },                    
                             ]}/> 
                        </Grid>  
                        <Grid item xs={6}>
                            <NumberInput label="resources.seguridads.fields.diametro" source="diametro" fullWidth validate={[required(),minValue(0),maxValue(99999999),validarDosDecimales]}/>
                        </Grid>
                        <Grid item xs={6}>
                            <NumberInput label="resources.seguridads.fields.altura" source="altura" fullWidth validate={[required(),minValue(0),maxValue(99999999),validarDosDecimales]}/>
                        </Grid>
                        <Grid item xs={6}>
                            <NumberInput label="resources.seguridads.fields.topes" source="topes" fullWidth validate={[required(),minValue(0),maxValue(99999999),Entero]}/>
                        </Grid>
                        <Grid item xs={6}>
                            <NumberInput label="resources.seguridads.fields.tramos" source="tramos" fullWidth validate={[required(),minValue(0),maxValue(99999999),validarUnDecimal]}/>
                        </Grid>
                    </Grid>
                    )}
                  </FormDataConsumer>
            </FormTab>
            <FormTab label="dynatest.resources.seguridads.tabs.tabsTitle.detalles" >
                <Grid container spacing={24}>                       
                    <Grid item xs={4}>
                        <SelectInput label="resources.seguridads.fields.condicionElemento" source="condicionElemento" choices={[ 
                            { id: translate('dynatest.resources.seguridads.select.condicionElemento.existente'), name: 'dynatest.resources.seguridads.select.condicionElemento.existente' },
                            { id: translate('dynatest.resources.seguridads.select.condicionElemento.reemplazo'), name: 'dynatest.resources.seguridads.select.condicionElemento.reemplazo' },
                            { id: translate('dynatest.resources.seguridads.select.condicionElemento.nuevo'), name: 'dynatest.resources.seguridads.select.condicionElemento.nuevo' },   
                        ]}  fullWidth validate={[required(), ValidarEstado]} 
                        />
                    </Grid>     
                    <Grid item xs={4}>
                        {estados === translate('dynatest.resources.seguridads.select.condicionElemento.reemplazo') && 
                                <SelectInput 
                                label="resources.seguridads.fields.motivoReemplazo"
                                fullWidth 
                                source="motivoReemplazo" 
                                allowEmpty emptyValue="" choices={[
                                    { id: translate('dynatest.resources.seguridads.select.motivoReemplazo.accidente'), name: 'dynatest.resources.seguridads.select.motivoReemplazo.accidente' },
                                    { id: translate('dynatest.resources.seguridads.select.motivoReemplazo.instalacionId'), name: 'dynatest.resources.seguridads.select.motivoReemplazo.instalacion' },
                                    { id: translate('dynatest.resources.seguridads.select.motivoReemplazo.reemplazoProgramado'), name: 'dynatest.resources.seguridads.select.motivoReemplazo.reemplazoProgramado' },
                                    { id: translate('dynatest.resources.seguridads.select.motivoReemplazo.vandalismo'), name: 'dynatest.resources.seguridads.select.motivoReemplazo.vandalismo' },
                                    { id: translate('dynatest.resources.seguridads.select.motivoReemplazo.otro'), name: 'dynatest.resources.seguridads.select.motivoReemplazo.otro' }
                            ]} />

                        }                        

                    </Grid>   
                    <Grid item xs={4}>
                        {estados === translate('dynatest.resources.seguridads.select.condicionElemento.reemplazo') && 
                            // <ElementosCreados 
                            //     tabla="seguridads" 
                            //     tipo="editar" 
                            //     elementos={elemento} 
                            //     source="elementoReemplazo_id"
                            //     fullWidth 

                            // />
                            <FormDataConsumer>
                                    {({ formData, ...rest }) =>
                                        <ReferenceInput
                                            label="resources.seguridads.fields.elementoReemplazo_id"
                                            source="elementoReemplazo_id"
                                            reference="seguridads"
                                            filter={{
                                                tipoElemento : elemento,
                                                estado : "Activo",
                                                unidadFuncional: unidads,
                                                tramo: tramos,
                                                segmento : segmentos,
                                                exclude : (formData) ? formData.id : null
                                            }}
                                            fullWidth
                                            formClassName={classes.grid_cont4_s}
                                        >
                                            <AutocompleteInput options={{ fullWidth: true }} optionText="fullCodigoConsecutivo" />
                                        </ReferenceInput>
                                    }
                                </FormDataConsumer>
                        }
                    </Grid> 
                </Grid>
                
                <SelectInput label="resources.seguridads.fields.estado" source="estado" choices={[
                                { id: translate('dynatest.resources.seguridads.select.estado.activo'), name: 'dynatest.resources.seguridads.select.estado.activo' },
                                { id: translate('dynatest.resources.seguridads.select.estado.inactivo'), name: 'dynatest.resources.seguridads.select.estado.inactivo' },
                            ]} fullWidth validate={[required()]} 
                            formClassName={classes.grid_cont4s}
                            />
                            <br/>
                <Grid container spacing={24}>       
                    <Grid item xs={3}>
                        <DateInput 
                            label="resources.seguridads.fields.fechaInstalacion" 
                            source="fechaInstalacion" 
                            fullWidth 
                            validate={[required(), validacionmaxfechaact]}/>        
                    </Grid>
                    <Grid item xs={6}>
                    <FormDataConsumer>
                        {
                            ({ formData, ...rest }) => ( 
                                <ReferenceInput 
                                    label="resources.seguridads.fields.proveedor" 
                                    source="proveedor" 
                                    reference="proveedors" 
                                    filter={{ 
                                        proyecto: props.proyecto, sistema: 'SEG' 
                                    }}
                                    sort={{ field: 'razonSocial', order: 'ASC' }}
                                    filterToQuery={searchText => ({ razonSocial: searchText })} 
                                    validate={[required()]}
                                >
                                    <AutocompleteInput  options={{fullWidth : true}} optionText="razonSocial"  />
                                </ReferenceInput>
                            )
                        }
                        </FormDataConsumer>           
                    </Grid>    
                    <Grid item xs={3}>
                        <FormDataConsumer>
                            {
                            ({ formData, ...rest }) => (  
                                <NumberInput label="resources.seguridads.fields.garantia" source="garantia" fullWidth validate={[required() , Entero , minValue(0)]}/>
                                )
                            }
                        </FormDataConsumer> 
                    </Grid>
                </Grid>
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <BooleanInput label="dynatest.resources.seguridads.boolean.mantenimiento" source="mantenimiento" fullWidth />
                    </Grid>
                </Grid>
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <TextInput source="observaciones" label="resources.seguridads.fields.observaciones" fullWidth validate={[required(), maxLength(200, "dynatest.resources.seguridads.messageWarning.observaciones")]}/>
                    </Grid>
                </Grid>
                <FormDataConsumer>
                    {({ formData, ...rest }) => { formData.updatedIn = "api"; }}
                </FormDataConsumer> 
            </FormTab>
            <FormTab label="dynatest.resources.seguridads.tabs.tabsTitle.fotografias">
                {/* <ImageInput multiple={true} style={{display :'none'}} source="images" label=" " maxSize={1000000000} accept="image/jpeg, image/png">
                    <ImageField source="src" title="title" />
                </ImageInput> */}
                <Grid container spacing={24}>                         
                    <Grid item xs={8}>  
                        {/* <ArrayInput label="" source="imagess" defaultValue={defaultTipoImagen} >
                            <SimpleFormIterator disableAdd disableRemove >
                                <ImageInput multiple={true} source="images" label=" " maxSize={1000000000} accept="image/jpeg, image/png">
                                    <ImageField source="src" title="title" />
                                </ImageInput>                           
                                <ReferenceInput disabled label="Tipo de elemento" source="tipoImagen" reference="tipo_images" fullWidth validate={[required()]} sort={{ field: 'nombre', order: 'ASC' }}  formClassName={classes.grid_cont6}>
                                    <SelectInput  optionText="nombre"  />
                                </ReferenceInput>                          
                            </SimpleFormIterator>
                        </ArrayInput>                             */}
                        <FormDataConsumer>
                            {({ formData, ...rest }) =>
                                <ArrayInputImagesEdit record={formData} {...rest} />                                
                            }
                        </FormDataConsumer> 
                    </Grid>     
                    <Grid item xs={4}>  
                        <FormDataConsumer>
                            {({ formData, ...rest }) =>
                                <ButtonCarousel record={formData} {...rest} />                                
                            }
                        </FormDataConsumer>
                    </Grid>                                                    
                </Grid>                   
            </FormTab>
      </TabbedForm>
    </Edit>
)}));