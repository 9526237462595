import React, { Fragment,useState } from 'react';
import { List, Datagrid,SelectField, TextField,AutocompleteInput, EditButton, ShowButton, ReferenceField,NumberField,Filter,ReferenceInput, SelectInput} from 'react-admin';
import {  BulkDeleteWithConfirmButton, Loading  } from 'react-admin';
import { translate } from 'react-admin';
import { CardActions, CreateButton, ExportButton } from 'react-admin';

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);
const ListAction = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
        <ExportButton basePath={basePath}/>
    </CardActions>
);
const ListAction2 = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
    </CardActions>
);
const InvetarioFilter = translate((props) => {
    const [uf, setUf] = useState('none');
    const ufSet = (value) => {
        setUf(value);
    }
 return(
    <Filter {...props}>
        <ReferenceInput
                id="unidadFuncional"
                label="resources.parametros_analises.fields.unidad_funcional"
                source="unidad_funcional"
                reference="unidad_funcionals"
                filterToQuery={searchText => ({ codigo: searchText })}
                sort={{ field: 'codigo', order: 'asc' }}
                validate={[ufSet]}
                alwaysOn
                fullWidth
            >
                <AutocompleteInput options={{ fullWidth: true }} optionText="fullCodigos" />
            </ReferenceInput>
    </Filter>
);
 });

export const ParametrosAnalisisList = ({ permissions, ...props }) => {
    if (!permissions) return <Loading />;  
    return (
    <List {...props} 
        sort={{ field:"updatedAt", order: 'DESC' }} 
        filters={<InvetarioFilter/>}  
        actions={permissions['parametrosAnalisis']  == 1 ? <ListAction /> : permissions['parametrosAnalisis']  == 2 ? <ListAction2 />: null}
        bulkActionButtons={permissions['parametrosAnalisis']  == 1 ? <PostBulkActionButtons />: null}>
        <Datagrid>    
            <ReferenceField label="resources.parametros_analises.fields.unidad_funcional" source="unidad_funcional" reference="unidad_funcionals"  linkType={false} >
                <TextField source="fullCodigos" />
            </ReferenceField>
            <TextField source="plan" label="resources.parametros_analises.fields.plan" fullWidth />  
            <NumberField label="resources.parametros_analises.fields.num_analisis" source="num_analisis"  />
            {permissions['parametrosAnalisis'] == 1 || permissions['parametrosAnalisis'] == 2 || permissions['parametrosAnalisis'] == 4 ? <ShowButton/>: null}
            {permissions['parametrosAnalisis'] == 1 || permissions['parametrosAnalisis'] == 2 ? <EditButton/>: null}    
        </Datagrid>
    </List>
)};