import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
    BooleanInput, ReferenceInput, SelectInput, DateInput, required, AutocompleteInput,
    TextInput, NumberInput, ArrayInput, SimpleFormIterator, Loading
} from 'react-admin';
import { CurrencyInput } from '../../inputs/CurrencyInput'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import config from '../../config/config';
import { styles } from './../../EstilosGrid';
import { translate } from 'react-admin';

let urlAppjs = config.UrlServer;

class EditElement extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            Elementos: [],
            datos: {
                rutinario: [],
                periodico: [],
                rehabilitacion: [],
                cambio: [],
                unidadMedida: [],
                tipoElementos: []
            }

        }
        this.fetcher.bind(this);
        this.returnComponent.bind(this);

    }

    componentDidMount() {
        let urlTipoElemento = (this.props.sistema != "/sistemas/SEN") ?
            `tipo_elementos.json?pagination=off&sistema=${this.props.sistema}` :
            "tipo_elemento_senals.json?pagination=off";
        fetch(urlAppjs + "/unidad_medidas.json?pagination=off",
            {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                return response.json()
            })
            .then((unidades) => {
                this.state.datos.unidadMedida = unidades;
            })

        fetch(urlAppjs + `/${urlTipoElemento}`,
            {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                return response.json()
            })
            .then((tipoElementos) => {
                this.state.datos.tipoElementos = tipoElementos;

                this.fetcher("politicas_elementos", "rutinario");
                this.fetcher("politicas_elemento_periodicos", "periodico");
                this.fetcher("politicas_elemento_rehabilitacions", "rehabilitacion");
                this.fetcher("politicas_elemento_cambios", "cambio");
            })
    }

    fetcher(target, container) {
        fetch(urlAppjs + "/" + target + ".json?pagination=off&politicasMantenimiento=" + this.props.politica,
            {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                return response.json()
            })
            .then((Elementos) => {
                this.setState({ Elementos: Elementos })
                var datos = [];
                var unidad = "";
                var abreviatura
                var nombreElemento = "";
                if (this.state.Elementos.hasOwnProperty("length")) {
                    this.state.Elementos.map(index => {
                        this.state.datos.unidadMedida.map(record => {
                            if (record.id == index.unidadMedida.split("/")[2]) {
                                unidad = "/unidad_medidas/" + record.id;
                                abreviatura = record.abreviatura;
                            }
                        })
                        this.state.datos.tipoElementos.map(record => {
                            if (index.tipoElemento) {
                                if (record.id == index.tipoElemento.split("/")[2]) {
                                    nombreElemento = record.nombre;
                                }
                            } else if (index.tipoElementoSenal) {
                                if (record.id == index.tipoElementoSenal.split("/")[2]) {
                                    nombreElemento = record.nombre;
                                }
                            }
                        })
                        datos = {
                            id: "/"+target+ "/"+index.id,
                            sistema: index.sistema,
                            tipoElemento: index.tipoElemento,
                            tipoElementoSenal: index.tipoElementoSenal,
                            nombreElemento: nombreElemento,
                            operacion: index.operacion,
                            seRealiza: index.seRealiza,
                            unidadMedida: unidad,
                            abreviatura: abreviatura,
                            periocidad: index.periocidad,
                            valorU: index.valorU,
                            fechaU: index.fechaU,
                            prolVidaUtil: index.prolVidaUtil,
                            fuente: index.fuente,
                            fecha: index.fecha,
                            descripcion: index.descripcion
                        }
                        this.state.datos[container].push(datos);
                        this.forceUpdate();

                    })
                } else {
                    // console.log("datos?")
                    datos = {
                        id: "/"+target+ "/"+this.state.Elementos.id,
                        sistema: this.state.Elementos.sistema,
                        tipoElemento: this.state.Elementos.tipoElemento,
                        tipoElementoSenal: this.state.Elementos.tipoElementoSenal,
                        nombreElemento: this.state.Elementos.nombreElemento,
                        seRealiza: this.state.Elementos.seRealiza,
                        unidadMedida: this.state.Elementos.unidadMedida,
                        periocidad: this.state.Elementos.periocidad,
                        valorU: this.state.Elementos.valorU,
                        fechaU: this.state.Elementos.fechaU,
                        fuente: this.state.Elementos.fuente,
                        prolVidaUtil: this.state.Elementos.prolVidaUtil,
                        fecha: this.state.Elementos.fecha,
                        descripcion: this.state.Elementos.descripcion
                    }
                    this.state.datos[container].push(datos);
                    this.forceUpdate();
                }
            })
    }

    returnComponent(source, classes) {
        var arraySource = "";
        switch (source) {
            case "rutinario":
                arraySource = "politicasElementos"
                break;
            case "periodico":
                arraySource = "politicasElementoPeriodicos"
                break;
            case "rehabilitacion":
                arraySource = "politicasElementoRehabilitacions"
                break;
            case "cambio":
                arraySource = "politicasElementoCambios"
                break;
        }
        const diligenciado = (value) => {
            const { translate } = this.props;
            // console.log(source, value)
            var errorMsg = [];
            var returnMsg = "";
            var today = new Date();

            var month = "";
            if ((today.getMonth() + 1) < 10) {
                month = "0" + (today.getMonth() + 1)
            } else {
                month = (today.getMonth() + 1)
            }
            today = today.getFullYear() + "-" + month + "-" + today.getDate();
            if (typeof value !== 'undefined') {
                for (var i = 0; i < value.length; i++) {
                    if (value[i].seRealiza == true) {

                        if (value[i].periocidad == null || value[i].periocidad == "" ||
                            value[i].valorU == null || value[i].valorU == "" ||
                            value[i].fechaU == null || value[i].fechaU == "" ||
                            value[i].fuente == null || value[i].fuente == "" ||
                            value[i].fecha == null || value[i].fecha == "" ||
                            value[i].descripcion == null || value[i].descripcion == ""
                        ) {
                            errorMsg.push(translate('resources.validaciones.todosLosDatos') +
                                value[i].nombreElemento +
                                ". ");
                        } else {
                            if (value[i].fecha && value[i].fecha.toString().split("T")[0] > today) {
                                errorMsg.push(
                                    value[i].nombreElemento + " " +
                                    translate('resources.validaciones.fechaFutura'));
                            } else {
                                errorMsg = [];
                            }
                        }
                    }
                }
            }
            if (errorMsg.length > 0) {
                for (var i = 0; i < errorMsg.length; i++) {
                    returnMsg = returnMsg + (i + 1) + ")." + errorMsg[i];
                }
                // errorMsg.map( msg => {returnMsg = returnMsg + msg})
                return returnMsg;
            }
        }

        // console.log(this.state.datos[source]);
        return (
            <div>
                <DateInput label="resources.politicas_mantenimientos.fields.FechaIniPeriodicidad" defaultValue={this.state.datos[source][0].fecha} fullWidth disabled validate={[required()]} formClassName={classes.ocultar} />
                <ArrayInput
                    label=" "
                    source={arraySource + "2"}
                    validate={[diligenciado]}
                    defaultValue={this.state.datos[source]}
                >
                    <SimpleFormIterator disableAdd disableRemove>
                        {/**valores ocultos necesarios no diligenciables */}
                        <TextInput source="id" fullWidth formClassName={classes.ocultar} />
                        <TextInput id={source + ".sistema"} disabled label="resources.politicas_mantenimientos.fields.sistema" source="sistema" fullWidth formClassName={classes.ocultar} />
                        <TextInput id={source + ".tipoElemento"} disabled label="tipoElemento" source="tipoElemento" fullWidth formClassName={classes.ocultar} />
                        <TextInput id={source + ".tipoElementoSenal"} disabled label="tipoElemento" source="tipoElementoSenal" fullWidth formClassName={classes.ocultar} />
                        <TextInput disabled label="resources.politicas_mantenimientos.fields.unidadMedida" source="unidadMedida" fullWidth formClassName={classes.ocultar}/>
                        {/**------------------------------------------------- */}
                        <BooleanInput id={source + ".seRealiza"} label="dynatest.resources.politicas_mantenimientos.boolean.seRealiza" source="seRealiza" fullWidth formClassName={classes.fieldCol3} />
                        <TextInput disabled label="resources.politicas_mantenimientos.fields.nombre" source="nombreElemento" fullWidth formClassName={classes.fieldCol9} />
                        {source === "cambio" &&
                            <SelectInput
                                id={source + ".operacion"}
                                label="resources.politicas_mantenimientos.fields.operacion"
                                source="operacion"
                                fullWidth
                                formClassName={classes.fieldCol12}
                                choices={[
                                    { id: 'reconstruccion', name: 'dynatest.resources.politicas_mantenimientos.select.operacion.reconstruccion' },
                                    { id: 'cambio', name: 'dynatest.resources.politicas_mantenimientos.select.operacion.cambio' }
                                ]}
                            />
                        }
                        <br />
                        <TextInput id={source + ".unidad"} disabled label="resources.politicas_mantenimientos.fields.unidadMedida" source="abreviatura" fullWidth formClassName={classes.fieldCol3} />
                        {source === "rutinario" &&
                            <AutocompleteInput
                                label="resources.politicas_mantenimientos.fields.periocidad"
                                source="periocidad"
                                fullWidth
                                options={{ fullWidth: true }}
                                choices={[
                                    { id: 1, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.Mes1' },
                                    { id: 3, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.Meses3' },
                                    { id: 6, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.Meses6' },
                                    { id: 9, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.Meses9' },
                                    { id: 12, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.Meses12' },
                                    { id: 18, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.Meses18' },
                                ]}
                                formClassName={classes.fieldCol2}
                            />
                        }
                        {source === "periodico" &&
                            <AutocompleteInput
                                label="resources.politicas_mantenimientos.fields.periocidad"
                                source="periocidad"
                                fullWidth
                                options={{ fullWidth: true }}
                                choices={[
                                    { id: 1, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.Mes1' },
                                    { id: 3, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.Meses3' },
                                    { id: 6, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.Meses6' },
                                    { id: 9, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.Meses9' },
                                    { id: 12, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.Meses12' },
                                    { id: 18, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.Meses18' },
                                    { id: 24, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.Meses24' },
                                    { id: 36, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.Meses36' },
                                ]}
                                formClassName={classes.fieldCol2}
                            />
                        }
                        {source === "rehabilitacion" &&
                            <AutocompleteInput
                                label="resources.politicas_mantenimientos.fields.periocidad"
                                source="periocidad"
                                fullWidth
                                options={{ fullWidth: true }}
                                choices={[
                                    { id: 2, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios2' }, 
                                    { id: 2.5, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios2_5' },
                                    { id: 3, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios3' }, 
                                    { id: 3.5, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios3_5' },
                                    { id: 4, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios4' }, 
                                    { id: 4.5, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios4_5' },
                                    { id: 5, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios5' }, 
                                    { id: 5.5, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios5_5' },
                                    { id: 6, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios6' }, 
                                    { id: 6.5, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios6_5' },
                                    { id: 7, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios7' }, 
                                    { id: 7.5, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios7_5' },
                                    { id: 8, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios8' }, 
                                    { id: 8.5, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios8_5' },
                                    { id: 9, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios9' }, 
                                    { id: 9.5, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios9_5' },
                                    { id: 10, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios10' }
                                ]}
                                formClassName={classes.fieldCol2}
                            />
                        }
                        {source === "cambio" &&
                            <AutocompleteInput
                                label="resources.politicas_mantenimientos.fields.periocidad"
                                source="periocidad"
                                fullWidth
                                options={{ fullWidth: true }}
                                formClassName={classes.fieldCol12}
                                choices={[
                                    { id: 3, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios3' }, 
                                    { id: 4, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios4' }, 
                                    { id: 5, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios5' },
                                    { id: 6, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios6' }, 
                                    { id: 7, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios7' }, 
                                    { id: 8, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios8' },
                                    { id: 9, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios9' }, 
                                    { id: 10, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios10' }, 
                                    { id: 11, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios11' },
                                    { id: 12, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios12' }, 
                                    { id: 13, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios13' }, 
                                    { id: 14, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios14' },
                                    { id: 15, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios15' }, 
                                    { id: 16, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios16' }, 
                                    { id: 17, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios17' },
                                    { id: 18, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios18' }, 
                                    { id: 19, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios19' }, 
                                    { id: 20, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios20' },
                                    { id: 21, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios21' }, 
                                    { id: 22, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios22' }, 
                                    { id: 23, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios23' },
                                    { id: 24, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios24' }, 
                                    { id: 25, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios25' }, 
                                    { id: 26, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios26' },
                                    { id: 27, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios27' }, 
                                    { id: 28, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios28' }, 
                                    { id: 29, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios29' },
                                    { id: 30, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios30' }
                                ]}
                            />
                        }

                        <CurrencyInput id={source + ".valorU"} label="resources.politicas_mantenimientos.fields.valorU" source="valorU" fullWidth className="align-number" formClassName={classes.fieldCol2} />

                        <DateInput label="resources.politicas_mantenimientos.fields.fechaU" fullWidth source="fechaU" formClassName={classes.fieldCol3} />

                        <TextInput id={source + ".fuente"} label="resources.politicas_mantenimientos.fields.fuente" source="fuente" fullWidth formClassName={
                            (source === "rehabilitacion" || source === "cambio") ? classes.fieldCol3 : classes.fieldCol4
                        } />

                        {(source === "rehabilitacion" || source === "cambio") &&
                            <NumberInput id={source + ".vidaUtil"} label="resources.politicas_mantenimientos.fields.prolVidaUtil" source="prolVidaUtil" fullWidth formClassName={classes.fieldCol1} />
                        }
                        <DateInput id={source + ".fecha"} label="resources.politicas_mantenimientos.fields.fecha" source="fecha" fullWidth formClassName={classes.ocultar} />
                        <br />
                        <TextInput label="resources.politicas_mantenimientos.fields.descripcion" source="descripcion" fullWidth />
                    </SimpleFormIterator>
                </ArrayInput>
            </div>

        )

    }



    render() {
        const { translate } = this.props;
        const { classes } = this.props;
        if (this.state.datos["rutinario"].length &&
            this.state.datos["periodico"].length &&
            this.state.datos["rehabilitacion"].length &&
            this.state.datos["cambio"].length
        ) {
            return (
                <Fragment>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography >{translate('dynatest.resources.politicas_mantenimientos.expansionPanel.ElemenMantenRutinario')}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            {this.returnComponent("rutinario", classes)}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography >{translate('dynatest.resources.politicas_mantenimientos.expansionPanel.ElemenMantenPeriodico')}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            {this.returnComponent("periodico", classes)}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography >{translate('dynatest.resources.politicas_mantenimientos.expansionPanel.ElemenMantenRehabilitacion')}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            {this.returnComponent("rehabilitacion", classes)}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography >{translate('dynatest.resources.politicas_mantenimientos.expansionPanel.ElemenMantenReconstruccion_Cambio')}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            {this.returnComponent("cambio", classes)}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Fragment>
            )
        } else {
            return <Loading />
        }

    }
}

EditElement.propTypes = {
    classes: PropTypes.object.isRequired,
}
const EditElements = withStyles(styles)(EditElement);


export default translate(EditElements);