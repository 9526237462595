import React from 'react';
import { ReferenceField,TabbedShowLayout, Tab,TextField, DateField, NumberField } from 'react-admin';
import { Show } from '../../Show';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
const styles = {
    ocultar: {
        display:"none",
    },

};


export const TasasShow = 
withStyles(styles)(
   ({ classes, ...props }) => (
<Show title="Información Tasa de Crecimiento" {...props}>
        <TabbedShowLayout>
        <Tab label="detalles">
        <ReferenceField label="Estación de conteo" source="estacion_conteo" reference="estacion_conteos" fullWidth linkType={false}>
                <TextField source="nombre"  />
            </ReferenceField>
            <ReferenceField label="Unidad Funcional" source="estacion_conteo" reference="estacion_conteos" fullWidth linkType={false}>
                <TextField source="unidadFuncional_.codigo"  />
            </ReferenceField>
            <ReferenceField label="Segmento" source="estacion_conteo" reference="estacion_conteos" fullWidth linkType={false}>
                <TextField source="segmento_.codigo"  />
            </ReferenceField> 
             
                <b>Fecha de evaluación</b>
                <span></span>
                <span></span>                        
                <Divider /> 
                <Divider /> 
                <Divider />     
            <ReferenceField label="Fecha Inicial" source="date1" reference="ejes" fullWidth linkType={false}>                               
                    <TextField  source="dateq"  fullWidth />
            </ReferenceField>
            <ReferenceField label="Fecha Final" source="date2" reference="ejes" fullWidth linkType={false}>                               
                <TextField  source="dateq"  fullWidth />
            </ReferenceField>  
            <span></span>                                  
            <TextField  label="Autos" source="autodanovolumen" />
            <TextField  label="Buses" source="busesporcentaje" />    
            <TextField  label="Camiones" source="camiones" />   
            <span></span>  
                </Tab> 
                <Tab label="Categoría">
              
                
                    <h4 style={{marginBottom: 0}}>Autos</h4>                    
                    
                    
                    
                    
                    <NumberField label="Cantidad Fecha Inicial"  source="auto_dano" fullWidth />
                      
                      
                      <NumberField label="Cantidad Fecha Final" source="auto_volumen" fullWidth />
                      

                      
                    <h4 style={{marginBottom: 0}}>Buses</h4>                    
                    
                    
                    
                    
                    <NumberField label="Cantidad Fecha Inicial"  source="buses_dano" fullWidth />
                      
                      
                      <NumberField label="Cantidad Fecha Final" source="buses_volumen" fullWidth />
                      

                      
                    <h4 style={{marginBottom: 0}}>C2-P</h4>                    
                    
                    
                    
                    
                    <NumberField label="Cantidad Fecha Inicial"  source="c2_p_dano" fullWidth />
                      
                      
                      <NumberField label="Cantidad Fecha Final" source="c2_p_volumen" fullWidth />
                      
                      
                    <h4 style={{marginBottom: 0}}>C2-G</h4>                    
                    
                    
                    
                    
                    <NumberField label="Cantidad Fecha Inicial"  source="c2_g_dano" fullWidth />
                      
                      
                      <NumberField label="Cantidad Fecha Final" source="c2_g_volumen" fullWidth />
                      
                      
                    <h4 style={{marginBottom: 0}}>C3-C4</h4>                    
                    
                    
                    
                    
                    <NumberField label="Cantidad Fecha Inicial"  source="c3_dano" fullWidth />
                      
                      
                      <NumberField label="Cantidad Fecha Final" source="c3_volumen" fullWidth />
                      
                      
                    <h4 style={{marginBottom: 0}}>C5</h4>                    
                    
                    
                    
                    
                    <NumberField label="Cantidad Fecha Inicial"  source="c5_dano" fullWidth />
                      
                      
                      <NumberField label="Cantidad Fecha Final" source="c5_volumen" fullWidth />
                      
                      
                    <h4 style={{marginBottom: 0}}>>C5</h4>                    
                    
                    
                    
                    
                    <NumberField label="Cantidad Fecha Inicial"  source="c5_1_dano" fullWidth />
                      
                      
                    <NumberField label="Cantidad Fecha Final" source="c5_1_volumen" fullWidth />
                    <NumberField source="x" fullWidth className={classes.ocultar}/>
                     
                    
                </Tab>                  
           
            <Tab label="Auditoría">
                <DateField source="createdAt" label="Fecha creación" showTime/> 
                <TextField source="createdBy" label="Creado por"/>       
                <span></span>           
                <DateField source="updatedAt" label="Fecha actualización" showTime/>
                <TextField source="updatedBy" label="Actualizado por"/>    
                <span></span>              
            </Tab>
        </TabbedShowLayout>
    </Show>
));