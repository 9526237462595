import React from 'react';
import { SelectInput, required, minLength, maxLength } from 'react-admin';
import config from '../../../../config/config';
import { func } from 'prop-types';
let urlAppjs = config.UrlServer;
/**
 * Atributos requeridos
 * id
 * label
 * source
 * reference
 * operation
 * validate
 */

class UniqueYears extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            fetch: [],
            error: false,
            tipo: "",
            oldValue : null,
            reference : "estacion_conteos",
            id : this.props.source
        }
        
        this.algo = this.algo.bind(this);
        this.unique  = this.unique.bind(this);
        
    }
    
    algo(valor){
        
       this.setState({ years: valor }) 
    }
    


    // componentDidUpdate(){
    //     this.setState({ mes: this.props.source }) 
    //     var jsmes = "";
    //     jsmes = document.getElementById(this.props.source).value;    
    //     this.setState({ mes: jsmes })
    // }   
    unique(valor) {
        const { mes } = this.state;
       // console.log(this.state);
       // console.log(this.props)
    //     var jsunidad = "";
    //     if(document.getElementById("unidad_id")) {
    //         jsunidad = document.getElementById("unidad_id").value;
    //     }
    //     var jstramo = "";
    //     if(document.getElementById("tramo_id")) {
    //         jstramo = document.getElementById("tramo_id").value;
    //     }
    //     var jssegmento = "";
    //     if(document.getElementById("segmento_id")){
    //     jssegmento = document.getElementById("segmento_id").value;
    // }
        var jyears = "";
        jyears = document.getElementById(this.props.source).value;
        // console.log("peaje->" + jspeaje);
        // console.log("sentido->" + jssentido);
        // console.log("año->" + jsano);
        // console.log("mes->" + jsmes);
        fetch(urlAppjs + "/"+this.state.reference+".json?unidadFuncional.ruta.proyecto="+localStorage.getItem('id_project')+"&pagination=false&nombre="+this.props.nombre+"&years="+valor,

            {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                return response.json()
            })
            .then((response) => {
                this.state.fetch = response;
            });
        if (this.state.fetch.length) {
            return "Registro ya Realizado";
        }
    }
     
         
    render() {
        const {tipo} = this.state;
        return (
            <SelectInput
                id={this.props.source}
                label={this.props.label}
                source={this.props.source}
                style={{width:"300px"}}
                choices={[
                    { id: '2000', name: '2000' },
                    { id: '2001', name: '2001' },
                    { id: '2002', name: '2002' },
                    { id: '2003', name: '2003' },
                    { id: '2004', name: '2004' },
                    { id: '2005', name: '2005' },
                    { id: '2006', name: '2006' },
                    { id: '2007', name: '2007' },
                    { id: '2008', name: '2008' },
                    { id: '2009', name: '2009' },
                    { id: '2010', name: '2010' },
                    { id: '2011', name: '2011' },
                    { id: '2012', name: '2012' },
                    { id: '2013', name: '2013' },
                    { id: '2014', name: '2014' },
                    { id: '2015', name: '2015' },
                    { id: '2016', name: '2016' },
                    { id: '2017', name: '2017' },
                    { id: '2018', name: '2018' },
                    { id: '2019', name: '2019' },
                    { id: '2020', name: '2020' },
                    { id: '2021', name: '2021' },
                    { id: '2022', name: '2022' },
                    { id: '2023', name: '2023' },
                    { id: '2024', name: '2024' },
                    { id: '2025', name: '2025' },
                    { id: '2026', name: '2026' },
                    { id: '2027', name: '2027' },
                    { id: '2028', name: '2028' },
                    { id: '2029', name: '2029' },
                    { id: '2030', name: '2030' }
                  ]}
                  validate={[
                    this.unique,
                    required(), 
                    minLength(2), 
                    maxLength(10)
                ]}
                formClassName={this.props.formClassName}
            />
        )
        }
}

export default UniqueYears;
