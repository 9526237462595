import React from 'react';
import NumberFormat from 'react-number-format';


export default function PercentageFormat(props){
    const { inputRef, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            suffix="%"
            decimalSeparator=','
            fixedDecimalScale={true}
            allowNegative={false}            
        />
      );
}