import React, { useState, Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import {
  DateInput, BooleanInput, required, TabbedForm, FormTab, AutocompleteInput, SimpleFormIterator,
  ArrayInput, NumberInput, SelectInput, ReferenceInput, TextInput, FormDataConsumer
} from 'react-admin';
import { PimsCreate } from './../Create';
import { styles } from './../../EstilosGrid';
import {
  abValidationInicio, validarAB, abValidationFin, validarDosDecimales, validarUnDecimal, 
} from '../../../src/resources/validacionesInputs';
import { latMininima, lngMininima, Entero, isFechaInvalida} from '../validacionesInputs';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { minValue, maxValue, minLength, maxLength } from 'ra-core';
import { PRInput } from '../../inputs/PRInput';
import { DInput } from '../../inputs/DInput';
import { DEInput } from '../../inputs/DEInput';
import CoordenadasProyecto from '../CoordenadasInventario';
import AbsSegmento from '../AbsSegmento';
import config from '../../config/config';
import { ImagenesInventario } from '../images/ImagenesInventario';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { translate } from 'react-admin';


let urlAppjs = config.UrlServer;


export const CalificacionPuenteCreate = translate(withStyles(styles)(({ classes, ...props }) => {
  const { translate } = props;

  const styles = {
    ocultar: { display: "none" },

  };
  
  const [area_puentes, setPuentes] = useState('');
  const ValidarPuentes = (value) => {
    if (value != null) {
      let lenghtObject = Object.keys(value);
      fetch(urlAppjs + value + "?properties[geometria]")
        .then((response) => {
          return response.json()
        })
        .then((response) => {
          setPuentes(parseFloat(response.geometria[0].area_tab))
          sumDato()
        })
    }

  }

  let titulos2 = [];
  titulos2[0] = { "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.tablero.TableroTitle'), "data": [] };
  titulos2[0].data[0] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.tablero.SuperficieGrietas'), "dato": "1", 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.tablero.Area'), "decimales_dato": "1", "sum_dato": "1", "lugar_detalle": "0", "source": "calificacionPuentesSuperficieGrietas" };
  titulos2[0].data[1] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.tablero.SuperficieDanada'), "dato": "1", 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.tablero.Area'), "decimales_dato": "1", "sum_dato": "1", "lugar_detalle": "0", "source": "calificacionPuentesSuperficieDanada" };
  titulos2[0].data[2] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.tablero.SuperficieDesconchados'), "dato": "1", 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.tablero.Area'), "decimales_dato": "1", "sum_dato": "1", "lugar_detalle": "0", "source": "calificacionPuentesSuperficieDesconchados" };
  titulos2[0].data[3] = { "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.tablero.TotalAreaAfectada'), "dato": "1", 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.tablero.SumaAreas'), "decimales_dato": "1", "disabled_dato": "1", "default_value_dato": "0", "lugar_detalle": "0", "source": "calificacionPuentesTotalAreaAfectada" };
  titulos2[0].data[4] = { "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.tablero.PorcentajeAreaAfectada'), "dato": "1", 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.tablero.Calculo'), "decimales_dato": "1", "disabled_dato": "1", "default_value_dato": "0", "lugar_detalle": "0", "source": "calificacionPuentesPorcentajeAreaAfectada" };


  titulos2[1] = { "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.juntasExpansion.juntasExpansionTitle'), "data": [] };
  titulos2[1].data[0] = { "subtitulos": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.juntasExpansion.NumeroJuntasExpan'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato') , "dato": "1", "decimales_dato": "0", "lugar_detalle": "0", "source": "calificacionPuentesNroJuntasExpansion" };
  titulos2[1].data[1] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.juntasExpansion.ObstruccionSello'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "1", "agregar": "1", "source": "calificacionPuentesObstruccionSello" };
  titulos2[1].data[2] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.juntasExpansion.RupturaSello'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "1", "agregar": "1", "source": "calificacionPuentesRupturaSello" };
  titulos2[1].data[3] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.juntasExpansion.AusenciaSello'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "1", "agregar": "1", "source": "calificacionPuentesAusenciaSello" };
  titulos2[1].data[4] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.juntasExpansion.AgrietamientoRoturaSoldad'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "1", "agregar": "1", "source": "calificacionPuentesAgrietamientoRoturaSoldaduras" };
  titulos2[1].data[5] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.juntasExpansion.AusenciaAnclajes'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "1", "agregar": "1", "source": "calificacionPuentesAuenciaAnclajes" };
  titulos2[1].data[6] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.juntasExpansion.PerfilesDefectuosos'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "1", "agregar": "1", "source": "calificacionPuentesPerfilesDefectuosos" };
  titulos2[1].data[7] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.juntasExpansion.PerfilesSueltos'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "1", "agregar": "1", "source": "calificacionPuentesPerfilesSueltos" };
  titulos2[1].data[8] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.juntasExpansion.DesgasteGuardacantos'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "1", "agregar": "1", "source": "calificacionPuentesDesgasteGuardacantos" };
  titulos2[1].data[9] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.juntasExpansion.DesportillamientoGuardacantos'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "1", "agregar": "1", "source": "calificacionPuentesDesportillamientoGuardacantos" };
  titulos2[1].data[10] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.juntasExpansion.FisuramientoGuardacantos'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "1", "agregar": "1", "source": "calificacionPuentesFisuramientoGuradacantos" };
  titulos2[1].data[11] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.juntasExpansion.JuntaFuncional'), "decimales_dato": "0", "lugar_detalle": "0", "source": "calificacionPuentesJuntaNoFuncional" };


  titulos2[2] = { "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.AndenesBordillos.AndenesBordillosTitle'), "data": [] };
  titulos2[2].data[0] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.AndenesBordillos.Desportillamiento'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "2", "agregar": "1", "source": "calificacionPuentesDesportillamiento" };
  titulos2[2].data[1] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.AndenesBordillos.AceroRefuerzoExpuesto'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "2", "agregar": "1", "source": "calificacionPuentesAceroRefuerzoExpuesto" };
  titulos2[2].data[2] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.AndenesBordillos.DimensionesInsuficientes'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "2", "agregar": "1", "source": "calificacionPuentesDimensionesInsuficientes" };


  titulos2[3] = { "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.BarrSegBaranPretilMetal.Title'), "data": [] };
  titulos2[3].data[0] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.BarrSegBaranPretilMetal.ConexionesMalEstado'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "2", "agregar": "1", "source": "calificacionPuentesConexionesMalEstado" };
  titulos2[3].data[1] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.BarrSegBaranPretilMetal.ProteccionesMalEstado'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "2", "agregar": "1", "source": "calificacionPuentesProteccionesMalEstado" };

  titulos2[3].data[2] = { "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.BarrSegBaranPretilMetal.Pintura'), "decimales_dato": "0", "subtitulos": "1", "lugar_detalle": "0"/*, "source": "calificacionPuentesPintura" */};
  titulos2[3].data[3] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.BarrSegBaranPretilMetal.Delaminacion'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "2", "agregar": "1", "source": "calificacionPuentesPinturaDelaminacion" };
  titulos2[3].data[4] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.BarrSegBaranPretilMetal.Ausencia'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "2", "agregar": "1", "source": "calificacionPuentesPinturaAusencia" };
  titulos2[3].data[5] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.BarrSegBaranPretilMetal.Deterioro'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "2", "agregar": "1", "source": "calificacionPuentesPinturaDeterioro" };

  titulos2[3].data[6] = { "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.Postes.titlePostes'), "decimales_dato": "0", "subtitulos": "1", "lugar_detalle": "0"/*, "source": "calificacionPuentesPostes" */};
  titulos2[3].data[7] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.Postes.Fracturamiento'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "2", "agregar": "1", "source": "calificacionPuentesPostesFracturamiento" };
  titulos2[3].data[8] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.Postes.Ausencia'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "2", "agregar": "1", "source": "calificacionPuentesPostesAusencia" };
  titulos2[3].data[9] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.Postes.GolpesImpacto'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "2", "agregar": "1", "source": "calificacionPuentesPostesGolpesImpacto" };
  titulos2[3].data[10] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.Postes.Grietas5mm'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "2", "agregar": "1", "source": "calificacionPuentesPostesGrietas" };
  titulos2[3].data[11] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.Postes.Desconchados10mm'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "2", "agregar": "1", "source": "calificacionPuentesPostesDesconchados" };

  titulos2[3].data[12] = { "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.Pasamanos.titlePasamanos'), "decimales_dato": "0", "subtitulos": "1", "lugar_detalle": "0"/*, "source": "calificacionPuentesPasamanos" */};
  titulos2[3].data[13] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.Pasamanos.Corrosión'), 
                            "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                            "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "2", "agregar": "1", "source": "calificacionPuentesPasamanosCorrosion" };
  titulos2[3].data[14] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.Pasamanos.AusenciaElementos'), 
                            "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                            "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "2", "agregar": "1", "source": "calificacionPuentesPasamanosAusenciaElementos" };
  titulos2[3].data[15] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.Pasamanos.GolpesImpacto'), 
                            "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                            "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "2", "agregar": "1", "source": "calificacionPuentesPasamanosGolpesImpacto" };
  titulos2[3].data[16] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.Pasamanos.Grietas5mm'), 
                            "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                            "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "2", "agregar": "1", "source": "calificacionPuentesPasamanosGrietas" };
  titulos2[3].data[17] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.Pasamanos.Desconchados10mm'), 
                            "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                            "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "2", "agregar": "1", "source": "calificacionPuentesPasamanosDesconchados" };



  titulos2[4] = { "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.Title'), "data": [] };
  titulos2[4].data[1] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.Subestructura'), "decimales_dato": "0", "agregar": "1", "subtitulos": "1" };
  titulos2[4].data[2] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.FisurasFlexion'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "3", "agregar": "1", "source": "calificacionPuentesFisurasFlexion" };
  titulos2[4].data[3] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.FisurasCortante'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "3", "agregar": "1", "source": "calificacionPuentesFisurasCortante" };
  titulos2[4].data[4] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.FisurasTorsion'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "3", "agregar": "1", "source": "calificacionPuentesFisurasTorsion" };
  titulos2[4].data[5] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.AplastamientoLocal'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "3", "agregar": "1", "source": "calificacionPuentesAplastamientoLocal" };
  titulos2[4].data[6] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.Asentamientos'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "3", "agregar": "1", "source": "calificacionPuentesAsentamientos" };
  titulos2[4].data[7] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.Volcamiento'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "3", "agregar": "1", "source": "calificacionPuentesVolcamiento" };
  titulos2[4].data[8] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.Segregación'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "3", "agregar": "1", "source": "calificacionPuentesSegregacion" };
  titulos2[4].data[9] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.Hormigueros'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "3", "agregar": "1", "source": "calificacionPuentesHormigueros" };
  titulos2[4].data[10] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.FisurasRetraccion'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "3", "agregar": "1", "source": "calificacionPuentesFisurasRetraccion" };
  titulos2[4].data[11] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.RecubrimientoInadecuado'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "3", "agregar": "1", "source": "calificacionPuentesRecubrimientoInadecuado" };
  titulos2[4].data[12] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.ExposicionAceroRef'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "3", "agregar": "1", "source": "calificacionPuentesExposicionAceroRefuerzo" };
  titulos2[4].data[13] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.Infiltracion'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "3", "agregar": "1", "source": "calificacionPuentesInfiltracion" };
  titulos2[4].data[14] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.Eflorescencias'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "3", "agregar": "1", "source": "calificacionPuentesEflorencias" };
  titulos2[4].data[15] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.Carbonatacion'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "3", "agregar": "1", "source": "calificacionPuentesCarbonatacion" };
  titulos2[4].data[16] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.CorrosionArmadura'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "3", "agregar": "1", "source": "calificacionPuentesCorrosionArmadura" };
  titulos2[4].data[17] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.FallasImpacto'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "3", "agregar": "1", "source": "calificacionPuentesFallasImpacto" };
  titulos2[4].data[18] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.Socavacion'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "3", "agregar": "1", "source": "calificacionPuentesSocavacion" };
  titulos2[4].data[19] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.Erosion'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "3", "agregar": "1", "source": "calificacionPuentesErosion" };

  titulos2[4].data[20] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.DanosEstrucMetalicas'), "decimales_dato": "0", "lugar_detalle": "0", "agregar": "1", "subtitulos": "1" };
  titulos2[4].data[21] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.CorrosionLeve'), 
                            "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                            "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "3", "agregar": "1", "source": "calificacionPuentesCorrosionLeve" };
  titulos2[4].data[22] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.CorrosionMedia'), 
                            "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                            "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "3", "agregar": "1", "source": "calificacionPuentesCorrosionMedia" };
  titulos2[4].data[23] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.CorrosionSevera'), 
                            "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                            "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "3", "agregar": "1", "source": "calificacionPuentesCorrosionSevera" };
  titulos2[4].data[24] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.PinturaDeteriorada'), 
                            "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                            "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "3", "agregar": "1", "source": "calificacionPuentesPinturaDeteriorada" };

  titulos2[4].data[25] = { "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.CablesPendolones'), "decimales_dato": "0", "lugar_detalle": "0", "subtitulos": "1"/*, "source": "calificacionPuentesCablesPendolones" */};
  titulos2[4].data[26] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.PerdidaRecubriCables'), 
                           "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "1", 
                           "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "0", "source": "calificacionPuentesPerdidaRecubrimientoCables" };
  titulos2[4].data[27] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.FaltaTensionCablesPendol'), 
                           "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                           "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "0", "source": "calificacionPuentesFaltaTensionCablesPendolones" };
  titulos2[4].data[28] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.FisurasAlambres'), 
                           "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                           "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "0", "source": "calificacionPuentesFisurasAlambres" };
  titulos2[4].data[29] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.FisurasMuertosAnclajes'), 
                           "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                           "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "0", "source": "calificacionPuentesFisurasMuertosAnclajes" };
  titulos2[4].data[30] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.ContaminacionZonaAnclajes'), 
                           "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                           "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "0", "source": "calificacionPuentesContaminacionZonaAnclajes" };

  titulos2[4].data[31] = { "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.PerfMetalTorresMiembrosArmad'), "decimales_dato": "0", "lugar_detalle": "0", "subtitulos": "1"/*, "source": "calificacionPuentesPerfilesMetalicosTorres" */};
  titulos2[4].data[32] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.PandeoLocal'), 
                           "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                           "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "0", "source": "calificacionPuentesPandeoLocal" };
  titulos2[4].data[33] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.PandeoGeneralLateral'), 
                           "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                           "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "0", "source": "calificacionPuentesPandeoGeneralLateral" };
  titulos2[4].data[34] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.FisurasVigas'), 
                           "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                           "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "0", "source": "calificacionPuentesFisurasVigas" };
  titulos2[4].data[35] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.FallasImpacto'), 
                           "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                           "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "0", "source": "calificacionPuentesFallasImpactoPerfiles" };
  titulos2[4].data[36] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.DeflexionExcesiva'), 
                           "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                           "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "0", "source": "calificacionPuentesDeflexionExcesiva" };

  titulos2[4].data[37] = { "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.Conexiones'), "decimales_dato": "0", "subtitulos": "1", "lugar_detalle": "0", /*"source": "calificacionPuentesConexiones" */};
  titulos2[4].data[38] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.AusenciaConectores'), 
                           "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                           "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "0", "source": "calificacionPuentesAusenciaConectores" };
  titulos2[4].data[39] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.Excentricidades'), 
                           "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                           "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "0", "source": "calificacionPuentesExcentricidades" };
  titulos2[4].data[40] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.FallasTensionPlatina'), 
                           "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                           "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "0", "source": "calificacionPuentesFallasTensionPlatina" };
  titulos2[4].data[41] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.AplastamientoPlatina'), 
                           "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                           "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "0", "source": "calificacionPuentesAplastamientoPlatina" };
  titulos2[4].data[42] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.FallasDesgarramiento'), 
                           "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                           "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "0", "source": "calificacionPuentesFallasDesgarramiento" };
  titulos2[4].data[43] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.FallaCorteConector'), 
                           "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                           "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "0", "source": "calificacionPuentesFallaCorteConector" };
  titulos2[4].data[44] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.FallaBloqueCortante'), 
                           "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                           "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "0", "source": "calificacionPuentesFallaBloqueCortante" };
  titulos2[4].data[45] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.RoturaSoldadura'), 
                           "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                           "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "0", "source": "calificacionPuentesRoturaSoldadura" };


  titulos2[5] = { "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.Drenaje'), "data": [] };
  titulos2[5].data[1] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.SuperficieErosionada'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "2", "agregar": "1", "source": "calificacionPuentesSuperficieErosionada" };
  titulos2[5].data[2] = { "activo": "1", "valida_check":"1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.DanoEstructural'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "2", "agregar": "1", "source": "calificacionPuentesDanoEstructural" };
  titulos2[5].data[3] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.Taponamiento'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "2", "agregar": "1", "source": "calificacionPuentesTaponamiento" };
  titulos2[5].data[4] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.Ausencia'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "2", "agregar": "1", "source": "calificacionPuentesAusencia" };
  titulos2[5].data[5] = { "activo": "1", "nombre": translate('dynatest.resources.calificacion_puentes.inspeccion.DanosEstrucConcreto.LongitudInsuficiente'), 
                          "label_dato": translate('dynatest.resources.calificacion_puentes.inspeccion.dato'), "dato": "1", "decimales_dato": "0", 
                          "label_lugarDetalle": translate('dynatest.resources.calificacion_puentes.inspeccion.LugarDetalle'), "lugar_detalle": "2", "agregar": "1", "source": "calificacionPuentesLongitudInsuficiente" };


  let lugar_detalle = [[], 
  [{ id: "Entrada del puente", name: translate('dynatest.resources.calificacion_puentes.inspeccion.lugar_detalle.EntradaPuente') }, 
    { id: "Salida del puente", name: translate('dynatest.resources.calificacion_puentes.inspeccion.lugar_detalle.SalidaPuente') }, 
    { id: "Junta intermedia", name: translate('dynatest.resources.calificacion_puentes.inspeccion.lugar_detalle.JuntaIntermedia') }], 
  [{ id: "Costado derecho", name: translate('dynatest.resources.calificacion_puentes.inspeccion.lugar_detalle.CostadoDerecho') }, 
    { id: "Costado izquierdo", name: translate('dynatest.resources.calificacion_puentes.inspeccion.lugar_detalle.CostadoIzquierdo') }], 
  [{ id: "Losa", name: translate('dynatest.resources.calificacion_puentes.inspeccion.lugar_detalle.Losa') }, 
    { id: "Vigas", name: translate('dynatest.resources.calificacion_puentes.inspeccion.lugar_detalle.Vigas') }, 
    { id: "Riostras", name: translate('dynatest.resources.calificacion_puentes.inspeccion.lugar_detalle.Riostras') }, 
    { id: "Apoyos", name: translate('dynatest.resources.calificacion_puentes.inspeccion.lugar_detalle.Apoyos') }, 
    { id: "Arcos", name: translate('dynatest.resources.calificacion_puentes.inspeccion.lugar_detalle.Arcos') }, 
    { id: "Cimentación", name: translate('dynatest.resources.calificacion_puentes.inspeccion.lugar_detalle.Cimentacion') }, 
    { id: "Rótulas", name: translate('dynatest.resources.calificacion_puentes.inspeccion.lugar_detalle.Rotulas') }], 
  [{ id: "Arcos metálicos", name: translate('dynatest.resources.calificacion_puentes.inspeccion.lugar_detalle.ArcosMetalicos') }, 
    { id: "Perfiles metálicos", name: translate('dynatest.resources.calificacion_puentes.inspeccion.lugar_detalle.PerfilesMetalicos') }, 
    { id: "Armaduras", name: translate('dynatest.resources.calificacion_puentes.inspeccion.lugar_detalle.Armaduras') }, 
    { id: "Conexiones", name: translate('dynatest.resources.calificacion_puentes.inspeccion.lugar_detalle.Conexiones') }, 
    { id: "Cables", name: translate('dynatest.resources.calificacion_puentes.inspeccion.lugar_detalle.Cables') }, 
    { id: "Pendolones", name: translate('dynatest.resources.calificacion_puentes.inspeccion.lugar_detalle.Pendolones') }, 
    { id: "Torres", name: translate('dynatest.resources.calificacion_puentes.inspeccion.lugar_detalle.Torres') }]];

  const [cumplimiento, setCumplimiento] = useState('');
  const [cumplimientoChecks, setCumplimientoChecks] = useState('');
  const [cumplimientoLugarDet, setCumplimientoLugarDet] = useState('');
  const [cumplimientoPorcentaje, setCumplimientoPorcentaje] = useState('');

  const [checked_incumple, setCheckedIncumple] = React.useState({});
  const CheckIncumple = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    setCheckedIncumple((prevState) => ({
      ...prevState,
      [name]: value
    }));
    let objCheckeds = Object.entries(checked_incumple);
    let estado = false;
    if(objCheckeds.length > 0){
      objCheckeds.forEach(function (subValue) {
        if (subValue[0] == name) {
          subValue[1] = value
        }

        if (subValue[1] == true) {
          estado = true;
        }
      });
    }

    if(value == true){
      estado = true;
    }
    
   
    if (estado == true) {
      setCumplimientoChecks('No cumple')
      console.log("CumplimientoChecks = No cumple")
    } else {
      setCumplimientoChecks('Cumple')
      console.log("CumplimientoChecks = Cumple")
    }
  }

  const [lugar_select_incumple, setLugarSelectIncumple] = React.useState({});
  const lugarSeleccionado = (value, inputId) => {
    if (value != null) {
      console.log("lugarSeleccionado")
      let lenghtObject = Object.keys(value);
      let valor = '';
      for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }

      setLugarSelectIncumple((prevState) => ({
        ...prevState,
          [inputId]: valor
      }));
      let objCheckeds = Object.entries(lugar_select_incumple);
      let estado = false;
      if(objCheckeds.length > 0){
        objCheckeds.forEach(function (subValue) {
          if (subValue[0] == inputId) {
            subValue[1] = valor
          }
  
          if (subValue[1] == 'Cimentación' || subValue[1] == 'Rótulas') {
            estado = true;
          }
        });
      }
      if(valor == 'Cimentación' || valor == 'Rótulas'){
        estado = true;
      }
      

      if (estado == true) {
        setCumplimientoLugarDet('No cumple')
        //console.log("CumplimientoLugarDet = No cumple")
      } else {
        setCumplimientoLugarDet('Cumple')
        //console.log("CumplimientoLugarDet = Cumple")
      }
      
      /*console.log("value = " +value+ " ;; cumplimiento = " + cumplimiento + " ;; cumplimientoChecks = " + cumplimientoChecks + " ;; cumplimientoPorcentaje = " + cumplimientoPorcentaje)
      if(value == 'Cimentación' || value == 'Rótulas'){
        setCumplimientoLugarDet('No cumple')
      }else{
        setCumplimientoLugarDet('Cumple')
      }*/
    }
  }

  const sumDato = () => {
    let porcentaje = 0;
    let val = [];
    let total = 0;

    if (document.getElementsByName('calificacionPuentesSuperficieGrietas[0].dato')[0] !== null) {
      val[0] = document.getElementsByName('calificacionPuentesSuperficieGrietas[0].dato')[0].value;
    } else {
      val[0] = 0;
    }

    if (document.getElementsByName('calificacionPuentesSuperficieDanada[0].dato')[0] !== null) {
      val[1] = document.getElementsByName('calificacionPuentesSuperficieDanada[0].dato')[0].value;
    } else {
      val[1] = 0;
    }

    if (document.getElementsByName('calificacionPuentesSuperficieDesconchados[0].dato')[0] !== null) {
      val[2] = document.getElementsByName('calificacionPuentesSuperficieDesconchados[0].dato')[0].value;
    } else {
      val[2] = 0;
    }

    total = parseFloat(val[0] ? val[0] : 0) + parseFloat(val[1] ? val[1] : 0) + parseFloat(val[2] ? val[2] : 0);
    if (document.getElementsByName('calificacionPuentesTotalAreaAfectada[0].dato')[0] !== null) {
      document.getElementsByName('calificacionPuentesTotalAreaAfectada[0].dato')[0].value = total;
    }

    if (area_puentes > 0) {
      porcentaje = (total / area_puentes) * 100;
    } else {
      porcentaje = 0;
    }

    if (document.getElementsByName('calificacionPuentesPorcentajeAreaAfectada[0].dato')[0] !== null) {
      document.getElementsByName('calificacionPuentesPorcentajeAreaAfectada[0].dato')[0].value = Number.parseFloat(porcentaje).toFixed(2);
    }

    if (porcentaje > 5) {
      setCumplimientoPorcentaje("No cumple");
      //console.log("porcentaje = " +porcentaje + " ;; CumplimientoPorcentaje = No cumple")
    } else {
      setCumplimientoPorcentaje("Cumple");
      //console.log("porcentaje = " +porcentaje + " ;; CumplimientoPorcentaje = Cumple")
    }
  }

  /*OPCIONES PARA TIPO DE IMÁGENES*/
  const [defaultTipoImagen, setDefaultTipoImagen] = useState([
    {
      tipoImagen: '/tipo_images/21',
    },
    {
      tipoImagen: '/tipo_images/16',
    },
    {
      tipoImagen: '/tipo_images/17',
    },
    {
      tipoImagen: '/tipo_images/18',
    },
    {
      tipoImagen: '/tipo_images/19',
    },
    {
      tipoImagen: '/tipo_images/20',
    }
  ]);
  const OpcionTipoImagen = (value) => {
    console.log("triggered element type. " + value);
    setDefaultTipoImagen([
      {
        tipoImagen: '/tipo_images/21',
      },
      {
        tipoImagen: '/tipo_images/16',
      },
      {
        tipoImagen: '/tipo_images/17',
      },
      {
        tipoImagen: '/tipo_images/18',
      },
      {
        tipoImagen: '/tipo_images/19',
      },
      {
        tipoImagen: '/tipo_images/20',
      }
    ])
  }

  const [consect, setConsect] = useState('');
  const busqConsecutivo = (value) => {
    let valores = value.split('/');
    let puente = valores[2];
    fetch(urlAppjs + '/calificacionPuentesCon/' + puente)
        .then((response) => {
            return response.json();
        })
        .then((response) => {
          setConsect(response[0]);
        })

}

  return (
    <PimsCreate {...props}>
      <TabbedForm redirect="list">
        <FormTab label="dynatest.resources.calificacion_puentes.tabs.tabsTitle.detalles">
          <CoordenadasProyecto />
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <TextInput options={ { disabled: true } } label="resources.calificacion_puentes.fields.smartCode" source="smartCode" fullWidth defaultValue={consect}/>
            </Grid>
            <Grid item xs={4}>
              <ReferenceInput label="resources.calificacion_puentes.fields.puentes" source="puentes" reference="puentes" filter={{detalles: {estados:"Activo"}}} fullWidth validate={[required(), OpcionTipoImagen, ValidarPuentes, busqConsecutivo]}>
                <SelectInput optionText="smartCode" />
              </ReferenceInput>
            </Grid>
            <Grid item xs={4}>
              <DateInput label="resources.calificacion_puentes.fields.fechaInicioInspeccion" source="fechaInicioInspeccion" validate={[required(), isFechaInvalida]} fullWidth formClassName={classes.grid_cont4s} />
            </Grid>
            <Grid item xs={4}>
              <TextInput label="resources.calificacion_puentes.fields.nombreInspector" source="nombreInspector" fullWidth validate={[required(), minLength(1), maxLength(30)]} />
            </Grid>

            <Grid item xs={6}>
              <TextInput label="resources.calificacion_puentes.fields.nombreReporteInspector" source="nombreReporteInspector" fullWidth validate={[required(), minLength(1), maxLength(30)]} />
            </Grid>
            <Grid item xs={6}>
              <DateInput label="resources.calificacion_puentes.fields.fechaReporteInspeccion" source="fechaReporteInspeccion" validate={[required(), isFechaInvalida]} fullWidth formClassName={classes.grid_cont4s} />
            </Grid>

            <Grid item xs={4}>
              <SelectInput source="medidoPor" label="resources.calificacion_puentes.fields.medidoPor" validate={[required()]} fullWidth choices={[
                { id: translate('dynatest.resources.calificacion_puentes.select.medidoPor.Interventoria'), name: 'dynatest.resources.calificacion_puentes.select.medidoPor.Interventoria' },
                { id: translate('dynatest.resources.calificacion_puentes.select.medidoPor.Mantenimiento'), name: 'dynatest.resources.calificacion_puentes.select.medidoPor.Mantenimiento' },
              ]} />
            </Grid>
            <Grid item xs={4}>
              <SelectInput source="estado" label="resources.calificacion_puentes.fields.estado" validate={[required()]} fullWidth choices={[
                { id: translate('dynatest.resources.calificacion_puentes.select.estado.PorRevisar'), name: 'dynatest.resources.calificacion_puentes.select.estado.PorRevisar' },
                { id: translate('dynatest.resources.calificacion_puentes.select.estado.RevisionRealizada'), name: 'dynatest.resources.calificacion_puentes.select.estado.RevisionRealizada' },
                { id: translate('dynatest.resources.calificacion_puentes.select.estado.Publicada'), name: 'dynatest.resources.calificacion_puentes.select.estado.Publicada' },
              ]} />
            </Grid>
            <Grid item xs={4}>
              <SelectInput source="tipoMedicion" label="resources.calificacion_puentes.fields.tipoMedicion" validate={[required()]} fullWidth choices={[
                { id: translate('dynatest.resources.calificacion_puentes.select.tipoMedicion.Periodica'), name: 'dynatest.resources.calificacion_puentes.select.tipoMedicion.Periodica' },
                { id: translate('dynatest.resources.calificacion_puentes.select.tipoMedicion.DeCorreccion'), name: 'dynatest.resources.calificacion_puentes.select.tipoMedicion.DeCorreccion' },
              ]} />
            </Grid>
            <TextInput style={{ display: 'none' }} id={`cumplimiento`} source={`cumplimiento`} label="resources.calificacion_puentes.fields.cumplimiento" defaultValue="Cumple" />
            <FormDataConsumer formClassName={classes.ocultar}>
              {({ formData, dispatch, ...rest }) => {
                if (typeof formData != 'undefined' && formData.cumplimiento) {
                  if(cumplimientoPorcentaje == "No cumple" || cumplimientoChecks == "No cumple" || cumplimientoLugarDet == "No cumple"){
                    formData.cumplimiento = "No cumple"
                  }else{
                    formData.cumplimiento = "Cumple"
                  }
                  //console.log("form cumplimiento = " + formData.cumplimiento)
                  //console.log("cumplimientoPorcentaje = " +cumplimientoPorcentaje+ " ;; cumplimientoChecks = " + cumplimientoChecks + " ;; cumplimientoLugarDet = " + cumplimientoLugarDet)
                }
              }}
            </FormDataConsumer>
          </Grid>
        </FormTab>

        <FormTab label="dynatest.resources.calificacion_puentes.tabs.tabsTitle.Inspeccion">
          <React.Fragment>
            {
              titulos2.map((data, i) => (
                <ExpansionPanel>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography><h2>{titulos2[i].nombre}</h2></Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Table size="small">
                      <TableBody>
                        {
                          //Crear una referencia(source) de cada campo pero apuntando a la misma tabla en la clase
                        }
                        {
                          titulos2[i].data.map((info, z) => {
                            return ((!info.hasOwnProperty("source")) ?
                              <div>
                                <ArrayInput label={(!info.hasOwnProperty("subtitulos")) ? <Typography><h2 style={{marginTop: "0px",marginBottom: "0px"}}>{info.nombre}</h2></Typography> : <Typography><h2 style={{marginTop: "0px",marginBottom: "0px"}}>{info.nombre}</h2></Typography>} fullWidth>
                                  <SimpleFormIterator fullWidth disableAdd disableRemove>
                                  </SimpleFormIterator>
                                </ArrayInput>
                                <hr fullWidth size="2px" color="gray"/>
                              </div>
                              :
                              ((info.hasOwnProperty("agregar")) ? (
                                <div>
                                  <ArrayInput label={(!info.hasOwnProperty("subtitulos")) ? <Typography><h2 style={{marginTop: "0px",marginBottom: "0px"}}>{info.nombre}</h2></Typography> : <Typography><h2 style={{marginTop: "0px",marginBottom: "0px"}}>{info.nombre}</h2></Typography>} fullWidth source={info.source}
                                    defaultValue={[
                                      {
                                        activo: false,
                                        dato: "",
                                        lugarDetalle: "",
                                      }
                                    ]}>
                                    <SimpleFormIterator style="" fullWidth>
                                      <BooleanInput onChange={info.hasOwnProperty("valida_check")? CheckIncumple:''} formClassName={classes.grid_cont4_f} style={(!info.hasOwnProperty("activo")) ? { display: 'none' } : {}} label=" " source="activo" fullWidth />
                                      <NumberInput formClassName={classes.grid_cont4_s} defaultValue={info.hasOwnProperty("default_value_dato") ? info.default_value_dato : ""} style={(!info.hasOwnProperty("dato")) ? { display: 'none' } : {}} options={info.hasOwnProperty("disabled_dato") ? { disabled: true } : {}} onChange={(info.hasOwnProperty("sum_dato")) ? sumDato : ""} label={info.hasOwnProperty("label_dato") ? info.label_dato : "Dato"} source="dato" fullWidth validate={(info.decimales_dato == 1) ? [validarDosDecimales, minValue(0)] : [minValue(0),Entero]} />
                                      <SelectInput onChange={event => lugarSeleccionado(event,info.source)} formClassName={classes.grid_cont4_s} style={info.hasOwnProperty("lugar_detalle")? ((info.lugar_detalle == 0) ? { display: 'none' } : {}) : {}} source="lugarDetalle" label={info.hasOwnProperty("label_lugarDetalle") ? info.label_lugarDetalle : "Lugar Detalle"} fullWidth choices={lugar_detalle[info.lugar_detalle]} />
                                    </SimpleFormIterator>
                                  </ArrayInput>
                                  <hr fullWidth size="2px" color="gray"/>
                                </div>)
                                :
                                (
                                  <div>
                                    <ArrayInput label={(!info.hasOwnProperty("subtitulos")) ? <Typography><h2 style={{marginTop: "0px",marginBottom: "0px"}}>{info.nombre}</h2></Typography> : <Typography><h2 style={{marginTop: "0px",marginBottom: "0px"}}>{info.nombre}</h2></Typography>} fullWidth source={info.source}
                                    defaultValue={[
                                      {
                                        activo: false,
                                        dato: "",
                                        lugarDetalle: "",
                                      }
                                    ]}>
                                      <SimpleFormIterator fullWidth disableAdd disableRemove>
                                        <BooleanInput onChange={info.hasOwnProperty("valida_check")? CheckIncumple:''} formClassName={classes.grid_cont4_f} style={(!info.hasOwnProperty("activo")) ? { display: 'none' } : {}} label=" " source="activo" fullWidth />
                                        <NumberInput formClassName={classes.grid_cont4_s} defaultValue={info.hasOwnProperty("default_value_dato") ? info.default_value_dato : ""} style={(!info.hasOwnProperty("dato")) ? { display: 'none' } : {}} options={info.hasOwnProperty("disabled_dato") ? { disabled: true } : {}} onChange={(info.hasOwnProperty("sum_dato")) ? sumDato : ""} label={info.hasOwnProperty("label_dato") ? info.label_dato : "Dato"} source="dato" fullWidth validate={(info.decimales_dato == 1) ? [validarDosDecimales, minValue(0)] : [minValue(0),Entero]} />
                                        <SelectInput onChange={event => lugarSeleccionado(event,info.source)} formClassName={classes.grid_cont4_s} style={info.hasOwnProperty("lugar_detalle")? ((info.lugar_detalle == 0) ? { display: 'none' } : {}) : {}} source="lugarDetalle" label="Lugar Detalle" fullWidth choices={lugar_detalle[info.lugar_detalle]} />
                                      </SimpleFormIterator>
                                    </ArrayInput>
                                    <hr fullWidth size="2px" color="gray"/>
                                  </div>)
                              )
                            )
                          })
                        }
                      </TableBody>
                    </Table>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              ))
            }
          </React.Fragment>
          {/*<DetalleCalificacionPuentes areaPuentes={area_puentes} classes={classes} />*/}
        </FormTab>
        <FormTab label="dynatest.resources.calificacion_puentes.tabs.tabsTitle.fotografias">
          <ImagenesInventario type='create' defaultTipoImagen={defaultTipoImagen} />
        </FormTab>
      </TabbedForm>
    </PimsCreate>
  )
}));
