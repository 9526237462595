import React from 'react';
import { TabbedShowLayout, Tab, TextField, DateField, CardActions, EditButton,ListButton, SelectField,WithPermissions } from 'react-admin';
import { Show } from './../Show';
import { PField } from './../../fields/PField';
import { withStyles } from '@material-ui/core';
import  {styles}  from './../../EstilosGrid';
import { translate } from 'react-admin';


import Divider from '@material-ui/core/Divider';

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

export const RutaShow = translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;
    return(
    <Show  title="dynatest.resources.rutas.title.showTitle" {...props}>
        <TabbedShowLayout>
            <Tab label="dynatest.resources.rutas.tabs.informacionBasica">
                {/* <ReferenceField source="proyecto" reference="proyectos" linkType="show"><TextField source="nombre" /></ReferenceField>                 */}
                <TextField label="resources.rutas.fields.codigo" source="fullCode" />
                <SelectField source="categoriaRuta" choices={[
                    { id: 1, name: 'dynatest.resources.rutas.select.categoria.priOrden' },
                    { id: 2, name: 'dynatest.resources.rutas.select.categoria.segOrden' },
                    { id: 3, name: 'dynatest.resources.rutas.select.categoria.terOrden' },
                ]} />
                {/* <TextField label="Categoría" source="categoriaRuta" /> */}
                <span></span>
                <PField source="pInicio"/>
                <PField source="pFin"/>
                <span></span>
                <b>{translate('dynatest.resources.rutas.title.inforInvias')}</b>
                <span></span>
                <span></span>                        
                <Divider /> 
                <Divider /> 
                <Divider /> 
                <TextField source="tramo" />
                <TextField source="sector" /> 
                <span></span>   
                <TextField source="origen" /> 
                <TextField source="destino" /> 
                <span></span>
            </Tab>
            <Tab label="dynatest.resources.rutas.tabs.auditoria">
            <DateField source="createdAt" showTime/> 
                <TextField source="createdBy"/>
                <span></span>               
                <DateField source="updatedAt" showTime/>
                <TextField source="updatedBy"/>
                <span></span>    
            </Tab>
        </TabbedShowLayout>
    </Show>
)
            }));