import React, { useState, Fragment } from 'react';
import {Loading} from 'react-admin';
import {change} from 'redux-form';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
    TextField
} from 'react-admin';
import { styles } from './../../EstilosGrid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { translate } from 'react-admin';


class DatatableAudit extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            type : "",
            old : [],
            new : [],
            altered : [],
        }
    }

    componentDidMount() {
        let dataValidate = this.props.data;
        this.state.type = dataValidate.type;
        if(dataValidate.type == "Creación"){
            this.state.new = dataValidate.new.split("^");
        }else if(dataValidate.type == "Edición"){
            this.state.altered = dataValidate.alteredValue.split("^");
        }else if(dataValidate.type == "Eliminación"){
            this.state.old = dataValidate.old.split("^");
        }
    }
    componentDidUpdate(){
        
    }    

    render() {
        const { translate } = this.props;
        const { classes } = this.props;

        if(this.state.type == "Creación"){
            return(
                <Table id='tableAudit' style={{ width: '100%' }} >
                    <TableHead>
                        <TableRow>
                            <TableCell component="th" id='thAuditAlign'>{translate('resources.audits.tabs.tableShow.titleField')}</TableCell>
                            <TableCell component="th" id='thAuditAlign'>{translate('resources.audits.tabs.tableShow.titleNew')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
    
                    {this.state.new.map((data, i) => {
                        let firstSplit = data.slice(0, data.indexOf(' '));
                        let secondSplit = data.slice(data.indexOf(' ') + 1);
                        return(
                            <TableRow>
                                <TableCell fullWidth>{firstSplit}</TableCell>
                                <TableCell fullWidth>{secondSplit}</TableCell>
                            </TableRow>
                            )
                        })}
                                
                    </TableBody>
                </Table>
            )
        }else if (this.state.type == "Edición") {
            return(
                <Table id='tableAudit' style={{ width: '100%' }} >
                    <TableHead>
                        <TableRow>
                            <TableCell component="th" id='thAuditAlign'>{translate('resources.audits.tabs.tableShow.titleField')}</TableCell>
                            <TableCell component="th" id='thAuditAlign'>{translate('resources.audits.tabs.tableShow.titleOld')}</TableCell>
                            <TableCell component="th" id='thAuditAlign'>{translate('resources.audits.tabs.tableShow.titleNew')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
    
                    {this.state.altered.map((data, i) => {
                        if(data != ""){
                            let firstSplit = data.slice(0, data.indexOf(' '));
                            let secondSplit = data.slice(data.indexOf(' ') + 1);
                            let changeOld = secondSplit.slice(0, secondSplit.indexOf('(:)'));
                            let changeNew = secondSplit.slice(secondSplit.indexOf('(:)') + 3); 
                            return(
                                <TableRow>
                                    <TableCell fullWidth>{firstSplit}</TableCell>
                                    <TableCell fullWidth>{changeOld}</TableCell>
                                    <TableCell fullWidth>{changeNew}</TableCell>
                                </TableRow>
                            )
                        }
                        })}
                                
                    </TableBody>
                </Table>
            )
        }else if (this.state.type == "Eliminación") {
            return(
                <Table id='tableAudit' style={{ width: '100%' }} >
                    <TableHead>
                        <TableRow>
                            <TableCell component="th" id='thAuditAlign'>{translate('resources.audits.tabs.tableShow.titleField')}</TableCell>
                            <TableCell component="th" id='thAuditAlign'>{translate('resources.audits.tabs.tableShow.titleOld')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
    
                    {this.state.old.map((data, i) => {
                        let firstSplit = data.slice(0, data.indexOf(' '));
                        let secondSplit = data.slice(data.indexOf(' ') + 1);
                        return(
                            <TableRow>
                                <TableCell fullWidth>{firstSplit}</TableCell>
                                <TableCell fullWidth>{secondSplit}</TableCell>
                            </TableRow>
                            )
                        })}
                                
                    </TableBody>
                </Table>
            )
        }else{
            return <Loading />
        }

       
    }
}
// const tableData = withStyles(styles)(DatatableAudit);
export default translate(DatatableAudit);