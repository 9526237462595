import React, { useState } from 'react';
import {
    FormTab, NumberInput, ReferenceInput, Toolbar, SaveButton, SelectInput, ArrayInput, SimpleFormIterator,
    DateInput, BooleanInput, TextInput, AutocompleteInput
} from 'react-admin';
import { PimsElementoActions } from './../PimsElementoActions';
import {
    validarUnDecimal, abValidationInicio, abValidationFin, latMininima, lngMininima, validarLogicaAbsFinCreate, prValidationInicio, prValidationFin,
    validacionmaxfechaact, isFechaInvalida, Entero
} from '../../validacionesInputs';
import { styles } from './../../../EstilosGrid';
import { PimsCreate } from './../../Create';
import { PRInput } from '../../../inputs/PRInput';
import { PInput } from '../../../inputs/PInput';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CancelIcon from '@material-ui/icons/Cancel';
import { withStyles } from '@material-ui/core';
import {
    required,
    maxLength,
    minValue,
} from 'react-admin';
import TabbedForm from './../../TabbedForm';
import CoordenadasProyecto from '../../CoordenadasInventario';
import AbsSegmento from '../../AbsSegmento';
import config from '../../../config/config';
import { ImagenesInventario } from '../../images/ImagenesInventario';
import { translate } from 'react-admin';

let urlAppjs2 = config.UrlFront;

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const tipoElemento = { sistema: 'ALR' };

const LinkCancelar = () => {
    let redirect = "elemento_alrededors"
    return <a href={urlAppjs2 + redirect} class="cancelar" ><CancelIcon style={{ width: '0.8em', paddingRight: '8px' }} />CANCELAR</a>;
}


const CustomToolbar = ({ basePath, data, ...props }) => (
    <Toolbar {...props} >
        <SaveButton />
        <LinkCancelar />
    </Toolbar>
);

export const ElementoAlrededorCreate = translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;

    /* VALIDAR LOS RANGOS QUE PUEDEN TENER LAS LATITUDES Y LONGITUDES */
    const [latMin, setLatMin] = useState('');
    const [lngMin, setLngMin] = useState('');
    const [latMax, setLatMax] = useState('');
    const [lngMax, setLngMax] = useState('');
    const [segmentos, setSegmento] = useState('');
    const ValidarSegmento = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setSegmento(value)
        let idSegmento = valor;
        idSegmento = idSegmento.split('/');
    }

    /* FIN DE VALIDACIÓN DE LOS RANGOS QUE PUEDEN TENER LAS LATITUDES Y LONGITUDES */

    /*VALORES POR DEFECTO DEL ARRAY DE COORDENADAS*/
    const [latitudMinimaArray, setLatitudArray] = useState('');
    const LatitudMinimaArray = (value) => {
        setLatitudArray(value)
    }
    const [longitudMinimaArray, setLongitudArray] = useState('');
    const LongitudMinimaArray = (value) => {
        setLongitudArray(value)
    }
    const [longitudMaximaArray, setLongitudMaxdArray] = useState('');
    const LongitudMaximaArray = (value) => {
        setLongitudMaxdArray(value)
    }
    const [latitudMaximaArray, setLatitudMaxdArray] = useState('');
    const LatitudMaximaArray = (value) => {
        setLatitudMaxdArray(value)
    }
    /*FIN DE LOS VALORES POR DEFECTO DEL ARRAY DE COORDENADAS*/



    //------- VALIDACIONES DE NUMEROS NEGATIVOS -------
    const validarNegativos = (value) => {
        if (value < 0) {
            return translate('dynatest.resources.elemento_alrededors.messagesVal.valNumNegativos');
        }
    }


    const [unidads, setUnidad] = useState('');
    const ValidarUnidad = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setUnidad(value)
    }
    const Unidades = { unidadFuncional: unidads };

    const [tramos, setTramo] = useState('');
    const ValidarTramo = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setTramo(value)
    }
    const Tramoss = { tramo: tramos };

    const [estados, setEstado] = useState('');

    const [elemento, setElemento] = useState('');
    const [opciones, setOpcion] = useState();
    const ValidarElemento = (value) => {

        let lenghtObject = Object.keys(value);
        let valor = '';

        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setElemento(valor);
        if (valor != "/tipo_elemento/MARG" && valor != "/tipo_elemento/SEPD" && valor != "/tipo_elemento/ZINT") {
            setEstado("");
        }
    }

    const ValidarEstado = (value) => {

        let lenghtObject = Object.keys(value);
        let valor = '';

        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setEstado(valor)

    }

    const [obras, setObr] = useState('');

    const ValidarObra = (value) => {
        let valor = value['target']['checked'];
        setObr(valor)
        if (valor == false) {
            setFicha(valor)
            setEstudio(valor)
        }

    }

    const [ficha, setFicha] = useState('');

    const ValidarFicha = (value) => {
        let valor = value['target']['checked'];
        setFicha(valor)
        if (valor == false) {
            setEstudio(valor)
        }

    }
    const [estudio, setEstudio] = useState('');

    const ValidarEstudio = (value) => {
        let valor = value['target']['checked'];
        setEstudio(valor)

    }

    const choices = opciones;
    const optionRenderer = choice => `${choice.name}`;

    const FormatoGarantia = v => {
        if (v) {
            let vaux = v.toString().replace(',', '').replace('.', '')
            return parseInt(vaux)
        }
        else {
            return v;
        }
    }


    const [ancho, setAncho] = useState(0);
    const largo = (value) => {
        let valAncho = 0;
        if (document.getElementById('23')) {
            valAncho = document.getElementById('23').value;
        }
        if (value && value != null) {
            setAncho(parseFloat(value) * parseFloat(valAncho).toFixed(1));
        }
    }


    /*OPCIONES PARA TIPO DE IMÁGENES*/
    const [defaultTipoImagen, setDefaultTipoImagen] = useState([
        {
            tipoImagen: '/tipo_images/1',
        },
        {
            tipoImagen: '/tipo_images/7',
        },
        {
            tipoImagen: '/tipo_images/8',
        },
        {
            tipoImagen: '/tipo_images/9',
        },
        {
            tipoImagen: '/tipo_images/13',
        },
        {
            tipoImagen: '/tipo_images/14',
        }
    ]);
    const OpcionTipoImagen = (value) => {
        console.log("triggered element type. " + value);
        setDefaultTipoImagen([
            {
                tipoImagen: '/tipo_images/1',
            },
            {
                tipoImagen: '/tipo_images/7',
            },
            {
                tipoImagen: '/tipo_images/8',
            },
            {
                tipoImagen: '/tipo_images/9',
            },
            {
                tipoImagen: '/tipo_images/13',
            },
            {
                tipoImagen: '/tipo_images/14',
            }
        ])
    }
    //    const abValidationInicio1 =() =>{

    //     /*VALORES ABS INICIO*/
    //     let AbInicioBase = document.getElementById('IdAbInicio');
    //     if(AbInicioBase)
    //     {

    //         AbInicioBase = AbInicioBase.value
    //         let ingresoAbInicio = document.getElementById('ingresoAbInicio1').value;
    //         let separadorInicio = ingresoAbInicio.split("+");
    //         let separadorInicio1 = separadorInicio[0].split("K");
    //         let seAbInicioBase = AbInicioBase.split(".");
    //         let AbFinBase = document.getElementById('IdAbFin').value;
    //         let seAbFinBase = AbFinBase.split(".");

    //      /*VALIDACIONES PR INICIO*/
    //      if(parseInt(separadorInicio1[1] ) < parseInt(AbInicioBase) || parseInt(separadorInicio1[1] ) > parseInt(AbFinBase) )
    //      {

    //          return "Error el rango debe estar entre " + AbInicioBase +" y " + AbFinBase;
    //      }
    //      if(parseInt(separadorInicio1[1] ) === parseInt(AbInicioBase))
    //      {
    //          if(parseInt(separadorInicio[1]) < parseInt(seAbInicioBase[1]))

    //          return "Error el rango debe estar entre " + AbInicioBase +" y " + AbFinBase;
    //      }
    //      if(parseInt(separadorInicio1[1] ) === parseInt(seAbFinBase[0]))
    //      {

    //          if(parseInt(separadorInicio[1]) > parseInt(seAbFinBase[1]))

    //          return "Error el rango debe estar entre " + AbInicioBase +" y " + AbFinBase;
    //      }
    //    }
    //      }
    const validatePrimera = (value) => {
        let AbInicioBase = document.getElementById('IdAbInicio');
        if (AbInicioBase) {
            if (typeof value !== 'undefined') {
                if (value.length > 0) {
                    AbInicioBase = AbInicioBase.value
                    let seAbInicioBase = AbInicioBase.split(".");
                    let AbFinBase = document.getElementById('IdAbFin').value;
                    let seAbFinBase = AbFinBase.split(".");
                    for (let i = 0; i < value.length; i++) {
                        if (value[i].hasOwnProperty("abInicio")) {
                            let ingresoAbInicio = value[i].abInicio
                            let separadorInicio = ingresoAbInicio.split("+");
                            let separadorInicio1 = separadorInicio[0].split("K");

                            /*VALIDACIONES PR INICIO*/
                            if (parseInt(separadorInicio1[1]) < parseInt(AbInicioBase) || parseInt(separadorInicio1[1]) > parseInt(AbFinBase)) {

                                return translate('dynatest.resources.elemento_alrededors.messagesVal.rangoLongitud') + AbInicioBase + " y " + AbFinBase;
                            }
                            if (parseInt(separadorInicio1[1]) === parseInt(AbInicioBase)) {
                                if (parseInt(separadorInicio[1]) < parseInt(seAbInicioBase[1]))

                                    return translate('dynatest.resources.elemento_alrededors.messagesVal.rangoLongitud') + AbInicioBase + " y " + AbFinBase;
                            }
                            if (parseInt(separadorInicio1[1]) === parseInt(seAbFinBase[0])) {

                                if (parseInt(separadorInicio[1]) > parseInt(seAbFinBase[1]))

                                    return translate('dynatest.resources.elemento_alrededors.messagesVal.rangoLongitud') + AbInicioBase + " y " + AbFinBase;
                            }
                        }
                    }
                }
            }
        }
    }
    const [tipoServicio, setTipoServicio] = useState('');
    const ValidarTipoServicio = (value) => {
        let valor = Object.keys(value);
        let cadena = '';
        for (let i = 0; i < valor.length-1; i++){
            cadena += value[i]
        }
        setTipoServicio(cadena);
    }
    return (
        <PimsCreate  {...props} actions={<PimsElementoActions />}>
            <TabbedForm redirect="list">
                <FormTab label="dynatest.resources.elemento_alrededors.tabs.tabsTitle.identificacion">
                    <CoordenadasProyecto />
                    <Grid container spacing={24}>
                        <Grid item xs={4}>
                            <ReferenceInput
                                label="resources.elemento_alrededors.fields.tipoElemento"
                                source="tipoElemento"
                                reference="tipo_elementos"
                                validate={[required(), OpcionTipoImagen]}
                                onChange={ValidarElemento}
                                filter={tipoElemento}
                                sort={{ field: 'nombre', order: 'ASC' }}
                                fullWidth
                                formClassName={classes.grid_cont12}
                            >
                                <SelectInput optionText="nombre" />
                            </ReferenceInput>
                        </Grid>
                        <Grid item xs={4}>
                            <SelectInput label="resources.elemento_alrededors.fields.margen" source="margen" allowEmpty emptyValue="" validate={[required()]} choices={[
                                { id: translate('dynatest.resources.elemento_alrededors.select.margen.derecha'), name: 'dynatest.resources.elemento_alrededors.select.margen.derecha' },
                                { id: translate('dynatest.resources.elemento_alrededors.select.margen.izquierda'), name: 'dynatest.resources.elemento_alrededors.select.margen.izquierda' },
                                { id: translate('dynatest.resources.elemento_alrededors.select.margen.centro'), name: 'dynatest.resources.elemento_alrededors.select.margen.centro' },
                            ]} fullWidth formClassName={classes.grid_cont12} />
                        </Grid>
                        <Grid item xs={4}>
                            <SelectInput label="resources.elemento_alrededors.fields.sentido" source='sentido' choices={[
                            { id: translate('dynatest.resources.elemento_alrededors.select.sentido.NORTEId'), name: 'dynatest.resources.elemento_alrededors.select.sentido.NORTEName' },
                            { id: translate('dynatest.resources.elemento_alrededors.select.sentido.SURId'), name: 'dynatest.resources.elemento_alrededors.select.sentido.SURName' },
                            { id: translate('dynatest.resources.elemento_alrededors.select.sentido.ESTEId'), name: 'dynatest.resources.elemento_alrededors.select.sentido.ESTEName' },
                            { id: translate('dynatest.resources.elemento_alrededors.select.sentido.OESTEId'), name: 'dynatest.resources.elemento_alrededors.select.sentido.OESTEName' }
                            ]}
                            fullWidth
                            formClassName={classes.grid_cont4_f}/>
                        </Grid>
                    </Grid>
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <Typography variant="b" component="b">{translate('dynatest.resources.elemento_alrededors.subTitle.localizacion')}</Typography>
                        </Grid>
                    </Grid>
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <Divider fullWidth />
                        </Grid>
                    </Grid>
                    <Grid container spacing={24}>
                        <Grid item xs={4}>
                            <ReferenceInput
                                label="resources.elemento_alrededors.fields.unidadFuncional"
                                source="unidadFuncional"
                                reference="unidad_funcionals"
                                filterToQuery={searchText => ({ codigo: searchText })}
                                sort={{ field: 'codigo', order: 'asc' }}
                                validate={[required(), ValidarUnidad]}
                                fullWidth
                                formClassName={classes.grid_cont4_f}
                            >
                                <AutocompleteInput options={{ fullWidth: true }} optionText="fullCodigos" />
                            </ReferenceInput>
                        </Grid>

                        {unidads &&
                            <Grid item xs={4}>
                                <ReferenceInput
                                    label="resources.elemento_alrededors.fields.tramo"
                                    source="tramo"
                                    alwaysOn
                                    reference="tramos"
                                    filter={{ unidadFuncional: unidads }}
                                    filterToQuery={searchText => ({ codigo: searchText })}
                                    sort={{ field: 'codigo', order: 'ASC', }}
                                    validate={[required(), ValidarTramo]}
                                    formClassName={classes.grid_cont4_s}
                                >
                                    <AutocompleteInput options={{ fullWidth: true }} optionText="fullCodigos" />
                                </ReferenceInput>
                            </Grid>
                        }
                        {tramos &&
                            <Grid item xs={4}>
                                <ReferenceInput
                                    label="resources.elemento_alrededors.fields.segmento"
                                    source="segmento"
                                    reference="segmentos"
                                    sort={{ field: 'codigo', order: 'ASC' }}
                                    filter={{ tramo: tramos }}
                                    filterToQuery={searchText => ({ codigo: searchText })}
                                    validate={[required(), ValidarSegmento]}
                                    formClassName={classes.grid_cont4_s}
                                >

                                    <AutocompleteInput options={{ fullWidth: true }} optionText="fullCodigos" />
                                </ReferenceInput>
                            </Grid>
                        }
                    </Grid>

                    {segmentos &&
                        <AbsSegmento segmento={segmentos} />
                    }
                    {segmentos &&
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Typography variant="h3" component="h3" className={classes.coord}>{translate('dynatest.resources.elemento_alrededors.subTitle.coordenadasIniciales')}</Typography>
                                <Divider fullWidth style={{ marginTop: '10px' }} />
                            </Grid>
                        </Grid>
                    }
                    {segmentos && <PRInput label="resources.elemento_alrededors.fields.abscisa" id="ingresoAbInicio" source="abInicio" fullWidth validate={[abValidationInicio, required()]} formClassName={classes.grid_cont3s} />}
                    {segmentos && <PInput label="resources.elemento_alrededors.fields.pr" id="ingresoPrInicio" source="pInicio" fullWidth validate={[prValidationInicio]} formClassName={classes.grid_cont3} />}
                    {segmentos && <NumberInput label="resources.elemento_alrededors.fields.latitud" id="LatitudInicial" source="latitudInicial" fullWidth validate={[required(), latMininima, LatitudMinimaArray]} formClassName={classes.grid_cont3} />}
                    {segmentos && <NumberInput label="resources.elemento_alrededors.fields.longitud" id="LongitudInicial" source="longitudInicial" fullWidth validate={[required(), lngMininima, LongitudMinimaArray]} formClassName={classes.grid_cont3} />}
                    {segmentos &&
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Typography variant="h3" component="h3" className={classes.coord}>{translate('dynatest.resources.elemento_alrededors.subTitle.coordenadasFinales')}</Typography>
                                <Divider fullWidth style={{ marginTop: '10px' }} />
                            </Grid>
                        </Grid>
                    }
                    {segmentos && <PRInput label="resources.elemento_alrededors.fields.abscisa" id="ingresoAbFin" source="abFin" fullWidth validate={[abValidationFin, validarLogicaAbsFinCreate]} formClassName={classes.grid_cont3s} />}
                    {segmentos && <PInput label="resources.elemento_alrededors.fields.pr" id="ingresoPrFin" source="pFin" fullWidth validate={[prValidationFin]} formClassName={classes.grid_cont3} />}
                    {segmentos && <NumberInput label="resources.elemento_alrededors.fields.latitud" source="latitudFinal" fullWidth validate={[latMininima, LatitudMaximaArray]} formClassName={classes.grid_cont3} />}
                    {segmentos && <NumberInput label="resources.elemento_alrededors.fields.longitud" source="longitudFinal" fullWidth validate={[lngMininima, LongitudMaximaArray]} formClassName={classes.grid_cont3} />}
                    {segmentos &&
                        <ArrayInput label="" source="ubicaciones" className={classes.ocultar}
                            defaultValue={[
                                {
                                    lat: latitudMinimaArray,
                                    lng: longitudMinimaArray
                                },
                                {
                                    lat: latitudMaximaArray,
                                    lng: longitudMaximaArray
                                }
                            ]}
                        >
                            <SimpleFormIterator disableAdd disableRemove >
                                <NumberInput label="Latitud" source="lat" fullWidth />
                                <NumberInput label="Longitud" source="lng" fullWidth />
                            </SimpleFormIterator>
                        </ArrayInput>
                    }
                </FormTab>
                {/* ---------- CARACTERÍSTICAS ------------- */}
                <FormTab label="dynatest.resources.elemento_alrededors.tabs.tabsTitle.caracteristicas">
                    {elemento === "/tipo_elementos/COMR" &&
                        <SelectInput label="resources.elemento_alrededors.fields.tipoServicio" source="tipoServicio" allowEmpty emptyValue="" onChange={ValidarTipoServicio} choices={[
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoServicio.barraca'), name: "dynatest.resources.elemento_alrededors.select.tipoServicio.barraca" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoServicio.centroEducativo'), name: "dynatest.resources.elemento_alrededors.select.tipoServicio.centroEducativo" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoServicio.estaciónCombustible'), name: "dynatest.resources.elemento_alrededors.select.tipoServicio.estaciónCombustible" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoServicio.hospitalCentroSalud'), name: "dynatest.resources.elemento_alrededors.select.tipoServicio.hospitalCentroSalud" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoServicio.hotel'), name: "dynatest.resources.elemento_alrededors.select.tipoServicio.hotel" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoServicio.montallantas'), name: "dynatest.resources.elemento_alrededors.select.tipoServicio.montallantas" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoServicio.restaurante'), name: "dynatest.resources.elemento_alrededors.select.tipoServicio.restaurante" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoServicio.tallerAutomotriz'), name: "dynatest.resources.elemento_alrededors.select.tipoServicio.tallerAutomotriz" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoServicio.otros'), name: "dynatest.resources.elemento_alrededors.select.tipoServicio.otros" },

                        ]} fullWidth formClassName={classes.grid_cont4_f} />
                    }
                    {elemento === "/tipo_elementos/COMR" &&
                        <SelectInput label="resources.elemento_alrededors.fields.tipoAcceso" source="tipoAcceso" allowEmpty emptyValue="" choices={[
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoAcceso.conCarrilAcelDesa'), name: "dynatest.resources.elemento_alrededors.select.tipoAcceso.conCarrilAcelDesa" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoAcceso.sinCarril'), name: "dynatest.resources.elemento_alrededors.select.tipoAcceso.sinCarril" },
                        ]} fullWidth formClassName={classes.grid_cont4_s} />
                    }
                    {elemento === "/tipo_elementos/COMR" &&
                        <SelectInput label="resources.elemento_alrededors.fields.estacionamiento" source="estacionamiento" allowEmpty emptyValue="" choices={[
                            { id: translate('dynatest.resources.elemento_alrededors.select.estacionamiento.enFachada'), name: "dynatest.resources.elemento_alrededors.select.estacionamiento.enFachada" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.estacionamiento.enVia'), name: "dynatest.resources.elemento_alrededors.select.estacionamiento.enVia" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.estacionamiento.interno'), name: "dynatest.resources.elemento_alrededors.select.estacionamiento.interno" },
                        ]} fullWidth formClassName={classes.grid_cont4_s} />
                    }
                    
                    {elemento === "/tipo_elementos/COMR" &&
                        <br />
                    }
                    {elemento === "/tipo_elementos/COMR" &&

                        <NumberInput
                            label="resources.elemento_alrededors.fields.largo"
                            source="largo"
                            id="22"
                            validate={[largo, minValue(0), validarUnDecimal]}
                            fullWidth
                            formClassName={classes.grid_cont4_f}
                        />
                    }
                    {elemento === "/tipo_elementos/COMR" &&
                        <NumberInput
                            label="resources.elemento_alrededors.fields.ancho"
                            source="ancho"
                            id="23"
                            validate={[minValue(0), validarUnDecimal]}
                            fullWidth formClassName={classes.grid_cont4_s}
                        />
                    }
                    {elemento === "/tipo_elementos/COMR" &&
                        <NumberInput
                            label="resources.elemento_alrededors.fields.area"
                            source="area"
                            disabled
                            defaultValue={ancho}
                            validate={[minValue(0)]}
                            fullWidth formClassName={classes.grid_cont4_s}
                        />
                    }
                    {(elemento === "/tipo_elementos/COMR" && tipoServicio === translate('dynatest.resources.elemento_alrededors.select.tipoServicio.barraca')) &&
                        <SelectInput label="resources.elemento_alrededors.fields.identificacion" source="identificacion" allowEmpty emptyValue="" choices={[
                            { id: translate('dynatest.resources.elemento_alrededors.select.identificacion.Regular'), name: "dynatest.resources.elemento_alrededors.select.identificacion.Regular" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.identificacion.Irregular'), name: "dynatest.resources.elemento_alrededors.select.identificacion.Irregular" },
                        ]} fullWidth formClassName={classes.grid_cont6_f} />
                    }
                    {(elemento === "/tipo_elementos/COMR" && tipoServicio === translate('dynatest.resources.elemento_alrededors.select.tipoServicio.barraca')) &&
                        <TextInput
                            label="resources.elemento_alrededors.fields.propietario"
                            source="propietario"
                            validate={[maxLength(100)]}
                            fullWidth
                            formClassName={classes.grid_cont6_s}
                        />
                    }
                    {(elemento === "/tipo_elementos/COMR" && tipoServicio === translate('dynatest.resources.elemento_alrededors.select.tipoServicio.barraca')) &&
                        <TextInput
                            label="resources.elemento_alrededors.fields.emailPropietario"
                            source="emailPropietario"
                            validate={[maxLength(100)]}
                            fullWidth
                            formClassName={classes.grid_cont6_f}
                        />
                    }
                    {(elemento === "/tipo_elementos/COMR" && tipoServicio === translate('dynatest.resources.elemento_alrededors.select.tipoServicio.barraca')) &&
                        <NumberInput
                            label="resources.elemento_alrededors.fields.telefonoPropietario"
                            source="telefonoPropietario"
                            validate={[minValue(0), Entero]}
                            fullWidth
                            formClassName={classes.grid_cont6_s}
                        />
                    }

                    {/* ------ Elemento = Márgenes ------- */}
                    {elemento === "/tipo_elementos/MARG" &&
                        <SelectInput label="resources.elemento_alrededors.fields.tipoServicioCarac" source="tipoServicio" allowEmpty emptyValue="" choices={[
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoServicio.concretoAdoquin'), name: "dynatest.resources.elemento_alrededors.select.tipoServicio.concretoAdoquin" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoServicio.noAplica'), name: "dynatest.resources.elemento_alrededors.select.tipoServicio.noAplica" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoServicio.talud'), name: "dynatest.resources.elemento_alrededors.select.tipoServicio.talud" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoServicio.vegetación'), name: "dynatest.resources.elemento_alrededors.select.tipoServicio.vegetación" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoServicio.otro'), name: "dynatest.resources.elemento_alrededors.select.tipoServicio.otro" },

                        ]} fullWidth formClassName={classes.grid_cont12} />
                    }
                    {elemento === "/tipo_elementos/MARG" &&
                        <NumberInput
                            label="resources.elemento_alrededors.fields.largoM"
                            source="largo"
                            validate={[required(), minValue(0), validarUnDecimal]}
                            fullWidth formClassName={classes.grid_cont4_f}
                        />
                    }
                    {elemento === "/tipo_elementos/MARG" &&
                        <NumberInput
                            label="resources.elemento_alrededors.fields.anchoM"
                            source="ancho"
                            validate={[required(), minValue(0), validarUnDecimal]}
                            fullWidth
                            formClassName={classes.grid_cont4_s}
                        />
                    }
                    {elemento === "/tipo_elementos/MARG" &&
                        <NumberInput
                            label="resources.elemento_alrededors.fields.areaM"
                            source="area"
                            validate={[required(), minValue(0), validarUnDecimal]}
                            fullWidth formClassName={classes.grid_cont4_s}
                        />
                    }

                    {/* ------ Elemento =  Separadores ------- */}
                    {elemento === "/tipo_elementos/SEPD" &&
                        <SelectInput label="resources.elemento_alrededors.fields.tipoServicioCarac" source="tipoServicio" allowEmpty emptyValue="" choices={[
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoServicio.concretoAdoquin'), name: "dynatest.resources.elemento_alrededors.select.tipoServicio.concretoAdoquin" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoServicio.noAplica'), name: "dynatest.resources.elemento_alrededors.select.tipoServicio.noAplica" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoServicio.talud'), name: "dynatest.resources.elemento_alrededors.select.tipoServicio.talud" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoServicio.vegetación'), name: "dynatest.resources.elemento_alrededors.select.tipoServicio.vegetación" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoServicio.otro'), name: "dynatest.resources.elemento_alrededors.select.tipoServicio.otro" },

                        ]} fullWidth formClassName={classes.grid_cont12} />
                    }
                    {elemento === "/tipo_elementos/SEPD" &&
                        <NumberInput
                            label="resources.elemento_alrededors.fields.largoM"
                            source="largo"
                            validate={[required(), minValue(0), validarUnDecimal]}
                            fullWidth
                            formClassName={classes.grid_cont4_f}
                        />
                    }
                    {elemento === "/tipo_elementos/SEPD" &&
                        <NumberInput
                            label="resources.elemento_alrededors.fields.anchoM"
                            source="ancho"
                            validate={[required(), minValue(0), validarUnDecimal]}
                            fullWidth
                            formClassName={classes.grid_cont4_s}
                        />
                    }
                    {elemento === "/tipo_elementos/SEPD" &&
                        <NumberInput
                            label="resources.elemento_alrededors.fields.areaM"
                            source="area"
                            validate={[required(), minValue(0), validarUnDecimal]}
                            fullWidth
                            formClassName={classes.grid_cont4_s}
                        />
                    }

                    {/* ------ Elemento = Márgenes, Separadores ------- */}
                    {elemento === "/tipo_elementos/OBST" &&
                        <SelectInput label="resources.elemento_alrededors.fields.tipoServicioObs" source="tipoServicio" allowEmpty emptyValue="" choices={[
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoServicio.cercado'), name: "dynatest.resources.elemento_alrededors.select.tipoServicio.cercado" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoServicio.construccion'), name: "dynatest.resources.elemento_alrededors.select.tipoServicio.construccion" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoServicio.otros'), name: "dynatest.resources.elemento_alrededors.select.tipoServicio.otros" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoServicio.rio'), name: "dynatest.resources.elemento_alrededors.select.tipoServicio.rio" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoServicio.zonaVerde'), name: "dynatest.resources.elemento_alrededors.select.tipoServicio.zonaVerde" },
                        ]} fullWidth formClassName={classes.grid_cont6s} />
                    }
                    {elemento === "/tipo_elementos/OBST" &&
                        <NumberInput
                            label="resources.elemento_alrededors.fields.longitudAfectacion"
                            source="longitudAfectacion"
                            validate={[required(), minValue(0), validarUnDecimal]}
                            fullWidth
                            formClassName={classes.grid_cont6}
                        />
                    }
                    {elemento === "/tipo_elementos/OBST" &&
                        <br />
                    }
                    {elemento === "/tipo_elementos/OBST" &&
                        <NumberInput
                            label="resources.elemento_alrededors.fields.largoM"
                            source="largo"
                            validate={[required(), minValue(0), validarUnDecimal]}
                            fullWidth
                            formClassName={classes.grid_cont4_f}
                        />
                    }
                    {elemento === "/tipo_elementos/OBST" &&
                        <NumberInput
                            label="resources.elemento_alrededors.fields.anchoM"
                            source="ancho"
                            validate={[required(), minValue(0), validarUnDecimal]}
                            fullWidth
                            formClassName={classes.grid_cont4_s}
                        />
                    }
                    {elemento === "/tipo_elementos/OBST" &&
                        <NumberInput
                            label="resources.elemento_alrededors.fields.areaOb"
                            source="area"
                            validate={[required(), minValue(0), validarUnDecimal]}
                            fullWidth
                            formClassName={classes.grid_cont4_s}
                        />
                    }

                    {/* ------ Elemento = Zona inestable ------- */}

                    {elemento === "/tipo_elementos/ZINT" &&
                        <SelectInput label="resources.elemento_alrededors.fields.tipoServicioZINT" source="tipoServicio" allowEmpty emptyValue="" choices={[
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoServicio.caida'), name: "dynatest.resources.elemento_alrededors.select.tipoServicio.caida" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoServicio.deslizamiento'), name: "dynatest.resources.elemento_alrededors.select.tipoServicio.deslizamiento" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoServicio.flujos'), name: "dynatest.resources.elemento_alrededors.select.tipoServicio.flujos" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoServicio.movimientoComplejo'), name: "dynatest.resources.elemento_alrededors.select.tipoServicio.movimientoComplejo" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoServicio.volcamiento'), name: "dynatest.resources.elemento_alrededors.select.tipoServicio.volcamiento" },
                        ]} fullWidth formClassName={classes.grid_cont4_f} />
                    }
                    {elemento === "/tipo_elementos/ZINT" &&
                        <SelectInput label="resources.elemento_alrededors.fields.riesgo" source="riesgo" allowEmpty emptyValue="" choices={[
                            { id: translate('dynatest.resources.elemento_alrededors.select.riesgo.baja'), name: "dynatest.resources.elemento_alrededors.select.riesgo.baja" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.riesgo.media'), name: "dynatest.resources.elemento_alrededors.select.riesgo.media" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.riesgo.alta'), name: "dynatest.resources.elemento_alrededors.select.riesgo.alta" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.riesgo.muyAlta'), name: "dynatest.resources.elemento_alrededors.select.riesgo.muyAlta" },
                        ]} validate={[required()]} fullWidth formClassName={classes.grid_cont4_s} />
                    }
                    {elemento === "/tipo_elementos/ZINT" &&
                        <SelectInput label="resources.elemento_alrededors.fields.seccion" source="seccion" allowEmpty emptyValue="" choices={[
                            { id: translate('dynatest.resources.elemento_alrededors.select.seccion.taludSuperior'), name: "dynatest.resources.elemento_alrededors.select.seccion.taludSuperior" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.seccion.taludInferior'), name: "dynatest.resources.elemento_alrededors.select.seccion.taludInferior" },
                        ]} validate={[required()]} fullWidth formClassName={classes.grid_cont4_s} />
                    }
                    {elemento === "/tipo_elementos/ZINT" &&
                        <br />
                    }
                    {elemento === "/tipo_elementos/ZINT" &&
                        <NumberInput label="resources.elemento_alrededors.fields.longitudAfectacionZINT" source="longitudAfectacion" validate={[required(), minValue(0), validarUnDecimal]} fullWidth formClassName={classes.grid_cont4_f} />
                    }
                    {elemento === "/tipo_elementos/ZINT" &&
                        <SelectInput label="resources.elemento_alrededors.fields.tipoTerraplen" source="tipoTerraplen" allowEmpty emptyValue="" choices={[
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoTerraplen.Corte'), name: "dynatest.resources.elemento_alrededors.select.tipoTerraplen.Corte" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoTerraplen.Relleno'), name: "dynatest.resources.elemento_alrededors.select.tipoTerraplen.Relleno" },
                        ]} fullWidth formClassName={classes.grid_cont4_s} />
                    }
                    {elemento === "/tipo_elementos/ZINT" &&
                        <NumberInput label="resources.elemento_alrededors.fields.cantidadBermas" source="cantidadBermas" validate={[minValue(1), Entero]} fullWidth formClassName={classes.grid_cont4_s} />
                    }
                    {elemento === "/tipo_elementos/ZINT" &&
                        <br />
                    }
                    {elemento === "/tipo_elementos/ZINT" &&
                        <NumberInput label="resources.elemento_alrededors.fields.alturaPromedioBermas" source="alturaPromedioBermas" validate={[minValue(1), validarUnDecimal]} fullWidth formClassName={classes.grid_cont4_f} />
                    }
                    {elemento === "/tipo_elementos/ZINT" &&
                        <br />
                    }
                    {elemento === "/tipo_elementos/ZINT" &&
                        <Typography variant="h3" component="h3" className={classes.coord}>{translate('dynatest.resources.elemento_alrededors.subTitle.TramosPendVariable')}</Typography>
                    }
                    {elemento === "/tipo_elementos/ZINT" &&
                        <Divider fullWidth style={{ marginTop: '10px' }} />
                    }
                    {elemento === "/tipo_elementos/ZINT" &&

                        <ArrayInput label=" " fullWidth source="tramosPendientes" validate={[validatePrimera]}>
                            <SimpleFormIterator >
                                <NumberInput label="resources.elemento_alrededors.fields.tramo" source="tramoPendiente" fullWidth formClassName={classes.grid_cont4_f} />
                                <PRInput label="resources.elemento_alrededors.fields.abInicio" id="ingresoAbFin" source="abInicio" fullWidth formClassName={classes.grid_cont4_s} />
                                <NumberInput source="pendTransversal" label="resources.elemento_alrededors.fields.pendTransversal" fullWidth formClassName={classes.grid_cont4_s} />
                            </SimpleFormIterator>
                        </ArrayInput>
                    }
                    {elemento === "/tipo_elementos/ZINT" &&
                        <BooleanInput label="resources.elemento_alrededors.fields.obraEstabilizacion" source="obraEstabilizacion" onChange={ValidarObra} fullWidth formClassName={classes.grid_cont4_s} />

                    }

                    {obras &&
                        <SelectInput label="resources.elemento_alrededors.fields.tipoObra" source="tipoObra" allowEmpty emptyValue="" validate={[required()]} choices={[
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoObra.anclajesRoca'), name: "dynatest.resources.elemento_alrededors.select.tipoObra.anclajesRoca" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoObra.cercadosRedesContencion'), name: "dynatest.resources.elemento_alrededors.select.tipoObra.cercadosRedesContencion" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoObra.EscudoContraCaidaRocas'), name: "dynatest.resources.elemento_alrededors.select.tipoObra.EscudoContraCaidaRocas" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoObra.muroAnclado'), name: "dynatest.resources.elemento_alrededors.select.tipoObra.muroAnclado" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoObra.muroConcretoReforzado'), name: "dynatest.resources.elemento_alrededors.select.tipoObra.muroConcretoReforzado" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoObra.muroEncofrado'), name: "dynatest.resources.elemento_alrededors.select.tipoObra.muroEncofrado" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoObra.muroGaviones'), name: "dynatest.resources.elemento_alrededors.select.tipoObra.muroGaviones" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoObra.muroGravedadConcreto'), name: "dynatest.resources.elemento_alrededors.select.tipoObra.muroGravedadConcreto" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoObra.muroTierraReforzada'), name: "dynatest.resources.elemento_alrededors.select.tipoObra.muroTierraReforzada" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoObra.murosContencion'), name: "dynatest.resources.elemento_alrededors.select.tipoObra.murosContencion" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoObra.pantallasAncladas'), name: "dynatest.resources.elemento_alrededors.select.tipoObra.pantallasAncladas" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoObra.pilotesCaissons'), name: "dynatest.resources.elemento_alrededors.select.tipoObra.pilotesCaissons" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoObra.reconformacion'), name: "dynatest.resources.elemento_alrededors.select.tipoObra.reconformacion" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoObra.revestimientoFlexible'), name: "dynatest.resources.elemento_alrededors.select.tipoObra.revestimientoFlexible" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoObra.revestimientoVegetal'), name: "dynatest.resources.elemento_alrededors.select.tipoObra.revestimientoVegetal" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoObra.sueloEmpernado'), name: "dynatest.resources.elemento_alrededors.select.tipoObra.sueloEmpernado" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoObra.otros'), name: "dynatest.resources.elemento_alrededors.select.tipoObra.otros" },
                        ]} fullWidth formClassName={classes.grid_cont12} />

                    }
                    {obras &&
                        <NumberInput
                            label="resources.elemento_alrededors.fields.largoM"
                            source="largo"
                            validate={[required(), minValue(0), validarUnDecimal]}
                            fullWidth formClassName={classes.grid_cont4_f}
                        />
                    }
                    {obras &&
                        <NumberInput
                            label="resources.elemento_alrededors.fields.anchoM"
                            source="ancho"
                            validate={[required(), minValue(0), validarUnDecimal]}
                            fullWidth formClassName={classes.grid_cont4_s}
                        />
                    }
                    {obras &&
                        <NumberInput
                            label="resources.elemento_alrededors.fields.areaOb"
                            source="area"
                            validate={[required(), minValue(0), validarUnDecimal]}
                            fullWidth formClassName={classes.grid_cont4_s}
                        />
                    }
                    {obras &&
                        <br />
                    }
                    {obras &&
                        <BooleanInput
                            label="resources.elemento_alrededors.fields.fichaInspeccion"
                            source="fichaInspeccion"
                            onChange={ValidarFicha}
                            fullWidth
                            formClassName={classes.grid_cont12}
                        />
                    }
                    {ficha &&
                        <TextInput
                            label="resources.elemento_alrededors.fields.inspector"
                            source="inspector"
                            validate={[minValue(0)]}
                            fullWidth
                            formClassName={classes.grid_cont4_f}
                        />
                    }
                    {ficha &&
                        <DateInput
                            label="resources.elemento_alrededors.fields.fechaInspeccion"
                            source="fechaInspeccion"
                            fullWidth
                            formClassName={classes.grid_cont4_s}
                            validate={[validacionmaxfechaact, isFechaInvalida]}
                        />
                    }
                    {ficha &&
                        <TextInput
                            label="resources.elemento_alrededors.fields.nombreFicha"
                            source="nombreFicha"
                            validate={[maxLength(50)]}
                            fullWidth
                            formClassName={classes.grid_cont4_s}
                        />
                    }
                    {ficha &&
                        <br />
                    }
                    {ficha &&
                        <BooleanInput
                            label="resources.elemento_alrededors.fields.estudioEstabilidad"
                            source="EstudioEstabilidad"
                            onChange={ValidarEstudio}
                            fullWidth
                            formClassName={classes.grid_cont12}
                        />
                    }
                    {estudio &&
                        <TextInput
                            label= "resources.elemento_alrededors.fields.consultor"
                            source="consultor"
                            validate={[maxLength(30)]}
                            fullWidth
                            formClassName={classes.grid_cont12}
                        />
                    }
                    {estudio &&
                        <DateInput
                            label="resources.elemento_alrededors.fields.fechaEstudio"
                            source="fechaEstudio"
                            fullWidth
                            formClassName={classes.grid_cont4_f}
                            validate={[validacionmaxfechaact, isFechaInvalida]}
                        />
                    }
                    {estudio &&
                        <TextInput
                            label="resources.elemento_alrededors.fields.codigoEstudio"
                            source="codigoEstudio"
                            validate={[maxLength(10)]}
                            fullWidth
                            formClassName={classes.grid_cont4_s}
                        /> 
                    }
                    {estudio &&
                        <TextInput
                            label="resources.elemento_alrededors.fields.nombreEstudio"
                            source="nombreEstudio"
                            validate={[maxLength(50)]}
                            fullWidth
                            formClassName={classes.grid_cont4_s}
                        />
                    }

                    {/* ------ Elemento =  Ocupaciones ------- */}
                    {elemento === "/tipo_elementos/OCPN" &&
                        <SelectInput label="resources.elemento_alrededors.fields.tipoServicioOCPN" source="tipoServicio" allowEmpty emptyValue="" choices={[
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoServicio.Cerca'), name: "dynatest.resources.elemento_alrededors.select.tipoServicio.Cerca" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoServicio.Edificacion'), name: "dynatest.resources.elemento_alrededors.select.tipoServicio.Edificacion" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoServicio.Outdoor'), name: "dynatest.resources.elemento_alrededors.select.tipoServicio.Outdoor" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoServicio.Placa'), name: "dynatest.resources.elemento_alrededors.select.tipoServicio.Placa" },

                        ]} fullWidth formClassName={classes.grid_cont6} />
                    }
                    {elemento === "/tipo_elementos/OCPN" &&
                        <SelectInput label="resources.elemento_alrededors.fields.identificacion" source="identificacion" allowEmpty emptyValue="" choices={[
                            { id: translate('dynatest.resources.elemento_alrededors.select.identificacion.Regular'), name: "dynatest.resources.elemento_alrededors.select.identificacion.Regular" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.identificacion.Irregular'), name: "dynatest.resources.elemento_alrededors.select.identificacion.Irregular" },
                        ]} fullWidth formClassName={classes.grid_cont6_s} />
                    }
                    {elemento === "/tipo_elementos/OCPN" &&
                        <TextInput
                            label="resources.elemento_alrededors.fields.propietario"
                            source="propietario"
                            validate={[maxLength(100)]}
                            fullWidth
                            formClassName={classes.grid_cont6_s}
                        />
                    }
                    {elemento === "/tipo_elementos/OCPN" &&
                        <TextInput
                            label="resources.elemento_alrededors.fields.emailPropietario"
                            source="emailPropietario"
                            validate={[maxLength(100)]}
                            fullWidth
                            formClassName={classes.grid_cont6_s}
                        />
                    }
                    {elemento === "/tipo_elementos/OCPN" &&
                        <NumberInput
                            label="resources.elemento_alrededors.fields.telefonoPropietario"
                            source="telefonoPropietario"
                            validate={[minValue(0), Entero]}
                            fullWidth
                            formClassName={classes.grid_cont4}
                        />
                    }

                    {/* ------ Elemento Accesos ------- */}
                    {elemento === "/tipo_elementos/ACCS" &&
                        <SelectInput label="resources.elemento_alrededors.fields.tipoServicioObs" source="tipoServicio" allowEmpty emptyValue="" choices={[
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoServicio.Municipal'), name: "dynatest.resources.elemento_alrededors.select.tipoServicio.Municipal" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.tipoServicio.Particular'), name: "dynatest.resources.elemento_alrededors.select.tipoServicio.Particular" }

                        ]} fullWidth formClassName={classes.grid_cont6} />
                    }
                    {elemento === "/tipo_elementos/ACCS" &&
                        <NumberInput
                            label="resources.elemento_alrededors.fields.longitudAcceso"
                            source="longitudAcceso"
                            validate={[minValue(0), validarUnDecimal]}
                            fullWidth
                            formClassName={classes.grid_cont6}
                        />
                    }
                    {elemento === "/tipo_elementos/ACCS" &&
                        <NumberInput
                            label="resources.elemento_alrededors.fields.anchoAcceso"
                            source="anchoAcceso"
                            validate={[minValue(0), validarUnDecimal]}
                            fullWidth
                            formClassName={classes.grid_cont6}
                        />
                    }
                    {elemento === "/tipo_elementos/ACCS" &&
                        <SelectInput label="resources.elemento_alrededors.fields.identificacion" source="identificacion" allowEmpty emptyValue="" choices={[
                            { id: translate('dynatest.resources.elemento_alrededors.select.identificacion.Regular'), name: "dynatest.resources.elemento_alrededors.select.identificacion.Regular" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.identificacion.Irregular'), name: "dynatest.resources.elemento_alrededors.select.identificacion.Irregular" },
                        ]} fullWidth formClassName={classes.grid_cont6_s} />
                    }
                    {elemento === "/tipo_elementos/ACCS" &&
                        <TextInput
                            label="resources.elemento_alrededors.fields.propietario"
                            source="propietario"
                            validate={[maxLength(100)]}
                            fullWidth
                            formClassName={classes.grid_cont6_s}
                        />
                    }
                    {elemento === "/tipo_elementos/ACCS" &&
                        <TextInput
                            label="resources.elemento_alrededors.fields.emailPropietario"
                            source="emailPropietario"
                            validate={[maxLength(100)]}
                            fullWidth
                            formClassName={classes.grid_cont6_s}
                        />
                    }
                    {elemento === "/tipo_elementos/ACCS" &&
                        <NumberInput
                            label="resources.elemento_alrededors.fields.telefonoPropietario"
                            source="telefonoPropietario"
                            validate={[minValue(0), Entero]}
                            fullWidth
                            formClassName={classes.grid_cont6_s}
                        />
                    }

                </FormTab>
                {/* ---------- DETALLES ------------- */}
                <FormTab label="dynatest.resources.elemento_alrededors.tabs.tabsTitle.detalles">
                    <Grid container spacing={24}>
      
                    {/* ------ Elemento = Márgenes, Separadores ------- */} 
                    {elemento === "/tipo_elementos/MARG" &&
                        <Grid item xs={4}>
                            <SelectInput label="resources.elemento_alrededors.fields.estado" source="estado" allowEmpty emptyValue=""   choices={[
                                    { id: translate('dynatest.resources.elemento_alrededors.select.estado.Existente'), name: "dynatest.resources.elemento_alrededors.select.estado.Existente" },
                                    { id: translate('dynatest.resources.elemento_alrededors.select.estado.Reemplazo'), name: "dynatest.resources.elemento_alrededors.select.estado.Reemplazo" },
                                    { id: translate('dynatest.resources.elemento_alrededors.select.estado.Nuevo'), name: "dynatest.resources.elemento_alrededors.select.estado.Nuevo" },
                                    
                                ]} validate={[required()]} fullWidth formClassName={classes.grid_cont3_f} onChange={ValidarEstado}/>

                        </Grid>
                    }  
                    {elemento === "/tipo_elementos/SEPD" &&
                        <Grid item xs={4}>
                            <SelectInput label="resources.elemento_alrededors.fields.estado" source="estado" allowEmpty emptyValue=""   choices={[
                                { id: translate('dynatest.resources.elemento_alrededors.select.estado.Existente'), name: "dynatest.resources.elemento_alrededors.select.estado.Existente" },
                                { id: translate('dynatest.resources.elemento_alrededors.select.estado.Nuevo'), name: "dynatest.resources.elemento_alrededors.select.estado.Nuevo" },
                                { id: translate('dynatest.resources.elemento_alrededors.select.estado.Reemplazo'), name: "dynatest.resources.elemento_alrededors.select.estado.Reemplazo" },
                                
                            ]} validate={[required()]}  fullWidth formClassName={classes.grid_cont4_f} onChange={ValidarEstado} />

                        </Grid>
                    } 
                    {elemento === "/tipo_elementos/ZINT" &&
                        <Grid item xs={4}>
                            <SelectInput label="resources.elemento_alrededors.fields.estado"  source="estado" allowEmpty emptyValue=""  choices={[
                                    { id: translate('dynatest.resources.elemento_alrededors.select.estado.Existente'), name: "dynatest.resources.elemento_alrededors.select.estado.Existente" },
                                    { id: translate('dynatest.resources.elemento_alrededors.select.estado.Reemplazo'), name: "dynatest.resources.elemento_alrededors.select.estado.Reemplazo" },
                                    { id: translate('dynatest.resources.elemento_alrededors.select.estado.Nuevo'), name: "dynatest.resources.elemento_alrededors.select.estado.Nuevo" },
                            ]} validate={[required()]} fullWidth formClassName={classes.grid_cont4_f} onChange={ValidarEstado}/>

                        </Grid>
                    } 
                    {elemento === "/tipo_elementos/OBST" &&
                        <br />
                    }


                    {estados === translate('dynatest.resources.elemento_alrededors.select.estado.Reemplazo') &&
                    <Grid item xs={4}>
                        <SelectInput label="resources.elemento_alrededors.fields.condicion" source="condicion" choices={[
                            { id: translate('dynatest.resources.elemento_alrededors.select.condicion.accidente'), name: 'dynatest.resources.elemento_alrededors.select.condicion.accidente' },
                            { id: translate('dynatest.resources.elemento_alrededors.select.condicion.instalacion'), name: 'dynatest.resources.elemento_alrededors.select.condicion.instalacion' },
                            { id: translate('dynatest.resources.elemento_alrededors.select.condicion.otro'), name: 'dynatest.resources.elemento_alrededors.select.condicion.otro' },
                            { id: translate('dynatest.resources.elemento_alrededors.select.condicion.reemplazoProgramado'), name: 'dynatest.resources.elemento_alrededors.select.condicion.reemplazoProgramado' },
                            { id: translate('dynatest.resources.elemento_alrededors.select.condicion.vandalismo'), name: 'dynatest.resources.elemento_alrededors.select.condicion.vandalismo' },
                        ]} validate={[required()]} fullWidth formClassName={classes.grid_cont4_s} />
                        </Grid>
                    }

                    {estados === translate('dynatest.resources.elemento_alrededors.select.estado.Reemplazo') &&
                    
                    <Grid item xs={4}>

                        <ReferenceInput
                            label="resources.elemento_alrededors.fields.elementoReemplazo"
                            source="elementoReemplazo"
                            reference="elemento_alrededors"
                            filter={{
                                estados: "Activo",
                                tipoElemento: elemento,
                                unidadFuncional: unidads,
                                tramo: tramos,
                                segmento: segmentos
                            }}
                            fullWidth
                            validate={[required()]}
                            formClassName={classes.grid_cont4_s}
                            >
                            <AutocompleteInput options={{ fullWidth: true }} optionText="fullCodigoConsecutivo" />
                        </ReferenceInput>
                    </Grid>
                    }
                    </Grid>
                    {estados != translate('dynatest.resources.elemento_alrededors.select.estado.Reemplazo') &&
                        <div fullWidth formClassName={classes.grid_cont7}></div>
                    }
                    {/* --------ZONA INESTABLE -----------*/}
                    {elemento === "/tipo_elementos/ZINT" &&
                        <DateInput label="resources.elemento_alrededors.fields.fechaConstruccion" source="fechaConstruccion" fullWidth formClassName={classes.grid_cont4_f} validate={[validacionmaxfechaact, isFechaInvalida]} />
                    }

                    {elemento === "/tipo_elementos/ZINT" &&
                        <ReferenceInput
                            label="resources.elemento_alrededors.fields.proveedor"
                            source="proveedor"
                            reference="proveedors"
                            filter={{ proyecto: props.proyecto, sistema: 'ALR' }}
                            validate={[required()]} fullWidth formClassName={classes.grid_cont4_s}
                            filterToQuery={searchText => ({ razonSocial: searchText })}
                        >
                            <AutocompleteInput options={{ fullWidth: true }} optionText="razonSocial" fullWidth />
                        </ReferenceInput>
                    }

                    {elemento === "/tipo_elementos/ZINT" &&
                        <NumberInput label="resources.elemento_alrededors.fields.garantia" source="garantia" fullWidth validate={[required(), minValue(0)]} formClassName={classes.grid_cont4_s} format={FormatoGarantia} />
                    }
                    <br /> 
                    
                    {elemento === "/tipo_elementos/OCPN" &&
                        <SelectInput label="resources.elemento_alrededors.fields.estados" source="estados" allowEmpty emptyValue="" choices={[
                            { id: translate('dynatest.resources.elemento_alrededors.select.estados.Activo'), name: "dynatest.resources.elemento_alrededors.select.estados.Activo" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.estados.Inactivo'), name: "dynatest.resources.elemento_alrededors.select.estados.Inactivo" },
                        ]} validate={[required()]} fullWidth formClassName={classes.grid_cont4_f} />
                    }
                    {elemento === "/tipo_elementos/ACCS" &&
                        <SelectInput label="resources.elemento_alrededors.fields.estados" source="estados" allowEmpty emptyValue="" choices={[
                            { id: translate('dynatest.resources.elemento_alrededors.select.estados.Activo'), name: "dynatest.resources.elemento_alrededors.select.estados.Activo" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.estados.Inactivo'), name: "dynatest.resources.elemento_alrededors.select.estados.Inactivo" },
                        ]} validate={[required()]} fullWidth formClassName={classes.grid_cont4_f} />
                    }
                    {elemento !== "/tipo_elementos/OCPN" && elemento !== "/tipo_elementos/ACCS" &&
                        <SelectInput label="resources.elemento_alrededors.fields.estados" source="estados" allowEmpty emptyValue="" choices={[
                            { id: translate('dynatest.resources.elemento_alrededors.select.estados.Activo'), name: "dynatest.resources.elemento_alrededors.select.estados.Activo" },
                            { id: translate('dynatest.resources.elemento_alrededors.select.estados.Inactivo'), name: "dynatest.resources.elemento_alrededors.select.estados.Inactivo" },
                        ]} validate={[required()]} fullWidth formClassName={classes.grid_cont4_f} />
                    }
                    <br />
                    <BooleanInput label="resources.elemento_alrededors.fields.mantenimiento" source="mantenimiento" fullWidth formClassName={classes.grid_cont12} />
                    <TextInput label="resources.elemento_alrededors.fields.observaciones" source="observaciones" validate={[required()]} fullWidth formClassName={classes.grid_cont12} />
                    <TextInput source="createdIn" style={{ display: 'none' }} defaultValue={"api"} />
                    <TextInput source="updatedIn" style={{ display: 'none' }} defaultValue={"api"} />
                    <TextInput source="aprobacion" style={{ display: 'none' }} defaultValue={"Aprobado"} />
                </FormTab>
                {

                }
                <FormTab label="dynatest.resources.elemento_alrededors.tabs.tabsTitle.fotografias">
                    <ImagenesInventario type='create' defaultTipoImagen={defaultTipoImagen} />
                </FormTab>
            </TabbedForm>
        </PimsCreate>
    )
}));