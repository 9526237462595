import React from 'react';

import { Filter, ReferenceInput, SelectInput } from 'react-admin';

import { connect } from 'react-redux';

const PimsElementoListFilter = (props) => (
    <Filter {...props}>        
        {/* <ReferenceInput label="Segmento" source="segmento" reference="segmentos" alwaysOn>
            <SelectInput optionText="fullCode" />
        </ReferenceInput> */}
        <ReferenceInput label="Tipo elemento" source="tipoElemento" reference="tipo_elementos" alwaysOn >
            <SelectInput optionText="nombre" />
        </ReferenceInput>
    </Filter>
);

const mapStateToProps = 
    state => ({
        proyecto: state.proyecto, 
        ruta: state.ruta,
        unidadFuncional: state.unidadFuncional,
        tramo: state.tramo
    });

export default connect(mapStateToProps, {})(PimsElementoListFilter);