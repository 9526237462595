import React from 'react';

import { TextInput,  SaveButton, Toolbar} from 'react-admin';

import { withStyles } from '@material-ui/core';
import { SimpleForm } from './../../../SimpleForm';
import { Edit } from './../../../Edit';
const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const styles = {
    first_field: { display: 'inline-block', width: '30%' },    
};

export const EstadoEdit = withStyles(styles)(({ classes, ...props }) => (
        <Edit title="Editar estado" {...props} undoable={false}>
            <SimpleForm submitOnEnter={false} redirect="list" >
            <TextInput source="nombre" label="Estado"/>                
            </SimpleForm>
        </Edit>
))