import React, { useState } from 'react';
import { List, Datagrid,Toolbar,SelectInput,BooleanInput, ReferenceInput, TextField,SimpleForm, EditButton, CardActions,RadioButtonGroupInput,ShowButton} from 'react-admin';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import config from '../../../../config/config';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { translate } from 'react-admin';
let urlAppjs = config.UrlServer;
class ViewCategoria extends React.Component{
    constructor(props){
        super(props)
        this.state = {mensualview: [],record: [],tipo: "",value: '',titulos:[],ano: [],anios: [] };
        this.leerMeses = this.leerMeses.bind(this);
        this.sumarMeses = this.sumarMeses.bind(this);
        // this.sumarMeses2 = this.sumarMeses2.bind(this);
        this.sumarCategoria= this.sumarCategoria.bind(this);
        this.sumarCategoria2= this.sumarCategoria2.bind(this);
    }
    componentDidMount(){
      this.setState({
        tipo: this.props.tipo,
        record: this.props.record,
      });
        // fetch(urlAppjs+"/mensualviews.json?inventio_peajes="+this.props.peaje+"&sentido="+this.props.sentido+"&ano="+this.props.ano)
        let sentido   = "";
        let valor2    = "";
        let idproyecto = localStorage.getItem("id_project2").replace("/proyectos/", "");
        if(this.props.tipo === "tipo"){
          sentido   = this.props.sentido;
          let valor = this.props.peaje;
          valor2 = valor.split("/inventariopeajes/"); 
        }
        else {
          sentido = this.props.record.sentido;
          let valor = this.props.record.inventario_peajes;
          valor2 = valor.split("/inventariopeajes/");
        }
      fetch(urlAppjs+"/tablacategory/"+this.props.ano1+"/"+this.props.ano2+"/"+idproyecto+"/"+valor2[1]+"/"+sentido)
      .then((response) => {
        return response.json()
    })
    .then((mensualview) => {
        this.setState({ mensualview: mensualview.resultado })
    })
        
}
  leerMeses (data, anio) {
    if (data.ano === anio) {
    const keysValidation = ['enero','febrero','marzo','abril','mayo','junio','julio','agosto','septiembre','octubre','noviembre','diciembre']
    return Object.keys(data).map((key, i) => {
      if(keysValidation.includes(key)) {
        return <TableCell key={i} align="right" fullWidth style={{textAlign:'right'}}>{data[key] ? data[key] : " "}</TableCell>
      }})
    }
  };

  sumarCategoria (data, anio) {
    if (data.ano === anio) {
    let sumaCategorias = 0;
    const keysValidation = ['enero','febrero','marzo','abril','mayo','junio','julio','agosto','septiembre','octubre','noviembre','diciembre']
    Object.keys(data).map((key, i) => {
      if(keysValidation.includes(key)) {
        sumaCategorias += data[key] ? parseInt(data[key]) : 0;
      }})
      return <TableCell key='total' align="right" fullWidth style={{textAlign:'right'}}>{sumaCategorias}</TableCell>
    }
  };
  sumarCategoria2 (data, anio) {
    if (data.ano === anio) {
    let sumaCategorias = 0;
    const keysValidation = ['enero','febrero','marzo','abril','mayo','junio','julio','agosto','septiembre','octubre','noviembre','diciembre']
    Object.keys(data).map((key, i) => {
      if(keysValidation.includes(key)) {
        sumaCategorias += data[key] ? parseInt(data[key]) : 0;
      }})
      return sumaCategorias
    }
  };

  sumarMeses (data, anio) {
    const keysValidation = ['enero','febrero','marzo','abril','mayo','junio','julio','agosto','septiembre','octubre','noviembre','diciembre'];
    let sumaMeses = [];
    // var dataAnio = JSON.parse( JSON.stringify( data ) );
    var dataAnio = data;
    let dataAnios = [];
    if(dataAnio){
       dataAnios = dataAnio.filter(registro => registro.ano === anio)
    }
    let sumaMes = 0;
    let aSumar = [];

    if(typeof dataAnios !== 'undefined'){
      dataAnios.map(registro => {
      let vec = [];
      Object.keys(registro).map((key, i) => {
        if(keysValidation.includes(key)) {
          vec.push(registro[key] ? parseInt(registro[key]) : 0);
        }
      });
      aSumar.push(vec);
    });
  }
    console.log(aSumar);
    let sumsArr = [];
    for (let i = 0; i < aSumar[0].length; i += 1) {
      sumsArr.push(aSumar.map((val) => val[i])
        .reduce((acc, curr) => (acc+curr), 0)
      );
    }
    let sumTotal = 0
    sumsArr.forEach(element => {
      sumTotal += element;
    });
    sumsArr.push(sumTotal);
    return sumsArr.map(result =>
    <TableCell key='total' align="right" fullWidth style={{textAlign:'right'}}>{result ? result : ""}</TableCell>
    )
  }
  // sumarMeses2 (data, anio) {
  //   const keysValidation = ['enero','febrero','marzo','abril','mayo','junio','julio','agosto','septiembre','octubre','noviembre','diciembre'];
  //   let sumaMeses = [];
  //   // var dataAnio = JSON.parse( JSON.stringify( data ) );
  //   var dataAnio = data;
  //   let dataAnios = [];
  //   if(dataAnio){
  //      dataAnios = dataAnio.filter(registro => registro.ano === anio)
  //   }
  //   let sumaMes = 0;
  //   let aSumar = [];

  //   if(typeof dataAnios !== 'undefined'){
  //     dataAnios.map(registro => {
  //     let vec = [];
  //     Object.keys(registro).map((key, i) => {
  //       if(keysValidation.includes(key)) {
  //         vec.push(registro[key] ? parseInt(registro[key]) : 0);
  //       }
  //     });
  //     aSumar.push(vec);
  //   });
  // }
  //   console.log(aSumar);
  //   let sumsArr = [];
  //   for (let i = 0; i < aSumar[0].length; i += 1) {
  //     sumsArr.push(aSumar.map((val) => val[i])
  //       .reduce((acc, curr) => (acc+curr), 0)
  //     );
  //   }
  //   let sumTotal = 0
  //   let resultado = [];
  //   sumsArr.forEach(element => {
  //     if(element != 0){
  //       resultado.push(element);
  //     }
  //   });
  
  //   console.log("sumTotal")
  //   console.log(resultado)
  //   return resultado;
  // }
render(){
  const { translate } = this.props;
    const {
      tipo,
      mensualview,
      record,
      titulos,
    } = this.state;
    const { categoriasSeleccionadas } = this.props;
    const dataFiltrada = mensualview.filter(report =>
      categoriasSeleccionadas.includes(report.categoria)
    )
    console.log(dataFiltrada);
    const keysValidation = ['enero','febrero','marzo','abril','mayo','junio','julio','agosto','septiembre','octubre','noviembre','diciembre']
    var hash = {};
    for(let i = 0; i < dataFiltrada.length; i++) {
      try {
        let suma = 0;
        let val2 = 0;
        if((dataFiltrada[i].ano === dataFiltrada[i+1].ano) && (dataFiltrada[i].categoria === dataFiltrada[i+1].categoria) ) {
          Object.keys(dataFiltrada[i]).map((key, j) => {
            if(keysValidation.includes(key)) {
              dataFiltrada[i][key] = dataFiltrada[i][key] ? parseInt(dataFiltrada[i][key], 10) : 0;
              dataFiltrada[i+1][key] = dataFiltrada[i+1][key] ? parseInt(dataFiltrada[i+1][key], 10) : 0;
              suma = dataFiltrada[i][key] + dataFiltrada[i+1][key]
              dataFiltrada[i][key] = suma;
          }})
          suma = 0;
          delete dataFiltrada[i+1];
        }
      }catch (err) {

      }
    }

     // console.log(record);
    Array.prototype.unique=function(a){
      return function(){return this.filter(a)}
    }(function(a,b,c){return c.indexOf(a,b+1)<0});

    let aniosRF = [];
    let categoriaRF = [];
    dataFiltrada.map((reporte) => {
      aniosRF.push(reporte.ano)
      categoriaRF.push(reporte.categoria)
    })
    aniosRF = aniosRF.unique();
    categoriaRF = categoriaRF.unique();


    
    let transito=[]
    categoriaRF.map((cate) => { 
      let datos=[]
      aniosRF.map((anio) => {
        dataFiltrada.map((data, i) => {
          if(anio === data.ano && cate === data.categoria){ 
            datos.push(this.sumarCategoria2(data, anio))
          }
        })
      })

      switch(cate) {
        case 'categoria_a':
          cate = 'Categoria I';
          break;
        case 'categoria_b':
          cate = 'Categoria II';
          break;
        case 'categoria_c':
          cate = 'Categoria III';
          break;
        case 'categoria_d':
          cate = 'Categoria IV';
          break;
        case 'categoria_e':
          cate = 'Categoria V';
          break;
      }
      let opcion = {  
        name: cate,
        data: datos
      }
      transito.push(opcion)
    })

    console.log(transito)
  
      const options = {
        title: {
          text: translate('dynatest.resources.mensuals.graph.title')
      },
    
      subtitle: {
          text: translate('dynatest.resources.mensuals.graph.subTitle')
      },
    
      yAxis: {
          title: {
              text: translate('dynatest.resources.mensuals.graph.Valores')
          }
      },
      xAxis:{
        categories:aniosRF
      },
      series:transito
        
    }
if(tipo==='mostrar'){
  return(
    <React.Fragment>
      <Table aria-label="simple table" fullWidth  style={{width: "100%"}}>
        <TableHead>
          <TableRow style={{color:'#fff'}} >
            <TableCell></TableCell>
            <TableCell align="right" component="th" >{translate('dynatest.resources.mensuals.select.mes.Enero')}  </TableCell>
            <TableCell align="right" component="th" >{translate('dynatest.resources.mensuals.select.mes.Febrero')} </TableCell>
            <TableCell align="right" component="th" >{translate('dynatest.resources.mensuals.select.mes.Marzo')}  </TableCell>
            <TableCell align="right" component="th" >{translate('dynatest.resources.mensuals.select.mes.Abril')}  </TableCell>
            <TableCell align="right" component="th" >{translate('dynatest.resources.mensuals.select.mes.Mayo')}  </TableCell>
            <TableCell align="right" component="th" >{translate('dynatest.resources.mensuals.select.mes.Junio')}  </TableCell>
            <TableCell align="right" component="th" >{translate('dynatest.resources.mensuals.select.mes.Julio')}  </TableCell>
            <TableCell align="right" component="th" >{translate('dynatest.resources.mensuals.select.mes.Agosto')}  </TableCell>
            <TableCell align="right" component="th" >{translate('dynatest.resources.mensuals.select.mes.Septiembre')} </TableCell>
            <TableCell align="right" component="th" >{translate('dynatest.resources.mensuals.select.mes.Octubre')} </TableCell>
            <TableCell align="right" component="th" >{translate('dynatest.resources.mensuals.select.mes.Noviembre')} </TableCell>
            <TableCell align="right" component="th" >{translate('dynatest.resources.mensuals.select.mes.Diciembre')}  </TableCell>
            <TableCell align="right" component="th" >{translate('dynatest.resources.mensuals.subTitle.total')} </TableCell>
            <br></br>
          </TableRow>
        </TableHead>
        <TableBody>
          {aniosRF.map(anio => (
            <>
              <TableRow>
                <TableCell key={anio} align="right" component="th" fullWidth>
                  <h3 style={{marginBottom: 0}}>{anio}</h3><br/>    
                </TableCell>
              </TableRow>
              {dataFiltrada.map((data, i) => (
                <>
                  {anio === data.ano ?
                    (<TableRow >   
                      <TableCell align="right" component="th" fullWidth>
                        <strong style={{marginBottom: 0}}>{data.nombreCategoria}</strong><br/>    
                      </TableCell>
                      {this.leerMeses(data, anio)}
                      {this.sumarCategoria(data, anio)}
                    </TableRow>) : null
                  }
                </>
              ))}
              <TableRow>
                <TableCell key={anio} align="right" component="th" fullWidth>
                  <h3 style={{marginBottom: 0}}>{translate('dynatest.resources.mensuals.subTitle.total')}</h3><br/>    
                </TableCell>
                {this.sumarMeses(dataFiltrada, anio)}
              </TableRow>
            </>
          ))}
        </TableBody>
      </Table>
      <br/>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </React.Fragment>
  )
}else{
  return(
    <React.Fragment>
    </React.Fragment>
  )
}
}
}

export default translate(ViewCategoria);