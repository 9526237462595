import React, { Component, Fragment } from 'react';
import { FileInput, TextInput, TextField, FormDataConsumer, required } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import config from '../../../config/config';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import loader from '../../../loader10.gif';
import Grid from '@material-ui/core/Grid';
import { translate } from 'react-admin';
import extractGoogleCoords from '../../Mapa/localhelpers/extractGoogleCoords';
let urlAppjs = config.UrlServer;
let descRespuesta = "";

class PesoTotal extends Component {
  constructor(props) {
    super(props)
    // console.log(props);
    // console.log("******************")
    this.state = { loaded: false, respuestas: "", tipoCampo: "" }
  }
  componentDidMount() {
  }

  render() {
    const { translate } = this.props;
    let indicador = [];
    let umbralFallo= [];
    this.props.record.respuestasPreguntasComplementarios.forEach(element => {
      // let idIndicador = element.pregunta_.indicadorComplementario.replace("/indicador_complementarios/", "");
      indicador.push(element.pregunta_.indicadorComplementario);
    });
      let resultadoIndicador = indicador.filter((item,index)=>{
        return indicador.indexOf(item) === index;
    });
    return(
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
              <TableCell>{translate('resources.respuesta_evaluacion_complementarios.fields.indicadores')}</TableCell>
              <TableCell align="right">{translate('resources.respuesta_evaluacion_complementarios.fields.uFallo')}</TableCell>
              <TableCell align="right">{translate('resources.respuesta_evaluacion_complementarios.fields.pesoTotal')}</TableCell>
              <TableCell align="right">{translate('resources.respuesta_evaluacion_complementarios.fields.estadoVal')}</TableCell>    
          </TableRow>
        </TableHead>
        <TableBody>{                     
          resultadoIndicador.map((item, index) => {
            let umbral ;
            let nombre;
            let suma;
            let peso = 0; 
            let respuestaSelMul = 0;
            let resultado = 0;
            let mensaje = '';
            this.props.record.respuestasPreguntasComplementarios.forEach(indicadores => {
              if(indicadores.pregunta_.indicadorComplementario == item){
                nombre = indicadores.pregunta_.indicadorComplementario_.nombre;
                umbral = indicadores.pregunta_.indicadorComplementario_.umbralFallo;
      
                if (indicadores.pregunta_.tipoCampo === "Selección Múltiple") {
                  indicadores.pregunta_.respuestaIndicadorComplementario.forEach(element => {
                      if (element.originId == indicadores.respuesta)
                          respuestaSelMul += element.peso
                  });
                //  console.log(respuestaSelMul+" sel")
                } else {              
                  if( (indicadores.respuesta >= indicadores.pregunta_.rangoInicial) && (indicadores.respuesta <= indicadores.pregunta_.rangoFinal) ) {
                    peso += indicadores.pregunta_.peso;
                  }else{
                    peso += 0;
                  }     
                    // console.log(peso+ " num")
                }
                resultado = respuestaSelMul + peso;
                if(resultado >= umbral){
                  mensaje = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple');
                }else{
                  mensaje = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple');;
                }
              }
            });

            return(
              <TableRow>
                <TableCell style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>
                  <p style={{ fontSize: 0.875 + 'rem', textAlign: 'left', fontWeight: 400, fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }}>{nombre}</p>
                </TableCell>
                <TableCell style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>
                  <p style={{ fontSize: 0.875 + 'rem', textAlign: 'right', fontWeight: 400, fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }}>{umbral}</p>
                </TableCell>
                <TableCell style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}> 
                  <p style={{ fontSize: 0.875 + 'rem', textAlign: 'right', fontWeight: 400, fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }}>{resultado}</p>
                </TableCell>
                <TableCell style={{borderRight: '1px solid rgba(224, 224, 224, 1)'}}>
                  <p style={{ fontSize: 0.875 + 'rem', textAlign: 'left', fontWeight: 400, fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }}>{mensaje}</p>
                </TableCell>
              </TableRow>
            )  
          }) 



        }
      </TableBody>
    </Table>

    )
  }
}

export default translate(PesoTotal);