import React, { Fragment, useState } from 'react';

import {
    List, Datagrid, TextField, EditButton,BooleanField, ShowButton, ReferenceField, Filter,
    ReferenceInput, SelectInput, NumberField, BulkDeleteWithConfirmButton, AutocompleteInput, Loading
} from 'react-admin';
import { CardActions, CreateButton, ExportButton } from 'react-admin';
import { PRField } from './../../../fields/PRField';
import { translate } from 'react-admin';

const FiltroDreanje = translate((props) => {
    const { translate } = props;
    const [uf, setUf] = useState('none');
    const ufSet = (value) => {
        setUf(value);
    }
    const [tramo, setTramo] = useState('none');
    const tramoSet = (value) => {
        setTramo(value);
    }

    return (
        <Filter {...props}>
            <ReferenceInput
                id="unidadFuncional"
                label="resources.inventariopeajes.fields.unidad_funcional"
                source="unidad_funcional"
                reference="unidad_funcionals"
                filterToQuery={searchText => ({ codigo: searchText })}
                sort={{ field: 'codigo', order: 'asc' }}
                validate={[ufSet]}
                alwaysOn
                fullWidth
            >
                <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
            </ReferenceInput>
            <ReferenceInput
                label="resources.inventariopeajes.fields.tramo"
                source="tramo"
                alwaysOn
                reference="tramos"
                filter={{ unidadFuncional: uf }}
                validate={[tramoSet]}
                filterToQuery={searchText => ({ codigo: searchText })}
                sort={{
                    field: 'codigo',
                    order: 'ASC',

                }}>
                <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
            </ReferenceInput>
            <SelectInput
                fullWidth
                label="resources.inventariopeajes.fields.estado"
                source="estado"
                sort={{ field: 'name', order: 'ASC' }}
                alwaysOn
                choices={[
                    { id: '1', name: 'dynatest.resources.inventariopeajes.select.estado.Activo' },
                    { id: '0', name: 'dynatest.resources.inventariopeajes.select.estado.Inactivo' },
                ]}
                resettable
            />


</Filter>


    )
});
const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);
const ListAction = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
        <ExportButton basePath={basePath}/>
    </CardActions>
);
const ListAction2 = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
    </CardActions>
);
export const PeajesinventarioList = translate(({ permissions, ...props }) => {
    const { translate } = props;
    if (!permissions) return <Loading />;  
    return (
        <List title="dynatest.resources.inventariopeajes.title.listTitle" 
            {...props}  
            filters={<FiltroDreanje />} 
            actions={permissions['inventario']  == 1 ? <ListAction /> : permissions['inventario']  == 2 ? <ListAction2 />: null}
            bulkActionButtons={permissions['inventario']  == 1 ? <PostBulkActionButtons />: null} 
            sort={{ field: 'id', order: 'DESC' }}>
            <Datagrid >
                <TextField label="resources.inventariopeajes.fields.nombre" source="nombre" />
                <TextField label="resources.inventariopeajes.fields.codigo" source="codigo"/>
                <ReferenceField label="resources.inventariopeajes.fields.unidad_funcional" source="unidad_funcional" reference="unidad_funcionals" linkType={false}>                
                        <TextField source="fullCodigos" />                
                </ReferenceField>
                <PRField label="resources.inventariopeajes.fields.absciaShow" source="abInicio"/>
                <NumberField label="resources.inventariopeajes.fields.estacion_manual_show" source="estacion_manual"/>
                <NumberField label="resources.inventariopeajes.fields.estacion_automatica" source="estacion_automatica"/>
                <NumberField label="resources.inventariopeajes.fields.estacion" source="estacion"/>
                <BooleanField label="resources.inventariopeajes.fields.estado" source="estado"/>
                {permissions['inventario'] == 1 || permissions['inventario'] == 2 || permissions['inventario'] == 4 ? <ShowButton/>: null}
                {permissions['inventario'] == 1 || permissions['inventario'] == 2 ? <EditButton/>: null}      
            </Datagrid>
        </List>
)});