import React from 'react';
import Typography from '@material-ui/core/Typography';
import {
    REDUX_FORM_NAME, DateInput, BooleanInput, required, TabbedForm, FormTab, AutocompleteInput, SimpleFormIterator,
    ArrayInput, NumberInput, SelectInput, ReferenceInput, TextInput, FormDataConsumer, List, CheckboxGroupInput
} from 'react-admin';
import NumberFormat from 'react-number-format';
import { change } from 'redux-form';
import PropTypes from 'prop-types';
import { minValue, maxValue, minLength, maxLength } from 'ra-core';
import { PRInput } from '../../../inputs/PRInput';
import { DInput } from '../../../inputs/DInput';
import { DEInput } from '../../../inputs/DEInput';
import { AInput } from '../../../inputs/AInput';
import { styles } from '../../../EstilosGrid';
import { withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { PField } from '../../../fields/PField';
import { PRField } from '../../../fields/PRField';
import {
    abValidationInicio, abValidationFin, validarDosDecimales, validarUnDecimal, validarAB, isFechaInvalida,
    Altitud, latMininima, lngMininima
} from '../../../../src/resources/validacionesInputs';
import Grid from '@material-ui/core/Grid';

import config from '../../../config/config';
import { translate } from 'react-admin';
let urlAppjs = config.UrlServer;

class GenerarSegmentos extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            titulos: [],
            record: [],
            tipo: "",
            datos: {
                departamentos: [],
                municipios: [],
                tipo_calzadas: [],
                tipo_segmentos: [],
                tipo_elementos: []
            },
            default: {
                departamentos: "",
                municipios: "",
            },
            checkeados: {},
            departamentos: {},
            defaultCheck: false
        }

        this.departamentoDefault = this.props.departamentoDefault
        this.fetcher.bind(this);
    }

    componentDidMount() {
        //this.setState({ datoEva: this.props.record })   
        this.setState({ record: this.props.record })
        this.setState({ tipo: this.props.tipo })
        this.fetcher("departamentos", "order[nombre]=ASC");
        this.fetcher("municipios", "order[nombre]=ASC");
        this.fetcher("tipo_calzadas", "order[nombre]=ASC");
        this.fetcher("tipo_segmentos", "order[nombre]=ASC");
        this.fetcher("tipo_elementos", [
            "order[nombre]=ASC",
            'sistema=CLZ'
        ]);
        // console.log(this.props.tipoElementos) 
    }

    componentDidUpdate() {

    }

    fetcher(target, otherFilters = null, i = 0) {

        var urlPart = "/" + target + "?pagination=off";

        if (Array.isArray(otherFilters)) {
            otherFilters.map(filter => {
                urlPart = urlPart + "&" + filter;
            })
        } else if (otherFilters !== null) {
            urlPart = urlPart + "&" + otherFilters;
        }


        fetch(urlAppjs + urlPart,
            {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                return response.json()
            })
            .then((Elementos) => {
                var datos = [];
                console.log("Elementos")
                console.log(Elementos)
                if (target == "municipios") {
                    Elementos["hydra:member"].map(item => {
                        datos.push({
                            id: item["@id"],
                            name: item.nombre,
                            departamento: item.departamento["@id"]
                        })
                    })
                } else {
                    Elementos["hydra:member"].map(item => {
                        datos.push({
                            id: item["@id"],
                            name: item.nombre
                        })
                    })
                }
                /*if (target == "municipios") {
                    this.state.datos[target][i] = datos;
                    console.log(this.state.datos[target])
                    change(REDUX_FORM_NAME, "municipio_" + i, null)
                } else {*/
                this.state.datos[target] = datos;
                //}

            }).then(() => {
                this.forceUpdate();
            })
    }

    render() {
        const { translate } = this.props;
        const { titulos } = this.state;
        const { record } = this.state;
        const { tipo } = this.state;
        const { classes } = this.props;
        const { input } = this.props;

        const selected = (value) => {
            if (value) {
                console.log("selected = " + value)
            }
        }

        const changedDepartament = (value, i, defaultValue = '') => {
            let valor = '';
            if (defaultValue != '') {
                valor = defaultValue
            } else {
                let lenghtObject = Object.keys(value);

                for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
            }
            this.fetcher("municipios", [
                "order[nombre]=ASC",
                "departamento=" + valor
            ], i)
        }

        const changedMunicipio = (value) => {
            let lenghtObject = Object.keys(value);
            let valor = '';
            for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
            this.state.default.municipios = valor;
        }

        let longTotal = this.props.longTotal
        console.log("longTotal = " + longTotal + " ;; this.props.longInicial = " + this.props.longInicial)
        let cantSegmentos = (longTotal) / 1000
        let titulos2 = []
        let z = 0
        let longSegmento = 0
        let arrLongSeg = []
        let longInicioAnt = 0
        let longitudFinalAnt = 0
        if (cantSegmentos > 0 && cantSegmentos < 1) {
            cantSegmentos = 1
        }

        if ((cantSegmentos === +cantSegmentos && cantSegmentos !== (cantSegmentos|0)) == true) {
            cantSegmentos = Math.ceil(cantSegmentos);
        }

        let multiplo = this.props.longInicial % 1000;

        if(this.props.displayGenSegmento == true){
        for (let i = 1; i <= cantSegmentos; i++) {
            console.log(i + " = " + longTotal)
            if (longTotal > 0) {
                if ((longTotal - 2000) >= 0) {
                    if (this.props.longInicial > 0 && i == 1) {
                        if (multiplo == 0) {
                            longSegmento = 1000
                        } else {
                            // longSegmento = (this.props.longInicial - 1000)
                            longSegmento = (multiplo - 1000)
                            if (longSegmento < 0) {
                                longSegmento = longSegmento * -1
                            }
                        }
                        longTotal = longTotal - longSegmento
                        longInicioAnt = this.props.longInicial
                        longitudFinalAnt = parseInt(this.props.longInicial) + parseInt(longSegmento)
                        arrLongSeg = { "longSegmento": longSegmento, "inicio": this.props.longInicial, "fin": longitudFinalAnt }
                        console.log("1 - arrLongSeg")
                        console.log(arrLongSeg)
                    } else {
                        longSegmento = 1000
                        longTotal = longTotal - longSegmento
                        longInicioAnt = longitudFinalAnt
                        longitudFinalAnt = parseInt(longitudFinalAnt) + longSegmento
                        arrLongSeg = { "longSegmento": longSegmento, "inicio": longInicioAnt, "fin": longitudFinalAnt }
                        console.log("2 - arrLongSeg")
                        console.log(arrLongSeg)
                    }
                } else {
                    if (i == 1) {
                        longSegmento = longitudFinalAnt - longTotal
                        if (longSegmento < 0) {
                            longSegmento = longSegmento * -1
                        }
                        arrLongSeg = { "longSegmento": longSegmento, "inicio": this.props.longInicial, "fin": parseInt(this.props.longInicial) + parseInt(longTotal) }
                        longTotal = longTotal - longSegmento
                        console.log("3 - arrLongSeg")
                        console.log(arrLongSeg)
                        longInicioAnt = this.props.longInicial
                        longitudFinalAnt = this.props.longFinal
                    } else {
                        longInicioAnt = longitudFinalAnt
                        longitudFinalAnt = longTotal

                        longSegmento = longTotal
                        if (longSegmento < 0) {
                            longSegmento = longSegmento * -1
                        }
                        arrLongSeg = { "longSegmento": longSegmento, "inicio": longInicioAnt, "fin": this.props.longFinal }
                        longTotal = longTotal - longSegmento
                        console.log("4 - arrLongSeg")
                        console.log(arrLongSeg)
                    }
                }

                titulos2[z] = { "pos": i, "longSegmento": arrLongSeg, "dataDefault": {} }
                z = z + 1
            }
        }

        for (let i = 0; i < titulos2.length; i++) {
            if (this.state.defaultCheck == true) {
                if (this.state.checkeados[i] == true) {
                    titulos2[i].dataDefault = this.props.dataDefecto
                    if (titulos2[i].dataDefault.departamento) {
                        if (this.state.datos.municipios[i] == null || this.state.departamentos[i] != titulos2[i].dataDefault.departamento) {
                            this.state.departamentos[i] = titulos2[i].dataDefault.departamento;
                            //changedDepartament("", i, titulos2[i].dataDefault.departamento)
                        }
                    }
                } else {
                    titulos2[i].dataDefault = {}
                }
            } else {
                titulos2[i].dataDefault = this.props.dataDefecto
                if (titulos2[i].dataDefault.departamento) {
                    if (this.state.datos.municipios[i] == null || this.state.departamentos[i] != titulos2[i].dataDefault.departamento) {
                        this.state.departamentos[i] = titulos2[i].dataDefault.departamento;
                        //changedDepartament("", i, titulos2[i].dataDefault.departamento)
                    }
                }
            }
        }
        }


        const handleChange = (i, checked) => {
            if (document.getElementById('check_' + i) != null) {
                if (document.getElementById('check_' + i).checked) {
                    this.state.checkeados[i] = true;
                    this.state.defaultCheck = true;

                } else {
                    this.state.checkeados[i] = false;
                    let valida_check = false
                    for (let j = 0; j < titulos2.length; j++) {
                        if (document.getElementById('check_' + j)) {
                            if (document.getElementById('check_' + j).checked == true) {
                                valida_check = true;
                            }
                        }
                    }
                    this.state.defaultCheck = valida_check;
                }
            }
        }
        const allChecked = (source) => {
            if (document.getElementById('all_check') != null) {
                let checkboxes = document.getElementsByName('check');
                for(var i=0, n=checkboxes.length;i<n;i++) {
                    checkboxes[i].checked = document.getElementById('all_check').checked;
                }
            }
            console.log(source)
        }
        return (
                <div style={{overflowX: 'auto', width: "80vw"}}>
                        <FormDataConsumer style={{ width: "100%" }}>
                            {({ formData, dispatch, ...rest }) => {

                                return (
                                    <Table style={(this.props.displayGenSegmento == true ? { width: '100%' } : { display: "none" })} >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ width: '3%' }} component="th" align="right">
                                                    <input type="checkbox" source={`all_check`} id={`all_check`} name={`all_check`} optionText="name" onChange={allChecked} />
                                                </TableCell>
                                                <TableCell style={{ width: '5%' }} component="th" align="right">{translate('dynatest.resources.generacion_masiva_segmentos.table.nameColumns.absInicio')}</TableCell>
                                                <TableCell style={{ width: '5%' }} component="th" align="right">{translate('dynatest.resources.generacion_masiva_segmentos.table.nameColumns.absFinal')}</TableCell>
                                                <TableCell style={{ width: '4%' }} component="th" align="right">{translate('dynatest.resources.generacion_masiva_segmentos.table.nameColumns.longitud')}</TableCell>
                                                <TableCell style={{ width: '5%' }} component="th" align="right">{translate('dynatest.resources.generacion_masiva_segmentos.table.nameColumns.prInicio')}</TableCell>
                                                <TableCell style={{ width: '5%' }} component="th" align="right">{translate('dynatest.resources.generacion_masiva_segmentos.table.nameColumns.prFinal')}</TableCell>
                                                <TableCell style={{ width: '10%' }} component="th" align="right">{translate('dynatest.resources.generacion_masiva_segmentos.table.nameColumns.departamento')}</TableCell>
                                                <TableCell style={{ width: '10%' }} component="th" align="right">{translate('dynatest.resources.generacion_masiva_segmentos.table.nameColumns.municipio')}</TableCell>
                                                <TableCell style={{ width: '5%' }} component="th" align="right">{translate('dynatest.resources.generacion_masiva_segmentos.table.nameColumns.latitudInicial')}</TableCell>
                                                <TableCell style={{ width: '5%' }} component="th" align="right">{translate('dynatest.resources.generacion_masiva_segmentos.table.nameColumns.longitudInicial')}</TableCell>
                                                <TableCell style={{ width: '5%' }} component="th" align="right">{translate('dynatest.resources.generacion_masiva_segmentos.table.nameColumns.alturaInicial')}</TableCell>
                                                <TableCell style={{ width: '5%' }} component="th" align="right">{translate('dynatest.resources.generacion_masiva_segmentos.table.nameColumns.latitudFinal')}</TableCell>
                                                <TableCell style={{ width: '5%' }} component="th" align="right">{translate('dynatest.resources.generacion_masiva_segmentos.table.nameColumns.longitudFinal')}</TableCell>
                                                <TableCell style={{ width: '5%' }} component="th" align="right">{translate('dynatest.resources.generacion_masiva_segmentos.table.nameColumns.alturaFinal')}</TableCell>
                                                <TableCell style={{ width: '5%' }} component="th" align="right">{translate('dynatest.resources.generacion_masiva_segmentos.table.nameColumns.tipoCalzada')}</TableCell>
                                                <TableCell style={{ width: '5%' }} component="th" align="right">{translate('dynatest.resources.generacion_masiva_segmentos.table.nameColumns.tipoSegmento')}</TableCell>
                                                <TableCell style={{ width: '5%' }} component="th" align="right">{translate('dynatest.resources.generacion_masiva_segmentos.table.nameColumns.tipoSuperficie')}</TableCell>
                                                <TextInput defaultValue={titulos2.length} style={{ display: 'none' }} source={`cant_segmentos`} />
                                            </TableRow>
                                        </TableHead>

                                        {
                                            titulos2.map((data, i) => (
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell padding="none">
                                                            <input type="checkbox" label=" " source={`check_${i}`} id={`check_${i}`} name={`check`} optionText="name" onChange={handleChange(i)} />
                                                        </TableCell>
                                                        <TextInput defaultValue={this.props.uf} id={`unidadFuncional_${i}`} style={{ display: 'none' }} source={`unidadFuncional_${i}`} fullWidth />
                                                        <TextInput defaultValue={this.props.uf} id={`unidadFuncional_${i}`} style={{ display: 'none' }} source={`unidadFuncional`} fullWidth />
                                                        <TextInput defaultValue={this.props.tramo} id={`tramo_${i}`} style={{ display: 'none' }} source={`tramo_${i}`} fullWidth />
                                                        <TableCell align="right">
                                                            <NumberFormat
                                                                prefix={data.longSegmento.inicio < 1000 ? "K0+" : "K"}
                                                                thousandSeparator='+'
                                                                decimalScale={0}
                                                                fixedDecimalScale={false}
                                                                allowNegative={false}
                                                                value={data.longSegmento.inicio}
                                                                displayType={'text'}
                                                            />
                                                            <TextInput defaultValue={data.longSegmento.inicio} id={`abInicio_${i}`} style={{ display: 'none' }} source={`abInicio_${i}`} fullWidth />
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <NumberFormat
                                                                prefix={data.longSegmento.fin < 1000 ? "K0+" : "K"}
                                                                thousandSeparator='+'
                                                                decimalScale={0}
                                                                fixedDecimalScale={false}
                                                                allowNegative={false}
                                                                value={data.longSegmento.fin}
                                                                displayType={'text'}
                                                            />
                                                            <TextInput defaultValue={data.longSegmento.fin} id={`abFin_${i}`} style={{ display: 'none' }} source={`abFin_${i}`} fullWidth />
                                                        </TableCell>
                                                        <TableCell align="right">{data.longSegmento.longSegmento}{/*`longitud_${i}`*/}</TableCell>
                                                        <TableCell align="right">
                                                            <NumberFormat
                                                                prefix={data.longSegmento.inicio < 1000 ? "PR0+" : "PR"}
                                                                thousandSeparator='+'
                                                                decimalScale={0}
                                                                fixedDecimalScale={false}
                                                                allowNegative={false}
                                                                value={data.longSegmento.inicio}
                                                                displayType={'text'}
                                                            />
                                                            <TextInput defaultValue={data.longSegmento.inicio} id={`pInicio_${i}`} style={{ display: 'none' }} source={`pInicio_${i}`} fullWidth />
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <NumberFormat
                                                                prefix={data.longSegmento.fin < 1000 ? "PR0+" : "PR"}
                                                                thousandSeparator='+'
                                                                decimalScale={0}
                                                                fixedDecimalScale={false}
                                                                allowNegative={false}
                                                                value={data.longSegmento.fin}
                                                                displayType={'text'}
                                                            />
                                                            <TextInput defaultValue={data.longSegmento.fin} id={`pFin_${i}`} style={{ display: 'none' }} source={`pFin_${i}`} fullWidth />
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {(data.dataDefault.departamento) ?
                                                                <SelectInput
                                                                    label={translate('dynatest.resources.generacion_masiva_segmentos.table.nameRows.departamento') + ` ${i}`}
                                                                    source={`departamento_${i}`}
                                                                    options={{ fullWidth: true }}
                                                                    defaultValue={data.dataDefault.departamento}
                                                                    choices={this.state.datos.departamentos}
                                                                    onChange={value => {
                                                                        dispatch(change(REDUX_FORM_NAME, "municipio_" + i, null))
                                                                        //changedDepartament(value, i)
                                                                    }}
                                                                    validate={required()} />
                                                                :
                                                                <SelectInput
                                                                    label={translate('dynatest.resources.generacion_masiva_segmentos.table.nameRows.departamento') + ` ${i}`}
                                                                    source={`departamento_${i}`}
                                                                    options={{ fullWidth: true }}
                                                                    choices={this.state.datos.departamentos}
                                                                    onChange={value => {
                                                                        dispatch(change(REDUX_FORM_NAME, "municipio_" + i, null))
                                                                        //changedDepartament(value, i)
                                                                    }}
                                                                    validate={required()} />
                                                            }
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {(data.dataDefault.municipio) ?
                                                                <SelectInput
                                                                    label={translate('dynatest.resources.generacion_masiva_segmentos.table.nameRows.municipio') + ` ${i}`}
                                                                    source={`municipio_${i}`}
                                                                    defaultValue={data.dataDefault.municipio}
                                                                    options={{ fullWidth: true }}
                                                                    choices={this.state.datos.municipios.filter(item => item.departamento == formData["departamento_" + i])}
                                                                    onChange={changedMunicipio}
                                                                    validate={required()}
                                                                />
                                                                :
                                                                <SelectInput
                                                                    label={translate('dynatest.resources.generacion_masiva_segmentos.table.nameRows.municipio') + ` ${i}`}
                                                                    source={`municipio_${i}`}
                                                                    options={{ fullWidth: true }}
                                                                    choices={this.state.datos.municipios.filter(item => item.departamento == formData["departamento_" + i])}
                                                                    onChange={changedMunicipio}
                                                                    validate={required()}
                                                                />
                                                            }
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {(data.dataDefault.ini_latitud) ?
                                                                <NumberInput defaultValue={data.dataDefault.ini_latitud} source={`latitudInicial_${i}`} id={`latitudInicial_${i}`} label="dynatest.resources.generacion_masiva_segmentos.table.nameRows.latitudInicial" fullWidth validate={[required(), latMininima]} />
                                                                :
                                                                <NumberInput source={`latitudInicial_${i}`} id={`latitudInicial_${i}`} label="dynatest.resources.generacion_masiva_segmentos.table.nameRows.latitudInicial" fullWidth validate={[required(), latMininima]} />
                                                            }
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {(data.dataDefault.ini_longitud) ?
                                                                <NumberInput defaultValue={data.dataDefault.ini_longitud} source={`longitudInicial_${i}`} id={`longitudInicial_${i}`} label="dynatest.resources.generacion_masiva_segmentos.table.nameRows.longitudInicial" fullWidth validate={[required(), lngMininima]} />
                                                                :
                                                                <NumberInput source={`longitudInicial_${i}`} id={`longitudInicial_${i}`} label="dynatest.resources.generacion_masiva_segmentos.table.nameRows.longitudInicial" fullWidth validate={[required(), lngMininima]} />
                                                            }
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {(data.dataDefault.ini_longitud) ?
                                                                <NumberInput defaultValue={data.dataDefault.ini_altura} source={`alturaInicial_${i}`} id={`alturaInicial_${i}`} label="dynatest.resources.generacion_masiva_segmentos.table.nameRows.alturaInicial" validate={[required(), Altitud]} fullWidth />
                                                                :
                                                                <NumberInput source={`alturaInicial_${i}`} id={`alturaInicial_${i}`} label="dynatest.resources.generacion_masiva_segmentos.table.nameRows.alturaInicial" validate={[Altitud]} fullWidth />
                                                            }
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {(data.dataDefault.fin_latitud) ?
                                                                <NumberInput defaultValue={data.dataDefault.fin_latitud} source={`latitudFinal_${i}`} id={`latitudFinal_${i}`} label="dynatest.resources.generacion_masiva_segmentos.table.nameRows.latitudFinal" fullWidth validate={[required(), latMininima]} />
                                                                :
                                                                <NumberInput source={`latitudFinal_${i}`} id={`latitudFinal_${i}`} label="dynatest.resources.generacion_masiva_segmentos.table.nameRows.latitudFinal" fullWidth validate={[required(), latMininima]} />
                                                            }
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {(data.dataDefault.fin_longitud) ?
                                                                <NumberInput defaultValue={data.dataDefault.fin_longitud} source={`longitudFinal_${i}`} id={`longitudFinal_${i}`} label="dynatest.resources.generacion_masiva_segmentos.table.nameRows.longitudFinal" fullWidth validate={[required(), lngMininima]} />
                                                                :
                                                                <NumberInput source={`longitudFinal_${i}`} id={`longitudFinal_${i}`} label="dynatest.resources.generacion_masiva_segmentos.table.nameRows.longitudFinal" fullWidth validate={[required(), lngMininima]} />
                                                            }
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {(data.dataDefault.fin_altura) ?
                                                                <NumberInput defaultValue={data.dataDefault.fin_altura} source={`alturaFinal_${i}`} id={`alturaFinal_${i}`} label="dynatest.resources.generacion_masiva_segmentos.table.nameRows.alturaFinal" validate={[required(), Altitud]} fullWidth />
                                                                :
                                                                <NumberInput source={`alturaFinal_${i}`} id={`alturaFinal_${i}`} label="dynatest.resources.generacion_masiva_segmentos.table.nameRows.alturaFinal" validate={[Altitud]} fullWidth />
                                                            }
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {(data.dataDefault.tipo_calzada) ?
                                                                <SelectInput
                                                                    label="dynatest.resources.generacion_masiva_segmentos.table.nameRows.tipoCalzada"
                                                                    source={`tipoCalzada_${i}`}
                                                                    defaultValue={data.dataDefault.tipo_calzada}
                                                                    options={{ fullWidth: true }}
                                                                    choices={this.state.datos.tipo_calzadas}
                                                                    validate={required()}
                                                                />
                                                                :
                                                                <SelectInput
                                                                    label="dynatest.resources.generacion_masiva_segmentos.table.nameRows.tipoCalzada"
                                                                    source={`tipoCalzada_${i}`}
                                                                    options={{ fullWidth: true }}
                                                                    choices={this.state.datos.tipo_calzadas}
                                                                    validate={required()}
                                                                />
                                                            }
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {(data.dataDefault.tipo_segmento) ?
                                                                <SelectInput
                                                                    label="dynatest.resources.generacion_masiva_segmentos.table.nameRows.tipoSegmento"
                                                                    source={`tipoSegmento_${i}`}
                                                                    defaultValue={data.dataDefault.tipo_segmento}
                                                                    options={{ fullWidth: true }}
                                                                    choices={this.state.datos.tipo_segmentos}
                                                                    validate={required()}
                                                                />
                                                                :
                                                                <SelectInput
                                                                    label="dynatest.resources.generacion_masiva_segmentos.table.nameRows.tipoSegmento"
                                                                    source={`tipoSegmento_${i}`}
                                                                    options={{ fullWidth: true }}
                                                                    choices={this.state.datos.tipo_segmentos}
                                                                    validate={required()}
                                                                />
                                                            }
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            {(data.dataDefault.tipo_superficie) ?
                                                                <SelectInput
                                                                    label="dynatest.resources.generacion_masiva_segmentos.table.nameRows.tipoSuperficie"
                                                                    source={`tipoSuperficie_${i}`}
                                                                    defaultValue={data.dataDefault.tipo_superficie}
                                                                    options={{ fullWidth: true }}
                                                                    choices={this.state.datos.tipo_elementos}
                                                                    validate={required()}
                                                                />
                                                                :
                                                                <SelectInput
                                                                    label="dynatest.resources.generacion_masiva_segmentos.table.nameRows.tipoSuperficie"
                                                                    source={`tipoSuperficie_${i}`}
                                                                    defaultValue={data.dataDefault.tipo_superficie}
                                                                    options={{ fullWidth: true }}
                                                                    choices={this.state.datos.tipo_elementos}
                                                                    validate={required()}
                                                                />
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            ))
                                        }

                                    </Table>
                                )
                            }

                            }
                        </FormDataConsumer >
                    </div>
        )

    }
}

export default translate(GenerarSegmentos);