import React from 'react';
import { TextField, ReferenceField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import config from '../../config/config';
import loader from '../../loader10.gif';
let urlAppjs = config.UrlServer;

class VisualizacionModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = { datosCarga: [], parametros: [], estado: 1, totalLum: 0}
        this.calculoAreaAfectada.bind(this);
    }
    componentDidMount() {
        let idproyecto = localStorage.getItem('id_project');
        fetch(urlAppjs + "/parametros_grupos?proyecto=" + idproyecto)
            .then((response) => {
                return response.json()
            })
            .then((response) => {
                let resultado = [];
                if (response) {
                    resultado = response['hydra:member'][0]
                    this.setState({ parametros: resultado })
                }

            })
        
        fetch(urlAppjs + "/buscarIndicadoresOperacionales/" + this.props.ufuncional + "/" + this.props.indicador + "/" + this.props.fechaIni + "/" + this.props.fechaFin + "/" + this.props.tipoEvento)
            .then((response) => {
                return response.json()
            })
            .then((response) => {
                console.log("datosCarga")
                console.log(response)
                this.setState({ datosCarga: response.resultado })
                if(response.resultado.length == 0){
                    this.setState({ estado: 0 })
                }
            })
    }
    componentWillReceiveProps() {
        this.setState({ datosCarga: [] })
        this.setState({ estado: 1 })
        fetch(urlAppjs + "/buscarIndicadoresOperacionales/" + this.props.ufuncional + "/" + this.props.indicador + "/" + this.props.fechaIni + "/" + this.props.fechaFin + "/" + this.props.tipoEvento)
            .then((response) => {
                return response.json()
            })
            .then((response) => {
                console.log("datosCarga")
                console.log(response)
                this.setState({ datosCarga: response.resultado })
                if(response.resultado.length == 0){
                    this.setState({ estado: 0 })
                }
            })
        
    }
    formatoabscisa(valor) {
        if (this.props.indicador === "1" || this.props.indicador === "16") {
            let valor2 = valor - parseInt(valor);
            valor2 = parseFloat(valor2).toFixed(1);
            return "K" + parseInt(valor) + "+" + valor2.replace("0.", "") + "00";
        }
        else if (this.props.indicador === "2" || this.props.indicador === "4" || this.props.indicador === "5") {
            let valor2 = valor - parseInt(valor);
            valor2 = parseFloat(valor2).toFixed(2);
            return "K" + parseInt(valor) + "+" + valor2.replace("0.", "") + "0";
        }
        else {
            let valor2 = valor - parseInt(valor);
            valor2 = parseFloat(valor2).toFixed(3);
            return "K" + parseInt(valor) + "+" + valor2.replace("0.", "");
        }
    }
    formatovalores(valor, decimal) {
        let value = parseFloat(valor).toFixed(decimal).replace(".", ",");
        return value;
    }
    ColorPCI(valor) {
        if (valor >= 86) {
            return "rgb(0, 176, 80)"
        } else if (valor >= 71 && valor < 86) {
            return "rgb(146, 208, 80)"
        } else if (valor >= 56 && valor < 71) {
            return "rgb(255, 255, 0)"
        } else if (valor >= 41 && valor < 56) {
            return "rgb(255, 0, 0)"
        } else if (valor >= 26 && valor < 41) {
            return "rgb(192, 0, 0)"
        } else if (valor >= 11 && valor < 26) {
            return "rgb(148, 54, 52)"
        } else if (valor >= 0 && valor < 11) {
            return "rgb(166, 166, 166)"
        }
    }
    calculoAreaAfectada(baseEnSegmento, registradaEnIndicador) {
        return (parseFloat(registradaEnIndicador) / parseFloat(baseEnSegmento)) * 100;
    }
    ultimaFecha(fechas) {
        if (fechas.length > 0) {
            let dates = fechas.map(fecha => new Date(fecha))
            let fechaMax = new Date(Math.max.apply(null, dates));
            return fechaMax.getFullYear() + "-" + (('0' + (fechaMax.getMonth() + 1)).slice(-2)) + "-" + ('0' + fechaMax.getDate()).slice(-2);
        } else {
            return
        }
    }
    abscisasEspeciales(datos){
        let ultimafecha=[]
        let nuevosDatos=datos;
        datos.map(dato=>{
            let revision=false;
            revision=ultimafecha.find(objeto=>{
                if(dato.tipoMedicion==="Periódica"){
                    return objeto.unidadFuncional===dato.uf_codigo
                    && objeto.abInicial===parseFloat(dato.abInicial)
                    && objeto.abFinal===parseFloat(dato.abFinal)
                    && objeto.tipoMedicion===dato.tipoMedicion
                }else{
                    return objeto.unidadFuncional===dato.uf_codigo
                    && objeto.abInicial===parseFloat(dato.abiniC)
                    && objeto.abFinal===parseFloat(dato.abfinC)
                    && objeto.tipoMedicion===dato.tipoMedicion
                }
            })
            if(revision){
                if(new Date(revision.fecha) <new Date(dato.fecha)){
                    ultimafecha=ultimafecha.filter(objeto=>objeto!=revision)
                    ultimafecha.push({
                        "unidadFuncional":dato.uf_codigo,
                        "abInicial": dato.tipoMedicion==="Periódica"?parseFloat(dato.abInicial):parseFloat(dato.abiniC),
                        "abFinal": dato.tipoMedicion==="Periódica"?parseFloat(dato.abFinal):parseFloat(dato.abfinC),
                        "tipoMedicion": dato.tipoMedicion,
                        "fecha": dato.fecha,
                    })
                }
            }else{
                ultimafecha.push({
                    "unidadFuncional":dato.uf_codigo,
                    "abInicial": dato.tipoMedicion==="Periódica"?parseFloat(dato.abInicial):parseFloat(dato.abiniC),
                    "abFinal": dato.tipoMedicion==="Periódica"?parseFloat(dato.abFinal):parseFloat(dato.abfinC),
                    "tipoMedicion": dato.tipoMedicion,
                    "fecha": dato.fecha,
                })
            }
        });
        for(let dato of datos){
            let revision=false;
            revision=ultimafecha.filter(objeto=>parseFloat(dato.abscisa)>=objeto.abInicial && parseFloat(dato.abscisa)<=objeto.abFinal)
            if(revision.length>0){
                revision=revision.reduce((ant,prox)=>{
                    if(new Date(prox.fecha)>new Date(ant.fecha)){
                    return prox
                    }else{
                    return ant
                    }
                })
                if(new Date(dato.fecha)<new Date(revision.fecha)){
                    nuevosDatos=nuevosDatos.filter(objeto=>objeto!=dato)
                }
            }
        }
        return nuevosDatos;
    }
    render() {
        const { datosCarga } = this.state;
        const { parametros } = this.state;
        const { estado } = this.state;
        
        let indicador = this.props.indicador;
        let tramo = this.props.tramo;
        let ufunc = this.props.ufuncional;
        let valorpromedio = 3;
        let valorpuntual = 1;
        let indicadores = this.props.indicador;
        const MostrarDetalles = (valor, valor2) => {
            let det = document.getElementById('detalles_' + valor)
            let most = document.getElementById('mostrar_' + valor)
            if (det) {
                let disp = det.style.display;
                if (disp != "none") {
                    det.style.display = "none"
                }
                else {
                    det.style.display = ""
                }
            }
            if (most) {
                let html = most.innerHTML;
                if (html != "Mostrar") {
                    most.innerHTML = "Mostrar"
                }
                else {
                    most.innerHTML = "Ocultar"
                }
            }
        }
        const RestarFechas = (fecha1, fecha2, return_min = true) => {
            let fecha_inicial = new Date(fecha1);
            let fecha_final = new Date(fecha2);
            let fecha_inicial_off = new Date(fecha_inicial.getTime() + Math.abs(fecha_inicial.getTimezoneOffset() * 60000));
            let fecha_final_off = new Date(fecha_final.getTime() + Math.abs(fecha_final.getTimezoneOffset() * 60000));
            let difference = fecha_final_off.getTime() - fecha_inicial_off.getTime();
    
            //let differencedays=difference/(1000*3600*24);
            //let differencehours=(difference/(1000*3600))%24;
            let resultado = '';
            if(return_min == true){
                let differenceminutes = (difference / (1000 * 60));
                let minuto_resultado = Math.abs(parseInt(differenceminutes));
                if (minuto_resultado.toString().length <= 1) {
                    minuto_resultado = "0" + minuto_resultado;
                }
                resultado = minuto_resultado;

            }else{
                let differencehours2 = (difference / (1000 * 3600));
                let differenceminutes = (difference / (1000 * 60)) % 60;
                let hora_resultado = Math.abs(parseInt(differencehours2));
                let minuto_resultado = Math.abs(parseInt(differenceminutes));
                if (hora_resultado.toString().length <= 1) {
                    hora_resultado = "0" + hora_resultado;
                }
        
                if (minuto_resultado.toString().length <= 1) {
                    minuto_resultado = "0" + minuto_resultado;
                }
                resultado = hora_resultado + ":" + minuto_resultado;

            }
    
            return resultado
        }

        let campo1 = null;
        let campo2 = null;
        let campo3 = null;
        let campo4 = null;
        let campo5 = null;
        let campo6 = null;
        let campo7 = null;
        let campo8 = null;
        console.log(parametros.evaluacion)
        if (parametros && parametros.evaluacion) {
            campo1 = parametros.evaluacion[parseInt(this.props.indicador) - 1].frecuenciaMedicion; 
            campo2 = parametros.evaluacion[parseInt(this.props.indicador) - 1].tiempoCorreccion; 
            campo3 = parametros.evaluacion[parseInt(this.props.indicador) - 1].valormaxPuntual; 
            campo4 = parametros.evaluacion[parseInt(this.props.indicador) - 1].valormaxMedio; 
            campo5 = parametros.evaluacion[parseInt(this.props.indicador) - 1].campoAdicional; 
            campo6 = parametros.evaluacion[parseInt(this.props.indicador) - 1].campoAdicional2; 
            campo7 = parametros.evaluacion[parseInt(this.props.indicador) - 1].campoAdicional3; 
            campo8 = parametros.evaluacion[parseInt(this.props.indicador) - 1].campoAdicional4; 
        }
        if (indicador && datosCarga.length > 0 && this.props.indicador === "24") {
            let registros = datosCarga;
            let segmento = [];
            let segmento_id = [];
            let numero_eventos = [];
            let numero_eventos_cierre = [];
            let incumplimientos = [];
            let senalizacion_promedio = [];
            let tiempo_cierre_promedio = [];
            let cumplimientoGeneral = [];

            let aux = -1;
            let datosprueba = [];
            registros.map((data, i) => {
                if (data.segmento_id != aux) {
                    segmento.push(data.codigo_segmento);
                    segmento_id.push(data.segmento_id);
                    numero_eventos.push(0);
                    numero_eventos_cierre.push(0);
                    incumplimientos.push(0);
                    senalizacion_promedio.push('0:00');
                    tiempo_cierre_promedio.push('0:00');
                    
                    aux = data.segmento_id;
                }
            })
            registros.map((data, i) => {
                for (let i = 0; i < segmento.length; i++) {
                    if (!datosprueba[i]) {
                        datosprueba[i] = {
                            consecutivo: [],
                            tipo_evento: [],
                            fecha_reporte: [],
                            hora_reporte: [],
                            causa: [],
                            elemento_afectado: [],
                            tiempo_senalizacion: [],
                            tiempo_cierre: [],
                            cumplimiento: []
                        };
                    }
                    if (data.segmento_id === segmento_id[i]) {
                        numero_eventos[i]++;
                        datosprueba[i]['consecutivo'].push(data.consecutivo);
                        datosprueba[i]['tipo_evento'].push(data.tipo_evento);
                        datosprueba[i]['fecha_reporte'].push(data.fecha_reporte);
                        datosprueba[i]['hora_reporte'].push(data.hora_reporte);
                        datosprueba[i]['causa'].push(data.causa);
                        datosprueba[i]['elemento_afectado'].push(data.elemento_afect);
                        datosprueba[i]['tiempo_senalizacion'].push(data.tiemp_senal);
                        if(data.tiempo_despeje_fin != null){
                            let tiempo_desp = data.tiempo_despeje_fin.split(':');
                            let hora = ( parseInt(data.dias_despeje) * 24 ) + parseInt(tiempo_desp[0]);
                            let tiempo = hora + ':' + tiempo_desp[1];
                            datosprueba[i]['tiempo_cierre'].push(tiempo);

                            let t_cierre_p = tiempo_cierre_promedio[i].split(':');
                            
                            let add_hour = parseInt(hora) + parseInt(t_cierre_p[0]);
                            let add_min = parseInt(tiempo_desp[1]) + parseInt(t_cierre_p[1]);

                            let dd = add_min / 60;

                            for (let i = 1; i < dd; i++) {
                                add_min = parseInt(add_min) - 60;
                                add_hour = parseInt(add_hour) + 1;
                            }

                            tiempo_cierre_promedio[i] = add_hour+':'+add_min;

                            numero_eventos_cierre[i]++;

                        }else{
                            datosprueba[i]['tiempo_cierre'].push('N/A');
                        }

                        if(data.causa == 'Trabajos en vía' || data.causa == 'Manifestación' || data.causa == 'Otro' ){
                            datosprueba[i]['cumplimiento'].push('Si');
                            incumplimientos[i]++;
                        }else{
                            datosprueba[i]['cumplimiento'].push('No');
                        }
                        let t_sen_p = senalizacion_promedio[i].split(':');
                        let t_senal = data.tiemp_senal.split(':');
                        
                        let add_hour = parseInt(t_senal[0]) + parseInt(t_sen_p[0]);
                        let add_min = parseInt(t_senal[1]) + parseInt(t_sen_p[1]);

                        let dd = add_min / 60;

                        for (let i = 1; i < dd; i++) {
                            add_min = parseInt(add_min) - 60;
                            add_hour = parseInt(add_hour) + 1;
                        }

                        senalizacion_promedio[i] = add_hour+':'+add_min;

                    }
                }
            })
            //console.log(datosprueba)
            for (let i = 0; i < segmento.length; i++) {
                let cumplimiento = "Cumple";
                //for (let j = 0; j < datosprueba[i]['cumplimiento'].length; j++) {
                if(parametros && parametros.evaluacion){
                    if (incumplimientos[i] > parametros.evaluacion[24].tiempoCorreccion ) {
                        cumplimiento = "No Cumple";
                    }
                }
                //}
                let t_senal = senalizacion_promedio[i].split(':');
                
                let add_min = parseInt(t_senal[1]) + (parseInt(t_senal[0]) * 60);
                add_min = parseInt(parseInt(add_min) / parseInt(numero_eventos[i]))

                senalizacion_promedio[i] = add_min;

                cumplimientoGeneral.push(cumplimiento);
                
                // CIERRE PROMEDIO
                let t_cierre = tiempo_cierre_promedio[i].split(':');
                if(parseInt(t_cierre) > 0 || parseInt(t_cierre) > 0){
                    let add_min_cierre = parseInt(t_cierre[1]) + (parseInt(t_cierre[0]) * 60);
                    add_min_cierre = parseInt(parseInt(add_min_cierre) / parseInt(numero_eventos_cierre[i]))
    
                    tiempo_cierre_promedio[i] = add_min_cierre;
                }else{
                    tiempo_cierre_promedio[i] = 'N/A';
                }

            }

            const MostrarDetalles = (valor, valor2) => {
                let det = document.getElementById('detalles_' + valor)
                let most = document.getElementById('mostrar_' + valor)
                if (det) {
                    let disp = det.style.display;
                    if (disp != "none") {
                        det.style.display = "none"
                    }
                    else {
                        det.style.display = ""
                    }
                }
                if (most) {
                    let html = most.innerHTML;
                    if (html != "Mostrar") {
                        most.innerHTML = "Mostrar"
                    }
                    else {
                        most.innerHTML = "Ocultar"
                    }
                }

                //console.log(valor2)
            }
            return (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} >SEGMENTO</TableCell>
                            <TableCell style={{ textAlign: "center" }} >Número de eventos</TableCell>
                            <TableCell style={{ textAlign: "center" }} >Incumplimientos disponibilidad de vía</TableCell>
                            <TableCell style={{ textAlign: "center" }} >Tiempo de señalización promedio</TableCell>
                            <TableCell style={{ textAlign: "center" }} >Tiempo de cierre promedio</TableCell>
                            <TableCell style={{ textAlign: "center" }} >Cumplimiento indicador</TableCell>
                            <TableCell style={{ textAlign: "center" }}>Visualizar</TableCell>
                        </TableRow>
                    </TableHead>
                    {segmento.map((row, i) => (
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ textAlign: "left" }}>{segmento[i]}</TableCell>
                                <TableCell style={{ textAlign: "center" }}>{numero_eventos[i]}</TableCell>
                                <TableCell style={{ textAlign: "center" }}>{incumplimientos[i]}</TableCell>
                                <TableCell style={{ textAlign: "center" }}>{senalizacion_promedio[i]} min</TableCell>
                                <TableCell style={{ textAlign: "center" }}>{(tiempo_cierre_promedio[i] == 'N/A')? tiempo_cierre_promedio[i]: tiempo_cierre_promedio[i]+" min"}</TableCell>

                                {cumplimientoGeneral[i] === "No Cumple" &&
                                    <TableCell style={{ textAlign: "left", color: "red" }}>{cumplimientoGeneral[i]}</TableCell>
                                }
                                {cumplimientoGeneral[i] != "No Cumple" &&
                                    <TableCell style={{ textAlign: "left" }}>{cumplimientoGeneral[i]}</TableCell>
                                }

                                <TableCell style={{ textAlign: "left" }}><span id={"mostrar_" + i} style={{ color: "blue", cursor: "pointer" }} onClick={(evt) => MostrarDetalles(i, evt)}>Mostrar</span></TableCell>
                            </TableRow>
                            <TableRow style={{ display: "none" }} id={"detalles_" + i}>
                                <TableCell colSpan="6">
                                    <Table aria-label="simple table" style={{ width: "80%" }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ textAlign: "center" }}>Consecutivo</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>Tipo de evento</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>Fecha de reporte</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>Hora de reporte</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>Causa</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>Elemento afectado</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>Tiempo de señalización</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>Tiempo de cierre</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>Genera incumplimiento</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {datosprueba[i]['consecutivo'].map((row, j) => (
                                                <TableRow>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['consecutivo'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['tipo_evento'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['fecha_reporte'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['hora_reporte'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['causa'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['elemento_afectado'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['tiempo_senalizacion'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['tiempo_cierre'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['cumplimiento'][j]}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ))}

                </Table>
            )
        }
        else if (indicador && datosCarga.length > 0 && this.props.indicador === "25") {
            let registros = datosCarga;
            let unidad_funcional_id = [];
            let unidad_funcional = [];
            let numero_eventos = [];
            let incumplimientos = [];
            let tiempo_sen_prom = [];
            let tiempo_sen_max = [];
            let tiempo_sen_cant = [];
            let tiempo_cie_prom = [];
            let tiempo_cie_max = [];
            let tiempo_cie_cant = [];
            let tiempo_aten_prom = [];
            let tiempo_aten_max = [];
            let tiempo_aten_cant = [];
            let cumplimientoGeneral = [];

            let aux = -1;
            let datosprueba = [];
            registros.map((data, i) => {
                if (data.unidad_funcional_id != aux) {
                    unidad_funcional.push(data.unidad_funcional);
                    unidad_funcional_id.push(data.unidad_funcional_id);
                    numero_eventos.push(0);
                    incumplimientos.push(0);
                    tiempo_sen_prom.push(0);
                    tiempo_sen_max.push(0);
                    tiempo_sen_cant.push(0);
                    tiempo_cie_prom.push(0);
                    tiempo_cie_max.push(0);
                    tiempo_cie_cant.push(0);
                    tiempo_aten_prom.push(0);
                    tiempo_aten_max.push(0);
                    tiempo_aten_cant.push(0);
                    //cumplimientoGeneral.push('Cumple');
                    
                    aux = data.unidad_funcional_id;
                }
            })

            registros.map((data, i) => {
                for (let i = 0; i < unidad_funcional_id.length; i++) {
                    if (!datosprueba[i]) {
                        datosprueba[i] = {
                            consecutivo: [],
                            fecha_reporte: [],
                            hora_reporte: [],
                            causa: [],
                            elemento_afectado: [],
                            tiempo_senal: [],
                            volumen_derrumbe: [],
                            tiempo_despeje: [],
                            tiempo_ambulancia: [],
                            tiempo_grua: [],
                            cumplimiento: []
                        };
                    }
                    if (data.unidad_funcional_id === unidad_funcional_id[i]) {
                        numero_eventos[i]++;
                        datosprueba[i]['consecutivo'].push(data.consecutivo);
                        datosprueba[i]['fecha_reporte'].push(data.fecha_reporte);
                        datosprueba[i]['hora_reporte'].push(data.hora_reporte);
                        datosprueba[i]['causa'].push(data.causa);
                        datosprueba[i]['elemento_afectado'].push(data.elemento_afect);
                        datosprueba[i]['tiempo_senal'].push(data.tiemp_senal);
                        let cumplimiento = 'No';
                        if(this.props.tipoEvento == 'Accidente'){
                            let fecha_inicial = '';
                            let fecha_final = '';
                            let dif = '';
                            let dif_h = '';
                            if(data.fecha_ambulancia != null && data.hora_ambulancia != null){
                                fecha_inicial = data.fecha_reporte + "T" + data.hora_reporte;
                                fecha_final = data.fecha_ambulancia + "T" + data.hora_ambulancia;
                                dif = RestarFechas(fecha_inicial, fecha_final);
                                dif_h = RestarFechas(fecha_inicial, fecha_final,false);
                                datosprueba[i]['tiempo_ambulancia'].push(dif_h);
                                if(parametros && parametros.evaluacion){
                                    if(dif > parametros.evaluacion[25].tiempoCorreccion){
                                        cumplimiento = 'Si';
                                    }
                                }
                            }else{
                                datosprueba[i]['tiempo_ambulancia'].push('');
                            }

                            if(data.fecha_grua != null && data.hora_grua != null){
                                fecha_inicial = data.fecha_reporte + "T" + data.hora_reporte;
                                fecha_final = data.fecha_grua + "T" + data.hora_grua;
                                dif = RestarFechas(fecha_inicial, fecha_final);
                                dif_h = RestarFechas(fecha_inicial, fecha_final,false);
                                datosprueba[i]['tiempo_grua'].push(dif_h);
                                if(parametros && parametros.evaluacion){
                                    if(dif > parametros.evaluacion[25].valormaxPuntual){
                                        cumplimiento = 'Si';
                                    }
                                }
                            }else{
                                datosprueba[i]['tiempo_grua'].push('');
                            }

                            datosprueba[i]['volumen_derrumbe'].push('');  
                            datosprueba[i]['tiempo_despeje'].push('');

                        } else if(this.props.tipoEvento == 'Incidente'){
                            
                            datosprueba[i]['volumen_derrumbe'].push(data.volumen_derrumbe);  
                            if(parseInt(data.dias_despeje) > 0){
                                let tiempo_desp_t = data.tiempo_despeje_fin.split(':');
                                let hora_t = ( parseInt(data.dias_despeje) * 24 ) + parseInt(tiempo_desp_t[0]);
                                
                                datosprueba[i]['tiempo_despeje'].push(hora_t+":"+tiempo_desp_t[1]);
                            }else{
                                datosprueba[i]['tiempo_despeje'].push(data.tiempo_despeje_fin);
                            }
                            if(parametros && parametros.evaluacion){
                                if(data.elemento_afect == 'Bermas'){
                                    if(data.tiempo_despeje_fin != null){
                                        let tiempo_desp = data.tiempo_despeje_fin.split(':');
                                        let hora = ( parseInt(data.dias_despeje) * 24 ) + parseInt(tiempo_desp[0]);
                                        //let minutos = parseInt(parseInt(hora) * 60) + parseInt(tiempo_desp[1]);
                                        if(data.volumen_derrumbe < 200){
                                            if(hora > parametros.evaluacion[24].valormaxMedio){
                                                cumplimiento = 'Si';
                                            }
                                        }else{
                                            if(hora > parametros.evaluacion[24].campoAdicional2){
                                                cumplimiento = 'Si';
                                            }
                                        }
                                    }
                                    
                                    //// > 200
                                }else if(data.elemento_afect == 'Calzada'){
                                    if(data.tiempo_despeje_fin != null){
                                        let tiempo_desp = data.tiempo_despeje_fin.split(':');
                                        let hora = ( parseInt(data.dias_despeje) * 24 ) + parseInt(tiempo_desp[0]);
                                        //let minutos = parseInt(parseInt(hora) * 60) + parseInt(tiempo_desp[1]);
                                        if(data.volumen_derrumbe < 200){
                                            if(hora > parametros.evaluacion[24].valormaxPuntual){
                                                cumplimiento = 'Si';
                                            }
                                        }else{
                                            if(hora > parametros.evaluacion[24].campoAdicional){
                                                cumplimiento = 'Si';
                                            }
                                        }
                                    }
                                    //parametros.evaluacion[24].valormaxPuntual
                                    //parametros.evaluacion[24].campoAdicional
                                }
                            }

                            datosprueba[i]['tiempo_ambulancia'].push('');
                            datosprueba[i]['tiempo_grua'].push('');
                        }else{
                            datosprueba[i]['tiempo_ambulancia'].push('');
                            datosprueba[i]['tiempo_grua'].push('');
                            datosprueba[i]['volumen_derrumbe'].push('');  
                            datosprueba[i]['tiempo_despeje'].push('');
                        }

                        datosprueba[i]['cumplimiento'].push(cumplimiento);
                        if(cumplimiento == 'Si'){
                            incumplimientos[i]++;
                        }

                        //Tiempo señal
                        let t_sen_p = tiempo_sen_prom[i];
                        let t_senal = data.tiemp_senal.split(':');
                        
                        let add_min = parseInt(parseInt(t_senal[0]) * 60) + parseInt(t_senal[1]);
                        let min = parseInt(add_min) + parseInt(t_sen_p);

                        tiempo_sen_prom[i] += min;
                        
                        if(parseInt(add_min) > parseInt(tiempo_sen_max[i])){
                            tiempo_sen_max[i] = parseInt(add_min);
                        }

                        tiempo_sen_cant[i]++;

                        //Tiempo cierre
                        t_sen_p = tiempo_cie_prom[i];
                        if(data.tiempo_despeje_fin != null){
                            
                            t_senal = data.tiempo_despeje_fin.split(':');
                            add_min = ( parseInt(data.dias_despeje) * 24 * 60 ) + parseInt(t_senal[0] * 60) + parseInt(t_senal[1]);
                            min = parseInt(add_min) + parseInt(t_sen_p);
                            
                            tiempo_cie_prom[i] += min;
                        
                            if(parseInt(add_min) > parseInt(tiempo_cie_max[i])){
                                tiempo_cie_max[i] = parseInt(add_min);
                            }
    
                            tiempo_cie_cant[i]++;
                        }

                        //Tiempo Atencion
                        t_sen_p = tiempo_aten_prom[i];
                        if(data.fecha_ambulancia != null && data.hora_ambulancia != null){
                            let fecha_i = data.fecha_reporte + "T" + data.hora_reporte;
                            let fecha_f = data.fecha_ambulancia + "T" + data.hora_ambulancia;
                            min = RestarFechas(fecha_i, fecha_f);
                            tiempo_aten_prom[i] += min;
                            
                            if(parseInt(min) > parseInt(tiempo_aten_max[i])){
                                tiempo_aten_max[i] = parseInt(min);
                            }

                            tiempo_aten_cant[i]++;
                        }
                        
                    }
                }
            })

            for (let i = 0; i < unidad_funcional_id.length; i++) {
                let cumplimiento = "Cumple";
                if(parametros && parametros.evaluacion){
                    if(this.props.tipoEvento == 'Accidente'){
                        if (incumplimientos[i] > parametros.evaluacion[25].valormaxMedio){
                            cumplimiento = "No Cumple";
                        }
                    } else if(this.props.tipoEvento == 'Incidente'){
                        console.log(incumplimientos[i] +">" + parametros.evaluacion[24].tiempoCorreccion)
                        if(incumplimientos[i] > parametros.evaluacion[24].tiempoCorreccion){
                            cumplimiento = "No Cumple";
                        }
                    }
                    cumplimientoGeneral.push(cumplimiento);

                    if(tiempo_sen_cant[i] > 1){
                        tiempo_sen_prom[i] = tiempo_sen_prom[i] / tiempo_sen_cant[i];
                    }
                    if(tiempo_cie_cant[i] > 1){
                        tiempo_cie_prom[i] = tiempo_cie_prom[i] / tiempo_cie_cant[i];
                    }
                    if(tiempo_aten_cant[i] > 1){
                        tiempo_aten_prom[i] = tiempo_aten_prom[i] / tiempo_aten_cant[i];
                    }
                }
            }
            console.log(cumplimientoGeneral)

            return (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell rowspan="2" style={{ textAlign: "center" }} >Unidad Funcional</TableCell>
                            <TableCell rowspan="2" style={{ textAlign: "center" }} >Número de eventos</TableCell>
                            <TableCell rowspan="2" style={{ textAlign: "center" }} >Número de incumplimientos</TableCell>
                            <TableCell colspan="2" style={{ textAlign: "center" }} >Tiempo de señalización</TableCell>
                            <TableCell colspan="2" style={{ textAlign: "center" }} >Tiempo de cierre</TableCell>
                            <TableCell colspan="2" style={{ textAlign: "center" }} >Tiempo de atención</TableCell>
                            <TableCell rowspan="2" style={{ textAlign: "center" }} >Cumplimiento indicador</TableCell>
                            <TableCell rowspan="2" style={{ textAlign: "center" }}>Visualizar</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} >Promedio</TableCell>
                            <TableCell style={{ textAlign: "center" }} >Máximo</TableCell>
                            <TableCell style={{ textAlign: "center" }} >Promedio</TableCell>
                            <TableCell style={{ textAlign: "center" }} >Máximo</TableCell>
                            <TableCell style={{ textAlign: "center" }} >Promedio</TableCell>
                            <TableCell style={{ textAlign: "center" }} >Máximo</TableCell>
                        </TableRow>
                    </TableHead>
                    {unidad_funcional.map((row, i) => (
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ textAlign: "center" }}>{unidad_funcional[i]}</TableCell>
                                <TableCell style={{ textAlign: "center" }}>{numero_eventos[i]}</TableCell>
                                <TableCell style={{ textAlign: "center" }}>{incumplimientos[i]}</TableCell>

                                <TableCell style={{ textAlign: "left" }}>{Number.parseFloat(tiempo_sen_prom[i]).toFixed(2)} min</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{tiempo_sen_max[i]} min</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{Number.parseFloat(tiempo_cie_prom[i]).toFixed(2)} min</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{tiempo_cie_max[i]} min</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{Number.parseFloat(tiempo_aten_prom[i]).toFixed(2)} min</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{tiempo_aten_max[i]} min</TableCell>

                                {cumplimientoGeneral[i] === "No Cumple" &&
                                    <TableCell style={{ textAlign: "left", color: "red" }}>{cumplimientoGeneral[i]}</TableCell>
                                }
                                {cumplimientoGeneral[i] != "No Cumple" &&
                                    <TableCell style={{ textAlign: "left" }}>{cumplimientoGeneral[i]}</TableCell>
                                }

                                <TableCell style={{ textAlign: "right" }}><span id={"mostrar_" + i} style={{ color: "blue", cursor: "pointer" }} onClick={(evt) => MostrarDetalles(i, evt)}>Mostrar</span></TableCell>
                            </TableRow>
                            <TableRow style={{ display: "none" }} id={"detalles_" + i}>
                                <TableCell colSpan="10">
                                    <Table aria-label="simple table" style={{ width: "80%" }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ textAlign: "center" }}>Consecutivo</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>Fecha de reporte</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>Hora de reporte</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>Causa</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>Elemento afectado</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>Tiempo de señalización</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>Volumen del derrumbe (m³)</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>Tiempo de despeje</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>Tiempo llegada Ambulancia</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>Tiempo llegada grúa y demás equipos</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>Genera incumplimiento</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {datosprueba[i]['consecutivo'].map((row, j) => (
                                                <TableRow>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['consecutivo'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['fecha_reporte'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['hora_reporte'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['causa'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['elemento_afectado'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['tiempo_senal'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['volumen_derrumbe'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['tiempo_despeje'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['tiempo_ambulancia'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['tiempo_grua'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "center" }}>{datosprueba[i]['cumplimiento'][j]}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ))}

                </Table>
            )

        }
        else if (indicador && datosCarga.length > 0 && this.props.indicador === "26") {
            let registros = datosCarga;
            let unidad_funcional_id = [];
            let unidad_funcional = [];
            let numero_eventos = [];
            let incumplimientos = [];
            let long_cola_maxima = [];
            let long_cola_valida = [];
            let cumplimientoGeneral = [];

            let aux = -1;
            let datosprueba = [];
            registros.map((data, i) => {
                if (data.unidad_funcional_id != aux) {
                    unidad_funcional.push(data.unidad_funcional);
                    unidad_funcional_id.push(data.unidad_funcional_id);
                    numero_eventos.push(0);
                    incumplimientos.push(0);
                    long_cola_maxima.push(0);
                    if(parametros && parametros.evaluacion){
                        long_cola_valida.push(parametros.evaluacion[23].frecuenciaMedicion);
                    }else{
                        long_cola_valida.push(0);
                    }
                    //cumplimientoGeneral.push('Cumple');
                    
                    aux = data.unidad_funcional_id;
                }
            })

            registros.map((data, i) => {
                for (let i = 0; i < unidad_funcional_id.length; i++) {
                    if (!datosprueba[i]) {
                        datosprueba[i] = {
                            consecutivo: [],
                            fecha_reporte: [],
                            hora_reporte: [],
                            causa: [],
                            elemento_afectado: [],
                            longitud_retencion: [],
                            cumplimiento: []
                        };
                    }
                    if (data.unidad_funcional_id === unidad_funcional_id[i]) {
                        numero_eventos[i]++;
                        datosprueba[i]['consecutivo'].push(data.consecutivo);
                        datosprueba[i]['fecha_reporte'].push(data.fecha_reporte);
                        datosprueba[i]['hora_reporte'].push(data.hora_reporte);
                        datosprueba[i]['causa'].push(data.causa);
                        datosprueba[i]['elemento_afectado'].push(data.elemento_afect);
                        datosprueba[i]['longitud_retencion'].push(data.longitud_cola);
                        if(data.longitud_cola > long_cola_maxima[i]){
                            long_cola_maxima[i] = data.longitud_cola;
                        }
                        if(parametros && parametros.evaluacion){
                            if(this.props.tipoEvento == 'Incidente' && data.causa == 'Trabajos en vía' && (data.longitud_cola > parametros.evaluacion[23].frecuenciaMedicion)){
                                datosprueba[i]['cumplimiento'].push('Si');
                                incumplimientos[i]++;
                            }else{
                                datosprueba[i]['cumplimiento'].push('No');
                            }
                        }
                    }
                }
            })
            let cumplimiento = "Cumple";
            for (let i = 0; i < unidad_funcional.length; i++) {
                cumplimiento = "Cumple";
                //for (let j = 0; j < datosprueba[i]['cumplimiento'].length; j++) {
                if(parametros && parametros.evaluacion){
                    if (parseInt(incumplimientos[i]) > parseInt(parametros.evaluacion[23].valormaxPuntual)) {
                        cumplimiento = "No Cumple";
                    }
                }
                cumplimientoGeneral.push(cumplimiento);
           }
            return (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} >Unidad Funcional</TableCell>
                            <TableCell style={{ textAlign: "center" }} >Longitud de la cola máxima de vehiculos(m)</TableCell>
                            <TableCell style={{ textAlign: "center" }} >Longitud valida de la cola(m)</TableCell>
                            <TableCell style={{ textAlign: "center" }} >Número de eventos</TableCell>
                            <TableCell style={{ textAlign: "center" }} >Número de incumplimientos</TableCell>
                            <TableCell style={{ textAlign: "center" }} >Cumplimiento indicador</TableCell>
                            <TableCell style={{ textAlign: "center" }}>Visualizar</TableCell>
                        </TableRow>
                    </TableHead>
                    {unidad_funcional.map((row, i) => (
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ textAlign: "center" }}>{unidad_funcional[i]}</TableCell>
                                <TableCell style={{ textAlign: "center" }}>{long_cola_maxima[i]}</TableCell>
                                <TableCell style={{ textAlign: "center" }}>{long_cola_valida[i]}</TableCell>
                                <TableCell style={{ textAlign: "center" }}>{numero_eventos[i]}</TableCell>
                                <TableCell style={{ textAlign: "center" }}>{incumplimientos[i]}</TableCell>

                                {cumplimientoGeneral[i] === "No Cumple" &&
                                    <TableCell style={{ textAlign: "center", color: "red" }}>{cumplimientoGeneral[i]}</TableCell>
                                }
                                {cumplimientoGeneral[i] != "No Cumple" &&
                                    <TableCell style={{ textAlign: "center" }}>{cumplimientoGeneral[i]}</TableCell>
                                }

                                <TableCell style={{ textAlign: "right" }}><span id={"mostrar_" + i} style={{ color: "blue", cursor: "pointer" }} onClick={(evt) => MostrarDetalles(i, evt)}>Mostrar</span></TableCell>
                            </TableRow>
                            <TableRow style={{ display: "none" }} id={"detalles_" + i}>
                                <TableCell colSpan="6">
                                    <Table aria-label="simple table" style={{ width: "80%" }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ textAlign: "center" }}>Consecutivo</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>Fecha de reporte</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>Hora de reporte</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>Causa</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>Elemento afectado</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>Longitud de retención</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>Genera incumplimiento</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {datosprueba[i]['consecutivo'].map((row, j) => (
                                                <TableRow>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['consecutivo'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['fecha_reporte'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['hora_reporte'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['causa'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['elemento_afectado'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "center" }}>{datosprueba[i]['longitud_retencion'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "center" }}>{datosprueba[i]['cumplimiento'][j]}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ))}

                </Table>
            )

        }
        else if(estado === 0 && this.props.indicador === "26" && this.props.tipoEvento != 'Incidente'){
            return (
                <div>
                    <h2>No se encontraron datos</h2>
                    <br/>
                    <h2>Recuerde que para el indicador "Ocupacion de carril" se debe seleccionar tipo evento "Incidente".</h2>
                </div>
            )
        }
        else if (estado === 0) {
            return (
                <div>
                    <h2>No se encontraron datos.</h2>
                </div>
            )
        } else {
            return (
                <div className="Reload2">
                    <img src={loader} />
                </div>
            )
        }
    }
}
export default VisualizacionModal;