import React from 'react';
import Typography from '@material-ui/core/Typography';
import { SelectInput, DisabledInput, FormDataConsumer, required } from 'react-admin';
import loader from './../../../../../loader10.gif';
import config from '../../../../../config/config';
let urlAppjs = config.UrlServer;

class Grupo2Edit extends React.Component{

    constructor(props){
        super(props)
        this.state = { grupo2: [], datoEva: [], cumple: "", grupo2Peso: []}
        
    }

    componentDidMount(){

        let url_elemento_aux = document.location.hash;
        let croed=url_elemento_aux.split("/");
        let crearoeditar="";
        let componente =""
        if(croed[2]==="create"){
            crearoeditar=1;
        }
        else{
        let url_elemento = url_elemento_aux.split("/%2F");
        componente = url_elemento[1];
        componente = componente.replace("/1", "");
        console.log("Componente=>"+componente)
        componente = componente.replace("%2F", "/");
        console.log("Componente2=>"+componente)
        crearoeditar=2;
        }
        
        // console.log(urlAppjs+"/"+componente)
        if(crearoeditar===2){
            this.setState({ datoEva: this.props.record })
            // fetch(urlAppjs+"/"+componente)
        // .then((response) => {
        //     return response.json()
        // })
        // .then((datoEva) => {
        //     this.setState({ datoEva: datoEva })
        // })
    }
        fetch(urlAppjs+"/preguntas?elemento_indicador=2.json")
        .then((response) => {
            return response.json()
        })
        .then((grupo2) => {
            this.setState({ grupo2: grupo2 })
        })
        
        fetch(urlAppjs+"/preguntas/25")
        .then((response) => {
            return response.json()
        })
        .then((grupo2Peso) => {
            this.setState({ grupo2Peso: grupo2Peso })
        })
        
    }

    render(){

        const { grupo2 } = this.state;     
        const { grupo2Peso } = this.state;     
        const { datoEva } = this.state;             
        const {cumple}= this.state;
        let valoredit="";
        let pesoTab2=grupo2Peso.peso;
        const ValidarGrupo2=(value)=>{
            // let largo=0;
            // if(grupo2['hydra:member']){
            // largo=grupo2['hydra:member'].length;
            // }
            // let resultado =[];
            // let cumpleaux=0;
            // let cumplea="No Cumple";
            // for(let j=0; j<largo; j++){
            //     let valor = document.getElementById('gp2_res_'+j);
            //     if(valor && valor.value!=""){
            //         resultado.push(valor.value);
            //     }
            // }
            // if(resultado.length===largo){
            //     resultado.map((data, i)=>{
            //         if(data==="Cumple"){
            //             cumpleaux++;
            //         }
            //     })
            // }
            // if(cumpleaux===largo){
            //     cumplea="Cumple"
            // }
            // else{
            //     cumplea="No Cumple"
            // }
            // valoredit=cumplea;
        }
        // if(datoEva.svGrupo && datoEva.calificacionG2 && cumple===""){
        //     valoredit=datoEva.calificacionG2
            
        // }
        // if(cumple==="" && valoredit===""){
        // //valoredit="No Cumple";
        
        // ValidarGrupo2();
        // }
        // else{
        //     valoredit=cumple

        // }
        if(grupo2['hydra:member']){

            let response2 = [];
            if(datoEva.svGrupo){
                grupo2['hydra:member'].map((dataGrupo) => {
                    datoEva.svGrupo.map( responseSV => {
                        if(dataGrupo.pregunta === responseSV["preguntas_"].pregunta && responseSV["preguntas_"].elemento_indicador==="/elemento_indicadors/2"){
                            response2.push(responseSV.calificacion)
                        }
                    })
                })
            }
            let grupo2b=[];
            let pesosGrupo2b=[];
            grupo2['hydra:member'].map((dato) => {            
            if(dato.pregunta==="Sin Daños"){
            
            }else{
                grupo2b.push(dato);
                pesosGrupo2b.push(dato.peso);
            }
            })
            // console.log(response2)

            return(
                <React.Fragment>                                       
                    {
                        grupo2b.map((data, i) => (
                            <React.Fragment key={i}>                                
                                <Typography
                                    variant="strong"                                
                                    color="inherit"
                                    style={{width:'50%', display: 'inline-block'}}                                    
                                >
                                    {data.pregunta}
                                </Typography>
                                {/* <TextInput source={`gp1_res${i}`} label=" " style={{width:'30%', display: 'inline-block', marginLeft: '20px'}} fullWidth/>                                 */}
                                <SelectInput source={`gp2_res_${i}`} id={`gp2_res_${i}`}  allowEmpty emptyValue="" label=" " 
                                    choices={[
                                        {id: "-", name:"-"}, 
                                        { id: 'Cumple', name: 'Cumple' },
                                        { id: 'No Cumple', name: 'No Cumple' },                    
                                    ]}
                                style={{width:'30%', display: 'inline-block', marginLeft: '20px'}} fullWidth defaultValue={response2[i]} onChange={ValidarGrupo2} validate={[required()]}/>                                
                                <DisabledInput source={`gp2_id_${i}`} defaultValue={data["@id"]} style={{display: 'none'}}  />                                
                            </React.Fragment>
                        ))

                    }    
                     <React.Fragment key={3}>
                        <Typography
                            variant="strong"
                            color="inherit"
                            style={{width:'50%', display: 'none'}}
                        >
                            {grupo2['hydra:member'][3]['pregunta']}
                        </Typography>
                        <DisabledInput source="calificacionG2d" label=" "  defaultValue={valoredit} style={{width:'30%', display: 'none', marginLeft: '20px'}} fullWidth />
                         <DisabledInput source={`gp2_id_3`} defaultValue={grupo2['hydra:member'][3]["@id"]} style={{display: 'none'}}/>
                    </React.Fragment>
                    {/* <hr />
                    <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '-30px'}}>
                        <strong style={{display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}>Condición: </strong><DisabledInput source="calificacionG2d" label=" " defaultValue={valoredit}/>
                    </div>   */}
                    <DisabledInput source="gp2_length" label=" " defaultValue={grupo2['hydra:member'].length} style={{display: 'none'}}/>              
                    <DisabledInput source="gp2_calificacion" label=" " style={{display: 'none'}}/>
                    <FormDataConsumer style={{display: "none"}}>
                                {({formData, ...rest}) => {
                                    if(formData){
                                 let largo=0;
                                 if(grupo2['hydra:member']){
                                 largo=grupo2['hydra:member'].length-1;
                                 }
                                 let resultado =[];
                                 let cumpleaux=0;
                                 let cumpleauxpeso=0;
                                 let cumplea="No Cumple";
                                 for(let j=0; j<largo; j++){
                                     let valor = formData["gp2_res_"+j];
                                     if(valor){
                                         resultado.push(valor);
                                     }
                                 }
                                 if(resultado.length===largo-1 || resultado.length===largo){
                                     resultado.map((data, i)=>{
                                         
                                         if(data==="Cumple" || data==="-" || data==="Existe" || data===""){
                                             cumpleaux++;
                                         }
                                         else{
                                            cumpleauxpeso+=pesosGrupo2b[i];
                                        }
                                     })
                                 }
                                 if(cumpleaux===largo){
                                     cumplea="Cumple"
                                 }
                                 else{
                                     cumplea="No Cumple"
                                 }
                                 valoredit=cumplea;
                                 //console.log(valoredit)
                                 //console.log("cumple Aux ->"+cumpleaux+ " largo -> "+largo+ " resultado ->"+resultado.length)
                                 //formData.calificacionG2d=valoredit;
                                 if(cumpleauxpeso===0){
                                    formData.gp2_calificacion="0";
                                 }else{
                                 formData.gp2_calificacion=cumpleauxpeso;
                                 }
                                 if(cumpleauxpeso<pesoTab2){
                                    formData.calificacionG2d="Cumple"
                                 }
                                 else{
                                    formData.calificacionG2d="No Cumple"
                                 }
                                 
                                 console.log("Peso Total2: "+cumpleauxpeso);
                                 //this.setState({cumple: cumplea})
                                }
                                }}
                    </FormDataConsumer>
                </React.Fragment>
            )          
                     
        }else{                      
            return( 
                <div className="Reload2">
                    <img src={loader} />
                </div> 
            )    
        }
            
    }    
}

export default Grupo2Edit;