import React, { useState } from 'react';
import {
  FormTab, NumberInput, ReferenceInput, SelectInput, FormDataConsumer, DateInput,ImageInput,ImageField,
  TabbedForm, BooleanInput, TextInput, ArrayInput, SimpleFormIterator, AutocompleteInput
} from 'react-admin';
import { PimsElementoActions } from './../PimsElementoActions';
import {
  abValidationInicio,Entero, abValidationFin, latMininima, lngMininima, validarDosDecimales, validarTresDecimales, isFechaInvalida, prValidationInicio, prValidationFin, validacionmaxfechaact
} from '../../validacionesInputs';
import Typography from '@material-ui/core/Typography';
import { Edit } from './../../Edit';
import { PRInput } from '../../../inputs/PRInput';
import { PInput } from '../../../inputs/PInput';
import { DInput } from '../../../inputs/DInput';
import { AInput } from '../../../inputs/AInput';
import { styles } from './../../../EstilosGrid';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core';
import { required, minValue, maxValue, minLength, maxLength } from 'react-admin';
import CoordenadasProyecto from '../../CoordenadasInventario';
import AbsSegmento from '../../AbsSegmento';
import { ArrayInputImagesEdit } from './../../images/ArrayInputImagesEdit';
import { ButtonCarousel } from './../../images/ButtonCarouselPeru';
import { translate } from 'react-admin';

const choices = [
  { id: '2000', name: '2000' },
  { id: '2001', name: '2001' },
  { id: '2002', name: '2002' },
  { id: '2003', name: '2003' },
  { id: '2004', name: '2004' },
  { id: '2005', name: '2005' },
  { id: '2006', name: '2006' },
  { id: '2007', name: '2007' },
  { id: '2008', name: '2008' },
  { id: '2009', name: '2009' },
  { id: '2010', name: '2010' },
  { id: '2011', name: '2011' },
  { id: '2012', name: '2012' },
  { id: '2013', name: '2013' },
  { id: '2014', name: '2014' },
  { id: '2015', name: '2015' },
  { id: '2016', name: '2016' },
  { id: '2017', name: '2017' },
  { id: '2018', name: '2018' },
  { id: '2019', name: '2019' },
  { id: '2020', name: '2020' },
  { id: '2021', name: '2021' },
  { id: '2022', name: '2022' },
  { id: '2023', name: '2023' },
  { id: '2024', name: '2024' },
  { id: '2025', name: '2025' },
  { id: '2026', name: '2026' },
  { id: '2027', name: '2027' },
  { id: '2028', name: '2028' },
  { id: '2029', name: '2029' },
  { id: '2030', name: '2030' }
];

export const ElementoPontonEdit = translate(withStyles(styles)(({ classes, ...props }) => {
  const { translate } = props;

  const [unidads, setUnidad] = useState('');
  const [tramos, setTramo] = useState('');
  const [segmentos, setSegmento] = useState('');

  const validarEnteroNT = (value) => {
    if (value) {
      if (!/^([0-9]+)*$/.test(value)) { return translate('dynatest.resources.elemento_pontons.messagesVal.valNumEntero'); }
    }
  }


  const ValidarUnidad = (value) => {
    setUnidad(value)
  }


  const ValidarTramo = (value) => {

    setTramo(value)
  }


  /* VALIDAR LOS RANGOS QUE PUEDEN TENER LAS LATITUDES Y LONGITUDES */

  const ValidarSegmento = (value) => {
    setSegmento(value)

  }
  /* FIN DE VALIDACIÓN DE LOS RANGOS QUE PUEDEN TENER LAS LATITUDES Y LONGITUDES */

  /*VALORES POR DEFECTO DEL ARRAY DE COORDENADAS*/
  const [latitudMinimaArray, setLatitudArray] = useState('');
  const LatitudMinimaArray = (value) => {
    setLatitudArray(value)
  }
  const [longitudMinimaArray, setLongitudArray] = useState('');
  const LongitudMinimaArray = (value) => {
    setLongitudArray(value)
  }
  const [longitudMaximaArray, setLongitudMaxdArray] = useState('');
  const LongitudMaximaArray = (value) => {
    setLongitudMaxdArray(value)
  }
  const [latitudMaximaArray, setLatitudMaxdArray] = useState('');
  const LatitudMaximaArray = (value) => {
    setLatitudMaxdArray(value)
  }
  /*FIN DE LOS VALORES POR DEFECTO DEL ARRAY DE COORDENADAS*/



  const [estados, setEstado] = useState('');

  const ValidarEstado = (value) => {

    let lenghtObject = Object.keys(value);
    let valor = '';
    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setEstado(valor)

  }

/*VALIDAR IMÁGENES ACTUALES */
  const [imagenesOpen,setImagenesOpen] = useState('');
  const ImagenesEdit = (value) =>{
      setImagenesOpen(value);
      
  }

  return (
    <Edit {...props} title="dynatest.resources.elemento_pontons.title.editTitle" undoable={false} >
      <TabbedForm redirect="list">
        <FormTab label="dynatest.resources.elemento_pontons.tabs.tabsTitle.identificacion">
          <CoordenadasProyecto />
          <SelectInput label="resources.elemento_pontons.fields.tipoElemento" source="tipoElemento" validate={[required()]}
            choices={[
              { id: 'PTON', name: 'dynatest.resources.elemento_pontons.select.tipoElemento.PTON' }
            ]}
            fullWidth formClassName={classes.grid_cont4_f} />
          <SelectInput label="resources.elemento_pontons.fields.margen" source="margen" allowEmpty emptyValue="" validate={[required()]}
            choices={[
              { id: translate('dynatest.resources.elemento_pontons.select.margen.Derecha'), name: 'dynatest.resources.elemento_pontons.select.margen.Derecha' },
              { id: translate('dynatest.resources.elemento_pontons.select.margen.Izquierda'), name: 'dynatest.resources.elemento_pontons.select.margen.Izquierda' },
              { id: translate('dynatest.resources.elemento_pontons.select.margen.Centro'), name: 'dynatest.resources.elemento_pontons.select.margen.Centro' },
            ]}
            fullWidth formClassName={classes.grid_cont4} />
            <SelectInput label="resources.elemento_pontons.fields.sentidoB" source='sentidoB' choices={[
                { id: translate('dynatest.resources.elemento_pontons.select.sentidoB.NORTEId'), name: 'dynatest.resources.elemento_pontons.select.sentidoB.NORTEName' },
                { id: translate('dynatest.resources.elemento_pontons.select.sentidoB.SURId'), name: 'dynatest.resources.elemento_pontons.select.sentidoB.SURName' },
                { id: translate('dynatest.resources.elemento_pontons.select.sentidoB.ESTEId'), name: 'dynatest.resources.elemento_pontons.select.sentidoB.ESTEName' },
                { id: translate('dynatest.resources.elemento_pontons.select.sentidoB.OESTEId'), name: 'dynatest.resources.elemento_pontons.select.sentidoB.OESTEName' }
                ]}
                fullWidth
                formClassName={classes.grid_cont4}/>
          <SelectInput label="resources.elemento_pontons.fields.tipoPonton" source="tipoPonton" allowEmpty emptyValue="" validate={[required(), maxLength(20)]}
            choices={[
              { id: "Concreto", name: "dynatest.resources.elemento_pontons.select.tipoPonton.Concreto" },
              { id: "Metálicos o mixtos", name: "dynatest.resources.elemento_pontons.select.tipoPonton.MetalicosMixtos" },
            ]}
            fullWidth formClassName={classes.grid_cont6_f} />
          <NumberInput label="resources.elemento_pontons.fields.numeroCarriles" source="numeroCarriles" fullWidth validate={[required(),Entero ,minValue(1), maxValue(10)]} formClassName={classes.grid_cont6_s} />
          <h3 style={{ marginBottom: 0 }}>{translate('dynatest.resources.elemento_pontons.subTitle.localizacion')}</h3>
          <Divider fullWidth />
          <ReferenceInput
            label="resources.elemento_pontons.fields.unidadFuncional"
            source="unidadFuncional"
            reference="unidad_funcionals"
            filterToQuery={searchText => ({ codigo: searchText })}
            sort={{ field: 'codigo', order: 'asc' }}
            validate={[required(), ValidarUnidad]}
            fullWidth
            formClassName={classes.grid_cont4s}
          >
            <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
          </ReferenceInput>
          {unidads &&
            <ReferenceInput
              label="resources.elemento_pontons.fields.tramo"
              source="tramo"
              alwaysOn
              reference="tramos"
              filter={{ unidadFuncional: unidads }}
              filterToQuery={searchText => ({ codigo: searchText })}
              sort={{ field: 'codigo', order: 'ASC', }}
              validate={[required(), ValidarTramo]}
              fullWidth
              formClassName={classes.grid_cont4}
            >
              <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
            </ReferenceInput>
          }

          {tramos &&
            <ReferenceInput
              label="resources.elemento_pontons.fields.segmento"
              source="segmento"
              reference="segmentos"
              sort={{ field: 'codigo', order: 'ASC' }}
              filter={{ tramo: tramos }}
              filterToQuery={searchText => ({ codigo: searchText })}
              validate={[required(), ValidarSegmento]}
              formClassName={classes.grid_cont4}
              fullWidth
            >

              <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
            </ReferenceInput>
          }
          {/* ELEMENTOS OCULTOS */}
          {segmentos && <AbsSegmento segmento={segmentos} />}
          {/* ----------------- */}
          {segmentos &&
            <Grid container spacing={24}>
              <Typography variant="h3" component="h3" className={classes.coord}>{translate('dynatest.resources.elemento_pontons.subTitle.coordenadasIniciales')}</Typography>
              <Divider fullWidth formClassName={classes.grid_cont_div} />
            </Grid>
          }
          {segmentos && <PRInput label="resources.elemento_pontons.fields.abscisa" id="ingresoAbInicio" source="abInicio" fullWidth validate={[abValidationInicio, required()]} formClassName={classes.grid_cont3s} />}
          {segmentos && <PInput label="resources.elemento_pontons.fields.PR" id="ingresoPrInicio" source="pInicio" fullWidth validate={[prValidationInicio]} formClassName={classes.grid_cont3} />}
          {segmentos && <NumberInput label="resources.elemento_pontons.fields.latitud" id="LatitudInicial" source="ubicaciones[0][lat]" fullWidth validate={[required(), latMininima, LatitudMinimaArray]} formClassName={classes.grid_cont3} />}
          {segmentos && <NumberInput label="resources.elemento_pontons.fields.longitud" id="LongitudInicial" source="ubicaciones[0][lng]" fullWidth validate={[required(), lngMininima, LongitudMinimaArray]} formClassName={classes.grid_cont3} />}
          {segmentos &&
            <Grid container spacing={24}>
              <Typography variant="h3" component="h3" className={classes.coord}>{translate('dynatest.resources.elemento_pontons.subTitle.coordenadasFinales')}</Typography>
              <Divider fullWidth formClassName={classes.grid_cont_div} />
            </Grid>
          }
          {segmentos && <PRInput label="resources.elemento_pontons.fields.abscisa" id="ingresoAbFin" source="abFin" fullWidth validate={[abValidationFin]} formClassName={classes.grid_cont3s} />}
          {segmentos && <PInput label="resources.elemento_pontons.fields.PR" id="ingresoAbFin" source="pFin" fullWidth validate={[prValidationFin]} formClassName={classes.grid_cont3}/>}
          {segmentos && <NumberInput label="resources.elemento_pontons.fields.latitud" source="ubicaciones[1][lat]" fullWidth validate={[latMininima, LatitudMaximaArray]} formClassName={classes.grid_cont3} />}
          {segmentos && <NumberInput label="resources.elemento_pontons.fields.longitud" source="ubicaciones[1][lng]" fullWidth validate={[lngMininima, LongitudMaximaArray]} formClassName={classes.grid_cont3} />}
        </FormTab>
        <FormTab label="dynatest.resources.elemento_pontons.tabs.tabsTitle.datosAdministrativos">
          <h3 style={{ marginBottom: 0 }}>{translate('dynatest.resources.elemento_pontons.subTitle.detallesConstructivos')}</h3><Divider fullWidth />
          <DateInput label="resources.elemento_pontons.fields.ano" source="ano" validate={[isFechaInvalida]} fullWidth formClassName={classes.grid_cont3_f} />
          <NumberInput label="resources.elemento_pontons.fields.area" source="area" validate={[minValue(0)]} fullWidth formClassName={classes.grid_cont3_s} />
          <TextInput label="resources.elemento_pontons.fields.norma" source="norma" fullWidth validate={[minLength(1), maxLength(60)]} formClassName={classes.grid_cont3_s} />
          <TextInput label="resources.elemento_pontons.fields.disenador" source="disenador" fullWidth validate={[minLength(1), maxLength(60)]} formClassName={classes.grid_cont3_s} />
          <TextInput label="resources.elemento_pontons.fields.info_di" source="info_di" fullWidth validate={[minLength(1), maxLength(200)]} />
          <h3 style={{ marginBottom: 0 }}>{translate('dynatest.resources.elemento_pontons.subTitle.detRefuerzoRehabilit')}</h3><hr fullWidth />
          <DateInput label="resources.elemento_pontons.fields.anoa" source="anoa" validate={[isFechaInvalida]} fullWidth formClassName={classes.grid_cont3_f} />
          <NumberInput label="resources.elemento_pontons.fields.areaa" source="areaa" validate={[minValue(0), validarDosDecimales]} fullWidth formClassName={classes.grid_cont3_s} />
          <TextInput label="resources.elemento_pontons.fields.normaa" source="normaa" validate={[minLength(1), maxLength(60)]} fullWidth formClassName={classes.grid_cont3_s} />
          <TextInput label="resources.elemento_pontons.fields.disenadora" source="disenadora" validate={[minLength(1), maxLength(60)]} fullWidth formClassName={classes.grid_cont3_s} />
          <TextInput label="resources.elemento_pontons.fields.info_dia" source="info_dia" validate={[minLength(1), maxLength(200)]} fullWidth />
          <div></div>
          <TextInput label="resources.elemento_pontons.fields.obser_dia" source="obser_dia" fullWidth validate={[minLength(1), maxLength(200)]} />
          <h3 style={{ marginBottom: 0 }}>{translate('dynatest.resources.elemento_pontons.subTitle.detallesInspeccion')}</h3><Divider fullWidth />
          <DateInput label="resources.elemento_pontons.fields.anob" source="anob" validate={[isFechaInvalida]} fullWidth formClassName={classes.grid_cont4_f} />
          <SelectInput label="resources.elemento_pontons.fields.sentido" source="sentido"
            choices={[
              { id: 'S', name: 'dynatest.resources.elemento_pontons.select.sentido.S' },
              { id: 'N', name: 'dynatest.resources.elemento_pontons.select.sentido.N' },
              { id: 'E', name: 'dynatest.resources.elemento_pontons.select.sentido.E' },
              { id: 'O', name: 'dynatest.resources.elemento_pontons.select.sentido.O' },
            ]}
            fullWidth formClassName={classes.grid_cont4_s} />
          <ReferenceInput label="resources.elemento_pontons.fields.estacion_conteo" source="estacion_conteo" reference="estacion_conteos" fullWidth formClassName={classes.grid_cont4_s}>
            <SelectInput optionText="nombre" />
          </ReferenceInput>
          <TextInput label="resources.elemento_pontons.fields.inspector" source="inspector" validate={[minLength(1), maxLength(30)]} fullWidth formClassName={classes.grid_cont4_f} />
          <NumberInput label="resources.elemento_pontons.fields.inspeccion" source="inspeccion" validate={[minValue(0), maxValue(99999999), validarEnteroNT]} fullWidth formClassName={classes.grid_cont4_s} />
          <NumberInput label="resources.elemento_pontons.fields.numinspeccion" source="numinspeccion" validate={[minValue(0), maxValue(99999999), validarEnteroNT]} fullWidth formClassName={classes.grid_cont4_s} />
          <h3 style={{ marginBottom: 0 }}>{translate('dynatest.resources.elemento_pontons.subTitle.miembrosInteresados')}</h3><Divider fullWidth />
          <TextInput label="resources.elemento_pontons.fields.propietario" source="propietario" fullWidth validate={[minLength(1), maxLength(20)]} formClassName={classes.grid_cont4_f} />
          <TextInput label="resources.elemento_pontons.fields.admon_vial" source="admon_vial" fullWidth validate={[minLength(1), maxLength(20)]} formClassName={classes.grid_cont4_s} />
          <TextInput label="resources.elemento_pontons.fields.proyectista" source="proyectista" fullWidth validate={[minLength(1), maxLength(30)]} formClassName={classes.grid_cont4_s} />
          <h3 style={{ marginBottom: 0 }}>{translate('dynatest.resources.elemento_pontons.subTitle.caractOperacionales')}</h3><Divider fullWidth />
          <DInput label="resources.elemento_pontons.fields.coeficiente" className="align-number" source="coeficiente" validate={[minValue(0), maxValue(1)]} fullWidth formClassName={classes.grid_cont6_f} />
          <SelectInput label="resources.elemento_pontons.fields.cauce" source="cauce"
            choices={[
              { id: 'S', name: 'dynatest.resources.elemento_pontons.select.selectSorN.S' },
              { id: 'N', name: 'dynatest.resources.elemento_pontons.select.selectSorN.N' }
            ]}
            fullWidth formClassName={classes.grid_cont6_s} />
          <SelectInput label="resources.elemento_pontons.fields.variante" source="variante"
            choices={[
              { id: 'S', name: 'dynatest.resources.elemento_pontons.select.selectSorN.S' },
              { id: 'N', name: 'dynatest.resources.elemento_pontons.select.selectSorN.N' }
            ]}
            fullWidth formClassName={classes.grid_cont4_f} />
          <FormDataConsumer formClassName={classes.grid_cont4_s}>
            {({ formData, ...rest }) => formData.variante === "S" && (
              <NumberInput label="resources.elemento_pontons.fields.long_variante" source="long_variante" validate={[minValue(0), maxValue(99999999), validarTresDecimales]} fullWidth />
            )

            }
          </FormDataConsumer>
          <FormDataConsumer formClassName={classes.grid_cont4_s}>
            {({ formData, ...rest }) => formData.variante === "S" && (
              <SelectInput label="resources.elemento_pontons.fields.esta" source="esta"
                choices={[
                  { id: 'B', name: 'dynatest.resources.elemento_pontons.select.esta.b' },
                  { id: 'R', name: 'dynatest.resources.elemento_pontons.select.esta.r' },
                  { id: 'M', name: 'dynatest.resources.elemento_pontons.select.esta.m' },

                ]}
                fullWidth />
            )

            }
          </FormDataConsumer>
          <h3 style={{ marginBottom: 0 }}>{translate('dynatest.resources.elemento_pontons.subTitle.capacidadCargaTransitoLegal')}</h3><br />
          <Divider fullWidth />
          <TextInput label="resources.elemento_pontons.fields.vehi_dise" source="vehi_dise" fullWidth validate={[minLength(0), maxLength(10)]} formClassName={classes.grid_cont4_f} />
          <TextInput label="resources.elemento_pontons.fields.distri_carga" source="distri_carga" fullWidth validate={[minLength(0), maxLength(20)]} formClassName={classes.grid_cont4_s} />
          <DInput label="resources.elemento_pontons.fields.capa_carga" source="capa_carga" fullWidth className="align-number" formClassName={classes.grid_cont4_s} />
          <DInput label="resources.elemento_pontons.fields.luz_critica" source="luz_critica" fullWidth className="align-number" formClassName={classes.grid_cont4_f} />
          <DInput label="resources.elemento_pontons.fields.fac_clasi" source="fac_clasi" fullWidth className="align-number" formClassName={classes.grid_cont4_s} />
          <h3 style={{ marginBottom: 0 }}>{translate('dynatest.resources.elemento_pontons.subTitle.capacidadCargaTranspEspeciales')}</h3>
          <Divider fullWidth />
          <TextInput label="resources.elemento_pontons.fields.fue_cor" source="fue_cor" fullWidth validate={[required(), minValue(0, "Debe ser al menos 0."), validarDosDecimales]} className="align-number" formClassName={classes.grid_cont4_f} />
          <DInput label="resources.elemento_pontons.fields.momento" source="momento" className="align-number" fullWidth validate={[required(), minValue(0, "Debe ser al menos 0."), validarDosDecimales]} formClassName={classes.grid_cont4_s} />
          <DInput label="resources.elemento_pontons.fields.linea_carga" source="linea_carga" fullWidth className="align-number" formClassName={classes.grid_cont4_s} />
          <TextInput label="resources.elemento_pontons.fields.obs_1" source="obs_1" fullWidth validate={[required(), minLength(1), maxLength(200)]} />
        </FormTab>
        <FormTab label="dynatest.resources.elemento_pontons.tabs.tabsTitle.geometria">
          <h3 style={{ marginBottom: 0 }}>{translate('dynatest.resources.elemento_pontons.subTitle.general')}</h3><Divider fullWidth />
          <SelectInput label="resources.elemento_pontons.fields.pon_terra" source="pon_terra" validate={[required()]}
            choices={[
              { id: 'S', name: 'dynatest.resources.elemento_pontons.select.selectSorN.S' },
              { id: 'N', name: 'dynatest.resources.elemento_pontons.select.selectSorN.N' }
            ]}
            fullWidth formClassName={classes.grid_cont6_f} />
          <SelectInput label="resources.elemento_pontons.fields.pon_c" source="pon_c" validate={[required()]}
            choices={[
              { id: 'T', name: 'dynatest.resources.elemento_pontons.select.pon_c.T' },
              { id: 'C', name: 'dynatest.resources.elemento_pontons.select.pon_c.C' },
            ]}
            fullWidth formClassName={classes.grid_cont6_s} />
          <h3 style={{ marginBottom: 0 }}>{translate('dynatest.resources.elemento_pontons.subTitle.detallesEstructura')}</h3><Divider fullWidth />
          <DInput label="resources.elemento_pontons.fields.long_2" source="long_2" fullWidth validate={[required(), minValue(0), maxValue(15), validarDosDecimales]} className="align-number" formClassName={classes.grid_cont4_f} />
          <DInput label="resources.elemento_pontons.fields.ancho_2" source="ancho_2" fullWidth validate={[required(), minValue(0), maxValue(200), validarDosDecimales]} className="align-number" formClassName={classes.grid_cont4_s} />
          <DInput label="resources.elemento_pontons.fields.espe_2" source="espe_2" fullWidth validate={[required(), minValue(0), maxValue(20), validarDosDecimales]} className="align-number" formClassName={classes.grid_cont4_s} />
          <DInput label="resources.elemento_pontons.fields.altu_2" source="altu_2" fullWidth validate={[required(), minValue(0), maxValue(20), validarDosDecimales]} className="align-number" formClassName={classes.grid_cont4_f} />
          <DInput label="resources.elemento_pontons.fields.ancho_3" source="ancho_3" fullWidth validate={[required(), minValue(0), maxValue(20), validarDosDecimales]} className="align-number" formClassName={classes.grid_cont4_s} />
          <DInput label="resources.elemento_pontons.fields.espe_3" source="espe_3" fullWidth validate={[required(), minValue(0), maxValue(200), validarDosDecimales]} className="align-number" formClassName={classes.grid_cont4_s} />
          <DInput label="resources.elemento_pontons.fields.pro_3" source="pro_3" fullWidth validate={[required(), minValue(0), maxValue(20), validarDosDecimales]} className="align-number" formClassName={classes.grid_cont4_f} />
          <DInput label="resources.elemento_pontons.fields.espe_4" source="espe_4" fullWidth validate={[required(), minValue(0), maxValue(20), validarDosDecimales]} className="align-number" formClassName={classes.grid_cont4_s} />
          <DInput label="resources.elemento_pontons.fields.sepa_4" source="sepa_4" fullWidth validate={[required(), minValue(0), maxValue(20), validarDosDecimales]} className="align-number" formClassName={classes.grid_cont4_s} />
        </FormTab>
        <FormTab label="dynatest.resources.elemento_pontons.tabs.tabsTitle.reparaciones">
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{translate('dynatest.resources.elemento_pontons.table.fieldsHead.Componente')}</TableCell>
                <TableCell align="right">{translate('dynatest.resources.elemento_pontons.table.fieldsHead.Aplica')}</TableCell>
                <TableCell align="right">{translate('dynatest.resources.elemento_pontons.table.fieldsHead.FechaReparacion')}</TableCell>
                <TableCell align="right">{translate('dynatest.resources.elemento_pontons.table.fieldsHead.Tipo')}</TableCell>
                <TableCell align="right">{translate('dynatest.resources.elemento_pontons.table.fieldsHead.Cantidad')}</TableCell>
                <TableCell align="right">{translate('dynatest.resources.elemento_pontons.table.fieldsHead.Anio')}</TableCell>
                <TableCell align="right">{translate('dynatest.resources.elemento_pontons.table.fieldsHead.Costo')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow >
                <TableCell component="th">{translate('dynatest.resources.elemento_pontons.table.titleRows.SuperficiePonton')}</TableCell>
                <TableCell align="right"><BooleanInput label="" source="aplica1" fullWidth /></TableCell>
                <TableCell align="right"><DateInput label="" source="fecha1" validate={[isFechaInvalida]} fullWidth /></TableCell>
                <TableCell align="right"><TextInput label="" source="text1" fullWidth /></TableCell>
                <TableCell align="right"><DInput label="" source="cant1" className="align-number" fullWidth /></TableCell>
                <TableCell align="right"><SelectInput label="" source="sel13" choices={choices} /></TableCell>
                <TableCell align="right"><AInput label="" source="precio1" fullWidth /></TableCell>
              </TableRow>
              <TableRow >
                <TableCell component="th">{translate('dynatest.resources.elemento_pontons.table.titleRows.AndenesBordillos')}</TableCell>
                <TableCell align="right"><BooleanInput label="" source="aplica2" fullWidth /></TableCell>
                <TableCell align="right"><DateInput label="" source="fecha2" validate={[isFechaInvalida]} fullWidth /></TableCell>
                <TableCell align="right"><TextInput label="" source="text2" fullWidth /></TableCell>
                <TableCell align="right"><DInput label="" source="cant2" className="align-number" fullWidth /></TableCell>
                <TableCell align="right"><SelectInput label="" source="sel2" choices={choices} /></TableCell>
                <TableCell align="right"><AInput label="" source="precio2" fullWidth /></TableCell>
              </TableRow>
              <TableRow >
                <TableCell component="th">{translate('dynatest.resources.elemento_pontons.table.titleRows.Barandas')}</TableCell>
                <TableCell align="right"><BooleanInput label="" source="aplica3" fullWidth /></TableCell>
                <TableCell align="right"><DateInput label="" source="fecha3" validate={[isFechaInvalida]} fullWidth /></TableCell>
                <TableCell align="right"><TextInput label="" source="text3" fullWidth /></TableCell>
                <TableCell align="right"><DInput label="" source="cant3" className="align-number" fullWidth /></TableCell>
                <TableCell align="right"><SelectInput label="" source="sel3" choices={choices} /></TableCell>
                <TableCell align="right"><AInput label="" source="precio3" fullWidth /></TableCell>
              </TableRow>
              <TableRow >
                <TableCell component="th">{translate('dynatest.resources.elemento_pontons.table.titleRows.ConosTaludes')}</TableCell>
                <TableCell align="right"><BooleanInput label="" source="aplica14" fullWidth /></TableCell>
                <TableCell align="right"><DateInput label="" source="fecha14" validate={[isFechaInvalida]} fullWidth /></TableCell>
                <TableCell align="right"><TextInput label="" source="text14" fullWidth /></TableCell>
                <TableCell align="right"><DInput label="" source="cant14" className="align-number" fullWidth /></TableCell>
                <TableCell align="right"><SelectInput label="" source="sel14" choices={choices} /></TableCell>
                <TableCell align="right"><AInput label="" source="precio14" fullWidth /></TableCell>
              </TableRow>
              <TableRow >
                <TableCell component="th">{translate('dynatest.resources.elemento_pontons.table.titleRows.ElementosArmadura')}</TableCell>
                <TableCell align="right"><BooleanInput label="" source="aplica15" fullWidth /></TableCell>
                <TableCell align="right"><DateInput label="" source="fecha15" validate={[isFechaInvalida]} fullWidth /></TableCell>
                <TableCell align="right"><TextInput label="" source="text15" fullWidth /></TableCell>
                <TableCell align="right"><DInput label="" source="cant15" className="align-number" fullWidth /></TableCell>
                <TableCell align="right"><SelectInput label="" source="sel15" choices={choices} /></TableCell>
                <TableCell align="right"><AInput label="" source="precio15" fullWidth /></TableCell>
              </TableRow>
              <TableRow >
                <TableCell component="th">{translate('dynatest.resources.elemento_pontons.table.titleRows.Cauce')}</TableCell>
                <TableCell align="right"><BooleanInput label="" source="aplica16" fullWidth /></TableCell>
                <TableCell align="right"><DateInput label="" source="fecha16" validate={[isFechaInvalida]} fullWidth /></TableCell>
                <TableCell align="right"><TextInput label="" source="text16" fullWidth /></TableCell>
                <TableCell align="right"><DInput label="" source="cant16" className="align-number" fullWidth /></TableCell>
                <TableCell align="right"><SelectInput label="" source="sel16" choices={choices} /></TableCell>
                <TableCell align="right"><AInput label="" source="precio16" fullWidth /></TableCell>
              </TableRow>
              <TableRow >
                <TableCell component="th">{translate('dynatest.resources.elemento_pontons.table.titleRows.LosaSuperior')}</TableCell>
                <TableCell align="right"><BooleanInput label="" source="aplica17" fullWidth /></TableCell>
                <TableCell align="right"><DateInput label="" source="fecha17" validate={[isFechaInvalida]} fullWidth /></TableCell>
                <TableCell align="right"><TextInput label="" source="text17" fullWidth /></TableCell>
                <TableCell align="right"><DInput label="" source="cant17" className="align-number" fullWidth /></TableCell>
                <TableCell align="right"><SelectInput label="" source="sel17" choices={choices} /></TableCell>
                <TableCell align="right"><AInput label="" source="precio17" fullWidth /></TableCell>
              </TableRow>
              <TableRow >
                <TableCell component="th">{translate('dynatest.resources.elemento_pontons.table.titleRows.LosaInferior')}</TableCell>
                <TableCell align="right"><BooleanInput label="" source="aplica18" fullWidth /></TableCell>
                <TableCell align="right"><DateInput label="" source="fecha18" validate={[isFechaInvalida]} fullWidth /></TableCell>
                <TableCell align="right"><TextInput label="" source="text18" fullWidth /></TableCell>
                <TableCell align="right"><DInput label="" source="cant18" className="align-number" fullWidth /></TableCell>
                <TableCell align="right"><SelectInput label="" source="sel18" choices={choices} /></TableCell>
                <TableCell align="right"><AInput label="" source="precio18" fullWidth /></TableCell>
              </TableRow>
              <TableRow >
                <TableCell component="th">{translate('dynatest.resources.elemento_pontons.table.titleRows.Muros')}</TableCell>
                <TableCell align="right"><BooleanInput label="" source="aplica19" fullWidth /></TableCell>
                <TableCell align="right"><DateInput label="" source="fecha19" validate={[isFechaInvalida]} fullWidth /></TableCell>
                <TableCell align="right"><TextInput label="" source="text19" fullWidth /></TableCell>
                <TableCell align="right"><DInput label="" source="cant19" className="align-number" fullWidth /></TableCell>
                <TableCell align="right"><SelectInput label="" source="sel19" choices={choices} /></TableCell>
                <TableCell align="right"><AInput label="" source="precio19" fullWidth /></TableCell>
              </TableRow>
              <TableRow >
                <TableCell component="th">{translate('dynatest.resources.elemento_pontons.table.titleRows.OtrosElementos')}</TableCell>
                <TableCell align="right"><BooleanInput label="" source="aplica10" fullWidth /></TableCell>
                <TableCell align="right"><DateInput label="" source="fecha10" validate={[isFechaInvalida]} fullWidth /></TableCell>
                <TableCell align="right"><TextInput label="" source="text10" fullWidth /></TableCell>
                <TableCell align="right"><DInput label="" source="cant10" className="align-number" fullWidth /></TableCell>
                <TableCell align="right"><SelectInput label="" source="sel10" choices={choices} /></TableCell>
                <TableCell align="right"><AInput label="" source="precio10" fullWidth /></TableCell>
              </TableRow>
              <TableRow >
                <TableCell component="th">{translate('dynatest.resources.elemento_pontons.table.titleRows.PontonGeneral')}</TableCell>
                <TableCell align="right"><BooleanInput label="" source="aplica21" fullWidth /></TableCell>
                <TableCell align="right"><DateInput label="" source="fecha21" validate={[isFechaInvalida]} fullWidth /></TableCell>
                <TableCell align="right"><TextInput label="" source="text21" fullWidth /></TableCell>
                <TableCell align="right"><DInput label="" source="cant21" className="align-number" fullWidth /></TableCell>
                <TableCell align="right"><SelectInput label="" source="sel21" choices={choices} /></TableCell>
                <TableCell align="right"><AInput label="" source="precio21" fullWidth /></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </FormTab>
        <FormTab label="dynatest.resources.elemento_pontons.tabs.tabsTitle.detalles">
          <SelectInput label="resources.elemento_pontons.fields.estado" source="estado" validate={[required(), ValidarEstado]}
            choices={[
              { id: translate('dynatest.resources.elemento_pontons.select.estado.Existente'), name: "dynatest.resources.elemento_pontons.select.estado.Existente" },
              { id: translate('dynatest.resources.elemento_pontons.select.estado.Reemplazo'), name: "dynatest.resources.elemento_pontons.select.estado.Reemplazo" },
              { id: translate('dynatest.resources.elemento_pontons.select.estado.Nuevo'), name: "dynatest.resources.elemento_pontons.select.estado.Nuevo" },
            ]}
            fullWidth formClassName={classes.grid_cont4s}
          />
          <FormDataConsumer formClassName={classes.grid_cont4}>
            {({ formData, ...rest }) => formData.estado === translate('dynatest.resources.elemento_pontons.select.estado.Reemplazo') && (
              <SelectInput label="resources.elemento_pontons.fields.motivoReemplazo" source="motivoReemplazo" validate={[required()]}
                choices={[
                  { id: translate('dynatest.resources.elemento_pontons.select.motivoReemplazo.Accidente'), name: 'dynatest.resources.elemento_pontons.select.motivoReemplazo.Accidente' },
                  { id: translate('dynatest.resources.elemento_pontons.select.motivoReemplazo.Instalacion'), name: 'dynatest.resources.elemento_pontons.select.motivoReemplazo.Instalacion' },
                  { id: translate('dynatest.resources.elemento_pontons.select.motivoReemplazo.ReemplazoProgramado'), name: 'dynatest.resources.elemento_pontons.select.motivoReemplazo.ReemplazoProgramado' },
                  { id: translate('dynatest.resources.elemento_pontons.select.motivoReemplazo.Vandalismo'), name: 'dynatest.resources.elemento_pontons.select.motivoReemplazo.Vandalismo' },
                  { id: translate('dynatest.resources.elemento_pontons.select.motivoReemplazo.Otro'), name: 'dynatest.resources.elemento_pontons.select.motivoReemplazo.Otro' },
                ]}
                fullWidth />
            )

            }
          </FormDataConsumer>

          <FormDataConsumer formClassName={classes.grid_cont4}>
            {({ formData, ...rest }) => formData.estado === translate('dynatest.resources.elemento_pontons.select.estado.Reemplazo') && (
              <ReferenceInput
                label="resources.elemento_pontons.fields.elementoReemplazo"
                source="elementoReemplazo"
                reference="elemento_pontons"
                filter={{
                  tipoElemento: "PTON",
                  estados : "Activo", 
                  unidadFuncional: unidads,
                  tramo: tramos,
                  segmento : segmentos,
                  exclude : (formData) ? formData.id : null
                }}
                fullWidth
                validate={[required()]}
              >
                <AutocompleteInput options={{ fullWidth: true }} optionText="fullCodigoConsecutivo" />
              </ReferenceInput>

              // <ElementosCreados tabla="elemento_pontons" tipo="editar" fullWidth />
            )

            }
          </FormDataConsumer>
          <br/>
          
          <SelectInput label="resources.elemento_pontons.fields.estados" source="estados" allowEmpty emptyValue="" choices={[
                { id: translate('dynatest.resources.elemento_pontons.select.estados.Activo'), name: "dynatest.resources.elemento_pontons.select.estados.Activo" },
                { id: translate('dynatest.resources.elemento_pontons.select.estados.Inactivo'), name: "dynatest.resources.elemento_pontons.select.estados.Inactivo" },
            ]} validate={[required()]} fullWidth formClassName={classes.grid_cont4_f} />
          <br />
          <DateInput
            label="resources.elemento_pontons.fields.fecha"
            source="fecha"
            fullWidth 
            validate={[validacionmaxfechaact]}
            formClassName={classes.grid_cont4s}
          />
          <ReferenceInput
            label="resources.elemento_pontons.fields.proveedor"
            source="proveedor"
            reference="proveedors"
            filter={{ sistema: 'PTS' }}
            sort={{ field: 'razonSocial', order: 'ASC' }}
            filterToQuery={searchText => ({ razonSocial: searchText })}
            validate={[required()]}
            formClassName={classes.grid_cont4}
            fullWidth
          >
            <AutocompleteInput options={{ fullWidth: true }} optionText="razonSocial" />
          </ReferenceInput>
          <NumberInput
            label="resources.elemento_pontons.fields.garantia"
            source="garantia"
            fullWidth
            validate={[required(), minValue(0), maxValue(99999999), validarEnteroNT]}
            formClassName={classes.grid_cont4}
          />

          <br />
          <BooleanInput label="dynatest.resources.elemento_pontons.boolean.mantenimiento" source="mantenimiento" validate={[required()]} fullWidth formClassName={classes.grid_cont6_f} />
          <br />
          <TextInput label="resources.elemento_pontons.fields.observaciones" source="observaciones" validate={[required(), minLength(1), maxLength(200)]} fullWidth formClassName={classes.grid_cont6_f} />
        </FormTab>
        <FormTab label="dynatest.resources.elemento_pontons.tabs.tabsTitle.fotografias">
            <Grid container spacing={24}>                         
                <Grid item xs={8}>  
                    {/* <ArrayInput label="" source="images" validate={ImagenesEdit} >
                        <SimpleFormIterator disableAdd disableRemove >
                            <ImageInput multiple={true} source="images" label=" " maxSize={1000000000} accept="image/jpeg, image/png">
                                <ImageField source="src" title="title" />
                            </ImageInput>                           
                            <ReferenceInput disabled label="Tipo de elemento" source="tipoImagen" reference="tipo_images" fullWidth validate={[required()]} sort={{ field: 'nombre', order: 'ASC' }}  formClassName={classes.grid_cont6}>
                                <SelectInput  optionText="nombre"  />
                            </ReferenceInput>                          
                        </SimpleFormIterator>
                    </ArrayInput>                             */}
                  <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            <ArrayInputImagesEdit record={formData} {...rest} />                                
                        }
                    </FormDataConsumer> 
                </Grid>  
                <Grid item xs={4}>
                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            <ButtonCarousel record={formData} {...rest} />                                
                        }
                    </FormDataConsumer>  
                </Grid>                                                 
            </Grid>      
            <FormDataConsumer>
                {({ formData, ...rest }) => { formData.updatedIn = "api"; }}
            </FormDataConsumer>                  
        </FormTab>  

      </TabbedForm>
    </Edit>
  )
}));