import React from 'react';
import deFormatter from './../format/DEFormat';

import { TextInput } from 'react-admin';


export const DEInput = (props) => (
    <TextInput 
        {...props} 
        InputProps={{
            inputComponent: deFormatter,
        }}
    />
);

