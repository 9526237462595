import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { changeLocale as changeLocaleAction } from 'react-admin';
import EditIcon from '@material-ui/icons/Edit';
import GTranslateIcon from '@material-ui/icons/GTranslate';
import config from '../../config/config';
import { translate } from 'react-admin';
let urlAppjs = config.UrlServer;

class LocaleSwitcher extends Component {
    constructor(props) {
        super(props)
        this.state = { editar: true }
        this.state.listIdiomas = this.props.list;
        this.state.idiomaSelec = this.props.idioma;
    }
    
    switchToIdioma = (value) => {
        this.props.changeLocale(value);
        this.changeIndioma(value);
        fetch(urlAppjs + "/idiomas?region=" + value, {
            method: 'GET',
            headers:{
                'authorization': 'Bearer '+ localStorage.getItem('token')
            }
        })
        .then(res => res.json())
        .then((success) => {
            if (success['hydra:member'].length > 0) {
                document.getElementById("lengua").innerHTML = success['hydra:member'][0].nombreIdioma;
            }
        });
    }

    changeIndioma = (value) => {
        let idNew = window.localStorage.getItem('id_project');
        let userLogin = window.localStorage.getItem('ucalpro');
        let dataSend = new FormData();
        dataSend.append('id', idNew);
        dataSend.append('idioma', value);
        dataSend.append('userLogin', userLogin);
        fetch(urlAppjs + '/configurarIdioma/', {
            method: 'POST',
            body: dataSend,
            headers: {
                'authorization': { 'authorization': 'Bearer ' + localStorage.getItem('token') }
            }
        })
            .then(response => response.json())
            .then(success => {
                console.log(success);
            });
    }

    render() {
        const { changeLocale } = this.props;
        const { translate } = this.props;
        return (
            <div>
                <Button variant="outlined" color="primary" id="btnConfirmar" onClick={() => this.switchToIdioma(this.state.idiomaSelec)} style={{ border: 0 }} autoFocus >
                    {translate('dynatest.resources.config_sistema.btnConfirmar')}
                </Button>
            </div>
        );
    }
}

export default translate(connect(undefined, { changeLocale: changeLocaleAction })(LocaleSwitcher));