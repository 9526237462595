import React from 'react';
import {SaveButton, SimpleForm as ReactAdminForm, Toolbar } from 'react-admin';
import BackButton from './../BotonCancelar'
import { withStyles } from '@material-ui/core';

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};


const SimpleFormActions = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <SaveButton />
        <BackButton />
    </Toolbar>
));
export const SimpleForm = ReactAdminForm;

SimpleForm.defaultProps = {
   toolbar: <SimpleFormActions />    
};

export default SimpleForm;