import React, { useState, Fragment } from 'react';
import PropTypes, { string } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
    FileField, required, FileInput, DateInput, ReferenceInput, SelectInput, minValue, maxValue,
    TextInput, NumberInput, ArrayInput, SimpleFormIterator, Loading, FormDataConsumer, BooleanInput
} from 'react-admin';
import { styles } from './../../../EstilosGrid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import { Entero, isFechaInvalida } from './../../../helpers/validacionesInputs';
import { sources } from './../localHelpers/GlobalVars'
import config from '../../../config/config';
import { CurrencyInput } from './../../../inputs/CurrencyInput';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import TableWithPagination from "./TableWithPagination"
import { REDUX_FORM_NAME } from 'react-admin';
import { change } from 'redux-form';
import { translate} from 'react-admin'
let urlAppjs = config.UrlServer;

/**
 * @param unidadFuncional
 * @param tramo
 * @param segmento
 * @param tipoElemento = la entidad a apuntar en el fetcher
 * @param abInicio = la abscisa inicial para el filtro
 * @param abFin = la abscisa final para el filtro
 * @param isClone
 * @param valorGlobal
 * 
 */

class AddMasivoCosto extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            aprobados: [],
            desaprobados: [],
            proveedoresDesaprobados: [],
            smartCodeDesaprovado: [],
            valorDesaprobados: [],
            smartCodeList: [],
            datos: [],
            datosArchivo: [],
            datosArchivo2: [],
            isLoading: false,
            fetchingRequests: 0,
            valorGlobal: 0,
            rowToEdit: 0,
            modalProps: {},
            editedRowData: [],
            hasInvalidValues: false,
            abscisasValidacionIni:[],
            abscisasValidacionFin:[]
        }
        this.sources = sources
        this.stagedProps = {
            unidadFuncional: this.props.unidadFuncional,
            tramo: this.props.tramo,
            segmento: this.props.segmento,
            tipoElemento: this.props.tipoElementoSenal,
            tipoElementoSenal: this.props.tipoElemento,
            tipoSenal: this.props.tipoSenal,
            abInicio: this.props.abInicio,
            abFin: this.props.abFin,
            valorGlobal: this.props.valorGlobal,
        }
        this.projectId = window.localStorage.getItem('id_project');
        this.allowedMaintenanceTypes = ["ru", "pe", "re", "ca"]
        this.fetcher.bind(this);
        this.returnElementsComponent.bind(this);
        this.returnFileInput.bind();
        this.formatAbscisa.bind(this);
        this.formDataMerger.bind(this);
        this.setGlobalValue.bind(this);
        this.verificarElementos.bind(this);
        this.verifyId.bind(this);
    }

    componentDidMount() {
        this.forceUpdate();
    }
    componentDidUpdate() {
        if (
            this.stagedProps.unidadFuncional != this.props.unidadFuncional ||
            this.stagedProps.tramo != this.props.tramo ||
            this.stagedProps.segmento != this.props.segmento ||
            this.stagedProps.tipoElemento != this.props.tipoElemento ||
            this.tipoElementoSenal != this.props.tipoElementoSenal ||
            this.tipoSenal != this.props.tipoSenal ||
            this.stagedProps.abInicio != this.props.abInicio ||
            this.stagedProps.abFin != this.props.abFin ||
            this.stagedProps.valorGlobal != this.props.valorGlobal
        ) {
            this.stagedProps.unidadFuncional = this.props.unidadFuncional
            this.stagedProps.tramo = this.props.tramo;
            this.stagedProps.segmento = this.props.segmento;
            this.stagedProps.tipoElemento = this.props.tipoElemento;
            this.tipoElementoSenal = this.props.tipoElementoSenal;
            this.tipoSenal = this.props.tipoSenal;
            this.stagedProps.abInicio = this.props.abInicio;
            this.stagedProps.abFin = this.props.abFin;
            this.stagedProps.valorGlobal = this.props.valorGlobal;

            this.state.aprobados = [];
            this.state.desaprobados = [];
            this.state.abscisasValidacionIni = [];
            this.state.abscisasValidacionFin = [];
            this.state.datos = [];
            this.state.hasInvalidValues = false;

            this.setGlobalValue()

            this.verificarElementos(this.state.datosArchivo);

            this.componentDidMount();
        }
    }
    formatFecha(value) {
        let arrayDate = value.split(" ")[0].split("-")
        let dateObject = new Date(arrayDate[0], arrayDate[1], arrayDate[2])
        let day = dateObject.getDate() < 10 ? `0${dateObject.getDate()}` : dateObject.getDate()
        let month = dateObject.getMonth() < 10 ? `0${dateObject.getMonth()}` : dateObject.getMonth()
        let year = dateObject.getFullYear()
        return `${day}-${month}-${year}`
    }

    fetcher(target, stringId, abInicio, abFin, otherFilters = null, nit = undefined) {
        this.state.fetchingRequests++;
        this.setState({ isLoading: true });
        var urlPart = "/" + target + "?properties[smartCode]" +
            "&properties[unidadFuncional]" +
            "&properties[unidad_funcional]" +
            "&properties[abInicio]" +
            "&properties[abFin]" +
            "&properties[tramo]" +
            "&properties[segmento]" +
            "&properties[tipoElemento]" +
            "&properties[tipoElementoSenal]" +
            "&properties[tipoSenal]" +
            "&costo.unidadFuncional.ruta.proyecto=" + localStorage.getItem("id_project") +
            "&unidadFuncional.ruta.proyecto=" + localStorage.getItem("id_project") +
            "&unidadFuncional=" + this.props.unidadFuncional +
            "&unidad_funcional=" + this.props.unidadFuncional +
            "&tramo=" + this.props.tramo +
            "&smartCode=" + stringId

        if (typeof this.props.tipoElemento !== "undefined" && this.props.tipoElemento !== null) {
            let tipoElementoString = target == "puentes" ? "elementos" : "tipoElemento"
            urlPart = urlPart + `&${tipoElementoString}=${this.props.tipoElemento}`;
        }
        if (
            typeof this.props.tipoElementoSenal !== "undefined" && this.props.tipoElementoSenal !== null
        ) {
            urlPart = urlPart + "&tipoElementoSenal=" + this.props.tipoElementoSenal;
        }

        if (
            typeof this.props.tipoElementoSenal !== "undefined" && this.props.tipoElementoSenal !== null &&
            typeof this.props.tipoSenal !== "undefined" && this.props.tipoSenal !== null
        ) {
            urlPart = urlPart + "&tipoSenal=" + this.props.tipoSenal;
        }
        if(abInicio > 0 && abFin > 0){
            urlPart = urlPart + "&segmento.abInicio[between]=" + abInicio + ".." + abFin;
            urlPart = urlPart + "&segmento.abFin[between]=" + abInicio + ".." + abFin;
        }else if (abInicio > 0) {
            urlPart = urlPart + "&segmento.abInicio[gte]=" + abInicio;
        }else if (abFin > 0) {
            urlPart = urlPart + "&segmento.abFin[lte]=" + abFin;
        }
        

        if (this.props.segmento != null) {
            urlPart = urlPart + "&segmento=" + this.props.segmento;
        }

        if (Array.isArray(otherFilters)) {
            otherFilters.map(filter => {
                urlPart = urlPart + "&" + filter;
            })
        } else if (otherFilters !== null) {
            urlPart = urlPart + "&" + otherFilters;
        }
        let validaSmartCode = false;
        let dataElem = '';
        

        let Abinc = abInicio.split("+");
        Abinc[0] = Abinc[0].replace("K", "");
        let ingresoAbInicio = Abinc[0] + "." + Abinc[1];

        let AbFinc = abFin.split("+");
        AbFinc[0] = AbFinc[0].replace("K", "");
        let ingresoAbFin = AbFinc[0] + "." + AbFinc[1];

        console.log("abscisa final")
        console.log(ingresoAbFin)
        fetch(urlAppjs + urlPart,
            {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                return response.json()
            })
            .then((Elementos) => {
                if (Elementos["hydra:totalItems"] > 0) {
                    validaSmartCode = false;
                    Elementos["hydra:member"].forEach(element => {
                        if(element.smartCode == stringId){
                            validaSmartCode = true;
                            dataElem = element;
                              
                        } 
                    });
                   
                    console.log(dataElem)
                    var id_dato = this.state.datosArchivo.findIndex(item => item.codigo == stringId)
                    this.state.datosArchivo[id_dato].unidadFuncional = (dataElem.unidad_funcional)? dataElem.unidad_funcional["@id"] : dataElem.unidadFuncional["@id"];
                    this.state.datosArchivo[id_dato].tramo = dataElem.tramo["@id"];
                    this.state.datosArchivo[id_dato].segmento = dataElem.segmento["@id"];

                    this.state.datosArchivo[id_dato].tipoElemento = dataElem.tipoElemento ? dataElem.tipoElemento["@id"] : null;
                    this.state.datosArchivo[id_dato].tipoElementoSenal = dataElem.tipoElementoSenal ? dataElem.tipoElementoSenal["@id"] : null;
                    this.state.datosArchivo[id_dato].tipoSenal = dataElem.tipoSenal ? dataElem.tipoSenal["@id"] : null;
                    if(validaSmartCode == true){
                        
                        //formato abscisas
                        let valor1 = dataElem.abInicio - parseInt(dataElem.abInicio);
                        valor1 = parseFloat(valor1).toFixed(3);
                        let resultado1 = "K" + parseInt(dataElem.abInicio) + "+" + valor1.replace("0.", "");
                        
                        let AbInicioConsulta = resultado1.split("+");
                        AbInicioConsulta[0] = AbInicioConsulta[0].replace("K", "");
                        let consultaAbInicio = AbInicioConsulta[0] + "." + AbInicioConsulta[1];

                        let valor2 = dataElem.abFin - parseInt(dataElem.abFin);
                        valor2 = parseFloat(valor2).toFixed(3);
                        let resultado2 = "K" + parseInt(dataElem.abFin) + "+" + valor2.replace("0.", "");
                        
                        let AbFinConsulta = resultado2.split("+");
                        AbFinConsulta[0] = AbFinConsulta[0].replace("K", "");
                        let consultaAbFin = AbFinConsulta[0] + "." + AbFinConsulta[1];

                        console.log("inicio "+consultaAbInicio+ "Fin "+ consultaAbFin)
                        if(consultaAbInicio < ingresoAbInicio){
                            this.state.abscisasValidacionIni.push({ id: stringId })       
                        }else if(consultaAbFin > ingresoAbFin){
                            this.state.abscisasValidacionFin.push({ id: stringId })           
                        } 

                        if (typeof nit == "string") {
                            fetch(
                                `${urlAppjs}/proveedors?proyecto=${localStorage.getItem('id_project')}&numeroIdentificacion=${nit}&mantenimiento=1&sistema=${this.props.sistema}`,
                                { headers: { 'authorization': 'Bearer ' + localStorage.getItem('token') } }
                            )
                                .then((response) => { return response.json() })
                                .then((proveedor) => {
                                    if (proveedor["hydra:totalItems"] > 0) {
                                        var index = this.state.datosArchivo.findIndex(item => item.codigo == stringId)
                                        this.state.datosArchivo[index].proveedor = proveedor["hydra:member"][0]["@id"];
                                        this.state.datosArchivo[index].razonSocial = proveedor["hydra:member"][0].razonSocial;
                                        console.log({
                                            findIndexOf: Elementos["hydra:member"][0].smartCode,
                                            index: index,
                                            proveedorId: proveedor["hydra:member"][0]["@id"],
                                            razonSocial: proveedor["hydra:member"][0].razonSocial
                                        })
                                    } else {
                                        this.state.proveedoresDesaprobados.push({
                                            codigo: stringId,
                                            proveedor: nit
                                        })
                                        this.forceUpdate()
                                    }
                                })
                                .catch(function (error) { console.log(error) })
                                .finally(() => this.forceUpdate())
                        }
                    }else{
                        this.state.smartCodeDesaprovado.push({ id: stringId })
                    }
                } else {
                    this.state.desaprobados.push({ id: stringId })
                }
            })
            .catch(function (error) { console.log(error) })
            .finally(() => {
                this.state.fetchingRequests--;
                this.state.isLoading = false
                this.forceUpdate()
            });
    }

    setGlobalValue() {
        if (this.props.valorGlobal !== null && typeof this.props.valorGlobal !== "undefined") {
            var valorGlobal = (typeof this.props.valorGlobal === "string") ? this.props.valorGlobal.split("$")[1] : this.props.valorGlobal;
            var valorIndividual = parseFloat(valorGlobal / this.state.datosArchivo.length);
            var array = this.state.datosArchivo2;
            array.map((item, index) => {
                array[index].costoMantenimiento = valorIndividual;
            })
            this.state.datosArchivo = array;
            // this.setState({ datosArchivo: array});
        } else {
            this.state.datosArchivo = this.state.datosArchivo2;
            // this.setState({ datosArchivo: this.state.datosArchivo2 });
        }



    }

    returnElementsComponent(classes) {
        const { translate } = this.props;
        var datosArchivo = []
        var idDesaprovado = undefined
        this.state.datosArchivo.map(item => {
            idDesaprovado = this.state.desaprobados.find(desaprovado => desaprovado.id == item.codigo)
            if (typeof idDesaprovado === "undefined") {
                datosArchivo.push(item)
            }
        })
        this.state.datosArchivo = datosArchivo;
        const tipoMantenimientos = [
            { id: "ru", name: translate("dynatest.resources.costos.select.tipoMantenimientos.Rutinario")},
            { id: "pe", name: translate("dynatest.resources.costos.select.tipoMantenimientos.Periodico") },
            { id: "re", name: translate("dynatest.resources.costos.select.tipoMantenimientos.Rehabilitacion") },
            { id: "ca", name: translate("dynatest.resources.costos.select.tipoMantenimientos.Cambio") } 
        ];
        const validarElementos = (array) => {
            var sumaValorGlobal = 0;
            array.map(item => {
                sumaValorGlobal += typeof item.costoMantenimiento == "string" ? parseFloat(item.costoMantenimiento.toString().replaceAll("$", "").replaceAll(".", "").replaceAll(",", ".")) : 0;
            })
            this.setState({ valorGlobal: sumaValorGlobal })
            // console.log(sumaValorGlobal)
            if (this.state.desaprobados.length > 0) {
                return translate("resources.costos.algunosElementos");
            }
        }

        const crearFecha = (string) => {
            console.log("crearFecha")
            console.log(string)
            if (string) {
                var splitted = string.split("/");
                var dateString = ""
                console.log(splitted.length)
                if (splitted.length == 3) {
                    if (splitted[2].length == 4) {
                        var date = new Date(splitted[2], (parseInt(splitted[1]) - 1), splitted[0])
                        dateString = splitted[2] + "-" + (parseInt(splitted[1])) + "-" + splitted[0]
                    } else {
                        var date = new Date(splitted[0], (parseInt(splitted[1]) - 1), splitted[1])
                        dateString = splitted[0] + "-" + (parseInt(splitted[1])) + "-" + splitted[2]
                    }
                    console.log(dateString)
                    var dateValidation = isFechaInvalida(dateString)
                    console.log(dateValidation)
                    if (typeof dateValidation == "undefined") {
                        return date;
                    } else {
                        return new Date(undefined)
                    }
                } else {
                    return new Date(undefined)
                }
            } else {
                return new Date(undefined)
            }
        }

        const prepareDatosArchivos = (txt, isGlobal = false) => {
            this.setState({ datosArchivo: [], desaprobados: [], proveedoresDesaprobados: [], smartCodeDesaprovado: [], valorDesaprobados: [], smartCodeList: [], abscisasValidacionIni: [], abscisasValidacionFin:[] });
            var array = [];
            var row = [];
            var sumaValorGlobal = 0;
            if (txt && typeof txt == "string") {
                row = txt.split("\n");
                row.map((item, index) => {
                    if (index != 0 && item != "") {
                        sumaValorGlobal += parseFloat(item.split(";")[3]);
                        array.push({
                            codigo: item.split(";")[0],
                            tipoMantenimiento: item.split(";")[1],
                            tipoMantenimientoLabel: tipoMantenimientos.find(mant => mant.id == item.split(";")[1]) ? tipoMantenimientos.find(mant => mant.id == item.split(";")[1]).name : null,
                            fechaMantenimiento: crearFecha(item.split(";")[2]),
                            fechaMantenimientoOriginalString: item.split(";")[2],
                            costoMantenimiento: parseFloat(item.split(";")[3]),
                            calificacion: item.split(";")[4],
                            observaciones: item.split(";")[5],
                            nit: item.split(";")[6],
                            proveedor: "",
                            razonSocial: "",
                            unidadFuncional: "",
                            tramo: "",
                            segmento: "",
                            tipoElemento: "",
                            tipoElementoSenal: "",
                            tipoSenal: "",
                        })
                    }
                })
                this.state.hasInvalidValues = false
                this.setState({ datosArchivo: array });
                this.verificarElementos(array);
            }
            this.setState({ valorGlobal: sumaValorGlobal })
        }
        return (
            <Fragment>
                {this.returnFileInput()}
                <FormDataConsumer>
                    {({ formData, dispatch, ...rest }) => {
                        if (formData && formData.archivoCarga && formData.archivoCarga.src) {
                            var blobUrl = formData.archivoCarga.src;
                            fetch(blobUrl)
                                .then((res) => {
                                    return res.blob()
                                })
                                .then((blob) => {
                                    var myReader = new FileReader();
                                    myReader.readAsText(blob);
                                    myReader.onload = function (e) {
                                        var txt = myReader.result;
                                        (formData.isGlobalValue) ? prepareDatosArchivos(txt, true) : prepareDatosArchivos(txt)
                                    };
                                })
                                .then(() => { formData.archivoCarga = null; })
                        }
                    }}
                </FormDataConsumer>
                {this.state.desaprobados.length == 0 && this.state.proveedoresDesaprobados.length == 0 && this.state.hasInvalidValues == false && this.state.smartCodeDesaprovado.length == 0 && this.state.valorDesaprobados.length == 0 && this.state.abscisasValidacionIni.length == 0 && this.state.abscisasValidacionFin.length == 0 &&
                    <Fragment>
                        <CurrencyInput source="valorGlobal" disabled defaultValue={this.state.valorGlobal} fullWidth style={{ display: "none" }} validate={[required()]} />
                        <FormDataConsumer>
                            {({ formData, dispatch, ...rest }) => (
                                <Fragment>
                                    {formData.costoElementos && formData.costoElementos.length > 0 &&
                                        <Fragment>
                                            <TableWithPagination
                                                label="Datos"
                                                identityColumn="codigo"
                                                columnNames={[
                                                    { id: 'codigo', name: translate("resources.costos.fields.codigo") },
                                                    { id: 'tipoMantenimientoLabel', name: translate("resources.costos.fields.tipoMantenimiento") },
                                                    { id: 'fechaMantenimientoOriginalString', name: translate("resources.costos.fields.fechaMantenimiento") },
                                                    { id: 'costoMantenimiento', name: translate("resources.costos.fields.costoMantenimiento") },
                                                    { id: 'razonSocial', name: translate("resources.costos.fields.proveedor")},
                                                    { id: 'calificacion', name: translate("resources.costos.fields.calificacion") },
                                                    { id: 'observaciones', name: translate("resources.costos.fields.observaciones") },
                                                ]}
                                                formatColumn={[
                                                    { id: 'costoMantenimiento', formatTo: 'currency' },
                                                    { id: 'calificacion', formatTo: "number" }
                                                ]}
                                                arrayData={this.state.datosArchivo}
                                            />

                                        </Fragment>

                                    }
                                    <ArrayInput
                                        source="costoElementos"
                                        style={{
                                            width: '100%',
                                            display: "none"
                                        }}
                                        label=" "
                                        defaultValue={this.state.datosArchivo}
                                        className="inventariosSegmentos"
                                        validate={[
                                            required(),
                                            validarElementos,
                                            () => {
                                                dispatch(change(REDUX_FORM_NAME, 'valorGlobal', `${this.state.valorGlobal}`))
                                                return ""
                                            }
                                        ]}
                                    >
                                        <SimpleFormIterator
                                            disableAdd
                                            disableRemove
                                        >
                                            <TextInput disabled label="resources.costos.fields.codigo" source="codigo" fullWidth formClassName={classes.fieldCol9} validate={[required()]} />
                                            <SelectInput disabled label="resources.costos.fields.tipoMantenimiento" source="tipoMantenimiento" fullWidth formClassName={classes.fieldCol3} validate={[required()]} choices={tipoMantenimientos} />
                                            <DateInput disabled label="resources.costos.fields.fechaMantenimiento" source="fechaMantenimiento" fullWidth formClassName={classes.fieldCol3} validate={[required()]} />
                                            <CurrencyInput disabled label="resources.costos.fields.costoMantenimiento" source="costoMantenimiento" fullWidth formClassName={classes.fieldCol3} validate={[required()]} />
                                            <TextInput disabled label="resources.costos.fields.proveedor" source="proveedor" fullWidth formClassName={classes.fieldCol3} />
                                            <NumberInput disabled label="resources.costos.fields.calificacion" source="calificacion" fullWidth formClassName={classes.fieldCol3} validate={[required(), minValue(1), maxValue(5), Entero]} />
                                            <TextInput disabled label="resources.costos.fields.unidadFuncional" source="unidadFuncional" fullWidth formClassName={classes.fieldCol3} />
                                            <TextInput disabled label="resources.costos.fields.tramo" source="tramo" fullWidth formClassName={classes.fieldCol3} />
                                            <TextInput disabled label="resources.costos.fields.segmento" source="segmento" fullWidth formClassName={classes.fieldCol3} />

                                            <TextInput disabled label="resources.costos.fields.tipoElementoSenal" source="tipoElementoSenal" fullWidth formClassName={classes.fieldCol3} />
                                            <TextInput disabled label="resources.costos.fields.tipoElemento" source="tipoElemento" fullWidth formClassName={classes.fieldCol3} />
                                            <TextInput disabled label="resources.costos.fields.tipoSenal" source="tipoSenal" fullWidth formClassName={classes.fieldCol3} />
                                            
                                            <TextInput disabled label="resources.costos.fields.observaciones" source="observaciones" fullWidth formClassName={classes.fieldCol12} validate={[required()]} />
                                            <br />
                                        </SimpleFormIterator>
                                    </ArrayInput>
                                </Fragment>
                            )}
                        </FormDataConsumer>
                    </Fragment>
                }
                <FormDataConsumer>
                    {({ formData, dispatch, ...rest }) => (
                        <Fragment>
                            {/* {console.log(formData.costoElementos)} */}
                            {formData.costoElementos && formData.costoElementos.length == 0 &&
                                <ArrayInput label=" " source="emptyData" validate={[() => { return translate('resources.costos.noCrear')}]} >
                                    <SimpleFormIterator disableAdd disableRemove  >
                                        <TextInput disabled label="resources.costos.fields.tipoElemento" source="tipoElemento" fullWidth formClassName={classes.fieldCol12} />
                                    </SimpleFormIterator>
                                </ArrayInput>
                            }
                        </Fragment>
                    )}
                </FormDataConsumer>
            </Fragment>
        )
    }

    returnFileInput() {
        return (
            <FileInput source="archivoCarga" label="resources.proyectos.fields.cargarArchivo" accept=".csv" multiple={false} >
                <FileField source="src" title="title" />
            </FileInput>
        )
    }

    formatAbscisa(abscisa) {
        if (typeof abscisa == "string") {
            let Abinc = abscisa.split("+");
            Abinc[0] = Abinc[0].replace("K", "");
            let ingresoAbInicio = Abinc[0] + "." + Abinc[1];

            return ingresoAbInicio;
        }
        else {
            return abscisa;
        }
    }

    formDataMerger(formDataItem, aditionalObject) {
        if (this.props.isClone) {
            var normal = [];
            formDataItem.map((item, index) => {
                normal.push({ ...item, ...aditionalObject[index] })
            })
            return normal
        } else {
            return aditionalObject;
        }
    }

    verificarElementos(array) {
        const { translate } = this.props;
        if (array.length != 0) {
           // console.log(array);
            this.state.aprobados = []
            this.state.desaprobados = []
            this.state.valorDesaprobados = []
            let value = 0;
            array.map(item => {
                this.verifyId({
                    elementId: item.codigo,
                    nit: item.nit
                })
                console.log(item.costoMantenimiento)
                value = parseFloat(item.costoMantenimiento.toString().replaceAll("$", "").replaceAll(".", "").replaceAll(",", "."));
                if(isNaN(value)){
                    this.state.valorDesaprobados.push({ id: item.codigo, err: translate('resocurces.costos.valorItem') })
                }
                if(item.observaciones == '' || item.observaciones == null){
                    this.state.valorDesaprobados.push({ id: item.codigo, err: translate('resocurces.costos.obsObligatoria') })
                }
                
                if(this.state.smartCodeList.includes(item.codigo)){
                    this.state.valorDesaprobados.push({ id: item.codigo, err: translate('resocurces.costos.codigoRepetido') })
                }else{
                    this.state.smartCodeList.push(item.codigo);
                }
                
            })
        }
    }
    verifyValue(props) {

    }
    verifyId(props) {
        
        if (this.props.sistema === "/sistemas/CLZ" ) {
            var targetCase = this.props.tipoElemento.split("/")[2];
        }else if(this.props.sistema === "/sistemas/SEN"){
            var targetCase = this.props.sistema.split("/")[2];
        } else {
            var targetCase = props.elementId.split("::")[props.elementId.split("::").length - 2]
        }
        // console.log({
        //     target: this.sources[targetCase],
        //     id: props.elementId,
        // })

        if (this.props.sistema === "/sistemas/CLZ" || this.props.sistema === "/sistemas/PTS") {

            if (typeof this.props.tipoElemento == "string" && this.sources[targetCase] == this.sources[this.props.tipoElemento.split("/")[2]]) {
                this.fetcher(this.sources[targetCase], props.elementId, this.props.abInicio, this.props.abFin, null, props.nit)
            } else if (typeof this.props.tipoElementoSenal == "string") {
                this.fetcher(this.sources[targetCase], props.elementId, this.props.abInicio, this.props.abFin, null, props.nit)
            } else if (typeof this.props.tipoElementoSenal == "string" && typeof this.props.tipoSenal == "string") {
                this.fetcher(this.sources[targetCase], props.elementId, this.props.abInicio, this.props.abFin, null, props.nit)
            }
            else {
                this.state.desaprobados.push({ id: props.elementId })
            }
        } else {
            this.fetcher(this.sources[targetCase], props.elementId, this.props.abInicio, this.props.abFin, null, props.nit)
        }

    }

    render() {
        const { translate } = this.props;
        const { classes } = this.props; 
        const preventSave = (value) => {
            return translate("resources.costos.noCrear2");
        }

        let errorTableContent = null
        this.state.datosArchivo.map(item => {
            if (!this.allowedMaintenanceTypes.includes(item.tipoMantenimiento)) {
                if (this.state.hasInvalidValues == false) { this.setState({ hasInvalidValues: true }) }
                errorTableContent =
                    <Fragment>
                        {errorTableContent}
                        <TableBody>
                            <TableCell><WarningIcon style={{ color: "#f57c00" }} /></TableCell>
                            <TableCell>{translate("resources.costos.fields.tipoMantenimiento")} {item.tipoMantenimiento} en elemento {item.codigo}</TableCell>
                            <TableCell>no se reconoce el tipo de mantenimiento. Asegurese de usar las abreviaciones ["ru", "pe", "re", "ca"] para describir "rutinario", "periódico", "rehabilitación" y "cambio".</TableCell>
                        </TableBody>
                    </Fragment>
            }
            if (item.fechaMantenimiento.toString() == 'Invalid Date') {
                if (this.state.hasInvalidValues == false) { this.setState({ hasInvalidValues: true }) }
                errorTableContent =
                    <Fragment>
                        {errorTableContent}
                        <TableBody>
                            <TableCell><WarningIcon style={{ color: "#f57c00" }} /></TableCell>
                            <TableCell>Fecha: {item.fechaMantenimientoOriginalString} en elemento {item.codigo}</TableCell>
                            <TableCell>El formato de la fecha no se reconoce o es futura a la creacion de este registro o es anterior al año 2000.</TableCell>
                        </TableBody>
                    </Fragment>

            }
            if (!(item.calificacion > 0 && item.calificacion < 6)) {
                if (this.state.hasInvalidValues == false) { this.setState({ hasInvalidValues: true }) }
                errorTableContent =
                    <Fragment>
                        {errorTableContent}
                        <TableBody>
                            <TableCell><WarningIcon style={{ color: "#f57c00" }} /></TableCell>
                            <TableCell>{translate("resources.costos.fields.calificacion")} {item.calificacion} en elemento {item.codigo}</TableCell>
                            <TableCell>El rango de la calificación no es valida. Debe ser entre 1 y 5.</TableCell>
                        </TableBody>
                    </Fragment>
            }
        })

        if (this.state.fetchingRequests > 0) {
            return (
                <Fragment>
                    <Loading loadingPrimary="Validando Cargue Masivo" loadingSecondary={"elementos restantes: " + this.state.fetchingRequests} />
                    <ArrayInput label=" " source="preventSave" validate={[preventSave]}  >
                        <SimpleFormIterator disableAdd disableRemove  >
                            <TextInput disabled label="tipo elemento" source="tipoElemento" fullWidth formClassName={classes.ocultar} />
                        </SimpleFormIterator>
                    </ArrayInput>
                </Fragment>

            )
        } else {
            // console.log(this.state.datosArchivo)
            return (
                <Fragment>
                    {(this.state.hasInvalidValues || this.state.desaprobados.length || this.state.proveedoresDesaprobados.length || this.state.smartCodeDesaprovado.length || this.state.valorDesaprobados.length || this.state.abscisasValidacionIni.length || this.state.abscisasValidacionFin.length ) ?
                        <Fragment>
                            <Table>
                                <TableHead >
                                    <TableCell></TableCell>
                                    <TableCell>Valor Analizado</TableCell>
                                    <TableCell>Error</TableCell>
                                </TableHead>
                                {errorTableContent} 
                                {this.state.desaprobados.length > 0 &&
                                    this.state.desaprobados.map(item => (
                                        <TableBody>
                                            <TableCell><ErrorIcon style={{ color: "#d32f2f" }} /></TableCell>
                                            <TableCell>Código: {item.id}</TableCell>
                                            <TableCell> Item no corresponde con los filtros</TableCell>
                                        </TableBody>
                                    ))
                                }
                                {this.state.abscisasValidacionIni.length > 0 &&
                                    this.state.abscisasValidacionIni.map(item => (
                                        <TableBody>
                                            <TableCell><ErrorIcon style={{ color: "#d32f2f" }} /></TableCell>
                                            <TableCell>Código: {item.id}</TableCell>
                                            <TableCell> La abscisa inicial del elemento es menor a la abscisa inicial ingresada</TableCell>
                                        </TableBody>
                                    ))
                                }
                                {this.state.abscisasValidacionFin.length > 0 &&
                                    this.state.abscisasValidacionFin.map(item => (
                                        <TableBody>
                                            <TableCell><ErrorIcon style={{ color: "#d32f2f" }} /></TableCell>
                                            <TableCell>Código: {item.id}</TableCell>
                                            <TableCell> La abscisa final del elemento es mayor a la abscisa final ingresada</TableCell>
                                        </TableBody>
                                    ))
                                }
                                {this.state.proveedoresDesaprobados.length > 0 &&
                                    this.state.proveedoresDesaprobados.map(item => (
                                        <TableBody>
                                            <TableCell><ErrorIcon style={{ color: "#d32f2f" }} /></TableCell>
                                            <TableCell>NIT/Cedula: {item.proveedor} en elemento {item.codigo}</TableCell>
                                            <TableCell>El proveedor no existe en la base de datos o no corresponde al sistema del elemento a registrar.</TableCell>
                                        </TableBody>
                                    ))
                                }
                                {this.state.smartCodeDesaprovado.length > 0 &&
                                    this.state.smartCodeDesaprovado.map(item => (
                                        <TableBody>
                                            <TableCell><ErrorIcon style={{ color: "#d32f2f" }} /></TableCell>
                                            <TableCell>Código: {item.id}</TableCell>
                                            <TableCell>El código del ítem no existe en los registros actuales.</TableCell>
                                        </TableBody>
                                    ))
                                }
                                {this.state.valorDesaprobados.length > 0 &&
                                    this.state.valorDesaprobados.map(item => (
                                        <TableBody>
                                            <TableCell><ErrorIcon style={{ color: "#d32f2f" }} /></TableCell>
                                            <TableCell>Código: {item.id}</TableCell>
                                            <TableCell>{(item.err)? item.err : 'El valor del ítem debe ser positivo y es obligatorio.'}</TableCell>
                                        </TableBody>
                                    ))
                                }
                            </Table>
                            <ArrayInput label=" " source="wrongData" validate={[() => { return "La carga masiva presenta errores." }]} >
                                <SimpleFormIterator disableAdd disableRemove  >
                                    <TextInput disabled label="tipo elemento" source="tipoElemento" fullWidth formClassName={classes.ocultar} />
                                </SimpleFormIterator>
                            </ArrayInput>
                        </Fragment>
                        : null
                    }
                    {this.returnElementsComponent(classes)}
                </Fragment>
            )
        }
    }
}

AddMasivoCosto.propTypes = {
    classes: PropTypes.object.isRequired,
}
const AddMasivoCostos = withStyles(styles)(AddMasivoCosto);

 
export default translate(AddMasivoCostos);