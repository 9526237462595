import React, { useState, Component } from 'react';
import {
    Button, FormTab, required, TextInput, NumberInput, ReferenceInput, SelectInput, minLength,
    AutocompleteInput, Query
} from 'react-admin';
import { PimsCreate } from '../Create';
import Grid from '@material-ui/core/Grid';
import { Toolbar, SaveButton } from 'react-admin';
import CancelIcon from '@material-ui/icons/Cancel';
import { TabbedForm } from './../TabbedForm';
import UniqueEmail from './../componentes/UniqueEmail';
import config from '../../config/config';
import Typography from '@material-ui/core/Typography';
import { styles } from '../../EstilosGrid';
import { withStyles } from '@material-ui/core';
import { translate } from 'react-admin';
import LocaleSwitcher from './traduccion/LocaleSwitcher';
let urlAppjs = config.UrlServer;
export const UsuarioCreate =  translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;
    const [emailExist, setEmailExist] = useState(false);


    // const validarEmail=()=>{
    //     var texto = document.getElementById("emails").value;
    //     var regex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
    //     if (!regex.test(texto)) {
    //        return ("Correo Invalido");
    //     } 
    //     else {
    //         fetch(urlAppjs+"/usuarios?username="+texto,
    //             {headers:{
    //                 'authorization': 'Bearer '+localStorage.getItem('token')
    //             }
    //             })
    //             .then((response) => {
    //                 return response.json()
    //             })
    //             .then((response) => {
    //                 //AMendez@dynatest.com
    //                 if (response["hydra:member"].length != 0) {
    //                     setEmailExist(true)
    //                     //fetchResponse("El e-mail ya se encuentra en uso")
    //                 } else {
    //                     setEmailExist(false)
    //                 }
    //             });
    //     }
    //   }
    // const compobarEmail = () => {
    //     if (emailExist == true) {
    //         return "El e-mail ya se encuentra en uso";
    //     } 
    // }
    const [rol, setRol] = useState('');
    const ValidarAdminRol = (value) => {
        setRol(value)
    }
    const contrasena = () => {
        let clave1 = document.getElementById("clave1").value;
        let clave2 = document.getElementById("clave2").value;
        if (clave1 !== clave2) {
            return translate('resources.validaciones.contraseniaNoCoindice');
    
        }
    }
    const validarNombre=(elemento)=>{
        var texto = document.getElementById("nombres").value;
        var regex = /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g;
        
        if (!regex.test(texto)) {
            return translate('resources.validaciones.valNombre');
        } else {
            console.log("nombre valido") ;
        }
    }
    const validarApellido=(elemento)=>{
        var texto = document.getElementById("apellidos").value;
        var regex = /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/g;
        
        if (!regex.test(texto)) {
            return translate('resources.validaciones.valApellido');
        } else {
            console.log("apellido valido") ;
        }
    }
    return (
        <PimsCreate {...props}>
            <TabbedForm submitOnEnter={false} redirect="list" >
                <FormTab label="dynatest.resources.usuarios.formTabs.identificacionTitle">
                    <Grid container spacing={24}>
                        <Grid item xs={6}>
                            <TextInput label="resources.usuarios.fields.nombres" source="nombres" id="nombres" fullWidth validate={[required(), minLength(3), validarNombre]} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput label="resources.usuarios.fields.apellidos" source="apellidos" id="apellidos" fullWidth validate={[required(), minLength(3), validarApellido]} />
                        </Grid>
                        <Grid item xs={6} style={{display:'none'}}>
                            <SelectInput label="resources.usuarios.fields.tipo_documento" source="tipo_documento" sort={{ field: 'name', order: 'ASC' }} choices={[
                                { id: translate('dynatest.resources.usuarios.select.documento.cedula_ciudadania'), name: 'dynatest.resources.usuarios.select.documento.cedula_ciudadania' },
                                { id: translate('dynatest.resources.usuarios.select.documento.cedula_extrangeria'), name: 'dynatest.resources.usuarios.select.documento.cedula_extrangeria' },
                                { id: translate('dynatest.resources.usuarios.select.documento.nit'), name: 'dynatest.resources.usuarios.select.documento.nit' },
                            ]} fullWidth defaultValue={translate('dynatest.resources.usuarios.select.documento.cedula_ciudadania')} />
                        </Grid>
                        <Grid item xs={6}>
                            <NumberInput label="resources.usuarios.fields.identificacion" source="identificacion" fullWidth />
                        </Grid>
                        <Grid item xs={6}>
                            <ReferenceInput
                                label="resources.usuarios.fields.admin"
                                source="admin"
                                reference="atributos"
                                filterToQuery={searchText => ({ nombre: searchText })}
                                sort={{ field: 'nombre', order: 'asc' }}
                                alwaysOn
                                fullWidth
                                validate={[required(), ValidarAdminRol]}
                            >
                                <AutocompleteInput options={{ fullWidth: true }} optionText="nombre" id='adminRol' />
                            </ReferenceInput>
                        </Grid>
                        {
                            <Query type="GET_ONE" resource="atributos" payload={{ id: rol }} >
                                {({ data, loading, error }) => {
                                    if (data) {
                                        return <TextInput style={{ display: 'none' }} label="NombreRol" defaultValue={data.nombre} source="adminRol" fullWidth validate={[required()]} />;
                                    }
                                    return <div></div>;
                                }}
                            </Query>
                        }
                        <Grid item xs={8}>
                            {/* <TextInput 
                            label="Correo electrónico" 
                            source="username" 
                            id="emails" 
                            fullWidth 
                            onBlur={compobarEmail}
                            validate={[validarEmail, required()]}
                            autoComplete="new-password"
                        /> */}
                            <UniqueEmail />
                        </Grid>
                        
                        <Grid item xs={6}>
                            <TextInput type='password' label="resources.usuarios.fields.password" autoComplete="new-password" source="password" id="clave1" fullWidth validate={[required()]} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextInput type='password' label="resources.usuarios.fields.plainPassword" autoComplete="new-password" source="plainPassword" id="clave2" fullWidth validate={[contrasena, required()]} />
                        </Grid>
                        <Typography variant="h3" component="h3" style={{ width: '300%' }}>
                            {translate('dynatest.resources.usuarios.tabs.idiomas')}
                        </Typography>
                        <Grid item xs={6}>
                            <SelectInput label="resources.usuarios.fields.idioma" source="idioma" sort={{ field: 'name', order: 'ASC' }} choices={[
                                { id: "es", name: translate('dynatest.resources.config_sistema.btnEspanol')},
                                { id: "en",name: translate('dynatest.resources.config_sistema.btnIngles')},
                                { id: "pt",name: translate('dynatest.resources.config_sistema.btnPortugues')},
                            ]} fullWidth defaultValue="es" />
                        </Grid>
                        {/* <LocaleSwitcher /> */}
                    </Grid>
                </FormTab>
            </TabbedForm>
        </PimsCreate>
    )
}));