import React from 'react';
import dFormatter from './../format/DFormat';

import { TextInput } from 'react-admin';


export const DInput = (props) => (
    <TextInput 
        {...props} 
        InputProps={{
            inputComponent: dFormatter,
        }}
    />
);

