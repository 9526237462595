import React, { useState, Fragment } from 'react';
import { TabbedForm, FormTab, TextInput, DateInput, SelectInput, NumberInput, ReferenceInput, DisabledInput, ImageField,ImageInput,
         ArrayInput, SimpleFormIterator, AutocompleteInput, FormDataConsumer
} from 'react-admin';
import {REDUX_FORM_NAME} from 'react-admin';
import {change} from 'redux-form';
import { PercentageInput } from './../../../../inputs/PercentageInput';
import { withStyles } from '@material-ui/core';
import { Edit } from './../../../Edit';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import {
    required,
    minValue,
    maxValue,
    minLength,
    maxLength
} from 'react-admin';
import { ImagenesInventario } from './../../../images/ImagenesInventario';
import { ButtonCarousel } from './../../../images/ButtonCarouselPeru';
import { Entero, isFechaInvalida } from '../../../validacionesInputs';
import { ArrayInputImagesEdit } from './../../../images/ArrayInputImagesEdit';
import { translate } from 'react-admin';



 const styles = {
    first_field: { display: 'inline-block', width: '30%' },
    observaciones_field: {display: 'inline-block', width: '40%'},
    last_filed: { display: 'inline-block', marginLeft: 32, width: '30%' },   
    separador: { marginTop: 40, marginLeft: 100, marginRight: 100, marginBottom: 0}, 
    subtittle: {marginTop: '15px',marginBottom: '15px'},       
    tabla:  {display: 'inline-block', width: '30%', padding: '5px'},
    grid_cont4:  { display: 'inline-block', width: '32%' ,marginBottom: '20px', padding: '5px'},
};

export const ElementoFlexibleEdit = translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;

    //----------------------------------------------
    //-----------------VALIDACIÓNES-----------------
    //----------------------------------------------

    let Esal10 = 0;
    const value10 = (value) =>{
        Esal10 = value;
    }

    let auxValue;
    const value20 = (value) =>{
        if(value){
            auxValue = document.getElementById('esal_time_10');
            if(Esal10 === 0){ Esal10 = auxValue.value; }                
            if(value <= Esal10){            
                return translate('dynatest.resources.flexible_detalles.messagesVal.value20')
            }
        }        
    }

    //GEOMETRIA
    //------------------->   

    //HOOKS VALOR AUTOMATICO AREA DE CARRILES(LARGO CALZADA) Y AREA VERDADERA CARRILES
    const [LargoCal, setLargoCal] = useState(1);
    const [AnchoCal, setAnchoCal] = useState(1);
    const [NumCarr, setNumCarr] = useState(1);
    const [AnchoCarr, setAnchoCarr] = useState(1);

    //CAMBIAR EL VALOR AREA DE CARRILES (LARGO)
   //CAMBIAR EL VALOR AREA DE CARRILES (LARGO)
   const ValDetallesCalzada = (value) =>{   
        if(value){
            //Si ingresa un decimal VAlidar solo 2     
            if(value !== 0){                
                if (value % 1 !== 0) {                     
                    var pattern = new RegExp("^[0-9]+([.][0-9]{2})$");
                    var pattern2 = new RegExp("^[0-9]+([.][0-9]{1})$");
                    if(!pattern.test(value) && !pattern2.test(value)){
                        if(!pattern.test(value)){ return translate('dynatest.resources.flexible_detalles.messagesVal.valNumDecimals'); }                            
                    }                                                            
                }                
            }

            setLargoCal(value);
            document.getElementById("AreaCarrilesFE").value = ((parseFloat(document.getElementById("largom").value)*100) * (parseFloat(document.getElementById("anchom").value)*100))/10000;
            // document.getElementById("AreaVerdaderaFE").value = ((parseFloat(document.getElementById("largom").value)*100) * (parseFloat(document.getElementById("anchom").value)*100) * (document.getElementById("anchoCar").value*100))/1000000;  

        }        
    }

    //VALIDAR NUMERO CON DECIMALES
    const valNumDecimals = (value) =>{    
        if(value){            
            if(value !== 0){                
                if (value % 1 !== 0) {                     
                    var pattern = new RegExp("^[0-9]+([.][0-9]{2})$");
                    var pattern2 = new RegExp("^[0-9]+([.][0-9]{1})$");
                    if(!pattern.test(value) && !pattern2.test(value)){
                        if(!pattern.test(value)){ return translate('dynatest.resources.flexible_detalles.messagesVal.valNumDecimals'); }                            
                    }                                                            
                }                
            }
        } 
    }

    //CAMBIAR EL VALOR AREA DE CARRILES (ANCHO)
    const ValDetallesCalzadaAncho = (value) =>{    
        if(value){
            setAnchoCal(value)
            
            document.getElementById("AreaCarrilesFE").value = ((parseFloat(document.getElementById("largom").value)*100) * (parseFloat(document.getElementById("anchom").value)*100))/10000;
            // document.getElementById("AreaVerdaderaFE").value = ((parseFloat(document.getElementById("largom").value)*100) * (parseFloat(document.getElementById("anchom").value)*100) * (document.getElementById("anchoCar").value*100))/1000000;  

        }
    }    

    //VALOR AUTOMATICO AREA VERDADERA CARRILES(NUMERO CARRILES)    
    const ValNumeroCarriles = (value) =>{    
        if (value){
            setNumCarr(value)
            
            document.getElementById("AreaCarrilesFE").value = ((parseFloat(LargoCal)*100) * (parseFloat(AnchoCal)*100))/10000;
            // document.getElementById("AreaVerdaderaFE").value = ((parseFloat(LargoCal)*100) * (parseFloat(AnchoCal)*100) * (AnchoCarr*100))/1000000;            
        }        
    }

    //ASIGNAR VALOR AUTOMATICO VERDADERA AREA CARRILES (ANCHO CARRIL)
    const ValAnchoCarril = (value) =>{ 
        if (value){

            if(value !== 0){
                if (value % 1 !== 0) { 
                    var pattern = new RegExp("^[0-9]+([.][0-9]{1})$");
                    if(!pattern.test(value)){ return translate('dynatest.resources.flexible_detalles.messagesVal.valNumDecimal'); }       
                }                
            }
            setAnchoCarr(value)
            
            document.getElementById("AreaCarrilesFE").value = ((parseFloat(document.getElementById("largom").value)*100) * (parseFloat(document.getElementById("anchom").value)*100))/10000;
            // document.getElementById("AreaVerdaderaFE").value = ((parseFloat(document.getElementById("largom").value)*100) * (parseFloat(document.getElementById("anchom").value)*100) * (document.getElementById("anchoCar").value*100))/1000000;  

        }
    }

    //VALIDACION PENDIENTE 0-20
    const valPendiente20 = (value) =>{
        if(value){
            let longitud20 = value.split("%");
            //VALIDAR ENTERO
            let entero = longitud20[0].split(".");
            if(entero[1]){
                if(entero[1] !== "") { return translate('dynatest.resources.flexible_detalles.messagesVal.valNumEntero') }
            }  
            //VALIDAR MINIMO 0
            if(parseInt(longitud20[0]) < 0){ return translate('dynatest.resources.flexible_detalles.messagesVal.valLongitudMin0'); }
            //VALIDAR MAXIMO 5
            if(parseInt(longitud20[0]) > 20){ return translate('dynatest.resources.flexible_detalles.messagesVal.valLongitudMax20'); }            
        }        
    }

    //VALIDACION PENDIENTE 0-5  
    const valPendiente5 = (value) =>{
        if(value){
            let longitud5 = value.split("%");
            //VALIDAR ENTERO
            let entero = longitud5[0].split(".");
            if(entero[1]){
                if(entero[1] !== "") { return translate('dynatest.resources.flexible_detalles.messagesVal.valNumEntero') }
            }            
            //VALIDAR MINIMO 0
            if(parseInt(longitud5[0]) < 0){ return translate('dynatest.resources.flexible_detalles.messagesVal.valLongitudMin0'); }
            //VALIDAR MAXIMO 5
            if(parseInt(longitud5[0]) > 5){ return translate('dynatest.resources.flexible_detalles.messagesVal.valLongitudMax5'); }            
        }
    }

    //ESTRUCTURA
    //------------------->    
    let espensor1 = 0, valEspensor1;
    let espensor2 = 0, valEspensor2;
    let espensor3 = 0, valEspensor3;
    let espensor4 = 0, valEspensor4;
    let espensor5 = 0, valEspensor5;
    let espensor6 = 0, valEspensor6;
    let espensor7 = 0, valEspensor7;    

    //VALOR ESPENSOR
    let defecto = 0;
    const [espensortotal, setEspensortotal] = useState(defecto);
    const ValEspensor = (value) => {
        if(value){      
            
            if (value % 1 !== 0) { return translate('dynatest.resources.flexible_detalles.messagesVal.valNumEntero'); }
            //ESPENSOR 1
            valEspensor1 = document.getElementById('tablaSegmento[0].estructuraEspesor');
            valEspensor2 = document.getElementById('tablaSegmento[1].estructuraEspesor');
            valEspensor3 = document.getElementById('tablaSegmento[2].estructuraEspesor');
            valEspensor4 = document.getElementById('tablaSegmento[3].estructuraEspesor');
            valEspensor5 = document.getElementById('tablaSegmento[4].estructuraEspesor');
            valEspensor6 = document.getElementById('tablaSegmento[5].estructuraEspesor');
            valEspensor7 = document.getElementById('tablaSegmento[6].estructuraEspesor');

            // console.log("valEspensor", valEspensor1, valEspensor2, valEspensor3, valEspensor4, valEspensor5, valEspensor6);
            
            //ESPENSOR 1
            if(valEspensor1) { if(valEspensor1.value !== null){ espensor1 = valEspensor1.value } }
            //ESPENSOR 2
            if(valEspensor2) { if(valEspensor2.value !== null){ espensor2 = valEspensor2.value } }
            //ESPENSOR 2
            if(valEspensor3) { if(valEspensor3.value !== null){ espensor3 = valEspensor3.value } }
            //ESPENSOR 2
            if(valEspensor4) { if(valEspensor4.value !== null){ espensor4 = valEspensor4.value } }
            //ESPENSOR 2
            if(valEspensor5) { if(valEspensor5.value !== null){ espensor5 = valEspensor5.value } }
            //ESPENSOR 2
            if(valEspensor6) { if(valEspensor6.value !== null){ espensor6 = valEspensor6.value } }
            //ESPENSOR 2
            if(valEspensor7) { if(valEspensor7.value !== ''){ espensor7 = valEspensor7.value } }
            
            var suma = parseInt(espensor1) + parseInt(espensor2) + parseInt(espensor3) + parseInt(espensor4) + parseInt(espensor5) + parseInt(espensor6) + parseInt(espensor7);
            if (!isNaN(suma)) {
                document.getElementById('espensorTotalFE').value = suma;
            } else {
                document.getElementById('espensorTotalFE').value = "";
            }
        }  else {
            setEspensortotal(0);
        }
    }
    

    //VALOR MODULO 1
    const ValModulo = (value) =>{
        if(value){  
            if (value % 1 !== 0) { return translate('dynatest.resources.flexible_detalles.messagesVal.valNumEntero'); }          
        } 
    }

    //VALOR ESTRUCTURA 1
    const ValEstructura = (value) =>{
        if(value){
            if(value !== 0){
                if (value % 1 !== 0) {
                    var pattern = new RegExp("^[0-9]+([.][0-9]{2})$");
                    if(!pattern.test(value)){ return translate('dynatest.resources.flexible_detalles.messagesVal.valNumDecimals'); }            
                }                
            }
        } 
    }

    //VALIDAR NUMERO ENTERO
    const valentero = (value) =>{
        if(value !== null){
            if (value % 1 !== 0) { return translate('dynatest.resources.flexible_detalles.messagesVal.valNumEntero'); }
        }
    }
    
    //VALIDAR NUMERO CON 1 DECIMALES
    const valNum1Decimal = (value) =>{ 
        if(value){ 
            if(typeof value === 'string'){
                value = parseFloat(value.replace(",", "."));        
            }
            if(value !== 0){                
                if (value % 1 !== 0) {                     
                    var pattern = new RegExp("^[0-9]+([.][0-9]{1})$");
                    if(!pattern.test(value)){ return translate('dynatest.resources.flexible_detalles.messagesVal.valNumDecimal'); }       
                }                
            }
        }  
    }

    //VALIDAR ESPECIFICACIÓN
    const [material , setMaterial]= useState('');
    const ValidarEspecificacion = (value)  => { 
        let lenghtObject = Object.keys(value);
        let valor = '';
        
        if(value){
            for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
            var filtroGra = valor.split('/materials/');  
            setMaterial(filtroGra[1]);
        }       
        
    }    
    const [estado, setEstado] = useState('');

    const Validationrazon =(value) =>{                 
        setEstado(value);
    }
    const [condicionberma, setCondicionberma] = useState(true);
    const validarCondicionBerma = (value) => {

        if(value){
            if (value === 'Inexistente'){
                setCondicionberma(false);
            }else{
                setCondicionberma(true);
            }
        }

    }

    const [arrayMax, setArrayMax] = useState(false);
    const arrayCount5 = (value) => {
        if(typeof value !== 'undefined'){
            if(value.length > 0) {
                if("estructuraEspesor" in value[value.length -1]) {
                    if(value[value.length -1].estructuraEspesor > 0) {
                        return translate('dynatest.resources.flexible_detalles.messagesVal.messageIniCapa') + value.length + translate('dynatest.resources.flexible_detalles.messagesVal.messageFinCapa');
                    }
                }
                if (value.length > 4) {
                    setArrayMax(true);
                } else {
                    setArrayMax(false);
                }
            } else {
                return translate('dynatest.resources.flexible_detalles.messagesVal.valMinCapa')
            }
        } else {
            return translate('dynatest.resources.flexible_detalles.messagesVal.valMinCapa')
        } 
    }

    const [anchosep, setAnchosep] = useState('');

    const ValidationConSeparador = (value) => {
        // let lenghtObject = Object.keys(value);
        // let valor = '';
        // for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                
        //setAnchosep(valor) 
        setAnchosep(value)  
    }

    const [type, setType] = useState('edit');

    const Type = () => {
        setType('edit')  
        
    }

    /*VALIDAR IMÁGENES ACTUALES */
    const [imagenesOpen,setImagenesOpen] = useState('');
    const ImagenesEdit = (value) =>{
        setImagenesOpen(value);
        
    }

    /*CALCULAR CONCEITO EN ESTRUCTURA*/
    
    const CalcularCoceito = (value) => {
        var conceito = '';
        if(value > 0 && value <= 20){
            conceito = 'Ótimo';
        }else if(value > 20 && value <= 40){
            conceito = 'Bom';
        }else if(value >40 && value <= 80){
            conceito = 'Regular';
        }else if(value >80 && value <= 160){
            conceito = 'Ruim';
        }else if(value > 160){
            conceito = 'Péssimo';
        }
        document.getElementById("Conceito").value = conceito;
        
    }
    /*FIN DEL CALCULO */

    return(
    <Edit title="dynatest.resources.flexible_detalles.title.editTitle" {...props} undoable={false}>
        <TabbedForm redirect="list">
            <FormTab label="dynatest.resources.flexible_detalles.tabs.detalles">
            <FormDataConsumer >
                    {({formData, dispatch, ...rest}) => (
                        <Fragment>
                            <Grid container spacing={24}>
                                <Grid item xs={4}>
                                    <ReferenceInput 
                                        label="resources.flexible_detalles.fields.unidadFuncional" 
                                        source="unidadFuncional" 
                                        reference="unidad_funcionals" 
                                        sort={{ field: 'codigo', order: 'ASC' }}
                                        onChange={value => dispatch(
                                            change(REDUX_FORM_NAME, "tramo", null)
                                        )}
                                        filterToQuery={searchText => ({ codigo: searchText })} 
                                        disabled
                                    >
                                        <AutocompleteInput  options={{fullWidth : true, disabled: true}} optionText="codigo"  />
                                    </ReferenceInput>
                                </Grid>
                                <Grid item xs={4}>
                                <ReferenceInput 
                                        label="resources.flexible_detalles.fields.tramo" 
                                        source="tramo" 
                                        reference="tramos" 
                                        filter={{unidadFuncional: formData.unidadFuncional}} 
                                        sort={{ field: 'codigo', order: 'ASC' }}
                                        onChange={value => dispatch(
                                            change(REDUX_FORM_NAME, "segmento", null)
                                        )}
                                        filterToQuery={searchText => ({ codigo: searchText })} 
                                        disabled
                                    >
                                        <AutocompleteInput  options={{fullWidth : true, disabled: true}} optionText="codigo"  />
                                    </ReferenceInput>
                                </Grid>
                                <Grid item xs={4}>
                                    <ReferenceInput 
                                        label="resources.flexible_detalles.fields.segmento" 
                                        source="segmento" 
                                        reference="segmentos" 
                                        filter={{tramo: formData.tramo}} 
                                        sort={{ field: 'codigo', order: 'ASC' }}
                                        filterToQuery={searchText => ({ codigo: searchText })} 
                                        disabled
                                    >
                                        <AutocompleteInput  options={{fullWidth : true, disabled: true}} optionText="codigo"  />
                                    </ReferenceInput>
                                </Grid>
                            </Grid>
                        </Fragment>
                        
                    )}
                </FormDataConsumer>
                <ArrayInput source="detallesSegmento" style={{width: '100%'}} label=" "
                defaultValue={[
                    {                        
                        estadoDetalles: null,
                        estadoRazon: null,
                        dateInicioOperacion: null,
                        proveedor: null,
                        garantiaMeses: null,
                        esalTime: null,
                        nivelTrafico: null,
                        esalTime10: null,
                        esalTime20: null,
                        detallesObservaciones: null
                    }
                ]} className="inventariosSegmentos">                
                    <SimpleFormIterator disableRemove disableAdd>                          
                        <div></div>
                        <SelectInput label="resources.flexible_detalles.fields.sentido" source='sentido' choices={[
                            { id: translate('dynatest.resources.flexible_detalles.select.sentido.NORTEId'), name: 'dynatest.resources.flexible_detalles.select.sentido.NORTEName' },
                            { id: translate('dynatest.resources.flexible_detalles.select.sentido.SURId'), name: 'dynatest.resources.flexible_detalles.select.sentido.SURName' },
                            { id: translate('dynatest.resources.flexible_detalles.select.sentido.ESTEId'), name: 'dynatest.resources.flexible_detalles.select.sentido.ESTEName' },
                            { id: translate('dynatest.resources.flexible_detalles.select.sentido.OESTEId'), name: 'dynatest.resources.flexible_detalles.select.sentido.OESTEName' }
                        ]}
                        formClassName={classes.first_field}/>  
                        <SelectInput 
                            source="estadoDetalles" 
                            label="resources.flexible_detalles.fields.estadoDetalles" choices={[
                                { id: translate('dynatest.resources.flexible_detalles.select.estadoDetalles.Activo'), name: 'dynatest.resources.flexible_detalles.select.estadoDetalles.Activo' },
                                { id: translate('dynatest.resources.flexible_detalles.select.estadoDetalles.Inactivo'), name: 'dynatest.resources.flexible_detalles.select.estadoDetalles.Inactivo' },                      
                            ]} 
                            validate={[required(), Validationrazon]} 
                             
                            formClassName={classes.grid_cont4}
                        />                                
                        
                        {estado === translate('dynatest.resources.flexible_detalles.select.estadoDetalles.Inactivo') &&
                            <TextInput 
                                source="estadoRazon" 
                                label="resources.flexible_detalles.fields.estadoRazon" 
                                validate={[required()]}
                                 
                                formClassName={classes.grid_cont4}
                            />
                        }              
                        <div ></div>     
                        <DateInput 
                            source="dateInicioOperacion" 
                            label="resources.flexible_detalles.fields.dateInicioOperacion" 
                            formClassName={classes.first_field} 
                             
                            validate={[required(), isFechaInvalida]} 
                        />                         
                        
                        <ReferenceInput 
                            label="resources.flexible_detalles.fields.proveedor" 
                            source="proveedor" 
                            reference="proveedors" 
                            filter={{proyecto: props.proyecto, suministros: 1, sistema: "CLZ"}} 
                            sort={{ field: 'razonSocial', order: 'ASC' }}
                            formClassName={classes.grid_cont4}
                            validate={[Type]} 
                            filterToQuery={searchText => ({ razonSocial: searchText })} 
                        >
                            <AutocompleteInput optionText="razonSocial"  />
                        </ReferenceInput>
                        <NumberInput 
                            source="garantiaMeses" 
                            label="resources.flexible_detalles.fields.garantiaMeses" 
                            validate={[minValue(0), Entero]} 
                            formClassName={classes.grid_cont4}
                        />
                        <div className={classes.subtittle}><strong>{translate('resources.flexible_detalles.fields.trafico')}</strong></div>                
                        <Divider fullWidth />
                        <NumberInput 
                            source="esalTime" 
                            label="resources.flexible_detalles.fields.esalTime" 
                            formClassName={classes.grid_cont4} 
                             
                            validate={[required(),minValue(0), Entero]}
                            />
                        
                        <SelectInput source="nivelTrafico" label="resources.flexible_detalles.fields.nivelTrafico" choices={[
                                { id: translate('dynatest.resources.flexible_detalles.select.nivelTrafico.NT1'), name: 'dynatest.resources.flexible_detalles.select.nivelTrafico.NT1' },
                                { id: translate('dynatest.resources.flexible_detalles.select.nivelTrafico.NT2'), name: 'dynatest.resources.flexible_detalles.select.nivelTrafico.NT2' },
                                { id: translate('dynatest.resources.flexible_detalles.select.nivelTrafico.NT3'), name: 'dynatest.resources.flexible_detalles.select.nivelTrafico.NT3' },
                            ]} 
                            formClassName={classes.grid_cont4} 
                             
                            validate={[required()]} 
                        />  
                        <div></div>                
                        <NumberInput 
                            source="esalTime10" 
                            label="resources.flexible_detalles.fields.esalTime10" 
                            formClassName={classes.grid_cont4} 
                            validate={[minValue(0), value10, Entero]}
                             
                        />
                        <NumberInput 
                            source="esalTime20" 
                            label="resources.flexible_detalles.fields.esalTime20" 
                            formClassName={classes.grid_cont4} 
                             
                            validate={[minValue(0), value20, Entero]}
                        />
                        <Divider fullWidth formClassName={classes.grid_cont12s} />                
                        <TextInput 
                            source="detallesObservaciones" 
                            label="resources.flexible_detalles.fields.detallesObservaciones" 
                            formClassName={classes.grid_cont12s} 
                            validate={[minLength(0), maxLength(200)]} 
                             
                        />
                    </SimpleFormIterator>
                </ArrayInput>    
            </FormTab>
            <FormTab label="dynatest.resources.flexible_detalles.tabs.geometria">
                <ArrayInput source="geometriaSegmento" style={{width: '100%'}} label=" "
                    defaultValue={[
                        {
                            geometriaLargo: null,
                            geometriaAncho: null,
                            geometriaNumCarriles: null,
                            geometriaAnchoCarril: null,
                            geometriaAnchoBerma: null,
                            geometriaTipoBerma: null,
                            geometriaCondicionBerma: null,
                            geometriaLongitudinal: null,
                            geometriaLongitudinalMa: null,
                            geometriaLongitudinalMi: null,
                            geometriaTransversal: null,
                            geometriaRadioMi: null,
                            geometriaRadioMa: null,
                            geometriaRadioCurvatura: null,
                            geometriaVelDiseno: null,
                            geometriaSeparador: null,
                            geometriaSeparadorAncho: null,
                            geometriaCondicionesGeometrica: null,
                            geometriaAlineamiento: null,
                            geometriaTipoTerreno: null,
                            detallesGeometria: null,
                            aux1: 0,
                            aux2: 0
                        }
                    ]} className="inventariosSegmentos">
                    <SimpleFormIterator disableRemove disableAdd>  
                        <div className={classes.subtittle}><strong>{translate('resources.flexible_detalles.fields.detallesCalzada')}</strong></div>
                        <Divider fullWidth />
                        <NumberInput 
                            id="largom"
                            source="geometriaLargo" 
                            label="resources.flexible_detalles.fields.geometriaLargo" 
                            formClassName={classes.grid_cont4} 
                             
                            validate={[required(), minValue(0), maxValue(1999.99), ValDetallesCalzada]}
                        />
                        <NumberInput 
                            id="anchom"
                            source="geometriaAncho" 
                            label="resources.flexible_detalles.fields.geometriaAncho" 
                            formClassName={classes.grid_cont4} 
                             
                            validate={[required(), minValue(3), maxValue(40), ValDetallesCalzadaAncho]}
                        />
                        <div className={classes.subtittle}><strong>{translate('resources.flexible_detalles.fields.detallesCarriles')}</strong></div>
                        <Divider fullWidth />
                        <NumberInput 
                            source="geometriaNumCarriles" 
                            label="resources.flexible_detalles.fields.geometriaNumCarriles" 
                            formClassName={classes.grid_cont4} 
                             
                            validate={[required(), minValue(1), maxValue(5), ValNumeroCarriles, Entero]}
                        />
                        <NumberInput 
                            id="anchoCar"
                            source="geometriaAnchoCarril" 
                            label="resources.flexible_detalles.fields.geometriaAnchoCarril" 
                            formClassName={classes.grid_cont4} 
                             
                            validate={[required(), minValue(1), maxValue(5), ValAnchoCarril]}
                            />
                        <div></div>
                        <SelectInput 
                            source="geometriaTipoBerma" 
                            label="resources.flexible_detalles.fields.geometriaTipoBerma" choices={[
                                { id: translate('dynatest.resources.flexible_detalles.select.geometriaTipoBerma.bermaCuneta'), name: 'dynatest.resources.flexible_detalles.select.geometriaTipoBerma.bermaCuneta' },
                                { id: translate('dynatest.resources.flexible_detalles.select.geometriaTipoBerma.flexible'), name: 'dynatest.resources.flexible_detalles.select.geometriaTipoBerma.flexible' },
                                { id: translate('dynatest.resources.flexible_detalles.select.geometriaTipoBerma.inexistente'), name: 'dynatest.resources.flexible_detalles.select.geometriaTipoBerma.inexistente' },
                                { id: translate('dynatest.resources.flexible_detalles.select.geometriaTipoBerma.rigida'), name: 'dynatest.resources.flexible_detalles.select.geometriaTipoBerma.rigida' },
                            ]} formClassName={classes.grid_cont4} 
                             
                            validate={[validarCondicionBerma]}
                        />
                        {condicionberma && (
                            <NumberInput 
                                source="geometriaAnchoBerma" 
                                label="resources.flexible_detalles.fields.geometriaAnchoBerma" 
                                formClassName={classes.grid_cont4} 
                                 
                                validate={[required(), minValue(0), maxValue(10), valNum1Decimal]}
                            />
                        )}
                        {condicionberma && (
                            <SelectInput source="geometriaCondicionBerma" label="resources.flexible_detalles.fields.geometriaCondicionBerma" resettable choices={[
                                { id: translate('dynatest.resources.flexible_detalles.select.geometriaCondicionBerma.bueno'), name: 'dynatest.resources.flexible_detalles.select.geometriaCondicionBerma.bueno' },
                                { id: translate('dynatest.resources.flexible_detalles.select.geometriaCondicionBerma.regular'), name: 'dynatest.resources.flexible_detalles.select.geometriaCondicionBerma.regular' },
                                { id: translate('dynatest.resources.flexible_detalles.select.geometriaCondicionBerma.deficiente'), name: 'dynatest.resources.flexible_detalles.select.geometriaCondicionBerma.deficiente' },               
                            ]} formClassName={classes.grid_cont4} 
                             
                            /> 
                        )}                           
                        <div></div>
                        <DisabledInput 
                            label="resources.flexible_detalles.fields.aux1" 
                            source="sumaAreaCarriles" 
                            id="AreaCarrilesFE" 
                            className="align-number"
                            formClassName={classes.grid_cont4} 
                             
                        />                
                        <NumberInput 
                            label="resources.flexible_detalles.fields.areaCarrilesVerdadera" 
                            source="areaCarrilesVerdadera" 
                            id="AreaVerdaderaFE" 
                            className="align-number"
                            formClassName={classes.grid_cont4} 
                            validate={[required(), minValue(0)]}
                             
                        />
                        <div className={classes.subtittle}><strong>{translate('resources.flexible_detalles.fields.propiedadesGeometricas')}</strong></div>
                        <Divider fullWidth />
                        <PercentageInput 
                            className="Longitudes_segmentos" 
                            source="geometriaLongitudinal" 
                            label="resources.flexible_detalles.fields.geometriaLongitudinal" 
                            formClassName={classes.grid_cont4} 
                            validate={[valPendiente20]}
                             
                        />
                        <PercentageInput 
                            className="Longitudes_segmentos" 
                            source="geometriaLongitudinalMa" 
                            label="resources.flexible_detalles.fields.geometriaLongitudinalMa" 
                            formClassName={classes.grid_cont4} 
                            validate={[valPendiente20]}
                             
                        />
                        <PercentageInput 
                            className="Longitudes_segmentos" 
                            source="geometriaLongitudinalMi" 
                            label="resources.flexible_detalles.fields.geometriaLongitudinalMi" 
                            formClassName={classes.grid_cont4} 
                            validate={[valPendiente20]}
                             
                        />
                        {/* -----------RADIO--------- */}
                        <br/>
                        <PercentageInput 
                            className="Longitudes_segmentos" 
                            source="geometriaTransversal" 
                            label="resources.flexible_detalles.fields.geometriaTransversal" 
                            formClassName={classes.grid_cont4} 
                            validate={[required(), valPendiente5]}
                             
                        />
                        <NumberInput 
                            source="geometriaRadioMi" 
                            label="resources.flexible_detalles.fields.geometriaRadioMi" 
                            formClassName={classes.grid_cont4} 
                            validate={[required(), minValue(0), maxValue(5), Entero]}
                             
                        />
                        <NumberInput 
                            source="geometriaRadioMa" 
                            label="resources.flexible_detalles.fields.geometriaRadioMa" 
                            formClassName={classes.grid_cont4} 
                            validate={[required(), minValue(0), maxValue(5000), Entero]}
                             
                        />
                        <br/>
                        {/* -------------------- */}
                        <NumberInput 
                            source="geometriaRadioCurvatura" 
                            label="resources.flexible_detalles.fields.geometriaRadioCurvatura" 
                            formClassName={classes.grid_cont4} 
                            validate={[required(), minValue(0), maxValue(5000), valNum1Decimal]} 
                             
                        />
                        <NumberInput 
                            source="geometriaVelDiseno" 
                            label="resources.flexible_detalles.fields.geometriaVelDiseno" 
                            formClassName={classes.grid_cont4} 
                            validate={[required(), minValue(10), maxValue(150), valentero]} 
                             
                        />
                        <div></div>
                        <SelectInput source="geometriaSeparador" label="resources.flexible_detalles.fields.geometriaSeparador" choices={[
                                { id: translate('dynatest.resources.flexible_detalles.select.geometriaSeparador.Si'), name: 'dynatest.resources.flexible_detalles.select.geometriaSeparador.Si' },
                                { id: translate('dynatest.resources.flexible_detalles.select.geometriaSeparador.No'), name: 'dynatest.resources.flexible_detalles.select.geometriaSeparador.No' },                       
                            ]} 
                            formClassName={classes.grid_cont4} 
                            validate={[required(), ValidationConSeparador]}
                             
                        />
                        { anchosep === translate('dynatest.resources.flexible_detalles.select.geometriaSeparador.Si') &&   
                            <NumberInput 
                                source="geometriaSeparadorAncho" 
                                label="resources.flexible_detalles.fields.geometriaSeparadorAncho" 
                                formClassName={classes.grid_cont4} 
                                validate={[required(), minValue(0), maxValue(50)]}
                                 
                            />
                        }                 
                        <div></div>
                        <SelectInput source="geometriaCondicionesGeometrica" label="resources.flexible_detalles.fields.geometriaCondicionesGeometrica" choices={[
                                { id: translate('dynatest.resources.flexible_detalles.select.geometriaCondicionesGeometrica.bueno'), name: 'dynatest.resources.flexible_detalles.select.geometriaCondicionesGeometrica.bueno' },
                                { id: translate('dynatest.resources.flexible_detalles.select.geometriaCondicionesGeometrica.regular'), name: 'dynatest.resources.flexible_detalles.select.geometriaCondicionesGeometrica.regular' },
                                { id: translate('dynatest.resources.flexible_detalles.select.geometriaCondicionesGeometrica.deficiente'), name: 'dynatest.resources.flexible_detalles.select.geometriaCondicionesGeometrica.deficiente' },
                            ]} formClassName={classes.grid_cont4} 
                             
                        />
                        <SelectInput source="geometriaAlineamiento" label="resources.flexible_detalles.fields.geometriaAlineamiento" choices={[
                                { id: translate('dynatest.resources.flexible_detalles.select.geometriaAlineamiento.curvo'), name: 'dynatest.resources.flexible_detalles.select.geometriaAlineamiento.curvo' },
                                { id: translate('dynatest.resources.flexible_detalles.select.geometriaAlineamiento.predominantementeCurvo'), name: 'dynatest.resources.flexible_detalles.select.geometriaAlineamiento.predominantementeCurvo' },
                                { id: translate('dynatest.resources.flexible_detalles.select.geometriaAlineamiento.predominantementeRecto'), name: 'dynatest.resources.flexible_detalles.select.geometriaAlineamiento.predominantementeRecto' },
                                { id: translate('dynatest.resources.flexible_detalles.select.geometriaAlineamiento.recto'), name: 'dynatest.resources.flexible_detalles.select.geometriaAlineamiento.recto' },
                            ]} formClassName={classes.grid_cont4} 
                             
                        />
                        <SelectInput source="geometriaTipoTerreno" label="resources.flexible_detalles.fields.geometriaTipoTerreno" choices={[
                                { id: translate('dynatest.resources.flexible_detalles.select.geometriaTipoTerreno.plano'), name: 'dynatest.resources.flexible_detalles.select.geometriaTipoTerreno.plano' },
                                { id: translate('dynatest.resources.flexible_detalles.select.geometriaTipoTerreno.ondulado'), name: 'dynatest.resources.flexible_detalles.select.geometriaTipoTerreno.ondulado' },
                                { id: translate('dynatest.resources.flexible_detalles.select.geometriaTipoTerreno.montañoso'), name: 'dynatest.resources.flexible_detalles.select.geometriaTipoTerreno.montañoso' },
                                { id: translate('dynatest.resources.flexible_detalles.select.geometriaTipoTerreno.escarpado'), name: 'dynatest.resources.flexible_detalles.select.geometriaTipoTerreno.escarpado' },
                            ]} formClassName={classes.grid_cont4} 
                             
                        />
                        <Divider fullWidth formClassName={classes.separador} />                
                        <TextInput source="detallesGeometria" label="resources.flexible_detalles.fields.detallesGeometria" validate={[minLength(0), maxLength(200)]} formClassName={classes.grid_cont12}  />   
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
            <FormTab label="dynatest.resources.flexible_detalles.tabs.estructura" >
                <div className={classes.subtittle}><strong>{translate('resources.flexible_detalles.fields.informacionEstructura')}</strong></div>
                <Divider fullWidth />                
                <ArrayInput 
                    source="tablaSegmento" 
                    style={{width: '100%'}} 
                    label=" " 
                    validate={[required(), arrayCount5]}
                     
                >
                    <SimpleFormIterator 
                        disableAdd={arrayMax}
                    >
                        <NumberInput 
                            source="estructuraEspesor" 
                            label="resources.flexible_detalles.fields.estructuraEspesor" 
                            validate={[ValEspensor, required(), minValue(0), maxValue(20000), Entero]} 
                            formClassName={classes.tabla} style={{width: '100%'}}
                        />        
                        <NumberInput 
                            source="estructuraModulo" 
                            label="resources.flexible_detalles.fields.estructuraModulo" 
                            validate={[required(), minValue(0), maxValue(100000), ValModulo, Entero]} 
                            formClassName={classes.tabla} style={{width: '100%'}}
                        />   
                        <NumberInput 
                            source="estructuraDs" 
                            label="resources.flexible_detalles.fields.estructuraDs" 
                            validate={[required(), minValue(0.01), maxValue(4), valNumDecimals]} 
                            formClassName={classes.tabla} 
                            style={{width: '100%'}} 
                            className="Longitudes_segmentos"
                        />                       
                         
                        <ReferenceInput 
                            label="resources.flexible_detalles.fields.material" 
                            source="material"  
                            reference="materials" 
                            filterToQuery={searchText => ({ material: searchText })} 
                            sort={{field : 'material', order : 'asc'}}
                            onChange={ValidarEspecificacion}
                            validate={[required()]} 
                              
                            formClassName={classes.tabla} 
                        >
                            <AutocompleteInput  options={{fullWidth : true}} optionText="material"  />
                        </ReferenceInput>                        
                        <ReferenceInput 
                            label="resources.flexible_detalles.fields.materialEsp" 
                            source="material" 
                            reference="materials"  
                            sort={{field : 'material', order : 'ASC'}}
                            formClassName={classes.tabla} 
                            style={{width: '100%'}}
                        >
                            <SelectInput  optionText="especificacion" disabled   />
                        </ReferenceInput>   
                        <ReferenceInput 
                            label="resources.flexible_detalles.fields.granulometria" 
                            source="granulometria" 
                            reference="granulometrias" 
                            filterToQuery={searchText => ({ granulometria: searchText })} 
                            filter={{material: material}}
                            sort={{field : 'granulometria', order : 'asc'}}
                             
                            formClassName={classes.tabla} 
                        >
                            <AutocompleteInput optionText="granulometria" />
                        </ReferenceInput>     
                    </SimpleFormIterator>
                </ArrayInput>
                <div></div><br/>                                               
                <DisabledInput 
                    label="resources.flexible_detalles.fields.espesorTotal" 
                    source="espensorTotal" 
                    id="espensorTotalFE" 
                    formClassName={classes.first_field} 
                    className="align-number"
                    defaultValue ={espensortotal}
                />                
                <ArrayInput source="estructuraSegmento" style={{width: '100%'}} label=" "
                    defaultValue={[
                        {
                            igg: null,
                            conceito: null,
                            estructuraNumDiseno: null,
                            estructuraFecha: null,
                            estructuraNumero: null,
                            estructuraNumeroFecha: null,
                            estructuraCbr: null,
                            estructuraCbrFecha: null,
                            estructuraFechaConst: null,
                            estructuraFechaRehab: null,
                            detallesEstructura: null
                        }
                    ]} className="inventariosSegmentos">
                    <SimpleFormIterator disableRemove disableAdd> 
                        <NumberInput 
                            source="igg" 
                            label="resources.flexible_detalles.fields.igg" 
                            validate={[minValue(1), maxValue(200), CalcularCoceito]}
                            formClassName={classes.first_field}
                        />
                        <DisabledInput 
                            id="Conceito" 
                            source="conceito" 
                            label="resources.flexible_detalles.fields.conceito"
                            formClassName={classes.last_filed}
                        /> 
                        <div className={classes.subtittle}><strong>{translate('resources.flexible_detalles.fields.capacidadEstructural')}</strong></div>
                        <Divider fullWidth />
                        <NumberInput source="estructuraNumDiseno" label="resources.flexible_detalles.fields.estructuraNumDiseno" formClassName={classes.first_field} validate={[required(), minValue(0.01), maxValue(20), valNum1Decimal]}/>
                        <DateInput source="estructuraFecha" label="resources.flexible_detalles.fields.estructuraFecha" formClassName={classes.last_filed} validate={[required(), isFechaInvalida]} /> 
                        <div></div>
                        <NumberInput source="estructuraNumero" label="resources.flexible_detalles.fields.estructuraNumero" formClassName={classes.first_field} validate={[required(), minValue(0.01), maxValue(20), valNum1Decimal]}/>
                        <DateInput source="estructuraNumeroFecha" label="resources.flexible_detalles.fields.estructuraNumeroFecha" formClassName={classes.last_filed} validate={[required(), isFechaInvalida]} /> 
                        <div></div>
                        <NumberInput source="estructuraCbr" label="resources.flexible_detalles.fields.estructuraCbr" className="align-number"  formClassName={classes.first_field} validate={[required(), minValue(0), maxValue(100), valNum1Decimal]}/>
                        <DateInput source="estructuraCbrFecha" label="resources.flexible_detalles.fields.estructuraCbrFecha" formClassName={classes.last_filed} validate={[required(), isFechaInvalida]} /> 
                        <div></div>                        
                        <div className={classes.subtittle}><strong>{translate('resources.flexible_detalles.fields.fechasImportantes')}</strong></div>
                        <Divider fullWidth />
                        <DateInput source="estructuraFechaConst" label="resources.flexible_detalles.fields.estructuraFechaConst" formClassName={classes.first_field} validate={[required(), isFechaInvalida]} /> 
                        <DateInput source="estructuraFechaRehab" label="resources.flexible_detalles.fields.estructuraFechaRehab" formClassName={classes.last_filed} validate={[required(), isFechaInvalida]} />                         
                        <Divider fullWidth formClassName={classes.separador} />
                        <TextInput source="detallesEstructura" label="resources.flexible_detalles.fields.detallesEstructura" validate={[minLength(0), maxLength(200)]}  fullWidth/>   
                    </SimpleFormIterator>
                </ArrayInput>
                <FormDataConsumer>
                    {({ formData, ...rest }) => { formData.updatedIn = "api"; }}
                </FormDataConsumer>
            </FormTab>
            <FormTab label="dynatest.resources.flexible_detalles.tabs.fotografias">
                <Grid container spacing={24}>                         
                    <Grid item xs={8}>  
                        {/* <ArrayInput label="" source="images" validate={ImagenesEdit} >
                            <SimpleFormIterator disableAdd disableRemove >
                                <ImageInput multiple={true} source="images" label=" " maxSize={1000000000} accept="image/jpeg, image/png">
                                    <ImageField source="src" title="title" />
                                </ImageInput>                           
                                <ReferenceInput 
                                    disabled 
                                    label="Tipo de elemento" 
                                    source="tipoImagen" 
                                    reference="tipo_images" 
                                    fullWidth 
                                    validate={[required()]} 
                                    sort={{ field: 'nombre', order: 'ASC' }}  
                                    formClassName={classes.grid_cont6}
                                >
                                    <SelectInput  optionText="nombre"  />
                                </ReferenceInput>                          
                            </SimpleFormIterator>
                        </ArrayInput>     */}
                        <FormDataConsumer>
                            {({ formData, ...rest }) =>
                                <ArrayInputImagesEdit record={formData} {...rest} />                                
                            }
                        </FormDataConsumer> 
                        
                    </Grid> 
                    <Grid item xs={4}>    
                        <FormDataConsumer>
                            {({ formData, ...rest }) =>
                                <ButtonCarousel record={formData} {...rest} />                                
                            }
                        </FormDataConsumer>             
                    </Grid>                    
                </Grid>                   
            </FormTab>
        </TabbedForm>
    </Edit>
    )
}));