import React, { useState, Fragment } from 'react';
import PropTypes, { array, element } from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
    required, SelectInput, DateInput, minValue, maxValue, BooleanInput, FormDataConsumer,
    TextInput, NumberInput, ArrayInput, SimpleFormIterator, Loading, ReferenceInput, maxLength,
} from 'react-admin';
import { REDUX_FORM_NAME } from 'react-admin';
import { change } from 'redux-form';
import { styles } from './../../../EstilosGrid';
import { CurrencyInput } from './../../../inputs/CurrencyInput';
import { Entero, isFechaInvalida } from './../../../helpers/validacionesInputs';
import { sources } from './../localHelpers/GlobalVars'
import config from '../../../config/config';
import {
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tabs, Tab, AppBar, Typography, Stepper, Step, StepLabel, Button
} from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ElementsTable from "./../localHelpers/ElementsTable";
import { translate} from 'react-admin'; 
let urlAppjs = config.UrlServer;

/**
 * @param tipoElemento = la entidad a apuntar en el fetcher
 * @param abInicio = la abscisa inicial para el filtro
 * @param abFin = la abscisa final para el filtro
 * 
 */

class AddElementosCosto extends React.Component {
    
    constructor(props) {
        
        super(props)
        this.state = {
            Elementos: [],
            datos: [],
            datosAutoComplete: [],
            backupDatosAutoComplete: [],
            isLoadedElements: false,
            showModal: false,
            tabIndex: 0,
            isGlobalValue: this.props.isGlobalValue,
            valorGlobal: 0,
            sumaTotal: 0

        }
        this.sources = sources
        this.stagedProps = {}
        this.tipoMantenimientos = [ 
            { id: "ru", name: translate("dynatest.resources.costos.select.tipoMantenimientos.Rutinario")},
            { id: "pe", name: translate("dynatest.resources.costos.select.tipoMantenimientos.Periodico") },
            { id: "re", name: translate("dynatest.resources.costos.select.tipoMantenimientos.Rehabilitacion") },
            { id: "ca", name: translate("dynatest.resources.costos.select.tipoMantenimientos.Cambio") }
        ]
        this.projectId = window.localStorage.getItem('id_project');;
        this.fetcher.bind(this);
        this.returnElementsComponent.bind(this);
        this.formatAbscisa.bind(this);
        this.formDataMerger.bind(this);
    }

    componentDidMount() {

    }
    componentDidUpdate() {
        if (JSON.stringify(this.props) != JSON.stringify(this.stagedProps)) {
            if (
                this.props.unidadFuncional != this.stagedProps.unidadFuncional ||
                this.props.tramo != this.stagedProps.tramo ||
                this.props.segmento != this.stagedProps.segmento ||
                this.props.sistema != this.stagedProps.sistema ||
                this.props.tipoElemento != this.stagedProps.tipoElemento ||
                this.props.tipoElementoSenal != this.stagedProps.tipoElementoSenal ||
                this.props.tipoSenal != this.stagedProps.tipoSenal ||
                this.props.abInicio != this.stagedProps.abInicio ||
                this.props.abFin != this.stagedProps.abFin ||
                (
                    (typeof this.props.tipoElemento !== "undefined" && this.props.tipoElemento !== null) ||
                    (typeof this.props.tipoElementoSenal !== "undefined" && this.props.tipoElementoSenal !== null)
                )
            ) {
                if (
                    typeof this.props.unidadFuncional !== "undefined" && this.props.unidadFuncional !== null &&
                    typeof this.props.tramo !== "undefined" && this.props.tramo !== null &&
                    typeof this.props.sistema !== "undefined" && this.props.sistema !== null
                ) {
                    console.log({
                        sistema: this.props.sistema,
                        tipoElemento: this.props.tipoElemento,
                        tipoElementoSeñal: this.props.tipoElementoSenal,
                        tipoSeñal: this.props.tipoSeñal
                    })
                    if (this.props.sistema == "/sistemas/CLZ" || this.props.sistema == "/sistemas/PTS") {
                        this.setState({ Elementos: [], isLoadedElements: true, tabIndex: 0 })
                        if (this.props.tipoElemento) {
                            this.setState({
                                datos: [],
                                datosArchivo: [],
                                Elementos: [],
                            })
                            this.fetcher(
                                this.getTarget("tipoElemento"),
                                this.props.segmento,
                                this.formatAbscisa(this.props.abInicio),
                                this.formatAbscisa(this.props.abFin)
                            );
                        }
                    } else {
                        this.setState({
                            datos: [],
                            datosArchivo: [],
                            Elementos: [],
                        })
                        this.fetcher(
                            this.getTarget("sistema"),
                            this.props.segmento,
                            this.formatAbscisa(this.props.abInicio),
                            this.formatAbscisa(this.props.abFin)
                        );

                    }
                }
            }
            this.stagedProps = this.props
            this.state.isGlobalValue = this.props.isGlobalValue
            // this.componentDidMount();
        }
    }

    fetcher(target, segmento = null, abInicio, abFin, otherFilters = null) {
        console.log()
        this.setState({ isLoadedElements: 'loading' });

        var urlPart = "/" + target + "?aprobacion=Aprobado&pagination=off" +
            "&costo.unidadFuncional.ruta.proyecto=" + localStorage.getItem("id_project") +
            "&unidadFuncional=" + this.props.unidadFuncional +
            "&unidad_funcional=" + this.props.unidadFuncional +
            "&tramo=" + this.props.tramo +
            "&estados=Activo";

        if (typeof this.props.tipoElemento !== "undefined" && this.props.tipoElemento !== null) {
            let tipoElementoString = target == "puentes" ? "elementos" : "tipoElemento"
            urlPart = urlPart + `&${tipoElementoString}=${this.props.tipoElemento}`;
        }
        if (
            typeof this.props.tipoElementoSenal !== "undefined" && this.props.tipoElementoSenal !== null
        ) {
            urlPart = urlPart + "&tipoElementoSenal=" + this.props.tipoElementoSenal;
        }

        if (
            typeof this.props.tipoElementoSenal !== "undefined" && this.props.tipoElementoSenal !== null &&
            typeof this.props.tipoSenal !== "undefined" && this.props.tipoSenal !== null
        ) {
            urlPart = urlPart + "&tipoSenal=" + this.props.tipoSenal;
        }

        if (segmento != null) {
            urlPart = urlPart + "&segmento=" + segmento;
        }
        if(abInicio > 0 && abFin > 0){
            urlPart = urlPart + "&segmento.abInicio[between]=" + abInicio + ".." + abFin;
            urlPart = urlPart + "&segmento.abFin[between]=" + abInicio + ".." + abFin;
        }else if (abInicio > 0) {
            urlPart = urlPart + "&segmento.abInicio[gte]=" + abInicio;
        }else if (abFin > 0) {
            urlPart = urlPart + "&segmento.abFin[lte]=" + abFin;
        }
        if (Array.isArray(otherFilters)) {
            otherFilters.map(filter => {
                urlPart = urlPart + "&" + filter;
            })
        } else if (otherFilters !== null) {
            urlPart = urlPart + "&" + otherFilters;
        }

        fetch(urlAppjs + urlPart,
            {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                return response.json()
            })
            .then((Elementos) => {
                var datos = [];
                var datosAutoComplete = [];
                const getTipoElemento = index => {
                    if (this.props.sistema === "/sistemas/CLZ") {
                        switch (this.props.tipoElemento) {
                            case "/tipo_elementos/PART": return "Segmento/Adoquín";
                            case "/tipo_elementos/POTR": return "Segmento/Otros";
                            case "/tipo_elementos/PFLEX": return "Segmento/Flexible";
                            case "/tipo_elementos/PRIGD": return "Segmento/Rígido";
                        }
                    } else if (this.props.sistema === "/sistemas/PTS") {
                        switch (this.props.tipoElemento) {
                            case "/tipo_elementos/CONT": return `Estructuras de Contención`;
                            case "/tipo_elementos/PTON": return `Pontones`;
                            case "/tipo_elementos/PNTE": return `${index["elementos"]["nombre"]}`;
                            case "/tipo_elementos/PNTEP": return `${index["elementos"]["nombre"]}`;
                            case "/tipo_elementos/TNEL": return `Túneles`;
                        }
                    } else {
                        return this.props.sistema === "/sistemas/SEN" ? `${index["tipoElementoSenal"]["nombre"]} - ${index["tipoSenal"]["nombre"]}` : index["tipoElemento"]["nombre"]
                    }
                }
                this.setState({
                    Elementos: Elementos["hydra:member"],
                    isLoadedElements: true,
                    datos: [],
                    datosAutoComplete: [],
                    backupDatosAutoComplete: []
                })
                this.state.Elementos.map(index => {
                    console.log(index)
                    datosAutoComplete.push({
                        id: index["@id"],
                        name: index["smartCode"],
                        tipoElemento: getTipoElemento(index),
                        abInicio: index["abInicio"],
                        abFin: index["abFin"]
                    })
                })
                this.setState({
                    datos: datos,
                    datosAutoComplete: datosAutoComplete,
                    backupDatosAutoComplete: datosAutoComplete
                })
            }).then(() => {
                this.setState({ isLoadedElements: true })
                this.forceUpdate();
            })
            .catch(function (error) {
                console.log(error)
            });
    }

    getTarget(type) {
        if (type == "sistema") {
            console.log("NOT CLZ nor PTS", this.sources[this.props.sistema.split("/")[2]])
            return this.sources[this.props.sistema.split("/")[2]]
        } else if (type == "tipoElemento") {
            console.log("is CLZ or PTS", this.sources[this.props.tipoElemento.split("/")[2]])
            return this.sources[this.props.tipoElemento.split("/")[2]]
        }
    }

    currencyToNumber(string) {
        if (typeof string == "string") {
            return parseFloat(string.replaceAll(/[\$\.]/g, "").replaceAll(",", "."))
        } else {
            return 0
        }
    }

    returnElementsComponent(classes) {
        const { translate } = this.props;
        const validarElementos = (value) => { 
            const {translate} = this.props;
            var valorAcumulado = 0;

            if (this.state.valorGlobal == null) {
                value.map((item, index) => {
                    valorAcumulado += (typeof item.costoMantenimiento == "string") ? this.currencyToNumber(item.costoMantenimiento) : item.costoMantenimiento;
                })
                this.setState({ valorGlobal: valorAcumulado });
            }

            if (value && Array.isArray(value)) {
                if (value.length == 0) { return translate('resources.costos.elementosADiligenciar') }
            }
        };

        const validarAutoComplete = (array) => {
            const {translate} = this.props;
            if (typeof array !== "undefined") {
                var valorIndividualBulk = Array.isArray(this.state.editedRowDataBulk) ?
                    this.state.editedRowDataBulk[0].hasOwnProperty("costoMantenimiento") ?
                        this.currencyToNumber(this.state.editedRowDataBulk[0].costoMantenimiento)
                        :
                        0
                    :
                    0
                var valorIndividual = (this.state.isGlobalValue) ? this.currencyToNumber(this.state.valorGlobal) / array.length : valorIndividualBulk.toString();
                console.log({
                    valorGlobal: this.currencyToNumber(this.state.valorGlobal),
                    valorIndividual: valorIndividual,
                    arrayLength: array.length,
                    isGlobalValue: this.state.isGlobalValue
                })
                var elementos = [];
                var elemento = [];
                console.log("array")
                console.log(array)
                let filteredArray = array.filter((item, index) => { 
                    console.log(array.indexOf(item))
                    console.log("===")
                    console.log(index)
                    return array.indexOf(item) === index; 
                })
                filteredArray.map(itemArray => {
                    elemento = {
                        item: this.state.Elementos.find(item => item['@id'] == itemArray),
                        index: this.state.Elementos.findIndex(item => item['@id'] == itemArray)
                    }
                    console.log("elemento")
                    console.log(elemento)
                    elementos.push({
                        codigo: elemento.item ? elemento.item["smartCode"] : null,
                        // proveedor: elemento.item? elemento.item.proveedor ? elemento.proveedor["@id"] : elemento.proveedor : null,
                        abInicio: elemento.item ? elemento.item.segmento.abInicio : null,
                        abFin: elemento.item ? elemento.item.segmento.abFin : null,
                        costoMantenimiento: valorIndividual,
                        unidadFuncional:  elemento.item ? (elemento.item.unidad_funcional ? elemento.item.unidad_funcional['@id'] : elemento.item.unidadFuncional['@id']) : null,
                        segmento:  elemento.item ? elemento.item.segmento['@id'] : null,
                        tramo:  elemento.item ? elemento.item.tramo['@id'] : null,
                        tipoElemento: elemento.item.tipoElemento ? elemento.item.tipoElemento['@id'] : null,
                        tipoElementoSenal: elemento.item.tipoElementoSenal ? elemento.item.tipoElementoSenal['@id'] : null,
                        tipoSenal: elemento.item.tipoSenal ? elemento.item.tipoSenal['@id'] : null,
                    })
                })
                console.log("elementos 2")
                console.log(elementos)
            }
            if (elementos && this.state.editedRowDataBulk) {
                let bulkData = this.state.editedRowDataBulk[0]
                let updatedElements = []
                
                elementos.map(item => {
                    console.log("validate => ")
                    console.log(bulkData)
                    console.log("vs")
                    console.log(item)
                    updatedElements.push({
                        abInicio: item.abInicio,
                        abFin: item.abFin,
                        codigo: bulkData.hasOwnProperty("codigo") ? bulkData.codigo : item.codigo,
                        proveedor: bulkData.hasOwnProperty("proveedor") ? bulkData.proveedor : item.proveedor,
                        costoMantenimiento: bulkData.hasOwnProperty("costoMantenimiento") ? bulkData.costoMantenimiento : item.costoMantenimiento,
                        calificacion: bulkData.hasOwnProperty("calificacion") ? bulkData.calificacion : item.calificacion,
                        fechaMantenimiento: bulkData.hasOwnProperty("fechaMantenimiento") ? bulkData.fechaMantenimiento : item.fechaMantenimiento,
                        tipoMantenimiento: bulkData.hasOwnProperty("tipoMantenimiento") ? bulkData.tipoMantenimiento : item.tipoMantenimiento,
                        observaciones: bulkData.hasOwnProperty("observaciones") ? bulkData.observaciones : item.observaciones,
                        unidadFuncional: bulkData.hasOwnProperty("unidadFuncional") ? bulkData.unidadFuncional : item.unidadFuncional,
                        segmento: bulkData.hasOwnProperty("segmento") ? bulkData.segmento : item.segmento,
                        tramo: bulkData.hasOwnProperty("tramo") ? bulkData.tramo : item.tramo,
                        tipoElemento: bulkData.hasOwnProperty("tipoElemento") ? bulkData.tipoElemento : item.tipoElemento,
                        tipoElementoSenal: bulkData.hasOwnProperty("tipoElementoSenal") ? bulkData.tipoElementoSenal : item.tipoElementoSenal,
                        tipoSenal: bulkData.hasOwnProperty("tipoSenal") ? bulkData.tipoSenal : item.tipoSenal

                    })
                })
                console.log("updatedElements")
                console.log(updatedElements)
                this.setState({ datos: updatedElements })
            }
            else if (elementos) { 
                console.log(elementos)
                this.setState({ datos: elementos }) 
            }

            if (Array.isArray(array) && array.length == 0) {
                return translate('resources.costos.unElemento');
            }
        };

        return (
            <FormDataConsumer>
                {({ formData, dispatch, ...rest }) => (
                    <Fragment>
                        <Stepper activeStep={this.state.tabIndex} alternativeLabel>
                            <Step key={"Indicar Valores Predeterminados"}> <StepLabel>{translate('resources.costos.valoresPredeterminados')}</StepLabel> </Step>
                            <Step key={"Seleccionar Elementos"}> <StepLabel>{translate('resources.costos.seleccionarElementos')}</StepLabel> </Step>
                            <Step key={"Diligenciar Datos"}> <StepLabel>{translate('resources.costos.diligenciarDatos')}</StepLabel> </Step>
                        </Stepper>
                        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                            {this.state.tabIndex < 2 &&
                                <Button variant="contained" color="primary" onClick={() => this.setState({ tabIndex: this.state.tabIndex + 1 })} > {translate('ra.navigation.next')} </Button>
                            }
                        </div>
                        <div style={{ display: this.state.tabIndex === 0 ? "unset" : 'none', marginLeft: 10, marginBottom: 10, marginRight: 10, marginTop: 20 }} >
                            <Typography component="p" style={{ color: "#ff9800" }}>{translate('resources.costos.paso3')}</Typography>
                            <Typography component="i" >{translate('resources.costos.sinDiligenciar')}</Typography>
                            {(formData && Array.isArray(formData.costoElementosOnBulkEdit) && formData.costoElementosOnBulkEdit[0].costoMantenimiento) ?
                                <div>
                                    <Typography component="p" style={{ color: "#2196f3" }}>{translate('resources.costos.globalNoDisponible')}</Typography>
                                </div>
                                :
                                <div style={{ width: "100%", display: "flex", justifyContent: "center", flexDirection: "row", alignItems: "flex-end" }}>
                                    <BooleanInput
                                        label="resources.costos.definirCosto"
                                        source="isGlobalValue"
                                        onChange={value => {
                                            this.setState({ valorGlobal: 0 })
                                            dispatch(change(REDUX_FORM_NAME, 'localValorGlobal', ""))
                                        }}
                                        defaultValue={false}
                                        fullWidth
                                    />
                                    <CurrencyInput
                                        label="resources.costos.fields.valorGlobal"
                                        source="localValorGlobal"
                                        fullWidth
                                        disabled={
                                            (
                                                typeof formData !== "undefined" &&
                                                (formData.archivoCarga == null || typeof formData.archivoCarga == "undefined") &&
                                                formData.isGlobalValue == true
                                            ) ? false : true}
                                        validate={[value => this.setState({ valorGlobal: value })]}
                                    />
                                </div>
                            }
                            <ArrayInput
                                source="costoElementosOnBulkEdit"
                                style={{ width: '100%' }}
                                label=" "
                                defaultValue={[{}]}
                                className="inventariosSegmentos"
                                validate={array => this.setState({ editedRowDataBulk: array })}

                            >
                                <SimpleFormIterator
                                    disableAdd
                                    disableRemove
                                >
                                    <SelectInput label="resources.costos.fields.tipoMantenimiento" source="tipoMantenimiento" fullWidth formClassName={classes.fieldCol6} choices={this.tipoMantenimientos} />
                                    <DateInput label="resources.costos.fields.fechaMantenimiento" source="fechaMantenimiento" fullWidth formClassName={classes.fieldCol6} validate={[isFechaInvalida]}/>

                                    {formData && formData.isGlobalValue == false &&
                                        <CurrencyInput label="resources.costos.fields.costoMantenimiento" source="costoMantenimiento" fullWidth formClassName={classes.fieldCol6} />
                                    }
                                    <ReferenceInput
                                        id="proveedorBulk"
                                        label="resources.costos.fields.proveedor"
                                        source="proveedor"
                                        reference="proveedors"
                                        filter={{
                                            sistema: this.props.sistema,
                                            mantenimiento: 1
                                        }}
                                        fullWidth
                                        formClassName={classes.fieldCol6}
                                    >
                                        <SelectInput optionText="razonSocial" fullWidth />
                                    </ReferenceInput>
                                    <NumberInput label="resources.costos.fields.calificacion" source="calificacion" fullWidth formClassName={classes.fieldCol6} validate={[minValue(1), maxValue(5), Entero]}/>
                                    <TextInput label="resources.costos.fields.observaciones" source="observaciones" fullWidth formClassName={(formData && formData.isGlobalValue == false) ? classes.fieldCol6 : classes.fieldCol12} />
                                    <br />
                                </SimpleFormIterator>
                            </ArrayInput>
                        </div>
                        <div style={{ display: this.state.tabIndex === 1 ? "unset" : 'none', marginLeft: 10, marginBottom: 10, marginRight: 10, marginTop: 20 }} >
                            {/* <Typography component="p" style={{ color: "#ff9800" }}>{`Los elementos duplicados seran omitidos.`}</Typography> */}
                            <Typography component="p">{translate('resources.costos.seleccioneElementos')}</Typography>
                            <br />
                            {/* <AutocompleteArrayInput source="elementosId" choices={this.state.datosAutoComplete} fullWidth validate={[required(), validarAutoComplete]} className={classes.fieldCol9} /> */}
                            <ElementsTable
                                label={translate("resources.costos.fields.elementos")}
                                columnNames={[
                                    { id: 'name', name: translate('resources.costos.fields.codigo') },
                                    { id: 'tipoElemento', name: translate('resources.costos.fields.elementos') },
                                    { id: 'abInicio', name: translate('resources.costos.fields.abInicio') },
                                    { id: 'abFin', name: translate('resources.costos.fields.abFin') },
                                ]}
                                formatColumn={[
                                    { id: 'abInicio', formatTo: 'abs' },
                                    { id: 'abFin', formatTo: 'abs' },
                                ]}
                                arrayData={this.state.datosAutoComplete}
                                selectableRows={true}
                                onSelectedRowsChange={props => {
                                    let arrayData = []
                                    props.selectedRows.map(row => { arrayData.push(row.id) })
                                    validarAutoComplete(arrayData)
                                }}
                            />
                        </div>
                        <div style={{ display: this.state.tabIndex === 2 ? "unset" : 'none', marginLeft: 10, marginBottom: 10, marginRight: 10, marginTop: 20 }} >
                            {this.state.datos.length == 0 &&
                                <div style={{ width: "100%", display: "grid", justifyContent: "center", }}>
                                    <br />
                                    <Typography component="p" style={{ color: "#ff9800" }}>{translate('resources.costos.indicarElementos')}</Typography>
                                    <Button variant="contained" color="primary" onClick={() => this.setState({ tabIndex: this.state.tabIndex - 1 })} >  {translate('resources.costos.fields.pasoAnterior')} </Button>
                                </div>
                            }
                            <ArrayInput
                                source="costoElementos"
                                style={{ width: '100%' }}
                                label="resources.costos.fields.registroCostos"
                                defaultValue={this.state.datos}
                                className="inventariosSegmentos"
                                validate={[
                                    required(),
                                    validarElementos,
                                    array => {
                                        let sumaTotal = 0
                                        if (Array.isArray(array)) {
                                            array.map(item => {
                                                if (item.hasOwnProperty("costoMantenimiento")) {
                                                    if (typeof item.costoMantenimiento == "number") {
                                                        sumaTotal += item.costoMantenimiento
                                                    } else if (typeof item.costoMantenimiento == "string") {
                                                        sumaTotal += parseFloat(item.costoMantenimiento.replaceAll("$", "").replaceAll(".", "").replaceAll(",", "."))
                                                    }
                                                }
                                            })

                                            // this.setState({ sumaTotal: sumaTotal })
                                        }
                                        console.log({
                                            isGlobalValue: this.state.isGlobalValue,
                                            sumaTotal: sumaTotal.toString().replaceAll(".", ","),
                                            valorGlobal: this.state.valorGlobal,
                                            valorAcumulado: this.state.valorAcumulado
                                        })
                                        dispatch(change(REDUX_FORM_NAME, 'valorGlobal', `${sumaTotal.toString().replaceAll(".", ",")}`))
                                        // if (!this.state.isGlobalValue) {  }
                                        return ""
                                    }
                                ]}
                            >
                                <SimpleFormIterator
                                    disableAdd
                                    disableRemove
                                >
                                    <TextInput disabled label="resources.costos.fields.codigo" source="codigo" fullWidth formClassName={classes.fieldCol9} validate={[required()]} />
                                    <SelectInput label="resources.costos.fields.tipoMantenimiento" source="tipoMantenimiento" fullWidth formClassName={classes.fieldCol3} choices={this.tipoMantenimientos} validate={[required()]} />
                                    <DateInput label="resources.costos.fields.fechaMantenimiento" source="fechaMantenimiento" fullWidth formClassName={classes.fieldCol3} validate={[required(), isFechaInvalida]} />
                                    <CurrencyInput label="resources.costos.fields.costoMantenimiento" source="costoMantenimiento" fullWidth formClassName={classes.fieldCol3} disabled={this.state.isGlobalValue} validate={[required()]} />
                                    <ReferenceInput label="resources.costos.fields.proveedor" source="proveedor" reference="proveedors" fullWidth formClassName={classes.fieldCol3} filter={{ mantenimiento: 1, sistema: this.props.sistema, }} validate={[required()]} >
                                        <SelectInput optionText="razonSocial" fullWidth />
                                    </ReferenceInput>
                                    <NumberInput label="resources.costos.fields.calificacion" source="calificacion" fullWidth formClassName={classes.fieldCol3} validate={[required(), minValue(1), maxValue(5), Entero]} />
                                    
                                    <ReferenceInput disabled label="resources.costos.fields.unidadFuncional" source="unidadFuncional" reference="unidad_funcionals" fullWidth formClassName={classes.ocultar} validate={[required()]} >
                                        <SelectInput optionText="fullCodigos" fullWidth />
                                    </ReferenceInput>
                                    <ReferenceInput disabled label="resources.costos.fields.tramo" source="tramo" reference="tramos" fullWidth formClassName={classes.ocultar} validate={[required()]} >
                                        <SelectInput optionText="fullCodigos" fullWidth />
                                    </ReferenceInput>
                                    <ReferenceInput disabled label="resources.costos.fields.segmento" source="segmento" reference="segmentos" fullWidth formClassName={classes.ocultar} validate={[required()]} >
                                        <SelectInput optionText="fullCodigos" fullWidth />
                                    </ReferenceInput>

                                    <ReferenceInput allowEmpty disabled label="resources.costos.fields.tipoElementoSenal" source="tipoElementoSenal" reference="tipo_elemento_senals" fullWidth formClassName={classes.ocultar} >
                                        <SelectInput optionText="nombre" fullWidth />
                                    </ReferenceInput>
                                    <ReferenceInput allowEmpty disabled label="resources.costos.fields.tipoElemento" source="tipoElemento" reference="tipo_elementos" fullWidth formClassName={classes.ocultar} >
                                        <SelectInput optionText="nombre" fullWidth />
                                    </ReferenceInput>
                                    <ReferenceInput allowEmpty disabled label="resources.costos.fields.tipoSenal" source="tipoSenal" reference="tipo_senals" fullWidth formClassName={classes.ocultar} >
                                        <SelectInput optionText="nombre" fullWidth />
                                    </ReferenceInput>

                                    <TextInput label="resources.costos.fields.observaciones" source="observaciones" fullWidth formClassName={classes.fieldCol12} validate={[required(), maxLength(200)]} />
                                    <br />
                                </SimpleFormIterator>
                            </ArrayInput>
                        </div>

                    </Fragment>

                )}
            </FormDataConsumer>
        )

    }

    formatAbscisa(abscisa) {
        if (typeof abscisa == "string") {
            let Abinc = abscisa.split("+");
            Abinc[0] = Abinc[0].replace("K", "");
            let ingresoAbInicio = Abinc[0] + "." + Abinc[1];

            return ingresoAbInicio;
        }
        else {
            return abscisa;
        }
    }
    formatter(value, type) {
        let returnValue = null;
        switch (type) {
            case "abs":
                if (value != null && value !== "") {
                    returnValue = "K" + parseFloat(value).toFixed(3).toString().replaceAll(".", "+")
                } else {
                    returnValue = "-"
                }
                break;
            case "date":
                if (value != null && value !== "") {
                    let arrayDate = value.split("\u00a0")[0].split("-")
                    let dateObject = new Date(arrayDate[0], arrayDate[1], arrayDate[2])
                    let day = dateObject.getDate() < 10 ? `0${dateObject.getDate()}` : dateObject.getDate()
                    let month = dateObject.getMonth() < 10 ? `0${dateObject.getMonth()}` : dateObject.getMonth()
                    let year = dateObject.getFullYear()
                    returnValue = `${day}-${month}-${year}`
                } else {
                    returnValue = "-"
                }

                break;
            case "numeric":
                if (value != null && value.toString() != "" && value != undefined) {
                    returnValue = parseFloat(value).toString().replaceAll(".", ",")
                } else {
                    returnValue = "Valor No Diligenciado."
                }
                break;
            default:
                if (value != null && value !== "") {
                    returnValue = value
                } else {
                    returnValue = "-"
                }
        }
        return returnValue
    }

    formDataMerger(formDataItem, aditionalObject) {
        if (this.props.isClone) {
            // console.log(formDataItem)
            var normal = [];
            formDataItem.map((item, index) => {
                normal.push({ ...item, ...aditionalObject[index] })
            })
            return normal
        } else {
            return aditionalObject;
        }
    }

    SimpleModal = (props) => {

        const handleOpen = () => {
            this.setState({ showModal: true });
        };

        const handleClose = () => {
            this.setState({ showModal: false });
        };

        return (
            <div>
                <Dialog
                    open={this.state.showModal}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {props.children}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {props.isCancellable &&
                            <Button
                                onClick={() => { handleClose() }}
                                variant="outlined"
                            >
                                <CancelIcon color="default" />
                            </Button>
                        }
                        <Button
                            onClick={() => { handleClose(); props.onSave() }}
                            variant="outlined"
                        >
                            <CheckCircleIcon color="primary" />
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    render() {
        const { classes } = this.props;
        const { translate} = this.props; 
        const preventSave = (value) => {
            return translate('resources.costos.seleccioneFiltro');
        }

        if (this.state.isLoadedElements == 'loading') {
            return (<Loading />)
        } else {

            if (Array.isArray(this.state.Elementos) && this.state.Elementos.length > 0 &&
                this.state.isLoadedElements == true
            ) {
                return (
                    <Fragment>
                        {this.returnElementsComponent(classes)}
                        {this.state.tabIndex < 2 &&
                            <ArrayInput label=" " source="preventSave" validate={array => { return translate('resources.costos.completeAsistente') }} >
                                <SimpleFormIterator disableAdd disableRemove >
                                    <TextInput disabled label="resources.costos.fields.tipoSenal" source="tipoElemento" fullWidth formClassName={classes.ocultar} />
                                </SimpleFormIterator>
                            </ArrayInput>
                        }
                    </Fragment>

                )
            } else if (
                Array.isArray(this.state.Elementos) &&
                this.state.Elementos.length == 0 &&
                this.state.isLoadedElements == true
            ) {
                return (
                    <Fragment>
                        <ArrayInput label=" " source="preventSave" validate={[preventSave]} >
                            <SimpleFormIterator disableAdd disableRemove >
                                <TextInput disabled label="tipo elemento" source="tipoElemento" fullWidth formClassName={classes.ocultar} />
                            </SimpleFormIterator>
                        </ArrayInput>
                        <div style={{ textAlign: "center" }}>
                            <Typography variant="h2" component="h2">
                                {translate('resources.costos.fields.sinResultados')}
                            </Typography>
                        </div>
                    </Fragment>
                )

            } else {
                return <div style={{ textAlign: "center" }}>
                    <Typography variant="h2" component="h2">
                        {translate('resources.costos.fields.sinResultados')}
                    </Typography>
                </div>
            }
        }
    }
}

AddElementosCosto.propTypes = {
    classes: PropTypes.object.isRequired,
}
const AddElementosCostos = withStyles(styles)(AddElementosCosto);


export default translate(AddElementosCostos);