import React from 'react';
import PercentageFormat from './../format/PercentageFormat';

import { TextInput } from 'react-admin';


export const PercentageInput = (props) => (
    <TextInput 
        {...props} 
        InputProps={{
            inputComponent: PercentageFormat,
        }}
    />
);

