import React, {useState} from 'react';
import { List, Datagrid, TextField, EditButton, ShowButton, Filter, ReferenceInput, AutocompleteInput, 
        NumberField, ArrayField, SingleFieldList, Loading, DateField,SelectInput,ReferenceField, SimpleFormIterator
    } from 'react-admin';
import { Fragment } from 'react';
import {  BulkDeleteWithConfirmButton  } from 'react-admin';
import { translate } from 'react-admin';
import { CardActions, CreateButton, ExportButton } from 'react-admin';

const RutaFilter= translate((props) => {
    const { translate } = props;
    const [uf, setUf] = useState('none');
    const ufSet = (value) => {
        setUf(value);
    }
    const [tramo, setTramo] = useState('none');
    const tramoSet = (value) => {
        setTramo(value);
    }
    return(
    <Filter {...props}>
        <ReferenceInput 
            id="unidadFuncional"
            source="unidadFuncional"  
            reference="unidad_funcionals" 
            filterToQuery={searchText => ({ codigo: searchText })} 
            sort={{field : 'codigo', order : 'asc'}}
            validate={[ufSet]}
            alwaysOn
            fullWidth 
        >
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
        <ReferenceInput 
            source="tramo" 
            alwaysOn 
            reference="tramos" 
            filter={{ unidadFuncional: uf}}
            validate={[tramoSet]}
            filterToQuery={searchText => ({ codigo: searchText })} 
            sort={{ 
                field: 'codigo', 
                order: 'ASC' ,
                
            }}
        >
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
        <ReferenceInput 
            source="segmento" 
            alwaysOn 
            reference="segmentos" 
            sort={{ field: 'codigo', order: 'ASC' }}
            filter={{tramo : tramo}}
            filterToQuery={searchText => ({ codigo: searchText })} 
        >
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
        <SelectInput
                fullWidth
                label="resources.flexible_detalles.fields.estadoDetalles"
                source="detallesSegmento.estadoDetalles" 
                sort={{ field: 'name', order: 'ASC' }}
                alwaysOn
                choices={[
                    { id: translate('dynatest.resources.flexible_detalles.select.estadoDetalles.Activo'), name: 'dynatest.resources.flexible_detalles.select.estadoDetalles.Activo' },
                    { id: translate('dynatest.resources.flexible_detalles.select.estadoDetalles.Inactivo'), name: 'dynatest.resources.flexible_detalles.select.estadoDetalles.Inactivo' },
                ]}
                resettable
            />
    </Filter>
    )
});

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);
const ListAction = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
        <ExportButton basePath={basePath}/>
    </CardActions>
);
const ListAction2 = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
    </CardActions>
);

export const ElementoFlexibleList = ({ permissions, ...props }) => {
    if (!permissions) return <Loading />;  
    return (
    <List {...props} 
        sort={{ field: 'updatedAt', order: 'DESC' }} 
        actions={permissions['flexible']  == 1 ? <ListAction /> : permissions['flexible']  == 2 ? <ListAction2 />: null} 
        filters={<RutaFilter/>} bulkActionButtons={permissions['flexible']  == 1 ? <PostBulkActionButtons />: null}>
        <Datagrid>   
            <TextField  source="smartCode" sortable />   
            <TextField label="resources.flexible_detalles.fields.proveedor" source="detallesSegmento[0].proveedor_.razonSocial" sortable /> 
            <NumberField label="resources.flexible_detalles.fields.esalTime" source="detallesSegmento[0].esalTime" sortable={false} linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}}/>
            <NumberField source="detallesSegmento[0].esalTime10"  label="resources.flexible_detalles.fields.esalTime10" sortable={false} linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} />
            <TextField source="detallesSegmento[0].nivelTrafico" label="resources.flexible_detalles.fields.nivelTrafico" linkType={false} />
            <DateField source="detallesSegmento[0].dateInicioOperacion" label="resources.flexible_detalles.fields.dateInicioOperacion" linkType={false}/>
            <TextField source="detallesSegmento[0].estadoDetalles"  label="resources.flexible_detalles.fields.estadoDetalles" sortable/>
            {permissions['flexible'] == 1 || permissions['flexible'] == 2 || permissions['flexible'] == 4 ? <ShowButton/>: null}
            {permissions['flexible'] == 1 || permissions['flexible'] == 2 ? <EditButton/>: null}
        </Datagrid>
    </List>
)};