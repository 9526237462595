import React from 'react';
import { CardActions, ListButton , EditButton} from 'react-admin';

export const ShowToolbar = ({
    basePath,
    className,
    hasList,
    hasEdit,
    data
}) => (
    <CardActions className={className}>
        {hasList && <ListButton basePath={basePath} />}      
        {hasEdit && <EditButton basePath={basePath} record={data} />} 
    </CardActions>
);