import React, {useState, Fragment, Component, useEffect} from 'react';

import { SelectInput,Filter,ReferenceInput,List, Datagrid, EditButton, ShowButton, AutocompleteInput,
    ReferenceField, BooleanField, CreateButton, TopToolbar, TextInput }  from 'react-admin';
import Grid from '@material-ui/core/Grid';
import {  BulkDeleteWithConfirmButton, Loading  } from 'react-admin';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Collapse from '@material-ui/core/Collapse';
import ErrorIcon from '@material-ui/icons/Error';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import BugReportIcon from '@material-ui/icons/BugReport';
import Slider from './multipleItems';
import { Discovery } from 'aws-sdk';
import styled from "styled-components";
import config from '../../config/config';
import { translate } from 'react-admin';
import TextField from '@material-ui/core/TextField';
import {Link} from 'react-router-dom';

let urlAppjs = config.UrlServer;

const Wrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`;

const ListActions = ({basePath}) => (
    <CardActions>
        {/* <FilterButton/> */}
        <CreateButton basePath={basePath}/>
    </CardActions>
);

const FiltroList = translate((props) => {
    const { translate } = props;
    return(
        <Filter {...props}>
            <TextInput label="resources.carga_masivas.fields.nombreGrupo" source="nombreGrupo" resettable={true} alwaysOn />
        </Filter>
    )
});

export const CargaMasivaList = translate(({ permissions, ...props }) => {
    const { translate } = props;
    if (!permissions) return <Loading />; 
    
    return (
        <List {...props} title="dynatest.resources.carga_masivas.title.listTitle" 
            filters={<FiltroList/>} actions={permissions['cargaMasiva']  == 1 || permissions['cargaMasiva']  == 2 ? <ListActions /> : null} 
            bulkActionButtons={false} sort={{ field: 'id', order: 'DESC' }}>
            <ListGridCustom translate={translate} />
        </List>
    )
});

const ListGridCustom = ({ ids, data, basePath, translate }) => (
    <div>
        {ids.map(id => {
            let countProgress = 0;
            let countTotal = 0;
            if(data[id].cargaMasivaArchivos.length > 0){
                data[id].cargaMasivaArchivos.map((archive, ia) => {
                    if(archive.estado == 1){
                        countProgress++;
                    }
                    countTotal++;
                });
            }
            return(
                <div xs={12}>
                    <Card variant="outlined">
                        <CardContent>
                            <Grid container className="margin_bottom_5">
                                <Grid item xs={8}>
                                    {countProgress == 0 ?
                                            <div><strong> {data[id].nombreGrupo} </strong>  &nbsp; <CheckCircleIcon style={{color:'#1daf03'}} size={18} /> &nbsp; {translate('resources.carga_masivas.fields.cargados')} ({countTotal})</div>
                                        :
                                            <div><strong> {data[id].nombreGrupo} </strong>  &nbsp; <CircularProgress size={18} /> &nbsp; {translate('resources.carga_masivas.fields.enProceso')} ({countProgress}/{countTotal}) </div>
                                    }
                                </Grid>
                                <Grid item xs={4} align={'right'}>
                                    <Link to={{pathname: basePath + data[id]['@id'], idCargaMasiva: data[id]['@id']}} style={{textDecoration:'none'}} >
                                        <Button style={{color:'#3f51b5'}}>{translate('dynatest.resources.carga_masivas.button.verTodos')}</Button>
                                    </Link>
                                </Grid>
                            </Grid>
                            <br></br>
                            <Wrapper>
                                <Slider dataCard={data[id].cargaMasivaArchivos} translate={translate} />
                            </Wrapper>
                        </CardContent>
                    </Card>
                    <br></br>
                    <br></br>
                </div>
            )
        })}
    </div>
);

ListGridCustom.defaultProps = {
    data: {},
    ids: [],
};