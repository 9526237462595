import React from 'react';

import { SelectInput } from 'react-admin';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import DatosDeterioros from './datos';



class TablaDeterioro extends React.Component{

    constructor(props){

        super(props);   
        
        this.state = {
            filas: [],
            add: false,
            index: 1,
        }       

        console.log(this.props.segmento)

    }

    addRegistro(e){
        
        const Deterioro = document.getElementById('Deterioro')
        const Unidad = document.getElementById('Unidad')
        const Sbaja = document.getElementById('Sbaja')
        const Smedia = document.getElementById('Smedia')
        const Salta = document.getElementById('Salta')

        let valorBaja = Sbaja.value*2; //OJO CAMBIAR POR VALOR CORRECTO
        let valorMedia = Smedia.value*4; //OJO CAMBIAR POR VALOR CORRECTO
        let valorAlta = Salta.value*6; //OJO CAMBIAR POR VALOR CORRECTO

        let sum = parseInt(valorBaja) + parseInt(valorMedia) + parseInt(valorAlta);

       let newFila = {
           DeterioroS: Deterioro.value,
           UnidadS: Unidad.value,
           SbajaS: Sbaja.value,
           bajaT: valorBaja,
           SmediaS: Smedia.value,
           mediaT: valorMedia,
           SaltaS: Salta.value,
           altaT: valorAlta,
           total: sum,
           id: this.state.index,
       }

       let auxFilas = this.state.filas
       auxFilas.unshift(newFila)

       this.setState({
        filas: auxFilas,
        add: false,
        index: this.state.index + 1,
       })

       Deterioro.value = null
       Unidad.value = null
       Sbaja.value = null
       Smedia.value = null
       Salta.value = null
        
    }

    agregar(e){

        if(this.state.add === false){
            this.setState({
                add: true
            })
        }else{
            this.setState({
                add: false
            })
        }

    }

    render() {

        if(this.state.filas.length > 0){
            return (
                <div>
                    <div>
                        <button color="primary" onClick={this.agregar.bind(this)} style={{borderRadius: '50%', padding: 0, marginTop: '10px', border: 'none', backgroundColor: '#3f51b5', color: 'white', width: '30px', height: '30px', cursor: 'pointer'}}>
                            { this.state.add ? <span>-</span> : <span>+</span> }
                        </button>
                    </div>                                                            
                    <div style={this.state.add ? {display:'block'} : {display:'none'}}>
                        <div style={{marginTop:'5px'}}>
                            <TextField id="Deterioro" type="text" label= "Deterioro" style={{marginRight: '20px', width: '28%'}}/> 
                            <TextField id="Unidad" type="text" label= "Unidad" style={{width: '28%'}}/>                            
                        </div>
                        <div style={{marginTop:'5px'}}>
                            <TextField id="Sbaja" type="number" label= "Severidad baja  (Longitud o Área)" style={{marginRight: '20px', width: '28%'}}/> 
                            <TextField id="Smedia" type="number" label= "Severidad media  (Longitud o Área)" style={{marginRight: '20px', width: '28%'}}/> 
                            <TextField id="Salta" type="number" label= "Severidad Alta (Longitud o Área)" style={{width: '25%'}}/>                         
                        </div>
                        <Button variant="contained" color="primary" onClick={this.addRegistro.bind(this)} style={{marginTop:'15px'}}>
                            Agregar
                        </Button> 
                    </div>
                    <table class="table-seg-Flex">                
                        <thead>
                            <tr>
                                <th align="center">Deterioro</th>
                                <th align="center">Unidad</th>
                                <th align="center">Severidad baja  (Longitud o Área)</th>
                                <th align="center">Severidad baja (%)</th>                            
                                <th align="center">Severidad media  (Longitud o Área)</th>
                                <th align="center">Severidad Media(%)</th>
                                <th align="center">Severidad Alta (Longitud o Área)</th>
                                <th align="center">Severidad Alta(%)</th>                        
                                <th align="center">Suma % afectado</th>      
                                <th align="center"></th> 
                            </tr>
                        </thead>
                        <tbody>          
                            {
                                this.state.filas.map(filas=>(
                                    <DatosDeterioros 
                                        DeterioroD={filas.DeterioroS}
                                        UnidadD={filas.UnidadS}
                                        SbajaD={filas.SbajaS}
                                        SbajaDT={filas.bajaT}
                                        SmediaD={filas.SmediaS}
                                        SmediaDT={filas.mediaT}
                                        SaltaD={filas.SaltaS}
                                        SaltaDT={filas.altaT}
                                        TotalD={filas.total}
                                        IdFila={filas.id}
                                    />                                
                                ))
                            }                            
                        </tbody>                        
                    </table>   
                </div>            
            ) 

        }else{
            return(
                <div>
                    <div>
                        <button color="primary" onClick={this.agregar.bind(this)} style={{borderRadius: '50%', padding: 0, marginTop: '10px', border: 'none', backgroundColor: '#3f51b5', color: 'white', width: '30px', height: '30px', cursor: 'pointer'}}>                            
                            { this.state.add ? <span>-</span> : <span>+</span> }
                        </button>
                    </div>                                                            
                    <div style={this.state.add ? {display:'block'} : {display:'none'}}>
                        <div>
                        {/* <TextField id="Deterioro" type="text" label= "Deterioro" style={{marginRight: '20px', width: '28%'}}/>  */}
                        {/* <TextField id="Unidad" type="text" label= "Unidad" style={{width: '28%'}}/>  */}
                        <SelectInput source="Deterioro_td" id="Deterioro" label="Deterioro" choices={[
                            { id: 'Fisura de Piel de Cocodrilo o Falla por Fatiga', name: 'Fisura de Piel de Cocodrilo o Falla por Fatiga' },
                            { id: 'Sangrado o Exudación', name: 'Sangrado o Exudación' },
                            { id: 'Agrietamiento en bloque', name: 'Agrietamiento en bloque' },
                            { id: 'Abultamientos y hundimientos', name: 'Abultamientos y hundimientos' },
                            { id: 'Corrugación ', name: 'Corrugación ' },
                            { id: 'Depresión', name: 'Depresión' },
                            { id: 'Fisura de borde', name: 'Fisura de borde' },
                            { id: 'Fisura de reflexion de junta', name: 'Fisura de reflexion de junta' },
                            { id: 'Desnivel carril/ berma', name: 'Desnivel carril/ berma' },
                            { id: 'Fisura longitudinal y transversal', name: 'Fisura longitudinal y transversal' },
                            { id: 'Parche', name: 'Parche' },
                            { id: 'Pulimiento de agregados', name: 'Pulimiento de agregados' },
                            { id: 'Bache', name: 'Bache' },
                            { id: 'Cruce de vía ferrea', name: 'Cruce de vía ferrea' },
                            { id: 'Ahuellamiento ', name: 'Ahuellamiento ' },
                            { id: 'Desplazamiento ', name: 'Desplazamiento ' },
                            { id: 'Fisura parabolica', name: 'Fisura parabolica' },
                            { id: 'Hinchamiento ', name: 'Hinchamiento ' },
                            { id: 'Desprendimiento de agregados', name: 'Desprendimiento de agregados' },
                            { id: 'Envejecimiento', name: 'Envejecimiento' },                   
                        ]} style={{marginRight: '20px', width: '28%'}} /> 
                        <SelectInput source="Unidad_td" id="Unidad" label="Unidad" choices={[
                            { id: 'und', name: 'und' },
                            { id: 'm', name: 'm' },
                            { id: 'm²', name: 'm²' },                    
                        ]} style={{width: '28%'}}/>  
                            {/* <FormControl style={{marginRight: '20px', width: '28%'}}>
                                <InputLabel >Deterioro</InputLabel>
                                <Select id="Deterioro" label="Deterioro">
                                <MenuItem value='Fisura de Piel de Cocodrilo o Falla por Fatiga'>Fisura de Piel de Cocodrilo o Falla por Fatiga</MenuItem>
                                <MenuItem value='Sangrado o Exudación'>Sangrado o Exudación</MenuItem>
                                <MenuItem value='Agrietamiento en bloque'>Agrietamiento en bloque</MenuItem>
                                <MenuItem value='Abultamientos y hundimientos'>Abultamientos y hundimientos</MenuItem>
                                <MenuItem value='Corrugación '>Corrugación </MenuItem>
                                <MenuItem value='Depresión'>Depresión</MenuItem>
                                <MenuItem value='Fisura de borde'>Fisura de borde</MenuItem>
                                <MenuItem value='Fisura de reflexion de junta'>Fisura de reflexion de junta</MenuItem>
                                <MenuItem value='Desnivel carril/ berma'>Desnivel carril/ berma</MenuItem>
                                <MenuItem value='Fisura longitudinal y transversal'>Fisura longitudinal y transversal</MenuItem>
                                <MenuItem value='Parche'>Parche</MenuItem>
                                <MenuItem value='Pulimiento de agregados'>Pulimiento de agregados</MenuItem>
                                <MenuItem value='Bache'>Bache</MenuItem>
                                <MenuItem value='Cruce de vía ferrea'>Cruce de vía ferrea</MenuItem>
                                <MenuItem value='Ahuellamiento '>Ahuellamiento </MenuItem>
                                <MenuItem value='Desplazamiento '>Desplazamiento </MenuItem>
                                <MenuItem value='Fisura parabolica'>Fisura parabolica</MenuItem>
                                <MenuItem value='Hinchamiento '>Hinchamiento </MenuItem>
                                <MenuItem value='Desprendimiento de agregados'>Desprendimiento de agregados</MenuItem>
                                <MenuItem value='Envejecimiento'>Envejecimiento</MenuItem>                                                                
                                </Select>
                            </FormControl>
                            <FormControl style={{width: '28%'}}>
                                <InputLabel >Unidad</InputLabel>
                                <Select id="Unidad" label="Unidad">
                                    <MenuItem value='und'>und</MenuItem>
                                    <MenuItem value='m'>m</MenuItem>
                                    <MenuItem value='m²'>m²</MenuItem>                                    
                                </Select>
                            </FormControl>                             */}
                        </div>
                        <div>
                            <TextField id="Sbaja" type="number" label= "Severidad baja  (Longitud o Área)" style={{marginRight: '20px', width: '28%'}}/> 
                            <TextField id="Smedia" type="number" label= "Severidad media  (Longitud o Área)" style={{marginRight: '20px', width: '28%'}}/> 
                            <TextField id="Salta" type="number" label= "Severidad Alta (Longitud o Área)" style={{width: '25%'}}/>                         
                        </div>
                        <Button variant="contained" color="primary" onClick={this.addRegistro.bind(this)} style={{marginTop:'15px'}}>
                            Agregar
                        </Button> 
                    </div>                                       
                </div>  
            )
        }
      }
}
export default TablaDeterioro;

