import React, { useState, Fragment } from 'react'
import { CirclePicker, SketchPicker, ChromePicker } from 'react-color';
import Grid from '@material-ui/core/Grid';
import TuneIcon from '@material-ui/icons/Tune';
import SvgIcon from '@material-ui/core/SvgIcon';
import Button from '@material-ui/core/Button';
import { TextInput, SelectInput, NumberInput, FormDataConsumer } from 'react-admin';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Component } from 'react';
import { translate } from 'react-admin';
/* import { withTranslate } from 'react-admin'; */

class BotonFiltro extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            filtros: false,
            config: false,
            color: [false, false, false],
            backgroundColor: ["green","green","green"],
            umbral1: 2,
            umbral2: 2.5,
            tipoMapa: 2,
            intervalos: 1,
            umbrales1: [],
            umbrales2: [],
        }
        this.VerFiltros = this.VerFiltros.bind(this)
        this.VerConfig = this.VerConfig.bind(this)
        this.Vercolor = this.Vercolor.bind(this)
        this.validarUmbral1 = this.validarUmbral1.bind(this)
        this.validarUmbral2 = this.validarUmbral2.bind(this)
        this.validateTipoMapa = this.validateTipoMapa.bind(this)
        this.Intervalos = this.Intervalos.bind(this)
        this.MostrarUmbrales = this.MostrarUmbrales.bind(this)
    }
    VerFiltros(value) {
        this.setState({ filtros: !this.state.filtros, config: false })
        let filtros = document.getElementById('filtrosCambio')
        if (filtros) {
            if (filtros.style.display === "none") {
                filtros.style.display = ""
            } else {
                filtros.style.display = "none"
            }
        }
    }
    VerConfig(value) {
        this.setState({ config: !this.state.config, filtros: false })
        let filtros = document.getElementById('filtrosCambio')
        if (filtros) {
            filtros.style.display = "none"
        }
    }
    Vercolor(e, pos) {
        let valor = this.state.color
        valor[pos] = !valor[pos]
        this.setState({ color: [...valor] })
        
        if (e.hex) {
            // console.log(this.state.backgroundColor)
            
            let back = this.state.backgroundColor
            back[pos] = e.hex
            this.setState({ backgroundColor: [...back] })
            this.setState({ backgroundColor: this.state.backgroundColor })
        }

    }
    validarUmbral1(value) {
        this.setState({ umbral1: value })
    }
    validarUmbral2(value) {
        this.setState({ umbral2: value })
    }
    validateTipoMapa(id) {
        this.setState({ tipoMapa: id })
        if (id === 1) {
            let filtros = document.getElementById('filtrosCambio')
            if (filtros) {
                filtros.style.display = "none"
            }
        } else if (id === 2) {
            this.setState({ config: false })
        }
        //this.props.validarP(id);
    }
    Intervalos(valor) {
        this.setState({ intervalos: Number(valor) })
    }
    MostrarUmbrales() {
        let umbrales = [];
        let contadorource = 1;
        let defaultval = 2;
        for (let i = 0; i < this.state.intervalos; i++) {
            umbrales[i] = { source1: `${contadorource}`, defaultValue1: defaultval }
            contadorource++;
            defaultval += 0.5;
            umbrales[i] = { ...umbrales[i], defaultValue2: defaultval }
            contadorource++;
        }
        return umbrales
    }

    MostrarColores() {
        let colores = [];
        let contadorource = 1;
        for (let i = 0; i <= this.state.intervalos; i++) {
            colores[i] = { source: `${contadorource}` }
            contadorource++;
        }
        return colores
    }
    render() {
        const { translate } = this.props;
        return (
            <Grid item xs={12}>
                <Grid item xs={12}>
                    {/*Debido a que se pidió mover este select al área de filtros, se duplicó este mismo elemento en MapaList y se ocultó este para que funcionaran la validación*/}
                    <SelectInput source="tipoMapa" label="Función del Mapa" choices={[
                        { id: 1, name: 'Indicadores' },
                        { id: 2, name: 'Inventario' },
                    ]} validate={this.validateTipoMapa} style={{ display: "none" }} />
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" color="black" onClick={this.VerConfig} disabled={this.state.tipoMapa === 1 ? false : true}>
                        <TuneIcon />
                    </Button>
                    <Button variant="contained" color="black" onClick={this.VerFiltros} disabled={this.state.tipoMapa === 2 ? false : true}>
                        <SvgIcon>
                            <polygon points="0,0 22,0, 13,11 13,22 9,22, 9,11" />
                            {/* <rect x="9" y="0" width="4" height="20" /> */}
                        </SvgIcon>
                    </Button>
                </Grid>
                <Grid item xs={12} style={{ display: this.state.config ? "" : "none" }}>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            {/* <Typography >Rangos y Colores</Typography>  */} 
                            <Typography variant="h6">{translate('dynatest.resources.elementos.titleIndicadores.rangosColores')}</Typography>  
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            <Grid container spacing={10}>
                                <Grid item xs={12}>
                                    {/* <Typography variant="h6">IRI</Typography> */}
                                    <Typography variant="h6">{translate('dynatest.resources.elementos.titleIndicadores.iri')}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <SelectInput fullWidth label='resources.elementos.fields.numIntervalos' source="numIntervalos" choices={[
                                        { id: '1', name: 'dynatest.resources.elementos.select.numIntervalos.1' },
                                        { id: '2', name: 'dynatest.resources.elementos.select.numIntervalos.2' },
                                        { id: '3', name: 'dynatest.resources.elementos.select.numIntervalos.3' },
                                        { id: '4', name: 'dynatest.resources.elementos.select.numIntervalos.4' },
                                        { id: '5', name: 'dynatest.resources.elementos.select.numIntervalos.5' },
                                        { id: '6', name: 'dynatest.resources.elementos.select.numIntervalos.6' },
                                        { id: '7', name: 'dynatest.resources.elementos.select.numIntervalos.7' },
                                        { id: '8', name: 'dynatest.resources.elementos.select.numIntervalos.8' },
                                    ]} defaultValue="1" validate={this.Intervalos} />
                                </Grid>
                                <Grid container spacing={24}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                {translate('dynatest.resources.elementos.titleIndicadores.umbrales')}
                                            </Grid>
                                            {/* {console.log(this.MostrarUmbrales())} */}
                                            <Grid container spacing={24}>
                                                {
                                                    this.MostrarUmbrales().map((item, i) => (
                                                        <Grid item xs={6}>
                                                            <NumberInput label={translate('resources.elementos.fields.umbral') + (i + 1)} fullWidth source={"umbral" + (i + 1)} defaultValue={item.defaultValue1} />
                                                        </Grid>
                                                    ))
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} item xs={12} alignItems="center">
                                        <Grid item xs={12}>
                                        {translate('dynatest.resources.elementos.titleIndicadores.colores')}
                                        </Grid>
                                        {this.MostrarColores().map((item, i) => (
                                            <FormDataConsumer style={{ display: "none" }}>
                                                {({ formData, dispatch, ...rest }) => {
                                                    let countColor = this.state.intervalos;
                                                    if(i== 0){
                                                        if (typeof formData != 'undefined' && formData['umbral' + item.source]) {
                                                            return (
                                                                <Fragment>
                                                                    {formData['umbral' + (i + 1)] != undefined &&
                                                                        <Grid container spacing={2}>
                                                                            <Grid item xs={1}>
                                                                                <div onClick={ev => this.Vercolor(ev, Number(item.source))} id="colorSelectJ" style={{ border: '1px solid black', width: "10px", height: "10px", background: this.state.backgroundColor[Number(item.source)] ? this.state.backgroundColor[Number(item.source)] : "", cursor: "pointer" }}></div>
                                                                                <TextInput source={"color" + (Number(item.source))} defaultValue={this.state.backgroundColor[Number(item.source)] ? this.state.backgroundColor[Number(item.source)] : ""} style={{ display: "none" }} />
                                                                            </Grid>
                                                                            <Grid item xs={11} style={{ textAlign: "center" }}>
                                                                                {"x<" + formData['umbral' + (i + 1)]}
                                                                            </Grid>
                                                                            <Grid item xs={12} >
                                                                                <div style={{ display: this.state.color && this.state.color[Number(item.source)] ? "" : "none" }}><CirclePicker onChangeComplete={ev => this.Vercolor(ev, Number(item.source))} width="100%" /></div>
                                                                            </Grid>
                                                                        </Grid>
                                                                    }
                                                                </Fragment>
                                                            )
                                                        }
                                                    }else if(i== this.state.intervalos) {
                                                        if (typeof formData != 'undefined' && formData['umbral' + (item.source - 1)]) {
                                                            return (
                                                                <Fragment>
                                                                    {formData['umbral' + (i)] != undefined &&
                                                                        <Grid container spacing={2}>
                                                                            <Grid item xs={1}>
                                                                                <div onClick={ev => this.Vercolor(ev, Number(item.source))} id="colorSelectL" style={{ border: '1px solid black', width: "10px", height: "10px", background: this.state.backgroundColor[Number(item.source)] ? this.state.backgroundColor[Number(item.source)] : "", cursor: "pointer" }}></div>
                                                                                <TextInput source={"color" + (Number(item.source))} defaultValue={this.state.backgroundColor[Number(item.source)] ? this.state.backgroundColor[Number(item.source)] : ""} style={{ display: "none" }} />
                                                                            </Grid>
                                                                            <Grid item xs={11} style={{ textAlign: "center" }}>
                                                                                { "x>=" + formData['umbral' + (i)]}
                                                                            </Grid>
                                                                            <Grid item xs={12} >
                                                                                <div style={{ display: this.state.color && this.state.color[Number(item.source)] ? "" : "none" }}><CirclePicker onChangeComplete={ev => this.Vercolor(ev, Number(item.source))} width="100%" /></div>
                                                                            </Grid>
                                                                        </Grid>
                                                                    }
                                                                </Fragment>
                                                            )
                                                        }
                                                    } else {
                                                        if (typeof formData != 'undefined' && formData['umbral' + item.source]) {
                                                            return (
                                                                <Fragment>
                                                                    {formData['umbral' + (i)] != undefined &&
                                                                        <Grid container spacing={2}>
                                                                            <Grid item xs={1}>
                                                                                <div onClick={ev => this.Vercolor(ev, Number(item.source))} id="colorSelectK" style={{ border: '1px solid black', width: "10px", height: "10px", background: this.state.backgroundColor[Number(item.source)] ? this.state.backgroundColor[Number(item.source)] : "", cursor: "pointer" }}></div>
                                                                                <TextInput source={"color" + (Number(item.source))} defaultValue={this.state.backgroundColor[Number(item.source)] ? this.state.backgroundColor[Number(item.source)] : ""} style={{ display: "none" }} />
                                                                            </Grid>
                                                                            <Grid item xs={11} style={{ textAlign: "center" }}>
                                                                                {formData['umbral' + (i)] + "=<x<" + formData['umbral' + (i + 1)]}
                                                                            </Grid>
                                                                            <Grid item xs={12} >
                                                                                <div style={{ display: this.state.color && this.state.color[Number(item.source)] ? "" : "none" }}><CirclePicker onChangeComplete={ev => this.Vercolor(ev, Number(item.source))} width="100%" /></div>
                                                                            </Grid>
                                                                        </Grid>
                                                                    }
                                                                </Fragment>
                                                            )
                                                        }
                                                    }


                                                }}
                                            </FormDataConsumer>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography>{translate('dynatest.resources.elementos.titleIndicadores.visualizacion')}</Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                            {translate('dynatest.resources.elementos.titleIndicadores.pantallaConfiguración')}
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </Grid>
            </Grid>
        )
    }
}

export default translate(BotonFiltro);