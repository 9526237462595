import React, { useState, Fragment } from 'react';
import {
    BooleanInput, ReferenceInput, SelectInput, Toolbar, SaveButton, DateInput, FormTab, TabbedForm, required,
    TextInput, Query, Loading, NumberInput, FormDataConsumer, ArrayInput, ReferenceField, SimpleFormIterator, AutocompleteInput
} from 'react-admin';
import { PRInput } from '../../../inputs/PRInput';
import { PimsActionsWithoutImage } from './../../../helpers/PimsActionsWithoutImage';
import { styles } from './../../../EstilosGrid';
import { PimsCreate } from './../../Create';
import AddElementosMantenimientoParticular from './../localHelpers/AddElementosMantenimientoParticular';
import Grid from '@material-ui/core/Grid';
import CancelIcon from '@material-ui/icons/Cancel';
import { withStyles } from '@material-ui/core';
import { REDUX_FORM_NAME } from 'react-admin';
import { change } from 'redux-form';
import config from '../../../config/config';
import { translate } from 'react-admin';
let urlAppjs = config.UrlFront;

export const PoliticasMantenimientoParticularCreate = translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;

    const LinkCancelar = () => {
        let redirect = "elemento_reds"
        return <a href={urlAppjs + redirect} class="cancelar" ><CancelIcon style={{ width: '0.8em', paddingRight: '8px' }} />CANCELAR</a>;
    }
    const CustomToolbar = ({ basePath, data, ...props }) => (
        <Toolbar {...props} >
            <SaveButton />
            <LinkCancelar />
        </Toolbar>
    );

    const [getSistema, setSistema] = useState();
    const regSistema = (value) => {
        setSistema(value);
    }

    const [getTipoElemento, setTipoElemento] = useState();
    const regTipoElemento = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setTipoElemento(valor)
    }

    const [getUnidadFuncional, setUnidadFuncional] = useState();
    const validarUnidadFuncional = (value) => {
        setUnidadFuncional(value);
    }
    const [getTramo, setTramo] = useState(null);
    const validarTramo = (value) => {
        setTramo(value);
    }

    const [getAbscisaInicial, setAbscisaInicial] = useState(0);
    const validarAbscisaInicial = (value) => {
        if (value) {
            var val = value.split("K")[1].split("+")
            val = val[0] + "." + val[1];
            val = parseFloat(val);
            setAbscisaInicial(val);
        }
    }

    const [getAbscisaFinal, setAbscisaFinal] = useState(0);
    const validarAbscisaFinal = (value) => {
        if (value) {
            var val = value.split("K")[1].split("+")
            val = val[0] + "." + val[1];
            val = parseFloat(val);
            setAbscisaFinal(val);
        }
    }

    const [shouldShowOnReduxChange, setShouldShowOnReduxChange] = useState(true);


    return (
        <PimsCreate  {...props} actions={<PimsActionsWithoutImage />} >
            <TabbedForm
                redirect="list"
            //toolbar={<CustomToolbar />}
            >
                {/* ------ ELEMENTO DE MANTENIMIENTO ------ */}
                <FormTab label="dynatest.resources.politicas_mantenimiento_particulars.tabs.tabsTitle.identificacion" redirect="list" >
                    <Grid container justify="flex-start" alignItems="flex-end" spacing={24}>
                        <Grid item xs={6}>
                            <TextInput label="resources.politicas_mantenimiento_particulars.fields.nombre" source="nombre" validate={[required()]} fullWidth />
                        </Grid>
                        <FormDataConsumer>
                            {
                                ({ formData, dispatch, ...rest }) => (
                                    <Fragment>
                                        <Grid item xs={3}>
                                            <ReferenceInput
                                                label="resources.politicas_mantenimiento_particulars.fields.sistema"
                                                source="sistema"
                                                reference="sistemas"
                                                filter={{
                                                    visibleOnPolicies: 1
                                                }}
                                                validate={[required(), regSistema]}
                                                onChange={value => {
                                                    dispatch(change(REDUX_FORM_NAME, 'tipoElemento', null))
                                                    setShouldShowOnReduxChange(false)
                                                }}
                                                fullWidth
                                            >
                                                <SelectInput optionText="nombreEnPoliticas" fullWidth />
                                            </ReferenceInput>
                                        </Grid>
                                        <Grid item xs={3}>
                                            {formData != undefined && formData.sistema != "/sistemas/SEN" ?
                                                <Fragment>
                                                    {shouldShowOnReduxChange ?
                                                        <ReferenceInput
                                                            label="resources.politicas_mantenimiento_particulars.fields.tipoElemento"
                                                            source="tipoElemento"
                                                            reference="tipo_elementos"
                                                            fullWidth
                                                            filter={{
                                                                visibleOnPolicies: 1,
                                                                sistema: formData.sistema
                                                            }}
                                                            sort={{ field: 'nombre', order: 'ASC' }}
                                                            onChange={regTipoElemento}
                                                            validate={[required()]}
                                                            formClassName={classes.fieldCol9}
                                                        >
                                                            <SelectInput optionText="nombre" />
                                                        </ReferenceInput>
                                                        :
                                                        setShouldShowOnReduxChange(true)
                                                    }
                                                </Fragment>
                                                :
                                                <ReferenceInput
                                                    label="resources.politicas_mantenimiento_particulars.fields.tipoElementoSenal"
                                                    source="tipoElementoSenal"
                                                    reference="tipo_elemento_senals"
                                                    fullWidth
                                                    filter={{
                                                        visibleOnPolicies: 1,
                                                        sistema: formData ? formData.sistema : null
                                                    }}
                                                    onChange={regTipoElemento}
                                                    validate={[required()]}
                                                    formClassName={classes.fieldCol9}
                                                >
                                                    <SelectInput optionText="nombre" />
                                                </ReferenceInput>
                                            }
                                        </Grid>
                                    </Fragment>
                                )
                            }
                        </FormDataConsumer>
                    </Grid>
                    <FormDataConsumer>
                        {
                            ({ formData, dispatch, ...rest }) => (
                                <Fragment>
                                    <Grid container spacing={24}>
                                        <Grid item xs={3}>
                                            <ReferenceInput
                                                id="departamento"
                                                // value="" 
                                                label="resources.politicas_mantenimiento_particulars.fields.unidadFuncional"
                                                source="unidadFuncional"
                                                reference="unidad_funcionals"
                                                onChange={value => dispatch(
                                                    change(REDUX_FORM_NAME, 'tramo', null)
                                                )}
                                                validate={[required(), validarUnidadFuncional]}
                                            >
                                                <AutocompleteInput optionText="fullCodigos" options={{ fullWidth: true }} fullWidth />
                                            </ReferenceInput>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <ReferenceInput
                                                label="resources.politicas_mantenimiento_particulars.fields.tramo"
                                                source="tramo"
                                                reference="tramos"
                                                validate={[validarTramo]}
                                                filter={{
                                                    unidadFuncional: getUnidadFuncional
                                                }}
                                            >
                                                <AutocompleteInput optionText="fullCodigos" options={{ fullWidth: true }} fullWidth />
                                            </ReferenceInput>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <PRInput label="resources.politicas_mantenimiento_particulars.fields.abInic" source="abInic" fullWidth validate={[validarAbscisaInicial]} />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <PRInput label="resources.politicas_mantenimiento_particulars.fields.abFin" source="abFin" fullWidth validate={[validarAbscisaFinal]} />
                                        </Grid>
                                    </Grid>
                                </Fragment>
                            )
                        }
                    </FormDataConsumer>
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <FormDataConsumer>
                                {({ formData, dispatch, ...rest }) =>
                                    <Fragment>
                                        {formData &&
                                            (formData.tipoElemento || formData.tipoElementoSenal) &&
                                            formData.unidadFuncional &&
                                            <AddElementosMantenimientoParticular
                                                unidadFuncional={formData.unidadFuncional}
                                                tramo={formData.tramo}
                                                tipoElemento={formData.tipoElemento}
                                                tipoElementoSenal={formData.tipoElementoSenal}
                                                abInicio={formData.abInic}
                                                abFin={formData.abFin}
                                                isClone={(formData.hasOwnProperty("@id")) ? true : false}
                                            />
                                        }
                                    </Fragment>
                                }
                            </FormDataConsumer>
                        </Grid>
                    </Grid>
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <div className={classes.fieldCol12}>

                            </div>
                        </Grid>
                    </Grid>
                </FormTab>
            </TabbedForm>
        </PimsCreate>
    )

}));
