import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { SelectInput, DisabledInput, FormDataConsumer, required } from 'react-admin';
import loader from './../../../../../loader10.gif';
import config from '../../../../../config/config';
let urlAppjs = config.UrlServer;

class Grupo1Edit extends React.Component {

    constructor(props) {
        super(props)
        this.state = { 
            grupo1: [], 
            datoEva: [], 
            cumple: "", 
            grupo1Peso: [], 
            disabled : false,
            didUpdateDisabled : false
        }
        this.myRef = React.createRef();
    }

    componentDidMount() {

        //OBTENER EL TIPO DEL ELEMENTO
        let url_elemento_aux = document.location.hash;
        let croed = url_elemento_aux.split("/");
        let crearoeditar = "";
        let componente = ""
        if (croed[2] === "create") {
            crearoeditar = 1;
        }
        else {
            let url_elemento = url_elemento_aux.split("/%2F");
            componente = url_elemento[1];
            componente = componente.replace("%2F", "/");
            crearoeditar = 2;
        }

        if (crearoeditar === 2) {
            this.setState({ datoEva: this.props.record })

            // fetch(urlAppjs+"/"+componente)
            // .then((response) => {
            //     return response.json()
            // })
            // .then((datoEva) => {
            //     this.setState({ datoEva: datoEva })
            // })
        }
        fetch(urlAppjs + "/preguntas?elemento_indicador=1.json")
            .then((response) => {
                return response.json()
            })
            .then((grupo1) => {
                this.setState({ grupo1: grupo1 })
                //console.log(grupo1)
            })
        fetch(urlAppjs + "/preguntas/24")
            .then((response) => {
                return response.json()
            })
            .then((grupo1Peso) => {
                this.setState({ grupo1Peso: grupo1Peso })
                //console.log(grupo1)
            })

    }

    componentDidUpdate() {
        let gp1_res_6 = document.getElementById("gp1_res_6");
        console.log(gp1_res_6);
        console.log("isDisabled?", this.state.disabled);
        console.log("hasBeenUpdated?", this.state.didUpdateDisabled);
        if(gp1_res_6) {
            if(this.state.didUpdateDisabled == false) {
                if (gp1_res_6.value == "-") {
                    this.setState({
                        disabled : true,
                        didUpdateDisabled : true
                    })
                    console.log("updated. disabled setted to true")
                }
            }
        }
    }
    render() {
        const { grupo1 } = this.state;
        const { grupo1Peso } = this.state;
        const { datoEva } = this.state;
        const { cumple } = this.state;
        // const dataGrup = grupo1['hydra:member'];
        let valoredit = "";
        let pesoTab1 = grupo1Peso.peso;
        //console.log(grupo1Peso)
        const ValidarGrupo1 = (value) => {
            let lenghtObject = Object.keys(value);
            let valor = '';
            for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }  
            if (valor == "-") {
                this.setState({
                    disabled : true
                })
            } else {
                this.setState({
                    disabled : false
                })
            }
            console.log(valor, this.state.disabled);

            // let largo=0;
            // if(grupo1['hydra:member']){
            // largo=grupo1['hydra:member'].length-2;
            // }
            // let resultado =[];
            // let cumpleaux=0;
            // let cumplea="No Cumple";
            // for(let j=0; j<largo; j++){
            //     let valor = document.getElementById('gp1_res_'+j);
            //     if(valor && valor.value!=""){
            //         resultado.push(valor.value);
            //     }
            // }
            // if(resultado.length===largo-1 || resultado.length===largo){
            //     resultado.map((data, i)=>{
            //         console.log(data)
            //         if(data==="Cumple" || data==="-" || data==="Existe" || data===""){
            //             cumpleaux++;
            //         }
            //     })
            // }
            // if(cumpleaux===largo){
            //     cumplea="Cumple"
            // }
            // else{
            //     cumplea="No Cumple"
            // }
            // valoredit=cumplea;
            // //console.log(valoredit)
            // console.log("cumple Aux ->"+cumpleaux+ " largo -> "+largo+ " resultado ->"+resultado.length)
            // this.setState({cumple: cumplea})

        }
        // if(datoEva.svGrupo && datoEva.calificacionG1 && cumple===""){
        //     valoredit=datoEva.calificacionG1

        // }

        // if(cumple==="" && valoredit===""){
        // //valoredit="No Cumple";

        // ValidarGrupo1();
        // }
        // else{
        //     valoredit=cumple

        // }
        if (grupo1['hydra:member']) {

            let response = [];
            if (datoEva.svGrupo) {
                grupo1['hydra:member'].map((dataGrupo) => {
                    datoEva.svGrupo.map(responseSV => {
                        if (dataGrupo.pregunta === responseSV["preguntas_"].pregunta && responseSV["preguntas_"].elemento_indicador === "/elemento_indicadors/1") {
                            response.push(responseSV.calificacion)
                        }
                    })
                })
            }

            let grupo1b = [];
            let opcionesgrupo1 = [];
            let pesosGrupo1b = [];
            let pesodeco = 0;
            let pesoretro = 0;
            grupo1['hydra:member'].map((dato) => {
                if (dato.pregunta === "Sin Daños" || dato.pregunta === "No cumple según normatividad vigente" || dato.pregunta === "No existe placa" || dato.pregunta === "Decoloración de las placas" || dato.pregunta === "Visibilidad Nocturna Insuficiente de las placas") {

                } else {
                    grupo1b.push(dato);
                    if (dato.pregunta === "Tipo de Deterioro en Placa") {
                        let valor = [
                            { id: "Sin Daño", name: "Sin Daño" },
                            { id: "Tipo 1", name: "Tipo 1" },
                            { id: "Tipo 2", name: "Tipo 2" },
                            { id: "Tipo 3", name: "Tipo 3" },
                            { id: 'No Corresponde', name: 'No Corresponde' },
                            { id: "-", name: "-" },
                        ]
                        opcionesgrupo1.push(valor)
                    } else if (dato.pregunta === "Estado de la Placa") {
                        let valor = [
                            { id: "Estado", name: "Estado" },
                            { id: "Cumple", name: "Cumple" },
                            { id: "No Cumple", name: "No Cumple" },
                            { id: "No Corresponde", name: "No Corresponde" },
                            { id: "-", name: "-" },
                        ]
                        opcionesgrupo1.push(valor)
                    } else {
                        let valor = [
                            { id: "-", name: "-" },
                            { id: 'Cumple', name: 'Cumple' },
                            { id: 'No Cumple', name: 'No Cumple' },
                        ]
                        opcionesgrupo1.push(valor)
                    }

                }
                if (dato.id != 1 && dato.id != 2 && dato.id != 8) {
                    if (dato.peso) {
                        pesosGrupo1b.push(dato.peso)
                    }
                    else {
                        pesosGrupo1b.push(0)
                    }
                }
                if (dato.id === 1) {
                    pesodeco = dato.peso;
                }
                else if (dato.id === 2) {
                    pesoretro = dato.peso;
                }
            })
            const validarSiCumple = (value) => {
                let cumplea = ""
                this.setState({ cumple: cumplea })
                //console.log("llega");
            }
            console.log(grupo1['hydra:member'][6]['pregunta'])
            // console.log(response)
            return (
                <React.Fragment>
                    <React.Fragment key={6}>
                        <Typography
                            variant="strong"
                            color="inherit"
                            style={{ width: '50%', display: 'inline-block' }}
                        >
                            {grupo1['hydra:member'][6]['pregunta']}
                        </Typography>
                        <SelectInput 
                            source={`gp1_res_6`} 
                            id={`gp1_res_6`} 
                            allowEmpty 
                            emptyValue="" 
                            label=" "
                            choices={[
                                { id: "-", name: "-" },
                                { id: 'Existe', name: 'Existe' },
                                { id: 'No Existe', name: 'No Existe' },

                            ]}
                            style={{ width: '30%', display: 'inline-block', marginLeft: '20px' }} 
                            fullWidth 
                            defaultValue={response[6]} 
                            onChange={ValidarGrupo1} 
                            validate={[required()]} 
                        />
                        <DisabledInput source={`gp1_id_6`} defaultValue={grupo1['hydra:member'][6]["@id"]} style={{ display: 'none' }} />
                    </React.Fragment>
                    <FormDataConsumer style={{ display: "none" }}>
                        {({ formData, ...rest }) => {
                            if (formData && formData.gp1_res_6 === "No Existe") {
                                formData.gp1_res_0 = "-";
                                formData.gp1_res_1 = "-";
                                formData.gp1_res_2 = "-";
                                formData.gp1_res_3 = "-";
                                formData.gp1_res_4 = "-";
                                formData.gp1_res_5 = "-";
                            }
                        }}
                    </FormDataConsumer>
                    {
                        grupo1b.map((data, i) => (
                            <React.Fragment key={i}>
                                <Typography
                                    variant="strong"
                                    color="inherit"
                                    style={{ width: '50%', display: 'inline-block' }}
                                >
                                    {data.pregunta}
                                </Typography>
                                {i >= 4 &&
                                    <SelectInput 
                                        source={`gp1_res_${i + 5}`} 
                                        id={`gp1_res_${i + 5}`} 
                                        disabled={this.state.disabled} 
                                        allowEmpty 
                                        emptyValue="" 
                                        label=" "
                                        choices={opcionesgrupo1[i]}
                                        style={{ width: '30%', display: 'inline-block', marginLeft: '20px' }} 
                                        fullWidth 
                                        defaultValue={(this.state.disabled) ? "-" : response[i + 5]} 
                                        onChange={validarSiCumple} 
                                        validate={[required()]} 
                                    />
                                }
                                {i < 4 &&
                                    <SelectInput 
                                        source={`gp1_res_${i + 2}`}
                                        id={`gp1_res_${i + 2}`} 
                                        disabled={this.state.disabled} 
                                        allowEmpty 
                                        emptyValue="" 
                                        label=" "
                                        choices={opcionesgrupo1[i]}
                                        style={{ width: '30%', display: 'inline-block', marginLeft: '20px' }} 
                                        fullWidth 
                                        defaultValue={(this.state.disabled) ? "-" : response[i + 2]} 
                                        onChange={validarSiCumple} 
                                        validate={[required()]} 
                                    />
                                }
                                {i >= 4 &&
                                    <DisabledInput source={`gp1_id_${i + 5}`} defaultValue={data["@id"]} style={{ display: 'none' }} />
                                }
                                {i < 4 &&
                                    <DisabledInput source={`gp1_id_${i + 2}`} defaultValue={data["@id"]} style={{ display: 'none' }} />
                                }
                            </React.Fragment>
                        ))
                    }
                    <React.Fragment key={7}>
                        <Typography
                            variant="strong"
                            color="inherit"
                            style={{ width: '50%', display: 'none' }}
                        >
                            {grupo1['hydra:member'][7]['pregunta']}
                        </Typography>
                        <DisabledInput source="calificacionG1d" label=" " style={{ width: '30%', display: 'none', marginLeft: '20px' }} fullWidth onChange={validarSiCumple} />
                        <DisabledInput source={`gp1_id_7`} defaultValue={grupo1['hydra:member'][7]["@id"]} style={{ display: 'none' }} />
                    </React.Fragment>
                    <React.Fragment key={8}>
                        <Typography
                            variant="strong"
                            color="inherit"
                            style={{ width: '50%', display: 'inline-block' }}
                        >
                            {grupo1['hydra:member'][8]['pregunta']}
                        </Typography>
                        <SelectInput 
                            source={`gp1_res_8`} 
                            id={`gp1_res_8`} 
                            allowEmpty 
                            emptyValue="" 
                            label=" "
                            choices={[
                                { id: "-", name: "-" },
                                { id: 'Cumple', name: 'Cumple' },
                                { id: 'No Cumple', name: 'No Cumple' },

                            ]}
                            style={{ width: '30%', display: 'inline-block', marginLeft: '20px' }} 
                            disabled={this.state.disabled} 
                            fullWidth 
                            defaultValue={(this.state.disabled) ? "-" : response[8]} 
                            onChange={ValidarGrupo1} 
                            validate={[required()]} 
                        />
                        <DisabledInput source={`gp1_id_8`} defaultValue={grupo1['hydra:member'][8]["@id"]} style={{ display: 'none' }} />
                    </React.Fragment>
                    {/* <hr />
                    <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '-30px'}}>
                        <strong style={{display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}>Condición: </strong><DisabledInput source="calificacionG1d" label=" "  defaultValue={valoredit} />
                    </div>*/}
                    <DisabledInput source="gp1_length" label=" " defaultValue={grupo1['hydra:member'].length} style={{ display: 'none' }} />
                    <DisabledInput source="gp1_calificacion" label=" " style={{ display: 'none' }} />
                    <DisabledInput source="decoloracion_peso" label=" " style={{ display: 'none' }} />
                    <DisabledInput source="retroreflectividad_peso" label=" " style={{ display: 'none' }} />
                    <FormDataConsumer style={{ display: "none" }}>
                        {({ formData, ...rest }) => {
                            if (formData) {

                                let largo = 0;
                                //  console.log("Largo grupo1 "+grupo1['hydra:member'].length)
                                //  console.log("Largo grupo1b "+grupo1b.length)
                                // console.log(pesosGrupo1b)
                                //  console.log(grupo1b)
                                if (grupo1['hydra:member']) {
                                    largo = grupo1['hydra:member'].length;
                                }
                                let resultado = [];
                                let cumpleaux = 0;
                                let cumpleauxpeso = 0;
                                let cumplea = "No Cumple";
                                for (let j = 2; j < largo; j++) {
                                    if (j != 7) {
                                        let valor = formData["gp1_res_" + j];
                                        //console.log(valor)
                                        if (valor) {
                                            resultado.push(valor);
                                        }
                                    }
                                }
                                //console.log(resultado)
                                //console.log("largo "+largo)
                                if (resultado.length === largo - 3) {
                                    // console.log("llega")
                                    resultado.map((data, i) => {

                                        if (data === "Cumple" || data === "-" || data === "Existe" || data === "") {
                                            cumpleaux++;
                                        }
                                        else {
                                            cumpleauxpeso += pesosGrupo1b[i];
                                        }

                                    })
                                }
                                if (cumpleaux === largo) {
                                    cumplea = "Cumple"
                                }
                                else {
                                    cumplea = "No Cumple"
                                }
                                valoredit = cumplea;
                                //console.log(valoredit)
                                //console.log("cumple Aux ->"+cumpleaux+ " largo -> "+largo+ " resultado ->"+resultado.length)
                                //formData.calificacionG1d=valoredit;
                                if (cumpleauxpeso === 0) {
                                    formData.gp1_calificacion = "0";
                                } else {
                                    formData.gp1_calificacion = cumpleauxpeso;
                                }
                                formData.decoloracion_peso = pesodeco;
                                formData.retroreflectividad_peso = pesoretro;
                                if (cumpleauxpeso < pesoTab1) {
                                    formData.calificacionG1d = "Cumple"
                                }
                                else {
                                    formData.calificacionG1d = "No Cumple"
                                }
                                // console.log("Peso Total: " + cumpleauxpeso);
                                //this.setState({cumple: cumplea})
                            }
                        }}
                    </FormDataConsumer>
                </React.Fragment>
            )

        } else {
            return (
                <div className="Reload2">
                    <img src={loader} />
                </div>
            )
        }

    }
}

export default Grupo1Edit;