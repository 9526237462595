import React, { useState, Fragment, useEffect } from 'react';
import {
  DateInput, BooleanInput, required, TabbedForm, FormTab, AutocompleteInput, NumberInput, SelectInput, ImageField, ImageInput,
  ReferenceInput, SimpleFormIterator, ArrayInput, TextInput, FormDataConsumer
} from 'react-admin';
import { Edit } from '../Edit';
import {
  abValidationInicio, abValidationFin, validarDosDecimales, validarUnDecimal, validarAB, isFechaInvalida
} from '../../../src/resources/validacionesInputs';
import { withStyles } from '@material-ui/core';
import { styles } from '../../EstilosGrid';
import Grid from '@material-ui/core/Grid';
import { minValue, maxValue, minLength, maxLength } from 'ra-core';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { PRInput } from '../../inputs/PRInput';
import { DInput } from '../../inputs/DInput';
import { DEInput } from '../../inputs/DEInput';
import CoordenadasProyecto from '../CoordenadasInventario';
import AbsSegmento from '../AbsSegmento';
import { latMininima, lngMininima, Entero } from '../validacionesInputs';
//import Reparaciones from './campos/reparaciones';
import { ButtonCarousel } from '../images/ButtonCarouselPeru';
import { PimsElementoActions } from '../elementos/PimsElementoActions';
import { ArrayInputImagesEdit } from '../images/ArrayInputImagesEdit';
import { translate } from 'react-admin';
import { REDUX_FORM_NAME } from 'react-admin';
import { change } from 'redux-form';
import config from '../../config/config';

export const RegistroNovedadEdit = translate(withStyles(styles)(({ classes, ...props }) => {
  let urlAppjs = config.UrlServer;
  const { translate } = props;
  /* NEW EDER */
  const [sistema, setSistema] = useState(null);
  const ValidarSistema = (value) => {
    let lenghtObject = Object.keys(value);
    let valor = '';
    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setSistema(value);
  }

  const modal = () => {
    console.log('click...');
  }

  const [peso, setPeso] = useState(0);
  const updatePeso = (e) => {
    //console.log(e);
  }
  const validarUmbral = (value) => {
    var errorMsg = [];
    var returnMsg = "";
    let tam = document.getElementsByClassName("peso").length;
    let umbral = document.getElementsByName("umbralFallo")[0].value;
    //console.log(tam);
    let suma = 0.0;
    for (let i = 0; i < tam; i++) {
      let elements = document.getElementsByName("preguntaRegistroNovedad[" + i + "].peso");
      let dato = document.getElementsByName("preguntaRegistroNovedad[" + i + "].peso")[0].defaultValue;
      suma += parseFloat(dato);
    }
    setPeso(suma);
    //console.log(peso);
    //debugger
      if (umbral > suma) {
        errorMsg.push(translate('resources.validaciones.umbralSumatoria') );
      }
      if (errorMsg.length > 0) {
        for (var i = 0; i < errorMsg.length; i++) {
            returnMsg = returnMsg + (i + 1) + ")." + errorMsg[i];
        }
        // errorMsg.map( msg => {returnMsg = returnMsg + msg})
        return returnMsg;
      }
  }
  /* END EDER */

  const validarUmbralnuevo = (value) => {
    var array = []; 
    var errorMsg = [];
    var returnMsg = "";
    if (typeof value !== 'undefined') {
        for (var i = 0; i < value.length; i++) {
          let pesopregunta = value[i].peso;
          if(value[i].tipoCampo != 'Selección Múltiple')
          {
            
            //document.getElementById("preguntaRegistroNovedad["+i+"].respuestaRegistroNovedad").style.display = "none";
          }else
          {
            
            //document.getElementById("preguntaRegistroNovedad["+i+"].respuestaRegistroNovedad").style.display = "unset";
            if (typeof value[i].respuestaRegistroNovedad !== 'undefined') {
              let pesoTotal=0;
              let pesorespuesta=0;
              let numero = i +1;
              for (var j = 0; j < value[i].respuestaRegistroNovedad.length; j++) {
                pesorespuesta = value[i].respuestaRegistroNovedad[j].peso;
                pesoTotal=pesoTotal+pesorespuesta;
                if (pesorespuesta > pesopregunta) {
                  errorMsg.push(translate('resources.validaciones.pesoRespuesta') + numero + "." );
                   
                 //console.log(value[i].respuestaRegistroNovedad[j].peso)
                }
                }   
                
            }
          }
        }
    }
    if (errorMsg.length > 0) {
      for (var i = 0; i < errorMsg.length; i++) {
          returnMsg = returnMsg + (i + 1) + ")." + errorMsg[i];
      }
      // errorMsg.map( msg => {returnMsg = returnMsg + msg})
      return returnMsg;
    }
 
  }

  const [latitudMinimaArray, setLatitudArray] = useState('');
  const LatitudMinimaArray = (value) => {
    setLatitudArray(value)
  }
  const [longitudMinimaArray, setLongitudArray] = useState('');
  const LongitudMinimaArray = (value) => {
    setLongitudArray(value)
  }
  const [longitudMaximaArray, setLongitudMaxdArray] = useState('');
  const LongitudMaximaArray = (value) => {
    setLongitudMaxdArray(value)
  }
  const [latitudMaximaArray, setLatitudMaxdArray] = useState('');
  const LatitudMaximaArray = (value) => {
    setLatitudMaxdArray(value)
  }
  const [elemento, setElemento] = useState('');
  const [latMin, setLatMin] = useState('');
  const [lngMin, setLngMin] = useState('');
  const [latMax, setLatMax] = useState('');
  const [lngMax, setLngMax] = useState('');

  const [tipo_elementos, setTipoElementos] = useState('');
  const [tipo_acceso, setTipoAcceso] = useState('');

  const ValidarTipoElementos = (value) => {
    let lenghtObject = Object.keys(value);
    let valor = '';
    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setTipoElementos(value);
  }

  const ValidarTipoAcceso = (value) => {
    let lenghtObject = Object.keys(value);
    let valor = '';
    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setTipoAcceso(value);
  }

  const ValidarSegmento = (value) => {
    let lenghtObject = Object.keys(value);
    let valor = '';
    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setSegmento(value)
  }

  const [estados, setEstado] = useState('');
  const ValidarEstado = (value) => {
    setEstado(value)
  }
  //const Unidades = { unidadFuncional: unidads };

  const [unidads, setUnidad] = useState('');
  const ValidarUnidad = (value) => {
    //let lenghtObject = Object.keys(value);
    //let valor = '';
    //for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setUnidad(value)
  }

  const [tramos, setTramo] = useState('');

  const ValidarTramo = (value) => {
    let lenghtObject = Object.keys(value);
    let valor = '';
    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setTramo(value)
  }
  const [segmentos, setSegmento] = useState('');


  const [variante, setVariante] = useState('');
  const ValidarVariante = (value) => {

    let lenghtObject = Object.keys(value);
    let valor = '';

    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setVariante(valor)

  }

  const [tipo, setTipo] = useState('');
  const ValidarTipo = (value) => {

    // let lenghtObject = Object.keys(value);
    // let valor = '';

    // for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setTipo(value)

  }
  const [tipoa, setTipoa] = useState('');
  const ValidarTipoa = (value) => {

    // let lenghtObject = Object.keys(value);
    // let valor = '';

    // for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setTipoa(value)

  }
  const [totaltablero, setTotaltablero] = useState(0);
  const Longitu = (value) => {
    // console.log(value);
    let valAncho = 0;
    valAncho = document.getElementById('1').value;
    if (valAncho) { if (valAncho !== '') { valAncho = valAncho } }
    // console.log(parseFloat (valAncho) + parseFloat(value));
    //setTotaltablero(valAncho * value);
    let gtr = totaltablero;
    setTotaltablero(0);

  }

  /*VALIDAR IMÁGENES ACTUALES */
  const [imagenesOpen, setImagenesOpen] = useState('');
  const ImagenesEdit = (value) => {
    setImagenesOpen(value);

  }

  /*OPCIONES PARA TIPO DE IMÁGENES*/
  const [opcionTipoImagen, setOpcionTipoImagen] = useState([{ id: 'Cargando...', name: 'Cargando...' }]);
  const OpcionTipoImagen = (value) => {
    if (value !== '/tipo_elementos/DESC') {
      setOpcionTipoImagen([
        { id: '/tipo_images/1', name: 'Panorámica' },
        { id: '/tipo_images/7', name: 'Elemento completo' },
        { id: '/tipo_images/8', name: 'Detalle 1' },
        { id: '/tipo_images/9', name: 'Detalle 2' },
        { id: '/tipo_images/5', name: 'Deterioro 1' },
        { id: '/tipo_images/6', name: 'Deterioro 2' },
      ])
    }
  }
  const ValidarElemento = (value) => {
    setElemento(value)

  }

  const [shouldShowOnReduxChange, setShouldShowOnReduxChange] = useState(true);


  return (
    <Edit title="dynatest.resources.registro_novedads.title.editTitle" {...props}>
      <TabbedForm redirect="list">
        {/* ......:::.... IDENTIFICACION ......:::....*/}
        <FormTab label="dynatest.resources.registro_novedads.tabs.identificacion" > 
          <Grid container spacing={24}>
            <Grid item xs={4}>
              <TextInput label="resources.registro_novedads.fields.nombre"  source="nombre" fullWidth validate={[required(), minLength(1), maxLength(100)]} formClassName={classes.grid_cont3} />
            </Grid>
            <Grid item xs={4}>
              <SelectInput name="estado" source="estado" label="resources.registro_novedads.fields.estado" validate={[required()]} fullWidth choices={[
                { id: translate('dynatest.resources.registro_novedads.select.estado.Activo'), name: 'dynatest.resources.registro_novedads.select.estado.Activo' },
                { id: translate('dynatest.resources.registro_novedads.select.estado.Inactivo'), name: 'dynatest.resources.registro_novedads.select.estado.Inactivo' },
              ]} />
            </Grid>
            <Grid item xs={4}>
              {/* <DInput label="Umbral de fallo" source="umbralFallo" id="umbralFallo" className="align-number" fullWidth validate={[required(), minValue(1), maxValue(5), validateDecimal]} formClassName={classes.grid_cont3} /> */}
              <NumberInput label="resources.registro_novedads.fields.umbralFallo"  source="umbralFallo" fullWidth validate={[required(), validarDosDecimales, validarUmbral, minValue(0), maxValue(peso)]} formClassName={classes.grid_cont3} />
            </Grid>
            <Grid item xs={4}>
              <ReferenceInput label="resources.registro_novedads.fields.proyecto" name="proyecto" source="proyecto" reference="proyectos" fullWidth validate={[required()]} defaultValue={window.localStorage.getItem('id_project2')}>
                <SelectInput optionText="nombre" disabled />
              </ReferenceInput>
            </Grid>
            <FormDataConsumer>
            {
              ({ formData, dispatch, ...rest }) => (
                <Fragment>
                  <Grid item xs={4}>
                    <ReferenceInput 
                      label="resources.registro_novedads.fields.sistema"
                      name="sistema" 
                      source="sistema" 
                      reference="sistemas" 
                      filter={{ id: ["ALR", "DRE", "OTR", "PTS", "RDS", "SEG", "SEN"] }}
                      onChange={value => {
                        dispatch(change(REDUX_FORM_NAME, 'tipoElemento', null))
                        setShouldShowOnReduxChange(false)
                      }} 
                      fullWidth validate={[required(), ValidarTipoElementos, ValidarSistema]}
                    >
                      <SelectInput optionText="nombre" />
                    </ReferenceInput>
                  </Grid>
                  {(sistema != null) && (
                    <Fragment>
                      {shouldShowOnReduxChange ?
                        <Grid item xs={4}>
                          {(sistema == '/sistemas/OTR') ?
                            <ReferenceInput label="resources.registro_novedads.fields.tipoElemento" name="tipoElemento" source="tipoElemento" reference="tipo_elementos" fullWidth filter={{ puntos: 1, sistema: "OTR" }} validate={[required()]} sort={{ field: 'nombre', order: 'asc' }}>
                              <SelectInput optionText="nombre" />
                            </ReferenceInput>
                            :
                            (sistema == '/sistemas/SEN') ?
                              <ReferenceInput label="resources.registro_novedads.fields.tipoElementoSenal" source="tipoElementoSenal" reference="tipo_elemento_senals" fullWidth validate={[required(), ValidarElemento]} sort={{ field: 'nombre', order: 'asc' }}>
                                <SelectInput optionText="nombre" />
                              </ReferenceInput>
                              :
                              <ReferenceInput label="resources.registro_novedads.fields.tipoElemento" name="tipoElemento" source="tipoElemento" reference="tipo_elementos" fullWidth filter={{ sistema: tipo_elementos }} validate={[required()]} sort={{ field: 'nombre', order: 'asc' }}>
                                <SelectInput optionText="nombre" />
                              </ReferenceInput>
                          }
                        </Grid>
                        :
                        setShouldShowOnReduxChange(true)
                      }
                    </Fragment>
                  )}
                </Fragment>
              )}
            </FormDataConsumer>
            {(sistema != null) && (sistema == '/sistemas/SEN') && (elemento) && (
              <Grid item xs={4}>
                <ReferenceInput label="resources.registro_novedads.fields.tipoSenal" source="tipoSenal" reference="tipo_senals" fullWidth filter={{ tipoElementoSenal: elemento }} validate={[required()]} sort={{ field: 'nombre', order: 'asc' }}>
                  <SelectInput optionText="nombre" />
                </ReferenceInput>
              </Grid>
            )}

          </Grid>
        </FormTab>
        {/* ......:::.... PREGUNTAS ......:::....*/}
        <FormTab label="dynatest.resources.registro_novedads.tabs.preguntas" >
          <Typography style={{ marginBottom: "-25px", marginTop: "5px" }}><b>{translate("dynatest.resources.registro_novedads.subTitle.preguntas")}</b></Typography>
          <ArrayInput fullWidth label=" " source="preguntaRegistroNovedad" validate={[validarUmbralnuevo]} defaultValue={[
            {
              pregunta: "", peso: "", tipoCampo: "", estado: "",
            }
          ]}>
            <SimpleFormIterator>
              <TextInput label="resources.registro_novedads.fields.pregunta" source="pregunta" id="pregunta" fullWidth validate={[required(), minLength(1), maxLength(60)]} formClassName={classes.grid_cont3} />
              {/* <DInput label="Peso" source="peso" id="peso" className="align-number" fullWidth validate={[required(), minValue(1), maxValue(5), validateDecimal]} formClassName={classes.grid_cont3} /> */}
              <NumberInput label="resources.registro_novedads.fields.peso" source="peso" className="peso" fullWidth validate={[required(), validarDosDecimales, minValue(0)]} formClassName={classes.grid_cont3} />
              <SelectInput label="resources.registro_novedads.fields.tipoCampo" source="tipoCampo" id="tipoCampo" allowEmpty emptyValue="" validate={[required()]} choices={[
                { id: translate('dynatest.resources.registro_novedads.select.tipoCampo.seleccionMultiple'), name: "dynatest.resources.registro_novedads.select.tipoCampo.seleccionMultiple" },
                { id: translate('dynatest.resources.registro_novedads.select.tipoCampo.numerico'), name: "dynatest.resources.registro_novedads.select.tipoCampo.numerico" },
              ]} fullWidth formClassName={classes.grid_cont3} />
              <SelectInput label="resources.registro_novedads.fields.estado" source="estado" id="estado" allowEmpty emptyValue="" validate={[required()]} choices={[
                { id: translate('dynatest.resources.registro_novedads.select.estado.Activo'), name: 'dynatest.resources.registro_novedads.select.estado.Activo' },
                { id: translate('dynatest.resources.registro_novedads.select.estado.Inactivo'), name: 'dynatest.resources.registro_novedads.select.estado.Inactivo' },
              ]} fullWidth formClassName={classes.grid_cont3} />

              {/* <Typography><b>Respuestas:</b></Typography> */}
              <FormDataConsumer style={{ width: '50%' }}>
                {
                  ({ formData, scopedFormData, getSource, ...rest }) => (scopedFormData != null &&
                    scopedFormData.tipoCampo != null && scopedFormData.tipoCampo === translate("dynatest.resources.registro_novedads.select.tipoCampo.numerico") &&
                    <NumberInput defaultValue="0" label="resources.registro_novedads.fields.decimales" source={getSource("decimales")} validate={[required(), Entero, minValue(0)]} style={{ width: '370px' }} />
                  )
                }
              </FormDataConsumer>
              <FormDataConsumer style={{ width: '33%' }}>
                {
                  ({ formData, scopedFormData, getSource, ...rest }) => (scopedFormData != null &&
                    scopedFormData.tipoCampo != null && scopedFormData.tipoCampo === translate("dynatest.resources.registro_novedads.select.tipoCampo.numerico") &&
                    <Typography style={{marginTop: '1rem'}}><b>{translate("dynatest.resources.registro_novedads.subTitle.consideracionesPeso")}</b></Typography>
                  )
                }
              </FormDataConsumer>
              <FormDataConsumer style={{ width: '33%' }}>
                {
                  ({ formData, scopedFormData, getSource, ...rest }) => (scopedFormData != null &&
                    scopedFormData.tipoCampo != null && scopedFormData.tipoCampo === translate("dynatest.resources.registro_novedads.select.tipoCampo.numerico") &&
                    <Fragment>
                      <NumberInput defaultValue="0" label="resources.registro_novedads.fields.rangoInicial" source={getSource("rangoInicial")} validate={[required(), Entero, minValue(0)]} formClassName={classes.grid_cont3} style={{marginRight: '0.5rem'}} />
                      <NumberInput defaultValue="0" label="resources.registro_novedads.fields.rangoFinal" source={getSource("rangoFinal")} validate={[required(), Entero, minValue(0)]} formClassName={classes.grid_cont3s} />
                    </Fragment>
                  )
                }
              </FormDataConsumer>

              <FormDataConsumer style={{ width: '33%' }}>
                {
                  ({ formData, scopedFormData, getSource, ...rest }) => (scopedFormData != null &&
                    scopedFormData.tipoCampo != null && scopedFormData.tipoCampo === translate("dynatest.resources.registro_novedads.select.tipoCampo.seleccionMultiple") &&
                    <Typography><b>{translate("dynatest.resources.registro_novedads.subTitle.respuestas")}</b></Typography>
                  )
                }
              </FormDataConsumer>

              <FormDataConsumer style={{ width: '33%' }}>
                {
                  ({ formData, scopedFormData, getSource, ...rest }) => (scopedFormData != null &&
                    scopedFormData.tipoCampo != null && scopedFormData.tipoCampo === translate("dynatest.resources.registro_novedads.select.tipoCampo.seleccionMultiple") &&

                    <ArrayInput fullWidth label=" " source={getSource("respuestaRegistroNovedad")} defaultValue={[
                      {
                        respuesta: "0", peso: "0",
                      }
                    ]}>
                      <SimpleFormIterator fullWidth>
                        <TextInput 
                          label="resources.registro_novedads.fields.respuesta" 
                          source="respuesta" 
                          id="respuesta" 
                          fullWidth 
                          validate={[required(), minLength(1), maxLength(60)]} 
                          formClassName={classes.grid_cont6} />
                        <NumberInput 
                          label="resources.registro_novedads.fields.peso" 
                          source="peso" 
                          className="align-number" 
                          fullWidth 
                          validate={[required(), validarDosDecimales, minValue(0)]} 
                          formClassName={classes.grid_cont6} />
                      </SimpleFormIterator>
                    </ArrayInput>
                  )
                }
              </FormDataConsumer>
              {/* <ArrayInput fullWidth label=" " source="respuestaRegistroNovedad" defaultValue={[
                {
                  respuesta: "", peso: "",
                }
              ]}>
                <SimpleFormIterator fullWidth>
                  <TextInput label="Respuesta" source="respuesta" id="respuesta" fullWidth validate={[required(), minLength(1), maxLength(60)]} formClassName={classes.grid_cont6} />
                  <DInput label="Peso" source="peso" id="peso" className="align-number" fullWidth validate={[required(), minValue(1), maxValue(5), validateDecimal]} formClassName={classes.grid_cont6} />
                  <NumberInput label="Peso" source="peso" fullWidth validate={[required(), validarDosDecimales, minValue(0)]} formClassName={classes.grid_cont6} />
                </SimpleFormIterator>
              </ArrayInput> */}
            <hr fullWidth style={{ marginBottom: '-2px', border:'2' }}></hr>
            </SimpleFormIterator> 
          </ArrayInput>
        </FormTab>
      </TabbedForm>

    </Edit>
  )
}));
const WithProps = ({ children, ...props }) => children(props);