import React, {useState} from 'react';
import { SimpleForm, TabbedForm, FormTab, SelectInput, Query, TextInput, ReferenceInput, DateInput, NumberInput, SimpleFormIterator, AutocompleteInput} from 'react-admin';

import { withStyles } from '@material-ui/core';
import { Edit } from './../../..//Edit';

import Grid from '@material-ui/core/Grid';
import Grupo1Edit from './../tabs/edit/grupo1';
import Grupo2Edit from './../tabs/edit/grupo2';
import Grupo3Edit from './../tabs/edit/grupo3';
import RetroreflectividadEdit from './../tabs/edit/retroreflectividad';
import DecoloracionEdit from './../tabs/edit/decoloracion';
import loader from './../../../../loader10.gif';

import { required, minLength, maxLength } from 'react-admin';

const styles = {
    grid_cont12: { width: '100%', marginBottom: '20px' },
    grid_cont6_f: { display: 'inline-block', width: '48%' },
    grid_cont6_s: { display: 'inline-block', width: '48%', marginLeft: '20px'},

    grid_cont5_f: { display: 'inline-block', width: '41%' },
    grid_cont5_s: { display: 'inline-block', width: '41%', marginLeft: '20px'},
    
    grid_cont4_f: { display: 'inline-block', width: '31%' },
    grid_cont4_s: { display: 'inline-block', width: '31%', marginLeft: '20px' },

    grid_cont3_f: { display: 'inline-block', width: '22%' },
    grid_cont3_s: { display: 'inline-block', width: '22%', marginLeft: '20px'},

    grid_cont2_f: { display: 'inline-block', width: '15%'},
    grid_cont2_s: { display: 'inline-block', width: '15%', marginLeft: '20px'},

    medicion_global: { display: 'inline-block', width: '31%', marginLeft: '20px' },
    tipo: {width: '90%', fontWeight: 'bold'},
    ocultar: {display: 'none'}
};
 
export const EvaluacionSVEdit = withStyles(styles)(({ classes, record, ...props }) => {
    const [unidads, setUnidad] = useState('');
    const [tramos, setTramo] = useState('');
    const [segmentos, setSegmento] = useState('');
    const [elementos, setElemento] = useState('');
    const [tipoSenales, setTipoSenal] = useState('');
    const [modelos, setModelo] = useState('');

    const ValidarUnidad = (value) => {

        
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setUnidad(value);
        
        
    }
    const ValidarTramo = (value) => {
        
        let lenghtObject = Object.keys(value);
        let valor = '';        
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }                
        setTramo(value);
    }
    const ValidarSegmento = (value) => {
        
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setSegmento(value)
          
    }
    const ValidarElemento = (value) => {
        
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length); index++) { valor += value[index]; }
        setElemento(valor);
    }
    const ValidarTipoElemento = (value) => {
        
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length); index++) { valor += value[index]; }
        setTipoSenal(valor);
    }
    
    const ValidarModelo =(value) =>{    
        let lenghtObject = Object.keys(value);
        let valor = '';        
        for (let index = 0; index < (lenghtObject.length); index++) {  valor += value[index]; }                
        setModelo(value)                
    }
    // const [idEva, setIdEva] = useState('');

    // const getIdForTabs = (value) => {
    //     if(value){
    //         setIdEva(value)
    //     }

    //     console.log(idEva)
        
    // }
    const ValidarFecha = (value) => {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth() + 1; //January is 0!
        var yyyy = today.getFullYear();
        if (dd < 10) {
            dd = '0' + dd
        }
        if (mm < 10) {
            mm = '0' + mm
        }

        today = yyyy + '-' + mm + '-' + dd;
        if (document.getElementById("datefield")) {
            document.getElementById("datefield").setAttribute("max", today);
        }
    }
 
    return (
    <Edit  title="Editar Evaluación" {...props}  undoable={false}>
        <SimpleForm submitOnEnter={false} redirect="list" >        
            {/* HEADER */}
            <ReferenceInput label="Unidad Funcional" source="unidadFuncional" reference="unidad_funcionals" validate={[required(), ValidarUnidad]} filterToQuery={searchText => ({ codigo: searchText })} formClassName={classes.grid_cont3_f} fullWidth>
                    <AutocompleteInput  optionText="fullCodigos" fullWidth/>
                </ReferenceInput>

                {unidads &&
                <ReferenceInput label="Tramo" source="tramo" reference="tramos" validate={[required(), ValidarTramo]} formClassName={classes.grid_cont3_s} filterToQuery={searchText => ({ codigo: searchText })} sort={{ field: 'codigo', order: 'ASC' }} filter={{unidadFuncional: unidads}} fullWidth>
                    <AutocompleteInput  optionText="fullCodigos" fullWidth />
                </ReferenceInput>                   
                }                
                {tramos &&
                <ReferenceInput label="Segmento" source="segmento" reference="segmentos" validate={[required(), ValidarSegmento]} formClassName={classes.grid_cont3_s} filterToQuery={searchText => ({ codigo: searchText })} sort={{ field: 'codigo', order: 'ASC' }} filter={{ tramo: tramos }} fullWidth>
                    <AutocompleteInput  optionText="fullCodigos" fullWidth />
                </ReferenceInput>
                }
                {segmentos &&
                    <ReferenceInput label="Elemento" source="tipoElementoSenal" fullWidth reference="tipo_elemento_senal_perus" validate={[required(), ValidarElemento]} sort={{ field: 'nombre', order: 'ASC' }} filter={{segmento:segmentos}} formClassName={classes.grid_cont2_s}>
                    <SelectInput  optionText="nombre" fullWidth />
                </ReferenceInput>
                }
                {elementos &&
                    <ReferenceInput label="Tipo de elemento" source="tipoSenal" fullWidth reference="tipo_senal_perus" validate={[required(), ValidarTipoElemento]}  sort={{ field: 'nombre', order: 'ASC' }} filter={{tipoElementoSenalPeru: elementos}} formClassName={classes.grid_cont3_f}>
                    <SelectInput  optionText="nombre" fullWidth />
                </ReferenceInput>         
                } 
                {tipoSenales &&
                    <ReferenceInput label="Modelo" source="nombre" fullWidth reference="senal_vertical_perus" validate={[required(), ValidarModelo]} filter={{tipoSenalPeru: tipoSenales}} sort={{field: "nombre", order: "ASC"}} filterToQuery={searchText => ({ nombre: searchText })} formClassName={classes.grid_cont3_s}>
                        <AutocompleteInput  optionText="nombre" fullWidth />
                    </ReferenceInput>                                 
                }
                {modelos &&
                <ReferenceInput label="Señalización" source="elemento_senalizacion" reference="elemento_senalizacion_perus" validate={[required()]} formClassName={classes.grid_cont5_s} sort={{ field: 'codigo', order: 'ASC' }} filter={{segmento:segmentos, tramo:tramos, unidadFuncional:unidads, tipoSenalPeru: tipoSenales, tipoElementoSenalPeru: elementos, senalVerticalPeru: modelos}} fullWidth>
                    <SelectInput  optionText="fullCodigo" fullWidth />
                </ReferenceInput>
                }
                <div formClassName={classes.grid_cont12} fullWidth></div>
            <DateInput label="Fecha" source="fecha" formClassName={classes.grid_cont4_f} fullWidth validate={[required(), ValidarFecha]}/>
            <TextInput label="Condición Global" source="medicion_global" formClassName={classes.medicion_global} fullWidth/>
            <Query type='GET_ONE' resource='preguntas' payload={{ id: "preguntas/23" }}>
                {({ data, loading, error }) => {
                    if (data && data.peso) {
                    return (
                        <NumberInput source="medicion_global_peso" defaultValue={data.peso} style={{display: "none"}}/>
                    )
                    }else{
                        return(
                        <span>No Se Encontró Peso Para la Medición Global</span>
                        )
                    }
                }}
                </Query>
            {/* <TextInput source="id" label="id" validate={[getIdForTabs]} /> */}
            {/* TABS */}
            <TabbedForm redirect="list" save={false} submitOnEnter={false} saving={false} className="Evaluaciones-tabs">
                <FormTab label="Deterioro de Placa o Panel">
                <WithProps>{({record,...props})=>{
                 if(record && record.svGrupo){
                  return(  <Grupo1Edit record={record}/>)
                 }
                 else{
                     return(<div className="Reload2">
                                <img src={loader} />
                            </div> 
                           )
                    }
                }
                    }
                </WithProps>
                </FormTab>
                <FormTab label="Deterioro de los Elementos de Fijación">
                <WithProps>{({record,...props})=>{
                 if(record && record.svGrupo){
                  return(  <Grupo2Edit record={record}/>)
                 }
                 else{
                     return(<div className="Reload2">
                                <img src={loader} />
                            </div> 
                           )
                    }
                }
                    }
                </WithProps>
                </FormTab>
                <FormTab label="Deterioro de los Soportes/Estructuras de Soportes">
                <WithProps>{({record,...props})=>{
                 if(record && record.svGrupo){
                  return(  <Grupo3Edit record={record}/>)
                 }
                 else{
                     return(<div className="Reload2">
                                <img src={loader} />
                            </div> 
                           )
                    }
                }
                    }
                </WithProps>
                </FormTab>
                <FormTab label="RETRO-REFLECTIVIDAD">
                <WithProps>{({record,...props})=>
                    <RetroreflectividadEdit record={record}/>}
                </WithProps>
                </FormTab>
                <FormTab label="DECOLORACIÓN">
                <WithProps>{({record,...props})=>
                    <DecoloracionEdit record={record}/>}
                </WithProps>
                </FormTab>
            </TabbedForm>
        </SimpleForm>
    </Edit>
    )
});
const WithProps = ({children,...props}) => children(props);
