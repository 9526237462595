import React, { useState } from 'react';
import { List, SimpleForm, Datagrid, TextField, DateField, Toolbar, EditButton, ShowButton, ReferenceField, Loading, Filter, ReferenceInput, CardActions, BulkDeleteWithConfirmButton, AutocompleteInput, CreateButton, SelectInput, FunctionField } from 'react-admin';
import { PRField } from './../../../fields/PRField';
import { Fragment } from 'react';
import Button from '@material-ui/core/Button';
// import AddIcon from '@material-ui/icons/Add';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import VisualizacionModal from './visualizacionModal';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import { translate } from 'react-admin';

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props} />
    </Fragment>
);
const CargarInformacionFilter = translate((props) => {
    const { translate } = props;
    const [uf, setUf] = useState('none');
    const ufSet = (value) => {
        setUf(value);
    }
    const [tramo, setTramo] = useState('none');
    const tramoSet = (value) => {
        setTramo(value);
    }

    return (
        <Filter {...props}>
            <ReferenceInput 
                id="unidadFuncional"
                label="resources.indicadores_cargar_informacions.fields.unidadFuncional" 
                source="unidadFuncional"  
                reference="unidad_funcionals" 
                filterToQuery={searchText => ({ codigo: searchText })} 
                sort={{field : 'codigo', order : 'asc'}}
                validate={[ufSet]}
                alwaysOn
                fullWidth 
            >
                <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
            </ReferenceInput>

            <ReferenceInput
                label="resources.indicadores_cargar_informacions.fields.tramo"
                source="tramo"
                alwaysOn
                reference="tramos"
                filter={{ unidadFuncional: uf }}
                validate={[tramoSet]}
                filterToQuery={searchText => ({ codigo: searchText })}
                sort={{
                    field: 'codigo',
                    order: 'ASC',

                }}>
                <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
            </ReferenceInput>
            <ReferenceInput label="resources.indicadores_cargar_informacions.fields.indicador" alwaysOn source="indicador" style={{width:"190px"}} reference="parametros" sort={{ field: 'id', order: 'ASC' }}>
                <SelectInput  style={{width:"190px"}} optionText="codigoCompleto" />
            </ReferenceInput>
        </Filter>
    )
});
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const SimpleDialog = translate((props) => {
    const { translate } = props;
    const { onClose, open } = props;
    const handleClose = value => {
        onClose(value);
    };
    const CustomToolbar = ({ basePath, data, ...props }) => (
        <Toolbar {...props} >
            <Button variant="secondary" id="generarInforme" onClick={handleClose} style={{ backgroundColor: '#3f51b5', color: '#fff' }}>
            {translate('resources.indicadores_cargar_informacions.cerrar')} 
            </Button>
        </Toolbar>
    );
    const [uf, setUf] = useState('none');
    const ufSet = (value) => {
        setUf(value);
    }
    const [tramo, setTramo] = useState('none');
    const tramoSet = (value) => {
        setTramo(value);
    }
    const [parametro, setParametro] = useState('');
    const ValidarParametro = (value) => {
        setParametro(value)
    }
    return (
        <Dialog fullScreen onClose={handleClose} aria-labelledby="simple-dialog-title" TransitionComponent={Transition} open={open}>
            <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close" style={{ textAlign: "right" }}>
                <CloseIcon />
            </IconButton>
            {<DialogTitle id="simple-dialog-title">{translate('resources.indicadores_cargar_informacions.title')} </DialogTitle>}
            <SimpleForm toolbar={<CustomToolbar />}>
                <Grid container spacing={24}>
                    <Grid item xs={3}>
                        <ReferenceInput
                            id="unidadFuncional"
                            label="resources.indicadores_cargar_informacions.fields.unidadFuncional"
                            source="unidadFuncional"
                            reference="unidad_funcionals"
                            filterToQuery={searchText => ({ codigo: searchText })}
                            sort={{ field: 'codigo', order: 'asc' }}
                            validate={[ufSet]}
                            alwaysOn
                            fullWidth
                        >
                            <SelectInput options={{ fullWidth: true }} optionText="codigo" />
                        </ReferenceInput>
                    </Grid>
                    <Grid item xs={3}>
                        <ReferenceInput
                            label="resources.indicadores_cargar_informacions.fields.tramo"
                            source="tramo"
                            alwaysOn
                            reference="tramos"
                            filter={{ unidadFuncional: uf }}
                            validate={[tramoSet]}
                            filterToQuery={searchText => ({ codigo: searchText })}
                            sort={{
                                field: 'codigo',
                                order: 'ASC',
                            }}
                        >
                            <SelectInput options={{ fullWidth: true }} optionText="codigo" />
                        </ReferenceInput>
                    </Grid>
                    <Grid item xs={3}>
                        <ReferenceInput label="resources.indicadores_cargar_informacions.fields.indicador" source="indicador" reference="parametros" sort={{ field: 'id', order: 'ASC' }} fullWidth validate={ValidarParametro}>
                            <SelectInput options={{ fullWidth: true }} optionText="codigoCompleto" />
                        </ReferenceInput>
                    </Grid>
                    {uf && tramo && parametro &&
                        <Grid item xs={12}>
                            <VisualizacionModal ufuncional={uf.replace("/unidad_funcionals/", "")} tramo={tramo.replace("/tramos/", "")} indicador={parametro.replace("/parametros/", "")} />
                        </Grid>
                    }
                </Grid>
            </SimpleForm>
        </Dialog>
    )
});

const ListAction = translate(({ basePath, data, resource, ...props }) => {
    const [open, setOpen] = React.useState(false);
    const { translate } = props;
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = value => {
        setOpen(false);
    };
    return (
        <CardActions>
            {/* <LinkToMapa style={{paddingTop:"13px", marginRight:"20px"}}/> */}
            <CreateButton basePath={basePath} style={{ paddingTop: "13px", marginRight: "20px" }} />
            <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                <VisibilityIcon /> {translate('resources.indicadores_cargar_informacions.visualizacion')}
            </Button>
            <SimpleDialog open={open} onClose={handleClose} />
        </CardActions>
    );
});
const ListAction2 = translate(({ basePath, data, resource, ...props }) => {
    return (
        <CardActions> 
            <CreateButton basePath={basePath} style={{ paddingTop: "13px", marginRight: "20px" }} />
        </CardActions>
    );
});
export const CargarInformacionList = translate(({ permissions, ...props }) => {
    if (!permissions) return <Loading />;
    function formatoAbs(ab) {
        if (typeof ab === "number") return "K" + ab.toFixed(3).replace(".", "+");
        else if (typeof ab === "string") return "K" + ab.replace(".", "+")
    }
    return (
        <List {...props} 
            tittle="Lista de Carga de Información" 
            perPage={25} 
            sort={{ field: 'updatedAt', order: 'DESC' }} 
            filters={<CargarInformacionFilter />} 
            actions={permissions['indicadores4g']  == 1 ? <ListAction /> : permissions['indicadores4g']  == 2 ? <ListAction2 />: null}
            bulkActionButtons={permissions['indicadores4g'] == 1 ? <PostBulkActionButtons /> : null}>
            <Datagrid>
                <ReferenceField label="resources.indicadores_cargar_informacions.fields.indicador" source="indicador" reference="parametros" linkType={false}>
                    <TextField source="codigoCompleto" />
                </ReferenceField>
                <ReferenceField label="resources.indicadores_cargar_informacions.fields.unidadFuncional" source="unidadFuncional" reference="unidad_funcionals" linkType={false}>
                    <TextField source="fullCodigos" />
                </ReferenceField>
                <ReferenceField label="resources.indicadores_cargar_informacions.fields.tramo" source="tramo" reference="tramos" linkType={false}>
                    <TextField source="fullCodigos" />
                </ReferenceField>
                <FunctionField label="resources.indicadores_cargar_informacions.fields.AbscisaInicio" render={record => {
                    if (record.tipoMedicion == "De Corrección") {
                        return formatoAbs(record.abiniCorreccion)
                    } else {
                        return formatoAbs(record.abiniFloat)
                    }
                }} />
                <FunctionField label="resources.indicadores_cargar_informacions.fields.AbscisaFin" render={record => {
                    if (record.tipoMedicion == "De Corrección") {
                        return formatoAbs(record.abfinCorreccion)
                    } else {
                        return formatoAbs(record.abfinFloat)
                    }
                }} />
                {/* <PRField label="Abscisa Inicio" source="abiniFloat" />
                <PRField label="Abscisa Fin" source="abfinFloat" />  */}
                <DateField label="resources.indicadores_cargar_informacions.fields.fechaMedicion" source="fechaMedicion" />
                <TextField label="resources.indicadores_cargar_informacions.fields.medidoPor" source="medidoPor" />
                <TextField label="resources.indicadores_cargar_informacions.fields.estado" source="estado" />
                <TextField label="resources.indicadores_cargar_informacions.fields.tipoMedicion" source="tipoMedicion" />
                {permissions['indicadores4g'] == 1 || permissions['indicadores4g'] == 2 || permissions['indicadores4g'] == 4 ? <ShowButton/>: null}
                {permissions['indicadores4g'] == 1 || permissions['indicadores4g'] == 2 ? <EditButton/>: null}    
            </Datagrid >
        </List >
    )
});