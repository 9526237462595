import React, {useState, Fragment} from 'react';

import { List, Datagrid, TextField, ShowButton, Filter, Loading, TextInput, DateField, DateInput, SelectField, SelectInput } from 'react-admin';
import { CardActions, CreateButton, ExportButton } from 'react-admin';
import { translate } from 'react-admin';
import Button from '@material-ui/core/Button';

const FiltroAudit = translate((props) => {
    return(
        <div>
            <Filter {...props}>
                <TextInput label="resources.audits.fields.module" source="module" resettable alwaysOn />
                <TextInput label="resources.audits.fields.functionality" source="functionality" resettable alwaysOn/>
                <TextInput label="resources.audits.fields.subElement" source="subElement" resettable alwaysOn/>
                <TextInput label="resources.audits.fields.type" source="type" resettable alwaysOn/>
                <DateInput label="resources.audits.fields.fechaInicial" source="dateIn[after]" id="fechaInicial" fullWidth alwaysOn/>
                <DateInput label="resources.audits.fields.fechaFinal" source="dateIn[before]" id="fechaFinal" fullWidth alwaysOn/>
                <SelectInput source='tableMaster' fullWidth alwaysOn choices={[
                    { id:"true", name:'Tabla maestra'},
                    { id:"false", name:'Tabla Relacional'}
                ]}/>
            </Filter>
            <br/>
            <Button variant="outlined" color="primary" alwaysOn onMouseDown={() => props.setFilters({
                module: '',
                functionality: '',
                subElement: '',
                type: '',
                fechaInicial: '',
                fechaFinal: '',
                tableMaster: '',
            })} >
                Limpiar Filtros
            </Button>
            
        </div>
    )
});

export const AuditList = translate(({ permissions, ...props }) => {
    if (!permissions) return <Loading />;  
    return (
        <List 
            {...props} 
            sort={{ field: 'id', order: 'DESC' }} 
            filters={<FiltroAudit/>} 
            actions={null}
            bulkActionButtons={null}
        >
        <Datagrid>
            <TextField label="resources.audits.fields.idElement" source="idElement" sortable={false}/>
            <TextField label="resources.audits.fields.smartCode" source="smartCode" sortable={false}/>
            <TextField label="resources.audits.fields.module" source="module" sortable={false} />
            <TextField label="resources.audits.fields.functionality" source="functionality" sortable={false} />
            <TextField label="resources.audits.fields.subElement" source="subElement" sortable={false} />
            <TextField label="resources.audits.fields.type" source="type" sortable={false} />
            <DateField label="resources.audits.fields.dateIn" source="dateIn" showTime sortable={false} />
            <TextField label="resources.audits.fields.user" source="user" sortable={false} />
            <SelectField source='tableMaster' choices={[
                { id:true, name:'Tabla maestra'},
                { id:false, name:'Tabla Relacional'}
            ]}/>
            {/* {permissions['audit'] == 1 || permissions['audit'] == 2 || permissions['audit'] == 4 ? <ShowButton/>: null}                           */}
            <ShowButton/>                        
        </Datagrid>
    </List>
)});