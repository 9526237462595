import React from 'react';

import { TextInput} from 'react-admin';

import { withStyles } from '@material-ui/core';
import { Edit } from './../../../Edit';
import { SimpleForm } from './../../../SimpleForm';
const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};



const styles = {
    first_field: { display: 'inline-block', width: '30%' },    
};

export const CalificacionIndicadorEdit = withStyles(styles)(({ classes, ...props }) => (
        <Edit title="Editar calificación" {...props} undoable={false}>
            <SimpleForm submitOnEnter={false} redirect="list" >
            <TextInput source="tipo" label="Calificación"/>                
            </SimpleForm>
        </Edit>
))