import React from 'react';
import { TabbedShowLayout, TextField, Tab, DateField,NumberField,SelectField} from 'react-admin';

import { Show } from './../../../Show';
import { withStyles } from '@material-ui/core';
import  {styles}  from './../../../../EstilosGrid';

export const MaterialesShow = withStyles(styles)(({ classes,record, ...props }) => {
    const choises2 = [
        { id: '1', name: 'dynatest.resources.materials.selectMaterials.adoquinEnArcilla' },
        { id: '2', name: 'dynatest.resources.materials.selectMaterials.amadoquinDeConcreto' },
        { id: '3', name: 'dynatest.resources.materials.selectMaterials.afirmado' },
        { id: '4', name: 'dynatest.resources.materials.selectMaterials.baseAsfaltica' },
        { id: '5', name: 'dynatest.resources.materials.selectMaterials.baseDeConcHidraulico' },
        { id: '6', name: 'dynatest.resources.materials.selectMaterials.baseEstOtros' },
        { id: '7', name: 'dynatest.resources.materials.selectMaterials.baseEstbAsfalto' },
        { id: '8', name: 'dynatest.resources.materials.selectMaterials.baseEstEmulsion' },
        { id: '9', name: 'dynatest.resources.materials.selectMaterials.baseGranular' },
        { id: '10', name: 'dynatest.resources.materials.selectMaterials.baseTratada' },
        { id: '11', name: 'dynatest.resources.materials.selectMaterials.concHidraulico' },
        { id: '12', name: 'dynatest.resources.materials.selectMaterials.lechAsfaltica' },
        { id: '13', name: 'dynatest.resources.materials.selectMaterials.mezAbierCaliente' },
        { id: '14', name: 'dynatest.resources.materials.selectMaterials.mezAbierFrio' },
        { id: '15', name: 'dynatest.resources.materials.selectMaterials.mezAsfalGraCaucho' },
        { id: '16', name: 'dynatest.resources.materials.selectMaterials.mezAsfalModPolimeros' },
        { id: '17', name: 'dynatest.resources.materials.selectMaterials.mezDenCaliente' },
        { id: '18', name: 'dynatest.resources.materials.selectMaterials.mezDenFrio' },
        { id: '19', name: 'dynatest.resources.materials.selectMaterials.mezDrenante' },
        { id: '20', name: 'dynatest.resources.materials.selectMaterials.microagloCaliente' },
        { id: '21', name: 'dynatest.resources.materials.selectMaterials.subBaseAsfalEspumado' },
        { id: '22', name: 'dynatest.resources.materials.selectMaterials.subBaseEstabOtros' },
        { id: '23', name: 'dynatest.resources.materials.selectMaterials.subBaseEstabAsfalto' },
        { id: '24', name: 'dynatest.resources.materials.selectMaterials.subBaseEstabCemento' },
        { id: '25', name: 'dynatest.resources.materials.selectMaterials.subBaseEstabGranular' },
        { id: '26', name: 'dynatest.resources.materials.selectMaterials.subraEstabCal' },
        { id: '27', name: 'dynatest.resources.materials.selectMaterials.subraEstabCemento' },
        { id: '28', name: 'dynatest.resources.materials.selectMaterials.subraEstabGeomalla' },
        { id: '29', name: 'dynatest.resources.materials.selectMaterials.subraEstabGeotextil' },
        { id: '30', name: 'dynatest.resources.materials.selectMaterials.subraFina' },
        { id: '31', name: 'dynatest.resources.materials.selectMaterials.subraGranular' },
        { id: '32', name: 'dynatest.resources.materials.selectMaterials.subraCemento' },
    ];
    const optionRenderer = choice => `${choice.id}`;
    const choises3 = [
        { id: '1', name: 'dynatest.resources.materials.selectOption.otros' },
        { id: '2', name: 'dynatest.resources.materials.selectOption.otros' },
        { id: '3', name: 'dynatest.resources.materials.selectOption.noLigado' },
        { id: '4', name: 'dynatest.resources.materials.selectOption.bituminoso' },
        { id: '5', name: 'dynatest.resources.materials.selectOption.cementado' },
        { id: '6', name: 'dynatest.resources.materials.selectOption.noLigado' },
        { id: '7', name: 'dynatest.resources.materials.selectOption.bituminoso' },
        { id: '8', name: 'dynatest.resources.materials.selectOption.bituminoso' },
        { id: '9', name: 'dynatest.resources.materials.selectOption.noLigado' },
        { id: '10', name: 'dynatest.resources.materials.selectOption.cementado' },
        { id: '11', name: 'dynatest.resources.materials.selectOption.cementado' },
        { id: '12', name: 'dynatest.resources.materials.selectOption.bituminoso' },
        { id: '13', name: 'dynatest.resources.materials.selectOption.bituminoso' },
        { id: '14', name: 'dynatest.resources.materials.selectOption.bituminoso' },
        { id: '15', name: 'dynatest.resources.materials.selectOption.bituminoso' },
        { id: '16', name: 'dynatest.resources.materials.selectOption.bituminoso' },
        { id: '17', name: 'dynatest.resources.materials.selectOption.bituminoso' },
        { id: '18', name: 'dynatest.resources.materials.selectOption.bituminoso' },
        { id: '19', name: 'dynatest.resources.materials.selectOption.bituminoso' },
        { id: '20', name: 'dynatest.resources.materials.selectOption.bituminoso' },
        { id: '21', name: 'dynatest.resources.materials.selectOption.noLigado' },
        { id: '22', name: 'dynatest.resources.materials.selectOption.noLigado' },
        { id: '23', name: 'dynatest.resources.materials.selectOption.noLigado' },
        { id: '24', name: 'dynatest.resources.materials.selectOption.noLigado' },
        { id: '25', name: 'dynatest.resources.materials.selectOption.noLigado' },
        { id: '26', name: 'dynatest.resources.materials.selectOption.noLigado' },
        { id: '27', name: 'dynatest.resources.materials.selectOption.noLigado' },
        { id: '28', name: 'dynatest.resources.materials.selectOption.noLigado' },
        { id: '29', name: 'dynatest.resources.materials.selectOption.noLigado' },
        { id: '30', name: 'dynatest.resources.materials.selectOption.noLigado' },
        { id: '31', name: 'dynatest.resources.materials.selectOption.noLigado' },
        { id: '32', name: 'dynatest.resources.materials.selectOption.noLigado' }
    ];

    return( 
        <Show title="resources.materials.name" {...props}>
            <TabbedShowLayout>
                <Tab 
                    label="dynatest.resources.materials.tabs.informacionBasica"
                >
                    <TextField 
                        source="material"  
                        formClassName={classes.grid_cont4s}
                    />                
                     <SelectField 
                        source="tipo"   
                        choices={choises2}  
                        optionText="name"   
                        formClassName={classes.grid_cont4}
                    />
                    <SelectField 
                        source="tipo" 
                        label="resources.materials.fields.comportamiento" 
                        fullWidth 
                        choices={choises3}  
                        optionText="name"  
                        formClassName={classes.grid_cont3}
                    />
                    <TextField 
                        source="especificacion"  
                        fullWidth   
                        formClassName={classes.grid_cont4}
                    />  
                    <NumberField 
                        source="refmodulus" fullWidth 
                        style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} 
                        formClassName={classes.grid_cont4} 
                    />
                    <NumberField  
                        source="minmodulus" fullWidth 
                        style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} 
                        formClassName={classes.grid_cont3}
                    />
                    <NumberField  
                        source="stdfactor" fullWidth 
                        style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} 
                        formClassName={classes.grid_cont4}
                    />
                    <NumberField  
                        source="poissons"
                        fullWidth 
                        style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} 
                        formClassName={classes.grid_cont4}/>
                    {/* <TextInput label="Material Name" source="materialname" fullWidth formClassName={classes.grid_cont3}/> */}
                    <br/>
                    <TextField 
                        source="observaciones" 
                        fullWidth 
                        formClassName={classes.grid_cont4}
                    /> 
                    </Tab>
                <Tab 
                     label="dynatest.resources.materials.tabs.auditoria"
                >
                    <DateField 
                        source="createdAt" showTime
                    /> 
                    <TextField 
                        source="createdBy" 
                    />
                    <span></span>               
                    <DateField 
                        source="updatedAt" showTime
                    />
                    <TextField 
                        source="updatedBy" 
                    />
                    <span></span>   
                </Tab>
            </TabbedShowLayout>
        </Show>
)})