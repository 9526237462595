import React from 'react';
import { CardActions, ListButton , EditButton} from 'react-admin';
import { CarouselImage } from './CarouselImage';

export const ButtonCarousel = ({
    basePath,
    className,
    hasList,
    hasEdit,
    data
}) => (
    <CardActions className={className}>
        {hasList && <ListButton basePath={basePath} />}      
        {hasEdit && <EditButton basePath={basePath} record={data} />} 
        {data && <CarouselImage record={data} />  }
    </CardActions>
);