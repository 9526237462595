import React, { useState, useEffect } from 'react';
import {
    Filter, List, Datagrid, TextField, NumberField, EditButton, ShowButton, CloneButton, SelectInput,
    ReferenceInput, ReferenceField, AutocompleteInput, FormDataConsumer, TextInput, Query, ChipField, SingleFieldList,
    ArrayField, DateField, Confirm, required, Toolbar, CardActions
} from 'react-admin';
import Button from '@material-ui/core/Button';
import { BulkDeleteWithConfirmButton, Loading } from 'react-admin';
import { Fragment } from 'react';
import config from '../../config/config';
import { PRField } from '../../fields/PRField';
import { PimsCreate } from '../Create';
import { Grid } from '@material-ui/core/Grid';
import { SimpleForm } from '../SimpleForm';
import { useListController, ListContextProvider } from 'react-admin';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import { translate } from 'react-admin';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import ButtonMaterial from '@material-ui/core/Button';

let urlAppjs = config.UrlServer;
let urlAppjsFront = config.UrlFront;


export const AprobacionsList = translate(({ permissions, record, ...props }) => {
    const { translate } = props;
    const [open, setOpen] = React.useState(false);
    const [dataSelected, setDataSelected] = React.useState("");
    const handleClose = value => {
        setOpen(false);
    };
    const FiltroAprobacion = (props) => {

        const [uf, setUf] = useState('');
        const ufSet = (value) => {
            setUf(value);
            //console.log(value);
        }

        const [tramo, setTramo] = useState('none');
        const tramoSet = (value) => {
            setTramo(value);
        }

        const [acc, setAcc] = useState('');
        const accSet = (value) => {
            setAcc(value);
            //console.log(value);
        }

        return (
            <Filter {...props}>
                <ReferenceInput
                    label="resources.aprobacions.fields.unidadFuncional"
                    source="unidadFuncional"
                    reference="unidad_funcionals"
                    filterToQuery={searchText => ({ codigo: searchText })}
                    sort={{ field: 'codigo', order: 'asc' }}
                    validate={[ufSet]}
                    alwaysOn
                    fullWidth
                >
                    <AutocompleteInput options={{ fullWidth: true }} optionText="fullCodigos" />
                </ReferenceInput>

                <SelectInput
                    label="resources.aprobacions.fields.tipoRegistro"
                    source="tipoRegistro"
                    sort={{ field: 'name', order: 'ASC' }}
                    validate={[accSet]}
                    choices={[
                        { id: translate("dynatest.resources.aprobacions.select.tipoRegistro.Inventario"), name: "dynatest.resources.aprobacions.select.tipoRegistro.Inventario" },
                        { id: translate("dynatest.resources.aprobacions.select.tipoRegistro.IndicadorComplementario"), name: "dynatest.resources.aprobacions.select.tipoRegistro.IndicadorComplementario" },
                        { id: translate("dynatest.resources.aprobacions.select.tipoRegistro.medicionId"), name: "dynatest.resources.aprobacions.select.tipoRegistro.medicion" },
                        { id: translate("dynatest.resources.aprobacions.select.tipoRegistro.novedad"), name: "dynatest.resources.aprobacions.select.tipoRegistro.novedad" },
                    ]}
                    fullWidth
                    alwaysOn
                    resettable
                />
                {/* <SelectInput
                    label="resources.aprobacions.fields.aprobacion"
                    source="aprobacion"
                    sort={{ field: 'name', order: 'ASC' }}
                    validate={[accSet]}
                    choices={[
                        { id: translate("dynatest.resources.aprobacions.select.aprobacion.aprobado"), name: "dynatest.resources.aprobacions.select.aprobacion.aprobado" },
                        { id: translate("dynatest.resources.aprobacions.select.aprobacion.rechazado"), name: "dynatest.resources.aprobacions.select.aprobacion.rechazado" },
                    ]}
                    fullWidth
                    alwaysOn
                    resettable
                /> */}
            </Filter>
        )
    };

    const ValidarRegistro = (value) => {
        setDataSelected(value);
        let originId = value.originId.split('/')
        originId = originId[2];
        let ids  = originId.replace(/\D/g,"");
        const dataSend = new FormData();
        dataSend.append('id', ids);
        dataSend.append('elemento', value.elemento);
        let requestOptionsReplace = {
            method: 'POST',
            headers: { 'authorization': 'Bearer ' + localStorage.getItem('token') },
            body: dataSend
        };
        if (value.elemento == "respuesta_evaluacion_novedad") {
            fetch(urlAppjs + "/duplicateNovedades/", requestOptionsReplace)
            .then(response => response.json())
            .then((dataRequestInventario) => {
                if(dataRequestInventario == "Success"){
                    AprobarRegistro(value);
                }else if(dataRequestInventario == "Duplicate"){
                    setOpen(true);
                }
            });
        }else{
            AprobarRegistro(value);
        }

    }

    const AprobarRegistro = (value) => {
        let originId = value.originId.split('/')
        originId = originId[2];
        let ids  = originId.replace(/\D/g,"");
        const dataSend = new FormData();
        dataSend.append('id', ids);
        dataSend.append('elemento', value.elemento);
        let requestOptionsReplace = {
            method: 'POST',
            headers: { 'authorization': 'Bearer ' + localStorage.getItem('token') },
            body: dataSend
        };
        fetch(urlAppjs + "/aprobarRegistro/", requestOptionsReplace)
        .then(response => response.json())
        .then((dataRequestInventario) => {
            console.log(dataRequestInventario, "OK")
            window.location.reload();
        });
    }

    const RechazarRegistro = (value) => {
        let originId = value.originId.split('/')
        originId = originId[2];
        let ids  = originId.replace(/\D/g,"");
        const dataSend = new FormData();
        dataSend.append('id', ids);
        dataSend.append('elemento', value.elemento);
        let requestOptionsReplace = {
            method: 'POST',
            headers: { 'authorization': 'Bearer ' + localStorage.getItem('token') },
            body: dataSend
        };
        fetch(urlAppjs + "/rechazarRegistro/", requestOptionsReplace)
            .then(response => response.json())
            .then((dataRequestInventario) => {
                console.log(dataRequestInventario, "OK")
                window.location.reload();
            });
    }

    const EliminarRegistro = (props) => {
        let datos = Object.values(props);
        datos[3].forEach(element => {
            let ids      = element.replace(/\D/g,"");
            let elemen   = element.replace("/aprobacions/", "");
            let elemento = elemen.replace(/\d/g,""); 
            
            const dataSend = new FormData();
            dataSend.append('id', ids);
            dataSend.append('elemento', elemento);
            let requestOptionsReplace = {
                method: 'POST',
                headers: { 'authorization': 'Bearer ' + localStorage.getItem('token') },
                body: dataSend
            };
            fetch(urlAppjs + "/eliminarRegistro/", requestOptionsReplace)
            .then(response => response.json())
            .then((dataRequestInventario) => {
                console.log(dataRequestInventario, "OK")
                window.location.reload();
            });
        });
    }

    const PostBulkActionButtons = (props) => { 
        const [open, setOpen] = useState(false);
        const handleClick = () => setOpen(true);
        const handleDialogClose = () => setOpen(false);
        const handleConfirm = () => {
            EliminarRegistro(props);
            setOpen(false);
        };
    
        return (
            <>
                <Button label="Update Posts" onClick={handleClick} style={{
                    fontSize: '0.8125rem', color: '#f44336', border: 0,
                    display: 'inline-flex', alignItems: 'center',
                    width: '7rem', height: '2rem'
                }}>
                    <DeleteIcon style={{ width: '1.3rem', paddingRight: '0.3rem' }} />BORRAR
                </Button>
                
                <Confirm
                    isOpen={open}
                    title="Borrar. Registros pendientes por aprobar"
                    content="¿Estás seguro de que quieres borrar este elemento?"
                    onConfirm={handleConfirm}
                    onClose={handleDialogClose}
                />
            </>
        );
                    
    };

    function SimpleDialog(props) {
        const { onClose, selectedValue, open } = props;
        const [valueModal, setValueModal] = React.useState('');
        // const { translate } = props;
        const handleChange = (event) => {
            setValueModal(event.target.value);
        };
    
        const handleClose = () => {
            onClose(selectedValue);
        };
    
        return (
            <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle id="simple-dialog-title">{translate('dynatest.resources.aprobacions.modalTitle')}</DialogTitle>
                <DialogContent>
                    {translate('dynatest.resources.aprobacions.modalMessaje')}
                </DialogContent>
                <DialogActions style={{alignSelf:"center"}}>
                    <ButtonMaterial variant="contained" color="primary" id="successfullButton" onClick={() => {AprobarRegistro(dataSelected)}}>
                        {translate('dynatest.resources.aprobacions.buttons.aprobar')}
                    </ButtonMaterial>
                    <ButtonMaterial variant="contained" color="secondary" id="canceledButton" onClick={() => {RechazarRegistro(dataSelected)}} >
                        {translate('dynatest.resources.aprobacions.buttons.rechazar')}                      
                    </ButtonMaterial>
                </DialogActions>
            </Dialog>
        );
    }

    const { codigo } = props;    
    if (!permissions) return <Loading />;
    return (
        <Fragment>
            <SimpleDialog open={open} onClose={handleClose} />
            <List 
                title="resources.aprobacions.title" {...props}
                filters={<FiltroAprobacion />}
                sort={{ field: 'updatedAt', order: 'DESC' }}
                bulkActionButtons={permissions['aprobacion']  == 1 ? <PostBulkActionButtons />: null}
                actions={false} 
                >
                <Datagrid>
                    <TextField label="resources.aprobacions.fields.tipoRegistro" source="tipoRegistro" linkType={false} fullWidth />
                    <DateField label="resources.aprobacions.fields.fecha" source="updatedAt" linkType={false} sortable={false} fullWidth />
                    <ReferenceField label="resources.aprobacions.fields.unidadFuncional" source="unidadFuncional" reference="unidad_funcionals" linkType={false}>
                        <TextField source="fullCodigos" />
                    </ReferenceField>
                    <TextField label="resources.aprobacions.fields.usuario" source="createdBy" linkType={false} fullWidth />
                    {permissions['aprobacion'] == 1 || permissions['aprobacion'] == 2  ?
                        <FormDataConsumer>
                            {
                                ({ formData, scopedFormData, getSource, ...rest }) => {
                                    return (
                                            <Button
                                                onClick={() => {
                                                    ValidarRegistro(formData)
                                                }}
                                                id="btnAprobar"
                                                variant="outlined"
                                                style={{
                                                    fontSize: '0.8125rem', color: '#3f51b5', border: 0,
                                                    display: 'inline-flex', alignItems: 'center',
                                                    width: '7rem', height: '2rem'
                                                }}
                                            >
                                                <CheckCircleIcon style={{ width: '1.3rem', paddingRight: '0.3rem' }} />  {translate('dynatest.resources.aprobacions.buttons.aprobar')}
                                            </Button>
                                    )
                                }
                            }
                        </FormDataConsumer>
                        :null
                    }
                    {permissions['aprobacion'] == 1 || permissions['aprobacion'] == 2  ?
                        <FormDataConsumer>
                            {
                                ({ formData, scopedFormData, getSource, ...rest }) => {
                                    return (
                                        <Button
                                            onClick={() => {
                                                RechazarRegistro(formData)
                                            }}
                                            variant="outlined"
                                            style={{
                                                fontSize: '0.8125rem', color: '#3f51b5', border: 0,
                                                display: 'inline-flex', alignItems: 'center',
                                                width: '7rem', height: '2rem'
                                            }}
                                        >
                                            <CancelIcon style={{ width: '1.3rem', paddingRight: '0.3rem' }} />  {translate('dynatest.resources.aprobacions.buttons.rechazar')}
                                        </Button>
                                    )
                                }
                            }
                        </FormDataConsumer>
                        :null
                    }
                    {permissions['aprobacion'] == 1 || permissions['aprobacion'] == 2 || permissions['aprobacion'] == 4 ? 
                        <FormDataConsumer>
                        {
                            ({ formData, scopedFormData, getSource, ...rest }) => {
                                return (
                                    <Button
                                        onClick={() => {  
                                            let originId = formData.originId.split('/')
                                            originId = originId[2];
                                            let ids  = originId.replace(/\D/g,"");
                                            console.log(ids);
                                        }}
                                        variant="outlined"
                                        href={urlAppjsFront + formData.urlShow}
                                        target="_blank"
                                        style={{
                                            fontSize: '0.8125rem', color: '#3f51b5', border: 0,
                                            display: 'inline-flex', alignItems: 'center',
                                            width: '7rem', height: '2rem'
                                        }}
                                    >
                                        <RemoveRedEyeIcon style={{ width: '1.3rem', paddingRight: '0.3rem' }} />  {translate('dynatest.resources.aprobacions.buttons.mostrar')}
                                    </Button>
                                )
                            }
                        }
                        </FormDataConsumer>
                        : null
                    }
                    

                </Datagrid>
            </List>
        </Fragment>
    )
});