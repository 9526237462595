import React, { useState, useEffect } from 'react';
import { styles } from '../../EstilosGrid';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CancelIcon from '@material-ui/icons/Cancel';
import { withStyles } from '@material-ui/core';
import { ListButton, FileInput, FileField } from 'react-admin';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
// import ProgressBar from 'react-bootstrap/Button';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Filter, ShowController, SimpleForm, Datagrid, ReferenceInput, Toolbar, BooleanInput, SelectInput, NumberField, EditButton, ShowButton } from 'react-admin';
import { BulkDeleteWithConfirmButton, required, Loading } from 'react-admin';
import AddIcon from '@material-ui/icons/Add';
import PropTypes, { array, element } from 'prop-types';
//icons
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Dropzone from "react-dropzone";
import CircularProgress from '@material-ui/core/CircularProgress';
import config from '../../config/config';
import { translate } from 'react-admin';
import ErrorIcon from '@material-ui/icons/Error';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ReactExport from "react-export-excel";
import BugReportIcon from '@material-ui/icons/BugReport';
import IconButton from '@material-ui/core/IconButton';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { PimsCreate } from './../Create';
import PrintIcon from '@material-ui/icons/Print';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
let urlAppjs = config.UrlServer;
let urlAppjsFront = config.UrlFront;

export const CargaMasivaEdit = translate(withStyles(styles)(({ classes, basePath, ...props }) => {
    const { translate } = props;
    const [idCargaMasiva, setIdCargaMasiva] = useState(window.location.hash);
    const [open, setOpen] = React.useState(false);
    const [openStop, setOpenStop] = React.useState(false);
    const [openAlert, setOpenAlert] = React.useState(false);
    const [openUpload, setOpenUpload] = React.useState(false);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [modalOpenStop, setModalOpenStop] = React.useState(false);
    const [modalOpenAlert, setModalOpenAlert] = React.useState(false);
    const [modalOpenUpload, setModalOpenUpload] = React.useState(false);
    const [nameGroup, setNameGroup] = React.useState("");
    const [indexSelected, setIndexSelected] = React.useState("");
    const [indexSelectedUpload, setIndexSelectedUpload] = React.useState(0);
    const [dataSelected, setDataSelected] = React.useState(0);
    const [dataSelectedUpload, setDataSelectedUpload] = React.useState(0);
    const [listData, setListData] = React.useState("");
    let openTestUpload = [];
    const handleClickOpen = (indexTo) => {
        setIndexSelected(indexTo);
        setNameGroup(listData.nombreGrupo);
        setDataSelected(listData.cargaMasivaArchivos[indexTo]);
        setModalOpen(true);
        setOpen(true);
    };
    const handleClickOpenStop = (indexTo) => {
        setIndexSelected(indexTo);
        setNameGroup(listData.nombreGrupo);
        setDataSelected(listData.cargaMasivaArchivos[indexTo]);
        setModalOpenStop(true);
        setOpenStop(true);
    };
    const handleClickOpenAlert = (indexTo) => {
        setIndexSelected((indexTo == indexSelected) ? indexTo + 100 : indexTo );
        setNameGroup(listData.nombreGrupo);
        setDataSelected(listData.cargaMasivaArchivos[indexTo]);
        setModalOpenAlert(true);
        setOpenAlert(true);
    };
    const handleClickOpenUpload = (indexToUp) => {
        setIndexSelectedUpload((indexToUp == indexSelectedUpload) ? indexToUp + 100 : indexToUp );
        setNameGroup(listData.nombreGrupo);
        setDataSelectedUpload(listData.cargaMasivaArchivos[indexToUp]);
        setModalOpenUpload(true);
        setOpenUpload(true);
    };
    const handleClose = value => {
        setOpen(false);
    };
    const handleCloseStop = value => {
        setOpenStop(false);
    };
    const handleCloseAlert = value => {
        setOpenAlert(false);
    };
    const handleCloseUpload = value => {
        setOpenUpload(false);
    };
    
    useEffect(() => {
        let querySend = idCargaMasiva.replace("#/carga_masivas", "");
        fetch(urlAppjs + querySend, {
            method: 'GET',
            headers:{
                'authorization': 'Bearer '+ localStorage.getItem('token')
            }
        })
        .then(res => res.json())
        .then(datos => {
            if (datos.code == 401) {
                setListData([]);
            }else{
                setListData(datos);
            }
        });
    }, []);

    if(listData == ""){
        return <Loading />
    } else {
        return (
            <PimsCreate {...props} title="dynatest.resources.carga_masivas.title.editTitle" actions={false} basePath='/carga_masivas'>
                <div>
                    <Card variant="outlined">
                        <CardContent>
                            <Grid item xs={12} align={'right'}>
                                <ListButton basePath={basePath} />
                            </Grid>
                        </CardContent>
                        <CardContent>
                            <Grid item xs={12} >
                                <Grid >
                                    <TextField id="input-with-icon-textfield" label={translate("resources.carga_masivas.fields.nombreGrupo")} fullWidth disabled defaultValue={listData.nombreGrupo}/>
                                    <br></br>
                                    <br></br>
                                    <Grid >
                                        {listData && 
                                            listData.cargaMasivaArchivos.map((fileName, index) => (
                                                <div>
                                                    <Grid container>
                                                        <Grid item xs={5}>
                                                            <Grid container xs={12} id={index} style={{padding:'2%', borderRadius:'15px', color:'white', background:'#95CCAC'}}>
                                                                <Grid container>
                                                                    <Grid item xs={10} style={{whiteSpace:'nowrap', overflow: 'hidden', textOverflow:'ellipsis', paddingLeft: '2%'}}>
                                                                        {fileName.rutaArchivo}
                                                                    </Grid>
                                                                    <Grid item xs={1}>
                                                                    </Grid>
                                                                    <Grid item xs={1} style={{cursor:'pointer'}}>
                                                                        <VisibilityIcon onClick={() => { handleClickOpen(index) }}> </VisibilityIcon>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={1} style={{display:'flex'}}>
                                                                {(fileName.estado == 3 || fileName.estado == 4 || fileName.estado == 6) ? 
                                                                    (<Grid item xs={6}>
                                                                        {(fileName.cargaMasivaErrorCount > 0) ?
                                                                                (<IconButton size="small" onClick={() => { handleClickOpenUpload(index) }}> <PlayArrowIcon style={{color:'#0F78BC'}} fontSize="inherit"/></IconButton>):
                                                                                (<IconButton size="small" onClick={() => { handleClickOpenAlert(index) }}> <PlayArrowIcon style={{color:'#0F78BC'}} fontSize="inherit"/></IconButton>)
                                                                        }
                                                                    </Grid>):
                                                                    (<Grid item xs={6}>
                                                                        <IconButton size="small" disabled type='button'> <PlayArrowIcon style={{color:'#e0e0e0'}} fontSize="inherit"/></IconButton>
                                                                    </Grid>)
                                                                }
                                                                {(fileName.estado == 2 || fileName.estado == 6) ? 
                                                                    (<Grid item xs={6}>
                                                                        <IconButton size="small" type='button' disabled> <StopIcon style={{color:'#0F78BC'}} fontSize="inherit"/></IconButton>
                                                                    </Grid>):
                                                                    (<Grid item xs={6}>
                                                                        <IconButton size="small" onClick={() => { handleClickOpenStop(index) }}> <StopIcon style={{color:'#0F78BC'}} fontSize="inherit"/></IconButton>
                                                                    </Grid>)
                                                                }
                                                        </Grid>
                                                        <Grid container xs={5}>
                                                            <Grid container xs={12}>
                                                                {fileName.cargaMasivaAlertaCount > 0 &&
                                                                    <Grid container xs={3}>
                                                                        <Grid container>
                                                                            <Button style={{fontSize:'smaller', alignItems:'flex-end', height:'75%'}} onClick={() => { handleClickOpenAlert(index)}}> <ErrorIcon style={{color:'#ebce00', fontSize:'large'}}/> &nbsp; {translate('dynatest.resources.carga_masivas.button.alertas')}</Button>
                                                                        </Grid>      
                                                                    </Grid>
                                                                }
                                                                {fileName.cargaMasivaErrorCount > 0 &&
                                                                    <Grid container xs={3}>
                                                                        <Grid container>
                                                                            <Button style={{fontSize:'smaller', alignItems:'flex-end', height:'75%'}} onClick={() => { handleClickOpenUpload(index)}}> <BugReportIcon style={{color:'#e10202', fontSize:'large'}}/> &nbsp; {translate('dynatest.resources.carga_masivas.button.errores')} </Button>
                                                                        </Grid> 
                                                                    </Grid>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={1} align={'right'}>
                                                                {(() => {
                                                                    if (fileName.estado == 1) {
                                                                        return (<CircularProgress size={18} />)
                                                                    } else if (fileName.estado == 2) {
                                                                        return (<CheckCircleIcon style={{color:'#95CCAC', fontSize: '24px'}}/>)
                                                                    } else if (fileName.estado == 3) {
                                                                        return (<strong>60%</strong>)
                                                                    } else if (fileName.estado == 4) {
                                                                        return ( <CancelIcon style={{color:'#e10202', fontSize: '24px'}}/>)
                                                                    } else if (fileName.estado == 5) {
                                                                        return (<CircularProgress size={18} />)
                                                                    } else if (fileName.estado == 6) {
                                                                        return (<strong>0%</strong>)
                                                                    }
                                                                })()}
                                                        </Grid>
                                                    </Grid>
                                                    <hr></hr>
                                                </div>
                                            ))
                                        }
                                        {modalOpen &&
                                            <SimpleDialog open={open} onClose={handleClose} indexFile={indexSelected} dataSource={dataSelected} nameGroup={nameGroup} translate={translate} />
                                        }
                                        {modalOpenAlert &&
                                            <SimpleDialogAlert open={openAlert} onClose={handleCloseAlert} indexFile={indexSelected} dataSource={dataSelected} nameGroup={nameGroup} translate={translate} />
                                        }
                                        {modalOpenStop &&
                                            <SimpleDialogStop open={openStop} onClose={handleCloseStop} indexFile={indexSelected} dataSource={dataSelected} nameGroup={nameGroup} translate={translate} />
                                        }
                                        {modalOpenUpload &&
                                            <SimpleDialogUpload open={openUpload} onClose={handleCloseUpload} indexFile={indexSelectedUpload} dataCard={dataSelectedUpload} nameGroup={nameGroup} translate={translate} />
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                        {/* {fileNames.length > 0 && 
                            <CardContent style={{background:'aliceblue'}}>
                                <Grid container >
                                    <Grid xs={12} style={{textAlign:'center'}}>
                                        <PlayArrowIcon onClick={() => { saveForm(); }} style={{cursor:'pointer', fontSize: '36px'}} > </PlayArrowIcon>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        } */}
                    </Card>
                </div>
            </PimsCreate>
        )
    }
}));

function SimpleDialog(props) {
    const { translate } = props;
    const { onClose, selectedValue, open } = props;
    const handleClose = () => {
        onClose(selectedValue);
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} fullWidth={'fullWidth'} maxWidth={'md'} keepMounted={true}>
            <DialogTitle id="simple-dialog-title"><strong>{props.nameGroup}</strong><br></br> <div style={{fontSize:'smaller'}}>{props.dataSource.rutaArchivo}</div>
            </DialogTitle>
            <SimpleForm toolbar={false}>
                <Grid container spacing={24}>
                    <Grid item xs={4}>
                        {translate('resources.carga_masivas.fields.proyectoList')} {props.dataSource.proyecto}
                        <hr></hr>
                    </Grid>
                    <Grid item xs={4}>
                        {translate('resources.carga_masivas.fields.rutaList')} {props.dataSource.ruta}
                        <hr></hr>
                    </Grid>
                    <Grid item xs={4}>
                        {translate('resources.carga_masivas.fields.unidadFuncional')} {props.dataSource.unidadFuncional}
                        <hr></hr>
                    </Grid>
                    <Grid item xs={4}>
                        {translate('resources.carga_masivas.fields.tramoList')} {props.dataSource.tramo}
                        <hr></hr>
                    </Grid>
                    <Grid item xs={4}>
                        {(() => {
                            if (props.dataSource.sistema === 'Puentes y estructuras') {
                                if (props.dataSource.subSistema == "CONT") {
                                    return (
                                      <div>{translate('resources.carga_masivas.fields.sistemaEstrucCont')}</div>
                                    )
                                } else if (props.dataSource.subSistema == "PNTE") {
                                return (
                                    <div>{translate('resources.carga_masivas.fields.sistemaPuentes')}</div>
                                )
                                } else if (props.dataSource.subSistema == "PTON") {
                                return (
                                    <div>{translate('resources.carga_masivas.fields.sistemaPontones')}</div>
                                )
                                } else if (props.dataSource.subSistema == "TNEL") {
                                return (
                                    <div>{translate('resources.carga_masivas.fields.sistemaTuneles')}</div>
                                )
                                }
                            } else if (props.dataSource.sistema !== 'Puentes y estructuras') {
                                return (
                                    <div>{translate('resources.carga_masivas.fields.sistemaList')} {props.dataSource.sistema} </div>
                                )
                            }
                        })()}
                        <hr></hr>
                    </Grid>
                    <Grid item xs={4}>
                        {props.dataSource.imagenes}
                        {translate('resources.carga_masivas.fields.imagenes')} {(props.dataSource.imagenes == true) ? 'Si' : 'No' }
                        <hr></hr>
                    </Grid>
                </Grid>
            </SimpleForm>
            <Card variant="outlined">
                <CardContent align="center">
                    <Button id="guardarFiltro" style={{ backgroundColor: '#0F78BC', color: '#fff' }} onClick={handleClose}>
                        {translate('dynatest.resources.carga_masivas.button.confirmar')}
                    </Button>
                </CardContent>
            </Card>
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};

function SimpleDialogUpload(props) {
    const { translate } = props;
    const { onClose, selectedValue, open } = props;
    const [infoFile, setInfoFile] = useState(props.dataCard);
    const [fileNames, setFileNames] = useState([]);
    const [files, setFiles] = useState([]);
    const [dataFile, setDataFile] = useState([]);
    const [dateExport, setDateExport] = useState(new Date().toISOString().slice(0, 10));

    useEffect(() => {
        let idArchivo = props.dataCard['@id'].replace("/carga_masiva_archivos/", "");
        let urlFetch = "/carga_masiva_error_archivos?cargaMasivaArchivo=" + idArchivo + "&pagination=false";
        fetch(urlAppjs + urlFetch, {
            method: 'GET',
            headers:{
                'authorization': 'Bearer '+ localStorage.getItem('token')
            }
        })
        .then(res => res.json())
        .then(datos => {
            let data = datos['hydra:member'];
            if (data.length == 0) {
                setDataFile([]);
            }else{
                setDataFile(data);
            }
        });
    }, [props.indexFile]); 

    const handleClose = () => {
        setDataFile([]);
        onClose(selectedValue);
    };
    const handleDrop = acceptedFiles =>{
        setFileNames(acceptedFiles.map(file => {
            return {rutaArchivo: file.name}
        }));
        setFiles(acceptedFiles);
    };
    const saveForm = () => {
        var formData = new FormData();
        files.map((file, index) => {
            formData.append(`files[${index}]`, file);
        });

        fetch(urlAppjs + '/cargaUpload', {
            method: 'POST',
            body: formData,
            headers:{
                'authorization': 'Bearer '+ localStorage.getItem('token')
            }
        })
        .then(res => res.json())
        .then((success) => {
            if(success == "Success"){
                let arraySend = {...fileNames[0], estado: 1}
                let data = JSON.stringify(arraySend);
                fetch(urlAppjs + props.dataCard['@id'], {
                    method: 'PUT',
                    body: data,
                    headers:{
                        'authorization': 'Bearer '+ localStorage.getItem('token'),
                        'Content-Type': 'application/json'
                    }
                })
                .then(res => res.json())
                .then(datos => {
                    window.location.reload();
                });
            }
        });
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} fullWidth={'fullWidth'} maxWidth={'sm'} keepMounted={true}>
            <DialogTitle id="simple-dialog-title" align="center"><strong>{translate('dynatest.resources.carga_masivas.button.errores')}</strong><br></br> <div style={{fontSize:'smaller'}}>{props.nameGroup}<br></br>{props.dataCard.rutaArchivo}</div>
            </DialogTitle>
            <SimpleForm toolbar={false}>
                    {dataFile.length > 0 &&
                        <Grid container spacing={24}>
                            <Grid item xs={12} align="center">
                                {translate('dynatest.resources.carga_masivas.message.dialogErrores')}
                            </Grid>
                            <Grid item xs={12} align="center">
                                {props.dataCard.cargaMasivaErrorCount > 0 &&
                                    <ExcelFile filename={"Errores" + dateExport} element={<Button> <PrintIcon style={{color:'#0F78BC'}} size={18}/> &nbsp; {translate('dynatest.resources.carga_masivas.button.descargar')}</Button>}>
                                        <ExcelSheet data={dataFile} name="Errores">
                                            <ExcelColumn label="Description Error" value="errorDescription"/>
                                        </ExcelSheet>
                                    </ExcelFile>
                                }
                            </Grid>
                            {/* <Grid item xs={12} >
                                <Dropzone onDrop={handleDrop}>
                                    {({ acceptedFiles, getRootProps, getInputProps }) => (
                                    <div {...getRootProps({ className: "dropzone" })}>
                                        <input {...getInputProps()} multiple={false}/>
                                        <p>Drag'n'drop file, or click to select file</p>
                                    </div>
                                    )}
                                </Dropzone>
                                <Grid >
                                    {fileNames.map((fileName, index) => (
                                        <div>
                                            <Grid item xs={12} className={'padding-left-1px'} style={{whiteSpace:'nowrap', overflow: 'hidden', textOverflow:'ellipsis'}}>
                                                {fileName.rutaArchivo}
                                            </Grid>
                                        </div>
                                    ))}
                                </Grid>
                            </Grid> */}
                        </Grid>
                    }
                    {dataFile.length == 0 &&
                        <Grid container spacing={24}>
                            <Grid item xs={12} align="center">
                                <CircularProgress />
                            </Grid>
                        </Grid>
                    }
            </SimpleForm>
            <Card variant="outlined">
                <CardContent align="center">
                    <Button id="guardarFiltro" style={{ backgroundColor: '#0F78BC', color: '#fff' }} onClick={handleClose}>
                        {translate('dynatest.resources.carga_masivas.button.confirmar')}
                    </Button>
                    &nbsp;
                    &nbsp;
                    {/* <Button id="guardarFiltro" style={{ backgroundColor: '#e36c6c', color: '#fff' }} onClick={handleClose}>
                        Cancelar
                    </Button> */}
                </CardContent>
            </Card>
        </Dialog>
    );
}

SimpleDialogUpload.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};

function SimpleDialogAlert(props) {
    const { translate } = props;
    const { onClose, selectedValue, open } = props;
    const [dateExport, setDateExport] = useState(new Date().toISOString().slice(0, 10));
    const [openConfirm, setOpenConfirm] = React.useState(false);
    const [dataFile, setDataFile] = useState([]);

    useEffect(() => {
        let idArchivo = props.dataSource['@id'].replace("/carga_masiva_archivos/", "");
        let urlFetch = "/carga_masiva_alerta_archivos?cargaMasivaArchivo=" + idArchivo + "&pagination=false";
        fetch(urlAppjs + urlFetch, {
            method: 'GET',
            headers:{
                'authorization': 'Bearer '+ localStorage.getItem('token')
            }
        })
        .then(res => res.json())
        .then(datos => {
            let data = datos['hydra:member'];
            if (data.length == 0) {
                setDataFile([]);
            }else{
                setDataFile(data);
            }
        });
    }, [props.indexFile]); 

    const handleClose = () => {
        setDataFile([]);
        onClose(false);
    };
    const handleCloseConfirm = () => {
        setOpenConfirm(false);
    };

    const approveFileConfirm = () => {
        setOpenConfirm(true);
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} fullWidth={'fullWidth'} maxWidth={'sm'} keepMounted={true}>
            <DialogTitle id="simple-dialog-title" align="center"><strong>{translate('dynatest.resources.carga_masivas.button.alertas')}</strong><br></br> <div style={{fontSize:'smaller'}}>{props.nameGroup} <br></br> {props.dataSource.rutaArchivo}</div>
            </DialogTitle>
            <SimpleForm toolbar={false}>
                {dataFile.length > 0 &&
                    <Grid container spacing={24}>
                        <Grid item xs={12} align="center">
                            {translate('dynatest.resources.carga_masivas.message.dialogAlerts')}
                        </Grid>
                        <Grid item xs={12} align="center">
                            {props.dataSource.cargaMasivaAlertaCount > 0 &&
                                <ExcelFile filename={"Alertas" + dateExport} element={<Button> <PrintIcon style={{color:'#0F78BC'}} size={18}/> &nbsp; {translate('dynatest.resources.carga_masivas.button.descargar')}</Button>}>
                                    <ExcelSheet data={dataFile} name="Alertas">
                                        <ExcelColumn label="Description Alert" value="alertDescription"/>
                                    </ExcelSheet>
                                </ExcelFile>
                            }
                        </Grid>
                        {(props.dataSource.cargaMasivaErrorCount == 0)?
                            (""):
                            (<Grid item xs={12} align="center">
                                {translate('dynatest.resources.carga_masivas.message.dialogAddAlert')}
                            </Grid>)
                        }
                    </Grid>
                }
                {dataFile.length == 0 &&
                    <Grid container spacing={24}>
                        <Grid item xs={12} align="center">
                            <CircularProgress />
                        </Grid>
                    </Grid>
                }
            </SimpleForm>
            <Card variant="outlined">
                <CardContent align="center">
                    {(props.dataSource.cargaMasivaErrorCount == 0)?
                        (<Button id="guardarFiltro" style={{ backgroundColor: '#0F78BC', color: '#fff' }} onClick={approveFileConfirm}>
                            {translate('dynatest.resources.carga_masivas.button.confirmar')}
                        </Button>):
                        (<Button disabled id="guardarFiltro" style={{ backgroundColor: '#0F78BC', color: '#fff' }} onClick={approveFileConfirm}>
                            {translate('dynatest.resources.carga_masivas.button.confirmar')}
                        </Button>)
                    }
                    &nbsp;
                    &nbsp;
                    <Button id="guardarFiltro" style={{ backgroundColor: '#e36c6c', color: '#fff' }} onClick={handleClose}>
                        {translate('dynatest.resources.carga_masivas.button.cancelar')}
                    </Button>
                </CardContent>
            </Card>
            <SimpleDialogAlertConfirm open={openConfirm} onClose={handleCloseConfirm} dataSource={props.dataSource} translate={translate} />
        </Dialog>
    );
}

SimpleDialogAlert.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};

function SimpleDialogStop(props) {
    const { translate } = props;
    const { onClose, selectedValue, open } = props;
    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleClickSave = () => {
        let dataSend = props.dataSource;
        let arraySend = {estado: 6};
        fetch(urlAppjs + dataSend['@id'], {
            method: 'PUT',
            body: JSON.stringify(arraySend),
            headers:{
                'authorization': 'Bearer '+ localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(datos => {
            window.location.reload();
        });
    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} fullWidth={'fullWidth'} maxWidth={'sm'} keepMounted={true}>
            <DialogTitle id="simple-dialog-title" align="center"><strong>{props.nameGroup}</strong><br></br> <div style={{fontSize:'smaller'}}>{props.dataSource.rutaArchivo}</div>
            </DialogTitle>
            <SimpleForm toolbar={false}>
                <Grid container spacing={24}>
                    <Grid item xs={12} align="center">
                        <ErrorOutlineIcon style={{fontSize:'xxx-large', color:'#ebce00'}}/>
                        <br></br>
                        <br></br>
                        {translate('dynatest.resources.carga_masivas.message.dialogStopQ')}
                    </Grid>
                    <Grid item xs={12} align="center">
                        {translate('dynatest.resources.carga_masivas.message.dialogStop')}
                        <br></br>
                    </Grid>
                </Grid>
            </SimpleForm>
            <Card variant="outlined">
                <CardContent align="center">
                    <Button id="guardarFiltro" style={{ backgroundColor: '#0F78BC', color: '#fff' }} onClick={handleClickSave}>
                        {translate('dynatest.resources.carga_masivas.button.confirmar')}
                    </Button>
                    &nbsp;
                    &nbsp;
                    <Button id="guardarFiltro" style={{ backgroundColor: '#e36c6c', color: '#fff' }} onClick={handleClose}>
                        {translate('dynatest.resources.carga_masivas.button.cancelar')}
                    </Button>
                </CardContent>
            </Card>
        </Dialog>
    );
}

SimpleDialogStop.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};

function SimpleDialogAlertConfirm(props) {
    const { translate } = props;
    const { onClose, selectedValue, open } = props;
    const [dateExport, setDateExport] = useState(new Date().toISOString().slice(0, 10));
    const handleCloseConfirm = () => {
        onClose(false);
    };

    const approveFile = () => {
        let arraySend = {estado: 5}
        let data = JSON.stringify(arraySend);
        fetch(urlAppjs + props.dataSource['@id'], {
            method: 'PUT',
            body: data,
            headers:{
                'authorization': 'Bearer '+ localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }
        })
        .then(res => res.json())
        .then(datos => {
            window.location.reload();
        });
    };

    return (
        <Dialog onClose={handleCloseConfirm} aria-labelledby="simple-dialog-title" open={open} fullWidth={'fullWidth'} maxWidth={'sm'} keepMounted={true}>
            <SimpleForm toolbar={false}>
                <Grid container spacing={24}>
                    <Grid item xs={12} align="center">
                        {translate('dynatest.resources.carga_masivas.message.dialogConfirmAlert')}
                    </Grid>
                </Grid>
            </SimpleForm>
            <Card variant="outlined">
                <CardContent align="center">
                    <Button id="guardarFiltro" style={{ backgroundColor: '#0F78BC', color: '#fff' }} onClick={approveFile}>
                        {translate('dynatest.resources.carga_masivas.button.siConfirmar')}
                    </Button>
                    &nbsp;
                    &nbsp;
                    <Button id="guardarFiltro" style={{ backgroundColor: '#e36c6c', color: '#fff' }} onClick={handleCloseConfirm}>
                        {translate('dynatest.resources.carga_masivas.button.cancelar')}
                    </Button>
                </CardContent>
            </Card>
        </Dialog>
    );
}

SimpleDialogAlertConfirm.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};