import React from 'react';
import { SimpleForm, NumberInput, ReferenceInput, 
        SelectInput, FormDataConsumer,  DateInput, AutocompleteInput,
 TextInput, Query
    } from 'react-admin';

import { PimsCreate } from './../../Create';

import { PInput } from '../../../inputs/PInput';
import { GMapInput } from '../../../inputs/GMapInput';
import Grid from '@material-ui/core/Grid';

import { withStyles } from '@material-ui/core';

import { PimsElementoActions } from './../PimsElementoActions';

import {
    required,

} from 'react-admin';

const styles = {    
    verySmallField: { display: 'inline-flex', marginRight: '1%', width:  '9%' },
    smallField:     { display: 'inline-flex', marginRight: '1%', width: '19%' },
    middleField:    { display: 'inline-flex', marginRight: '1%', width: '39%' },
    bigField:       { display: 'inline-flex', marginRight: '1%', width: '99%' },
    innerField:     { width: '100%'}
};

const tipoElemento = { sistema: 'OTR' };

export const ElementoOtroCreate = withStyles(styles)(({ classes, ...props }) => (
    <PimsCreate  {...props} title="Nuevo elemento otro" actions={<PimsElementoActions/>}>
        <SimpleForm submitOnEnter={false} redirect="edit">       
                <Grid container spacing={24}>
                    <Grid item xs={8}>
                        <Grid container spacing={24}>
                            <Grid item xs={6}>
                                <ReferenceInput label="Segmento" source="segmento" reference="segmentos" validate={[required()]} filter={ { tramo: props.tramo } }>
                                    <SelectInput  optionText="fullCode" fullWidth />
                                </ReferenceInput>
                            </Grid>
                            <Grid item xs={6}>
                                <ReferenceInput label="Tipo elemento" source="tipoElemento" reference="tipo_elementos" alwaysOn filter={tipoElemento}>
                                    <SelectInput optionText="nombre" fullWidth />
                                </ReferenceInput>
                            </Grid>
                            <Grid item xs={6}>                                
                                <PInput label="Abscisa inicial" source="pInicio" fullWidth validate={[required()]}/>
                            </Grid>
                            <Grid item xs={6}>                                
                                <FormDataConsumer>
                                {
                                    ({ formData, ...rest }) => (                                        
                                        <Query type="GET_ONE" resource="tipo_elementos" payload={{ id: formData !== null ? formData.tipoElemento : -1, fromCache: true }} >
                                            {({ data, loading, error }) => {                                                
                                                if( data && data.puntos > 1 ){
                                                    return <PInput label="Abscisa final" source="pFin" fullWidth validate={[required()]}/>;
                                                }
                                                return <div></div>;                                                
                                            }}
                                        </Query>
                                    )
                                }
                                </FormDataConsumer>                                
                            </Grid>
                            <Grid item xs={6}>
                                <FormDataConsumer>
                                {
                                    ({ formData, ...rest }) => (                                        
                                        <ReferenceInput label="Departamento" source="segmento" reference="segmentos" filter={{id: formData !== null ? formData.segmento : null }} {...rest} disabled>
                                            <SelectInput  optionText="municipio_.departamento_.nombre" fullWidth />
                                        </ReferenceInput>
                                    )
                                }
                                </FormDataConsumer>
                            </Grid>
                            <Grid item xs={6}>                                
                                <FormDataConsumer>
                                {
                                    ({ formData, ...rest }) => (                                        
                                        <ReferenceInput label="Municipio" source="segmento" reference="segmentos" filter={{id: formData !== null ? formData.segmento : null}} {...rest} disabled>
                                            <SelectInput  optionText="municipio_.nombre" fullWidth />
                                        </ReferenceInput>
                                    )
                                }
                                </FormDataConsumer>
                            </Grid>
                            <Grid item xs={6}>
                                <FormDataConsumer>
                                {
                                    ({ formData, ...rest }) => (                                        
                                        <ReferenceInput label="Tipo calzada" source="segmento" reference="segmentos" filter={{id: formData !== null ? formData.segmento : null}} {...rest} disabled>
                                            <SelectInput  optionText="tipoCalzada_.nombre" fullWidth />
                                        </ReferenceInput>
                                    )
                                }
                                </FormDataConsumer>
                            </Grid>
                            <Grid item xs={6}>
                                <FormDataConsumer>
                                {
                                    ({ formData, ...rest }) => (                                        
                                        <ReferenceInput label="Tipo segmento" source="segmento" reference="segmentos" filter={{id: formData !== null ? formData.segmento : null}} {...rest} disabled>
                                            <SelectInput  optionText="tipoSegmento_.nombre" fullWidth />
                                        </ReferenceInput>
                                    )
                                }
                                </FormDataConsumer>                                
                            </Grid>
                            <Grid item xs={6}>                                
                                <FormDataConsumer>
                                {
                                    ({ formData, ...rest }) => (                                        
                                        <ReferenceInput label="Tipo superficie" source="segmento" reference="segmentos" filter={{id: formData !== null ? formData.segmento : null}} {...rest} disabled>
                                            <SelectInput  optionText="tipoSuperficie_.nombre" fullWidth />
                                        </ReferenceInput>
                                    )
                                }
                                </FormDataConsumer>
                            </Grid> 
                            <Grid item xs={6}>
                                <SelectInput source="costado" choices={[
                                        { id: true, name: 'Derecho' },
                                        { id: false, name: 'Izquierdo' },                                        
                                    ]}  fullWidth validate={[required()]}                       
                                />
                            </Grid>                            
                            <Grid item xs={4}>
                                <SelectInput source="estado" choices={[
                                        { id: 2, name: 'Existente' },
                                        { id: 1, name: 'Nuevo' },
                                        { id: 3, name: 'Reemplazo' },                                        
                                    ]}  fullWidth validate={[required()]}
                                />
                            </Grid>     
                            <Grid item xs={8}>
                                <FormDataConsumer>
                                {
                                    ({ formData, ...rest }) => (  formData !== null && formData.estado === 3 &&
                                        <ReferenceInput label="Razón" source="razonReemplazo" reference="listado_variables" filter={{listado: 'RAZON_REEM'}} {...rest} validate={[required()]}>
                                            <SelectInput  optionText="label" fullWidth />
                                        </ReferenceInput>
                                    )
                                }
                                </FormDataConsumer>
                            </Grid>   
                            <Grid item xs={12}>
                            <FormDataConsumer>
                                {
                                    ({ formData, ...rest }) => (  formData !== null && formData.estado === 3 &&
                                        <ReferenceInput label="Elemento que reemplaza" source="elementoReemplazo" reference="elemento_drenajes" filter={{tipoElemento: formData !== null ? formData.tipoElemento : null}} {...rest} validate={[required()]}>
                                            <AutocompleteInput optionText="fullCode" options={{fullWidth: true}}/>
                                        </ReferenceInput>
                                    )
                                }
                                </FormDataConsumer>
                            </Grid>                               
                        </Grid>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid container spacing={24}>                                          
                            <Grid item xs={12}>
                                <GMapInput
                                    defaultZoom={12}                                    
                                    defaultCenter={{ lat: 4.7567039, lng: -74.0311056 }}
                                    source="ubicaciones"
                                    multipleMarkers="true"
                                    googleKey="AIzaSyCivTXzJBYRBK8nosBYRFXnv-hGXIbhnYY"
                                />;   
                            </Grid>                            
                            <Grid item xs={12}>
                                <NumberInput label="Elevación" source="elevacion" fullWidth validate={[required()]}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={3}>
                        <FormDataConsumer>
                        {
                            ({ formData, ...rest }) => (  
                                <DateInput source="fechaInstalacion" fullWidth/>
                            )
                        }
                        </FormDataConsumer>
                        
                    </Grid>
                    <Grid item xs={6}>
                        <FormDataConsumer>
                        {
                            ({ formData, ...rest }) => ( 
                                <ReferenceInput label="Proveedor" source="proveedor" reference="proveedors" filter={{proyecto: props.proyecto, suministros: 1}}>
                                    <SelectInput  optionText="razonSocial" fullWidth />
                                </ReferenceInput>
                            )
                        }
                        </FormDataConsumer>          
                    </Grid>    
                    <Grid item xs={3}>
                        <FormDataConsumer>
                        {
                            ({ formData, ...rest }) => (  
                                <NumberInput label="Garantia" source="garantia" fullWidth/>
                            )
                        }
                        </FormDataConsumer> 
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput source="observaciones" fullWidth/>
                    </Grid>
                </Grid>                        
        </SimpleForm>
    </PimsCreate>
) ); 