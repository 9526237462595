import React from 'react';
import { TabbedShowLayout, Tab, TextField, DateField, SelectField } from 'react-admin';
import { Show } from './../Show';
import { BulkDeleteWithConfirmButton } from 'react-admin';
import { Fragment } from 'react';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';
import  {styles}  from './../../EstilosGrid';
import { translate } from 'react-admin';
const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props} />
    </Fragment>
);

const opciones = [
    { id: 0, name: 'dynatest.resources.atributos.select.acceso.noDefinido' },
    { id: null, name: 'dynatest.resources.atributos.select.acceso.noDefinido' },
    { id: 1, name: 'dynatest.resources.atributos.select.acceso.acceso_completo' },
    { id: 2, name: 'dynatest.resources.atributos.select.acceso.acceso_parcial' },
    { id: 3, name: 'dynatest.resources.atributos.select.acceso.sin_acceso'},
    { id: 4, name: 'dynatest.resources.atributos.select.acceso.visualiza' }
];

const AtributoTitulo =  translate(({ record, ...props }) => {
    const { translate } = props;
    return <span>{translate('dynatest.resources.atributos.title.showTitle')} {record ? `"${record.nombre}"` : ''}</span>;
});
export const AtributosShow = translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;
    return(
    <Show  {...props} title={<AtributoTitulo />} bulkActionButtons={<PostBulkActionButtons />}>
        <TabbedShowLayout>
            <Tab label="dynatest.resources.atributos.formTabs.administracion" submitOnEnter={false} >
                <TextField source="nombre" fullWidth />
                <TextField source="tipoRol" />
                <SelectField choices={opciones} source="usuario" />
                <SelectField choices={opciones} source="perfiles" />
                {/* <SelectField choices={opciones} source="asigclaves" /> */}
                <div></div>
                <div></div>
                <Typography variant="h3" component="h3" style={{ width: '300%' }}>
                     {translate('dynatest.resources.atributos.title.configGeneral')}
                </Typography>
                <div></div>
                <div></div>
                <Divider />
                <Divider />
                <Divider />
                <SelectField choices={opciones} source="idioma" />
                <SelectField choices={opciones} source="configSistema" label="resources.atributos.fields.configSistema" />
                <div></div>

                <Typography variant="h3" component="h3" style={{ width: '300%' }}>
                     {translate('dynatest.resources.atributos.title.configReports')}
                </Typography>
                <div></div>
                <div></div>
                <Divider />
                <Divider />
                <Divider />
                <SelectField choices={opciones} source="reportesConfig" />
                <div></div>
                <div></div>

                <Typography variant="h3" component="h3" style={{ width: '300%' }}>
                     {translate('dynatest.resources.atributos.title.configAudit')}
                </Typography>
                <div></div>
                <div></div>
                <Divider />
                <Divider />
                <Divider />
                <SelectField choices={opciones} source="audit" />
            </Tab>
            <Tab label="dynatest.resources.atributos.formTabs.proyecto" >
                <Typography variant="h3" component="h3" style={{ width: '300%' }}>
                     {translate('dynatest.resources.atributos.title.proyectos')}
                </Typography>
                <div></div>
                <div></div>
                <Divider />
                <Divider />
                <Divider />
                <SelectField choices={opciones} source="proyecto" />
                <SelectField choices={opciones} source="rutas" />
                <SelectField choices={opciones} source="unifuncionales" />
                <SelectField choices={opciones} source="tramos" />
                <SelectField choices={opciones} source="segmento" />

                <div></div>

                <Typography variant="h3" component="h3" style={{ width: '300%' }}>
                     {translate('dynatest.resources.atributos.title.configMateriales')}
                </Typography>
                <div></div>
                <div></div>
                <Divider />
                <Divider />
                <Divider />
                <SelectField choices={opciones} source="materiales" />
                <SelectField choices={opciones} source="ganulometria" />
                <div></div>

                <Typography variant="h3" component="h3" style={{ width: '300%' }}>
                {translate('dynatest.resources.atributos.title.configIndicadores')}
                </Typography>
                <div></div>
                <div></div>
                <Divider />
                <Divider />
                <Divider />
                <SelectField choices={opciones} source="parametrosEvaluacion" />
                <SelectField choices={opciones} source="diagramaColorimetrico" />
                <SelectField choices={opciones} source="indicadorComplementario" />
                
                <Typography variant="h3" component="h3" style={{ width: '300%' }}>
                {translate('dynatest.resources.atributos.title.configNovedades')}
                </Typography>
                <div></div>
                <div></div>
                <Divider />
                <Divider />
                <Divider />
                <SelectField choices={opciones} source="registroNovedad" label="resources.atributos.fields.novedades" />

                <div></div>
                <div></div>

                <Typography variant="h3" component="h3" style={{ width: '300%' }}>
                {translate('dynatest.resources.atributos.title.configDeterioroPers')}
                </Typography>
                <div></div>
                <div></div>
                <Divider />
                <Divider />
                <Divider />
                <SelectField choices={opciones} source="reparaciones" />
                <SelectField choices={opciones} source="deterioros" />
                <div></div>
                
                <Typography variant="h3" component="h3" style={{ width: '300%' }}>
                {translate('dynatest.resources.atributos.title.contactos')}
                </Typography>

                <div></div><div></div>
                <Divider />
                <Divider />
                <Divider />

                <SelectField choices={opciones} source="contemergencia" />
                <SelectField choices={opciones} source="proveedores" />
                <SelectField choices={opciones} source="serpublicos" />
            </Tab>
            <Tab label="dynatest.resources.atributos.formTabs.inventario">

                <SelectField source="mapa"  choices={opciones} />

                <br/>
                <br/>

                <Typography variant="h3" component="h3" style={{ width: '300%' }}>
                    {translate('dynatest.resources.atributos.title.cargaMasiva')}
                </Typography>
                <div></div>
                <div></div>
                <Divider /><Divider /><Divider />

                <SelectField choices={opciones} source="cargaMasiva" />
                <div></div>
                <div></div>

                <Typography variant="h3" component="h3" style={{ width: '300%' }}>
                {translate('dynatest.resources.atributos.title.segmentos')}
                </Typography>
                <div></div><div></div>
                <Divider /> <Divider /> <Divider />
                <SelectField source="flexible" choices={opciones} />
                <SelectField source="rigido" choices={opciones} />
                <SelectField source="adoquin" choices={opciones} />
                <SelectField source="otro" choices={opciones} />
                <div></div>           <div></div>
                <div></div>
                <Typography variant="h3" component="h3" style={{ width: '300%' }}>
                {translate('dynatest.resources.atributos.title.elemInventario')}
                </Typography>
                <div></div>
                <Divider />
                <Divider />
                <Divider />

                <SelectField choices={opciones} source="seguridad" />
                <SelectField choices={opciones} source="drenaje" />
                <SelectField choices={opciones} source="senalizacion" />
                <SelectField choices={opciones} source="alrededores" />
                <SelectField choices={opciones} source="otroElemento" />
                <SelectField choices={opciones} source="redes" />

                <Typography variant="h3" component="h3" style={{ width: '300%' }}>
                {translate('dynatest.resources.atributos.title.puentesEstructurasEspec')}
                </Typography>
                <div></div>
                <div></div>
                <Divider />
                <Divider />
                <Divider />
                <SelectField choices={opciones} source="puentes" />
                <SelectField choices={opciones} source="contencion" />
                <SelectField choices={opciones} source="ponton" />
                <SelectField choices={opciones} source="tunel" />
                <div></div>
                <di></di>
            </Tab>
            <Tab label="dynatest.resources.atributos.formTabs.transito">

                <SelectField choices={opciones} source="inventario" />
                <SelectField choices={opciones} source="transitoMensual" />
                <SelectField choices={opciones} source="inventarioa" />

            </Tab>
            <Tab label="dynatest.resources.atributos.formTabs.indicadores">
                <SelectField choices={opciones} source="indicadores4g" />
                {/* <SelectField choices={opciones} source="datosBasicos" />
                <SelectField choices={opciones} source="colaDePeaje" />
                <SelectField choices={opciones} source="calificacionPuentes" />
                <SelectField choices={opciones} source="calificacionPontones" /> */}
                <SelectField choices={opciones} source="respuestaEvaluacionComplementario" label='dynatest.resources.atributos.title.complementarios' />
                <SelectField choices={opciones} source="disponibilidadVia" />
                {/* <SelectField choices={opciones} source="colaDePeajea" /> */}

            </Tab>
            <Tab label="dynatest.resources.atributos.formTabs.gestion">
                <b>{translate('dynatest.resources.atributos.title.programacion')}</b>
                <div></div>
                <div></div>
                <Divider /><Divider /><Divider />

                <SelectField choices={opciones} source="parametrosAnalisis" />
                <div></div>
                <div></div>

                <b>{translate('dynatest.resources.atributos.title.otrosSistemas')}</b>
                <div></div>
                <div></div>
                <Divider /><Divider /><Divider />
                <SelectField choices={opciones} source="politicaGeneral" />
                <SelectField choices={opciones} source="politicaParticular" />
                
                <div></div>
                <b>{translate('dynatest.resources.atributos.title.pavimentoPers')}</b>
                <div></div>
                <div></div>
                <Divider /><Divider /><Divider />

                <SelectField choices={opciones} source="alternativaIntervencion" />
                <SelectField choices={opciones} source="reparacionesMenores" />
                <SelectField choices={opciones} source="exportarPers" />
                <SelectField choices={opciones} source="reportesPers" />
                
                <div></div>
                <div></div>
                <b>{translate('dynatest.resources.atributos.title.registroCostos')}</b>
                <div></div>
                <div></div>
                <Divider /><Divider /><Divider />

                <SelectField choices={opciones} source="costo" label='resources.atributos.fields.costo' />

                <div></div>
                <div></div>
                <b>{translate('dynatest.resources.atributos.title.flujoCaja')}</b>
                <div></div>
                <div></div>
                <Divider /><Divider /><Divider />

                <SelectField choices={opciones} source="flujoCaja" />
                
            </Tab>

            <Tab label="dynatest.resources.atributos.formTabs.aplicacionMovil">
                <SelectField choices={opciones} source="respuestaEvaluacionNovedad" label='dynatest.resources.atributos.title.respuestaEvaluacionNovedad' />
                <SelectField choices={opciones} source="aprobacion" label='dynatest.resources.atributos.title.aprobacion' />

            </Tab>

            <Tab label="dynatest.resources.atributos.formTabs.reportesQS">
                <SelectField source="reportesPersQ"  choices={opciones} />
                <SelectField source="reportesInventarioQ"  choices={opciones} />
                <SelectField source="reportesIndicadoresQ"  choices={opciones} />
                <SelectField source="reportesCostosQ"  choices={opciones} />
                <SelectField source="reportesTransitoQ"  choices={opciones} />
                <SelectField source="reportesContactosQ"  choices={opciones} />
                <SelectField source="reportesModelacionQ"  choices={opciones} />
            </Tab>

            <Tab label="dynatest.resources.atributos.formTabs.auditoria">
                <DateField source="createdAt" showTime />
                <TextField source="createdBy"  />
                <div></div>
                <DateField source="updatedAt" showTime />
                <TextField source="updatedBy"  />
                <div></div>
            </Tab>
        </TabbedShowLayout>
    </Show>
)
    }));