import React, {useState} from 'react';
import { WithPermissions,ListButton, ReferenceField,EditButton, ShowController,SelectField,Query,TextInput,
         BooleanField , TextField, DateField, CardActions, TabbedForm, FormTab, ShowView} from 'react-admin';
import { PField } from './../../fields/PField';
import { PRField } from './../../fields/PRField';
import { NumberField }  from 'react-admin';
import Divider from '@material-ui/core/Divider';
import  {styles}  from './../../EstilosGrid';
import { withStyles } from '@material-ui/core';
import { translate } from 'react-admin';
import { GMapInput } from '../../inputs/GMapInput';
import Typography from '@material-ui/core/Typography'; 
import {ShowToolbar} from './../componentes/ShowToolbar';
const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

const ElementoTitulo = translate(({ record, ...props }) => {
    const { translate } = props;
    if(record.tipoElemento === 'R'){
        return <span>{translate('dynatest.resources.segmentos.title.showTitleRamal')}</span>;
    }else{
        return <span>{translate('dynatest.resources.segmentos.title.showTitleSegmento')}</span>;
    }    
  });

  



export const SegmentoShow = translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;

    const Segmento = ({ record }) => {  
        return(
        <Query type="GET_ONE" resource="segmentos" payload={{ id: record.segmentoId}} > 
            {({ data, loading, error }) => {                                                
                if( data){               
                    return (   
                        <Typography variant="P"  component="b">{data.municipio}</Typography>                                                                                               
                        // <Query type="GET_ONE" resource="segmentos" payload={{ id: record.segmentoId}} > 
                        //     {({ data, loading, error }) => {                                                
                        //         if( data){               
                        //             return (                                                                                                
                        //                 <Typography variant="b" label="EEEROOO" component="b">{data.municipio}</Typography>                              
                        //             )
                        //         }
                        //     if(loading){return <div></div>}      
                        //     if(error){return <div></div>}                                            
                        //     }}
                        // </Query>                           
                    )
                }
            if(loading){return <div></div>}      
            if(error){return <div></div>}                                            
            }}
        </Query> 
        )
      };

      const [imagen , setImagen] =useState(true);
      const ValidarImagen =  (value) =>{
          // console.log(value + "Aqui est la imagen ")
          if(value !== null && value !== undefined && value !== 'undefined')
          {
              let lenghtObject = Object.keys(value);
              let valor = '';        
              for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                
              setImagen(value) 
              // console.log(value + "Aqui est la imagen ")
          }
      }

    return(
    <ShowController  {...props} >
         {controllerProps =>
            <ShowView {...props} {...controllerProps} title={<ElementoTitulo/> } actions={<ShowToolbar />} >
                <TabbedForm toolbar={false}>
                    <FormTab label="dynatest.resources.segmentos.tabs.informacionBasica">     
                    <TextInput source="tipoElementoSrc" validate={ValidarImagen} style={{display : 'none'}}/>                                
                        <SelectField source="tipoElemento" choices={[
                            { id: 'R', name: 'dynatest.resources.segmentos.select.ramal' },
                            { id: 'SGM', name: 'dynatest.resources.segmentos.select.segmento' },
                        ]} formClassName={classes.grid_cont4s} />  
                        <TextField label="resources.segmentos.fields.codigo" source="fullCodeInventariosSeg"  formClassName={classes.grid_cont4s} />                                               
                         <div></div>
                         
                        {controllerProps.record && controllerProps.record.tipoElemento  === "R" &&
                            <ReferenceField source="segmentoId" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont4s}>
                                <TextField source="codigo" />       
                            </ReferenceField>                         
                        }
                        {controllerProps.record && controllerProps.record.tipoElemento  === "R" &&
                            <div></div>                        
                        }
                        {controllerProps.record && controllerProps.record.tipoElemento  === "R" &&
                            <TextField source="identificacionRamal"  formClassName={classes.grid_cont4s}/>                     
                        }  
                        {controllerProps.record && controllerProps.record.tipoElemento  === "R" &&
                            <PRField source="absSgm" fullWidth formClassName={classes.grid_cont4}/>                          
                        }  
                        {controllerProps.record && controllerProps.record.tipoElemento  === "R" &&
                            <TextField source="lado" fullWidth formClassName={classes.grid_cont4}/>                          
                        }     
                        {controllerProps.record && controllerProps.record.tipoElemento  !== "R" &&
                            <div></div>                         
                        }  
                         {controllerProps.record && controllerProps.record.tipoElemento  === "R" &&
                            <div></div>                         
                        }                 
                        <PRField source="abInicio" fullWidth formClassName={classes.grid_cont4s}/>
                        <PRField source="abFin" fullWidth formClassName={classes.grid_cont4}/>
                        <NumberField source="fullResta"  fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4}/>
                        <PField source="pInicio" fullWidth formClassName={classes.grid_cont4s}/>
                        <PField source="pFin" fullWidth formClassName={classes.grid_cont4}/>                        
                        
                        <b>{translate('dynatest.resources.segmentos.title.localizacion')}</b>
                        <Divider fullWidth  style={{marginTop :'10px'}} />
                        {controllerProps.record && controllerProps.record.tipoElemento  === "R" &&
                            <ReferenceField source="segmentoId" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont4s}>
                                <TextField source="departamentos" />       
                            </ReferenceField>                         
                        }
                        {controllerProps.record && controllerProps.record.tipoElemento  === "R" &&
                            <ReferenceField source="segmentoId" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <TextField source="municipios" />       
                            </ReferenceField>                         
                        }      

                        {controllerProps.record && controllerProps.record.tipoElemento  !== "R" &&
                            <TextField label="resources.segmentos.fields.departamento" source="departamentos" fullWidth formClassName={classes.grid_cont4}/>                    
                        }
                        {controllerProps.record && controllerProps.record.tipoElemento  !== "R" &&
                            <ReferenceField source="municipio" reference="municipios" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <TextField source="nombre" />       
                            </ReferenceField>                      
                        }                        
                                                                          
                        <Typography variant="b" component="b">{translate('dynatest.resources.segmentos.title.coorIniciales')}</Typography>
                        <Divider fullWidth  style={{marginTop :'10px'}} />
                        <NumberField label="resources.segmentos.fields.latitud" source="latitud" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4s}/>
                        <NumberField label="resources.segmentos.fields.longitud" source="longitud" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4}/>
                        <NumberField  label="resources.segmentos.fields.altura" source="alturaIni" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/>
                        <Typography variant="b" component="b">Coordenadas Finales</Typography>
                        <Divider fullWidth  style={{marginTop :'10px'}} />
                        <NumberField label="resources.segmentos.fields.latitud" source="latitud2" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4s}/>
                        <NumberField label="resources.segmentos.fields.longitud" source="longitud2" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4}/>
                        <NumberField label="resources.segmentos.fields.altura" source="alturaFin" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/>
                        
                        <b>{translate('dynatest.resources.segmentos.title.caracteristicas')}</b>

                        <Divider fullWidth />
                        <ReferenceField source="tipoCalzada" reference="tipo_calzadas" linkType={false}  formClassName={classes.grid_cont4s}>
                            <TextField source="nombre" />
                        </ReferenceField>

                    <ReferenceField source="tipoSegmento" reference="tipo_segmentos" linkType={false}  formClassName={classes.grid_cont4}>
                            <TextField source="nombre" />
                        </ReferenceField>

                        <ReferenceField source="tipoSuperficie" reference="tipo_elementos" linkType={false}  formClassName={classes.grid_cont3}>
                            <TextField source="nombre" />
                        </ReferenceField>

                        <BooleanField  source="estado" fullWidth formClassName={classes.grid_cont4}/> 
                        <span></span>
                        <GMapInput
                            defaultZoom={8}                                    
                            defaultCenter={{ lat: 4.7567039, lng: -74.0311056 }}
                            source="ubicacionMapa"
                            multipleMarkers="true"
                            googleKey="AIzaSyCivTXzJBYRBK8nosBYRFXnv-hGXIbhnYY"    
                            icon={imagen}                
                        />

                    </FormTab>
                    <FormTab label="dynatest.resources.segmentos.tabs.auditoria">
                    <DateField source="createdAt" fullWidth formClassName={classes.grid_cont4} showTime/> 
                        <TextField source="createdBy" fullWidth formClassName={classes.grid_cont4}/>
                        <span></span>               
                        <DateField source="updatedAt" fullWidth formClassName={classes.grid_cont4} showTime/>
                        <TextField source="updatedBy" fullWidth formClassName={classes.grid_cont4}/>
                        <span></span>    
                    </FormTab>
                </TabbedForm>
        </ShowView>
        }
    </ShowController>
    
)}));