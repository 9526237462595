import React,{ useState } from 'react';
import { required,minLength,maxLength,CardActions,ListButton,minValue,maxValue,NumberInput,TabbedForm,FormTab,ShowButton,  ReferenceInput, SelectInput, } from 'react-admin';
import { Edit } from './../../Edit';
import ViewHistorico from './ViewHistorico/historico';
import Grid from '@material-ui/core/Grid';
import { styles } from './../../../EstilosGrid';
import {
    Toolbar,
    SaveButton,

   
} from 'react-admin';
import { withStyles } from '@material-ui/core';
import config from '../../../config/config';
import { translate } from 'react-admin';
let urlAppjs = config.UrlServer;
export const PeajesmensualEdit = translate(withStyles(styles)(({ classes, ...props }) => {
  const { translate } = props;
const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};
const [peajes, setPeaje] = useState('');
  const ValidarPeaje = (value) => {

    //let lenghtObject = Object.keys(value);
   // let valor = '';

    //for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setPeaje(value)

  }
  const [sentidos, setSentido] = useState('');
  const ValidarSentido = (value) => {

    //let lenghtObject = Object.keys(value);
    //let valor = '';

    //for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    //console.log(valor.toLowerCase());
    setSentido(value)

  }
  const [años, setAño] = useState('');
  const validarAño = (value) => {

    //let lenghtObject = Object.keys(value);
    //let valor = '';

    //for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setAño(value)

  }
const TramoTitle = ({ record }) => {
    return <span>Tramo {record ? `${record.fullCode}` : ''}</span>;
};

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <SaveButton />
        
    </Toolbar>
));
const filtro = [];
const cardActionStyle = {
  zIndex: 2,
  display: 'inline-block',
  float: 'right',
};
const PostActions = ({basePath,data}) =>(
<CardActions style={cardActionStyle}>
        {/* Add your custom actions */}
        <ListButton basePath={basePath} />
        <ShowButton basePath={basePath} label="dynatest.resources.mensuals.tabs.tabsTitle.historico" record={data} />
    </CardActions> 
);

const validarFormulario= (values) =>{
  return fetch(urlAppjs + "/mensuals.json?inventario_peajes.unidadFuncional.ruta.proyecto="+localStorage.getItem('id_project')+"&pagination=false&inventario_peajes="+values.inventario_peajes+"&sentido="+values.sentido+"&ano="+values.ano+"&mes="+values.mes)
  .then((response) => {
    return response.json()
  }).then((response) => {
    let idact=values["@id"];
    console.log(response)
    if(response.length>0 && response.length<2){
      let idres=response[0].id
      if(idact!="/mensuals/"+idres){
        throw { mes: 'Registro ya Realizado' }
      }
    }else if(response.length>=2){
      throw { mes: 'Registro ya Realizado' }
    }
  });
}
        return(
    <Edit title="dynatest.resources.mensuals.title.editTitle" {...props}  actions={<PostActions/>} toolbar={<CustomToolbar />} undoable={false}>
       <TabbedForm  redirect="list" asyncValidate={validarFormulario}>
            <FormTab label="dynatest.resources.mensuals.tabs.tabsTitle.identificacion">
                <Grid container spacing={24}>
                  <Grid item xs={4}>
                  <ReferenceInput label="resources.mensuals.fields.inventario_peajes" disabled source="inventario_peajes" id="peajes_id" reference="inventariopeajes" filter={{ Peajes: peajes }} fullWidth validate={[required(), ValidarPeaje]}>                
                        <SelectInput optionText="nombre" />                
                      </ReferenceInput>
                  </Grid>  
                  {peajes &&
                   <Grid item xs={4}>
                    <SelectInput label="resources.mensuals.fields.sentido" disabled source="sentido"  choices={[
                          { id: translate('dynatest.resources.mensuals.select.sentido.Ascendente'), name: 'dynatest.resources.mensuals.select.sentido.Ascendente' },
                          { id: translate('dynatest.resources.mensuals.select.sentido.Descendente'), name: 'dynatest.resources.mensuals.select.sentido.Descendente' } 
                      ]} fullWidth validate={[required(),ValidarSentido]} />
                  </Grid>
}   
                  <Grid item xs={4}>
                  <span></span>
                  </Grid>
                  <Grid item xs={4}>
                  <SelectInput label="resources.mensuals.fields.ano" source="ano"  choices={[
                                    { id: '2000', name: '2000' },
                                    { id: '2001', name: '2001' },
                                    { id: '2002', name: '2002' },
                                    { id: '2003', name: '2003' },
                                    { id: '2004', name: '2004' },
                                    { id: '2005', name: '2005' },
                                    { id: '2006', name: '2006' },
                                    { id: '2007', name: '2007' },
                                    { id: '2008', name: '2008' },
                                    { id: '2009', name: '2009' },
                                    { id: '2010', name: '2010' },
                                    { id: '2011', name: '2011' },
                                    { id: '2012', name: '2012' },
                                    { id: '2013', name: '2013' },
                                    { id: '2014', name: '2014' },
                                    { id: '2015', name: '2015' },
                                    { id: '2016', name: '2016' },
                                    { id: '2017', name: '2017' },
                                    { id: '2018', name: '2018' },
                                    { id: '2019', name: '2019' },
                                    { id: '2020', name: '2020' },
                                    { id: '2021', name: '2021' },
                                    { id: '2022', name: '2022' },
                                    { id: '2023', name: '2023' },
                                    { id: '2024', name: '2024' },
                                    { id: '2025', name: '2025' },
                                    { id: '2026', name: '2026' },
                                    { id: '2027', name: '2027' },
                                    { id: '2028', name: '2028' },
                                    { id: '2029', name: '2029' },
                                    { id: '2030', name: '2030' }
                                ]} fullWidth validate={[required(),validarAño]}/>
                  </Grid>
                  {años &&       
                  <Grid item xs={4}>
                    <SelectInput
                        label="resources.mensuals.fields.mes"
                        source="mes"
                        choices={[
                            { id: '1', name: 'dynatest.resources.mensuals.select.mes.Enero' },
                            { id: '2', name: 'dynatest.resources.mensuals.select.mes.Febrero' },
                            { id: '3', name: 'dynatest.resources.mensuals.select.mes.Marzo' },
                            { id: '4', name: 'dynatest.resources.mensuals.select.mes.Abril' },
                            { id: '5', name: 'dynatest.resources.mensuals.select.mes.Mayo' },
                            { id: '6', name: 'dynatest.resources.mensuals.select.mes.Junio' },
                            { id: '7', name: 'dynatest.resources.mensuals.select.mes.Julio' },
                            { id: '8', name: 'dynatest.resources.mensuals.select.mes.Agosto' },
                            { id: '9', name: 'dynatest.resources.mensuals.select.mes.Septiembre' },
                            { id: '91', name: 'dynatest.resources.mensuals.select.mes.Octubre' },
                            { id: '92', name: 'dynatest.resources.mensuals.select.mes.Noviembre' },
                            { id: '93', name: 'dynatest.resources.mensuals.select.mes.Diciembre' }                                    
                        ]} fullWidth validate={required()} />      
                  </Grid>       
}
                <Grid item xs={12}>
                <span></span>     
                <span></span>   
                <b>{translate('dynatest.resources.mensuals.subTitle.VolVehicCategoria')}</b>
                <hr></hr>
                <span></span>
                <span></span>
                </Grid>
                </Grid>
                <Grid item xs={4}>
                  <NumberInput label="resources.mensuals.fields.categoria_a" source="categoria_a" fullWidth validate={[required(), minValue(0),maxValue(1000000)]}/>
                  </Grid>  
                  <Grid item xs={4}>
                  <NumberInput label="resources.mensuals.fields.categoria_b" source="categoria_b" fullWidth validate={[required(), minValue(0),maxValue(1000000)]}/>
                  </Grid>  
                  <Grid item xs={4}>
                  <NumberInput label="resources.mensuals.fields.categoria_c" source="categoria_c" fullWidth validate={[required(), minValue(0),maxValue(1000000)]}/>
                  </Grid>  
                  <Grid item xs={4}>
                  <NumberInput label="resources.mensuals.fields.categoria_d" source="categoria_d" fullWidth validate={[required(), minValue(0),maxValue(1000000)]}/>
                  </Grid>  
                  <Grid item xs={4}>
                  <NumberInput label="resources.mensuals.fields.categoria_e" source="categoria_e" fullWidth validate={[required(),  minValue(0),maxValue(1000000)]}/>
                  </Grid>  
                  
                  
            </FormTab>
            <FormTab label="dynatest.resources.mensuals.tabs.tabsTitle.historico">
            <WithProps formClassName={classes.grid_cont_div}>{({record,...props})=>
                <ViewHistorico  sentido={sentidos} peaje={peajes} ano={años} tipo="tipo" filtro={filtro}/> }
                </WithProps>
            </FormTab>
            
      </TabbedForm>
    </Edit>
)
}));
const WithProps = ({children,...props}) => children(props);