import React from 'react';
import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_CHECK, AUTH_GET_PERMISSIONS } from 'react-admin';
import config from './config/config';
let urlAppjs = config.UrlServer;
//licenciamiento
let urlAppjs2 = config.UrlServer2;
let urlFront = config.UrlFront;


class PimsAuthProvider extends React.Component{
    state = {
        url: null,
        //user: ''
    };
    constructor(props){
        super(props);
        this.state.url      = props.url;
        this.handleEvent    = this.handleEvent.bind(this);
        this.login          = this.login.bind(this);
        this.logout         = this.logout.bind(this);
        this.error          = this.error.bind(this);
        this.check          = this.check.bind(this);
        this.getPermissions = this.getPermissions.bind(this);

    }
    handleEvent(type, params){
        switch (type) {
            case AUTH_LOGIN: return this.login(params);
            case AUTH_LOGOUT: return this.logout(params);
            case AUTH_ERROR: return this.error(params);
            case AUTH_CHECK: return this.check(params);
            case AUTH_GET_PERMISSIONS: return this.getPermissions(params);
            default: return Promise.reject();
        }

    }
    async login(params){
        
        document.getElementById('btn-login').style.display = 'none';
        document.getElementById('loader-btn-login').style.display = 'block';

        const { username, password } = params;
        const request = new Request(
            this.state.url+'/authentication_token', {
                    method: 'POST',
                    body: JSON.stringify({ username, password }),
                    headers: new Headers({ 'Content-Type': 'application/json' }),
                }
                
            );
        return await fetch(request)
            .then(response => {
                    if (response.status < 200 || response.status >= 300) throw new Error(response.statusText);
                    return response.json();
                }
            )
            .then(async({ token, user }) => {
                let idLicencia = "";

                let today = new Date();
                let month = "";
                let day   = "";

                if ((today.getMonth() + 1) < 10) {
                    month = "0" + (today.getMonth() + 1)
                } else {
                    month = (today.getMonth() + 1)
                }

                if (today.getDate() < 10) {
                    day = "0" + (today.getDate())
                } else {
                    day = (today.getDate())
                }

                today = today.getFullYear() + "-" + month + "-" + day;

                //validacion ID DE LICENCIA
                await fetch(urlAppjs+'/consultarLicencia/')
                .then(function(response) {
                    return response.json();
                })
                .then(async function(myJson) {
                    // console.log(myJson)
                    // console.log(myJson[0].codigo)
                    idLicencia = myJson[0].codigo;
                    //validacion de licencia
                    await fetch(urlAppjs2+'/ValidarLicencia/'+idLicencia+'/'+today)
                    // fetch(urlAppjs2+'/ValidarLicencia/'+idLicencia+'/2023-01-10')
                    .then(function(response) {
                        return response.json();
                    })
                    .then(function(myJson) {
                        if(myJson.length !=  0){         
                            console.log(myJson)  
                            const permisos = JSON.parse(user);  
                            const user1 = permisos['admin'];
                            const user3 = permisos['username'];
                            localStorage.setItem('permissions', JSON.stringify(user1));                 
                            localStorage.setItem('ucalpro',user3);
                            localStorage.removeItem('msj-login-error');
                            localStorage.removeItem('msj-login-error-msj');
                            localStorage.setItem('token', token);
                            localStorage.setItem('idioma', permisos['idioma']);
                            return Promise.resolve(user);
                        }else{
                            localStorage.setItem('msj-login-error',true);
                            localStorage.setItem('msj-login-error-msj','Licencia NO valida, por favor verificar');  
                        }
                    }).catch((error) => {
                        console.log(error)
                    }); 
                }).catch((error) => {
                    console.log(error)
                });  
            }        
            ).catch(function(error) {
                
                localStorage.setItem('msj-login-error',true);
                console.log(error)
                // MENSAJES DE ERROR
                if(error.message === 'Unauthorized'){
                    localStorage.setItem('msj-login-error-msj','usuario y/o contraseña incorrecta');                                          
                }

                if(error.message === 'Internal Server Error'){
                    localStorage.setItem('msj-login-error-msj','Hubo un problema con la petición, por favor intente nuevamente');                                          
                }
                
                if(error.message === 'Failed to fetch'){
                    localStorage.setItem('msj-login-error-msj','Hubo un problema con la petición, por favor intente nuevamente');                                          
                }

                
                document.getElementById('loader-btn-login').style.display = 'none';
                document.getElementById('btn-login').style.display = 'block';
                document.getElementById('content-msj').style.display = 'block';                                
                
            });      
    }
    logout(params){

        localStorage.removeItem('token');
        localStorage.removeItem('proyectoId');
        localStorage.removeItem('id_project');
        localStorage.removeItem('id_project2');
        localStorage.removeItem('ucalpro');
        localStorage.removeItem('visibility');
        localStorage.removeItem('permissions'); 
        localStorage.removeItem('codigoProyecto'); 
        localStorage.removeItem('nombreProyecto'); 
        localStorage.removeItem('idioma');
        return Promise.resolve();
    }
    error(params){
        console.log("error en AuthProvider", params)
        console.log("hasResponseCode? ", params.hasOwnProperty("response"))

        if(params.hasOwnProperty("response")){
            const status  = params.response.status;
            if (status === 401 || status === 403) {
                let actualDir = window.location.href.split("#")[1]
                if(actualDir != "/login") {
                    this.logout(params)
                    window.location.reload(true);
                }
            }
        }
    }

    check(params){        

        return localStorage.getItem('token') ? Promise.resolve() : Promise.reject();
        
    }
    getPermissions(params){
        let role = localStorage.getItem('permissions');
        let array = JSON.parse(role);
        return array ? Promise.resolve(array) : Promise.reject();
    }

}

export default PimsAuthProvider;

