import React, { useState, Fragment } from 'react';
import {REDUX_FORM_NAME} from 'react-admin';
import {change} from 'redux-form';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
    FormDataConsumer, AutocompleteInput, required, SelectInput
} from 'react-admin';
import { styles } from './../../EstilosGrid';
import Grid from '@material-ui/core/Grid';
import config from '../../config/config';
import { translate } from 'react-admin';
let urlAppjs = config.UrlServer;

/**
 * @param tipoElemento = la entidad a apuntar en el fetcher
 * @param abInicio = la abscisa inicial para el filtro
 * @param abFin = la abscisa final para el filtro
 * 
 */

class AsociarServicioProveedor extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            datos : {
                sistemas: [],
                proveedors: [],
            }
        }
        
        this.fetcher.bind(this);

    }

    componentDidMount() {

        this.fetcher("sistemas", "order[nombre]=ASC");
        this.fetcher("proveedors", "order[razonSocial]=ASC")
        
    }
    componentDidUpdate(){
        
    }

    fetcher(target, otherFilters = null) {
        var urlPart = "/" + target + "?pagination=off";
        if(Array.isArray(otherFilters)) {
            otherFilters.map(filter => {
                urlPart = urlPart + "&" + filter;
            })
        } else if(otherFilters !== null) {
            urlPart = urlPart + "&" + otherFilters;
        }
        
        urlPart += "&proyecto=" + localStorage.getItem('id_project')
        fetch(urlAppjs + urlPart,
            {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                return response.json()
            })
            .then((Elementos) => {
                var datos = [];
                Elementos["hydra:member"].map(item => {
                    if (target == "sistemas") {
                        datos.push({
                            id : item["@id"],
                            name : item.nombre
                        })
                    } else {
                        datos.push({
                            id : item["@id"],
                            name : item.razonSocial
                        })
                    }
                })
                // console.log(datos);
                this.state.datos[target] = datos;
            }).then(()=> {
                this.forceUpdate();
            })
    }
    

    render() {
        const { translate } = this.props;
        const { classes } = this.props;
        const { getSource } = this.props;
        const changedProveedor = (value) => {
            let lenghtObject = Object.keys(value);
            let valor = '';        
            for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; } 
            // console.log(valor);
            this.fetcher("proveedors", [
                "order[razonSocial]=ASC",
                "sistema=" + valor
            ])
        }
        return(
            <FormDataConsumer >
                {({formData, scopedFormData, dispatch, ...rest}) => (
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                        <SelectInput label="resources.disponibilidad_vias.fields.sistema" source={getSource('serviciosSistemaProveedores')} choices={this.state.datos.sistemas} 
                            validate={[required()]} formClassName={classes.grid_cont3}
                            options={{fullWidth : true}}
                            onChange={value => dispatch(
                                change(REDUX_FORM_NAME, getSource('serviciosElementoProveedores'), null)
                            ), changedProveedor}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <SelectInput 
                                label="resources.disponibilidad_vias.fields.servElemento"
                                source={getSource('serviciosElementoProveedores')}
                                options={{fullWidth : true}}
                                choices={this.state.datos.proveedors}
                                validate={required()}
                                formClassName={classes.grid_cont3}
                            />
                        </Grid>
                    </Grid>
                )}
                </FormDataConsumer>
        )
    }
}

AsociarServicioProveedor.propTypes = {
    classes: PropTypes.object.isRequired,
}
const AsociarServicioProveedors = withStyles(styles)(AsociarServicioProveedor);


export default translate(AsociarServicioProveedors);