import React, { useState } from 'react';
import {
    NumberInput, ReferenceInput, SelectInput, FormDataConsumer, ArrayInput, SimpleFormIterator, DateInput,
    TextInput, BooleanInput, FormTab, maxValue, minValue, required, AutocompleteInput
} from 'react-admin';
import { PimsElementoActions } from './../PimsElementoActions';
import {  validarDecimales4, validarDecimales, validarDecimales3, validarDecimales2, validarDosDecimales, isFechaInvalida
} from './../../../helpers/validacionesInputs';
import { styles } from './../../../EstilosGrid';
import { PimsCreate } from './../../Create';
import { PRInput } from '../../../inputs/PRInput';
import { PInput } from '../../../inputs/PInput';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { maxLength } from 'ra-core';
import { TabbedForm } from './../../TabbedForm';
import ElementosCreados from './../../ElementosCreados';
import CoordenadasProyecto from '../../CoordenadasInventario';
import AbsSegmento from '../../AbsSegmento';
import { abValidationInicio, prValidationInicio, latMininima, lngMininima, validacionmaxfechaact,
abValidationFin, validarLogicaAbsFinCreate, prValidationFin } from '../../validacionesInputs';
import config from '../../../config/config';
import { ImagenesInventario } from '../../images/ImagenesInventario';
import { translate } from 'react-admin';
let urlAppjs = config.UrlServer;

const tipoElemento = { sistema: 'RDS' };
 
export const ElementoRedCreate =  translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;
    const [elemento, setElemento] = useState('-');
    const [unidads, setUnidad] = useState('');
    const ValidarUnidad = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setUnidad(value)
    }
    const [tramos, setTramo] = useState('');
    const ValidarTramo = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setTramo(value)
    }

    const garantia = (value) => {
        if (!/^([0-9]+)*$/.test(value)) {
            return translate('resources.validaciones.entero');
        }
    }
    const optionRenderer = choice => `${choice.name}`;
    const Unidades = { unidadFuncional: unidads };
    const Tramoss = { tramo: tramos };
    /* VALIDAR LOS RANGOS QUE PUEDEN TENER LAS LATITUDES Y LONGITUDES */
    const [latMin, setLatMin] = useState('');
    const [lngMin, setLngMin] = useState('');
    const [latMax, setLatMax] = useState('');
    const [lngMax, setLngMax] = useState('');
    const [segmentos, setSegmento] = useState('');
    const ValidarSegmento = (value) => {
        setSegmento(value)
    }
    /* FIN DE VALIDACIÓN DE LOS RANGOS QUE PUEDEN TENER LAS LATITUDES Y LONGITUDES */

     /*VALORES POR DEFECTO DEL ARRAY DE COORDENADAS*/
    const [latitudMinimaArray, setLatitudArray] = useState('');
    const LatitudMinimaArray = (value) => {
        setLatitudArray(value)
    }
    const [longitudMinimaArray, setLongitudArray] = useState('');
    const LongitudMinimaArray = (value) => {
        setLongitudArray(value)
    }
    const [longitudMaximaArray, setLongitudMaxdArray] = useState('');
    const LongitudMaximaArray = (value) => {
        setLongitudMaxdArray(value)
    }
    const [latitudMaximaArray, setLatitudMaxdArray] = useState('');
    const LatitudMaximaArray = (value) => {
        setLatitudMaxdArray(value)
    }
    /*FIN DE LOS VALORES POR DEFECTO DEL ARRAY DE COORDENADAS*/
    const [estados, setEstado] = useState('');
    const ValidarEstado = (value) => {

        let lenghtObject = Object.keys(value);
        let valor = '';

        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setEstado(valor)
    }
    const ValidarElemento = (value) => {

        let lenghtObject = Object.keys(value);
        let valor = '';

        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setElemento(value)
    }

/*OPCIONES PARA TIPO DE IMÁGENES*/
   const [defaultTipoImagen,setDefaultTipoImagen] = useState([{id: 'Cargando...' ,name :'Cargando...'}]);
   const OpcionTipoImagen = (value) =>{    
        setDefaultTipoImagen([
            {
                tipoImagen : '/tipo_images/1',
            },
            {
                tipoImagen : '/tipo_images/7',
            },
            {
                tipoImagen : '/tipo_images/8',
            },
            {
                tipoImagen : '/tipo_images/9',
            },
        ])
   }

    return (
        <PimsCreate  {...props} title="dynatest.resources.redes.title.createTitle" actions={<PimsElementoActions />} >
            <TabbedForm redirect="list" >
                {/* ------ ELEMENTO DE RED ------ */}
                <FormTab label="dynatest.resources.redes.tabs.tabsTitle.identificacion" redirect="list" >
                <CoordenadasProyecto/>
                    <ReferenceInput
                        label="resources.redes.fields.tipoElemento"
                        source="tipoElemento"
                        reference="tipo_elementos"
                        onChange={OpcionTipoImagen}
                        filter={{
                            sistema: 'RDS'
                        }}
                        //filterToQuery={searchText => ({ nombre: searchText })}
                        sort={{ field: 'nombre', order: 'asc' }}
                        validate={[required(),ValidarElemento]}
                        fullWidth
                        formClassName={classes.grid_cont4s}
                    >
                        <SelectInput 
                            //options={{ fullWidth: true }} 
                            optionText="nombre" 
                        />
                    </ReferenceInput>
                    <SelectInput
                        fullWidth 
                        label="resources.redes.fields.margen" source="margen" allowEmpty emptyValue="" validate={[required()]} choices={[
                            { id: translate('dynatest.resources.redes.select.margen.Derecha'), name: 'dynatest.resources.redes.select.margen.Derecha' },
                            { id: translate('dynatest.resources.redes.select.margen.Izquierda'), name: 'dynatest.resources.redes.select.margen.Izquierda' },
                            { id: translate('dynatest.resources.redes.select.margen.Centro'), name: 'dynatest.resources.redes.select.margen.Centro' },
                        ]}
                        formClassName={classes.grid_cont4}
                    />
                    <SelectInput label="resources.redes.fields.sentidoB" source='sentido' choices={[
                        { id: translate('dynatest.resources.redes.select.sentido.NORTEId'), name: 'dynatest.resources.redes.select.sentido.NORTEName' },
                        { id: translate('dynatest.resources.redes.select.sentido.SURId'), name: 'dynatest.resources.redes.select.sentido.SURName' },
                        { id: translate('dynatest.resources.redes.select.sentido.ESTEId'), name: 'dynatest.resources.redes.select.sentido.ESTEName' },
                        { id: translate('dynatest.resources.redes.select.sentido.OESTEId'), name: 'dynatest.resources.redes.select.sentido.OESTEName' }
                        ]}
                        fullWidth
                        formClassName={classes.grid_cont4}/>
                    {elemento === "/tipo_elementos/CUNT" &&
                        <SelectInput fullWidth source="tipoCuneta" allowEmpty emptyValue="" choices={[
                            { id: 'Tipo carretera', name: 'Tipo carretera' },
                            { id: 'Bajo accesos a predios o intersección de vías', name: 'Bajo accesos a predios o intersección de vías' },
                            { id: 'En zonas urbanas y semiurbanas', name: 'En zonas urbanas y semiurbanas' },
                        ]} validate={[required()]}
                            formClassName={classes.grid_cont4} />

                    }
                    {/* ------- LOCALIZACIÓN --------- */}
                    <Typography variant="b" component="b" fullWidth>{translate('dynatest.resources.redes.title.localizacion')}</Typography>
                    <Divider fullWidth />
                    <Grid container spacing={24}>
                        <Grid item xs={4}>
                            <ReferenceInput
                                label="resources.redes.fields.unidadFuncional" 
                                source="unidadFuncional"
                                reference="unidad_funcionals"
                                filterToQuery={searchText => ({ codigo: searchText })}
                                sort={{ field: 'codigo', order: 'asc' }}
                                validate={[required(), ValidarUnidad]}
                                fullWidth
                            >
                                <AutocompleteInput options={{ fullWidth: true }} optionText="fullCodigos" />
                            </ReferenceInput>
                        </Grid>

                        {unidads &&
                            <Grid item xs={4}>
                                <ReferenceInput
                                    label="resources.redes.fields.tramo" 
                                    source="tramo"
                                    alwaysOn
                                    reference="tramos"
                                    filter={{ "properties[]" : "fullCodigos", unidadFuncional: unidads }}
                                    filterToQuery={searchText => ({ codigo: searchText })}
                                    sort={{ field: 'codigo', order: 'ASC', }}
                                    validate={[required(), ValidarTramo]}
                                    formClassName={classes.grid_cont4}
                                >
                                    <AutocompleteInput options={{ fullWidth: true }} optionText="fullCodigos" />
                                </ReferenceInput>
                            </Grid>
                        }
                        {tramos &&
                            <Grid item xs={4}>
                                <ReferenceInput
                                    label="resources.redes.fields.segmento" 
                                    source="segmento"
                                    reference="segmentos"
                                    sort={{ field: 'codigo', order: 'ASC' }}
                                    filter={{ "properties[]" : "fullCodigos", tramo: tramos}}
                                    filterToQuery={searchText => ({ codigo: searchText })}
                                    validate={[required(), ValidarSegmento]}
                                    formClassName={classes.grid_cont4}
                                >

                                    <AutocompleteInput options={{ fullWidth: true }} optionText="fullCodigos" />
                                </ReferenceInput>
                            </Grid>
                        }
                    </Grid>
                    {segmentos && <AbsSegmento segmento={segmentos} />}
                    {segmentos && (elemento === "/tipo_elementos/DCT" || elemento === "/tipo_elementos/REE") &&
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Typography variant="h3" component="h3" className={classes.coord}>{translate('dynatest.resources.redes.title.coordenadasIniciales')}</Typography>
                                <Divider fullWidth style={{ marginTop: '10px' }} />
                            </Grid>
                        </Grid> ||
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Typography variant="h3" component="h3" className={classes.coord}>{translate('dynatest.resources.redes.title.coordenadas')}</Typography>
                                <Divider fullWidth style={{ marginTop: '10px' }} />
                            </Grid>
                        </Grid>
                    }
                    {segmentos && <PRInput label="resources.redes.fields.abscisa" id="ingresoAbInicio" source="abInicio" fullWidth validate={[abValidationInicio, required()]} formClassName={classes.grid_cont3s} />}
                    {segmentos && <PInput label="resources.redes.fields.pr" id="ingresoPrInicio" source="pInicio" fullWidth validate={[prValidationInicio, /* required() */]} formClassName={classes.grid_cont3} />}
                    {segmentos && <NumberInput label="resources.redes.fields.latitud" id="LatitudInicial" source="latitudInicial" fullWidth validate={[required(), latMininima, LatitudMinimaArray]} formClassName={classes.grid_cont3} />}
                    {segmentos && <NumberInput label="resources.redes.fields.longitud" id="LongitudInicial" source="longitudInicial" fullWidth validate={[required(), lngMininima, LongitudMinimaArray]} formClassName={classes.grid_cont3} />}
                    {segmentos && (elemento !== "/tipo_elementos/DCT" || elemento !== "/tipo_elementos/REE") &&
                        <ArrayInput label="" source="ubicaciones" className={classes.ocultar}
                            defaultValue={[
                                {
                                    lat: latitudMinimaArray,
                                    lng: longitudMinimaArray
                                }
                            ]}
                        >
                            <SimpleFormIterator disableAdd disableRemove >
                                <NumberInput label="resources.redes.fields.latitud" source="lat" fullWidth />
                                <NumberInput label="resources.redes.fields.longitud" source="lng" fullWidth />
                            </SimpleFormIterator>
                        </ArrayInput>
                    }
                    {segmentos && (elemento === "/tipo_elementos/DCT" || elemento === "/tipo_elementos/REE") &&
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Typography variant="h3" component="h3" className={classes.coord}>{translate('dynatest.resources.redes.title.coordenadasFinales')}</Typography>
                                <Divider fullWidth style={{ marginTop: '10px' }} />
                            </Grid>
                        </Grid>
                    }
                    {segmentos && (elemento === "/tipo_elementos/DCT" || elemento === "/tipo_elementos/REE") && 
                        <PRInput label="resources.redes.fields.abscisa" id="ingresoAbFin" source="abFin" fullWidth 
                        validate={[validarLogicaAbsFinCreate, abValidationFin]} formClassName={classes.grid_cont3s} />}
                    {segmentos && (elemento === "/tipo_elementos/DCT" || elemento === "/tipo_elementos/REE") && 
                        <PInput label="resources.redes.fields.pr" id="ingresoPrFin" source="pFin" fullWidth validate={[prValidationFin]} formClassName={classes.grid_cont3} />}
                    {segmentos && (elemento === "/tipo_elementos/DCT" || elemento === "/tipo_elementos/REE") && 
                        <NumberInput label="resources.redes.fields.latitud" source="latitudFinal" fullWidth validate={[latMininima, LatitudMaximaArray]} formClassName={classes.grid_cont3} />}
                    {segmentos && (elemento === "/tipo_elementos/DCT" || elemento === "/tipo_elementos/REE") && 
                        <NumberInput label="resources.redes.fields.longitud" source="longitudFinal" fullWidth validate={[lngMininima, LongitudMaximaArray]} formClassName={classes.grid_cont3} />}
                    {segmentos && (elemento === "/tipo_elementos/DCT" || elemento === "/tipo_elementos/REE") &&
                        <ArrayInput label="" source="ubicaciones" className={classes.ocultar}
                            defaultValue={[
                                {
                                    lat: latitudMinimaArray,
                                    lng: longitudMinimaArray
                                },
                                {
                                    lat: latitudMaximaArray,
                                    lng: longitudMaximaArray
                                }
                            ]}
                        >
                            <SimpleFormIterator disableAdd disableRemove >
                                <NumberInput label="resources.redes.fields.latitud" source="lat" fullWidth />
                                <NumberInput label="resources.redes.fields.longitud" source="lng" fullWidth />
                            </SimpleFormIterator>
                        </ArrayInput>
                    }
                </FormTab>
                {/* ---------- CARACTERÍSTICAS ------------- */}
                <FormTab label="dynatest.resources.redes.tabs.tabsTitle.caracteristicas">
                    {/* ----- ELEMENTO= LUMINARIA ------ */}
                    {elemento === "/tipo_elementos/LUM" &&

                        <SelectInput label="resources.redes.fields.entornoVia" source="entornoVia" allowEmpty emptyValue="" choices={[
                            { id: translate('dynatest.resources.redes.select.entornoVia.claro'), name: "dynatest.resources.redes.select.entornoVia.claro" },
                            { id: translate('dynatest.resources.redes.select.entornoVia.oscuro'), name: "dynatest.resources.redes.select.entornoVia.oscuro" },
                        ]} validate={[required()]} fullWidth formClassName={classes.grid_cont4s} />

                    }
                    {elemento === "" &&
                        <p>*</p>

                    }
                    {elemento === "/tipo_elementos/LUM" &&

                        <SelectInput label="resources.redes.fields.claseIluminacion" source="claseIluminacion" allowEmpty emptyValue="" choices={[
                            { id: translate('dynatest.resources.redes.select.claseIluminacion.M1'), name: "dynatest.resources.redes.select.claseIluminacion.M1" },
                            { id: translate('dynatest.resources.redes.select.claseIluminacion.M2'), name: "dynatest.resources.redes.select.claseIluminacion.M2" },
                            { id: translate('dynatest.resources.redes.select.claseIluminacion.M3'), name: "dynatest.resources.redes.select.claseIluminacion.M3" },
                            { id: translate('dynatest.resources.redes.select.claseIluminacion.M4'), name: "dynatest.resources.redes.select.claseIluminacion.M4" },
                            { id: translate('dynatest.resources.redes.select.claseIluminacion.M5'), name: "dynatest.resources.redes.select.claseIluminacion.M5" },

                        ]} validate={[required()]} fullWidth formClassName={classes.grid_cont4} />

                    }
                    {elemento === "/tipo_elementos/LUM" &&

                        <SelectInput label="resources.redes.fields.tipoLampara" source="tipoLampara" allowEmpty emptyValue="" choices={[
                            { id: translate('dynatest.resources.redes.select.tipoLampara.descarga'), name: "dynatest.resources.redes.select.tipoLampara.descarga" },
                            { id: translate('dynatest.resources.redes.select.tipoLampara.incandescentes'), name: "dynatest.resources.redes.select.tipoLampara.incandescentes" },
                            { id: translate('dynatest.resources.redes.select.tipoLampara.vaporMercurioAltaPresion'), name: "dynatest.resources.redes.select.tipoLampara.vaporMercurioAltaPresion" },
                            { id: translate('dynatest.resources.redes.select.tipoLampara.vaporSodioAltaPresion'), name: "dynatest.resources.redes.select.tipoLampara.vaporSodioAltaPresion" },
                            { id: translate('dynatest.resources.redes.select.tipoLampara.VaporSodioBajaPresion'), name: "dynatest.resources.redes.select.tipoLampara.VaporSodioBajaPresion" },
                            { id: translate('dynatest.resources.redes.select.tipoLampara.otro'), name: "dynatest.resources.redes.select.tipoLampara.otro" },       
                        ]} validate={[required()]} fullWidth formClassName={classes.grid_cont4} />

                    }
                    {elemento === "/tipo_elementos/LUM" &&

                        <SelectInput label="resources.redes.fields.brazoTipo" source="brazoTipo" allowEmpty emptyValue="" choices={[
                            { id: translate('dynatest.resources.redes.select.brazoTipo.A'), name: "dynatest.resources.redes.select.brazoTipo.A" },
                            { id: translate('dynatest.resources.redes.select.brazoTipo.B'), name: "dynatest.resources.redes.select.brazoTipo.B" },
                            { id: translate('dynatest.resources.redes.select.brazoTipo.C'), name: "dynatest.resources.redes.select.brazoTipo.C" },
                            { id: translate('dynatest.resources.redes.select.brazoTipo.D'), name: "dynatest.resources.redes.select.brazoTipo.D" },
                            { id: translate('dynatest.resources.redes.select.brazoTipo.E'), name: "dynatest.resources.redes.select.brazoTipo.E" },
                        ]} validate={[required()]} fullWidth formClassName={classes.grid_cont4_f} />

                    }
                    {elemento === "/tipo_elementos/LUM" &&

                        <SelectInput label="resources.redes.fields.disposicionIluminaria" source="disposicionIluminaria" allowEmpty emptyValue="" choices={[
                            { id: translate('dynatest.resources.redes.select.disposicionLuminarias.bilateralPareada'), name: "dynatest.resources.redes.select.disposicionLuminarias.bilateralPareada" },
                            { id: translate('dynatest.resources.redes.select.disposicionLuminarias.centralDobleBrazo'), name: "dynatest.resources.redes.select.disposicionLuminarias.centralDobleBrazo" },
                            { id: translate('dynatest.resources.redes.select.disposicionLuminarias.combinaciónBrazosDoblesTresbolill'), name: "dynatest.resources.redes.select.disposicionLuminarias.combinaciónBrazosDoblesTresbolill" },
                            { id: translate('dynatest.resources.redes.select.disposicionLuminarias.unilateralCalzadasDiferenciadas'), name: "dynatest.resources.redes.select.disposicionLuminarias.unilateralCalzadasDiferenciadas" },
                            { id: translate('dynatest.resources.redes.select.disposicionLuminarias.unilateralLadoExterior'), name: "dynatest.resources.redes.select.disposicionLuminarias.unilateralLadoExterior" },
                            
                        ]} validate={[required()]} fullWidth formClassName={classes.grid_cont4_s} />

                    }

                    {elemento === "/tipo_elementos/LUM" &&
                        <NumberInput label="resources.redes.fields.luminancia" source="valorIluminancia" validate={[required(), minValue(0, "Debe ser al menos 0."), maxValue(1000000)]} fullWidth formClassName={classes.grid_cont4_s} />
                    }

                    {elemento === "/tipo_elementos/LUM" &&
                        <NumberInput label="resources.redes.fields.angulo" source="anguloInclinacion" validate={[required(), minValue(0, "Debe ser al menos 0."), maxValue(90)]} fullWidth formClassName={classes.grid_cont4_f} />
                    }
                    {elemento === "/tipo_elementos/LUM" &&
                        <NumberInput label="resources.redes.fields.largo" source="largo" validate={[required(), validarDosDecimales, minValue(0, "Debe ser al menos 0.")]} fullWidth formClassName={classes.grid_cont4_s} />
                    }
                    {elemento === "/tipo_elementos/LUM" &&
                        <NumberInput label="resources.redes.fields.altura" source="altura" validate={[required(), validarDosDecimales, minValue(0, "Debe ser al menos 0.")]} fullWidth formClassName={classes.grid_cont4_s} />
                    }
                    {elemento === "/tipo_elementos/LUM" &&

                        <ReferenceInput
                            label="resources.redes.fields.empresa"
                            source="empresa"
                            reference="servicios_publicos"
                            sort={{ field: 'empresa', order: 'ASC' }}
                            filterToQuery={searchText => ({ empresa: searchText })}
                            validate={[required()]}
                            fullWidth
                            formClassName={classes.grid_cont12}
                        >

                            <AutocompleteInput options={{ fullWidth: true }} optionText="empresa" />
                        </ReferenceInput>
                    }


                    {/* ------- ELEMENTO = SEMÁFORO ------ */}
                    {elemento === "/tipo_elementos/SEM" &&
                        <SelectInput label="resources.redes.fields.clase" source="entornoVia" allowEmpty emptyValue="" choices={[
                            { id: translate('dynatest.resources.redes.select.claseSemaforo.semaforoEspecial'), name: "dynatest.resources.redes.select.claseSemaforo.semaforoEspecial" },
                            { id: translate('dynatest.resources.redes.select.claseSemaforo.semaforosControlTránsitoVehículos'), name: "dynatest.resources.redes.select.claseSemaforo.semaforosControlTránsitoVehículos" },
                            { id: translate('dynatest.resources.redes.select.claseSemaforo.semaforosPasosPeatonales'), name: "dynatest.resources.redes.select.claseSemaforo.semaforosPasosPeatonales" },
                            { id: translate('dynatest.resources.redes.select.claseSemaforo.semaforosSonoros'), name: "dynatest.resources.redes.select.claseSemaforo.semaforosSonoros" },
                      
                        ]} validate={[required()]} fullWidth formClassName={classes.grid_cont6_f} />
                    }
                    {elemento === "/tipo_elementos/SEM" &&
                        <SelectInput label="resources.redes.fields.clasificacionSoporte" source="claseIluminacion" allowEmpty emptyValue="" choices={[
                            { id: translate('dynatest.resources.redes.select.clasificacionSoporte.postes'), name: "dynatest.resources.redes.select.clasificacionSoporte.postes" },
                            { id: translate('dynatest.resources.redes.select.clasificacionSoporte.cablesSuspención'), name: "dynatest.resources.redes.select.clasificacionSoporte.cablesSuspención" },
                            { id: translate('dynatest.resources.redes.select.clasificacionSoporte.estructurasPórtico'), name: "dynatest.resources.redes.select.clasificacionSoporte.estructurasPórtico" },
                            { id: translate('dynatest.resources.redes.select.clasificacionSoporte.mensulasCortas'), name: "dynatest.resources.redes.select.clasificacionSoporte.mensulasCortas" },
                            { id: translate('dynatest.resources.redes.select.clasificacionSoporte.mensulasLargasPostesLaterales'), name: "dynatest.resources.redes.select.clasificacionSoporte.mensulasLargasPostesLaterales" },
                            { id: translate('dynatest.resources.redes.select.clasificacionSoporte.postesPedestalesIslas'), name: "dynatest.resources.redes.select.clasificacionSoporte.postesPedestalesIslas" },
                        ]} validate={[required()]} fullWidth formClassName={classes.grid_cont6_s} />
                    }

                    {elemento === "/tipo_elementos/SEM" &&
                        <NumberInput label="resources.redes.fields.largoLuminoso" source="largo" validate={[required(), validarDecimales3, minValue(0, "Debe ser al menos 0.")]} fullWidth formClassName={classes.grid_cont4_f} />
                    }
                    {elemento === "/tipo_elementos/SEM" &&
                        <NumberInput label="resources.redes.fields.anchoLuminoso" source="ancho" validate={[required(), validarDecimales4, minValue(0, "Debe ser al menos 0.")]} fullWidth formClassName={classes.grid_cont4_s} />
                    }
                    {elemento === "/tipo_elementos/SEM" &&
                        <NumberInput label="resources.redes.fields.altura" source="altura" validate={[required(), validarDosDecimales, minValue(0, "Debe ser al menos 0.")]} fullWidth formClassName={classes.grid_cont4_s} />
                    }

                    {/* ------- Elemento=Báscula de pesaje ------ */}
                    <Grid container spacing={24}>
                    {elemento === "/tipo_elementos/BDP" &&
                        <Grid item xs={6}>
                            <TextInput label="resources.redes.fields.marca" source="entornoVia" validate={[required(), maxLength(30)]} fullWidth formClassName={classes.grid_cont6} />
                        </Grid>
                    }
                    {elemento === "/tipo_elementos/BDP" &&
                        <Grid item xs={6}>
                            <NumberInput label="resources.redes.fields.capacidadT" source="altura" validate={[required(), minValue(0, "Debe ser al menos 0."), maxLength(30), validarDecimales2]} fullWidth formClassName={classes.grid_cont6} />
                        </Grid>
                    }
                    {elemento === "/tipo_elementos/BDP" &&
                        <Grid item xs={6}>
                            <NumberInput label="resources.redes.fields.largo" source="largo" validate={[required(), validarDecimales3, minValue(0, "Debe ser al menos 0.")]} fullWidth formClassName={classes.grid_cont6} />
                        </Grid>
                    }
                    {elemento === "/tipo_elementos/BDP" &&
                        <Grid item xs={6}>
                            <NumberInput label="resources.redes.fields.ancho" source="ancho" validate={[required(), validarDecimales4, minValue(0, "Debe ser al menos 0.")]} fullWidth formClassName={classes.grid_cont6} />
                        </Grid>
                    }
                    {elemento === "/tipo_elementos/BDP" &&
                        <Grid item xs={6}>
                            {/* <ReferenceInput
                                label="Nivel de tráfico"
                                source="nivelTrafico"
                                reference="flexible_detalles"
                                filter={{"properties[]" : "nivel_trafico"}}
                                sort={{ field: 'nivel_trafico', order: 'ASC' }}
                                validate={[required()]}
                                formClassName={classes.grid_cont6}
                            >
                                    <SelectInput fullWidth optionText="nivel_trafico" />
                            </ReferenceInput> */}
                            <SelectInput source="nivelTrafico" label="resources.redes.fields.nivelTraficoDetalles" fullWidth choices={[
                                { id: translate('dynatest.resources.redes.select.nivelTrafico.NT1'), name: "dynatest.resources.redes.select.nivelTrafico.NT1" },
                                { id: translate('dynatest.resources.redes.select.nivelTrafico.NT2'), name: "dynatest.resources.redes.select.nivelTrafico.NT2" },
                                { id: translate('dynatest.resources.redes.select.nivelTrafico.NT3'), name: "dynatest.resources.redes.select.nivelTrafico.NT3" },
                            ]} formClassName={classes.last_filed} validate={[required()]} />  
                        </Grid>
                    }
                    </Grid>





                    {/* ------- ELEMENTOS RESTANTES ------ */}

                    {elemento !== "/tipo_elementos/SEM" && elemento !== "/tipo_elementos/LUM" && elemento !== "" && elemento !== "/tipo_elementos/BDP" &&
                    elemento !== "/tipo_elementos/DCT" && elemento !== "/tipo_elementos/REE" &&
                    <div formClassName={classes.grid_cont12}></div>
                    }
                    {elemento !== "/tipo_elementos/SEM" && elemento !== "/tipo_elementos/LUM" && elemento !== "" && elemento !== "/tipo_elementos/BDP" && 
                    elemento !== "/tipo_elementos/DCT" && elemento !== "/tipo_elementos/REE" &&
                        <SelectInput label="resources.redes.fields.material" source="entornoVia" allowEmpty emptyValue="" choices={[
                            { id: translate('dynatest.resources.redes.select.material.concreto'), name: "dynatest.resources.redes.select.material.concreto" },
                            { id: translate('dynatest.resources.redes.select.material.mamposteria'), name: "dynatest.resources.redes.select.material.mamposteria" },
                            { id: translate('dynatest.resources.redes.select.material.prefabricado'), name: "dynatest.resources.redes.select.material.prefabricado" },
                            { id: translate('dynatest.resources.redes.select.material.otro'), name: "dynatest.resources.redes.select.material.otro" },
                        ]} fullWidth formClassName={classes.grid_cont6s} />
                    }
                    {elemento !== "/tipo_elementos/SEM" && elemento !== "/tipo_elementos/LUM" && elemento !== "" && elemento !== "" && elemento !== "/tipo_elementos/BDP" &&
                        elemento !== "/tipo_elementos/DCT" && elemento !== "/tipo_elementos/REE" &&
                        <SelectInput label="resources.redes.fields.seccion" source="claseIluminacion" allowEmpty emptyValue="" choices={[
                            { id: translate('dynatest.resources.redes.select.seccion.circular'), name: "dynatest.resources.redes.select.seccion.circular" },
                            { id: translate('dynatest.resources.redes.select.seccion.cuadrada'), name: "dynatest.resources.redes.select.seccion.cuadrada" },
                            { id: translate('dynatest.resources.redes.select.seccion.hexagonal'), name: "dynatest.resources.redes.select.seccion.hexagonal" },
                            { id: translate('dynatest.resources.redes.select.seccion.perfilH'), name: "dynatest.resources.redes.select.seccion.perfilH" },
                            { id: translate('dynatest.resources.redes.select.seccion.rectangular'), name: "dynatest.resources.redes.select.seccion.rectangular" },
                            { id: translate('dynatest.resources.redes.select.seccion.otro'), name: "dynatest.resources.redes.select.seccion.otro" },

                        ]} fullWidth formClassName={classes.grid_cont6} />
                    }
                    {elemento !== "/tipo_elementos/SEM" && elemento !== "/tipo_elementos/LUM" && elemento !== "" && elemento !== "" && elemento !== "/tipo_elementos/BDP" &&
                        elemento !== "/tipo_elementos/DCT" && elemento !== "/tipo_elementos/REE" &&
                        <NumberInput label="resources.redes.fields.largoDiametro" source="largo" validate={[validarDecimales3, minValue(0, "Debe ser al menos 0.")]} fullWidth formClassName={classes.grid_cont4s} />
                    }
                    {elemento !== "/tipo_elementos/SEM" && elemento !== "/tipo_elementos/LUM" && elemento !== "" && elemento !== "" && elemento !== "/tipo_elementos/BDP" &&
                        elemento !== "/tipo_elementos/DCT" && elemento !== "/tipo_elementos/REE" &&
                        <NumberInput label="resources.redes.fields.base" source="ancho" validate={[validarDecimales4, minValue(0, "Debe ser al menos 0.")]} fullWidth formClassName={classes.grid_cont4} />
                    }
                    {elemento !== "/tipo_elementos/SEM" && elemento !== "/tipo_elementos/LUM" && elemento !== "" && elemento !== "" && elemento !== "/tipo_elementos/BDP" &&
                        elemento !== "/tipo_elementos/DCT" && elemento !== "/tipo_elementos/REE" &&
                        <NumberInput label="resources.redes.fields.altura" source="altura" validate={[validarDosDecimales, minValue(0, "Debe ser al menos 0.")]} fullWidth formClassName={classes.grid_cont4} />
                    }
                    {elemento !== "/tipo_elementos/SEM" && elemento !== "/tipo_elementos/LUM" && elemento !== "" && elemento !== "" && elemento !== "/tipo_elementos/BDP" &&
                        elemento !== "/tipo_elementos/DCT" && elemento !== "/tipo_elementos/REE" &&
                        <ReferenceInput
                            label="resources.redes.fields.empresa"
                            source="empresa"
                            reference="servicios_publicos"
                            sort={{ field: 'empresa', order: 'ASC' }}
                            filterToQuery={searchText => ({ empresa: searchText })}
                            validate={[required()]}
                            formClassName={classes.grid_cont12}
                            fullWidth
                        >

                            <AutocompleteInput options={{ fullWidth: true }} optionText="empresa" />
                        </ReferenceInput>
                    }
                    <div formClassName={classes.grid_cont12}></div>

                    {/* ------- ELEMENTO = DUCTOS ------ */}
                    {elemento === "/tipo_elementos/DCT" &&
                        <SelectInput label="resources.redes.fields.tipo" source="tipoRed" allowEmpty emptyValue="" choices={[
                            { id: translate('dynatest.resources.redes.select.tipoRedes.gas'), name: "dynatest.resources.redes.select.tipoRedes.gas" },
                            { id: translate('dynatest.resources.redes.select.tipoRedes.acueducto'), name: "dynatest.resources.redes.select.tipoRedes.acueducto" },
                            { id: translate('dynatest.resources.redes.select.tipoRedes.alcantarillado'), name: "dynatest.resources.redes.select.tipoRedes.alcantarillado" },
                            { id: translate('dynatest.resources.redes.select.tipoRedes.fibraOptica'), name: "dynatest.resources.redes.select.tipoRedes.fibraOptica" },
                        ]} fullWidth formClassName={classes.grid_cont6_f} />
                    }
                    {elemento === "/tipo_elementos/DCT" &&
                        <SelectInput label="resources.redes.fields.material" source="material" allowEmpty emptyValue="" choices={[
                            { id: translate('dynatest.resources.redes.select.material.acero'), name: "dynatest.resources.redes.select.material.acero" },
                            { id: translate('dynatest.resources.redes.select.material.cobre'), name: "dynatest.resources.redes.select.material.cobre" },
                            { id: translate('dynatest.resources.redes.select.material.concreto'), name: "dynatest.resources.redes.select.material.concreto" },
                            { id: translate('dynatest.resources.redes.select.material.concretoAcero'), name: "dynatest.resources.redes.select.material.concretoAcero" },
                            { id: translate('dynatest.resources.redes.select.material.mamposteria'), name: "dynatest.resources.redes.select.material.mamposteria" },
                            { id: translate('dynatest.resources.redes.select.material.HDEP'), name: "dynatest.resources.redes.select.material.HDEP" },
                            { id: translate('dynatest.resources.redes.select.material.polipropileno'), name: "dynatest.resources.redes.select.material.polipropileno" },
                            { id: translate('dynatest.resources.redes.select.material.PVC'), name: "dynatest.resources.redes.select.material.PVC" },
                            { id: translate('dynatest.resources.redes.select.material.otro'), name: "dynatest.resources.redes.select.material.otro" },
                            

                        ]} fullWidth formClassName={classes.grid_cont6_s} />
                    }

                    {elemento === "/tipo_elementos/DCT" &&
                        <NumberInput label="resources.redes.fields.longitudM" source="longitud" validate={[validarDosDecimales, minValue(0, "Debe ser al menos 0.")]} 
                        fullWidth formClassName={classes.grid_cont4_f} />
                    }
                    {elemento === "/tipo_elementos/DCT" &&
                        <NumberInput label="resources.redes.fields.diametro" source="diametro" validate={[validarDosDecimales, minValue(0, "Debe ser al menos 0.")]} 
                        fullWidth formClassName={classes.grid_cont4_s} />
                    }
                    {elemento === "/tipo_elementos/DCT" &&
                        <ReferenceInput
                            label="resources.redes.fields.empresa"
                            source="empresa"
                            reference="servicios_publicos"
                            sort={{ field: 'empresa', order: 'ASC' }}
                            filterToQuery={searchText => ({ empresa: searchText })}
                            formClassName={classes.grid_cont4}
                            fullWidth
                        >

                        <AutocompleteInput options={{ fullWidth: true }} optionText="empresa" />
                        </ReferenceInput>
                    }
                    <br/>

                    {/* ------- ELEMENTO = REDES DE ENERGÍA ELECTRICA ------ */}
                    {elemento === "/tipo_elementos/REE" &&
                        <SelectInput label="resources.redes.fields.tipo" source="tipoRed" allowEmpty emptyValue="" choices={[
                            { id: translate('dynatest.resources.redes.select.tipoRedes.longitudinal'), name: "dynatest.resources.redes.select.tipoRedes.longitudinal" },
                            { id: translate('dynatest.resources.redes.select.tipoRedes.poste'), name: "dynatest.resources.redes.select.tipoRedes.poste" },
                            { id: translate('dynatest.resources.redes.select.tipoRedes.travesia'), name: "dynatest.resources.redes.select.tipoRedes.travesia" },
                        ]} fullWidth formClassName={classes.grid_cont6_f} />
                    }
                    {elemento === "/tipo_elementos/REE" &&
                        <SelectInput label="resources.redes.fields.material" source="material" allowEmpty emptyValue="" choices={[
                            { id: translate('dynatest.resources.redes.select.material.concreto'), name: "dynatest.resources.redes.select.material.concreto" },
                            { id: translate('dynatest.resources.redes.select.material.mamposteria'), name: "dynatest.resources.redes.select.material.mamposteria" },
                            { id: translate('dynatest.resources.redes.select.material.prefabricado'), name: "dynatest.resources.redes.select.material.prefabricado" },
                            { id: translate('dynatest.resources.redes.select.material.otro'), name: "dynatest.resources.redes.select.material.otro" },
                        ]} fullWidth formClassName={classes.grid_cont6_s} />
                    }

                    {elemento === "/tipo_elementos/REE" &&
                        <NumberInput label="resources.redes.fields.longitudM" source="longitud" validate={[validarDosDecimales, minValue(0, "Debe ser al menos 0.")]} 
                        fullWidth formClassName={classes.grid_cont4_f} />
                    }
                    {elemento === "/tipo_elementos/REE" &&
                        <NumberInput label="resources.redes.fields.diametro" source="diametro" validate={[validarDosDecimales, minValue(0, "Debe ser al menos 0.")]} 
                        fullWidth formClassName={classes.grid_cont4_s} />
                    }
                    {elemento === "/tipo_elementos/REE" &&
                        <ReferenceInput
                            label="resources.redes.fields.empresa"
                            source="empresa"
                            reference="servicios_publicos"
                            sort={{ field: 'empresa', order: 'ASC' }}
                            filterToQuery={searchText => ({ empresa: searchText })}
                            formClassName={classes.grid_cont4}
                            fullWidth
                        >

                        <AutocompleteInput options={{ fullWidth: true }} optionText="empresa" />
                        </ReferenceInput>
                    }
                    <br/>

                </FormTab>
                {/* ------ DETALLES ---------- */}
                <FormTab label="dynatest.resources.redes.tabs.tabsTitle.detalles" >
                    <SelectInput label="resources.redes.fields.estado" source="estado" choices={[
                        { id: translate('dynatest.resources.redes.select.estado.Existente'), name: "dynatest.resources.redes.select.estado.Existente" },
                        { id: translate('dynatest.resources.redes.select.estado.Reemplazo'), name: "dynatest.resources.redes.select.estado.Reemplazo" },
                        { id: translate('dynatest.resources.redes.select.estado.Nuevo'), name: "dynatest.resources.redes.select.estado.Nuevo" },
                    ]}
                        fullWidth
                        validate={[required()]}
                        onChange={ValidarEstado}
                        formClassName={classes.grid_cont4_f}
                    />

                    {estados === translate('dynatest.resources.redes.select.estado.Reemplazo') &&
                        <SelectInput fullWidth label="resources.redes.fields.motivoReemplazo" source="motivoReemplazo"
                            allowEmpty
                            emptyValue=""
                            choices={[
                                { id: translate('dynatest.resources.redes.select.motivoReemplazo.accidente'), name: 'dynatest.resources.redes.select.motivoReemplazo.accidente' },
                                { id: translate('dynatest.resources.redes.select.motivoReemplazo.instalacion'), name: 'dynatest.resources.redes.select.motivoReemplazo.instalacion' },
                                { id: translate('dynatest.resources.redes.select.motivoReemplazo.reemplazoProgramado'), name: 'dynatest.resources.redes.select.motivoReemplazo.reemplazoProgramado' },
                                { id: translate('dynatest.resources.redes.select.motivoReemplazo.vandalismo'), name: 'dynatest.resources.redes.select.motivoReemplazo.vandalismo' },
                                { id: translate('dynatest.resources.redes.select.motivoReemplazo.otro'), name: 'dynatest.resources.redes.select.motivoReemplazo.otro' },
                            ]}
                            validate={[required()]}
                            formClassName={classes.grid_cont4_s}
                        />
                    }
                    {estados === translate('dynatest.resources.redes.select.estado.Reemplazo') && 
                        <ReferenceInput
                            label="resources.redes.fields.elementoReemplazo"
                            source="elementoReemplazo_id"
                            reference="elemento_reds"
                            filter={{
                                "properties[]" : "fullCodigoConsecutivo",
                                tipoElemento : elemento,
                                estados : "Activo",
                                unidadFuncional: unidads,
                                tramo: tramos,
                                segmento : segmentos
                            }}
			                filterToQuery={searchText => ({ fullCodigo: searchText })} 
                            fullWidth
                            formClassName={classes.grid_cont4}
                        >
                            <AutocompleteInput options={{fullWidth: true}} optionText="fullCodigoConsecutivo" />
                        </ReferenceInput>
                    }
                    <br/>
                    {elemento !== "/tipo_elementos/DCT" && elemento !== "/tipo_elementos/REE" &&
                        <SelectInput label="resources.redes.fields.estados" source="estados" allowEmpty emptyValue="" choices={[
                            { id: translate('dynatest.resources.redes.select.estados.Activo'), name: "dynatest.resources.redes.select.estados.Activo" },
                            { id: translate('dynatest.resources.redes.select.estados.Inactivo'), name: "dynatest.resources.redes.select.estados.Inactivo" },
                            ]} validate={[required()]} fullWidth formClassName={classes.grid_cont4_f} />
                    }
                    {elemento === "/tipo_elementos/DCT" &&
                        <SelectInput label="resources.redes.fields.estados" source="estados" allowEmpty emptyValue="" choices={[
                            { id: translate('dynatest.resources.redes.select.estados.Activo'), name: "dynatest.resources.redes.select.estados.Activo" },
                            { id: translate('dynatest.resources.redes.select.estados.Inactivo'), name: "dynatest.resources.redes.select.estados.Inactivo" },
                            ]} fullWidth formClassName={classes.grid_cont4_f} />
                    }
                    {elemento === "/tipo_elementos/REE" &&
                        <SelectInput label="resources.redes.fields.estados" source="estados" allowEmpty emptyValue="" choices={[
                            { id: translate('dynatest.resources.redes.select.estados.Activo'), name: "dynatest.resources.redes.select.estados.Activo" },
                            { id: translate('dynatest.resources.redes.select.estados.Inactivo'), name: "dynatest.resources.redes.select.estados.Inactivo" },
                            ]} fullWidth formClassName={classes.grid_cont4_f} />
                    }
                    <div formClassName={classes.grid_cont12}></div>
                    <DateInput
                        label="resources.redes.fields.fecha"
                        source="fechaInstalacion"
                        fullWidth
                        validate={[required(), validacionmaxfechaact, isFechaInvalida]}
                        formClassName={classes.grid_cont4_f}
                    />

                    <FormDataConsumer
                        formClassName={classes.grid_cont4_s}
                    >
                        {
                            ({ formData, ...rest }) => (

                                <ReferenceInput 
                                    label="resources.redes.fields.proveedor" 
                                    source="proveedor" 
                                    reference="proveedors" 
                                    filter={{ proyecto: props.proyecto, suministros: 1, sistema: 'RDS' }}
                                    sort={{ field: 'razonSocial', order: 'ASC' }}
                                    filterToQuery={searchText => ({ razonSocial: searchText })} 
                                    validate={[required()]}
                                >
                                    <AutocompleteInput  options={{fullWidth : true}} optionText="razonSocial"  />
                                </ReferenceInput>
                            )
                        }
                    </FormDataConsumer>

                    <FormDataConsumer
                        formClassName={classes.grid_cont4_s}
                    >
                        {
                            ({ formData, ...rest }) => (
                                <NumberInput  label="resources.redes.fields.garantiaMeses" source="garantia" fullWidth validate={[required(), garantia, minValue(0, "Debe ser al menos 0.")]} />
                            )
                        }
                    </FormDataConsumer>
                    <BooleanInput
                        label="resources.redes.fields.mantenimiento"
                        source="mantenimiento" fullWidth
                        formClassName={classes.grid_cont12}
                    />

                    <TextInput
                        label="resources.redes.fields.observaciones"
                        source="observaciones"
                        fullWidth
                        validate={[required()]}
                        formClassName={classes.grid_cont12}
                    />
                    <TextInput source="createdIn" style={{ display: 'none' }} defaultValue={"api"} />
                    <TextInput source="updatedIn" style={{ display: 'none' }} defaultValue={"api"} />
                    <TextInput source="aprobacion" style={{ display: 'none' }} defaultValue={"Aprobado"} />
                </FormTab>
                <FormTab label="dynatest.resources.redes.tabs.tabsTitle.fotografias">
                    <ImagenesInventario type='create' defaultTipoImagen={defaultTipoImagen}/>                                   
                </FormTab>
            </TabbedForm>
        </PimsCreate>
    )

}));
