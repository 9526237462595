import React from 'react';
import { DateField, Filter, List, Datagrid, TextField, EditButton, ShowButton, ReferenceField, SelectField } from 'react-admin';
import { BulkDeleteWithConfirmButton, Loading } from 'react-admin';
import { Fragment } from 'react';


const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props} />
    </Fragment>
);
const FiltroSeguridad = (props) => (
    <Filter {...props}>

    </Filter>
);

export const CalificacionList = ({ permissions, ...props }) => {
    if (!permissions) return <Loading />;
    return (
        <List title="Lista Calificación" 
            {...props} 
            sort={{ field: 'id', order: 'DESC' }} 
            filters={<FiltroSeguridad />} 
            bulkActionButtons={permissions['calificacionPuentes'] == 1 ? <PostBulkActionButtons /> : null}>
            <Datagrid>
                <ReferenceField label="Peaje" source="puente" reference="puentes" linkType={false}>
                    <TextField source="smartCode" />
                </ReferenceField>
                <TextField source="nombre_inspector" />
                <DateField label="Fecha de Inspección" source="fecha2" />
                {permissions['calificacionPuentes'] == 1 || permissions['calificacionPuentes'] == 2 || permissions['calificacionPuentes'] == 4 ? <ShowButton/>: null}
                {permissions['calificacionPuentes'] == 1 || permissions['calificacionPuentes'] == 2 ? <EditButton/>: null}

            </Datagrid>
        </List>
    )
};