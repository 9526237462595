import React from 'react';
import { ReferenceField,Filter,TextField,List, Datagrid, EditButton, ShowButton } from 'react-admin';
import {  BulkDeleteWithConfirmButton, Loading  } from 'react-admin';
import { Fragment } from 'react';

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);

const InvetarioFilter = (props) => (
    <Filter {...props}>
        {/* <ReferenceInput label="Segmento" source="estacion_conteo" reference="estacion_conteos" alwaysOn>
                <SelectInput optionText="segmento_.codigo"  />
         </ReferenceInput> */}
    </Filter>
);


export const TasasList = ({ permissions, ...props }) => {
    if (!permissions) return <Loading />;  
    return (
        <List title="Lista Tasas de Crecimiento" {...props} sort={{ field: 'id', order: 'DESC' }} filters={<InvetarioFilter/>}  bulkActionButtons={permissions['tasaCrecimiento']  == 1 ? <PostBulkActionButtons />: null} >
            <Datagrid >
            <ReferenceField label="Estación de conteo" source="estacion_conteo" reference="estacion_conteos" fullWidth linkType={false}>
                    <TextField source="nombre"  />
                </ReferenceField>
                <ReferenceField label="Unidad Funcional" source="estacion_conteo" reference="estacion_conteos" fullWidth linkType={false}>
                    <TextField source="unidadFuncional_.codigo"  />
                </ReferenceField>
                <ReferenceField label="Segmento" source="estacion_conteo" reference="estacion_conteos" fullWidth linkType={false}>
                    <TextField source="segmento_.codigo"  />
                </ReferenceField> 
                <ReferenceField label="Fecha Inicial" source="date1" reference="ejes" fullWidth linkType={false}>                               
                        <TextField  source="dateq"  fullWidth />
                </ReferenceField>
                <ReferenceField label="Fecha Final" source="date2" reference="ejes" fullWidth linkType={false}>                               
                    <TextField  source="dateq"  fullWidth />
                </ReferenceField>                                                 
                <TextField  label="Autos" source="autodanovolumen" />
                <TextField  label="Buses" source="busesporcentaje" />    
                <TextField  label="Camiones" source="camiones" />            
                {permissions['tasaCrecimiento'] == 1 || permissions['tasaCrecimiento'] == 2 || permissions['tasaCrecimiento'] == 4 ? <ShowButton/>: null}
                {permissions['tasaCrecimiento'] == 1 || permissions['tasaCrecimiento'] == 2 ? <EditButton/>: null}
            </Datagrid>
        </List>
)};