import React, {useState, Fragment} from 'react';

import { List, Datagrid, TextField, EditButton, ShowButton, ReferenceField, Filter, ReferenceInput, SelectInput,
    AutocompleteInput, BulkDeleteWithConfirmButton, Loading
} from 'react-admin';
import { CardActions, CreateButton, ExportButton } from 'react-admin';
import { PRField } from './../../../fields/PRField';
import { PField } from './../../../fields/PField';
import { translate } from 'react-admin';

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);
const ListAction = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
        <ExportButton basePath={basePath}/>
    </CardActions>
);
const ListAction2 = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
    </CardActions>
);

const FiltroAlrededor =(props) => {
    const [uf, setUf] = useState('none');
    const ufSet = (value) => {
        setUf(value);
    }
    const [tramo, setTramo] = useState('none');
    const tramoSet = (value) => {
        setTramo(value);
    }
    return(
    <Filter {...props}>
        <ReferenceInput 
            id="unidadFuncional"
            label="resources.elemento_alrededors.fields.unidadFuncional" 
            source="unidadFuncional"  
            reference="unidad_funcionals" 
            filterToQuery={searchText => ({ codigo: searchText })} 
            sort={{field : 'codigo', order : 'asc'}}
            validate={[ufSet]}
            alwaysOn
            fullWidth 
        >
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
        <ReferenceInput 
            label="resources.elemento_alrededors.fields.tramo" 
            source="tramo" 
            alwaysOn 
            reference="tramos" 
            filter={{ unidadFuncional: uf}}
            validate={[tramoSet]}
            filterToQuery={searchText => ({ codigo: searchText })} 
            sort={{ 
                field: 'codigo', 
                order: 'ASC' ,
                
            }}>
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
        
        <ReferenceInput 
            label="resources.elemento_alrededors.fields.segmento" 
            source="segmento" 
            alwaysOn 
            reference="segmentos" 
            sort={{ field: 'codigo', order: 'ASC' }}
            filter={{tramo : tramo}}
            filterToQuery={searchText => ({ codigo: searchText })} 
        >
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>

        <ReferenceInput 
            label="resources.elemento_alrededors.fields.tipoElementoShow" 
            source="tipoElemento" 
            reference="tipo_elementos" 
            filter={{sistema: "ALR"}} 
            sort={{
                field: "nombre",
                order: "ASC"
            }}
            alwaysOn
        >
            <SelectInput optionText="nombre"/>
        </ReferenceInput>

        <SelectInput label="resources.elemento_alrededors.fields.estados" source="estados" choices={[
                { id: translate('dynatest.resources.elemento_alrededors.select.estados.Activo'), name: "dynatest.resources.elemento_alrededors.select.estados.Activo" },
                { id: translate('dynatest.resources.elemento_alrededors.select.estados.Inactivo'), name: "dynatest.resources.elemento_alrededors.select.estados.Inactivo" },
            ]}  fullWidth alwaysOn
            />
    </Filter>
    )
};

export const ElementoAlrededorList = translate(({ permissions, ...props }) => {
    const { translate } = props;
    if (!permissions) return <Loading />;  
    return (
        <List 
            {...props} 
            sort={{ field: 'updatedAt', order: 'DESC' }} 
            filters={<FiltroAlrededor/>} 
            actions={permissions['alrededores']  == 1 ? <ListAction /> : permissions['alrededores']  == 2 ? <ListAction2 />: null}
            bulkActionButtons={permissions['alrededores']  == 1 ? <PostBulkActionButtons />: null}
        >
        <Datagrid>
            <TextField label="resources.elemento_alrededors.fields.smartCode" source="smartCode" sortable={false}/>
            <ReferenceField 
                label="resources.elemento_alrededors.fields.tipoElementoShow" 
                source="tipoElemento" 
                sortable={false} 
                reference="tipo_elementos" 
                linkType={false}
            >
                <TextField source="nombre" />
            </ReferenceField>            
            <PRField label="resources.elemento_alrededors.fields.abInicioList" source="abInicio"/>
            <PField label="resources.elemento_alrededors.fields.pInicioList" source="pInicio"/>
            <TextField label="resources.elemento_alrededors.fields.margen" source="margen"/>
            <TextField label="resources.elemento_alrededors.fields.tipoServicioObs" source="tipoServicio"/>
            <TextField label="resources.elemento_alrededors.fields.estados" source="estados" sortable/>
            {permissions['alrededores'] == 1 || permissions['alrededores'] == 2 || permissions['alrededores'] == 4 ? <ShowButton/>: null}
            {permissions['alrededores'] == 1 || permissions['alrededores'] == 2 ? <EditButton/>: null}                            
        </Datagrid>
    </List>
)});