import React, {useState, Fragment} from 'react';
import { SimpleForm, Query, TextInput, DateInput, ReferenceInput, SelectInput, FormDataConsumer, NumberInput, BooleanInput,  FileInput, FileField , AutocompleteInput} from 'react-admin';
import { PimsCreate } from './../Create';
import { PRInput } from './../../inputs/PRInput';
import { withStyles } from '@material-ui/core';
import  {styles}  from './../../EstilosGrid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import {required} from 'react-admin';
import {abValidationInicio, abValidationFin, validarLogicaAbsFinCreate} from '../validacionesInputs';
import config from '../../config/config'; 
import { translate } from 'react-admin';
let urlAppjs = config.UrlServer;

export const ImportacionPersCreate = translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;
const [unidads, setUnidad] = useState('');
const [fechas, setFecha] = useState('');
const [namearchivo, setNombreArchivo] = useState('');
const [referencia, setReferencia] = useState('');
const [unidadcodigo, setUnidadcodigo] = useState('');
const [unidadcodigo2, setUnidadcodigo2] = useState('');
const ValidarUnidad = (value) => {
    setUnidad(value)
}
const validarreferencia= (value) =>{
    setReferencia(value);
}
const ValidacionArchivo=(value)=>{
if(value){
    const data = new FormData();
    var today = new Date();
    var horaact = today.getHours();
    var minact = today.getMinutes();
    if (horaact.toString().length <= 1) {
        horaact = "0" + horaact;
    }

    if (minact.toString().length <= 1) {
        minact = "0" + minact;
    }
    var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate()+"-"+horaact + ":" + minact;
    let nombrearchivo=unidadcodigo2+"-PersImport-"+date+".mdb";
    setNombreArchivo(nombrearchivo);
    data.append('Pers', value.rawFile, nombrearchivo);
    fetch(urlAppjs +'/guardarArchivoPERS/', {
        method: 'POST',
        body: data
    })
    .then(res=> res.json())
    .then(datos => {
        //console.log(datos)
    })
}
}
let idproyecto=localStorage.getItem("id_project2");
let idproyecto2=localStorage.getItem("id_project");
var Hoy = new Date();
var fecha_actual = Hoy.getFullYear()+'-'+(Hoy.getMonth()+1)+'-'+Hoy.getDate();
return(
    <PimsCreate  {...props}>
        <SimpleForm submitOnEnter={false} redirect="list" > 
            <ReferenceInput label="resources.pers_imports.fields.unidadFuncional" source="unidadFuncional" reference="unidad_funcionals" 
                filterToQuery={searchText => ({ codigo: searchText })} 
                sort={{ field: 'codigo', order: 'asc' }} 
                formClassName={classes.grid_cont4_f}
                validate={[required(), ValidarUnidad]}
                fullWidth
                >
                <AutocompleteInput  options={{ fullWidth: true }} optionText="fullCodigos" fullWidth/>
            </ReferenceInput>
            <Query type='GET_ONE' resource='unidad_funcionals' payload={{ id: unidads }} formClassName={classes.ocultar}>
                {({ data, loading, error }) => {
                    if(data){
                        setUnidadcodigo(data.fullCode);
                        setUnidadcodigo2(data.codigo)
                    }
                    return(
                        <div/>
                    )
                }}
            </Query>
            {unidadcodigo &&
            <ReferenceInput label="resources.pers_imports.fields.persExport" source="persExport" reference="pers_exports" 
                    filterToQuery={searchText => ({ unidad_funcional: searchText })} 
                    sort={{ field: 'unidad_funcional', order: 'asc' }} 
                    formClassName={classes.grid_cont4_s}
                    validate={[required(), validarreferencia]}
                    filter={{ unidad_funcional: unidadcodigo }}
                    fullWidth
                    >
                <AutocompleteInput  options={{ fullWidth: true }} optionText="nombreImport" fullWidth/>
            </ReferenceInput>
            }
            {unidadcodigo &&
            <Query type='GET_ONE' resource='pers_exports' payload={{ id: referencia }} formClassName={classes.ocultar}>
                {({ data, loading, error }) => {
                    if(data){
                        return(
                            <TextInput source="documentoref" defaultValue={data.document}/>
                        )
                    }else{
                        return(
                            <div/>
                        )
                    }
                }}
            </Query>
            }
            <DateInput label="resources.pers_imports.fields.fecha" id="datefield" source="fecha" formClassName={classes.grid_cont4_s} fullWidth defaultValue={fecha_actual} disabled/>
            <Typography component="b" variant="b" formClassName={classes.grid_cont12} fullWidth>{translate('resources.pers_imports.fields.cargarArchivo')}</Typography>
            <FileInput source="archivoCarga" label="resources.pers_imports.fields.archivoCarga" accept=".mdb" multiple={false} validate={[required(), ValidacionArchivo]}>
                <FileField source="src" title="title" />
            </FileInput>
            <TextInput source="proyecto" defaultValue={idproyecto} style={{display: "none"}}/>
            <TextInput source="proyectoId" defaultValue={idproyecto2} style={{display: "none"}}/>
            <TextInput source="unidadId" defaultValue={unidads.replace("/unidad_funcionals/", "")} style={{display: "none"}}/>
            <TextInput source="unidadCodigo" defaultValue={unidadcodigo2} style={{display: "none"}}/>
            <TextInput source="nombrearchivo" defaultValue={namearchivo} style={{display: "none"}}/>
            <TextInput source="persExportid" defaultValue={referencia} style={{display: "none"}}/>
        </SimpleForm>
    </PimsCreate>
);
}));