import React, { useState, Fragment } from 'react';
import {
    NumberInput, SelectInput, ReferenceInput, TextInput, FormDataConsumer, ReferenceArrayInput, AutocompleteInput,
    AutocompleteArrayInput, required, minLength, maxLength, minValue, maxValue, email, number, REDUX_FORM_NAME
} from 'react-admin';
import { change } from 'redux-form';
import { PimsCreate } from './../Create';
import { SimpleForm } from './../SimpleForm';

import Grid from '@material-ui/core/Grid';
import { translate } from 'react-admin';



export const Contacto_emergenciaCreate = translate((props) => {
    const { translate } = props;
    const [departamentoss, setDepartamento] = useState('');

    const ValidarDepartamento = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setDepartamento(value)
    }

    return (
        <PimsCreate {...props}>
            <SimpleForm submitOnEnter={false} redirect="list">
                <Grid container spacing={24}>
                    <Grid item xs={4}>
                        <ReferenceInput label="resources.contacto_emergencias.fields.proyecto" source="proyecto" reference="proyectos" validate={[required()]} defaultValue={window.localStorage.getItem('id_project2')}>
                            <SelectInput optionText="nombre" disabled fullWidth />
                        </ReferenceInput>
                    </Grid>
                    <Grid item xs={4}>
                        <SelectInput id="grupoIngreso" label="resources.contacto_emergencias.fields.grupo" source="grupo" allowEmpty emptyValue="" fullWidth choices={[
                            { id: 'Alcaldía', name: 'dynatest.resources.contacto_emergencias.select.grupo.alcaldia' },
                            { id: 'Bomberos', name: 'dynatest.resources.contacto_emergencias.select.grupo.bomberos' },
                            { id: 'Centro educativo', name: 'dynatest.resources.contacto_emergencias.select.grupo.centroEducativo' },
                            { id: 'Defensa civil', name: 'dynatest.resources.contacto_emergencias.select.grupo.defensaCivil' },
                            { id: 'Ejército', name: 'dynatest.resources.contacto_emergencias.select.grupo.ejercito' },
                            { id: 'Emisora radial', name: 'dynatest.resources.contacto_emergencias.select.grupo.emisoraRadial' },
                            { id: 'Interventoría', name: 'dynatest.resources.contacto_emergencias.select.grupo.interventoria' },
                            { id: 'Maquinaria', name: 'dynatest.resources.contacto_emergencias.select.grupo.maquinaria' },
                            { id: 'Otros', name: 'dynatest.resources.contacto_emergencias.select.grupo.otros' },
                            { id: 'POB-Administrativa', name: 'dynatest.resources.contacto_emergencias.select.grupo.pOB_Administrativa' },
                            { id: 'POB-Ambulancia', name: 'dynatest.resources.contacto_emergencias.select.grupo.pOB_Ambulancia' },
                            { id: 'POB-Carro taller', name: 'dynatest.resources.contacto_emergencias.select.grupo.pOB_CarroTaller' },
                            { id: 'POB-Grúas', name: 'dynatest.resources.contacto_emergencias.select.grupo.pOB_Gruas' },
                            { id: 'POB-Peajes', name: 'dynatest.resources.contacto_emergencias.select.grupo.pOB_Peajes' },
                            { id: 'POB-Radio Operación', name: 'dynatest.resources.contacto_emergencias.select.grupo.pOB_RadioOperacion' },
                            { id: 'POB-Supervisión vial', name: 'dynatest.resources.contacto_emergencias.select.grupo.pOB_SupervisionVial' },
                            { id: 'Policía', name: 'dynatest.resources.contacto_emergencias.select.grupo.policia' },
                            { id: 'Servicios médicos', name: 'dynatest.resources.contacto_emergencias.select.grupo.serviciosMedicos' },
                            { id: 'Talleres', name: 'dynatest.resources.contacto_emergencias.select.grupo.talleres' },
                        ]} validate={[required()]} />

                    </Grid>
                    <Grid item xs={4}>
                        <FormDataConsumer>
                            {
                                ({ formData, ...rest }) => (formData != null && formData.grupo === 'Servicios médicos' &&
                                    <NumberInput source="nivel" label="resources.contacto_emergencias.fields.nivel" fullWidth validate={[minValue(1), maxValue(5)]} />
                                )
                            }
                        </FormDataConsumer>
                    </Grid>

                    <Grid item xs={4}>
                        <SelectInput label="resources.contacto_emergencias.fields.identificacion" source="identificacion" allowEmpty emptyValue="" validate={[required()]} fullWidth choices={[
                            { id: 'CC', name: 'dynatest.resources.contacto_emergencias.select.identificacion.CC' },
                            { id: 'NIT', name: 'dynatest.resources.contacto_emergencias.select.identificacion.NIT' },
                            { id: 'Otro', name: 'dynatest.resources.contacto_emergencias.select.identificacion.Otro' },

                        ]} />
                    </Grid>
                    <Grid item xs={4}>
                        <NumberInput label="resources.contacto_emergencias.fields.numero_identificacion" source="numero_identificacion" fullWidth validate={[required(), minLength(2), maxLength(10), minValue(11111), maxValue(99999999999)]} />
                    </Grid>
                    <Grid item xs={4}>
                        <TextInput label="resources.contacto_emergencias.fields.empresa" source="empresa" fullWidth validate={[required(), minLength(2), maxLength(100)]} />
                    </Grid>
                    <Grid item xs={4}>
                        <TextInput label="resources.contacto_emergencias.fields.nombre" source="nombre" fullWidth validate={[minLength(2), maxLength(100)]} />
                    </Grid>
                    <Grid item xs={4}>
                        <TextInput label="resources.contacto_emergencias.fields.apellido" source="apellido" fullWidth validate={[minLength(2), maxLength(100)]} />
                    </Grid>
                    <Grid item xs={4}>
                        <TextInput label="resources.contacto_emergencias.fields.cargo" source="cargo" fullWidth validate={[minLength(1), maxLength(20)]} />
                    </Grid>
                    <Grid item xs={4}>
                        <TextInput label="resources.contacto_emergencias.fields.direccion" source="direccion" fullWidth validate={[required(), minLength(2), maxLength(100)]} />
                    </Grid>
                    {/* <Grid item xs={4}>
                    <ReferenceInput 
                        label="Departamento" 
                        source="departamento" 
                        reference="departamentos" 
                        filterToQuery={searchText => ({ nombre: searchText })} 
                        validate={[required(), ValidarDepartamento]}
                    >
                        <AutocompleteInput  optionText="nombre" fullWidth />
                    </ReferenceInput>
                </Grid> */}
                    <Grid item xs={8}>
                        <FormDataConsumer>
                            {
                                ({ formData, dispatch, ...rest }) => (
                                    <Fragment>
                                        <Grid container spacing={24}>
                                            <Grid item xs={6}>
                                                <ReferenceInput
                                                    id="departamento"
                                                    value=""
                                                    label="resources.contacto_emergencias.fields.departamento"
                                                    source="departamento"
                                                    reference="departamentos"
                                                    fullWidth
                                                    sort={{ field: "nombre", order: "ASC" }}
                                                    filterToQuery={searchText => ({ nombre: searchText })}
                                                    onChange={value => dispatch(
                                                        change(REDUX_FORM_NAME, 'municipio', null)
                                                    )}
                                                    validate={[required(), ValidarDepartamento]}
                                                >
                                                    <AutocompleteInput options={{ fullWidth: true }} optionText="nombre" fullWidth />
                                                </ReferenceInput>
                                            </Grid>
                                            <Grid item xs={6}>
                                                {/* MUNICIPIOS POR DEPARTAMENTO */}
                                                {
                                                    departamentoss === '/departamentos/05' && (
                                                        <ReferenceArrayInput 
                                                            label="resources.contacto_emergencias.fields.municipio1" 
                                                            source="municipio1" 
                                                            reference="municipios" 
                                                            sort={{ field: "nombre", order: "ASC" }} 
                                                            validate={[required()]} 
                                                            filter={{ departamento: '/departamentos/05' }} 
                                                            filterToQuery={searchText => ({ nombre: searchText })} 
                                                            fullWidth>
                                                            <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                                        </ReferenceArrayInput>
                                                    )
                                                }
                                                {
                                                    departamentoss === '/departamentos/08' && (
                                                        <ReferenceArrayInput 
                                                            label="resources.contacto_emergencias.fields.municipio2" 
                                                            source="municipio2" 
                                                            reference="municipios" 
                                                            sort={{ field: "nombre", order: "ASC" }} 
                                                            validate={[required()]} 
                                                            filter={{ departamento: '/departamentos/08' }} 
                                                            filterToQuery={searchText => ({ nombre: searchText })} 
                                                            fullWidth>
                                                            <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                                        </ReferenceArrayInput>
                                                    )
                                                }
                                                {
                                                    departamentoss === '/departamentos/11' && (
                                                        <ReferenceArrayInput 
                                                            label="resources.contacto_emergencias.fields.municipio3" 
                                                            source="municipio3" 
                                                            reference="municipios" 
                                                            sort={{ field: "nombre", order: "ASC" }} 
                                                            validate={[required()]} 
                                                            filter={{ departamento: '/departamentos/11' }} 
                                                            filterToQuery={searchText => ({ nombre: searchText })} 
                                                            fullWidth>
                                                            <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                                        </ReferenceArrayInput>
                                                    )
                                                }
                                                {
                                                    departamentoss === '/departamentos/13' && (
                                                        <ReferenceArrayInput 
                                                            label="resources.contacto_emergencias.fields.municipio4" 
                                                            source="municipio4" 
                                                            reference="municipios" 
                                                            sort={{ field: "nombre", order: "ASC" }} 
                                                            validate={[required()]} 
                                                            filter={{ departamento: '/departamentos/13' }} 
                                                            filterToQuery={searchText => ({ nombre: searchText })} 
                                                            fullWidth>
                                                            <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                                        </ReferenceArrayInput>
                                                    )
                                                }
                                                {
                                                    departamentoss === '/departamentos/15' && (
                                                        <ReferenceArrayInput 
                                                            label="resources.contacto_emergencias.fields.municipio5" 
                                                            source="municipio5" 
                                                            reference="municipios" 
                                                            sort={{ field: "nombre", order: "ASC" }} 
                                                            validate={[required()]} 
                                                            filter={{ departamento: '/departamentos/15' }} 
                                                            filterToQuery={searchText => ({ nombre: searchText })} 
                                                            fullWidth>
                                                            <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                                        </ReferenceArrayInput>
                                                    )
                                                }
                                                {
                                                    departamentoss === '/departamentos/17' && (
                                                        <ReferenceArrayInput 
                                                            label="resources.contacto_emergencias.fields.municipio6" 
                                                            source="municipio6" 
                                                            reference="municipios" 
                                                            sort={{ field: "nombre", order: "ASC" }} 
                                                            validate={[required()]} 
                                                            filter={{ departamento: '/departamentos/17' }} 
                                                            filterToQuery={searchText => ({ nombre: searchText })} 
                                                            fullWidth>
                                                            <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                                        </ReferenceArrayInput>
                                                    )
                                                }
                                                {
                                                    departamentoss === '/departamentos/18' && (
                                                        <ReferenceArrayInput 
                                                            label="resources.contacto_emergencias.fields.municipio7" 
                                                            source="municipio7" 
                                                            reference="municipios" 
                                                            sort={{ field: "nombre", order: "ASC" }} 
                                                            validate={[required()]} 
                                                            filter={{ departamento: '/departamentos/18' }} 
                                                            filterToQuery={searchText => ({ nombre: searchText })} 
                                                            fullWidth>
                                                            <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                                        </ReferenceArrayInput>
                                                    )
                                                }
                                                {
                                                    departamentoss === '/departamentos/19' && (
                                                        <ReferenceArrayInput 
                                                            label="resources.contacto_emergencias.fields.municipio8" 
                                                            source="municipio8" 
                                                            reference="municipios" 
                                                            sort={{ field: "nombre", order: "ASC" }} 
                                                            validate={[required()]} 
                                                            filter={{ departamento: '/departamentos/19' }} 
                                                            filterToQuery={searchText => ({ nombre: searchText })} 
                                                            fullWidth>
                                                            <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                                        </ReferenceArrayInput>
                                                    )
                                                }
                                                {
                                                    departamentoss === '/departamentos/20' && (
                                                        <ReferenceArrayInput 
                                                            label="resources.contacto_emergencias.fields.municipio9" 
                                                            source="municipio9" 
                                                            reference="municipios" 
                                                            sort={{ field: "nombre", order: "ASC" }} 
                                                            validate={[required()]} 
                                                            filter={{ departamento: '/departamentos/20' }} 
                                                            filterToQuery={searchText => ({ nombre: searchText })} 
                                                            fullWidth>
                                                            <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                                        </ReferenceArrayInput>
                                                    )
                                                }
                                                {
                                                    departamentoss === '/departamentos/23' && (
                                                        <ReferenceArrayInput 
                                                            label="resources.contacto_emergencias.fields.municipio10" 
                                                            source="municipio10" 
                                                            reference="municipios" 
                                                            sort={{ field: "nombre", order: "ASC" }} 
                                                            validate={[required()]} 
                                                            filter={{ departamento: '/departamentos/23' }} 
                                                            filterToQuery={searchText => ({ nombre: searchText })} 
                                                            fullWidth>
                                                            <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                                        </ReferenceArrayInput>
                                                    )
                                                }
                                                {
                                                    departamentoss === '/departamentos/25' && (
                                                        <ReferenceArrayInput 
                                                            label="resources.contacto_emergencias.fields.municipio11" 
                                                            source="municipio11" 
                                                            reference="municipios" 
                                                            sort={{ field: "nombre", order: "ASC" }} 
                                                            validate={[required()]} 
                                                            filter={{ departamento: '/departamentos/25' }} 
                                                            filterToQuery={searchText => ({ nombre: searchText })} 
                                                            fullWidth>
                                                            <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                                        </ReferenceArrayInput>
                                                    )
                                                }
                                                {
                                                    departamentoss === '/departamentos/27' && (
                                                        <ReferenceArrayInput 
                                                            label="resources.contacto_emergencias.fields.municipio12" 
                                                            source="municipio12"
                                                            reference="municipios" 
                                                            sort={{ field: "nombre", order: "ASC" }} 
                                                            validate={[required()]} 
                                                            filter={{ departamento: '/departamentos/27' }} 
                                                            filterToQuery={searchText => ({ nombre: searchText })} 
                                                            fullWidth>
                                                            <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                                        </ReferenceArrayInput>
                                                    )
                                                }
                                                {
                                                    departamentoss === '/departamentos/41' && (
                                                        <ReferenceArrayInput 
                                                            label="resources.contacto_emergencias.fields.municipio13" 
                                                            source="municipio13" 
                                                            reference="municipios" 
                                                            sort={{ field: "nombre", order: "ASC" }} 
                                                            validate={[required()]} 
                                                            filter={{ departamento: '/departamentos/41' }} 
                                                            filterToQuery={searchText => ({ nombre: searchText })} 
                                                            fullWidth>
                                                            <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                                        </ReferenceArrayInput>
                                                    )
                                                }
                                                {
                                                    departamentoss === '/departamentos/44' && (
                                                        <ReferenceArrayInput 
                                                            label="resources.contacto_emergencias.fields.municipio14" 
                                                            source="municipio14" 
                                                            reference="municipios" 
                                                            sort={{ field: "nombre", order: "ASC" }} 
                                                            validate={[required()]} 
                                                            filter={{ departamento: '/departamentos/44' }} 
                                                            filterToQuery={searchText => ({ nombre: searchText })} 
                                                            fullWidth>
                                                            <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                                        </ReferenceArrayInput>
                                                    )
                                                }
                                                {
                                                    departamentoss === '/departamentos/47' && (
                                                        <ReferenceArrayInput 
                                                            label="resources.contacto_emergencias.fields.municipio15" 
                                                            source="municipio15" 
                                                            reference="municipios" 
                                                            sort={{ field: "nombre", order: "ASC" }} 
                                                            validate={[required()]} 
                                                            filter={{ departamento: '/departamentos/47' }} 
                                                            filterToQuery={searchText => ({ nombre: searchText })} 
                                                            fullWidth>
                                                            <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                                        </ReferenceArrayInput>
                                                    )
                                                }
                                                {
                                                    departamentoss === '/departamentos/50' && (
                                                        <ReferenceArrayInput 
                                                            label="resources.contacto_emergencias.fields.municipio16" 
                                                            source="municipio16" 
                                                            reference="municipios" 
                                                            sort={{ field: "nombre", order: "ASC" }} 
                                                            validate={[required()]} 
                                                            filter={{ departamento: '/departamentos/50' }} 
                                                            filterToQuery={searchText => ({ nombre: searchText })} 
                                                            fullWidth>
                                                            <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                                        </ReferenceArrayInput>
                                                    )
                                                }
                                                {
                                                    departamentoss === '/departamentos/52' && (
                                                        <ReferenceArrayInput 
                                                            label="resources.contacto_emergencias.fields.municipio17" 
                                                            source="municipio17" 
                                                            reference="municipios" 
                                                            sort={{ field: "nombre", order: "ASC" }} 
                                                            validate={[required()]} 
                                                            filter={{ departamento: '/departamentos/52' }} 
                                                            filterToQuery={searchText => ({ nombre: searchText })} 
                                                            fullWidth>
                                                            <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                                        </ReferenceArrayInput>
                                                    )
                                                }
                                                {
                                                    departamentoss === '/departamentos/54' && (
                                                        <ReferenceArrayInput 
                                                            label="resources.contacto_emergencias.fields.municipio18" 
                                                            source="municipio18" 
                                                            reference="municipios" 
                                                            sort={{ field: "nombre", order: "ASC" }} 
                                                            validate={[required()]} 
                                                            filter={{ departamento: '/departamentos/54' }} 
                                                            filterToQuery={searchText => ({ nombre: searchText })} 
                                                            fullWidth>
                                                            <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                                        </ReferenceArrayInput>
                                                    )
                                                }
                                                {
                                                    departamentoss === '/departamentos/63' && (
                                                        <ReferenceArrayInput 
                                                            label="resources.contacto_emergencias.fields.municipio19" 
                                                            source="municipio19" 
                                                            reference="municipios" 
                                                            sort={{ field: "nombre", order: "ASC" }} 
                                                            validate={[required()]} 
                                                            filter={{ departamento: '/departamentos/63' }} 
                                                            filterToQuery={searchText => ({ nombre: searchText })} 
                                                            fullWidth>
                                                            <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                                        </ReferenceArrayInput>
                                                    )
                                                }
                                                {
                                                    departamentoss === '/departamentos/66' && (
                                                        <ReferenceArrayInput 
                                                            label="resources.contacto_emergencias.fields.municipio20" 
                                                            source="municipio20" 
                                                            reference="municipios" 
                                                            sort={{ field: "nombre", order: "ASC" }} 
                                                            validate={[required()]} 
                                                            filter={{ departamento: '/departamentos/66' }} 
                                                            filterToQuery={searchText => ({ nombre: searchText })} 
                                                            fullWidth>
                                                            <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                                        </ReferenceArrayInput>
                                                    )
                                                }
                                                {
                                                    departamentoss === '/departamentos/68' && (
                                                        <ReferenceArrayInput 
                                                            label="resources.contacto_emergencias.fields.municipio21" 
                                                            source="municipio21"
                                                            reference="municipios" 
                                                            sort={{ field: "nombre", order: "ASC" }} 
                                                            validate={[required()]} 
                                                            filter={{ departamento: '/departamentos/68' }} 
                                                            filterToQuery={searchText => ({ nombre: searchText })} 
                                                            fullWidth>
                                                            <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                                        </ReferenceArrayInput>
                                                    )
                                                }
                                                {
                                                    departamentoss === '/departamentos/70' && (
                                                        <ReferenceArrayInput 
                                                            label="resources.contacto_emergencias.fields.municipio22" 
                                                            source="municipio22" 
                                                            reference="municipios" 
                                                            sort={{ field: "nombre", order: "ASC" }} 
                                                            validate={[required()]} 
                                                            filter={{ departamento: '/departamentos/70' }} 
                                                            filterToQuery={searchText => ({ nombre: searchText })} 
                                                            fullWidth>
                                                            <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                                        </ReferenceArrayInput>
                                                    )
                                                }
                                                {
                                                    departamentoss === '/departamentos/73' && (
                                                        <ReferenceArrayInput 
                                                            label="resources.contacto_emergencias.fields.municipio23" 
                                                            source="municipio23" 
                                                            reference="municipios" 
                                                            sort={{ field: "nombre", order: "ASC" }} 
                                                            validate={[required()]} 
                                                            filter={{ departamento: '/departamentos/73' }} 
                                                            filterToQuery={searchText => ({ nombre: searchText })} 
                                                            fullWidth>
                                                            <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                                        </ReferenceArrayInput>
                                                    )
                                                }
                                                {
                                                    departamentoss === '/departamentos/76' && (
                                                        <ReferenceArrayInput 
                                                            label="resources.contacto_emergencias.fields.municipio24" 
                                                            source="municipio24" 
                                                            reference="municipios" 
                                                            sort={{ field: "nombre", order: "ASC" }} 
                                                            validate={[required()]} 
                                                            filter={{ departamento: '/departamentos/76' }} 
                                                            filterToQuery={searchText => ({ nombre: searchText })} 
                                                            fullWidth>
                                                            <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                                        </ReferenceArrayInput>
                                                    )
                                                }
                                                {
                                                    departamentoss === '/departamentos/81' && (
                                                        <ReferenceArrayInput 
                                                            label="resources.contacto_emergencias.fields.municipio25" 
                                                            source="municipio25" 
                                                            reference="municipios" 
                                                            sort={{ field: "nombre", order: "ASC" }} 
                                                            validate={[required()]} 
                                                            filter={{ departamento: '/departamentos/81' }} 
                                                            filterToQuery={searchText => ({ nombre: searchText })} 
                                                            fullWidth>
                                                            <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                                        </ReferenceArrayInput>
                                                    )
                                                }
                                                {
                                                    departamentoss === '/departamentos/85' && (
                                                        <ReferenceArrayInput 
                                                            label="resources.contacto_emergencias.fields.municipio26" 
                                                            source="municipio26" 
                                                            reference="municipios" 
                                                            sort={{ field: "nombre", order: "ASC" }} 
                                                            validate={[required()]} 
                                                            filter={{ departamento: '/departamentos/85' }} 
                                                            filterToQuery={searchText => ({ nombre: searchText })} 
                                                            fullWidth>
                                                            <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                                        </ReferenceArrayInput>
                                                    )
                                                }
                                                {
                                                    departamentoss === '/departamentos/86' && (
                                                        <ReferenceArrayInput 
                                                            label="resources.contacto_emergencias.fields.municipio27" 
                                                            source="municipio27" 
                                                            reference="municipios" 
                                                            sort={{ field: "nombre", order: "ASC" }} 
                                                            validate={[required()]} 
                                                            filter={{ departamento: '/departamentos/86' }} 
                                                            filterToQuery={searchText => ({ nombre: searchText })} 
                                                            fullWidth>
                                                            <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                                        </ReferenceArrayInput>
                                                    )
                                                }
                                                {
                                                    departamentoss === '/departamentos/88' && (
                                                        <ReferenceArrayInput 
                                                            label="resources.contacto_emergencias.fields.municipio28" 
                                                            source="municipio28" 
                                                            reference="municipios" 
                                                            sort={{ field: "nombre", order: "ASC" }} 
                                                            validate={[required()]} 
                                                            filter={{ departamento: '/departamentos/88' }} 
                                                            filterToQuery={searchText => ({ nombre: searchText })} 
                                                            fullWidth>
                                                            <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                                        </ReferenceArrayInput>
                                                    )
                                                }
                                                {
                                                    departamentoss === '/departamentos/91' && (
                                                        <ReferenceArrayInput 
                                                            label="resources.contacto_emergencias.fields.municipio29" 
                                                            source="municipio29" 
                                                            reference="municipios" 
                                                            sort={{ field: "nombre", order: "ASC" }} 
                                                            validate={[required()]} 
                                                            filter={{ departamento: '/departamentos/91' }} 
                                                            filterToQuery={searchText => ({ nombre: searchText })} 
                                                            fullWidth>
                                                            <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                                        </ReferenceArrayInput>
                                                    )
                                                }
                                                {
                                                    departamentoss === '/departamentos/94' && (
                                                        <ReferenceArrayInput 
                                                            label="resources.contacto_emergencias.fields.municipio30" 
                                                            source="municipio30" 
                                                            reference="municipios" 
                                                            sort={{ field: "nombre", order: "ASC" }} 
                                                            validate={[required()]} 
                                                            filter={{ departamento: '/departamentos/94' }} 
                                                            filterToQuery={searchText => ({ nombre: searchText })} 
                                                            fullWidth>
                                                            <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                                        </ReferenceArrayInput>
                                                    )
                                                }
                                                {
                                                    departamentoss === '/departamentos/95' && (
                                                        <ReferenceArrayInput 
                                                            label="resources.contacto_emergencias.fields.municipio31" 
                                                            source="municipio31" 
                                                            reference="municipios" 
                                                            sort={{ field: "nombre", order: "ASC" }} 
                                                            validate={[required()]} 
                                                            filter={{ departamento: '/departamentos/95' }} 
                                                            filterToQuery={searchText => ({ nombre: searchText })} 
                                                            fullWidth>
                                                            <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                                        </ReferenceArrayInput>
                                                    )
                                                }
                                                {
                                                    departamentoss === '/departamentos/97' && (
                                                        <ReferenceArrayInput 
                                                            label="resources.contacto_emergencias.fields.municipio32" 
                                                            source="municipio32" 
                                                            reference="municipios" 
                                                            sort={{ field: "nombre", order: "ASC" }} 
                                                            validate={[required()]} 
                                                            filter={{ departamento: '/departamentos/97' }} 
                                                            filterToQuery={searchText => ({ nombre: searchText })} 
                                                            fullWidth>
                                                            <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                                        </ReferenceArrayInput>
                                                    )
                                                }
                                                {
                                                    departamentoss === '/departamentos/99' && (
                                                        <ReferenceArrayInput 
                                                            label="resources.contacto_emergencias.fields.municipio33" 
                                                            source="municipio33" 
                                                            reference="municipios" 
                                                            sort={{ field: "nombre", order: "ASC" }} 
                                                            validate={[required()]} 
                                                            filter={{ departamento: '/departamentos/99' }} 
                                                            filterToQuery={searchText => ({ nombre: searchText })} 
                                                            fullWidth>
                                                            <AutocompleteArrayInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                                                        </ReferenceArrayInput>
                                                    )
                                                }
                                            </Grid>
                                        </Grid>
                                    </Fragment>
                                )
                            }
                        </FormDataConsumer>
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput label="resources.contacto_emergencias.fields.telefono_1" source="telefono_1" fullWidth className="labelNumber" validate={[required(), number("Solo Números")]} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput label="resources.contacto_emergencias.fields.telefono_2" source="telefono_2" fullWidth className="labelNumber" validate={[number("Solo Números")]} />
                    </Grid>


                    <Grid item xs={12}>
                        <TextInput label="resources.contacto_emergencias.fields.correo" source="correo" fullWidth validate={[minLength(2), email(), maxLength(100, "No mas de 100 caracteres")]} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput label="resources.contacto_emergencias.fields.observaciones" source="observaciones" fullWidth validate={[minLength(2), maxLength(200, "No mas de 200 caracteres")]} />
                    </Grid>

                </Grid>
            </SimpleForm>
        </PimsCreate>
    )
});