import React from 'react';
import pFormatter from './../format/PFormat';

import { TextInput } from 'react-admin';


export const PInput = (props) => (
    <TextInput 
        {...props} 
        InputProps={{
            inputComponent: pFormatter,
        }}
    />
);

