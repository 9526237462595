import React from 'react';
import { TextField, ReferenceField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import config from '../../../config/config';
import loader from '../../../loader10.gif';
import { translate } from 'react-admin';
let urlAppjs = config.UrlServer;

class VisualizacionModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = { datosCarga: [], parametros: [], estado: 1, totalLum: 0 }
        this.calculoAreaAfectada.bind(this);
    }
    componentDidMount() {
        let idproyecto = localStorage.getItem('id_project');
        fetch(urlAppjs + "/parametros_grupos?proyecto=" + idproyecto)
            .then((response) => {
                return response.json()
            })
            .then((response) => {
                let resultado = [];
                if (response) {
                    resultado = response['hydra:member'][0]
                    this.setState({ parametros: resultado })
                }

            })
    }
    componentWillReceiveProps() {
        this.setState({ datosCarga: [] })
        this.setState({ estado: 1 })
        fetch(urlAppjs + "/buscarIndicadoresVisualizacion/" + this.props.ufuncional + "/" + this.props.indicador + "/" + this.props.tramo)
            .then((response) => {
                return response.json()
            })
            .then((response) => {
                this.setState({
                    datosCarga: response.resultado,
                    totalLum: response.totalLumInventario ? response.totalLumInventario : 0,
                    estado: response.cantidad <= 0 ? 0 : 1
                })
            })
    }
    formatoabscisa(valor) {
        if (this.props.indicador === "1" || this.props.indicador === "16") {
            let valor2 = valor - parseInt(valor);
            valor2 = parseFloat(valor2).toFixed(1);
            return "K" + parseInt(valor) + "+" + valor2.replace("0.", "") + "00";
        }
        else if (this.props.indicador === "2" || this.props.indicador === "4" || this.props.indicador === "5") {
            let valor2 = valor - parseInt(valor);
            valor2 = parseFloat(valor2).toFixed(2);
            return "K" + parseInt(valor) + "+" + valor2.replace("0.", "") + "0";
        }
        else {
            let valor2 = valor - parseInt(valor);
            valor2 = parseFloat(valor2).toFixed(3);
            return "K" + parseInt(valor) + "+" + valor2.replace("0.", "");
        }
    }
    formatovalores(valor, decimal) {
        let value = parseFloat(valor).toFixed(decimal).replace(".", ",");
        return value;
    }
    ColorPCI(valor) {
        if (valor >= 86) {
            return "rgb(0, 176, 80)"
        } else if (valor >= 71 && valor < 86) {
            return "rgb(146, 208, 80)"
        } else if (valor >= 56 && valor < 71) {
            return "rgb(255, 255, 0)"
        } else if (valor >= 41 && valor < 56) {
            return "rgb(255, 0, 0)"
        } else if (valor >= 26 && valor < 41) {
            return "rgb(192, 0, 0)"
        } else if (valor >= 11 && valor < 26) {
            return "rgb(148, 54, 52)"
        } else if (valor >= 0 && valor < 11) {
            return "rgb(166, 166, 166)"
        }
    }
    calculoAreaAfectada(baseEnSegmento, registradaEnIndicador) {
        // console.log(baseEnSegmento)
        // console.log(registradaEnIndicador)
        return (parseFloat(registradaEnIndicador) / parseFloat(baseEnSegmento)) * 100;
    }
    ultimaFecha(fechas) {
        if (fechas.length > 0) {
            let dates = fechas.map(fecha => new Date(fecha))
            let fechaMax = new Date(Math.max.apply(null, dates));
            return fechaMax.getFullYear() + "-" + (('0' + (fechaMax.getMonth() + 1)).slice(-2)) + "-" + ('0' + fechaMax.getDate()).slice(-2);
        } else {
            return
        }
    }
    abscisasEspeciales(datos){
        let ultimafecha=[]
        let nuevosDatos=datos;
        datos.map(dato=>{
            let revision=false;
            revision=ultimafecha.find(objeto=>{
                if(dato.tipoMedicion==="Periódica"){
                    return objeto.unidadFuncional===dato.uf_codigo
                    && objeto.abInicial===parseFloat(dato.abInicial)
                    && objeto.abFinal===parseFloat(dato.abFinal)
                    && objeto.tipoMedicion===dato.tipoMedicion
                }else{
                    return objeto.unidadFuncional===dato.uf_codigo
                    && objeto.abInicial===parseFloat(dato.abiniC)
                    && objeto.abFinal===parseFloat(dato.abfinC)
                    && objeto.tipoMedicion===dato.tipoMedicion
                }
            })
            //console.log(revision)
            if(revision){
                if(new Date(revision.fecha) <new Date(dato.fecha)){
                    ultimafecha=ultimafecha.filter(objeto=>objeto!=revision)
                    ultimafecha.push({
                        "unidadFuncional":dato.uf_codigo,
                        "abInicial": dato.tipoMedicion==="Periódica"?parseFloat(dato.abInicial):parseFloat(dato.abiniC),
                        "abFinal": dato.tipoMedicion==="Periódica"?parseFloat(dato.abFinal):parseFloat(dato.abfinC),
                        "tipoMedicion": dato.tipoMedicion,
                        "fecha": dato.fecha,
                    })
                }
            }else{
                ultimafecha.push({
                    "unidadFuncional":dato.uf_codigo,
                    "abInicial": dato.tipoMedicion==="Periódica"?parseFloat(dato.abInicial):parseFloat(dato.abiniC),
                    "abFinal": dato.tipoMedicion==="Periódica"?parseFloat(dato.abFinal):parseFloat(dato.abfinC),
                    "tipoMedicion": dato.tipoMedicion,
                    "fecha": dato.fecha,
                })
            }
        });
        for(let dato of datos){
            let revision=false;
            revision=ultimafecha.filter(objeto=>parseFloat(dato.abscisa)>=objeto.abInicial && parseFloat(dato.abscisa)<=objeto.abFinal)
            if(revision.length>0){
                revision=revision.reduce((ant,prox)=>{
                    if(new Date(prox.fecha)>new Date(ant.fecha)){
                    return prox
                    }else{
                    return ant
                    }
                })
                if(new Date(dato.fecha)<new Date(revision.fecha)){
                    nuevosDatos=nuevosDatos.filter(objeto=>objeto!=dato)
                }
            }
        }
        return nuevosDatos;
    }
    render() {
        const { translate } = this.props;
        const { datosCarga } = this.state;
        const { parametros } = this.state;
        const { estado } = this.state;
        let indicador = this.props.indicador;
        let tramo = this.props.tramo;
        let ufunc = this.props.ufuncional;
        let valorpromedio = 3;
        let valorpuntual = 1;
        let indicadores = this.props.indicador;
        //console.log(datosCarga)
        if (parametros && parametros.evaluacion && parametros.evaluacion[0] && parametros.evaluacion[0].valormaxMedio) {
            if (this.props.indicador === "1") { valorpromedio = parametros.evaluacion[0].valormaxMedio; valorpuntual = parametros.evaluacion[0].valormaxPuntual; }
            else if (this.props.indicador === "2") { valorpromedio = parametros.evaluacion[1].valormaxMedio; valorpuntual = parametros.evaluacion[1].valormaxPuntual; }
            else if (this.props.indicador === "3") { valorpromedio = parametros.evaluacion[2].valormaxMedio; valorpuntual = parametros.evaluacion[2].valormaxPuntual; }
            else if (this.props.indicador === "4") { valorpromedio = parametros.evaluacion[3].valormaxMedio; valorpuntual = parametros.evaluacion[3].valormaxPuntual; }
            else if (this.props.indicador === "6") { valorpromedio = parametros.evaluacion[5].valormaxMedio; valorpuntual = parametros.evaluacion[5].valormaxPuntual; }
            else if (this.props.indicador === "7") { valorpromedio = parametros.evaluacion[6].valormaxMedio; valorpuntual = parametros.evaluacion[6].valormaxPuntual; }
            else if (this.props.indicador === "8") { valorpromedio = parametros.evaluacion[7].valormaxPuntual; valorpuntual = parametros.evaluacion[7].valormaxMedio; }
            else if (this.props.indicador === "9") { valorpromedio = parametros.evaluacion[8].valormaxPuntual; valorpuntual = parametros.evaluacion[8].valormaxMedio; }
            else if (this.props.indicador === "10") { valorpromedio = parametros.evaluacion[9].valormaxPuntual; valorpuntual = parametros.evaluacion[9].valormaxMedio; }
            else if (this.props.indicador === "11") { valorpromedio = parametros.evaluacion[10].valormaxPuntual; valorpuntual = parametros.evaluacion[10].valormaxMedio; }
            else if (this.props.indicador === "12") { valorpromedio = parametros.evaluacion[11].valormaxPuntual; valorpuntual = parametros.evaluacion[11].valormaxMedio; }
            else if (this.props.indicador === "13") { valorpromedio = parametros.evaluacion[12].valormaxPuntual; }
            else if (this.props.indicador === "21") { valorpromedio = parametros.evaluacion[20].valormaxMedio; valorpuntual = parametros.evaluacion[20].valormaxPuntual; }
            else if (this.props.indicador === "22") { valorpromedio = parametros.evaluacion[21].valormaxMedio; valorpuntual = parametros.evaluacion[21].valormaxPuntual; }
            else if (this.props.indicador === "23") { valorpromedio = parametros.evaluacion[21].valormaxMedio; valorpuntual = parametros.evaluacion[21].valormaxPuntual; }
        }
        if (indicador && tramo && ufunc && datosCarga && datosCarga.length > 0 && (indicadores === "1" || indicadores === "2" || indicadores === "4" || indicadores === "5")) {
            let registros = datosCarga;
            let segmentos = [];
            let promedioI = [];
            let promedioD = [];
            let promedioC = [];
            let promedioCalzada = [];
            let cumplimientoI = [];
            let cumplimientoD = [];
            let cumplimientoIndicador = [];
            let cumplimientoPromCalzada = []
            let cumplimientoPuntCalzada = []
            let aux = -1;
            let datosprueba = [];
            if (registros) {
                registros.map((data, i) => {
                    if (data.sg_codigo != aux) {
                        segmentos.push(data.sg_codigo);
                        aux = data.sg_codigo;
                    }
                })
                registros.map((data, i) => {
                    for (let i = 0; i < segmentos.length; i++) {
                        if (!datosprueba[i]) {
                            datosprueba[i] = {
                                iriCizq: [],
                                iriCder: [],
                                cumplimientoI: [],
                                cumplimientoD: [],
                                cumplimientoC: [],
                                abscisaini: [],
                                abscisafin: [],
                                eventoCizq: [],
                                singularidadCizq: [],
                                eventoCder: [],
                                singularidadCder: [],
                                fecha: [],
                                estado: [],
                                medidopor: []
                            };
                        }
                        if (data.sg_codigo === segmentos[i]) {
                            datosprueba[i]['iriCizq'].push(data.iricizq);
                            datosprueba[i]['iriCder'].push(data.iricder);
                            datosprueba[i]['cumplimientoI'].push(data.cumplimientoizq);
                            datosprueba[i]['cumplimientoD'].push(data.cumplimientocder);
                            datosprueba[i]['cumplimientoC'].push(data.cumpcal);
                            datosprueba[i]['abscisaini'].push(data.abscisa_inicial);
                            datosprueba[i]['abscisafin'].push(data.abscisa_final);
                            datosprueba[i]['eventoCizq'].push(data.eventoizq);
                            datosprueba[i]['singularidadCizq'].push(data.singizq);
                            datosprueba[i]['eventoCder'].push(data.eventocder);
                            datosprueba[i]['singularidadCder'].push(data.singcder);
                            datosprueba[i]['fecha'].push(data.fecha);
                            datosprueba[i]['estado'].push(data.estado);
                            datosprueba[i]['medidopor'].push(data.medido_por);
                        }
                    }
                })

                for (let i = 0; i < datosprueba.length; i++) {
                    let promedioIzq = 0;
                    let promedioDer = 0;
                    let cumplimientoder = 0;
                    let cumplimientoizq = 0;
                    let cumplimientoCal = 0;
                    for (let j = 0; j < datosprueba[i]['iriCizq'].length; j++) {
                        promedioIzq += parseFloat(datosprueba[i]['iriCizq'][j]);
                    }
                    for (let j = 0; j < datosprueba[i]['iriCder'].length; j++) {
                        promedioDer += parseFloat(datosprueba[i]['iriCder'][j]);
                    }
                    for (let j = 0; j < datosprueba[i]['cumplimientoI'].length; j++) {
                        if (datosprueba[i]['cumplimientoI'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')) {
                            cumplimientoizq++
                        }
                    }
                    for (let j = 0; j < datosprueba[i]['cumplimientoD'].length; j++) {
                        if (datosprueba[i]['cumplimientoD'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')) {
                            cumplimientoder++
                        }
                    }
                    for (let j = 0; j < datosprueba[i]['cumplimientoC'].length; j++) {
                        if (datosprueba[i]['cumplimientoC'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')) {
                            cumplimientoCal++
                        }
                    }
                    promedioI[i] = promedioIzq / (datosprueba[i]['iriCizq'].length);
                    promedioD[i] = promedioDer / (datosprueba[i]['iriCder'].length);
                    promedioCalzada[i] = Math.max(promedioI[i], promedioD[i]);
                    if (promedioCalzada[i] > valorpromedio) {
                        cumplimientoPromCalzada[i] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')
                    }
                    else {
                        cumplimientoPromCalzada[i] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')
                    }

                    cumplimientoI[i] = cumplimientoizq;
                    cumplimientoD[i] = cumplimientoder;
                    promedioC[i] = cumplimientoCal;
                    if (promedioC[i] > 0) {
                        cumplimientoPuntCalzada[i] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')
                    }
                    else {
                        cumplimientoPuntCalzada[i] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')
                    }
                    if (promedioCalzada[i] > valorpromedio || promedioC[i] > 0) {
                        cumplimientoIndicador[i] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')
                    }
                    else {
                        cumplimientoIndicador[i] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')
                    }
                }
            }
            const MostrarDetalles = (valor, valor2) => {
                //alert("Prueba"+valor);
                //console.log(valor)
                let det = document.getElementById('detalles_' + valor)
                let most = document.getElementById('mostrar_' + valor)
                if (det) {
                    let disp = det.style.display;
                    if (disp != "none") {
                        det.style.display = "none"
                    }
                    else {
                        det.style.display = ""
                    }
                }
                if (most) {
                    let html = most.innerHTML;
                    if (html != "Mostrar") {
                        most.innerHTML = "Mostrar"
                    }
                    else {
                        most.innerHTML = "Ocultar"
                    }
                }

                //console.log(valor2)
            }
            if (indicadores === "1" || indicadores === "2" || indicadores === "4") {
                return (
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.SEGMENTO')}</TableCell>
                                <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.fechaEvaluacion')}</TableCell>
                                <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.estado')}</TableCell>
                                <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.medidoPor')}</TableCell>
                                <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.PromedioCarrilI')}</TableCell>
                                <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.IncumplPuntualesCarrilI')}</TableCell>
                                <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.PromedioCarrilD')}</TableCell>
                                <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.IncumplPuntualesCarrilD')}</TableCell>
                                {this.props.indicador === "1" &&
                                    <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.ValorMedioIRI_mm')}</TableCell>
                                }
                                {this.props.indicador === "2" &&
                                    <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.ValorMedAhuellamiento_mm')}</TableCell>
                                }
                                {this.props.indicador === "4" &&
                                    <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.ValorMedioCFT')}</TableCell>
                                } 
                                <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.CumplimientoValorPromedio')}</TableCell>
                                <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.CumplimientoValorPuntual')}</TableCell>
                                <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.cumplimientoIndicador')}</TableCell>
                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.visualizar')}</TableCell>
                            </TableRow>
                        </TableHead>
                        {promedioI.map((row, i) => (
                            <TableBody>
                                <TableRow>
                                    <TableCell style={{ textAlign: "left" }}>{segmentos[i]}</TableCell>
                                    <TableCell style={{ textAlign: "left" }}>{this.ultimaFecha(datosprueba[i]['fecha'])}</TableCell>
                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['estado'][0]}</TableCell>
                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['medidopor'][0]}</TableCell>
                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(row, 2)}</TableCell>
                                    <TableCell style={{ textAlign: "right" }}>{cumplimientoI[i]}</TableCell>
                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(promedioD[i], 2)}</TableCell>
                                    <TableCell style={{ textAlign: "right" }}>{cumplimientoD[i]}</TableCell>
                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(promedioCalzada[i], 2)}</TableCell>
                                    {cumplimientoPromCalzada[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                        <TableCell style={{ textAlign: "left", color: "red" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                    }
                                    {cumplimientoPromCalzada[i] != translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                        <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                    }
                                    {cumplimientoPuntCalzada[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                        <TableCell style={{ textAlign: "left", color: "red" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                    }
                                    {cumplimientoPuntCalzada[i] != translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                        <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                    }
                                    {cumplimientoIndicador[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                        <TableCell style={{ textAlign: "left", color: "red" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                    }
                                    {cumplimientoIndicador[i] != translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                        <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                    }

                                    <TableCell style={{ textAlign: "right" }}><span id={"mostrar_" + i} style={{ color: "blue", cursor: "pointer" }} onClick={(evt) => MostrarDetalles(i, evt)}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')}</span></TableCell>
                                </TableRow>
                                <TableRow style={{ display: "none" }} id={"detalles_" + i}>
                                    <TableCell colSpan="13">
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell style={{ textAlign: "center" }} colSpan="2">{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.abscisa')}</TableCell>
                                                    <TableCell style={{ textAlign: "center" }} colspan="4">{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.SEGMENTO')}</TableCell>
                                                    <TableCell style={{ textAlign: "center" }} colspan="4">{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.carrilDerechoAscendente')}</TableCell>
                                                    <TableCell style={{ textAlign: "center" }} rowSpan="2">{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.cumplimientoIndicadorCalzada')}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.AbscisaInicio')}</TableCell>
                                                    <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.AbscisaFinal')}</TableCell>
                                                    {this.props.indicador === "1" &&
                                                        <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.IRImm')}</TableCell>
                                                    }
                                                    {this.props.indicador === "2" &&
                                                        <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Ahuellamiento_mm')}</TableCell>
                                                    }
                                                    {this.props.indicador === "4" &&
                                                        <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CFT')}</TableCell>
                                                    }
                                                    <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CumplimientoIndicPuntual')}</TableCell>
                                                    <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Evento')}</TableCell>
                                                    <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Singularidad')}</TableCell>
                                                    {this.props.indicador === "1" &&
                                                        <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.IRImm')}</TableCell>
                                                    }
                                                    {this.props.indicador === "2" &&
                                                        <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Ahuellamiento_mm')}</TableCell>
                                                    }
                                                    {this.props.indicador === "4" &&
                                                        <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CFT')}</TableCell>
                                                    }
                                                    <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CumplimientoIndicPuntual')}</TableCell>
                                                    <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Evento')}</TableCell>
                                                    <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Singularidad')}</TableCell>
                                                    <TableCell style={{ textAlign: "center" }}></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {datosprueba[i]['cumplimientoI'].map((data, j) => (
                                                    <TableRow>
                                                        <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(datosprueba[i]['abscisaini'][j])}</TableCell>
                                                        <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(datosprueba[i]['abscisafin'][j])}</TableCell>
                                                        <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['iriCizq'][j], 2)}</TableCell>
                                                        {datosprueba[i]['cumplimientoI'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                                            <TableCell style={{ color: "red", textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                                        }
                                                        {datosprueba[i]['cumplimientoI'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple') &&
                                                            <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                                        }
                                                        {datosprueba[i]['cumplimientoI'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica') &&
                                                            <TableCell style={{ color: "red", textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica')}</TableCell>
                                                        }
                                                        <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['eventoCizq'][j]}</TableCell>
                                                        <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['singularidadCizq'][j]}</TableCell>
                                                        <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['iriCder'][j], 2)}</TableCell>
                                                        {datosprueba[i]['cumplimientoD'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                                            <TableCell style={{ color: "red", textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                                        }
                                                        {datosprueba[i]['cumplimientoD'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple') &&
                                                            <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                                        }
                                                        {datosprueba[i]['cumplimientoD'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica') &&
                                                            <TableCell style={{ color: "red", textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica')}</TableCell>
                                                        }
                                                        <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['eventoCder'][j]}</TableCell>
                                                        <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['singularidadCder'][j]}</TableCell>
                                                        {datosprueba[i]['cumplimientoC'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                                            <TableCell style={{ color: "red", textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                                        }
                                                        {datosprueba[i]['cumplimientoC'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple') &&
                                                            <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                                        }
                                                        {datosprueba[i]['cumplimientoC'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica') &&
                                                            <TableCell style={{ color: "red", textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica')}</TableCell>
                                                        }
                                                    </TableRow>
                                                ))
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        ))}

                    </Table>
                )
            } else if (this.props.indicador === "5") {
                return (
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.SEGMENTO')}</TableCell>
                                <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.fechaEvaluacion')}</TableCell>
                                <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.estado')}</TableCell>
                                <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.medidoPor')}</TableCell>
                                <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.IncumplPuntualesCarrilI')}</TableCell>
                                <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.IncumplPuntualesCarrilD')}</TableCell>
                                <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.cumplimientoIndicador')}</TableCell>
                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.visualizar')}</TableCell>
                            </TableRow>
                        </TableHead>
                        {promedioI.map((row, i) => (
                            <TableBody>
                                <TableRow>
                                    <TableCell style={{ textAlign: "left" }}>{segmentos[i]}</TableCell>
                                    <TableCell style={{ textAlign: "left" }}>{this.ultimaFecha(datosprueba[i]['fecha'])}</TableCell>
                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['estado'][0]}</TableCell>
                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['medidopor'][0]}</TableCell>
                                    <TableCell style={{ textAlign: "right" }}>{cumplimientoI[i]}</TableCell>
                                    <TableCell style={{ textAlign: "right" }}>{cumplimientoD[i]}</TableCell>
                                    {cumplimientoPuntCalzada[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                        <TableCell style={{ textAlign: "left", color: "red" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                    }
                                    {cumplimientoPuntCalzada[i] != translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                        <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                    }

                                    <TableCell style={{ textAlign: "right" }}><span id={"mostrar_" + i} style={{ color: "blue", cursor: "pointer" }} onClick={(evt) => MostrarDetalles(i, evt)}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')}</span></TableCell>
                                </TableRow>
                                <TableRow style={{ display: "none" }} id={"detalles_" + i}>
                                    <TableCell colSpan="13">
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell style={{ textAlign: "center" }} colSpan="2">{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.abscisa')}</TableCell>
                                                    <TableCell style={{ textAlign: "center" }} colspan="4">{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.SEGMENTO')}</TableCell>
                                                    <TableCell style={{ textAlign: "center" }} colspan="4">{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.carrilDerechoAscendente')}</TableCell>
                                                    <TableCell style={{ textAlign: "center" }} rowSpan="2">{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.cumplimientoIndicadorCalzada')}</TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.AbscisaInicio')}</TableCell>
                                                    <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.AbscisaFinal')}</TableCell>
                                                    <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.MTDmmASTME1845')}</TableCell>
                                                    <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CumplimientoIndicPuntual')}</TableCell>
                                                    <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Evento')}</TableCell>
                                                    <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Singularidad')}</TableCell>
                                                    <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.MTDmmASTME1845')}</TableCell>
                                                    <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CumplimientoIndicPuntual')}</TableCell>
                                                    <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Evento')}</TableCell>
                                                    <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Singularidad')}</TableCell>
                                                    <TableCell style={{ textAlign: "center" }}></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {datosprueba[i]['cumplimientoI'].map((data, j) => (
                                                    <TableRow>
                                                        <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(datosprueba[i]['abscisaini'][j])}</TableCell>
                                                        <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(datosprueba[i]['abscisafin'][j])}</TableCell>
                                                        <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['iriCizq'][j], 2)}</TableCell>
                                                        {datosprueba[i]['cumplimientoI'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                                            <TableCell style={{ color: "red", textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                                        }
                                                        {datosprueba[i]['cumplimientoI'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple') &&
                                                            <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                                        }
                                                        {datosprueba[i]['cumplimientoI'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica') &&
                                                            <TableCell style={{ color: "red", textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica')}</TableCell>
                                                        }
                                                        <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['eventoCizq'][j]}</TableCell>
                                                        <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['singularidadCizq'][j]}</TableCell>
                                                        <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['iriCder'][j], 2)}</TableCell>
                                                        {datosprueba[i]['cumplimientoD'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                                            <TableCell style={{ color: "red", textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                                        }
                                                        {datosprueba[i]['cumplimientoD'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple') &&
                                                            <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                                        }
                                                        {datosprueba[i]['cumplimientoD'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica') &&
                                                            <TableCell style={{ color: "red", textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica')}</TableCell>
                                                        }
                                                        <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['eventoCder'][j]}</TableCell>
                                                        <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['singularidadCder'][j]}</TableCell>
                                                        {datosprueba[i]['cumplimientoC'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                                            <TableCell style={{ color: "red", textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                                        }
                                                        {datosprueba[i]['cumplimientoC'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple') &&
                                                            <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                                        }
                                                        {datosprueba[i]['cumplimientoC'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica') &&
                                                            <TableCell style={{ color: "red", textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica')}</TableCell>
                                                        }
                                                    </TableRow>
                                                ))
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        ))}

                    </Table>

                )
            } else {
                return (
                    <div className="Reload2">
                        <img src={loader} />
                    </div>
                )
            }
        } else if (indicador && tramo && ufunc && datosCarga && datosCarga.length > 0 && (indicadores === "16")) {
            let registros = datosCarga;
            let segmentos = [];
            let promedioI = [];
            let aux = -1;
            let datosprueba = [];
            //console.log(registros)
            registros.map((data, i) => {
                if (data.sg_codigo != aux) {
                    segmentos.push(data.sg_codigo);
                    aux = data.sg_codigo;
                }
            })
            registros.map((data, i) => {
                for (let i = 0; i < segmentos.length; i++) {
                    if (!datosprueba[i]) {
                        datosprueba[i] = {
                            indicador: [],
                            abscisaini: [],
                            abscisafin: [],
                            observaciones: [],
                            fecha: [],
                            estado: [],
                            medidopor: []
                        };
                    }
                    if (data.sg_codigo === segmentos[i]) {
                        datosprueba[i]['indicador'].push(data.iricizq);
                        datosprueba[i]['abscisaini'].push(data.abini);
                        datosprueba[i]['abscisafin'].push(data.abfin);
                        datosprueba[i]['observaciones'].push(data.observaciones);
                        datosprueba[i]['fecha'].push(data.fecha);
                        datosprueba[i]['estado'].push(data.estado);
                        datosprueba[i]['medidopor'].push(data.medido_por);
                    }
                }
            })
            return (
                <Table aria-label="simple table" style={{ width: "70%" }}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.SEGMENTO')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.fechaEvaluacion')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.estado')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.medidoPor')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.PCI')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.CodigoColor')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {segmentos.map((row, i) => (
                            <TableRow>
                                <TableCell style={{ textAlign: "left" }}>{segmentos[i]}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{this.ultimaFecha(datosprueba[i]['fecha'])}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['estado'][0]}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['medidopor'][0]}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['indicador']}</TableCell>
                                <TableCell style={{ textAlign: "right", backgroundColor: this.ColorPCI(datosprueba[i]['indicador']) }}></TableCell>
                            </TableRow>
                        ))
                        }
                    </TableBody>
                </Table>
            )
        } else if (indicador && tramo && ufunc && datosCarga && datosCarga.length > 0 && (indicadores === "3")) {
            let registros = datosCarga;
            let segmentos = [];
            let AreaCalzada = [];
            let AreaAfectadaTotal = [];
            let AreaAfectadaPorcentaje = [];
            let cumplimiento = [];
            let aux = -1;
            let datosprueba = [];
            //console.log(registros)
            registros.map((data, i) => {
                if (data.sg_codigo != aux) {
                    segmentos.push(data.sg_codigo);
                    aux = data.sg_codigo;
                }
            })
            registros.map((data, i) => {
                for (let i = 0; i < segmentos.length; i++) {
                    if (!datosprueba[i]) {
                        datosprueba[i] = {
                            abscisa: [],
                            tipofisura: [],
                            severidad: [],
                            largo: [],
                            ancho: [],
                            area: [],
                            areaverdadera: [],
                            fecha: [],
                            estado: [],
                            medidopor: []
                        };
                    }
                    if (data.sg_codigo === segmentos[i]) {
                        datosprueba[i]['abscisa'].push(data.abscisa);
                        datosprueba[i]['tipofisura'].push(data.tipofisura);
                        datosprueba[i]['severidad'].push(data.severidad);
                        datosprueba[i]['largo'].push(data.largo);
                        datosprueba[i]['ancho'].push(data.ancho);
                        datosprueba[i]['area'].push(data.area);
                        datosprueba[i]['areaverdadera'].push(data.area_verdadera);
                        datosprueba[i]['fecha'].push(data.fecha);
                        datosprueba[i]['estado'].push(data.estado);
                        datosprueba[i]['medidopor'].push(data.medido_por);
                    }
                }
            })
            //console.log(datosprueba)
            for (let i = 0; i < datosprueba.length; i++) {
                let areaAfectada = 0;
                let areaAfectadaPorc = 0;
                let sumaArea = 0;
                let areaCumplimiento = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple');
                let tipo_fisura = ["OND", "AB", "HUN", "AHU", "DC", "BHC", "PCH", "DSU", "PA", "EX", "PU", "CD", "SU"];
                for (let j = 0; j < datosprueba[i]['area'].length; j++) {
                    if (datosprueba[i]['ancho'][j] == valorpromedio && datosprueba[i]['severidad'][j] == "3" && !tipo_fisura.includes(datosprueba[i]['tipofisura'][j]) ) {
                        sumaArea += parseFloat(datosprueba[i]['area'][j]);
                    }
                }
                areaAfectadaPorc = (sumaArea / datosprueba[i]['areaverdadera'][0]) * 100;
                if (parseFloat(areaAfectadaPorc) < parseFloat(valorpuntual)) {
                    areaCumplimiento = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')
                } else {
                    areaCumplimiento = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')
                }
                cumplimiento.push(areaCumplimiento);
                AreaAfectadaPorcentaje.push(parseFloat(areaAfectadaPorc).toFixed(2));
                AreaCalzada.push(datosprueba[i]['areaverdadera'][0])
                AreaAfectadaTotal.push(sumaArea);

            }

            //console.log(segmentos)
            //console.log(promedioI)
            const MostrarDetalles = (valor, valor2) => {
                //alert("Prueba"+valor);
                //console.log(valor)
                let det = document.getElementById('detalles_' + valor)
                let most = document.getElementById('mostrar_' + valor)
                if (det) {
                    let disp = det.style.display;
                    if (disp != "none") {
                        det.style.display = "none"
                    }
                    else {
                        det.style.display = ""
                    }
                }
                if (most) {
                    let html = most.innerHTML;
                    if (html != "Mostrar") {
                        most.innerHTML = "Mostrar"
                    }
                    else {
                        most.innerHTML = "Ocultar"
                    }
                }

                //console.log(valor2)
            }
            return (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.SEGMENTO')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.fechaEvaluacion')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.estado')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.medidoPor')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.areaCalzada')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.areaAfectada_m')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.areaAfectada')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.cumplimientoIndicador')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.visualizar')}</TableCell>
                        </TableRow>
                    </TableHead>
                    {segmentos.map((row, i) => (
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ textAlign: "left" }}>{segmentos[i]}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.ultimaFecha(datosprueba[i]['fecha'])}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['estado'][0]}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['medidopor'][0]}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{AreaCalzada[i]}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(AreaAfectadaTotal[i], 2)}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(AreaAfectadaPorcentaje[i], 2)}</TableCell>
                                {cumplimiento[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left", color: "red" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                }
                                {cumplimiento[i] != translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                }
                                <TableCell style={{ textAlign: "right" }}><span id={"mostrar_" + i} style={{ color: "blue", cursor: "pointer" }} onClick={(evt) => MostrarDetalles(i, evt)}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')}</span></TableCell>
                            </TableRow>
                            <TableRow style={{ display: "none" }} id={"detalles_" + i}>
                                <TableCell colSpan="9">
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.abscisa')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.TipoFisura')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Severidad')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Largo_m')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Ancho_m')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Area_m')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {datosprueba[i]['area'].map((data, j) => (
                                                <TableRow>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(datosprueba[i]['abscisa'][j])}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['tipofisura'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['severidad'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['largo'][j], 2)}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['ancho'][j], 2)}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['area'][j], 2)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ))}

                </Table>
            )
        } else if (indicador && tramo && ufunc && datosCarga && datosCarga.length > 0 && (indicadores === "6")) {
            let registros = datosCarga;
            let segmentos = [];
            let cumplimientoCalzada = []
            let incumplimientos = [];
            let aux = -1;
            let datosprueba = [];
            //console.log(registros)
            registros.map((data, i) => {
                if (data.sg_codigo != aux) {
                    segmentos.push(data.sg_codigo);
                    aux = data.sg_codigo;
                }
            })
            registros.map((data, i) => {
                for (let i = 0; i < segmentos.length; i++) {
                    if (!datosprueba[i]) {
                        datosprueba[i] = {
                            ancho: [],
                            largo: [],
                            area: [],
                            profundidad: [],
                            observaciones: [],
                            carril: [],
                            abscisa: [],
                            fecha: [],
                            estado: [],
                            medidopor: []
                        };
                    }
                    if (data.sg_codigo === segmentos[i]) {
                        datosprueba[i]['abscisa'].push(data.abscisa);
                        datosprueba[i]['ancho'].push(data.ancho);
                        datosprueba[i]['largo'].push(data.largo);
                        datosprueba[i]['area'].push(data.area);
                        datosprueba[i]['profundidad'].push(data.profundidad);
                        datosprueba[i]['observaciones'].push(data.obs);
                        datosprueba[i]['carril'].push(data.carril);
                        datosprueba[i]['fecha'].push(data.fecha);
                        datosprueba[i]['estado'].push(data.estado);
                        datosprueba[i]['medidopor'].push(data.medido_por);
                    }
                }
            })
            //console.log(datosprueba)
            for (let i = 0; i < datosprueba.length; i++) {
                let cumplimiento = "No Cumple";
                let sumaIncumplimientosCizq = 0;
                let sumaIncumplimientosCder = 0;
                for (let j = 0; j < datosprueba[i]['ancho'].length; j++) {
                    let area = datosprueba[i]['area'][j];
                    let profundidad = datosprueba[i]['profundidad'][j];
                    let carril = datosprueba[i]['carril'][j];
                    if (parseFloat(area) > 0.05 && parseFloat(profundidad) > 2.5 && carril === "Izquierdo") {
                        sumaIncumplimientosCizq++;
                    }
                    if (parseFloat(area) > 0.05 && parseFloat(profundidad) > 2.5 && carril === "Derecho") {
                        sumaIncumplimientosCder++;
                    }
                }
                if (sumaIncumplimientosCder + sumaIncumplimientosCizq > valorpuntual) {
                    cumplimiento = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')
                }
                else {
                    cumplimiento = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')
                }
                cumplimientoCalzada.push(cumplimiento);
                incumplimientos.push(sumaIncumplimientosCder + sumaIncumplimientosCizq);
            }

            //console.log(segmentos)
            //console.log(promedioI)
            const MostrarDetalles = (valor, valor2) => {
                //alert("Prueba"+valor);
                //console.log(valor)
                let det = document.getElementById('detalles_' + valor)
                let most = document.getElementById('mostrar_' + valor)
                if (det) {
                    let disp = det.style.display;
                    if (disp != "none") {
                        det.style.display = "none"
                    }
                    else {
                        det.style.display = ""
                    }
                }
                if (most) {
                    let html = most.innerHTML;
                    if (html != "Mostrar") {
                        most.innerHTML = "Mostrar"
                    }
                    else {
                        most.innerHTML = "Ocultar"
                    }
                }

                //console.log(valor2)
            }
            return (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.SEGMENTO')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.fechaEvaluacion')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.estado')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.medidoPor')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.IncumplPuntualesCalzada')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.cumplimientoIndicador')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.visualizar')}</TableCell>
                        </TableRow>
                    </TableHead>
                    {segmentos.map((row, i) => (
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ textAlign: "left" }}>{segmentos[i]}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{this.ultimaFecha(datosprueba[i]['fecha'])}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['estado'][0]}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['medidopor'][0]}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{incumplimientos[i]}</TableCell>
                                {cumplimientoCalzada[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left", color: "red" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                }
                                {cumplimientoCalzada[i] != translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                }

                                <TableCell style={{ textAlign: "right" }}><span id={"mostrar_" + i} style={{ color: "blue", cursor: "pointer" }} onClick={(evt) => MostrarDetalles(i, evt)}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')}</span></TableCell>
                            </TableRow>
                            <TableRow style={{ display: "none" }} id={"detalles_" + i}>
                                <TableCell colSpan="7">
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.abscisa')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Ancho_cm')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Largo_cm')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Area_m')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Profundidadcm')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.observaciones')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Carril')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {datosprueba[i]['abscisa'].map((row, j) => (
                                                <TableRow>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(datosprueba[i]['abscisa'][j])}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['ancho'][j], 2)}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['largo'][j], 2)}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['area'][j], 2)}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['profundidad'][j], 2)}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['observaciones'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['carril'][j]}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ))}

                </Table>

            )
        } else if (indicador && tramo && ufunc && datosCarga && datosCarga.length > 0 && (indicadores === "7")) {
            let registros = datosCarga;
            let segmentos = [];
            let cumplimientoCalzada = []
            let AreaVerdaderaCal = [];
            let AreaAfect = [];
            let AreaPorc = [];
            let aux = -1;
            let datosprueba = [];
            //console.log(registros)
            registros.map((data, i) => {
                if (data.sg_codigo != aux) {
                    segmentos.push(data.sg_codigo);
                    aux = data.sg_codigo;
                }
            })
            registros.map((data, i) => {
                for (let i = 0; i < segmentos.length; i++) {
                    if (!datosprueba[i]) {
                        datosprueba[i] = {
                            ancho: [],
                            largo: [],
                            area: [],
                            profundidad: [],
                            observaciones: [],
                            carril: [],
                            abscisa: [],
                            fecha: [],
                            estado: [],
                            medidopor: [],
                            areaverdadera: []
                        };
                    }
                    if (data.sg_codigo === segmentos[i]) {
                        datosprueba[i]['abscisa'].push(data.abscisa);
                        datosprueba[i]['ancho'].push(data.ancho);
                        datosprueba[i]['largo'].push(data.largo);
                        datosprueba[i]['area'].push(data.area);
                        datosprueba[i]['profundidad'].push(data.profundidad);
                        datosprueba[i]['observaciones'].push(data.obs);
                        datosprueba[i]['carril'].push(data.carril);
                        datosprueba[i]['areaverdadera'].push(data.area_verdadera);
                        datosprueba[i]['fecha'].push(data.fecha);
                        datosprueba[i]['estado'].push(data.estado);
                        datosprueba[i]['medidopor'].push(data.medido_por);
                    }
                }
            })
            //console.log(datosprueba)
            for (let i = 0; i < datosprueba.length; i++) {
                let cumplimiento = "No Cumple";
                let sumaAreasCizq = 0;
                let sumaAreasCder = 0;
                let SumaIncumplimiento = 0;
                for (let j = 0; j < datosprueba[i]['ancho'].length; j++) {
                    let area = datosprueba[i]['area'][j];
                    let profundidad = datosprueba[i]['profundidad'][j];
                    let carril = datosprueba[i]['carril'][j];
                    if (carril === "Izquierdo" && parseFloat(profundidad) >= 2) {
                        sumaAreasCizq += parseFloat(datosprueba[i]['area'][j]);
                    }
                    if (carril === "Derecho" && parseFloat(profundidad) >= 2) {
                        sumaAreasCder += parseFloat(datosprueba[i]['area'][j]);

                    }
                }

                let areaVerdad = datosprueba[i]['areaverdadera'][0]
                let areaAfectada = (sumaAreasCder + sumaAreasCizq).toFixed(2);
                //console.log(sumaAreasCizq+" + "+sumaAreasCder+" = "+areaAfectada)
                let porcentaje = parseFloat((areaAfectada / areaVerdad) * 100).toFixed(2);
                //console.log("valor punt = "+valorpuntual)
                if (porcentaje < valorpuntual) {
                    cumplimiento = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')
                } else {
                    cumplimiento = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')
                }

                cumplimientoCalzada.push(cumplimiento);
                AreaVerdaderaCal.push(areaVerdad);
                AreaAfect.push(areaAfectada);
                AreaPorc.push(porcentaje);
            }

            //console.log(segmentos)
            //console.log(promedioI)
            const MostrarDetalles = (valor, valor2) => {
                //alert("Prueba"+valor);
                //console.log(valor)
                let det = document.getElementById('detalles_' + valor)
                let most = document.getElementById('mostrar_' + valor)
                if (det) {
                    let disp = det.style.display;
                    if (disp != "none") {
                        det.style.display = "none"
                    }
                    else {
                        det.style.display = ""
                    }
                }
                if (most) {
                    let html = most.innerHTML;
                    if (html != "Mostrar") {
                        most.innerHTML = "Mostrar"
                    }
                    else {
                        most.innerHTML = "Ocultar"
                    }
                }

                //console.log(valor2)
            }
            return (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.SEGMENTO')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.fechaEvaluacion')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.estado')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.medidoPor')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.areaVerdadera')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.areaAfectada_m')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.areaAfectada')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.cumplimientoIndicador')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.visualizar')}</TableCell>
                        </TableRow>
                    </TableHead>
                    {segmentos.map((row, i) => (
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ textAlign: "left" }}>{segmentos[i]}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{this.ultimaFecha(datosprueba[i]['fecha'])}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['estado'][0]}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['medidopor'][0]}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{AreaVerdaderaCal[i]}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(AreaAfect[i], 2)}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(AreaPorc[i], 2)}</TableCell>
                                {cumplimientoCalzada[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left", color: "red" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                }
                                {cumplimientoCalzada[i] != translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                }

                                <TableCell style={{ textAlign: "right" }}><span id={"mostrar_" + i} style={{ color: "blue", cursor: "pointer" }} onClick={(evt) => MostrarDetalles(i, evt)}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')}</span></TableCell>
                            </TableRow>
                            <TableRow style={{ display: "none" }} id={"detalles_" + i}>
                                <TableCell colSpan="9">
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.abscisa')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Ancho_cm')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Largo_cm')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Area_m')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Profundidadcm')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.observaciones')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Carril')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {datosprueba[i]['abscisa'].map((row, j) => (
                                                <TableRow>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(datosprueba[i]['abscisa'][j])}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['ancho'][j], 2)}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['largo'][j], 2)}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['area'][j], 2)}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['profundidad'][j], 2)}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['observaciones'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['carril'][j]}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ))}

                </Table>

            )
        } else if (indicador && tramo && ufunc && datosCarga && datosCarga.length > 0 && (indicadores === "8")) {
            let registros = this.abscisasEspeciales(datosCarga);
            let segmentos = [];
            let cumplimientoSegmento = []
            let incumplimientosPuntuales = [];
            let aux = -1;
            let datosprueba = [];
            //console.log(registros)
            
            registros.map((data, i) => {
                if (data.sg_codigo != aux) {
                    segmentos.push(data.sg_codigo);
                    aux = data.sg_codigo;
                }
            })
            registros.map((data, i) => {
                for (let i = 0; i < segmentos.length; i++) {
                    if (!datosprueba[i]) {
                        datosprueba[i] = {
                            abscisa: [],
                            medida1: [],
                            medida2: [],
                            medida3: [],
                            medida4: [],
                            medida5: [],
                            limpiezaGeneral: [],
                            vegetacionSeguridad: [],
                            numeroBasuras: [],
                            observaciones: [],
                            cumplimiento: [],
                            fecha: [],
                            estado: [],
                            medidopor: []
                        };
                    }
                    if (data.sg_codigo === segmentos[i]) {
                        datosprueba[i]['abscisa'].push(data.abscisa);
                        datosprueba[i]['medida1'].push(data.medida1);
                        datosprueba[i]['medida2'].push(data.medida2);
                        datosprueba[i]['medida3'].push(data.medida3);
                        datosprueba[i]['medida4'].push(data.medida4);
                        datosprueba[i]['medida5'].push(data.medida5);
                        datosprueba[i]['limpiezaGeneral'].push(data.limpiezaGeneral);
                        datosprueba[i]['vegetacionSeguridad'].push(data.vegetacionSeguridad);
                        datosprueba[i]['numeroBasuras'].push(data.numeroBasuras);
                        datosprueba[i]['observaciones'].push(data.observaciones);
                        datosprueba[i]['fecha'].push(data.fecha);
                        datosprueba[i]['estado'].push(data.estado);
                        datosprueba[i]['medidopor'].push(data.medido_por);
                    }
                }
            })
            //console.log(datosprueba)
            for (let i = 0; i < datosprueba.length; i++) {
                let cumplimiento = "No Cumple";
                let maximomedida = valorpromedio;
                let maxvegetacion = valorpuntual;
                let cuentaCumplimiento = 0;
                let cuentacumplimientoSeg = 0;
                for (let j = 0; j < datosprueba[i]['abscisa'].length; j++) {
                    cuentaCumplimiento = 0;
                    if (datosprueba[i]['medida1'][j] > maximomedida || datosprueba[i]['medida2'][j] > maximomedida
                        || datosprueba[i]['medida3'][j] > maximomedida || datosprueba[i]['medida4'][j] > maximomedida
                        || datosprueba[i]['medida5'][j] > maximomedida) {
                        cuentaCumplimiento++
                    }
                    if (datosprueba[i]['limpiezaGeneral'][j] != 1) {
                        cuentaCumplimiento++
                    }
                    if (datosprueba[i]['vegetacionSeguridad'][j] == 1) {
                        cuentaCumplimiento++
                    }
                    if (datosprueba[i]['numeroBasuras'][j] >= maxvegetacion) {
                        cuentaCumplimiento++
                    }
                    if (cuentaCumplimiento > 0) {
                        cuentacumplimientoSeg++;
                        datosprueba[i]['cumplimiento'][j] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')
                    } else {
                        datosprueba[i]['cumplimiento'][j] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')
                    }
                }
                if (cuentacumplimientoSeg > 0) {
                    cumplimiento = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')
                } else {
                    cumplimiento = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')
                }
                cumplimientoSegmento.push(cumplimiento);
                incumplimientosPuntuales.push(cuentacumplimientoSeg)
            }


            const MostrarDetalles = (valor, valor2) => {
                let det = document.getElementById('detalles_' + valor)
                let most = document.getElementById('mostrar_' + valor)
                if (det) {
                    let disp = det.style.display;
                    if (disp != "none") {
                        det.style.display = "none"
                    }
                    else {
                        det.style.display = ""
                    }
                }
                if (most) {
                    let html = most.innerHTML;
                    if (html != "Mostrar") {
                        most.innerHTML = "Mostrar"
                    }
                    else {
                        most.innerHTML = "Ocultar"
                    }
                }

                //console.log(valor2)
            }
            return (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.SEGMENTO')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.fechaEvaluacion')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.estado')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.medidoPor')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.incumplimientosPuntuales')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.cumplimientoIndicador')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.visualizar')}</TableCell>
                        </TableRow>
                    </TableHead>
                    {segmentos.map((row, i) => (
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ textAlign: "left" }}>{segmentos[i]}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{this.ultimaFecha(datosprueba[i]['fecha'])}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['estado'][0]}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['medidopor'][0]}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{incumplimientosPuntuales[i]}</TableCell>

                                {cumplimientoSegmento[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left", color: "red" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                }
                                {cumplimientoSegmento[i] != translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                }
                                <TableCell style={{ textAlign: "right" }}><span id={"mostrar_" + i} style={{ color: "blue", cursor: "pointer" }} onClick={(evt) => MostrarDetalles(i, evt)}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')}</span></TableCell>
                            </TableRow>
                            <TableRow style={{ display: "none" }} id={"detalles_" + i}>
                                <TableCell colSpan="4">
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.abscisa')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Medida1_cm')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Medida2_cm')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Medida3_cm')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Medida4_cm')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Medida5_cm')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.LimpiezaGenCalzada')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.LaVegetacionAfecta')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.NumeroBasuras')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.observaciones')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.cumplimiento')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {datosprueba[i]['abscisa'].map((row, j) => (
                                                <TableRow>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(datosprueba[i]['abscisa'][j])}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['medida1'][j], 2)}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['medida2'][j], 2)}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['medida3'][j], 2)}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['medida4'][j], 2)}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['medida5'][j], 2)}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['limpiezaGeneral'][j] == 1 ? translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.adecuado') : translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.inadecuado')}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['vegetacionSeguridad'][j] == 1 ? translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.booleanSi') : translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.booleanNo')}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['numeroBasuras'][j], 2)}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['observaciones'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['cumplimiento'][j]}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ))}

                </Table>

            )
        } else if (indicador && tramo && ufunc && datosCarga && datosCarga.length > 0 && (indicadores === "9")) {
            let registros = datosCarga;
            let segmentos = [];
            let cumplimientoSegmento = [];
            let cumplimientoPuntual = [];
            let aux = -1;
            let datosprueba = [];
            //console.log(registros)
            registros.map((data, i) => {
                if (data.sg_codigo != aux) {
                    segmentos.push(data.sg_codigo);
                    aux = data.sg_codigo;
                }
            })
            registros.map((data, i) => {
                for (let i = 0; i < segmentos.length; i++) {
                    if (!datosprueba[i]) {
                        datosprueba[i] = {
                            elemento: [],
                            seccionHidraulica: [],
                            observaciones: [],
                            cumplimiento: [],
                            fecha: [],
                            estado: [],
                            medidopor: []
                        };
                    }
                    if (data.sg_codigo === segmentos[i]) {
                        datosprueba[i]['elemento'].push(data.codInt);
                        datosprueba[i]['seccionHidraulica'].push(data.seccionHidraulica);
                        datosprueba[i]['observaciones'].push(data.observaciones);
                        datosprueba[i]['fecha'].push(data.fecha);
                        datosprueba[i]['estado'].push(data.estado);
                        datosprueba[i]['medidopor'].push(data.medido_por);
                    }
                }
            })
            for (let i = 0; i < datosprueba.length; i++) {
                let cumplimiento = "No Cumple";
                let cuenta = 0;
                let cuenta2 = 0;
                let valormaxhidraulica = valorpromedio;
                for (let j = 0; j < datosprueba[i]['elemento'].length; j++) {
                    cuenta = 0
                    if (datosprueba[i]['seccionHidraulica'][j] > valormaxhidraulica) cuenta++
                    if (cuenta > 0) { datosprueba[i]['cumplimiento'][j] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple'); cuenta2++; }
                    else datosprueba[i]['cumplimiento'][j] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')
                }
                if (cuenta2 > 0) cumplimientoSegmento.push(translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple'))
                else cumplimientoSegmento.push(translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple'))
                cumplimientoPuntual.push(cuenta2)
            }
            const MostrarDetalles = (valor, valor2) => {
                let det = document.getElementById('detalles_' + valor)
                let most = document.getElementById('mostrar_' + valor)
                if (det) {
                    let disp = det.style.display;
                    if (disp != "none") {
                        det.style.display = "none"
                    }
                    else {
                        det.style.display = ""
                    }
                }
                if (most) {
                    let html = most.innerHTML;
                    if (html != "Mostrar") {
                        most.innerHTML = "Mostrar"
                    }
                    else {
                        most.innerHTML = "Ocultar"
                    }
                }
            }
            return (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.SEGMENTO')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.fechaEvaluacion')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.estado')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.medidoPor')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.incumplimientosPuntuales')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.cumplimientoIndicador')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.visualizar')}</TableCell>
                        </TableRow>
                    </TableHead>
                    {segmentos.map((row, i) => (
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ textAlign: "left" }}>{segmentos[i]}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{this.ultimaFecha(datosprueba[i]['fecha'])}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['estado'][0]}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['medidopor'][0]}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{cumplimientoPuntual[i]}</TableCell>
                                {cumplimientoSegmento[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left", color: "red" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                }
                                {cumplimientoSegmento[i] != translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                }
                                <TableCell style={{ textAlign: "right" }}><span id={"mostrar_" + i} style={{ color: "blue", cursor: "pointer" }} onClick={(evt) => MostrarDetalles(i, evt)}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')}</span></TableCell>
                            </TableRow>
                            <TableRow style={{ display: "none" }} id={"detalles_" + i}>
                                <TableCell colSpan="8">
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.elemento')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>Sección hidráulica colmatada (%)</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.observaciones')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.cumplimiento')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {datosprueba[i]['elemento'].map((row, j) => (
                                                <TableRow>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['elemento'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['seccionHidraulica'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['observaciones'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['cumplimiento'][j]}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ))}

                </Table>

            )
        } else if (indicador && tramo && ufunc && datosCarga && datosCarga.length > 0 && (indicadores === "10")) {
            let registros = datosCarga;
            let segmentos = [];
            let cumplimientoSegmento = [];
            let cumplimientoPuntual = [];
            let aux = -1;
            let datosprueba = [];
            //console.log(registros)
            registros.map((data, i) => {
                if (data.sg_codigo != aux) {
                    segmentos.push(data.sg_codigo);
                    aux = data.sg_codigo;
                }
            })
            registros.map((data, i) => {
                for (let i = 0; i < segmentos.length; i++) {
                    if (!datosprueba[i]) {
                        datosprueba[i] = {
                            elemento: [],
                            retroPrincipal: [],
                            retroSecundario: [],
                            legibilidad: [],
                            localizacion: [],
                            estabilidad: [],
                            estadoLimpieza: [],
                            observaciones: [],
                            cumplimiento: [],
                            retroElemento: [],
                            retroElementof: [],
                            fecha: [],
                            estado: [],
                            medidopor: []
                        };
                    }
                    if (data.sg_codigo === segmentos[i]) {
                        datosprueba[i]['elemento'].push(data.codInt ? data.codInt : data.elementoSenalizacion);
                        datosprueba[i]['retroPrincipal'].push(data.retroPrincipal);
                        datosprueba[i]['retroSecundario'].push(data.retroSecundario);
                        datosprueba[i]['legibilidad'].push(data.legibilidad);
                        datosprueba[i]['localizacion'].push(data.localizacion);
                        datosprueba[i]['estabilidad'].push(data.estabilidad);
                        datosprueba[i]['estadoLimpieza'].push(data.estadoLimpieza);
                        datosprueba[i]['observaciones'].push(data.observaciones);
                        datosprueba[i]['retroElemento'].push(data.retroElemento);
                        datosprueba[i]['retroElementof'].push(data.retroElementof);
                        datosprueba[i]['fecha'].push(data.fecha);
                        datosprueba[i]['estado'].push(data.estado);
                        datosprueba[i]['medidopor'].push(data.medido_por);
                    }
                }
            })
            //console.log(datosprueba)
            for (let i = 0; i < datosprueba.length; i++) {
                let cumplimiento = "No Cumple";
                let cuenta = 0;
                let cuenta2 = 0;
                for (let j = 0; j < datosprueba[i]['elemento'].length; j++) {
                    let cuenta = 0;
                    let retro_elemento = parseFloat(datosprueba[i]['retroElemento'][j]) * (valorpromedio / 100);
                    let retro2_elemento = parseFloat(datosprueba[i]['retroElementof'][j]) * (valorpromedio / 100);
                    if (parseFloat(datosprueba[i]['retroPrincipal'][j]) <= retro_elemento) {
                        cuenta++;
                    }
                    if (parseFloat(datosprueba[i]['retroSecundario'][j]) <= retro2_elemento) {
                        cuenta++;
                    }
                    let suma = parseFloat(datosprueba[i]['legibilidad'][j]) + parseFloat(datosprueba[i]['localizacion'][j]) + parseFloat(datosprueba[i]['estabilidad'][j]) + parseFloat(datosprueba[i]['estadoLimpieza'][j]);
                    if (suma != 4) {
                        cuenta++;
                    }
                    if (cuenta > 0) {
                        cuenta2++;
                        datosprueba[i]['cumplimiento'][j] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')
                    } else {
                        datosprueba[i]['cumplimiento'][j] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')
                    }
                }
                if (cuenta2 >= 2) {
                    cumplimiento = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')
                } else {
                    cumplimiento = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')
                }
                cumplimientoSegmento.push(cumplimiento);
                cumplimientoPuntual.push(cuenta2);
            }

            //console.log(segmentos)
            //console.log(promedioI)
            const MostrarDetalles = (valor, valor2) => {
                //alert("Prueba"+valor);
                //console.log(valor)
                let det = document.getElementById('detalles_' + valor)
                let most = document.getElementById('mostrar_' + valor)
                if (det) {
                    let disp = det.style.display;
                    if (disp != "none") {
                        det.style.display = "none"
                    }
                    else {
                        det.style.display = ""
                    }
                }
                if (most) {
                    let html = most.innerHTML;
                    if (html != "Mostrar") {
                        most.innerHTML = "Mostrar"
                    }
                    else {
                        most.innerHTML = "Ocultar"
                    }
                }

                //console.log(valor2)
            }
            return (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.SEGMENTO')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.fechaEvaluacion')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.estado')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.medidoPor')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.incumplimientosPuntuales')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.cumplimientoIndicador')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.visualizar')}</TableCell>
                        </TableRow>
                    </TableHead>
                    {segmentos.map((row, i) => (
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ textAlign: "left" }}>{segmentos[i]}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{this.ultimaFecha(datosprueba[i]['fecha'])}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['estado'][0]}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['medidopor'][0]}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{cumplimientoPuntual[i]}</TableCell>
                                {cumplimientoSegmento[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left", color: "red" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                }
                                {cumplimientoSegmento[i] != translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                }
                                <TableCell style={{ textAlign: "right" }}><span id={"mostrar_" + i} style={{ color: "blue", cursor: "pointer" }} onClick={(evt) => MostrarDetalles(i, evt)}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')}</span></TableCell>
                            </TableRow>
                            <TableRow style={{ display: "none" }} id={"detalles_" + i}>
                                <TableCell colSpan="8">
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.elemento')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.RetroreflectividadColorPrin')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.RetroreflectividadColorSec')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.LegibilidadSenal')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Localización')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Estabilidad')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.EstadoLimpieza')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.observaciones')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.cumplimiento')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {datosprueba[i]['elemento'].map((row, j) => (
                                                <TableRow>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['elemento'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['retroPrincipal'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['retroSecundario'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['legibilidad'][j] ? translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.adecuado') : translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.inadecuado')}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['localizacion'][j] ? translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.adecuado') : translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.inadecuado')}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['estabilidad'][j] ? translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.adecuado') : translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.inadecuado')}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['estadoLimpieza'][j] ? translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.adecuado') : translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.inadecuado')}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['observaciones'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['cumplimiento'][j]}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ))}

                </Table>

            )
        } else if (indicador && tramo && ufunc && datosCarga && datosCarga.length > 0 && (indicadores === "11")) {
            let registros = datosCarga;
            let segmentos = [];
            let cumplimientoSegmento = [];
            let cumplimientoPuntual = [];
            let aux = -1;
            let datosprueba = [];
            //console.log(registros)
            registros.map((data, i) => {
                if (data.sg_codigo != aux) {
                    segmentos.push(data.sg_codigo);
                    aux = data.sg_codigo;
                }
            })
            registros.map((data, i) => {
                for (let i = 0; i < segmentos.length; i++) {
                    if (!datosprueba[i]) {
                        datosprueba[i] = {
                            abscisa: [],
                            lineaBlancaDerecho: [],
                            lineaBlancaIzquierdo: [],
                            lineaAmarilla: [],
                            delineadorPiso: [],
                            tipoEquipo: [],
                            fecha: [],
                            estado: [],
                            medidopor: [],
                            cumplimiento: []
                        };
                    }
                    if (data.sg_codigo === segmentos[i]) {
                        datosprueba[i]['abscisa'].push(data.abscisa);
                        datosprueba[i]['lineaBlancaDerecho'].push(data.lineaBlancaDerecho);
                        datosprueba[i]['lineaBlancaIzquierdo'].push(data.lineaBlancaIzquierdo);
                        datosprueba[i]['lineaAmarilla'].push(data.lineaAmarilla);
                        datosprueba[i]['delineadorPiso'].push(data.delineadorPiso);
                        datosprueba[i]['tipoEquipo'].push(data.tipoEquipo);
                        datosprueba[i]['fecha'].push(data.fecha);
                        datosprueba[i]['estado'].push(data.estado);
                        datosprueba[i]['medidopor'].push(data.medido_por);
                    }
                }
            })
            //console.log(datosprueba)
            for (let i = 0; i < datosprueba.length; i++) {
                let cumplimiento = "No Cumple";
                let cuenta = 0;
                let valorlineablancaderecho = valorpromedio;
                let valorlineablancaizquierdo = valorpromedio;
                let valorlineaamarilla = valorpuntual;
                let cuentaTemporal = 0;
                let cuentaTemporal2 = 0;
                for (let j = 0; j < datosprueba[i]['abscisa'].length; j++) {
                    cuentaTemporal = 0;
                    // if (parseFloat(datosprueba[i]['tipoEquipo'][j]) === 30) {
                    //     valorlineablancaderecho = 140;
                    //     valorlineablancaizquierdo = 140;
                    //     valorlineaamarilla = 120;
                    // } else {
                    //     valorlineablancaderecho = 160;
                    //     valorlineablancaizquierdo = 160;
                    //     valorlineaamarilla = 140;
                    // }
                    if (parseFloat(datosprueba[i]['lineaBlancaDerecho'][j]) < valorlineablancaderecho) {
                        cuenta++;
                        cuentaTemporal++;
                    }
                    if (parseFloat(datosprueba[i]['lineaBlancaIzquierdo'][j]) < valorlineablancaizquierdo) {
                        cuenta++;
                        cuentaTemporal++;
                    }
                    if (parseFloat(datosprueba[i]['lineaAmarilla'][j]) < valorlineaamarilla) {
                        cuenta++;
                        cuentaTemporal++;
                    } if (datosprueba[i]['delineadorPiso'][j] != "1") {
                        cuenta++;
                        cuentaTemporal++;
                    }
                    if (cuentaTemporal < 1) {
                        datosprueba[i]['cumplimiento'][j] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')
                    } else {
                        cuentaTemporal2++;
                        datosprueba[i]['cumplimiento'][j] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')
                    }
                }

                if (cuenta < 1) {
                    cumplimiento = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')
                } else {
                    cumplimiento = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')
                }

                cumplimientoSegmento.push(cumplimiento);
                cumplimientoPuntual.push(cuentaTemporal2);
            }

            //console.log(segmentos)
            //console.log(promedioI)
            const MostrarDetalles = (valor, valor2) => {
                //alert("Prueba"+valor);
                //console.log(valor)
                let det = document.getElementById('detalles_' + valor)
                let most = document.getElementById('mostrar_' + valor)
                if (det) {
                    let disp = det.style.display;
                    if (disp != "none") {
                        det.style.display = "none"
                    }
                    else {
                        det.style.display = ""
                    }
                }
                if (most) {
                    let html = most.innerHTML;
                    if (html != "Mostrar") {
                        most.innerHTML = "Mostrar"
                    }
                    else {
                        most.innerHTML = "Ocultar"
                    }
                }

                //console.log(valor2)
            }
            return (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.SEGMENTO')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.fechaEvaluacion')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.estado')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.medidoPor')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.incumplimientosPuntuales')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.cumplimientoIndicador')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.visualizar')}</TableCell>
                        </TableRow>
                    </TableHead>
                    {segmentos.map((row, i) => (
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ textAlign: "left" }}>{segmentos[i]}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{this.ultimaFecha(datosprueba[i]['fecha'])}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['estado'][0]}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['medidopor'][0]}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{cumplimientoPuntual[i]}</TableCell>
                                {cumplimientoSegmento[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left", color: "red" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                }
                                {cumplimientoSegmento[i] != translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                }
                                <TableCell style={{ textAlign: "right" }}><span id={"mostrar_" + i} style={{ color: "blue", cursor: "pointer" }} onClick={(evt) => MostrarDetalles(i, evt)}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')}</span></TableCell>
                            </TableRow>
                            <TableRow style={{ display: "none" }} id={"detalles_" + i}>
                                <TableCell colSpan="8">
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.abscisa')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.medidaLineaBlancaDerecho')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.medidaLineaBlancaIzquierdo')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.medidalineaAmarilla')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.cumplimientoDelineadores')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.TipoEquipoGeometria_cm')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.cumplimientoIndicador')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {datosprueba[i]['abscisa'].map((row, j) => (
                                                <TableRow>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(datosprueba[i]['abscisa'][j])}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['lineaBlancaDerecho'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['lineaBlancaIzquierdo'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['lineaAmarilla'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['delineadorPiso'][j] ? translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.booleanSi') : translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.booleanNo')}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{j <= 0 ? datosprueba[i]['tipoEquipo'][j] : ""}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['cumplimiento'][j]}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ))}

                </Table>

            )
        } else if (indicador && tramo && ufunc && datosCarga && datosCarga.length > 0 && (indicadores === "12")) {
            let registros = datosCarga;
            let segmentos = [];
            let cumplimientoSegmento = [];
            let cumplimientoPuntual = [];
            let aux = -1;
            let datosprueba = [];
            //console.log(registros)
            registros.map((data, i) => {
                if (data.sg_codigo != aux) {
                    segmentos.push(data.sg_codigo);
                    aux = data.sg_codigo;
                }
            })
            registros.map((data, i) => {
                for (let i = 0; i < segmentos.length; i++) {
                    if (!datosprueba[i]) {
                        datosprueba[i] = {
                            elemento: [],
                            alineacion: [],
                            condicionEstructural: [],
                            estabilidad: [],
                            longitudIncumplimiento: [],
                            observaciones: [],
                            cumplimiento: [],
                            fecha: [],
                            estado: [],
                            medidopor: []
                        };
                    }
                    if (data.sg_codigo === segmentos[i]) {
                        datosprueba[i]['elemento'].push(data.codInt);
                        datosprueba[i]['alineacion'].push(data.alineacion);
                        datosprueba[i]['condicionEstructural'].push(data.condicionEstructural);
                        datosprueba[i]['estabilidad'].push(data.estabilidad);
                        datosprueba[i]['longitudIncumplimiento'].push(data.longitudIncumplimiento);
                        datosprueba[i]['observaciones'].push(data.observaciones);
                        datosprueba[i]['fecha'].push(data.fecha);
                        datosprueba[i]['estado'].push(data.estado);
                        datosprueba[i]['medidopor'].push(data.medido_por);
                    }
                }
            })
            for (let i = 0; i < datosprueba.length; i++) {
                let cuenta = 0;
                let cuenta2 = 0;
                let valorminlongitud = valorpromedio;
                for (let j = 0; j < datosprueba[i]['elemento'].length; j++) {
                    cuenta = 0
                    if (datosprueba[i]['longitudIncumplimiento'][j] >= valorminlongitud) cuenta++
                    if (cuenta > 0) { datosprueba[i]['cumplimiento'][j] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple'); cuenta2++; }
                    else datosprueba[i]['cumplimiento'][j] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')
                }
                if (cuenta2 >= 2) cumplimientoSegmento.push(translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple'))
                else cumplimientoSegmento.push(translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple'))
                cumplimientoPuntual.push(cuenta2)
            }
            const MostrarDetalles = (valor, valor2) => {
                let det = document.getElementById('detalles_' + valor)
                let most = document.getElementById('mostrar_' + valor)
                if (det) {
                    let disp = det.style.display;
                    if (disp != "none") {
                        det.style.display = "none"
                    }
                    else {
                        det.style.display = ""
                    }
                }
                if (most) {
                    let html = most.innerHTML;
                    if (html != "Mostrar") {
                        most.innerHTML = "Mostrar"
                    }
                    else {
                        most.innerHTML = "Ocultar"
                    }
                }
            }
            return (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.SEGMENTO')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.fechaEvaluacion')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.estado')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.medidoPor')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.incumplimientosPuntuales')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.cumplimientoIndicador')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.visualizar')}</TableCell>
                        </TableRow>
                    </TableHead>
                    {segmentos.map((row, i) => (
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ textAlign: "left" }}>{segmentos[i]}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{this.ultimaFecha(datosprueba[i]['fecha'])}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['estado'][0]}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['medidopor'][0]}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{cumplimientoPuntual[i]}</TableCell>
                                {cumplimientoSegmento[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left", color: "red" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                }
                                {cumplimientoSegmento[i] != translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                }
                                <TableCell style={{ textAlign: "right" }}><span id={"mostrar_" + i} style={{ color: "blue", cursor: "pointer" }} onClick={(evt) => MostrarDetalles(i, evt)}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')}</span></TableCell>
                            </TableRow>
                            <TableRow style={{ display: "none" }} id={"detalles_" + i}>
                                <TableCell colSpan="8">
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.elemento')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.Alineacion')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.CondicionEstructural')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.estabilidad')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.LongitudIncumplimiento')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.observaciones')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.cumplimiento')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {datosprueba[i]['elemento'].map((row, j) => (
                                                <TableRow>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['elemento'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['alineacion'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['condicionEstructural'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['estabilidad'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['longitudIncumplimiento'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['observaciones'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['cumplimiento'][j]}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ))}

                </Table>

            )
        } else if (indicador && tramo && ufunc && datosCarga && datosCarga.length > 0 && (indicadores === "13")) {
            let registros = datosCarga;
            let segmentos = [];
            let cumplimientoSegmento = [];
            let cumplimientoPuntual = [];
            let aux = -1;
            let datosprueba = [];
            //console.log(registros)
            registros.map((data, i) => {
                if (data.sg_codigo != aux) {
                    segmentos.push(data.sg_codigo);
                    aux = data.sg_codigo;
                }
            })
            registros.map((data, i) => {
                for (let i = 0; i < segmentos.length; i++) {
                    if (!datosprueba[i]) {
                        datosprueba[i] = {
                            elemento: [],
                            cumplimientol: [],
                            estadoFisico: [],
                            estadoSoporte: [],
                            estadoCentro: [],
                            estadoTablero: [],
                            observaciones: [],
                            cumplimiento: [],
                            fecha: [],
                            estado: [],
                            medidopor: []
                        };
                    }
                    if (data.sg_codigo === segmentos[i]) {
                        datosprueba[i]['elemento'].push(data.codInt ? data.codInt : data.elementoRed);
                        datosprueba[i]['cumplimientol'].push(data.cumplimiento);
                        datosprueba[i]['estadoFisico'].push(data.estadoFisico);
                        datosprueba[i]['estadoSoporte'].push(data.estadoSoporte);
                        datosprueba[i]['estadoCentro'].push(data.estadoCentro);
                        datosprueba[i]['estadoTablero'].push(data.estadoTablero);
                        datosprueba[i]['observaciones'].push(data.observaciones);
                        datosprueba[i]['fecha'].push(data.fecha);
                        datosprueba[i]['estado'].push(data.estado);
                        datosprueba[i]['medidopor'].push(data.medido_por);
                    }
                }
            })
            //console.log(datosprueba)
            for (let i = 0; i < datosprueba.length; i++) {
                let cumplimiento = "No Cumple";
                let maxPorcentaje = valorpromedio;
                let cuenta = 0;
                let cuenta2 = 0;
                let cuentaElementos = 0;
                for (let j = 0; j < datosprueba[i]['elemento'].length; j++) {
                    cuenta = 0;
                    cuentaElementos++;
                    if (datosprueba[i]['cumplimientol'][j] !== translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.estadoa.Bueno') && datosprueba[i]['cumplimientol'][j] !== translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica')) {
                        cuenta++;
                    }
                    if (datosprueba[i]['estadoFisico'][j] !== translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.estadoa.Bueno') && datosprueba[i]['estadoFisico'][j] !== translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica')) {
                        cuenta++;
                    }
                    if (datosprueba[i]['estadoSoporte'][j] !== translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.estadoa.Bueno') && datosprueba[i]['estadoSoporte'][j] !== translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica')) {
                        cuenta++;
                    }
                    if (datosprueba[i]['estadoCentro'][j] !== translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.estadoa.Bueno') && datosprueba[i]['estadoCentro'][j] !== translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica')) {
                        cuenta++;
                    }
                    if (datosprueba[i]['estadoTablero'][j] !== translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.estadoa.Bueno') && datosprueba[i]['estadoTablero'][j] !== translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica')) {
                        cuenta++;
                    }
                    if (cuenta > 0) {
                        datosprueba[i]['cumplimiento'][j] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')
                        cuenta2++;
                    } else {
                        datosprueba[i]['cumplimiento'][j] = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')
                    }
                }
                let porcentaje = 0;
                let totalLuminariasSeg = this.state.totalLum[segmentos[i]]
                if (totalLuminariasSeg > 0) { porcentaje = (cuenta2 / totalLuminariasSeg) * 100; }
                else {
                    porcentaje = (cuenta2 / cuentaElementos) * 100;
                }

                if (porcentaje > maxPorcentaje) {
                    cumplimiento = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')
                } else {
                    cumplimiento = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')
                }
                cumplimientoSegmento.push(cumplimiento);
                cumplimientoPuntual.push(`${porcentaje}`);
            }

            //console.log(segmentos)
            //console.log(promedioI)
            const MostrarDetalles = (valor, valor2) => {
                //alert("Prueba"+valor);
                //console.log(valor)
                let det = document.getElementById('detalles_' + valor)
                let most = document.getElementById('mostrar_' + valor)
                if (det) {
                    let disp = det.style.display;
                    if (disp != "none") {
                        det.style.display = "none"
                    }
                    else {
                        det.style.display = ""
                    }
                }
                if (most) {
                    let html = most.innerHTML;
                    if (html != "Mostrar") {
                        most.innerHTML = "Mostrar"
                    }
                    else {
                        most.innerHTML = "Ocultar"
                    }
                }

                //console.log(valor2)
            }
            return (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.SEGMENTO')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.fechaEvaluacion')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.estado')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.medidoPor')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.porcentajeElemento')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.cumplimientoIndicador')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.visualizar')}</TableCell>
                        </TableRow>
                    </TableHead>
                    {segmentos.map((row, i) => (
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ textAlign: "left" }}>{segmentos[i]}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{this.ultimaFecha(datosprueba[i]['fecha'])}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['estado'][0]}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['medidopor'][0]}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{this.formatovalores(cumplimientoPuntual[i], 3)}</TableCell>
                                {cumplimientoSegmento[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left", color: "red" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                }
                                {cumplimientoSegmento[i] != translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                }
                                <TableCell style={{ textAlign: "right" }}><span id={"mostrar_" + i} style={{ color: "blue", cursor: "pointer" }} onClick={(evt) => MostrarDetalles(i, evt)}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')}</span></TableCell>
                            </TableRow>
                            <TableRow style={{ display: "none" }} id={"detalles_" + i}>
                                <TableCell colSpan="8">
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.elemento')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.funcionamientoLuminaria')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.estadofisicoLuminaria')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.estadoSoportes')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.estadoCentroMando')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.estadoTableroControl')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.observaciones')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.cumplimiento')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {datosprueba[i]['elemento'].map((row, j) => (
                                                <TableRow>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['elemento'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['cumplimientol'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['estadoFisico'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['estadoSoporte'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['estadoCentro'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['estadoTablero'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['observaciones'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['cumplimiento'][j]}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ))}

                </Table>

            )
        } else if (indicador && tramo && ufunc && datosCarga && datosCarga.length > 0 && indicadores === "14") {
            let registros = datosCarga;
            let puentes = [];
            let puentes_id = [];
            let fecha_inspeccion = [];
            let estado = [];
            let medido_por = [];
            let cumplimientoGeneral = []

            let aux = -1;
            let datosprueba = [];
            //console.log(registros)
            registros.map((data, i) => {
                if (data.puente_id != aux) {
                    puentes.push(data.puente);
                    puentes_id.push(data.puente_id);
                    fecha_inspeccion.push(data.fecha_inicio_inspeccion);
                    estado.push(data.estado);
                    medido_por.push(data.medido_por);
                    aux = data.puente_id;
                }
            })
            registros.map((data, i) => {
                for (let i = 0; i < puentes.length; i++) {
                    if (!datosprueba[i]) {
                        datosprueba[i] = {
                            total_area_afectada: [],
                            porcentaje_area_afectada: [],
                            deterioros_juntas_expansion: [],
                            deterioros_andenes_bordillos: [],
                            deterioros_barreras_seguridad: [],
                            fisuras_estructura: [],
                            exposicion_acero_refuerzo: [],
                            corrosion: [],
                            deterioros_drenaje: [],
                            cumplimiento: []
                        };
                    }
                    if (data.puente_id === puentes_id[i]) {
                        datosprueba[i]['total_area_afectada'].push(data.total_area_afectada);
                        datosprueba[i]['porcentaje_area_afectada'].push(data.porcentaje_area_afectada);
                        datosprueba[i]['deterioros_juntas_expansion'].push(data.deterioros_juntas_expansion);
                        datosprueba[i]['deterioros_andenes_bordillos'].push(data.deterioros_andenes_bordillos);
                        datosprueba[i]['deterioros_barreras_seguridad'].push(data.deterioros_barreras_seguridad);
                        datosprueba[i]['fisuras_estructura'].push(data.fisuras_estructura);
                        datosprueba[i]['exposicion_acero_refuerzo'].push(data.exposicion_acero_refuerzo);
                        datosprueba[i]['corrosion'].push(data.corrosion);
                        datosprueba[i]['deterioros_drenaje'].push(data.deterioros_drenaje);
                        datosprueba[i]['cumplimiento'].push(data.cumplimiento);
                    }
                }
            })
            //console.log(datosprueba)
            for (let i = 0; i < datosprueba.length; i++) {
                let cumplimiento = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple');
                for (let j = 0; j < datosprueba[i]['cumplimiento'].length; j++) {
                    if (datosprueba[i]['cumplimiento'][j] == translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')) {
                        cumplimiento = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple');
                    }
                }
                cumplimientoGeneral.push(cumplimiento);
                console.log(cumplimiento)
            }
            const MostrarDetalles = (valor, valor2) => {
                //alert("Prueba"+valor);
                //console.log(valor)
                let det = document.getElementById('detalles_' + valor)
                let most = document.getElementById('mostrar_' + valor)
                if (det) {
                    let disp = det.style.display;
                    if (disp != "none") {
                        det.style.display = "none"
                    }
                    else {
                        det.style.display = ""
                    }
                }
                if (most) {
                    let html = most.innerHTML;
                    if (html != "Mostrar") {
                        most.innerHTML = "Mostrar"
                    }
                    else {
                        most.innerHTML = "Ocultar"
                    }
                }

                //console.log(valor2)
            }
            return (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.puente')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.fechaInspeccion')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.estado')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.medidoPor')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.cumplimiento')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.visualizar')}</TableCell>
                        </TableRow>
                    </TableHead>
                    {puentes.map((row, i) => (
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ textAlign: "left" }}>{puentes[i]}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{fecha_inspeccion[i]}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{estado[i]}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{medido_por[i]}</TableCell>
                                {cumplimientoGeneral[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left", color: "red" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                }
                                {cumplimientoGeneral[i] != translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                }

                                <TableCell style={{ textAlign: "right" }}><span id={"mostrar_" + i} style={{ color: "blue", cursor: "pointer" }} onClick={(evt) => MostrarDetalles(i, evt)}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')}</span></TableCell>
                            </TableRow>
                            <TableRow style={{ display: "none" }} id={"detalles_" + i}>
                                <TableCell colspan="6">
                                    <Table aria-label="simple table" style={{ width: "50%" }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.totalAreaAfectada')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.porcentajeAreaAfectada')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.deterioroJuntas')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.deterioroAndenes')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.deterioroBarreras')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.fisuraEstructura')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.exposicionAcero')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.corrosion')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.deterioroDrenaje')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.cumplimiento')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {datosprueba[i]['cumplimiento'].map((row, j) => (
                                                <TableRow>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['total_area_afectada'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['porcentaje_area_afectada'][j]}%</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['deterioros_juntas_expansion'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['deterioros_andenes_bordillos'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['deterioros_barreras_seguridad'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['fisuras_estructura'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['exposicion_acero_refuerzo'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['corrosion'][j]}</TableCell>
                                                    <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['deterioros_drenaje'][j]}</TableCell>
                                                    {datosprueba[i]['cumplimiento'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                                        <TableCell style={{ textAlign: "left", color: "red" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                                    }
                                                    {datosprueba[i]['cumplimiento'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple') &&
                                                        <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                                    }
                                                    {datosprueba[i]['cumplimiento'][j] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica') &&
                                                        <TableCell style={{ textAlign: "left", color: "red" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noAplica')}</TableCell>
                                                    }
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ))}

                </Table>

            )
        } else if (indicador && tramo && ufunc && datosCarga && datosCarga.length > 0 && indicadores === "15") {
            let registros = datosCarga;
            let segmentos = [];
            let cumplimientoCalzada = []
            let promedioCarrilI = [];
            let promedioCarrilD = [];
            let incumplimientosPuntuales = [];

            let aux = -1;
            let datosprueba = [];
            //console.log(registros)
            registros.map((data, i) => {
                if (data.sg_codigo != aux) {
                    segmentos.push(data.sg_codigo);
                    aux = data.sg_codigo;
                }
            })
            registros.map((data, i) => {
                for (let i = 0; i < segmentos.length; i++) {
                    if (!datosprueba[i]) {
                        datosprueba[i] = {
                            carrilIzq: [],
                            carrilDer: [],
                            abscisa: [],
                            valormedio: [],
                            niveltrafico: []
                        };
                    }
                    if (data.sg_codigo === segmentos[i]) {
                        datosprueba[i]['abscisa'].push(data.abscisa);
                        datosprueba[i]['carrilIzq'].push(data.carrilIzquierdo);
                        datosprueba[i]['carrilDer'].push(data.carrilDerecho);
                        datosprueba[i]['valormedio'].push(data.valorMedio);
                        datosprueba[i]['niveltrafico'].push(data.nivelTrafico);
                    }
                }
            })
            //console.log(datosprueba)
            for (let i = 0; i < datosprueba.length; i++) {
                let cumplimiento = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple');
                let cumplimientoIzq = 0;
                let cumplimientoDer = 0;
                let averageizq = 0;
                let averageder = 0;
                let cuenta = 0;
                let valormedioTemp = datosprueba[i]['valormedio'][0];
                for (let j = 0; j < datosprueba[i]['abscisa'].length; j++) {
                    cuenta++;
                    averageizq += parseFloat(datosprueba[i]['carrilIzq'][j]);
                    averageder += parseFloat(datosprueba[i]['carrilDer'][j]);
                    if (parseFloat(datosprueba[i]['carrilIzq'][j]) > valormedioTemp) {
                        cumplimientoIzq++;
                    }
                    if (parseFloat(datosprueba[i]['carrilDer'][j]) > valormedioTemp) {
                        cumplimientoDer++;
                    }
                }
                if ((cumplimientoIzq + cumplimientoDer) <= 0) {
                    cumplimiento = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')
                }
                let promediotempizq = averageizq / cuenta;
                let promediotempder = averageder / cuenta;
                promedioCarrilI.push(promediotempizq.toFixed(2));
                promedioCarrilD.push(promediotempder.toFixed(2));
                incumplimientosPuntuales.push(cumplimientoIzq + cumplimientoDer);
                cumplimientoCalzada.push(cumplimiento);
                console.log(datosprueba[i]['carrilIzq'])
            }
            const MostrarDetalles = (valor, valor2) => {
                //alert("Prueba"+valor);
                //console.log(valor)
                let det = document.getElementById('detalles_' + valor)
                let most = document.getElementById('mostrar_' + valor)
                if (det) {
                    let disp = det.style.display;
                    if (disp != "none") {
                        det.style.display = "none"
                    }
                    else {
                        det.style.display = ""
                    }
                }
                if (most) {
                    let html = most.innerHTML;
                    if (html != "Mostrar") {
                        most.innerHTML = "Mostrar"
                    }
                    else {
                        most.innerHTML = "Ocultar"
                    }
                }

                //console.log(valor2)
            }
            return (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.SEGMENTO')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.promedioCarrilIzquierdo')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.promedioCarrilDerecho')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.incumplimientosPuntuales')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.cumplimientoIndicador')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.visualizar')}</TableCell>
                        </TableRow>
                    </TableHead>
                    {segmentos.map((row, i) => (
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ textAlign: "left" }}>{segmentos[i]}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{promedioCarrilI[i]}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{promedioCarrilD[i]}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{incumplimientosPuntuales[i]}</TableCell>
                                {cumplimientoCalzada[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "right", color: "red" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                }
                                {cumplimientoCalzada[i] != translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "right" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                }

                                <TableCell style={{ textAlign: "right" }}><span id={"mostrar_" + i} style={{ color: "blue", cursor: "pointer" }} onClick={(evt) => MostrarDetalles(i, evt)}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')}</span></TableCell>
                            </TableRow>
                            <TableRow style={{ display: "none" }} id={"detalles_" + i}>
                                <TableCell colSpan="6">
                                    <Table aria-label="simple table" style={{ width: "50%" }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.nivelTrafico')} {datosprueba[i]['niveltrafico'][0]}</TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.abscisa')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.carrilIzquierdo')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.carrilDerecho')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {datosprueba[i]['abscisa'].map((row, j) => (
                                                <TableRow>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(datosprueba[i]['abscisa'][j])}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['carrilIzq'][j], 2)}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['carrilDer'][j], 2)}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ))}

                </Table>

            )
        } else if (indicador && tramo && ufunc && datosCarga && datosCarga.length > 0 && indicadores === "21") {
            let registros = datosCarga;
            let segmentos = [];
            let cumplimientoSegmento = [];
            let incumplimientosPuntuales = [];
            let aux = -1;
            let datosprueba = [];
            //console.log(registros)
            registros.map((data, i) => {
                if (data.sg_codigo != aux) {
                    segmentos.push(data.sg_codigo);
                    aux = data.sg_codigo;
                }
            })
            registros.map((data, i) => {
                for (let i = 0; i < segmentos.length; i++) {
                    if (!datosprueba[i]) {
                        datosprueba[i] = {
                            indicador: [],
                            abscisa: [],
                            observaciones: [],
                            fecha: [],
                            estado: [],
                            medidopor: [],
                        };
                    }
                    if (data.sg_codigo === segmentos[i]) {
                        datosprueba[i]['indicador'].push(data.indicador);
                        datosprueba[i]['abscisa'].push(data.abscisa);
                        datosprueba[i]['observaciones'].push(data.observaciones);
                        datosprueba[i]['fecha'].push(data.fecha);
                        datosprueba[i]['estado'].push(data.estado);
                        datosprueba[i]['medidopor'].push(data.medido_por);
                    }
                }
            })
            for (let i = 0; i < datosprueba.length; i++) {
                let cumplimiento = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple');
                let cuenta = 0;
                for (let j = 0; j < datosprueba[i]['abscisa'].length; j++) {
                    if (parseFloat(datosprueba[i]['indicador'][j]) > valorpuntual) {
                        cuenta++;
                    }
                }
                if (cuenta === 0) {
                    cumplimiento = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple');
                }
                cumplimientoSegmento.push(cumplimiento);
                incumplimientosPuntuales.push(cuenta);
            }

            //console.log(segmentos)
            //console.log(promedioI)
            const MostrarDetalles = (valor, valor2) => {
                //alert("Prueba"+valor);
                //console.log(valor)
                let det = document.getElementById('detalles_' + valor)
                let most = document.getElementById('mostrar_' + valor)
                if (det) {
                    let disp = det.style.display;
                    if (disp != "none") {
                        det.style.display = "none"
                    }
                    else {
                        det.style.display = ""
                    }
                }
                if (most) {
                    let html = most.innerHTML;
                    if (html != "Mostrar") {
                        most.innerHTML = "Mostrar"
                    }
                    else {
                        most.innerHTML = "Ocultar"
                    }
                }

                //console.log(valor2)
            }
            return (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.SEGMENTO')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.fechaEvaluacion')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.estado')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.medidoPor')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.incumplimientosPuntuales')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.cumplimiento')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.visualizar')}</TableCell>
                        </TableRow>
                    </TableHead>
                    {segmentos.map((row, i) => (
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ textAlign: "left" }}>{segmentos[i]}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{this.ultimaFecha(datosprueba[i]['fecha'])}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['estado'][0]}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['medidopor'][0]}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{incumplimientosPuntuales[i]}</TableCell>
                                {cumplimientoSegmento[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left", color: "red" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                }
                                {cumplimientoSegmento[i] != translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                }

                                <TableCell style={{ textAlign: "right" }}><span id={"mostrar_" + i} style={{ color: "blue", cursor: "pointer" }} onClick={(evt) => MostrarDetalles(i, evt)}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')}</span></TableCell>
                            </TableRow>
                            <TableRow style={{ display: "none" }} id={"detalles_" + i}>
                                <TableCell colSpan="6">
                                    <Table aria-label="simple table" style={{ width: "50%" }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.abscisa')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.Escalonamiento')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.observaciones')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {datosprueba[i]['abscisa'].map((row, j) => (
                                                <TableRow>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(datosprueba[i]['abscisa'][j])}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['indicador'][j], 2)}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['observaciones'][j]}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ))}

                </Table>
            )
        } else if (indicador && tramo && ufunc && datosCarga && datosCarga.length > 0 && indicadores === "22") {
            let registros = datosCarga;
            let segmentos = [];
            let cumplimientoSegmento = [];
            let incumplimientosPuntuales = [];
            let aux = -1;
            let datosprueba = [];
            // console.log(registros)
            registros.map((data, i) => {
                if (data.sg_codigo != aux) {
                    segmentos.push(data.sg_codigo);
                    aux = data.sg_codigo;
                }
            })
            registros.map((data, i) => {
                for (let i = 0; i < segmentos.length; i++) {
                    if (!datosprueba[i]) {
                        datosprueba[i] = {
                            indicador: [],
                            abscisa: [],
                            observaciones: [],
                            fecha: [],
                            estado: [],
                            medidopor: [],
                        };
                    }
                    // console.log(data);
                    if (data.sg_codigo === segmentos[i]) {
                        datosprueba[i]['abscisa'].push(data.abscisa);
                        datosprueba[i]['indicador'].push(data.alturaDesportillamiento);
                        datosprueba[i]['observaciones'].push(data.observaciones);
                        datosprueba[i]['fecha'].push(data.fecha);
                        datosprueba[i]['estado'].push(data.estado);
                        datosprueba[i]['medidopor'].push(data.medido_por);
                    }
                }
            })
            for (let i = 0; i < datosprueba.length; i++) {
                let cumplimiento = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple');
                let cuenta = 0;
                for (let j = 0; j < datosprueba[i]['abscisa'].length; j++) {
                    // console.log("Valor Puntual -> " + datosprueba[i]['indicador'][j])
                    if (parseFloat(datosprueba[i]['indicador'][j]) > valorpuntual) {
                        cuenta++;
                    }
                }
                console.log(cuenta + " <-> " + valorpromedio)
                if (cuenta < valorpromedio) {
                    cumplimiento = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple');
                }
                cumplimientoSegmento.push(cumplimiento);
                incumplimientosPuntuales.push(cuenta);
            }

            //console.log(segmentos)
            //console.log(promedioI)
            const MostrarDetalles = (valor, valor2) => {
                //alert("Prueba"+valor);
                //console.log(valor)
                let det = document.getElementById('detalles_' + valor)
                let most = document.getElementById('mostrar_' + valor)
                if (det) {
                    let disp = det.style.display;
                    if (disp != "none") {
                        det.style.display = "none"
                    }
                    else {
                        det.style.display = ""
                    }
                }
                if (most) {
                    let html = most.innerHTML;
                    if (html != "Mostrar") {
                        most.innerHTML = "Mostrar"
                    }
                    else {
                        most.innerHTML = "Ocultar"
                    }
                }

                //console.log(valor2)
            }
            return (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.SEGMENTO')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.fechaEvaluacion')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.estado')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.medidoPor')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.incumplimientosPuntuales')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.cumplimiento')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.visualizar')}</TableCell>
                        </TableRow>
                    </TableHead>
                    {segmentos.map((row, i) => (
                        <TableBody>
                            <TableRow>
                                {/* {console.log(datosprueba)} */}
                                <TableCell style={{ textAlign: "left" }}>{segmentos[i]}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{this.ultimaFecha(datosprueba[i]['fecha'])}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['estado'][0]}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['medidopor'][0]}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{incumplimientosPuntuales[i]}</TableCell>
                                {cumplimientoSegmento[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left", color: "red" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                }
                                {cumplimientoSegmento[i] != translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                }

                                <TableCell style={{ textAlign: "right" }}><span id={"mostrar_" + i} style={{ color: "blue", cursor: "pointer" }} onClick={(evt) => MostrarDetalles(i, evt)}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')}</span></TableCell>
                            </TableRow>
                            <TableRow style={{ display: "none" }} id={"detalles_" + i}>
                                <TableCell colSpan="6">
                                    <Table aria-label="simple table" style={{ width: "50%" }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.abscisa')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.ExtensionDesportillam')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.observaciones')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.cumplimiento')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {datosprueba[i]['abscisa'].map((row, j) => (
                                                <TableRow>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(datosprueba[i]['abscisa'][j])}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['indicador'][j], 2)}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['observaciones'][j]}</TableCell>
                                                    {datosprueba[i]['indicador'][j] > valorpuntual &&
                                                        <TableCell style={{ textAlign: "left", color: "red" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                                    }
                                                    {datosprueba[i]['indicador'][j] <= valorpuntual &&
                                                        <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                                    }
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ))}

                </Table>
            )

        } else if (indicador && tramo && ufunc && datosCarga && datosCarga.length > 0 && indicadores === "23") {
            let registros = this.abscisasEspeciales(datosCarga);
            let segmentos = [];
            let cumplimientoSegmento = [];
            let incumplimientosPuntuales = [];
            let areaAfectada = [];
            let aux = -1;
            let datosprueba = [];
            // console.log(registros)
            registros.map((data, i) => {
                if (data.sg_codigo != aux) {
                    segmentos.push(data.sg_codigo);
                    aux = data.sg_codigo;
                }
            })
            registros.map((data, i) => {
                for (let i = 0; i < segmentos.length; i++) {
                    if (!datosprueba[i]) {
                        datosprueba[i] = {
                            indicador: [],
                            abscisa: [],
                            observaciones: [],
                            fecha: [],
                            estado: [],
                            medidopor: [],
                            longJuntaSegmento: 0,
                            longJuntasAcum: 0
                        };
                    }
                    // console.log(data);
                    if (data.sg_codigo === segmentos[i]) {
                        datosprueba[i]['abscisa'].push(data.abscisa);
                        datosprueba[i]['indicador'].push(data.longitudJuntaAfectada);
                        datosprueba[i]['observaciones'].push(data.observaciones);
                        datosprueba[i]['longJuntaSegmento'] = data.longJuntaSegmento;
                        datosprueba[i]['longJuntasAcum'] += parseFloat(data.longitudJuntaAfectada);
                        datosprueba[i]['fecha'].push(data.fecha);
                        datosprueba[i]['estado'].push(data.estado);
                        datosprueba[i]['medidopor'].push(data.medido_por);
                    }
                }
            })

            let cuenta = 0;
            let criteria = "";
            for (let i = 0; i < datosprueba.length; i++) {
                let cumplimiento = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple');
                let criteria = this.calculoAreaAfectada(datosprueba[i]['longJuntaSegmento'], datosprueba[i]['longJuntasAcum']);
                if (criteria < 3) {
                    cumplimiento = translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple');
                    cuenta++;
                }
                cumplimientoSegmento.push(cumplimiento);
                areaAfectada.push(criteria)
            }
            incumplimientosPuntuales.push(cuenta);
            //console.log(segmentos)
            //console.log(promedioI)
            const MostrarDetalles = (valor, valor2) => {
                //alert("Prueba"+valor);
                //console.log(valor)
                let det = document.getElementById('detalles_' + valor)
                let most = document.getElementById('mostrar_' + valor)
                if (det) {
                    let disp = det.style.display;
                    if (disp != "none") {
                        det.style.display = "none"
                    }
                    else {
                        det.style.display = ""
                    }
                }
                if (most) {
                    let html = most.innerHTML;
                    if (html != "Mostrar") {
                        most.innerHTML = "Mostrar"
                    }
                    else {
                        most.innerHTML = "Ocultar"
                    }
                }

                //console.log(valor2)
            }
            return (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.SEGMENTO')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.fechaEvaluacion')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.estado')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.medidoPor')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.areaAfectada')}</TableCell>
                            <TableCell style={{ textAlign: "center" }} >{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.cumplimiento')}</TableCell>
                            <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.visualizar')}</TableCell>
                        </TableRow>
                    </TableHead>
                    {segmentos.map((row, i) => (
                        <TableBody>
                            <TableRow>
                                {/* {console.log(datosprueba)} */}
                                <TableCell style={{ textAlign: "left" }}>{segmentos[i]}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{this.ultimaFecha(datosprueba[i]['fecha'])}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['estado'][0]}</TableCell>
                                <TableCell style={{ textAlign: "left" }}>{datosprueba[i]['medidopor'][0]}</TableCell>
                                {/* <TableCell style={{ textAlign: "right" }}>{incumplimientosPuntuales[i]}</TableCell> */}

                                <TableCell style={{ textAlign: "right" }}>{areaAfectada[i].toFixed(2)}%</TableCell>
                                {cumplimientoSegmento[i] === translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple') &&
                                    <TableCell style={{ textAlign: "left", color: "red" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.noCumple')}</TableCell>
                                }
                                {cumplimientoSegmento[i] != translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple') &&
                                    <TableCell style={{ textAlign: "left" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.Cumple')}</TableCell>
                                }

                                <TableCell style={{ textAlign: "right" }}><span id={"mostrar_" + i} style={{ color: "blue", cursor: "pointer" }} onClick={(evt) => MostrarDetalles(i, evt)}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableHead.mostrar')}</span></TableCell>
                            </TableRow>
                            <TableRow style={{ display: "none" }} id={"detalles_" + i}>
                                <TableCell colSpan="6">
                                    <Table aria-label="simple table" style={{ width: "50%" }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.abscisa')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.LongitudJuntasAfectadas')}</TableCell>
                                                <TableCell style={{ textAlign: "center" }}>{translate('dynatest.resources.indicadores_cargar_informacions.table.tableVisualizacion.observaciones')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {datosprueba[i]['abscisa'].map((row, j) => (
                                                <TableRow>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatoabscisa(datosprueba[i]['abscisa'][j])}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{this.formatovalores(datosprueba[i]['indicador'][j], 2)}</TableCell>
                                                    <TableCell style={{ textAlign: "right" }}>{datosprueba[i]['observaciones'][j]}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ))}

                </Table>
            )

        } else if (indicadores === "17") {
            return (
                <div>
                    <h2>{translate('dynatest.resources.indicadores_cargar_informacions.messagesVal.noTieneVisualizacion')}</h2>
                </div>
            )
        }
        else if (estado === 0) {
            return (
                <div>
                    <h2>{translate('dynatest.resources.indicadores_cargar_informacions.messagesVal.noSeEncontro')}</h2>
                </div>
            )
        } else {
            return (
                <div className="Reload2">
                    <img src={loader} />
                </div>
            )
        }
    }
}
export default translate(VisualizacionModal);