import React, { Fragment } from 'react';
import { Loading } from 'react-admin';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official';
import config from '../../../config/config';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import LinkIcon from '@material-ui/icons/Link';
import TableWithPagination from "./../localHelpers/TableWithPagination"
import { translate } from 'react-admin';
let urlAppjs = config.UrlServer;

class VisualizacionModal extends React.Component {
    constructor(props) {
        const { translate } = props;
        super(props)
        this.state = {
            Elementos: [],
            ElementosParaTabla: [],
            excelFile: {},
            isLoadedElements: false,
            highcharts: {
                options: {
                    title: {
                        text: translate('dynatest.resources.costos.tableDatos.columnNames.costoPorMantenimiento') 
                    },
                    yAxis: {
                        title: {
                            text: translate('dynatest.resources.costos.tableDatos.columnNames.costoEn') 
                        }
                    },
                    xAxis: {
                        title: {
                            text: translate('dynatest.resources.costos.tableDatos.columnNames.tiempo') 
                        },
                        categories: []

                    },

                    plotOptions: {
                        series: {
                            label: { connectorAllowed: false },
                            pointStart: 0
                        }
                    },

                    series: [
                        {
                            name: translate('dynatest.resources.costos.select.tipoMantenimientos.Rutinario') ,
                            data: [],
                            type: 'column'
                        },
                        {
                            name: translate('dynatest.resources.costos.select.tipoMantenimientos.Periodico'),
                            data: [],
                            type: 'column'
                        },
                        {
                            name: translate('dynatest.resources.costos.select.tipoMantenimientos.Rehabilitacion'),
                            data: [],
                            type: 'column'
                        },
                        {
                            name: translate('dynatest.resources.costos.select.tipoMantenimientos.Cambio'),
                            data: [],
                            type: 'column'
                        }
                    ]
                },
            },
            consolidados: {
                ru: 0,
                pe: 0,
                re: 0,
                ca: 0
            },
            isDialogOpen: false,
            isModalMessageOpen: false,
            uniqueTipoMantenimiento: [],
        }
        this.stagedProps = {
            unidadFuncional: this.props.unidadFuncional,
            tramo: this.props.tramo,
            segmento: this.props.segmento,
            sistema: this.props.sistema,
            tipoElemento: this.props.tipoElemento,
            tipoElementoSenal: this.props.tipoElementoSenal,
            tipoSenal: this.props.tipoSenal,
            abInicio: this.props.abInicio,
            abFin: this.props.abFin,
            tipoMantenimiento: this.props.tipoMantenimiento,
            fechaInicial: this.props.fechaInicial,
            fechaFinal: this.props.fechaFinal,
            proveedor: this.props.proveedor,
            consolidarPor: this.props.consolidarPor
        }
        this.CurrencyFormatter = new Intl.NumberFormat("es-CO", { style: "currency", currency: "COP", minimumFractionDigits: 0 })
        this.fetcher.bind(this);
        this.formatFecha.bind(this);
        this.mantToText.bind(this);
        this.generateDateRange.bind(this);
        this.compareDates.bind(this);
        this.dialog.bind(this);
        this.modalMessage.bind(this);
        this.exportar.bind(this);
    }
    componentDidMount() {
        if (
            typeof this.props.unidadFuncional !== "undefined" && this.props.unidadFuncional !== null
        ) {
            this.fetcher(
                "costo_elementos",
                this.props.segmento,
                this.formatAbscisa(this.props.abInicio),
                this.formatAbscisa(this.props.abFin),
            );
        } else if (
            typeof this.props.unidadFuncional !== "undefined" && this.props.unidadFuncional !== null &&
            typeof this.props.tipoElementoSenal !== "undefined" && this.props.tipoElementoSenal !== null &&
            typeof this.props.tipoSenal !== "undefined" && this.props.tipo !== null
        ) {
            this.fetcher(
                "costo_elementos",
                this.props.segmento,
                this.formatAbscisa(this.props.abInicio),
                this.formatAbscisa(this.props.abFin),
            );
        }
    }
    componentDidUpdate() {
        if (
            this.stagedProps.unidadFuncional != this.props.unidadFuncional ||
            this.stagedProps.tramo != this.props.tramo ||
            this.stagedProps.segmento != this.props.segmento ||
            this.stagedProps.sistema != this.props.sistema ||
            this.stagedProps.tipoElemento != this.props.tipoElemento ||
            this.stagedProps.tipoElementoSenal != this.props.tipoElementoSenal ||
            this.stagedProps.tipoSenal != this.props.tipoSenal ||
            this.stagedProps.abInicio != this.props.abInicio ||
            this.stagedProps.abFin != this.props.abFin ||
            this.stagedProps.tipoMantenimiento != this.props.tipoMantenimiento ||
            this.stagedProps.fechaInicial != this.props.fechaInicial ||
            this.stagedProps.fechaFinal != this.props.fechaFinal ||
            this.stagedProps.proveedor != this.props.proveedor ||
            this.stagedProps.consolidarPor != this.props.consolidarPor
        ) {
            this.stagedProps.unidadFuncional = this.props.unidadFuncional
            this.stagedProps.tramo = this.props.tramo;
            this.stagedProps.segmento = this.props.segmento;
            this.stagedProps.sistema = this.props.sistema;
            this.stagedProps.tipoElemento = this.props.tipoElemento;
            this.stagedProps.tipoElementoSenal = this.props.tipoElementoSenal;
            this.stagedProps.tipoSenal = this.props.tipoSenal;
            this.stagedProps.abInicio = this.props.abInicio;
            this.stagedProps.abFin = this.props.abFin;
            this.stagedProps.tipoMantenimiento = this.props.tipoMantenimiento;
            this.stagedProps.fechaInicial = this.props.fechaInicial;
            this.stagedProps.fechaFinal = this.props.fechaFinal;
            this.stagedProps.proveedor = this.props.proveedor;
            this.stagedProps.consolidarPor = this.props.consolidarPor;

            this.state.Elementos = [];

            this.state.highcharts.options.series[0].data = [];
            this.state.highcharts.options.series[1].data = [];
            this.state.highcharts.options.series[2].data = [];
            this.state.highcharts.options.series[3].data = [];
            this.state.highcharts.options.xAxis.categories = [];

            this.state.consolidados.ru = 0;
            this.state.consolidados.pe = 0;
            this.state.consolidados.re = 0;
            this.state.consolidados.ca = 0;

            this.state.excelFile = {}


            this.componentDidMount();
        }
    }
    fetcher(target, segmento = null, abInicio, abFin, otherFilters = null) {
        this.setState({ isLoadedElements: 'loading' });

        var urlPart = "/" + target + "?pagination=off" +
            "&unidadFuncional.ruta.proyecto=" + localStorage.getItem("id_project") +
            "&unidadFuncional.ruta.proyecto=" + localStorage.getItem("id_project") +
            "&unidadFuncional=" + this.props.unidadFuncional +
            "&order[tipoMantenimiento]=ASC";

        if (this.props.tramo != null) {
            urlPart = urlPart + "&tramo=" + this.props.tramo
        }

        if (this.props.fechaInicial && this.props.fechaInicial != null) {
            urlPart = urlPart + "&fechaMantenimiento[after]=" + this.props.fechaInicial
        }

        if (this.props.fechaFinal && this.props.fechaFinal != null) {
            urlPart = urlPart + "&fechaMantenimiento[before]=" + this.props.fechaFinal
        }

        if (this.props.proveedor && this.props.proveedor != null) {
            urlPart = urlPart + "&proveedor=" + this.props.proveedor
        }

        if (this.props.tipoElemento && this.props.tipoElemento != null) {
            urlPart = urlPart + "&tipoElemento=" + this.props.tipoElemento;
        }
        if (this.props.tipoElementoSenal && this.props.tipoElementoSenal != null){
            urlPart = urlPart + "&tipoElementoSenal=" + this.props.tipoElementoSenal;
        }
        if(this.props.tipoSenal && this.props.tipoSenal != null) {
            urlPart = urlPart + "&tipoSenal=" + this.props.tipoSenal;
        }
        if(this.props.sistema && this.props.sistema != null) {
            urlPart = urlPart + "&costo.sistema=" + this.props.sistema;
        }
        if (segmento && segmento != null) {
            urlPart = urlPart + "&segmento=" + segmento;
        }
        if(abInicio > 0 && abFin > 0){
            //urlPart = urlPart + "&costo.segmento.abInicio[between]=" + abInicio + ".." + abFin;
            //urlPart = urlPart + "&costo.segmento.abFin[between]=" + abInicio + ".." + abFin;
            urlPart = urlPart + "&segmento.abInicio[between]=" + abInicio + ".." + abFin;
            urlPart = urlPart + "&segmento.abFin[between]=" + abInicio + ".." + abFin;
        }else if (abInicio > 0) {
            //urlPart = urlPart + "&costo.segmento.abInicio[gte]=" + abInicio;
            urlPart = urlPart + "&segmento.abInicio[gte]=" + abInicio;
        }else if (abFin > 0) {
            //urlPart = urlPart + "&costo.segmento.abFin[lte]=" + abFin;
            urlPart = urlPart + "&segmento.abFin[lte]=" + abFin;
        }
        if (Array.isArray(otherFilters)) {
            otherFilters.map(filter => {
                urlPart = urlPart + "&" + filter;
            })
        } else if (otherFilters !== null) {
            urlPart = urlPart + "&" + otherFilters;
        }

        if (Array.isArray(this.props.tipoMantenimiento)) {
            this.state.uniqueTipoMantenimiento = this.props.tipoMantenimiento.filter((valor, indice) => { return this.props.tipoMantenimiento.indexOf(valor) === indice; })
        }

        fetch(urlAppjs + urlPart,
            {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                return response.json()
            })
            .then((Elementos) => {
                var dateSorted = Elementos["hydra:member"].sort(this.compareDates)
                var categories = {};

                this.state.ElementosParaTabla = Elementos["hydra:member"].filter(elemento => this.state.uniqueTipoMantenimiento.includes(elemento.tipoMantenimiento))

                dateSorted.map((item, index) => {
                    if (!categories.hasOwnProperty(this.formatFecha(item.fechaMantenimiento, this.props.consolidarPor))) {
                        categories[this.formatFecha(item.fechaMantenimiento, this.props.consolidarPor)] = {
                            ru: 0,
                            pe: 0,
                            re: 0,
                            ca: 0
                        }
                    }
                    categories[this.formatFecha(item.fechaMantenimiento, this.props.consolidarPor)][item.tipoMantenimiento] += item.costoMantenimiento;
                    this.state.consolidados[item.tipoMantenimiento] += item.costoMantenimiento;
                })
                var keys = Object.keys(categories);
                this.state.highcharts.options.xAxis.categories = keys;
                keys.map(key => {
                    if (this.state.uniqueTipoMantenimiento.includes("ru")) {
                        this.state.highcharts.options.series[0].data.push(categories[key].ru);
                    }
                    if (this.state.uniqueTipoMantenimiento.includes("pe")) {
                        this.state.highcharts.options.series[1].data.push(categories[key].pe);
                    }
                    if (this.state.uniqueTipoMantenimiento.includes("re")) {
                        this.state.highcharts.options.series[2].data.push(categories[key].re);
                    }
                    if (this.state.uniqueTipoMantenimiento.includes("ca")) {
                        this.state.highcharts.options.series[3].data.push(categories[key].ca);
                    }
                })
                this.setState({
                    Elementos: Elementos["hydra:member"],
                    isLoadedElements: true,
                })
            })
            .catch(function (error) {
                console.log(error)
            });
    }

    formatAbscisa(abscisa) {
        if (typeof abscisa == "string") {
            let Abinc = abscisa.split("+");
            Abinc[0] = Abinc[0].replace("K", "");
            let ingresoAbInicio = Abinc[0] + "." + Abinc[1];

            return ingresoAbInicio;
        }
        else {
            return abscisa;
        }
    }
    formatFecha(fecha, type = undefined) {
        var day = "";
        var month = "";
        var year = "";
        var store = "";
        if (fecha != null) {
            store = fecha.split("T");
            store = store[0].split("-");
            year = store[0];
            month = store[1];
            day = store[2];

            switch (type) {
                case "month/year":
                    return month + "/" + year
                case "year":
                    return year
                default: return day + "/" + month + "/" + year
            }
        }
    }
    exportar() {
        var params = JSON.stringify({
            unidadFuncional: this.props.unidadFuncional.split("/")[2],
            tramo: this.props.tramo ? this.props.tramo.split("/")[2] : null,
            segmento: (this.props.segmento != null) ? this.props.segmento.split("/")[2] : null,
            sistema: (this.props.sistema != null) ? this.props.sistema.split("/")[2] : null,
            tipoElemento: (this.props.tipoElemento != null) ? this.props.tipoElemento.split("/")[2] : null,
            tipoElementoSenal: (this.props.tipoElementoSenal != null) ? this.props.tipoElementoSenal.split("/")[2] : null,
            tipoSenal: (this.props.tipoSenal != null) ? this.props.tipoSenal.split("/")[2] : null,
            abInicio: (this.props.abInicio) ? this.formatAbscisa(this.props.abInicio) : null,
            abFin: (this.props.abFin) ? this.formatAbscisa(this.props.abFin) : null,
            tipoMantenimiento: this.props.tipoMantenimiento ? this.props.tipoMantenimiento : null,
            fechaInicial: this.props.fechaInicial ? this.props.fechaInicial : null,
            fechaFinal: this.props.fechaFinal ? this.props.fechaFinal : null,
            proveedor: this.props.proveedor ? this.props.proveedor.split("/")[2] : null,
        })
        console.log(params)
        var urlPart = "/costos/excel/" + params;
        fetch(urlAppjs + urlPart, { headers: { 'authorization': 'Bearer ' + localStorage.getItem('token') } })
            .then((response) => { return response.json() })
            .then((json) => {
                this.setState({
                    excelFile: json,
                    isModalMessageOpen: true
                })
            })
            .catch(function (error) {
                console.log(error)
            });
    }
    dialog(buttonText, header, msg) {
        const handleClickOpen = () => {
            this.setState({ isDialogOpen: true });
        };
        const handleClose = () => {
            this.setState({ isDialogOpen: false });
        };
        const handleFetch = () => {
            this.exportar();
            this.setState({ isDialogOpen: false });
        }
        return (
            <div>
                <Button size="small" color="primary" onClick={handleClickOpen}> {buttonText}  </Button>
                <Dialog
                    open={this.state.isDialogOpen}
                    onClose={handleClose}
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title"> {header} </DialogTitle>
                    <DialogContent>
                        <DialogContentText> {msg} </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button autoFocus onClick={handleClose} color="primary"> No </Button>
                        <Button onClick={handleFetch} color="primary"> Si </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
    modalMessage(buttonText, header, msg) {
        const handleClickOpen = () => {
            this.setState({ isModalMessageOpen: true });
        };
        const handleClose = () => {
            this.setState({ isModalMessageOpen: false });
        };
        return (
            <div>
                <Button size="small" color="primary" onClick={handleClickOpen}> {buttonText}  </Button>
                <Dialog
                    open={this.state.isModalMessageOpen}
                    onClose={handleClose}
                    aria-labelledby="draggable-dialog-title"
                >
                    <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title"> {header} </DialogTitle>
                    <DialogContent>
                        <DialogContentText> {msg} </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary"> Ok </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
    mantToText(value) {
        var returnValue = ""
        switch (value) {
            case "ru": returnValue = "Rutinario"; break;
            case "pe": returnValue = "Periódico"; break;
            case "re": returnValue = "Rehabilitación"; break;
            case "ca": returnValue = "Cambio"; break;

        }

        return returnValue;
    }
    generateDateRange(startDate, endDate) {
        var begin = new Date(startDate);
        console.log(begin instanceof Date)
        var end = new Date(endDate);
        var ndaysAsArray = [];
        var nDays = (end - begin) / (1000 * 3600 * 24);
        for (var i = 0; i <= nDays; i++) {
            ndaysAsArray.push(begin.getDate() + "-" + (begin.getMonth() + 1) + "-" + begin.getFullYear());
            begin = new Date(begin.setDate(begin.getDate() + 1));
        }
        console.log(ndaysAsArray)
        return ndaysAsArray;
    }
    compareDates(a, b) {
        const bandA = a.fechaMantenimiento;
        const bandB = b.fechaMantenimiento;

        let comparison = 0;
        if (bandA > bandB) {
            comparison = 1;
        } else if (bandA < bandB) {
            comparison = -1;
        }
        return comparison;
    }

    render() {
        const { translate } = this.props;
        if (this.state.isLoadedElements == 'loading') {
            return <Loading />
        } else if (this.state.isLoadedElements == true) {
            return (
                <Fragment>
                    <TableWithPagination
                        label= {translate("dynatest.resources.costos.exportar.datos")}
                        identityColumn="codigo"
                        columnNames={[
                            { id: 'tipoMantenimiento', name: translate('dynatest.resources.costos.tableDatos.columnNames.tipo') },
                            { id: 'fechaMantenimiento2', name: translate('dynatest.resources.costos.tableDatos.columnNames.fecha') },
                            { id: 'costoMantenimiento', name: translate('dynatest.resources.costos.tableDatos.columnNames.costo')},
                            { id: 'razonSocial', name: translate('dynatest.resources.costos.tableDatos.columnNames.proveedor') },
                            { id: 'calificacion', name: translate('dynatest.resources.costos.tableDatos.columnNames.calificacion')},
                        ]}
                        formatColumn={[
                            { id: 'fechaMantenimiento2', formatTo: 'date' },
                            { id: 'costoMantenimiento', formatTo: 'currency' },
                            { id: 'calificacion', formatTo: "number" }
                        ]}
                        arrayData={this.state.ElementosParaTabla}
                        headerLeft={this.state.ElementosParaTabla.length > 0 &&
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                {this.dialog(<CloudDownloadIcon />, translate('dynatest.resources.costos.exportar.exportarBusqueda'), translate('dynatest.resources.costos.exportar.busquedaActual'))}
                                {this.state.excelFile.hasOwnProperty("success") &&
                                    this.modalMessage(<LinkIcon />, translate('dynatest.resources.costos.exportar.enlace'), <a href={urlAppjs + "/" + this.state.excelFile.success.name}>{this.state.excelFile.success.name}</a>)
                                }
                            </div>
                        }

                    />
                    <hr />
                    <Table >
                        <TableBody>
                            {this.state.uniqueTipoMantenimiento.includes("ru") &&
                                <TableRow>
                                    <TableCell>{translate('dynatest.resources.costos.tableDatos.columnNames.rutinario')}</TableCell>
                                    <TableCell style={{textAlign: 'end'}}>{this.CurrencyFormatter.format(this.state.consolidados.ru)}</TableCell>
                                </TableRow>
                            }
                            {this.state.uniqueTipoMantenimiento.includes("pe") &&
                                <TableRow>
                                    <TableCell>{translate('dynatest.resources.costos.tableDatos.columnNames.periodico')}</TableCell>
                                    <TableCell style={{textAlign: 'end'}}>{this.CurrencyFormatter.format(this.state.consolidados.pe)}</TableCell>
                                </TableRow>
                            }
                            {this.state.uniqueTipoMantenimiento.includes("re") &&
                                <TableRow>
                                    <TableCell>{translate('dynatest.resources.costos.tableDatos.columnNames.rehabilitacion')}</TableCell>
                                    <TableCell style={{textAlign: 'end'}}>{this.CurrencyFormatter.format(this.state.consolidados.re)}</TableCell>
                                </TableRow>
                            }
                            {this.state.uniqueTipoMantenimiento.includes("ca") &&
                                <TableRow>
                                    <TableCell>{translate('dynatest.resources.costos.tableDatos.columnNames.cambio')}</TableCell>
                                    <TableCell style={{textAlign: 'end'}}>{this.CurrencyFormatter.format(this.state.consolidados.ca)}</TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={this.state.highcharts.options}
                    />
                </Fragment>
            )
        } else if (this.state.isLoadedElements == false) {
            return <span></span>
        }
    }
}

export default translate(VisualizacionModal);