import React, { useState } from 'react';
import { ArrayInput, SimpleFormIterator, NumberInput, ChipField, ReferenceArrayField, Datagrid, TabbedForm, FormTab, TextInput, SelectInput, SimpleList, SingleFieldList, ShowController, ShowView, SelectField, TextField, DateField, ArrayField, ReferenceField, NumberField, BooleanField } from 'react-admin';
import { withStyles } from '@material-ui/core';
import { PRField } from '../../fields/PRField';
import Divider from '@material-ui/core/Divider';
import { GMapField } from '../../fields/GMapField';
//import Reparaciones from './campos/viewreparacion';
import Typography from '@material-ui/core/Typography';
import { styles } from '../../EstilosGrid';
import { ButtonCarousel } from '../images/ButtonCarousel';
import { ShowToolbar } from '../componentes/ShowToolbar';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { translate } from 'react-admin';

const choices1 = [
    { _id: '01', full_name: '01' },
    { _id: '02', full_name: '02' },
    { _id: '03', full_name: '03' },
];
const choices = [
    { _id: '01', full_name: 'Circular' },
    { _id: '02', full_name: 'Rectangular' },
    { _id: '00', full_name: 'Otra' },
];
const optionRenderer = choice => `${choice.id}`;
const choises2 = [
    { id: '01 ', name: ' 01 ' },
    { id: '02 ', name: ' 02 ' },
    { id: '03 ', name: ' 03 ' },
    { id: '04 ', name: ' 04 ' },
    { id: '05 ', name: ' 05 ' },
    { id: '00 ', name: ' 00 ' },
    { id: 'N/A ', name: ' N/A ' },
]
const choises3 = [
    { id: '01 ', name: ' Balancín ' },
    { id: '02 ', name: ' Rodillo ' },
    { id: '03 ', name: ' Placa en neopreno ' },
    { id: '04 ', name: ' Apoyo fijo ' },
    { id: '05 ', name: ' Basculante ' },
    { id: '00 ', name: ' Otros ' },
    { id: 'N/A ', name: ' No aplica ' },
];

export const RegistroNovedadShow = translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;
    const [imagen, setImagen] = useState(true);
    const [latitud, setLatitud] = useState();
    const [longitud, setLongitud] = useState();
    const Latitud = (value) => {
        setLatitud(value)
    }
    const Longitud = (value) => {
        setLongitud(value)
    }
    const ValidarImagen = (value) => {
        // console.log(value + "Aqui est la imagen ")
        if (value !== null && value !== undefined && value !== 'undefined') {
            let lenghtObject = Object.keys(value);
            let valor = '';
            for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
            setImagen(value)
            // console.log(value + "Aqui est la imagen ")
        }
    }

    const [tipo_elementos, setTipoElementos] = useState('');
    const ValidarTipoElementos = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setTipoElementos(value);
    }

    const [sistema, setSistema] = useState(null);
    const ValidarSistema = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setSistema(value);
    }

    return (
        <ShowController {...props} >
            {controllerProps =>
                <ShowView {...props} {...controllerProps} title="dynatest.resources.registro_novedads.title.showTitle" actions={<ShowToolbar />}>
                    <TabbedForm toolbar={false}>
                        <FormTab label="dynatest.resources.registro_novedads.tabs.identificacion">
                            <TextInput source="src" validate={ValidarImagen} style={{ display: 'none' }} />
                            <TextField label="resources.registro_novedads.fields.nombre" source="nombre" fullWidth formClassName={classes.grid_cont3} />
                            <SelectField label="resources.registro_novedads.fields.estado" source="estado" choices={[
                                { id: translate('dynatest.resources.registro_novedads.select.estado.Activo'), name: 'dynatest.resources.registro_novedads.select.estado.Activo' },
                                { id: translate('dynatest.resources.registro_novedads.select.estado.Inactivo'), name: 'dynatest.resources.registro_novedads.select.estado.Inactivo' },
                            ]} fullWidth formClassName={classes.grid_cont4} />
                            <NumberField label="resources.registro_novedads.fields.umbralFallo" source="umbralFallo" fullWidth style={{ marginLeft: '70px' }} formClassName={classes.grid_cont3} />
                            <ReferenceField label="resources.registro_novedads.fields.proyecto" source="proyecto" reference="proyectos" linkType={false} fullWidth formClassName={classes.grid_cont3}>
                                <TextField source="nombre" />
                            </ReferenceField>
                            <ReferenceField label="resources.registro_novedads.fields.sistema" source="sistema" reference="sistemas" linkType={false} fullWidth validate={[ValidarTipoElementos, ValidarSistema]} formClassName={classes.grid_cont4}>
                                <TextField source="nombre" />
                            </ReferenceField>
                            {controllerProps.record && controllerProps.record.sistema === '/sistemas/SEN' &&
                                <ReferenceField label="resources.registro_novedads.fields.tipoElementoSenal" source="tipoElementoSenal" reference="tipo_elemento_senals" linkType={false} fullWidth filter={{ sistema: tipo_elementos }} formClassName={classes.grid_cont3}>
                                    <TextField source="nombre" />
                                </ReferenceField>
                            }

                            {controllerProps.record && controllerProps.record.sistema === '/sistemas/SEN' &&
                                <ReferenceField label="resources.registro_novedads.fields.tipoSenal" source="tipoSenal" reference="tipo_senals" linkType={false} fullWidth filter={{ sistema: tipo_elementos }} formClassName={classes.grid_cont3}>
                                    <TextField source="nombre" />
                                </ReferenceField>
                            }

                            {controllerProps.record && controllerProps.record.sistema !== '/sistemas/SEN' &&
                                <ReferenceField label="resources.registro_novedads.fields.tipoElemento" source="tipoElemento" reference="tipo_elementos" linkType={false} fullWidth filter={{ sistema: tipo_elementos }} formClassName={classes.grid_cont3}>
                                    <TextField source="nombre" />
                                </ReferenceField>
                            }
                        </FormTab>
                        <FormTab label="dynatest.resources.registro_novedads.tabs.preguntas">
                            <ArrayField label="" source="preguntaRegistroNovedad" fieldKey="uuid" fullWidth formClassName={classes.grid_cont12}>
                                <Datagrid>
                                    <TextField label="resources.registro_novedads.fields.pregunta" source="pregunta" fullWidth formClassName={classes.grid_cont3} linkType={false} />
                                    <NumberField label="resources.registro_novedads.fields.peso" source="peso" fullWidth formClassName={classes.grid_cont3} linkType={false} />
                                    <SelectField label="resources.registro_novedads.fields.tipoCampo" source="tipoCampo" allowEmpty emptyValue="" choices={[
                                        { id: translate('dynatest.resources.registro_novedads.select.tipoCampo.seleccionMultiple'), name: "dynatest.resources.registro_novedads.select.tipoCampo.seleccionMultiple" },
                                        { id: translate('dynatest.resources.registro_novedads.select.tipoCampo.numerico'), name: "dynatest.resources.registro_novedads.select.tipoCampo.numerico" },
                                    ]} fullWidth formClassName={classes.grid_cont3} />
                                    <SelectField label="resources.registro_novedads.fields.estado" source="estado" allowEmpty emptyValue="" choices={[
                                        { id: translate('dynatest.resources.registro_novedads.select.estado.Activo'), name: 'dynatest.resources.registro_novedads.select.estado.Activo' },
                                        { id: translate('dynatest.resources.registro_novedads.select.estado.Inactivo'), name: 'dynatest.resources.registro_novedads.select.estado.Inactivo' },
                                    ]} fullWidth formClassName={classes.grid_cont3} />
                                    
                                    <ArrayField label="resources.registro_novedads.fields.respuestasMult" source="respuestaRegistroNovedad" reference="respuesta_registro_novedad" fieldKey="uuid" fullWidth formClassName={classes.grid_cont12}>
                                        <Datagrid>
                                            <TextField label="resources.registro_novedads.fields.respuesta" source="respuesta" fullWidth formClassName={classes.grid_cont3} linkType={false} />
                                            <NumberField label="resources.registro_novedads.fields.peso" source="peso" fullWidth formClassName={classes.grid_cont3} linkType={false} />
                                        </Datagrid>
                                        {/* <SingleFieldList>
                                            <ChipField source="respuesta" />
                                        </SingleFieldList> */}
                                    </ArrayField>
                                    <NumberField label="resources.registro_novedads.fields.decimales" source="decimales" fullWidth formClassName={classes.grid_cont3} />
                                    <NumberField label="resources.registro_novedads.fields.rangoInicial" source="rangoInicial" fullWidth formClassName={classes.grid_cont3} />
                                    <NumberField label="resources.registro_novedads.fields.rangoFinal" source="rangoFinal" fullWidth formClassName={classes.grid_cont3} />

                                </Datagrid>
                            </ArrayField>
                        </FormTab>

                    </TabbedForm>
                </ShowView>
            }
        </ShowController>

    )
}
));
const WithProps = ({ children, ...props }) => children(props);