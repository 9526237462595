import React from 'react';
import NumberFormat from 'react-number-format';


export default function eFormatter(props){
    const { inputRef, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            
            decimalSeparator='.'
            decimalScale={1}
            fixedDecimalScale={true}
            allowNegative={false}            
        />
      );
}