import React, {Component, Fragment } from 'react';
import { Loading, TabbedForm, FormTab, Toolbar, SaveButton, translate, showNotification } from 'react-admin';
import { connect } from 'react-redux';
import { useEffect, useRef } from "react";
import config from '../../config/config';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
var apigClientFactory = require('aws-api-gateway-client').default;
let LanguajeQuickSight = config.LanguajeQuickSight;
let urlAppjs = config.UrlServer;

export class ConfigQuicksight extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = { 
            isOnline: null,
            defaultData: ""
        };
        this.errores = "";
        this.embedUrl = "";
        this.dashboardRef = React.createRef();
        this.requestApiAmazon = this.requestApiAmazon.bind(this);
    }

    async componentDidMount(){
        await fetch(urlAppjs + '/reporte_quicksights?environment=Production', {
            method: 'GET',
            headers: {
                'authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
        .then(res => res.json())
        .then((success) => {
            if(success["hydra:member"].length >= 1){
                this.setState({defaultData: success["hydra:member"]});
            }
        })
    }


    setReportId = (dataNew, item) => {
        let dataReplace = this.state.defaultData;
        for (let i = 0; i < dataReplace.length; i++) {
            if(dataReplace[i].nameReport == item.nameReport){
                dataReplace[i].uuidReport = dataNew;
            };
        }
        this.setState({defaultData: dataReplace});
    }

    requestApiAmazon() {
        document.getElementById("btnConfirmar").style.display = 'none';
        document.getElementById("circleProgress").style.display = '';
        let dataUpdate = this.state.defaultData;
        let countData = dataUpdate.length -1;
        dataUpdate.map((itemSend, index) => {
            fetch(urlAppjs + itemSend['@id'], {
                method: 'PUT',
                body: JSON.stringify(itemSend),
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/json'
                }
            })
            .then(res => res.json())
            .then((success) => {
                if (countData == index) {
                    window.location.reload();
                }
            })

        });
    }

    render(){

        const TabbForm = translate(({ label, translate }) => (
            <TabbedForm toolbar={false}>
                <FormTab label={translate('dynatest.resources.pimsMenu.subMenu.ReportesConfig')}>
                    <br></br>
                    <Grid container spacing={24}>
                        {this.state.defaultData.map((item) => {
                            return(
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        id={item['@id']}
                                        label={item.nameReport}
                                        onBlur={(data) => this.setReportId(data.target.value, item)}
                                        defaultValue={item.uuidReport}
                                        fullWidth/>
                                </Grid>
                            );
                        })
                        }
                        <Grid item xs={12}>
                            <Button variant="contained" color="primary" id="btnConfirmar" onClick={this.requestApiAmazon} style={{ backgroundColor: '#3f51b5' }}  >
                                {translate('dynatest.resources.config_sistema.btnConfirmar')}
                            </Button>
                            &nbsp; &nbsp; &nbsp;
                            <CircularProgress id='circleProgress' size={24} style={{display: 'none'}}/>
                        </Grid>
                    </Grid>
                </FormTab>
            </TabbedForm>
        ));


        return (
            <Fragment>
                {this.state.defaultData != "" && 
                    <TabbForm/>
                }
                
                {this.state.defaultData == "" &&
                    <Loading />
                }
            </Fragment>
        );
    }
}

export default translate((ConfigQuicksight));