import React from 'react';
import { SimpleForm, AutocompleteInput, ArrayInput, SimpleFormIterator, DateInput, TextInput, ImageInput, ImageField, 
    ReferenceInput
} from 'react-admin';

import { withStyles } from '@material-ui/core';
import { PimsCreate } from './../../Create';

import TablaDeterioro from './tabla_deterioros';

import { required, minValue, maxValue } from 'react-admin';  
    
import { ButtonCarousel } from './../../images/ButtonCarousel';

const styles = {
    first_field: { display: 'inline-block', width: '30%' },
    last_filed: { display: 'inline-block', marginLeft: 32, width: '30%' },
    separador: { marginTop: 40, marginLeft: 100, marginRight: 100, marginBottom: 0},
    subtittle: {marginTop: "15px"},
};
 
export const ElementoDeterioroCreate = withStyles(styles)(({ classes, ...props }) => {

    const [Fecha, setFecha] = React.useState("dd / mm / aaaa");    
    const [FechaAux, setFechaAux] = React.useState("dd / mm / aaaa");     
    const [idsegmento, setIdSegmento] = React.useState("/segmentos");     

    const dias = ["Domingo","Lunes","Martes","Miercoles","Jueves","Viernes","Sabado"];
    const mes = ["Enero","Febrero","Marzo","Abril","Mayo","Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];    
    
    //OBTENER FECHA ACTUAL (Calculo Fecha Próxima)
    const FechaActual = (value) =>{                
        
        if(value){                                  
            let e = new Date(value.replace(/-/g, '\/'))    
            e.setMonth(e.getMonth());            
            setFecha(dias[e.getDay()]+" "+(e.getDate())+" "+mes[e.getMonth()]+" del "+e.getFullYear());
            setFechaAux(e.getFullYear()+"-"+(e.getMonth()+1)+"-"+(e.getDate()));
        }        
           
    }

    //OBTENER PERIOCIDAD (Calculo Fecha Próxima)
    const PeriodicidadMes = (value) => {                

        if(Fecha !== "dd / mm / aaaa"){
            let d = new Date(FechaAux.replace(/-/g, '\/'));            
            d.setMonth(d.getMonth() + parseInt(value));           
            setFecha(dias[d.getDay()]+" "+(d.getDate())+" "+mes[d.getMonth()]+" del "+d.getFullYear());                        
        }
        
    };

    const ObtenerValorSegmento = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                                
        setIdSegmento(valor)
    }

    // actions={<ButtonCarousel/>}
    return (
    <PimsCreate {...props} title="Crear deterioro" >
        <SimpleForm redirect="list">      
            <ReferenceInput 
                label="segmento" 
                source="segmento"
                reference="segmentos" 
                onChange={ObtenerValorSegmento}
                filterToQuery={searchText => ({ codigo: searchText })} 
                sort={{ 
                    field: 'codigo', 
                    order: 'ASC' ,
                    
                }}>
                <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
            </ReferenceInput>
            {/* <div></div>
            <NumberInput source="deterioros_pci" label="PCI" validate={[required(), minValue(0), maxValue(100)]}/>
            <div></div>
            <DateInput source="deterioros_fecha_ultima" label="Fecha de última evaluación" formClassName={classes.first_field} validate={[required(),FechaActual]}/> 
            <SelectInput source="deterioros_periocidad" label="Periodicidad de medición (mes)" choices={[
                    { id: '1', name: '1' },
                    { id: '2', name: '2' },
                    { id: '3', name: '3' },
                    { id: '4', name: '4' },
                    { id: '5', name: '5' },                    
                ]} formClassName={classes.last_filed} validate={[required(),PeriodicidadMes]}/>             
            <DisabledInput label="Fecha próxima medición" formClassName={classes.last_filed} defaultValue={Fecha}/>            
            <NumberInput source="deterioros_ancho_long" label="Ancho longitudinales(m)" />
            <div></div>
            <div className={classes.subtittle}><strong>Deterioro</strong></div>
            <hr fullWidth />      
            <TablaDeterioro segmento={idsegmento} />          
            <div></div> */}
            <div className={classes.subtittle}><strong>Imagen</strong></div>
            <hr fullWidth />    
            <ArrayInput label="" source="images" defaultValue={[{images : ""}]} >
                <SimpleFormIterator disableAdd disableRemove >
                    <ImageInput multiple={true} source="images" label=" " maxSize={1000000000} accept="image/jpeg, image/png">
                        <ImageField source="src" title="title" />
                    </ImageInput>                 
                </SimpleFormIterator>
            </ArrayInput>  
            {/* <ImageInput multiple={true} source="images" label=" " accept="image/jpeg, image/png" maxSize="20000000">
                    <ImageField source="src" title="title"/>
            </ImageInput> */}
            {/* <TextInput source="deterioros_observaciones" label="Observaciones" />                  */}
        </SimpleForm>
    </PimsCreate>
    )
});