import React from 'react';
import NumberFormat from 'react-number-format';


export default function aFormatter(props) {
    const { inputRef, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            prefix="$"
            decimalSeparator=','
            fixedDecimalScale={false}
            allowNegative={false}
        />
    );
}