import React from 'react';
import { CardActions,EditButton,ListButton,TabbedShowLayout, Tab, TextField, DateField, WithPermissions, NumberField
} from 'react-admin';
import { Show } from './../Show';
import { PField } from './../../fields/PField';
import { PRField } from './../../fields/PRField';


const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

export const UnidadFuncionalShow = props => (
    <Show title="dynatest.resources.unidad_funcionals.title.showTitle" {...props}>
        <TabbedShowLayout>
            <Tab label="dynatest.resources.unidad_funcionals.tabs.informacionBasica">          
                <TextField label="resources.unidad_funcionals.fields.codigo" source="fullCode" />    
                <span></span>  
                <span></span>         
                <PRField source="abInicio"/>
                <PRField source="abFin"/> 
                <NumberField source="fullResta" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}}/>   
                <PField source="pInicio"/>
                <PField source="pFin"/>       
            </Tab>
            <Tab label="dynatest.resources.unidad_funcionals.tabs.auditoria">
            <DateField source="createdAt" showTime/> 
                <TextField source="createdBy" />
                <span></span>               
                <DateField source="updatedAt" showTime/>
                <TextField source="updatedBy" />
                <span></span>    
            </Tab>
        </TabbedShowLayout>
    </Show>
);