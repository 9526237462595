import React, { useState } from 'react';
import {translate} from 'react-admin';
import {
  withGoogleMap,
  withScriptjs,
  Marker,
  GoogleMap,
  Polygon,
  InfoWindow,
  Polyline
} from "react-google-maps";
import config from '../config/config';
let urlAppjs = config.UrlFront;
// const { compose, withProps, withStateHandlers } = require("recompose");

export const MapWithAMarker = translate(withScriptjs(withGoogleMap(

  props => {
    const {
      ubicaciones,
      //   prf,
      //   url,
      defaultCenter,
      redirect,
      positions,
      //   info,
      //   demarcacion
      info,
      kmlmapa,
      numIntervalos,
      indInv,
      largokml,
      umbrales,
      
    } = props;


    
    const {translate} = props;
    const [windows, setWindow] = useState(false);
    const [markerKey, setMarkerKey] = useState('');

    const ShowWindow = (i) => {
      setWindow(true);
      setMarkerKey(i)
      // debugger
    }

    const HiddenWindow = () => {
      setWindow(false);
    }
    let redirects = [];
    //   let posicion1 = [ demarcacion[0] , demarcacion[1] ,demarcacion[2] ,demarcacion[3]]
    let urlAppjs1 = urlAppjs + "elemento_senalizacion_perus/%2Felemento_senalizacion_perus%2F";

    let pathCoordinates = '';
    if (info !== undefined) {
      pathCoordinates = info.map((mrk, i) => {
        //console.log(Array.isArray(mrk))
        if (mrk.icon !== '/imagenes/senalesColombia/defecto') {
          return ({
            ubicaciones: mrk.ubicaciones,
            elemento: mrk.elemento,
            icon: new window.google.maps.MarkerImage(
              mrk.icon,
              null, /* size is determined at runtime */
              null, /* origin is 0,0 */
              null, /* anchor is bottom center of the scaled image */
              new window.google.maps.Size(45, 45),
            ),
            codigo: mrk.codigo,
            abInicio: mrk.abInicio,
            tipoElemento: mrk.tipoElemento,
            unidadFuncional: mrk.unidadFuncional,
            tramo: mrk.tramo,
            segmento: mrk.segmento,
            // url: urlAppjs + mrk.url + '/%2F' + mrk.url + '%2F'
            url: (typeof mrk === "object" && !Array.isArray(mrk)) ? urlAppjs + mrk.url.split("/")[1] + '/%2F' + mrk.url.split("/")[1] + '%2F' + mrk.url.split("/")[2] : null
          })
        } else {
          return ({
            ubicaciones: mrk.ubicaciones,
            elemento: mrk.elemento,
            icon: mrk.icon
          })
        }
      })

    }


    // let urlAppjs1 = urlAppjs + "elemento_senalizacion_perus/%2Felemento_senalizacion_perus%2F" ;

    const putMarkers = () => {
     
      if (pathCoordinates !== undefined && pathCoordinates !== '') {
        return (
          pathCoordinates.map((mrk, i) => {
            return (
              <div>
                {/* {console.log("i: ")}*/}
                {/* {console.log(i)} */}
                <Marker
                  key={`mrk${i}`}
                  position={mrk.ubicaciones}
                  icon={mrk.icon}
                  onClick={(e) => { ShowWindow(i) }}
                >

                  {windows && markerKey === i &&
                    <div id='mapaProyect'>

                      <InfoWindow key={`mrk${i}`} id='mapaProyect' className='mapeos' onCloseClick={HiddenWindow}>
                        <div >
                          {/* <h3>{mrk.nombre.nom}</h3> */}
                          <p><strong>{translate('resources.elementos.fields.codigo')}: </strong>{mrk.codigo}</p>
                          <p><strong>{translate('resources.elementos.fields.elemento')}: </strong>{mrk.elemento}</p>
                          <p><strong>{translate('resources.elementos.fields.unidadFuncional')}: </strong>{mrk.unidadFuncional}</p>
                          <p><strong>{translate('resources.elementos.fields.tramo')}: </strong>{mrk.tramo}</p>
                          <p><strong>{translate('resources.elementos.fields.segmento')}: </strong>{mrk.segmento}</p>
                          <p><strong>{translate('resources.elementos.fields.absInicial')}: </strong>{mrk.abInicio}</p>
                          {/* <a href={mrk.url + redirect[i] + '/show'} target="_blank">Ver más...</a> */}
                          <a href={mrk.url + '/show'} target="_blank">{translate('resources.elementos.fields.verMas')}</a>
                        </div>
                      </InfoWindow>
                    </div>

                  }

                </Marker>
              </div>
            )

          })
        )

      } else {

        return (
          <Marker
            key={`mrk`}
            // position={mrk.position}
            icon={false}
          // onClick={ ()=> {ShowWindow(i)} }
          />
        )
      }

    }
    let cuenta = 0;
    return (
      <GoogleMap
        defaultZoom={9}
        defaultCenter={defaultCenter}
        showsCompass={true}
      >
        <Polygon
          path={positions}
          key={1}
          options={{
            fillColor: "#000",
            fillOpacity: 0.2,
            strokeColor: "#000",
            strokeOpacity: 1,
            strokeWeight: 1
          }}
        />
        {kmlmapa.map(({ unidadFuncional, valores, indicadores }) => valores.map((poli, i) => {
          cuenta++;
          let color = "#ff0000"; // rojo
          let largo = largokml;
          if (poli.indicador && poli.indicador.length > 0 && indInv === 1) {
            poli.indicador.map(indi => {
              let carril = poli.informacion.carril;
              let indicador = carril === "Derecho" ? parseFloat(indi['iricder']) : parseFloat(indi['iricizq'])

              // TIPO DE CONSULTA POR NUMERO DE INTERVALOS
              for (let ind = 0; ind <= numIntervalos; ind++) {
                if (ind == 0) {
                  if (indicador < parseFloat(umbrales['umbral' + (ind + 1)])) {
                    color = umbrales["color" + (ind + 1)];
                  }
                }else if (ind == numIntervalos) {
                  if (indicador >= parseFloat(umbrales['umbral' + (ind)])) {
                    color = umbrales["color" + (ind + 1)];
                  }
                }else{
                  if (parseFloat(umbrales['umbral' + (ind)]) <= indicador && indicador < parseFloat(umbrales['umbral' + (ind + 1)])) {
                    color = umbrales["color" + (ind + 1)];
                  }
                }
              }
            })
          }
          let visible = true;
          if (visible) {
            return (<Polyline
              path={poli.arreglo}
              options={{
                strokeColor: color
              }}
            />)
          }
        }))}
        {putMarkers()}
      </GoogleMap>
    )

  }
)));

export default translate(MapWithAMarker);