import React, { useState } from 'react';
import {
    FormTab, BooleanInput, TextInput, ArrayInput, SimpleFormIterator, ReferenceInput, SelectInput,
    FormDataConsumer,  NumberInput, AutocompleteInput, DateInput,ImageInput,ImageField
} from 'react-admin'
import { PRInput } from '../../../inputs/PRInput';
import { PInput } from '../../../inputs/PInput';
import { abValidationInicioEdit, validarDecimales3, validarDecimales4, validarDosDecimales, isFechaInvalida
} from './../../../helpers/validacionesInputs';
import { abValidationInicio, prValidationInicio,  
abValidationFin, validarLogicaAbsFinCreate, prValidationFin} from '../../validacionesInputs';
import { PimsElementoActions } from './../PimsElementoActions';
import { styles } from './../../../EstilosGrid';
import { withStyles } from '@material-ui/core';
import { Edit } from './../../Edit';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import CoordenadasProyecto from '../../CoordenadasInventario';
import AbsSegmento from '../../AbsSegmento';
import {
    required,
    maxLength,
    minValue,
    maxValue

} from 'react-admin';
import { TabbedForm } from './../../TabbedForm';
import { ButtonCarousel } from './../../images/ButtonCarouselPeru';
import {  latMininima, lngMininima, validacionmaxfechaact } from '../../validacionesInputs';
import { ArrayInputImagesEdit } from './../../images/ArrayInputImagesEdit';
import { translate } from 'react-admin';
const tipoElemento = { sistema: 'RDS' };

export const ElementoRedEdit = translate(withStyles(styles)(({ classes, record, ...props }) => {
    const { translate } = props;
    const [segmentos, setSegmento] = useState('');

    const ValidarSegmento = (value) => {
        setSegmento(value)

    }
    /*VALORES POR DEFECTO DEL ARRAY DE COORDENADAS*/
    const [latitudMinimaArray, setLatitudArray] = useState('');
    const LatitudMinimaArray = (value) => {
        setLatitudArray(value)
    }
    const [longitudMinimaArray, setLongitudArray] = useState('');
    const LongitudMinimaArray = (value) => {
        setLongitudArray(value)
    }
    const [longitudMaximaArray, setLongitudMaxdArray] = useState('');
    const LongitudMaximaArray = (value) => {
        setLongitudMaxdArray(value)
    }
    const [latitudMaximaArray, setLatitudMaxdArray] = useState('');
    const LatitudMaximaArray = (value) => {
        setLatitudMaxdArray(value)
    }
    /*FIN DE LOS VALORES POR DEFECTO DEL ARRAY DE COORDENADAS*/
    const [unidads, setUnidad] = useState('');
    const ValidarUnidad = (value) => {

        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setUnidad(value)
    }
    const [tramos, setTramo] = useState('');
    const ValidarTramo = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setTramo(value)
    }
    const [elemento, setElemento] = useState('');
    const ValidarElemento = (value) => {
        setElemento(value);
        console.log(value);
    }

/*VALIDAR IMÁGENES ACTUALES */
    const [imagenesOpen,setImagenesOpen] = useState('');
    const ImagenesEdit = (value) =>{
        setImagenesOpen(value);
        
    }


    return (
        <Edit title="dynatest.resources.redes.title.editTitle" undoable={false} {...props} >
            <TabbedForm redirect="list">
                <FormTab label="dynatest.resources.redes.tabs.tabsTitle.identificacion">
                    <CoordenadasProyecto/>
                    <Grid container spacing={24}>
                        <Grid item xs={4}>
                            <ReferenceInput 
                                label="resources.redes.fields.tipoElemento"
                                source="tipoElemento" 
                                disabled 
                                reference="tipo_elementos"
                                validate={[required(), ValidarElemento]} 
                                filter={tipoElemento} 
                                fullWidth 
                                formClassName={classes.grid_cont12}
                            >
                                <SelectInput optionText="nombre" />
                            </ReferenceInput>
                            <TextInput label="resources.redes.fields.tipoElemento" source="tipoElemento" className={classes.ocultar} id="elementos" reference="tipo_elementos" validate={[required()]} />
                        </Grid>
                        <Grid item xs={4}>
                            <SelectInput label="resources.redes.fields.margen" source="margen" allowEmpty emptyValue="" validate={[required()]} choices={[
                                { id: translate('dynatest.resources.redes.select.margen.Derecha'), name: 'dynatest.resources.redes.select.margen.Derecha' },
                                { id: translate('dynatest.resources.redes.select.margen.Izquierda'), name: 'dynatest.resources.redes.select.margen.Izquierda' },
                                { id: translate('dynatest.resources.redes.select.margen.Centro'), name: 'dynatest.resources.redes.select.margen.Centro' },
                            ]} fullWidth formClassName={classes.grid_cont12} />
                        </Grid>
                        <Grid item xs={4}>
                        <SelectInput label="resources.redes.fields.sentidoB" source='sentido' choices={[
                                { id: translate('dynatest.resources.redes.select.sentido.NORTEId'), name: 'dynatest.resources.redes.select.sentido.NORTEName' },
                                { id: translate('dynatest.resources.redes.select.sentido.SURId'), name: 'dynatest.resources.redes.select.sentido.SURName' },
                                { id: translate('dynatest.resources.redes.select.sentido.ESTEId'), name: 'dynatest.resources.redes.select.sentido.ESTEName' },
                                { id: translate('dynatest.resources.redes.select.sentido.OESTEId'), name: 'dynatest.resources.redes.select.sentido.OESTEName' }
                                ]}
                                fullWidth
                                formClassName={classes.grid_cont4}/>
                        </Grid>
                    </Grid>
                    {/* ------- LOCALIZACIÓN ----- */}
                    <Typography variant="strong" component="strong">{translate('dynatest.resources.redes.title.localizacion')}</Typography>
                    <Divider fullWidth formClassName={classes.grid_cont12} />

                    <Grid container spacing={24}>
                        <Grid item xs={4}>
                            <ReferenceInput
                                label="resources.redes.fields.unidadFuncional"
                                source="unidadFuncional"
                                reference="unidad_funcionals"
                                filterToQuery={searchText => ({ codigo: searchText })}
                                sort={{ field: 'codigo', order: 'asc' }}
                                validate={[required(), ValidarUnidad]}
                                fullWidth
                            >
                                <AutocompleteInput options={{ fullWidth: true }} optionText="fullCodigos" />
                            </ReferenceInput>
                        </Grid>

                        {unidads &&
                            <Grid item xs={4}>
                                <ReferenceInput
                                    label="resources.redes.fields.tramo"
                                    source="tramo"
                                    alwaysOn
                                    reference="tramos"
                                    filter={{ "properties[]" : "fullCodigos", unidadFuncional: unidads }}
                                    filterToQuery={searchText => ({ codigo: searchText })}
                                    sort={{ field: 'codigo', order: 'ASC', }}
                                    validate={[required(), ValidarTramo]}
                                    formClassName={classes.grid_cont4}
                                >
                                    <AutocompleteInput options={{ fullWidth: true }} optionText="fullCodigos" />
                                </ReferenceInput>
                            </Grid>
                        }
                        {tramos &&
                            <Grid item xs={4}>
                                <ReferenceInput
                                   label="resources.redes.fields.segmento" 
                                    source="segmento"
                                    reference="segmentos"
                                    sort={{ field: 'codigo', order: 'ASC' }}
                                    filter={{ "properties[]" : "fullCodigos", tramo: tramos }}
                                    filterToQuery={searchText => ({ codigo: searchText })}
                                    validate={[required(), ValidarSegmento]}
                                    formClassName={classes.grid_cont4}
                                >

                                    <AutocompleteInput options={{ fullWidth: true }} optionText="fullCodigos" />
                                </ReferenceInput>
                            </Grid>
                        }
                    </Grid>
                    {segmentos && <AbsSegmento segmento={segmentos} />}
 

                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <Typography variant="h3" component="h3" className={classes.coord}>{translate('dynatest.resources.redes.title.coordenadas')}</Typography>
                            <Divider fullWidth style={{ marginTop: '10px' }} />
                        </Grid>
                    </Grid>

                    <PRInput label="resources.redes.fields.abscisa" id="ingresoAbInicio" source="abInicio" fullWidth validate={[abValidationInicioEdit, required()]} formClassName={classes.grid_cont3s} />
                    <PInput label="resources.redes.fields.pr" id="ingresoPrInicio" source="pInicio" fullWidth validate={[prValidationInicio, /* required() */]} formClassName={classes.grid_cont3} />
                    <NumberInput label="resources.redes.fields.latitud" id="LatitudInicial" source="ubicaciones[0][lat]" fullWidth validate={[required(), latMininima, LatitudMinimaArray]} formClassName={classes.grid_cont3} />
                    <NumberInput label="resources.redes.fields.longitud" id="LongitudInicial" source="ubicaciones[0][lng]" fullWidth validate={[required(),lngMininima,LongitudMinimaArray]} formClassName={classes.grid_cont3} />

                    {segmentos && (elemento === "/tipo_elementos/DCT" || elemento === "/tipo_elementos/REE") &&
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Typography variant="h3" component="h3" className={classes.coord}>{translate('dynatest.resources.redes.title.coordenadasFinales')}</Typography>
                                <Divider fullWidth style={{ marginTop: '10px' }} />
                            </Grid>
                        </Grid>
                    }
                    {segmentos && (elemento === "/tipo_elementos/DCT" || elemento === "/tipo_elementos/REE") && 
                        <PRInput label="resources.redes.fields.abscisa" id="ingresoAbFin" source="abFin" fullWidth validate={[abValidationFin, validarLogicaAbsFinCreate]} formClassName={classes.grid_cont3s} />}
                    {segmentos && (elemento === "/tipo_elementos/DCT" || elemento === "/tipo_elementos/REE") && 
                        <PInput label="resources.redes.fields.pr" id="ingresoPrFin" source="pFin" fullWidth validate={[prValidationFin]} formClassName={classes.grid_cont3} />}
                    {segmentos && (elemento === "/tipo_elementos/DCT" || elemento === "/tipo_elementos/REE") && 
                        <NumberInput label="resources.redes.fields.latitud" source="ubicaciones[1][lat]" fullWidth validate={[latMininima, LatitudMaximaArray]} formClassName={classes.grid_cont3} />}
                    {segmentos && (elemento === "/tipo_elementos/DCT" || elemento === "/tipo_elementos/REE") && 
                        <NumberInput label="resources.redes.fields.longitud" source="ubicaciones[1][lng]" fullWidth validate={[lngMininima, LongitudMaximaArray]} formClassName={classes.grid_cont3} />}
                </FormTab>
                {/* ---------- CARACTERÍSTICAS ------------- */}
                <FormTab label="dynatest.resources.redes.tabs.tabsTitle.caracteristicas">
                    <FormDataConsumer>
                        {({ formData, ...rest }) => formData != null && formData.tipoElemento === "/tipo_elementos/LUM" &&
                            <Grid container spacing={24}>
                                <Grid item xs={4}>
                                    <SelectInput label="resources.redes.fields.entornoVia" source="entornoVia" allowEmpty emptyValue="" choices={[
                                        { id: translate('dynatest.resources.redes.select.entornoVia.claro'), name: "dynatest.resources.redes.select.entornoVia.claro" },
                                        { id: translate('dynatest.resources.redes.select.entornoVia.oscuro'), name: "dynatest.resources.redes.select.entornoVia.oscuro" },
                                    ]} validate={[required()]} fullWidth />
                                </Grid>
                                <Grid item xs={4}>
                                    <SelectInput label="resources.redes.fields.claseIluminacion" source="claseIluminacion" allowEmpty emptyValue="" choices={[
                                        { id: translate('dynatest.resources.redes.select.claseIluminacion.M1'), name: "dynatest.resources.redes.select.claseIluminacion.M1" },
                                        { id: translate('dynatest.resources.redes.select.claseIluminacion.M2'), name: "dynatest.resources.redes.select.claseIluminacion.M2" },
                                        { id: translate('dynatest.resources.redes.select.claseIluminacion.M3'), name: "dynatest.resources.redes.select.claseIluminacion.M3" },
                                        { id: translate('dynatest.resources.redes.select.claseIluminacion.M4'), name: "dynatest.resources.redes.select.claseIluminacion.M4" },
                                        { id: translate('dynatest.resources.redes.select.claseIluminacion.M5'), name: "dynatest.resources.redes.select.claseIluminacion.M5" },

                                    ]} validate={[required()]} fullWidth />
                                </Grid>
                                <Grid item xs={4}>
                                    <SelectInput label="resources.redes.fields.tipoLampara" source="tipoLampara" allowEmpty emptyValue="" choices={[
                                        { id: translate('dynatest.resources.redes.select.tipoLampara.descarga'), name: "dynatest.resources.redes.select.tipoLampara.descarga" },
                                        { id: translate('dynatest.resources.redes.select.tipoLampara.incandescentes'), name: "dynatest.resources.redes.select.tipoLampara.incandescentes" },
                                        { id: translate('dynatest.resources.redes.select.tipoLampara.vaporMercurioAltaPresion'), name: "dynatest.resources.redes.select.tipoLampara.vaporMercurioAltaPresion" },
                                        { id: translate('dynatest.resources.redes.select.tipoLampara.vaporSodioAltaPresion'), name: "dynatest.resources.redes.select.tipoLampara.vaporSodioAltaPresion" },
                                        { id: translate('dynatest.resources.redes.select.tipoLampara.VaporSodioBajaPresion'), name: "dynatest.resources.redes.select.tipoLampara.VaporSodioBajaPresion" },
                                        { id: translate('dynatest.resources.redes.select.tipoLampara.otro'), name: "dynatest.resources.redes.select.tipoLampara.otro" },  
                                    ]} validate={[required()]} fullWidth />
                                </Grid>
                                <Grid item xs={4}>
                                    <SelectInput label="resources.redes.fields.brazoTipo" source="brazoTipo" allowEmpty emptyValue="" choices={[
                                        { id: translate('dynatest.resources.redes.select.brazoTipo.A'), name: "dynatest.resources.redes.select.brazoTipo.A" },
                                        { id: translate('dynatest.resources.redes.select.brazoTipo.B'), name: "dynatest.resources.redes.select.brazoTipo.B" },
                                        { id: translate('dynatest.resources.redes.select.brazoTipo.C'), name: "dynatest.resources.redes.select.brazoTipo.C" },
                                        { id: translate('dynatest.resources.redes.select.brazoTipo.D'), name: "dynatest.resources.redes.select.brazoTipo.D" },
                                        { id: translate('dynatest.resources.redes.select.brazoTipo.E'), name: "dynatest.resources.redes.select.brazoTipo.E" },
                                    ]} validate={[required()]} fullWidth formClassName={classes.grid_cont4_f}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <SelectInput label="resources.redes.fields.disposicionIluminaria" source="disposicionIluminaria" allowEmpty emptyValue="" choices={[
                                        { id: translate('dynatest.resources.redes.select.disposicionLuminarias.bilateralPareada'), name: "dynatest.resources.redes.select.disposicionLuminarias.bilateralPareada" },
                                        { id: translate('dynatest.resources.redes.select.disposicionLuminarias.centralDobleBrazo'), name: "dynatest.resources.redes.select.disposicionLuminarias.centralDobleBrazo" },
                                        { id: translate('dynatest.resources.redes.select.disposicionLuminarias.combinaciónBrazosDoblesTresbolill'), name: "dynatest.resources.redes.select.disposicionLuminarias.combinaciónBrazosDoblesTresbolill" },
                                        { id: translate('dynatest.resources.redes.select.disposicionLuminarias.unilateralCalzadasDiferenciadas'), name: "dynatest.resources.redes.select.disposicionLuminarias.unilateralCalzadasDiferenciadas" },
                                        { id: translate('dynatest.resources.redes.select.disposicionLuminarias.unilateralLadoExterior'), name: "dynatest.resources.redes.select.disposicionLuminarias.unilateralLadoExterior" },

                                    ]} validate={[required()]} fullWidth  formClassName={classes.grid_cont4_s}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <NumberInput label="resources.redes.fields.luminancia" source="valorIluminancia" validate={[required(), minValue(0, "Debe ser al menos 0"), maxValue(1000000)]} fullWidth formClassName={classes.grid_cont4_s}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <NumberInput label="resources.redes.fields.angulo" source="anguloInclinacion" validate={[required(), minValue(0, "Debe ser al menos 0"), maxValue(90)]} fullWidth formClassName={classes.grid_cont4_f}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <NumberInput label="resources.redes.fields.largo" source="largo" validate={[required(), validarDosDecimales, minValue(0, "Debe ser al menos 0")]} fullWidth />
                                </Grid>
                                <Grid item xs={4}>
                                    <NumberInput label="resources.redes.fields.altura" source="altura" validate={[required(), validarDosDecimales, minValue(0, "Debe ser al menos 0")]} fullWidth />
                                </Grid>
                                <Grid item xs={12}>
                                    <ReferenceInput
                                        label="resources.redes.fields.empresa"
                                        source="empresa"
                                        reference="servicios_publicos"
                                        sort={{ field: 'empresa', order: 'ASC' }}
                                        filterToQuery={searchText => ({ empresa: searchText })}
                                        validate={[required()]}
                                        formClassName={classes.grid_cont8_s}
                                    >

                                        <AutocompleteInput options={{ fullWidth: true }} optionText="empresa" />
                                    </ReferenceInput>
                                </Grid>
                            </Grid>
                        }
                    </FormDataConsumer>

                    {/* ------- ELEMENTO = SEMÁFORO ------ */}
                    <FormDataConsumer>
                        {({ formData, ...rest }) => formData != null && formData.tipoElemento === "/tipo_elementos/SEM" &&
                            <Grid container spacing={24}>
                                <Grid item xs={6}>
                                    <SelectInput label="resources.redes.fields.clase" source="entornoVia" allowEmpty emptyValue="" choices={[
                                        { id: translate('dynatest.resources.redes.select.claseSemaforo.semaforoEspecial'), name: "dynatest.resources.redes.select.claseSemaforo.semaforoEspecial" },
                                        { id: translate('dynatest.resources.redes.select.claseSemaforo.semaforosControlTránsitoVehículos'), name: "dynatest.resources.redes.select.claseSemaforo.semaforosControlTránsitoVehículos" },
                                        { id: translate('dynatest.resources.redes.select.claseSemaforo.semaforosPasosPeatonales'), name: "dynatest.resources.redes.select.claseSemaforo.semaforosPasosPeatonales" },
                                        { id: translate('dynatest.resources.redes.select.claseSemaforo.semaforosSonoros'), name: "dynatest.resources.redes.select.claseSemaforo.semaforosSonoros" },
                                    ]} validate={[required()]} fullWidth />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectInput label="resources.redes.fields.clasificacionSoporte" source="claseIluminacion" allowEmpty emptyValue="" choices={[
                                        { id: translate('dynatest.resources.redes.select.clasificacionSoporte.postes'), name: "dynatest.resources.redes.select.clasificacionSoporte.postes" },
                                        { id: translate('dynatest.resources.redes.select.clasificacionSoporte.cablesSuspención'), name: "dynatest.resources.redes.select.clasificacionSoporte.cablesSuspención" },
                                        { id: translate('dynatest.resources.redes.select.clasificacionSoporte.estructurasPórtico'), name: "dynatest.resources.redes.select.clasificacionSoporte.estructurasPórtico" },
                                        { id: translate('dynatest.resources.redes.select.clasificacionSoporte.mensulasCortas'), name: "dynatest.resources.redes.select.clasificacionSoporte.mensulasCortas" },
                                        { id: translate('dynatest.resources.redes.select.clasificacionSoporte.mensulasLargasPostesLaterales'), name: "dynatest.resources.redes.select.clasificacionSoporte.mensulasLargasPostesLaterales" },
                                        { id: translate('dynatest.resources.redes.select.clasificacionSoporte.postesPedestalesIslas'), name: "dynatest.resources.redes.select.clasificacionSoporte.postesPedestalesIslas" },
            

                                    ]} validate={[required()]} fullWidth />
                                </Grid>
                                <Grid item xs={4}>
                                    <NumberInput label="resources.redes.fields.largoLuminoso" source="largo" validate={[required(), validarDecimales3, minValue(0, "Debe ser al menos 0")]} fullWidth />
                                </Grid>
                                <Grid item xs={4}>
                                    <NumberInput label="resources.redes.fields.anchoLuminoso" source="ancho" validate={[required(), validarDecimales4, minValue(0, "Debe ser al menos 0")]} fullWidth />
                                </Grid>
                                <Grid item xs={4}>
                                    <NumberInput label="resources.redes.fields.altura" source="altura" validate={[required(), validarDosDecimales, minValue(0, "Debe ser al menos 0")]} fullWidth/>
                                </Grid>
                            </Grid>
                        }
                    </FormDataConsumer>

                    {/* ------- ELEMENTO = BÁCULA DE PESAJE ------ */}
                    <FormDataConsumer>
                        {({ formData, ...rest }) => formData != null && formData.tipoElemento === "/tipo_elementos/BDP" &&
                            <Grid container spacing={24}>
                                <Grid item xs={6}>
                                    <TextInput label="resources.redes.fields.marca" source="entornoVia" validate={[required(), maxLength(30)]} fullWidth />
                                </Grid>
                                <Grid item xs={6}>
                                    <NumberInput label="resources.redes.fields.capacidadT" source="altura" validate={[required(), maxLength(30), minValue(0, "Debe ser al menos 0")]} fullWidth />
                                </Grid>
                                <Grid item xs={6}>
                                    <NumberInput label="resources.redes.fields.largo" source="largo" validate={[required(), validarDecimales3, minValue(0, "Debe ser al menos 0")]} fullWidth />
                                </Grid>
                                <Grid item xs={6}>
                                    <NumberInput label="resources.redes.fields.ancho" source="ancho" validate={[required(), validarDecimales4, minValue(0, "Debe ser al menos 0")]} fullWidth />
                                </Grid>
                                <Grid item xs={6}>
                                    {/* <ReferenceInput
                                        label="Nivel de tráfico"
                                        source="nivelTrafico"
                                        reference="flexible_detalles"
                                        sort={{ field: 'nivel_trafico', order: 'ASC' }}
                                        fullWidth
                                        //filterToQuery={searchText => ({ nivel_trafico: searchText })}
                                        validate={[required()]}
                                    >
                                        <SelectInput 
                                            //options={{ fullWidth: true }} 
                                            optionText="nivelTrafico" 
                                        />
                                    </ReferenceInput> */}
                                    <SelectInput source="nivelTrafico" label="resources.redes.fields.nivelTraficoDetalles" fullWidth choices={[
                                        { id: translate('dynatest.resources.redes.select.nivelTrafico.NT1'), name: "dynatest.resources.redes.select.nivelTrafico.NT1" },
                                        { id: translate('dynatest.resources.redes.select.nivelTrafico.NT2'), name: "dynatest.resources.redes.select.nivelTrafico.NT2" },
                                        { id: translate('dynatest.resources.redes.select.nivelTrafico.NT3'), name: "dynatest.resources.redes.select.nivelTrafico.NT3" },
                                    ]} formClassName={classes.last_filed} validate={[required()]} /> 
                                </Grid>
                            </Grid>
                        }
                    </FormDataConsumer>


                    {/* ------- ELEMENTOS RESTANTES ------ */}
                    <FormDataConsumer>
                        {({ formData, ...rest }) => formData != null && formData.tipoElemento !== "/tipo_elementos/SEM" && formData.tipoElemento !== "/tipo_elementos/LUM" && formData.tipoElemento !== "/tipo_elementos/BDP" &&
                            formData.tipoElemento !== "/tipo_elementos/DCT" && formData.tipoElemento !== "/tipo_elementos/REE" &&
                            <Grid container spacing={24}>
                                <Grid item xs={6}>
                                    <SelectInput label="resources.redes.fields.material" source="entornoVia" allowEmpty emptyValue="" choices={[
                                        { id: translate('dynatest.resources.redes.select.material.concreto'), name: "dynatest.resources.redes.select.material.concreto" },
                                        { id: translate('dynatest.resources.redes.select.material.mamposteria'), name: "dynatest.resources.redes.select.material.mamposteria" },
                                        { id: translate('dynatest.resources.redes.select.material.prefabricado'), name: "dynatest.resources.redes.select.material.prefabricado" },
                                        { id: translate('dynatest.resources.redes.select.material.otro'), name: "dynatest.resources.redes.select.material.otro" },

                                    ]} fullWidth />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectInput label="resources.redes.fields.seccion" source="claseIluminacion" allowEmpty emptyValue="" choices={[
                                         { id: translate('dynatest.resources.redes.select.seccion.circular'), name: "dynatest.resources.redes.select.seccion.circular" },
                                         { id: translate('dynatest.resources.redes.select.seccion.cuadrada'), name: "dynatest.resources.redes.select.seccion.cuadrada" },
                                         { id: translate('dynatest.resources.redes.select.seccion.hexagonal'), name: "dynatest.resources.redes.select.seccion.hexagonal" },
                                         { id: translate('dynatest.resources.redes.select.seccion.perfilH'), name: "dynatest.resources.redes.select.seccion.perfilH" },
                                         { id: translate('dynatest.resources.redes.select.seccion.rectangular'), name: "dynatest.resources.redes.select.seccion.rectangular" },
                                         { id: translate('dynatest.resources.redes.select.seccion.otro'), name: "dynatest.resources.redes.select.seccion.otro" },

                                    ]} fullWidth />
                                </Grid>
                                <Grid item xs={4}>
                                    <NumberInput label="resources.redes.fields.largoDiametro" source="largo" validate={[validarDecimales3, minValue(0, "Debe ser al menos 0")]} fullWidth />
                                </Grid>
                                <Grid item xs={4}>
                                    <NumberInput label="resources.redes.fields.base" source="ancho" validate={[validarDecimales4, minValue(0, "Debe ser al menos 0")]} fullWidth />
                                </Grid>
                                <Grid item xs={4}>
                                    <NumberInput label="resources.redes.fields.altura" source="altura" validate={[validarDosDecimales, minValue(0, "Debe ser al menos 0")]} fullWidth />
                                </Grid>
                                <Grid item xs={12}>
                                    <ReferenceInput
                                        label="resources.redes.fields.empresa"
                                        source="empresa"
                                        reference="servicios_publicos"
                                        sort={{ field: 'empresa', order: 'ASC' }}
                                        filterToQuery={searchText => ({ empresa: searchText })}
                                        validate={[required()]}
                                        formClassName={classes.grid_cont4}
                                    >

                                        <AutocompleteInput options={{ fullWidth: true }} optionText="empresa" />
                                    </ReferenceInput>

                                </Grid>
                            </Grid>
                        }
                    </FormDataConsumer>

                    {/* ------- ELEMENTO = DUCTOS ------ */}
                    {elemento === "/tipo_elementos/DCT" &&
                        <SelectInput label="resources.redes.fields.tipo" source="tipoRed" allowEmpty emptyValue="" choices={[
                            { id: translate('dynatest.resources.redes.select.tipoRedes.gas'), name: "dynatest.resources.redes.select.tipoRedes.gas" },
                            { id: translate('dynatest.resources.redes.select.tipoRedes.acueducto'), name: "dynatest.resources.redes.select.tipoRedes.acueducto" },
                            { id: translate('dynatest.resources.redes.select.tipoRedes.alcantarillado'), name: "dynatest.resources.redes.select.tipoRedes.alcantarillado" },
                            { id: translate('dynatest.resources.redes.select.tipoRedes.fibraOptica'), name: "dynatest.resources.redes.select.tipoRedes.fibraOptica" },

                        ]} fullWidth formClassName={classes.grid_cont6_f} />
                    }
                    {elemento === "/tipo_elementos/DCT" &&
                        <SelectInput label="resources.redes.fields.material" source="material" allowEmpty emptyValue="" choices={[
                            { id: translate('dynatest.resources.redes.select.material.acero'), name: "dynatest.resources.redes.select.material.acero" },
                            { id: translate('dynatest.resources.redes.select.material.cobre'), name: "dynatest.resources.redes.select.material.cobre" },
                            { id: translate('dynatest.resources.redes.select.material.concreto'), name: "dynatest.resources.redes.select.material.concreto" },
                            { id: translate('dynatest.resources.redes.select.material.concretoAcero'), name: "dynatest.resources.redes.select.material.concretoAcero" },
                            { id: translate('dynatest.resources.redes.select.material.mamposteria'), name: "dynatest.resources.redes.select.material.mamposteria" },
                            { id: translate('dynatest.resources.redes.select.material.HDEP'), name: "dynatest.resources.redes.select.material.HDEP" },
                            { id: translate('dynatest.resources.redes.select.material.polipropileno'), name: "dynatest.resources.redes.select.material.polipropileno" },
                            { id: translate('dynatest.resources.redes.select.material.PVC'), name: "dynatest.resources.redes.select.material.PVC" },
                            { id: translate('dynatest.resources.redes.select.material.otro'), name: "dynatest.resources.redes.select.material.otro" },

                        ]} fullWidth formClassName={classes.grid_cont6_s} />
                    }

                    {elemento === "/tipo_elementos/DCT" &&
                        <NumberInput label="resources.redes.fields.longitudM" source="longitud" validate={[validarDosDecimales, minValue(0, "Debe ser al menos 0.")]} 
                        fullWidth formClassName={classes.grid_cont4_f} />
                    }
                    {elemento === "/tipo_elementos/DCT" &&
                        <NumberInput label="resources.redes.fields.diametro" source="diametro" validate={[validarDosDecimales, minValue(0, "Debe ser al menos 0.")]} 
                        fullWidth formClassName={classes.grid_cont4_s} />
                    }
                    {elemento === "/tipo_elementos/DCT" &&
                        <ReferenceInput
                            label="resources.redes.fields.empresa"
                            source="empresa"
                            reference="servicios_publicos"
                            sort={{ field: 'empresa', order: 'ASC' }}
                            filterToQuery={searchText => ({ empresa: searchText })}
                            formClassName={classes.grid_cont4}
                            fullWidth
                        >

                        <AutocompleteInput options={{ fullWidth: true }} optionText="empresa" />
                        </ReferenceInput>
                    }
                    <br/>

                    {/* ------- ELEMENTO = REDES DE ENERGÍA ELECTRICA ------ */}
                    {elemento === "/tipo_elementos/REE" &&
                        <SelectInput label="resources.redes.fields.tipo" source="tipoRed" allowEmpty emptyValue="" choices={[
                            { id: translate('dynatest.resources.redes.select.tipoRedes.longitudinal'), name: "dynatest.resources.redes.select.tipoRedes.longitudinal" },
                            { id: translate('dynatest.resources.redes.select.tipoRedes.poste'), name: "dynatest.resources.redes.select.tipoRedes.poste" },
                            { id: translate('dynatest.resources.redes.select.tipoRedes.travesia'), name: "dynatest.resources.redes.select.tipoRedes.travesia" },

                        ]} fullWidth formClassName={classes.grid_cont6_f} />
                    }
                    {elemento === "/tipo_elementos/REE" &&
                        <SelectInput label="resources.redes.fields.material" source="material" allowEmpty emptyValue="" choices={[
                            { id: translate('dynatest.resources.redes.select.material.concreto'), name: "dynatest.resources.redes.select.material.concreto" },
                            { id: translate('dynatest.resources.redes.select.material.mamposteria'), name: "dynatest.resources.redes.select.material.mamposteria" },
                            { id: translate('dynatest.resources.redes.select.material.prefabricado'), name: "dynatest.resources.redes.select.material.prefabricado" },
                            { id: translate('dynatest.resources.redes.select.material.otro'), name: "dynatest.resources.redes.select.material.otro" },

                        ]} fullWidth formClassName={classes.grid_cont6_s} />
                    }

                    {elemento === "/tipo_elementos/REE" &&
                        <NumberInput label="resources.redes.fields.longitudM" source="longitud" validate={[validarDosDecimales, minValue(0, "Debe ser al menos 0.")]} 
                        fullWidth formClassName={classes.grid_cont4_f} />
                    }
                    {elemento === "/tipo_elementos/REE" &&
                        <NumberInput label="resources.redes.fields.diametro" source="diametro" validate={[validarDosDecimales, minValue(0, "Debe ser al menos 0.")]} 
                        fullWidth formClassName={classes.grid_cont4_s} />
                    }
                    {elemento === "/tipo_elementos/REE" &&
                        <ReferenceInput
                            label="resources.redes.fields.empresa"
                            source="empresa"
                            reference="servicios_publicos"
                            sort={{ field: 'empresa', order: 'ASC' }}
                            filterToQuery={searchText => ({ empresa: searchText })}
                            formClassName={classes.grid_cont4}
                            fullWidth
                        >

                        <AutocompleteInput options={{ fullWidth: true }} optionText="empresa" />
                        </ReferenceInput>
                    }
                    <br/>
                </FormTab>
                {/* ------------ DETALLES ------------- */}
                <FormTab label="dynatest.resources.redes.tabs.tabsTitle.detalles" >
                    <Grid container spacing={24}>
                        <Grid item xs={4}>
                            <SelectInput label="resources.redes.fields.estado" source="estado" allowEmpty emptyValue="" choices={[
                                { id: translate('dynatest.resources.redes.select.estado.Existente'), name: "dynatest.resources.redes.select.estado.Existente" },
                                { id: translate('dynatest.resources.redes.select.estado.Reemplazo'), name: "dynatest.resources.redes.select.estado.Reemplazo" },
                                { id: translate('dynatest.resources.redes.select.estado.Nuevo'), name: "dynatest.resources.redes.select.estado.Nuevo" },
                            ]} validate={[required()]} fullWidth />
                        </Grid>
                        <FormDataConsumer>
                            {
                                ({ formData, ...rest }) => (formData !== null && formData !== undefined && formData.estado === translate('dynatest.resources.redes.select.estado.Reemplazo') &&
                                    <Grid item xs={4}>
                                        <SelectInput label="resources.redes.fields.motivoReemplazo" source="motivoReemplazo" choices={[
                                            { id: translate('dynatest.resources.redes.select.motivoReemplazo.accidente'), name: 'dynatest.resources.redes.select.motivoReemplazo.accidente' },
                                            { id: translate('dynatest.resources.redes.select.motivoReemplazo.instalacion'), name: 'dynatest.resources.redes.select.motivoReemplazo.instalacion' },
                                            { id: translate('dynatest.resources.redes.select.motivoReemplazo.reemplazoProgramado'), name: 'dynatest.resources.redes.select.motivoReemplazo.reemplazoProgramado' },
                                            { id: translate('dynatest.resources.redes.select.motivoReemplazo.vandalismo'), name: 'dynatest.resources.redes.select.motivoReemplazo.vandalismo' },
                                            { id: translate('dynatest.resources.redes.select.motivoReemplazo.otro'), name: 'dynatest.resources.redes.select.motivoReemplazo.otro' },
                                        ]} validate={[required()]} 
                                        fullWidth />
                                    </Grid>
                                )

                            }
                        </FormDataConsumer>
                        <FormDataConsumer>
                            {
                                ({ formData, ...rest }) => (formData !== null && formData !== undefined && formData.estado === translate('dynatest.resources.redes.select.estado.Reemplazo') &&
                                    <Grid item xs={4}>
                                        {/* <ElementosCreados tabla="elemento_reds" tipo="create" fullWidth formClassName={classes.grid_cont4s} /> */}
                                        <ReferenceInput
                                        label="resources.redes.fields.elementoReemplazo"
                                        source="elementoReemplazo_id"
                                        reference="elemento_reds"
                                        filter={{
                                            "properties[]" : "fullCodigoConsecutivo",
                                            tipoElemento : elemento,
                                            estados : "Activo",
                                            unidadFuncional: unidads,
                                            tramo: tramos,
                                            segmento : segmentos,
                                            exclude : (formData) ? formData.id : null
                                        }}
                                        filterToQuery={searchText => ({ fullCodigo: searchText })} 
                                        fullWidth
                                    >
                                        <AutocompleteInput options={{fullWidth: true}} optionText="fullCodigoConsecutivo" />
                                    </ReferenceInput>
                                    </Grid>
                                )}
                        </FormDataConsumer>
                        <FormDataConsumer>
                            {
                                ({ formData, ...rest }) => (formData !== null && formData !== undefined && formData.estado != translate('dynatest.resources.redes.select.estado.Reemplazo') &&
                                    <Grid item xs={8}>
                                    </Grid>
                                )}
                        </FormDataConsumer>
                        
                        <Grid item xs={4}>
                        {elemento !== "/tipo_elementos/DCT" && elemento !== "/tipo_elementos/REE" &&
                            <SelectInput label="resources.redes.fields.estados" source="estados" allowEmpty emptyValue="" choices={[
                                { id: translate('dynatest.resources.redes.select.estados.Activo'), name: "dynatest.resources.redes.select.estados.Activo" },
                                { id: translate('dynatest.resources.redes.select.estados.Inactivo'), name: "dynatest.resources.redes.select.estados.Inactivo" },
                            ]} validate={[required()]} fullWidth formClassName={classes.grid_cont4_f} />
                        }
                        {elemento === "/tipo_elementos/DCT" &&
                            <SelectInput label="resources.redes.fields.estados" source="estados" allowEmpty emptyValue="" choices={[
                                { id: translate('dynatest.resources.redes.select.estados.Activo'), name: "dynatest.resources.redes.select.estados.Activo" },
                                { id: translate('dynatest.resources.redes.select.estados.Inactivo'), name: "dynatest.resources.redes.select.estados.Inactivo" },
                            ]} fullWidth formClassName={classes.grid_cont4_f} />
                        }
                        {elemento === "/tipo_elementos/REE" &&
                            <SelectInput label="resources.redes.fields.estados" source="estados" allowEmpty emptyValue="" choices={[
                                { id: translate('dynatest.resources.redes.select.estados.Activo'), name: "dynatest.resources.redes.select.estados.Activo" },
                                { id: translate('dynatest.resources.redes.select.estados.Inactivo'), name: "dynatest.resources.redes.select.estados.Inactivo" },
                            ]} fullWidth formClassName={classes.grid_cont4_f} />
                        }
                        </Grid>
                        <Grid item xs={8}></Grid>
                        <Grid item xs={4}>
                            <DateInput label="resources.redes.fields.fecha" source="fechaInstalacion" fullWidth validate={[validacionmaxfechaact]}/>
                        </Grid>
                        <Grid item xs={4}>
                        <ReferenceInput 
                            label="resources.redes.fields.proveedor" 
                            source="proveedor" 
                            reference="proveedors" 
                            filter={{ proyecto: props.proyecto, suministros: 1, sistema: 'RDS' }}
                            sort={{ field: 'razonSocial', order: 'ASC' }}
                            filterToQuery={searchText => ({ razonSocial: searchText })} 
                            validate={[required()]}
                        >
                            <AutocompleteInput  options={{fullWidth : true}} optionText="razonSocial"  />
                        </ReferenceInput>
                        </Grid>
                        <Grid item xs={4}>
                            <NumberInput label="resources.redes.fields.garantiaMeses" source="garantia" fullWidth validate={[required(), minValue(0, "Debe ser al menos 0")]} />
                        </Grid>
                        <Grid item xs={6}>
                            <BooleanInput label="resources.redes.fields.mantenimiento" source="mantenimiento" fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput label="resources.redes.fields.observaciones" source="observaciones" validate={[required()]} fullWidth />
                        </Grid>

                    </Grid>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => { formData.updatedIn = "api"; }}
                    </FormDataConsumer>
                </FormTab>
                <FormTab label="dynatest.resources.redes.tabs.tabsTitle.fotografias">
                <Grid container spacing={24}>                         
                    <Grid item xs={8}>  
                        {/* <ArrayInput label="" source="images" validate={ImagenesEdit} >
                            <SimpleFormIterator disableAdd disableRemove >
                                <ImageInput multiple={true} source="images" label=" " maxSize={1000000000} accept="image/jpeg, image/png">
                                    <ImageField source="src" title="title" />
                                </ImageInput>                           
                                <ReferenceInput disabled label="Tipo de elemento" source="tipoImagen" reference="tipo_images" fullWidth validate={[required()]} sort={{ field: 'nombre', order: 'ASC' }}  formClassName={classes.grid_cont6}>
                                    <SelectInput  optionText="nombre"  />
                                </ReferenceInput>                          
                            </SimpleFormIterator>
                        </ArrayInput>   */}
                        <FormDataConsumer>
                                {({ formData, ...rest }) =>
                                    <ArrayInputImagesEdit record={formData} {...rest} />                                
                                }
                            </FormDataConsumer> 
                        </Grid>  
                        <Grid item xs={4}>
                            <FormDataConsumer>
                                {({ formData, ...rest }) =>
                                    <ButtonCarousel record={formData} {...rest} />                                
                                }
                            </FormDataConsumer>  
                        </Grid>                                                   
                </Grid>                   
            </FormTab>
            </TabbedForm>
        </Edit>
    )
}));