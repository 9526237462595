import React, { useState } from 'react';
import { Toolbar, CardActions, NumberField, TextInput, ShowController, ShowView, TextField, DateField, ReferenceField, BooleanField, TabbedForm, ListButton, EditButton, FormTab } from 'react-admin';
import { GMapInput } from '../../../inputs/GMapInput';
import { PRField } from './../../../fields/PRField';
import { PField } from './../../../fields/PField';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { styles } from './../../../EstilosGrid';
import { withStyles } from '@material-ui/core';
import { ButtonCarousel } from './../../images/ButtonCarousel';
import { translate } from 'react-admin';

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};


const PostShowActions = ({ basePath, data }) => (
    <CardActions style={cardActionStyle}>
        <EditButton basePath={basePath} record={data} />
        {/* Add your custom actions */}
        <ListButton basePath={basePath} />
    </CardActions>
);

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <span></span>

    </Toolbar>
));

export const ElementoSenalizacionShow = translate(withStyles(styles)(({ classes, record, ...props }) => {
    const { translate } = props;

    const [imagen, setImagen] = useState(true);
    const ValidarImagen = (value) => {
        // console.log(value + "Aqui est la imagen ")
        if (value !== null && value !== undefined && value !== '') {
            let lenghtObject = Object.keys(value);
            let valor = '';
            for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
            setImagen(value)
            console.log("image?" + value)
        }
    }

    return (
        <ShowController {...props} >
            {controllerProps =>
                <ShowView {...props} {...controllerProps} title="dynatest.resources.elemento_senalizacions.title.showTitle" actions={<ButtonCarousel />}>
                    <TabbedForm toolbar={false}>
                        <FormTab label="dynatest.resources.elemento_senalizacions.tabs.tabsTitle.identificacion">
                            <TextField label="resources.elemento_senalizacions.fields.smartCode" source="smartCode" fullWidth formClassName={classes.grid_cont4s} />
                            <br/>
                            <ReferenceField label="resources.elemento_senalizacions.fields.tipoElementoSenal" source="tipoElementoSenal" reference="tipo_elemento_senals" linkType={false} fullWidth formClassName={classes.grid_cont4_f}>
                                <TextField source="nombre" />
                            </ReferenceField>
                            {controllerProps.record && controllerProps.record.tipoElementoSenal === '/tipo_elemento_senals/SV' &&
                                <TextInput source="senal_.url" validate={ValidarImagen} formClassName={classes.ocultar} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal !== '/tipo_elemento_senals/SV' &&
                                <TextInput source="tipoSenal_.url" validate={ValidarImagen} formClassName={classes.ocultar} />
                            }
                            <TextField label="resources.elemento_senalizacions.fields.margen" source="margen" fullWidth formClassName={classes.grid_cont4s} />
                            {/* <ImageField source="tipoSenal_.url" src="url"  /> */}
                            {/* <ImageField source="senal_.url" validate={ValidarImagen}/> */}
                            {controllerProps.record && controllerProps.record.tipoElementoSenal === '/tipo_elemento_senals/SV' &&
                                controllerProps.record.tipoSenal !== '/tipo_senals/SMV' &&

                                <TextField label="resources.elemento_senalizacions.fields.sentido" source="sentido" fullWidth formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal !== '/tipo_elemento_senals/SV' &&
                                <TextField label="resources.elemento_senalizacions.fields.sentido" source="sentido" fullWidth formClassName={classes.grid_cont4} />
                            }

                            <ReferenceField label="resources.elemento_senalizacions.fields.tipoSenal" source="tipoSenal" reference="senals" linkType={false} fullWidth formClassName={classes.grid_cont4_f} >
                                <TextField source="nombre" />
                            </ReferenceField>
                            {controllerProps.record && controllerProps.record.tipoElementoSenal === '/tipo_elemento_senals/SV' &&
                                controllerProps.record.tipoSenal !== '/tipo_senals/SMV' &&

                                <ReferenceField label="resources.elemento_senalizacions.fields.senal" source="senal" reference="senals" linkType={false} fullWidth formClassName={classes.grid_cont4s}>
                                    <TextField source="ids" />
                                </ReferenceField>
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal === '/tipo_elemento_senals/SV' &&
                                controllerProps.record.tipoSenal !== '/tipo_senals/SMV' &&

                                <ReferenceField label="resources.elemento_senalizacions.fields.modelo" source="senal" reference="senals" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                    <TextField source="nombre" />
                                </ReferenceField>
                            }
                            
                            {/* {controllerProps.record && controllerProps.record.tipoElementoSenal === '/tipo_elemento_senals/SV' &&
                                controllerProps.record.tipoSenal !== '/tipo_senals/SMV' &&

                                <TextField label="Codigo Señal" source="codigoSenal" fullWidth formClassName={classes.grid_cont6} />
                            }
                            <br/>
                            {controllerProps.record && controllerProps.record.tipoElementoSenal === '/tipo_elemento_senals/SV' &&
                                controllerProps.record.tipoSenal !== '/tipo_senals/SMV' &&

                                <TextField label="Señal" source="senal" fullWidth formClassName={classes.grid_cont6} />
                            } */}
                            <div></div>
                            <Typography variant="h3" component="h3">{translate('dynatest.resources.elemento_senalizacions.subTitle.localizacion')}</Typography>
                            <span></span>
                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <ReferenceField label="resources.elemento_senalizacions.fields.unidadFuncional" source="unidadFuncional" reference="unidad_funcionals" linkType={false} fullWidth formClassName={classes.grid_cont4s}>
                                <TextField source="fullCodigos" />
                            </ReferenceField>
                            <ReferenceField label="resources.elemento_senalizacions.fields.tramo" source="tramo" reference="tramos" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <TextField source="fullCodigos" />
                            </ReferenceField>
                            <ReferenceField label="resources.elemento_senalizacions.fields.segmento" source="segmento" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <TextField source="fullCodigos" />
                            </ReferenceField>

                            {controllerProps.record && controllerProps.record.tipoElementoSenal === '/tipo_elemento_senals/SV' &&
                                controllerProps.record.tipoSenal !== '/tipo_senals/SMV' &&
                                <Typography variant="b" component="b">{translate('dynatest.resources.elemento_senalizacions.subTitle.coordenadasIniciales')}</Typography>
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal === '/tipo_elemento_senals/SV' &&
                                controllerProps.record.tipoSenal !== '/tipo_senals/SMV' &&
                                <Divider fullWidth style={{ width: '300%', marginTop: '10px' }} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal === '/tipo_elemento_senals/SV' &&
                                controllerProps.record.tipoSenal !== '/tipo_senals/SMV' &&
                                <PRField label="resources.elemento_senalizacions.fields.abscisa" source="abInicio" fullWidth formClassName={classes.grid_cont3s} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal === '/tipo_elemento_senals/SV' &&
                                controllerProps.record.tipoSenal !== '/tipo_senals/SMV' &&
                                <PField label="resources.elemento_senalizacions.fields.pr" source="pInicio" fullWidth formClassName={classes.grid_cont3} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal === '/tipo_elemento_senals/SV' &&
                                controllerProps.record.tipoSenal !== '/tipo_senals/SMV' &&
                                <NumberField label="resources.elemento_senalizacions.fields.latitud" source="latitudInicial" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal === '/tipo_elemento_senals/SV' &&
                                controllerProps.record.tipoSenal !== '/tipo_senals/SMV' &&
                                <NumberField label="resources.elemento_senalizacions.fields.longitud" source="longitudInicial" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                            }



                            
                            {controllerProps.record && controllerProps.record.tipoElementoSenal !== '/tipo_elemento_senals/SV' &&
                                <PRField label="resources.elemento_senalizacions.fields.abInicio" source="abInicio" fullWidth formClassName={classes.grid_cont4s} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal !== '/tipo_elemento_senals/SV' &&
                                <PRField label="resources.elemento_senalizacions.fields.abFin" source="abFin" fullWidth formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal !== '/tipo_elemento_senals/SV' &&
                                <NumberField label="resources.elemento_senalizacions.fields.fullResta" source="fullResta" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal !== '/tipo_elemento_senals/SV' &&
                                <PField label="resources.elemento_senalizacions.fields.pInicio" source="pInicio" fullWidth formClassName={classes.grid_cont4s} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal !== '/tipo_elemento_senals/SV' &&
                                <PField label="resources.elemento_senalizacions.fields.pFin" source="pFin" fullWidth formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal !== '/tipo_elemento_senals/SV' &&
                                <Typography variant="b" component="b">{translate('dynatest.resources.elemento_senalizacions.subTitle.coordenadasIniciales')}</Typography>
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal !== '/tipo_elemento_senals/SV' &&
                                <Divider fullWidth style={{ width: '300%', marginTop: '10px' }} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal !== '/tipo_elemento_senals/SV' &&
                                <NumberField label="resources.elemento_senalizacions.fields.latitudFinal" source="latitudInicial" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4s} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal !== '/tipo_elemento_senals/SV' &&
                                <NumberField label="resources.elemento_senalizacions.fields.longitudFinal" source="longitudInicial" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal !== '/tipo_elemento_senals/SV' &&
                                <Typography variant="b" component="b">{translate('dynatest.resources.elemento_senalizacions.subTitle.coordenadasFinales')}</Typography>
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal !== '/tipo_elemento_senals/SV' &&
                                <Divider fullWidth style={{ width: '300%', marginTop: '10px' }} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal !== '/tipo_elemento_senals/SV' &&
                                <NumberField label="resources.elemento_senalizacions.fields.latitudFinal" source="latitudFinal" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4s} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal !== '/tipo_elemento_senals/SV' &&
                                <NumberField label="resources.elemento_senalizacions.fields.longitudFinal" source="longitudFinal" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4} />
                            }

                            <span></span>
                            <GMapInput
                                defaultZoom={12}
                                defaultCenter={{ lat: 4.7567039, lng: -74.0311056 }}
                                source="ubicacionMapa"
                                multipleMarkers="true"
                                googleKey="AIzaSyCivTXzJBYRBK8nosBYRFXnv-hGXIbhnYY"
                                icon={imagen}
                            />
                        </FormTab>
                        <FormTab label="dynatest.resources.elemento_senalizacions.tabs.tabsTitle.caracteristicas">
                            {controllerProps.record && controllerProps.record.tipoSenal === '/tipo_senals/SMV' &&
                                <Typography variant="p" component="p" fullWidth >
                                    <br/>{translate('dynatest.resources.elemento_senalizacions.messageWarning.warningMensajeVariable')}
                                </Typography>
                            }

                            {controllerProps.record && controllerProps.record.tipoElementoSenal !== '/tipo_elemento_senals/OTR' &&
                                controllerProps.record.tipoSenal !== '/tipo_senals/SMV' &&

                                <TextField label="resources.elemento_senalizacions.fields.materialPlaca" source="material" fullWidth formClassName={classes.grid_cont3} />

                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal === '/tipo_elemento_senals/SV' &&
                                controllerProps.record.tipoSenal !== '/tipo_senals/SMV' &&

                                <TextField label="resources.elemento_senalizacions.fields.tipoPapel" source="tipoPapel" fullWidth formClassName={classes.grid_cont3} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal === '/tipo_elemento_senals/SV' &&
                                controllerProps.record.tipoSenal !== '/tipo_senals/SMV' &&

                                <TextField label="resources.elemento_senalizacions.fields.tipoLamina" source="tipoLamina" fullWidth formClassName={classes.grid_cont3} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal === '/tipo_elemento_senals/SV' &&
                                controllerProps.record.tipoSenal !== '/tipo_senals/SMV' &&

                                <br />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal === '/tipo_elemento_senals/SV' &&
                                controllerProps.record.tipoSenal !== '/tipo_senals/SMV' &&

                                <TextField label="resources.elemento_senalizacions.fields.tipoTablero" source="tipoTablero" fullWidth formClassName={classes.grid_cont3} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal === '/tipo_elemento_senals/SV' &&
                                controllerProps.record.tipoSenal !== '/tipo_senals/SMV' &&

                                <NumberField label="resources.elemento_senalizacions.fields.dimensionTablero" source="dimensionTablero" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal === '/tipo_elemento_senals/SV' &&
                                controllerProps.record.tipoSenal !== '/tipo_senals/SMV' &&

                                <NumberField label="resources.elemento_senalizacions.fields.anchoTablero" source="anchoTablero" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal === '/tipo_elemento_senals/SV' &&
                                controllerProps.record.tipoSenal !== '/tipo_senals/SMV' &&

                                <br />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal === '/tipo_elemento_senals/SV' &&
                                controllerProps.record.tipoSenal !== '/tipo_senals/SMV' &&

                                <NumberField label="resources.elemento_senalizacions.fields.altoTablero" source="altoTablero" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal === '/tipo_elemento_senals/SV' &&
                                controllerProps.record.tipoSenal !== '/tipo_senals/SMV' &&

                                <TextField label="resources.elemento_senalizacions.fields.estadoTablero" source="estadoTablero" fullWidth formClassName={classes.grid_cont3} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal === '/tipo_elemento_senals/SV' &&
                                controllerProps.record.tipoSenal !== '/tipo_senals/SMV' &&

                                <TextField label="resources.elemento_senalizacions.fields.tipoSoporte" source="tipoSoporte" fullWidth formClassName={classes.grid_cont3} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal === '/tipo_elemento_senals/SV' &&
                                controllerProps.record.tipoSenal !== '/tipo_senals/SMV' &&

                                <br />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal === '/tipo_elemento_senals/SV' &&
                                controllerProps.record.tipoSenal !== '/tipo_senals/SMV' &&

                                <TextField label="resources.elemento_senalizacions.fields.estadoSoporte" source="estadoSoporte" fullWidth formClassName={classes.grid_cont3} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal === '/tipo_elemento_senals/SV' &&
                                controllerProps.record.tipoSenal !== '/tipo_senals/SMV' &&

                                <TextField label="resources.elemento_senalizacions.fields.materialSoporte" source="materialSoporte" fullWidth formClassName={classes.grid_cont3} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal === '/tipo_elemento_senals/SV' &&
                                controllerProps.record.tipoSenal !== '/tipo_senals/SMV' &&

                                <NumberField label="resources.elemento_senalizacions.fields.velocidadDimension" source="velocidadDimension" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal === '/tipo_elemento_senals/SV' &&
                                controllerProps.record.tipoSenal !== '/tipo_senals/SMV' &&

                                <br />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal === '/tipo_elemento_senals/SV' &&
                                controllerProps.record.tipoSenal !== '/tipo_senals/SMV' &&

                                <NumberField label="resources.elemento_senalizacions.fields.altura" source="altura" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal === '/tipo_elemento_senals/SV' &&
                                controllerProps.record.tipoSenal !== '/tipo_senals/SMV' &&

                                <NumberField label="resources.elemento_senalizacions.fields.valorLimite" source="valorLimite" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                            }
                            
                            {controllerProps.record && controllerProps.record.tipoElementoSenal === '/tipo_elemento_senals/SV' &&
                                controllerProps.record.tipoSenal !== '/tipo_senals/SMV' &&

                                <NumberField label="resources.elemento_senalizacions.fields.ABNT" source="ABNT" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                            }
                            
                            {controllerProps.record && controllerProps.record.tipoElementoSenal === '/tipo_elemento_senals/SV' &&
                                controllerProps.record.tipoSenal !== '/tipo_senals/SMV' &&

                                <br />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal !== '/tipo_elemento_senals/OTR' &&
                                controllerProps.record.tipoSenal !== '/tipo_senals/SMV' &&

                                <TextField label="resources.elemento_senalizacions.fields.color" source="color"  fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal === '/tipo_elemento_senals/SV' &&
                                controllerProps.record.tipoSenal !== '/tipo_senals/SMV' &&

                                <NumberField label="resources.elemento_senalizacions.fields.retroflectividadInicial" source="retroflectividadInicial" fullWidth formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal === '/tipo_elemento_senals/SV' &&
                                controllerProps.record.tipoSenal !== '/tipo_senals/SMV' &&

                                <br />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal === '/tipo_elemento_senals/SV' &&
                                controllerProps.record.tipoSenal !== '/tipo_senals/SMV' &&

                                <TextField label="resources.elemento_senalizacions.fields.colorSecundario" source="colorSecundario" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4} />
                            }
                            
                            {controllerProps.record && controllerProps.record.tipoElementoSenal === '/tipo_elemento_senals/SV' &&
                                controllerProps.record.tipoSenal !== '/tipo_senals/SMV' &&
                                controllerProps.record.colorSecundario !== 'No aplica' &&

                                <NumberField label="resources.elemento_senalizacions.fields.retroflectividadFinal" source="retroflectividadFinal" fullWidth formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal === '/tipo_elemento_senals/SV' &&
                                controllerProps.record.tipoSenal !== '/tipo_senals/SMV' &&

                                <br />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal === '/tipo_elemento_senals/SV' &&
                                controllerProps.record.tipoSenal !== '/tipo_senals/SMV' &&

                                <TextField label="resources.elemento_senalizacions.fields.colorTerciario" source="colorTerciario" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal === '/tipo_elemento_senals/SV' &&
                                controllerProps.record.tipoSenal !== '/tipo_senals/SMV' &&
                                controllerProps.record.colorSecundario !== 'No aplica' &&

                                <NumberField label="resources.elemento_senalizacions.fields.retroflectividadTerciaria" source="retroflectividadTerciaria" fullWidth formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal === '/tipo_elemento_senals/SV' &&
                                controllerProps.record.tipoSenal !== '/tipo_senals/SMV' &&

                                <br />
                            }

                            {/*controllerProps.record && controllerProps.record.tipoElementoSenal === '/tipo_elemento_senals/SV' &&
                                controllerProps.record.FormTab === 'Circular' &&
                                <NumberField label="Diámetro" source="area" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                        */}

                            {/*-----------------------------------------------------------------------------------*/}

                            {controllerProps.record && controllerProps.record.tipoElementoSenal === '/tipo_elemento_senals/SH' &&
                                controllerProps.record.tipoSenal !== '/tipo_senals/SMV' &&

                                <NumberField label="resources.elemento_senalizacions.fields.numeroTachas" source="numeroTachas" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal === '/tipo_elemento_senals/SH' &&

                                <br />
                            }


                            {controllerProps.record && controllerProps.record.tipoElementoSenal !== '/tipo_elemento_senals/SV' && controllerProps.record.tipoElementoSenal !== '/tipo_elemento_senals/OTR' &&
                                controllerProps.record.tipoSenal !== '/tipo_senals/SMV' &&

                                <NumberField label="resources.elemento_senalizacions.fields.longitud" source="longitud" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal !== '/tipo_elemento_senals/SV' && controllerProps.record.tipoElementoSenal !== '/tipo_elemento_senals/OTR' &&
                                controllerProps.record.tipoSenal !== '/tipo_senals/SMV' &&

                                <NumberField label="resources.elemento_senalizacions.fields.ancho" source="ancho" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal === '/tipo_elemento_senals/DEM' &&
                                controllerProps.record.tipoSenal !== '/tipo_senals/SMV' &&

                                <NumberField label="resources.elemento_senalizacions.fields.area" source="area" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                            }

                            {controllerProps.record && controllerProps.record.tipoElementoSenal === '/tipo_elemento_senals/OTR' &&
                                controllerProps.record.tipoSenal !== '/tipo_senals/SMV' &&

                                <NumberField label="resources.elemento_senalizacions.fields.cantidad" source="cantidad" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal === '/tipo_elemento_senals/OTR' &&
                                controllerProps.record.tipoSenal !== '/tipo_senals/SMV' &&

                                <NumberField label="resources.elemento_senalizacions.fields.espaciamientoPromedio" source="espaciamientoPromedio" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4} />
                            }
                            <span></span>

                        </FormTab>

                        <FormTab label="dynatest.resources.elemento_senalizacions.tabs.tabsTitle.detalles">

                            <TextField label="resources.elemento_senalizacions.fields.estado" source="estado" fullWidth formClassName={classes.grid_cont4_f} />

                            {controllerProps.record && controllerProps.record.tipoElementoSenal !== '/tipo_elemento_senals/SV' && controllerProps.record.estado === translate('dynatest.resources.elemento_senalizacions.select.estado.nuevo') &&
                                <TextField label="resources.elemento_senalizacions.fields.condicion" source="condicion" fullWidth formClassName={classes.grid_cont4} />
                            }

                            {controllerProps.record && controllerProps.record.estado === translate('dynatest.resources.elemento_senalizacions.select.estado.reemplazo') &&
                                <TextField label="resources.elemento_senalizacions.fields.fullMotivoReemplazo" source="motivoReemplazo" fullWidth formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.estado === translate('dynatest.resources.elemento_senalizacions.select.estado.reemplazo') &&
                                <ReferenceField
                                    label="resources.elemento_senalizacions.fields.fullCodigo"
                                    source="elementoReemplazo_id"
                                    reference="elemento_senalizacions"
                                    linkType={false}
                                    fullWidth
                                    formClassName={classes.grid_cont4}
                                >
                                    <TextField source="fullCodigoConsecutivo" />
                                </ReferenceField>
                            }
                            <br />
                            
                            <TextField label="resources.elemento_senalizacions.fields.estados" source="estados" fullWidth formClassName={classes.grid_cont4} />
                            <br />
                            <DateField label="resources.elemento_senalizacions.fields.fechaInstalacion" source="fechaInstalacion" fullWidth formClassName={classes.grid_cont3} />
                            <ReferenceField label="resources.elemento_senalizacions.fields.proveedor" source="proveedor" reference="proveedors" linkType={false} fullWidth formClassName={classes.grid_cont3}>
                                <TextField source="razonSocial" />
                            </ReferenceField>
                            <NumberField label="resources.elemento_senalizacions.fields.garantia" source="garantia" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                            <br />
                            <BooleanField label="dynatest.resources.elemento_senalizacions.boolean.mantenimiento" source="mantenimiento" fullWidth formClassName={classes.grid_cont4} />
                            <br />
                            <TextField label="resources.elemento_senalizacions.fields.observaciones" source="observaciones" fullWidth formClassName={classes.grid_cont4} />
                        </FormTab>
                        <FormTab label="dynatest.resources.elemento_senalizacions.tabs.tabsTitle.auditoria">
                            <DateField source="createdAt" label="resources.elemento_senalizacions.fields.createdAt" fullWidth formClassName={classes.grid_cont4} showTime/>
                            <TextField source="createdBy" label="resources.elemento_senalizacions.fields.createdBy" fullWidth formClassName={classes.grid_cont4} />
                            <TextField source="createdIn" label="resources.elemento_senalizacions.fields.createdEn" fullWidth formClassName={classes.grid_cont3} />
                            <span></span>
                            <DateField source="updatedAt" label="resources.elemento_senalizacions.fields.updatedAt" fullWidth formClassName={classes.grid_cont4} showTime/>
                            <TextField source="updatedBy" label="resources.elemento_senalizacions.fields.updatedBy" fullWidth formClassName={classes.grid_cont4} />
                            <TextField source="updatedIn" label="resources.elemento_senalizacions.fields.updatedEn" fullWidth formClassName={classes.grid_cont3} />
                            <span></span>
                        </FormTab>
                    </TabbedForm>
                </ShowView>
            }
        </ShowController>
    )
}));
