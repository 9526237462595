import React, { useState, Fragment } from 'react';
import {
    SelectInput, Loading
} from 'react-admin';
import { LinearProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { styles } from './../../../EstilosGrid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Grid from '@material-ui/core/Grid';
import { translate } from 'react-admin';

import config from '../../../config/config';
let urlAppjs = config.UrlServer;
let frontAppjs = config.UrlFront;

/**
 * @param tipoElemento = la entidad a apuntar en el fetcher
 * @param abInicio = la abscisa inicial para el filtro
 * @param abFin = la abscisa final para el filtro
 * 
 */

class AddElement extends React.Component {
    constructor(props) {
        super(props)
        this.stagedProps = {
            parentProps: this.props.parentProps
        }
        this.state = {
            excel: [],
            excelMountCount: 0,
            isExcelLoaded: false,
            isDialogOpen: false,
            disableSelect: false,
            isUpdatingMatrix: false,
            fetchingTasks: 0,
            emisionId: null
        }
        this.fetcher.bind(this);
        this.simpleFetch.bind(this)
        this.returnMatrix.bind(this);
        this.generarmatriz.bind(this);
        this.regenerarMatriz.bind(this);
        this.generarExcel.bind(this);
        this.toDate.bind(this);
    }

    componentDidMount() {
        // console.log(this.props.parentProps)
        if (typeof this.props.parentProps !== 'undefined') {
            this.simpleFetch("matriz/consultar/NULL/" + this.props.parentProps.originId.split("/")[2], "excel");
        }
    }

    fetcher(target, filters = null, fetchOnly = false) {
        this.setState({ isExcelLoaded: false });
        var urlPart = "/" + target +"?aprobacion=Aprobado";
        console.log(urlPart, "Url parte")
        var locationReload = () => { window.location.reload(false) };
        if (Array.isArray(filters)) {
            filters.map(filter => {
                urlPart = urlPart + "&" + filter;
            })
        } else if (filters !== null) {
            urlPart = urlPart + "&" + filters;
        }
        if (fetchOnly) {
            fetch(urlAppjs + urlPart,
                {
                    headers: {
                        'authorization': 'Bearer ' + localStorage.getItem('token')
                    }
                })
                .then(() => {
                    locationReload();
                })
        } else {
            fetch(urlAppjs + urlPart,
                {
                    headers: {
                        'authorization': 'Bearer ' + localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    return response.json()
                })
                .then((Elementos) => {
                    var today = new Date();
                    var dd = String(today.getDate()).padStart(2, '0');

                    this.state["excel"] = Elementos;
                    this.setState({
                        isExcelLoaded: true,
                        isUpdatingMatrix: true
                    });
                    if (Elementos.length == 0) {
                        this.generarmatriz();
                    } else if (Elementos.hasOwnProperty(0)) {
                        if (Elementos[0].hasOwnProperty("fecha_emision")) {
                            if (dd > Elementos[0].fecha_emision.split(" ")[0].split("-")[2]) {
                                this.setState({ isUpdatingMatrix: false });
                                console.log("updating matrix");
                                this.fetcher("matriz/borrar/NULL/" + this.props.parentProps.originId.split("/")[2]);
                                this.fetcher("matriz/consultar/NULL/" + this.props.parentProps.originId.split("/")[2]);
                            } else {
                                this.setState({ isUpdatingMatrix: false });
                                console.log("matrix is up to date");
                            }
                        }
                    } else {
                        this.setState({ isUpdatingMatrix: false });
                        console.log(Elementos)
                    }
                })
        }
    }
    simpleFetch(target, propNameToSave, fetchOnly = false, otherFilters = null) {
        var urlPart = "/" + target;

        if (Array.isArray(otherFilters)) {
            otherFilters.map(filter => {
                urlPart = urlPart + "&" + filter;
            })
        } else if (otherFilters !== null) {
            urlPart = urlPart + "&" + otherFilters;
        }
        if (fetchOnly) {
            this.setState({ fetchingTasks: 1 });
            fetch(urlAppjs + urlPart,
                {
                    headers: {
                        'authorization': 'Bearer ' + localStorage.getItem('token')
                    }
                })
                .then(() => {
                    this.setState({ fetchingTasks: 0 });
                })
                .catch(function (error) {
                    console.log(error)
                });
        } else {
            this.setState({ fetchingTasks: 1 });
            fetch(urlAppjs + urlPart,
                {
                    headers: {
                        'authorization': 'Bearer ' + localStorage.getItem('token')
                    }
                })
                .then((response) => {
                    return response.json()
                })
                .then((Elementos) => {
                    var datos = [];
                    this.state[propNameToSave] = [];
                    if (Elementos.hasOwnProperty("hydra:member")) {
                        this.state[propNameToSave] = Elementos["hydra:member"]
                    } else {
                        this.state[propNameToSave] = Elementos
                    }
                }).then(() => {
                    this.setState({ fetchingTasks: 0 });
                })
                .catch(function (error) {
                    console.log(error)
                });
        }

    }
    regenerarMatriz() {
        this.setState({ isExcelLoaded: false })
        console.log("regenerating matrix");
        this.fetcher("matriz/borrar/NULL/" + this.props.parentProps.originId.split("/")[2], null, true);
    }
    generarmatriz() {
        var target = 'generarmatriz/{"uf": "' + this.props.parentProps.unidadFuncional.split("/")[2] + '",' +
            '"nombre": "' + this.props.parentProps.nombrePolitica.split("/")[2] + '",' +
            '"flujoCaja":"' + this.props.parentProps.originId.split("/")[2] + '",' +
            '"fechaInicio": "' + this.props.parentProps.fechaInicio + '",' +
            '"years":"' + this.props.parentProps.years + '",' +
            '"interes":' + this.props.parentProps.tasaInteres + '}'

        var urlPart = "/" + target;

        fetch(urlAppjs + urlPart,
            {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }).then(() => {
                setTimeout(() => window.location.reload(), 1000)
            })
        // .then(() => {window.reload()})
        // .then((response) => {
        //     //colocar nota de que se está generando una matriz para el registro
        //     return response.json()
        // })
        // .then((Elementos) => {
        //     console.log(Elementos);
        //     this.componentDidMount()
        //     // this.generarExcel(Elementos.emisionId);
        // })
        setTimeout(() => { this.componentDidMount() }, 500)
    }
    borrarMatriz() {
        this.simpleFetch("matriz/borrar/NULL/" + this.props.parentProps.originId.split("/")[2], null, true);
        setTimeout(() => { this.componentDidMount() }, 1000);
    }
    generarExcel() {
        // console.log(id);
        const reload = () => {
            // window.location.reload(false);
            this.componentDidMount();
        }
        if (this.state.excel[0].id !== undefined) {
            var urlPart = "/matriz/generar/" + this.state.excel[0].id + "/NULL";
            fetch(urlAppjs + urlPart,
                {
                    headers: {
                        'authorization': 'Bearer ' + localStorage.getItem('token')
                    }
                })
                .then(() => {
                    // alert("El portal se recargará periódicamente para mostrar el estado de la generación, aunque tambien puede recargar manualmente.");
                    reload();
                })
        }

    }
    toDate(value) {
        var splittedDate = value.split("-");
        var splittedTime = splittedDate[2].split(" ")
        var year = splittedDate[0];
        var month = splittedDate[1];
        var day = splittedTime[0];
        var time = splittedTime[1];
        // console.log(value.split("-"))

        return day + "-" + month + "-" + year + " " + time
    }
    returnMatrix(classes) {
        const { translate } = this.props;
        const handleClickOpen = () => {
            this.setState({ isDialogOpen: true });
        };
        const handleClose = () => {
            this.setState({ isDialogOpen: false });
        };
        const handleFetchExcelDialog = () => {
            this.generarExcel();
            this.setState({ isDialogOpen: false });
        }
        const handleDeleteExcelDialog = () => {
            this.borrarMatriz();
            this.setState({ isDialogOpen: false });
        }
        const generarExcelDialog = (buttonText, header, msg) => {
            return (
                <div>
                    <Button variant="outlined" color="primary" onClick={handleClickOpen}> {buttonText}  </Button>
                    <Dialog
                        open={this.state.isDialogOpen}
                        onClose={handleClose}
                        aria-labelledby="draggable-dialog-title"
                    >
                        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title"> {header} </DialogTitle>
                        <DialogContent>
                            <DialogContentText> {msg} </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={handleClose} color="primary"> {translate('ra.message.no')} </Button>
                            <Button onClick={handleFetchExcelDialog} color="primary"> {translate('ra.message.yes')} </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            );
        }
        const borrarExcelDialog = (buttonText, header, msg) => {
            return (
                <div>
                    <Button variant="outlined" color="primary" onClick={handleClickOpen}> {buttonText}  </Button>
                    <Dialog
                        open={this.state.isDialogOpen}
                        onClose={handleClose}
                        aria-labelledby="draggable-dialog-title"
                    >
                        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title"> {header} </DialogTitle>
                        <DialogContent>
                            <DialogContentText> {msg} </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={handleClose} color="primary"> {translate('ra.message.no')} </Button>
                            <Button onClick={handleDeleteExcelDialog} color="primary"> {translate('ra.message.yes')}</Button>
                        </DialogActions>
                    </Dialog>
                </div>
            );
        }
        var matrix =
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.TbCellCol1}>{translate('resources.flujo_cajas.fields.id')}</TableCell>
                        <TableCell className={classes.TbCellCol3}>{translate('resources.flujo_cajas.fields.fecha')}</TableCell>
                        <TableCell className={classes.TbCellCol3}>{translate('resources.flujo_cajas.fields.status')}</TableCell>
                        <TableCell className={classes.TbCellCol4}>{translate('resources.flujo_cajas.fields.acciones')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.state.excel.map((index) => (
                        <TableRow>
                            <TableCell className={classes.TbCellCol1}>{index.id}</TableCell>
                            <TableCell className={classes.TbCellCol3}>{this.toDate(index.fecha_emision)}</TableCell>
                            <TableCell className={classes.TbCellCol3}>{index.status}</TableCell>
                            <TableCell className={classes.TbCellCol4}>
                                {index.status == "Generando Matriz" &&
                                    <p>{translate('resources.validaciones.sinAcciones')}</p>
                                }
                                {index.status == "Matriz Generada" &&
                                    generarExcelDialog(translate("resources.flujo_cajas.generarExcel"), translate("resources.flujo_cajas.generarExcel2"), translate("resources.flujo_cajas.generarArchivo"))
                                }
                                {index.status == "Listo para Descargar" &&
                                    <div style={{ display: "flex", alignItems: "baseline", justifyContent: "space-between" }}>
                                        <a href={urlAppjs + "/" + index.xls}>{index.xls}</a>
                                        {borrarExcelDialog(<DeleteForeverIcon />, translate("resources.flujo_cajas.borrarMatriz"), translate("resources.flujo_cajas.borrarMatriz"))}
                                    </div>
                                }
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

        return (matrix)

    }
    render() {
        // console.log(this.state)
        var choices = [];
        console.log(this.state.excel);
        if (!this.state.excel.hasOwnProperty("recordId")) {
            if (typeof this.state.excel === "")
                this.state.excel.map((index) => {
                    if (index.xls == "-" && index.status == "Matriz Generada") {
                        choices.push({
                            id: index.id,
                            name: index.id
                        })
                    }
                })
        }
        const { classes } = this.props;
        const { translate } = this.props;
        // console.log(this.state.excel);

        const handleClickOpen = () => {
            this.setState({ isDialogOpen: true });
        };
        const handleClose = () => {
            this.setState({ isDialogOpen: false });
        };
        const handleFetch = () => {
            this.regenerarMatriz();
        }
        const handleFetch2 = () => {
            this.generarmatriz();
            this.setState({ isDialogOpen: false });
        }
        const dialog = (buttonText, header, msg) => {
            return (
                <div>
                    <Button variant="outlined" color="primary" onClick={handleClickOpen}> {buttonText}  </Button>
                    <Dialog
                        open={this.state.isDialogOpen}
                        onClose={handleClose}
                        aria-labelledby="draggable-dialog-title"
                    >
                        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title"> {header} </DialogTitle>
                        <DialogContent>
                            <DialogContentText> {msg} </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={handleClose} color="primary"> {translate('ra.message.no')} </Button>
                            <Button onClick={handleFetch} color="primary"> {translate('ra.message.yes')} </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            );
        }
        const dialog2 = (buttonText, header, msg) => {
            return (
                <div>
                    <Button variant="outlined" color="primary" onClick={handleClickOpen}> {buttonText}  </Button>
                    <Dialog
                        open={this.state.isDialogOpen}
                        onClose={handleClose}
                        aria-labelledby="draggable-dialog-title"
                    >
                        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title"> {header} </DialogTitle>
                        <DialogContent>
                            <DialogContentText> {msg} </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button autoFocus onClick={handleClose} color="primary"> {translate('ra.message.no')} </Button>
                            <Button onClick={handleFetch2} color="primary"> {translate('ra.message.yes')} </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            );
        }
        if (this.state.isUpdatingMatrix) {
            return (
                <div style={{ textAlign: "center", width: "100%" }}>
                    <br />
                    <Loading loadingSecondary="Verificando y/o Actualizando Matriz..." />
                </div>
            )
        }
        // if (this.state.isExcelLoaded == true && this.state.excel.length > 0) {
        //     return (
        //         <Fragment>
        //             <Divider fullWidth />
        //             <div style={{ textAlign: "right", width: "100%" }}>
        //                 {dialog("Regenerar Matriz", "¿Generar de Nuevo la Matriz?", "¿Seguro que desea regenerar nuevamente la matriz?")}
        //             </div>
        //             <Divider />
        //             {this.returnMatrix(classes, this)}

        //         </Fragment>
        //     );
        if (this.state.fetchingTasks <= 0 && this.state.excel.length > 0) {

            return (
                <Fragment>
                    {/* <Divider fullWidth />
                    <div style={{ textAlign: "right", width: "100%" }}>
                        {dialog2("Regenerar Matriz", "¿Generar de Nuevo la Matriz?", "¿Seguro que desea regenerar nuevamente la matriz?")}
                    </div>
                    <Divider /> */}
                    {this.returnMatrix(classes, this)}
                </Fragment>
            )
            // } else if (this.state.isExcelLoaded == true && this.state.excel.length == 0) {
            //     return (
            //         <div style={{ textAlign: "center", width: "100%" }}>
            //             <Typography component="h4">
            //                 Sin resultados.
            //             </Typography>
            //         </div>
            //     )
            // } else {
        } else if (this.state.fetchingTasks <= 0 && this.state.excel.length == 0) {
            return (
                <Fragment>
                    <Divider fullWidth />
                    <div style={{ textAlign: "right", width: "100%" }}>
                        {dialog2(translate("dynatest.resources.flujo_cajas.messages.generarMatriz"), translate("dynatest.resources.flujo_cajas.messages.generarMatriz2"), translate("dynatest.resources.flujo_cajas.messages.confirmacionMatriz"))}
                    </div>
                    <Divider />
                    <div style={{ textAlign: "center", width: "100%" }}>
                        <Typography component="h4">
                            {translate("resources.costos.fields.sinResultados")}
                    </Typography>
                    </div>
                </Fragment>

            )
        } else {
            return (
                <div style={{ textAlign: "center", width: "100%" }}>
                    <br />
                    <Loading loadingSecondary="dynatest.resources.flujo_cajas.messages.ConsInfoMatriz" />
                </div>
            )
        }

    }
}

AddElement.propTypes = {
    classes: PropTypes.object.isRequired,
}
const AddFlujoCajaDetalles = withStyles(styles)(AddElement);


export default translate(AddFlujoCajaDetalles);