import React, { useState } from 'react';
import { connect } from 'react-redux';
import { change, getFormValues, submit, isSubmitting } from 'redux-form';
import {
  required, FormTab, SelectInput, SaveButton, Toolbar, FileInput, FileField, TextInput, REDUX_FORM_NAME, SimpleForm
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { Edit } from '../Edit';
import { withStyles } from '@material-ui/core';
import { styles } from '../../EstilosGrid';
import { TabbedForm } from './../TabbedForm';
import config from '../../config/config';
import { translate } from 'react-admin';
import BackButton from '../../BotonCancelar'
let urlAppjs = config.UrlServer;

const toolbarStyles = {
  toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
  },
};

const titleStyle = { textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '20em' };
const Title = ({ record }) => <spam style={titleStyle}>{record.title}</spam>;
Title.defaultProps = {
  addLabel: true,
};


export const IdiomaEdit = translate(withStyles(styles)(({ classes, ...props }) => {
  const { translate } = props;
  return (
    <Edit title="dynatest.resources.idiomas_config.editTitle" {...props} undoable={false}>
      {/* <WithProps>{({record,...props})=> */}
      <SimpleForm submitOnEnter={false} >
        <FormTab label="dynatest.resources.idiomas.formTabs.seleccionIdiomaBase">
          {/* <Title label="dynatest.resources.idiomas.formTabs.seleccionIdiomaBase" /> */}
          {/* <TextInput label="dynatest.resources.idiomas_config.fields.idioma" source="idiomaBase" formClassName={classes.grid_cont4} fullWidth validate={[required()]} /> */}
          <TextInput label="dynatest.resources.idiomas_config.fields.nombreIdioma" source="nombreIdioma" disabled formClassName={classes.grid_cont4} fullWidth validate={[required()]} />
          <TextInput label="dynatest.resources.idiomas_config.fields.region" source="region" disabled formClassName={classes.grid_cont4} fullWidth validate={[required()]} />
          {/* <SelectInput source="estado" formClassName={classes.grid_cont4} sort={{ field: 'estado', order: 'ASC' }} choices={[
              { id: 'Activo', name: 'Activo' },
              { id: 'Inactivo', name: 'Inactivo' },
            ]} fullWidth validate={[required()]} /> */}
          {/* <Title label="dynatest.resources.idiomas.formTabs.cargueArchivos" /> */}
          <Typography component="h2" variant="title" >{translate('dynatest.resources.idiomas.formTabs.cargueArchivos')}</Typography>
          <FileInput source="manual_usuario" accept=".pdf" multiple={false}  validate={[required()]}>
            <FileField source="src" title="title" />
          </FileInput>
          {/* <FileInput label="dynatest.resources.idiomas_config.modulo_administracion" source="modulo_administracion" accept=".js" multiple={false} >
            <FileField source="cargueIdioma" title="title" />
          </FileInput> */}
          {/* <FileInput source="modulo_inventario" accept=".csv" multiple={false} >
            <FileField source="cargueIdioma" title="title" />
          </FileInput> */}
        </FormTab>
      </SimpleForm>
      {/* </WithProps> */}
    </Edit>
  )
}));
const WithProps = ({ children, ...props }) => children(props);