import React from 'react';

import { TextInput} from 'react-admin';
import { SimpleForm } from './../../../SimpleForm';
import { withStyles } from '@material-ui/core';
import { Edit } from './../../../Edit';


const styles = {
    first_field: { display: 'inline-block', width: '30%' },    
};

export const EvaluacionEdit = withStyles(styles)(({ classes, ...props }) => (
        <Edit title="Editar evaluación" {...props} undoable={false}>
            <SimpleForm submitOnEnter={false} redirect="list" >
            <TextInput source="tipo" label="Evaluación"/>                
            </SimpleForm>
        </Edit>
))