import React, { useState } from 'react';
import {
    Filter, List, Datagrid, TextField, EditButton, ShowButton, SelectInput, ReferenceInput, AutocompleteInput, FormDataConsumer
} from 'react-admin';
import Button from '@material-ui/core/Button';
import { BulkDeleteWithConfirmButton, Loading } from 'react-admin';
import { Fragment } from 'react';
import config from '../../config/config';
import {REDUX_FORM_NAME} from 'react-admin';
import {change} from 'redux-form';
import { PField } from './../../fields/PField';
import { translate } from 'react-admin';
import { CardActions, CreateButton, ExportButton } from 'react-admin';
let urlAppjs = config.UrlServer;

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props} />
    </Fragment>
);
const ListAction = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
        <ExportButton basePath={basePath}/>
    </CardActions>
);
const ListAction2 = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
    </CardActions>
);
const FiltroPuentes = translate((props) => {
    const { translate } = props;
    const [uf, setUf] = useState('none');
    const ufSet = (value) => {
        tramoSet('none');
        setUf(value);
        change(REDUX_FORM_NAME, "tramo", null)
    }
    const [tramo, setTramo] = useState('none');
    const tramoSet = (value) => {
        setTramo(value);
    }

    return (
        <Filter {...props}>
            <ReferenceInput
                id="unidadFuncional"
                label="resources.puentes.fields.unidad_funcional"
                source="unidad_funcional"
                reference="unidad_funcionals"
                filterToQuery={searchText => ({ codigo: searchText })}
                sort={{ field: 'codigo', order: 'asc' }}
                validate={[ufSet]}
                alwaysOn
                fullWidth
                onChange={value => change(REDUX_FORM_NAME, "tramo", null)}
            >
                <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
            </ReferenceInput>
            <ReferenceInput
                label="resources.puentes.fields.tramo"
                source="tramo"
                alwaysOn
                reference="tramos"
                filter={{ unidadFuncional: uf }}
                validate={[tramoSet]}
                filterToQuery={searchText => ({ codigo: searchText })}
                sort={{
                    field: 'codigo',
                    order: 'ASC',

                }}
                onChange={value => change(REDUX_FORM_NAME, "segmento", null)}
                >
                <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
            </ReferenceInput>

            <ReferenceInput
                label="resources.puentes.fields.segmento"
                source="segmento"
                reference="segmentos"
                alwaysOn
                sort={{ field: 'codigo', order: 'ASC' }}
                filter={{ tramo: tramo }}
                filterToQuery={searchText => ({ codigo: searchText })}
            >
                <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
            </ReferenceInput>
            
            {/*<FormDataConsumer >
                {({ formData, dispatch }) => (
                    <Fragment>
                        <ReferenceInput
                            label="Unidad Funcional"
                            source="unidad_funcional"
                            reference="unidad_funcionals"
                            sort={{ field: 'codigo', order: 'ASC' }}
                            onChange={value => dispatch(
                                change(REDUX_FORM_NAME, "tramo", null)
                            )}
                            filterToQuery={searchText => ({ codigo: searchText })}
                        >
                            <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
                        </ReferenceInput>
                        <ReferenceInput
                            label="Tramo"
                            source="tramo"
                            reference="tramos"
                            filter={{ unidadFuncional: (formData.unidad_funcional ? formData.unidad_funcional : 'none') }}
                            sort={{ field: 'codigo', order: 'ASC' }}
                            onChange={value => dispatch(
                                change(REDUX_FORM_NAME, "segmento", null)
                            )}
                            filterToQuery={searchText => ({ codigo: searchText })}
                        >
                            <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
                        </ReferenceInput>
                        <ReferenceInput
                            label="Segmento"
                            source="segmento"
                            reference="segmentos"
                            filter={{
                                tramo: (formData.tramo ? formData.tramo : 'none')
                            }}
                            sort={{ field: 'codigo', order: 'ASC' }}
                            filterToQuery={searchText => ({ codigo: searchText })}
                        >
                            <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
                        </ReferenceInput>
                    </Fragment>

                )}
                        </FormDataConsumer>*/}
            <SelectInput
                fullWidth
                label="resources.puentes.fields.estados"
                source="detalles.estados"
                sort={{ field: 'name', order: 'ASC' }}
                alwaysOn
                choices={[
                    { id: translate('dynatest.resources.puentes.select.estados.Activo'), name: "dynatest.resources.puentes.select.estados.Activo" },
                    { id: translate('dynatest.resources.puentes.select.estados.Inactivo'), name: "dynatest.resources.puentes.select.estados.Inactivo" },
                ]}
                resettable
            />
        </Filter>
    )
});

export const PuenteList = ({ permissions, ...props }) => {
    if (!permissions) return <Loading />;
    return (
        <List title="dynatest.resources.puentes.title.listTitle" 
            {...props} 
            sort={{ field: 'createdAt', order: 'DESC' }} 
            filters={<FiltroPuentes />} 
            actions={permissions['puentes']  == 1 ? <ListAction /> : permissions['puentes']  == 2 ? <ListAction2 />: null}
            bulkActionButtons={permissions['puentes'] == 1 ? <PostBulkActionButtons /> : null}>
            <Datagrid>
                <TextField label="resources.puentes.fields.codigo" source="smartCode" sortable />
                <TextField label="resources.puentes.fields.tipo" source="tipo" allowEmpty emptyValue="" fullWidth sortable />
                <PField label="resources.puentes.fields.PR" source="pInicio"/>
                <TextField label="resources.puentes.fields.margen" source="margen" fullWidth choices={[
                ]} />
                <TextField label="resources.puentes.fields.estados" source="detalles[0].estados" linkType={false} fullWidth sortable />
                {permissions['puentes'] == 1 || permissions['puentes'] == 2 || permissions['puentes'] == 4 ? <ShowButton/>: null}
                {permissions['puentes'] == 1 || permissions['puentes'] == 2 ? <EditButton/>: null}      
            </Datagrid>
        </List>
    )
};