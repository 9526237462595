import React, { useState, Fragment } from 'react';
import {
    SimpleForm, required, TextInput, SelectInput, maxLength, BooleanInput, NumberInput, ReferenceInput, AutocompleteInput,
    minValue, FormDataConsumer, REDUX_FORM_NAME
} from 'react-admin';
import { change } from 'redux-form';
import { Edit } from './../../Edit';
import { withStyles, Grid, Button } from '@material-ui/core';
import { styles } from './../../../EstilosGrid';
import { ComponenteDeterioroPers } from '../componentes/ComponeteDeterioroPers';
import { Entero } from '../../validacionesInputs'
import ClearIcon from '@material-ui/icons/Clear';
import { translate } from 'react-admin';

export const DeterioroPersEdit = translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;
    const [tipoElemento, setTipoElemento] = useState();
    const ValidarTipoElemento = (value) => {
        setTipoElemento(value)
    }
    return (
        <Edit {...props} undoable={false} title="dynatest.resources.tipo_deterioro_pers.title.editTitle" >
            <SimpleForm redirect="list" >
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <SelectInput id='tipoElemento' label="resources.tipo_deterioro_pers.fields.tipoSuperficie" source='tipoSuperficie' choices={[
                            { id: 'PFLEX', name: 'dynatest.resources.tipo_deterioro_pers.select.tipoSuperficie.PFLEX' },
                            { id: 'PRIGD', name: 'dynatest.resources.tipo_deterioro_pers.select.tipoSuperficie.PRIGD' },
                            { id: 'PART', name: 'dynatest.resources.tipo_deterioro_pers.select.tipoSuperficie.PART'},
                            { id: 'POTR', name: 'dynatest.resources.tipo_deterioro_pers.select.tipoSuperficie.POTR'},
                        ]} validate={[required(), ValidarTipoElemento]} disabled fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <NumberInput label="resources.tipo_deterioro_pers.fields.codigoAstm" source="codigoAstm" validate={[required(), minValue(0), Entero]} fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectInput label="resources.tipo_deterioro_pers.fields.nivelSeveridad" source='nivelSeveridad' choices={[
                            { id: 1, name: 'dynatest.resources.tipo_deterioro_pers.select.nivelSeveridad.1' },
                            { id: 3, name: 'dynatest.resources.tipo_deterioro_pers.select.nivelSeveridad.3' },
                        ]} validate={[required()]} fullWidth />
                    </Grid>
                    <Grid item xs={4}>
                        <BooleanInput label="resources.tipo_deterioro_pers.fields.estado" source="estado" fullWidth />
                    </Grid>
                    <Grid item xs={4}>
                        <TextInput label="resources.tipo_deterioro_pers.fields.nombre" source="nombre" validate={[required(), maxLength(40)]} fullWidth />
                    </Grid>
                    <Grid item xs={4}>
                        {tipoElemento === 'PFLEX' &&
                            <SelectInput label="resources.tipo_deterioro_pers.fields.unidad" source='unidad' choices={[
                                {id : 'm', name:'m'},
                                {id : 'm²', name:'m²'}
                            ]} validate={[required()]} fullWidth/>
                        }
                        {tipoElemento !== 'PFLEX' && tipoElemento !== 'PART' && tipoElemento !== 'POTR' &&
                            <SelectInput label="resources.tipo_deterioro_pers.fields.unidad" source='unidad' choices={[ 
                                {id : 'm', name:'m'},
                                {id : 'm²', name:'m²'},
                                {id : translate('resources.tipo_deterioro_pers.fields.losa'), name:'resources.tipo_deterioro_pers.fields.losa'}
                            ]} validate={[required()]} fullWidth/>
                        }
                        {tipoElemento === 'PART' &&
                            <SelectInput label="resources.tipo_deterioro_pers.fields.unidad" source='unidad' choices={[
                                {id : 'm', name:'m'},
                                {id : 'm²', name:'m²'}
                            ]} validate={[required()]} fullWidth/>
                        }
                        {tipoElemento === 'POTR' &&
                            <SelectInput label="resources.tipo_deterioro_pers.fields.unidad" source='unidad' choices={[
                                {id : 'm', name:'m'},
                                {id : 'm²', name:'m²'}
                            ]} validate={[required()]} fullWidth/>
                        }
                    </Grid>
                    <FormDataConsumer>
                        {({ formData, dispatch, ...rest }) => (
                            <Fragment>
                                <Grid item xs={6} style={{ display: "flex" }} alignItems="flex-end">
                                    <ReferenceInput
                                        label="resources.tipo_deterioro_pers.fields.tipoReparacionMedia"
                                        source="tipoReparacionMedia"
                                        reference="reparacion_localizadas"
                                        filter={{ tipoReparacion: tipoElemento }}
                                        allowEmpty
                                    >
                                        <AutocompleteInput options={{ fullWidth: true }} optionText="nombre" onClick={ValidarTipoElemento} />
                                    </ReferenceInput>
                                    <Button onClick={value => dispatch(change(REDUX_FORM_NAME, "tipoReparacionMedia", null))}><ClearIcon /></Button>
                                </Grid>
                                <Grid item xs={6} style={{ display: "flex" }} alignItems="flex-end">
                                    <ReferenceInput
                                        label="resources.tipo_deterioro_pers.fields.tipoReparacionAlta"
                                        source="tipoReparacionAlta"
                                        reference="reparacion_localizadas"
                                        filter={{ tipoReparacion: tipoElemento }}
                                        allowEmpty
                                    >
                                        <AutocompleteInput options={{ fullWidth: true }} optionText="nombre" />
                                    </ReferenceInput>
                                    <Button onClick={value => dispatch(change(REDUX_FORM_NAME, "tipoReparacionAlta", null))}><ClearIcon /></Button>
                                </Grid>

                            </Fragment>
                        )}
                    </FormDataConsumer>
                </Grid>
            </SimpleForm>
        </Edit>
    )
}));