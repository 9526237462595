import React from 'react';
import { TextField, TabbedShowLayout, Tab, ImageField } from 'react-admin';
import { Show } from './../../Show';
import { withStyles } from '@material-ui/core';
import { ButtonCarousel } from './../../images/ButtonCarousel';

const styles = {
    first_field: { color: 'blue !important' },
};

export const ElementoDeterioroShow = withStyles(styles)(({ classes,record, ...props }) => (
    <Show title="Información Deterioro" {...props} actions={<ButtonCarousel/>}>
        <TabbedShowLayout>
            <Tab label="Deteriorio"> 
                {/* <TextField source="deterioros_pci" label="PCI"/>
                <div></div><div></div>
                <TextField source="deterioros_fecha_ultima" label="Fecha de última evaluación"/> 
                <TextField source="deterioros_periocidad" label="Periodicidad de medición (mes)" />             
                <TextField source="fechaCalculada" label="Fecha próxima medición" />            
                <TextField source="deterioros_ancho_long" label="Ancho longitudinales(m)" />
                <div></div><div></div>
                <div className={classes.subtittle}><strong>Deterioro</strong></div><div></div><div></div>
                <hr fullWidth /><hr fullWidth /><hr fullWidth />
                <TextField source="deterioros_list" label="Deterioro" />  
                <TextField source="deterioros_unidad" label="Unidad"/>   
                <div></div>
                <TextField source="deterioros_sbaja_la" label="Severidad baja  (Longitud o Área)" />             
                <TextField source="" label="Severidad baja (%)" />             
                <div></div>
                <TextField source="deterioros_smedia_la" label="Severidad media  (Longitud o Área)" />             
                <TextField source="" label="Severidad Media(%)" />             
                <div></div>
                <TextField source="deterioros_salta_la" label="Severidad Alta (Longitud o Área)" />             
                <TextField source="" label="Severidad Alta(%)" />             
                <div></div>
                <TextField source="sumaAfectado" label="Suma % afectado"/>               
                <div></div><div></div>
                <hr fullWidth /><hr fullWidth /><hr fullWidth />
                <TextField source="deterioros_observaciones" label="Observaciones" />                        */}
            </Tab>
        </TabbedShowLayout>
    </Show>
));