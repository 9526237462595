
import PropTypes from 'prop-types';
import fFormatter from '../format/FFormat';

/**
 * 
 * , isNumericString:true
 */

export const FField = ({ source, record = {}, props }) => fFormatter({value: record[source], displayType:'text', ...props});

FField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

FField.defaultProps = {
    addLabel: true,
};
