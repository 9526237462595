import React, { useState } from 'react';
import {
    SimpleForm, ReferenceInput, SelectInput, DateInput, TextInput, AutocompleteInput
} from 'react-admin';
import { PimsCreate } from './../Create';
import {
    required,

} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import { Toolbar, SaveButton } from 'react-admin';
import CancelIcon from '@material-ui/icons/Cancel';
import BackButton from './../../BotonCancelar';
import config from '../../config/config';
import { translate } from 'react-admin';
let urlAppjs = config.UrlFront;

const LinkCancelar = () => {
    let redirect = "proveedors"
    return <a href={urlAppjs + redirect} class="cancelar" ><CancelIcon style={{ width: '0.8em', paddingRight: '8px' }} />CANCELAR</a>;
}

const styles = {

    img: {
        height: 128,
    },
    number: {
        textAlign: 'right',
    },
    ocultar: {
        display: 'none'
    }
}



const CustomToolbar = (({ basePath, data, ...props }) =>
    <Toolbar {...props} >
        <SaveButton
            redirect='/proveedors/'
            submitOnEnter={true} />
        <SaveButton style={{ marginLeft: '15px', marginRight: '50px' }}
            label="dynatest.resources.calificacion_proveedors.buttons.saveAndEnd"
            redirect={false}
            submitOnEnter={true}
        />
        <BackButton />
    </Toolbar>
);

const fecha = new Date();

export const ProveedorCalificacionCreate = translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;

    return (
        <PimsCreate {...props} title="dynatest.resources.calificacion_proveedors.title.qualifyTitle" actions={false} >
            <SimpleForm submitOnEnter={false} toolbar={<CustomToolbar />} >
                <Grid container spacing={24}>
                    <Grid item xs={4}>
                        <ReferenceInput
                            label="resources.calificacion_proveedors.fields.proveedor"
                            source="proveedor"
                            reference="proveedors"
                            validate={[required()]}
                            fullWidth
                            filterToQuery={searchText => ({ razonSocial: searchText })}
                            sort={{ field: "razonSocial", order: "ASC" }}
                        >
                            <AutocompleteInput optionText="razonSocial" />
                        </ReferenceInput>
                    </Grid>
                    <Grid item xs={2}>
                        <SelectInput label="resources.calificacion_proveedors.fields.calificacion" fullWidth source="calificacion" choices={[
                            { id: 1, name: 'dynatest.resources.calificacion_proveedors.select.calificacion.1' },
                            { id: 2, name: 'dynatest.resources.calificacion_proveedors.select.calificacion.2' },
                            { id: 3, name: 'dynatest.resources.calificacion_proveedors.select.calificacion.3' },
                            { id: 4, name: 'dynatest.resources.calificacion_proveedors.select.calificacion.4' },
                            { id: 5, name: 'dynatest.resources.calificacion_proveedors.select.calificacion.5' },
                        ]} validate={[required()]} />
                    </Grid>
                    <Grid item xs={2}>
                        <DateInput label="resources.calificacion_proveedors.fields.fechaCalificacion" source="fechaCalificacion" fullWidth validate={[required()]} defaultValue={fecha} disabled />
                    </Grid>
                    <Grid item xs={4}>
                        <TextInput label="resources.calificacion_proveedors.fields.usuario_id" source="usuario_id" fullWidth validate={[required()]} defaultValue={window.localStorage.getItem('ucalpro')} disabled />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="span" component="span">{translate('dynatest.resources.calificacion_proveedors.span.descripcionCalificacion')}</Typography>
                    </Grid>
                </Grid>
            </SimpleForm>
        </PimsCreate>
    )
}));
