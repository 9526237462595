import React, { useState } from 'react';
import {
    FormTab, TextInput, DateInput, ReferenceInput, SelectInput, FormDataConsumer, NumberInput, BooleanInput,
    SimpleFormIterator, ArrayInput, Query, Loading, Error, ReferenceField, TextField, ArrayField, Datagrid, AutocompleteInput,DisabledInput
} from 'react-admin';
import { TabbedForm } from './../TabbedForm';
import { Edit } from './../Edit';
import { styles } from './../../EstilosGrid';
import { PRInput } from './../../inputs/PRInput';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { FInput } from './../../inputs/FInput';
import {
    required,
} from 'react-admin';
import {
    abValidationInicio,
    ValorMaximoAbsCreate,
    latMininima,
    lngMininima,
    isFechaInvalida,
    isFechaInvalidaDayEquals,
    validacionmaxfechaact
} from './../validacionesInputs';
import { minValue, maxValue, minLength, maxLength } from 'ra-core';
import config from '../../config/config';
import { ArrayInputImagesEdit } from './../images/ArrayInputImagesEdit';
import { ImagenesInventario } from '../images/ImagenesInventario';
import { ButtonCarousel } from './../images/ButtonCarouselPeru';
import { translate } from 'react-admin';
import AsociarServiciosEmergencia from './AsociarServiciosEmergencia';
import AsociarServicio from './AsociarServicio';
import AsociarServicioProveedor from './AsociarServicioProveedor';
let urlAppjs = config.UrlServer;



// const styles = {
//     ocultar: { display: "none", },
//     grid_cont2: { display: 'inline-block', width: '20%', marginLeft: '10px' },
//     grid_cont3: { display: 'inline-block', width: '24%', marginLeft: '10px' },
//     grid_cont4: { display: 'inline-block', width: '32%', marginLeft: '10px' },
//     grid_cont6: { display: 'inline-block', width: '49%', marginLeft: '10px' },
//     grid_cont12: { display: 'inline-block', width: '99%', marginLeft: '10px' },
//     "div:empty": { display: 'none' }
// };

export const DisponibilidadEdit = translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;
    const [estado, setEstado] = useState('');
    const [abValidaini, setAbValidaini] = useState('');
    const [abValidafin, setAbValidafin] = useState('');
    const [unidad, setUnidad] = useState('');
    const [longitudafect, setLongitud] = useState('');
    const [causa, setCausa] = useState('');
    const [longcl, setLongcl] = useState('');
    const [elementoaf, setElementoaf] = useState('');
    const [volumenderr, setVolumenderr] = useState('');
    const [condicionesesp, setCondicionesesp] = useState('');
    const [tiempodespeje, setTiempodespeje] = useState('');
    const [unidadfun, setUnidadfun] = useState('');
    const [abingini, setAbingini] = useState('');
    const [abingfin, setAbingfin] = useState('');
    const [segmentosafectados, setSegmentosafectados] = useState('');
    const [segmentosafectadosid, setSegmentosafectadosid] = useState('');
    const [horasen, setHoras] = useState('');
    const [proyectos, setProyecto] = useState(window.localStorage.getItem('id_project2'));
    const [diasdespeje, setDiasdespeje] = useState('');
    const [horadespeje, setHoradespeje] = useState('');
    const [indicadoro4, setIndicadoro4] = useState('');
    const [diasconcierre, setDiasconcierre] = useState('');
    const [indicadore17, setDndicadore17] = useState('');
    const [segmentosCierre, setSegmentosCierre] = useState([]);
    const [tiemposervicio, setTiemposervicio] = useState([]);
    const [nombreCausa, setNombreCausa] = useState('');

    const ValidarEvento = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length); index++) { valor += value[index]; }
        setEstado(valor);
        console.log("estado=" + estado + ".");
    }
    var today = new Date();
    var horaact = today.getHours();
    var minact = today.getMinutes();
    if (horaact.toString().length <= 1) {
        horaact = "0" + horaact;
    }

    if (minact.toString().length <= 1) {
        minact = "0" + minact;
    }
    //console.log(horaact.length+" AND "+minact.length)
    //console.log(typeof minact);
    var hora = horaact + ":" + minact;
    const HoraSenal = (value) => {
        if (document.getElementById('fecha_senal') && document.getElementById('Fechareport') && document.getElementById('hora_senal') && document.getElementById('Horardeporte')) {
            let fecha_senalizacion = document.getElementById('fecha_senal').value;
            let fecha_reporte = document.getElementById('Fechareport').value;
            let tiempo_senalizacion = document.getElementById('hora_senal').value;
            let tiempo_reporte = document.getElementById('Horardeporte').value;
            let tiemposplit = tiempo_reporte.split(':');
            let hora_reporte = tiemposplit[0];
            let minuto_reporte = tiemposplit[1];
            if (hora_reporte.toString().length <= 1) {
                hora_reporte = "0" + hora_reporte;
            }
            tiempo_reporte = hora_reporte + ":" + minuto_reporte;
            let fecha_inicial = fecha_reporte + "T" + tiempo_reporte;
            let fecha_final = fecha_senalizacion + "T" + tiempo_senalizacion;
            let resultado = RestarFechas(fecha_inicial, fecha_final);
            console.log(resultado);
            setHoras(resultado);
        }
    }
    const ValidarUnidad = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';

        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setUnidad(valor)
    }
    const validarUnifun = (value) => {
        if (value) {
            if (value != "") {
                let idunidadactual = value.split('/');
                if (value && idunidadactual[2] != unidadfun) {
                    let sperador1 = value.split('/');
                    console.log("urlAppjs");
                    console.log(sperador1[2]);
                    setUnidadfun(sperador1[2]);
                    fetch(urlAppjs + '/unidad_funcionals/' + sperador1[2])
                        .then((response) => {
                            console.log("REST");
                            return response.json();
                        })
                        .then((response) => {
                            setAbValidaini(response['abInicio']);
                            setAbValidafin(response['abFin']);
                            validarsegmentosafect(0)
                        })
                }
            }
        }
    }
    const validarAbini = (value) => {
        //console.log("El valor es = "+value+"La abs = "+abValidaini);
        if (value && value.search("[\\K\\+]") != -1) {

            let sperador1 = value.split('K');
            let separador2 = sperador1[1].split('+');
            let cadena = separador2[0] + '.' + separador2[1]
            let absfin = document.getElementById('abfin').value;
            cadena = Number.parseFloat(cadena)
            if (cadena < abValidaini) {
                return translate('resources.validaciones.abscisaInicial') + abValidaini + ' y ' + abValidafin
            }
            else if (cadena > abValidafin) {
                return translate('resources.validaciones.abscisaInicial') + abValidaini + ' y ' + abValidafin
            }
            else if (absfin && absfin.search("[\\K\\+]") != -1) {
                let sperador1 = absfin.split('K');
                let separador2 = sperador1[1].split('+');
                let cadena2 = separador2[0] + '.' + separador2[1];
                cadena2 = Number.parseFloat(cadena2)


                if (cadena2 <= cadena) {
                    return translate('resources.validaciones.abscisaInicialDebeSerMenor');
                }
            }
        }
    };
    const validarAbfin = (value) => {
        //console.log(abValidafin);
        if (value && value.search("[\\K\\+]") != -1) {

            let sperador1 = value.split('K');
            let separador2 = sperador1[1].split('+');
            let cadena = separador2[0] + '.' + separador2[1]
            let absini = document.getElementById('abin').value;
            cadena = Number.parseFloat(cadena)
            if (cadena > abValidafin) {
                return translate('resources.validaciones.abscisaFinal') + abValidaini + " y " + abValidafin
            }
            if (cadena < abValidaini) {
                return translate('resources.validaciones.abscisaFinal') + abValidaini + " y " + abValidafin
            }
            if (absini && absini.search("[\\K\\+]") != -1) {
                let sperador1 = absini.split('K');
                let separador2 = sperador1[1].split('+');
                let cadena2 = separador2[0] + '.' + separador2[1];
                cadena2 = Number.parseFloat(cadena2)
                if (cadena2 >= cadena) {
                    return translate('resources.validaciones.abscisaFinalDebeSerMayor');
                }
            }
        }
    };
    const validarLongitudafect = (value) => {
        let abini = document.getElementById('abin').value
        let abfin = document.getElementById('abfin').value
        if (abfin != "" && abini != "") {
            abini = abini.replace('K', '');
            abini = abini.replace('+', '.');
            abfin = abfin.replace('K', '');
            abfin = abfin.replace('+', '.');
            let res = (abfin - abini).toFixed(3);
            setLongitud(res);
        }
    }
    const validarMaxl = (value) => {
        console.log(props)
        let largo = value.length;
        if (largo > 40) {
            return translate('resources.validaciones.max40');
        }
    }
    const obtenerdominio = () => {
        return urlAppjs;
    }
    const validarCausa = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';

        for (let index = 0; index < (lenghtObject.length); index++) { valor += value[index]; }
        setCausa(valor)
    }
    const validarLongcl = (value) => {
        if(value && typeof value != 'undefined'){
            if (!Number.isInteger(value) || value < 0) {
                return translate('resources.validaciones.enteroPositivo');
            }
            else if (value > 300) {
                setLongcl("No Cumple");
            }
            else if (value <= 300) {
                setLongcl("Cumple");
            }
        }
    }
    const validarVolder = (value) => {
        if (!Number.isInteger(value) || value < 0) {
            return translate('resources.validaciones.enteroPositivo');
        }
        else {
            setVolumenderr(value);
        }
    }
    const validarElemento = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';

        for (let index = 0; index < (lenghtObject.length); index++) { valor += value[index]; }
        setElementoaf(valor);
    }
    const validarCondicionesesp = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';

        for (let index = 0; index < (lenghtObject.length); index++) { valor += value[index]; }
        setCondicionesesp(valor)
        console.log(valor);
    }
    const valorTiempodespeje = () => {
        let aux = "";
        if (condicionesesp === "Disposición de Nuevos Dispositivos de Contención" && volumenderr > 2000) {
            aux = "1 semana";
        }
        else if (condicionesesp === "Mayores Dificultades" && volumenderr > 2000) {
            aux = "1 mes";
        }
        else if (volumenderr > 200 && volumenderr <= 2000 && elementoaf === "Calzada") {
            aux = "24";
        }
        else if (volumenderr > 200 && volumenderr <= 2000 && elementoaf === "Bermas") {
            aux = "24";
        }
        else if (volumenderr <= 200 && elementoaf === "Calzada") {
            aux = "4";
        }
        else if (volumenderr <= 200 && elementoaf === "Bermas") {
            aux = "24";
        }
        setTiempodespeje(aux);
        /*let valorC = document.getElementById("tiempodespejemax");
        if (valorC) {
             valorC.value = aux;
        }*/

    }
    const validarTipoincidente = (value) => {
        let largo = value.length;
        if (largo > 40) {
            return translate('resources.validaciones.max40');
        }
    }
    const validarTipovehiculootro = (value) => {
        let largo = value.length;
        if (largo > 20) {
            return translate('resources.validaciones.max20');
        }
    }
    
    const validarCausaProbable = (value) => {
        let largo = value.length;
        if (largo > 200) {
            return translate('resources.validaciones.max200');
        }
    }
    const validarClasePolicia = (value) => {
        let largo = value.length;
        if (largo > 20) {
            return translate('resources.validaciones.max20');
        }
    }
    const validarcantidadheridos = (value) => {
        if (value < 0 || value > 100) {
            return translate('resources.validaciones.cantidadEntre');
        }
    }
    const validartipoheridos = (value) => {
        let largo = value.length;
        if (largo > 20) {
            return translate('resources.validaciones.max20');
        }
    }
    const validargeneroheridos = (value) => {
        let largo = value.length;
        if (largo > 10) {
            return translate('resources.validaciones.max10');
        }
    }
    const validaredadheridos = (value) => {
        if (value < 0 || value > 122) {
            return translate('resources.validaciones.edadEntre');
        }
    }
    const validartipovehiculo = (value) => {
        let largo = value.length;
        if (largo > 15) {
            return translate('resources.validaciones.max15');
        }
    }
    const validarobservaciones = (value) => {
        let largo = value.length;
        if (largo > 200) {
            return translate('resources.validaciones.max200');
        }
    }
    const validarsegmentosafect = (value) => {
        let abini = document.getElementById('abin').value
        let abfin = document.getElementById('abfin').value
        let safect = document.getElementById('segmentosAfect').value;
        if (abfin != "" && abini != "") {
            abini = abini.replace('K', '');
            abini = abini.replace('+', '.');
            abfin = abfin.replace('K', '');
            abfin = abfin.replace('+', '.');
            //console.log("abini ="+abini+" abingini= "+abingini)    
            if (abini != abingini || abfin != abingfin || safect == "") {
                setAbingini(abini);
                setAbingfin(abfin);
                fetch(obtenerdominio() + "/disponibilidadViaSeg/" + unidadfun + "/" + abini + "/" + abfin)
                    .then((response) => {
                        return response.json();
                    })
                    .then((response) => {
                        let largo = response.codigoseg.length;
                        let valor = "";
                        let valor2 = [];
                        for (let i = 0; i < largo; i++) {
                            valor = valor + response.codigoseg[i] + "; ";
                            valor2[i] = "segmentos/" + response.segid[i];
                        }
                        setSegmentosafectados(valor);
                        setSegmentosCierre(valor2);

                        largo = response.segid.length;
                        valor = "";
                        for (let i = 0; i < largo; i++) {
                            valor = valor + response.segid[i] + "; ";
                        }
                        setSegmentosafectadosid(valor);
                    })
            }
        }
    }
    const validardespeje = (value) => {
        let despejeh = "";
        let despejem = "";
        if (document.getElementById('hora_despeje')) {
            let aux = document.getElementById('hora_despeje').value;
            let aux2 = aux.split(':');
            despejeh = aux2[0];
            despejem = aux2[1];
            if (despejeh.toString().length <= 1) {
                despejeh = "0" + despejeh;
            }

            if (despejem.toString().length <= 1) {
                despejem = "0" + despejem;
            }
        }
        if (document.getElementById('fecha_despeje')) {
            let fecha_despeje = new Date(document.getElementById('fecha_despeje').value + "T" + despejeh + ":" + despejem);
            let fecha_senalizacion = new Date(document.getElementById('fecha_senal').value + "T" + document.getElementById('hora_senal').value);
            let fecha_despeje_off = new Date(fecha_despeje.getTime() + Math.abs(fecha_despeje.getTimezoneOffset() * 60000));
            let fecha_senalizacion_off = new Date(fecha_senalizacion.getTime() + Math.abs(fecha_senalizacion.getTimezoneOffset() * 60000));
            let difference = fecha_senalizacion_off.getTime() - fecha_despeje_off.getTime();

            let differencedays = difference / (1000 * 3600 * 24);
            let differencehours = (difference / (1000 * 3600)) % 24;
            let differencehours2 = (difference / (1000 * 3600));
            let differenceminutes = (difference / (1000 * 60)) % 60;
            let horas_res = Math.abs(parseInt(differencehours));
            let minutos_res = Math.abs(parseInt(differenceminutes));
            if (horas_res.toString().length <= 1) {
                horas_res = "0" + horas_res;
            }

            if (minutos_res.toString().length <= 1) {
                minutos_res = "0" + minutos_res;
            }
            setDiasdespeje(Math.abs(parseInt(differencedays)));
            //document.getElementById('dias_despejef').value=Math.abs(parseInt(differencedays));
            setHoradespeje(horas_res + ":" + minutos_res);
            //document.getElementById('tiempo_deespejef').value=Math.abs(parseInt(differencehours))+":"+Math.abs(parseInt(differenceminutes));
            //console.log(differencehours+":"+differenceminutes);
            let tiempomax = ''
            if(document.getElementById('tiempodespejemax')){
                tiempomax = document.getElementById('tiempodespejemax').value;
            }
            let horasmax = 0;
            let aux1 = "";
            if (tiempomax === '1 semana') {
                horasmax = 168;
            }
            else if (tiempomax === '1 mes') {
                horasmax = 730;
            }
            else {
                horasmax = parseInt(tiempomax);
            }
            if (Math.abs(differencehours2) > horasmax) {
                aux1 = "No Cumple";
            } else {
                aux1 = "Cumple"
            }
            setIndicadoro4(aux1);
            //document.getElementById('indicaddoro4').value=aux1;
        }
    }
    const validardiascierre = (value) => {
        let fecha_puestaservicio = new Date(document.getElementById('fecha_puestaservicio').value);
        let fecha_reporte = new Date(document.getElementById('Fechareport').value);
        let fecha_puestaservicio_off = new Date(fecha_puestaservicio.getTime() + Math.abs(fecha_puestaservicio.getTimezoneOffset() * 60000));
        let fecha_reporte_off = new Date(fecha_reporte.getTime() + Math.abs(fecha_reporte.getTimezoneOffset() * 60000));
        let difference = fecha_reporte_off.getTime() - fecha_puestaservicio_off.getTime();
        let differencedays = difference / (1000 * 3600 * 24);
        setDiasconcierre(Math.abs(parseInt(differencedays)));
        let aux1 = "";
        if (Math.abs(parseInt(differencedays)) >= 1) {
            aux1 = "No Cumple"
        }
        else {
            aux1 = "Cumple"
        }
        setDndicadore17(aux1);
    }
    const validararray = (value) => {
        console.log("Valor =")
        console.log(value)
        setTiemposervicio("5:30");
    }
    const RestarFechas = (fecha1, fecha2) => {
        let fecha_inicial = new Date(fecha1);
        let fecha_final = new Date(fecha2);
        let fecha_inicial_off = new Date(fecha_inicial.getTime() + Math.abs(fecha_inicial.getTimezoneOffset() * 60000));
        let fecha_final_off = new Date(fecha_final.getTime() + Math.abs(fecha_final.getTimezoneOffset() * 60000));
        let difference = fecha_final_off.getTime() - fecha_inicial_off.getTime();

        //let differencedays=difference/(1000*3600*24);
        //let differencehours=(difference/(1000*3600))%24;
        let differencehours2 = (difference / (1000 * 3600));
        let differenceminutes = (difference / (1000 * 60)) % 60;
        let hora_resultado = Math.abs(parseInt(differencehours2));
        let minuto_resultado = Math.abs(parseInt(differenceminutes));
        if (hora_resultado.toString().length <= 1) {
            hora_resultado = "0" + hora_resultado;
        }

        if (minuto_resultado.toString().length <= 1) {
            minuto_resultado = "0" + minuto_resultado;
        }
        let resultado = hora_resultado + ":" + minuto_resultado;

        return resultado
    }
    const timeFormatter = v => {
        if (v && typeof v != 'undefined') {
            console.log(v)
            let timeformat = v.split(':');
            let horaformat = timeformat[0];
            let minutoformat = timeformat[1];
            if (parseInt(horaformat) > 24) {
                horaformat = 23;
            }
            if (horaformat.length <= 1) {
                horaformat = "0" + horaformat;
            }
            if (parseInt(minutoformat) > 60) {
                minutoformat = 59;
            }
            if (minutoformat.length <= 1) {
                minutoformat = "0" + minutoformat;
            }
            let tiempofinal = horaformat + ":" + minutoformat;
            return tiempofinal;
        }
        else {
            return v;
        }
    }

    const [heridostotal, setHeridostotal] = useState(0);
    const sumHeridos = (arrData) => {
        let total = 0;
        console.log("sumHeridos")
        console.log(typeof arrData)
        if(typeof arrData !== 'undefined'){
            arrData.map(heridos => {
                if(heridos.cantidadHeridos !== undefined){
                    total = total + heridos.cantidadHeridos
                }
            })
        }
        setHeridostotal(total);
    }

    const [victimasftotal, setVictimasFTotal] = useState(0);
    const sumVictimasF = (arrData) => {
        let total = 0;
        console.log("sumVictimasF")
        console.log(arrData)
        if(typeof arrData !== 'undefined'){
            arrData.map(victimasf => {
                if(victimasf.cantidadVictimasf !== undefined){
                    total = total + victimasf.cantidadVictimasf
                }
            })
        }
        setVictimasFTotal(total);
    }

    const [finalizo, setFinalizo] = React.useState(false);
    const [checked_finaliza, setCheckedFinaliza] = React.useState(false);
    const CheckFinaliza = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setCheckedFinaliza(value)
        console.log("CheckFinaliza")
        console.log(name)
        console.log(value)
    }

    const [ambulancia, setAmbulancia] = React.useState(false);
    const [checked_ambulancia, setCheckedAmbulancia] = React.useState(false);
    const CheckAmbulancia = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setCheckedAmbulancia(value)
        console.log("CheckAmbulancia")
        console.log(name)
        console.log(value)
    }

    const [grua, setGrua] = React.useState(false);
    const [checked_grua, setCheckedGrua] = React.useState(false);
    const CheckGrua = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setCheckedGrua(value)
        console.log("CheckGrua")
        console.log(name)
        console.log(value)
    }
    /*OPCIONES PARA TIPO DE IMÁGENES*/
    const [defaultTipoImagen, setDefaultTipoImagen] = useState([{tipoImagen: '/tipo_images/22'},{tipoImagen: '/tipo_images/23'},{tipoImagen: '/tipo_images/24'},{tipoImagen: '/tipo_images/25'}]);//{tipoImagen: '/tipo_images/25'}
    return (
        <Edit {...props}  title="dynatest.resources.disponibilidad_vias.title.editTitle" >
            <TabbedForm submitOnEnter={false} redirect="list">
                <FormTab label="dynatest.resources.disponibilidad_vias.tabs.tabsTitle.identificacion">
                    <TextInput label="resources.disponibilidad_vias.fields.consecutivo" fullWidth source="consecutivo" disabled formClassName={classes.grid_cont3s} validate={[required()]} />
                    <DateInput label="resources.disponibilidad_vias.fields.fecha" fullWidth source="fecha" disabled validate={[required()]} formClassName={classes.grid_cont3} />
                    <SelectInput label="resources.disponibilidad_vias.fields.tipoEvento" fullWidth source="tipoEvento" choices={[
                        { id: translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente'), name: 'dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente' },
                        { id: translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente'), name: 'dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente' },
                        { id: translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Cierre'), name: 'dynatest.resources.disponibilidad_vias.select.tipoEvento.Cierre' }
                    ]} validate={[required(), ValidarEvento]} formClassName={classes.grid_cont3} />

                    {estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Cierre') &&

                        <SelectInput label="resources.disponibilidad_vias.fields.tipoCierre" fullWidth source="tipoCierre" choices={[
                            { id: translate('dynatest.resources.disponibilidad_vias.select.tipoCierre.Calzada'), name: 'dynatest.resources.disponibilidad_vias.select.tipoCierre.Calzada' },
                            { id: translate('dynatest.resources.disponibilidad_vias.select.tipoCierre.Carril'), name: 'dynatest.resources.disponibilidad_vias.select.tipoCierre.Carril' }
                        ]} formClassName={classes.grid_cont3} />

                    }
                    <br />
                    <Typography variant="b" component="b" formClassName={classes.grid_cont12}>{translate('dynatest.resources.disponibilidad_vias.subTitle.localizacion')}</Typography>
                    <Divider fullWidth formClassName={classes.grid_cont12} />
                    <ReferenceInput label="resources.disponibilidad_vias.fields.unidadFuncional" source="unidadFuncional" id="ufuncional" disabled reference="unidad_funcionals" sort={{ field: 'codigo', order: 'asc' }} filterToQuery={searchText => ({ smartCode: searchText })} validate={[required(), ValidarUnidad, validarUnifun]} fullWidth formClassName={classes.grid_cont4s}>
                        <AutocompleteInput options={{ fullWidth: true, id: "ufuncional" }} optionText="codigo" />
                    </ReferenceInput>

                    {unidad &&
                        // <PRInput label="resources.disponibilidad_vias.fields.AbscisaInicial" source="abscisaininum" id="abin" fullWidth formClassName={classes.grid_cont4} validate={[required(), ValorMaximoAbsCreate, abValidationInicio, validarAbini, validarLongitudafect, validarsegmentosafect]} />
                        <PRInput label="resources.disponibilidad_vias.fields.AbscisaInicial" source="abscisaininum" id="abin" disabled fullWidth formClassName={classes.grid_cont4} validate={[validarLongitudafect, validarsegmentosafect]} />
                    }
                    {unidad &&
                        <NumberInput source="abscisaIni" id="abinihid" formClassName={classes.ocultar} defaultValue={abingini} />
                    }
                    {unidad &&
                        <NumberInput source="abscisaFin" id="abfinhid" formClassName={classes.ocultar} defaultValue={abingfin} />
                    }

                    {unidad &&
                        <PRInput label="resources.disponibilidad_vias.fields.AbscisaFinal" source="abscisafinnum" id="abfin" disabled fullWidth formClassName={classes.grid_cont4} />
                    }
                    {!unidad &&
                        <div formClassName={classes.grid_cont8}></div>
                    }

                    <SelectInput label="resources.disponibilidad_vias.fields.margen" fullWidth source="margen" choices={[
                        { id: translate('dynatest.resources.disponibilidad_vias.select.margen.Izquierda'), name: 'dynatest.resources.disponibilidad_vias.select.margen.Izquierda' },
                        { id: translate('dynatest.resources.disponibilidad_vias.select.margen.Derecha'), name: 'dynatest.resources.disponibilidad_vias.select.margen.Derecha' }
                    ]} validate={[required()]} formClassName={classes.grid_cont6s} />

                    <TextInput label="resources.disponibilidad_vias.fields.lugar" source="lugar" fullWidth formClassName={classes.grid_cont6} validate={[required(), validarMaxl]} maxLength={40} />

                    <TextInput label="resources.disponibilidad_vias.fields.longitudAfect" source="longitudAfect" fullWidth formClassName={classes.grid_cont3s} disabled validate={[required()]} defaultValue={longitudafect}  format={v => { return longitudafect }} />

                    <FormDataConsumer formClassName={classes.ocultar}>
                        {({ formData, dispatch, ...rest }) => {
                            if (typeof formData != 'undefined' && formData.longitudAfect && longitudafect) {
                                formData.longitudAfect = longitudafect
                            }
                        }}
                    </FormDataConsumer>
                    {unidad &&
                        <TextInput label="resources.disponibilidad_vias.fields.segmentosAfect" disabled defaultValue={segmentosafectados} format={v => { return segmentosafectados }} source="segmentosAfect" fullWidth  formClassName={classes.grid_cont4} />
                    }
                    {unidad &&
                        <TextInput label="resources.disponibilidad_vias.fields.segmentosAfectId" style={{ display: 'none' }} disabled defaultValue={segmentosafectadosid} format={v => { return segmentosafectadosid }} source="segmentosAfectId" fullWidth formClassName={classes.ocultar}/>
                    }
                    
                    {unidad &&
                        <FormDataConsumer formClassName={classes.ocultar}>
                            {({ formData, dispatch, ...rest }) => {
                                if (typeof formData != 'undefined') {
                                    formData.segmentosAfect = segmentosafectados
                                    formData.segmentosAfectId = segmentosafectadosid
                                }
                            }}
                        </FormDataConsumer>
                    }
                    <br/>

                    {
                        <Query type="GET_ONE" resource="proyectos" payload={{ id: proyectos }} formClassName={classes.ocultar}>
                            {({ data, loading, error }) => {
                                if (data) {
                                    return (
                                        // 
                                        <Grid item xs={3} className={classes.ocultar}>
                                            <TextInput id="lngMin" style={{ display: 'none' }} defaultValue={data.longitud1} source="longituds" fullWidth />
                                            <TextInput id="latMin" style={{ display: 'none' }} defaultValue={data.latitud1} source="latituds" fullWidth />
                                            <TextInput id="lngMax" style={{ display: 'none' }} defaultValue={data.longitud2} source="longituds2" fullWidth />
                                            <TextInput id="latMax" style={{ display: 'none' }} defaultValue={data.latitud2} source="latituds2" fullWidth />
                                        </Grid>
                                    )
                                }
                                return <div></div>;
                            }}
                        </Query>
                    }
                    <NumberInput label="resources.disponibilidad_vias.fields.latitud" source="latitud" fullWidth formClassName={classes.grid_cont4} validate={[required(), latMininima]} />
                    <NumberInput label="resources.disponibilidad_vias.fields.longitud" source="longitud" fullWidth formClassName={classes.grid_cont4} validate={[required(), lngMininima]} />


                </FormTab>
                <FormTab label="dynatest.resources.disponibilidad_vias.tabs.tabsTitle.detalles">
                    <DateInput label="resources.disponibilidad_vias.fields.fechaReporte" source="fechaReporte" disabled fullWidth id="Fechareport" formClassName={classes.grid_cont4s} validate={[required(),isFechaInvalidaDayEquals]} />
                    <FInput label="resources.disponibilidad_vias.fields.horaReporte" source="horareport" disabled id="Horardeporte" fullWidth formClassName={classes.grid_cont4} validate={[required(), HoraSenal]} format={timeFormatter} />
                    {/* <FInput label="resources.disponibilidad_vias.fields.horaReporte" source="horareport" id="Horardeporte" fullWidth formClassName={classes.grid_cont4} validate={[HoraSenal]} format={timeFormatter} /> */}
                    <br/>
                    <DateInput label="resources.disponibilidad_vias.fields.fechaSenal" source="fechaSenal" id="fecha_senal" fullWidth formClassName={classes.grid_cont4s} validate={[required()]} disabled />
                    <FInput label="resources.disponibilidad_vias.fields.horaSenal" source="horasenalizacion" id="hora_senal" fullWidth formClassName={classes.grid_cont4} validate={[required()]} disabled />
                    <FInput label="resources.disponibilidad_vias.fields.tiempSenal" source="tiempSenal" id="tiempsenal" fullWidth formClassName={classes.grid_cont4} validate={[required()]} disabled format={v => { return horasen; }} />
                    <br/>
                    {ambulancia && 
                        <Typography variant="b" component="b" formClassName={classes.grid_cont12} fullWidth>{translate('dynatest.resources.disponibilidad_vias.subTitle.fechaAmbulanciaRegistrada')}</Typography>
                    }
                    <BooleanInput style={(ambulancia)?{ display: 'none' }: {}} onChange={CheckAmbulancia} formClassName={classes.grid_cont4_f} label="resources.disponibilidad_vias.fields.ambulancia" source="ambulancia" fullWidth />
                    <FormDataConsumer formClassName={classes.ocultar}>
                        {({ formData, dispatch, ...rest }) => {
                            if (typeof formData != 'undefined') {
                                if(formData.ambulancia == true && checked_ambulancia == false){
                                    setAmbulancia(true)
                                    let check_data = {'target': {"type": 'checkbox', "name": 'ambulancia', 'checked': formData.ambulancia}}
                                    CheckAmbulancia(check_data)
                                }
                                
                            }
                        }}
                    </FormDataConsumer>
                    <br/>
                    {ambulancia == false && checked_ambulancia &&
                        <DateInput options={ (ambulancia)?{ disabled: true }:{} } label="resources.disponibilidad_vias.fields.fechaAmbulancia" source="fechaAmbulancia" id="fecha_ambulancia" disabled defaultValue={today} format={v => { return today }} formClassName={classes.grid_cont6s} fullWidth />
                    }
                    {ambulancia == false && checked_ambulancia && //estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.Derrumbe') &&
                            <FormDataConsumer formClassName={classes.ocultar}>
                                {({ formData, dispatch, ...rest }) => {
                                    if (typeof formData != 'undefined') {
                                        formData.fechaAmbulancia = today
                                    }
                                }}
                            </FormDataConsumer>
                        }
                    {ambulancia == false && checked_ambulancia &&
                        <FInput options={ (ambulancia)?{ disabled: true }:{} } label="resources.disponibilidad_vias.fields.tiempoRespuestaAmbulancia" source="horaAmbulancia" id="hora_ambulancia" disabled format={v => { return hora }} formClassName={classes.grid_cont6} fullWidth />
                    }
                    {ambulancia == false && checked_ambulancia && //estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.Derrumbe') &&
                        <FormDataConsumer formClassName={classes.ocultar}>
                            {({ formData, dispatch, ...rest }) => {
                                if (typeof formData != 'undefined') {
                                    formData.horaAmbulancia = hora
                                }
                            }}
                        </FormDataConsumer>
                    }
                    <br/>
                    {grua && 
                        <Typography variant="b" component="b" formClassName={classes.grid_cont12} fullWidth>{translate('dynatest.resources.disponibilidad_vias.subTitle.fechaGruaRegistrada')}</Typography>
                    }
                    <BooleanInput style={(grua)?{ display: 'none' }: {}} onChange={CheckGrua} formClassName={classes.grid_cont4_f} label="resources.disponibilidad_vias.fields.grua" source="grua" fullWidth />
                    <FormDataConsumer formClassName={classes.ocultar}>
                        {({ formData, dispatch, ...rest }) => {
                            if (typeof formData != 'undefined') {
                                if(formData.grua == true && checked_grua == false){
                                    setGrua(true)
                                    let check_data = {'target': {"type": 'checkbox', "name": 'grua', 'checked': formData.grua}}
                                    CheckGrua(check_data)
                                }
                                
                            }
                        }}
                    </FormDataConsumer>
                    <br/>
                    {grua == false && checked_grua &&
                        <DateInput options={ (grua)?{ disabled: true }:{} } label="resources.disponibilidad_vias.fields.fechaGrua" source="fechaGrua" id="fecha_grua" disabled defaultValue={today} format={v => { return today }} formClassName={classes.grid_cont6s} fullWidth />
                    }
                    {grua == false && checked_grua && //estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.Derrumbe') &&
                            <FormDataConsumer formClassName={classes.ocultar}>
                                {({ formData, dispatch, ...rest }) => {
                                    if (typeof formData != 'undefined') {
                                        formData.fechaGrua = today
                                    }
                                }}
                            </FormDataConsumer>
                        }
                    {grua == false && checked_grua &&
                        <FInput options={ (grua)?{ disabled: true }:{} } label="resources.disponibilidad_vias.fields.tiempoRespuestaGrua" source="horaGrua" id="hora_grua" disabled format={v => { return hora }} formClassName={classes.grid_cont6} fullWidth />
                    }
                    {grua == false && checked_grua && //estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.Derrumbe') &&
                        <FormDataConsumer formClassName={classes.ocultar}>
                            {({ formData, dispatch, ...rest }) => {
                                if (typeof formData != 'undefined') {
                                    formData.horaGrua = hora
                                }
                            }}
                        </FormDataConsumer>
                    }
                    <br/>
                    <SelectInput label="resources.disponibilidad_vias.fields.fuenteRep" fullWidth source="fuenteRep" choices={[
                        { id: translate('dynatest.resources.disponibilidad_vias.select.fuenteRep.Llamada'), name: 'dynatest.resources.disponibilidad_vias.select.fuenteRep.Llamada' },
                        { id: translate('dynatest.resources.disponibilidad_vias.select.fuenteRep.Email'), name: 'dynatest.resources.disponibilidad_vias.select.fuenteRep.Email' },
                        { id: translate('dynatest.resources.disponibilidad_vias.select.fuenteRep.Recorredor'), name: 'dynatest.resources.disponibilidad_vias.select.fuenteRep.Recorredor' }
                    ]} formClassName={classes.grid_cont4s} validate={[required()]} />
                    <SelectInput label="resources.disponibilidad_vias.fields.causa" fullWidth source="causa" choices={[
                        { id: translate('dynatest.resources.disponibilidad_vias.select.causa.Accidente'), name: 'dynatest.resources.disponibilidad_vias.select.causa.Accidente' },
                        { id: translate('dynatest.resources.disponibilidad_vias.select.causa.Derrumbe'), name: 'dynatest.resources.disponibilidad_vias.select.causa.Derrumbe' },
                        { id: translate('dynatest.resources.disponibilidad_vias.select.causa.TrabajosVia'), name: 'dynatest.resources.disponibilidad_vias.select.causa.TrabajosVia' },
                        { id: translate('dynatest.resources.disponibilidad_vias.select.causa.Manifestacion'), name: 'dynatest.resources.disponibilidad_vias.select.causa.Manifestacion' },
                        { id: translate('dynatest.resources.disponibilidad_vias.select.causa.OrdenPublico'), name: 'dynatest.resources.disponibilidad_vias.select.causa.OrdenPublico' },
                        { id: translate('dynatest.resources.disponibilidad_vias.select.causa.Otro'), name: 'dynatest.resources.disponibilidad_vias.select.causa.Otro' }
                    ]} formClassName={classes.grid_cont4} validate={[required(), validarCausa]} />
                    <SelectInput label="resources.disponibilidad_vias.fields.elementoAfect" fullWidth source="elementoAfect" choices={[
                        { id: translate('dynatest.resources.disponibilidad_vias.select.elementoAfect.Calzada'), name: 'dynatest.resources.disponibilidad_vias.select.elementoAfect.Calzada' },
                        { id: translate('dynatest.resources.disponibilidad_vias.select.elementoAfect.Puente'), name: 'dynatest.resources.disponibilidad_vias.select.elementoAfect.Puente' },
                        { id: translate('dynatest.resources.disponibilidad_vias.select.elementoAfect.Tunel'), name: 'dynatest.resources.disponibilidad_vias.select.elementoAfect.Tunel' },
                        { id: translate('dynatest.resources.disponibilidad_vias.select.elementoAfect.Bermas'), name: 'dynatest.resources.disponibilidad_vias.select.elementoAfect.Bermas' }
                    ]} formClassName={classes.grid_cont4} validate={[required(), validarElemento]} />
                    <TextInput label="resources.disponibilidad_vias.fields.observaciones" source="observaciones" fullWidth formClassName={classes.grid_cont12} validate={[required(), maxLength(200)]} multiline />
                    <Typography variant="b" component="b" fullWidth formClassName={classes.grid_cont12}>{translate('dynatest.resources.disponibilidad_vias.subTitle.AsociarServicios')}</Typography>
                    <Divider fullWidth formClassName={classes.grid_cont12} />
                    <ArrayInput label="" source="servicios" fullWidth>
                        <SimpleFormIterator>
                            <SelectInput label="resources.disponibilidad_vias.fields.serviciosDirectorio" fullWidth source="serviciosDirectorio" choices={[
                                { id: translate('dynatest.resources.disponibilidad_vias.select.serviciosDirectorio.Emergencias'), name: 'dynatest.resources.disponibilidad_vias.select.serviciosDirectorio.Emergencias' },
                                { id: translate('dynatest.resources.disponibilidad_vias.select.serviciosDirectorio.Proveedores'), name: 'dynatest.resources.disponibilidad_vias.select.serviciosDirectorio.Proveedores' },
                                { id: translate('dynatest.resources.disponibilidad_vias.select.serviciosDirectorio.ServiciosPublicos'), name: 'dynatest.resources.disponibilidad_vias.select.serviciosDirectorio.ServiciosPublicos' }
                            ]} formClassName={classes.grid_cont12} validate={[required()]} />
                            <NumberInput source="tipoServicio" className={classes.ocultar} formClassName={classes.ocultar} />
                            <FormDataConsumer className={classes.ocultar}>
                                {({ formData, scopedFormData, dispatch, ...rest }) => {
                                    if (typeof scopedFormData != 'undefined') {
                                        if (scopedFormData.serviciosDirectorio === translate('dynatest.resources.disponibilidad_vias.select.serviciosDirectorio.Emergencias')) {
                                            scopedFormData.tipoServicio = 1;
                                        }
                                        else if (scopedFormData.serviciosDirectorio === translate('dynatest.resources.disponibilidad_vias.select.serviciosDirectorio.Proveedores')) {
                                            scopedFormData.tipoServicio = 2;
                                        }
                                        else if (scopedFormData.serviciosDirectorio === translate('dynatest.resources.disponibilidad_vias.select.serviciosDirectorio.ServiciosPublicos')) {
                                            scopedFormData.tipoServicio = 3;
                                        }
                                    }
                                }}
                            </FormDataConsumer>
                            {/* <FormDataConsumer>
                                    {
                                            ({ formData, scopedFormData, getSource, ...rest }) => ( scopedFormData != null && scopedFormData.serviciosDirectorio === translate('dynatest.resources.disponibilidad_vias.select.serviciosDirectorio.Emergencias') &&
                                            <NumberInput source={getSource("tipoServicio")} defaultValue={1} className={classes.ocultar}/>
                                            )
                                    }                                        
                                    </FormDataConsumer>
                                    <FormDataConsumer>
                                    {
                                            ({ formData, scopedFormData, getSource, ...rest }) => ( scopedFormData != null && scopedFormData.serviciosDirectorio === translate('dynatest.resources.disponibilidad_vias.select.serviciosDirectorio.Proveedores') &&
                                            <NumberInput source={getSource("tipoServicio")} defaultValue={2} className={classes.ocultar}/>
                                            )
                                    }                                        
                                    </FormDataConsumer>
                                    <FormDataConsumer>
                                    {
                                            ({ formData, scopedFormData, getSource, ...rest }) => (scopedFormData != null && scopedFormData.serviciosDirectorio === translate('dynatest.resources.disponibilidad_vias.select.serviciosDirectorio.ServiciosPublicos') &&
                                            <NumberInput source={getSource("tipoServicio")} defaultValue={3} className={classes.ocultar}/>
                                            )
                                    }                                        
                                    </FormDataConsumer> */}
                            <FormDataConsumer>
                                {
                                    ({ formData, scopedFormData, getSource, ...rest }) => (scopedFormData != null && scopedFormData.serviciosDirectorio === translate('dynatest.resources.disponibilidad_vias.select.serviciosDirectorio.Emergencias') &&
                                        <AsociarServiciosEmergencia getSource={getSource} />
                                    )
                                }
                            </FormDataConsumer>
                            <FormDataConsumer>
                                {
                                    ({ formData, scopedFormData, getSource, ...rest }) => (scopedFormData != null && scopedFormData.serviciosDirectorio === translate('dynatest.resources.disponibilidad_vias.select.serviciosDirectorio.Proveedores') &&
                                        <AsociarServicioProveedor getSource={getSource} />
                                    )
                                }
                            </FormDataConsumer>
                            <FormDataConsumer>
                                {
                                    ({ formData, scopedFormData, getSource, ...rest }) => (scopedFormData != null && scopedFormData.serviciosDirectorio === translate('dynatest.resources.disponibilidad_vias.select.serviciosDirectorio.ServiciosPublicos') &&
                                        <AsociarServicio getSource={getSource} />
                                    )
                                }
                            </FormDataConsumer>
                        </SimpleFormIterator>
                    </ArrayInput>
                </FormTab>
                <FormTab label="dynatest.resources.disponibilidad_vias.tabs.tabsTitle.caracteristicas">
                    {//(estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.TrabajosVia')) &&
                        <NumberInput label="resources.disponibilidad_vias.fields.longitudCola" source="longitudCola" fullWidth validate={(estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.TrabajosVia')) ? [required(), validarLongcl] : [validarLongcl]} formClassName={classes.grid_cont6s} />
                    }
                    {//(estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.TrabajosVia')) &&
                        <TextInput label="resources.disponibilidad_vias.fields.indicadorO2" source="indicadorO2" fullWidth validate={(estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.TrabajosVia')) ? [required()] : []} disabled format={v => { return longcl }} formClassName={(estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.TrabajosVia')) ? classes.grid_cont6 : classes.ocultar} />
                    }
                    {//(estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.TrabajosVia')) &&
                        <FormDataConsumer formClassName={classes.ocultar}>
                            {({ formData, dispatch, ...rest }) => {
                                if (typeof formData != 'undefined') {
                                    formData.indicadorO2 = longcl
                                }
                            }}
                        </FormDataConsumer>
                    }
                    {//(estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.TrabajosVia')) &&
                        <TextInput label="resources.disponibilidad_vias.fields.observacionesTrabajos" source="observacionesTrabajos" fullWidth formClassName={classes.grid_cont12} validate={(estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.TrabajosVia')) ? [required(), maxLength(200)] : [maxLength(200)]} multiline />
                    }
                    {estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.Derrumbe') &&
                        <NumberInput label="resources.disponibilidad_vias.fields.volumenDerrumbe" source="volumenDerrumbe" fullWidth validate={[required(), validarVolder, valorTiempodespeje]} formClassName={classes.grid_cont3s} />
                    }
                    {estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.Derrumbe') &&
                        <TextInput label="resources.disponibilidad_vias.fields.ElementoAfectado" fullWidth validate={[required()]} disabled defaultValue={elementoaf} formClassName={classes.grid_cont3} />
                    }
                    {/*estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.Derrumbe') && volumenderr > 2000 &&
                        <SelectInput label="Condiciones Especiales" fullWidth validate={[required(), validarCondicionesesp]} source="condicionesEsp" choices={[
                            { id: 'Disposición de Nuevos Dispositivos de Contención', name: 'Disposición de Nuevos Dispositivos de Contención' },
                            { id: 'Mayores Dificultades', name: 'Mayores Dificultades' }
                        ]} formClassName={classes.grid_cont3} style={{ display: 'none' }}/>
                    */}
                    {/*estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.Derrumbe') &&
                        <TextInput label="Tiempo de Despeje Máximo (h)" id="tiempodespejemax" fullWidth validate={[required()]} source="tiempoDespeje" disabled format={v => { return tiempodespeje; }} formClassName={classes.grid_cont3} style={{ display: 'none' }}/>
                */}
                    {estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.Derrumbe') &&
                        <FormDataConsumer formClassName={classes.ocultar}>
                            {({ formData, dispatch, ...rest }) => {
                                if (typeof formData != 'undefined') {
                                    formData.tiempoDespeje = tiempodespeje
                                }
                            }}
                        </FormDataConsumer>
                    }
                    {estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.Derrumbe') &&
                        <TextInput label="resources.disponibilidad_vias.fields.observacionesDerrumbe" source="observacionesDerrumbe" fullWidth formClassName={classes.grid_cont12} validate={[required(), maxLength(200)]} multiline />
                    }
                    {estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.Otro') &&
                        <TextInput label="resources.disponibilidad_vias.fields.tipoIncidenteOtro" fullWidth validate={[required(), validarTipoincidente, maxLength(40)]} maxLength={40} source="tipoIncidenteOtro" formClassName={classes.grid_cont6s} />
                    }
                    {estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.Otro') &&
                        <TextInput label="resources.disponibilidad_vias.fields.tipoVehiculoOtro" fullWidth validate={[required(), validarTipovehiculootro, maxLength(20)]} maxLength={20} source="tipoVehiculoOtro" formClassName={classes.grid_cont6} />
                    }
                    {estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.Otro') &&
                        <TextInput label="resources.disponibilidad_vias.fields.observacionesOtro" source="observacionesOtro" fullWidth formClassName={classes.grid_cont12} validate={[required(), maxLength(200)]} multiline />
                    }

                    {estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') &&
                        <SelectInput label="resources.disponibilidad_vias.fields.tipoAccidente" fullWidth source="tipoAccidente" choices={[
                            { id: translate('dynatest.resources.disponibilidad_vias.select.tipoAccidente.Simple'), name: 'dynatest.resources.disponibilidad_vias.select.tipoAccidente.Simple' },
                            { id: translate('dynatest.resources.disponibilidad_vias.select.tipoAccidente.Heridos'), name: 'dynatest.resources.disponibilidad_vias.select.tipoAccidente.Heridos' },
                            { id: translate('dynatest.resources.disponibilidad_vias.select.tipoAccidente.Muertos'), name: 'dynatest.resources.disponibilidad_vias.select.tipoAccidente.Muertos' }
                        ]} validate={[required()]} formClassName={classes.grid_cont12} />
                    }
                    {estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') &&
                        <Typography variant="b" component="b" formClassName={classes.grid_cont12} fullWidth>{translate('dynatest.resources.disponibilidad_vias.subTitle.JuicioPolicia')}</Typography>
                    }
                    {estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') &&
                        <Divider fullWidth formClassName={classes.grid_cont12} />
                    }
                    {estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') &&
                        <ReferenceInput label="resources.disponibilidad_vias.fields.CodigoCausa" 
                        source="disponibilidadViaCausaProbable" 
                        reference="disponibilidad_via_causa_probables" 
                        validate={[required()]} 
                        fullWidth 
                        perPage={900}
                        formClassName={classes.grid_cont4s}>
                            <AutocompleteInput optionText="codigo"  options={{ fullWidth: true}}/>
                        </ReferenceInput>
                    }
                    {/* disabled defaultValue={nombreCausa} */}
                    {estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') &&
                        <ReferenceInput perPage={900} label="resources.disponibilidad_vias.fields.CausaProbable" source="disponibilidadViaCausaProbable" reference="disponibilidad_via_causa_probables" sort={{ field: 'nombre', order: 'asc' }} validate={[required()]} fullWidth formClassName={classes.grid_cont4}>
                            <AutocompleteInput optionText="nombre"  options={{ fullWidth: true}}/>
                        </ReferenceInput>
                    }
                    {estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') &&
                        <TextInput label="resources.disponibilidad_vias.fields.clasePolicia" source="clasePolicia" validate={[required(), validarClasePolicia]} fullWidth formClassName={classes.grid_cont4} />
                    }
                    {estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') &&
                        <hr fullWidth size="2px" color="gray"/>
                    }
                    {estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') &&
                        <ArrayInput label={<Typography><b style={{marginTop: "0px",marginBottom: "0px"}}>{translate('dynatest.resources.disponibilidad_vias.subTitle.Heridos')}</b></Typography>} source="heridos" fullWidth validate={array => sumHeridos(array)}>
                            <SimpleFormIterator>
                                <NumberInput label="resources.disponibilidad_vias.fields.cantidadHeridos" source="cantidadHeridos" validate={[required(), validarcantidadheridos]} formClassName={classes.grid_cont3} fullWidth />
                                <SelectInput label="resources.disponibilidad_vias.fields.tipoHeridos" fullWidth source="tipoHeridos" choices={[
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.tipoHeridos.Peaton'), name: 'dynatest.resources.disponibilidad_vias.select.tipoHeridos.Peaton' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.tipoHeridos.Ciclista'), name: 'dynatest.resources.disponibilidad_vias.select.tipoHeridos.Ciclista' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.tipoHeridos.Motociclista'), name: 'dynatest.resources.disponibilidad_vias.select.tipoHeridos.Motociclista' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.tipoHeridos.Conductor'), name: 'dynatest.resources.disponibilidad_vias.select.tipoHeridos.Conductor' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.tipoHeridos.Pasajero'), name: 'dynatest.resources.disponibilidad_vias.select.tipoHeridos.Pasajero' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.tipoHeridos.Otro'), name: 'dynatest.resources.disponibilidad_vias.select.tipoHeridos.Otro' }
                                ]} validate={[required()]} formClassName={classes.grid_cont3} />
                                <SelectInput label="resources.disponibilidad_vias.fields.generoHeridos" fullWidth source="generoHeridos" choices={[
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.generoHeridos.Hombre'), name: 'dynatest.resources.disponibilidad_vias.select.generoHeridos.Hombre' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.generoHeridos.Mujer'), name: 'dynatest.resources.disponibilidad_vias.select.generoHeridos.Mujer' }
                                ]} validate={[required()]} formClassName={classes.grid_cont3} />
                                <NumberInput label="resources.disponibilidad_vias.fields.edadHeridos" source="edadHeridos" validate={[required(), validaredadheridos]} formClassName={classes.grid_cont2} fullWidth />
                            </SimpleFormIterator>
                        </ArrayInput>
                    }
                    {estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') &&
                        <DisabledInput 
                            label="resources.disponibilidad_vias.fields.totalHeridos"
                            source="totalHeridos"
                            formClassName={classes.first_field} 
                            className="align-number"
                            defaultValue ={heridostotal}
                        />
                    }
                    {estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') &&
                        <hr fullWidth size="2px" color="gray"/>
                    }
                    {estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') &&
                        <ArrayInput label={<Typography><b style={{marginTop: "0px",marginBottom: "0px"}}>{translate('dynatest.resources.disponibilidad_vias.subTitle.VictimasFatales')}</b></Typography>} source="victimas" fullWidth validate={array => sumVictimasF(array)}>
                            <SimpleFormIterator>
                                <NumberInput label="resources.disponibilidad_vias.fields.cantidadVictimasf" source="cantidadVictimasf" validate={[required(), validarcantidadheridos]} formClassName={classes.grid_cont3} fullWidth />
                                <SelectInput label="resources.disponibilidad_vias.fields.tipoVictimasf" fullWidth source="tipoVictimasf" choices={[
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.tipoHeridos.Peaton'), name: 'dynatest.resources.disponibilidad_vias.select.tipoHeridos.Peaton' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.tipoHeridos.Ciclista'), name: 'dynatest.resources.disponibilidad_vias.select.tipoHeridos.Ciclista' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.tipoHeridos.Motociclista'), name: 'dynatest.resources.disponibilidad_vias.select.tipoHeridos.Motociclista' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.tipoHeridos.Conductor'), name: 'dynatest.resources.disponibilidad_vias.select.tipoHeridos.Conductor' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.tipoHeridos.Pasajero'), name: 'dynatest.resources.disponibilidad_vias.select.tipoHeridos.Pasajero' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.tipoHeridos.Otro'), name: 'dynatest.resources.disponibilidad_vias.select.tipoHeridos.Otro' }
                                ]} validate={[required()]} formClassName={classes.grid_cont3} />
                                <SelectInput label="resources.disponibilidad_vias.fields.generoVictimasf" fullWidth source="generoVictimasf" choices={[
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.generoHeridos.Hombre'), name: 'dynatest.resources.disponibilidad_vias.select.generoHeridos.Hombre' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.generoHeridos.Mujer'), name: 'dynatest.resources.disponibilidad_vias.select.generoHeridos.Mujer' }
                                ]} validate={[required()]} formClassName={classes.grid_cont3} />
                                <NumberInput label="resources.disponibilidad_vias.fields.edadVictimasf" source="edadVictimasf" validate={[required(), validaredadheridos]} formClassName={classes.grid_cont2} fullWidth />
                            </SimpleFormIterator>
                        </ArrayInput>
                    }
                    {estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') &&
                        <DisabledInput 
                            label="resources.disponibilidad_vias.fields.totalVictimasFatales"
                            source="totalVictimasFatales"
                            formClassName={classes.first_field} 
                            className="align-number"
                            defaultValue ={victimasftotal}
                        />
                    }
                    {estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') &&
                        <hr fullWidth size="2px" color="gray"/>
                    }
                    {estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') &&
                        <ArrayInput label={<Typography><b style={{marginTop: "0px",marginBottom: "0px"}}>Vehículos Implicados</b></Typography>} source="vehiculos" fullWidth >
                            <SimpleFormIterator>
                                <NumberInput label="resources.disponibilidad_vias.fields.cantidadVehiculos" source="cantidadVehiculos" fullWidth validate={[required(), validarcantidadheridos]} formClassName={classes.grid_cont3} />
                                <SelectInput label="resources.disponibilidad_vias.fields.categoriaVehiculos" fullWidth source="categoriaVehiculos" choices={[
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Peaton'), name: 'dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Peaton' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Bicicleta'), name: 'dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Bicicleta' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Motocicleta'), name: 'dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Motocicleta' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Automovil'), name: 'dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Automovil' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Taxi'), name: 'dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Taxi' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Bus'), name: 'dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Bus' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Camion'), name: 'dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Camion' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.MaquinaConstr'), name: 'dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.MaquinaConstr' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.MaquinariaAgricola'), name: 'dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.MaquinariaAgricola' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.ObjetoFijo'), name: 'dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.ObjetoFijo' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.ObjetoMovil'), name: 'dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.ObjetoMovil' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Otros'), name: 'dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Otros' }
                                ]} validate={[required()]} formClassName={classes.grid_cont3} />
                                <SelectInput label="resources.disponibilidad_vias.fields.tipoVehiculos" fullWidth source="tipoVehiculos" choices={[
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Peaton'), name: 'dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Peaton' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Bicicleta'), name: 'dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Bicicleta' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Motocicleta'), name: 'dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Motocicleta' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Automovil'), name: 'dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Automovil' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Taxi'), name: 'dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Taxi' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Bus'), name: 'dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Bus' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Camion'), name: 'dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Camion' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.MaquinaConstr'), name: 'dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.MaquinaConstr' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.MaquinariaAgricola'), name: 'dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.MaquinariaAgricola' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.ObjetoFijo'), name: 'dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.ObjetoFijo' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.ObjetoMovil'), name: 'dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.ObjetoMovil' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Otros'), name: 'dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Otros' }
                                ]} validate={[required()]} formClassName={classes.grid_cont3} />
                                <TextInput label="resources.disponibilidad_vias.fields.marcaVehiculos" source="marcaVehiculos" fullWidth validate={[required(), validargeneroheridos]} formClassName={classes.grid_cont2} />
                                <TextInput label="resources.disponibilidad_vias.fields.lineaVehiculos" source="lineaVehiculos" fullWidth validate={[required(), validargeneroheridos]} formClassName={classes.grid_cont3} />
                                <TextInput label="resources.disponibilidad_vias.fields.modeloVehiculos" source="modeloVehiculos" fullWidth validate={[required(), validargeneroheridos]} formClassName={classes.grid_cont3} />
                                <TextInput label="resources.disponibilidad_vias.fields.placaVehiculos" source="placaVehiculos" fullWidth validate={[required(), validargeneroheridos]} formClassName={classes.grid_cont3} />
                                <TextInput label="resources.disponibilidad_vias.fields.colorVehiculos" source="colorVehiculos" fullWidth validate={[required(), validargeneroheridos]} formClassName={classes.grid_cont2} />
                            </SimpleFormIterator>
                        </ArrayInput>
                    }
                    {estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') &&
                        <TextInput label="resources.disponibilidad_vias.fields.observacionesAccidente" source="observacionesAccidente" fullWidth formClassName={classes.grid_cont12} validate={[required(), maxLength(200)]} multiline />
                    }

                </FormTab>
                {/*((estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.Derrumbe')) || (estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.Otro')) || (estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente')) || (estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Cierre'))) &&*/
                    <FormTab label="dynatest.resources.disponibilidad_vias.tabs.tabsTitle.finalizar">
                        {finalizo && 
                            <Typography variant="b" component="b" formClassName={classes.grid_cont12} fullWidth>{translate('dynatest.resources.disponibilidad_vias.subTitle.disponibilidadVia')}</Typography>
                        }
                        <BooleanInput style={(finalizo)?{ display: 'none' }: {}} onChange={CheckFinaliza} formClassName={classes.grid_cont4_f} label="dynatest.resources.disponibilidad_vias.subTitle.finalizar" source="finalizar" fullWidth />
                        <FormDataConsumer formClassName={classes.ocultar}>
                            {({ formData, dispatch, ...rest }) => {
                                if (typeof formData != 'undefined') {
                                    if(formData.finalizar == true && checked_finaliza == false){
                                        setFinalizo(true)
                                        let check_data = {'target': {"type": 'checkbox', "name": 'finalizar', 'checked': formData.finalizar}}
                                        CheckFinaliza(check_data)
                                    }
                                    
                                }
                            }}
                        </FormDataConsumer>
                        
                        <br/>
                        
                        {finalizo == false && checked_finaliza && //estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.Derrumbe') &&
                            <DateInput options={ (finalizo)?{ disabled: true }:{} } label="resources.disponibilidad_vias.fields.fechaDespeje" source="fechaDespeje" id="fecha_despeje" disabled format={v => { return today }} formClassName={classes.grid_cont6s} fullWidth />
                        } 
                        {finalizo == false && checked_finaliza && //estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.Derrumbe') &&
                            <FormDataConsumer formClassName={classes.ocultar}>
                                {({ formData, dispatch, ...rest }) => {
                                    if (typeof formData != 'undefined') {
                                        formData.fechaDespeje = today
                                    }
                                }}
                            </FormDataConsumer>
                        }
                        {finalizo == false && checked_finaliza && //estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.Derrumbe') &&
                            <FInput options={ (finalizo)?{ disabled: true }:{} } label="resources.disponibilidad_vias.fields.horaDespeje" source="horaDespeje" id="hora_despeje" disabled format={v => { return hora }} formClassName={classes.grid_cont6} fullWidth />
                        }
                        {finalizo == false && checked_finaliza && //estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.Derrumbe') &&
                            <FormDataConsumer formClassName={classes.ocultar}>
                                {({ formData, dispatch, ...rest }) => {
                                    if (typeof formData != 'undefined') {
                                        formData.horaDespeje = hora
                                    }
                                }}
                            </FormDataConsumer>
                        }
                        {finalizo == false && checked_finaliza && //estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.Derrumbe') &&
                            <NumberInput options={ (finalizo)?{ disabled: true }:{} } label="resources.disponibilidad_vias.fields.diasDespeje" id="dias_despejef" disabled source="diasDespeje" format={v => { return diasdespeje }} formClassName={ (estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.Derrumbe'))? classes.ocultar : classes.grid_cont4s} fullWidth />
                        }
                        {finalizo == false && checked_finaliza && //estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.Derrumbe') &&
                            <FormDataConsumer formClassName={classes.ocultar}>
                                {({ formData, dispatch, ...rest }) => {
                                    if (typeof formData != 'undefined') {
                                        formData.diasDespeje = diasdespeje
                                    }
                                }}
                            </FormDataConsumer>
                        }
                        {finalizo == false && checked_finaliza && //estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.Derrumbe') &&
                            <FInput options={ (finalizo)?{ disabled: true }:{} } label="resources.disponibilidad_vias.fields.tiempoDespejeFin" id="tiempo_deespejef" disabled source="tiempoDespejeFin" format={v => { return horadespeje }} formClassName={ (estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.Derrumbe'))? classes.ocultar : classes.grid_cont4} fullWidth />
                        }
                        {finalizo == false && checked_finaliza && //estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.Derrumbe') &&
                            <FormDataConsumer formClassName={classes.ocultar}>
                                {({ formData, dispatch, ...rest }) => {
                                    if (typeof formData != 'undefined') {
                                        formData.tiempoDespejeFin = horadespeje
                                    }
                                }}
                            </FormDataConsumer>
                        }
                        {/*finalizo == false && checked_finaliza && //estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.Derrumbe') &&
                            <TextInput options={ (finalizo)?{ disabled: true }:{} } label="Inicador O4" disabled id="indicaddoro4" source="indicadorO4" format={v => { return indicadoro4 }} formClassName={ (estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.Derrumbe'))? classes.ocultar : classes.grid_cont4} fullWidth />
                        */}
                        {/*finalizo == false && checked_finaliza && //estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.Derrumbe') &&
                            <FormDataConsumer formClassName={classes.ocultar}>
                                {({ formData, dispatch, ...rest }) => {
                                    if (typeof formData != 'undefined') {
                                        formData.indicadorO4 = indicadoro4
                                    }
                                }}
                            </FormDataConsumer>
                            */}
                        {finalizo == false && checked_finaliza && //estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.Derrumbe') &&
                            <TextInput options={ (finalizo)?{ disabled: true }:{} } label="resources.disponibilidad_vias.fields.observacionesDespeje" source="observacionesDespeje" validate={[required(), maxLength(200), validardespeje]} multiline formClassName={classes.grid_cont12} fullWidth />
                        }
                        {finalizo == false && checked_finaliza && //estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.Derrumbe') &&
                            <FormDataConsumer formClassName={classes.ocultar}>
                                {({ formData, dispatch, ...rest }) => {
                                    if (typeof formData != 'undefined') {
                                        console.log(formData.observacionesDespeje)
                                    }
                                }}
                            </FormDataConsumer>
                        }
                        {estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.Otro') &&
                            <Grid container spacing={24}>
                                <Grid item xs={12}>
                                    <ArrayInput label="" source="servicios" fullWidth validate={[validararray]} defaultValue={[{ tiempoServicio: tiemposervicio }]}>
                                        <SimpleFormIterator disableAdd disableRemove >

                                            <FormDataConsumer>
                                                {
                                                    ({ formData, scopedFormData, getSource, ...rest }) => (scopedFormData != null && scopedFormData.tipoServicio === 1 &&
                                                        <ReferenceInput label="resources.disponibilidad_vias.fields.servElemento" source={getSource("serviciosElementoEmergencias")} reference="contacto_emergencias" fullWidth>
                                                            <SelectInput optionText="empresa" disabled />
                                                        </ReferenceInput>
                                                    )
                                                }
                                            </FormDataConsumer>
                                            <FormDataConsumer>
                                                {
                                                    ({ formData, scopedFormData, getSource, ...rest }) => (scopedFormData != null && scopedFormData.tipoServicio === 2 &&
                                                        <ReferenceInput label="resources.disponibilidad_vias.fields.servElemento" source={getSource("serviciosElementoProveedores")} reference="contacto_emergencias" fullWidth>
                                                            <SelectInput optionText="razonSocial" disabled />
                                                        </ReferenceInput>
                                                    )
                                                }
                                            </FormDataConsumer>
                                            <FormDataConsumer>
                                                {
                                                    ({ formData, scopedFormData, getSource, ...rest }) => (scopedFormData != null && scopedFormData.tipoServicio === 3 &&
                                                        <ReferenceInput label="resources.disponibilidad_vias.fields.servElemento" source={getSource("serviciosElementoServicios")} reference="contacto_emergencias" fullWidth>
                                                            <SelectInput optionText="empresa" disabled />
                                                        </ReferenceInput>
                                                    )
                                                }
                                            </FormDataConsumer>
                                            <FormDataConsumer>
                                                {
                                                    ({ formData, scopedFormData, getSource, ...rest }) => (scopedFormData != null &&
                                                        <DateInput label="resources.disponibilidad_vias.fields.fechaFinalizacion" source={getSource("fechaFinalizacion")} validate={[required()]} fullWidth />
                                                    )
                                                }
                                            </FormDataConsumer>
                                            <FormDataConsumer>
                                                {
                                                    ({ formData, scopedFormData, getSource, ...rest }) => (scopedFormData != null &&
                                                        <FInput label="resources.disponibilidad_vias.fields.horaFinalizacion" source={getSource("horaFinalizacion")} fullWidth format={timeFormatter} />
                                                    )
                                                }
                                            </FormDataConsumer>
                                            <FInput label="resources.disponibilidad_vias.fields.tiempoServicio" source="tiempoServicio" disabled fullWidth />
                                            <FormDataConsumer className={classes.ocultar}>
                                                {({ formData, scopedFormData, dispatch, ...rest }) => {

                                                    if (typeof formData != 'undefined' && scopedFormData.fechaFinalizacion && scopedFormData.horaFinalizacion) {
                                                        let ffinal = scopedFormData.fechaFinalizacion;
                                                        if (ffinal) {
                                                            let aux = ffinal.split('T');
                                                            ffinal = aux[0];
                                                        }
                                                        let hfinal = scopedFormData.horaFinalizacion;
                                                        if (hfinal) {
                                                            let aux = hfinal.split(':')
                                                            if (aux[0] <= 9) {
                                                                hfinal = "0" + hfinal;
                                                            }
                                                        }
                                                        let fechareporte = formData.fechaReporte;
                                                        if (fechareporte) {
                                                            let aux = fechareporte.split('T');
                                                            fechareporte = aux[0];
                                                        }
                                                        let fecha_tiemposervicio = new Date(ffinal + "T" + hfinal);
                                                        let fecha_reporte = new Date(fechareporte + "T" + formData.horareport);
                                                        let fecha_tiemposervicio_off = new Date(fecha_tiemposervicio.getTime() + Math.abs(fecha_tiemposervicio.getTimezoneOffset() * 60000));
                                                        let fecha_reporte_off = new Date(fecha_reporte.getTime() + Math.abs(fecha_reporte.getTimezoneOffset() * 60000));
                                                        let difference = fecha_reporte_off.getTime() - fecha_tiemposervicio_off.getTime();
                                                        let differenhours = difference / (1000 * 3600);
                                                        let differenminutes = (difference / (1000 * 60)) % 60;
                                                        console.log(fecha_reporte_off + " // " + ffinal + "T" + hfinal);

                                                        scopedFormData.tiempoServicio = Math.abs(parseInt(differenhours)) + ":" + Math.abs(parseInt(differenminutes));
                                                    }
                                                }}
                                            </FormDataConsumer>
                                        </SimpleFormIterator>
                                    </ArrayInput>
                                </Grid>
                                {/*<Grid item xs={12}>
                                    <TextInput label="Observaciones" source="observacionesFinalizacion" formClassName={classes.grid_cont12} validate={[required(), maxLength(200)]} fullWidth multiline />
                                </Grid>*/}
                            </Grid>
                        }
                        {estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') &&
                            <Grid container spacing={24}>
                                <Grid item xs={12}>
                                    <ArrayInput label="" source="servicios" fullWidth validate={[validararray]} defaultValue={[{ tiempoServicio: tiemposervicio }]}>
                                        <SimpleFormIterator disableAdd disableRemove >
                                            <NumberInput source="tipoServicio" className={classes.ocultar} />
                                            <FormDataConsumer>
                                                {
                                                    ({ formData, scopedFormData, getSource, ...rest }) => (scopedFormData != null && scopedFormData.tipoServicio === 1 &&
                                                        <ReferenceInput label="resources.disponibilidad_vias.fields.servElemento" source={getSource("serviciosElementoEmergencias")} reference="contacto_emergencias" fullWidth>
                                                            <SelectInput optionText="empresa" disabled />
                                                        </ReferenceInput>
                                                    )
                                                }
                                            </FormDataConsumer>
                                            <FormDataConsumer>
                                                {
                                                    ({ formData, scopedFormData, getSource, ...rest }) => (scopedFormData != null && scopedFormData.tipoServicio === 2 &&
                                                        <ReferenceInput label="resources.disponibilidad_vias.fields.servElemento" source={getSource("serviciosElementoProveedores")} reference="contacto_emergencias" fullWidth>
                                                            <SelectInput optionText="razonSocial" disabled />
                                                        </ReferenceInput>
                                                    )
                                                }
                                            </FormDataConsumer>
                                            <FormDataConsumer>
                                                {
                                                    ({ formData, scopedFormData, getSource, ...rest }) => (scopedFormData != null && scopedFormData.tipoServicio === 3 &&
                                                        <ReferenceInput label="resources.disponibilidad_vias.fields.servElemento" source={getSource("serviciosElementoServicios")} reference="contacto_emergencias" fullWidth>
                                                            <SelectInput optionText="empresa" disabled />
                                                        </ReferenceInput>
                                                    )
                                                }
                                            </FormDataConsumer>
                                            <FormDataConsumer>
                                                {
                                                    ({ formData, scopedFormData, getSource, ...rest }) => (scopedFormData != null &&
                                                        <DateInput label="resources.disponibilidad_vias.fields.fechaLlegada" source={getSource("fechaLlegada")} validate={[required()]} fullWidth />
                                                    )
                                                }
                                            </FormDataConsumer>
                                            <FormDataConsumer>
                                                {
                                                    ({ formData, scopedFormData, getSource, ...rest }) => (scopedFormData != null &&
                                                        <FInput label="resources.disponibilidad_vias.fields.horaLlegada" source={getSource("horaLlegada")} fullWidth format={timeFormatter} />
                                                    )
                                                }
                                            </FormDataConsumer>
                                            <FInput label="resources.disponibilidad_vias.fields.tiempoRespuesta" source="tiempoRespuesta" disabled fullWidth />
                                            <FormDataConsumer className={classes.ocultar}>
                                                {({ formData, scopedFormData, dispatch, ...rest }) => {

                                                    if (typeof formData != 'undefined' && typeof scopedFormData != 'undefined' && scopedFormData.fechaLlegada && scopedFormData.horaLlegada) {
                                                        let ffinal = scopedFormData.fechaLlegada;
                                                        if (ffinal) {
                                                            let aux = ffinal.split('T');
                                                            ffinal = aux[0];
                                                        }
                                                        let hfinal = scopedFormData.horaLlegada;
                                                        if (hfinal) {
                                                            let aux = hfinal.split(':')
                                                            if (aux[0] <= 9) {
                                                                hfinal = "0" + hfinal;
                                                            }
                                                        }
                                                        let fechareporte = formData.fechaReporte;
                                                        if (fechareporte) {
                                                            let aux = fechareporte.split('T');
                                                            fechareporte = aux[0];
                                                        }
                                                        let fecha_tiemposervicio = new Date(ffinal + "T" + hfinal);
                                                        let fecha_reporte = new Date(fechareporte + "T" + formData.horareport);
                                                        let fecha_tiemposervicio_off = new Date(fecha_tiemposervicio.getTime() + Math.abs(fecha_tiemposervicio.getTimezoneOffset() * 60000));
                                                        let fecha_reporte_off = new Date(fecha_reporte.getTime() + Math.abs(fecha_reporte.getTimezoneOffset() * 60000));
                                                        let difference = fecha_reporte_off.getTime() - fecha_tiemposervicio_off.getTime();
                                                        let differenhours = difference / (1000 * 3600);
                                                        let differenminutes = (difference / (1000 * 60)) % 60;
                                                        console.log(fecha_reporte_off + " // " + ffinal + "T" + hfinal);

                                                        scopedFormData.tiempoRespuesta = Math.abs(parseInt(differenhours)) + ":" + Math.abs(parseInt(differenminutes));
                                                    }
                                                }}
                                            </FormDataConsumer>
                                            {/*<TextInput label="Indicador O5" source="indicadorO5" disabled fullWidth />
                                            <FormDataConsumer className={classes.ocultar}>
                                                {({ formData, dispatch, scopedFormData, ...rest }) => {
                                                    if (typeof formData != 'undefined' && typeof scopedFormData != 'undefined' && scopedFormData.tiempoRespuesta) {
                                                        let aux = scopedFormData.tiempoRespuesta.split(':');
                                                        let horaresp = aux[0];
                                                        let minutosresp = aux[1];
                                                        if (scopedFormData.serviciosElementoEmergencias === "Ambulancia") {
                                                            if ((minutosresp > 30 && horaresp == 0) || horaresp >= 1) {
                                                                scopedFormData.indicadorO5 = "No Cumple"
                                                            }
                                                        }
                                                        else if ((horaresp > 1) || (horaresp == 1 && minutosresp > 0)) {
                                                            scopedFormData.indicadorO5 = "No Cumple"
                                                        }
                                                        else {
                                                            scopedFormData.indicadorO5 = "Cumple"
                                                        }

                                                    }
                                                }}
                                            </FormDataConsumer>*/}
                                        </SimpleFormIterator>
                                    </ArrayInput>
                                </Grid>
                                {/*<Grid item xs={12}>
                                    <TextInput label="Observaciones" source="observacionesLlegada" validate={[required(), maxLength(200)]} multiline fullWidth />
                                </Grid>*/}
                            </Grid>
                        }
                        {estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Cierre') &&
                            <Grid container spacing={24}>
                                <Grid item xs={4}>
                                    <DateInput label="resources.disponibilidad_vias.fields.fecha_puestaservicio" id="fecha_puestaservicio" disabled format={v => { return today }} source="fechaPuestaServicio" fullWidth />
                                    <FormDataConsumer className={classes.ocultar}>
                                        {({ formData, dispatch, ...rest }) => {
                                            if (typeof formData != 'undefined') {
                                                formData.fechaPuestaServicio = today
                                            }
                                        }}
                                    </FormDataConsumer>
                                </Grid>
                                <Grid item xs={4}>
                                    <NumberInput label="resources.disponibilidad_vias.fields.diasCierre" disabled source="diasCierre" fullWidth format={v => { return diasconcierre }} />
                                </Grid>
                                <FormDataConsumer className={classes.ocultar}>
                                    {({ formData, dispatch, ...rest }) => {
                                        if (typeof formData != 'undefined') {
                                            formData.diasCierre = diasconcierre
                                        }
                                    }}
                                </FormDataConsumer>
                                <Grid item xs={4}>
                                    <TextInput label="resources.disponibilidad_vias.fields.indicadorE17" disabled source="indicadorE17" fullWidth format={v => { return indicadore17 }} />
                                </Grid>
                                <FormDataConsumer className={classes.ocultar}>
                                    {({ formData, dispatch, ...rest }) => {
                                        if (typeof formData != 'undefined') {
                                            formData.indicadorE17 = indicadore17
                                        }
                                    }}
                                </FormDataConsumer>
                                <Grid item xs={12}>
                                    <Typography variant="b" component="b">{translate('dynatest.resources.disponibilidad_vias.subTitle.NoSegmentosCierre')}</Typography>
                                </Grid>
                                <Query type="GET_MANY" resource="segmentos" payload={{ ids: segmentosCierre }}>
                                    {({ data, loading, error }) => {
                                        if (loading) { return <Loading />; }
                                        if (error) { return <p>ERROR {error.message}</p>; }
                                        return (
                                            <Grid item xs={12}>
                                                <table>
                                                    <tr><td>{"Segmentos Afectados"}</td></tr>
                                                    {data.map(segmento =>
                                                        <tr>
                                                            <td>{segmento.fullCodigo}</td>
                                                        </tr>
                                                    )}
                                                </table>
                                            </Grid>
                                        )
                                    }}
                                </Query>
                                <Grid item xs={12}>
                                    <TextInput label="resources.disponibilidad_vias.fields.observacionesCierre" validate={[required(), maxLength(200), validardiascierre]} source="observacionesCierre" fullWidth multiline />
                                </Grid>
                            </Grid>
                        }
                    </FormTab>
                }
                <FormTab label="dynatest.resources.disponibilidad_vias.tabs.tabsTitle.fotografias">
                    <Grid container spacing={24}>
                        <Grid item xs={8}>
                        <FormDataConsumer>
                            {({ formData, ...rest }) => { formData.updatedIn = "api"; }}
                        </FormDataConsumer> 
                        <FormDataConsumer>
                            {({ formData, ...rest }) =>
                            <ArrayInputImagesEdit record={formData} {...rest} />
                            }
                        </FormDataConsumer>
                        </Grid>
                        <Grid item xs={4}>
                        <FormDataConsumer>
                            {({ formData, ...rest }) =>
                            <ButtonCarousel record={formData} {...rest} />
                            }
                        </FormDataConsumer>
                        </Grid>
                    </Grid>
                </FormTab>
            </TabbedForm>
        </Edit>
    );
}));