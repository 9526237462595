import React, {useState}  from 'react';
import { ReferenceField,CardActions,SelectField,TextInput,ShowView,ShowController,SelectInput,BooleanField, ListButton,EditButton,FormTab,ArrayField,Datagrid,NumberField ,TabbedShowLayout, Tab, TextField, DateField } from 'react-admin';
import { Show } from './../../Show';
import { PRField } from './../../../fields/PRField';
import { withStyles } from '@material-ui/core';
import TabbedForm from '../../TabbedForm';
import { styles } from './../../../EstilosGrid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Ejes from './views/ejes';
import {PercentageInput} from '../../../inputs/PercentageInput';
import Tasas from './views/tasas';
import { GMapInput } from '../../../inputs/GMapInput';
import { translate } from 'react-admin';

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};


const PostShowActions = ({ basePath, data}) => (
    <CardActions style={cardActionStyle}>
        <EditButton basePath={basePath} record={data} />
        {/* Add your custom actions */}
        <ListButton basePath={basePath}/>
    </CardActions>
);

// const CustomToolbar = withStyles(toolbarStyles)(props => (
//     <Toolbar {...props}>
//         <span>.</span>
        
//     </Toolbar>
// ));
export const EstacionInventarioShow = translate(withStyles(styles)(({ classes, record, ...props }) =>{
    const { translate } = props;
    const [years1, setYears1] = useState('');
    const validarAño1 = (value) => {
       
       
        setYears1(value)
    
    
    }
    const [years2, setYears2] = useState('');
    const validarAño2 = (value) => {
      if(years1 > value){
        return translate("resources.validaciones.fechaInicio")
     }
    // else if (years1 === value){
    //     return "La fecha incial no deber igual ser a la final."
    // }
        setYears2(value)
    
    
    }
const choices=[
    { id: '2000', name: '2000' },
    { id: '2001', name: '2001' },
    { id: '2002', name: '2002' },
    { id: '2003', name: '2003' },
    { id: '2004', name: '2004' },
    { id: '2005', name: '2005' },
    { id: '2006', name: '2006' },
    { id: '2007', name: '2007' },
    { id: '2008', name: '2008' },
    { id: '2009', name: '2009' },
    { id: '2010', name: '2010' },
    { id: '2011', name: '2011' },
    { id: '2012', name: '2012' },
    { id: '2013', name: '2013' },
    { id: '2014', name: '2014' },
    { id: '2015', name: '2015' },
    { id: '2016', name: '2016' },
    { id: '2017', name: '2017' },
    { id: '2018', name: '2018' },
    { id: '2019', name: '2019' },
    { id: '2020', name: '2020' },
    { id: '2021', name: '2021' },
    { id: '2022', name: '2022' },
    { id: '2023', name: '2023' },
    { id: '2024', name: '2024' },
    { id: '2025', name: '2025' },
    { id: '2026', name: '2026' },
    { id: '2027', name: '2027' },
    { id: '2028', name: '2028' },
    { id: '2029', name: '2029' },
    { id: '2030', name: '2030' }
  ]
  const [imagen , setImagen] =useState(true);
  const ValidarImagen =  (value) =>{
      // console.log(value + "Aqui est la imagen ")
      if(value !== null && value !== undefined && value !== 'undefined')
      {
          let lenghtObject = Object.keys(value);
          let valor = '';        
          for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                
          setImagen(value) 
          // console.log(value + "Aqui est la imagen ")
      }
   }
    return(
        <ShowController {...props} >
        {controllerProps =>
    <ShowView title="dynatest.resources.estacion_conteos.title.showTitle"  {...controllerProps} {...props} actions={<PostShowActions />} >
       <TabbedForm toolbar={false}>
            <FormTab label="dynatest.resources.estacion_conteos.tabs.tabsTitle.identificacion">
                <TextField label="resources.estacion_conteos.fields.nombre" source="nombre" fullWidth formClassName={classes.grid_cont3s}/>
                <br></br>
                <TextInput source="tipoElemento_.src" validate={ValidarImagen} style={{display : 'none'}}/>
                <Typography variant="h3" style={{ width: '100%' }} component="h3">{translate('dynatest.resources.estacion_conteos.subTitle.localizacion')}</Typography>
          <span></span>
          <span></span>
          <Divider fullWidth formClassName={classes.grid_cont12} />
                <ReferenceField label="resources.estacion_conteos.fields.unidad_funcional" source="unidad_funcional" reference="unidad_funcionals" linkType={false} fullWidth formClassName={classes.grid_cont4}>
              <TextField source="fullCodigos" />
          </ReferenceField>
          <ReferenceField label="resources.estacion_conteos.fields.tramo" source="tramo" reference="tramos" linkType={false} fullWidth formClassName={classes.grid_cont4}>
              <TextField source="fullCodigos" />
          </ReferenceField>
          <ReferenceField label="resources.estacion_conteos.fields.segmento" source="segmento" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont3}>
              <TextField source="fullCodigos" />
          </ReferenceField>
          <Typography variant="b" component="b">{translate('dynatest.resources.estacion_conteos.subTitle.coordenadas')}</Typography>
        <span></span>
        <span></span>
        <Divider fullWidth style={{ width: '100%', marginTop: '10px' }} />
        <PRField label="resources.estacion_conteos.fields.abscisa" id="ingresoAbFin" source="abInicio" fullWidth formClassName={classes.grid_cont4} />
        <NumberField label="resources.estacion_conteos.fields.latitud" source="latitudInicial" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4} />
        <NumberField label="resources.estacion_conteos.fields.longitud" source="longitudInicial" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
        <GMapInput style={{width : "100%"}}
                                defaultZoom={12}                                    
                                defaultCenter={{ lat: 4.7567039, lng: -74.0311056 }}
                                source="ubicacionMapa"
                                multipleMarkers="true"
                                googleKey="AIzaSyCivTXzJBYRBK8nosBYRFXnv-hGXIbhnYY"
                                icon= {"/imagenes/senalesColombia/Elementos/ESTCON.png"} 
                            />
                <BooleanField label="resources.estacion_conteos.fields.operativa" source="operativa" linkType={false} fullWidth />
            </FormTab>
            <FormTab label="dynatest.resources.estacion_conteos.tabs.tabsTitle.detalles">
            <TextField label="resources.estacion_conteos.fields.consultor" source="consultor"  formClassName={classes.grid_cont3}/>
            <TextField label="resources.estacion_conteos.fields.informe" source="informe"  formClassName={classes.grid_cont3}/>
            <DateField label="resources.estacion_conteos.fields.date" source="date" formClassName={classes.grid_cont3}/>
            <Typography variant="b" component="b">{translate('dynatest.resources.estacion_conteos.subTitle.abscisasAfectacion')}</Typography>
        <span></span>
        <span></span>
        <Divider fullWidth style={{ width: '100%', marginTop: '10px' }} />
        <ArrayField
                                source="tablaEstacion"
                                style={{ width: '100%' }}
                                label=" "
                            >
                                <Datagrid>
        <ReferenceField
                label="resources.estacion_conteos.fields.unidad_funcional"
                source="unidadFuncionala"
                linkType={false} 
                reference="unidad_funcionals"
                fullWidth formClassName={classes.grid_cont3}
              >
                <TextField source="fullCodigos" />
              </ReferenceField>
                <TextField label="resources.estacion_conteos.fields.abscisaInicial" source="abInicioa" linkType={false} fullWidth formClassName={classes.grid_cont3} />
                <TextField label="resources.estacion_conteos.fields.abscisaFinal"  source="abFina" fullWidth formClassName={classes.grid_cont3} />
               </Datagrid>
                </ArrayField>
            </FormTab>
            <FormTab label="dynatest.resources.estacion_conteos.tabs.tabsTitle.ejesEquivalentes">
            <NumberField label="resources.estacion_conteos.fields.direccional" source="direccional"  style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}}  formClassName={classes.grid_cont3s}/>
            <NumberField label="resources.estacion_conteos.fields.carril" source="carril"  style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/>
            
            <NumberField label="resources.estacion_conteos.fields.factorCamionPonderado" source="factorCamionPonderado"  style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/>

            <TextField label="resources.estacion_conteos.fields.years" source="years" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3} />
           
            <WithProps>{({ record, ...props }) =>
                 <Ejes record={record} />}
            </WithProps>
            </FormTab>
            <FormTab label="dynatest.resources.estacion_conteos.tabs.tabsTitle.tasasCrecimiento">
              <NumberField label="resources.estacion_conteos.fields.esal1" source="esal1"  style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} fullWidth formClassName={classes.grid_cont4}/>
              <NumberField label="resources.estacion_conteos.fields.esal2" source="esal2" style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} fullWidth formClassName={classes.grid_cont4}/>
              <NumberField label="resources.estacion_conteos.fields.esal3" source="esal3" style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} fullWidth formClassName={classes.grid_cont3}/>
              <NumberField label="resources.estacion_conteos.fields.tpdactual" source="tpdactual"  fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4}/>
              <TextField label="resources.estacion_conteos.fields.tasacrecimiento" source="tasacrecimiento"  style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} fullWidth formClassName={classes.grid_cont4}/>
              <br/>
            <SelectInput id="ano_id" label="resources.estacion_conteos.fields.years1"  source="years1" validate={[validarAño1]} choices={choices} fullWidth formClassName={classes.grid_cont3}/>
            <SelectInput id="ano_id" label="resources.estacion_conteos.fields.years2"  source="years2" validate={[validarAño2]} choices={choices} fullWidth formClassName={classes.grid_cont3}/>
            {years1 && years2 &&
                    <WithProps formClassName={classes.grid_cont_div}>{({record,...props})=>
                <Tasas record={record} years1={years1}  years2={years2} tipo="tipo" /> }
                </WithProps> 
}
            </FormTab>
            <FormTab label="dynatest.resources.estacion_conteos.tabs.tabsTitle.auditoria">
                    <DateField source="createdAt" label="resources.estacion_conteos.fields.createdAt" formClassName={classes.grid_cont3s} showTime/>
                    <TextField source="createdBy" label="resources.estacion_conteos.fields.createdBy" formClassName={classes.grid_cont3}/>    
                    <br/>
                    <DateField source="updatedAt" label="resources.estacion_conteos.fields.updatedAt" formClassName={classes.grid_cont3s} showTime/>
                    <TextField source="updatedBy" label="resources.estacion_conteos.fields.updatedBy" formClassName={classes.grid_cont3}/>
                </FormTab>
            
        </TabbedForm>
    </ShowView>
}
</ShowController>
)}));
const WithProps = ({ children, ...props }) => children(props);