import React, { Fragment, useState } from 'react';
import { List, Datagrid, TextField, EditButton, ShowButton, ReferenceField, Filter, ReferenceInput, SelectInput, 
    AutocompleteInput, BulkDeleteWithConfirmButton, Loading
} from 'react-admin';
import { PField } from './../../../fields/PField';
import { PRField } from './../../../fields/PRField';
import { translate } from 'react-admin';
import { CardActions, CreateButton, ExportButton } from 'react-admin';
const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);
const ListAction = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
        <ExportButton basePath={basePath}/>
    </CardActions>
);
const ListAction2 = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
    </CardActions>
);
const FiltroAlrededor = translate((props) => {
    const { translate } = props;
    const [uf, setUf] = useState('none');
    const ufSet = (value) => {
        setUf(value);
    }
    const [tramo, setTramo] = useState('none');
    const tramoSet = (value) => {
        setTramo(value);
    }
        
    return(
    <Filter {...props}>
       <ReferenceInput 
            id="unidadFuncional"
            label="resources.elemento_contencions.fields.unidadFuncional" 
            source="unidadFuncional"  
            reference="unidad_funcionals" 
            filterToQuery={searchText => ({ codigo: searchText })} 
            sort={{field : 'codigo', order : 'asc'}}
            validate={[ufSet]}
            alwaysOn
            fullWidth 
        >
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
        <ReferenceInput 
            label="resources.elemento_contencions.fields.tramo" 
            source="tramo" 
            alwaysOn 
            reference="tramos" 
            filter={{ unidadFuncional: uf}}
            validate={[tramoSet]}
            filterToQuery={searchText => ({ codigo: searchText })} 
            sort={{ 
                field: 'codigo', 
                order: 'ASC' ,
                
            }}>
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
        
        <ReferenceInput 
            label="resources.elemento_contencions.fields.segmento" 
            source="segmento" 
            reference="segmentos" 
            alwaysOn 
            sort={{ field: 'codigo', order: 'ASC' }}
            filter={{tramo : tramo}}
            filterToQuery={searchText => ({ codigo: searchText })} 
        >
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
        {/* <SelectInput 
            fullWidth 
            label="Estado" 
            source="estados"   
            sort={{field : 'name' , order :'ASC'}} 
            alwaysOn  
            choices={[
                {id : 'Existente' , name : 'Existente'},
                {id : 'Nuevo' , name : 'Nuevo'},
                {id : 'Reemplazo' , name : 'Reemplazo'},
            ]}
            resettable
        /> */}
        {/* <SelectInput  label="Tipo de Elemento" source="tipo" allowEmpty emptyValue=""
            choices={[
                {id : "Anclajes en roca", name: "Anclajes en roca"},
                {id : "Cercados y redes de contención", name: "Cercados y redes de contención"},
                {id : "Escudo contra caída de rocas", name: "Escudo contra caída de rocas"},
                {id : "Muro anclado", name: "Muro anclado"},
                {id : "Muro de concreto reforzado", name: "Muro de concreto reforzado"},
                {id : "Muro de encofrado", name: "Muro de encofrado"},
                {id : "Muro de gaviones", name: "Muro de gaviones"},
                {id : "Muro de gravedad en concreto", name: "Muro de gravedad en concreto"},
                {id : "Muro de tierra reforzada", name: "Muro de tierra reforzada"},
                {id : "Muros de contención", name: "Muros de contención"},
                {id : "Otros", name: "Otros"},
                {id : "Pantallas ancladas", name: "Pantallas ancladas"},
                {id : "Pilotes y caissons", name: "Pilotes y caissons"},
                {id : "Reconformación", name: "Reconformación"},
                {id : "Revestimiento flexible", name: "Revestimiento flexible"},
                {id : "Revestimiento vegetal", name: "Revestimiento vegetal"},
                {id : "Suelo empernado", name: "Suelo empernado"},
            ]}
        alwaysOn/> */}
         <SelectInput label="resources.elemento_contencions.fields.estados" source="estados" choices={[
                { id: translate('dynatest.resources.elemento_contencions.select.estados.Activo'), name: "dynatest.resources.elemento_contencions.select.estados.Activo" },
                { id: translate('dynatest.resources.elemento_contencions.select.estados.Inactivo'), name: "dynatest.resources.elemento_contencions.select.estados.Inactivo" },
            ]}  fullWidth alwaysOn
        />
            
    </Filter>
    )
});

export const ElementoContencionList = ({ permissions, ...props }) => {
    if (!permissions) return <Loading />;  
    return (
    <List {...props} 
        sort={{ field: 'updatedAt', order: 'DESC' }} 
        title="dynatest.resources.elemento_contencions.title.listTitle" 
        filters={<FiltroAlrededor/>} 
        actions={permissions['contencion']  == 1 ? <ListAction /> : permissions['contencion']  == 2 ? <ListAction2 />: null}
        bulkActionButtons={permissions['contencion']  == 1 ? <PostBulkActionButtons />: null}>
        <Datagrid>
            <TextField label="resources.elemento_contencions.fields.smartCode" source="smartCode"/>
            <TextField label="resources.elemento_contencions.fields.tipoList" source="tipo" sortable/>          
            <PRField label="resources.elemento_contencions.fields.abInicioList" source="abInicio"/>
            <PField label="resources.elemento_contencions.fields.pInicioList" source="pInicio"/>
            <TextField label="resources.elemento_contencions.fields.margen" source="margen" sortable/>
            <TextField label="resources.elemento_contencions.fields.estados" source="estados" sortable/>
            {permissions['contencion'] == 1 || permissions['contencion'] == 2 || permissions['contencion'] == 4 ? <ShowButton/>: null}
            {permissions['contencion'] == 1 || permissions['contencion'] == 2 ? <EditButton/>: null}                             
        </Datagrid>
    </List>
)};