import React from 'react';
import { Admin, Resource, Error } from 'react-admin';
import { render } from 'react-dom';
import NotFound from './NotFound';
/**
 * Hydra
 */
import { fetchHydra as baseFetchHydra } from '@api-platform/admin';
import hydraClient from './hydra/hydraClient';
// IMAGEN
import addUploadFeature from './addUploadFeature';
/**
 * Temporales
 */
import { EditGuesser, ShowGuesser } from 'react-admin';
/**
 * Seguridad
 */
import PimsAuthProvider from './PimsAuthProvider';
import PimsAuthProviderPermissions from './PimsAuthProviderPermissions';
/**
 * Dashboard
 */
import PimsDashboard from './PimsDashboard';
/**
 * Reducers
 */
import proyecto from './reducers/proyectoReducer';
/**
 * Layout
 */
import PimsLayout from './PimsLayout';

/**
 * Recursos
 *
 */
import { ReportesPersQuicksight } from './resources/reportes/listPers';
import { ReportesInventarioQuicksight } from './resources/reportes/listInventario';
import { ReportesIndicadoresQuicksight } from './resources/reportes/listIndicadores';
import { ReportesCostosQuicksight } from './resources/reportes/listCostos';
import { ReportesTransitoQuicksight } from './resources/reportes/listTransito';
import { ReportesContactosQuicksight } from './resources/reportes/listContactos';
import { ReportesModelacionQuicksight } from './resources/reportes/listModelacion';
import { ReportesNovedadesQuicksight } from './resources/reportes/listNovedades';
import { AutomatizacionQuicksight } from './resources/reportes/listAutomatizacion';
import { ConfigQuicksight } from './resources/reportes/listConfig';

import { EstacionInventarioList } from './resources/estacion_conteo/inventario/list';
import { EstacionInventarioCreate } from './resources/estacion_conteo/inventario/create';
import { EstacionInventarioShow } from './resources/estacion_conteo/inventario/show';
import { EstacionInventarioEdit } from './resources/estacion_conteo/inventario/edit';

import { CalificacionList } from './resources/calificacion/list';
import { CalificacionCreate } from './resources/calificacion/create';
import { CalificacionShow } from './resources/calificacion/show';
import { CalificacionEdit } from './resources/calificacion/edit';

import { CalificacionPontonList } from './resources/calificacion_ponton/list';
import { CalificacionPontonCreate } from './resources/calificacion_ponton/create';
import { CalificacionPontonShow } from './resources/calificacion_ponton/show';
import { CalificacionPontonEdit } from './resources/calificacion_ponton/edit';

import { TasasList } from './resources/estacion_conteo/tasas/list';
import { TasasCreate } from './resources/estacion_conteo/tasas/create';
import { TasasShow } from './resources/estacion_conteo/tasas/show';
import { TasasEdit } from './resources/estacion_conteo/tasas/edit';

import { PuenteList } from './resources/puentes/list';
import { PuenteCreate } from './resources/puentes/create';
import { PuenteShow } from './resources/puentes/show';
import { PuenteEdit } from './resources/puentes/edit';

import { CalificacionPuenteList } from './resources/puentes_calificacion/list';
import { CalificacionPuenteCreate } from './resources/puentes_calificacion/create';
import { CalificacionPuenteShow } from './resources/puentes_calificacion/show';
import { CalificacionPuenteEdit } from './resources/puentes_calificacion/edit';

import { TunelList } from './resources/tuneles/list';
import { TunelCreate } from './resources/tuneles/create';
import { TunelShow } from './resources/tuneles/show';
import { TunelEdit } from './resources/tuneles/edit';

import { IndicadorComplementarioList } from './resources/indicador_complementario/list';
import { IndicadorComplementarioCreate } from './resources/indicador_complementario/create';
import { IndicadorComplementarioShow } from './resources/indicador_complementario/show';
import { IndicadorComplementarioEdit } from './resources/indicador_complementario/edit';

import { RegistroNovedadList } from './resources/registro_novedad/list';
import { RegistroNovedadCreate } from './resources/registro_novedad/create';
import { RegistroNovedadShow } from './resources/registro_novedad/show';
import { RegistroNovedadEdit } from './resources/registro_novedad/edit';

import { SeguridadList } from './resources/seguridad/list';
import { SeguridadCreate } from './resources/seguridad/create';
import { SeguridadShow } from './resources/seguridad/show';
import { SeguridadEdit } from './resources/seguridad/edit';

import { EjesList } from './resources/estacion_conteo/ejes/list';
import { EjesCreate } from './resources/estacion_conteo/ejes/create';
import { EjesShow } from './resources/estacion_conteo/ejes/show';
import { EjesEdit } from './resources/estacion_conteo/ejes/edit';

import { PeajesinventarioList } from './resources/peajes/inventario/list';
import { PeajesinventarioCreate } from './resources/peajes/inventario/create';
import { PeajesinventarioShow } from './resources/peajes/inventario/show';
import { PeajesinventarioEdit } from './resources/peajes/inventario/edit';

import { PeajescolajeList } from './resources/peajes/colaje/list';
import { PeajescolajeCreate } from './resources/peajes/colaje/create';
import { PeajescolajeShow } from './resources/peajes/colaje/show';
import { PeajescolajeEdit } from './resources/peajes/colaje/edit';

import { Peajesmensualist } from './resources/peajes/mensual/list';
import { PeajesmensualCreate } from './resources/peajes/mensual/create';
import { PeajesmensualShow } from './resources/peajes/mensual/show';
import { PeajesmensualEdit } from './resources/peajes/mensual/edit';

import { TransitoList } from './resources/transito/list';
import { TransitoShow } from './resources/transito/show';
import { TransitoEdit } from './resources/transito/edit';
import { TransitoCreate } from './resources/transito/create';

import { AtributosList } from './resources/atributo/list';
import { AtributosShow } from './resources/atributo/show';
import { AtributosCreate } from './resources/atributo/create';
import { AtributosEdit } from './resources/atributo/edit';

import { UsuarioList } from './resources/usuario/list';
import { UsuarioShow } from './resources/usuario/show';
import { UsuarioCreate } from './resources/usuario/create';
import { UsuarioEdit } from './resources/usuario/edit';

import { IdiomaList } from './resources/idiomas/list';
import { IdiomaShow } from './resources/idiomas/show';
import { IdiomaCreate } from './resources/idiomas/create';
import { IdiomaEdit } from './resources/idiomas/edit';

import { ProyectoList } from './resources/proyectos/list';
import { ProyectoShow } from './resources/proyectos/show';
import { ProyectoCreate } from './resources/proyectos/create';
import { ProyectoEdit } from './resources/proyectos/edit';

import { Contacto_emergenciaList } from './resources/contactos_emergencia/list';
import { Contacto_emergenciaShow } from './resources/contactos_emergencia/show';
import { Contacto_emergenciaCreate } from './resources/contactos_emergencia/create';
import { Contacto_emergenciaEdit } from './resources/contactos_emergencia/edit';

import { Servicio_publicoList } from './resources/servicios_publicos/list';
import { Servicio_publicoShow } from './resources/servicios_publicos/show';
import { Servicio_publicoCreate } from './resources/servicios_publicos/create';
import { Servicio_publicoEdit } from './resources/servicios_publicos/edit';

import { RutaList } from './resources/rutas/list';
import { RutaShow } from './resources/rutas/show';
import { RutaCreate } from './resources/rutas/create';
import { RutaEdit } from './resources/rutas/edit';

import { UnidadFuncionalList } from './resources/unidades_funcionales/list';
import { UnidadFuncionalCreate } from './resources/unidades_funcionales/create';
import { UnidadFuncionalEdit } from './resources/unidades_funcionales/edit';
import { UnidadFuncionalShow } from './resources/unidades_funcionales/show';

import { TramoList } from './resources/tramos/list';
import { TramoCreate } from './resources/tramos/create';
import { TramoEdit } from './resources/tramos/edit';
import { TramoShow } from './resources/tramos/show';

import { SegmentoList } from './resources/segmentos/list';
import { SegmentoCreate } from './resources/segmentos/create';
import { SegmentoEdit } from './resources/segmentos/edit';
import { SegmentoShow } from './resources/segmentos/show';

import { GeneracionMasivaSegmentoCreate } from './resources/generacion_masiva_segmentos/create';
import { GeneracionMasivaSegmentoEdit } from './resources/generacion_masiva_segmentos/edit';
import { GeneracionMasivaSegmentoList } from './resources/generacion_masiva_segmentos/list';

import { TransformacionSegmentoList } from './resources/transformacion_segmento/list';

import { ProveedorList } from './resources/proveedores/list';
import { ProveedorCreate } from './resources/proveedores/create';
import { ProveedorEdit } from './resources/proveedores/edit';
import { ProveedorShow } from './resources/proveedores/show';


import { ElementoAlrededorList } from './resources/elementos/alrededor/list';
import { ElementoAlrededorCreate } from './resources/elementos/alrededor/create';
import { ElementoAlrededorEdit } from './resources/elementos/alrededor/edit';
import { ElementoAlrededorShow } from './resources/elementos/alrededor/show';

import { ElementoCalzadaList } from './resources/elementos/calzada/list';
import { ElementoCalzadaEdit } from './resources/elementos/calzada/edit';

import { ElementoDrenajeList } from './resources/elementos/drenaje/list';
import { ElementoDrenajeCreate } from './resources/elementos/drenaje/create';
import { ElementoDrenajeEdit } from './resources/elementos/drenaje/edit';
import { ElementoDrenajeShow } from './resources/elementos/drenaje/show';

import { ElementoContencionCreate } from './resources/elementos/contencion/create';
import { ElementoContencionList } from './resources/elementos/contencion/list';
import { ElementoContencionEdit } from './resources/elementos/contencion/edit';
import { ElementoContencionShow } from './resources/elementos/contencion/show';

import { ElementoInstalacionList } from './resources/elementos/instalacion/list';
import { ElementoInstalacionCreate } from './resources/elementos/instalacion/create';

import { ElementoOtroList } from './resources/elementos/otro/list';
import { ElementoOtroCreate } from './resources/elementos/otro/create';

import { ElementoPuenteList } from './resources/elementos/puente/list';
import { ElementoPuenteCreate } from './resources/elementos/puente/create';

import { ElementoRedList } from './resources/elementos/red/list';
import { ElementoRedCreate } from './resources/elementos/red/create';
import { ElementoRedEdit } from './resources/elementos/red/edit';
import { ElementoRedShow } from './resources/elementos/red/show';

import { ElementoSeguridadList } from './resources/elementos/seguridad/list';
import { ElementoSeguridadCreate } from './resources/elementos/seguridad/create';

import { ElementoSenalizacionList } from './resources/elementos/senalizacion/list';
import { ElementoSenalizacionCreate } from './resources/elementos/senalizacion/create';
import { ElementoSenalizacionEdit } from './resources/elementos/senalizacion/edit';
import { ElementoSenalizacionShow } from './resources/elementos/senalizacion/show';

import { ElementoTraficoList } from './resources/elementos/trafico/list';
import { ElementoTraficoCreate } from './resources/elementos/trafico/create';

import { ElementoPontonList } from './resources/elementos/ponton/list';
import { ElementoPontonCreate } from './resources/elementos/ponton/create';
import { ElementoPontonEdit } from './resources/elementos/ponton/edit';
import { ElementoPontonShow } from './resources/elementos/ponton/show';

import { ExportPERSList } from './resources/exportacion_pers/list';
import { ExportacionPersCreate } from './resources/exportacion_pers/create';

import { DisponibilidadList } from './resources/disponibilidad_via/list';
import { DisponibilidadCreate } from './resources/disponibilidad_via/create';
import { DisponibilidadEdit } from './resources/disponibilidad_via/edit';
import { DisponibilidadShow } from './resources/disponibilidad_via/show';

import { ImportacionPersList } from './resources/importacion_pers/list';
import { ImportacionPersCreate } from './resources/importacion_pers/create';


/**
 * Plan_mantenimiento DEPRECATED
 */
// import { PlanMantenimientoList } from './resources/mantenimientos/list';
// import { PlanMantenimientoCreate } from './resources/mantenimientos/create';
// import { PlanMantenimientoEdit } from './resources/mantenimientos/edit';
// import { PlanMantenimientoShow } from './resources/mantenimientos/show';
/**
 * Plan_mantenimiento_detalles
 */
// import { PlanMantenimientoDetallesList } from './resources/mantenimientos/planes/list';
// import { PlanMantenimientoDetallesCreate } from './resources/mantenimientos/planes/create';
// import { PlanMantenimientoDetallesEdit } from './resources/mantenimientos/planes/edit';
// import { PlanMantenimientoDetallesShow } from './resources/mantenimientos/planes/show';

/**
 * Politicas de mantenimiento
 */
import { PoliticasMantenimientoList } from './resources/mantenimientos/politicas/list';
import { PoliticasMantenimientoCreate } from './resources/mantenimientos/politicas/create';
import { PoliticasMantenimientoEdit } from './resources/mantenimientos/politicas/edit';
import { PoliticasMantenimientoShow } from './resources/mantenimientos/politicas/show';

/**
 * Politicas de mantenimiento Particulares
 */
import { PoliticasMantenimientoParticularList } from './resources/mantenimientos/politicas_especiales/list';
import { PoliticasMantenimientoParticularCreate } from './resources/mantenimientos/politicas_especiales/create';
import { PoliticasMantenimientoParticularEdit } from './resources/mantenimientos/politicas_especiales/edit';
import { PoliticasMantenimientoParticularShow } from './resources/mantenimientos/politicas_especiales/show';

/**
 *  FlujoCaja
 */
import { FlujoCajaList } from './resources/mantenimientos/flujo_caja/list';
import { FlujoCajaCreate } from './resources/mantenimientos/flujo_caja/create';
import { FlujoCajaEdit } from './resources/mantenimientos/flujo_caja/edit';
import { FlujoCajaShow } from './resources/mantenimientos/flujo_caja/show';


/**
 *  Costos
 */
import { CostosList } from './resources/mantenimientos/costos/list';
import { CostosCreate } from './resources/mantenimientos/costos/create';
import { CostosShow } from './resources/mantenimientos/costos/show';
import { CostosEdit } from './resources/mantenimientos/costos/edit';

/**
 * Sagas
 */
import errorSaga from './sagas/errorSaga';


/**
 * INVENTARIO
 */

/* FLEXIBLE */
import { ElementoFlexibleList } from './resources/inventarios/segmentos/flexible/list';
import { ElementoFlexibleCreate } from './resources/inventarios/segmentos/flexible/create';
import { ElementoFlexibleShow } from './resources/inventarios/segmentos/flexible/show';
import { ElementoFlexibleEdit } from './resources/inventarios/segmentos/flexible/edit';

/* RIGIDO */
import { ElementoRigidoList } from './resources/inventarios/segmentos/rigido/list';
import { ElementoRigidoCreate } from './resources/inventarios/segmentos/rigido/create';
import { ElementoRigidoShow } from './resources/inventarios/segmentos/rigido/show';
import { ElementoRigidoEdit } from './resources/inventarios/segmentos/rigido/edit';

/* ADOQUIN */
import { ElementoAdoquinList } from './resources/inventarios/segmentos/adoquin/list';
import { ElementoAdoquinCreate } from './resources/inventarios/segmentos/adoquin/create';
import { ElementoAdoquinShow } from './resources/inventarios/segmentos/adoquin/show';
import { ElementoAdoquinEdit } from './resources/inventarios/segmentos/adoquin/edit';

/* OTRO */
import { ElementoOtroSegList } from './resources/inventarios/segmentos/otro/list';
import { ElementoOtroSegCreate } from './resources/inventarios/segmentos/otro/create';
import { ElementoOtroSegShow } from './resources/inventarios/segmentos/otro/show';
import { ElementoOtroSegEdit } from './resources/inventarios/segmentos/otro/edit';

/* DETERIOROS */
import { ElementoDeterioroList } from './resources/indicadores/deterioros/list';
import { ElementoDeterioroCreate } from './resources/indicadores/deterioros/create';
import { ElementoDeterioroEdit } from './resources/indicadores/deterioros/edit';
import { ElementoDeterioroShow } from './resources/indicadores/deterioros/show';

/* OTROS ELEMENTOS */
import { OtrosElementosList } from './resources/inventarios/otros_elementos/list';
import { OtrosElementosCreate } from './resources/inventarios/otros_elementos/create';
import { OtrosElementosEdit } from './resources/inventarios/otros_elementos/edit';
import { OtrosElementosShow } from './resources/inventarios/otros_elementos/show';

/* MATERIALES */
import { MaterialesList } from './resources/inventarios/materiales/especificacion/list';
import { MaterialesCreate } from './resources/inventarios/materiales/especificacion/create';
import { MaterialesEdit } from './resources/inventarios/materiales/especificacion/edit';
import { MaterialesShow } from './resources/inventarios/materiales/especificacion/show';

/* GRANULOMETRIA */
import { GranulometriaList } from './resources/inventarios/materiales/granulometria/list';
import { GranulometriaCreate } from './resources/inventarios/materiales/granulometria/create';
import { GranulometriaEdit } from './resources/inventarios/materiales/granulometria/edit';
import { GranulometriaShow } from './resources/inventarios/materiales/granulometria/show';

/**
 * DATOS BASICOS
 */

/* MEDIDO POR */
import { MedidoPorList } from './resources/indicadores/datos_basicos/medido_por/list';
import { MedidoPorCreate } from './resources/indicadores/datos_basicos/medido_por/create';
import { MedidoPorEdit } from './resources/indicadores/datos_basicos/medido_por/edit';

/* ESTADO */
import { EstadoList } from './resources/indicadores/datos_basicos/estado/list';
import { EstadoCreate } from './resources/indicadores/datos_basicos/estado/create';
import { EstadoEdit } from './resources/indicadores/datos_basicos/estado/edit';

/* TIPO DE DOMINIO */
import { TipoDominioList } from './resources/indicadores/datos_basicos/tipo_dominio/list';
import { TipoDominioEdit } from './resources/indicadores/datos_basicos/tipo_dominio/edit';
import { TipoDominioCreate } from './resources/indicadores/datos_basicos/tipo_dominio/create';

/* FISURA */
import { FisuraList } from './resources/indicadores/datos_basicos/fisura/list';
import { FisuraCreate } from './resources/indicadores/datos_basicos/fisura/create';
import { FisuraEdit } from './resources/indicadores/datos_basicos/fisura/edit';

/* CALIFICACIÓN */
import { CalificacionIndicadorList } from './resources/indicadores/datos_basicos/calificacion/list';
import { CalificacionIndicadorCreate } from './resources/indicadores/datos_basicos/calificacion/create';
import { CalificacionIndicadorEdit } from './resources/indicadores/datos_basicos/calificacion/edit';

/* EVALUACIÓN */
import { EvaluacionList } from './resources/indicadores/datos_basicos/evaluacion/list';
import { EvaluacionCreate } from './resources/indicadores/datos_basicos/evaluacion/create';
import { EvaluacionEdit } from './resources/indicadores/datos_basicos/evaluacion/edit';

/* NIVELES */
import { NivelesList } from './resources/indicadores/datos_basicos/niveles/list';
import { NivelesCreate } from './resources/indicadores/datos_basicos/niveles/create';
import { NivelesEdit } from './resources/indicadores/datos_basicos/niveles/edit';

/* CALIFICACIÓN DE PROVEEDORES */
import { ProveedorCalificacionList } from './resources/proveedor_calificacion/list';
import { ProveedorCalificacionCreate } from './resources/proveedor_calificacion/create';

/* EVALUACIONES */
import { EvaluacionSVList } from './resources/indicadores/evaluacion/seguridad_vial/list';
import { EvaluacionSVCreate } from './resources/indicadores/evaluacion/seguridad_vial/create';
import { EvaluacionSVShow } from './resources/indicadores/evaluacion/seguridad_vial/show';
import { EvaluacionSVEdit } from './resources/indicadores/evaluacion/seguridad_vial/edit';

/* SEÑALES DE PERU */
import { ElementoSenalizacionPeruList } from './resources/elementos/senalizacion_peru/list';
import { ElementoSenalizacionPeruCreate } from './resources/elementos/senalizacion_peru/create';
import { ElementoSenalizacionPeruEdit } from './resources/elementos/senalizacion_peru/edit';
import { ElementoSenalizacionPeruShow } from './resources/elementos/senalizacion_peru/show';

// /* MAPA PERU */
// import {MapaGenralPeru} from './resources/Mapa/MapaList';

/* MAPA GENERAL */
import { MapaGenral } from './resources/Mapa/MapaList';

/* PREGUNTAS */
import { PreguntasList } from './resources/indicadores/datos_basicos/preguntas/list';
import { PreguntasEdit } from './resources/indicadores/datos_basicos/preguntas/edit';

/* COLORES */
import { ColorCreate } from './resources/indicadores/datos_basicos/colores/create';
import { ColorList } from './resources/indicadores/datos_basicos/colores/list';
import { ColorEdit } from './resources/indicadores/datos_basicos/colores/edit';
import { ColorShow } from './resources/indicadores/datos_basicos/colores/show';

/* PARAMETROS DE EVALUACIÓN*/
import { ParametrosList } from './resources/parametros_evaluacion/list';
import { ParametrosCreate } from './resources/parametros_evaluacion/create';
import { ParametrosEdit } from './resources/parametros_evaluacion/edit';
import { ParametrosShow } from './resources/parametros_evaluacion/show';

/*Carga de Información Indicadores Colombia*/
import { CargarInformacionList } from './resources/indicadores_4G/cargar_informacion/list'
import { CargarInformacionCreate } from './resources/indicadores_4G/cargar_informacion/create'
import { CargarInformacionEdit } from './resources/indicadores_4G/cargar_informacion/edit'
import { CargarInformacionShow } from './resources/indicadores_4G/cargar_informacion/show'


/*DETERIOR  O PERS*/
import { ReparacionPersCreate } from './resources/deterioro_pers/reparacion_pers/create'
import { ReparacionPersEdit } from './resources/deterioro_pers/reparacion_pers/edit'
import { ReparacionPersList } from './resources/deterioro_pers/reparacion_pers/list'
import { ReparacionPersShow } from './resources/deterioro_pers/reparacion_pers/show'

import { DeterioroPersCreate } from './resources/deterioro_pers/deterioro_pers/create'
import { DeterioroPersEdit } from './resources/deterioro_pers/deterioro_pers/edit'
import { DeterioroPersList } from './resources/deterioro_pers/deterioro_pers/list'
import { DeterioroPersShow } from './resources/deterioro_pers/deterioro_pers/show'

import { ModeloCostosPersCreate } from './resources/deterioro_pers/modelo_costos/create'
import { ModeloCostosPersEdit } from './resources/deterioro_pers/modelo_costos/edit'
import { ModeloCostosPersList } from './resources/deterioro_pers/modelo_costos/list'
import { ModeloCostosPersShow } from './resources/deterioro_pers/modelo_costos/show'

/*Carga Masiva*/
import { CargaMasivaList } from './resources/carga_masiva/list';
import { CargaMasivaCreate } from './resources/carga_masiva/create';
import { CargaMasivaEdit } from './resources/carga_masiva/edit';

/*Trayectorias*/
import { TrayectoriasList } from './resources/trayectorias/list';
import { TrayectoriasCreate } from './resources/trayectorias/create';
import { TrayectoriasEdit } from './resources/trayectorias/edit';
import { TrayectoriasShow } from './resources/trayectorias/show';
/*PARAMENTROS DE ANALISIS*/
import { ParametrosAnalisisList } from './resources/parametros_analisis/list';
import { ParametrosAnalisisCreate } from './resources/parametros_analisis/create';
import { ParametrosAnalisisEdit } from './resources/parametros_analisis/edit';
import { ParametrosAnalisisShow } from './resources/parametros_analisis/show';
/* LOGIN PAGE*/
import LoginPage from './LoginPage';
import config from './config/config';

/**
 * Idioma General
 */
import spanishMessages from 'ra-language-spanish';
import englishMessages from 'ra-language-english';
import portugueseMessages from 'ra-language-portuguese';
/**
* domain translations
*/

import domainTranslationsPt from './languages/dynatest-language-portuguese';
import domainTranslationsEs from './languages/dynatest-language-spanish';
import domainTranslationsEn from './languages/dynatest-language-english';

/*INDICADORES COMPLEMENTARIOS*/
import { RespuestaEvaluacionComplementariosList } from './resources/respuesta_evaluacion_complementario/list';
import { RespuestaEvaluacionComplementariosShow } from './resources/respuesta_evaluacion_complementario/show';
import { RespuestaEvaluacionComplementariosEdit } from './resources/respuesta_evaluacion_complementario/edit';

/*REGISTRO NOVEDADES*/
import { RespuestaEvaluacionNovedadsList } from './resources/respuesta_evaluacion_novedads/list';
import { RespuestaEvaluacionNovedadsShow } from './resources/respuesta_evaluacion_novedads/show';
import { RespuestaEvaluacionNovedadesEdit } from './resources/respuesta_evaluacion_novedads/edit';

import { AuditList } from './resources/audit/list';
import { AuditShow } from './resources/audit/show';

/*APROBACIONES*/
import { AprobacionsList } from './resources/aprobacion/list';

/*CONFIGURACIÓN SISTEMA*/
import { ConfiguracionGeneralList } from './resources/configuracion_sistema/list';

let urlAppjs = config.UrlServer;
const entrypoint = urlAppjs;//process.env.apiURL


// const headers = {'Authorization': `Bearer ${window.localStorage.getItem('token')}`};
const fetchHydra = (url, options = {}) => baseFetchHydra(url, { ...options, headers: new Headers({ 'Authorization': `Bearer ${window.localStorage.getItem('token')}` }), });

const dataProvider = hydraClient(entrypoint, fetchHydra);

//IMAGEN
const uploadCapableDataProvider = addUploadFeature(dataProvider);
const authProvider = new PimsAuthProvider({ url: entrypoint });


document.addEventListener("mousewheel", function (event) {
  if (document.activeElement.type === "number") {
    document.activeElement.blur();
  }
});

document.addEventListener("keydown", function (event2) {
  if (document.activeElement.type === "number") {
    if (event2.keyCode === 38 || event2.keyCode === 40) {
      event2.preventDefault();
    }
  }
})

// let permiso = authProvider.getPermissions(null);
// debugger
// console.log(permiso['Prueba']);



const messages = {
  'es': domainTranslationsEs,
  'en': domainTranslationsEn,
  'pt': domainTranslationsPt,
};
const i18nProvider = locale => messages[locale];

const userSelect =  () => {
  let idiomaUsuario = window.localStorage.getItem('idioma');
  idiomaUsuario = (idiomaUsuario != undefined && idiomaUsuario != null) ? idiomaUsuario : 'es';
  return idiomaUsuario;
}

const App = () =>
  <Admin

    // dataProvider={dataProvider}

    dataProvider={uploadCapableDataProvider}
    authProvider={authProvider.handleEvent}
    // authProvider={authProvider}
    // authProvider={PimsAuthProviderPermissions}
    appLayout={PimsLayout}
    dashboard={PimsDashboard}
    i18nProvider={i18nProvider}
    locale={userSelect()}
    customReducers={{ proyecto }}
    customSagas={[errorSaga]}
    loginPage={LoginPage}
  >
    {permissions => [
      <Resource name="elemento_senalizacions" list={permissions['senalizacion'] != 3 ? ElementoSenalizacionList : NotFound} edit={permissions['senalizacion'] == 1 || permissions['senalizacion'] == 2 ? ElementoSenalizacionEdit : null} show={ElementoSenalizacionShow} create={permissions['senalizacion'] != 4 ? ElementoSenalizacionCreate : null} />,
      <Resource name="estacion_conteos" list={permissions['inventarioa'] != 3 ? EstacionInventarioList : NotFound} show={EstacionInventarioShow} edit={permissions['inventarioa'] == 1 || permissions['inventarioa'] == 2 ? EstacionInventarioEdit : null} create={permissions['inventarioa'] != 4 ? EstacionInventarioCreate : null} />,
      <Resource name="calificacions" list={permissions['calificacionPuentes'] != 3 ? CalificacionList : NotFound} show={CalificacionShow} edit={permissions['calificacionPuentes'] == 1 || permissions['calificacionPuentes'] == 2 ? CalificacionEdit : null} create={permissions['calificacionPuentes'] != 4 ? CalificacionCreate : null} />,
      <Resource name="calificacion_pontons" list={permissions['calificacionPontones'] != 3 ? CalificacionPontonList : NotFound} show={CalificacionPontonShow} edit={permissions['calificacionPontones'] == 1 || permissions['calificacionPontones'] == 2 ? CalificacionPontonEdit : null} create={permissions['calificacionPontones'] != 4 ? CalificacionPontonCreate : null} />,
      <Resource name="puentes" list={permissions['puentes'] != 3 ? PuenteList : NotFound} show={PuenteShow} edit={permissions['puentes'] == 1 || permissions['puentes'] == 2 ? PuenteEdit : null} create={permissions['puentes'] != 4 ? PuenteCreate : null} />,
      <Resource name="idiomas" list={permissions['idioma'] != 3 ? IdiomaList : NotFound} show={IdiomaShow} edit={permissions['idioma'] == 1 || permissions['idioma'] == 2 ? IdiomaEdit : null} create={permissions['idioma'] != 4 ? IdiomaCreate : null} />,
      <Resource name="calificacion_puentes" list={permissions['puentes'] != 3 ? CalificacionPuenteList : NotFound} show={CalificacionPuenteShow} edit={permissions['puentes'] == 1 || permissions['puentes'] == 2 ? CalificacionPuenteEdit : null} create={permissions['puentes'] != 4 ? CalificacionPuenteCreate : null} />,
      <Resource name="generacion_masiva_segmentos" list={permissions['segmento'] != 3 ? GeneracionMasivaSegmentoList : NotFound} edit={permissions['segmento'] == 1 || permissions['segmento'] == 2 ? GeneracionMasivaSegmentoEdit : null} create={permissions['segmento'] != 4 ? GeneracionMasivaSegmentoCreate : null} />,
      <Resource name="transformacion_segmentos" list={TransformacionSegmentoList} />,
      <Resource name="tuneles" list={permissions['tunel'] != 3 ? TunelList : NotFound} show={TunelShow} edit={permissions['tunel'] == 1 || permissions['tunel'] == 2 ? TunelEdit : null} create={permissions['tunel'] != 4 ? TunelCreate : null} />,
      <Resource name="indicador_complementarios" list={permissions['indicadorComplementario'] != 3 ? IndicadorComplementarioList : NotFound} show={IndicadorComplementarioShow} edit={permissions['indicadorComplementario'] == 1 || permissions['indicadorComplementario'] == 2 ? IndicadorComplementarioEdit : null} create={permissions['indicadorComplementario'] != 4 ? IndicadorComplementarioCreate : null} />,
      <Resource name="registro_novedads" list={permissions['registroNovedad'] != 3 ? RegistroNovedadList : NotFound} show={RegistroNovedadShow} edit={ permissions['registroNovedad'] == 1 || permissions['registroNovedad'] == 2 ? RegistroNovedadEdit : null } create={permissions['registroNovedad'] != 4 ? RegistroNovedadCreate : null}  />,
      <Resource name="seguridads" list={permissions['seguridad'] != 3 ? SeguridadList : NotFound} show={SeguridadShow} edit={permissions['seguridad'] == 1 || permissions['seguridad'] == 2 ? SeguridadEdit : null} create={permissions['seguridad'] != 4 ? SeguridadCreate : null} />,
      <Resource name="tasas" list={permissions['tasaCrecimiento'] != 3 ? TasasList : NotFound} show={TasasShow} edit={permissions['tasaCrecimiento'] == 1 || permissions['tasaCrecimiento'] == 2 ? TasasEdit : null} create={permissions['tasaCrecimiento'] != 4 ? TasasCreate : null} />,
      <Resource name="ejes" list={permissions['transitoMensuala'] != 3 ? EjesList : NotFound} show={EjesShow} edit={permissions['transitoMensuala'] == 1 || permissions['transitoMensuala'] == 2 ? EjesEdit : null} create={permissions['transitoMensuala'] != 4 ? EjesCreate : null} />,
      <Resource name="mensuals" list={permissions['transitoMensual'] != 3 ? Peajesmensualist : NotFound} show={PeajesmensualShow} edit={permissions['transitoMensual'] == 1 || permissions['transitoMensual'] == 2 ? PeajesmensualEdit : null} create={permissions['transitoMensual'] != 4 ? PeajesmensualCreate : null} />,
      <Resource name="colajes" list={permissions['colaDePeajea'] != 3 ? PeajescolajeList : NotFound} show={PeajescolajeShow} edit={permissions['colaDePeajea'] == 1 || permissions['colaDePeajea'] == 2 ? PeajescolajeEdit : null} create={permissions['colaDePeajea'] != 4 ? PeajescolajeCreate : null} />,
      <Resource name="inventariopeajes" list={permissions['inventario'] != 3 ? PeajesinventarioList : NotFound} show={PeajesinventarioShow} edit={permissions['inventario'] == 1 || permissions['inventario'] == 2 ? PeajesinventarioEdit : null} create={permissions['inventario'] != 4 ? PeajesinventarioCreate : null} />,
      <Resource name="atributos" list={permissions['perfiles'] != 3 ? AtributosList : NotFound} edit={permissions['perfiles'] == 1 || permissions['perfiles'] == 2 ? AtributosEdit : null} show={AtributosShow} create={permissions['perfiles'] != 4 ? AtributosCreate : null} />,
      <Resource name="contacto_emergencias" list={permissions['contemergencia'] != 3 ? Contacto_emergenciaList : NotFound} edit={permissions['contemergencia'] == 1 || permissions['contemergencia'] == 2 ? Contacto_emergenciaEdit : null} show={Contacto_emergenciaShow} create={permissions['contemergencia'] != 4 ? Contacto_emergenciaCreate : null} />,
      <Resource name="servicios_publicos" list={permissions['serpublicos'] != 3 ? Servicio_publicoList : NotFound} edit={permissions['serpublicos'] == 1 || permissions['serpublicos'] == 2 ? Servicio_publicoEdit : null} show={Servicio_publicoShow} create={permissions['serpublicos'] != 4 ? Servicio_publicoCreate : null} />,
      <Resource name="proyectos" list={permissions['proyecto'] != 3 ? ProyectoList : NotFound} edit={permissions['proyecto'] == 1 || permissions['proyecto'] == 2 ? ProyectoEdit : null} show={ProyectoShow} create={permissions['proyecto'] != 4 ? ProyectoCreate : null} />,
      <Resource name="rutas" list={permissions['rutas'] != 3 ? RutaList : NotFound} edit={permissions['rutas'] == 1 || permissions['rutas'] == 2 ? RutaEdit : null} show={RutaShow} create={permissions['rutas'] != 4 ? RutaCreate : null} />,
      <Resource name="unidad_funcionals" list={permissions['unifuncionales'] != 3 ? UnidadFuncionalList : NotFound} edit={permissions['unifuncionales'] == 1 || permissions['unifuncionales'] == 2 ? UnidadFuncionalEdit : null} show={UnidadFuncionalShow} create={permissions['unifuncionales'] != 4 ? UnidadFuncionalCreate : null} />,
      <Resource name="tramos" list={permissions['tramos'] != 3 ? TramoList : NotFound} edit={permissions['tramos'] == 1 || permissions['tramos'] == 2 ? TramoEdit : null} show={TramoShow} create={permissions['tramos'] != 4 ? TramoCreate : null} />,
      <Resource name="segmentos" list={permissions['segmento'] != 3 ? SegmentoList : NotFound} edit={permissions['segmento'] == 1 || permissions['segmento'] == 2 ? SegmentoEdit : null} show={SegmentoShow} create={permissions['segmento'] != 4 ? SegmentoCreate : null} />,
      <Resource name="usuarios" list={permissions['usuario'] != 3 ? UsuarioList : NotFound} edit={permissions['usuario'] == 1 || permissions['usuario'] == 2 ? UsuarioEdit : null} show={UsuarioShow} create={permissions['usuario'] != 4 ? UsuarioCreate : null} />,
      <Resource name="proveedors" list={permissions['proveedores'] != 3 ? ProveedorList : NotFound} edit={permissions['proveedores'] == 1 || permissions['proveedores'] == 2 ? ProveedorEdit : null} show={ProveedorShow} create={permissions['proveedores'] != 4 ? ProveedorCreate : null} />,
      <Resource name="elemento_alrededors" list={permissions['alrededores'] != 3 ? ElementoAlrededorList : NotFound} edit={permissions['alrededores'] == 1 || permissions['alrededores'] == 2 ? ElementoAlrededorEdit : null} show={ElementoAlrededorShow} create={permissions['alrededores'] != 4 ? ElementoAlrededorCreate : null} />,
      <Resource name="elemento_reds" list={permissions['redes'] != 3 ? ElementoRedList : NotFound} edit={permissions['redes'] == 1 || permissions['redes'] == 2 ? ElementoRedEdit : null} show={ElementoRedShow} create={permissions['redes'] != 4 ? ElementoRedCreate : null} />,
      <Resource name="elemento_drenajes" list={permissions['drenaje'] != 3 ? ElementoDrenajeList : NotFound} edit={permissions['drenaje'] == 1 || permissions['drenaje'] == 2 ? ElementoDrenajeEdit : null} show={ElementoDrenajeShow} create={permissions['drenaje'] != 4 ? ElementoDrenajeCreate : null} />,
      <Resource name="elemento_contencions" list={permissions['contencion'] != 3 ? ElementoContencionList : NotFound} edit={permissions['contencion'] == 1 || permissions['contencion'] == 2 ? ElementoContencionEdit : null} show={ElementoContencionShow} create={permissions['contencion'] != 4 ? ElementoContencionCreate : null} />,
      <Resource name="elemento_pontons" list={permissions['ponton'] != 3 ? ElementoPontonList : NotFound} edit={permissions['ponton'] == 1 || permissions['ponton'] == 2 ? ElementoPontonEdit : null} show={ElementoPontonShow} create={permissions['ponton'] != 4 ? ElementoPontonCreate : null} />,

      <Resource name="flexible_detalles" list={permissions['flexible'] != 3 ? ElementoFlexibleList : NotFound} create={permissions['flexible'] != 4 ? ElementoFlexibleCreate : null} show={ElementoFlexibleShow} edit={permissions['flexible'] == 1 || permissions['flexible'] == 2 ? ElementoFlexibleEdit : null} />,
      <Resource name="rigido_detalles" list={permissions['rigido'] != 3 ? ElementoRigidoList : NotFound} create={permissions['rigido'] != 4 ? ElementoRigidoCreate : null} show={ElementoRigidoShow} edit={permissions['rigido'] == 1 || permissions['rigido'] == 2 ? ElementoRigidoEdit : null} />,
      <Resource name="adoquin_detalles" list={permissions['adoquin'] != 3 ? ElementoAdoquinList : NotFound} create={permissions['adoquin'] != 4 ? ElementoAdoquinCreate : null} show={ElementoAdoquinShow} edit={permissions['adoquin'] == 1 || permissions['adoquin'] == 2 ? ElementoAdoquinEdit : null} />,
      <Resource name="otro_detalles" list={permissions['otro'] != 3 ? ElementoOtroSegList : NotFound} create={permissions['otro'] != 4 ? ElementoOtroSegCreate : null} show={ElementoOtroSegShow} edit={permissions['otro'] == 1 || permissions['otro'] == 2 ? ElementoOtroSegEdit : null} />,
      <Resource name="deterioro_detalles" list={permissions['colaDePeaje'] != 3 ? ElementoDeterioroList : NotFound} create={permissions['colaDePeaje'] != 4 ? ElementoDeterioroCreate : null} edit={permissions['colaDePeaje'] == 1 || permissions['colaDePeaje'] == 2 ? ElementoDeterioroEdit : null} show={ElementoDeterioroShow} />,
      <Resource name="pers_exports" list={permissions['exportarPers'] != 3 ? ExportPERSList : NotFound} />,
      <Resource name="pers_imports" list={permissions['reportesPers'] != 3 ? ImportacionPersList : NotFound} create={permissions['reportesPers'] != 4 ? ImportacionPersCreate : null} />,
      <Resource name="otros_elementos" list={permissions['otroElemento'] != 3 ? OtrosElementosList : NotFound} create={permissions['otroElemento'] != 4 ? OtrosElementosCreate : null} edit={permissions['otroElemento'] == 1 || permissions['otroElemento'] == 2 ? OtrosElementosEdit : null} show={OtrosElementosShow} />,
      <Resource name="materials" list={permissions['materiales'] != 3 ? MaterialesList : NotFound} create={permissions['materiales'] != 4 ? MaterialesCreate : null} edit={permissions['materiales'] == 1 || permissions['materiales'] == 2 ? MaterialesEdit : null} show={MaterialesShow} />,
      <Resource name="granulometrias" list={permissions['ganulometria'] != 3 ? GranulometriaList : NotFound} create={permissions['ganulometria'] != 4 ? GranulometriaCreate : null} edit={permissions['ganulometria'] == 1 || permissions['ganulometria'] == 2 ? GranulometriaEdit : null} show={GranulometriaShow} />,
      <Resource name="disponibilidad_vias" list={permissions['disponibilidadVia'] != 3 ? DisponibilidadList : DisponibilidadList} create={permissions['disponibilidadVia'] != 4 ? DisponibilidadCreate : DisponibilidadCreate} edit={permissions['disponibilidadVia'] == 1 || permissions['disponibilidadVia'] == 2 ? DisponibilidadEdit : DisponibilidadEdit} show={DisponibilidadShow} />,
      /* -----------------------DETERIORO PERS-------------------------------- */
      <Resource name="reparacion_localizadas" create={permissions['reparaciones'] != 4 ? ReparacionPersCreate : null} list={permissions['reparaciones'] != 3 ? ReparacionPersList : NotFound} edit={permissions['reparaciones'] == 1 || permissions['reparaciones'] == 2 ? ReparacionPersEdit : null} show={ReparacionPersShow} />,
      <Resource name="tipo_deterioro_pers" create={permissions['deterioros'] != 4 ? DeterioroPersCreate : null} list={permissions['deterioros'] != 3 ? DeterioroPersList : NotFound} edit={permissions['deterioros'] == 1 || permissions['deterioros'] == 2 ? DeterioroPersEdit : null} show={DeterioroPersShow} />,
      <Resource name="trayectorias" list={permissions['alternativaIntervencion'] != 3 ? TrayectoriasList : NotFound} edit={permissions['alternativaIntervencion'] == 1 || permissions['alternativaIntervencion'] == 2 ? TrayectoriasEdit : null} show={TrayectoriasShow} create={permissions['alternativaIntervencion'] != 4 ? TrayectoriasCreate : null} />,
      <Resource name="parametros_analises" list={permissions['parametrosAnalisis'] != 3 ? ParametrosAnalisisList : NotFound} edit={permissions['parametrosAnalisis'] == 1 || permissions['parametrosAnalisis'] == 2 ? ParametrosAnalisisEdit : null} show={ParametrosAnalisisShow} create={permissions['parametrosAnalisis'] != 4 ? ParametrosAnalisisCreate : null} />,
      <Resource name="colores" list={permissions['diagramaColorimetrico'] != 3 ? ColorList : NotFound} create={permissions['diagramaColorimetrico'] != 4 ? ColorCreate : null} edit={permissions['diagramaColorimetrico'] == 1 || permissions['diagramaColorimetrico'] == 2 ? ColorEdit : null} show={ColorShow} />,
      /** Politicas Mantenimiento */
      <Resource name="politicas_mantenimientos" list={permissions['politicaGeneral'] != 3 ? PoliticasMantenimientoList : NotFound} create={permissions['politicaGeneral'] != 4 ? PoliticasMantenimientoCreate : null} show={PoliticasMantenimientoShow} edit={permissions['politicaGeneral'] == 1 || permissions['politicaGeneral'] == 2 ? PoliticasMantenimientoEdit : null} />,
      /** Politicas Mantenimiento Particular */
      <Resource name="politicas_mantenimiento_particulars" list={permissions['politicaParticular'] != 3 ? PoliticasMantenimientoParticularList : NotFound} create={permissions['politicaParticular'] != 4 ? PoliticasMantenimientoParticularCreate : null} show={PoliticasMantenimientoParticularShow} edit={permissions['politicaParticular'] == 1 || permissions['politicaParticular'] == 2 ? PoliticasMantenimientoParticularEdit : null} />,
      /* PARAMETROS DE EVALUACIÓN*/
      <Resource name="parametros_grupos" list={permissions['parametrosEvaluacion'] != 3 ? ParametrosList : NotFound} create={permissions['parametrosEvaluacion'] != 4 ? ParametrosCreate : null} edit={permissions['parametrosEvaluacion'] == 1 || permissions['parametrosEvaluacion'] == 2 ? ParametrosEdit : null} show={ParametrosShow} />,
      // MAPA
      <Resource name="elementos" list={permissions['mapa'] != 3 ? MapaGenral : NotFound} />,
      <Resource name="indicadores_cargar_informacions" list={permissions['indicadores4g'] != 3 ? CargarInformacionList : NotFound} create={permissions['indicadores4g'] != 4 ? CargarInformacionCreate : null} show={CargarInformacionShow} edit={permissions['indicadores4g'] == 1 || permissions['indicadores4g'] == 2 ? CargarInformacionEdit : null} />,
      <Resource name="modelo_costos_pers" list={permissions['reparacionesMenores'] != 3 ? ModeloCostosPersList : NotFound} create={permissions['reparacionesMenores'] != 4 ? ModeloCostosPersCreate : null} edit={permissions['reparacionesMenores'] == 1 || permissions['reparacionesMenores'] == 2 ? ModeloCostosPersEdit : null} show={ModeloCostosPersShow} />,
      <Resource name="flujo_cajas" list={permissions['flujoCaja'] != 3 ? FlujoCajaList : NotFound} create={permissions['flujoCaja'] != 4 ? FlujoCajaCreate : null} show={FlujoCajaShow} edit={permissions['flujoCaja'] == 1 || permissions['flujoCaja'] == 2 ? FlujoCajaEdit : null} />,

      <Resource name="reportes_pers" list={permissions['reportesPersQ'] != 3 ? ReportesPersQuicksight : NotFound} />,
      <Resource name="reportes_inventario" list={permissions['reportesInventarioQ'] != 3 ? ReportesInventarioQuicksight : NotFound} />,
      <Resource name="reportes_indicadores" list={permissions['reportesIndicadoresQ'] != 3 ? ReportesIndicadoresQuicksight : NotFound} />,
      <Resource name="reportes_costos" list={permissions['reportesCostosQ'] != 3 ? ReportesCostosQuicksight : NotFound} />,
      <Resource name="reportes_transito" list={permissions['reportesTransitoQ'] != 3 ? ReportesTransitoQuicksight : NotFound} />,
      <Resource name="reportes_contactos" list={permissions['reportesContactosQ'] != 3 ? ReportesContactosQuicksight : NotFound} />,
      <Resource name="reportes_modelacion" list={permissions['reportesModelacionQ'] != 3 ? ReportesModelacionQuicksight : NotFound} />,
      <Resource name="reportes_novedades" list={permissions['reportesNovedadesQ'] != 3 ? ReportesNovedadesQuicksight : NotFound} />,
      <Resource name="automatizacion" list={AutomatizacionQuicksight} />,
      <Resource name="reports_configuration" list={ConfigQuicksight} />,

      // <Resource name="carga_masiva" list={permissions['cargaMasiva'] != 3 ? CargaMasivaList : NotFound} edit={ permissions['cargaMasiva'] == 1 ? CargaMasivaEdit : null } create={permissions['cargaMasiva'] != 4 ? CargaMasivaCreate : null} />,
      <Resource name="carga_masivas" list={permissions['cargaMasiva'] != 3 ? CargaMasivaList : NotFound} edit={permissions['cargaMasiva'] == 1 || permissions['cargaMasiva'] == 2 ? CargaMasivaEdit:null } create={permissions['cargaMasiva'] != 4 ? CargaMasivaCreate : null} />,


      <Resource name="audits" list={permissions['audit'] != 3 ? AuditList : NotFound} show={AuditShow} />,


      // FALTAN PERMISOS PARA BLOQUEO DE VISTAS IMPORTANTES (Crear, Editar, Mostrar)

      <Resource name="costos" list={ permissions['costo'] != 3 ? CostosList : NotFound } create={ permissions['costo'] != 4 ? CostosCreate : null } show={CostosShow} edit={ permissions['costo'] == 1 || permissions['costo'] == 2 ? CostosEdit : null} />,
      <Resource name="obicacion_senals" />,
      <Resource name="calificacion_proveedors" create={permissions['proveedores'] != 4 ? ProveedorCalificacionCreate : null} />,
      <Resource name="evaluacion_seguridads" list={EvaluacionSVList} create={EvaluacionSVCreate} edit={EvaluacionSVEdit} show={EvaluacionSVShow} />,
      <Resource name="preguntas" list={PreguntasList} edit={PreguntasEdit} />,

      /*CARGA DE INFORMACIÓN INDICADORES COLOMBIA */
      /* ---------------- SEÑALES DE PERÚ  --------------------- */
      <Resource name="dimension_senal_perus" />,
      <Resource name="elemento_senalizacion_perus" list={ElementoSenalizacionPeruList} edit={ElementoSenalizacionPeruEdit} show={ElementoSenalizacionPeruShow} create={ElementoSenalizacionPeruCreate} />,
      <Resource name="tipo_elemento_senal_perus" />,
      <Resource name="tipo_senal_perus" />,
      <Resource name="senal_vertical_perus" />,
      <Resource name="ubicacion_segmentos" />,
      <Resource name="tipo_images" />,

      <Resource name="medido_pors" list={MedidoPorList} create={MedidoPorCreate} edit={MedidoPorEdit} />,
      <Resource name="estado_indicadors" list={EstadoList} create={EstadoCreate} edit={EstadoEdit} />,
      <Resource name="tipo_dominios" list={TipoDominioList} create={TipoDominioCreate} edit={TipoDominioEdit} />,
      <Resource name="fisura_indicadors" list={FisuraList} create={FisuraCreate} edit={FisuraEdit} />,
      <Resource name="calificacion_indicadors" list={CalificacionIndicadorList} create={CalificacionIndicadorCreate} edit={CalificacionIndicadorEdit} />,
      <Resource name="evaluacion_indicadors" list={EvaluacionList} create={EvaluacionCreate} edit={EvaluacionEdit} />,
      <Resource name="niveles_indicadors" list={NivelesList} create={NivelesCreate} edit={NivelesEdit} />,
      <Resource name="elemento_traficos" list={ElementoTraficoList} edit={EditGuesser} show={ShowGuesser} create={ElementoTraficoCreate} />,
      <Resource name="transitos" list={TransitoList} show={TransitoShow} edit={TransitoEdit} create={TransitoCreate} />,
      <Resource name="elemento_calzadas" list={ElementoCalzadaList} edit={ElementoCalzadaEdit} show={ShowGuesser} />,
      <Resource name="elemento_seguridads" list={ElementoSeguridadList} edit={EditGuesser} show={ShowGuesser} create={ElementoSeguridadCreate} />,
      <Resource name="elemento_puentes" list={ElementoPuenteList} edit={EditGuesser} show={ShowGuesser} create={ElementoPuenteCreate} />,
      <Resource name="elemento_otros" list={ElementoOtroList} edit={EditGuesser} show={ShowGuesser} create={ElementoOtroCreate} />,
      <Resource name="elemento_instalacions" list={ElementoInstalacionList} edit={EditGuesser} show={ShowGuesser} create={ElementoInstalacionCreate} />,

      /** Flujo Caja */

      //

      /*-------------------------Elementos de Mantenimiento (deprecated)---------------------- */
      <Resource name="tipo_plans" />,
      <Resource name="entidad_elementos" />,
      /*-------------------------Politicas de Mantenimiento---------------------- */
      <Resource name="unidad_medidas" />,

      <Resource name="politicas_elementos" />,
      <Resource name="politicas_elemento_cambios" />,
      <Resource name="politicas_elemento_periodicos" />,
      <Resource name="politicas_elemento_rehabilitacions" />,
      <Resource name="politicas_nombres" />,

      /*-------------------------Politicas de Mantenimiento particular---------------------- */
      <Resource name="politicas_elemento_particulars" />,
      <Resource name="politicas_elemento_cambio_particulars" />,
      <Resource name="politicas_elemento_periodico_particulars" />,
      <Resource name="politicas_elemento_rehabilitacion_particulars" />,
      <Resource name="politicas_particulares_items" />,
      <Resource name="politicas_nombres" />,
      /*----Elementos de puentes ..::..::.. Superestructura----------------------- */
      <Resource name="superestructuras" />,
      <Resource name="superficies" />,
      <Resource name="transversarls" />,
      <Resource name="longituds" />,
      <Resource name="transversarlas" />,
      <Resource name="longas" />,
      <Resource name="barandas" />,
      <Resource name="expantions" />,
      /*----Elementos de puentes ..::..::.. Subestructura----------------------- */
      <Resource name="subestructuras" />,
      <Resource name="tipoestribos" />,
      <Resource name="estris" />,
      <Resource name="tippilas" />,

      /*------------------- Valicadiconesde codigos --------------------------*/
      <Resource name="codigoRuta" />,
      /*------------------- HISTÓRICO   --------------------------*/
      <Resource name="mensualviews" />,
      <Resource name="validar_rango_pr_abs" />,

      <Resource name="tipo_calzadas" />,
      <Resource name="tipo_segmentos" />,
      <Resource name="indicadors" />,
      <Resource name="valor_indicadors" />,
      <Resource name="tipo_senals" />,
      <Resource name="tipo_elemento_senals" />,
      <Resource name="senals" />,
      <Resource name="fotografias" />,
      <Resource name="licencias" />,
      <Resource name="sistemas" />,
      <Resource name="tipo_elementos" />,
      <Resource name="departamentos" />,
      <Resource name="indicativos" />,
      <Resource name="municipios" />,
      <Resource name="material_estructuras" />,
      <Resource name="tipo_deterioros" />,
      <Resource name="parametros" />,
      <Resource name="variables" />,
      <Resource name="listado_variables" />,
      <Resource name="image_drenajes" />,
      <Resource name="image_bases" />,
      <Resource name="disponibilidad_via_causa_probables"/>,

      /*------------------- INDICADORES COMPLEMENTARIOS   --------------------------*/
      <Resource name="respuesta_evaluacion_complementarios" list={permissions['respuestaEvaluacionComplementario'] != 3 ? RespuestaEvaluacionComplementariosList : NotFound} show={RespuestaEvaluacionComplementariosShow} edit={permissions['respuestaEvaluacionComplementario'] == 1 || permissions['respuestaEvaluacionComplementario'] == 2 ? RespuestaEvaluacionComplementariosEdit : null} />,
      /*------------------- REGISTRO DE NOVEDADES  --------------------------*/
      <Resource name="respuesta_evaluacion_novedads" list={permissions['respuestaEvaluacionNovedad'] != 3 ? RespuestaEvaluacionNovedadsList : NotFound} show={RespuestaEvaluacionNovedadsShow} edit={ permissions['respuestaEvaluacionNovedad'] == 1 || permissions['respuestaEvaluacionNovedad'] == 2 ? RespuestaEvaluacionNovedadesEdit : null } />,
      <Resource name="aprobacions" list={ permissions['aprobacion'] != 3 ? AprobacionsList : NotFound } />,
      <Resource name="logos" list={ permissions['configSistema'] != 3 ? ConfiguracionGeneralList : NotFound } />,

    ]}
  </Admin>
export default App;
