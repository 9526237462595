import React, { useState, Fragment } from 'react';
import {
    FunctionField, List, Datagrid, EditButton, ShowButton, Filter, CardActions, Toolbar, SimpleForm, BooleanInput, DateField, SelectField,
    SelectInput, ReferenceInput, BulkDeleteWithConfirmButton, TextField, AutocompleteInput, CreateButton, DateInput, AutocompleteArrayInput, Loading
} from 'react-admin';

import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { stringify } from 'query-string';
import ListIcon from '@material-ui/icons/List';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import { PRInput } from '../../../inputs/PRInput';
import VisualizacionModal from './../localHelpers/visualizacionModal';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import { translate } from 'react-admin';

const ConfirmarBorrar = (props) => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props} />
    </Fragment>
);
const ListAction = translate(({ basePath, data, resource, ...props }) => {
    const { translate } = props;
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = value => {
        setOpen(false);
    };
    return (
        <CardActions>
            <CreateButton basePath={basePath} style={{ paddingTop: "13px", marginRight: "20px" }} />
            <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                <VisibilityIcon /> {translate('dynatest.resources.costos.modalVisual.btnVisual')}
            </Button>
            <SimpleDialog open={open} onClose={handleClose} />
        </CardActions>
    );
});
const ListAction2 = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} style={{ paddingTop: "13px", marginRight: "20px" }} />
    </CardActions>
);
const ListarPlan = (props) => (
    <Button
        {...props}
        size="small"
        color="primary"
        component={Link}
        to={{
            pathname: '/plan_mantenimiento_detalles',
            search: stringify({
                page: 1,
                perPage: 25,
                sort: 'id',
                order: 'DESC',
                filter: JSON.stringify({ planMantenimientos: props.record.id }),
            }),
        }}
    >
        <ListIcon />
    </Button>
)

const Transition = React.forwardRef(function Transition(props, ref) { return <Slide direction="up" ref={ref} {...props} />; });
const SimpleDialog = translate((props) => {
    const { translate } = props;
    const { onClose, open } = props;
    const handleClose = value => {
        onClose(value);
    };
    const CustomToolbar = ({ basePath, data, ...props }) => (
        <Toolbar {...props} >
            {/* <Button variant="secondary" id="generarInforme" onClick={handleClose} style={{ backgroundColor: '#3f51b5', color: '#fff' }}>
                Cerrar
        </Button> */}
            <span></span>
        </Toolbar>
    );
    const [uf, setUf] = useState('');
    const ufSet = (value) => {
        setUf(value);
    }
    const [tramo, setTramo] = useState('');
    const tramoSet = (value) => {
        setTramo(value);
    }
    const [segmento, setSegmento] = useState('');
    const validarSegmento = (value) => {
        setSegmento(value);
    }
    const [sistema, setSistema] = useState('');
    const validarSistema = (value) => {
        setSistema(value)
    }
    const [tipoElemento, setTipoElemento] = useState('');
    const validarTipoElemento = (value) => {
        setTipoElemento(value)
    }
    const [tipoElementoSenal, setTipoElementoSenal] = useState('');
    const validarTipoElementoSenal = (value) => {
        setTipoElementoSenal(value)
    }
    const [tipoSenal, setTipoSenal] = useState('');
    const validarTipoSenal = (value) => {
        setTipoSenal(value)
    }
    const [porAbscisas, setPorAbscisas] = useState(false);
    const validarPorAbscisas = (value) => {
        setPorAbscisas(value)
    }
    const [abInicio, setAbInicio] = useState('');
    const validarAbscisaInicial = (value) => {
        setAbInicio(value)
    }
    const [abFin, setAbFin] = useState('');
    const validarAbscisaFinal = (value) => {
        setAbFin(value)
    }
    const [fechaInicial, setFechaInicial] = useState('');
    const validarFechaInicial = (value) => {
        setFechaInicial(value)
    }
    const [fechaFinal, setFechaFinal] = useState('');
    const validarFechaFinal = (value) => {
        setFechaFinal(value)
    }
    const [tipoMantenimiento, setTipoMantenimiento] = useState([]);
    const validarMantenimiento = (value) => {
        if (value) { setTipoMantenimiento(value) }
        else { setTipoMantenimiento([]) }
    }
    const [consolidarPor, setConsolidarPor] = useState([]);
    const [search, setSearch] = useState(false);
    const validateSearch = (value) => {
        if (uf && tipoMantenimiento.length > 0) { setSearch(true) }
        else { setSearch(false); snackBarOpener("Al menos la unidad funcional y un tipo de mantenimiento son requeridos") }
    }
    const tipoMantenimientos = [
        { id: "ru", name: "dynatest.resources.costos.select.tipoMantenimientos.Rutinario" },
        { id: "pe", name: "dynatest.resources.costos.select.tipoMantenimientos.Periodico" },
        { id: "re", name: "dynatest.resources.costos.select.tipoMantenimientos.Rehabilitacion" },
        { id: "ca", name: "dynatest.resources.costos.select.tipoMantenimientos.Cambio" },
    ];

    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [snackBarMessage, setSnackBarMessage] = useState("Al menos la unidad funcional y un tipo de mantenimiento son requeridos");
    const snackBarOpener = (message) => {
        setSnackBarMessage(message)
        setSnackBarOpen(true);
    };
    const snackBarClose = () => {
        setSnackBarOpen(false);
    };
    const [proveedor, setProveedor] = useState(undefined);
    const validarProveedor = (value) => {
        setProveedor(value)
    }
    const limpiarFechaInicial = value => {
        validarFechaInicial(null);
    }
    const limpiarFechaFinal = value => {
        validarFechaFinal(null);
    }
    const limpiarProveedor = value => {
        validarProveedor(null);
    }
    return (
        <Fragment>
            <Dialog fullScreen onClose={handleClose} aria-labelledby="simple-dialog-title" TransitionComponent={Transition} open={open}>
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={snackBarOpen}
                    onClose={snackBarClose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{snackBarMessage}</span>}
                />
                <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close" style={{ textAlign: "right" }}>
                    <CloseIcon />
                </IconButton>
                <DialogTitle id="simple-dialog-title">{translate('dynatest.resources.costos.modal.visualizarCostos')}</DialogTitle>
                <SimpleForm toolbar={<CustomToolbar />}>
                    <Grid container spacing={24} alignItems="flex-end">
                        <Grid item xs={3}>
                            <ReferenceInput
                                id="unidadFuncional"
                                label="resources.costos.fields.unidadFuncional"
                                source="unidadFuncional"
                                filter={{ pagination: "off" }}
                                reference="unidad_funcionals"
                                filterToQuery={searchText => ({ fullCodigos: searchText })}
                                sort={{ field: 'fullCodigos', order: 'asc' }}
                                validate={[ufSet]}
                                alwaysOn
                                fullWidth
                            >
                                <SelectInput resettable options={{ fullWidth: true }} optionText="fullCodigos" />
                            </ReferenceInput>
                        </Grid>
                        <Grid item xs={3}>
                            <ReferenceInput
                                label="resources.costos.fields.tramo"
                                source="tramo"
                                alwaysOn
                                reference="tramos"
                                filter={{ unidadFuncional: uf, pagination: "off" }}
                                validate={[tramoSet]}
                                filterToQuery={searchText => ({ fullCodigos: searchText })}
                                sort={{ field: 'fullCodigos', order: 'ASC', }}
                            >
                                <SelectInput resettable options={{ fullWidth: true }} optionText="fullCodigos" />
                            </ReferenceInput>
                        </Grid>
                        <Grid item xs={3}>
                            <BooleanInput
                                label="dynatest.resources.costos.boolean.rango"
                                source="rango"
                                validate={[validarPorAbscisas]}
                                defaultValue={porAbscisas}
                            />
                        </Grid>
                        <Grid item xs={3}></Grid>
                        {porAbscisas === false &&
                            <Grid item xs={3}>
                                <ReferenceInput
                                    label="resources.costos.fields.segmento"
                                    source="segmento"
                                    reference="segmentos"
                                    sort={{ field: 'fullCodigos', order: 'ASC' }}
                                    filter={{ tramo: tramo, pagination: "off" }}
                                    filterToQuery={searchText => ({ fullCodigos: searchText })}
                                    validate={[validarSegmento]}
                                >
                                    <SelectInput resettable options={{ fullWidth: true }} optionText="fullCodigos" />
                                </ReferenceInput>
                            </Grid>
                        }
                        {porAbscisas === true &&
                            <Fragment>
                                <Grid item xs={3}>
                                    <PRInput
                                        label="resources.costos.fields.abInicio"
                                        source="abInicio"
                                        fullWidth
                                        validate={[validarAbscisaInicial]}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <PRInput
                                        label="resources.costos.fields.abFin"
                                        source="abFin"
                                        fullWidth
                                        validate={[validarAbscisaFinal]}
                                    />
                                </Grid>


                            </Fragment>
                        }
                        <Grid item xs={3}>
                            <ReferenceInput
                                label="resources.costos.fields.sistema"
                                source="sistema"
                                reference="sistemas"
                                filterToQuery={searchText => ({ nombre: searchText })}
                                validate={[validarSistema]}
                                filter={{ visibleOnPolicies: 1 }}
                            >
                                <SelectInput resettable optionText="nombreEnPoliticas" fullWidth />
                            </ReferenceInput>
                        </Grid>
                        {sistema != "/sistemas/SEN" &&
                            <Grid item xs={3}>
                                <ReferenceInput
                                    label="resources.costos.fields.tipoElemento"
                                    source="tipoElemento"
                                    reference="tipo_elementos"
                                    sort={{ field: 'nombre', order: 'ASC' }}
                                    filter={{
                                        sistema: sistema
                                    }}
                                    filterToQuery={searchText => ({ nombre: searchText })}
                                    fullWidth
                                    validate={[validarTipoElemento]}
                                >
                                    <SelectInput resettable optionText="nombre" fullWidth />
                                </ReferenceInput>
                            </Grid>
                        }
                        {sistema == "/sistemas/SEN" &&
                            <Grid item xs={3}>
                                <ReferenceInput
                                    label="resources.costos.fields.tipoElementoSenal"
                                    source="tipoElementoSenal"
                                    reference="tipo_elemento_senals"
                                    sort={{ field: 'nombre', order: 'ASC' }}
                                    filterToQuery={searchText => ({ nombre: searchText })}
                                    validate={[validarTipoElementoSenal]}
                                    fullWidth
                                >
                                    <SelectInput resettable optionText="nombre" fullWidth />
                                </ReferenceInput>
                            </Grid>
                        }
                        {sistema == "/sistemas/SEN" &&
                            <Grid item xs={3}>
                                <ReferenceInput
                                    label="resources.costos.fields.tipoSenal"
                                    source="tipoSenal"
                                    reference="tipo_senals"
                                    sort={{ field: 'nombre', order: 'ASC' }}
                                    filter={{
                                        tipoElementoSenal: tipoElementoSenal
                                    }}
                                    filterToQuery={searchText => ({ nombre: searchText })}
                                    validate={[validarTipoSenal]}
                                    fullWidth
                                >
                                    <SelectInput resettable optionText="nombre" fullWidth />
                                </ReferenceInput>
                            </Grid>
                        }
                        <Grid item xs={2}>
                            <DateInput
                                label="resources.costos.fields.fechaInicial"
                                source="fechaInicial"
                                fullWidth
                                validate={[validarFechaInicial]}
                                defaultValue={fechaInicial}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                <Button title={'Limpiar Fecha Inicial'} onClick={limpiarFechaInicial}>X</Button>
                            </div>
                        </Grid>
                        <Grid item xs={2}>
                            <DateInput
                                label="resources.costos.fields.fechaFinal"
                                source="fechaFinal"
                                fullWidth
                                validate={[validarFechaFinal]}
                                defaultValue={fechaFinal}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                <Button title={'Limpiar Fecha Final'} onClick={limpiarFechaFinal}>X</Button>
                            </div>
                        </Grid>
                        <Grid item xs={2}>
                            <ReferenceInput
                                label="resources.costos.fields.proveedor"
                                source="proveedor"
                                reference="proveedors"
                                filter={{
                                    proyecto: localStorage.getItem("id_project"),
                                    suministros: 1,
                                    sistema: typeof sistema == "string" ? sistema.split("/")[2] : sistema
                                }}
                                validate={[validarProveedor]}
                                fullWidth
                                filterToQuery={searchText => ({ razonSocial: searchText })}
                                defaultValue={proveedor}
                            >
                                <AutocompleteInput
                                    options={{ fullWidth: true, suggestionsContainerProps: { style: { zIndex: 10000 } } }}
                                    optionText="razonSocial"
                                    fullWidth
                                    resettable
                                />
                            </ReferenceInput>
                        </Grid>
                        <Grid item xs={1}>
                            <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                <Button title={'Limpiar Proveedor'} onClick={limpiarProveedor}>X</Button>
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <SelectInput
                                label="resources.costos.fields.consolidaror"
                                source="consolidaror"
                                fullWidth
                                resettable
                                validate={[value => setConsolidarPor(value)]}
                                choices={[
                                    { id: "day/month/year", name: "Por Dia" },
                                    { id: "month/year", name: "Por Mes" },
                                    { id: "year", name: "Por Año" }
                                ]}
                                defaultValue="month/year"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <AutocompleteArrayInput
                                resettable
                                label="resources.costos.fields.tipoMantenimiento"
                                source="tipoMantenimiento"
                                fullWidth
                                validate={[validarMantenimiento]}
                                choices={tipoMantenimientos}
                                defaultValue={["ru", "pe", "re", "ca"]}
                                options={{ suggestionsContainerProps: { style: { zIndex: 10000 } } }}
                            />
                        </Grid>
                        {!search &&
                            <Grid item xs={12}>
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Button onClick={validateSearch}>Buscar Elementos <SearchIcon /></Button>
                                </div>
                            </Grid>
                        }
                        {search &&
                            <Grid item xs={12}>
                                <VisualizacionModal
                                    unidadFuncional={uf}
                                    tramo={tramo}
                                    segmento={segmento}
                                    sistema={sistema}
                                    tipoElemento={(sistema != "/sistemas/SEN") ? tipoElemento : null}
                                    tipoElementoSenal={(sistema == "/sistemas/SEN") ? tipoElementoSenal : null}
                                    tipoSenal={(sistema == "/sistemas/SEN") ? tipoSenal : null}
                                    abInicio={abInicio}
                                    abFin={abFin}
                                    tipoMantenimiento={tipoMantenimiento}
                                    fechaInicial={fechaInicial}
                                    fechaFinal={fechaFinal}
                                    proveedor={proveedor}
                                    consolidarPor={consolidarPor}
                                />
                            </Grid>
                        }
                    </Grid>
                </SimpleForm>
            </Dialog>

        </Fragment>

    )
});

const Filtro = (props) => {
    const [uf, setUf] = useState('none');
    const ufSet = (value) => {
        setUf(value);
    }
    const [tramo, setTramo] = useState('none');
    const tramoSet = (value) => {
        setTramo(value);
    }
    const [sistema, setSistema] = useState('');
    const validarSistema = (value) => {
        setSistema(value)
        console.log(sistema)
    }
    const [tipoElemento, setTipoElemento] = useState('');
    const validarTipoElemento = (value) => {
        setTipoElemento(value)
    }
    const [tipoElementoSenal, setTipoElementoSenal] = useState('');
    const validarTipoElementoSenal = (value) => {
        setTipoElementoSenal(value)
    }
    const [tipoSenal, setTipoSenal] = useState('');
    const validarTipoSenal = (value) => {
        setTipoSenal(value)
    }

    return (
        <Filter {...props}>
            <ReferenceInput
                id="unidadFuncional"
                label="resources.costos.fields.unidadFuncional"
                source="unidadFuncional"
                reference="unidad_funcionals"
                filterToQuery={searchText => ({ codigo: searchText })}
                sort={{ field: 'codigo', order: 'asc' }}
                validate={[ufSet]}
                alwaysOn
                fullWidth
            >
                <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
            </ReferenceInput>
            <ReferenceInput
                label="resources.costos.fields.tramo"
                source="tramo"
                alwaysOn
                reference="tramos"
                filter={{ unidadFuncional: uf }}
                validate={[tramoSet]}
                filterToQuery={searchText => ({ codigo: searchText })}
                sort={{
                    field: 'codigo',
                    order: 'ASC',

                }}>
                <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
            </ReferenceInput>

            <ReferenceInput
                label="resources.costos.fields.segmento"
                source="segmento"
                reference="segmentos"
                alwaysOn
                sort={{ field: 'codigo', order: 'ASC' }}
                filter={{ tramo: tramo }}
                filterToQuery={searchText => ({ codigo: searchText })}
            >
                <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
            </ReferenceInput>
            <ReferenceInput
                label="resources.costos.fields.sistema"
                source="sistema"
                reference="sistemas"
                filterToQuery={searchText => ({ nombre: searchText })}
                validate={[validarSistema]}
                alwaysOn
                filter={{ visibleOnPolicies: 1 }}
            >
                <SelectInput optionText="nombre" fullWidth />
            </ReferenceInput>
            {sistema != "/sistemas/SEN" &&
                <Grid item xs={3}>
                    <ReferenceInput
                        label="resources.costos.fields.tipoElemento"
                        source="tipoElemento"
                        reference="tipo_elementos"
                        sort={{ field: 'nombre', order: 'ASC' }}
                        filter={{
                            sistema: sistema
                        }}
                        filterToQuery={searchText => ({ nombre: searchText })}
                        fullWidth
                        validate={[validarTipoElemento]}
                        alwaysOn
                    >
                        <SelectInput optionText="nombre" fullWidth />
                    </ReferenceInput>
                </Grid>
            }
            {sistema == "/sistemas/SEN" &&
                <Grid item xs={3}>
                    <ReferenceInput
                        label="resources.costos.fields.tipoElementoSenal"
                        source="tipoElementoSenal"
                        reference="tipo_elemento_senals"
                        sort={{ field: 'nombre', order: 'ASC' }}
                        filterToQuery={searchText => ({ nombre: searchText })}
                        validate={[validarTipoElementoSenal]}
                        fullWidth
                        alwaysOn
                    >
                        <SelectInput optionText="nombre" fullWidth />
                    </ReferenceInput>
                </Grid>
            }
            {sistema == "/sistemas/SEN" &&
                <Grid item xs={3}>
                    <ReferenceInput
                        label="resources.costos.fields.tipoSenal"
                        source="tipoSenal"
                        reference="tipo_senals"
                        sort={{ field: 'nombre', order: 'ASC' }}
                        filter={{
                            tipoElementoSenal: tipoElementoSenal
                        }}
                        filterToQuery={searchText => ({ nombre: searchText })}
                        validate={[validarTipoSenal]}
                        fullWidth
                        alwaysOn
                    >
                        <SelectInput optionText="nombre" fullWidth />
                    </ReferenceInput>
                </Grid>
            }
        </Filter>
    )
};

const numberFixer = (value) => {
    if (value % 1 == 0) {
        return value
    } else {
        return value.toFixed(2)
    }
}

export const CostosList = ({ permissions, ...props }) => {
    if (!permissions) return <Loading />;
    return (
    <List {...props}
        sort={{ field: 'id', order: 'DESC' }}
        filters={<Filtro />}
        bulkActionButtons={permissions['costo'] == 1 ? <ConfirmarBorrar /> : null}
        actions={permissions['costo']  == 1 ? <ListAction /> : permissions['costo']  == 2 ? <ListAction2 />: null} 
    >
        <Datagrid>
            <FunctionField
                label="resources.costos.fields.id"
                source="id"
                render={record => record.id.split("/")[2]}
                linkType={false}
                fullWidth
            />
            <TextField
                label="resources.costos.fields.unidadFuncional"
                source="unidadFuncional_.fullCodigos"
                linkType={false}
                fullWidth
            />
            <TextField
                label="resources.costos.fields.tramo"
                source="tramo_.fullCodigos"
                linkType={false}
                fullWidth
            />
            <TextField
                label="resources.costos.fields.segmento"
                source="segmento_.fullCodigos"
                linkType={false}
                fullWidth
            />
            <TextField
                label="resources.costos.fields.tipoSenal"
                source="genericTipoElemento"
                linkType={false}
                fullWidth
            />
            <DateField
                label="resources.costos.fields.fecha"
                source="costoElementos[0].fechaMantenimiento"
                fullWidth
            />
            <FunctionField
                label="resources.costos.fields.costoTotal"
                source="costoTotal"
                fullWidth
                render={record => `$${numberFixer(record.costoTotal)}`}
                style={{ textAlign: "right" }}
            />
            <SelectField
                label="resources.costos.fields.tipoMantenimiento"
                source="costoElementos[0].tipoMantenimiento"
                choices={[
                    { id: "ru", name: "dynatest.resources.costos.select.tipoMantenimientos.Rutinario" },
                    { id: "pe", name: "dynatest.resources.costos.select.tipoMantenimientos.Periodico" },
                    { id: "re", name: "dynatest.resources.costos.select.tipoMantenimientos.Rehabilitacion" },
                    { id: "ca", name: "dynatest.resources.costos.select.tipoMantenimientos.Cambio" }
                ]}
            />
            {permissions['costo'] == 1 || permissions['costo'] == 2 || permissions['costo'] == 4 ? <ShowButton/>: null}
            {permissions['costo'] == 1 || permissions['costo'] == 2 ? <EditButton/>: null}
        </Datagrid>
    </List>
)};
