import React from 'react';
import { TextField, NumberField,BooleanField,SelectField} from 'react-admin';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Switch from '@material-ui/core/Switch';
import config from '../../config/config';
let urlAppjs = config.UrlServer;

class TablaCalificacion extends React.Component{

    constructor(props){
        super(props)
        this.state = { TablaEstructura: [] }
       
      
    }

    componentWillMount() {
        var urlApi = urlAppjs+"/"+this.props.tabla+"/"+this.props.id;
        
        fetch(urlApi)
          .then((response) => {
            return response.json()
          })
          .then((TablaEstructura) => {
            this.setState({ TablaEstructura: TablaEstructura })
          })
      }

      render() {
        let Datos = Object.values(this.state.TablaEstructura); 

        console.log(Datos);
        if (Datos.length > 0) {
          return (
            <Table  aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Componente</TableCell>
                <TableCell align="right">Presencia de daños</TableCell>
                <TableCell align="right">Aplica</TableCell>
                <TableCell align="right">Cantidad</TableCell>
                <TableCell align="right">Elemento</TableCell>
                <TableCell align="right">Fotografías</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              
            <TableRow >
                <TableCell component="th"  rowSpan={5}>
                Superficie del puente
                  </TableCell>
                  <TableCell align="right">Grietas</TableCell>
                  <TableCell align="right"> 
                    <Switch checked={Datos[71]}  color="primary" />
                  </TableCell>
                  <TableCell align="right">{Datos[7]}</TableCell>
                  <TableCell align="right">{Datos[70]}</TableCell>
                  <TableCell align="right"></TableCell>
                  
                </TableRow>
              
                <TableRow >
                <TableCell component="th">
                Dañada
                  </TableCell>
                 
                  <TableCell align="right"><Switch checked={Datos[72]}  color="primary" /></TableCell>
                  <TableCell align="right">{Datos[8]}</TableCell>
                  <TableCell align="right">{Datos[38]}</TableCell>
                  <TableCell align="right"></TableCell>
                  
                </TableRow>
                <TableRow >
                <TableCell component="th">
                Desconchados
                  </TableCell>
                  
                  <TableCell align="right">  <Switch checked={Datos[73]}  color="primary" /></TableCell>
                  <TableCell align="right">{Datos[9]}</TableCell>
                  <TableCell align="right">{Datos[39]}</TableCell>
                  <TableCell align="right"></TableCell>
                  
                </TableRow>
                <TableRow >
                <TableCell component="th">
                Total - suma
                  </TableCell>
                  
                  <TableCell align="right">  <Switch checked={Datos[74]}  color="primary" /></TableCell>
                  <TableCell align="right">{Datos[10]}</TableCell>
                  <TableCell align="right">{Datos[40]}</TableCell>
                  <TableCell align="right"></TableCell>
                  
                </TableRow>
                <TableRow >
                <TableCell component="th">
                %Área afectada
                  </TableCell>
                  
                  <TableCell align="right">  <Switch checked={Datos[75]}  color="primary" /></TableCell>
                  <TableCell align="right">{Datos[11]}</TableCell>
                  <TableCell align="right">{Datos[41]}</TableCell>
                  <TableCell align="right"></TableCell>
                  
                </TableRow>
                <TableRow >
                <TableCell component="th"  rowSpan={4}>
                Juntas de expansión
                  </TableCell>
                  <TableCell align="right">Obstrucción del sello</TableCell>
                  <TableCell align="right">  <Switch checked={Datos[76]}  color="primary" /></TableCell>
                  <TableCell align="right">{Datos[12]}</TableCell>
                  <TableCell align="right">{Datos[42]}</TableCell>
                  <TableCell align="right"></TableCell>
                  
                </TableRow>
              
                <TableRow >
                <TableCell component="th">
                Ruptura del sello
                  </TableCell>
                 
                  <TableCell align="right">  <Switch checked={Datos[77]}  color="primary" /></TableCell>
                  <TableCell align="right">{Datos[13]}</TableCell>
                  <TableCell align="right">{Datos[43]}</TableCell>
                  <TableCell align="right"></TableCell>
                  
                </TableRow>
                <TableRow >
                <TableCell component="th">
                Ausencia del sello
                  </TableCell>
                  
                  <TableCell align="right">  <Switch checked={Datos[78]}  color="primary" /></TableCell>
                  <TableCell align="right">{Datos[14]}</TableCell>
                  <TableCell align="right">{Datos[44]}</TableCell>
                  <TableCell align="right"></TableCell>
                  
                </TableRow>
                <TableRow >
                <TableCell component="th">
                No funcional
                  </TableCell>
                  
                  <TableCell align="right">  <Switch checked={Datos[79]}  color="primary" /></TableCell>
                  <TableCell align="right">{Datos[15]}</TableCell>
                  <TableCell align="right">{Datos[45]}</TableCell>
                  <TableCell align="right"></TableCell>
                  
                </TableRow>
                <TableRow >
                <TableCell component="th"  rowSpan={3}>
                Andenes/Bordillos
                  </TableCell>
                  <TableCell align="right">Pérdidas</TableCell>
                  <TableCell align="right">  <Switch checked={Datos[80]}  color="primary" /></TableCell>
                  <TableCell align="right">{Datos[16]}</TableCell>
                  <TableCell align="right">{Datos[46]}</TableCell>
                  <TableCell align="right"></TableCell>
                  
                </TableRow>
                <TableRow >
                <TableCell component="th">
                Grietas
                  </TableCell>
                  
                  <TableCell align="right">  <Switch checked={Datos[81]}  color="primary" /></TableCell>
                  <TableCell align="right">{Datos[17]}</TableCell>
                  <TableCell align="right">{Datos[47]}</TableCell>
                  <TableCell align="right"></TableCell>
                  
                </TableRow>
                <TableRow >
                <TableCell component="th">
                Desconchados
                  </TableCell>
                  
                  <TableCell align="right">  <Switch checked={Datos[82]}  color="primary" /></TableCell>
                  <TableCell align="right">{Datos[18]}</TableCell>
                  <TableCell align="right">{Datos[48]}</TableCell>
                  <TableCell align="right"></TableCell>
                  
                </TableRow>
                <TableRow >
                <TableCell component="th"  rowSpan={4}>
                Barreras de seguridad/barandillas de puentes/pretiles metálicos
                  </TableCell>
                  <TableCell align="right">Rotura o daños</TableCell>
                  <TableCell align="right">  <Switch checked={Datos[83]}  color="primary" /></TableCell>
                  <TableCell align="right">{Datos[19]}</TableCell>
                  <TableCell align="right">{Datos[49]}</TableCell>
                  <TableCell align="right"></TableCell>
                  
                </TableRow>
                <TableRow >
                <TableCell component="th">
                Conexiones en mal estado
                  </TableCell>
                  
                  <TableCell align="right">  <Switch checked={Datos[84]}  color="primary" /></TableCell>
                  <TableCell align="right">{Datos[20]}</TableCell>
                  <TableCell align="right">{Datos[50]}</TableCell>
                  <TableCell align="right"></TableCell>
                  
                </TableRow>
                <TableRow >
                <TableCell component="th">
                Protecciones en mal estado
                  </TableCell>
                  
                  <TableCell align="right">  <Switch checked={Datos[85]}  color="primary" /></TableCell>
                  <TableCell align="right">{Datos[21]}</TableCell>
                  <TableCell align="right">{Datos[51]}</TableCell>
                  <TableCell align="right"></TableCell>
                  
                </TableRow>
                <TableRow >
                <TableCell component="th">
                Grietas >5mm o desconchados >10mm
                  </TableCell>
                  
                  <TableCell align="right">  <Switch checked={Datos[86]}  color="primary" /></TableCell>
                  <TableCell align="right">{Datos[22]}</TableCell>
                  <TableCell align="right">{Datos[52]}</TableCell>
                  <TableCell align="right"></TableCell>
                  
                </TableRow>
                <TableRow >
                <TableCell component="th"  rowSpan={7}>
                Subestructura
                  </TableCell>
                  <TableCell align="right">Desconchados</TableCell>
                  <TableCell align="right">  <Switch checked={Datos[87]}  color="primary" /></TableCell>
                  <TableCell align="right">{Datos[23]}</TableCell>
                  <TableCell align="right">{Datos[53]}</TableCell>
                  <TableCell align="right"></TableCell>
                  
                </TableRow>
                <TableRow >
                <TableCell component="th">
                Grietas
                  </TableCell>
                  
                  <TableCell align="right">  <Switch checked={Datos[88]}  color="primary" /></TableCell>
                   <TableCell align="right">{Datos[24]}</TableCell>
                  <TableCell align="right">{Datos[54]}</TableCell>
                  <TableCell align="right"></TableCell>
                  
                </TableRow>
                <TableRow >
                <TableCell component="th">
                Erosión
                  </TableCell>
                  
                  <TableCell align="right">  <Switch checked={Datos[89]}  color="primary" /></TableCell>
                  <TableCell align="right">{Datos[25]}</TableCell>
                  <TableCell align="right">{Datos[55]}</TableCell>
                  <TableCell align="right"></TableCell>
                  
                </TableRow>
                <TableRow >
                <TableCell component="th">
                Deterioros en apoyos
                  </TableCell>
                  
                  <TableCell align="right">  <Switch checked={Datos[90]}  color="primary" /></TableCell>
                  <TableCell align="right">{Datos[26]}</TableCell>
                  <TableCell align="right">{Datos[56]}</TableCell>
                  <TableCell align="right"></TableCell>
                  
                </TableRow>
                <TableRow >
                <TableCell component="th">
                Elemento con descalce o mal estado
                  </TableCell>
                  
                  <TableCell align="right">  <Switch checked={Datos[91]}  color="primary" /></TableCell>
                  <TableCell align="right">{Datos[27]}</TableCell>
                  <TableCell align="right">{Datos[57]}</TableCell>
                  <TableCell align="right"></TableCell>
                  
                </TableRow>
                <TableRow >
                <TableCell component="th">
                Deterioros en rótulas
                  </TableCell>
                  
                  <TableCell align="right">  <Switch checked={Datos[92]}  color="primary" /></TableCell>
                  <TableCell align="right">{Datos[28]}</TableCell>
                  <TableCell align="right">{Datos[58]}</TableCell>
                  <TableCell align="right"></TableCell>
                  
                </TableRow>
                <TableRow >
                <TableCell component="th">
                Daños estructurales
                  </TableCell>
                  
                  <TableCell align="right">  <Switch checked={Datos[93]}  color="primary" /></TableCell>
                  <TableCell align="right">{Datos[29]}</TableCell>
                  <TableCell align="right">{Datos[59]}</TableCell>
                  <TableCell align="right"></TableCell>
                  
                </TableRow>
                <TableRow >
                <TableCell component="th"  rowSpan={2}>
                Drenaje
                  </TableCell>
                  <TableCell align="right">Superficie erosionada</TableCell>
                  <TableCell align="right">  <Switch checked={Datos[94]}  color="primary" /></TableCell>
                  <TableCell align="right">{Datos[30]}</TableCell>
                  <TableCell align="right">{Datos[60]}</TableCell>
                  <TableCell align="right"></TableCell>
                  
                </TableRow>
                <TableRow >
                <TableCell component="th">
                Daños Daño estructural
                  </TableCell>
                  
                  <TableCell align="right">  <Switch checked={Datos[95]}  color="primary" /></TableCell>
                  <TableCell align="right">{Datos[31]}</TableCell>
                  <TableCell align="right">{Datos[61]}</TableCell>
                  <TableCell align="right"></TableCell>
                  
                </TableRow>
                <TableRow >
                <TableCell component="th"  rowSpan={6}>
                Puente en general
                  </TableCell>
                  <TableCell align="right">Armadura descubierta</TableCell>
                  <TableCell align="right">  <Switch checked={Datos[96]}  color="primary" /></TableCell>
                  <TableCell align="right">{Datos[32]}</TableCell>
                  <TableCell align="right">{Datos[62]}</TableCell>
                  <TableCell align="right"></TableCell>
                  
                </TableRow>
                <TableRow >
                <TableCell component="th">
                Daños estructurales
                  </TableCell>
                  
                  <TableCell align="right">  <Switch checked={Datos[97]}  color="primary" /></TableCell>
                  <TableCell align="right">{Datos[33]}</TableCell>
                  <TableCell align="right">{Datos[63]}</TableCell>
                  <TableCell align="right"></TableCell>
                  
                </TableRow>
                <TableRow >
                <TableCell component="th">
                Concretos metálicos en mal estado
                  </TableCell>
                  
                  <TableCell align="right">  <Switch checked={Datos[98]}  color="primary" /></TableCell>
                  <TableCell align="right">{Datos[34]}</TableCell>
                  <TableCell align="right">{Datos[64]}</TableCell>
                  <TableCell align="right"></TableCell>
                  
                </TableRow>
                <TableRow >
                <TableCell component="th">
                Elementos de unión en mal estado
                  </TableCell>
                  
                  <TableCell align="right">  <Switch checked={Datos[99]}  color="primary" /></TableCell>
                  <TableCell align="right">{Datos[35]}</TableCell>
                  <TableCell align="right">{Datos[65]}</TableCell>
                  <TableCell align="right"></TableCell>
                  
                </TableRow>
                <TableRow >
                <TableCell component="th">
                Superficie con corrosión
                  </TableCell>
                  
                  <TableCell align="right">  <Switch checked={Datos[100]}  color="primary" /></TableCell>
                  <TableCell align="right">{Datos[36]}</TableCell>
                  <TableCell align="right">{Datos[66]}</TableCell>
                  <TableCell align="right"></TableCell>
                  
                </TableRow>
                <TableRow >
                <TableCell component="th">
                Pintura en malas condiciones
                  </TableCell>
                  
                  <TableCell align="right">  <Switch checked={Datos[101]}  color="primary" /></TableCell>
                  <TableCell align="right">{Datos[37]}</TableCell>
                  <TableCell align="right">{Datos[67]}</TableCell>
                  <TableCell align="right"></TableCell>
                  
                </TableRow>
                
                
              
            </TableBody>
          </Table> 
          )
        } else {           
          return (
            <p className="text-center">Cargando Tabla...</p>            
          )
        }

      }
}

export default TablaCalificacion;