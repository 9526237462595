import React, { Component, Fragment } from 'react';
import { addField } from 'ra-core';
import GMap from './GMap';

export const getMarkers = (input) => input.value || null;

export const getPosition = e => ({
  lng: e.latLng.lng(),
  lat: e.latLng.lat()
})
export class GMapInputInt extends Component {
  constructor(props) {
    super(props);
    if(props.input.value[1]){
      if(props.input.value[1].lng == null){
        props.input.value[1].lng = props.input.value[0].lng;
      }
    }
    if(props.input.value[1]){
      if(props.input.value[1].lat == null){
        props.input.value[1].lat = props.input.value[0].lat;
      }
    }
    this.state = {
      center: props.defaultCenter != null ? props.defaultCenter : { lat: 4.7567039, lng: -74.0311056 },
      zoom: props.defaultZoom != null ? props.defaultZoom : 12,
      input: props.input != null ? props.input : [{ lat: 4.7567039, lng: -74.0311056 }, { lat: 4.99999, lng: -75.99999 }]
    }
    // this.GoogleMapstoken = "AIzaSyDnpZTyYCu_WV19ztwMGY90hvOQ-nEcLXs"; //old
    this.GoogleMapstoken = "AIzaSyAgv5BQ_0nFzgvvOrEun9MK_pQOzJB5-sE"; //new

    this.putMarker = ({ markerPos, input, multipleMarkers }) => {
      const currentValue = getMarkers(input);
      if (multipleMarkers) {
        if (currentValue && currentValue !== null) {
          return input.onChange([...currentValue, markerPos])
        }
        return input.onChange([markerPos]);
      }
      return input.onChange(markerPos);
    }

    this.setCenter = markerPos => this.setState({ center: markerPos });

    this.deleteMarker = ({ markerPos, input, multipleMarkers }) => {
      const currentValue = getMarkers(input); //----------------------- UBICACIONES
      let newValue;
      if (multipleMarkers) {
        newValue = currentValue.filter(mrk =>
          mrk.lat !== markerPos.lat &&
          mrk.lng !== markerPos.lng
        );
        if (!newValue.length) {
          newValue = null;
        }
      } else { newValue = null; };
      input.onChange(newValue);
    }

  }

  componentDidMount() {
    const markers = getMarkers(this.props.input); //----------------------- UBICACIONES
    if (markers) {
      if (markers instanceof Array) {
        this.setState({ center: markers[markers.length - 1] });
      } else {
        this.setState({ center: markers });
      }
    }
  }

  render() {
    const {
      googleKey,
      input,
      multipleMarkers,
      icon,
    } = this.props;


    /**
     * Es posible consultar la URL de google maps para determinar si está en linea y condicionar el return del mapa
     */


    const childrenProps = {
      input,
      markers: getMarkers(input),
      multipleMarkers,
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={{ height: `450px` }} />,
      googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${this.GoogleMapstoken}&v=3.exp&libraries=geometry,drawing,places`,
      icon,

    };

    return (
      <Fragment>
        <div style={{ position: `relative` }}>
          <GMap
            mapElement={<div style={{ height: `100%` }} />}
            defaultCenter={this.state.center}
            defaultZoom={this.state.zoom}
            iconMap={icon}
            // onMapClick={e =>
            //   this.putMarker({
            //     input,
            //     multipleMarkers,
            //     markerPos: getPosition(e),

            //   })}
            // onMarkerClick={e => this.deleteMarker({
            //   input,
            //   multipleMarkers,
            //   markerPos: getPosition(e),

            // })}
            {...childrenProps}
          />
        </div>
      </Fragment>
    )
  }
}

export const GMapInput = addField(GMapInputInt);