import React, {useState, Fragment, useEffect} from 'react';
import {  DisabledInput,  TabbedShowLayout ,Toolbar, Tab, Datagrid, ShowController, Query, Loading, Error, TextInput,
   ShowView,TextField, DateField,ReferenceField, ReferenceInput, BooleanField, required, AutocompleteInput, FormTab, ArrayField,ChipField,SingleFieldList,NumberField,ListButton,EditButton } from 'react-admin';
import { Show } from './../Show';
import { TabbedForm } from './../TabbedForm';
import { PRField } from './../../fields/PRField';
import { FField } from './../../fields/FField';
import { PRInput } from './../../inputs/PRInput';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import AsociarServicios from './serviciosAsociados';
import { ButtonCarousel } from './../images/ButtonCarousel';
import { translate } from 'react-admin';
import config from '../../config/config';
let urlAppjs = config.UrlServer;

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <span>.</span>
        
    </Toolbar>
));

const styles = {
   ocultar: {display:"none",    },
   grid_cont2:{display: 'inline-block!important', width: '20%!important', marginLeft: '10px!important', whiteSpace: 'nowrap!important' },
   grid_cont3:{display: 'inline-block!important', width: '24%!important', marginLeft: '10px!important', whiteSpace: 'nowrap!important' },
   grid_cont4:{width: '32%!important', marginLeft: '10px!important', whiteSpace: 'nowrap!important' },
   grid_cont4_nw:{width: '32%!important', marginLeft: '10px!important', whiteSpace: 'nowrap!important' },
   grid_cont6:{display: 'inline-block!important', width: '49%!important', marginLeft: '10px!important', whiteSpace: 'nowrap!important' },
   grid_cont6_nw:{display: 'inline-block!important', width: '49%!important', marginLeft: '10px!important', whiteSpace: 'nowrap!important' },
   grid_cont12:{display: 'inline-block!important', width: '99%!important', marginLeft: '10px!important', wordBreak: 'break-all', whiteSpace: 'nowrap!important' },
   "& div:empty":{display:'none'},
   grid_cont1:{display: 'inline-block!important', width: '18%!important', marginLeft: '10px!important', whiteSpace: 'nowrap!important' },
   grid_cont7:{width:'100%!important'},
};
export const DisponibilidadShow = translate(withStyles(styles)(({ classes, ...props }) => {
   const { translate } = props;
   const [heridostotal, setHeridostotal] = useState(0);
    const [estado, setEstado] = useState('');
    const [abValidaini, setAbValidaini] = useState('');
    const [abValidafin, setAbValidafin] = useState('');
    const [unidad, setUnidad] = useState('');
    const [longitudafect, setLongitud] = useState('');
    const [causa, setCausa] = useState('');
    const [longcl, setLongcl] = useState('');
    const [elementoaf, setElementoaf] = useState('');
    const [volumenderr, setVolumenderr] = useState('');
    const [condicionesesp, setCondicionesesp] = useState('');
    const [tiempodespeje, setTiempodespeje] = useState('');
    const [unidadfun, setUnidadfun] = useState('');
    const [abingini, setAbingini] = useState('');
    const [abingfin, setAbingfin] = useState('');
    const [segmentosafectados, setSegmentosafectados] = useState('');
    const [segmentosafectadosid, setSegmentosafectadosid] = useState('');
    const [horasen, setHoras] = useState('');
    const [proyectos, setProyecto] = useState(window.localStorage.getItem('id_project2'));
    const [diasdespeje, setDiasdespeje] = useState('');
    const [horadespeje, setHoradespeje] = useState('');
    const [indicadoro4, setIndicadoro4] = useState('');
    const [diasconcierre, setDiasconcierre] = useState('');
    const [indicadore17, setDndicadore17] = useState('');
    const [segmentosCierre, setSegmentosCierre] = useState([]);
    const [tiemposervicio, setTiemposervicio] = useState([]);
    const [nombreCausa, setNombreCausa] = useState('');
   const sumHeridos = (arrData) => {
       console.log("sumHeridos")
       console.log(arrData)
        let total = 0;
        if(arrData.length > 0){
            arrData.map(heridos => {
                if(heridos.cantidadHeridos !== undefined){
                    total = total + heridos.cantidadHeridos
                }
            })
        }
        setHeridostotal(total);
    }

    const [victimasftotal, setVictimasFTotal] = useState(0);
    const sumVictimasF = (arrData) => {
      console.log("sumVictimasF")
      console.log(arrData)
        let total = 0;
        if(arrData.length > 0){
            arrData.map(victimasf => {
                if(victimasf.cantidadVictimasf !== undefined){
                    total = total + victimasf.cantidadVictimasf
                }
            })
        }
        setVictimasFTotal(total);
    }
   const obtenerdominio = () => {
      return urlAppjs;
   }
    const validarsegmentosafect = (value) => {
      
  }
  const validarUnifun = (value) => {
   if (value) {
       if (value != "") {
           let idunidadactual = value.split('/');
           if (value && idunidadactual[2] != unidadfun) {
               let sperador1 = value.split('/');
               console.log("urlAppjs");
               console.log(sperador1[2]);
               setUnidadfun(sperador1[2]);

               let abini = document.getElementById('abin').value
               let abfin = document.getElementById('abfin').value
               
               if (abfin != "" && abini != "") {
                  abini = abini.replace('K', '');
                  abini = abini.replace('+', '.');
                  abfin = abfin.replace('K', '');
                  abfin = abfin.replace('+', '.');
                 
                  fetch(obtenerdominio() + "/disponibilidadViaSeg/" + sperador1[2] + "/" + abini + "/" + abfin)
                  .then((response) => {
                     return response.json();
                  })
                  .then((response) => {
                     let largo = response.codigoseg.length;
                     let valor = "";
                     let valor2 = [];
                     for (let i = 0; i < largo; i++) {
                        valor = valor + response.codigoseg[i] + "; ";
                        valor2[i] = "segmentos/" + response.segid[i];
                     }
                     setSegmentosafectados(valor);
                     setSegmentosCierre(valor2);

                  })
                  
               }
              
           }
       }
   }
}
  useEffect(() => {
   validarUnifun();
 });
const ValidarUnidad = (value) => { 
   let lenghtObject = Object.keys(value);
   let valor = '';

   for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
   setUnidad(valor)
}
const validarLongitudafect = (value) => {
   let abini = document.getElementById('abin').value
   let abfin = document.getElementById('abfin').value
   if (abfin != "" && abini != "") {
       abini = abini.replace('K', '');
       abini = abini.replace('+', '.');
       abfin = abfin.replace('K', '');
       abfin = abfin.replace('+', '.');
       let res = (abfin - abini).toFixed(3);
       setLongitud(res);
   }
}
     return(
<ShowController {...props} title="Dispoinibilidad de Vía">
{controllerProps =>
     <Show title="dynatest.resources.disponibilidad_vias.title.showTitle"  {...props} {...controllerProps}  actions={<ButtonCarousel />}>
        <TabbedShowLayout>
        <Tab label="dynatest.resources.disponibilidad_vias.tabs.tabsTitle.identificacion">
            <TextField label="resources.disponibilidad_vias.fields.consecutivo" fullWidth source="consecutivo" className={classes.grid_cont3}/>
            <DateField label="resources.disponibilidad_vias.fields.fecha" fullWidth source="fecha" className={classes.grid_cont3}/>
            <TextField label="resources.disponibilidad_vias.fields.tipoEvento" fullWidth source="tipoEvento" className={classes.grid_cont3}/>
            {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Cierre') &&
            <TextField label="resources.disponibilidad_vias.fields.tipoCierre"  fullWidth source="tipoCierre" className={classes.grid_cont3}/>
            }
            {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Cierre') &&
            <span></span>
            }
            <Typography formClassName={classes.grid_cont12} variant="b" component="b">{translate('dynatest.resources.disponibilidad_vias.subTitle.localizacion')}</Typography>
            <span></span>
            <span></span>
            <Divider fullWidth className={classes.grid_cont12}/>
            <TextField label="resources.disponibilidad_vias.fields.unidadFuncional" source="unidadcodigo" className={classes.grid_cont3}/> 
            <PRField label="resources.disponibilidad_vias.fields.AbscisaInicial" source="abscisaininum" fullWidth className={classes.grid_cont3}/>
            <PRField label="resources.disponibilidad_vias.fields.AbscisaFinal" source="abscisafinnum" fullWidth className={classes.grid_cont3}/>
            <TextField label="resources.disponibilidad_vias.fields.margen"  fullWidth source="margen" className={classes.grid_cont6} />
            <TextField label="resources.disponibilidad_vias.fields.lugar"  source="lugar" fullWidth className={classes.grid_cont6_nw} />
            <TextField label="resources.disponibilidad_vias.fields.longitudAfect" source="longitudAfect" fullWidth className={classes.grid_cont3}/>
            <TextField label="resources.disponibilidad_vias.fields.segmentosAfect" source="segmentosAfect" fullWidth/>
            <NumberField label="resources.disponibilidad_vias.fields.latitud" source="latitud" fullWidth className={classes.grid_cont3} options={{ maximumFractionDigits: 2 }} />
            <NumberField label="resources.disponibilidad_vias.fields.longitud" source="longitud" fullWidth className={classes.grid_cont1} options={{ maximumFractionDigits: 2 }}/>  
        </Tab>
        <Tab label="dynatest.resources.disponibilidad_vias.tabs.tabsTitle.detalles">
            <DateField label="resources.disponibilidad_vias.fields.fechaReporte" source="fechaReporte" fullWidth className={classes.grid_cont4}/>        
            <FField label="resources.disponibilidad_vias.fields.horaReporte" source="horareport" id="horadereporte" fullWidth className={classes.grid_cont4}/>
            <DateField label="resources.disponibilidad_vias.fields.fechaSenal" source="fechaSenal" fullWidth className={classes.grid_cont4}/>
            <FField label="resources.disponibilidad_vias.fields.horaSenal" source="horasenalizacion" fullWidth className={classes.grid_cont4} />
            <FField label="resources.disponibilidad_vias.fields.tiempSenal" source="tiempSenal" fullWidth className={classes.grid_cont4}/>
            <div className={classes.grid_cont4}></div>
            {controllerProps.record && controllerProps.record.ambulancia &&
               <DateField label="resources.disponibilidad_vias.fields.fechaAmbulancia" source="fechaAmbulancia" id="fecha_ambulancia" className={classes.grid_cont6}/>
            }
            {controllerProps.record && controllerProps.record.ambulancia &&            
               <FField label="resources.disponibilidad_vias.fields.tiempoRespuestaAmbulancia" source="horaAmbulancia" id="hora_ambulancia" className={classes.grid_cont6}/>
            }
            {controllerProps.record && controllerProps.record.grua &&
               <DateField label="resources.disponibilidad_vias.fields.fechaGrua" source="fechaGrua" id="fecha_grua" className={classes.grid_cont6}/>
            }
            {controllerProps.record && controllerProps.record.grua &&            
               <FField label="resources.disponibilidad_vias.fields.tiempoRespuestaGrua" source="horaGrua" id="hora_grua" className={classes.grid_cont6}/>
            }
            <TextField label="resources.disponibilidad_vias.fields.fuenteRep"  fullWidth source="fuenteRep" className={classes.grid_cont4} />
            <TextField label="resources.disponibilidad_vias.fields.causa" source="causa" className={classes.grid_cont4_nw} fullWidth/>
            <TextField label="resources.disponibilidad_vias.fields.elementoAfect"  fullWidth source="elementoAfect" className={classes.grid_cont4} />
            <TextField label="resources.disponibilidad_vias.fields.observaciones" source="observaciones" fullWidth className={classes.grid_cont12} multiline/>
            <Typography className={classes.grid_cont12} variant="b" component="b">{translate('dynatest.resources.disponibilidad_vias.subTitle.AsociarServicios')}</Typography>
            <Divider fullWidth className={classes.grid_cont12}/>
            {controllerProps.record && controllerProps.record.servicios &&    
               <AsociarServicios {...controllerProps.record}/>
               // <ArrayField label="" source="servicios" fullwidth>
               //    <Datagrid>
               //       <TextField label="Directorio"  fullWidth source="serviciosDirectorio"/>
                     
               //       {controllerProps.record && controllerProps.record.servicios[0] && controllerProps.record.servicios[0].tipoServicio ===1 &&
               //          <TextField label="Grupo" source='serviciosGrupoEmergencias' fullWidth/>
               //       }
               //       {controllerProps.record && controllerProps.record.servicios[1] && controllerProps.record.servicios[1].tipoServicio ===1 &&
               //          <TextField label="Grupo" source='serviciosGrupoEmergencias' fullWidth/>
               //       }
               //       {controllerProps.record && controllerProps.record.servicios[2] && controllerProps.record.servicios[2].tipoServicio ===1 &&
               //          <TextField label="Grupo" source='serviciosGrupoEmergencias' fullWidth/>
               //       }
               //       {controllerProps.record && controllerProps.record.servicios[0] && controllerProps.record.servicios[0].tipoServicio ===2 &&
               //          <ReferenceField label="Sistema" source='serviciosSistemaProveedores' reference="sistemas">
               //             <TextField  source="nombre" fullWidth />
               //          </ReferenceField>
               //       }                                            
               //       {controllerProps.record && controllerProps.record.servicios[1] && controllerProps.record.servicios[2].tipoServicio ===2 &&
               //          <ReferenceField label="Sistema" source='serviciosSistemaProveedores' reference="sistemas">
               //             <TextField  source="nombre" fullWidth />
               //          </ReferenceField>
               //       }                                            
               //       {controllerProps.record && controllerProps.record.servicios[2] && controllerProps.record.servicios[2].tipoServicio ===2 &&
               //          <ReferenceField label="Sistema" source='serviciosSistemaProveedores' reference="sistemas" >
               //             <TextField  source="nombre" fullWidth />
               //          </ReferenceField>
               //       }              
               //       {controllerProps.record && controllerProps.record.servicios[0] && controllerProps.record.servicios[0].tipoServicio ===3 &&
               //          <TextField label="Grupo" source='serviciosGrupoServicios'/>
               //       }
               //       {controllerProps.record && controllerProps.record.servicios[1] && controllerProps.record.servicios[1].tipoServicio ===3 &&
               //          <TextField label="Grupo" source='serviciosGrupoServicios'/>
               //       }
               //       {controllerProps.record && controllerProps.record.servicios[2] && controllerProps.record.servicios[2].tipoServicio ===3 &&
               //          <TextField label="Grupo" source='serviciosGrupoServicios'/>
               //       }
               //       {controllerProps.record && controllerProps.record.servicios[0] && controllerProps.record.servicios[0].tipoServicio ===1 &&
               //          <ReferenceField label="Elemento" source='serviciosElementoEmergencias' reference="contacto_emergencias" linkType={false}>
               //             <TextField  source="empresa" fullWidth />
               //          </ReferenceField>
               //       }
               //       {controllerProps.record && controllerProps.record.servicios[1] && controllerProps.record.servicios[1].tipoServicio ===1 &&
               //          <ReferenceField label="Elemento" source='serviciosElementoEmergencias' reference="contacto_emergencias" linkType={false}>
               //             <TextField  source="empresa" fullWidth />
               //          </ReferenceField>
               //       }
               //       {controllerProps.record && controllerProps.record.servicios[2] && controllerProps.record.servicios[2].tipoServicio ===1 &&
               //          <ReferenceField label="Elemento" source='serviciosElementoEmergencias' reference="contacto_emergencias" linkType={false}>
               //             <TextField  source="empresa" fullWidth />
               //          </ReferenceField>
               //       }
               //       {controllerProps.record && controllerProps.record.servicios[0] && controllerProps.record.servicios[0].tipoServicio ===2 &&
               //          <ReferenceField label="Elemento" source='serviciosElementoProveedores' reference="proveedors" linkType={false}>
               //             <TextField  source="razonSocial" fullWidth />
               //          </ReferenceField>
               //       }
               //       {controllerProps.record && controllerProps.record.servicios[1] && controllerProps.record.servicios[1].tipoServicio ===2 &&
               //          <ReferenceField label="Elemento" source='serviciosElementoProveedores' reference="proveedors" linkType={false}>
               //             <TextField  source="razonSocial" fullWidth />
               //          </ReferenceField>
               //       }
               //       {controllerProps.record && controllerProps.record.servicios[2] && controllerProps.record.servicios[2].tipoServicio ===2 &&
               //          <ReferenceField label="Elemento" source='serviciosElementoProveedores' reference="proveedors" linkType={false}>
               //             <TextField  source="razonSocial" fullWidth />
               //          </ReferenceField>
               //       }
               //       {controllerProps.record && controllerProps.record.servicios[0] && controllerProps.record.servicios[0].tipoServicio ===3 &&
               //       <ReferenceField label="Elemento" source='serviciosElementoServicios' reference="servicios_publicos" linkType={false}>
               //          <TextField  source="empresa" fullWidth />
               //       </ReferenceField>
               //       }
               //       {controllerProps.record && controllerProps.record.servicios[1] && controllerProps.record.servicios[1].tipoServicio ===3 &&
               //       <ReferenceField label="Elemento" source='serviciosElementoServicios' reference="servicios_publicos" linkType={false}>
               //          <TextField  source="empresa" fullWidth />
               //       </ReferenceField>
               //       }
               //       {controllerProps.record && controllerProps.record.servicios[2] && controllerProps.record.servicios[2].tipoServicio ===3 &&
               //       <ReferenceField label="Elemento" source='serviciosElementoServicios' reference="servicios_publicos" linkType={false}>
               //          <TextField  source="empresa" fullWidth />
               //       </ReferenceField>
               //       }
               //    </Datagrid>
               // </ArrayField>
            }
        </Tab>
        <Tab label="dynatest.resources.disponibilidad_vias.tabs.tabsTitle.caracteristicas" >
            <NumberField label="resources.disponibilidad_vias.fields.longitudCola" source="longitudCola" fullWidth className={classes.grid_cont7}/>
        {controllerProps.record &&(controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && controllerProps.record.causa===translate('dynatest.resources.disponibilidad_vias.select.causa.TrabajosVia')) &&
            <TextField label="resources.disponibilidad_vias.fields.indicadorO2" source="indicadorO2" fullWidth className={classes.grid_cont6}/>
        }
            <TextField label="resources.disponibilidad_vias.fields.observacionesTrabajos" source="observacionesTrabajos" fullWidth className={classes.grid_cont7} multiline/>
        {controllerProps.record &&(controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && controllerProps.record.causa===translate('dynatest.resources.disponibilidad_vias.select.causa.Derrumbe')) &&
            <NumberField label="resources.disponibilidad_vias.fields.volumenDerrumbe" source="volumenDerrumbe" fullWidth />
        }    
        {controllerProps.record &&(controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && controllerProps.record.causa===translate('dynatest.resources.disponibilidad_vias.select.causa.Derrumbe')) &&    
            <TextField label="resources.disponibilidad_vias.fields.ElementoAfectado" fullWidth source="elementoAfect"/>
        }                        
        {/*controllerProps.record &&(controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && controllerProps.record.causa===translate('dynatest.resources.disponibilidad_vias.select.causa.Derrumbe')) && controllerProps.record.volumenDerrumbe>2000 &&
                       
            <TextField label="Condiciones Especiales" fullWidth source="condicionesEsp" style={{ display: 'none' }}/>             
      */}
        {/*controllerProps.record &&(controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && controllerProps.record.causa===translate('dynatest.resources.disponibilidad_vias.select.causa.Derrumbe')) &&    
            <TextField label="Tiempo de Despeje Máximo (h)" fullWidth source="tiempoDespeje" style={{ display: 'none' }}/>
   */}                
        {controllerProps.record &&(controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && controllerProps.record.causa===translate('dynatest.resources.disponibilidad_vias.select.causa.Derrumbe')) &&
            <TextField label="resources.disponibilidad_vias.fields.observacionesDerrumbe" source="observacionesDerrumbe" fullWidth className={classes.grid_cont12} multiline/>
        }         
        {controllerProps.record &&(controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && controllerProps.record.causa===translate('dynatest.resources.disponibilidad_vias.select.causa.Otro')) &&
            <TextField label="resources.disponibilidad_vias.fields.tipoIncidenteOtro" fullWidth source="tipoIncidenteOtro" className={classes.grid_cont6s} />
        }        
        {controllerProps.record &&(controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && controllerProps.record.causa===translate('dynatest.resources.disponibilidad_vias.select.causa.Otro')) &&    
            <TextField label="resources.disponibilidad_vias.fields.tipoVehiculoOtro" fullWidth source="tipoVehiculoOtro" className={classes.grid_cont6} />
        }
        {controllerProps.record &&(controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && controllerProps.record.causa===translate('dynatest.resources.disponibilidad_vias.select.causa.Otro')) &&
            <TextField label="resources.disponibilidad_vias.fields.observacionesOtro" source="observacionesOtro" fullWidth className={classes.grid_cont7} multiline/>
        }      
        {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') &&
            <TextField label="resources.disponibilidad_vias.fields.tipoAccidente" fullWidth source="tipoAccidente" />
        }            
        {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') &&
           <span></span>
        }           
        {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') &&
           <span></span>
        } 
        {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') &&
           <Typography formClassName={classes.grid_cont12} variant="b" component="b">{translate('dynatest.resources.disponibilidad_vias.subTitle.JuicioPolicia')}</Typography>
        }
        {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') &&
           <span></span>
        }           
        {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') &&
           <span></span>
        }
        {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') &&
           <Divider fullWidth formClassName={classes.grid_cont12}/>
        }
        {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') &&
           <Divider fullWidth formClassName={classes.grid_cont12}/>
        }
        {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') &&
           <Divider fullWidth formClassName={classes.grid_cont12}/>
        }
        {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') &&
            <ReferenceField perPage={900} label="resources.disponibilidad_vias.fields.CodigoCausa" source="disponibilidadViaCausaProbable" reference="disponibilidad_via_causa_probables" linkType={false}>
               <TextField source="codigo"  options={{ fullWidth: true}}/>
            </ReferenceField>
        }                        
        {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') &&
            <ReferenceField perPage={900} label="resources.disponibilidad_vias.fields.CausaProbable" source="disponibilidadViaCausaProbable" reference="disponibilidad_via_causa_probables" linkType={false}>
               <TextField source="nombre"  options={{ fullWidth: true}}/>
            </ReferenceField>
        }
        {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') &&
            <TextField label="resources.disponibilidad_vias.fields.clasePolicia" source="clasePolicia" fullWidth/>
        }
         {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') && controllerProps.record.heridos.length >0  &&
            <hr fullWidth size="2px" color="gray"/>
         }
         {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') && controllerProps.record.heridos.length >0  &&
            <hr fullWidth size="2px" color="gray"/>
         }
         {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') && controllerProps.record.heridos.length >0  &&
            <hr fullWidth size="2px" color="gray"/>
         }
        {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') && controllerProps.record.heridos.length >0  &&
            <ArrayField label={<Typography><b style={{marginTop: "0px",marginBottom: "0px"}}>{translate('dynatest.resources.disponibilidad_vias.subTitle.Heridos')}</b></Typography>} source="heridos" validate={array => sumHeridos(array)} style={{ width: '100%' }} fullWidth formClassName={classes.grid_cont12}>
               <Datagrid>
                  <NumberField label="resources.disponibilidad_vias.fields.cantidadHeridos" source="cantidadHeridos" />
                  <TextField label="resources.disponibilidad_vias.fields.tipoHeridos" source="tipoHeridos" />
                  <TextField label="resources.disponibilidad_vias.fields.generoHeridos" source="generoHeridos"/>
                  <NumberField label="resources.disponibilidad_vias.fields.edadHeridos" source="edadHeridos" />
               </Datagrid>
            </ArrayField>
        }
        {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') && controllerProps.record.heridos.length >0  &&
           <span></span>
        }
        {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') && controllerProps.record.heridos.length >0  &&
           <span></span>
        }
        {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') && controllerProps.record.heridos.length >0  &&
           <NumberField label="resources.disponibilidad_vias.fields.totalHeridos" source="totalHeridos" />
        }        
        {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') && controllerProps.record.heridos.length >0  &&
           <span></span>
        }
        {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') && controllerProps.record.heridos.length >0  &&
           <span></span>
        }
        {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') && controllerProps.record.victimas.length > 0  &&
            <hr fullWidth size="2px" color="gray"/>
         }
         {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') && controllerProps.record.victimas.length > 0  &&
            <hr fullWidth size="2px" color="gray"/>
         }
         {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') && controllerProps.record.victimas.length > 0  &&
            <hr fullWidth size="2px" color="gray"/>
         }
        {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') && controllerProps.record.victimas.length > 0  &&
            <ArrayField label={<Typography><b style={{marginTop: "0px",marginBottom: "0px"}}>{translate('dynatest.resources.disponibilidad_vias.subTitle.VictimasFatales')}</b></Typography>} source="victimas"  style={{ width: '100%' }} fullWidth formClassName={classes.grid_cont12} validate={array => sumVictimasF(array)}>
               <Datagrid>
                     <NumberField label="resources.disponibilidad_vias.fields.cantidadVictimasf" source="cantidadVictimasf" fullWidth formClassName={classes.grid_cont4}/>
                     <TextField label="resources.disponibilidad_vias.fields.tipoVictimasf" source="tipoVictimasf"  fullWidth formClassName={classes.grid_cont4}/>
                     <TextField label="resources.disponibilidad_vias.fields.generoVictimasf" source="generoVictimasf"  fullWidth formClassName={classes.grid_cont4}/>
                     <NumberField label="resources.disponibilidad_vias.fields.edadVictimasf" source="edadVictimasf"  fullWidth formClassName={classes.grid_cont4}/>
               </Datagrid>
            </ArrayField>
        }
        
        {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') && controllerProps.record.victimas.length >0  &&
           <span></span>
        }
        {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') && controllerProps.record.victimas.length >0  &&
           <span></span>
        }
        {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') && controllerProps.record.victimas.length >0  && 
            <NumberField 
                label="resources.disponibilidad_vias.fields.totalVictimasFatales"
                source="totalVictimasFatales"
                value={victimasftotal}
            />
         }
         {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') && controllerProps.record.victimas.length >0  &&
            <span></span>
         }
         {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') && controllerProps.record.victimas.length >0  &&
            <span></span>
         }
         {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') && controllerProps.record.vehiculos.length > 0  &&
            <hr fullWidth size="2px" color="gray"/>
         }
         {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') && controllerProps.record.vehiculos.length > 0  &&
            <hr fullWidth size="2px" color="gray"/>
         }
         {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') && controllerProps.record.vehiculos.length > 0  &&
            <hr fullWidth size="2px" color="gray"/>
         }
        {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') && controllerProps.record.vehiculos.length > 0  &&
        <ArrayField label={<Typography><b style={{marginTop: "0px",marginBottom: "0px"}}>Vehículos Implicados</b></Typography>} source="vehiculos" fullwidth>
            <Datagrid>
                  <NumberField label="resources.disponibilidad_vias.fields.cantidadVehiculos" source="cantidadVehiculos" fullWidth />
                  <TextField label="resources.disponibilidad_vias.fields.categoriaVehiculos" source="categoriaVehiculos" fullWidth />
                  <TextField label="resources.disponibilidad_vias.fields.tipoVehiculos" source="tipoVehiculos" fullWidth />
                  <TextField label="resources.disponibilidad_vias.fields.marcaVehiculos" source="marcaVehiculos" fullWidth />
                  <TextField label="resources.disponibilidad_vias.fields.lineaVehiculos" source="lineaVehiculos" fullWidth />
                  <TextField label="resources.disponibilidad_vias.fields.modeloVehiculos" source="modeloVehiculos" fullWidth />
                  <TextField label="resources.disponibilidad_vias.fields.placaVehiculos" source="placaVehiculos" fullWidth />
                  <TextField label="resources.disponibilidad_vias.fields.colorVehiculos" source="colorVehiculos" fullWidth />
            </Datagrid>
         </ArrayField>
        }
        {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') && controllerProps.record.vehiculos.length >0 &&
           <span></span>
        }           
        {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') && controllerProps.record.vehiculos.length >0 &&
           <span></span>
        }
        {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') &&
        <TextField label="resources.disponibilidad_vias.fields.observacionesAccidente" source="observacionesAccidente" fullWidth className={classes.grid_cont12} multiline/>
        }
        </Tab>
        {//controllerProps.record && ((controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && controllerProps.record.causa===translate('dynatest.resources.disponibilidad_vias.select.causa.Derrumbe')) || (controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && controllerProps.record.causa===translate('dynatest.resources.disponibilidad_vias.select.causa.Otro')) || (controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente'))|| (controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Cierre')))  &&
        <Tab label="dynatest.resources.disponibilidad_vias.tabs.tabsTitle.finalizar">
   
         {controllerProps.record && controllerProps.record.finalizar &&
            <DateField label="resources.disponibilidad_vias.fields.fechaDespeje" source="fechaDespeje" id="fecha_despeje" className={classes.grid_cont6}/>
         }
         {controllerProps.record && controllerProps.record.finalizar &&            
            <FField label="resources.disponibilidad_vias.fields.horaDespeje" source="horaDespeje" id="hora_despeje" className={classes.grid_cont6}/>
         }
         {controllerProps.record && controllerProps.record.finalizar &&
            <NumberField label="resources.disponibilidad_vias.fields.diasDespeje" id="dias_despejef" source="diasDespeje" className={classes.grid_cont4}/>
         }
         {controllerProps.record && controllerProps.record.finalizar &&
            <FField label="resources.disponibilidad_vias.fields.tiempoDespejeFin" id="tiempo_deespejef"  source="tiempoDespejeFin" className={classes.grid_cont4}/>
         }   
         {/*controllerProps.record && controllerProps.record.finalizar &&
            <TextField label="Inicador O4"  id="indicaddoro4" source="indicadorO4" className={classes.grid_cont4}/>
         */}   
         {controllerProps.record && controllerProps.record.finalizar &&
            <TextField label="resources.disponibilidad_vias.fields.observacionesDespeje" source="observacionesDespeje" multiline className={classes.grid_cont12}/>
         }
       
         {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && controllerProps.record.causa===translate('dynatest.resources.disponibilidad_vias.select.causa.Otro') &&
                        
            <ArrayField label="" source="servicios" fullWidth >
               <Datagrid>
               {controllerProps.record && controllerProps.record.servicios[0] && controllerProps.record.servicios[0].tipoServicio ===1 &&
                  <ReferenceField label="resources.disponibilidad_vias.fields.servElemento" source="serviciosElementoEmergencias" reference="contacto_emergencias" fullWidth linkType={false}>
                  <TextField  source="empresa" />
                  </ReferenceField>
               }
               {controllerProps.record && controllerProps.record.servicios[1] && controllerProps.record.servicios[1].tipoServicio ===1 &&
                  <ReferenceField label="resources.disponibilidad_vias.fields.servElemento" source="serviciosElementoEmergencias" reference="contacto_emergencias" fullWidth linkType={false}>
                  <TextField  source="empresa" />
                  </ReferenceField>
               }
               {controllerProps.record && controllerProps.record.servicios[2] && controllerProps.record.servicios[2].tipoServicio ===1 &&
                  <ReferenceField label="resources.disponibilidad_vias.fields.servElemento" source="serviciosElementoEmergencias" reference="contacto_emergencias" fullWidth linkType={false}>
                  <TextField  source="empresa" />
                  </ReferenceField>
               }
               {controllerProps.record && controllerProps.record.servicios[0] && controllerProps.record.servicios[0].tipoServicio ===2 &&
                  <ReferenceField label="resources.disponibilidad_vias.fields.servElemento" source="serviciosElementoProveedores" reference="contacto_emergencias" fullWidth linkType={false}>
                  <TextField  source="razonSocial"  />
                  </ReferenceField>
               }
               {controllerProps.record && controllerProps.record.servicios[1] && controllerProps.record.servicios[1].tipoServicio ===2 &&
                  <ReferenceField label="resources.disponibilidad_vias.fields.servElemento" source="serviciosElementoProveedores" reference="contacto_emergencias" fullWidth linkType={false}>
                  <TextField  source="razonSocial"  />
                  </ReferenceField>
               }
               {controllerProps.record && controllerProps.record.servicios[2] && controllerProps.record.servicios[2].tipoServicio ===2 &&
                  <ReferenceField label="resources.disponibilidad_vias.fields.servElemento" source="serviciosElementoProveedores" reference="contacto_emergencias" fullWidth linkType={false}>
                  <TextField  source="razonSocial"  />
                  </ReferenceField>
               }
               {controllerProps.record && controllerProps.record.servicios[0] && controllerProps.record.servicios[0].tipoServicio ===3 &&
                  <ReferenceField label="resources.disponibilidad_vias.fields.servElemento" source="serviciosElementoServicios" reference="contacto_emergencias" fullWidth linkType={false}>
                  <TextField  source="empresa" />
                  </ReferenceField>
               }
               {controllerProps.record && controllerProps.record.servicios[1] && controllerProps.record.servicios[1].tipoServicio ===3 &&
                  <ReferenceField label="resources.disponibilidad_vias.fields.servElemento" source="serviciosElementoServicios" reference="contacto_emergencias" fullWidth linkType={false}>
                  <TextField  source="empresa" />
                  </ReferenceField>
               }
               {controllerProps.record && controllerProps.record.servicios[2] && controllerProps.record.servicios[2].tipoServicio ===3 &&
                  <ReferenceField label="resources.disponibilidad_vias.fields.servElemento" source="serviciosElementoServicios" reference="contacto_emergencias" fullWidth linkType={false}>
                  <TextField  source="empresa" />
                  </ReferenceField>
               }
               {controllerProps.record && controllerProps.record.servicios[0] &&
                  <DateField label="resources.disponibilidad_vias.fields.fechaFinalizacion" source="fechaFinalizacion"  fullWidth/>
               }
               {controllerProps.record && controllerProps.record.servicios[1] && 
                  <DateField label="resources.disponibilidad_vias.fields.fechaFinalizacion" source="fechaFinalizacion"  fullWidth/>
               }
               {controllerProps.record && controllerProps.record.servicios[2] && 
                  <DateField label="resources.disponibilidad_vias.fields.fechaFinalizacion" source="fechaFinalizacion"  fullWidth/>
               }
               {controllerProps.record && controllerProps.record.servicios[0] &&
                  <FField label="resources.disponibilidad_vias.fields.horaFinalizacion" source="horaFinalizacion"  fullWidth/>
               }
               {controllerProps.record && controllerProps.record.servicios[1] &&
                  <FField label="resources.disponibilidad_vias.fields.horaFinalizacion" source="horaFinalizacion"  fullWidth/>
               }
               {controllerProps.record && controllerProps.record.servicios[2] && 
                  <FField label="resources.disponibilidad_vias.fields.horaFinalizacion" source="horaFinalizacion"  fullWidth/>
               }
               <FField label="resources.disponibilidad_vias.fields.tiempoServicio" source="tiempoServicio" fullWidth/>
               </Datagrid>
            </ArrayField>
         }
         {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && controllerProps.record.causa===translate('dynatest.resources.disponibilidad_vias.select.causa.Otro') &&
         <span></span>
         }
         {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && controllerProps.record.causa===translate('dynatest.resources.disponibilidad_vias.select.causa.Otro') &&
            <span></span>
         }
         {/*controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && controllerProps.record.causa===translate('dynatest.resources.disponibilidad_vias.select.causa.Otro') &&
            <TextField label="Observaciones" source="observacionesFinalizacion" className={classes.grid_cont12} fullWidth multiline/>
      */}
            
         
         {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') &&
           <ArrayField label="" source="servicios" fullWidth >
           <Datagrid>
           {controllerProps.record && controllerProps.record.servicios[0] && controllerProps.record.servicios[0].tipoServicio ===1 &&
              <ReferenceField label="resources.disponibilidad_vias.fields.servElemento" source="serviciosElementoEmergencias" reference="contacto_emergencias" fullWidth>
              <TextField  source="empresa" />
              </ReferenceField>
           }
           {controllerProps.record && controllerProps.record.servicios[1] && controllerProps.record.servicios[1].tipoServicio ===1 &&
              <ReferenceField label="resources.disponibilidad_vias.fields.servElemento" source="serviciosElementoEmergencias" reference="contacto_emergencias" fullWidth>
              <TextField  source="empresa" />
              </ReferenceField>
           }
           {controllerProps.record && controllerProps.record.servicios[2] && controllerProps.record.servicios[2].tipoServicio ===1 &&
              <ReferenceField label="resources.disponibilidad_vias.fields.servElemento" source="serviciosElementoEmergencias" reference="contacto_emergencias" fullWidth>
              <TextField  source="empresa" />
              </ReferenceField>
           }
           {controllerProps.record && controllerProps.record.servicios[0] && controllerProps.record.servicios[0].tipoServicio ===2 &&
              <ReferenceField label="resources.disponibilidad_vias.fields.servElemento" source="serviciosElementoProveedores" reference="contacto_emergencias" fullWidth>
              <TextField  source="razonSocial"  />
              </ReferenceField>
           }
           {controllerProps.record && controllerProps.record.servicios[1] && controllerProps.record.servicios[1].tipoServicio ===2 &&
              <ReferenceField label="resources.disponibilidad_vias.fields.servElemento" source="serviciosElementoProveedores" reference="contacto_emergencias" fullWidth>
              <TextField  source="razonSocial"  />
              </ReferenceField>
           }
           {controllerProps.record && controllerProps.record.servicios[2] && controllerProps.record.servicios[2].tipoServicio ===2 &&
              <ReferenceField label="resources.disponibilidad_vias.fields.servElemento" source="serviciosElementoProveedores" reference="contacto_emergencias" fullWidth>
              <TextField  source="razonSocial"  />
              </ReferenceField>
           }
           {controllerProps.record && controllerProps.record.servicios[0] && controllerProps.record.servicios[0].tipoServicio ===3 &&
              <ReferenceField label="resources.disponibilidad_vias.fields.servElemento" source="serviciosElementoServicios" reference="contacto_emergencias" fullWidth>
              <TextField  source="empresa" />
              </ReferenceField>
           }
           {controllerProps.record && controllerProps.record.servicios[1] && controllerProps.record.servicios[1].tipoServicio ===3 &&
              <ReferenceField label="resources.disponibilidad_vias.fields.servElemento" source="serviciosElementoServicios" reference="contacto_emergencias" fullWidth>
              <TextField  source="empresa" />
              </ReferenceField>
           }
           {controllerProps.record && controllerProps.record.servicios[2] && controllerProps.record.servicios[2].tipoServicio ===3 &&
              <ReferenceField label="resources.disponibilidad_vias.fields.servElemento" source="serviciosElementoServicios" reference="contacto_emergencias" fullWidth>
              <TextField  source="empresa" />
              </ReferenceField>
           }
           <DateField label="resources.disponibilidad_vias.fields.fechaLlegada" source="fechaLlegada"  fullWidth/>
           <FField label="resources.disponibilidad_vias.fields.horaLlegada" source="horaLlegada"  fullWidth/>
           <FField label="resources.disponibilidad_vias.fields.tiempoRespuesta" source="tiempoRespuesta" fullWidth/>
           <TextField label="resources.disponibilidad_vias.fields.indicadorO5" source="indicadorO5" fullWidth/>
           </Datagrid>
        </ArrayField>
         }
         {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') &&
         <span></span>
         }
         {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') &&
         <span></span>
         }
         {/*controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') &&
            <TextField label="Observaciones" source="observacionesLlegada" multiline className={classes.grid_cont12}/>
      */}         
         {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Cierre')  &&
            <DateField label="resources.disponibilidad_vias.fields.fecha_puestaservicio" id="fecha_puestaservicio" source="fechaPuestaServicio" fullWidth/>                   
         }
         {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Cierre')  &&
            <NumberField label="resources.disponibilidad_vias.fields.diasCierre"  source="diasCierre" fullWidth/>                
         }            
         {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Cierre')  &&
            <TextField label="resources.disponibilidad_vias.fields.indicadorE17" source="indicadorE17" fullWidth/>               
         }            
         {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Cierre')  &&
            <Typography  variant="b" component="b" fullWidth>{translate('dynatest.resources.disponibilidad_vias.subTitle.NoSegmentosCierre')}</Typography>                          
         }
         <br></br>
         <Divider fullWidth className={classes.grid_cont12}/>
         
         {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Cierre')  &&
            <Query type="GET_MANY" resource="segmentos" payload={{ ids: segmentosCierre }}>
               {({ data, loading, error }) => {
                  if (loading) { return <Loading />; }
                  if (error) { return <p>ERROR {error.message}</p>; }
                  return (
                     <Grid fullWidth>
                           <table>
                              <tr><td>{translate('resources.disponibilidad_vias.fields.segmentosAfect')}</td></tr>
                              {data.map(segmento =>
                                 <tr>
                                       <td>{segmento.fullCodigo}</td>
                                 </tr>
                              )}
                           </table>
                     </Grid>
                  )
               }}
            </Query>                   
         }
         {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Cierre')  &&
            <TextField label="resources.disponibilidad_vias.fields.observacionesCierre"  source="observacionesCierre" fullWidth multiline className={classes.grid_cont12}/>
         }
         {controllerProps.record && controllerProps.record.tipoEvento===translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Cierre')  &&
            <TabbedForm submitOnEnter={false} redirect="list" formClassName={classes.ocultar} style={{ display: 'none' }}>
               <FormTab>
                  <TextInput label="resources.disponibilidad_vias.fields.unidadFuncional" source="unidadFuncional" id="ufuncional" disabled reference="unidad_funcionals" validate={[required(), ValidarUnidad, validarUnifun]} fullWidth formClassName={classes.grid_cont4s}/>
                  <PRInput label="resources.disponibilidad_vias.fields.AbscisaFinal" source="abscisafinnum" id="abfin" disabled fullWidth formClassName={classes.grid_cont4} />
                  <PRInput label="resources.disponibilidad_vias.fields.AbscisaInicial" source="abscisaininum" id="abin" disabled fullWidth formClassName={classes.grid_cont4} validate={[validarLongitudafect]} />
               </FormTab>
            </TabbedForm>
         }
         </Tab>
         }
        <Tab label="dynatest.resources.disponibilidad_vias.tabs.tabsTitle.auditoria">
            <DateField showTime source="createdAt" label="resources.disponibilidad_vias.fields.createdAt" fullWidth formClassName={classes.grid_cont4}/> 
            <TextField source="createdBy" label="resources.disponibilidad_vias.fields.createdBy" fullWidth formClassName={classes.grid_cont4}/>
            <span></span>
            <DateField showTime source="updatedAt" label="resources.disponibilidad_vias.fields.updatedAt" fullWidth formClassName={classes.grid_cont4}/>
            <TextField source="updatedBy" label="resources.disponibilidad_vias.fields.updatedBy" fullWidth formClassName={classes.grid_cont4}/>
        </Tab>
        </TabbedShowLayout>
    </Show>
}
</ShowController>
     )
}));