import React, { useState, Fragment } from 'react';
import { Query, Loading,BooleanInput } from 'react-admin';

import Drawer from '@material-ui/core/Drawer';
import Checkbox from '@material-ui/core/Checkbox';

import IconImageEye from '@material-ui/icons/RemoveRedEye';
import IconKeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button } from 'react-admin';
import { translate } from 'react-admin';
import config from '../../config/config';
// import { CarouselImage } from './CarouselImagePeru';
let urlAppjs = config.UrlServer;

export const ButtonCarousel = translate(({
    record,
    ImagenesOpen,
    data,
    TipoImagen
}) => {

    const [showPanel, setShowPanel] = useState(false);

    const handleClick = () => {
        setShowPanel(true)
    }

    const handleCloseClick = () => {
        setShowPanel(false)
    }

    const [imgDelete, setImgDelete] = useState({});
    const addImgDelete = (event,image_id,pos, div_id) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        setImgDelete((prevState) => ({
        ...prevState,
            [div_id]: {"div_id": div_id,"image_id":image_id,"pos":pos, "value": value}
        }));
    }

    const handleClick2 = () => {
        let objImg = Object.entries(imgDelete);
        let puente = '0';
        let valores = '';
        let pos = '';
        if(objImg.length > 0){
            objImg.forEach(function (subValue) {
                if(subValue[1].value == true){
                    if(pos != ''){
                        pos = pos + ',';
                    }
                    valores = subValue[1].image_id.split('/');
                    pos = pos + valores[2] + "-" + subValue[1].pos;
                }
            })
            
            fetch(urlAppjs + '/eliminarImagenCalificacionPuentes/' + puente + "/" + pos)
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                alert(response.msg)
                //document.getElementsByName("chkImgDelete")
                objImg.forEach(function (subValue) {
                    if(subValue[1].value == true){
                        document.getElementById(subValue[1].div_id).remove();
                    }
                })
                setImgDelete({})
                //document.getElementById(div_id).style.display = 'none';
            })
        }else{
            alert('Se deben seleccionar imagenes para ser eliminadas');
        }
    }

    if (typeof record !== 'undefined' && record.images !== [] && record.images !== null && record.images !== '' && record.images.length != 0) {
        // console.log(record.images);
        if (record.images.length > 0 && typeof record.images[0] === "string") {
            const resource = record.images[0].split("/")[1];
            const payload = {
                pagination: { page: 1, perPage: 10 },
                sort: { field: 'id', order: 'ASC' },
                filter: {
                    recurso: record.id
                }
            };

            return (

                <Fragment>
                    <Button onClick={handleClick} label="ra.action.imageActuales">
                        <IconImageEye />
                    </Button>
                    <Drawer
                        anchor="right"
                        open={showPanel}
                        onClose={handleCloseClick}
                    >
                        <div>
                            <Button label="Close" onClick={handleCloseClick}>
                                <IconKeyboardArrowRight />
                            </Button>
                        </div>
                        <Query type="GET_LIST" resource={resource} payload={payload}>
                            {({ data, loading, error }) => {
                                if (loading) { return <Loading />; }
                                if (error) { return <p>ERROR</p>; }
                                // console.log(data);

                                return (
                                    <Fragment>
                                        {data.map((item, index) =>
                                            <div style={{ textAlign: 'center', border: 'solid  0.5px black', margin: '5px', padding: '5px' }} >
                                                <img src={item.src} alt="Logo" height="250" />
                                                <p style={{ fontSize: '1.2em' }}>{item.tipoImagen_.nombre}</p>
                                            </div>
                                        )}
                                    </Fragment>
                                )
                            }}
                        </Query>
                        {/* {
                            console.log(record)
                            
                        } */}
                    </Drawer>
                </Fragment>
            );
        } else if (record.images.length > 0) {
            if (record.images.length > 0) {
                return (

                    <Fragment>
                        <Button onClick={handleClick} label="ra.action.imageActuales">
                            <IconImageEye />
                        </Button>
                        <Drawer
                            anchor="right"
                            open={showPanel}
                            onClose={handleCloseClick}
                            style={{width: "80%"}}
                        >
                            <div>
                                <Button label="Close" onClick={handleCloseClick}>
                                    <IconKeyboardArrowRight />
                                </Button>
                                <Button onClick={() => handleClick2()} label={"ra.action.imageEliminar"}>
                                    <DeleteIcon />
                                </Button>
                            </div>
                            {
                                record.images.map((item, index) => {
                                    if(item.arrSrc != null && Object.keys(item.arrSrc).length > 0){
                                        let pos = 0;
                                        return Object.keys(item.arrSrc).map((arrItem, indexZ) => {
                                            if((item.arrSrc[arrItem] != null && item.arrSrc[arrItem] != '')){
                                                pos++;
                                            }
                                            return (
                                                <div  id={item.tipoImagen_.nombre + "-" + indexZ} name={item.tipoImagen_.nombre + "-" + indexZ} style={(item.arrSrc[arrItem] != null && item.arrSrc[arrItem] != '')?{ textAlign: 'center', border: 'solid  0.5px black', margin: '5px', padding: '5px' }:{ display: 'none' }} >
                                                    <img src={item.arrSrc[arrItem]} alt="Logo" height="250" />
                                                    <p style={{ fontSize: '1.2em' }}>
                                                        <Checkbox name="chkImgDelete"
                                                            onClick={event => addImgDelete(event,item.id,indexZ,item.tipoImagen_.nombre + "-" + indexZ)}
                                                        />{(item.tipoImagen_) ? item.tipoImagen_.nombre + " - " + pos : ""}
                                                    </p>
                                                </div>
                                            )
                                        })
                                    }else{
                                        return (

                                            <div style={{ textAlign: 'center', border: 'solid  0.5px black', margin: '5px', padding: '5px' }} >
                                                <img src={item.src} alt="Logo" height="250" />
                                                <p style={{ fontSize: '1.2em' }}>{(item.tipoImagen_) ? item.tipoImagen_.nombre : ""}</p>
                                            </div>
                                        )
                                    }
                                    
                                })
                            }
                        </Drawer>
                    </Fragment>
                );
            }
        } else {

            return (<div></div>)
        }
    } else if (typeof ImagenesOpen !== 'undefined' && ImagenesOpen !== [] && ImagenesOpen.length !== 0 && ImagenesOpen !== null && ImagenesOpen !== '') {
        if (ImagenesOpen.length > 0) {
            return (

                <Fragment>
                    <Button onClick={handleClick} label="ra.action.imageActuales">
                        <IconImageEye />
                    </Button>
                    <Drawer
                        anchor="right"
                        open={showPanel}
                        onClose={handleCloseClick}
                    >
                        <div>
                            <Button label="Close" onClick={handleCloseClick}>
                                <IconKeyboardArrowRight />
                            </Button>
                        </div>
                        {
                            ImagenesOpen.map((item, index) => {

                                return (
                                    <div style={{ textAlign: 'center', border: 'solid  0.5px black', margin: '5px', padding: '5px' }} >
                                        <img src={item.src} alt="Logo" height="250" />
                                        <p style={{ fontSize: '1.2em' }}>{item.tipoImagen_.nombre}</p>
                                    </div>
                                )
                            })
                        }
                    </Drawer>
                </Fragment>
            );
        } else {

            return (<div></div>)
        }
    } else {

        return (<div></div>)
    }
});