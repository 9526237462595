import React from 'react';
import { ReferenceField,BooleanField,TextField,List, Datagrid, EditButton, ShowButton,DateField, BulkDeleteWithConfirmButton, Loading } from 'react-admin';
import { Fragment } from 'react';

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);

export const PeajescolajeList = ({ permissions, ...props }) => {
    if (!permissions) return <Loading />;  
    return (
        <List title="Cola de Peaje" {...props} sort={{ field: 'id', order: 'DESC' }} bulkActionButtons={permissions['colaDePeajea']  == 1 ? <PostBulkActionButtons />: null}>
            <Datagrid >
            <ReferenceField label="Peaje" source="inventario_peajes" reference="inventariopeajes" linkType={false}>
                <TextField source="nombre" />
                </ReferenceField>
                <TextField label="Consecutivo de Campaña" source="consecutivo"/>
                <DateField label="Fecha medición" source="date" />
                <TextField label="Tiempo de Medición" source="tiempo"/> 
                <BooleanField label="Indicador O3" source="indicador"/>
                {permissions['colaDePeajea'] == 1 || permissions['colaDePeajea'] == 2 || permissions['colaDePeajea'] == 4 ? <ShowButton/>: null}
                {permissions['colaDePeajea'] == 1 || permissions['colaDePeajea'] == 2 ? <EditButton/>: null}
            </Datagrid>
        </List>
)};