import React, { useState, Fragment } from 'react';
import {
    maxValue, ReferenceInput, minValue, Toolbar, SaveButton, DateInput, FormTab, TabbedForm, required,
    regex, Query, Loading, NumberInput, FormDataConsumer, ArrayInput, ReferenceField, SimpleFormIterator, AutocompleteInput
} from 'react-admin';
import { PRInput } from '../../../inputs/PRInput';
import { PimsElementoActions } from './../../../helpers/PimsElementoActions';
import { Entero } from './../../../helpers/validacionesInputs';
import { styles } from './../../../EstilosGrid';
import { PimsCreate } from './../../Create';
import AddElementosMantenimientoParticular from './../localHelpers/AddElementosMantenimientoParticular';
import Grid from '@material-ui/core/Grid';
import CancelIcon from '@material-ui/icons/Cancel';
import { withStyles } from '@material-ui/core';
import config from '../../../config/config';
import { translate } from 'react-admin';
let urlAppjs = config.UrlFront;

export const FlujoCajaCreate = translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;

    const LinkCancelar = () => {
        let redirect = "elemento_reds"
        return <a href={urlAppjs + redirect} class="cancelar" ><CancelIcon style={{ width: '0.8em', paddingRight: '8px' }} />CANCELAR</a>;
    }

    return (
        <PimsCreate  {...props}
            actions={<PimsElementoActions />}
        >
            <TabbedForm
                redirect="list"
            //toolbar={<CustomToolbar />}
            >
                {/* ------ ELEMENTO DE FLUJO DE CAJA ------ */}
                <FormTab label="dynatest.resources.flujo_cajas.tabs.identificacion" redirect="list" >
                    <Grid container justify="flex-start" alignItems="flex-end" spacing={24}>
                        <Grid item xs={4}>
                            <ReferenceInput
                                label="resources.flujo_cajas.fields.unidadFuncional"
                                source="unidadFuncional"
                                reference="unidad_funcionals"
                                validate={[required()]}
                            >
                                <AutocompleteInput optionText="fullCodigos" options={{ fullWidth: true }} fullWidth />
                            </ReferenceInput>
                        </Grid>
                        <Grid item xs={4}>
                            <ReferenceInput
                                label="resources.flujo_cajas.fields.nombrePolitica"
                                source="nombrePolitica"
                                reference="politicas_nombres"
                                validate={[required()]}
                            >
                                <AutocompleteInput optionText="nombre" options={{ fullWidth: true }} fullWidth />
                            </ReferenceInput>
                        </Grid>
                        <Grid item xs={4}>
                            <NumberInput
                                label="resources.flujo_cajas.fields.tasaInteres"
                                source="tasaInteres"
                                validate={[required(), minValue(0, "Mínimo 0%"), maxValue(100, "Máximo 100%")]}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-start" alignItems="flex-end" spacing={24}>
                        <Grid item xs={6}>
                            <DateInput
                                label="resources.flujo_cajas.fields.fechaInicio"
                                source="fechaInicio" validate={[required(), minValue("2000-01-01", "La fecha minima debe ser 01-01-2000")]}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <NumberInput
                                label="resources.flujo_cajas.fields.years"
                                source="years"
                                validate={[required(), Entero, minValue(0, "Mínimo 1 año"), maxValue(30, "Máximo 30 años")]}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </FormTab>
            </TabbedForm>
        </PimsCreate>
    )

}));
