import React,{ useState } from 'react';
import { NumberInput,required, DisabledInput,TextInput,FormDataConsumer } from 'react-admin';
import { minValue,number, maxValue, minLength, maxLength } from 'ra-core';
import {validarDosDecimales,numero} from '../validacion/validacionColores';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import { translate } from 'react-admin';
// import { number } from 'prop-types';
// import loader from './../../../../../loader10.gif';


    class Coloramiento extends React.Component {


    constructor(props){
        super(props)
        this.state = {  record: [],tipo: "",value: ''};
        this.array = {
            valoresx1 : 0,valoresx2 : 0,valoresx3 : 0,valoresx4 : 0, maximo: 0,minimo: 0,maximo1: 0,minimo1: 0,
        }
        this.muestra_oculta.bind(this);
        // validatestring.bind(this);
        // this.validateint.bind(this);
        // this.validatenumer.bind(this);
       // this.useStyles = this.useStyles.bind(this);

    }

    componentDidMount(){
        this.setState({ record: this.props.record })
        this.setState({ tipo: this.props.tipo })
        this.array.valoresx1=this.props.record.p1
        this.array.valoresx2=this.props.record.p2
        this.array.valoresx3=this.props.record.p3   
        this.array.valoresx4=this.props.record.p4
        this.array.minimo=this.props.record.min
        this.array.minimo1=this.props.record.min1
        this.array.maximo=this.props.record.max   
        this.array.maximo1=this.props.record.max1
    }

    // rectay(event){
    //     for(let i=0; i<4; i++){
    //         //$x=($a2-$a1)/($b1-$b2);
    //         //$y=($b1*$x)+$a1;
    //     let j=i-1; //j=1 i=2
    //         if(j<0){
    //             let j=3;
    //         }
    //         let y =(b[i]*x)+a[i];
    //        let putosy,[i]=y;
    //         console.log( "X-> "+x+"\n");
    //         }
    //     }

    muestra_oculta (id){
        if (document.getElementById(id)){
            let lol = document.getElementById("valorb_3");
            var el = document.getElementById(id);
            if(lol.value !== ""){
                el.style.display = (el.style.display == 'flex') ? 'flex' : 'flex';
                // console.log(lol)
            }
        }
    }

    render(){
        const { translate } = this.props;
        const { colores } = this.state;
        const { record } = this.state;
        const {tipo} = this.state;
        
        const recta=()=>{
            let a = [];
            let b= [];
            let puntosx=[]
            let puntosy=[]
            for(let i=0; i<4; i++){
                let valor_a=document.getElementById('valora_'+i)
                let valor_b=document.getElementById('valorb_'+i)
                if(valor_a && valor_b && valor_a.value && valor_b.value){
                    a.push(parseFloat(valor_a.value));
                    b.push(parseFloat(valor_b.value));
                }
            }
            if(a.length===4 && b.length===4){
                let x=0;
                let y=0;
                let j=0;
                //console.log(a);
                //console.log(b);
                for(let i=0; i<4; i++){
                    //$x=($a2-$a1)/($b1-$b2);
                    //$y=($b1*$x)+$a1;
                    let j=i-1; //j=1 i=2
                    if(j<0){
                        j=3;
                    }
                    x=(a[i]-a[j])/(b[j]-b[i]);
                    console.log(x);
                    y =(b[i]*x)+a[i];
                    console.log(y);
                    puntosx[i] = parseFloat(x).toFixed(3);
                    puntosy[i] = parseFloat(y).toFixed(3);

                }

                //console.log(puntosx)
                //console.log(puntosy)
                this.array.maximo = Math.max(...puntosx)
                this.array.minimo = Math.min(...puntosy)
                this.array.minimo1 = Math.min(...puntosx)
                this.array.maximo1 = Math.max(...puntosy)
            }
            this.array.valoresx1="x="+puntosx[0]+" y="+puntosy[0];
            this.array.valoresx2="x="+puntosx[1]+" y="+puntosy[1];
            this.array.valoresx3="x="+puntosx[2]+" y="+puntosy[2];
            this.array.valoresx4="x="+puntosx[3]+" y="+puntosy[3];
        }
        const Validatenumer = (value) => {
            if(value){
            let date2=value.split('=');
            let date3=date2[1].split(' ');
              if (date3[0]<0) {
                //console.log(date3[0]);
              return translate('resources.validaciones.noNegativo');;
            }
          }
        }
        const validatestring = (value) => {
            if(value){
                let date2=value.split('=')
                let date3=date2[1].split(' ')
                if (isNaN(date3[0])) {
                    //console.log(date3[0]);
                    return translate('resources.validaciones.noIntersectando');
                }else if(!isFinite(date3[0])){
                    return translate('resources.validaciones.noIntersectando');
                }else if(typeof date3[0] === 'undefined'){
                    return translate('resources.validaciones.noIntersectando');
                }
            }
        }
        const validateint = (value) => {
            if(value){
                let date2=value.split('=')
                let date3=date2[1].split(' ')
                date3.push(date3[1])
                if (isNaN(date3[0])) {
                    // console.log(date3[0]);
                    return translate('resources.validaciones.noIntersectando');
                }else if(!isFinite(date3[0])){
                    return translate('resources.validaciones.noIntersectando');
                }else if(typeof date3[0] === 'undefined'){
                    return translate('resources.validaciones.noIntersectando');
                }
            }
        }
        let colores2=[translate('dynatest.resources.colores.title.rectSuper'),
                    translate('dynatest.resources.colores.title.rectDer'),
                    translate('dynatest.resources.colores.title.rectInfe'),
                    translate('dynatest.resources.colores.title.rectIzq')];

        let coloramiento=this.props.record
        if(tipo==="editar" && coloramiento){
            // console.log(tipo);
            //console.log(this.array.valoresx1)
            return(
                <React.Fragment>
                
                    <div style={{display: 'flex'}}>
                        {
                            colores2.map((data, i) => (
                                <div style={{margin: '0px 40px'}}>
                                    <div style={{textAlign: 'center', marginTop: '15px'}}>
                                    {colores2[i]}
                                    </div>
                                    <div>
                                        <NumberInput label="Valor A" source={`valora_${i}`} id={`valora_${i}`} onChange={recta}  validate={[required(),]}/>
                                        <NumberInput label="Valor B" source={`valorb_${i}`} id={`valorb_${i}`} onKeyDown={this.muestra_oculta("contenido_a_mostrar")} onChange={recta}  validate={[required(),]}/>
                                        <NumberInput source={`color_id_${i}`} label=" " defaultValue={i} style={{display: 'none'}}/>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <hr />
                    <div id="contenido_a_mostrar" style={{display: 'none', justifyContent: 'flex',margin: '0px 10px', marginTop: '-20px'}}>
                        <strong style={{display: 'flex', alignItems: 'flex-end', marginRight: '5px', marginBottom: '15px'}}>{translate('dynatest.resources.colores.title.punto1')}</strong> <TextInput   label=" " disabled source="p1edit"  className="align-text"  defaultValue={this.array.valoresx1}/>
                        <span style={{margin: '0px 10px'}}></span>
                    <strong style={{display: 'flex', alignItems: 'flex-end', marginRight: '5px', marginBottom: '15px'}}>{translate('dynatest.resources.colores.title.punto2')}</strong> <TextInput source="p2edit" disabled label=" "  defaultValue={this.array.valoresx2}/>                      
                    <span style={{margin: '0px 10px'}}></span>
                    <strong style={{display: 'flex', alignItems: 'flex-end', marginRight: '5px', marginBottom: '15px'}}>{translate('dynatest.resources.colores.title.punto3')}</strong> <TextInput source="p3edit" disabled label=" "  defaultValue={this.array.valoresx3}/>
                    <span style={{margin: '0px 10px'}}></span>
                    <strong style={{display: 'flex', alignItems: 'flex-end', marginRight: '5px', marginBottom: '15px'}}>{translate('dynatest.resources.colores.title.punto4')}</strong> <TextInput source="p4edit" label=" "disabled  defaultValue={this.array.valoresx4}/>
                    </div>
                    <hr></hr>
                    <div style={{display: 'flex', justifyContent: 'center', marginTop: '-30px'}}>
                    <strong style={{display: 'flex', alignItems: 'flex-end', marginRight: '15px', marginBottom: '15px'}}>{translate('dynatest.resources.colores.title.valorMinX')} </strong><NumberInput label=" " source="min1edit" disabled className="align-text" defaultValue={this.array.minimo1} />
                    <span style={{margin: '0px 30px'}}></span>
                    <strong style={{display: 'flex', alignItems: 'flex-end', marginRight: '15px', marginBottom: '15px'}}>{translate('dynatest.resources.colores.title.valorMinY')} </strong><NumberInput label=" " source="minedit" disabled className="align-text" defaultValue={this.array.minimo}/>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', marginTop: '-30px'}}>
                    <strong style={{display: 'flex', alignItems: 'flex-end', marginRight: '15px', marginBottom: '15px'}}>{translate('dynatest.resources.colores.title.valorMaxX')} </strong><NumberInput label=" " source="maxedit" disabled className="align-text" defaultValue={this.array.maximo}/>
                    <span style={{margin: '0px 30px'}}></span>
                        <strong style={{display: 'flex', alignItems: 'flex-end', marginRight: '15px', marginBottom: '15px'}}>{translate('dynatest.resources.colores.title.valorMaxY')} </strong><NumberInput label=" " source="max1edit" disabled className="align-text" defaultValue={this.array.maximo1} />
                        </div>
                        <hr></hr>
                        <h3 style={{marginLeft: '40%'}}>{translate('dynatest.resources.colores.title.valRetroReflec')}</h3>
                        <NumberInput label="dynatest.resources.colores.title.valorMinCump" source="valor"  validate={[required(), minValue(0)]} className="align-text" style={{width:"300px"}} />
                    <DisabledInput source="dec_length" label=" " style={{display: 'none'}}/>
                </React.Fragment>
            )            
        }
        else{
            return(
                <React.Fragment>
                    <div style={{display: 'flex'}}>
                        {colores2.map((data, i) => (
                            <div style={{margin: '0px 40px'}}>
                                <div style={{textAlign: 'center', marginTop: '15px'}}>
                                {colores2[i]}
                                </div>
                                <div>
                                    <NumberInput label="Valor A" source={`valora_${i}`} id={`valora_${i}`} onChange={recta}  validate={[required(),]}/>
                                    <NumberInput label="Valor B" source={`valorb_${i}`} id={`valorb_${i}`} onKeyDown={this.muestra_oculta("contenido_a_mostrar")} onChange={recta}  validate={[required(),]}/>
                                    <NumberInput source={`color_id_${i}`} label=" " defaultValue={i} style={{display: 'none'}}/>
                                </div>
                            </div>
                        ))}
                    </div>
                    <hr />
                    <div id="contenido_a_mostrar" style={{display: 'none', justifyContent: 'flex',margin: '0px 10px', marginTop: '-20px'}}>
                        <strong style={{display: 'flex', alignItems: 'flex-end', marginRight: '5px', marginBottom: '15px'}}>{translate('dynatest.resources.colores.title.punto1')}</strong> <TextInput   label=" " disabled source="p1"  className="align-text"   defaultValue={this.array.valoresx1}/>
                        <span style={{margin: '0px 10px'}}></span>
                        <strong style={{display: 'flex', alignItems: 'flex-end', marginRight: '5px', marginBottom: '15px'}}>{translate('dynatest.resources.colores.title.punto2')}</strong> <TextInput source="p2" disabled label=" "  defaultValue={this.array.valoresx2}/>
                            <span style={{margin: '0px 10px'}}></span>
                        <strong style={{display: 'flex', alignItems: 'flex-end', marginRight: '5px', marginBottom: '15px'}}>{translate('dynatest.resources.colores.title.punto3')}</strong> <TextInput source="p3" disabled label=" "  defaultValue={this.array.valoresx3}/>
                            <span style={{margin: '0px 10px'}}></span>
                        <strong style={{display: 'flex', alignItems: 'flex-end', marginRight: '5px', marginBottom: '15px'}}>{translate('dynatest.resources.colores.title.punto4')}</strong> <TextInput source="p4" label=" "disabled  defaultValue={this.array.valoresx4}/>
                    </div>
                    <hr></hr>
                    <div style={{display: 'flex', justifyContent: 'center', marginTop: '-30px'}}>
                    <strong style={{display: 'flex', alignItems: 'flex-end', marginRight: '15px', marginBottom: '15px'}}>{translate('dynatest.resources.colores.title.valorMinX')}</strong><NumberInput label=" " source="min1" disabled className="align-text" defaultValue={this.array.minimo1} />
                    <span style={{margin: '0px 30px'}}></span>
                    <strong style={{display: 'flex', alignItems: 'flex-end', marginRight: '15px', marginBottom: '15px'}}>{translate('dynatest.resources.colores.title.valorMinY')}</strong><NumberInput label=" " source="min"  disabled className="align-text" defaultValue={this.array.minimo}/>
                    </div>
                    <div style={{display: 'flex', justifyContent: 'center', marginTop: '-30px'}}>
                        <strong style={{display: 'flex', alignItems: 'flex-end', marginRight: '15px', marginBottom: '15px'}}>{translate('dynatest.resources.colores.title.valorMaxX')} </strong><NumberInput label=" " source="max"  disabled className="align-text" defaultValue={this.array.maximo}/>
                        <span style={{margin: '0px 30px'}}></span>
                        <strong style={{display: 'flex', alignItems: 'flex-end', marginRight: '15px', marginBottom: '15px'}}>{translate('dynatest.resources.colores.title.valorMaxY')} </strong><NumberInput label=" " source="max1"  disabled className="align-text" defaultValue={this.array.maximo1} />
                    </div>
                    <hr></hr>
                    <h3 style={{marginLeft: '40%'}}>{translate('dynatest.resources.colores.title.valRetroReflec')}</h3>
                    <NumberInput label="dynatest.resources.colores.title.valorMinCump" source="valor"  validate={[required(), minValue(0)]} className="align-text" style={{width:"300px"}} />
                    <DisabledInput source="dec_length" label=" " style={{display: 'none'}}/>
                </React.Fragment>
            )
        }
}
}

export default translate(Coloramiento);