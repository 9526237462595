import React, { useState, Fragment } from 'react';
import {
    BooleanInput, ReferenceInput, SelectInput, Toolbar, SaveButton, DateInput, FormTab, TabbedForm, required,
    TextInput, Query, Loading, NumberInput, TextField, ArrayInput, FormDataConsumer, SimpleFormIterator, AutocompleteInput
} from 'react-admin';
import { PimsActionsWithoutImage } from './../../../helpers/PimsActionsWithoutImage';
import { CurrencyInput } from './../../../inputs/CurrencyInput'
import { styles } from './../../../EstilosGrid';
import { PimsCreate } from './../../Create';
import Grid from '@material-ui/core/Grid';
import CancelIcon from '@material-ui/icons/Cancel';
import { withStyles } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SimpleModalWrapped2 from './../localHelpers/SimpleModalWrapped2'
import config from '../../../config/config';
import { translate } from 'react-admin';
let urlAppjs = config.UrlFront;
let urlBack = config.UrlServer;

export const PoliticasMantenimientoCreate = translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;

    const [results, setResults] = useState([]);
    var result = [];
    if (results.length == 0) {
        fetch(urlBack + "/sistemas.json?pagination=off?visibleOnPolicies=1",
            {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                return response.json()
            })
            .then((unidades) => {
                unidades.map(item => {
                    if (item.nombre == "Seguridad") {
                        result.push({
                            id: "/sistemas/" + item.id,
                            name: "Sistemas de Contención"
                        })
                    } else {
                        result.push({
                            id: "/sistemas/" + item.id,
                            name: item.nombre
                        })
                    }
                })
            })
            .then(() => {
                setResults(result.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)));
                // console.log(results);
            });
    }
    const LinkCancelar = () => {
        let redirect = "elemento_reds"
        return <a href={urlAppjs + redirect} class="cancelar" ><CancelIcon style={{ width: '0.8em', paddingRight: '8px' }} />CANCELAR</a>;
    }
    const CustomToolbar = ({ basePath, data, ...props }) => (
        <Toolbar {...props} >
            <SaveButton />
            <LinkCancelar />
        </Toolbar>
    );

    const [sistema, setSistema] = useState();
    const [elemento, setElemento] = useState();
    const [payload, setPayload] = useState({});
    const regSistema = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        if (valor.split("/")[2]) {
            valor = valor.split("/")[2]
        }
        setSistema(valor);
        setPayload({
            pagination: { page: 1, perPage: 25 },
            sort: { field: 'nombre', order: 'ASC' },
            filter: {
                sistema: "/sistemas/" + valor,
                visibleOnPolicies: 1
            }
        })
        setElemento("tipo_elementos");
    }

    const diligenciadoRutinario = translate((value) => {
        var errorMsg = [];
        var returnMsg = "";
        var today = new Date();
        var month = "";
        if ((today.getMonth() + 1) < 10) {
            month = "0" + (today.getMonth() + 1)
        } else {
            month = (today.getMonth() + 1)
        }
        today = today.getFullYear() + "-" + month + "-" + today.getDate();

        if (typeof value !== 'undefined') {
            for (var i = 0; i < value.length; i++) {
                if (value[i].seRealiza == true) {

                    if (value[i].periocidad == null || value[i].periocidad == "" ||
                        value[i].valorU == null || value[i].valorU == "" ||
                        value[i].fechaU == null || value[i].fechaU == "" ||
                        value[i].fuente == null || value[i].fuente == "" ||
                        value[i].fecha == null || value[i].fecha == "" ||
                        value[i].descripcion == null || value[i].descripcion == ""
                    ) {
                        errorMsg.push(translate('resources.validaciones.todosLosDatos') +
                            value[i].nombreElemento +
                            ". ");
                    } else {
                        if (value[i].fecha > today) {
                            errorMsg.push(value[i].nombreElemento + " " + translate('resources.validaciones.fechaFutura'));
                        } else {
                            errorMsg = [];
                        }

                        if (value[i].fechaU > today) {
                            errorMsg.push(
                                value[i].nombreElemento + " " +
                                translate('resources.validaciones.fechaFutura'));
                        } else {
                            errorMsg = [];
                        }
                    }
                }
            }
        }
        // console.log(errorMsg)
        if (errorMsg.length > 0) {
            for (var i = 0; i < errorMsg.length; i++) {
                returnMsg = returnMsg + (i + 1) + ")." + errorMsg[i];
            }
            // errorMsg.map( msg => {returnMsg = returnMsg + msg})
            return returnMsg;
        }
    })

    const diligenciadoPeriodico = translate((value) => {
        var errorMsg = [];
        var returnMsg = "";
        var today = new Date();
        var month = "";
        if ((today.getMonth() + 1) < 10) {
            month = "0" + (today.getMonth() + 1)
        } else {
            month = (today.getMonth() + 1)
        }
        today = today.getFullYear() + "-" + month + "-" + today.getDate();

        if (typeof value !== 'undefined') {
            for (var i = 0; i < value.length; i++) {
                if (value[i].seRealiza == true) {

                    if (value[i].periocidad == null || value[i].periocidad == "" ||
                        value[i].valorU == null || value[i].valorU == "" ||
                        value[i].fechaU == null || value[i].fechaU == "" ||
                        value[i].fuente == null || value[i].fuente == "" ||
                        value[i].fecha == null || value[i].fecha == "" ||
                        value[i].descripcion == null || value[i].descripcion == ""
                    ) {
                        errorMsg.push(translate('resources.validaciones.todosLosDatos') +
                            value[i].nombreElemento +
                            ". ");
                    } else {
                        if (value[i].fecha > today) {
                            errorMsg.push(
                                value[i].nombreElemento + " " +
                                translate('resources.validaciones.fechaFutura'));
                        } else {
                            errorMsg = [];
                        }

                        if (value[i].fechaU > today) {
                            errorMsg.push(
                                value[i].nombreElemento + " " +
                                translate('resources.validaciones.fechaFutura'));
                        } else {
                            errorMsg = [];
                        }
                    }
                }
            }
        }
        // console.log(errorMsg)
        if (errorMsg.length > 0) {
            for (var i = 0; i < errorMsg.length; i++) {
                returnMsg = returnMsg + (i + 1) + ")." + errorMsg[i];
            }
            // errorMsg.map( msg => {returnMsg = returnMsg + msg})
            return returnMsg;
        }
    })

    const diligenciadoRehabilitacion = translate((value) => {
        var errorMsg = [];
        var returnMsg = "";
        var today = new Date();
        var month = "";
        if ((today.getMonth() + 1) < 10) {
            month = "0" + (today.getMonth() + 1)
        } else {
            month = (today.getMonth() + 1)
        }
        today = today.getFullYear() + "-" + month + "-" + today.getDate();

        if (typeof value !== 'undefined') {
            for (var i = 0; i < value.length; i++) {
                if (value[i].seRealiza == true) {

                    if (value[i].periocidad == null || value[i].periocidad == "" ||
                        value[i].valorU == null || value[i].valorU == "" ||
                        value[i].fechaU == null || value[i].fechaU == "" ||
                        value[i].fuente == null || value[i].fuente == "" ||
                        value[i].fecha == null || value[i].fecha == "" ||
                        value[i].descripcion == null || value[i].descripcion == ""
                    ) {
                        errorMsg.push(translate('resources.validaciones.todosLosDatos')+
                            value[i].nombreElemento +
                            ". ");
                    } else {
                        if (value[i].fecha > today) {
                            errorMsg.push(
                                value[i].nombreElemento + " " +
                                translate('resources.validaciones.fechaFutura'));
                        } else {
                            errorMsg = [];
                        }

                        if (value[i].fechaU > today) {
                            errorMsg.push(
                                value[i].nombreElemento + " " +
                                translate('resources.validaciones.fechaFutura'));
                        } else {
                            errorMsg = [];
                        }
                    }
                }
            }
        }
        // console.log(errorMsg)
        if (errorMsg.length > 0) {
            for (var i = 0; i < errorMsg.length; i++) {
                returnMsg = returnMsg + (i + 1) + ")." + errorMsg[i];
            }
            // errorMsg.map( msg => {returnMsg = returnMsg + msg})
            return returnMsg;
        }
    })

    const diligenciadoCambio = translate((value) => {
        var errorMsg = [];
        var returnMsg = "";
        var today = new Date();
        var month = "";
        if ((today.getMonth() + 1) < 10) {
            month = "0" + (today.getMonth() + 1)
        } else {
            month = (today.getMonth() + 1)
        }
        today = today.getFullYear() + "-" + month + "-" + today.getDate();

        if (typeof value !== 'undefined') {
            for (var i = 0; i < value.length; i++) {
                if (value[i].seRealiza == true) {

                    if (value[i].periocidad == null || value[i].periocidad == "" ||
                        value[i].valorU == null || value[i].valorU == "" ||
                        value[i].fechaU == null || value[i].fechaU == "" ||
                        value[i].fuente == null || value[i].fuente == "" ||
                        value[i].fecha == null || value[i].fecha == "" ||
                        value[i].descripcion == null || value[i].descripcion == ""
                    ) {
                        errorMsg.push(translate('resources.validaciones.todosLosDatos') +
                            value[i].nombreElemento +
                            ". ");
                    } else {
                        if (value[i].fecha > today) {
                            errorMsg.push(
                                value[i].nombreElemento + " " +
                                translate('resources.validaciones.fechaFutura'));
                        } else {
                            errorMsg = [];
                        }

                        if (value[i].fechaU > today) {
                            errorMsg.push(
                                value[i].nombreElemento + " " +
                                translate('resources.validaciones.fechaFutura'));
                        } else {
                            errorMsg = [];
                        }
                    }
                }
            }
        }
        // console.log(errorMsg)
        if (errorMsg.length > 0) {
            for (var i = 0; i < errorMsg.length; i++) {
                returnMsg = returnMsg + (i + 1) + ")." + errorMsg[i];
            }
            // errorMsg.map( msg => {returnMsg = returnMsg + msg})
            return returnMsg;
        }
    })



    const [validations, setValidations] = useState()

    return (
        <PimsCreate  {...props} actions={<PimsActionsWithoutImage />} >
            <TabbedForm
                redirect="list"
            //toolbar={<CustomToolbar />}
            >
                {/* ------ ELEMENTO DE MANTENIMIENTO ------ */}
                <FormTab label="dynatest.resources.politicas_mantenimientos.tabs.tabsTitle.identificacion" redirect="list" >
                    <TextInput
                        // label="tipo"
                        source="tipo"
                        validate={[required()]}
                        defaultValue="normal"
                        formClassName={classes.ocultar}
                    />
                    <Grid container justify="flex-start" alignItems="flex-end" spacing={24}>
                        <Grid item xs={4}>
                            <ReferenceInput
                                label="resources.politicas_mantenimientos.fields.nombre"
                                source="nombre"
                                reference="politicas_nombres"
                                validate={[required()]}
                                fullWidth
                            >
                                <SelectInput optionText="nombre" fullWidth />
                            </ReferenceInput>
                        </Grid>
                        <Grid item xs={1}>
                            <SimpleModalWrapped2 />
                        </Grid>
                        <Grid item xs={4}>
                            <ReferenceInput
                                label="resources.politicas_mantenimientos.fields.unidadFuncional"
                                source="unidadFuncional"
                                reference="unidad_funcionals"
                                validate={[required()]}
                                fullWidth
                            >
                                <AutocompleteInput optionText="fullCodigos" options={{ fullWidth: true }} fullWidth />
                            </ReferenceInput>
                        </Grid>
                        <Grid item xs={3}>
                            <SelectInput
                                label="resources.politicas_mantenimientos.fields.sistema"
                                source="sistema"
                                choices={results}
                                onChange={regSistema}
                                validate={[required()]}
                                fullWidth
                            />
                        </Grid>


                    </Grid>
                    <FormDataConsumer>
                        {({ formData, ...rest }) =>
                            <Grid container spacing={24}>
                                <Grid item xs={12}>
                                    {formData.sistema &&
                                        <div className={classes.fieldCol12}>
                                            <ExpansionPanel>
                                                <ExpansionPanelSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1bh-content"
                                                    id="panel1bh-header"
                                                >
                                                    <Typography >{translate('dynatest.resources.politicas_mantenimientos.expansionPanel.ElemenMantenRutinario')}</Typography>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails>
                                                    <Query type="GET_LIST" resource={formData.sistema != "/sistemas/SEN" ? "tipo_elementos" : "tipo_elemento_senals"} payload={{
                                                        pagination: { page: 1, perPage: 25 },
                                                        sort: { field: 'nombre', order: 'ASC' },
                                                        filter: {
                                                            sistema: formData.sistema,
                                                            visibleOnPolicies: 1
                                                        }
                                                    }}>
                                                        {({ data, loading, error }) => {
                                                            if (loading) {
                                                                return (
                                                                    <Fragment>
                                                                        <Loading />
                                                                    </Fragment>
                                                                );
                                                            }
                                                            if (error) { return error; }
                                                            var defaultValues = [];
                                                            var datos = [];
                                                            {
                                                                data.map(index => {
                                                                    datos = {
                                                                        sistema: "/sistemas/" + sistema,
                                                                        tipoElemento: formData.sistema != "/sistemas/SEN" ? index.id : null,
                                                                        tipoElementoSenal: formData.sistema == "/sistemas/SEN" ? index.id : null,
                                                                        nombreElemento: index.nombre,
                                                                        seRealiza: false,
                                                                        unidadMedida: index.unidadMedida,
                                                                        periocidad: null,
                                                                        valorU: null,
                                                                        fechaU: null,
                                                                        fuente: null,
                                                                        fecha: formData.fechaRutinario,
                                                                        descripcion: null
                                                                    }
                                                                    defaultValues.push(datos)
                                                                })
                                                            }
                                                            return (
                                                                <div className={classes.fieldCol12}>
                                                                    <DateInput label="resources.politicas_mantenimientos.fields.FechaIniPeriodicidad" fullWidth source="fechaRutinario" validate={[required()]} defaultValue={(formData.hasOwnProperty("@id") ? formData.politicasElementos[0].fecha : null)} formClassName={classes.fieldCol12} />
                                                                    <br />
                                                                    <ArrayInput
                                                                        label=" "
                                                                        source="politicasElementos"
                                                                        validate={[diligenciadoRutinario]}
                                                                        defaultValue={defaultValues}
                                                                        fullWidth
                                                                        formClassName={classes.fieldCol12}
                                                                    >
                                                                        <SimpleFormIterator disableAdd disableRemove>
                                                                            {/* <TextInput disabled source="tipoMantenimiento" fullWidth formClassName={classes.fieldCol12} /> */}
                                                                            <TextInput disabled label="resources.politicas_mantenimientos.fields.sistema" source="sistema" fullWidth formClassName={classes.ocultar} />
                                                                            <DateInput label="resources.politicas_mantenimientos.fields.fecha" source="fecha" fullWidth formClassName={classes.ocultar} />
                                                                            <BooleanInput label="dynatest.resources.politicas_mantenimientos.boolean.seRealiza" source="seRealiza" fullWidth formClassName={classes.fieldCol3} />
                                                                            {formData.sistema != "/sistemas/SEN" ?
                                                                                <ReferenceInput
                                                                                    disabled
                                                                                    label="resources.politicas_mantenimientos.fields.nombre"
                                                                                    source="tipoElemento"
                                                                                    reference="tipo_elementos"
                                                                                    fullWidth
                                                                                    validate={[required()]}
                                                                                    formClassName={classes.fieldCol9}
                                                                                >
                                                                                    <AutocompleteInput optionText="nombre" options={{ fullWidth: true, disabled: true }} />
                                                                                </ReferenceInput>
                                                                                :
                                                                                <ReferenceInput
                                                                                    disabled
                                                                                    label="resources.politicas_mantenimientos.fields.nombre"
                                                                                    source="tipoElementoSenal"
                                                                                    reference="tipo_elemento_senals"
                                                                                    fullWidth
                                                                                    validate={[required()]}
                                                                                    formClassName={classes.fieldCol9}
                                                                                >
                                                                                    <AutocompleteInput optionText="nombre" options={{ fullWidth: true, disabled: true }} />
                                                                                </ReferenceInput>
                                                                            }
                                                                            <br />
                                                                            <ReferenceInput disabled label="resources.politicas_mantenimientos.fields.unidadMedida" source="unidadMedida" reference="unidad_medidas" fullWidth validate={[required()]} formClassName={classes.fieldCol3} >
                                                                                <AutocompleteInput optionText="abreviatura" options={{ fullWidth: true, disabled: true }} />
                                                                            </ReferenceInput>
                                                                            <AutocompleteInput
                                                                                label="resources.politicas_mantenimientos.fields.periocidad"
                                                                                source="periocidad"
                                                                                fullWidth
                                                                                options={{ fullWidth: true }}
                                                                                choices={[
                                                                                    { id: 1, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.Mes1' },
                                                                                    { id: 3, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.Meses3' },
                                                                                    { id: 6, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.Meses6' },
                                                                                    { id: 9, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.Meses9' },
                                                                                    { id: 12, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.Meses12' },
                                                                                    { id: 18, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.Meses18' },
                                                                                ]}
                                                                                formClassName={classes.fieldCol2}
                                                                            />
                                                                            <CurrencyInput label="resources.politicas_mantenimientos.fields.valorU" source="valorU" fullWidth className="align-number" formClassName={classes.fieldCol3} />
                                                                            <DateInput label="resources.politicas_mantenimientos.fields.fechaU" fullWidth source="fechaU" formClassName={classes.fieldCol3} />
                                                                            <TextInput label="resources.politicas_mantenimientos.fields.fuente" source="fuente" fullWidth formClassName={classes.fieldCol3} />
                                                                            <br />
                                                                            <TextInput label="resources.politicas_mantenimientos.fields.descripcion" source="descripcion" fullWidth formClassName={classes.fieldCol12} />
                                                                        </SimpleFormIterator>
                                                                    </ArrayInput>

                                                                </div>
                                                            );
                                                        }}
                                                    </Query>

                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                            <ExpansionPanel >
                                                <ExpansionPanelSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1bh-content"
                                                    id="panel1bh-header"
                                                >
                                                    <Typography >{translate('dynatest.resources.politicas_mantenimientos.expansionPanel.ElemenMantenPeriodico')}</Typography>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails>
                                                    <Query type="GET_LIST" resource={formData.sistema != "/sistemas/SEN" ? "tipo_elementos" : "tipo_elemento_senals"} payload={{
                                                        pagination: { page: 1, perPage: 25 },
                                                        sort: { field: 'nombre', order: 'ASC' },
                                                        filter: {
                                                            sistema: formData.sistema,
                                                            visibleOnPolicies: 1
                                                        }
                                                    }}>
                                                        {({ data, loading, error }) => {
                                                            if (loading) { return <Loading />; }
                                                            if (error) { return error; }
                                                            // return <div>{console.log(data)}</div>;
                                                            var defaultValues = [];
                                                            var datos = [];
                                                            {
                                                                data.map(index => {
                                                                    datos = {
                                                                        sistema: "/sistemas/" + sistema,
                                                                        tipoElemento: formData.sistema != "/sistemas/SEN" ? index.id : null,
                                                                        tipoElementoSenal: formData.sistema == "/sistemas/SEN" ? index.id : null,
                                                                        nombreElemento: index.nombre,
                                                                        seRealiza: false,
                                                                        unidadMedida: index.unidadMedida,
                                                                        periocidad: null,
                                                                        valorU: null,
                                                                        fechaU: null,
                                                                        fuente: null,
                                                                        fecha: formData.fechaPeriodico,
                                                                        descripcion: null
                                                                    }
                                                                    defaultValues.push(datos)
                                                                })
                                                            }
                                                            return (
                                                                <div className={classes.fieldCol12}>
                                                                    <DateInput label="resources.politicas_mantenimientos.fields.FechaIniPeriodicidad" fullWidth source="fechaPeriodico" validate={[required()]} defaultValue={(formData.hasOwnProperty("@id") ? formData.politicasElementoPeriodicos[0].fecha : null)} formClassName={classes.fieldCol12} />
                                                                    <br />
                                                                    <ArrayInput
                                                                        label=" "
                                                                        source="politicasElementoPeriodicos"
                                                                        validate={[diligenciadoPeriodico]}
                                                                        defaultValue={defaultValues}
                                                                    >
                                                                        <SimpleFormIterator disableAdd disableRemove>
                                                                            <TextInput disabled label="resources.politicas_mantenimientos.fields.sistema" source="sistema" fullWidth formClassName={classes.ocultar} />
                                                                            <DateInput disabled label="resources.politicas_mantenimientos.fields.fecha" source="fecha" fullWidth formClassName={classes.ocultar} />
                                                                            <BooleanInput label="dynatest.resources.politicas_mantenimientos.boolean.seRealiza" source="seRealiza" fullWidth formClassName={classes.fieldCol3} />
                                                                            {/* <TextInput disabled label="Nombre" source="nombreElemento" fullWidth formClassName={classes.fieldCol9} /> */}
                                                                            {formData.sistema != "/sistemas/SEN" ?
                                                                                <ReferenceInput
                                                                                    disabled
                                                                                    label="resources.politicas_mantenimientos.fields.nombre"
                                                                                    source="tipoElemento"
                                                                                    reference="tipo_elementos"
                                                                                    fullWidth
                                                                                    validate={[required()]}
                                                                                    formClassName={classes.fieldCol9}
                                                                                >
                                                                                    <AutocompleteInput optionText="nombre" options={{ fullWidth: true, disabled: true }} />
                                                                                </ReferenceInput>
                                                                                :
                                                                                <ReferenceInput
                                                                                    disabled
                                                                                    label="resources.politicas_mantenimientos.fields.nombre"
                                                                                    source="tipoElementoSenal"
                                                                                    reference="tipo_elemento_senals"
                                                                                    fullWidth
                                                                                    validate={[required()]}
                                                                                    formClassName={classes.fieldCol9}
                                                                                >
                                                                                    <AutocompleteInput optionText="nombre" options={{ fullWidth: true, disabled: true }} />
                                                                                </ReferenceInput>
                                                                            }
                                                                            <br />
                                                                            <ReferenceInput disabled label="resources.politicas_mantenimientos.fields.unidadMedida" source="unidadMedida" reference="unidad_medidas" fullWidth validate={[required()]} formClassName={classes.fieldCol3} >
                                                                                <AutocompleteInput optionText="abreviatura" options={{ fullWidth: true, disabled: true }} />
                                                                            </ReferenceInput>
                                                                            <AutocompleteInput
                                                                                label="resources.politicas_mantenimientos.fields.periocidad"
                                                                                source="periocidad"
                                                                                fullWidth
                                                                                options={{ fullWidth: true }}
                                                                                choices={[
                                                                                    { id: 1, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.Mes1' },
                                                                                    { id: 3, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.Meses3' },
                                                                                    { id: 6, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.Meses6' },
                                                                                    { id: 9, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.Meses9' },
                                                                                    { id: 12, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.Meses12' },
                                                                                    { id: 18, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.Meses18' },
                                                                                    { id: 24, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.Meses24' },
                                                                                    { id: 36, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.Meses36' },
                                                                                ]}
                                                                                formClassName={classes.fieldCol2}
                                                                            />
                                                                            <CurrencyInput label="resources.politicas_mantenimientos.fields.valorU" source="valorU" fullWidth className="align-number" formClassName={classes.fieldCol3} />
                                                                            <DateInput label="resources.politicas_mantenimientos.fields.fechaU" fullWidth source="fechaU" formClassName={classes.fieldCol3} />
                                                                            <TextInput label="resources.politicas_mantenimientos.fields.fuente" source="fuente" fullWidth formClassName={classes.fieldCol3} />
                                                                            <br />
                                                                            <TextInput label="resources.politicas_mantenimientos.fields.descripcion" source="descripcion" fullWidth formClassName={classes.fieldCol12} />
                                                                        </SimpleFormIterator>
                                                                    </ArrayInput>

                                                                </div>
                                                            );
                                                        }}
                                                    </Query>

                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                            <ExpansionPanel >
                                                <ExpansionPanelSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1bh-content"
                                                    id="panel1bh-header"
                                                >
                                                    <Typography >{translate('dynatest.resources.politicas_mantenimientos.expansionPanel.ElemenMantenRehabilitacion')}</Typography>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails>
                                                    <Query type="GET_LIST" resource={formData.sistema != "/sistemas/SEN" ? "tipo_elementos" : "tipo_elemento_senals"} payload={{
                                                        pagination: { page: 1, perPage: 25 },
                                                        sort: { field: 'nombre', order: 'ASC' },
                                                        filter: {
                                                            sistema: formData.sistema,
                                                            visibleOnPolicies: 1
                                                        }
                                                    }}>
                                                        {({ data, loading, error }) => {
                                                            if (loading) { return <Loading />; }
                                                            if (error) { return error; }
                                                            // return <div>{console.log(data)}</div>;
                                                            var defaultValues = [];
                                                            var datos = [];
                                                            {
                                                                data.map(index => {
                                                                    datos = {
                                                                        sistema: "/sistemas/" + sistema,

                                                                        tipoElemento: formData.sistema != "/sistemas/SEN" ? index.id : null,
                                                                        tipoElementoSenal: formData.sistema == "/sistemas/SEN" ? index.id : null,
                                                                        nombreElemento: index.nombre,
                                                                        seRealiza: false,
                                                                        unidadMedida: index.unidadMedida,
                                                                        periocidad: null,
                                                                        valorU: null,
                                                                        fechaU: null,
                                                                        fuente: null,
                                                                        fecha: formData.fechaRehabilitacion,
                                                                        descripcion: null
                                                                    }
                                                                    defaultValues.push(datos)
                                                                })
                                                            }
                                                            return (
                                                                <div className={classes.fieldCol12}>
                                                                    <DateInput label="resources.politicas_mantenimientos.fields.FechaIniPeriodicidad" fullWidth source="fechaRehabilitacion" validate={[required()]} defaultValue={(formData.hasOwnProperty("@id") ? formData.politicasElementoRehabilitacions[0].fecha : null)} formClassName={classes.fieldCol12} />
                                                                    <ArrayInput
                                                                        label=" "
                                                                        source="politicasElementoRehabilitacions"
                                                                        validate={[diligenciadoRehabilitacion]}
                                                                        defaultValue={defaultValues}
                                                                    >
                                                                        <SimpleFormIterator disableAdd disableRemove>
                                                                            <TextInput disabled label="resources.politicas_mantenimientos.fields.sistema" source="sistema" fullWidth formClassName={classes.ocultar} />
                                                                            <DateInput label="resources.politicas_mantenimientos.fields.fecha" source="fecha" fullWidth formClassName={classes.ocultar} />
                                                                            <BooleanInput label="dynatest.resources.politicas_mantenimientos.boolean.seRealiza" source="seRealiza" fullWidth formClassName={classes.fieldCol3} />
                                                                            {/* <TextInput disabled label="Nombre" source="nombreElemento" fullWidth formClassName={classes.fieldCol9} /> */}
                                                                            {formData.sistema != "/sistemas/SEN" ?
                                                                                <ReferenceInput
                                                                                    disabled
                                                                                    label="resources.politicas_mantenimientos.fields.nombre"
                                                                                    source="tipoElemento"
                                                                                    reference="tipo_elementos"
                                                                                    fullWidth
                                                                                    validate={[required()]}
                                                                                    formClassName={classes.fieldCol9}
                                                                                >
                                                                                    <AutocompleteInput optionText="nombre" options={{ fullWidth: true, disabled: true }} />
                                                                                </ReferenceInput>
                                                                                :
                                                                                <ReferenceInput
                                                                                    disabled
                                                                                    label="resources.politicas_mantenimientos.fields.nombre"
                                                                                    source="tipoElementoSenal"
                                                                                    reference="tipo_elemento_senals"
                                                                                    fullWidth
                                                                                    validate={[required()]}
                                                                                    formClassName={classes.fieldCol9}
                                                                                >
                                                                                    <AutocompleteInput optionText="nombre" options={{ fullWidth: true, disabled: true }} />
                                                                                </ReferenceInput>
                                                                            }
                                                                            <br />
                                                                            <ReferenceInput disabled label="resources.politicas_mantenimientos.fields.unidadMedida" source="unidadMedida" reference="unidad_medidas" fullWidth validate={[required()]} formClassName={classes.fieldCol3}>
                                                                                <AutocompleteInput optionText="abreviatura" options={{ fullWidth: true, disabled: true }} />
                                                                            </ReferenceInput>
                                                                            <AutocompleteInput
                                                                                label="resources.politicas_mantenimientos.fields.periocidad"
                                                                                source="periocidad"
                                                                                fullWidth
                                                                                options={{ fullWidth: true }}
                                                                                choices={[
                                                                                    { id: 2, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios2' }, 
                                                                                    { id: 2.5, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios2_5' },
                                                                                    { id: 3, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios3' }, 
                                                                                    { id: 3.5, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios3_5' },
                                                                                    { id: 4, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios4' }, 
                                                                                    { id: 4.5, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios4_5' },
                                                                                    { id: 5, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios5' }, 
                                                                                    { id: 5.5, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios5_5' },
                                                                                    { id: 6, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios6' }, 
                                                                                    { id: 6.5, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios6_5' },
                                                                                    { id: 7, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios7' }, 
                                                                                    { id: 7.5, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios7_5' },
                                                                                    { id: 8, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios8' }, 
                                                                                    { id: 8.5, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios8_5' },
                                                                                    { id: 9, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios9' }, 
                                                                                    { id: 9.5, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios9_5' },
                                                                                    { id: 10, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios10' }
                                                                                ]}
                                                                                formClassName={classes.fieldCol2}
                                                                            />
                                                                            <CurrencyInput label="resources.politicas_mantenimientos.fields.valorU" source="valorU" fullWidth className="align-number" formClassName={classes.fieldCol2} />
                                                                            <DateInput label="resources.politicas_mantenimientos.fields.fechaU" fullWidth source="fechaU" formClassName={classes.fieldCol3} />
                                                                            <TextInput label="resources.politicas_mantenimientos.fields.fuente" source="fuente" fullWidth formClassName={classes.fieldCol3} />
                                                                            <NumberInput label="resources.politicas_mantenimientos.fields.prolVidaUtil" source="prolVidaUtil" fullWidth formClassName={classes.fieldCol1} />
                                                                            <br />
                                                                            <TextInput label="resources.politicas_mantenimientos.fields.descripcion" source="descripcion" fullWidth formClassName={classes.fieldCol12} />
                                                                        </SimpleFormIterator>
                                                                    </ArrayInput>

                                                                </div>
                                                            );
                                                        }}
                                                    </Query>

                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                            <ExpansionPanel >
                                                <ExpansionPanelSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1bh-content"
                                                    id="panel1bh-header"
                                                >
                                                    <Typography >{translate('dynatest.resources.politicas_mantenimientos.expansionPanel.ElemenMantenReconstruccion_Cambio')}</Typography>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails>
                                                    <Query type="GET_LIST" resource={formData.sistema != "/sistemas/SEN" ? "tipo_elementos" : "tipo_elemento_senals"} payload={{
                                                        pagination: { page: 1, perPage: 25 },
                                                        sort: { field: 'nombre', order: 'ASC' },
                                                        filter: {
                                                            sistema: formData.sistema,
                                                            visibleOnPolicies: 1
                                                        }
                                                    }}>
                                                        {({ data, loading, error }) => {
                                                            if (loading) { return <Loading />; }
                                                            if (error) { return error; }
                                                            // return <div>{console.log(data)}</div>;
                                                            var defaultValues = [];
                                                            var datos = [];

                                                            {
                                                                data.map(index => {
                                                                    datos = {
                                                                        sistema: "/sistemas/" + sistema,
                                                                        tipoElemento: formData.sistema != "/sistemas/SEN" ? index.id : null,
                                                                        tipoElementoSenal: formData.sistema == "/sistemas/SEN" ? index.id : null,
                                                                        nombreElemento: index.nombre,
                                                                        seRealiza: false,
                                                                        unidadMedida: index.unidadMedida,
                                                                        periocidad: null,
                                                                        valorU: null,
                                                                        fechaU: null,
                                                                        fuente: null,
                                                                        fecha: formData.fechaCambio,
                                                                        descripcion: null
                                                                    }
                                                                    defaultValues.push(datos)
                                                                })
                                                            }
                                                            return (
                                                                <div className={classes.fieldCol12}>
                                                                    <DateInput label="resources.politicas_mantenimientos.fields.FechaIniPeriodicidad" fullWidth source="fechaCambio" defaultValue={(formData.hasOwnProperty("@id") ? formData.politicasElementoCambios[0].fecha : null)} validate={[required()]} formClassName={classes.fieldCol12} />
                                                                    <ArrayInput
                                                                        label=" "
                                                                        source="politicasElementoCambios"
                                                                        validate={[diligenciadoCambio]}
                                                                        defaultValue={defaultValues}
                                                                    >
                                                                        <SimpleFormIterator disableAdd disableRemove>
                                                                            <TextInput disabled label="resources.politicas_mantenimientos.fields.sistema" source="sistema" fullWidth formClassName={classes.ocultar} />
                                                                            <DateInput label="resources.politicas_mantenimientos.fields.fecha" source="fecha" fullWidth formClassName={classes.ocultar} />
                                                                            <BooleanInput label="dynatest.resources.politicas_mantenimientos.boolean.seRealiza" source="seRealiza" fullWidth formClassName={classes.fieldCol3} />
                                                                            {/* <TextInput disabled label="Nombre" source="nombreElemento" fullWidth formClassName={classes.fieldCol9} /> */}
                                                                            {formData.sistema != "/sistemas/SEN" ?
                                                                                <ReferenceInput
                                                                                    disabled
                                                                                    label="resources.politicas_mantenimientos.fields.nombre"
                                                                                    source="tipoElemento"
                                                                                    reference="tipo_elementos"
                                                                                    sort={{ field: 'nombre', order: 'ASC' }}
                                                                                    fullWidth
                                                                                    validate={[required()]}
                                                                                    formClassName={classes.fieldCol9}
                                                                                >
                                                                                    <AutocompleteInput optionText="nombre" options={{ fullWidth: true, disabled: true }} />
                                                                                </ReferenceInput>
                                                                                :
                                                                                <ReferenceInput
                                                                                    disabled
                                                                                    label="resources.politicas_mantenimientos.fields.nombre"
                                                                                    source="tipoElementoSenal"
                                                                                    reference="tipo_elemento_senals"
                                                                                    fullWidth
                                                                                    validate={[required()]}
                                                                                    formClassName={classes.fieldCol9}
                                                                                >
                                                                                    <AutocompleteInput optionText="nombre" options={{ fullWidth: true, disabled: true }} />
                                                                                </ReferenceInput>
                                                                            }
                                                                            <br />
                                                                            <SelectInput label="resources.politicas_mantenimientos.fields.operacion" source="operacion" fullWidth formClassName={classes.fieldCol12} choices={[
                                                                                { id: 'reconstruccion', name: 'dynatest.resources.politicas_mantenimientos.select.operacion.reconstruccion' },
                                                                                { id: 'cambio', name: 'dynatest.resources.politicas_mantenimientos.select.operacion.cambio' }
                                                                            ]} defaultValue="cambio"
                                                                                formClassName={classes.fieldCol3}
                                                                            />
                                                                            <br />
                                                                            <ReferenceInput disabled label="resources.politicas_mantenimientos.fields.unidadMedida" source="unidadMedida" reference="unidad_medidas" fullWidth validate={[required()]} formClassName={classes.fieldCol1}>
                                                                                <AutocompleteInput optionText="abreviatura" options={{ fullWidth: true, disabled: true }} />
                                                                            </ReferenceInput>

                                                                            <AutocompleteInput
                                                                                label="resources.politicas_mantenimientos.fields.periocidad"
                                                                                source="periocidad"
                                                                                fullWidth
                                                                                options={{ fullWidth: true }}
                                                                                formClassName={classes.fieldCol12}
                                                                                choices={[
                                                                                    { id: 3, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios3' }, 
                                                                                    { id: 4, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios4' }, 
                                                                                    { id: 5, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios5' },
                                                                                    { id: 6, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios6' }, 
                                                                                    { id: 7, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios7' }, 
                                                                                    { id: 8, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios8' },
                                                                                    { id: 9, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios9' }, 
                                                                                    { id: 10, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios10' }, 
                                                                                    { id: 11, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios11' },
                                                                                    { id: 12, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios12' }, 
                                                                                    { id: 13, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios13' }, 
                                                                                    { id: 14, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios14' },
                                                                                    { id: 15, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios15' }, 
                                                                                    { id: 16, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios16' }, 
                                                                                    { id: 17, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios17' },
                                                                                    { id: 18, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios18' }, 
                                                                                    { id: 19, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios19' }, 
                                                                                    { id: 20, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios20' },
                                                                                    { id: 21, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios21' }, 
                                                                                    { id: 22, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios22' }, 
                                                                                    { id: 23, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios23' },
                                                                                    { id: 24, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios24' }, 
                                                                                    { id: 25, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios25' }, 
                                                                                    { id: 26, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios26' },
                                                                                    { id: 27, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios27' }, 
                                                                                    { id: 28, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios28' }, 
                                                                                    { id: 29, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios29' },
                                                                                    { id: 30, name: 'dynatest.resources.politicas_mantenimientos.select.periocidad.anios30' }
                                                                                ]}
                                                                                formClassName={classes.fieldCol2}
                                                                            />
                                                                            <CurrencyInput label="resources.politicas_mantenimientos.fields.valorU" source="valorU" fullWidth className="align-number" formClassName={classes.fieldCol2} />
                                                                            <DateInput label="resources.politicas_mantenimientos.fields.fechaU" fullWidth source="fechaU" formClassName={classes.fieldCol3} />
                                                                            <TextInput label="resources.politicas_mantenimientos.fields.fuente" source="fuente" fullWidth formClassName={classes.fieldCol3} />
                                                                            <NumberInput label="resources.politicas_mantenimientos.fields.prolVidaUtil" source="prolVidaUtil" fullWidth formClassName={classes.fieldCol1} />
                                                                            <br />
                                                                            <TextInput label="resources.politicas_mantenimientos.fields.descripcion" source="descripcion" fullWidth formClassName={classes.fieldCol12} />

                                                                        </SimpleFormIterator>
                                                                    </ArrayInput>
                                                                </div>
                                                            );
                                                        }}
                                                    </Query>
                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                        </div>
                                    }
                                </Grid>
                            </Grid>
                        }
                    </FormDataConsumer>
                </FormTab>
            </TabbedForm>
        </PimsCreate>
    )

}));
