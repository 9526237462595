import React, {useState, Fragment} from 'react';
import { SimpleForm, TabbedForm, FormTab, SelectInput, NumberInput, TextInput, ReferenceInput, DateInput, ArrayInput, SimpleFormIterator, AutocompleteInput, FormDataConsumer} from 'react-admin';
import { PRInput } from './../../../inputs/PRInput';
import { withStyles } from '@material-ui/core';
import { Edit } from './../../Edit';
import  {styles}  from './../../../EstilosGrid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import EvaluacionPorSegmento from './EvaluacionPorSegmento'
import { required, minLength, maxLength } from 'react-admin';
import { translate } from 'react-admin';

export const CargarInformacionEdit = translate(withStyles(styles)(({ classes, record, ...props }) => {
    const { translate } = props;
    const[tipoMedicion, setTipoMedicion] = useState('');
    let idproyecto=localStorage.getItem("id_project");
    const validartipoMedida=(value)=>{
        setTipoMedicion(value);        
    }
    return(
        <Edit  title="dynatest.resources.indicadores_cargar_informacions.title.editTitle" {...props}  undoable={false}>
         <SimpleForm submitOnEnter={false} redirect="list" > 
         <Divider fullWidth/>
            <Typography component="b" variant="b" formClassName={classes.grid_cont12} fullWidth>{translate('dynatest.resources.indicadores_cargar_informacions.subTitle.SeleccionIndicador')}</Typography>
            <ReferenceInput label="resources.indicadores_cargar_informacions.fields.indicador" source="indicador" reference="parametros" sort={{ field: 'id', order: 'ASC' }} fullWidth formClassName={classes.grid_cont12} disabled>
                <SelectInput optionText="codigoCompleto" />
            </ReferenceInput>
            <Divider fullWidth/>
            <Typography component="b" variant="b" formClassName={classes.grid_cont12} fullWidth>{translate('dynatest.resources.indicadores_cargar_informacions.subTitle.Detalles')}</Typography>
            <ReferenceInput label="resources.indicadores_cargar_informacions.fields.unidadFuncional" source="unidadFuncional" reference="unidad_funcionals" 
            filterToQuery={searchText => ({ codigo: searchText })} 
            sort={{ field: 'codigo', order: 'asc' }} 
            formClassName={classes.grid_cont3_f}
            disabled
            fullWidth
            >
               <SelectInput  options={{ fullWidth: true }} optionText="fullCodigos" fullWidth/>
            </ReferenceInput>
            <ReferenceInput label="resources.indicadores_cargar_informacions.fields.tramo" source="tramo" reference="tramos" 
                filterToQuery={searchText => ({ codigo: searchText })} 
                sort={{ field: 'codigo', order: 'ASC' }} 
                fullWidth 
                formClassName={classes.grid_cont3_s}
                disabled
                >
                <SelectInput options={{ fullWidth: true }} optionText="fullCodigos" />
            </ReferenceInput>   

            <PRInput label="resources.indicadores_cargar_informacions.fields.abini" id="ingresoAbInicio" source="abiniFloat" fullWidth   formClassName={classes.grid_cont3_s} disabled/>
            <PRInput label="resources.indicadores_cargar_informacions.fields.abfin" id="ingresoAbFin" source="abfinFloat" fullWidth  formClassName={classes.grid_cont3_s} disabled/>
            
            <DateInput label="resources.indicadores_cargar_informacions.fields.fechaMedicion" id="datefield" source="fechaMedicion" formClassName={classes.grid_cont3_f} fullWidth disabled/>

            <SelectInput label="resources.indicadores_cargar_informacions.fields.medidoPor" source="medidoPor" formClassName={classes.grid_cont3_s} fullWidth choices={[
                { id: translate('dynatest.resources.indicadores_cargar_informacions.select.medidoPor.Interventoria'), name: 'dynatest.resources.indicadores_cargar_informacions.select.medidoPor.Interventoria' },
                { id: translate('dynatest.resources.indicadores_cargar_informacions.select.medidoPor.Mantenimiento'), name: 'dynatest.resources.indicadores_cargar_informacions.select.medidoPor.Mantenimiento' }
            ]} />
            <SelectInput label="resources.indicadores_cargar_informacions.fields.estado" source="estado" formClassName={classes.grid_cont3_s} fullWidth 
            choices={[
                { id: translate('dynatest.resources.indicadores_cargar_informacions.select.estado.PorRevisar'), name: 'dynatest.resources.indicadores_cargar_informacions.select.estado.PorRevisar' },
                { id: translate('dynatest.resources.indicadores_cargar_informacions.select.estado.Publicada'), name: 'dynatest.resources.indicadores_cargar_informacions.select.estado.Publicada' },
                { id: translate('dynatest.resources.indicadores_cargar_informacions.select.estado.RevisionRealizada'), name: 'dynatest.resources.indicadores_cargar_informacions.select.estado.RevisionRealizada' }
            ]}/>
            <SelectInput label="resources.indicadores_cargar_informacions.fields.tipoMedicion" source="tipoMedicion" formClassName={classes.grid_cont3_s} validate={[validartipoMedida]} choices={[
                { id: translate('dynatest.resources.indicadores_cargar_informacions.select.tipoMedicion.periodica'), name: 'dynatest.resources.indicadores_cargar_informacions.select.tipoMedicion.periodica' },
                // {id: 'De Verificación', name: 'De Verificación'},
                { id: translate('dynatest.resources.indicadores_cargar_informacions.select.tipoMedicion.deCorreccion'), name: 'dynatest.resources.indicadores_cargar_informacions.select.tipoMedicion.deCorreccion' },
                
                
            ]} disabled/>
            {tipoMedicion==="De Corrección" &&
            <Fragment>
            <Typography component="b" variant="b" formClassName={classes.grid_cont12} fullWidth>{translate('dynatest.resources.indicadores_cargar_informacions.subTitle.RangoCorreccion')}</Typography>
            <PRInput label="resources.indicadores_cargar_informacions.fields.abini"  source="abiniCorreccionFloat" fullWidth   className={classes.grid_cont3_f} disabled/>
            <PRInput label="resources.indicadores_cargar_informacions.fields.abfin"  source="abfinCorreccionFloat" fullWidth  className={classes.grid_cont3_s} disabled/>
            </Fragment>
            }
            <Divider fullWidth/>
            <FormDataConsumer>
                {({ formData, ...rest }) => { formData.proyecto = idproyecto; formData.translateUser = localStorage.getItem("ucalpro"); }}
            </FormDataConsumer>
           
            {/* <NumberInput label="Promedio carril I" source="promedioci"/>
            Incumplimientos puntuales carril I
            Promedio carril D
            Incumplimientos puntuales carril D */}
            {/* <WithProps>{({record,...props})=>
               <EvaluacionPorSegmento record={record}/>}
            </WithProps> */}
            
         </SimpleForm>
         </Edit>
    )
}));

const WithProps = ({children,...props}) => children(props);