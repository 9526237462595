import React, { useState, Fragment } from 'react';
import {
    NumberInput, minValue, TextInput, BooleanInput, ReferenceInput, SelectInput, SelectArrayInput,
    ReferenceArrayInput, AutocompleteArrayInput, AutocompleteInput, DateInput, Query, number, REDUX_FORM_NAME, FormDataConsumer, email
}
    from 'react-admin';
import { change } from 'redux-form';
import { Edit } from './../Edit';
import { SimpleForm } from './../SimpleForm';
import { required, minLength, maxLength, } from 'react-admin'
import Grid from '@material-ui/core/Grid';
import MunicipioArray from './municipioArray';
import { translate } from 'react-admin';
import { withStyles } from '@material-ui/core';
import { styles } from './../../EstilosGrid';

export const ProveedorEdit = translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;

    const [departamentoss, setDepartamento] = useState('Ninguno');
    const [mantenimiento, setMantenimiento] = useState(false);
    const [suministros, setSuministros] = useState(false);

    const ValidarDepartamento = (value) => {
        // let idDepartamento = value.split("departamentos/")        
        // Auxprueba=idDepartamento[1]      
        setDepartamento(value);
    }

    /*VALOR PARA ORDENAMIETO DE SISTEMA*/

    const [sistemaNombre, setSistemaNombre] = useState('');
    const SistemaNombre = (value) => {
        console.log(value);
        setSistemaNombre(value)
    }
    const validaMantenimiento = (value) => {
        var mante = document.getElementById("mantenimiento").checked;
        var sumin = document.getElementById("suministros").checked;
        if(mante == false && sumin == false){
           return translate('resources.validaciones.mantenimientoOSuministro');
        }        
    }

    const validaSuministro = (value) => {
        var mante = document.getElementById("mantenimiento").checked;
        var sumin = document.getElementById("suministros").checked;
        if(mante == false && sumin == false){
           return translate('resources.validaciones.mantenimientoOSuministro');
        }
    }
   
    return (
        <Edit {...props} title="dynatest.resources.proveedors.title.editTitle" undoable={false} >
            <SimpleForm submitOnEnter={false} >
                <Grid container spacing={24}>
                    <Grid item xs={4}>
                        <ReferenceInput
                            label="resources.proveedors.fields.proyecto"
                            source="proyecto"
                            reference="proyectos"
                            disabled
                            fullWidth
                            validate={[required(), ValidarDepartamento]}
                            filterToQuery={searchText => ({ nombre: searchText })}
                        >
                            <AutocompleteInput options={{ fullWidth: true, disabled: true }} optionText="nombre" fullWidth />
                        </ReferenceInput>
                    </Grid>
                    <Grid item xs={4}>
                        <BooleanInput validate={[validaMantenimiento]} label="dynatest.resources.proveedors.boolean.mantenimiento" source="mantenimiento" id="mantenimiento" fullWidth />
                    </Grid>
                    <Grid item xs={4}>
                        <BooleanInput validate={[validaSuministro]} label="dynatest.resources.proveedors.boolean.suministros" source="suministros" id="suministros" fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <ReferenceInput label="resources.proveedors.fields.sistema" source="sistema" sort={{ field: "nombre", order: "ASC" }} reference="sistemas" validate={[required(), SistemaNombre]} filter={{ visibleOnPolicies: 1 }} >
                            <SelectInput optionText="nombre" fullWidth />
                        </ReferenceInput>
                    </Grid>
                    {/* <Grid item xs={12}>
                    <ReferenceInput 
                        label="Nombre sistema" 
                        source="nombreSistema" 
                        reference="sistemas" 
                        validate={[required()]}
                        filter={{ id: sistemaNombre}}
                    >
                        <SelectInput  optionText="nombre" fullWidth />
                    </ReferenceInput>
                </Grid> */}
                    {sistemaNombre &&
                        <Query type="GET_ONE" resource="sistemas" payload={{ id: sistemaNombre }} >
                            {({ data, loading, error }) => {
                                if (data) {
                                    console.log(data.nombre);
                                    return <TextInput
                                        style={{ display: 'none' }}
                                        label="resources.proveedors.fields.nombreSistema"
                                        defaultValue={data.nombre}
                                        source="nombreSistema"
                                        fullWidth
                                        validate={[required()]}
                                    />;
                                }
                                return <div></div>;
                            }}
                        </Query>
                    }
                    <Grid item xs={4}>
                        <SelectInput label="resources.proveedors.fields.identificacion" source="identificacion" fullWidth allowEmpty emptyValue="" choices={[
                            { id: 'CC', name: 'dynatest.resources.proveedors.select.identificacion.CC' },
                            { id: 'NIT', name: 'dynatest.resources.proveedors.select.identificacion.NIT' },
                            { id: 'Otro', name: 'dynatest.resources.proveedors.select.identificacion.Otro' },
                        ]} validate={[required()]} />
                    </Grid>
                    <Grid item xs={4}>
                        <TextInput label="resources.proveedors.fields.numeroIdentificacion" source="numeroIdentificacion" fullWidth validate={[required(), minLength(5), maxLength(25), number("El valor debe ser numérico.")]} />
                    </Grid>
                    <Grid item xs={4}>
                        <TextInput label="resources.proveedors.fields.razonSocial" source="razonSocial" fullWidth validate={[required(), minLength(2), maxLength(250)]} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput label="resources.proveedors.fields.nombre" source="nombre" fullWidth />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput label="resources.proveedors.fields.apellido" source="apellido" fullWidth />
                    </Grid>

                    <Grid item xs={4}>
                        <TextInput label="resources.proveedors.fields.direccion" source="direccion" fullWidth validate={[required(), minLength(2), maxLength(250)]} />
                    </Grid>
                    <Grid item xs={8}>
                        <FormDataConsumer>
                            {
                                ({ formData, dispatch, ...rest }) => (
                                    <Fragment>
                                        <Grid container spacing={24}>
                                            <Grid item xs={6}>
                                                <ReferenceInput
                                                    id="departamento"
                                                    value=""
                                                    label="resources.proveedors.fields.departamento"
                                                    source="departamento"
                                                    reference="departamentos"
                                                    fullWidth
                                                    sort={{ field: "nombre", order: "ASC" }}
                                                    filterToQuery={searchText => ({ nombre: searchText })}
                                                    onChange={value => dispatch(
                                                        change(REDUX_FORM_NAME, 'municipio', null)
                                                    )}
                                                    validate={[required(), ValidarDepartamento]}
                                                >
                                                    <AutocompleteInput options={{ fullWidth: true }} optionText="nombre" fullWidth />
                                                </ReferenceInput>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <ReferenceArrayInput
                                                    label="resources.proveedors.fields.municipio1"
                                                    source="municipio"
                                                    reference="municipios"
                                                    validate={[required()]}
                                                    sort={{ field: "nombre", order: "ASC" }}
                                                    filterToQuery={searchText => ({ nombre: searchText })}
                                                    filter={{
                                                        departamento: departamentoss
                                                    }}
                                                >
                                                    <AutocompleteArrayInput options={{ fullWidth: true }} optionText="nombre" fullWidth />
                                                </ReferenceArrayInput>
                                            </Grid>
                                        </Grid>
                                    </Fragment>
                                )
                            }
                        </FormDataConsumer>

                    </Grid>
                    <Grid item xs={6}>
                        <NumberInput
                            label="resources.proveedors.fields.telefono"
                            source="telefono"
                            fullWidth
                            className="labelNumber"
                            validate={[required()]}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <NumberInput
                            label="resources.proveedors.fields.telefono2"
                            source="telefono2"
                            fullWidth
                            className="labelNumber"
                            validate={[]}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput label="resources.proveedors.fields.correo" source="correo" fullWidth validate={[minLength(2), maxLength(100),email()]} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput label="resources.proveedors.fields.observaciones" source="observaciones" fullWidth validate={[minLength(2), maxLength(200)]} />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    )
}));