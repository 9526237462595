import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { PimsCreate } from './../Create';
import { withStyles } from '@material-ui/core/styles';
import { TablePagination, TextField } from '@material-ui/core';
import { DateInput,NumberInput,TabbedForm,FormTab,  TextInput, SelectField, ReferenceField} from 'react-admin';

import { Query, Loading } from 'react-admin';
const styles = {
    ocultar: {
        display:"none",
    },
    root: {
        width: '100%',
        overflowX: 'auto',
      },
      table: {
        minWidth: 650,
      },

};



function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows=[
  "hola"
];
var x="";

//   fetch('http://localhost:8000/segmentos.json')
//   .then(res=> res.json())
//   .then(datos => {
//     tabla(datos);
//   });
  
//   function tabla(datos){
//     // console.log(datos);
   
//     for(let valor of datos){
      
//      rows.push(createData(valor.codigo, 159, 6.0, 24, 4.0));
    
//     }
//   }
  const payload = {
    pagination: { page: 1},
    sort: { field: 'codigo', order: 'ASC' },
 };

//  console.log(rows)
export const ExportacionPersCreate = withStyles(styles)(({ classes, ...props }) =>{
  
  
 
  
  

  return (
    <PimsCreate title="Crear Puente" {...props}>
     
    <Paper className={classes.root}>
        <Query type="GET_LIST" resource="segmento.json" payload={payload}>
              {({ data,  loading, error }) => {
                console.log(data);
                  if (loading) { return <Loading />; }
                  if (error) { return <p>ERROR por favor referscar la pagina</p>; }
                  
                  return (
                    <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Código</TableCell>
                        <TableCell >Inicio</TableCell>
                        <TableCell >Fin</TableCell>
                        <TableCell>Largo</TableCell>
                        <TableCell>Ancho</TableCell>
                        <TableCell>Área</TableCell>
                        <TableCell>Año de constru -                   4 4444444444cción</TableCell>
                        <TableCell>Año última rehabilitación</TableCell>
                        <TableCell>Layer 1 Type</TableCell>
                        <TableCell>Layer 1 Thickness</TableCell>
                        <TableCell>Layer 1 Modulus</TableCell>
                        <TableCell>Layer 1 Std.</TableCell>
                        <TableCell>Layer 2 Type</TableCell>
                        <TableCell>Layer 2 Thickness</TableCell>
                        <TableCell>Layer 2 Modulus</TableCell>
                        <TableCell>Layer 2 Std.</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody >
                              {data.map(user =>
                                
                                    <TableRow key={user.id}>
                                    <TableCell component="th" scope="row">
                                    {user.codigo}
                                    </TableCell>
                                   <TableCell align="right">{"PR "+user.fullAbinicio}</TableCell>
                                 <TableCell align="right">{"PR " + user.fullAbfin}</TableCell>
                                 </TableRow>
                                 )} 
                         </TableBody>
                         </Table>
                  );
              }}
          </Query>    
    </Paper>
    </PimsCreate>
  )
}

);