import React, { useState, Fragment } from 'react';
import { FormTab, NumberInput, ReferenceInput, ArrayInput, SimpleFormIterator, SelectInput, FormDataConsumer, 
    DateInput, AutocompleteInput
} from 'react-admin';
import { TabbedForm, BooleanInput, TextInput } from 'react-admin';
import { PimsElementoActions } from './../PimsElementoActions';
import { latMininima, lngMininima, validarDosDecimales, abValidationInicio, abValidationFin, Entero, isFechaInvalida, prValidationInicio, prValidationFin
} from '../../validacionesInputs';
import { styles } from './../../../EstilosGrid';
import { PimsCreate } from './../../Create';
import { PRInput } from '../../../inputs/PRInput';
import { PInput } from '../../../inputs/PInput';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core';
import { required, maxLength, minValue, maxValue } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { ImagenesInventario } from '../../images/ImagenesInventario';
import CoordenadasProyecto from '../../CoordenadasInventario';
import AbsSegmento from '../../AbsSegmento';
import { translate } from 'react-admin';


const tipoElemento = { sistema: 'PTS' };
export const ElementoContencionCreate = translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;

    const [unidads, setUnidad] = useState('');
    const [tramos, setTramo] = useState('');

    const ValidarUnidad = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setUnidad(value)
    }

    const Unidades = { unidadFuncional: unidads };

    const ValidarTramo = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setTramo(value)
    }

    const Tramoss = { tramo: tramos };

    /* VALIDAR LOS RANGOS QUE PUEDEN TENER LAS LATITUDES Y LONGITUDES */
    const [segmentos, setSegmento] = useState('');
    const ValidarSegmento = (value) => {
        setSegmento(value)
    }


    /*VALORES POR DEFECTO DEL ARRAY DE COORDENADAS*/
    const [latitudMinimaArray, setLatitudArray] = useState('');
    const LatitudMinimaArray = (value) => {
        setLatitudArray(value)
    }
    const [longitudMinimaArray, setLongitudArray] = useState('');
    const LongitudMinimaArray = (value) => {
        setLongitudArray(value)
    }
    const [longitudMaximaArray, setLongitudMaxdArray] = useState('');
    const LongitudMaximaArray = (value) => {
        setLongitudMaxdArray(value)
    }
    const [latitudMaximaArray, setLatitudMaxdArray] = useState('');
    const LatitudMaximaArray = (value) => {
        setLatitudMaxdArray(value)
    }
    /*FIN DE LOS VALORES POR DEFECTO DEL ARRAY DE COORDENADAS*/


    const [estados, setEstado] = useState('');

    const ValidarEstado = (value) => {

        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setEstado(valor)

    }

    const [elemento, setElemento] = useState('-');
    const ValidarElemento = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setElemento(valor);
    }
/*OPCIONES PARA TIPO DE IMÁGENES*/
   const [defaultTipoImagen,setDefaultTipoImagen] = useState([{id: 'Cargando...' ,name :'Cargando...'}]);
   const OpcionTipoImagen = (value) =>{    
        setDefaultTipoImagen([
            {
                tipoImagen : '/tipo_images/1',
            },
            {
                tipoImagen : '/tipo_images/7',
            },
            {
                tipoImagen : '/tipo_images/8',
            },
            {
                tipoImagen : '/tipo_images/9',
            },
            {
                tipoImagen : '/tipo_images/5',
            },
            {
                tipoImagen : '/tipo_images/6',
            }
        ])
   }


    return (
        <PimsCreate  {...props} actions={<PimsElementoActions />}>
            <TabbedForm redirect="list">
                <FormTab label="dynatest.resources.elemento_contencions.tabs.tabsTitle.identificacion">
                    <CoordenadasProyecto/>
                    <ReferenceInput 
                        label="resources.elemento_contencions.fields.tipoElemento" 
                        source="tipoElemento" 
                        reference="tipo_elementos" 
                        sort={{ field: 'codigo', order: 'ASC' }}
                        filter={{id : '/tipo_elementos/CONT'}}
                        filterToQuery={searchText => ({ nombre: searchText })} 
                        onChange={ValidarElemento}
                        validate={[required(),OpcionTipoImagen]}
                        fullWidth
                        formClassName={classes.grid_cont6_f}
                    >
                    
                        <AutocompleteInput  options={{fullWidth : true}} optionText="nombre"  />
                    </ReferenceInput>
                    <br/>
                    <SelectInput label="resources.elemento_contencions.fields.margen" source="margen" validate={[required()]}
                        choices={[
                            { id: translate('dynatest.resources.elemento_contencions.select.margen.Derecha'), name: 'dynatest.resources.elemento_contencions.select.margen.Derecha' },
                            { id: translate('dynatest.resources.elemento_contencions.select.margen.Izquierda'), name: 'dynatest.resources.elemento_contencions.select.margen.Izquierda' },
                        ]}
                        fullWidth formClassName={classes.grid_cont6_f} />
                    <SelectInput label="resources.elemento_contencions.fields.sentido" source='sentido' choices={[
                        { id: translate('dynatest.resources.elemento_contencions.select.sentido.NORTEId'), name: 'dynatest.resources.elemento_contencions.select.sentido.NORTEName' },
                        { id: translate('dynatest.resources.elemento_contencions.select.sentido.SURId'), name: 'dynatest.resources.elemento_contencions.select.sentido.SURName' },
                        { id: translate('dynatest.resources.elemento_contencions.select.sentido.ESTEId'), name: 'dynatest.resources.elemento_contencions.select.sentido.ESTEName' },
                        { id: translate('dynatest.resources.elemento_contencions.select.sentido.OESTEId'), name: 'dynatest.resources.elemento_contencions.select.sentido.OESTEName' }
                        ]}
                        fullWidth
                        formClassName={classes.grid_cont6}/>
                    <SelectInput label="resources.elemento_contencions.fields.tipo" source="tipo" validate={[required()]} sort={{ field: 'name', order: 'asc' }}
                        choices={[
                            { id: translate('dynatest.resources.elemento_contencions.select.tipo.anclajesRoca'), name: "dynatest.resources.elemento_contencions.select.tipo.anclajesRoca" },
                            { id: translate('dynatest.resources.elemento_contencions.select.tipo.cercadosRedesContencion'), name: "dynatest.resources.elemento_contencions.select.tipo.cercadosRedesContencion" },
                            { id: translate('dynatest.resources.elemento_contencions.select.tipo.escudoContraCaidaRocas'), name: "dynatest.resources.elemento_contencions.select.tipo.escudoContraCaidaRocas" },
                            { id: translate('dynatest.resources.elemento_contencions.select.tipo.muroAnclado'), name: "dynatest.resources.elemento_contencions.select.tipo.muroAnclado" },
                            { id: translate('dynatest.resources.elemento_contencions.select.tipo.muroConcretoReforzado'), name: "dynatest.resources.elemento_contencions.select.tipo.muroConcretoReforzado" },
                            { id: translate('dynatest.resources.elemento_contencions.select.tipo.muroEncofrado'), name: "dynatest.resources.elemento_contencions.select.tipo.muroEncofrado" },
                            { id: translate('dynatest.resources.elemento_contencions.select.tipo.muroGaviones'), name: "dynatest.resources.elemento_contencions.select.tipo.muroGaviones" },
                            { id: translate('dynatest.resources.elemento_contencions.select.tipo.muroGravedadConcreto'), name: "dynatest.resources.elemento_contencions.select.tipo.muroGravedadConcreto" },
                            { id: translate('dynatest.resources.elemento_contencions.select.tipo.muroTierraReforzada'), name: "dynatest.resources.elemento_contencions.select.tipo.muroTierraReforzada" },
                            { id: translate('dynatest.resources.elemento_contencions.select.tipo.murosContencion'), name: "dynatest.resources.elemento_contencions.select.tipo.murosContencion" },
                            { id: translate('dynatest.resources.elemento_contencions.select.tipo.pantallasAncladas'), name: "dynatest.resources.elemento_contencions.select.tipo.pantallasAncladas" },
                            { id: translate('dynatest.resources.elemento_contencions.select.tipo.pilotesCaissons'), name: "dynatest.resources.elemento_contencions.select.tipo.pilotesCaissons" },
                            { id: translate('dynatest.resources.elemento_contencions.select.tipo.reconformacion'), name: "dynatest.resources.elemento_contencions.select.tipo.reconformacion" },
                            { id: translate('dynatest.resources.elemento_contencions.select.tipo.revestimientoFlexible'), name: "dynatest.resources.elemento_contencions.select.tipo.revestimientoFlexible" },
                            { id: translate('dynatest.resources.elemento_contencions.select.tipo.revestimientoVegetal'), name: "dynatest.resources.elemento_contencions.select.tipo.revestimientoVegetal" },
                            { id: translate('dynatest.resources.elemento_contencions.select.tipo.sueloEmpernado'), name: "dynatest.resources.elemento_contencions.select.tipo.sueloEmpernado" },
                            { id: translate('dynatest.resources.elemento_contencions.select.tipo.otros'), name: "dynatest.resources.elemento_contencions.select.tipo.otros" },
                        ]}
                        fullWidth formClassName={classes.grid_cont6_f} />
                    <SelectInput label="resources.elemento_contencions.fields.funcionalidad" source="funcionalidad" validate={[required()]}
                        choices={[
                            { id: translate('dynatest.resources.elemento_contencions.select.funcionalidad.controlCauces'), name: "dynatest.resources.elemento_contencions.select.funcionalidad.controlCauces" },
                            { id: translate('dynatest.resources.elemento_contencions.select.funcionalidad.estabilidadBanca'), name: "dynatest.resources.elemento_contencions.select.funcionalidad.estabilidadBanca" },
                            { id: translate('dynatest.resources.elemento_contencions.select.funcionalidad.estabilidadTaludes'), name: "dynatest.resources.elemento_contencions.select.funcionalidad.estabilidadTaludes" },
                            { id: translate('dynatest.resources.elemento_contencions.select.funcionalidad.proteccionInfraest'), name: "dynatest.resources.elemento_contencions.select.funcionalidad.proteccionInfraest" },
                            { id: translate('dynatest.resources.elemento_contencions.select.funcionalidad.otros'), name: "dynatest.resources.elemento_contencions.select.funcionalidad.otros" }
                        ]}
                        fullWidth formClassName={classes.grid_cont6} />
                    <TextInput source="tipoElemento" className={classes.ocultar} reference="tipo_elementos" validate={[required()]} />
                    <Typography variant="b" component="b">{translate('dynatest.resources.elemento_contencions.subTitle.localizacion')}</Typography>
                    <Divider fullWidth formClassName={classes.grid_cont12} />
                            <ReferenceInput
                                label="resources.elemento_contencions.fields.unidadFuncional"
                                source="unidadFuncional"
                                reference="unidad_funcionals"
                                filterToQuery={searchText => ({ codigo: searchText })}
                                sort={{ field: 'codigo', order: 'asc' }}
                                validate={[required(), ValidarUnidad]}
                                fullWidth
                                formClassName={classes.grid_cont4_f}
                            >
                                <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
                            </ReferenceInput>
                        {unidads &&
                                <ReferenceInput
                                    label="resources.elemento_contencions.fields.tramo"
                                    source="tramo"
                                    alwaysOn
                                    reference="tramos"
                                    filter={{ unidadFuncional: unidads }}
                                    filterToQuery={searchText => ({ codigo: searchText })}
                                    sort={{ field: 'codigo', order: 'ASC', }}
                                    validate={[required(), ValidarTramo]}
                                    fullWidth
                                    formClassName={classes.grid_cont4_s}
                                >
                                    <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
                                </ReferenceInput>
                        }
                        {tramos &&
                                <ReferenceInput
                                    label="resources.elemento_contencions.fields.segmento"
                                    source="segmento"
                                    reference="segmentos"
                                    sort={{ field: 'codigo', order: 'ASC' }}
                                    filter={{ tramo: tramos }}
                                    filterToQuery={searchText => ({ codigo: searchText })}
                                    validate={[required(), ValidarSegmento]}
                                    fullWidth
                                    formClassName={classes.grid_cont4_s}
                                >

                                    <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
                                </ReferenceInput>
                        }
                    {/* ELEMENTOS OCULTOS */}
                    {segmentos &&
                        <AbsSegmento segmento={segmentos}  />
                    }
                    {/* ----------------- */}
                    {segmentos &&
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Typography variant="h3" component="h3" className={classes.coord}>{translate('dynatest.resources.elemento_contencions.subTitle.coordenadasIniciales')}</Typography>
                                <Divider fullWidth style={{ marginTop: '10px' }} />
                            </Grid>
                        </Grid>
                    }
                    {segmentos && <PRInput label="resources.elemento_contencions.fields.abscisa" id="ingresoAbInicio" source="abInicio" fullWidth validate={[abValidationInicio, required()]} formClassName={classes.grid_cont3_f} />}
                    {segmentos && <PInput label="resources.elemento_contencions.fields.pr" id="ingresoPrInicio" source="pInicio" fullWidth validate={[prValidationInicio]} formClassName={classes.grid_cont3_s} />}
                    {segmentos && <NumberInput label="resources.elemento_contencions.fields.latitud" id="LatitudInicial" source="latitudInicial" fullWidth validate={[required(), latMininima, LatitudMinimaArray]} formClassName={classes.grid_cont3_s} />}
                    {segmentos && <NumberInput label="resources.elemento_contencions.fields.longitud" id="LongitudInicial" source="longitudInicial" fullWidth validate={[required(), lngMininima, LongitudMinimaArray]} formClassName={classes.grid_cont3_s} />}
                    {segmentos &&
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Typography variant="h3" component="h3" className={classes.coord}>{translate('dynatest.resources.elemento_contencions.subTitle.coordenadasFinales')}</Typography>
                                <Divider fullWidth style={{ marginTop: '10px' }} />
                            </Grid>
                        </Grid>
                    }
                    {segmentos && <PRInput label="resources.elemento_contencions.fields.abscisa" id="ingresoAbFin" source="abFin" fullWidth validate={[abValidationFin]} formClassName={classes.grid_cont3_f} />}
                    {segmentos && <PInput label="resources.elemento_contencions.fields.pr" id="ingresoPrFin" source="pFin" fullWidth validate={[prValidationFin]} formClassName={classes.grid_cont3_s} />}
                    {segmentos && <NumberInput label="resources.elemento_contencions.fields.latitud" source="latitudFinal" fullWidth validate={[latMininima, LatitudMaximaArray]} formClassName={classes.grid_cont3_s} />}
                    {segmentos && <NumberInput label="resources.elemento_contencions.fields.longitud" source="longitudFinal" fullWidth validate={[lngMininima, LongitudMaximaArray]} formClassName={classes.grid_cont3_s} />}
                    {segmentos &&
                        <ArrayInput label="" source="ubicaciones" className={classes.ocultar}
                            defaultValue={[
                                {
                                    lat: latitudMinimaArray,
                                    lng: longitudMinimaArray
                                },
                                {
                                    lat: latitudMaximaArray,
                                    lng: longitudMaximaArray
                                }
                            ]}
                        >
                            <SimpleFormIterator disableAdd disableRemove >
                                <NumberInput label="Latitud" source="lat" fullWidth />
                                <NumberInput label="Longitud" source="lng" fullWidth />
                            </SimpleFormIterator>
                        </ArrayInput>
                    }
                </FormTab>
                {/* ---------- CARACTERÍSTICAS ------------- */}
                <FormTab label="dynatest.resources.elemento_contencions.tabs.tabsTitle.caracteristicas">
                    <NumberInput label="resources.elemento_contencions.fields.largo" source="largo" validate={[minValue(0), required(), maxValue(200), validarDosDecimales]} fullWidth formClassName={classes.grid_cont4_f} />
                    <NumberInput label="resources.elemento_contencions.fields.ancho" source="ancho" validate={[minValue(0), maxValue(50), validarDosDecimales]} fullWidth formClassName={classes.grid_cont4_s} />
                    <NumberInput label="resources.elemento_contencions.fields.altura" source="altura" validate={[minValue(0), maxValue(100), validarDosDecimales]} fullWidth formClassName={classes.grid_cont4_s} />
                    <BooleanInput label="dynatest.resources.elemento_contencions.boolean.estudioEstabilidad" source="estudioEstabilidad" fullWidth formClassName={classes.grid_cont12} />
                    <FormDataConsumer>
                        {({ formData, ...rest }) => (formData.estudioEstabilidad &&
                            <Fragment>
                                <TextInput label="resources.elemento_contencions.fields.consultor" source="consultor" validate={[maxLength(30)]} className={classes.grid_cont6_f} fullWidth />
                                <DateInput label="resources.elemento_contencions.fields.fechaEstudio" source="fechaEstudio" validate={[isFechaInvalida]} className={classes.grid_cont6_s} fullWidth />
                                <TextInput label="resources.elemento_contencions.fields.codigoEstudio" source="codigoEstudio" validate={[maxLength(10)]} className={classes.grid_cont6_f} fullWidth />
                                <TextInput label="resources.elemento_contencions.fields.nombreEstudio" source="nombreEstudio" validate={[maxLength(50)]} className={classes.grid_cont6_s} fullWidth />
                            </Fragment>
                        )}
                    </FormDataConsumer>
                </FormTab>
                
                {/* ------------ DETALLES ------------- */}
                <FormTab label="dynatest.resources.elemento_contencions.tabs.tabsTitle.detalles">
                    <SelectInput label="resources.elemento_contencions.fields.estado" source="estado"
                        choices={[
                            { id: translate('dynatest.resources.elemento_contencions.select.estado.Existente'), name: "dynatest.resources.elemento_contencions.select.estado.Existente" },
                            { id: translate('dynatest.resources.elemento_contencions.select.estado.Reemplazo'), name: "dynatest.resources.elemento_contencions.select.estado.Reemplazo" },
                            { id: translate('dynatest.resources.elemento_contencions.select.estado.Nuevo'), name: "dynatest.resources.elemento_contencions.select.estado.Nuevo" },
                        ]}
                        validate={[required()]} onChange={ValidarEstado} fullWidth formClassName={classes.grid_cont4_f} />
                    {estados === translate('dynatest.resources.elemento_contencions.select.estado.Reemplazo') &&
                        <SelectInput label="resources.elemento_contencions.fields.motivoReemplazo" source="motivoReemplazo"
                            choices={[
                                { id: translate('dynatest.resources.elemento_contencions.select.motivoReemplazo.Instalacion'), name: 'dynatest.resources.elemento_contencions.select.motivoReemplazo.Instalacion' },
                                { id: translate('dynatest.resources.elemento_contencions.select.motivoReemplazo.ReemplazoProgramado'), name: 'dynatest.resources.elemento_contencions.select.motivoReemplazo.ReemplazoProgramado' },
                                { id: translate('dynatest.resources.elemento_contencions.select.motivoReemplazo.Accidente'), name: 'dynatest.resources.elemento_contencions.select.motivoReemplazo.Accidente' },
                                { id: translate('dynatest.resources.elemento_contencions.select.motivoReemplazo.Vandalismo'), name: 'dynatest.resources.elemento_contencions.select.motivoReemplazo.Vandalismo' },
                                { id: translate('dynatest.resources.elemento_contencions.select.motivoReemplazo.Otro'), name: 'dynatest.resources.elemento_contencions.select.motivoReemplazo.Otro' },
                            ]}
                            validate={[required()]} fullWidth formClassName={classes.grid_cont4_s} />
                    }
                    {estados ===translate('dynatest.resources.elemento_contencions.select.estado.Reemplazo') &&
                        // <ElementosCreados tabla="elemento_contencions" tipo="create" fullWidth formClassName={classes.grid_cont4_s} />
                        <ReferenceInput
                            label="resources.elemento_contencions.fields.elementoReemplazo"
                            source="elementoReemplazo"
                            reference="elemento_contencions"
                            filter={{
                                tipoElemento : elemento,
                                estados : "Activo",
                                unidadFuncional: unidads,
                                tramo: tramos,
                                segmento : segmentos
                            }}
                            fullWidth
                            validate={[required()]}
                            formClassName={classes.grid_cont4}
                        >
                            <AutocompleteInput options={{fullWidth: true}} optionText="smartCode" />
                        </ReferenceInput>
                    }
                    <br/>
                    
                    <SelectInput label="resources.elemento_contencions.fields.estados" source="estados" allowEmpty emptyValue="" choices={[
                            { id: translate('dynatest.resources.elemento_contencions.select.estados.Activo'), name: "dynatest.resources.elemento_contencions.select.estados.Activo" },
                            { id: translate('dynatest.resources.elemento_contencions.select.estados.Inactivo'), name: "dynatest.resources.elemento_contencions.select.estados.Inactivo" },
                        ]} validate={[required()]} fullWidth formClassName={classes.grid_cont4_f} />
                    <div></div>
                    <Grid container spacing={24}>
                        <Grid item xs={4}>
                            <DateInput label="resources.elemento_contencions.fields.fechaInstalacion" source="fechaInstalacion" validate={[isFechaInvalida]} fullWidth formClassName={classes.grid_cont4_f} />

                        </Grid>
                        <Grid item xs={4}>
                            <ReferenceInput 
                                label="resources.elemento_contencions.fields.proveedor" 
                                source="proveedor" 
                                reference="proveedors" 
                                filter={{ sistema: 'PTS' }} 
                                sort={{ field: 'razonSocial', order: 'ASC' }}
                                filterToQuery={searchText => ({ razonSocial: searchText })} 
                                validate={[required()]}
                            >
                                <AutocompleteInput  options={{fullWidth : true}} optionText="razonSocial"  />
                            </ReferenceInput>
                            
                        </Grid>
                        <Grid item xs={4}>
                            <NumberInput label="resources.elemento_contencions.fields.garantia" source="garantia" fullWidth validate={[required(), minValue(0), Entero]} formClassName={classes.grid_cont4_s} />
                            
                        </Grid>
                    </Grid>                    
                    <BooleanInput label="dynatest.resources.elemento_contencions.boolean.mantenimiento" source="mantenimiento" fullWidth formClassName={classes.grid_cont6_f} />
                    <TextInput label="resources.elemento_contencions.fields.observaciones" source="observaciones" validate={[required()]} fullWidth formClassName={classes.grid_cont12} />
                    <TextInput source="createdIn" style={{ display: 'none' }} defaultValue={"api"} />
                    <TextInput source="updatedIn" style={{ display: 'none' }} defaultValue={"api"} />
                </FormTab>
                <FormTab label="dynatest.resources.elemento_contencions.tabs.tabsTitle.fotografias">
                    <ImagenesInventario type='create' defaultTipoImagen={defaultTipoImagen}/>                                   
                </FormTab>
            </TabbedForm>
        </PimsCreate>
    )
}));