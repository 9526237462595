import React, { useState, Fragment } from 'react';
import { SimpleForm, Query, TextInput, DateInput, ReferenceInput, SelectInput, FormDataConsumer, NumberInput, BooleanInput, FileInput, FileField, AutocompleteInput } from 'react-admin';
import { PimsCreate } from './../../Create';
import { PRInput } from './../../../inputs/PRInput';
import { withStyles } from '@material-ui/core';
import { styles } from './../../../EstilosGrid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { required } from 'react-admin';
import { abValidationInicio, abValidationFin, validarLogicaAbsFinCreate } from '../../validacionesInputs';
import config from '../../../config/config';
import { translate } from 'react-admin';
let urlAppjs = config.UrlServer;

export const CargarInformacionCreate = translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;
    const [tipoMedicion, setTipoMedicion] = useState('');
    const [datosArchivo, setdatosArchivo] = useState('');
    const [unidads, setUnidad] = useState('');
    const [tramos, setTramo] = useState('');
    const [parametro, setParametro] = useState('');
    const [correccionabini, setCorreccionabini] = useState('');
    const [correccionabfin, setCorreccionabfin] = useState('');
    // const [validacionesabinicial, setValidacionesabinicial]=useState([required(), abValidationInicio]);
    // const [validacionesabfinal, setValidacionesabfinal]=useState([required(), abValidationFin, validarLogicaAbsFinCreate]);

    const validartipoMedida = (value) => {
        setTipoMedicion(value);
        // console.log(tramos);
        // console.log(parametro)
        if (value === translate('dynatest.resources.indicadores_cargar_informacions.select.tipoMedicion.deCorreccion')) {
            let tramo = 0;
            let indicador = 0;
            let respuesta = 0;
            if (tramos) {
                tramo = tramos.replace("/tramos/", "");
            }
            if (parametro) {
                indicador = parametro.replace("/parametros/", "");
            }
            if (tramos && parametro && tramos != "" && parametro != "") {
                fetch(urlAppjs + "/buscarTramoCorreccion/" + tramo + "/" + indicador)
                    .then((response) => {
                        return response.json()
                    })
                    .then((response) => {
                        respuesta = response.respuesta;
                        if (respuesta === 1) {
                            setCorreccionabini(response.abinicial)
                            setCorreccionabfin(response.abfinal)
                        }
                        //console.log(response)
                    });
                console.log("correccionabini", correccionabini)
                console.log("correccionabfin", correccionabfin)
                if (correccionabfin) { }
                else {
                    return translate('resources.validaciones.cargasIndicador')
                }
            }
            // if(respuesta===0){
            //     return "No se han encontrado Cargas Publicadas para este indicador"
            // }
        } else {

        }
    }
    const ValidarUnidad = (value) => {
        setUnidad(value)
    }
    const ValidarTramo = (value) => {
        //console.log(value)
        if (value) {
            setTramo(value)
        }

    }
    const validarfechaact = (value) => {
        let fecha = new Date(value + "T00:00:00");
        let fechaact = new Date();
        if (fecha > fechaact) {
            return translate('dynatest.resources.indicadores_cargar_informacions.messagesVal.fechaMedicion');
        }
    }
    const ValidarParametro = (value) => {
        setParametro(value);
    }

    const ValidarCorreccionIni = (value) => {
        if (value && typeof value === "string") {
            let valor = value.replace("K", "").replace("+", ".");
            valor = parseFloat(valor);
            if (correccionabini != "" || correccionabfin != "") {
                if (valor < correccionabini) {
                    return translate('resources.validaciones.absInicialDeCorreccionMenor')
                } else if (valor > correccionabfin) {
                    return translate('resources.validaciones.absInicialDeCorreccionMayor')
                }
            } else {
                return translate('resources.validaciones.cargasIndicador')
            }
        }
    }
    const ValidarCorreccionFin = (value) => {
        if (value && typeof value === "string") {
            let valor = value.replace("K", "").replace("+", ".");
            valor = parseFloat(valor);
            console.log(correccionabini + "//" + correccionabfin);
            if (correccionabini != "" || correccionabfin != "") {
                if (valor < correccionabini) {
                    return translate('resources.validaciones.absFinalDeCorreccionMenor')
                } else if (valor > correccionabfin) {
                    return translate('resources.validaciones.absFinalDeCorreccionMayor')
                }
            } else {
                return translate('resources.validaciones.cargasIndicador')
            }
        }
    }
    let idproyecto = localStorage.getItem("id_project");
    return (
        <PimsCreate  {...props} >
            <SimpleForm submitOnEnter={false} redirect="list" >
                <Divider fullWidth />
                <Typography component="b" variant="b" formClassName={classes.grid_cont12} fullWidth>{translate('dynatest.resources.indicadores_cargar_informacions.subTitle.SeleccionIndicador')}</Typography>
                <ReferenceInput
                    label="resources.indicadores_cargar_informacions.fields.indicador"
                    source="indicador"
                    reference="parametros"
                    sort={{ field: 'id', order: 'ASC' }}
                    filter={{ hasMasiveLoading: 1 }}
                    fullWidth
                    formClassName={classes.grid_cont12}
                    validate={[required(), ValidarParametro]}
                >
                    <SelectInput optionText="codigoCompleto" />
                </ReferenceInput>
                <TextInput source="indicadorident" style={{ display: "none" }} defaultValue={parametro} />
                <TextInput source="tramoident" style={{ display: "none" }} defaultValue={tramos} />
                <Divider fullWidth />
                <Typography component="b" variant="b" formClassName={classes.grid_cont12} fullWidth>{translate('dynatest.resources.indicadores_cargar_informacions.subTitle.Detalles')}</Typography>
                <ReferenceInput label="resources.indicadores_cargar_informacions.fields.unidadFuncional" source="unidadFuncional" reference="unidad_funcionals"
                    filterToQuery={searchText => ({ codigo: searchText })}
                    sort={{ field: 'codigo', order: 'asc' }}
                    formClassName={classes.grid_cont3_f}
                    validate={[required(), ValidarUnidad]}
                    fullWidth
                >
                    <AutocompleteInput options={{ fullWidth: true }} optionText="fullCodigos" fullWidth />
                </ReferenceInput>
                {unidads &&
                    <ReferenceInput label="resources.indicadores_cargar_informacions.fields.tramo" source="tramo" reference="tramos"
                        filterToQuery={searchText => ({ codigo: searchText })}
                        sort={{ field: 'codigo', order: 'ASC' }}
                        fullWidth
                        formClassName={classes.grid_cont3_s}
                        validate={[required(), ValidarTramo]}
                        filter={{ unidadFuncional: unidads }}
                    >
                        <AutocompleteInput options={{ fullWidth: true }} optionText="fullCodigos" />
                    </ReferenceInput>
                }
                {tramos &&
                    <Query type='GET_ONE' resource='tramo' payload={{ id: tramos }} formClassName={classes.ocultar}>
                        {({ data, loading, error }) => {
                            if (data) {
                                return (
                                    <React.Fragment>
                                        <NumberInput
                                            source="abInicios"
                                            label="resources.indicadores_cargar_informacions.fields.abInicios"
                                            id="IdAbInicio"
                                            defaultValue={data.abInicios}
                                            style={{ display: 'none', width: '100%' }}
                                            fullWidth
                                        />
                                        <NumberInput
                                            source="abFins"
                                            label="resources.indicadores_cargar_informacions.fields.abFins"
                                            id="IdAbFin"
                                            defaultValue={data.abFins}
                                            style={{ display: 'none', width: '100%' }}
                                            fullWidth
                                        />
                                    </React.Fragment>

                                )
                            }
                            else {
                                return (<div></div>)
                            }
                        }}
                    </Query>
                }
                {tramos && tipoMedicion != translate('dynatest.resources.indicadores_cargar_informacions.select.tipoMedicion.deCorreccion') &&
                    <PRInput label="resources.indicadores_cargar_informacions.fields.abini" id="ingresoAbInicio" source="abini" fullWidth formClassName={classes.grid_cont3_s} validate={[required(), abValidationInicio]} />
                }
                {tramos && tipoMedicion != translate('dynatest.resources.indicadores_cargar_informacions.select.tipoMedicion.deCorreccion') &&
                    <PRInput label="resources.indicadores_cargar_informacions.fields.abfin" id="ingresoAbFin" source="abfin" fullWidth formClassName={classes.grid_cont3_s} validate={[required(), abValidationFin, validarLogicaAbsFinCreate]} />
                }
                {tramos && parametro && tipoMedicion === translate('dynatest.resources.indicadores_cargar_informacions.select.tipoMedicion.deCorreccion') &&
                    <PRInput label="resources.indicadores_cargar_informacions.fields.abini" id="ingresoAbInicio" source="abiniC" fullWidth formClassName={classes.grid_cont3_s} defaultValue={correccionabini} disabled />
                }
                {tramos && parametro && tipoMedicion === translate('dynatest.resources.indicadores_cargar_informacions.select.tipoMedicion.deCorreccion') &&
                    <PRInput label="resources.indicadores_cargar_informacions.fields.abfin" id="ingresoAbFin" source="abfinC" fullWidth formClassName={classes.grid_cont3_s} defaultValue={correccionabfin} disabled />
                }
                {/* {
                <FormDataConsumer formClassName={classes.ocultar}>
                {({formData, dispatch, ...rest}) => {
                    if(typeof formData != 'undefined' && typeof formData.abini!= 'undefined' && typeof formData.abfin!= 'undefined' && parametro && tipoMedicion===translate('dynatest.resources.indicadores_cargar_informacions.select.tipoMedicion.deCorreccion')){
                        formData.abini=correccionabiniC;
                        formData.abfin=correccionabfinC;
                    }
                }}
                </FormDataConsumer>
            } */}
                {!unidads &&
                    <div formClassName={classes.grid_cont3_s}></div>
                }
                {!tramos &&
                    <div formClassName={classes.grid_cont3_s}></div>
                }
                {!tramos &&
                    <div formClassName={classes.grid_cont3_s}></div>
                }

                <DateInput label="resources.indicadores_cargar_informacions.fields.fechaMedicion" id="datefield" source="fechaMedicion" formClassName={classes.grid_cont3_f} fullWidth validate={[required(), validarfechaact]} />

                <SelectInput label="resources.indicadores_cargar_informacions.fields.medidoPor" source="medidoPor" formClassName={classes.grid_cont3_s} fullWidth validate={[required()]} choices={[
                    { id: translate('dynatest.resources.indicadores_cargar_informacions.select.medidoPor.Interventoria'), name: 'dynatest.resources.indicadores_cargar_informacions.select.medidoPor.Interventoria' },
                    { id: translate('dynatest.resources.indicadores_cargar_informacions.select.medidoPor.Mantenimiento'), name: 'dynatest.resources.indicadores_cargar_informacions.select.medidoPor.Mantenimiento' }
                ]} />
                <SelectInput label="resources.indicadores_cargar_informacions.fields.estado" source="estado" formClassName={classes.grid_cont3_s} fullWidth validate={[required()]}
                    choices={[
                        { id: translate('dynatest.resources.indicadores_cargar_informacions.select.estado.PorRevisar'), name: 'dynatest.resources.indicadores_cargar_informacions.select.estado.PorRevisar' },
                        { id: translate('dynatest.resources.indicadores_cargar_informacions.select.estado.Publicada'), name: 'dynatest.resources.indicadores_cargar_informacions.select.estado.Publicada' },
                        { id: translate('dynatest.resources.indicadores_cargar_informacions.select.estado.RevisionRealizada'), name: 'dynatest.resources.indicadores_cargar_informacions.select.estado.RevisionRealizada' }
                    ]}
                />
                <SelectInput label="resources.indicadores_cargar_informacions.fields.tipoMedicion" source="tipoMedicion" formClassName={classes.grid_cont3_s} validate={[required(), validartipoMedida]} choices={[
                    { id: translate('dynatest.resources.indicadores_cargar_informacions.select.tipoMedicion.periodica'), name: 'dynatest.resources.indicadores_cargar_informacions.select.tipoMedicion.periodica' },
                    // {id: 'De Verificación', name: 'De Verificación'},
                    { id: translate('dynatest.resources.indicadores_cargar_informacions.select.tipoMedicion.deCorreccion'), name: 'dynatest.resources.indicadores_cargar_informacions.select.tipoMedicion.deCorreccion' },


                ]} />
                {tipoMedicion === translate('dynatest.resources.indicadores_cargar_informacions.select.tipoMedicion.deCorreccion') &&
                    <Fragment>
                        <Typography component="b" variant="b" formClassName={classes.grid_cont12} fullWidth>{translate('dynatest.resources.indicadores_cargar_informacions.subTitle.RangoCorreccion')}</Typography>
                        <PRInput label="resources.indicadores_cargar_informacions.fields.abini" source="abiniCorreccion" fullWidth validate={[required(), ValidarCorreccionIni]} className={classes.grid_cont3_f} />
                        <PRInput label="resources.indicadores_cargar_informacions.fields.abfin" source="abfinCorreccion" fullWidth validate={[required(), ValidarCorreccionFin]} className={classes.grid_cont3_s} />
                    </Fragment>
                }
                <Divider fullWidth />
                <Typography component="b" variant="b" formClassName={classes.grid_cont12} fullWidth>{translate('dynatest.resources.indicadores_cargar_informacions.subTitle.CargarArchivo')}</Typography>
                <FileInput source="archivoCarga" label="resources.indicadores_cargar_informacions.fields.archivoCarga" accept=".csv" multiple={false} validate={[required()]}>
                    <FileField source="src" title="title" />
                </FileInput>
                <FormDataConsumer style={{ display: "none" }}>
                    {({ formData, ...rest }) => {
                        if (formData && formData.archivoCarga && formData.archivoCarga.src) {
                            //console.log(formData.archivoCarga)
                            var blobUrl = formData.archivoCarga.src;
                            fetch(blobUrl)
                                .then((res) => {
                                    return res.blob()
                                })
                                .then((blob) => {
                                    var myReader = new FileReader();
                                    myReader.readAsText(blob, 'latin1');
                                    let texto = ""
                                    myReader.onload = function (e) {
                                        var txt = myReader.result;
                                        setdatosArchivo(txt)
                                        // console.log(txt)
                                    };
                                })

                        }
                        else {
                            setdatosArchivo("");
                        }
                    }}
                </FormDataConsumer>
                <TextInput source="datosarchivo" id="datosarchivo" style={{ display: "none" }} multiline defaultValue={datosArchivo} />
                <TextInput source="proyecto" defaultValue={idproyecto} style={{ display: "none" }} />
                <TextInput source="translateUser" defaultValue={localStorage.getItem("ucalpro")} style={{ display: "none" }} />
                <FormDataConsumer>
                    {({ formData, ...rest }) => { formData.updatedIn = "api"; }}
                </FormDataConsumer> 
                <Divider fullWidth />
            </SimpleForm>
        </PimsCreate>
    )
}))