import React from 'react';
import Typography from '@material-ui/core/Typography';
import { SelectInput, DisabledInput } from 'react-admin';
import loader from './../../../../../loader10.gif';
import config from '../../../../../config/config';
let urlAppjs = config.UrlServer;

class Grupo2Show extends React.Component{

    constructor(props){
        super(props)
        this.state = { grupo2: [], datoEva: [] }
    }

    componentDidMount(){

        let url_elemento_aux = document.location.hash;
        let croed=url_elemento_aux.split("/");
        let crearoeditar="";
        let componente =""
        if(croed[2]==="create"){
            crearoeditar=1;
        }
        else{
        let url_elemento = url_elemento_aux.split("/%2F");
        componente = url_elemento[1];
        componente = componente.replace("%2F", "/");
        componente = componente.replace("/show/1", "");
        crearoeditar=2;
        }
        
        // console.log(urlAppjs+"/"+componente)
        if(crearoeditar===2){
            this.setState({ datoEva: this.props.record })
            // fetch(urlAppjs+"/"+componente)
        // .then((response) => {
        //     return response.json()
        // })
        // .then((datoEva) => {
        //     this.setState({ datoEva: datoEva })
        // })
    }
        fetch(urlAppjs+"/preguntas?elemento_indicador=2.json")
        .then((response) => {
            return response.json()
        })
        .then((grupo2) => {
            this.setState({ grupo2: grupo2 })
        })
    
    }

    render(){

        const { grupo2 } = this.state;     
        const { datoEva } = this.state;             
        
        if(grupo2['hydra:member']){
            let respuestasindaños=datoEva.calificacionG2;
            let response2 = [];
            if(datoEva.svGrupo){
                grupo2['hydra:member'].map((dataGrupo) => {
                    datoEva.svGrupo.map( responseSV => {
                        if(dataGrupo.pregunta === responseSV["preguntas_"].pregunta && responseSV["preguntas_"].elemento_indicador==="/elemento_indicadors/2"){
                            if(dataGrupo.pregunta==="Sin Daños" && (!responseSV.calificacion || responseSV.calificacion==="" || responseSV.calificacion===null)){
                                response2.push(respuestasindaños)
                            }else{
                                response2.push(responseSV.calificacion)
                            }
                        }
                    })
                })
            }

            // console.log(response2)

            return(
                <React.Fragment>                                       
                    {
                        grupo2['hydra:member'].map((data, i) => (
                            <React.Fragment key={i} style={{marginTop: '5px'}}>                                
                                <Typography
                                    variant="strong"                                
                                    color="inherit"
                                    style={{width:'50%', display: 'inline-block', marginTop: '5px'}}                                    
                                >
                                    {data.pregunta}
                                </Typography>
                                <Typography
                                    variant="strong"                                
                                    color="inherit"
                                    style={{width:'50%', display: 'inline-block', marginTop: '5px'}}                                    
                                >
                                    {response2[i]}
                                </Typography>
                            </React.Fragment>
                        ))

                    }    
                    <hr />
                    <div style={{display: 'none', justifyContent: 'flex-end', marginTop: '-30px'}}>
                        <strong style={{display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}>Condición: </strong><DisabledInput source="condicion_gp2" label=" " defaultValue="Cumple"/>
                    </div>  
                    <DisabledInput source="gp2_length" label=" " defaultValue={grupo2['hydra:member'].length} style={{display: 'none'}}/>              
                </React.Fragment>
            )          
                     
        }else{                      
            return( 
                <div className="Reload2">
                    <img src={loader} />
                </div> 
            )    
        }
            
    }    
}

export default Grupo2Show;