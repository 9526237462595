import React, { useState } from 'react';
import {
    Filter, List, Datagrid, TextField, NumberField, EditButton, ShowButton, CloneButton, SelectInput,
    ReferenceInput, ReferenceField, AutocompleteInput, FormDataConsumer, DateField
} from 'react-admin';
import Button from '@material-ui/core/Button';
import { BulkDeleteWithConfirmButton, Loading } from 'react-admin';
import { Fragment } from 'react';
import config from '../../config/config';
import Codigo from './codigoComplemento/complementoCodigo';
import { translate } from 'react-admin';
let urlAppjs = config.UrlServer;

export const RespuestaEvaluacionComplementariosList = translate(({ permissions, ...props }) => {
    const { translate } = props;

    const FiltroRespuestaIndicadorComplementarios = (props) => {
        let choices = [
            { id: "Reemplazar", name: "Reemplazar" },
            { id: "Reparar", name: "Reparar" },
            { id: "Mantenimiento", name: "Mantenimiento" }
        ]
        const [uf, setUf] = useState('none');
        const ufSet = (value) => {
            setUf(value);
            //console.log(value);
        }

        const [tramo, setTramo] = useState('none');
        const tramoSet = (value) => {
            setTramo(value);
        }

        const [acc, setAcc] = useState('');
        const accSet = (value) => {
            setAcc(value);
            //console.log(value);
        }

        return (
            <Filter {...props}>
                <ReferenceInput
                    id="unidadFuncional"
                    label="resources.respuesta_evaluacion_complementarios.fields.unidadFuncional"
                    source="unidadFuncional"
                    reference="unidad_funcionals"
                    filterToQuery={searchText => ({ codigo: searchText })}
                    sort={{ field: 'codigo', order: 'asc' }}
                    validate={[ufSet]}
                    alwaysOn
                    fullWidth
                >
                    <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
                </ReferenceInput>
                <ReferenceInput
                    label="resources.respuesta_evaluacion_complementarios.fields.tramo"
                    source="tramo"
                    alwaysOn
                    reference="tramos"
                    filter={{ unidadFuncional: uf }}
                    validate={[tramoSet]}
                    filterToQuery={searchText => ({ codigo: searchText })}
                    sort={{
                        field: 'codigo',
                        order: 'ASC',

                    }}>
                    <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
                </ReferenceInput>

                <ReferenceInput
                    label="resources.respuesta_evaluacion_complementarios.fields.segmento"
                    source="segmento"
                    reference="segmentos"
                    alwaysOn
                    sort={{ field: 'codigo', order: 'ASC' }}
                    filter={{ tramo: tramo }}
                    filterToQuery={searchText => ({ codigo: searchText })}
                >
                    <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
                </ReferenceInput>
                <ReferenceInput
                    id="sistema"
                    label="resources.respuesta_evaluacion_complementarios.fields.sistema"
                    source="sistema"
                    reference="sistemas"
                    filter={{ id: ["ALR", "CLZ", "DRE", "OTR", "PTS", "RDS", "SEG", "SEN"] }}
                    filterToQuery={searchText => ({ nombre: searchText })}
                    sort={{ field: 'nombre', order: 'asc' }}
                    //validate={[ufSet]}
                    alwaysOn
                    fullWidth
                >
                    <AutocompleteInput options={{ fullWidth: true }} optionText="nombre" />
                </ReferenceInput>


                {(uf == '/sistemas/OTR') ?
                    <ReferenceInput
                        id="tipoElemento"
                        label="resources.respuesta_evaluacion_complementarios.fields.tipoElemento"
                        source="tipoElemento"
                        reference="tipo_elementos"
                        filter={{ puntos: 1, sistema: "OTR" }}
                        filterToQuery={searchText => ({ nombre: searchText })}
                        sort={{ field: 'nombre', order: 'asc' }}
                        alwaysOn
                        fullWidth
                    >
                        <AutocompleteInput options={{ fullWidth: true }} optionText="nombre" />
                    </ReferenceInput>

                    :

                    (uf == '/sistemas/SEN') ?
                        <ReferenceInput
                            id="tipoElemento"
                            label="resources.respuesta_evaluacion_complementarios.fields.tipoElemento"
                            source="tipoElemento"
                            reference="tipo_elemento_senals"
                            filterToQuery={searchText => ({ nombre: searchText })}
                            sort={{ field: 'nombre', order: 'asc' }}
                            alwaysOn
                            fullWidth
                        >
                            <AutocompleteInput options={{ fullWidth: true }} optionText="nombre" />
                        </ReferenceInput>

                        :

                        <ReferenceInput
                            id="tipoElemento"
                            label="resources.respuesta_evaluacion_complementarios.fields.tipoElemento"
                            source="tipoElemento"
                            reference="tipo_elementos"
                            filter={{ sistema: uf }}
                            filterToQuery={searchText => ({ nombre: searchText })}
                            sort={{ field: 'nombre', order: 'asc' }}
                            alwaysOn
                            fullWidth
                        >
                            <AutocompleteInput options={{ fullWidth: true }} optionText="nombre" />
                        </ReferenceInput>
                }

                <SelectInput
                    label="resources.respuesta_evaluacion_complementarios.fields.accion"
                    source="accion"
                    sort={{ field: 'name', order: 'ASC' }}
                    validate={[accSet]}
                    choices={[
                        { id: translate("dynatest.resources.respuesta_evaluacion_complementarios.select.accion.Nada"), name: "dynatest.resources.respuesta_evaluacion_complementarios.select.accion.Nada" },
                        { id: translate("dynatest.resources.respuesta_evaluacion_complementarios.select.accion.Mantenimiento"), name: "dynatest.resources.respuesta_evaluacion_complementarios.select.accion.Mantenimiento" },
                        { id: translate("dynatest.resources.respuesta_evaluacion_complementarios.select.accion.Reparar"), name: "dynatest.resources.respuesta_evaluacion_complementarios.select.accion.Reparar" },
                        { id: translate("dynatest.resources.respuesta_evaluacion_complementarios.select.accion.Reemplazar"), name: "dynatest.resources.respuesta_evaluacion_complementarios.select.accion.Reemplazar" },
                    ]}
                    fullWidth
                    alwaysOn
                    resettable
                />
            </Filter>
        )
    };

    const PostBulkActionButtons = props => (
        <Fragment>
            <BulkDeleteWithConfirmButton {...props} label="¿Borrar?" />
        </Fragment>
    );
    
    if (!permissions) return <Loading />;

    return (

        <List {...props}
            title="dynatest.resources.respuesta_evaluacion_complementarios.title.list"  
            sort={{ field: 'updatedAt', order: 'DESC' }}
            filters={<FiltroRespuestaIndicadorComplementarios/>}
            bulkActionButtons={permissions['respuestaEvaluacionComplementario'] == 1 ? <PostBulkActionButtons /> : null}>
            <Datagrid>
                {/* <Codigo label="Código elemento" codigo={codigo} /> */}
                <TextField label="resources.respuesta_evaluacion_complementarios.fields.codigoElemento" source="smartCode" sortable={true} />
                <ReferenceField label="resources.respuesta_evaluacion_complementarios.fields.sistema" source="sistema" reference="sistemas" linkType={false} >
                    <TextField source="nombre" />
                </ReferenceField>
                <TextField label="resources.respuesta_evaluacion_complementarios.fields.tipoElemento" source="genericTipoElemento" linkType={false} fullWidth />
                <TextField label="resources.respuesta_evaluacion_complementarios.fields.accion" source="accion" linkType={false} fullWidth />
                <DateField label="resources.respuesta_evaluacion_complementarios.fields.fechaMedicion" source="fechaMedicion" linkType={false} fullWidth />
                <TextField label="resources.respuesta_evaluacion_complementarios.fields.estado" source="estado" linkType={false} fullWidth />
                {permissions['respuestaEvaluacionComplementario'] == 1 || permissions['respuestaEvaluacionComplementario'] == 2 || permissions['respuestaEvaluacionComplementario'] == 4 ? <ShowButton/>: null}
                {permissions['respuestaEvaluacionComplementario'] == 1 || permissions['respuestaEvaluacionComplementario'] == 2 ? <EditButton/>: null}
            </Datagrid>
        </List>
    )
});