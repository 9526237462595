import React from 'react';
import { NumberInput, TabbedForm, FormTab, SelectInput, ReferenceInput, FormDataConsumer } from 'react-admin';
import { PimsCreate } from '../../Create';
import Grid from '@material-ui/core/Grid';
import {
  required,
  minLength,
  maxLength,
  minValue,
  maxValue
} from 'react-admin';


function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

// const rows = [
//   createData('Frozen yoghurt'),
//   createData('Ice cream sandwich'),
//   createData('Eclair'),
//   createData('Cupcake'),
//   createData('Gingerbread'),
// ];


export const TasasCreate = props => (
  <PimsCreate title=" Crear Tasa de Crecimiento"  {...props} >
    <TabbedForm redirect="list">
      <FormTab label="Datos">
        <Grid container spacing={24}>
          <Grid item xs={6}>
            <ReferenceInput label="Inventario" source="estacion_conteo" reference="estacion_conteos" fullWidth validate={[required()]}>
              <SelectInput optionText="nombre" />
            </ReferenceInput>
          </Grid>
          <Grid item xs={6}>
            <NumberInput label="Periodo" fullWidth source="periodo" />
          </Grid>
          <Grid item xs={12}>
            <h3 style={{ marginBottom: 0 }}>Fecha de evaluación</h3>
            <hr></hr>
          </Grid>
          <Grid item xs={6}>
            <FormDataConsumer>
              {
                ({ formData, ...rest }) => (
                  <ReferenceInput label="Fecha Inicial" source="date1" reference="ejes" fullWidth validate={[required()]} filter={{ estacion_conteo: formData.estacion_conteo }} {...rest}>
                    <SelectInput optionText="dateq" fullWidth />
                  </ReferenceInput>
                )
              }
            </FormDataConsumer>
          </Grid>
          <Grid item xs={6}>
            <FormDataConsumer>
              {
                ({ formData, ...rest }) => (
                  <ReferenceInput label="Fecha Final" source="date2" reference="ejes" fullWidth validate={[required()]} filter={{ estacion_conteo: formData.estacion_conteo }} {...rest}>
                    <SelectInput optionText="dateq" fullWidth />
                  </ReferenceInput>
                )
              }
            </FormDataConsumer>
          </Grid>

        </Grid>
      </FormTab>
      <FormTab label="Categoría">
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <h3 style={{ marginBottom: 0 }}>Autos</h3>
            <hr></hr>

          </Grid>
          <Grid item xs={6}>
            <NumberInput label="Cantidad Fecha Inicial" source="auto_dano" fullWidth className="align-number" validate={[required(), minLength(1), maxLength(11), minValue(1), maxValue(999999999)]} />
          </Grid>
          <Grid item xs={6}>
            <NumberInput label="Cantidad Fecha Final" source="auto_volumen" fullWidth className="align-number" validate={[required(), minLength(1), maxLength(9), minValue(1), maxValue(999999999)]} />
          </Grid>

          <Grid item xs={12}>
            <h3 style={{ marginBottom: 0 }}>Buses</h3>
            <hr></hr>

          </Grid>
          <Grid item xs={6}>
            <NumberInput label="Cantidad Fecha Inicial" source="buses_dano" fullWidth className="align-number" validate={[required(), minLength(1), maxLength(11), minValue(1), maxValue(999999999)]} />
          </Grid>
          <Grid item xs={6}>
            <NumberInput label="Cantidad Fecha Final" source="buses_volumen" fullWidth className="align-number" validate={[required(), minLength(1), maxLength(9), minValue(1), maxValue(999999999)]} />
          </Grid>

          <Grid item xs={12}>
            <h3 style={{ marginBottom: 0 }}>C2-P</h3>
            <hr></hr>

          </Grid>
          <Grid item xs={6}>
            <NumberInput label="Cantidad Fecha Inicial" source="c2_p_dano" fullWidth className="align-number" validate={[required(), minLength(1), maxLength(11), minValue(1), maxValue(999999999)]} />
          </Grid>
          <Grid item xs={6}>
            <NumberInput label="Cantidad Fecha Final" source="c2_p_volumen" fullWidth className="align-number" validate={[required(), minLength(1), maxLength(9), minValue(1), maxValue(999999999)]} />
          </Grid>
          <Grid item xs={12}>
            <h3 style={{ marginBottom: 0 }}>C2-G</h3>
            <hr></hr>

          </Grid>
          <Grid item xs={6}>
            <NumberInput label="Cantidad Fecha Inicial" source="c2_g_dano" fullWidth  validate={[required(), minLength(1), maxLength(11), minValue(1), maxValue(999999999)]} />
          </Grid>
          <Grid item xs={6}>
            <NumberInput label="Cantidad Fecha Final" source="c2_g_volumen" fullWidth  validate={[required(), minLength(1), maxLength(9), minValue(1), maxValue(999999999)]} />
          </Grid>
          <Grid item xs={12}>
            <h3 style={{ marginBottom: 0 }}>C3-C4</h3>
            <hr></hr>

          </Grid>
          <Grid item xs={6}>
            <NumberInput label="Cantidad Fecha Inicial" source="c3_dano" fullWidth className="align-number" validate={[required(), minLength(1), maxLength(11), minValue(1), maxValue(999999999)]} />
          </Grid>
          <Grid item xs={6}>
            <NumberInput label="Cantidad Fecha Final" source="c3_volumen" fullWidth className="align-number" validate={[required(), minLength(1), maxLength(9), minValue(1), maxValue(999999999)]} />
          </Grid>
          <Grid item xs={12}>
            <h3 style={{ marginBottom: 0 }}>C5</h3>
            <hr></hr>

          </Grid>
          <Grid item xs={6}>
            <NumberInput label="Cantidad Fecha Inicial" source="c5_dano" fullWidth className="align-number" validate={[required(), minLength(1), maxLength(11), minValue(1), maxValue(999999999)]} />
          </Grid>
          <Grid item xs={6}>
            <NumberInput label="Cantidad Fecha Final" source="c5_volumen" fullWidth className="align-number" validate={[required(), minLength(1), maxLength(9), minValue(1), maxValue(999999999)]} />
          </Grid>
          <Grid item xs={12}>
            <h3 style={{ marginBottom: 0 }}>>C5</h3>
            <hr></hr>

          </Grid>
          <Grid item xs={6}>
            <NumberInput label="Cantidad Fecha Inicial" source="c5_1_dano" fullWidth className="align-number" validate={[required(), minLength(1), maxLength(11), minValue(1), maxValue(999999999)]} />
          </Grid>
          <Grid item xs={6}>
            <NumberInput label="Cantidad Fecha Final" source="c5_1_volumen" fullWidth className="align-number" validate={[required(), minLength(1), maxLength(9), minValue(1), maxValue(999999999)]} />
          </Grid>
        </Grid>
      </FormTab>
    </TabbedForm>
  </PimsCreate>

);