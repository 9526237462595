import React from 'react';
import { TextInput, SelectInput, ReferenceInput, AutocompleteInput, NumberInput, minValue, maxValue, BooleanInput } from 'react-admin';
import { withStyles, Grid } from '@material-ui/core';
import { PimsCreate } from './../Create';
import { SimpleForm } from './../SimpleForm';
import { styles } from './../../EstilosGrid';
import { required, maxLength } from 'react-admin';
import { abValidationInicio, Entero, validarUnDecimal, validateDecima, validarDecimales, validarAB, abValidationFin, validarDosDecimales, validarPorcentaje, validarDecimales2 } from '../validacionesInputs';
import { useState } from 'react';
import { translate } from 'react-admin';

export const TrayectoriasCreate = translate(withStyles(styles)(({ classes, ...props }) => {
  const { translate } = props;
  const styles = {
    first_field: { width: '100%' }
  };
  const validatePrecio = (valor) => {
    let precio1 = 0;
    let precio2 = 0;
    precio1 = document.getElementById('12').value;
    precio2 = document.getElementById('13').value;
    if (parseInt(precio1) === parseInt(precio2)) {
      return translate('dynatest.resources.trayectorias.messages.precioTotalIgual');
    }
    else if (parseInt(precio1) < parseInt(precio2)) {
      return translate('dynatest.resources.trayectorias.messages.precioTotalMayor');
    }
  }

  return (
    <PimsCreate {...props} >
      <SimpleForm redirect="list" >
        <ReferenceInput
          label="resources.trayectorias.fields.unidad_funcional"
          source="unidad_funcional"
          id="unidad_id"
          reference="unidad_funcionals"
          filterToQuery={searchText => ({ codigo: searchText })}
          sort={{ field: 'codigo', order: 'asc' }}
          validate={[required()]}
          fullWidth
          formClassName={classes.grid_cont4s}>
          <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
        </ReferenceInput>
        <NumberInput source="trafico" label="resources.trayectorias.fields.trafico" fullWidth validate={[minValue(0), Entero, maxValue(1000000)]} formClassName={classes.grid_cont4} />
        <TextInput label="resources.trayectorias.fields.nombre" source="nombre" fullWidth validate={[maxLength(30)]} formClassName={classes.grid_cont4} />
        <NumberInput source="precio" id="12" label="resources.trayectorias.fields.precio" fullWidth validate={[minValue(0), Entero]} formClassName={classes.grid_cont4s} />
        <NumberInput source="preciocar" id="13" label="resources.trayectorias.fields.preciocar" fullWidth validate={[minValue(0), Entero, validatePrecio]} formClassName={classes.grid_cont4} />
        <NumberInput label="resources.trayectorias.fields.vida" source="vida" fullWidth validate={[minValue(0), Entero, maxValue(30)]} formClassName={classes.grid_cont4} />
        <NumberInput label="resources.trayectorias.fields.espesor" source="espesor" fullWidth validate={[minValue(0), validarDosDecimales, maxValue(500)]} formClassName={classes.grid_cont4s} />

        <Grid container spacing={24}>
          <Grid item xs={4} ></Grid>
          <Grid item xs={4} ><strong >{translate('dynatest.resources.trayectorias.tableEspesor.nameColumns.Espesores')}</strong></Grid>
          <Grid item xs={4}><strong>{translate('dynatest.resources.trayectorias.tableEspesor.nameColumns.CodigoMaterial')} </strong></Grid>
        </Grid>

        <Grid container spacing={24} >
          <Grid item xs={4}>
            <strong>{translate('dynatest.resources.trayectorias.tableEspesor.nameRows.EspesorCapa1')}</strong>
          </Grid>
          <Grid item xs={4}> 
            <NumberInput label=" " fullWidth source="espesorcap1" validate={[minValue(0), Entero, maxValue(2000), required()]} id="1"/>
          </Grid>
          <Grid item xs={4}>
            <ReferenceInput label=" " source="material" reference="materials" 
                filterToQuery={searchText => ({ material: searchText })} 
                sort={{ field: 'material', order: 'ASC' }} 
                fullWidth 
                validate={[required()]}
                perPage={50}
                >
                    <AutocompleteInput options={{ fullWidth: true }} optionText="material" />
            </ReferenceInput>
          </Grid>
        </Grid>

        <Grid container spacing={24}>
          <Grid item xs={4}>
            <strong>{translate('dynatest.resources.trayectorias.tableEspesor.nameRows.EspesorCapa2')}</strong>
          </Grid>
          <Grid item xs={4}> 
            <NumberInput label=" " source="espesorcap2" fullWidth validate={[minValue(0), Entero, maxValue(2000)]} id="2" />
          </Grid>
          <Grid item xs={4}>
            <ReferenceInput label=" " source="material1" reference="materials" 
                filterToQuery={searchText => ({ material: searchText })} 
                sort={{ field: 'material', order: 'ASC' }} 
                fullWidth 
                allowEmpty={true}
                perPage={50}
                >
                  <AutocompleteInput options={{ fullWidth: true }} optionText="material" />
            </ReferenceInput>
          </Grid>
        </Grid>

        <Grid container spacing={24}>
          <Grid item xs={4}>
            <strong>{translate('dynatest.resources.trayectorias.tableEspesor.nameRows.EspesorCapa3')}</strong>
          </Grid>
          <Grid item xs={4}> 
            <NumberInput label=" " source="espesorcap3" fullWidth validate={[minValue(0), Entero, maxValue(2000)]} id="3" />
          </Grid>
          <Grid item xs={4}>
            <ReferenceInput label=" " source="material2" reference="materials" 
                filterToQuery={searchText => ({ material: searchText })} 
                sort={{ field: 'material', order: 'ASC' }} 
                fullWidth 
                allowEmpty={true}
                perPage={50}
                >
                  <AutocompleteInput options={{ fullWidth: true }} optionText="material" />
            </ReferenceInput>
          </Grid>
        </Grid>

        <Grid container spacing={24}>
          <Grid item xs={4}>
            <strong>{translate('dynatest.resources.trayectorias.tableEspesor.nameRows.EspesorCapa4')}</strong>
          </Grid>
          <Grid item xs={4}> 
            <NumberInput label=" " source="espesorcap4" fullWidth validate={[minValue(0), Entero, maxValue(2000)]} id="4" />
          </Grid>
          <Grid item xs={4}>
            <ReferenceInput label=" " source="material3" reference="materials" 
                filterToQuery={searchText => ({ material: searchText })} 
                sort={{ field: 'material', order: 'ASC' }} 
                fullWidth 
                allowEmpty={true}
                perPage={50}
                >
                  <AutocompleteInput options={{ fullWidth: true }} optionText="material" />
            </ReferenceInput>
          </Grid>
        </Grid>

        {/* <TextInput label="Material Name" source="materialname" fullWidth formClassName={classes.grid_cont3}/> */}
        <BooleanInput label="dynatest.resources.trayectorias.boolean.rigido" source="rigido" formClassName={classes.grid_cont4} />
        <BooleanInput label="dynatest.resources.trayectorias.boolean.flexible" source="flexible" formClassName={classes.grid_cont4} />

        <Grid container spacing={24}>
          <Grid item xs={4}>
          <strong style={{ display: 'flex', marginLeft:'30px', marginTop:'20px' }}>{translate('dynatest.resources.trayectorias.tableIndicador.nameColumns.Indicador')}</strong>
          </Grid>
          <Grid item xs={2}>
          <strong style={{ display: 'flex', alignItems: 'flex-end', marginRight: '150px', marginBottom: '15px' }}>{translate('dynatest.resources.trayectorias.tableIndicador.nameColumns.Mejorar')}</strong>
          </Grid>
          <Grid item xs={2}>
          <strong style={{ display: 'flex', alignItems: 'flex-end', marginRight: '150px', marginBottom: '15px' }}>{translate('dynatest.resources.trayectorias.tableIndicador.nameColumns.Limite')}</strong>
          </Grid>
        </Grid>

        <Grid container spacing={24}>
          <Grid item xs={4}>
            <strong style={{ display: 'flex', marginLeft:'30px', marginTop:'20px' }}>{translate('dynatest.resources.trayectorias.tableIndicador.nameRows.IRI')}</strong>
          </Grid>
          <Grid item xs={2}> 
            <NumberInput label=" " source="iri_mejora" fullWidth validate={[required(), validarDosDecimales, minValue(0), maxValue(20)]} id="1"  />
          </Grid>
          <Grid item xs={2}>
          <NumberInput label=" " source="iri_limite" fullWidth validate={[required(), validarDosDecimales, minValue(0), maxValue(20)]} />
          </Grid>
        </Grid>
        <Grid container spacing={24}>
          <Grid item xs={4}>
          <strong style={{ display: 'flex', marginLeft:'30px', marginTop:'20px' }}>{translate('dynatest.resources.trayectorias.tableIndicador.nameRows.Friccion')}</strong>
          </Grid>
          <Grid item xs={2}> 
            <NumberInput label=" " source="friccion_mejora" fullWidth validate={[required(), Entero, minValue(0), maxValue(100)]} id="2"/>   
          </Grid>
          <Grid item xs={2}>
            <NumberInput label=" " source="friccion_limite" fullWidth validate={[required(), Entero, minValue(0), maxValue(100)]} />
          </Grid>
        </Grid>
        <Grid container spacing={24}>
          <Grid item xs={4}>
          <strong style={{ display: 'flex', marginLeft:'30px', marginTop:'20px' }}>{translate('dynatest.resources.trayectorias.tableIndicador.nameRows.Ahuellamiento')}</strong>
          </Grid>
          <Grid item xs={2}> 
            <NumberInput label=" " source="ahuellamiento_mejora" fullWidth validate={[required(), Entero, minValue(0), maxValue(100)]}  id="3" />
          </Grid>
          <Grid item xs={2}>
            <NumberInput label=" " source="ahuellamiento_limite" fullWidth validate={[required(), Entero, minValue(0), maxValue(100)]}  />
          </Grid>
        </Grid>
        <Grid container spacing={24}>
          <Grid item xs={4}>
          <strong style={{ display: 'flex', marginLeft:'30px', marginTop:'20px' }}>{translate('dynatest.resources.trayectorias.tableIndicador.nameRows.PCI')}</strong>
          </Grid>
          <Grid item xs={2}> 
            <NumberInput label=" " source="pci_mejora" fullWidth validate={[required(), Entero, minValue(0), maxValue(100)]}  id="4" />
          </Grid>
          <Grid item xs={2}>
            <NumberInput label=" " source="pci_limite" fullWidth validate={[required(), Entero, minValue(0), maxValue(100)]}  />
          </Grid>
        </Grid>
      </SimpleForm>
    </PimsCreate>
  )
}));