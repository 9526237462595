import React from 'react';
import NumberFormat from 'react-number-format';


export default function dFormatter(props){
    const { inputRef, ...other } = props;
    return (
        <NumberFormat
           {...other}
            getInputRef={inputRef}
            decimalSeparator=','
            decimalScale={2}
            fixedDecimalScale={true}
            allowNegative={false}            
        />
      );
}