import React, { useState } from 'react';
import { CardActions, TextInput, TabbedForm, Toolbar, Datagrid, ShowController, ShowView, TextField, DateField, ReferenceField, BooleanField, FormTab, ListButton, FormDataConsumer } from 'react-admin';
// import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core';
import { styles } from './../../EstilosGrid';
import { translate } from 'react-admin';
import DatatableAudit from './DatatableAudit';

export const AuditShow = translate(withStyles(styles)(({ classes, record, ...props }) => {
    const { translate } = props;
    const [datosArray, setDatosArray] = useState([]);
    const PostShowActions = ({ basePath }) => (
        <CardActions>
            <ListButton basePath={basePath} />
        </CardActions>
    );
    //let elemento = "/tipo_elementos/COMR";
    return (
        <ShowController {...props} >
            {controllerProps =>
                <ShowView {...props} {...controllerProps} title="resources.audits.tabs.title" actions={<PostShowActions />}>
                    <TabbedForm toolbar={false}>
                        <FormTab label="resources.audits.tabs.tabIdentificacion">
                            <TextField label="resources.audits.fields.idElement" source="idElement" fullWidth formClassName={classes.grid_cont4} />
                            <TextField label="resources.audits.fields.smartCode" source="smartCode" fullWidth formClassName={classes.grid_cont4} />
                            <TextField label="resources.audits.fields.type" source="type" fullWidth formClassName={classes.grid_cont3} />

                            <TextField label="resources.audits.fields.module" source="module" fullWidth formClassName={classes.grid_cont4} />
                            <TextField label="resources.audits.fields.functionality" source="functionality" fullWidth formClassName={classes.grid_cont4} />
                            <TextField label="resources.audits.fields.subElement" source="subElement" fullWidth formClassName={classes.grid_cont3} />
                            
                            <DateField label="resources.audits.fields.dateIn" source="dateIn" showTime fullWidth formClassName={classes.grid_cont4}/>
                            <TextField label="resources.audits.fields.user" source="user" fullWidth formClassName={classes.grid_cont4} />
                            <TextField label="resources.audits.fields.createdIn" source="createdIn" fullWidth formClassName={classes.grid_cont3} />
                            
                            <TextField label="resources.audits.fields.tableOrigin" source="tableOrigin" fullWidth formClassName={classes.grid_cont4} />


                            <Typography className={classes.grid_cont12} variant="b" component="b">{translate('resources.audits.tabs.tabDetalles')}</Typography>
                            <Divider fullWidth className={classes.grid_cont12}/>

                            <DatatableAudit data={controllerProps.record} />

                        </FormTab>

                    </TabbedForm>
                </ShowView>
            }
        </ShowController>
    )
}));