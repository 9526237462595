import React, {useState} from 'react';
import { List, Datagrid, TextField, EditButton, ShowButton, Filter, ReferenceInput, AutocompleteInput, NumberField, ReferenceField,
    ArrayField, SingleFieldList, Loading, DateField,SelectInput} from 'react-admin';
import { Fragment } from 'react';
import {  BulkDeleteWithConfirmButton  } from 'react-admin';
import { translate } from 'react-admin';
import { CardActions, CreateButton, ExportButton } from 'react-admin';
const RutaFilter= translate((props) => {
    const { translate } = props;
    const [uf, setUf] = useState('none');
    const ufSet = (value) => {
        setUf(value);
    }
    const [tramo, setTramo] = useState('none');
    const tramoSet = (value) => {
        setTramo(value);
    }
        
    return(
    <Filter {...props}>
        <ReferenceInput 
            id="unidadFuncional"
            source="unidadFuncional"  
            reference="unidad_funcionals" 
            filterToQuery={searchText => ({ codigo: searchText })} 
            sort={{field : 'codigo', order : 'asc'}}
            validate={[ufSet]}
            alwaysOn
            fullWidth 
        >
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
        <ReferenceInput 
            source="tramo" 
            alwaysOn 
            reference="tramos" 
            filter={{ unidadFuncional: uf}}
            validate={[tramoSet]}
            filterToQuery={searchText => ({ codigo: searchText })} 
            sort={{ 
                field: 'codigo', 
                order: 'ASC' ,
                
            }}>
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
        
        <ReferenceInput 
            source="segmento" 
            alwaysOn 
            reference="segmentos" 
            sort={{ field: 'codigo', order: 'ASC' }}
            filter={{tramo : tramo}}
            filterToQuery={searchText => ({ codigo: searchText })} 
        >
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
        <SelectInput      
            source="detallesSegmentos.estadoDetalles"  label="resources.adoquin_detalles.fields.estadoDetalles" 
                alwaysOn sort={{field : 'name' , order :'ASC'}} choices={[
                    { id: translate('dynatest.resources.adoquin_detalles.select.estadoDetalles.Activo'), name: 'dynatest.resources.adoquin_detalles.select.estadoDetalles.Activo' },
                    { id: translate('dynatest.resources.adoquin_detalles.select.estadoDetalles.Inactivo'), name: 'dynatest.resources.adoquin_detalles.select.estadoDetalles.Inactivo' },               
            ]} />  
    </Filter>
    )
});

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);
const ListAction = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
        <ExportButton basePath={basePath}/>
    </CardActions>
);
const ListAction2 = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
    </CardActions>
);
export const ElementoAdoquinList = ({ permissions, ...props }) => {
    if (!permissions) return <Loading />;  
    return (
    <List {...props} 
        sort={{ field: 'updatedAt', order: 'DESC' }} 
        actions={permissions['adoquin']  == 1 ? <ListAction /> : permissions['adoquin']  == 2 ? <ListAction2 />: null}
        filters={<RutaFilter/>} 
        bulkActionButtons={permissions['adoquin']  == 1 ? <PostBulkActionButtons />: null}>
        <Datagrid>  
            <TextField source="smartCode" sortable />  
            <TextField label="resources.adoquin_detalles.fields.proveedor" source="detallesSegmentos[0].proveedor_.razonSocial" sortable /> 
            <NumberField label="resources.adoquin_detalles.fields.esalTime" source="detallesSegmentos[0].esalTime" sortable={false} linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}}/>
            <NumberField source="detallesSegmentos[0].esalTime10"  label="resources.adoquin_detalles.fields.esalTime10" sortable={false} linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} />
            <TextField source="detallesSegmentos[0].nivelTrafico" label="resources.adoquin_detalles.fields.nivelTrafico" linkType={false} />
            <DateField source="detallesSegmentos[0].dateInicioOperacion" label="resources.adoquin_detalles.fields.dateInicioOperacion" linkType={false}/>
            <TextField source="detallesSegmentos[0].estadoDetalles"  label="resources.adoquin_detalles.fields.estadoDetalles" sortable/>
            {permissions['adoquin'] == 1 || permissions['adoquin'] == 2 || permissions['adoquin'] == 4 ? <ShowButton/>: null}
            {permissions['adoquin'] == 1 || permissions['adoquin'] == 2 ? <EditButton/>: null}    
        </Datagrid>
    </List>
)};