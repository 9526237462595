import React, { useState } from 'react';
import {
    Toolbar, CardActions, Tab, NumberField, ShowController, ShowView, TextField, DateField,
    ReferenceField, BooleanField, TabbedForm, ListButton, EditButton, FunctionField, FormDataConsumer,
    SingleFieldList, FormTab, RichTextField, ReferenceArrayField, Datagrid
} from 'react-admin';
import { PRField } from './../../../fields/PRField';
import AddFlujoCajaDetalles  from './../localHelpers/AddFlujoCajaDetalles';
import { Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/core';
import { styles } from './../../../EstilosGrid';
import config from '../../../config/config';
import { translate } from 'react-admin';
let urlAppjs = config.UrlServer;


const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};


const PostShowActions = ({ basePath, data }) => (
    <CardActions style={cardActionStyle}>
        <EditButton basePath={basePath} record={data} />
        {/* Add your custom actions */}
        <ListButton basePath={basePath} />
    </CardActions>
);

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <span></span>

    </Toolbar>
));

clearTimeout();


export const FlujoCajaShow = translate(withStyles(styles)(({ classes, record, ...props }) => {
    return (
        <ShowController {...props} title="Información Flujo de Caja">
            {controllerProps =>
                <ShowView {...props} {...controllerProps} title="dynatest.resources.flujo_cajas.title.showTitle" actions={<PostShowActions />}>
                    <TabbedForm toolbar={<CustomToolbar />}>
                        <FormTab label="dynatest.resources.flujo_cajas.tabs.informacion">
                            <FunctionField
                                label="resources.flujo_cajas.fields.id"
                                source="id"
                                render={record => record.id.split("/")[2]}
                                linkType={false}
                                fullWidth
                                formClassName={classes.grid_cont3}
                            />
                            <br/>
                            <ReferenceField
                                label="resources.flujo_cajas.fields.unidadFuncional"
                                source="unidadFuncional"
                                reference="unidad_funcionals"
                                linkType={false}
                                fullWidth
                                formClassName={classes.grid_cont3}
                            >
                                <TextField label="" source="fullCodigos" />
                            </ReferenceField>
                            <ReferenceField
                                label="resources.flujo_cajas.fields.nombrePolitica"
                                source="nombrePolitica"
                                reference="politicas_nombres"
                                linkType={false}
                                fullWidth
                                formClassName={classes.grid_cont3}
                            >
                                <TextField label="" source="nombre" />
                            </ReferenceField>
                            <FunctionField 
                                label="resources.flujo_cajas.fields.tasaInteres" 
                                source="tasaInteres"
                                render={record => record.tasaInteres + "%"}
                                formClassName={classes.grid_cont3}
                                style={{display: "flex", justifyContent: "flex-start", marginLeft : "50%"}}
                            />   

                            <br/>
                            <DateField 
                                label="resources.flujo_cajas.fields.fechaInicio"
                                source="fechaInicio"
                                fullWidth
                                formClassName={classes.grid_cont3}
                            />
                            <NumberField 
                                label="resources.flujo_cajas.fields.years"
                                source="years" 
                                fullWidth
                                formClassName={classes.grid_cont3}
                                style={{display: "flex", justifyContent: "flex-start", marginLeft : "50%"}}
                            />
                            <br/>

                            {controllerProps.record &&
                                <AddFlujoCajaDetalles 
                                    parentProps={controllerProps.record} 
                                />
                            }
                        </FormTab>
                        <FormTab label="dynatest.resources.flujo_cajas.tabs.auditoria">
                            <DateField source="createdAt" label="resources.flujo_cajas.fields.createdAt" fullWidth formClassName={classes.grid_cont4} showTime/>
                            <TextField source="createdBy" label="resources.flujo_cajas.fields.createdBy" fullWidth formClassName={classes.grid_cont4} />
                            <span></span>
                            <DateField source="updatedAt" label="resources.flujo_cajas.fields.updatedAt" fullWidth formClassName={classes.grid_cont4} showTime/>
                            <TextField source="updatedBy" label="resources.flujo_cajas.fields.updatedBy" fullWidth formClassName={classes.grid_cont4} />
                            <span></span>
                        </FormTab>
                    </TabbedForm>
                </ShowView>
            }
        </ShowController>
    )
}));