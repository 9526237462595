import React from 'react';
import { TabbedShowLayout, Tab, TextField, DateField } from 'react-admin';
import { Show } from './../Show';


export const TransitoShow = props => (
    <Show  {...props}>
        <TabbedShowLayout>
            <Tab label="Información básica">
                <TextField label="Departamento" source="municipio_.departamento_.nombre" />
                <TextField label="Municipio" source="municipio_.nombre" />
                <TextField label="Codigo Identificación" source="identificacion" />
                <TextField label="Nombre" source="nombre"/>
                <TextField label="Rutas" source="ruta_.codigo"/>
                <TextField label="Unidad Funcional" source="unidadfuncional_.codigo" />
                <TextField label="Tramos" source="tramo_.codigo" />
                <TextField label="Segmentos" source="tiposegmento_.nombre" />
            </Tab>
            <Tab label="Auditoria">
                <DateField source="createdAt" label="Fecha creación" showTime/> 
                <TextField source="createdBy" label="Creado por"/>                
                <DateField source="updatedAt" label="Fecha actualización" showTime/>
                <TextField source="updatedBy" label="Actualizado por"/>                
            </Tab>
        </TabbedShowLayout>
    </Show>
);