import React, { Fragment, Component } from 'react';

import { connect } from 'react-redux';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
//import SelectProfileButton from './button/profileSelect';
import SelectProyectoButton from './buttons/proyectoSelect';

import { withStyles, CardActions } from '@material-ui/core';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { Query, Loading } from 'react-admin';
import { changeLocale as changeLocaleAction } from 'react-admin';
import { translate } from 'react-admin';

const styles = theme => ({
    container: {
      backgroundColor: theme.primary,
      color: theme.secondary,
      
      margin: theme.spacing.unit * 2
    },
    button: {
        margin: theme.spacing.unit * 2,
        float: 'right'
    }
  });

const payload = {
    pagination: { page: 1, perPage: 50 },
    sort: { field: 'id', order: 'ASC' },
 };

 
  const PimsDashboard = translate(withStyles(styles)(({ classes, profile, user, ...props }) => {
    const { translate } = props;
    const { changeLocale } = props;
    // let idiomaUsuario = window.localStorage.getItem('idioma');
    // idiomaUsuario = (idiomaUsuario != undefined && idiomaUsuario != null) ? idiomaUsuario : 'es';
    // props.changeLocale(idiomaUsuario);
  return(
    <Grid container spacing={8}>
        <Grid item xs={12}>
            <Typography component="h2" variant="title"> {translate('resources.inicio.titleProyecto')}</Typography>
        </Grid>
        <Query type="GET_LIST" resource="proyectos" payload={payload}>
            {({ data, loading, error }) => {
                if (loading) { return <Loading loadingSecondary="resources.inicio.mensaje"/>; }
                if (error) { return <p>ERROR {error.message}</p>; }
                return (
                    data.map(
                        proyecto => 
                        <Grid item xs={6}>
                            <Card className={classes.container} >
                                <CardContent>
                                    <Typography component="h2" variant="title">{proyecto.codigo}</Typography>                                    
                                    <Typography variant="subtitle1" color="textSecondary" component="p">{proyecto.nombre}</Typography>    
                                    <Typography style={{display:'none'}} component="h2" variant="title">{proyecto.id}</Typography>                                
                                </CardContent>
                                <CardActions>
                                    <SelectProyectoButton proyecto={proyecto} label={translate('resources.inicio.seleccionar')} className={classes.button} />
                                </CardActions>
                            </Card>
                        </Grid>
                    )
                );
            }}
        </Query>
    </Grid>
)}));

const mapStateToProps = state => ({    
    profile: state.profile,
    user: state.user
});

// export default connect(mapStateToProps, { changeLocale: changeLocaleAction })(PimsDashboard);
export default connect(mapStateToProps)(PimsDashboard);