import React, { useState, Fragment } from 'react';
import {
    CloneButton, List, Datagrid, FormDataConsumer, EditButton, ShowButton, Filter, ReferenceField,
    SelectInput, ReferenceInput, BulkDeleteWithConfirmButton, TextField, AutocompleteInput, Loading
} from 'react-admin';
import { CardActions, CreateButton, ExportButton } from 'react-admin';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { stringify } from 'query-string';
import ListIcon from '@material-ui/icons/List';
import { REDUX_FORM_NAME } from 'react-admin';
import { change } from 'redux-form';
import { translate } from 'react-admin';

//--------LIST LINE ACTIONS
const ListarPlan = (props) => (
    <Button
        {...props}
        size="small"
        color="primary"
        component={Link}
        to={{
            pathname: '/plan_mantenimiento_detalles',
            search: stringify({
                page: 1,
                perPage: 25,
                sort: 'id',
                order: 'DESC',
                filter: JSON.stringify({ planMantenimientos: props.record.id }),
            }),
        }}
    >
        <ListIcon />
    </Button>
)


//-----FIN DEL LIST ACTIONS

const PostBulkActionButtons = (props) => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props} label="¿Borrar?" />
    </Fragment>
);

const ListAction = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
        <ExportButton basePath={basePath}/>
    </CardActions>
);
const ListAction2 = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
    </CardActions>
);


const Filtro = translate((props) => {
    const [getSistema, setSistema] = useState();
    const regSistema = (value) => {
        // let lenghtObject = Object.keys(value);
        // let valor = '';
        // for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        // console.log("sistema->", valor)
        setSistema(value);
    }

    const [getTipoElemento, setTipoElemento] = useState();
    const regTipoElemento = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setTipoElemento(valor)
    }

    const [getUnidadFuncional, setUnidadFuncional] = useState();
    const validarUnidadFuncional = (value) => {
        setUnidadFuncional(value);
    }
    const [getTramo, setTramo] = useState();
    const validarTramo = (value) => {
        setTramo(value);
    }

    return (
        <Filter {...props}>
            <ReferenceInput
                label="resources.politicas_mantenimiento_particulars.fields.sistema"
                source="sistema"
                reference="sistemas"
                filter={{
                    visibleOnPolicies: 1
                }}
                validate={[regSistema]}
                alwaysOn
            >
                <SelectInput optionText="nombreEnPoliticas" />
            </ReferenceInput>
            <ReferenceInput
                label="resources.politicas_mantenimiento_particulars.fields.tipoElementoShow"
                source="tipoElemento"
                reference="tipo_elementos"
                onChange={regTipoElemento}
                filter={{
                    visibleOnPolicies: 1,
                    sistema: getSistema
                }}
                sort={{ field: 'nombre', order: 'ASC' }}
                alwaysOn

            >
                <SelectInput optionText="nombre" />
            </ReferenceInput>

            <ReferenceInput
                id="departamento"
                // value="" 
                label="resources.politicas_mantenimiento_particulars.fields.unidadFuncional"
                source="unidadFuncional"
                reference="unidad_funcionals"
                validate={[validarUnidadFuncional]}
                alwaysOn
            >
                <AutocompleteInput optionText="fullCodigos" />
            </ReferenceInput>
            <ReferenceInput
                label="resources.politicas_mantenimiento_particulars.fields.tramo"
                source="tramo"
                reference="tramos"
                validate={[validarTramo]}
                filter={{
                    unidadFuncional: getUnidadFuncional
                }}
                alwaysOn
            >
                <AutocompleteInput optionText="fullCodigos" />
            </ReferenceInput>
        </Filter>
    );
});
export const PoliticasMantenimientoParticularList = ({ permissions, ...props }) => {
    if (!permissions) return <Loading />;
    return (
        <List {...props}
            sort={{ field: 'id', order: 'DESC' }}
            title="dynatest.resources.politicas_mantenimiento_particulars.title.listTitle"
            filters={<Filtro />}
            actions={permissions['politicaParticular']  == 1 ? <ListAction /> : permissions['politicaParticular']  == 2 ? <ListAction2 />: null}
            bulkActionButtons={permissions['politicaParticular'] == 1 ? <PostBulkActionButtons /> : null}
        >
            <Datagrid>
                <TextField
                    label="resources.politicas_mantenimiento_particulars.fields.nombre"
                    source="nombre"
                    linkType={false}
                    fullWidth
                />
                <ReferenceField
                    label="resources.politicas_mantenimiento_particulars.fields.sistema"
                    source="sistema"
                    reference="sistemas"
                    linkType={false}
                    fullWidth
                >
                    <TextField label="" source="nombreEnPoliticas" />
                </ReferenceField>
                <TextField label="resources.politicas_mantenimiento_particulars.fields.tipoElementoShowTable" source="tipoElementoNombre_.nombre" fullWidth />
                {permissions['politicaParticular'] == 1 ? <CloneButton label="resources.politicas_mantenimiento_particulars.fields.Clonar" />: null}
                {permissions['politicaParticular'] == 1 || permissions['politicaParticular'] == 2 || permissions['politicaParticular'] == 4 ? <ShowButton/>: null}
                {permissions['politicaParticular'] == 1 || permissions['politicaParticular'] == 2 ? <EditButton/>: null}
            </Datagrid>
        </List>
    )
};