import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { Filter, List, SimpleForm, Datagrid, ReferenceInput, Toolbar, CardActions, SelectInput, TextField, NumberField, EditButton, ShowButton } from 'react-admin';
import { BulkDeleteWithConfirmButton, required, Loading } from 'react-admin';
import { Fragment } from 'react';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { styles } from './../../EstilosGrid';
import Grid from '@material-ui/core/Grid';
import config from '../../config/config';
import { translate } from 'react-admin';
let urlAppjs = config.UrlServer;


const años = [{ id: '2000', name: '2000' },
{ id: '2001', name: '2001' },
{ id: '2002', name: '2002' },
{ id: '2003', name: '2003' },
{ id: '2004', name: '2004' },
{ id: '2005', name: '2005' },
{ id: '2006', name: '2006' },
{ id: '2007', name: '2007' },
{ id: '2008', name: '2008' },
{ id: '2009', name: '2009' },
{ id: '2010', name: '2010' },
{ id: '2011', name: '2011' },
{ id: '2012', name: '2012' },
{ id: '2013', name: '2013' },
{ id: '2014', name: '2014' },
{ id: '2015', name: '2015' },
{ id: '2016', name: '2016' },
{ id: '2017', name: '2017' },
{ id: '2018', name: '2018' },
{ id: '2019', name: '2019' },
{ id: '2020', name: '2020' },
{ id: '2021', name: '2021' },
{ id: '2022', name: '2022' },
{ id: '2023', name: '2023' },
{ id: '2024', name: '2024' },
{ id: '2025', name: '2025' },
{ id: '2026', name: '2026' },
{ id: '2027', name: '2027' },
{ id: '2028', name: '2028' },
{ id: '2029', name: '2029' },
{ id: '2030', name: '2030' }];


const SimpleDialog = translate(({...props}) =>{
    const { onClose, selectedValue, open } = props;
    const { translate } = props;
    const handleClose = () => {
        onClose(selectedValue);
    };

    const handleListItemClick = value => {
        onClose(value);
    };

    const [unidadSave, setUnidadSave] = useState('');
    const saveUnidad = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setUnidadSave(valor)
    }

    const [analisis, setAnalisis] = useState('');
    const saveParAnalisis = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setAnalisis(valor)
    }

    const [persImport, setPersImport] = useState('');
    const savePersImport = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        valor = valor.split("/")
        console.log("savePersImport")
        console.log(valor)
        setPersImport(valor[2])
    }

    const handleListItemClick2 = props => {
        if (unidadSave == "") {
            alert("Debe seleccionar la Unidad Funcional");
            return;
        }
        if (analisis == "") {
            alert("Debe seleccionar el parámetro de analisis");
            return;
        }
        const data = new FormData();
        data.append('unidadSave', unidadSave);
        data.append('analisis', analisis);
        data.append('persImport', persImport);

        fetch(urlAppjs + '/generarPERS/', {
            method: 'POST',
            body: data
        })
            .then(res => res.json())
            .then(datos => {
                if (datos == "Sin datos") {
                    alert("No se encontraron datos.");
                } else {
                    const URL = urlAppjs + "/Pers/" + datos
                    window.location.href = URL;
                    onClose(false);
                    setTimeout(function () { window.location.reload(); }, 3000);
                }
            });
    };

    const CustomToolbar = ({ basePath, data, ...props }) => {
        return (
        <Toolbar {...props} >
            <Button variant="secondary" id="generarInforme" onClick={handleListItemClick2} style={{ backgroundColor: '#3f51b5', color: '#fff' }}>
                <AddIcon /> {translate('dynatest.resources.pers_exports.btnGenerarInf')}
            </Button>
        </Toolbar>
    )};

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} fullWidth={'fullWidth'} maxWidth={'sm'}>
            <DialogTitle id="simple-dialog-title">{translate('dynatest.resources.pers_exports.titleCrearInforme')}</DialogTitle>
            <SimpleForm toolbar={<CustomToolbar />}>
                <Grid container spacing={24}>
                    <Grid item xs={6}>
                        <ReferenceInput source="unidadSave" label="resources.pers_exports.unidadSave" reference="unidad_funcionals" validate={[required()]} alwaysOn onChange={saveUnidad}>
                            <SelectInput optionText="fullCodigos" fullWidth />
                        </ReferenceInput>
                        
                    </Grid>
                    <Grid item xs={6}>
                        <ReferenceInput source="parAnalisis" label="resources.pers_exports.parAnalisis" reference="parametros_analises" validate={[required()]} alwaysOn onChange={saveParAnalisis}>
                            <SelectInput optionText="plan" fullWidth />
                        </ReferenceInput>
                    </Grid>
                    {unidadSave &&
                        <Grid item xs={6}>
                            <ReferenceInput filter={{ "properties[]": "urlDocument", unidadFuncional: unidadSave }} sort={{ field: 'urlDocument', order: 'ASC', }} 
                                source="persImport" label="resources.pers_exports.persImport" reference="pers_imports" alwaysOn onChange={savePersImport} perPage={100}>
                                <SelectInput resettable optionText="urlDocument" fullWidth />
                            </ReferenceInput>
                        </Grid>
                    }
                    {/* <Grid item xs={6}>
                    <SelectInput label="Año" source="yearSave" choices={años}alwaysOn fullWidth validate={[required()]} onChange={saveYear}/>
                </Grid> */}
                </Grid>
            </SimpleForm>
        </Dialog>
    );
});

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    selectedValue: PropTypes.string.isRequired,
};



const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props} />
    </Fragment>
);
const FiltroSeguridad = translate((props) => (
    <Filter {...props}>
        <ReferenceInput label="resources.pers_exports.unidad_funcional" reference="unidad_funcionals" source="unidad_funcional" alwaysOn>
            <SelectInput optionText="codigo" fullWidth />
        </ReferenceInput>
        <SelectInput label="resources.pers_exports.year" source="year" choices={años} alwaysOn fullWidth />
    </Filter>
));

const PostEditActions = translate(({ basePath, data, resource, ...props }) => {
    const [open, setOpen] = React.useState(false);
    const { translate } = props;
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = value => {
        setOpen(false);
    };
    return (
        <CardActions>
            <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                <AddIcon /> {translate('dynatest.resources.pers_exports.btnCrearInforme')}
            </Button>
            <SimpleDialog open={open} onClose={handleClose} />
        </CardActions>
    );

});
export const ExportPERSList = translate(({ permissions, ...props }) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = value => {
        setOpen(false);
    };
    const { translate } = props;
    const UrlField = ({ record, source }) =>
        <a href={urlAppjs + "/" + record[source]} target="_blank" style={{ textDecoration: "none" }}>
            <Button variant="outlined" color="primary">{translate('dynatest.resources.pers_exports.btnDescargar')}</Button>
        </a>;

    if (!permissions) return <Loading />;
    return (
        <List title="dynatest.resources.pers_exports.title" {...props} 
            actions={permissions['exportarPers'] == 1 || permissions['exportarPers'] == 2 ? <PostEditActions/> : null} 
            filters={<FiltroSeguridad />} 
            bulkActionButtons={permissions['exportarPers'] == 1 ? <PostBulkActionButtons /> : null}>
            <Datagrid>
                <TextField label="resources.pers_exports.unidadFuncional" source="unidadFuncional" sortable={false} />
                <NumberField label="resources.pers_exports.year" source="year" sortable={false} />
                <TextField label="resources.pers_exports.version" source="version" />
                <UrlField label="resources.pers_exports.document" source="document" />
            </Datagrid>
        </List>
    );
});
