import React, { useState, Fragment } from 'react';
import keyBy from 'lodash/keyBy';
import {
    TextInput, ReferenceInput, SelectInput, FormDataConsumer, NumberInput, AutocompleteInput,
    SimpleFormIterator, ArrayInput, Query, FormTab, Button,  TextField, List, DatagridBody, Toolbar,
    SaveButton,
    DeleteButton
} from 'react-admin';
import { PimsCreate } from '../Create';
import { PInput } from '../../inputs/PInput';
import { PRInput } from '../../inputs/PRInput';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { SimpleForm } from '../SimpleForm';
import {
    required,
    minLength,
    maxLength,
} from 'react-admin';
import {
    validarDosDecimales, validarUnDecimal, validarAB, isFechaInvalida,
    Altitud, latMininima, lngMininima
} from '../../../src/resources/validacionesInputs';
import config from '../../config/config';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import GenerarSegmentos from './campos/generarSegmentos';
import TablaTransformacion from './campos/tablaSegmentos';
import TablaTransformacionSegmentos from './campos/generarTransformacion';
import { translate } from 'react-admin';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import ButtonMaterial from '@material-ui/core/Button';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import { createTheme } from '@material-ui/core/styles';

let urlAppjs = config.UrlServer;

const styles = {
    ocultar: {
        display: "none",
    },
    coord: { marginTop: '50px' },

};

export const TransformacionSegmentoList = translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;

    const [proyectos, setProyecto] = useState(window.localStorage.getItem('id_project2'));
    const [open, setOpen] = React.useState(false);
    const [showDatagrid, setShowDatagrid] = React.useState(true);
    const [validaSegmentos, setValidaSegmentos] = React.useState(true);
    const [validandoSegmentos, setValidandoSegmentos] = React.useState(false);
    const [tramoValidado, setTramoValidado] = React.useState('');
    const [ElementosSegmento, setElementosSegmento] = React.useState('');
    const [ElementosSegmentoSelected, setElementosSegmentoSelected] = React.useState('');

    const handleClickOpenModal = () => {
        setOpen(true);
    };

    const handleClose = value => {
        setOpen(false);
    };

    function SimpleDialog(props) {
        const { onClose, selectedValue, open } = props;
        const [valueModal, setValueModal] = React.useState('');
        const { translate } = props;
        const handleChange = (event) => {
            setValueModal(event.target.value);
        };

        const handleClose = () => {
            onClose(selectedValue);
        };
    
        const handleListItemClick = value => {
            onClose(value);
        };

    
        const handleListItemClick2 = props => {
            let checkboxes = document.getElementsByName('check');
            setElementosSegmentoSelected("");
            let selectedSegmentosArray = [];
            let selectedSegmentos = 0;
            if (valueModal == "") {
                alert("Debe seleccionar una opción para transformación");
                return;
            }

            for(var i=0, n=checkboxes.length;i<n;i++) {
                if (checkboxes[i].checked == true) {
                    selectedSegmentosArray.push(ElementosSegmento[i]);
                    selectedSegmentos++;
                }
            }

            if (selectedSegmentos == 0) {
                alert("Debe seleccionar los segmentos continuos a transformar");
                return;
            }else{
                setElementosSegmentoSelected(selectedSegmentosArray);
            }
            
            setElementosSegmento("")
            setShowDatagrid(false);
            onClose(selectedValue);
        };
    
        return (
            <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} fullWidth={'fullWidth'} maxWidth={'sm'}>
                <DialogTitle id="simple-dialog-title">{translate('dynatest.resources.transformacion_segmentos.title.transformarSegmentos')}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <RadioGroup aria-label="transformacion" name="transformacion" value={valueModal} onChange={handleChange}>
                                <FormControlLabel value="Modificar segmentos y sus características" control={<Radio />} label= {translate("dynatest.resources.transformacion_segmentos.radioSel.modificarSegmentos")} />
                                <FormControlLabel value="Modificar trazado (Reemplazar segmentos)" control={<Radio />} label= {translate("dynatest.resources.transformacion_segmentos.radioSel.modificarTrazado")} />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonMaterial variant="contained" color="secondary" id="canceledButton" onClick={handleClose} >
                        {translate('dynatest.resources.transformacion_segmentos.button.cancelar')}                      
                    </ButtonMaterial>
                    <ButtonMaterial variant="contained" color="primary" id="successfullButton" onClick={handleListItemClick2}>
                        {translate('dynatest.resources.transformacion_segmentos.button.confirmar')}
                    </ButtonMaterial>
                </DialogActions>
            </Dialog>
        );
    }
    
    SimpleDialog.propTypes = {
        onClose: PropTypes.func.isRequired,
        open: PropTypes.bool.isRequired,
        selectedValue: PropTypes.string.isRequired,
    };

    const [uf, setUf] = useState('none');
    const ufSet = (value) => {
        setUf(value);
        tramoSet('');
    }

    const [tramo, setTramo] = useState('');
    const tramoSet = (value) => {
        setLongTotal(0);
        setTramo(value);
    }

    const [abIn, setAbIn] = useState('');
    const [abFin, setAbFin] = useState('');
    const abValidationInicio =(value) =>{
    
        /*VALORES ABS INICIO*/
        let AbInicioBase = document.getElementById('IdAbInicio');
        if(AbInicioBase)
        {
           
            AbInicioBase = AbInicioBase.value
            let ingresoAbInicio = document.getElementById('ingresoAbInicio').value;
            let separadorInicio = ingresoAbInicio.split("+");
            let separadorInicio1 = separadorInicio[0].split("K");
            let seAbInicioBase = AbInicioBase.split(".");
            let AbFinBase = document.getElementById('IdAbFin').value;
            let seAbFinBase = AbFinBase.split(".");
            /*VALIDACIONES PR INICIO*/
            if(parseInt(separadorInicio1[1] ) < parseInt(AbInicioBase) || parseInt(separadorInicio1[1] ) > parseInt(AbFinBase) )
            {
                setAbIn(0);
                setLongTotal(0);
                return translate('resources.validaciones.rangoEntre') + AbInicioBase +" y " + AbFinBase;
            }
            if(parseInt(separadorInicio1[1] ) === parseInt(AbInicioBase))
            {
                if(parseInt(separadorInicio[1]) < parseInt(seAbInicioBase[1])){
                    setAbIn(0);
                    setLongTotal(0);
                    return translate('resources.validaciones.rangoEntre') + AbInicioBase +" y " + AbFinBase;
                }
            }
            if(parseInt(separadorInicio1[1] ) === parseInt(seAbFinBase[0]))
            {
                
                if(parseInt(separadorInicio[1]) > parseInt(seAbFinBase[1])){
                    setAbIn(0);
                    setLongTotal(0);
                    return translate('resources.validaciones.rangoEntre') + AbInicioBase +" y " + AbFinBase;
                }
            }
        }
        setAbIn(value);
        setShowDatagrid(false);
    }
    
    const [longTotal, setLongTotal] = useState(0);
    const [longInicial, setLongInicial] = useState(0);
    const [longFinal, setLongFinal] = useState(0);
    const [buttonSearch, setButtonSearch] = useState(0);

    const calcTotalAbs = (abFinAct) => {
        /*VALORES AB FIN */
        let AbFinBase = document.getElementById('IdAbFin');
        let ingresoAbFin = document.getElementById('ingresoAbFin');
        if(AbFinBase && ingresoAbFin)
        {
            AbFinBase = AbFinBase.value;
            ingresoAbFin = ingresoAbFin.value;
            let separadorFin = ingresoAbFin.split("+");
            let separadorFin1 = separadorFin[0].split("K");
            let seAbFinBase = AbFinBase.split(".");
            let AbInicioBase = document.getElementById('IdAbInicio').value;
            let seAbInicioBase = AbInicioBase.split(".");
            /*VALIDACIONES AB FIN*/
            if(parseInt(separadorFin1[1] ) > parseInt(AbFinBase) || parseInt(separadorFin1[1] ) < parseInt(AbInicioBase ) )
            {
                setLongTotal(0);
                return translate('resources.validaciones.rangoEntre') + AbInicioBase +" y " + AbFinBase;
            }
            if(parseInt(separadorFin1[1] ) === parseInt(AbFinBase))
            {
                if(parseInt(separadorFin[1]) > parseInt(seAbFinBase[1])){
                    setLongTotal(0);
                    return translate('resources.validaciones.rangoEntre') + AbInicioBase +" y " + AbFinBase;
                }
            }
            if(parseInt(separadorFin1[1] ) === parseInt(seAbInicioBase[0]))
            {
                if(parseInt(separadorFin[1]) < parseInt(seAbInicioBase[1])){
                    setLongTotal(0);
                    return translate('resources.validaciones.rangoEntre') + AbInicioBase +" y " + AbFinBase;
                }
            }
        }

        if (abIn && abFinAct) {
            let valAbIn = abIn.split('K');
            let AbInFilter = parseFloat(valAbIn[1].replace("+", "."));
            valAbIn = valAbIn[1].split('+');
            valAbIn = valAbIn[0] + valAbIn[1];

            let valAbFin = abFinAct.split('K');
            let AbFinFilter = parseFloat(valAbFin[1].replace("+", "."));
            valAbFin = valAbFin[1].split('+');
            valAbFin = valAbFin[0] + valAbFin[1];
            setLongInicial(parseInt(valAbIn))
            setLongFinal(parseInt(valAbFin))

            if (parseInt(valAbIn) >= parseInt(valAbFin)) {
                if(document.getElementById('longTotal') !== null){
                    document.getElementById('longTotal').value = '';
                }
                setLongTotal(0);
                return translate('resources.validaciones.abscisaFinalDebeSerMayor')
            } else {
                if(document.getElementById('longTotal') !== null){
                    document.getElementById('longTotal').value = parseFloat(valAbFin) - parseFloat(valAbIn);
                }
                setLongTotal(parseFloat(valAbFin) - parseFloat(valAbIn));
                setAbFin(abFinAct);
                setButtonSearch(1);
                setShowDatagrid(false);
                // if (showDatagrid == true) {
                //     let tramoId = tramo.toString().split('/');
                //     var urlPart = "/segmentos?pagination=off&order[codigo]=ASC&order[calzadaNombre]=ASC&tramo=" + tramoId[2] + "&abInicio[gte]=" + AbInFilter + "&abFin[lte]=" + AbFinFilter;
                //     fetch(urlAppjs + urlPart,
                //     {
                //         headers: {
                            
                //             'authorization': 'Bearer ' + localStorage.getItem('token')
                //         }
                //     })
                //     .then((response) => {
                //         return response.json()
                //     })
                //     .then((Elementos) => {
                //         // setValidandoSegmentos(false)
                //         if(Elementos["hydra:member"].length > 0){
                //             // setValidaSegmentos(true)
                //             setElementosSegmento(Elementos["hydra:member"]);
                //             setShowDatagrid(true);
                //         }else{
                //             setElementosSegmento("");
                //         }
                //     })
                // }
                
            }
        }
    }

    const validateSegmento = (value) => {
        if(value){
            let tramoId = value.toString().split('/');
            if(tramoId[2] != tramoValidado){
                setTramoValidado(tramoId[2])
                setValidandoSegmentos(false)
                setValidaSegmentos(true)
            }
        }
    }

    const filtradoInicial = () => {
        let valAbIn = abIn.split('K');
        let AbInFilter = parseFloat(valAbIn[1].replace("+", "."));
        let valAbFin = abFin.split('K');
        let AbFinFilter = parseFloat(valAbFin[1].replace("+", "."));
        let tramoId = tramo.toString().split('/');
        var urlPart = "/segmentos?pagination=off&order[codigo]=ASC&order[calzadaNombre]=ASC&tramo=" + tramoId[2] + "&abInicio[gte]=" + AbInFilter + "&abFin[lte]=" + AbFinFilter;
        fetch(urlAppjs + urlPart,
        {
            headers: {
                
                'authorization': 'Bearer ' + localStorage.getItem('token')
            }
        })
        .then((response) => {
            return response.json()
        })
        .then((Elementos) => {
            // setValidandoSegmentos(false)
            if(Elementos["hydra:member"].length > 0){
                // setValidaSegmentos(true)
                setElementosSegmento(Elementos["hydra:member"]);
                setShowDatagrid(true);
            }else{
                setElementosSegmento("");
            }
        })
    }

    return (
        <PimsCreate {...props} actions={false}>
            <SimpleForm toolbar="" >
                <Grid container spacing={24}>
                    <SimpleDialog open={open} onClose={handleClose} translate = {translate} />
                    <Grid item xs={4}>
                        <ReferenceInput
                            id="unidadFuncional"
                            label="resources.transformacion_segmentos.fields.unidadFuncional"
                            source="unidadFuncional"
                            reference="unidad_funcionals"
                            filterToQuery={searchText => ({ codigo: searchText })}
                            sort={{ field: 'codigo', order: 'asc' }}
                            validate={[ufSet, required()]}
                            alwaysOn
                            fullWidth
                        >
                            <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
                        </ReferenceInput>
                    </Grid>
                    {uf &&
                        <Grid item xs={4}>
                            <ReferenceInput
                                label="resources.transformacion_segmentos.fields.tramo"
                                source="tramo"
                                alwaysOn
                                reference="tramos"
                                filter={{ unidadFuncional: uf }}
                                validate={[tramoSet, validateSegmento, required()]}
                                filterToQuery={searchText => ({ codigo: searchText })}
                                sort={{
                                    field: 'codigo',
                                    order: 'ASC',

                                }}>
                                    
                                <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
                            </ReferenceInput>
                        </Grid>
                    }
                </Grid>
                {validandoSegmentos && validaSegmentos &&
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <Typography variant="b" component="b">{translate('dynatest.resources.transformacion_segmentos.title.validSeg')}</Typography>
                        </Grid>
                    </Grid>
                }
                {validaSegmentos == false && 
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <Typography style={{ color: "red" }} variant="b" component="b">{translate('dynatest.resources.transformacion_segmentos.title.uniFuncTram')}</Typography>
                        </Grid>
                    </Grid>
                }
                {uf && tramo && validandoSegmentos == false && validaSegmentos &&
                    <Grid container spacing={24}>
                        <Grid item xs={4}>
                            <PRInput label="resources.transformacion_segmentos.fields.absSegIni" id="ingresoAbInicio" source="absSegIni" fullWidth validate={[abValidationInicio, required()]} />
                        </Grid>
                        <Grid item xs={4}>
                            <PRInput label='resources.transformacion_segmentos.fields.absSegFin' id="ingresoAbFin" source='absSegFin' fullWidth validate={[calcTotalAbs, required()]} />
                        </Grid>
                        <Grid item xs={4}>
                            <NumberInput label='resources.transformacion_segmentos.fields.longTotal' source='longTotal' id="longTotal" fullWidth options={{ disabled: true }} />
                        </Grid>
                    </Grid>
                }
                {uf && tramo && validandoSegmentos == false && validaSegmentos &&
                    <Query type="GET_ONE" resource="rutas" payload={{ id: tramo}} >
                        {({ data, loading, error }) => {                                                
                            if( data){
                                return (
                                    <Grid item xs={3}>
                                        <TextInput  id="IdprInicio"  style={{display : 'none'}} label="pr Inicio" defaultValue={data.PInicio} source="pInicios" fullWidth />
                                        <TextInput id="IdprFin"   style={{display : 'none'}}  label="PrFin" defaultValue={data.PFin} source="pFins" fullWidth />
                                        <TextInput  id="IdAbInicio"  style={{display : 'none'}} label="pr Inicio" defaultValue={data.abInicios} source="pAbI" fullWidth />
                                        <TextInput id="IdAbFin"   style={{display : 'none'}}  label="PrFin" defaultValue={data.abFins} source="pAbF" fullWidth />
                                    </Grid>
                                )
                            }
                            return <div></div>;                                                
                        }}
                    </Query>                            
                }
                {buttonSearch == 1 &&
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <Button variant="outlined" color="primary" label="dynatest.resources.transformacion_segmentos.button.filtrar" onClick={filtradoInicial}>
                            </Button>
                        </Grid>
                    </Grid>
                }

                {ElementosSegmento && ElementosSegmento != "" && showDatagrid == true &&
                     <Grid container spacing={24}>
                         <br/><br/>
                        <Grid item xs={12}>
                            <Button variant="outlined" color="primary" label="dynatest.resources.transformacion_segmentos.button.transformar" onClick={handleClickOpenModal}></Button>                        
                        </Grid>
                    </Grid>
                }

                {ElementosSegmento && ElementosSegmento != "" && showDatagrid == true &&
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <TablaTransformacion data={ElementosSegmento} />
                        </Grid>
                    </Grid>
                }

                {/* {ElementosSegmentoSelected && ElementosSegmentoSelected != "" && showDatagrid == false &&
                     <Grid container spacing={24}>
                         <br/><br/>
                        <Grid item xs={12}>
                            <Button variant="outlined" color="primary" label="Generar" ></Button>                        
                        </Grid>
                    </Grid>
                } */}

                {ElementosSegmentoSelected && ElementosSegmentoSelected != "" && showDatagrid == false &&
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <TablaTransformacionSegmentos dataValidate={ElementosSegmentoSelected} />
                        </Grid>
                    </Grid>
                }

                {/* {longTotal > 0 && validandoSegmentos == false && validaSegmentos &&
                    <GenerarSegmentos uf={uf}  />
                } */}
            </SimpleForm>
        </PimsCreate>
    )
}

));
const WithProps = ({ children, ...props }) => children(props);