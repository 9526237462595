import React, { useState, useEffect, useCallback } from 'react';
import {
    List, SimpleForm,
} from 'react-admin';
import Button from '@material-ui/core/Button';
import { Loading } from 'react-admin';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import SvgIcon from '@material-ui/core/SvgIcon';
import EditIcon from '@material-ui/icons/Edit';
import LocaleSwitcher from './LocaleSwitcher';
import { Fragment } from 'react';
import config from '../../config/config';
import { ImagenesLogos } from './ImagenesLogos';
import Dropzone from "react-dropzone";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TranslateIcon from '@material-ui/icons/Translate';
import { translate } from 'react-admin';
let urlAppjs = config.UrlServer;

function PaidIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm.88 15.76V19h-1.75v-1.29c-.74-.18-2.39-.77-3.02-2.96l1.65-.67c.06.22.58 2.09 2.4 2.09.93 0 1.98-.48 1.98-1.61 0-.96-.7-1.46-2.28-2.03-1.1-.39-3.35-1.03-3.35-3.31 0-.1.01-2.4 2.62-2.96V5h1.75v1.24c1.84.32 2.51 1.79 2.66 2.23l-1.58.67c-.11-.35-.59-1.34-1.9-1.34-.7 0-1.81.37-1.81 1.39 0 .95.86 1.31 2.64 1.9 2.4.83 3.01 2.05 3.01 3.45 0 2.63-2.5 3.13-3.02 3.22z" />
        </SvgIcon>
    );
}

function InfoIcon(props) {
    return (
        <SvgIcon {...props}>
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" />
        </SvgIcon>
    );
}

export const ConfiguracionGeneralList = translate(({ permissions, ...props }) => {
    const { translate } = props;
    const [open, setOpen] = React.useState(false);
    const [openLogo, setOpenLogo] = React.useState(false);
    const [editar, setEditar] = React.useState(true);
    const [editarLogo, setEditarLogo] = React.useState(true);
    const heightField = '22rem';
    const [idiomaSlc, setIdiomaSeleccionado] = React.useState("");
    const [files, setFiles] = useState([]);
    const [titulo, setTitulo] = useState('');
    const [images, setImages] = useState([]);
    const [idiomaActual, setIdiomaActual] = React.useState("");
    const [idiomaProject, setIdiomaProject] = React.useState("");

    useEffect(() => {
        const idBtn = document.getElementById("btnConfirmar");
        if(idiomaProject == ""){
            let idiomaProject2 = props.locale;       
            fetch(urlAppjs + "/idiomas?region=" + idiomaProject2, {
                method: 'GET',
                headers:{
                    'authorization': 'Bearer '+ localStorage.getItem('token')
                }
            })
            .then(res => res.json())
            .then((success) => {
                if (success['hydra:member'].length > 0) {
                    setIdiomaProject(success['hydra:member'][0].nombreIdioma);
                    document.getElementById("lengua").innerHTML = success['hydra:member'][0].nombreIdioma
                }
            });
        }
        // setIdiomaActual(props.locale)
        if (idBtn) {
            return (setOpen(false), setEditar(true));
        }
    });
    
    const handleClickOpen = (value) => {
        setIdiomaSeleccionado(value);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseLogo = () => {
        setOpenLogo(false);
    };

    const handleDrop = acceptedFiles => {
        setFiles(acceptedFiles);
        if (acceptedFiles) {
            setOpenLogo(true);
        }
    };

    const editarIdiomas = () => {
        if (permissions.configSistema == 1 || permissions.configSistema == 2) {
            setEditar(false);
        }
    }

    const editLogo = () => {
        if (permissions.configSistema == 1 || permissions.configSistema == 2) {
            setEditarLogo(false);
        }
    }

    const modalEditarLogo = () => {
        let proyectSelect = window.localStorage.id_project;
        let nameProyectSlc = window.localStorage.codigoProyecto;
        const calidarRegistro = crearRegistro(proyectSelect);
        if (files && calidarRegistro) {
            var formData = new FormData();
            files.map((file, index) => {
                formData.append(`files[${index}]`, file);
            });
            formData.append('nameProyectSlc', nameProyectSlc);

            fetch(urlAppjs + '/cargaUploadLogo', {
                method: 'POST',
                body: formData,
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
                .then(res => res.json())
                .then((success) => {
                    window.location.reload();
                    console.log("Success")
                });
        }
        setOpenLogo(false);
        setEditarLogo(true);
    }

    const crearRegistro = (value) => {
        let nameProyectSlc = window.localStorage.codigoProyecto;
        let nameLogo = files[0].name;
        let typeLogo = files[0].type;
        const formData = new FormData();
        formData.append('idProyecto', value);
        formData.append('nameLogo', nameLogo);
        formData.append('typeLogo', typeLogo);
        formData.append('nameProyectSlc', nameProyectSlc);
        formData.append('estado', 1);
        let requestOptionsReplace = {
            method: 'POST',
            headers: { 'authorization': 'Bearer ' + localStorage.getItem('token') },
            body: formData
        };
        fetch(urlAppjs + "/saveInfoLogo", requestOptionsReplace)
            .then(response => response.json())
            .then((response) => { });

        return true;
    }

    if (!permissions) return <Loading />;
    return (
        <>
            <List {...props} title="dynatest.resources.config_sistema.title.listTitle" style={{ display: 'none' }}>
            </List>
            <div container>
                {
                    <div xs={12}>
                        <Card variant="outlined">
                            <CardContent>
                                <Grid container className="margin_bottom_5">
                                    <Grid item xs={12} align={'center'}>
                                        <div ><strong> {translate('dynatest.resources.config_sistema.title_cards')} </strong></div>
                                    </Grid>
                                </Grid>
                                <br></br>

                                <SimpleForm toolbar={false} >
                                    <Grid container >
                                        <Grid item xs={4} style={{ padding: '0.7rem' }}>
                                            <Card style={{ height: heightField, marginBottom: '1rem', paddingBottom: '1.5rem' }}>
                                                <CardContent style={{ background: '#EEE' }} align={'center'}>
                                                    <p style={{ display: 'flex', textDecoration: 'none', justifyContent: 'center' }}>
                                                        <strong>{translate('dynatest.resources.config_sistema.titles_cards.idioma')}</strong>
                                                    </p>
                                                </CardContent>
                                                <CardActions>
                                                    <Grid item xs={1} className="negrilla" align={'center'} >
                                                    </Grid>
                                                    <Grid item xs={10} className="negrilla" align={'center'} >
                                                        <Button variant="outlined" color="primary" disabled={editar} onClick={() => handleClickOpen('es')} style={{ border: 0 }} >{translate('dynatest.resources.config_sistema.btnEspanol')}</Button>
                                                        <Button variant="outlined" color="primary" disabled={editar} onClick={() => handleClickOpen('en')} style={{ border: 0 }} >{translate('dynatest.resources.config_sistema.btnIngles')}</Button>
                                                        <Button variant="outlined" color="primary" disabled={editar} onClick={() => handleClickOpen('pt')} style={{ border: 0 }} >{translate('dynatest.resources.config_sistema.btnPortugues')}</Button>
                                                        {/* <LocaleSwitcher /> */}
                                                        <br></br>
                                                        <TranslateIcon style={{ fontSize: '7rem', paddingRight: '0.4rem', color: '#95CCAC' }} />
                                                        <br></br>                                                       
                                                        <span></span>
                                                        <span>{translate('resources.idiomas.idiomaActual')} <span style={{textTransform: 'uppercase'}} id="lengua"></span> </span>
                                                        <br></br>
                                                        <Button
                                                            onClick={editarIdiomas}
                                                            id="btnAprobar"
                                                            variant="outlined"
                                                            style={{
                                                                fontSize: '0.8125rem', border: 0, marginTop: '1rem',
                                                                display: 'inline-flex', alignItems: 'center',
                                                                width: '7rem', height: '2rem'
                                                            }}

                                                            color="primary"
                                                        >
                                                            <EditIcon style={{ width: '1.3rem', paddingRight: '0.3rem' }} />
                                                                {translate('dynatest.resources.config_sistema.btnEditar')}
                                                            </Button>
                                                        
                                                        
                                                        <Dialog
                                                            open={open}
                                                            onClose={handleClose}
                                                            aria-labelledby="alert-dialog-title"
                                                            aria-describedby="alert-dialog-description"
                                                        >
                                                            <DialogTitle id="alert-dialog-title">
                                                                {/* {"Cambiar idioma del sistema"} */}
                                                                {translate('dynatest.resources.config_sistema.mensajesModals.titulo')}
                                                            </DialogTitle>
                                                            <DialogContent>
                                                                <DialogContentText id="alert-dialog-description">
                                                                    {translate('dynatest.resources.config_sistema.mensajesModals.idioma')}
                                                                </DialogContentText>
                                                            </DialogContent>
                                                            <DialogActions>
                                                                <LocaleSwitcher onClick={handleClose} idioma={idiomaSlc} open={open} Titulo={titulo} />
                                                                <Button color="primary" onClick={handleClose} autoFocus>
                                                                    {translate('dynatest.resources.config_sistema.btnCancelar')}
                                                                </Button>
                                                            </DialogActions>
                                                        </Dialog>
                                                    </Grid>
                                                </CardActions>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={4} style={{ padding: '0.7rem' }}>
                                            <Card style={{ height: heightField, marginBottom: '1rem', paddingBottom: '1.5rem' }}>
                                                <CardContent style={{ background: '#EEE' }} align={'center'}>
                                                    <p style={{ display: 'flex', textDecoration: 'none', justifyContent: 'center' }}>
                                                        <strong>{translate('dynatest.resources.config_sistema.titles_cards.moneda')}</strong>
                                                    </p>
                                                </CardContent>
                                                <CardActions>
                                                    <Grid item xs={1} className="negrilla" align={'center'} >
                                                    </Grid>
                                                    <Grid item xs={10} className="negrilla" align={'center'} >
                                                        <div style={{ marginTop: '2rem', marginBottom: '1rem' }}>
                                                            <label style={{ color: 'rgba(0, 0, 0, 0.54)', fontWeight: 600, fontSize: '1rem' }}>
                                                                {translate('dynatest.resources.config_sistema.cards.pesos')}
                                                            </label>
                                                        </div>
                                                        <PaidIcon style={{ fontSize: '7rem', paddingRight: '0.4rem', color: '#95CCAC' }} />
                                                        <br></br>
                                                        <Button
                                                            onClick={() => {
                                                            }}
                                                            id="btnAprobar"
                                                            variant="outlined"
                                                            disabled
                                                            style={{
                                                                fontSize: '0.8125rem', border: 0, marginTop: '1rem',
                                                                display: 'inline-flex', alignItems: 'center',
                                                                width: '7rem', height: '2rem', color: 'rgba(0, 0, 0, 0.54)'
                                                            }}
                                                        >
                                                            <EditIcon style={{ width: '1.3rem', paddingRight: '0.3rem' }} />
                                                            {translate('dynatest.resources.config_sistema.btnEditar')}
                                                        </Button>
                                                    </Grid>
                                                </CardActions>
                                            </Card>
                                        </Grid>
                                        <Grid item xs={4} style={{ padding: '0.7rem' }}>
                                            <Card style={{ height: heightField, paddingBottom: '1.5rem' }}>
                                                <CardContent style={{ background: '#EEE' }} align={'center'}>
                                                    <p style={{ display: 'flex', textDecoration: 'none', justifyContent: 'center' }}>
                                                        <strong>{translate('dynatest.resources.config_sistema.titles_cards.logos')}</strong>
                                                    </p>
                                                </CardContent>
                                                <CardActions>
                                                    <Grid item xs={1} className="negrilla" align={'center'} >
                                                    </Grid>
                                                    <Grid item xs={10} className="negrilla" align={'center'} >
                                                        {/* <div style={{ marginTop: '2rem', marginBottom: '1rem' }}>
                                                        <InfoIcon style={{ fontSize: '1rem', paddingRight: '0.4rem', color: 'rgba(0, 0, 0, 0.54)' }} /><label style={{ color: 'rgba(0, 0, 0, 0.54)', fontWeight: 600, fontSize: '0.8rem' }}>
                                                            {translate('dynatest.resources.config_sistema.cards.logo')}
                                                        </label>
                                                    </div> */}
                                                        <div style={{ marginTop: '2rem', marginBottom: '1rem' }}>

                                                        </div>
                                                        <Dropzone onDrop={handleDrop} disabled={editarLogo} uploadMultiple={false} createImageThumbnails='true'
                                                            thumbnailWidth="120" >
                                                            {({ acceptedFiles, getRootProps, getInputProps }) => (
                                                                <div {...getRootProps({ className: "dropzone" })}>
                                                                    <input {...getInputProps()} />
                                                                    <p>{translate('dynatest.resources.config_sistema.cards.dropzone')}</p>
                                                                </div>
                                                            )}
                                                        </Dropzone>
                                                        <br></br>
                                                        <Button
                                                            onClick={editLogo}
                                                            id="btnAprobar"
                                                            variant="outlined"
                                                            style={{
                                                                fontSize: '0.8125rem', border: 0,
                                                                display: 'inline-flex', alignItems: 'center',
                                                                width: '7rem', height: '2rem'
                                                            }}
                                                            color="primary"
                                                        >
                                                            <EditIcon style={{ width: '1.3rem', paddingRight: '0.3rem' }} />
                                                            {translate('dynatest.resources.config_sistema.btnEditar')}
                                                        </Button>
                                                        <Dialog
                                                            open={openLogo}
                                                            onClose={handleCloseLogo}
                                                            aria-labelledby="alert-dialog-title"
                                                            aria-describedby="alert-dialog-description"
                                                        >
                                                            <DialogTitle id="alert-dialog-title">
                                                                {translate('dynatest.resources.config_sistema.mensajesModals.titleLogo')}
                                                            </DialogTitle>
                                                            <DialogContent>
                                                                <DialogContentText id="alert-dialog-description">
                                                                    {translate('dynatest.resources.config_sistema.mensajesModals.logo')}
                                                                </DialogContentText>
                                                            </DialogContent>
                                                            <DialogActions>
                                                                <Button color="primary" onClick={modalEditarLogo} autoFocus>
                                                                    {translate('dynatest.resources.config_sistema.btnAceptar')}
                                                                </Button>
                                                                <Button color="primary" onClick={handleCloseLogo} autoFocus>
                                                                    {translate('dynatest.resources.config_sistema.btnCancelar')}
                                                                </Button>
                                                            </DialogActions>
                                                        </Dialog>
                                                    </Grid>
                                                </CardActions>
                                            </Card>
                                        </Grid>
                                    </Grid>
                                </SimpleForm>
                            </CardContent>
                        </Card>
                        <br></br>
                        <br></br>
                    </div>
                }
            </div>
        </>
    )
});
