import React from 'react';
import {
    ShowController,  ShowView, TabbedForm, FormTab, Toolbar,
    TextField, ReferenceField, NumberField, ArrayField, SingleFieldList, Datagrid, DateField,
} from 'react-admin';
import { PRField } from './../../../../fields/PRField';
import { PField } from './../../../../fields/PField';
import { PercentageField } from './../../../../fields/PercentageField';
import { withStyles } from '@material-ui/core';
import { GMapInput } from '../../../../inputs/GMapInput';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { styles } from '../../../../EstilosGrid';
import { ButtonCarousel } from './../../../images/ButtonCarousel';
import { translate } from 'react-admin';

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <span></span>

    </Toolbar>
));


export const ElementoRigidoShow = translate(withStyles(styles)(({ classes, record, ...props }) => {
    const { translate } = props;

    return (
        <ShowController title="Información Segmento Rígido" {...props}>
            {controllerProps =>
                <ShowView {...props} {...controllerProps} title="dynatest.resources.rigido_detalles.title.showTitle" actions={<ButtonCarousel />}>
                    <TabbedForm toolbar={<CustomToolbar />}>
                        <FormTab label="dynatest.resources.rigido_detalles.tabs.informacionBasica">
                            <ReferenceField source="unidadFuncional" reference="unidad_funcionals" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <TextField label="" source="fullCodigos" />
                            </ReferenceField>
                            <ReferenceField source="tramo" reference="tramos" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <TextField label="" source="fullCodigos" />
                            </ReferenceField>
                            <TextField label="resources.rigido_detalles.fields.segmento" source="segmento_.codigo" fullWidth formClassName={classes.grid_cont3} />
                            <ReferenceField label="resources.rigido_detalles.fields.absInicio" source="segmento" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <PRField label="" source="abInicio" />
                            </ReferenceField>
                            <ReferenceField label="resources.rigido_detalles.fields.absFin" source="segmento" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <PRField label="" source="abFin" />
                            </ReferenceField><NumberField label="resources.rigido_detalles.fields.longitudShow" source="segmento_.fullResta" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3} />
                            <ReferenceField label="resources.rigido_detalles.fields.prInicio" source="segmento" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont4} >
                                <PField label="" source="pInicio" />
                            </ReferenceField>
                            <ReferenceField label="resources.rigido_detalles.fields.prFin" source="segmento" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <PField label="" source="pFin" />
                            </ReferenceField><span></span>

                            <b>{translate('resources.rigido_detalles.fields.localizacion')}</b>
                            <Divider fullWidth style={{ marginTop: '10px' }} />

                            <TextField label="resources.rigido_detalles.fields.departamento" source="segmento_.municipio_.departamento_.nombre"  fullWidth formClassName={classes.grid_cont4} />  
                            <TextField label="resources.rigido_detalles.fields.municipio" source="segmento_.municipio_.nombre"  fullWidth formClassName={classes.grid_cont4}/> 
                            
                            <Typography variant="b" component="b">{translate('resources.rigido_detalles.fields.coordenadasIniciales')}</Typography>
                            <Divider fullWidth style={{ marginTop: '10px' }} />
                            <NumberField label="resources.rigido_detalles.fields.latitud" source="segmento_.latitud" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4} />
                            <NumberField label="resources.rigido_detalles.fields.longitud" source="segmento_.longitud" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4} />
                            <NumberField label="resources.rigido_detalles.fields.altura" source="segmento_.alturaIni" style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} fullWidth formClassName={classes.grid_cont3} />
                            <Typography variant="b" component="b">{translate('resources.rigido_detalles.fields.coordenadasFinales')}</Typography>
                            <Divider fullWidth style={{ marginTop: '10px' }} />
                            <NumberField label="resources.rigido_detalles.fields.latitud" source="segmento_.latitud2" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4} />
                            <NumberField label="resources.rigido_detalles.fields.longitud" source="segmento_.longitud2" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4} />
                            <NumberField label="resources.rigido_detalles.fields.altura" source="segmento_.alturaFin" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3} />

                            {/* <b>Características</b>

                            <Divider fullWidth />
                            <ReferenceField label="Tipo Calzada" source="segmento_.tipoCalzada" reference="tipo_calzadas" linkType={false}  formClassName={classes.grid_cont3}>
                                <TextField source="nombre" />
                            </ReferenceField>

                            <ReferenceField label="Tipo Segmento" source="segmento_.tipoSegmento" reference="tipo_segmentos" linkType={false}  formClassName={classes.grid_cont3}>
                                <TextField source="nombre" />
                            </ReferenceField>

                            <ReferenceField label="Tipo de superficie" source="segmento_.tipoSuperficie" reference="tipo_elementos" linkType={false}  formClassName={classes.grid_cont3}>
                                <TextField source="nombre" />
                            </ReferenceField>

                            <BooleanField  source="segmento_.estado" fullWidth formClassName={classes.grid_cont4}/> 
                            <span></span> */}

                            <GMapInput
                                defaultZoom={8}
                                defaultCenter={{ lat: 4.7567039, lng: -74.0311056 }}
                                source="segmento_.ubicacionMapa"
                                multipleMarkers="true"
                                googleKey="AIzaSyCivTXzJBYRBK8nosBYRFXnv-hGXIbhnYY"
                                icon={"/imagenes/senalesColombia/Elementos/segmento_inventaro/Rigido.png"}
                            />
                        </FormTab>
                        <FormTab label="dynatest.resources.rigido_detalles.tabs.detalles">
                            <div></div><div></div><div></div>
                            <ArrayField source="detallesSegmentos" label="resources.rigido_detalles.fields.sentido" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="sentido" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="detallesSegmentos" label="resources.rigido_detalles.fields.estadoDetalles" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="estadoDetalles" />
                                </SingleFieldList>
                            </ArrayField>
                            {controllerProps.record && controllerProps.record.detallesSegmentos[0].estadoDetalles === translate('dynatest.resources.rigido_detalles.select.estadoDetalles.Inactivo') &&
                            <ArrayField source="detallesSegmentos" label="resources.rigido_detalles.fields.estadoRazon" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="estadoRazon" />
                                </SingleFieldList>
                            </ArrayField>
                            }
                            <br/>
                            <ArrayField source="detallesSegmentos" label="resources.rigido_detalles.fields.dateInicioOperacion" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <DateField source="dateInicioOperacion" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="detallesSegmentos" label="resources.rigido_detalles.fields.proveedor" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="proveedor_.razonSocial" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="detallesSegmentos" label="resources.rigido_detalles.fields.garantiaMeses" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="garantiaMeses" />
                                </SingleFieldList>
                            </ArrayField>
                            <div ><strong>{translate('resources.rigido_detalles.fields.trafico')}</strong></div><div></div><div></div>
                            <Divider fullWidth /><Divider fullWidth /><Divider fullWidth />
                            <ArrayField source="detallesSegmentos" label="resources.rigido_detalles.fields.esalTime" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="esalTime" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="detallesSegmentos" label="resources.rigido_detalles.fields.nivelTraficoDetalles" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="nivelTrafico" />
                                </SingleFieldList>
                            </ArrayField>
                            <br/>
                            <ArrayField source="detallesSegmentos" label="resources.rigido_detalles.fields.esalTime10" linkType={false} fullWidth  style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="esalTime10" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="detallesSegmentos" label="resources.rigido_detalles.fields.esalTime20" linkType={false} fullWidth  style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="esalTime20" />
                                </SingleFieldList>
                            </ArrayField>
                            <br/>
                            <Divider fullWidth /><Divider fullWidth /><Divider fullWidth />
                            <ArrayField source="detallesSegmentos" label="resources.rigido_detalles.fields.detallesObservaciones">
                                <SingleFieldList>
                                    <TextField source="detallesObservaciones" />
                                </SingleFieldList>
                            </ArrayField>
                        </FormTab>
                        <FormTab label="dynatest.resources.rigido_detalles.tabs.geometria">
                            <div></div><div></div><div></div>
                            <div><strong>{translate('resources.rigido_detalles.fields.detallesCalzada')}</strong></div><div></div><div></div>
                            <Divider fullWidth /><Divider fullWidth /><Divider fullWidth />
                            <ArrayField source="geometriaSegmentos" label="resources.rigido_detalles.fields.geometriaLargo" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaLargo" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmentos" label="resources.rigido_detalles.fields.geometriaAncho" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaAncho" />
                                </SingleFieldList>
                            </ArrayField>
                            <br/>
                            <div><strong>{translate('resources.rigido_detalles.fields.detallesCarriles')}</strong></div><div></div><div></div>
                            <Divider fullWidth /><Divider fullWidth /><Divider fullWidth />
                            <ArrayField source="geometriaSegmentos" label="resources.rigido_detalles.fields.geometriaNumCarriles" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaNumCarriles" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmentos" label="resources.rigido_detalles.fields.geometriaAnchoCarril" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaAnchoCarril" />
                                </SingleFieldList>
                            </ArrayField>
                            <br/>
                            <ArrayField source="geometriaSegmentos" label="resources.rigido_detalles.fields.geometriaTipoBerma" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="geometriaTipoBerma" />
                                </SingleFieldList>
                            </ArrayField>
                            {controllerProps.record && controllerProps.record.geometriaSegmentos[0].geometriaTipoBerma !== "Inexistente" &&
                            <ArrayField source="geometriaSegmentos" label="resources.rigido_detalles.fields.geometriaAnchoBerma" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaAnchoBerma" />
                                </SingleFieldList>
                            </ArrayField>
                            }
                            {controllerProps.record && controllerProps.record.geometriaSegmentos[0].geometriaTipoBerma !== "Inexistente" &&
                                <ArrayField source="geometriaSegmentos" label="resources.rigido_detalles.fields.geometriaCondicionBerma" fullWidth linkType={false} formClassName={classes.grid_cont3}>
                                    <SingleFieldList>
                                        <TextField source="geometriaCondicionBerma" />
                                    </SingleFieldList>
                                </ArrayField>
                            }
                            <br/>
                            <ArrayField source="geometriaSegmentos" label="resources.rigido_detalles.fields.aux1" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="sumaAreaCarriles" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmentos" label="resources.rigido_detalles.fields.areaCarrilesVerdadera" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="areaCarrilesVerdadera" />
                                </SingleFieldList>
                            </ArrayField>
                            <br/>
                            <div><strong>{translate('resources.rigido_detalles.fields.propiedadesGeometricas')}</strong></div><div></div><div></div>
                            <Divider fullWidth /><Divider fullWidth /><Divider fullWidth />
                            <ArrayField source="geometriaSegmentos" label="resources.rigido_detalles.fields.geometriaLongitudinal" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <PercentageField source="geometriaLongitudinal" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmentos" label="resources.rigido_detalles.fields.geometriaLongitudinalMa" fullWidth linkType={false} style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <PercentageField source="geometriaLongitudinalMa" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmentos" label="resources.rigido_detalles.fields.geometriaLongitudinalMi" fullWidth linkType={false} style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <PercentageField source="geometriaLongitudinalMi" />
                                </SingleFieldList>
                            </ArrayField>
                            <br/>
                            {/* -----------RADIO--------- */}
                            <ArrayField source="geometriaSegmentos" label="resources.rigido_detalles.fields.geometriaTransversal" fullWidth linkType={false} style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <PercentageField source="geometriaTransversal" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmentos" label="resources.rigido_detalles.fields.geometriaRadioMi" fullWidth linkType={false} style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaRadioMi" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmentos" label="resources.rigido_detalles.fields.geometriaRadioMa" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaRadioMa" />
                                </SingleFieldList>
                            </ArrayField>
                            <br/>
                            {/* -------------------- */}
                            <ArrayField source="geometriaSegmentos" label="resources.rigido_detalles.fields.geometriaRadioCurvatura" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaRadioCurvatura" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmentos" label="resources.rigido_detalles.fields.geometriaVelDiseno" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaVelDiseno" />
                                </SingleFieldList>
                            </ArrayField>
                            <br/>
                            <ArrayField source="geometriaSegmentos" label="resources.rigido_detalles.fields.geometriaSeparador" linkType={false}  formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="geometriaSeparador" />
                                </SingleFieldList>
                            </ArrayField>
                {controllerProps.record && controllerProps.record.geometriaSegmentos[0].geometriaSeparador === translate('dynatest.resources.rigido_detalles.select.geometriaSeparador.Si')  &&
                            <ArrayField source="geometriaSegmentos" label="resources.rigido_detalles.fields.geometriaSeparadorAncho" fullWidth linkType={false} style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaSeparadorAncho" />
                                </SingleFieldList>
                            </ArrayField>
                }
                <br />
                            <ArrayField source="geometriaSegmentos" label="resources.rigido_detalles.fields.geometriaCondicionesGeometrica" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="geometriaCondicionesGeometrica" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmentos" label="resources.rigido_detalles.fields.geometriaAlineamiento" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="geometriaAlineamiento" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmentos" label="resources.rigido_detalles.fields.geometriaTipoTerreno" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="geometriaTipoTerreno" />
                                </SingleFieldList>
                            </ArrayField>
                            <div><strong>{translate('resources.rigido_detalles.fields.dimensionesLosaTipica')}</strong></div><div></div><div></div>
                            <Divider fullWidth /><Divider fullWidth /><Divider fullWidth />
                            <ArrayField source="geometriaSegmentos" label="resources.rigido_detalles.fields.geometriaDltLargo" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaDltLargo" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmentos" label="resources.rigido_detalles.fields.geometriaDltAncho" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaDltAncho" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmentos" label="resources.rigido_detalles.fields.geometria_dlt_area" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="areaGeometriaDLT" />
                                </SingleFieldList>
                            </ArrayField>
                            <br/>
                            <ArrayField source="geometriaSegmentos" label="resources.rigido_detalles.fields.geometriaDltNumlosas" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaDltNumlosas" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmentos" label="resources.rigido_detalles.fields.geometriaDltLongjuntas" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaDltLongjuntas" />
                                </SingleFieldList>
                            </ArrayField>
                            <div></div>
                            <hr fullWidth/>
                            <ArrayField source="geometriaSegmentos" label="resources.rigido_detalles.fields.detallesGeometria" linkType={false} fullWidth formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="detallesGeometria" />
                                </SingleFieldList>
                            </ArrayField>
                        </FormTab>
                        <FormTab label="dynatest.resources.rigido_detalles.tabs.estructura">
                            <div></div><div></div><div></div>
                            <div><strong>{translate('resources.rigido_detalles.fields.informacionEstructura')}</strong></div><div></div><div></div>
                            <Divider fullWidth /><Divider fullWidth /><Divider fullWidth />
                            <ArrayField source="tablaSegmentos" style={{ width: '100%' }} label=" " className="inventariosSegmentos">
                                <Datagrid>
                                    <NumberField source="estructuraEspesor" label="resources.rigido_detalles.fields.estructuraEspesor" />
                                    <NumberField source="estructuraModulo" label="resources.rigido_detalles.fields.estructuraModulo" />
                                    <NumberField source="estructuraDs" label="resources.rigido_detalles.fields.estructuraDs" />
                                    <TextField label="resources.rigido_detalles.fields.material" source="material_.material" />
                                    <TextField label="resources.rigido_detalles.fields.materialEsp" source="material_.especificacion" />
                                    <TextField label="resources.rigido_detalles.fields.granulometria" source="granulometria_.granulometria" />
                                </Datagrid>
                            </ArrayField>
                            <div></div><div></div>
                            <NumberField label="resources.rigido_detalles.fields.espesorTotal" source="espensorTotal" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/>
                            <br/>
                            <ArrayField source="estructuraSegmentos" label="resources.rigido_detalles.fields.iggEstructura" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="igg" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="estructuraSegmentos" label="resources.rigido_detalles.fields.conceito" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="conceito" />
                                </SingleFieldList>
                            </ArrayField>
                            <br/>
                            <div></div><div></div>
                            <div className={classes.subtittle}><strong>{translate('resources.rigido_detalles.fields.capacidadEstructural')}</strong></div><div></div><div></div>
                            <Divider fullWidth /><Divider fullWidth /><Divider fullWidth />
                            <ArrayField source="estructuraSegmentos" label="resources.rigido_detalles.fields.estructuraNumDiseno" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="estructuraNumDiseno" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="estructuraSegmentos" label="resources.rigido_detalles.fields.estructuraFecha" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <DateField source="estructuraFecha" />
                                </SingleFieldList>
                            </ArrayField>
                            <br/>
                            <ArrayField source="estructuraSegmentos" label="resources.rigido_detalles.fields.estructuraNumero" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="estructuraNumero" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="estructuraSegmentos" label="resources.rigido_detalles.fields.estructuraNumeroFecha" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <DateField source="estructuraNumeroFecha" />
                                </SingleFieldList>
                            </ArrayField>
                            <br/>
                            <ArrayField source="estructuraSegmentos" label="resources.rigido_detalles.fields.estructuraCbr" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <PercentageField source="estructuraCbr" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="estructuraSegmentos" label="resources.rigido_detalles.fields.estructuraCbrFecha" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <DateField source="estructuraCbrFecha" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="estructuraSegmentos" label="resources.rigido_detalles.fields.estructuraVacios" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="estructuraVacios" />
                                </SingleFieldList>
                            </ArrayField>
                            <br/>
                            <ArrayField source="estructuraSegmentos" label="resources.rigido_detalles.fields.kjoint" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="kjoint" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="estructuraSegmentos" label="resources.rigido_detalles.fields.jsdf" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="jsdf" />
                                </SingleFieldList>
                            </ArrayField>
                            <br/>
                            <ArrayField source="estructuraSegmentos" label="resources.rigido_detalles.fields.kcenter" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="kcenter" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="estructuraSegmentos" label="resources.rigido_detalles.fields.csdf" linkType={false} fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="csdf" />
                                </SingleFieldList>
                            </ArrayField>
                            <div><strong>{translate('resources.rigido_detalles.fields.fechasImportantes')}</strong></div><div></div><div></div>
                            <Divider fullWidth /><Divider fullWidth /><Divider fullWidth />
                            <ArrayField source="estructuraSegmentos" label="resources.rigido_detalles.fields.estructuraFechaConst" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <DateField source="estructuraFechaConst" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="estructuraSegmentos" label="resources.rigido_detalles.fields.estructuraFechaRehab" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <DateField source="estructuraFechaRehab" />
                                </SingleFieldList>
                            </ArrayField>
                            <div></div>
                            <Divider fullWidth /><Divider fullWidth /><Divider fullWidth />
                            <ArrayField source="estructuraSegmentos" label="resources.rigido_detalles.fields.detallesEstructura" linkType={false} >
                                <SingleFieldList>
                                    <TextField source="detallesEstructura" />
                                </SingleFieldList>
                            </ArrayField>
                        </FormTab>
                        <FormTab label="dynatest.resources.rigido_detalles.tabs.auditoria">
                            <DateField source="createdAt" label="resources.rigido_detalles.fields.createdAt" fullWidth formClassName={classes.grid_cont4} showTime/>
                            <TextField source="createdBy" label="resources.rigido_detalles.fields.createdBy" fullWidth formClassName={classes.grid_cont4} />
                            <TextField source="createdIn" label="resources.rigido_detalles.fields.createdEn" fullWidth formClassName={classes.grid_cont3} />
                            <span></span>
                            <DateField source="updatedAt" label="resources.rigido_detalles.fields.updatedAt" fullWidth formClassName={classes.grid_cont4} showTime/>
                            <TextField source="updatedBy" label="resources.rigido_detalles.fields.updatedBy" fullWidth formClassName={classes.grid_cont4} />
                            <TextField source="updatedIn" label="resources.rigido_detalles.fields.updatedEn" fullWidth formClassName={classes.grid_cont3} />
                            <span></span>
                        </FormTab>
                    </TabbedForm>
                </ShowView>
            }
        </ShowController>
    )
}));