import React from 'react';
import eFormatter from './../format/EFormat';

import { TextInput } from 'react-admin';


export const EInput = (props) => (
    <TextInput 
        {...props} 
        InputProps={{
            inputComponent: eFormatter,
        }}
    />
);

