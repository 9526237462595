import React, { useState } from 'react';
import {
    FormTab, BooleanInput, TextInput, NumberInput, ReferenceInput, SelectInput, AutocompleteInput, DateInput, ImageInput, ImageField,
    required, minValue, ArrayInput, SimpleFormIterator, Edit, maxLength, minLength, FormDataConsumer
} from 'react-admin';
import { PRInput } from '../../../inputs/PRInput';
import { PInput } from '../../../inputs/PInput';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core';
import { styles } from './../../../EstilosGrid';
import { PimsElementoActions } from './../PimsElementoActions';
import {
    latMininima, lngMininima, abValidationInicio, abValidationFin, validarDosDecimales,
    Entero, isFechaInvalida, validarLogicaAbsFinCreate, prValidationInicio, prValidationFin, validacionmaxfechaact
} from '../../validacionesInputs';
import { POInput } from '../../../inputs/POInput';
import TabbedForm from '../../TabbedForm'
import CoordenadasProyecto from '../../CoordenadasInventario';
import AbsSegmento from '../../AbsSegmento';
import { ButtonCarousel } from './../../images/ButtonCarouselPeru';
import { ArrayInputImagesEdit } from './../../images/ArrayInputImagesEdit';
import { translate } from 'react-admin';


export const ElementoDrenajeEdit = translate(withStyles(styles)(({ classes, ...props }) => {
    console.log(props, "propiedades")
    const { translate } = props;
    //MOSTRAR/OCULTAR CAMPO RAZON
    const [detallesEstado, setDetallesEstado] = useState('');
    const validarRazon = (value) => { 
        console.log(value)   
        setDetallesEstado(value)   
    }

    const [unidads, setUnidad] = useState('');
    const ValidarUnidad = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setUnidad(value)
    }
    const Unidades = { unidadFuncional: unidads };
    const [tramos, setTramo] = useState('');
    const ValidarTramo = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setTramo(value)

    }
    const Tramoss = { tramo: tramos };

    const [elemento, setElemento] = useState('');
    const ValidarElemento = (value) => {
        setElemento(value)
    }
    const validarPorcentaje =(value) =>{
        value = value.split('%');
        if (!/^[+-]?([0-9]+)*$/.test(value[0]))
        {
            return translate('resources.validaciones.entero');
        }
    }
    const [segmentos, setSegmento] = useState('');
    const [estados, setEstado] = useState('');
    const ValidarEstado = (value) => {
        setEstado(value)
    }



    /* VALIDAR LOS RANGOS QUE PUEDEN TENER LAS LATITUDES Y LONGITUDES */

    const ValidarSegmento = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setSegmento(value)

    }

    /* FIN DE VALIDACIÓN DE LOS RANGOS QUE PUEDEN TENER LAS LATITUDES Y LONGITUDES */

    /*VALORES POR DEFECTO DEL ARRAY DE COORDENADAS*/
    const [latitudMinimaArray, setLatitudArray] = useState('');
    const LatitudMinimaArray = (value) => {
        setLatitudArray(value)
    }
    const [longitudMinimaArray, setLongitudArray] = useState('');
    const LongitudMinimaArray = (value) => {
        setLongitudArray(value)
    }
    const [longitudMaximaArray, setLongitudMaxdArray] = useState('');
    const LongitudMaximaArray = (value) => {
        setLongitudMaxdArray(value)
    }
    const [latitudMaximaArray, setLatitudMaxdArray] = useState('');
    const LatitudMaximaArray = (value) => {
        setLatitudMaxdArray(value)
    }
    /*FIN DE LOS VALORES POR DEFECTO DEL ARRAY DE COORDENADAS*/

    /*VALIDAR IMÁGENES ACTUALES */
    const [imagenesOpen,setImagenesOpen] = useState('');
    const ImagenesEdit = (value) =>{
        setImagenesOpen(value);
        
    }

    
    const [seccion, setSeccion] = useState('');
    const ValidateSeccion = (value) =>{
        setSeccion(value)
    }
    
    /*OPCIONES PARA TIPO DE IMÁGENES*/
    const [opcionTipoImagen,setOpcionTipoImagen] = useState([{id: 'Cargando...' ,name :'Cargando...'}]);
    const [defaultTipoImagen,setDefaultTipoImagen] = useState([{id: 'Cargando...' ,name :'Cargando...'}]);
    const OpcionTipoImagen = (value) =>{
        if(value !== '/tipo_elementos/DESC'){
            setDefaultTipoImagen([
                {
                    tipoImagen : '/tipo_images/1',
                },
                {
                    tipoImagen : '/tipo_images/7',
                },
                {
                    tipoImagen : '/tipo_images/8',
                },
                {
                    tipoImagen : '/tipo_images/9',
                },
                {
                    tipoImagen : '/tipo_images/5',
                },
                {
                    tipoImagen : '/tipo_images/6',
                }
            ])
           
        }else{
            setDefaultTipoImagen([
                {
                    tipoImagen : '/tipo_images/1',
                },
                {
                    tipoImagen : '/tipo_images/8',
                },
                {
                    tipoImagen : '/tipo_images/9',
                },
                {
                    tipoImagen : '/tipo_images/5',
                },
                {
                    tipoImagen : '/tipo_images/6',
                }
            ])
        } 
        setOpcionTipoImagen([
            {id:'/tipo_images/1'  , name:'Panorámica'},
            {id:'/tipo_images/7'  , name:'Elemento completo'},
            {id:'/tipo_images/8'  , name:'Detalle 1'},
            {id:'/tipo_images/9'  , name:'Detalle 2'},
            {id:'/tipo_images/5'  , name:'Deterioro 1'},
            {id:'/tipo_images/6'  , name:'Deterioro 2'},
        ])       
    }

    return (
        <Edit {...props} title="dynatest.resources.elemento_drenajes.title.editTitle" undoable={false} >
            <TabbedForm redirect="list">
                {/* ------ ELEMENTO DRENAJE ------ */}
                <FormTab label="dynatest.resources.elemento_drenajes.tabs.tabsTitle.identificacion" >
                    <CoordenadasProyecto />
                    <ReferenceInput 
                        label="resources.elemento_drenajes.fields.tipoElemento" 
                        source="tipoElemento"
                        reference="tipo_elementos"
                        validate={[required(), ValidarElemento, OpcionTipoImagen]}
                        filter={{ sistema: 'DRE' }}
                        sort={{
                            field: 'nombre',
                            order: 'ASC'
                        }}
                        fullWidth
                        formClassName={classes.grid_cont3_s}
                        disabled
                    >
                        <SelectInput optionText="nombre" />
                    </ReferenceInput>
                    {elemento === "/tipo_elementos/CUNT" &&
                        <SelectInput fullWidth label="resources.elemento_drenajes.fields.tipoCuneta" source="tipoCuneta" allowEmpty emptyValue="" choices={[
                            { id: translate('dynatest.resources.elemento_drenajes.select.tipoCuneta.bajoAccesosPrediosInterseccionVias'), name: 'dynatest.resources.elemento_drenajes.select.tipoCuneta.bajoAccesosPrediosInterseccionVias' },
                            { id: translate('dynatest.resources.elemento_drenajes.select.tipoCuneta.enZonasUrbanasSemiurbanas'), name: 'dynatest.resources.elemento_drenajes.select.tipoCuneta.enZonasUrbanasSemiurbanas' },
                            { id: translate('dynatest.resources.elemento_drenajes.select.tipoCuneta.tipoCarretera'), name: 'dynatest.resources.elemento_drenajes.select.tipoCuneta.tipoCarretera' },
                        ]} validate={[required()]} formClassName={classes.grid_cont3_s} />
                    }
                    <SelectInput fullWidth source="margen" label="resources.elemento_drenajes.fields.margen" allowEmpty emptyValue="" validate={[required()]} choices={[
                        { id: translate('dynatest.resources.elemento_drenajes.select.margen.derecha'), name: 'dynatest.resources.elemento_drenajes.select.margen.derecha' },
                        { id: translate('dynatest.resources.elemento_drenajes.select.margen.izquierda'), name: 'dynatest.resources.elemento_drenajes.select.margen.izquierda' },
                    ]} formClassName={classes.grid_cont3_s} />
                            <SelectInput label="resources.elemento_drenajes.fields.sentido" source='sentido' choices={[
                                { id: translate('dynatest.resources.elemento_drenajes.select.sentido.NORTEId'), name: 'dynatest.resources.elemento_drenajes.select.sentido.NORTEName' },
                                { id: translate('dynatest.resources.elemento_drenajes.select.sentido.SURId'), name: 'dynatest.resources.elemento_drenajes.select.sentido.SURName' },
                                { id: translate('dynatest.resources.elemento_drenajes.select.sentido.ESTEId'), name: 'dynatest.resources.elemento_drenajes.select.sentido.ESTEName' },
                                { id: translate('dynatest.resources.elemento_drenajes.select.sentido.OESTEId'), name: 'dynatest.resources.elemento_drenajes.select.sentido.OESTEName' }
                                ]}
                                fullWidth
                            formClassName={classes.grid_cont3_s}/>
                    
                    {/* ------- LOCALIZACIÓN --------- */}
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <Typography variant="h3" component="h3">{translate('dynatest.resources.elemento_drenajes.subTitle.localizacion')}</Typography>
                            <Divider fullWidth style={{ marginTop: '10px' }} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={24}>
                        <Grid item xs={4}>
                            <ReferenceInput
                                label="resources.elemento_drenajes.fields.unidadFuncional"
                                source="unidadFuncional"
                                reference="unidad_funcionals"
                                filterToQuery={searchText => ({ codigo: searchText })}
                                sort={{ field: 'codigo', order: 'asc' }}
                                validate={[required(), ValidarUnidad]}
                                fullWidth
                                disabled
                            >
                                <AutocompleteInput options={{ fullWidth: true, disabled: true }} optionText="codigo" />
                            </ReferenceInput>
                        </Grid>
                        <Grid item xs={4}>
                            <ReferenceInput
                                label="resources.elemento_drenajes.fields.tramo"
                                source="tramo"
                                reference="tramos"
                                filter={Unidades}
                                filterToQuery={searchText => ({ codigo: searchText })}
                                sort={{ field: 'codigo', order: 'ASC', }}
                                validate={[required(), ValidarTramo]}
                                formClassName={classes.grid_cont4}
                                disabled
                            >
                                <AutocompleteInput options={{ fullWidth: true, disabled: true }} optionText="codigo" />
                            </ReferenceInput>
                        </Grid>
                        <Grid item xs={4}>
                            <ReferenceInput
                                label="resources.elemento_drenajes.fields.segmento"
                                source="segmento"
                                reference="segmentos"
                                sort={{ field: 'codigo', order: 'ASC' }}
                                filter={Tramoss}
                                filterToQuery={searchText => ({ codigo: searchText })}
                                validate={[required(), ValidarSegmento]}
                                formClassName={classes.grid_cont4}
                                disabled
                            >

                                <AutocompleteInput options={{ fullWidth: true, disabled: true }} optionText="codigo" />
                            </ReferenceInput>
                        </Grid>
                    </Grid>
                    {segmentos && <AbsSegmento segmento={segmentos} />}

                    {segmentos &&
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Typography variant="h3" component="h3" className={classes.coord}>{translate('dynatest.resources.elemento_drenajes.subTitle.coordenadasIniciales')}</Typography>
                                <Divider fullWidth style={{ marginTop: '10px' }} />
                            </Grid>
                        </Grid>
                    }
                    {segmentos && <PRInput label="resources.elemento_drenajes.fields.abscisa" id="ingresoAbInicio" source="abInicio" fullWidth validate={[abValidationInicio, required()]} formClassName={classes.grid_cont3s} />}
                    {segmentos && <PInput label="resources.elemento_drenajes.fields.pr" id="ingresoPrInicio" source="pInicio" fullWidth validate={[prValidationInicio]} formClassName={classes.grid_cont3} />}
                    {segmentos && <NumberInput label="resources.elemento_drenajes.fields.latitud" id="LatitudInicial" source="ubicaciones[0][lat]" fullWidth validate={[required(), latMininima, LatitudMinimaArray]} formClassName={classes.grid_cont3} />}
                    {segmentos && <NumberInput label="resources.elemento_drenajes.fields.longitud" id="LongitudInicial" source="ubicaciones[0][lng]" fullWidth validate={[required(), lngMininima, LongitudMinimaArray]} formClassName={classes.grid_cont3} />}
                    {segmentos &&
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Typography variant="h3" component="h3" className={classes.coord}>{translate('dynatest.resources.elemento_drenajes.subTitle.coordenadasFinales')}</Typography>
                                <Divider fullWidth style={{ marginTop: '10px' }} />
                            </Grid>
                        </Grid>
                    }
                    {segmentos && <PRInput label="resources.elemento_drenajes.fields.abscisa" id="ingresoAbFin" source="abFin" fullWidth validate={[abValidationFin, validarLogicaAbsFinCreate]} formClassName={classes.grid_cont3s} />}
                    {segmentos && <PInput label="resources.elemento_drenajes.fields.pr" id="ingresoPrFin" source="pFin" fullWidth validate={[prValidationFin]} formClassName={classes.grid_cont3} />}
                    {segmentos && <NumberInput label="resources.elemento_drenajes.fields.latitud" source="ubicaciones[1][lat]" fullWidth validate={[latMininima, LatitudMaximaArray]} formClassName={classes.grid_cont3} />}
                    {segmentos && <NumberInput label="resources.elemento_drenajes.fields.longitud" source="ubicaciones[1][lng]" fullWidth validate={[lngMininima, LongitudMaximaArray]} formClassName={classes.grid_cont3} />}
                </FormTab>
                {/* ---------- CARACTERÍSTICAS ------------- */}
                <FormTab label="dynatest.resources.elemento_drenajes.tabs.tabsTitle.caracteristicas">
                {(elemento === "/tipo_elementos/ALCT" || elemento === "/tipo_elementos/BOXC") &&
                    <SelectInput label="resources.elemento_drenajes.fields.material" fullWidth source="material" allowEmpty emptyValue="" /* validate={[required()]} */ choices={[
                        { id: translate('dynatest.resources.elemento_drenajes.select.material.acero'), name: 'dynatest.resources.elemento_drenajes.select.material.acero' },
                        { id: translate('dynatest.resources.elemento_drenajes.select.material.concreto'), name: 'dynatest.resources.elemento_drenajes.select.material.concreto' },
                        { id: translate('dynatest.resources.elemento_drenajes.select.material.concretoAcero'), name: 'dynatest.resources.elemento_drenajes.select.material.concretoAcero' },
                        { id: translate('dynatest.resources.elemento_drenajes.select.material.mamposteria'), name: 'dynatest.resources.elemento_drenajes.select.material.mamposteria' },
                        { id: translate('dynatest.resources.elemento_drenajes.select.material.otro'), name: 'dynatest.resources.elemento_drenajes.select.material.otro' },
                        { id: translate('dynatest.resources.elemento_drenajes.select.material.PEAD'), name: 'dynatest.resources.elemento_drenajes.select.material.PEAD' },
                        { id: translate('dynatest.resources.elemento_drenajes.select.material.PVC'), name: 'dynatest.resources.elemento_drenajes.select.material.PVC' },
                    ]} formClassName={classes.grid_cont4s} />
                    }
                    {(elemento !== "/tipo_elementos/ALCT" && elemento !== "/tipo_elementos/BOXC") &&
                    <SelectInput label="resources.elemento_drenajes.fields.material" fullWidth source="material" allowEmpty emptyValue="" /* validate={[required()]} */ choices={[
                        { id: translate('dynatest.resources.elemento_drenajes.select.material.acero'), name: 'dynatest.resources.elemento_drenajes.select.material.acero' },
                        { id: translate('dynatest.resources.elemento_drenajes.select.material.concreto'), name: 'dynatest.resources.elemento_drenajes.select.material.concreto' },
                        { id: translate('dynatest.resources.elemento_drenajes.select.material.concretoAcero'), name: 'dynatest.resources.elemento_drenajes.select.material.concretoAcero' },
                        { id: translate('dynatest.resources.elemento_drenajes.select.material.mamposteria'), name: 'dynatest.resources.elemento_drenajes.select.material.mamposteria' },
                        { id: translate('dynatest.resources.elemento_drenajes.select.material.otro'), name: 'dynatest.resources.elemento_drenajes.select.material.otro' },
                        { id: translate('dynatest.resources.elemento_drenajes.select.material.PVC'), name: 'dynatest.resources.elemento_drenajes.select.material.PVC' },
                    ]} formClassName={classes.grid_cont6s} />
                    }
                    {(elemento === "/tipo_elementos/ALCT" || elemento === "/tipo_elementos/BOXC") &&
                    <SelectInput label="resources.elemento_drenajes.fields.seccion" fullWidth source="seccion" allowEmpty emptyValue="" validate={[required(),ValidateSeccion]} choices={[
                        { id: translate('dynatest.resources.elemento_drenajes.select.seccion.circular'), name: 'dynatest.resources.elemento_drenajes.select.seccion.circular' },
                        { id: translate('dynatest.resources.elemento_drenajes.select.seccion.cuadrada'), name: 'dynatest.resources.elemento_drenajes.select.seccion.cuadrada' },
                        { id: translate('dynatest.resources.elemento_drenajes.select.seccion.rectangular'), name: 'dynatest.resources.elemento_drenajes.select.seccion.rectangular' },
                        { id: translate('dynatest.resources.elemento_drenajes.select.seccion.trapezoidal'), name: 'dynatest.resources.elemento_drenajes.select.seccion.trapezoidal' },
                        { id: translate('dynatest.resources.elemento_drenajes.select.seccion.triangular'), name: 'dynatest.resources.elemento_drenajes.select.seccion.triangular' },
                    ]} formClassName={classes.grid_cont4} />
                    }
                    {(elemento !== "/tipo_elementos/ALCT" && elemento !== "/tipo_elementos/BOXC") &&
                    <SelectInput label="resources.elemento_drenajes.fields.seccion" fullWidth source="seccion" allowEmpty emptyValue="" validate={[required(),ValidateSeccion]} choices={[
                        { id: translate('dynatest.resources.elemento_drenajes.select.seccion.circular'), name: 'dynatest.resources.elemento_drenajes.select.seccion.circular' },
                        { id: translate('dynatest.resources.elemento_drenajes.select.seccion.cuadrada'), name: 'dynatest.resources.elemento_drenajes.select.seccion.cuadrada' },
                        { id: translate('dynatest.resources.elemento_drenajes.select.seccion.rectangular'), name: 'dynatest.resources.elemento_drenajes.select.seccion.rectangular' },
                        { id: translate('dynatest.resources.elemento_drenajes.select.seccion.trapezoidal'), name: 'dynatest.resources.elemento_drenajes.select.seccion.trapezoidal' },
                        { id: translate('dynatest.resources.elemento_drenajes.select.seccion.triangular'), name: 'dynatest.resources.elemento_drenajes.select.seccion.triangular' },
                    ]} formClassName={classes.grid_cont6} />
                    }
                    {elemento === "/tipo_elementos/ALCT" &&
                    <SelectInput label="resources.elemento_drenajes.fields.tipoDelElemento" fullWidth source="tipoDelElemento" allowEmpty emptyValue="" /* validate={[required()]} */ choices={[
                        { id: translate('dynatest.resources.elemento_drenajes.select.tipoDelElemento.AlcantarDobleconcreto'), name: 'dynatest.resources.elemento_drenajes.select.tipoDelElemento.AlcantarDobleconcreto' },
                        { id: translate('dynatest.resources.elemento_drenajes.select.tipoDelElemento.AlcantarDoblePVC'), name: 'dynatest.resources.elemento_drenajes.select.tipoDelElemento.AlcantarDoblePVC' },
                        { id: translate('dynatest.resources.elemento_drenajes.select.tipoDelElemento.AlcantarDobleMetalica'), name: 'dynatest.resources.elemento_drenajes.select.tipoDelElemento.AlcantarDobleMetalica' },
                        { id: translate('dynatest.resources.elemento_drenajes.select.tipoDelElemento.AlcantarSimpleConcreto'), name: 'dynatest.resources.elemento_drenajes.select.tipoDelElemento.AlcantarSimpleConcreto' },
                        { id: translate('dynatest.resources.elemento_drenajes.select.tipoDelElemento.AlcantarSimplePVC'), name: 'dynatest.resources.elemento_drenajes.select.tipoDelElemento.AlcantarSimplePVC' },
                        { id: translate('dynatest.resources.elemento_drenajes.select.tipoDelElemento.AlcantarSimpleMetalica'), name: 'dynatest.resources.elemento_drenajes.select.tipoDelElemento.AlcantarSimpleMetalica' },
                        { id: translate('dynatest.resources.elemento_drenajes.select.tipoDelElemento.AlcantarTripleConcreto'), name: 'dynatest.resources.elemento_drenajes.select.tipoDelElemento.AlcantarTripleConcreto' },
                        { id: translate('dynatest.resources.elemento_drenajes.select.tipoDelElemento.AlcantarTriplePVC'), name: 'dynatest.resources.elemento_drenajes.select.tipoDelElemento.AlcantarTriplePVC' },
                        { id: translate('dynatest.resources.elemento_drenajes.select.tipoDelElemento.AlcantarTripleMetalica'), name: 'dynatest.resources.elemento_drenajes.select.tipoDelElemento.AlcantarTripleMetalica' },
                    ]} formClassName={classes.grid_cont4} />
                    }
                    {elemento === "/tipo_elementos/BOXC" &&
                    <SelectInput label="resources.elemento_drenajes.fields.tipoDelElemento" fullWidth source="tipoDelElemento" allowEmpty emptyValue="" /* validate={[required()]} */ choices={[
                        { id: translate('dynatest.resources.elemento_drenajes.select.tipoDelElemento.boxCulvertDobleConcreto'), name: 'dynatest.resources.elemento_drenajes.select.tipoDelElemento.boxCulvertDobleConcreto' },
                        { id: translate('dynatest.resources.elemento_drenajes.select.tipoDelElemento.boxCulvertEspecial'), name: 'dynatest.resources.elemento_drenajes.select.tipoDelElemento.boxCulvertEspecial' },
                        { id: translate('dynatest.resources.elemento_drenajes.select.tipoDelElemento.boxCulvertSimpleConcreto'), name: 'dynatest.resources.elemento_drenajes.select.tipoDelElemento.boxCulvertSimpleConcreto' },
                        { id: translate('dynatest.resources.elemento_drenajes.select.tipoDelElemento.boxCulvertTripleConcreto'), name: 'dynatest.resources.elemento_drenajes.select.tipoDelElemento.boxCulvertTripleConcreto' },
                    ]} formClassName={classes.grid_cont4} />
                    }
                    <NumberInput label="resources.elemento_drenajes.fields.largo" source="largo" fullWidth validate={[required(), minValue(0), validarDosDecimales]} className="align-number" formClassName={classes.grid_cont4s} />
                    <NumberInput label="resources.elemento_drenajes.fields.ancho" source="ancho" fullWidth validate={[required(), minValue(0), validarDosDecimales]} className="align-number" formClassName={classes.grid_cont4} />
                    {seccion !== 'Circular' &&
                        <NumberInput label="resources.elemento_drenajes.fields.altura" source="altura" fullWidth validate={[required(), minValue(0), validarDosDecimales]} className="align-number" formClassName={classes.grid_cont4} />
                    }
                    <NumberInput id="numeroTramos" label="resources.elemento_drenajes.fields.numeroTramos" source="numeroTramos" fullWidth validate={[required(), minValue(0), Entero]} formClassName={classes.grid_cont6s} />
                    <NumberInput id="seccionHidraulica" label="resources.elemento_drenajes.fields.seccionHidraulica" source="seccionHidraulica" fullWidth validate={[required(), minValue(0), validarDosDecimales]} formClassName={classes.grid_cont6} />
                    <POInput label="resources.elemento_drenajes.fields.pendienteTransversal" source="pendienteTransversal" fullWidth className="align-number" validate={[required(), validarPorcentaje]} formClassName={classes.grid_cont6s} />
                    <POInput label="resources.elemento_drenajes.fields.pendienteLongitudinal" source="pendienteLongitudinal" fullWidth className="align-number" validate={[required(), validarPorcentaje]} formClassName={classes.grid_cont6} />
                    {(elemento === "/tipo_elementos/ALCT" || elemento === "/tipo_elementos/BOXC") &&
                    <NumberInput id="numeroDuctos" label="resources.elemento_drenajes.fields.numeroDuctos" source="numeroDuctos" fullWidth validate={[/* required(), */ minValue(1), Entero]} formClassName={classes.grid_cont4s} />
                    }
                    {(elemento === "/tipo_elementos/ALCT" || elemento === "/tipo_elementos/BOXC") &&
                    <SelectInput label="resources.elemento_drenajes.fields.tipoPlanta" fullWidth source="tipoPlanta" allowEmpty emptyValue="" /* validate={[required()]} */ choices={[
                        { id: translate('dynatest.resources.elemento_drenajes.select.tipoPlanta.Normal'), name: 'dynatest.resources.elemento_drenajes.select.tipoPlanta.Normal' },
                        { id: translate('dynatest.resources.elemento_drenajes.select.tipoPlanta.Esconso'), name: 'dynatest.resources.elemento_drenajes.select.tipoPlanta.Esconso' },
                    ]} formClassName={classes.grid_cont4} />
                    }
                    {(elemento === "/tipo_elementos/ALCT" || elemento === "/tipo_elementos/BOXC") &&
                    <SelectInput label="resources.elemento_drenajes.fields.direccion" fullWidth source="direccion" allowEmpty emptyValue="" choices={[
                        { id: translate('dynatest.resources.elemento_drenajes.select.direccion.Jusante'), name: 'dynatest.resources.elemento_drenajes.select.direccion.Jusante' },
                        { id: translate('dynatest.resources.elemento_drenajes.select.direccion.Montante'), name: 'dynatest.resources.elemento_drenajes.select.direccion.Montante' },
                    ]} formClassName={classes.grid_cont4} />
                    }
                    {(elemento === "/tipo_elementos/ALCT" || elemento === "/tipo_elementos/BOXC") &&
                    <NumberInput id="areaAla" label="resources.elemento_drenajes.fields.areaAla" source="areaAla" fullWidth validate={[minValue(0), validarDosDecimales]} formClassName={classes.grid_cont6s} />
                    }
                    {(elemento === "/tipo_elementos/ALCT" || elemento === "/tipo_elementos/BOXC") &&
                    <NumberInput id="areaCabezal" label="resources.elemento_drenajes.fields.areaCabezal" source="areaCabezal" fullWidth validate={[minValue(0), validarDosDecimales]} formClassName={classes.grid_cont6} />
                    }
                    {(elemento === "/tipo_elementos/ALCT" || elemento === "/tipo_elementos/BOXC") &&
                    <NumberInput id="areaCalzada" label="resources.elemento_drenajes.fields.areaCalzada" source="areaCalzada" fullWidth validate={[minValue(0), validarDosDecimales]} formClassName={classes.grid_cont6s} />
                    }
                    {(elemento === "/tipo_elementos/ALCT" || elemento === "/tipo_elementos/BOXC") &&
                    <NumberInput id="areaLateral" label="resources.elemento_drenajes.fields.areaLateral" source="areaLateral" fullWidth validate={[minValue(0), validarDosDecimales]} formClassName={classes.grid_cont6} />
                    }
                </FormTab>
                {/* ------ DETALLES ---------- */}
                <FormTab label="dynatest.resources.elemento_drenajes.tabs.tabsTitle.detalles" >
                    <Grid container spacing={24}>
                        <Grid item xs={4}>
                            <SelectInput label="resources.elemento_drenajes.fields.estado"
                                source="estado"
                                choices={[
                                    { id: translate('dynatest.resources.elemento_drenajes.select.estado.existente'), name: 'dynatest.resources.elemento_drenajes.select.estado.existente' },
                                    { id: translate('dynatest.resources.elemento_drenajes.select.estado.reemplazo'), name: 'dynatest.resources.elemento_drenajes.select.estado.reemplazo' },
                                    { id: translate('dynatest.resources.elemento_drenajes.select.estado.nuevo'), name: 'dynatest.resources.elemento_drenajes.select.estado.nuevo' },
                                ]}
                                fullWidth
                                validate={[required(), ValidarEstado]}
                                formClassName={classes.grid_cont4s}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            {(estados === translate('dynatest.resources.elemento_drenajes.select.estado.reemplazo')) &&
                                <SelectInput
                                    label="resources.elemento_drenajes.fields.motivoReemplazo"
                                    fullWidth
                                    source="motivoReemplazo"
                                    allowEmpty emptyValue=""
                                    choices={[
                                        { id: translate('dynatest.resources.elemento_drenajes.select.motivoReemplazo.accidente'), name: 'dynatest.resources.elemento_drenajes.select.motivoReemplazo.accidente' },
                                        { id: translate('dynatest.resources.elemento_drenajes.select.motivoReemplazo.otro'), name: 'dynatest.resources.elemento_drenajes.select.motivoReemplazo.otro' },
                                        { id: translate('dynatest.resources.elemento_drenajes.select.motivoReemplazo.reemplazoProgramado'), name: 'dynatest.resources.elemento_drenajes.select.motivoReemplazo.reemplazoProgramado' },
                                        { id: translate('dynatest.resources.elemento_drenajes.select.motivoReemplazo.vandalismo'), name: 'dynatest.resources.elemento_drenajes.select.motivoReemplazo.vandalismo' },
                                    ]}
                                    formClassName={classes.grid_cont4} />
                            }
                        </Grid>
                        <Grid item xs={4}>
                            {(estados === translate('dynatest.resources.elemento_drenajes.select.estado.reemplazo')) &&
                                <FormDataConsumer>
                                    {({ formData, ...rest }) =>
                                            <ReferenceInput
                                                {...rest}
                                                label="resources.elemento_drenajes.fields.elementoReemplazo_id"
                                                source="elementoReemplazo_id"
                                                reference="elemento_drenajes"
                                                filter={{
                                                    tipoElemento: elemento,
                                                    status: 1,
                                                    unidadFuncional: unidads,
                                                    tramo: tramos,
                                                    segmento : segmentos,
                                                    exclude : (formData) ? formData.id : null
                                                }}
                                                fullWidth
                                                formClassName={classes.grid_cont4}
                                            >
                                            <AutocompleteInput options={{ fullWidth: true }} optionText="fullCodigoConsecutivo" />
                                        </ReferenceInput>
                                    }
                                </FormDataConsumer>
                            }
                        </Grid>
                    </Grid>
                    <Grid container spacing={24}>
                        
                        <Grid item xs={4}>
                            <SelectInput label="resources.elemento_drenajes.fields.estados" source="estados" allowEmpty emptyValue="" choices={[
                                { id: translate('dynatest.resources.elemento_drenajes.select.estados.Activo'), name: 'dynatest.resources.elemento_drenajes.select.estados.Activo' },
                                { id: translate('dynatest.resources.elemento_drenajes.select.estados.Inactivo'), name: 'dynatest.resources.elemento_drenajes.select.estados.Inactivo' },
                            ]} validate={[required(), validarRazon]} fullWidth formClassName={classes.grid_cont4s}/>
                        </Grid>
                        {(detallesEstado === translate('dynatest.resources.elemento_drenajes.select.estados.Inactivo')) &&  
                            <Grid item xs={4}>          
                                <TextInput source="estadoRazon" label="resources.elemento_drenajes.fields.estadoRazon" fullWidth formClassName={classes.grid_cont4} validate={[required()]}/>
                            </Grid>
                        }
                    </Grid>
                    <Grid container spacing={24}>
                        <Grid item xs={4}>
                            <DateInput label="resources.elemento_drenajes.fields.fechaInstalacion" source="fechaInstalacion" fullWidth validate={[required(), validacionmaxfechaact]} formClassName={classes.grid_cont3s}/>
                        </Grid>
                        <Grid item xs={4}>
                            <ReferenceInput
                                label="resources.elemento_drenajes.fields.proveedor"
                                source="proveedor"
                                reference="proveedors"
                                filter={{
                                    proyecto: props.proyecto, sistema: 'DRE'
                                }}
                                sort={{ field: 'razonSocial', order: 'DRE' }}
                                filterToQuery={searchText => ({ razonSocial: searchText })}
                                validate={[required()]}
                            >
                                <AutocompleteInput options={{ fullWidth: true }} optionText="razonSocial" />
                            </ReferenceInput>
                        </Grid>
                        <Grid item xs={4}>
                            <NumberInput label="resources.elemento_drenajes.fields.garantia" source="garantia" fullWidth validate={[required(), Entero, minValue(0)]} />
                        </Grid>
                    </Grid>
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <BooleanInput label="dynatest.resources.elemento_drenajes.boolean.mantenimiento" source="mantenimiento" fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput label="resources.elemento_drenajes.fields.observaciones" source="observaciones" fullWidth validate={[required(), minLength(5, "Al menos 5 Caracteres."), maxLength(200, "No mas de 200 Caracteres")]} />
                        </Grid>
                    </Grid>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => { formData.updatedIn = "api"; }}
                    </FormDataConsumer> 
                </FormTab>
                <FormTab label="dynatest.resources.elemento_drenajes.tabs.tabsTitle.fotografias">
                    {/* <ImageInput multiple={true} style={{display :'none'}} source="images" label=" " maxSize={1000000000} accept="image/jpeg, image/png">
                        <ImageField source="src" title="title" />
                    </ImageInput> */}
                    <Grid container spacing={24}>                         
                        <Grid item xs={8}>  
                            {/* <ArrayInput label="" source="imagess" defaultValue={defaultTipoImagen}>
                                <SimpleFormIterator disableAdd disableRemove >
                                    <ImageInput multiple={true} source="images" label=" " maxSize={1000000000} accept="image/jpeg, image/png">
                                        <ImageField source="src" title="title" />
                                    </ImageInput>                           
                                    <ReferenceInput disabled label="Tipo de elemento" source="tipoImagen" reference="tipo_images" fullWidth validate={[required()]} sort={{ field: 'nombre', order: 'ASC' }}  formClassName={classes.grid_cont6}>
                                        <SelectInput  optionText="nombre"  />
                                    </ReferenceInput>                          
                                </SimpleFormIterator>
                            </ArrayInput>    */}
                            <FormDataConsumer>
                                {({ formData, ...rest }) =>
                                    <ArrayInputImagesEdit record={formData} {...rest} />                                
                                }
                            </FormDataConsumer> 
                        </Grid>  
                        <Grid item xs={4}>
                            <FormDataConsumer>
                                {({ formData, ...rest }) =>
                                    <ButtonCarousel record={formData} {...rest} />                                
                                }
                            </FormDataConsumer>  
                        </Grid> 
                    </Grid>                   
                </FormTab>
            </TabbedForm>
        </Edit>
    )
}));