import React, { useState } from 'react';
import {
    NumberInput, 
    ReferenceInput, 
    ArrayInput, 
    SimpleFormIterator,
    SelectInput,
     FormDataConsumer, 
     DateInput,
    TextInput, 
    BooleanInput, 
    maxLength, 
    Query,
    AutocompleteInput
} from 'react-admin';
import { PimsCreate } from './../Create';
import TabbedForm from '../TabbedForm'
import { PRInput } from '../../inputs/PRInput';
import { PInput } from '../../inputs/PInput';
import { styles } from './../../EstilosGrid';
import { withStyles } from '@material-ui/core';
import { latMininima, lngMininima, abValidationInicio, abValidationFin, prValidationInicio, prValidationFin, validarUnDecimal, Entero, 
          validarDosDecimales,validarAB, validarPR, isFechaInvalida, validarLogicaAbsFinCreate
        } from '../validacionesInputs';
import {
    required,
    FormTab,
    maxValue
} from 'react-admin';
import { minValue } from 'ra-core';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CoordenadasProyecto from '../CoordenadasInventario';
import AbsSegmento from '../AbsSegmento';
import { ImagenesInventario } from '../images/ImagenesInventario';
import { translate } from 'react-admin';


const defensachoices =
    [
        
        { id: '1', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniAbatidaEsviadaTerFinAbatida' },
        { id: '2', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniAbatidaEsviadaTerFinAbatidaEsviada' },
        { id: '3', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniAbatEsviadaTermFinEnterradaTalud' },
        { id: '4', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniAbatEsviadaTermFinColaPez' },
        { id: '5', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniAbatTerminalFinAbatida' },
        { id: '6', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniAbatTerminalFinAbatidaEsviada' },
        { id: '7', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniAbatTerminalFinColaPez' },
        { id: '17', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniAbatTerminalFinSalidaAerea' },
        { id: '8', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniAbatTerminalFinEnterradaTalud' },
        { id: '18', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniAbatTerminalFinIncrustadaObraArte' },
        { id: '10', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniColaPezTermiFinAbatidaEsviada' },
        { id: '9', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniColaPezTermiFinAbatida' },
        { id: '11', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniColaPezTermiFinColaPez' },
        { id: '12', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniColaPezTermiFinEnterradaTalud' },
        { id: '19', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniAmortiguadorImpacTermFinAbatida' },
        { id: '21', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniAmortiguadorImpacTermFinColaPez' },
        { id: '22', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniAmortiguadorImpacTermFinSalAerea' },
        { id: '20', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniAmortiguadorImpacTermFinEnterradaTalud' },
        { id: '23', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniAmortiguadorImpacTermFinIncrusObraArte' },
        { id: '14', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniEnterrTaludTermFinAbatEsviada' },
        { id: '13', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniEnterrTaludTermFinAbatida' },
        { id: '15', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniEnterrTaludTermFinColaPez' },
        { id: '16', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniEnterrTaludTermFinEnterrTalud' },
        { id: '24', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniEsviadaTermFinAbatida' },
        { id: '25', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniEsviadaTermFinSalidaAerea' },
        { id: '26', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniEsviadaTermFinEnterradaTalud' },
        { id: '27', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniEsviadaTermFinIncrustadaObraArte' },
        { id: '28', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniIncrustObraArteTermFinalAbatida' },
        { id: '29', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniIncrustObraArteTermFinalSalidaAerea' },
        { id: '30', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniIncrustObraArteTermFinalEnterradaTalud' },
        { id: '31', name: 'dynatest.resources.seguridads.select.tipodeDefensa.termIniIncrustObraArteTermFinalEsviada' },
    ]
const optionRendererDefensa = choice => `${choice.name}`;

const tipoElemento = { sistema: 'SEG' };


export const SeguridadCreate = translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;

    const [elemento, setElemento] = useState('-');
    const ValidarElemento = (value) => {
        setElemento(value)
    }

    const [unidads, setUnidad] = useState('');
    const [tramos, setTramo] = useState('');
    const [segmentos, setSegmento] = useState('');

    const ValidarUnidad = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setUnidad(value);
    }
    const ValidarTramo = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setTramo(value);
    }

    const ValidarSegmento = (value) => {
        setSegmento(value)
    }
    /*VALORES POR DEFECTO DEL ARRAY DE COORDENADAS*/
    const [latitudMinimaArray, setLatitudArray] = useState('');
    const LatitudMinimaArray = (value) => {
        setLatitudArray(value)
    }
    const [longitudMinimaArray, setLongitudArray] = useState('');
    const LongitudMinimaArray = (value) => {
        setLongitudArray(value)
    }
    const [longitudMaximaArray, setLongitudMaxdArray] = useState('');
    const LongitudMaximaArray = (value) => {
        setLongitudMaxdArray(value)
    }
    const [latitudMaximaArray, setLatitudMaxdArray] = useState('');
    const LatitudMaximaArray = (value) => {
        setLatitudMaxdArray(value)
    }
    /*FIN DE LOS VALORES POR DEFECTO DEL ARRAY DE COORDENADAS*/

    const [estados, setEstado] = useState('');

    const ValidarEstado = (value) => {

        let lenghtObject = Object.keys(value);
        let valor = '';

        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setEstado(valor)
    }

/*OPCIONES PARA TIPO DE IMÁGENES*/
    const [defaultTipoImagen,setDefaultTipoImagen] = useState([{id: 'Cargando...' ,name :'Cargando...'}]);
    const OpcionTipoImagen = (value) =>{
        if(value !== '/tipo_elementos/CAPT' && value !== '/tipo_elementos/TPVH' && value !== '/tipo_elementos/CERC' && value !== '/tipo_elementos/BORD' ){
            setDefaultTipoImagen([
                {
                    tipoImagen : '/tipo_images/1',
                },
                {
                    tipoImagen : '/tipo_images/7',
                },
                {
                    tipoImagen : '/tipo_images/8',
                },
                {
                    tipoImagen : '/tipo_images/9',
                },
                {
                    tipoImagen : '/tipo_images/5',
                },
                {
                    tipoImagen : '/tipo_images/6',
                }
            ])
        }else if(value === '/tipo_elementos/CERC'){
            setDefaultTipoImagen([
                {
                    tipoImagen : '/tipo_images/1',
                },
                {
                    tipoImagen : '/tipo_images/7',
                },            
            ])
        }else{
            setDefaultTipoImagen([
                {
                    tipoImagen : '/tipo_images/1',
                },
                {
                    tipoImagen : '/tipo_images/7',
                },
                {
                    tipoImagen : '/tipo_images/5',
                },
                {
                    tipoImagen : '/tipo_images/6',
                }
            ])
        }       
    }

    return (
        <PimsCreate {...props} >
            <TabbedForm 
                redirect="list" 
            >
                <FormTab label="dynatest.resources.seguridads.tabs.tabsTitle.identificacion">
                    <CoordenadasProyecto />
                    <ReferenceInput 
                        label="resources.seguridads.fields.tipoElemento" 
                        source="tipoElemento" 
                        reference="tipo_elementos" 
                        validate={[required(), ValidarElemento,OpcionTipoImagen]} 
                        filter={{ 
                            sistema: 'SEG' 
                        }} 
                        sort={{ 
                            field: 'nombre', 
                            order: 'ASC'
                        }} 
                        fullWidth 
                        formClassName={classes.grid_cont4s} 
                    >
                        <SelectInput optionText="nombre" />
                    </ReferenceInput>
                    <SelectInput 
                        fullWidth 
                        label="resources.seguridads.fields.margen" 
                        source="margen" 
                        allowEmpty 
                        emptyValue="" 
                        validate={[required()]} 
                        choices={[
                            { id: translate('dynatest.resources.seguridads.select.margen.derechaId'), name: 'dynatest.resources.seguridads.select.margen.derechaName' },
                            { id: translate('dynatest.resources.seguridads.select.margen.izquierdaId'), name: 'dynatest.resources.seguridads.select.margen.izquierdaName' },
                            { id: translate('dynatest.resources.seguridads.select.margen.centroId'), name: 'dynatest.resources.seguridads.select.margen.centroName' },
                        ]} formClassName={classes.grid_cont4} 
                    />
                    <SelectInput label="resources.seguridads.fields.sentido" source='sentido' choices={[
                            { id: translate('dynatest.resources.seguridads.select.sentido.norteId'), name: 'dynatest.resources.seguridads.select.sentido.norteName' },
                            { id: translate('dynatest.resources.seguridads.select.sentido.surId'), name: 'dynatest.resources.seguridads.select.sentido.surName' },
                            { id: translate('dynatest.resources.seguridads.select.sentido.esteId'), name: 'dynatest.resources.seguridads.select.sentido.esteName' },
                            { id: translate('dynatest.resources.seguridads.select.sentido.oesteId'), name: 'dynatest.resources.seguridads.select.sentido.oesteName' }
                            ]}
                            fullWidth
                            formClassName={classes.grid_cont4}/>

                    <Query type="GET_ONE" resource="tipo_elementos" payload={{ id: elemento}} >
                        {({ data, loading, error }) => {                                                
                            if( data){
                                return (
                                <TextInput 
                                    defaultValue={data.nombre} 
                                    source="tipoElementoDesc" 
                                    fullWidth validate={[required()]}
                                    style={{display:'none'}} 
                                />)
                            }
                            return <div></div>;                                                
                        }}
                    </Query> 
                    {/* ---------- LOCALIZACIÓN ------------------ */}
                    <Typography variant="h3" component="h3" className={classes.coord}>{translate('dynatest.resources.seguridads.subTitle.localizacion')}</Typography>
                    <Divider fullWidth formClassName={classes.grid_cont_div} />
                    <ReferenceInput 
                        label="resources.seguridads.fields.unidadFuncional" 
                        source="unidadFuncional"  
                        reference="unidad_funcionals" 
                        filterToQuery={searchText => ({ codigo: searchText })} 
                        sort={{field : 'codigo', order : 'asc'}}
                        validate={[required(), ValidarUnidad]}
                        formClassName={classes.grid_cont4s}
                        fullWidth
                    >
                        <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
                    </ReferenceInput>
                                           
                    {unidads &&
                            <ReferenceInput 
                                label="resources.seguridads.fields.tramo" 
                                source="tramo" 
                                alwaysOn 
                                reference="tramos" 
                                filter={{ unidadFuncional:unidads}}
                                filterToQuery={searchText => ({ codigo: searchText })} 
                                sort={{ field: 'codigo', order: 'ASC' ,}}
                                validate={[required(), ValidarTramo]}
                                formClassName={classes.grid_cont4}
                                fullWidth
                            >
                                <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
                            </ReferenceInput>
                    }
                    {tramos &&
                            <ReferenceInput 
                                label="resources.seguridads.fields.segmento" 
                                source="segmento" 
                                reference="segmentos" 
                                sort={{ field: 'codigo', order: 'ASC' }}
                                filter={{tramo : tramos}}
                                filterToQuery={searchText => ({ codigo: searchText })} 
                                validate={[required(), ValidarSegmento]}
                                formClassName={classes.grid_cont4}
                                fullWidth
                            >
                            
                                <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
                            </ReferenceInput>
                    }
                    {segmentos &&
                        <div>
                            <Typography variant="h3" component="h3" className={classes.coord}>{translate('dynatest.resources.seguridads.subTitle.coordenadasIniciales')}</Typography>
                            <Divider fullWidth style={{marginTop : '10px'}} />
                        </div>
                    }
                    {segmentos && <PRInput label="resources.seguridads.fields.abscisa" id="ingresoAbInicio" source="abInicio" fullWidth validate={[abValidationInicio, required()]} formClassName={classes.grid_cont3s} />}
                    {segmentos && <PInput label="resources.seguridads.fields.pr" id="ingresoPrInicio" source="pInicio" fullWidth validate={[prValidationInicio]} formClassName={classes.grid_cont3} />}
                    {segmentos && <NumberInput label="resources.seguridads.fields.latitud" id="LatitudInicial" source="latitudInicial" fullWidth validate={[required(), latMininima, LatitudMinimaArray]} formClassName={classes.grid_cont3} />}
                    {segmentos && <NumberInput label="resources.seguridads.fields.longitud" id="LongitudInicial" source="longitudInicial" fullWidth validate={[required(), lngMininima, LongitudMinimaArray]} formClassName={classes.grid_cont3} />}
                    {segmentos &&
                    <div>
                        <Typography variant="h3" component="h3" className={classes.coord}>{translate('dynatest.resources.seguridads.subTitle.coordenadasFinales')}</Typography>
                        <Divider fullWidth style={{marginTop : '10px'}} />
                    </div>
                    }
                    {/* validarLogicaAbsFinCreate */}
                    {segmentos && <PRInput label="resources.seguridads.fields.abscisa" id="ingresoAbFin" source="abFin" fullWidth validate={[abValidationFin, validarLogicaAbsFinCreate]} formClassName={classes.grid_cont3s} />}
                    {segmentos && <PInput label="resources.seguridads.fields.pr" id="ingresoPrFin" source="pFin" fullWidth validate={[prValidationFin]} formClassName={classes.grid_cont3} />}
                    {segmentos && <NumberInput label="resources.seguridads.fields.latitud" source="latitudFinal" fullWidth validate={[LatitudMaximaArray, latMininima]} formClassName={classes.grid_cont3} />}
                    {segmentos && <NumberInput label="resources.seguridads.fields.longitud" source="longitudFinal" fullWidth validate={[lngMininima, LongitudMaximaArray]} formClassName={classes.grid_cont3} />}
                    {segmentos &&
                        <ArrayInput label="" source="ubicaciones" className={classes.ocultar}
                            defaultValue={[
                                {
                                    lat: latitudMinimaArray,
                                    lng: longitudMinimaArray
                                },
                                {
                                    lat: latitudMaximaArray,
                                    lng: longitudMaximaArray
                                }
                            ]}
                        >
                            <SimpleFormIterator disableAdd disableRemove >
                                <NumberInput label="Latitud" source="lat" fullWidth />
                                <NumberInput label="Longitud" source="lng" fullWidth />
                            </SimpleFormIterator>
                        </ArrayInput>
                    }
                    {segmentos &&
                         <AbsSegmento segmento={segmentos}/>
                    }
                </FormTab>
                <FormTab label="dynatest.resources.seguridads.tabs.tabsTitle.caracteristicas">
                    {elemento === "/tipo_elementos/BRRC" &&
                                <SelectInput 
                                    fullWidth 
                                    label="resources.seguridads.fields.material" 
                                    source="material" 
                                    allowEmpty 
                                    emptyValue="" 
                                    validate={[required()]} 
                                    choices={[
                                        { id: translate('dynatest.resources.seguridads.select.material.barreraJerseyPlasticaId'), name: 'dynatest.resources.seguridads.select.material.barreraJerseyPlastica' },
                                        { id: translate('dynatest.resources.seguridads.select.material.concreto'), name: 'dynatest.resources.seguridads.select.material.concreto' },
                                        { id: translate('dynatest.resources.seguridads.select.material.malla'), name: 'dynatest.resources.seguridads.select.material.malla' },
                                        { id: translate('dynatest.resources.seguridads.select.material.metalicaId'), name: 'dynatest.resources.seguridads.select.material.metalica' },
                                        { id: translate('dynatest.resources.seguridads.select.material.plasticaId'), name: 'dynatest.resources.seguridads.select.material.plastica' },
                                        { id: translate('dynatest.resources.seguridads.select.material.polisombra'), name: 'dynatest.resources.seguridads.select.material.polisombra' },
                                        { id: translate('dynatest.resources.seguridads.select.material.prefabricado'), name: 'dynatest.resources.seguridads.select.material.prefabricado' }
                                    ]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/BRRC" &&
                                <NumberInput 
                                    label="resources.seguridads.fields.cant_elem" 
                                    source="cant_elem" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), Entero]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/BRRC" &&
                                <NumberInput 
                                    label="resources.seguridads.fields.cant_capta" 
                                    source="cant_capta" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), Entero]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/BRRC" &&
                                <NumberInput 
                                    label="resources.seguridads.fields.cant_largo" 
                                    source="cant_largo" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), validarUnDecimal]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/BRRC" &&
                                <NumberInput 
                                    label="resources.seguridads.fields.cant_ancho" 
                                    source="cant_ancho" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), validarUnDecimal]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/BRRC" &&
                                <NumberInput 
                                    label="resources.seguridads.fields.altura" 
                                    source="altura" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), validarDosDecimales]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/BRRF" &&
                                <SelectInput 
                                    fullWidth 
                                    label="resources.seguridads.fields.material" 
                                    source="material" 
                                    allowEmpty emptyValue="" 
                                    validate={[required()]} 
                                    choices={[
                                        { id: translate('dynatest.resources.seguridads.select.material.barreraJerseyPlasticaId'), name: 'dynatest.resources.seguridads.select.material.barreraJerseyPlastica' },
                                        { id: translate('dynatest.resources.seguridads.select.material.concreto'), name: 'dynatest.resources.seguridads.select.material.concreto' },
                                        { id: translate('dynatest.resources.seguridads.select.material.malla'), name: 'dynatest.resources.seguridads.select.material.malla' },
                                        { id: translate('dynatest.resources.seguridads.select.material.metalicaId'), name: 'dynatest.resources.seguridads.select.material.metalica' },
                                        { id: translate('dynatest.resources.seguridads.select.material.plasticaId'), name: 'dynatest.resources.seguridads.select.material.plastica' },
                                        { id: translate('dynatest.resources.seguridads.select.material.polisombra'), name: 'dynatest.resources.seguridads.select.material.polisombra' },
                                        { id: translate('dynatest.resources.seguridads.select.material.prefabricado'), name: 'dynatest.resources.seguridads.select.material.prefabricado' }
                                    ]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/BRRF" &&
                                <NumberInput 
                                    label="resources.seguridads.fields.cant_elem" 
                                    source="cant_elem" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), Entero]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/BRRF" &&
                                <NumberInput 
                                    label="resources.seguridads.fields.cant_capta" 
                                    source="cant_capta" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), Entero]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/BRRF" &&
                                <NumberInput 
                                    label="resources.seguridads.fields.cant_largo" 
                                    source="cant_largo" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), validarUnDecimal]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/BRRF" &&
                                <NumberInput 
                                    label="resources.seguridads.fields.cant_ancho" 
                                    source="cant_ancho" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), validarUnDecimal]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/BRRF" &&
                                
                                <NumberInput 
                                    label="resources.seguridads.fields.altura" 
                                    source="altura" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), validarUnDecimal]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/BRRJ" &&
                                <SelectInput 
                                    fullWidth 
                                    label="resources.seguridads.fields.material" 
                                    source="material" 
                                    allowEmpty 
                                    emptyValue="" 
                                    validate={[required()]} 
                                    choices={[
                                        { id: translate('dynatest.resources.seguridads.select.material.barreraJerseyPlasticaId'), name: 'dynatest.resources.seguridads.select.material.barreraJerseyPlastica' },
                                        { id: translate('dynatest.resources.seguridads.select.material.concreto'), name: 'dynatest.resources.seguridads.select.material.concreto' },
                                        { id: translate('dynatest.resources.seguridads.select.material.malla'), name: 'dynatest.resources.seguridads.select.material.malla' },
                                        { id: translate('dynatest.resources.seguridads.select.material.metalicaId'), name: 'dynatest.resources.seguridads.select.material.metalica' },
                                        { id: translate('dynatest.resources.seguridads.select.material.plasticaId'), name: 'dynatest.resources.seguridads.select.material.plastica' },
                                        { id: translate('dynatest.resources.seguridads.select.material.polisombra'), name: 'dynatest.resources.seguridads.select.material.polisombra' },
                                        { id: translate('dynatest.resources.seguridads.select.material.prefabricado'), name: 'dynatest.resources.seguridads.select.material.prefabricado' }
                                    ]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/BRRJ" &&
                                <NumberInput 
                                    label="resources.seguridads.fields.cant_elem" 
                                    source="cant_elem" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), Entero]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/BRRJ" &&
                                <NumberInput 
                                    label="resources.seguridads.fields.cant_capta" 
                                    source="cant_capta" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), Entero]} 
                                    formClassName={classes.grid_cont4Rigth}
                                 />
                    }
                    {elemento === "/tipo_elementos/BRRJ" &&
                                <NumberInput 
                                    label="resources.seguridads.fields.cant_largo" 
                                    source="cant_largo" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), validarUnDecimal]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/BRRJ" &&
                                <NumberInput 
                                    label="resources.seguridads.fields.cant_ancho" 
                                    source="cant_ancho" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), validarUnDecimal]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/BRRJ" &&
                                <NumberInput 
                                    label="resources.seguridads.fields.altura" 
                                    source="altura" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), validarUnDecimal]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/BORD" &&
                                <SelectInput fullWidth label="resources.seguridads.fields.material" source="material" allowEmpty emptyValue="" validate={[required()]} choices={[
                                    { id: translate('dynatest.resources.seguridads.select.material.barreraJerseyPlasticaId'), name: 'dynatest.resources.seguridads.select.material.barreraJerseyPlastica' },
                                    { id: translate('dynatest.resources.seguridads.select.material.concreto'), name: 'dynatest.resources.seguridads.select.material.concreto' },
                                    { id: translate('dynatest.resources.seguridads.select.material.malla'), name: 'dynatest.resources.seguridads.select.material.malla' },
                                    { id: translate('dynatest.resources.seguridads.select.material.metalicaId'), name: 'dynatest.resources.seguridads.select.material.metalica' },
                                    { id: translate('dynatest.resources.seguridads.select.material.plasticaId'), name: 'dynatest.resources.seguridads.select.material.plastica' },
                                    { id: translate('dynatest.resources.seguridads.select.material.polisombra'), name: 'dynatest.resources.seguridads.select.material.polisombra' },
                                    { id: translate('dynatest.resources.seguridads.select.material.prefabricado'), name: 'dynatest.resources.seguridads.select.material.prefabricado' }

                                ]} 
                                formClassName={classes.grid_cont4Rigth}
                            />
                    }
                    {elemento === "/tipo_elementos/BORD" &&
                                <NumberInput 
                                    label="resources.seguridads.fields.cant_elem" 
                                    source="cant_elem" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), Entero]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/BORD" &&
                                <NumberInput 
                                    label="resources.seguridads.fields.cant_largo" 
                                    source="cant_largo" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), validarUnDecimal]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/BORD" &&
                                <NumberInput 
                                    label="resources.seguridads.fields.cant_ancho" 
                                    source="cant_ancho" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), validarUnDecimal]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/BORD" &&        
                                <NumberInput 
                                    label="resources.seguridads.fields.altura" 
                                    source="altura" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), validarUnDecimal]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/CERC" &&
                                <SelectInput 
                                    fullWidth 
                                    label="resources.seguridads.fields.material" 
                                    source="material" 
                                    allowEmpty emptyValue="" 
                                    validate={[required()]} 
                                    choices={[
                                        { id: translate('dynatest.resources.seguridads.select.material.barreraJerseyPlasticaId'), name: 'dynatest.resources.seguridads.select.material.barreraJerseyPlastica' },
                                        { id: translate('dynatest.resources.seguridads.select.material.concreto'), name: 'dynatest.resources.seguridads.select.material.concreto' },
                                        { id: translate('dynatest.resources.seguridads.select.material.malla'), name: 'dynatest.resources.seguridads.select.material.malla' },
                                        { id: translate('dynatest.resources.seguridads.select.material.metalicaId'), name: 'dynatest.resources.seguridads.select.material.metalica' },
                                        { id: translate('dynatest.resources.seguridads.select.material.plasticaId'), name: 'dynatest.resources.seguridads.select.material.plastica' },
                                        { id: translate('dynatest.resources.seguridads.select.material.polisombra'), name: 'dynatest.resources.seguridads.select.material.polisombra' },
                                        { id: translate('dynatest.resources.seguridads.select.material.prefabricado'), name: 'dynatest.resources.seguridads.select.material.prefabricado' }

                                    ]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/CERC" &&
                                <NumberInput 
                                    label="resources.seguridads.fields.cant_elem" 
                                    source="cant_elem" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), Entero]} 
                                    formClassName={classes.grid_cont4Rigth}
                                    />
                    }
                    {elemento === "/tipo_elementos/CERC" &&
                                <NumberInput 
                                    label="resources.seguridads.fields.cant_largo" 
                                    source="cant_largo" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), validarUnDecimal]} 
                                    formClassName={classes.grid_cont4Rigth}
                                    />
                    }
                    {elemento === "/tipo_elementos/CERC" &&
                                <NumberInput 
                                    label="resources.seguridads.fields.cant_ancho" 
                                    source="cant_ancho" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), validarUnDecimal]} 
                                    formClassName={classes.grid_cont4Rigth}
                                    />
                    }
                    {elemento === "/tipo_elementos/CERC" &&
                                <NumberInput 
                                    label="resources.seguridads.fields.altura" 
                                    source="altura" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), validarUnDecimal]} 
                                    formClassName={classes.grid_cont4Rigth}
                                    />
                    }
                    {elemento === "/tipo_elementos/CAPT" &&
                                <SelectInput 
                                    fullWidth 
                                    label="resources.seguridads.fields.material" 
                                    source="material" 
                                    allowEmpty 
                                    emptyValue="" 
                                    validate={[required()]} 
                                    choices={[
                                        { id: translate('dynatest.resources.seguridads.select.material.barreraJerseyPlasticaId'), name: 'dynatest.resources.seguridads.select.material.barreraJerseyPlastica' },
                                        { id: translate('dynatest.resources.seguridads.select.material.concreto'), name: 'dynatest.resources.seguridads.select.material.concreto' },
                                        { id: translate('dynatest.resources.seguridads.select.material.malla'), name: 'dynatest.resources.seguridads.select.material.malla' },
                                        { id: translate('dynatest.resources.seguridads.select.material.metalicaId'), name: 'dynatest.resources.seguridads.select.material.metalica' },
                                        { id: translate('dynatest.resources.seguridads.select.material.plasticaId'), name: 'dynatest.resources.seguridads.select.material.plastica' },
                                        { id: translate('dynatest.resources.seguridads.select.material.polisombra'), name: 'dynatest.resources.seguridads.select.material.polisombra' },
                                        { id: translate('dynatest.resources.seguridads.select.material.prefabricado'), name: 'dynatest.resources.seguridads.select.material.prefabricado' }
                                    ]} 
                                    formClassName={classes.grid_cont6Right}
                                />
                    }
                    {elemento === "/tipo_elementos/CAPT" &&
                                <NumberInput 
                                    label="resources.seguridads.fields.cant_capta" 
                                    source="captafaros" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), Entero]} 
                                    formClassName={classes.grid_cont6Right}
                                />
                    }
                    {elemento === "/tipo_elementos/CAPT" &&
                                <NumberInput 
                                    label="resources.seguridads.fields.cant_largo" 
                                    source="cant_largo" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), validarUnDecimal]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/CAPT" &&
                                <NumberInput 
                                    label="resources.seguridads.fields.cant_ancho" 
                                    source="cant_ancho" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), validarUnDecimal]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/CAPT" &&
                                <NumberInput 
                                    label="resources.seguridads.fields.altura" 
                                    source="altura" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), validarUnDecimal]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                            
                    }
                    {elemento === "/tipo_elementos/DEFMT" &&
            
                                <SelectInput fullWidth label="resources.seguridads.fields.material" source="material" allowEmpty emptyValue="" validate={[required()]} choices={[
                                    { id: translate('dynatest.resources.seguridads.select.material.barreraJerseyPlasticaId'), name: 'dynatest.resources.seguridads.select.material.barreraJerseyPlastica' },
                                    { id: translate('dynatest.resources.seguridads.select.material.concreto'), name: 'dynatest.resources.seguridads.select.material.concreto' },
                                    { id: translate('dynatest.resources.seguridads.select.material.malla'), name: 'dynatest.resources.seguridads.select.material.malla' },
                                    { id: translate('dynatest.resources.seguridads.select.material.metalicaId'), name: 'dynatest.resources.seguridads.select.material.metalica' },
                                    { id: translate('dynatest.resources.seguridads.select.material.plasticaId'), name: 'dynatest.resources.seguridads.select.material.plastica' },
                                    { id: translate('dynatest.resources.seguridads.select.material.polisombra'), name: 'dynatest.resources.seguridads.select.material.polisombra' },
                                    { id: translate('dynatest.resources.seguridads.select.material.prefabricado'), name: 'dynatest.resources.seguridads.select.material.prefabricado' }

                                ]} formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/DEFMT" &&
                                <SelectInput 
                                    fullWidth 
                                    label="resources.seguridads.fields.perfil" 
                                    source="perfil" 
                                    allowEmpty 
                                    emptyValue="" 
                                    validate={[required()]} 
                                    choices={[
                                        { id: translate('dynatest.resources.seguridads.select.perfil.perfilC'), name: 'dynatest.resources.seguridads.select.perfil.perfilC' },
                                        { id: translate('dynatest.resources.seguridads.select.perfil.perfilU'), name: 'dynatest.resources.seguridads.select.perfil.perfilU' },
                                        { id: translate('dynatest.resources.seguridads.select.perfil.perfilW_DobleOnda'), name: 'dynatest.resources.seguridads.select.perfil.perfilW_DobleOnda' },
                                        { id: translate('dynatest.resources.seguridads.select.perfil.simple'), name: 'dynatest.resources.seguridads.select.perfil.simple' },
                                ]} 
                                    formClassName={classes.grid_cont4Rigth}
                                    />
                    }
                    {elemento === "/tipo_elementos/DEFMT" &&
                                <SelectInput 
                                    fullWidth 
                                    label="resources.seguridads.fields.defensa" 
                                    source="defensa" 
                                    choices={defensachoices} 
                                    optionText={optionRendererDefensa} 
                                    allowEmpty 
                                    emptyValue="" 
                                    validate={[required()]} 
                                    formClassName={classes.grid_cont4Rigth}
                                    />
                    }
                    {elemento === "/tipo_elementos/DEFMT" &&
                                <NumberInput 
                                    label="resources.seguridads.fields.postes" 
                                    source="postes" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), Entero]} 
                                    formClassName={classes.grid_cont4Rigth}
                                    />
                    }
                    {elemento === "/tipo_elementos/DEFMT" &&
                                <NumberInput 
                                    label="resources.seguridads.fields.captafaros" 
                                    source="captafaros" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), Entero]} 
                                    formClassName={classes.grid_cont4Rigth}
                                    />
                    }
                    {elemento === "/tipo_elementos/DEFMT" &&
                                <NumberInput 
                                    label="resources.seguridads.fields.unidades" 
                                    source="unidades" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), Entero]} 
                                    formClassName={classes.grid_cont4Rigth}
                                    />
                    }
                    {elemento === "/tipo_elementos/DEFMT" &&
                                <NumberInput 
                                    label="resources.seguridads.fields.terminales" 
                                    source="terminales" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), Entero]} 
                                    formClassName={classes.grid_cont4Rigth}
                                    />
                    }
                    {elemento === "/tipo_elementos/DEFMT" &&
                                <NumberInput 
                                    label="resources.seguridads.fields.tornillos" 
                                    source="tornillos" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), Entero]} 
                                    formClassName={classes.grid_cont4Rigth}
                                    />
                    }
                    {elemento === "/tipo_elementos/DEFMT" &&
                                <NumberInput 
                                    label="resources.seguridads.fields.cant_largo" 
                                    source="largo" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), validarUnDecimal]} 
                                    formClassName={classes.grid_cont4Rigth}
                                    />
                            
                    }
                    {elemento === "/tipo_elementos/DEFMT" &&
                                <NumberInput 
                                    label="resources.seguridads.fields.altura" 
                                    source="altura" 
                                    fullWidth 
                                    validate={[required(),minValue(0), maxValue(99999999), validarDosDecimales]} 
                                    formClassName={classes.grid_cont4Rigth}
                                    />
                            
                    }
                     {elemento === "/tipo_elementos/DEFMT" &&
                                <SelectInput 
                                fullWidth 
                                label="resources.seguridads.fields.posteMetalico" 
                                source="posteMetalico" 
                                allowEmpty 
                                emptyValue="" 
                                choices={[
                                    { id: translate('dynatest.resources.seguridads.select.posteMetalico.siId'), name: 'dynatest.resources.seguridads.select.posteMetalico.siName' },
                                    { id: translate('dynatest.resources.seguridads.select.posteMetalico.noId'), name: 'dynatest.resources.seguridads.select.posteMetalico.noName' },
                                ]} 
                                formClassName={classes.grid_cont4Rigth}
                                />
                            
                    }
                    {elemento === "/tipo_elementos/PRTP" &&
                                <SelectInput 
                                    fullWidth 
                                    label="resources.seguridads.fields.material" 
                                    source="material" 
                                    allowEmpty 
                                    emptyValue="" 
                                    validate={[required()]} choices={[
                                        { id: translate('dynatest.resources.seguridads.select.material.barreraJerseyPlasticaId'), name: 'dynatest.resources.seguridads.select.material.barreraJerseyPlastica' },
                                        { id: translate('dynatest.resources.seguridads.select.material.concreto'), name: 'dynatest.resources.seguridads.select.material.concreto' },
                                        { id: translate('dynatest.resources.seguridads.select.material.malla'), name: 'dynatest.resources.seguridads.select.material.malla' },
                                        { id: translate('dynatest.resources.seguridads.select.material.metalicaId'), name: 'dynatest.resources.seguridads.select.material.metalica' },
                                        { id: translate('dynatest.resources.seguridads.select.material.plasticaId'), name: 'dynatest.resources.seguridads.select.material.plastica' },
                                        { id: translate('dynatest.resources.seguridads.select.material.polisombra'), name: 'dynatest.resources.seguridads.select.material.polisombra' },
                                        { id: translate('dynatest.resources.seguridads.select.material.prefabricado'), name: 'dynatest.resources.seguridads.select.material.prefabricado' }

                                    ]} 
                                    formClassName={classes.grid_cont6Right}
                                    />
                    }
                    {elemento === "/tipo_elementos/PRTP" &&
                                <SelectInput 
                                    fullWidth 
                                    label="resources.seguridads.fields.perfil" 
                                    source="perfil" 
                                    allowEmpty 
                                    emptyValue="" 
                                    validate={[required()]} 
                                    choices={[
                                        { id: translate('dynatest.resources.seguridads.select.perfil.perfilC'), name: 'dynatest.resources.seguridads.select.perfil.perfilC' },
                                        { id: translate('dynatest.resources.seguridads.select.perfil.perfilU'), name: 'dynatest.resources.seguridads.select.perfil.perfilU' },
                                        { id: translate('dynatest.resources.seguridads.select.perfil.perfilW_DobleOnda'), name: 'dynatest.resources.seguridads.select.perfil.perfilW_DobleOnda' },
                                        { id: translate('dynatest.resources.seguridads.select.perfil.simple'), name: 'dynatest.resources.seguridads.select.perfil.simple' },
                                    ]} 
                                    formClassName={classes.grid_cont6Right}
                                    />
                    }
                    {elemento === "/tipo_elementos/PRTP" &&
                                <NumberInput 
                                    label="resources.seguridads.fields.ca_largo" 
                                    source="ca_largo" 
                                    fullWidth 
                                    validate={[required(), minValue(0), validarUnDecimal]} 
                                    formClassName={classes.grid_cont3s}
                                    />
                    }
                    {elemento === "/tipo_elementos/PRTP" &&
                                <NumberInput 
                                    label="resources.seguridads.fields.ca_ancho" 
                                    source="ca_ancho" 
                                    fullWidth 
                                    validate={[required(), minValue(0), validarUnDecimal]} 
                                    formClassName={classes.grid_cont4}
                                    />
                    }
                    {elemento === "/tipo_elementos/PRTP" &&
                                <NumberInput 
                                    label="resources.seguridads.fields.ca_altura" 
                                    source="ca_altura" 
                                    fullWidth 
                                    validate={[required(), minValue(0), validarUnDecimal]} 
                                    formClassName={classes.grid_cont4}
                                    />
                    }
                    {elemento === "/tipo_elementos/PRTP" &&
                               <NumberInput 
                               label="resources.seguridads.fields.cant_capta" 
                               source="cant_capta" 
                               fullWidth 
                               validate={[required(), minValue(1), maxValue(99999999), Entero]} 
                               formClassName={classes.grid_cont6Right}
                               />
                    }
                    {elemento === "/tipo_elementos/PRTP" &&
                               <NumberInput 
                               label="resources.seguridads.fields.calibre_mm" 
                               source="calibre_mm" 
                               fullWidth 
                               validate={[required(), minValue(0), maxValue(99999999), Entero]} 
                               formClassName={classes.grid_cont6Right}
                               />
                    }
                    {elemento === "/tipo_elementos/TPVH" &&
                                <SelectInput 
                                    fullWidth 
                                    label="resources.seguridads.fields.material" 
                                    source="material" 
                                    allowEmpty 
                                    emptyValue="" 
                                    validate={[required()]} 
                                    choices={[
                                        { id: translate('dynatest.resources.seguridads.select.material.barreraJerseyPlasticaId'), name: 'dynatest.resources.seguridads.select.material.barreraJerseyPlastica' },
                                        { id: translate('dynatest.resources.seguridads.select.material.concreto'), name: 'dynatest.resources.seguridads.select.material.concreto' },
                                        { id: translate('dynatest.resources.seguridads.select.material.malla'), name: 'dynatest.resources.seguridads.select.material.malla' },
                                        { id: translate('dynatest.resources.seguridads.select.material.metalicaId'), name: 'dynatest.resources.seguridads.select.material.metalica' },
                                        { id: translate('dynatest.resources.seguridads.select.material.plasticaId'), name: 'dynatest.resources.seguridads.select.material.plastica' },
                                        { id: translate('dynatest.resources.seguridads.select.material.polisombra'), name: 'dynatest.resources.seguridads.select.material.polisombra' },
                                        { id: translate('dynatest.resources.seguridads.select.material.prefabricado'), name: 'dynatest.resources.seguridads.select.material.prefabricado' }

                                    ]} 
                                    formClassName={classes.grid_cont4Rigth}
                                />
                    }
                    {elemento === "/tipo_elementos/TPVH" &&
                                <SelectInput 
                                    fullWidth 
                                    label="resources.seguridads.fields.tope" 
                                    source="tope" 
                                    allowEmpty 
                                    emptyValue="" 
                                    validate={[required()]} 
                                    choices={[
                                        { id: translate('dynatest.resources.seguridads.select.tope.horizontal'), name: 'dynatest.resources.seguridads.select.tope.horizontal' },
                                        { id: translate('dynatest.resources.seguridads.select.tope.vertical'), name: 'dynatest.resources.seguridads.select.tope.vertical' },
                                    ]} 
                                    formClassName={classes.grid_cont4Rigth}
                                    />
                    }
                    {elemento === "/tipo_elementos/TPVH" &&
                                <SelectInput 
                                    fullWidth 
                                    label="resources.seguridads.fields.seccion" 
                                    source="seccion" 
                                    allowEmpty 
                                    emptyValue="" 
                                    validate={[required()]} 
                                    choices={[
                                    { id: translate('dynatest.resources.seguridads.select.seccion.circular'), name: 'dynatest.resources.seguridads.select.seccion.circular' },
                                    { id: translate('dynatest.resources.seguridads.select.seccion.rectangular'), name: 'dynatest.resources.seguridads.select.seccion.rectangular' },
                                ]} 
                                formClassName={classes.grid_cont4Rigth}
                                    />
                    }
                    {elemento === "/tipo_elementos/TPVH" &&
                                <NumberInput 
                                    label="resources.seguridads.fields.diametro" 
                                    source="diametro" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), validarDosDecimales]} 
                                    formClassName={classes.grid_cont6Right}
                                    />
                    }
                    {elemento === "/tipo_elementos/TPVH" &&
                                <NumberInput 
                                    label="resources.seguridads.fields.altura" 
                                    source="altura" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), validarDosDecimales]} 
                                    formClassName={classes.grid_cont6Right}
                                    />
                    }
                    {elemento === "/tipo_elementos/TPVH" &&
                                <NumberInput 
                                    label="resources.seguridads.fields.topes" 
                                    source="topes" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), Entero]} 
                                    formClassName={classes.grid_cont6Right}
                                    />
                    }
                    {elemento === "/tipo_elementos/TPVH" &&
                                <NumberInput 
                                    label="resources.seguridads.fields.tramos" 
                                    source="tramos" 
                                    fullWidth 
                                    validate={[required(), minValue(0), maxValue(99999999), validarUnDecimal]} 
                                    formClassName={classes.grid_cont6Right}
                                    />
                    }

                </FormTab>
                <FormTab label="dynatest.resources.seguridads.tabs.tabsTitle.detalles" >
                            <SelectInput label="resources.seguridads.fields.condicionElemento" source="condicionElemento" choices={[
                                { id: translate('dynatest.resources.seguridads.select.condicionElemento.existente'), name: 'dynatest.resources.seguridads.select.condicionElemento.existente' },
                                { id: translate('dynatest.resources.seguridads.select.condicionElemento.reemplazo'), name: 'dynatest.resources.seguridads.select.condicionElemento.reemplazo' },
                                { id: translate('dynatest.resources.seguridads.select.condicionElemento.nuevo'), name: 'dynatest.resources.seguridads.select.condicionElemento.nuevo' },
                            ]} fullWidth validate={[required()]} 
                            onChange={ValidarEstado}
                            formClassName={classes.grid_cont4_f}
                            />
                            {estados === translate('dynatest.resources.seguridads.select.condicionElemento.reemplazo') &&
                                <SelectInput fullWidth source="motivoReemplazo" allowEmpty emptyValue="" choices={[
                                    { id: translate('dynatest.resources.seguridads.select.motivoReemplazo.accidente'), name: 'dynatest.resources.seguridads.select.motivoReemplazo.accidente' },
                                    { id: translate('dynatest.resources.seguridads.select.motivoReemplazo.instalacionId'), name: 'dynatest.resources.seguridads.select.motivoReemplazo.instalacion' },
                                    { id: translate('dynatest.resources.seguridads.select.motivoReemplazo.reemplazoProgramado'), name: 'dynatest.resources.seguridads.select.motivoReemplazo.reemplazoProgramado' },
                                    { id: translate('dynatest.resources.seguridads.select.motivoReemplazo.vandalismo'), name: 'dynatest.resources.seguridads.select.motivoReemplazo.vandalismo' },
                                    { id: translate('dynatest.resources.seguridads.select.motivoReemplazo.otro'), name: 'dynatest.resources.seguridads.select.motivoReemplazo.otro' }
                                ]} 
                                formClassName={classes.grid_cont4_s}
                                />

                            }
                            {estados === translate('dynatest.resources.seguridads.select.condicionElemento.reemplazo') && 
                                // <ElementosCreados 
                                //     tabla="seguridads" 
                                //     tipo="create" 
                                //     elementos={elemento}
                                //     fullWidth 
                                //     formClassName={classes.grid_cont4Rigth} 
                                // />

                                <ReferenceInput
                                    label="resources.seguridads.fields.elementoReemplazo_id"
                                    source="elementoReemplazo_id"
                                    reference="seguridads"
                                    filter={{
                                        tipoElemento : elemento,
                                        estado : "Activo",
                                        unidadFuncional: unidads,
                                        tramo: tramos,
                                        segmento : segmentos
                                    }}
                                    filterToQuery={searchText => ({ fullCodigo: searchText })} 
                                    fullWidth
                                    formClassName={classes.grid_cont4_s}
                                >
                                    <AutocompleteInput options={{fullWidth: true}} optionText="smartCode" />
                                </ReferenceInput>
                            }
                            <br></br>
                            
                            <SelectInput label="resources.seguridads.fields.estado" source="estado" choices={[
                                { id: translate('dynatest.resources.seguridads.select.estado.activo'), name: 'dynatest.resources.seguridads.select.estado.activo' },
                                { id: translate('dynatest.resources.seguridads.select.estado.inactivo'), name: 'dynatest.resources.seguridads.select.estado.inactivo' },
                            ]} fullWidth validate={[required()]} 
                            formClassName={classes.grid_cont4_f}
                            />
                            <br></br>
                            <DateInput 
                                label="resources.seguridads.fields.fechaInstalacion" 
                                source="fechaInstalacion" 
                                fullWidth 
                                validate={[required(), isFechaInvalida]} 
                                formClassName={classes.grid_cont3_f}
                                />
                            <FormDataConsumer formClassName={classes.grid_cont6_s}>
                                {
                                    ({ formData, ...rest }) => (
                                        
                                        <ReferenceInput 
                                            label="resources.seguridads.fields.proveedor" 
                                            source="proveedor" 
                                            reference="proveedors" 
                                            filter={{ 
                                                proyecto: props.proyecto, sistema: 'SEG' 
                                            }}
                                            sort={{ field: 'razonSocial', order: 'ASC' }}
                                            filterToQuery={searchText => ({ razonSocial: searchText })} 
                                            validate={[required()]}
                                        >
                                            <AutocompleteInput  options={{fullWidth : true}} optionText="razonSocial"  />
                                        </ReferenceInput>
                                    )
                                }
                            </FormDataConsumer>
                            <FormDataConsumer formClassName={classes.grid_cont3_s}>
                                {
                                    ({ formData, ...rest }) => (
                                        <NumberInput 
                                        label="resources.seguridads.fields.garantia" 
                                        source="garantia" 
                                        fullWidth 
                                        validate={[required(), Entero, minValue(0)]} 
                                    />
                                    )
                                }
                            </FormDataConsumer>
                            <BooleanInput 
                                label="dynatest.resources.seguridads.boolean.mantenimiento" 
                                    source="mantenimiento" 
                                    fullWidth
                                    formClassName={classes.grid_cont12}
                                    />
                            <TextInput 
                                label="resources.seguridads.fields.observaciones" 
                                source="observaciones" 
                                fullWidth validate={[required(), maxLength(200, "dynatest.resources.seguridads.messageWarning.observaciones")]} 
                                formClassName={classes.grid_cont12}
                                />
                    <TextInput source="createdIn" style={{ display: 'none' }} defaultValue={"api"} />
                    <TextInput source="updatedIn" style={{ display: 'none' }} defaultValue={"api"} />
                    <TextInput source="aprobacion" style={{ display: 'none' }} defaultValue={"Aprobado"} />
                </FormTab>
                <FormTab label="dynatest.resources.seguridads.tabs.tabsTitle.fotografias">
                    <ImagenesInventario type='create' defaultTipoImagen={defaultTipoImagen}/>                                   
                </FormTab>
            </TabbedForm>
        </PimsCreate>
    )
}));