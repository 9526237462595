import React, { Component, Fragment } from 'react';
import { FileInput, TextInput, TextField, FormDataConsumer, required } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import config from '../../../config/config';
import loader from '../../../loader10.gif';
import Grid from '@material-ui/core/Grid';
import { translate } from 'react-admin';
import extractGoogleCoords from '../../Mapa/localhelpers/extractGoogleCoords';
let urlAppjs = config.UrlServer;
let descRespuesta = "";

class Peso extends Component {
    constructor(props) {
        super(props)
        this.state = { loaded: false, respuestas: "", tipoCampo: "" }
        this.state.respuestas = this.props.record.respuesta;
        this.state.tipoCampo = this.props.record.pregunta_.tipoCampo;
        this.state.respuestaIndicadorComplementario = this.props.record.pregunta_.respuestaIndicadorComplementario;
    }
    componentDidMount() {
    }

    render() {
        const { translate } = this.props;
        if (this.state.loaded === false && this.state.tipoCampo === "Selección Múltiple") {
            let respuestaSelMul;
            this.state.respuestaIndicadorComplementario.forEach(element => {
                if (element.originId == this.state.respuestas)
                    respuestaSelMul = element.peso
            });
            return (
                <div>
                    <p name="peso" style={{ fontSize: 0.875 + 'rem', textAlign: 'right', fontWeight: 400, fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }}>
                        {respuestaSelMul} </p>
                </div>
            )
        } else {
                let peso; 
                if( (this.state.respuestas >= this.props.record.pregunta_.rangoInicial) && (this.state.respuestas <= this.props.record.pregunta_.rangoFinal) ) {
                    peso = this.props.record.pregunta_.peso;
                }else{
                    peso = 0;
                }     
            return (
                <div>
                    <p name="peso" style={{ fontSize: 0.875 + 'rem', textAlign: 'right', fontWeight: 400, fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }}>
                        {peso} </p>
                </div>
            )
        }
    }
}

export default Peso;