import React, { useState, useEffect } from 'react';
import {
    ArrayInput, SimpleFormIterator, NumberInput, ChipField, ReferenceArrayField,
    Datagrid, TabbedForm, FormTab, TextInput, SelectInput, SimpleList, SingleFieldList,
    ShowController, ShowView, SelectField, TextField, DateField, ArrayField,
    ReferenceField, NumberField, BooleanField, FormDataConsumer
} from 'react-admin';
import { withStyles } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import { PRField } from './../../fields/PRField';
import Divider from '@material-ui/core/Divider';
import { GMapField } from './../../fields/GMapField';
import Typography from '@material-ui/core/Typography';
import { styles } from './../../EstilosGrid';
import { ButtonCarousel } from './../images/ButtonCarousel';
import { ShowToolbar } from './../componentes/ShowToolbar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import config from '../../config/config';
import Respuestas from './preguntasRespuestas/complementoRespuestas';
import Peso from './preguntasRespuestas/complementoPeso';
import PesoTotal from './preguntasRespuestas/complementoPesoTotal';
import { translate } from 'react-admin';

let urlAppjs = config.UrlServer;

export const RespuestaEvaluacionComplementariosShow = translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;
    //const { pregunta } = props;
    const { respuesta } = props;
    const { peso } = props;
    const { pesoTotal } = props;

    const [imagen, setImagen] = useState(true);
    const [latitud, setLatitud] = useState();
    const [longitud, setLongitud] = useState();
    const Latitud = (value) => {
        setLatitud(value)
    }
    const Longitud = (value) => {
        setLongitud(value)
    }
    const ValidarImagen = (value) => {
        // console.log(value + "Aqui est la imagen ")
        if (value !== null && value !== undefined && value !== 'undefined') {
            let lenghtObject = Object.keys(value);
            let valor = '';
            for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
            setImagen(value)
            // console.log(value + "Aqui est la imagen ")
        }
    }

    const [tipo_elementos, setTipoElementos, tipo_elemento_senals] = useState('');
    const ValidarTipoElementos = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setTipoElementos(value);
    }

    const [sistema, setSistema] = useState(null);
    const ValidarSistema = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setSistema(value);
    }
    const TagsField = ({ record }) => (
        console.log(record) 
        // <ul>
        //     {record.tags.map(item => (
        //         <li key={item.name}>{item.name}</li>
        //     ))}
        // </ul>
    )
    return (
        <ShowController {...props} >
            {controllerProps =>
                <ShowView {...props} {...controllerProps} title="dynatest.resources.respuesta_evaluacion_complementarios.title.showTitle" actions={<ButtonCarousel />}> 
                    <TabbedForm toolbar={false}>
                        <FormTab label="dynatest.resources.respuesta_evaluacion_complementarios.tabs.filtro" >
                            <TextInput source="src" validate={ValidarImagen} style={{ display: 'none' }} />
                            <TextInput source="respuestasPreguntasComplementarios" style={{ display: 'none' }} />
                            <ReferenceField
                                label="resources.respuesta_evaluacion_complementarios.fields.unidadFuncional"
                                source="unidadFuncional"
                                reference="unidad_funcionals"
                                linkType={false}
                                fullWidth
                                formClassName={classes.grid_cont4}
                            >
                                <TextField source="fullCodigos" />
                            </ReferenceField>
                            <ReferenceField label="resources.respuesta_evaluacion_complementarios.fields.tramo" source="tramo" reference="tramos" linkType={false} fullWidth formClassName={classes.grid_cont3}>
                                <TextField source="fullCodigos" />
                            </ReferenceField>
                            <ReferenceField label="resources.respuesta_evaluacion_complementarios.fields.segmento" source="segmento" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont3}>
                                <TextField source="fullCodigos" />
                            </ReferenceField>
                            <ReferenceField label="resources.respuesta_evaluacion_complementarios.fields.sistema" name="sistemas" source="sistema" reference="sistemas" fullWidth formClassName={classes.grid_cont4} linkType={false} >
                                <TextField source="nombre" />
                            </ReferenceField>
                            <TextField label="resources.respuesta_evaluacion_complementarios.fields.tipoElemento" source="genericTipoElemento" linkType={false} fullWidth formClassName={classes.grid_cont4} />

                            {controllerProps.record && controllerProps.record.tipoElemento &&
                                <TextField label="resources.respuesta_evaluacion_complementarios.fields.codigo" source="smartCode" linkType={false} fullWidth formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal &&
                                <TextField label="resources.respuesta_evaluacion_complementarios.fields.smartCode" source="smartCode" linkType={false} fullWidth formClassName={classes.grid_cont4} />
                            }
                        </FormTab>
                        <FormTab label="dynatest.resources.respuesta_evaluacion_complementarios.tabs.registro">
                            <br></br>
                            <DateField source="fechaMedicion" label="resources.respuesta_evaluacion_complementarios.fields.fechaMedicion" fullWidth formClassName={classes.grid_cont3} />
                            <TextField source="accion" label="resources.respuesta_evaluacion_complementarios.fields.accion" fullWidth formClassName={classes.grid_cont3} />
                            <br></br>
                            <TextField source="estado" name="estado" label="resources.respuesta_evaluacion_complementarios.fields.estado" fullWidth formClassName={classes.grid_cont4} />
                            <TextField source="aprobacion" label="resources.respuesta_evaluacion_complementarios.fields.aprobacion" fullWidth formClassName={classes.grid_cont4} />
                            <TextField source="observaciones" label="resources.respuesta_evaluacion_complementarios.fields.observaciones" fullWidth formClassName={classes.grid_cont3} />
                            <br></br>

                            <span><b>{translate('dynatest.resources.respuesta_evaluacion_complementarios.subTitle.resultados')}</b></span>
                            <PesoTotal pesoTotal={pesoTotal}/>

                            <br />
                            <Typography variant="b" component="b"><b>{translate('dynatest.resources.respuesta_evaluacion_complementarios.subTitle.indicadores')}</b></Typography>
                            <Divider fullWidth />

                            <ArrayField label="" source="respuestasPreguntasComplementarios" fullWidth formClassName={classes.grid_cont12}>
                                <Datagrid>
                                    <TextField source="pregunta_.indicadorComplementario_.nombre" label="resources.respuesta_evaluacion_complementarios.fields.indicadores"
                                        fullWidth
                                        formClassName={classes.grid_cont3}
                                        linkType={false} 
                                        style={{ borderRight: '1px solid rgba(224, 224, 224, 1)', padding:'12px'}}
                                    />
                                    <TextField source="pregunta_.pregunta" label="resources.respuesta_evaluacion_complementarios.fields.pregunta"
                                        fullWidth
                                        formClassName={classes.grid_cont3}
                                        linkType={false} 
                                        style={{ borderRight: '1px solid rgba(224, 224, 224, 1)', padding:'12px'}}
                                    />
                                    <Respuestas label="resources.respuesta_evaluacion_complementarios.fields.respuesta" pregunta={respuesta} />
                                    <Peso label="resources.respuesta_evaluacion_complementarios.fields.peso" peso={peso} />                                
                                </Datagrid>
                            </ArrayField>


                        </FormTab>
                        <FormTab label="dynatest.resources.respuesta_evaluacion_complementarios.tabs.auditoria" >
                            <br></br>
                            <DateField source="createdAt" label="resources.respuesta_evaluacion_complementarios.fields.createdAt" fullWidth formClassName={classes.grid_cont3} showTime/>
                            <TextField source="createdBy" label="resources.respuesta_evaluacion_complementarios.fields.createdBy" fullWidth formClassName={classes.grid_cont3} />
                            <span></span>
                            <DateField source="updatedAt" label="resources.respuesta_evaluacion_complementarios.fields.updatedAt" fullWidth formClassName={classes.grid_cont3} showTime/>
                            <TextField source="updatedBy" label="resources.respuesta_evaluacion_complementarios.fields.updatedBy" fullWidth formClassName={classes.grid_cont3} />
                            <span></span>
                        </FormTab>
                    </TabbedForm>
                </ShowView>
            }
        </ShowController>

    )
}
));
const WithProps = ({ children, ...props }) => children(props);