import React from 'react';
import { FormTab,required,TextInput, SelectInput } from 'react-admin';
import { PimsCreate } from './../Create';
import { TabbedForm } from './../TabbedForm';
import Grid from '@material-ui/core/Grid';
import  {styles}  from './../../EstilosGrid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core';
import { translate } from 'react-admin';
import UniqueAttribute from './../componentes/UniqueAttribute';

const opciones = [
    { id: 1, name: 'dynatest.resources.atributos.select.acceso.acceso_completo' },
    { id: 2, name: 'dynatest.resources.atributos.select.acceso.acceso_parcial'  },
    { id: 3, name: 'dynatest.resources.atributos.select.acceso.sin_acceso'  },
    { id: 4, name: 'dynatest.resources.atributos.select.acceso.visualiza' }
];

const opciones2 = [
    { id: 1, name: 'dynatest.resources.atributos.select.acceso.acceso_completo' },
    { id: 3, name: 'dynatest.resources.atributos.select.acceso.sin_acceso'  },
];

export const AtributosCreate = translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;
    return(
    <PimsCreate {...props}>
      <TabbedForm redirect="list" >
        <FormTab label="dynatest.resources.atributos.formTabs.administracion" submitOnEnter={false} >                
                
            {/* <TextInput   fullWidth validate={[required()]} /> */}
            <UniqueAttribute 
                id="rol"
                label="resources.atributos.fields.nombre"
                source="nombre"
                formClassName={classes.grid_cont4s}
            />
                <SelectInput source="tipoRol"  choices={[
                    { id: "Administrativo", name: 'dynatest.resources.atributos.select.tipo_rol.administrativo' },
                    { id: "Campo", name: 'dynatest.resources.atributos.select.tipo_rol.campo' },
                    { id: "Oficina", name: 'dynatest.resources.atributos.select.tipo_rol.oficina' }, 
                ]} fullWidth validate={[required()]} sort={{ field: 'id', order: 'ASC' }} formClassName={classes.grid_cont4} />
            
                <SelectInput source="usuario"  choices={opciones} optionText="name" fullWidth validate={[required()]} formClassName={classes.grid_cont4}/>
            
                <SelectInput source="perfiles"  choices={opciones} optionText="name" fullWidth validate={[required()]} formClassName={classes.grid_cont4s}/>
        
                {/* <SelectInput source="asigclaves"  choices={[
                    { id: 1, name: 'dynatest.resources.atributos.select.acceso.acceso_completo' },
                    
                    { id: 3, name: 'dynatest.resources.atributos.select.acceso.sin_acceso' },
                    
                ]} fullWidth validate={[required()]} formClassName={classes.grid_cont4}/> */}

                <Grid container spacing={24}>  
                    <Grid item xs={12}>
                        <Typography variant="h3" component="h3" className={classes.coord}>
                        {translate('dynatest.resources.atributos.title.configGeneral')}</Typography>                    
                        <Divider fullWidth style={{marginTop:'10px'}}/>  
                    </Grid>
                </Grid>
                <SelectInput source="idioma" choices={opciones} fullWidth validate={[required()]} formClassName={classes.grid_cont4s}/>
                <SelectInput source="configSistema" label="resources.atributos.fields.configSistema" choices={opciones} fullWidth validate={[required()]} formClassName={classes.grid_cont4}/>

                <Grid container spacing={24}>  
                    <Grid item xs={12}>
                        <Typography variant="h3" component="h3" className={classes.coord}>
                        {translate('dynatest.resources.atributos.title.configReports')}</Typography>                    
                        <Divider fullWidth style={{marginTop:'10px'}}/>  
                    </Grid>
                </Grid>
                <SelectInput source="reportesConfig" choices={opciones} fullWidth validate={[required()]} formClassName={classes.grid_cont4s}/>                
     
                <Grid container spacing={24}>  
                    <Grid item xs={12}>
                        <Typography variant="h3" component="h3" className={classes.coord}>
                        {translate('dynatest.resources.atributos.title.configAudit')}</Typography>                    
                        <Divider fullWidth style={{marginTop:'10px'}}/>  
                    </Grid>
                </Grid>
                <SelectInput source="audit" choices={opciones} fullWidth validate={[required()]} formClassName={classes.grid_cont4s}/>
            </FormTab>  
                
            <FormTab label="dynatest.resources.atributos.formTabs.proyecto" submitOnEnter={false} redirect="list">    
                
                <Grid container spacing={24}>  
                    <Grid item xs={12}>
                        <Typography variant="h3" component="h3" className={classes.coord}>
                            {translate('dynatest.resources.atributos.title.proyectos')}</Typography>                    
                        <Divider fullWidth style={{marginTop:'10px'}}/>  
                    </Grid>
                </Grid>

                <SelectInput source="proyecto"  choices={opciones} optionText="name"  fullWidth validate={[required()]} formClassName={classes.grid_cont4s}/>
                
                <SelectInput source="rutas" choices={opciones} optionText="name"  fullWidth validate={[required()]} formClassName={classes.grid_cont4}/>
             
                <SelectInput source="unifuncionales"  choices={opciones} optionText="name"  fullWidth validate={[required()]} formClassName={classes.grid_cont4}/>
                <SelectInput source="tramos"  choices={opciones} optionText="name"  fullWidth validate={[required()]} formClassName={classes.grid_cont4s}/>
                
                <SelectInput source="segmento"  choices={opciones} optionText="name"  fullWidth validate={[required()]} formClassName={classes.grid_cont4}/>

                <Grid container spacing={24}>  
                    <Grid item xs={12}>
                        <Typography variant="h3" component="h3" className={classes.coord}>
                            {translate('dynatest.resources.atributos.title.configMateriales')}</Typography>                    
                        <Divider fullWidth style={{marginTop:'10px'}}/>  
                    </Grid>
                </Grid>
                <SelectInput source="materiales"  choices={opciones} optionText="name"  fullWidth validate={[required()]} formClassName={classes.grid_cont4s}/>
                <SelectInput source="ganulometria"  choices={opciones} optionText="name"  fullWidth validate={[required()]} formClassName={classes.grid_cont4}/>

                <Grid container spacing={24}>  
                    <Grid item xs={12}>
                        <Typography variant="h3" component="h3" className={classes.coord}>
                        {translate('dynatest.resources.atributos.title.configIndicadores')}</Typography>                    
                        <Divider fullWidth style={{marginTop:'10px'}}/>  
                    </Grid>
                </Grid>
                <SelectInput source="parametrosEvaluacion"  choices={opciones} optionText="name"  fullWidth validate={[required()]} formClassName={classes.grid_cont4s}/>
                <SelectInput source="diagramaColorimetrico"  choices={opciones} optionText="name"  fullWidth validate={[required()]} formClassName={classes.grid_cont4}/>
                <SelectInput source="indicadorComplementario"  choices={opciones} optionText="name"  fullWidth validate={[required()]} formClassName={classes.grid_cont4}/>

                <Grid container spacing={24}>  
                    <Grid item xs={12}>
                        <Typography variant="h3" component="h3" className={classes.coord}>
                        {translate('dynatest.resources.atributos.title.configDeterioroPers')}</Typography>                    
                        <Divider fullWidth style={{marginTop:'10px'}}/>  
                    </Grid>
                </Grid>
                <SelectInput source="reparaciones"  choices={opciones} optionText="name"  fullWidth validate={[required()]} formClassName={classes.grid_cont4s}/>
                <SelectInput source="deterioros"  choices={opciones} optionText="name"  fullWidth validate={[required()]} formClassName={classes.grid_cont4}/>
                
                <Grid container spacing={24}>  
                    <Grid item xs={12}>
                        <Typography variant="h3" component="h3" className={classes.coord}>
                        {translate('dynatest.resources.atributos.title.configNovedades')}</Typography>                    
                        <Divider fullWidth style={{marginTop:'10px'}}/>  
                    </Grid>
                </Grid>
                <SelectInput source="registroNovedad" label="resources.atributos.fields.novedades" choices={opciones} optionText="name"  fullWidth validate={[required()]} formClassName={classes.grid_cont4s}/>


                <Grid container spacing={24}>  
                    <Grid item xs={12}>
                        <Typography variant="h3" component="h3" className={classes.coord}>
                            {translate('dynatest.resources.atributos.title.contactos')}</Typography>                    
                        <Divider fullWidth style={{marginTop:'10px'}}/>  
                    </Grid>
                </Grid>

                <SelectInput source="contemergencia" choices={opciones} optionText="name"  fullWidth validate={[required()]} formClassName={classes.grid_cont4s}/>
                
                <SelectInput source="proveedores" choices={opciones} optionText="name"  fullWidth validate={[required()]}formClassName={classes.grid_cont4}/>
                
                <SelectInput source="serpublicos"  choices={opciones} optionText="name"  fullWidth validate={[required()]} formClassName={classes.grid_cont4}/>
        
            </FormTab>
            <FormTab label="dynatest.resources.atributos.formTabs.inventario">
            
                <SelectInput source="mapa"  choices={[
                        { id: 1, name: 'dynatest.resources.atributos.select.acceso.acceso_completo' },
                        { id: 3, name: 'dynatest.resources.atributos.select.acceso.sin_acceso' },
                ]} fullWidth validate={[required()]} formClassName={classes.grid_cont3s}/>

                <Grid container spacing={24}>  
                    <Grid item xs={12}>
                        <Typography variant="h3" component="h3" className={classes.coord}>
                        {translate('dynatest.resources.atributos.title.cargaMasiva')}</Typography>                    
                        <Divider fullWidth style={{marginTop:'10px'}}/>  
                    </Grid>
                </Grid>
                
                <SelectInput source="cargaMasiva"  choices={opciones} optionText="name"  fullWidth validate={[required()]} formClassName={classes.grid_cont3s}/>
                
                <Grid container spacing={24}>  
                    <Grid item xs={12}>
                        <Typography variant="h3" component="h3" className={classes.coord}>
                        {translate('dynatest.resources.atributos.title.segmentos')}</Typography>                    
                        <Divider fullWidth style={{marginTop:'10px'}}/>  
                    </Grid>
                </Grid>
                
                <SelectInput source="flexible"  choices={opciones} optionText="name"  fullWidth validate={[required()]} formClassName={classes.grid_cont3s}/>
                
                <SelectInput source="rigido"  choices={opciones} optionText="name"  fullWidth validate={[required()]} formClassName={classes.grid_cont3}/>
                
                <SelectInput source="adoquin"  choices={opciones} optionText="name"  fullWidth validate={[required()]} formClassName={classes.grid_cont3}/>
                
                <SelectInput source="otro"  choices={opciones} optionText="name"  fullWidth validate={[required()]} formClassName={classes.grid_cont3}/>
                                
                <Grid container spacing={24}>  
                    <Grid item xs={12} alignItems="center">
                        <div style={{textAlign : "center", width : "100%"}}>
                            <Typography variant="h3" component="h3" className={classes.coord}>
                            {translate('dynatest.resources.atributos.title.elemInventario')}</Typography> 
                        </div>                   
                        <Divider fullWidth style={{marginTop:'10px'}}/>  
                    </Grid>
                </Grid>
            
                <SelectInput source="seguridad"  choices={opciones} optionText="name"  fullWidth validate={[required()]} formClassName={classes.grid_cont4s}/>
                <SelectInput source="drenaje"  choices={opciones} optionText="name"  fullWidth validate={[required()]} formClassName={classes.grid_cont4}/>
                <SelectInput source="senalizacion"  choices={opciones} optionText="name"  fullWidth validate={[required()]} formClassName={classes.grid_cont4}/>
                <SelectInput source="alrededores"  choices={opciones} optionText="name"  fullWidth validate={[required()]} formClassName={classes.grid_cont4s}/>
                <SelectInput source="otroElemento"  choices={opciones} optionText="name"  fullWidth validate={[required()]} formClassName={classes.grid_cont4}/>
                <SelectInput source="redes"  choices={opciones} optionText="name"  fullWidth validate={[required()]} formClassName={classes.grid_cont4}/>
                <Grid container spacing={24}>  
                    <Grid item xs={12}>
                        <Typography variant="h3" component="h3" className={classes.coord}>
                        {translate('dynatest.resources.atributos.title.puentesEstructurasEspec')}</Typography>                    
                        <Divider fullWidth style={{marginTop:'10px'}}/>  
                    </Grid>
                </Grid>
                <SelectInput source="puentes"  choices={opciones} optionText="name"  fullWidth validate={[required()]} formClassName={classes.grid_cont4s}/>
                <SelectInput source="contencion"  choices={opciones} optionText="name"  fullWidth validate={[required()]} formClassName={classes.grid_cont4}/>
                <SelectInput source="ponton"  choices={opciones} optionText="name"  fullWidth validate={[required()]} formClassName={classes.grid_cont4}/>
                <SelectInput source="tunel"  choices={opciones} optionText="name"  fullWidth validate={[required()]} formClassName={classes.grid_cont4s}/>
            </FormTab> 
            <FormTab label="dynatest.resources.atributos.formTabs.transito">

                <SelectInput source="inventario"  choices={opciones} optionText="name"  fullWidth validate={[required()]} formClassName={classes.grid_cont4s}/>
                <SelectInput source="transitoMensual"  choices={opciones} optionText="name"  fullWidth validate={[required()]} formClassName={classes.grid_cont4}/>
                <SelectInput source="inventarioa"  choices={opciones} optionText="name"  fullWidth validate={[required()]} formClassName={classes.grid_cont4}/>
                {/* <SelectInput label="Ejes Equivalentes" source="transitoMensuala"  choices={opciones} optionText="name"  fullWidth validate={[required()]} formClassName={classes.grid_cont4}/> */}
                {/* <SelectInput label="Deterioros" source="tasaCrecimiento"  choices={opciones} optionText="name"  fullWidth validate={[required()]} formClassName={classes.grid_cont4}/> */}
            </FormTab> 
            <FormTab label="dynatest.resources.atributos.formTabs.indicadores">
                <SelectInput source="indicadores4g"  choices={opciones} fullWidth validate={[required()]} formClassName={classes.grid_cont3s}/>
                {/* <SelectInput source="datosBasicos"  choices={opciones} optionText="name"  fullWidth validate={[required()]} formClassName={classes.grid_cont3}/> */}
                {/* <SelectInput source="colaDePeaje"  choices={opciones} optionText="name"  fullWidth validate={[required()]} formClassName={classes.grid_cont3}/> */}
                {/* <SelectInput source="calificacionPuentes" choices={opciones} optionText="name"  fullWidth validate={[required()]} formClassName={classes.grid_cont3}/> */}
                {/* <SelectInput source="calificacionPontones"  choices={opciones} optionText="name"  fullWidth validate={[required()]} formClassName={classes.grid_cont3s}/> */}
                <SelectInput source="respuestaEvaluacionComplementario" label='dynatest.resources.atributos.title.complementarios' choices={opciones} optionText="name"  fullWidth validate={[required()]} formClassName={classes.grid_cont3}/>
                <SelectInput source="disponibilidadVia"  choices={opciones} optionText="name"  fullWidth validate={[required()]} formClassName={classes.grid_cont3}/>
                {/* <SelectInput source="colaDePeajea"  choices={opciones} optionText="name"  fullWidth validate={[required()]} formClassName={classes.grid_cont3}/> */}

            </FormTab>

            <FormTab label="dynatest.resources.atributos.formTabs.gestion">
                <Grid container spacing={24}>  
                    <Grid item xs={12}>
                        <Typography variant="h3" component="h3" className={classes.coord}>
                        {translate('dynatest.resources.atributos.title.programacion')}</Typography>                    
                        <Divider fullWidth style={{marginTop:'10px'}}/>  
                    </Grid>
                </Grid>
                <SelectInput source="parametrosAnalisis"  choices={opciones} fullWidth validate={[required()]} formClassName={classes.grid_cont3s}/>
                
                <Grid container spacing={24}>  
                    <Grid item xs={12}>
                        <Typography variant="h3" component="h3" className={classes.coord}>
                        {translate('dynatest.resources.atributos.title.otrosSistemas')}</Typography>                    
                        <Divider fullWidth style={{marginTop:'10px'}}/>  
                    </Grid>
                </Grid>  
                <SelectInput source="politicaGeneral"  choices={opciones}  fullWidth validate={[required()]} formClassName={classes.grid_cont3s}/>
                <SelectInput source="politicaParticular"  choices={opciones}  fullWidth validate={[required()]} formClassName={classes.grid_cont3}/>

                <Grid container spacing={24}>  
                    <Grid item xs={12}>
                        <Typography variant="h3" component="h3" className={classes.coord}>
                        {translate('dynatest.resources.atributos.title.pavimentoPers')}</Typography>                    
                        <Divider fullWidth style={{marginTop:'10px'}}/>  
                    </Grid>
                </Grid>  
                <SelectInput source="alternativaIntervencion"  choices={opciones}  fullWidth validate={[required()]} formClassName={classes.grid_cont3s}/>
                <SelectInput source="reparacionesMenores"  choices={opciones}  fullWidth validate={[required()]} formClassName={classes.grid_cont3}/>
                <SelectInput source="exportarPers"  choices={[
                    { id: 1, name: 'dynatest.resources.atributos.select.acceso.acceso_completo' },
                    { id: 3, name: 'dynatest.resources.atributos.select.acceso.sin_acceso' },
                ]}  fullWidth validate={[required()]} formClassName={classes.grid_cont3}/>
                <SelectInput source="reportesPers"  choices={[
                   { id: 1, name: 'dynatest.resources.atributos.select.acceso.acceso_completo' },
                   { id: 3, name: 'dynatest.resources.atributos.select.acceso.sin_acceso' },
                ]}  fullWidth validate={[required()]} formClassName={classes.grid_cont3}/>


                <Grid container spacing={24}>  
                    <Grid item xs={12}>
                        <Typography variant="h3" component="h3" className={classes.coord}>
                        {translate('dynatest.resources.atributos.title.registroCostos')}</Typography>                    
                        <Divider fullWidth style={{marginTop:'10px'}}/>  
                    </Grid>
                </Grid>  
                <SelectInput source="costo" label='resources.atributos.fields.costo' choices={opciones}  fullWidth validate={[required()]} formClassName={classes.grid_cont3s}/>
                
                <Grid container spacing={24}>  
                    <Grid item xs={12}>
                        <Typography variant="h3" component="h3" className={classes.coord}>
                        {translate('dynatest.resources.atributos.title.flujoCaja')}</Typography>                    
                        <Divider fullWidth style={{marginTop:'10px'}}/>  
                    </Grid>
                </Grid>  
                <SelectInput source="flujoCaja"  choices={opciones}  fullWidth validate={[required()]} formClassName={classes.grid_cont3s}/>
                
                

            </FormTab> 

            <FormTab label="dynatest.resources.atributos.formTabs.aplicacionMovil">
                <SelectInput 
                    source="respuestaEvaluacionNovedad"  
                    label='dynatest.resources.atributos.title.respuestaEvaluacionNovedad'
                    choices={opciones} 
                    optionText="name"  fullWidth 
                    validate={[required()]} 
                    formClassName={classes.grid_cont3}/>
                <SelectInput 
                    source="aprobacion" 
                    label='dynatest.resources.atributos.title.aprobacion' 
                    choices={opciones2} fullWidth 
                    validate={[required()]} 
                    formClassName={classes.grid_cont3}/>
                
            </FormTab> 

            <FormTab label="dynatest.resources.atributos.formTabs.reportesQS">
                <SelectInput source="reportesPersQ"  choices={opciones} fullWidth validate={[required()]} formClassName={classes.grid_cont3s}/>
                <SelectInput source="reportesInventarioQ"  choices={opciones} optionText="name"  fullWidth validate={[required()]} formClassName={classes.grid_cont3}/>
                <SelectInput source="reportesIndicadoresQ"  choices={opciones} optionText="name"  fullWidth validate={[required()]} formClassName={classes.grid_cont3}/>
                <SelectInput source="reportesCostosQ"  choices={opciones} optionText="name"  fullWidth validate={[required()]} formClassName={classes.grid_cont3}/>
                <SelectInput source="reportesTransitoQ"  choices={opciones} optionText="name"  fullWidth validate={[required()]} formClassName={classes.grid_cont3s}/>
                <SelectInput source="reportesContactosQ"  choices={opciones} optionText="name"  fullWidth validate={[required()]} formClassName={classes.grid_cont3}/>
                <SelectInput source="reportesModelacionQ"  choices={opciones} optionText="name"  fullWidth validate={[required()]} formClassName={classes.grid_cont3}/>
                {/* <SelectInput label="Reportes Novedades" source="reportesNovedadesQ"  choices={opciones} optionText="name"  fullWidth validate={[required()]} formClassName={classes.grid_cont3s}/> */}

            </FormTab> 
        </TabbedForm>  
    </PimsCreate>
    )
}));