import React, { useState, useEffect, Fragment } from 'react'
import { MapWithAMarker } from './../../inputs/GMapInventario';
import Grid from '@material-ui/core/Grid';
import TuneIcon from '@material-ui/icons/Tune';
import SvgIcon from '@material-ui/core/SvgIcon';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import brujula from './imgAux/Brujula.svg.png'
import {
    List,
    SimpleForm,
    Query,
    TextInput,
    AutocompleteArrayInput,
    ReferenceArrayInput,
    ReferenceInput,
    AutocompleteInput,
    FormDataConsumer,
    CheckboxGroupInput,
    ArrayInput,
    NumberInput,
    SimpleFormIterator,
    Loading,
    SelectInput,
    BooleanInput, CardActions
} from 'react-admin';
//import {shapefile} from 'shapefile';
import { REDUX_FORM_NAME } from 'react-admin';
import { change } from 'redux-form';
import { array } from 'prop-types';
import MapaGeneral from './localhelpers/MapaGeneral'
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BotonFiltro from './localhelpers/ElementoConfigFiltro'
import config from '../../config/config';
import { translate } from 'react-admin';
let urlAppjs = config.UrlServer;
export const MapaGenral = translate(props => {
    const { translate } = props;
    // Selección de tabla
    const [tablas, setTabla] = useState('elemento_alrededors');
    const [index, setIndex] = useState('elemento_alrededors');
    const SeleccionTabla = (value) => {
        if (value !== undefined) {
            let cantidad = value.length
            setIndex(cantidad)
        }
        setTabla(value)

    }
 
    //Fin Selección tabla
    //seteo de coordenadas de proyecto
    const [coordenadasProyec, setCoordenadasProyec] = useState([]);
    //fin de seteo de coordenadas de proyeto
    //Valores de las propiedades del Mapa
    const [ubicaciones, setUbicaciones] = useState();


    const [unidadFuncional, setUnidadFuncional] = useState();

    const [conteo, setConteo] = useState(0);
    const UnidadFuncional = (value) => {
        setUnidadFuncional(value)
        //  setTabla('elemento_senalizacion_perus')
        if (value !== undefined)
            setConteo(1)
    }

    const [tramos, setTramo] = useState();
    const Tramo = (value) => {
        setTramo(value)
        if (value !== undefined)
            setConteo(1)
    }

    const [segmentos, setSegmento] = useState();
    const Segmento = (value) => {
        setSegmento(value)
        if (value !== undefined)
            setConteo(1)
    }

    const [abInicio, setAbInicio] = useState();
    const AbInicio = (value) => {

        if (value !== null && value !== '' && value !== undefined) {
            let valor = value;
            //  valor =valor.value;
            let valor1 = valor.split('K')
            valor1 = valor1[1].split('+')
            valor = valor1[0] + '.' + valor1[1]
            setAbInicio(valor)
            setTramo(tramos)
            setabInicioA('abInicio[gt]')

        }
    }

    const [abFin, setAbFin] = useState(0);
    const AbFin = (value) => {
        if (value !== null && value !== '') {
            let valor = document.getElementById('abFin');
            valor = valor.value;
            let valor1 = valor.split('K')
            valor1 = valor1[1].split('+')
            valor = valor1[0] + '.' + valor1[1]
            setAbFin(valor)
        }
    }


    const [tipoElementoSenal, setTipoElementoSenal] = useState()
    const TipoElementoSenal = (value) => {
        if (value) {
            setTipoElementoSenal(value)
        }
    }

    const [tipoSenal, setTipoSenal] = useState()
    const TipoSenal = (value) => {
        if (value) {
            setTipoSenal(value)
        }

    }
    // const abInicioA ='abInicio[gt]';
    // const abFinA ='abInicio[lt]';
    const [abInicioA, setabInicioA] = useState('abInicio[gt]');
    //    , tipoElemento : tipoElemento
    const [estw, setEstw] = useState(false);
    const [tipoElementoSeguridad, setTipoElementosSeguridad] = useState();
    const [tipoElementoAlrededor, setTipoElementosAlrededor] = useState();
    const [tipoElementoDrenaje, setTipoElementosDrenaje] = useState();
    const [tipoElementoRed, setTipoElementosRed] = useState();
    const [tipoElementoOtro, setTipoElementosOtro] = useState();
    const [tipoPuente, setTipoElementosPuente] = useState();

    const TipoElementoSeguridad = (value) => {
        setTipoElementosSeguridad(value)
        if (value !== undefined) {
            if (estw === false)
                setEstw(true)
            else
                setEstw(false)
        }
    }

    const TipoElementosAlrededor = (value) => {
        setTipoElementosAlrededor(value)
        if (value !== undefined) {
            if (estw === false)
                setEstw(true)
            else
                setEstw(false)
        }
    }

    const TipoElementosDrenaje = (value) => {
        setTipoElementosDrenaje(value)
        if (value !== undefined) {
            if (estw === false)
                setEstw(true)
            else
                setEstw(false)
        }
    }

    const TipoElementosRed = (value) => {
        setTipoElementosRed(value)
        if (value !== undefined) {
            if (estw === false)
                setEstw(true)
            else
                setEstw(false)
        }
    }

    const TipoElementosPuente = (value) => {
        setTipoElementosPuente(value)
        if (value !== undefined) {
            if (estw === false)
                setEstw(true)
            else
                setEstw(false)
        }
    }

    const [indInv, setIndInv] = useState(true);
    const validateIndInv = (value) => {
        setIndInv(value)
    }
    const RefreshButton = () => {
        const handleClick = () => {
            document.location.reload(true)
        }
        return (
            <CardActions>
                {/* <LinkToMapa style={{paddingTop:"13px", marginRight:"20px"}}/> */}
                <Button variant="outlined" color="primary" onClick={handleClick}>
                    {/* <VisibilityIcon /> {translate('resources.indicadores_cargar_informacions.visualizacion')} */}
                    Limpiar filtros
                </Button>
            </CardActions>
        );
    };

    let center = [];

    return (
        <List {...props} basePath={false} record={false} path={false} title='dynatest.resources.elementos.title.listTitle' actions={false} perPage={false} pagination={false}>
            <SimpleForm toolbar={false}>
                <Grid container spacing={24}>
                    <Grid item xs={3}>
                        <ReferenceInput label="resources.elementos.fields.unidadFuncional" source="unidadFuncional" allowEmpty emptyValue="" fullWidth filterToQuery={searchText => ({ codigo: searchText })} reference="unidad_funcionals" validate={[UnidadFuncional]} filter={{ 'properties[0]': 'id', 'properties[1]': 'fullCodigos' }} sort={{ field: 'codigo', order: 'ASC' }}>
                            <SelectInput fullWidth allowEmpty emptyValue="" options={{ fullWidth: true }} optionText="fullCodigos" />
                        </ReferenceInput>
                    </Grid>
                    <Grid item xs={3}>
                        <ReferenceInput label="resources.elementos.fields.tramo" allowEmpty emptyValue="" source="tramo" reference="tramos" validate={[Tramo]} fullWidth filterToQuery={searchText => ({ codigo: searchText })} sort={{ field: 'codigo', order: 'ASC' }} filter={{ unidadFuncional: unidadFuncional, 'properties[0]': 'id', 'properties[1]': 'fullCodigos' }}>
                            <SelectInput optionText="fullCodigos" allowEmpty emptyValue=""  options={{ fullWidth: true }} />
                        </ReferenceInput>
                    </Grid>
                    <Grid item xs={2}>
                        <ReferenceInput label="resources.elementos.fields.segmento" allowEmpty emptyValue="" source="segmento" reference="segmentos" fullWidth filterToQuery={searchText => ({ codigo: searchText })} validate={[Segmento]} sort={{ field: 'codigo', order: 'ASC' }} filter={{ tramo: tramos, 'properties[0]': 'id', 'properties[1]': 'fullCodigos' }}>
                            <SelectInput optionText="fullCodigos" allowEmpty emptyValue=""  options={{ fullWidth: true }} />
                        </ReferenceInput>
                    </Grid> 
                    <Grid item xs={2}>
                        <SelectInput source="tipoMapa" label="resources.elementos.fields.tipoMapa" allowEmpty emptyValue="" choices={[
                            { id: 1, name: 'dynatest.resources.elementos.select.tipoMapa.1' },
                            { id: 2, name: 'dynatest.resources.elementos.select.tipoMapa.2' },
                        ]} fullWidth />
                    </Grid>
                    <Grid item xs={2}>
                        <RefreshButton/>
                    </Grid>
                    <Grid item xs={3}>
                        <BotonFiltro key="1" />
                        <Grid item xs={12} id="filtrosCambio" style={{ display: "none" }}>
                            <ExpansionPanel>
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                    <Typography >{translate('dynatest.resources.elementos.tilteFiltros.filtros')}</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails>
                                    <Grid container spacing={24}>
                                        <Grid item xs={12}>
                                            <ExpansionPanel>
                                                <ExpansionPanelSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1bh-content"
                                                    id="panel1bh-header"
                                                >
                                                    <Typography >{translate('dynatest.resources.elementos.tilteFiltros.calzadas')}</Typography>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails>
                                                    <Grid container spacing={24}>
                                                        <Grid item xs={12} style={{ borderBottom: '1px solid black' }}>
                                                            <CheckboxGroupInput label='' source='flexible_detalles'
                                                                validate={[SeleccionTabla]}
                                                                choices={[
                                                                    { id: 'flexible_detalles', name: 'dynatest.resources.elementos.checkboxs.flexible_detalles' }
                                                                ]}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} style={{ borderBottom: '1px solid black' }}>
                                                            <CheckboxGroupInput label='' source='rigido_detalles'
                                                                validate={[SeleccionTabla]}
                                                                choices={[
                                                                    { id: 'rigido_detalles', name: 'dynatest.resources.elementos.checkboxs.rigido_detalles' }
                                                                ]}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} style={{ borderBottom: '1px solid black' }}>
                                                            <CheckboxGroupInput label='' source='adoquin_detalles'
                                                                validate={[SeleccionTabla]}
                                                                choices={[
                                                                    { id: 'adoquin_detalles', name: 'dynatest.resources.elementos.checkboxs.adoquin_detalles' }
                                                                ]}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} style={{ borderBottom: '1px solid black' }}>
                                                            <CheckboxGroupInput label='' source='otro_detalles'
                                                                validate={[SeleccionTabla]}
                                                                choices={[
                                                                    { id: 'otro_detalles', name: 'dynatest.resources.elementos.checkboxs.otro_detalles' }
                                                                ]}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ExpansionPanel>
                                                <ExpansionPanelSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1bh-content"
                                                    id="panel1bh-header"
                                                >
                                                    <Typography >{translate('dynatest.resources.elementos.tilteFiltros.sistemasContencion')}</Typography>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails>
                                                    <Grid container spacing={24}>
                                                        <Grid item xs={12} style={{ borderBottom: '1px solid black' }}>
                                                            <CheckboxGroupInput label='' source='seguridads'
                                                                validate={[SeleccionTabla]}
                                                                choices={[
                                                                    { id: 'seguridads', name: 'dynatest.resources.elementos.checkboxs.seguridads' },
                                                                ]}
                                                            />
                                                            <FormDataConsumer>
                                                                {
                                                                    ({ formData, ...rest }) => (formData.seguridads != undefined &&
                                                                        <FormDataConsumer>
                                                                            {
                                                                                ({ formData, ...rest }) => (formData.seguridads[0] !== undefined &&
                                                                                    <ReferenceArrayInput label="resources.elementos.fields.tipoElementoSeguridad" validate={[TipoElementoSeguridad]} source="tipoElementoSeguridad" reference="tipo_elementos" fullWidth filterToQuery={searchText => ({ codigo: searchText })} filter={{ sistema: 'SEG' }} sort={{ field: 'codigo', order: 'ASC' }} >
                                                                                        <AutocompleteArrayInput options={{ fullWidth: true }} optionText={choice => `${choice.nombre.slice(0, 15)}...`} />
                                                                                    </ReferenceArrayInput>
                                                                                )
                                                                            }
                                                                        </FormDataConsumer>
                                                                    )
                                                                }
                                                            </FormDataConsumer>
                                                        </Grid>
                                                    </Grid>
                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ExpansionPanel>
                                                <ExpansionPanelSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1bh-content"
                                                    id="panel1bh-header"
                                                >
                                                    <Typography >{translate('dynatest.resources.elementos.tilteFiltros.drenajes')}</Typography>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails>
                                                    <Grid container spacing={24}>
                                                        <Grid item xs={12} style={{ borderBottom: '1px solid black' }}>
                                                            <CheckboxGroupInput label='' source='elemento_drenajes'
                                                                validate={[SeleccionTabla]}
                                                                choices={[
                                                                    { id: 'elemento_drenajes', name: 'dynatest.resources.elementos.checkboxs.elemento_drenajes' }
                                                                ]}
                                                            />
                                                            <FormDataConsumer>
                                                                {
                                                                    ({ formData, ...rest }) => (formData.elemento_drenajes !== undefined &&
                                                                        <FormDataConsumer>
                                                                            {
                                                                                ({ formData, ...rest }) => (formData.elemento_drenajes[0] !== undefined &&
                                                                                    <ReferenceArrayInput label="resources.elementos.fields.tipoElementoDrenaje" source="tipoElementoDrenaje" validate={[TipoElementosDrenaje]} reference="tipo_elementos" fullWidth filterToQuery={searchText => ({ codigo: searchText })} filter={{ sistema: 'DRE' }} sort={{ field: 'codigo', order: 'ASC' }} >
                                                                                        <AutocompleteArrayInput options={{ fullWidth: true }} optionText={choice => `${choice.nombre.slice(0, 15)}...`} />
                                                                                    </ReferenceArrayInput>
                                                                                )
                                                                            }
                                                                        </FormDataConsumer>
                                                                    )
                                                                }
                                                            </FormDataConsumer>
                                                        </Grid>
                                                    </Grid>
                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ExpansionPanel>
                                                <ExpansionPanelSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1bh-content"
                                                    id="panel1bh-header"
                                                >
                                                    <Typography >{translate('dynatest.resources.elementos.tilteFiltros.senalizacion')}</Typography>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails>
                                                    <Grid container spacing={24}>
                                                        <Grid item xs={12} style={{ borderBottom: '1px solid black' }}>
                                                            <CheckboxGroupInput label='' source='elemento_senalizacions'
                                                                validate={[SeleccionTabla]}
                                                                choices={[
                                                                    { id: 'elemento_senalizacions', name: 'dynatest.resources.elementos.checkboxs.elemento_senalizacions' }
                                                                ]}
                                                            />
                                                            <FormDataConsumer>
                                                                {
                                                                    ({ formData, dispatch, ...rest }) => (formData.elemento_senalizacions !== undefined &&
                                                                        <Fragment>
                                                                            <ReferenceInput
                                                                                label="resources.elementos.fields.tipoElementoSenal"
                                                                                source="tipoElementoSenal"
                                                                                reference="tipo_elemento_senals"
                                                                                fullWidth
                                                                                filter={{ pagination: "off" }}
                                                                                filterToQuery={searchText => ({ codigo: searchText })}
                                                                                sort={{ field: 'codigo', order: 'ASC' }}
                                                                                validate={[TipoElementoSenal]}
                                                                            >
                                                                                <SelectInput optionText="nombre" options={{ fullWidth: true }} />
                                                                            </ReferenceInput>
                                                                            {formData.tipoElementoSenal !== undefined &&
                                                                                <ReferenceInput
                                                                                    label="resources.elementos.fields.tipoSenal"
                                                                                    source="tipoSenal"
                                                                                    reference="tipo_senals"
                                                                                    fullWidth
                                                                                    filterToQuery={searchText => ({ codigo: searchText })}
                                                                                    sort={{ field: 'codigo', order: 'ASC' }}
                                                                                    filter={{ tipoElementoSenal: formData.tipoElementoSenal, pagination: "off" }}
                                                                                    validate={[TipoSenal]}
                                                                                >
                                                                                    <SelectInput optionText="nombre" options={{ fullWidth: true }} />
                                                                                </ReferenceInput>
                                                                            }
                                                                            {formData.tipoElementoSenal !== undefined && formData.tipoElementoSenal === '/tipo_elemento_senals/SV' &&
                                                                                <ReferenceInput
                                                                                    label="resources.elementos.fields.tipoSenalVertical"
                                                                                    source="tipoSenalVertical"
                                                                                    reference="senals"
                                                                                    fullWidth
                                                                                    filterToQuery={searchText => ({ codigo: searchText })}
                                                                                    sort={{ field: 'codigo', order: 'ASC' }}
                                                                                    filter={{ tipoSenal: formData.tipoSenal, pagination: "off" }}
                                                                                >
                                                                                    <SelectInput optionText="nombre" options={{ fullWidth: true }} />
                                                                                </ReferenceInput>
                                                                            }
                                                                        </Fragment>
                                                                    )
                                                                }
                                                            </FormDataConsumer>
                                                        </Grid>
                                                    </Grid>
                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ExpansionPanel>
                                                <ExpansionPanelSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1bh-content"
                                                    id="panel1bh-header"
                                                >
                                                    <Typography >{translate('dynatest.resources.elementos.tilteFiltros.alrededores')}</Typography>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails>
                                                    <Grid container spacing={24}>
                                                        <Grid item xs={12} style={{ borderBottom: '1px solid black' }}>
                                                            <CheckboxGroupInput label='' source='elemento_alrededors'
                                                                validate={[SeleccionTabla]}
                                                                choices={[
                                                                    { id: 'elemento_alrededors', name: 'dynatest.resources.elementos.checkboxs.elemento_alrededors' }
                                                                ]}
                                                            />
                                                            <FormDataConsumer>
                                                                {
                                                                    ({ formData, ...rest }) => (formData.elemento_alrededors !== undefined &&
                                                                        <FormDataConsumer>
                                                                            {
                                                                                ({ formData, ...rest }) => (formData.elemento_alrededors[0] !== undefined &&
                                                                                    <ReferenceArrayInput label="resources.elementos.fields.tipoElementoAlrededor" source="tipoElementoAlrededor" validate={[TipoElementosAlrededor]} reference="tipo_elementos" fullWidth filterToQuery={searchText => ({ codigo: searchText })} filter={{ sistema: 'ALR' }} sort={{ field: 'codigo', order: 'ASC' }} >
                                                                                        <AutocompleteArrayInput options={{ fullWidth: true }} optionText={choice => `${choice.nombre.slice(0, 15)}...`} />
                                                                                    </ReferenceArrayInput>
                                                                                )
                                                                            }
                                                                        </FormDataConsumer>
                                                                    )
                                                                }
                                                            </FormDataConsumer>
                                                        </Grid>
                                                    </Grid>
                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ExpansionPanel>
                                                <ExpansionPanelSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1bh-content"
                                                    id="panel1bh-header"
                                                >
                                                    <Typography >{translate('dynatest.resources.elementos.tilteFiltros.puentesEstrucEsp')}</Typography>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails>
                                                    <Grid container spacing={24}>
                                                        <Grid item xs={12} style={{ borderBottom: '1px solid black' }}>
                                                            <CheckboxGroupInput label='' source='tuneles'
                                                                validate={[SeleccionTabla]}
                                                                choices={[
                                                                    { id: 'tuneles', name: 'dynatest.resources.elementos.checkboxs.tuneles' }
                                                                ]}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} style={{ borderBottom: '1px solid black' }}>
                                                            <CheckboxGroupInput label='' source='elemento_contencions'
                                                                validate={[SeleccionTabla]}
                                                                choices={[
                                                                    { id: 'elemento_contencions', name: 'dynatest.resources.elementos.checkboxs.elemento_contencions' }
                                                                ]}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} style={{ borderBottom: '1px solid black' }}>
                                                            <CheckboxGroupInput label='' source='elemento_pontons'
                                                                validate={[SeleccionTabla]}
                                                                choices={[
                                                                    { id: 'elemento_pontons', name: 'dynatest.resources.elementos.checkboxs.elemento_pontons' }
                                                                ]}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} style={{ borderBottom: '1px solid black' }}>
                                                            <CheckboxGroupInput label='' source='puentes'
                                                                validate={[SeleccionTabla]}
                                                                choices={[
                                                                    { id: 'puentes', name: 'dynatest.resources.elementos.checkboxs.puentes' }
                                                                ]}
                                                            />
                                                            <FormDataConsumer>
                                                                {
                                                                    ({ formData, ...rest }) => (formData.puentes !== undefined &&
                                                                        <FormDataConsumer>
                                                                            {
                                                                                ({ formData, ...rest }) => (formData.puentes[0] !== undefined &&
                                                                                    <ReferenceArrayInput label="resources.elementos.fields.tipoPuente" source="tipoPuente" validate={[TipoElementosPuente]} reference="tipo_elementos" fullWidth filterToQuery={searchText => ({ codigo: searchText })} filter={{ sistema_id: 'PTS', nombre: 'Puente ' }} sort={{ field: 'codigo', order: 'ASC' }} >
                                                                                        <AutocompleteArrayInput options={{ fullWidth: true }} optionText={choice => `${choice.nombre.slice(0, 15)}...`} />
                                                                                    </ReferenceArrayInput>
                                                                                )
                                                                            }
                                                                        </FormDataConsumer>
                                                                    )
                                                                }
                                                            </FormDataConsumer>
                                                        </Grid>
                                                    </Grid>
                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ExpansionPanel>
                                                <ExpansionPanelSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1bh-content"
                                                    id="panel1bh-header"
                                                >
                                                    <Typography >{translate('dynatest.resources.elementos.tilteFiltros.otrosElementos')}</Typography>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails>
                                                    <Grid container spacing={24}>
                                                        <Grid item xs={12} style={{ borderBottom: '1px solid black' }}>
                                                            <CheckboxGroupInput label='' source='otros_elementos'
                                                                validate={[SeleccionTabla]}
                                                                choices={[
                                                                    { id: 'otros_elementos', name: 'dynatest.resources.elementos.checkboxs.otros_elementos' }
                                                                ]}
                                                            />
                                                            <FormDataConsumer>
                                                                {
                                                                    ({ formData, ...rest }) => (formData.otros_elementos !== undefined &&
                                                                        <ReferenceArrayInput
                                                                            label="resources.elementos.fields.tipoElementoOtro"
                                                                            source="tipoElementoOtro"
                                                                            reference="tipo_elementos"
                                                                            fullWidth
                                                                            filterToQuery={searchText => ({ codigo: searchText })}
                                                                            sort={{ field: 'codigo', order: 'ASC' }} filter={{ sistema: 'OTR' }}
                                                                        >
                                                                            <AutocompleteArrayInput options={{ fullWidth: true }} optionText={choice => `${choice.nombre.slice(0, 15)}...`} />
                                                                        </ReferenceArrayInput>
                                                                    )
                                                                }
                                                            </FormDataConsumer>
                                                        </Grid>
                                                    </Grid>
                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <ExpansionPanel>
                                                <ExpansionPanelSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1bh-content"
                                                    id="panel1bh-header"
                                                >
                                                    <Typography >{translate('dynatest.resources.elementos.tilteFiltros.redes')}</Typography>
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails>
                                                    <Grid container spacing={24}>
                                                        <Grid item xs={12} style={{ borderBottom: '1px solid black' }}>
                                                            <CheckboxGroupInput label='' source='elemento_reds'
                                                                validate={[SeleccionTabla]}
                                                                choices={[
                                                                    { id: 'elemento_reds', name: 'dynatest.resources.elementos.checkboxs.elemento_reds' }
                                                                ]}
                                                            />
                                                            <FormDataConsumer>
                                                                {
                                                                    ({ formData, ...rest }) => (formData.elemento_reds !== undefined &&
                                                                        <ReferenceArrayInput label="resources.elementos.fields.tipoElementoRed" source="tipoElementoRed" validate={[TipoElementosRed]} reference="tipo_elementos" fullWidth filterToQuery={searchText => ({ codigo: searchText })} filter={{ sistema: 'RDS' }} sort={{ field: 'codigo', order: 'ASC' }} >
                                                                            <AutocompleteArrayInput options={{ fullWidth: true }} optionText={choice => `${choice.nombre.slice(0, 15)}...`} />
                                                                        </ReferenceArrayInput>
                                                                    )
                                                                }
                                                            </FormDataConsumer>
                                                        </Grid>
                                                    </Grid>
                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                        </Grid>
                                    </Grid>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </Grid>
                    </Grid>
                    {/* ----------------- COORDENADAS DEL PROYECTO 'CENTER' ----------------- */}
                    <Query type='GET_ONE' resource='proyectos' payload={{ id: window.localStorage.getItem('id_project2') }}>
                        {({ data, loading, error }) => {
                            let coordenadasProyec = [];
                            if (data) {
                                data.ubicaciones.map(items => {
                                    center = {
                                        lat: items.lat,
                                        lng: items.lng
                                    }
                                    coordenadasProyec.push(center)
                                })
                                setCoordenadasProyec(coordenadasProyec)
                                return (

                                    <ArrayInput style={{ display: 'none' }} source='coordenadasProyec' defaultValue={coordenadasProyec}>
                                        <SimpleFormIterator>
                                            <TextInput source='lat' />
                                        </SimpleFormIterator>
                                    </ArrayInput>
                                )
                            }
                            return (<div></div>)
                        }}
                    </Query>
                    {/* ----------------- FIN COORDENADAS DEL PROYECTO 'CENTER' ----------------- */}

                    <Grid item xs={9}>
                        <FormDataConsumer>
                            {
                                ({ formData, ...rest }) => (formData.table != null && formData.table !== undefined &&
                                    <NumberInput source='cantidad' defaultValue={formData.table.length} />
                                )
                            }
                        </FormDataConsumer>

                        <FormDataConsumer>
                            {
                                ({ formData, ...rest }) => (
                                    <Grid container spacing={24}>
                                        <Grid item xs={11}>
                                            <MapaGeneral
                                                unidadFuncional={unidadFuncional}
                                                tramo={tramos}
                                                segmento={segmentos}
                                                flexibleDetalles={formData.flexible_detalles}
                                                rigidoDetalles={formData.rigido_detalles}
                                                adoquinDetalles={formData.adoquin_detalles}
                                                otroDetalles={formData.otro_detalles}
                                                seguridads={formData.seguridads}
                                                tipoElementoSeguridad={formData.tipoElementoSeguridad}
                                                elementoDrenajes={formData.elemento_drenajes}
                                                tipoElementoDrenaje={formData.tipoElementoDrenaje}
                                                elementoSenalizacion={formData.elemento_senalizacions}
                                                tipoElementoSenal={formData.tipoElementoSenal}
                                                tipoSenal={formData.tipoSenal}
                                                tipoSenalVertical={formData.tipoSenalVertical}
                                                elementoAlrededors={formData.elemento_alrededors}
                                                tipoElementoAlrededor={formData.tipoElementoAlrededor}
                                                tuneles={formData.tuneles}
                                                elementoContencions={formData.elemento_contencions}
                                                elementoPontons={formData.elemento_pontons}
                                                puentes={formData.puentes}
                                                tipoPuente={formData.tipoPuente}
                                                otrosElementos={formData.otros_elementos}
                                                tipoElementoOtro={formData.tipoElementoOtro}
                                                elementoReds={formData.elemento_reds}
                                                tipoElementoRed={formData.tipoElementoRed}
                                                positions={coordenadasProyec}
                                                estw={estw}
                                                indInv={formData.tipoMapa}
                                                numIntervalos={formData.numIntervalos}
                                                umbrales={formData}
                                            />
                                        </Grid>
                                        <Grid item xs={1}>
                                            <img style={{ width: "100%", height: "auto" }} src={brujula} />
                                        </Grid>
                                    </Grid>
                                )
                            }
                        </FormDataConsumer>
                    </Grid>

                </Grid>
            </SimpleForm>
        </List>
    )
});



