import React from 'react';
import { TabbedShowLayout, TextField, Tab,FormTab, DateField,NumberField,ReferenceField,SelectInput,SelectField} from 'react-admin';
import { useState } from 'react';
import { Show } from './../Show';
import { withStyles } from '@material-ui/core';
import { styles } from './../../EstilosGrid';
import TabbedForm from '../TabbedForm';
import Viewanalisis from './tablaAnalisis/viewtablaAnalisis';
import { translate } from 'react-admin';
export const ParametrosAnalisisShow = translate(withStyles(styles)(({ classes,record, ...props }) => {
    const { translate } = props;
    const [years1, setYears1] = useState('');
    const validarAño1 = (value) => {
        
        
    
        
       
        setYears1(value)
    
    
    }
    const [years2, setYears2] = useState('');
    const validarAño2 = (value) => {
        
        
    
        
        
        setYears2(value)
    
    
    }
    const choices=[
        { id: '2000', name: '2000' },
        { id: '2001', name: '2001' },
        { id: '2002', name: '2002' },
        { id: '2003', name: '2003' },
        { id: '2004', name: '2004' },
        { id: '2005', name: '2005' },
        { id: '2006', name: '2006' },
        { id: '2007', name: '2007' },
        { id: '2008', name: '2008' },
        { id: '2009', name: '2009' },
        { id: '2010', name: '2010' },
        { id: '2011', name: '2011' },
        { id: '2012', name: '2012' },
        { id: '2013', name: '2013' },
        { id: '2014', name: '2014' },
        { id: '2015', name: '2015' },
        { id: '2016', name: '2016' },
        { id: '2017', name: '2017' },
        { id: '2018', name: '2018' },
        { id: '2019', name: '2019' },
        { id: '2020', name: '2020' },
        { id: '2021', name: '2021' },
        { id: '2022', name: '2022' },
        { id: '2023', name: '2023' },
        { id: '2024', name: '2024' },
        { id: '2025', name: '2025' },
        { id: '2026', name: '2026' },
        { id: '2027', name: '2027' },
        { id: '2028', name: '2028' },
        { id: '2029', name: '2029' },
        { id: '2030', name: '2030' }
      ]
    return(
        <Show title="dynatest.resources.parametros_analises.title.showTitle" {...props}>
           <TabbedForm>
                <FormTab label="dynatest.resources.parametros_analises.tabs.tabsTitle.InformacionBasica">
                <ReferenceField label="resources.parametros_analises.fields.unidad_funcional" source="unidad_funcional" reference="unidad_funcionals" linkType={false} fullWidth formClassName={classes.grid_cont4}>
              <TextField source="fullCodigos" />
          </ReferenceField>
          <br/>
          <TextField source="plan" label="resources.parametros_analises.fields.plan" fullWidth formClassName={classes.grid_cont4s}/>  
                    <NumberField label="resources.parametros_analises.fields.num_analisis" source="num_analisis" fullWidth formClassName={classes.grid_cont4} style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4}/>
                   <SelectInput id="12" label="resources.parametros_analises.fields.years1"  disabled allowEmpty  source="years1" validate={validarAño1} choices={choices} fullWidth formClassName={classes.grid_cont3}/>
                    <TextField label="resources.parametros_analises.fields.descripcion" source="descripcion" fullWidth formClassName={classes.grid_cont12}/>
                    <NumberField label="resources.parametros_analises.fields.tasa_crecimiento" source="tasa_crecimiento" fullWidth formClassName={classes.grid_cont4s} style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4s}/>
                    <NumberField label="resources.parametros_analises.fields.tasa_interes" source="tasa_interes" fullWidth formClassName={classes.grid_cont4} style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4}/>
                     <TextField label="resources.parametros_analises.fields.coin" source="coin" allowEmpty emptyValue="" fullWidth formClassName={classes.grid_cont3} />
                     <NumberField label="resources.parametros_analises.fields.per_analisis" source="per_analisis" fullWidth formClassName={classes.grid_cont4s} style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}}/>
                    <NumberField label="resources.parametros_analises.fields.nom_analisis" source="nom_analisis" fullWidth formClassName={classes.grid_cont4} style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4}/>
                    {/* <NumberField label="Periodo de análisis" source="per_analisis" fullWidth formClassName={classes.grid_cont4}/>
                    <TextField label="Nombre de análisis" source="nom_analisis" fullWidth formClassName={classes.grid_cont4}/>
                    <SelectField label="Año de evaluación" source="years" defaultValue={'2020'} fullWidth choices={choices} formClassName={classes.grid_cont4}/> */}
                    <SelectInput id="13" label="resources.parametros_analises.fields.years2" disabled allowEmpty  source="years2" validate={validarAño2} choices={choices} style={{display:'none'}} fullWidth formClassName={classes.grid_cont3}/>
                    {years1 && years2 &&
                  <WithProps>{({ record, ...props }) => 
                 
                                <Viewanalisis record={record}  years1={years1} years2={years2} />}
                 
                             </WithProps> 
                        }
                    </FormTab>
                    <FormTab label="dynatest.resources.parametros_analises.tabs.tabsTitle.auditoria">
                    <DateField source="createdAt" label="resources.parametros_analises.fields.createdAt" formClassName={classes.grid_cont3s} showTime/>
                    <TextField source="createdBy" label="resources.parametros_analises.fields.createdBy" formClassName={classes.grid_cont3}/>    
                    <br/>
                    <DateField source="updatedAt" label="resources.parametros_analises.fields.updatedAt" formClassName={classes.grid_cont3s} showTime/>
                    <TextField source="updatedBy" label="resources.parametros_analises.fields.updatedBy" formClassName={classes.grid_cont3}/>
                </FormTab>
                </TabbedForm>
        </Show>
)}));
const WithProps = ({ children, ...props }) => children(props);