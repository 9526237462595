import React, { useState } from 'react';
import { Button, List, Datagrid, TextField, NumberField, EditButton, ShowButton, Filter, ReferenceInput, AutocompleteInput, Confirm
 } from 'react-admin';
import EditButtonPims from '../EditButton';
import {  WithPermissions,BulkDeleteWithConfirmButton, Loading  } from 'react-admin';
import { Fragment } from 'react';
import { PField } from './../../fields/PField';
import { PRField } from './../../fields/PRField';
import { CardActions, CreateButton, ExportButton } from 'react-admin';
import IconEvent from '@material-ui/icons/Visibility';
import {Link} from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles';
import {styles}  from './../../EstilosGrid';
import DeleteIcon from '@material-ui/icons/Delete';
import config from '../../config/config';
import { translate } from 'react-admin';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconCancel from '@material-ui/icons/Cancel';
let urlAppjs = config.UrlServer;

export const TramoList =  translate(withStyles(styles)(({ classes, permissions,...props }) => {
    const { translate } = props;
    const [open, setOpen] = React.useState(false);
    const handleClose = value => {
        setOpen(false);
    };

    const TramoFilter = (props) => (
        <Filter {...props}>
            <ReferenceInput 
                source="unidadFuncional"  
                reference="unidad_funcionals" 
                filterToQuery={searchText => ({ codigo: searchText })} 
                sort={{field : 'codigo', order : 'asc'}}
                alwaysOn
                fullWidth 
            >
                <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
            </ReferenceInput>
        </Filter>
    );
        
    const EliminarRegistro = (props) => {
        let datos = Object.values(props);
        console.log(datos)
        datos[3].forEach(element => {
            let ids      = element.replace(/\D/g,"");
            let elemen   = element.replace("/tramos/", "");
            let elemento = elemen.replace(/\d/g,""); 
            
            const dataSend = new FormData();
            dataSend.append('id', ids);
            dataSend.append('elemento', 'tramo');
            let requestOptionsReplace = {
                method: 'POST',
                headers: { 'authorization': 'Bearer ' + localStorage.getItem('token') },
                body: dataSend
            };
            fetch(urlAppjs + "/eliminarRegistro/", requestOptionsReplace)
            .then(response => response.json())
            .then((dataRequestInventario) => {
                if(dataRequestInventario == "Success"){
                    console.log(dataRequestInventario, "OK")
                    window.location.reload();
                }
            }).catch((error) => {
                setOpen(true);
                console.log(error)
            });
        });
    } 
    
    const PostBulkActionButtons = (props) => {
        const [open, setOpen] = useState(false);
        const handleClick = () => setOpen(true);
        const handleDialogClose = () => setOpen(false);
        const handleConfirm = () => {
            EliminarRegistro(props);
            setOpen(false);
        };

        return (
            <>
                <Button label="resources.validaciones.borrar" onClick={handleClick} style={{
                    fontSize: '0.8125rem', color: '#f44336', border: 0,
                    display: 'inline-flex', alignItems: 'center',
                    width: '7rem', height: '2rem'
                }}>
                    <DeleteIcon  style={{ width: '1.3rem', paddingRight: '0.3rem' }} />
                </Button>
                
                <Confirm
                    isOpen={open}
                    title="resources.validaciones.valBorrarTramo"
                    content="resources.validaciones.valBorrar"
                    onConfirm={handleConfirm}
                    onClose={handleDialogClose}
                />
            </>
        );
                    
    };
    function SimpleDialog(props) {
        const { open } = props;
        const handleClose = () => {
            setOpen(false);
        };
    
        return (
            <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle id="simple-dialog-title">{translate('resources.validaciones.valEliminacion')}</DialogTitle>
                <DialogContent>
                    {translate('resources.validaciones.valRegistroAsociado')}
                </DialogContent>
                <DialogActions>
                    <Button label="Cerrar" onClick={handleClose}>
                        <IconCancel />
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
    const ListAction = ({ basePath, data, resource }) => (
        <CardActions>
            <CreateButton basePath={basePath} /> 
            <ExportButton basePath={basePath}/>
            <Link to="/generacion_masiva_segmentos/create">
                <Button label="dynatest.resources.tramos.title.genMasvSeg">
                    <IconEvent/>
                </Button>
            </Link>
        </CardActions>
    );
    const ListAction2 = ({ basePath, data, resource}) => (
        <CardActions>
            <CreateButton basePath={basePath} /> 
            <ExportButton basePath={basePath}/>
        </CardActions>
    );
    if (!permissions) return <Loading />;  
    return (
        <Fragment>
            <SimpleDialog open={open} onClose={handleClose} />
            <List {...props} 
                actions={permissions['tramos']  == 1 ? <ListAction /> : permissions['tramos']  == 2 ? <ListAction2 />: null} 
                sort={{ field: "updatedAt", order: 'DESC' }} 
                filters={<TramoFilter/>} 
                bulkActionButtons={permissions['tramos']  == 1 ? <PostBulkActionButtons />: null}>
            <Datagrid>                                    
                <TextField source="codigo" />  
                <PField source="pInicio"/>
                <PField source="pFin"/>    
                <PRField source="abInicio"/>
                <PRField source="abFin"/>
                <NumberField source="fullResta" sortable={false}/>                      
                {permissions['tramos'] == 1 || permissions['tramos'] == 2 || permissions['tramos'] == 4 ? <ShowButton/>: null}
                {permissions['tramos'] == 1 || permissions['tramos'] == 2 ? <EditButton/>: null}    
            </Datagrid>
        </List>
        </Fragment>
)}));