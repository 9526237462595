import React from 'react';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { translate } from 'react-admin';

class Viewanalisis extends React.Component{

    constructor(props){
        console.log(props)
        super(props)
        this.state = { 
            titulos: [], record: [], tipo: "",totalSum:[],
            years1: props.years1,
            years2: props.years2,
            titulos2: []
    
    }
        this.array = {
            valoriza : 0
        }
    }
    

    componentDidMount(){
        //this.setState({ datoEva: this.props.record })   
        this.setState({ record: this.props.record })  
        this.setState({ tipo: this.props.tipo })  
       // console.log(this.props.record) 
       this.allYears()

    }
    componentWillReceiveProps() {
        // if(this.props.years1 != this.props.years2 && parseInt(this.props.years2) > parseInt(this.props.years1)){
            console.log();
            this.setState({years1:this.props.years1, years2:this.props.years2})
        this.allYears()
        
      }

    allYears(){
        const {totalSum} = this.state
        const years = []
        const total = totalSum
        let years1 = this.props.years1
        let years2 = this.props.years2
        console.log("Hasta acá llegamos equisde");
        for (let index = years1; index <= years2; index++) {
            years.push(parseInt(index))
            total.push("")
            
        }
        console.log(this.props.years2)
        this.setState({
            titulos2: years,
            totalSum: total
        })
        
    }

    totaliza = (i) => {
        const {years1, years2,totalSum} = this.state
        const total = totalSum
        if(total.length === 0) {
           for (let index = years1; index <= years2; index++) {
                total.push("")
            }
        }
        let valor_a=parseInt((document.getElementById('precioa_'+i).value).replace("$","") )
        let valor_b=parseInt((document.getElementById('preciob_'+i).value).replace("$",""))
        const a =  valor_a ? valor_a : 0
        const b =  valor_b ? valor_b : 0
        const suma = a + b
        total[i] = suma        
        this.setState({
            totalSum: total
        })
    }

    sumaTotal(valor1, valor2){
        if(valor1 && valor2){
            return Number(valor1.replace("$", ""))+Number(valor2.replace("$", ""))
        }else if(valor1 && !valor2){
            return Number(valor1.replace("$", ""))
        }else if(!valor1 && valor2){
            return Number(valor2.replace("$", ""))
        }else{
            return "";
        }
    }
    render(){
        const { translate } = this.props;
        const { titulos } = this.state;
        const {record} = this.state;
        const {tipo} = this.state;
        const  {titulos2} = this.state

        let analisis=this.props.record.analisis
            return(
                <React.Fragment>
                        <div style={{textAlign : "center", marginBottom: '25px'}}>
              <Typography variant="h3" component="h3">
                 {translate('dynatest.resources.parametros_analises.table.titleTable')}
              </Typography>
          </div>
                   <Table aria-label="simple table"  >
                        <TableHead>
              <TableRow>
                <TableCell align="right">{translate('dynatest.resources.parametros_analises.table.nameColumns.Anio')}</TableCell>
                <TableCell align="right">{translate('dynatest.resources.parametros_analises.table.nameColumns.PresupMantenPavimento')}</TableCell>
                <TableCell align="right">{translate('dynatest.resources.parametros_analises.table.nameColumns.PresupMantenOtrosElemen')}</TableCell>
                <TableCell align="right">{translate('dynatest.resources.parametros_analises.table.nameColumns.PresupTotal')}</TableCell>
                
              </TableRow>
            </TableHead>
            
                  
                        <TableBody>
                        {
                    titulos2.map((data, i) => (
                        <TableRow > 
                        <TableCell align="center"  >
                        <h3 style={{marginBottom: 0}}>{titulos2[i]}</h3>                    
                        </TableCell>
            
                     
                      <TableCell align="right" style={{textAlign:'right'}} ><span>{analisis[i].precio}</span></TableCell>  
                      <TableCell align="right" style={{textAlign:'right'}} ><span>{analisis[i].precio2}</span></TableCell>  
                      <TableCell align="right" style={{textAlign:'right'}} ><span>${this.sumaTotal(analisis[i].precio, analisis[i].precio2)}</span> </TableCell>  
                      <span style={{display: 'none'}}>{i}</span>
                    </TableRow>
                     ))                        
                    }
                    </TableBody>     
                
                       
                   
                        </Table>
                </React.Fragment>
            )
        }
        
            

    }


export default translate(Viewanalisis);