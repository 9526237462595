import React from 'react';

import { Edit } from './../Edit';

import { TextInput, DateInput,SelectInput, NumberInput,BooleanInput,ReferenceInput ,FormTab,TabbedForm} from 'react-admin';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue
} from 'react-admin';

import {
    Toolbar,
    SaveButton,
    DeleteButton,
    
   
} from 'react-admin';
import { withStyles } from '@material-ui/core';

import Grid from '@material-ui/core/Grid';

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const validarEnteroNT =(value) =>{
    if (!/^([0-9]+)*$/.test(value))
    {
      return "Debe ser un número entero";
    }
  }

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <SaveButton />
          
    </Toolbar>
));

const choices1 = [
    { id: 'Costado derecho', name: 'Costado derecho' },
    { id: 'Costado izquierdo', name: 'Costado izquierdo' },
    
];


    

export const CalificacionPontonEdit = props => (
    <Edit title="Editar Calificación" {...props} >
        
        <TabbedForm submitOnEnter={false} redirect="list">
      <FormTab label="Detalles">
            <Grid container spacing={24}>   
            <Grid item xs={12}>
                  <h3 style={{marginBottom: 0}}>Detalles de inspección</h3>                    
                  <hr></hr>
              </Grid>
              <Grid item xs={4}>
              <ReferenceInput label="Ponton" source="ponton" reference="elemento_pontons" validate={required()} fullWidth>                
                   <SelectInput optionText="fullCodigo" />                
                 </ReferenceInput>  
              </Grid>             
              <Grid item xs={4}>
            <DateInput label="Fecha de inicio de inspección" source="fecha1" fullWidth/>
              </Grid>
              <Grid item xs={4}>
            <NumberInput label="Consecutivo de inspección" source="consecutivo" fullWidth  validate={[required(),minValue(0),maxValue(99999999),validarEnteroNT]}/>
              </Grid>
              <Grid item xs={4}>
                <TextInput label="Inspector" source="inspector" fullWidth validate={[required(),minLength(0),maxLength(40)]}/>
              </Grid>
              <Grid item xs={6}>
            <DateInput label="Fecha de reporte de inspección" source="fecha2" fullWidth/>
              </Grid>
              <Grid item xs={6}>
                <TextInput label="Nombre de reporte de inspección" source="nombre_inspector" fullWidth validate={[required(),minLength(0),maxLength(80)]}/>
              </Grid>
              
                </Grid>
              </FormTab>
              <FormTab label="Componentes">
              <Grid container spacing={24}>  
              <Grid item xs={12}>
                  <h3 style={{marginBottom: 0}}>Calificación de Componentes</h3>                    
                  <hr></hr>
              </Grid> 
              <Table  aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Componente</TableCell>
            <TableCell align="right">Presencia de daños</TableCell>
            <TableCell align="right">Aplica</TableCell>
            <TableCell align="right">Cantidad</TableCell>
            <TableCell align="right">Elemento</TableCell>
            <TableCell align="right">Fotografías</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          
        <TableRow >
            <TableCell component="th"  rowSpan={3}>
            Losa superior
              </TableCell>
              <TableCell align="right">Grietas</TableCell>
              <TableCell align="right"><BooleanInput label="" source="bol1" fullWidth/></TableCell>
              <TableCell align="right"><NumberInput label="" source="num1"/></TableCell>
              <TableCell align="right"><TextInput label="" source="sel31" validate={[minLength(0),maxLength(20)]}/></TableCell>
              <TableCell align="right"></TableCell>
              
            </TableRow>
          
            <TableRow >
            <TableCell component="th">
            Dañada
              </TableCell>
             
              <TableCell align="right"><BooleanInput label="" source="bol2" fullWidth/></TableCell>
              <TableCell align="right"><NumberInput label="" source="num2"/></TableCell>
              <TableCell align="right"><TextInput label="" source="sel1" validate={[minLength(0),maxLength(20)]}/></TableCell>
              <TableCell align="right"></TableCell>
              
            </TableRow>
            <TableRow >
            <TableCell component="th">
            Desconchados
              </TableCell>
              
              <TableCell align="right"><BooleanInput label="" source="bol3" fullWidth/></TableCell>
              <TableCell align="right"><NumberInput label="" source="num3"/></TableCell>
              <TableCell align="right"><TextInput label="" source="sel2" validate={[minLength(0),maxLength(20)]}/></TableCell>
              <TableCell align="right"></TableCell>
              
            </TableRow>
            <TableRow >
            <TableCell component="th"  rowSpan={3}>
            Losa inferior
              </TableCell>
              <TableCell align="right">Grietas</TableCell>
              <TableCell align="right"><BooleanInput label="" source="bol6" fullWidth/></TableCell>
              <TableCell align="right"><NumberInput label="" source="num6"/></TableCell>
              <TableCell align="right"><TextInput label="" source="sel5" validate={[minLength(0),maxLength(20)]}/></TableCell>
              <TableCell align="right"></TableCell>
              
            </TableRow>
          
            <TableRow >
            <TableCell component="th">
            Dañada
              </TableCell>
             
              <TableCell align="right"><BooleanInput label="" source="bol7" fullWidth/></TableCell>
              <TableCell align="right"><NumberInput label="" source="num7"/></TableCell>
              <TableCell align="right"><TextInput label="" source="sel6" validate={[minLength(0),maxLength(20)]}/></TableCell>
              <TableCell align="right"></TableCell>
              
            </TableRow>
            <TableRow >
            <TableCell component="th">
            Desconchados
              </TableCell>
              
              <TableCell align="right"><BooleanInput label="" source="bol8" fullWidth/></TableCell>
              <TableCell align="right"><NumberInput label="" source="num8"/></TableCell>
              <TableCell align="right"><TextInput label="" source="sel7" validate={[minLength(0),maxLength(20)]}/></TableCell>
              <TableCell align="right"></TableCell>
              
            </TableRow>
           
            <TableRow >
            <TableCell component="th"  rowSpan={3}>
            Muros
              </TableCell>
              <TableCell align="right">Grietas</TableCell>
              <TableCell align="right"><BooleanInput label="" source="bol13" fullWidth/></TableCell>
              <TableCell align="right"><NumberInput label="" source="num13"/></TableCell>
              <TableCell align="right"><TextInput label="" source="sel53" validate={[minLength(0),maxLength(20)]}/></TableCell>
              <TableCell align="right"></TableCell>
              
            </TableRow>
            <TableRow >
            <TableCell component="th">
            Dañada
              </TableCell>
              
              <TableCell align="right"><BooleanInput label="" source="bol14" fullWidth/></TableCell>
              <TableCell align="right"><NumberInput label="" source="num14"/></TableCell>
              <TableCell align="right"><TextInput label="" source="sel54" validate={[minLength(0),maxLength(20)]}/></TableCell>
              <TableCell align="right"></TableCell>
              
            </TableRow>
            <TableRow >
            <TableCell component="th">
            Desconchados
              </TableCell>
              
              <TableCell align="right"><BooleanInput label="" source="bol15" fullWidth/></TableCell>
              <TableCell align="right"><NumberInput label="" source="num15"/></TableCell>
              <TableCell align="right"><TextInput label="" source="sel55" validate={[minLength(0),maxLength(20)]}/></TableCell>
              <TableCell align="right"></TableCell>
              
            </TableRow>
            <TableRow >
            <TableCell component="th"  rowSpan={3}>
            Andenes/Bordillos
              </TableCell>
              <TableCell align="right">Pérdidas</TableCell>
              <TableCell align="right"><BooleanInput label="" source="bol17" fullWidth/></TableCell>
              <TableCell align="right"><NumberInput label="" source="num17"/></TableCell>
              <TableCell >                           
                   <SelectInput  choices={choices1}  label=""  source="sel16" fullWidth/>
              </TableCell>
              <TableCell align="right"></TableCell>
              
            </TableRow>
            <TableRow >
            <TableCell component="th">
            Grietas
              </TableCell>
              
              <TableCell align="right"><BooleanInput label="" source="bol18" fullWidth/></TableCell>
              <TableCell align="right"><NumberInput label="" source="num18"/></TableCell>
              <TableCell align="right">    <SelectInput  choices={choices1}  label=""  source="sel17" fullWidth/></TableCell>
              <TableCell align="right"></TableCell>
              
            </TableRow>
            <TableRow >
            <TableCell component="th">
            Desconchados
              </TableCell>
              
              <TableCell align="right"><BooleanInput label="" source="bol19" fullWidth/></TableCell>
              <TableCell align="right"><NumberInput label="" source="num19"/></TableCell>
              <TableCell align="right">    <SelectInput  choices={choices1}  label=""  source="sel18" fullWidth/></TableCell>
              <TableCell align="right"></TableCell>
              
            </TableRow>
           
            <TableRow >
            <TableCell component="th"  rowSpan={4}>
            Barreras de seguridad/barandillas de puentes/pretiles metálicos
              </TableCell>
              <TableCell align="right">Rotura o daños</TableCell>
              <TableCell align="right"><BooleanInput label="" source="bol24" fullWidth/></TableCell>
              <TableCell align="right"><NumberInput label="" source="num24"/></TableCell>
              <TableCell >                           
              <TextInput label="" source="sel23" validate={[minLength(0),maxLength(20)]}/>
              </TableCell>
              <TableCell align="right"></TableCell>
              
            </TableRow>
            <TableRow >
            <TableCell component="th">
            Conexiones en mal estado
              </TableCell>
              
              <TableCell align="right"><BooleanInput label="" source="bol50" fullWidth/></TableCell>
              <TableCell align="right"><NumberInput label="" source="num50"/></TableCell>
              <TableCell align="right">    <SelectInput  choices={choices1}  label=""  source="sel50" fullWidth/></TableCell>
              <TableCell align="right"></TableCell>
              
            </TableRow>
            <TableRow >
            <TableCell component="th">
            Protecciones en mal estado
              </TableCell>
              
              <TableCell align="right"><BooleanInput label="" source="bol51" fullWidth/></TableCell>
              <TableCell align="right"><NumberInput label="" source="num51"/></TableCell>
              <TableCell align="right">    <SelectInput  choices={choices1}  label=""  source="sel51" fullWidth/></TableCell>
              <TableCell align="right"></TableCell>
              
            </TableRow>
            <TableRow >
            <TableCell component="th">
            Grietas >5mm o desconchados >10mm
              </TableCell>
              
              <TableCell align="right"><BooleanInput label="" source="bol52" fullWidth/></TableCell>
              <TableCell align="right"><NumberInput label="" source="num52"/></TableCell>
              <TableCell align="right">    <SelectInput  choices={choices1}  label=""  source="sel52" fullWidth/></TableCell>
              <TableCell align="right"></TableCell>
              
            </TableRow>
            <TableRow >
            <TableCell component="th"  rowSpan={2}>
            Drenaje
              </TableCell>
              <TableCell align="right">Superficie erosionada</TableCell>
              <TableCell align="right"><BooleanInput label="" source="bol26" fullWidth/></TableCell>
              <TableCell align="right"><NumberInput label="" source="num26"/></TableCell>
              <TableCell >                           
              <SelectInput  choices={choices1}  label=""  source="sel25" fullWidth/>
              </TableCell>
              <TableCell align="right"></TableCell>
              
            </TableRow>
            <TableRow >
            <TableCell component="th">
            Daño estructural
              </TableCell>
              
              <TableCell align="right"><BooleanInput label="" source="bol27" fullWidth/></TableCell>
              <TableCell align="right"><NumberInput label="" source="num27"/></TableCell>
              <TableCell align="right">   <SelectInput  choices={choices1}  label=""  source="sel26" fullWidth/></TableCell>
              <TableCell align="right"></TableCell>
              
            </TableRow>
            
            
          
        </TableBody>
      </Table> 
            </Grid>
            </FormTab>
            </TabbedForm>
       
    </Edit>
);