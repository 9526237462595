import React, { useState, Fragment } from 'react';
import {
    SelectInput, ReferenceInput, BooleanInput, FormTab, TabbedForm, required, NumberInput, FormDataConsumer, minValue
} from 'react-admin';
import { PRInput } from '../../../inputs/PRInput';
import { PimsElementoActions } from './../../../helpers/PimsElementoActions';
import { Entero } from './../../../helpers/validacionesInputs';
import { styles } from './../../../EstilosGrid';
import { PimsCreate } from './../../Create';
import Grid from '@material-ui/core/Grid';
import { withStyles, Typography } from '@material-ui/core';
import config from '../../../config/config';
import { REDUX_FORM_NAME } from 'react-admin';
import { change } from 'redux-form';
import Divider from '@material-ui/core/Divider';
import { CurrencyInput } from './../../../inputs/CurrencyInput';
import AddElementosCostos from '../localHelpers/addElementosCostos';
import AddMasivoCostos from '../localHelpers/addMasivoCostos';
import AlertDialog from './../../../helpers/AlertDialog'
import { translate } from 'react-admin';

let urlAppjs = config.UrlServer;

export const CostosCreate = translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;
    const [datosArchivo, setdatosArchivo] = useState([]);
    const [valorGlobal, setValorGlobal] = useState();
    const prepareDatosArchivos = (txt, isGlobal = false) => {
        var array = [];
        var row = [];
        var sumaValorGlobal = 0;
        if (txt && typeof txt == "string") {
            row = txt.split("\n");
            var valorIndividual = (isGlobal && typeof valorGlobal == "string") ? parseFloat(valorGlobal.split("$")[1]) / row.length : null;
            row.map((item, index) => {
                if (index != 0 && item != "") {
                    sumaValorGlobal += parseFloat(item.split(";")[3]);
                    array.push({
                        codigo: item.split(";")[0],
                        tipoMantenimiento: item.split(";")[1],
                        fechaMantenimiento: crearFecha(item.split(";")[2]),
                        costoMantenimiento: isGlobal ? valorIndividual : item.split(";")[3],
                        proveedor: item.split(";")[4],
                        calificacion: item.split(";")[5],
                        observaciones: item.split(";")[6],
                    })
                }
            })
            if (array != datosArchivo) { setdatosArchivo(array); }
        }
        if (!isGlobal) { setValorGlobal(sumaValorGlobal) };
    }


    const emptyValorGlobal = () => { setValorGlobal("0"); }

    const validarValorGlobal = (value) => {
        setValorGlobal(value);
    }

    const crearFecha = (string) => {
        var splitted = string.split("/");
        var date = new Date(splitted[2], (parseInt(splitted[1]) - 1), splitted[0])
        return date;
    }
    const [getAbscisaInicial, setAbscisaInicial] = useState(0);
    const validarAbscisaInicial = (value) => {
        if (value) {
            var val = value.split("K")[1].split("+")
            val = val[0] + "." + val[1];
            val = parseFloat(val);
            setAbscisaInicial(val);
            if (val < rangoValidoAbscisas.inicial || val > rangoValidoAbscisas.final) {
                return translate('resources.validaciones.abscisaInicial') + `${rangoValidoAbscisas.inicial} y ${rangoValidoAbscisas.final}`
            }
        }
    }

    const [getAbscisaFinal, setAbscisaFinal] = useState(0);
    const validarAbscisaFinal = (value) => {
        if (value) {
            var val = value.split("K")[1].split("+")
            val = val[0] + "." + val[1];
            val = parseFloat(val);
            setAbscisaFinal(val);
            if (val < rangoValidoAbscisas.inicial || val > rangoValidoAbscisas.final) {
                return translate('resources.validaciones.abscisaFinal')+ `${rangoValidoAbscisas.inicial} y ${rangoValidoAbscisas.final}`
            }
        }
    }
    const [shouldShowElementType, setShoulShowElementType] = useState(true);
    const [shouldShowSignalElementType, setShouldShowSignalElementType] = useState(true);
    const [rangoValidoAbscisas, setRangoValidoAbscisas] = useState({ inicial: undefined, final: undefined });
    const fetchTramo = value => {
        if (value) {
            fetch(urlAppjs + "/tramos?properties[abInicio]&properties[abFin]&id=" + value,
                { headers: { 'authorization': 'Bearer ' + localStorage.getItem('token') } })
                .then((response) => { return response.json() })
                .then(tramo => setRangoValidoAbscisas({ inicial: tramo["hydra:member"][0].abInicio, final: tramo["hydra:member"][0].abFin }))
            // .finally(console.log(rangoValidoAbscisas))
        }

    }
    const [unidads, setUnidad] = useState('');
    const ValidarUnidad = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setUnidad(value)
    }

    return (
        <PimsCreate  {...props} >
            <TabbedForm
                redirect="list"
            //toolbar={<CustomToolbar />}
            >
                {/* ------ ELEMENTO DE FLUJO DE CAJA ------ */}
                <FormTab label="dynatest.resources.costos.tabs.identificacion" redirect="list" >
                    <Grid container justify="flex-start" alignItems="flex-end" spacing={24}>
                        <FormDataConsumer>
                            {
                                ({ formData, dispatch, ...rest }) => (
                                    <Fragment>
                                        <Grid item xs={3}>
                                            <ReferenceInput
                                                label="resources.costos.fields.unidadFuncional"
                                                source="unidadFuncional"
                                                reference="unidad_funcionals"
                                                filter={{ pagination: "off" }}
                                                filterToQuery={searchText => ({ codigo: searchText })}
                                                sort={{ field: 'codigo', order: 'asc' }}
                                                // onChange={value => dispatch(
                                                //     change(REDUX_FORM_NAME, 'tramo', null)
                                                // )}
                                                validate={[required(),ValidarUnidad]}
                                            >
                                                <SelectInput optionText="fullCodigos" options={{ fullWidth: true }} fullWidth />
                                            </ReferenceInput>
                                        </Grid>
                                        {unidads &&
                                        <Grid item xs={3}>
                                            <ReferenceInput
                                                label="resources.costos.fields.tramo"
                                                source="tramo"
                                                reference="tramos"
                                                sort={{ field: 'codigo', order: 'asc' }}
                                                filter={{
                                                    unidadFuncional: (typeof formData !== "undefined") ? formData.unidadFuncional : unidads,
                                                    pagination: "off"
                                                }}
                                                filterToQuery={searchText => ({ codigo: searchText })}
                                                // onChange={value => dispatch(
                                                //     change(REDUX_FORM_NAME, 'segmento', null)
                                                // )}
                                                validate={[required(), value => {
                                                    if (value) { fetchTramo(value) }
                                                }]}
                                            >
                                                <SelectInput optionText="fullCodigos" options={{ fullWidth: true }} fullWidth />
                                            </ReferenceInput>
                                        </Grid>
                                        }
                                        <Grid item xs={3}>
                                            <BooleanInput
                                                label="dynatest.resources.costos.boolean.rango"
                                                source="rango"
                                                defaultValue={false}
                                            // defaultValue={(typeof formData !== "undefined" && formData.segmento) ? false : true}
                                            />
                                        </Grid>
                                        <Grid item xs={3}> </Grid>
                                        {!unidads &&
                                        <Grid item xs={3}> </Grid>
                                        }
                                        {formData && formData.rango == false &&
                                        
                                            <Grid item xs={3}>
                                                <ReferenceInput
                                                    label="resources.costos.fields.segmento"
                                                    source="segmento"
                                                    reference="segmentos"
                                                    filter={{
                                                        tramo: (typeof formData !== "undefined") ? formData.tramo : null,
                                                        pagination: "off"
                                                    }}
                                                    sort={{ field: 'fullCodigos', order: 'ASC' }}
                                                    filterToQuery={searchText => ({ codigo: searchText })}
                                                // validate={
                                                //     (typeof formData !== "undefined" && formData.rango) ? [] :
                                                //         (typeof formData !== "undefined" && formData.isMasiveLoading) ? [required()] : []
                                                // }
                                                >
                                                    <SelectInput optionText="fullCodigos" options={{ fullWidth: true }} fullWidth />
                                                </ReferenceInput>
                                            </Grid>
                                        }

                                        {formData && formData.rango == true &&
                                            <Fragment>
                                                <Grid item xs={3}>
                                                    <PRInput
                                                        label="resources.costos.fields.abInicio"
                                                        source="abInicio"
                                                        // onChange={value => dispatch(
                                                        //     change(REDUX_FORM_NAME, 'segmento', null)
                                                        // )}
                                                        fullWidth
                                                        validate={(typeof formData !== "undefined" && formData.rango) ? [validarAbscisaInicial, required()] : [validarAbscisaInicial]}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <PRInput
                                                        label="resources.costos.fields.abFin"
                                                        source="abFin"
                                                        fullWidth validate={[validarAbscisaFinal]}
                                                        // onChange={value => dispatch(
                                                        //     change(REDUX_FORM_NAME, 'segmento', null)
                                                        // )}                                                      
                                                        validate={(typeof formData !== "undefined" && formData.rango) ? [validarAbscisaInicial, required()] : [validarAbscisaInicial]}
                                                    />
                                                </Grid>
                                            </Fragment>
                                        }
                                    </Fragment>
                                )
                            }
                        </FormDataConsumer>
                        <FormDataConsumer>
                            {
                                ({ formData, dispatch, ...rest }) => (
                                    <Fragment>
                                        <Grid item xs={3}>
                                            <ReferenceInput
                                                label="resources.costos.fields.sistema"
                                                source="sistema"
                                                reference="sistemas"
                                                filterToQuery={searchText => ({ nombre: searchText })}
                                                filter={{ visibleOnPolicies: 1 }}
                                                validate={[required()]}
                                                onChange={value => {
                                                    dispatch(change(REDUX_FORM_NAME, 'tipoElemento', null))
                                                    dispatch(change(REDUX_FORM_NAME, 'tipoElementoSenal', null))
                                                    dispatch(change(REDUX_FORM_NAME, 'tipoSenal', null))
                                                    setShoulShowElementType(false)
                                                }}
                                                fullWidth
                                            >
                                                <SelectInput optionText="nombreEnPoliticas" fullWidth />
                                            </ReferenceInput>
                                        </Grid>
                                        {formData && formData.sistema !== undefined && formData.sistema == "/sistemas/SEN" &&
                                            <Grid item xs={3}>
                                                <ReferenceInput
                                                    label="resources.costos.fields.tipoElementoSenal"
                                                    source="tipoElementoSenal"
                                                    reference="tipo_elemento_senals"
                                                    sort={{ field: 'nombre', order: 'ASC' }}
                                                    filterToQuery={searchText => ({ nombre: searchText })}
                                                    validate={[required()]}
                                                    onChange={value => {
                                                        dispatch(change(REDUX_FORM_NAME, 'tipoSenal', null))
                                                        setShouldShowSignalElementType(false)
                                                    }}
                                                    fullWidth
                                                >
                                                    <SelectInput optionText="nombre" fullWidth />
                                                </ReferenceInput>
                                            </Grid>

                                        }
                                        {setShouldShowSignalElementType(true)}
                                        {formData && formData.sistema !== undefined && formData.sistema == "/sistemas/SEN" && shouldShowSignalElementType &&
                                            <Grid item xs={3}>
                                                <ReferenceInput
                                                    label="resources.costos.fields.tipoSenal"
                                                    source="tipoSenal"
                                                    reference="tipo_senals"
                                                    sort={{ field: 'nombre', order: 'ASC' }}
                                                    filter={{
                                                        tipoElementoSenal: (typeof formData !== "undefined") ? formData.tipoElementoSenal : null
                                                    }}
                                                    filterToQuery={searchText => ({ nombre: searchText })}
                                                    validate={
                                                        // (typeof formData !== "undefined" && formData.isMasiveLoading) ?
                                                        /*(typeof formData !== "undefined" && formData.tipoElementoSenal !== "/tipo_elemento_senals/SV") ?
                                                            [required()]
                                                            :*/
                                                            []
                                                        // :
                                                        // []
                                                    }
                                                    fullWidth
                                                >
                                                    <SelectInput optionText="nombre" fullWidth />
                                                </ReferenceInput>
                                            </Grid>

                                        }
                                        {setShoulShowElementType(true)}
                                        {formData && formData.sistema !== undefined && formData.sistema != "/sistemas/SEN" && shouldShowElementType &&
                                            <Grid item xs={3}>
                                                <ReferenceInput
                                                    label="resources.costos.fields.tipoElemento"
                                                    source="tipoElemento"
                                                    reference="tipo_elementos"
                                                    sort={{ field: 'nombre', order: 'ASC' }}
                                                    filter={{
                                                        sistema: (typeof formData !== "undefined") ? formData.sistema : null
                                                    }}
                                                    filterToQuery={searchText => ({ nombre: searchText })}
                                                    validate={
                                                        (typeof formData !== "undefined") ?
                                                            (typeof formData !== "undefined" && (formData.sistema === "/sistemas/CLZ" || formData.sistema === "/sistemas/PTS")) ?
                                                                [required()]
                                                                :
                                                                []
                                                            :
                                                            []
                                                    }
                                                    fullWidth
                                                >
                                                    <SelectInput optionText="nombre" fullWidth />
                                                </ReferenceInput>
                                            </Grid>
                                        }
                                    </Fragment>
                                )
                            }
                        </FormDataConsumer>

                    </Grid>
                    <Divider fullWidth />
                    <br />
                    <Grid container justify="flex-end" alignItems="flex-end" spacing={24}>
                        <FormDataConsumer>
                            {
                                ({ formData, dispatch, ...rest }) => (
                                    <Fragment>
                                        <Grid item xs={6}>
                                            {/* <Grid item xs={(formData && formData.isMasiveLoading) ? 6 : 12}> */}
                                            <BooleanInput
                                                label="resources.costos.fields.isMasiveLoading"
                                                source="isMasiveLoading"
                                                onChange={() => {
                                                    emptyValorGlobal()
                                                    dispatch(change(REDUX_FORM_NAME, 'valorGlobal', "0"))
                                                    console.log("changedIsMasiveLoading")
                                                }}
                                                defaultValue={true}
                                            />
                                        </Grid>
                                        {(formData && formData.isMasiveLoading) ?
                                            <Grid item xs={3}>
                                                {AlertDialog(translate('dynatest.resources.costos.modal.btnPlantilla'), 
                                                    translate('dynatest.resources.costos.modal.titleModal'), [
                                                        translate('dynatest.resources.costos.modal.text1'),
                                                        <strong><i>{translate('dynatest.resources.costos.modal.nameColumns')}</i></strong>,
                                                        translate('dynatest.resources.costos.modal.text2'),
                                                    <div>
                                                        <i>{translate('dynatest.resources.costos.modal.ejemplo1')}</i><br />
                                                        <i>{translate('dynatest.resources.costos.modal.ejemplo2')}</i><br />
                                                        <i>{translate('dynatest.resources.costos.modal.ejemplo3')}</i>
                                                    </div>,
                                                    translate('dynatest.resources.costos.modal.text3'),
                                                    translate('dynatest.resources.costos.modal.nota'),
                                                    translate('dynatest.resources.costos.modal.text4'),
                                                    <a href={`${urlAppjs}/plantilla_costos.csv`}>{translate('dynatest.resources.costos.modal.enlace')}</a>
                                                ])}
                                            </Grid>
                                            :
                                            // (formData && Array.isArray(formData.costoElementosOnBulkEdit) && formData.costoElementosOnBulkEdit[0].costoMantenimiento) ?
                                            //     <Grid item xs={3}>
                                            //         <Typography component="p" style={{ color: "#ff9800" }}>{`El costo global fue deshabilitado porque indicó un costo en los valores predeterminados.`}</Typography>
                                            //     </Grid>
                                            //     :
                                            //     <Grid item xs={3}>
                                            //         <BooleanInput
                                            //             label="¿Definir Costo Global?"
                                            //             source="isGlobalValue"
                                            //             onChange={value => {
                                            //                 setValorGlobal(0)
                                            //                 dispatch(change(REDUX_FORM_NAME, 'valorGlobal', "0"))
                                            //             }}
                                            //             defaultValue={false}
                                            //         />
                                            //     </Grid>
                                            null
                                        }
                                        <Grid item xs={(formData && formData.isMasiveLoading) ? 3 : 6}>
                                            <CurrencyInput
                                                label="resources.costos.fields.valorGlobal"
                                                source="valorGlobal"
                                                value={valorGlobal}
                                                fullWidth
                                                disabled={true}
                                            />
                                        </Grid>


                                        {formData && !formData.isMasiveLoading &&
                                            <Grid item xs={12}>
                                                <AddElementosCostos
                                                    unidadFuncional={formData.unidadFuncional}
                                                    tramo={formData.tramo}
                                                    segmento={formData.segmento}
                                                    sistema={formData.sistema}
                                                    tipoElemento={formData.tipoElemento}
                                                    tipoElementoSenal={formData.tipoElementoSenal}
                                                    tipoSenal={formData.tipoSenal}
                                                    abInicio={formData.abInicio}
                                                    abFin={formData.abFin}
                                                    isClone={(formData.hasOwnProperty("@id")) ? true : false}
                                                    valorGlobal={formData.isGlobalValue ? formData.valorGlobal : null}
                                                    isGlobalValue={formData.isGlobalValue}
                                                />
                                            </Grid>
                                        }

                                        {formData && formData.isMasiveLoading && 
                                            <Grid item xs={12}>
                                                <AddMasivoCostos
                                                    unidadFuncional={formData.unidadFuncional}
                                                    tramo={formData.tramo}
                                                    segmento={formData.segmento}
                                                    sistema={formData.sistema}
                                                    tipoElemento={formData.tipoElemento}
                                                    tipoElementoSenal={formData.tipoElementoSenal}
                                                    tipoSenal={formData.tipoSenal}
                                                    abInicio={formData.abInicio}
                                                    abFin={formData.abFin}
                                                    isClone={(formData.hasOwnProperty("@id")) ? true : false}
                                                    valorGlobal={formData.isGlobalValue ? formData.valorGlobal : null}
                                                />
                                            </Grid>
                                        }
                                    </Fragment>
                                )
                            }
                        </FormDataConsumer>
                    </Grid>
                </FormTab>
            </TabbedForm>
        </PimsCreate>
    )

}));
