import React, { useState, Fragment } from 'react';
import {
    TextInput, ReferenceInput, SelectInput, FormDataConsumer, NumberInput, AutocompleteInput,
    SimpleFormIterator, ArrayInput, Query, FormTab ,Button
} from 'react-admin';
import { PimsCreate } from '../Create';
import { PInput } from '../../inputs/PInput';
import { PRInput } from '../../inputs/PRInput';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { SimpleForm } from '../SimpleForm';
import {
    required,
    minLength,
    maxLength,
} from 'react-admin';
import {
    abValidationInicio, abValidationFin, validarDosDecimales, validarUnDecimal, validarAB, isFechaInvalida,
    Altitud, latMininima, lngMininima
} from '../../../src/resources/validacionesInputs';
import config from '../../config/config';
import DepartamentosMunicipios from './campos/DepartamentosMunicipios';
import GenerarSegmentos from './campos/generarSegmentos';
import { translate } from 'react-admin';

let urlAppjs = config.UrlServer;

const styles = {
    ocultar: {
        display: "none",
    },
    coord: { marginTop: '50px' },

};

export const GeneracionMasivaSegmentoEdit = translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;

    const [proyectos, setProyecto] = useState(window.localStorage.getItem('id_project2'));

    const [uf, setUf] = useState('none');
    const ufSet = (value) => {
        setUf(value);
        tramoSet('');
    }

    const [tramo, setTramo] = useState('');
    const tramoSet = (value) => {
        setTramo(value);
    }

    const [abIn, setAbIn] = useState('');
    const abInSet = (value) => {
        setAbIn(value);
    }

    const [longTotal, setLongTotal] = useState(0);
    const [longInicial, setLongInicial] = useState(0);
    const [longFinal, setLongFinal] = useState(0);

    const calcTotalAbs = (abFinAct) => {
        if (abIn && abFinAct) {
            let valAbIn = abIn.split('K');
            valAbIn = valAbIn[1].split('+');
            valAbIn = valAbIn[0] + valAbIn[1];

            let valAbFin = abFinAct.split('K');
            valAbFin = valAbFin[1].split('+');
            valAbFin = valAbFin[0] + valAbFin[1];
            setLongInicial(parseInt(valAbIn))
            setLongFinal(parseInt(valAbFin))

            if (parseInt(valAbIn) >= parseInt(valAbFin)) {
                document.getElementById('longTotal').value = '';
                setLongTotal(0);
                return translate('resources.validaciones.abscisaFinalDebeSerMayor');
            } else {
                document.getElementById('longTotal').value = parseFloat(valAbFin) - parseFloat(valAbIn);
                setLongTotal(parseFloat(valAbFin) - parseFloat(valAbIn));
            }
        }
    }

    const [valDefecto, setValDefecto] = React.useState(false);
    const [generarSegmentos, setGenerarSegmentos] = React.useState(false);
    const [dataDefecto, setDataDefecto] = React.useState({
        departamento: '',
        municipio: '',
        ini_latitud: '',
        ini_longitud: '',
        ini_altura: '',
        fin_latitud: '',
        fin_longitud: '',
        fin_altura: '',
        tipo_calzada: '',
        tipo_segmento: '',
        tipo_superficie: '',
    });
    const [segmentosMasivos, setSegmentosMasivos] = React.useState([]);

    const CancelarValDefecto = (value) => {
        setValDefecto(false);
        setGenerarSegmentos(false);
    };


    const GenerarSegmentosSet = (value) => {
        if (valDefecto == true) {
            if (document.getElementById('departamentoDefault')) {
                setDataDefecto((prevState) => ({
                    ...prevState,
                    departamento: document.getElementById('departamentoDefault').value
                }));
            }
            if (document.getElementById('municipioDefault')) {
                setDataDefecto((prevState) => ({
                    ...prevState,
                    municipio: document.getElementById('municipioDefault').value
                }));
            }
        }
        setValDefecto(false);
        setGenerarSegmentos(!generarSegmentos)

    };

    const valDefectoClick = (value) => {
        setValDefecto(!valDefecto);
        setGenerarSegmentos(false);
    };

    const departamentoDefault = (value) => {
        console.log("departamentoDefault  = " + value)
        setDataDefecto((prevState) => ({
            ...prevState,
            departamento: value
        }));
    };

    const latInicialMinDefault = (value) => {
        setDataDefecto((prevState) => ({
            ...prevState,
            ini_latitud: value
        }));
    };
    const longInicialMinDefault = (value) => {
        setDataDefecto((prevState) => ({
            ...prevState,
            ini_longitud: value
        }));
    };
    const altInicialMinDefault = (value) => {
        setDataDefecto((prevState) => ({
            ...prevState,
            ini_altura: value
        }));
    };

    const latFinalMinDefault = (value) => {
        setDataDefecto((prevState) => ({
            ...prevState,
            fin_latitud: value
        }));
    };
    const longFinalMinDefault = (value) => {
        setDataDefecto((prevState) => ({
            ...prevState,
            fin_longitud: value
        }));
    };
    const altFinalMinDefault = (value) => {
        setDataDefecto((prevState) => ({
            ...prevState,
            fin_altura: value
        }));
    };

    const tipoCalzadaDefault = (value) => {
        setDataDefecto((prevState) => ({
            ...prevState,
            tipo_calzada: value
        }));
    };

    const tipoSegmentoDefault = (value) => {
        setDataDefecto((prevState) => ({
            ...prevState,
            tipo_segmento: value
        }));
    };

    const tipoSuperficieDefault = (value) => {
        setDataDefecto((prevState) => ({
            ...prevState,
            tipo_superficie: value
        }));
    };


    return (
        <PimsCreate title={"Generación Masiva de Segmentos"} {...props}>
            <SimpleForm submitOnEnter={false} redirect="list" >
                <Grid container spacing={24}>
                    <Grid item xs={4}>
                        <ReferenceInput
                            id="unidadFuncional"
                            label="resources.generacion_masiva_segmentos.fields.unidadFuncional"
                            source="unidadFuncional"
                            reference="unidad_funcionals"
                            filterToQuery={searchText => ({ codigo: searchText })}
                            sort={{ field: 'codigo', order: 'asc' }}
                            validate={[ufSet, required()]}
                            alwaysOn
                            fullWidth
                        >
                            <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
                        </ReferenceInput>
                    </Grid>
                    {uf &&
                        <Grid item xs={4}>
                            <ReferenceInput
                                label="resources.generacion_masiva_segmentos.fields.tramo"
                                source="tramo"
                                alwaysOn
                                reference="tramos"
                                filter={{ unidadFuncional: uf }}
                                validate={[tramoSet, required()]}
                                filterToQuery={searchText => ({ codigo: searchText })}
                                sort={{
                                    field: 'codigo',
                                    order: 'ASC',

                                }}>
                                <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
                            </ReferenceInput>
                        </Grid>
                    }
                </Grid>
                {uf && tramo &&
                    <Grid container spacing={24}>
                        <Grid item xs={4}>
                            <PRInput label="resources.generacion_masiva_segmentos.fields.absSegIni" id="ingresoAbInicio" source="absSegIni" fullWidth validate={[abInSet, abValidationInicio, required()]} />
                        </Grid>
                        <Grid item xs={4}>
                            <PRInput label='resources.generacion_masiva_segmentos.fields.absSegFin' id="ingresoAbFin" source='absSegFin' fullWidth validate={[calcTotalAbs, abValidationFin, required()]} />
                        </Grid>
                        <Grid item xs={4}>
                            <NumberInput label='resources.generacion_masiva_segmentos.fields.longTotal' id="longTotal" fullWidth options={{ disabled: true }} />
                        </Grid>
                    </Grid>
                }
                {longTotal > 0 &&
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <Button variant="outlined" color="primary" label="dynatest.resources.generacion_masiva_segmentos.button.asigValSegmentos" onClick={valDefectoClick}>
                            </Button>
                        </Grid>
                        <Grid style={(valDefecto == true ? {} : { display: "none" })} container spacing={24}>
                            <Grid item xs={12}>
                                <DepartamentosMunicipios departamentoDefault={dataDefecto.departamento} municipioDefault={dataDefecto.municipio} />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="b" component="b">{translate('dynatest.resources.generacion_masiva_segmentos.title.coorIniciales')}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="b" component="b">{translate('dynatest.resources.generacion_masiva_segmentos.title.coorFinales')}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <NumberInput source="latitudInicial" id="latitudInicial" label="resources.generacion_masiva_segmentos.fields.latitud" fullWidth validate={[latMininima, latInicialMinDefault]} />
                            </Grid>
                            <Grid item xs={2}>
                                <NumberInput source="longitudInicial" id="longitudInicial" label="resources.generacion_masiva_segmentos.fields.longitud" fullWidth validate={[lngMininima, longInicialMinDefault]} />
                            </Grid>
                            <Grid item xs={2}>
                                <NumberInput source="alturaInicial" id="alturaInicial" label="resources.generacion_masiva_segmentos.fields.altura" validate={[Altitud, altInicialMinDefault]} fullWidth />
                            </Grid>
                            <Grid item xs={2}>
                                <NumberInput source="latitudFinal" id="latitudFinal" label="resources.generacion_masiva_segmentos.fields.latitud" fullWidth validate={[latMininima, latFinalMinDefault]} />
                            </Grid>
                            <Grid item xs={2}>
                                <NumberInput source="longitudFinal" id="longitudFinal" label="resources.generacion_masiva_segmentos.fields.longitud" fullWidth validate={[lngMininima, longFinalMinDefault]} />
                            </Grid>
                            <Grid item xs={2}>
                                <NumberInput source="alturaFinal" id="alturaFinal" label="resources.generacion_masiva_segmentos.fields.altura" validate={[Altitud, altFinalMinDefault]} fullWidth />
                            </Grid>

                            <Grid item xs={12}>
                                <b>{translate('dynatest.resources.generacion_masiva_segmentos.title.caracteristicas')}</b>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>

                            <Grid item xs={4}>
                                <ReferenceInput
                                    label="resources.generacion_masiva_segmentos.fields.tipoCalzada"
                                    source="tipoCalzada"
                                    reference="tipo_calzadas"
                                    fullWidth
                                    validate={[tipoCalzadaDefault]}
                                    sort={{ field: 'customOrder', order: 'ASC' }}
                                >
                                    <SelectInput optionText="nombre" />
                                </ReferenceInput>
                            </Grid>

                            <Grid item xs={4}>
                                <ReferenceInput
                                    label="resources.generacion_masiva_segmentos.fields.tipoSegmento"
                                    source="tipoSegmento"
                                    reference="tipo_segmentos"
                                    fullWidth
                                    validate={[tipoSegmentoDefault]}
                                    sort={{ field: 'customOrder', order: 'ASC' }}
                                >
                                    <SelectInput optionText="nombre" />
                                </ReferenceInput>
                            </Grid>

                            <Grid item xs={4}>
                                <ReferenceInput
                                    label="resources.generacion_masiva_segmentos.fields.tipoSuperficie"
                                    source="tipoSuperficie"
                                    reference="tipo_elementos"
                                    fullWidth
                                    validate={[tipoSuperficieDefault]}
                                    filter={{ sistema: 'CLZ' }}
                                    sort={{ field: 'customOrder', order: 'ASC' }}
                                >
                                    <SelectInput optionText="nombre" />
                                </ReferenceInput>
                            </Grid>

                            <Grid item xs={3} style={{ display: "flex" }} alignItems="flex-end">
                                <Button variant="outlined" color="primary" label="dynatest.resources.generacion_masiva_segmentos.button.generSegmentos" onClick={GenerarSegmentosSet}></Button>
                            </Grid>

                            <Grid item xs={3} style={{ display: "flex" }} alignItems="flex-end" onClick={CancelarValDefecto}>
                                <Button variant="outlined" color="secondary" label="dynatest.resources.generacion_masiva_segmentos.button.cancelar"></Button>
                            </Grid>


                        </Grid>
                    </Grid>
                }
                {longTotal > 0 && valDefecto == false &&
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <Button variant="outlined" color="primary" label="dynatest.resources.generacion_masiva_segmentos.button.generSegmentos" onClick={GenerarSegmentosSet}>
                            </Button>
                        </Grid>
                    </Grid>
                }
                {longTotal > 0 &&
                    <FormTab label="GenerarSegmentos">
                        <GenerarSegmentos uf={uf} tramo={tramo} displayGenSegmento={generarSegmentos} label="GenerarSegmentos" dataDefecto={dataDefecto} longTotal={longTotal} longInicial={longInicial} longFinal={longFinal} />
                    </FormTab>
                }
                {proyectos &&
                    <Query type="GET_ONE" resource="proyectos" payload={{ id: proyectos }} >
                        {({ data, loading, error }) => {
                            if (data) {
                                return (
                                    // style={{display : 'none'}}
                                    <Grid item xs={3}>
                                        <TextInput id="lngMin" style={{ display: 'none' }} defaultValue={data.longitud1} source="longituds" fullWidth />
                                        <TextInput id="latMin" style={{ display: 'none' }} defaultValue={data.latitud1} source="latituds" fullWidth />
                                        <TextInput id="lngMax" style={{ display: 'none' }} defaultValue={data.longitud2} source="longituds2" fullWidth />
                                        <TextInput id="latMax" style={{ display: 'none' }} defaultValue={data.latitud2} source="latituds2" fullWidth />
                                        <TextInput id="alturaIni" style={{ display: 'none' }} defaultValue={data.alturaIni} source="altutaInis" fullWidth />
                                        <TextInput id="alturaFin" style={{ display: 'none' }} defaultValue={data.alturaFin} source="altutaFins" fullWidth />
                                    </Grid>
                                )
                            }
                            return <div></div>;
                        }}
                    </Query>
                }
            </SimpleForm>
        </PimsCreate>
    )
}

));
const WithProps = ({ children, ...props }) => children(props);