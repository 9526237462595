import React, {useState} from 'react';
import { SelectInput,Filter,ReferenceInput,List, Datagrid, TextField, EditButton, ShowButton, AutocompleteInput,
         ReferenceField, BooleanField } 
         from 'react-admin';
import {  BulkDeleteWithConfirmButton, Loading  } from 'react-admin';
import { Fragment } from 'react';
import { PRField } from './../../fields/PRField';
import { PField } from './../../fields/PField';
import Button from '@material-ui/core/Button';
import { translate } from 'react-admin';
import { CardActions, CreateButton, ExportButton } from 'react-admin';
const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);
const ListAction = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
        <ExportButton basePath={basePath}/>
    </CardActions>
);
const ListAction2 = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
    </CardActions>
);
const FiltroSeguridad= translate((props) => {
    const { translate } = props;
    const [uf, setUf] = useState('none');
    const ufSet = (value) => {
        setUf(value);
    }
    const [tramo, setTramo] = useState('none');
    const tramoSet = (value) => {
        setTramo(value);
    }
        
    return(
    <Filter {...props}>
        <ReferenceInput 
            id="unidadFuncional"
            source="unidadFuncional"  
            reference="unidad_funcionals" 
            filterToQuery={searchText => ({ codigo: searchText })} 
            sort={{field : 'codigo', order : 'asc'}}
            validate={[ufSet]}
            alwaysOn
            fullWidth 
        >
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
        <ReferenceInput 
            source="tramo" 
            alwaysOn 
            reference="tramos" 
            filter={{ unidadFuncional: uf}}
            validate={[tramoSet]}
            filterToQuery={searchText => ({ codigo: searchText })} 
            sort={{ 
                field: 'codigo', 
                order: 'ASC' 
            }}>
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
        
        <ReferenceInput 
            source="segmento" 
            alwaysOn 
            reference="segmentos" 
            sort={{ field: 'codigo', order: 'ASC' }}
            filter={{tramo : tramo}}
            filterToQuery={searchText => ({ codigo: searchText })} 
        >
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
        <SelectInput 
            label="resources.seguridads.fields.tipoElementoDesc" 
            source="tipoElementoDesc"  
            sort={{field : 'name' , order :'ASC'}} 
            alwaysOn 
            resettable
            choices={[
                {id : translate('dynatest.resources.seguridads.select.tipoElementoDesc.barreraConcretoPendSimple') , name : 'dynatest.resources.seguridads.select.tipoElementoDesc.barreraConcretoPendSimple'},
                {id : translate('dynatest.resources.seguridads.select.tipoElementoDesc.barreraPerfilF') , name : 'dynatest.resources.seguridads.select.tipoElementoDesc.barreraPerfilF'},
                {id : translate('dynatest.resources.seguridads.select.tipoElementoDesc.barreraJersey') , name : 'dynatest.resources.seguridads.select.tipoElementoDesc.barreraJersey'},
                {id : translate('dynatest.resources.seguridads.select.tipoElementoDesc.bordillo') , name : 'dynatest.resources.seguridads.select.tipoElementoDesc.bordillo'},
                {id : translate('dynatest.resources.seguridads.select.tipoElementoDesc.captafaros') , name : 'dynatest.resources.seguridads.select.tipoElementoDesc.captafaros'},
                {id : translate('dynatest.resources.seguridads.select.tipoElementoDesc.cerramientoProvisional') , name : 'dynatest.resources.seguridads.select.tipoElementoDesc.cerramientoProvisional'},
                {id : translate('dynatest.resources.seguridads.select.tipoElementoDesc.defensa') , name : 'dynatest.resources.seguridads.select.tipoElementoDesc.defensa'},
                {id : translate('dynatest.resources.seguridads.select.tipoElementoDesc.pretilesPuentes') , name : 'dynatest.resources.seguridads.select.tipoElementoDesc.pretilesPuentes'},
                {id : translate('dynatest.resources.seguridads.select.tipoElementoDesc.topeVehicular') , name : 'dynatest.resources.seguridads.select.tipoElementoDesc.topeVehicular'},   
            ]}
        />
        <SelectInput label="resources.seguridads.fields.estado" source="estado" alwaysOn sort={{field : 'name' , order :'ASC'}}
                                resettable  choices={[
                                    { id: translate('dynatest.resources.seguridads.select.estado.activo'), name: 'dynatest.resources.seguridads.select.estado.activo' },
                                    { id: translate('dynatest.resources.seguridads.select.estado.inactivo'), name: 'dynatest.resources.seguridads.select.estado.inactivo' },
                            ]} fullWidth
                            
                            />
        {/* <SelectInput 
            fullWidth 
            label="Status" 
            source="status"   
            alwaysOn  
            choices={[
                {id : true , name : 'Activo'},
                {id : false , name : 'Inactivo'}
            ]}
        /> */}
        
    </Filter>
    )
});

export const SeguridadList =({ permissions, ...props }) => {
    if (!permissions) return <Loading />;  
    return (
        <List {...props} 
            title="dynatest.resources.seguridads.title.showTitle" 
            sort={{ field: 'updatedAt', order: 'DESC' }} 
            actions={permissions['seguridad']  == 1 ? <ListAction /> : permissions['seguridad']  == 2 ? <ListAction2 />: null}
            filters={<FiltroSeguridad/>} 
            bulkActionButtons={permissions['seguridad']  == 1 ? <PostBulkActionButtons />: null}>
            <Datagrid>
                <TextField label="resources.seguridads.fields.smartCode" source="smartCode" sortBy="smartCode"/>
                <TextField label="resources.seguridads.fields.tipoElementoDesc" fullWidth source="tipoElementoDesc" sortBy="tipoElementoDesc"/>
                <PRField label="resources.seguridads.fields.abInicio" fullWidth source="abInicio"/>
                <PField label="resources.seguridads.fields.pInicio" fullWidth source="pInicio"/>
                <TextField fullWidth label="resources.seguridads.fields.margen" source="margen"/>
                <TextField fullWidth label="resources.seguridads.fields.estado" source="estado" />
                {permissions['seguridad'] == 1 || permissions['seguridad'] == 2 || permissions['seguridad'] == 4 ? <ShowButton/>: null}
                {permissions['seguridad'] == 1 || permissions['seguridad'] == 2 ? <EditButton/>: null}       
            </Datagrid>
        </List>
)};