import React ,{useState}from 'react';
import { CardActions, Toolbar, TabbedForm, ShowController, ShowView,TextField, 
    DateField,ReferenceField, BooleanField, TextInput,FormTab,NumberField,
    ListButton,EditButton 
} from 'react-admin';
import { PRField } from './../../../fields/PRField';
import { PField } from './../../../fields/PField';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core';
import  {styles}  from './../../../EstilosGrid';
import { GMapField } from '../../../fields/GMapField';
import { ButtonCarousel } from './../../images/ButtonCarousel';
import { translate } from 'react-admin';


export const ElementoContencionShow  = translate(withStyles(styles)(({ classes, record, ...props }) => {
    const { translate } = props;
    const [imagen , setImagen] =useState(true);
    const [senalId , setSenalId] =useState(0);
     const ValidarImagen =  (value) =>{
        // console.log(value + "Aqui est la imagen ")
        if(value !== null && value !== undefined && value !== 'undefined')
        {
            let lenghtObject = Object.keys(value);
            let valor = '';        
            for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                
            setImagen(value) 
            // console.log(value + "Aqui est la imagen ")
        }
     }
    //let elemento = "/tipo_elementos/COMR";
    return(
        <ShowController {...props} title="Información Elemento Contención">
        {controllerProps =>
            <ShowView {...props} {...controllerProps} title="dynatest.resources.elemento_contencions.title.showTitle"  actions={<ButtonCarousel />}>
                <TabbedForm toolbar={false}>                
                    <FormTab label="dynatest.resources.elemento_contencions.tabs.tabsTitle.identificacion">
                        <TextField label="resources.elemento_contencions.fields.smartCode" source="smartCode" fullWidth formClassName={classes.grid_cont6_f}/>
                         <br/>
                         <TextInput source="tipoElemento_.src" validate={ValidarImagen} formClassName={classes.ocultar}/>
                        <ReferenceField label="resources.elemento_contencions.fields.tipoElemento" source="tipoElemento" reference="tipo_elementos" linkType={false} fullWidth formClassName={classes.grid_cont6_f}>
                            <TextField  source="nombre"  />
                        </ReferenceField> 
                        <br/>
                        <TextField label="resources.elemento_contencions.fields.margen" source="margen" fullWidth formClassName={classes.grid_cont4_f} /> 
                        <TextField label="resources.elemento_contencions.fields.sentido" source="sentido" fullWidth formClassName={classes.grid_cont4} />
                        <TextField source="tipo" label="resources.elemento_contencions.fields.tipo" fullWidth formClassName={classes.grid_cont4_s}/> 
                        <br/>
                        <TextField label="resources.elemento_contencions.fields.funcionalidad" source="funcionalidad" fullWidth formClassName={classes.grid_cont4_f}/> 

                        <Typography variant="b" component="b">{translate('dynatest.resources.elemento_contencions.subTitle.localizacion')}</Typography> 
                        <Divider fullWidth style={{width:'300%',}} />                         
                        
                        <ReferenceField label="resources.elemento_contencions.fields.unidadFuncional" source="unidadFuncional" reference="unidad_funcionals" linkType={false} fullWidth formClassName={classes.grid_cont4_f}>    
                            <TextField source="fullCodigos" />       
                        </ReferenceField>
                        <ReferenceField label="resources.elemento_contencions.fields.tramo" source="tramo" reference="tramos" linkType={false} fullWidth formClassName={classes.grid_cont4_s}>    
                            <TextField source="fullCodigos" />       
                        </ReferenceField> 
                        <ReferenceField label="resources.elemento_contencions.fields.segmento" source="segmento" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont4_s}>    
                            <TextField source="fullCodigos" />       
                        </ReferenceField>   
                        <PRField label="resources.elemento_contencions.fields.abInicio" id="ingresoAbFin" source="abInicio" fullWidth formClassName={classes.grid_cont4_f}/>
                        <PRField label="resources.elemento_contencions.fields.abFin" id="ingresoAbFin" source="abFin" fullWidth formClassName={classes.grid_cont4_s}/>
                        <br/>
                        <PField label="resources.elemento_contencions.fields.pInicio" source="pInicio" fullWidth formClassName={classes.grid_cont4_f} />
                        <PField label="resources.elemento_contencions.fields.pFin" source="pFin" fullWidth formClassName={classes.grid_cont4_s} />
                        <br/>    
                        <br/>

                        <Typography variant="b" component="b">{translate('dynatest.resources.elemento_contencions.subTitle.coordenadasIniciales')}</Typography>
                        <Divider fullWidth style={{width:'300%', marginTop :'10px'}} /> 
                                                                        
                        <NumberField label="resources.elemento_contencions.fields.latitud" source="latitudInicial" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s}/>
                        <NumberField label="resources.elemento_contencions.fields.longitud" source="longitudInicial" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s}/> 
                        <br/>

                        <Typography variant="b" component="b">{translate('dynatest.resources.elemento_contencions.subTitle.coordenadasFinales')}</Typography>
                        <Divider fullWidth style={{width:'300%' , marginTop :'10px'}} />                     
                        
                        <NumberField label="resources.elemento_contencions.fields.latitud" source="latitudFinal" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s}/>
                        <NumberField label="resources.elemento_contencions.fields.longitud" source="longitudFinal" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s}/>
                        <GMapField   fullWidth formClassName={classes.grid_cont12}
                            defaultZoom={12}                                    
                            defaultCenter={{ lat: 4.7567039, lng: -74.0311056 }}
                            source="ubicacionMapa"
                            multipleMarkers="true"
                            googleKey="AIzaSyCivTXzJBYRBK8nosBYRFXnv-hGXIbhnYY"
                            icon= {imagen} 
                         /> 
                    </FormTab>

                    <FormTab label="dynatest.resources.elemento_contencions.tabs.tabsTitle.caracteristicas">                       
                        <NumberField label="resources.elemento_contencions.fields.largo" source="largo" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_f}/>
                        <NumberField label="resources.elemento_contencions.fields.ancho" source="ancho" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s}/>
                        <NumberField label="resources.elemento_contencions.fields.altura" source="altura" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s}/>
                        <br/>
                        <BooleanField label="dynatest.resources.elemento_contencions.boolean.estudioEstabilidad" source="estudioEstabilidad" fullWidth formClassName={classes.grid_cont12}/>
                        {controllerProps.record && controllerProps.record.estudioEstabilidad  === true &&
                            <TextField label="resources.elemento_contencions.fields.consultor" source="consultor" fullWidth formClassName={classes.grid_cont4_f}/>                          
                        }
                        {controllerProps.record && controllerProps.record.estudioEstabilidad  === true &&
                            <DateField label="resources.elemento_contencions.fields.fechaEstudio"  source="fechaEstudio" fullWidth formClassName={classes.grid_cont4_s} />                          
                        }
                        {controllerProps.record && controllerProps.record.estudioEstabilidad  === true &&
                            <br/>                          
                        }
                        {controllerProps.record && controllerProps.record.estudioEstabilidad  === true &&
                            <TextField label="resources.elemento_contencions.fields.codigoEstudio"  source="codigoEstudio" fullWidth formClassName={classes.grid_cont4_f}/>                          
                        }
                        {controllerProps.record && controllerProps.record.estudioEstabilidad  === true &&
                            <TextField label="resources.elemento_contencions.fields.nombreEstudio"  source="nombreEstudio"fullWidth formClassName={classes.grid_cont4_s} />                          
                        }
                    </FormTab>
                    <FormTab label="dynatest.resources.elemento_contencions.tabs.tabsTitle.detalles">                    
                        <TextField label="resources.elemento_contencions.fields.estado" fullWidth source="estado" formClassName={classes.grid_cont4_f}/>                                                  
                        {controllerProps.record && controllerProps.record.estado  === translate('dynatest.resources.elemento_contencions.select.estado.Reemplazo') &&
                            <TextField label="resources.elemento_contencions.fields.motivoReemplazo" source="motivoReemplazo" fullWidth formClassName={classes.grid_cont4_s}/>                          
                        }
                        {controllerProps.record && controllerProps.record.estado  === translate('dynatest.resources.elemento_contencions.select.estado.Reemplazo') &&
                            
                            <ReferenceField 
                                label="resources.elemento_contencions.fields.elementoReemplazo" 
                                source="elementoReemplazo" 
                                reference="elemento_contencions" 
                                linkType={false} 
                                fullWidth 
                                formClassName={classes.grid_cont4_s}
                            >
                                <TextField  source="smartCode"  />
                            </ReferenceField> 
                        }
                        <br/>
                        
                        <TextField label="resources.elemento_contencions.fields.estados" source="estados" fullWidth formClassName={classes.grid_cont4}/>
                        <br/>
                        <DateField  label="resources.elemento_contencions.fields.fechaInstalacion" source="fechaInstalacion" fullWidth formClassName={classes.grid_cont4_f}/>                          
                        <TextField  label="resources.elemento_contencions.fields.proveedor" source="proveedor_.razonSocial" fullWidth formClassName={classes.grid_cont4_s}/>                          
                        <NumberField  label="resources.elemento_contencions.fields.garantia" source="garantia" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s}/> 
                        <br/>                         
                        <BooleanField label="dynatest.resources.elemento_contencions.boolean.mantenimiento" source="mantenimiento" fullWidth formClassName={classes.grid_cont4_f}/>
                        <br/>
                        <TextField label="resources.elemento_contencions.fields.observaciones" source="observaciones" fullWidth formClassName={classes.grid_cont12}/>    
                        
                    </FormTab>
                    <FormTab label="dynatest.resources.elemento_contencions.tabs.tabsTitle.auditoria">
                        <DateField source="createdAt" label="resources.elemento_contencions.fields.createdAt" fullWidth formClassName={classes.grid_cont4} showTime/> 
                        <TextField source="createdBy" label="resources.elemento_contencions.fields.createdBy" fullWidth formClassName={classes.grid_cont4}/>
                        <TextField source="createdIn" label="resources.elemento_contencions.fields.createdEn" fullWidth formClassName={classes.grid_cont3} />
                        <span></span>               
                        <DateField source="updatedAt" label="resources.elemento_contencions.fields.updatedAt" fullWidth formClassName={classes.grid_cont4} showTime/>
                        <TextField source="updatedBy" label="resources.elemento_contencions.fields.updatedBy" fullWidth formClassName={classes.grid_cont4}/>
                        <TextField source="updatedIn" label="resources.elemento_contencions.fields.updatedEn" fullWidth formClassName={classes.grid_cont3} />
                        <span></span>    
                    </FormTab>                
                </TabbedForm>
            </ShowView>
        }
    </ShowController>
    )
}));