import React, { Fragment, useState } from 'react';
import {
    BulkDeleteWithConfirmButton, BooleanInput, ReferenceArrayInput, AutocompleteArrayInput, SimpleForm, Toolbar, CardActions, List, Datagrid, TextField, EditButton, ShowButton, ReferenceInput, Filter,
    SelectInput, CreateButton
} from 'react-admin'
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import config from '../../../../config/config';
//import FiltroPeru from '../../../../filtro/FiltroPeru';
let urlAppjs = config.UrlServer;
let UnidadFuncionalFilter = '';
let TramoFilter = '';
let SegmentoFilter = '';

const FiltroPeru = (props) => {
    const [uf, setUf] = useState();
    const ufSet = (value) => {
        setUf(value);
        UnidadFuncionalFilter = value;
    }
    const [tramo, setTramo] = useState();
    const tramoSet = (value) => {
        setTramo(value);
        TramoFilter = value;
    }
    const [segmento, setSegmento] = useState();
    const segmentoSet = (value) => {
        setSegmento(value);
        SegmentoFilter = value;
    }
    const [elemento, setElemento] = useState();
    const elementoSet = (value) => {
        setElemento(value);
    }
    const [tipoelemento, setTipoelemento] = useState();
    const tipoelementoSet = (value) => {
        setTipoelemento(value);
    }


    return (
        <div>
            <Filter {...props}>
                <ReferenceArrayInput
                    id="unidadFuncional"
                    label="Unidad Funcional"
                    source="unidadFuncional"
                    reference="unidad_funcionals"
                    filterToQuery={searchText => ({ codigo: searchText })}
                    sort={{ field: 'codigo', order: 'asc' }}
                    validate={[ufSet]}
                    alwaysOn
                    fullWidth

                >
                    <AutocompleteArrayInput options={{ fullWidth: true }} optionText="fullCodigos" />
                </ReferenceArrayInput>
                <ReferenceArrayInput
                    label="Tramo"
                    source="tramo"
                    alwaysOn
                    reference="tramos"
                    filter={{ unidadFuncional: uf }}
                    validate={[tramoSet]}
                    filterToQuery={searchText => ({ codigo: searchText })}
                    sort={{ field: 'codigo', order: 'ASC', }}>
                    <AutocompleteArrayInput options={{ fullWidth: true }} optionText="fullCodigos" />
                </ReferenceArrayInput>
                <ReferenceArrayInput
                    label="Segmento"
                    source="segmento"
                    reference="segmentos"
                    alwaysOn
                    sort={{ field: 'codigo', order: 'ASC' }}
                    filter={{ tramo: tramo }}
                    filterToQuery={searchText => ({ codigo: searchText })}
                    validate={[segmentoSet]}
                >
                    <AutocompleteArrayInput options={{ fullWidth: true }} optionText="fullCodigos" />
                </ReferenceArrayInput>
                <ReferenceInput
                    label="Elemento"
                    source="tipoElementoSenal"
                    reference="tipo_elemento_senal_perus"
                    validate={elementoSet}
                    alwaysOn
                    sort={{ field: 'nombre', order: 'ASC' }}
                    filter={{ segmento: segmento }}
                >
                    <SelectInput optionText="nombre" />
                </ReferenceInput>
                <SelectInput label="Sentido del tránsito" alwaysOn source="elemento_senalizacion.sentidoTransito" choices={[
                    { id: "Únicamente Creciente (UC) ", name: 'Únicamente Creciente (UC)' },
                    { id: "Únicamente Decreciente (UD)", name: 'Únicamente Decreciente (UD)' },
                ]} />

            </Filter>
            <Button color="primary" onMouseDown={() => props.setFilters({
                'elemento_senalizacion.sentidoTransito': '',
                tipoElementoSenal: '',
                segmento: '',
                tramo: '',
                unidadFuncional: ''
            })} >
                Limpiar Filtros
            </Button>
        </div>
    )

}

function SimpleDialog(props) {
    const { onClose, open } = props;

    const handleClose = value => {
        onClose(value);
    };

    const handleListItemClick = value => {
        onClose(value);
    };
    const [unidads, setUnidad] = useState('');
    const ValidarUnidad = (value) => {
        if (value != undefined) {
            setUnidad(value)
        }
    }
    const [unidads2, setUnidad2] = useState('');
    const ValidarUnidad2 = (value) => {
        if (value != undefined) {
            setUnidad2(value)
        }
    }
    const Unidades = { unidadFuncional: unidads };
    const Unidades2 = { unidadFuncional: unidads2 };

    const [tramos2, setTramo2] = useState('');
    const ValidarTramo2 = (value) => {
        if (value != undefined) {
            setTramo2(value)
        }
    }
    const Tramoss2 = { tramo: tramos2 };
    const [segmentos2, setSegmento2] = useState('');
    const ValidarSegmento2 = (value) => {
        if (value != undefined) {
            setSegmento2(value)
        }
    }

    const [tramos, setTramo] = useState('');
    const ValidarTramo = (value) => {
        if (value != undefined) {
            setTramo(value)
        }
    }
    const Tramoss = { tramo: tramos };
    const [segmentos, setSegmento] = useState('');
    const ValidarSegmento = (value) => {
        if (value != undefined) {
            setSegmento(value)
        }
    }

    const [reportCheck, setReportCheck] = useState(false);
    const ValidateReport = (value) => {
        if (value != undefined) {
            if (value == false) {
                setReportCheck(value)
            } else {
                let checked = value['target']['checked']
                setReportCheck(checked)
            }
        }
    }

    const CustomToolbar = ({ basePath, data, ...props }) => (
        <Toolbar {...props} >
            <Button variant="secondary" id="generarInforme" onClick={handleListItemClick2} style={{ backgroundColor: '#3f51b5', color: '#fff' }}>
                <AddIcon /> Generar Reporte
            </Button>
        </Toolbar>
    );

    const handleListItemClick2 = props => {
        const dataPost = new FormData();
        if (reportCheck === false) {
            if (unidads == "") {
                alert("Debe seleccionar la Unidad Funcional");
                return;
            }
            if (tramos == "") {
                alert("Debe seleccionar el tramo");
                return;
            }
            if (segmentos == "") {
                alert("Debe seleccionar el segmentos");
                return;
            }
            dataPost.append('reportCheck', reportCheck);
            dataPost.append('unidads', unidads);
            dataPost.append('tramos', tramos);
            dataPost.append('segmentos', segmentos);

        } else {
            if (unidads2 == "") {
                alert("Debe seleccionar la Unidad Funcional");
                return;
            }
            if (tramos2 == "") {
                alert("Debe seleccionar el tramo");
                return;
            }
            if (segmentos2 == "") {
                alert("Debe seleccionar el segmentos");
                return;
            }

            dataPost.append('reportCheck', reportCheck);
            dataPost.append('unidads2', unidads2);
            dataPost.append('tramos2', tramos2);
            dataPost.append('segmentos2', segmentos2);
        }

        fetch(urlAppjs + '/reportIndicadores/', {
            method: 'POST',
            body: dataPost
        })
            .then(res => res.text())
            .then(datos => {
                if (datos == "Sin datos") {
                    alert("No se encontraron datos.");
                } else {
                    var myWindow = window.open('', '', 'width=800,height=600');
                    if (myWindow == null || typeof (myWindow) == 'undefined') {
                        alert('Por favor deshabilita el bloqueador de ventanas emergentes y vuelve a hacer clic en "Generar Reporte".');
                    }
                    else {
                        myWindow.document.write(datos);
                        myWindow.document.close();
                        myWindow.focus();
                        setTimeout(function () { myWindow.print(); }, 2000);
                    }
                }
            });

    };

    return (
        <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} fullWidth={'fullWidth'} maxWidth={'md'}>
            <DialogTitle id="simple-dialog-title">Crear Reporte</DialogTitle>
            <SimpleForm toolbar={<CustomToolbar />}>
                <Grid container spacing={24}>
                    <Grid item xs={5} style={{ textAlign: "right" }}>
                        <h3><strong>Reporte de estado</strong></h3>
                    </Grid>
                    <Grid item xs={1}>
                        <BooleanInput label=" " source="reporte" defaultValue={false} onChange={ValidateReport} fullWidth />
                    </Grid>
                    <Grid item xs={5}>
                        <h3><strong>Reporte individual</strong></h3>
                    </Grid>
                    <br></br>
                    {reportCheck == false &&
                        <Grid item xs={4}>
                            <ReferenceInput label="Unidad Funcional" source="unidadFuncional" reference="unidad_funcionals"
                                validate={[ValidarUnidad]}
                                sort={{
                                    field: "codigo",
                                    order: "ASC"
                                }}
                            >
                                <SelectInput optionText="codigo" fullWidth />
                            </ReferenceInput>
                        </Grid>
                    }
                    {reportCheck == false &&
                        <Grid item xs={4}>
                            {unidads &&
                                <ReferenceInput label="Tramo" source="tramo" reference="tramos" validate={[ValidarTramo]} filter={Unidades}>
                                    <SelectInput optionText="codigo" fullWidth />
                                </ReferenceInput>
                            }
                        </Grid>
                    }
                    {reportCheck == false &&
                        <Grid item xs={4}>
                            {tramos &&
                                <ReferenceInput label="Segmento" source="segmento" reference="segmentos" validate={[ValidarSegmento]} filter={Tramoss} sort={{ field: 'codigo', order: 'ASC' }}>
                                    <SelectInput optionText="codigo" fullWidth />
                                </ReferenceInput>
                            }
                        </Grid>
                    }
                    {reportCheck == false &&
                        <br></br>
                    }
                    {reportCheck == true &&
                        <Grid item xs={4}>
                            <ReferenceInput label="Unidad Funcional" source="unidadFuncional2" reference="unidad_funcionals"
                                validate={[ValidarUnidad2]}
                                sort={{
                                    field: "codigo",
                                    order: "ASC"
                                }}
                            >
                                <SelectInput optionText="codigo" fullWidth />
                            </ReferenceInput>
                        </Grid>
                    }
                    {reportCheck == true &&
                        <Grid item xs={4}>
                            {unidads2 &&
                                <ReferenceInput label="Tramo" source="tramo2" reference="tramos" validate={[ValidarTramo2]} filter={Unidades2}>
                                    <SelectInput optionText="codigo" fullWidth />
                                </ReferenceInput>
                            }
                        </Grid>
                    }
                    {reportCheck == true &&
                        <Grid item xs={4}>
                            {tramos2 &&
                                <ReferenceInput label="Segmento" source="segmento2" reference="segmentos" validate={[ValidarSegmento2]} filter={Tramoss2} sort={{ field: 'codigo', order: 'ASC' }}>
                                    <SelectInput optionText="codigo" fullWidth />
                                </ReferenceInput>
                            }
                        </Grid>
                    }
                    {reportCheck == true &&
                        <br></br>
                    }
                </Grid>
            </SimpleForm>
        </Dialog>
    );
}

SimpleDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};


const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props} />
    </Fragment>
);

const PostEditActions = ({ basePath, data, resource }) => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = value => {
        setOpen(false);
    };
    return (
        <CardActions>
            {/* <LinkToMapa style={{paddingTop:"13px", marginRight:"20px"}}/> */}
            <CreateButton basePath={basePath} style={{ paddingTop: "13px", marginRight: "20px" }} />
            <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                <AddIcon /> Reportes
            </Button>
            <SimpleDialog open={open} onClose={handleClose} />
        </CardActions>
    );
};

export const EvaluacionSVList = props => {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = value => {
        setOpen(false);
    };

    return (
        <List {...props} filters={<FiltroPeru />} perPage={5} sort={{ field: 'id', order: 'DESC' }} title="Elementos Evaluación" actions={<PostEditActions />} bulkActionButtons={<PostBulkActionButtons />}>
            <Datagrid>
                <TextField source="elemento_senalizacion_.fullCodigo" reference="elemento_senalizacion_perus" label="Elemento señalización" />
                <TextField source="fecha" label="Fecha" />
                <TextField source="medicion_global" label="Medición global" />
                <ShowButton />
                <EditButton />
            </Datagrid>
        </List>
    );
};