import React, { useState, useEffect } from 'react';
import {
    TextField, DateField, NumberField, ReferenceField, ListButton, EditButton,
    SelectInput, BooleanField, ShowController, ShowView, TabbedForm, FormTab, CardActions
} from 'react-admin';
import { withStyles} from '@material-ui/core';
import TablaReparacion from './TablaReparacion';
import { GMapField } from '../../../fields/GMapField';
import { PRField } from '../../../fields/PRField';
import { PField } from '../../../fields/PField';
import { styles } from './../../../EstilosGrid';
import config from '../../../config/config';
import { ButtonCarousel } from './../../images/ButtonCarousel';
import { translate } from 'react-admin';

let urlAppjs = config.UrlServer;


export const ElementoPontonShow = translate(withStyles(styles)(({ classes, record, ...props }) => {
    const { translate } = props;

    const cardActionStyle = {
        zIndex: 2,
        display: 'inline-block',
        float: 'right',
    };
    const [IdElemento_pontons, setIdElemento_pontons] = useState(0);
    const [imagen , setImagen] =useState(true);
     const ValidarImagen =  (value) =>{
        // console.log(value + "Aqui est la imagen ")
        if(value !== null && value !== undefined && value !== 'undefined')
        {
            let lenghtObject = Object.keys(value);
            let valor = '';        
            for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                
            setImagen(value) 
            // console.log(value + "Aqui est la imagen ")
        }
     }

    useEffect(() => {
        var url = document.location.hash;
        var auxId = url.split("elemento_pontons%2F");
        var id = auxId[1].split("/");
        setIdElemento_pontons(id[0])
    });
    const PostShowActions = ({ basePath, data }) => (
        <CardActions style={cardActionStyle}>
            <EditButton basePath={basePath} record={data} />
            {/* Add your custom actions */}
            <ListButton basePath={basePath} />
        </CardActions>
    );
    return (
        <ShowController {...props} >
            {controllerProps =>
                <ShowView {...props} {...controllerProps}
                    title="dynatest.resources.elemento_pontons.title.showTitle"
                    actions={<ButtonCarousel />}
                >
                    <TabbedForm >
                        <FormTab label="dynatest.resources.elemento_pontons.tabs.tabsTitle.identificacion">
                            <TextField label="resources.elemento_pontons.fields.fullCodigo" source="fullCodigo" sortable={false} />
                            <span></span>
                            
       
                            <TextField label="resources.elemento_pontons.fields.margen" source="margen"  formClassName={classes.grid_cont4_f}/>
                            <TextField label="resources.elemento_pontons.fields.sentidoB" source="sentidoB" fullWidth formClassName={classes.grid_cont4_s} />
                            <TextField label="resources.elemento_pontons.fields.tipoPonton" source="tipoPonton" formClassName={classes.grid_cont4_s} />
                            <NumberField label="resources.elemento_pontons.fields.numeroCarriles" source="numeroCarriles" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_f}/>
                            <h3 style={{ marginBottom: 0 }}>{translate('dynatest.resources.elemento_pontons.subTitle.localizacion')}</h3>
                            <hr style={{ width: "100%" }} />
                            <ReferenceField label="resources.elemento_pontons.fields.unidadFuncional" source="unidadFuncional" reference="unidad_funcionals" linkType={false} fullWidth f formClassName={classes.grid_cont4_f}>
                                <TextField source="fullCodigos" />
                            </ReferenceField>
                            <ReferenceField label="resources.elemento_pontons.fields.tramo" source="tramo" reference="tramos" linkType={false} fullWidth  formClassName={classes.grid_cont4_s}>
                                <TextField source="fullCodigos" />
                            </ReferenceField>
                            <ReferenceField label="resources.elemento_pontons.fields.segmento" source="segmento" reference="segmentos" linkType={false} fullWidth  formClassName={classes.grid_cont4_s}>
                                <TextField source="fullCodigos" />
                            </ReferenceField>
                            
                            <PRField label="resources.elemento_pontons.fields.abInicio" source="abInicio" fullWidth  formClassName={classes.grid_cont4_f} />
                            <PRField label="resources.elemento_pontons.fields.abFin" source="abFin" fullWidth  formClassName={classes.grid_cont4_s} />
                            <NumberField label="resources.elemento_pontons.fields.fullResta" source="fullResta" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}}  formClassName={classes.grid_cont4_s} />
                            <PField label="resources.elemento_pontons.fields.pInicio" source="pInicio" fullWidth formClassName={classes.grid_cont4_f} />
                            <PField label="resources.elemento_pontons.fields.pFin" source="pFin" fullWidth formClassName={classes.grid_cont4_s} />
                            <br/>
                            <h3 style={{ marginBottom: 0 }}>{translate('dynatest.resources.elemento_pontons.subTitle.coordenadasIniciales')}</h3>
                            <hr style={{ width: "100%" }} />
                            <NumberField label="resources.elemento_pontons.fields.latitud" source="latitudInicial" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}}  formClassName={classes.grid_cont4_f} />
                            <NumberField label="resources.elemento_pontons.fields.longitud" source="longitudInicial" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}}  formClassName={classes.grid_cont4_s} />
                            <span></span>

                            <h3 style={{ marginBottom: 0 }}>{translate('dynatest.resources.elemento_pontons.subTitle.coordenadasFinales')}</h3>
                            <hr style={{ width: "100%" }} />
                            <NumberField label="resources.elemento_pontons.fields.latitud" source="latitudFinal" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_f} />
                            <NumberField label="resources.elemento_pontons.fields.longitud" source="longitudFinal" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s} />
                            <span></span>

                            <GMapField
                                defaultZoom={12}
                                defaultCenter={{ lat: 4.7567039, lng: -74.0311056 }}
                                source="ubicacionMapa"
                                multipleMarkers="true"
                                style={{ width: "100%" }}
                                googleKey="AIzaSyCivTXzJBYRBK8nosBYRFXnv-hGXIbhnYY"
                                icon={'imagenes/senalesColombia/Elementos/ponton/PTON.png' }
                            />
                        </FormTab >
                        <FormTab label="dynatest.resources.elemento_pontons.tabs.tabsTitle.datosAdministrativos">
                            <h3 style={{ marginBottom: 0 }}>{translate('dynatest.resources.elemento_pontons.subTitle.detallesConstructivos')}</h3>
                            <hr style={{ width: "100%" }} />
                            <DateField label="resources.elemento_pontons.fields.ano" source="ano" formClassName={classes.grid_cont3_f} options={{ timeZone:'UTC'}}/>
                            <NumberField label="resources.elemento_pontons.fields.area" source="area" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3_s} />
                            <TextField label="resources.elemento_pontons.fields.norma" source="norma" formClassName={classes.grid_cont3_s} />
                            <TextField label="resources.elemento_pontons.fields.disenador" source="disenador" formClassName={classes.grid_cont3_s} />
                            <br/>
                            <TextField label="resources.elemento_pontons.fields.info_di" source="info_di" />

                            <h3 style={{ marginBottom: 0 }}>{translate('dynatest.resources.elemento_pontons.subTitle.detRefuerzoRehabilit')}</h3>
                            <hr style={{ width: "100%" }} />

                            <DateField label="resources.elemento_pontons.fields.anoa" source="anoa" formClassName={classes.grid_cont3_f} options={{ timeZone:'UTC'}}/>
                            <NumberField label="resources.elemento_pontons.fields.areaa" source="areaa" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3_s}/>
                            <TextField label="resources.elemento_pontons.fields.normaa" source="normaa" formClassName={classes.grid_cont3_s}/>
                            <TextField label="resources.elemento_pontons.fields.disenadora" source="disenadora" formClassName={classes.grid_cont3_s}/>
                            <br/>
                            <TextField label="resources.elemento_pontons.fields.info_dia" source="info_dia" />
                            <br/>
                            <TextField label="resources.elemento_pontons.fields.obser_dia" source="obser_dia" />

                            <h3 style={{ marginBottom: 0 }}>{translate('dynatest.resources.elemento_pontons.subTitle.detallesInspeccion')}</h3>
                            <hr style={{ width: "100%" }} />

                            <DateField label="resources.elemento_pontons.fields.anob" source="anob" formClassName={classes.grid_cont4_f} options={{ timeZone:'UTC'}}/>
                            <TextField label="resources.elemento_pontons.fields.sentido" source="sentido" formClassName={classes.grid_cont4_s}/>
                            <TextField label="resources.elemento_pontons.fields.estacion_conteo" source="estacion_conteo_.nombre" formClassName={classes.grid_cont4_s}/>
                            <br/>
                            <TextField label="resources.elemento_pontons.fields.inspector" source="inspector" formClassName={classes.grid_cont4_f}/>
                            <NumberField label="resources.elemento_pontons.fields.inspeccion" source="inspeccion" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s}/>
                            <NumberField label="resources.elemento_pontons.fields.numinspeccion" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} source="numinspeccion" formClassName={classes.grid_cont4_s}/>

                            <h3 style={{ marginBottom: 0 }}>{translate('dynatest.resources.elemento_pontons.subTitle.miembrosInteresados')}</h3>
                            <hr style={{ width: "100%" }} />

                            <TextField label="resources.elemento_pontons.fields.propietario" source="propietario" formClassName={classes.grid_cont4_f}/>
                            <TextField label="resources.elemento_pontons.fields.admon_vial" source="admon_vial" formClassName={classes.grid_cont4_s}/>
                            <TextField label="resources.elemento_pontons.fields.proyectista" source="proyectista" formClassName={classes.grid_cont4_s}/>

                            <h3 style={{ marginBottom: 0 }}>{translate('dynatest.resources.elemento_pontons.subTitle.caractOperacionales')}</h3>
                            <hr style={{ width: "100%" }} />

                            <NumberField label="resources.elemento_pontons.fields.coeficiente" source="coeficiente" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_f} />
                            <TextField label="resources.elemento_pontons.fields.cauce" source="cauce" formClassName={classes.grid_cont4_s}/>
                            <br/>
                            <TextField label="resources.elemento_pontons.fields.variante" source="variante" formClassName={classes.grid_cont4_f}/>
                            <NumberField label="resources.elemento_pontons.fields.long_variante" source="long_variante" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s}/>
                            <TextField label="resources.elemento_pontons.fields.esta" source="esta" formClassName={classes.grid_cont4_s}/>

                            <h3 style={{ marginBottom: 0 }}>{translate('dynatest.resources.elemento_pontons.subTitle.capacidadCargaTransitoLegal')}</h3>
                            <hr style={{ width: "100%" }} />
                            
                            <TextField label="resources.elemento_pontons.fields.vehi_dise" source="vehi_dise" formClassName={classes.grid_cont4_f}/>
                            <TextField label="resources.elemento_pontons.fields.distri_carga" source="distri_carga" formClassName={classes.grid_cont4_s}/>
                            <NumberField  label="resources.elemento_pontons.fields.capa_carga" source="capa_carga" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s}/>
                            <br/>
                            <NumberField label="resources.elemento_pontons.fields.luz_critica" source="luz_critica" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_f}/>
                            <NumberField label="resources.elemento_pontons.fields.fac_clasi" source="fac_clasi" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s}/>
                            
                            <h3 style={{ marginBottom: 0 }}>{translate('dynatest.resources.elemento_pontons.subTitle.capacidadCargaTranspEspeciales')}</h3>
                            <hr style={{ width: "100%" }} />

                            <NumberField label="resources.elemento_pontons.fields.fue_cor" source="fue_cor" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_f}/>
                            <NumberField label="resources.elemento_pontons.fields.momento" source="momento" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s}/>
                            <NumberField label="resources.elemento_pontons.fields.linea_carga" source="linea_carga" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s}/>
                            <br/>
                            <TextField label="resources.elemento_pontons.fields.obs_1" source="obs_1" />

                        </FormTab >
                        <FormTab label="dynatest.resources.elemento_pontons.tabs.tabsTitle.geometria">
                            <h3 style={{ marginBottom: 0 }}>{translate('dynatest.resources.elemento_pontons.subTitle.general')}</h3>
                            <TextField label="resources.elemento_pontons.fields.pon_terra" source="pon_terra" formClassName={classes.grid_cont4_f}/>
                            <TextField label="resources.elemento_pontons.fields.pon_c" source="pon_c" formClassName={classes.grid_cont4_s}/>
                            <div></div>
                            <h3 style={{ marginBottom: 0 }}>{translate('dynatest.resources.elemento_pontons.subTitle.detallesEstructura')}</h3>
                            <hr style={{ width: "100%" }} />
                            <NumberField label="resources.elemento_pontons.fields.long_2" source="long_2"  fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_f}/>
                            <NumberField label="resources.elemento_pontons.fields.ancho_2" source="ancho_2"  fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s}/>
                            <NumberField label="resources.elemento_pontons.fields.espe_2" source="espe_2"  fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s}/>
                            <br/>
                            <NumberField label="resources.elemento_pontons.fields.altu_2" source="altu_2"  fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_f}/>
                            <NumberField label="resources.elemento_pontons.fields.ancho_3" source="ancho_3"  fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s}/>
                            <NumberField label="resources.elemento_pontons.fields.espe_3" source="espe_3"  fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s}/>
                            <br/>
                            <NumberField label="resources.elemento_pontons.fields.pro_3" source="pro_3"  fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_f}/>
                            <NumberField label="resources.elemento_pontons.fields.espe_4" source="espe_4" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s}/>
                            <NumberField label="resources.elemento_pontons.fields.sepa_4" source="sepa_4"  fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s}/>

                        </FormTab >
                        <FormTab label="dynatest.resources.elemento_pontons.tabs.tabsTitle.reparaciones">
                            <TablaReparacion tabla="elemento_pontons" id={IdElemento_pontons} />
                        </FormTab>
                        <FormTab label="dynatest.resources.elemento_pontons.tabs.tabsTitle.detalles">
                            <TextField label="resources.elemento_pontons.fields.estado" source="estado" formClassName={classes.grid_cont4_f}/>
                            {controllerProps.record && controllerProps.record.estado === translate('dynatest.resources.elemento_pontons.select.estado.Reemplazo') &&
                                <TextField label="resources.elemento_pontons.fields.motivoReemplazo" source="motivoReemplazo" formClassName={classes.grid_cont4_s}/>
                            }

                            {controllerProps.record && controllerProps.record.estado === translate('dynatest.resources.elemento_pontons.select.estado.Reemplazo') &&
                                <ReferenceField
                                    label="resources.elemento_pontons.fields.elementoReemplazo"
                                    source="elementoReemplazo"
                                    reference="elemento_pontons"
                                    linkType={false}
                                    fullWidth
                                    formClassName={classes.grid_cont4_s}
                                >
                                    <TextField source="fullCodigoConsecutivo" />
                                </ReferenceField>
                            }
                            {controllerProps.record && controllerProps.record.estado !== translate('dynatest.resources.elemento_pontons.select.estado.Reemplazo') && <br/>}
                            
                            
                            <TextField label="resources.elemento_pontons.fields.estados" source="estados" fullWidth formClassName={classes.grid_cont4_f}/>
                            <br/>
                            <DateField label="resources.elemento_pontons.fields.fecha" source="fecha" formClassName={classes.grid_cont4_f}/>
                            <ReferenceField
                                label="resources.elemento_pontons.fields.proveedor"
                                source="proveedor"
                                reference="proveedors"
                                linkType={false}
                                fullWidth
                                formClassName={classes.grid_cont4_s}
                            >
                                <TextField source="razonSocial" />
                            </ReferenceField>
                            <NumberField label="resources.elemento_pontons.fields.garantia" source="garantia"  fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}}formClassName={classes.grid_cont4_s}/>
                            <br/>
                            <BooleanField label="dynatest.resources.elemento_pontons.boolean.mantenimiento" source="mantenimiento" />
                            <br/>
                            <TextField source="observaciones" label="resources.elemento_pontons.fields.observaciones" />

                        </FormTab>
                        <FormTab label="dynatest.resources.elemento_pontons.tabs.tabsTitle.auditoria">
                            <DateField source="createdAt" label="resources.elemento_pontons.fields.createdAt" formClassName={classes.grid_cont4_f} showTime/>
                            <TextField source="createdBy" label="resources.elemento_pontons.fields.createdBy" formClassName={classes.grid_cont4_s}/>
                            <TextField source="createdIn" label="resources.elemento_pontons.fields.createdEn" fullWidth formClassName={classes.grid_cont3} />
                            <br/>
                            <DateField source="updatedAt" label="resources.elemento_pontons.fields.updatedAt" formClassName={classes.grid_cont4_f} showTime/>
                            <TextField source="updatedBy" label="resources.elemento_pontons.fields.updatedBy" formClassName={classes.grid_cont4_s}/>
                            <TextField source="updatedIn" label="resources.elemento_pontons.fields.updatedEn" fullWidth formClassName={classes.grid_cont3} />
                        </FormTab>
                    </TabbedForm>
                </ShowView>
            }
        </ShowController>

    )
}));