import React, { useState, Fragment } from 'react';
import {
    SimpleForm, ImageInput, ImageField, FormTab, TextInput, DateInput, ReferenceInput, SelectInput, FormDataConsumer, NumberInput, BooleanInput, SimpleFormIterator, ArrayInput, Query,
    Loading, Error, Datagrid, AutocompleteInput, DisabledInput
} from 'react-admin';
import { TabbedForm } from './../TabbedForm';
import { PimsCreate } from './../Create';
import { PInput } from './../../inputs/PInput';
import { PRInput } from './../../inputs/PRInput';
import { withStyles } from '@material-ui/core';
import { styles } from './../../EstilosGrid';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { FInput } from './../../inputs/FInput';
import {
    required,

} from 'react-admin';
import {
    abValidationInicio,
    ValorMaximoAbsCreate,
    latMininima,
    lngMininima,
    isFechaInvalida,
    validacionmaxfechaact
} from './../validacionesInputs';
import { Toolbar, SaveButton } from 'react-admin';
import CancelIcon from '@material-ui/icons/Cancel';
import config from '../../config/config';
import { minValue, maxValue, minLength, maxLength } from 'ra-core';
import { ImagenesInventario } from '../images/ImagenesInventario';
import { translate } from 'react-admin';
import AsociarServiciosEmergencia from './AsociarServiciosEmergencia';
import AsociarServicio from './AsociarServicio';
import AsociarServicioProveedor from './AsociarServicioProveedor';
let urlAppjs = config.UrlServer;
let urlAppjs2 = config.UrlFront;


const LinkCancelar = () => {
    let redirect = "disponibilidad_vias"
    return <a href={urlAppjs2 + redirect} class="cancelar" ><CancelIcon style={{ width: '0.8em', paddingRight: '8px' }} />CANCELAR</a>;
}

const CustomToolbar = ({ basePath, data, ...props }) => (
    <Toolbar {...props} >
        <SaveButton />
        <LinkCancelar />
    </Toolbar>
);
/*
const styles = {
    ocultar: {
        display:"none",
        
    },
    coord :{marginTop : '50px'},

};
*/
// const styles = {
//     ocultar: { display: "none", },
//     grid_cont2: { display: 'inline-block', width: '20%', marginLeft: '10px' },
//     grid_cont3: { display: 'inline-block', width: '24%', marginLeft: '10px' },
//     grid_cont4: { display: 'inline-block', width: '32%', marginLeft: '10px' },
//     grid_cont6: { display: 'inline-block', width: '49%', marginLeft: '10px' },
//     grid_cont12: { display: 'inline-block', width: '99%', marginLeft: '10px' },
//     "& div:empty": { display: 'none' }
// };

export const DisponibilidadCreate = translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;
    const [estado, setEstado] = useState('');
    const [horasen, setHoras] = useState('');
    const [causa, setCausa] = useState('');
    const ValidarEvento = (value) => {
        //console.log(value);
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setEstado(valor);

    }
    var today = new Date();
    var horaact = today.getHours();
    var minact = today.getMinutes();
    if (horaact.toString().length <= 1) {
        horaact = "0" + horaact;
    }

    if (minact.toString().length <= 1) {
        minact = "0" + minact;
    }
    //console.log(horaact.length+" AND "+minact.length)
    //console.log(typeof minact);
    var hora = horaact + ":" + minact;
    const HoraSenal = (value) => {
        /*let aux = value.split(':');
        let hora2 = parseInt(aux[0]);
        let minut2 = parseInt(aux[1]);
        let horacomp2 = (hora2*60) + minut2;
        let hora1 = parseInt(horaact);
        let minut1 = parseInt(minact);
        let horacomp1 = (hora1*60) + minut1;
        let res1 = horacomp1 - horacomp2;
        let horasenal=parseInt(res1/60);
        let minutsenal=parseInt(res1%60);
        if(horasenal.toString().length<=1){
            horasenal="0"+horasenal;
        }
        
        if(minutsenal.toString().length<=1){
            minutsenal="0"+minutsenal;
        }
        */
        if (document.getElementById('fecha_senal') && document.getElementById('Fechareport') && document.getElementById('hora_senal') && document.getElementById('Horardeporte')) {
            let fecha_senalizacion = document.getElementById('fecha_senal').value;
            let fecha_reporte = document.getElementById('Fechareport').value;
            let tiempo_senalizacion = document.getElementById('hora_senal').value;
            let tiempo_reporte = document.getElementById('Horardeporte').value;
            let tiemposplit = tiempo_reporte.split(':');
            let hora_reporte = tiemposplit[0];
            let minuto_reporte = tiemposplit[1];
            if (hora_reporte.toString().length <= 1) {
                hora_reporte = "0" + hora_reporte;
            }
            tiempo_reporte = hora_reporte + ":" + minuto_reporte;
            let fecha_inicial = fecha_reporte + "T" + tiempo_reporte;
            let fecha_final = fecha_senalizacion + "T" + tiempo_senalizacion;
            let resultado = RestarFechas(fecha_inicial, fecha_final);
            //console.log(resultado);
            setHoras(resultado);
        }
    }
    const [abValidaini, setAbValidaini] = useState('');
    const [abValidafin, setAbValidafin] = useState('');
    const [unidad, setUnidad] = useState('');
    const [longitudafect, setLongitud] = useState('');
    const [consect, setConsect] = useState('');
    const [longcl, setLongcl] = useState('');
    const [elementoaf, setElementoaf] = useState('');
    const [volumenderr, setVolumenderr] = useState('');
    const [condicionesesp, setCondicionesesp] = useState('');
    const [tiempodespeje, setTiempodespeje] = useState('');
    const [unidadfun, setUnidadfun] = useState('');
    const [abingini, setAbingini] = useState('');
    const [abingfin, setAbingfin] = useState('');
    const [segmentosafectados, setSegmentosafectados] = useState('');
    const [segmentosafectadosid, setSegmentosafectadosid] = useState('');
    const [proyectos, setProyecto] = useState(window.localStorage.getItem('id_project2'));

    const ValidarUnidad = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length); index++) { valor += value[index]; }
        let aux = valor.split('/');
        setUnidad(aux[2])
    }
    const validarUnifun = (value) => {
        if (document.getElementById('ufuncional')) {
            let unidadactual = document.getElementById('ufuncional').value;
            let idunidadactual = unidadactual.split('/');
            if (value && idunidadactual[2] != unidadfun) {
                let sperador1 = value.split('/');
                //console.log(urlAppjs);
                //console.log(sperador1[2]);
                setUnidadfun(sperador1[2]);
                fetch(urlAppjs + '/unidad_funcionals/' + sperador1[2])
                    .then((response) => {
                        //console.log("REST");
                        return response.json();
                    })
                    .then((response) => {
                        setAbValidaini(response['abInicio']);
                        setAbValidafin(response['abFin']);
                    })
            }
        }

    }
    const validarAbini = (value) => {
        //console.log("El valor es = "+value+"La abs = "+abValidaini);
        if (value && value.search("[\\K\\+]") != -1) {

            let sperador1 = value.split('K');
            let separador2 = sperador1[1].split('+');
            let cadena = separador2[0] + '.' + separador2[1]
            let absfin = document.getElementById('abfin').value;
            cadena = Number.parseFloat(cadena)
            if (cadena < abValidaini) {
                return translate("resources.validaciones.abscisaInicial") + abValidaini + ' y ' + abValidafin
            }
            else if (cadena > abValidafin) {
                return translate("resources.validaciones.abscisaInicial") + abValidaini + ' y ' + abValidafin
            }
            else if (absfin && absfin.search("[\\K\\+]") != -1) {
                let sperador1 = absfin.split('K');
                let separador2 = sperador1[1].split('+');
                let cadena2 = separador2[0] + '.' + separador2[1];
                cadena2 = Number.parseFloat(cadena2)


                if (cadena2 <= cadena) {

                    return translate("resources.validaciones.abscisaInicialDebeSerMenor");
                }
            }
        }
    };
    const validarAbfin = (value) => {
        //console.log(abValidafin);
        if (value && value.search("[\\K\\+]") != -1) {

            let sperador1 = value.split('K');
            let separador2 = sperador1[1].split('+');
            let cadena = separador2[0] + '.' + separador2[1]
            let absini = document.getElementById('abin').value;
            cadena = Number.parseFloat(cadena)
            if (cadena > abValidafin) {
                return translate("resources.validaciones.abscisaFinal") + abValidaini + " y " + abValidafin
            }
            if (cadena < abValidaini) {
                return translate("resources.validaciones.abscisaFinal") + abValidaini + " y " + abValidafin
            }
            if (absini && absini.search("[\\K\\+]") != -1) {
                let sperador1 = absini.split('K');
                let separador2 = sperador1[1].split('+');
                let cadena2 = separador2[0] + '.' + separador2[1];
                cadena2 = Number.parseFloat(cadena2)
                if (cadena2 >= cadena) {
                    return translate("resources.validaciones.abscisaFinalDebeSerMayor");
                }
            }
        }
    };
    const validarLongitudafect = (value) => {
        let abiniel = document.getElementById('abin')
        let abfinel = document.getElementById('abfin')
        if (abiniel && abfinel) {
            let abini = abiniel.value;
            let abfin = abfinel.value;
            if (abfin != "" && abini != "") {
                abini = abini.replace('K', '');
                abini = abini.replace('+', '.');
                abfin = abfin.replace('K', '');
                abfin = abfin.replace('+', '.');
                let res = (abfin - abini).toFixed(3);
                setLongitud(res);
            }
        }
    }
    const validarMaxl = (value) => {
        let largo = value.length;
        if (largo > 40) {
            return translate("resources.validaciones.max40");
        }
    }
    const obtenerdominio = () => {
        return urlAppjs;
    }
    const consecutivo1 = (value) => {
        let valores = value.split('/');
        let ufunc = valores[2];
        fetch(urlAppjs + '/disponibilidadViaCon/' + ufunc)
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                setConsect(response[0].toString());
                // if(response[0]){
                // let numero =parseInt(response[0]);
                // numero=numero+1;
                //     if(numero<=9){setConsect("00"+numero);}
                //     else {setConsect("0"+numero);}
                // }
            })

    }
    const validarCausa = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';

        for (let index = 0; index < (lenghtObject.length); index++) { valor += value[index]; }
        setCausa(valor)
    }
    const validarLongcl = (value) => {
        if(value && typeof value != 'undefined'){
            if (!Number.isInteger(value) || value < 0) {
                return translate("resources.validaciones.enteroPositivo");
            }
            else if (value > 300) {
                setLongcl("No Cumple");
            }
            else if (value <= 300) {
                setLongcl("Cumple");
            }
        }
    }
    const validarVolder = (value) => {
        if (!Number.isInteger(value) || value < 0) {
            return translate("resources.validaciones.enteroPositivo");
        }
        else {
            setVolumenderr(value);
        }
    }
    const validarElemento = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';

        for (let index = 0; index < (lenghtObject.length); index++) { valor += value[index]; }
        setElementoaf(valor);
    }
    const validarCondicionesesp = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';

        for (let index = 0; index < (lenghtObject.length); index++) { valor += value[index]; }
        setCondicionesesp(valor)
        //console.log(valor);
    }
    const valorTiempodespeje = () => {
        console.log("Volumen ->" + volumenderr + " cesp->" + condicionesesp);
        let aux = "";
        if (condicionesesp === "Disposición de Nuevos Dispositivos de Contención" && volumenderr > 2000) {
            aux = "1 semana";
        }
        else if (condicionesesp === "Mayores Dificultades" && volumenderr > 2000) {
            aux = "1 mes";
        }
        else if (volumenderr > 200 && volumenderr <= 2000 && elementoaf === "Calzada") {
            aux = "24";
        }
        else if (volumenderr > 200 && volumenderr <= 2000 && elementoaf === "Bermas") {
            aux = "24";
        }
        else if (volumenderr <= 200 && elementoaf === "Calzada") {
            aux = "4";
        }
        else if (volumenderr <= 200 && elementoaf === "Bermas") {
            aux = "24";
        }
        else {
            aux = "0";
        }
        setTiempodespeje(aux);
    }
    const validarTipoincidente = (value) => {
        let largo = value.length;
        if (largo > 40) {
            return translate("resources.validaciones.max40");
        }
    }
    const validarTipovehiculootro = (value) => {
        let largo = value.length;
        if (largo > 20) {
            return translate("resources.validaciones.max20");
        }
    }
    const validarCausaProbable = (value) => {
        let largo = value.length;
        if (largo > 200) {
            return translate("resources.validaciones.max200");
        }
    }
    const validarClasePolicia = (value) => {
        let largo = value.length;
        if (largo > 20) {
            return translate("resources.validaciones.max20");
        }
    }
    const validarcantidadheridos = (value) => {
        if (value < 0 || value > 100) {
            return translate("resources.validaciones.cantidadEntre");
        }
    }
    const validartipoheridos = (value) => {
        let largo = value.length;
        if (largo > 20) {
            return translate("resources.validaciones.max20");
        }
    }
    const validargeneroheridos = (value) => {
        let largo = value.length;
        if (largo > 10) {
            return translate("resources.validaciones.max10");
        }
    }
    const validaredadheridos = (value) => {
        if (value < 0 || value > 122) {
            return translate("resources.validaciones.edadEntre");
        }
    }
    const validartipovehiculo = (value) => {
        let largo = value.length;
        if (largo > 15) {
            return translate("resources.validaciones.max15");
        }
    }
    const validarobservaciones = (value) => {
        console.log(value)
        let largo = value.length;
        if (largo > 200) {
            return translate("resources.validaciones.max200");
        }
    }
    const validarsegmentosafect = (value) => {
        let abiniel = document.getElementById('abin')
        let abfinel = document.getElementById('abfin')
        if (abiniel && abfinel) {
            let abini = abiniel.value;
            let abfin = abfinel.value;
            if (abfin != "" && abini != "") {
                abini = abini.replace('K', '');
                abini = abini.replace('+', '.');
                abfin = abfin.replace('K', '');
                abfin = abfin.replace('+', '.');
                //console.log("abini ="+abini+" abingini= "+abingini)    
                if (abini != abingini || abfin != abingfin || unidadfun != unidad) {
                    setAbingini(abini);
                    setAbingfin(abfin);
                    fetch(obtenerdominio() + "/disponibilidadViaSeg/" + unidadfun + "/" + abini + "/" + abfin)
                        .then((response) => {
                            return response.json();
                        })
                        .then((response) => {
                            let largo = response.codigoseg.length;
                            let valor = "";
                            for (let i = 0; i < largo; i++) {
                                valor = valor + response.codigoseg[i] + "; ";
                            }
                            if (valor === "") {
                                valor = "Ningun Segmento Afectado"
                            }
                            setSegmentosafectados(valor);

                            largo = response.segid.length;
                            valor = "";
                            for (let i = 0; i < largo; i++) {
                                valor = valor + response.segid[i] + "; ";
                            }
                            if (valor === "") {
                                valor = ""
                            }
                            setSegmentosafectadosid(valor);
                        })
                        
                }
            }
        }
    }
    /*const validarDirectorio=(value)=>{
        let lenghtObject = Object.keys(value);
        let valor = '';
    
        for (let index = 0; index < (lenghtObject.length); index++) {  valor += value[index]; } 
        setDirectorio(value);
    }*/
    const RestarFechas = (fecha1, fecha2) => {
        let fecha_inicial = new Date(fecha1);
        let fecha_final = new Date(fecha2);
        let fecha_inicial_off = new Date(fecha_inicial.getTime() + Math.abs(fecha_inicial.getTimezoneOffset() * 60000));
        let fecha_final_off = new Date(fecha_final.getTime() + Math.abs(fecha_final.getTimezoneOffset() * 60000));
        let difference = fecha_final_off.getTime() - fecha_inicial_off.getTime();

        //let differencedays=difference/(1000*3600*24);
        //let differencehours=(difference/(1000*3600))%24;
        let differencehours2 = (difference / (1000 * 3600));
        let differenceminutes = (difference / (1000 * 60)) % 60;
        let hora_resultado = Math.abs(parseInt(differencehours2));
        let minuto_resultado = Math.abs(parseInt(differenceminutes));
        if (hora_resultado.toString().length <= 1) {
            hora_resultado = "0" + hora_resultado;
        }

        if (minuto_resultado.toString().length <= 1) {
            minuto_resultado = "0" + minuto_resultado;
        }
        let resultado = hora_resultado + ":" + minuto_resultado;

        return resultado
    }
    const timeFormatter = v => {
        if (v && typeof v != 'undefined') {
            let timeformat = v.split(':');
            let horaformat = timeformat[0];
            let minutoformat = timeformat[1];
            if (parseInt(horaformat) > 24) {
                horaformat = 23;
            }
            if (horaformat.length <= 1) {
                horaformat = "0" + horaformat;
            }
            if (parseInt(minutoformat) > 60) {
                minutoformat = 59;
            }
            if (minutoformat.length <= 1) {
                minutoformat = "0" + minutoformat;
            }
            let tiempofinal = horaformat + ":" + minutoformat;
            return tiempofinal;
        }
        else {
            return v;
        }
    }

    const [heridostotal, setHeridostotal] = useState(0);
    const sumHeridos = (arrData) => {
        let total = 0;
        console.log("sumHeridos")
        console.log(typeof arrData)
        if(typeof arrData !== 'undefined'){
            arrData.map(heridos => {
                if(heridos.cantidadHeridos !== undefined){
                    total = total + heridos.cantidadHeridos
                }
            })
        }
        setHeridostotal(total);
    }

    const [victimasftotal, setVictimasFTotal] = useState(0);
    const sumVictimasF = (arrData) => {
        let total = 0;
        console.log("sumVictimasF")
        console.log(arrData)
        if(typeof arrData !== 'undefined'){
            arrData.map(victimasf => {
                if(victimasf.cantidadVictimasf !== undefined){
                    total = total + victimasf.cantidadVictimasf
                }
            })
        }
        setVictimasFTotal(total);
    }

    const [info_proyecto, setInfoProyecto] = useState({});
    const busqInfoProyecto = () => {
        if (Object.keys(info_proyecto).length == 0) {
            fetch(urlAppjs + window.localStorage.getItem('id_project2'))
            .then((response) => {
                return response.json()
            })
            .then((response) => {
                setInfoProyecto(response)
            })
        }

    }
    busqInfoProyecto();
    /*OPCIONES PARA TIPO DE IMÁGENES*/
    const [defaultTipoImagen, setDefaultTipoImagen] = useState([{tipoImagen: '/tipo_images/22'},{tipoImagen: '/tipo_images/23'},{tipoImagen: '/tipo_images/24'},{tipoImagen: '/tipo_images/25'}]); //{tipoImagen: '/tipo_images/25'}
    const [defaultTipoImagenFinalizacion, setDefaultTipoImagenFinalizacion] = useState([{tipoImagen: '/tipo_images/25'}]);

    return (
        <PimsCreate  {...props} >
            <TabbedForm submitOnEnter={false} redirect="list">
                <FormTab label="dynatest.resources.disponibilidad_vias.tabs.tabsTitle.identificacion">
                    <TextInput label="resources.disponibilidad_vias.fields.consecutivo" fullWidth source="consecutivo" disabled formClassName={classes.grid_cont3s} validate={[required()]} defaultValue={consect} />
                    <DateInput label="resources.disponibilidad_vias.fields.fecha" fullWidth source="fecha" disabled validate={[required()]} defaultValue={today} formClassName={classes.grid_cont3} />
                    <SelectInput label="resources.disponibilidad_vias.fields.tipoEvento" fullWidth source="tipoEvento" choices={[
                        { id: translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente'), name: 'dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente' },
                        { id: translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente'), name: 'dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente' },
                        { id: translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Cierre'), name: 'dynatest.resources.disponibilidad_vias.select.tipoEvento.Cierre' }
                    ]} onChange={ValidarEvento} validate={[required()]} formClassName={classes.grid_cont3} />

                    {estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Cierre') &&

                        <SelectInput label="resources.disponibilidad_vias.fields.tipoCierre" fullWidth source="tipoCierre" choices={[
                            { id: translate('dynatest.resources.disponibilidad_vias.select.tipoCierre.Calzada'), name: 'dynatest.resources.disponibilidad_vias.select.tipoCierre.Calzada' },
                            { id: translate('dynatest.resources.disponibilidad_vias.select.tipoCierre.Carril'), name: 'dynatest.resources.disponibilidad_vias.select.tipoCierre.Carril' }
                        ]} formClassName={classes.grid_cont3} />

                    }
                    <br />
                    <Typography variant="b" component="b" formClassName={classes.grid_cont12}>{translate('dynatest.resources.disponibilidad_vias.subTitle.localizacion')}</Typography>
                    <Divider fullWidth formClassName={classes.grid_cont12} />
                    <ReferenceInput label="resources.disponibilidad_vias.fields.unidadFuncional" source="unidadFuncional" id="ufuncional" reference="unidad_funcionals" sort={{ field: 'codigo', order: 'asc' }} filterToQuery={searchText => ({ smartCode: searchText })} validate={[required(), ValidarUnidad, validarUnifun, consecutivo1, validarLongitudafect, validarsegmentosafect]} fullWidth formClassName={classes.grid_cont4s}>
                        <AutocompleteInput options={{ fullWidth: true, id: "ufuncional" }} optionText="codigo" />
                    </ReferenceInput>

                    {unidad &&
                        <PRInput label="resources.disponibilidad_vias.fields.AbscisaInicial" source="ninguno" id="abin" fullWidth formClassName={classes.grid_cont4} validate={[required(), ValorMaximoAbsCreate, abValidationInicio, validarAbini, validarLongitudafect, validarsegmentosafect]} />
                    }

                    {unidad && <NumberInput source="abscisaIni" id="abinihid" defaultValue={abingini} formClassName={classes.ocultar} />}
                    {unidad && <NumberInput source="abscisaFin" id="abfinhid" defaultValue={abingfin} formClassName={classes.ocultar} />}

                    {unidad &&
                        <PRInput label="resources.disponibilidad_vias.fields.AbscisaFinal" source="nada" id="abfin" fullWidth formClassName={classes.grid_cont4} validate={[required(), ValorMaximoAbsCreate, validarAbfin, validarLongitudafect, validarsegmentosafect]} />
                    }
                    {!unidad &&
                        <div formClassName={classes.grid_cont8}></div>
                    }

                    <SelectInput label="resources.disponibilidad_vias.fields.margen" fullWidth source="margen" choices={[
                        { id: translate('dynatest.resources.disponibilidad_vias.select.margen.Izquierda'), name: 'dynatest.resources.disponibilidad_vias.select.margen.Izquierda' },
                        { id: translate('dynatest.resources.disponibilidad_vias.select.margen.Derecha'), name: 'dynatest.resources.disponibilidad_vias.select.margen.Derecha' }
                    ]} validate={[required()]} formClassName={classes.grid_cont6s} />


                    <TextInput label="resources.disponibilidad_vias.fields.lugar" source="lugar" fullWidth formClassName={classes.grid_cont4} validate={[required(), validarMaxl]} maxLength={40} />


                    <TextInput label="resources.disponibilidad_vias.fields.longitudAfect" source="longitudAfect" fullWidth formClassName={classes.grid_cont3s} disabled validate={[required()]} defaultValue={longitudafect} />


                    {abingfin &&
                        /*<Query type="GET_ONE" resource="" payload={{ id: obtenerdominio()+"disponibilidadViaSeg/"+unidadfun+"/"+abingini+"/"+abingfin}} > 
                        {({ data, loading, error }) => {                                                
                           console.log(data)
                           if(data){
                                return <TextInput label="Segmentos Afectados" disabled defaultValue={
                                    data.map(function(valor){
                                        return valor+" ;"
                                    })
                                } source="segmentosAfect" fullWidth validate={[required()]}/>;
                            }else{
                             return   <TextInput label="Segmentos Afectados" disabled defaultValue="No afecta ningún segmento" source="segmentosAfect" fullWidth validate={[required()]}/>;
                            }                                                
                        }}
                        </Query> */
                        <TextInput label="resources.disponibilidad_vias.fields.segmentosAfect" disabled defaultValue={segmentosafectados} validate={[required()]} source="segmentosAfect" fullWidth multiline formClassName={classes.grid_cont3} />
                    }
                    {abingfin &&
                        <TextInput style={{ display: 'none' }} label="resources.disponibilidad_vias.fields.segmentosAfectId" disabled defaultValue={segmentosafectadosid} source="segmentosAfectId" fullWidth formClassName={classes.ocultar} />
                    }
                    
                    {!unidad &&
                        <div formClassName={classes.grid_cont3}></div>
                    }

                    <NumberInput label="resources.disponibilidad_vias.fields.latitud" source="latitud" fullWidth formClassName={classes.grid_cont3} validate={[required(), latMininima]} />

                    <NumberInput label="resources.disponibilidad_vias.fields.longitud" source="longitud" fullWidth formClassName={classes.grid_cont3} validate={[required(), lngMininima]} />

                    {/*
                        <Query type="GET_ONE" resource="proyectos" payload={{ id: proyectos }} formclassName={classes.ocultar}>
                            {({ data, loading, error }) => {
                                if (data) {
                                    return (
                                        // 
                                        <Grid item xs={3} className={classes.ocultar}>
                                            <TextInput id="lngMin" style={{ display: 'none' }} defaultValue={data.longitud1} source="longituds" fullWidth />
                                            <TextInput id="latMin" style={{ display: 'none' }} defaultValue={data.latitud1} source="latituds" fullWidth />
                                            <TextInput id="lngMax" style={{ display: 'none' }} defaultValue={data.longitud2} source="longituds2" fullWidth />
                                            <TextInput id="latMax" style={{ display: 'none' }} defaultValue={data.latitud2} source="latituds2" fullWidth />
                                        </Grid>
                                    )
                                }
                                return <div></div>;
                            }}
                        </Query>
                    */}
                    <Grid item xs={3} className={classes.ocultar}>
                        <TextInput id="lngMin" style={{ display: 'none' }} defaultValue={info_proyecto.longitud1} source="longituds" fullWidth />
                        <TextInput id="latMin" style={{ display: 'none' }} defaultValue={info_proyecto.latitud1} source="latituds" fullWidth />
                        <TextInput id="lngMax" style={{ display: 'none' }} defaultValue={info_proyecto.longitud2} source="longituds2" fullWidth />
                        <TextInput id="latMax" style={{ display: 'none' }} defaultValue={info_proyecto.latitud2} source="latituds2" fullWidth />
                    </Grid>

                </FormTab>
                <FormTab label="dynatest.resources.disponibilidad_vias.tabs.tabsTitle.detalles">
                    <DateInput label="resources.disponibilidad_vias.fields.fechaReporte" source="fechaReporte" fullWidth id="Fechareport" formClassName={classes.grid_cont4s} validate={[required(),isFechaInvalida]} />
                    <FInput label="resources.disponibilidad_vias.fields.horaReporte" source="horaReporte" id="Horardeporte" fullWidth formClassName={classes.grid_cont4} validate={[required(), HoraSenal]} format={timeFormatter} />
                    <br/>
                    <DateInput label="resources.disponibilidad_vias.fields.fechaSenal" source="fechaSenal" id="fecha_senal" fullWidth formClassName={classes.grid_cont4s} validate={[required()]} disabled defaultValue={today} />
                    <FInput label="resources.disponibilidad_vias.fields.horaSenal" source="horaSenal" id="hora_senal" fullWidth formClassName={classes.grid_cont4} validate={[required()]} defaultValue={hora} disabled />
                    <FInput label="resources.disponibilidad_vias.fields.tiempSenal" source="tiempSenal" id="tiempsenal" fullWidth formClassName={classes.grid_cont4} validate={[required()]} defaultValue={horasen} disabled />
                    <FInput label="resources.disponibilidad_vias.fields.horaFinal" source="horaFinal" formClassName={classes.ocultar} defaultValue={hora} />
                    <br/>
                    <SelectInput label="resources.disponibilidad_vias.fields.fuenteRep" fullWidth source="fuenteRep" choices={[
                        { id: translate('dynatest.resources.disponibilidad_vias.select.fuenteRep.Llamada'), name: 'dynatest.resources.disponibilidad_vias.select.fuenteRep.Llamada' },
                        { id: translate('dynatest.resources.disponibilidad_vias.select.fuenteRep.Email'), name: 'dynatest.resources.disponibilidad_vias.select.fuenteRep.Email' },
                        { id: translate('dynatest.resources.disponibilidad_vias.select.fuenteRep.Recorredor'), name: 'dynatest.resources.disponibilidad_vias.select.fuenteRep.Recorredor' }
                    ]} formClassName={classes.grid_cont4s} validate={[required()]} />


                    <SelectInput label="resources.disponibilidad_vias.fields.causa" fullWidth source="causa" choices={[
                        { id: translate('dynatest.resources.disponibilidad_vias.select.causa.Accidente'), name: 'dynatest.resources.disponibilidad_vias.select.causa.Accidente' },
                        { id: translate('dynatest.resources.disponibilidad_vias.select.causa.Derrumbe'), name: 'dynatest.resources.disponibilidad_vias.select.causa.Derrumbe' },
                        { id: translate('dynatest.resources.disponibilidad_vias.select.causa.TrabajosVia'), name: 'dynatest.resources.disponibilidad_vias.select.causa.TrabajosVia' },
                        { id: translate('dynatest.resources.disponibilidad_vias.select.causa.Manifestacion'), name: 'dynatest.resources.disponibilidad_vias.select.causa.Manifestacion' },
                        { id: translate('dynatest.resources.disponibilidad_vias.select.causa.OrdenPublico'), name: 'dynatest.resources.disponibilidad_vias.select.causa.OrdenPublico' },
                        { id: translate('dynatest.resources.disponibilidad_vias.select.causa.Otro'), name: 'dynatest.resources.disponibilidad_vias.select.causa.Otro' }
                    ]} formClassName={classes.grid_cont4} validate={[required(), validarCausa]} />

                    <SelectInput label="resources.disponibilidad_vias.fields.elementoAfect" fullWidth source="elementoAfect" choices={[
                        { id: translate('dynatest.resources.disponibilidad_vias.select.elementoAfect.Calzada'), name: 'dynatest.resources.disponibilidad_vias.select.elementoAfect.Calzada' },
                        { id: translate('dynatest.resources.disponibilidad_vias.select.elementoAfect.Puente'), name: 'dynatest.resources.disponibilidad_vias.select.elementoAfect.Puente' },
                        { id: translate('dynatest.resources.disponibilidad_vias.select.elementoAfect.Tunel'), name: 'dynatest.resources.disponibilidad_vias.select.elementoAfect.Tunel' },
                        { id: translate('dynatest.resources.disponibilidad_vias.select.elementoAfect.Bermas'), name: 'dynatest.resources.disponibilidad_vias.select.elementoAfect.Bermas' }
                    ]} formClassName={classes.grid_cont4} validate={[required(), validarElemento]} />

                    <TextInput label="resources.disponibilidad_vias.fields.observaciones" source="observaciones" fullWidth formClassName={classes.grid_cont12} validate={[required(),maxLength(200)]}  maxLength={200} multiline />

                    <Typography variant="b" component="b" formClassName={classes.grid_cont12}>{translate('dynatest.resources.disponibilidad_vias.subTitle.AsociarServicios')}</Typography>

                    <Divider fullWidth formClassName={classes.grid_cont12} />
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <ArrayInput label="" source="servicios" fullWidth>
                                <SimpleFormIterator>
                                    <SelectInput label="resources.disponibilidad_vias.fields.serviciosDirectorio" fullWidth source="serviciosDirectorio" choices={[
                                        { id: translate('dynatest.resources.disponibilidad_vias.select.serviciosDirectorio.Emergencias'), name: 'dynatest.resources.disponibilidad_vias.select.serviciosDirectorio.Emergencias' },
                                        { id: translate('dynatest.resources.disponibilidad_vias.select.serviciosDirectorio.Proveedores'), name: 'dynatest.resources.disponibilidad_vias.select.serviciosDirectorio.Proveedores' },
                                        { id: translate('dynatest.resources.disponibilidad_vias.select.serviciosDirectorio.ServiciosPublicos'), name: 'dynatest.resources.disponibilidad_vias.select.serviciosDirectorio.ServiciosPublicos' }
                                    ]} validate={[required()]} />
                                    <NumberInput source="tipoServicio" className={classes.ocultar} formClassName={classes.ocultar} />

                                    {/* <FormDataConsumer className={classes.ocultar} formClassName={classes.ocultar}>
                                    {
                                            ({ formData, scopedFormData, getSource, ...rest }) => ( scopedFormData != null && scopedFormData.serviciosDirectorio === translate('dynatest.resources.disponibilidad_vias.select.serviciosDirectorio.Emergencias') &&
                                            <NumberInput source={getSource("tipoServicio")}  parse={v => parseInt(v)} className={classes.ocultar} formClassName={classes.ocultar}/>
                                            )
                                    }                                        
                                    </FormDataConsumer>
                                    <FormDataConsumer className={classes.ocultar} formClassName={classes.ocultar}>
                                    {
                                            ({ formData, scopedFormData, getSource, ...rest }) => ( scopedFormData != null && scopedFormData.serviciosDirectorio === translate('dynatest.resources.disponibilidad_vias.select.serviciosDirectorio.Proveedores') &&
                                            <NumberInput source={getSource("tipoServicio")}  parse={v => parseInt(v)} className={classes.ocultar} formClassName={classes.ocultar}/>
                                            )
                                    }                                        
                                    </FormDataConsumer>
                                    <FormDataConsumer className={classes.ocultar} formClassName={classes.ocultar}>
                                    {
                                            ({ formData, scopedFormData, getSource, ...rest }) => (scopedFormData != null && scopedFormData.serviciosDirectorio === translate('dynatest.resources.disponibilidad_vias.select.serviciosDirectorio.ServiciosPublicos')&&
                                            <NumberInput source={getSource("tipoServicio")}  className={classes.ocultar} formClassName={classes.ocultar}/>
                                            )
                                    }                                        
                                    </FormDataConsumer> */}
                                    <FormDataConsumer formClassName={classes.ocultar}>
                                        {({ formData, scopedFormData, ...rest }) => {
                                            if (scopedFormData != null && scopedFormData.serviciosDirectorio === translate('dynatest.resources.disponibilidad_vias.select.serviciosDirectorio.Emergencias')) {
                                                scopedFormData.tipoServicio = 1;
                                            }
                                            else if (scopedFormData != null && scopedFormData.serviciosDirectorio === translate('dynatest.resources.disponibilidad_vias.select.serviciosDirectorio.Proveedores')) {
                                                scopedFormData.tipoServicio = 2;
                                            }
                                            else if (scopedFormData != null && scopedFormData.serviciosDirectorio === translate('dynatest.resources.disponibilidad_vias.select.serviciosDirectorio.ServiciosPublicos')) { 
                                                scopedFormData.tipoServicio = 3;
                                            }
                                        }}
                                    </FormDataConsumer>
                                    <FormDataConsumer>
                                        {
                                            ({ formData, scopedFormData, getSource, dispatch, ...rest }) => (scopedFormData != null && scopedFormData.serviciosDirectorio === translate('dynatest.resources.disponibilidad_vias.select.serviciosDirectorio.Emergencias') &&
                                                <AsociarServiciosEmergencia getSource={getSource} />
                                            )
                                        }
                                    </FormDataConsumer>
                                    <FormDataConsumer>
                                        {
                                            ({ formData, scopedFormData, getSource, dispatch, ...rest }) => (scopedFormData != null && scopedFormData.serviciosDirectorio === translate('dynatest.resources.disponibilidad_vias.select.serviciosDirectorio.Proveedores') &&
                                                <AsociarServicioProveedor getSource={getSource} />
                                            )
                                        }
                                    </FormDataConsumer>
                                    <FormDataConsumer >
                                        {
                                            ({ formData, scopedFormData, getSource, dispatch, ...rest }) => (scopedFormData != null && scopedFormData.serviciosDirectorio === translate('dynatest.resources.disponibilidad_vias.select.serviciosDirectorio.ServiciosPublicos')&&
                                                <AsociarServicio getSource={getSource} />
                                            )
                                        }
                                    </FormDataConsumer>
                                </SimpleFormIterator>
                            </ArrayInput>
                        </Grid>
                    </Grid>
                </FormTab>
                <FormTab label="dynatest.resources.disponibilidad_vias.tabs.tabsTitle.caracteristicas">
                    {//(estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.TrabajosVia')) &&
                        <NumberInput label="resources.disponibilidad_vias.fields.longitudCola" source="longitudCola" fullWidth validate={(estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.TrabajosVia')) ? [required(), validarLongcl]: [validarLongcl]} formClassName={classes.grid_cont6s} />
                    }
                    {//(estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.TrabajosVia')) &&
                        <TextInput label="resources.disponibilidad_vias.fields.indicadorO2" source="indicadorO2" fullWidth validate={(estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.TrabajosVia')) ? [required()] : []} disabled defaultValue={longcl} formClassName={(estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.TrabajosVia')) ? classes.grid_cont6 : classes.ocultar} />
                    }
                    {//(estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.TrabajosVia')) &&
                        <TextInput label="resources.disponibilidad_vias.fields.observacionesTrabajos" source="observacionesTrabajos" fullWidth formClassName={classes.grid_cont12} validate={(estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.TrabajosVia')) ? [required(), maxLength(200)] : [ maxLength(200)]}  maxLength={200} multiline />
                    }
                    {estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.Derrumbe') &&
                        <NumberInput label="resources.disponibilidad_vias.fields.volumenDerrumbe" source="volumenDerrumbe" fullWidth validate={[required(), validarVolder, valorTiempodespeje]} formClassName={classes.grid_cont3s} />
                    }
                    {estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.Derrumbe') &&
                        <TextInput label="resources.disponibilidad_vias.fields.ElementoAfectado" fullWidth validate={[required()]} disabled defaultValue={elementoaf} formClassName={classes.grid_cont3} />
                    }
                    {/*estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.Derrumbe') && volumenderr > 2000 &&

                        <SelectInput label="Condiciones Especiales" fullWidth validate={[required(), validarCondicionesesp]} source="condicionesEsp" choices={[
                            { id: 'Disposición de Nuevos Dispositivos de Contención', name: 'Disposición de Nuevos Dispositivos de Contención' },
                            { id: 'Mayores Dificultades', name: 'Mayores Dificultades' }
                        ]} formClassName={classes.grid_cont3} style={{ display: 'none' }}/>
                    */}
                    {/*estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.Derrumbe') &&
                        <TextInput label="Tiempo de Despeje Máximo (h)" fullWidth validate={[required()]} source="tiempoDespeje" disabled defaultValue={tiempodespeje} formClassName={classes.grid_cont3} style={{ display: 'none' }}/>
                */}
                    {estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.Derrumbe') &&
                        <TextInput label="resources.disponibilidad_vias.fields.observacionesDerrumbe" source="observacionesDerrumbe" fullWidth formClassName={classes.grid_cont12} validate={[required(), maxLength(200)]} maxLength={200} multiline />

                    }
                    {estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.Otro') &&
                        <TextInput label="resources.disponibilidad_vias.fields.tipoIncidenteOtro" fullWidth validate={[required(), validarTipoincidente, maxLength(40)]} maxLength={40} source="tipoIncidenteOtro" formClassName={classes.grid_cont6s} />
                    }
                    {estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.Otro') &&
                        <TextInput label="resources.disponibilidad_vias.fields.tipoVehiculoOtro" fullWidth validate={[required(), validarTipovehiculootro, maxLength(20)]} maxLength={20} source="tipoVehiculoOtro" formClassName={classes.grid_cont6} />
                    }
                    {estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') && causa === translate('dynatest.resources.disponibilidad_vias.select.causa.Otro') &&
                        <TextInput label="resources.disponibilidad_vias.fields.observacionesOtro" source="observacionesOtro" fullWidth formClassName={classes.grid_cont12} validate={[required(), maxLength(200)]}  maxLength={200} multiline />
                    }

                    {estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') &&
                        <SelectInput label="resources.disponibilidad_vias.fields.tipoAccidente" fullWidth source="tipoAccidente" choices={[
                            { id: translate('dynatest.resources.disponibilidad_vias.select.tipoAccidente.Simple'), name: 'dynatest.resources.disponibilidad_vias.select.tipoAccidente.Simple' },
                            { id: translate('dynatest.resources.disponibilidad_vias.select.tipoAccidente.Heridos'), name: 'dynatest.resources.disponibilidad_vias.select.tipoAccidente.Heridos' },
                            { id: translate('dynatest.resources.disponibilidad_vias.select.tipoAccidente.Muertos'), name: 'dynatest.resources.disponibilidad_vias.select.tipoAccidente.Muertos' }
                        ]} validate={[required()]} formClassName={classes.grid_cont12} />
                    }
                    {estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') &&
                        <Typography variant="b" component="b" formClassName={classes.grid_cont12}>{translate('dynatest.resources.disponibilidad_vias.subTitle.JuicioPolicia')}</Typography>
                    }
                    {estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') &&
                        <Divider fullWidth formClassName={classes.grid_cont12} />
                    }
                    {estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') &&
                        <ReferenceInput label="resources.disponibilidad_vias.fields.CodigoCausa" 
                        source="disponibilidadViaCausaProbable" 
                        reference="disponibilidad_via_causa_probables" 
                        validate={[required()]} 
                        fullWidth 
                        perPage={900}
                        formClassName={classes.grid_cont4s}>
                            <AutocompleteInput optionText="codigo" options={{ fullWidth: true}}/>
                        </ReferenceInput>
                        //<AutocompleteInput defaultValue={codCausa}  validate={[required(), validarCodigocausa]} label="" source="codigoCausa" choices={choicesCodCausa}  fullWidth formClassName={classes.grid_cont4s}/>
                        //<NumberInput label="Código Causa" source="codigoCausa" fullWidth validate={[required(), validarCodigocausa]} formClassName={classes.grid_cont4s} />
                    }
                    {/* disabled defaultValue={nombreCausa}*/}
                    {estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') &&
                        <ReferenceInput perPage={900} label="resources.disponibilidad_vias.fields.CausaProbable" source="disponibilidadViaCausaProbable" reference="disponibilidad_via_causa_probables" sort={{ field: 'nombre', order: 'asc' }} validate={[required()]} fullWidth formClassName={classes.grid_cont4}>
                            <AutocompleteInput optionText="nombre" options={{ fullWidth: true}}/>
                        </ReferenceInput>
                        //<AutocompleteInput sort={{ field: 'name', order: 'ASC' }} defaultValue={nombreCausa} validate={[required(),validarNombrecausa]} label="Causa Probable" source="causaProbable" choices={choicesNombreCausa}  fullWidth formClassName={classes.grid_cont4}/>
                        //<TextInput label="Causa Probable" source="causaProbable" validate={[required(), validarCausaProbable]} fullWidth formClassName={classes.grid_cont4} />
                    }
                    {estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') &&
                        <TextInput label="resources.disponibilidad_vias.fields.clasePolicia" source="clasePolicia" validate={[required(), validarClasePolicia]} fullWidth formClassName={classes.grid_cont4} />
                    }
                    {estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') &&
                        <hr fullWidth size="2px" color="gray"/>
                    }
                    {estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') &&
                        <ArrayInput label={<Typography><b style={{marginTop: "0px",marginBottom: "0px"}}>{translate('dynatest.resources.disponibilidad_vias.subTitle.Heridos')}</b></Typography>} source="heridos" fullWidth validate={array => sumHeridos(array)}>
                            <SimpleFormIterator>
                                <NumberInput label="resources.disponibilidad_vias.fields.cantidadHeridos" source="cantidadHeridos" validate={[required(), validarcantidadheridos]} formClassName={classes.grid_cont3} fullWidth />
                                <SelectInput label="resources.disponibilidad_vias.fields.tipoHeridos" fullWidth source="tipoHeridos" choices={[
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.tipoHeridos.Peaton'), name: 'dynatest.resources.disponibilidad_vias.select.tipoHeridos.Peaton' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.tipoHeridos.Ciclista'), name: 'dynatest.resources.disponibilidad_vias.select.tipoHeridos.Ciclista' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.tipoHeridos.Motociclista'), name: 'dynatest.resources.disponibilidad_vias.select.tipoHeridos.Motociclista' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.tipoHeridos.Conductor'), name: 'dynatest.resources.disponibilidad_vias.select.tipoHeridos.Conductor' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.tipoHeridos.Pasajero'), name: 'dynatest.resources.disponibilidad_vias.select.tipoHeridos.Pasajero' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.tipoHeridos.Otro'), name: 'dynatest.resources.disponibilidad_vias.select.tipoHeridos.Otro' }
                                ]} validate={[required()]} formClassName={classes.grid_cont3} />
                                <SelectInput label="resources.disponibilidad_vias.fields.generoHeridos" fullWidth source="generoHeridos" choices={[
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.generoHeridos.Hombre'), name: 'dynatest.resources.disponibilidad_vias.select.generoHeridos.Hombre' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.generoHeridos.Mujer'), name: 'dynatest.resources.disponibilidad_vias.select.generoHeridos.Mujer' }
                                ]} validate={[required()]} formClassName={classes.grid_cont3} />
                                <NumberInput label="resources.disponibilidad_vias.fields.edadHeridos" source="edadHeridos" validate={[required(), validaredadheridos]} formClassName={classes.grid_cont2} fullWidth />
                            </SimpleFormIterator>
                        </ArrayInput>
                    }
                    {estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') &&
                        <DisabledInput 
                            label="resources.disponibilidad_vias.fields.totalHeridos"
                            source="totalHeridos"
                            formClassName={classes.first_field} 
                            className="align-number"
                            defaultValue ={heridostotal}
                        />
                    }
                    {estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') &&
                        <hr fullWidth size="2px" color="gray"/>
                    }
                    {estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') &&
                        <ArrayInput label={<Typography><b style={{marginTop: "0px",marginBottom: "0px"}}>{translate('dynatest.resources.disponibilidad_vias.subTitle.VictimasFatales')}</b></Typography>} source="victimas" fullWidth validate={array => sumVictimasF(array)}>
                            <SimpleFormIterator>
                                <NumberInput label="resources.disponibilidad_vias.fields.cantidadVictimasf" source="cantidadVictimasf" validate={[required(), validarcantidadheridos]} formClassName={classes.grid_cont3} fullWidth />
                                <SelectInput label="resources.disponibilidad_vias.fields.tipoVictimasf" fullWidth source="tipoVictimasf" choices={[
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.tipoHeridos.Peaton'), name: 'dynatest.resources.disponibilidad_vias.select.tipoHeridos.Peaton' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.tipoHeridos.Ciclista'), name: 'dynatest.resources.disponibilidad_vias.select.tipoHeridos.Ciclista' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.tipoHeridos.Motociclista'), name: 'dynatest.resources.disponibilidad_vias.select.tipoHeridos.Motociclista' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.tipoHeridos.Conductor'), name: 'dynatest.resources.disponibilidad_vias.select.tipoHeridos.Conductor' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.tipoHeridos.Pasajero'), name: 'dynatest.resources.disponibilidad_vias.select.tipoHeridos.Pasajero' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.tipoHeridos.Otro'), name: 'dynatest.resources.disponibilidad_vias.select.tipoHeridos.Otro' }
                                ]} validate={[required()]} formClassName={classes.grid_cont3} />
                                <SelectInput label="resources.disponibilidad_vias.fields.generoVictimasf" fullWidth source="generoVictimasf" choices={[
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.generoHeridos.Hombre'), name: 'dynatest.resources.disponibilidad_vias.select.generoHeridos.Hombre' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.generoHeridos.Mujer'), name: 'dynatest.resources.disponibilidad_vias.select.generoHeridos.Mujer' }
                                ]} validate={[required()]} formClassName={classes.grid_cont3} />
                                <NumberInput label="resources.disponibilidad_vias.fields.edadVictimasf" source="edadVictimasf" validate={[required(), validaredadheridos]} formClassName={classes.grid_cont2} fullWidth />
                            </SimpleFormIterator>
                        </ArrayInput>
                    }
                    {estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') &&
                        <DisabledInput 
                            label="resources.disponibilidad_vias.fields.totalVictimasFatales"
                            source="totalVictimasFatales"
                            formClassName={classes.first_field} 
                            className="align-number"
                            defaultValue ={victimasftotal}
                        />
                    }
                    {estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') &&
                        <hr fullWidth size="2px" color="gray"/>
                    }
                    {estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') &&
                        <ArrayInput label={<Typography><b style={{marginTop: "0px",marginBottom: "0px"}}>{translate('dynatest.resources.disponibilidad_vias.subTitle.VehiculosImplicados')}</b></Typography>} source="vehiculos" fullWidth >
                            <SimpleFormIterator>
                                <NumberInput label="resources.disponibilidad_vias.fields.cantidadVehiculos" source="cantidadVehiculos" fullWidth validate={[required(), validarcantidadheridos]} formClassName={classes.grid_cont3} />
                                <SelectInput label="resources.disponibilidad_vias.fields.categoriaVehiculos" fullWidth source="categoriaVehiculos" choices={[
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Peaton'), name: 'dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Peaton' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Bicicleta'), name: 'dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Bicicleta' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Motocicleta'), name: 'dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Motocicleta' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Automovil'), name: 'dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Automovil' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Taxi'), name: 'dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Taxi' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Bus'), name: 'dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Bus' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Camion'), name: 'dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Camion' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.MaquinaConstr'), name: 'dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.MaquinaConstr' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.MaquinariaAgricola'), name: 'dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.MaquinariaAgricola' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.ObjetoFijo'), name: 'dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.ObjetoFijo' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.ObjetoMovil'), name: 'dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.ObjetoMovil' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Otros'), name: 'dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Otros' }
                                ]} validate={[required()]} formClassName={classes.grid_cont3} />
                                <SelectInput label="resources.disponibilidad_vias.fields.tipoVehiculos" fullWidth source="tipoVehiculos" choices={[
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Peaton'), name: 'dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Peaton' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Bicicleta'), name: 'dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Bicicleta' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Motocicleta'), name: 'dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Motocicleta' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Automovil'), name: 'dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Automovil' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Taxi'), name: 'dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Taxi' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Bus'), name: 'dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Bus' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Camion'), name: 'dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Camion' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.MaquinaConstr'), name: 'dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.MaquinaConstr' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.MaquinariaAgricola'), name: 'dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.MaquinariaAgricola' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.ObjetoFijo'), name: 'dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.ObjetoFijo' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.ObjetoMovil'), name: 'dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.ObjetoMovil' },
                                    { id: translate('dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Otros'), name: 'dynatest.resources.disponibilidad_vias.select.categoriaVehiculos.Otros' }
                                ]} validate={[required()]} formClassName={classes.grid_cont3} />
                                <TextInput label="resources.disponibilidad_vias.fields.marcaVehiculos" source="marcaVehiculos" fullWidth validate={[required(), validargeneroheridos]} formClassName={classes.grid_cont2} />
                                <TextInput label="resources.disponibilidad_vias.fields.lineaVehiculos" source="lineaVehiculos" fullWidth validate={[required(), validargeneroheridos]} formClassName={classes.grid_cont3} />
                                <TextInput label="resources.disponibilidad_vias.fields.modeloVehiculos" source="modeloVehiculos" fullWidth validate={[required(), validargeneroheridos]} formClassName={classes.grid_cont3} />
                                <TextInput label="resources.disponibilidad_vias.fields.placaVehiculos" source="placaVehiculos" fullWidth validate={[required(), validargeneroheridos]} formClassName={classes.grid_cont3} />
                                <TextInput label="resources.disponibilidad_vias.fields.colorVehiculos" source="colorVehiculos" fullWidth validate={[required(), validargeneroheridos]} formClassName={classes.grid_cont2} />
                            </SimpleFormIterator>
                        </ArrayInput>
                    }
                    {estado === translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') &&
                        <TextInput label="resources.disponibilidad_vias.fields.observacionesAccidente" source="observacionesAccidente" fullWidth formClassName={classes.grid_cont12} validate={[required(), maxLength(200)]} multiline />
                    }
                    <TextInput source="aprobacion" style={{ display: 'none' }} defaultValue={"Aprobado"} />
                    <FormDataConsumer>
                        {({ formData, ...rest }) => { formData.createdIn = "api"; }}
                    </FormDataConsumer> 
                </FormTab>
                <FormTab label="dynatest.resources.disponibilidad_vias.tabs.tabsTitle.fotografias">
                    <ImagenesInventario type='create' defaultTipoImagen={defaultTipoImagen} />
                </FormTab>
            </TabbedForm>
        </PimsCreate>
    )
}));