import * as React from 'react';
import MUITable from 'mui-tables';

//datos de Referencia
const data = [
    {
        name: 'UF1A::DRE::TR1::SG03::ZCOR::001', props: {
            codigo: "UF1A::DRE::TR1::SG03::ZCOR::001",
            tipoMantenimiento: "pe",
            fechaMantenimiento: "2021-02-20T05:00:00.000Z",
            fechaMantenimientoOriginalString: "20/02/2021",
            costoMantenimiento: "2",
            calificacion: "1",
            observaciones: "observaciones",
            nit: "1232400204\r",
            proveedor: ""
        }
    },
    {
        name: 'UF1A::DRE::TR1::SG01::POZT::001', props: {
            codigo: "UF1A::DRE::TR1::SG01::POZT::001",
            tipoMantenimiento: "pe",
            fechaMantenimiento: "2021-02-20T05:00:00.000Z",
            fechaMantenimientoOriginalString: "20/02/2021",
            costoMantenimiento: "3",
            calificacion: "2",
            observaciones: "observaciones",
            nit: "1232400204\r",
            proveedor: ""
        }
    }
];
//columnas de Referencia
const columns = {
    static: [
        {
            name: 'codigo',
            title: 'Código',
            calculateCellDefinition: (entry) => ({
                display: entry.props.codigo,
                value: entry.props.codigo
            })
        },
        {
            name: 'tipoMantenimiento',
            title: 'Tipo Mantenimiento',
            calculateCellDefinition: (entry) => {
                return ({
                    display: entry.props.tipoMantenimiento,
                    value: entry.props.tipoMantenimiento
                })
            }
        }
    ]
};

const formatter = (format, value) => {
    let valueToReturn = value
    // console.log(format, value)
    switch (format) {
        case "date":
            if (typeof value == "object") {
                let day = value.getDate()
                let month = value.getMonth() + 1
                let year = value.getFullYear()
                valueToReturn = `${day}/${month}/${year}`
            } else if (typeof value == "string") {
                let splittedValue = value.split("/")
                let valueToDate = ""
                if (splittedValue.length > 1) {
                    valueToDate = splittedValue[0].length == 4 ? `${splittedValue[0]}-${splittedValue[1]}-${splittedValue[2]}` : `${splittedValue[2]}-${splittedValue[1]}-${splittedValue[0]}`
                }
                let valueDate = new Date(valueToDate != "" ? valueToDate : value)
                let day = valueDate.getDate()
                let month = valueDate.getMonth() + 1
                let year = valueDate.getFullYear()
                valueToReturn = `${day}/${month}/${year}`
            }
            break;
        case "currency":
            var numericValue = typeof value == "string" ? value.replace(/\./g, "").replace(",", ".").replace("$", "") : value
            numericValue = parseFloat(numericValue)
            valueToReturn = new Intl.NumberFormat("es-CO", { style: "currency", currency: "COP", minimumFractionDigits: 0 }).format(numericValue)
            break;
    }
    return valueToReturn
}

const tipoMantenimientos = {
    ru: "Rutinario",
    pe: "Periódico",
    re: "Rehabilitación",
    ca: "Cambio"

}

const translations = {
    body: {
        noMatch: 'Sin Resultados',
        toolTip: 'Ordenar',
        summary: 'RESUMEN'
    },
    pagination: {
        next: 'Siguiente',
        previous: 'Anterior',
        first: 'Primera Pag.',
        last: 'Ultima Pag.',
        rowsPerPage: 'Registros por Página',
        displayRows: 'de'
    },
    toolbar: {
        search: 'Buscar',
        downloadCsv: 'Descargar CSV',
        print: 'Imprimir',
        viewColumns: 'Ver Columnas',
        filterTable: 'Filtrar Resultados'
    },
    filter: {
        all: 'Todo',
        title: 'FILTROS',
        reset: 'REINICIAR'
    },
    viewColumns: {
        title: 'Mostrar Columnas',
        titleAria: 'Mostrar/Ocultar Columnas'
    },
    selectedRows: {
        text: 'Fila(s) Seleccionadas',
        delete: 'Borrar',
        deleteAria: 'Borrar Columnas Seleccionadas'
    }
}
const getData = (arrayData, identityColumn) => {
    let data = []
    arrayData = JSON.parse(arrayData);
    arrayData.map((item, index) => {
        data.push({
            name: item[identityColumn],
            props: item
        })
    })
    // console.log(data)
    return data
}

const getColumns = (arrayData, columnNames, formatColumn = []) => {
    let columns = { static: [] }
    arrayData = JSON.parse(arrayData);
    arrayData.map((item, itemIndex) => {
        let singleColumnName = undefined
        let singleColumn = []
        columnNames.map(keyObject => {
            singleColumnName = columnNames.find(columnName => columnName.id == keyObject.id)
            if (singleColumnName !== undefined) {
                singleColumn.push({
                    name: keyObject.id,
                    title: keyObject.name,
                    display: "true",
                    sort: false,
                    type: "metric",
                    calculateCellDefinition: (entry) => {
                        return ({
                            display: keyObject.id == "tipoMantenimiento" ? tipoMantenimientos[entry.props[keyObject.id]] : entry.props[keyObject.id],
                            value: keyObject.id == "tipoMantenimiento" ? tipoMantenimientos[entry.props[keyObject.id]] : entry.props[keyObject.id]
                        })
                    },
                    customHeadRender: (column) => {
                        // console.log(column)
                        return (<div
                            name="test"
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            {column.title}
                        </div>)
                    },
                    // customBodyRender: (entry, row) => {
                    //     let formatSingleItem = Array.isArray(formatColumn) ? formatColumn.find(item => item.id == entry.column.name) : false
                    //     return (<div
                    //         name="test"
                    //         style={{
                    //             width: "100%",
                    //             display: "flex",
                    //             justifyContent: formatSingleItem ? getAlignmentCssProp(formatSingleItem.formatTo) : "center",
                    //             alignItems: "center"
                    //         }}
                    //     >
                    //         {entry.value}
                    //     </div>)
                    // }
                })
            }
        })
        columns.static = singleColumn

    })
    return columns
}
const getAlignmentCssProp = (type) => {
    switch (type) {
        case "number":
        case "currency": return "flex-end"
        default: return "center"
    }
}

/**
 * @props(
 *      label //string
 *      arrayData //as array of objects
 *      columnNames // [{id: "idName"," name : "stringDisplayValue"}]
 *      headerLeft // react component,
 *      formatColumn // [{id: "idName"," formatTo : "latindate"}] //formats are specified on formatter switch case
 * )
 * @returns 
 */
export default function TableWithPagination(props) {
    const [arrayData, setArrayData] = React.useState([])
    let arrayDataVar = JSON.stringify(props.arrayData);
    let data = []
    let columns = []
    
    data = getData(arrayDataVar, props.identityColumn)
    columns = getColumns(arrayDataVar, props.columnNames, props.formatColumn)

    if (Array.isArray(props.formatColumn)) {
        for (let i = 0; i < data.length; i++) {
            const elementData = data[i].props;
            props.formatColumn.map(formatData => {
                data[i].props[formatData.id] = formatter(formatData.formatTo, data[i].props[formatData.id])
            })
        }
    }
    return (
        <React.Fragment>
            <MUITable
                search={false}
                title={props.label}
                data={data}
                columns={columns}
                loading={false}
                translations={translations}
                hooks={{ onCellClick: (cell, row, rowIndex) => typeof props.onCellClick == "function" ? props.onCellClick({ cell: cell, row: row, rowIndex: rowIndex }) : undefined }}
                rows={{ rowHover: true }}
                toolbar={{
                    customToolbarBottom: (context) => {
                        return (
                            <div style={{
                                width: "100%",
                                display: 'flex',
                                flexDirection: "row",
                                justifyContent: "left"
                            }}>
                                {props.headerLeft ? props.headerLeft : null}
                            </div>
                        )
                    },
                }}
                display={{ download: true, search: false, viewColumns: false, filterTable: false, filter: false }}
                pagination= {{
                    rowsPerPage: 5, rowsPerPageOptions: [5, 25, 50]
                }}
            />
        </React.Fragment>
    );
}