import React, { useState, Fragment } from 'react';
import { TabbedForm, FormTab, TextInput, DateInput, SelectInput, FormDataConsumer, NumberInput, 
    ReferenceInput, DisabledInput, ArrayInput, SimpleFormIterator, AutocompleteInput
} from 'react-admin';
import {REDUX_FORM_NAME} from 'react-admin';
import {change} from 'redux-form';
import Grid from '@material-ui/core/Grid';
import { PercentageInput } from './../../../../inputs/PercentageInput';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core';
import { PimsCreate } from './../../../Create';
import { SegmentoActions } from './../SegmentoActions';
import { DInput } from './../../../../inputs/DInput';
import { required, minValue, maxValue, minLength, maxLength } from 'react-admin';
import {validarUnDecimal, validarDosDecimales, isFechaInvalida} from './../../../validacionesInputs';
import { ImagenesInventario } from '../../../images/ImagenesInventario';
import { translate } from 'react-admin';


    
//MOSTRAR IMAGEN
const showBtnImage =() =>{
    let btn = document.getElementById("Valbtn");        
    btn.style.display = "inline-flex";
}

//ESCONDER IMAGEN
const HideBtnImage =() =>{
    let btn = document.getElementById("Valbtn");    
    btn.style.display = "none";    
}

const styles = {
    first_field: {display: 'inline-block', width: '30%' },
    observaciones_field: {display: 'inline-block', width: '40%'},
    last_filed: {display: 'inline-block', marginLeft: 32, width: '30%' },   
    separador: {marginTop: 40, marginLeft: 100, marginRight: 100, marginBottom: 0}, 
    subtittle: {marginTop: '15px',marginBottom: '15px'},  
    // espensor:  {display: 'inline-block', width: '12%', padding: '5px', border: '1px solid'},
    // modulo:  {display: 'inline-block', width: '13%', padding: '5px', border: '1px solid'},
    // desviacion:  {display: 'inline-block', width: '22%', padding: '5px', border: '1px solid'},
    // material:  {display: 'inline-block', width: '30%', padding: '5px', border: '1px solid'}
    tabla:  {display: 'inline-block', width: '30%', padding: '5px'}
};
 
export const ElementoRigidoCreate = translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;

    //----------------------------------------------
    //-----------------VALIDACIÓNES-----------------
    //----------------------------------------------

    let defecto = 0;

    //DETALLES
    //------------------------->   

    //MOSTRAR/OCULTAR CAMPO RAZON
    const [estado, setEstado] = useState('');

    const Validationrazon =(value) =>{        
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                
        setEstado(valor)        
    }

    //ESAL
    let Esal10 = 0;
    const value10 = (value) =>{
        if(value){
            Esal10 = value;
        }
    }

    const value20 = (value) =>{
        if(Esal10 !== 0){
            if(value <= Esal10){            
                return translate('dynatest.resources.rigido_detalles.messagesVal.value20')
            }
        }
    }
      
    //GEOMETRIA
    //------------------->   

    //HOOKS VALOR AUTOMATICO AREA DE CARRILES(LARGO CALZADA) Y AREA VERDADERA CARRILES
    const [LargoCal, setLargoCal] = useState(1);
    const [AnchoCal, setAnchoCal] = useState(1);
    const [NumCarr, setNumCarr] = useState(1);
    const [AnchoCarr, setAnchoCarr] = useState(1);

    //CAMBIAR EL VALOR AREA DE CARRILES (LARGO)
    const ValDetallesCalzada = (value) =>{   
        if(value){
            //Si ingresa un decimal VAlidar solo 2     
            if(value !== 0){                
                if (value % 1 !== 0) {                     
                    var pattern = new RegExp("^[0-9]+([.][0-9]{2})$");
                    var pattern2 = new RegExp("^[0-9]+([.][0-9]{1})$");
                    if(!pattern.test(value) && !pattern2.test(value)){
                        if(!pattern.test(value)){ return translate('dynatest.resources.rigido_detalles.messagesVal.valNumDecimals'); }                            
                    }                                                            
                }                
            }

            setLargoCal(value)            
            var largom = document.getElementById("largom").value;
            var anchom = document.getElementById("anchom").value;
            var largoCar = document.getElementById("anchoCar").value;
            var total = ((parseFloat(largom)*100) * (parseFloat(anchom)*100))/10000;
            var totalVerdadero = ((parseFloat(largom)*100) * (parseFloat(anchom)*100) * (largoCar*100))/1000000;
            if (!isNaN(total)) {
                document.getElementById("AreaCarrilesRC").value = total;
                // document.getElementById("AreaVerdaderaRC").value = totalVerdadero;           

            }

        }        
    }

    //VALIDAR NUMERO CON DECIMALES
    const valNumDecimals = (value) =>{    
        if(value){            
            if(value !== 0){                
                if (value % 1 !== 0) {                     
                    var pattern = new RegExp("^[0-9]+([.][0-9]{2})$");
                    var pattern2 = new RegExp("^[0-9]+([.][0-9]{1})$");
                    if(!pattern.test(value) && !pattern2.test(value)){
                        if(!pattern.test(value)){ return translate('dynatest.resources.rigido_detalles.messagesVal.valNumDecimals'); }                            
                    }                                                            
                }                
            }
        } 
    }

    //CAMBIAR EL VALOR AREA DE CARRILES (ANCHO)
    const ValDetallesCalzadaAncho = (value) =>{    
        if(value){
            setAnchoCal(value)
            var largom = document.getElementById("largom").value;
            var anchom = document.getElementById("anchom").value;
            var largoCar = document.getElementById("anchoCar").value;
            var total = ((parseFloat(largom)*100) * (parseFloat(anchom)*100))/10000;
            var totalVerdadero = ((parseFloat(largom)*100) * (parseFloat(anchom)*100) * (largoCar*100))/1000000;
            if (!isNaN(total)) {
                document.getElementById("AreaCarrilesRC").value = total;
                // document.getElementById("AreaVerdaderaRC").value = totalVerdadero;           

            }
        }
    }    

    //VALOR AUTOMATICO AREA VERDADERA CARRILES(NUMERO CARRILES)    
    const ValNumeroCarriles = (value) =>{    
        if (value){
            setNumCarr(value)
            document.getElementById("AreaCarrilesRC").value = ((parseFloat(LargoCal)*100) * (parseFloat(AnchoCal)*100))/10000;
            // document.getElementById("AreaVerdaderaRC").value = ((parseFloat(LargoCal)*100) * (parseFloat(AnchoCal)*100) * (AnchoCarr*100))/1000000;          
        }        
    }

    //ASIGNAR VALOR AUTOMATICO VERDADERA AREA CARRILES (ANCHO CARRIL)
    const ValAnchoCarril = (value) =>{ 
        if (value){
            if(value !== 0){
                if (value % 1 !== 0) { 
                    var pattern = new RegExp("^[0-9]+([.][0-9]{1})$");
                    if(!pattern.test(value)){ return translate('dynatest.resources.rigido_detalles.messagesVal.valNumDecimal'); }       
                }                
            }
            setAnchoCarr(value)
            var largom = document.getElementById("largom").value;
            var anchom = document.getElementById("anchom").value;
            var largoCar = document.getElementById("anchoCar").value;
            var total = ((parseFloat(largom)*100) * (parseFloat(anchom)*100))/10000;
            var totalVerdadero = ((parseFloat(largom)*100) * (parseFloat(anchom)*100) * (largoCar*100))/1000000;
            if (!isNaN(total)) {
                document.getElementById("AreaCarrilesRC").value = total;
                // document.getElementById("AreaVerdaderaRC").value = totalVerdadero;           

            }
        }
    }

    //OCULTAR/MOSTRAR ANCHO DEL SEPARADOR
    const [anchosep, setAnchosep] = useState('');

    const ValidationConSeparador = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                
        setAnchosep(valor)  
    }

    //VALIDACION PENDIENTE 0-20
    const valPendiente20 = (value) =>{
        if(value){
            let longitud20 = value.split("%");
            //VALIDAR ENTERO
            let entero = longitud20[0].split(".");
            if(entero[1]){
                if(entero[1] !== "") { return translate('dynatest.resources.rigido_detalles.messagesVal.valNumEntero') }
            }  
            //VALIDAR MINIMO 0
            if(parseInt(longitud20[0]) < 0){ return translate('dynatest.resources.rigido_detalles.messagesVal.valLongitudMin0'); }
            //VALIDAR MAXIMO 5
            if(parseInt(longitud20[0]) > 20){ return translate('dynatest.resources.rigido_detalles.messagesVal.valLongitudMax20'); }            
        }        
    }

    //VALIDACION PENDIENTE 0-5  
    const valPendiente5 = (value) =>{
        if(value){
            let longitud5 = value.split("%");
            //VALIDAR ENTERO
            let entero = longitud5[0].split(".");
            if(entero[1]){
                if(entero[1] !== "") { return translate('dynatest.resources.rigido_detalles.messagesVal.valNumEntero') }
            }            
            //VALIDAR MINIMO 0
            if(parseInt(longitud5[0]) < 0){ return translate('dynatest.resources.rigido_detalles.messagesVal.valLongitudMin0'); }
            //VALIDAR MAXIMO 5
            if(parseInt(longitud5[0]) > 5){ return translate('dynatest.resources.rigido_detalles.messagesVal.valLongitudMax5'); }            
        }
    }

    //ESTRUCTURA
    //-------------------> 

    let espensor1 = 0, valEspensor1;
    let espensor2 = 0, valEspensor2;
    let espensor3 = 0, valEspensor3;
    let espensor4 = 0, valEspensor4;
    let espensor5 = 0, valEspensor5;
    let espensor6 = 0, valEspensor6;
    let espensor7 = 0, valEspensor7;
    const [espensortotal, setEspensortotal] = useState(defecto);

    //VALOR ESPENSOR
    const ValEspensor = (value) => {
        
        console.log(value);
        if(value){      
            if (value % 1 !== 0) { return translate('dynatest.resources.rigido_detalles.messagesVal.valNumEntero'); }
            //ESPENSOR 1
            valEspensor1 = document.getElementById('tablaSegmentos[0].estructuraEspesor');
            valEspensor2 = document.getElementById('tablaSegmentos[1].estructuraEspesor');
            valEspensor3 = document.getElementById('tablaSegmentos[2].estructuraEspesor');
            valEspensor4 = document.getElementById('tablaSegmentos[3].estructuraEspesor');
            valEspensor5 = document.getElementById('tablaSegmentos[4].estructuraEspesor');
            valEspensor6 = document.getElementById('tablaSegmentos[5].estructuraEspesor');
            valEspensor7 = document.getElementById('tablaSegmentos[6].estructuraEspesor');

            //ESPENSOR 1
            if(valEspensor1) { if(valEspensor1.value !== ''){ espensor1 = valEspensor1.value } }
            //ESPENSOR 2
            if(valEspensor2) { if(valEspensor2.value !== ''){ espensor2 = valEspensor2.value } }
            //ESPENSOR 2
            if(valEspensor3) { if(valEspensor3.value !== ''){ espensor3 = valEspensor3.value } }
            //ESPENSOR 2
            if(valEspensor4) { if(valEspensor4.value !== ''){ espensor4 = valEspensor4.value } }
            //ESPENSOR 2
            if(valEspensor5) { if(valEspensor5.value !== ''){ espensor5 = valEspensor5.value } }
            //ESPENSOR 2
            if(valEspensor6) { if(valEspensor6.value !== ''){ espensor6 = valEspensor6.value } }
            //ESPENSOR 2
            if(valEspensor7) { if(valEspensor7.value !== ''){ espensor7 = valEspensor7.value } }
            
            setEspensortotal(parseInt(espensor1) + parseInt(espensor2) + parseInt(espensor3) + parseInt(espensor4) + parseInt(espensor5) + parseInt(espensor6) + parseInt(espensor7));                  

        }  else {
            setEspensortotal(0);
        }
    }

    //VALOR MODULO
    const ValModulo = (value) =>{
        if(value){  
            if (value % 1 !== 0) { return translate('dynatest.resources.rigido_detalles.messagesVal.valNumEntero'); }          
        } 
    }

    //VALOR ESTRUCTURA
    const ValEstructura = (value) =>{
        if(value){
            if(value !== 0){
                if (value % 1 !== 0) {
                    var pattern = new RegExp("^[0-9]+([.][0-9]{2})$");
                    if(!pattern.test(value)){ return translate('dynatest.resources.rigido_detalles.messagesVal.valNumDecimals'); }            
                }                
            }
        } 
    }

    //VALIDAR NUMERO ENTERO
    const valentero = (value) =>{
        if(value){
            if (value % 1 !== 0) { return translate('dynatest.resources.rigido_detalles.messagesVal.valNumEntero'); }
        }
    }
    
    //VALIDAR NUMERO CON 1 DECIMALES
    const valNum1Decimal = (value) =>{    
        if(value){
            if(typeof value === 'string'){
                value = parseFloat(value.replace(",", "."));        
            } 
            if(value !== 0){
                if (value % 1 !== 0) { 
                    var pattern = new RegExp("^[0-9]+([.][0-9]{1})$");
                    if(!pattern.test(value)){ return translate('dynatest.resources.rigido_detalles.messagesVal.valNumDecimal'); }       
                }                
            }
        } 
    }

    //VALIDAR ESPECIFICACIÓN
    const [material , setMaterial]= useState('');
    const ValidarEspecificacion = (value)  => { 
        let lenghtObject = Object.keys(value);
        let valor = '';
        
        if(value){
            for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
            var filtroGra = valor.split('/materials/');  
            setMaterial(filtroGra[1]);
        }
    } 

    const [condicionberma, setCondicionberma] = useState(true);
    const validarCondicionBerma = (value) => {

        if(value){
            if (value === 'Inexistente'){
                setCondicionberma(false);
            }else{
                setCondicionberma(true);
            }
        }

    }
    const [arrayMax, setArrayMax] = useState(false);
    const arrayCount5 = (value) => {
        if(typeof value !== 'undefined'){
            if(value.length > 0) {
                if("estructuraEspesor" in value[value.length -1]) {
                    if(value[value.length -1].estructuraEspesor > 0) {
                        return translate('dynatest.resources.rigido_detalles.messagesVal.messageIniCapa') + value.length + translate('dynatest.resources.rigido_detalles.messagesVal.messageFinCapa');
                    }
                }
                if (value.length > 4) {
                    setArrayMax(true);
                } else {
                    setArrayMax(false);
                }
            } else {
                return translate('dynatest.resources.rigido_detalles.messagesVal.valMinCapa')
            }
        } else {
            return translate('dynatest.resources.rigido_detalles.messagesVal.valMinCapa')
        } 
    }
    
    const areaDLT = () => {
        if(document.getElementById("largoDLT") && document.getElementById("anchoDLT")){
            var largoDLT = document.getElementById("largoDLT").value;
            var anchoDLT = document.getElementById("anchoDLT").value;
            var area = anchoDLT * largoDLT;

            document.getElementById("areaDLT").value = area;

        }
    }
/*OPCIONES PARA TIPO DE IMÁGENES*/
    const [defaultTipoImagen,setDefaultTipoImagen] = useState([{id: 'Cargando...' ,name :'Cargando...'}]);
    const OpcionTipoImagen = () =>{
        setDefaultTipoImagen([
            {
                tipoImagen : '/tipo_images/1',
            },
            {
                tipoImagen : '/tipo_images/10',
            },
            {
                tipoImagen : '/tipo_images/11',
            },
            {
                tipoImagen : '/tipo_images/12',
            },
            {
                tipoImagen : '/tipo_images/5',
            },
            {
                tipoImagen : '/tipo_images/6',
            }
        ])
    }
/*FIN OPCIONES PARA TIPO DE IMÁGENES*/

/*CALCULAR CONCEITO EN ESTRUCTURA*/
const [conceitoEstado, setConceito] = useState();
    
const CalcularCoceito = (value) => {
    var conceito = '';
    if(value > 0 && value <= 20){
        conceito = 'Ótimo';
    }else if(value > 20 && value <= 40){
        conceito = 'Bom';
    }else if(value >40 && value <= 80){
        conceito = 'Regular';
    }else if(value >80 && value <= 160){
        conceito = 'Ruim';
    }else if(value > 160){
        conceito = 'Péssimo';
    }
    setConceito(conceito);
}   
/*FIN DEL CALCULO*/
    return(
    <PimsCreate {...props} actions={<SegmentoActions/>}>
        <TabbedForm redirect="list">
            <FormTab label="dynatest.resources.rigido_detalles.tabs.detalles" onClick={showBtnImage}>
                
            <FormDataConsumer >
                    {({formData, dispatch, ...rest}) => (
                        <Fragment>
                            <Grid container spacing={24}>
                                <Grid item xs={4}>
                                    <ReferenceInput 
                                        label="resources.rigido_detalles.fields.unidadFuncional" 
                                        source="unidadFuncional" 
                                        reference="unidad_funcionals" 
                                        sort={{ field: 'codigo', order: 'ASC' }}
                                        onChange={value => dispatch(
                                            change(REDUX_FORM_NAME, "tramo", null)
                                        )}
                                        filterToQuery={searchText => ({ codigo: searchText })} 
                                        validate={[required()]}
                                    >
                                        <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
                                    </ReferenceInput>
                                </Grid>
                                <Grid item xs={4}>
                                {formData !== null && formData !== undefined && formData.unidadFuncional &&
                                    <ReferenceInput 
                                        label="resources.rigido_detalles.fields.tramo" 
                                        source="tramo" 
                                        reference="tramos" 
                                        filter={{unidadFuncional: formData.unidadFuncional}} 
                                        sort={{ field: 'codigo', order: 'ASC' }}
                                        onChange={value => dispatch(
                                            change(REDUX_FORM_NAME, "segmento", null)
                                        )}
                                        filterToQuery={searchText => ({ codigo: searchText })} 
                                        validate={[required()]}
                                    >
                                        <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
                                    </ReferenceInput>
                                }
                                </Grid>
                                <Grid item xs={4}>
                                {formData !== null && formData !== undefined && formData.tramo &&
                                    <ReferenceInput 
                                        label="resources.rigido_detalles.fields.segmento" 
                                        source="segmento" 
                                        reference="segmentos" 
                                        filter={{
                                            tramo: formData.tramo,
                                            tipoSuperficie : "PRIGD"
                                        }} 
                                        sort={{ field: 'codigo', order: 'ASC' }}
                                        filterToQuery={searchText => ({ codigo: searchText })} 
                                        validate={[required(), OpcionTipoImagen]}
                                    >
                                        <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
                                    </ReferenceInput>
                                }
                                </Grid>
                            </Grid>
                        </Fragment>
                        
                    )}
                </FormDataConsumer>
                <div></div>
                <ArrayInput source="detallesSegmentos" style={{width: '100%'}} label=" " 
                    defaultValue={[
                    {         
                        sentido: null,                
                        estadoDetalles: null,
                        estadoRazon: null,
                        dateInicioOperacion: null,
                        proveedor: null,
                        garantia_meses: null,
                        esalTime: null,
                        nivelTrafico: null,
                        esal_time_10: null,
                        esalTime20: null,
                        detallesObservaciones: null
                    }
                ]} className="inventariosSegmentos">
                    <SimpleFormIterator disableRemove disableAdd>   
                        <SelectInput label="resources.rigido_detalles.fields.sentido" source='sentido' choices={[
                            { id: translate('dynatest.resources.rigido_detalles.select.sentido.NORTEId'), name: 'dynatest.resources.rigido_detalles.select.sentido.NORTEName' },
                            { id: translate('dynatest.resources.rigido_detalles.select.sentido.SURId'), name: 'dynatest.resources.rigido_detalles.select.sentido.SURName' },
                            { id: translate('dynatest.resources.rigido_detalles.select.sentido.ESTEId'), name: 'dynatest.resources.rigido_detalles.select.sentido.ESTEName' },
                            { id: translate('dynatest.resources.rigido_detalles.select.sentido.OESTEId'), name: 'dynatest.resources.rigido_detalles.select.sentido.OESTEName' }
                        ]}
                        formClassName={classes.first_field}/> 
                        <SelectInput source="estadoDetalles" label="resources.rigido_detalles.fields.estadoDetalles" choices={[
                            { id: translate('dynatest.resources.rigido_detalles.select.estadoDetalles.Activo'), name: 'dynatest.resources.rigido_detalles.select.estadoDetalles.Activo' },
                            { id: translate('dynatest.resources.rigido_detalles.select.estadoDetalles.Inactivo'), name: 'dynatest.resources.rigido_detalles.select.estadoDetalles.Inactivo' },                      
                        ]} formClassName={classes.last_filed} validate={[required()]} onChange={Validationrazon}/>                                
                        { estado === translate('dynatest.resources.rigido_detalles.select.estadoDetalles.Inactivo') &&            
                            <TextInput source="estadoRazon" label="resources.rigido_detalles.fields.estadoRazon" formClassName={classes.last_filed} validate={[required()]}/>
                        }
                        <div ></div>     
                        <DateInput source="dateInicioOperacion" label="resources.rigido_detalles.fields.dateInicioOperacion" formClassName={classes.first_field} validate={[required(), isFechaInvalida]} />                         
                        <ReferenceInput 
                            label="resources.rigido_detalles.fields.proveedor" 
                            source="proveedor" 
                            reference="proveedors" 
                            filter={{sistema: 'CLZ'}} 
                            sort={{ field: 'razonSocial', order: 'ASC' }}
                            formClassName={classes.last_filed}
                            filterToQuery={searchText => ({ razonSocial: searchText })} 
                            validate={[required()]}
                        >
                            <AutocompleteInput  options={{fullWidth : true}} optionText="razonSocial"  />
                        </ReferenceInput>
                        <NumberInput source="garantiaMeses" label="resources.rigido_detalles.fields.garantiaMeses" validate={[minValue(0)]} formClassName={classes.last_filed}/>
                        <div className={classes.subtittle}><strong>{translate('resources.rigido_detalles.fields.trafico')}</strong></div>                
                        <Divider fullWidth />
                        <NumberInput source="esalTime" label="resources.rigido_detalles.fields.esalTime" formClassName={classes.first_field} validate={[required(),minValue(0)]}/>
                        <SelectInput source="nivelTrafico" label="resources.rigido_detalles.fields.nivelTrafico" choices={[
                            { id: translate('dynatest.resources.rigido_detalles.select.nivelTrafico.NT1'), name: 'dynatest.resources.rigido_detalles.select.nivelTrafico.NT1' },
                            { id: translate('dynatest.resources.rigido_detalles.select.nivelTrafico.NT2'), name: 'dynatest.resources.rigido_detalles.select.nivelTrafico.NT2' },
                            { id: translate('dynatest.resources.rigido_detalles.select.nivelTrafico.NT3'), name: 'dynatest.resources.rigido_detalles.select.nivelTrafico.NT3' },
                        ]} formClassName={classes.last_filed} validate={[required()]} />  
                        <div></div>                
                        <NumberInput source="esalTime10" label="resources.rigido_detalles.fields.esalTime10" formClassName={classes.first_field} validate={[minValue(0), value10]}/>
                        <NumberInput source="esalTime20" label="resources.rigido_detalles.fields.esalTime20" formClassName={classes.last_filed} validate={[minValue(0), value20]}/>
                        <Divider fullWidth formClassName={classes.separador} />                
                        <TextInput source="detallesObservaciones" label="resources.rigido_detalles.fields.detallesObservaciones" formClassName={classes.observaciones_field} validate={[minLength(0), maxLength(200)]} fullWidth/>  
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
            <FormTab label="dynatest.resources.rigido_detalles.tabs.geometria" onClick={HideBtnImage} redirect="list">
                <ArrayInput source="geometriaSegmentos" style={{width: '100%'}} label=" " 
                    defaultValue={[
                    {
                        geometriaLargo: null,
                        geometriaAncho: null,
                        geometriaNumCarriles: null,
                        geometriaAnchoCarril: null,
                        geometriaAnchoBerma: null,
                        geometriaTipoBerma: null,
                        geometriaCondicionBerma: null,
                        aux1: 0,
                        aux2: 0,
                        geometriaLongitudinal: null,
                        geometriaLongitudinalMa: null,
                        geometriaLongitudinalMi: null,
                        geometriaTransversal: null,
                        geometriaRadioMi: null,
                        geometriaRadioMa: null,
                        geometriaRadioCurvatura: null,
                        geometriaVelDiseno: null,
                        geometriaSeparador: null,
                        geometriaSeparadorAncho: null,
                        geometriaCondicionesGeometrica: null,
                        geometriaAlineamiento: null,
                        geometriaTipoTerreno: null,
                        geometriaDltLargo: null,
                        geometriaDltAncho: null,
                        geometria_dlt_area: null,
                        geometriaDltNumlosas: null,
                        geometriaDltLongjuntas: null
                    }
                    ]} className="inventariosSegmentos">
                    <SimpleFormIterator disableRemove disableAdd>
                        <div className={classes.subtittle}><strong>{translate('resources.rigido_detalles.fields.detallesCalzada')}</strong></div>
                        <Divider fullWidth />
                        <NumberInput 
                            id="largom"
                            source="geometriaLargo" 
                            label="resources.rigido_detalles.fields.geometriaLargo" 
                            formClassName={classes.first_field} 
                            validate={[required(), minValue(0), maxValue(1999.99), ValDetallesCalzada]}
                        />
                        <NumberInput 
                            id="anchom"
                            source="geometriaAncho" 
                            label="resources.rigido_detalles.fields.geometriaAncho" 
                            formClassName={classes.last_filed} 
                            validate={[required(), minValue(3), maxValue(40), ValDetallesCalzadaAncho]}
                        />
                        <div className={classes.subtittle}><strong>{translate('resources.rigido_detalles.fields.detallesCarriles')}</strong></div>
                        <Divider fullWidth />
                        <NumberInput source="geometriaNumCarriles" label="resources.rigido_detalles.fields.geometriaNumCarriles" formClassName={classes.first_field} validate={[required(), minValue(1), maxValue(5), ValNumeroCarriles]}/>
                        <NumberInput 
                            id="anchoCar"
                            source="geometriaAnchoCarril" 
                            label="resources.rigido_detalles.fields.geometriaAnchoCarril" 
                            formClassName={classes.last_filed} 
                            validate={[required(), minValue(1), maxValue(5), ValAnchoCarril]}
                        />
                        <div></div>
                        <SelectInput source="geometriaTipoBerma" label="resources.rigido_detalles.fields.geometriaTipoBerma" choices={[
                            { id: translate('dynatest.resources.rigido_detalles.select.geometriaTipoBerma.bermaCuneta'), name: 'dynatest.resources.rigido_detalles.select.geometriaTipoBerma.bermaCuneta' },
                            { id: translate('dynatest.resources.rigido_detalles.select.geometriaTipoBerma.flexible'), name: 'dynatest.resources.rigido_detalles.select.geometriaTipoBerma.flexible' },
                            { id: translate('dynatest.resources.rigido_detalles.select.geometriaTipoBerma.inexistente'), name: 'dynatest.resources.rigido_detalles.select.geometriaTipoBerma.inexistente' },
                            { id: translate('dynatest.resources.rigido_detalles.select.geometriaTipoBerma.rigida'), name: 'dynatest.resources.rigido_detalles.select.geometriaTipoBerma.rigida' },
                        ]} formClassName={classes.first_field} validate={[validarCondicionBerma]}
                        />
                        {condicionberma  && 
                            <NumberInput source="geometriaAnchoBerma" label="resources.rigido_detalles.fields.geometriaAnchoBerma" formClassName={classes.last_filed} validate={[required(), minValue(0), maxValue(10), valNum1Decimal]}/>
                        }
                        {condicionberma  && 
                            <SelectInput source="geometriaCondicionBerma" label="resources.rigido_detalles.fields.geometriaCondicionBerma" resettable choices={[
                                { id: translate('dynatest.resources.rigido_detalles.select.geometriaCondicionBerma.bueno'), name: 'dynatest.resources.rigido_detalles.select.geometriaCondicionBerma.bueno' },
                                { id: translate('dynatest.resources.rigido_detalles.select.geometriaCondicionBerma.regular'), name: 'dynatest.resources.rigido_detalles.select.geometriaCondicionBerma.regular' },
                                { id: translate('dynatest.resources.rigido_detalles.select.geometriaCondicionBerma.deficiente'), name: 'dynatest.resources.rigido_detalles.select.geometriaCondicionBerma.deficiente' },             
                            ]} formClassName={classes.last_filed} /> 
                        }  
                        <div></div>
                        <DisabledInput source="aux1" label="resources.rigido_detalles.fields.aux1" id="AreaCarrilesRC" className="align-number" formClassName={classes.first_field} defaultValue ={0}/>                
                        <NumberInput source="areaCarrilesVerdadera" label="resources.rigido_detalles.fields.areaCarrilesVerdadera" id="AreaVerdaderaRC" className="align-number" formClassName={classes.last_filed} validate={[required()]}/>
                        <div className={classes.subtittle}><strong>{translate('resources.rigido_detalles.fields.propiedadesGeometricas')}</strong></div>
                        <Divider fullWidth />
                        <PercentageInput className="Longitudes_segmentos" source="geometriaLongitudinal" label="resources.rigido_detalles.fields.geometriaLongitudinal" formClassName={classes.first_field} validate={[valPendiente20]}/>
                        <PercentageInput className="Longitudes_segmentos" source="geometriaLongitudinalMa" label="resources.rigido_detalles.fields.geometriaLongitudinalMa" formClassName={classes.last_filed} validate={[valPendiente20]}/>
                        <PercentageInput className="Longitudes_segmentos" source="geometriaLongitudinalMi" label="resources.rigido_detalles.fields.geometriaLongitudinalMi" formClassName={classes.last_filed} validate={[valPendiente20]}/>
                        {/* -----------RADIO--------- */}
                        <PercentageInput className="Longitudes_segmentos" source="geometriaTransversal" label="resources.rigido_detalles.fields.geometriaTransversal" formClassName={classes.first_field} validate={[valPendiente5]}/>
                        <NumberInput source="geometriaRadioMi" label="resources.rigido_detalles.fields.geometriaRadioMi" formClassName={classes.last_filed} validate={[minValue(0), maxValue(5)]}/>
                        <NumberInput source="geometriaRadioMa" label="resources.rigido_detalles.fields.geometriaRadioMa" formClassName={classes.last_filed} validate={[minValue(0), maxValue(5000)]}/>
                        {/* -------------------- */}
                        <NumberInput source="geometriaRadioCurvatura" label="resources.rigido_detalles.fields.geometriaRadioCurvatura" formClassName={classes.first_field} validate={[required(), minValue(0), maxValue(5000), valNum1Decimal]} />
                        <NumberInput source="geometriaVelDiseno" label="resources.rigido_detalles.fields.geometriaVelDiseno" formClassName={classes.last_filed} validate={[required(), minValue(10), maxValue(150), valentero]} />
                        <div></div>
                        <SelectInput source="geometriaSeparador" label="resources.rigido_detalles.fields.geometriaSeparador" choices={[
                            { id: translate('dynatest.resources.rigido_detalles.select.geometriaSeparador.Si'), name: 'dynatest.resources.rigido_detalles.select.geometriaSeparador.Si' },
                            { id: translate('dynatest.resources.rigido_detalles.select.geometriaSeparador.No'), name: 'dynatest.resources.rigido_detalles.select.geometriaSeparador.No' },                     
                        ]} formClassName={classes.first_field} onChange={ValidationConSeparador} validate={[required()]}/>
                        { anchosep === translate('dynatest.resources.rigido_detalles.select.geometriaSeparador.Si') &&   
                            <NumberInput source="geometriaSeparadorAncho" label="resources.rigido_detalles.fields.geometriaSeparadorAncho" formClassName={classes.last_filed} validate={[required(), minValue(0), maxValue(50)]}/>
                        }                               
                        <div></div>
                        <SelectInput source="geometriaCondicionesGeometrica" label="resources.rigido_detalles.fields.geometriaCondicionesGeometrica" choices={[
                            { id: translate('dynatest.resources.rigido_detalles.select.geometriaCondicionesGeometrica.bueno'), name: 'dynatest.resources.rigido_detalles.select.geometriaCondicionesGeometrica.bueno' },
                            { id: translate('dynatest.resources.rigido_detalles.select.geometriaCondicionesGeometrica.regular'), name: 'dynatest.resources.rigido_detalles.select.geometriaCondicionesGeometrica.regular' },
                            { id: translate('dynatest.resources.rigido_detalles.select.geometriaCondicionesGeometrica.deficiente'), name: 'dynatest.resources.rigido_detalles.select.geometriaCondicionesGeometrica.deficiente' },
                        ]} formClassName={classes.first_field} />
                        <SelectInput source="geometriaAlineamiento" label="resources.rigido_detalles.fields.geometriaAlineamiento" choices={[
                            { id: translate('dynatest.resources.rigido_detalles.select.geometriaAlineamiento.curvo'), name: 'dynatest.resources.rigido_detalles.select.geometriaAlineamiento.curvo' },
                            { id: translate('dynatest.resources.rigido_detalles.select.geometriaAlineamiento.predominantementeCurvo'), name: 'dynatest.resources.rigido_detalles.select.geometriaAlineamiento.predominantementeCurvo' },
                            { id: translate('dynatest.resources.rigido_detalles.select.geometriaAlineamiento.predominantementeRecto'), name: 'dynatest.resources.rigido_detalles.select.geometriaAlineamiento.predominantementeRecto' },
                            { id: translate('dynatest.resources.rigido_detalles.select.geometriaAlineamiento.recto'), name: 'dynatest.resources.rigido_detalles.select.geometriaAlineamiento.recto' },
                        ]} formClassName={classes.last_filed} />
                        <SelectInput source="geometriaTipoTerreno" label="resources.rigido_detalles.fields.geometriaTipoTerreno" choices={[
                            { id: translate('dynatest.resources.rigido_detalles.select.geometriaTipoTerreno.plano'), name: 'dynatest.resources.rigido_detalles.select.geometriaTipoTerreno.plano' },
                            { id: translate('dynatest.resources.rigido_detalles.select.geometriaTipoTerreno.ondulado'), name: 'dynatest.resources.rigido_detalles.select.geometriaTipoTerreno.ondulado' },
                            { id: translate('dynatest.resources.rigido_detalles.select.geometriaTipoTerreno.montañoso'), name: 'dynatest.resources.rigido_detalles.select.geometriaTipoTerreno.montañoso' },
                            { id: translate('dynatest.resources.rigido_detalles.select.geometriaTipoTerreno.escarpado'), name: 'dynatest.resources.rigido_detalles.select.geometriaTipoTerreno.escarpado' },
                        ]} formClassName={classes.last_filed} />
                        <div className={classes.subtittle}><strong>{translate('resources.rigido_detalles.fields.dimensionesLosaTipica')}</strong></div>
                        <Divider fullWidth />
                        <NumberInput id ="largoDLT" source="geometriaDltLargo" label="resources.rigido_detalles.fields.geometriaDltLargo" validate={[required(), minValue(0), maxValue(10), valNum1Decimal, areaDLT]} formClassName={classes.first_field}/> 
                        <NumberInput id="anchoDLT" source="geometriaDltAncho" label="resources.rigido_detalles.fields.geometriaDltAncho" validate={[required(), minValue(0), maxValue(10), valNum1Decimal, areaDLT]} formClassName={classes.last_filed}/> 
                        <NumberInput id="areaDLT" disabled  source="geometria_dlt_area" label="resources.rigido_detalles.fields.geometria_dlt_area" formClassName={classes.last_filed}/> 
                        <NumberInput source="geometriaDltNumlosas" label="resources.rigido_detalles.fields.geometriaDltNumlosas" validate={[required(), minValue(0), maxValue(9999), valentero]} formClassName={classes.first_field}/> 
                        <NumberInput source="geometriaDltLongjuntas" label="resources.rigido_detalles.fields.geometriaDltLongjuntas" validate={[required(), valNum1Decimal]} formClassName={classes.last_filed}/> 
                        <Divider fullWidth formClassName={classes.separador} />                
                        <TextInput source="detallesGeometria" label="resources.rigido_detalles.fields.detallesGeometria" validate={[minLength(0), maxLength(200)]} formClassName={classes.observaciones_field} fullWidth/> 
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
            <FormTab label="dynatest.resources.rigido_detalles.tabs.estructura" onClick={HideBtnImage}>
                <div className={classes.subtittle}><strong>{translate('resources.rigido_detalles.fields.informacionEstructura')}</strong></div>
                <Divider fullWidth />               
                <ArrayInput 
                    source="tablaSegmentos" 
                    style={{width: '100%'}} 
                    label=" " 
                    validate={[required(), arrayCount5]}
                >
                    <SimpleFormIterator disableAdd={arrayMax}>
                        <NumberInput source="estructuraEspesor" label="resources.rigido_detalles.fields.estructuraEspesor" validate={[ValEspensor, required(), minValue(0), maxValue(20000)]} formClassName={classes.tabla} style={{width: '100%'}}/>                          
                        <NumberInput source="estructuraModulo" label="resources.rigido_detalles.fields.estructuraModulo" validate={[required(), minValue(0), maxValue(100000), ValModulo]} formClassName={classes.tabla} style={{width: '100%'}}/>   
                        <NumberInput source="estructuraDs" label="resources.rigido_detalles.fields.estructuraDs" validate={[required(), minValue(0.01), maxValue(4), valNumDecimals]} formClassName={classes.tabla} style={{width: '100%'}} className="Longitudes_segmentos"/>                       
                        <ReferenceInput 
                            label="resources.rigido_detalles.fields.material" 
                            source="material"  
                            reference="materials" 
                            filterToQuery={searchText => ({ material: searchText })} 
                            sort={{field : 'material', order : 'ASC'}}
                            onChange={ValidarEspecificacion}
                            validate={[required()]} 
                            fullWidth 
                            formClassName={classes.tabla} 
                        >
                            <AutocompleteInput  options={{fullWidth : true}} optionText="material"  />
                        </ReferenceInput>                        
                        <ReferenceInput label="resources.rigido_detalles.fields.materialEsp" source="material" reference="materials" sort={{field : 'material', order : 'ASC'}}  formClassName={classes.tabla} style={{width: '100%'}}>
                            <SelectInput  optionText="especificacion" disabled fullWidth />
                        </ReferenceInput>                               
                        <ReferenceInput 
                            label="resources.rigido_detalles.fields.granulometria" 
                            source="granulometria" 
                            reference="granulometrias" 
                            filterToQuery={searchText => ({ granulometria: searchText })} 
                            sort={{field : 'granulometria', order : 'ASC'}}
                            filter={{material: material}} 
                            formClassName={classes.tabla} 
                            style={{width: '100%'}}
                        >
                            <AutocompleteInput  options={{fullWidth : true}} optionText="granulometria" fullWidth  />
                        </ReferenceInput>       
                    </SimpleFormIterator>
                </ArrayInput>
                <div></div><br/>                                               
                <DisabledInput label="resources.rigido_detalles.fields.espesorTotal" formClassName={classes.first_field} defaultValue ={espensortotal}/>                
                <ArrayInput source="estructuraSegmentos" style={{width: '100%'}} label=" "
                    defaultValue={[
                    {
                        igg: null,
                        conceito: conceitoEstado,
                        estructuraNumDiseno: null,
                        estructuraFecha: null,
                        estructuraNumero: null,
                        estructuraNumeroFecha: null,
                        estructuraCbr: null,
                        estructuraCbrFecha: null,
                        estructuraVacios: null,
                        estructura_fecha_const: null,
                        estructuraFechaRehab: null,
                        detallesEstructura: null
                    }
                    ]} className="inventariosSegmentos">
                    <SimpleFormIterator disableRemove disableAdd>
                        <NumberInput 
                            source="igg" 
                            label="resources.rigido_detalles.fields.igg" 
                            validate={[minValue(1), maxValue(200), CalcularCoceito]}
                            formClassName={classes.first_field}
                        />
                        <DisabledInput 
                            id="Conceito" 
                            source="conceito" 
                            label="resources.rigido_detalles.fields.conceito"
                            formClassName={classes.last_filed}
                        />   
                        <div className={classes.subtittle}><strong>{translate('resources.rigido_detalles.fields.capacidadEstructural')}</strong></div>
                        <Divider fullWidth />
                        <NumberInput source="estructuraNumDiseno" label="resources.rigido_detalles.fields.estructuraNumDiseno" formClassName={classes.first_field} validate={[required(), minValue(0.1), maxValue(20), valNum1Decimal]}/>
                        <DateInput source="estructuraFecha" label="resources.rigido_detalles.fields.estructuraFecha" formClassName={classes.last_filed} validate={[required(), isFechaInvalida]} /> 
                        <div></div>
                        <NumberInput source="estructuraNumero" label="resources.rigido_detalles.fields.estructuraNumero" formClassName={classes.first_field} validate={[required(), minValue(0.1), maxValue(20), valNum1Decimal]}/>
                        <DateInput source="estructuraNumeroFecha" label="resources.rigido_detalles.fields.estructuraNumeroFecha" formClassName={classes.last_filed} validate={[required(), isFechaInvalida]} /> 
                        <div></div>
                        <NumberInput source="estructuraCbr" label="resources.rigido_detalles.fields.estructuraCbr" className="align-number" formClassName={classes.first_field} validate={[required(), minValue(0), maxValue(100), valNum1Decimal]}/>
                        <DateInput source="estructuraCbrFecha" label="resources.rigido_detalles.fields.estructuraCbrFecha" formClassName={classes.last_filed} validate={[required(), isFechaInvalida]} /> 
                        <NumberInput 
                            source="estructuraVacios" 
                            label="resources.rigido_detalles.fields.estructuraVacios" 
                            formClassName={classes.last_filed} 
                            validate={[required(), minValue(0), maxValue(100), validarUnDecimal]}
                        />                
                        <NumberInput source="kjoint" label="resources.rigido_detalles.fields.kjoint" formClassName={classes.first_field} validate={[minValue(0), maxValue(400)]}/>
                        <NumberInput source="jsdf" label="resources.rigido_detalles.fields.jsdf" className="labelNumber" formClassName={classes.last_filed} validate={[minValue(0),maxValue(4), validarDosDecimales]}/>
                        <div></div>
                        <NumberInput source="kcenter" label="resources.rigido_detalles.fields.kcenter" formClassName={classes.first_field} validate={[minValue(0), maxValue(400)]}/>
                        <NumberInput source="csdf" label="resources.rigido_detalles.fields.csdf" className="labelNumber" formClassName={classes.last_filed} validate={[minValue(0),maxValue(4), validarDosDecimales]}/>
                        <div></div>
                        <div className={classes.subtittle}><strong>{translate('resources.rigido_detalles.fields.fechasImportantes')}</strong></div>
                        <Divider fullWidth />
                        <DateInput source="estructuraFechaConst" label="resources.rigido_detalles.fields.estructuraFechaConst" formClassName={classes.first_field} validate={[required(), isFechaInvalida]} /> 
                        <DateInput source="estructuraFechaRehab" label="resources.rigido_detalles.fields.estructuraFechaRehab" formClassName={classes.last_filed} validate={[required(),isFechaInvalida]} /> 
                        <Divider fullWidth formClassName={classes.separador} />
                        <TextInput source="detallesEstructura" label="resources.rigido_detalles.fields.detallesEstructura" validate={[minLength(0), maxLength(200)]} formClassName={classes.observaciones_field} fullWidth/>   
                    </SimpleFormIterator>
                </ArrayInput>
                <TextInput source="createdIn" style={{ display: 'none' }} defaultValue={"api"} />
                <TextInput source="updatedIn" style={{ display: 'none' }} defaultValue={"api"} />
            </FormTab>
            <FormTab label="dynatest.resources.rigido_detalles.tabs.fotografias">
                <ImagenesInventario type='create' defaultTipoImagen={defaultTipoImagen}/>                                   
            </FormTab> 
        </TabbedForm>
    </PimsCreate>
    )

}));