import React, { Fragment } from 'react';
import { List, Datagrid,SelectField, NumberField,TextField, EditButton, ShowButton, Filter,  SelectInput,CloneButton} from 'react-admin';
import {  BulkDeleteWithConfirmButton, Loading  } from 'react-admin';
import EditButtonPims from '../EditButton'
import { translate } from 'react-admin';
import { CardActions, CreateButton, ExportButton } from 'react-admin';

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);

const ListAction = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
        <ExportButton basePath={basePath}/>
    </CardActions>
);
const ListAction2 = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
    </CardActions>
);

export const TrayectoriasList = translate(({ permissions, ...props }) => {
    if (!permissions) return <Loading />;  
    return (
    <List {...props} 
        sort={{ field:"updatedAt", order: 'DESC' }} 
        title="dynatest.resources.trayectorias.title.listTitle" 
        actions={permissions['alternativaIntervencion']  == 1 ? <ListAction /> : permissions['alternativaIntervencion']  == 2 ? <ListAction2 />: null} 
        bulkActionButtons={permissions['alternativaIntervencion']  == 1 ? <PostBulkActionButtons />: null}>
        <Datagrid>    
            <TextField label="resources.trayectorias.fields.nombre" source="nombre" />
            <NumberField source="precio" label="resources.trayectorias.fields.precio" />  
            <NumberField source="preciocar" label="resources.trayectorias.fields.preciocar" />  
            <NumberField label="resources.trayectorias.fields.vida" source="vida"  />
            <NumberField label="resources.trayectorias.fields.espesor" source="espesor" />
            {permissions['alternativaIntervencion'] == 1 ? <CloneButton label="resources.trayectorias.fields.clonar" />: null}
            {permissions['alternativaIntervencion'] == 1 || permissions['alternativaIntervencion'] == 2 || permissions['alternativaIntervencion'] == 4 ? <ShowButton/>: null}
            {permissions['alternativaIntervencion'] == 1 || permissions['alternativaIntervencion'] == 2 ? <EditButton/>: null}
        </Datagrid>
    </List>
)});