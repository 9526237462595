import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
    BooleanInput, ReferenceInput, SelectInput, DateInput, AutocompleteInput,
    TextInput, NumberInput, ArrayInput, SimpleFormIterator, Loading, FormDataConsumer
} from 'react-admin';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { styles } from './../../../EstilosGrid';
import { Divider } from '@material-ui/core';
import { CurrencyInput } from './../../../inputs/CurrencyInput';
import { PRInput } from '../../../inputs/PRInput';
import { translate } from 'react-admin';

import config from '../../../config/config';
let urlAppjs = config.UrlServer;

/**
 * @param tipoElemento = la entidad a apuntar en el fetcher
 * @param abInicio = la abscisa inicial para el filtro
 * @param abFin = la abscisa final para el filtro
 * 
 */

class AddElement extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            Elementos: [],
            datos: [],
            politicas: {
                rutinario: [],
                periodico: [],
                rehabilitacion: [],
                cambio: []
            },
            politicasParent: [],
            isLoadedPolicy: {
                rutinario: false,
                periodico: false,
                rehabilitacion: false,
                cambio: false
            },
            isLoadedElements: false,

        }
        this.sources = {
            ZINT: "elemento_alrededors", SEPD: "elemento_alrededors", OBST: "elemento_alrededors", MARG: "elemento_alrededors",
            COMR: "elemento_alrededors", ACCS: "elemento_alrededors", OCPN: "elemento_alrededors",

            PART: "adoquin_detalles",

            POTR: "otro_detalles",

            ZCOR: "elemento_drenajes", POZT: "elemento_drenajes", DREN: "elemento_drenajes", DESC: "elemento_drenajes",
            CUNT: "elemento_drenajes", BOXC: "elemento_drenajes", BAJT: "elemento_drenajes", ALCT: "elemento_drenajes",

            INIV: "otros_elementos", IDES: "otros_elementos", CASC: "otros_elementos",

            PNTE: "puentes", PNTEP: "puentes", MCON: "elemento_contencions", ESTE: "elemento_contencions", CONT: "elemento_contencions",

            SOS: "elemento_reds", SEM: "elemento_reds", PST: "elemento_reds", PMZ: "elemento_reds", LUM: "elemento_reds",
            ETD: "elemento_reds", ENL: "elemento_reds", CTV: "elemento_reds", CSP: "elemento_reds", BDP: "elemento_reds",
            ANT: "elemento_reds", DCT: "elemento_reds", REE: "elemento_reds",

            TPVH: "seguridads", PRTP: "seguridads", DEFMT: "seguridads", CERC: "seguridads", CAPT: "seguridads",
            BRRJ: "seguridads", BRRF: "seguridads", BRRC: "seguridads", BORD: "seguridads",

            DEM: "elemento_senalizacions", OTR: "elemento_senalizacions", SH: "elemento_senalizacions", SV: "elemento_senalizacions",

            TRAF: "elemento_traficos",
        }
        this.stagedProps = {
            unidadFuncional: this.props.unidadFuncional,
            tramo: this.props.tramo,
            tipoElemento: this.props.tipoElemento,
            tipoElementoSenal: this.props.tipoElementoSenal,
            abInicio: this.props.abInicio,
            abFin: this.props.abFin
        }
        this.projectId = window.localStorage.getItem('id_project');
        this.timeOutVar = "";
        this.fetcher.bind(this);
        this.fetchPolicies.bind(this);
        this.returnElementsComponent.bind(this);
        this.formatAbscisa.bind(this);
        this.formDataMerger.bind(this);
    }

    componentDidMount() {
        this.fetcher(
            (this.props.tipoElemento) ? this.sources[this.props.tipoElemento.split("/")[2]] : this.sources[this.props.tipoElementoSenal.split("/")[2]],
            this.props.tramo,
            this.formatAbscisa(this.props.abInicio),
            this.formatAbscisa(this.props.abFin)
        );
        this.fetchPolicies("rutinario", (this.props.tipoElemento) ? this.props.tipoElemento : this.props.tipoElementoSenal);
        this.fetchPolicies("periodico", (this.props.tipoElemento) ? this.props.tipoElemento : this.props.tipoElementoSenal);
        this.fetchPolicies("rehabilitacion", (this.props.tipoElemento) ? this.props.tipoElemento : this.props.tipoElementoSenal);
        this.fetchPolicies("cambio", (this.props.tipoElemento) ? this.props.tipoElemento : this.props.tipoElementoSenal);
    }
    componentDidUpdate() {
        if (this.timeOutVar !== "") {
            clearTimeout(this.timeOutVar)
        }
        this.timeOutVar = setTimeout(() => {
            if (
                this.stagedProps.unidadFuncional != this.props.unidadFuncional ||
                this.stagedProps.tramo != this.props.tramo ||
                this.stagedProps.tipoElemento != this.props.tipoElemento ||
                this.stagedProps.tipoElementoSenal != this.props.tipoElementoSenal ||
                this.stagedProps.abInicio != this.props.abInicio ||
                this.stagedProps.abFin != this.props.abFin
            ) {
                this.stagedProps.unidadFuncional = this.props.unidadFuncional
                this.stagedProps.tramo = this.props.tramo;
                this.stagedProps.tipoElemento = this.props.tipoElemento;
                this.stagedProps.tipoElementoSenal = this.props.tipoElementoSenal;
                this.stagedProps.abInicio = this.props.abInicio;
                this.stagedProps.abFin = this.props.abFin;

                this.state.Elementos = [];
                this.state.datos = [];
                this.state.politicas.rutinario = [];
                this.state.politicas.periodico = [];
                this.state.politicas.rehabilitacion = [];
                this.state.politicas.cambio = [];

                this.state.isLoadedPolicy.rutinario = false;
                this.state.isLoadedPolicy.periodico = false;
                this.state.isLoadedPolicy.rehabilitacion = false;
                this.state.isLoadedPolicy.cambio = false;

                this.componentDidMount();
            }

        }, 1000)
    }

    fetcher(target, tramo = null, abInicio, abFin, otherFilters = null) {
        this.setState({ isLoadedElements: 'loading' });

        var urlPart = "/" + target + "?pagination=off";
        urlPart += "&unidadFuncional=" + this.props.unidadFuncional;
        if (this.props.tipoElemento) {
            if (target == "puentes") {
                urlPart += "&elementos=" + this.props.tipoElemento
            } else {
                urlPart += "&tipoElemento=" + this.props.tipoElemento
            }
        } else {
            urlPart += "&tipoElementoSenal=" + this.props.tipoElementoSenal
        }
        urlPart += "&estados=Activo&aprobacion=Aprobado";

        console.log(urlPart, "url fetch")

        if (tramo != null) {
            urlPart = urlPart + "&tramo=" + tramo;
        }
        if (abInicio > 0) {
            urlPart = urlPart + "&segmento.abInicio[gte]=" + abInicio;
        }

        if (abFin > 0) {
            urlPart = urlPart + "&segmento.abFin[lte]=" + abFin;
        }
        if (Array.isArray(otherFilters)) {
            otherFilters.map(filter => {
                urlPart = urlPart + "&" + filter;
            })
        } else if (otherFilters !== null) {
            urlPart = urlPart + "&" + otherFilters;
        }

        fetch(urlAppjs + urlPart,
            {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                return response.json()
            })
            .then((Elementos) => {
                var datos = [];
                this.state.datos = [];
                this.setState({
                    Elementos: Elementos["hydra:member"],
                    isLoadedElements: true
                })
                this.state.Elementos.map(index => {
                    datos = {
                        codigoConsecutivo: index.fullCodigoConsecutivo,
                        segmento: index.segmento["fullCodigo"],
                        tipoElemento: this.props.tipoElemento,
                        tipoElementoSenal: this.props.tipoElementoSenal,
                        elementoInventarioId: index["@id"],
                        abInicio: index.abInicio,
                        abFin: index.abFin
                    }
                    console.log(index)
                    this.state.datos.push(datos);
                })
            }).then(() => {
                this.setState({ isLoadedElements: true })
                this.forceUpdate();
            })
            .catch(function (error) {
                console.log(error)
            });
    }
    fetchPolicies(tipoMant, tipoElemento) {
        this.state.isLoadedPolicy[tipoMant] = 'loading';
        var entityMant = "";
        switch (tipoMant) {
            case "rutinario": entityMant = "politicas_elementos"; break;
            case "periodico": entityMant = "politicas_elemento_periodicos"; break;
            case "rehabilitacion": entityMant = "politicas_elemento_rehabilitacions"; break;
            case "cambio": entityMant = "politicas_elemento_cambios"; break;
        }
        var tipoElemento = this.props.tipoElemento;
        var tipoELementoSenal = this.props.tipoElementoSenal
        var urlPart = "/" + entityMant +
            "?pagination=off" +
            "&seRealiza=1" +
            "&politicasMantenimiento.unidadFuncional=" + this.props.unidadFuncional;
        urlPart += (this.props.tipoElemento) ? "&tipoElemento=" +
            this.props.tipoElemento : "&tipoElementoSenal=" + this.props.tipoElementoSenal;

        fetch(urlAppjs + urlPart,
            {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                return response.json()
            })
            .then((Elementos) => {
                this.state.politicas[tipoMant] = [];
                var datos = [];
                if (Elementos["hydra:member"].length) {
                    Elementos["hydra:member"].map(index => {
                        datos = {
                            politicaElementoModificado: index.id,
                            politicasMantenimiento: index.politicasMantenimiento,
                            nombrePoliticaString: index.parentName.politicas_nombre,
                            nombrePolitica: "/politicas_nombres/" + index.parentName.politicas_nombre_id,
                            valorU2: index.valorU,
                            fechaU2: index.fechaU,
                            fuente2: index.fuente,
                            fecha2: index.fecha,
                            descripcion2: index.descripcion
                        }
                        if (index.hasOwnProperty("prolVidaUtil")) {
                            datos.prolVidaUtil2 = index.prolVidaUtil;
                        }
                        if (tipoMant == "rutinario" || tipoMant == "periodico") {
                            if (index.periocidad == 1) {
                                datos.periocidad2 = index.periocidad + " Mes";
                            } else {
                                datos.periocidad2 = index.periocidad + " Meses";
                            }
                        } else {
                            datos.periocidad2 = index.periocidad + " Años";
                        }
                        this.state.politicas[tipoMant].push(datos);
                        this.state.isLoadedPolicy[tipoMant] = true;
                    })
                } else {
                    this.state.isLoadedPolicy[tipoMant] = false;
                }
                this.forceUpdate();
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    returnElementsComponent(classes) {
        const { translate } = this.props;
        const diligenciado = (value) => {
            var errors = 0;
            var returnMsg = "";
            if (typeof value !== 'undefined') {
                for (var i = 0; i < value.length; i++) {
                    if (value[i].seRealiza != true) {
                        errors = 1;
                    } else {
                        errors = 0;
                        break;
                    }
                }
                if (errors > 0) {
                    return translate('resources.validaciones.unElemento');
                }
            }

        }

        const itemSource = (this.props.isClone) ? "politicasParticularesItems2" : "politicasParticularesItems"
        // console.log(itemSource)

        return (
            <ArrayInput
                label=" "
                source={itemSource}
                validate={[diligenciado]}
                defaultValue={this.state.datos}
            >
                <SimpleFormIterator
                    disableAdd
                    disableRemove
                >
                    <TextInput disabled source="tipoElemento" fullWidth formClassName={classes.ocultar} />
                    <TextInput disabled source="tipoElementoSenal" fullWidth formClassName={classes.ocultar} />
                    <TextInput disabled source="elementoInventarioId" fullWidth formClassName={classes.ocultar} />
                    <TextInput disabled label="resources.politicas_mantenimiento_particulars.fields.codigoConsecutivo" source="codigoConsecutivo" fullWidth formClassName={classes.fieldCol5} />
                    {/* <ReferenceInput
                        label="ID en Inventario"
                        source="elementoInventarioId"
                        reference={this.sources[source]}
                        fullWidth
                        disabled
                        formClassName={classes.fieldCol5}
                    >
                        <AutocompleteInput options={{ fullWidth: true, disabled: true }} optionText="fullCodigoConsecutivo" />
                    </ReferenceInput> */}
                    <PRInput disabled label="resources.politicas_mantenimiento_particulars.fields.abInic" source="abInicio" fullWidth formClassName={classes.fieldCol2} />
                    <PRInput disabled label="resources.politicas_mantenimiento_particulars.fields.abFin" source="abFin" fullWidth formClassName={classes.fieldCol2} />
                    <BooleanInput label="dynatest.resources.politicas_mantenimiento_particulars.boolean.seRealiza" source="seRealiza" defaultValue={false} fullWidth formClassName={classes.fieldCol3} />
                </SimpleFormIterator>
            </ArrayInput>

        )

    }

    returnPoliciesComponent(tipoPolitica, classes) {
        var choices = [];
        var source = "";
        switch (tipoPolitica) {
            case "rutinario":
                choices = [
                    { id: 1, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.rutinario.Mes1' },
                    { id: 3, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.rutinario.Meses3' },
                    { id: 6, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.rutinario.Meses6' },
                    { id: 9, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.rutinario.Meses9' },
                    { id: 12, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.rutinario.Meses12' },
                    { id: 18, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.rutinario.Meses18' },
                ];
                source = "politicasElementoParticulars";
                break;
            case "periodico":
                choices = [
                    { id: 1, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.periodico.Mes1' },
                    { id: 3, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.periodico.Meses3' },
                    { id: 6, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.periodico.Meses6' },
                    { id: 9, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.periodico.Meses9' },
                    { id: 12, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.periodico.Meses12' },
                    { id: 18, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.periodico.Meses18' },
                    { id: 24, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.periodico.Meses24' },
                    { id: 36, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.periodico.Meses36' },
                ];
                source = "politicasElementoPeriodicoParticulars";
                break;
            case "rehabilitacion":
                choices = [
                    { id: 2, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.rehabilitacion.anios2' }, 
                    { id: 2.5, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.rehabilitacion.anios2_5' },
                    { id: 3, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.rehabilitacion.anios3' }, 
                    { id: 3.5, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.rehabilitacion.anios3_5' },
                    { id: 4, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.rehabilitacion.anios4' }, 
                    { id: 4.5, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.rehabilitacion.anios4_5' },
                    { id: 5, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.rehabilitacion.anios5' }, 
                    { id: 5.5, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.rehabilitacion.anios5_5' },
                    { id: 6, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.rehabilitacion.anios6' }, 
                    { id: 6.5, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.rehabilitacion.anios6_5' },
                    { id: 7, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.rehabilitacion.anios7' }, 
                    { id: 7.5, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.rehabilitacion.anios7_5' },
                    { id: 8, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.rehabilitacion.anios8' }, 
                    { id: 8.5, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.rehabilitacion.anios8_5' },
                    { id: 9, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.rehabilitacion.anios9' }, 
                    { id: 9.5, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.rehabilitacion.anios9_5' },
                    { id: 10, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.rehabilitacion.anios10' }
                ];
                source = "politicasElementoRehabilitacionParticulars";
                break;
            case "cambio":
                choices = [
                    { id: 3, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.cambio.anios3' }, 
                    { id: 4, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.cambio.anios4' }, 
                    { id: 5, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.cambio.anios5' },
                    { id: 6, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.cambio.anios6' }, 
                    { id: 7, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.cambio.anios7' }, 
                    { id: 8, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.cambio.anios8' },
                    { id: 9, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.cambio.anios9' }, 
                    { id: 10, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.cambio.anios10' }, 
                    { id: 11, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.cambio.anios11' },
                    { id: 12, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.cambio.anios12' }, 
                    { id: 13, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.cambio.anios13' }, 
                    { id: 14, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.cambio.anios14' },
                    { id: 15, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.cambio.anios15' }, 
                    { id: 16, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.cambio.anios16' }, 
                    { id: 17, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.cambio.anios17' },
                    { id: 18, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.cambio.anios18' }, 
                    { id: 19, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.cambio.anios19' }, 
                    { id: 20, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.cambio.anios20' },
                    { id: 21, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.cambio.anios21' }, 
                    { id: 22, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.cambio.anios22' }, 
                    { id: 23, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.cambio.anios23' },
                    { id: 24, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.cambio.anios24' }, 
                    { id: 25, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.cambio.anios25' }, 
                    { id: 26, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.cambio.anios26' },
                    { id: 27, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.cambio.anios27' }, 
                    { id: 28, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.cambio.anios28' }, 
                    { id: 29, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.cambio.anios29' },
                    { id: 30, name: 'dynatest.resources.politicas_mantenimiento_particulars.select.cambio.anios30' }
                ];
                source = "politicasElementoCambioParticulars";
                break;
        }
        const diligenciado = (value) => {
            const {translate} = this.props;
            var errorMsg = [];
            var policiesNumber = 0;
            var returnMsg = "";
            var today = new Date();
            var month = "";
            if ((today.getMonth() + 1) < 10) {
                month = "0" + (today.getMonth() + 1)
            } else {
                month = (today.getMonth() + 1)
            }
            today = today.getFullYear() + "-" + month + "-" + today.getDate();

            if (typeof value !== 'undefined') {
                for (var i = 0; i < value.length; i++) {
                    if (value[i].seRealiza == true) {

                        policiesNumber++;

                        if (policiesNumber > 1) {
                            errorMsg[0] = translate('resources.validaciones.politicaGeneral');
                        } else if (value[i].fecha > today) {
                            errorMsg[0] = translate('resources.validaciones.fechaFutura');
                        } else if (
                            value[i].periocidad == null || value[i].periocidad == "" ||
                            value[i].valorU == null || value[i].valorU == "" ||
                            value[i].fechaU == null || value[i].fechaU == "" ||
                            value[i].fuente == null || value[i].fuente == "" ||
                            value[i].fecha == null || value[i].fecha == "" ||
                            value[i].descripcion == null || value[i].descripcion == "") {
                            errorMsg[0] = translate('resources.validaciones.todosLosDatos');
                        } else {
                            if (value[i].fecha > today) {
                                errorMsg.push(value[i].nombreElemento + " " + translate('resources.validaciones.fechaFutura'));
                            } else {
                                errorMsg = [];
                            }

                            if (value[i].fechaU > today) {
                                errorMsg.push(value[i].nombreElemento + " " + translate('resources.validaciones.fechaFutura'));
                            } else {
                                errorMsg = [];
                            }
                        }
                    }
                }
            }
            if (errorMsg.length > 0) {
                for (var i = 0; i < errorMsg.length; i++) {
                    returnMsg = returnMsg + errorMsg[i] + ". ";
                }
                return returnMsg;
            }
        }
        return (
            <FormDataConsumer>
                {({ formData, dispatch, ...rest }) =>
                    <div>
                        {console.log(formData)}
                        <ArrayInput
                            label=" "
                            source={(this.props.isClone) ? source + "Clone" : source}
                            validate={[diligenciado]}
                            defaultValue={(this.props.isClone) ? this.formDataMerger(formData[source], this.state.politicas[tipoPolitica]) : this.state.politicas[tipoPolitica]}
                        >
                            <SimpleFormIterator disableAdd disableRemove >

                                {/* <ReferenceInput
                                    label="Nombre de la Política"
                                    source="politicasMantenimiento"
                                    reference="politicas_mantenimientos"
                                    disabled
                                    fullWidth
                                >
                                    <SelectInput optionText="nombre_.nombre" fullWidth />
                                </ReferenceInput> */}
                                {/* <TextInput label="Politica Elemento Modificado" source="politicaElementoModificado" disabled fullWidth formClassName={classes.fieldCol12} /> */}
                                <TextInput label="resources.politicas_mantenimiento_particulars.fields.nombrePoliticaString" source="nombrePoliticaString" disabled fullWidth formClassName={classes.fieldCol12} />
                                <TextInput label="resources.politicas_mantenimiento_particulars.fields.nombrePolitica" source="nombrePolitica" disabled fullWidth formClassName={classes.ocultar} />

                                <TextInput
                                    label="resources.politicas_mantenimiento_particulars.fields.periocidad2"
                                    source="periocidad2"
                                    disabled
                                    fullWidth
                                    formClassName={classes.fieldCol2}
                                />
                                <CurrencyInput
                                    label="resources.politicas_mantenimiento_particulars.fields.valorU2"
                                    source="valorU2"
                                    disabled
                                    fullWidth
                                    className="align-number"
                                    formClassName={classes.fieldCol2}
                                />
                                <DateInput
                                    label="resources.politicas_mantenimiento_particulars.fields.fechaU2"
                                    fullWidth
                                    source="fechaU2"
                                    disabled
                                    formClassName={classes.fieldCol2}
                                />
                                <TextInput
                                    label="resources.politicas_mantenimiento_particulars.fields.fuente2"
                                    source="fuente2"
                                    disabled
                                    fullWidth
                                    formClassName={classes.fieldCol3}
                                />
                                {tipoPolitica === "rutinario" &&
                                    <DateInput
                                        label="resources.politicas_mantenimiento_particulars.fields.fecha2"
                                        source="fecha2"
                                        disabled
                                        fullWidth
                                        formClassName={classes.fieldCol3}
                                    />
                                }
                                {tipoPolitica === "periodico" &&
                                    <DateInput
                                        label="resources.politicas_mantenimiento_particulars.fields.fecha2"
                                        source="fecha2"
                                        disabled
                                        fullWidth
                                        formClassName={classes.fieldCol3}
                                    />
                                }
                                {tipoPolitica === "rehabilitacion" &&
                                    <NumberInput
                                        label="resources.politicas_mantenimiento_particulars.fields.prolVidaUtil2"
                                        source="prolVidaUtil2"
                                        disabled
                                        fullWidth
                                        formClassName={classes.fieldCol1}
                                    />
                                }
                                {tipoPolitica === "rehabilitacion" &&
                                    <DateInput
                                        label="resources.politicas_mantenimiento_particulars.fields.fecha2"
                                        source="fecha2"
                                        disabled
                                        fullWidth
                                        formClassName={classes.fieldCol2}
                                    />
                                }
                                {tipoPolitica === "cambio" &&
                                    <NumberInput
                                        label="resources.politicas_mantenimiento_particulars.fields.prolVidaUtil2"
                                        source="prolVidaUtil2"
                                        disabled
                                        fullWidth
                                        formClassName={classes.fieldCol1}
                                    />
                                }
                                {tipoPolitica === "cambio" &&
                                    <DateInput
                                        label="resources.politicas_mantenimiento_particulars.fields.fecha2"
                                        source="fecha2" disabled fullWidth formClassName={classes.fieldCol2} />
                                }

                                <br />
                                <TextInput label="resources.politicas_mantenimiento_particulars.fields.descripcion2" source="descripcion2" disabled fullWidth formClassName={classes.fieldCol12} />

                                <Divider fullWidth />

                                <BooleanInput label="dynatest.resources.politicas_mantenimiento_particulars.boolean.seRealizaModif" source="seRealiza" fullWidth defaultValue={false} formClassName={classes.fieldCol12} />

                                {/**************************RUTINARIO************************************************** */}
                                {tipoPolitica === "rutinario" &&
                                    <AutocompleteInput
                                        label="resources.politicas_mantenimiento_particulars.fields.periocidad"
                                        source="periocidad"
                                        fullWidth
                                        options={{ fullWidth: true }}
                                        choices={choices}
                                        formClassName={classes.fieldCol3}
                                    />
                                }
                                {tipoPolitica === "rutinario" && <CurrencyInput label="resources.politicas_mantenimiento_particulars.fields.valorU" source="valorU" fullWidth className="align-number" formClassName={classes.fieldCol2} />}
                                {tipoPolitica === "rutinario" && <DateInput label="resources.politicas_mantenimiento_particulars.fields.fechaU" fullWidth source="fechaU" formClassName={classes.fieldCol2} />}
                                {tipoPolitica === "rutinario" && <TextInput label="resources.politicas_mantenimiento_particulars.fields.fuente" source="fuente" fullWidth formClassName={classes.fieldCol2} />}
                                {tipoPolitica === "rutinario" && <DateInput label="resources.politicas_mantenimiento_particulars.fields.fecha" source="fecha" fullWidth formClassName={classes.fieldCol3} />}

                                {/**************************PERIODICO************************************************** */}
                                {tipoPolitica === "periodico" &&
                                    <AutocompleteInput
                                        label="resources.politicas_mantenimiento_particulars.fields.periocidad"
                                        source="periocidad"
                                        fullWidth
                                        options={{ fullWidth: true }}
                                        choices={choices}
                                        formClassName={classes.fieldCol3}
                                    />
                                }
                                {tipoPolitica === "periodico" && <CurrencyInput label="resources.politicas_mantenimiento_particulars.fields.valorU" source="valorU" fullWidth className="align-number" formClassName={classes.fieldCol2} />}
                                {tipoPolitica === "periodico" && <DateInput label="resources.politicas_mantenimiento_particulars.fields.fechaU" fullWidth source="fechaU" formClassName={classes.fieldCol2} />}
                                {tipoPolitica === "periodico" && <TextInput label="resources.politicas_mantenimiento_particulars.fields.fuente" source="fuente" fullWidth formClassName={classes.fieldCol2} />}
                                {tipoPolitica === "periodico" && <DateInput label="resources.politicas_mantenimiento_particulars.fields.fecha" source="fecha" fullWidth formClassName={classes.fieldCol3} />}

                                {/**************************REHABILITACION************************************************** */}
                                {tipoPolitica === "rehabilitacion" &&
                                    <AutocompleteInput
                                        label="resources.politicas_mantenimiento_particulars.fields.periocidad"
                                        source="periocidad"
                                        fullWidth
                                        options={{ fullWidth: true }}
                                        choices={choices}
                                        formClassName={classes.fieldCol2}
                                    />
                                }
                                {tipoPolitica === "rehabilitacion" && <CurrencyInput label="resources.politicas_mantenimiento_particulars.fields.valorU" source="valorU" fullWidth className="align-number" formClassName={classes.fieldCol2} />}
                                {tipoPolitica === "rehabilitacion" && <DateInput label="resources.politicas_mantenimiento_particulars.fields.fechaU" fullWidth source="fechaU" formClassName={classes.fieldCol2} />}
                                {tipoPolitica === "rehabilitacion" && <TextInput label="resources.politicas_mantenimiento_particulars.fields.fuente" source="fuente" fullWidth formClassName={classes.fieldCol2} />}
                                {tipoPolitica === "rehabilitacion" && <NumberInput label="resources.politicas_mantenimiento_particulars.fields.prolVidaUtil" source="prolVidaUtil" fullWidth formClassName={classes.fieldCol2} />}
                                {tipoPolitica === "rehabilitacion" && <DateInput label="resources.politicas_mantenimiento_particulars.fields.fecha" source="fecha" fullWidth formClassName={classes.fieldCol2} />}

                                {/**************************CAMBIO************************************************** */}
                                {tipoPolitica === "cambio" &&
                                    <AutocompleteInput
                                        label="resources.politicas_mantenimiento_particulars.fields.periocidad"
                                        source="periocidad"
                                        fullWidth
                                        options={{ fullWidth: true }}
                                        choices={choices}
                                        formClassName={classes.fieldCol2}
                                    />
                                }
                                {tipoPolitica === "cambio" && <CurrencyInput label="resources.politicas_mantenimiento_particulars.fields.valorU" source="valorU" fullWidth className="align-number" formClassName={classes.fieldCol2} />}
                                {tipoPolitica === "cambio" && <DateInput label="resources.politicas_mantenimiento_particulars.fields.fechaU" fullWidth source="fechaU" formClassName={classes.fieldCol2} />}
                                {tipoPolitica === "cambio" && <TextInput label="resources.politicas_mantenimiento_particulars.fields.fuente" source="fuente" fullWidth formClassName={classes.fieldCol2} />}
                                {tipoPolitica === "cambio" && <NumberInput label="resources.politicas_mantenimiento_particulars.fields.VidaUtil" source="prolVidaUtil" fullWidth formClassName={classes.fieldCol1} />}
                                {tipoPolitica === "cambio" && <DateInput label="resources.politicas_mantenimiento_particulars.fields.fecha" source="fecha" fullWidth formClassName={classes.fieldCol2} />}


                                <TextInput label="resources.politicas_mantenimiento_particulars.fields.descripcion" source="descripcion" fullWidth formClassName={classes.fieldCol12} />
                            </SimpleFormIterator>
                        </ArrayInput>
                        {/* {console.log(formData)} */}
                    </div>
                }
            </FormDataConsumer>
        )
    }

    formatAbscisa(abscisa) {
        if (typeof abscisa == "string") {
            let Abinc = abscisa.split("+");
            Abinc[0] = Abinc[0].replace("K", "");
            let ingresoAbInicio = Abinc[0] + "." + Abinc[1];

            return ingresoAbInicio;
        }
        else {
            return abscisa;
        }
    }

    formDataMerger(formDataItem, aditionalObject) {
        if (this.props.isClone) {
            console.log(formDataItem)
            var normal = [];
            formDataItem.map((item, index) => {
                normal.push({ ...item, ...aditionalObject[index] })
            })
            return normal
        } else {
            return aditionalObject;
        }
    }

    render() {
        const { translate } = this.props;
        const { classes } = this.props;

        const preventSave = (value) => {
            return translate('resources.validaciones.filtrosValidos');
        }
        if (
            this.state.isLoadedPolicy.rutinario == 'loading' ||
            this.state.isLoadedPolicy.periodico == 'loading' ||
            this.state.isLoadedPolicy.rehabilitacion == 'loading' ||
            this.state.isLoadedPolicy.cambio == 'loading' ||
            this.state.isLoadedElements == 'loading'
        ) {
            // console.log(this.state);
            return <Loading loadingPrimary="Cargando..." />
        } else {

            if (this.state.datos.length > 0 &&
                this.state.isLoadedPolicy.rutinario == true &&
                this.state.isLoadedPolicy.periodico == true &&
                this.state.isLoadedPolicy.rehabilitacion == true &&
                this.state.isLoadedPolicy.cambio == true
            ) {
                return (
                    <Fragment>
                        <ExpansionPanel>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography >{translate('dynatest.resources.politicas_mantenimiento_particulars.expansionPanel.Elementos')}</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                {this.returnElementsComponent(classes)}
                            </ExpansionPanelDetails>
                        </ExpansionPanel>

                        <ExpansionPanel>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography >{translate('dynatest.resources.politicas_mantenimiento_particulars.expansionPanel.MantenimientoRutinario')}</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                {this.returnPoliciesComponent("rutinario", classes)}
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography >{translate('dynatest.resources.politicas_mantenimiento_particulars.expansionPanel.MantenimientoPeriodico')}</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                {this.returnPoliciesComponent("periodico", classes)}
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography >{translate('dynatest.resources.politicas_mantenimiento_particulars.expansionPanel.MantenimientoRehabilitacion')}</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                {this.returnPoliciesComponent("rehabilitacion", classes)}
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                        <ExpansionPanel>
                            <ExpansionPanelSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography >{translate('dynatest.resources.politicas_mantenimiento_particulars.expansionPanel.MantenimientoCambio')}</Typography>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                                {this.returnPoliciesComponent("cambio", classes)}
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </Fragment>
                )
            } else if (
                this.state.datos.length &&
                (
                    this.state.isLoadedPolicy.rutinario != true ||
                    this.state.isLoadedPolicy.periodico != true ||
                    this.state.isLoadedPolicy.rehabilitacion != true ||
                    this.state.isLoadedPolicy.cambio != true
                ) &&
                this.state.isLoadedElements == true

            ) {
                console.log(this.state);
                return (
                    <Fragment>
                        <ArrayInput
                            label=" "
                            source="preventSave"
                            validate={[preventSave]}
                        >
                            <SimpleFormIterator
                                disableAdd
                                disableRemove
                            >
                                <TextInput disabled label="tipo elemento" source="tipoElemento" fullWidth formClassName={classes.ocultar} />
                            </SimpleFormIterator>
                        </ArrayInput>
                        <div style={{ textAlign: "center" }}>
                            <Typography variant="h2" component="h2">
                                {translate('resources.validaciones.sinPoliticasAsociadas')}
                            </Typography>
                            {/* <br/> */}
                            <Typography variant="h5" component="h5">
                                {translate('resources.validaciones.politicasDiligencias')} {this.stagedProps.tipoElemento ? this.stagedProps.tipoElemento.split("/")[2] : this.stagedProps.tipoElementoSenal.split("/")[2]}.
                            </Typography>
                        </div>
                    </Fragment>
                )

            } else
            // if(
            // this.state.Elementos.length == 0 && 
            // this.state.isLoadedElements == true
            // ) 
            {
                // console.log(this.state);
                return (
                    <Fragment>
                        <ArrayInput
                            label=" "
                            source="preventSave"
                            validate={[preventSave]}
                        >
                            <SimpleFormIterator
                                disableAdd
                                disableRemove
                            >
                                <TextInput disabled label="tipo elemento" source="tipoElemento" fullWidth formClassName={classes.ocultar} />
                            </SimpleFormIterator>
                        </ArrayInput>
                        <div style={{ textAlign: "center" }}>
                            <Typography variant="h2" component="h2">
                                {translate('dynatest.resources.politicas_mantenimiento_particulars.messages.SinResultados')}
                            </Typography>
                        </div>
                    </Fragment>
                )

            }
        }
    }
}

AddElement.propTypes = {
    classes: PropTypes.object.isRequired,
}
const AddElementosMantenimientoParticular = withStyles(styles)(AddElement);


export default translate(AddElementosMantenimientoParticular);