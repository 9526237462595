import React, { useState } from 'react';
import {
    Toolbar, CardActions, NumberField, ShowController, ShowView, TextField, DateField, ReferenceField,
    BooleanField, TabbedForm, ListButton, EditButton, FormTab, FunctionField, TextInput, SelectField
} from 'react-admin';
import { GMapField } from '../../../fields/GMapField';
import { PRField } from './../../../fields/PRField';
import { PField } from './../../../fields/PField';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { styles } from './../../../EstilosGrid';
import { withStyles } from '@material-ui/core';
import { ButtonCarousel } from './../../images/ButtonCarousel';
import { translate } from 'react-admin';

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};


const PostShowActions = ({ basePath, data }) => (
    <CardActions style={cardActionStyle}>
        <EditButton basePath={basePath} record={data} />
        {/* Add your custom actions */}
        <ListButton basePath={basePath} />
    </CardActions>
);

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <span></span>

    </Toolbar>
));

export const ElementoDrenajeShow = translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;

    // const [leftNumber, setLeftNumber] = useState();
    // const alignLeft = () => {
    //     let width = "";

    //     if (document.getElementById("longitud")) {
    //         width = document.getElementById("longitud").parentNode.parentNode.childNodes[0].childNodes[0].offsetWidth;

    //         return ;
    //     }
    // }
    // setLeftNumber(alignLeft);
    const [imagen , setImagen] =useState(true);
    const ValidarImagen =  (value) =>{
        // console.log(value + "Aqui est la imagen ")
        if(value !== null && value !== undefined && value !== 'undefined')
        {
            let lenghtObject = Object.keys(value);
            let valor = '';        
            for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                
            setImagen(value) 
            // console.log(value + "Aqui est la imagen ")
        }
    }

    return (
        <ShowController {...props} >
            {controllerProps =>
                <ShowView {...props} {...controllerProps} title="dynatest.resources.elemento_drenajes.title.showTitle" actions={<ButtonCarousel />} >
                    <TabbedForm toolbar={<CustomToolbar />}>
                        <FormTab label="dynatest.resources.elemento_drenajes.tabs.tabsTitle.identificacion">
                            <TextField label="resources.elemento_drenajes.fields.fullCodigoConsecutivo" source="fullCodigoConsecutivo" fullWidth formClassName={classes.grid_cont4} />
                            {/* <NumberField label="Consecutivo" source="fullConsecutivo" fullWidth formClassName={classes.grid_cont4} /> */}
                            <br/>
                            <ReferenceField label="resources.elemento_drenajes.fields.tipoElemento" source="tipoElemento" reference="tipo_elementos" linkType={false} fullWidth formClassName={classes.grid_cont3_s}>
                                <TextField source="nombre" />
                            </ReferenceField>
                            {controllerProps.record && controllerProps.record.tipoElemento === "/tipo_elementos/CUNT" &&
                                <TextField label="resources.elemento_drenajes.fields.tipoCuneta" source="tipoCuneta" fullWidth formClassName={classes.grid_cont3_s} />
                            }
                            <TextField source="margen" label="resources.elemento_drenajes.fields.margen" fullWidth formClassName={classes.grid_cont3_s} />
                            <TextField source="sentido" label="resources.elemento_drenajes.fields.sentido" formClassName={classes.grid_cont3_s} />
                            <TextInput source="tipoElemento_.src" validate={ValidarImagen} style={{display : 'none'}}/>
                            
                            <Typography variant="h3" component="h3">{translate('dynatest.resources.elemento_drenajes.subTitle.localizacion')}</Typography>
                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <ReferenceField label="resources.elemento_drenajes.fields.unidadFuncional" source="unidadFuncional" reference="unidad_funcionals" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <TextField source="fullCodigos" />
                            </ReferenceField>
                            <ReferenceField label="resources.elemento_drenajes.fields.tramo" source="tramo" reference="tramos" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <TextField source="fullCodigos" />
                            </ReferenceField>
                            <ReferenceField label="resources.elemento_drenajes.fields.segmento" source="segmento" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont3}>
                                <TextField source="fullCodigos" />
                            </ReferenceField>
                            <PRField label="resources.elemento_drenajes.fields.abInicio" source="abInicio" fullWidth formClassName={classes.grid_cont4} />
                            <PRField label="resources.elemento_drenajes.fields.abFin" source="abFin" fullWidth formClassName={classes.grid_cont4} />
                            <NumberField id="longitud" label="resources.elemento_drenajes.fields.fullResta" source="fullResta" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3} />
                            <PField label="resources.elemento_drenajes.fields.pInicio" source="pInicio" fullWidth formClassName={classes.grid_cont4}/>
                            <PField label="resources.elemento_drenajes.fields.pFin"  source="pFin" fullWidth formClassName={classes.grid_cont4}/>

                            <Typography variant="b" component="b">{translate('dynatest.resources.elemento_drenajes.subTitle.coordenadasIniciales')}</Typography>
                            <Divider fullWidth style={{ width: '300%', marginTop: '10px' }} />

                            <NumberField label="resources.elemento_drenajes.fields.latitud" source="latitudInicial" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4} />
                            <NumberField label="resources.elemento_drenajes.fields.longitud" source="longitudInicial" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4} />
                            <span></span>


                            <Typography variant="b" component="b">{translate('dynatest.resources.elemento_drenajes.subTitle.coordenadasFinales')}</Typography>
                            <Divider fullWidth style={{ width: '300%', marginTop: '10px' }} />

                            <NumberField label="resources.elemento_drenajes.fields.latitud" source="latitudFinal" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4} />
                            <NumberField label="resources.elemento_drenajes.fields.longitud" source="longitudFinal" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4} />
                            <GMapField style={{ width: "100%" }}
                                defaultZoom={12}
                                defaultCenter={{ lat: 4.7567039, lng: -74.0311056 }}
                                source="ubicacionMapa"
                                multipleMarkers="true"
                                googleKey="AIzaSyCivTXzJBYRBK8nosBYRFXnv-hGXIbhnYY"
                                icon={imagen}
                            />

                        </FormTab >

                        <FormTab label="dynatest.resources.elemento_drenajes.tabs.tabsTitle.caracteristicas">
                            <TextField label="resources.elemento_drenajes.fields.material" source="material" fullWidth formClassName={classes.grid_cont3} />
                            <TextField label="resources.elemento_drenajes.fields.seccion" source="seccion" fullWidth formClassName={classes.grid_cont3} />
                            {controllerProps.record && (controllerProps.record.tipoElemento === "/tipo_elementos/ALCT" || controllerProps.record.tipoElemento === "/tipo_elementos/BOXC") &&
                                <TextField label="resources.elemento_drenajes.fields.tipoDelElemento" source="tipoDelElemento" fullWidth formClassName={classes.grid_cont3} />
                            }
                            <br />
                            <NumberField label="resources.elemento_drenajes.fields.largo" source="largo" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3} />
                            <NumberField label="resources.elemento_drenajes.fields.ancho" source="ancho" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3} />
                            {controllerProps.record && controllerProps.record.seccion !== "Circular" &&
                                <NumberField label="resources.elemento_drenajes.fields.altura" source="altura" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3} />
                            }
                            <br />
                            <NumberField label="resources.elemento_drenajes.fields.numeroTramos" source="numeroTramos" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3} />
                            <NumberField label="resources.elemento_drenajes.fields.seccionHidraulica" source="seccionHidraulica" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3} />
                            <br />
                            <FunctionField  
                                label="resources.elemento_drenajes.fields.pendienteTransversalShow" 
                                source="pendienteTransversal"
                                render={record => `${record.pendienteTransversal}%`} 
                                fullWidth 
                                style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} 
                                formClassName={classes.grid_cont3} 
                            />
                            <FunctionField 
                                label="resources.elemento_drenajes.fields.pendienteLongitudinalShow" 
                                source="pendienteLongitudinal"
                                render={record => `${record.pendienteLongitudinal}%`}  
                                fullWidth 
                                style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} 
                                formClassName={classes.grid_cont3} 
                            />
                            <br/>
                            {controllerProps.record && (controllerProps.record.tipoElemento == "/tipo_elementos/ALCT" || controllerProps.record.tipoElemento == "/tipo_elementos/BOXC") &&
                                <NumberField label="resources.elemento_drenajes.fields.numeroDuctos" source="numeroDuctos" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3} />
                            }
                            {controllerProps.record && (controllerProps.record.tipoElemento == "/tipo_elementos/ALCT" || controllerProps.record.tipoElemento == "/tipo_elementos/BOXC") &&
                                <TextField label="resources.elemento_drenajes.fields.tipoPlanta" source="tipoPlanta" fullWidth formClassName={classes.grid_cont3} />
                            }
                            {controllerProps.record && (controllerProps.record.tipoElemento == "/tipo_elementos/ALCT" || controllerProps.record.tipoElemento == "/tipo_elementos/BOXC") &&
                                <TextField label="resources.elemento_drenajes.fields.direccion" source="direccion" fullWidth formClassName={classes.grid_cont3} />
                            }
                            <br/>
                            {controllerProps.record && (controllerProps.record.tipoElemento == "/tipo_elementos/ALCT" || controllerProps.record.tipoElemento == "/tipo_elementos/BOXC") &&
                                <NumberField label="resources.elemento_drenajes.fields.areaAla" source="areaAla" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3} />
                            }
                            {controllerProps.record && (controllerProps.record.tipoElemento == "/tipo_elementos/ALCT" || controllerProps.record.tipoElemento == "/tipo_elementos/BOXC") &&
                                <NumberField label="resources.elemento_drenajes.fields.areaCabezal" source="areaCabezal" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3} />
                            }
                            {controllerProps.record && (controllerProps.record.tipoElemento == "/tipo_elementos/ALCT" || controllerProps.record.tipoElemento == "/tipo_elementos/BOXC") &&
                                <NumberField label="resources.elemento_drenajes.fields.areaCalzada" source="areaCalzada" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3} />
                            }
                            {controllerProps.record && (controllerProps.record.tipoElemento == "/tipo_elementos/ALCT" || controllerProps.record.tipoElemento == "/tipo_elementos/BOXC") &&
                                <NumberField label="resources.elemento_drenajes.fields.areaLateral" source="areaLateral" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3} />
                            }
                            <br/>
                            <span></span>
                        </FormTab>
                        <FormTab label="dynatest.resources.elemento_drenajes.tabs.tabsTitle.detalles">
                            <TextField label="resources.elemento_drenajes.fields.estado" source="estado" formClassName={classes.grid_cont3} />
                            {controllerProps.record && controllerProps.record.estado === translate('dynatest.resources.elemento_drenajes.select.estado.reemplazo') &&
                                <TextField label="resources.elemento_drenajes.fields.motivoReemplazo" source="motivoReemplazo" fullWidth formClassName={classes.grid_cont3} />
                            }
                            {controllerProps.record && controllerProps.record.estado === translate('dynatest.resources.elemento_drenajes.select.estado.reemplazo') &&
                                <ReferenceField
                                    label="resources.elemento_drenajes.fields.elementoReemplazo_id"
                                    source="elementoReemplazo_id"
                                    reference="elemento_drenajes"
                                    linkType={false}
                                    fullWidth
                                    formClassName={classes.grid_cont3}
                                >
                                    <TextField source="fullCodigoConsecutivo" />
                                </ReferenceField>
                            }
                            <br/>
                            
                             <TextField label="resources.elemento_drenajes.fields.estados" source="estados"  formClassName={classes.grid_cont3} />
                            {controllerProps.record && controllerProps.record.estados === translate('dynatest.resources.elemento_drenajes.select.estados.Inactivo') &&
                                <TextField
                                    source="estadoRazon" 
                                    label="resources.elemento_drenajes.fields.estadoRazon" 
                                    formClassName={classes.grid_cont3} 
                                />
                            }
                            <br />
                            <DateField label="resources.elemento_drenajes.fields.fechaInstalacion" source="fechaInstalacion" formClassName={classes.grid_cont3} />
                            <ReferenceField label="resources.elemento_drenajes.fields.proveedor" source="proveedor" reference="proveedors" linkType={false} fullWidth formClassName={classes.grid_cont3}>
                                <TextField source="razonSocial" />
                            </ReferenceField>
                            <NumberField label="resources.elemento_drenajes.fields.garantia" source="garantia" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3} />
                            <br />
                            <BooleanField label="dynatest.resources.elemento_drenajes.boolean.mantenimiento" source="mantenimiento" fullWidth formClassName={classes.grid_cont12} />
                            <TextField source="observaciones" label="resources.elemento_drenajes.fields.observaciones" formClassName={classes.grid_cont12} />
                        </FormTab>
                        <FormTab label="dynatest.resources.elemento_drenajes.tabs.tabsTitle.auditoria">
                            <DateField source="createdAt" label="resources.elemento_drenajes.fields.createdAt" fullWidth formClassName={classes.grid_cont3} showTime/>
                            <TextField source="createdBy" label="resources.elemento_drenajes.fields.createdBy" fullWidth formClassName={classes.grid_cont3} />
                            <TextField source="createdIn" label="resources.elemento_drenajes.fields.createdEn" fullWidth formClassName={classes.grid_cont3} />
                            <span></span>
                            <DateField source="updatedAt" label="resources.elemento_drenajes.fields.updatedAt" fullWidth formClassName={classes.grid_cont3} showTime/>
                            <TextField source="updatedBy" label="resources.elemento_drenajes.fields.updatedBy" fullWidth formClassName={classes.grid_cont3} />
                            <TextField source="updatedIn" label="resources.elemento_drenajes.fields.updatedEn" fullWidth formClassName={classes.grid_cont3} />
                            <span></span>
                        </FormTab>
                    </TabbedForm>
                </ShowView>
            }
        </ShowController>

    )
}));