import React, { useState } from 'react';
import { TabbedShowLayout, Tab, TextField, DateField } from 'react-admin';
import { Show } from './../Show';
import {  BulkDeleteWithConfirmButton  } from 'react-admin';
import { Fragment } from 'react';
import { translate } from 'react-admin';
const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);

export const IdiomaShow = translate((props) => (
        <Show title="dynatest.resources.idiomas_config.showTitle"  {...props}   bulkActionButtons={<PostBulkActionButtons />}>
        <TabbedShowLayout>
            <Tab label="resources.idiomas.fields.informacionIdioma">
                <TextField source="nombreIdioma"/>
                <TextField source="region"/>
                <div></div>
                <TextField style={{width : '300%'}} source="estado"/>
                <TextField source="cargueIdioma"/>
                <div></div><div></div><div></div>
            </Tab>
            <Tab label="resources.idiomas.fields.auditoria">
                <DateField label="resources.idiomas.fields.createdAt" source="createdAt" showTime/> 
                <TextField label="resources.idiomas.fields.createdBy" source="createdBy" />
                <div></div>               
                <DateField label="resources.idiomas.fields.updatedAt" source="updatedAt" showTime/>
                <TextField label="resources.idiomas.fields.updatedBy" source="updatedBy" />
                <div></div>   
            </Tab>
        </TabbedShowLayout>
    </Show>
));
    