import React from 'react';

import { TextInput, SimpleForm, SaveButton, Toolbar} from 'react-admin';
import { translate } from 'react-admin';
import { withStyles } from '@material-ui/core';
import { Edit } from './../../../Edit';
import Coloramiento from './color/coloramiento';
import { required } from 'react-admin';
import UniqueUF from './validacion/validacionColores';


const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
));

const styles = {
    first_field: { display: 'inline-block', width: '30%' },    
};

function ValidaLetra (value){
    if (typeof value !== 'string') return ''
    return value[0].toUpperCase()+ value.slice(1);
}
const local = localStorage.getItem('id_project2');
export const ColorEdit = translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;
    return(
        <Edit title="dynatest.resources.colores.title.editTitle" {...props} undoable={false}>
            <SimpleForm redirect="list" >
            <h3 style={{marginLeft: '40%'}}>{translate('dynatest.resources.colores.title.paramAreaColor')}</h3>
                   <hr fullWidth></hr>
                   <WithProps formClassName={classes.grid_cont_div}>{({record,...props})=>
                   <UniqueUF disabled source="color" tipo="editar" validate={[required()]} format={ValidaLetra} />}
                   </WithProps>      
                <WithProps formClassName={classes.grid_cont_div}>{({record,...props})=>
                <Coloramiento record={record} tipo="editar"/> }
                </WithProps>
                <TextInput style={{display:"none"}} source="proyecto" />            
            </SimpleForm>
        </Edit>
)}));
const WithProps = ({children,...props}) => children(props);