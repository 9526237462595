import React, {useState} from 'react';
import {  TabbedShowLayout, TabbedForm ,Toolbar, SelectInput, SimpleForm} from 'react-admin';
import { Show } from './../Show';
import { withStyles } from '@material-ui/core';
import  {styles}  from './../../EstilosGrid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Parametros from './parametros';
import { translate } from 'react-admin';
const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};
const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <span>.</span>
        
    </Toolbar> 
));
export const ParametrosShow = translate(withStyles(styles)(({ classes, ...props }) => {
const {translate} = props;
const [tipoParametro, setTipoParametro] = useState('');
const validarTipoParametro=(value)=>{
setTipoParametro(value);
}
return (
<Show title="dynatest.resources.parametros_evaluacion.title.showTitle"  {...props}>
<SimpleForm submitOnEnter={false} toolbar={<CustomToolbar/>}>   
<Typography component="b" variant="b" style={{textAlign: "center"}} formClassName={classes.grid_cont12} fullWidth>{translate('resources.parametros_evaluacion.fields.titleParametros')}</Typography>
        <Divider formClassName={classes.grid_cont12} fullWidth/>
        <SelectInput label="dynatest.resources.parametros_evaluacion.select.indicadores.indicadoresMessage" choices={[
                    { id: "All", name: "dynatest.resources.parametros_evaluacion.select.indicadores.todosParam" },
                    { id: "PFLEX", name: "dynatest.resources.parametros_evaluacion.select.indicadores.indiPavFlex" },
                    { id: "PRIG", name: "dynatest.resources.parametros_evaluacion.select.indicadores.indiPavRig" },
                    { id: "PRIGYFLEX", name: "dynatest.resources.parametros_evaluacion.select.indicadores.indiPavRigFlex" },
                    { id: "PUENTYEST", name: "dynatest.resources.parametros_evaluacion.select.indicadores.indiPuentesEstr" },
                    { id: "TUN", name: "dynatest.resources.parametros_evaluacion.select.indicadores.indiTuneles" },
                    { id: "OPER", name: "dynatest.resources.parametros_evaluacion.select.indicadores.indiOperac" },


         ]} formClassName={classes.grid_cont4} fullWidth validate={validarTipoParametro} defaultValue="All"/>
          <WithProps>{({record,...props})=>
         <Parametros aplicaind={tipoParametro} tipo="mostrar" record={record}/>
        }
        </WithProps>
</SimpleForm>
</Show>)
}))
const WithProps = ({children,...props}) => children(props);