import React, { useState } from 'react'
import { Query } from 'react-admin'
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { MapWithAMarker } from './../../inputs/GMapGen';
import CoordenadasProyecto from '../CoordenadasInventario';
// import { makeStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles(theme => ({
//   root: {
//     width: '100%',
//     '& > * + *': {
//       marginTop: theme.spacing(2),
//     },
//   },
// }));

const useStyles = theme => ({
    root: {
        width: '100%',
        '& > * + *': {
            //   marginTop: theme.spacing(2),
        },
    },
});

export default function LinearIndeterminate() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <LinearProgress />
            {/* <LinearProgress color="secondary" /> */}
        </div>
    );
}
export const ModalMapa = ({
    unidadFuncional,
    tramo,
    segmento,
    AbInicio,
    AbFin,
    Elemento,
    TipoElemento,
    SentidoTransito
}) => {
    console.log(
        "unidadFuncional: " + unidadFuncional,
        "tramo: " + tramo,
        "segmento: " + segmento,
        "abInicio: " + AbInicio,
        "abFin: " + AbFin,
        "elemento: " + Elemento,
        "tipoElemento: " + TipoElemento,
        "sentidoTransito: " + SentidoTransito
    )

    //VALIDACIONES DE FUNCIONES DE FILTROS
    // const [unidadFuncional, setUnidadFuncional] = useState();
    const [tabla, setTabla] = useState('');
    const [conteo, setConteo] = useState(0);
    // const UnidadFuncional = (value) =>{
    //     setUnidadFuncional(value)
    //     setTabla('elemento_senalizacion_perus')
    //     if(value !== undefined)
    //     setConteo(1)
    // }

    // const [tramos, setTramo] = useState();
    // const Tramo = (value) =>{
    //     setTramo(value)
    //     if(value !== undefined)
    //     setConteo(1)
    // }

    const [segmentos, setSegmento] = useState();
    const Segmento = (value) => {
        setSegmento(value)
        if (value !== undefined)
            setConteo(1)
    }

    // const [abInicio, setAbInicio] = useState();
    // const AbInicio = (value)  => {

    //     if(value !== null && value !== '' && value !== undefined){
    //         let valor=  value;
    //         //  valor =valor.value;
    //         let valor1 = valor.split('K')            
    //         valor1 =  valor1[1].split('+')            
    //         valor = valor1[0] +'.'+ valor1[1]
    //         setAbInicio(valor)    
    //         setTramo(tramos)
    //         setabInicioA('abInicio[gt]')                      

    //     }                
    // }

    // const [abFin, setAbFin] = useState(0);
    // const AbFin = (value) =>{
    //     if(value !== null && value !== ''){
    //         let valor=   document.getElementById('abFin');
    //         valor =valor.value;
    //         let valor1 = valor.split('K')
    //         valor1 =  valor1[1].split('+')
    //         valor = valor1[0] +'.'+ valor1[1]
    //         setAbFin(valor)             
    //     }       
    // }

    const [tipoSenal, setTipoSenal] = useState('');
    const TipoSenal = (value) => {
        setTipoSenal(value)
    }

    const Coordenadas = <CoordenadasProyecto />;

    const [abInicioA, setabInicioA] = useState('abInicio[gt]');

    let payload = {}
    if (window.hash === '#/elemento_senalizacion_perus?map') {
        payload = {
            pagination: { page: 1, perPage: 10000 },
            sort: { field: 'lat', order: 'ASC' },

        };
    } else {
        payload = {
            pagination: { page: 1, perPage: 10000 },
            sort: { field: 'lat', order: 'ASC' },
            filter: { unidadFuncional, tramo, segmento, 'abInicio[gt]': AbInicio, 'abInicio[lt]': AbFin, tipoElementoSenalPeru: Elemento, tipoSenalPeru: TipoElemento, sentidoTransito: SentidoTransito }
        };
    }



    let coordenadasProyec = [];
    let center = [];
    return (
        <div>
            <Query type='GET_ONE' resource='proyectos' payload={{ id: window.localStorage.getItem('id_project2') }}>
                {({ data, loading, error }) => {

                    let latitud = [];
                    // let datos = [];
                    // if (loading) { return <Loading />; }

                    if (data) {

                        return (

                            data.ubicaciones.map(items => {
                                center = {
                                    lat: items['lat'],
                                    lng: items['lng']
                                }
                                coordenadasProyec.push(center)
                            })

                        )
                    }
                    return <div></div>

                }}
            </Query>
            <Query type="GET_LIST" resource='elemento_senalizacion_perus' payload={payload} >
                {({ data, loading, error }) => {
                    if (data) {

                        // let GoogleMapstoken = "AIzaSyDnpZTyYCu_WV19ztwMGY90hvOQ-nEcLXs"; //old
                        let GoogleMapstoken = "AIzaSyAgv5BQ_0nFzgvvOrEun9MK_pQOzJB5-sE"; //new
                        let latitud = [];
                        let datos = [];
                        let Pr = [];
                        let urls = [];
                        let tipoSeñal = [];
                        let redirect = [];
                        let uf = '';
                        let tra = '';
                        let seg = '';
                        let sentidoTransito = '';
                        let informacion = [];

                        if (data !== []) {
                            return (
                                <Grid container sapcing={24}>
                                    <Grid item xs={12}>

                                        {data.map(item => {
                                            //  debugger      
                                            if (item.ubicacionMapa !== null) {
                                                item.ubicacionMapa.map(items => {
                                                    datos = {
                                                        lat: items['lat'],
                                                        lng: items['lng']
                                                    }
                                                    latitud.push(datos)
                                                })
                                            }

                                            let urla = item.senalVerticalPeruA_.url
                                            urls.push(urla)
                                            Pr = item.abInicioA;
                                            // Uf= item.unidadFuncional
                                            Pr = Pr.split('.')
                                            let pr = 'K' + Pr[0] + '+' + Pr[1];
                                            let redirectA = item.id
                                            redirectA = redirectA.split('/');
                                            redirectA = redirectA[2];

                                            let nombre = item.senalVerticalPeruA_.nombre;
                                            let info = {
                                                uf: item.unidadFuncionalA,
                                                tra: item.tramoA,
                                                seg: item.segmentoA,
                                                sentidoTransito: item.sentidoTransito,
                                                alt: item.alturaIni,
                                                tipo: item.tipoSenalPeruA,
                                                condicion: item.seguridadEvaluacion,
                                                id: item.senalVerticalPeruA_.ids,
                                                margen: item.margen,
                                                pr: pr
                                            }

                                            tipoSeñal.push(nombre)
                                            redirect.push(redirectA)
                                            informacion.push(info)

                                        })}
                                        <MapWithAMarker
                                            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GoogleMapstoken}&v=3.exp&libraries=geometry, rawing,places`}
                                            loadingElement={<div style={{ height: `100%` }} />}
                                            containerElement={<div style={{ height: `400px` }} />}
                                            mapElement={<div style={{ height: `100%` }} />}
                                            positions={latitud}
                                            defaultCenter={center}
                                            pr={Pr}
                                            url={urls}
                                            info={informacion}
                                            tipoSeñal={tipoSeñal}
                                            redirect={redirect}
                                            demarcacion={coordenadasProyec}
                                        />

                                    </Grid>
                                </Grid>
                            )
                        }


                        // if(conteo){
                        // debugger
                        else if (data === []) {
                            return (<div>Entro el mapa</div>)
                        }

                        // }

                    }
                    return <LinearIndeterminate />

                }}
            </Query>
        </div>
    )
}