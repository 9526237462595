import React from 'react';
import { NumberField, DisabledInput } from 'react-admin';
import loader from './../../../../../loader10.gif';
import config from '../../../../../config/config';
let urlAppjs = config.UrlServer;

class DecoloracionShow extends React.Component{

    constructor(props){
        super(props)
        this.state = { colores: [], datoEva: [] }
    }

    componentDidMount(){

        let url_elemento_aux = document.location.hash;
        let croed=url_elemento_aux.split("/");
        let crearoeditar="";
        let componente =""
        if(croed[2]==="create"){
            crearoeditar=1;
        }
        else{
        let url_elemento = url_elemento_aux.split("/%2F");
        componente = url_elemento[1];
        componente = componente.replace("%2F", "/");
        componente = componente.replace("/show/4", "");
        crearoeditar=2;
        }
        if(crearoeditar===2){
            this.setState({ datoEva: this.props.record })
            // fetch(urlAppjs+"/"+componente)
        // .then((response) => {
        //     return response.json()
        // })
        // .then((datoEva) => {
        //     this.setState({ datoEva: datoEva })
        // })
    }
        fetch(urlAppjs+"/colores.json")
        .then((response) => {
            return response.json()
        })
        .then((colores) => {
            this.setState({ colores: colores })
        })
        
    }

    render(){
        
        const { colores } = this.state;        
        const { datoEva } = this.state;        
        
        if(colores.length > 0){

            let responsex = [];
            let responsey = [];
            if(datoEva.decoloracion){
                console.log("SvColor") 
                console.log(datoEva.decoloracion[0].svColor)
                datoEva.decoloracion[0].svColor.map( responseSV => {
                    if(responseSV.valorx>0){
                        responsex.push(Number.parseFloat(responseSV.valorx).toFixed(3)) 
                        }
                        else{
                            responsex.push("-") 
                        }
                        if(responseSV.valory>0){
                        responsey.push(Number.parseFloat(responseSV.valory).toFixed(3))
                        }
                        else{
                            responsey.push("-") 
                        }
                }) 
            } 

            // 

            return(
                <React.Fragment>
                    
                     <div style={{display: 'flex', marginTop: '10px'}}>                    
                        {
                            colores.map((data, i) => (
                                <div key={i} style={{margin: '0px 15px', border: 'solid 1px', marginLeft: '0px', marginRight: '0px'}}>
                                    <div style={{textAlign: 'center', marginTop: '15px', borderBottom: 'solid 1px'}}>
                                        <span style={{marginLeft:'5px', marginRight:'5px'}}>{data.color}</span>
                                    </div>
                                    <div style={{textAlign: 'center', marginTop: '15px', borderBottom: 'solid 1px'}}>
                                    <span style={{marginLeft:'5px', marginRight:'5px'}}>{responsex[i]}</span>
                                    </div>
                                    <div style={{textAlign: 'center', marginTop: '15px'}}>
                                    <span style={{marginLeft:'5px', marginRight:'5px'}}>{responsey[i]}</span>
                                    </div>
                                </div>
                            

                                
                            ))
                        }
                    </div> 


                    
                    <hr />
                    <div style={{display: 'none', justifyContent: 'flex-end', marginTop: '-30px'}}>
                        <strong style={{display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}>Condición: </strong><DisabledInput source="condicion_decoloracion" label=" " defaultValue="Cumple"/>
                    </div> 
                    <DisabledInput source="dec_length" label=" " defaultValue={colores.length} style={{display: 'none'}}/>   
                </React.Fragment>
            )          
                     
        }else{                      
            return( 
                <div className="Reload2">
                    <img src={loader} />
                </div> 
            )   
        }
            
    }    
}

export default DecoloracionShow;