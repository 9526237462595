import React, { useState } from 'react';
import { TabbedForm, FormTab, TextInput, SingleFieldList, ShowController, ShowView, SelectField, TextField, DateField, ArrayField, ReferenceField, NumberField, BooleanField } from 'react-admin';
import { withStyles } from '@material-ui/core';
import { PRField } from './../../fields/PRField';
import { PField } from './../../fields/PField';
import Divider from '@material-ui/core/Divider';
import { GMapField } from './../../fields/GMapField';
import Reparaciones from './campos/viewreparacion';
import Typography from '@material-ui/core/Typography';
import { styles } from './../../EstilosGrid';
import { ButtonCarousel } from './../images/ButtonCarousel';
import { translate } from 'react-admin';

const choices1 = [
    { _id: '01', full_name: '01' },
    { _id: '02', full_name: '02' },
    { _id: '03', full_name: '03' },
];
const choices = [
    { _id: '01', full_name: 'dynatest.resources.puentes.select.choices.Circular' },
    { _id: '02', full_name: 'dynatest.resources.puentes.select.choices.Rectangular' },
    { _id: '00', full_name: 'dynatest.resources.puentes.select.choices.Otra' },
];
const optionRenderer = choice => `${choice.id}`;
const choises2 = [
    { id: '01 ', name: ' 01 ' },
    { id: '02 ', name: ' 02 ' },
    { id: '03 ', name: ' 03 ' },
    { id: '04 ', name: ' 04 ' },
    { id: '05 ', name: ' 05 ' },
    { id: '00 ', name: ' 00 ' },
    { id: 'N/A ', name: ' N/A ' },
]
const choises3 = [
    { id: '04 ', name: 'dynatest.resources.puentes.select.choises3.ApoyoFijo' },
    { id: '01 ', name: 'dynatest.resources.puentes.select.choises3.Balancin' },
    { id: '05 ', name: 'dynatest.resources.puentes.select.choises3.Basculante' },
    { id: '03 ', name: 'dynatest.resources.puentes.select.choises3.PlacaNeopreno' },
    { id: '02 ', name: 'dynatest.resources.puentes.select.choises3.Rodillo' },
    { id: '00 ', name: 'dynatest.resources.puentes.select.choises3.Otros' },
    { id: 'N/A ', name: 'dynatest.resources.puentes.select.choises3.NoAplica' },
];

export const PuenteShow = translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;
    const [imagen, setImagen] = useState(true);
    const [latitud, setLatitud] = useState();
    const [longitud, setLongitud] = useState();
    const Latitud = (value) => {
        setLatitud(value)
    }
    const Longitud = (value) => {
        setLongitud(value)
    }
    const ValidarImagen = (value) => {
        // console.log(value + "Aqui est la imagen ")
        if (value !== null && value !== undefined && value !== 'undefined') {
            let lenghtObject = Object.keys(value);
            let valor = '';
            for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
            setImagen(value)
            // console.log(value + "Aqui est la imagen ")
        }

    }
    return (
        <ShowController {...props} >
            {controllerProps =>
                <ShowView {...props} {...controllerProps} title="dynatest.resources.puentes.title.showTitle" actions={<ButtonCarousel />} >
                    <TabbedForm toolbar={false}>
                        <FormTab label="dynatest.resources.puentes.tabs.tabsTitle.identificacion">
                            <TextInput source="src" validate={ValidarImagen} style={{ display: 'none' }} />
                            <ReferenceField allowEmpty emptyValue="" label="resources.puentes.fields.elementos" source="elementos" reference="tipo_elementos" linkType={false} fullWidth formClassName={classes.grid_cont4} filter={{ sistema: 'PTS' }}>
                                <TextField source="nombre" />
                            </ReferenceField>

                            {controllerProps.record && controllerProps.record.elementos && controllerProps.record.elementos != '/tipo_elementos/PNTEP' &&
                                <TextField label="resources.puentes.fields.margen" source="margen" fullWidth linkType={false} formClassName={classes.grid_cont4} />
                            }
                            <TextField label="resources.puentes.fields.tipo" source="tipo" fullWidth linkType={false} formClassName={classes.grid_cont4} />
                            {controllerProps.record && controllerProps.record.elementos && controllerProps.record.elementos != '/tipo_elementos/PNTEP' &&
                                <NumberField source="carriles" label="resources.puentes.fields.carriles" className="align-number" allowEmpty emptyValue="" linkType={false} fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                            }
                            <Typography variant="h3" style={{ width: '100%' }} component="h3">{translate('dynatest.resources.puentes.subTitle.localizacion')}</Typography>
                            <span></span>
                            <span></span>
                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <ReferenceField allowEmpty emptyValue="" label="resources.puentes.fields.unidad_funcional" source="unidad_funcional" reference="unidad_funcionals" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <TextField source="fullCodigos" />
                            </ReferenceField>
                            <ReferenceField allowEmpty emptyValue="" label="resources.puentes.fields.tramo" source="tramo" reference="tramos" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <TextField source="fullCodigos" />
                            </ReferenceField>
                            <ReferenceField allowEmpty emptyValue="" label="resources.puentes.fields.segmento" source="segmento" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont3}>
                                <TextField source="fullCodigos" />
                            </ReferenceField>
                            <PRField label="resources.puentes.fields.ingresoAbFin" id="ingresoAbFin" source="abInicio" fullWidth formClassName={classes.grid_cont4} />
                            <PRField label="resources.puentes.fields.ingresoAbFin" id="ingresoAbFin" source="abFin" fullWidth formClassName={classes.grid_cont4} />
                            <br/>
                            <PField label="resources.puentes.fields.pInicio" source="pInicio" fullWidth formClassName={classes.grid_cont4} />
                            <PField label="resources.puentes.fields.pFin" source="pFin" fullWidth formClassName={classes.grid_cont4} />
                            {/* <PField label="PR Inicio" source="pInicio" />
                        <PField label="PR Fin"  source="pFin" /> */}
                            <Typography variant="b" component="b">{translate('dynatest.resources.puentes.subTitle.coordenadasIniciales')}</Typography>
                            <span></span>
                            <span></span>
                            <Divider fullWidth style={{ width: '100%', marginTop: '10px' }} />
                            
                            <NumberField label="resources.puentes.fields.latitud" source="latitudInicial" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4} />
                            <NumberField label="resources.puentes.fields.longitud" source="longitudInicial" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                            <br/>
                            <Typography variant="b" component="b">{translate('dynatest.resources.puentes.subTitle.coordenadasFinales')}</Typography>
                            <span></span>
                            <span></span>
                            <Divider fullWidth style={{ width: '100%', marginTop: '10px' }} />
                            <NumberField label="resources.puentes.fields.latitud" source="latitudFinal" fullWidth validate={Latitud} style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4} />
                            <NumberField label="resources.puentes.fields.longitud" source="longitudFinal" fullWidth validate={Longitud} style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                            <GMapField style={{ width: "100%" }}
                                defaultZoom={12}
                                defaultCenter={{ lat: latitud, lng: longitud }}
                                source="ubicacionMapa"
                                multipleMarkers="true"
                                googleKey="AIzaSyCivTXzJBYRBK8nosBYRFXnv-hGXIbhnYY"
                                icon={imagen}
                            />
                        </FormTab>
                        <FormTab label="dynatest.resources.puentes.tabs.tabsTitle.datosAdministrativos">
                            <br />
                            <Typography variant="h3" style={{ width: '100%' }} component="h3">{translate('dynatest.resources.puentes.subTitle.detallesConstructivos')}</Typography>
                            <span></span>
                            <span></span>
                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <DateField label="resources.puentes.fields.years" source="administrativos[0].years" fullWidth formClassName={classes.grid_cont3} />
                            <NumberField label="resources.puentes.fields.area" source="administrativos[0].area" id="area1" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                            <TextField label="resources.puentes.fields.norma_diseno" source="administrativos[0].norma_diseno" fullWidth formClassName={classes.grid_cont3} />
                            <TextField label="resources.puentes.fields.disenador" source="administrativos[0].disenador" fullWidth formClassName={classes.grid_cont3} />
                            <TextField label="resources.puentes.fields.info_di" source="administrativos[0].info_di" fullWidth formClassName={classes.grid_cont3} />
                            <br />
                            <Typography variant="h3" style={{ width: '100%' }} component="h3">{translate('dynatest.resources.puentes.subTitle.detRefuerzoRehabilit')}</Typography>
                            <span></span>
                            <span></span>
                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <DateField label="resources.puentes.fields.yearsb" source="administrativos[0].yearsb" fullWidth formClassName={classes.grid_cont3} />
                            <NumberField label="resources.puentes.fields.areab" source="administrativos[0].areab" fullWidth id="area2" style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                            <TextField label="resources.puentes.fields.norma_disenob" source="administrativos[0].norma_disenob" fullWidth formClassName={classes.grid_cont3} />
                            <TextField label="resources.puentes.fields.disenadorb" source="administrativos[0].disenadorb" fullWidth formClassName={classes.grid_cont3} />
                            <TextField label="resources.puentes.fields.info_dib" source="administrativos[0].info_dib" fullWidth formClassName={classes.grid_cont3} />
                            <br />
                            <Typography variant="h3" style={{ width: '100%' }} component="h3">{translate('dynatest.resources.puentes.subTitle.detallesInspeccion')}</Typography>
                            <span></span>
                            <span></span>
                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <DateField label="resources.puentes.fields.fecha" source="administrativos[0].fecha" fullWidth formClassName={classes.grid_cont4s} />
                            <TextField label="resources.puentes.fields.inspector" source="administrativos[0].inspector" fullWidth formClassName={classes.grid_cont4} />
                            <NumberField label="resources.puentes.fields.inspeccion" source="administrativos[0].inspeccion" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4} />
                            <TextField label="resources.puentes.fields.direccion_abscisado" source="administrativos[0].direccion_abscisado" allowEmpty emptyValue="" fullWidth formClassName={(controllerProps.record && controllerProps.record.elementos && controllerProps.record.elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont4s} />
                            <ReferenceField allowEmpty emptyValue="" label="resources.puentes.fields.estacion_conteo" source="administrativos[0].estacion_conteo" linkType={false} reference="estacion_conteos" fullWidth formClassName={(controllerProps.record && controllerProps.record.elementos && controllerProps.record.elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont4}>
                                <TextField source="nombre" />
                            </ReferenceField>
                            <NumberField label="resources.puentes.fields.numinspeccion" source="administrativos[0].numinspeccion" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={(controllerProps.record && controllerProps.record.elementos && controllerProps.record.elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont4} />

                            <Typography variant="h3" style={{ width: '100%' }} component="h3">{translate('dynatest.resources.puentes.subTitle.detallesInspeccion')}</Typography>
                            <span></span>
                            <span></span>
                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <TextField label="resources.puentes.fields.propietario" source="administrativos[0].propietario" fullWidth formClassName={classes.grid_cont3} />
                            <TextField label="resources.puentes.fields.admon_vial" source="administrativos[0].admon_vial" fullWidth formClassName={classes.grid_cont3} />
                            <TextField label="resources.puentes.fields.proyectista" source="administrativos[0].proyectista" fullWidth formClassName={classes.grid_cont3} />
                            <Typography formClassName={(controllerProps.record && controllerProps.record.elementos && controllerProps.record.elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : ''} variant="h3" style={{ width: '100%' }} component="h3">{translate('dynatest.resources.puentes.subTitle.caractOperacionales')}</Typography>
                            <span></span>
                            <span></span>
                            <Divider fullWidth formClassName={(controllerProps.record && controllerProps.record.elementos && controllerProps.record.elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont12} />
                            <NumberField label="resources.puentes.fields.coe_sismo" source="administrativos[0].coe_sismo" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={(controllerProps.record && controllerProps.record.elementos && controllerProps.record.elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont4s} />
                            <TextField label="resources.puentes.fields.paso_cauce" source="administrativos[0].paso_cauce" fullWidth formClassName={(controllerProps.record && controllerProps.record.elementos && controllerProps.record.elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont4} />
                            <TextField label="resources.puentes.fields.estado_op" source="administrativos[0].estado_op" fullWidth formClassName={(controllerProps.record && controllerProps.record.elementos && controllerProps.record.elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont4} />
                            <TextField label="resources.puentes.fields.exis_variante" source="administrativos[0].exis_variante" fullWidth formClassName={(controllerProps.record && controllerProps.record.elementos && controllerProps.record.elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont4} />
                            {controllerProps.record && controllerProps.record.administrativos && controllerProps.record.administrativos[0].exis_variante === translate('dynatest.resources.puentes.select.opcion.si') &&
                                <NumberField label="resources.puentes.fields.long_km" source="administrativos[0].long_km" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={(controllerProps.record && controllerProps.record.elementos && controllerProps.record.elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont4} />
                            }

                            <Typography formClassName={(controllerProps.record && controllerProps.record.elementos && controllerProps.record.elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : ''} variant="h3" style={{ width: '100%' }} component="h3">{translate('dynatest.resources.puentes.subTitle.capacidadCargaTransitoLegal')}</Typography>
                            <span></span>
                            <span></span>
                            <Divider fullWidth formClassName={(controllerProps.record && controllerProps.record.elementos && controllerProps.record.elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont12} />
                            <TextField label="resources.puentes.fields.vehi_di" source="administrativos[0].vehi_di" fullWidth formClassName={(controllerProps.record && controllerProps.record.elementos && controllerProps.record.elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont3} />
                            <TextField label="resources.puentes.fields.distri_carga" source="administrativos[0].distri_carga" fullWidth formClassName={(controllerProps.record && controllerProps.record.elementos && controllerProps.record.elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont3} />
                            <NumberField label="resources.puentes.fields.capacidad" source="administrativos[0].capacidad" fullWidth id="area3" style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={(controllerProps.record && controllerProps.record.elementos && controllerProps.record.elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont3} />
                            <NumberField label="resources.puentes.fields.luz_critica" source="administrativos[0].luz_critica" fullWidth id="area4" style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={(controllerProps.record && controllerProps.record.elementos && controllerProps.record.elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont3} />
                            <NumberField label="resources.puentes.fields.fac_clas" source="administrativos[0].fac_clas" fullWidth id="area5" formClassName={(controllerProps.record && controllerProps.record.elementos && controllerProps.record.elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont3} />
                            <br />
                            <Typography formClassName={(controllerProps.record && controllerProps.record.elementos && controllerProps.record.elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : ''} variant="h3" style={{ width: '100%' }} component="h3">{translate('dynatest.resources.puentes.subTitle.capacidadCargaTranspEspeciales')}</Typography>
                            <span></span>
                            <span></span>
                            <Divider fullWidth formClassName={(controllerProps.record && controllerProps.record.elementos && controllerProps.record.elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont12} />
                            <TextField label="resources.puentes.fields.fac_cort" source="administrativos[0].fac_cort" fullWidth id="area6" formClassName={(controllerProps.record && controllerProps.record.elementos && controllerProps.record.elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont3} />
                            <TextField label="resources.puentes.fields.momento" source="administrativos[0].momento" fullWidth id="area7" formClassName={(controllerProps.record && controllerProps.record.elementos && controllerProps.record.elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont3} />
                            <TextField label="resources.puentes.fields.car_rue" source="administrativos[0].car_rue" fullWidth id="area8" formClassName={(controllerProps.record && controllerProps.record.elementos && controllerProps.record.elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont3} />
                            <TextField label="resources.puentes.fields.observacioness" source="administrativos[0].observacioness" fullWidth formClassName={(controllerProps.record && controllerProps.record.elementos && controllerProps.record.elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont_div} />
                        </FormTab>
                        <FormTab label="dynatest.resources.puentes.tabs.tabsTitle.geometria">
                            <br />
                            <Typography variant="h3" style={{ width: '100%' }} component="h3">{translate('dynatest.resources.puentes.subTitle.general')}</Typography>
                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <TextField label="resources.puentes.fields.terraplen" source="geometria[0].terraplen" allowEmpty emptyValue="" fullWidth formClassName={(controllerProps.record && controllerProps.record.elementos && controllerProps.record.elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont4} />
                            <TextField label="resources.puentes.fields.curva" source="geometria[0].curva" allowEmpty emptyValue="" fullWidth formClassName={classes.grid_cont4} />
                            <NumberField label="resources.puentes.fields.esviajamiento" source="geometria[0].esviajamiento" allowEmpty emptyValue="" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                            <Typography variant="h3" style={{ width: '100%' }} component="h3">{translate('dynatest.resources.puentes.subTitle.estructura')}</Typography>
                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <NumberField label="resources.puentes.fields.luces" fullWidth source="geometria[0].luces" allowEmpty style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} emptyValue="" formClassName={classes.grid_cont4} />
                            <br />
                            <NumberField label="resources.puentes.fields.luz_menor" source="geometria[0].luz_menor" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} allowEmpty emptyValue="" formClassName={classes.grid_cont4} />
                            <NumberField label="resources.puentes.fields.luz_mayor" source="geometria[0].luz_mayor" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} allowEmpty emptyValue="" formClassName={classes.grid_cont4} />
                            <NumberField label="resources.puentes.fields.long_total" source="geometria[0].long_total" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} allowEmpty emptyValue="" formClassName={classes.grid_cont3} />
                            <NumberField label="resources.puentes.fields.ancho_tab" source="geometria[0].ancho_tab" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} allowEmpty emptyValue="" formClassName={classes.grid_cont4} />
                            <NumberField label="resources.puentes.fields.area_tab" source="geometria[0].area_tab" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4} />
                            <NumberField label="resources.puentes.fields.ancho_sep" source="geometria[0].ancho_sep" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} allowEmpty emptyValue="" formClassName={(controllerProps.record && controllerProps.record.elementos && controllerProps.record.elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont3} />
                            <NumberField label="resources.puentes.fields.ancho_anden_izq" source="geometria[0].ancho_anden_izq" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} allowEmpty emptyValue="" formClassName={(controllerProps.record && controllerProps.record.elementos && controllerProps.record.elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont4} />
                            <NumberField label="resources.puentes.fields.ancho_anden_der" source="geometria[0].ancho_anden_der" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} allowEmpty emptyValue="" formClassName={(controllerProps.record && controllerProps.record.elementos && controllerProps.record.elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont4} />
                            <NumberField label="resources.puentes.fields.ancho_calzada" source="geometria[0].ancho_calzada" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} allowEmpty emptyValue="" formClassName={(controllerProps.record && controllerProps.record.elementos && controllerProps.record.elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont3} />
                            <NumberField label="resources.puentes.fields.ancho_bordillo" source="geometria[0].ancho_bordillo" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} allowEmpty emptyValue="" formClassName={(controllerProps.record && controllerProps.record.elementos && controllerProps.record.elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont4} />
                            <br />
                            <Typography variant="h3" style={{ width: '100%' }} component="h3">{translate('dynatest.resources.puentes.subTitle.subestructura')}</Typography>
                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <NumberField label="resources.puentes.fields.alt_pilas" source="geometria[0].alt_pilas" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} allowEmpty emptyValue="" formClassName={classes.grid_cont4} />
                            <NumberField label="resources.puentes.fields.long_pilas" source="geometria[0].long_pilas" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} allowEmpty emptyValue="" formClassName={classes.grid_cont4} />
                            <NumberField label="resources.puentes.fields.alt_estribos" source="geometria[0].alt_estribos" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} allowEmpty emptyValue="" formClassName={classes.grid_cont4} />
                            <NumberField label="resources.puentes.fields.long_estribos" source="geometria[0].long_estribos" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} allowEmpty emptyValue="" formClassName={classes.grid_cont4} />

                        </FormTab>
                        <FormTab label="dynatest.resources.puentes.tabs.tabsTitle.estructura">

                            <br />
                            <Typography variant="h3" component="h3">{translate('dynatest.resources.puentes.subTitle.Principal')}</Typography>
                            <span></span><span></span>
                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <TextField label="resources.puentes.fields.dise_tipo" source="superestructura[0].dise_tipo" formClassName={classes.grid_cont4} />
                            {controllerProps.record && controllerProps.record.superestructura && controllerProps.record.superestructura[0] && controllerProps.record.superestructura[0].material && controllerProps.record.superestructura[0].dise_tipo === translate('dynatest.resources.puentes.select.opcion.si') &&
                                <TextField label="resources.puentes.fields.material" source="superestructura[0].material" allowEmpty emptyValue="" fullWidth formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.superestructura && controllerProps.record.superestructura[0] && controllerProps.record.superestructura[0].material && controllerProps.record.superestructura[0].dise_tipo === translate('dynatest.resources.puentes.select.opcion.si') &&
                                <ReferenceField allowEmpty emptyValue="" label="resources.puentes.fields.transversarlCod" source="superestructura[0].transversarl" reference="transversarls" linkType={false} formClassName={classes.grid_cont4} >
                                    <TextField source="ids" />
                                </ReferenceField>
                            }
                            {controllerProps.record && controllerProps.record.superestructura && controllerProps.record.superestructura[0] && controllerProps.record.superestructura[0].material && controllerProps.record.superestructura[0].dise_tipo === translate('dynatest.resources.puentes.select.opcion.si') &&
                                <ReferenceField allowEmpty emptyValue="" label="resources.puentes.fields.transversarl" source="superestructura[0].transversarl" linkType={false} reference="transversarls" formClassName={classes.grid_cont4} fullWidth>
                                    <TextField source="descripcion" />
                                </ReferenceField>
                            }
                            {controllerProps.record && controllerProps.record.superestructura && controllerProps.record.superestructura[0] && controllerProps.record.superestructura[0].material && controllerProps.record.superestructura[0].dise_tipo === translate('dynatest.resources.puentes.select.opcion.si') &&
                                <ReferenceField allowEmpty emptyValue="" label="resources.puentes.fields.longitudCod" source="superestructura[0].longitud" reference="longituds" disabled linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                    <TextField source="ids" />
                                </ReferenceField>
                            }
                            {controllerProps.record && controllerProps.record.superestructura && controllerProps.record.superestructura[0] && controllerProps.record.superestructura[0].material && controllerProps.record.superestructura[0].dise_tipo === translate('dynatest.resources.puentes.select.opcion.si') &&
                                <ReferenceField allowEmpty emptyValue="" label="resources.puentes.fields.longitudinal" source="superestructura[0].longitud" reference="longituds" disabled linkType={false} formClassName={classes.grid_cont4}>
                                    <TextField source="decripcion" />
                                </ReferenceField>
                            }
                            {controllerProps.record && controllerProps.record.dise_tipo !== translate('dynatest.resources.puentes.select.opcion.si') &&
                                <div></div>
                            }
 
                            <Typography variant="h3" component="h3">{translate('dynatest.resources.puentes.subTitle.Secundario')}</Typography>
                            <span></span><span></span>
                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <TextField label="resources.puentes.fields.dise_tipo" source="superestructura[0].dise_tipoa" emptyValue="" linkType={false} fullWidth formClassName={classes.grid_cont4} />

                            {controllerProps.record && controllerProps.record.superestructura && controllerProps.record.superestructura[0] && controllerProps.record.superestructura[0].material && controllerProps.record.superestructura[0].dise_tipoa === translate('dynatest.resources.puentes.select.opcion.si') &&
                                <TextField label="resources.puentes.fields.material" source="superestructura[0].materiala" allowEmpty emptyValue="" linkType={false} fullWidth formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.superestructura && controllerProps.record.superestructura[0] && controllerProps.record.superestructura[0].material && controllerProps.record.superestructura[0].dise_tipoa === translate('dynatest.resources.puentes.select.opcion.si') &&
                                <ReferenceField allowEmpty emptyValue="" label="resources.puentes.fields.transversarlCod" source="superestructura[0].transversarla" reference="transversarlas" linkType={false} disabled fullWidth formClassName={classes.grid_cont4}>
                                    <TextField source="ids" />
                                </ReferenceField>
                            }
                            {controllerProps.record && controllerProps.record.superestructura && controllerProps.record.superestructura[0] && controllerProps.record.superestructura[0].material && controllerProps.record.superestructura[0].dise_tipoa === translate('dynatest.resources.puentes.select.opcion.si') &&
                                <ReferenceField allowEmpty emptyValue="" label="resources.puentes.fields.transversarl" source="superestructura[0].transversarla" reference="transversarlas" linkType={false} disabled fullWidth formClassName={classes.grid_cont4}>
                                    <TextField source="descripcion" />
                                </ReferenceField>
                            }
                            {controllerProps.record && controllerProps.record.superestructura && controllerProps.record.superestructura[0] && controllerProps.record.superestructura[0].material && controllerProps.record.superestructura[0].dise_tipoa === translate('dynatest.resources.puentes.select.opcion.si') &&
                                <ReferenceField allowEmpty emptyValue="" label="resources.puentes.fields.longitudCod" source="superestructura[0].longa" reference="longas" disabled linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                    <TextField source="ids" />
                                </ReferenceField>
                            }
                            {controllerProps.record && controllerProps.record.superestructura && controllerProps.record.superestructura[0] && controllerProps.record.superestructura[0].material && controllerProps.record.superestructura[0].dise_tipoa === translate('dynatest.resources.puentes.select.opcion.si') &&
                                <ReferenceField allowEmpty emptyValue="" label="resources.puentes.fields.longitudinal" source="superestructura[0].longa" reference="longas" linkType={false} disabled fullWidth formClassName={classes.grid_cont4}>
                                    <TextField source="descripcion" />
                                </ReferenceField>
                            }
                            <Typography variant="h3" component="h3">{translate('dynatest.resources.puentes.subTitle.Senales')}</Typography>
                            <span></span><span></span>
                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <TextField label="resources.puentes.fields.car_max" source="superestructura[0].car_max" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={(controllerProps.record && controllerProps.record.elementos && controllerProps.record.elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont4} />

                            <NumberField label="resources.puentes.fields.velo_max" source="superestructura[0].velo_max" style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} fullWidth formClassName={(controllerProps.record && controllerProps.record.elementos && controllerProps.record.elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont4} />

                            <TextField label="resources.puentes.fields.galibo_maximo" source="superestructura[0].galibo_maximo" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={(controllerProps.record && controllerProps.record.elementos && controllerProps.record.elementos == '/tipo_elementos/PNTEP') ? classes.grid_cont3 : classes.ocultar} />

                            <TextField label="resources.puentes.fields.otra" source="superestructura[0].otra" fullWidth formClassName={classes.grid_cont3} />

                            <Typography variant="h3" component="h3">{translate('dynatest.resources.puentes.subTitle.Detalles')}</Typography>
                            <span></span><span></span>
                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <ReferenceField allowEmpty emptyValue="" label="resources.puentes.fields.barandaCod" source="superestructura[0].baranda" reference="barandas" disabled linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <TextField source="ides" />
                            </ReferenceField>

                            <ReferenceField allowEmpty emptyValue="" label="resources.puentes.fields.baranda" source="superestructura[0].baranda" reference="barandas" disabled linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <TextField source="descripcion" />
                            </ReferenceField>

                            <ReferenceField allowEmpty emptyValue="" label="resources.puentes.fields.expantionCod" source="superestructura[0].expantion" reference="expantions" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <TextField source="ids" />
                            </ReferenceField>

                            <ReferenceField allowEmpty emptyValue="" label="resources.puentes.fields.expantion" source="superestructura[0].expantion" reference="expantions" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <TextField source="descripcion" />
                            </ReferenceField>

                            {controllerProps.record && controllerProps.record.elementos && controllerProps.record.elementos == '/tipo_elementos/PNTEP' &&
                                <TextField label="resources.puentes.fields.proteccion_alta_tension" source="superestructura[0].proteccion_alta_tension" emptyValue="" linkType={false} fullWidth formClassName={classes.grid_cont4} />
                            }

                            {controllerProps.record && controllerProps.record.elementos && controllerProps.record.elementos == '/tipo_elementos/PNTEP' &&
                                <TextField label="resources.puentes.fields.tipo_acceso" source="superestructura[0].tipo_acceso" emptyValue="" linkType={false} fullWidth formClassName={classes.grid_cont4} /> 
                            }
                            
                            {controllerProps.record && controllerProps.record.superestructura && controllerProps.record.superestructura[0] && controllerProps.record.superestructura[0].tipo_acceso && controllerProps.record.superestructura[0].tipo_acceso == translate('dynatest.resources.puentes.select.tipo_acceso.Escalera') &&
                                <TextField label="resources.puentes.fields.huella" source="superestructura[0].huella" allowEmpty emptyValue="" linkType={false} fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4} />
                            }

                            {controllerProps.record && controllerProps.record.superestructura && controllerProps.record.superestructura[0] && controllerProps.record.superestructura[0].tipo_acceso && controllerProps.record.superestructura[0].tipo_acceso == translate('dynatest.resources.puentes.select.tipo_acceso.Escalera') &&
                                <TextField label="resources.puentes.fields.contrahuella" source="superestructura[0].contrahuella" allowEmpty emptyValue="" linkType={false} fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.superestructura && controllerProps.record.superestructura[0] && controllerProps.record.superestructura[0].tipo_acceso && controllerProps.record.superestructura[0].tipo_acceso == translate('dynatest.resources.puentes.select.tipo_acceso.Rampa') &&
                                <TextField label="resources.puentes.fields.logitud_remapa" source="superestructura[0].logitud_remapa" allowEmpty emptyValue="" linkType={false} fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.superestructura && controllerProps.record.superestructura[0] && controllerProps.record.superestructura[0].tipo_acceso && controllerProps.record.superestructura[0].tipo_acceso == translate('dynatest.resources.puentes.select.tipo_acceso.Rampa') &&
                                <TextField label="resources.puentes.fields.pendiente" source="superestructura[0].pendiente" allowEmpty emptyValue="" linkType={false} fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.superestructura && controllerProps.record.superestructura[0] && controllerProps.record.superestructura[0].tipo_acceso &&
                                <TextField label="resources.puentes.fields.nro_tramos" source="superestructura[0].nro_tramos" allowEmpty emptyValue="" linkType={false} fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                            }

                        </FormTab>

                        {controllerProps.record && controllerProps.record.elementos && controllerProps.record.elementos == '/tipo_elementos/PNTEP' &&
                            <FormTab label="dynatest.resources.puentes.tabs.tabsTitle.subestructura">
                                <Typography variant="h3" component="h3">{translate('dynatest.resources.puentes.messagesVal.ElementoNoRequiereInfo')}</Typography>
                            </FormTab>
                        }

                        {controllerProps.record && controllerProps.record.elementos && controllerProps.record.elementos != '/tipo_elementos/PNTEP' &&
                            <FormTab label="dynatest.resources.puentes.tabs.tabsTitle.subestructura"> 
                                <Typography variant="h3" component="h3">{translate('dynatest.resources.puentes.subTitle.Estribos')}</Typography>
                                <span></span><span></span>
                                <Divider fullWidth formClassName={classes.grid_cont12} />
                                <ReferenceField allowEmpty emptyValue="" label="resources.puentes.fields.tipoestribos" source="subestructura[0].tipoestribos" reference="tipoestribos" fullWiidth linkType={false} formClassName={classes.grid_cont3s} >
                                    <TextField source="descripcion" />
                                </ReferenceField>
                                <ReferenceField allowEmpty emptyValue="" label="resources.puentes.fields.estriCod" source="subestructura[0].estri" reference="estris" fullWidth linkType={false} formClassName={classes.grid_cont3}>
                                    <TextField source="ids" disabled emptyValue="" />
                                </ReferenceField>
                                <ReferenceField allowEmpty emptyValue="" label="resources.puentes.fields.estriMat" source="subestructura[0].estri" reference="estris" fullWidth linkType={false} formClassName={classes.grid_cont3} >
                                    <TextField source="descripcion" fullWidth />
                                </ReferenceField>
                                <SelectField label="resources.puentes.fields.tipo_cimen" source="subestructura[0].tipo_cimen" allowEmpty emptyValue="" fullWidth choices={[
                                    { id: '01 ', name: ' Profunda ' },
                                    { id: '02 ', name: ' Superficial ' },
                                ]} optionText="name" linkType={false} formClassName={classes.grid_cont3} />
                                <br></br><br></br>
                                <Typography variant="h3" style={{ width: '100%' }} component="h3">{translate('dynatest.resources.puentes.subTitle.Pilas')}</Typography>
                                <span></span><span></span>
                                <Divider fullWidth formClassName={classes.grid_cont12} />
                                <ReferenceField allowEmpty emptyValue="" label="resources.puentes.fields.tippilasCod" source="subestructura[0].tippilas" reference="tippilas" disabled fullWidth linkType={false} formClassName={classes.grid_cont3s}>
                                    <TextField source="ids" />
                                </ReferenceField>
                                <ReferenceField allowEmpty emptyValue="" label="resources.puentes.fields.tippilas" source="subestructura[0].tippilas" reference="tippilas" fullWidth linkType={false} formClassName={classes.grid_cont3}>
                                    <TextField source="descripcion" fullWidth />
                                </ReferenceField>
                                <TextField label="resources.puentes.fields.forma_pilaCod" source="subestructura[0].forma_pila" optionText="full_name" optionValue="_id" allowEmpty emptyValue="" disabled fullWidth choices={choices1} linkType={false} formClassName={classes.grid_cont3} />
                                <SelectField label="resources.puentes.fields.forma_pila" source="subestructura[0].forma_pila" fullWidth choices={choices} optionText="full_name" optionValue="_id" linkType={false} formClassName={classes.grid_cont3} />
                                <SelectField label="resources.puentes.fields.cimen_pila" source="subestructura[0].cimen_pila" fullWidth choices={[
                                    { id: '01 ', name: ' Profunda ' },
                                    { id: '02 ', name: ' Superficial ' },
                                ]} optionText="name" linkType={false} formClassName={classes.grid_cont3s} />
                                <ReferenceField allowEmpty emptyValue="" label="resources.puentes.fields.estrisaCod" source="subestructura[0].estrisa" reference="estris" disabled fullWidth linkType={false} formClassName={classes.grid_cont3}>
                                    <TextField source="ids" />
                                </ReferenceField>
                                <ReferenceField allowEmpty emptyValue="" label="resources.puentes.fields.estriMat" source="subestructura[0].estrisa" reference="estris" fullWidth linkType={false} formClassName={classes.grid_cont3}>
                                    <TextField source="descripcion" />
                                </ReferenceField>
                                <br></br><br></br>
                                <Typography variant="h3" component="h3">{translate('dynatest.resources.puentes.subTitle.Apoyos')}</Typography>
                                <span></span><span></span>
                                <Divider fullWidth formClassName={classes.grid_cont12} />
                                <TextField label="resources.puentes.fields.apoyo_fijoCod" source="subestructura[0].apoyo_fijo" disabled allowEmpty emptyValue="" fullWidth choices={choises2} formClassName={classes.grid_cont4} />
                                <SelectField label="resources.puentes.fields.apoyo_fijo" source="subestructura[0].apoyo_fijo" allowEmpty emptyValue="" fullWidth choices={choises3} optionText="name" formClassName={classes.grid_cont4} />
                                <TextField label="resources.puentes.fields.pila_movilCod" source="subestructura[0].pila_movil" disabled allowEmpty emptyValue="" fullWidth choices={choises2} formClassName={classes.grid_cont4} />
                                <SelectField label="resources.puentes.fields.pila_movil" source="subestructura[0].pila_movil" allowEmpty emptyValue="" fullWidth choices={choises3} optionText="name" formClassName={classes.grid_cont4} />
                                <TextField label="resources.puentes.fields.pila_pilaCod" source="subestructura[0].pila_pila" disabled allowEmpty emptyValue="" fullWidth choices={choises2} formClassName={classes.grid_cont4} />
                                <SelectField label="resources.puentes.fields.pila_pila" source="subestructura[0].pila_pila" allowEmpty emptyValue="" fullWidth choices={choises3} optionText="name" formClassName={classes.grid_cont4} />
                                <TextField label="resources.puentes.fields.apoyo_movilCod" source="subestructura[0].apoyo_movil" disabled allowEmpty emptyValue="" fullWidth choices={choises2} formClassName={classes.grid_cont4} />
                                <SelectField label="resources.puentes.fields.apoyo_movil" source="subestructura[0].apoyo_movil" allowEmpty emptyValue="" fullWidth choices={choises3} optionText="name" formClassName={classes.grid_cont4} />
                                <TextField label="resources.puentes.fields.pila_vigasCod" source="subestructura[0].pila_vigas" disabled allowEmpty emptyValue="" fullWidth choices={choises2} formClassName={classes.grid_cont4} />
                                <SelectField label="resources.puentes.fields.pila_vigas" source="subestructura[0].pila_vigas" allowEmpty emptyValue="" fullWidth choices={choises3} optionText="name" formClassName={classes.grid_cont4} />
                                <TextField label="resources.puentes.fields.movil_vigasCod" source="subestructura[0].movil_vigas" disabled allowEmpty emptyValue="" fullWidth choices={choises2} formClassName={classes.grid_cont4} />
                                <SelectField label="resources.puentes.fields.movil_vigas" source="subestructura[0].movil_vigas" allowEmpty emptyValue="" fullWidth choices={choises3} optionText="name" formClassName={classes.grid_cont4} />
                            </FormTab>
                        }
                        <FormTab label="dynatest.resources.puentes.tabs.tabsTitle.reparaciones">

                            <WithProps>{({ record, ...props }) =>
                                <Reparaciones record={record} />}
                            </WithProps>
                        </FormTab>
                        <FormTab label="dynatest.resources.puentes.tabs.tabsTitle.detalles">
                            <TextField label="dynatest.resources.puentes.tabs.tabsTitle.detalles" source="detalles[0].estado" fullWidth formClassName={classes.grid_cont4} />
                            {controllerProps.record && controllerProps.record.detalles && controllerProps.record.detalles[0].estado === translate('dynatest.resources.puentes.select.estado.Reemplazo') &&
                                <TextField label="resources.puentes.fields.mot_remplazo" source="detalles[0].mot_remplazo" linkType={false} fullWidth formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.detalles && controllerProps.record.detalles[0].estado === translate('dynatest.resources.puentes.select.estado.Reemplazo') &&
                                <ReferenceField allowEmpty emptyValue="" label="resources.puentes.fields.elemnto_remplazo" source="detalles[0].elemnto_remplazo" reference="puentes" linkType={false} fullWidth formClassName={classes.grid_cont3} >
                                    <TextField source="fullCodigoConsecutivo" />
                                </ReferenceField>
                            }
                            <br />
                            <TextField label="resources.puentes.fields.estados" source="detalles[0].estados" linkType={false} fullWidth formClassName={classes.grid_cont4} />
                            <br />
                            <DateField label="resources.puentes.fields.fecha" source="detalles[0].fecha" fullWidth formClassName={classes.grid_cont4} />
                            <ReferenceField allowEmpty emptyValue="" label="resources.puentes.fields.proveedor" source="detalles[0].proveedor" reference="proveedors" linkType={false} filter={{ sistema: 'PTS' }} fullWidth formClassName={classes.grid_cont4} >
                                <TextField source="razonSocial" />
                            </ReferenceField>
                            <NumberField label="resources.puentes.fields.garantia" source="detalles[0].garantia" reference="detalless" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                            <BooleanField label="dynatest.resources.puentes.boolean.mantenimiento" source="detalles[0].mantenimiento" />
                            <TextField label="resources.puentes.fields.observacionesDetalles" source="detalles[0].observaciones" fullWidth formClassName={classes.grid_cont12} />
                        </FormTab>
                        <FormTab label="dynatest.resources.puentes.tabs.tabsTitle.auditoria">
                            <DateField source="createdAt" label="resources.puentes.fields.createdAt" formClassName={classes.grid_cont4} showTime/>
                            <TextField source="createdBy" label="resources.puentes.fields.createdBy" formClassName={classes.grid_cont4} />
                            <TextField source="createdIn" label="resources.puentes.fields.createdEn" fullWidth formClassName={classes.grid_cont3} />
                            <br />
                            <DateField source="updatedAt" label="resources.puentes.fields.updatedAt" formClassName={classes.grid_cont4} showTime/>
                            <TextField source="updatedBy" label="resources.puentes.fields.updatedBy" formClassName={classes.grid_cont4} />
                            <TextField source="updatedIn" label="resources.puentes.fields.updatedEn" fullWidth formClassName={classes.grid_cont3} />
                        </FormTab>

                    </TabbedForm>
                </ShowView>
            }
        </ShowController>

    )
}
));
const WithProps = ({ children, ...props }) => children(props);