import React, {Fragment}from 'react';
import { Filter,SelectInput,ReferenceInput,SelectField,List,NumberField,ReferenceField,Datagrid, EditButton, ShowButton,TextField } from 'react-admin';
import {  BulkDeleteWithConfirmButton, Loading  } from 'react-admin';
import { PRField } from './../../../fields/PRField';
import { translate } from 'react-admin';
import { CardActions, CreateButton, ExportButton } from 'react-admin';
const MensualFilter = translate((props) => (
    <Filter {...props}> 
        <ReferenceInput label="resources.mensuals.fields.inventario_peajes" source="inventario_peajes" id="peajes_id" reference="inventariopeajes" linkType="show" alwaysOn>                
            <SelectInput optionText="nombre" />                
        </ReferenceInput>
        <SelectInput label="resources.mensuals.fields.ano" source="ano"  choices={[
            { id: '2000', name: '2000' },
            { id: '2001', name: '2001' },
            { id: '2002', name: '2002' },
            { id: '2003', name: '2003' },
            { id: '2004', name: '2004' },
            { id: '2005', name: '2005' },
            { id: '2006', name: '2006' },
            { id: '2007', name: '2007' },
            { id: '2008', name: '2008' },
            { id: '2009', name: '2009' },
            { id: '2010', name: '2010' },
            { id: '2011', name: '2011' },
            { id: '2012', name: '2012' },
            { id: '2013', name: '2013' },
            { id: '2014', name: '2014' },
            { id: '2015', name: '2015' },
            { id: '2016', name: '2016' },
            { id: '2017', name: '2017' },
            { id: '2018', name: '2018' },
            { id: '2019', name: '2019' },
            { id: '2020', name: '2020' },
            { id: '2021', name: '2021' },
            { id: '2022', name: '2022' },
            { id: '2023', name: '2023' },
            { id: '2024', name: '2024' },
            { id: '2025', name: '2025' },
            { id: '2026', name: '2026' },
            { id: '2027', name: '2027' },
            { id: '2028', name: '2028' },
            { id: '2029', name: '2029' },
            { id: '2030', name: '2030' }
        ]} alwaysOn/>
                 
        <SelectInput label="resources.mensuals.fields.mes" source="mes"   choices={[
            { id: '1', name: 'dynatest.resources.mensuals.select.mes.Enero' },
            { id: '2', name: 'dynatest.resources.mensuals.select.mes.Febrero' },
            { id: '3', name: 'dynatest.resources.mensuals.select.mes.Marzo' },
            { id: '4', name: 'dynatest.resources.mensuals.select.mes.Abril' },
            { id: '5', name: 'dynatest.resources.mensuals.select.mes.Mayo' },
            { id: '6', name: 'dynatest.resources.mensuals.select.mes.Junio' },
            { id: '7', name: 'dynatest.resources.mensuals.select.mes.Julio' },
            { id: '8', name: 'dynatest.resources.mensuals.select.mes.Agosto' },
            { id: '9', name: 'dynatest.resources.mensuals.select.mes.Septiembre' },
            { id: '91', name: 'dynatest.resources.mensuals.select.mes.Octubre' },
            { id: '92', name: 'dynatest.resources.mensuals.select.mes.Noviembre' },
            { id: '93', name: 'dynatest.resources.mensuals.select.mes.Diciembre' }                                    
        ]} alwaysOn/>
    </Filter>
));
const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);
const ListAction = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
        <ExportButton basePath={basePath}/>
    </CardActions>
);
const ListAction2 = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
    </CardActions>
);
export const Peajesmensualist = ({ permissions, ...props }) => {
    if (!permissions) return <Loading />;  
    return (
    <List title="dynatest.resources.mensuals.title.listTitle" 
        {...props} 
        sort={{ field: 'id', order: 'DESC' }} 
        filters={<MensualFilter/>} 
        actions={permissions['transitoMensual']  == 1 ? <ListAction /> : permissions['transitoMensual']  == 2 ? <ListAction2 />: null} 
        bulkActionButtons={permissions['transitoMensual']  == 1 ? <PostBulkActionButtons />: null}>
        <Datagrid >                
                <ReferenceField label="resources.mensuals.fields.inventario_peajes" source="inventario_peajes" reference="inventariopeajes" linkType={false}>
                        <TextField source="nombre" />
                    </ReferenceField>
                    <ReferenceField label="resources.mensuals.fields.codigo" source="inventario_peajes" reference="inventariopeajes" align="right" style={{textAlign:'right'}} linkType={false}>
                        <NumberField source="codigo" />
                    </ReferenceField>
                    <ReferenceField label="resources.mensuals.fields.Unidad_Funcional" source="inventario_peajes" reference="inventariopeajes" linkType={false}>                
                    <TextField source="unidad_funcional_.fullCodigos" />                
            </ReferenceField>
                    <ReferenceField label="resources.mensuals.fields.Abscisa" source="inventario_peajes" reference="inventariopeajes" linkType={false}>
                        <PRField source="abInicio" />
                    </ReferenceField>     
                    <TextField label="resources.mensuals.fields.sentido" source="sentido" />
                    <NumberField label="resources.mensuals.fields.TPM" source="tPM" />
                    <TextField label="resources.mensuals.fields.ano" source="ano" />   
                    <SelectField
                        label="resources.mensuals.fields.mes"
                        source="mes"
                        choices={[
                            { id: '1', name: 'Enero' },
                            { id: '2', name: 'Febrero' },
                            { id: '3', name: 'Marzo' },
                            { id: '4', name: 'Abril' },
                            { id: '5', name: 'Mayo' },
                            { id: '6', name: 'Junio' },
                            { id: '7', name: 'Julio' },
                            { id: '8', name: 'Agosto' },
                            { id: '9', name: 'Septiembre' },
                            { id: '91', name: 'Octubre' },
                            { id: '92', name: 'Noviembre' },
                            { id: '93', name: 'Diciembre' }                                    
                        ]}
                    />      
                    {/* <TextField label="Mes" source="mes" />             */}
                    {permissions['transitoMensual'] == 1 || permissions['transitoMensual'] == 2 || permissions['transitoMensual'] == 4 ? <ShowButton/>: null}
                    {permissions['transitoMensual'] == 1 || permissions['transitoMensual'] == 2 ? <EditButton/>: null} 
        </Datagrid>
    </List>
)};