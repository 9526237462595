import React , {useState} from 'react';
import {  TextInput, ReferenceInput, AutocompleteInput , Query, NumberInput} from 'react-admin';
import { Edit } from './../Edit';
import { PInput } from './../../inputs/PInput';
import { PRInput } from './../../inputs/PRInput';
import Grid from '@material-ui/core/Grid';
import { translate } from 'react-admin';
import {abValidationFin,abValidationInicio,prValidationFin,prValidationInicio,validarLogicaAbsFinEdit, 
        ValorMaximoAbsEditInicio, ValorMaximoAbsEditFin,  ValorMaximoPrEditInicio, ValorMaximoPrEditFin, 
        validarLogicaPrFinEdit
} from './../validacionesInputs'
import Divider from '@material-ui/core/Divider';
import { required, minLength, maxLength } from 'react-admin';  
import { withStyles } from '@material-ui/core';
import { SimpleForm } from './../SimpleForm';

import config from '../../config/config';
let urlAppjs = config.UrlServer;
  
const styles = {
    ocultar: {
        display:"none",
    },

};


export const TramoEdit= translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;
    const [unidad , setRutas]= useState('');

    const ValidarUnidadFuncional = (value)  => {      
        setRutas(value)         
    }
    const [prValidador, setPrValidador] = useState('');
    const [AbValidador, setAbValidador] = useState('');
    const [contador, setContador] =useState(0);
    const [separadore, setSeparador] =useState();
    const [idUnidade, setIdUnidad] =useState();
    const ValidarUnidades = (value)  => { 
        let urlUnidad = window.location.hash;
        setIdUnidad(urlUnidad.split("%2Ftramos%2F"))
        let lenghtObject = Object.keys(value);
        let valor = '';   
        for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                  
        setRutas(value) 
        let separador = value.split('/');
        setSeparador(separador[2]);     
    }
/*VALIDAR  QUE LAS UNIDADES F NO SE SOBREPISEN*/
    const NoSobreponerAbs = () =>{
            
        // unidad  id de la rita contra la que se valida
        // abInicio valor de la abscisa inicial ingresada
        // abFin valor de la abscisa final ingresada
        // tipoConsulta valida si es editar o crear 
        let IdnidadFuncional = unidad.substr(19);        
        let abInicioA = document.getElementById('ingresoAbInicio');
        let abFinA = document.getElementById('ingresoAbFin'); 
        if(abInicioA && abFinA)
        {
            abInicioA = abInicioA.value
            abFinA = abFinA.value
            abInicioA = abInicioA.substr(1, abInicioA.lastIndexOf('+')-1)+'.'+abInicioA.substr(abInicioA.lastIndexOf('+')+1,abInicioA.length);
            abFinA = abFinA.substr(1, abFinA.lastIndexOf('+')-1)+'.'+abFinA.substr(abFinA.lastIndexOf('+')+1,abFinA.length);
            fetch(urlAppjs+'/validar/rango/Tramos/abs/Editar/'+IdnidadFuncional+'/'+abInicioA +'/'+ abFinA+'/'+idUnidade[1])
            .then(function(response) {
                
                return response.text();
            })
            .then(function(myJson) {  
                setAbValidador(myJson)   
                // setContador(1)
                if(AbValidador === '1'){
            
                    return translate('resources.validaciones.rangoNoValido')
                }                
            })
        }        
        if(AbValidador === '1'){
        
            return translate('resources.validaciones.rangoNoValido')
        }
    }

    const MansajeValidadorAb = () =>{
        if(AbValidador === '1'){
        
            return translate('resources.validaciones.rangoNoValido')
        }
    }

    const NoSobreponerPr = () =>{
        // unidad  id de la rita contra la que se valida
        // abInicio valor de la abscisa inicial ingresada
        // abFin valor de la abscisa final ingresada
        // tipoConsulta valida si es editar o crear 
        let IdRuta = unidad.substr(19);      
        let abInicioA = document.getElementById('ingresoPrInicio');
        let abFinA = document.getElementById('ingresoPrFin'); 

        abInicioA = abInicioA.value
        abFinA = abFinA.value
        abInicioA = abInicioA.substr(2, abInicioA.lastIndexOf('+')-2)+'.'+abInicioA.substr(abInicioA.lastIndexOf('+')+1,abInicioA.length);
        abFinA = abFinA.substr(2, abFinA.lastIndexOf('+')-2)+'.'+abFinA.substr(abFinA.lastIndexOf('+')+1,abFinA.length);
        fetch(urlAppjs+'/validar/rango/Tramo/pr/Editar/'+IdRuta+'/'+abInicioA +'/'+ abFinA+'/'+idUnidade[1])
        .then(function(response) {
            
            return response.text();
        })
        .then(function(myJson) {  
            setPrValidador(myJson)   
            // setContador(1)
            if(AbValidador === '1'){
        
                return translate('resources.validaciones.rangoNoValido')
            }                
        })
        if(prValidador === '1'){
        
            return translate('resources.validaciones.rangoNoValido')
        }
    }

    const MansajeValidadorPb = () =>{
        if(prValidador === '1'){
        
            return 'Rango de Pr no válido'
        }
    }
/* FIN DE VALIDAR  QUE LAS UNIDADES F NO SE SOBREPISEN*/

    return(
    <Edit title="dynatest.resources.tramos.title.editTitle"  {...props}  >
        <SimpleForm submitOnEnter={false} redirect="list" >            
            <Grid container spacing={24}>
                <Grid item xs={6}>                    
                <ReferenceInput 
                    label="resources.tramos.fields.unidadFuncional"  
                    source="unidadFuncional"  
                    reference="unidad_funcionals" 
                    filterToQuery={searchText => ({ codigo: searchText })} 
                    sort={{field : 'codigo', order : 'asc'}}
                    validate={[required(),ValidarUnidadFuncional,ValidarUnidades]}
                    // onChange={}
                    fullWidth 
                    disabled
                >
                    <AutocompleteInput options={{fullWidth : true, disabled : true}} optionText="codigo"  />
                </ReferenceInput>
                </Grid>
                <TextInput label="Código" source="CODIGAAS"  id="codigo" style={{display : 'none'}} defaultValue={AbValidador}  fullWidth disabled />
                    <TextInput label="Código" source="PrValidador"  id="codigo" style={{display : 'none'}} defaultValue={prValidador} fullWidth disabled />
                <Grid item xs={6}>
                    <TextInput label="resources.tramos.fields.codigo" source="codigo" id="codigo" fullWidth disabled/>
                </Grid>            
                <Grid item xs={6}>
                    <PRInput label="resources.tramos.fields.abInicio" id="ingresoAbInicio" onChange={NoSobreponerAbs} source="abInicio" fullWidth validate={[abValidationInicio,required(), ValorMaximoAbsEditInicio, MansajeValidadorAb]}/>
                </Grid>
                <Grid item xs={6}>
                    <PRInput label="resources.tramos.fields.abFin" id="ingresoAbFin" onChange={NoSobreponerAbs} source="abFin" fullWidth validate={[abValidationFin,required(), validarLogicaAbsFinEdit, ValorMaximoAbsEditFin,MansajeValidadorAb]}/>
                </Grid>      
                <Grid item xs={6}>
                    <PInput label="resources.tramos.fields.pInicio" source="pInicio"  id="ingresoPrInicio" onChange={NoSobreponerPr} fullWidth validate={[prValidationInicio,required(),  ValorMaximoPrEditInicio, MansajeValidadorPb]}/>
                </Grid>         
                <Grid item xs={6}>
                    <PInput label="resources.tramos.fields.pFin" source="pFin"   id="ingresoPrFin" onChange={NoSobreponerPr} fullWidth validate={[prValidationFin,required(), ValorMaximoPrEditFin, validarLogicaPrFinEdit, MansajeValidadorPb]}/>
                </Grid>   
                
                {                                    
                    <Query type="GET_ONE" resource="unidad_funcionals" payload={{ id: unidad}} >
                        {({ data, loading, error }) => {                                                
                            if( data){
                                return (
                                    <Grid item xs={3}>
                                        <TextInput  id="IdprInicio"  style={{display : 'none'}} label="pr Inicio" defaultValue={data.PInicio} source="pInicios" fullWidth />
                                        <TextInput id="IdprFin"   style={{display : 'none'}}  label="PrFin" defaultValue={data.PFin} source="pFins" fullWidth />
                                        <TextInput  id="IdAbInicio"  style={{display : 'none'}} label="pr Inicio" defaultValue={data.abInicios} source="pAbI" fullWidth />
                                        <TextInput id="IdAbFin"   style={{display : 'none'}}  label="PrFin" defaultValue={data.abFins} source="pAbF" fullWidth />
                                    </Grid>
                                )
                            }
                            return <div></div>;                                                
                        }}
                    </Query>                            
                }
                <Grid item xs={12}>                    
                    <b>{translate('dynatest.resources.tramos.title.inforTramo')}</b>                    
                </Grid>
                <Grid item xs={12}>
                    <Divider />                    
                </Grid>
                <Grid item xs={6}>
                    <TextInput label="resources.tramos.fields.inicio" source="inicio"  fullWidth validate={[required(), minLength(2), maxLength(100)]}/>
                </Grid>
                <Grid item xs={6}>
                    <TextInput label="resources.tramos.fields.final" source="final"  fullWidth validate={[required(), minLength(2), maxLength(100)]}/>
                </Grid>       
            </Grid>
        </SimpleForm>
    </Edit>
)}));