import React, { Fragment, useState } from 'react';
import {
    NumberField, List, Datagrid, BooleanField, TextField, EditButton, ShowButton,
    AutocompleteInput, Filter, ReferenceInput, SelectInput, WithPermissions, BulkDeleteWithConfirmButton, CardActions,
    CreateButton, ExportButton, ReferenceArrayField, SingleFieldList, ChipField, Loading
} from 'react-admin';
import LinkToRuta from './LinkToRuta';
import { translate } from 'react-admin';

const ListAction = ({ basePath, data, resource }) => {
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = value => {
        setOpen(false);
    };
    return (
        <CardActions>
            <CreateButton basePath={basePath} />
            <LinkToRuta />
        </CardActions>
    );

};
const ListAction2 = ({ basePath, data, resource }) => {
    return (
        <CardActions>
            <CreateButton basePath={basePath} />
        </CardActions>
    );

};

const ProveedorFilter = (props) => {
    const [departamento, setDepartamento] = useState('-');
    const ValidarDepartamento = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setDepartamento(valor)
    }
    return (
        <Filter {...props}>
            <ReferenceInput
                label="resources.proveedors.fields.departamento"
                source="departamento"
                reference="departamentos"
                onChange={ValidarDepartamento}
                alwaysOn
                sort={{ field: "nombre", order: "ASC" }}
                filterToQuery={searchText => ({ nombre: searchText })}
            >
                <AutocompleteInput optionText="nombre" fullWidth />
            </ReferenceInput>

            <ReferenceInput
                label="resources.proveedors.fields.municipio"
                source="municipio"
                reference="municipios"
                alwaysOn
                sort={{ field: "nombre", order: "ASC" }}
                filterToQuery={searchText => ({ nombre: searchText })}
                filter={{ departamento: departamento }}
            >
                <AutocompleteInput optionText="nombre" fullWidth />
            </ReferenceInput>
            <ReferenceInput label="resources.proveedors.fields.sistema" source="sistema" reference="sistemas" sort={{ field: 'nombre', order: 'ASC' }} alwaysOn sortable={false} filter={{ visibleOnPolicies: 1 }} >
                <SelectInput optionText="nombre" />
            </ReferenceInput>


        </Filter>

    )
};

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props} />
    </Fragment>
);

export const ProveedorList = translate(({ permissions, ...props }) => {
    if (!permissions) return <Loading />;
    return (
        <List {...props} 
            filters={<ProveedorFilter />} 
            actions={permissions['proveedores']  == 1 ? <ListAction /> : permissions['proveedores']  == 2 ? <ListAction2 />: null}
            sort={{ field: 'updatedAt', order: 'DESC' }}  
            bulkActionButtons={permissions['proveedores'] == 1 ? <PostBulkActionButtons /> : null}>
            <Datagrid>
                <ReferenceArrayField label="resources.proveedors.fields.municipio" reference="municipios" source="municipio" sortable={false}>
                    <SingleFieldList>
                        <ChipField source="nombre" />
                    </SingleFieldList>
                </ReferenceArrayField>
                {/* <ReferenceField source="sistema" reference="sistemas" linkType={false}>
                <TextField source="nombre" />
            </ReferenceField>          */}
                <TextField label="resources.proveedors.fields.sistemaNombre" source="sistemaNombre" sortBy="nombreSistema" />
                <TextField label="resources.proveedors.fields.razonSocial" source="razonSocial" />
                <NumberField label="resources.proveedors.fields.numeroIdentificacion" source="numeroIdentificacion" sortable={false} />
                <BooleanField label="dynatest.resources.proveedors.boolean.mantenimiento" source="mantenimiento" sortable={false} />
                <BooleanField label="dynatest.resources.proveedors.boolean.suministros" source="suministros" sortable={false} />
                {permissions['proveedores'] == 1 || permissions['proveedores'] == 2 || permissions['proveedores'] == 4 ? <ShowButton/>: null}
                {permissions['proveedores'] == 1 || permissions['proveedores'] == 2 ? <EditButton/>: null}     
            </Datagrid>
        </List>
    )
});