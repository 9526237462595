import React from 'react';
import { ReferenceInput, TabbedForm, FormTab, NumberInput, SelectInput, FormDataConsumer, Toolbar, SaveButton, } from 'react-admin';
import { Edit } from '../../Edit';
import { required, minLength, maxLength, minValue, maxValue, } from 'react-admin';
import Grid from '@material-ui/core/Grid';

import { withStyles } from '@material-ui/core';

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const CustomToolbar = withStyles(toolbarStyles)(props => (
  <Toolbar {...props}>
    <SaveButton />

  </Toolbar>
));

export const TasasEdit = props => (
  <Edit title=" Editar Tasa de Crecimiento" {...props} redirect="list" toolbar={<CustomToolbar />}>
    <TabbedForm redirect="list">
      <FormTab label="Datos">
        <Grid container spacing={24}>
          <Grid xs={6}>
            <ReferenceInput label="Inventario" source="estacion_conteo" reference="estacion_conteos" fullWidth validate={[required()]}>
              <SelectInput optionText="nombre" />
            </ReferenceInput>
          </Grid>
          <Grid xs={6}>
            <NumberInput label="Periodo" source="periodo" fullWidth className="align-number" />
          </Grid>
          <Grid xs={6}>
            <FormDataConsumer>
              {
                ({ formData, ...rest }) => (
                  <ReferenceInput label="Fecha Inicial" source="date1" reference="ejes" fullWidth validate={[required()]} filter={{ estacion_conteo: formData.estacion_conteo }} {...rest}>
                    <SelectInput optionText="dateq" fullWidth />
                  </ReferenceInput>
                )
              }
            </FormDataConsumer>
          </Grid>
          <Grid xs={6}>
            <FormDataConsumer>
              {
                ({ formData, ...rest }) => (
                  <ReferenceInput label="Fecha Final" source="date2" reference="ejes" fullWidth validate={[required()]} filter={{ estacion_conteo: formData.estacion_conteo }} {...rest}>
                    <SelectInput optionText="dateq" fullWidth />
                  </ReferenceInput>
                )
              }
            </FormDataConsumer>
          </Grid>

        </Grid>
      </FormTab>
      <FormTab label="Categoría">
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <h3 style={{ marginBottom: 0 }}>Autos</h3>
            <hr></hr>

          </Grid>
          <Grid item xs={6}>
            <NumberInput label="Cantidad Fecha Inicial" source="auto_dano" fullWidth className="align-number" validate={[required(), minLength(1), maxLength(11), minValue(1), maxValue(999999999)]} />
          </Grid>
          <Grid item xs={6}>
            <NumberInput label="Cantidad Fecha Final" source="auto_volumen" fullWidth className="align-number" validate={[required(), minLength(1), maxLength(9), minValue(1), maxValue(999999999)]} />
          </Grid>

          <Grid item xs={12}>
            <h3 style={{ marginBottom: 0 }}>Buses</h3>
            <hr></hr>

          </Grid>
          <Grid item xs={6}>
            <NumberInput label="Cantidad Fecha Inicial" source="buses_dano" fullWidth className="align-number" validate={[required(), minLength(1), maxLength(11), minValue(1), maxValue(999999999)]} />
          </Grid>
          <Grid item xs={6}>
            <NumberInput label="Cantidad Fecha Final" source="buses_volumen" fullWidth className="align-number" validate={[required(), minLength(1), maxLength(9), minValue(1), maxValue(999999999)]} />
          </Grid>

          <Grid item xs={12}>
            <h3 style={{ marginBottom: 0 }}>C2-P</h3>
            <hr></hr>

          </Grid>
          <Grid item xs={6}>
            <NumberInput label="Cantidad Fecha Inicial" source="c2_p_dano" fullWidth className="align-number" validate={[required(), minLength(1), maxLength(11), minValue(1), maxValue(999999999)]} />
          </Grid>
          <Grid item xs={6}>
            <NumberInput label="Cantidad Fecha Final" source="c2_p_volumen" fullWidth className="align-number" validate={[required(), minLength(1), maxLength(9), minValue(1), maxValue(999999999)]} />
          </Grid>
          <Grid item xs={12}>
            <h3 style={{ marginBottom: 0 }}>C2-G</h3>
            <hr></hr>

          </Grid>
          <Grid item xs={6}>
            <NumberInput label="Cantidad Fecha Inicial" source="c2_g_dano" fullWidth className="align-number" validate={[required(), minLength(1), maxLength(11), minValue(1), maxValue(999999999)]} />
          </Grid>
          <Grid item xs={6}>
            <NumberInput label="Cantidad Fecha Final" source="c2_g_volumen" fullWidth className="align-number" validate={[required(), minLength(1), maxLength(9), minValue(1), maxValue(999999999)]} />
          </Grid>
          <Grid item xs={12}>
            <h3 style={{ marginBottom: 0 }}>C3-C4</h3>
            <hr></hr>

          </Grid>
          <Grid item xs={6}>
            <NumberInput label="Cantidad Fecha Inicial" source="c3_dano" fullWidth className="align-number" validate={[required(), minLength(1), maxLength(11), minValue(1), maxValue(999999999)]} />
          </Grid>
          <Grid item xs={6}>
            <NumberInput label="Cantidad Fecha Final" source="c3_volumen" fullWidth className="align-number" validate={[required(), minLength(1), maxLength(9), minValue(1), maxValue(999999999)]} />
          </Grid>
          <Grid item xs={12}>
            <h3 style={{ marginBottom: 0 }}>C5</h3>
            <hr></hr>

          </Grid>
          <Grid item xs={6}>
            <NumberInput label="Cantidad Fecha Inicial" source="c5_dano" fullWidth className="align-number" validate={[required(), minLength(1), maxLength(11), minValue(1), maxValue(999999999)]} />
          </Grid>
          <Grid item xs={6}>
            <NumberInput label="Cantidad Fecha Final" source="c5_volumen" fullWidth className="align-number" validate={[required(), minLength(1), maxLength(9), minValue(1), maxValue(999999999)]} />
          </Grid>
          <Grid item xs={12}>
            <h3 style={{ marginBottom: 0 }}>>C5</h3>
            <hr></hr>

          </Grid>
          <Grid item xs={6}>
            <NumberInput label="Cantidad Fecha Inicial" source="c5_1_dano" fullWidth className="align-number" validate={[required(), minLength(1), maxLength(11), minValue(1), maxValue(999999999)]} />
          </Grid>
          <Grid item xs={6}>
            <NumberInput label="Cantidad Fecha Final" source="c5_1_volumen" fullWidth className="align-number" validate={[required(), minLength(1), maxLength(9), minValue(1), maxValue(999999999)]} />
          </Grid>
        </Grid>
      </FormTab>
    </TabbedForm>
  </Edit>
);