import React, { useState, Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import {
  DateInput, BooleanInput, required, TabbedForm, FormTab, AutocompleteInput, SimpleFormIterator,
  ArrayInput, NumberInput, SelectInput, ReferenceInput, TextInput, FormDataConsumer
} from 'react-admin';
import { PimsCreate } from './../Create';
import { styles } from './../../EstilosGrid';
import {
  abValidationInicio, validarLogicaAbsFinCreate, abValidationFin, validarDosDecimales, validarUnDecimal, isFechaInvalida, prValidationInicio, prValidationFin
} from '../../../src/resources/validacionesInputs';
import { latMininima, lngMininima, Entero } from '../validacionesInputs';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { minValue, maxValue, minLength, maxLength } from 'ra-core';
import { PRInput } from '../../inputs/PRInput';
import { PInput } from '../../inputs/PInput';
import { DInput } from '../../inputs/DInput';
import { DEInput } from '../../inputs/DEInput';
import CoordenadasProyecto from '../CoordenadasInventario';
import AbsSegmento from '../AbsSegmento';
import Reparaciones from './campos/reparaciones';
import config from '../../config/config';
import { ImagenesInventario } from '../images/ImagenesInventario';
import {REDUX_FORM_NAME} from 'react-admin';
import {change} from 'redux-form';
import { translate } from 'react-admin';
let urlAppjs = config.UrlServer;

export const PuenteCreate = translate(withStyles(styles)(({ classes, ...props }) => {
  const { translate } = props;

  const styles = {
    ocultar: { display: "none" },

  };
  let defecto = 0;
  const [segmentos, setSegmento] = useState('');
  const [unidads, setUnidad] = useState('');

  /* NEW ANDERSON */
  const [tipo_elementos, setTipoElementos] = useState('');
  const [tipo_acceso, setTipoAcceso] = useState('');

  const ValidarTipoElementos = (value) => {
    let lenghtObject = Object.keys(value);
    let valor = '';
    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setTipoElementos(value);
  }

  const ValidarTipoAcceso = (value) => {
    if(value){
      let lenghtObject = Object.keys(value);
      let valor = '';
      for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
      setTipoAcceso(value);
    }
  }

  /* END ANDERSON */

  const ValidarUnidad = (value) => {
    let lenghtObject = Object.keys(value);
    let valor = '';
    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setUnidad(value)
  }

  const Unidades = { unidadFuncional: unidads };

  const [tramos, setTramo] = useState('');

  const ValidarTramo = (value) => {
    let lenghtObject = Object.keys(value);
    let valor = '';
    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setTramo(value)
  }
  const [estados, setEstado] = useState('');
  const ValidarEstado = (value) => {

    let lenghtObject = Object.keys(value);
    let valor = '';

    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setEstado(value)

  }
  const [variante, setVariante] = useState('');
  const ValidarVariante = (value) => {

    let lenghtObject = Object.keys(value);
    let valor = '';

    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setVariante(valor)

  }

  const [tipo, setTipo] = useState('');
  const ValidarTipo = (value) => {
    if (value != null) {
      let lenghtObject = Object.keys(value);
      let valor = '';

      for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
      setTipo(value)
    }

  }
  const [tipoa, setTipoa] = useState('');
  const ValidarTipoa = (value) => {
    if (value != null) {
      let lenghtObject = Object.keys(value);
      let valor = '';

      for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
      setTipoa(value)
    }
  }

  const Tramoss = { tramo: tramos };

  const [elemento, setElemento] = useState('');
  const [opciones, setOpcion] = useState('');
  const ValidarElemento = (value) => {

    let lenghtObject = Object.keys(value);
    let valor = '';

    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setElemento(value)

    fetch(urlAppjs + '/elementoDrenaje/')
      .then((response) => {
        console.log("REST");
        return response.json()
      })
      .then((response) => {
        setOpcion(response);
        console.log(response)
      })

  }
  // const validarDerecha=(value)=>{
  //   let resultado =[];
  //   for(let j=0; j<largo; j++){
  //       let valor = document.getElementById('1'+j);
  //       if(valor && valor.value!=""){
  //           valor.style.textAlign="right"
  //           resultado.push(valor.value);
  //       }
  //   }
  // }

  /* VALIDAR LOS RANGOS QUE PUEDEN TENER LAS LATITUDES Y LONGITUDES */
  const [latMin, setLatMin] = useState('');
  const [lngMin, setLngMin] = useState('');
  const [latMax, setLatMax] = useState('');
  const [lngMax, setLngMax] = useState('');

  const ValidarSegmento = (value) => {
    let lenghtObject = Object.keys(value);
    let valor = value;
    //for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setSegmento(valor)
    let idSegmento = valor;
    idSegmento = idSegmento.split('/');
    fetch(urlAppjs + '/segmentos/' + idSegmento[2] + '.json')
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        let comprobacion = myJson["ubicacionMapa"]
        if (comprobacion !== undefined) {
          let comprobar = myJson["ubicacionMapa"][0]
          if (comprobar !== undefined) {
            let latMin = myJson["ubicacionMapa"][0]['lat'];
            let lngMin = myJson["ubicacionMapa"][0]['lng'];
            let latMax = myJson["ubicacionMapa"][1]['lat'];
            let lngMax = myJson["ubicacionMapa"][1]['lng'];
            setLatMin(latMin);
            setLngMin(lngMin);
            setLatMax(latMax);
            setLngMax(lngMax);

          }
        }
      })

  }
  const LngIni = (value) => {
    if (lngMax !== '') {
      if (parseFloat(value) > parseFloat(lngMin) || parseFloat(value) < parseFloat(lngMax)) {
        return translate('resources.validaciones.rangoEntre') + lngMin + " y " + lngMax;
      }
    }
  }
  const LatIni = (value) => {
    if (latMax !== '') {
      if (parseFloat(value) < parseFloat(latMin) || parseFloat(value) > parseFloat(latMax)) {
        return translate('resources.validaciones.rangoEntre') + latMin + " y " + latMax;
      }
    }
  }
  /* FIN DE VALIDACIÓN DE LOS RANGOS QUE PUEDEN TENER LAS LATITUDES Y LONGITUDES */

  /*VALORES POR DEFECTO DEL ARRAY DE COORDENADAS*/
  const [latitudMinimaArray, setLatitudArray] = useState('');
  const LatitudMinimaArray = (value) => {
    setLatitudArray(value)
  }
  const [longitudMinimaArray, setLongitudArray] = useState('');
  const LongitudMinimaArray = (value) => {
    setLongitudArray(value)
  }
  const [longitudMaximaArray, setLongitudMaxdArray] = useState('');
  const LongitudMaximaArray = (value) => {
    setLongitudMaxdArray(value)
  }
  const [latitudMaximaArray, setLatitudMaxdArray] = useState('');
  const LatitudMaximaArray = (value) => {
    setLatitudMaxdArray(value)
  }
  const choices1 = [
    { _id: '01', full_name: '01' },
    { _id: '02', full_name: '02' },
    { _id: '00', full_name: '00' },
  ];
  const choices = [
    { _id: '01', full_name: 'dynatest.resources.puentes.select.choices.Circular' },
    { _id: '02', full_name: 'dynatest.resources.puentes.select.choices.Rectangular' },
    { _id: '00', full_name: 'dynatest.resources.puentes.select.choices.Otra' },
  ];
  const optionRenderer = choice => `${choice.id}`;
  const choises2 = [
    { id: '01 ', name: ' 01 ' },
    { id: '02 ', name: ' 02 ' },
    { id: '03 ', name: ' 03 ' },
    { id: '04 ', name: ' 04 ' },
    { id: '05 ', name: ' 05 ' },
    { id: '00 ', name: ' 00 ' },
    { id: 'N/A ', name: ' N/A ' },
  ]
  const choises3 = [
    { id: '04 ', name: 'dynatest.resources.puentes.select.choises3.ApoyoFijo' },
    { id: '01 ', name: 'dynatest.resources.puentes.select.choises3.Balancin' },
    { id: '05 ', name: 'dynatest.resources.puentes.select.choises3.Basculante' },
    { id: '03 ', name: 'dynatest.resources.puentes.select.choises3.PlacaNeopreno' },
    { id: '02 ', name: 'dynatest.resources.puentes.select.choises3.Rodillo' },
    { id: '00 ', name: 'dynatest.resources.puentes.select.choises3.Otros' },
    { id: 'N/A ', name: 'dynatest.resources.puentes.select.choises3.NoAplica' },
  ];
  const choises4 = [
    { id: '2000', name: '2000' },
    { id: '2001', name: '2001' },
    { id: '2002', name: '2002' },
    { id: '2003', name: '2003' },
    { id: '2004', name: '2004' },
    { id: '2005', name: '2005' },
    { id: '2006', name: '2006' },
    { id: '2007', name: '2007' },
    { id: '2008', name: '2008' },
    { id: '2009', name: '2009' },
    { id: '2010', name: '2010' },
    { id: '2011', name: '2011' },
    { id: '2012', name: '2012' },
    { id: '2013', name: '2013' },
    { id: '2014', name: '2014' },
    { id: '2015', name: '2015' },
    { id: '2016', name: '2016' },
    { id: '2017', name: '2017' },
    { id: '2018', name: '2018' },
    { id: '2019', name: '2019' },
    { id: '2020', name: '2020' },
    { id: '2021', name: '2021' },
    { id: '2022', name: '2022' },
    { id: '2023', name: '2023' },
    { id: '2024', name: '2024' },
    { id: '2025', name: '2025' },
    { id: '2026', name: '2026' },
    { id: '2027', name: '2027' },
    { id: '2028', name: '2028' },
    { id: '2029', name: '2029' },
    { id: '2030', name: '2030' }
  ];
  //Validacion de longitud y ancho del tablero
  //Total --->
  const [totaltablero, setTotaltablero] = useState('');


  const Longitu = (value) => {
    let valAncho = 0;
    valAncho = document.getElementById('1').value;
    if (valAncho != null && value != null) {
      //console.log(parseFloat(value)*100 + "<-->" + parseFloat(valAncho));
      // valAncho = valAncho.replace('.', ',');
      // value = value.replace('.', ',');
    }
    //console.log(parseFloat(value)*100 + "<-->" + parseFloat(valAncho));
    setTotaltablero(((parseFloat(value) * 100) * (parseFloat(valAncho) * 100)) / 10000);
    // console.log(totaltablero);
  }
  const validarEnteroNT = (value) => {
    if (!/^([0-9]+)*$/.test(value)) {
      return translate('resources.validaciones.entero');
    }
  }

  /*OPCIONES PARA TIPO DE IMÁGENES*/
  const [defaultTipoImagen, setDefaultTipoImagen] = useState([{ id: 'Cargando...', name: 'Cargando...' }]);
  const OpcionTipoImagen = (value) => {
    setDefaultTipoImagen([
      {
        tipoImagen: '/tipo_images/1',
      },
      {
        tipoImagen: '/tipo_images/7',
      },
      {
        tipoImagen: '/tipo_images/8',
      },
      {
        tipoImagen: '/tipo_images/9',
      },
      {
        tipoImagen: '/tipo_images/5',
      },
      {
        tipoImagen: '/tipo_images/6',
      }
    ])
  }


  return (

    <PimsCreate  {...props}>
      <TabbedForm redirect="list">
        <FormTab label="dynatest.resources.puentes.tabs.tabsTitle.identificacion">
          <CoordenadasProyecto />
          <Grid container spacing={24}>
            <Grid item xs={6}>
              <ReferenceInput label="resources.puentes.fields.elementos" source="elementos" reference="tipo_elementos" fullWidth filter={{ sistema_id: 'PTS', id: ["PNTE", "PNTEP"] }} validate={[required(), OpcionTipoImagen, ValidarTipoElementos]}>
                <SelectInput optionText="nombre" />
              </ReferenceInput>
            </Grid>
            {tipo_elementos && tipo_elementos != '/tipo_elementos/PNTEP' &&
              <Grid item xs={6}>
                <SelectInput source="margen" label="resources.puentes.fields.margen" validate={[required()]} fullWidth choices={[
                  { id: translate('dynatest.resources.puentes.select.margen.Derecha'), name: 'dynatest.resources.puentes.select.margen.Derecha' },
                  { id: translate('dynatest.resources.puentes.select.margen.Izquierda'), name: 'dynatest.resources.puentes.select.margen.Izquierda' },
                  { id: translate('dynatest.resources.puentes.select.margen.Centro'), name: 'dynatest.resources.puentes.select.margen.Centro' },
                ]} />
              </Grid>
            }
            <Grid item xs={6}>
              <SelectInput label="resources.puentes.fields.tipo" source="tipo" allowEmpty emptyValue="" validate={[required()]} fullWidth choices={[
                { id: translate('dynatest.resources.puentes.select.tipo.Concreto'), name: 'dynatest.resources.puentes.select.tipo.Concreto' },
                { id: translate('dynatest.resources.puentes.select.tipo.MetalicosMixtos'), name: 'dynatest.resources.puentes.select.tipo.MetalicosMixtos' },

              ]} />
            </Grid>
            {tipo_elementos && tipo_elementos != '/tipo_elementos/PNTEP' &&
              <Grid item xs={6}>
                <NumberInput source="carriles" label="resources.puentes.fields.carriles" validate={[required(), Entero, minValue(1), maxValue(10)]} fullWidth />
              </Grid>
            }
            {/* ------- LOCALIZACIÓN --------- */}
            <Grid item xs={12}>
              <h3 style={{ marginBottom: 0 }}>{translate('dynatest.resources.puentes.subTitle.localizacion')}</h3>
              <hr></hr>
            </Grid>
            <Grid item xs={4}>
              <ReferenceInput
                label="resources.puentes.fields.unidad_funcional"
                source="unidad_funcional"
                reference="unidad_funcionals"
                filter={{ "properties[]": "codigo" }}
                filterToQuery={searchText => ({ codigo: searchText })}
                sort={{ field: 'codigo', order: 'asc' }}
                validate={[required(),ValidarUnidad]}
                fullWidth
              >
                <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
              </ReferenceInput>
            </Grid>

            {unidads &&
              <Grid item xs={4}>
                <ReferenceInput
                  label="resources.puentes.fields.tramo"
                  source="tramo"
                  reference="tramos"
                  filter={{ "properties[]": "codigo", unidadFuncional: unidads }}
                  filterToQuery={searchText => ({ codigo: searchText })}
                  sort={{ field: 'codigo', order: 'ASC', }}
                  validate={[required(), ValidarTramo]}
                  formClassName={classes.grid_cont4}
                >
                  <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
                </ReferenceInput>
              </Grid>
            }
            {tramos &&
              <Grid item xs={4}>
                <ReferenceInput
                  label="resources.puentes.fields.segmento"
                  source="segmento"
                  reference="segmentos"
                  sort={{ field: 'codigo', order: 'ASC' }}
                  filter={{ "properties[]": "codigo", tramo: tramos }}
                  filterToQuery={searchText => ({ codigo: searchText })}
                  validate={[required(), ValidarSegmento]}
                  formClassName={classes.grid_cont4}
                >

                  <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
                </ReferenceInput>
                {/* <ReferenceInput label="Segmento" source="segmento" reference="segmentos" className={classes.ocultar} >
                  <SelectInput optionText="fullAbinicio" id="IdAbInicio" />
                </ReferenceInput>
                <ReferenceInput label="Segmento" source="segmento" reference="segmentos" className={classes.ocultar} >
                  <SelectInput optionText="fullAbfin" id="IdAbFin" />
                </ReferenceInput> */}
              </Grid>
            }
          </Grid>
          {segmentos && <AbsSegmento segmento={segmentos} />}
          {segmentos &&
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <Typography variant="h3" component="h3" className={classes.coord}>{translate('dynatest.resources.puentes.subTitle.coordenadasIniciales')}</Typography>
                <Divider fullWidth style={{ marginTop: '10px' }} />
              </Grid>
            </Grid>
          }
          {segmentos && <PRInput label="resources.puentes.fields.abscisa" id="ingresoAbInicio" source="abInicio" fullWidth validate={[abValidationInicio, required()]} formClassName={classes.grid_cont3s} />}
          {segmentos && <PInput label="resources.puentes.fields.PR" id="ingresoPrInicio" source="pInicio" fullWidth validate={[prValidationInicio]} formClassName={classes.grid_cont3} />}
          {segmentos && <NumberInput label="resources.puentes.fields.latitud" id="LatitudInicial" source="latitudInicial" fullWidth validate={[required(), latMininima, LatitudMinimaArray]} formClassName={classes.grid_cont3} />}
          {segmentos && <NumberInput label="resources.puentes.fields.longitud" id="LongitudInicial" source="longitudInicial" fullWidth validate={[required(), lngMininima, LongitudMinimaArray]} formClassName={classes.grid_cont3} />}
          {segmentos &&
            <Grid container spacing={24}>
              <Grid item xs={12}>
                <Typography variant="h3" component="h3" className={classes.coord}>{translate('dynatest.resources.puentes.subTitle.coordenadasFinales')}</Typography>
                <Divider fullWidth style={{ marginTop: '10px' }} />
              </Grid>
            </Grid>
          }
          {segmentos && <PRInput label="resources.puentes.fields.abscisa" id="ingresoAbFin" source="abFin" fullWidth validate={((tipo_elementos == '/tipo_elementos/PNTEP') ? [abValidationFin, validarLogicaAbsFinCreate] : [abValidationFin, validarLogicaAbsFinCreate])} formClassName={classes.grid_cont3s} />}
          {segmentos && <PInput label="resources.puentes.fields.PR" id="ingresoPrFin" source="pFin" fullWidth validate={[prValidationFin]} formClassName={classes.grid_cont3} />}
          {segmentos && <NumberInput label="resources.puentes.fields.latitud" source="latitudFinal" fullWidth validate={((tipo_elementos == '/tipo_elementos/PNTEP') ? [latMininima, LatitudMaximaArray] : [latMininima, LatitudMaximaArray])} formClassName={classes.grid_cont3} />}
          {segmentos && <NumberInput label="resources.puentes.fields.longitud" source="longitudFinal" fullWidth validate={((tipo_elementos == '/tipo_elementos/PNTEP') ? [lngMininima, LongitudMaximaArray] : [lngMininima, LongitudMaximaArray])} formClassName={classes.grid_cont3} />}
          {segmentos &&

            <ArrayInput label="" source="ubicaciones" style={{
              display:
                "none"
            }}
              defaultValue={[
                {
                  lat: latitudMinimaArray,
                  lng: longitudMinimaArray
                },
                {
                  lat: latitudMaximaArray,
                  lng: longitudMaximaArray
                }
              ]}
            >
              <SimpleFormIterator disableAdd disableRemove >
                <NumberInput label="Latitud" source="lat" fullWidth />
                <DInput label="Longitud" source="lng" fullWidth />
              </SimpleFormIterator>
            </ArrayInput>
          }
        </FormTab>
        {/* ......:::.... ADMINISTRATIVOS ......:::....*/}
        <FormTab label="dynatest.resources.puentes.tabs.tabsTitle.datosAdministrativos">
          <ArrayInput className="estilos_array_puentes" label=" " fullWidth source="administrativos" defaultValue={[
            {
              years: null, area: null, norma_diseno: null, disenador: null, info_di: null, yearsb: null, areab: null, norma_disenob: null, disenadorb: null, info_dib: null, fecha: null, direccion_abscisado: null, estacion_conteo: null, inspección: null, inspector: null, numinspeccion: null, propietario: null, admon_vial: null, proyectista: null, coe_sismo: null, paso_cauce: null, exis_variante: null, long_km: null, estado_op: null, vehi_di: null, distri_carga: null, capacidad: null, luz_critica: null, fac_clas: null, fac_cort: null, momento: null, car_rue: null, observacioness: null,
            }
          ]}>
            <SimpleFormIterator disableAdd disableRemove>
              <Typography variant="h3" component="h3">{translate('dynatest.resources.puentes.subTitle.detallesConstructivos')}</Typography>
              <span></span><span></span>
              <Divider fullWidth formClassName={classes.grid_cont12} />
              <DateInput label="resources.puentes.fields.years" source="years" validate={[required(), isFechaInvalida]} fullWidth formClassName={classes.grid_cont3s} />
              <NumberInput allowEmpty emptyValue="" label="resources.puentes.fields.area" source="area" id="area1" fullWidth validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? [validarDosDecimales, minValue(1)] : [required(), validarDosDecimales, minValue(1)]} formClassName={classes.grid_cont3} />
              <TextInput label="resources.puentes.fields.norma_diseno" source="norma_diseno" fullWidth validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? [minLength(1), maxLength(60)] : [required(), minLength(1), maxLength(60)]} formClassName={classes.grid_cont3} />
              <TextInput label="resources.puentes.fields.disenador" source="disenador" fullWidth validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? [minLength(1), maxLength(60)] : [required(), minLength(1), maxLength(60)]} formClassName={classes.grid_cont3} />
              <TextInput label="resources.puentes.fields.info_di" source="info_di" fullWidth validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? [minLength(1), maxLength(200)] : [required(), minLength(1), maxLength(200)]} />

              <Typography variant="h3" component="h3" fullWidth>{translate('dynatest.resources.puentes.subTitle.detRefuerzoRehabilit')}</Typography>
              <span></span><span></span>
              <Divider fullWidth formClassName={classes.grid_cont12} />
              <DateInput label="resources.puentes.fields.yearsb" source="yearsb" validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? [isFechaInvalida] : [required(), isFechaInvalida]} fullWidth formClassName={classes.grid_cont3s} />
              <NumberInput allowEmpty emptyValue="" label="resources.puentes.fields.areab" source="areab" id="area2" fullWidth validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? [validarDosDecimales, minValue(1)] : [required(), validarDosDecimales, minValue(1)]} formClassName={classes.grid_cont3} />
              <TextInput label="resources.puentes.fields.norma_disenob" source="norma_disenob" fullWidth validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? [minLength(1), maxLength(60)] : [required(), minLength(1), maxLength(60)]} formClassName={classes.grid_cont3} />
              <TextInput label="resources.puentes.fields.disenadorb" source="disenadorb" fullWidth validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? [minLength(1), maxLength(60)] : [required(), minLength(1), maxLength(60)]} formClassName={classes.grid_cont3} />
              <TextInput label="resources.puentes.fields.info_dib" source="info_dib" fullWidth validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? [minLength(1), maxLength(200)] : [required(), minLength(1), maxLength(200)]} />

              <Typography variant="h3" component="h3">{translate('dynatest.resources.puentes.subTitle.detallesInspeccion')}</Typography>
              <span></span><span></span>
              <Divider fullWidth formClassName={classes.grid_cont12} />
              <DateInput label="resources.puentes.fields.fecha" source="fecha" fullWidth validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? [isFechaInvalida] : [required(), isFechaInvalida]} formClassName={classes.grid_cont4s} />
              <TextInput label="resources.puentes.fields.inspector" source="inspector" fullWidth validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? [minLength(1), maxLength(30)] : [required(), minLength(1), maxLength(30)]} formClassName={classes.grid_cont4} />
              <NumberInput label="resources.puentes.fields.inspeccion" source="inspeccion" fullWidth validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? [Entero, minValue(0), maxValue(99999999)] : [required(), Entero, minValue(0), maxValue(99999999)]} formClassName={classes.grid_cont4} />
              <SelectInput allowEmpty emptyValue="" label="resources.puentes.fields.direccion_abscisado" source="direccion_abscisado" validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? '' : [required()]} fullWidth choices={[
                { id: 'N', name: 'dynatest.resources.puentes.select.sentido.N' },
                { id: 'S', name: 'dynatest.resources.puentes.select.sentido.S' },
                { id: 'E', name: 'dynatest.resources.puentes.select.sentido.E' },
                { id: 'O', name: 'dynatest.resources.puentes.select.sentido.O' },
              ]} formClassName={(tipo_elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont4s} />
              <ReferenceInput allowEmpty emptyValue="" label="resources.puentes.fields.estacion_conteo" source="estacion_conteo" reference="estacion_conteos" filter={{ "properties[]": "nombre" }} validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? '' : [required()]} fullWidth formClassName={(tipo_elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont4}>
                <SelectInput optionText="nombre" />
              </ReferenceInput>
              <NumberInput allowEmpty emptyValue="" label="resources.puentes.fields.numinspeccion" source="numinspeccion" fullWidth validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? [Entero, minValue(0), maxValue(99999999)] : [required(), Entero, minValue(0), maxValue(99999999)]} formClassName={(tipo_elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont4} />

              <Typography variant="h3" component="h3">{translate('dynatest.resources.puentes.subTitle.detallesInspeccion')}</Typography>
              <span></span><span></span>
              <Divider fullWidth formClassName={classes.grid_cont12} />
              <TextInput label="resources.puentes.fields.propietario" source="propietario" fullWidth validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? [minLength(1), maxLength(20)] : [required(), minLength(1), maxLength(20)]} formClassName={classes.grid_cont4s} />
              <TextInput label="resources.puentes.fields.admon_vial" source="admon_vial" fullWidth validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? [minLength(1), maxLength(20)] : [required(), minLength(1), maxLength(20)]} formClassName={classes.grid_cont4} />
              <TextInput label="resources.puentes.fields.proyectista" source="proyectista" fullWidth validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? [minLength(1), maxLength(30)] : [required(), minLength(1), maxLength(30)]} formClassName={classes.grid_cont4} />


              <Typography formClassName={(tipo_elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : ''} variant="h3" component="h3">{translate('dynatest.resources.puentes.subTitle.caractOperacionales')}</Typography>
              <span></span><span></span>
              <Divider fullWidth formClassName={(tipo_elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont12} />
              <NumberInput allowEmpty emptyValue="" label="resources.puentes.fields.coe_sismo" source="coe_sismo" fullWidth validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? [validarDosDecimales, minValue(0), maxValue(1)] : [required(), validarDosDecimales, minValue(0), maxValue(1)]} formClassName={(tipo_elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont4s} />
              <SelectInput allowEmpty emptyValue="" label="resources.puentes.fields.paso_cauce" source="paso_cauce" fullWidth validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? '' : [required()]} choices={[
               { id: 'S', name: 'dynatest.resources.puentes.select.opcion.si' },
               { id: 'N', name: 'dynatest.resources.puentes.select.opcion.no' }
              ]} formClassName={(tipo_elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont4} />
              <SelectInput allowEmpty emptyValue="" label="resources.puentes.fields.estado_op" source="estado_op" fullWidth validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? '' : [required()]} choices={[
                { id: 'B', name: 'dynatest.resources.puentes.select.esta.b' },
                { id: 'R', name: 'dynatest.resources.puentes.select.esta.r' },
                { id: 'M', name: 'dynatest.resources.puentes.select.esta.m' },
              ]} formClassName={(tipo_elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont4} />

              <SelectInput allowEmpty emptyValue="" label="resources.puentes.fields.exis_variante" source="exis_variante" fullWidth validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? '' : [required()]} choices={[
                { id: translate('dynatest.resources.puentes.select.opcion.si'), name: 'dynatest.resources.puentes.select.opcion.si' },
                { id: translate('dynatest.resources.puentes.select.opcion.no'), name: 'dynatest.resources.puentes.select.opcion.no' }
              ]} formClassName={(tipo_elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont4} onChange={(tipo_elementos == '/tipo_elementos/PNTEP') ? "" : ValidarVariante} />

              {variante === translate('dynatest.resources.puentes.select.opcion.si') && (
                <DEInput allowEmpty emptyValue="" label="resources.puentes.fields.long_km" source="long_km" fullWidth validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? '' : [required(), minValue(0), maxValue(9999999999)]} formClassName={(tipo_elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont3} />
              )
              }


              <Typography formClassName={(tipo_elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : ''} variant="h3" component="h3" fullWidth>{translate('dynatest.resources.puentes.subTitle.capacidadCargaTransitoLegal')}</Typography>
              <span></span><span></span>
              <Divider fullWidth formClassName={(tipo_elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont12} />
              <TextInput label="resources.puentes.fields.vehi_di" source="vehi_di" fullWidth validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? [minLength(0), maxLength(10)] : [required(), minLength(0), maxLength(10)]} formClassName={(tipo_elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont4s} />
              <TextInput label="resources.puentes.fields.distri_carga" source="distri_carga" fullWidth validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? [minLength(1), maxLength(20)] : [required(), minLength(1), maxLength(20)]} formClassName={(tipo_elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont4} />
              <DInput label="resources.puentes.fields.capacidad" source="capacidad" className="align-number" fullWidth id="area3" validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? [minValue(0), maxValue(99999999)] : [required(), minValue(0), maxValue(99999999)]} formClassName={(tipo_elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont4} />
              <DInput label="resources.puentes.fields.luz_critica" source="luz_critica" fullWidth id="area4" className="align-number" validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? [minValue(0), maxValue(99999999)] : [required(), minValue(0), maxValue(99999999)]} formClassName={(tipo_elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont4s} />
              <DInput label="resources.puentes.fields.fac_clas" source="fac_clas" fullWidth id="area5" className="align-number" validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? [minValue(0), maxValue(99999999)] : [required(), minValue(0), maxValue(99999999)]} formClassName={(tipo_elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont4} />

              <Typography formClassName={(tipo_elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : ''} variant="h3" component="h3" fullWidth>{translate('dynatest.resources.puentes.subTitle.capacidadCargaTranspEspeciales')}</Typography>
              <span></span><span></span>
              <Divider fullWidth formClassName={(tipo_elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont12} />
              <DInput label="resources.puentes.fields.fac_cort" source="fac_cort" className="align-number" fullWidth id="area6" validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? [minValue(0), maxValue(99999999)] : [required(), minValue(0), maxValue(99999999)]} formClassName={(tipo_elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont4s} />
              <DInput label="resources.puentes.fields.momento" source="momento" className="align-number" fullWidth id="area7" validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? [minValue(0), maxValue(99999999)] : [required(), minValue(0), maxValue(99999999)]} formClassName={(tipo_elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont4} />
              <DInput label="resources.puentes.fields.car_rue" className="align-number" source="car_rue" fullWidth id="area8" validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? [minValue(0), maxValue(99999999)] : [required(), minValue(0), maxValue(99999999)]} formClassName={(tipo_elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont4} />
              <TextInput label="resources.puentes.fields.observacioness" source="observacioness" fullWidth validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? [minLength(1), maxLength(200)] : [required(), minLength(1), maxLength(200)]} />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="dynatest.resources.puentes.tabs.tabsTitle.geometria">
          <ArrayInput className="estilos_array_puentes" label=" " fullWidth source="geometria" defaultValue={[{
            terraplen: null, curva: null, esviajamiento: null, luces: null, luz_menor: null, luz_mayor: null, long_total: null, ancho_tab: null, area_tab: totaltablero, ancho_sep: null, ancho_anden_der: null, ancho_anden_izq: null, ancho_calzada: null, ancho_bordillo: null, alt_pilas: null, long_pilas: null, alt_estribos: null, long_estribos: null,
          }]}>
            <SimpleFormIterator disableAdd disableRemove>
              <Typography variant="h3" component="h3">{translate('dynatest.resources.puentes.subTitle.general')}</Typography>
              <Divider fullWidth formClassName={classes.grid_cont12} />
              <SelectInput label="resources.puentes.fields.curva" source="curva" allowEmpty emptyValue="" validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? '' : [required()]} fullWidth choices={[
                { id: 'C', name: 'dynatest.resources.puentes.select.pon_c.C' },
                { id: 'T', name: 'dynatest.resources.puentes.select.pon_c.T' },
              ]} formClassName={classes.grid_cont4s} />

              <SelectInput label="resources.puentes.fields.terraplen" source="terraplen" allowEmpty emptyValue="" validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? '' : [required()]} fullWidth choices={[
                { id: translate('dynatest.resources.puentes.select.opcion.si'), name: 'dynatest.resources.puentes.select.opcion.si' },
                { id: translate('dynatest.resources.puentes.select.opcion.no'), name: 'dynatest.resources.puentes.select.opcion.no' }
              ]} formClassName={(tipo_elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont4} />
              <NumberInput label="resources.puentes.fields.esviajamiento" source="esviajamiento" className="align-number" allowEmpty emptyValue="" validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? [validarDosDecimales, minValue(-90), maxValue(90)] : [required(), validarDosDecimales, minValue(-90), maxValue(90)]} fullWidth formClassName={classes.grid_cont4} />
              <Typography variant="h3" component="h3">{translate('dynatest.resources.puentes.subTitle.estructura')}</Typography>
              <span></span><span></span>
              <Divider fullWidth formClassName={classes.grid_cont12} />
              <NumberInput label="resources.puentes.fields.luces" fullWidth source="luces" allowEmpty emptyValue="" validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? [minValue(0), maxValue(20),Entero] : [required(), minValue(0), maxValue(20),Entero]} formClassName={classes.grid_cont4s} />
              <span></span>
              <NumberInput label="resources.puentes.fields.luz_menor" source="luz_menor" className="align-number" fullWidth allowEmpty emptyValue="" validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? [validarDosDecimales, minValue(0), maxValue(100)] : [required(), validarDosDecimales, minValue(0), maxValue(100)]} formClassName={classes.grid_cont4s} />
              <NumberInput label="resources.puentes.fields.luz_mayor" source="luz_mayor" className="align-number" fullWidth allowEmpty emptyValue="" validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? [validarDosDecimales, minValue(0), maxValue(1000)] : [required(), validarDosDecimales, minValue(0), maxValue(1000)]} formClassName={classes.grid_cont4} />
              <NumberInput label="resources.puentes.fields.long_total" source="long_total" className="align-number" fullWidth allowEmpty emptyValue="" validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? [Longitu, validarDosDecimales, minValue(1), maxValue(5000)] : [Longitu, validarDosDecimales, required(), minValue(1), maxValue(5000)]} formClassName={classes.grid_cont4} defaultValue="2" />
              <NumberInput label="resources.puentes.fields.ancho_tab" id='1' source="ancho_tab" className="align-number" fullWidth allowEmpty emptyValue="" validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? [validarDosDecimales, minValue(0), maxValue(200)] : [required(), validarDosDecimales, minValue(0), maxValue(200)]} formClassName={classes.grid_cont4s} />
              <NumberInput id="3" disabled locales="fr-FR" label="resources.puentes.fields.area_tab" className="align-number" source="area_tab" fullWidth formClassName={classes.grid_cont4} />
              <NumberInput label="resources.puentes.fields.ancho_sep" source="ancho_sep" className="align-number" fullWidth allowEmpty emptyValue="" validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? [minValue(0), maxValue(100)] : [required(), minValue(0), maxValue(100)]} formClassName={(tipo_elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont4} />
              <NumberInput label="resources.puentes.fields.ancho_anden_izq" source="ancho_anden_izq" className="align-number" fullWidth allowEmpty emptyValue="" validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? [validarDosDecimales, minValue(0), maxValue(100)] : [required(), validarDosDecimales, minValue(0), maxValue(100)]} formClassName={(tipo_elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont4s} />
              <NumberInput label="resources.puentes.fields.ancho_anden_der" source="ancho_anden_der" className="align-number" fullWidth allowEmpty emptyValue="" validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? [validarDosDecimales, minValue(0), maxValue(100)] : [required(), validarDosDecimales, minValue(0), maxValue(100)]} formClassName={(tipo_elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont4} />
              <NumberInput label="resources.puentes.fields.ancho_calzada" source="ancho_calzada" className="align-number" fullWidth allowEmpty emptyValue="" validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? [validarDosDecimales, minValue(0), maxValue(100)] : [required(), validarDosDecimales, minValue(0), maxValue(100)]} formClassName={(tipo_elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont4} />
              <NumberInput label="resources.puentes.fields.ancho_bordillo" source="ancho_bordillo" className="align-number" fullWidth allowEmpty emptyValue="" validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? [validarDosDecimales, minValue(0), maxValue(100)] : [required(), validarDosDecimales, minValue(0), maxValue(100)]} formClassName={(tipo_elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont4s} />
              <span></span>
              <Typography variant="h3" component="h3">{translate('dynatest.resources.puentes.subTitle.subestructura')}</Typography>
              <span></span><span></span>
              <Divider fullWidth formClassName={classes.grid_cont12} />
              <NumberInput label="resources.puentes.fields.alt_pilas" source="alt_pilas" fullWidth allowEmpty emptyValue="" validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? [validarDosDecimales, minValue(0), maxValue(500)] : [required(), validarDosDecimales, minValue(0), maxValue(500)]} formClassName={classes.grid_cont4s} />
              <NumberInput label="resources.puentes.fields.long_pilas" source="long_pilas" fullWidth allowEmpty emptyValue="" validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? [validarDosDecimales, minValue(0), maxValue(10)] : [required(), validarDosDecimales, minValue(0), maxValue(10)]} formClassName={classes.grid_cont4} />
              <br />
              <NumberInput label="resources.puentes.fields.alt_estribos" source="alt_estribos" fullWidth allowEmpty emptyValue="" validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? [minValue(0), validarDosDecimales, maxValue(500)] : [required(), minValue(0), validarDosDecimales, maxValue(500)]} formClassName={classes.grid_cont4s} />
              <NumberInput label="resources.puentes.fields.long_estribos" source="long_estribos" fullWidth allowEmpty emptyValue="" validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? [validarDosDecimales, minValue(0), maxValue(10)] : [required(), validarDosDecimales, minValue(0), maxValue(10)]} formClassName={classes.grid_cont4} />

            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        {/* -------------------------ESTRUCTURA --------------------------------------- */}
        <FormTab label="dynatest.resources.puentes.tabs.tabsTitle.estructura">
          <ArrayInput className="estilos_array_puentes" label=" " fullWidth source="superestructura" defaultValue={[
            {
              dise_tipo: null, material: null, cod_transversarl: null, cod_long: null, dise_tipoa: null, materiala: null, cod_transversarla: null, cod_longa: null, car_max: null, velo_max: null, otra: null, cod_baranda: null, cod_expansion: null, proteccion_alta_tension: null, tipo_acceso: null,
            }
          ]}>
            <SimpleFormIterator disableRemove disableAdd>
              <Typography variant="h3" component="h3">{translate('dynatest.resources.puentes.subTitle.Principal')}</Typography>
              <span></span><span></span>
              <Divider fullWidth formClassName={classes.grid_cont12} />
              <SelectInput label="resources.puentes.fields.dise_tipo" source="dise_tipo" emptyValue="" validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? [ValidarTipo] : [required(), ValidarTipo]} fullWidth choices={[
                { id: translate('dynatest.resources.puentes.select.opcion.si'), name: 'dynatest.resources.puentes.select.opcion.si' },
                { id: translate('dynatest.resources.puentes.select.opcion.no'), name: 'dynatest.resources.puentes.select.opcion.no' }
              ]} formClassName={classes.grid_cont4} />
              {tipo === translate('dynatest.resources.puentes.select.opcion.si') && (
                <SelectInput label="resources.puentes.fields.material" source="material" allowEmpty emptyValue="" validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? '' : [required()]} fullWidth choices={[
                  { id: translate('dynatest.resources.puentes.select.material.Acero'), name: 'dynatest.resources.puentes.select.material.Acero' },
                  { id: translate('dynatest.resources.puentes.select.material.Concreto'), name: 'dynatest.resources.puentes.select.material.Concreto' },
                  { id: translate('dynatest.resources.puentes.select.material.Madera'), name: 'dynatest.resources.puentes.select.material.Madera' },
                  { id: translate('dynatest.resources.puentes.select.material.Mixto'), name: 'dynatest.resources.puentes.select.material.Mixto' },
                  { id: translate('dynatest.resources.puentes.select.material.Otro'), name: 'dynatest.resources.puentes.select.material.Otro' },
                ]} formClassName={classes.grid_cont4} />
              )}
              {tipo === translate('dynatest.resources.puentes.select.opcion.si') && (
                <ReferenceInput label="resources.puentes.fields.transversarlCod" disabled source="transversarl" reference="transversarls" filter={{ "properties[]": "ids", }} fullWidth className="align-number" formClassName={[classes.grid_cont4, classes.textToRight]}>
                  <SelectInput optionText="ids" />
                </ReferenceInput>
              )}
              {tipo === translate('dynatest.resources.puentes.select.opcion.si') && (
                <ReferenceInput label="resources.puentes.fields.transversarl" source="transversarl" sort={{ field: 'descripcion', order: 'ASC' }} filter={{ "properties[]": "descripcion", }} reference="transversarls" validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? '' : [required()]} fullWidth formClassName={classes.grid_cont4}>
                  <SelectInput optionText="descripcion" />
                </ReferenceInput>)}
              {tipo === translate('dynatest.resources.puentes.select.opcion.si') && (
                <ReferenceInput label="resources.puentes.fields.longitudCod" source="longitud" reference="longituds" disabled fullWidth filter={{ "properties[]": "ids", }} className="align-number" formClassName={[classes.grid_cont4, classes.textToRight]}>
                  <SelectInput optionText="ids" />
                </ReferenceInput>
              )}
              {tipo === translate('dynatest.resources.puentes.select.opcion.si') && (
                <ReferenceInput label="resources.puentes.fields.longitudinal" sort={{ field: 'decripcion', order: 'ASC' }} source="longitud" filter={{ "properties[]": "descripcion", }} reference="longituds" allowEmpty emptyValue="" validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? '' : [required()]} fullWidth formClassName={classes.grid_cont4}>
                  <SelectInput optionText="decripcion" />
                </ReferenceInput>
              )}
              <Typography variant="h3" component="h3">{translate('dynatest.resources.puentes.subTitle.Secundario')}</Typography>
              <span></span><span></span>
              <Divider fullWidth formClassName={classes.grid_cont12} />
              <SelectInput label="resources.puentes.fields.dise_tipo" source="dise_tipoa" emptyValue="" validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? [ValidarTipoa] : [required(), ValidarTipoa]} fullWidth choices={[
                { id: translate('dynatest.resources.puentes.select.opcion.si'), name: 'dynatest.resources.puentes.select.opcion.si' },
                { id: translate('dynatest.resources.puentes.select.opcion.no'), name: 'dynatest.resources.puentes.select.opcion.no' }
              ]} formClassName={classes.grid_cont4} />
              {tipoa === translate('dynatest.resources.puentes.select.opcion.si') && (
                <SelectInput label="resources.puentes.fields.material" source="materiala" allowEmpty emptyValue="" validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? '' : [required()]} fullWidth choices={[
                  { id: translate('dynatest.resources.puentes.select.material.Acero'), name: 'dynatest.resources.puentes.select.material.Acero' },
                  { id: translate('dynatest.resources.puentes.select.material.Concreto'), name: 'dynatest.resources.puentes.select.material.Concreto' },
                  { id: translate('dynatest.resources.puentes.select.material.Madera'), name: 'dynatest.resources.puentes.select.material.Madera' },
                  { id: translate('dynatest.resources.puentes.select.material.Mixto'), name: 'dynatest.resources.puentes.select.material.Mixto' },
                  { id: translate('dynatest.resources.puentes.select.material.Otro'), name: 'dynatest.resources.puentes.select.material.Otro' },

                ]} formClassName={classes.grid_cont4} />
              )
              }
              {tipoa === translate('dynatest.resources.puentes.select.opcion.si') && (
                <ReferenceInput
                  label="resources.puentes.fields.transversarlCod"
                  source="transversarla"
                  reference="transversarlas"
                  disabled
                  fullWidth
                  filter={{ "properties[]": "ids", }}
                  className="align-number"
                  formClassName={[classes.grid_cont4, classes.textToRight]}
                >
                  <SelectInput optionText="ids" />
                </ReferenceInput>
              )
              }
              {tipoa === translate('dynatest.resources.puentes.select.opcion.si') && (
                <ReferenceInput label="resources.puentes.fields.transversarl" filter={{ "properties[]": "descripcion", }} sort={{ field: 'descripcion', order: 'ASC' }} source="transversarla" reference="transversarlas" allowEmpty emptyValue="" validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? '' : [required()]} fullWidth formClassName={classes.grid_cont4}>
                  <SelectInput optionText="descripcion" />
                </ReferenceInput>
              )
              }
              {tipoa === translate('dynatest.resources.puentes.select.opcion.si') && (
                <ReferenceInput label="resources.puentes.fields.longitudCod" source="longa" reference="longas" filter={{ "properties[]": "ids", }} emptyValue="" disabled fullWidth className="align-number" formClassName={[classes.grid_cont4, classes.textToRight]}>
                  <SelectInput optionText="ids" />
                </ReferenceInput>
              )
              }
              {tipoa === translate('dynatest.resources.puentes.select.opcion.si') && (
                <ReferenceInput label="resources.puentes.fields.longitudinal" filter={{ "properties[]": "descripcion", }} sort={{ field: 'descripcion', order: 'ASC' }} source="longa" reference="longas" allowEmpty emptyValue="" validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? '' : [required()]} fullWidth formClassName={classes.grid_cont4}>
                  <SelectInput optionText="descripcion" />
                </ReferenceInput>
              )
              }
              <Typography variant="h3" component="h3">{translate('dynatest.resources.puentes.subTitle.Senales')}</Typography>
              <span></span><span></span>
              <Divider fullWidth formClassName={classes.grid_cont12} />
              <TextInput allowEmpty emptyValue="" label="resources.puentes.fields.car_max" source="car_max" validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? [minLength(1), maxLength(10)] : [required(), minLength(1), maxLength(10)]} fullWidth formClassName={(tipo_elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont4} />
              <NumberInput allowEmpty emptyValue="" label="resources.puentes.fields.velo_max" source="velo_max" fullWidth validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? [Entero, minValue(0)] : [required(), Entero, minValue(0)]} formClassName={(tipo_elementos == '/tipo_elementos/PNTEP') ? classes.ocultar : classes.grid_cont4} />
              {(tipo_elementos == '/tipo_elementos/PNTEP') && <NumberInput label="resources.puentes.fields.galibo_maximo" source="galibo_maximo" fullWidth validate={(tipo_elementos == '/tipo_elementos/PNTEP') ? [minValue(0), maxValue(15), validarUnDecimal] : [minValue(0), validarUnDecimal]} formClassName={classes.grid_cont4} />}
              <TextInput label="resources.puentes.fields.otra" source="otra" validate={[required(), minLength(1), maxLength(10)]} fullWidth formClassName={classes.grid_cont3} />
              <Typography variant="h3" component="h3">{translate('dynatest.resources.puentes.subTitle.Detalles')}</Typography>
              <span></span><span></span>
              <Divider fullWidth formClassName={classes.grid_cont12} />
              <ReferenceInput label="resources.puentes.fields.barandaCod" source="baranda" reference="barandas" fullWidth disabled allowEmpty emptyValue="" validate={[required()]} className="align-number" formClassName={[classes.grid_cont4, classes.textToRight]}>
                <SelectInput optionText="ides" />
              </ReferenceInput>
              <ReferenceInput label="resources.puentes.fields.baranda" source="baranda" reference="barandas" allowEmpty emptyValue="" sort={{ field: 'descripcion', order: 'ASC' }} validate={[required()]} fullWidth formClassName={classes.grid_cont4}>
                <SelectInput optionText="descripcion" />
              </ReferenceInput>
              <ReferenceInput label="resources.puentes.fields.expantionCod" source="expantion" reference="expantions" fullWidth disabled allowEmpty emptyValue="" validate={[required()]} className="align-number" formClassName={[classes.grid_cont4, classes.textToRight]}>
                <SelectInput optionText="ids" />
              </ReferenceInput>
              <ReferenceInput label="resources.puentes.fields.expantion" source="expantion" reference="expantions" sort={{ field: 'descripcion', order: 'ASC' }} allowEmpty emptyValue="" validate={[required()]} fullWidth formClassName={classes.grid_cont4}>
                <SelectInput optionText="descripcion" fullWidth />
              </ReferenceInput>

              {tipo_elementos == '/tipo_elementos/PNTEP' && 
              <SelectInput label="resources.puentes.fields.proteccion_alta_tension" source="proteccion_alta_tension" allowEmpty emptyValue="" validate={[required()]} fullWidth choices={[
                { id: translate('dynatest.resources.puentes.select.opcion.si'), name: 'dynatest.resources.puentes.select.opcion.si' },
                { id: translate('dynatest.resources.puentes.select.opcion.no'), name: 'dynatest.resources.puentes.select.opcion.no' }
              ]} formClassName={classes.grid_cont4} />}
              
              {tipo_elementos == '/tipo_elementos/PNTEP' &&
              <SelectInput label="resources.puentes.fields.tipo_acceso" source="tipo_acceso" allowEmpty emptyValue="" validate={[ValidarTipoAcceso, required()]} fullWidth choices={[
                { id: translate('dynatest.resources.puentes.select.tipo_acceso.Escalera'), name: 'dynatest.resources.puentes.select.tipo_acceso.Escalera' },
                { id: translate('dynatest.resources.puentes.select.tipo_acceso.Rampa'), name: 'dynatest.resources.puentes.select.tipo_acceso.Rampa' },
              ]} formClassName={classes.grid_cont4} />}

              {tipo_acceso && tipo_acceso == translate('dynatest.resources.puentes.select.tipo_acceso.Escalera') && tipo_elementos == '/tipo_elementos/PNTEP' &&
                <NumberInput label="resources.puentes.fields.huella" source="huella" fullWidth validate={[minValue(0), maxValue(50)]} formClassName={classes.grid_cont4} />
              }
              {tipo_acceso && tipo_acceso == translate('dynatest.resources.puentes.select.tipo_acceso.Escalera') && tipo_elementos == '/tipo_elementos/PNTEP' &&
                <NumberInput label="resources.puentes.fields.contrahuella" source="contrahuella" fullWidth validate={[minValue(0), maxValue(50)]} formClassName={classes.grid_cont4} />
              }
              {tipo_acceso && tipo_acceso == translate('dynatest.resources.puentes.select.tipo_acceso.Rampa') && tipo_elementos == '/tipo_elementos/PNTEP' &&
                <NumberInput label="resources.puentes.fields.logitud_remapa" source="logitud_remapa" fullWidth validate={[validarDosDecimales, minValue(0), maxValue(50)]} formClassName={classes.grid_cont4} />
              }
              {tipo_acceso && tipo_acceso == translate('dynatest.resources.puentes.select.tipo_acceso.Rampa') && tipo_elementos == '/tipo_elementos/PNTEP' &&
                <NumberInput label="resources.puentes.fields.pendiente" source="pendiente" fullWidth validate={[minValue(0), validarUnDecimal, maxValue(50)]} formClassName={classes.grid_cont4} />
              }

              {tipo_acceso && tipo_elementos == '/tipo_elementos/PNTEP' &&
                <NumberInput label="resources.puentes.fields.nro_tramos" source="nro_tramos" fullWidth validate={[minValue(0), maxValue(10)]} formClassName={classes.grid_cont3} />
              }
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>

        <FormTab label="dynatest.resources.puentes.tabs.tabsTitle.subestructura">
          {tipo_elementos != '/tipo_elementos/PNTEP' &&
            <ArrayInput className="estilos_array_puentes" label=" " fullWidth source="subestructura" defaultValue={[
              {
                tipoestribos: null, tipo_cimen: null, estri: null, tippilas: null, forma_pila: null, cimen_pila: null, estrisa: null, apoyo_fijo: null, apoyo_movil: null, pila_movil: null, pila_pila: null, pila_vigas: null, movil_vigas: null,
              }
            ]}>
              <SimpleFormIterator disableAdd disableRemove>
                <Typography variant="h3" component="h3">{translate('dynatest.resources.puentes.subTitle.Estribos')}</Typography>
                <span></span><span></span>
                <Divider fullWidth formClassName={classes.grid_cont12} />
                <ReferenceInput label="resources.puentes.fields.tipoestribos" source="tipoestribos" reference="tipoestribos" validate={[required()]} sort={{ field: 'descripcion', order: 'ASC' }} fullWidth formClassName={classes.grid_cont3s} >
                  <SelectInput optionText="descripcion" />
                </ReferenceInput>
                <ReferenceInput label="resources.puentes.fields.estriCod" source="estri" reference="estris" fullWidth disable className="align-number" formClassName={[classes.grid_cont4, classes.textToRight, classes.left]}>
                  <SelectInput optionText="ids" disabled emptyValue="" />
                </ReferenceInput>
                <ReferenceInput label="resources.puentes.fields.estriMat" source="estri" reference="estris" allowEmpty emptyValue="" sort={{ field: 'descripcion', order: 'ASC' }} validate={[required()]} fullWidth formClassName={classes.grid_cont3} >
                  <SelectInput optionText="descripcion" fullWidth />
                </ReferenceInput>
                <SelectInput label="resources.puentes.fields.tipo_cimen" source="tipo_cimen" allowEmpty emptyValue="" validate={[required()]} fullWidth choices={[
                  { id: '01 ', name: 'dynatest.resources.puentes.select.tipo_cimen.Profunda' },
                  { id: '02 ', name: 'dynatest.resources.puentes.select.tipo_cimen.Superficial' },
                ]} formClassName={classes.grid_cont3} />
                <Typography variant="h3" component="h3">{translate('dynatest.resources.puentes.subTitle.Pilas')}</Typography>
                <span></span><span></span>
                <Divider fullWidth formClassName={classes.grid_cont12} />
                <ReferenceInput label="resources.puentes.fields.tippilasCod" source="tippilas" reference="tippilas" disabled fullWidth className="align-number" formClassName={[classes.grid_cont4, classes.textToRight, classes.toLeft]}>
                  <SelectInput optionText="ids" />
                </ReferenceInput>
                <ReferenceInput label="resources.puentes.fields.tippilas" source="tippilas" reference="tippilas" sort={{ field: 'descripcion', order: 'ASC' }} fullWidth formClassName={classes.grid_cont3}>
                  <SelectInput optionText="descripcion" fullWidth />
                </ReferenceInput>
                <SelectInput label="resources.puentes.fields.forma_pilaCod" source="forma_pila" optionText="full_name" optionValue="_id" allowEmpty emptyValue="" disabled fullWidth choices={choices1} className="align-number" formClassName={[classes.grid_cont4, classes.textToRight, classes.toLeft]} />
                <SelectInput label="resources.puentes.fields.forma_pila" source="forma_pila" fullWidth choices={choices} optionText="full_name" optionValue="_id" formClassName={classes.grid_cont3} />
                <SelectInput label="resources.puentes.fields.cimen_pila" source="cimen_pila" fullWidth choices={[
                  { id: '01 ', name: 'dynatest.resources.puentes.select.cimen_pila.Profunda' },
                  { id: '02 ', name: 'dynatest.resources.puentes.select.cimen_pila.Superficial' },
                ]} formClassName={classes.grid_cont3} />
                <ReferenceInput label="resources.puentes.fields.estrisaCod" source="estrisa" reference="estris" disabled emptyValue="" fullWidth className="align-number" formClassName={[classes.grid_cont4, classes.textToRight, classes.toLeft]}>
                  <SelectInput optionText="ids" />
                </ReferenceInput>
                <ReferenceInput label="resources.puentes.fields.estrisa" source="estrisa" reference="estris" allowEmpty emptyValue="" sort={{ field: 'descripcion', order: 'ASC' }} validate={[required()]} fullWidth formClassName={classes.grid_cont3}>
                  <SelectInput optionText="descripcion" />
                </ReferenceInput>
                <Typography variant="h3" component="h3">{translate('dynatest.resources.puentes.subTitle.Apoyos')}</Typography>
                <span></span><span></span>
                <Divider fullWidth formClassName={classes.grid_cont12} />
                <SelectInput label="resources.puentes.fields.apoyo_fijoCod" source="apoyo_fijo" disabled allowEmpty emptyValue="" validate={[required()]} fullWidth choices={choises2} className="align-number" formClassName={[classes.grid_cont4, classes.textToRight]} />
                <SelectInput label="resources.puentes.fields.apoyo_fijo" source="apoyo_fijo" allowEmpty emptyValue="" validate={[required()]} fullWidth choices={choises3} formClassName={classes.grid_cont4} />
                <SelectInput label="resources.puentes.fields.pila_movilCod" source="pila_movil" disabled allowEmpty emptyValue="" validate={[required()]} fullWidth choices={choises2} className="align-number" formClassName={[classes.grid_cont4, classes.textToRight]} />
                <SelectInput label="resources.puentes.fields.pila_movil" source="pila_movil" allowEmpty emptyValue="" validate={[required()]} fullWidth choices={choises3} formClassName={classes.grid_cont4} />
                <SelectInput label="resources.puentes.fields.pila_pilaCod" source="pila_pila" disabled allowEmpty emptyValue="" validate={[required()]} fullWidth choices={choises2} className="align-number" formClassName={[classes.grid_cont4, classes.textToRight]} />
                <SelectInput label="resources.puentes.fields.pila_pila" source="pila_pila" allowEmpty emptyValue="" validate={[required()]} fullWidth choices={choises3} formClassName={classes.grid_cont4} />
                <SelectInput label="resources.puentes.fields.apoyo_movilCod" source="apoyo_movil" disabled allowEmpty emptyValue="" validate={[required()]} fullWidth choices={choises2} className="align-number" formClassName={[classes.grid_cont4, classes.textToRight]} />
                <SelectInput label="resources.puentes.fields.apoyo_movil" source="apoyo_movil" allowEmpty emptyValue="" validate={[required()]} fullWidth choices={choises3} formClassName={classes.grid_cont4} />
                <SelectInput label="resources.puentes.fields.pila_vigasCod" source="pila_vigas" disabled allowEmpty emptyValue="" validate={[required()]} fullWidth choices={choises2} className="align-number" formClassName={[classes.grid_cont4, classes.textToRight]} />
                <SelectInput label="resources.puentes.fields.pila_vigas" source="pila_vigas" allowEmpty emptyValue="" validate={[required()]} fullWidth choices={choises3} formClassName={classes.grid_cont4} />
                <SelectInput label="resources.puentes.fields.movil_vigasCod" source="movil_vigas" disabled allowEmpty emptyValue="" validate={[required()]} fullWidth choices={choises2} className="align-number" formClassName={[classes.grid_cont4, classes.textToRight]} />
                <SelectInput label="resources.puentes.fields.movil_vigas" source="movil_vigas" allowEmpty emptyValue="" validate={[required()]} fullWidth choices={choises3} formClassName={classes.grid_cont4} />
              </SimpleFormIterator>
            </ArrayInput>
          }
          {tipo_elementos == '/tipo_elementos/PNTEP' &&
            <Typography variant="h3" component="h3">{translate('dynatest.resources.puentes.messagesVal.ElementoNoRequiereInfo')}</Typography>
          }
        </FormTab>
        <FormTab label="dynatest.resources.puentes.tabs.tabsTitle.reparaciones">
          <Reparaciones tipoElementos={tipo_elementos} />
        </FormTab>
        {/* DETALLES */}
        <FormTab label="dynatest.resources.puentes.tabs.tabsTitle.detalles">
          <ArrayInput className="estilos_array_puentes" label=" " fullWidth source="detalles" defaultValue={[
            {
              estado: null,
              mot_reemplazo: null,
              elemnto_remplazo: null,
              incluir: null,
              observaciones: null,
              estados: null
              
            }
          ]}>
            <SimpleFormIterator disableAdd disableRemove >

              <SelectInput label="resources.puentes.fields.estado" source="estado" choices={[
                { id: translate('dynatest.resources.puentes.select.estado.Existente'), name: "dynatest.resources.puentes.select.estado.Existente" },
                { id: translate('dynatest.resources.puentes.select.estado.Reemplazo'), name: "dynatest.resources.puentes.select.estado.Reemplazo" },
                { id: translate('dynatest.resources.puentes.select.estado.Nuevo'), name: "dynatest.resources.puentes.select.estado.Nuevo" },
              ]} validate={[required(), ValidarEstado]} fullWidth formClassName={classes.grid_cont4s} allowEmpty emptyValue="" />


              {estados === translate('dynatest.resources.puentes.select.estado.Reemplazo') &&
                <SelectInput label="resources.puentes.fields.mot_remplazo" source="mot_remplazo" choices={[
                  { id: translate('dynatest.resources.puentes.select.mot_remplazo.Accidente'), name: 'dynatest.resources.puentes.select.mot_remplazo.Accidente' },
                  { id: translate('dynatest.resources.puentes.select.mot_remplazo.ReemplazoProgramado'), name: 'dynatest.resources.puentes.select.mot_remplazo.ReemplazoProgramado' },
                  { id: translate('dynatest.resources.puentes.select.mot_remplazo.Vandalismo'), name: 'dynatest.resources.puentes.select.mot_remplazo.Vandalismo' },
                  { id: translate('dynatest.resources.puentes.select.mot_remplazo.Otro'), name: 'dynatest.resources.puentes.select.mot_remplazo.Otro' },
                ]} validate={[required()]} allowEmpty emptyValue="" fullWidth formClassName={classes.grid_cont4} />
              }

              {estados === translate('dynatest.resources.puentes.select.estado.Reemplazo') && 
                <ReferenceInput
                  label="resources.puentes.fields.elemnto_remplazo"
                  source="elemnto_remplazo"
                  reference="puentes"
                  filter={{
                    elementos: elemento,
                    unidad_funcional: unidads,
                    tramo: tramos,
                    segmento: segmentos
                  }}
                  fullWidth
                  validate={[required()]}
                  formClassName={classes.grid_cont4}
                >
                  <AutocompleteInput options={{ fullWidth: true }} optionText="fullCodigoConsecutivo" />
                </ReferenceInput>
              }
              <br />
              <SelectInput label="resources.puentes.fields.estados" source="estados" allowEmpty emptyValue="" choices={[
                { id: translate('dynatest.resources.puentes.select.estados.Activo'), name: "dynatest.resources.puentes.select.estados.Activo" },
                { id: translate('dynatest.resources.puentes.select.estados.Inactivo'), name: "dynatest.resources.puentes.select.estados.Inactivo" },
              ]} validate={[required()]} fullWidth formClassName={classes.grid_cont4s} />
              <br></br>

              <DateInput label="resources.puentes.fields.fecha" source="fecha" validate={[isFechaInvalida]} fullWidth formClassName={classes.grid_cont4s} />

              <ReferenceInput
                label="resources.puentes.fields.proveedor"
                source="proveedor"
                reference="proveedors"
                filter={{ sistema: 'PTS' }}
                sort={{ field: 'razonSocial', order: 'ASC' }}
                filterToQuery={searchText => ({ razonSocial: searchText })}
                validate={[required()]}
                fullWidth formClassName={classes.grid_cont4}
              >
                <AutocompleteInput options={{ fullWidth: true }} optionText="razonSocial" />
              </ReferenceInput>

              <NumberInput label="resources.puentes.fields.garantia" source="garantia" fullWidth validate={[required(), minValue(0), maxValue(99999999), validarEnteroNT]} formClassName={classes.grid_cont4} />

              <BooleanInput label="dynatest.resources.puentes.boolean.mantenimiento" source="mantenimiento" allowEmpty emptyValue="" fullWidth />


              <TextInput label="resources.puentes.fields.observaciones" source="observaciones" validate={[required(), minLength(1), maxLength(200)]} fullWidth allowEmpty emptyValue="" />

            </SimpleFormIterator>
          </ArrayInput>
          <TextInput source="createdIn" style={{ display: 'none' }} defaultValue={"api"} />
          <TextInput source="updatedIn" style={{ display: 'none' }} defaultValue={"api"} />
        </FormTab>
        <FormTab label="dynatest.resources.puentes.tabs.tabsTitle.fotografias">
          <ImagenesInventario type='create' defaultTipoImagen={defaultTipoImagen} />
        </FormTab>
      </TabbedForm>
    </PimsCreate>
  )
}));
