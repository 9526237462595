import React from 'react';
import { TextInput, SimpleForm, NumberInput} from 'react-admin';

import { withStyles } from '@material-ui/core';
import { PimsCreate } from './../../../Create';

import { required } from 'react-admin';
    
const styles = {
    first_field: { display: 'inline-block', width: '30%' },
    last_field: { display: 'inline-block', marginLeft: 32, width: '30%' }    
};

export const FisuraCreate = withStyles(styles)(({ classes, ...props }) => (
        <PimsCreate {...props} title="Crear fisura">
            <SimpleForm redirect="list">
                <TextInput source="tipo" label="Fisura" validate={[required()]}/>                      
                <NumberInput source="factor" label="Factor" validate={[required()]}/>
            </SimpleForm>
        </PimsCreate>
));