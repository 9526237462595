export default {
    ra: {
        action: {
            delete: 'Borrar',
            show: 'Mostrar',
            list: 'Listar',
            save: 'Guardar',
            create: 'Crear',
            edit: 'Editar',
            sort: 'Ordenar',
            cancel: 'Cancelar',
            undo: 'Deshacer',
            refresh: 'Refrescar',
            add: 'Agregar',
            remove: 'Remover',
            add_filter: 'Agregar filtro',
            remove_filter: 'Remover este filtro',
            back: 'Regresar',
            bulk_actions: '%{smart_count} seleccionado',
            confirm:'Confirmar',
            save_add: 'Guardar y agregar',
            save_end: 'Guardar y finalizar',
            clear_input_value: 'Limpiar valor',
            clone: 'Clonar',
            create_item: 'Crear %{item}',
            export: 'Exportar',
            search: 'Buscar',
            unselect: 'Deseleccionar',
            expand: 'Expandir',
            close: 'Cerrar',
            open_menu: 'Abrir menú',
            close_menu: 'Cerrar menú',
            update: 'Actualizar',
            image: 'Imagen',
            carouselImage: 'GALERÍA',
            imageActuales: 'Imágenes actuales',
            imageEliminar: 'ELIMINAR',
          },
          boolean: {
            true: 'Si',
            false: 'No',
            null: ' ',
          },
          page: {
            list: 'Lista de %{name}',
            edit: '%{name} #%{id}',
            show: '%{name} #%{id}',
            create: 'Crear %{name}',
            dashboard: 'Dashboard',
            not_found: 'No encontrado',
            loading: 'Cargando',
            invite: '¿Quieres añadir uno?',
          },
          input: {
            file: {
              upload_several:
                             'Arrastra algunos archivos para empezar la subida, o presiona aquí para seleccionarlos.',
              upload_single: 'Arrastra un archivo para empezar la subida, o presiona aquí para seleccionarlo.',
            },
            image: {
              upload_several:
                             'Arrastra algunas imágenes para empezar la subida, o presiona aquí para seleccionar una.',
              upload_single:
                            'Arrastra una imagen aquí para empezar la subida, o presiona aqui para seleccionarla.',
            },
            references: {
              all_missing: 'No se encontró ninguna referencia.',
              many_missing:
                           'Al menos una de las referencias asociadas parece ya no estar disponible.',
              single_missing:
                             'La referencia asociada parece ya no estar disponible.',
            },
            password: {
              toggle_visible: 'Ocultar contraseña',
              toggle_hidden: 'Mostrar contraseña',
          },
          },
          message: {
            yes: 'Si',
            no: 'No',
            are_you_sure: '¿Estás seguro?',
            about: 'Acerca de',
            not_found:
                      'Ha escrito una URL incorrecta o ha seguido un enlace incorrecto.',
            loading: 'La página esta cargando, espera un momento por favor',
            invalid_form: 'El formulario no es válido. Por favor busca por errores',
            delete_title: 'Borrar %{name} #%{id}',
            delete_content: '¿Estás seguro que quieres borrar este elemento?',
            bulk_delete_title:
                              'Borrar %{name} |||| Borrar %{smart_count} %{name} elementos',
            bulk_delete_content:
                                '¿Estás seguro de que quieres borrar este %{name}? |||| ¿Estás seguro de que quieres borrar estos %{smart_count} elementos?',
          },
          navigation: {
            no_results: 'No se encontraron resultados',
            no_more_results:
                            'La página número %{page} esta fuera de los límites. Prueba con la página anterior.',
            page_out_of_boundaries: 'La página número %{page} se encuentra fuera de los límites',
            page_out_from_end: 'No se puede navegar después de la última página',
            page_out_from_begin: 'No se puede navegar antes de la página 1',
            page_range_info: '%{offsetBegin}-%{offsetEnd} de %{total}',
            page_rows_per_page: 'Filas por página:',
            next: 'Siguiente',
            prev: 'Anterior',
          },
          auth: {
            username: 'Usuario',
            password: 'Contraseña',
            sign_in: 'Loguearse',
            sign_in_error: 'Error de autenticación, por favor reintenta',
            logout: 'Cerrar sesión',
          },
          notification: {
            updated: 'Elemento actualizado |||| %{smart_count} elementos actualizados',
            created: 'Elemento creado',
            deleted: 'Elemento borrado |||| %{smart_count} elementos borrados',
            bad_item: 'Elemento incorrecto',
            item_doesnt_exist: 'El elemento no existe',
            http_error: 'Error de comunicación con el servidor',
            canceled: 'Acción cancelada',
          },
          validation: {
            required: 'Requerido',
            minLength: 'Debe tener al menos %{min} carácteres',
            maxLength: 'Debe tener %{max} carácteres o menos',
            minValue: 'Debe ser al menos %{min}',
            maxValue: 'Debe ser %{max} o menos',
            number: 'Debe ser un número',
            email: 'Debe ser un correo electrónico válido',
            oneOf: 'Debe ser uno de los siguientes valores: %{options}',
            regex: 'Debe seguir un formato específico (regexp): %{pattern}',
          },
    },
    resources: {
        inicio:{
          titleProyecto:'Selección de proyectos',
          mensaje:'La página esta cargando, espere un momento por favor',
          seleccionar:'Seleccionar',
        },
        usuarios: {
            name: 'Usuario |||| Usuarios',
            fields: {
                nombres: 'Nombres',
                apellidos: 'Apellidos',
                tipo_documento: 'Tipo de documento',
                identificacion: 'Número de identificación',
                admin: 'Rol',
                password: 'Contraseña',
                plainPassword: 'Confirmar contraseña',
                idioma: 'Idioma',
                createdAt: 'Creado el',
                createdBy: 'Creado por',
                updatedAt: 'Actualizado el',
                updatedBy: 'Actualizado por',
                sections:{
                  tipo_rol: 'Tipo de rol',
                  usuarios: 'Usuarios',
                  perfiles: 'Perfiles',
                  asignar_clave: 'Asignar Clave',
                  proyectos: 'Proyectos',
                  rutas: 'Rutas',
                  unidades_funcionales: 'Unidades funcionales',
                  tramos: 'Tramos',
                  segmentos: 'Segmentos',
                  materiales: 'Materiales',
                  granulometria: 'Granulometría',
                  parametros_evaluacion: 'Parámetros de Evaluación',
                  diagrama_colorimetrico: 'Diagrama Colorimétrico',
                  reparaciones: 'Reparaciones',
                  deterioros: 'Deterioros',
                  contacto_emergencia: 'Contacto de Emergencia',
                  proveedores: 'Provedores',
                  servicios_publicos: 'Servicios Públicos',
                  mapa: 'Mapa',
                  flexible: 'Flexible',
                  rigido: 'Rígido',
                  adoquin: 'Adoquín',
                  otro: 'Otro',
                  sistemas_contencion: 'Sistema de Contención',
                  drenaje: 'Drenaje',
                  señalizacion:  'Señalización',
                  alrededores:  'Alrededores',
                  otros_elementos: 'Otros Elementos',
                  redes: 'Redes',
                  puentes: 'Puentes',
                  contencion: 'Contención',
                  ponton: 'Pontón',
                  tuneles: 'Túneles',
                  inventario_peajes: 'Inventario Peajes',
                  transito_historico: 'Tránsito Histórico',
                  inv_est_conteo: 'Inventario Estación de conteo',
                  indicadores_4g: 'Indicadores 4G',
                  datos_basicos: 'Datos básicos',
                  deterioro: 'Deterioro',
                  calificacion_puentes: 'Calificación de puentes',
                  calificacion_pontones: 'Calificación de pontones',
                  disp_vias: 'Disponibilidad de Vías',
                  cola_peaje: 'Cola de peaje',
                  parametros_planeacion: 'Parámetros de planeación',
                  politica_general: 'Políticas generales',
                  politica_particular: 'Políticas particulares',
                  alternativa_intervencion: 'Alternativa de Intervención',
                  repa_menores: 'Reparaciones Menores',
                  exportar_pers: 'Exportar base pers',
                  reportes_pavimentos: 'Importar base PERS',
                  flujo_caja: 'Flujo de caja',
                  idioma: 'Idioma',
                  sistema: 'Sistema',
                  indicadorComplementario: 'Indicador complementario',
                  novedades: 'Novedades',
                  cargaMasiva: 'Carga masiva',
                  complementarios: 'Complementarios',
                  costo: 'Registro de Costos',
                  respuestaEvaluacionNovedad: 'Novedades',
                  aprobacion: 'Aprobación'
                }
            }
        },
        atributos:{
          name: 'Atributo |||| Atributos',
          fields: {
            nombre: 'Roles',
            tipoRol: 'Tipo de rol',
            usuario: 'Usuarios',
            perfiles: 'Perfiles',
            asigclaves: 'Asignar clave',
            proyecto: 'Proyectos',
            rutas: 'Rutas',
            unifuncionales: 'Unidades Funcionales',
            tramos: 'Tramos',
            segmento: 'Segmentos',
            materiales: 'Materiales',
            ganulometria: 'Granulometría',
            parametrosEvaluacion: 'Parámetros de Evaluación',
            diagramaColorimetrico: 'Diagrama Colorimétrico',
            reparaciones: 'Reparaciones',
            deterioros: 'Deterioros',
            contemergencia: 'Contactos de Emergencia',
            proveedores: 'Proveedores',
            serpublicos: 'Servicios Públicos',
            mapa: 'Mapa',
            flexible: 'Flexible',
            rigido: 'Rígido',
            adoquin: 'Adoquín',
            otro: 'Otro',
            seguridad: 'Sistemas de Contención',
            drenaje: 'Drenaje',
            senalizacion: 'Señalización',
            alrededores: 'Alrededores',
            otroElemento: 'Otros Elementos',
            redes: 'Redes',
            puentes: 'Puentes',
            contencion: 'Contención',
            ponton: 'Ponton',
            tunel: 'Túneles',
            inventario: 'Inventario Peajes',
            transitoMensual: 'Tránsito Histórico',
            inventarioa: 'Inventario Estación de conteo',
            indicadores4g: 'Indicadores 4G',
            datosBasicos: 'Datos básicos',
            colaDePeaje: 'Deterioro',
            calificacionPuentes: 'Calificación Puentes',
            calificacionPontones: 'Calificación Pontones',
            disponibilidadVia: 'Disponibilidad de Vías',
            colaDePeajea: 'Cola de Peaje',
            parametrosAnalisis: 'Parámetros de Planeación',
            politicaGeneral:'Políticas Generales',
            politicaParticular:'Políticas Particulares',
            alternativaIntervencion: 'Alternativa de Intervención',
            reparacionesMenores: 'Reparaciones Menores',
            exportarPers: 'Exportar base PERS',
            reportesPers: 'Importar base PERS',
            flujoCaja: 'Flujo de Caja',
            reportesConfig: 'Configuración Reportes',
            reportesPersQ: 'Reportes PERS',
            reportesInventarioQ: 'Reportes Inventario',
            reportesIndicadoresQ: 'Reportes Indicadores',
            reportesCostosQ: 'Reportes Costos',
            reportesTransitoQ: 'Reportes Tránsito',
            reportesContactosQ: 'Otros Reportes',
            reportesModelacionQ: 'Reportes Modelación',
            createdAt: 'Creado el',
            createdBy: 'Creado por',
            updatedAt: 'Actualizado el',
            updatedBy: 'Actualizado por',
            novedades: 'Novedades',
            configSistema: 'Sistema',
            audit: 'Auditoría',
            costo: 'Registro de Costos'
          }
        },
        proyectos:{
          name: 'Proyecto |||| Proyectos',
          fields: {
            codigo: 'Código proyecto',
            nombre: 'Nombre',
            licencia: 'Licencia',
            concesionario: 'Concesionario',
            latitud: 'Latitud',
            longitud: 'Longitud',
            alturaIni: 'Altura mínima (m.s.n.m)',
            alturaFin: 'Altura máxima (m.s.n.m)',
            ubicaciones: 'Agregar ubicacación',
            proyecto: 'Proyecto',
            createdAt: 'Creado el',
            createdBy: 'Creado por',
            updatedAt: 'Actualizado el',
            updatedBy: 'Actualizado por',
            uniFuncionalesProyec: 'Unidades Funcionales del proyecto',
            archivoKml: 'Archivo .kml',
            ArchCargadoUniFuncional: 'Archivo(s) cargado(s) correctamente',
            descargarkml:'Descargar archivo KML',
            noSubido:'Archivo KML no procesado',
            cargarArchivo: 'Cargar Archivo',
          }

        },
        rutas:{
          name: 'Ruta |||| Rutas',
          fields:{
            proyecto: 'Proyecto',
            codigo: 'Código',
            categoriaRuta: 'Categoría',
            pInicio: 'PR inicio',
            pFin: 'PR fin',
            tramo: 'Tramo',
            sector: 'Sector',
            origen: 'Origen',
            destino: 'Destino',
            createdAt: 'Creado el',
            createdBy: 'Creado por',
            updatedAt: 'Actualizado el',
            updatedBy: 'Actualizado por',
          }
        },
        unidad_funcionals:{
          name: 'Unidad Funcional |||| Unidades funcionales',
          fields:{
            ruta: 'Ruta',
            codigo: 'Código',
            abInicio: 'Abscisa inicio',
            abFin: 'Abscisa fin',
            pInicio: 'PR inicio', 
            pFin: 'PR fin',
            fullResta: 'Longitud (km)',
            createdAt: 'Creado el',
            createdBy: 'Creado por',
            updatedAt: 'Actualizado el',
            updatedBy: 'Actualizado por',
          }
        },
        tramos:{
          name: 'Tramo |||| Tramos',
          fields:{
            unidadFuncional:'Unidad Funcional',
            codigo: 'Código Tramo',
            abInicio: 'Abscisa inicial',
            abFin: 'Abscisa final',
            pInicio: 'PR inicio',
            pFin: 'PR fin',
            inicio: 'Inicio',
            final: 'Final',
            fullResta: 'Longitud (km)',
            createdAt: 'Creado el',
            createdBy: 'Creado por',
            updatedAt: 'Actualizado el',
            updatedBy: 'Actualizado por',
          }
        },
        segmentos:{
          name: 'Segmento |||| Segmentos',
          fields:{
            tipoElemento:'Tipo',
            segmentoId: 'Segmento',
            codigo:'Código',
            identificacionRamal:'Identificación del ramal',
            codigoRR:'Código',
            absSgm:'Abscisa del segmento',
            lado:'Lado',
            abInicio:'Abscisa inicial',
            abFin:'Abscisa final',
            pInicio:'PR Inicio',
            pFin:'PR Fin',
            departamento:'Departamento',
            municipio:'Municipio',
            latitud:'Latitud',
            longitud:'Longitud',
            altura:'Altura (m.s.n.m)',
            ubicaciones:'Ubicaciones',
            tipoCalzada:'Tipo Calzada',
            calzadaCodigo:'Nombre calzada',
            tipoSegmento:'Tipo Segmento',
            tipoSuperficie:'Tipo Superficie',
            estado:'Estado',
            unidadFuncional:'Unidad Funcional',
            tramo:'Tramo',
            fullResta:'Longitud (km)',
            createdAt: 'Creado el',
            createdBy: 'Creado por',
            updatedAt: 'Actualizado el',
            updatedBy: 'Actualizado por',
          }
        },
        materials: {
            name: 'Información Materiales',
            editar:'Editar Materiales',
            fields: {
                material: 'Código de material',
                tipo: 'Tipo',
                comportamiento: 'Comportamiento',
                especificacion: 'Especificación',
                refmodulus: 'Módulo teórico (MPa)',
                minmodulus: 'Módulo mínimo (MPa)',
                stdfactor: 'Desviación estandar',
                poissons: 'Módulo de poisson',
                observaciones: 'Observaciones',
                createdAt: 'Creado el',
                createdBy: 'Creado por',
                updatedAt: 'Actualizado el',
                updatedBy: 'Actualizado por',
                materialCreate: 'Material'
            }
        },
        granulometrias:{
          name: 'Granulomentría |||| Granulometrías',
          fields:{
            tipo:'Tipo de material',
            granulometria: 'Granulometría',
            especificacion: 'Especificación',
            createdAt: 'Creado el',
            createdBy: 'Creado por',
            updatedAt: 'Actualizado el',
            updatedBy: 'Actualizado por',
          }
        },
        generacion_masiva_segmentos:{
          name: 'Generación Masiva de Segmento |||| Generación Masiva de Segmentos',
          fields:{
            unidadFuncional: 'Unidad Funcional',
            tramo: 'Tramo',
            absSegIni: 'Abscisa inicial del segmento',
            absSegFin: 'Abscisa final del segmento',
            longTotal: 'Longitud total',
            latitud: 'Latitud',
            longitud:  'Longitud',
            altura: 'Altura (m.s.n.m)',
            tipoCalzada: 'Tipo Calzada',
            tipoSegmento: 'Tipo Segmento',
            tipoSuperficie: 'Tipo Superficie',
          }
        },
        transformacion_segmentos: {
          name: 'Transformación segmento |||| Transformación segmento',
          fields: {
            unidadFuncional: 'Unidad Funcional',
            tramo: 'Tramo',
            absSegIni: 'Abscisa inicial del segmento',
            absSegFin: 'Abscisa final del segmento',
            longTotal: 'Longitud total',
          }
        },
        colores:{
          name: 'Color |||| Colores',
          fields:{
            color: 'Color',
            valorA: 'Valor A',
            valorB: 'Valor B',
            valorMin: 'Valor mínimo de Cumplimiento',
            createdAt: 'Creado el',
            createdBy: 'Creado por',
            updatedAt: 'Actualizado el',
            updatedBy: 'Actualizado por',
          }
        },
        parametros_evaluacion:{
          name: 'Parámetros de evaluación |||| Parámetros de evaluaciones',
          fields:{
            valorAlerta_: 'Valor de alerta',
            datosminModelacion_: 'Datos mínimos para modelación',
            tiempoAntesAlertaMedicion_: '¿Cuánto tiempo antes generar alerta de medición? (meses)',
            proyectoNombre: 'Proyecto',
            indicadores: 'Indicadores que apliquen a: ',
            titleParametros: 'Parámetros de Evaluación',
            proyecto: 'Proyecto Nombre',
          }
        },  
        politicas_mantenimientos: {
            name: 'Política de mantenimientos |||| Politica de mantenimientos',
            fields: {
                seRealiza : 'Se realiza',
                nombreElemento: 'Nombre elemento',
                tipo : 'Tipo',
                nombre : "Nombre de política de mantenimiero",
                sistema : 'Sistema',
            }
        },
        idiomas: {
            name: 'Idioma |||| Idiomas',
            idiomaActual:'Idioma actual: ',
            fields: {
                idioma: 'Idioma',
                region: 'Región',
                nombreIdioma: 'Nombre de idioma adicionado',
                manual_usuario:'Manual del usuario',
                modulo_administracion: 'Módulo de administración',
                modulo_inventario:'Módulo de inventario',
                estado: 'Estado',
                eliminar: 'Eliminar',
                cargueIdioma: 'Cargue de idioma',
                descargar:'Descargar',
                descargar_manual_usuario: 'Descargar manual de usuario',
                informacionIdioma: 'Información del idioma',
                auditoria: 'Auditoría',
                createdAt: 'Creado el',
                createdBy: 'Creado por',
                updatedAt: 'Actualizado el',
                updatedBy: 'Actualizado por'          
             }
        },
        reparacion_localizadas:{
          name: 'Reparacion PERS |||| Reparaciones PERS',
            fields: {
              tipoReparacion: 'Tipo de reparación',
              nombre: 'Nombre',
              unidad: 'Unidad',
              costo: 'Costo',
              unidadPlaca:'$/losa',
              observaciones: 'Observaciones',
              createdAt: 'Fecha creación',
              createdBy: 'Creado por',
              updatedAt: 'Fecha actualización',
              updatedBy: 'Actualizado por'
            }
        },
        tipo_deterioro_pers:{
          name: 'Deterioro PERS |||| Deterioros PERS',
          fields: {
            tipoSuperficie: 'Tipo de Pavimento',
            codigoAstm: 'Código ASTM',
            nivelSeveridad: 'Niveles de severidad',
            estado:'Estado',
            nombre: 'Nombre',
            unidad: 'Unidad',
            losa:'losa',
            tipoReparacionMedia: 'Tipo de reparaciones severidad media',
            tipoReparacionAlta: 'Tipo de reparaciones severidad alta',
            createdAt: 'Fecha creación',
            createdBy: 'Creado por',
            updatedAt: 'Fecha actualización',
            updatedBy: 'Actualizado por'
          }
        },
        contacto_emergencias:{
          name: 'Contacto de Emergencia |||| Contactos de Emergencia',
          fields: {
            proyecto: 'Proyecto',
            grupo: 'Grupo',
            numero_identificacion: 'Número de Identificación',
            nivel: 'Nivel',
            identificacion: 'Tipo de identificación',
            empresa: 'Empresa',
            nombre: 'Nombres',
            apellido: 'Apellidos',
            cargo: 'Cargo',
            direccion: 'Dirección',
            departamento: 'Departamento',
            municipio: 'Municipio',
            municipio1: 'Municipio',
            municipio2: 'Municipio',
            municipio3: 'Municipio',
            municipio4: 'Municipio',
            municipio5: 'Municipio',
            municipio6: 'Municipio',
            municipio7: 'Municipio',
            municipio8: 'Municipio',
            municipio9: 'Municipio',
            municipio10: 'Municipio',
            municipio11: 'Municipio',
            municipio12: 'Municipio',
            municipio13: 'Municipio',
            municipio14: 'Municipio',
            municipio15: 'Municipio',
            municipio16: 'Municipio',
            municipio17: 'Municipio',
            municipio18: 'Municipio',
            municipio19: 'Municipio',
            municipio20: 'Municipio',
            municipio21: 'Municipio',
            municipio22: 'Municipio',
            municipio23: 'Municipio',
            municipio24: 'Municipio',
            municipio25: 'Municipio',
            municipio26: 'Municipio',
            municipio27: 'Municipio',
            municipio28: 'Municipio',
            municipio29: 'Municipio',
            municipio30: 'Municipio',
            municipio31: 'Municipio',
            municipio32: 'Municipio',
            municipio33: 'Municipio',
            telefono_1: 'Teléfono 1',
            telefono_2: 'Teléfono 2',
            correo: 'Correo electrónico',
            observaciones: 'Observaciones',
            createdAt: 'Fecha creación',
            createdBy: 'Creado por',
            updatedAt: 'Fecha actualización',
            updatedBy: 'Actualizado por'
          },
        },
        proveedors:{
          name: 'Proveedor |||| Proveedores',
          fields:{
            proyecto: 'Proyecto',
            sistema: 'Sistema',
            nombreSistema: 'Nombre sistema',
            sistemaNombre: 'Sistema',
            identificacion: 'Tipo de identificación',
            numeroIdentificacion: 'Número de identificación',
            razonSocial: 'Empresa',
            nombre: 'Nombres',
            apellido: 'Apellidos',
            direccion: 'Dirección',
            departamento: 'Departamento',
            municipio: 'Municipio',
            municipio1: 'Municipio',
            telefono: 'Teléfono 1',
            telefono2: 'Teléfono 2',
            correo: 'Correo electrónico',
            observaciones: 'Observaciones',
            createdAt: 'Fecha creación',
            createdBy: 'Creado por',
            updatedAt: 'Fecha actualización',
            updatedBy: 'Actualizado por',
            calificacion: 'Calificación',
            fechaCalificacion: 'Fecha de calificación',
            usuario_id: 'Usuario',
            promedioCal: 'Promedio'
          }
        },
        calificacion_proveedors: {
          fields: {
            proveedor: 'Proveedor',
            calificacion: 'Calificación',
            fechaCalificacion: 'Fecha de calificación',
            usuario_id: 'Usario'
          }
        },
        servicios_publicos: {
          name: 'Servicio público |||| Servicios públicos',
          fileds: {
            proyecto: 'Proyecto',
            grupo: 'Grupo',
            identificacion: 'Tipo de Identificación',
            numero_identificacion: 'Número de identificación',
            empresa: 'Empresa',
            nombre: 'Nombres',
            apellido: 'Apellido',
            direccion: 'Dirección',
            departamento: 'Departamento',
            municipio1: 'Municipio',
            municipio: 'Municipio',
            municipios: 'Municipios',
            telefono_1: 'Teléfono 1',
            telefono_2: 'Teléfono 2',
            correo: 'Correo electrónico',
            observaciones: 'Observaciones',
            createdAt: 'Fecha creación',
            createdBy: 'Creado por',
            updatedAt: 'Fecha actualización',
            updatedBy: 'Actualizado por',
          }
        },
        elementos: {
          name: 'Mapa general |||| Mapas generales',
          fields: {
            codigo:'Código',
            elemento:'Elemento',
            unidadFuncional: 'Unidad Funcional',
            tramo: 'Tramo',
            segmento: 'Segmento',
            absInicial:'Abscisa inicio',
            verMas:'Ver más',
            tipoMapa: 'Función del Mapa',
            tipoElementoSeguridad: 'Tipo de Elemento',
            tipoElementoDrenaje: 'Tipo de Elemento',
            tipoElementoSenal: 'Tipo de Señal',
            tipoSenal: 'Tipo de Elemento',
            tipoSenalVertical: 'Tipo Modelo es',
            tipoElementoAlrededor: 'Tipo de Elemento',
            tipoPuente: 'Tipo de Puente',
            tipoElementoOtro: 'Tipo de Elemento',
            tipoElementoRed: 'Tipo de Elemento',
            numIntervalos: 'N° de intervalos',
            umbral: 'Umbral ',
          }
        },
        flexible_detalles: {
          name: 'Segmento Flexible |||| Segmentos Flexibles',
          fields: {
            unidadFuncional: 'Unidad Funcional',
            tramo: 'Tramo',
            segmento: 'Segmento',
            sentido: 'Sentido',
            estadoDetalles: 'Estado',
            estadoRazon: 'Razón',
            dateInicioOperacion: 'Fecha de Inicio Operación',
            proveedor: 'Proveedor',
            garantiaMeses: 'Garantía (meses)',
            trafico: 'Tráfico',
            esalTime: 'ESAL (Año)',
            nivelTrafico: 'Nivel de tráfico',
            nivelTraficoDetalles: 'Nivel de Tráfico',
            esalTime10: 'ESAL (Proyección 10 Años)',
            esalTime20: 'ESAL (Proyección 20 Años)',
            detallesObservaciones: 'Observaciones',
            detallesCalzada: 'Detalles de Calzada',
            geometriaLargo: 'Largo (m)',
            geometriaAncho: 'Ancho de la Calzada (m)',
            geometriaNumCarriles: 'Número de Carriles',
            geometriaAnchoCarril: 'Ancho por Carril (m)',
            geometriaTipoBerma: 'Tipo de Berma',
            geometriaAnchoBerma: 'Ancho de Berma (m)',
            geometriaCondicionBerma: 'Condición de Berma',
            aux1: 'Área de Carriles (m²)',
            areaCarrilesVerdadera: 'Área Verdadera Carriles (m²)',
            geometriaLongitudinal: 'Pendiente Longitudinal',
            geometriaLongitudinalMa: 'Pendiente Longitudinal máxima',
            geometriaLongitudinalMi: 'Pendiente Longitudinal mínima',
            geometriaTransversal: 'Pendiente Transversal',
            geometriaRadioMi: 'Radio Mínimo (m)',
            geometriaRadioMa: 'Radio Máximo (m)',
            geometriaRadioCurvatura: 'Radio de Curvatura (°/km)',
            geometriaVelDiseno: 'Velocidad de Diseño (km/h)',
            geometriaSeparador: 'Con Separador',
            geometriaSeparadorAncho: 'Ancho Separador (m)',
            geometriaCondicionesGeometrica: 'Condiciones Geométricas',
            geometriaAlineamiento: 'Alineamiento',
            geometriaTipoTerreno: 'Tipo de Terreno',
            detallesGeometria: 'Observaciones',
            informacionEstructura: 'Información Estructura',
            propiedadesGeometricas: 'Propiedades geométricas',
            detallesCarriles: 'Detalles de Carriles',
            estructuraEspesor: 'Espesor (mm)',
            estructuraModulo: 'Módulo (MPa)',
            estructuraDs: 'Desviación Estándar (Std)',
            material: 'Material',
            materialEsp: 'Especificación',
            granulometria: 'Granulometría',
            espesorTotal: 'Espesor total (mm)',
            igg: 'Índice de Gavidade Global (IGG)',
            iggEstructura: 'Índice de Gavidade Global',
            conceito: 'Conceito',
            capacidadEstructural: 'Capacidad estructural',
            estructuraNumDiseno: 'Número Estructural de Diseño',
            estructuraFecha: 'Fecha',
            estructuraNumero: 'Número Estructural Efectivo',
            estructuraNumeroFecha: 'Fecha',
            estructuraCbr: 'CBR (%)',
            estructuraCbrFecha: 'Fecha',
            estructuraFechaConst: 'Fecha de Construcción',
            estructuraFechaRehab: 'Fecha de Última Rehabilitación',
            detallesEstructura: 'Observaciones',
            fechasImportantes: 'Fechas importantes',
            smartCode: 'Código',
            absInicio: 'Abscisa Inicial',
            absFin: 'Abscisa Final',
            longitudShow: 'Longitud (km)',
            prInicio: 'PR Inicio',
            prFin: 'PR Fin',
            departamento: 'Departamento',
            municipio: 'Municipio',
            latitud: 'Latitud',
            longitud: 'Longitud',
            altura: 'Altura (m.s.n.m)',
            createdAt: 'Fecha Creación',
            createdBy: 'Creado Por',
            updatedAt: 'Fecha Actualización',
            updatedBy: 'Actualizado Por',
            createdEn: 'Creado en',
            updatedEn: 'Actualizado en',
            localizacion: 'Localización',
            coordenadasIniciales: 'Coordenadas Iniciales',
            coordenadasFinales: 'Coordenadas Finales'
          }
        },
        rigido_detalles: {
          name: 'Segmento Rígido |||| Segmentos Rígidos',
          fields: {
            unidadFuncional: 'Unidad Funcional',
            tramo: 'Tramo',
            segmento: 'Segmento',
            sentido: 'Sentido',
            estadoDetalles: 'Estado',
            estadoRazon: 'Razón',
            dateInicioOperacion: 'Fecha de Inicio Operación',
            proveedor: 'Proveedor',
            garantiaMeses: 'Garantía (meses)',
            trafico: 'Tráfico',
            esalTime: 'ESAL (Año)',
            nivelTrafico: 'Nivel de tráfico',
            nivelTraficoDetalles: 'Nivel de Tráfico',
            esalTime10: 'ESAL (Proyección 10 Años)',
            esalTime20: 'ESAL (Proyección 20 Años)',
            detallesObservaciones: 'Observaciones',
            detallesCalzada: 'Detalles de Calzada',
            geometriaLargo: 'Largo (m)',
            geometriaAncho: 'Ancho de la Calzada (m)',
            geometriaNumCarriles: 'Número de Carriles',
            geometriaAnchoCarril: 'Ancho por Carril (m)',
            geometriaTipoBerma: 'Tipo de Berma',
            geometriaAnchoBerma: 'Ancho de Berma (m)',
            geometriaCondicionBerma: 'Condición de Berma',
            aux1: 'Área de Carriles (m²)',
            areaCarrilesVerdadera: 'Área Verdadera Carriles (m²)',
            geometriaLongitudinal: 'Pendiente Longitudinal',
            geometriaLongitudinalMa: 'Pendiente Longitudinal Máxima',
            geometriaLongitudinalMi: 'Pendiente Longitudinal Mínima',
            geometriaTransversal: 'Pendiente Transversal',
            geometriaRadioMi: 'Radio Mínimo (m)',
            geometriaRadioMa: 'Radio Máximo (m)',
            geometriaRadioCurvatura: 'Radio de Curvatura (°/km)',
            geometriaVelDiseno: 'Velocidad de Diseño (km/h)',
            geometriaSeparador: 'Con Separador',
            geometriaSeparadorAncho: 'Ancho Separador (m)',
            geometriaCondicionesGeometrica: 'Condiciones Geométricas',
            geometriaAlineamiento: 'Alineamiento',
            geometriaTipoTerreno: 'Tipo de Terreno',
            dimensionesLosaTipica: 'Dimensiones de la losa típica',
            geometriaDltLargo: 'Largo (m)',
            geometriaDltAncho: 'Ancho (m)',
            geometria_dlt_area: 'Área (m2)',
            geometriaDltNumlosas: 'No. De Losas',
            geometriaDltLongjuntas: 'Longitud de Juntas (m)',
            detallesGeometria: 'Observaciones',
            informacionEstructura: 'Información Estructura',
            propiedadesGeometricas: 'Propiedades geométricas',
            detallesCarriles: 'Detalles de Carriles',
            estructuraEspesor: 'Espesor (mm)',
            estructuraModulo: 'Módulo (MPa)',
            estructuraDs: 'Desviación Estándar (Std)',
            material: 'Material',
            materialEsp: 'Especificación',
            granulometria: 'Granulometría',
            espesorTotal: 'Espesor total (mm)',
            igg: 'Índice de Gavidade Global (IGG)',
            iggEstructura: 'Índice de Gavidade Global',
            conceito: 'Conceito',
            capacidadEstructural: 'Capacidad estructural',
            estructuraNumDiseno: 'Número Estructural de Diseño',
            estructuraFecha: 'Fecha',
            estructuraNumero: 'Número Estructural Efectivo',
            estructuraNumeroFecha: 'Fecha',
            estructuraCbr: 'CBR (%)',
            estructuraCbrFecha: 'Fecha',
            estructuraVacios: 'Vacíos (%)',
            kjoint: 'k-joint (kPa/mm)',
            jsdf: 'SDF',
            kcenter: 'k-center (kPa/mm)',
            csdf: 'SDF',
            estructuraFechaConst: 'Fecha de Construcción',
            estructuraFechaRehab: 'Fecha de Última Rehabilitación',
            detallesEstructura: 'Observaciones',
            fechasImportantes: 'Fechas importantes',
            smartCode: 'Código',
            absInicio: 'Abscisa Inicial',
            absFin: 'Abscisa Final',
            longitudShow: 'Longitud (km)',
            prInicio: 'PR Inicio',
            prFin: 'PR Fin',
            departamento: 'Departamento',
            municipio: 'Municipio',
            latitud: 'Latitud',
            longitud: 'Longitud',
            altura: 'Altura (m.s.n.m)',
            createdAt: 'Fecha Creación',
            createdBy: 'Creado Por',
            updatedAt: 'Fecha Actualización',
            updatedBy: 'Actualizado Por',
            createdEn: 'Creado en',
            updatedEn: 'Actualizado en',
            localizacion: 'Localización',
            coordenadasIniciales: 'Coordenadas Iniciales',
            coordenadasFinales: 'Coordenadas Finales'
          }
        },
        adoquin_detalles: {
          name: 'Segmento Adoquín |||| Segmentos Adoquínes',
          fields: {
            unidadFuncional: 'Unidad Funcional',
            tramo: 'Tramo',
            segmento: 'Segmento',
            sentido: 'Sentido',
            estadoDetalles: 'Estado',
            estadoRazon: 'Razón',
            dateInicioOperacion: 'Fecha de Inicio Operación',
            proveedor: 'Proveedor',
            garantiaMeses: 'Garantía (meses)',
            trafico: 'Tráfico',
            esalTime: 'ESAL (Año)',
            nivelTrafico: 'Nivel de tráfico',
            nivelTraficoDetalles: 'Nivel de Tráfico',
            esalTime10: 'ESAL (Proyección 10 Años)',
            esalTime20: 'ESAL (Proyección 20 Años)',
            detallesObservaciones: 'Observaciones',
            detallesCalzada: 'Detalles de Calzada',
            geometriaLargo: 'Largo (m)',
            geometriaAncho: 'Ancho de la Calzada (m)',
            geometriaNumCarriles: 'Número de Carriles',
            geometriaAnchoCarril: 'Ancho por Carril (m)',
            geometriaTipoBerma: 'Tipo de Berma',
            geometriaAnchoBerma: 'Ancho de Berma (m)',
            geometriaCondicionBerma: 'Condición de Berma',
            aux1: 'Área de Carriles (m²)',
            areaCarrilesVerdadera: 'Área Verdadera Carriles (m²)',
            geometriaLongitudinal: 'Pendiente Longitudinal',
            geometriaLongitudinalMa: 'Pendiente Longitudinal Máxima',
            geometriaLongitudinalMi: 'Pendiente Longitudinal Mínima',
            geometriaTransversal: 'Pendiente Transversal',
            geometriaRadioMi: 'Radio Mínimo (m)',
            geometriaRadioMa: 'Radio Máximo (m)',
            geometriaRadioCurvatura: 'Radio de Curvatura (°/km)',
            geometriaVelDiseno: 'Velocidad de Diseño (km/h)',
            geometriaSeparador: 'Con Separador',
            geometriaSeparadorAncho: 'Ancho Separador (m)',
            geometriaCondicionesGeometrica: 'Condiciones Geométricas',
            geometriaAlineamiento: 'Alineamiento',
            geometriaTipoTerreno: 'Tipo de Terreno',
            dimAdoquinTipico: 'Dimensiones del Adoquín típico',
            geometriaAdoLargo: 'Largo (m)',
            geometriaAdoAncho: 'Ancho (m)',
            geometriaAdoAlto: 'Alto (m)',
            detallesGeometria: 'Observaciones',
            informacionEstructura: 'Información Estructura',
            propiedadesGeometricas: 'Propiedades geométricas',
            detallesCarriles: 'Detalles de Carriles',
            estructuraEspesor: 'Espesor (mm)',
            estructuraModulo: 'Módulo (MPa)',
            estructuraDs: 'Desviación Estándar (Std)',
            material: 'Material',
            materialEsp: 'Especificación',
            granulometria: 'Granulometría',
            espesorTotal: 'Espesor total (mm)',
            igg: 'Índice de Gavidade Global (IGG)',
            iggEstructura: 'Índice de Gavidade Global',
            conceito: 'Conceito',
            capacidadEstructural: 'Capacidad estructural',
            estructuraNumDiseno: 'Número Estructural de Diseño',
            estructuraFecha: 'Fecha',
            estructuraNumero: 'Número Estructural Efectivo',
            estructuraNumeroFecha: 'Fecha',
            estructuraCbr: 'CBR (%)',
            estructuraCbrFecha: 'Fecha',
            estructuraFechaConst: 'Fecha de Construcción',
            estructuraFechaRehab: 'Fecha de Última Rehabilitación',
            detallesEstructura: 'Observaciones',
            fechasImportantes: 'Fechas importantes',
            smartCode: 'Código',
            absInicio: 'Abscisa Inicial',
            absFin: 'Abscisa Final',
            longitudShow: 'Longitud (km)',
            prInicio: 'PR Inicio',
            prFin: 'PR Fin',
            departamento: 'Departamento',
            municipio: 'Municipio',
            latitud: 'Latitud',
            longitud: 'Longitud',
            altura: 'Altura (m.s.n.m)',
            createdAt: 'Fecha Creación',
            createdBy: 'Creado Por',
            updatedAt: 'Fecha Actualización',
            updatedBy: 'Actualizado Por',
            createdEn: 'Creado en',
            updatedEn: 'Actualizado en',
            localizacion: 'Localización',
            coordenadasIniciales: 'Coordenadas Iniciales',
            coordenadasFinales: 'Coordenadas Finales',
            tipoElmentoFotografia: 'Tipo de elemento'
          },
        },
        otro_detalles: {
          name: 'Segmento Otro |||| Segmentos Otros ',
          fields: {
            unidadFuncional: 'Unidad Funcional',
            tramo: 'Tramo',
            segmento: 'Segmento',
            sentido: 'Sentido',
            estadoDetalles: 'Estado',
            estadoRazon: 'Razón',
            dateInicioOperacion: 'Fecha de Inicio Operación',
            proveedor: 'Proveedor',
            garantiaMeses: 'Garantía (meses)',
            trafico: 'Tráfico',
            esalTime: 'ESAL (Año)',
            nivelTrafico: 'Nivel de tráfico',
            nivelTraficoDetalles: 'Nivel de Tráfico',
            esalTime10: 'ESAL (Proyección 10 Años)',
            esalTime20: 'ESAL (Proyección 20 Años)',
            detallesObservaciones: 'Observaciones',
            detallesCalzada: 'Detalles de Calzada',
            geometriaLargo: 'Largo (m)',
            geometriaAncho: 'Ancho de la Calzada (m)',
            geometriaNumCarriles: 'Número de Carriles',
            geometriaAnchoCarril: 'Ancho por Carril (m)',
            geometriaTipoBerma: 'Tipo de Berma',
            geometriaAnchoBerma: 'Ancho de Berma (m)',
            geometriaCondicionBerma: 'Condición de Berma',
            aux1: 'Área de Carriles (m²)',
            sumaAreaCarriles: 'Área de Carriles (m²)',
            areaCarrilesVerdadera: 'Área Verdadera Carriles (m²)',
            geometriaLongitudinal: 'Pendiente Longitudinal',
            geometriaLongitudinalMa: 'Pendiente Longitudinal máxima',
            geometriaLongitudinalMi: 'Pendiente Longitudinal mínima',
            geometriaTransversal: 'Pendiente Transversal',
            geometriaRadioMi: 'Radio Mínimo (m)',
            geometriaRadioMa: 'Radio Máximo (m)',
            geometriaRadioCurvatura: 'Radio de Curvatura (°/km)',
            geometriaVelDiseno: 'Velocidad de Diseño (km/h)',
            geometriaSeparador: 'Con Separador',
            geometriaSeparadorAncho: 'Ancho Separador (m)',
            geometriaCondicionesGeometrica: 'Condiciones Geométricas',
            geometriaAlineamiento: 'Alineamiento',
            geometriaTipoTerreno: 'Tipo de Terreno',
            detallesGeometria: 'Observaciones',
            informacionEstructura: 'Información Estructura',
            propiedadesGeometricas: 'Propiedades geométricas',
            detallesCarriles: 'Detalles de Carriles',
            estructuraEspesor: 'Espesor (mm)',
            estructuraModulo: 'Módulo (MPa)',
            estructuraDs: 'Desviación Estándar (Std)',
            material: 'Material',
            materialEsp: 'Especificación',
            granulometria: 'Granulometría',
            espesorTotal: 'Espesor total (mm)',
            igg: 'Índice de Gavidade Global (IGG)',
            iggEstructura: 'Índice de Gavidade Global',
            conceito: 'Conceito',
            capacidadEstructural: 'Capacidad estructural',
            estructuraNumDiseno: 'Número Estructural de Diseño',
            estructuraFecha: 'Fecha',
            estructuraNumero: 'Número Estructural Efectivo',
            estructuraNumeroFecha: 'Fecha',
            estructuraCbr: 'CBR (%)',
            estructuraCbrFecha: 'Fecha',
            estructuraFechaConst: 'Fecha de Construcción',
            estructuraFechaRehab: 'Fecha de Última Rehabilitación',
            detallesEstructura: 'Observaciones',
            fechasImportantes: 'Fechas importantes',
            smartCode: 'Código',
            absInicio: 'Abscisa Inicial',
            absFin: 'Abscisa Final',
            longitudShow: 'Longitud (km)',
            prInicio: 'PR Inicio',
            prFin: 'PR Fin',
            departamento: 'Departamento',
            municipio: 'Municipio',
            latitud: 'Latitud',
            longitud: 'Longitud',
            altura: 'Altura (m.s.n.m)',
            createdAt: 'Fecha Creación',
            createdBy: 'Creado Por',
            updatedAt: 'Fecha Actualización',
            updatedBy: 'Actualizado Por',
            createdEn: 'Creado en',
            updatedEn: 'Actualizado en',
            localizacion: 'Localización',
            coordenadasIniciales: 'Coordenadas Iniciales',
            coordenadasFinales: 'Coordenadas Finales'
          }
        },
        seguridads:{
          name: 'Sistema de Contención Vehicular |||| Sistemas de Contención Vehiculares',
          fields: {
            tipoElemento: 'Elemento',
            margen: 'Margen',
            sentido: 'Sentido',
            unidadFuncional: 'Unidad Funcional',
            tramo: 'Tramo',
            segmento: 'Segmento',
            abscisa: 'Abscisa',
            pr: 'PR',
            latitud: 'Latitud',
            longitud: 'Longitud',
            material: 'Material',
            cant_elem: 'Cantidad de elementos',
            cant_capta: 'No. Captafaros',
            cant_largo: 'Largo (m)',
            ca_largo: 'Largo (m)',
            largo: 'Largo ss(m)',
            cant_ancho: 'Ancho (m)',
            ca_ancho: 'Ancho (m)',
            altura: 'Altura (m)',
            ca_altura: 'Altura (m)',
            perfil: 'Tipo de perfil',
            defensa: 'Tipo de defensa',
            postes: 'No. Postes',
            captafaros: 'No. Captafaros',
            cant_capta: 'No. Captafaros',
            unidades: 'No. Unidades',
            terminales: 'No. Terminales',
            tornillos: 'No. Tornillos',
            posteMetalico: 'Poste Metálico',
            calibre_mm: 'Calibre (mm)',
            tope: 'Tipo de tope',
            seccion: 'Sección',
            diametro: 'Diámetro/Base (m)',
            diametroShow: 'Diametro Base',
            topes: 'Cantidad de topes',
            tramos: 'Longitud del tramo (m)',
            motivoReemplazo: 'Motivo del Reemplazo',
            condicionElemento: 'Condición del elemento',
            elementoReemplazo_id: 'Elemento que reemplazo',
            estado: 'Estado',
            fechaInstalacion: 'Fecha de instalación',
            proveedor: 'Proveedor',
            garantia: 'Garantía (meses)',
            observaciones: 'Observaciones',
            smartCode: 'Código',
            abInicio: 'Abscisa Inicio',
            abFin: 'Abscisa Fin',
            fullResta: 'Longitud (km)',
            pInicio: 'PR Inicio',
            pFin: 'PR Fin',
            latitudInicial: 'Latitud',
            longitudInicial: 'Longitud',
            latitudFinal: 'Latitud',
            longitudFinal: 'Longitud',
            createdAt: 'Fecha Creación',
            createdBy: 'Creado Por',
            updatedAt: 'Fecha Actualización',
            updatedBy: 'Actualizado Por',
            createdEn: 'Creado en',
            updatedEn: 'Actualizado en',
            tipoElementoDesc: 'Elementos',
          },
        },
        elemento_drenajes:{
          name: 'Elemento Drenaje |||| Elementos Drenajes',
          fields: {
           
            tipoElemento: 'Tipo de Elemento',
            margen: 'Margen',
            sentido: 'Sentido',
            tipoCuneta: 'Tipo Cuneta',
            unidadFuncional: 'Unidad Funcional',
            tramo: 'Tramo',
            segmento: 'Segmento',
            abscisa: 'Abscisa',
            pr: 'PR',
            latitud: 'Latitud',
            longitud: 'Longitud',
            fullResta: 'Longitud (km)',
            abInicio: 'Abscisa Inicio',
            abFin: 'Abscisa Fin',
            pInicio: 'PR Inicio',
            pFin: 'PR Fin',
            material: 'Material',
            seccion: 'Sección',
            tipoDelElemento: 'Tipo del Elemento',
            largo: 'Largo (m)',
            ancho: 'Ancho/Diámetro (m)',
            altura: 'Altura (m)',
            numeroTramos: 'Número de Tramos',
            seccionHidraulica: 'Sección Hidráulica (m²)',
            pendienteTransversal: 'Pendiente Transversal',
            pendienteTransversalShow: 'Pendiente Transversal°',
            pendienteLongitudinal: 'Pendiente Longitudinal',
            pendienteLongitudinalShow: 'Pendiente Longitudinal°',
            numeroDuctos: 'Numero de ductos',
            tipoPlanta: 'Tipo de Planta',
            direccion: 'Dirección',
            areaAla: 'Área ala (m²)',
            areaCabezal: 'Área muro cabezal (m²)',
            areaCalzada: 'Área Calzada (m²)',
            areaLateral: 'Área lateral (m²)',
            estado: 'Condición del elemento',
            motivoReemplazo: 'Motivo del Reemplazo',
            elementoReemplazo_id: 'Elemento que reemplazo',
            estados: 'Estado',
            estadoRazon: 'Razón',
            fechaInstalacion: 'Fecha de instalación',
            proveedor: 'Proveedor',
            garantia: 'Garantía (Meses)',
            observaciones: 'Observaciones',
            fullCodigoConsecutivo: 'Código',
            smartCode: 'Código',
            createdAt: 'Fecha Creación',
            createdBy: 'Creado Por',
            updatedAt: 'Fecha Actualización',
            updatedBy: 'Actualizado Por',
            createdEn: 'Creado en',
            updatedEn: 'Actualizado en',
            tipoImagen: 'Tipo de elemento'
          }
        }, 
        elemento_senalizacions: {
          name: 'Elemento Señal |||| Elementos Señales',
          fields: {
            tipoElementoSenal: 'Elemento',
            margen: 'Margen',
            sentido: 'Sentido',
            tipoSenal: 'Tipo de elemento',
            senal: 'Código de modelo',
            modelo: 'Modelo',
            unidadFuncional: 'Unidad Funcional',
            tramo: 'Tramo',
            segmento: 'Segmento',
            abscisa: 'Abscisa',
            pr: 'PR',
            latitud: 'Latitud',
            longitud: 'Longitud',
            materialPlaca: 'Material de placa',
            material: 'Material',
            tipoPapel: 'Tipo papel',
            tipoLamina: 'Tipo de lámina',
            tipoTablero: 'Forma del tablero',
            dimensionTablero: 'Dimensiones del tablero',
            dimensionTablero: 'Dimensiones del tablero',
            anchoTablero: 'Ancho/Diámetro del tablero (m)',
            altoTablero: 'Alto del tablero (m)',
            estadoTablero: 'Estado del tablero',
            tipoSoporte: 'Tipo de soporte',
            estadoSoporte: 'Estado del soporte',
            materialSoporte: 'Material soporte',
            velocidadDimension: 'Velocidad asociada a la dimensión',
            altura: 'Altura de señal (m)',
            valorLimite: 'Valor límite',
            valorLimiteDescrip: 'Aplica si es señal SR-30, SR-31, SR-32,SR-33,SP-38',
            ABNT: 'Valor Mínimo ABNT',
            color: 'Color principal',
            colorOtrosElem: 'Color',
            retroflectividadInicial: 'Retro-reflectividad color principal (cd/lux/m²)',
            colorSecundario: 'Color secundario',
            retroflectividadFinal: 'Retro-reflectividad color secundario (cd/lux/m²)',
            colorTerciario: 'Color terciario',
            retroflectividadTerciaria: 'Retro-reflectividad color terciario (cd/lux/m²)',
            numeroTachas: 'Número de tachas',
            ancho: 'Ancho (m)',
            area: 'Área (m²)',
            cantidad: 'Cantidad',
            espaciamientoPromedio: 'Espaciamiento promedio (m)',
            estado: 'Condición del elemento',
            condicion: 'Condición',
            motivoReemplazo: 'Motivo reemplazo',
            elementoReemplazo_id: 'Elemento que reemplazo',
            estados: 'Estado',
            fechaInstalacion: 'Fecha de instalación',
            proveedor: 'Proveedor',
            garantia: 'Garantía (meses)',
            observaciones: 'Observaciones',
            smartCode: 'Código',
            abInicio: 'Abscisa Inicial',
            abInicioShow: 'Abscisa Inicio',
            abFin: 'Abscisa Final',
            fullResta: 'Longitud (km)',
            pInicio: 'PR Inicial',
            pInicioShow: 'PR Inicio',
            pFin: 'PR Final',
            latitudFinal: 'Latitud',
            longitudFinal: 'Longitud',
            fullMotivoReemplazo: 'Motivo del reemplazo',
            fullCodigo: 'Elemento que reemplaza',
            createdAt: 'Fecha creación',
            createdBy: 'Creado por',
            updatedAt: 'Fecha actualización',
            updatedBy: 'Actualizado por',
            createdEn: 'Creado en',
            updatedEn: 'Actualizado en',
            tipoImagen: 'Tipo de elemento'
          }
        },
        elemento_alrededors: {
          name: 'Elemento Alrededor |||| Elementos Alrededores',
          fields: {
            tipoElemento: 'Tipo Elemento',
            tipoElementoShow: 'Elemento',
            margen: 'Margen',
            sentido: 'Sentido',
            unidadFuncional: 'Unidad Funcional',
            tramo: 'Tramo',
            segmento: 'Segmento',
            abscisa: 'Abscisa',
            pr: 'PR',
            latitud: 'Latitud',
            longitud: 'Longitud',
            tipoServicio: 'Tipo de servicio',
            tipoServicioCarac: 'Superficie características',
            tipoServicioObs: 'Tipo',
            tipoServicioZINT: 'Tipo de afectación',
            tipoServicioOCPN: 'Tipo de elemento',
            tipoAcceso: 'Tipo de acceso',
            estacionamiento: 'Estacionamiento',
            largo: 'Largo del acceso (m)',
            ancho: 'Ancho del acceso (m)',
            area: 'Área de acceso (m²)',
            identificacion: 'Identificación',
            propietario: 'Propietario',
            emailPropietario: 'Email propietario',
            telefonoPropietario: 'Teléfono propietario',
            largoM: 'Largo (m)',
            anchoM: 'Ancho (m)',
            areaM: 'Área (m²)',
            areaOb: 'Altura (m)',
            longitudAfectacion: 'Longitud de afectación (m)',
            longitudAfectacionZINT: 'Longitud de calzada afectada (m)',
            riesgo: 'Riesgo',
            seccion: 'Ubicación',
            tipoTerraplen: 'Tipo de Terraplen',
            cantidadBermas: 'Cantidad de Bermas',
            alturaPromedioBermas: 'Altura Promedio de las Bermas',
            tramoPendiente: 'Tramos de Pendiente Variable',
            pendTransversal: 'Pendiente Transversal H:V',
            obraEstabilizacion: 'Obras de estabilización',
            tipoObra: 'Tipo de obra',
            fichaInspeccion: 'Ficha de inspección de obra',
            inspector: 'Inspector',
            fechaInspeccion: 'Fecha del estudio',
            nombreFicha: 'Nombre de la ficha',
            estudioEstabilidad: 'Estudios de estabilidad del sitio',
            consultor: 'Consultor',
            fechaEstudio: 'Fecha del estudio',
            codigoEstudio: 'Código de estudio',
            nombreEstudio: 'Nombre del estudio',
            longitudAcceso: 'Longitud del acceso (m)',
            anchoAcceso: 'Ancho del acceso (m)',
            estado: 'Condición del elemento',
            condicion: 'Motivo de reemplazo',
            elementoReemplazo: 'Elemento que reemplazo',
            fechaConstruccion: 'Fecha de construcción',
            proveedor: 'Proveedor',
            garantia: 'Garantía (meses)',
            estados: 'Estado',
            mantenimiento: '¿Incluir este elemento a un plan de mantenimiento?',
            observaciones: 'Observaciones',
            smartCode: 'Código',
            tipoCuneta: 'Tipo de cuneta',
            abInicio: 'Abscisa Inicio',
            abInicioList: 'Abscisa inicial',
            abFin: 'Abscisa Fin',
            fullResta: 'Longitud (km)',
            pInicio: 'PR Inicio',
            pInicioList: 'PR inicial',
            pFin: 'PR Fin',
            latitudInicial: 'Latitud',
            longitudInicial: 'Longitud',
            latitudFinal: 'Latitud',
            longitudFinal: 'Longitud',
            createdAt: 'Fecha creación',
            createdBy: 'Creado por',
            updatedAt: 'Fecha actualización',
            updatedBy: 'Actualizado por',
            createdEn: 'Creado en',
            updatedEn: 'Actualizado en',
          }
        },
        tuneles: {
          name:'Elemento Túnel |||| Elementos Túneles',
          fields: {
            elementos: 'Elementos',
            TNEL: 'Túnel',
            sentido: 'Sentido',
            clasificacion: 'Clasificación',
            cobertura: 'Según cobertura máxima',
            carriles: 'Número de carriles',
            carrilesShow: 'Carriles',
            unidadFuncional: 'Unidad Funcional',
            tramo: 'Tramo',
            segmento: 'Segmento',
            abscisa: 'Abscisa',
            pr: 'PR',
            latitud: 'Latitud',
            longitud: 'Longitud',
            ano: 'Año',
            area: 'Área (m²)',
            diseno: 'Norma de diseño',
            disenador: 'Diseñador',
            informe: 'Informe de diseño',
            anoa: 'Año',
            areaa: 'Área (m²)',
            disenoa: 'Norma de diseño',
            disenadora: 'Diseñador',
            informea: 'Informe de diseño',
            observacionesa: 'Observaciones',
            anob: 'Fecha del estudio',
            consultor: 'Consultor',
            estudio: 'Código del estudio',
            nom_estudio: 'Nombre del estudio',
            anoc: 'Fecha del estudio',
            consultora: 'Consultor',
            estudioa: 'Código del estudio',
            nom_estudioa: 'Nombre del estudio',
            propietario: 'Propietario',
            vial: 'Administración vial',
            proyectista: 'Proyectista',
            opacidad: 'Opacidad de diseño (x10¯³ m¯¹) ',
            concentracion: 'Concentración CO de diseño (ppm)',
            existe: 'Existe variante',
            long_km: 'Longitud variante (km)',
            estadoa: 'Estado',
            observacioness: 'Observaciones',
            radio_min: 'Radio mínimo de curvatura (m)',
            radio_max: 'Radio máximo de curvatura (m)',
            ancho_carril: 'Ancho por carril (m)',
            pend_longi: 'Pendiente longitudinal (%)',
            radio_min_curva: 'Radio mínimo curvas verticales (m)',
            radio_max_curva: 'Radio máximo curvas verticales (m)',
            long_tunel: 'Longitud del túnel (m)',
            galibo: 'Gálibo (m)',
            ancho_anden: 'Ancho del andén (m)',
            ancho_berma: 'Ancho berma (m)',
            soporte: 'Sistema de soporte',
            tipologia: 'Tipología de los portales',
            observacioness: 'Observaciones',
            codigo_soporte: 'Código',
            codigo_soporteName: 'Revestimiento definitivo',
            estado: 'Condición del elemento',
            mot_remplazo: 'Motivo de reemplazo',
            elemnto_remplazo: 'Elemento que reemplazo',
            estados: 'Estado',
            fecha: 'Fecha de instalación',
            proveedor: 'Proveedor',
            garantia: 'Garantía (meses)',
            observaciones: 'Observaciones',
            smartCode: 'Código',
            abInicio: 'Abscisa Inicio',
            abFin: 'Abscisa Fin',
            fullResta: 'Longitud (km)',
            pInicio: 'PR Inicio',
            pFin: 'PR Fin',
            createdAt: 'Fecha creación',
            createdBy: 'Creado por',
            updatedAt: 'Fecha actualización',
            updatedBy: 'Actualizado por',
            createdEn: 'Creado en',
            updatedEn: 'Actualizado en',
            clasificacionList: 'Tipo',
            sentidoList: 'Margen',
            sentidoB: 'Sentido'
          }
        },
        elemento_contencions: {
          name: 'Elemento Contención |||| Elementos Contención',
          fields: {
            tipoElemento: 'Tipo de Elemento',
            margen: 'Margen',
            sentido: 'Sentido',
            tipo: 'Clasificación',
            funcionalidad: 'Funcionalidad',
            unidadFuncional: 'Unidad Funcional',
            tramo: 'Tramo',
            segmento: 'Segmento',
            abscisa: 'Abscisa',
            pr: 'PR',
            latitud: 'Latitud',
            longitud: 'Longitud',
            largo: 'Largo (m)',
            ancho: 'Ancho (m)',
            altura: 'Altura (m)',
            consultor: 'Consultor',
            fechaEstudio: 'Fecha del estudio',
            codigoEstudio: 'Código de estudio',
            nombreEstudio: 'Nombre del estudio',
            estado: 'Condición del elemento',
            motivoReemplazo: 'Motivo reemplazo',
            elementoReemplazo: 'Reemplazo',
            estados: 'Estado',
            fechaInstalacion: 'Fecha de instalación',
            proveedor: 'Proveedor',
            garantia: 'Garantía (meses)',
            observaciones: 'Observaciones',
            smartCode: 'Código',
            abInicio: 'Abscisa Inicio',
            abInicioList: 'Abscisa inicial',
            abFin: 'Abscisa Fin',
            pInicio: 'PR Inicio',
            pInicioList: 'PR inicial',
            pFin: 'PR Fin',
            createdAt: 'Fecha creación',
            createdBy: 'Creado por',
            updatedAt: 'Fecha actualización',
            updatedBy: 'Actualizado por',
            createdEn: 'Creado en',
            updatedEn: 'Actualizado en',
            tipoList: 'Elemento',
          }
        },
        elemento_pontons: {
          name: 'Elemento Pontones |||| Elementos Pontones',
          fields: {
            tipoElemento: 'Elemento',
            margen: 'Margen',
            sentidoB: 'Sentido',
            tipoPonton: 'Tipo',
            numeroCarriles: 'Número de carriles',
            unidadFuncional: 'Unidad Funcional',
            tramo: 'Tramo',
            segmento: 'Segmento',
            abscisa: 'Abscisa',
            PR: 'PR',
            latitud: 'Latitud',
            longitud: 'Longitud',
            ano: 'Año',
            area: 'Área (m²)',
            norma: 'Norma de diseño',
            disenador: 'Diseñador',
            info_di: 'Informe de Diseño',
            anoa: 'Año',
            areaa: 'Área (m²)',
            normaa: 'Norma de diseño',
            disenadora: 'Diseñador',
            info_dia: 'Informe de Diseño',
            obser_dia: 'Observaciones',
            anob: 'Fecha Recolección Datos',
            sentido: 'Dirección de abscisado',
            estacion_conteo: 'Estación de Conteo',
            inspector: 'Inspector',
            inspeccion: 'Requisitos de Inspección',
            numinspeccion: 'Número de Secciones de Inspección',
            propietario: 'Propietario',
            admon_vial: 'Administración Vial',
            proyectista: 'Proyectista',
            coeficiente: 'Coeficiente de aceleración sísmica (Aa)',
            cauce: 'Paso por el cauce',
            variante: 'Existe variante',
            long_variante: 'Longitud variante (km)',
            esta: 'Estado',
            vehi_dise: 'Vehículo de diseño',
            distri_carga: 'Clase distribución de carga',
            capa_carga: 'Capacidad de carga (t)',
            luz_critica: 'Longitud de luz crítica (m)',
            fac_clasi: 'Factor de clasificación',
            fue_cor: 'Fuerza cortante (t)',
            momento: 'Momento (t.m)',
            linea_carga: 'Línea de carga por rueda (t)',
            obs_1: 'Observaciones',
            pon_terra: 'Pontón en terraplén',
            pon_c: 'Pontón en Curvatura/Tangente',
            long_2: 'Longitud (m)',
            ancho_2: 'Ancho losa superior (m)',
            espe_2: 'Espesor losa superior (m)',
            altu_2: 'Altura inferior (m)',
            ancho_3: 'Ancho losa inferior (m)',
            espe_3: 'Espesor losa inferior (m)',
            pro_3: 'Profundidad del relleno (m)',
            espe_4: 'Espesor de muros (m)',
            sepa_4: 'Separación entre muros (m)',
            estado: 'Condición del elemento',
            motivoReemplazo: 'Motivo reemplazo',
            elementoReemplazo: 'Reemplazo',
            estados: 'Estado',
            fecha: 'Fecha de instalación',
            proveedor: 'Proveedor',
            garantia: 'Garantía (meses)',
            observaciones: 'Observaciones',
            fullCodigo: 'Código',
            abInicio: 'Abscisa Inicio',
            abFin: 'Abscisa Fin',
            fullResta: 'Longitud(km)',
            pInicio: 'PR Inicio',
            pFin: 'PR Fin',
            createdAt: 'Fecha creación',
            createdBy: 'Creado por',
            updatedAt: 'Fecha actualización',
            updatedBy: 'Actualizado por',
            createdEn: 'Creado en',
            updatedEn: 'Actualizado en',
            Borrar: '¿Borrar?',
            abInicio: 'Abscisa inicial',
            pInicio: 'PR inicial',
          }
        },
        puentes: {
          name: 'Elemento Puentes |||| Elemento Puentes',
          fields: {
            elementos: 'Elemento',
            margen: 'Margen',
            tipo: 'Tipo de Puente',
            codigo:'Código',
            carriles: 'Número de carriles',
            unidad_funcional: 'Unidad Funcional',
            tramo: 'Tramo',
            segmento: 'Segmento',
            abscisa: 'Abscisa',
            PR: 'PR',
            latitud: 'Latitud',
            longitud: 'Longitud',
            ingresoAbFin: 'Abscisa Inicio',
            ingresoAbFin: 'Abscisa Fin',
            pInicio: 'PR Inicio',
            pFin: 'PR Fin',
            years: 'Año',
            area: 'Área (m²)',
            norma_diseno: 'Norma de diseño',
            disenador: 'Diseñador',
            info_di: 'Informe de Diseño',
            yearsb: 'Año',
            areab: 'Área (m²)',
            norma_disenob: 'Norma de diseño',
            disenadorb: 'Diseñador',
            info_dib: 'Informe de Diseño',
            fecha: 'Fecha de Recolección de Datos',
            inspector: 'Inspector',
            inspeccion: 'Requisitos de Inspección',
            direccion_abscisado: 'Dirección de abscisado',
            estacion_conteo: 'Estación de Conteo',
            numinspeccion: 'Número de Secciones de Inspección',
            propietario: 'Propietario',
            admon_vial: 'Administración Vial',
            proyectista: 'Proyectista',
            coe_sismo: 'Coeficiente de aceleración sísmica (Aa)',
            paso_cauce: 'Paso por el cauce',
            estado_op: 'Estado',
            exis_variante: 'Existe variante',
            long_km: 'Longitud variante (km)',
            vehi_di: 'Vehículo de diseño',
            distri_carga: 'Clase de Distribución de Carga',
            capacidad: 'Capacidad de Carga (t)',
            luz_critica: 'Longitud de luz Crítica (m)',
            fac_clas: 'Factor de Clasificación',
            fac_cort: 'Fuerza Cortante (t)',
            momento: 'Momento (t.m)',
            car_rue: 'Línea de Carga por Rueda (t)',
            observacioness: 'Observaciones',
            curva: 'Puente en Curvatura/Tangente',
            terraplen: 'Puente en terraplén',
            esviajamiento: 'Esviajamiento (gra)',
            luces: 'Número de luces',
            luz_menor: 'Longitud luz menor (m)',
            luz_mayor: 'Longitud luz mayor (m)',
            long_total: 'Longitud total (m)',
            ancho_tab: 'Ancho de tablero (m)',
            area_tab: 'Área del tablero (m²)',
            ancho_sep: 'Ancho del separador (m)',
            ancho_anden_izq: 'Ancho del andén izquierdo (m)',
            ancho_anden_der: 'Ancho del andén derecho (m)',
            ancho_calzada: 'Ancho de calzada (m)',
            ancho_bordillo: 'Ancho entre bordillos (m)',
            alt_pilas: 'Altura de pilas (m)',
            long_pilas: 'Longitud de apoyo en pilas (m)',
            alt_estribos: 'Altura de estribos (m)',
            long_estribos: 'Longitud de apoyo en estribos (m)',
            dise_tipo: 'Diseño tipo',
            material: 'Material',
            transversarlCod: 'Código',
            transversarl: 'Tipo de estructuración transversal',
            longitudCod: 'Código',
            longitudinal: 'Tipo de estructuración longitudinal',
            car_max: 'Carga máxima',
            velo_max: 'Velocidad máxima (km/h)',
            galibo_maximo: 'Gálibo máximo (m)',
            otra: 'Otra',
            barandaCod: 'Código',
            baranda: 'Detalles de baranda',
            expantionCod: 'Código',
            expantion: 'Junta de expansión',
            proteccion_alta_tension: 'Protección en líneas de alta tensión',
            tipo_acceso: 'Tipo de Acceso',
            huella: 'Huella (cm)',
            contrahuella: 'Contrahuella (cm)',
            logitud_remapa: 'Longitud de rampa (m)',
            pendiente: 'Pendiente (%)',
            nro_tramos: 'Número de tramos',
            tipoestribos: 'Tipo',
            estriCod: 'Código',
            estriMat: 'Material',
            tipo_cimen: 'Tipo de cimentación',
            tippilasCod: 'Código',
            tippilas: 'Tipo',
            forma_pilaCod: 'Código',
            forma_pila: 'Forma',
            cimen_pila: 'Tipo de cimentación',
            estrisaCod: 'Código',
            estrisa: 'Material',
            apoyo_fijoCod: 'Código',
            apoyo_fijo: 'Apoyos fijos sobre estribos',
            pila_movilCod: 'Código',
            pila_movil: 'Apoyos móviles sobre estribos',
            pila_pilaCod: 'Código',
            pila_pila: 'Apoyos fijos en pilas',
            apoyo_movilCod: 'Código',
            apoyo_movil: 'Apoyos móviles en pilas',
            pila_vigasCod: 'Código',
            pila_vigas: 'Apoyos fijos en vigas',
            movil_vigasCod: 'Código',
            movil_vigas: 'Apoyos móviles en vigas',
            estado: 'Condición del elemento',
            mot_remplazo: 'Motivo de reemplazo',
            elemnto_remplazo: 'Elemento que reemplazo',
            estados: 'Estado',
            fecha: 'Fecha de instalación',
            proveedor: 'Proveedor',
            garantia: 'Garantía (meses)',
            observaciones: 'Observaciones',
            observacionesDetalles: 'Detalles y observaciones',
            createdAt: 'Fecha creación',
            createdBy: 'Creado por',
            updatedAt: 'Fecha actualización',
            updatedBy: 'Actualizado por',
            createdEn: 'Creado en',
            updatedEn: 'Actualizado en',
          }
        },
        otros_elementos: {
          name: 'Otro Elemento |||| Otros Elementos',
          fields: {
            tipoElemento: 'Elemento',
            margen: 'Margen',
            sentido: 'Sentido',
            unidadFuncional: 'Unidad Funcional',
            tramo: 'Tramo',
            segmento: 'Segmento',
            abscisa: 'Abscisa',
            PR: 'PR',
            latitud: 'Latitud',
            longitud: 'Longitud',
            interseccion: 'Clase de intersección',
            material: 'Material',
            flujo_peatonal: 'Flujo peatonal',
            clase_via_intersecta: 'Clase de vía intersecta',
            tipo_via_intersecta: 'Tipo de vía intersecta',
            reductores_velocidad: 'Reductores de velocidad vía intersecta',
            largo: 'Largo (m)',
            carriles: 'No. Carriles',
            estado: 'Condición del elemento',
            motivo: 'Motivo del reemplazo',
            elementoReemplazo_id: 'Elemento que Reemplazo',
            estados: 'Estado',
            fecha_instalacion: 'Fecha de instalación',
            proveedor: 'Proveedor',
            garantia: 'Garantía (meses)',
            observaciones: 'Observaciones',
            sentidoCarac: 'Sentido',
            fullCodigoConsecutivo: 'Código',
            smartCode: 'Código',
            abInicio: 'Abscisa Inicio',
            abFin: 'Abscisa Fin',
            fullResta: 'Longitud (km)',
            pInicio: 'PR Inicio',
            pFin: 'PR Fin',
            latitudShow: 'Latitud',
            longitudShow: 'Longitud',
            createdAt: 'Fecha creación',
            createdBy: 'Creado por',
            updatedAt: 'Fecha actualización',
            updatedBy: 'Actualizado por',
            createdEn: 'Creado en',
            updatedEn: 'Actualizado en',
          }
        },
        redes: {
          fields: {
            tipoElemento: 'Elemento',
            estado: 'Condicion del elemento',
            motivoReemplazo: 'Motivo de reemplazo',
            elementoReemplazo: 'Elemento que reemplazo en',
            estados: 'Estado',  
            fecha: 'Fecha de instalacion',     
            proveedor: 'Proveedor', 
            unidadFuncional:'Unidad Funcional',  
            tramo: 'Tramo',
            abscisa: 'Abscisa',
            fullCodigo: 'Código',
            Borrar: '¿Borrar?',
            margen: 'Margen',
            sentidoB: 'Sentido',
            segmento: 'Segmento',
            latitud: 'Latitud',
            longitud: 'Longitud',
            entornoVia:' Entorno de la vía',
            claseIluminacion:'Clase de iluminación',
            tipoLampara:'Tipo de lámpara',
            brazoTipo:'Brazo tipo',
            disposicionIluminaria:'Disposición de las luminarias',
            garantiaMeses: 'Garantía (meses)',
            mantenimiento: '¿Incluir este elemento a un plan de mantenimiento?',
            observaciones: 'Observaciones',
            empresa: 'Empresa',
            diametro: 'Diámetro (m)',
            longitudM: 'Longitud (m)',
            altura:'Altura (m)',
            largo:'Largo (m)',
            ancho:'Ancho (m)',
            base:'Base (m)',
            largoDiametro:'Largo/Diámetro (m)',
            capacidadT:'Capacidad (t)',
            marca: 'Marca',
            angulo:'Ángulo de inclinación del brazo (°)',
            luminancia:'Valor de luminancia promedio  (cd/m²)',
            anchoLuminoso:'Ancho del módulo luminoso (m)',
            largoLuminoso:'Largo del módulo luminoso (m)',
            material: 'Material',
            tipo:'Tipo',
            seccion: 'Sección',
            nivelTraficoDetalles: 'Nivel de Tráfico',
            pr:'PR',
            clase:'Clase',
            clasificacionSoporte:'Clasificación de los soportes',
            codigo:'Código',
            createdAt: 'Fecha creación',
            createdBy: 'Creado por',
            updatedAt: 'Fecha actualización',
            updatedBy: 'Actualizado por',
            createdEn: 'Creado en',
            updatedEn: 'Actualizado en',
          }
        },
        inventariopeajes: {
          name: 'Inventario Peaje |||| Inventarios Peajes',
          fields: {
            nombre: 'Nombre de  Peaje',
            codigo: 'Código',
            estacion_manual: 'N° Estaciones manuales',
            estacion_manual_show: 'Estaciones manuales',
            estacion_automatica: 'Estaciones automáticas',
            estacion: 'Total Estaciones',
            unidad_funcional: 'Unidad Funcional',
            tramo: 'Tramo',
            segmento: 'Segmento',
            Abscisa: 'Abscisa',
            Latitud: 'Latitud',
            Longitud: 'Longitud',
            createdAt: 'Fecha creación',
            createdBy: 'Creado por',
            updatedAt: 'Fecha actualización',
            updatedBy: 'Actualizado por',
            estado: 'Estado',
            absciaShow: 'Abscisa (km)',
          }
        },
        mensuals: {
          name: 'Tránsito Histórico |||| Tránsito Histórico',
          fields: {
            inventario_peajes: 'Peaje',
            sentido: 'Sentido',
            ano: 'Año',
            mes: 'Mes',
            categoria_a: 'Categoría I',
            categoria_b: 'Categoría II',
            categoria_c: 'Categoría III',
            categoria_d: 'Categoría IV',
            categoria_e: 'Categoría V', 
            categoriaA: 'Cat. I',
            categoriaB: 'Cat. II',
            categoriaC: 'Cat. III',
            categoriaD: 'Cat. IV',
            categoriaE: 'Cat. V',           
            categoria_aShow: 'Categoría I:',
            categoria_bShow: 'Categoría II:',
            categoria_cShow: 'Categoría III:',
            categoria_dShow: 'Categoría IV:',
            categoria_eShow: 'Categoría V:',           
            codigo: 'Código',
            Unidad_Funcional: 'Unidad Funcional',
            Tramo: 'Tramo',
            Segmento: 'Segmento',
            Abscisa: 'Abscisa',
            año: 'Por año consolidado',
            categoria: 'Por categoría',
            desde: 'Desde',
            hasta: 'Hasta',
            Transito: 'Tránsito',
            TPM: 'TPM',
            historico: 'Histórico',
            createdAt: 'Fecha creación',
            createdBy: 'Creado por',
            updatedAt: 'Fecha actualización',
            updatedBy: 'Actualizado por',
          }
        },
        estacion_conteos: {
          name: 'Estación de conteo |||| Estaciones de conteo',
          fields: {
            nombre: 'Nombre',
            unidad_funcional: 'Unidad Funcional',
            tramo: 'Tramo',
            segmento: 'Segmento',
            abscisa: 'Abscisa',
            latitud: 'Latitud',
            longitud: 'Longitud',
            categoria:'Categoría',
            consultor: 'Consultor',
            informe: 'Informe',
            date: 'Fecha de estudio',
            abscisaInicial: 'Abscisa inicial',
            abscisaFinal: 'Abscisa final',
            operativa:'Operativa',
            direccional: 'Factor direccional',
            carril: 'Factor carril',
            years: 'Año',
            factorCamionPonderado: 'Factor camión ponderado',
            c2p:'C₂P',
            c2_p:'C2-p',
            c2g:'C₂G',
            c2_g:'C2-g',
            c3yc4:'C₃yC₄',
            c3c4:'C3-C4',
            c5:'C₅',
            c52:'>C₅',
            tpd:'TPD',
            esal1: 'ESAL (Proyección 5 años)',
            esal2: 'ESAL (Proyección 10 años)',
            esal3: 'ESAL (Proyección 20 años)',
            tpdactual: 'TPD actual',
            tasacrecimiento: 'Tasa de crecimiento',
            valores:'Valores',
            datosTotal:'Datos total',
            years1: 'Desde',
            years2: 'Hasta',
            createdAt: 'Fecha creación',
            createdBy: 'Creado por',
            updatedAt: 'Fecha actualización',
            updatedBy: 'Actualizado por',
          }, 
          input:{
            fechaEstudio:'La fecha no puede ser mayor a la actual',
            tasaCrecimientoMenor:'El número ingresado debe ser menor a 100',
            tasaCrecimientoMayor:'El número ingresado debe ser mayor a -100',
          }
        },
        indicadores_cargar_informacions: {
          name: 'Carga de Información |||| Carga de Información ',
          title:'Visualizar Indicadores',
          cerrar:'Cerrar',
          visualizacion:'Visualización',
          detalle:'Detalles',
          fields: {
            indicador: 'Indicador',
            unidadFuncional: 'Unidad Funcional',
            tramo: 'Tramo',
            abInicios: 'ABSCISA INICIAL',
            abFins: 'ABSCISA FINAL',
            abini: 'Abscisa Inicial',
            abfin: 'Abscisa Final',
            fechaMedicion: 'Fecha de Medición',
            medidoPor: 'Medido Por',
            estado: 'Estado',
            tipoMedicion: 'Tipo de medición',
            archivoCarga: 'Related files',
            AbscisaInicio: 'Abscisa Inicio',
            AbscisaFin: 'Abscisa Fin',
            disponibilidadVia:'Disponibilidad vía',
            tiempoAtencion:'Tiempo de atención',
            ocupacionCarril:'Ocupación del carril',
            VisualizarIndicadores: 'Visualizar Indicadores'

          }
        },
        calificacion_puentes: {
          name: 'Calificación Puentes |||| Calificación Puentes',
          fields: {
            smartCode: 'Consecutivo de medición',
            puentes: 'Puente',
            fechaInicioInspeccion: 'Fecha inicio inspección',
            nombreInspector: 'Inspector',
            nombreReporteInspector: 'Nombre de reporte de inspección',
            fechaReporteInspeccion: 'Fecha reporte inspección',
            medidoPor: 'Medido por',
            estado: 'Estado',
            tipoMedicion: 'Tipo de medición',
            cumplimiento: 'Cumplimiento',
            unidadFuncional: 'Unidad Funcional',
            tramo: 'Tramo',
            medicion: 'Medición',
            activo:'Activo',
            inactivo:'Inactivo',
          }
        },
        disponibilidad_vias: {
          name: 'Disponibilidad de Vía |||| Disponibilidad Vías',
          fields: {
            consecutivo: 'Consecutivo',
            fecha: 'Fecha',
            tipoEvento: 'Tipo de Evento',
            tipoCierre: 'Tipo de Cierre',
            unidadFuncional: 'Unidad Funcional',
            AbscisaInicial: 'Abscisa Inicial',
            AbscisaFinal: 'Abscisa Final',
            margen: 'Margen',
            lugar: 'Lugar',
            longitudAfect: 'Longitud Afectada (km)',
            segmentosAfect: 'Segmentos Afectados',
            segmentosAfectId: 'Segmentos Afectados',
            latitud: 'Latitud',
            longitud: 'Longitud',
            fechaReporte: 'Fecha de Reporte',
            horaReporte: 'Hora de Reporte',
            fechaSenal: 'Fecha de Señalización',
            horaSenal: 'Hora de Señalización',
            tiempSenal: 'Tiempo de Señalización',
            horaFinal: 'Hora Finalización',
            fuenteRep: 'Fuente del Reporte',
            causa: 'Causa',
            elementoAfect: 'Elemento Afectado',
            observaciones: 'Observaciones',
            serviciosDirectorio: 'Directorio',
            grupoEmergencias: 'Grupo',
            sistema: 'Sistema',
            grupo: 'Grupo',
            servElemento: 'Elemento',
            longitudCola: 'Longitud de Retención o Cola (m)',
            indicadorO2: 'Indicador O2',
            observacionesTrabajos: 'Observaciones Retención',
            volumenDerrumbe: 'Volumen del Derrumbe (m³)',
            ElementoAfectado: 'Elemento Afectado',
            observacionesDerrumbe: 'Observaciones',
            tipoIncidenteOtro: 'Tipo de Incidente',
            tipoVehiculoOtro: 'Tipo de Vehículo',
            observacionesOtro: 'Observaciones',
            tipoAccidente: 'Tipo de Accidente',
            CodigoCausa: 'Código Causa',
            CausaProbable: 'Causa Probable',
            clasePolicia: 'Clase',
            cantidadHeridos: 'Cantidad',
            tipoHeridos: 'Tipo',
            generoHeridos: 'Género',
            edadHeridos: 'Edad',
            totalHeridos: 'Heridos Totales',
            cantidadVictimasf: 'Cantidad',
            tipoVictimasf: 'Tipo',
            generoVictimasf: 'Género',
            edadVictimasf: 'Edad',
            totalVictimasFatales: 'Víctimas Fatales Totales',
            cantidadVehiculos: 'Cantidad',
            categoriaVehiculos: 'Categoría',
            tipoVehiculos: 'Tipo',
            marcaVehiculos: 'Marca',
            lineaVehiculos: 'Línea',
            modeloVehiculos: 'Modelo',
            placaVehiculos: 'Placa',
            colorVehiculos: 'Color',
            observacionesAccidente: 'Observaciones',
            ambulancia: 'Para registrar la fecha y hora de ambulancia active el botón',
            fechaDespeje: 'Fecha de Cierre',
            horaDespeje: 'Hora de Cierre',
            diasDespeje: 'Duración del evento (Días)',
            tiempoDespejeFin: 'Duración del evento (h:mm)',
            observacionesDespeje: 'Observaciones Finalización',
            fechaFinalizacion: 'Fecha Finalización',
            horaFinalizacion: 'Hora Finalización',
            tiempoServicio: 'Tiempo Servicio',
            fechaLlegada: 'Fecha de Llegada',
            horaLlegada: 'Hora de Llegada',
            tiempoRespuesta: 'Tiempo Servicio',
            indicadorO5: 'Indicador O5',
            fecha_puestaservicio: 'Fecha de Puesta en Servicio',
            diasCierre: 'No. Días Con Cierre',
            indicadorE17: 'Indicador E17',
            observacionesCierre: 'Observaciones Cierre',
            createdAt: 'Fecha creación',
            createdBy: 'Creado por',
            updatedAt: 'Fecha actualización',
            updatedBy: 'Actualizado por',
            tiempoFinalizacion: 'Tiempo Respuesta',
            finalizar: 'Finalizó',
            yearact: 'Año',
            mesact: 'Mes',
            fechaAmbulancia:'Fecha llegada ambulancia',
            tiempoRespuestaAmbulancia:'Tiempo de respuesta ambulancia',
            grua:'Para registrar la fecha y hora de grúa active el botón',
            fechaGrua:'Fecha llegada grúa',
            tiempoRespuestaGrua:'Tiempo de respuesta grúa',
          }
        },
        parametros_analises: {
          name: 'Parámetros de planeación |||| Parámetros de planeación',
          fields: {
            unidad_funcional: 'Unidad Funcional',
            plan: 'Plan de mantenimiento',
            num_analisis: 'Número de años de análisis',
            years1: 'Año de evaluación',
            descripcion: 'Descripción del escenario',
            tasa_crecimiento: 'Tasa crecimiento tránsito',
            tasa_interes: 'Tasa de interés',
            coin: 'Moneda',
            per_analisis: 'Costo de reparación estructural',
            nom_analisis: 'Costo de reparación de la capa superficial',
            years2: 'Hasta',
            createdAt: 'Fecha creación',
            createdBy: 'Creado por',
            updatedAt: 'Fecha actualización',
            updatedBy: 'Actualizado por',
          }
        },
        politicas_mantenimientos: {
          name: 'Política de Mantenimiento |||| Políticas de Mantenimiento',
          fields: {
            nombre: 'Nombre',
            unidadFuncional: 'Unidad Funcional',
            sistema: 'Sistema',
            FechaIniPeriodicidad: 'Fecha de Inicio de Periodicidad',
            sistema: 'Sistema',
            fecha: 'Fecha',
            unidadMedida: 'Unidad',
            periocidad: 'Periodicidad',
            valorU: 'Valor Unitario',
            fechaU: 'Fecha del Valor Unitario',
            fuente: 'Fuente',
            descripcion: 'Descripción',
            prolVidaUtil: 'Vida Útil',
            operacion: 'Tipo de Operación',
            tipoElemento: 'Tipo de Elemento',
            Valor_U: 'Valor U.',
            Fecha_U: 'Fecha U.',
            nombrePolitica: 'Nombre de la Política',
            createdAt: 'Fecha creación',
            createdBy: 'Creado por',
            updatedAt: 'Fecha actualización',
            updatedBy: 'Actualizado por',
            SeRealiza: 'Se Realiza',
            Politica: 'Política',
            Clonar: 'Clonar',
          },
        },
        politicas_mantenimiento_particulars: {
          name: 'Políticas de Mantenimiento Particular |||| Crear Política de Mantenimiento Particular',
          fields: {
            nombre: 'Nombre de la Política',
            sistema: 'Sistema',
            tipoElemento: 'Nombre',
            tipoElementoSenal: 'Nombre',
            unidadFuncional: 'Unidad Funcional',
            tramo: 'Tramo',
            abInic: 'Abscisa Inicial',
            abFin: 'Abscisa Final',
            codigoConsecutivo: 'ID en Inventario',
            nombrePoliticaString: 'Nombre de la Política',
            nombrePolitica: 'Nombre de la Política ID',
            periocidad2: 'Periodicidad',
            valorU2: '"Valor Unitario',
            fechaU2: 'Fecha del Valor Unitario',
            fuente2: 'Fuente',
            fecha2: 'Fecha de Inicio de Periodicidad',
            prolVidaUtil2: 'Prol. Vida Util',
            descripcion2: 'Descripción',
            periocidad: 'Periodicidad',
            valorU: '"Valor Unitario',
            fechaU: 'Fecha del Valor Unitario',
            fuente: 'Fuente',
            fecha: 'Fecha de Inicio de Periodicidad',
            prolVidaUtil: 'Prol. Vida Util',
            VidaUtil: 'Vida Util',
            descripcion: 'Descripción',
            createdAt: 'Fecha creación',
            createdBy: 'Creado por',
            updatedAt: 'Fecha actualización',
            updatedBy: 'Actualizado por',
            tipoElementoShow: 'Elemento',
            tipoElementoShowTable: 'Tipo de Elemento',
            Clonar: 'Clonar',
          }
        },
        trayectorias: {
          name: 'Alternativas |||| Alternativas',
          fields: {
            unidad_funcional: 'Unidad Funcional',
            trafico: 'Limite de tránsito',
            nombre: 'Nombre de la alternativa',
            precio: 'Precio total ($/m²)',
            preciocar: 'Precio carpeta ($/m²)',
            vida: 'Vida esperada (años)',
            espesor: 'Espesor removido (mm)',
            createdAt: 'Fecha creación',
            createdBy: 'Creado por',
            updatedAt: 'Fecha actualización',
            updatedBy: 'Actualizado por',
            clonar: 'Clonar'
          }
        },
        modelo_costos_pers: {
          name: 'Reparaciones Menores |||| Reparaciones Menores',
          fields: {
            areaSegmento: 'Área del segmento m²',
            unidadFuncional: 'Unidad Funcional',
            createdAt: 'Fecha creación',
            createdBy: 'Creado por',
            updatedAt: 'Fecha actualización',
            updatedBy: 'Actualizado por',
            variableAFlex: 'Variable A',
            variableBFlex: 'Variable B',
            variableXFlex: 'Variable X',
            variableYFlex: 'Variable Y',
          }
        },
        pers_exports: {
          unidadSave: 'Código Unidad Funcional',
          parAnalisis: 'Parámetros de analisis',
          persImport: 'Documento base importado',
          unidad_funcional: 'Código',
          year: 'Año',
          unidadFuncional: 'Unidad Funcional',
          version: 'Versión',
          document: 'Documento',
        },
        pers_imports: {
          name: 'Importación de PERS |||| Importación de PERS',
          fields: {
            unidadFuncional: 'Unidad Funcional',
            persExport: 'Archivo de referencia de Exportación',
            fecha: 'Fecha',
            archivoCarga: 'Archivos relacionados',
            fechaImp: 'Fecha de importación',
            document: 'Documento',
            cargarArchivo:'Cargar Archivo',
          }
        },
        costos: {
          name: 'Costos |||| Costos',
          indicarElementos:'Debe indicar elementos para el registro de costos.',
          seleccioneElementos:'Seleccione los elementos para el registro de costos',
          definirCosto:'¿Definir Costo Global?',
          globalNoDisponible:'El costo global no está disponible si indica un costo en los valores predeterminados',
          sinDiligenciar:'Dejar sin diligenciar si no se requiere',
          paso3:'Los datos serán validados en el paso 3.',
          diligenciarDatos:'Diligenciar Datos',
          seleccionarElementos:'Seleccionar Elementos',
          valoresPredeterminados:'Indicar Valores Predeterminados',
          unElemento:'Seleccione al menos un elemento',
          elementosADiligenciar:'Debe seleccionar elementos a diligenciar para poder realizar un registro de costos',
          completeAsistente:'Complete el asistente para realizar el registro',
          seleccioneFiltro:'Debe seleccionar filtros válidos para diligenciar las políticas particulares de los elementos correspondientes.',
          algunosElementos:'Algunos elementos no corresponden con los filtros o con las validaciones de su tipo de dato.',
          noCrear:'No se puede crear el registro si no se han cargado elementos válidos.',
          valorItem:'El valor del ítem debe ser positivo y es obligatorio',
          obsObligatoria:'La observación es obligatoria',
          codigoRepetido:'El codigo esta repetido',
          noCrear2:'El registro no puede crearse hasta que se complete la validación de los elementos.',
          fields: {
            elementos:'Elementos',
            unidadFuncional: 'Unidad Funcional',
            tramo: 'Tramo',
            segmento: 'Segmento',
            abInicio: 'Abscisa Inicial',
            abFin: 'Abscisa Final',
            sistema: 'Sistema',
            tipoElementoSenal: 'Elemento',
            tipoSenal: 'Tipo de Elemento',
            tipoElemento: 'Elemento',
            isMasiveLoading: 'Carga Masiva',
            valorGlobal: 'Suma Global',
            tipoMantenimiento: 'Tipo de Mantenimiento',            
            fechaMantenimiento: 'Fecha del Mantenimiento',
            costoMantenimiento: 'Costo del Mantenimiento',
            proveedor: 'Proveedor',
            calificacion: 'Calificación',
            observaciones: 'Observaciones',
            codigo: 'Código en Inventario',
            genericTipoElemento: 'Elemento',
            costoTotal: 'Costo Total',
            createdAt: 'Fecha creación',
            createdBy: 'Creado por',
            updatedAt: 'Fecha actualización',
            updatedBy: 'Actualizado por',
            fechaInicial: 'Fecha Inicial',
            fechaFinal: 'Fecha Final',
            consolidaror: 'Consolidar Por',
            id: 'ID',
            fecha: 'Fecha',
            sinResultados:'Sin resultados',
            registroCostos:'Registro de costos',
            pasoAnterior:'Ir al paso anterior',
          }
        },
        flujo_cajas: {
          name: 'Flujo de Caja |||| Flujos de Caja',
          generarExcel:'Generar excel',
          generarExcel2:'¿Generar Excel?',
          generarArchivo:'¿Generar archivo de Excel para la Matriz?',
          borrarMatriz:'Borrar Matriz?',
          fields: {
            unidadFuncional: 'Unidad Funcional',
            nombrePolitica: 'Nombre de Políticas',
            tasaInteres: 'Tasa de Interés',
            fechaInicio: 'Fecha de Inicio del Cálculo',
            years: 'Periodicidad',
            id: 'ID',
            createdAt: 'Fecha creación',
            createdBy: 'Creado por',
            updatedAt: 'Fecha actualización',
            updatedBy: 'Actualizado por',
            fecha: 'Fecha',
            status: 'Status',
            acciones: 'Acciones',
          }
        },
        indicador_complementarios: {
          name: 'Conf. Ind. Complementario |||| Conf. Ind. Complementarios',
          fields: {
            nombre: 'Nombre indicador',
            estado: 'Estado',
            umbralFallo: 'Umbral de fallo',
            proyecto: 'Proyecto',
            sistema: 'Sistema',
            tipoElemento: 'Elemento',
            tipoElementoSenal: 'Elemento',
            tipoSenal: 'Tipo de elemento',

            pregunta: 'Pregunta',
            peso: 'Peso',
            tipoCampo: 'Tipo Campo',
            decimales: 'Precisión decimales',
            respuesta: 'Respuesta',
            rangoInicial: 'Rango inicial',
            rangoFinal: 'Rango final',
            respuestasMult: 'Respuestas Múltiples',
          }
        },
        respuesta_evaluacion_complementarios: {
          name: 'Indicador Complementario |||| Indicadores Complementarios',
          fields: {
            unidadFuncional: 'Unidad Funcional',
            estado:'Estado',
            aprobacion: 'Aprobación',
            tramo: 'Tramo',
            segmento: 'Segmento',
            sistema: 'Sistema',
            tipoElemento: 'Tipo elemento',
            codigo:'Código',
            codigoElemento:'Código elemento',
            tipoElementoSenal: 'Tipo elemento',
            elemento: 'Elemento',
            fechaMedicion: 'Fecha de medición',
            accion: 'Acción',
            observaciones: 'Observaciones',
            indicadores: 'Indicadores',
            pregunta: 'Pregunta',
            respuesta: 'Respuesta',
            peso:'Peso',
            pesoTotal:'Peso total',
            uFallo:'Umbral de fallo',
            estadoVal:'Estado de validación',
            smartCode: 'Código',
            createdAt: 'Fecha creación',
            createdBy: 'Creado por',
            updatedAt: 'Fecha actualización',
            updatedBy: 'Actualizado por',
          }
        },
        registro_novedads: {
          name: 'Configuración de Novedad |||| Configuración de Novedades',
          fields: {
            nombre: 'Nombre novedad',
            estado: 'Estado',
            umbralFallo: 'Umbral de fallo',
            proyecto: 'Proyecto',
            sistema: 'Sistema',
            tipoElemento: 'Elemento',
            tipoElementoSenal: 'Elemento',
            tipoSenal: 'Tipo de elemento',

            pregunta: 'Pregunta',
            peso: 'Peso',
            tipoCampo: 'Tipo Campo',
            decimales: 'Precisión decimales',
            respuesta: 'Respuesta',
            rangoInicial: 'Rango inicial',
            rangoFinal: 'Rango final',
            respuestasMult: 'Respuestas Múltiples',
          }
        },
        respuesta_evaluacion_novedads: {
          name: 'Reg. Novedad-Medición |||| Reg. Novedades-Mediciones',
          fields: {
            sistema: 'Sistema',
            tipoElemento: 'Tipo elemento',
            tipoElementoSenal: 'Tipo elemento',
            tipoRegistro: 'Tipo de Registro',
            unidadFuncional: 'Unidad Funcional',
            tramo: 'Tramo',
            segmento: 'Segmento',
            elemento: 'Elemento',
            abscisaIni: 'Abscisa',
            abscisaFin: 'Abscisa',
            latitudInicial: 'Latitud',
            latitudFinal: 'Latitud',
            longitudInicial: 'Longitud',
            longitudFinal: 'Longitud',
            deterioro: 'Deterioro',
            fechaRegistro: 'Fecha de registro',
            severidad: 'Severidad',
            longitud: 'Longitud (m)',
            ancho: 'Ancho (m)',
            areaAfectada: 'Área afectada (m²)',
            estado: 'Estado',
            aprobacion: 'Aprobación',
            modelo: 'Modelo',
            codigo: 'Código',
            retroreflectColorPrincipal: 'Retro-reflectividad color principal (cd/lux/m²)',
            disminucionPrincipal:'Disminución color principal (%)',
            retroreflectColorSecundario: 'Retro-reflectividad color secundario (cd/lux/m²)',
            disminucionSecundario:'Disminución color secundario (%)',
            posicion: 'Posición',
            legibilidad: 'Legibilidad',
            novedad:'Novedad',
            novedades: 'Novedades', 
            pregunta: 'Pregunta',
            respuesta: 'Respuesta',
            genericTipoElemento: 'Elemento',
            observaciones: 'Observaciones',
            indicadores:'Indicadores',
            peso:'Peso',
            pesoTotal:'Peso total',
            uFallo:'Umbral de fallo',
            estadoVal:'Estado de validación',
            smartCode: 'Código Elemento',
            createdAt: 'Fecha creación',
            createdBy: 'Creado por',
            updatedAt: 'Fecha actualización',
            updatedBy: 'Actualizado por',
          }
        },
        aprobacions: {
          name: 'Aprobación |||| Aprobaciones',
          title:'Lista de aprobaciones',
          fields: {
            unidadFuncional: 'Unidad Funcional',
            tipoRegistro: 'Tipo de Registro',
            aprobacion: 'Aprobación',
            tipoRegistro: 'Tipo',
            actividad: 'Actividad',
            fecha: 'Fecha',
            unidadFuncional: 'Unidad Funcional',
            usuario: 'Usuario',
          }
        },
        carga_masivas: {
          name: 'Carga masiva |||| Cargas masivas',
          fields: {
            nombreGrupo: 'Nombre de grupo',
            cargados: 'Cargados',
            enProceso: 'En Proceso',
            proyectoList: 'Proyecto:',
            rutaList: 'Ruta:',
            unidadFuncional: 'Unidad Funcional:',
            tramoList: 'Tramo:',
            sistemaList: 'Sistema:',
            subSistema: 'Sub sistema',
            sistemaEstrucCont: 'Sistema: Estructuras de contención',
            sistemaPuentes: 'Sistema: Puentes',
            sistemaPontones: 'Sistema: Pontones',
            sistemaTuneles: 'Sistema: Túneles',
            imagenes: 'Imagenes:',
            nombreGrupo: 'Nombre Grupo',
            proyecto: 'Proyecto',
            ruta: 'Ruta',
            unidadSave: 'Unidad Funcional',
            tramo: 'Tramo',
            sistema: 'Sistema',
            arrastrarSoltar:'Arrastrar y soltar archivos o hacer clic para seleccionar archivos',
          }
        },
        validaciones:{
          fechaFutura:'La fecha indicada no puede ser futura.',
          fechaAPartir:'La fecha debe ser a partir del año 2000.',
          abscisaInicial:'La Abscisa inicial debe estar entre.',
          abscisaInicialDebeSerMenor:'La Abscisa Inicial debe ser menor a la Abscisa Final.',
          abscisaFinal:'La Abscisa Final debe estar entre.',
          abscisaFinalDebeSerMayor:'La Abscisa Final debe ser mayor a la Abscisa inicial.',
          enteroPositivo:'El número debe ser un entero positivo.',
          max10:'Máximo 10 caracteres.',
          max15:'Máximo 15 caracteres.',
          max40:'Máximo 40 caracteres.',
          max20:'Máximo 20 caracteres.',
          max50:'Superado maximo de caracteres: 50 max.',
          max90:'El valor máximo debe ser 90.',
          min90:'El valor mínimo debe ser -90.',
          mayor90:'El valor ingresado debe mayor a -90.',
          max180:'El valor máximo debe ser 180.',
          min180:'El valor mínimo debe ser -180.',
          max200:'Máximo 200 caracteres.',
          edadEntre:'La Edad  debe estar entre 0 y 122.',
          cantidadEntre:'La cantidad debe estar entre 0 y 100.',
          sinAcciones:'Sin Acciones Disponibles.',
          millardos:'El costo no debe ser 100 millardos o más.',
          fechaInicio:'Fecha de inicio no puede ser mayor que fecha final (Revise la fecha de inicio).',
          planComenzar:'El plan debe comenzar al menos hoy. (Revise la fecha de inicio).',
          planComenzar2:'El plan debe comenzar al menos hoy.',
          entero:'Debe ser un número entero.',
          coordenadaValida:'Ingrese un coordenada valida.',
          distanciaAbscisa:'La distancia entre las Abscisas de ser menor a 1.999.', 
          altitudMaxima:'La altitud máxima debe ser mayor a la altitud mínima.',
          decimales1:'El valor ingresado solo puede tener 1 decimal.',
          decimales2:'El valor ingresado solo puede tener 2 decimales.',
          decimales3:'El valor ingresado solo puede tener hasta 3 decimales.',
          distanciaPr:'La distancia entre los Pr debe ser menor a 1.999.',
          distanciaPrMenor:'La distancia entr los Pr deber ser de al menos  de 1.000.',
          rangoEntre:'El rango debe estar entre.',
          mayorK9:'El valor ingresado no puede ser mayor a K999+999.',
          abscisaFin:'Abscisa fin debe ser mayor a Abscisa inicio.',
          mayorPR9:'El valor ingresado no puede ser mayor a PR999+999.',
          prFin:'PR fin debe ser mayor a PR inicio.',
          coordenadasDecimal:'El valor ingresado en las coordenadas debe ser decimal.',
          decimal:'El valor ingresado debe ser decimal.',
          latitudMaxima:'La latitud máxima debe ser mayor a la latitud mínima.',
          longitudMaxima:'La longitud máxima debe ser mayor a la longitud mínima.',
          datoDigitado:'El dato digitado ya se encuentra en uso.',
          correoInvalido:'Correo Invalido.',
          correoEnUso:'El dato digitado ya se encuentra en uso.',
          anioIgual:'Los años no pueden ser iguales.',
          anioMayorFinal:'El año inicial no puede ser mayor al año final.',
          mayor_a_0:'El numero ingresado debe ser mayor a 0.',
          mayor_a_10:'El valor debe ser mayor a 10.',
          menor_a_5:'El numero ingresado debe ser menor a 5.',
          menor_a_30:'El valor debe ser menor a 30.',
          umbralSumatoria:'El umbral de fallo, no puede ser mayor a la sumatoria del peso de las preguntas.',
          pesoRespuesta:'El peso de la respuesta, no puede ser mayor al valor del peso de la pregunta No.',
          noIntersectando:'El número no se está intersectando.',
          noNegativo:'El número no debe ser negativo.',
          valNumMayorA0:'El numero ingresado debe ser menor a 1 y mayor a 0.',
          cargasIndicador:'No se encontraron cargas de datos publicadas para el indicador.',
          absInicialDeCorreccionMenor:'La abscisa inicial de corrección no puede ser menor a la abscisa inicial.',
          absInicialDeCorreccionMayor:'La abscisa inicial de corrección no puede ser mayor a la abscisa final.',
          absFinalDeCorreccionMenor:'La abscisa final de corrección no puede ser menor a la abscisa inicial.',
          absFinalDeCorreccionMayor:'La abscisa final de corrección no puede ser mayor a la abscisa final.',
          unElemento:'Debe escoger al menos un elemento.',
          politicaGeneral:'Sólo debe usar una política general como base para política particular. ',
          todosLosDatos:'Debe diligenciar todos los datos.',
          filtrosValidos:'Debe seleccionar filtros válidos para diligenciar las políticas particulares de los elementos correspondientes.',
          sinPoliticasAsociadas:'Sin Políticas Asociadas.',
          politicasDiligencias:'Asegurese de que los 4 tipos de políticas generales estén diligenciadas para el elemento.',
          mantenimientoOSuministro:'Debe seleccionar alguna de las dos opciones (mantenimiento o suministro).',
          abscisasTrazos:'No se encontraron abscisas en uno o más de los trazos.',
          coordenadaDevuelta:'Al menos uno de los grupos de coordenadas fue devuelto sin información. Asegurese de usar un KML generado por ArcGIS.',
          noCoincide:'La unidad funcional en el archivo no coincide con la unidad funcional seleccionada.',
          trazosDivididos:'Los trazo no se encuentran divididos correctamente.',
          errorArchivo:'Hubo un error al leer el archivo.',
          mayorA:'Debe ser mayor a.',
          rangoNoValido:'Rango no válido.',
          contraseniaNoCoindice:'La contraseña no coinciden.',
          valNombre:'El nombre no es válido',
          valApellido:'El apellido no es válido',
          valEliminacion:'No se puede eliminar',
          valRegistroAsociado:'Se tienen registros del inventario asociados a este elemento. Por favor validar.',
          valBorrar:'¿Estás seguro de que quieres borrar este elemento?',
          borrar:'BORRAR',
          valBorrarUnidadFuncional:'Borrar unidad funcional',
          valBorrarProyecto:'Borrar proyecto',
          valBorrarRuta:'Borrar ruta',
          valBorrarTramo:'Borrar tramo',
          valBorrarSegmentos:'Borrar segmento',
        },
        audits:{
          name: 'Auditoría |||| Auditorías',
          fields:{
            type: 'Actividad',
            tableOrigin: 'Tabla',
            old: 'Información anterior',
            new: 'Información nueva',
            alteredValue: 'Información afectada', 
            user: 'Usuario',
            idElement: 'ID Elemento',
            module: 'Módulo',
            functionality: 'Funcionalidad',
            subElement: 'Elemento',
            smartCode: 'Código inteligente',
            createdIn: 'Creado en',
            dateIn: 'Fecha',
            tableMaster: 'Tabla principal',
            fechaInicial: 'Fecha Inicial',
            fechaFinal: 'Fecha Final',
          },
          tabs:{
            title: "Información auditoría",
            tabDetalles: "Detalle",
            tabIdentificacion: "Identificación",
            tableShow:{
              titleField: "Campo afectado",
              titleOld: "Información anterior",
              titleNew: "Información nueva",
            },
          }
        }
    },
    dynatest : {
        resources: {
            imagenesInventario: {
              titleImagen: 'Tipo de elemento',
              descripcionImagen: 'Arrastra algunas imágenes para empezar la subida o presiona aquí para seleccionar una',
            },
            pimsMenu: {
                fields: {
                    inicio:'Inicio',
                    administracion: "Administración",
                    inventario: "Inventario",
                    transito: "Tránsito",
                    indicadores:"Indicadores",
                    gestion:"Gestión",
                    reportesQS:"Reportes QuickSight",
                    auditoria: "Auditoría",
                },
                subMenu: {
                  usuarios: 'Usuarios',
                  atributos: 'Atributos',
                  proyectos: 'Proyectos',
                  DisenarProyecto: 'Diseñar Proyecto',
                  Rutas: 'Rutas',
                  UnidadesFuncionales: 'Unidades funcionales',
                  Tramos: 'Tramos',
                  Segmentos: 'Segmentos',
                  Configuraciones: 'Configuraciones',
                  Materiales: 'Materiales',
                  Granulometría: 'Granulo - metría',
                  Indicadores: 'Indicadores',
                  ParamEvalRangosAlerta: 'Parámetros evaluación y Rangos de alerta',
                  DiagramaColorimetrico: 'Diagrama colori - métrico',
                  IndicadoresComplementarios: 'Indicadores comple - mentarios',
                  DeterioroPERS: 'Deterioro PERS',
                  Reparaciones: 'Reparación',
                  Deterioros: 'Deterioros',
                  Contactos: 'Contactos',
                  Emergencias: 'Emergencias',
                  Proveedores: 'Proveedores',
                  ServiciosPublicos: 'Servicios Públicos',
                  Mapa: 'Mapa',
                  Segmentos: 'Segmentos',
                  Flexible: 'Flexible',
                  Rigido: 'Rígido',
                  Adoquin: 'Adoquín',
                  Otro: 'Otro',
                  SistemasContencionVehicular: 'Sistemas de Contención Vehicular',
                  Drenaje: 'Drenaje',
                  Senalizacion: 'Señalización',
                  Alrededores: 'Alrededores',
                  PuentesEstructurasEspe: 'Puentes y estructuras especiales',
                  Tuneles: 'Túneles',
                  Contencion: 'Contención',
                  Ponton: 'Pontón',
                  Puentes: 'Puentes',
                  OtrosElementos: 'Otros elementos',
                  Redes: 'Redes',
                  Peajes: 'Peajes',
                  Inventario: 'Inventario',
                  TransitoHistorico: 'Tránsito Histórico',
                  EstacionConteo: 'Estación de conteo',
                  Indicadores4G: 'Indicadores 4G',
                  CargarInformacion: 'Ver y cargar Información',
                  E15Puentes: 'E15 Puentes',
                  Complementarios:'Complementarios',
                  DisponibilidadVia: 'Disponibilidad de Vía',
                  Programacion: 'Programación',
                  ParametrosPlaneacion: 'Parámetros de planeación',
                  OtrosSistemas: 'Otros Sistemas',
                  PoliticasGenerales: 'Políticas Generales',
                  PoliticasParticulares: 'Políticas Particulares',
                  PavimentosPERS: 'Pavimentos - PERS',
                  AlternativasIntervencion: 'Alternativas de intervención',
                  ReparacionesMenores: 'Reparaciones Menores',
                  ExportarBasePers: 'Exportar base PERS',
                  ReportesPavimentos: 'Importar base PERS',
                  RegistroCostos: 'Registro de Costos',
                  FlujoCaja: 'Flujo de Caja',
                  ReportesConfig: 'Configuración Reportes',
                  ReportesPers: 'Reportes PERS',
                  ReportesInventario: 'Reportes Inventario',
                  ReportesIndicadores: 'Reportes Indicadores',
                  ReportesCostosMantenimiento: 'Reportes Costos - Mantenimiento',
                  ReportesTransito: 'Reportes Tránsito',
                  ReportesContactos: 'Otros Reportes',
                  ReportesModelacion: 'Reportes Modelación',
                  AplicacionMovil: 'Aplicación Móvil',
                  Novedades: 'Novedades',
                  Aprobacion: 'Aprobación',
                  ConfiguracionGeneral: 'Configuración General',
                  Idioma: 'Idioma',
                  Sistema: 'Sistema',
                  Audit: 'Auditoría',
                  cargaMasiva: 'Carga Masiva',
                }
            },
            idiomas: {
            formTabs:{
                 seleccionIdiomaBase: 'Seleccione el idioma base',
                 cargueArchivos: 'Cargar archivos'
                }
            },
            usuarios:{
              title:{
                editTitle: 'Editar Usuario',
                showTitle: 'Información Usuario'
              },
              formTabs:{
                identificacionTitle: 'Identificación',
              },
              select:{
                documento:{
                  cedula_ciudadania: 'Cédula de Ciudadanía',
                  cedula_extrangeria: 'Cédula de extrangería',
                  nit: 'NIT',
                }
              },
              tabs: {
                informacionUsuario: 'Información del usuario',
                informacionBasica: 'Información básica',
                auditoria : 'Auditoria',
                acceso_usuario_app: 'ACCESOS DEL USUARIO EN LA APLICACIÓN',
                administracion: 'ADMINISTRACIÓN',
                proyectosMay: 'PROYECTOS',
                proyectosMin: 'Proyectos',
                confMateriales: 'Configuraciones - Materiales',
                confIndicadores: 'Configuraciones - Indicadores',
                confDeterioro: 'Configuraciones - Deterioro PERS',
                contactos: 'Contactos',
                elem_inventario: 'ELEMENTOS INVENTARIO',
                segmentos_cal: 'Segmentos (Calzada)',
                inventario: 'Inventarios',
                puentes_estructuras: 'Puentes y estructuras especiales',
                transito: 'TRÁNSITO',
                indicadoresMay: 'INDICADORES',
                indicadoresMin: 'Indicadores',
                gestion: 'GESTIÓN',
                programacion: 'Programación',
                otros_sistemas: 'Otros Sistemas',
                pavimento_pers: 'Pavimento PERS',
                flujo_cajaTitle: 'Flujo de Caja',
                idiomas: 'Idiomas',
                configGeneral: 'Configuración General',
                configReports: 'Configuración Reportes',
                configAudit: 'Auditoría',
                configNovedades : 'Configuraciones - Novedades',
                cargaMasiva : 'Carga masiva',
                registroCostos: 'Registro de Costos',
                aplicacionMovil: 'APLICACIÓN MÓVIL'
            },
            },
            atributos:{
              formTabs:{
                administracion: 'Administración',
                proyecto: 'Proyecto',
                inventario: 'Inventario',
                transito: 'Tránsito',
                indicadores: 'Indicadores',
                gestion: 'Gestión',
                reportesQS: 'Reportes QuickSight',
                auditoria: 'Auditoría',
                aplicacionMovil: 'Aplicación Móvil'

              },
                select:{
                  acceso:{
                    acceso_completo: 'Acceso Completo',
                    acceso_parcial: 'Acceso Parcial',
                    sin_acceso: 'Sin Acceso',
                    visualiza: 'Visualiza',
                    noDefinido: 'No Definido'
                  },
                  tipo_rol:{
                    administrativo: 'Administrativo',
                    campo: 'Campo',
                    oficina: 'Oficina',
                  }
              },
              title: {
                proyectos: 'Proyectos',
                configMateriales: 'Configuraciones - Materiales',
                configIndicadores: 'Configuraciones - Indicadores',
                configDeterioroPers: 'Configuraciones - Deterioro PERS',
                contactos: 'Contactos',
                segmentos: 'Segmentos (Calzada)',
                elemInventario: 'Elementos Inventario',
                puentesEstructurasEspec: 'Puentes y Estructuras Especiales',
                programacion: 'Programación',
                otrosSistemas: 'Otros Sistemas',
                pavimentoPers: 'Pavimento PERS',
                flujoCaja: 'Flujo de Caja',
                editTitle: 'Atributos',
                showTitle: 'Atributos',
                configNovedades: 'Configuraciones - Novedades',
                complementarios: 'Complementarios',
                respuestaEvaluacionNovedad: 'Novedades',
                novedades: 'Novedades',
                aprobacion: 'Aprobación',
                configGeneral: 'Configuración General',
                configReports: 'Configuración Reportes',
                configAudit: 'Auditoría',
                registroCostos: 'Registro de Costos',
                cargaMasiva: 'Carga Masiva',
            }
            },
            proyectos:{
              title:{
                coordMinimas: 'Coordenadas mínimas',
                coordMaximas: 'Coordenadas máximas',
                altura: 'Altura',
                editTitle: 'Editar Proyecto',
                showTitle: 'Información Proyecto'
              },
              tabs: {
                informacionBasica: 'Información básica',
                auditoria : 'Auditoria',
              },
              buttons:{
                anadirKML: ' Añadir KML'
              }
            },
            rutas:{
              title:{
                inforInvias: 'Información INVIAS',
                editTitle: 'Editar Ruta',
                showTitle: 'Información Ruta'
              },
              select: {
                categoria:{
                priOrden: 'Primer orden',
                segOrden: 'Segundo orden',
                terOrden: 'Tercer orden',
                }
              },
              tabs:{
                informacionBasica: 'Información básica',
                auditoria : 'Auditoria',
                }
            },
            unidad_funcionals:{
              title: {
                editTitle: 'Editar Unidad Funcional',
                showTitle: 'Información Unidad Funcional'
              },
              tabs:{
                informacionBasica: 'Información básica',
                auditoria : 'Auditoria',
                } 
            },
            tramos:{
              title:{
                inforTramo: 'Información Tramo',
                genMasvSeg: 'GENERACIÓN MASIVA SEG.',
                editTitle: 'Editar Tramo',
                showTitle: 'Información Tramo'
              },
              tabs:{
                informacionBasica: 'Información básica',
                auditoria : 'Auditoria',
                } 
            },
            segmentos:{
              title:{
                localizacion:'Localización',
                caracteristicas:'Características',
                coorIniciales:'Coordenadas Iniciales',
                coorFinales:'Coordenadas Finales',
                showTitleRamal: 'Información de Ramal',
                showTitleSegmento: 'Información de Segmento',
                listTitle: 'Lista de Segmentos - Ramales',
                createTitleRamal: 'Crear Ramal',
                createTitleSegmento: 'Crear Segmento',
                createTitle: 'Crear',
                editTitleRamal: 'Editar Ramal',
                editTitleSegmento: 'Editar Segmento',
                editTitle: 'Editar',
                tranSegmento: 'Transformación'
              },
              tabs:{
                informacionBasica: 'Información básica',
                auditoria : 'Auditoria',
              },
              select:{
                ramal: 'Ramal',
                segmento: 'Segmento',
                izq: 'Izquierdo',
                der: 'Derecho'
              },
              messagesVal: {
                abscisaSegmento: 'Error el rago debe estar entre ',
                prIngresado:'El Pr ingresado debe ser mayor a ',
                absInicial:'La Abscisa inicial debe ser mayor a',
                rango:'Rango no válido',
                prRango:'Rango de Pr no válido',
              }
            },
            materials:{
              selectMaterials: {
                adoquinEnArcilla: 'Adoquín en arcilla',
                amadoquinDeConcreto: 'Adoquines de concreto',
                afirmado: 'Afirmado',
                baseAsfaltica: 'Base asfáltica',
                baseDeConcHidraulico: 'Base de concreto hidráulico',
                baseEstOtros: 'Base estabilizada - otros',
                baseEstbAsfalto: 'Base estabilizada con asfalto espumado',
                baseEstEmulsion: 'Base estabilizada con emulsión asfáltica',
                baseGranular: 'Base granular',
                baseTratada: 'Base tratada con cemento',
                concHidraulico: 'Concreto hidráulico',
                lechAsfaltica: 'Lechada ásfaltica',
                mezAbierCaliente: 'Mezcla abierta en caliente',
                mezAbierFrio: 'Mezcla abierta en frío',
                mezAsfalGraCaucho: 'Mezcla asfáltica modificada con grano de caucho',
                mezAsfalModPolimeros: 'Mezcla asfáltica modificada con polímeros',
                mezDenCaliente: 'Mezcla densa en caliente',
                mezDenFrio: 'Mezcla densa en frío',
                mezDrenante: 'Mezcla drenante',
                microagloCaliente: 'Microaglomerado en caliente',
                subBaseAsfalEspumado: 'Sub base asfalto espumado',
                subBaseEstabOtros: 'Sub base estabilizada - otros',
                subBaseEstabAsfalto: 'Sub base estabilizada con asfalto',
                subBaseEstabCemento: 'Sub base estabilizada con cemento',
                subBaseEstabGranular: 'Sub base granular',
                subraEstabCal: 'Subrasante estabilizada con cal',
                subraEstabCemento: 'Subrasante estabilizada con cemento',
                subraEstabGeomalla: 'Subrasante estabilizada con geomalla',
                subraEstabGeotextil: 'Subrasante estabilizada con geotextil',
                subraFina: 'Subrasante fina',
                subraGranular: 'Subrasante granular',
                subraCemento: 'Suelo cemento',
              },
              selectOption:{
                otros: 'Otro',
                noLigado: 'No Ligado',
                bituminoso: 'Bituminoso',
                cementado: 'Cementado'
              },
              tabs:{
                informacionBasica: 'Información básica ',
                auditoria : 'Auditoria',
              }
            },
            granulometrias:{
              title: {
                showTitle: 'Información Granulometría',
                ediTitle: 'Editar granulometría',
              },
              select:{
                tipoMaterial:{
                adoquinEnArcilla: 'Adoquín en arcilla',
                amadoquinDeConcreto: 'Adoquines de concreto',
                afirmado: 'Afirmado',
                baseAsfaltica: 'Base asfáltica',
                baseDeConcHidraulico: 'Base de concreto hidráulico',
                baseEstOtros: 'Base estabilizada - otros',
                baseEstbAsfalto: 'Base estabilizada con asfalto espumado',
                baseEstEmulsion: 'Base estabilizada con emulsión asfáltica',
                baseGranular: 'Base granular',
                baseTratada: 'Base tratada con cemento',
                concHidraulico: 'Concreto hidráulico',
                lechAsfaltica: 'Lechada ásfaltica',
                mezAbierCaliente: 'Mezcla abierta en caliente',
                mezAbierFrio: 'Mezcla abierta en frío',
                mezAsfalGraCaucho: 'Mezcla asfáltica modificada con grano de caucho',
                mezAsfalModPolimeros: 'Mezcla asfáltica modificada con polímeros',
                mezDenCaliente: 'Mezcla densa en caliente',
                mezDenFrio: 'Mezcla densa en frío',
                mezDrenante: 'Mezcla drenante',
                microagloCaliente: 'Microaglomerado en caliente',
                subBaseAsfalEspumado: 'Sub base asfalto espumado',
                subBaseEstabOtros: 'Sub base estabilizada - otros',
                subBaseEstabAsfalto: 'Sub base estabilizada con asfalto',
                subBaseEstabCemento: 'Sub base estabilizada con cemento',
                subBaseEstabGranular: 'Sub base granular',
                subraEstabCal: 'Subrasante estabilizada con cal',
                subraEstabCemento: 'Subrasante estabilizada con cemento',
                subraEstabGeomalla: 'Subrasante estabilizada con geomalla',
                subraEstabGeotextil: 'Subrasante estabilizada con geotextil',
                subraFina: 'Subrasante fina',
                subraGranular: 'Subrasante granular',
                subraCemento: 'Suelo cemento',
                }
              },
              tabs:{
                informacionBasica: 'Información básica ',
                auditoria : 'Auditoria',
              }
            },
            generacion_masiva_segmentos:{
              title:{
               validSeg: '¡Validando segmentos!',
               uniFuncTram: '¡La unidad funcional y el tramo ya tienen segmentos, no se pueden crear masivamente!',
               coorIniciales: 'Coordenadas iniciales',
               coorFinales: 'Coordenadas Finales',
               caracteristicas: 'Características'
              },
              button:{
                asigValSegmentos: 'Asignar valores por defecto a los  segmentos',
                generSegmentos: 'Generar Segmentos',
                cancelar: 'Cancelar',
              },
              table: {
                nameColumns: {
                  absInicio: 'Abs Inicio',
                  absFinal: 'Abs Final',
                  longitud: 'Longitud',
                  prInicio: 'PR Inicio',
                  prFinal: 'PR Final',
                  departamento: 'Departamento',
                  municipio: 'Municipio',
                  latitudInicial: 'Latitud Inicial',
                  longitudInicial: 'Longitud Inicial',
                  alturaInicial: 'Altura Inicial',
                  latitudFinal: 'Latitud Final',
                  longitudFinal: 'Longitud Final',
                  alturaFinal: 'Altura Final',
                  tipoCalzada: 'Tipo Calzada',
                  tipoSegmento: 'Tipo Segmento',
                  tipoSuperficie: 'Tipo Superficie',
                },
                nameRows: {
                  departamento: 'Departamento',
                  municipio: 'Municipio',
                  latitudInicial: 'Latitud Inicial',
                  longitudInicial: 'Longitud Inicial',
                  alturaInicial: 'Altura Inicial (m.s.n.m)',
                  latitudFinal: 'Latitud Final',
                  longitudFinal: 'Longitud Final',
                  alturaFinal: 'Altura Final (m.s.n.m)',
                  tipoCalzada: 'Tipo Calzada',
                  tipoSegmento: 'Tipo Segmento',
                  tipoSuperficie: 'Tipo Superficie',
                }
              }
            },
            transformacion_segmentos:{
              title:{
               validSeg: '¡Validando segmentos!',
               uniFuncTram: '¡La unidad funcional y el tramo ya tienen segmentos, no se pueden crear masivamente!',
               coorIniciales: 'Coordenadas iniciales',
               transformarSegmentos: 'Transformar segmentos',
              },
              button:{
                generSegmentos: 'Generar Segmentos',
                cancelar: 'Cancelar',
                filtrar: 'Filtrar',
                transformar: 'Transformar',
                confirmar:'Confirmar',
                generar:'GENERAR',
                agregar:'AGREGAR',
                remover:'REMOVER',
              },
              radioSel: {
                modificarSegmentos: 'Modificar segmentos y sus características',
                modificarTrazado: 'Modificar trazado (Reemplazar segmentos)',
              },
              table: {
                nameColumns: {
                  codigo: 'Código',
                  absInicio: 'Abs Inicio',
                  absFinal: 'Abs Final',
                  longitud: 'Longitud',
                  longitudCol: 'Longitud(km)',
                  prInicio: 'PR Inicio',
                  prFinal: 'PR Final',
                  departamento: 'Departamento',
                  municipio: 'Municipio',
                  latitudInicial: 'Latitud Inicial',
                  longitudInicial: 'Longitud Inicial',
                  alturaInicial: 'Altura Inicial',
                  latitudFinal: 'Latitud Final',
                  longitudFinal: 'Longitud Final',
                  alturaFinal: 'Altura Final',
                  tipoCalzada: 'Tipo Calzada',
                  tipoSegmento: 'Tipo Segmento',
                  tipoSuperficie: 'Tipo Superficie',
                },
                nameRows: {
                  departamento: 'Departamento',
                  municipio: 'Municipio',
                  latitudInicial: 'Latitud Inicial',
                  longitudInicial: 'Longitud Inicial',
                  alturaInicial: 'Altura Inicial (m.s.n.m)',
                  latitudFinal: 'Latitud Final',
                  longitudFinal: 'Longitud Final',
                  alturaFinal: 'Altura Final (m.s.n.m)',
                  tipoCalzada: 'Tipo Calzada',
                  tipoSegmento: 'Tipo Segmento',
                  tipoSuperficie: 'Tipo Superficie',
                }
              }
            },
            reparacion_localizadas:{
              title:{
                editTitle: 'Editar Reparacion PERS',
                showTitle: 'Mostrar Reparacion PERS'
              },
              select: {
                tipoReparacion:{
                  PFLEX: 'Flexible',
                  PRIGD: 'Rígido',
                  PART: 'Adoquín',
                  POTR: 'Otro',
                }
              },
              tabs:{
                reparacionPers: 'Reparación PERS',
                auditoria : 'Auditoría'
              }
            },
            tipo_deterioro_pers:{
              title:{
                editTitle: 'Editar Deterioro PERS',
                showTitle: 'Mostrar Deterioro PERS'
              },
              select: {
                tipoSuperficie:{
                  PFLEX: 'Flexible',
                  PRIGD: 'Rígido',
                  PART: 'Adoquín',
                  POTR: 'Otro',
                },
                nivelSeveridad:{
                  1: '1',
                  2: '2',
                  3: '3'
                },
                unidad:{
                  m2: 'm2',
                  m: 'm',
                  losa: 'losa'
                }
              },
              tabs:{
                reparacionPers: 'Reparación PERS',
                auditoria : 'Auditoría'
              }
            },
            contacto_emergencias:{
              title:{
                editTitle: 'Editar Información Contacto',
                showTitle: 'Mostrar Información Contacto'
              },
              select: {
                grupo: {
                  alcaldia: 'Alcaldía Prueba',
                  bomberos: 'Bomberos',
                  centroEducativo: 'Centro educativo',
                  defensaCivil: 'Defensa civil',
                  ejercito: 'Ejército',
                  emisoraRadial: 'Emisora radial',
                  interventoria: 'Interventoría',
                  maquinaria: 'Maquinaria',
                  otros: 'Otros',
                  pOB_Administrativa: 'POB-Administrativa',
                  pOB_Ambulancia: 'POB-Ambulancia',
                  pOB_CarroTaller: 'POB-Carro taller',
                  pOB_Gruas: 'POB-Grúas',
                  pOB_Peajes: 'POB-Peajes',
                  pOB_RadioOperacion: 'POB-Radio Operación',
                  pOB_SupervisionVial: 'POB-Supervisión vial',
                  policia: 'Policía',
                  serviciosMedicos: 'Servicios médicos',
                  talleres: 'Talleres',
                },
                identificacion: {
                  CC: 'CC',
                  NIT: 'NIT',
                  Otro: 'Otro'
                }
              },
              tabs:{
                informacionBasica: 'Información básica',
                auditoria : 'Auditoría'
              }
            },
            colores:{
              title:{
                createTitle: 'Crear Diagrama colorimétrico',
                editTitle: 'Editar Color',
                showTitle: 'Información Color',
                punto1: 'Punto 1: ',
                punto2: 'Punto 2: ',
                punto3: 'Punto 3: ',
                punto4: 'Punto 4: ',
                valorMinX: 'Valor Mínimo x: ',
                valorMinY: 'Valor Mínimo y: ',
                valorMaxX: 'Valor Máximo x: ',
                valorMaxY: 'Valor Máximo y: ',
                valRetroReflec: 'Valores Retro-Reflectividad',
                rectSuper: 'Recta Superior',
                rectDer: 'Recta Derecha',
                rectInfe: 'Recta Inferior',
                rectIzq: 'Recta Izquierda',
                paramAreaColor: 'Parametros Área de Color',
                inforDiagColor: 'Información  Diagrama Colorimétrico',
                color: 'Colores',
                valorMinCump: 'Valor mínimo de cumplimiento', 
                diagrama: 'Diagrama',
              },
              formTabs:{
                identificacion: 'Identificación',
                auditoria: 'Auditoria'
              }
            },
            parametros_evaluacion:{
              select:{
                indicadores:{
                 indicadoresMessage: 'Indicadores que apliquen a: ',
                 todosParam:"Todos los Parámetros",
                 indiPavFlex:"Indicadores exclusivos para Pavimento Flexible",
                 indiPavRig:"Indicadores exclusivos para Pavimento Rígido",
                 indiPavRigFlex:"Indicadores exclusivos para Pavimento Rigido y Flexible",
                 indiPuentesEstr:"Indicadores exclusivos de Puentes y Estructuras",
                 indiTuneles:"Indicadores exclusivos para Túneles",
                 indiOperac:"Indicadores Operacionales",
                }
              },
              title:{
                grupoParamCrea:'Ya Tiene un Grupo de Parámetros Creados',
                editTitle: 'Editar Parámetros de Evaluación',
                showTitle: 'Mostrar Parámetros de Evaluación'
              },
              table: {
                titleColumns: {
                  frecuenciaMedicion:'Frecuencia de medición (meses).',
                  tiempoCorreccion:'Tiempo de corrección (meses).',
                  valormaxPuntual:'Valor máximo puntual (mm/m).',
                  valormaxMedio:'Valor máximo medio (mm/m).',
                  indicador: 'Indicador',
                  aplica: 'Aplica',
                  valorAlerta: 'Valor de alerta',
                  datosMinmodelacion: 'Datos mínimos para modelación',
                  tiempoGenerarAlerta: '¿Cuánto tiempo antes generar alerta de medición?',
                },
                si: 'Si',
                no: 'No',
              }
            },
            proveedors:{
              title:{
                editTitle: 'Editar Proveedor',
                showTitle: 'Información Proveedor es'
              },
              tabs:{
                proyecto: 'PROYECTO',
                informacionProveedor: 'Información básica',
                calificacion: 'CALIFICACIÓN',
                auditoria : 'Auditoría'
              },
              select: {
                identificacion: {
                  CC: 'CC',
                  NIT: 'NIT',
                  Otro: 'Otro'
                },
              },
              boolean:{
                mantenimiento: 'Mantenimiento',
                suministros: 'Suministros',
              },
              buttons:{
                calificarProveedor: 'Calificar proveedor'
              }
            },
            calificacion_proveedors: {
              title:{
                qualifyTitle: 'Calificar Proveedor',
              },
              select: {
                calificacion: {
                  1: '1',
                  2: '2',
                  3: '3',
                  4: '4',
                  5: '5'
                }
              },
              buttons:{
                save: 'GUARDAR',
                saveAndEnd: 'GUARDAR Y Calificar otro proveedor'
              },
              span:{
                descripcionCalificacion: 'Evalúe el proveedor en una calificación de 1 a 5, donde 5 significa un nivel "muy alto" y 1 un nivel "muy bajo"'
              }
            },
            servicios_publicos: {
              title: {
                editTitle: 'Editar Servicio Público',
                showTitle: 'Información Servicio Público'
              },
              select: {
                grupo: {
                  acueducto: 'Acueducto',
                  aseo: 'Aseo',
                  energia: 'Energía',
                  gasNatural: 'Gas natural',
                  otros: 'Otros',
                  telecomunicaciones: 'Telecomunicaciones'
                },
                identificacion: {
                  CC: 'CC',
                  NIT: 'NIT',
                  Otro: 'Otro'
                }
              },
              tabs: {
                informacionBasica: 'Información básica',
                auditoria: 'Auditoría'
              }
            },
            elementos: {
              title: {
                listTitle: 'Mapa general',
              },
              tilteFiltros: {
                filtros: 'Filtros',
                calzadas: 'Calzadas',
                sistemasContencion: 'Sistemas de Contención',
                drenajes: 'Drenajes',
                senalizacion: 'Señalización',
                alrededores: 'Alrededores',
                puentesEstrucEsp: 'Puentes y estructuras especiales',
                otrosElementos: 'Otros Elementos',
                redes: 'Redes'
              },
              titleIndicadores: {
                rangosColores: 'Rangos y Colores',
                iri: 'IRI',
                umbrales: 'Umbrales',
                colores: 'Colores',
                visualizacion: 'Visualización',
                pantallaConfiguración: 'Pantalla de configuración'
              },
              checkboxs: {
                flexible_detalles: 'Segmento Flexible',
                rigido_detalles: 'Segmento Rígido',
                adoquin_detalles: 'Segmento Adoquín',
                otro_detalles: 'Segmento Otros',
                seguridads: 'Sistemas de Contención',
                elemento_drenajes: 'Drenajes',
                elemento_senalizacions: 'Señalización',
                elemento_alrededors: 'Alrededores',
                tuneles: 'Túneles',
                elemento_contencions: 'Contención',
                elemento_pontons: 'Pontón',
                puentes: 'Puentes',
                otros_elementos: 'Otros Elementos',
                elemento_reds: 'Redes',
                Otro: 'Otro',
              },
              select: {
                tipoMapa: {
                  1: 'Indicadores',
                  2: 'Inventario',
                },
                numIntervalos: {
                  1: '1',
                  2: '2',
                  3: '3',
                  4: '4',
                  5: '5',
                  6: '6',
                  7: '7',
                  8: '8',
                }
              },
            },
            flexible_detalles: {
              title: {
                editTitle: 'Editar Segmento Flexible',
                showTitle: 'Información Segmento Flexible'
              },
              select:{
                sentido: {
                  'NORTEName': 'Norte',
                  NORTEId: 'NORTE',
                  'SURName': 'Sur',
                  SURId: 'SUR',
                  'ESTEName': 'Este',
                  ESTEId: 'ESTE',
                  'OESTEName': 'Oeste',
                  OESTEId: 'OESTE'
                },
                estadoDetalles: {
                  Activo: 'Activo',
                  Inactivo: 'Inactivo'
                },
                nivelTrafico: {
                  NT1: 'NT1',
                  NT2: 'NT2',
                  NT3: 'NT3',
                },
                geometriaTipoBerma: {
                  bermaCuneta: 'Berma Cuneta',
                  flexible: 'Flexible',
                  inexistente: 'Inexistente',
                  rigida: 'Rígida' 
                },
                geometriaCondicionBerma: {
                  bueno: 'Bueno',
                  regular: 'Regular',
                  deficiente: 'Deficiente'
                },
                geometriaSeparador: {
                  Si: 'Si',
                  No: 'No'
                },
                geometriaCondicionesGeometrica: {
                  bueno: 'Bueno',
                  regular: 'Regular',
                  deficiente: 'Deficiente'
                },
                geometriaAlineamiento: {
                  curvo: 'Curvo',
                  predominantementeCurvo: 'Predominantemente Curvo',
                  predominantementeRecto: 'Predominantemente Recto',
                  recto: 'Recto'
                },
                geometriaTipoTerreno: {
                  plano: 'Plano',
                  ondulado: 'Ondulado',
                  montañoso: 'Montañoso',
                  escarpado: 'Escarpado'
                }
              },
              tabs:{
                detalles: 'Detalles',
                geometria: 'Geometría',
                estructura: 'Estructura',
                fotografias : 'FOTOGRAFÍAS',
                informacionBasica: 'Información Básica',
                auditoria: 'AuditorÍa'
              },
              messagesVal: {
                value20: "Debe ser mayor a 'ESAL (Proyección 10 años)'",
                valNumDecimals: 'El valor ingresado solo puede tener 2 decimales',
                valNumDecimal: 'El valor ingresado solo puede tener 1 decimal',
                valNumEntero: 'El valor ingresado debe ser entero',
                valLongitudMin0: 'Debe ser al menos 0',
                valLongitudMax5: 'Debe ser 5 o menos',
                valLongitudMax20: 'Debe ser 20 o menos',
                valMinCapa: 'Mínimo 1 capa',
                messageIniCapa: 'El Espesor en la capa ',
                messageFinCapa: ' (la última), debe ser 0',
              }
            },
            rigido_detalles:{
              title: {
                editTitle: 'Editar Segmento Rígido',
                showTitle: 'Información Segmento Rígido'
              },
              messagesVal: {
                value20: "Debe ser mayor a 'ESAL (Proyección 10 años)'",
                valNumDecimals: 'El valor ingresado solo puede tener 2 decimales',
                valNumDecimal: 'El valor ingresado solo puede tener 1 decimal',
                valNumEntero: 'El valor ingresado debe ser entero',
                valLongitudMin0: 'Debe ser al menos 0',
                valLongitudMax5: 'Debe ser 5 o menos',
                valLongitudMax20: 'Debe ser 20 o menos',
                valMinCapa: 'Mínimo 1 capa',
                messageIniCapa: 'El Espesor en la capa ',
                messageFinCapa: ' (la última), debe ser 0',
              },
              select: {
                estadoDetalles: {
                  Activo: 'Activo',
                  Inactivo: 'Inactivo'
                },
                nivelTrafico: {
                  NT1: 'NT1',
                  NT2: 'NT2',
                  NT3: 'NT3',
                },
                geometriaSeparador: {
                  Si: 'Si',
                  No: 'No',
                  SiId: 'Si',
                  NoId: 'No'
                },
                sentido: {
                  'NORTEName': 'Norte',
                  NORTEId: 'NORTE',
                  'SURName': 'Sur',
                  SURId: 'SUR',
                  'ESTEName': 'Este',
                  ESTEId: 'ESTE',
                  'OESTEName': 'Oeste',
                  OESTEId: 'OESTE'
                },
                geometriaTipoBerma: {
                  bermaCuneta: 'Berma Cuneta',
                  flexible: 'Flexible',
                  inexistente: 'Inexistente',
                  rigida: 'Rígida' 
                },
                geometriaCondicionBerma: {
                  bueno: 'Bueno',
                  regular: 'Regular',
                  deficiente: 'Deficiente'
                },
                geometriaCondicionesGeometrica: {
                  bueno: 'Bueno',
                  regular: 'Regular',
                  deficiente: 'Deficiente'
                },
                geometriaAlineamiento: {
                  curvo: 'Curvo',
                  predominantementeCurvo: 'Predominantemente Curvo',
                  predominantementeRecto: 'Predominantemente Recto',
                  recto: 'Recto'
                },
                geometriaTipoTerreno: {
                  plano: 'Plano',
                  ondulado: 'Ondulado',
                  montañoso: 'Montañoso',
                  escarpado: 'Escarpado'
                }
              },
              tabs:{
                detalles: 'Detalles',
                geometria: 'Geometría',
                estructura: 'Estructura',
                fotografias : 'FOTOGRAFÍAS',
                informacionBasica: 'Información Básica',
                auditoria: 'AuditorÍa'
              },
            },
            adoquin_detalles: {
              title: {
                editTitle: 'Editar Segmento Adoquín',
                showTitle: 'Información Segmento Adoquín'
              },
              messagesVal: {
                value20: "Debe ser mayor a 'ESAL (Proyección 10 años)'",
                valNumDecimals: 'El valor ingresado solo puede tener 2 decimales',
                valNumDecimal: 'El valor ingresado solo puede tener 1 decimal',
                valNumEntero: 'El valor ingresado debe ser entero',
                valLongitudMin0: 'Debe ser al menos 0',
                valLongitudMax5: 'Debe ser 5 o menos',
                valLongitudMax20: 'Debe ser 20 o menos',
                valMinCapa: 'Mínimo 1 capa',
                messageIniCapa: 'El Espesor en la capa ',
                messageFinCapa: ' (la última), debe ser 0',
                maxDosDecimales: 'Máximo 2 decimales',
              },
              select: {
                estadoDetalles: {
                  Activo: 'Activo',
                  Inactivo: 'Inactivo'
                },
                nivelTrafico: {
                  NT1: 'NT1',
                  NT2: 'NT2',
                  NT3: 'NT3',
                },
                geometriaSeparador: {
                  Si: 'Si',
                  No: 'No'
                },
                sentido: {
                  'NORTEName': 'Norte',
                  NORTEId: 'NORTE',
                  'SURName': 'Sur',
                  SURId: 'SUR',
                  'ESTEName': 'Este',
                  ESTEId: 'ESTE',
                  'OESTEName': 'Oeste',
                  OESTEId: 'OESTE'
                },
                geometriaTipoBerma: {
                  bermaCuneta: 'Berma Cuneta',
                  flexible: 'Flexible',
                  inexistente: 'Inexistente',
                  rigida: 'Rígida' 
                },
                geometriaCondicionBerma: {
                  bueno: 'Bueno',
                  regular: 'Regular',
                  deficiente: 'Deficiente'
                },
                geometriaCondicionesGeometrica: {
                  bueno: 'Bueno',
                  regular: 'Regular',
                  deficiente: 'Deficiente'
                },
                geometriaAlineamiento: {
                  curvo: 'Curvo',
                  predominantementeCurvo: 'Predominantemente Curvo',
                  predominantementeRecto: 'Predominantemente Recto',
                  recto: 'Recto'
                },
                geometriaTipoTerreno: {
                  plano: 'Plano',
                  ondulado: 'Ondulado',
                  montañoso: 'Montañoso',
                  escarpado: 'Escarpado'
                }
              },
              tabs:{
                detalles: 'Detalles',
                geometria: 'Geometría',
                estructura: 'Estructura',
                fotografias : 'FOTOGRAFÍAS',
                informacionBasica: 'Información Básica',
                auditoria: 'AuditorÍa'
              },
              boolean: {
                elementoMantenimiento: '¿Incluir este elemento a un plan de mantenimiento?'
              },
            },
            otro_detalles: {
              title: {
                editTitle: 'Editar Segmento Otro',
                showTitle: 'Información Segmento Otro'
              },
              select: {
                estadoDetalles: {
                  Activo: 'Activo',
                  Inactivo: 'Inactivo'
                },
                nivelTrafico: {
                  NT1: 'NT1',
                  NT2: 'NT2',
                  NT3: 'NT3',
                },
                geometriaSeparador: {
                  Si: 'Si',
                  No: 'No'
                },
                sentido: {
                  NORTEName: 'Norte',
                  NORTEId: 'NORTE',
                  SURName: 'Sur',
                  SURId: 'SUR',
                  ESTEName: 'Este',
                  ESTEId: 'ESTE',
                  OESTEName: 'Oeste',
                  OESTEId: 'OESTE'
                },
                geometriaTipoBerma: {
                  bermaCuneta: 'Berma Cuneta',
                  flexible: 'Flexible',
                  inexistente: 'Inexistente',
                  rigida: 'Rígida' 
                },
                geometriaCondicionBerma: {
                  bueno: 'Bueno',
                  regular: 'Regular',
                  deficiente: 'Deficiente'
                },
                geometriaCondicionesGeometrica: {
                  bueno: 'Bueno',
                  regular: 'Regular',
                  deficiente: 'Deficiente'
                },
                geometriaAlineamiento: {
                  curvo: 'Curvo',
                  predominantementeCurvo: 'Predominantemente Curvo',
                  predominantementeRecto: 'Predominantemente Recto',
                  recto: 'Recto'
                },
                geometriaTipoTerreno: {
                  plano: 'Plano',
                  ondulado: 'Ondulado',
                  montañoso: 'Montañoso',
                  escarpado: 'Escarpado'
                }
              },
              messagesVal: {
                value20: "Debe ser mayor a 'ESAL (Proyección 10 años)'",
                valNumDecimals: 'El valor ingresado solo puede tener 2 decimales',
                valNumDecimal: 'El valor ingresado solo puede tener 1 decimal',
                valNumEntero: 'El valor ingresado debe ser entero',
                valLongitudMin0: 'Debe ser al menos 0',
                valLongitudMax5: 'Debe ser 5 o menos',
                valLongitudMax20: 'Debe ser 20 o menos',
                valMinCapa: 'Mínimo 1 capa',
                messageIniCapa: 'El Espesor en la capa ',
                messageFinCapa: ' (la última), debe ser 0',
                maxDosDecimales: 'Máximo 2 decimales.',
                sinDecimales: 'Sin Decimales.'
              },
              boolean: {
                elementoMantenimiento: '¿Incluir este elemento a un plan de mantenimiento?'
              },
              tabs:{
                detalles: 'Detalles',
                geometria: 'Geometría',
                estructura: 'Estructura',
                fotografias : 'FOTOGRAFÍAS',
                informacionBasica: 'Información Básica',
                auditoria: 'AuditorÍa'
              },
            },
            seguridads: {
              title: {
                showTitle: "Lista Sistemas de Contención Vehicular",
                editTitle: 'Editar Sistemas de Contención Vehicular',
                showTitle: 'Información Sistemas de Contención Vehicular'
              },
              tabs: {
                tabsTitle: {
                  identificacion: 'Identificación',
                  caracteristicas: 'Características',
                  detalles: 'Detalles',
                  fotografias: 'FOTOGRAFÍAS',
                  auditoria: 'AuditorÍa'
                },
              },
              select: {
                tipodeDefensa: {
                  termIniAbatidaEsviadaTerFinAbatida: 'Terminal de inicio abatida y esviada y Terminal final abatida',
                  termIniAbatidaEsviadaTerFinAbatidaEsviada: 'Terminal de inicio abatida y esviada y Terminal final abatida y esviada',
                  termIniAbatEsviadaTermFinEnterradaTalud: 'Terminal de inicio abatida y esviada y Terminal final enterrada en talud',
                  termIniAbatEsviadaTermFinColaPez: 'Terminal de inicio abatida y esviada y Terminal final cola de pez',
                  termIniAbatTerminalFinAbatida: 'Terminal de inicio abatida y Terminal final abatida',
                  termIniAbatTerminalFinAbatidaEsviada: 'Terminal de inicio abatida y Terminal final abatida y esviada',
                  termIniAbatTerminalFinColaPez: 'Terminal de inicio abatida y Terminal final cola de pez',
                  termIniAbatTerminalFinSalidaAerea: 'Terminal de inicio abatida y Terminal final con salida aérea',
                  termIniAbatTerminalFinEnterradaTalud: 'Terminal de inicio abatida y Terminal final enterrada en talud',
                  termIniAbatTerminalFinIncrustadaObraArte: 'Terminal de inicio abatida y Terminal final incrustada en obra de arte',
                  termIniColaPezTermiFinAbatidaEsviada: 'Terminal de inicio cola de pez y Terminal final abatida y esviada',
                  termIniColaPezTermiFinAbatida: 'Terminal de inicio cola de pez y Terminal final abatida',
                  termIniColaPezTermiFinColaPez: 'Terminal de inicio cola de pez y Terminal final cola de pez',
                  termIniColaPezTermiFinEnterradaTalud: 'Terminal de inicio cola de pez y Terminal final enterrada en talud',
                  termIniAmortiguadorImpacTermFinAbatida: 'Terminal de inicio con amortiguador de impacto y Terminal final abatida',
                  termIniAmortiguadorImpacTermFinColaPez: 'Terminal de inicio con amortiguador de impacto y Terminal final cola de pez',
                  termIniAmortiguadorImpacTermFinSalAerea: 'Terminal de inicio con amortiguador de impacto y Terminal final con salida aérea',
                  termIniAmortiguadorImpacTermFinEnterradaTalud: 'Terminal de inicio con amortiguador de impacto y Terminal final enterrada en talud',
                  termIniAmortiguadorImpacTermFinIncrusObraArte: 'Terminal de inicio con amortiguador de impacto y Terminal final incrustada en obra de arte',
                  termIniEnterrTaludTermFinAbatEsviada: 'Terminal de inicio enterrada en talud y Terminal final abatida y esviada',
                  termIniEnterrTaludTermFinAbatida: 'Terminal de inicio enterrada en talud y Terminal final abatida',
                  termIniEnterrTaludTermFinColaPez: 'Terminal de inicio enterrada en talud y Terminal final cola de pez',
                  termIniEnterrTaludTermFinEnterrTalud: 'Terminal de inicio enterrada en talud y Terminal final enterrada en talud',
                  termIniEsviadaTermFinAbatida: 'Terminal de inicio esviada y Terminal final abatida',
                  termIniEsviadaTermFinSalidaAerea: 'Terminal de inicio esviada y Terminal final con salida aérea',
                  termIniEsviadaTermFinEnterradaTalud: 'Terminal de inicio esviada y Terminal final enterrada en talud',
                  termIniEsviadaTermFinIncrustadaObraArte: 'Terminal de inicio esviada y Terminal final incrustada en obra de arte',
                  termIniIncrustObraArteTermFinalAbatida: 'Terminal de inicio incrustada en obra de arte y Terminal final abatida',
                  termIniIncrustObraArteTermFinalSalidaAerea: 'Terminal de inicio incrustada en obra de arte y Terminal final con salida aérea',
                  termIniIncrustObraArteTermFinalEnterradaTalud: 'Terminal de inicio incrustada en obra de arte y Terminal final enterrada en talud',
                  termIniIncrustObraArteTermFinalEsviada: 'Terminal de inicio incrustada en obra de arte y Terminal final esviada',
                },
                margen: {
                  derechaName: 'Derecha',
                  derechaId: 'Derecha',
                  izquierdaName: 'Izquierda',
                  izquierdaId: 'Izquierda',
                  centroName: 'Centro',
                  centroId: 'Centro',
                },
                sentido: {
                  norteName: 'Norte',
                  norteId: 'NORTE',
                  surName: 'Sur',
                  surId: 'SUR',
                  esteName: 'Este',
                  esteId: 'ESTE',
                  oesteName: 'Oeste',
                  oesteId: 'OESTE',
                },
                material: {
                  barreraJerseyPlastica: 'Barrera jersey plástica',
                  barreraJerseyPlasticaId: 'Barrera jersey plastica',
                  concreto: 'Concreto',
                  malla: 'Malla',
                  metalica: 'Metálica',
                  metalicaId: 'Metalica',
                  plastica: 'Plástica',
                  plasticaId: 'Plastica',
                  polisombra: 'Polisombra',
                  prefabricado: 'Prefabricado',
                },
                perfil: {
                  perfilC: 'Perfil en C',
                  perfilU: 'Perfil en U',
                  perfilW_DobleOnda: 'Perfil W - doble onda',
                  simple: 'Simple'
                },
                posteMetalico: {
                  siName: 'Si',
                  siId: 'SI',
                  noName: 'No',
                  noId: 'NO'
                },
                tope: {
                  horizontal: 'Horizontal',
                  vertical: 'Vertical'
                },
                seccion: {
                  circular: 'Circular',
                  rectangular: 'Rectangular',
                },
                condicionElemento: {
                  existente: 'Existente',
                  reemplazo: 'Reemplazo',
                  nuevo: 'Nuevo',
                },
                motivoReemplazo:{
                  accidente: 'Accidente',
                  instalacion: 'Instalación',
                  instalacionId: 'Instalacion',
                  reemplazoProgramado: 'Reemplazo programado',
                  vandalismo: 'Vandalismo',
                  otro: 'Otro',
                },
                estado: {
                  activo: 'Activo',
                  inactivo: 'Inactivo'
                },
                tipoElementoDesc:{
                  barreraConcretoPendSimple: 'Barrera de concreto de pendiente simple',
                  barreraPerfilF: 'Barrera de perfil F',
                  barreraJersey: 'Barrera Jersey',
                  bordillo: 'Bordillo',
                  captafaros: 'Captafaros',
                  cerramientoProvisional: 'Cerramiento provisional',
                  defensa: 'Defensa',
                  pretilesPuentes: 'Pretiles de Puentes',
                  topeVehicular: 'Tope Vehicular',
                }
              },
              subTitle: {
                localizacion: 'Localización',
                coordenadasIniciales: 'Coordenadas Iniciales',
                coordenadasFinales: 'Coordenadas Finales',
              },
              boolean: {
                mantenimiento: '¿Incluir este elemento a un plan de mantenimiento?'
              },
              messageWarning: {
                observaciones: 'Máximo 200 caracteres.'
              }
            },
            elemento_drenajes: {
              title: {
                editTitle: 'Editar Elemento Drenaje',
                showTitle: 'Información Elemento Drenaje',
                listTitle: 'Lista Elementos Drenajes'
              },
              tabs: {
                tabsTitle: {
                  identificacion: 'Identificación',
                  caracteristicas: 'Características',
                  detalles: 'Detalles',
                  fotografias: 'FOTOGRAFÍAS',
                  auditoria: 'AuditorÍa'
                },
              },
              select: {
                sentido: {
                  NORTEName: 'Norte',
                  NORTEId: 'NORTE',
                  SURName: 'Sur',
                  SURId: 'SUR',
                  ESTEName: 'Este',
                  ESTEId: 'ESTE',
                  OESTEName: 'Oeste',
                  OESTEId: 'OESTE'
                },
                margen: {
                  derecha: 'Derecha',
                  izquierda: 'Izquierda',
                  centro:'Centro',
                },
                tipoCuneta: {
                  bajoAccesosPrediosInterseccionVias: 'Bajo Accesos a Predios o Intersección de Vías',
                  enZonasUrbanasSemiurbanas: 'En Zonas Urbanas y Semiurbanas',
                  tipoCarretera: 'Tipo Carretera',
                },
                material: {
                  acero: 'Acero',
                  concreto: 'Concreto',
                  concretoAcero: 'Concreto y acero',
                  cobre:'Cobre',
                  mamposteria: 'Mampostería',
                  polipropileno:'Polipropileno',
                  otro: 'Otro',
                  PEAD: 'PEAD',
                  PVC: 'PVC',
                  HDEP:'HDPE',
                },
                seccion: {
                  circular: 'Circular',
                  cuadrada: 'Cuadrada',
                  rectangular: 'Rectangular',
                  trapezoidal: 'Trapezoidal',
                  triangular: 'Triangular',
                  hexagonal:'Hexagonal',
                  perfilH:'Perfil H',
                  otro:'Otro',
                },
                tipoDelElemento: {
                  AlcantarDobleconcreto: 'Alcantarilla doble de concreto',
                  AlcantarDoblePVC: 'Alcantarilla doble de PVC',
                  AlcantarDobleMetalica: 'Alcantarilla doble metálica',
                  AlcantarSimpleConcreto: 'Alcantarilla simple de concreto',
                  AlcantarSimplePVC: 'Alcantarilla simple de PVC',
                  AlcantarSimpleMetalica: 'Alcantarilla simple metálica',
                  AlcantarTripleConcreto: 'Alcantarilla triple de concreto',
                  AlcantarTriplePVC: 'Alcantarilla triple de PVC',
                  AlcantarTripleMetalica: 'Alcantarilla triple metálica',
                  boxCulvertDobleConcreto: 'Box culvert doble de concreto',
                  boxCulvertEspecial: 'Box culvert especial',
                  boxCulvertSimpleConcreto: 'Box culvert simple de concreto',
                  boxCulvertTripleConcreto: 'Box culvert triple de concreto',
                },
                tipoPlanta: {
                  Normal: 'Normal',
                  Esconso: 'Esconso',
                },
                direccion: {
                  Jusante: 'Jusante',
                  Montante: 'Montante',
                },
                estado: {
                  existente: 'Existente',
                  reemplazo: 'Reemplazo',
                  nuevo: 'Nuevo',
                },
                motivoReemplazo: {
                  accidente: 'Accidente',
                  otro: 'Otro',
                  reemplazoProgramado: 'Reemplazo programado',
                  vandalismo: 'Vandalismo',
                },
                estados: {
                  Activo: 'Activo',
                  Inactivo: 'Inactivo',
                }
              },
              subTitle: {
                localizacion: 'Localización',
                coordenadasIniciales: 'Coordenadas Iniciales',
                coordenadasFinales: 'Coordenadas Finales',
              },
              boolean: {
                mantenimiento: '¿Incluir este elemento a un plan de mantenimiento?'
              },
            },
            elemento_senalizacions:{
              title: {
                editTitle: 'Editar Elemento Señal',
                showTitle: 'Información Elemento Señal',
                listTitle: ''
              },
              tabs: {
                tabsTitle: {
                  identificacion: 'Identificación',
                  caracteristicas: 'Características',
                  detalles: 'Detalles',
                  fotografias: 'FOTOGRAFÍAS',
                  auditoria: 'AuditorÍa'
                },
              },
              messagesVal: {
                valNumEntero: 'Debe ser un número entero',
                rangoLongitud: 'El rango debe estar entre '
              },
              select: {
                margen: {
                  derecha: 'Derecha',
                  izquierda: 'Izquierda',
                  centro: 'Centro'
                },
                sentido: {
                  NORTEName: 'Norte',
                  NORTEId: 'NORTE',
                  SURName: 'Sur',
                  SURId: 'SUR',
                  ESTEName: 'Este',
                  ESTEId: 'ESTE',
                  OESTEName: 'Oeste',
                  OESTEId: 'OESTE'
                },
                choice: {
                  amarillo: 'Amarillo',
                  amariFluorescente: 'Amarillo fluorescente',
                  amariVerdeFluores: 'Amarillo-verde fluorescente',
                  azul: 'Azul',
                  blanco: 'Blanco',
                  marrón: 'Marrón',
                  naranja: 'Naranja',
                  naranjaFluorescente: 'Naranja fluorescente',
                  rojo: 'Rojo',
                  verde: 'Verde',
                  noAplica: 'No aplica',
                },
                choicess: {
                  amarillo: 'Amarillo',
                  amariFluorescente: 'Amarillo fluorescente',
                  amariVerdeFluores: 'Amarillo-verde fluorescente',
                  azul: 'Azul',
                  blanco: 'Blanco',
                  marrón: 'Marrón',
                  naranja: 'Naranja',
                  naranjaFluorescente: 'Naranja fluorescente',
                  negro: 'Negro',
                  rojo: 'Rojo',
                  verde: 'Verde',
                  noAplica: 'No aplica',
                },
                material: {
                  aceroGalvanizado: 'Acero galvanizado',
                  aluminio: 'Aluminio',
                  fibra: 'Fibra',
                  cintasPreformadas: 'Cintas preformadas',
                  pinturas: 'Pinturas',
                  plasticosFrio: 'Plásticos en frio',
                  termoplasticas: 'Termoplásticas',
                },
                tipoPapel: {
                  altaAmarillo: 'Alta amarillo',
                  altaBlanco: 'Alta blanco',
                  diamanteAmariVerdeFluores: 'Diamante amarillo verde fluorescente',
                  diamanteAzul: 'Diamante azul',
                  diamanteBlanco: 'Diamante blanco',
                  diamanteMarron: 'Diamante marrón',
                  diamanteRojo: 'Diamante rojo',
                  diamanteVerde: 'Diamante verde',
                  ingenieriaAmarillo: 'Ingeniería amarillo',
                  ingenieriaBlanco: 'Ingeniería blanco',
                  t11Amarillo700: 'T11 amarillo 700',
                },
                tipoTablero: {
                  circular: 'Circular',
                  cuadrada: 'Cuadrada',
                  rectangular: 'Rectangular',
                  otro: 'Otro',
                },
                dimensionTablero:{
                  x80: '80 x 80 cm',
                  x100: '100 x 100 cm',
                  x120: '120 x 120 cm',
                  x140: '140 x 140 cm',
                  Otra: 'Otra'
                },
                estadoTablero: {
                  Afectada: 'Afectada',
                  Buena: 'Buena',
                },
                tipoSoporte: {
                  Bandera: 'Bandera',
                  Pasacalle: 'Pasacalle',
                  Vastago: 'Vástago',
                },
                estadoSoporte: {
                  Afectada: 'Afectada',
                  Buena: 'Buena',
                },
                materialSoporte: {
                  Acero: 'Acero',
                  Madera: 'Madera'
                },
                colorOtrosElem: {
                  Amarilla: 'Amarilla',
                  Blanca: 'Blanca',
                },
                estado: {
                  existente: 'Existente',
                  reemplazo: 'Reemplazo',
                  nuevo: 'Nuevo',
                  bueno: 'Bueno',
                  malo: 'Malo',
                  regular: 'Regular',
                },
                estados: {
                  Activo: 'Activo',
                  Inactivo: 'Inactivo',
                },
                motivoReemplazo: {
                  accidente: 'Accidente',
                  instalacion: 'Instalación',
                  otro: 'Otro',
                  reemplazoProgramado: 'Reemplazo programado',
                  vandalismo: 'Vandalismo',
                },
                condicion: {
                  instalacion: 'Instalación',
                  mantenimiento: 'Mantenimiento',
                }
              },
              subTitle: {
                localizacion: 'Localización',
                coordenadas: 'Coordenadas',
                coordenadasIniciales: 'Coordenadas Iniciales',
                coordenadasFinales: 'Coordenadas Finales',
              },
              messageWarning: {
                warningMensajeVariable: 'Módulo no diponible para tipo de señal "Mensaje variable"',
                altura: 'Debe ser al menos 0',
                numEntero: 'Debe ser un número entero',
                rangolng: 'El rango debe estar entre '
              },
              boolean: {
                mantenimiento: '¿Incluir este elemento a un plan de mantenimiento?'
              },
            },
            elemento_alrededors: {
              title: {
                editTitle: 'Editar Elemento Alrededor',
                showTitle: 'Información Elemento Alrededor',
                listTitle: ''
              },
              tabs: {
                tabsTitle: {
                  identificacion: 'Identificación',
                  caracteristicas: 'Características',
                  detalles: 'Detalles',
                  fotografias: 'FOTOGRAFÍAS',
                  auditoria: 'AuditorÍa'
                },
              },
              select: {
                margen: {
                  derecha: 'Derecha',
                  izquierda: 'Izquierda',
                  centro: 'Centro'
                },
                sentido: {
                  NORTEName: 'Norte',
                  NORTEId: 'NORTE',
                  SURName: 'Sur',
                  SURId: 'SUR',
                  ESTEName: 'Este',
                  ESTEId: 'ESTE',
                  OESTEName: 'Oeste',
                  OESTEId: 'OESTE'
                },
                tipoServicio: {
                  barraca: 'Barraca',
                  centroEducativo: 'Centro educativo',
                  estaciónCombustible: 'Estación de combustible',
                  hospitalCentroSalud: 'Hospital/Centro de salud',
                  hotel: 'Hotel',
                  montallantas: 'Montallantas',
                  restaurante: 'Restaurante',
                  tallerAutomotriz: 'Taller automotriz',
                  otros: 'Otros',
                  /* Margen */
                  concretoAdoquin: 'Concreto/Adoquín',
                  noAplica: 'No aplica',
                  talud: 'Talud',
                  vegetación: 'Vegetación',
                  otro: 'Otro',
                  /* Obstaculo */
                  cercado: 'Cercado',
                  construccion: 'Construcción',
                  otros: 'Otros',
                  rio: 'Río',
                  zonaVerde: 'Zona verde',
                  /* Zona Inestable */
                  caida: 'Caída',
                  deslizamiento: 'Deslizamiento',
                  flujos: 'Flujos',
                  movimientoComplejo: 'Movimiento complejo',
                  volcamiento: 'Volcamiento',
                  /* Ocupación */
                  Cerca: 'Cerca',
                  Edificacion: 'Edificación',
                  Outdoor: 'Outdoor',
                  Placa: 'Placa',
                  /* Accesos */
                  Municipal: 'Municipal',
                  Particular: 'Particular',
                },
                tipoAcceso: {
                  conCarrilAcelDesa: 'Con carril de aceleración/desaceleración',
                  sinCarril: 'Sin carril'
                },
                estacionamiento: {
                  enFachada: 'En fachada',
                  enVia: 'En vía',
                  interno: 'Interno',
                },
                identificacion: {
                  Regular: 'Regular',
                  Irregular: 'Irregular',
                },
                riesgo: {
                  baja: 'Baja',
                  media: 'Media',
                  alta: 'Alta',
                  muyAlta: 'Muy Alta',
                },
                seccion: {
                  taludSuperior: 'Talud superior',
                  taludInferior: 'Talud inferior',
                },
                tipoTerraplen: {
                  Corte: 'Corte',
                  Relleno: 'Relleno'
                },
                tipoObra: {
                  anclajesRoca: 'Anclajes en roca',
                  cercadosRedesContencion: 'Cercados y redes de contención',
                  EscudoContraCaidaRocas: 'Escudo contra caída de rocas',
                  muroAnclado: 'Muro anclado',
                  muroConcretoReforzado: 'Muro de concreto reforzado',
                  muroEncofrado: 'Muro de encofrado',
                  muroGaviones: 'Muro de gaviones',
                  muroGravedadConcreto: 'Muro de gravedad en concreto',
                  muroTierraReforzada: 'Muro de tierra reforzada',
                  murosContencion: 'Muros de contención',
                  pantallasAncladas: 'Pantallas ancladas',
                  pilotesCaissons: 'Pilotes y caissons',
                  reconformacion: 'Reconformación',
                  revestimientoFlexible: 'Revestimiento flexible',
                  revestimientoVegetal: 'Revestimiento vegetal',
                  sueloEmpernado: 'Suelo empernado',
                  otros: 'Otros',
                  
                },
                estado: {
                  Activo: 'Activo',
                  Inactivo: 'Inactivo',
                  Existente: 'Existente',
                  Reemplazo: 'Reemplazo',
                  Nuevo: 'Nuevo',
                },
                condicion: {
                  accidente: 'Accidente',
                  instalacion: 'Instalación',
                  otro: 'Otro',
                  reemplazoProgramado: 'Reemplazo programado',
                  vandalismo: 'Vandalismo',
                },
                estados: {
                  Activo: 'Activo',
                  Inactivo: 'Inactivo',
                }
              },
              subTitle: {
                localizacion: 'Localización',
                coordenadas: 'Coordenadas',
                coordenadasIniciales: 'Coordenadas Iniciales',
                coordenadasFinales: 'Coordenadas Finales',
                TramosPendVariable: 'Tramos de Pendiente Variable',
              },
              messagesVal: {
                valNumNegativos: 'El valor ingresado no puede ser negativo',
                valNumEntero: 'Debe ser un número entero',
                rangoLongitud: 'El rango debe estar entre '
              },
            },
            tuneles: {
              title: {
                editTitle: 'Editar Elemento Túneles',
                showTitle: 'Información Elemento Túneles',
                listTitle: ''
              },
              tabs: {
                tabsTitle: {
                  identificacion: 'Identificación',
                  caracteristicas: 'Características',
                  datosAdministrativos: 'Datos Administrativos',
                  geometria: 'Geometría',
                  detalles: 'Detalles',
                  fotografias: 'FOTOGRAFÍAS',
                  auditoria: 'AuditorÍa'
                },
              },
              select: {
                sentido: {
                  Ascendente: 'Ascendente',
                  Descendente: 'Descendente',
                },
                sentidoB: {
                  NORTEName: 'Norte',
                  NORTEId: 'NORTE',
                  SURName: 'Sur',
                  SURId: 'SUR',
                  ESTEName: 'Este',
                  ESTEId: 'ESTE',
                  OESTEName: 'Oeste',
                  OESTEId: 'OESTE'
                },
                clasificacion: {
                  ClaseA: 'Clase A',
                  ClaseB: 'Clase B',
                  ClaseC: 'Clase C',
                  ClaseD: 'Clase D',
                  ClaseE: 'Clase E',
                },
                cobertura: {
                  Somero: 'Somero',
                  Semiprofundo: 'Semiprofundo',
                  Profundo: 'Profundo',
                },
                existe: {
                  Si: 'Si',
                  No: 'No',
                },
                estadoa: {
                  Bueno: 'Bueno',
                  Regular: 'Regular',
                  Malo: 'Malo',
                },
                soporte: {
                  ArcMetalicReticulado: 'Arcos metálicos o reticulados',
                  ConcretoLanzado: 'Concreto lanzado',
                  ElementosDuctiles: 'Elementos dúctiles',
                  MejoramientoTerreno: 'Mejoramiento del terreno',
                  PernosAnclaje: 'Pernos de Anclaje',
                  RefuerzoTerreno: 'Refuerzo del terreno',
                },
                codigo_soporte: {
                  RCSname: 'Revestimiento de capa sencilla',
                  RCCname: 'Revestimiento de capa compuesta',
                  RCDname: 'Revestimiento de capa doble',
                },
                tipologia: {
                  EnVoEncajonado: 'En "V" o encajonado',
                  EnLoMediaLadera: 'En "L" o a media ladera',
                  EnCoSesgados: 'En "C" o sesgados',
                  EnToPerpendiculares: 'En "T" o perpendiculares',
                  EnUoHerradura: 'En "U" o herradura',
                  EnEoEstructurales: 'En "E" o estructurales',
                },
                estado: {
                  Existente: 'Existente',
                  Reemplazo: 'Reemplazo',
                  Nuevo: 'Nuevo',
                },
                mot_remplazo: {
                  ReemplazoProgramado: 'Reemplazo programado',
                  Accidente: 'Accidente',
                  Vandalismo: 'Vandalismo',
                  Otro: 'Otro',
                },
                estados: {
                  Activo: 'Activo',
                  Inactivo: 'Inactivo',
                }
              },
              subTitle: {
                localizacion: 'Localización',
                coordenadas: 'Coordenadas',
                coordenadasIniciales: 'Coordenadas Iniciales',
                coordenadasFinales: 'Coordenadas Finales',
                detallesConstructivos: 'Detalles constructivos',
                detRefuerzoRehabilit: 'Detalles de refuerzo o rehabilitación',
                estudioHidrogeologico: 'Estudio Hidrogeológico',
                estProyeccionTrafico: 'Estudio de proyección del tráfico',
                miembrosInteresados: 'Miembros Interesados',
                caractOperacionales: 'Características operacionales',
                disenoPlanta: 'Diseño en planta',
                disenoPerfil: 'Diseño en perfil',
                SeccTransverServicio: 'Secciones transversales de servicio',
                InfoComplementaria: 'Información complementaria',
              },
              table: {
                titles: {
                  Costado: 'Costado',
                  Cantidad: 'Cantidad',
                  Separacion: 'Separación (m)',
                  Longitud: 'Longitud (m)',
                  Ancho: 'Ancho (m)',
                  Altura: 'Altura (m)',
                  Pendiente: 'Pendiente (%)',
                },
                nameRows: {
                  BahiasParqueo: 'Bahías de parqueo',
                  CarrilEmergencia: 'Carril de emergencia',
                  GaleriasEvacuacion: 'Galerías de evacuación',
                  NichoEmergenciaSOS: 'Nicho de Emergencia S.O.S',
                  NichoControlIncendios: 'Nicho de Control de incendios',
                  NichoEquiposElectricos: 'Nicho de Equipos eléctricos'
                },
                choicesCostado:{
                  Izquierdo: 'Izquierdo',
                  Derecho: 'Derecho',
                  Ambos: 'Ambos',
                },
                message: 'Cargando Tabla...',
              },
              boolean: {
                incluir: '¿Incluir este elemento a un plan de mantenimiento?'
              },
              messagesVal: {
                valNumEntero: 'Debe ser un número entero',
                valNumMayorA1: 'El numero ingresado debe ser mayor a 1',
                valNumMayorA0: 'El numero ingresado debe ser mayor a 0',
                valNumMenorA90: 'El numero ingresado debe ser menor a 90',
                valNumMenorA100: 'El numero ingresado debe ser menor a 100',
              },
            },
            elemento_contencions: {
              title: {
                editTitle: 'Editar Elemento Contención',
                showTitle: 'Información Elemento Contención',
                listTitle: 'Lista Elementos Contención'
              },
              tabs: {
                tabsTitle: {
                  identificacion: 'Identificación',
                  caracteristicas: 'Características',
                  detalles: 'Detalles',
                  fotografias: 'FOTOGRAFÍAS',
                  auditoria: 'AuditorÍa'
                },
              },
              select: {
                margen:{
                  Derecha: 'Derecha',
                  Izquierda: 'Izquierda',
                  centro:'Centro',
                },
                sentido: {
                  NORTEName: 'Norte',
                  NORTEId: 'NORTE',
                  SURName: 'Sur',
                  SURId: 'SUR',
                  ESTEName: 'Este',
                  ESTEId: 'ESTE',
                  OESTEName: 'Oeste',
                  OESTEId: 'OESTE'
                },
                tipo: {
                  anclajesRoca: 'Anclajes en roca',
                  cercadosRedesContencion: 'Cercados y redes de contención',
                  escudoContraCaidaRocas: 'Escudo contra caída de rocas',
                  muroAnclado: 'Muro anclado',
                  muroConcretoReforzado: 'Muro de concreto reforzado',
                  muroEncofrado: 'Muro de encofrado',
                  muroGaviones: 'Muro de gaviones',
                  muroGravedadConcreto: 'Muro de gravedad en concreto',
                  muroTierraReforzada: 'Muro de tierra reforzada',
                  murosContencion: 'Muros de contención',
                  pantallasAncladas: 'Pantallas ancladas',
                  pilotesCaissons: 'Pilotes y caissons',
                  reconformacion: 'Reconformación',
                  revestimientoFlexible: 'Revestimiento flexible',
                  revestimientoVegetal: 'Revestimiento vegetal',
                  sueloEmpernado: 'Suelo empernado',
                  otros: 'Otros',
                },
                funcionalidad: {
                  controlCauces: 'Control de cauces',
                  estabilidadBanca: 'Estabilidad de banca',
                  estabilidadTaludes: 'Estabilidad de taludes',
                  proteccionInfraest: 'Protección de infraestructura',
                  otros: 'Otros',
                },
                estado: {
                  Existente: 'Existente',
                  Reemplazo: 'Reemplazo',
                  Nuevo: 'Nuevo',
                },
                motivoReemplazo: {
                  Instalacion: 'Instalación',
                  ReemplazoProgramado: 'Reemplazo programado',
                  Accidente: 'Accidente',
                  Vandalismo: 'Vandalismo',
                  Otro: 'Otro',
                },
                estados: {
                  Activo: 'Activo',
                  Inactivo: 'Inactivo',
                }
              },
              subTitle: {
                localizacion: 'Localización',
                coordenadas: 'Coordenadas',
                coordenadasIniciales: 'Coordenadas Iniciales',
                coordenadasFinales: 'Coordenadas Finales',
              },
              boolean: {
                estudioEstabilidad: 'Estudios de estabilidad del sitio',
                mantenimiento: '¿Incluir este elemento a un plan de mantenimiento?'
              }
            },
            elemento_pontons: {
              title: {
                createTitle: 'Crear Elemento Pontones',
                editTitle: 'Editar Elemento Pontones',
                showTitle: 'Información Elemento Pontones',
                listTitle: 'Lista Elementos Pontones'
              },
              tabs: {
                tabsTitle: {
                  identificacion: 'Identificación',
                  datosAdministrativos: 'Datos Administrativos',
                  geometria: 'Geometría',
                  reparaciones: 'Reparaciones',
                  detalles: 'Detalles',
                  fotografias: 'FOTOGRAFÍAS',
                  auditoria: 'AuditorÍa'
                },
              },
              select: {
                tipoElemento: {
                  PTON: 'Pontón'
                },
                margen: {
                  Derecha: 'Derecha',
                  Izquierda: 'Izquierda',
                  Centro: 'Centro',
                },
                sentidoB: {
                  NORTEName: 'Norte',
                  NORTEId: 'NORTE',
                  SURName: 'Sur',
                  SURId: 'SUR',
                  ESTEName: 'Este',
                  ESTEId: 'ESTE',
                  OESTEName: 'Oeste',
                  OESTEId: 'OESTE'
                },
                tipoPonton: {
                  Concreto: 'Concreto',
                  MetalicosMixtos: 'Metálicos o mixtos',
                },
                selectSorN: {
                  S: 'S',
                  N: 'N'
                },
                esta: {
                  b: 'B',
                  r: 'R',
                  m: 'M'
                },
                sentido: {
                  S: 'S',
                  N: 'N',
                  E: 'E',
                  O: 'O',
                },
                pon_c: {
                  T: 'T',
                  C: 'C'
                },
                estado: {
                  Existente: 'Existente',
                  Reemplazo: 'Reemplazo',
                  Nuevo: 'Nuevo',
                },
                motivoReemplazo: {
                  Instalacion: 'Instalación',
                  ReemplazoProgramado: 'Reemplazo programado',
                  Accidente: 'Accidente',
                  Vandalismo: 'Vandalismo',
                  Otro: 'Otro',
                },
                estados: {
                  Activo: 'Activo',
                  Inactivo: 'Inactivo',
                }
              },
              messagesVal: {
                valNumEntero: 'Debe ser un número entero',
                valRangoLatLong: 'El rango debe estar entre ',
                CargandoTabla: 'Cargando Tabla...'
              },
              subTitle: {
                localizacion: 'Localización',
                coordenadas: 'Coordenadas',
                coordenadasIniciales: 'Coordenadas Iniciales',
                coordenadasFinales: 'Coordenadas Finales',
                detallesConstructivos: 'Detalles constructivos',
                detRefuerzoRehabilit: 'Detalles de refuerzo o rehabilitación',
                detallesInspeccion: 'Detalles de Inspección',
                miembrosInteresados: 'Miembros Interesados',
                caractOperacionales: 'Características operacionales',
                capacidadCargaTransitoLegal: 'Capacidad de carga para tránsito legal',
                capacidadCargaTranspEspeciales: 'Capacidad de carga para transportes especiales',
                general: 'General',
                detallesEstructura: 'Detalles de estructura', 
              },
              table: {
                fieldsHead: {
                  Componente: 'Componente',
                  Aplica: 'Aplica',
                  FechaReparacion: 'Fecha reparación',
                  Tipo: 'Tipo',
                  Cantidad: 'Cantidad',
                  Anio: 'Año',
                  Costo: 'Costo',
                },
                titleRows: {
                  SuperficiePonton: 'Superficie del pontón',
                  AndenesBordillos: 'Andenes / Bordillos',
                  Barandas: 'Barandas',
                  ConosTaludes: 'Conos /Taludes',
                  ElementosArmadura: 'Elementos de armadura',
                  Cauce: 'Cauce',
                  LosaSuperior: 'Losa superior',
                  LosaInferior: 'Losa inferior',
                  Muros: 'Muros',
                  OtrosElementos: 'Otros elementos',
                  PontonGeneral: 'Pontón en general',

                }
              },
              boolean: {
                mantenimiento: '¿Incluir este elemento a un plan de mantenimiento?'
              }
            },
            puentes: {
              title: {
                editTitle: 'Editar Elemento Puentes',
                showTitle: 'Información Elemento Puentes',
                listTitle: 'Lista de Elemento Puentes'
              },
              tabs: {
                tabsTitle: {
                  identificacion: 'Identificación',
                  datosAdministrativos: 'Datos Administrativos',
                  geometria: 'Geometría',
                  estructura: 'Estructura',
                  subestructura: 'Subestructura',
                  reparaciones: 'Reparaciones',
                  detalles: 'Detalles',
                  fotografias: 'FOTOGRAFÍAS',
                  auditoria: 'AuditorÍa'
                },
              },
              select: {
                margen: {
                  Derecha: 'Derecha',
                  Izquierda: 'Izquierda',
                  Centro: 'Centro',
                },
                tipo: {
                  Concreto: 'Concreto',
                  MetalicosMixtos: 'Metálicos o mixtos',
                },
                esta: {
                  b: 'B',
                  r: 'R',
                  m: 'M'
                },
                 pon_c: {
                  T: 'T',
                  C: 'C'
                },
                material: {
                  Acero: 'Acero',
                  Concreto: 'Concreto',
                  Madera: 'Madera',
                  Mixto: 'Mixto',
                  Otro: 'Otro',
                },
                tipo_acceso: {
                  Escalera: 'Escalera',
                  Rampa: 'Rampa',
                },
                tipo_cimen: {
                  Profunda: 'Profunda',
                  Superficial: 'Superficial',
                },
                cimen_pila: {
                  Profunda: 'Profunda',
                  Superficial: 'Superficial',
                },
                estado: {
                  Existente: 'Existente',
                  Reemplazo: 'Reemplazo',
                  Nuevo: 'Nuevo',
                },
                mot_remplazo: {
                  Accidente: 'Accidente',
                  ReemplazoProgramado: 'Reemplazo programado',
                  Vandalismo: 'Vandalismo',
                  Otro: 'Otro',
                },
                estados: {
                  Activo: 'Activo',
                  Inactivo: 'Inactivo',
                },
                choices: {
                  Circular: 'Circular',
                  Rectangular: 'Rectangular',
                  Otra: 'Otra',
                },
                choises3: {
                  ApoyoFijo: 'Apoyo fijo',
                  Balancin: 'Balancín',
                  Basculante: 'Basculante',
                  Rodillo: 'Rodillo',
                  PlacaNeopreno: 'Placa en neopreno',
                  NoAplica: 'No aplica',
                  Otros: 'Otros',
                },
                opcion:{
                  si:'S',
                  no:'N',
                },
                sentido: {
                  S: 'S',
                  N: 'N',
                  E: 'E',
                  O: 'O',
                },
              },
              subTitle: {
                localizacion: 'Localización',
                coordenadas: 'Coordenadas',
                coordenadasIniciales: 'Coordenadas Iniciales',
                coordenadasFinales: 'Coordenadas Finales',
                detallesConstructivos: 'Detalles Constructivos',
                detRefuerzoRehabilit: 'Detalles de Refuerzo o Rehabilitación',
                detallesInspeccion: 'Detalles de Inspección',
                miembrosInteresados: 'Miembros Interesados',
                caractOperacionales: 'Características Operacionales',
                capacidadCargaTransitoLegal: 'Capacidad de carga para tránsito legal',
                capacidadCargaTranspEspeciales: 'Capacidad de Carga para Transportes especiales',
                general: 'General',
                estructura: 'Estructura',
                subestructura: 'Subestructura',
                detallesEstructura: 'Detalles de estructura', 
                Principal: 'Principal',
                Secundario: 'Secundario',
                Senales: 'Señales',
                Detalles: 'Detalles',
                Estribos: 'Estribos',
                Pilas: 'Pilas',
                Apoyos: 'Apoyos',
              },
              messagesVal: {
                ElementoNoRequiereInfo: 'El elemento seleccionado no requiere información en subestructura.',
              },
              boolean: {
                mantenimiento: '¿Incluir este elemento a un plan de mantenimiento?'
              },
              reparaciones: {
                titulos2: {
                  SuperficiePuente: 'Superficie del puente',
                  JuntasExpansion: 'Juntas de expansión',
                  AndenesBordillos: 'Andenes / Bordillos',
                  Barandas: 'Barandas',
                  ConosTaludes: 'Conos /Taludes',
                  Aletas: 'Aletas',
                  Estribos: 'Estribos',
                  Pilas: 'Pilas',
                  Apoyos: 'Apoyos',
                  Losa: 'Losa',
                  VigasLarguerosDiafragmas: 'Vigas / Largueros/ Diafragmas',
                  ElementosArco: 'Elementos de arco',
                  CablesPendolonesTorresMacizos: 'Cables /Pendolones/ Torres / Macizos',
                  ElementosArmadura: 'Elementos de armadura',
                  Cauce: 'Cauce',
                  OtrosElementos: 'Otros elementos',
                  PuenteGeneral: 'Puente en general',
                },
                titleFields: {
                  Aplica: 'Aplica',
                  FechaReparacion: 'Fecha reparación',
                  Tipo: 'Tipo',
                  Cantidad: 'Cantidad',
                  Anio: 'Año',
                  Costo: 'Costo',
                }
              }
            },
            otros_elementos: {
              title: {
                editTitle: 'Editar Otro Elemento',
                showTitle: 'Informacion Otro Elemento',
                listTitle: ''
              },
              tabs: {
                tabsTitle: {
                  identificacion: 'Identificación',
                  caracteristicas: 'Características',
                  detalles: 'Detalles',
                  fotografias: 'FOTOGRAFÍAS',
                  auditoria: 'AuditorÍa'
                },
              },
              messagesVal: {
                valNum2Decimales: 'El valor ingresado solo puede tener 2 decimales',
                decimal: 'El valor ingresado debe ser decimal',
                valEntero: 'El valor ingresado debe ser entero',
                validarEnteroNT: 'Debe ser un número entero',
                validacionmaxfechaact: 'La fecha no puede ser mayor a la actual',
                abscisaFinMayorInicio: 'Abscisa fin debe ser mayor a Abscisa inicio'
              }, 
              select: {
                margen: {
                  Derecha: 'Derecha',
                  Izquierda: 'Izquierda',
                  Centro: 'Centro',
                },
                sentido: {
                  NORTEName: 'Norte',
                  NORTEId: 'NORTE',
                  SURName: 'Sur',
                  SURId: 'SUR',
                  ESTEName: 'Este',
                  ESTEId: 'ESTE',
                  OESTEName: 'Oeste',
                  OESTEId: 'OESTE'
                },
                interseccionIDES: {
                  DeprimeIntersecta: 'Deprime intersecta',
                  DeprimeVia: 'Deprime vía',
                  ElevaIntersecta: 'Eleva intersecta',
                  ElevaVia: 'Eleva vía'
                },
                interseccionINIV: {
                  Glorieta: 'Glorieta',
                  ConPare: 'Con pare',
                  Semaforizada: 'Semaforizada',
                },
                materialINIV: {
                  Adoquin: 'Adoquín',
                  Afirmado: 'Afirmado',
                  Concreto: 'Concreto',
                  Flexible: 'Flexible',
                  Otro: 'Otro',
                },
                flujo_peatonal: {
                  Alto: 'Alto',
                  Medio: 'Medio',
                  Bajo: 'Bajo',
                },
                clase_via_intersecta: {
                  Urbana: 'Urbana',
                  Rural: 'Rural',
                },
                tipo_via_intersecta: {
                  Primaria: 'Primaria',
                  Secundaria: 'Secundaria',
                  Terciaria: 'Terciaria',
                },
                reductores_velocidad: {
                  Si: 'Si',
                  No: 'No',
                },
                sentidoCarac: {
                  Ascendente: 'Ascendente',
                  Descendente: 'Descendente',
                  Doble: 'Doble',
                },
                estado: {
                  Nuevo: 'Nuevo',
                  Existente: 'Existente',
                  Reemplazo: 'Reemplazo',
                },
                motivo: {
                  Instalacion: 'Instalación',
                  ReemplazoProgramado: 'Reemplazo programado',
                  Accidente: 'Accidente',
                  Vandalismo: 'Vandalismo',
                  Otro: 'Otro',
                },
                estados: {
                  Activo: 'Activo',
                  Inactivo: 'Inactivo',
                }
              },
              subTitle: {
                localizacion: 'Localización',
                coordenadas: 'Coordenadas',
                coordenadasIniciales: 'Coordenadas Iniciales',
                coordenadasFinales: 'Coordenadas Finales',
                caracteristicasPasoDesnivel: 'Características del paso a desnivel',
              },
              boolean: {
                mantenimiento: '¿Incluir este elemento a un plan de mantenimiento?'
              }
            },
            redes: {
              title: {
                createTitle: 'Crear Elemento Redes',
                editTitle: 'Editar Elemento Redes',
                showTitle: 'Información Elemento Redes',
                listTitle: 'Lista Elementos Redes',
                localizacion:'Localización',
                coordenadasIniciales:'Coordenadas iniciales',
                coordenadas:'Coordenadas',
                coordenadasFinales:'Coordenadas finales',
              
              },
              tabs: {
                tabsTitle: {
                  identificacion: 'Identificación',
                  datosAdministrativos: 'Datos Administrativos',
                  geometria: 'Geometría',
                  reparaciones: 'Reparaciones',
                  detalles: 'Detalles',
                  fotografias: 'FOTOGRAFÍAS',
                  auditoria: 'AuditorÍa',
                  caracteristicas: 'Características',
                },
              },
              select: {
                margen: {
                  Derecha: 'Derecha',
                  Izquierda: 'Izquierda',
                  Centro: 'Centro'
                },
                sentido: {
                  NORTEName: 'Norte',
                  NORTEId: 'NORTE',
                  SURName: 'Sur',
                  SURId: 'SUR',
                  ESTEName: 'Este',
                  ESTEId: 'ESTE',
                  OESTEName: 'Oeste',
                  OESTEId: 'OESTE'
                },
                tipoRedes: {
                  gas:'Gas',
                  acueducto:'Acueducto',
                  alcantarillado:'Alcantarillado',
                  fibraOptica:'Fibra óptica',
                  longitudinal:'Longitudinal',
                  poste:'Poste',
                  travesia:'Travesía'
                },
                material: {              
                  concreto: 'Concreto',
                  mamposteria: 'Mampostería',
                  prefabricado: 'Prefabricado',
                  otro:'Otro',
                  acero: 'Acero',
                  cobre:'Cobre',
                  concretoAcero: 'Concreto y acero',
                  HDEP:'HDPE',               
                  polipropileno:'Polipropileno',
                  PVC: 'PVC',
                  PEAD: 'PEAD',
                  barreraJerseyPlastica: 'Barrera jersey plástica',
                  barreraJerseyPlasticaId: 'Barrera jersey plastica',
                  malla: 'Malla',
                  metalica: 'Metálica',
                  metalicaId: 'Metalica',
                  plastica: 'Plástica',
                  plasticaId: 'Plastica',
                  polisombra: 'Polisombra',
                  
                },
                seccion: {
                  circular: 'Circular',
                  cuadrada: 'Cuadrada',
                  rectangular: 'Rectangular',
                  trapezoidal: 'Trapezoidal',
                  triangular: 'Triangular',
                  hexagonal:'Hexagonal',
                  perfilH:'Perfil H',
                  otro:'Otro',
                },
                estado: {
                  Existente: 'Existente',
                  Reemplazo: 'Reemplazo',
                  Nuevo: 'Nuevo',
                },
                nivelTrafico: {
                  NT1: 'NT1',
                  NT2: 'NT2',
                  NT3: 'NT3',
                },
                claseIluminacion: {
                  M1: 'M1',
                  M2: 'M2',
                  M3: 'M3',
                  M4: 'M4',
                  M5: 'M5',
                },
                brazoTipo: {
                  A: 'A',
                  B: 'B',
                  C: 'C',
                  D: 'D',
                  E: 'E',
                },
                entornoVia: {
                  claro: 'Claro',
                  oscuro: 'Oscuro',
                },
                tipoLampara:{
                  descarga:'Descarga',
                  incandescentes:'Incandescentes',
                  vaporMercurioAltaPresion:'Vapor de Mercurio a alta presión',
                  vaporSodioAltaPresion:'Vapor de sodio a alta presión',
                  VaporSodioBajaPresion:'Vapor de sodio a baja presión',
                  otro:'Otro'
                },
                disposicionLuminarias:{
                  bilateralPareada:'Bilateral pareada',
                  centralDobleBrazo:'Central con doble brazo',
                  combinaciónBrazosDoblesTresbolill:'Combinación brazos dobles y tresbolillo',
                  unilateralCalzadasDiferenciadas:'Unilateral en calzadas diferenciadas',
                  unilateralLadoExterior:'Unilateral por el lado exterior',
                },
                claseSemaforo:{
                  semaforoEspecial:'Semáforos especiales',
                  semaforosControlTránsitoVehículos:'Semáforos para el control del tránsito de vehículos',
                  semaforosPasosPeatonales:'Semáforos para pasos peatonales',
                  semaforosSonoros:'Semáforos sonoros',
                },
                clasificacionSoporte:{
                  postes:'Postes',
                  cablesSuspención:'Cables de Suspención',
                  estructurasPórtico:'Estructuras tipo Pórtico',
                  mensulasCortas:'Ménsulas Cortas',
                  mensulasLargasPostesLaterales:'Ménsulas Largas Sujetas a Postes Laterales',
                  postesPedestalesIslas:'Postes y Pedestales en Islas',
                },
                condicionElemento: {
                  existente: 'Existente',
                  reemplazo: 'Reemplazo',
                  nuevo: 'Nuevo',
                },
                motivoReemplazo:{
                  accidente: 'Accidente',
                  instalacion: 'Instalación',
                  instalacionId: 'Instalacion',
                  reemplazoProgramado: 'Reemplazo programado',
                  vandalismo: 'Vandalismo',
                  otro: 'Otro',
                },
                estados: {
                  Activo: 'Activo',
                  Inactivo: 'Inactivo',
                }
              },
            },
            inventariopeajes: {
              title: {
                editTitle: 'Editar Inventario Peaje',
                showTitle: 'Información Inventario Peaje',
                listTitle: 'Lista Inventario Peajes'
              },
              tabs: {
                tabsTitle: {
                  identificacion: 'Identificación',
                  auditoria: 'AuditorÍa'
                },
              },
              select: {
                estado: {
                  Activo: 'Activo',
                  Inactivo: 'Inactivo',
                },
              },
              messagesVal: {
                valEntero: 'El valor ingresado debe ser entero',
              }, 
              subTitle: {
                detalles: 'Detalles',
                localizacion: 'Localización',
                coordenadas: 'Coordenadas',
              },
              boolean: {
                estado: 'Estado'
              },
            },
            mensuals: {
              title: {
                editTitle: 'Editar Tránsito Histórico',
                showTitle: 'Información Tránsito Histórico',
                listTitle: 'Lista Tránsito Histórico'
              },
              tabs: {
                tabsTitle: {
                  identificacion: 'Identificación',
                  historico: 'Histórico',
                  InformacionBasica: 'Información básica',
                  InformacionAnual: 'Información anual',
                  Auditoria:'Auditoría',
                }
              },
              select: {
                sentido: {
                  Ascendente: 'Ascendente',
                  Descendente: 'Descendente',
                },
                mes: {
                  Enero: 'Enero',
                  Febrero: 'Febrero',
                  Marzo: 'Marzo',
                  Abril: 'Abril',
                  Mayo: 'Mayo',
                  Junio: 'Junio',
                  Julio: 'Julio',
                  Agosto: 'Agosto',
                  Septiembre: 'Septiembre',
                  Octubre: 'Octubre',
                  Noviembre: 'Noviembre',
                  Diciembre: 'Diciembre',
                }
              },
              subTitle: {
                VolVehicCategoria: 'Volumen de Vehículos por categoría',
                AniosEvaluación: 'Años de evaluación',
                total:'Total',
              },
              graph: {
                title: 'Histórico',
                subTitle: 'Datos total',
                Valores: 'Valores',
              }
            },
            estacion_conteos: {
              title: {
                editTitle: 'Editar Estación de conteo',
                showTitle: 'Información Estación de conteo',
                listTitle: ''
              },
              tabs: {
                tabsTitle: {
                  identificacion: 'Identificación',
                  detalles: 'Detalles',
                  ejesEquivalentes: 'Ejes equivalentes',
                  tasasCrecimiento: 'Tasas de crecimiento',
                  InformacionBasica: 'Información básica',
                  auditoria: 'Auditoría',
                }
              },
              subTitle: {
                detalles: 'Detalles',
                localizacion: 'Localización',
                coordenadas: 'Coordenadas',

                abscisasAfectacion: 'Abscisas de afectación',
              },
              messagesVal: {
                valRango: 'Error el rango debe estar entre ',
                valFechaAntFin: 'La fecha inicial debe ser anterior a la final.',
                SinResultados: 'Sin Resultados.'
              },
              boolean: {
                operativa: 'Operativa',
              },
              table: {
                titles: {
                  categoria: 'Categoría',
                  volumen: 'Volumen',
                  factor: 'Factor',
                  distribucionPorcentual: 'Distribución porcentual',
                },
                rows: {
                  autos: 'Autos',
                  buses: 'Buses',
                }
              }
            },
            indicadores_cargar_informacions: {
              title: {
                editTitle: 'Editar Evaluación',
                showTitle: 'Mostrar Evaluación',
                listTitle: ''
              },
              subTitle: {
                SeleccionIndicador: 'Selección del Indicador',
                Detalles: 'Detalles',
                CargarArchivo: 'Cargar Archivo',
                RangoCorreccion: 'Rango de Corrección es'
              },
              tabs: {
                IndicadorSegmento: 'Indicador Por Segmento',
                ResumenRegistros: 'Resumen Registros',
              },
              select: {
                medidoPor: {
                  Interventoria: 'Interventoría',
                  Mantenimiento: 'Mantenimiento',
                },
                estado: {
                  PorRevisar: 'Por Revisar',
                  Publicada: 'Publicada',
                  RevisionRealizada: 'Revisión realizada',
                },
                tipoMedicion: {
                  periodica: 'Periódica',
                  deCorreccion: 'De Corrección',
                }
              },
              table: {
                tableHead: {
                  SEGMENTO: 'SEGMENTO',
                  IncumpPuntuales: 'Incumplimientos Puntuales',
                  CumplimientoIndicSegmento: 'Cumplimiento del indicador en el segmento',
                  Visualizar: 'Visualizar',

                  abscisa: 'ABSCISA',
                  carrilIzquierdoDescendente: 'CARRIL IZQUIERDO (DESCENDENTE)',
                  carrilDerechoAscendente: 'CARRIL DERECHO (ASCENDENTE)',
                  cumplimientoIndicadorCalzada: 'Cumplimiento Indicador Calzada',

                  PromedioCarrilI: 'Promedio carril I',
                  IncumplPuntualesCarrilI: 'Incumplimientos puntuales carril I',
                  PromedioCarrilD: 'Promedio carril D',
                  IncumplPuntualesCarrilD: 'Incumplimientos puntuales carril D',
                  ValorMedioIRI_mm: 'Valor Medio IRI (mm/m)',
                  ValorMedAhuellamiento_mm: 'Valor Medio Ahuellamiento (mm)',
                  CumplimientoValorPromedio: 'Cumplimiento valor promedio',
                  CumplimientoValorPuntual: 'Cumplimiento valor puntual',
                  CumplimientoIndicadorSegmento: 'Cumplimiento del indicador en el segmento',
                  IncumplPuntualesCalzada: 'Incumplimientos puntuales Calzada',
                  incumplimientosPuntuales: 'Incumplimientos Puntuales',

                  AbscisaInicio: 'Abscisa Inicio',
                  AbscisaFinal: 'Abscisa Final',
                  IRImm: 'IRI (mm/m)',
                  Ahuellamiento_mm: 'Ahuellamiento (mm)',
                  ValorMedioCFT: 'Valor Medio CFT',
                  CumplimientoIndicPuntual: 'Cumplimiento indicador puntual',
                  Evento: 'Evento',
                  Singularidad: 'Singularidad',
                  IRImm_m: 'IRI (mm/m)',
                  CFT: 'CFT',
                  MTDmmASTME1845: 'MTD (mm) ASTM E1845',

                  areaCalzada: 'Área calzada (m²)',
                  cumplimientoIndicador: 'Cumplimiento indicador',

                  AbscisaFisura: 'Abscisa',
                  TipoFisura: 'Tipo de fisura',
                  Severidad: 'Severidad',
                  Largo_m: 'Largo (m)',
                  Largo_cm: 'Largo (cm)',
                  Ancho_m: 'Ancho (m)',
                  Ancho_cm: 'Ancho (cm)',
                  Area_m: 'Área (m²)',

                  Profundidadcm: 'Profundidad (cm)',
                  Observaciones: 'Observaciones',
                  Carril: 'Carril',

                  areaVerdadera: 'Área verdadera',
                  areaAfectada_m: 'Área afectada (m²)',
                  areaAfectada: 'Área afectada (%)',

                  Medida1_cm: 'Medida 1 (cm)',
                  Medida2_cm: 'Medida 2 (cm)',
                  Medida3_cm: 'Medida 3 (cm)',
                  Medida4_cm: 'Medida 4 (cm)',
                  Medida5_cm: 'Medida 5 (cm)',
                  LimpiezaGenCalzada: 'Limpieza general de calzada',
                  LaVegetacionAfecta: '¿La vegetación afecta la seguridad?',
                  NumeroBasuras: 'Número basuras',
                  Cumplimiento: 'Cumplimiento',

                  Elemento: 'Elemento',
                  SeccionHidraulColmatada: 'Sección hidráulica colmatada (%)',

                  RetroreflectividadColorPrin: 'Retroreflectividad (cd/lux/m²) color principal',
                  RetroreflectividadColorSec: 'Retroreflectividad (cd/lux/m²) color secundario',
                  LegibilidadSenal: 'Legibilidad de la señal',
                  Localización: 'Localización',
                  Estabilidad: 'Estabilidad',
                  EstadoLimpieza: 'Estado de limpieza',
                  
                  MedLineaBlanBordeDerecho: 'Medida línea blanca borde derecho',
                  MedLineaBlanBordeIzquierdo: 'Medida línea blanca borde izquierdo',
                  MedidalineaAmarilla: 'Medida línea amarilla',
                  CumplimientoDelineadPiso: 'Cumplimiento delineadores de piso',
                  TipoEquipoGeometria_cm: 'Tipo de equipo con geometría (cm)',
                  CumplimientoIndicador: 'Cumplimiento del Indicador',

                  Alineacion: 'Alineación',
                  CondicionEstructural: 'Condición estructural',
                  Estabilidad: 'Estabilidad',
                  LongitudIncumplimiento: 'Longitud con incumplimiento (m)',

                  PromedioCarrilIzquierdo: 'Promedio carril Izquierdo',
                  PromedioCarrilDerecho: 'Promedio carril Derecho',
                  IncumplimientosPuntuales: 'Incumplimientos puntuales',

                  areaAfectada: 'Área Afectada (%)',

                  CarrilIzquierdo: 'Carril Izquierdo',
                  CarrilDerecho: 'Carril Derecho',

                  PCI: 'PCI (%)',
                  CodigoColor: 'Código de Color',

                  Escalonamiento: 'Escalonamiento (mm)',
                  ExtensionDesportillam: 'Extensión de desportillamientos (mm)',

                  LongitudJuntasAfectadas: 'Longitud de juntas afectadas (m)',

                  FuncionamientoLuminaria: 'Funcionamiento de la luminaria',
                  EstadofisicoLuminaria: 'Estado físico de la luminaria',
                  EstadoSoportes: 'Estado de los soportes',
                  EstadoCentroMando: 'Estado del centro del mando',
                  EstadoTableroControl: 'Estado del tablero de control',
                  porcentajeNoCumple:'Porcentaje de elementos que no cumplen',
                  totalElementos:'Total elementos',
                  elementosQueNoCumplen:'Elementos con incumpliento',

                  CodigoASTM: 'Código ASTM',
                  Alto: 'Alto',
                  Medio: 'Medio',
                  Bajo: 'Bajo',
                  mostrar:'Mostrar',
                  ocultar:'Ocultar',
                  noCumple:'No Cumple',
                  Cumple:'Cumple',
                  noAplica:'No Aplica',
                  adecuado:'Adecuado',
                  inadecuado:'Inadecuado',
                  booleanSi:'Si',
                  booleanNo:'No',
                  estadoa: {
                    Bueno: 'Bueno',
                    Regular: 'Regular',
                    Malo: 'Malo',
                  },
                },
                tableVisualizacion:{
                  SEGMENTO: 'SEGMENTO',
                  fechaEvaluacion:'Fecha de evaluación',
                  estado:'Estado',
                  medidoPor:'Medido por',
                  porcentajeElemento:'Porcentaje de elementos defectuosos (%)',
                  cumplimientoIndicador:'Cumplimiento del indicador en el segmento',
                  visualizar:'Visualizar',
                  elemento:'Elemento',
                  funcionamientoLuminaria:'Funcionamiento de la luminaria',
                  estadofisicoLuminaria:'Estado físico de la luminaria',
                  estadoSoportes: 'Estado de los soportes',
                  estadoCentroMando: 'Estado del centro del mando',
                  estadoTableroControl: 'Estado del tablero de control',
                  observaciones:'Observaciones',
                  cumplimiento:'Cumplimiento',
                  puente:'Puente',
                  fechaInspeccion:'Fecha de inspección',
                  totalAreaAfectada:'Total área afectada',
                  porcentajeAreaAfectada:'Porcentaje área afectada',
                  deterioroJuntas:'Deterioros en juntas de expansión',
                  deterioroAndenes:'Deterioros en andenes y bordillos',
                  deterioroBarreras:'Deterioros en barreras de seguridad',
                  fisuraEstructura:'Fisuras en estructura',
                  exposicionAcero:'Exposición de acero de refuerzo',
                  corrosion:'Corrosión',
                  deterioroDrenaje:'Deterioros en drenaje',
                  promedioCarrilIzquierdo:'Promedio carril Izquierdo',
                  promedioCarrilDerecho:'Promedio carril Derecho',
                  incumplimientosPuntuales:'Incumplimientos puntuales',
                  nivelTrafico:'Nivel de Tráfico:',
                  abscisa:'Abscisa',
                  carrilIzquierdo:'Carril Izquierdo',
                  carrilDerecho:'Carril Derecho',
                  Escalonamiento:'Escalonamiento (mm)',
                  ExtensionDesportillam: 'Extensión de desportillamientos (mm)',
                  areaAfectada: 'Área Afectada (%)',
                  LongitudJuntasAfectadas: 'Longitud de juntas afectadas (m)',
                  Alineacion:'Alineación',
                  CondicionEstructural:'Condición estructural',
                  estabilidad:'Estabilidad',
                  LongitudIncumplimiento: 'Longitud con incumplimiento (m)',
                  medidaLineaBlancaDerecho:'Medida línea blanca borde derecho',
                  medidaLineaBlancaIzquierdo:'Medida línea blanca borde izquierdo',
                  medidalineaAmarilla:'Medida línea amarilla',
                  cumplimientoDelineadores:'Cumplimiento delineadores de piso',
                  TipoEquipoGeometria_cm: 'Tipo de equipo con geometría (cm)',
                  PromedioCarrilI: 'Promedio carril I',
                  IncumplPuntualesCarrilI: 'Incumplimientos puntuales carril I',
                  PromedioCarrilD: 'Promedio carril D',
                  IncumplPuntualesCarrilD: 'Incumplimientos puntuales carril D',
                  ValorMedioIRI_mm: 'Valor Medio IRI (mm/m)',
                  ValorMedAhuellamiento_mm: 'Valor Medio Ahuellamiento (mm)',
                  ValorMedioCFT: 'Valor Medio CFT',
                  CumplimientoValorPromedio: 'Cumplimiento valor promedio',
                  CumplimientoValorPuntual: 'Cumplimiento valor puntual',
                },
                tableMessege: 'No se tiene visualización por segmento para este indicador',
              },
              messagesVal: {
                fechaMedicion:'La fecha no puede ser mayor a la actual',
                noTieneVisualizacion:'No se tiene visualización para este indicador.',
                noSeEncontro:'No se encontraron datos.',
              }
            },
            calificacion_puentes: {
              title: {
                editTitle: 'Editar Calificación Puentes',
                showTitle: 'Información Calificación Puentes',
              },
              tabs: {
                tabsTitle: {
                  detalles: 'Detalles',
                  Inspeccion: 'Inspección',
                  fotografias: 'FOTOGRAFÍAS',
                  auditoria: 'Auditoría',
                }
              },
              select: {
                medidoPor: {
                  Interventoria: 'Interventoría',
                  Mantenimiento: 'Mantenimiento',
                },
                estado: {
                  PorRevisar: 'Por revisar',
                  RevisionRealizada: 'Revisión realizada',
                  Publicada: 'Publicada',
                },
                tipoMedicion: {
                  Periodica: 'Periódica',
                  DeCorreccion: 'De corrección',
                }
              },
              tableShow: {
                titleTable: {
                  Activo: 'Activo',
                  Cantidad: 'Cantidad',
                  LugarDetalle: 'Lugar detalle',
                },
              },
              inspeccion: {
                dato: 'Dato',
                LugarDetalle: 'Lugar Detalle',
                tablero: {
                  TableroTitle: 'Tablero',
                  SuperficieGrietas: 'Superficie con grietas (m²)',
                  Area: 'Área (m²)',
                  SuperficieDanada: 'Superficie dañada (m²)',
                  SuperficieDesconchados: 'Superficie con desconchados (m²)',
                  TotalAreaAfectada: 'Total área afectada (m²)',
                  PorcentajeAreaAfectada: 'Porcentaje área afectada',
                  SumaAreas: 'Suma de áreas',
                  Calculo: 'Cálculo (%)'
                },
                juntasExpansion: {
                  juntasExpansionTitle: 'Juntas de Expansión',
                  NumeroJuntasExpan: 'Número de juntas de expansión',
                  ObstruccionSello: 'Obstrucción del sello (m)',
                  RupturaSello: 'Ruptura del sello (m)',
                  AusenciaSello: 'Ausencia del sello (m)',
                  AgrietamientoRoturaSoldad: 'Agrietamiento o rotura de soldaduras (m)',
                  AusenciaAnclajes: 'Ausencia de anclajes (m)',
                  PerfilesDefectuosos: 'Perfiles defectuosos (m)',
                  PerfilesSueltos: 'Perfiles sueltos (m)',
                  DesgasteGuardacantos: 'Desgaste en guardacantos (m)',
                  DesportillamientoGuardacantos: 'Desportillamiento en guardacantos (m)',
                  FisuramientoGuardacantos: 'Fisuramiento de guardacantos (m)',
                  JuntaFuncional: 'Junta no funcional',
                  
                },
                AndenesBordillos: {
                  AndenesBordillosTitle: 'Andenes / Bordillos',
                  Desportillamiento: 'Desportillamiento (m)',
                  AceroRefuerzoExpuesto: 'Acero de refuerzo expuesto (m)',
                  DimensionesInsuficientes: 'Dimensiones insuficientes (m)',
                },
                BarrSegBaranPretilMetal: {
                  Title: 'Barreras de seguridad, barandillas o pretiles metálicos',
                  ConexionesMalEstado: 'Conexiones en mal estado (m)',
                  ProteccionesMalEstado: 'Protecciones en mal estado (m)',
                  Pintura: 'Pintura',
                  Delaminacion: 'Delaminación (m)',
                  Ausencia: 'Ausencia (m)',
                  Deterioro: 'Deterioro (m)',
                },
                Postes: {
                  titlePostes: 'Postes',
                  Fracturamiento: 'Fracturamiento (m)',
                  Ausencia: 'Ausencia (m)',
                  GolpesImpacto: 'Golpes por impacto (m)',
                  Grietas5mm: 'Grietas > 5 mm (m)',
                  Desconchados10mm: 'Desconchados > 10 mm (m)',
                },
                Pasamanos: {
                  titlePasamanos: 'Pasamanos',
                  Corrosión: 'Corrosión (m)',
                  AusenciaElementos: 'Ausencia de elementos (m)',
                  GolpesImpacto: 'Golpes por impacto (m)',
                  Grietas5mm: 'Grietas > 5 mm (m)',
                  Desconchados10mm: 'Desconchados > 10 mm (m)',
                },
                DanosEstrucConcreto: {
                  Title: 'Daños Estructura de concreto',
                  Subestructura: 'Subestructura',
                  FisurasFlexion: 'Fisuras por flexión (m)',
                  FisurasCortante: 'Fisuras por cortante (m)',
                  FisurasTorsion: 'Fisuras por torsión (m)',
                  AplastamientoLocal: 'Aplastamiento local (Und)',
                  Asentamientos: 'Asentamientos (Und)',
                  Volcamiento: 'Volcamiento (Und)',
                  Segregación: 'Segregación (m²)',
                  Hormigueros: 'Hormigueros (m²)',
                  FisurasRetraccion: 'Fisuras por retracción (m²)',
                  RecubrimientoInadecuado: 'Recubrimiento inadecuado (m²)',
                  ExposicionAceroRef: 'Exposición del acero de refuerzo (m²)',
                  Infiltracion: 'Infiltración (m²)',
                  Eflorescencias: 'Eflorescencias (m²)',
                  Carbonatacion: 'Carbonatación (m²)',
                  CorrosionArmadura: 'Corrosión en la armadura (m/m²)',
                  FallasImpacto: 'Fallas por impacto (m²)',
                  Socavacion: 'Socavación (m²)',
                  Erosion: 'Erosión (m²)',

                  DanosEstrucMetalicas: 'Daños Estructuras Metálicas',
                  CorrosionLeve: 'Corrosión leve (Und)',
                  CorrosionMedia: 'Corrosión media (Und)',
                  CorrosionSevera: 'Corrosión severa (Und)',
                  PinturaDeteriorada: 'Pintura deteriorada (%)',

                  CablesPendolones: 'Cables y pendolones',
                  PerdidaRecubriCables: 'Pérdida de recubrimiento de cables (m)',
                  FaltaTensionCablesPendol: 'Falta de tensión de cables y pendolones (Und)',
                  FisurasAlambres: 'Fisuras en alambres (Und)',
                  FisurasMuertosAnclajes: 'Fisuras en muertos de anclajes (Und)',
                  ContaminacionZonaAnclajes: 'Contaminación en zona de anclajes (Und)',

                  PerfMetalTorresMiembrosArmad: 'Perfiles metálicos, Torres y miembros de armaduras',
                  PandeoLocal: 'Pandeo local (Und)',
                  PandeoGeneralLateral: 'Pandeo general lateral (Und)',
                  FisurasVigas: 'Fisuras en vigas (Und)',
                  FallasImpacto: 'Fallas por impacto (Und)',
                  DeflexionExcesiva: 'Deflexión excesiva (Und)',

                  Conexiones: 'Conexiones',
                  AusenciaConectores: 'Ausencia de conectores (Und)',
                  Excentricidades: 'Excentricidades (Und)',
                  FallasTensionPlatina: 'Fallas por tensión en la platina (Und)',
                  AplastamientoPlatina: 'Aplastamiento en la platina (Und)',
                  FallasDesgarramiento: 'Fallas por desgarramiento (Und)',
                  FallaCorteConector: 'Falla por corte en el conector (Und)',
                  FallaBloqueCortante: 'Falla por bloque de cortante (Und)',
                  RoturaSoldadura: 'Rotura de la soldadura (Und)',

                  Drenaje: 'Drenaje',
                  SuperficieErosionada: 'Superficie erosionada (Und)',
                  DanoEstructural: 'Daño estructural (Und)',
                  Taponamiento: 'Taponamiento (Und)',
                  Ausencia: 'Ausencia (Und)',
                  LongitudInsuficiente: 'Longitud insuficiente (Und)',

                },
                lugar_detalle: {
                  EntradaPuente: 'Entrada del puente',
                  SalidaPuente: 'Salida del puente',
                  JuntaIntermedia: 'Junta intermedia',
                  CostadoDerecho: 'Costado derecho',
                  CostadoIzquierdo: 'Costado izquierdo',
                  Losa: 'Losa',
                  Vigas: 'Vigas',
                  Riostras: 'Riostras',
                  Apoyos: 'Apoyos',
                  Arcos: 'Arcos',
                  Cimentacion: 'Cimentación',
                  Rotulas: 'Rótulas',
                  ArcosMetalicos: 'Arcos metálicos',
                  PerfilesMetalicos: 'Perfiles metálicos',
                  Armaduras: 'Armaduras',
                  Conexiones: 'Conexiones',
                  Cables: 'Cables',
                  Pendolones: 'Pendolones',
                  Torres: 'Torres',
                },
              }
            },
            disponibilidad_vias: {
              title: {
                editTitle: 'Editar Disponibilidad de Vía',
                showTitle: 'Información de Disponibilidad de Vía',
                listTitle: 'Lista de Disponibilidad Vías'
              }, 
              tabs: {
                tabsTitle: {
                  identificacion: 'IDENTIFICACIÓN',
                  detalles: 'DETALLES',
                  caracteristicas: 'CARACTERÍSTICAS',
                  fotografias: 'FOTOGRAFÍAS',
                  InformacionBasica: 'Información básica',
                  auditoria: 'Auditoría',
                  finalizar: 'FINALIZAR'
                }
              },
              select: {
                tipoEvento: {
                  Incidente: 'Incidente',
                  Accidente: 'Accidente',
                  Cierre: 'Cierre',
                },
                tipoCierre: {
                  Calzada: 'Calzada',
                  Carril: 'Carril',
                },
                margen: {
                  Izquierda: 'Izquierda',
                  Derecha: 'Derecha',
                  Centro:'Centro',
                },
                fuenteRep: {
                  Llamada: 'Llamada',
                  Email: 'Email',
                  Recorredor: 'Recorredor',
                },
                causa: {
                  Accidente: 'Accidente',
                  Derrumbe: 'Derrumbe',
                  TrabajosVia: 'Trabajos en vía',
                  Manifestacion: 'Manifestación',
                  OrdenPublico: 'Orden público',
                  Otro: 'Otro',
                },
                elementoAfect: {
                  Calzada: 'Calzada',
                  Puente: 'Puente',
                  Tunel: 'Túnel',
                  Bermas: 'Bermas',
                },
                serviciosDirectorio: {
                  Emergencias: 'Emergencias',
                  Proveedores: 'Proveedores',
                  ServiciosPublicos: 'Servicios Públicos',
                },
                grupoEmergencias: {
                  POBSupervisionVial: 'POB-Supervisión vial',
                  POBAdministrativa: 'POB-Administrativa',
                  POBRadioOperacion: 'POB-Radio Operación',
                  POBGruas: 'POB-Grúas',
                  POBCarroTaller: 'POB-Carro taller',
                  POBAmbulancia: 'POB-Ambulancia',
                  POBPeajes: 'POB-Peajes',
                  Interventoria: 'Interventoría',
                  ServiciosMedicos: 'Servicios médicos',
                  Policia: 'Policía',
                  Ejercito: 'Ejército',
                  DefensaCivil: 'Defensa civil',
                  Bomberos: 'Bomberos',
                  CentroEducativo: 'Centro educativo',
                  Alcaldia: 'Alcaldía',
                  EmisoraRadial: 'Emisora radial',
                  Talleres: 'Talleres',
                  Maquinaria: 'Maquinaria',
                  Otros: 'Otros',
                },
                grupo: {
                  Acueducto: 'Acueducto',
                  GasNatural: 'Gas natural',
                  Energia: 'Energía',
                  Telecomunicaciones: 'Telecomunicaciones',
                  Aseo: 'Aseo',
                  Otros: 'Otros',
                },
                tipoAccidente: {
                  Simple: 'Simple',
                  Heridos: 'Heridos',
                  Muertos: 'Muertos',
                },
                tipoHeridos: {
                  Peaton: 'Peatón',
                  Ciclista: 'Ciclista',
                  Motociclista: 'Motociclista',
                  Conductor: 'Conductor',
                  Pasajero: 'Pasajero',
                  Otro: 'Otro',
                },
                generoHeridos: {
                  Hombre: 'Hombre',
                  Mujer: 'Mujer',
                },
                categoriaVehiculos: {
                  Peaton: 'Peatón',
                  Bicicleta: 'Bicicleta',
                  Motocicleta: 'Motocicleta',
                  Automovil: 'Automóvil',
                  Taxi: 'Taxi',
                  Bus: 'Bus',
                  Camion: 'Camión',
                  MaquinaConstr: 'Maquinaria construcción',
                  MaquinariaAgricola: 'Maquinaria agrícola',
                  ObjetoFijo: 'Objeto fijo',
                  ObjetoMovil: 'Objeto móvil',
                  Otros: 'Otros',
                },
                fechaSenal: {
                  Enero: 'Enero',
                  Febrero: 'Febrero',
                  Marzo: 'Marzo',
                  Abril: 'Abril',
                  Mayo: 'Mayo',
                  Junio: 'Junio',
                  Julio: 'Julio',
                  Agosto: 'Agosto',
                  Septiembre: 'Septiembre',
                  Octubre: 'Octubre',
                  Noviembre: 'Noviembre',
                  Diciembre: 'Diciembre',
                }
              },
              subTitle: {
                localizacion: 'Localización',
                AsociarServicios: 'Asociar Servicios',
                JuicioPolicia: 'Juicio de la Policía',
                Heridos: 'Heridos',
                VictimasFatales: 'Víctimas Fatales',
                VehiculosImplicados: 'Vehículos Implicados',
                fechaAmbulanciaRegistrada: 'La fecha de ambulancia ya fue registrada.',
                NoSegmentosCierre: 'No. Segmentos con cierre',
                fechaGruaRegistrada:'La fecha de grua ya fue registrada',
                disponibilidadVia:'La disponibilidad de vía actual ya fue finalizada',
                finalizar:'Finalizar',
                directorio:'Directorio',
                grupoSistema:'Grupo / Sistema',
                elemento:'Elemento',
              }
            },
            parametros_analises: {
              title: {
                editTitle: 'Editar Parámetros de planeación',
                showTitle: 'Información Parámetros de planeación',
                listTitle: ''
              },
              tabs: {
                tabsTitle: {
                  InformacionBasica: 'Información básica',
                  auditoria: 'Auditoría',
                }
              },
              table: {
                titleTable: 'Presupuesto disponible',
                nameColumns: {
                  Anio: 'Año',
                  PresupMantenPavimento: 'Presupuesto mantenimiento pavimento',
                  PresupMantenOtrosElemen: 'Presupuesto mantenimiento otros elementos',
                  PresupTotal: 'Presupuesto Total',
                }
              }
            },
            politicas_mantenimientos: {
              title: {
                editTitle: 'Editar política de Mantenimiento',
                showTitle: 'Información Política de Mantenimiento',
                listTitle: 'Lista Políticas de Mantenimiento',
              },
              tabs: {
                tabsTitle: {
                  identificacion: 'IDENTIFICACIÓN',
                  auditoria: 'Auditoría',
                  INFORMACION: 'INFORMACION',
                  actualizarNombre:'Actualizar Nombre de Política',
                  politica:'Seleccione Política',
                  nuevoNombre:'Nuevo nombre', 
                  noDiligenciado:'No Diligenciado',
                }
              },
              expansionPanel: {
                ElemenMantenRutinario: 'Elementos para Mantenimiento Rutinario',
                ElemenMantenPeriodico: 'Elementos para Mantenimiento Periódico',
                ElemenMantenRehabilitacion: 'Elementos para Mantenimiento Rehabilitación',
                ElemenMantenReconstruccion_Cambio: 'Elementos para Mantenimiento Reconstrucción/Cambio',
              },
              boolean: {
                seRealiza: 'Se Realiza',
              },
              select: {
                periocidad: {
                  Mes1: '1 Mes',
                  Meses3: '3 Meses',
                  Meses6: '6 Meses',
                  Meses9: '9 Meses',
                  Meses12: '12 Meses',
                  Meses18: '18 Meses',
                  Meses24: '24 Meses',
                  Meses36: '36 Meses',
                  anios2: '2 Años',
                  anios2_5: '2,5 Años',
                  anios3: '3 Años',
                  anios3_5: '3,5 Años',
                  anios4: '4 Años',
                  anios4_5: '4,5 Años',
                  anios5: '5 Años',
                  anios5_5: '5,5 Años',
                  anios6: '6 Años',
                  anios6_5: '6,5 Años',
                  anios7: '7 Años',
                  anios7_5: '7,5 Años',
                  anios8: '8 Años',
                  anios8_5: '8,5 Años',
                  anios9: '9 Años',
                  anios9_5: '9,5 Años',
                  anios10: '10 Años',
                  anios11: '11 Años',
                  anios12: '12 Años',
                  anios13: '13 Años',
                  anios14: '14 Años',
                  anios15: '15 Años',
                  anios16: '16 Años',
                  anios17: '17 Años',
                  anios18: '18 Años',
                  anios19: '19 Años',
                  anios20: '20 Años',
                  anios21: '21 Años',
                  anios22: '22 Años',
                  anios23: '23 Años',
                  anios24: '24 Años',
                  anios25: '25 Años',
                  anios26: '26 Años',
                  anios27: '27 Años',
                  anios28: '28 Años',
                  anios29: '29 Años',
                  anios30: '30 Años',
                },
                operacion: {
                  reconstruccion: 'Reconstrucción',
                  cambio: 'Cambio',
                }
              },
              messages: {
                NingunaPolDefinida: 'Ninguna Política Definida',
              },
            },
            politicas_mantenimiento_particulars: {
              title: {
                editTitle: 'Editar política de Mantenimiento Particular',
                showTitle: 'Información Política de Mantenimiento Particular',
                listTitle: 'Lista Política de Mantenimiento Particular',
              },
              tabs: {
                tabsTitle: {
                  identificacion: 'IDENTIFICACIÓN',
                  auditoria: 'Auditoría',
                  informacion: 'INFORMACION',
                }
              },
              select: {
                rutinario: {
                  Mes1: '1 Mes',
                  Meses3: '3 Meses',
                  Meses6: '6 Meses',
                  Meses9: '9 Meses',
                  Meses12: '12 Meses',
                  Meses18: '18 Meses',
                },
                periodico: {
                  Mes1: '1 Mes',
                  Meses3: '3 Meses',
                  Meses6: '6 Meses',
                  Meses9: '9 Meses',
                  Meses12: '12 Meses',
                  Meses18: '18 Meses',
                  Meses24: '24 Meses',
                  Meses36: '36 Meses',
                },
                rehabilitacion: {
                  anios2: '2 Años',
                  anios2_5: '2,5 Años',
                  anios3: '3 Años',
                  anios3_5: '3,5 Años',
                  anios4: '4 Años',
                  anios4_5: '4,5 Años',
                  anios5: '5 Años',
                  anios5_5: '5,5 Años',
                  anios6: '6 Años',
                  anios6_5: '6,5 Años',
                  anios7: '7 Años',
                  anios7_5: '7,5 Años',
                  anios8: '8 Años',
                  anios8_5: '8,5 Años',
                  anios9: '9 Años',
                  anios9_5: '9,5 Años',
                  anios10: '10 Años',
                },
                cambio: {
                  anios3: '3 Años',
                  anios4: '4 Años',
                  anios5: '5 Años',
                  anios6: '6 Años',
                  anios7: '7 Años',
                  anios8: '8 Años',
                  anios9: '9 Años',
                  anios10: '10 Años',
                  anios11: '11 Años',
                  anios12: '12 Años',
                  anios13: '13 Años',
                  anios14: '14 Años',
                  anios15: '15 Años',
                  anios16: '16 Años',
                  anios17: '17 Años',
                  anios18: '18 Años',
                  anios19: '19 Años',
                  anios20: '20 Años',
                  anios21: '21 Años',
                  anios22: '22 Años',
                  anios23: '23 Años',
                  anios24: '24 Años',
                  anios25: '25 Años',
                  anios26: '26 Años',
                  anios27: '27 Años',
                  anios28: '28 Años',
                  anios29: '29 Años',
                  anios30: '30 Años',
                }
              },
              boolean: {
                seRealiza: 'Se Aplica Política Particular',
                seRealizaModif: 'Se Modifica',
              },
              expansionPanel: {
                Elementos: 'Elementos',
                MantenimientoRutinario: 'Mantenimiento particular Rutinario',
                MantenimientoPeriodico: 'Mantenimiento particular Periódico',
                MantenimientoRehabilitacion: 'Mantenimiento particular Rehabilitación',
                MantenimientoCambio: 'Mantenimiento particular Reconstrucción/Cambio',
              },
              messages: {
                SinResultados: 'Sin Resultados.',
                SinPoliticaPartiDilig: 'Sin Politica Particular Diligenciada.',
                SinElementos:'Sin elementos asociados',
                tramo:'Sin Tramo Definido.',
                absInicial:'Sin Abscisa Inicial Definida.',
                absFinal:'Sin Abscisa Final Definida.',
              },
              table: {
                nameColumns: {
                  Periodicidad: 'Periodicidad',
                  ValorU: 'Valor U.',
                  FechaU: 'Fecha U.',
                  Fuente: 'Fuente',
                  FechaIniPeriodicidad: 'Fecha de Inicio de Periodicidad',
                  Descripcion: 'Descripción',
                  idInventario:'ID en Inventario',
                  absInicial:'Abscisa Inicial',
                  absFinal:'Abscisa Final',
                  politicaParticular:'Aplica Política Particular',
                },
                periocidad: {
                  mes: ' Mes',
                  meses: ' Meses',
                  anos: ' Años',
                }
              }
            },
            trayectorias: {
              title: {
                editTitle: 'Editar Alternativas',
                showTitle: 'Información Alternativas',
                listTitle: 'Lista Alternativas'
              },
              tabs: {
                Alternativas: 'Alternativas',
                auditoria: 'Auditoría'
              },
              tableEspesor: {
                nameColumns: {
                  Espesores: 'Espesores',
                  CodigoMaterial: 'Código material',
                },
                nameRows: {
                  EspesorCapa1: 'Espesor capa 1 (mm)',
                  EspesorCapa2: 'Espesor capa 2 (mm)',
                  EspesorCapa3: 'Espesor capa 3 (mm)',
                  EspesorCapa4: 'Espesor capa 4 (mm)',
                  EspesorCapa1Show: 'Espesor capa 1',
                  EspesorCapa2Show: 'Espesor capa 2',
                  EspesorCapa3Show: 'Espesor capa 3',
                  EspesorCapa4Show: 'Espesor capa 4',
                }
              },
              tableIndicador: {
                nameColumns: {
                  Indicador: 'Indicador',
                  Mejorar: 'Mejorar',
                  Limite: 'Limite',
                },
                nameRows: {
                  IRI: 'IRI(mm/m)',
                  Friccion: 'Fricción',
                  Ahuellamiento: 'Ahuellamiento(mm)',
                  PCI: 'PCI(%)',
                }
              },
              boolean: {
                rigido: 'Rígido',
                flexible: 'Flexible',
              },
              messages:{
                precioTotalIgual:'El precio total no puede ser igual al precio carpeta',
                precioTotalMayor:'El precio total debe ser mayor al precio carpeta',
              }
            },
            modelo_costos_pers: {
              title: {
                editTitle: 'Editar Deterioro PERS',
                showTitle: 'Modelo de costos PERS',
                listTitle: 'Reparaciones menores'
              },
              tabs: {
                identificacion: 'Identificación',
                auditoria: 'Auditoría'
              },
              buttons: {
                guadarAgregar: 'GUADAR Y AGREGAR',
                guadarFinalizar: 'GUADAR Y FINALIZAR',
              },
              modalFormulas: {
                btnFormulas: 'Fórmulas',
                titleModal: 'Fórmulas de Cálculo',
                CostoReparacion: 'Costo de reparación = a*PCI-b*ÁREA SECCIÓN (m2)',
                Costo1: 'Costo = 0 si PCI es mayor a x',
                Costo2: 'Costo = 0 si costo reparación del segmento es menor a y',
              },
              tableVariable: {
                title: 'Variable',
                nameColumns: {
                  Flexible: 'Flexible',
                  Rigido: 'Rígido',
                },
                nameRows: {
                  variableA: 'Variable a',
                  variableB: 'Variable b',
                  variableX: 'Variable x',
                  variableY: 'Variable y',
                }
              },
              tableFlexible: {
                titleTable: 'Flexible',
                nameColumns: {
                  PCI: 'PCI',
                  CostoBase: 'Costo Base',
                  CostoConsidRestr: 'Costo considerando restricciones',
                }
              },
              tableRigido: {
                titleTable: 'Rígido',
                nameColumns: {
                  PCI: 'PCI',
                  CostoBase: 'Costo Base',
                  CostoConsidRestr: 'Costo considerando restricciones',
                }
              },
              grafica: {
                titleGraph: 'Reparaciones menores',
                axisY: 'Costo considerando restricciones  (miles $)',
                axisX: 'Valor de PCI',
                serieFlexible: 'Flexible',
                serieRigido: 'Rígido',
              }
            },
            pers_exports: {
              btnGenerarInf: 'Generar Informe',
              title:'Lista Pers',
              btnDescargar:'Descargar',
              btnCrearInforme:'Crear informe',
              titleCrearInforme:'Crear Informe PERS',
              modal: {
                title: 'Crear Informe PERS',
              }
            },
            costos: {
              title: {
                editTitle: 'Editar Costos',
                showTitle: 'Información Costos'
              },
              tabs: {
                identificacion: 'IDENTIFICACIÓN',
                auditoria: 'AuditorÍa',
                informacion: 'Informacion'
              },
              boolean: {
                rango: '¿Por Rango de Abscisas?'
              },
              modal: {
                visualizarCostos:'Visualizar Costos',
                btnPlantilla: 'Plantilla.csv',
                titleModal: 'Plantilla para Carga Masiva',
                text1: 'La carga masiva requiere de una primera línea con los nombres de las columnas como se indica a continuación:',
                nameColumns: 'codigo;tipo de mantenimiento;fecha mantenimiento;costo mantenimiento;calificacion;observaciones;proveedor',
                text2: 'Los datos siguientes, deben corresponder en orden con dichas columnas. Por ejemplo:',
                ejemplo1: 'UF1A::DRE::TR1::SG03::ZCOR::001;pe;2/01/2020;2;2;observaciones;90012345678',
                ejemplo2: 'UF1A::DRE::TR1::SG01::POZT::001;re;3/01/2020;3;3;observaciones;90012345678',
                ejemplo3: 'UF1A::DRE::TR1::SG01::CUNT::001;ca;4/01/2020;4;4;observaciones;90012345678',
                text3: 'Los datos ingresados son según su origen excepto el tipo de mantenimiento, que debe indicarse usando las siglas [ru | pe | re | ca] para indicar los tipos de mantenimiento Rutinario, Periodico, Rehabilitación y Cambio respectivamente',
                nota: 'NOTA: el valor de proveedor se indica con el Número de Identificación registrado en la aplicación.',
                text4: 'Descargue la plantilla en CSV con valores de ejemplo usando el siguiente enlace:',
                enlace: 'Enlace a Plantilla',
              },
              exportar:{
                datos:'Datos',
                exportarBusqueda:'Exportar Búsqueda',
                busquedaActual:'¿Exportar la busqueda actual como un archivo de excel?',
                enlace:'Enlace de Descarga',
              },
              select: {
                tipoMantenimientos: {
                  Rutinario: 'Rutinario',
                  Periodico: 'Periódico',
                  Rehabilitacion: 'Rehabilitación',
                  Cambio: 'Cambio',
                }
              },
              tableDatos: {
                titleTable: 'Datos',
                columnNames: {
                  codigo: 'Código',
                  tipoMantenimiento: 'Tipo de Mantenimiento',
                  fechaMantenimiento2: 'Fecha de Mantenimiento',
                  costoMantenimiento: 'Costo',
                  razonSocial: 'Proveedor',
                  calificacion: 'Calif.',
                  observaciones: 'Observaciones',
                  tipoMantenShow: 'Tipo de Mant.',
                  fechaMantenShow: 'Fecha de Mant.',
                  tipo:'Tipo',
                  fecha:'Fecha',
                  costo:'Costo',
                  proveedor:'Proveedor',
                  calificacion:'Calificación',
                  rutinario:'Total Costos Mantenimiento Rutinario',
                  periodico:'Total Costos Mantenimiento Periódico',
                  rehabilitacion:'Total Costos Mantenimiento Rehabilitación',
                  cambio:'Total Costos Mantenimiento Cambio',
                  costoPorMantenimiento:'Costos por Mantenimiento',
                  costoEn:'Costo en $',
                  tiempo:'Tiempo',

                }
              },
              modalEdit: {
                title: 'Editar ',
              },
              modalVisual: {
                btnVisual: 'Visualización',
              }
            },
            flujo_cajas: {
              title: {
                editTitle: 'Editar Flujo de Caja',
                showTitle: 'Información Flujo de Caja',
                listTitle: 'Lista Flujos de Caja',
              },
              tabs: {
                identificacion: 'IDENTIFICACIÓN',
                informacion: 'informacion',
                auditoria: 'AuditorÍa'
              },
              messages: {
                ConsInfoMatriz: 'Consultando Información de la Matriz',
                generarMatriz:'Generar Matriz',
                generarMatriz2:'¿Generar una Matriz?',
                confirmacionMatriz:'¿Seguro que desea generar una matriz?',
              }
            },
            validaciones: {
              ValorMaximoAbsCreate: ' El valor ingresado no puede ser mayor a PR999+999',
              ValorMaximoPrCreate: 'El valor ingresado no puede ser mayor a PR999+999',
              validarLogicaAbsFinCreate: 'Abscisa fin debe ser mayor a Abscisa inicio',
              AbsRamal: 'La distancia entre las Abscisas de ser menor a 3.000',
              validarLogicaPrFinCreate: 'PR fin debe ser mayor a PR inicio',
              latMininima: 'El rango debe estar entre ',
              latMininimay: ' y ',
              lngMininima: 'El rango debe estar entre ',
              lngMininimay: ' y ',
              altitud: 'El rango debe estar entre ',
              altitudy: ' y ',
              isFechaInvalida2000: 'La fecha debe ser a partir del año 2000',
              isFechaInvalidaSup: 'La fecha indicada no puede ser futura.',
              Entero: 'Debe ser un número entero',
              abValidationInicio: 'Error el rango debe estar entre ',
              abValInicioy: ' y ',
              abValidationFin: 'Error el rango debe estar entre ',
              abValFiny: ' y ',
              prValidationInicio: 'Error el rango debe estar entre ',
              prValIniy: ' y ',
              prValidationFin: 'Error el rango debe estar entre ',
              prValnFiny: ' y ',
              MansajeValidadorAb: 'Rango no válido'
            },
            indicador_complementarios: {
              title: {
                editTitle: 'Editar Conf. Ind. Complementario',
                showTitle: 'Información Conf. Ind. Complementario',
                listTitle: 'Lista de Conf. Ind. Complementarios',
              },
              subTitle: {
                preguntas: 'Preguntas:',
                respuestas: 'Respuestas:',
                consideracionesPeso: 'Consideraciones Peso'
              },
              tabs: {
                identificacion: 'IDENTIFICACIÓN',
                informacion: 'informacion',
                auditoria: 'AuditorÍa',
                preguntas: 'Preguntas'
              },
              select: {
                estado: {
                  Activo: 'Activo',
                  Inactivo: 'Inactivo'
                },
                tipoCampo: {
                  seleccionMultiple: 'Selección Múltiple',
                  numerico: 'Numérico',
                }
              },
            },
            respuesta_evaluacion_complementarios: {
              title: {
                list:'Lista de Indicadores Complementarios',
                editTitle: 'Editar Indicador Complementario',
                showTitle: 'Información Indicador Complementario',
                listTitle: 'Lista de Conf. Ind. Complementarios',
              },
              subTitle: {
                indicadores: 'Detalles Indicadores Complementarios',
                resultados:'Resultados Indicadores Complementarios',
              },
              tabs: {
                filtro: 'FILTRO',
                registro: 'Registro',
                auditoria: 'AuditorÍa',
                preguntas: 'Preguntas'
              },
              select: {
                accion: {
                  Nada: 'Nada',
                  Mantenimiento: 'Mantenimiento',
                  Reparar: 'Reparar',
                  Reemplazar: 'Reemplazar'
                },
                estado: {
                  Activo: 'Activo',
                  enProceso: 'En proceso',
                  Cerrado: 'Cerrado',
                },
              },
              condiciones: {
                numerico: 'Numérico',
                seleccionMultiple: 'Selección Múltiple'
              }
            },
            registro_novedads: {
              title: {
                editTitle: 'Editar Configuración de Novedad',
                showTitle: 'Información Conf. de Novedad',
                listTitle: 'Lista Configuración de Novedades',
              },
              subTitle: {
                preguntas: 'Preguntas:',
                respuestas: 'Respuestas:',
                consideracionesPeso: 'Consideraciones Peso'
              },
              tabs: {
                identificacion: 'IDENTIFICACIÓN',
                informacion: 'informacion',
                auditoria: 'AuditorÍa',
                preguntas: 'Preguntas'
              },
              select: {
                estado: {
                  Activo: 'Activo',
                  Inactivo: 'Inactivo'
                },
                tipoCampo: {
                  seleccionMultiple: 'Selección Múltiple',
                  numerico: 'Numérico',
                }
              },
            },
            respuesta_evaluacion_novedads: {
              title: {  
                editTitle: 'Editar Reg. de Novedad-Medición',
                showTitle: 'Info. Reg. de Novedad-Medición',
                listTitle: 'Lista de Reg. Novedades-Mediciones',
              },
              subTitle: {
                localizacion: 'Localización',
                coordIniciales: 'Coordenadas Iniciales',
                coordFinales: 'Coordenadas Finales',
                deterioro: 'Deterioro',
                mediciones: 'Mediciones',
                indicadores: 'Detalles Registros Novedades',
                resultados:'Resultados Registros Novedades',
              },
              tabs: {
                filtro: 'FILTRO',
                registro: 'Registro',
                auditoria: 'AuditorÍa',
                preguntas: 'Preguntas'
              },
              select: {
                estado: {
                  activo: 'Activo',
                  proceso: 'En proceso',
                  cerrado: 'Cerrado',
                },
                tipoRegistro: {
                  novedad: 'Novedad',
                  medicion: 'Medición',
                  medicionId: 'Medicion',
                }
              },
              condiciones: {
                numerico: 'Numérico',
                seleccionMultiple: 'Selección Múltiple'
              },
              tableMediciones: {
                columns: {
                  mediciones: 'Mediciones',
                  tipoElemento: 'Tipo de elemento',
                  modelo: 'Modelo',
                  codigo: 'Código',
                  retroReflecColorPrin: 'Retro-reflectividad color principal (cd/lux/m²)',
                  disminucionPrincipal:'Disminución color principal (%)',
                  retroReflecColorSec: 'Retro-reflectividad color secundario (cd/lux/m²)',
                  disminucionSecundario:'Disminución color secundario (%)',
                  posicion: 'Posición',
                  legibilidad: 'Legibilidad',
                  observaciones: 'Observaciones',
                }
              },
              mensajes: {
                borrar: '¿Borrar?'
              }
            },
            aprobacions: {
              title: {
                listTitle: 'Lista Aprobaciones',
              },
              select: {
                tipoRegistro: {
                  novedad: 'Novedad',
                  medicion: 'Medición',
                  medicionId: 'Medicion',
                  Inventario: 'Inventario',
                  IndicadorComplementario: 'Indicador complementario'
                },
                aprobacion: {
                  aprobado: 'Aprobado',
                  rechazado: 'Rechazado'
                }
              },
              buttons: {
                aprobar: 'APROBAR',
                rechazar: 'RECHAZAR',
                mostrar: 'MOSTRAR'
              },
              modalTitle: "Registro duplicado",
              modalMessaje: "Se encontró un registro similar con el estado de pendiente, ¿Desea usted aprobar o rechazar este registro?",
            },
            config_sistema: {
              title: {
                listTitle: 'Configuración General',
              },
              title_cards: 'Sistema',
              titles_cards: {
                idioma: 'Idioma del sistema',
                moneda: 'Moneda',
                logos: 'Logos'
              },
              btnEditar: 'Editar',
              btnCancelar: 'Cancelar',
              btnAceptar: 'Aceptar',
              btnConfirmar: 'Confirmar',
              btnEspanol:'ESPAÑOL',
              btnIngles:'INGLÉS',
              btnPortugues:'PORTUGUÉS',
              cards: {
                pesos: 'Pesos (COP)',
                logo: 'Formato PNG - máx 800x600',
                dropzone: 'Arrastre y suelte la imagen o haga clic para seleccionar una imagen'
              },
              mensajesModals: {
                titulo: 'Cambiar idioma del sistema',
                idioma: '¿Está seguro de que desea cambiar el idioma del sistema?',
                titleLogo: 'Cambiar logo del proyecto',
                logo: '¿Está seguro de que desea cambiar el logo del proyecto?'
                
              }
            },
            idiomas_config: {
              title: 'Idiomas',
              editTitle:'Editar idioma',
              showTitle:'Información idioma',
              fields:{
                idioma:'Idioma',
                region:'Región',
                nombreIdioma:'Nombre de idioma adicionado',
              },
              cards: {
                eliminar: 'Eliminar',
                cargarInfoIdioma: 'Cargar información idioma',
                descManualUsuario: 'Descargar manual de usuario',
                descargar: 'Descargar',
                activo: 'Activo'
              },
              modulo_administracion: 'Archivo de traducción'
            },
            carga_masivas: {
              title: {
                listTitle: 'Lista carga masiva',
                createTitle: 'Crear carga masiva',
                editTitle: 'Visualización carga masiva'
              },
              button: {
                verTodos: 'Ver todos',
                cargarArcdeNuevo: 'Cargar archivos de nuevo',
                cancelar: 'Cancelar',
                guardar: 'Guardar',
                confirmar: 'Confirmar',
                alertas: 'Alertas',
                errores: 'Errores',
                descargar: 'Descargar',
                siConfirmar: 'Sí, continuar'
              },
              boolean: {
                imagenes: '¿Archivo de imagenes?'
              },
              message: {
                dialogFilterEmpty: 'Para continuar con la carga de los archivos, por favor registre los datos del sistema desde el icono de editar y el nombre del grupo',
                dialogErrores: 'Se encontraron errores, revíselos en el archivo original y vuelva a cargarlo.',
                dialogAlerts: 'Se encontraron alertas, puede confirmarlos y continuar la carga del archivo.',
                dialogAddAlert: '*No puede confirmar las alertas porque se encontraron errores, revíselos en el archivo original y vuelva a cargarlo.',
                dialogConfirmAlert: '¿Está seguro que desea continuar con la carga teniendo alertas?',
                dialogStopQ: '¿Está seguro que quiere detener la carga?',
                dialogStop: 'Si detiene la carga se perderá el progreso que lleva actualmente.',
                dialogMsgIdioma: 'El idioma del proyecto está configurado en '
              }
            }
    },
    uniqueEmail:{
      email: 'Correo Electrónico'
    }
  }
}
