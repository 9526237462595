import React from 'react';
import { WithPermissions,ListButton, TabbedShowLayout, EditButton, Tab, TextField, DateField, CardActions, NumberField 
} from 'react-admin';
import { Show } from './../Show';
import { PRField } from './../../fields/PRField';
import { withStyles } from '@material-ui/core/styles';
import {styles}  from './../../EstilosGrid';
import { translate } from 'react-admin';
import Divider from '@material-ui/core/Divider';
import { PField } from './../../fields/PField';




const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

export const TramoShow = translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;
    return(
    <Show title="dynatest.resources.tramos.title.showTitle" {...props}>
        <TabbedShowLayout>
            <Tab label="dynatest.resources.tramos.tabs.informacionBasica">
                <TextField label="resources.tramos.fields.codigo" source="fullCode" /> 
                <span></span>     
                <span></span>                               
                <PRField source="abInicio"/>
                <PRField source="abFin"/>
                <NumberField source="fullResta" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}}/>
                <PField source="pInicio"/>
                <PField source="pFin"/>
                <span></span> 
                <b>{translate('dynatest.resources.tramos.title.inforTramo')}</b> 
                <span></span>     
                <span></span>                
                <Divider /> 
                <Divider />
                <Divider />
                <TextField source="inicio" />
                <TextField source="final" />
                <span></span> 
            </Tab>
            <Tab label="dynatest.resources.tramos.tabs.auditoria">
            <DateField source="createdAt" showTime/> 
                <TextField source="createdBy" />
                <span></span>               
                <DateField source="updatedAt" showTime/>
                <TextField source="updatedBy" />
                <span></span>    
            </Tab>
        </TabbedShowLayout>
    </Show>
)
    }));