import React from 'react';
import { SimpleForm} from 'react-admin';
import { Edit } from './../../Edit';
import { withStyles, Grid } from '@material-ui/core';
import { styles } from './../../../EstilosGrid';
import {ComponenteDeterioroPers} from '../componentes/ComponeteReparacionPers';
import { translate } from 'react-admin';

export const ReparacionPersEdit = translate(withStyles(styles)(({ classes, ...props }) => {
    return (
        <Edit title="dynatest.resources.reparacion_localizadas.title.editTitle" {...props} undoable={false} >
            <SimpleForm redirect="list" >
                    <ComponenteDeterioroPers/>
            </SimpleForm>
        </Edit>
    )
}));