import React from 'react';
import {DateField,Filter,List, Datagrid, TextField, EditButton, ShowButton,ReferenceField,SelectField } from 'react-admin';
import {  BulkDeleteWithConfirmButton, Loading  } from 'react-admin';
import { Fragment } from 'react';


const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);
const FiltroSeguridad= (props) => (
    <Filter {...props}>
       
    </Filter>
);

export const CalificacionPontonList = ({ permissions, ...props }) => {
    if (!permissions) return <Loading />;  
    return (
        <List title="Lista Calificación" {...props}  sort={{ field: 'id', order: 'DESC' }} filters={<FiltroSeguridad/>} bulkActionButtons={permissions['calificacionPontones']  == 1 ? <PostBulkActionButtons />: null}>
            <Datagrid>
                <ReferenceField label="Ponton" source="ponton" reference="elemento_pontons" linkType={false}  fullWidth>                
                        <TextField source="fullCodigo" />                
                        </ReferenceField>  
                <TextField source="nombre_inspector"/>
                <DateField label="Fecha de Inspección" source="fecha2"/>
                {permissions['calificacionPontones'] == 1 || permissions['calificacionPontones'] == 2 || permissions['calificacionPontones'] == 4 ? <ShowButton/>: null}
                {permissions['calificacionPontones'] == 1 || permissions['calificacionPontones'] == 2 ? <EditButton/>: null}    
            </Datagrid>
        </List>
)};