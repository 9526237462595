import React , { useState }from 'react';
import { ReferenceField, BooleanField, TabbedShowLayout, Tab, TextField, DateField, NumberField, FormTab,TextInput
} from 'react-admin';
import { GMapInput } from '../../../inputs/GMapInput';
import Typography from '@material-ui/core/Typography';
import { Show } from './../../Show';
import { PRField } from './../../../fields/PRField';
import Divider from '@material-ui/core/Divider';
import TabbedForm from '../../TabbedForm';
import { withStyles } from '@material-ui/core';
import { styles } from './../../../EstilosGrid';
import { translate } from 'react-admin';


export const PeajesinventarioShow = translate(withStyles(styles)(({ classes, record, ...props }) =>{
  const { translate } = props;

  const [imagen , setImagen] =useState(true);
  const ValidarImagen =  (value) =>{
      // console.log(value + "Aqui est la imagen ")
      if(value !== null && value !== undefined && value !== 'undefined')
      {
          let lenghtObject = Object.keys(value);
          let valor = '';        
          for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                
          setImagen(value) 
          // console.log(value + "Aqui est la imagen ")
      }
   }
  return (
  <Show title="dynatest.resources.inventariopeajes.title.showTitle" {...props}>
    <TabbedForm toolbar={false}>
      <FormTab label="dynatest.resources.inventariopeajes.tabs.tabsTitle.identificacion">

        <b>{translate('dynatest.resources.inventariopeajes.subTitle.detalles')}</b>
        <span></span>
        <Divider fullWidth/>

        <TextField label="resources.inventariopeajes.fields.nombre" source="nombre" fullWidth formClassName={classes.grid_cont3s} />
        <NumberField label="resources.inventariopeajes.fields.codigo" source="codigo" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3}/>
        <BooleanField label="dynatest.resources.inventariopeajes.boolean.estado" source="estado" fullWidth formClassName={classes.grid_cont3}/>
        
        <br/>

        <NumberField label="resources.inventariopeajes.fields.estacion_manual" source="estacion_manual" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }}formClassName={classes.grid_cont3s}/>
        <NumberField label="resources.inventariopeajes.fields.estacion_automatica" source="estacion_automatica" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3}/>
        <NumberField label="resources.inventariopeajes.fields.estacion" source="estacion" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3}/>

        <br/>
        <TextInput source="tipoElemento_.src" validate={ValidarImagen} style={{display : 'none'}}/>
        <Typography variant="h3" style={{ width: '100%' }} component="h3">{translate('dynatest.resources.inventariopeajes.subTitle.localizacion')}</Typography>
          <span></span>
          <span></span>
          <Divider fullWidth formClassName={classes.grid_cont12} />
          <ReferenceField label="resources.inventariopeajes.fields.unidad_funcional" source="unidad_funcional" reference="unidad_funcionals" linkType={false} fullWidth formClassName={classes.grid_cont4}>
              <TextField source="fullCodigos" />
          </ReferenceField>
          <ReferenceField label="resources.inventariopeajes.fields.tramo" source="tramo" reference="tramos" linkType={false} fullWidth formClassName={classes.grid_cont4}>
              <TextField source="fullCodigos" />
          </ReferenceField>
          <ReferenceField label="resources.inventariopeajes.fields.segmento" source="segmento" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont3}>
              <TextField source="fullCodigos" />
          </ReferenceField>
          <Typography variant="b" component="b">{translate('dynatest.resources.inventariopeajes.subTitle.coordenadas')}</Typography>
        <span></span>
        <span></span>
        <Divider fullWidth style={{ width: '100%', marginTop: '10px' }} />
        <PRField label="resources.inventariopeajes.fields.Abscisa" id="ingresoAbFin" source="abInicio" fullWidth formClassName={classes.grid_cont4} />
        <NumberField label="resources.inventariopeajes.fields.Latitud" source="latitudInicial" fullWidth fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4} />
        <NumberField label="resources.inventariopeajes.fields.Longitud" source="longitudInicial" fullWidth fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
        <GMapInput style={{width : "100%"}}
                                defaultZoom={12}                                    
                                defaultCenter={{ lat: 4.7567039, lng: -74.0311056 }}
                                source="ubicacionMapa"
                                multipleMarkers="true"
                                googleKey="AIzaSyCivTXzJBYRBK8nosBYRFXnv-hGXIbhnYY"
                                icon= {"/imagenes/senalesColombia/Elementos/PEAJ.png"} 
                            />
      </FormTab>
      <FormTab label="dynatest.resources.inventariopeajes.tabs.tabsTitle.auditoria">
        <DateField source="createdAt" label="resources.inventariopeajes.fields.createdAt" formClassName={classes.grid_cont3} showTime/>
        <TextField source="createdBy" label="resources.inventariopeajes.fields.createdBy" formClassName={classes.grid_cont3}/>
        <br/>
        <DateField source="updatedAt" label="resources.inventariopeajes.fields.updatedAt"formClassName={classes.grid_cont3} showTime/>
        <TextField source="updatedBy" label="resources.inventariopeajes.fields.updatedBy" formClassName={classes.grid_cont3}/>
      </FormTab>
    </TabbedForm>
  </Show>
  )
}));