import React, { useState } from 'react';
import {
    List, NumberField, Datagrid, TextField, BooleanField, EditButton, ShowButton, AutocompleteInput, Filter, ReferenceInput, SelectInput,
    CloneButton, Button, Confirm
} from 'react-admin';
import { PField } from './../../fields/PField';
import { PRField } from './../../fields/PRField';
import { WithPermissions, BulkDeleteWithConfirmButton, Loading } from 'react-admin';
import { Fragment } from 'react';
import { CardActions, CreateButton, ExportButton } from 'react-admin';
import IconEvent from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import config from '../../config/config';
import { translate } from 'react-admin';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconCancel from '@material-ui/icons/Cancel';
import {Link} from 'react-router-dom'
let urlAppjs = config.UrlServer;

export const SegmentoList = translate(({ permissions, ...props }) => {
    const { translate } = props;
    const [open, setOpen] = React.useState(false);
    const handleClose = value => {
        setOpen(false);
    };

    const SegmentoFilter = (props) => {
        const [uf, setUf] = useState('none');
        const ufSet = (value) => {
            setUf(value);
        }
        const [tramo, setTramo] = useState('none');
        const tramoSet = (value) => {
            setTramo(value);
        }
    
        return (
            <Filter {...props}>
                <ReferenceInput
                    source="unidadFuncional"
                    reference="unidad_funcionals"
                    sort={{ field: 'codigo', order: 'ASC' }}
                    validate={[ufSet]}
                    alwaysOn
                >
                    <SelectInput options={{ fullWidth: true }} optionText="codigo" />
                </ReferenceInput>
    
                <ReferenceInput
                    source="tramo"
                    alwaysOn
                    reference="tramos"
                    filter={{ unidadFuncional: uf }}
                    validate={[tramoSet]}
                    filterToQuery={searchText => ({ codigo: searchText })}
                    sort={{
                        field: 'codigo',
                        order: 'ASC',
    
                    }}>
                    <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
                </ReferenceInput>
    
                <ReferenceInput
                    source="tipoCalzada"
                    reference="tipo_calzadas"
                    alwaysOn
                    sort={{ field: 'customOrder', order: 'ASC' }}
                >
                    <SelectInput optionText="nombre" />
                </ReferenceInput>
    
                <ReferenceInput
                    source="tipoSegmento"
                    reference="tipo_segmentos"
                    sort={{ field: 'customOrder', order: 'ASC' }}
                    filter={{ sistema: 'CLZ' }} alwaysOn
                >
                    <SelectInput optionText="nombre" />
                </ReferenceInput>
                <ReferenceInput
                    source="tipoSuperficie"
                    reference="tipo_elementos"
                    sort={{ field: 'customOrder', order: 'ASC' }}
                    filter={{ sistema: 'CLZ' }}
                    alwaysOn
                >
                    <SelectInput optionText="nombre" />
                </ReferenceInput>
            </Filter>
        )
    };
    const EliminarRegistro = (props) => {
        let datos = Object.values(props);
        console.log(datos)
        datos[3].forEach(element => {
            let ids      = element.replace(/\D/g,"");
            let elemen   = element.replace("/segmentos/", "");
            let elemento = elemen.replace(/\d/g,""); 
            
            const dataSend = new FormData();
            dataSend.append('id', ids);
            dataSend.append('elemento', 'segmento');
            let requestOptionsReplace = {
                method: 'POST',
                headers: { 'authorization': 'Bearer ' + localStorage.getItem('token') },
                body: dataSend
            };
            fetch(urlAppjs + "/eliminarRegistro/", requestOptionsReplace)
            .then(response => response.json())
            .then((dataRequestInventario) => {
                if(dataRequestInventario == "Success"){
                    console.log(dataRequestInventario, "OK")
                    window.location.reload();
                }
            }).catch((error) => {
                setOpen(true);
                console.log(error)
            });
        });
    } 
    
    const PostBulkActionButtons = (props) => {
        const [open, setOpen] = useState(false);
        const handleClick = () => setOpen(true);
        const handleDialogClose = () => setOpen(false);
        const handleConfirm = () => {
            EliminarRegistro(props);
            setOpen(false);
        };

        return (
            <>
                <Button label="resources.validaciones.borrar" onClick={handleClick} style={{
                    fontSize: '0.8125rem', color: '#f44336', border: 0,
                    display: 'inline-flex', alignItems: 'center',
                    width: '7rem', height: '2rem'
                }}>
                    <DeleteIcon  style={{ width: '1.3rem', paddingRight: '0.3rem' }} />
                </Button>
                
                <Confirm
                    isOpen={open}
                    title="resources.validaciones.valBorrarSegmentos"
                    content="resources.validaciones.valBorrar"
                    onConfirm={handleConfirm}
                    onClose={handleDialogClose}
                />
            </>
        );
                    
    };
    function SimpleDialog(props) {
        const { open } = props;
        const handleClose = () => {
            setOpen(false);
        };
    
        return (
            <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle id="simple-dialog-title">{translate('resources.validaciones.valEliminacion')}</DialogTitle>
                <DialogContent>
                    {translate('resources.validaciones.valRegistroAsociado')}
                </DialogContent>
                <DialogActions>
                    <Button label="Cerrar" onClick={handleClose}>
                        <IconCancel />
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
    
    const ListAction = ({ basePath, data, resource }) => (
        <CardActions>
            <CreateButton basePath={basePath} />
            <ExportButton basePath={basePath}/>
            <Link to="/transformacion_segmentos">
                <Button label="dynatest.resources.segmentos.title.tranSegmento">
                    <IconEvent/>
                </Button>
            </Link>
        </CardActions>
    );
    const ListAction2 = ({ basePath, data, resource }) => (
        <CardActions>
            <CreateButton basePath={basePath} />
        </CardActions>
    );

    if (!permissions) return <Loading />;
    return (
        <Fragment>
            <SimpleDialog open={open} onClose={handleClose} />
            <List {...props}
                actions={permissions['segmento']  == 1 ? <ListAction /> : permissions['segmento']  == 2 ? <ListAction2 />: null}
                title="dynatest.resources.segmentos.title.listTitle"
                perPage={5}
                sort={{ field: "updatedAt", order: 'DESC' }}
                filters={<SegmentoFilter />}
                bulkActionButtons={permissions['segmento'] == 1 ? <PostBulkActionButtons /> : null}>
                <Datagrid>
                    <TextField  source="codigo" />
                    <PField  source="pInicio" />
                    <PField  source="pFin" />
                    <PRField  source="abInicio" />
                    <PRField  source="abFin" />
                    <NumberField source="fullResta" sortable={false} />
                    <TextField label="resources.segmentos.fields.tipoCalzada" source="calzadaNombre" />
                    <TextField label="resources.segmentos.fields.tipoSegmento" source="segmentoNombre" />
                    <TextField label="resources.segmentos.fields.tipoSuperficie" source="superficieNombre" />
                    <BooleanField source="estado" sortable={false} />
                    {/* <CloneButton /> */}
                    {permissions['segmento'] == 1 || permissions['segmento'] == 2 || permissions['segmento'] == 4 ? <ShowButton/>: null}
                    {permissions['segmento'] == 1 || permissions['segmento'] == 2 ? <EditButton/>: null}
                </Datagrid>
            </List>
        </Fragment>
    )
});