import React, { Fragment, useState } from 'react';

import {
    List, Datagrid, TextField, EditButton, ShowButton, ReferenceField, Filter,
    ReferenceInput, SelectInput, NumberField, BulkDeleteWithConfirmButton, Loading, AutocompleteInput
} from 'react-admin';
import { CardActions, CreateButton, ExportButton } from 'react-admin';
import { PRField } from './../../../fields/PRField';
import { PField } from './../../../fields/PField';
import { translate } from 'react-admin';


const FiltroDreanje = (props) => {
    const [uf, setUf] = useState('none');
    const ufSet = (value) => {
        setUf(value);
    }
    const [tramo, setTramo] = useState('none');
    const tramoSet = (value) => {
        setTramo(value);
    }

    return (
        <Filter {...props}>
            <ReferenceInput
                id="unidadFuncional"
                label="resources.elemento_drenajes.fields.unidadFuncional"
                source="unidadFuncional"
                reference="unidad_funcionals"
                filterToQuery={searchText => ({ codigo: searchText })}
                sort={{ field: 'codigo', order: 'asc' }}
                validate={[ufSet]}
                alwaysOn
                fullWidth
            >
                <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
            </ReferenceInput>
            <ReferenceInput
                label="resources.elemento_drenajes.fields.tramo"
                source="tramo"
                alwaysOn
                reference="tramos"
                filter={{ unidadFuncional: uf }}
                validate={[tramoSet]}
                filterToQuery={searchText => ({ codigo: searchText })}
                sort={{
                    field: 'codigo',
                    order: 'ASC',

                }}>
                <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
            </ReferenceInput>

            <ReferenceInput
                label="resources.elemento_drenajes.fields.segmento"
                source="segmento"
                reference="segmentos"
                alwaysOn
                sort={{ field: 'codigo', order: 'ASC' }}
                filter={{ tramo: tramo }}
                filterToQuery={searchText => ({ codigo: searchText })}
            >
                <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
            </ReferenceInput>
            <ReferenceInput
                label="resources.elemento_drenajes.fields.tipoElemento"
                source="tipoElemento"
                reference="tipo_elementos"
                filter={{ sistema: "DRE" }}
                sort={{ field: 'name', order: 'ASC' }}
                filterToQuery={searchText => ({ nombre: searchText })}
                alwaysOn
                resettable
            >
                <AutocompleteInput options={{ fullWidth: true }} optionText="nombre" />
            </ReferenceInput>
            <SelectInput
                fullWidth
                label="resources.elemento_drenajes.fields.estados"
                source="estados"
                sort={{ field: 'name', order: 'ASC' }}
                alwaysOn
                choices={[
                    { id: translate('dynatest.resources.elemento_drenajes.select.estados.Activo'), name: 'dynatest.resources.elemento_drenajes.select.estados.Activo' },
                            { id: translate('dynatest.resources.elemento_drenajes.select.estados.Inactivo'), name: 'dynatest.resources.elemento_drenajes.select.estados.Inactivo' }, 
                ]}
                resettable
            />
        </Filter>
    )
};
const PostBulkActionButtons = (props) => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props} label="¿Borrar?" />
    </Fragment>
);
const ListAction = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
        <ExportButton basePath={basePath}/>
    </CardActions>
);
const ListAction2 = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
    </CardActions>
);

 
export const ElementoDrenajeList = ({ permissions, ...props }) => {
    if (!permissions) return <Loading />;  
    return (
        <List {...props}
            sort={{ field: 'updatedAt', order: 'DESC' }}
            title="dynatest.resources.elemento_drenajes.title.listTitle"
            actions={permissions['drenaje']  == 1 ? <ListAction /> : permissions['drenaje']  == 2 ? <ListAction2 />: null}
            filters={<FiltroDreanje />}
            bulkActionButtons={permissions['drenaje']  == 1 ? <PostBulkActionButtons />: null}
        > 
        <Datagrid>
            <TextField label="resources.elemento_drenajes.fields.smartCode" source="smartCode" sortable />
            <TextField label="resources.elemento_drenajes.fields.tipoElemento" source="tipoElemento_.nombre" sortable/>
            <PRField label="resources.elemento_drenajes.fields.abInicio" source="abInicio" sortable />
            <PRField label="resources.elemento_drenajes.fields.abFin" source="abFin" sortable />
            <PField label="resources.elemento_drenajes.fields.pInicio" source="pInicio" sortable />
            <PField label="resources.elemento_drenajes.fields.pFin" source="pFin" sortable />
            <NumberField label="resources.elemento_drenajes.fields.fullResta" source="fullResta" sortable={false} />
            <TextField label="resources.elemento_drenajes.fields.estados" source="estados" sortable />
            {permissions['drenaje'] == 1 || permissions['drenaje'] == 2 || permissions['drenaje'] == 4 ? <ShowButton/>: null}
            {permissions['drenaje'] == 1 || permissions['drenaje'] == 2 ? <EditButton/>: null}
        </Datagrid>
    </List>
)};