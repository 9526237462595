import React from 'react';
import { List, Datagrid,  EditButton, ShowButton, } from 'react-admin';


// filters={<PimsElementoListFilter sistema="ALR"/>} filter={{tipoElemento: {sistema: "ALR"}, proyecto: {id:1}}}
export const ElementoCalzadaList = props => (
    <List {...props} title="Calzadas" >
        <Datagrid>                                    
                        
            <ShowButton />
            <EditButton />
        </Datagrid>
    </List>
);