import React, { useState, useEffect } from 'react';
import {
  TabbedShowLayout, Tab, TextField, DateField, NumberField, BooleanField, ReferenceField, FormTab, TabbedForm, SelectField,
  ShowController
} from 'react-admin';
import { Show } from './../Show';
import { withStyles } from '@material-ui/core';
import { PRField } from './../../fields/PRField';
import { PField } from './../../fields/PField';
import { DField } from './../../fields/DField';
import Divider from '@material-ui/core/Divider';
import TablaTuneles from './TablaTuneles';
import { GMapField } from '../../fields/GMapField';
import Typography from '@material-ui/core/Typography';
import { styles } from './../../EstilosGrid';
import { ButtonCarousel } from './../images/ButtonCarousel';
import { translate } from 'react-admin';

export const TunelShow = translate(withStyles(styles)(({ classes, record, ...props }) => {
  const { translate } = props;

  const [IdTuneles, setIdTuneles] = useState(0);

  useEffect(() => {
    var url = document.location.hash;
    var auxId = url.split("tuneles%2F");
    var id = auxId[1].split("/");
    setIdTuneles(id[0])
  });

  return (
    <ShowController {...props} title="Información Elemento Red">
      {controllerProps =>
        <Show  {...props} title="dynatest.resources.tuneles.title.showTitle" actions={<ButtonCarousel/>}>
          <TabbedForm toolbar={false} >
            <FormTab label="dynatest.resources.tuneles.tabs.tabsTitle.identificacion">
              <TextField label="resources.tuneles.fields.smartCode" source="smartCode" formClassName={classes.grid_cont4_f}/>
              <br/>
              <SelectField label="resources.tuneles.fields.elementos" source="elementos"
                choices={[{ id: 'TNEL', name: 'resources.tuneles.fields.TNEL' }]} formClassName={classes.grid_cont4_f} />
              <TextField label="resources.tuneles.fields.sentido" source="sentido" formClassName={classes.grid_cont4_s} />
              <br />
              <TextField label="resources.tuneles.fields.clasificacion" source="clasificacion" formClassName={classes.grid_cont4_f} />
              <TextField label="resources.tuneles.fields.cobertura" source="cobertura" formClassName={classes.grid_cont4_s} />
              <NumberField label="resources.tuneles.fields.carrilesShow" source="carriles" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s} />

              <br />
              <strong >{translate('dynatest.resources.tuneles.subTitle.localizacion')}</strong>
              <Divider fullWidth />

              <ReferenceField label="resources.tuneles.fields.unidadFuncional" source="unidadFuncional" reference="unidad_funcionals" linkType={false} fullWidth formClassName={classes.grid_cont4_f}>
                <TextField source="fullCodigos" />
              </ReferenceField>
              <ReferenceField label="resources.tuneles.fields.tramo" source="tramo" reference="tramos" linkType={false} fullWidth formClassName={classes.grid_cont4_s}>
                <TextField source="fullCodigos" />
              </ReferenceField>
              <ReferenceField label="resources.tuneles.fields.segmento" source="segmento" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont4_s}>
                <TextField source="fullCodigos" />
              </ReferenceField>
              <br />
              <PRField label="resources.tuneles.fields.abInicio" source="abInicio" fullWidth formClassName={classes.grid_cont4_f} />
              <PRField label="resources.tuneles.fields.abFin" source="abFin" fullWidth formClassName={classes.grid_cont4_s} />
              <NumberField label="resources.tuneles.fields.fullResta" source="fullResta" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s} />
              <br />
              <PField label="resources.tuneles.fields.pInicio" source="pInicio" fullWidth formClassName={classes.grid_cont4_f} />
              <PField label="resources.tuneles.fields.pFin" source="pFin" fullWidth formClassName={classes.grid_cont4_s} />
              <br/>
              <Typography variant="b" component="b">{translate('dynatest.resources.tuneles.subTitle.coordenadasIniciales')}</Typography>
              <Divider fullWidth />

              <NumberField label="resources.tuneles.fields.latitud" source="latitudInicial" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_f} />
              <NumberField label="resources.tuneles.fields.longitud" source="longitudInicial" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s} />

              <br />
              <Typography variant="b" component="b">{translate('dynatest.resources.tuneles.subTitle.coordenadasFinales')}</Typography>
              <Divider fullWidth style={{ width: '300%', marginTop: '10px' }} />


              <NumberField label="resources.tuneles.fields.latitud" source="latitudFinal" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_f} />
              <NumberField label="resources.tuneles.fields.longitud" source="longitudFinal" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s} />

              <br />
              <GMapField
                fullWidth
                defaultZoom={12}
                defaultCenter={{ lat: 4.7567039, lng: -74.0311056 }}
                source="ubicacionMapa"
                multipleMarkers="true"
                googleKey="AIzaSyCivTXzJBYRBK8nosBYRFXnv-hGXIbhnYY"
                icon={'imagenes/senalesColombia/Elementos/tunel/TNEL.png'}
              />
            </FormTab>

            <FormTab label="dynatest.resources.tuneles.tabs.tabsTitle.datosAdministrativos">
              <strong>{translate('dynatest.resources.tuneles.subTitle.detallesConstructivos')}</strong>
              <Divider fullWidth />
              <DateField label="resources.tuneles.fields.ano" source="ano" formClassName={classes.grid_cont3_f} />
              <NumberField label="resources.tuneles.fields.area" source="area" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3_s} />
              <TextField label="resources.tuneles.fields.diseno" source="diseno" formClassName={classes.grid_cont3_s} />
              <TextField label="resources.tuneles.fields.disenador" source="disenador" formClassName={classes.grid_cont3_s} />
              <br />
              <TextField label="resources.tuneles.fields.informe" source="informe" formClassName={classes.grid_cont12} />

              <strong>{translate('dynatest.resources.tuneles.subTitle.detRefuerzoRehabilit')}</strong>
              <Divider fullWidth />
              <DateField label="resources.tuneles.fields.anoa" source="anoa" formClassName={classes.grid_cont3_f} />
              <NumberField label="resources.tuneles.fields.areaa" source="areaa" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3_s} />
              <TextField label="resources.tuneles.fields.disenoa" source="disenoa" formClassName={classes.grid_cont3_s} />
              <TextField label="resources.tuneles.fields.disenadora" source="disenadora" formClassName={classes.grid_cont3_s} />
              <br />
              <TextField label="resources.tuneles.fields.informea" source="informea" formClassName={classes.grid_cont12} />
              <br />
              <TextField label="resources.tuneles.fields.observacionesa" source="observacionesa" formClassName={classes.grid_cont12} />

              <strong>{translate('dynatest.resources.tuneles.subTitle.estudioHidrogeologico')}</strong>
              <Divider fullWidth />
              <DateField label="resources.tuneles.fields.anob" source="anob" formClassName={classes.grid_cont4_f} />
              <TextField label="resources.tuneles.fields.consultor" source="consultor" formClassName={classes.grid_cont4_s} />
              <TextField label="resources.tuneles.fields.estudio" source="estudio" formClassName={classes.grid_cont4_s} />
              <br />
              <TextField label="resources.tuneles.fields.nom_estudio" source="nom_estudio" formClassName={classes.grid_cont12} />

              <strong >{translate('dynatest.resources.tuneles.subTitle.estProyeccionTrafico')}</strong>
              <Divider fullWidth />
              <DateField label="resources.tuneles.fields.anoc" source="anoc" formClassName={classes.grid_cont4_f} />
              <TextField label="resources.tuneles.fields.consultora" source="consultora" formClassName={classes.grid_cont4_s} />
              <TextField label="resources.tuneles.fields.estudioa" source="estudioa" formClassName={classes.grid_cont4_s} />
              <br />
              <TextField label="resources.tuneles.fields.nom_estudioa" source="nom_estudioa" formClassName={classes.grid_cont12} />

              <strong >{translate('dynatest.resources.tuneles.subTitle.miembrosInteresados')}</strong>
              <Divider fullWidth />
              <TextField label="resources.tuneles.fields.propietario" source="propietario" formClassName={classes.grid_cont4_f} />
              <TextField label="resources.tuneles.fields.vial" source="vial" formClassName={classes.grid_cont4_s} />
              <TextField label="resources.tuneles.fields.proyectista" source="proyectista" formClassName={classes.grid_cont4_s} />
              <strong >{translate('dynatest.resources.tuneles.subTitle.caractOperacionales')}</strong>
              <Divider fullWidth />
              <NumberField label="resources.tuneles.fields.opacidad" source="opacidad" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_f} />
              <NumberField label="resources.tuneles.fields.concentracion" source="concentracion" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s} />
              <br />
              <TextField label="resources.tuneles.fields.existe" source="existe" formClassName={classes.grid_cont4_f} />
              <NumberField label="resources.tuneles.fields.long_km" source="long_km" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s} />
              <TextField label="resources.tuneles.fields.estadoa" source="estadoa" formClassName={classes.grid_cont4_s} />
              <TextField label="resources.tuneles.fields.observacioness" source="observacioness" formClassName={classes.grid_cont12} />
            </FormTab>
            <FormTab label="dynatest.resources.tuneles.tabs.tabsTitle.geometria">
              <strong>{translate('dynatest.resources.tuneles.subTitle.disenoPlanta')}</strong>
              <Divider fullWidth />
              <NumberField label="resources.tuneles.fields.radio_min" source="radio_min" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_f} />
              <NumberField label="resources.tuneles.fields.radio_max" source="radio_max" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s} />
              <NumberField label="resources.tuneles.fields.ancho_carril" source="ancho_carril" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s} />

              <br />
              <strong>{translate('dynatest.resources.tuneles.subTitle.disenoPerfil')}</strong>
              <Divider fullWidth />
              <NumberField label="resources.tuneles.fields.pend_longi" source="pend_longi" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_f} />
              <NumberField label="resources.tuneles.fields.radio_min_curva" source="radio_min_curva" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s} />
              <NumberField label="resources.tuneles.fields.radio_max_curva" source="radio_max_curva" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s} />
              <br />
              <NumberField label="resources.tuneles.fields.long_tunel" source="long_tunel" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_f} />
              <NumberField label="resources.tuneles.fields.galibo" source="galibo"  fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s} />
              <NumberField label="resources.tuneles.fields.ancho_anden" source="ancho_anden" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s} />
              <br />
              <NumberField label="resources.tuneles.fields.ancho_berma" source="ancho_berma" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_f} />

              <br />
              <strong>{translate('dynatest.resources.tuneles.subTitle.SeccTransverServicio')}</strong>
              <Divider fullWidth />

              <TablaTuneles tabla="tuneles" id={IdTuneles} />

              <strong>{translate('dynatest.resources.tuneles.subTitle.InfoComplementaria')}</strong>
              <Divider fullWidth />
              <TextField label="resources.tuneles.fields.soporte" source="soporte" formClassName={classes.grid_cont3_s} />
              <TextField label="resources.tuneles.fields.codigo_soporte" source="codigo_soporte" formClassName={classes.grid_cont3_s} />
              <TextField label="resources.tuneles.fields.codigo_soporteName" source="descSoporte" formClassName={classes.grid_cont3_s} />
              <TextField label="resources.tuneles.fields.tipologia" source="tipologia" formClassName={classes.grid_cont3_s} />
            </FormTab>
            <FormTab label="dynatest.resources.tuneles.tabs.tabsTitle.detalles">
              <TextField fullWidth label="resources.tuneles.fields.estado" source="estado" formClassName={classes.grid_cont4_f} />
              {controllerProps.record && controllerProps.record.estado === translate('dynatest.resources.tuneles.select.estado.Reemplazo') &&
                <TextField fullWidth label="resources.tuneles.fields.mot_remplazo" source="mot_remplazo" formClassName={classes.grid_cont4_s} />
              }
              {controllerProps.record && controllerProps.record.estado === translate('dynatest.resources.tuneles.select.estado.Reemplazo') &&
                <ReferenceField
                  label="resources.tuneles.fields.elemnto_remplazo"
                  source="elemnto_remplazo"
                  reference="tuneles"
                  linkType={false}
                  fullWidth
                  formClassName={classes.grid_cont4_s}
                >
                  <TextField source="fullCodigoConsecutivo" />
                </ReferenceField>
              }
              <br/><br/>
                <TextField label="resources.tuneles.fields.sentidoB" source="sentidoB" fullWidth formClassName={classes.grid_cont4_f} />
                <TextField label="resources.tuneles.fields.estados" source="estados" formClassName={classes.grid_cont4} />
              <br />
              <DateField label="resources.tuneles.fields.fecha" source="fecha" formClassName={classes.grid_cont4_f} />
              <ReferenceField
                label="resources.tuneles.fields.proveedor"
                source="proveedor"
                reference="proveedors"
                linkType={false}
                fullWidth
                formClassName={classes.grid_cont4_s}
              >
                <TextField source="razonSocial" />
              </ReferenceField>
              <NumberField label="resources.tuneles.fields.garantia" source="garantia" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4_s} />
              <br />
              <BooleanField label="dynatest.resources.tuneles.boolean.incluir" source="incluir" formClassName={classes.grid_cont4_f} />
              <br />
              <TextField label="resources.tuneles.fields.observaciones" source="observaciones" fullWidth formClassName={classes.grid_cont12} />


            </FormTab>
            <FormTab label="dynatest.resources.tuneles.tabs.tabsTitle.auditoria">
              <DateField source="createdAt" label="resources.tuneles.fields.createdAt" formClassName={classes.grid_cont4_f} showTime/>
              <TextField source="createdBy" label="resources.tuneles.fields.createdBy" formClassName={classes.grid_cont4_s}/>
              <TextField source="createdIn" label="resources.tuneles.fields.createdEn" fullWidth formClassName={classes.grid_cont3} />
              <br/>
              <DateField source="updatedAt" label="resources.tuneles.fields.updatedAt" formClassName={classes.grid_cont4_f} showTime/>
              <TextField source="updatedBy" label="resources.tuneles.fields.updatedBy" formClassName={classes.grid_cont4_s}/>
              <TextField source="updatedIn" label="resources.tuneles.fields.updatedEn"  fullWidth formClassName={classes.grid_cont3} />
            </FormTab>
          </TabbedForm>
        </Show>
      }
    </ShowController>

  )
}
));