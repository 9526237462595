import React, { useState } from 'react';
import { List, SimpleForm, Datagrid, TextField, DateField, Toolbar, EditButton, ShowButton, ReferenceField, Filter, ReferenceInput, CardActions, BulkDeleteWithConfirmButton, AutocompleteInput, CreateButton, SelectInput, Loading } from 'react-admin';
import { Fragment } from 'react';
import Button from '@material-ui/core/Button';
// import AddIcon from '@material-ui/icons/Add';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import { translate } from 'react-admin';
import { ExportButton } from 'react-admin';
const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props} />
    </Fragment>
);
const ListAction = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
        <ExportButton basePath={basePath}/>
    </CardActions>
);
const ListAction2 = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
    </CardActions>
);
const ImportacionPersFilter = translate((props) => {
    const { translate } = props;
    const [uf, setUf] = useState('none');
    const ufSet = (value) => {
        setUf(value);
    }

    return (
        <Filter {...props}>
            <ReferenceInput
                id="unidadFuncional"
                label="resources.pers_imports.fields.unidadFuncional"
                source="unidadFuncional"
                reference="unidad_funcionals"
                filterToQuery={searchText => ({ codigo: searchText })}
                sort={{ field: 'codigo', order: 'asc' }}
                validate={[ufSet]}
                alwaysOn
                fullWidth
            >
                <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
            </ReferenceInput>
        </Filter>
    )
});

export const ImportacionPersList = ({ permissions, ...props }) => {
    if (!permissions) return <Loading />;
    return (
        <List {...props} 
            perPage={25} 
            sort={{ field: 'updatedAt', order: 'DESC' }} 
            filters={<ImportacionPersFilter />} 
            actions={permissions['politicaParticular']  == 1 ? <ListAction /> : permissions['politicaParticular']  == 2 ? <ListAction2 />: null}
            bulkActionButtons={permissions['politicaParticular'] == 1 ? <PostBulkActionButtons /> : null}>
            <Datagrid>
                <DateField label="resources.pers_imports.fields.fechaImp" source="fecha" />
                <ReferenceField label="resources.pers_imports.fields.unidadFuncional" source="unidadFuncional" reference="unidad_funcionals" linkType={false}>
                    <TextField source="fullCodigos" />
                </ReferenceField>
                <TextField label="resources.pers_imports.fields.document" source="document" />
                <ShowButton />
            </Datagrid>
        </List>
    )
};