export default {
    ra: {
        action: {
            add_filter: 'Add filter en',
            add: 'Add en',
            back: 'Go Back en',
            bulk_actions: '1 item selected |||| %{smart_count} items selected en',
            cancel: 'Cancel en',
            clear_input_value: 'Clear value en',
            clone: 'Clone en',
            confirm: 'Confirm en',
            create: 'Create en',
            create_item: 'Create %{item} en',
            delete: 'Delete en',
            edit: 'Edit en',
            export: 'Export en',
            list: 'List en',
            refresh: 'Refresh en',
            remove_filter: 'Remove this filter en',
            remove: 'Remove en',
            save: 'Save en',
            search: 'Search en',
            show: 'Show en',
            sort: 'Sort en',
            undo: 'Undo en',
            unselect: 'Unselect en',
            expand: 'Expand en',
            close: 'Close en',
            open_menu: 'Open menu en',
            close_menu: 'Close menu en',
            update: 'Update en',
            save_add: 'Save and add en',
            save_end: 'Save and End en',
            image: 'Image en',
            carouselImage: 'GALERÍA en',
            imageActuales: 'Imágenes actuales en',
            imageEliminar: 'ELIMINAR en',
        },
        boolean: {
            true: 'Yes en',
            false: 'No en',
            null: '  en',
        },
        page: {
            create: 'Create %{name} en',
            dashboard: 'Dashboard en',
            edit: '%{name} #%{id} en',
            error: 'Something went wrong en',
            list: '%{name} en',
            loading: 'Loading en',
            not_found: 'Not Found en',
            show: '%{name} #%{id} en',
            empty: 'No %{name} yet. en',
            invite: 'Do you want to add one? en',
        },
        input: {
            file: {
                upload_several:
                    'Drop some files to uploa end, or click to select one. en',
                upload_single: 'Drop a file to uploa end, or click to select it. en',
            },
            image: {
                upload_several:
                    'Drop some pictures to uploa end, or click to select one. en',
                upload_single:
                    'Drop a picture to uploa end, or click to select it. en',
            },
            references: {
                all_missing: 'Unable to find references data. en',
                many_missing:
                    'At least one of the associated references no longer appears to be available. en',
                single_missing:
                    'Associated reference no longer appears to be available. en',
            },
            password: {
                toggle_visible: 'Hide password en',
                toggle_hidden: 'Show password en',
            },
        },
        message: {
            about: 'About en',
            are_you_sure: 'Are you sure? en',
            bulk_delete_content:
                'Are you sure you want to delete this %{name}? |||| Are you sure you want to delete these %{smart_count} items? en',
            bulk_delete_title:
                'Delete %{name} |||| Delete %{smart_count} %{name} en',
            bulk_update_content:
                'Are you sure you want to update this %{name}? |||| Are you sure you want to update these %{smart_count} items? en',
            bulk_update_title:
                'Update %{name} |||| Update %{smart_count} %{name} en',
            delete_content: 'Are you sure you want to delete this item? en',
            delete_title: 'Delete %{name} #%{id} en',
            details: 'Details en',
            error:
                "A client error occurred and your request couldn't be completed. en",
            invalid_form: 'The form is not valid. Please check for errors en',
            loading: 'The page is loadin eng, just a moment please en',
            no: 'No en',
            not_found:
                'Either you typed a wrong UR enL, or you followed a bad link. en',
            yes: 'Yes en',
            unsaved_changes:
                "Some of your changes weren't saved. Are you sure you want to ignore them? en",
        },
        navigation: {
            no_results: 'No results found en',
            no_more_results:
                'The page number %{page} is out of boundaries. Try the previous page. en',
            page_out_of_boundaries: 'Page number %{page} out of boundaries en',
            page_out_from_end: 'Cannot go after last page en',
            page_out_from_begin: 'Cannot go before page 1 en',
            page_range_info: '%{offsetBegin}-%{offsetEnd} of %{total} en',
            page_rows_per_page: 'Rows per page: en',
            next: 'Next en',
            prev: 'Prev en',
            skip_nav: 'Skip to content en',
        },
        sort: {
            sort_by: 'Sort by %{field} %{order} en',
            ASC: 'ascending en',
            DESC: 'descending en',
        },
        auth: {
            auth_check_error: 'Please login to continue en',
            user_menu: 'Profile en',
            username: 'Username en',
            password: 'Password en',
            sign_in: 'Sign in en',
            sign_in_error: 'Authentication faile end, please retry en',
            logout: 'Logout en',
        },
        notification: {
            updated: 'Element updated |||| %{smart_count} elements updated en',
            created: 'Element created en',
            deleted: 'Element deleted |||| %{smart_count} elements deleted en',
            bad_item: 'Incorrect element en',
            item_doesnt_exist: 'Element does not exist en',
            http_error: 'Server communication error en',
            data_provider_error:
                'dataProvider error. Check the console for details. en',
            i18n_error:
                'Cannot load the translations for the specified language en',
            canceled: 'Action cancelled en',
            logged_out: 'Your session has ende end, please reconnect. en',
        },
        validation: {
            required: 'Required en',
            minLength: 'Must be %{min} characters at least en',
            maxLength: 'Must be %{max} characters or less en',
            minValue: 'Must be at least %{min} en',
            maxValue: 'Must be %{max} or less en',
            number: 'Must be a number en',
            email: 'Must be a valid email en',
            oneOf: 'Must be one of: %{options} en',
            regex: 'Must match a specific format (regexp): %{pattern} en',
        }
    },
    resources: {
        inicio:{
          titleProyecto:'Selección de proyectos es',
          mensaje:'La página esta cargando, espere un momento por favor es.',
          seleccionar:'Seleccionar es.',
        },
        usuarios: {
            name: 'User |||| Users en',
            fields: {
                nombres: 'Name en',
                apellidos: 'Last name en',
                tipo_documento: 'Document type en',
                identificacion: 'Document number en',
                admin: 'Role en',
                password: 'Password en',
                plainPassword: 'Confirm  password en',
                idioma:'Idioma en',
                createdAt: 'Created at en',
                createdBy: 'Created by en',
                updatedAt: 'updated At en',
                updatedBy: 'Updated By en',
                sections: {
                    tipo_rol: 'Role type en',
                    usuarios: 'Users en',
                    perfiles: 'Profiles en',
                    asignar_clave: 'Assign Key en',
                    proyectos: 'Projects en',
                    rutas: 'Routes en',
                    unidades_funcionales: 'Functional units en',
                    tramos: 'Sections en',
                    segmentos: 'Segments en',
                    materiales: 'Materials en',
                    granulometria: 'Granulometry en',
                    parametros_evaluacion: 'Evaluation parameters en',
                    diagrama_colorimetrico: 'Colorimetric diagram en',
                    reparaciones: 'Repairs en',
                    deterioros: 'Deteriorations en',
                    contacto_emergencia: 'Emergency contact en',
                    proveedores: 'Suppliers en',
                    servicios_publicos: 'Public Services en',
                    mapa: 'Map en',
                    flexible: 'Flexible en',
                    rigido: 'Rigid en',
                    adoquin: 'Cobblestone en',
                    otro: 'Other en',
                    sistemas_contencion: 'Containment System en',
                    drenaje: 'Drainage en',
                    señalizacion: 'Signage en',
                    alrededores: 'Surroundings en',
                    otros_elementos: 'Other elements en',
                    redes: 'Nets en',
                    puentes: 'Bridges en',
                    contencion: 'Containment en',
                    ponton: 'Pontoon en',
                    tuneles: 'Tunnels en',
                    inventario_peajes: 'Inventory Tolls en',
                    transito_historico: 'Historical traffic en',
                    inv_est_conteo: 'Inventory Counting station en',
                    indicadores_4g: '4G indicators en',
                    datos_basicos: 'Basic data en',
                    deterioro: 'Deterioration en',
                    calificacion_puentes: 'Bridge rating en',
                    calificacion_pontones: 'Pontoon rating en',
                    disp_vias: 'Availability of Roads en',
                    cola_peaje: 'Toll queue en',
                    parametros_planeacion: 'Planning parameters en',
                    politica_general: 'General policies en',
                    politica_particular: 'Particular policies en',
                    alternativa_intervencion: 'Intervention Alternative en',
                    repa_menores: 'Minor repairs en',
                    exportar_pers: 'Export pers base en',
                    reportes_pavimentos: 'Import base PERS en',
                    flujo_caja: 'Cash flow en',
                    idioma: 'Idioma en',
                    sistema: 'Sistema en',
                    indicadorComplementario: 'Indicador complementario en',
                    novedades: 'Novedades en',
                    cargaMasiva: 'Carga masiva en',
                    complementarios: 'Complementarios en',
                    costo: 'Registro de Costos en',
                    respuestaEvaluacionNovedad: 'Novedades en',
                    aprobacion: 'Aprobación en'
                }
            }
        },
        atributos: {
            name: 'Attribute |||| Attributes en',
            fields: {
                nombre: 'Roles en',
                tipoRol: 'Tipo de rol en',
                usuario: 'Usuarios en',
                perfiles: 'Perfiles en',
                asigclaves: 'Asignar clave en',
                proyecto: 'Proyectos en',
                rutas: 'Rutas en',
                unifuncionales: 'Unidades Funcionales en',
                tramos: 'Tramos en',
                segmento: 'Segmentos en',
                materiales: 'Materiales en',
                ganulometria: 'Granulometría en',
                parametrosEvaluacion: 'Parámetros de Evaluación en',
                diagramaColorimetrico: 'Diagrama Colorimétrico en',
                reparaciones: 'Reparaciones en',
                deterioros: 'Deterioros en',
                contemergencia: 'Contactos de Emergencia en',
                proveedores: 'Proveedores en',
                serpublicos: 'Servicios Públicos en',
                mapa: 'Mapa en',
                flexible: 'Flexible en',
                rigido: 'Rígido en',
                adoquin: 'Adoquín en',
                otro: 'Otro en',
                seguridad: 'Sistemas de Contención en',
                drenaje: 'Drenaje en',
                senalizacion: 'Señalización en',
                alrededores: 'Alrededores en',
                otroElemento: 'Otros Elementos en',
                redes: 'Redes en',
                puentes: 'Puentes en',
                contencion: 'Contención en',
                ponton: 'Ponton en',
                tunel: 'Túneles en',
                inventario: 'Inventario Peajes en',
                transitoMensual: 'Tránsito Histórico en',
                inventarioa: 'Inventario Estación de conteo en',
                indicadores4g: 'Indicadores 4G en',
                datosBasicos: 'Datos básicos en',
                colaDePeaje: 'Deterioro en',
                calificacionPuentes: 'Calificación Puentes en',
                calificacionPontones: 'Calificación Pontones en',
                disponibilidadVia: 'Disponibilidad de Vías en',
                colaDePeajea: 'Cola de Peaje en',
                parametrosAnalisis: 'Parámetros de Planeación en',
                politicaGeneral: 'Políticas Generales en',
                politicaParticular: 'Políticas Particulares en',
                alternativaIntervencion: 'Alternativa de Intervención en',
                reparacionesMenores: 'Reparaciones Menores en',
                exportarPers: 'Exportar base pers en',
                reportesPers: 'Import base PERS en',
                flujoCaja: 'Flujo de Caja en',
                reportesConfig: 'Configuración Reportes en',
                reportesPersQ: 'Reportes PERS en',
                reportesInventarioQ: 'Reportes Inventario en',
                reportesIndicadoresQ: 'Reportes Indicadores en',
                reportesCostosQ: 'Reportes Costos en',
                reportesTransitoQ: 'Reportes Tránsito en',
                reportesContactosQ: 'Otros Reportes en',
                reportesModelacionQ: 'Reportes Modelación en',
                createdAt: 'Creado el en',
                createdBy: 'Creado por en',
                updatedAt: 'Actualizado el en',
                updatedBy: 'Actualizado por en',
                novedades: 'Novedades en',
                configSistema: 'Sistema en',
                audit: 'Audit en',
                costo: 'Registro de Costos en'
            }
        },
        proyectos:{
            name: 'Project |||| Projects',
            fields: {
              codigo: 'Código proyecto en',
              nombre: 'Nombre en',
              licencia: 'Licencia en',
              concesionario: 'Concesionario en',
              latitud: 'Latitud en',
              longitud: 'Longitud en',
              alturaIni: 'Altura mínima (m.s.n.m) en',
              alturaFin: 'Altura máxima (m.s.n.m) en',
              ubicaciones: 'Agregar ubicacación en',
              proyecto: 'Proyecto en',
              createdAt: 'Creado el en',
              createdBy: 'Creado por en',
              updatedAt: 'Actualizado el en',
              updatedBy: 'Actualizado por en',
              uniFuncionalesProyec: 'Unidades Funcionales del proyecto en',
              archivoKml: 'Archivo .kml en',
              ArchCargadoUniFuncional: 'Archivo(s) cargado(s) correctamente en',
              descargarkml:'Descargar archivo KML en',
              noSubido:'Archivo KML no procesado en',
              cargarArchivo: 'Cargar Archivo en',
            }
  
          },
        rutas:{
            name: 'Route |||| Routes',
            fields:{
              proyecto: 'Proyecto en',
              codigo: 'Código en',
              categoriaRuta: 'Categoría en',
              pInicio: 'PR inicio en',
              pFin: 'PR fin en',
              tramo: 'Tramo en',
              sector: 'Sector en',
              origen: 'Origen en',
              destino: 'Destino en',
              createdAt: 'Creado el en',
              createdBy: 'Creado por en',
              updatedAt: 'Actualizado el en',
              updatedBy: 'Actualizado por en',
            }
          },
        materials: {
            name: 'Información Materiales en',
            editar:'Editar Materiales en',
            fields: {
                material: 'Material Code en',
                tipo: 'Type en',
                comportamiento: 'Behaviour en',
                especificacion: 'Specification en',
                refmodulus: 'Theoric Module en',
                minmodulus: 'Minimun Module en',
                stdfactor: 'Standar Deviation en',
                poissons: 'Poisson Module en',
                observaciones: 'Notes en',
                createdAt: 'Created At en',
                createdBy: 'Created By en',
                updatedAt: 'Updated At en',
                updatedBy: 'Updated By en',
            }
        },
        politicas_mantenimientos: {
            name: 'Manteniance Policy |||| Manteniance Policies en',
            fields: {
                seRealiza: 'Is Done en',
                nombreElemento: 'Name en',
                tipo: 'Type en',
                nombre: "Manteniance Policy's Name en",
                sistema: 'System en',
            }
        },
        unidad_funcionals:{
            name: 'Unidad Funcional |||| Unidades funcionales en',
            fields:{
              ruta: 'Ruta en ',
              codigo: 'Código en',
              abInicio: 'Abscisa inicio en',
              abFin: 'Abscisa fin en',
              pInicio: 'PR inicio en', 
              pFin: 'PR fin en',
              fullResta: 'Longitud (km) en',
              createdAt: 'Creado el en',
              createdBy: 'Creado por en',
              updatedAt: 'Actualizado el en',
              updatedBy: 'Actualizado por en',
            }
          },
          tramos:{
            name: 'Tramo |||| Tramos',
            fields:{
              unidadFuncional:'Unidad Funcional en',
              codigo: 'Código Tramo en',
              abInicio: 'Abscisa inicial en',
              abFin: 'Abscisa final en',
              pInicio: 'PR inicio en',
              pFin: 'PR fin en',
              inicio: 'Inicio en',
              final: 'Final en',
              fullResta: 'Longitud (km) en',
              createdAt: 'Creado el en',
              createdBy: 'Creado por en',
              updatedAt: 'Actualizado el en',
              updatedBy: 'Actualizado por en',
            }
          },
          segmentos:{
            name: 'Segmento |||| Segmentos',
            fields:{
              tipoElemento:'Tipo en',
              segmentoId: 'Segmento en',
              codigo:'Código en',
              identificacionRamal:'Identificación del ramal en',
              codigoRR:'Código en',
              absSgm:'Abscisa del segmento en',
              lado:'Lado en',
              abInicio:'Abscisa inicial en',
              abFin:'Abscisa final en',
              pInicio:'PR Inicio en',
              pFin:'PR Fin en',
              departamento:'Departamento en',
              municipio:'Municipio en',
              latitud:'Latitud en',
              longitud:'Longitud en',
              altura:'Altura (m.s.n.m) en',
              ubicaciones:'Ubicaciones en',
              tipoCalzada:'Tipo Calzada en',
              calzadaCodigo:'Nombre calzada en',
              tipoSegmento:'Tipo Segmento en',
              tipoSuperficie:'Tipo Superficie en',
              estado:'Estado en',
              unidadFuncional:'Unidad Funcional en',
              tramo:'Tramo en',
              fullResta:'Longitud (km) en',
              createdAt: 'Creado el en',
              createdBy: 'Creado por en',
              updatedAt: 'Actualizado el en',
              updatedBy: 'Actualizado por en',
            }
          },
          materials: {
              name: 'Material |||| Materiales en',
              fields: {
                  material: 'Código de material en',
                  tipo: 'Tipo en',
                  comportamiento: 'Comportamiento en',
                  especificacion: 'Especificación en',
                  refmodulus: 'Módulo teórico (MPa) en',
                  minmodulus: 'Módulo mínimo (MPa) en',
                  stdfactor: 'Desviación estandar en',
                  poissons: 'Módulo de poisson en',
                  observaciones: 'Observaciones en',
                  createdAt: 'Creado el en',
                  createdBy: 'Creado por en',
                  updatedAt: 'Actualizado el en',
                  updatedBy: 'Actualizado por en',
                  materialCreate: 'Material en'
              }
          },
          granulometrias:{
            name: 'Granulomentría |||| Granulometrías en',
            fields:{
              tipo:'Tipo de material en',
              granulometria: 'Granulometría en',
              especificacion: 'Especificación en',
              createdAt: 'Creado el en',
              createdBy: 'Creado por en',
              updatedAt: 'Actualizado el en',
              updatedBy: 'Actualizado por en',
            }
          },
          generacion_masiva_segmentos:{
            name: 'Generación Masiva de Segmento |||| Generación Masiva de Segmentos',
            fields:{
              unidadFuncional: 'Unidad Funcional en',
              tramo: 'Tramo en',
              absSegIni: 'Abscisa inicial del segmento en',
              absSegFin: 'Abscisa final del segmento en',
              longTotal: 'Longitud total en',
              latitud: 'Latitud en',
              longitud:  'Longitud en',
              altura: 'Altura (m.s.n.m) en',
              tipoCalzada: 'Tipo Calzada en',
              tipoSegmento: 'Tipo Segmento en',
              tipoSuperficie: 'Tipo Superficie en',
            }
          },
          transformacion_segmentos: {
            name: 'Transformación segmento en |||| Transformación segmento en',
            fields: {
              unidadFuncional: 'Unidad Funcional en',
              tramo: 'Tramo en',
              absSegIni: 'Abscisa inicial del segmento en',
              absSegFin: 'Abscisa final del segmento en',
              longTotal: 'Longitud total en',
            }
          },
        idiomas: {
            name: 'Idioma |||| Idiomas en',
            idiomaActual:'Idioma actual en: ',
            fields: {
                idioma: 'Language en',
                region: 'Region en',
                nombreIdioma: 'Language name add en',
                manual_usuario: 'User manual en',
                modulo_administracion: 'Management module en',
                modulo_inventario: 'Inventory module en',
                estado: 'State en',
                eliminar: 'delete en',
                cargueIdioma: 'Load language information en',
                descargar: 'Download en',
                descargar_manual_usuario: 'Download User manual en',
                informacionIdioma: 'Language Information en',
                auditoria: 'audit en',
                createdAt: 'Created At en',
                createdBy: 'Created By en',
                updatedAt: 'Updated At en',
                updatedBy: 'Updated By'
            }
        },
        reparacion_localizadas:{
          name: 'Reparacion PERS ES |||| Reparaciones PERS en',
            fields: {
              tipoReparacion: 'Tipo de reparación en',
              nombre: 'Nombre en',
              unidad: 'Unidad en',
              costo: 'Costo en',
              unidadPlaca:'$/losa en',
              
              observaciones: 'Observaciones en',
              createdAt: 'Fecha creación en',
              createdBy: 'Creado por en',
              updatedAt: 'Fecha actualización en',
              updatedBy: 'Actualizado por en'
            }
        },
        tipo_deterioro_pers:{
          name: 'Deterioro PERS ES |||| Deterioros PERS',
          fields: {
            tipoSuperficie: 'Tipo de Pavimento en',
            codigoAstm: 'Código ASTM en',
            nivelSeveridad: 'Niveles de severidad en',
            estado: 'State en',
            nombre: 'Name en',
            unidad: 'Unidad en',
            losa:'losa en',
            tipoReparacionMedia: 'Tipo de reparaciones severidad media en',
            tipoReparacionAlta: 'Tipo de reparaciones severidad alta en',
            createdAt: 'Fecha creación en',
            createdBy: 'Creado por en',
            updatedAt: 'Fecha actualización en',
            updatedBy: 'Actualizado por en'
          }
        },
        contacto_emergencias:{
          name: 'Contacto de Emergencia ES |||| Contactos de Emergencia en',
          fields: {
            proyecto: 'Proyecto en',
            grupo: 'Grupo en',
            numero_identificacion: 'Número de Identificación en',
            nivel: 'Nivel en',
            identificacion: 'Tipo de dentificación en',
            empresa: 'Empresa en',
            nombre: 'Nombres en',
            apellido: 'Apellidos en',
            cargo: 'Cargo en',
            direccion: 'Dirección en',
            departamento: 'Departamento en',
            municipio: 'Municipio en',
            municipio1: 'Municipio en',
            municipio2: 'Municipio en',
            municipio3: 'Municipio en',
            municipio4: 'Municipio en',
            municipio5: 'Municipio en',
            municipio6: 'Municipio en',
            municipio7: 'Municipio en',
            municipio8: 'Municipio en',
            municipio9: 'Municipio en',
            municipio10: 'Municipio en',
            municipio11: 'Municipio en',
            municipio12: 'Municipio en',
            municipio13: 'Municipio en',
            municipio14: 'Municipio en',
            municipio15: 'Municipio en',
            municipio16: 'Municipio en',
            municipio17: 'Municipio en',
            municipio18: 'Municipio en',
            municipio19: 'Municipio en',
            municipio20: 'Municipio en',
            municipio21: 'Municipio en',
            municipio22: 'Municipio en',
            municipio23: 'Municipio en',
            municipio24: 'Municipio en',
            municipio25: 'Municipio en',
            municipio26: 'Municipio en',
            municipio27: 'Municipio en',
            municipio28: 'Municipio en',
            municipio29: 'Municipio en',
            municipio30: 'Municipio en',
            municipio31: 'Municipio en',
            municipio32: 'Municipio en',
            municipio33: 'Municipio en',
            telefono_1: 'Teléfono 1 en',
            telefono_2: 'Teléfono 2 en',
            correo: 'Correo electrónico en',
            observaciones: 'Observaciones en',
            createdAt: 'Fecha creación en',
            createdBy: 'Creado por en',
            updatedAt: 'Fecha actualización en',
            updatedBy: 'Actualizado por en'
          },
        },
        proveedors:{
          name: 'Proveedor prueba ES |||| Proveedores prueba en',
          fields:{
            proyecto: 'Proyecto en',
            sistema: 'Sistema en',
            nombreSistema: 'Nombre sistema en',
            sistemaNombre: 'Sistema en',
            identificacion: 'Tipo de dentificación en',
            numeroIdentificacion: 'Número de identificación en',
            razonSocial: 'Empresa en',
            nombre: 'Nombres en',
            apellido: 'Apellidos en',
            direccion: 'Dirección en',
            departamento: 'Departamento en',
            municipio: 'Municipio en',
            municipio1: 'Municipio en',
            telefono: 'Teléfono 1 en',
            telefono2: 'Teléfono 2 en',
            correo: 'Correo electrónico en',
            observaciones: 'Observaciones en',
            createdAt: 'Fecha creación en',
            createdBy: 'Creado por en',
            updatedAt: 'Fecha actualización en',
            updatedBy: 'Actualizado por en',
            calificacion: 'Calificación en',
            fechaCalificacion: 'Fecha de calificación en',
            usuario_id: 'Usuario en',
            promedioCal: 'Promedio en'
          }
        },
        calificacion_proveedors: {
          fields: {
            proveedor: 'Proveedor en',
            calificacion: 'Calificación en',
            fechaCalificacion: 'Fecha de calificación en',
            usuario_id: 'Usario en'
          }
        },
        servicios_publicos: {
          name: 'Servicio público  |||| Servicios públicos ',
          fileds: {
            proyecto: 'Proyecto en',
            grupo: 'Grupo en',
            identificacion: 'Tipo de Identificación en',
            numero_identificacion: 'Número de identificación en',
            empresa: 'Empresa en',
            nombre: 'Nombres en',
            apellido: 'Apellido en',
            direccion: 'Dirección en',
            departamento: 'Departamento en',
            municipio1: 'Municipio en',
            municipio: 'Municipio en',
            municipios: 'Municipios es',
            telefono_1: 'Teléfono 1 en',
            telefono_2: 'Teléfono 2 en',
            correo: 'Correo electrónico en',
            observaciones: 'Observaciones en',
            createdAt: 'Fecha creación en',
            createdBy: 'Creado por en',
            updatedAt: 'Fecha actualización en',
            updatedBy: 'Actualizado por en',
          }
        },
        parametros_evaluacion:{
          name: 'Parámetros de evaluación en |||| Parámetros de evaluaciones en',
          fields:{
            valorAlerta_: 'Valor de alerta en',
            datosminModelacion_: 'Datos mínimos para modelación en',
            tiempoAntesAlertaMedicion_: '¿Cuánto tiempo antes generar alerta de medición? (meses) en',
            proyectoNombre: 'Proyecto en',
            indicadores: 'Indicadores que apliquen a:  en',
            titleParametros: 'Parámetros de Evaluación en',
            proyecto: 'Proyecto Nombre en',
          }
        },
        colores:{
          name: 'Color  en|||| Colores en',
          fields:{
            color: 'Color en',
            valorA: 'Valor A en',
            valorB: 'Valor B en',
            valorMin: 'Valor mínimo de Cumplimiento en',
            createdAt: 'Creado el en',
            createdBy: 'Creado por en',
            updatedAt: 'Actualizado el en',
            updatedBy: 'Actualizado por en',
          }
        },
        elementos: {
          name: 'Mapa general |||| Mapas generales',
          fields: {
            codigo:'Código en',
            elemento:'Elemento en',
            unidadFuncional: 'Unidad Funcional en',
            tramo: 'Tramo en',
            segmento: 'Segmento en',
            absInicial:'Abscisa inicio en',
            verMas:'Ver más en',
            tipoMapa: 'Función del Mapa en',
            tipoElementoSeguridad: 'Tipo de Elemento en',
            tipoElementoDrenaje: 'Tipo de Elemento en',
            tipoElementoSenal: 'Tipo de Señal en',
            tipoSenal: 'Tipo de Elemento en',
            tipoSenalVertical: 'Tipo Modelo en',
            tipoElementoAlrededor: 'Tipo de Elemento en',
            tipoPuente: 'Tipo de Puente en',
            tipoElementoOtro: 'Tipo de Elemento en',
            tipoElementoRed: 'Tipo de Elemento en',
            numIntervalos: 'N° de intervalos en',
            umbral: 'Umbral en',
          }
        },
        flexible_detalles: {
          name: 'Segmento Flexible en |||| Segmentos Flexibles en',
          fields: {
            unidadFuncional: 'Unidad Funcional en',
            tramo: 'Tramo en',
            segmento: 'Segmento en',
            sentido: 'Sentido en',
            estadoDetalles: 'Estado en',
            estadoRazon: 'Razón en',
            dateInicioOperacion: 'Fecha de Inicio Operación en',
            proveedor: 'Proveedor en',
            garantiaMeses: 'Garantía (meses) en',
            trafico: 'Tráfico en',
            esalTime: 'ESAL (Año) en',
            nivelTrafico: 'Nivel de tráfico en',
            nivelTraficoDetalles: 'Nivel de Tráfico en',
            esalTime10: 'ESAL (Proyección 10 Años) en',
            esalTime20: 'ESAL (Proyección 20 Años) en',
            detallesObservaciones: 'Observaciones en',
            detallesCalzada: 'Detalles de Calzada en',
            geometriaLargo: 'Largo (m) en',
            geometriaAncho: 'Ancho de la Calzada (m) en',
            geometriaNumCarriles: 'Número de Carriles en',
            geometriaAnchoCarril: 'Ancho por Carril (m) en',
            geometriaTipoBerma: 'Tipo de Berma en',
            geometriaAnchoBerma: 'Ancho de Berma (m) en',
            geometriaCondicionBerma: 'Condición de Berma en',
            aux1: 'Área de Carriles (m²) en',
            areaCarrilesVerdadera: 'Área Verdadera Carriles (m²) en',
            geometriaLongitudinal: 'Pendiente Longitudinal en',
            geometriaLongitudinalMa: 'Pendiente Longitudinal máxima en',
            geometriaLongitudinalMi: 'Pendiente Longitudinal mínima en',
            geometriaTransversal: 'Pendiente Transversal en',
            geometriaRadioMi: 'Radio Mínimo (m) en',
            geometriaRadioMa: 'Radio Máximo (m) en',
            geometriaRadioCurvatura: 'Radio de Curvatura (°/km) en',
            geometriaVelDiseno: 'Velocidad de Diseño (km/h) en',
            geometriaSeparador: 'Con Separador en',
            geometriaSeparadorAncho: 'Ancho Separador (m) en',
            geometriaCondicionesGeometrica: 'Condiciones Geométricas en',
            geometriaAlineamiento: 'Alineamiento en',
            geometriaTipoTerreno: 'Tipo de Terreno en',
            detallesGeometria: 'Observaciones en',
            informacionEstructura: 'Información Estructura en',
            propiedadesGeometricas: 'Propiedades geométricas en',
            detallesCarriles: 'Detalles de Carriles en',
            estructuraEspesor: 'Espesor (mm) en',
            estructuraModulo: 'Módulo (MPa) en',
            estructuraDs: 'Desviación Estándar (Std) en',
            material: 'Material en',
            materialEsp: 'Especificación en',
            granulometria: 'Granulometría en',
            espesorTotal: 'Espesor total (mm) en',
            igg: 'Índice de Gavidade Global (IGG) en',
            iggEstructura: 'Índice de Gavidade Global en',
            conceito: 'Conceito en',
            capacidadEstructural: 'Capacidad estructural en',
            estructuraNumDiseno: 'Número Estructural de Diseño en',
            estructuraFecha: 'Fecha en',
            estructuraNumero: 'Número Estructural Efectivo en',
            estructuraNumeroFecha: 'Fecha en',
            estructuraCbr: 'CBR (%) en',
            estructuraCbrFecha: 'Fecha en',
            estructuraFechaConst: 'Fecha de Construcción en',
            estructuraFechaRehab: 'Fecha de Última Rehabilitación en',
            detallesEstructura: 'Observaciones en',
            fechasImportantes: 'Fechas importantes en',
            smartCode: 'Código en',
            absInicio: 'Abscisa Inicial en',
            absFin: 'Abscisa Final en',
            longitudShow: 'Longitud (km) en',
            prInicio: 'PR Inicio ns',
            prFin: 'PR Fin en',
            departamento: 'Departamento en',
            municipio: 'Municipio en',
            latitud: 'Latitud en',
            longitud: 'Longitud en',
            altura: 'Altura (m.s.n.m) en',
            createdAt: 'Fecha Creación en',
            createdBy: 'Creado Por en',
            updatedAt: 'Fecha Actualización en',
            updatedBy: 'Actualizado Por en',  
            createdEn: 'Creado en en',
            updatedEn: 'Actualizado en en',          
            localizacion: 'Localización en',
            coordenadasIniciales: 'Coordenadas Iniciales en',
            coordenadasFinales: 'Coordenadas Finales en'
          }
        },
        rigido_detalles: {
          name: 'Segmento Rígido en |||| Segmentos Rígidos en',
          fields: {
            unidadFuncional: 'Unidad Funcional en',
            tramo: 'Tramo en',
            segmento: 'Segmento en',
            sentido: 'Sentido en',
            estadoDetalles: 'Estado en',
            estadoRazon: 'Razón en',
            dateInicioOperacion: 'Fecha de Inicio Operación en',
            proveedor: 'Proveedor en',
            garantiaMeses: 'Garantía (meses) en',
            trafico: 'Tráfico en',
            esalTime: 'ESAL (Año) en',
            nivelTrafico: 'Nivel de tráfico en',
            nivelTraficoDetalles: 'Nivel de Tráfico en',
            esalTime10: 'ESAL (Proyección 10 Años) en',
            esalTime20: 'ESAL (Proyección 20 Años) en',
            detallesObservaciones: 'Observaciones en',
            detallesCalzada: 'Detalles de Calzada en',
            geometriaLargo: 'Largo (m) en',
            geometriaAncho: 'Ancho de la Calzada (m) en',
            geometriaNumCarriles: 'Número de Carriles en',
            geometriaAnchoCarril: 'Ancho por Carril (m) en',
            geometriaTipoBerma: 'Tipo de Berma en',
            geometriaAnchoBerma: 'Ancho de Berma (m) en',
            geometriaCondicionBerma: 'Condición de Berma en',
            aux1: 'Área de Carriles (m²) en',
            areaCarrilesVerdadera: 'Área Verdadera Carriles (m²) en',
            geometriaLongitudinal: 'Pendiente Longitudinal en',
            geometriaLongitudinalMa: 'Pendiente Longitudinal Máxima en',
            geometriaLongitudinalMi: 'Pendiente Longitudinal Mínima en',
            geometriaTransversal: 'Pendiente Transversal en',
            geometriaRadioMi: 'Radio Mínimo (m) en',
            geometriaRadioMa: 'Radio Máximo (m) en',
            geometriaRadioCurvatura: 'Radio de Curvatura (°/km) en',
            geometriaVelDiseno: 'Velocidad de Diseño (km/h) en',
            geometriaSeparador: 'Con Separador en',
            geometriaSeparadorAncho: 'Ancho Separador (m) en',
            geometriaCondicionesGeometrica: 'Condiciones Geométricas en',
            geometriaAlineamiento: 'Alineamiento en',
            geometriaTipoTerreno: 'Tipo de Terreno en',
            dimensionesLosaTipica: 'Dimensiones de la losa típica en',
            geometriaDltLargo: 'Largo (m) en',
            geometriaDltAncho: 'Ancho (m) en',
            geometria_dlt_area: 'Área (m2) en',
            geometriaDltNumlosas: 'No. De Losas en',
            geometriaDltLongjuntas: 'Longitud de Juntas (m) en',
            detallesGeometria: 'Observaciones en',
            informacionEstructura: 'Información Estructura en',
            propiedadesGeometricas: 'Propiedades geométricas en',
            detallesCarriles: 'Detalles de Carriles en',
            estructuraEspesor: 'Espesor (mm) en',
            estructuraModulo: 'Módulo (MPa) en',
            estructuraDs: 'Desviación Estándar (Std) en',
            material: 'Material en',
            materialEsp: 'Especificación en',
            granulometria: 'Granulometría en',
            espesorTotal: 'Espesor total (mm) en',
            igg: 'Índice de Gavidade Global (IGG) en',
            iggEstructura: 'Índice de Gavidade Global en',
            conceito: 'Conceito en',
            capacidadEstructural: 'Capacidad estructural en',
            estructuraNumDiseno: 'Número Estructural de Diseño en',
            estructuraFecha: 'Fecha en',
            estructuraNumero: 'Número Estructural Efectivo en',
            estructuraNumeroFecha: 'Fecha en',
            estructuraCbr: 'CBR (%) en',
            estructuraCbrFecha: 'Fecha en',
            estructuraVacios: 'Vacíos (%) en',
            kjoint: 'k-joint (kPa/mm) en',
            jsdf: 'SDF en',
            kcenter: 'k-center (kPa/mm) en',
            csdf: 'SDF en',
            estructuraFechaConst: 'Fecha de Construcción en',
            estructuraFechaRehab: 'Fecha de Última Rehabilitación en',
            detallesEstructura: 'Observaciones en',
            fechasImportantes: 'Fechas importantes en',
            smartCode: 'Código en',
            absInicio: 'Abscisa Inicial en',
            absFin: 'Abscisa Final en',
            longitudShow: 'Longitud (km) en',
            prInicio: 'PR Inicio en',
            prFin: 'PR Fin en',
            departamento: 'Departamento en',
            municipio: 'Municipio en',
            latitud: 'Latitud en',
            longitud: 'Longitud en',
            altura: 'Altura (m.s.n.m) en',
            createdAt: 'Fecha Creación en',
            createdBy: 'Creado Por en',
            updatedAt: 'Fecha Actualización en',
            updatedBy: 'Actualizado Por en',
            createdEn: 'Creado en en',
            updatedEn: 'Actualizado en en',  
            localizacion: 'Localización en',
            coordenadasIniciales: 'Coordenadas Iniciales en',
            coordenadasFinales: 'Coordenadas Finales en'
          }
        },
        adoquin_detalles: {
          name: 'Segmento Adoquín en|||| Segmentos Adoquínes en',
          fields: {
            unidadFuncional: 'Unidad Funcional en',
            tramo: 'Tramo en',
            segmento: 'Segmento en',
            sentido: 'Sentido en',
            estadoDetalles: 'Estado en',
            estadoRazon: 'Razón en',
            dateInicioOperacion: 'Fecha de Inicio Operación en',
            proveedor: 'Proveedor en',
            garantiaMeses: 'Garantía (meses) en',
            trafico: 'Tráfico en',
            esalTime: 'ESAL (Año) en',
            nivelTrafico: 'Nivel de tráfico en',
            nivelTraficoDetalles: 'Nivel de Tráfico en',
            esalTime10: 'ESAL (Proyección 10 Años) en',
            esalTime20: 'ESAL (Proyección 20 Años en)',
            detallesObservaciones: 'Observaciones en',
            detallesCalzada: 'Detalles de Calzada en',
            geometriaLargo: 'Largo (m) en',
            geometriaAncho: 'Ancho de la Calzada (m) en',
            geometriaNumCarriles: 'Número de Carriles en',
            geometriaAnchoCarril: 'Ancho por Carril (m) en',
            geometriaTipoBerma: 'Tipo de Berma en',
            geometriaAnchoBerma: 'Ancho de Berma (m) en',
            geometriaCondicionBerma: 'Condición de Berma en',
            aux1: 'Área de Carriles (m²) en',
            areaCarrilesVerdadera: 'Área Verdadera Carriles (m²) en',
            geometriaLongitudinal: 'Pendiente Longitudinal en',
            geometriaLongitudinalMa: 'Pendiente Longitudinal Máxima en',
            geometriaLongitudinalMi: 'Pendiente Longitudinal Mínima en',
            geometriaTransversal: 'Pendiente Transversal en',
            geometriaRadioMi: 'Radio Mínimo (m) en',
            geometriaRadioMa: 'Radio Máximo (m) en',
            geometriaRadioCurvatura: 'Radio de Curvatura (°/km) en',
            geometriaVelDiseno: 'Velocidad de Diseño (km/h) en',
            geometriaSeparador: 'Con Separador en',
            geometriaSeparadorAncho: 'Ancho Separador (m) en',
            geometriaCondicionesGeometrica: 'Condiciones Geométricas en',
            geometriaAlineamiento: 'Alineamiento en',
            geometriaTipoTerreno: 'Tipo de Terreno en',
            dimAdoquinTipico: 'Dimensiones del Adoquín típico en',
            geometriaAdoLargo: 'Largo (m) en',
            geometriaAdoAncho: 'Ancho (m) en',
            geometriaAdoAlto: 'Alto (m) en',
            detallesGeometria: 'Observaciones en',
            informacionEstructura: 'Información Estructura en',
            propiedadesGeometricas: 'Propiedades geométricas en',
            detallesCarriles: 'Detalles de Carriles en',
            estructuraEspesor: 'Espesor (mm) en',
            estructuraModulo: 'Módulo (MPa) en',
            estructuraDs: 'Desviación Estándar (Std) en',
            material: 'Material en',
            materialEsp: 'Especificación en',
            granulometria: 'Granulometría en',
            espesorTotal: 'Espesor total (mm) en',
            igg: 'Índice de Gavidade Global (IGG) en',
            iggEstructura: 'Índice de Gavidade Global en',
            conceito: 'Conceito en',
            capacidadEstructural: 'Capacidad estructural en',
            estructuraNumDiseno: 'Número Estructural de Diseño en',
            estructuraFecha: 'Fecha en',
            estructuraNumero: 'Número Estructural Efectivo en',
            estructuraNumeroFecha: 'Fecha en',
            estructuraCbr: 'CBR (%) en',
            estructuraCbrFecha: 'Fecha en',
            estructuraFechaConst: 'Fecha de Construcción en',
            estructuraFechaRehab: 'Fecha de Última Rehabilitación en',
            detallesEstructura: 'Observaciones en',
            fechasImportantes: 'Fechas importantes en',
            smartCode: 'Código en',
            absInicio: 'Abscisa Inicial en',
            absFin: 'Abscisa Final en',
            longitudShow: 'Longitud (km) en',
            prInicio: 'PR Inicio en',
            prFin: 'PR Fin en',
            departamento: 'Departamento en',
            municipio: 'Municipio en',
            latitud: 'Latitud en',
            longitud: 'Longitud en',
            altura: 'Altura (m.s.n.m) en',
            createdAt: 'Fecha Creación en',
            createdBy: 'Creado Por en',
            updatedAt: 'Fecha Actualización en',
            updatedBy: 'Actualizado Por en',
            createdEn: 'Creado en en',
            updatedEn: 'Actualizado en en',
            localizacion: 'Localización en',
            coordenadasIniciales: 'Coordenadas Iniciales en',
            coordenadasFinales: 'Coordenadas Finales en',
            tipoElmentoFotografia: 'Tipo de elemento en'
          },
        },
        otro_detalles: {
          name: 'Segmento Otro en|||| Segmentos Otros en',
          fields: {
            unidadFuncional: 'Unidad Funcional en',
            tramo: 'Tramo en',
            segmento: 'Segmento en',
            sentido: 'Sentido en',
            estadoDetalles: 'Estado en',
            estadoRazon: 'Razón en',
            dateInicioOperacion: 'Fecha de Inicio Operación en',
            proveedor: 'Proveedor en',
            garantiaMeses: 'Garantía (meses) en',
            trafico: 'Tráfico en',
            esalTime: 'ESAL (Año) en',
            nivelTrafico: 'Nivel de tráfico en',
            nivelTraficoDetalles: 'Nivel de Tráfico en',
            esalTime10: 'ESAL (Proyección 10 Años) en',
            esalTime20: 'ESAL (Proyección 20 Años) en',
            detallesObservaciones: 'Observaciones en',
            detallesCalzada: 'Detalles de Calzada en',
            geometriaLargo: 'Largo (m) en',
            geometriaAncho: 'Ancho de la Calzada (m) en',
            geometriaNumCarriles: 'Número de Carriles en',
            geometriaAnchoCarril: 'Ancho por Carril (m) en',
            geometriaTipoBerma: 'Tipo de Berma en',
            geometriaAnchoBerma: 'Ancho de Berma (m) en',
            geometriaCondicionBerma: 'Condición de Berma en',
            aux1: 'Área de Carriles (m²) en',
            sumaAreaCarriles: 'Área de Carriles (m²) en',
            areaCarrilesVerdadera: 'Área Verdadera Carriles (m²) en',
            geometriaLongitudinal: 'Pendiente Longitudinal en',
            geometriaLongitudinalMa: 'Pendiente Longitudinal máxima en',
            geometriaLongitudinalMi: 'Pendiente Longitudinal mínima en',
            geometriaTransversal: 'Pendiente Transversal en',
            geometriaRadioMi: 'Radio Mínimo (m) en',
            geometriaRadioMa: 'Radio Máximo (m) en',
            geometriaRadioCurvatura: 'Radio de Curvatura (°/km) en',
            geometriaVelDiseno: 'Velocidad de Diseño (km/h) en',
            geometriaSeparador: 'Con Separador en',
            geometriaSeparadorAncho: 'Ancho Separador (m) en',
            geometriaCondicionesGeometrica: 'Condiciones Geométricas en',
            geometriaAlineamiento: 'Alineamiento en',
            geometriaTipoTerreno: 'Tipo de Terreno en',
            detallesGeometria: 'Observaciones en',
            informacionEstructura: 'Información Estructura en',
            propiedadesGeometricas: 'Propiedades geométricas en',
            detallesCarriles: 'Detalles de Carriles en',
            estructuraEspesor: 'Espesor (mm) en',
            estructuraModulo: 'Módulo (MPa) en',
            estructuraDs: 'Desviación Estándar (Std) en',
            material: 'Material en',
            materialEsp: 'Especificación en',
            granulometria: 'Granulometría en',
            espesorTotal: 'Espesor total (mm) en',
            igg: 'Índice de Gavidade Global (IGG) en',
            iggEstructura: 'Índice de Gavidade Global en',
            conceito: 'Conceito en',
            capacidadEstructural: 'Capacidad estructural en',
            estructuraNumDiseno: 'Número Estructural de Diseño en',
            estructuraFecha: 'Fecha en',
            estructuraNumero: 'Número Estructural Efectivo en',
            estructuraNumeroFecha: 'Fecha en',
            estructuraCbr: 'CBR (%) en',
            estructuraCbrFecha: 'Fecha en',
            estructuraFechaConst: 'Fecha de Construcción en',
            estructuraFechaRehab: 'Fecha de Última Rehabilitación en',
            detallesEstructura: 'Observaciones en',
            fechasImportantes: 'Fechas importantes en',
            smartCode: 'Código en',
            absInicio: 'Abscisa Inicial en',
            absFin: 'Abscisa Final en',
            longitudShow: 'Longitud (km) en',
            prInicio: 'PR Inicio en',
            prFin: 'PR Fin en',
            departamento: 'Departamento en',
            municipio: 'Municipio en',
            latitud: 'Latitud en',
            longitud: 'Longitud en',
            altura: 'Altura (m.s.n.m) en',
            createdAt: 'Fecha Creación en',
            createdBy: 'Creado Por en',
            updatedAt: 'Fecha Actualización en',
            updatedBy: 'Actualizado Por en',
            createdEn: 'Creado en en',
            updatedEn: 'Actualizado en en',
            localizacion: 'Localización en',
            coordenadasIniciales: 'Coordenadas Iniciales en',
            coordenadasFinales: 'Coordenadas Finales en'
          }
        },
        seguridads:{
          name: 'Sistema de Contención Vehicular en |||| Sistemas de Contención Vehiculares en',
          fields: {
            tipoElemento: 'Elemento en',
            margen: 'Margen en',
            sentido: 'Sentido en',
            unidadFuncional: 'Unidad Funcional en',
            tramo: 'Tramo en',
            segmento: 'Segmento en',
            abscisa: 'Abscisa en',
            pr: 'PR en',
            latitud: 'Latitud en',
            longitud: 'Longitud en',
            material: 'Material en',
            cant_elem: 'Cantidad de elementos en',
            cant_capta: 'No. Captafaros en',
            cant_largo: 'Largo (m) en',
            ca_largo: 'Largo (m) en',
            largo: 'Largo ss(m) en',
            cant_ancho: 'Ancho (m) en',
            ca_ancho: 'Ancho (m) en',
            altura: 'Altura (m) en',
            ca_altura: 'Altura (m) en',
            perfil: 'Tipo de perfil en',
            defensa: 'Tipo de defensa en',
            postes: 'No. Postes en',
            captafaros: 'No. Captafaros en',
            cant_capta: 'No. Captafaros en',
            unidades: 'No. Unidades en',
            terminales: 'No. Terminales en',
            tornillos: 'No. Tornillos en',
            posteMetalico: 'Poste Metálico en',
            calibre_mm: 'Calibre (mm) en',
            tope: 'Tipo de tope en',
            seccion: 'Sección en',
            diametro: 'Diámetro/Base (m) en',
            diametroShow: 'Diametro Base en',
            topes: 'Cantidad de topes en',
            tramos: 'Longitud del tramo (m) en',
            condicionElemento: 'Condición del elemento en',
            elementoReemplazo_id: 'Elemento que reemplazo en',
            estado: 'Estado en',
            fechaInstalacion: 'Fecha de instalación en',
            proveedor: 'Proveedor en',
            garantia: 'Garantía (meses) en',
            observaciones: 'Observaciones en',
            smartCode: 'Código en',
            abInicio: 'Abscisa Inicio en',
            abFin: 'Abscisa Fin en',
            fullResta: 'Longitud (km) en',
            pInicio: 'PR Inicio en',
            pFin: 'PR Fin en',
            latitudInicial: 'Latitud en',
            longitudInicial: 'Longitud en',
            latitudFinal: 'Latitud en',
            longitudFinal: 'Longitud en',
            createdAt: 'Fecha Creación en',
            createdBy: 'Creado Por en',
            updatedAt: 'Fecha Actualización en',
            updatedBy: 'Actualizado Por en',
            createdEn: 'Creado en en',
            updatedEn: 'Actualizado en en',
            tipoElementoDesc: 'Elementos en',
          },
        },
        elemento_drenajes:{
          name: 'Elemento Drenaje en |||| Elementos Drenajes en',
          fields: {
            tipoElemento: 'Tipo de Elemento en',
            margen: 'Margen en',
            sentido: 'Sentido en',
            tipoCuneta: 'Tipo Cuneta en',
            unidadFuncional: 'Unidad Funcional en',
            tramo: 'Tramo en',
            segmento: 'Segmento en',
            abscisa: 'Abscisa en',
            pr: 'PR en',
            latitud: 'Latitud en',
            longitud: 'Longitud en',
            fullResta: 'Longitud (km) en',
            abInicio: 'Abscisa Inicio en',
            abFin: 'Abscisa Fin en',
            pInicio: 'PR Inicio en',
            pFin: 'PR Fin en',
            material: 'Material en',
            seccion: 'Sección en',
            tipoDelElemento: 'Tipo del Elemento en',
            largo: 'Largo (m) en',
            ancho: 'Ancho/Diámetro (m) en',
            altura: 'Altura (m) en',
            numeroTramos: 'Número de Tramos en',
            seccionHidraulica: 'Sección Hidráulica (m²) en',
            pendienteTransversal: 'Pendiente Transversal en',
            pendienteTransversalShow: 'Pendiente Transversal° en',
            pendienteLongitudinal: 'Pendiente Longitudinal en',
            pendienteLongitudinalShow: 'Pendiente Longitudinal° en',
            numeroDuctos: 'Numero de ductos en',
            tipoPlanta: 'Tipo de Planta en',
            direccion: 'Dirección en',
            areaAla: 'Área ala (m²) en',
            areaCabezal: 'Área muro cabezal (m²) en',
            areaCalzada: 'Área Calzada (m²) en',
            areaLateral: 'Área lateral (m²) en',
            estado: 'Condición del elemento en',
            motivoReemplazo: 'Motivo del Reemplazo en',
            elementoReemplazo_id: 'Elemento que reemplazo en',
            estados: 'Estado en',
            estadoRazon: 'Razón en',
            fechaInstalacion: 'Fecha de instalación en',
            proveedor: 'Proveedor en',
            garantia: 'Garantía (Meses) en',
            observaciones: 'Observaciones en',
            fullCodigoConsecutivo: 'Código en',
            smartCode: 'Código en',
            createdAt: 'Fecha Creación en',
            createdBy: 'Creado Por en',
            updatedAt: 'Fecha Actualización en',
            updatedBy: 'Actualizado Por en',
            createdEn: 'Creado en en',
            updatedEn: 'Actualizado en en',
            tipoImagen: 'Tipo de elemento en'
          }
        },
        elemento_senalizacions: {
          name: 'Elemento Señal en |||| Elementos Señales en',
          fields: {
            tipoElementoSenal: 'Elemento en',
            margen: 'Margen en',
            sentido: 'Sentido en',
            tipoSenal: 'Tipo de elemento en',
            senal: 'Código de modelo en',
            modelo: 'Modelo en',
            unidadFuncional: 'Unidad Funcional en',
            tramo: 'Tramo en',
            segmento: 'Segmento en',
            abscisa: 'Abscisa en',
            pr: 'PR en',
            latitud: 'Latitud en',
            longitud: 'Longitud en',
            materialPlaca: 'Material de placa en',
            material: 'Material en',
            tipoPapel: 'Tipo papel en',
            tipoLamina: 'Tipo de lámina en',
            tipoTablero: 'Forma del tablero en',
            dimensionTablero: 'Dimensiones del tablero en',
            dimensionTablero: 'Dimensiones del tablero en',
            anchoTablero: 'Ancho/Diámetro del tablero (m) en',
            altoTablero: 'Alto del tablero (m) en',
            estadoTablero: 'Estado del tablero en',
            tipoSoporte: 'Tipo de soporte en',
            estadoSoporte: 'Estado del soporte en',
            materialSoporte: 'Material soporte en',
            velocidadDimension: 'Velocidad asociada a la dimensión en',
            altura: 'Altura de señal (m) en',
            valorLimite: 'Valor límite en',
            valorLimiteDescrip: 'Aplica si es señal SR-30, SR-31, SR-32,SR-33,SP-38 en',
            ABNT: 'Valor Mínimo ABNT en',
            color: 'Color principal en',
            colorOtrosElem: 'Color en',
            retroflectividadInicial: 'Retro-reflectividad color principal (cd/lux/m²) en',
            colorSecundario: 'Color secundario en',
            retroflectividadFinal: 'Retro-reflectividad color secundario (cd/lux/m²) en',
            colorTerciario: 'Color terciario en',
            retroflectividadTerciaria: 'Retro-reflectividad color terciario (cd/lux/m²) en',
            numeroTachas: 'Número de tachas en',
            ancho: 'Ancho (m) en',
            area: 'Área (m²) en',
            cantidad: 'Cantidad en',
            espaciamientoPromedio: 'Espaciamiento promedio (m) en',
            estado: 'Condición del elemento en',
            condicion: 'Condición en',
            motivoReemplazo: 'Motivo reemplazo en',
            elementoReemplazo_id: 'Elemento que reemplazo en',
            estados: 'Estado en',
            fechaInstalacion: 'Fecha de instalación en',
            proveedor: 'Proveedor en',
            garantia: 'Garantía (meses) en',
            observaciones: 'Observaciones en',
            smartCode: 'Código en',
            abInicio: 'Abscisa Inicial en',
            abInicioShow: 'Abscisa Inicio en',
            abFin: 'Abscisa Final en',
            fullResta: 'Longitud (km) en',
            pInicio: 'PR Inicial en',
            pInicioShow: 'PR Inicio en',
            pFin: 'PR Final en',
            latitudFinal: 'Latitud en',
            longitudFinal: 'Longitud en',
            fullMotivoReemplazo: 'Motivo del reemplazo en',
            fullCodigo: 'Elemento que reemplaza en',
            createdAt: 'Fecha creación en',
            createdBy: 'Creado por en',
            updatedAt: 'Fecha actualización en',
            updatedBy: 'Actualizado por en',
            createdEn: 'Creado en en',
            updatedEn: 'Actualizado en en',
            tipoImagen: 'Tipo de elemento en'
          }
        },
        elemento_alrededors: {
          name: 'Elemento Alrededor en |||| Elementos Alrededores en',
          fields: {
            tipoElemento: 'Tipo Elemento en',
            tipoElementoShow: 'Elemento en',
            margen: 'Margen en',
            sentido: 'Sentido en',
            unidadFuncional: 'Unidad Funcional en',
            tramo: 'Tramo en',
            segmento: 'Segmento en',
            abscisa: 'Abscisa en',
            pr: 'PR en',
            latitud: 'Latitud en',
            longitud: 'Longitud en',
            tipoServicio: 'Tipo de servicio en',
            tipoServicioCarac: 'Superficie características en',
            tipoServicioObs: 'Tipo en',
            tipoServicioZINT: 'Tipo de afectación en',
            tipoServicioOCPN: 'Tipo de elemento en',
            tipoAcceso: 'Tipo de acceso en',
            estacionamiento: 'Estacionamiento en',
            largo: 'Largo del acceso (m) en',
            ancho: 'Ancho del acceso (m) en',
            area: 'Área de acceso (m²) en',
            identificacion: 'Identificación en',
            propietario: 'Propietario en',
            emailPropietario: 'Email propietario en',
            telefonoPropietario: 'Teléfono propietario en',
            largoM: 'Largo (m) en',
            anchoM: 'Ancho (m) en',
            areaM: 'Área (m²) en',
            areaOb: 'Altura (m) en',
            longitudAfectacion: 'Longitud de afectación (m) en',
            longitudAfectacionZINT: 'Longitud de calzada afectada (m) en',
            riesgo: 'Riesgo en',
            seccion: 'Ubicación en',
            tipoTerraplen: 'Tipo de Terraplen en',
            cantidadBermas: 'Cantidad de Bermas en',
            alturaPromedioBermas: 'Altura Promedio de las Bermas en',
            tramoPendiente: 'Tramos de Pendiente Variable en',
            pendTransversal: 'Pendiente Transversal H:V en',
            obraEstabilizacion: 'Obras de estabilización en',
            tipoObra: 'Tipo de obra en',
            fichaInspeccion: 'Ficha de inspección de obra en',
            inspector: 'Inspector en',
            fechaInspeccion: 'Fecha del estudio en',
            nombreFicha: 'Nombre de la ficha en',
            estudioEstabilidad: 'Estudios de estabilidad del sitio en',
            consultor: 'Consultor en',
            fechaEstudio: 'Fecha del estudio en',
            codigoEstudio: 'Código de estudio en',
            nombreEstudio: 'Nombre del estudio en',
            longitudAcceso: 'Longitud del acceso (m) en',
            anchoAcceso: 'Ancho del acceso (m) en',
            estado: 'Condición del elemento en',
            condicion: 'Motivo de reemplazo en',
            elementoReemplazo: 'Elemento que reemplazo en',
            fechaConstruccion: 'Fecha de construcción en',
            proveedor: 'Proveedor en',
            garantia: 'Garantía (meses) en',
            estados: 'Estado en',
            mantenimiento: '¿Incluir este elemento a un plan de mantenimiento? en',
            observaciones: 'Observaciones en',
            smartCode: 'Código en',
            tipoCuneta: 'Tipo de cuneta en',
            abInicio: 'Abscisa Inicio en',
            abInicioList: 'Abscisa inicial en',
            abFin: 'Abscisa Fin en',
            fullResta: 'Longitud (km) en',
            pInicio: 'PR Inicio en',
            pInicioList: 'PR inicial en',
            pFin: 'PR Fin en',
            latitudInicial: 'Latitud en',
            longitudInicial: 'Longitud en',
            latitudFinal: 'Latitud en',
            longitudFinal: 'Longitud en',
            createdAt: 'Fecha creación en',
            createdBy: 'Creado por en',
            updatedAt: 'Fecha actualización en',
            updatedBy: 'Actualizado por en',
            createdEn: 'Creado en en',
            updatedEn: 'Actualizado en en',
          }
        },
        tuneles: {
          name:'Elemento Túnel en |||| Elementos Túneles en',
          fields: {
            elementos: 'Elementos en',
            TNEL: 'Túnel en',
            sentido: 'Sentido en',
            clasificacion: 'Clasificación en',
            cobertura: 'Según cobertura máxima en',
            carriles: 'Número de carriles en',
            carrilesShow: 'Carriles en',
            unidadFuncional: 'Unidad Funcional en',
            tramo: 'Tramo en',
            segmento: 'Segmento en',
            abscisa: 'Abscisa en',
            pr: 'PR en',
            latitud: 'Latitud en',
            longitud: 'Longitud en',
            ano: 'Año en',
            area: 'Área (m²) en',
            diseno: 'Norma de diseño en',
            disenador: 'Diseñador en',
            informe: 'Informe de diseño en',
            anoa: 'Año en',
            areaa: 'Área (m²) en',
            disenoa: 'Norma de diseño en',
            disenadora: 'Diseñador en',
            informea: 'Informe de diseño en',
            observacionesa: 'Observaciones en',
            anob: 'Fecha del estudio en',
            consultor: 'Consultor en',
            estudio: 'Código del estudio en',
            nom_estudio: 'Nombre del estudio en',
            anoc: 'Fecha del estudio en',
            consultora: 'Consultor en',
            estudioa: 'Código del estudio en',
            nom_estudioa: 'Nombre del estudio en',
            propietario: 'Propietario en',
            vial: 'Administración vial en',
            proyectista: 'Proyectista en',
            opacidad: 'Opacidad de diseño (x10¯³ m¯¹) en ',
            concentracion: 'Concentración CO de diseño (ppm) en',
            existe: 'Existe variante en',
            long_km: 'Longitud variante (km) en',
            estadoa: 'Estado en',
            observacioness: 'Observaciones en',
            radio_min: 'Radio mínimo de curvatura (m) en',
            radio_max: 'Radio máximo de curvatura (m) en',
            ancho_carril: 'Ancho por carril (m) en',
            pend_longi: 'Pendiente longitudinal (%) en',
            radio_min_curva: 'Radio mínimo curvas verticales (m) en',
            radio_max_curva: 'Radio máximo curvas verticales (m) en',
            long_tunel: 'Longitud del túnel (m) en',
            galibo: 'Gálibo (m) en',
            ancho_anden: 'Ancho del andén (m) en',
            ancho_berma: 'Ancho berma (m) en',
            soporte: 'Sistema de soporte en',
            tipologia: 'Tipología de los portales en',
            observacioness: 'Observaciones en',
            codigo_soporte: 'Código en',
            codigo_soporteName: 'Revestimiento definitivo en',
            estado: 'Condición del elemento en',
            mot_remplazo: 'Motivo de reemplazo en',
            elemnto_remplazo: 'Elemento que reemplazo en',
            estados: 'Estado en',
            fecha: 'Fecha de instalación en',
            proveedor: 'Proveedor en',
            garantia: 'Garantía (meses) en',
            observaciones: 'Observaciones en',
            smartCode: 'Código en',
            abInicio: 'Abscisa Inicio en',
            abFin: 'Abscisa Fin en',
            fullResta: 'Longitud (km) en',
            pInicio: 'PR Inicio en',
            pFin: 'PR Fin en',
            createdAt: 'Fecha creación en',
            createdBy: 'Creado por en',
            createdEn: 'Creado en en',
            updatedEn: 'Actualizado en en',
            updatedAt: 'Fecha actualización en',
            updatedBy: 'Actualizado por en',
            clasificacionList: 'Tipo en',
            sentidoList: 'Margen en',
            sentidoB: 'Sentido en'
          }
        },
        elemento_contencions: {
          name: 'Elemento Contención  en|||| Elementos Contención en',
          fields: {
            tipoElemento: 'Tipo de Elemento en',
            margen: 'Margen en',
            sentido: 'Sentido en',
            tipo: 'Clasificación en',
            funcionalidad: 'Funcionalidad en',
            unidadFuncional: 'Unidad Funcional en',
            tramo: 'Tramo en',
            segmento: 'Segmento en',
            abscisa: 'Abscisa en',
            pr: 'PR en',
            latitud: 'Latitud en',
            longitud: 'Longitud en',
            largo: 'Largo (m) en',
            ancho: 'Ancho (m) en',
            altura: 'Altura (m) en',
            consultor: 'Consultor en',
            fechaEstudio: 'Fecha del estudio en',
            codigoEstudio: 'Código de estudio en',
            nombreEstudio: 'Nombre del estudio en',
            estado: 'Condición del elemento en',
            motivoReemplazo: 'Motivo reemplazo en',
            elementoReemplazo: 'Reemplazo en',
            estados: 'Estado en',
            fechaInstalacion: 'Fecha de instalación en',
            proveedor: 'Proveedor en',
            garantia: 'Garantía (meses) en',
            observaciones: 'Observaciones en',
            smartCode: 'Código en',
            abInicio: 'Abscisa Inicio en',
            abInicioList: 'Abscisa inicial en',
            abFin: 'Abscisa Fin en',
            pInicio: 'PR Inicio en',
            pInicioList: 'PR inicial en',
            pFin: 'PR Fin en',
            createdAt: 'Fecha creación en',
            createdBy: 'Creado por en',
            updatedAt: 'Fecha actualización en',
            updatedBy: 'Actualizado por en',
            createdEn: 'Creado en en',
            updatedEn: 'Actualizado en en',
            tipoList: 'Elemento en',
          }
        },
        elemento_pontons: {
          name: 'Elemento Pontones en |||| Elementos Pontones en',
          fields: {
            tipoElemento: 'Elemento en',
            margen: 'Margen en',
            sentidoB: 'Sentido en',
            tipoPonton: 'Tipo en',
            numeroCarriles: 'Número de carriles en',
            unidadFuncional: 'Unidad Funcional en',
            tramo: 'Tramo en',
            segmento: 'Segmento en',
            abscisa: 'Abscisa en',
            PR: 'PR en',
            latitud: 'Latitud en',
            longitud: 'Longitud en',
            ano: 'Año en',
            area: 'Área (m²) en',
            norma: 'Norma de diseño en',
            disenador: 'Diseñador en',
            info_di: 'Informe de Diseño en',
            anoa: 'Año en',
            areaa: 'Área (m²) en',
            normaa: 'Norma de diseño en',
            disenadora: 'Diseñador en',
            info_dia: 'Informe de Diseño en',
            obser_dia: 'Observaciones en',
            anob: 'Fecha Recolección Datos en',
            sentido: 'Dirección de abscisado en',
            estacion_conteo: 'Estación de Conteo en',
            inspector: 'Inspector en',
            inspeccion: 'Requisitos de Inspección en',
            numinspeccion: 'Número de Secciones de Inspección en',
            propietario: 'Propietario en',
            admon_vial: 'Administración Vial en',
            proyectista: 'Proyectista en',
            coeficiente: 'Coeficiente de aceleración sísmica (Aa) en',
            cauce: 'Paso por el cauce en',
            variante: 'Existe variante en',
            long_variante: 'Longitud variante (km) en',
            esta: 'Estado en',
            vehi_dise: 'Vehículo de diseño en',
            distri_carga: 'Clase distribución de carga en',
            capa_carga: 'Capacidad de carga (t) en',
            luz_critica: 'Longitud de luz crítica (m) en',
            fac_clasi: 'Factor de clasificación en',
            fue_cor: 'Fuerza cortante (t) en',
            momento: 'Momento (t.m) en',
            linea_carga: 'Línea de carga por rueda (t) en',
            obs_1: 'Observaciones en',
            pon_terra: 'Pontón en terraplén en',
            pon_c: 'Pontón en Curvatura/Tangente en',
            long_2: 'Longitud (m) en',
            ancho_2: 'Ancho losa superior (m) en',
            espe_2: 'Espesor losa superior (m) en',
            altu_2: 'Altura inferior (m) en',
            ancho_3: 'Ancho losa inferior (m) en',
            espe_3: 'Espesor losa inferior (m) en',
            pro_3: 'Profundidad del relleno (m) en',
            espe_4: 'Espesor de muros (m) en',
            sepa_4: 'Separación entre muros (m) en',
            estado: 'Condición del elemento en',
            motivoReemplazo: 'Motivo reemplazo en',
            elementoReemplazo: 'Reemplazo en',
            estados: 'Estado en',
            fecha: 'Fecha de instalación en',
            proveedor: 'Proveedor en',
            garantia: 'Garantía (meses) en',
            observaciones: 'Observaciones en',
            fullCodigo: 'Código en',
            abInicio: 'Abscisa Inicio en',
            abFin: 'Abscisa Fin en',
            fullResta: 'Longitud(km) en',
            pInicio: 'PR Inicio en',
            pFin: 'PR Fin en',
            createdAt: 'Fecha creación en',
            createdBy: 'Creado por en',
            updatedAt: 'Fecha actualización en',
            updatedBy: 'Actualizado por en',
            createdEn: 'Creado en en',
            updatedEn: 'Actualizado en en',
            Borrar: '¿Borrar? en',
            abInicio: 'Abscisa inicial en',
            pInicio: 'PR inicial en',
          }
        },
        puentes: {
          name: 'Elemento Puentes en |||| Elemento Puentes en',
          fields: {
            elementos: 'Elemento en',
            margen: 'Margen en',
            tipo: 'Tipo de Puente en',
            codigo:'Código en',
            carriles: 'Número de carriles en',
            unidad_funcional: 'Unidad Funcional en',
            tramo: 'Tramo en',
            segmento: 'Segmento en',
            abscisa: 'Abscisa en',
            PR: 'PR en',
            latitud: 'Latitud en',
            longitud: 'Longitud en',
            ingresoAbFin: 'Abscisa Inicio en',
            ingresoAbFin: 'Abscisa Fin en',
            pInicio: 'PR Inicio en',
            pFin: 'PR Fin en',
            years: 'Año en',
            area: 'Área (m²) en',
            norma_diseno: 'Norma de diseño en',
            disenador: 'Diseñador en',
            info_di: 'Informe de Diseño en',
            yearsb: 'Año en',
            areab: 'Área (m²) en',
            norma_disenob: 'Norma de diseño en',
            disenadorb: 'Diseñador en',
            info_dib: 'Informe de Diseño en',
            fecha: 'Fecha de Recolección de Datos en',
            inspector: 'Inspector en',
            inspeccion: 'Requisitos de Inspección en',
            direccion_abscisado: 'Dirección de abscisado en',
            estacion_conteo: 'Estación de Conteo en',
            numinspeccion: 'Número de Secciones de Inspección en',
            propietario: 'Propietario en',
            admon_vial: 'Administración Vial en',
            proyectista: 'Proyectista en',
            coe_sismo: 'Coeficiente de aceleración sísmica (Aa) en',
            paso_cauce: 'Paso por el cauce en',
            estado_op: 'Estado en',
            exis_variante: 'Existe variante en',
            long_km: 'Longitud variante (km) en',
            vehi_di: 'Vehículo de diseño en',
            distri_carga: 'Clase de Distribución de Carga en',
            capacidad: 'Capacidad de Carga (t) en',
            luz_critica: 'Longitud de luz Crítica (m) en',
            fac_clas: 'Factor de Clasificación en',
            fac_cort: 'Fuerza Cortante (t) en',
            momento: 'Momento (t.m) en',
            car_rue: 'Línea de Carga por Rueda (t) en',
            observacioness: 'Observaciones en',
            curva: 'Puente en Curvatura/Tangente en',
            terraplen: 'Puente en terraplé enn',
            esviajamiento: 'Esviajamiento (gra) en',
            luces: 'Número de luces en',
            luz_menor: 'Longitud luz menor (m en)',
            luz_mayor: 'Longitud luz mayor (m) en',
            long_total: 'Longitud total (m) en',
            ancho_tab: 'Ancho de tablero (m) en',
            area_tab: 'Área del tablero (m²) en',
            ancho_sep: 'Ancho del separador (m) en',
            ancho_anden_izq: 'Ancho del andén izquierdo (m) en',
            ancho_anden_der: 'Ancho del andén derecho (m) en',
            ancho_calzada: 'Ancho de calzada (m) en',
            ancho_bordillo: 'Ancho entre bordillos (m) en',
            alt_pilas: 'Altura de pilas (m) en',
            long_pilas: 'Longitud de apoyo en pilas (m) en',
            alt_estribos: 'Altura de estribos (m) en',
            long_estribos: 'Longitud de apoyo en estribos (m) en',
            dise_tipo: 'Diseño tipo en',
            material: 'Material en',
            transversarlCod: 'Código en',
            transversarl: 'Tipo de estructuración transversal en',
            longitudCod: 'Código en',
            longitudinal: 'Tipo de estructuración longitudinal en',
            car_max: 'Carga máxima en',
            velo_max: 'Velocidad máxima (km/h) en',
            galibo_maximo: 'Gálibo máximo (m) en',
            otra: 'Otra en',
            barandaCod: 'Código en',
            baranda: 'Detalles de baranda en',
            expantionCod: 'Código en',
            expantion: 'Junta de expansión en',
            proteccion_alta_tension: 'Protección en líneas de alta tensión en',
            tipo_acceso: 'Tipo de Acceso en',
            huella: 'Huella (cm) en',
            contrahuella: 'Contrahuella (cm) en',
            logitud_remapa: 'Longitud de rampa (m) en',
            pendiente: 'Pendiente (%) en',
            nro_tramos: 'Número de tramos en',
            tipoestribos: 'Tipo en',
            estriCod: 'Código en',
            estriMat: 'Material en',
            tipo_cimen: 'Tipo de cimentación en',
            tippilasCod: 'Código en',
            tippilas: 'Tipo en',
            forma_pilaCod: 'Código en',
            forma_pila: 'Forma en',
            cimen_pila: 'Tipo de cimentación en',
            estrisaCod: 'Código en',
            estrisa: 'Material en',
            apoyo_fijoCod: 'Código en',
            apoyo_fijo: 'Apoyos fijos sobre estribos en',
            pila_movilCod: 'Código en',
            pila_movil: 'Apoyos móviles sobre estribos en',
            pila_pilaCod: 'Código en',
            pila_pila: 'Apoyos fijos en pilas en',
            apoyo_movilCod: 'Código en',
            apoyo_movil: 'Apoyos móviles en pilas en',
            pila_vigasCod: 'Código en',
            pila_vigas: 'Apoyos fijos en viga ens',
            movil_vigasCod: 'Código en',
            movil_vigas: 'Apoyos móviles en vigas en',
            estado: 'Condición del elemento en',
            mot_remplazo: 'Motivo de reemplazo en',
            elemnto_remplazo: 'Elemento que reemplazo en',
            estados: 'Estado en',
            fecha: 'Fecha de instalación en',
            proveedor: 'Proveedor en',
            garantia: 'Garantía (meses) en',
            observaciones: 'Observaciones en',
            observacionesDetalles: 'Detalles y observaciones en',
            createdAt: 'Fecha creación en',
            createdBy: 'Creado por en',
            updatedAt: 'Fecha actualización en',
            updatedBy: 'Actualizado por en',
            createdEn: 'Creado en en',
            updatedEn: 'Actualizado en en',
          }
        },
        otros_elementos: {
          name: 'Otro Elemento |||| Otros Elementos en',
          fields: {
            tipoElemento: 'Elemento en',
            margen: 'Margen en',
            sentido: 'Sentido en',
            unidadFuncional: 'Unidad Funcional en',
            tramo: 'Tramo en',
            segmento: 'Segmento en',
            abscisa: 'Abscisa en',
            PR: 'PR en',
            latitud: 'Latitud en',
            longitud: 'Longitud en',
            interseccion: 'Clase de intersección en',
            material: 'Material en',
            flujo_peatonal: 'Flujo peatonal en',
            clase_via_intersecta: 'Clase de vía intersecta en',
            tipo_via_intersecta: 'Tipo de vía intersecta en',
            reductores_velocidad: 'Reductores de velocidad vía intersecta en',
            largo: 'Largo (m) en',
            carriles: 'No. Carriles en',
            estado: 'Condición del elemento en',
            motivo: 'Motivo del reemplazo en',
            elementoReemplazo_id: 'Elemento que Reemplazo en',
            estados: 'Estado en',
            fecha_instalacion: 'Fecha de instalación en',
            proveedor: 'Proveedor en',
            garantia: 'Garantía (meses) en',
            observaciones: 'Observaciones en',
            sentidoCarac: 'Sentido en',
            fullCodigoConsecutivo: 'Código en',
            smartCode: 'Código en',
            abInicio: 'Abscisa Inicio en',
            abFin: 'Abscisa Fin en',
            fullResta: 'Longitud (km) en',
            pInicio: 'PR Inicio en',
            pFin: 'PR Fin en',
            latitudShow: 'Latitud en',
            longitudShow: 'Longitud en',
            createdAt: 'Fecha creación en',
            createdBy: 'Creado por en',
            updatedAt: 'Fecha actualización en',
            updatedBy: 'Actualizado por en',
            createdEn: 'Creado en en',
            updatedEn: 'Actualizado en en',
          }
        },
        redes: {
          fields: {
            tipoElemento: 'Elemento en',
            estado: 'Condicion del elemento en ',
            motivoReemplazo: 'Motivo de reemplazo en',
            elementoReemplazo: 'Elemento que reemplazo en',
            estados: 'Estado en', 
            fecha: 'Fecha de instalacion en', 
            proveedor: 'Proveedor en',
            unidadFuncional:'Unidad Funcional en',
            tramo: 'Tramo en',  
            abscisa: 'Abscisa en',
            fullCodigo: 'Código en',  
            Borrar: '¿Borrar? en',
            margen: 'Margen en',
            sentidoB: 'Sentido en',
            segmento: 'Segmento en',
            latitud: 'Latitud en',
            longitud: 'Longitud en',
            entornoVia:' Entorno de la vía en',
            claseIluminacion:'Clase de iluminación en',
            tipoLampara:'Tipo de lámpara en',
            brazoTipo:'Brazo tipo en',
            disposicionIluminaria:'Disposición de las luminarias en',
            garantiaMeses: 'Garantía (meses) en',
            mantenimiento: '¿Incluir este elemento a un plan de mantenimiento? en',
            observaciones: 'Observaciones en',
            empresa: 'Empresa en',
            diametro: 'Diámetro (m) en',
            longitudM: 'Longitud (m) en',
            altura:'Altura (m) en',
            largo:'Largo (m) en',
            ancho:'Ancho (m) en',
            base:'Base (m) en',
            capacidadT:'Capacidad (t) en',
            largoDiametro:'Largo/Diámetro (m) en',
            marca: 'Marca en',
            angulo:'Ángulo de inclinación del brazo (°) en',
            luminancia:'Valor de luminancia promedio  (cd/m²) en',
            anchoLuminoso:'Ancho del módulo luminoso (m) en',
            largoLuminoso:'Largo del módulo luminoso (m) en',
            material: 'Material en',
            tipo:'Tipo en',
            seccion: 'Sección en',
            nivelTraficoDetalles: 'Nivel de Tráfico en',
            pr:'PR en',
            clase:'Clase en',
            clasificacionSoporte:'Clasificación de los soportes en',
            codigo:'Código en',
            createdAt: 'Fecha creación en',
            createdBy: 'Creado por en',
            updatedAt: 'Fecha actualización en',
            updatedBy: 'Actualizado por en',
            createdEn: 'Creado en en',
            updatedEn: 'Actualizado en en',
          }
        },
        inventariopeajes: {
          name: 'Inventario Peaje en |||| Inventarios Peajes en',
          fields: {
            nombre: 'Nombre de  Peaje en',
            codigo: 'Código en',
            estacion_manual: 'N° Estaciones manuales en',
            estacion_manual_show: 'Estaciones manuales en',
            estacion_automatica: 'Estaciones automáticas en',
            estacion: 'Total Estaciones en',
            unidad_funcional: 'Unidad Funcional en',
            tramo: 'Tramo en',
            segmento: 'Segmento en',
            Abscisa: 'Abscisa en',
            Latitud: 'Latitud en',
            Longitud: 'Longitud en',
            createdAt: 'Fecha creación en',
            createdBy: 'Creado por en',
            updatedAt: 'Fecha actualización en',
            updatedBy: 'Actualizado por en',
            estado: 'Estado en',
            absciaShow: 'Abscisa (km) en',
          }
        },
        mensuals: {
          name: 'Tránsito Histórico en |||| Tránsito Histórico en',
          fields: {
            inventario_peajes: 'Peaje en',
            sentido: 'Sentido en',
            ano: 'Año en',
            mes: 'Mes en',
            categoria_a: 'Categoría I en',
            categoria_b: 'Categoría II en',
            categoria_c: 'Categoría III en',
            categoria_d: 'Categoría IV en',
            categoria_e: 'Categoría V en',  
            categoriaA: 'Cat. I en',
            categoriaB: 'Cat. II en',
            categoriaC: 'Cat. III en',
            categoriaD: 'Cat. IV en',
            categoriaE: 'Cat. V en',            
            categoria_aShow: 'Categoría I: en',
            categoria_bShow: 'Categoría II: en',
            categoria_cShow: 'Categoría III: en',
            categoria_dShow: 'Categoría IV: en',
            categoria_eShow: 'Categoría V: en',           
            codigo: 'Código en',
            Unidad_Funcional: 'Unidad Funcional en',
            Tramo: 'Tramo en',
            Segmento: 'Segmento en',
            Abscisa: 'Abscisa en',
            año: 'Por año consolidado en',
            categoria: 'Por categoría en',
            desde: 'Desde en',
            hasta: 'Hasta en',
            Transito: 'Tránsito en',
            TPM: 'TPM en',
            historico: 'Histórico en',
            createdAt: 'Fecha creación en',
            createdBy: 'Creado por en',
            updatedAt: 'Fecha actualización en',
            updatedBy: 'Actualizado por en',
          }
        },
        estacion_conteos: {
          name: 'Estación de conteo en |||| Estaciones de conteo en',
          fields: {
            nombre: 'Nombre en',
            unidad_funcional: 'Unidad Funcional en',
            tramo: 'Tramo en',
            segmento: 'Segmento en',
            abscisa: 'Abscisa en',
            latitud: 'Latitud en',
            longitud: 'Longitud en',
            categoria:'Categoría en',
            consultor: 'Consultor en',
            informe: 'Informe en',
            date: 'Fecha de estudio en',
            abscisaInicial: 'Abscisa inicial en',
            abscisaFinal: 'Abscisa final en',
            operativa:'Operativa en',
            direccional: 'Factor direccional en',
            carril: 'Factor carril en',
            years: 'Año en',
            factorCamionPonderado: 'Factor camión ponderado en',
            c2p:'C₂P en',
            c2_p:'C2-p en',
            c2g:'C₂G en',
            c2_g:'C2-g en',
            c3yc4:'C₃yC₄ en',
            c3c4:'C3-C4 en',
            c5:'C₅ en',
            c52:'>C₅ en',
            tpd:'TPD en',
            esal1: 'ESAL (Proyección 5 años) en',
            esal2: 'ESAL (Proyección 10 años) en',
            esal3: 'ESAL (Proyección 20 años) en',
            tpdactual: 'TPD actual en',
            tasacrecimiento: 'Tasa de crecimiento en',
            valores:'Valores en',
            datosTotal:'Datos total en',
            years1: 'Desde en',
            years2: 'Hasta en',
            createdAt: 'Fecha creación en',
            createdBy: 'Creado por en',
            updatedAt: 'Fecha actualización en',
            updatedBy: 'Actualizado por en',
          },
          input:{
            fechaEstudio:'La fecha no puede ser mayor a la actual en',
            tasaCrecimientoMenor:'El número ingresado debe ser menor a 100 en',
            tasaCrecimientoMayor:'El número ingresado debe ser mayor a -100 en',
          }
        },
        indicadores_cargar_informacions: {
          name: 'Carga de Información en|||| Carga de Información en',
          title:'Visualizar Indicadores en',
          cerrar:'Cerrar en',
          visualizacion:'Visualización en',
          detalle:'Detalles en',
          fields: {
            indicador: 'Indicador en',
            unidadFuncional: 'Unidad Funcional en',
            tramo: 'Tramo en',
            abInicios: 'ABSCISA INICIAL en',
            abFins: 'ABSCISA FINAL en',
            abini: 'Abscisa Inicial en',
            abfin: 'Abscisa Final en',
            fechaMedicion: 'Fecha de Medición en',
            medidoPor: 'Medido Por en',
            estado: 'Estado en',
            tipoMedicion: 'Tipo de medición en',
            archivoCarga: 'Related files en',
            AbscisaInicio: 'Abscisa Inicio en',
            AbscisaFin: 'Abscisa Fin en',
            VisualizarIndicadores: 'Visualizar Indicadores en',
            tiempoAtencion:'Tiempo de atención en',
            ocupacionCarril:'Ocupación del carril en',
            disponibilidadVia:'Disponibilidad vía en',
          }
        },
        calificacion_puentes: {
          name: 'Calificación Puentes en |||| Calificación Puentes en',
          fields: {
            smartCode: 'Consecutivo de medición en',
            puentes: 'Puente en',
            fechaInicioInspeccion: 'Fecha inicio inspección en',
            nombreInspector: 'Inspector en',
            nombreReporteInspector: 'Nombre de reporte de inspección en',
            fechaReporteInspeccion: 'Fecha reporte inspección en',
            medidoPor: 'Medido por en',
            estado: 'Estado en',
            tipoMedicion: 'Tipo de medición en',
            cumplimiento: 'Cumplimiento en',
            unidadFuncional: 'Unidad Funcional en',
            tramo: 'Tramo en',
            medicion: 'Medición en',
            activo:'Activo en',
            inactivo:'Inactivo en',
          }
        },
        disponibilidad_vias: {
          name: 'Disponibilidad de Vía en |||| Disponibilidad Vías en',
          fields: {
            consecutivo: 'Consecutivo en',
            fecha: 'Fecha en',
            tipoEvento: 'Tipo de Evento en',
            tipoCierre: 'Tipo de Cierre en',
            unidadFuncional: 'Unidad Funcional en',
            AbscisaInicial: 'Abscisa Inicial en',
            AbscisaFinal: 'Abscisa Final en',
            margen: 'Margen en',
            lugar: 'Lugar en',
            longitudAfect: 'Longitud Afectada (km) en',
            segmentosAfect: 'Segmentos Afectados en',
            segmentosAfectId: 'Segmentos Afectados en',
            latitud: 'Latitud en',
            longitud: 'Longitud en',
            fechaReporte: 'Fecha de Reporte en',
            horaReporte: 'Hora de Reporte en',
            fechaSenal: 'Fecha de Señalización en',
            horaSenal: 'Hora de Señalización en',
            tiempSenal: 'Tiempo de Señalización en',
            horaFinal: 'Hora Finalización en',
            fuenteRep: 'Fuente del Reporte en',
            causa: 'Causa en',
            elementoAfect: 'Elemento Afectado en',
            observaciones: 'Observaciones en',
            serviciosDirectorio: 'Directorio en',
            grupoEmergencias: 'Grupo en',
            sistema: 'Sistema en',
            grupo: 'Grupo en',
            servElemento: 'Elemento en',
            longitudCola: 'Longitud de Retención o Cola (m) en',
            indicadorO2: 'Indicador O2 en',
            observacionesTrabajos: 'Observaciones Retención en',
            volumenDerrumbe: 'Volumen del Derrumbe (m³) en',
            ElementoAfectado: 'Elemento Afectado en',
            observacionesDerrumbe: 'Observaciones en',
            tipoIncidenteOtro: 'Tipo de Incidente en',
            tipoVehiculoOtro: 'Tipo de Vehículo en',
            observacionesOtro: 'Observaciones en',
            tipoAccidente: 'Tipo de Accidente en',
            CodigoCausa: 'Código Causa en',
            CausaProbable: 'Causa Probable en',
            clasePolicia: 'Clase en',
            cantidadHeridos: 'Cantidad en',
            tipoHeridos: 'Tipo en',
            generoHeridos: 'Género en',
            edadHeridos: 'Edad en',
            totalHeridos: 'Heridos Totales en',
            cantidadVictimasf: 'Cantidad en',
            tipoVictimasf: 'Tipo en',
            generoVictimasf: 'Género en',
            edadVictimasf: 'Edad en',
            totalVictimasFatales: 'Víctimas Fatales Totales en',
            cantidadVehiculos: 'Cantidad en',
            categoriaVehiculos: 'Categoría en',
            tipoVehiculos: 'Tipo en',
            marcaVehiculos: 'Marca en',
            lineaVehiculos: 'Línea en',
            modeloVehiculos: 'Modelo en',
            placaVehiculos: 'Placa en',
            colorVehiculos: 'Color en',
            observacionesAccidente: 'Observaciones en',
            ambulancia: 'Para registrar la fecha y hora de ambulancia active el botón en',
            fechaDespeje: 'Fecha de Cierre en',
            horaDespeje: 'Hora de Cierre en',
            diasDespeje: 'Duración del evento (Días) en',
            tiempoDespejeFin: 'Duración del evento (h:mm) en',
            observacionesDespeje: 'Observaciones Finalización en',
            fechaFinalizacion: 'Fecha Finalización en',
            horaFinalizacion: 'Hora Finalización en',
            tiempoServicio: 'Tiempo Servicio en',
            fechaLlegada: 'Fecha de Llegada en',
            horaLlegada: 'Hora de Llegada en',
            tiempoRespuesta: 'Tiempo Servicio en',
            indicadorO5: 'Indicador O5 en',
            fecha_puestaservicio: 'Fecha de Puesta en Servicio en',
            diasCierre: 'No. Días Con Cierre en',
            indicadorE17: 'Indicador E17 en',
            observacionesCierre: 'Observaciones Cierre en',
            createdAt: 'Fecha creación en',
            createdBy: 'Creado por en',
            updatedAt: 'Fecha actualización en',
            updatedBy: 'Actualizado por en',
            tiempoFinalizacion: 'Tiempo Respuesta en',
            finalizar: 'Finalizó en',
            yearact: 'Año en',
            mesact: 'Mes en',
            fechaAmbulancia:'Fecha llegada ambulancia en',
            tiempoRespuestaAmbulancia:'Tiempo de respuesta ambulancia en',
            grua:'Para registrar la fecha y hora de grúa active el botón en',
            fechaGrua:'Fecha llegada grúa en',
            tiempoRespuestaGrua:'Tiempo de respuesta grúa en',
          }
        },
        parametros_analises: {
          name: 'Parámetros de planeación en |||| Parámetros de planeación en',
          fields: {
            unidad_funcional: 'Unidad Funcional en',
            plan: 'Plan de mantenimiento en',
            num_analisis: 'Número de años de análisis en',
            years1: 'Año de evaluación en',
            descripcion: 'Descripción del escenario en',
            tasa_crecimiento: 'Tasa crecimiento tránsito en',
            tasa_interes: 'Tasa de interés en',
            coin: 'Moneda en',
            per_analisis: 'Costo de reparación estructural en',
            nom_analisis: 'Costo de reparación de la capa superficial en',
            years2: 'Hasta en',
            createdAt: 'Fecha creación en',
            createdBy: 'Creado por en',
            updatedAt: 'Fecha actualización en',
            updatedBy: 'Actualizado por en',
          }
        },
        politicas_mantenimientos: {
          name: 'Política de Mantenimiento en |||| Políticas de Mantenimiento en',
          fields: {
            nombre: 'Nombre en',
            unidadFuncional: 'Unidad Funcional en',
            sistema: 'Sistema en',
            FechaIniPeriodicidad: 'Fecha de Inicio de Periodicidad en',
            sistema: 'Sistema en',
            fecha: 'Fecha en',
            unidadMedida: 'Unidad en',
            periocidad: 'Periodicidad en',
            valorU: 'Valor Unitario en',
            fechaU: 'Fecha del Valor Unitario en',
            fuente: 'Fuente en',
            descripcion: 'Descripción en',
            prolVidaUtil: 'Vida Útil en',
            operacion: 'Tipo de Operación en',
            tipoElemento: 'Tipo de Elemento en',
            Valor_U: 'Valor U. en',
            Fecha_U: 'Fecha U. en',
            nombrePolitica: 'Nombre de la Política en',
            createdAt: 'Fecha creación en',
            createdBy: 'Creado por en',
            updatedAt: 'Fecha actualización en',
            updatedBy: 'Actualizado por en',
            SeRealiza: 'Se Realiza en',
            Politica: 'Política en',
            Clonar: 'Clonar en',
          },
        },
        politicas_mantenimiento_particulars: {
          name: 'Políticas de Mantenimiento Particular en |||| Crear Política de Mantenimiento Particular en',
          fields: {
            nombre: 'Nombre de la Política en',
            sistema: 'Sistema en',
            tipoElemento: 'Nombre en',
            tipoElementoSenal: 'Nombre en',
            unidadFuncional: 'Unidad Funcional en',
            tramo: 'Tramo en',
            abInic: 'Abscisa Inicial en',
            abFin: 'Abscisa Final en',
            codigoConsecutivo: 'ID en Inventario en',
            nombrePoliticaString: 'Nombre de la Política en',
            nombrePolitica: 'Nombre de la Política ID en',
            periocidad2: 'Periodicidad en',
            valorU2: '"Valor Unitario en',
            fechaU2: 'Fecha del Valor Unitario en',
            fuente2: 'Fuente en',
            fecha2: 'Fecha de Inicio de Periodicidad en',
            prolVidaUtil2: 'Prol. Vida Util en',
            descripcion2: 'Descripción en',
            periocidad: 'Periodicidad en',
            valorU: '"Valor Unitario en',
            fechaU: 'Fecha del Valor Unitario en',
            fuente: 'Fuente en',
            fecha: 'Fecha de Inicio de Periodicidad en',
            prolVidaUtil: 'Prol. Vida Util en',
            VidaUtil: 'Vida Util en',
            descripcion: 'Descripción en',
            createdAt: 'Fecha creación en',
            createdBy: 'Creado por en',
            updatedAt: 'Fecha actualización en',
            updatedBy: 'Actualizado por en',
            tipoElementoShow: 'Elemento en',
            tipoElementoShowTable: 'Tipo de Elemento en',
            Clonar: 'Clonar en',
          }
        },
        trayectorias: {
          name: 'Alternativas en |||| Alternativas en',
          fields: {
            unidad_funcional: 'Unidad Funcional en',
            trafico: 'Limite de tránsito en',
            nombre: 'Nombre de la alternativa en',
            precio: 'Precio total ($/m²) en',
            preciocar: 'Precio carpeta ($/m²) en',
            vida: 'Vida esperada (años) en',
            espesor: 'Espesor removido (mm) en',
            createdAt: 'Fecha creación en',
            createdBy: 'Creado por en',
            updatedAt: 'Fecha actualización en',
            updatedBy: 'Actualizado por en',
            clonar: 'Clonar en'
          }
        },
        modelo_costos_pers: {
          name: 'Reparaciones Menores en |||| Reparaciones Menores en',
          fields: {
            areaSegmento: 'Área del segmento m² en',
            unidadFuncional: 'Unidad Funcional en',
            createdAt: 'Fecha creación en',
            createdBy: 'Creado por en',
            updatedAt: 'Fecha actualización en',
            updatedBy: 'Actualizado por en',
            variableAFlex: 'Variable A en',
            variableBFlex: 'Variable B en',
            variableXFlex: 'Variable X en',
            variableYFlex: 'Variable Y en',
          }
        },
        pers_exports: {
          unidadSave: 'Código Unidad Funcional en',
          parAnalisis: 'Parámetros de analisis en',
          persImport: 'Documento base importado en',
          unidad_funcional: 'Código en',
          year: 'Año en',
          unidadFuncional: 'Unidad Funcional en',
          version: 'Versión en',
          document: 'Documento en',
        },
        pers_imports: {
          name: 'Importación de PERS en |||| Importación de PERS en',
          fields: {
            unidadFuncional: 'Unidad Funcional en',
            persExport: 'Archivo de referencia de Exportación en',
            fecha: 'Fecha en',
            archivoCarga: 'Archivos relacionados en',
            fechaImp: 'Fecha de importación en',
            document: 'Documento en',
            cargarArchivo:'Cargar Archivo en',
          }
        },
        costos: {
          name: 'Costos en |||| Costos en',
          indicarElementos:'Debe indicar elementos para el registro de costos. en',
          seleccioneElementos:'Seleccione los elementos para el registro de costos en',
          definirCosto:'¿Definir Costo Global? en',
          globalNoDisponible:'El costo global no está disponible si indica un costo en los valores predeterminados. en',
          sinDiligenciar:'Dejar sin diligenciar si no se requiere. en',
          paso3:'Los datos serán validados en el paso 3. en',
          diligenciarDatos:'Diligenciar Datos en',
          seleccionarElementos:'Seleccionar Elementos en',
          valoresPredeterminados:'Indicar Valores Predeterminados en',
          unElemento:'Seleccione al menos un elemento en',
          elementosADiligenciar:'Debe seleccionar elementos a diligenciar para poder realizar un registro de costos en',
          completeAsistente:'Complete el asistente para realizar el registro en',
          seleccioneFiltro:'Debe seleccionar filtros válidos para diligenciar las políticas particulares de los elementos correspondientes. en',
          algunosElementos:'Algunos elementos no corresponden con los filtros o con las validaciones de su tipo de dato. en',
          noCrear:'No se puede crear el registro si no se han cargado elementos válidos. en',
          valorItem:'El valor del ítem debe ser positivo y es obligatorio. en',
          obsObligatoria:'La observación es obligatoria en',
          codigoRepetido:'El codigo esta repetido. en',
          noCrear2:'El registro no puede crearse hasta que se complete la validación de los elementos. en',
          fields: {
            elementos:'Elementos en',
            unidadFuncional: 'Unidad Funcional en',
            tramo: 'Tramo en',
            segmento: 'Segmento en',
            abInicio: 'Abscisa Inicial en',
            abFin: 'Abscisa Final en',
            sistema: 'Sistema en',
            tipoElementoSenal: 'Elemento en',
            tipoSenal: 'Tipo de Elemento en',
            tipoElemento: 'Elemento en',
            isMasiveLoading: 'Carga Masiva en',
            valorGlobal: 'Suma Global en',
            tipoMantenimiento: 'Tipo de Mantenimiento en',            
            fechaMantenimiento: 'Fecha del Mantenimiento en',
            costoMantenimiento: 'Costo del Mantenimiento en',
            proveedor: 'Proveedor en',
            calificacion: 'Calificación en',
            observaciones: 'Observaciones en',
            codigo: 'Código en Inventario en',
            genericTipoElemento: 'Elemento en',
            costoTotal: 'Costo Total en',
            createdAt: 'Fecha creación en',
            createdBy: 'Creado por en',
            updatedAt: 'Fecha actualización en',
            updatedBy: 'Actualizado por en',
            fechaInicial: 'Fecha Inicial en',
            fechaFinal: 'Fecha Final en',
            consolidaror: 'Consolidar Por en',
            id: 'ID en',
            fecha: 'Fecha en',
            sinResultados:'Sin resultados en',
            registroCostos:'Registro de costos en',
            pasoAnterior:'ir al paso anterior en',
          }
        },
        flujo_cajas: {
          name: 'Flujo de Caja en |||| Flujos de Caja en',
          generarExcel:'Generar excel en',
          generarExcel2:'¿Generar Excel? en',
          generarArchivo:'¿Generar archivo de Excel para la Matriz? en',
          borrarMatriz:'Borrar Matriz? en',
          fields: {
            unidadFuncional: 'Unidad Funcional en',
            nombrePolitica: 'Nombre de Políticas en',
            tasaInteres: 'Tasa de Interés en',
            fechaInicio: 'Fecha de Inicio del Cálculo en',
            years: 'Periodicidad en',
            id: 'ID en',
            createdAt: 'Fecha creación en',
            createdBy: 'Creado por en',
            updatedAt: 'Fecha actualización en',
            updatedBy: 'Actualizado por en',
            fecha: 'Fecha en',
            status: 'Status en',
            acciones: 'Acciones en',
          }
        },
        indicador_complementarios: {
          name: 'Conf. Ind. Complementario en |||| Conf. Ind. Complementarios en',
          fields: {
            nombre: 'Nombre indicador en',
            estado: 'Estado en',
            umbralFallo: 'Umbral de fallo en',
            proyecto: 'Proyecto en',
            sistema: 'Sistema en',
            tipoElemento: 'Elemento en',
            tipoElementoSenal: 'Elemento en',
            tipoSenal: 'Tipo de elemento en',

            pregunta: 'Pregunta en',
            peso: 'Peso en',
            tipoCampo: 'Tipo Campo en',
            decimales: 'Precisión decimales en',
            respuesta: 'Respuesta en',
            rangoInicial: 'Rango inicia enl',
            rangoFinal: 'Rango final en',
            respuestasMult: 'Respuestas Múltiples en',
          }
        },
        respuesta_evaluacion_complementarios: {
          name: 'Indicador Complementario en |||| Indicadores Complementarios en',
          fields: {
            unidadFuncional: 'Unidad Funcional en',
            estado:'Estado en',
            aprobacion: 'Aprobación en',
            tramo: 'Tramo en',
            segmento: 'Segmento en',
            sistema: 'Sistema en',
            tipoElemento: 'Tipo elemento en',
            codigo:'Código en',
            codigoElemento:'Código elemento en',
            tipoElementoSenal: 'Tipo elemento en',
            elemento: 'Elemento en',
            fechaMedicion: 'Fecha de medición en',
            accion: 'Acción en',
            observaciones: 'Observaciones en',
            indicadores: 'Indicadores en',
            pregunta: 'Pregunta en',
            respuesta: 'Respuesta en',
            peso:'Peso en',
            pesoTotal:'Peso total en',
            uFallo:'Umbral de fallo en',
            estadoVal:'Estado de validación en',
            smartCode: 'Código en',
            createdAt: 'Fecha creación en',
            createdBy: 'Creado por en',
            updatedAt: 'Fecha actualización en',
            updatedBy: 'Actualizado por en',
          }
        },
        registro_novedads: {
          name: 'Configuración de Novedad en |||| Configuración de Novedades en',
          fields: {
            nombre: 'Nombre novedad en',
            estado: 'Estado en',
            umbralFallo: 'Umbral de fallo en',
            proyecto: 'Proyecto en',
            sistema: 'Sistema en',
            tipoElemento: 'Elemento en',
            tipoElementoSenal: 'Elemento en',
            tipoSenal: 'Tipo de elemento en',

            pregunta: 'Pregunta en',
            peso: 'Peso en',
            tipoCampo: 'Tipo Campo en',
            decimales: 'Precisión decimales en',
            respuesta: 'Respuesta en',
            rangoInicial: 'Rango inicial en',
            rangoFinal: 'Rango final en',
            respuestasMult: 'Respuestas Múltiples en',
          }
        },
        respuesta_evaluacion_novedads: {
          name: 'Reg. Novedad-Medición en |||| Reg. Novedades-Mediciones en',
          fields: {
            sistema: 'Sistema en',
            tipoElemento: 'Tipo elemento en',
            tipoElementoSenal: 'Tipo elemento en',
            tipoRegistro: 'Tipo de Registro en',
            unidadFuncional: 'Unidad Funcional en',
            tramo: 'Tramo en',
            segmento: 'Segmento en',
            elemento: 'Elemento en',
            abscisaIni: 'Abscisa en',
            abscisaFin: 'Abscisa en',
            latitudInicial: 'Latitud en',
            latitudFinal: 'Latitud en',
            longitudInicial: 'Longitud en',
            longitudFinal: 'Longitud en',
            deterioro: 'Deterioro en',
            fechaRegistro: 'Fecha de registro en',
            severidad: 'Severidad en',
            longitud: 'Longitud (m) en',
            ancho: 'Ancho (m) en',
            areaAfectada: 'Área afectada (m²) en',
            estado: 'Estado en',
            aprobacion: 'Aprobación en',
            modelo: 'Modelo en',
            codigo: 'Código en',
            retroreflectColorPrincipal: 'Retro-reflectividad color principal (cd/lux/m²) en',
            disminucionPrincipal:'Disminución color principal (%) en',
            retroreflectColorSecundario: 'Retro-reflectividad color secundario (cd/lux/m²) en',
            disminucionSecundario:'Disminución color secundario (%) en',
            posicion: 'Posición en',
            legibilidad: 'Legibilidad en',
            novedad:'Novedad en',
            novedades: 'Novedades en',
            pregunta: 'Pregunta en',
            respuesta: 'Respuesta en',
            genericTipoElemento: 'Elemento en',
            observaciones: 'Observaciones en',
            indicadores:'Indicadores en',
            pesoTotal:'Peso total en',
            uFallo:'Umbral de fallo en',
            estadoVal:'Estado de validación en',
            smartCode: 'Código Elemento en',
            createdAt: 'Fecha creación en',
            createdBy: 'Creado por en',
            updatedAt: 'Fecha actualización en',
            updatedBy: 'Actualizado por en',
          }
        },
        aprobacions: {
          name: 'Aprobación en |||| Aprobaciones en',
          title:'Lista de aprobacion en',
          fields: {
            unidadFuncional: 'Unidad Funcional en',
            tipoRegistro: 'Tipo de Registro en',
            aprobacion: 'Aprobación en',
            tipoRegistro: 'Tipo en',
            actividad: 'Actividad en',
            fecha: 'Fecha en',
            unidadFuncional: 'Unidad Funcional en',
            usuario: 'Usuario en',
          }
        },
        carga_masivas: {
          name: 'Carga masiva en |||| Cargas masivas en',
          fields: {
            nombreGrupo: 'Nombre de grupo en',
            cargados: 'Cargados en',
            enProceso: 'En Proceso en',
            proyectoList: 'Proyecto: en',
            rutaList: 'Ruta: en',
            unidadFuncional: 'Unidad Funcional: en',
            tramoList: 'Tramo: en',
            sistemaList: 'Sistema: en',
            subSistema: 'Sub sistema en',
            sistemaEstrucCont: 'Sistema: Estructuras de contención en',
            sistemaPuentes: 'Sistema: Puentes en',
            sistemaPontones: 'Sistema: Pontones en',
            sistemaTuneles: 'Sistema: Túneles en',
            imagenes: 'Imagenes: en',
            nombreGrupo: 'Nombre Grupo en',
            proyecto: 'Proyecto en',
            ruta: 'Ruta en',
            unidadSave: 'Unidad Funcional en',
            tramo: 'Tramo en',
            sistema: 'Sistema en',
            arrastrarSoltar:'Arrastrar y soltar archivos o hacer clic para seleccionar archivos en',
          }
        },
        validaciones:{
          fechaFutura:'La fecha indicada no puede ser futura. en',
          fechaAPartir:'La fecha debe ser a partir del año 2000. en',
          abscisaInicial:'La Abscisa inicial debe estar entre. en',
          abscisaInicialDebeSerMenor:'La Abscisa Inicial debe ser menor a la Abscisa Final. en',
          abscisaFinal:'La Abscisa Final debe estar entre. en',
          abscisaFinalDebeSerMayor:'La Abscisa Final debe ser mayor a la Abscisa inicial. en',
          enteroPositivo:'El número debe ser un entero positivo. en',
          max10:'Máximo 10 caracteres. en',
          max15:'Máximo 15 caracteres. en',
          max40:'Máximo 40 caracteres. en',
          max20:'Máximo 20 caracteres. en',
          max50:'Superado maximo de caracteres: 50 max. en',
          max90:'El valor máximo debe ser 90. en',
          min90:'El valor mínimo debe ser -90. en',
          mayor90:'El valor ingresado debe mayor a -90. en',
          max180:'El valor máximo debe ser 180. en',
          min180:'El valor mínimo debe ser -180. en',
          max200:'Máximo 200 caracteres. en',
          edadEntre:'La Edad  debe estar entre 0 y 122. en',
          cantidadEntre:'La cantidad debe estar entre 0 y 100. en',
          sinAcciones:'Sin Acciones Disponibles. en',
          millardos:'El costo no debe ser 100 millardos o más. en',
          fechaInicio:'Fecha de inicio no puede ser mayor que fecha final (Revise la fecha de inicio). en',
          planComenzar:'El plan debe comenzar al menos hoy. (Revise la fecha de inicio). en',
          planComenzar2:'El plan debe comenzar al menos hoy. en',
          entero:'Debe ser un número entero. en',
          coordenadaValida:'Ingrese un coordenada valida. en',
          distanciaAbscisa:'La distancia entre las Abscisas de ser menor a 1.999. en',
          altitudMaxima:'La altitud máxima debe ser mayor a la altitud mínima. en',
          decimales1:'El valor ingresado solo puede tener 1 decimal. en',
          decimales2:'El valor ingresado solo puede tener 2 decimales. en',
          decimales3:'El valor ingresado solo puede tener hasta 3 decimales. en',
          distanciaPr:'La distancia entre los Pr debe ser menor a 1.999. en',
          distanciaPrMenor:'La distancia entr los Pr deber ser de al menos  de 1.000. en',
          rangoEntre:'El rango debe estar entre. en',
          mayorK9:'El valor ingresado no puede ser mayor a K999+999. en',
          abscisaFin:'Abscisa fin debe ser mayor a Abscisa inicio. en',
          mayorPR9:'El valor ingresado no puede ser mayor a PR999+999. en',
          prFin:'PR fin debe ser mayor a PR inicio. en',
          coordenadasDecimal:'El valor ingresado en las coordenadas debe ser decimal. en',
          decimal:'El valor ingresado debe ser decimal. en',
          latitudMaxima:'La latitud máxima debe ser mayor a la latitud mínima. en',
          longitudMaxima:'La longitud máxima debe ser mayor a la longitud mínima. en',
          datoDigitado:'El dato digitado ya se encuentra en uso. en',
          correoInvalido:'Correo Invalido. en',
          correoEnUso:'El dato registrado ya se encuentra en uso. en',
          anioIgual:'Los años no pueden ser iguales. en',
          anioMayorFinal:'El año inicial no puede ser mayor al año final. en',
          mayor_a_0:'El numero ingresado debe ser mayor a 0. en',
          mayor_a_10:'El valor debe ser mayor a 10. en',
          menor_a_5:'El numero ingresado debe ser menor a 5. en',
          menor_a_30:'El valor debe ser menor a 30. en',
          umbralSumatoria:'El umbral de fallo, no puede ser mayor a la sumatoria del peso de las preguntas. en',
          pesoRespuesta:'El peso de la respuesta, no puede ser mayor al valor del peso de la pregunta No. en',
          noIntersectando:'El número no se está intersectando. en',
          noNegativo:'El número no debe ser negativo. en',
          valNumMayorA0:'El numero ingresado debe ser menor a 1 y mayor a 0. en',
          cargasIndicador:'No se encontraron cargas de datos publicadas para el indicador. en',
          absInicialDeCorreccionMenor:'La abscisa inicial de corrección no puede ser menor a la abscisa inicial. en',
          absInicialDeCorreccionMayor:'La abscisa inicial de corrección no puede ser mayor a la abscisa final. en',
          absFinalDeCorreccionMenor:'La abscisa final de corrección no puede ser menor a la abscisa inicial. en',
          absFinalDeCorreccionMayor:'La abscisa final de corrección no puede ser mayor a la abscisa final. en',
          unElemento:'Debe escoger al menos un elemento. en',
          politicaGeneral:'Sólo debe usar una política general como base para política particular. en',
          todosLosDatos:'Debe diligenciar todos los datos. en',
          filtrosValidos:'Debe seleccionar filtros válidos para diligenciar las políticas particulares de los elementos correspondientes. en',
          sinPoliticasAsociadas:'Sin Políticas Asociadas. en',
          politicasDiligencias:'Asegurese de que los 4 tipos de políticas generales estén diligenciadas para el elemento. en',
          mantenimientoOSuministro:'Debe seleccionar alguna de las dos opciones (mantenimiento o suministro). en',
          abscisasTrazos:'No se encontraron abscisas en uno o más de los trazos. en',
          coordenadaDevuelta:'Al menos uno de los grupos de coordenadas fue devuelto sin información. Asegurese de usar un KML generado por ArcGIS. en',
          noCoincide:'La unidad funcional en el archivo no coincide con la unidad funcional seleccionada. en',
          trazosDivididos:'Los trazo no se encuentran divididos correctamente. en',
          errorArchivo:'Hubo un error al leer el archivo. en',
          mayorA:'Debe ser mayor a. en',
          rangoNoValido:'Rango no válido. en',
          contraseniaNoCoindice:'La contraseña no coinciden. en',
          valNombre:'El nombre no es válido. en',
          valApellido:'El apellido no es válido. en',
          valEliminacion:'No se puede eliminar. en',
          valRegistroAsociado:'Se tienen registros del inventario asociados a este elemento. Por favor validar. en',
          valBorrar:'¿Estás seguro de que quieres borrar este elemento? en',
          borrar:'BORRAR EN',
          valBorrarUnidadFuncional:'Borrar unidad funcional. en',
          valBorrarProyecto:'Borrar proyecto. en',
          valBorrarRuta:'Borrar ruta. en',
          valBorrarTramo:'Borrar tramo. en',
          valBorrarSegmentos:'Borrar segmento. en',
        },
        audits:{
          name: 'Auditoría |||| Auditorías',
          fields:{
            type: 'Actividad',
            tableOrigin: 'Tabla',
            old: 'Información anterior',
            new: 'Información nueva',
            alteredValue: 'Información afectada', 
            user: 'Usuario',
            idElement: 'ID Elemento',
            module: 'Módulo',
            functionality: 'Funcionalidad',
            subElement: 'Elemento',
            smartCode: 'Código inteligente',
            createdIn: 'Creado en',
            dateIn: 'Fecha',
            tableMaster: 'Tabla principal',
            fechaInicial: 'Fecha Inicial',
            fechaFinal: 'Fecha Final',
          },
          tabs:{
            title: "Información auditoría",
            tabDetalles: "Detalle",
            tabIdentificacion: "Identificación",
            tableShow:{
              titleField: "Campo afectado",
              titleOld: "Información anterior",
              titleNew: "Información nueva",
            },
          }
        }
    },
    dynatest: {
        resources: {
            imagenesInventario: {
              titleImagen: 'Tipo de elemento en',
              descripcionImagen: 'Arrastra algunas imágenes para empezar la subida o presiona aquí para seleccionar una. en',
            },
            pimsMenu: {
                fields: {
                    inicio:'Start',
                    administracion: "Administration en",
                    inventario: "Inventory en",
                    transito: "Traffic en",
                    indicadores: "Indicators en",
                    gestion: "Management en",
                    reportesQS: "QuickSight Reports"
                },
                subMenu: {
                  usuarios: 'Usuarios en',
                  atributos: 'Atributos en',
                  proyectos: 'Proyectos en',
                  DisenarProyecto: 'Diseñar Proyecto en',
                  Rutas: 'Rutas en',
                  UnidadesFuncionales: 'Unidades funcionales en',
                  Tramos: 'Tramos en',
                  Segmentos: 'Segmentos en',
                  Configuraciones: 'Configuraciones en',
                  Materiales: 'Materiales en',
                  Granulometría: 'Granulometría en',
                  Indicadores: 'Indicadores en',
                  ParamEvalRangosAlerta: 'Parámetros de evaluación y Rangos de alerta en',
                  DiagramaColorimetrico: 'Diagrama colorimétrico en',
                  IndicadoresComplementarios:'Indicadores comple - mentarios en',
                  DeterioroPERS: 'Deterioro PERS en',
                  Reparaciones: 'Reparaciones en',
                  Deterioros: 'Deterioros en',
                  Contactos: 'Contactos en',
                  Emergencias: 'Emergencias en',
                  Proveedores: 'Proveedores en',
                  ServiciosPublicos: 'Servicios Públicos en',
                  Mapa: 'Mapa en',
                  Segmentos: 'Segmentos en',
                  Flexible: 'Flexible en',
                  Rigido: 'Rígido en',
                  Adoquin: 'Adoquín en',
                  Otro: 'Otro en',
                  SistemasContencionVehicular: 'Sistemas de Contención Vehicular en',
                  Drenaje: 'Drenaje en',
                  Senalizacion: 'Señalización en',
                  Alrededores: 'Alrededores en',
                  PuentesEstructurasEspe: 'Puentes y estructuras especiales en',
                  Tuneles: 'Túneles en',
                  Contencion: 'Contención en',
                  Ponton: 'Pontón en',
                  Puentes: 'Puentes en',
                  OtrosElementos: 'Otros elementos en',
                  Redes: 'Redes en',
                  Peajes: 'Peajes en',
                  Inventario: 'Inventario en',
                  TransitoHistorico: 'Tránsito Histórico en',
                  EstacionConteo: 'Estación de conteo en',
                  Indicadores4G: 'Indicadores 4G en',
                  CargarInformacion: 'Ver y cargar Información en',
                  E15Puentes: 'E15 Puentes en',
                  Complementarios:'Complementarios en',
                  DisponibilidadVia: 'Disponibilidad de Vía en',
                  Programacion: 'Programación en',
                  ParametrosPlaneacion: 'Parámetros de planeación en',
                  OtrosSistemas: 'Otros Sistemas en',
                  PoliticasGenerales: 'Políticas Generales en',
                  PoliticasParticulares: 'Políticas Particulares en',
                  PavimentosPERS: 'Pavimentos - PERS en',
                  AlternativasIntervencion: 'Alternativas de intervención en',
                  ReparacionesMenores: 'Reparaciones Menores en',
                  ExportarBasePers: 'Exportar base PERS en',
                  ReportesPavimentos: 'Importar base PERS en',
                  RegistroCostos: 'Registro de Costos en',
                  FlujoCaja: 'Flujo de Caja en',
                  ReportesConfig: 'Configuración Reportes en',
                  ReportesPers: 'Reportes PERS en',
                  ReportesInventario: 'Reportes Inventario en',
                  ReportesIndicadores: 'Reportes Indicadores en',
                  ReportesCostosMantenimiento: 'Reportes Costos - Mantenimiento en',
                  ReportesTransito: 'Reportes Tránsito en',
                  ReportesContactos: 'Otros Reportes en',
                  ReportesModelacion: 'Reportes Modelación en',
                  AplicacionMovil: 'Aplicación Móvil en',
                  Novedades: 'Novedades en',
                  Aprobacion: 'Aprobación en',
                  ConfiguracionGeneral: 'Configuración General en',
                  Idioma: 'Idioma en',
                  Sistema: 'Sistema en',
                  Audit: 'Audit en',
                  cargaMasiva: 'Carga Masiva en',
                }
            },
            idiomas: {
                formTabs: {
                    seleccionIdiomaBase: 'Base language selection en',
                    cargueArchivos: 'File upload'
                }
            },
            usuarios: {
                title:{
                  editTitle: 'Edit User',
                  showTitle: 'User Information'
                },
                formTabs: {
                    identificacionTitle: 'Identification en',
                },
                select: {
                    documento: {
                        cedula_ciudadania: 'Citizenship card en',
                        cedula_extrangeria: "Foreigner's identity card en",
                        nit: 'Tax ID Number en',
                    }
                },
                tabs: {
                    informacionUsuario: 'User information en',
                    informacionBasica: 'Basic Information en',
                    auditoria: 'Audit and Control en',
                    acceso_usuario_app: 'USER ACCESSES IN THE APPLICATION en',
                    administracion: 'ADMINISTRATION en',
                    proyectosMay: 'PROJECTS en',
                    proyectosMin: 'Projects en',
                    confMateriales: 'Settings - Materials en',
                    confIndicadores: 'Settings - Indicators en',
                    confDeterioro: 'Settings - PERS Deterioration en',
                    contactos: 'Contacts en',
                    elem_inventario: 'INVENTORY ELEMENTS en',
                    segmentos_cal: 'Segments (Roadway) en',
                    inventario: 'Inventories en',
                    puentes_estructuras: 'Bridges and special structures en',
                    transito: 'TRANSIT en',
                    indicadoresMay: 'INDICATORS en',
                    indicadoresMin: 'Indicators en',
                    gestion: 'MANAGEMENT en',
                    programacion: 'Programming en',
                    otros_sistemas: 'Other systems en',
                    pavimento_pers: 'PERS pavement en',
                    flujo_cajaTitle: 'Cash flow en',
                    idiomas: 'Languages',
                    configGeneral: 'Configuración General en',
                    configReports: 'Configuración Reportes en',
                    configAudit: 'Auditoría en',
                    configNovedades : 'Configuraciones - Novedades en',
                    cargaMasiva : 'Carga masiva en',
                    registroCostos: 'Registro de Costos en',
                    aplicacionMovil: 'APLICACIÓN MÓVIL en'
                    },
            },
            atributos: {
                formTabs: {
                    administracion: 'Administración en',
                    proyecto: 'Proyecto en',
                    inventario: 'Inventario en',
                    transito: 'Tránsito en',
                    indicadores: 'Indicadores en',
                    gestion: 'Gestión en',
                    reportesQS: 'Reportes QuickSight en',
                    auditoria: "Auditoria en",
                    aplicacionMovil: 'Aplicación Móvil',
                },
                select: {
                    acceso: {
                        acceso_completo: 'Acceso Completo en',
                        acceso_parcial: 'Acceso Parcial en',
                        sin_acceso: 'Sin Acceso en',
                        visualiza: 'Visualiza en',
                        noDefinido: 'No Definido en'
                    },
                    tipo_rol: {
                        administrativo: 'Administrativo en',
                        campo: 'Campo en',
                        oficina: 'Oficina en',
                    }
                },
                title: {
                    proyectos: 'Proyectos en',
                    configMateriales: 'Configuraciones - Materiales en',
                    configIndicadores: 'Configuraciones - Indicadores en',
                    configDeterioroPers: 'Configuraciones - Deterioro PERS en',
                    contactos: 'Contactos en',
                    segmentos: 'Segmentos (Calzada) en',
                    elemInventario: 'Elementos Inventario en',
                    puentesEstructurasEspec: 'Puentes y Estructuras Especiales en',
                    programacion: 'Programación en',
                    otrosSistemas: 'Otros Sistemas en',
                    pavimentoPers: 'Pavimento PERS en',
                    flujoCaja: 'Flujo de Caja en',
                    editTitle: 'Attributes',
                    showTitle: 'Attributes',
                    configNovedades: 'Configuraciones - Novedades en',
                    complementarios: 'Complementarios en',
                    respuestaEvaluacionNovedad: 'Novedades en',
                    novedades: 'Novedades en',
                    aprobacion: 'Aprobación en',
                    configGeneral: 'Configuración General en',
                    configReports: 'Configuración Reportes en',
                    configAudit: 'Auditoría en',
                    registroCostos: 'Registro de Costos en',
                    cargaMasiva: 'Carga Masiva en',
                }
            },
            proyectos:{
                title:{
                  coordMinimas: 'Coordenadas mínimas en',
                  coordMaximas: 'Coordenadas máximas en',
                  altura: 'Altura en',
                  editTitle: 'Edit Project en',
                  showTitle: 'Información Project en'
                  
                },
                tabs: {
                  informacionBasica: 'Información básica en',
                  auditoria : 'Auditoria en',
                },
                buttons:{
                  anadirKML: 'Add KML en'
                }
              },
            rutas:{
                title:{
                  inforInvias: 'Información INVIAS en',
                  editTitle: 'Edit Ruta en',
                  showTitle: 'Route Information en'
                },
                select: {
                  categoria:{
                  priOrden: 'Primer orden en',
                  segOrden: 'Segundo orden en',
                  terOrden: 'Tercer orden en',
                  }
                },
                tabs:{
                  informacionBasica: 'Información básica en',
                  auditoria : 'Auditoria en',
                  }
              },
              unidad_funcionals:{
                title: {
                  editTitle: 'Edit Unidad Funcional en',
                  showTitle: 'Información Unidad Funcional en'
                },
                tabs:{
                  informacionBasica: 'Información básica en',
                  auditoria : 'Auditoria en',
                  } 
              },
              tramos:{
                title:{
                  inforTramo: 'Información Tramo en',
                  genMasvSeg: 'GENERACIÓN MASIVA SEG. en',
                  editTitle: 'Edit Tramo en',
                  showTitle: 'Información Tramo en'
                },
                tabs:{
                  informacionBasica: 'Información básica en',
                  auditoria : 'Auditoria en',
                  }  
              },
              segmentos:{
                title:{
                  localizacion:'Localización en',
                  caracteristicas:'Características en',
                  coorIniciales:'Coordenadas Iniciales en',
                  coorFinales:'Coordenadas Finales en',
                  showTitleRamal: 'Información de Ramal en',
                  showTitleSegmento: 'Información de Segmento en',
                  listTitle: 'Lista de Segmentos - Ramales en',
                  createTitleRamal: 'Crear Ramal en',
                  createTitleSegmento: 'Crear Segmento en',
                  createTitle: 'Crear en',
                  editTitleRamal: 'Editar Ramal en',
                  editTitleSegmento: 'Editar Segmento en',
                  editTitle: 'Editar en',
                  tranSegmento: 'Transformacion en'
                },
                tabs:{
                  informacionBasica: 'Información básica en',
                  auditoria : 'Auditoria en',
                },
                select:{
                  ramal: 'Ramal en',
                  segmento: 'Segmento en',
                  izq: 'Izquierdo en',
                  der: 'Derecho en'
                },
                messagesVal: {
                  abscisaSegmento: 'Error el rago debe estar entre en',
                  prIngresado:'El Pr ingresado debe ser mayor a en',
                  absInicial:'La Abscisa inicial debe ser mayor a en',
                  rango:'Rango no válido en',
                  prRango:'Rango de Pr no válido en',
                }
              },
              materials:{
                selectMaterials: {
                  adoquinEnArcilla: 'Adoquín en arcilla en',
                  amadoquinDeConcreto: 'Adoquines de concreto en',
                  afirmado: 'Afirmado en',
                  baseAsfaltica: 'Base asfáltica en',
                  baseDeConcHidraulico: 'Base de concreto hidráulico en',
                  baseEstOtros: 'Base estabilizada - otros en',
                  baseEstbAsfalto: 'Base estabilizada con asfalto espumado en',
                  baseEstEmulsion: 'Base estabilizada con emulsión asfáltica en',
                  baseGranular: 'Base granular en',
                  baseTratada: 'Base tratada con cemento en',
                  concHidraulico: 'Concreto hidráulico en',
                  lechAsfaltica: 'Lechada ásfaltica en',
                  mezAbierCaliente: 'Mezcla abierta en caliente en',
                  mezAbierFrio: 'Mezcla abierta en frío en',
                  mezAsfalGraCaucho: 'Mezcla asfáltica modificada con grano de caucho en',
                  mezAsfalModPolimeros: 'Mezcla asfáltica modificada con polímeros en',
                  mezDenCaliente: 'Mezcla densa en caliente en',
                  mezDenFrio: 'Mezcla densa en frío en',
                  mezDrenante: 'Mezcla drenante en',
                  microagloCaliente: 'Microaglomerado en caliente en',
                  subBaseAsfalEspumado: 'Sub base asfalto espumado en',
                  subBaseEstabOtros: 'Sub base estabilizada - otros en',
                  subBaseEstabAsfalto: 'Sub base estabilizada con asfalto en',
                  subBaseEstabCemento: 'Sub base estabilizada con cemento en',
                  subBaseEstabGranular: 'Sub base granular en',
                  subraEstabCal: 'Subrasante estabilizada con cal en',
                  subraEstabCemento: 'Subrasante estabilizada con cemento en',
                  subraEstabGeomalla: 'Subrasante estabilizada con geomalla en',
                  subraEstabGeotextil: 'Subrasante estabilizada con geotextil en',
                  subraFina: 'Subrasante fina en',
                  subraGranular: 'Subrasante granular en',
                  subraCemento: 'Suelo cemento en',
                },
                selectOption:{
                  otros: 'Otro en',
                  noLigado: 'No Ligado en',
                  bituminoso: 'Bituminoso en',
                  cementado: 'Cementado en'
                },
                tabs:{
                  informacionBasica: 'Información básica en',
                  auditoria : 'Auditoria en',
                }
              },
              granulometrias:{
                title: {
                  showTitle: 'Información Granulometría en',
                  ediTitle: 'Editar granulometría en',
                },
                select:{
                  tipoMaterial:{
                  adoquinEnArcilla: 'Adoquín en arcilla en',
                  amadoquinDeConcreto: 'Adoquines de concreto en',
                  afirmado: 'Afirmado en',
                  baseAsfaltica: 'Base asfáltica en',
                  baseDeConcHidraulico: 'Base de concreto hidráulico en',
                  baseEstOtros: 'Base estabilizada - otros en',
                  baseEstbAsfalto: 'Base estabilizada con asfalto espumado en',
                  baseEstEmulsion: 'Base estabilizada con emulsión asfáltica en',
                  baseGranular: 'Base granular en',
                  baseTratada: 'Base tratada con cemento en',
                  concHidraulico: 'Concreto hidráulico en',
                  lechAsfaltica: 'Lechada ásfaltica en',
                  mezAbierCaliente: 'Mezcla abierta en caliente en',
                  mezAbierFrio: 'Mezcla abierta en frío en',
                  mezAsfalGraCaucho: 'Mezcla asfáltica modificada con grano de caucho en',
                  mezAsfalModPolimeros: 'Mezcla asfáltica modificada con polímeros en',
                  mezDenCaliente: 'Mezcla densa en caliente en',
                  mezDenFrio: 'Mezcla densa en frío en',
                  mezDrenante: 'Mezcla drenante en',
                  microagloCaliente: 'Microaglomerado en caliente en',
                  subBaseAsfalEspumado: 'Sub base asfalto espumado en',
                  subBaseEstabOtros: 'Sub base estabilizada - otros en',
                  subBaseEstabAsfalto: 'Sub base estabilizada con asfalto en',
                  subBaseEstabCemento: 'Sub base estabilizada con cemento en',
                  subBaseEstabGranular: 'Sub base granular en',
                  subraEstabCal: 'Subrasante estabilizada con cal en',
                  subraEstabCemento: 'Subrasante estabilizada con cemento en',
                  subraEstabGeomalla: 'Subrasante estabilizada con geomalla en',
                  subraEstabGeotextil: 'Subrasante estabilizada con geotextil en',
                  subraFina: 'Subrasante fina en',
                  subraGranular: 'Subrasante granular en',
                  subraCemento: 'Suelo cemento en',
                  }
                },
                tabs:{
                  informacionBasica: 'Información básica en',
                  auditoria : 'Auditoria en',
                }
              },
              generacion_masiva_segmentos:{
                title:{
                 validSeg: '¡Validando segmentos! en',
                 uniFuncTram: '¡La unidad funcional y el tramo ya tienen segmentos, no se pueden crear masivamente! en',
                 coorIniciales: 'Coordenadas iniciales en',
                 coorFinales: 'Coordenadas Finales en',
                 caracteristicas: 'Características en'
                },
                button:{
                  asigValSegmentos: 'Asignar valores por defecto a los  segmentos en',
                  generSegmentos: 'Generar Segmentos en',
                  cancelar: 'Cancelar en',
                },
                table: {
                  nameColumns: {
                    absInicio: 'Abs Inicio en',
                    absFinal: 'Abs Final en',
                    longitud: 'Longitud en',
                    prInicio: 'PR Inicio en',
                    prFinal: 'PR Final en',
                    departamento: 'Departamento en',
                    municipio: 'Municipio en',
                    latitudInicial: 'Latitud Inicial en',
                    longitudInicial: 'Longitud Inicial en',
                    alturaInicial: 'Altura Inicial en',
                    latitudFinal: 'Latitud Final en',
                    longitudFinal: 'Longitud Final en',
                    alturaFinal: 'Altura Final en',
                    tipoCalzada: 'Tipo Calzada en',
                    tipoSegmento: 'Tipo Segmento en',
                    tipoSuperficie: 'Tipo Superficie en',
                  },
                  nameRows: {
                    departamento: 'Departamento en',
                    municipio: 'Municipio en',
                    latitudInicial: 'Latitud Inicial en',
                    longitudInicial: 'Longitud Inicial en',
                    alturaInicial: 'Altura Inicial (m.s.n.m) en',
                    latitudFinal: 'Latitud Final en',
                    longitudFinal: 'Longitud Final en',
                    alturaFinal: 'Altura Final (m.s.n.m) en',
                    tipoCalzada: 'Tipo Calzada en',
                    tipoSegmento: 'Tipo Segmento en',
                    tipoSuperficie: 'Tipo Superficie en',
                  }
                }
              },
              transformacion_segmentos:{
                title:{
                 validSeg: '¡Validando segmentos! en',
                 uniFuncTram: '¡La unidad funcional y el tramo ya tienen segmentos, no se pueden crear masivamente! en',
                 coorIniciales: 'Coordenadas iniciales en',
                 transformarSegmentos: 'Transformar segmentos en',
                },
                button:{
                  generSegmentos: 'Generar Segmentos en',
                  cancelar: 'Cancelar en',
                  filtrar: 'Filtrar en',
                  transformar: 'Transformar en',
                  confirmar:'Confirmar en',
                  generar:'GENERAR EN',
                  agregar:'AGREGAR EN',
                  remover:'REMOVER EN',
                },
                radioSel: {
                  modificarSegmentos: 'Modificar segmentos y sus características en',
                  modificarTrazado: 'Modificar trazado (Reemplazar segmentos) en',
                },
                table: {
                  nameColumns: {
                    codigo: 'Código en',
                    absInicio: 'Abs Inicio en',
                    absFinal: 'Abs Final en',
                    longitud: 'Longitud en',
                    longitudCol: 'Longitud(km) en',
                    prInicio: 'PR Inicio en',
                    prFinal: 'PR Final en',
                    departamento: 'Departamento en',
                    municipio: 'Municipio en',
                    latitudInicial: 'Latitud Inicial en',
                    longitudInicial: 'Longitud Inicial en',
                    alturaInicial: 'Altura Inicial en',
                    latitudFinal: 'Latitud Final en',
                    longitudFinal: 'Longitud Final en',
                    alturaFinal: 'Altura Final en',
                    tipoCalzada: 'Tipo Calzada en',
                    tipoSegmento: 'Tipo Segmento en',
                    tipoSuperficie: 'Tipo Superficie en',
                  },
                  nameRows: {
                    departamento: 'Departamento en',
                    municipio: 'Municipio en',
                    latitudInicial: 'Latitud Inicial en',
                    longitudInicial: 'Longitud Inicial en',
                    alturaInicial: 'Altura Inicial (m.s.n.m) en',
                    latitudFinal: 'Latitud Final en',
                    longitudFinal: 'Longitud Final en',
                    alturaFinal: 'Altura Final (m.s.n.m) en',
                    tipoCalzada: 'Tipo Calzada en',
                    tipoSegmento: 'Tipo Segmento en',
                    tipoSuperficie: 'Tipo Superficie en',
                  }
                }
              },
              reparacion_localizadas:{
                title:{
                  editTitle: 'Edit Reparacion PERS en',
                  showTitle: 'Show Reparacion PERS en'
                },
                select: {
                  tipoReparacion:{
                    PFLEX: 'Flexible en',
                    PRIGD: 'Rígido en',
                    PART: 'Adoquín en',
                    POTR: 'Otro en',
                  }
                },
                tabs:{
                  reparacionPers: 'Reparación PERS en',
                  auditoria : 'Auditoría en'
                }
              },
              tipo_deterioro_pers:{
                title:{
                  editTitle: 'Edit Deterioro PERS en',
                  showTitle: 'Show Deterioro PERS en'
                },
                select: {
                  tipoSuperficie:{
                    PFLEX: 'Flexible en',
                    PRIGD: 'Rígido en',
                    PART: 'Adoquín en',
                    POTR: 'Otro en',
                  },
                  nivelSeveridad:{
                    1: '1 en',
                    3: '3 en'
                  },
                  unidad:{
                    m2: 'm2 en',
                    m: 'm en',
                    losa: 'losa en'
                  }
                },
                tabs:{
                  reparacionPers: 'Reparación PERS en',
                  auditoria : 'Auditoría en'
                }
              },
              contacto_emergencias:{
                title:{
                  editTitle: 'Edit Información Contacto en',
                  showTitle: 'Show Información Contacto en'
                },
                select: {
                  grupo: {
                    alcaldia: 'Alcaldía en',
                    bomberos: 'Bomberos en',
                    centroEducativo: 'Centro educativo en',
                    defensaCivil: 'Defensa civil en',
                    ejercito: 'Ejército en',
                    emisoraRadial: 'Emisora radial en',
                    interventoria: 'Interventoría en',
                    maquinaria: 'Maquinaria en',
                    otros: 'Otros en',
                    pOB_Administrativa: 'POB-Administrativa en',
                    pOB_Ambulancia: 'POB-Ambulancia en',
                    pOB_CarroTaller: 'POB-Carro taller en',
                    pOB_Gruas: 'POB-Grúas en',
                    pOB_Peajes: 'POB-Peajes en',
                    pOB_RadioOperacion: 'POB-Radio Operación en',
                    pOB_SupervisionVial: 'POB-Supervisión vial en',
                    policia: 'Policía en',
                    serviciosMedicos: 'Servicios médicos en',
                    talleres: 'Talleres en',
                  },
                  identificacion: {
                    CC: 'CC en',
                    NIT: 'NIT en',
                    Otro: 'Otro en'
                  }
                },
                tabs:{
                  informacionBasica: 'Información básica en',
                  auditoria : 'Auditoría en'
                }
              },
              colores:{
                title:{
                  createTitle: 'Crear Diagrama colorimétrico en',
                  editTitle: 'Editar Color en',
                  showTitle: 'Información Color en',
                  punto1: 'Punto 1:  en',
                  punto2: 'Punto 2:  en',
                  punto3: 'Punto 3:  en',
                  punto4: 'Punto 4:  en',
                  valorMinX: 'Valor Mínimo x:  en',
                  valorMinY: 'Valor Mínimo y:  en',
                  valorMaxX: 'Valor Máximo x:  en',
                  valorMaxY: 'Valor Máximo y:  en',
                  valRetroReflec: 'Valores Retro-Reflectividad en',
                  rectSuper: 'Recta Superior en',
                  rectDer: 'Recta Derecha en',
                  rectInfe: 'Recta Inferior en',
                  rectIzq: 'Recta Izquierda en',
                  paramAreaColor: 'Parametros Área de Color en',
                  inforDiagColor: 'Información  Diagrama Colorimétrico en',
                  color: 'Colores en',
                  valorMinCump: 'Valor mínimo de cumplimiento en', 
                  diagrama: 'Diagrama en',
                },
                formTabs:{
                  identificacion: 'Identificación en',
                  auditoria: 'Auditoria en'
                }
              },
              proveedors:{
                title:{
                  editTitle: 'Edit Proveedor en',
                  showTitle: 'Show Información Proveedor en'
                },
                tabs:{
                  proyecto: 'PROYECTO en',
                  informacionProveedor: 'Información básica en',
                  calificacion: 'CALIFICACIÓN en',
                  auditoria : 'Auditoría en'
                },
                select: {
                  identificacion: {
                    CC: 'CC en',
                    NIT: 'NIT en',
                    Otro: 'Otro en'
                  },
                },
                boolean:{
                  mantenimiento: 'Mantenimiento en',
                  suministros: 'Suministros en',
                },
                buttons:{
                  calificarProveedor: 'Calificar proveedor en'
                }
              },
              calificacion_proveedors: {
                title:{
                  qualifyTitle: 'Calificar Proveedor en',
                },
                select: {
                  calificacion: {
                    1: '1 en',
                    2: '2 en',
                    3: '3 en',
                    4: '4 en',
                    5: '5 en'
                  }
                },
                buttons:{
                  save: 'SAVE en',
                  saveAndEnd: 'GUARDAR Y Calificar otro proveedor en'
                },
                span:{
                  descripcionCalificacion: 'Evalúe el proveedor en una calificación de 1 a 5, donde 5 significa un nivel "muy alto" y 1 un nivel "muy bajo" en'
                }
              },
              servicios_publicos: {
                title: {
                  editTitle: 'Edit Servicio Público en',
                  showTitle: 'Información Servicio Público en'
                },
                select: {
                  grupo: {
                    acueducto: 'Acueducto en',
                    aseo: 'Aseo en',
                    energia: 'Energía en',
                    gasNatural: 'Gas natural en',
                    otros: 'Otros en',
                    telecomunicaciones: 'Telecomunicaciones en'
                  },
                  identificacion: {
                    CC: 'CC en',
                    NIT: 'NIT en',
                    Otro: 'Otro en'
                  }
                },
                tabs: {
                  informacionBasica: 'Información básica en',
                  auditoria: 'Auditoría en'
                }
              },
              parametros_evaluacion:{
                select:{
                  indicadores:{
                   indicadoresMessage: 'Indicadores que apliquen a:  en',
                   todosParam:"Todos los Parámetros en",
                   indiPavFlex:"Indicadores exclusivos para Pavimento Flexible en",
                   indiPavRig:"Indicadores exclusivos para Pavimento Rígido en",
                   indiPavRigFlex:"Indicadores exclusivos para Pavimento Rigido y Flexible en",
                   indiPuentesEstr:"Indicadores exclusivos de Puentes y Estructuras en",
                   indiTuneles:"Indicadores exclusivos para Túneles en",
                   indiOperac:"Indicadores Operacionales en",
                  }
                },
                title:{
                  grupoParamCrea:'Ya Tiene un Grupo de Parámetros Creados en',
                  editTitle: 'Editar Parámetros de Evaluación en',
                  showTitle: 'Mostrar Parámetros de Evaluación en' 
                },
                table: {
                  titleColumns: {
                    frecuenciaMedicion:'Frecuencia de medición (meses). en',
                    tiempoCorreccion:'Tiempo de corrección (meses). en',
                    valormaxPuntual:'Valor máximo puntual (mm/m). en',
                    valormaxMedio:'Valor máximo medio (mm/m). en',
                    indicador: 'Indicador en',
                    aplica: 'Aplica en',
                    valorAlerta: 'Valor de alerta en',
                    datosMinmodelacion: 'Datos mínimos para modelación en',
                    tiempoGenerarAlerta: '¿Cuánto tiempo antes generar alerta de medición? en',
                  },
                  si: 'Si en',
                  no: 'No en',
                }
              },
              elementos: {
                title: {
                  listTitle: 'Mapa general en',
                },
                tilteFiltros: {
                  filtros: 'Filtros en',
                  calzadas: 'Calzadas en',
                  sistemasContencion: 'Sistemas de Contención en',
                  drenajes: 'Drenajes en',
                  senalizacion: 'Señalización en',
                  alrededores: 'Alrededores en',
                  puentesEstrucEsp: 'Puentes y estructuras especiales en',
                  otrosElementos: 'Otros Elementos en',
                  redes: 'Redes en'
                },
                titleIndicadores: {
                  rangosColores: 'Rangos y Colores en',
                  iri: 'IRI en',
                  umbrales: 'Umbrales en',
                  colores: 'Colores en',
                  visualizacion: 'Visualización en',
                  pantallaConfiguración: 'Pantalla de configuración en'
                },
                checkboxs: {
                  flexible_detalles: 'Segmento Flexible en',
                  rigido_detalles: 'Segmento Rígido en',
                  adoquin_detalles: 'Segmento Adoquín en',
                  otro_detalles: 'Segmento Otros en',
                  seguridads: 'Sistemas de Contención en',
                  elemento_drenajes: 'Drenajes en',
                  elemento_senalizacions: 'Señalización en',
                  elemento_alrededors: 'Alrededores en',
                  tuneles: 'Túneles en',
                  elemento_contencions: 'Contención en',
                  elemento_pontons: 'Pontón en',
                  puentes: 'Puentes en',
                  otros_elementos: 'Otros Elementos en',
                  elemento_reds: 'Redes en',
                  Otro: 'Otro en',
                },
                select: {
                  tipoMapa: {
                    1: 'Indicadores en',
                    2: 'Inventario en',
                  },
                  numIntervalos: {
                    1: '1 en',
                    2: '2 en',
                    3: '3 en',
                    4: '4 en',
                    5: '5 en',
                    6: '6 en',
                    7: '7 en',
                    8: '8 en',
                  },
                },
              },
              flexible_detalles: {
                title: {
                  editTitle: 'Editar Segmento Flexible en',
                  showTitle: 'Información Segmento Flexible en'
                },
                select:{
                  sentido: {
                    'NORTEName': 'Norte en',
                    NORTEId: 'NORTE',
                    'SURName': 'Sur en',
                    SURId: 'SUR',
                    'ESTEName': 'Este en',
                    ESTEId: 'ESTE',
                    'OESTEName': 'Oeste en',
                    OESTEId: 'OESTE'
                  },
                  estadoDetalles: {
                    Activo: 'Activo en',
                    Inactivo: 'Inactivo en'
                  },
                  nivelTrafico: {
                    NT1: 'NT1 en',
                    NT2: 'NT2 en',
                    NT3: 'NT3 en',
                  },
                  geometriaTipoBerma: {
                    bermaCuneta: 'Berma Cuneta en',
                    flexible: 'Flexible en',
                    inexistente: 'Inexistente en',
                    rigida: 'Rígida en' 
                  },
                  geometriaCondicionBerma: {
                    bueno: 'Bueno en',
                    regular: 'Regular en',
                    deficiente: 'Deficiente en'
                  },
                  geometriaSeparador: {
                    Si: 'Si en',
                    No: 'No en'
                  },
                  geometriaCondicionesGeometrica: {
                    bueno: 'Bueno en',
                    regular: 'Regular en',
                    deficiente: 'Deficiente en'
                  },
                  geometriaAlineamiento: {
                    curvo: 'Curvo en',
                    predominantementeCurvo: 'Predominantemente Curvo en',
                    predominantementeRecto: 'Predominantemente Recto en',
                    recto: 'Recto en'
                  },
                  geometriaTipoTerreno: {
                    plano: 'Plano en',
                    ondulado: 'Ondulado en',
                    montañoso: 'Montañoso en',
                    escarpado: 'Escarpado en'
                  }
                },
                tabs:{
                  detalles: 'Detalles en',
                  geometria: 'Geometría en',
                  estructura: 'Estructura en',
                  fotografias : 'FOTOGRAFÍAS en',
                  informacionBasica: 'Información Básica en',
                  auditoria: 'AuditorÍa en'
                },
                messagesVal: {
                  value20: "Debe ser mayor a 'ESAL (Proyección 10 años)' en",
                  valNumDecimals: 'El valor ingresado solo puede tener 2 decimales en',
                  valNumDecimal: 'El valor ingresado solo puede tener 1 decimal en',
                  valNumEntero: 'El valor ingresado debe ser entero en',
                  valLongitudMin0: 'Debe ser al menos 0 en',
                  valLongitudMax5: 'Debe ser 5 o menos en',
                  valLongitudMax20: 'Debe ser 20 o menos en',
                  valMinCapa: 'Mínimo 1 capa en',
                  messageIniCapa: 'El Espesor en la capa  en',
                  messageFinCapa: ' (la última), debe ser 0 en',
                },
              },
              rigido_detalles:{
                title: {
                  editTitle: 'Editar Segmento Rígido en',
                  showTitle: 'Información Segmento Rígido en'
                },
                messagesVal: {
                  value20: "Debe ser mayor a 'ESAL (Proyección 10 años)' en",
                  valNumDecimals: 'El valor ingresado solo puede tener 2 decimales en',
                  valNumDecimal: 'El valor ingresado solo puede tener 1 decimal en',
                  valNumEntero: 'El valor ingresado debe ser entero en',
                  valLongitudMin0: 'Debe ser al menos 0 en',
                  valLongitudMax5: 'Debe ser 5 o menos en',
                  valLongitudMax20: 'Debe ser 20 o menos en',
                  valMinCapa: 'Mínimo 1 capa en',
                  messageIniCapa: 'El Espesor en la capa  en',
                  messageFinCapa: ' (la última), debe ser 0 en',
                },
                select: {
                  estadoDetalles: {
                    Activo: 'Activo en',
                    Inactivo: 'Inactivo en'
                  },
                  nivelTrafico: {
                    NT1: 'NT1 en',
                    NT2: 'NT2 en',
                    NT3: 'NT3 en',
                  },
                  geometriaSeparador: {
                    Si: 'Si en',
                    No: 'No en',
                    SiId: 'Si',
                    NoId: 'No'
                  },
                  sentido: {
                    'NORTEName': 'Norte en',
                    NORTEId: 'NORTE en',
                    'SURName': 'Sur en',
                    SURId: 'SUR en',
                    'ESTEName': 'Este en',
                    ESTEId: 'ESTE en',
                    'OESTEName': 'Oeste en',
                    OESTEId: 'OESTE en'
                  },
                  geometriaTipoBerma: {
                    bermaCuneta: 'Berma Cuneta en',
                    flexible: 'Flexible en',
                    inexistente: 'Inexistente en',
                    rigida: 'Rígida en' 
                  },
                  geometriaCondicionBerma: {
                    bueno: 'Bueno en',
                    regular: 'Regular en',
                    deficiente: 'Deficiente en'
                  },
                  geometriaCondicionesGeometrica: {
                    bueno: 'Bueno en',
                    regular: 'Regular en',
                    deficiente: 'Deficiente en'
                  },
                  geometriaAlineamiento: {
                    curvo: 'Curvo en',
                    predominantementeCurvo: 'Predominantemente Curvo en',
                    predominantementeRecto: 'Predominantemente Recto en',
                    recto: 'Recto en'
                  },
                  geometriaTipoTerreno: {
                    plano: 'Plano en',
                    ondulado: 'Ondulado en',
                    montañoso: 'Montañoso en',
                    escarpado: 'Escarpado en'
                  }
                },
                tabs:{
                  detalles: 'Detalles en',
                  geometria: 'Geometría en',
                  estructura: 'Estructura en',
                  fotografias : 'FOTOGRAFÍAS en',
                  informacionBasica: 'Información Básica en',
                  auditoria: 'AuditorÍa en'
                },
              },
              adoquin_detalles: {
                title: {
                  editTitle: 'Editar Segmento Adoquín en',
                  showTitle: 'Información Segmento Adoquín en'
                },
                messagesVal: {
                  value20: "Debe ser mayor a 'ESAL (Proyección 10 años)' en",
                  valNumDecimals: 'El valor ingresado solo puede tener 2 decimales en',
                  valNumDecimal: 'El valor ingresado solo puede tener 1 decimal en',
                  valNumEntero: 'El valor ingresado debe ser entero en',
                  valLongitudMin0: 'Debe ser al menos 0 en',
                  valLongitudMax5: 'Debe ser 5 o menos en',
                  valLongitudMax20: 'Debe ser 20 o menos en',
                  valMinCapa: 'Mínimo 1 capa en',
                  messageIniCapa: 'El Espesor en la capa ',
                  messageFinCapa: ' (la última), debe ser 0 en',
                  maxDosDecimales: 'Máximo 2 decimales en',
                },
                select: {
                  estadoDetalles: {
                    Activo: 'Activo en',
                    Inactivo: 'Inactivo en'
                  },
                  nivelTrafico: {
                    NT1: 'NT1 en',
                    NT2: 'NT2 en',
                    NT3: 'NT3 en',
                  },
                  geometriaSeparador: {
                    Si: 'Si en',
                    No: 'No en'
                  },
                  sentido: {
                    'NORTEName': 'Norte en',
                    NORTEId: 'NORTE en',
                    'SURName': 'Sur en',
                    SURId: 'SUR en',
                    'ESTEName': 'Este en',
                    ESTEId: 'ESTE en',
                    'OESTEName': 'Oeste en',
                    OESTEId: 'OESTE en'
                  },
                  geometriaTipoBerma: {
                    bermaCuneta: 'Berma Cuneta en',
                    flexible: 'Flexible en',
                    inexistente: 'Inexistente en',
                    rigida: 'Rígida en' 
                  },
                  geometriaCondicionBerma: {
                    bueno: 'Bueno en',
                    regular: 'Regular en',
                    deficiente: 'Deficiente en'
                  },
                  geometriaCondicionesGeometrica: {
                    bueno: 'Bueno en',
                    regular: 'Regular en',
                    deficiente: 'Deficiente en'
                  },
                  geometriaAlineamiento: {
                    curvo: 'Curvo en',
                    predominantementeCurvo: 'Predominantemente Curvo en',
                    predominantementeRecto: 'Predominantemente Recto en',
                    recto: 'Recto en'
                  },
                  geometriaTipoTerreno: {
                    plano: 'Plano en',
                    ondulado: 'Ondulado en',
                    montañoso: 'Montañoso en',
                    escarpado: 'Escarpado en'
                  }
                },
                tabs:{
                  detalles: 'Detalles en',
                  geometria: 'Geometría en',
                  estructura: 'Estructura en',
                  fotografias : 'FOTOGRAFÍAS en',
                  informacionBasica: 'Información Básica en',
                  auditoria: 'AuditorÍa en'
                },
                boolean: {
                  elementoMantenimiento: '¿Incluir este elemento a un plan de mantenimiento? en'
                },
              },
              otro_detalles: {
                title: {
                  editTitle: 'Editar Segmento Otro en',
                  showTitle: 'Información Segmento Otro en'
                },
                select: {
                  estadoDetalles: {
                    Activo: 'Activo en',
                    Inactivo: 'Inactivo en'
                  },
                  nivelTrafico: {
                    NT1: 'NT1 en',
                    NT2: 'NT2 en',
                    NT3: 'NT3 en',
                  },
                  geometriaSeparador: {
                    Si: 'Si en',
                    No: 'No en'
                  },
                  sentido: {
                    NORTEName: 'Norte en',
                    NORTEId: 'NORTE en',
                    SURName: 'Sur en',
                    SURId: 'SUR en',
                    ESTEName: 'Este en',
                    ESTEId: 'ESTE en',
                    OESTEName: 'Oeste en',
                    OESTEId: 'OESTE en'
                  },
                  geometriaTipoBerma: {
                    bermaCuneta: 'Berma Cuneta en',
                    flexible: 'Flexible en',
                    inexistente: 'Inexistente en',
                    rigida: 'Rígida en' 
                  },
                  geometriaCondicionBerma: {
                    bueno: 'Bueno en',
                    regular: 'Regular en',
                    deficiente: 'Deficiente en'
                  },
                  geometriaCondicionesGeometrica: {
                    bueno: 'Bueno en',
                    regular: 'Regular en',
                    deficiente: 'Deficiente en'
                  },
                  geometriaAlineamiento: {
                    curvo: 'Curvo en',
                    predominantementeCurvo: 'Predominantemente Curvo en',
                    predominantementeRecto: 'Predominantemente Recto en',
                    recto: 'Recto en'
                  },
                  geometriaTipoTerreno: {
                    plano: 'Plano en',
                    ondulado: 'Ondulado en',
                    montañoso: 'Montañoso en',
                    escarpado: 'Escarpado en'
                  }
                },
                messagesVal: {
                  value20: "Debe ser mayor a 'ESAL (Proyección 10 años)' en",
                  valNumDecimals: 'El valor ingresado solo puede tener 2 decimales en',
                  valNumDecimal: 'El valor ingresado solo puede tener 1 decimal en',
                  valNumEntero: 'El valor ingresado debe ser entero en',
                  valLongitudMin0: 'Debe ser al menos 0 en',
                  valLongitudMax5: 'Debe ser 5 o menos en',
                  valLongitudMax20: 'Debe ser 20 o menos en',
                  valMinCapa: 'Mínimo 1 capa en',
                  messageIniCapa: 'El Espesor en la capa ',
                  messageFinCapa: ' (la última), debe ser 0 en',
                  maxDosDecimales: 'Máximo 2 decimales. en',
                  sinDecimales: 'Sin Decimales. en'
                },
                boolean: {
                  elementoMantenimiento: '¿Incluir este elemento a un plan de mantenimiento? en'
                },
                tabs:{
                  detalles: 'Detalles en',
                  geometria: 'Geometría en',
                  estructura: 'Estructura en',
                  fotografias : 'FOTOGRAFÍAS en',
                  informacionBasica: 'Información Básica en',
                  auditoria: 'AuditorÍa en'
                },
              },
              seguridads: {
                title: {
                  showTitle: "Lista Sistemas de Contención Vehicular en",
                  editTitle: 'Editar Sistemas de Contención Vehicular en',
                  showTitle: 'Información Sistemas de Contención Vehicular en'
                },
                tabs: {
                  tabsTitle: {
                    identificacion: 'Identificación en',
                    caracteristicas: 'Características en',
                    detalles: 'Detalles en',
                    fotografias: 'FOTOGRAFÍAS en',
                    auditoria: 'AuditorÍa en'
                  },
                },
                select: {
                  tipodeDefensa: {
                    termIniAbatidaEsviadaTerFinAbatida: 'Terminal de inicio abatida y esviada y Terminal final abatida en',
                    termIniAbatidaEsviadaTerFinAbatidaEsviada: 'Terminal de inicio abatida y esviada y Terminal final abatida y esviada en',
                    termIniAbatEsviadaTermFinEnterradaTalud: 'Terminal de inicio abatida y esviada y Terminal final enterrada en talud en',
                    termIniAbatEsviadaTermFinColaPez: 'Terminal de inicio abatida y esviada y Terminal final cola de pez en',
                    termIniAbatTerminalFinAbatida: 'Terminal de inicio abatida y Terminal final abatida en',
                    termIniAbatTerminalFinAbatidaEsviada: 'Terminal de inicio abatida y Terminal final abatida y esviada en',
                    termIniAbatTerminalFinColaPez: 'Terminal de inicio abatida y Terminal final cola de pez en',
                    termIniAbatTerminalFinSalidaAerea: 'Terminal de inicio abatida y Terminal final con salida aérea en',
                    termIniAbatTerminalFinEnterradaTalud: 'Terminal de inicio abatida y Terminal final enterrada en talud en',
                    termIniAbatTerminalFinIncrustadaObraArte: 'Terminal de inicio abatida y Terminal final incrustada en obra de arte en',
                    termIniColaPezTermiFinAbatidaEsviada: 'Terminal de inicio cola de pez y Terminal final abatida y esviada en',
                    termIniColaPezTermiFinAbatida: 'Terminal de inicio cola de pez y Terminal final abatida en',
                    termIniColaPezTermiFinColaPez: 'Terminal de inicio cola de pez y Terminal final cola de pez en',
                    termIniColaPezTermiFinEnterradaTalud: 'Terminal de inicio cola de pez y Terminal final enterrada en talud en',
                    termIniAmortiguadorImpacTermFinAbatida: 'Terminal de inicio con amortiguador de impacto y Terminal final abatida en',
                    termIniAmortiguadorImpacTermFinColaPez: 'Terminal de inicio con amortiguador de impacto y Terminal final cola de pez en',
                    termIniAmortiguadorImpacTermFinSalAerea: 'Terminal de inicio con amortiguador de impacto y Terminal final con salida aérea en',
                    termIniAmortiguadorImpacTermFinEnterradaTalud: 'Terminal de inicio con amortiguador de impacto y Terminal final enterrada en talud en',
                    termIniAmortiguadorImpacTermFinIncrusObraArte: 'Terminal de inicio con amortiguador de impacto y Terminal final incrustada en obra de arte en',
                    termIniEnterrTaludTermFinAbatEsviada: 'Terminal de inicio enterrada en talud y Terminal final abatida y esviada en',
                    termIniEnterrTaludTermFinAbatida: 'Terminal de inicio enterrada en talud y Terminal final abatida en',
                    termIniEnterrTaludTermFinColaPez: 'Terminal de inicio enterrada en talud y Terminal final cola de pez en',
                    termIniEnterrTaludTermFinEnterrTalud: 'Terminal de inicio enterrada en talud y Terminal final enterrada en talud en',
                    termIniEsviadaTermFinAbatida: 'Terminal de inicio esviada y Terminal final abatida en',
                    termIniEsviadaTermFinSalidaAerea: 'Terminal de inicio esviada y Terminal final con salida aérea en',
                    termIniEsviadaTermFinEnterradaTalud: 'Terminal de inicio esviada y Terminal final enterrada en talud en',
                    termIniEsviadaTermFinIncrustadaObraArte: 'Terminal de inicio esviada y Terminal final incrustada en obra de arte en',
                    termIniIncrustObraArteTermFinalAbatida: 'Terminal de inicio incrustada en obra de arte y Terminal final abatida en',
                    termIniIncrustObraArteTermFinalSalidaAerea: 'Terminal de inicio incrustada en obra de arte y Terminal final con salida aérea en',
                    termIniIncrustObraArteTermFinalEnterradaTalud: 'Terminal de inicio incrustada en obra de arte y Terminal final enterrada en talud en',
                    termIniIncrustObraArteTermFinalEsviada: 'Terminal de inicio incrustada en obra de arte y Terminal final esviada en',
                  },
                  margen: {
                    derechaName: 'Derecha en',
                    derechaId: 'Derecha',
                    izquierdaName: 'Izquierda en',
                    izquierdaId: 'Izquierda',
                    centroName: 'Centro en',
                    centroId: 'Centro',
                  },
                  sentido: {
                    norteName: 'Norte en',
                    norteId: 'NORTE',
                    surName: 'Sur en',
                    surId: 'SUR',
                    esteName: 'Este en',
                    esteId: 'ESTE',
                    oesteName: 'Oeste en',
                    oesteId: 'OESTE',
                  },
                  material: {
                    barreraJerseyPlastica: 'Barrera jersey plástica en',
                    barreraJerseyPlasticaId: 'Barrera jersey plastica en',
                    concreto: 'Concreto en',
                    malla: 'Malla en',
                    metalica: 'Metálica en',
                    metalicaId: 'Metalica en',
                    plastica: 'Plástica en',
                    plasticaId: 'Plastica en',
                    polisombra: 'Polisombra en',
                    prefabricado: 'Prefabricado en',
                  },
                  perfil: {
                    perfilC: 'Perfil en C en',
                    perfilU: 'Perfil en U en',
                    perfilW_DobleOnda: 'Perfil W - doble onda en',
                    simple: 'Simple en'
                  },
                  posteMetalico: {
                    siName: 'Si en',
                    siId: 'SI',
                    noName: 'No en',
                    noId: 'NO'
                  },
                  tope: {
                    horizontal: 'Horizontal en',
                    vertical: 'Vertical en'
                  },
                  seccion: {
                    circular: 'Circular en',
                    rectangular: 'Rectangular en',
                  },
                  condicionElemento: {
                    existente: 'Existente en',
                    reemplazo: 'Reemplazo en',
                    nuevo: 'Nuevo en',
                  },
                  motivoReemplazo:{
                    accidente: 'Accidente en',
                    instalacion: 'Instalación en',
                    instalacionId: 'Instalacion en',
                    reemplazoProgramado: 'Reemplazo programado en',
                    vandalismo: 'Vandalismo en',
                    otro: 'Otro en',
                  },
                  estado: {
                    activo: 'Activo en',
                    inactivo: 'Inactivo en'
                  },
                  tipoElementoDesc:{
                    barreraConcretoPendSimple: 'Barrera de concreto de pendiente simple en',
                    barreraPerfilF: 'Barrera de perfil F en',
                    barreraJersey: 'Barrera Jersey en',
                    bordillo: 'Bordillo en',
                    captafaros: 'Captafaros en',
                    cerramientoProvisional: 'Cerramiento provisional en',
                    defensa: 'Defensa en',
                    pretilesPuentes: 'Pretiles de Puentes en',
                    topeVehicular: 'Tope Vehicular en',
                  }
                },
                subTitle: {
                  localizacion: 'Localización en',
                  coordenadasIniciales: 'Coordenadas Iniciales en',
                  coordenadasFinales: 'Coordenadas Finales en',
                },
                boolean: {
                  mantenimiento: '¿Incluir este elemento a un plan de mantenimiento? en'
                },
                messageWarning: {
                  observaciones: 'Máximo 200 caracteres. en'
                }
              },
              elemento_drenajes: {
                title: {
                  editTitle: 'Editar Elemento Drenaje en',
                  showTitle: 'Información Elemento Drenaje en',
                  listTitle: 'Lista Elementos Drenajes en'
                },
                tabs: {
                  tabsTitle: {
                    identificacion: 'Identificación en',
                    caracteristicas: 'Características en',
                    detalles: 'Detalles en',
                    fotografias: 'FOTOGRAFÍAS en',
                    auditoria: 'AuditorÍa en'
                  },
                },
                select: {
                  sentido: {
                    NORTEName: 'Norte en',
                    NORTEId: 'NORTE en',
                    SURName: 'Sur en',
                    SURId: 'SUR en',
                    ESTEName: 'Este en',
                    ESTEId: 'ESTE en',
                    OESTEName: 'Oeste en',
                    OESTEId: 'OESTE en'
                  },
                  margen: {
                    derecha: 'Derecha en',
                    izquierda: 'Izquierda en',
                    centro:'Centro en',
                  },
                  tipoCuneta: {
                    bajoAccesosPrediosInterseccionVias: 'Bajo Accesos a Predios o Intersección de Vías en',
                    enZonasUrbanasSemiurbanas: 'En Zonas Urbanas y Semiurbanas en',
                    tipoCarretera: 'Tipo Carretera en',
                  },
                  material: {
                    acero: 'Acero en',
                    concreto: 'Concreto en',
                    concretoAcero: 'Concreto y acero en',
                    mamposteria: 'Mampostería en',
                    polipropileno:'Polipropileno en',
                    cobre:'Cobre en',
                    otro: 'Otro en',
                    PEAD: 'PEAD en',
                    PVC: 'PVC en',
                    HDPE:'HDPE en',
                  },
                  seccion: {
                    circular: 'Circular en',
                    cuadrada: 'Cuadrada en',
                    rectangular: 'Rectangular en',
                    trapezoidal: 'Trapezoidal en',
                    triangular: 'Triangular en',
                    hexagonal:'Hexagonal en',
                    perfilH:'Perfil H en',
                    otro:'Otro en',
                  },
                  tipoDelElemento: {
                    AlcantarDobleconcreto: 'Alcantarilla doble de concreto en',
                    AlcantarDoblePVC: 'Alcantarilla doble de PVC en',
                    AlcantarDobleMetalica: 'Alcantarilla doble metálica en',
                    AlcantarSimpleConcreto: 'Alcantarilla simple de concreto en',
                    AlcantarSimplePVC: 'Alcantarilla simple de PVC en',
                    AlcantarSimpleMetalica: 'Alcantarilla simple metálica en',
                    AlcantarTripleConcreto: 'Alcantarilla triple de concreto en',
                    AlcantarTriplePVC: 'Alcantarilla triple de PVC en',
                    AlcantarTripleMetalica: 'Alcantarilla triple metálica en',
                    boxCulvertDobleConcreto: 'Box culvert doble de concreto en',
                    boxCulvertEspecial: 'Box culvert especial en',
                    boxCulvertSimpleConcreto: 'Box culvert simple de concreto en',
                    boxCulvertTripleConcreto: 'Box culvert triple de concreto en',
                  },
                  tipoPlanta: {
                    Normal: 'Normal en',
                    Esconso: 'Esconso en',
                  },
                  direccion: {
                    Jusante: 'Jusante en',
                    Montante: 'Montante en',
                  },
                  estado: {
                    existente: 'Existente en',
                    reemplazo: 'Reemplazo en',
                    nuevo: 'Nuevo en',
                  },
                  motivoReemplazo: {
                    accidente: 'Accidente en',
                    otro: 'Otro en',
                    reemplazoProgramado: 'Reemplazo programado en',
                    vandalismo: 'Vandalismo en',
                  },
                  estados: {
                    Activo: 'Activo en',
                    Inactivo: 'Inactivo en',
                  }
                },
                subTitle: {
                  localizacion: 'Localización en',
                  coordenadasIniciales: 'Coordenadas Iniciales en',
                  coordenadasFinales: 'Coordenadas Finales en',
                },
                boolean: {
                  mantenimiento: '¿Incluir este elemento a un plan de mantenimiento? en'
                },
              },
              elemento_senalizacions:{
                title: {
                  editTitle: 'Editar Elemento Señal en',
                  showTitle: 'Información Elemento Señal en',
                  listTitle: ''
                },
                tabs: {
                  tabsTitle: {
                    identificacion: 'Identificación en',
                    caracteristicas: 'Características en',
                    detalles: 'Detalles en',
                    fotografias: 'FOTOGRAFÍAS en',
                    auditoria: 'AuditorÍa en'
                  },
                },
                messagesVal: {
                  valNumEntero: 'Debe ser un número entero en',
                  rangoLongitud: 'El rango debe estar entre en '
                },
                select: {
                  margen: {
                    derecha: 'Derecha en',
                    izquierda: 'Izquierda en',
                    centro: 'Centro en'
                  },
                  sentido: {
                    NORTEName: 'Norte en',
                    NORTEId: 'NORTE en',
                    SURName: 'Sur en',
                    SURId: 'SUR en',
                    ESTEName: 'Este en',
                    ESTEId: 'ESTE en',
                    OESTEName: 'Oeste en',
                    OESTEId: 'OESTE en'
                  },
                  choice: {
                    amarillo: 'Amarillo en',
                    amariFluorescente: 'Amarillo fluorescente en',
                    amariVerdeFluores: 'Amarillo-verde fluorescente en',
                    azul: 'Azul en',
                    blanco: 'Blanco en',
                    marrón: 'Marrón en',
                    naranja: 'Naranja en',
                    naranjaFluorescente: 'Naranja fluorescente en',
                    rojo: 'Rojo en',
                    verde: 'Verde en',
                    noAplica: 'No aplica en',
                  },
                  choicess: {
                    amarillo: 'Amarillo en',
                    amariFluorescente: 'Amarillo fluorescente en',
                    amariVerdeFluores: 'Amarillo-verde fluorescente en',
                    azul: 'Azul en',
                    blanco: 'Blanco en',
                    marrón: 'Marrón en',
                    naranja: 'Naranja en',
                    naranjaFluorescente: 'Naranja fluorescente en',
                    negro: 'Negro en',
                    rojo: 'Rojo en',
                    verde: 'Verde en',
                    noAplica: 'No aplica en',
                  },
                  material: {
                    aceroGalvanizado: 'Acero galvanizado en',
                    aluminio: 'Aluminio en',
                    fibra: 'Fibra en',
                    cintasPreformadas: 'Cintas preformadas en',
                    pinturas: 'Pinturas en',
                    plasticosFrio: 'Plásticos en frio en',
                    termoplasticas: 'Termoplásticas en',
                  },
                  tipoPapel: {
                    altaAmarillo: 'Alta amarillo en',
                    altaBlanco: 'Alta blanco en',
                    diamanteAmariVerdeFluores: 'Diamante amarillo verde fluorescente en',
                    diamanteAzul: 'Diamante azul en',
                    diamanteBlanco: 'Diamante blanco en',
                    diamanteMarron: 'Diamante marrón en',
                    diamanteRojo: 'Diamante rojo en',
                    diamanteVerde: 'Diamante verde en',
                    ingenieriaAmarillo: 'Ingeniería amarillo en',
                    ingenieriaBlanco: 'Ingeniería blanco en',
                    t11Amarillo700: 'T11 amarillo 700 en',
                  },
                  tipoTablero: {
                    circular: 'Circular en',
                    cuadrada: 'Cuadrada en',
                    rectangular: 'Rectangular en',
                    otro: 'Otro en',
                  },
                  dimensionTablero:{
                    x80: '80 x 80 cm en',
                    x100: '100 x 100 cm en',
                    x120: '120 x 120 cm en',
                    x140: '140 x 140 cm en',
                    Otra: 'Otra en'
                  },
                  estadoTablero: {
                    Afectada: 'Afectada en',
                    Buena: 'Buena en',
                  },
                  tipoSoporte: {
                    Bandera: 'Bandera en',
                    Pasacalle: 'Pasacalle en',
                    Vastago: 'Vástago en',
                  },
                  estadoSoporte: {
                    Afectada: 'Afectada en',
                    Buena: 'Buena en',
                  },
                  materialSoporte: {
                    Acero: 'Acero en',
                    Madera: 'Madera en'
                  },
                  colorOtrosElem: {
                    Amarilla: 'Amarilla en',
                    Blanca: 'Blanca en',
                  },
                  estado: {
                    existente: 'Existente en',
                    reemplazo: 'Reemplazo en',
                    nuevo: 'Nuevo en',
                    bueno: 'Bueno en',
                    malo: 'Malo en',
                    regular: 'Regular en',
                  },
                  estados: {
                    Activo: 'Activo en',
                    Inactivo: 'Inactivo en',
                  },
                  motivoReemplazo: {
                    accidente: 'Accidente en',
                    instalacion: 'Instalación en',
                    otro: 'Otro',
                    reemplazoProgramado: 'Reemplazo programado en',
                    vandalismo: 'Vandalismo en',
                  },
                  condicion: {
                    instalacion: 'Instalación en',
                    mantenimiento: 'Mantenimiento en',
                  }
                },
                subTitle: {
                  localizacion: 'Localización en',
                  coordenadas: 'Coordenadas en',
                  coordenadasIniciales: 'Coordenadas Iniciales en',
                  coordenadasFinales: 'Coordenadas Finales en',
                },
                messageWarning: {
                  warningMensajeVariable: 'Módulo no diponible para tipo de señal "Mensaje variable" en',
                  altura: 'Debe ser al menos 0 en',
                  numEntero: 'Debe ser un número entero en',
                  rangolng: 'El rango debe estar entre '
                },
                boolean: {
                  mantenimiento: '¿Incluir este elemento a un plan de mantenimiento? en'
                },
              },
              elemento_alrededors: {
                title: {
                  editTitle: 'Editar Elemento Alrededor en',
                  showTitle: 'Información Elemento Alrededor en',
                  listTitle: ''
                },
                tabs: {
                  tabsTitle: {
                    identificacion: 'Identificación en',
                    caracteristicas: 'Características en',
                    detalles: 'Detalles en',
                    fotografias: 'FOTOGRAFÍAS en',
                    auditoria: 'AuditorÍa en'
                  },
                },
                select: {
                  margen: {
                    derecha: 'Derecha en',
                    izquierda: 'Izquierda en',
                    centro: 'Centro en'
                  },
                  sentido: {
                    NORTEName: 'Norte en',
                    NORTEId: 'NORTE',
                    SURName: 'Sur en',
                    SURId: 'SUR',
                    ESTEName: 'Este en',
                    ESTEId: 'ESTE',
                    OESTEName: 'Oeste en',
                    OESTEId: 'OESTE'
                  },
                  tipoServicio: {
                    barraca: 'Barraca en',
                    centroEducativo: 'Centro educativo en',
                    estaciónCombustible: 'Estación de combustible en',
                    hospitalCentroSalud: 'Hospital/Centro de salud en',
                    hotel: 'Hotel en',
                    montallantas: 'Montallantas en',
                    restaurante: 'Restaurante en',
                    tallerAutomotriz: 'Taller automotriz en',
                    otros: 'Otros en',
                    /* Margen */
                    concretoAdoquin: 'Concreto/Adoquín en',
                    noAplica: 'No aplica en',
                    talud: 'Talud en',
                    vegetación: 'Vegetación en',
                    otro: 'Otro en',
                    /* Obstaculo */
                    cercado: 'Cercado en',
                    construccion: 'Construcción en',
                    otros: 'Otros en',
                    rio: 'Río en',
                    zonaVerde: 'Zona verde en',
                    /* Zona Inestable */
                    caida: 'Caída en',
                    deslizamiento: 'Deslizamiento en',
                    flujos: 'Flujos en',
                    movimientoComplejo: 'Movimiento complejo en',
                    volcamiento: 'Volcamiento en',
                    /* Ocupación */
                    Cerca: 'Cerca en',
                    Edificacion: 'Edificación en',
                    Outdoor: 'Outdoor en',
                    Placa: 'Placa en',
                    /* Accesos */
                    Municipal: 'Municipal en',
                    Particular: 'Particular en',
                  },
                  tipoAcceso: {
                    conCarrilAcelDesa: 'Con carril de aceleración/desaceleración en',
                    sinCarril: 'Sin carril en'
                  },
                  estacionamiento: {
                    enFachada: 'En fachada en',
                    enVia: 'En vía en',
                    interno: 'Interno en',
                  },
                  identificacion: {
                    Regular: 'Regular en',
                    Irregular: 'Irregular en',
                  },
                  riesgo: {
                    baja: 'Baja en',
                    media: 'Media en',
                    alta: 'Alta en',
                    muyAlta: 'Muy Alta en',
                  },
                  seccion: {
                    taludSuperior: 'Talud superior en',
                    taludInferior: 'Talud inferior en',
                  },
                  tipoTerraplen: {
                    Corte: 'Corte en',
                    Relleno: 'Relleno en'
                  },
                  tipoObra: {
                    anclajesRoca: 'Anclajes en roca en',
                    cercadosRedesContencion: 'Cercados y redes de contenci en',
                    EscudoContraCaidaRocas: 'Escudo contra caída de rocas en',
                    muroAnclado: 'Muro anclado en',
                    muroConcretoReforzado: 'Muro de concreto reforzado en',
                    muroEncofrado: 'Muro de encofrado en',
                    muroGaviones: 'Muro de gaviones en',
                    muroGravedadConcreto: 'Muro de gravedad en concreto en',
                    muroTierraReforzada: 'Muro de tierra reforzada en',
                    murosContencion: 'Muros de contención en',
                    pantallasAncladas: 'Pantallas ancladas en',
                    pilotesCaissons: 'Pilotes y caissons en',
                    reconformacion: 'Reconformación en',
                    revestimientoFlexible: 'Revestimiento flexible en',
                    revestimientoVegetal: 'Revestimiento vegetal en',
                    sueloEmpernado: 'Suelo empernado en',
                    otros: 'Otros en',
                    
                  },
                  estado: {
                    Activo: 'Activo en',
                    Inactivo: 'Inactivo en',
                    Existente: 'Existente en',
                    Reemplazo: 'Reemplazo en',
                    Nuevo: 'Nuevo en',
                  },
                  condicion: {
                    accidente: 'Accidente en',
                    instalacion: 'Instalación en',
                    otro: 'Otro en',
                    reemplazoProgramado: 'Reemplazo programado en',
                    vandalismo: 'Vandalismo en',
                  },
                  estados: {
                    Activo: 'Activo en',
                    Inactivo: 'Inactivo en',
                  }
                },
                subTitle: {
                  localizacion: 'Localización en',
                  coordenadas: 'Coordenadas en',
                  coordenadasIniciales: 'Coordenadas Iniciales en',
                  coordenadasFinales: 'Coordenadas Finales en',
                  TramosPendVariable: 'Tramos de Pendiente Variable en',
                },
                messagesVal: {
                  valNumNegativos: 'El valor ingresado no puede ser negativo en',
                  valNumEntero: 'Debe ser un número entero en',
                  rangoLongitud: 'El rango debe estar entre en '
                },
              },
              tuneles: {
                title: {
                  editTitle: 'Editar Elemento Túneles en',
                  showTitle: 'Información Elemento Túneles en',
                  listTitle: ''
                },
                tabs: {
                  tabsTitle: {
                    identificacion: 'Identificación en',
                    caracteristicas: 'Características en',
                    datosAdministrativos: 'Datos Administrativos en',
                    geometria: 'Geometría en',
                    detalles: 'Detalles en',
                    fotografias: 'FOTOGRAFÍAS en',
                    auditoria: 'AuditorÍa en'
                  },
                },
                select: {
                  sentido: {
                    Ascendente: 'Ascendente en',
                    Descendente: 'Descendente en',
                  },
                  sentidoB: {
                    norteName: 'Norte en',
                    norteId: 'NORTE',
                    surName: 'Sur en',
                    surId: 'SUR',
                    esteName: 'Este en',
                    esteId: 'ESTE',
                    oesteName: 'Oeste en',
                    oesteId: 'OESTE',
                  },
                  clasificacion: {
                    ClaseA: 'Clase A en',
                    ClaseB: 'Clase B en',
                    ClaseC: 'Clase C en',
                    ClaseD: 'Clase D en',
                    ClaseE: 'Clase E en',
                  },
                  cobertura: {
                    Somero: 'Somero en',
                    Semiprofundo: 'Semiprofundo en',
                    Profundo: 'Profundo en',
                  },
                  existe: {
                    Si: 'Si en',
                    No: 'No en',
                  },
                  estadoa: {
                    Bueno: 'Bueno en',
                    Regular: 'Regular en',
                    Malo: 'Malo en',
                  },
                  soporte: {
                    ArcMetalicReticulado: 'Arcos metálicos o reticulados en',
                    ConcretoLanzado: 'Concreto lanzado en',
                    ElementosDuctiles: 'Elementos dúctiles en',
                    MejoramientoTerreno: 'Mejoramiento del terreno en',
                    PernosAnclaje: 'Pernos de Anclaje en',
                    RefuerzoTerreno: 'Refuerzo del terreno en',
                  },
                  codigo_soporte: {
                    RCSname: 'Revestimiento de capa sencilla en',
                    RCCname: 'Revestimiento de capa compuesta en',
                    RCDname: 'Revestimiento de capa doble en',
                  },
                  tipologia: {
                    EnVoEncajonado: 'En "V" o encajonado en',
                    EnLoMediaLadera: 'En "L" o a media ladera en',
                    EnCoSesgados: 'En "C" o sesgados en',
                    EnToPerpendiculares: 'En "T" o perpendiculares en',
                    EnUoHerradura: 'En "U" o herradura en',
                    EnEoEstructurales: 'En "E" o estructurales en',
                  },
                  estado: {
                    Existente: 'Existente en',
                    Reemplazo: 'Reemplazo en',
                    Nuevo: 'Nuevo en',
                  },
                  mot_remplazo: {
                    ReemplazoProgramado: 'Reemplazo programado en',
                    Accidente: 'Accidente en',
                    Vandalismo: 'Vandalismo en',
                    Otro: 'Otro en',
                  },
                  estados: {
                    Activo: 'Activo en',
                    Inactivo: 'Inactivo en',
                  }
                },
                subTitle: {
                  localizacion: 'Localización en',
                  coordenadas: 'Coordenadas en',
                  coordenadasIniciales: 'Coordenadas Iniciales en',
                  coordenadasFinales: 'Coordenadas Finales en',
                  detallesConstructivos: 'Detalles constructivos en',
                  detRefuerzoRehabilit: 'Detalles de refuerzo o rehabilitación en',
                  estudioHidrogeologico: 'Estudio Hidrogeológico en',
                  estProyeccionTrafico: 'Estudio de proyección del tráfico en',
                  miembrosInteresados: 'Miembros Interesados en',
                  caractOperacionales: 'Características operacionales en',
                  disenoPlanta: 'Diseño en planta en',
                  disenoPerfil: 'Diseño en perfil en',
                  SeccTransverServicio: 'Secciones transversales de servicio en',
                  InfoComplementaria: 'Información complementaria en',
                },
                table: {
                  titles: {
                    Costado: 'Costado en',
                    Cantidad: 'Cantidad en',
                    Separacion: 'Separación (m) en',
                    Longitud: 'Longitud (m) en',
                    Ancho: 'Ancho (m) en',
                    Altura: 'Altura (m) en',
                    Pendiente: 'Pendiente (%) en',
                  },
                  nameRows: {
                    BahiasParqueo: 'Bahías de parqueo en',
                    CarrilEmergencia: 'Carril de emergencia en',
                    GaleriasEvacuacion: 'Galerías de evacuación en',
                    NichoEmergenciaSOS: 'Nicho de Emergencia S.O.S en',
                    NichoControlIncendios: 'Nicho de Control de incendios en',
                    NichoEquiposElectricos: 'Nicho de Equipos eléctricos en'
                  },
                  choicesCostado:{
                    Izquierdo: 'Izquierdo en',
                    Derecho: 'Derecho en',
                    Ambos: 'Ambos en',
                  },
                  message: 'Cargando Tabla... en',
                },
                boolean: {
                  incluir: '¿Incluir este elemento a un plan de mantenimiento? en'
                },
                messagesVal: {
                  valNumEntero: 'Debe ser un número entero en',
                  valNumMayorA1: 'El numero ingresado debe ser mayor a 1 en',
                  valNumMayorA0: 'El numero ingresado debe ser mayor a 0 en',
                  valNumMenorA90: 'El numero ingresado debe ser menor a 90 en',
                  valNumMenorA100: 'El numero ingresado debe ser menor a 100 en',
                },
              },
              elemento_contencions: {
                title: {
                  editTitle: 'Editar Elemento Contención en',
                  showTitle: 'Información Elemento Contención en',
                  listTitle: 'Lista Elementos Contención en'
                },
                tabs: {
                  tabsTitle: {
                    identificacion: 'Identificación en',
                    caracteristicas: 'Características en',
                    detalles: 'Detalles en',
                    fotografias: 'FOTOGRAFÍAS en',
                    auditoria: 'AuditorÍa en'
                  },
                },
                select: {
                  margen:{
                    Derecha: 'Derecha en',
                    Izquierda: 'Izquierda en',
                  },
                  sentido: {
                    NORTEName: 'Norte en',
                    NORTEId: 'NORTE',
                    SURName: 'Sur en',
                    SURId: 'SUR',
                    ESTEName: 'Este en',
                    ESTEId: 'ESTE',
                    OESTEName: 'Oeste en',
                    OESTEId: 'OESTE'
                  },
                  tipo: {
                    anclajesRoca: 'Anclajes en roca en',
                    cercadosRedesContencion: 'Cercados y redes de contención en',
                    escudoContraCaidaRocas: 'Escudo contra caída de rocas en',
                    muroAnclado: 'Muro anclado en',
                    muroConcretoReforzado: 'Muro de concreto reforzado en',
                    muroEncofrado: 'Muro de encofrado en',
                    muroGaviones: 'Muro de gaviones en',
                    muroGravedadConcreto: 'Muro de gravedad en concreto en',
                    muroTierraReforzada: 'Muro de tierra reforzada en',
                    murosContencion: 'Muros de contención en',
                    pantallasAncladas: 'Pantallas ancladas en',
                    pilotesCaissons: 'Pilotes y caissons en',
                    reconformacion: 'Reconformación en',
                    revestimientoFlexible: 'Revestimiento flexible en',
                    revestimientoVegetal: 'Revestimiento vegetal en',
                    sueloEmpernado: 'Suelo empernado en',
                    otros: 'Otros en',
                  },
                  funcionalidad: {
                    controlCauces: 'Control de cauces en',
                    estabilidadBanca: 'Estabilidad de banca en',
                    estabilidadTaludes: 'Estabilidad de taludes en',
                    proteccionInfraest: 'Protección de infraestructura en',
                    otros: 'Otros en',
                  },
                  estado: {
                    Existente: 'Existente en',
                    Reemplazo: 'Reemplazo en',
                    Nuevo: 'Nuevo en',
                  },
                  motivoReemplazo: {
                    Instalacion: 'Instalación en',
                    ReemplazoProgramado: 'Reemplazo programado en',
                    Accidente: 'Accidente en',
                    Vandalismo: 'Vandalismo en',
                    Otro: 'Otro en',
                  },
                  estados: {
                    Activo: 'Activo en',
                    Inactivo: 'Inactivo en',
                  }
                },
                subTitle: {
                  localizacion: 'Localización en',
                  coordenadas: 'Coordenadas en',
                  coordenadasIniciales: 'Coordenadas Iniciales en',
                  coordenadasFinales: 'Coordenadas Finales en',
                },
                boolean: {
                  estudioEstabilidad: 'Estudios de estabilidad del sitio en',
                  mantenimiento: '¿Incluir este elemento a un plan de mantenimiento? en'
                }
              },
              elemento_pontons: {
                title: {
                  createTitle: 'Crear Elemento Pontones en',
                  editTitle: 'Editar Elemento Pontones en',
                  showTitle: 'Información Elemento Pontones en',
                  listTitle: 'Lista Elementos Pontones en'
                },
                tabs: {
                  tabsTitle: {
                    identificacion: 'Identificación en',
                    datosAdministrativos: 'Datos Administrativos en',
                    geometria: 'Geometría en',
                    reparaciones: 'Reparaciones en',
                    detalles: 'Detalles en',
                    fotografias: 'FOTOGRAFÍAS en',
                    auditoria: 'AuditorÍa en'
                  },
                },
                select: {
                  tipoElemento: {
                    PTON: 'Pontón en'
                  },
                  margen: {
                    Derecha: 'Derecha en',
                    Izquierda: 'Izquierda en',
                    Centro: 'Centro en',
                  },
                  sentidoB: {
                    NORTEName: 'Norte en',
                    NORTEId: 'NORTE',
                    SURName: 'Sur en',
                    SURId: 'SUR',
                    ESTEName: 'Este en',
                    ESTEId: 'ESTE',
                    OESTEName: 'Oeste en',
                    OESTEId: 'OESTE'
                  },
                  tipoPonton: {
                    Concreto: 'Concreto en',
                    MetalicosMixtos: 'Metálicos o mixtos en',
                  },
                  selectSorN: {
                    S: 'S en',
                    N: 'N en',
                  },
                  esta: {
                    b: 'B en',
                    r: 'R en',
                    m: 'M en'
                  },
                  sentido: {
                    S: 'S en',
                    N: 'N en',
                    E: 'E en',
                    O: 'O en',
                  },
                  pon_c: {
                    T: 'T en',
                    C: 'C en'
                  },
                  estado: {
                    Existente: 'Existente en',
                    Reemplazo: 'Reemplazo en',
                    Nuevo: 'Nuevo en',
                  },
                  motivoReemplazo: {
                    Instalacion: 'Instalación en',
                    ReemplazoProgramado: 'Reemplazo programado en',
                    Accidente: 'Accidente en',
                    Vandalismo: 'Vandalismo en',
                    Otro: 'Otro en',
                  },
                  estados: {
                    Activo: 'Activo en',
                    Inactivo: 'Inactivo en',
                  }
                },
                messagesVal: {
                  valNumEntero: 'Debe ser un número entero en',
                  valRangoLatLong: 'El rango debe estar entre en ',
                  CargandoTabla: 'Cargando Tabla... en'
                },
                subTitle: {
                  localizacion: 'Localización en',
                  coordenadas: 'Coordenadas en',
                  coordenadasIniciales: 'Coordenadas Iniciales en',
                  coordenadasFinales: 'Coordenadas Finales en',
                  detallesConstructivos: 'Detalles constructivos en',
                  detRefuerzoRehabilit: 'Detalles de refuerzo o rehabilitación en',
                  detallesInspeccion: 'Detalles de Inspección en',
                  miembrosInteresados: 'Miembros Interesados en',
                  caractOperacionales: 'Características operacionales en',
                  capacidadCargaTransitoLegal: 'Capacidad de carga para tránsito legal en',
                  capacidadCargaTranspEspeciales: 'Capacidad de carga para transportes especiales en',
                  general: 'General en',
                  detallesEstructura: 'Detalles de estructura en', 
                },
                table: {
                  fieldsHead: {
                    Componente: 'Componente en',
                    Aplica: 'Aplica en',
                    FechaReparacion: 'Fecha reparación en',
                    Tipo: 'Tipo en',
                    Cantidad: 'Cantidad en',
                    Anio: 'Año en',
                    Costo: 'Costo en',
                  },
                  titleRows: {
                    SuperficiePonton: 'Superficie del pontón en',
                    AndenesBordillos: 'Andenes / Bordillos en',
                    Barandas: 'Barandas en',
                    ConosTaludes: 'Conos /Taludes en',
                    ElementosArmadura: 'Elementos de armadura en',
                    Cauce: 'Cauce en',
                    LosaSuperior: 'Losa superior en',
                    LosaInferior: 'Losa inferior en',
                    Muros: 'Muros en',
                    OtrosElementos: 'Otros elementos en',
                    PontonGeneral: 'Pontón en general en',
  
                  }
                },
                boolean: {
                  mantenimiento: '¿Incluir este elemento a un plan de mantenimiento? en'
                }
              },
              puentes: {
                title: {
                  editTitle: 'Editar Elemento Puentes en',
                  showTitle: 'Información Elemento Puentes en',
                  listTitle: 'Lista de Elemento Puentes en'
                },
                tabs: {
                  tabsTitle: {
                    identificacion: 'Identificación en',
                    datosAdministrativos: 'Datos Administrativos en',
                    geometria: 'Geometría en',
                    estructura: 'Estructura en',
                    subestructura: 'Subestructura en',
                    reparaciones: 'Reparaciones en',
                    detalles: 'Detalles en',
                    fotografias: 'FOTOGRAFÍAS en',
                    auditoria: 'AuditorÍa en'
                  },
                },
                select: {
                  
                  margen: {
                    Derecha: 'Derecha en',
                    Izquierda: 'Izquierda en',
                    Centro: 'Centro en',
                  },
                  tipo: {
                    Concreto: 'Concreto en',
                    MetalicosMixtos: 'Metálicos o mixtos en',
                  },
                  material: {
                    Acero: 'Acero en',
                    Concreto: 'Concreto en',
                    Madera: 'Madera en',
                    Mixto: 'Mixto en',
                    Otro: 'Otro en',
                  },
                  tipo_acceso: {
                    Escalera: 'Escalera en',
                    Rampa: 'Rampa en',
                  },
                  tipo_cimen: {
                    Profunda: 'Profunda en',
                    Superficial: 'Superficial en',
                  },
                  cimen_pila: {
                    Profunda: 'Profunda en',
                    Superficial: 'Superficial en',
                  },
                  estado: {
                    Existente: 'Existente en',
                    Reemplazo: 'Reemplazo en',
                    Nuevo: 'Nuevo en',
                  },
                  mot_remplazo: {
                    Accidente: 'Accidente en',
                    ReemplazoProgramado: 'Reemplazo programado en',
                    Vandalismo: 'Vandalismo en',
                    Otro: 'Otro en',
                  },
                  estados: {
                    Activo: 'Activo en',
                    Inactivo: 'Inactivo en',
                  },
                  choices: {
                    Circular: 'Circular en',
                    Rectangular: 'Rectangular en',
                    Otra: 'Otra en',
                  },
                  choises3: {
                    ApoyoFijo: 'Apoyo fijo en',
                    Balancin: 'Balancín en',
                    Basculante: 'Basculante en',
                    Rodillo: 'Rodillo en',
                    PlacaNeopreno: 'Placa en neopreno en',
                    NoAplica: 'No aplica en',
                    Otros: 'Otros en',
                  },
                  opcion:{
                    si:'S en',
                    no:'N en',
                  },
                  sentido: {
                    S: 'S en',
                    N: 'N en',
                    E: 'E en',
                    O: 'O en',
                  },
                  esta: {
                    b: 'B en',
                    r: 'R en',
                    m: 'M en'
                  },
                   pon_c: {
                    T: 'T en',
                    C: 'C en'
                  },
                },
                subTitle: {
                  localizacion: 'Localización en',
                  coordenadas: 'Coordenadas en',
                  coordenadasIniciales: 'Coordenadas Iniciales en',
                  coordenadasFinales: 'Coordenadas Finales en',
                  detallesConstructivos: 'Detalles Constructivos en',
                  detRefuerzoRehabilit: 'Detalles de Refuerzo o Rehabilitación en',
                  detallesInspeccion: 'Detalles de Inspección en',
                  miembrosInteresados: 'Miembros Interesados en',
                  caractOperacionales: 'Características Operacionales en',
                  capacidadCargaTransitoLegal: 'Capacidad de carga para tránsito legal en',
                  capacidadCargaTranspEspeciales: 'Capacidad de Carga para Transportes especiales en',
                  general: 'General en',
                  estructura: 'Estructura en',
                  subestructura: 'Subestructura en',
                  detallesEstructura: 'Detalles de estructura en', 
                  Principal: 'Principal en',
                  Secundario: 'Secundario en',
                  Senales: 'Señales en',
                  Detalles: 'Detalles en',
                  Estribos: 'Estribos en',
                  Pilas: 'Pilas en',
                  Apoyos: 'Apoyos en',
                },
                messagesVal: {
                  ElementoNoRequiereInfo: 'El elemento seleccionado no requiere información en subestructura. en',
                },
                boolean: {
                  mantenimiento: '¿Incluir este elemento a un plan de mantenimiento? en'
                },
                reparaciones: {
                  titulos2: {
                    SuperficiePuente: 'Superficie del puente en',
                    JuntasExpansion: 'Juntas de expansión en',
                    AndenesBordillos: 'Andenes / Bordillos en',
                    Barandas: 'Barandas en',
                    ConosTaludes: 'Conos /Taludes en',
                    Aletas: 'Aletas en',
                    Estribos: 'Estribos en',
                    Pilas: 'Pilas en',
                    Apoyos: 'Apoyos en',
                    Losa: 'Losa en',
                    VigasLarguerosDiafragmas: 'Vigas / Largueros/ Diafragmas en',
                    ElementosArco: 'Elementos de arco en',
                    CablesPendolonesTorresMacizos: 'Cables /Pendolones/ Torres / Macizos en',
                    ElementosArmadura: 'Elementos de armadura en',
                    Cauce: 'Cauce en',
                    OtrosElementos: 'Otros elementos en',
                    PuenteGeneral: 'Puente en general en',
                  },
                  titleFields: {
                    Aplica: 'Aplica en',
                    FechaReparacion: 'Fecha reparación en',
                    Tipo: 'Tipo en',
                    Cantidad: 'Cantidad en',
                    Anio: 'Año en',
                    Costo: 'Costo en',
                  }
                }
              },
              otros_elementos: {
                title: {
                  editTitle: 'Editar Otro Elemento en',
                  showTitle: 'Informacion Otro Elemento en',
                  listTitle: ''
                },
                tabs: {
                  tabsTitle: {
                    identificacion: 'Identificación en',
                    caracteristicas: 'Característica en',
                    detalles: 'Detalles en',
                    fotografias: 'FOTOGRAFÍAS en',
                    auditoria: 'AuditorÍa en'
                  },
                },
                messagesVal: {
                  valNum2Decimales: 'El valor ingresado solo puede tener 2 decimales en',
                  decimal: 'El valor ingresado debe ser decimal en',
                  valEntero: 'El valor ingresado debe ser entero en',
                  validarEnteroNT: 'Debe ser un número entero en',
                  validacionmaxfechaact: 'La fecha no puede ser mayor a la actual en',
                  abscisaFinMayorInicio: 'Abscisa fin debe ser mayor a Abscisa inicio en'
                }, 
                select: {
                  margen: {
                    Derecha: 'Derecha en',
                    Izquierda: 'Izquierda en',
                    Centro: 'Centro en',
                  },
                  sentido: {
                    NORTEName: 'Norte en',
                    NORTEId: 'NORTE',
                    SURName: 'Sur en',
                    SURId: 'SUR',
                    ESTEName: 'Este en',
                    ESTEId: 'ESTE',
                    OESTEName: 'Oeste en',
                    OESTEId: 'OESTE'
                  },
                  interseccionIDES: {
                    DeprimeIntersecta: 'Deprime intersecta en',
                    DeprimeVia: 'Deprime vía en',
                    ElevaIntersecta: 'Eleva intersecta en',
                    ElevaVia: 'Eleva vía en'
                  },
                  interseccionINIV: {
                    Glorieta: 'Glorieta en',
                    ConPare: 'Con pare en',
                    Semaforizada: 'Semaforizada en',
                  },
                  materialINIV: {
                    Adoquin: 'Adoquín en',
                    Afirmado: 'Afirmado en',
                    Concreto: 'Concreto en',
                    Flexible: 'Flexible en',
                    Otro: 'Otro en',
                  },
                  flujo_peatonal: {
                    Alto: 'Alto en',
                    Medio: 'Medio en',
                    Bajo: 'Bajo en',
                  },
                  clase_via_intersecta: {
                    Urbana: 'Urbana en',
                    Rural: 'Rural en',
                  },
                  tipo_via_intersecta: {
                    Primaria: 'Primaria en',
                    Secundaria: 'Secundaria en',
                    Terciaria: 'Terciaria en',
                  },
                  reductores_velocidad: {
                    Si: 'Si en',
                    No: 'No en',
                  },
                  sentidoCarac: {
                    Ascendente: 'Ascendente en',
                    Descendente: 'Descendente en',
                    Doble: 'Doble en',
                  },
                  estado: {
                    Nuevo: 'Nuevo en',
                    Existente: 'Existente en',
                    Reemplazo: 'Reemplazo en',
                  },
                  motivo: {
                    Instalacion: 'Instalación en',
                    ReemplazoProgramado: 'Reemplazo programado en',
                    Accidente: 'Accidente en',
                    Vandalismo: 'Vandalismo en',
                    Otro: 'Otro en',
                  },
                  estados: {
                    Activo: 'Activo en',
                    Inactivo: 'Inactivo en',
                  }
                },
                subTitle: {
                  localizacion: 'Localización en',
                  coordenadas: 'Coordenadas en',
                  coordenadasIniciales: 'Coordenadas Iniciales en',
                  coordenadasFinales: 'Coordenadas Finales en',
                  caracteristicasPasoDesnivel: 'Características del paso a desnivel en',
                },
                boolean: {
                  mantenimiento: '¿Incluir este elemento a un plan de mantenimiento? en'
                }
              },
              redes: {
                title: {
                  createTitle: 'Crear Elemento Redes en',
                  editTitle: 'Editar Elemento Redes en',
                  showTitle: 'Información Elemento Redes en',
                  listTitle: 'Lista Elementos Redes en',
                  localizacion:'Localización en',
                  coordenadasIniciales:'Coordenadas iniciales en',
                  coordenadas:'Coordenadas en',
                  coordenadasFinales:'Coordenadas finales en',
                },
                tabs: {
                  tabsTitle: {
                    identificacion: 'Identificación en',
                    datosAdministrativos: 'Datos Administrativos en',
                    geometria: 'Geometría en',
                    reparaciones: 'Reparaciones en',
                    detalles: 'Detalles en',
                    fotografias: 'FOTOGRAFÍAS en',
                    auditoria: 'AuditorÍa en',
                    caracteristicas: 'Características en',
                  },
                },
                select: {
                  margen: {
                    Derecha: 'Derecha en',
                    Izquierda: 'Izquierda en',
                    Centro: 'Centro en',
                  },
                  sentido: {
                    NORTEName: 'Norte en',
                    NORTEId: 'NORTE',
                    SURName: 'Sur en',
                    SURId: 'SUR',
                    ESTEName: 'Este en',
                    ESTEId: 'ESTE',
                    OESTEName: 'Oeste en',
                    OESTEId: 'OESTE'
                  },
                  tipoRedes: {
                    gas:'Gas en',
                    acueducto:'Acueducto en',
                    alcantarillado:'Alcantarillado en',
                    fibraOptica:'Fibra óptica en',
                    longitudinal:'Longitudinal en',
                    poste:'Poste en',
                    travesia:'Travesía en'
                  },
                  claseIluminacion: {
                    M1: 'M1 en',
                    M2: 'M2 en',
                    M3: 'M3 en',
                    M4: 'M4 en',
                    M5: 'M5 en',
                  },
                  brazoTipo: {
                    A: 'A en',
                    B: 'B en',
                    C: 'C en',
                    D: 'D en',
                    E: 'E en',
                  },
                  material: {              
                    concreto: 'Concreto en',
                    mamposteria: 'Mampostería en',
                    prefabricado: 'Prefabricado en',
                    otro:'Otro en',
                    acero: 'Acero en',
                    cobre:'Cobre en',
                    concretoAcero: 'Concreto y acero en',
                    HDEP:'HDPE en',               
                    polipropileno:'Polipropileno en',
                    PVC: 'PVC en',
                    PEAD: 'PEAD en',
                    barreraJerseyPlastica: 'Barrera jersey plástica en',
                    barreraJerseyPlasticaId: 'Barrera jersey plastica en',
                    malla: 'Malla en',
                    metalica: 'Metálica en',
                    metalicaId: 'Metalica en',
                    plastica: 'Plástica en',
                    plasticaId: 'Plastica en',
                    polisombra: 'Polisombra en',
                    
                  },
                  seccion: {
                    circular: 'Circular en',
                    cuadrada: 'Cuadrada en',
                    rectangular: 'Rectangular en',
                    trapezoidal: 'Trapezoidal en',
                    triangular: 'Triangular en',
                    hexagonal:'Hexagonal en',
                    perfilH:'Perfil H en',
                    otro:'Otro en',
                  },
                  nivelTrafico: {
                    NT1: 'NT1 en',
                    NT2: 'NT2 en',
                    NT3: 'NT3 en',
                  },
                  estado: {
                    Existente: 'Existente en',
                    Reemplazo: 'Reemplazo en',
                    Nuevo: 'Nuevo en',
                  },
                  entornoVia: {
                    claro: 'Claro en',
                    oscuro: 'Oscuro en',
                  },
                  tipoLampara:{
                    descarga:'Descarga en',
                    incandescentes:'Incandescentes en',
                    vaporMercurioAltaPresion:'Vapor de Mercurio a alta presión en',
                    vaporSodioAltaPresion:'Vapor de sodio a alta presión en',
                    VaporSodioBajaPresion:'Vapor de sodio a baja presión en',
                    otro:'Otro en'
                  },
                  disposicionLuminarias:{
                    bilateralPareada:'Bilateral pareada en',
                    centralDobleBrazo:'Central con doble brazo en',
                    combinaciónBrazosDoblesTresbolill:'Combinación brazos dobles y tresbolillo en',
                    unilateralCalzadasDiferenciadas:'Unilateral en calzadas diferenciadas en',
                    unilateralLadoExterior:'Unilateral por el lado exterior en',
                  },
                  claseSemaforo:{
                    semaforoEspecial:'Semáforos especiales en',
                    semaforosControlTránsitoVehículos:'Semáforos para el control del tránsito de vehículos en',
                    semaforosPasosPeatonales:'Semáforos para pasos peatonales en',
                    semaforosSonoros:'Semáforos sonoros en',
                  },
                  clasificacionSoporte:{
                    postes:'Postes en',
                    cablesSuspención:'Cables de Suspención en',
                    estructurasPórtico:'Estructuras tipo Pórtico en',
                    mensulasCortas:'Ménsulas Cortas en',
                    mensulasLargasPostesLaterales:'Ménsulas Largas Sujetas a Postes Laterales en',
                    postesPedestalesIslas:'Postes y Pedestales en Islas en',
                  },
                  condicionElemento: {
                    existente: 'Existente en',
                    reemplazo: 'Reemplazo en',
                    nuevo: 'Nuevo en',
                  },
                  motivoReemplazo:{
                    accidente: 'Accidente en',
                    instalacion: 'Instalación en',
                    instalacionId: 'Instalacion en',
                    reemplazoProgramado: 'Reemplazo programado en',
                    vandalismo: 'Vandalismo en',
                    otro: 'Otro en',
                  },
                  estados: {
                    Activo: 'Activo en',
                    Inactivo: 'Inactivo en',
                  }
                },
              },
              inventariopeajes: {
                title: {
                  editTitle: 'Editar Inventario Peaje en',
                  showTitle: 'Información Inventario Peaje en',
                  listTitle: 'Lista Inventario Peajes en'
                },
                tabs: {
                  tabsTitle: {
                    identificacion: 'Identificación en',
                    auditoria: 'AuditorÍa en'
                  },
                },
                select: {
                  estado: {
                    Activo: 'Activo en',
                    Inactivo: 'Inactivo en',
                  },
                },
                messagesVal: {
                  valEntero: 'El valor ingresado debe ser entero en',
                }, 
                subTitle: {
                  detalles: 'Detalles en',
                  localizacion: 'Localización en',
                  coordenadas: 'Coordenadas en',
                },
                boolean: {
                  estado: 'Estado en'
                },
              },
              mensuals: {
                title: {
                  editTitle: 'Editar Tránsito Histórico en',
                  showTitle: 'Información Tránsito Histórico en',
                  listTitle: 'Lista Tránsito Histórico en'
                },
                tabs: {
                  tabsTitle: {
                    identificacion: 'Identificación en',
                    historico: 'Histórico en',
                    InformacionBasica: 'Información básica en',
                    InformacionAnual: 'Información anual en',
                    Auditoria:'Auditoría en',
                  }
                },
                select: {
                  sentido: {
                    Ascendente: 'Ascendente en',
                    Descendente: 'Descendente en',
                  },
                  mes: {
                    Enero: 'Enero en',
                    Febrero: 'Febrero en',
                    Marzo: 'Marzo en',
                    Abril: 'Abril en',
                    Mayo: 'Mayo en',
                    Junio: 'Junio en',
                    Julio: 'Julio en',
                    Agosto: 'Agosto en',
                    Septiembre: 'Septiembre en',
                    Octubre: 'Octubre en',
                    Noviembre: 'Noviembre en',
                    Diciembre: 'Diciembre en',
                  }
                },
                subTitle: {
                  VolVehicCategoria: 'Volumen de Vehículos por categoría en',
                  AniosEvaluación: 'Años de evaluación en',
                  total:'Total en',
                },
                graph: {
                  title: 'Histórico en',
                  subTitle: 'Datos total en',
                  Valores: 'Valores en',
                }
              },
              estacion_conteos: {
                title: {
                  editTitle: 'Editar Estación de conteo en',
                  showTitle: 'Información Estación de conteo en',
                  listTitle: ''
                },
                tabs: {
                  tabsTitle: {
                    identificacion: 'Identificación en',
                    detalles: 'Detalles en',
                    ejesEquivalentes: 'Ejes equivalentes en',
                    tasasCrecimiento: 'Tasas de crecimiento en',
                    InformacionBasica: 'Información básica en',
                    auditoria: 'Auditoría en',
                  }
                },
                subTitle: {
                  detalles: 'Detalles en',
                  localizacion: 'Localización en',
                  coordenadas: 'Coordenadas en',
  
                  abscisasAfectacion: 'Abscisas de afectación en',
                },
                messagesVal: {
                  valRango: 'Error el rango debe estar entre  en',
                  valFechaAntFin: 'La fecha inicial debe ser anterior a la final. en',
                  SinResultados: 'Sin Resultados. en'
                },
                boolean: {
                  operativa: 'Operativa en',
                },
                table: {
                  titles: {
                    categoria: 'Categoría en',
                    volumen: 'Volumen en',
                    factor: 'Factor en',
                    distribucionPorcentual: 'Distribución porcentual en',
                  },
                  rows: {
                    autos: 'Autos en',
                    buses: 'Buses en',
                  }
                }
              },
              indicadores_cargar_informacions: {
                title: {
                  editTitle: 'Editar Evaluación en',
                  showTitle: 'Mostrar Evaluación en',
                  listTitle: ''
                },
                subTitle: {
                  SeleccionIndicador: 'Selección del Indicador en',
                  Detalles: 'Detalles en',
                  CargarArchivo: 'Cargar Archivo en',
                  RangoCorreccion: 'Rango de Corrección en'
                },
                tabs: {
                  IndicadorSegmento: 'Indicador Por Segmento en',
                  ResumenRegistros: 'Resumen Registros en',
                },
                select: {
                  medidoPor: {
                    Interventoria: 'Interventoría en',
                    Mantenimiento: 'Mantenimiento en',
                  },
                  estado: {
                    PorRevisar: 'Por Revisar en',
                    Publicada: 'Publicada en',
                    RevisionRealizada: 'Revisión realizada en',
                  },
                  tipoMedicion: {
                    periodica: 'Periódica en',
                    deCorreccion: 'De Corrección en',
                  }
                },
                table: {
                  tableHead: {
                    SEGMENTO: 'SEGMENTO en',
                    IncumpPuntuales: 'Incumplimientos Puntuales en',
                    CumplimientoIndicSegmento: 'Cumplimiento del indicador en el segmento en',
                    Visualizar: 'Visualizar en',
  
                    abscisa: 'ABSCISA en',
                    carrilIzquierdoDescendente: 'CARRIL IZQUIERDO (DESCENDENTE) en',
                    carrilDerechoAscendente: 'CARRIL DERECHO (ASCENDENTE) en',
                    cumplimientoIndicadorCalzada: 'Cumplimiento Indicador Calzada en',
  
                    PromedioCarrilI: 'Promedio carril I en',
                    IncumplPuntualesCarrilI: 'Incumplimientos puntuales carril I en',
                    PromedioCarrilD: 'Promedio carril D en',
                    IncumplPuntualesCarrilD: 'Incumplimientos puntuales carril D en',
                    ValorMedioIRI_mm: 'Valor Medio IRI (mm/m) en',
                    ValorMedAhuellamiento_mm: 'Valor Medio Ahuellamiento (mm) en',
                    CumplimientoValorPromedio: 'Cumplimiento valor promedio en',
                    CumplimientoValorPuntual: 'Cumplimiento valor puntual en',
                    CumplimientoIndicadorSegmento: 'Cumplimiento del indicador en el segmento en',
                    IncumplPuntualesCalzada: 'Incumplimientos puntuales Calzada en',
                    incumplimientosPuntuales: 'Incumplimientos Puntuales en',
  
                    AbscisaInicio: 'Abscisa Inicio en',
                    AbscisaFinal: 'Abscisa Final en',
                    IRImm: 'IRI (mm/m) en',
                    Ahuellamiento_mm: 'Ahuellamiento (mm) en',
                    ValorMedioCFT: 'Valor Medio CFT en',
                    CumplimientoIndicPuntual: 'Cumplimiento indicador puntual en',
                    Evento: 'Evento en',
                    Singularidad: 'Singularidad en',
                    IRImm_m: 'IRI (mm/m) en',
                    CFT: 'CFT en',
                    MTDmmASTME1845: 'MTD (mm) ASTM E1845 en',
  
                    areaCalzada: 'Área calzada (m²) en',
                    areaAfectada: 'Área afectada (m²) en',
                    areaAfectada: 'Área afectada (%) en',
                    cumplimientoIndicador: 'Cumplimiento indicador en',
  
                    AbscisaFisura: 'Abscisa en',
                    TipoFisura: 'Tipo de fisura en',
                    Severidad: 'Severidad en',
                    Largo_m: 'Largo (m) en',
                    Largo_cm: 'Largo (cm) en',
                    Ancho_m: 'Ancho (m) en',
                    Ancho_cm: 'Ancho (cm) en',
                    Area_m: 'Área (m²) en',
  
                    Profundidadcm: 'Profundidad (cm) en',
                    Observaciones: 'Observaciones en',
                    Carril: 'Carril en',
  
                    areaVerdadera: 'Área verdadera en',
                    areaAfectada_m: 'Área afectada (m²) en',
                    areaAfectada: 'Área afectada (%) en',
  
                    Medida1_cm: 'Medida 1 (cm) en',
                    Medida2_cm: 'Medida 2 (cm) en',
                    Medida3_cm: 'Medida 3 (cm) en',
                    Medida4_cm: 'Medida 4 (cm) en',
                    Medida5_cm: 'Medida 5 (cm) en',
                    LimpiezaGenCalzada: 'Limpieza general de calzada en',
                    LaVegetacionAfecta: '¿La vegetación afecta la seguridad? en',
                    NumeroBasuras: 'Número basuras en',
                    Cumplimiento: 'Cumplimiento en',
  
                    Elemento: 'Elemento en',
                    SeccionHidraulColmatada: 'Sección hidráulica colmatada (%) en',
  
                    RetroreflectividadColorPrin: 'Retroreflectividad (cd/lux/m²) color principal en',
                    RetroreflectividadColorSec: 'Retroreflectividad (cd/lux/m²) color secundario en',
                    LegibilidadSenal: 'Legibilidad de la señal en',
                    Localización: 'Localización en',
                    Estabilidad: 'Estabilidad en',
                    EstadoLimpieza: 'Estado de limpieza en',
                    
                    MedLineaBlanBordeDerecho: 'Medida línea blanca borde derecho en',
                    MedLineaBlanBordeIzquierdo: 'Medida línea blanca borde izquierdo en',
                    MedidalineaAmarilla: 'Medida línea amarilla en',
                    CumplimientoDelineadPiso: 'Cumplimiento delineadores de piso en',
                    TipoEquipoGeometria_cm: 'Tipo de equipo con geometría (cm) en',
                    CumplimientoIndicador: 'Cumplimiento del Indicador en',
  
                    Alineacion: 'Alineación en',
                    CondicionEstructural: 'Condición estructural en',
                    Estabilidad: 'Estabilidad en',
                    LongitudIncumplimiento: 'Longitud con incumplimiento (m) en',
  
                    PromedioCarrilIzquierdo: 'Promedio carril Izquierdo en',
                    PromedioCarrilDerecho: 'Promedio carril Derecho en',
                    IncumplimientosPuntuales: 'Incumplimientos puntuales en',
  
                    areaAfectada: 'Área Afectada (%) en',
  
                    CarrilIzquierdo: 'Carril Izquierdo en',
                    CarrilDerecho: 'Carril Derecho en',
  
                    PCI: 'PCI (%) en',
                    CodigoColor: 'Código de Color en',
  
                    Escalonamiento: 'Escalonamiento (mm) en',
                    ExtensionDesportillam: 'Extensión de desportillamientos (mm) en',
  
                    LongitudJuntasAfectadas: 'Longitud de juntas afectadas (m) en',
  
                    FuncionamientoLuminaria: 'Funcionamiento de la luminaria en',
                    EstadofisicoLuminaria: 'Estado físico de la luminaria en',
                    EstadoSoportes: 'Estado de los soportes en',
                    EstadoCentroMando: 'Estado del centro del mando en',
                    EstadoTableroControl: 'Estado del tablero de control en',
                    porcentajeNoCumple:'Porcentaje de elementos que no cumplen en',
                    totalElementos:'Total elementos en',
                    elementosQueNoCumplen:'Elementos con incumpliento en',
  
                    CodigoASTM: 'Código ASTM en',
                    Alto: 'Alto en',
                    Medio: 'Medio en',
                    Bajo: 'Bajo en',
                    mostrar:'Mostrar en',
                    ocultar:'Ocultar en',
                    noCumple:'No Cumple en',
                    Cumple:'Cumple en',
                    noAplica:'No Aplica en',
                    adecuado:'Adecuado en',
                    inadecuado:'Inadecuado en',
                    booleanSi:'Si en',
                    booleanNo:'No en',
                    estadoa: {
                      Bueno: 'Bueno en',
                      Regular: 'Regular en',
                      Malo: 'Malo en',
                    },
                  },
                  tableVisualizacion:{
                    SEGMENTO: 'SEGMENTO en',
                    fechaEvaluacion:'Fecha de evaluación en',
                    estado:'Estado en',
                    medidoPor:'Medido por en',
                    porcentajeElemento:'Porcentaje de elementos defectuosos (%) en',
                    cumplimientoIndicador:'Cumplimiento del indicador en el segmento en',
                    visualizar:'Visualizar en',
                    elemento:'Elemento en',
                    funcionamientoLuminaria:'Funcionamiento de la luminaria en',
                    estadofisicoLuminaria:'Estado físico de la luminaria en',
                    estadoSoportes: 'Estado de los soportes en',
                    estadoCentroMando: 'Estado del centro del mando en',
                    estadoTableroControl: 'Estado del tablero de control en',
                    observaciones:'Observaciones en',
                    cumplimiento:'Cumplimiento en',
                    puente:'Puente en',
                    fechaInspeccion:'Fecha de inspección en',
                    totalAreaAfectada:'Total área afectada en',
                    porcentajeAreaAfectada:'Porcentaje área afectada en',
                    deterioroJuntas:'Deterioros en juntas de expansión en',
                    deterioroAndenes:'Deterioros en andenes y bordillos en',
                    deterioroBarreras:'Deterioros en barreras de seguridad en',
                    fisuraEstructura:'Fisuras en estructura en',
                    exposicionAcero:'Exposición de acero de refuerzo en',
                    corrosion:'Corrosión en',
                    deterioroDrenaje:'Deterioros en drenaje en',
                    promedioCarrilIzquierdo:'Promedio carril Izquierdo en',
                    promedioCarrilDerecho:'Promedio carril Derecho en',
                    incumplimientosPuntuales:'Incumplimientos puntuales en',
                    nivelTrafico:'Nivel de Tráfico en:',
                    abscisa:'Abscisa en',
                    carrilIzquierdo:'Carril Izquierdo en',
                    carrilDerecho:'Carril Derecho en',
                    Escalonamiento:'Escalonamiento (mm) en',
                    ExtensionDesportillam: 'Extensión de desportillamientos (mm) en',
                    areaAfectada: 'Área Afectada (%) en',
                    LongitudJuntasAfectadas: 'Longitud de juntas afectadas (m) en',
                    CondicionEstructural:'Condición estructural en',
                    estabilidad:'Estabilidad en',
                    LongitudIncumplimiento: 'Longitud con incumplimiento (m) en',
                    medidaLineaBlancaDerecho:'Medida línea blanca borde derecho en',
                    medidaLineaBlancaIzquierdo:'Medida línea blanca borde izquierdo en',
                    medidalineaAmarilla:'Medida línea amarilla en',
                    cumplimientoDelineadores:'Cumplimiento delineadores de piso en',
                    TipoEquipoGeometria_cm: 'Tipo de equipo con geometría (cm) en',
                    PromedioCarrilI: 'Promedio carril I en',
                    IncumplPuntualesCarrilI: 'Incumplimientos puntuales carril I en',
                    PromedioCarrilD: 'Promedio carril D en',
                    IncumplPuntualesCarrilD: 'Incumplimientos puntuales carril D en',
                    ValorMedioIRI_mm: 'Valor Medio IRI (mm/m) en',
                    ValorMedAhuellamiento_mm: 'Valor Medio Ahuellamiento (mm) en',
                    ValorMedioCFT: 'Valor Medio CFT en',
                    CumplimientoValorPromedio: 'Cumplimiento valor promedio en',
                    CumplimientoValorPuntual: 'Cumplimiento valor puntual en',
                  },
                  tableMessege: 'No se tiene visualización por segmento para este indicador en',
                },
                messagesVal: {
                  fechaMedicion:'La fecha no puede ser mayor a la actual en',
                  noTieneVisualizacion:'No se tiene visualización para este indicador. en',
                  noSeEncontro:'No se encontraron datos. en',
                  Alineacion:'Alineación en',
                }
              },
              calificacion_puentes: {
                title: {
                  editTitle: 'Editar Calificación Puentes en',
                  showTitle: 'Información Calificación Puentes en',
                },
                tabs: {
                  tabsTitle: {
                    detalles: 'Detalles en',
                    Inspeccion: 'Inspección en',
                    fotografias: 'FOTOGRAFÍAS en',
                    auditoria: 'Auditoría en',
                  }
                },
                select: {
                  medidoPor: {
                    Interventoria: 'Interventoría en',
                    Mantenimiento: 'Mantenimiento en',
                  },
                  estado: {
                    PorRevisar: 'Por revisar en',
                    RevisionRealizada: 'Revisión realizada en',
                    Publicada: 'Publicada en',
                  },
                  tipoMedicion: {
                    Periodica: 'Periódica en',
                    DeCorreccion: 'De corrección en',
                  }
                },
                tableShow: {
                  titleTable: {
                    Activo: 'Activo en',
                    Cantidad: 'Cantidad en',
                    LugarDetalle: 'Lugar detalle en',
                  },
                },
                inspeccion: {
                  dato: 'Dato en',
                  LugarDetalle: 'Lugar Detalle en',
                  tablero: {
                    TableroTitle: 'Tablero en',
                    SuperficieGrietas: 'Superficie con grietas (m²) en',
                    Area: 'Área (m²) en',
                    SuperficieDanada: 'Superficie dañada (m²) en',
                    SuperficieDesconchados: 'Superficie con desconchados (m²) en',
                    TotalAreaAfectada: 'Total área afectada (m²) en',
                    PorcentajeAreaAfectada: 'Porcentaje área afectada en',
                    SumaAreas: 'Suma de áreas en',
                    Calculo: 'Cálculo (%) en'
                  },
                  juntasExpansion: {
                    juntasExpansionTitle: 'Juntas de Expansión en',
                    NumeroJuntasExpan: 'Número de juntas de expansión en',
                    ObstruccionSello: 'Obstrucción del sello (m) en',
                    RupturaSello: 'Ruptura del sello (m) en',
                    AusenciaSello: 'Ausencia del sello (m) en',
                    AgrietamientoRoturaSoldad: 'Agrietamiento o rotura de soldaduras (m) en',
                    AusenciaAnclajes: 'Ausencia de anclajes (m) en',
                    PerfilesDefectuosos: 'Perfiles defectuosos (m) en',
                    PerfilesSueltos: 'Perfiles sueltos (m) en',
                    DesgasteGuardacantos: 'Desgaste en guardacantos (m) en',
                    DesportillamientoGuardacantos: 'Desportillamiento en guardacantos (m) en',
                    FisuramientoGuardacantos: 'Fisuramiento de guardacantos (m) en',
                    JuntaFuncional: 'Junta no funcional en',                    
                  },
                  AndenesBordillos: {
                    AndenesBordillosTitle: 'Andenes / Bordillos en',
                    Desportillamiento: 'Desportillamiento (m) en',
                    AceroRefuerzoExpuesto: 'Acero de refuerzo expuesto (m) en',
                    DimensionesInsuficientes: 'Dimensiones insuficientes (m) en',
                  },
                  BarrSegBaranPretilMetal: {
                    Title: 'Barreras de seguridad, barandillas o pretiles metálicos en',
                    ConexionesMalEstado: 'Conexiones en mal estado (m) en',
                    ProteccionesMalEstado: 'Protecciones en mal estado (m) en',
                    Pintura: 'Pintura en',
                    Delaminacion: 'Delaminación (m) en',
                    Ausencia: 'Ausencia (m) en',
                    Deterioro: 'Deterioro (m) en',
                  },
                  Postes: {
                    titlePostes: 'Postes en',
                    Fracturamiento: 'Fracturamiento (m) en',
                    Ausencia: 'Ausencia (m) en',
                    GolpesImpacto: 'Golpes por impacto (m) en',
                    Grietas5mm: 'Grietas > 5 mm (m) en',
                    Desconchados10mm: 'Desconchados > 10 mm (m) en',
                  },
                  Pasamanos: {
                    titlePasamanos: 'Pasamanos en',
                    Corrosión: 'Corrosión (m) en',
                    AusenciaElementos: 'Ausencia de elementos (m) en',
                    GolpesImpacto: 'Golpes por impacto (m) en',
                    Grietas5mm: 'Grietas > 5 mm (m) en',
                    Desconchados10mm: 'Desconchados > 10 mm (m) en',
                  },
                  DanosEstrucConcreto: {
                    Title: 'Daños Estructura de concreto en',
                    Subestructura: 'Subestructura en',
                    FisurasFlexion: 'Fisuras por flexión (m) en',
                    FisurasCortante: 'Fisuras por cortante (m) en',
                    FisurasTorsion: 'Fisuras por torsión (m) en',
                    AplastamientoLocal: 'Aplastamiento local (Und) en',
                    Asentamientos: 'Asentamientos (Und) en',
                    Volcamiento: 'Volcamiento (Und) en',
                    Segregación: 'Segregación (m²) en',
                    Hormigueros: 'Hormigueros (m²) en',
                    FisurasRetraccion: 'Fisuras por retracción (m²) en',
                    RecubrimientoInadecuado: 'Recubrimiento inadecuado (m²) en',
                    ExposicionAceroRef: 'Exposición del acero de refuerzo (m²) en',
                    Infiltracion: 'Infiltración (m²) en',
                    Eflorescencias: 'Eflorescencias (m²) en',
                    Carbonatacion: 'Carbonatación (m²) en',
                    CorrosionArmadura: 'Corrosión en la armadura (m/m²) en',
                    FallasImpacto: 'Fallas por impacto (m²) en',
                    Socavacion: 'Socavación (m²) en',
                    Erosion: 'Erosión (m²) en',
  
                    DanosEstrucMetalicas: 'Daños Estructuras Metálicas en',
                    CorrosionLeve: 'Corrosión leve (Und) en',
                    CorrosionMedia: 'Corrosión media (Und) en',
                    CorrosionSevera: 'Corrosión severa (Und) en',
                    PinturaDeteriorada: 'Pintura deteriorada (%) en',
  
                    CablesPendolones: 'Cables y pendolones en',
                    PerdidaRecubriCables: 'Pérdida de recubrimiento de cables (m) en',
                    FaltaTensionCablesPendol: 'Falta de tensión de cables y pendolones (Und) en',
                    FisurasAlambres: 'Fisuras en alambres (Und) en',
                    FisurasMuertosAnclajes: 'Fisuras en muertos de anclajes (Und) en',
                    ContaminacionZonaAnclajes: 'Contaminación en zona de anclajes (Und) en',
  
                    PerfMetalTorresMiembrosArmad: 'Perfiles metálicos, Torres y miembros de armaduras en',
                    PandeoLocal: 'Pandeo local (Und) en',
                    PandeoGeneralLateral: 'Pandeo general lateral (Und) en',
                    FisurasVigas: 'Fisuras en vigas (Und) en',
                    FallasImpacto: 'Fallas por impacto (Und) en',
                    DeflexionExcesiva: 'Deflexión excesiva (Und) en',
  
                    Conexiones: 'Conexiones en',
                    AusenciaConectores: 'Ausencia de conectores (Und) en',
                    Excentricidades: 'Excentricidades (Und) en',
                    FallasTensionPlatina: 'Fallas por tensión en la platina (Und) en',
                    AplastamientoPlatina: 'Aplastamiento en la platina (Und) en',
                    FallasDesgarramiento: 'Fallas por desgarramiento (Und) en',
                    FallaCorteConector: 'Falla por corte en el conector (Und) en',
                    FallaBloqueCortante: 'Falla por bloque de cortante (Und) en',
                    RoturaSoldadura: 'Rotura de la soldadura (Und) en',
  
                    Drenaje: 'Drenaje en',
                    SuperficieErosionada: 'Superficie erosionada (Und) en',
                    DanoEstructural: 'Daño estructural (Und) en',
                    Taponamiento: 'Taponamiento (Und) en',
                    Ausencia: 'Ausencia (Und) en',
                    LongitudInsuficiente: 'Longitud insuficiente (Und) en',
  
                  },
                  lugar_detalle: {
                    EntradaPuente: 'Entrada del puente en',
                    SalidaPuente: 'Salida del puente en',
                    JuntaIntermedia: 'Junta intermedia en',
                    CostadoDerecho: 'Costado derecho en',
                    CostadoIzquierdo: 'Costado izquierdo en',
                    Losa: 'Losa en',
                    Vigas: 'Vigas en',
                    Riostras: 'Riostras en',
                    Apoyos: 'Apoyos en',
                    Arcos: 'Arcos en',
                    Cimentacion: 'Cimentación en',
                    Rotulas: 'Rótulas en',
                    ArcosMetalicos: 'Arcos metálicos en',
                    PerfilesMetalicos: 'Perfiles metálicos en',
                    Armaduras: 'Armaduras en',
                    Conexiones: 'Conexiones en',
                    Cables: 'Cables en',
                    Pendolones: 'Pendolones en',
                    Torres: 'Torres en',
                  },
                }
              },
              disponibilidad_vias: {
                title: {
                  editTitle: 'Editar Disponibilidad de Vía en',
                  showTitle: 'Información de Disponibilidad de Vía en',
                  listTitle: 'Lista de Disponibilidad Vías en'
                },
                tabs: {
                  tabsTitle: {
                    identificacion: 'IDENTIFICACIÓN en',
                    detalles: 'DETALLES en',
                    caracteristicas: 'CARACTERÍSTICAS en',
                    fotografias: 'FOTOGRAFÍAS en',
                    InformacionBasica: 'Información básica en',
                    auditoria: 'Auditoría en',
                    finalizar: 'FINALIZAR en'
                  }
                },
                select: {
                  tipoEvento: {
                    Incidente: 'Incidente en',
                    Accidente: 'Accidente en',
                    Cierre: 'Cierre en',
                  },
                  tipoCierre: {
                    Calzada: 'Calzada en',
                    Carril: 'Carril en',
                  },
                  margen: {
                    Izquierda: 'Izquierda en',
                    Derecha: 'Derecha en',
                  },
                  fuenteRep: {
                    Llamada: 'Llamada en',
                    Email: 'Email en',
                    Recorredor: 'Recorredor en',
                  },
                  causa: {
                    Accidente: 'Accidente en',
                    Derrumbe: 'Derrumbe en',
                    TrabajosVia: 'Trabajos en vía en',
                    Manifestacion: 'Manifestación en',
                    OrdenPublico: 'Orden público en',
                    Otro: 'Otro en',
                  },
                  elementoAfect: {
                    Calzada: 'Calzada en',
                    Puente: 'Puente en',
                    Tunel: 'Túnel en',
                    Bermas: 'Bermas en',
                  },
                  serviciosDirectorio: {
                    Emergencias: 'Emergencias en',
                    Proveedores: 'Proveedores en',
                    ServiciosPublicos: 'Servicios Públicos en',
                  },
                  grupoEmergencias: {
                    POBSupervisionVial: 'POB-Supervisión vial en',
                    POBAdministrativa: 'POB-Administrativa en',
                    POBRadioOperacion: 'POB-Radio Operación en',
                    POBGruas: 'POB-Grúas en',
                    POBCarroTaller: 'POB-Carro taller en',
                    POBAmbulancia: 'POB-Ambulancia en',
                    POBPeajes: 'POB-Peajes en',
                    Interventoria: 'Interventoría en',
                    ServiciosMedicos: 'Servicios médicos en',
                    Policia: 'Policía en',
                    Ejercito: 'Ejército en',
                    DefensaCivil: 'Defensa civil en',
                    Bomberos: 'Bomberos en',
                    CentroEducativo: 'Centro educativo en',
                    Alcaldia: 'Alcaldía en',
                    EmisoraRadial: 'Emisora radial en',
                    Talleres: 'Talleres en',
                    Maquinaria: 'Maquinaria en',
                    Otros: 'Otros en',
                  },
                  grupo: {
                    Acueducto: 'Acueducto en',
                    GasNatural: 'Gas natural en',
                    Energia: 'Energía en',
                    Telecomunicaciones: 'Telecomunicaciones en',
                    Aseo: 'Aseo en',
                    Otros: 'Otros en',
                  },
                  tipoAccidente: {
                    Simple: 'Simple en',
                    Heridos: 'Heridos en',
                    Muertos: 'Muertos en',
                  },
                  tipoHeridos: {
                    Peaton: 'Peatón en',
                    Ciclista: 'Ciclista en',
                    Motociclista: 'Motociclista en',
                    Conductor: 'Conductor en',
                    Pasajero: 'Pasajero en',
                    Otro: 'Otro en',
                  },
                  generoHeridos: {
                    Hombre: 'Hombre en',
                    Mujer: 'Mujer en',
                  },
                  categoriaVehiculos: {
                    Peaton: 'Peatón en',
                    Bicicleta: 'Bicicleta en',
                    Motocicleta: 'Motocicleta en',
                    Automovil: 'Automóvil en',
                    Taxi: 'Taxi en',
                    Bus: 'Bus en',
                    Camion: 'Camión en',
                    MaquinaConstr: 'Maquinaria construcción en',
                    MaquinariaAgricola: 'Maquinaria agrícola en',
                    ObjetoFijo: 'Objeto fijo en',
                    ObjetoMovil: 'Objeto móvil en',
                    Otros: 'Otros en',
                  },
                  fechaSenal: {
                    Enero: 'Enero en',
                    Febrero: 'Febrero en',
                    Marzo: 'Marzo en',
                    Abril: 'Abril en',
                    Mayo: 'Mayo en',
                    Junio: 'Junio en',
                    Julio: 'Julio en',
                    Agosto: 'Agosto en',
                    Septiembre: 'Septiembre en',
                    Octubre: 'Octubre en',
                    Noviembre: 'Noviembre en',
                    Diciembre: 'Diciembre en',
                  }
                },
                subTitle: {
                  localizacion: 'Localización en',
                  AsociarServicios: 'Asociar Servicios en',
                  JuicioPolicia: 'Juicio de la Policía en',
                  Heridos: 'Heridos en',
                  VictimasFatales: 'Víctimas Fatales en',
                  VehiculosImplicados: 'Vehículos Implicados en',
                  fechaAmbulanciaRegistrada: 'La fecha de ambulancia ya fue registrada. en',
                  NoSegmentosCierre: 'No. Segmentos con cierre en',
                  fechaGruaRegistrada:'La fecha de grua ya fue registrada en',
                  disponibilidadVia:'La disponibilidad de vía actual ya fue finalizada en',
                  finalizar:'Finalizar en',
                  directorio:'Directorio en',
                  grupoSistema:'Grupo / Sistema en',
                  elemento:'Elemento en',
                }
              },
              parametros_analises: {
                title: {
                  editTitle: 'Editar Parámetros de planeación en',
                  showTitle: 'Información Parámetros de planeación en',
                  listTitle: ''
                },
                tabs: {
                  tabsTitle: {
                    InformacionBasica: 'Información básica en',
                    auditoria: 'Auditoría en',
                  }
                },
                table: {
                  titleTable: 'Presupuesto disponible en',
                  nameColumns: {
                    Anio: 'Año en',
                    PresupMantenPavimento: 'Presupuesto mantenimiento pavimento en',
                    PresupMantenOtrosElemen: 'Presupuesto mantenimiento otros elementos en',
                    PresupTotal: 'Presupuesto Total en',
                  }
                }
              },
              politicas_mantenimientos: {
                title: {
                  editTitle: 'Editar política de Mantenimiento en',
                  showTitle: 'Información Política de Mantenimiento en',
                  listTitle: 'Lista Políticas de Mantenimiento en',
                },
                tabs: {
                  tabsTitle: {
                    identificacion: 'IDENTIFICACIÓN en',
                    auditoria: 'Auditoría en',
                    INFORMACION: 'INFORMACION en',
                    actualizarNombre:'Actualizar Nombre de Política en',
                    politica:'Seleccione Política en',
                    nuevoNombre:'Nuevo nombre en',
                    noDiligenciado:'No Diligenciado en',
                  }
                },
                expansionPanel: {
                  ElemenMantenRutinario: 'Elementos para Mantenimiento Rutinario en',
                  ElemenMantenPeriodico: 'Elementos para Mantenimiento Periódico en',
                  ElemenMantenRehabilitacion: 'Elementos para Mantenimiento Rehabilitación en',
                  ElemenMantenReconstruccion_Cambio: 'Elementos para Mantenimiento Reconstrucción/Cambio en',
                },
                boolean: {
                  seRealiza: 'Se Realiza en',
                },
                select: {
                  periocidad: {
                    Mes1: '1 Mes en',
                    Meses3: '3 Meses en',
                    Meses6: '6 Meses en',
                    Meses9: '9 Meses en',
                    Meses12: '12 Meses en',
                    Meses18: '18 Meses en',
                    Meses24: '24 Meses en',
                    Meses36: '36 Meses en',
                    anios2: '2 Años en',
                    anios2_5: '2,5 Años en',
                    anios3: '3 Años en',
                    anios3_5: '3,5 Años en',
                    anios4: '4 Años en',
                    anios4_5: '4,5 Años en',
                    anios5: '5 Años en',
                    anios5_5: '5,5 Años en',
                    anios6: '6 Años en',
                    anios6_5: '6,5 Años en',
                    anios7: '7 Años en',
                    anios7_5: '7,5 Años en',
                    anios8: '8 Años en',
                    anios8_5: '8,5 Años en',
                    anios9: '9 Años en',
                    anios9_5: '9,5 Años en',
                    anios10: '10 Años en',
                    anios11: '11 Años en',
                    anios12: '12 Años en',
                    anios13: '13 Años en',
                    anios14: '14 Años en',
                    anios15: '15 Años en',
                    anios16: '16 Años en',
                    anios17: '17 Años en',
                    anios18: '18 Años en',
                    anios19: '19 Años en',
                    anios20: '20 Años en',
                    anios21: '21 Años en',
                    anios22: '22 Años en',
                    anios23: '23 Años en',
                    anios24: '24 Años en',
                    anios25: '25 Años en',
                    anios26: '26 Años en',
                    anios27: '27 Años en',
                    anios28: '28 Años en',
                    anios29: '29 Años en',
                    anios30: '30 Años en',
                  },
                  operacion: {
                    reconstruccion: 'Reconstrucción en',
                    cambio: 'Cambio en',
                  }
                },
                messages: {
                  NingunaPolDefinida: 'Ninguna Política Definida en',
                },
              },
              politicas_mantenimiento_particulars: {
                title: {
                  editTitle: 'Editar política de Mantenimiento Particular en',
                  showTitle: 'Información Política de Mantenimiento Particular en',
                  listTitle: 'Lista Política de Mantenimiento Particular en',
                },
                tabs: {
                  tabsTitle: {
                    identificacion: 'IDENTIFICACIÓN en',
                    auditoria: 'Auditoría en',
                    informacion: 'INFORMACION en',
                  }
                },
                select: {
                  rutinario: {
                    Mes1: '1 Mes en',
                    Meses3: '3 Meses en',
                    Meses6: '6 Meses en',
                    Meses9: '9 Meses en',
                    Meses12: '12 Meses en',
                    Meses18: '18 Meses en',
                  },
                  periodico: {
                    Mes1: '1 Mes en',
                    Meses3: '3 Meses en',
                    Meses6: '6 Meses en',
                    Meses9: '9 Meses en',
                    Meses12: '12 Meses en',
                    Meses18: '18 Meses en',
                    Meses24: '24 Meses en',
                    Meses36: '36 Meses en',
                  },
                  rehabilitacion: {
                    anios2: '2 Años en',
                    anios2_5: '2,5 Años en',
                    anios3: '3 Años en',
                    anios3_5: '3,5 Años en',
                    anios4: '4 Años en',
                    anios4_5: '4,5 Años en',
                    anios5: '5 Años en',
                    anios5_5: '5,5 Años en',
                    anios6: '6 Años en',
                    anios6_5: '6,5 Años en',
                    anios7: '7 Años en',
                    anios7_5: '7,5 Años en',
                    anios8: '8 Años en',
                    anios8_5: '8,5 Años en',
                    anios9: '9 Años en',
                    anios9_5: '9,5 Año ens',
                    anios10: '10 Años en',
                  },
                  cambio: {
                    anios3: '3 Años en',
                    anios4: '4 Años en',
                    anios5: '5 Años en',
                    anios6: '6 Años en',
                    anios7: '7 Años en',
                    anios8: '8 Años en',
                    anios9: '9 Años en',
                    anios10: '10 Años en',
                    anios11: '11 Años en',
                    anios12: '12 Años en',
                    anios13: '13 Años en',
                    anios14: '14 Años en',
                    anios15: '15 Años en',
                    anios16: '16 Años en',
                    anios17: '17 Años en',
                    anios18: '18 Años en',
                    anios19: '19 Años en',
                    anios20: '20 Años en',
                    anios21: '21 Años en',
                    anios22: '22 Años en',
                    anios23: '23 Años en',
                    anios24: '24 Años en',
                    anios25: '25 Años en',
                    anios26: '26 Años en',
                    anios27: '27 Años en',
                    anios28: '28 Años en',
                    anios29: '29 Años en',
                    anios30: '30 Años en',
                  }
                },
                boolean: {
                  seRealiza: 'Se Aplica Política Particular en',
                  seRealizaModif: 'Se Modifica en',
                },
                expansionPanel: {
                  Elementos: 'Elementos en',
                  MantenimientoRutinario: 'Mantenimiento particular Rutinario en',
                  MantenimientoPeriodico: 'Mantenimiento particular Periódico en',
                  MantenimientoRehabilitacion: 'Mantenimiento particular Rehabilitación en',
                  MantenimientoCambio: 'Mantenimiento particular Reconstrucción/Cambio en',
                },
                messages: {
                  SinResultados: 'Sin Resultados. en',
                  SinPoliticaPartiDilig: 'Sin Politica Particular Diligenciada. en',
                  SinElementos:'Sin elementos asociados en',
                  tramo:'Sin Tramo Definido. en',
                  absInicial:'Sin Abscisa Inicial Definida. en',
                  absFinal:'Sin Abscisa Final Definida. en',
                },
                table: {
                  nameColumns: {
                    Periodicidad: 'Periodicidad en',
                    ValorU: 'Valor U. en',
                    FechaU: 'Fecha U. en',
                    Fuente: 'Fuente en',
                    FechaIniPeriodicidad: 'Fecha de Inicio de Periodicidad en',
                    Descripcion: 'Descripción en',
                    idInventario:'ID en Inventario en',
                    absInicial:'Abscisa Inicial en',
                    absFinal:'Abscisa Final en',
                    politicaParticular:'Aplica Política Particular en',
                  },
                  periocidad: {
                    mes: ' Mes en',
                    meses: ' Meses en',
                    anos: ' Años en',
                  }
                }
              },
              trayectorias: {
                title: {
                  editTitle: 'Editar Alternativas en',
                  showTitle: 'Información Alternativas en',
                  listTitle: 'Lista Alternativas en'
                },
                tabs: {
                  Alternativas: 'Alternativas en',
                  auditoria: 'Auditoría en'
                },
                tableEspesor: {
                  nameColumns: {
                    Espesores: 'Espesores en',
                    CodigoMaterial: 'Código material en',
                  },
                  nameRows: {
                    EspesorCapa1: 'Espesor capa 1 (mm) en',
                    EspesorCapa2: 'Espesor capa 2 (mm) en',
                    EspesorCapa3: 'Espesor capa 3 (mm) en',
                    EspesorCapa4: 'Espesor capa 4 (mm) en',
                    EspesorCapa1Show: 'Espesor capa 1 en',
                    EspesorCapa2Show: 'Espesor capa 2 en',
                    EspesorCapa3Show: 'Espesor capa 3 en',
                    EspesorCapa4Show: 'Espesor capa 4 en',
                  }
                },
                tableIndicador: {
                  nameColumns: {
                    Indicador: 'Indicador en',
                    Mejorar: 'Mejorar en',
                    Limite: 'Limite en',
                  },
                  nameRows: {
                    IRI: 'IRI(mm/m) en',
                    Friccion: 'Fricción en',
                    Ahuellamiento: 'Ahuellamiento(mm) en',
                    PCI: 'PCI(%) en',
                  }
                },
                boolean: {
                  rigido: 'Rígido en',
                  flexible: 'Flexible en',
                },
                messages:{
                  precioTotalIgual:'El precio total no puede ser igual al precio carpeta. en',
                  precioTotalMayor:'El precio total debe ser mayor al precio carpeta. en',
                }
              },
              modelo_costos_pers: {
                title: {
                  editTitle: 'Editar Deterioro PERS en',
                  showTitle: 'Modelo de costos PERS en',
                  listTitle: 'Reparaciones menores en'
                },
                tabs: {
                  identificacion: 'Identificación en',
                  auditoria: 'Auditoría en'
                },
                buttons: {
                  guadarAgregar: 'GUADAR Y AGREGAR en',
                  guadarFinalizar: 'GUADAR Y FINALIZAR en',
                },
                modalFormulas: {
                  btnFormulas: 'Fórmulas en',
                  titleModal: 'Fórmulas de Cálculo en',
                  CostoReparacion: 'Costo de reparación = a*PCI-b*ÁREA SECCIÓN (m2) en',
                  Costo1: 'Costo = 0 si PCI es mayor a x en',
                  Costo2: 'Costo = 0 si costo reparación del segmento es menor a y en',
                },
                tableVariable: {
                  title: 'Variable en',
                  nameColumns: {
                    Flexible: 'Flexible en',
                    Rigido: 'Rígido en',
                  },
                  nameRows: {
                    variableA: 'Variable a en',
                    variableB: 'Variable b en',
                    variableX: 'Variable x en',
                    variableY: 'Variable y en',
                  }
                },
                tableFlexible: {
                  titleTable: 'Flexible en',
                  nameColumns: {
                    PCI: 'PCI en',
                    CostoBase: 'Costo Base en',
                    CostoConsidRestr: 'Costo considerando restricciones en',
                  }
                },
                tableRigido: {
                  titleTable: 'Rígido en',
                  nameColumns: {
                    PCI: 'PCI en',
                    CostoBase: 'Costo Base en',
                    CostoConsidRestr: 'Costo considerando restricciones en',
                  }
                },
                grafica: {
                  titleGraph: 'Reparaciones menores en',
                  axisY: 'Costo considerando restricciones  (miles $) en',
                  axisX: 'Valor de PCI en',
                  serieFlexible: 'Flexible en',
                  serieRigido: 'Rígido en',
                }
              },
              pers_exports: {
                btnGenerarInf: 'Generar Informe en',
                title:'Lista Pers en',
                btnDescargar:'Descargar en',
                btnCrearInforme:'Crear informe en',
                titleCrearInforme:'Crear Informe PERS en',
                modal: {
                  title: 'Crear Informe PERS en',
                }
              },
              costos: {
              title: {
                editTitle: 'Editar Costos en',
                showTitle: 'Información Costos en'
              },
              tabs: {
                identificacion: 'IDENTIFICACIÓN en',
                auditoria: 'AuditorÍa en',
                informacion: 'Informacion en'
              },
              boolean: {
                rango: '¿Por Rango de Abscisas? en'
              },
              modal: {
                visualizarCostos:'Visualizar Costos en',
                btnPlantilla: 'Plantilla.csv en',
                titleModal: 'Plantilla para Carga Masiva en',
                text1: 'La carga masiva requiere de una primera línea con los nombres de las columnas como se indica a continuación: en',
                nameColumns: 'codigo;tipo de mantenimiento;fecha mantenimiento;costo mantenimiento;calificacion;observaciones;proveedor en',
                text2: 'Los datos siguientes, deben corresponder en orden con dichas columnas. Por ejemplo: en',
                ejemplo1: 'UF1A::DRE::TR1::SG03::ZCOR::001;pe;2/01/2020;2;2;observaciones;90012345678 en',
                ejemplo2: 'UF1A::DRE::TR1::SG01::POZT::001;re;3/01/2020;3;3;observaciones;90012345678 en',
                ejemplo3: 'UF1A::DRE::TR1::SG01::CUNT::001;ca;4/01/2020;4;4;observaciones;90012345678 en',
                text3: 'Los datos ingresados son según su origen excepto el tipo de mantenimiento, que debe indicarse usando las siglas [ru | pe | re | ca] para indicar los tipos de mantenimiento Rutinario, Periodico, Rehabilitación y Cambio respectivamente en',
                nota: 'NOTA: el valor de proveedor se indica con el Número de Identificación registrado en la aplicación. en',
                text4: 'Descargue la plantilla en CSV con valores de ejemplo usando el siguiente enlace: en',
                enlace: 'Enlace a Plantilla en',
              },
              exportar:{
                datos:'Datos en',
                exportarBusqueda:'Exportar Búsqueda en',
                busquedaActual:'¿Exportar la busqueda actual como un archivo de excel? en',
                enlace:'Enlace de Descarga en',
              },
              select: {
                tipoMantenimientos: {
                  Rutinario: 'Rutinario en',
                  Periodico: 'Periódico en',
                  Rehabilitacion: 'Rehabilitación en',
                  Cambio: 'Cambio en',
                }
              },
              tableDatos: {
                titleTable: 'Datos en',
                columnNames: {
                  codigo: 'Código en',
                  tipoMantenimiento: 'Tipo de Mantenimiento en',
                  fechaMantenimiento2: 'Fecha de Mantenimiento en',
                  costoMantenimiento: 'Costo en',
                  razonSocial: 'Proveedor en',
                  calificacion: 'Calif. en',
                  observaciones: 'Observaciones en',
                  tipoMantenShow: 'Tipo de Mant. en',
                  fechaMantenShow: 'Fecha de Mant. en',
                  tipo:'Tipo en',
                  fecha:'Fecha en',
                  costo:'Costo en',
                  proveedor:'Proveedor en',
                  calificacion:'Calificación en',
                  rutinario:'Total Costos Mantenimiento Rutinario en',
                  periodico:'Total Costos Mantenimiento Periódico en',
                  rehabilitacion:'Total Costos Mantenimiento Rehabilitación en',
                  cambio:'Total Costos Mantenimiento Cambio en',
                  costoPorMantenimiento:'Costos por Mantenimiento en',
                  costoEn:'Costo en $ en',
                  tiempo:'Tiempo en',
                }
              },
              modalEdit: {
                title: 'Editar en ',
              },
              modalVisual: {
                btnVisual: 'Visualización en',
              }
              },
              flujo_cajas: {
                title: {
                  editTitle: 'Editar Flujo de Caja en',
                  showTitle: 'Información Flujo de Caja en',
                  listTitle: 'Lista Flujos de Caja en',
                },
                tabs: {
                  identificacion: 'IDENTIFICACIÓN en',
                  informacion: 'informacion en',
                  auditoria: 'AuditorÍa en'
                },
                messages: {
                  ConsInfoMatriz: 'Consultando Información de la Matriz en',
                  generarMatriz:'Generar Matriz en',
                  generarMatriz2:'¿Generar una Matriz? en',
                  confirmacionMatriz:'¿Seguro que desea generar una matriz? en',
                }
              },
              validaciones: {
                ValorMaximoAbsCreate: ' El valor ingresado no puede ser mayor a PR999+999 en',
                ValorMaximoPrCreate: 'El valor ingresado no puede ser mayor a PR999+999 en',
                validarLogicaAbsFinCreate: 'Abscisa fin debe ser mayor a Abscisa inicio en',
                AbsRamal: 'La distancia entre las Abscisas de ser menor a 3.000 en',
                validarLogicaPrFinCreate: 'PR fin debe ser mayor a PR inicio en',
                latMininima: 'El rango debe estar entre en ',
                latMininimay: ' y en ',
                lngMininima: 'El rango debe estar entre en ',
                lngMininimay: ' y en ',
                altitud: 'El rango debe estar entre en ',
                altitudy: ' y en ',
                isFechaInvalida2000: 'La fecha debe ser a partir del año 2000 en',
                isFechaInvalidaSup: 'La fecha indicada no puede ser futura. en',
                Entero: 'Debe ser un número entero en',
                abValidationInicio: 'Error el rango debe estar entre ',
                abValInicioy: ' y en ',
                abValidationFin: 'en Error el rango debe estar entre ',
                abValFiny: ' y en ',
                prValidationInicio: 'en Error el rango debe estar entre ',
                prValIniy: ' y en ',
                prValidationFin: 'en Error el rango debe estar entre ',
                prValnFiny: ' y en ',
                MansajeValidadorAb: 'Rango no válido en'
              },
              indicador_complementarios: {
                title: {
                  editTitle: 'Editar Conf. Ind. Complementario en',
                  showTitle: 'Información Conf. Ind. Complementario en',
                  listTitle: 'Lista de Conf. Ind. Complementarios en',
                },
                subTitle: {
                  preguntas: 'Preguntas: en',
                  respuestas: 'Respuestas: en',
                  consideracionesPeso: 'Consideraciones Peso en'
                },
                tabs: {
                  identificacion: 'IDENTIFICACIÓN en',
                  informacion: 'informacion en',
                  auditoria: 'AuditorÍa en',
                  preguntas: 'Preguntas en'
                },
                select: {
                  estado: {
                    Activo: 'Activo en',
                    Inactivo: 'Inactivo en'
                  },
                  tipoCampo: {
                    seleccionMultiple: 'Selección Múltiple en',
                    numerico: 'Numérico en',
                  }
                },
              },
              respuesta_evaluacion_complementarios: {
                title: {
                  list:'Lista de Indicadores Complementarios en',
                  editTitle: 'Editar Indicador Complementario en',
                  showTitle: 'Información Indicador Complementario en',
                  listTitle: 'Lista de Conf. Ind. Complementarios en',
                },
                subTitle: {
                  indicadores: 'Detalles Indicadores Complementarios en',
                  resultados:'Resultados Indicadores Complementarios en',
                },
                tabs: {
                  filtro: 'FILTRO en',
                  registro: 'Registro en',
                  auditoria: 'AuditorÍa en',
                  preguntas: 'Preguntas en'
                },
                select: {
                  accion: {
                    Nada: 'Nada en',
                    Mantenimiento: 'Mantenimiento en',
                    Reparar: 'Reparar en',
                    Reemplazar: 'Reemplazar en'
                  },
                  estado: {
                    Activo: 'Activo en',
                    enProceso: 'En proceso en',
                    Cerrado: 'Cerrado en',
                  },
                },
                condiciones: {
                  numerico: 'Numérico',
                  seleccionMultiple: 'Selección Múltiple'
                }
              },
              registro_novedads: {
                title: {
                  editTitle: 'Editar Configuración de Novedad en',
                  showTitle: 'Información Conf. de Novedad en',
                  listTitle: 'Lista Configuración de Novedades en',
                },
                subTitle: {
                  preguntas: 'Preguntas: en',
                  respuestas: 'Respuestas: en',
                  consideracionesPeso: 'Consideraciones Peso en'
                },
                tabs: {
                  identificacion: 'IDENTIFICACIÓN en',
                  informacion: 'informacion en',
                  auditoria: 'AuditorÍa en',
                  preguntas: 'Preguntas en'
                },
                select: {
                  estado: {
                    Activo: 'Activo en',
                    Inactivo: 'Inactivo en'
                  },
                  tipoCampo: {
                    seleccionMultiple: 'Selección Múltiple en',
                    numerico: 'Numérico en',
                  }
                },
              },
              respuesta_evaluacion_novedads: {
                title: {
                  editTitle: 'Editar Reg. de Novedad-Medición en',
                  showTitle: 'Info. Reg. de Novedad-Medición en',
                  listTitle: 'Lista de Reg. Novedades-Mediciones en',
                },
                subTitle: {
                  localizacion: 'Localización en',
                  coordIniciales: 'Coordenadas Iniciales en',
                  coordFinales: 'Coordenadas Finales en',
                  deterioro: 'Deterioro en',
                  mediciones: 'Mediciones en',
                  indicadores: 'Detalles Registros Novedades en',
                  resultados:'Resultados Registros Novedades en',
                },
                tabs: {
                  filtro: 'FILTRO en',
                  registro: 'Registro en',
                  auditoria: 'AuditorÍa en',
                  preguntas: 'Preguntas en'
                },
                select: {
                  estado: {
                    activo: 'Activo en',
                    proceso: 'En proceso en',
                    cerrado: 'Cerrado en',
                  },
                  tipoRegistro: {
                    novedad: 'Novedad en',
                    medicion: 'Medición en',
                    medicionId: 'Medicion en',
                  }
                },
                condiciones: {
                  numerico: 'Numérico en',
                  seleccionMultiple: 'Selección Múltiple en'
                },
                tableMediciones: {
                  columns: {
                    mediciones: 'Mediciones en',
                    tipoElemento: 'Tipo de elemento en',
                    modelo: 'Modelo en',
                    codigo: 'Código en',
                    retroReflecColorPrin: 'Retro-reflectividad color principal (cd/lux/m²) en',
                    disminucionPrincipal:'Disminución color principal (%) en',
                    retroReflecColorSec: 'Retro-reflectividad color secundario (cd/lux/m²) en',
                    disminucionSecundario:'Disminución color secundario (%) en',
                    posicion: 'Posición en',
                    legibilidad: 'Legibilidad en',
                    observaciones: 'Observaciones en',
                  }
                },
                mensajes: {
                  borrar: '¿Borrar? en'
                }
              },
              aprobacions: {
                title: {
                  listTitle: 'Lista Aprobaciones en',
                },
                select: {
                  tipoRegistro: {
                    novedad: 'Novedad en',
                    medicion: 'Medición en',
                    medicionId: 'Medicion en',
                    Inventario: 'Inventario en',
                    IndicadorComplementario: 'Indicador complementario en'
                  },
                  aprobacion: {
                    aprobado: 'Aprobado en',
                    rechazado: 'Rechazado en'
                  }
                },
                buttons: {
                  aprobar: 'APROBAR en',
                  rechazar: 'RECHAZAR en',
                  mostrar: 'MOSTRAR en'
                },
                modalTitle: "Duplicate record",
                modalMessaje: "A similar record was found with pending status, do you want to approve or reject this record?",
              },
              config_sistema: {
                title: {
                  listTitle: 'Configuración General en',
                },
                title_cards: 'System',
                titles_cards: {
                  idioma: 'System language',
                  moneda: 'Coin',
                  logos: 'Logo'
                },
                btnEditar: 'Edit',
                btnCancelar: 'Cancel',
                btnAceptar: 'Accept',
                btnConfirmar: 'Confirm',
                btnEspanol:'ESPAÑOL en',
                btnIngles:'INGLÉS en',
                btnPortugues:'PORTUGUÉS en',
                cards: {
                  pesos: 'Pesos (COP) en',
                  logo: 'PNG format - max 800x600',
                  dropzone: 'Drag and drop the image or click to select an image'
                },
                mensajesModals: {
                  titulo: 'Change system language' ,
                  idioma: 'Are you sure you want to change the system language? on',
                  titleLogo: 'Change project logo in',
                  logo: 'Are you sure you want to change the project logo? on'
                }
              },
              idiomas_config: {
                title: 'Idiomas en',
                editTitle:'Editar idioma en',
                showTitle:'Información idioma en',
                fields:{
                  idioma:'Idioma en',
                  region:'Región en',
                  nombreIdioma:'Nombre de idioma adicionado en',
                },
                cards: {
                  eliminar: 'Eliminar en',
                  cargarInfoIdioma: 'Cargar información idioma en',
                  descManualUsuario: 'Descargar manual de usuario en',
                  descargar: 'Descargar en',
                  activo: 'Activo en'
                },
                modulo_administracion: 'Archivo de traducción en',
              },
              carga_masivas: {
                title: {
                  listTitle: 'Lista carga masiva en',
                  createTitle: 'Crear carga masiva en',
                  editTitle: 'Visualización carga masiva en'
                },
                button: {
                  verTodos: 'Ver todos en',
                  cargarArcdeNuevo: 'Cargar archivos de nuevo en',
                  cancelar: 'Cancelar en',
                  guardar: 'Guardar en',
                  confirmar: 'Confirmar en',
                  alertas: 'Alerta ens',
                  errores: 'Errores en',
                  descargar: 'Descargar en',
                  siConfirmar: 'Sí, continuar en'
                },
                boolean: {
                  imagenes: '¿Archivo de imagenes? en'
                },
                message: {
                  dialogFilterEmpty: 'Para continuar con la carga de los archivos, por favor registre los datos del sistema desde el icono de editar y el nombre del grupo en',
                  dialogErrores: 'Se encontraron errores, revíselos en el archivo original y vuelva a cargarlo. en',
                  dialogAlerts: 'Se encontraron alertas, puede confirmarlos y continuar la carga del archivo. en',
                  dialogAddAlert: '*No puede confirmar las alertas porque se encontraron errores, revíselos en el archivo original y vuelva a cargarlo. en',
                  dialogConfirmAlert: '¿Está seguro que desea continuar con la carga teniendo alertas? en',
                  dialogStopQ: '¿Está seguro que quiere detener la carga? en',
                  dialogStop: 'Si detiene la carga se perderá el progreso que lleva actualmente. en',
                  dialogMsgIdioma: 'El idioma del proyecto está configurado en '
                }
              }
        },
        uniqueEmail: {
            email: 'Email Address'
        },
        select: {
            adoquinEnArcilla: 'Paver in Clay'
        }
    }
}
