import React, { Fragment, useState } from 'react';
import {
    AutocompleteInput, SelectInput, Filter, WithPermissions, ReferenceInput, List, Datagrid, TextField, NumberField,
    EditButton, ShowButton, BulkDeleteWithConfirmButton, CardActions, CreateButton, ExportButton, ReferenceArrayField,
    SingleFieldList, ChipField, Loading
} from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import { translate } from 'react-admin';
/* PERMISOS */
const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props} />
    </Fragment>
);
const ListAction = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
        <ExportButton basePath={basePath}/>
    </CardActions>
);
const ListAction2 = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
    </CardActions>
);
const styles = {
    ocultar: {
        display: "none",
    },
};


const VariosFilterList = translate((props) => {
    const { translate } = props;
    const [departamento, setDepartamento] = useState();
    const ValidarDepartamento = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setDepartamento(valor)
    }

    return (
        <Filter {...props}>
            <ReferenceInput
                label="resources.servicios_publicos.fileds.departamento"
                source="departamento"
                reference="departamentos"
                onChange={ValidarDepartamento}
                alwaysOn
                sort={{ field: "nombre", order: "ASC" }}
                filterToQuery={searchText => ({ nombre: searchText })}
            >
                <AutocompleteInput optionText="nombre" fullWidth />
            </ReferenceInput>

            <ReferenceInput
                label="resources.servicios_publicos.fileds.municipios"
                source="municipio"
                reference="municipios"
                alwaysOn
                sort={{ field: "nombre", order: "ASC" }}
                filterToQuery={searchText => ({ nombre: searchText })}
                filter={{ departamento: departamento }}
            >
                <AutocompleteInput optionText="nombre" fullWidth />
            </ReferenceInput>
            <SelectInput label="resources.servicios_publicos.fileds.grupo" source="grupo" allowEmpty emptyValue="" choices={[
                { id: 'Acueducto', name: 'dynatest.resources.servicios_publicos.select.grupo.acueducto' },
                { id: 'Aseo', name: 'dynatest.resources.servicios_publicos.select.grupo.aseo' },
                { id: 'Energía', name: 'dynatest.resources.servicios_publicos.select.grupo.energia' },
                { id: 'Gas natural', name: 'dynatest.resources.servicios_publicos.select.grupo.gasNatural' },
                { id: 'Otros', name: 'dynatest.resources.servicios_publicos.select.grupo.otros' },
                { id: 'Telecomunicaciones', name: 'dynatest.resources.servicios_publicos.select.grupo.telecomunicaciones' },
            ]} alwaysOn />
        </Filter>

    )
});

export const Servicio_publicoList = translate(({ permissions, ...props }) => {
    const { translate } = props;
    if (!permissions) return <Loading />;
    return (
        <List {...props} 
            sort={{ field: 'updatedAt', order: 'DESC' }} 
            filters={<VariosFilterList />} 
            actions={permissions['serpublicos']  == 1 ? <ListAction /> : permissions['serpublicos']  == 2 ? <ListAction2 />: null} 
            bulkActionButtons={permissions['serpublicos'] == 1 ? <PostBulkActionButtons /> : null} >
            <Datagrid>
                <ReferenceArrayField label="resources.servicios_publicos.fileds.municipio" source="municipio" reference="municipios" sortable={false}>
                    <SingleFieldList>
                        <ChipField source="nombre" />
                    </SingleFieldList>
                </ReferenceArrayField>
                <TextField label="resources.servicios_publicos.fileds.grupo" source="grupo" />
                <TextField label="resources.servicios_publicos.fileds.empresa" source="empresa" />
                <NumberField label="resources.servicios_publicos.fileds.numero_identificacion" source="numero_identificacion" sortable={false} />
                <TextField label="resources.servicios_publicos.fileds.direccion" source="direccion" sortable={false} />
                <NumberField label="resources.servicios_publicos.fileds.telefono_1" source="telefono_1" sortable={false} />
                {permissions['serpublicos'] == 1 || permissions['serpublicos'] == 2 || permissions['serpublicos'] == 4 ? <ShowButton/>: null}
                {permissions['serpublicos'] == 1 || permissions['serpublicos'] == 2 ? <EditButton/>: null}
            </Datagrid>
        </List>
    )
});