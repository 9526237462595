import React, { Fragment } from 'react';
import { NumberInput, DisabledInput, TextInput, BooleanInput, FormDataConsumer, Datagrid, TextField, BooleanField } from 'react-admin';
import {
    required,
    minValue,
    maxValue,
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core';
import { styles } from './../../EstilosGrid';
import loader from './../../loader10.gif';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
//import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import SearchIcon from '@material-ui/icons/Search';
import config from '../../config/config';
import E1 from './imagenes/E1.jpg';
import E2 from './imagenes/E2.jpg';
import E3 from './imagenes/E3.jpg';
import E4 from './imagenes/E4.jpg';
import E5 from './imagenes/E5.jpg';
import E6 from './imagenes/E6.jpg';
import E7 from './imagenes/E7.jpg';
import E8 from './imagenes/E8.jpg';
import E10 from './imagenes/E10.jpg';
import E11 from './imagenes/E11.jpg';
import E12 from './imagenes/E12.jpg';
import E13 from './imagenes/E13.jpg';
import E14 from './imagenes/E14.jpg';
import E15 from './imagenes/E15.jpg';
import E16 from './imagenes/E16.jpg';
import E24 from './imagenes/E24.jpg';
import E25 from './imagenes/E25.jpg';
import { validarUnDecimal, Entero, validarDosDecimales } from './../validacionesInputs';
import { translate } from 'react-admin';


let urlAppjs = config.UrlServer;

class Parametros extends React.Component {

    constructor(props) {
        super(props)
        this.state = { tipoparametro: this.props.aplicaind, datoPar: [], record: [], tipo: "", registros: 0, validacionesreq: [] }
    }

    componentDidMount() {

        fetch(urlAppjs + "/parametros.json?perPage=40")
            .then((response) => {
                return response.json()
            }).then((response) => {
                //console.log(response)
                if (response) {
                    this.setState({ datoPar: response })
                }
            });
        let idproyecto = localStorage.getItem("id_project");
        if (idproyecto) {
            fetch(urlAppjs + "/parametros_grupos.json?perPage=40&proyecto=" + idproyecto)
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    let largo = response.length;
                    this.setState({ registros: largo })
                })
        }

        this.setState({ record: this.props.record })
        this.setState({ tipo: this.props.tipo })
        if (this.props.record.evaluacion && this.props.tipo === "editar") {
            let evaluacion = this.props.record.evaluacion;
            let aplicaevaluacion = []
            let aplicavalor = false;
            evaluacion.map((data, i) => {
                if (data.aplica === 1) { aplicavalor = true } else { aplicavalor = false }
                aplicaevaluacion[i] = aplicavalor;
            })
            this.setState({ validacionesreq: aplicaevaluacion })
        }

    }


    render() {
        const { translate } = this.props;
        const { tipoparametro } = this.state;
        const { datoPar } = this.state;
        const { record } = this.state;
        const { tipo } = this.state;
        const { registros } = this.state;
        const { validacionesreq } = this.state;
        const ValidarAplica = (valor, valor2) => {
            let aux = [];
            let largo = datoPar.length;
            //aux[0]=valor2.target.checked;
            //aux[1]=valor;
            for (let i = 0; i < largo; i++) {
                let aux2 = document.getElementsByName('aplica_' + i)
                if (aux2) {
                    aux[i] = aux2[0].checked
                }
            }

            this.setState({ validacionesreq: aux })
        }
        //console.log(record)
        const classes = styles;
        let tipoDeParametro = this.props.aplicaind;
        let codigo = [];
        let codigo2 = [];
        let elementoAp = [];
        let parametroid = [];
        let estilo = [];
        let estilo2 = [];
        let estilo3 = [];
        let estilo4 = [];
        let campos1 = [];
        let campos2 = [];
        let campos3 = [];
        let campos4 = [];
        let campos5 = [];
        let campos6 = [];
        let campos7 = [];
        let campos8 = [];
        let medidaalerta = [];
        let validaciones = [];
        let imagenes = [
            E1, //E1 - IRI
            E2, //E2 - Ahuellamiento
            E3, //E3 - Fisuras
            E4, //E4 - Coeficiente de Fricción Transversal
            E5, //E5 - Textura
            E6, //E6 - Baches
            E7, //E7 - Hundimientos
            E8, //E8 - Estado de Márgenes, separador central. Área de servicio y Corredor del proyecto
            E10, //E10 - Drenajes Superficiales, longitudinal y transversal
            E11, //E11 - Señalización Vertical
            E12, //E12 - Señalización Horizontal
            E13, //E13 - Barreras y elementos de contención
            E14, //E14 - Iluminación
            E15, //E15 - Puentes y Estructuras
            E16, //E16 - Capacidad Estructural (10-2 mm)
            null, //PCI - Índice de Condición del Pavimento
            null, //ASTM - Deterioros
            null, //DECAD - Decrecimiento admisible del módulo
            null, //RL - Vida residual
            null, //REPCO - Costos de reparación
            null, //E22 - Escalonamiento
            E24, //E24 - Desportillamientos
            E25, //E25 - Junta Afectada
        ]
        console.log(datoPar)
        datoPar.map((data, i) => {
            codigo.push(data.codigo + " - " + data.nombre);
            codigo2.push(data.codigo);
            elementoAp.push(data.elementoAplica);
            parametroid.push(data.id);
            campos1.push(data.campo1);
            campos2.push(data.campo2);
            campos3.push(data.campo3);
            campos4.push(data.campo4);
            campos5.push(data.campo5);
            campos6.push(data.campo6);
            campos7.push(data.campo7);
            campos8.push(data.campo8);
            let medida = "";
            if (data.campo3) {
                medida = data.campo3.split('(');
            } else if (!data.campo3 && data.campo1) {
                medida = data.campo1.split('(');
            }
            if (medida[1]) {
                medidaalerta.push("(" + medida[1]);
            }
            else {
                medidaalerta.push("");
            }

            let validacionesCampo = [];
            validacionesCampo[0] = []; validacionesCampo[1] = []; validacionesCampo[2] = []; validacionesCampo[3] = []; validacionesCampo[4] = []; validacionesCampo[5] = []; validacionesCampo[6] = []; validacionesCampo[7] = [];
            if (data.validacionCampo1 && data.validacionCampo1 != null && data.validacionCampo1 != "") {
                let auxval = data.validacionCampo1.split(',');
                let decimales = parseFloat(auxval[1]);
                if (decimales === 1) {
                    decimales = validarUnDecimal;
                }
                else if (decimales === 2) {
                    decimales = validarDosDecimales;
                }
                else {
                    decimales = Entero;
                }
                let maxymin = auxval[0].split('a');
                let valorminimo = parseFloat(maxymin[0]);
                let valormax = parseFloat(maxymin[1]);

                validacionesCampo[0]['valorminimo'] = valorminimo;
                validacionesCampo[0]['valormaximo'] = valormax;
                validacionesCampo[0]['decimales'] = decimales;
            }
            if (data.validacionCampo2 && data.validacionCampo2 != null && data.validacionCampo2 != "") {
                let auxval = data.validacionCampo2.split(',');
                let decimales = parseFloat(auxval[1]);
                if (decimales === 1) {
                    decimales = validarUnDecimal;
                }
                else if (decimales === 2) {
                    decimales = validarDosDecimales;
                }
                else {
                    decimales = Entero;
                }
                let maxymin = auxval[0].split('a');
                let valorminimo = parseFloat(maxymin[0]);
                let valormax = parseFloat(maxymin[1]);


                validacionesCampo[1]['valorminimo'] = valorminimo;
                validacionesCampo[1]['valormaximo'] = valormax;
                validacionesCampo[1]['decimales'] = decimales;
            }
            if (data.validacionCampo3 && data.validacionCampo3 != null && data.validacionCampo3 != "") {
                let auxval = data.validacionCampo3.split(',');
                let decimales = parseFloat(auxval[1]);
                if (decimales === 1) {
                    decimales = validarUnDecimal;
                }
                else if (decimales === 2) {
                    decimales = validarDosDecimales;
                }
                else {
                    decimales = Entero;
                }
                let maxymin = auxval[0].split('a');
                let valorminimo = parseFloat(maxymin[0]);
                let valormax = parseFloat(maxymin[1]);


                validacionesCampo[2]['valorminimo'] = valorminimo;
                validacionesCampo[2]['valormaximo'] = valormax;
                validacionesCampo[2]['decimales'] = decimales;
            }
            if (data.validacionCampo4 && data.validacionCampo4 != null && data.validacionCampo4 != "") {
                let auxval = data.validacionCampo4.split(',');
                let decimales = parseFloat(auxval[1]);
                if (decimales === 1) {
                    decimales = validarUnDecimal;
                }
                else if (decimales === 2) {
                    decimales = validarDosDecimales;
                }
                else {
                    decimales = Entero;
                }
                let maxymin = auxval[0].split('a');
                let valorminimo = parseFloat(maxymin[0]);
                let valormax = parseFloat(maxymin[1]);


                validacionesCampo[3]['valorminimo'] = valorminimo;
                validacionesCampo[3]['valormaximo'] = valormax;
                validacionesCampo[3]['decimales'] = decimales;
            }
            if (data.validacionCampo5 && data.validacionCampo5 != null && data.validacionCampo5 != "") {
                let auxval = data.validacionCampo5.split(',');
                let decimales = parseFloat(auxval[1]);
                if (decimales === 1) {
                    decimales = validarUnDecimal;
                }
                else if (decimales === 2) {
                    decimales = validarDosDecimales;
                }
                else {
                    decimales = Entero;
                }
                let maxymin = auxval[0].split('a');
                let valorminimo = parseFloat(maxymin[0]);
                let valormax = parseFloat(maxymin[1]);


                validacionesCampo[4]['valorminimo'] = valorminimo;
                validacionesCampo[4]['valormaximo'] = valormax;
                validacionesCampo[4]['decimales'] = decimales;
            }
            if (data.validacionCampo6 && data.validacionCampo6 != null && data.validacionCampo6 != "") {
                let auxval = data.validacionCampo6.split(',');
                let decimales = parseFloat(auxval[1]);
                if (decimales === 1) {
                    decimales = validarUnDecimal;
                }
                else if (decimales === 2) {
                    decimales = validarDosDecimales;
                }
                else {
                    decimales = Entero;
                }
                let maxymin = auxval[0].split('a');
                let valorminimo = parseFloat(maxymin[0]);
                let valormax = parseFloat(maxymin[1]);


                validacionesCampo[5]['valorminimo'] = valorminimo;
                validacionesCampo[5]['valormaximo'] = valormax;
                validacionesCampo[5]['decimales'] = decimales;
            }
            if (data.validacionCampo7 && data.validacionCampo7 != null && data.validacionCampo7 != "") {
                let auxval = data.validacionCampo7.split(',');
                let decimales = parseFloat(auxval[1]);
                if (decimales === 1) {
                    decimales = validarUnDecimal;
                }
                else if (decimales === 2) {
                    decimales = validarDosDecimales;
                }
                else {
                    decimales = Entero;
                }
                let maxymin = auxval[0].split('a');
                let valorminimo = parseFloat(maxymin[0]);
                let valormax = parseFloat(maxymin[1]);


                validacionesCampo[6]['valorminimo'] = valorminimo;
                validacionesCampo[6]['valormaximo'] = valormax;
                validacionesCampo[6]['decimales'] = decimales;
            }
            if (data.validacionCampo8 && data.validacionCampo8 != null && data.validacionCampo8 != "") {
                let auxval = data.validacionCampo8.split(',');
                let decimales = parseFloat(auxval[1]);
                if (decimales === 1) {
                    decimales = validarUnDecimal;
                }
                else if (decimales === 2) {
                    decimales = validarDosDecimales;
                }
                else {
                    decimales = Entero;
                }
                let maxymin = auxval[0].split('a');
                let valorminimo = parseFloat(maxymin[0]);
                let valormax = parseFloat(maxymin[1]);


                validacionesCampo[7]['valorminimo'] = valorminimo;
                validacionesCampo[7]['valormaximo'] = valormax;
                validacionesCampo[7]['decimales'] = decimales;
            }
            if (!estilo2[i]) estilo2[i] = [];
            if (!validaciones[i]) validaciones[i] = [];
            if (data.elementoAplica === tipoDeParametro || tipoDeParametro === "All") {
                estilo.push("")
                if (data.campo1 && data.campo1 != null && data.campo1 != "") {
                    estilo2[i][0] = "";
                    estilo3[i] = ""
                    estilo4[i] = "22%"
                    if (validacionesreq[i] === true) {
                        if (validacionesCampo[0]) {
                            validaciones[i][0] = [required(), minValue(validacionesCampo[0]['valorminimo']), maxValue(validacionesCampo[0]['valormaximo']), validacionesCampo[0]['decimales']];
                        }
                        else {
                            validaciones[i][0] = [required()];
                        }
                    }
                    else {
                        if (validacionesCampo[0] && validacionesCampo[0]['decimales']) {
                            validaciones[i][0] = [minValue(validacionesCampo[0]['valorminimo']), maxValue(validacionesCampo[0]['valormaximo']), validacionesCampo[0]['decimales']]
                        } else {
                            validaciones[i][0] = [minValue(validacionesCampo[0]['valorminimo']), maxValue(validacionesCampo[0]['valormaximo'])]
                        }
                    }
                } else {
                    estilo2[i][0] = "none";
                    estilo3[i] = "10px"
                    estilo4[i] = "32%"
                    validaciones[i][0] = []
                }
                if (data.campo2 && data.campo2 != null && data.campo2 != "") {
                    estilo2[i][1] = "";
                    estilo3[i] = ""
                    estilo4[i] = "22%"

                    if (validacionesreq[i] === true) {
                        if (validacionesCampo[1]) {
                            validaciones[i][1] = [required(), minValue(validacionesCampo[1]['valorminimo']), maxValue(validacionesCampo[1]['valormaximo']), validacionesCampo[1]['decimales']];
                        }
                        else {
                            validaciones[i][1] = [required()]
                        }

                    }
                    else {
                        if (validacionesCampo[1] && validacionesCampo[1]['decimales']) {
                            validaciones[i][1] = [minValue(validacionesCampo[1]['valorminimo']), maxValue(validacionesCampo[1]['valormaximo']), validacionesCampo[1]['decimales']]
                        } else {
                            validaciones[i][1] = [minValue(validacionesCampo[1]['valorminimo']), maxValue(validacionesCampo[1]['valormaximo'])]
                        }
                    }
                } else {
                    estilo2[i][1] = "none";
                    estilo3[i] = "10px"
                    estilo4[i] = "32%"
                    validaciones[i][1] = []
                }
                if (data.campo3 && data.campo3 != null && data.campo3 != "") {
                    estilo2[i][2] = "";
                    estilo3[i] = ""
                    estilo4[i] = "22%"
                    if (validacionesreq[i] === true) {
                        if (validacionesCampo[2]) {
                            validaciones[i][2] = [required(), minValue(validacionesCampo[2]['valorminimo']), maxValue(validacionesCampo[2]['valormaximo']), validacionesCampo[2]['decimales']];
                        }
                        else {
                            validaciones[i][2] = [required()]
                        }

                    }
                    else {
                        if (validacionesCampo[2] && validacionesCampo[2]['decimales']) {
                            validaciones[i][2] = [minValue(validacionesCampo[2]['valorminimo']), maxValue(validacionesCampo[2]['valormaximo']), validacionesCampo[2]['decimales']]
                        } else {
                            validaciones[i][2] = [minValue(validacionesCampo[2]['valorminimo']), maxValue(validacionesCampo[2]['valormaximo'])]
                        }
                    }
                } else {
                    estilo2[i][2] = "none";
                    estilo3[i] = "10px"
                    estilo4[i] = "32%"
                    validaciones[i][2] = []
                }
                if (data.campo4 && data.campo4 != null && data.campo4 != "") {
                    estilo2[i][3] = "";
                    estilo3[i] = ""
                    estilo4[i] = "22%"
                    if (validacionesreq[i] === true) {
                        if (validacionesCampo[3]) {
                            validaciones[i][3] = [required(), minValue(validacionesCampo[3]['valorminimo']), maxValue(validacionesCampo[3]['valormaximo']), validacionesCampo[3]['decimales']];
                        }
                        else {
                            validaciones[i][3] = [required()]
                        }

                    }
                    else {
                        if (validacionesCampo[3] && validacionesCampo[3]['decimales']) {
                            validaciones[i][3] = [minValue(validacionesCampo[3]['valorminimo']), maxValue(validacionesCampo[3]['valormaximo']), validacionesCampo[3]['decimales']]
                        } else {
                            validaciones[i][3] = [minValue(validacionesCampo[3]['valorminimo']), maxValue(validacionesCampo[3]['valormaximo'])]
                        }
                    }
                } else {
                    estilo2[i][3] = "none";
                    estilo3[i] = "10px"
                    estilo4[i] = "32%"
                    validaciones[i][3] = []
                }
                if (data.campo5 && data.campo5 != null && data.campo5 != "") {
                    estilo2[i][4] = "";
                    estilo3[i] = "10px"
                    estilo4[i] = "22%"
                    if (validacionesreq[i] === true) {
                        if (validacionesCampo[4]) {
                            validaciones[i][4] = [required(), minValue(validacionesCampo[4]['valorminimo']), maxValue(validacionesCampo[4]['valormaximo']), validacionesCampo[4]['decimales']];
                        }
                        else {
                            validaciones[i][4] = [required()]
                        }

                    }
                    else {
                        if (validacionesCampo[4] && validacionesCampo[4]['decimales']) {
                            validaciones[i][4] = [minValue(validacionesCampo[4]['valorminimo']), maxValue(validacionesCampo[4]['valormaximo']), validacionesCampo[4]['decimales']];
                        } else {
                            validaciones[i][4] = [minValue(validacionesCampo[4]['valorminimo']), maxValue(validacionesCampo[4]['valormaximo'])];
                        }
                    }
                } else {
                    estilo2[i][4] = "none";
                    estilo3[i] = "10px"
                    estilo4[i] = "32%"
                    validaciones[i][4] = []
                }

                if (data.campo6 && data.campo6 != null && data.campo6 != "") {
                    estilo2[i][5] = "";
                    estilo3[i] = "10px"
                    estilo4[i] = "22%"
                    if (validacionesreq[i] === true) {
                        if (validacionesCampo[5]) {
                            validaciones[i][5] = [required(), minValue(validacionesCampo[5]['valorminimo']), maxValue(validacionesCampo[5]['valormaximo']), validacionesCampo[5]['decimales']];
                        }
                        else {
                            validaciones[i][5] = [required()]
                        }

                    }
                    else {
                        if (validacionesCampo[5] && validacionesCampo[5]['decimales']) {
                            validaciones[i][5] = [minValue(validacionesCampo[5]['valorminimo']), maxValue(validacionesCampo[5]['valormaximo']), validacionesCampo[5]['decimales']];
                        } else {
                            validaciones[i][5] = [minValue(validacionesCampo[5]['valorminimo']), maxValue(validacionesCampo[5]['valormaximo'])];
                        }
                    }
                } else {
                    estilo2[i][5] = "none";
                    estilo3[i] = "10px"
                    estilo4[i] = "32%"
                    validaciones[i][5] = []
                }
                if (data.campo7 && data.campo7 != null && data.campo7 != "") {
                    estilo2[i][6] = "";
                    estilo3[i] = "10px"
                    estilo4[i] = "22%"
                    if (validacionesreq[i] === true) {
                        if (validacionesCampo[6]) {
                            validaciones[i][6] = [required(), minValue(validacionesCampo[6]['valorminimo']), maxValue(validacionesCampo[6]['valormaximo']), validacionesCampo[6]['decimales']];
                        }
                        else {
                            validaciones[i][6] = [required()]
                        }

                    }
                    else {
                        if (validacionesCampo[6] && validacionesCampo[6]['decimales']) {
                            validaciones[i][6] = [minValue(validacionesCampo[6]['valorminimo']), maxValue(validacionesCampo[6]['valormaximo']), validacionesCampo[6]['decimales']];
                        } else {
                            validaciones[i][6] = [minValue(validacionesCampo[6]['valorminimo']), maxValue(validacionesCampo[6]['valormaximo'])];
                        }
                    }
                } else {
                    estilo2[i][6] = "none";
                    estilo3[i] = "10px"
                    estilo4[i] = "32%"
                    validaciones[i][6] = []
                }
                if (data.campo8 && data.campo8 != null && data.campo8 != "") {
                    estilo2[i][7] = "";
                    estilo3[i] = "10px"
                    estilo4[i] = "22%"
                    if (validacionesreq[i] === true) {
                        if (validacionesCampo[7]) {
                            validaciones[i][7] = [required(), minValue(validacionesCampo[7]['valorminimo']), maxValue(validacionesCampo[7]['valormaximo']), validacionesCampo[7]['decimales']];
                        }
                        else {
                            validaciones[i][7] = [required()]
                        }

                    }
                    else {
                        if (validacionesCampo[7] && validacionesCampo[7]['decimales']) {
                            validaciones[i][7] = [minValue(validacionesCampo[7]['valorminimo']), maxValue(validacionesCampo[7]['valormaximo']), validacionesCampo[7]['decimales']];
                        } else {
                            validaciones[i][7] = [minValue(validacionesCampo[7]['valorminimo']), maxValue(validacionesCampo[7]['valormaximo'])];
                        }
                    }
                } else {
                    estilo2[i][7] = "none";
                    estilo3[i] = "10px"
                    estilo4[i] = "32%"
                    validaciones[i][7] = []
                }
            }
            else {
                validaciones[i][0] = []
                validaciones[i][1] = []
                validaciones[i][2] = []
                validaciones[i][3] = []
                validaciones[i][4] = []
                validaciones[i][5] = []
                validaciones[i][6] = []
                validaciones[i][7] = []
                estilo.push("none")
                estilo2[i][0] = "none";
                estilo2[i][1] = "none";
                estilo2[i][2] = "none";
                estilo2[i][3] = "none";
                estilo2[i][4] = "none";
                estilo2[i][5] = "none";
                estilo2[i][6] = "none";
                estilo2[i][7] = "none";
            }
        })
        const textoDerecha = (value) => {
            let largo = datoPar.length;
            if (largo) {
                for (let j = 0; j < largo; j++) {
                    let valor = document.getElementById('frecuenciaMedicion_' + j);
                    let valor2 = document.getElementById('tiempoCorreccion_' + j);
                    let valor3 = document.getElementById('valormaxPuntual_' + j);
                    let valor4 = document.getElementById('valormaxMedio_' + j);
                    let valor5 = document.getElementById('valorAlerta_' + j);
                    let valor6 = document.getElementById('datosminModelacion_' + j);
                    let valor7 = document.getElementById('tiempoAntesAlertaMedicion_' + j);
                    let valor8 = document.getElementById('campoAdicional_' + j);
                    let valor9 = document.getElementById('campoAdicional2_' + j);
                    let valor10 = document.getElementById('campoAdicional3_' + j);
                    let valor11 = document.getElementById('campoAdicional4_' + j);
                    if (valor && valor2 && valor3 && valor4 && valor5 && valor6 && valor7) {
                        valor.style.textAlign = "right";
                        valor2.style.textAlign = "right";
                        valor3.style.textAlign = "right";
                        valor4.style.textAlign = "right";
                        valor5.style.textAlign = "right";
                        valor6.style.textAlign = "right";
                        valor7.style.textAlign = "right";
                        valor8.style.textAlign = "right";
                        valor9.style.textAlign = "right";
                        valor10.style.textAlign = "right";
                        valor11.style.textAlign = "right";
                    }
                }
            }
        }
        const formatocoma = v => {
            if (v && typeof (v) === "number") {
                return toString(v)
            }
            else {
                return v
            }
        }
        let respuestas = [];
        if (record && record.evaluacion) {
            let eva = record.evaluacion;
            eva.map((data, i) => {
                let aplica = false;
                if (data.aplica === 1) { aplica = true } else { aplica = false }
                respuestas[i] = {
                    aplica: aplica,
                    frecuenciaMedicion: data.frecuenciaMedicion,
                    tiempoCorreccion: data.tiempoCorreccion,
                    valormaxPuntual: data.valormaxPuntual,
                    valormaxMedio: data.valormaxMedio,
                    campoAdicional: data.campoAdicional,
                    campoAdicional2: data.campoAdicional2,
                    campoAdicional3: data.campoAdicional3,
                    campoAdicional4: data.campoAdicional4,
                    valorAlerta: data.valorAlerta,
                    datosminModelacion: data.datosminModelacion,
                    tiempoAntesAlertaMedicion: data.tiempoAntesAlertaMedicion,
                    id: data.id
                }
            })
        }
        //console.log(respuestas);
        const MostrarImagen = (valor, valor2) => {
            //alert("Prueba"+valor);
            //console.log(valor)
            let img = document.getElementById('imagen_' + valor)
            if (img) {
                let disp = img.style.display;
                if (disp != "none") {
                    img.style.display = "none"
                }
                else {
                    img.style.display = ""
                }
            }
            //console.log(valor2)
        }
        //console.log(validaciones)
        //console.log(validacionesreq)
        //console.log(record);
        if (tipo === "editar" || tipo === "mostrar") {
            codigo.map((data, i) => {
                if (respuestas[i]) {
                }
                else {
                    respuestas[i] = {
                        aplica: false,
                        frecuenciaMedicion: null,
                        tiempoCorreccion: null,
                        valormaxPuntual: null,
                        valormaxMedio: null,
                        campoAdicional: null,
                        campoAdicional2: null,
                        campoAdicional3: null,
                        campoAdicional4: null,
                        valorAlerta: null,
                        datosminModelacion: null,
                        tiempoAntesAlertaMedicion: null,
                        id: null
                    }
                }
            })
        }
        let validaValorAlerta = {};
        validaValorAlerta['DECAD - Decrecimiento admisible del módulo'] = true;
        validaValorAlerta['RL - Vida residual'] = true;
        validaValorAlerta['REPCO - Costos de reparación'] = true;
        
        if (tipo === "editar") {
            return (
                <Fragment>
                    {codigo.map((data, i) => (
                        <Fragment key={i}>
                            {console.log(data, i)}
                            {console.log(validaValorAlerta.hasOwnProperty(data))}
                            <Typography component="b" variant="b" style={{ width: "90%", display: estilo[i] }}>{data}</Typography>
                            <BooleanInput style={{ width: "10%", display: estilo[i] }} source={"aplica_" + i} label="" defaultValue={respuestas[i].aplica} onChange={(evt) => ValidarAplica(i, evt)} />
                            <Divider style={{ display: estilo[i] }} />
                            <NumberInput label={campos1[i]} source={"frecuenciaMedicion_" + i} id={"frecuenciaMedicion_" + i} style={{ width: "45%", marginLeft: "10px", textAlign: "right", display: estilo2[i][0] }} fullWidth onChange={textoDerecha} defaultValue={respuestas[i].frecuenciaMedicion} validate={validaciones[i][0]} />
                            <NumberInput label={campos2[i]} source={"tiempoCorreccion_" + i} id={"tiempoCorreccion_" + i} style={{ width: "45%", marginLeft: "10px", display: estilo2[i][1] }} fullWidth onChange={textoDerecha} defaultValue={respuestas[i].tiempoCorreccion} validate={validaciones[i][1]} />
                            <NumberInput label={campos3[i]} source={"valormaxPuntual_" + i} id={"valormaxPuntual_" + i} style={{ width: "45%", marginLeft: "10px", display: estilo2[i][2] }} fullWidthonChange={textoDerecha} defaultValue={respuestas[i].valormaxPuntual} validate={validaciones[i][2]} />
                            <NumberInput label={campos4[i]} source={"valormaxMedio_" + i} id={"valormaxMedio_" + i} style={{ width: "45%", marginLeft: "10px", display: estilo2[i][3] }} fullWidth onChange={textoDerecha} defaultValue={respuestas[i].valormaxMedio} validate={validaciones[i][3]} />
                            <NumberInput label={campos5[i]} source={"campoAdicional_" + i} id={"campoAdicional_" + i} style={{ width: "45%", marginLeft: "10px", display: estilo2[i][4] }} fullWidth onChange={textoDerecha} defaultValue={respuestas[i].campoAdicional} validate={validaciones[i][4]} />
                            <NumberInput label={campos6[i]} source={"campoAdicional2_" + i} id={"campoAdicional2_" + i} style={{ width: "45%", marginLeft: "10px", display: estilo2[i][5] }} fullWidth onChange={textoDerecha} defaultValue={respuestas[i].campoAdicional2} validate={validaciones[i][5]} />
                            <NumberInput label={campos7[i]} source={"campoAdicional3_" + i} id={"campoAdicional3_" + i} style={{ width: "45%", marginLeft: "10px", display: estilo2[i][6] }} fullWidth onChange={textoDerecha} defaultValue={respuestas[i].campoAdicional3} validate={validaciones[i][6]} />
                            <NumberInput label={campos8[i]} source={"campoAdicional4_" + i} id={"campoAdicional4_" + i} style={{ width: "45%", marginLeft: "10px", display: estilo2[i][7] }} fullWidth onChange={textoDerecha} defaultValue={respuestas[i].campoAdicional4} validate={validaciones[i][7]} />
                            <NumberInput label={translate('dynatest.resources.parametros_evaluacion.table.titleColumns.valorAlerta') + " " + medidaalerta[i]} source={"valorAlerta_" + i} id={"valorAlerta_" + i} style={{ width: "45%", marginLeft: estilo3[i], display: estilo[i] }} fullWidth onChange={textoDerecha} defaultValue={respuestas[i].valorAlerta} validate={validaValorAlerta.hasOwnProperty(data) == true ? validaciones[i][0] : []} />
                            <NumberInput label="dynatest.resources.parametros_evaluacion.table.titleColumns.datosMinmodelacion" source={"datosminModelacion_" + i} id={"datosminModelacion_" + i} style={{ width: "45%", marginLeft: "10px", display: estilo[i] }} fullWidth onChange={textoDerecha} defaultValue={respuestas[i].datosminModelacion} />
                            <NumberInput label="dynatest.resources.parametros_evaluacion.table.titleColumns.tiempoGenerarAlerta" source={"tiempoAntesAlertaMedicion_" + i} id={"tiempoAntesAlertaMedicion_" + i} style={{ width: "45%", marginLeft: "10px", display: estilo[i] }} fullWidth onChange={textoDerecha} defaultValue={respuestas[i].tiempoAntesAlertaMedicion} />
                            <div align="right" style={{ display: estilo[i] }}><SearchIcon style={{ color: "blue" }} /><span style={{ cursor: "pointer", verticalAlign: "top", color: "blue" }} align="right" onClick={(evt) => MostrarImagen(i, evt)}>Ver más</span></div>
                            <div style={{ display: estilo[i] }}><div style={{ display: "none" }} id={"imagen_" + i}><img style={{ width: "100%", height: "100%" }} src={imagenes[i]} /></div></div>
                            <NumberInput style={{ display: "none" }} source={"idparametro_" + i} defaultValue={parametroid[i]} />
                            <NumberInput style={{ display: "none" }} source={"id_" + i} defaultValue={respuestas[i].id} />
                            <div style={{ width: "100%", marginTop: "20px", display: estilo[i] }}></div>
                        </Fragment>
                    ))

                    }
                    <NumberInput style={{ display: "none" }} source="largoparametro" defaultValue={datoPar.length} />
                </Fragment>
            )
        }
        else if (tipo === "crear" && registros < 1) {
            return (
                <Fragment>
                    {codigo.map((data, i) => (

                        <Fragment key={i}>
                            <Typography component="b" variant="b" style={{ width: "90%", display: estilo[i] }}>{data}</Typography>
                            <BooleanInput style={{ width: "10%", display: estilo[i] }} source={"aplica_" + i} label="" onChange={(evt) => ValidarAplica(i, evt)} />
                            <Divider style={{ display: estilo[i] }} />
                            <NumberInput label={campos1[i]} source={"frecuenciaMedicion_" + i} id={"frecuenciaMedicion_" + i} style={{ width: "45%", marginLeft: "10px", textAlign: "right", display: estilo2[i][0] }} fullWidth onChange={textoDerecha} validate={validaciones[i][0]} />
                            <NumberInput label={campos2[i]} source={"tiempoCorreccion_" + i} id={"tiempoCorreccion_" + i} style={{ width: "45%", marginLeft: "10px", display: estilo2[i][1] }} fullWidth onChange={textoDerecha} validate={validaciones[i][1]} />
                            <NumberInput label={campos3[i]} source={"valormaxPuntual_" + i} id={"valormaxPuntual_" + i} style={{ width: "45%", marginLeft: "10px", display: estilo2[i][2] }} fullWidthonChange={textoDerecha} validate={validaciones[i][2]} />
                            <NumberInput label={campos4[i]} source={"valormaxMedio_" + i} id={"valormaxMedio_" + i} style={{ width: "45%", marginLeft: "10px", display: estilo2[i][3] }} fullWidth onChange={textoDerecha} validate={validaciones[i][3]} />
                            <NumberInput label={campos5[i]} source={"campoAdicional_" + i} id={"campoAdicional_" + i} style={{ width: "45%", marginLeft: "10px", display: estilo2[i][4] }} fullWidth onChange={textoDerecha} validate={validaciones[i][4]} />
                            <NumberInput label={campos6[i]} source={"campoAdicional2_" + i} id={"campoAdicional2_" + i} style={{ width: "45%", marginLeft: "10px", display: estilo2[i][5] }} fullWidth onChange={textoDerecha} validate={validaciones[i][5]} />
                            <NumberInput label={campos7[i]} source={"campoAdicional3_" + i} id={"campoAdicional3_" + i} style={{ width: "45%", marginLeft: "10px", display: estilo2[i][6] }} fullWidth onChange={textoDerecha} validate={validaciones[i][6]} />
                            <NumberInput label={campos8[i]} source={"campoAdicional4_" + i} id={"campoAdicional4_" + i} style={{ width: "45%", marginLeft: "10px", display: estilo2[i][7] }} fullWidth onChange={textoDerecha} validate={validaciones[i][7]} />
                            <NumberInput label={translate('dynatest.resources.parametros_evaluacion.table.titleColumns.valorAlerta') + " " + medidaalerta[i]} source={"valorAlerta_" + i} id={"valorAlerta_" + i} style={{ width: "45%", marginLeft: estilo3[i], display: estilo[i] }} fullWidth onChange={textoDerecha} />
                            <NumberInput label="dynatest.resources.parametros_evaluacion.table.titleColumns.datosMinmodelacion" source={"datosminModelacion_" + i} id={"datosminModelacion_" + i} style={{ width: "45%", marginLeft: "10px", display: estilo[i] }} fullWidth onChange={textoDerecha} />
                            <NumberInput label="dynatest.resources.parametros_evaluacion.table.titleColumns.tiempoGenerarAlerta" source={"tiempoAntesAlertaMedicion_" + i} id={"tiempoAntesAlertaMedicion_" + i} style={{ width: "45%", marginLeft: "10px", display: estilo[i] }} fullWidth onChange={textoDerecha} />
                            <div align="right" style={{ display: estilo[i] }}><SearchIcon style={{ color: "blue" }} /><span style={{ cursor: "pointer", verticalAlign: "top", color: "blue" }} align="right" onClick={(evt) => MostrarImagen(i, evt)}>Ver más</span></div>
                            <div style={{ display: estilo[i] }}><div style={{ display: "none" }} id={"imagen_" + i}><img style={{ width: "100%", height: "100%" }} src={imagenes[i]} /></div></div>
                            <NumberInput style={{ display: "none" }} source={"idparametro_" + i} defaultValue={parametroid[i]} />
                            <div style={{ width: "100%", marginTop: "20px", display: estilo[i] }}></div>
                        </Fragment>
                    ))

                    }
                    <NumberInput style={{ display: "none" }} source="largoparametro" defaultValue={datoPar.length} />
                </Fragment>
            )
        }
        else if (tipo === "mostrar") {
            return (
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow style={{ textAlign: "center" }}>
                            <TableCell align="center" style={{ textAlign: "center" }} colSpan="9"><h4 style={{ textAlign: "center" }}>{translate('resources.parametros_evaluacion.fields.titleParametros')}</h4></TableCell>
                        </TableRow>
                    </TableHead>
                    {codigo.map((row, i) => (
                        <TableBody style={{ display: estilo[i] }}>
                            <TableRow style={{ backgroundColor: "#E8E8E8" }}>
                                <TableCell style={{ textAlign: "right" }}>{translate('dynatest.resources.parametros_evaluacion.table.titleColumns.indicador')}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{translate('dynatest.resources.parametros_evaluacion.table.titleColumns.aplica')}</TableCell>
                                <TableCell style={{ textAlign: "right", display: estilo2[i][0] }}>{campos1[i]}</TableCell>
                                <TableCell style={{ textAlign: "right", display: estilo2[i][1] }}>{campos2[i]}</TableCell>
                                <TableCell style={{ textAlign: "right", display: estilo2[i][2] }}>{campos3[i]}</TableCell>
                                <TableCell style={{ textAlign: "right", display: estilo2[i][3] }}>{campos4[i]}</TableCell>
                                <TableCell style={{ textAlign: "right", display: estilo2[i][4] }}>{campos5[i]}</TableCell>
                                <TableCell style={{ textAlign: "right", display: estilo2[i][5] }}>{campos6[i]}</TableCell>
                                <TableCell style={{ textAlign: "right", display: estilo2[i][6] }}>{campos7[i]}</TableCell>
                                <TableCell style={{ textAlign: "right", display: estilo2[i][7] }}>{campos8[i]}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{translate('dynatest.resources.parametros_evaluacion.table.titleColumns.valorAlerta')}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{translate('dynatest.resources.parametros_evaluacion.table.titleColumns.datosMinmodelacion')}</TableCell>
                                <TableCell style={{ textAlign: "right" }}>{translate('dynatest.resources.parametros_evaluacion.table.titleColumns.tiempoGenerarAlerta')}</TableCell>

                            </TableRow>
                            <TableRow style={{ textAlign: "right", display: estilo[i] }}>
                                <TableCell align="right">{row}</TableCell>
                                <TableCell align="right">{respuestas[i].aplica ? translate('dynatest.resources.parametros_evaluacion.table.si') : translate('dynatest.resources.parametros_evaluacion.table.no')}</TableCell>
                                <TableCell align="right" style={{ textAlign: "right", display: estilo2[i][0] }}>{respuestas[i].frecuenciaMedicion}</TableCell>
                                <TableCell align="right" style={{ textAlign: "right", display: estilo2[i][1] }}>{respuestas[i].tiempoCorreccion}</TableCell>
                                <TableCell align="right" style={{ textAlign: "right", display: estilo2[i][2] }}>{respuestas[i].valormaxPuntual}</TableCell>
                                <TableCell align="right" style={{ textAlign: "right", display: estilo2[i][3] }}>{respuestas[i].valormaxMedio}</TableCell>
                                <TableCell align="right" style={{ textAlign: "right", display: estilo2[i][4] }}>{respuestas[i].campoAdicional}</TableCell>
                                <TableCell align="right" style={{ textAlign: "right", display: estilo2[i][5] }}>{respuestas[i].campoAdicional2}</TableCell>
                                <TableCell align="right" style={{ textAlign: "right", display: estilo2[i][6] }}>{respuestas[i].campoAdicional3}</TableCell>
                                <TableCell align="right" style={{ textAlign: "right", display: estilo2[i][7] }}>{respuestas[i].campoAdicional4}</TableCell>
                                <TableCell align="right" style={{ textAlign: "right" }}>{respuestas[i].valorAlerta}</TableCell>
                                <TableCell align="right" style={{ textAlign: "right" }}>{respuestas[i].datosminModelacion}</TableCell>
                                <TableCell align="right" style={{ textAlign: "right" }}>{respuestas[i].tiempoAntesAlertaMedicion}</TableCell>
                            </TableRow>
                        </TableBody>
                    ))}
                </Table>
            )
        } else if (registros > 0 && tipo === "crear") {
            return (
                <div className="Reload2">
                    <span>{translate('dynatest.resources.parametros_evaluacion.title.grupoParamCrea')}</span>
                </div>
            )
        } else {
            return (
                <div className="Reload2">
                    <img src={loader} />
                </div>
            )
        }


    }

}
export default translate(Parametros);