import React, {useState} from 'react';
import { SimpleForm, TabbedForm, FormTab, SelectInput, DisabledInput, TextInput, ReferenceInput, DateInput, ArrayInput, SimpleFormIterator, AutocompleteInput} from 'react-admin';

import { withStyles } from '@material-ui/core';
import { Edit } from './../Edit';
import  {styles}  from './../../EstilosGrid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Parametros from './parametros';
import { translate } from 'react-admin';
import { required, minLength, maxLength } from 'react-admin';


export const ParametrosEdit = translate(withStyles(styles)(({ classes, record, ...props }) => {
    const {translate} = props;
    const [tipoParametro, setTipoParametro] = useState('');
const validarTipoParametro=(value)=>{
setTipoParametro(value);
}
let idproyecto=localStorage.getItem("id_project2");
    return(
        <Edit  title="dynatest.resources.parametros_evaluacion.title.editTitle" {...props}  undoable={false}>
         <SimpleForm submitOnEnter={false} redirect="list" > 
         <Typography component="b" variant="b" style={{textAlign: "center"}} formClassName={classes.grid_cont12} fullWidth>{translate('resources.usuarios.fields.sections.parametros_evaluacion')}</Typography>
        <Divider formClassName={classes.grid_cont12} fullWidth/>
        <SelectInput label="dynatest.resources.parametros_evaluacion.select.indicadores.indicadoresMessage" choices={[
                    { id: "All", name: "dynatest.resources.parametros_evaluacion.select.indicadores.todosParam" },
                    { id: "PFLEX", name: "dynatest.resources.parametros_evaluacion.select.indicadores.indiPavFlex" },
                    { id: "PRIG", name: "dynatest.resources.parametros_evaluacion.select.indicadores.indiPavRig" },
                    { id: "PRIGYFLEX", name: "dynatest.resources.parametros_evaluacion.select.indicadores.indiPavRigFlex" },
                    { id: "PUENTYEST", name: "dynatest.resources.parametros_evaluacion.select.indicadores.indiPuentesEstr" },
                    { id: "TUN", name: "dynatest.resources.parametros_evaluacion.select.indicadores.indiTuneles" },
                    { id: "OPER", name: "dynatest.resources.parametros_evaluacion.select.indicadores.indiOperac" },

         ]} formClassName={classes.grid_cont12} fullWidth validate={validarTipoParametro} defaultValue="All"/>
         <WithProps>{({record,...props})=>
            <Parametros aplicaind={tipoParametro} record={record} tipo="editar"/>
                    }
                </WithProps>
         </SimpleForm>
        </Edit>
    )
}))
const WithProps = ({children,...props}) => children(props);