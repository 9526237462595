import React from 'react';
import { TextInput, SimpleForm, SaveButton, Toolbar, ReferenceInput, SelectInput, required, minValue, maxValue, NumberInput} from 'react-admin';
import { withStyles } from '@material-ui/core';
import { Edit } from './../../../Edit';

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <SaveButton />
    </Toolbar>
));

const styles = {
    first_field: { display: 'inline-block', width: '100%' },    
};

export const PreguntasEdit = withStyles(styles)(({ classes, ...props }) => (
    <Edit title="Editar pregunta" {...props} undoable={false}>
        <SimpleForm submitOnEnter={false} redirect="list" toolbar={<CustomToolbar />} >            
        <WithProps>{({record,...props})=>
                    <span>{record.elemento_indicador_.elemento}</span>}
                </WithProps>
            <TextInput source="pregunta" label="Pregunta" formClassName={classes.first_field} fullWidth/>
            <NumberInput source="peso" label="Peso" formClassName={classes.first_field} validation={required(), minValue(0)}/>
        </SimpleForm>
    </Edit>
))

const WithProps = ({children,...props}) => children(props);