import React, { Component, Fragment } from 'react';

import Drawer from '@material-ui/core/Drawer';

import IconImageEye from '@material-ui/icons/RemoveRedEye';
import IconKeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { Button } from 'react-admin';

export class ReferenceImageButtonPeru extends Component {
    state = { showPanel: false };
    constructor(props) {
        super(props)
    }

    handleClick = () => {
        this.setState({ showPanel: true });
    };

    handleCloseClick = () => {
        this.setState({ showPanel: false });
    };

    render() {
        const { showPanel } = this.state;
        // const { id } = this.props;
        return (

            <Fragment>
                <Button onClick={this.handleClick} label="Imágenes actuales">
                    <IconImageEye />
                </Button>
                <Drawer
                    anchor="right"
                    open={showPanel}
                    onClose={this.handleCloseClick}
                >
                    <div>
                        <Button label="Close" onClick={this.handleCloseClick}>
                            <IconKeyboardArrowRight />
                        </Button>
                    </div>
                    <img src={this.props.src} alt="Logo" height="250" />
                    <p>{this.props.p}</p>
                    <img src={this.props.src2} alt="Logo" height="250" />
                    <p>{this.props.p2}</p>
                    <img src={this.props.src3} alt="Logo" height="250" />
                    <p>{this.props.p3}</p>
                    <img src={this.props.src4} alt="Logo" height="250" />
                    <p>{this.props.p4}</p>
                    <img src={this.props.src5} alt="Logo" height="250" />
                    <p>{this.props.p5}</p>
                    <img src={this.props.src6} alt="Logo" height="250" />
                    <p>{this.props.p6}</p>

                </Drawer>
            </Fragment>
        );
    }
}

export default ReferenceImageButtonPeru;