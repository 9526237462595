import React from 'react';
import { NumberInput, DisabledInput, TextInput, minValue, maxValue, required } from 'react-admin';
import loader from './../../../../../loader10.gif';
import config from '../../../../../config/config';
let urlAppjs = config.UrlServer;

class DecoloracionEdit extends React.Component{

    constructor(props){
        super(props)
        this.state = { colores: [], datoEva: [], cumple: "", url:"" }
    }
    componentDidMount(){
        
        let url_elemento_aux = document.location.hash;
        let croed=url_elemento_aux.split("/");
        let crearoeditar="";
        let componente =""
        if(croed[2]==="create"){
            crearoeditar=1;
        }
        else{
        let url_elemento = url_elemento_aux.split("/%2F");
        componente = url_elemento[1];
        componente = componente.replace("/4", "");
        componente = componente.replace("%2F", "/");
        crearoeditar=2;
        }

        if(crearoeditar===2){
            this.setState({ datoEva: this.props.record })
            // fetch(urlAppjs+"/"+componente)
        // .then((response) => {
        //     return response.json()
        // })
        // .then((datoEva) => {
        //     this.setState({ datoEva: datoEva })
        // })
    }
    let proyectoid=localStorage.getItem("id_project");
    fetch(urlAppjs+"/colores.json?proyecto="+proyectoid)
        .then((response) => {
            return response.json()
        })
        .then((colores) => {
            this.setState({ colores: colores })
            this.setState({ url: urlAppjs })
        })
        
    }
        
    render(){
        
        const { colores } = this.state;        
        const { datoEva } = this.state;        
        const { cumple } = this.state;
        const { url } = this.state;
        //var cumplimiento="No cumple";
        let valoredit="";
        let coloresstr="";
        let minimosx=[];
        let maximosx=[];
        let minimosy=[];
        let maximosy=[];
        if(colores){
            colores.map(resp=>{
                coloresstr=coloresstr+","+resp.color;
                minimosx.push(resp.min1);
                minimosy.push(resp.min);
                maximosx.push(resp.max);
                maximosy.push(resp.max1);
            })
        }
        const Validardeco=(value)=>{
            //console.log(value)
            let largo=colores.length;
            let resultado =[];
            let cumplimiento="No Cumple";
            for(let j=0; j<largo; j++){
                let valor = document.getElementById('dec_colorx_'+j);
                let valor2 = document.getElementById('dec_colory_'+j);
                if(valor){
                    valor.style.textAlign="right"
                }
                if(valor2){
                    valor2.style.textAlign="right"
                }
                //console.log(value);
                if(valor && valor2 && valor.value!="" && valor2.value!=""){
                    resultado.push(valor.value.replace(',', '.')+" "+valor2.value.replace(',', '.'));
                }
            }
            if(resultado.length===largo && coloresstr!=""){
                let proyecto=localStorage.getItem('id_project');
                fetch(url+ '/buscarDecoloracion/'+resultado+'/'+proyecto+'/'+coloresstr)
                        .then((response) => {
                            //console.log("REST");
                            return response.json();
                        })
                        .then((response) =>{
                            if (response.resultado===1 && response.respuesta.length>0){
                                cumplimiento="Cumple"
                            }
                            else{
                                cumplimiento="No Cumple"
                            }
                            valoredit=cumplimiento;
                            this.setState({cumple: cumplimiento})
                            //valoredit=cumplimiento;
                        });
                        //console.log("llega")
                    }
            //valoredit=cumplimiento;
            valoredit=cumplimiento;
        }
        // if(datoEva.decoloracion && datoEva.decoloracion[0].calificacion && cumple===""){
        //     valoredit=datoEva.decoloracion[0].calificacion
        // }
        if(cumple==="" && valoredit===""){
            valoredit="No Cumple";
            //console.log("Cumple3 -> "+cumple)
            Validardeco();
            }
        else{
                valoredit=cumple
                //console.log("cumple2 ->"+cumple)
        }
        if(colores.length > 0){

            let responsex = [];
            let responsey = [];
            if(datoEva.decoloracion && datoEva.decoloracion[0]){
                // console.log(datoEva.decoloracion[0].svColor)
                datoEva.decoloracion[0].svColor.map( responseSV => {
                    // console.log()
                    // console.log()
                    if(responseSV.valorx!=-1){
                    responsex.push(Number.parseFloat(responseSV.valorx).toFixed(3)) 
                    }
                    else{
                        responsex.push("-") 
                    }
                    if(responseSV.valory!=-1){
                    responsey.push(Number.parseFloat(responseSV.valory).toFixed(3))
                    }
                    else{
                        responsey.push("-")
                    }
                }) 
            } 

            // 
            const formatocoma=v=>{
                let coma;
                if(v){
                    coma =v.search(',');
                }
                if(isNaN(v) && v!="-" && coma===-1){
                    if(v){
                    return v.substring(0, v.length - 1);
                    }
                    else{
                    return ""
                    }
                }else{
                    if(coma!=-1){
                        if(v){
                            let aux= v.split(',')
                            if(isNaN(aux[1])){
                                return v.substring(0, v.length - 1);
                            }
                            else{
                                return v.replace('.', ',')    
                            }
                        }
                        else{
                            return v
                        }
                    }
                    else{
                        if(v){
                          return v.replace('.', ',')    
                        }
                        else{
                            return v
                        }
                    }
                }
            }
            
            return(
                <React.Fragment>
                    <div style={{display: 'flex'}}>                    
                        {
                            colores.map((data, i) => (
                                <div key={i} style={{margin: '0px 15px'}}>
                                    <div style={{textAlign: 'center', marginTop: '15px'}}>
                                        {data.color}
                                    </div>
                                    <div>
                                        <TextInput label="x" source={`dec_colorx_${i}`} id={`dec_colorx_${i}`} defaultValue={responsex[i]} onChange={Validardeco}  format={formatocoma} validate={[required(), minValue(minimosx[i]), maxValue(maximosx[i])]}/>
                                        <TextInput label="y" source={`dec_colory_${i}`} id={`dec_colory_${i}`} defaultValue={responsey[i]} onChange={Validardeco}  format={formatocoma} validate={[required(), minValue(minimosy[i]), maxValue(maximosy[i])]}/>
                                        <DisabledInput source={`dec_color_id_${i}`} label=" " defaultValue={"/colores/"+data.id} style={{display: 'none'}}/>              
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <hr />
                    <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '-30px'}}>
                        <strong style={{display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}>Condición: </strong><DisabledInput source="condicion_decoloracion" label=" " defaultValue={valoredit}/>
                    </div> 
                    <DisabledInput source="dec_length" label=" " defaultValue={colores.length} style={{display: 'none'}}/>   
                </React.Fragment>
            )          
                     
        }else{                      
            return( 
                <div className="Reload2">
                    <img src={loader} />
                </div> 
            )   
        }
            
    }    
}

export default DecoloracionEdit;