import React, { Fragment } from 'react';
import { List, Datagrid, TextField, EditButton, ShowButton, Filter, ReferenceInput, SelectInput,SelectField} from 'react-admin';
import {  BulkDeleteWithConfirmButton, Loading  } from 'react-admin';
import { CardActions, CreateButton, ExportButton } from 'react-admin';
const choises = [
    { id: '1', name: 'dynatest.resources.granulometrias.select.tipoMaterial.adoquinEnArcilla' },
        { id: '2', name: 'dynatest.resources.granulometrias.select.tipoMaterial.amadoquinDeConcreto' },
        { id: '3', name: 'dynatest.resources.granulometrias.select.tipoMaterial.afirmado' },
        { id: '4', name: 'dynatest.resources.granulometrias.select.tipoMaterial.baseAsfaltica' },
        { id: '5', name: 'dynatest.resources.granulometrias.select.tipoMaterial.baseDeConcHidraulico' },
        { id: '6', name: 'dynatest.resources.granulometrias.select.tipoMaterial.baseEstOtros' },
        { id: '7', name: 'dynatest.resources.granulometrias.select.tipoMaterial.baseEstbAsfalto' },
        { id: '8', name: 'dynatest.resources.granulometrias.select.tipoMaterial.baseEstEmulsion' },
        { id: '9', name: 'dynatest.resources.granulometrias.select.tipoMaterial.baseGranular' },
        { id: '10', name: 'dynatest.resources.granulometrias.select.tipoMaterial.baseTratada' },
        { id: '11', name: 'dynatest.resources.granulometrias.select.tipoMaterial.concHidraulico' },
        { id: '12', name: 'dynatest.resources.granulometrias.select.tipoMaterial.lechAsfaltica' },
        { id: '13', name: 'dynatest.resources.granulometrias.select.tipoMaterial.mezAbierCaliente' },
        { id: '14', name: 'dynatest.resources.granulometrias.select.tipoMaterial.mezAbierFrio' },
        { id: '15', name: 'dynatest.resources.granulometrias.select.tipoMaterial.mezAsfalGraCaucho' },
        { id: '16', name: 'dynatest.resources.granulometrias.select.tipoMaterial.mezAsfalModPolimeros' },
        { id: '17', name: 'dynatest.resources.granulometrias.select.tipoMaterial.mezDenCaliente' },
        { id: '18', name: 'dynatest.resources.granulometrias.select.tipoMaterial.mezDenFrio' },
        { id: '19', name: 'dynatest.resources.granulometrias.select.tipoMaterial.mezDrenante' },
        { id: '20', name: 'dynatest.resources.granulometrias.select.tipoMaterial.microagloCaliente' },
        { id: '21', name: 'dynatest.resources.granulometrias.select.tipoMaterial.subBaseAsfalEspumado' },
        { id: '22', name: 'dynatest.resources.granulometrias.select.tipoMaterial.subBaseEstabOtros' },
        { id: '23', name: 'dynatest.resources.granulometrias.select.tipoMaterial.subBaseEstabAsfalto' },
        { id: '24', name: 'dynatest.resources.granulometrias.select.tipoMaterial.subBaseEstabCemento' },
        { id: '25', name: 'dynatest.resources.granulometrias.select.tipoMaterial.subBaseEstabGranular' },
        { id: '26', name: 'dynatest.resources.granulometrias.select.tipoMaterial.subraEstabCal' },
        { id: '27', name: 'dynatest.resources.granulometrias.select.tipoMaterial.subraEstabCemento' },
        { id: '28', name: 'dynatest.resources.granulometrias.select.tipoMaterial.subraEstabGeomalla' },
        { id: '29', name: 'dynatest.resources.granulometrias.select.tipoMaterial.subraEstabGeotextil' },
        { id: '30', name: 'dynatest.resources.granulometrias.select.tipoMaterial.subraFina' },
        { id: '31', name: 'dynatest.resources.granulometrias.select.tipoMaterial.subraGranular' },
        { id: '32', name: 'dynatest.resources.granulometrias.select.tipoMaterial.subraCemento' },
];

const FiltrosElementos = (props) => (
    <Filter {...props}>
        <SelectInput source="tipo" choices={choises} alwaysOn/>
    </Filter>
);

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);
const ListAction = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
        <ExportButton basePath={basePath}/>
    </CardActions>
);
const ListAction2 = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
    </CardActions>
);

export const GranulometriaList = ({ permissions, ...props }) => {
    if (!permissions) return <Loading />;  
    return (
    <List {...props} 
        actions={permissions['ganulometria']  == 1 ? <ListAction /> : permissions['ganulometria']  == 2 ? <ListAction2 />: null}
        sort={{ field: "updatedAt", order: 'DESC' }} 
        filters={<FiltrosElementos/>} 
        bulkActionButtons={permissions['ganulometria']  == 1 ? <PostBulkActionButtons />: null}>
        <Datagrid>   
            {/* <TextField source="material_.tipo" label="Tipo de material" linkType={false}/> */}
            <SelectField source="tipo" choices={choises} fullWidth  />
            <TextField source="granulometria" /> 
            {permissions['ganulometria'] == 1 || permissions['ganulometria'] == 2 || permissions['ganulometria'] == 4 ? <ShowButton/>: null}
            {permissions['ganulometria'] == 1 || permissions['ganulometria'] == 2 ? <EditButton/>: null}    
        </Datagrid>
    </List>
)};