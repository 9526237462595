import React, { useState } from 'react';
import {
    Toolbar, CardActions, Tab, NumberField, ShowController, ShowView, TextField, DateField,
    ReferenceField, BooleanField, TabbedForm, ListButton, EditButton, ArrayField, FormDataConsumer,
    SingleFieldList, FormTab, RichTextField, ReferenceArrayField, Datagrid
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { PRField } from './../../../fields/PRField';
import ShowElementsParticular from './../ShowElementsParticular';
import { Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/core';
import { styles } from './../../../EstilosGrid';
import config from '../../../config/config';
import { translate } from 'react-admin';
let urlAppjs = config.UrlServer;


const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};


const PostShowActions = ({ basePath, data }) => (
    <CardActions style={cardActionStyle}>
        <EditButton basePath={basePath} record={data} />
        {/* Add your custom actions */}
        <ListButton basePath={basePath} />
    </CardActions>
);

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <span></span>

    </Toolbar>
));

export const PoliticasMantenimientoParticularShow = translate(withStyles(styles)(({ classes, record, ...props }) => {
    const { translate } = props;

    return (
        <ShowController {...props} title="Información Política de Mantenimiento">
            {controllerProps =>
                <ShowView {...props} {...controllerProps} title="dynatest.resources.politicas_mantenimiento_particulars.title.showTitle" actions={<PostShowActions />}>
                    <TabbedForm toolbar={<CustomToolbar />}>
                        <FormTab label="dynatest.resources.politicas_mantenimiento_particulars.tabs.tabsTitle.informacion">
                            <TextField
                                label="resources.politicas_mantenimiento_particulars.fields.nombre"
                                source="nombre"
                                fullWidth
                                formClassName={classes.grid_cont3}
                            />
                            <ReferenceField
                                label="resources.politicas_mantenimiento_particulars.fields.sistema"
                                source="sistema"
                                reference="sistemas"
                                linkType={false}
                                fullWidth
                                formClassName={classes.grid_cont3}
                            >
                                <TextField label="" source="nombreEnPoliticas" />
                            </ReferenceField>

                            <TextField
                                label="resources.politicas_mantenimiento_particulars.fields.tipoElemento"
                                source="tipoElementoNombre_.nombre"
                                fullWidth
                                formClassName={classes.grid_cont3}
                            />

                            <br />

                            <ReferenceField
                                label="resources.politicas_mantenimiento_particulars.fields.unidadFuncional"
                                source="unidadFuncional"
                                reference="unidad_funcionals"
                                linkType={false}
                                formClassName={classes.grid_cont3}
                                fullWidth
                            >
                                <TextField label="" source="fullCodigos" />
                            </ReferenceField>
                            {controllerProps.record && controllerProps.record.tramo !== null &&
                                <ReferenceField
                                    label="resources.politicas_mantenimiento_particulars.fields.tramo"
                                    source="tramo"
                                    reference="tramos"
                                    linkType={false}
                                    fullWidth
                                    formClassName={classes.grid_cont3}
                                >
                                    <TextField label="" source="fullCodigos" />
                                </ReferenceField>
                            }
                            {controllerProps.record && controllerProps.record.tramo === null &&
                                <Typography component="h4" formClassName={classes.grid_cont3}>
                                    {translate('dynatest.resources.politicas_mantenimiento_particulars.messages.tramo')}
                                </Typography>
                            }

                            {controllerProps.record && controllerProps.record.abInic &&
                                <PRField
                                    label="resources.politicas_mantenimiento_particulars.fields.abInic"
                                    source="abInic"
                                    fullWidth
                                    formClassName={classes.grid_cont3}
                                />

                            }
                            {controllerProps.record && !controllerProps.record.abInic &&
                                <Typography component="h4" formClassName={classes.grid_cont3}>
                                    {translate('dynatest.resources.politicas_mantenimiento_particulars.messages.absInicial')}
                                </Typography>

                            }
                            {controllerProps.record && controllerProps.record.abFin &&
                                <PRField
                                    label="resources.politicas_mantenimiento_particulars.fields.abFin"
                                    source="abFin"
                                    fullWidth
                                    formClassName={classes.grid_cont3}
                                />
                            }
                            {controllerProps.record && !controllerProps.record.abFin &&
                                <Typography component="h4" formClassName={classes.grid_cont3}>
                                    {translate('dynatest.resources.politicas_mantenimiento_particulars.messages.absFinal')}
                                </Typography>

                            }
                            {controllerProps.record &&
                                <ShowElementsParticular
                                    politica={controllerProps.record.id}
                                />
                            }
                        </FormTab>
                        <FormTab label="dynatest.resources.politicas_mantenimiento_particulars.tabs.tabsTitle.auditoria">
                            <DateField source="createdAt" label="resources.politicas_mantenimiento_particulars.fields.createdAt" fullWidth formClassName={classes.grid_cont4} showTime/>
                            <TextField source="createdBy" label="resources.politicas_mantenimiento_particulars.fields.createdBy" fullWidth formClassName={classes.grid_cont4} />
                            <span></span>
                            <DateField source="updatedAt" label="resources.politicas_mantenimiento_particulars.fields.updatedAt" fullWidth formClassName={classes.grid_cont4} showTime/>
                            <TextField source="updatedBy" label="resources.politicas_mantenimiento_particulars.fields.updatedBy" fullWidth formClassName={classes.grid_cont4} />
                            <span></span>
                        </FormTab>
                    </TabbedForm>
                </ShowView>
            }
        </ShowController>
    )
}));