import React, { useState, Fragment } from 'react';
import { TabbedShowLayout, TabbedForm, Toolbar, Tab, Datagrid, ShowController, ShowView, TextField, DateField, ReferenceField, BooleanField, FormTab, ArrayField, ChipField, SingleFieldList, NumberField, ListButton, EditButton, SimpleForm } from 'react-admin';
import { Show } from './../../Show';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { styles } from './../../../EstilosGrid';
import { PRField } from './../../../fields/PRField';
import VistaDatosShow from './visualizacion';
import EvaluacionPorSegmento from './EvaluacionPorSegmento'
import { render } from 'react-dom';
import { translate } from 'react-admin';

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <span>.</span>

    </Toolbar>
));

export const CargarInformacionShow = translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;
    return (
        <ShowController {...props} title="Disoinibilidad de Vía">
            {controllerProps =>
                <Show title="dynatest.resources.indicadores_cargar_informacions.title.showTitle"  {...props}>
                    <SimpleForm submitOnEnter={false} toolbar={<CustomToolbar />}>
                        <Divider fullWidth />
                        <Typography component="b" variant="b" formClassName={classes.grid_cont12} fullWidth>{translate('dynatest.resources.indicadores_cargar_informacions.subTitle.SeleccionIndicador')}</Typography>
                        <ReferenceField label="resources.indicadores_cargar_informacions.fields.indicador" source="indicador" reference="parametros" fullWidth formClassName={classes.grid_cont12} linkType={false}>
                            <TextField source="codigoCompleto" />
                        </ReferenceField>
                        <Divider fullWidth />
                        <Typography component="b" variant="b" formClassName={classes.grid_cont12} fullWidth>{translate('resources.indicadores_cargar_informacions.detalle')}</Typography>
                        <ReferenceField label="resources.indicadores_cargar_informacions.fields.unidadFuncional" source="unidadFuncional" reference="unidad_funcionals" formClassName={classes.grid_cont3_f} fullWidth linkType={false}>
                            <TextField options={{ fullWidth: true }} source="fullCodigos" fullWidth />
                        </ReferenceField>

                        <ReferenceField label="resources.indicadores_cargar_informacions.fields.tramo" source="tramo" reference="tramos" fullWidth formClassName={classes.grid_cont3_s} linkType={false}>
                            <TextField options={{ fullWidth: true }} source="fullCodigos" />
                        </ReferenceField>

                        <PRField label="resources.indicadores_cargar_informacions.fields.abini" id="ingresoAbInicio" source="abiniFloat" fullWidth formClassName={classes.grid_cont3_s} />
                        <PRField label="resources.indicadores_cargar_informacions.fields.abfin" id="ingresoAbFin" source="abfinFloat" fullWidth formClassName={classes.grid_cont3_s} />
                        <DateField label="resources.indicadores_cargar_informacions.fields.fechaMedicion" id="datefield" source="fechaMedicion" formClassName={classes.grid_cont3_f} fullWidth />
                        <TextField label="resources.indicadores_cargar_informacions.fields.medidoPor" source="medidoPor" formClassName={classes.grid_cont3_s} fullWidth />
                        <TextField label="resources.indicadores_cargar_informacions.fields.estado" source="estado" formClassName={classes.grid_cont3_s} fullWidth />
                        <TextField label="resources.indicadores_cargar_informacions.fields.tipoMedicion" source="tipoMedicion" formClassName={classes.grid_cont3_s} />
                        {controllerProps.record && controllerProps.record.tipoMedicion === "De Corrección" &&
                            <Typography component="b" variant="b" formClassName={classes.grid_cont12} fullWidth>{translate('dynatest.resources.indicadores_cargar_informacions.subTitle.RangoCorreccion')}</Typography>
                        }
                        {controllerProps.record && controllerProps.record.tipoMedicion === "De Corrección" &&
                            <PRField label="resources.indicadores_cargar_informacions.fields.abini" source="abiniCorreccionFloat" fullWidth formClassName={classes.grid_cont3_f} />
                        }
                        {controllerProps.record && controllerProps.record.tipoMedicion === "De Corrección" &&
                            <PRField label="resources.indicadores_cargar_informacions.fields.abfin" source="abfinCorreccionFloat" fullWidth formClassName={classes.grid_cont3_s} />
                        }
                        <Divider fullWidth />
                        <TabbedForm save={false} submitOnEnter={false} saving={false} className="Evaluaciones-tabs">
                            <FormTab label="dynatest.resources.indicadores_cargar_informacions.tabs.IndicadorSegmento">
                                <WithProps>{({ record, ...props }) =>
                                    <EvaluacionPorSegmento record={record} />}
                                </WithProps>
                            </FormTab>
                            <FormTab label="dynatest.resources.indicadores_cargar_informacions.tabs.ResumenRegistros">
                                <WithProps>{({ record, ...props }) =>
                                    <VistaDatosShow record={record} />}
                                </WithProps>
                            </FormTab>
                        </TabbedForm>
                    </SimpleForm>
                </Show>
            }
        </ShowController>
    )
}))
const WithProps = ({ children, ...props }) => children(props);