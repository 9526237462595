import React from 'react';
import fFormatter from './../format/FFormat';

import { TextInput } from 'react-admin';


export const FInput = (props) => (
    <TextInput 
        {...props} 
        InputProps={{
            inputComponent: fFormatter,
        }}
    />
);

