import React from 'react';
import {
    TabbedForm, Datagrid, ShowController, ShowView, TextField, DateField, ReferenceField,
    FormTab, ArrayField, SingleFieldList, NumberField, BooleanField, Toolbar
} from 'react-admin';
import { PRField } from './../../../../fields/PRField';
import { GMapInput } from '../../../../inputs/GMapInput';

import { PField } from './../../../../fields/PField';
import { PercentageField } from './../../../../fields/PercentageField';
import { withStyles } from '@material-ui/core';
import { styles } from './../../../../EstilosGrid';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { ButtonCarousel } from './../../../images/ButtonCarousel';
import { translate } from 'react-admin';

const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <span></span>

    </Toolbar>
));

export const ElementoAdoquinShow = translate(withStyles(styles)(({ classes, record, ...props }) => {
    const { translate } = props;

    return (
        <ShowController title="Información Segmento Adoquín" {...props}>
            {controllerProps =>
                <ShowView {...props} {...controllerProps} title="dynatest.resources.adoquin_detalles.title.showTitle" actions={<ButtonCarousel />}>
                    <TabbedForm toolbar={<CustomToolbar />}>
                        <FormTab label="dynatest.resources.adoquin_detalles.tabs.informacionBasica">
                            <ReferenceField source="unidadFuncional" reference="unidad_funcionals" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <TextField label="" source="fullCodigos" />
                            </ReferenceField>
                            <ReferenceField source="tramo" reference="tramos" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <TextField label="" source="fullCodigos" />
                            </ReferenceField>
                            <TextField label="resources.adoquin_detalles.fields.segmento" source="segmento_.codigo" fullWidth formClassName={classes.grid_cont3} />
                            <ReferenceField label="resources.adoquin_detalles.fields.absInicio" source="segmento" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <PRField label="" source="abInicio" />
                            </ReferenceField>
                            <ReferenceField label="resources.adoquin_detalles.fields.absFin" source="segmento" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <PRField label="" source="abFin" />
                            </ReferenceField><NumberField label="resources.adoquin_detalles.fields.longitudShow" source="segmento_.fullResta" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                            <ReferenceField label="resources.adoquin_detalles.fields.prInicio" source="segmento" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont4} >
                                <PField label="" source="pInicio" />
                            </ReferenceField>
                            <ReferenceField label="resources.adoquin_detalles.fields.prFin" source="segmento" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <PField label="" source="pFin" />
                            </ReferenceField><span></span>

                            <b>{translate('resources.adoquin_detalles.fields.localizacion')}</b>
                            <Divider fullWidth style={{ marginTop: '10px' }} />

                            <TextField label="resources.adoquin_detalles.fields.departamento" source="segmento_.municipio_.departamento_.nombre" fullWidth formClassName={classes.grid_cont4} />
                            <TextField label="resources.adoquin_detalles.fields.municipio" source="segmento_.municipio_.nombre" fullWidth formClassName={classes.grid_cont4} />

                            <Typography variant="b" component="b">{translate('resources.adoquin_detalles.fields.coordenadasIniciales')}</Typography>
                            <Divider fullWidth style={{ marginTop: '10px' }} />
                            <NumberField label="resources.adoquin_detalles.fields.latitud" source="segmento_.latitud" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4} />
                            <TextField label="resources.adoquin_detalles.fields.longitud" source="segmento_.longitud" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4} />
                            <NumberField label="resources.adoquin_detalles.fields.altura" source="segmento_.alturaIni" style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} fullWidth formClassName={classes.grid_cont3} />
                            <Typography variant="b" component="b">{translate('resources.adoquin_detalles.fields.coordenadasFinales')}</Typography>
                            <Divider fullWidth style={{ marginTop: '10px' }} />
                            <NumberField label="resources.adoquin_detalles.fields.latitud" source="segmento_.latitud2" style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} fullWidth formClassName={classes.grid_cont4} />
                            <NumberField label="resources.adoquin_detalles.fields.longitud" source="segmento_.longitud2" style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} fullWidth formClassName={classes.grid_cont4} />
                            <NumberField label="resources.adoquin_detalles.fields.altura" source="segmento_.alturaFin" style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} fullWidth formClassName={classes.grid_cont3} />

                            {/* <b>Características</b>

                            <Divider fullWidth />
                            <ReferenceField label="Tipo Calzada" source="segmento_.tipoCalzada" reference="tipo_calzadas" linkType={false}  formClassName={classes.grid_cont3}>
                                <TextField source="nombre" />
                            </ReferenceField>

                            <ReferenceField label="Tipo Segmento" source="segmento_.tipoSegmento" reference="tipo_segmentos" linkType={false}  formClassName={classes.grid_cont3}>
                                <TextField source="nombre" />
                            </ReferenceField>

                            <ReferenceField label="Tipo de superficie" source="segmento_.tipoSuperficie" reference="tipo_elementos" linkType={false}  formClassName={classes.grid_cont3}>
                                <TextField source="nombre" />
                            </ReferenceField>

                            <BooleanField  source="segmento_.estado" fullWidth formClassName={classes.grid_cont4}/> 
                            <span></span> */}

                            <GMapInput
                                defaultZoom={8}
                                defaultCenter={{ lat: 4.7567039, lng: -74.0311056 }}
                                source="segmento_.ubicacionMapa"
                                multipleMarkers="true"
                                googleKey="AIzaSyCivTXzJBYRBK8nosBYRFXnv-hGXIbhnYY"
                                icon={"/imagenes/senalesColombia/Elementos/segmento_inventaro/Adoquin.png"}
                            />

                        </FormTab>
                        <FormTab label="dynatest.resources.adoquin_detalles.tabs.detalles">
                            <ArrayField source="detallesSegmentos" label="resources.adoquin_detalles.fields.sentido" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="sentido" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="detallesSegmentos" label="resources.adoquin_detalles.fields.estadoDetalles" linkType={false} fullWidth formClassName={classes.grid_cont3} >
                                <SingleFieldList>
                                    <TextField source="estadoDetalles" />
                                </SingleFieldList>
                            </ArrayField>
                            {controllerProps.record && controllerProps.record.detallesSegmentos[0].estadoDetalles === translate('dynatest.resources.adoquin_detalles.select.estadoDetalles.Inactivo') &&
                                <ArrayField source="detallesSegmentos" label="resources.adoquin_detalles.fields.estadoRazon" fullWidth linkType={false} formClassName={classes.grid_cont3}>
                                    <SingleFieldList>
                                        <TextField source="estadoRazon" />
                                    </SingleFieldList>
                                </ArrayField>
                            }
                            <ArrayField source="detallesSegmentos" label="dynatest.resources.adoquin_detalles.boolean.elementoMantenimiento" fullWidth linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <BooleanField source="elementoMantenimiento" />
                                </SingleFieldList>
                            </ArrayField>
                            <br />
                            <ArrayField source="detallesSegmentos" label="resources.adoquin_detalles.fields.dateInicioOperacion" fullWidth linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <DateField source="dateInicioOperacion" />
                                </SingleFieldList>
                            </ArrayField>

                            <ArrayField source="detallesSegmentos" label="resources.adoquin_detalles.fields.proveedor" fullWidth linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="proveedor_.razonSocial" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="detallesSegmentos" label="resources.adoquin_detalles.fields.garantiaMeses" fullWidth linkType={false} style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="garantiaMeses" />
                                </SingleFieldList>
                            </ArrayField>
                            <br />
                            <Typography variant="b" component="b">{translate('resources.adoquin_detalles.fields.trafico')}</Typography>
                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <ArrayField source="detallesSegmentos" label="resources.adoquin_detalles.fields.esalTime" fullWidth linkType={false} style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="esalTime" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="detallesSegmentos" label="resources.adoquin_detalles.fields.nivelTraficoDetalles" fullWidth linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="nivelTrafico" />
                                </SingleFieldList>
                            </ArrayField>
                            <br />
                            <ArrayField source="detallesSegmentos" label="resources.adoquin_detalles.fields.esalTime10" fullWidth linkType={false} style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="esalTime10" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="detallesSegmentos" label="resources.adoquin_detalles.fields.esalTime20" fullWidth linkType={false} style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="esalTime20" />
                                </SingleFieldList>
                            </ArrayField>
                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <ArrayField source="detallesSegmentos" linkType={false}  label="resources.adoquin_detalles.fields.detallesObservaciones" formClassName={classes.grid_cont12}>
                                <SingleFieldList>
                                    <TextField source="detallesObservaciones" />
                                </SingleFieldList>
                            </ArrayField>
                        </FormTab>
                        <FormTab label="dynatest.resources.adoquin_detalles.tabs.geometria">
                            <br></br>
                            <Typography variant="b" component="b">{translate('resources.adoquin_detalles.fields.detallesCalzada')}</Typography>
                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <ArrayField source="geometriaSegmentos" label="resources.adoquin_detalles.fields.geometriaLargo" fullWidth linkType={false} style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaLargo" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmentos" label="resources.adoquin_detalles.fields.geometriaAncho" fullWidth linkType={false} style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaAncho" />
                                </SingleFieldList>
                            </ArrayField>

                            <Typography variant="b" component="b">{translate('resources.adoquin_detalles.fields.detallesCarriles')}</Typography>

                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <ArrayField source="geometriaSegmentos" label="resources.adoquin_detalles.fields.geometriaNumCarriles" fullWidth linkType={false} style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaNumCarriles" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmentos" label="resources.adoquin_detalles.fields.geometriaAnchoCarril" fullWidth linkType={false} style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaAnchoCarril" />
                                </SingleFieldList>
                            </ArrayField>
                            <br />
                            <ArrayField source="geometriaSegmentos" label="resources.adoquin_detalles.fields.geometriaTipoBerma" fullWidth linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="geometriaTipoBerma" />
                                </SingleFieldList>
                            </ArrayField>
                            {controllerProps.record && controllerProps.record.geometriaSegmentos[0].geometriaTipoBerma !== "Inexistente" &&
                                <ArrayField source="geometriaSegmentos" label="resources.adoquin_detalles.fields.geometriaAnchoBerma" fullWidth linkType={false} formClassName={classes.grid_cont3}>
                                    <SingleFieldList>
                                        <TextField source="geometriaCondicionBerma" />
                                    </SingleFieldList>
                                </ArrayField>
                            }
                            {controllerProps.record && controllerProps.record.geometriaSegmentos[0].geometriaTipoBerma !== "Inexistente" &&
                                <ArrayField source="geometriaSegmentos" label="resources.adoquin_detalles.fields.geometriaCondicionBerma" fullWidth linkType={false} style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3}>
                                    <SingleFieldList>
                                        <NumberField source="geometriaAnchoBerma" />
                                    </SingleFieldList>
                                </ArrayField>
                            }
                            <br />
                            <ArrayField source="geometriaSegmentos" label="resources.adoquin_detalles.fields.aux1" fullWidth linkType={false} style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="sumaAreaCarriles" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmentos" label="resources.adoquin_detalles.fields.areaCarrilesVerdadera" linkType={false} fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="areaCarrilesVerdadera" />
                                </SingleFieldList>
                            </ArrayField>

                            <Typography variant="b" component="b">{translate('resources.adoquin_detalles.fields.propiedadesGeometricas')}</Typography>

                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <ArrayField source="geometriaSegmentos" label="resources.adoquin_detalles.fields.geometriaLongitudinal" linkType={false} fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <PercentageField source="geometriaLongitudinal" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmentos" label="resources.adoquin_detalles.fields.geometriaLongitudinalMa" linkType={false} fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <PercentageField source="geometriaLongitudinalMa" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmentos" label="resources.adoquin_detalles.fields.geometriaLongitudinalMi" linkType={false} fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <PercentageField source="geometriaLongitudinalMi" />
                                </SingleFieldList>
                            </ArrayField>
                            <br />
                            <ArrayField source="geometriaSegmentos" label="resources.adoquin_detalles.fields.geometriaTransversal" linkType={false} fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <PercentageField source="geometriaTransversal" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmentos" label="resources.adoquin_detalles.fields.geometriaRadioMi" fullWidth linkType={false} style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaRadioMi" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmentos" label="resources.adoquin_detalles.fields.geometriaRadioMa" fullWidth linkType={false} style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaRadioMa" />
                                </SingleFieldList>
                            </ArrayField>
                            <br />
                            <ArrayField source="geometriaSegmentos" label="resources.adoquin_detalles.fields.geometriaRadioCurvatura" fullWidth linkType={false} style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaRadioCurvatura" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmentos" label="resources.adoquin_detalles.fields.geometriaVelDiseno" fullWidth linkType={false} style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaVelDiseno" />
                                </SingleFieldList>
                            </ArrayField>
                            <br />
                            <ArrayField source="geometriaSegmentos" label="resources.adoquin_detalles.fields.geometriaSeparador" fullWidth linkType={false} style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaSeparador" />
                                </SingleFieldList>
                            </ArrayField>

                            {controllerProps.record && controllerProps.record.geometriaSegmentos[0].geometriaSeparador === translate('dynatest.resources.adoquin_detalles.select.geometriaSeparador.Si')  &&
                                <ArrayField source="geometriaSegmentos" label="resources.adoquin_detalles.fields.geometriaSeparadorAncho" fullWidth linkType={false} formClassName={classes.grid_cont3}>
                                    <SingleFieldList>
                                        <NumberField source="geometriaSeparadorAncho" />
                                    </SingleFieldList>
                                </ArrayField>
                            }
                            <br />
                            <ArrayField source="geometriaSegmentos" label="resources.adoquin_detalles.fields.geometriaCondicionesGeometrica" fullWidth linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="geometriaCondicionesGeometrica" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmentos" label="resources.adoquin_detalles.fields.geometriaAlineamiento" fullWidth linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="geometriaAlineamiento" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmentos" label="resources.adoquin_detalles.fields.geometriaTipoTerreno" fullWidth linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="geometriaTipoTerreno" />
                                </SingleFieldList>
                            </ArrayField>

                            <Typography variant="b" component="b">{translate('resources.adoquin_detalles.fields.dimAdoquinTipico')}</Typography>
                            <Divider fullWidth formClassName={classes.grid_cont12} />

                            <ArrayField source="geometriaSegmentos" label="resources.adoquin_detalles.fields.geometriaAdoLargo" fullWidth linkType={false} style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaAdoLargo" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmentos" label="resources.adoquin_detalles.fields.geometriaAdoAncho" fullWidth linkType={false} style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="geometriaAdoAncho" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmentos" label="resources.adoquin_detalles.fields.geometriaAdoAlto" fullWidth linkType={false} style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4}>
                                <SingleFieldList>
                                    <NumberField source="geometriaAdoAlto" style={{ width: '30% !important' }} />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="geometriaSegmentos" linkType={false} label="resources.adoquin_detalles.fields.detallesGeometria" formClassName={classes.grid_cont12}>
                                <SingleFieldList>
                                    <TextField source="detallesGeometria"  />
                                </SingleFieldList>
                            </ArrayField>
                        </FormTab>
                        <FormTab label="dynatest.resources.adoquin_detalles.tabs.estructura">
                            <br></br>
                            <Typography variant="b" component="b">{translate('resources.adoquin_detalles.fields.informacionEstructura')}</Typography>
                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <ArrayField 
                                source="tablaSegmentos" 
                                style={{ width: '100%' }} 
                                label=" " 
                                className="inventariosSegmentos" 
                                formClassName={classes.grid_cont12}>
                                <Datagrid>
                                    <NumberField source="estructuraEspesor" label="resources.adoquin_detalles.fields.estructuraEspesor" />
                                    <NumberField source="estructuraModulo" label="resources.adoquin_detalles.fields.estructuraModulo" />
                                    <NumberField source="estructuraDs" label="resources.adoquin_detalles.fields.estructuraDs" />
                                    <TextField label="resources.adoquin_detalles.fields.material" source="material_.material" />
                                    <TextField label="resources.adoquin_detalles.fields.materialEsp" source="material_.especificacion" />
                                    <TextField label="resources.adoquin_detalles.fields.granulometria" source="granulometria_.granulometria" />
                                </Datagrid>
                            </ArrayField>
                            <NumberField label="resources.adoquin_detalles.fields.espesorTotal" source="espensorTotal" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4} />
                            <br/>
                            <ArrayField source="EstructuraSegmentos" label="resources.adoquin_detalles.fields.iggEstructura" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <NumberField source="igg" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="EstructuraSegmentos" label="resources.adoquin_detalles.fields.conceito" linkType={false} formClassName={classes.grid_cont3}>
                                <SingleFieldList>
                                    <TextField source="conceito" />
                                </SingleFieldList>
                            </ArrayField>
                            <br></br>
                            <Typography variant="b" component="b">{translate('resources.adoquin_detalles.fields.capacidadEstructural')}</Typography>
                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <ArrayField source="EstructuraSegmentos" label="resources.adoquin_detalles.fields.estructuraNumDiseno" linkType={false} fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4}>
                                <SingleFieldList>
                                    <NumberField source="estructuraNumDiseno" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="EstructuraSegmentos" label="resources.adoquin_detalles.fields.estructuraFecha" fullWidth linkType={false} formClassName={classes.grid_cont4}>
                                <SingleFieldList>
                                    <DateField source="estructuraFecha" />
                                </SingleFieldList>
                            </ArrayField>
                            <br></br>
                            <ArrayField source="EstructuraSegmentos" label="resources.adoquin_detalles.fields.estructuraNumero" fullWidth linkType={false} style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4}>
                                <SingleFieldList>
                                    <NumberField source="estructuraNumero" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="EstructuraSegmentos" label="resources.adoquin_detalles.fields.estructuraNumeroFecha" fullWidth linkType={false} formClassName={classes.grid_cont4}>
                                <SingleFieldList>
                                    <DateField source="estructuraNumeroFecha" />
                                </SingleFieldList>
                            </ArrayField>
                            <br></br>
                            <ArrayField source="EstructuraSegmentos" label="resources.adoquin_detalles.fields.estructuraCbr" fullWidth linkType={false} style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4}>
                                <SingleFieldList>
                                    <PercentageField source="estructuraCbr" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="EstructuraSegmentos" label="resources.adoquin_detalles.fields.estructuraCbrFecha" fullWidth linkType={false} formClassName={classes.grid_cont4}>
                                <SingleFieldList>
                                    <DateField source="estructuraCbrFecha" />
                                </SingleFieldList>
                            </ArrayField>
                            <Typography variant="b" component="b">{translate('resources.adoquin_detalles.fields.fechasImportantes')}</Typography>
                            <Divider fullWidth formClassName={classes.grid_cont12} />
                            <ArrayField source="EstructuraSegmentos" label="resources.adoquin_detalles.fields.estructuraFechaConst" fullWidth linkType={false} formClassName={classes.grid_cont4}>
                                <SingleFieldList>
                                    <DateField source="estructuraFechaConst" />
                                </SingleFieldList>
                            </ArrayField>
                            <ArrayField source="EstructuraSegmentos" label="resources.adoquin_detalles.fields.estructuraFechaRehab" fullWidth linkType={false} formClassName={classes.grid_cont4}>
                                <SingleFieldList>
                                    <DateField source="estructuraFechaRehab" />
                                </SingleFieldList>
                            </ArrayField>
                            <br></br>
                            <Divider fullWidth /><Divider fullWidth /><Divider fullWidth />
                            <ArrayField label="resources.adoquin_detalles.fields.detallesEstructura" source="EstructuraSegmentos" fullWidth linkType={false} formClassName={classes.grid_cont4}>
                                <SingleFieldList>
                                    <TextField source="detallesEstructura" />
                                </SingleFieldList>
                            </ArrayField>
                        </FormTab>
                        <FormTab label="dynatest.resources.adoquin_detalles.tabs.auditoria">
                            <DateField source="createdAt" label="resources.adoquin_detalles.fields.createdAt" fullWidth formClassName={classes.grid_cont4} showTime/>
                            <TextField source="createdBy" label="resources.adoquin_detalles.fields.createdBy" fullWidth formClassName={classes.grid_cont4} />
                            <TextField source="createdIn" label="resources.adoquin_detalles.fields.createdEn" fullWidth formClassName={classes.grid_cont3} />
                            <span></span>
                            <DateField source="updatedAt" label="resources.adoquin_detalles.fields.updatedAt" fullWidth formClassName={classes.grid_cont4} showTime/>
                            <TextField source="updatedBy" label="resources.adoquin_detalles.fields.updatedBy" fullWidth formClassName={classes.grid_cont4} />
                            <TextField source="updatedIn" label="resources.adoquin_detalles.fields.updatedEn" fullWidth formClassName={classes.grid_cont3} />
                            <span></span>
                        </FormTab>
                    </TabbedForm>
                </ShowView>
            }
        </ShowController>
    )
}));