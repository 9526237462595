import React, { useState } from 'react';
import {
    DateField, Filter, List, Datagrid, TextField, EditButton, ShowButton, SelectInput, ReferenceField, ReferenceInput, AutocompleteInput
} from 'react-admin';
import Button from '@material-ui/core/Button';
import { BulkDeleteWithConfirmButton, Loading } from 'react-admin';
import { Fragment } from 'react';
import config from '../../config/config';
import { CardActions, CreateButton, ExportButton } from 'react-admin';
import { translate } from 'react-admin';
let urlAppjs = config.UrlServer;

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props} />
    </Fragment>
);

const ListAction = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
        <ExportButton basePath={basePath}/>
    </CardActions>
);
const ListAction2 = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
    </CardActions>
);
const CalificacionPuentesFilter = props => {
    const [uf, setUf] = useState('none');
    const ufSet = (value) => {
        setUf(value);
    }
    const [tramo, setTramo] = useState('none');
    const tramoSet = (value) => {
        setTramo(value);
    }
    return(
    <Filter {...props}>
        <ReferenceInput 
            id="unidadFuncional"
            label="resources.calificacion_puentes.fields.unidadFuncional" 
            source="unidadFuncional"  
            reference="unidad_funcionals" 
            filterToQuery={searchText => ({ codigo: searchText })} 
            sort={{field : 'codigo', order : 'asc'}}
            validate={[ufSet]}
            alwaysOn
            fullWidth 
        >
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
        <ReferenceInput 
            label="resources.calificacion_puentes.fields.tramo" 
            source="tramo" 
            alwaysOn 
            reference="tramos" 
            filter={{ unidadFuncional: uf}}
            validate={[tramoSet]}
            filterToQuery={searchText => ({ codigo: searchText })} 
            sort={{ 
                field: 'codigo', 
                order: 'ASC' ,
                
            }}>
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
    </Filter>
    )
};

export const CalificacionPuenteList = ({ permissions, ...props }) => {
    if (!permissions) return <Loading />;
    return (
        <List {...props} 
            sort={{ field: 'createdAt', order: 'DESC' }} 
            filters={<CalificacionPuentesFilter/>}  
            actions={permissions['puentes']  == 1 ? <ListAction /> : permissions['puentes']  == 2 ? <ListAction2 />: null}
            bulkActionButtons={permissions['puentes'] == 1 ? <PostBulkActionButtons /> : null}>
            <Datagrid>
                <TextField label="resources.calificacion_puentes.fields.medicion" source="smartCode" fullWidth sortable />
                <DateField label="resources.calificacion_puentes.fields.fechaInicioInspeccion" source="fechaInicioInspeccion" fullWidth sortable />
                <TextField label="resources.calificacion_puentes.fields.medidoPor" source="medidoPor" fullWidth sortable />
                <TextField label="resources.calificacion_puentes.fields.estado" source="estado" linkType={false} fullWidth sortable />
                <TextField label="resources.calificacion_puentes.fields.tipoMedicion" source="tipoMedicion" fullWidth sortable />
                <TextField label="resources.calificacion_puentes.fields.cumplimiento" source="cumplimiento" fullWidth sortable />
                {permissions['puentes'] == 1 || permissions['puentes'] == 2 || permissions['puentes'] == 4 ? <ShowButton/>: null}
                {permissions['puentes'] == 1 || permissions['puentes'] == 2 ? <EditButton/>: null}  
            </Datagrid>
        </List>
    )
};