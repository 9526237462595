import React from 'react';
import NumberFormat from 'react-number-format';



export default function poFormatter(props){
    const { inputRef, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={inputRef}
            suffix="%"
            
            
            fixedDecimalScale={true}
            allowNegative={false}            
        />
      );
}