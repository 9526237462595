import React, { useState } from 'react';
import { CreateButton, SimpleForm, DateInput, List, CardActions, NullableBooleanInput, NumberField,Datagrid,Toolbar, TextField, Loading, DateField, BooleanField, EditButton, ShowButton, ReferenceField, Filter, ReferenceInput, SelectInput, BulkDeleteWithConfirmButton } from 'react-admin';
import { Fragment } from 'react';
import { FField } from './../../fields/FField';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import config from '../../config/config';
import { BulkDeleteButton } from 'react-admin';
import Button from '@material-ui/core/Button';
// import AddIcon from '@material-ui/icons/Add';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import VisualizacionModal from './visualizacionModal';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import {isFechaInvalida} from '../validacionesInputs';
import { translate } from 'react-admin';

const DisponibilidadFilter = translate((props) =>{
    const { translate } = props;
    return(
    <Filter {...props}>
        <ReferenceInput label="resources.disponibilidad_vias.fields.unidadFuncional" source="unidadFuncional" reference="unidad_funcionals" alwaysOn sort={{field : 'codigo' , order : 'ASC'}}>
            <SelectInput optionText="codigo" /> 
        </ReferenceInput>
        <SelectInput label="resources.disponibilidad_vias.fields.tipoEvento" sort={{ field: 'id', order: 'DESC' }}  allowEmpty source="tipoEvento" emptyValue="" choices={[
                        { id: translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente'), name: 'dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente' },
                        { id: translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente'), name: 'dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente' },
                        { id: translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Cierre'), name: 'dynatest.resources.disponibilidad_vias.select.tipoEvento.Cierre' }
         ]}  alwaysOn />
        <SelectInput label="resources.disponibilidad_vias.fields.causa" sort={{ field: 'id', order: 'DESC' }}  allowEmpty source="causa" emptyValue="" choices={[
                        { id: translate('dynatest.resources.disponibilidad_vias.select.causa.Accidente'), name: 'dynatest.resources.disponibilidad_vias.select.causa.Accidente' },
                        { id: translate('dynatest.resources.disponibilidad_vias.select.causa.Derrumbe'), name: 'dynatest.resources.disponibilidad_vias.select.causa.Derrumbe' },
                        { id: translate('dynatest.resources.disponibilidad_vias.select.causa.TrabajosVia'), name: 'dynatest.resources.disponibilidad_vias.select.causa.TrabajosVia' },
                        { id: translate('dynatest.resources.disponibilidad_vias.select.causa.Manifestacion'), name: 'dynatest.resources.disponibilidad_vias.select.causa.Manifestacion' },
                        { id: translate('dynatest.resources.disponibilidad_vias.select.causa.OrdenPublico'), name: 'dynatest.resources.disponibilidad_vias.select.causa.OrdenPublico' },
                        { id: translate('dynatest.resources.disponibilidad_vias.select.causa.Otro'), name: 'dynatest.resources.disponibilidad_vias.select.causa.Otro' }
         ]}  alwaysOn/>

        <SelectInput label="resources.disponibilidad_vias.fields.yearact" source="fecha"  choices={[
                        { id: '2000', name: '2000' },
                        { id: '2001', name: '2001' },
                        { id: '2002', name: '2002' },
                        { id: '2003', name: '2003' },
                        { id: '2004', name: '2004' },
                        { id: '2005', name: '2005' },
                        { id: '2006', name: '2006' },
                        { id: '2007', name: '2007' },
                        { id: '2008', name: '2008' },
                        { id: '2009', name: '2009' },
                        { id: '2010', name: '2010' },
                        { id: '2011', name: '2011' },
                        { id: '2012', name: '2012' },
                        { id: '2013', name: '2013' },
                        { id: '2014', name: '2014' },
                        { id: '2015', name: '2015' },
                        { id: '2016', name: '2016' },
                        { id: '2017', name: '2017' },
                        { id: '2018', name: '2018' },
                        { id: '2019', name: '2019' },
                        { id: '2020', name: '2020' },
                        { id: '2021', name: '2021' },
                        { id: '2022', name: '2022' },
                        { id: '2023', name: '2023' },
                        { id: '2024', name: '2024' },
                        { id: '2025', name: '2025' },
                        { id: '2026', name: '2026' },
                        { id: '2027', name: '2027' },
                        { id: '2028', name: '2028' },
                        { id: '2029', name: '2029' },
                        { id: '2030', name: '2030' }
        ]} alwaysOn/>
                 
        <SelectInput label="resources.disponibilidad_vias.fields.mesact" source="fechaSenal"  choices={[
                        { id: '-01-', name: 'dynatest.resources.disponibilidad_vias.select.fechaSenal.Enero' },
                        { id: '-02-', name: 'dynatest.resources.disponibilidad_vias.select.fechaSenal.Febrero' },
                        { id: '-03-', name: 'dynatest.resources.disponibilidad_vias.select.fechaSenal.Marzo' },
                        { id: '-04-', name: 'dynatest.resources.disponibilidad_vias.select.fechaSenal.Abril' },
                        { id: '-05-', name: 'dynatest.resources.disponibilidad_vias.select.fechaSenal.Mayo' },
                        { id: '-06-', name: 'dynatest.resources.disponibilidad_vias.select.fechaSenal.Junio' },
                        { id: '-07-', name: 'dynatest.resources.disponibilidad_vias.select.fechaSenal.Julio' },
                        { id: '-08-', name: 'dynatest.resources.disponibilidad_vias.select.fechaSenal.Agosto' },
                        { id: '-09-', name: 'dynatest.resources.disponibilidad_vias.select.fechaSenal.Septiembre' },
                        { id: '-10-', name: 'dynatest.resources.disponibilidad_vias.select.fechaSenal.Octubre' },
                        { id: '-11-', name: 'dynatest.resources.disponibilidad_vias.select.fechaSenal.Noviembre' },
                        { id: '-12-', name: 'dynatest.resources.disponibilidad_vias.select.fechaSenal.Diciembre' }                                    
        ]} alwaysOn/>

    </Filter>)
});
    const PostBulkActionButtons = props => (
        <Fragment>
            <BulkDeleteButton {...props} />
        </Fragment>
    );    
    //

const ActiveField = ({ record, source }) =>{
    if(record[source] == true){
        return <LockIcon/>
    }else{
        return <LockOpenIcon/>
    }
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const SimpleDialog = translate((props) =>{
    const { translate } = props;
    const { onClose, open } = props;
    const handleClose = value => {
        onClose(value);
    };
    const CustomToolbar = ({ basePath, data, ...props }) => (
        <Toolbar {...props} >
            <Button variant="secondary" id="generarInforme" onClick={handleClose} style={{ backgroundColor: '#3f51b5', color: '#fff' }}>
            {translate("resources.indicadores_cargar_informacions.cerrar")}
        </Button>
        </Toolbar>
    );
    const dateFormatterIni = (value) => {
        
        if(value){
            let dateActual = new Date();
            dateActual = dateActual.toLocaleString("en-US", {timeZone: "America/Bogota"});
            dateActual = dateActual.split(",")[0].split("/");
            let dateSeleccionada = new Date(value);
            dateSeleccionada = dateSeleccionada.toLocaleString("en-US", {timeZone: "America/Bogota"});
            dateSeleccionada = dateSeleccionada.split(",")[0].split("/"); 
            if(
                parseInt(dateSeleccionada[2]) == parseInt(dateActual[2]) &&
                parseInt(dateSeleccionada[0]) == parseInt(dateActual[0]) &&
                parseInt(dateSeleccionada[1]) >= parseInt(dateActual[1])
            ){
                let date = new Date();
                fechaIniSet(date.getFullYear() + "-01-01")
                return date.getFullYear() + "-01-01"
            }
        }

        if(value == undefined){
            let date = new Date();
            fechaIniSet(date.getFullYear() + "-01-01")
            return date.getFullYear() + "-01-01"
        }else{
            return value;
        }
    }
    const dateFormatterFin = value => {
        
        if(value){
            let dateActual = new Date();
            dateActual = dateActual.toLocaleString("en-US", {timeZone: "America/Bogota"});
            dateActual = dateActual.split(",")[0].split("/");
            let dateSeleccionada = new Date(value);
            dateSeleccionada = dateSeleccionada.toLocaleString("en-US", {timeZone: "America/Bogota"});
            dateSeleccionada = dateSeleccionada.split(",")[0].split("/"); 
            if(
                parseInt(dateSeleccionada[2]) == parseInt(dateActual[2]) &&
                parseInt(dateSeleccionada[0]) == parseInt(dateActual[0]) &&
                parseInt(dateSeleccionada[1]) >= parseInt(dateActual[1])
            ){
                let date = new Date();
                fechaIniSet(date.getFullYear() + "-01-01")
                return date.getFullYear() + "-01-01"
            } 
        }

        if(value == undefined){
            let date = new Date();
            fechaFinSet(date.getFullYear() + "-01-01")
            return date.getFullYear() + "-01-01"
        }else{
            return value;
        }
    }
    const [uf, setUf] = useState('none');
    const ufSet = (value) => {
        setUf(value);
    }
    const [tipoEvento, setTipoEvento] = useState('none');
    const tipoEventoSet = (value) => {
        setTipoEvento(value);
    }
    const [tramo, setTramo] = useState('none');
    const tramoSet = (value) => {
        setTramo(value);
    }
    const [parametro, setParametro] = useState('');
    const ValidarParametro = (value) => {
        setParametro(value)
    }
    const [fechaIni, setfechaIni] = useState('');
    const fechaIniSet = (value) => {
        setfechaIni(value)
    }
    const [fechaFin, setFechaFin] = useState('');
    const fechaFinSet = (value) => {
        setFechaFin(value)
    }
    return (
        <Dialog fullScreen onClose={handleClose} aria-labelledby="simple-dialog-title" TransitionComponent={Transition} open={open}>
            <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close" style={{ textAlign: "right" }}>
                <CloseIcon />
            </IconButton>
            <DialogTitle id="simple-dialog-title">{translate("resources.indicadores_cargar_informacions.title")}</DialogTitle>
            <SimpleForm toolbar={<CustomToolbar />}>
                <Grid container spacing={24}>
                    <Grid item xs={5}>
                        <SelectInput label="Indicador" fullWidth source="indicador" choices={[
                                { id: '24', name: translate('resources.indicadores_cargar_informacions.fields.disponibilidadVia') },
                                { id: '25', name: translate('resources.indicadores_cargar_informacions.fields.tiempoAtencion') },
                                { id: '26', name: translate('resources.indicadores_cargar_informacions.fields.ocupacionCarril') }
                            ]} validate={[ValidarParametro]}/>

                        {/*<ReferenceInput label="Indicador" source="indicador" filter={{ elementoAplica: 'OPER' }} reference="parametros" sort={{ field: 'id', order: 'ASC' }} fullWidth validate={ValidarParametro}>
                            <SelectInput optionText="codigoCompleto" />
                        </ReferenceInput>*/}
                    </Grid>
                    <Grid item xs={7}></Grid>
                    <Grid item xs={3}>
                        <ReferenceInput
                            id="unidadFuncional"
                            label="resources.disponibilidad_vias.fields.unidadFuncional"
                            source="unidadFuncional"
                            reference="unidad_funcionals"
                            filterToQuery={searchText => ({ codigo: searchText })}
                            sort={{ field: 'codigo', order: 'asc' }}
                            validate={[ufSet]}
                            alwaysOn
                            fullWidth
                        >
                            <SelectInput options={{ fullWidth: true }} optionText="codigo" />
                        </ReferenceInput>
                    </Grid>
                    <Grid item xs={3}>
                        <DateInput format={dateFormatterIni} label="resources.costos.fields.fechaInicial" source="fechaInicial" id="fechaInicial" fullWidth validate={[isFechaInvalida,fechaIniSet]} />
                    </Grid>
                    <Grid item xs={3}>
                        <DateInput format={dateFormatterFin} label="resources.costos.fields.fechaFinal" source="fechaFinal" id="fechaFinal" fullWidth validate={[isFechaInvalida,fechaFinSet]} />
                    </Grid>
                    <Grid item xs={3}>
                        <SelectInput label="resources.disponibilidad_vias.fields.tipoEvento" fullWidth source="tipoEvento" choices={[
                                { id: translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente'), name: translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Incidente') },
                                { id: translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente'), name: translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Accidente') },
                                { id: translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Cierre'), name: translate('dynatest.resources.disponibilidad_vias.select.tipoEvento.Cierre') }
                            ]} validate={[tipoEventoSet]}/>
                    </Grid>
                    {uf && fechaFin && fechaIni && parametro && tipoEvento && 
                        <Grid item xs={12}>
                            <VisualizacionModal fechaIni={fechaIni} fechaFin={fechaFin} tipoEvento={tipoEvento} ufuncional={uf.replace("/unidad_funcionals/", "")} tramo={tramo.replace("/tramos/", "")} indicador={parametro.replace("/parametros/", "")} />
                        </Grid>
                    }
                </Grid>
            </SimpleForm>
        </Dialog>
    )
});

const ListAction = translate(({ basePath, data, resource,...props }) => {
    const [open, setOpen] = React.useState(false);
    const { translate } = props;
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = value => {
        setOpen(false);
    };
    return (
        <CardActions>
            {/* <LinkToMapa style={{paddingTop:"13px", marginRight:"20px"}}/> */}
            <CreateButton basePath={basePath} style={{ paddingTop: "13px", marginRight: "20px" }} />
            <Button variant="outlined" color="primary" onClick={handleClickOpen}>
                <VisibilityIcon /> {translate('resources.indicadores_cargar_informacions.visualizacion')}
            </Button>
            <SimpleDialog open={open} onClose={handleClose} />
        </CardActions>
    );
}); 
const ListAction2 = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} style={{ paddingTop: "13px", marginRight: "20px" }} />
    </CardActions>
);
export const DisponibilidadList = translate(({ permissions, ...props }) => {
    if (!permissions) return <Loading />;  
    return (
    <List {...props} 
        title="dynatest.resources.disponibilidad_vias.title.listTitle" 
        perPage={25} 
        sort={{ field: 'updatedAt', order: 'DESC' }}  
        filters={<DisponibilidadFilter/>} 
        actions={permissions['disponibilidadVia']  == 1 ? <ListAction /> : permissions['disponibilidadVia']  == 2 ? <ListAction2 />: null}
        bulkActionButtons={permissions['disponibilidadVia'] == 1 ? <PostBulkActionButtons /> : null}>
        <Datagrid>                        
            <TextField label="resources.disponibilidad_vias.fields.consecutivo" source="fullCodigo" />     
            <TextField label="resources.disponibilidad_vias.fields.unidadFuncional" source="unidadcodigo" />     
            <TextField label="resources.disponibilidad_vias.fields.tipoEvento" source="tipoEvento" />     
            <TextField label="resources.disponibilidad_vias.fields.causa" source="causa" />  
            <TextField label="resources.disponibilidad_vias.fields.yearact" source="yearact" />  
            <TextField label="resources.disponibilidad_vias.fields.mesact" source="mesact" />  
            <FField label="resources.disponibilidad_vias.fields.horaReporte" source="horareport"/>
            <FField label="resources.disponibilidad_vias.fields.horaSenal" source="horasenalizacion"/>
            <FField label="resources.disponibilidad_vias.fields.tiempSenal" source="tiempoSenalizacion"/>
            <TextField label="resources.disponibilidad_vias.fields.tiempoFinalizacion" source="tiempoFinalizacion"/>
            <DateField label="resources.disponibilidad_vias.fields.fechaFinalizacion" source="fechaDespeje"/> 
            <FField label="resources.disponibilidad_vias.fields.horaFinal" source="horaDespeje"/>
            <ActiveField label="resources.disponibilidad_vias.fields.finalizar" source="finalizar"/>
            {permissions['disponibilidadVia'] == 1 || permissions['disponibilidadVia'] == 2 || permissions['disponibilidadVia'] == 4 ? <ShowButton/>: null}
            {permissions['disponibilidadVia'] == 1 || permissions['disponibilidadVia'] == 2 ? <EditButton/>: null}  
        </Datagrid>
    </List>
)});
