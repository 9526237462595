import PropTypes from 'prop-types';
import PercentageFormat from './../format/PercentageFormat';

/**
 * 
 * , isNumericString:true
 */

export const PercentageField = ({ source, record = {}, props }) => PercentageFormat({value: record[source], displayType:'text', ...props});

PercentageField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

PercentageField.defaultProps = {
    addLabel: true,
};
