import React from 'react';
import { NumberInput, DisabledInput, TextInput, required } from 'react-admin';
import loader from './../../../../../loader10.gif';
import config from '../../../../../config/config';
let urlAppjs = config.UrlServer;

class RetroreflectividadEdit extends React.Component{

    constructor(props){
        super(props)
        this.state = { colores: [], datoEva: [], cumple: "" }
        this.completado= React.createRef()
    }

    componentDidMount(){
        let url_elemento_aux = document.location.hash;
        let croed=url_elemento_aux.split("/");
        let crearoeditar="";
        let componente =""
        if(croed[2]==="create"){
            crearoeditar=1;
        }
        else{
        let url_elemento = url_elemento_aux.split("/%2F");
        componente = url_elemento[1];
        componente = componente.replace("/3", "");
        componente = componente.replace("%2F", "/");
        crearoeditar=2;
        }

        if(crearoeditar===2){
            this.setState({ datoEva: this.props.record })
            // fetch(urlAppjs+"/"+componente)
        // .then((response) => {
        //     return response.json()
        // })
        // .then((datoEva) => {
        //     this.setState({ datoEva: datoEva })
        // })
    }
    let proyectoid=localStorage.getItem("id_project");
        fetch(urlAppjs+"/colores.json?proyecto="+proyectoid)
        .then((response) => {
            return response.json()
        })
        .then((colores) => {
            this.setState({ colores: colores })
        })
    }
    
    render(){
        
        const { colores } = this.state; 
        const { datoEva } = this.state;       
        const {cumple}= this.state;
        
        
        if(colores.length > 0){
            let response4 = [];
            if(datoEva.retroreflectividad){
                datoEva.retroreflectividad[0].svColor.map( responseSV =>{
                    if(responseSV.valor===-1){
                        response4.push("-")    
                    }
                    else{
                        response4.push(responseSV.valor)
                    }
                    
                }
                      ) 
            }    
            //let valoredit=datoEva.calificacion;
            let minimos = [];
            colores.map(dato=>{
                minimos.push(dato.valor);
            })
            let valoredit="";
            const validarretrorefl=(value)=>{
                //console.log(this.completado);
                let largo=colores.length;
                let resultado =[];
                let cumpleaux=0;
                let cumplea="No Cumple";
                let valorzux=0;
                for(let j=0; j<largo; j++){
                    let valor = document.getElementById('rt_color_'+j);
                    if(valor && valor.value!=""){
                        valor.style.textAlign="right"
                        resultado.push(valor.value);
                    }
                }
                if(resultado.length===largo){
                    resultado.map((data, i)=>{
                        if(parseInt(data)>=parseInt(minimos[i])){
                            cumpleaux++;
                        }
                        else if(data==="-"){
                            cumpleaux++;
                            valorzux++;
                        }
                    })
                }
                if(cumpleaux===largo && valorzux!=cumpleaux){
                    cumplea="Cumple"
                }
                else{
                    cumplea="No Cumple"
                }
                //this.setState({cumple: cumplea})
                valoredit=cumplea;

            }
            
            
            // if(datoEva.retroreflectividad && datoEva.retroreflectividad[0].calificacion && cumple===""){
            //     valoredit=datoEva.retroreflectividad[0].calificacion
            //     //console.log("cumple ->"+cumple)
            // }
            if(cumple==="" && valoredit===""){
            //valoredit="No Cumple";
            //console.log("Cumple3 -> "+cumple)
            validarretrorefl();
            }
            else{
                valoredit=cumple
                //console.log("cumple2 ->"+cumple)
            }
            const formatocoma=v=>{
                let coma;
                if(v && typeof(v)==="string"){
                    coma =v.search(',');
                }
                if(isNaN(v) && v!="-" && coma===-1){
                    if(v){
                    return v.substring(0, v.length - 1);
                    }
                    else{
                    return ""
                    }
                }else{
                    if(coma!=-1){
                        if(v && typeof(v)==="string"){
                            let aux= v.split(',')
                            if(isNaN(aux[1])){
                                return v.substring(0, v.length - 1);
                            }
                            else{
                                return v.replace('.', ',')    
                            }
                        }
                        else{
                            return v
                        }
                    }
                    else{
                        if(v){
                          return v.replace('.', ',')    
                        }
                        else{
                            return v
                        }
                    }
                }
            }

            return(
                <React.Fragment>
                    <div style={{display: 'flex'}}>                    
                        {
                            colores.map((data, i) => (
                                <div key={i} style={{margin: '0px 15px'}}>
                                    <div style={{textAlign: 'center', marginTop: '15px'}}>
                                        {data.color}<br/>{"cd/lux/m2"}
                                    </div>
                                    <div>
                                        <TextInput label=" " source={`rt_color_${i}`} id={`rt_color_${i}`} defaultValue={response4[i]}  onChange={validarretrorefl} format={formatocoma} validate={[required()]}/> 
                                        <span style={{color:"rgb(241,220,43)"}}> Mínimo {minimos[i]}</span>
                                        <DisabledInput source={`rt_color_id_${i}`} label=" " defaultValue={"/colores/"+data.id} style={{display: 'none'}}/>              
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <hr />
                    <div style={{display: 'flex', justifyContent: 'flex-end', marginTop: '-30px'}}>
                        <strong ref={this.completado} style={{display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}>Condición: </strong><DisabledInput source="condicion_retroreflectividad" label=" " defaultValue={valoredit}/>
                    </div> 
                    <DisabledInput source="rt_length" label=" " defaultValue={colores.length} style={{display: 'none'}}/>              
                </React.Fragment>    
            )          
                     
        }else{                      
            return( 
                <div className="Reload2">
                    <img src={loader} />
                </div> 
            )   
        }
            
    }    
}

export default RetroreflectividadEdit;