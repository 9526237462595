import React from 'react';
import { WithPermissions,ListButton, Datagrid, ReferenceField,TabbedShowLayout, EditButton, Tab, TextField, 
    DateField, CardActions, BooleanField, ChipField, ArrayField,ReferenceArrayField, SingleFieldList, NumberField 
} from 'react-admin';
import { Show } from './../Show';
import { withStyles } from '@material-ui/core';
import { translate } from 'react-admin';

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};

const styles ={
    ocultar:{display:"none"}, 
    
}

export const ProveedorShow = translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;

    return(
    <Show title="dynatest.resources.proveedors.title.showTitle" {...props}>
        <TabbedShowLayout>
            <Tab label="dynatest.resources.proveedors.tabs.informacionProveedor">
                {/* <ReferenceField source="proyecto" reference="proyectos">
                    <TextField source="nombre" />
                </ReferenceField>  */}
                
                 <ReferenceField source="proyecto" reference="proyectos" linkType={false} >
                    <TextField source="nombre" />
                </ReferenceField>  
                <BooleanField label="dynatest.resources.proveedors.boolean.mantenimiento" source="mantenimiento"/>
                <BooleanField label="dynatest.resources.proveedors.boolean.suministros" source="suministros"/>

                <ReferenceField source="sistema" reference="sistemas" linkType={false} >
                    <TextField source="nombre" />
                </ReferenceField>  
                <span></span>   
                <span></span>  
                
                <TextField source="identificacion"/>
                <NumberField source="numeroIdentificacion" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}}/>
                <TextField source="razonSocial"/>
                {/* <TextField source="contacto"/>        */}
                <TextField source="nombre"/>
                <TextField source="apellido"/>
                <span></span>
                                          
                <TextField source="direccion"/>                
                
                <ReferenceField source="departamento" reference="departamentos" linkType={false} >
                    <TextField source="nombre" />
                </ReferenceField>

                <ReferenceArrayField label="resources.proveedors.fields.municipio1" reference="municipios" source="municipio">
                <SingleFieldList>
                    <ChipField source="nombre" />
                </SingleFieldList>
                </ReferenceArrayField>
                {/* <ReferenceField label="Indicativo"  source="departamento" reference="departamentos" linkType={false} >
                    <TextField source="indicativo" />
                </ReferenceField> */}
                <TextField source="telefono" />
                <TextField source="telefono2" />
                <br/>
                <TextField source="correo"/>
                <span></span>   
                <span></span>   
               
                <TextField source="observaciones"/>

            </Tab>
            <Tab label="dynatest.resources.proveedors.tabs.calificacion">
             
                <ReferenceArrayField label="" reference="calificacion_proveedors" source="calificaciones">
                    <Datagrid sortable={false}>                        
                        <TextField label ="resources.proveedors.fields.calificacion" source="calificacion" sortable={false}/>
                        <DateField  label="resources.proveedors.fields.fechaCalificacion" source="fechaCalificacion" sortable={false}/>
                        <TextField label="resources.proveedors.fields.usuario_id" source="usuario_id" sortable={false}/>
                    </Datagrid>
                </ReferenceArrayField>
                <span></span>   
                <span></span>  
                <NumberField source="promedioCal" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}}/>
            </Tab>
            <Tab label="dynatest.resources.proveedors.tabs.auditoria">
                <DateField source="createdAt" showTime/> 
                <TextField source="createdBy" />
                <span></span>               
                <DateField source="updatedAt" showTime/>
                <TextField source="updatedBy" />
                <span></span>   
            </Tab>
        </TabbedShowLayout>
    </Show>
)}));