import React ,{useState} from 'react';
import {  TextInput, SelectInput, ReferenceInput } from 'react-admin';
import { Edit } from './../Edit';
import { PInput } from './../../inputs/PInput';
import Divider from '@material-ui/core/Divider';
import {required,minLength,maxLength} from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { SimpleForm } from './../SimpleForm';
import { withStyles} from '@material-ui/core';
import {styles}  from './../../EstilosGrid';
import { translate } from 'react-admin';
import config from '../../config/config';
let urlAppjs = config.UrlServer;

export const RutaEdit = translate(withStyles(styles)(({classes, ...props  }) => {
    const { translate } = props;
    const  [acceso, setAcceso] = useState(0);

const [codigoIngreso , setCodigoIgreso] =useState('')
const  [pInicio, setPinicio] = useState('');
const  [pFin, setPfin] = useState('');
const  [prInicio, setPrinicio] = useState('');
const  [prFin, setPrfin] = useState('');

const ErrorRuta = (value) =>{    
    setCodigoIgreso(value)        
}   

const ValorMaximoPrEditFin =() =>{
    
    let value = document.getElementById('ingresoPrFin').value;
    value = value.split("PR")
    if(value[1] !== undefined)
    {
        value=value[1].split("+")
        
        if(value[0] > 999)
        {
            return translate('resources.validaciones.mayorPR9')
        }
    }
}
 const ValorMaximoPrEditInicio =() =>{
    
    let value = document.getElementById('ingresoPrInicio').value;
    value = value.split("PR")
    if(value[1] !== undefined)
    {
        value=value[1].split("+")
        
        if(value[0] > 999)
        {
            return translate('resources.validaciones.mayorPR9')
        }
    }
} 
 const validarLogicaPrFinEdit= ()=>{ 
    let prInicio = document.getElementById('ingresoPrInicio').value;
    prInicio = prInicio.split('PR')
    if(prInicio[1] !== undefined)
    {
        prInicio = prInicio[1].split('+')
        let prFin = document.getElementById('ingresoPrFin').value;
        prFin = prFin.split('PR')
        prFin = prFin[1].split('+')

        if(parseInt(prFin[0]) < parseInt(prInicio[0]))
        {   
            return translate("resources.validaciones.prFin");
        }
        if(parseInt(prFin[0]) === parseInt(prInicio[0]) && parseInt(prFin[1]) === parseInt(prInicio[1]))
        {
            return translate('resources.validaciones.prFin')
        }
        if(parseInt(prFin[0]) === parseInt(prInicio[0]) && parseInt(prFin[1]) < parseInt(prInicio[1]))
        {
            return translate('resources.validaciones.prFin')
        }
    }
}

const PrInicial = () =>{
    let value = document.getElementById('ingresoPrInicio').value;
    if(value !== undefined && value !== null && value !==''){
        value = value.split('PR'); 
        value =value[1].split('+');
        value = value[0] +'.'+ value[1];    
        setPinicio(value);
        
    }  
}

const PrFinal = () =>{
       
}

const CodigoRuta = () =>{
    
    if(codigoIngreso !== '' && acceso === 0){
        fetch(urlAppjs+"/CodigoRutaEdit/"+codigoIngreso)
        .then(function(response){
            return response.json()
        })
        .then(function(myJson){
            
            let prInicio=myJson[0]
            let prFin=myJson[0]
            
            if(prInicio !== undefined){
                
                prInicio=prInicio['pFin']
                // prFin=prFin['pFin']
                setPrinicio(prInicio)
                // setPrfin(prFin)                
            }                           
            // if(codigoIngreso !== 0 && prInicio > prFin ){
            //     setAcceso(0)
                
            // }else if(codigoIngreso !== 0 && prInicio <= prFin && prInicio !== null ){                
                setAcceso(1)
                
            // }else if((prInicio === null && prFin === null) || (prInicio === null)){
            //     setAcceso(0)
               
            // } 
            
        })
    }   
}

    return(
    <Edit title={translate('dynatest.resources.rutas.title.editTitle')} {...props} >
        <SimpleForm submitOnEnter={false} redirect="list">
            <Grid container spacing={24}>                
                <Grid item xs={12}>
                    <ReferenceInput label="resources.rutas.fields.proyecto" source="proyecto" disabled reference="proyectos" validate={[required()]}>
                        <SelectInput  optionText="nombre" fullWidth />
                    </ReferenceInput>
                </Grid>                
                <Grid item xs={6}>
                    <TextInput label="resources.rutas.fields.codigo" source="codigo" validate={[ErrorRuta, CodigoRuta]} fullWidth id="codigo" fullWidth disabled/>
                </Grid>
                <Grid item xs={6}>
                    <SelectInput label="resources.rutas.fields.categoriaRuta" source="categoriaRuta" choices={[
                            { id: 1, name: 'dynatest.resources.rutas.select.categoria.priOrden' },
                            { id: 2, name: 'dynatest.resources.rutas.select.categoria.segOrden' },
                            { id: 3, name: 'dynatest.resources.rutas.select.categoria.terOrden' },
                        ]}  fullWidth validate={[required()]}                   
                    />
                </Grid>                
                <Grid item xs={6}>
                    <PInput label="resources.rutas.fields.pInicio" id="ingresoPrInicio" source="pInicio" fullWidth validate={[required(), ValorMaximoPrEditInicio]} onChange={PrInicial}/>
                </Grid>
                <Grid item xs={6}>
                    <PInput label="resources.rutas.fields.pFin" id="ingresoPrFin" source="pFin" fullWidth validate={[required(),ValorMaximoPrEditFin, validarLogicaPrFinEdit]} />
                </Grid>
                <Grid item xs={12}>                    
                    <b>{translate('dynatest.resources.rutas.title.inforInvias')}</b>                  
                </Grid>
                <Grid item xs={12}>
                    <Divider />                    
                </Grid>
                <Grid item xs={6}>
                    <TextInput label="resources.rutas.fields.tramo" source="tramo"  fullWidth validate={[required(), minLength(3), maxLength(100)]}/>
                </Grid>
                <Grid item xs={6}>
                    <TextInput label="resources.rutas.fields.sector" source="sector"  fullWidth validate={[required(), minLength(3), maxLength(100)]}/>
                </Grid>
                <Grid item xs={6}>
                    <TextInput label="resources.rutas.fields.origen" source="origen"  fullWidth validate={[required(), minLength(3), maxLength(100)]}/>
                </Grid>
                <Grid item xs={6}>
                    <TextInput label="resources.rutas.fields.destino" source="destino" fullWidth validate={[required(), minLength(3), maxLength(100)]}/>
                </Grid>
            </Grid>
        </SimpleForm>
    </Edit>
)}));