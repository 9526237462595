import React, { useState } from 'react';
import { List, Datagrid, TextField, SelectField, EditButton, ShowButton, Filter, ReferenceInput, SelectInput, Confirm} from 'react-admin';
import { PField } from './../../fields/PField';
import { WithPermissions, BulkDeleteWithConfirmButton, Loading  } from 'react-admin';
import { Fragment } from 'react';
import { connect } from 'react-redux';
import { withStyles} from '@material-ui/core';
import {styles}  from './../../EstilosGrid';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import { translate } from 'react-admin';
import config from '../../config/config';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconCancel from '@material-ui/icons/Cancel';
import { CardActions, CreateButton, ExportButton } from 'react-admin';
let urlAppjs = config.UrlServer;

export const RutaList = translate(withStyles(styles)(({ classes, permissions, ...props }) => {

    const { translate } = props;
    const [open, setOpen] = React.useState(false);
    const handleClose = value => {
        setOpen(false);
    };

    const UnidadFuncionalFilter = (props) => (
        <Filter {...props}>
            <SelectInput label="resources.rutas.fields.categoriaRuta" source="categoriaRuta" choices={[
                { id: 1, name: 'dynatest.resources.rutas.select.categoria.priOrden' },
                { id: 2, name: 'dynatest.resources.rutas.select.categoria.segOrden' },
                { id: 3, name: 'dynatest.resources.rutas.select.categoria.terOrden' },
             ]}  alwaysOn           
            />
        </Filter>
    );
    const EliminarRegistro = (props) => {
        let datos = Object.values(props);
        console.log(datos)
        datos[3].forEach(element => {
            let ids      = element.replace(/\D/g,"");
            let elemen   = element.replace("/rutas/", "");
            let elemento = elemen.replace(/\d/g,""); 
            
            const dataSend = new FormData();
            dataSend.append('id', ids);
            dataSend.append('elemento', 'ruta');
            let requestOptionsReplace = {
                method: 'POST',
                headers: { 'authorization': 'Bearer ' + localStorage.getItem('token') },
                body: dataSend
            };
            fetch(urlAppjs + "/eliminarRegistro/", requestOptionsReplace)
            .then(response => response.json())
            .then((dataRequestInventario) => {
                if(dataRequestInventario == "Success"){
                    console.log(dataRequestInventario, "OK")
                    window.location.reload();
                }
            }).catch((error) => {
                setOpen(true);
                console.log(error)
            });
        });
    } 
    
    const PostBulkActionButtons = (props) => { 
        const [open, setOpen] = useState(false);
        const handleClick = () => setOpen(true);
        const handleDialogClose = () => setOpen(false);
        const handleConfirm = () => {
            EliminarRegistro(props);
            setOpen(false);
        };

        return (
            <>
                <Button label="Update Posts" onClick={handleClick} style={{
                    fontSize: '0.8125rem', color: '#f44336', border: 0,
                    display: 'inline-flex', alignItems: 'center',
                    width: '7rem', height: '2rem'
                }}>
                    <DeleteIcon style={{ width: '1.3rem', paddingRight: '0.3rem' }} />{translate('resources.validaciones.borrar')}
                </Button>
                
                <Confirm
                    isOpen={open}
                    title="resources.validaciones.valBorrarRuta"
                    content="resources.validaciones.valBorrar"
                    onConfirm={handleConfirm}
                    onClose={handleDialogClose}
                />
            </>
        );
                    
    };
    function SimpleDialog(props) {
        const { open } = props;
        const handleClose = () => {
            setOpen(false);
        };
    
        return (
            <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} fullWidth={true} maxWidth={'sm'}>
                <DialogTitle id="simple-dialog-title">{translate('resources.validaciones.valEliminacion')}</DialogTitle>
                <DialogContent>
                    {translate('resources.validaciones.valRegistroAsociado')}
                </DialogContent>
                <DialogActions>
                    <Button label="Cerrar" onClick={handleClose}>
                        <IconCancel />
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
    const ListAction = ({ basePath, data, resource }) => (
        <CardActions>
            <CreateButton basePath={basePath} /> 
            <ExportButton basePath={basePath}/>
        </CardActions>
    );
    const ListAction2 = ({ basePath, data, resource}) => (
        <CardActions>
            <CreateButton basePath={basePath} /> 
        </CardActions>
    );
    if (!permissions) return <Loading />;  
    return (
        <Fragment>
            <SimpleDialog open={open} onClose={handleClose} />
            <List {...props} 
                actions={permissions['rutas']  == 1 ? <ListAction /> : permissions['rutas']  == 2 ? <ListAction2 />: null} 
                sort={{ field: "updatedAt", order: 'DESC' }}  
                filters={<UnidadFuncionalFilter/>} 
                bulkActionButtons={permissions['rutas']  == 1 ? <PostBulkActionButtons />: null}>
                <Datagrid>            
                    <TextField source="codigo" />
                    <SelectField source="categoriaRuta" 
                        choices={[
                            { id: 1, name: 'dynatest.resources.rutas.select.categoria.priOrden' },
                            { id: 2, name: 'dynatest.resources.rutas.select.categoria.segOrden' },
                            { id: 3, name: 'dynatest.resources.rutas.select.categoria.terOrden' },
                        ]} 
                    />
                    <PField source="pInicio"/>
                    <PField source="pFin"/>
                    <TextField source="tramo" />  
                    {permissions['rutas'] == 1 || permissions['rutas'] == 2 || permissions['rutas'] == 4 ? <ShowButton/>: null}
                    {permissions['rutas'] == 1 || permissions['rutas'] == 2 ? <EditButton/>: null}    
                </Datagrid>
            </List>
        </Fragment>
)}));

const mapStateToProps = 
    state => 
        ({ 
        proyecto: state.proyecto, 
        ruta: state.ruta,
        unidadFuncional: state.unidadFuncional,
    });

export default connect(mapStateToProps, {})(RutaList);