import React from 'react';
import {Query, NumberInput, TextInput,Loading,Error, EditButton } from 'react-admin';
import Grid from '@material-ui/core/Grid';
const AbsSegmento = ({
    segmento,
})  => (
    <Query type='GET_ONE' resource='segmentos' payload={{ id: segmento }}>
        {({ data, loading, error }) => {
            if (data) {
            return (
                <Grid>
                    <NumberInput 
                        source="abInicios"
                        label="ABSCISA INICAL"
                        id="IdAbInicio"
                        defaultValue={data.abInicioA}                       
                        style={{ display: 'none', width: '100%' }}
                        fullWidth
                    />
                    <NumberInput 
                        source="abFins"
                        label="ABSCISA FINAL"
                        id="IdAbFin"
                        defaultValue={data.abFinA}                       
                        style={{ display: 'none', width: '100%' }}
                        fullWidth
                    />
                    <NumberInput 
                        source="prInicios"
                        label="PR INICAL"
                        id="IdprInicio"
                        defaultValue={data.PInicio}                    
                        style={{ display: 'none', width: '100%' }}
                        fullWidth
                    />
                    <NumberInput 
                        source="prFins"
                        label="PR FINAL"
                        id="IdprFin"
                        defaultValue={data.PFin}                      
                        style={{ display: 'none', width: '100%' }}
                        fullWidth
                    />
                    <NumberInput 
                        source="altitudInis"
                        label="Altotud inicial"
                        id="alturaIni"
                        defaultValue={data.alturaIni}                       
                        style={{ display: 'none', width: '100%' }}
                        fullWidth
                    />
                    <NumberInput 
                        source="altitudIniF"
                        label="Altotud inicial"
                        id="alturaFin"
                        defaultValue={data.alturaFin}                       
                        style={{ display: 'none', width: '100%' }}
                        fullWidth
                    />
                </Grid>
                
            )}
            else{
                return (<div></div>)
            }
        }}
    </Query>
);



export default AbsSegmento;



// import React from 'react';
// import { TextInput, NumberInput,Query } from 'react-admin'; 
// import loader from './../loading-dots.gif';
// import Grid from '@material-ui/core/Grid';
// class CoordenadasInventarios extends React.Component{
//     constructor(props){
//         super(props)
//         this.state = { Elementos: [],  Respuesta: [] }
//     }

//     componentWillMount() {
//         if(this.props.tipo === 'create'){

//             fetch(urlAppjs+ window.localStorage.getItem('id_project2') +`.json`)
//             .then((response) => {
//                 return response.json()
//             })
//             .then((Elementos) => {
//                 this.setState({ Elementos: Elementos })
                
//             })
        
//         }else{
           
//             let id = window.location.hash;
//             id = id.replace(`#/${this.props.tabla}/%2F${this.props.tabla}%2F`, '');
//             id =id.replace('/1', '');
            
//             fetch(urlAppjs+`${this.props.tabla}${id}.json`, 
//                 {headers:{
//                 'authorization': 'Bearer '+localStorage.getItem('token')
//               }
//             })
//             .then((response) => {
//                 return response.json()
//             })
//             .then((Respuesta) => {
//                 this.setState({ Respuesta: Respuesta })
//             })

//             fetch(urlAppjs+this.props.tabla+".json", {headers:{
//                 'authorization': 'Bearer '+localStorage.getItem('token')
//               }
//             })
//             .then((response) => {
//                 return response.json()
//             })
//             .then((Elementos) => {
//                 this.setState({ Elementos: Elementos })
//             })
//         }

//       }

//     //   shouldComponentUpdate(nextProps, nextState) { //SE UTILIZA PARA VALIDAR LOS VALORES NUEVOS Y ANTIGUOS PARA RENDERIZAR EL COMPONENTE
          
//     //     if (this.props.table !== nextProps.table) return true;
          
//     //   }

//       render() {

//         const { Elementos } = this.state;
//         const { Respuesta } = this.state;
//         const choices = [];

//         if(Elementos["id"] !== undefined && Elementos["id"] !== null && Elementos["id"] !== '' ){
//             let latitud = Elementos["latitud"];
//             let latitud2 = Elementos["latitud2"];
//             let longituds = Elementos["longitud"];
//             let longitud2 = Elementos["longitud2"];
//             let abInicio = Elementos["abInicio"];
//             let abFin = Elementos["abFin"];
//             let alturaIni = Elementos["alturaIni"];
//             let alturaFin = Elementos["alturaFin"];
//             // debugger

//             if(this.props.tipo === "create" || this.props.tipo === "editar"){

//                 return(
//                     <Grid>
//                         {/* <TextInput 
//                             source="latitud"
//                             label="Latitud mínima"
//                             id="latMin"
//                             defaultValue={data.latitud}                       
//                             style={{ display: 'none', width: '100%' }}
//                             fullWidth
//                         />
//                         <TextInput 
//                             source="latitud1"
//                             label="Latitud máxima"
//                             id="latMax"
//                             defaultValue={data.latitud2}                       
//                             style={{ display: 'none', width: '100%' }}
//                             fullWidth
//                         />
//                         <TextInput 
//                             source="longitud"
//                             label="longitud mínima"
//                             id="lngMin"
//                             defaultValue={data.longituds}                       
//                             style={{ display: 'none', width: '100%' }}
//                             fullWidth
//                         />
//                         <TextInput 
//                             source="longitud2"
//                             label="Longitud máxima"
//                             id="lngMax"
//                             defaultValue={data.longitud2}                       
//                             style={{ display: 'none', width: '100%' }}
//                             fullWidth
//                         /> */}
//                         <TextInput 
//                             source="abInicios"
//                             label="ABSCISA INICAL"
//                             id="IdAbInicio"
//                             defaultValue={data.abInicio}                       
//                             style={{ display: 'none', width: '100%' }}
//                             fullWidth
//                         />
//                         <TextInput 
//                             source="abFins"
//                             label="ABSCISA FINAL"
//                             id="IdAbFin"
//                             defaultValue={data.abFin}                       
//                             style={{ display: 'none', width: '100%' }}
//                             fullWidth
//                         />
//                         <NumberInput 
//                             source="altitudInis"
//                             label="Altotud inicial"
//                             id="alturaIni"
//                             defaultValue={data.alturaIni}                       
//                             style={{ display: 'none', width: '100%' }}
//                             fullWidth
//                         />
//                         <NumberInput 
//                             source="altitudInis"
//                             label="Altotud inicial"
//                             id="alturaFin"
//                             defaultValue={data.alturaFin}                       
//                             style={{ display: 'none', width: '100%' }}
//                             fullWidth
//                         />
//                     </Grid>
//                 )
//             }
//         }else{
//             return(
//                 <div></div>
//             )
//         }
//       }
// }

// export default CoordenadasInventarios;