import React from 'react';
import Typography from '@material-ui/core/Typography';
import { SelectInput, DisabledInput } from 'react-admin';
import loader from './../../../../../loader10.gif';
import config from '../../../../../config/config';
let urlAppjs = config.UrlServer;

class Grupo3Show extends React.Component{

    constructor(props){
        super(props)
        this.state = { grupo3: [], datoEva: [] }
    }

    componentDidMount(){

        let url_elemento_aux = document.location.hash;
        let croed=url_elemento_aux.split("/");
        let crearoeditar="";
        let componente =""
        if(croed[2]==="create"){
            crearoeditar=1;
        }
        else{
        let url_elemento = url_elemento_aux.split("/%2F");
        componente = url_elemento[1];
        componente = componente.replace("%2F", "/");
        componente = componente.replace("/show/2", "");
        crearoeditar=2;
        }
        
        // console.log(urlAppjs+"/"+componente)
        if(crearoeditar===2){
            this.setState({ datoEva: this.props.record })
            // fetch(urlAppjs+"/"+componente)
        // .then((response) => {
        //     return response.json()
        // })
        // .then((datoEva) => {
        //     this.setState({ datoEva: datoEva })
        // })
    }
        fetch(urlAppjs+"/preguntas?elemento_indicador=3.json")
        .then((response) => {
            return response.json()
        })
        .then((grupo3) => {
            this.setState({ grupo3: grupo3 })
        })
    
    }

    render(){

        const { grupo3 } = this.state;       
        const { datoEva } = this.state;        
        
        if(grupo3['hydra:member']){
            let respuestasindaños=datoEva.calificacionG3;
            let response3 = [];
            if(datoEva.svGrupo){
                grupo3['hydra:member'].map((dataGrupo) => {
                    datoEva.svGrupo.map( responseSV => {
                        if(dataGrupo.pregunta === responseSV["preguntas_"].pregunta && responseSV["preguntas_"].elemento_indicador==="/elemento_indicadors/3"){
                            if(dataGrupo.pregunta==="Sin Daños" && (!responseSV.calificacion || responseSV.calificacion==="" || responseSV.calificacion===null)){
                            response3.push(respuestasindaños)
                            }else{
                            response3.push(responseSV.calificacion)
                            }
                        }
                    })
                })
            }

            return(
                <React.Fragment>                                       
                    {
                        grupo3['hydra:member'].map((data, i) => (
                            <React.Fragment key={i}>                                
                                <Typography
                                    variant="strong"                                
                                    color="inherit"
                                    style={{width:'50%', display: 'inline-block', marginTop: '5px'}}                                    
                                >
                                    {data.pregunta}
                                </Typography>
                                <Typography
                                    variant="strong"                                
                                    color="inherit"
                                    style={{width:'50%', display: 'inline-block', marginTop: '5px'}}                                    
                                >
                                    {response3[i]}
                                </Typography>
                            </React.Fragment>
                        ))

                    }    
                    <hr />
                    <div style={{display: 'none', justifyContent: 'flex-end', marginTop: '-30px'}}>
                        <strong style={{display: 'flex', alignItems: 'flex-end', marginRight: '10px', marginBottom: '15px'}}>Condición: </strong><DisabledInput source="condicion_gp3" label=" " defaultValue="Cumple"/>
                    </div>              
                    <DisabledInput source="gp3_length" label=" " defaultValue={grupo3['hydra:member'].length} style={{display: 'none'}}/>  
                </React.Fragment>
            )          
                     
        }else{                      
            return( 
                <div className="Reload2">
                    <img src={loader} />
                </div> 
            )
        }
            
    }    
}

export default Grupo3Show;