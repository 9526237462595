import React, { useState } from 'react';
import {
  TabbedForm,
  TextField, DateField,
  FormTab,
  ArrayField,
  Query,
  Error,
  ReferenceField,
  NumberInput,
  Datagrid,
  NumberField
} from 'react-admin';
import { Show } from './../../Show';
import { BulkDeleteWithConfirmButton } from 'react-admin';
import { Fragment } from 'react';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import Typography from '@material-ui/core/Typography';
import { styles } from './../../../EstilosGrid';
import { withStyles } from '@material-ui/core';
// import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { translate } from 'react-admin';
const PostBulkActionButtons = props => (
  <Fragment>
    <BulkDeleteWithConfirmButton {...props} />
  </Fragment>
);




export const ModeloCostosPersShow = translate(withStyles(styles)(({ classes, record, ...props }) => {
  const { translate } = props;

  const ModeloCostos = ({ record }) => (
    <Query type='GET_ONE' resource='modelo_costos_pers' payload={{ id: record.id }}>
      {({ data, loading, error }) => {
        // if (loading) { return <Loading />; }
        if (error) { return <Error />; }
        if (data !== null) {


          return (
            <div>
              <Table style={{ width: '95%' }} formClassName={classes.grid_cont6}>
                <TableHead  >
                  <TableRow >
                    <TableCell style={{ fontWeight: ' bolder', color: 'black', fontSize: '1.1em' }} >
                      {translate('dynatest.resources.modelo_costos_pers.tableVariable.title')}
                    </TableCell>
                    <TableCell style={{ fontWeight: ' bolder', color: 'black', fontSize: '1.1em' }}>
                      {translate('dynatest.resources.modelo_costos_pers.tableVariable.nameColumns.Flexible')}
                    </TableCell>
                    <TableCell style={{ fontWeight: ' bolder', color: 'black', fontSize: '1.1em' }}>
                      {translate('dynatest.resources.modelo_costos_pers.tableVariable.nameColumns.Rigido')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      a
                        </TableCell>
                    <TableCell style={{ textAlign: 'right' }}>{data.variableAFlex}</TableCell>
                    <TableCell style={{ textAlign: 'right' }}>{data.variableARigd}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      b
                        </TableCell>
                    <TableCell style={{ textAlign: 'right' }}>{data.variableBFlex}</TableCell>
                    <TableCell style={{ textAlign: 'right' }}>{data.variableBRigd}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      x
                        </TableCell>
                    <TableCell style={{ textAlign: 'right' }}>{data.variableXFlex}</TableCell>
                    <TableCell style={{ textAlign: 'right' }}>{data.variableXRigd}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      y
                        </TableCell>
                    <TableCell style={{ textAlign: 'right' }}>{data.variableYFlex}</TableCell>
                    <TableCell style={{ textAlign: 'right' }}>{data.variableYRigd}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          )
        }
        else return <div></div>
      }}
    </Query>
  );

  const ModeloCostosGrafica = ({ record }) => (
    <Query type='GET_ONE' resource='modelo_costos_pers' payload={{ id: record.id }}>
      {({ data, loading, error }) => {
        let valoresFlex = [];
        let valores = [];
        // if (loading) { return <Loading />; }
        if (error) { return <Error />; }
        if (data !== null) {
          let arrayDatosFlex = [];
          let arrayDatosRig = [];


          data.calculo.map((item, i) => {
            let valor = item.costoReparaciones;
            let valorFlex = item.costoReparacionesFlex;
            if (item.costoReparaciones !== null) {
              arrayDatosRig.push(valor);
            } else if (i === 10) {
              arrayDatosRig.push(0);
            }
          })

          if (arrayDatosRig !== undefined) {
            let arrayOrganizado = arrayDatosRig.sort(function (a, b) { return b - a });
            valores = arrayOrganizado;
          }
          // let valoresFlex = data.calculoFlex
          data.calculoFlex.map((item, i) => {
            // let valor = item.costoReparaciones;
            let valorFlex = item.costoReparacionesFlex;
            if (item.costoReparaciones !== null) {
              arrayDatosFlex.push(valorFlex);
            } else if (i === 10) {
              arrayDatosFlex.push(0);
            }
          })
          if (arrayDatosFlex !== undefined) {
            let arrayOrganizado = arrayDatosFlex.sort(function (a, b) { return b - a });
            valoresFlex = arrayOrganizado;
          }

          const options = {
            title: {
              text: translate('dynatest.resources.modelo_costos_pers.grafica.titleGraph')
            },

            yAxis: {
              title: {
                text: translate('dynatest.resources.modelo_costos_pers.grafica.axisY')
              }
            },

            xAxis: {
              title: {
                text: translate('dynatest.resources.modelo_costos_pers.grafica.axisX')
              },
              accessibility: {
                rangeDescription: 'Range: 10 to 100'
              },
              categories: ['0', '10', '20', '30', '40', '50', '60', '70', '80', '90', '100']

            },

            legend: {
              layout: 'vertical',
              align: 'right',
              verticalAlign: 'middle'
            },

            plotOptions: {
              series: {
                label: {
                  connectorAllowed: false
                },
                pointStart: 0
              }
            },

            series: [{
              name: translate('dynatest.resources.modelo_costos_pers.grafica.serieFlexible'),
              data: valoresFlex
            },
            {
              name: translate('dynatest.resources.modelo_costos_pers.grafica.serieRigido'),
              data: valores
            }
            ]
          };

          return (
            <div>
              <HighchartsReact highcharts={Highcharts} options={options} />
            </div>
          )
        }
        else return <div></div>
      }}
    </Query>
  );
  return (
    <Show  {...props} title='dynatest.resources.modelo_costos_pers.title.showTitle' bulkActionButtons={<PostBulkActionButtons />}>
      <TabbedForm toolbar={false}>
        <FormTab label="dynatest.resources.modelo_costos_pers.tabs.identificacion">
          <ReferenceField label="resources.modelo_costos_pers.fields.unidadFuncional" source="unidadFuncional" reference="unidad_funcionals" linkType={false} fullWidth formClassName={classes.grid_cont12s}>
            <TextField source="fullCodigos" />
          </ReferenceField>
          <ModeloCostos fullWidth formClassName={classes.grid_cont12s} />

          <Typography fullWidth formClassName={classes.grid_cont6s} style={{ textAlign: 'center' }} variant="h4" component="h4">
            {translate('dynatest.resources.modelo_costos_pers.tableFlexible.titleTable')}
                </Typography>

          <Typography fullWidth formClassName={classes.grid_cont6} style={{ textAlign: 'center' }} variant="h4" component="h4">
            {translate('dynatest.resources.modelo_costos_pers.tableRigido.titleTable')}
                </Typography>
          <ArrayField label='' source='calculoFlex'
            fullWidth
            formClassName={classes.grid_cont6s}

          >
            <Datagrid>
              <NumberField label='dynatest.resources.modelo_costos_pers.tableFlexible.nameColumns.PCI' source='pci' />
              <NumberField label='dynatest.resources.modelo_costos_pers.tableFlexible.nameColumns.CostoBase' source='costoRepFlex' />
              <NumberField label='dynatest.resources.modelo_costos_pers.tableFlexible.nameColumns.CostoConsidRestr' source='costoReparacionesFlex' />
            </Datagrid>
          </ArrayField>
          <ArrayField label='' source='calculo'
            fullWidth
            formClassName={classes.grid_cont6}

          >
            <Datagrid>
              <NumberField label='dynatest.resources.modelo_costos_pers.tableRigido.nameColumns.PCI' source='pci' />
              <NumberField label='dynatest.resources.modelo_costos_pers.tableRigido.nameColumns.CostoBase' source='costoRep' />
              <NumberField label='dynatest.resources.modelo_costos_pers.tableRigido.nameColumns.CostoConsidRestr' source='costoReparaciones' />
            </Datagrid>
          </ArrayField>
          <ModeloCostosGrafica />
        </FormTab>
        <FormTab label="dynatest.resources.modelo_costos_pers.tabs.auditoria">
          <DateField source="createdAt" label="resources.modelo_costos_pers.fields.createdAt" formClassName={classes.grid_cont6s} showTime/>
          <TextField source="createdBy" label="resources.modelo_costos_pers.fields.createdBy" formClassName={classes.grid_cont6s} />
          <div></div>
          <DateField source="updatedAt" label="resources.modelo_costos_pers.fields.updatedAt" formClassName={classes.grid_cont6s} showTime/>
          <TextField source="updatedBy" label="resources.modelo_costos_pers.fields.updatedBy" formClassName={classes.grid_cont6s} />
          <div></div>
        </FormTab>
      </TabbedForm>
    </Show>
  )
}));