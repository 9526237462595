import React, { useState } from 'react';
import { List, Datagrid,Toolbar,SelectInput,BooleanInput, ReferenceInput, TextField,SimpleForm, EditButton, CardActions,RadioButtonGroupInput,ShowButton} from 'react-admin';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import config from '../../../../config/config';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { translate } from 'react-admin';
let urlAppjs = config.UrlServer;
class ViewHistorico extends React.Component{


    constructor(props){
        super(props)
        this.state = {mensualview: [],record: [],tipo: "",value: '',titulos:[],ano:[] };
        // this.array = {
        //     valoresx1 : 0,valoresx2 : 0,valoresx3 : 0,valoresx4 : 0, maximo: 0,minimo: 0,maximo1: 0,minimo1: 0,
        // }
        // this.validatenum = this.validatenum.bind(this);
    }
    componentDidMount(){
      this.setState({ tipo: this.props.tipo })
      this.setState({ record: this.props.record })
      
        // fetch(urlAppjs+"/mensualviews.json?inventio_peajes="+this.props.peaje+"&sentido="+this.props.sentido+"&ano="+this.props.ano)
        let idproyecto = localStorage.getItem("id_project2").replace("/proyectos/", "");
        let endpoint1 = "";
        let sentido   = "";
        let valor2    = "";
        if(this.props.tipo === "tipo"){
          let valor = this.props.peaje;
          sentido   = this.props.sentido;
          valor2 = valor.split("/inventariopeajes/"); 
         endpoint1 = "/tablahistorico/"+this.props.ano+"/"+valor2[1]+"/"+sentido+"/"+idproyecto;
        }
        else {
          let valor = this.props.record.inventario_peajes;
          // console.log(this.props.record.inventario_peajes);
          valor2 = valor.split("/inventariopeajes/");
          sentido = this.props.record.sentido;
         endpoint1 = "/tablahistorico/"+this.props.record.ano+"/"+valor2[1]+"/"+sentido+"/"+idproyecto;
        }

      let endpoint2 = "/tablayears/"+this.props.ano1+"/"+this.props.ano2+"/"+idproyecto+"/"+valor2[1]+"/"+sentido;
      let urlCompuesta = this.props.tipo ? urlAppjs + endpoint1 : urlAppjs + endpoint2;
        //console.log(endpoint2);
      fetch(urlCompuesta)
      .then((response) => {
        return response.json()
    })
    .then((mensualview) => {
        this.setState({ mensualview: mensualview.resultado })
    })
  }
    sumaFila(enero, febrero, marzo, abril, mayo, junio, julio, agosto, septiembre, octubre, noviembre, diciembre)
    {
      let suma = 0;
      if(enero != null){
        suma += parseInt(enero);
      }
      if(febrero != null){
        suma += parseInt(febrero);
      }
      if(marzo != null){
        suma += parseInt(marzo);
      }
      if(abril != null){
        suma += parseInt(abril);
      }
      if(mayo != null){
        suma += parseInt(mayo);
      }
      if(junio != null){
        suma += parseInt(junio);
      }
      if(julio != null){
        suma += parseInt(julio);
      }
      if(agosto != null){
        suma += parseInt(agosto);
      }
      if(septiembre != null){
        suma += parseInt(septiembre);
      }
      if(octubre != null){
        suma += parseInt(octubre);
      }
      if(noviembre != null){
        suma += parseInt(noviembre);
      }
      if(diciembre != null){
        suma += parseInt(diciembre);
      }
      return suma
    }
    // leerMeses (data) {
    //   const keysValidation = ['enero','febrero','marzo','abril','mayo','junio','julio','agosto','septiembre','octubre','noviembre','diciembre']
    //   return Object.keys(data).map((key, i) => {
    //   if(keysValidation.includes(key)) {
    //   return <TableCell key={i} align="right" fullWidth style={{textAlign:'right'}}>{data[key]}</TableCell>
    //   }})
    // };

    // sumarMeses (data) {
    //   const keysValidation = ['enero','febrero','marzo','abril','mayo','junio','julio','agosto','septiembre','octubre','noviembre','diciembre']
    //   if (data.length > 0) {
    //   return Object.values(data).reduce((a, b) => {
    //     Object.keys(a).map((key, i) => {
    //     let  suma = 0
    //     if(keysValidation.includes(key)) {
    //       if(a[key] === b[key]) {
    //       suma += parseInt(a[key], 10);
    //     }
    //       return <TableCell align="right" style={{textAlign:'right'}} fullWidth> {suma} </TableCell>
    //     }
    //   })
    // })};
    // }

    render(){
        const { translate } = this.props;
        const {tipo} = this.state;
        const { mensualview } = this.state;
        const { record } = this.state;
        const {titulos} = this.state;
        Array.prototype.unique=function(a){
          return function(){return this.filter(a)}}(function(a,b,c){return c.indexOf(a,b+1)<0
        });
        let aniosRF = [];
        mensualview.map((reporte) => {
          aniosRF.push(reporte.ano)
        })
        
        aniosRF = aniosRF.unique();
        const meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre','Total']     
        const cellMeses = meses.map((mes, i) => <TableCell key={i} align="right" component="th" >{mes} </TableCell>);
    // console.log(record);
    
    let categories = {};
    //console.log(mensualview);
        let titulos2 = [
          translate('resources.mensuals.fields.categoria_a'),
          translate('resources.mensuals.fields.categoria_b'),
          translate('resources.mensuals.fields.categoria_c'),
          translate('resources.mensuals.fields.categoria_d'),
          translate('resources.mensuals.fields.categoria_e')];
        let categoria_a = {enero: 0, febrero: 0, marzo: 0, abril: 0, mayo: 0,junio: 0,julio: 0, agosto: 0,septiembre: 0,
            octubre: 0,
            noviembre: 0,
            diciembre: 0,
        };
        let categoria_b ={enero: 0,
            febrero: 0,
            marzo: 0,
            abril: 0,
            mayo: 0,
            junio: 0,
            julio: 0,
            agosto: 0,
            septiembre: 0,
            octubre: 0,
            noviembre: 0,
            diciembre: 0,
        }
        let categoria_c ={enero: 0,
            febrero: 0,
            marzo: 0,
            abril: 0,
            mayo: 0,
            junio: 0,
            julio: 0,
            agosto: 0,
            septiembre: 0,
            octubre: 0,
            noviembre: 0,
            diciembre: 0,};
        let categoria_d ={enero: 0,
            febrero: 0,
            marzo: 0,
            abril: 0,
            mayo: 0,
            junio: 0,
            julio: 0,
            agosto: 0,
            septiembre: 0,
            octubre: 0,
            noviembre: 0,
            diciembre: 0,};
        let categoria_e ={enero: 0,
            febrero: 0,
            marzo: 0,
            abril: 0,
            mayo: 0,
            junio: 0,
            julio: 0,
            agosto: 0,
            septiembre: 0,
            octubre: 0,
            noviembre: 0,
            diciembre: 0,};
        if (mensualview && typeof mensualview === 'object' && mensualview.length > 0){
        mensualview.map((data,i)=>{
          if(data.categoria === "categoria_a" ){
            if(data.enero){
            categoria_a.enero+=parseInt(data.enero);
            }
            if(data.febrero){
            categoria_a.febrero+=parseInt(data.febrero);
            }
            if(data.marzo){
            categoria_a.marzo+=parseInt(data.marzo);
            } 
            if(data.abril ){   
            categoria_a.abril+=parseInt(data.abril);
            }
            if(data.mayo){
            categoria_a.mayo+=parseInt(data.mayo);
            }
            if(data.junio){
            categoria_a.junio+=parseInt(data.junio);
            }
            if(data.julio){
            categoria_a.julio+=parseInt(data.julio);
            }
            if(data.agosto){
            categoria_a.agosto+=parseInt(data.agosto);
            }
            if(data.septiembre){
            categoria_a.septiembre+=parseInt(data.septiembre);
            }
            if(data.octubre){
            categoria_a.octubre+=parseInt(data.octubre);
            }
            if(data.noviembre){
            categoria_a.noviembre+=parseInt(data.noviembre);
            }
            if(data.diciembre){
            categoria_a.diciembre+=parseInt(data.diciembre);
            }
          }   
         else if(data.categoria === "categoria_b"){
          if(data.enero){
            categoria_b.enero+=parseInt(data.enero);
            }
            if(data.febrero){
            categoria_b.febrero+=parseInt(data.febrero);
          }
            if(data.marzo){
              categoria_b.marzo+=parseInt(data.marzo);
              }
            if(data.abril ){      
            categoria_b.abril+=parseInt(data.abril);
            }
            if(data.mayo){
              categoria_b.mayo+=parseInt(data.mayo);
              }
              if(data.junio){
                categoria_b.junio+=parseInt(data.junio);
                }
             if(data.julio){
            categoria_b.julio+=parseInt(data.julio);
            }
            if(data.agosto){
              categoria_b.agosto+=parseInt(data.agosto);
              }
              if(data.septiembre){
                categoria_b.septiembre+=parseInt(data.septiembre);
                }
                if(data.octubre){
                  categoria_b.octubre+=parseInt(data.octubre);
                  }
                  if(data.noviembre){
                    categoria_b.noviembre+=parseInt(data.noviembre);
                    }
                    if(data.diciembre){
                    categoria_b.diciembre+=parseInt(data.diciembre);
                    }
          } 
         else if(data.categoria === "categoria_c"){
          if(data.enero){
            categoria_c.enero+=parseInt(data.enero);
            }
            if(data.febrero){
              categoria_c.febrero+=parseInt(data.febrero);
              }
              if(data.marzo){
                categoria_c.marzo+=parseInt(data.marzo);
                }
            if(data.abril){   
            categoria_c.abril+=parseInt(data.abril)
            }
            if(data.mayo){
              categoria_c.mayo+=parseInt(data.mayo);
              }
              if(data.junio){
                categoria_c.junio+=parseInt(data.junio);
                }
            if(data.julio){
                  categoria_c.julio+=parseInt(data.julio);
                  }
            if(data.agosto){
               categoria_c.agosto+=parseInt(data.agosto);
                    }
                    if(data.septiembre){
                      categoria_c.septiembre+=parseInt(data.septiembre);
                      }
                      if(data.octubre){
                        categoria_c.octubre+=parseInt(data.octubre);
                        }
                        if(data.noviembre){
                          categoria_c.noviembre+=parseInt(data.noviembre);
                          }
                          if(data.diciembre){
                          categoria_c.diciembre+=parseInt(data.diciembre);
                          }
          }   
         else if(data.categoria === "categoria_d"){
          if(data.enero){
            categoria_d.enero+=parseInt(data.enero);
            }
            if(data.febrero){
              categoria_d.febrero+=parseInt(data.febrero);
              }
              if(data.marzo){
                categoria_d.marzo+=parseInt(data.marzo);
                }
            if(data.abril){    
            categoria_d.abril+=parseInt(data.abril)
            }
            if(data.mayo){
              categoria_d.mayo+=parseInt(data.mayo);
              }
            if(data.junio){
                categoria_d.junio+=parseInt(data.junio);
                }
            if(data.julio){
                  categoria_d.julio+=parseInt(data.julio);
                  }
            if(data.agosto){
                 categoria_d.agosto+=parseInt(data.agosto);
                    }
                    if(data.septiembre){
                      categoria_d.septiembre+=parseInt(data.septiembre);
                      }
                      if(data.octubre){
                        categoria_d.octubre+=parseInt(data.octubre);
                        }
                        if(data.noviembre){
                          categoria_d.noviembre+=parseInt(data.noviembre);
                          }
                          if(data.diciembre){
                          categoria_d.diciembre+=parseInt(data.diciembre);
                          }
          }   
         else if(data.categoria === "categoria_e"){
          if(data.enero){
            categoria_e.enero+=parseInt(data.enero);
            }
            if(data.febrero){
              categoria_e.febrero+=parseInt(data.febrero);
              }
              if(data.marzo){
                categoria_e.marzo+=parseInt(data.marzo);
                }
            if(data.abril){    
            categoria_e.abril+=parseInt(data.abril)
            }
            if(data.mayo){
              categoria_e.mayo+=parseInt(data.mayo);
              }
              if(data.junio){
                categoria_e.junio+=parseInt(data.junio);
                }
             if(data.julio){
                categoria_e.julio+=parseInt(data.julio);
                  }
              if(data.agosto){
                 categoria_e.agosto+=parseInt(data.agosto);
                    }
              if(data.septiembre){
                      categoria_e.septiembre+=parseInt(data.septiembre);
                      }
              if(data.octubre){
                      categoria_e.octubre+=parseInt(data.octubre);
                        }
                        if(data.noviembre){
                          categoria_e.noviembre+=parseInt(data.noviembre);
                          }
                          if(data.diciembre){
                          categoria_e.diciembre+=parseInt(data.diciembre);
                          }
          }   

        })
        
     }
  
    //  let enero={ categoria_a: 0}
    //  let febrero={ categoria_a: 0}
    //  let marzo={ categoria_a: 0}
    //  let abril={ categoria_a: 0}
    //  let mayo={ categoria_a: 0}
    //  let junio={ categoria_a: 0}
    //  let julio={ categoria_a: 0}
    //  let agosto={ categoria_a: 0}
    //  let septiembre={ categoria_a: 0}
    //  let octubre={ categoria_a: 0}
    //  let noviembre={ categoria_a: 0}
    //  let diciembre={ categoria_a: 0}
    //  if (mensualview && typeof mensualview === 'object' && mensualview.length > 0){
    //   mensualview.map((data,i)=>{
    //     if(data.categoria === "categoria_a"){
    //     if(data.categoria){
    //       categoria_a.enero+=parseInt(data.enero);
    //       }
    //       if(data.febrero){
    //         categoria_a.febrero+=parseInt(data.febrero);
    //         }
    //       if(data.marzo){
    //           categoria_a.marzo+=parseInt(data.marzo);
    //           }
    //       if(data.abril){
    //             data.abril+=parseInt(data.categoria_a);
    //             console.log(abril)
    //             }
    //       if(data.mayo){
    //               categoria_a.mayo+=parseInt(data.mayo);
    //               }
    //        if(data.junio){
    //                 categoria_a.junio+=parseInt(data.junio);
    //                 }
    //       if(data.julio){
    //         categoria_a.julio+=parseInt(data.julio);
    //         }
    //     if(data.agosto){
    //       categoria_a.agosto+=parseInt(data.agosto);
    //       }
    //       if(data.septiembre){
    //         categoria_a.septiembre+=parseInt(data.septiembre);
    //         }
    //         if(data.octubre){
    //           categoria_a.octubre+=parseInt(data.octubre);
    //           }
    //           if(data.noviembre){
    //             categoria_a.noviembre+=parseInt(data.noviembre);
    //             } 
    //             if(data.diciembre){
    //               categoria_a.diciembre+=parseInt(data.diciembre);
    //               }    
    //     }
    //    } )
    
    //  }
     let categorias = [categoria_a,categoria_b,categoria_c,categoria_d,categoria_e];
     let sumaEnero = 0;
     let sumaFebrero = 0;
     let sumaMarzo = 0;
     let sumaAbril = 0;
     let sumaMayo = 0;
     let sumaJunio = 0;
     let sumaJulio = 0;
     let sumaAgosto = 0;
     let sumaSeptiembre = 0;
     let sumaOctubre = 0;
     let sumaNoviembre = 0;
     let sumaDiciembre = 0;
    //  let SumaCategoriaA = 0;
    categorias.map((data,res)=>{
      sumaEnero += data.enero;
      sumaFebrero += data.febrero;
      sumaMarzo += data.marzo;
      sumaAbril += data.abril;
      sumaMayo += data.mayo;
      sumaJunio += data.junio;
      sumaJulio += data.julio;
      sumaAgosto += data.agosto;
      sumaSeptiembre += data.septiembre;
      sumaOctubre += data.octubre;
      sumaNoviembre += data.noviembre;
      sumaDiciembre += data.diciembre;
          })
          // const newArray = [
          //   {
          //     0 : 0,
          //     1: 0
          //   },
          //   {
          //     0 : 0,
          //     1: 0
          //   },
          //   {
          //     0 : 0,
          //     1: 0
          //   },
          //   {
          //     0 : 0,
          //     1: 0
          //   },
          //   {
          //     0 : 0,
          //     1: 0
          //   },
          //   {
          //     0 : 0,
          //     1: 0
          //   },
          //   {
          //     0 : 0,
          //     1: 0
          //   },
          //   {
          //     0 : 0,
          //     1: 0
          //   },
          // ]
          // let math = []
          // for(let i; i>mensualview.length; i++){
          //   math.push(Object.values(mensualview[i]))
          // }
        console.log(mensualview)
          //        let math =   Object.keys(mensualview).map((data,i)=>{
          //            return mensualview
          //       })
                
          // console.log(math)
          // if(typeof math === 'array' && math.length > 0){
            // console.log(math)
            
            let enero = []
            let febrero = []
            let marzo = []
            let abril = []
            let mayo = []
            let junio = []
            let julio = []
            let agosto = []
            let septiembre = []
            let octubre = []
            let noviembre = []
            let diciembre = []
            if(mensualview && typeof mensualview === 'object' && mensualview.length > 0 || mensualview !== NaN){
            mensualview.map((data,i)=>{
              enero.push(parseInt(data.enero))
              febrero.push(parseInt(data.febrero))
              marzo.push(parseInt(data.marzo))
              abril.push(parseInt(data.abril))
              mayo.push(parseInt(data.mayo))
              junio.push(parseInt(data.junio))
              julio.push(parseInt(data.julio))
              agosto.push(parseInt(data.agosto))
              septiembre.push(parseInt(data.septiembre))
              octubre.push(parseInt(data.octubre))
              noviembre.push(parseInt(data.noviembre))
              diciembre.push(parseInt(data.diciembre))
            })
          }
          //   let enero = []
          //   let febrero = []
          //   let marzo = []
          //   let abril = []
          //   let mayo = []
          //   let junio = []
          //   let julio = []
          //   let agosto = []
          //   let septiembre = []
          //   let octubre = []
          //   let noviembre = []
          //   let diciembre = []
          //   let ano = []
          // math.map((data,i)=>{
            // if(i === 0 ){
            //   newArray[0][0] = parseInt(data.enero)
            //   newArray[1][0] = parseInt(data.febrero)
            //   newArray[2][0] = parseInt(data.marzo)
            //   newArray[3][0] = parseInt(data.cgvolumen)
            //   newArray[4][0] = parseInt(data.c3volumen)
            //   newArray[5][0] = parseInt(data.c5volumen)
            //   newArray[6][0] = parseInt(data.mayorc5volumen)
            // enero.push(parseInt(data.enero))
            // febrero.push(parseInt(data.febrero))
            // marzo.push(parseInt(data.marzo))
            // abril.push(parseInt(data.abril))
            // mayo.push(parseInt(data.mayo))
            // junio.push(parseInt(data.junio))
            // julio.push(parseInt(data.julio))
            // agosto.push(parseInt(data.agosto))
            // septiembre.push(parseInt(data.septiembre))
            // octubre.push(parseInt(data.octubre))
            // noviembre.push(parseInt(data.noviembre))
            // diciembre.push(parseInt(data.diciembre))
            // miArray2.push({
            //   0:data.aut1,
            //   1:data.bus1,
            //   2:data.c1volumen,
            //   3:data.cgvolumen,
            //   4:data.c3volumen,
            //   5:data.c5volumen,
            //   6:data.mayorc5volumen,
            //   7: sumTotal
            // })
            // }
          // })
        // }
        // let enero2 = []
        // let febrero2 = []
        // let marzo2 = []
        // let abril2 = []
        // let mayo2 = []
        // let junio2 = []
        // let julio2 = []
        // let agosto2 = []
        // let septiembre2 = []
        // let octubre2 = []
        // let noviembre2 = []
        // let diciembre2 = []
        // math2.map((data,i)=>{
        //   enero2.push(parseInt(data.enero))
        //   febrero2.push(parseInt(data.febrero))
        //   marzo2.push(parseInt(data.marzo))
        //   abril2.push(parseInt(data.abril))
        //   mayo2.push(parseInt(data.mayo))
        //   junio2.push(parseInt(data.junio))
        //   julio2.push(parseInt(data.julio))
        //   agosto2.push(parseInt(data.agosto))
        //   septiembre2.push(parseInt(data.septiembre))
        //   octubre2.push(parseInt(data.octubre))
        //   noviembre2.push(parseInt(data.noviembre))
        //   diciembre2.push(parseInt(data.diciembre))
        // }) 
        // let enero3 = []
        // let febrero3 = []
        // let marzo3 = []
        // let abril3 = []
        // let mayo3 = []
        // let junio3 = []
        // let julio3 = []
        // let agosto3 = []
        // let septiembre3 = []
        // let octubre3 = []
        // let noviembre3 = []
        // let diciembre3 = []
        // math3.map((data,i)=>{
        //   enero3.push(parseInt(data.enero))
        //   febrero3.push(parseInt(data.febrero))
        //   marzo3.push(parseInt(data.marzo))
        //   abril3.push(parseInt(data.abril))
        //   mayo3.push(parseInt(data.mayo))
        //   junio3.push(parseInt(data.junio))
        //   julio3.push(parseInt(data.julio))
        //   agosto3.push(parseInt(data.agosto))
        //   septiembre3.push(parseInt(data.septiembre))
        //   octubre3.push(parseInt(data.octubre))
        //   noviembre3.push(parseInt(data.noviembre))
        //   diciembre3.push(parseInt(data.diciembre))
        // }) 
            let ano=[]
        mensualview.map((data,i) =>{
           ano.push(data.ano)
        })
        //console.log(ano)
          console.log(enero,febrero,marzo,abril,mayo,junio,julio,agosto,septiembre,octubre,noviembre,diciembre)
          let transito=[]
          for (let i=0;i<ano.length;i++){
          let dataTemporal = [] 
          if(enero[i]){dataTemporal.push(enero[i])}else {dataTemporal.push(0)}
          if(febrero[i]){dataTemporal.push(febrero[i])}else {dataTemporal.push(0)}
          if(marzo[i]){dataTemporal.push(marzo[i])}else {dataTemporal.push(0)}
          if(abril[i]){dataTemporal.push(abril[i])}else {dataTemporal.push(0)}
          if(mayo[i]){dataTemporal.push(mayo[i])}else {dataTemporal.push(0)}
          if(junio[i]){dataTemporal.push(junio[i])}else {dataTemporal.push(0)}
          if(julio[i]){dataTemporal.push(julio[i])}else {dataTemporal.push(0)}
          if(agosto[i]){dataTemporal.push(agosto[i])}else {dataTemporal.push(0)}
          if(septiembre[i]){dataTemporal.push(septiembre[i])}else {dataTemporal.push(0)}
          if(octubre[i]){dataTemporal.push(octubre[i])}else {dataTemporal.push(0)}
          if(noviembre[i]){dataTemporal.push(noviembre[i])}else {dataTemporal.push(0)}
          if(diciembre[i]){dataTemporal.push(diciembre[i])}else {dataTemporal.push(0)}
          let opcion = {  
          name:translate('resources.mensuals.fields.Transito')+" "+ano[i],
          data: dataTemporal
          }
          transito.push(opcion)
        }
        console.log(transito)
        console.log(abril)
          const options = {
     
            title: {
              text: translate('dynatest.resources.mensuals.graph.title')
          },
        
          subtitle: {
              text: translate('dynatest.resources.mensuals.graph.subTitle')
          },
        
          yAxis: {
              title: {
                  text: translate('dynatest.resources.mensuals.graph.Valores')
              }
          },
          xAxis:{
            categories:[
              translate('dynatest.resources.mensuals.select.mes.Enero'), 
              translate('dynatest.resources.mensuals.select.mes.Febrero'), 
              translate('dynatest.resources.mensuals.select.mes.Marzo'), 
              translate('dynatest.resources.mensuals.select.mes.Abril'), 
              translate('dynatest.resources.mensuals.select.mes.Mayo'), 
              translate('dynatest.resources.mensuals.select.mes.Junio'), 
              translate('dynatest.resources.mensuals.select.mes.Julio'), 
              translate('dynatest.resources.mensuals.select.mes.Agosto'), 
              translate('dynatest.resources.mensuals.select.mes.Septiembre'), 
              translate('dynatest.resources.mensuals.select.mes.Octubre'), 
              translate('dynatest.resources.mensuals.select.mes.Noviembre'), 
              translate('dynatest.resources.mensuals.select.mes.Diciembre')]
          },
          series:transito
           
        }
  //   let mes = [enero, febrero, marzo, abril, mayo, junio, julio, agosto, septiembre, octubre, noviembre, diciembre];
  //   if(mes && abril &&abril[1]&& abril[1]){
  //   mes.map((data,i)=>{
  //     SumaCategoria +=abril +=enero;
      
  //   })
  // }
  // let suma = categorias.querySelectorAll(enero, febrero, marzo, abril, mayo, junio, julio, agosto, septiembre, octubre, noviembre, diciembre)
  //                  .reduce((abril,enero,mayo,diciembre) => enero + abril+mayo+diciembre)
    
 
    
        // const total=()=>{
        //     console.log('cualquier cosa')
        // let  a = [];
        // let b= [];
        // let  c = [];
        // let d= [];
        // let  e = [];
        // let total=[]
        // let categoria_a=document.getElementById(categoria_a)
        // let categoria_b=document.getElementById(categoria_b)
        // let categoria_c=document.getElementById(categoria_c)
        // let categoria_d=document.getElementById(categoria_d)
        // let categoria_e=document.getElementById(categoria_e)
        // if(categoria_a && categoria_b && categoria_c && categoria_d && categoria_e && categoria_a.value && categoria_b.value  && categoria_c.value && categoria_d.value && categoria_e.value){
        //     a.push(categoria_a.value)
        //     b.push(categoria_b.value)
        //     c.push(categoria_c.value)
        //     d.push(categoria_d.value)
        //     e.push(categoria_e.value)
        // }
        
        // if(a && b && c && d && e ){
        //     let t=0;
        //     let j=0;
        //     for(let i=0; i<5; i++){
        //         //$x=($a2-$a1)/($b1-$b2);
        //         //$y=($b1*$x)+$a1;
        //     let j=i-1; //j=1 i=2
        //         if(j<0){
        //             j=4;
        //         }
        //         t = (a[i] + b[i] + c[i] +d[i] + e[i]);

        //         total[i] = 0;
        //     }
        // }
        //         }
                
    // console.log(mensualview);
    // let categoriasF = {};
    // const categoriasU = mensualview.filter((mensual) => {
    //   switch(mensual.categoria) {
    //     case 'categoria_a':
    //       mensual.categoria = 'Cat.I';
    //       break;
    //     case 'categoria_b':
    //       mensual.categoria = 'Cat.II';
    //       break;
    //     case 'categoria_c':
    //       mensual.categoria = 'Cat.III';
    //       break;
    //     case 'categoria_d':
    //       mensual.categoria = 'Cat.IV';
    //       break;
    //     case 'categoria_e':
    //       mensual.categoria = 'Cat.V';
    //       break;
    //   }
    //   return categoriasF[mensual.categoria] ? false : (categoriasF[mensual.categoria] = true);
    // });
    if(tipo==='tipo'){
    return(
        <React.Fragment>

<Table aria-label="simple table" style={{width: "50%"}}>

                  <TableHead>
                    <TableRow style={{color:'#fff'}} >
                    {aniosRF.map(anio => (
                    <TableCell key={anio} align="right" component="th">
                  <h3 style={{marginBottom: 0}}>{anio}</h3><br/>
                </TableCell>
                      
                    ))}
                        <TableCell align="right" component="th" style={{padding: "4px 5px 4px 24px"}}>{translate('dynatest.resources.mensuals.select.mes.Enero')}</TableCell>
                        <TableCell align="right" component="th" style={{padding: "4px 5px 4px 24px"}}>{translate('dynatest.resources.mensuals.select.mes.Febrero')}</TableCell>
                        <TableCell align="right" component="th" style={{padding: "4px 5px 4px 24px"}}>{translate('dynatest.resources.mensuals.select.mes.Marzo')}</TableCell>
                        <TableCell align="right" component="th" style={{padding: "4px 5px 4px 24px"}}>{translate('dynatest.resources.mensuals.select.mes.Abril')}</TableCell>
                        <TableCell align="right" component="th" style={{padding: "4px 5px 4px 24px"}}>{translate('dynatest.resources.mensuals.select.mes.Mayo')}</TableCell>
                        <TableCell align="right" component="th" style={{padding: "4px 5px 4px 24px"}}>{translate('dynatest.resources.mensuals.select.mes.Junio')}</TableCell>
                        <TableCell align="right" component="th" style={{padding: "4px 5px 4px 24px"}}>{translate('dynatest.resources.mensuals.select.mes.Julio')}</TableCell>
                        <TableCell align="right" component="th" style={{padding: "4px 5px 4px 24px"}}>{translate('dynatest.resources.mensuals.select.mes.Agosto')}</TableCell>
                        <TableCell align="right" component="th" style={{padding: "4px 5px 4px 24px"}}>{translate('dynatest.resources.mensuals.select.mes.Septiembre')}</TableCell>
                        <TableCell align="right" component="th" style={{padding: "4px 5px 4px 24px"}}>{translate('dynatest.resources.mensuals.select.mes.Octubre')}</TableCell>
                        <TableCell align="right" component="th" style={{padding: "4px 5px 4px 24px"}}>{translate('dynatest.resources.mensuals.select.mes.Noviembre')}</TableCell>
                        <TableCell align="right" component="th" style={{padding: "4px 5px 4px 24px"}}>{translate('dynatest.resources.mensuals.select.mes.Diciembre')}</TableCell>
                        <TableCell align="right" component="th" style={{padding: "4px 5px 4px 24px"}}>{translate('dynatest.resources.mensuals.subTitle.total')}  </TableCell>
                        <br></br>   
                        </TableRow>
                        </TableHead>       
                {/* <TableHead>
              <TableRow style={{color:'#fff'}} >
                  <TableCell></TableCell>
                { cellMeses }
                  <br></br>
              </TableRow>
            </TableHead> */}
            <TableBody>
            {  titulos2.map((data, i) => (
                        <TableRow > 
                       <TableCell align="right" component="th" fullWidth>
                             <h3 style={{marginBottom: 0}}>{titulos2[i]}</h3><br/>    
                        </TableCell>
                        {/* {this.leerMeses(data)} */}
                        <TableCell align="right"  style={{textAlign:'right', padding: "4px 5px 4px 24px"}}> <span> {categorias[i].enero ? categorias[i].enero : " "} </span></TableCell>
                         <TableCell align="right"  style={{textAlign:'right', padding: "4px 5px 4px 24px"}}> <span> {categorias[i].febrero ? categorias[i].febrero : " "} </span></TableCell>  
                       <TableCell align="right"  style={{textAlign:'right', padding: "4px 5px 4px 24px"}}> <span >{categorias[i].marzo ? categorias[i].marzo : " " }</span> </TableCell>  
                       <TableCell align="right"  style={{textAlign:'right', padding: "4px 5px 4px 24px"}}> <span >{categorias[i].abril ? categorias[i].abril : " "}</span></TableCell>  
                        <TableCell align="right"  style={{textAlign:'right', padding: "4px 5px 4px 24px"}}> <span >{categorias[i].mayo ? categorias[i].mayo : " "}</span></TableCell> 
                        <TableCell align="right"  style={{textAlign:'right', padding: "4px 5px 4px 24px"}}> <span >{categorias[i].junio ? categorias[i].junio : " "}</span></TableCell> 
                        <TableCell align="right"  style={{textAlign:'right', padding: "4px 5px 4px 24px"}}> <span >{categorias[i].julio ? categorias[i].julio : " "}</span></TableCell> 
                        <TableCell align="right"  style={{textAlign:'right', padding: "4px 5px 4px 24px"}}> <span >{categorias[i].agosto ? categorias[i].agosto : " "}</span></TableCell>              
                       <TableCell align="right"  style={{textAlign:'right', padding: "4px 5px 4px 24px"}}> <span >{categorias[i].septiembre ? categorias[i].septiembre : " "}</span></TableCell> 
                       <TableCell align="right"  style={{textAlign:'right', padding: "4px 5px 4px 24px"}}> <span >{categorias[i].octubre ? categorias[i].octubre : " "}</span></TableCell> 
                       <TableCell align="right"  style={{textAlign:'right', padding: "4px 5px 4px 24px"}}> <span >{categorias[i].noviembre ? categorias[i].noviembre : " "}</span></TableCell> 
                        <TableCell align="right"  style={{textAlign:'right', padding: "4px 5px 4px 24px"}}> <span >{categorias[i].diciembre ? categorias[i].diciembre : " "}</span></TableCell>
                        <TableCell align="right"  style={{textAlign:'right', padding: "4px 5px 4px 24px"}}> <span >{categorias[i].enero +categorias[i].febrero +categorias[i].marzo+categorias[i].abril + categorias[i].mayo +categorias[i].junio+categorias[i].julio+categorias[i].agosto+categorias[i].septiembre+categorias[i].octubre+categorias[i].noviembre+categorias[i].diciembre}</span></TableCell> 
                 
                    {/* <TableCell align="right" > <span ></span></TableCell>  */}

                    </TableRow>
                    )
                    )} 
                    <TableRow>
                    <TableCell align="right" component="th" fullWidth>
                        <h3 style={{marginBottom: 0}}>{translate('dynatest.resources.mensuals.subTitle.total')}</h3><br/>    
                          </TableCell>
                        <TableCell align="right" style={{textAlign:'right', padding: "4px 5px 4px 24px"}} >   {sumaEnero ? sumaEnero: ""}</TableCell>
                        <TableCell align="right" style={{textAlign:'right', padding: "4px 5px 4px 24px"}} > {sumaFebrero ? sumaFebrero: ""} </TableCell>
                        <TableCell align="right" style={{textAlign:'right', padding: "4px 5px 4px 24px"}} >{sumaMarzo ? sumaMarzo: ""} </TableCell>
                        <TableCell align="right" style={{textAlign:'right', padding: "4px 5px 4px 24px"}} >{sumaAbril ? sumaAbril: ""}</TableCell>
                        <TableCell align="right" style={{textAlign:'right', padding: "4px 5px 4px 24px"}} > {sumaMayo ? sumaMayo: ""} </TableCell>
                        <TableCell align="right" style={{textAlign:'right', padding: "4px 5px 4px 24px"}} > {sumaJunio ? sumaJunio: ""} </TableCell>
                        <TableCell align="right" style={{textAlign:'right', padding: "4px 5px 4px 24px"}} > {sumaJulio ? sumaJulio: ""} </TableCell>
                        <TableCell align="right" style={{textAlign:'right', padding: "4px 5px 4px 24px"}} >{sumaAgosto ? sumaAgosto: ""}</TableCell>
                        <TableCell align="right" style={{textAlign:'right', padding: "4px 5px 4px 24px"}} > {sumaSeptiembre ? sumaSeptiembre: ""}</TableCell>
                        <TableCell align="right" style={{textAlign:'right', padding: "4px 5px 4px 24px"}} >{sumaOctubre ? sumaOctubre: ""}</TableCell>
                        <TableCell align="right" style={{textAlign:'right', padding: "4px 5px 4px 24px"}} >{sumaNoviembre ? sumaNoviembre: ""}</TableCell>
                        <TableCell align="right" style={{textAlign:'right', padding: "4px 5px 4px 24px"}} > {sumaDiciembre ? sumaDiciembre: ""} </TableCell>
                        <TableCell align="right" style={{textAlign:'right', padding: "4px 5px 4px 24px"}} > {sumaEnero+sumaFebrero+sumaMarzo+sumaAbril+sumaMayo+sumaJunio+sumaJulio+sumaAgosto+sumaSeptiembre+sumaOctubre+sumaNoviembre+sumaDiciembre } </TableCell>
                    </TableRow>
                    </TableBody>
                          
                        </Table>
                        
        </React.Fragment>
    )
            }
            else{

              return(
                <React.Fragment>
        
        <Table aria-label="simple table" style={{width: "50%"}}>
                        <TableHead>
                      <TableRow style={{color:'#fff'}} >
                  <TableCell></TableCell>
                          <TableCell align="right" style={{padding: "4px 5px 4px 24px"}}component="th" >{translate('dynatest.resources.mensuals.select.mes.Enero')}</TableCell>
                          <TableCell align="right" style={{padding: "4px 5px 4px 24px"}}component="th" >{translate('dynatest.resources.mensuals.select.mes.Febrero')}</TableCell>
                          <TableCell align="right" style={{padding: "4px 5px 4px 24px"}}component="th" >{translate('dynatest.resources.mensuals.select.mes.Marzo')}</TableCell>
                          <TableCell align="right" style={{padding: "4px 5px 4px 24px"}}component="th" >{translate('dynatest.resources.mensuals.select.mes.Abril')}</TableCell>
                          <TableCell align="right" style={{padding: "4px 5px 4px 24px"}}component="th" >{translate('dynatest.resources.mensuals.select.mes.Mayo')}</TableCell>
                          <TableCell align="right" style={{padding: "4px 5px 4px 24px"}}component="th" >{translate('dynatest.resources.mensuals.select.mes.Junio')}</TableCell>
                          <TableCell align="right" style={{padding: "4px 5px 4px 24px"}}component="th" >{translate('dynatest.resources.mensuals.select.mes.Julio')}</TableCell>
                          <TableCell align="right" style={{padding: "4px 5px 4px 24px"}}component="th" >{translate('dynatest.resources.mensuals.select.mes.Agosto')}</TableCell>
                          <TableCell align="right" style={{padding: "4px 5px 4px 24px"}}component="th" >{translate('dynatest.resources.mensuals.select.mes.Septiembre')}</TableCell>
                          <TableCell align="right" style={{padding: "4px 5px 4px 24px"}}component="th" >{translate('dynatest.resources.mensuals.select.mes.Octubre')}</TableCell>
                          <TableCell align="right" style={{padding: "4px 5px 4px 24px"}}component="th" >{translate('dynatest.resources.mensuals.select.mes.Noviembre')}</TableCell>
                          <TableCell align="right" style={{padding: "4px 5px 4px 24px"}}component="th" >{translate('dynatest.resources.mensuals.select.mes.Diciembre')}</TableCell>
                          <TableCell align="right" style={{padding: "4px 5px 4px 24px"}}component="th" >{translate('dynatest.resources.mensuals.subTitle.total')}  </TableCell>
                          <br></br>
                      </TableRow>
                    </TableHead>
                    
                   
                    <TableBody>
                    {  mensualview.map((data, i) => (
                                <TableRow > 
                                <TableCell align="right" component="th" fullWidth>
                               <h3 style={{marginBottom: 0}}>{data.ano}</h3><br/>    
                                </TableCell>
                                <TableCell align="right" style={{textAlign:'right', padding: "4px 5px 4px 24px"}} fullWidth>{data.enero ? data.enero: " "}</TableCell>
                                <TableCell align="right" style={{textAlign:'right', padding: "4px 5px 4px 24px"}} fullWidth> {data.febrero ? data.febrero: " "} </TableCell>
                                <TableCell align="right" style={{textAlign:'right', padding: "4px 5px 4px 24px"}} fullWidth>{data.marzo ? data.marzo: " "} </TableCell>
                                <TableCell align="right" style={{textAlign:'right', padding: "4px 5px 4px 24px"}} fullWidth>{data.abril ? data.abril: " "}</TableCell>
                                <TableCell align="right" style={{textAlign:'right', padding: "4px 5px 4px 24px"}} fullWidth> {data.mayo ? data.mayo: " "} </TableCell>
                                <TableCell align="right" style={{textAlign:'right', padding: "4px 5px 4px 24px"}} fullWidth> {data.junio ? data.junio: " "} </TableCell>
                                <TableCell align="right" style={{textAlign:'right', padding: "4px 5px 4px 24px"}} fullWidth> {data.julio ? data.julio: " "} </TableCell>
                                <TableCell align="right" style={{textAlign:'right', padding: "4px 5px 4px 24px"}} fullWidth>{data.agosto ? data.agosto: " "}</TableCell>
                                <TableCell align="right" style={{textAlign:'right', padding: "4px 5px 4px 24px"}} fullWidth> {data.septiembre ? data.septiembre: " "}</TableCell>
                                <TableCell align="right" style={{textAlign:'right', padding: "4px 5px 4px 24px"}} fullWidth>{data.octubre ? data.octubre: " "}</TableCell>
                                <TableCell align="right" style={{textAlign:'right', padding: "4px 5px 4px 24px"}} fullWidth>{data.noviembre ? data.noviembre: " "}</TableCell>
                                <TableCell align="right" style={{textAlign:'right', padding: "4px 5px 4px 24px"}} fullWidth> {data.diciembre ? data.diciembre: " "} </TableCell>
                                <TableCell align="right" style={{textAlign:'right', padding: "4px 5px 4px 24px"}} fullWidth> {
                                this.sumaFila(data.enero , data.febrero , data.marzo, data.abril , data.mayo , data.junio , data.julio, data.agosto, data.septiembre,data.octubre,data.noviembre, data.diciembre)
                                
                                } </TableCell>

                            </TableRow>
                              )
                            )}
                             <TableRow>
                    <TableCell align="right" component="th" fullWidth>
                        <h3 style={{marginBottom: 0}}>{translate('dynatest.resources.mensuals.subTitle.total')}</h3><br/>    
                          </TableCell>
                        <TableCell align="right" style={{textAlign:'right', padding: "4px 5px 4px 24px"}} fullWidth>   {sumaEnero ? sumaEnero: ""}</TableCell>
                        <TableCell align="right" style={{textAlign:'right', padding: "4px 5px 4px 24px"}} fullWidth> {sumaFebrero ? sumaFebrero: ""} </TableCell>
                        <TableCell align="right" style={{textAlign:'right', padding: "4px 5px 4px 24px"}} fullWidth>{sumaMarzo ? sumaMarzo: ""} </TableCell>
                        <TableCell align="right" style={{textAlign:'right', padding: "4px 5px 4px 24px"}} fullWidth>{sumaAbril ? sumaAbril: ""}</TableCell>
                        <TableCell align="right" style={{textAlign:'right', padding: "4px 5px 4px 24px"}} fullWidth> {sumaMayo ? sumaMayo: ""} </TableCell>
                        <TableCell align="right" style={{textAlign:'right', padding: "4px 5px 4px 24px"}} fullWidth> {sumaJunio ? sumaJunio: ""} </TableCell>
                        <TableCell align="right" style={{textAlign:'right', padding: "4px 5px 4px 24px"}} fullWidth> {sumaJulio ? sumaJulio: ""} </TableCell>
                        <TableCell align="right" style={{textAlign:'right', padding: "4px 5px 4px 24px"}} fullWidth>{sumaAgosto ? sumaAgosto: ""}</TableCell>
                        <TableCell align="right" style={{textAlign:'right', padding: "4px 5px 4px 24px"}} fullWidth> {sumaSeptiembre ? sumaSeptiembre: ""}</TableCell>
                        <TableCell align="right" style={{textAlign:'right', padding: "4px 5px 4px 24px"}} fullWidth>{sumaOctubre ? sumaOctubre: ""}</TableCell>
                        <TableCell align="right" style={{textAlign:'right', padding: "4px 5px 4px 24px"}} fullWidth>{sumaNoviembre ? sumaNoviembre: ""}</TableCell>
                        <TableCell align="right" style={{textAlign:'right', padding: "4px 5px 4px 24px"}} fullWidth> {sumaDiciembre ? sumaDiciembre: ""} </TableCell>
                        <TableCell align="right" style={{textAlign:'right', padding: "4px 5px 4px 24px"}} fullWidth> {sumaEnero+sumaFebrero+sumaMarzo+sumaAbril+sumaMayo+sumaJunio+sumaJulio+sumaAgosto+sumaSeptiembre+sumaOctubre+sumaNoviembre+sumaDiciembre } </TableCell>
                    </TableRow>
                            
                            </TableBody>
                          
                                  
                                </Table>
                                <br/>
                        <HighchartsReact highcharts={Highcharts} options={options} />
                </React.Fragment>
            )

            }
        //     else{
        //       return(
        //         <React.Fragment>
        
        // <Table aria-label="simple table" fullWidth  style={{width: "100%"}}>
        
                                 
        //                 <TableHead>
        //               <TableRow style={{color:'#fff'}} >
        //                   <TableCell></TableCell>
        //                   <TableCell align="right" component="th" >Enero  </TableCell>
        //                   <TableCell align="right" component="th" >Febrero  </TableCell>
        //                   <TableCell align="right" component="th" >Marzo  </TableCell>
        //                   <TableCell align="right" component="th" >Abril  </TableCell>
        //                   <TableCell align="right" component="th" >Mayo  </TableCell>
        //                   <TableCell align="right" component="th" >Junio  </TableCell>
        //                   <TableCell align="right" component="th" >Julio  </TableCell>
        //                   <TableCell align="right" component="th" >Agosto  </TableCell>
        //                   <TableCell align="right" component="th" >Septiembre </TableCell>
        //                   <TableCell align="right" component="th" >Noviembre  </TableCell>
        //                   <TableCell align="right" component="th" >Diciembre  </TableCell>
        //                   <br></br>
        //                 {/* {mensualview.map((data,i)=>(
                            
        //                 <TableCell align="right" component="th" > {data.ano}</TableCell>
                                                             
        //                 ))
        //                 } */}
        //               </TableRow>
        //             </TableHead>
        //             <TableBody>
        //             {  titulos2.map((data, i) => (
        //                         <TableRow > 
        //                         <TableCell align="right" component="th" fullWidth>
        //                         <h3 style={{marginBottom: 0}}>{titulos2[i]}</h3><br/>    
        //                         </TableCell>
        //                         <TableCell align="right" fullWidth style={{textAlign:'right'}}> <span> {categorias[i].enero ? categorias[i].enero : " "} </span></TableCell>
        //                          <TableCell align="right" fullWidth style={{textAlign:'right'}}> <span> {categorias[i].febrero ? categorias[i].febrero : " "} </span></TableCell>  
        //                        <TableCell align="right" fullWidth style={{textAlign:'right'}}> <span >{categorias[i].marzo ? categorias[i].marzo : " " }</span> </TableCell>  
        //                        <TableCell align="right" fullWidth style={{textAlign:'right'}}> <span >{categorias[i].abril ? categorias[i].abril : " "}</span></TableCell>  
        //                         <TableCell align="right"fullWidth  style={{textAlign:'right'}}> <span >{categorias[i].mayo ? categorias[i].mayo : " "}</span></TableCell> 
        //                         <TableCell align="right"fullWidth  style={{textAlign:'right'}}> <span >{categorias[i].junio ? categorias[i].junio : " "}</span></TableCell> 
        //                         <TableCell align="right" fullWidth style={{textAlign:'right'}}> <span >{categorias[i].julio ? categorias[i].julio : " "}</span></TableCell> 
        //                         <TableCell align="right" fullWidth style={{textAlign:'right'}}> <span >{categorias[i].agosto ? categorias[i].agosto : " "}</span></TableCell>              
        //                        <TableCell align="right" fullWidth style={{textAlign:'right'}}> <span >{categorias[i].septiembre ? categorias[i].septiembre : " "}</span></TableCell> 
        //                        <TableCell align="right" fullWidth style={{textAlign:'right'}}> <span >{categorias[i].octubre ? categorias[i].octubre : " "}</span></TableCell> 
        //                        <TableCell align="right" fullWidth style={{textAlign:'right'}}> <span >{categorias[i].noviembre ? categorias[i].noviembre : " "}</span></TableCell> 
                               
        //                         <TableCell align="right" fullWidth style={{textAlign:'right'}}> <span >{categorias[i].diciembre ? categorias[i].diciembre : " "}</span></TableCell> 
                         
        //                     {/* <TableCell align="right" > <span ></span></TableCell>  */}
        
        //                     </TableRow>
        //             )
        //                           )} 
        //                            <TableRow>
        //             <TableCell align="right" component="th" fullWidth>
        //                 <h3 style={{marginBottom: 0}}>{translate('dynatest.resources.mensuals.subTitle.total')}</h3><br/>    
        //                 </TableCell>
        //                 <TableCell align="right" style={{textAlign:'right'}} fullWidth>   {sumaEnero ? sumaEnero: ""}</TableCell>
        //                 <TableCell align="right" style={{textAlign:'right'}} fullWidth> {sumaFebrero ? sumaFebrero: ""} </TableCell>
        //                 <TableCell align="right" style={{textAlign:'right'}} fullWidth>{sumaMarzo ? sumaMarzo: ""} </TableCell>
        //                 <TableCell align="right" style={{textAlign:'right'}} fullWidth>{sumaAbril ? sumaAbril: ""}</TableCell>
        //                 <TableCell align="right" style={{textAlign:'right'}} fullWidth> {sumaMayo ? sumaMayo: ""} </TableCell>
        //                 <TableCell align="right" style={{textAlign:'right'}} fullWidth> {sumaJunio ? sumaJunio: ""} </TableCell>
        //                 <TableCell align="right" style={{textAlign:'right'}} fullWidth> {sumaJulio ? sumaJulio: ""} </TableCell>
        //                 <TableCell align="right" style={{textAlign:'right'}} fullWidth>{sumaAgosto ? sumaAgosto: ""}</TableCell>
        //                 <TableCell align="right" style={{textAlign:'right'}} fullWidth> {sumaSeptiembre ? sumaSeptiembre: ""}</TableCell>
        //                 <TableCell align="right" style={{textAlign:'right'}} fullWidth>{sumaOctubre ? sumaOctubre: ""}</TableCell>
        //                 <TableCell align="right" style={{textAlign:'right'}} fullWidth>{sumaNoviembre ? sumaNoviembre: ""}</TableCell>
        //                 <TableCell align="right" style={{textAlign:'right'}} fullWidth> {sumaDiciembre ? sumaDiciembre: ""} </TableCell>
        //             </TableRow>
        //                     </TableBody>
                                 
        //                         </Table>
        //         </React.Fragment>
        //     )
        //             }

            }
           
                
          }
            // }
            // else{
            //     return(
            //         <React.Fragment></React.Fragment>
            //         )
            // }
        




export default translate(ViewHistorico);