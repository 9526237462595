import React from 'react';
import { translate } from 'react-admin'
/* VALIDAR QUE LAS COORDENADAS MÁXIMAS DEL PROYECTO SEAN MAYORES A LAS COORDENADAS MÍNIMAS*/


export const CoordenadasMaximasLat =(value, props, translate) =>{
    
    if(value !== undefined)
    {  
        let latitudIni = document.getElementById('LatitudInicial');

        if(latitudIni){
            latitudIni = latitudIni.value
            if(parseFloat(value) <= parseFloat(latitudIni)){   
                return translate.translate('resources.validaciones.latitudMaxima');        
            } 
        }                                
    }
}

export const CoordenadasMaximasLng =(value, props, translate) =>{
    
    if(value !== undefined)
    {  
        let LngIni = document.getElementById('LongitudInicial');

        if(LngIni){
            LngIni = LngIni.value            
            if(parseFloat(value) <= parseFloat(LngIni)){   
                return translate.translate('resources.validaciones.longitudMaxima');         
            } 
        }                                
    }
}

// ------ VALIDACIÓN DE PR -------

export const validarPR =(props, translate) => {
    var ingresoAbInicio = document.getElementById("ingresoPrInicio");
    var ingresoAbFin = document.getElementById("ingresoPrFin");
    if (ingresoAbInicio && ingresoAbFin) {
        if (ingresoAbInicio.value != '' && ingresoAbFin.value != '') {
            let Abinc = ingresoAbInicio.value.split("+");
            Abinc[0] = Abinc[0].replace("PR", "");
            ingresoAbInicio = Abinc[0] + "." + Abinc[1];

            let Abfin = ingresoAbFin.value.split("+");
            Abfin[0] = Abfin[0].replace("PR", "");
            ingresoAbFin = Abfin[0] + "." + Abfin[1];

            if (ingresoAbInicio > ingresoAbFin) {
                return translate.translate('resources.validaciones.abscisaInicialDebeSerMenor');
            }
        }
    }
}

export const prValidationInicio =(value, props, translate) =>{
    
    /*VALORES PR INICIO*/
    let PrInicioBase = document.getElementById('IdprInicio');
    if(PrInicioBase){
        PrInicioBase = PrInicioBase.value;
        let ingresoPrInicio = document.getElementById('ingresoPrInicio').value;
        let separadorInicio = ingresoPrInicio.split("+");
        let separadorInicio1 = separadorInicio[0].split("PR");
        let sePrInicioBase = PrInicioBase.split(".");
        let PrFinBase = document.getElementById('IdprFin').value;
        let sePrFinBase = PrFinBase.split(".");

        /*VALIDACIONES PR INICIO*/
        if(parseInt(separadorInicio1[1] ) < parseInt(PrInicioBase) || parseInt(separadorInicio1[1] )  > parseInt(PrFinBase) )
        {
            return translate.translate('dynatest.resources.validaciones.prValidationInicio') + PrInicioBase +translate.translate('dynatest.resources.validaciones.prValIniy') + PrFinBase;
        }
        if(parseInt(separadorInicio1[1] ) === parseInt(PrInicioBase))
        {
            if(parseInt(separadorInicio[1]) < parseInt(sePrInicioBase[1]))

            return translate.translate('dynatest.resources.validaciones.prValidationInicio') + PrInicioBase +translate.translate('dynatest.resources.validaciones.prValIniy') + PrFinBase;
        }
        if(parseInt(separadorInicio1[1] ) === parseInt(sePrFinBase[0]))
        {
            if(parseInt(separadorInicio[1]) > parseInt(sePrFinBase[1]))

            return translate.translate('dynatest.resources.validaciones.prValidationInicio') + PrInicioBase +translate.translate('dynatest.resources.validaciones.prValIniy') + PrFinBase;
        }
    }
    
}

export const prValidationFin =(value, props, translate) =>{
    
    /*VALORES ABS FIN */
    let PrFinBase = document.getElementById('IdprFin');
    let ingresoPrFin = document.getElementById('ingresoPrFin');
    if(PrFinBase && ingresoPrFin)
    {
        PrFinBase = PrFinBase.value;
        let ingresoPrFin = document.getElementById('ingresoPrFin').value;
        let separadorFin = ingresoPrFin.split("+");
        let separadorFin1 = separadorFin[0].split("PR");
        let sePrFinBase = PrFinBase.split(".");
        let PrInicioBase = document.getElementById('IdprInicio').value;
        let sePrInicioBase = PrInicioBase.split(".");
        /*VALIDACIONES PRFIN*/
        if(parseInt(separadorFin1[1] ) > parseInt(PrFinBase) || parseInt(separadorFin1[1] )  < parseInt(PrInicioBase) )
        {
            return translate.translate('dynatest.resources.validaciones.prValidationFin') + PrInicioBase +translate.translate('dynatest.resources.validaciones.prValnFiny') + PrFinBase;
        }
        if(parseInt(separadorFin1[1] ) === parseInt(PrFinBase))
        {
            if(parseInt(separadorFin[1]) > parseInt(sePrFinBase[1]))
        
                return translate.translate('dynatest.resources.validaciones.prValidationFin') + PrInicioBase +translate.translate('dynatest.resources.validaciones.prValnFiny') + PrFinBase;
        }
        if(parseInt(separadorFin1[1] ) === parseInt(sePrInicioBase[0]))
        {
            if(parseInt(separadorFin[1]) < parseInt(sePrInicioBase[1]))
        
                return translate.translate('dynatest.resources.validaciones.prValidationFin') + PrInicioBase +translate.translate('dynatest.resources.validaciones.prValnFiny') + PrFinBase;
        }
    }    
}


//------ VALIDACIÓN LONGITUD Y LATITUD ----
export const validarDecimales =(value, props, translate) =>{
    
    if(value !== undefined){
        if (!/^[+-]?([0-9]+([.][0-9]+))?$/.test(value)) {
             return translate.translate('resources.validaciones.decimal');
            }      
    } 
}


export const validarDecimales2 =(value, props, translate) =>{
    if(value !== undefined){
        if (!/^[+-]?([0-9]+([,][0-9]+))*$/.test(value)) { return translate.translate('resources.validaciones.decimal'); }      
    } 
}
export const validateDecima =(value, props, translate) =>{
    
    if(value < -90.000){
        if (!/^[+-]?([0-9]+([.][0-9]+))?$/.test(value)) {
            return translate.translate('resources.validaciones.mayor90'); 
            }      
    } 
}

export const validatLatitud =(value, props, translate) =>{
    if(value !== undefined){

        // let separador1 =value.split('.');
        // console.log(separador1[1])
        if(value < -90.000){
            return translate.translate('resources.validaciones.min90'); 
        }
        if(value > 90.000){
            return translate.translate('resources.validaciones.max90'); 
        }
        
    }
}


export const validarLongitud =(value, props, translate) =>{
    if(value !== undefined){
    // let separador1 =value.split(',');
    //     console.log(separador1[1])
        if(value < -180){
            return translate.translate('resources.validaciones.min180');
        }
        if(value > 180){
            return translate.translate('resources.validaciones.max180'); 
        }
    } 
}

export const validarDecimales3 =(value, props, translate) =>{

    if(value !== undefined){
        if (!/^[+-]?([0-9]+([,][0-9]+))*$/.test(value)) { return translate.translate('resources.validaciones.decimal'); }      
    } 
}

export const validarDecimales4 =(value, props, translate) =>{
    if(value !== undefined){
        if (!/^[+-]?([0-9]+([,][0-9]+))*$/.test(value)) { return translate.translate('resources.validaciones.decimal'); }      
    } 
}

export const validarDecimales5 =(props, translate) =>{
    let latitudFin = document.getElementById('latitudFin').value;
    if (!/^[+-]?([0-9]+([,][0-9]+))*$/.test(latitudFin))
    {
      return translate.translate('resources.validaciones.coordenadasDecimal')
    }
}

// ------ VALIDACIÓN DE ABSCISAS -------
export const validarAB =(props, translate) => {
    var ingresoAbInicio = document.getElementById("ingresoAbInicio");
    var ingresoAbFin = document.getElementById("ingresoAbFin");
    if (ingresoAbInicio && ingresoAbFin) {
        if (ingresoAbInicio.value != '' && ingresoAbFin.value != '') {
            let Abinc = ingresoAbInicio.value.split("+");
            Abinc[0] = Abinc[0].replace("K", "");
            ingresoAbInicio = Abinc[0] + "." + Abinc[1];

            let Abfin = ingresoAbFin.value.split("+");
            Abfin[0] = Abfin[0].replace("K", "");
            ingresoAbFin = Abfin[0] + "." + Abfin[1];

            if (ingresoAbInicio > ingresoAbFin) {
                return translate.translate('resources.validaciones.abscisaInicialDebeSerMenor')
            }
        }
    }
}
export const abValidationInicio =(value, props, translate) =>{
    /*VALORES ABS INICIO*/
    let AbInicioBase = document.getElementById('IdAbInicio');
    if(AbInicioBase)
    {
       
        AbInicioBase = AbInicioBase.value
        let ingresoAbInicio = document.getElementById('ingresoAbInicio').value;
        let separadorInicio = ingresoAbInicio.split("+");
        let separadorInicio1 = separadorInicio[0].split("K");
        let seAbInicioBase = AbInicioBase.split(".");
        let AbFinBase = document.getElementById('IdAbFin').value;
        let seAbFinBase = AbFinBase.split(".");
        /*VALIDACIONES PR INICIO*/
        if(parseInt(separadorInicio1[1] ) < parseInt(AbInicioBase) || parseInt(separadorInicio1[1] ) > parseInt(AbFinBase) )
        {
            
            return translate.translate('dynatest.resources.validaciones.abValidationInicio') + AbInicioBase + translate.translate('dynatest.resources.validaciones.abValInicioy') + AbFinBase;
        }
        if(parseInt(separadorInicio1[1] ) === parseInt(AbInicioBase))
        {
            if(parseInt(separadorInicio[1]) < parseInt(seAbInicioBase[1]))

            return translate.translate('dynatest.resources.validaciones.abValidationInicio') + AbInicioBase +translate.translate('dynatest.resources.validaciones.abValInicioy') + AbFinBase;
        }
        if(parseInt(separadorInicio1[1] ) === parseInt(seAbFinBase[0]))
        {
            
            if(parseInt(separadorInicio[1]) > parseInt(seAbFinBase[1]))

            return translate.translate('dynatest.resources.validaciones.abValidationInicio') + AbInicioBase +translate.translate('dynatest.resources.validaciones.abValInicioy') + AbFinBase;
        }
    }    

}

export const abValidationFin =(value, props, translate) =>{
    /*VALORES AB FIN */
    let AbFinBase = document.getElementById('IdAbFin');
    let ingresoAbFin = document.getElementById('ingresoAbFin');
    if(AbFinBase && ingresoAbFin)
    {
        
        AbFinBase = AbFinBase.value;
        ingresoAbFin = ingresoAbFin.value;
        let separadorFin = ingresoAbFin.split("+");
        let separadorFin1 = separadorFin[0].split("K");
        let seAbFinBase = AbFinBase.split(".");
        let AbInicioBase = document.getElementById('IdAbInicio').value;
        let seAbInicioBase = AbInicioBase.split(".");
        /*VALIDACIONES AB FIN*/
        if(parseInt(separadorFin1[1] ) > parseInt(AbFinBase) || parseInt(separadorFin1[1] ) < parseInt(AbInicioBase ) )
        {
            return translate.translate('dynatest.resources.validaciones.abValidationFin') + AbInicioBase +translate.translate('dynatest.resources.validaciones.abValFiny') + AbFinBase;
        }
        if(parseInt(separadorFin1[1] ) === parseInt(AbFinBase))
        {
            if(parseInt(separadorFin[1]) > parseInt(seAbFinBase[1]))
        
                return translate.translate('dynatest.resources.validaciones.abValidationFin') + AbInicioBase +translate.translate('dynatest.resources.validaciones.abValFiny') + AbFinBase;
        }
        if(parseInt(separadorFin1[1] ) === parseInt(seAbInicioBase[0]))
        {
            if(parseInt(separadorFin[1]) < parseInt(seAbInicioBase[1]))
        
                return translate.translate('dynatest.resources.validaciones.abValidationFin') + AbInicioBase +translate.translate('dynatest.resources.validaciones.abValFiny') + AbFinBase;
        }
    }
    
}

//VALIDAR QUE LOS TAMAÑOS DE LOS PR NO SEAN IGUALES
export const validarLogicaPrFinCreate=(value, props, translate)=>{
    if(value !== undefined){
        
        let prInicio = document.getElementById('ingresoPrInicio').value;
        prInicio = prInicio.split('PR')
        if(prInicio[1] !== undefined)
        {
            prInicio = prInicio[1].split('+')
              
            value = value.split('PR')
            value = value[1].split('+')
        
            if(parseInt(value[0]) < parseInt(prInicio[0]))
            {   
                return translate.translate('dynatest.resources.validaciones.validarLogicaPrFinCreate');
            }
            if(parseInt(value[0]) === parseInt(prInicio[0]) && parseInt(value[1]) === parseInt(prInicio[1]))
            {
                return translate.translate('dynatest.resources.validaciones.validarLogicaPrFinCreate');
            }
            if(parseInt(value[0]) === parseInt(prInicio[0]) && parseInt(value[1]) < parseInt(prInicio[1]))
            {
                return translate.translate('dynatest.resources.validaciones.validarLogicaPrFinCreate');
            }
           
        }

        
        
    }
}

export const ValorMaximoPrCreate =(value, props, translate) =>{
    if(value !== undefined)
    {
        value = value.split("PR")
        if(value[1] !== undefined)
        {
            value=value[1].split("+")
            if(value[0] > 999)
            {
                return translate.translate('dynatest.resources.validaciones.ValorMaximoPrCreate');
            }
        }
    }
}

export const validarLogicaPrFinEdit= translate((value, props)=>{ 
    const translate = props;
    let prInicio = document.getElementById('ingresoPrInicio').value;
    prInicio = prInicio.split('PR')
    if(prInicio[1] !== undefined)
    {
        prInicio = prInicio[1].split('+')
        let prFin = document.getElementById('ingresoPrFin').value;
        prFin = prFin.split('PR')
        prFin = prFin[1].split('+')

        if(parseInt(prFin[0]) < parseInt(prInicio[0]))
        {   
            return translate("resources.validaciones.prFin");
        }
        if(parseInt(prFin[0]) === parseInt(prInicio[0]) && parseInt(prFin[1]) === parseInt(prInicio[1]))
        {
            return translate('resources.validaciones.prFin')
        }
        if(parseInt(prFin[0]) === parseInt(prInicio[0]) && parseInt(prFin[1]) < parseInt(prInicio[1]))
        {
            return translate('resources.validaciones.prFin')
        }
    }
})

export const ValorMaximoPrEditInicio =(props, translate) =>{
    
    let value = document.getElementById('ingresoPrInicio').value;
    value = value.split("PR")
    if(value[1] !== undefined)
    {
        value=value[1].split("+")
        
        if(value[0] > 999)
        {
            return translate.translate('resources.validaciones.mayorPR9')
        }
    }
} 

export const ValorMaximoPrEditFin =(props, translate) =>{
    
    let value = document.getElementById('ingresoPrFin').value;
    value = value.split("PR")
    if(value[1] !== undefined)
    {
        value=value[1].split("+")
        
        if(value[0] > 999)
        {
            return translate.translate('resources.validaciones.mayorPR9')
        }
    }
} 

//Validaciones logicas de las ABscisas
export const validarLogicaAbsFinCreate=(...translate)=>{
    //console.log(translate[2].translate)
    let value = document.getElementById('ingresoAbFin');
    if(value)
    {
        let prInicio = document.getElementById('ingresoAbInicio').value;
        value = document.getElementById('ingresoAbFin').value;
        prInicio = prInicio.split('K')
        if(prInicio[1] !== undefined)
        {
            prInicio = prInicio[1].split('+')
              
            value = value.split('K')
            if(value[1]){
                value = value[1].split('+')
            
                if(parseFloat(value[0]) < parseFloat(prInicio[0]))
                {   
                    return translate[2].translate('dynatest.resources.validaciones.validarLogicaAbsFinCreate')
                }
                if(parseFloat(value[0]) === parseFloat(prInicio[0]) && parseFloat(value[1]) === parseFloat(prInicio[1]))
                {
                    return translate[2].translate('dynatest.resources.validaciones.validarLogicaAbsFinCreate')
                }
                if(parseFloat(value[0]) === parseFloat(prInicio[0]) && parseFloat(value[1]) < parseFloat(prInicio[1]))
                {
                    return translate[2].translate('dynatest.resources.validaciones.validarLogicaAbsFinCreate')
                }
            }
        }
        
    }
}

export const validarLogicaAbsFinEdit= translate((value, props)=>{
    const translate = props;
    let prInicio = document.getElementById('ingresoAbInicio').value;
    prInicio = prInicio.split('K')
    if(prInicio[1] !== undefined)
    {
        prInicio = prInicio[1].split('+')
        
       
        let prFin = document.getElementById('ingresoAbFin').value;
        if(prFin !== null && prFin !== '' && prFin !== undefined){
            prFin = prFin.split('K')
            prFin = prFin[1].split('+')
        }

        if(parseInt(prFin[0]) < parseInt(prInicio[0]))
        {   
            return translate('resources.validaciones.abscisaFin')
        }
        if(parseInt(prFin[0]) === parseInt(prInicio[0]) && parseInt(prFin[1]) === parseInt(prInicio[1]))
        {
            return translate('resources.validaciones.abscisaFin') 
        }
        if(parseInt(prFin[0]) === parseInt(prInicio[0]) && parseInt(prFin[1]) < parseInt(prInicio[1]))
        {
            return translate('resources.validaciones.abscisaFin') 
        }

    }
    
    
})
export const ValorMaximoAbsEditInicio =(props, translate) =>{
    
    let value = document.getElementById('ingresoAbInicio').value;
    value = value.split("K")
    if(value[1] !== undefined)
    {
        value=value[1].split("+")
        
        if(value[0] > 9999)
        {
            return translate.translate('resources.validaciones.mayorK9');
        }
    }
    
} 

export const ValorMaximoAbsEditFin =(props, translate) =>{
    
    let value = document.getElementById('ingresoAbFin').value;
    value = value.split("K")
    if(value[1] !== undefined)
    {
        value=value[1].split("+")
       
        if(value[0] > 9999)
        {
            return translate.translate('resources.validaciones.mayorK9');
        }
    }
} 

export const ValorMaximoAbsCreate = (value, props, translate) =>{
    if(value !== undefined)
    {
        value = value.split("K")
        if(value[1] !== undefined)
        {
            value=value[1].split("+")
            if(value[0] > 999)
            {
                /* return 'El valor ingresado no puede ser mayor a PR999+999' */
                return translate.translate('dynatest.resources.validaciones.ValorMaximoAbsCreate')
            }
        }
    }
}

 
/*VALIDACION GEOREFERENCIA SEGMENTOS */
export const latMininima =(value, props, translate) =>{
    let latMin = document.getElementById('latMin');
    let latMax = document.getElementById('latMax');
    if(latMax){

        latMin = latMin.value;
        latMax = latMax.value;
        if(parseFloat(latMin) < parseFloat(latMax))
        {
            if(parseFloat(value) < parseFloat(latMin) || parseFloat(value) > parseFloat(latMax)){
                return translate.translate('dynatest.resources.validaciones.latMininima') + latMin +translate.translate('dynatest.resources.validaciones.latMininimay') + latMax;
            }
        }else if(parseFloat(latMax) < parseFloat(latMin)){
            if(parseFloat(value) < parseFloat(latMax) || parseFloat(value) > parseFloat(latMin)){
                return translate.translate('dynatest.resources.validaciones.latMininima') + latMax  +translate.translate('dynatest.resources.validaciones.latMininimay') + latMin;
            }
        }      
    }
} 

export const lngMininima =(value, props, translate) =>{
    
    
    /*VALORES ABS FIN */
    let lngMin = document.getElementById('lngMin');
    let lngMax = document.getElementById('lngMax');
    if(lngMax){
        lngMin = lngMin.value;
        lngMax = lngMax.value;
        if(parseFloat(lngMin) <  parseFloat(lngMax)){
            if(parseFloat(value) <  parseFloat(lngMin) || parseFloat(value) >  parseFloat(lngMax))
            {   //console.log('Entrada1' + lngMin + lngMax)
                return translate.translate('dynatest.resources.validaciones.lngMininima') + lngMin +translate.translate('dynatest.resources.validaciones.lngMininimay') + lngMax;
            }
        }else if(parseFloat(lngMax) < parseFloat(lngMin)){
            if(parseFloat(value) <  parseFloat(lngMax) || parseFloat(value) >  parseFloat(lngMin))
            {
                //console.log('Entrada2' + lngMin + lngMax)
                return translate.translate('dynatest.resources.validaciones.lngMininima') +  lngMax +translate.translate('dynatest.resources.validaciones.lngMininimay') + lngMin;
            }
        }       
    }
}

export const Altitud =(value, props, translate) =>{
    let alturaMin = document.getElementById('alturaIni');
    let alturaMax = document.getElementById('alturaFin');
    if(alturaMax){
        alturaMin = alturaMin.value;
        alturaMax = alturaMax.value;
        if(parseFloat(value) < parseFloat(alturaMin) || parseFloat(value) > parseFloat(alturaMax)){
            return translate.translate('dynatest.resources.validaciones.altitud') + alturaMin +translate.translate('dynatest.resources.validaciones.altitudy') + alturaMax;
        }
    }
}


export const latMininimaEdit =(value, props, translate) =>{
    
    /*VALORES ABS FIN */

    let latMin = document.getElementById('latMin');
    let latMax = document.getElementById('latMax');

    if(latMax){
        latMin = latMin.value;
        latMax = latMax.value;
        if(parseFloat(value) < parseFloat(latMin) || parseFloat(value) > parseFloat(latMax))
        {
         
            return translate.translate('resources.validaciones.rangoEntre') + latMin +" y " + latMax;
        }
        
    }
}


export const lngMininimaEdit =(value, props, translate) =>{
    
    /*VALORES ABS FIN */

    let lngMin = document.getElementById('lngMin');
    let lngMax = document.getElementById('lngMax');

    if(lngMax){
        lngMin = lngMin.value;
        lngMax = lngMax.value;
        if(parseFloat(value) <  parseFloat(lngMax) || parseFloat(value) >  parseFloat(lngMin))
        {

            return translate.translate('resources.validaciones.rangoEntre') + lngMin +" y " + lngMax;
        }
    }
}


export const validarTresDecimales =(value, props, translate) =>{
    if(value){
        if(value !== 0 && value  !== 'No aplica'){
            if (parseFloat(value)  % 1 !== 0) {
                let pattern = new RegExp("^[+-]?[0-9]+([.][0-9]{1,3})$");
                if(!pattern.test(value)){ 
                    //let patterns = new RegExp("^[0-9]+([.][0-9]{1})$");
                    // if(!patterns.test(parseFloat(value) )){ 
                    // }                           
                    return translate.translate('resources.validaciones.decimales3'); 
                }                            
            }                
        }
    }  
}

export const validarDosDecimales =(value, props, translate) =>{
    if(value){
        if(value !== 0 && value  !== 'No aplica'){
            if (parseFloat(value)  % 1 !== 0) {
                let pattern = new RegExp("^[+-]?[0-9]+([.][0-9]{1,2})$");
                if(!pattern.test(value)){ 
                    //let patterns = new RegExp("^[0-9]+([.][0-9]{1})$");
                    // if(!patterns.test(parseFloat(value) )){ 
                    // }                           
                    return translate.translate('resources.validaciones.decimales2')
                }                            
            }                
        }
    }  
}

export const validarUnDecimal =(value, props, translate) =>{

    if(value){
        if(value !== 0){
            if (value % 1 !== 0) {
                var pattern = new RegExp("^[0-9]+([.][0-9]{1})$");
                
                if(!pattern.test(value)){ 
                    var patterns = new RegExp("^[0-9]+([.][0-9]{1})$");
                    if(!patterns.test(value)){ return translate.translate('resources.validaciones.decimales1'); }                           
                }            
            }                
        }
    }  
}

export const validarPorcentaje =(value, props, translate) =>{
    value = value.split('%');
    if (!/^[+-]?([0-9]+)*$/.test(value[0]))
    {
        return translate.translate('resources.validaciones.entero');
    }
}

/* VALIDAR RANGOS DE PR ENTRE SEGMENTOS */

export const validarAbsEntreSegmento = (value, props, translate) =>
{
    let prIngreso =document.getElementById('ingresoAbInicio').value;
    let valor =document.getElementById('ingresoAbFin').value;
    
        // prIngreso =prIngreso.value;
        if(prIngreso !== undefined && prIngreso !== null && prIngreso !==''){
            prIngreso = prIngreso.split('K');
            prIngreso = prIngreso[1].split('+');
            prIngreso = prIngreso[0] +  prIngreso[1];

            
            valor= valor.split('K');
            valor= valor[1].split('+')
            valor = valor[0] +  valor[1];
            let resta = parseFloat(valor) - parseFloat(prIngreso);            
            if(resta > 1999)
            {
                return translate.translate('resources.validaciones.distanciaAbscisa');
            }
            // else if(resta < 1000)
            // {
            //     return 'La distancia entre las Abscisas deber ser de al menos  de 1.000'
            // }
        }
    
}


/*VALIDACIÓN DE ABSCISAS EN LOS RAMALES*/
export const AbsRamal = (value, props, translate) =>
{
    let prIngreso =document.getElementById('ingresoAbInicio');
    let valor =document.getElementById('ingresoAbFin');
    if(prIngreso)
    {
        prIngreso =prIngreso.value;
        prIngreso = prIngreso.split('K');
        if(prIngreso !== undefined || prIngreso !== null || prIngreso !=='')
        {
            prIngreso = prIngreso[1].split('+');
            prIngreso = prIngreso[0] +  prIngreso[1];

            valor = valor.value;
            valor= valor.split('K');
            valor= valor[1].split('+')
            valor = valor[0] +  valor[1];
            let resta = parseFloat(valor) - parseFloat(prIngreso);            
            if(resta > 3000)
            {
                return translate.translate('dynatest.resources.validaciones.AbsRamal');
            }
            // else if(resta < 1000)
            // {
            //     return 'La distancia entre las Abscisas deber ser de al menos  de 1.000'
            // }
        }
    }
}

export const validarPrEntreSegmento = (props, translate) =>
{
  
        let AbsIngreso =document.getElementById('ingresoPrInicio');
        let valor = document.getElementById('ingresoPrFin');
        if(AbsIngreso)
        {
            AbsIngreso =AbsIngreso.value;
            AbsIngreso = AbsIngreso.split('PR');
            if(AbsIngreso === undefined || AbsIngreso === null || AbsIngreso ==='')
            {
                AbsIngreso = AbsIngreso[1].split('+');
                AbsIngreso=AbsIngreso[0] +  AbsIngreso[1];

                valor = valor.value;
                valor= valor.split('PR');
                valor= valor[1].split('+')
                valor = valor[0] +  valor[1];

                let resta = parseInt(valor) - parseInt(AbsIngreso);

                if(resta > 1999)
                {
                    return translate.translate('resources.validaciones.distanciaPr')
                }
            }            
        }        
}

export const validarSeisDecimales =(value, props, translate) =>{

    if(value !== 0 && value !== 'No aplica'){
        if (value % 1 !== 0) {
            let pattern = new RegExp("^[0-9]+([.][0-9]{6})$");
            
            if(!pattern.test(value)){ 
                let patterns = new RegExp("^[0-9]+([.][,][0-9]{5})$");
                if(!patterns.test(value)){ return translate.translate('resources.validaciones.decimales2'); }                           
            }                            
        }                
    } 
}

export const Entero =(value, props, translate) =>{
    if(value !== undefined && value !== null)
    {
        if (!/^([0-9]+)*$/.test(value))
        {
        return translate.translate('dynatest.resources.validaciones.Entero');
        } 
    }   
}

// /*--------------------VALIDAR QUE LA ALTURA FINAL SEA MAYOR  A LA ALTURA INICIAL*/

export const AlturaMayor =(value, props, translate) =>{
    
    if(value !== undefined)
    {  
        let alturaIni = document.getElementById('alturaIni');

        if(alturaIni){
            alturaIni = alturaIni.value
            if(parseFloat(value) <= parseFloat(alturaIni)){   
                return translate.translate('resources.validaciones.altitudMaxima');       
            } 
        }                                
    }
}

export const validacionmaxfechaact=(value, props, translate)=>{
    if(value){
        let fecha=new Date(value+"T00:00:00");
        let fechaact=new Date();
        if(fecha>fechaact || !fecha){
            return translate.translate('resources.validaciones.fechaFutura');
        }
    } 
  }

export const isFechaInvalida=(value, props, translate)=>{
    if(value){

        let fechaSeleccionada = new Date(value).toLocaleString("en-US", {timeZone: "America/Bogota"});

        let dateActual = new Date();
        dateActual = dateActual.toLocaleString("en-US", {timeZone: "America/Bogota"});
        dateActual = dateActual.split(",")[0].split("/");
        let dateSeleccionada = new Date(value);
        dateSeleccionada = dateSeleccionada.toLocaleString("en-US", {timeZone: "America/Bogota"});
        dateSeleccionada = dateSeleccionada.split(",")[0].split("/");
        
        if(parseInt(fechaSeleccionada.split("/")[2]) < 2000) {
            return translate.translate('dynatest.resources.validaciones.isFechaInvalida2000')
        } 
        
        if (parseInt(dateSeleccionada[2]) > parseInt(dateActual[2])){
            return translate.translate('dynatest.resources.validaciones.isFechaInvalidaSup')

        } 
        
        if(
            parseInt(dateSeleccionada[2]) == parseInt(dateActual[2]) &&
            parseInt(dateSeleccionada[0]) > parseInt(dateActual[0])
        ){
            return translate.translate('dynatest.resources.validaciones.isFechaInvalidaSup')
            
        } 
        
        if(
            parseInt(dateSeleccionada[2]) == parseInt(dateActual[2]) &&
            parseInt(dateSeleccionada[0]) == parseInt(dateActual[0]) &&
            parseInt(dateSeleccionada[1]) >= parseInt(dateActual[1])
        ){
            return translate.translate('dynatest.resources.validaciones.isFechaInvalidaSup')
                            
        }
    }
  }

  export const isFechaInvalidaDayEquals=(value, props, translate)=>{
    if(value){

        let fechaSeleccionada = new Date(value).toLocaleString("en-US", {timeZone: "America/Bogota"});

        let dateActual = new Date();
        dateActual = dateActual.toLocaleString("en-US", {timeZone: "America/Bogota"});
        dateActual = dateActual.split(",")[0].split("/");
        let dateSeleccionada = new Date(value);
        dateSeleccionada = dateSeleccionada.toLocaleString("en-US", {timeZone: "America/Bogota"});
        dateSeleccionada = dateSeleccionada.split(",")[0].split("/");
        
        if(parseInt(fechaSeleccionada.split("/")[2]) < 2000) {
            return translate.translate('dynatest.resources.validaciones.isFechaInvalida2000')
        } 
        
        if (parseInt(dateSeleccionada[2]) > parseInt(dateActual[2])){
            return translate.translate('dynatest.resources.validaciones.isFechaInvalidaSup')

        } 
        
        if(
            parseInt(dateSeleccionada[2]) == parseInt(dateActual[2]) &&
            parseInt(dateSeleccionada[0]) > parseInt(dateActual[0])
        ){
            return translate.translate('dynatest.resources.validaciones.isFechaInvalidaSup')
            
        } 
        
        if(
            parseInt(dateSeleccionada[2]) == parseInt(dateActual[2]) &&
            parseInt(dateSeleccionada[0]) == parseInt(dateActual[0]) &&
            parseInt(dateSeleccionada[1]) > parseInt(dateActual[1])
        ){
            return translate.translate('dynatest.resources.validaciones.isFechaInvalidaSup')
                            
        }
    }
  }



