import React, { useState } from 'react';
import { showNotification, minValue, SimpleForm, TextInput, SaveButton, ArrayInput, SimpleFormIterator, NumberInput, Toolbar, NotFound } from 'react-admin';
import { Edit } from './../Edit';
//import { SimpleForm } from './../SimpleForm';
import BackButton from '../../BotonCancelar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core';
import { validatLatitud, validarLongitud, Entero, AlturaMayor, CoordenadasMaximasLat, CoordenadasMaximasLng } from './../validacionesInputs';
import {
    required,
    minLength,
    maxLength,
    maxValue
} from 'react-admin';
import Divider from '@material-ui/core/Divider';
import UniqueProject from './../componentes/UniqueProject';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CargarArchivoKml from './ModalKML/cargarArchivokml'
import extractGoogleCoords from '../Mapa/localhelpers/extractGoogleCoords';
import { translate } from 'react-admin';
import config from '../../config/config';
let urlAppjs = config.UrlServer;

const styles = {
    img: {
        height: 128,
    },
    number: {
        textAlign: 'right',
    },
    ocultar: {
        display: 'none'
    }
}
const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
function SimpleDialog(props) {
    console.log(props);
    const { onClose, open, showNotification, translate } = props;
    //console.log(props)
    const [saveButton, setSaveButton] = React.useState(false);
    let idproyecto = props.record["@id"] || null;
    let codproyecto = props.record["codigo"] || null;
    const handleClose = value => {
        onClose(value);
    };
    const handlesub = (valor) => {
        //console.log(valor)
        if (valor && Object.keys(valor).length > 1) {
            setSaveButton(true)
            let totalFetch = 0;
            let terminados = 0;
            for (let i = 0; i < Object.keys(valor).length; i++) {
                if (valor['archivoCarga' + i]) {
                    totalFetch++;
                    let unidadUfa = valor["unidadArchivo" + i];
                    CargarArchivos(valor['archivoCarga' + i], codproyecto, unidadUfa)
                        .then(data => {
                            terminados++;
                            if (terminados === totalFetch) {
                                setSaveButton(false)
                                //showNotification("Archivos Cargados Correctamente")
                            }
                        })
                }
            }
            if (totalFetch == 0) { setSaveButton(false) }
        }
    }
    function CargarArchivos(archivo, proyecto, ufuncional) {
        if (archivo) {
            const data = new FormData();
            let nombrearchivo = proyecto + "-" + ufuncional + ".kml";
            data.append('MapaKML', archivo.rawFile, nombrearchivo);
            data.append('proyecto', idproyecto.replace("/proyectos/", ""));
            data.append('unidadf', ufuncional);
            return fetch(urlAppjs + '/guardarArchivoMapa/', { 
                method: 'POST',
                body: data
            })
            .then(res => res.json())
            .then((success) => {
                console.log(success);
                if (success[0] == "recibido") {
                    document.getElementById("MensajeGuardado").innerHTML = translate('resources.proyectos.fields.ArchCargadoUniFuncional');
                }else{
                    document.getElementById("MensajeGuardado").innerHTML = translate('resources.proyectos.fields.noSubido');
                    console.log("No funciona");
                }
            });
        }
    }
    async function validacionasync(datos) {
        let error = {}
        for (let i = 0; i < Object.keys(datos).length; i++) {
            if (datos['archivoCarga' + i]) {
                let promesa = await readFileAsync(datos['archivoCarga' + i].rawFile)
                let resultado = extractGoogleCoords(promesa)
                console.log(resultado)
                let { errorRes, verificacion } = verificarResultado(resultado, datos['codUnidadArchivo' + i])
                if (!verificacion) {
                    error['archivoCarga' + i] = errorRes
                    throw error
                }
            }
        }
    }
    function readFileAsync(file) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsText(file);
        })
    }
    function verificarResultado(GoogleCoords, unidad) {
        if (GoogleCoords.polygons) {
            let cuenta = 0;
            let error = ""
            GoogleCoords.polygons.map(dato => {
                if (dato.informacion.abscisaIni === "" || dato.informacion.abscisaFin === "") {
                    cuenta++;
                    error = translate('resources.validaciones.abscisasTrazos');
                } else if (Object.keys(dato.informacion).length == 0) {
                    cuenta++;
                    error = translate('resources.validaciones.coordenadaDevuelta');
                } else if (unidad !== "UF" + dato.informacion.unidadFuncional) {
                    cuenta++;
                    error = translate('resources.validaciones.noCoincide'); 
                }
            })
            let ordenValores = GoogleCoords.polygons.sort(function ordenarKml(a, b) {
                if (a.informacion.abscisaIni > b.informacion.abscisaIni) {
                    return 1;
                }
                if (a.informacion.abscisaIni < b.informacion.abscisaIni) {
                    return -1;
                }
                return 0;
            })
            let aux1 = 0;
            ordenValores.map((dato, i) => {
                if (dato.informacion.abscisaIni < aux1 && i != 0) {
                    cuenta++;
                    error = translate('resources.validaciones.trazosDivididos');
                }
                aux1 = dato.informacion.abscisaIni
            })
            if (cuenta > 0) return { errorRes: error, verificacion: false }
            return { errorRes: "", verificacion: true }
        } else return { errorRes: translate('resources.validaciones.errorArchivo'), verificacion: false }
    }
    const CustomToolbar = ({ basePath, data, ...props }) => (
        <Toolbar {...props} saving={saveButton}>
            {/* <Button variant="primary" onClick={handlesub} style={{backgroundColor: '#3f51b5', color:'#fff'}} disabled={false}>
            Guardar
        </Button>        */}
            <SaveButton />
        </Toolbar>
    );
    return (
        <Dialog maxWidth="md" fullWidth onClose={handleClose} aria-labelledby="simple-dialog-title" TransitionComponent={Transition} open={open}>
            <IconButton edge="end" color="inherit" onClick={handleClose} aria-label="close" style={{ textAlign: "right" }}>
                <CloseIcon />
            </IconButton>
            <DialogTitle id="simple-dialog-title">{translate('resources.proyectos.fields.cargarArchivo')}</DialogTitle>
            <SimpleForm asyncValidate={validacionasync} form="FormFile" save={handlesub} toolbar={<CustomToolbar />}>
                <Grid container spacing={24}>
                    <Grid item xs={12}>
                        <CargarArchivoKml proyecto={idproyecto} codproyecto={codproyecto} />
                    </Grid>
                </Grid>
            </SimpleForm>
        </Dialog>
    )
}
const PimsToolbarActions = translate(withStyles(toolbarStyles)(props => {
    const { translate } = props;
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = value => {
        setOpen(false);
    };
    return (
        <Toolbar {...props}>
            <SaveButton />
            <Button variant="contained" color="primary" onClick={handleClickOpen}>
                <VisibilityIcon /> &nbsp; {translate('dynatest.resources.proyectos.buttons.anadirKML')}
        </Button>
            <SimpleDialog open={open} onClose={handleClose} translate = {translate}/>
            <BackButton />
        </Toolbar>
    )
}
));

export const ProyectoEdit = translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;
    const [latMax, setLatMax] = useState('');
    const LatMax = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setLatMax(value)
    }

    const [lngMax, setLngMax] = useState('');
    const LngMax = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setLngMax(value)
    }

    const [lngMin, setLngMin] = useState('');
    const LngMin = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setLngMin(value)
    }

    const [latMin, setLatMin] = useState('');
    const LatMin = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setLatMin(value)
    }
    return (
        <Edit title="dynatest.resources.proyectos.title.editTitle" {...props} >
            <SimpleForm submitOnEnter={false} redirect="list" toolbar={<PimsToolbarActions />}>
                <Grid container spacing={24}>
                    <Grid item xs={2}>
                        <UniqueProject
                            id="codigo"
                            source="codigo"
                            reference="proyectos"
                            label="resources.proyectos.fields.codigo"
                            operation="edit"
                            fullWidth
                            validate={[required(), minLength(2), maxLength(10)]}
                        />
                    </Grid>
                    <Grid item xs={10}>
                        <TextInput label="resources.proyectos.fields.nombre" source="nombre" fullWidth validate={[required(), minLength(2), maxLength(100)]} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput label="resources.proyectos.fields.concesionario" source="concesionario" fullWidth validate={[required(), minLength(2), maxLength(100)]} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h3" component="h3">{translate('dynatest.resources.proyectos.title.coordMinimas')}</Typography>
                        <Divider style={{ marginTop: '10px' }} />
                    </Grid>
                    <Grid item xs={6}>
                        <NumberInput label="resources.proyectos.fields.latitud" id="LatitudInicial" source="latitud1" fullWidth validate={[required(), LatMin, validatLatitud, maxValue(90), minValue(-90)]} />
                    </Grid>
                    <Grid item xs={6}>
                        <NumberInput label="resources.proyectos.fields.longitud" id="LongitudInicial" source="longitud1" fullWidth validate={[required(), LngMin, validarLongitud, maxValue(180), minValue(-180)]} />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant="h3" component="h3">{translate('dynatest.resources.proyectos.title.coordMaximas')}</Typography>
                        <Divider style={{ marginTop: '10px' }} />
                    </Grid>
                    <Grid item xs={6}>
                        <NumberInput label="resources.proyectos.fields.latitud" source="latitud2" fullWidth validate={[CoordenadasMaximasLat, required(), LatMax, validatLatitud, maxValue(90), minValue(-90)]} />
                    </Grid>
                    <Grid item xs={6}>
                        <NumberInput label="resources.proyectos.fields.longitud" source="longitud2" fullWidth validate={[CoordenadasMaximasLng, required(), LngMax, validarLongitud, maxValue(180), minValue(-180)]} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h3" component="h3">{translate('dynatest.resources.proyectos.title.altura')}</Typography>
                        <Divider style={{ marginTop: '10px' }} />
                    </Grid>
                    <Grid item xs={6}>
                        <NumberInput label="resources.proyectos.fields.alturaIni" id="alturaIni" source="alturaIni" fullWidth validate={[minValue(-450, 'Debe ser al menos -450'), maxValue(8700), Entero]} />
                    </Grid>
                    <Grid item xs={6}>
                        <NumberInput label="resources.proyectos.fields.alturaFin" source="alturaFin" fullWidth validate={[minValue(-450, 'Debe ser al menos -450'), maxValue(8700), Entero, AlturaMayor]} />
                    </Grid>

                    {/* className={classes.ocultar} */}
                    <Grid item xs={12}>
                        <ArrayInput label="resources.proyectos.fields.ubicaciones" source="ubicaciona" className={classes.ocultar}
                            defaultValue={[
                                {
                                    lat: latMax,
                                    lng: lngMax
                                },
                                {
                                    lat: latMax,
                                    lng: lngMin
                                },
                                {
                                    lat: latMin,
                                    lng: lngMin
                                },
                                {
                                    lat: latMin,
                                    lng: lngMax
                                }
                            ]}
                        >
                            <SimpleFormIterator disableAdd disableRemove >
                                <TextInput label="resources.proyectos.fields.latitud" source="lat" fullWidth validate={[required()]} />
                                <TextInput label="resources.proyectos.fields.longitud" source="lng" fullWidth validate={[required()]} />
                            </SimpleFormIterator>
                        </ArrayInput>
                    </Grid>
                </Grid>
            </SimpleForm>
        </Edit>
    )
}));