import React, { Component, Fragment } from 'react';
import { FileInput, TextInput, TextField, FormDataConsumer, required } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import config from '../../../config/config';
import loader from '../../../loader10.gif';
import Grid from '@material-ui/core/Grid';
import { translate } from 'react-admin';
import extractGoogleCoords from '../../Mapa/localhelpers/extractGoogleCoords';
let urlAppjs = config.UrlServer;
let descRespuesta = "";

class Respuestas extends Component {
    constructor(props) {
        super(props)
        this.state = { loaded: false, respuestas: "", descRespuesta: "", tipoCampo: "" }
        this.state.respuestas = this.props.record.respuesta;
        this.state.tipoCampo = this.props.record.pregunta_.tipoCampo;
        this.state.descRespuesta = '';
        this.state.respuestaIndicadorComplementario = this.props.record.pregunta_.respuestaIndicadorComplementario;
    }
    componentDidMount() {
    }

    render() {
        const { translate } = this.props;
        if (this.state.loaded === false && this.state.tipoCampo === "Selección Múltiple") {
            let respuestaSelMul;
            this.state.respuestaIndicadorComplementario.forEach(element => {
                if (element.originId == this.state.respuestas)
                    respuestaSelMul = element.respuesta
            });
            return (
                <div style={{borderRight: '1px solid rgba(224, 224, 224, 1)', padding:'1px'}}>
                    <p style={{ fontSize: 0.875 + 'rem', textAlign: 'left', fontWeight: 400, fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }}>
                        {respuestaSelMul} </p>
                </div>
            )
        } else {
            return (
                <div style={{borderRight: '1px solid rgba(224, 224, 224, 1)', padding:'1px'}}>
                    <p style={{ fontSize: 0.875 + 'rem', textAlign: 'right', fontWeight: 400, fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif', marginRight:'26px' }}>
                        {this.state.respuestas} </p>
                </div>
            )
        }
    }
}

export default Respuestas;