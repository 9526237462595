import React, { useState } from 'react';
import {
    Toolbar, CardActions, SelectField, NumberField, ShowController, ShowView, TextField, DateField,
    ReferenceField, BooleanField, TabbedForm, ListButton, EditButton, ArrayField,
    SingleFieldList, FormTab, FunctionField, ReferenceArrayField, Datagrid
} from 'react-admin';
import { AField } from "./../../../fields/AField"
import { PRField } from './../../../fields/PRField';
import { withStyles } from '@material-ui/core';
import { styles } from './../../../EstilosGrid';
import config from '../../../config/config';
import { translate } from 'react-admin';
import ElementsTable from "./../localHelpers/ElementsTable"
import TableWithPaginationShow from "./../localHelpers/TableWithPaginationShow"
let urlAppjs = config.UrlServer;


const toolbarStyles = {
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
};

const cardActionStyle = {
    zIndex: 2,
    display: 'inline-block',
    float: 'right',
};


const PostShowActions = ({ basePath, data }) => (
    <CardActions style={cardActionStyle}>
        <EditButton basePath={basePath} record={data} />
        {/* Add your custom actions */}
        <ListButton basePath={basePath} />
    </CardActions>
);

const CustomToolbar = withStyles(toolbarStyles)(props => (
    <Toolbar {...props}>
        <span></span>

    </Toolbar>

));
const tipoMantenimientos = [
    { id: "ru", name: "Rutinario" },
    { id: "pe", name: "Periódico" },
    { id: "re", name: "Rehabilitación" },
    { id: "ca", name: "Cambio" }
];

const numberFixer = (value) => {
    if (value % 1 == 0) {
        return value
    } else {
        return value.toFixed(2)
    }
}

export const CostosShow = translate(withStyles(styles)(({ classes, record, ...props }) => {
    const { translate } = props;

    return (
        <ShowController {...props} title="Información Costos">
            {controllerProps =>
                <ShowView {...props} {...controllerProps} title="dynatest.resources.costos.title.showTitle" actions={<PostShowActions />}>
                    <TabbedForm toolbar={<CustomToolbar />}>
                        <FormTab label="dynatest.resources.costos.tabs.informacion">

                            <ReferenceField
                                label="resources.costos.fields.unidadFuncional"
                                source="unidadFuncional"
                                reference="unidad_funcionals"
                                linkType={false}
                                formClassName={classes.grid_cont3}
                                fullWidth
                            >
                                <TextField label="" source="fullCodigos" />
                            </ReferenceField>
                            {controllerProps.record && controllerProps.record.tramo !== null &&
                                <ReferenceField
                                    label="resources.costos.fields.tramo"
                                    source="tramo"
                                    reference="tramos"
                                    linkType={false}
                                    fullWidth
                                    formClassName={classes.grid_cont3}
                                >
                                    <TextField label="" source="fullCodigos" />
                                </ReferenceField>
                            }
                            <BooleanField
                                label="dynatest.resources.costos.boolean.rango"
                                source="rango"
                                formClassName={classes.grid_cont3}
                            />
                            <div formClassName={classes.grid_cont3}></div>
                            {controllerProps.record && controllerProps.record.segmento !== null &&
                                <ReferenceField
                                    label="resources.costos.fields.segmento"
                                    source="segmento"
                                    reference="segmentos"
                                    linkType={false}
                                    fullWidth
                                    formClassName={classes.grid_cont3}
                                >
                                    <TextField label="" source="fullCodigos" />
                                </ReferenceField>
                            }
                            {controllerProps.record && controllerProps.record.rango &&
                                <PRField
                                    label="resources.costos.fields.abInicio"
                                    source="abInicio"
                                    fullWidth
                                    formClassName={classes.grid_cont3}
                                />
                            }
                            {controllerProps.record && controllerProps.record.rango &&
                                <PRField
                                    label="resources.costos.fields.abFin"
                                    source="abFin"
                                    fullWidth
                                    formClassName={classes.grid_cont3}
                                />
                            }
                            <ReferenceField
                                label="resources.costos.fields.sistema"
                                source="sistema"
                                reference="sistemas"
                                linkType={false}
                                fullWidth
                                formClassName={classes.grid_cont3}
                            >
                                <TextField label="" source="nombre" />
                            </ReferenceField>
                            <TextField
                                label="resources.costos.fields.genericTipoElemento"
                                source="genericTipoElemento"
                                fullWidth
                                formClassName={classes.grid_cont3}
                            />
                            <FunctionField
                                label="resources.costos.fields.costoTotal"
                                source="costoTotal"
                                fullWidth
                                formClassName={classes.grid_cont3}
                                render={record => `$${numberFixer(record.costoTotal)}`}
                            />
                            <br />
                            {controllerProps.record &&
                                <TableWithPaginationShow
                                    label={translate('resources.costos.fields.elementos')}
                                    identityColumn="@id"
                                    columnNames={[
                                        { id: 'codigo', name: translate('dynatest.resources.costos.tableDatos.columnNames.codigo'), },
                                        { id: 'tipoMantenimiento', name: translate('dynatest.resources.costos.tableDatos.columnNames.tipoMantenShow'), },
                                        { id: 'fechaMantenimiento', name: translate('dynatest.resources.costos.tableDatos.columnNames.fechaMantenShow') },
                                        { id: 'costoMantenimiento', name: translate('dynatest.resources.costos.tableDatos.columnNames.costoMantenimiento'), },
                                        { id: 'razonSocial', name: translate('dynatest.resources.costos.tableDatos.columnNames.razonSocial'), },
                                        { id: 'calificacion', name: translate('dynatest.resources.costos.tableDatos.columnNames.calificacion'), },
                                        { id: 'observaciones', name: translate('dynatest.resources.costos.tableDatos.columnNames.observaciones'), },
                                    ]}
                                    formatColumn={[
                                        { id: 'tipoMantenimiento', formatTo: 'mantenimiento' },
                                        { id: 'fechaMantenimiento', formatTo: 'date' },
                                        { id: 'costoMantenimiento', formatTo: 'currency' },
                                        { id: 'calificacion', formatTo: "number" }
                                    ]}
                                    arrayData={controllerProps.record.costoElementos}
                                />
                            }
                            {/* <ArrayField
                                source="costoElementos"
                                style={{ width: '100%' }}
                                label=" "
                            >
                                <Datagrid>
                                    <TextField disabled label="Código en Inventario" source="codigo" />
                                    <SelectField label="Tipo" source="tipoMantenimiento" choices={tipoMantenimientos} />
                                    <DateField label="Fecha" source="fechaMantenimiento" />
                                    <AField label="Costo" source="costoMantenimiento" />
                                    <TextField label="Proveedor" source="proveedor_.razonSocial" />
                                    <NumberField label="Calificación" source="calificacion" />
                                    <TextField label="Observaciones" source="observaciones" />
                                </Datagrid>
                            </ArrayField> */}
                        </FormTab>
                        <FormTab label="dynatest.resources.costos.tabs.auditoria">
                            <DateField source="createdAt" label="resources.costos.fields.createdAt" fullWidth formClassName={classes.grid_cont4} showTime/>
                            <TextField source="createdBy" label="resources.costos.fields.createdBy" fullWidth formClassName={classes.grid_cont4} />
                            <span></span>
                            <DateField source="updatedAt" label="resources.costos.fields.updatedAt" fullWidth formClassName={classes.grid_cont4} showTime/>
                            <TextField source="updatedBy" label="resources.costos.fields.updatedBy" fullWidth formClassName={classes.grid_cont4} />
                            <span></span>
                        </FormTab>
                    </TabbedForm>
                </ShowView>
            }
        </ShowController>
    )
}));