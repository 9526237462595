import React, { useState } from 'react';
import {
    NumberInput, ReferenceInput, ArrayInput, SimpleFormIterator, ImageInput, ImageField,
    SelectInput, DateInput, ShowButton, CardActions, ListButton,
    TextInput, BooleanInput, minLength, AutocompleteInput
} from 'react-admin';
import { Edit } from './../../Edit';
import { TabbedForm } from './../../TabbedForm';
import { PRInput } from '../../../inputs/PRInput';
import Grid from '@material-ui/core/Grid';
import { abValidationInicio, validarDosDecimales, latMininima, lngMininima, Altitud } from '../../validacionesInputs';
import { withStyles } from '@material-ui/core';
import {
    required,
    FormTab,
    maxValue,
    maxLength
} from 'react-admin';
import { minValue } from 'ra-core';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { styles } from './../../../EstilosGrid';
import ReferenceImageButton from './../ReferenceImageButton';
import AbsSegmento from '../../AbsSegmento';
import CoordenadasProyecto from '../../CoordenadasInventario';
import config from '../../../config/config';
import GetAppIcon from '@material-ui/icons/GetApp';
import Button from '@material-ui/core/Button';
import ReferenceImageButtonPeru from './ReferenceImageButtonPeru';

let urlAppjs = config.UrlServer;

const payload = {
    pagination: { page: 1, perPage: 10 },
    sort: { field: 'id', order: 'DESC' },
};



export const ElementoSenalizacionPeruEdit = withStyles(styles)(({ classes, record, ...props }) => {


    // const ValidarElemento =() =>{         
    //     if(recarga === 0)
    //     {
    //         fetch(urlAppjs+ '/elementoSenal/')
    //         .then((response) => {
    //             console.log("REST");
    //             return response.json()
    //         })
    //         .then((response) => {
    //             setOpcion(response);    
    //             console.log(response)
    //         })
    //     }
    // }


    const [unidads, setUnidad] = useState();
    const [opciones, setOpcion] = useState([{ id: 1, name: '' }]);
    const ValidarUnidad = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setUnidad(value)
    }


    const Unidades = { unidadFuncional: unidads };

    const [tramos, setTramo] = useState();

    const ValidarTramo = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setTramo(value)

    }


    const Tramoss = { tramo: tramos };

    /* VALIDAR LOS RANGOS QUE PUEDEN TENER LAS LATITUDES Y LONGITUDES */
    const [elemento, setElementos] = useState();
    const ValidarElemento = (value) => {
        setElementos(value)

    }

    const [segmentos, setSegmento] = useState();
    const ValidarSegmento = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setSegmento(value)
        let idSegmento = value;
        idSegmento = idSegmento.split('/');
    }

    /*VALORES POR DEFECTO DEL ARRAY DE COORDENADAS*/
    const [latitudMinimaArray, setLatitudArray] = useState();
    const LatitudMinimaArray = (value) => {
        setLatitudArray(value)
    }
    const [longitudMinimaArray, setLongitudArray] = useState();
    const LongitudMinimaArray = (value) => {
        setLongitudArray(value)

    }
    /*FIN DE LOS VALORES POR DEFECTO DEL ARRAY DE COORDENADAS*/

    const [modeloVertical, setModeloVertical] = useState('');
    const ValidarSubSenal = (value) => {
        setModeloVertical(value)
    }

    const [mostrarCampos, SetmostrarCampo] = useState('');
    const mostrarCampo = (value) => {
        SetmostrarCampo(value)
    }

    const [subElemento, setSubElemento] = useState('')
    const SubElemento = (value) => {
        setSubElemento(value)

    }

    const [imagenFlotante, setImagenFlotante] = useState([
        { tipoImagen: { nombre: 'CARGANDO' } },
        { tipoImagen: { nombre: 'CARGANDO' } },
        { tipoImagen: { nombre: 'CARGANDO' } },
        { tipoImagen: { nombre: 'CARGANDO' } },
        { tipoImagen: { nombre: 'CARGANDO' } },
        { tipoImagen: { nombre: 'CARGANDO' } }
    ])
    // const [imagenFlotante1, setImagenFlotante1] = useState('')
    // const [imagenFlotante2, setImagenFlotante2] = useState('')
    // const [imagenFlotante3, setImagenFlotante3] = useState('')
    // const [imagenFlotante4, setImagenFlotante4] = useState('')
    // const [imagenFlotante5, setImagenFlotante5] = useState('')
    const ImagenFlotante = (value) => {

        if (value !== undefined && value !== '') {

            setImagenFlotante(value)
            // setImagenFlotante1(value[1].src)
            // setImagenFlotante2(value[2].src)
            // setImagenFlotante3(value[3].src)
            // setImagenFlotante4(value[4].src)
            // setImagenFlotante5(value[5].src)
            // return <div></div>        
        }
    }
    /*VALIDACIÓN DE IMAGENES PREDETERMINADAS */
    const [images, setImagen] = useState('')
    const Imagenes = (value) => {
        if (value !== undefined) {
            // let valor = value.split('/')
            // valor = valor[2]
            // let imag = subElemento.split('/'); 
            // imag = imag[2]

            // if(imag === 'SI'){
            //     setImagen('/imagenes/senalesPeru/senalesInformativas/' + valor + '.png')
            // }
            // if(imag === 'SP'){
            //     setImagen('/imagenes/senalesPeru/senalesPreventivas/' + valor + '.png')
            // }
            // if(imag === 'SR'){
            //     setImagen('/imagenes/senalesPeru/senalesReglamentarias/' + valor + '.png')
            // }

        }

    }
    const Imagenes1 = (value) => {
        if (value !== undefined) {
            let valor = value.split('/')
            valor = valor[2]
            setImagen('/imagenes/senalesPeru/' + valor + '.png')
        }

    }

    const Imagenes2 = (value) => {
        if (value !== undefined) {
            // let valor = value.split('/')
            // valor = valor[2]            
            // setImagen('/imagenes/senalesPeru/TipoSenal/' + valor + '.png')
        }

    }
    /* FIN DE VALIDACIÓN DE IMAGENES PREDETERMINADAS */
    const PostEditActions = ({ basePath, data, resource }) => (
        <CardActions>
            <ButtonFicha record={data} />
            <ShowButton basePath={basePath} record={data} />
            <ListButton basePath={basePath} />
            <ReferenceImageButton src={images} />
        </CardActions>
    );

    /*VALIDAR ESTADO DE REEMPLAZO*/
    const [estado, setEstado] = useState(false);
    const Estado = (value) => {
        if (value === 'Nuevo') {
            setEstado(true)
        }
    }
    /* FIN VALIDAR ESTADO DE REEMPLAZO*/
    const ButtonFicha = ({ record }) => {
        if (record != undefined) {
            setSenalId(record['@id']);
        }
        return (
            <Button variant="secondary" id="generarInforme" onClick={ButtonFichaOnclick} style={{ color: '#3f51b5' }}>
                <GetAppIcon /> Ficha
            </Button>
        )
    };
    const [senalId, setSenalId] = useState(0);
    const ButtonFichaOnclick = ({ record }) => {
        let senalId1 = senalId;
        let senalIdReplace = parseInt(senalId1.replace("/elemento_senalizacion_perus/", ""));
        const dataPost = new FormData();
        dataPost.append('senalId', senalIdReplace);
        fetch(urlAppjs + '/reportFicha/', {
            method: 'POST',
            body: dataPost
        })
            .then(res => res.text())
            .then(datos => {
                if (datos == "Sin datos") {
                    alert("No se encontraron datos.");
                } else {
                    var myWindow = window.open('', '', 'width=800,height=600');
                    if (myWindow == null || typeof (myWindow) == 'undefined') {
                        alert('Por favor deshabilita el bloqueador de ventanas emergentes y vuelve a hacer clic en "Generar Reporte".');
                    }
                    else {
                        myWindow.document.write(datos);
                        myWindow.document.close();
                        myWindow.focus();
                        setTimeout(function () { myWindow.print(); }, 2000);
                    }
                }
            });
    };

    return (
        <Edit {...props} title="Editar señal" actions={<PostEditActions />}>
            <TabbedForm redirect="list" >
                {/* ------ ELEMENTO SEÑAL ------ */}
                <FormTab label="IDENTIFICACIÓN" >
                    <CoordenadasProyecto />
                    <ReferenceInput label="Elemento" source="tipoElementoSenalPeru" disabled reference="tipo_elemento_senal_perus" validate={[required(), Imagenes1]} fullWidth onChange={ValidarElemento} filter={{ complementaria: true }} sort={{ field: 'nombre', order: 'ASC' }}>
                        <SelectInput optionText="nombre" fullWidth />
                    </ReferenceInput>
                    {/* {(elementos && */}
                    <SelectInput label="Lado" source="margen" allowEmpty emptyValue="" validate={[required()]} fullWidth choices={[
                        { id: 'Derecho', name: 'Derecho' },
                        { id: 'Izquierdo', name: 'Izquierdo' },
                        { id: 'Berma central', name: 'Berma central' },
                        { id: 'Ejes', name: 'Ejes' },
                    ]} formClassName={classes.grid_cont6s} />
                    <ReferenceInput label="Tipo de elemento" source="tipoSenalPeru" reference="tipo_senal_perus" fullWidth validate={[required(), ValidarSubSenal, SubElemento, Imagenes2]} sort={{ field: 'nombre', order: 'ASC' }} formClassName={classes.grid_cont6}>
                        <SelectInput optionText="nombre" />
                    </ReferenceInput>
                    {subElemento &&
                        <ReferenceInput label="Código de señal" source="senalVerticalPeru" filterToQuery={searchText => ({ id: searchText })} reference="senal_vertical_perus" fullWidth validate={[required(), Imagenes]} sort={{ field: 'id', order: 'ASC' }} formClassName={classes.grid_cont3s} filter={{ tipoSenalPeru: modeloVertical }}>
                            <AutocompleteInput options={{ fullWidth: true }} optionText="ids" />
                        </ReferenceInput>
                    }
                    {subElemento &&
                        <ReferenceInput label="Significado de señal" source="senalVerticalPeru" fullWidth filterToQuery={searchText => ({ nombre: searchText })} reference="senal_vertical_perus" validate={[required()]} sort={{ field: 'nombre', order: 'ASC' }} formClassName={classes.grid_cont5} filter={{ tipoSenalPeru: modeloVertical }}>
                            <AutocompleteInput options={{ fullWidth: true }} optionText="nombre" />
                        </ReferenceInput>
                    }
                    {subElemento &&
                        <TextInput label="Descripción del mensaje" source="descripcionMensaje" fullWidth formClassName={classes.grid_cont3} />
                    }
                    <TextInput source="tipoSenal" className={classes.ocultar} />
                    <SelectInput source="estado" label="Sentido del tránsito" source="sentidoTransito" choices={[
                        { id: "Únicamente Creciente (UC) ", name: 'Únicamente Creciente (UC)' },
                        { id: "Únicamente Decreciente (UD)", name: 'Únicamente Decreciente (UD)' },
                    ]} fullWidth />

                    {/* ---------- LOCALIZACIÓN --------- */}
                    <Typography variant="h3" component="h3">Localización</Typography>
                    <Divider fullWidth style={{ marginTop: '10px' }} />
                    <ReferenceInput fullWidth label="Unidad Funcional" source="unidadFuncional" filterToQuery={searchText => ({ codigo: searchText })} reference="unidad_funcionals" validate={[required(), ValidarUnidad]} sort={{ field: 'codigo', order: 'ASC' }} formClassName={classes.grid_cont4s}>
                        <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
                    </ReferenceInput>
                    {unidads &&
                        <ReferenceInput label="Tramo" source="tramo" filterToQuery={searchText => ({ codigo: searchText })} fullWidth reference="tramos" validate={[required(), ValidarTramo]} sort={{ field: 'codigo', order: 'ASC' }} filter={Unidades} formClassName={classes.grid_cont4}>
                            <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
                        </ReferenceInput>
                    }
                    {tramos &&
                        <ReferenceInput label="Segmento" source="segmento" filterToQuery={searchText => ({ codigo: searchText })} reference="segmentos" fullWidth validate={[required(), ValidarSegmento]} filter={Tramoss} sort={{ field: 'codigo', order: 'ASC' }} formClassName={classes.grid_cont4}>
                            <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
                        </ReferenceInput>
                    }

                    {segmentos && <AbsSegmento segmento={segmentos} />}

                    {/* )}       */}
                    {segmentos &&
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Typography variant="h3" component="h3" className={classes.coord}>Coordenadas</Typography>
                                <Divider fullWidth style={{ marginTop: '10px' }} />
                            </Grid>
                        </Grid>
                    }
                    {segmentos && <PRInput label="Progresiva" id="ingresoAbInicio" source="abInicio" fullWidth validate={[abValidationInicio, required()]} formClassName={classes.grid_cont3s} />}
                    {segmentos && <NumberInput label="Latitud" id="LatitudInicial" source="ubicacionMapa[0]['lat']" fullWidth validate={[required(), latMininima, LatitudMinimaArray]} formClassName={classes.grid_cont3} />}
                    {segmentos && <NumberInput label="Longitud" id="LongitudInicial" source="ubicacionMapa[0]['lng']" fullWidth validate={[required(), lngMininima, LongitudMinimaArray]} formClassName={classes.grid_cont3} />}
                    {segmentos && <NumberInput label="Altura (m.s.n.m)" id="altitudInicial" source="alturaIni" fullWidth validate={[Altitud]} formClassName={classes.grid_cont3} />}

                    {segmentos &&
                        <ArrayInput label="" source="ubicaciones" className={classes.ocultar}
                            defaultValue={[
                                {
                                    lat: latitudMinimaArray,
                                    lng: longitudMinimaArray
                                }
                            ]}
                        >
                            <SimpleFormIterator disableAdd disableRemove >
                                <NumberInput label="Latitud" source="lat" fullWidth />
                                <NumberInput label="Longitud" source="lng" fullWidth />
                            </SimpleFormIterator>
                        </ArrayInput>
                    }
                </FormTab>
                {/* -------------- CARACTERÍSTICAS ---------- */}
                <FormTab label="Características" redirect="list" >
                    {(modeloVertical &&
                        <SelectInput fullWidth label="Tipo de Panel" source="materialPlaca" allowEmpty emptyValue="" fullWidth choices={[
                            { id: 'Fibra de vidrio', name: 'Fibra de vidrio' },
                            { id: 'Fierro galvanizado', name: 'Fierro galvanizado' },
                            { id: '(ACP)', name: '(ACP)' },
                            { id: 'Aluminio', name: 'Aluminio' },
                            { id: 'Otro', name: 'Otro' },
                            { id: 'Compuesto de aluminio', name: 'Compuesto de aluminio' },
                        ]} validate={[required()]} formClassName={classes.grid_cont4s} />
                    )}
                    {(modeloVertical &&
                        <SelectInput label="Tipo de poste" source="tipoPoste" sort={{ field: 'name', order: 'Asc' }} allowEmpty emptyValue="" fullWidth choices={[
                            { id: 'Metálico', name: 'Metálico' },
                            { id: 'Concreto', name: 'Concreto' },
                            { id: 'Mixto', name: 'Mixto' },
                            { id: 'Estructura puente', name: 'Estructura puente' },
                            { id: 'Otros', name: 'Otros' },
                        ]} validate={[required(), mostrarCampo]} formClassName={classes.grid_cont4} />
                    )}
                    {(modeloVertical && subElemento === '/tipo_senal_perus/SI' &&
                        <SelectInput label="Tipo de soporte" source="tipoSoporte" allowEmpty emptyValue="" sort={{ field: 'name', order: 'ASC' }} fullWidth choices={[
                            { id: 'Poste simple', name: 'Poste simple' },
                            { id: 'Biposte', name: 'Biposte' },
                            { id: 'Tipo bandera', name: 'Tipo bandera' },
                            { id: 'Tipo Pórtico ', name: 'Tipo Pórtico ' },
                            { id: 'Otro ', name: 'Otro ' },
                        ]} validate={[required()]} formClassName={classes.grid_cont4} />
                    )}
                    {(subElemento === '/tipo_senal_perus/SI' &&
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Typography variant="h3" component="h3" className={classes.coord}>Dimensiones Estructura de Soporte Señales Informativas</Typography>
                                <Divider fullWidth style={{ marginTop: '10px' }} />
                            </Grid>
                        </Grid>
                    )}
                    {(subElemento === '/tipo_senal_perus/SI' &&
                        <NumberInput label="Ancho (m)" source="anchoSoporte" fullWidth validate={[required(), minValue(0), maxValue(10), validarDosDecimales]} formClassName={classes.grid_cont4s} />
                    )}
                    {(subElemento === '/tipo_senal_perus/SI' &&
                        <NumberInput label="Altura (m)" source="alturaSoporte" fullWidth validate={[required(), minValue(0), maxValue(10), validarDosDecimales]} formClassName={classes.grid_cont4} />
                    )}
                    {(subElemento === '/tipo_senal_perus/SI' &&
                        <TextInput label="Diámetro (pulg)" source="dimensionSoporte" fullWidth validate={[required()]} formClassName={classes.grid_cont4} />
                    )}
                    {(
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Typography variant="h3" component="h3" className={classes.coord}>Dimensiones Pedestal</Typography>
                                <Divider fullWidth style={{ marginTop: '10px' }} />
                            </Grid>
                        </Grid>
                    )}
                    {(
                        <NumberInput label="Ancho (m)" source="anchoPedestal" fullWidth validate={[required(), minValue(0), maxValue(10), validarDosDecimales]} formClassName={classes.grid_cont4s} />
                    )}
                    {(
                        <NumberInput label="Altura (m)" source="alturaPedestal" fullWidth validate={[required(), minValue(0), maxValue(10), validarDosDecimales]} formClassName={classes.grid_cont4} />
                    )}
                    {(
                        <NumberInput label="Largo (m)" source="largoPedestal" fullWidth validate={[required(), minValue(0), maxValue(10), validarDosDecimales]} formClassName={classes.grid_cont4} />
                    )}
                    {(
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Typography variant="h3" component="h3" className={classes.coord}>Dimensiones de Panel</Typography>
                                <Divider fullWidth style={{ marginTop: '10px' }} />
                            </Grid>
                        </Grid>
                    )}
                    {(
                        <NumberInput label="Ancho (m)" source="anchoPanel" fullWidth validate={[required(), minValue(0), maxValue(10), validarDosDecimales]} formClassName={classes.grid_cont6s} />
                    )}
                    {(
                        <NumberInput label="Altura (m)" source="alturaPanel" fullWidth validate={[required(), minValue(0), maxValue(10), validarDosDecimales]} formClassName={classes.grid_cont6} />
                    )}

                </FormTab>
                {/* ------ DETALLES  ------ */}
                <FormTab label="Detalles" redirect="list" >
                    <SelectInput source="estado" choices={[
                        { id: "Bueno", name: 'Bueno' },
                        { id: "Malo", name: 'Malo' },
                        { id: "Nuevo", name: 'Nuevo' },
                        { id: "Regular", name: 'Regular' },
                    ]} fullWidth validate={[required(), Estado]} formClassName={classes.grid_cont4s} />
                    {/* ------------------ VALIDACIÓN DE ESTADO -----------------  */}
                    {estado &&
                        <SelectInput label="Condición" source="condicion" choices={[
                            { id: "Instalación", name: 'Instalación' },
                            { id: "Mantenimiento", name: 'Mantenimiento' },
                        ]} fullWidth validate={[required()]} formClassName={classes.grid_cont4} />
                    }
                    {/* {estado &&                        
                        <SelectInput source="elementoReemplazo_id" choices={choices} optionText={optionRenderer} formClassName={classes.grid_cont4} fullWidth/>                                             
                    }  */}
                    {!estado && <div></div>}

                    <Grid container spacing={24}>
                        <Grid item xs={4}>
                            <DateInput label="Fecha de instalación" source="fechaInstalacion" fullWidth formClassName={classes.grid_cont4s} validate={[required()]} />
                        </Grid>
                        <Grid item xs={5}>
                            <ReferenceInput label="Proveedor" source="proveedor" reference="proveedors" sort={{ field: 'razonSocial', order: 'ASC' }}
                                filterToQuery={searchText => ({ razonSocial: searchText })} filter={{ proyecto: props.proyecto, suministros: 1, sistema: 'SEN' }} validate={[required()]}>
                                <AutocompleteInput options={{ fullWidth: true }} optionText="razonSocial" fullWidth />
                            </ReferenceInput>
                        </Grid>
                    </Grid>
                    <BooleanInput label="¿Incluir este elemento a mantenimiento?" source="mantenimiento" fullWidth />
                    <TextInput source="observaciones" fullWidth validate={[required(), minLength(5), maxLength(200)]} />
                </FormTab>
                <FormTab label="FOTOGRAFÍAS">
                    {/* <ImageInput multiple={true} style={{display :'none'}} source="images" label=" " maxSize={1000000000} accept="image/jpeg, image/png">
                        <ImageField source="src" title="title" />
                    </ImageInput> */}
                    <Grid container spacing={24}>
                        {/* <Grid item xs={4}>
                            <Grid container spacing={24}> 
                                <ImageInput multiple={true} style={{display :'none'}} source="images[0]" label=" " maxSize={1000000000} accept="image/jpeg, image/png">
                                    <ImageField source="src" title="title" />
                                </ImageInput>
                                <TextInput label="Tipo de imagen" source="images[0]tipoImagen.nombre" fullWidth/>
                            </Grid>
                        </Grid>   */}
                        <Grid item xs={8}>
                            <ArrayInput label="" source="images" validate={[ImagenFlotante]}>
                                <SimpleFormIterator disableAdd disableRemove >
                                    <ImageInput multiple={true} source="images" label=" " maxSize={1000000000} accept="image/jpeg, image/png">
                                        <ImageField source="src" title="title" />
                                    </ImageInput>
                                    <TextInput disabled label="Tipo de imagen" source="tipoImagen.nombre" fullWidth />
                                </SimpleFormIterator>
                            </ArrayInput>
                        </Grid>
                        <Grid item xs={4}>
                            <ReferenceImageButtonPeru
                                src={imagenFlotante[0].src}
                                p={imagenFlotante[0].tipoImagen.nombre}

                                src2={imagenFlotante[1].src}
                                p2={imagenFlotante[1].tipoImagen.nombre}

                                src3={imagenFlotante[2].src}
                                p3={imagenFlotante[2].tipoImagen.nombre}

                                src4={imagenFlotante[3].src}
                                p4={imagenFlotante[3].tipoImagen.nombre}

                                src5={imagenFlotante[4].src}
                                p5={imagenFlotante[4].tipoImagen.nombre}

                                src6={imagenFlotante[5].src}
                                p6={imagenFlotante[5].tipoImagen.nombre}
                            />
                        </Grid>
                    </Grid>

                </FormTab>
            </TabbedForm>
        </Edit>

    )
});