import React, { Fragment } from 'react';
import { List, Datagrid, TextField, EditButton, ShowButton } from 'react-admin';
import {  BulkDeleteWithConfirmButton  } from 'react-admin';

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);

export const FisuraList = props => (
    <List {...props} title="Lista fisuras" bulkActionButtons={<PostBulkActionButtons />}>
        <Datagrid>          
            <TextField source="tipo" label="Fisura"/>                      
            <TextField source="factor" label="Factor"/>                      
            <TextField label=" "/>                                              
            <EditButton />
        </Datagrid>
    </List>
);