import React, { useState, Fragment  } from 'react';
import { List, Datagrid, TextField, EditButton, ShowButton, ReferenceField, Filter, ReferenceInput, AutocompleteInput,
         SelectInput, BulkDeleteWithConfirmButton, Loading  } from 'react-admin';
import { PRField } from './../../../fields/PRField';
import { PField } from './../../../fields/PField';
import { withStyles } from '@material-ui/core';
import { styles } from './../../../EstilosGrid';
import { translate } from 'react-admin';
import { CardActions, CreateButton, ExportButton } from 'react-admin';
export const ElementoRedList =  translate(({ permissions, ...props }) => {
    const { translate } = props;
    const FiltroRed =(props) => {
        const [uf, setUf] = useState('none');
        const ufSet = (value) => {
            setUf(value);
        }
        const [tramo, setTramo] = useState('none');
        const tramoSet = (value) => {
            setTramo(value);
    }
    return(
        <Filter {...props}>
            <ReferenceInput 
            id="unidadFuncional"
            label="resources.redes.fields.unidadFuncional" 
            source="unidadFuncional"  
            reference="unidad_funcionals" 
            filterToQuery={searchText => ({ codigo: searchText })} 
            sort={{field : 'codigo', order : 'asc'}}
            validate={[ufSet]}
            alwaysOn
            fullWidth 
        >
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
        <ReferenceInput 
            label="resources.redes.fields.tramo" 
            source="tramo" 
            alwaysOn 
            reference="tramos" 
            filter={{ unidadFuncional: uf}}
            validate={[tramoSet]}
            filterToQuery={searchText => ({ codigo: searchText })} 
            sort={{ 
                field: 'codigo', 
                order: 'ASC' ,
                
            }}>
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
        <ReferenceInput 
            label="resources.redes.fields.segmento" 
            source="segmento" 
            alwaysOn 
            reference="segmentos" 
            sort={{ field: 'codigo', order: 'ASC' }}
            filter={{tramo : tramo}}
            filterToQuery={searchText => ({ codigo: searchText })} 
        >
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
            <ReferenceInput 
                label="resources.redes.fields.tipoElemento" 
                source="tipoElemento"
                alwaysOn  
                reference="tipo_elementos"
                filter={{sistema: "RDS"}}
                sort={{ field: 'nombre', order: 'ASC' }}
                filterToQuery={searchText => ({ nombre: searchText })} 
            >
                <AutocompleteInput  options={{fullWidth : true}} optionText="nombre"  />
            </ReferenceInput>
            <SelectInput 
                label="resources.redes.fields.estado" 
                source="estado" 
                choices={[
                    { id: translate('dynatest.resources.redes.select.estado.Existente'), name: "dynatest.resources.redes.select.estado.Existente" },
                    { id: translate('dynatest.resources.redes.select.estado.Reemplazo'), name: "dynatest.resources.redes.select.estado.Reemplazo" },
                    { id: translate('dynatest.resources.redes.select.estado.Nuevo'), name: "dynatest.resources.redes.select.estado.Nuevo" },                                   
                ]}   
                alwaysOn 
                style={{width: '200px'}}
            />
             <SelectInput label="resources.redes.fields.estados" source="estados" choices={[
                 { id: translate('dynatest.resources.redes.select.estados.Activo'), name: "dynatest.resources.redes.select.estados.Activo" },
                 { id: translate('dynatest.resources.redes.select.estados.Inactivo'), name: "dynatest.resources.redes.select.estados.Inactivo" },
                ]}  fullWidth alwaysOn
            />
        </Filter>
        )
    };
    const PostBulkActionButtons = (props) => (
        <Fragment>
            <BulkDeleteWithConfirmButton {...props} label="resources.redes.fields.Borrar" />
        </Fragment>
    );
    const ListAction = ({ basePath, data, resource }) => (
        <CardActions>
            <CreateButton basePath={basePath} />
            <ExportButton basePath={basePath}/>
        </CardActions>
    );
    const ListAction2 = ({ basePath, data, resource }) => (
        <CardActions>
            <CreateButton basePath={basePath} />
        </CardActions>
    );
    if (!permissions) return <Loading />;  
    
    return (
        <List {...props} 
        sort={{ field: 'updatedAt', order: 'DESC' }} 
        actions={permissions['redes']  == 1 ? <ListAction /> : permissions['redes']  == 2 ? <ListAction2 />: null}
        title="dynatest.resources.redes.title.listTitle" filters={<FiltroRed/>}
        bulkActionButtons={permissions['redes']  == 1 ? <PostBulkActionButtons />: null}
        >
        <Datagrid>
            <TextField label="resources.redes.fields.fullCodigo" source="smartCode" sortable={true}/>
            <ReferenceField label="resources.redes.fields.tipoElemento" source="tipoElemento"  reference="tipo_elementos" linkType={false}><TextField source="nombre"  /></ReferenceField>            
            <PRField label="resources.redes.fields.abscisa" source="abInicio"/>
            <PField label="resources.redes.fields.pr" source="pInicio"/>
            <TextField label="resources.redes.fields.margen" source="margen"/>
            <TextField label="resources.redes.fields.estados" source="estados" sortable/>
            {permissions['redes'] == 1 || permissions['redes'] == 2 || permissions['redes'] == 4 ? <ShowButton/>: null}
            {permissions['redes'] == 1 || permissions['redes'] == 2 ? <EditButton/>: null}                                 
        </Datagrid>
    </List>
)});