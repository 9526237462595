import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TextInput, SelectInput,ReferenceInput,AutocompleteInput,NumberInput,minValue,maxValue,BooleanInput,ReferenceField,TextField,Datagrid } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import config from '../../../config/config';
import { translate } from 'react-admin';

let urlAppjs = config.UrlServer;

class Trayectorias extends React.Component{

    constructor(props){
        super(props)
        this.state = {materials:[],titulos: [], record: [],tipo:''}
    }

    componentDidMount(){
        //this.setState({ datoEva: this.props.record })   
        this.setState({ record: this.props.record })  
        this.setState({ tipo: this.props.tipo }) 
        this.setState({ record: this.props.record })
        //this.setState({ tipo: this.props.edit })}
        fetch(urlAppjs+"/materials.json")
        .then((response) => {
            return response.json()
        })
        .then((materials) => {
            this.setState({ materials: materials })
        })
    }
       // console.log(this.props.record) 


    render(){
      const { translate } = this.props;
        const { titulos } = this.state;
        const {record} = this.state;
        const {tipo} = this.state;
        const { materials } = this.state;
        console.log(materials)
        //console.log(this.state)
        let titulos2 =["IRIS (mm/m)","Fricción","Ahuellamiento (mm)","PCI (%)"];
       
        let trayectorias=this.props.record
        // let materials = this.props.record
        let material=[];
        

    //     if(trayectorias){
    //     trayectorias.map((data)=>{
    //             if(data.material!=null && typeof(data.material)==="string"){
    //                 let material=data.material.split('/')
    //                 material.push(material)
    //             }
    //             else{
    //                 material.push('')
    //             }
    //     }) 
    // }
    // let mat = [];
    // let mat1 = [];
    // let mat2 = [];
    // let mat3 = [];
    // materials.map((data,i)=>{
    //   if(data.material === trayectorias.material){
    //      trayectorias.material.push(mat);
    //   }
    //   else if(data.material === trayectorias.material1){
    //     trayectorias.material1.push(mat1);
    //   }
    //   else if(data.material === trayectorias.material2){
    //     trayectorias.material2.push(mat2);
    //   }
    //   else if(data.material === trayectorias.material3){
    //     trayectorias.material3.push(mat3);
    //   }
    // })           
    // console.log(mat)
    if(tipo==="tipo" && trayectorias){
            return(
                
                <React.Fragment style={{width: "100%"}}>
                   <Table aria-label="simple table" fullWidth  style={{width: "50%"}}>
                        <TableHead>
              <TableRow>
                <TableCell align="right" >{translate('dynatest.resources.trayectorias.tableIndicador.nameColumns.Indicador')}</TableCell>
                <TableCell align="right" >{translate('dynatest.resources.trayectorias.tableIndicador.nameColumns.Mejorar')}</TableCell>
                <TableCell align="right" >{translate('dynatest.resources.trayectorias.tableIndicador.nameColumns.Limite')}</TableCell>
              </TableRow>
            </TableHead>
            
                        <TableBody>            
                        <TableRow >
                        <TableCell component="th" align="right" >
                        <h3 style={{marginBottom: 0}}>{translate('dynatest.resources.trayectorias.tableIndicador.nameRows.IRI')}</h3>                    
                        </TableCell>
                        <TableCell  align="right" style={{textAlign:'right'}} fullWidth><span>{trayectorias.iri_mejora}</span></TableCell>
                         <TableCell align="right" style={{textAlign:'right'}} fullWidth><span>{trayectorias.iri_limite}</span></TableCell>
                        </TableRow>
                      <TableRow >
                      <TableCell component="th" align="right">
                        <h3 style={{marginBottom: 0}}>{translate('dynatest.resources.trayectorias.tableIndicador.nameRows.Friccion')}</h3>                    
                        </TableCell>
                      <TableCell align="right" style={{textAlign:'right'}} fullWidth> <span > {trayectorias.friccion_mejora} </span></TableCell> 
                      <TableCell align="right" style={{textAlign:'right'}} fullWidth> <span>{trayectorias.friccion_limite}</span></TableCell> 
                      </TableRow> 
                       <TableRow>
                       <TableCell component="th" align="right">
                        <h3 style={{marginBottom: 0}}>{translate('dynatest.resources.trayectorias.tableIndicador.nameRows.Ahuellamiento')}</h3>                    
                        </TableCell>
                      <TableCell align="right" style={{textAlign:'right'}} fullWidth> <span >{trayectorias.ahuellamiento_mejora}</span> </TableCell>  
                      <TableCell align="right" style={{textAlign:'right'}} fullWidth> <span >{trayectorias.ahuellamiento_limite}</span></TableCell>  
                       </TableRow>
                       <TableRow>
                       <TableCell component="th" align="right" >
                        <h3 style={{marginBottom: 0}}>{translate('dynatest.resources.trayectorias.tableIndicador.nameRows.PCI')}</h3>                    
                        </TableCell>
                      <TableCell align="right" style={{textAlign:'right'}} fullWidth> <span >{trayectorias.pci_mejora}</span></TableCell>  
                      <TableCell align="right" style={{textAlign:'right'}} fullWidth> <span >{trayectorias.pci_limite}</span></TableCell>  
                       </TableRow>
                    
                    </TableBody>     
                        </Table>
                </React.Fragment>
             
            )

    }
    else if (tipo==="mostrar"){
      return(
                
        <React.Fragment style={{width: "100%"}}>
           <Table aria-label="simple table" fullWidth  style={{width: "50%"}}>
                <TableHead>
      <TableRow>
        <TableCell align="right" ></TableCell>
        <TableCell align="right" >{translate('dynatest.resources.trayectorias.tableEspesor.nameColumns.Espesores')}</TableCell>
        <TableCell align="right" >{translate('dynatest.resources.trayectorias.tableEspesor.nameColumns.CodigoMaterial')}</TableCell>
      </TableRow>
    </TableHead>
    
                <TableBody>            
                <TableRow >
                <TableCell component="th" align="right" >
                <h3 style={{marginBottom: 0}}>{translate('dynatest.resources.trayectorias.tableEspesor.nameRows.EspesorCapa1Show')}</h3>                    
                </TableCell>
                <TableCell  align="right" style={{textAlign:'right'}} fullWidth><span>{trayectorias.espesorcap1 ? trayectorias.espesorcap1: ""}</span></TableCell>
                 <TableCell align="right" style={{textAlign:'right'}} fullWidth><span>{trayectorias.material_ ? trayectorias.material_.material: ""}</span></TableCell>
                </TableRow>
              <TableRow >
              <TableCell component="th" align="right">
                <h3 style={{marginBottom: 0}}>{translate('dynatest.resources.trayectorias.tableEspesor.nameRows.EspesorCapa2Show')}</h3>                    
                </TableCell>
              <TableCell align="right" style={{textAlign:'right'}} fullWidth> <span > {trayectorias.espesorcap2 ? trayectorias.espesorcap2: ""} </span></TableCell> 
              <TableCell align="right" style={{textAlign:'right'}} fullWidth> <span>{trayectorias.material1_ ? trayectorias.material1_.material: ""}</span></TableCell> 
              </TableRow> 
               <TableRow>
               <TableCell component="th" align="right">
                <h3 style={{marginBottom: 0}}>{translate('dynatest.resources.trayectorias.tableEspesor.nameRows.EspesorCapa3Show')}</h3>                    
                </TableCell>
              <TableCell align="right" style={{textAlign:'right'}} fullWidth> <span >{trayectorias.espesorcap3 ? trayectorias.espesorcap3: ""}</span> </TableCell>  
              <TableCell align="right" style={{textAlign:'right'}} fullWidth> <span>{trayectorias.material2_ ?trayectorias.material2_.material:""} </span>   </TableCell>  
               </TableRow>
               <TableRow>
               <TableCell component="th" align="right" >
                <h3 style={{marginBottom: 0}}>{translate('dynatest.resources.trayectorias.tableEspesor.nameRows.EspesorCapa4Show')}</h3>                    
                </TableCell>
              <TableCell align="right" style={{textAlign:'right'}} fullWidth> <span >{trayectorias.espesorcap4?trayectorias.espesorcap4:""}</span></TableCell>  
              <TableCell align="right" style={{textAlign:'right'}} fullWidth> <span >{trayectorias.material3_ ?trayectorias.material3_.material:""}</span></TableCell>  
               </TableRow>
            
            </TableBody>     
                </Table>
        </React.Fragment>
     
    )

}
else {
  return(
    <React.Fragment></React.Fragment>
  )
}
}
}

export default translate(Trayectorias);