import React, { Component } from 'react';
import { refreshView } from 'react-admin';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import config from './../config/config'
import { PROYECTO_SELECTED } from '../reducers/proyectoReducer';

let Url = config.UrlFront;
let country = config.Country;

export const proyectoSelected = (proyecto) => ({
    type: PROYECTO_SELECTED,
    proyecto: proyecto
});


class SelectProyectoButton extends Component{
    props = null;
    constructor(props){
        super(props);
        this.props = props;
        this.handleClick = this.handleClick.bind(this);
    } 

    handleClick() {        
        this.props.proyectoSelected(this.props.proyecto);        
        this.props.refreshView();                      
        localStorage.setItem('id_project2',this.props.proyecto.id);    
        localStorage.setItem('id_project',this.props.proyecto.originId);
        localStorage.setItem('codigoProyecto', this.props.proyecto.codigo);
        localStorage.setItem('nombreProyecto', this.props.proyecto.nombre);
        if (document.getElementById("pimsMenu")) {
            document.getElementById("pimsMenu").style.visibility = "visible ";
            localStorage.setItem("visibility", "visible");
        }
        if (country == "PE") {
            window.location.href= Url + 'elemento_senalizacion_perus?map';
        }

    } 
    render() {
        return (
            <Button {...this.props}  variant="contained" color="primary" id="btn-id-project"
                onClick={this.handleClick}>
                {this.props.label}
            </Button>
        );
    }
}

SelectProyectoButton.propTypes = {
    proyectoSelected: PropTypes.func,
    proyecto: PropTypes.object,
};

export default connect(null, {proyectoSelected, refreshView })(SelectProyectoButton);