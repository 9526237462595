import React, { useState, Fragment, useReducer, useEffect } from 'react';
import { Edit } from './../../Edit';
import {
    SelectInput, ReferenceInput, BooleanInput, minValue, maxValue, DateInput, FormTab, TabbedForm, required, BooleanField, Tab,
    FileInput, FileField, Loading, NumberInput, FormDataConsumer, ArrayInput, TextInput, SimpleFormIterator, AutocompleteInput
} from 'react-admin';
import { PRInput } from '../../../inputs/PRInput';
import { PimsElementoActions } from './../../../helpers/PimsElementoActions';
import { Entero, isFechaInvalida } from './../../../helpers/validacionesInputs';
import { styles } from './../../../EstilosGrid';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core';
import config from '../../../config/config';
import { REDUX_FORM_NAME } from 'react-admin';
import { change } from 'redux-form';
import Divider from '@material-ui/core/Divider';
import { CurrencyInput } from './../../../inputs/CurrencyInput';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import TableWithPagination from "./../localHelpers/TableWithPagination"
import NumberFormat from 'react-number-format';
import { translate } from 'react-admin';


import SimpleModal from "./../../../helpers/SimpleModal"

export const CostosEdit = translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const tipoMantenimientos = [
        { id: "ru", name: "dynatest.resources.costos.select.tipoMantenimientos.Rutinario" },
        { id: "pe", name: "dynatest.resources.costos.select.tipoMantenimientos.Periodico" },
        { id: "re", name: "dynatest.resources.costos.select.tipoMantenimientos.Rehabilitacion" },
        { id: "ca", name: "dynatest.resources.costos.select.tipoMantenimientos.Cambio" }
    ];
    const [datosArchivo, setdatosArchivo] = useState([]);
    const [valorGlobal, setValorGlobal] = useState();
    const prepareDatosArchivos = (txt, isGlobal = false) => {
        var array = [];
        var row = [];
        var sumaValorGlobal = 0;
        if (txt && typeof txt == "string") {
            row = txt.split("\n");
            var valorIndividual = (isGlobal && typeof valorGlobal == "string") ? parseFloat(valorGlobal.split("$")[1]) / row.length : null;
            row.map((item, index) => {
                if (index != 0 && item != "") {
                    sumaValorGlobal += parseFloat(item.split(";")[3]);
                    array.push({
                        codigo: item.split(";")[0],
                        tipoMantenimiento: item.split(";")[1],
                        fechaMantenimiento: crearFecha(item.split(";")[2]),
                        costoMantenimiento: isGlobal ? valorIndividual : item.split(";")[3],
                        proveedor: item.split(";")[4],
                        calificacion: item.split(";")[5],
                        observaciones: item.split(";")[6],
                    })
                }
            })
            if (array != datosArchivo) { setdatosArchivo(array); }
        }
        if (!isGlobal) { setValorGlobal(sumaValorGlobal) };
    }


    const emptyValorGlobal = () => {
        setValorGlobal();
    }

    const validarValorGlobal = (value) => {
        setValorGlobal(value);
    }

    const crearFecha = (string) => {
        var splitted = string.split("/");
        var date = new Date(splitted[2], (parseInt(splitted[1]) - 1), splitted[0])
        return date;
    }
    const [getAbscisaInicial, setAbscisaInicial] = useState(0);
    const validarAbscisaInicial = (value) => {
        if (value) {
            if (typeof value == "string") {
                var val = value.split("K")[1].split("+")
                val = val[0] + "." + val[1];
                val = parseFloat(val);
                setAbscisaInicial(val);
            } else {
                setAbscisaInicial(value);
            }
        }
    }

    const [getAbscisaFinal, setAbscisaFinal] = useState(0);
    const validarAbscisaFinal = (value) => {
        if (value) {
            if (typeof value == "string") {
                var val = value.split("K")[1].split("+")
                val = val[0] + "." + val[1];
                val = parseFloat(val);
                setAbscisaInicial(val);
            } else {
                setAbscisaInicial(value);
            }
        }
    }
    const [modalProps, setModalProps] = useState({});
    const [rowToEdit, setRowToEdit] = useState(0);
    const [renderTable, setRenderTable] = useState(true);
    const handleChangeModal = () => {
        modalProps.handleOpen()
    }
    const [unidads, setUnidad] = useState('');
    const ValidarUnidad = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setUnidad(value)
    }
    // RESET VAR MODAL EDIT TABLE
    let editedRowData = [];
    let dataToEdit = [];
    return (
        <Edit  {...props}
            title="dynatest.resources.costos.title.editTitle"
            // actions={<PimsElementoActions />}
            undoable={false}
        >
            <TabbedForm
                redirect="list"
            //toolbar={<CustomToolbar />}
            >
                {/* ------ ELEMENTO DE FLUJO DE CAJA ------ */}
                <FormTab label="dynatest.resources.costos.tabs.identificacion" redirect="list" >
                    <Grid container justify="flex-start" alignItems="flex-end" spacing={24}>
                        <FormDataConsumer>
                            {
                                ({ formData, dispatch, ...rest }) => (
                                    <Fragment>
                                        <Grid item xs={3}>
                                            <ReferenceInput
                                                label="resources.costos.fields.unidadFuncional"
                                                source="unidadFuncional"
                                                reference="unidad_funcionals"
                                                filter={{ pagination: "off" }}
                                                filterToQuery={searchText => ({ codigo: searchText })}
                                                disabled
                                                // onChange={value => dispatch(
                                                //     change(REDUX_FORM_NAME, 'tramo', null)
                                                // )}
                                                validate={[required(),ValidarUnidad]}
                                            >
                                                <SelectInput optionText="fullCodigos" options={{ fullWidth: true }} fullWidth />
                                            </ReferenceInput>
                                        </Grid>
                                        {unidads &&
                                        <Grid item xs={3}>
                                            <ReferenceInput
                                                label="resources.costos.fields.tramo"
                                                source="tramo"
                                                reference="tramos"
                                                disabled
                                                filter={{
                                                    unidadFuncional: (typeof formData !== "undefined") ? formData.unidadFuncional : null,
                                                    pagination: "off"
                                                }}
                                                filterToQuery={searchText => ({ codigo: searchText })}
                                                // onChange={value => dispatch(
                                                //     change(REDUX_FORM_NAME, 'segmento', null)
                                                // )}
                                                validate={[required()]}
                                            >
                                                <SelectInput optionText="fullCodigos" options={{ fullWidth: true }} fullWidth />
                                            </ReferenceInput>
                                        </Grid>
                                        }
                                        <Grid item xs={3}>
                                            {formData && formData.rango == false &&
                                                <span >
                                                    {translate('dynatest.resources.costos.boolean.rango')}
                                                    <br />
                                                    <ClearIcon />
                                                </span>
                                            }
                                            {formData && formData.rango == true &&
                                                <span >
                                                    {translate('dynatest.resources.costos.boolean.rango')}
                                                    <br />
                                                    <CheckIcon />
                                                </span>
                                            }

                                        </Grid>
                                        <Grid item xs={3}></Grid>
                                        {!unidads &&
                                        <Grid item xs={3}> </Grid>
                                        }
                                        {formData && formData.rango == false &&
                                            <Grid item xs={3}>
                                                <ReferenceInput
                                                    label="resources.costos.fields.segmento"
                                                    source="segmento"
                                                    reference="segmentos"
                                                    disabled
                                                    filter={{
                                                        tramo: (typeof formData !== "undefined") ? formData.tramo : null,
                                                        pagination: "off"
                                                    }}
                                                    filterToQuery={searchText => ({ codigo: searchText })}
                                                // validate={(typeof formData !== "undefined" && formData.rango) ? [] : [required()]}
                                                >
                                                    <SelectInput optionText="fullCodigos" options={{ fullWidth: true }} fullWidth />
                                                </ReferenceInput>
                                            </Grid>
                                        }

                                        {formData && formData.rango == true &&
                                            <Fragment>
                                                <Grid item xs={3}>
                                                    <PRInput
                                                        label="resources.costos.fields.abInicio"
                                                        source="abInicio"
                                                        disabled
                                                        // onChange={value => dispatch(
                                                        //     change(REDUX_FORM_NAME, 'segmento', null)
                                                        // )}
                                                        fullWidth
                                                        validate={(typeof formData !== "undefined" && formData.rango) ? [validarAbscisaInicial, required()] : [validarAbscisaInicial]}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <PRInput
                                                        label="resources.costos.fields.abFin"
                                                        source="abFin"
                                                        disabled
                                                        fullWidth validate={[validarAbscisaFinal]}
                                                        // onChange={value => dispatch(
                                                        //     change(REDUX_FORM_NAME, 'segmento', null)
                                                        // )}
                                                        validate={(typeof formData !== "undefined" && formData.rango) ? [validarAbscisaInicial, required()] : [validarAbscisaInicial]}
                                                    />
                                                </Grid>
                                            </Fragment>

                                        }
                                    </Fragment>
                                )
                            }
                        </FormDataConsumer>
                        <FormDataConsumer>
                            {
                                ({ formData, dispatch, ...rest }) => (
                                    <Fragment>
                                        <Grid item xs={3}>
                                            <ReferenceInput
                                                label="resources.costos.fields.sistema"
                                                source="sistema"
                                                reference="sistemas"
                                                disabled
                                                filterToQuery={searchText => ({ nombre: searchText })}
                                                validate={[required()]}
                                                onChange={value => dispatch(
                                                    change(REDUX_FORM_NAME, 'tipoElemento', null)
                                                )}
                                                fullWidth
                                            >
                                                <SelectInput optionText="nombre" fullWidth />
                                            </ReferenceInput>
                                        </Grid>
                                        {formData && formData.sistema !== undefined && formData.sistema == "/sistemas/SEN" &&
                                            <Grid item xs={3}>
                                                <ReferenceInput
                                                    label="resources.costos.fields.tipoElementoSenal"
                                                    source="tipoElementoSenal"
                                                    disabled
                                                    reference="tipo_elemento_senals"
                                                    sort={{ field: 'nombre', order: 'ASC' }}
                                                    filterToQuery={searchText => ({ nombre: searchText })}
                                                    // validate={[required()]}
                                                    fullWidth
                                                >
                                                    <SelectInput optionText="nombre" fullWidth />
                                                </ReferenceInput>
                                            </Grid>

                                        }
                                        {formData && formData.sistema !== undefined && formData.sistema == "/sistemas/SEN" &&
                                            <Grid item xs={3}>
                                                <ReferenceInput
                                                    label="resources.costos.fields.tipoSenal"
                                                    source="tipoSenal"
                                                    reference="tipo_senals"
                                                    disabled
                                                    sort={{ field: 'nombre', order: 'ASC' }}
                                                    filter={{
                                                        tipoElementoSenal: (typeof formData !== "undefined") ? formData.tipoElementoSenal : null
                                                    }}
                                                    filterToQuery={searchText => ({ nombre: searchText })}
                                                    // validate={(typeof formData !== "undefined" && formData.isMasiveLoading) ? [required()] : []}
                                                    fullWidth
                                                >
                                                    <SelectInput optionText="nombre" fullWidth />
                                                </ReferenceInput>
                                            </Grid>

                                        }
                                        {formData && formData.sistema !== undefined && formData.sistema != "/sistemas/SEN" &&
                                            <Grid item xs={3}>
                                                <ReferenceInput
                                                    label="resources.costos.fields.tipoElemento"
                                                    source="tipoElemento"
                                                    disabled
                                                    reference="tipo_elementos"
                                                    sort={{ field: 'nombre', order: 'ASC' }}
                                                    filter={{
                                                        sistema: (typeof formData !== "undefined") ? formData.sistema : null
                                                    }}
                                                    filterToQuery={searchText => ({ nombre: searchText })}
                                                    // validate={[required()]}
                                                    fullWidth
                                                >
                                                    <SelectInput optionText="nombre" fullWidth />
                                                </ReferenceInput>
                                            </Grid>
                                        }
                                    </Fragment>
                                )
                            }
                        </FormDataConsumer>
                    </Grid>
                    <Divider fullWidth />
                    <br />
                    <FormDataConsumer fullWidth>
                        {({ formData, dispatch, ...rest }) => {
                        // const [editedRowData, setEditedRowData] = useState([]);
                        // RESET VAR EDIT MODAL
                        const setEditedRowData = (valueEdit, index) => {
                            if (valueEdit != undefined) {  
                                if (valueEdit.length > 0) {
                                    let stringifyValue = JSON.stringify(valueEdit);
                                    let parseValue = JSON.parse(stringifyValue);
                                    editedRowData = parseValue; 
                                } else {
                                    let indexData = formData.costoElementos[rowToEdit]; 
                                    let stringifyValue = JSON.stringify(indexData);
                                    let parseValue = JSON.parse(stringifyValue);
                                    if (formData.costoElementosOnEdit != undefined) {
                                        formData.costoElementosOnEdit[0] = parseValue;
                                    } else {
                                        formData.costoElementosOnEdit = [parseValue];
                                    }
                                    
                                    editedRowData[0] = parseValue;
                                }
                                // dataToEdit = formData.costoElementos;
                                // if (dataToEdit != undefined) {   
                                //     for (let l = 0; l < dataToEdit.length; l++) {
                                //         let elementString = JSON.stringify(dataToEdit[l]);
                                //         let elementParse = JSON.parse(elementString);
                                //         dataToEdit[l] = elementParse;
                                        
                                //     }
                                // }
                            }
                        }
                        
                        return(
                            <Fragment>
                                {renderTable ?
                                    <TableWithPagination
                                            label={translate('dynatest.resources.costos.tableDatos.titleTable')}
                                            identityColumn="codigo"
                                            columnNames={[
                                                { id: 'codigo', name: translate('dynatest.resources.costos.tableDatos.columnNames.codigo'), },
                                                { id: 'tipoMantenimiento', name: translate('dynatest.resources.costos.tableDatos.columnNames.tipoMantenimiento'), },
                                                { id: 'fechaMantenimiento2', name: translate('dynatest.resources.costos.tableDatos.columnNames.fechaMantenimiento2') },
                                                { id: 'costoMantenimiento', name: translate('dynatest.resources.costos.tableDatos.columnNames.costoMantenimiento'), },
                                                { id: 'razonSocial', name: translate('dynatest.resources.costos.tableDatos.columnNames.razonSocial'), },
                                                { id: 'calificacion', name: translate('dynatest.resources.costos.tableDatos.columnNames.calificacion'), },
                                                { id: 'observaciones', name: translate('dynatest.resources.costos.tableDatos.columnNames.observaciones'), },
                                            ]}
                                            formatColumn={[
                                                { id: 'fechaMantenimiento2', formatTo: 'date' },
                                                { id: 'costoMantenimiento', formatTo: 'currency' },
                                                { id: 'calificacion', formatTo: "number" }

                                            ]}
                                            arrayData={formData.costoElementos}
                                            onCellClick={props => {
                                                setRowToEdit(props.rowIndex)
                                                setEditedRowData([], props.rowIndex);
                                                modalProps.handleOpen()
                                            }}
                                        />
                                    :
                                    setRenderTable(true)
                                }
                                <SimpleModal
                                    controller={props => { if (Object.keys(modalProps).length == 0) { setModalProps(props) } }}
                                    title={translate('dynatest.resources.costos.modalEdit.title') + formData.costoElementos[rowToEdit].codigo}
                                    onSave={() => {
                                        let errores
                                        let allRows = formData.costoElementos
                                        // VALIDACION FECHA MATENIMIENTO
                                        let validateDateMant = isFechaInvalida(editedRowData[0].fechaMantenimiento);
                                        if (validateDateMant != undefined) {
                                            return alert(validateDateMant);
                                        }
                                        // VALIDACION CALIFICACION
                                        if (editedRowData[0].calificacion < 1) {
                                            return alert("Calificación debe ser al menos 1");
                                        }
                                        if (editedRowData[0].calificacion > 5) {
                                            return alert("Calificación debe ser 5 o menos");
                                        }
                                        let validateCalificacion = Entero(editedRowData[0].calificacion);
                                        if (validateCalificacion != undefined) {
                                            return alert("Calificación " + validateCalificacion);
                                        }

                                        allRows[rowToEdit] = editedRowData[0]
                                        if (editedRowData[0].fechaMantenimiento.split("T").length == 2) {
                                            allRows[rowToEdit].fechaMantenimiento2 = editedRowData[0].fechaMantenimiento
                                        } else {
                                            allRows[rowToEdit].fechaMantenimiento2 = editedRowData[0].fechaMantenimiento + "T00:00:00-05:00"
                                        }
                                        allRows[rowToEdit].razonSocial = document.getElementById("proveedor") ? document.getElementById("proveedor").parentNode.firstChild.innerHTML : null
                                        // FORMATEO COSTO MANTENIMIENTO
                                        var costoMantString = allRows[rowToEdit].costoMantenimiento;
                                        if (typeof costoMantString == "string") {
                                            costoMantString = allRows[rowToEdit].costoMantenimiento.toString().replace("$", "").replace(/\./g, "");                                            
                                        } else {
                                            costoMantString = allRows[rowToEdit].costoMantenimiento.toString();                                            
                                        }
                                        allRows[rowToEdit].costoMantenimiento = parseFloat(costoMantString.replace(",", "."));

                                        dispatch(change(REDUX_FORM_NAME, 'costoElementos', allRows))
                                        setRenderTable(false)
                                        modalProps.handleClose()
                                    }}
                                >
                                    <ArrayInput
                                        source="costoElementosOnEdit"
                                        style={{ width: '100%' }}
                                        label=" "
                                        defaultValue={[formData.costoElementos[rowToEdit]]}
                                        className="inventariosSegmentos"
                                        validate={setEditedRowData}

                                    >
                                        <SimpleFormIterator
                                            disableAdd
                                            disableRemove
                                        >
                                            <SelectInput label="resources.costos.fields.tipoMantenimiento" source="tipoMantenimiento" fullWidth formClassName={classes.fieldCol6} validate={[required()]} choices={tipoMantenimientos} />
                                            <DateInput label="resources.costos.fields.fechaMantenimiento" source="fechaMantenimiento" fullWidth formClassName={classes.fieldCol6} validate={[required(), isFechaInvalida]}/>
                                            <CurrencyInput label="resources.costos.fields.costoMantenimiento" source="costoMantenimiento" fullWidth formClassName={classes.fieldCol6} validate={[required()]} />
                                            {/* <NumberInput label="Costo del Mantenimiento" source="costoMantenimiento" fullWidth formClassName={classes.fieldCol6} validate={[required()]} /> */}
                                            <ReferenceInput
                                                id="proveedor"
                                                label="resources.costos.fields.proveedor"
                                                source="proveedor"
                                                reference="proveedors"
                                                filter={{
                                                    sistema: formData.sistema,
                                                    mantenimiento: 1
                                                }}
                                                fullWidth
                                                formClassName={classes.fieldCol6}
                                                validate={[required()]}
                                            >
                                                <SelectInput optionText="razonSocial" fullWidth />
                                            </ReferenceInput>
                                            <NumberInput label="resources.costos.fields.calificacion" source="calificacion" fullWidth formClassName={classes.fieldCol6} validate={[required(), minValue(1), maxValue(5), Entero]} />
                                            <TextInput label="resources.costos.fields.observaciones" source="observaciones" fullWidth formClassName={classes.fieldCol6} validate={[required()]} />
                                            <br />
                                        </SimpleFormIterator>
                                    </ArrayInput>
                                </SimpleModal>
                                <ArrayInput
                                    source="costoElementos"
                                    style={{ width: '100%', display: "none" }}
                                    label=" "
                                    className="inventariosSegmentos"
                                    validate={[required()]}
                                >
                                    <SimpleFormIterator
                                        disableAdd
                                        disableRemove
                                    >
                                        <TextInput disabled label="resources.costos.fields.codigo" source="codigo" fullWidth formClassName={classes.fieldCol9} validate={[required()]} />
                                        <SelectInput label="resources.costos.fields.tipoMantenimiento" source="tipoMantenimiento" fullWidth formClassName={classes.fieldCol3} validate={[required()]} choices={tipoMantenimientos} />
                                        <DateInput label="resources.costos.fields.fechaMantenimiento" source="fechaMantenimiento" fullWidth formClassName={classes.fieldCol3} validate={[required(), isFechaInvalida]} />
                                        {/* <CurrencyInput label="Costo del Mantenimiento" source="costoMantenimiento" fullWidth formClassName={classes.fieldCol3} validate={[required()]} /> */}
                                        <NumberInput label="resources.costos.fields.costoMantenimiento" source="costoMantenimiento" fullWidth formClassName={classes.fieldCol3} validate={[required()]} />
                                        <TextInput disabled label="resources.costos.fields.proveedor" source="proveedor" fullWidth validate={[required()]} formClassName={classes.fieldCol3} />
                                        <NumberInput label="resources.costos.fields.calificacion" source="calificacion" fullWidth formClassName={classes.fieldCol3} validate={[required(), minValue(1), maxValue(5), Entero]} />
                                        <TextInput label="resources.costos.fields.observaciones" source="observaciones" fullWidth formClassName={classes.fieldCol12} validate={[required()]} />
                                        <br />
                                    </SimpleFormIterator>
                                </ArrayInput>

                            </Fragment>
                        )}}
                    </FormDataConsumer>
                </FormTab>
            </TabbedForm>
        </Edit>
    )

}));
