import React, { useState, Fragment, useEffect } from 'react';
import {
    ArrayInput, SimpleFormIterator, NumberInput, ChipField, ReferenceArrayField,
    Datagrid, TabbedForm, FormTab, TextInput, SelectInput, SimpleList, SingleFieldList,
    ShowController, ShowView, SelectField, TextField, DateField, ArrayField,
    ReferenceField, NumberField, BooleanField, FormDataConsumer
} from 'react-admin';
import { withStyles } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import { PRField } from '../../fields/PRField';
import Divider from '@material-ui/core/Divider';
import { GMapField } from '../../fields/GMapField';
import Typography from '@material-ui/core/Typography';
import { styles } from '../../EstilosGrid';
import { ButtonCarousel } from '../images/ButtonCarousel';
import { ShowToolbar } from '../componentes/ShowToolbar';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import config from '../../config/config';
import Respuestas from './preguntasRespuestas/complementoRespuestas';
import Peso from './preguntasRespuestas/complementoPeso';
import PesoTotal from './preguntasRespuestas/complementoPesoTotal';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import ShowElementsParticular from './../mantenimientos/ShowElementsParticular';
import { translate } from 'react-admin';

let urlAppjs = config.UrlServer;
let codGeneral = "";

export const RespuestaEvaluacionNovedadsShow = translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;
    const { respuesta } = props;
    const { peso } = props;
    const { pesoTotal } = props;

    const [imagen, setImagen] = useState(true);
    const [latitud, setLatitud] = useState();
    const [longitud, setLongitud] = useState();
    const Latitud = (value) => {
        setLatitud(value)
    }
    const Longitud = (value) => {
        setLongitud(value)
    }
    const ValidarImagen = (value) => {
        //console.log(value + " Aqui est la imagen")
        if (value !== null && value !== undefined && value !== 'undefined') {
            let lenghtObject = Object.keys(value);
            let valor = '';
            for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
            setImagen(value)
            // console.log(value + "Aqui est la imagen ")
        }
    }

    const [tipo_elementos, setTipoElementos, tipo_elemento_senals] = useState('');
    const ValidarTipoElementos = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setTipoElementos(value);
    }

    const [sistema, setSistema] = useState(null);
    const ValidarSistema = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setSistema(value);
    }

    const [severidad, setSeveridad] = useState();
    const ValidarSeveridad = (value) => {
        setSeveridad(value);
    }

    const [areaAfect, setAreaAfect] = useState();
    const ValidarArea = (value) => {
        setAreaAfect(value);
    }

    const [medicion, setMedicion] = useState();
    const ValidarMedicion = (value) => {
        if (value === "Medición") {
            setMedicion(value);
        }
    }


    return (
        <ShowController {...props} >
            {controllerProps =>
                <ShowView {...props} {...controllerProps} title="dynatest.resources.respuesta_evaluacion_novedads.title.showTitle" actions={medicion != "Medición" ? <ButtonCarousel /> : <ShowToolbar />} >
                    <TabbedForm toolbar={false}>
                        <FormTab label="dynatest.resources.respuesta_evaluacion_novedads.tabs.filtro">
                            <br />
                            {controllerProps.record && controllerProps.record.tipoElemento !== null &&
                                <TextInput source="tipoElemento_.src" validate={ValidarImagen} style={{ display: 'none' }} />
                            }
                            {/* <TextInput source="tipoElemento_.src" validate={ValidarImagen} style={{ display: 'none' }} /> */}
                            {controllerProps.record && controllerProps.record.tipoElementoSenal === '/tipo_elemento_senals/SV' &&
                                <TextInput source="senal_.url" validate={ValidarImagen} formClassName={classes.ocultar} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal === '/tipo_elemento_senals/SV' &&
                                <TextInput source="tipoRegistro" validate={ValidarMedicion} formClassName={classes.ocultar} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal !== '/tipo_elemento_senals/SV' &&
                                <TextInput source="tipoSenal_.url" validate={ValidarImagen} formClassName={classes.ocultar} />
                            }
                            {medicion !== "Medición" &&
                                <TextField label="resources.respuesta_evaluacion_novedads.fields.codigo" source="smartCode" linkType={false} fullWidth formClassName={classes.grid_cont4} />
                            }
                            <ReferenceField label="resources.respuesta_evaluacion_novedads.fields.sistema" source="sistema" reference="sistemas" fullWidth formClassName={classes.grid_cont4} linkType={false} >
                                <TextField source="nombre" />
                            </ReferenceField>
                            {medicion !== "Medición" &&
                                <TextField label="resources.respuesta_evaluacion_novedads.fields.genericTipoElemento" source="genericTipoElemento" linkType={false} fullWidth formClassName={classes.grid_cont3} />
                            }
                            {medicion === "Medición" &&
                                <TextField label="resources.respuesta_evaluacion_novedads.fields.genericTipoElemento" source="genericTipoElemento" linkType={false} fullWidth formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.tipoElementoSenal === '/tipo_elemento_senals/SV' && medicion === "Medición" &&
                                <TextField label="resources.respuesta_evaluacion_novedads.fields.tipoRegistro" source="tipoRegistro" linkType={false} fullWidth formClassName={classes.grid_cont3} />
                            }
                            <br />
                            <Divider fullWidth style={{ width: '300%', marginTop: '10px' }} />
                            <br />
                            <Typography variant="h3" component="h3"><b>{translate('dynatest.resources.respuesta_evaluacion_novedads.subTitle.localizacion')}</b></Typography>
                            <br />
                            <ReferenceField
                                label="resources.respuesta_evaluacion_novedads.fields.unidadFuncional"
                                source="unidadFuncional"
                                reference="unidad_funcionals"
                                linkType={false}
                                fullWidth
                                formClassName={classes.grid_cont4}
                            >
                                <TextField source="fullCodigos" />
                            </ReferenceField>
                            <ReferenceField label="resources.respuesta_evaluacion_novedads.fields.tramo" source="tramo" reference="tramos" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                <TextField source="fullCodigos" />
                            </ReferenceField>
                            <ReferenceField label="resources.respuesta_evaluacion_novedads.fields.segmento" source="segmento" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont3}>
                                <TextField source="fullCodigos" />
                            </ReferenceField>
                            {medicion !== "Medición" &&
                                <Divider fullWidth style={{ width: '300%', marginTop: '10px' }} />
                            }
                            <br />
                            {medicion !== "Medición" &&
                                <Typography variant="b" component="b"><b>{translate('dynatest.resources.respuesta_evaluacion_novedads.subTitle.coordIniciales')}</b></Typography>
                            }
                            {medicion !== "Medición" &&
                                <PRField label="resources.respuesta_evaluacion_novedads.fields.abscisaIni" source="abscisaIni" fullWidth formClassName={classes.grid_cont4} />
                            }
                            {medicion !== "Medición" &&
                                <NumberField label="resources.respuesta_evaluacion_novedads.fields.latitudInicial" source="latitudInicial" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4} />
                            }
                            {medicion !== "Medición" &&
                                <NumberField label="resources.respuesta_evaluacion_novedads.fields.longitudInicial" source="longitudInicial" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                            }
                            {medicion !== "Medición" &&
                                <Divider fullWidth style={{ width: '300%', marginTop: '10px' }} />
                            }
                            <br />
                            {controllerProps.record && controllerProps.record.ubicacionMapa.length > 1 && medicion !== "Medición" &&
                                <Typography variant="b" component="b"><b>{translate('dynatest.resources.respuesta_evaluacion_novedads.subTitle.coordFinales')}</b></Typography>
                            }
                            {controllerProps.record && controllerProps.record.ubicacionMapa.length > 1 && medicion !== "Medición" &&
                                <PRField label="resources.respuesta_evaluacion_novedads.fields.abscisaFin" source="abscisaFin" fullWidth formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.ubicacionMapa.length > 1 && medicion !== "Medición" &&
                                <NumberField label="resources.respuesta_evaluacion_novedads.fields.latitudFinal" source="latitudFinal" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.ubicacionMapa.length > 1 && medicion !== "Medición" &&
                                <NumberField label="resources.respuesta_evaluacion_novedads.fields.longitudFinal" source="longitudFinal" fullWidth style={{ display: "flex", justifyContent: "flex-end", marginRight: "30%" }} formClassName={classes.grid_cont3} />
                            }
                            {controllerProps.record && medicion !== "Medición" &&
                                <GMapField style={{ width: "100%" }}
                                    defaultZoom={16}
                                    defaultCenter={{ lat: 4.7567039, lng: -74.0311056 }}
                                    source="ubicacionMapa"
                                    multipleMarkers="true"
                                    googleKey="AIzaSyCivTXzJBYRBK8nosBYRFXnv-hGXIbhnYY"
                                    icon={imagen}
                                />
                            }
                            <Divider fullWidth style={{ width: '300%', marginTop: '10px' }} />

                        </FormTab>
                        <FormTab label="dynatest.resources.respuesta_evaluacion_novedads.tabs.registro">
                            <br></br>
                            {controllerProps.record && controllerProps.record.sistema === "/sistemas/CLZ" &&
                                <Fragment>
                                    <TextInput source="respuestaNovedadSegmento_.severidad" validate={ValidarSeveridad} style={{ display: 'none' }} />
                                    <TextInput source="respuestaNovedadSegmento_.areaAfectada" validate={ValidarArea} style={{ display: 'none' }} />
                                </Fragment>
                            }
                            <DateField source="fechaRegistro" label="resources.respuesta_evaluacion_novedads.fields.fechaRegistro" fullWidth formClassName={classes.grid_cont4} />
                            {controllerProps.record && controllerProps.record.sistema === "/sistemas/CLZ" &&
                                <ReferenceField label="resources.respuesta_evaluacion_novedads.fields.deterioro" source="respuestaNovedadSegmento_.deterioro" reference="tipo_deterioro_pers" linkType={false} fullWidth formClassName={classes.grid_cont4}>
                                    <TextField source="nombre" />
                                </ReferenceField>
                            }
                            {controllerProps.record && controllerProps.record.sistema === "/sistemas/CLZ" && severidad !== null &&
                                <TextField source="respuestaNovedadSegmento_.severidad" label="resources.respuesta_evaluacion_novedads.fields.severidad" fullWidth formClassName={classes.grid_cont3} />
                            }
                            {controllerProps.record && controllerProps.record.sistema === "/sistemas/CLZ" &&
                                <TextField source="respuestaNovedadSegmento_.longitud" label="resources.respuesta_evaluacion_novedads.fields.longitud" fullWidth formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.sistema === "/sistemas/CLZ" && areaAfect != null && areaAfect !== "" &&
                                <TextField source="respuestaNovedadSegmento_.ancho" label="resources.respuesta_evaluacion_novedads.fields.ancho" fullWidth formClassName={classes.grid_cont4} />
                            }
                            {controllerProps.record && controllerProps.record.sistema === "/sistemas/CLZ" && areaAfect != null && areaAfect !== "" &&
                                <TextField source="respuestaNovedadSegmento_.areaAfectada" label="resources.respuesta_evaluacion_novedads.fields.areaAfectada" fullWidth formClassName={classes.grid_cont3} />
                            }
                            <br />
                            <TextField source="estado" label="resources.respuesta_evaluacion_novedads.fields.estado" fullWidth formClassName={classes.grid_cont4} />
                            <TextField source="aprobacion" label="resources.respuesta_evaluacion_novedads.fields.aprobacion" fullWidth formClassName={classes.grid_cont4} />
                            <TextField source="observaciones" label="resources.respuesta_evaluacion_novedads.fields.observaciones" fullWidth formClassName={classes.grid_cont3} />
                            <span></span>
                            
                            {controllerProps.record && controllerProps.record.sistema !== "/sistemas/CLZ" && controllerProps.record.tipoElementoSenal !== "/tipo_elemento_senals/SV" &&
                                <span><b>{translate('dynatest.resources.respuesta_evaluacion_novedads.subTitle.resultados')}</b></span>
                            }
                            {controllerProps.record && controllerProps.record.sistema !== "/sistemas/CLZ" && controllerProps.record.tipoElementoSenal !== "/tipo_elemento_senals/SV" &&
                                <PesoTotal pesoTotal={pesoTotal}/>                           
                            }
                            {controllerProps.record && controllerProps.record.sistema !== "/sistemas/CLZ" && controllerProps.record.tipoElementoSenal !== "/tipo_elemento_senals/SV" &&
                                <span><b>{translate('dynatest.resources.respuesta_evaluacion_novedads.subTitle.indicadores')}</b></span>
                            }
                            < Divider fullWidth style={{ width: '300%', marginTop: '10px' }} />
                            {controllerProps.record && controllerProps.record.sistema !== "/sistemas/CLZ" && controllerProps.record.tipoElementoSenal !== "/tipo_elemento_senals/SV" &&
                                <ArrayField label="" source="respuestasPreguntasNovedades" fullWidth formClassName={classes.grid_cont12}>
                                    <Datagrid>
                                        <TextField source="pregunta_.registroNovedad_.nombre" label="resources.respuesta_evaluacion_novedads.fields.novedades" style={{ borderRight: '1px solid rgba(224, 224, 224, 1)', padding:'12px'}} linkType={false} />
                                        <TextField source="pregunta_.pregunta" label="resources.respuesta_evaluacion_novedads.fields.pregunta" style={{ borderRight: '1px solid rgba(224, 224, 224, 1)', padding:'12px'}} fullWidth formClassName={classes.grid_cont3} linkType={false} />
                                        <Respuestas label="resources.respuesta_evaluacion_novedads.fields.respuesta" pregunta={respuesta} fullWidth formClassName={classes.grid_cont3} linkType={false} />
                                        <Peso label="resources.respuesta_evaluacion_complementarios.fields.peso" peso={peso} fullWidth formClassName={classes.grid_cont3} linkType={false}/>s
                                    </Datagrid>
                                </ArrayField>
                            }


                            {controllerProps.record && controllerProps.record.sistema !== "/sistemas/CLZ" && controllerProps.record.tipoElementoSenal === "/tipo_elemento_senals/SV" &&
                                controllerProps.record.tipoRegistro === "Novedad" && controllerProps.record.tipoRegistro != null &&
                                <span><b>{translate('dynatest.resources.respuesta_evaluacion_novedads.subTitle.resultados')}</b></span>
                            }
                            {controllerProps.record && controllerProps.record.sistema !== "/sistemas/CLZ" && controllerProps.record.tipoElementoSenal === "/tipo_elemento_senals/SV" &&
                                controllerProps.record.tipoRegistro === "Novedad" && controllerProps.record.tipoRegistro != null &&
                                <PesoTotal pesoTotal={pesoTotal}/>                           
                            }
                            {controllerProps.record && controllerProps.record.sistema !== "/sistemas/CLZ" && controllerProps.record.tipoElementoSenal === "/tipo_elemento_senals/SV" &&
                                controllerProps.record.tipoRegistro === "Novedad" && controllerProps.record.tipoRegistro != null &&
                                <span><b>{translate('dynatest.resources.respuesta_evaluacion_novedads.subTitle.indicadores')}</b></span>
                            }
                            {controllerProps.record && controllerProps.record.sistema !== "/sistemas/CLZ" && controllerProps.record.tipoElementoSenal === "/tipo_elemento_senals/SV" &&
                                controllerProps.record.tipoRegistro === "Novedad" && controllerProps.record.tipoRegistro != null &&
                                <ArrayField label="" source="respuestasPreguntasNovedades" fullWidth formClassName={classes.grid_cont12}>
                                    <Datagrid>
                                        <TextField source="pregunta_.registroNovedad_.nombre" label="resources.respuesta_evaluacion_novedads.fields.novedades" style={{ borderRight: '1px solid rgba(224, 224, 224, 1)', padding:'12px'}} linkType={false} />
                                        <TextField source="pregunta_.pregunta" label="resources.respuesta_evaluacion_novedads.fields.pregunta" style={{ borderRight: '1px solid rgba(224, 224, 224, 1)', padding:'12px'}} fullWidth formClassName={classes.grid_cont3} linkType={false} />
                                        <Respuestas label="resources.respuesta_evaluacion_novedads.fields.respuesta" pregunta={respuesta} fullWidth formClassName={classes.grid_cont3} linkType={false} />
                                        <Peso label="resources.respuesta_evaluacion_complementarios.fields.peso" peso={peso} fullWidth formClassName={classes.grid_cont3} linkType={false}/>
                                    </Datagrid>
                                </ArrayField>
                            }
                            {controllerProps.record && controllerProps.record.sistema !== "/sistemas/CLZ" && controllerProps.record.tipoRegistro !== "Novedad"  && controllerProps.record.tipoElementoSenal === "/tipo_elemento_senals/SV" &&
                                <Table size="small">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell style={{ paddingLeft: 0, paddingRight: '1.5rem', textAlign: 'center', fontSize: '0.75rem' }} ><b>{translate('dynatest.resources.respuesta_evaluacion_novedads.tableMediciones.columns.mediciones')}</b></TableCell>
                                            <TableCell style={{ paddingLeft: 0, paddingRight: '1.5rem', textAlign: 'center', fontSize: '0.75rem' }} ><b>{translate('dynatest.resources.respuesta_evaluacion_novedads.tableMediciones.columns.tipoElemento')}</b></TableCell>
                                            <TableCell style={{ paddingLeft: 0, paddingRight: '1.5rem', textAlign: 'center', fontSize: '0.75rem' }} ><b>{translate('dynatest.resources.respuesta_evaluacion_novedads.tableMediciones.columns.modelo')}</b></TableCell>
                                            <TableCell style={{ paddingLeft: 0, paddingRight: '1.5rem', textAlign: 'center', fontSize: '0.75rem' }} ><b>{translate('dynatest.resources.respuesta_evaluacion_novedads.tableMediciones.columns.codigo')}</b></TableCell>
                                            <TableCell style={{ paddingLeft: 0, paddingRight: '1.5rem', textAlign: 'center', fontSize: '0.75rem' }} ><b>{translate('dynatest.resources.respuesta_evaluacion_novedads.tableMediciones.columns.retroReflecColorPrin')}</b></TableCell>
                                            <TableCell style={{ paddingLeft: 0, paddingRight: '1.5rem', textAlign: 'center', fontSize: '0.75rem' }} ><b>{translate('dynatest.resources.respuesta_evaluacion_novedads.tableMediciones.columns.disminucionPrincipal')}</b></TableCell>
                                            <TableCell style={{ paddingLeft: 0, paddingRight: '1.5rem', textAlign: 'center', fontSize: '0.75rem' }} ><b>{translate('dynatest.resources.respuesta_evaluacion_novedads.tableMediciones.columns.retroReflecColorSec')}</b></TableCell>
                                            <TableCell style={{ paddingLeft: 0, paddingRight: '1.5rem', textAlign: 'center', fontSize: '0.75rem' }} ><b>{translate('dynatest.resources.respuesta_evaluacion_novedads.tableMediciones.columns.disminucionSecundario')}</b></TableCell>
                                            <TableCell style={{ paddingLeft: 0, paddingRight: '1.5rem', textAlign: 'center', fontSize: '0.75rem' }} ><b>{translate('dynatest.resources.respuesta_evaluacion_novedads.tableMediciones.columns.posicion')}</b></TableCell>
                                            <TableCell style={{ paddingLeft: 0, paddingRight: '1.5rem', textAlign: 'center', fontSize: '0.75rem' }} ><b>{translate('dynatest.resources.respuesta_evaluacion_novedads.tableMediciones.columns.legibilidad')}</b></TableCell>
                                            <TableCell style={{ paddingLeft: 0, paddingRight: '1.5rem', textAlign: 'center', fontSize: '0.75rem' }} ><b>{translate('dynatest.resources.respuesta_evaluacion_novedads.tableMediciones.columns.observaciones')}</b></TableCell>
                                        </TableRow>
                                        {controllerProps.record.respuestaMediciones.map((data, f) => (
                                            <TableRow>
                                                <TableCell style={{ paddingLeft: 0, paddingRight: '1.5rem', textAlign: 'center', fontSize: '0.875rem', fontWeight: 400, fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }} >
                                                    {((parseInt(f) + 1))}
                                                </TableCell>
                                                <TableCell style={{ paddingLeft: 0, paddingRight: '1.5rem', textAlign: 'left', fontSize: '0.875rem', fontWeight: 400, fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }} >
                                                    {(data.tipoElemento_.nombre)}
                                                </TableCell>
                                                <TableCell style={{ paddingLeft: 0, paddingRight: '1.5rem', textAlign: 'left', fontSize: '0.875rem', fontWeight: 400, fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }} >
                                                    {(data.modelo_.nombre)}
                                                </TableCell>
                                                <TableCell style={{ paddingLeft: 0, paddingRight: '1.5rem', textAlign: 'left', fontSize: '0.875rem', fontWeight: 400, fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }} >
                                                    {(data.codigo)}
                                                </TableCell>
                                                <TableCell style={{ paddingLeft: 0, paddingRight: '1.5rem', textAlign: 'right', fontSize: '0.875rem', fontWeight: 400, fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }} >
                                                    {(data.retroreflectColorPrincipal)}
                                                </TableCell>
                                                <TableCell style={{ paddingLeft: 0, paddingRight: '1.5rem', textAlign: 'right', fontSize: '0.875rem', fontWeight: 400, fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }} >
                                                    {(data.disminucionPrincipal)}
                                                </TableCell>
                                                <TableCell style={{ paddingLeft: 0, paddingRight: '1.5rem', textAlign: 'right', fontSize: '0.875rem', fontWeight: 400, fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }} >
                                                    {(data.retroreflectColorSecundario)}
                                                </TableCell>
                                                <TableCell style={{ paddingLeft: 0, paddingRight: '1.5rem', textAlign: 'right', fontSize: '0.875rem', fontWeight: 400, fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }} >
                                                    {(data.disminucionSecundario)}
                                                </TableCell>
                                                <TableCell style={{ paddingLeft: 0, paddingRight: '1.5rem', textAlign: 'left', fontSize: '0.875rem', fontWeight: 400, fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }} >
                                                    {(data.posicion)}
                                                </TableCell>
                                                <TableCell style={{ paddingLeft: 0, paddingRight: '1.5rem', textAlign: 'left', fontSize: '0.875rem', fontWeight: 400, fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }} >
                                                    {(data.legibilidad)}
                                                </TableCell>
                                                <TableCell style={{ paddingLeft: 0, paddingRight: '1.5rem', textAlign: 'left', fontSize: '0.875rem', fontWeight: 400, fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }} >
                                                    {(data.observaciones)}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            }
                        </FormTab>
                        <FormTab label="dynatest.resources.respuesta_evaluacion_novedads.tabs.auditoria">
                            <br></br>
                            <DateField source="createdAt" label="resources.respuesta_evaluacion_novedads.fields.createdAt" fullWidth formClassName={classes.grid_cont3} showTime/>
                            <TextField source="createdBy" label="resources.respuesta_evaluacion_novedads.fields.createdBy" fullWidth formClassName={classes.grid_cont3} />
                            <span></span>
                            <DateField source="updatedAt" label="resources.respuesta_evaluacion_novedads.fields.updatedAt" fullWidth formClassName={classes.grid_cont3} showTime/>
                            <TextField source="updatedBy" label="resources.respuesta_evaluacion_novedads.fields.updatedBy" fullWidth formClassName={classes.grid_cont3} />
                            <span></span>
                        </FormTab>

                    </TabbedForm>
                </ShowView>
            }
        </ShowController>

    )
}
));
const WithProps = ({ children, ...props }) => children(props);