import React from 'react';

import { List, Datagrid, TextField, EditButton, ShowButton, ReferenceField } from 'react-admin';

import { PField } from './../../../fields/PField';

import PimsElementoListFilter from './../PimsElementoListFilter';

export const ElementoSeguridadList = props => (
    <List {...props} title="Seguridad" filters={<PimsElementoListFilter sistema="SEG"/>} filter={{tipoElemento: {sistema: "SEG"}, proyecto: {id:1}}}>
        <Datagrid>
            <TextField label="Codigo" source="fullCode"/>
            <ReferenceField source="segmento" reference="segmentos">
                <TextField source="fullCode" />
            </ReferenceField>
            <ReferenceField source="tipoElemento" reference="tipo_elementos">
                <TextField source="nombre" />
            </ReferenceField>            
            <PField label="Abscisa inicial" source="pInicio"/>
            <PField label="Abscisa final" source="pFin"/>
            <ShowButton />
            <EditButton />                           
        </Datagrid>
    </List>
);