import React from 'react';
import { AutocompleteArrayInput, ChipField   } from 'react-admin'
import loader from './../../../src/loading-dots.gif';
import config from '../../config/config';
let urlAppjs = config.UrlServer;

class MunicipioArray extends React.Component{

    constructor(props){
        super(props)
        this.state = { Proveedor: [], Municipios: [], departamento:null, urlAppjs: null }
    }
    
    componentDidMount() {
        //OBTENER DATOS DEL PROVEEDOR
        //-------------------------------------->        
        let url_elemento = document.location.hash;
        let aux_elemento = "%2F"+this.props.elemento+"%2F";
        let idElemento = url_elemento.split(aux_elemento);
        console.log(this.state.urlAppjs+"/"+this.props.elemento+"/"+idElemento[1])
        fetch(this.state.urlAppjs+"/"+this.props.elemento+"/"+idElemento[1])
            .then((response) => {
            return response.json()
            })
            .then((Proveedor) => {                               
                if(this.props.elemento === "proveedors"){
                    this.setState({ departamento: Proveedor["departamento"]["id"] })
                }

                if(this.props.elemento === "servicios_publicos"){
                    this.setState({ departamento: Proveedor["departamento"]["id"] })
                }

                if(this.props.elemento === "contacto_emergencias"){
                    this.setState({ departamento: Proveedor["municipio"][0]["departamento"]["id"] })                                         
                }                
                
                this.setState({ Proveedor: Proveedor })

            }
        )           

      }

      shouldComponentUpdate(nextProps, nextState){
        
        if(this.props.departamento !== nextProps.departamento){ return true }
        if(this.state.Proveedor["municipio"] === undefined || this.state.Municipios["hydra:member"] === undefined){
            return true;
        }else{
            return false;
        }

      }

      render() {   
               
        if(this.state.Proveedor["municipio"]){

            const Respuesta = []
            const choices = []            
            //OBTENER DATOS DEL MUNICIPIO POR DEPARTAMENTO
            //---------------------------------------------->
            if(this.props.departamento === "Ninguno"){
                // fetch(this.state.urlAppjs+"/municipios")
                fetch(this.state.urlAppjs+"/municipios?departamento="+this.state.departamento+"&perPage=150")
                    .then((response) => {
                        return response.json()
                    })
                    .then((Municipios) => {                
                        this.setState({ Municipios: Municipios })
                    }
                )
            }else{
                // 
                fetch(this.state.urlAppjs+"/municipios?departamento="+this.props.departamento+"&perPage=150")
                    .then((response) => {
                        return response.json()
                    })
                    .then((Municipios) => {            
                        console.log(Municipios)    
                        this.setState({ Municipios: Municipios })
                    }
                )  
            }

            console.log(this.state.Municipios)            

            if(this.state.Municipios["hydra:member"]){
            
                //MUNICIPOS X DEPARTAMENTO
                //---------------------------------------->
                this.state.Municipios["hydra:member"].map(choice => {

                    let dato = {
                        id: choice["@id"], 
                        nombre: choice["nombre"]
                    }

                    choices.push(dato)

                }) 

                console.log(choices)

                this.state.Proveedor["municipio"].map(response => {
                    Respuesta.push(response["@id"])
                }) 

                console.log(Respuesta)


                return(
                    <AutocompleteArrayInput  
                        label="Municipio" 
                        choices={choices}
                        source="municipioArray"                    
                        optionText="nombre"
                        optionValue="id"
                        style={{width:'100%'}}
                        defaultValue={Respuesta}
                        readonly
                    />  
                )

                

            }else{
                return(
                
                    <div className="loader-municipios">
                        <img src={loader}/>
                    </div>
                    
                )
            }
        }else{
            return(
                
                <div className="loader-municipios">
                    <img src={loader}/>
                </div>
                
            )
        }
    }
}

export default MunicipioArray;