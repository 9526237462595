import React, { useState, useEffect } from 'react';
import {
    EditButton, ShowButton, Button, Datagrid, TextField, List, ReferenceInput, CreateButton
} from 'react-admin';
import { BulkDeleteWithConfirmButton, Loading } from 'react-admin';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Slider from './multipleItems';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from "styled-components";
import { Fragment } from 'react';
import config from '../../config/config';
import { translate } from 'react-admin';
let urlAppjs = config.UrlServer;

const Wrapper = styled.div`
  max-width: 1000px;
  margin: 0 auto;
`;

export const IdiomaList = translate(({ permissions, ...props }) => {
    const { translate } = props;
    if (!permissions) return <Loading />; 

    return (
        <List {...props} title="dynatest.resources.idiomas_config.title" 
            filters={false} actions={false} pagination={false}
            bulkActionButtons={false} sort={{ field: 'id', order: 'DESC' }}>
            <ListGridCustom translate={translate} />
        </List>
    )
        // //console.log(listData, "Datos para renderizar")
        // return (
        //     <>
        //         <List {...props} basePath={basePath} title="dynatest.resources.idiomas_config.title" style={{ display: 'none' }}>
        //         </List>
        //         <div>
        //             <Grid container>
        //                 <Grid item xs={10}>
        //                     {/* <FiltroSeguridad /> */}
        //                 </Grid>
        //                 <Grid item xs={2} align={'right'}>
        //                     <CreateButton basePath={basePath} />
        //                 </Grid>
        //             </Grid>
        //             <br></br>
        //             <div container>
        //                 {
        //                     <div xs={12}>
        //                         <Card variant="outlined">
        //                             <CardContent>
        //                                 <Grid container className="margin_bottom_5">
        //                                     <Grid item xs={12} align={'center'}>
        //                                         <div ><strong> {translate('dynatest.resources.idiomas_config.title')} </strong></div>
        //                                     </Grid>
        //                                 </Grid>
        //                                 <br></br>
        //                                 <br></br>
        //                                 <Wrapper>
        //                                     <Slider dataCard={listData} />
        //                                 </Wrapper>
        //                             </CardContent>
        //                         </Card>
        //                         <br></br>
        //                         <br></br>
        //                     </div>
        //                 }
        //             </div>
        //         </div>
        //     </>
        // )
    // }
});

const ListGridCustom = ({ ids, data, basePath, translate }) => {
    let dataFormat = [];
    ids.map(id => {
        let test = data[id];
        dataFormat.push(data[id]);
    });
    return(
        <div xs={12}>
            <Card variant="outlined">
                <CardContent>
                    <Grid container className="margin_bottom_5">
                        <Grid item xs={12} align={'center'}>
                            <div ><strong> {translate('dynatest.resources.idiomas_config.title')} </strong></div>
                        </Grid>
                    </Grid>
                    <br></br>
                    <br></br>
                    <Wrapper>
                        <Slider dataCard={dataFormat} basePath={basePath}/>
                    </Wrapper>
                </CardContent>
            </Card>
            <br></br>
            <br></br>
        </div>
    )
};

ListGridCustom.defaultProps = {
    data: {},
    ids: [],
};
