import React, {useState} from 'react';
import { CardActions, TabbedForm ,Toolbar, TextInput, ShowController, ShowView,TextField, DateField,ReferenceField, BooleanField, FormTab, ArrayField,ChipField,SingleFieldList,NumberField,ListButton,EditButton, FunctionField  } from 'react-admin';
import { PRField } from './../../../fields/PRField';
import { PField } from './../../../fields/PField';
import { GMapField } from './../../../fields/GMapField';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import  {styles}  from './../../../EstilosGrid';
import Divider from '@material-ui/core/Divider';
import { ButtonCarousel } from './../../images/ButtonCarousel';
import { translate } from 'react-admin';

export const OtrosElementosShow = translate(withStyles(styles)(({ classes,record, ...props }) => {
    const { translate } = props;
    const [imagen , setImagen] =useState(true);
    const ValidarImagen =  (value) =>{
    // console.log(value + "Aqui est la imagen ")
    if(value !== null && value !== undefined && value !== 'undefined')
    {
        let lenghtObject = Object.keys(value);
        let valor = '';        
        for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                
        setImagen(value) 
        // console.log(value + "Aqui est la imagen ")
    }
    }


    return(
        <ShowController title="Informacion Otro Elemento" {...props}>
            {controllerProps =>
                <ShowView {...props} {...controllerProps} title="dynatest.resources.otros_elementos.title.showTitle"  actions={<ButtonCarousel />}>
                    <TabbedForm toolbar={false}>
                        <FormTab label="dynatest.resources.otros_elementos.tabs.tabsTitle.identificacion">
                            <TextField label="resources.otros_elementos.fields.fullCodigoConsecutivo" source="fullCodigoConsecutivo" fullWidth formClassName={classes.grid_cont4}/>
                            {/* <NumberField label="Consecutivo" source="fullConsecutivo" fullWidth formClassName={classes.grid_cont4}/> */}
                            <br></br>
                            <TextInput source="tipoElemento_.src" validate={ValidarImagen} formClassName={classes.ocultar}/>
                            <ReferenceField label="resources.otros_elementos.fields.tipoElemento" source="tipoElemento" reference="tipo_elementos" linkType={false} fullWidth formClassName={classes.grid_cont4}>    
                                <TextField source="nombre" />       
                            </ReferenceField>                      
                            <TextField source="margen" label="resources.otros_elementos.fields.margen" fullWidth formClassName={classes.grid_cont4}/>
                            <TextField label="resources.otros_elementos.fields.sentido" source="sentido" fullWidth formClassName={classes.grid_cont3} />

                            <Typography variant="h3" component="h3">{translate('dynatest.resources.otros_elementos.subTitle.localizacion')}</Typography>
                            <Divider fullWidth formClassName={classes.grid_cont12}/>
                            <ReferenceField label="resources.otros_elementos.fields.unidadFuncional" source="unidadFuncional" reference="unidad_funcionals" linkType={false} fullWidth formClassName={classes.grid_cont4}>    
                                <TextField source="fullCodigos" />       
                            </ReferenceField>
                            <ReferenceField label="resources.otros_elementos.fields.tramo" source="tramo" reference="tramos" linkType={false} fullWidth formClassName={classes.grid_cont4}>    
                                <TextField source="fullCodigos" />       
                            </ReferenceField> 
                            <ReferenceField label="resources.otros_elementos.fields.segmento" source="segmento" reference="segmentos" linkType={false} fullWidth formClassName={classes.grid_cont3}>    
                                <TextField source="fullCodigos" />       
                            </ReferenceField> 
                            {/* <PField label="PR Inicio" source="pInicio" />
                            <PField label="PR Fin"  source="pFin" /> */}
                            <PRField label="resources.otros_elementos.fields.abInicio" source="abInicio"fullWidth formClassName={classes.grid_cont4} />
                            <PRField label="resources.otros_elementos.fields.abFin" source="abFin" fullWidth formClassName={classes.grid_cont4}/>
                            <NumberField label="resources.otros_elementos.fields.fullResta" source="fullResta" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/> 
                            <br/>
                            <PField label="resources.otros_elementos.fields.pInicio" source="pInicio" fullWidth formClassName={classes.grid_cont4} />
                            <PField label="resources.otros_elementos.fields.pFin" source="pFin" fullWidth formClassName={classes.grid_cont4} />
                            <br/>

                            <Typography variant="b" component="b">{translate('dynatest.resources.otros_elementos.subTitle.coordenadasIniciales')}</Typography>
                            <Divider fullWidth style={{width:'300%', marginTop :'10px'}} /> 
                            {/* <PRField label="Abscisa" id="ingresoAbFin" source="abInicio" fullWidth formClassName={classes.grid_cont4}/> */}
                            <NumberField label="resources.otros_elementos.fields.latitudShow" source="latitudInicial" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4}/>
                            <NumberField label="resources.otros_elementos.fields.longitudShow" source="longitudInicial" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/> 
                            
                            <Typography variant="b" component="b">{translate('dynatest.resources.otros_elementos.subTitle.coordenadasFinales')}</Typography>
                            <Divider fullWidth style={{width:'300%' , marginTop :'10px'}} /> 
                            {/* <PRField label="Abscisa" id="ingresoAbFin" source="abFin" fullWidth formClassName={classes.grid_cont4}/> */}
                            <NumberField label="resources.otros_elementos.fields.latitudShow" source="latitudFinal" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4}/>
                            <NumberField label="resources.otros_elementos.fields.longitudShow" source="longitudFinal" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont3}/>
                            <GMapField style={{width : "100%"}}
                                defaultZoom={12}                                    
                                defaultCenter={{ lat: 4.7567039, lng: -74.0311056 }}
                                source="ubicacionMapa"
                                multipleMarkers="true"
                                googleKey="AIzaSyCivTXzJBYRBK8nosBYRFXnv-hGXIbhnYY"
                                icon= {imagen} 
                            /> 
                        </FormTab> 
                        <FormTab label="dynatest.resources.otros_elementos.tabs.tabsTitle.caracteristicas">
                            <br/>
                            <TextField source="interseccion" label="resources.otros_elementos.fields.interseccion" formClassName={classes.grid_cont4s}/>
                            <TextField source="material" label="resources.otros_elementos.fields.material" formClassName={classes.grid_cont4}/>
                            <TextField source="flujo_peatonal" label="resources.otros_elementos.fields.flujo_peatonal" formClassName={classes.grid_cont4}/>
                            <br/>
                            <TextField source="clase_via_intersecta" label="resources.otros_elementos.fields.clase_via_intersecta" formClassName={classes.grid_cont4s}/>
                            <TextField source="tipo_via_intersecta" label="resources.otros_elementos.fields.tipo_via_intersecta" formClassName={classes.grid_cont4}/>
                            <TextField source="reductores_velocidad" label="resources.otros_elementos.fields.reductores_velocidad" formClassName={classes.grid_cont4}/>
                            <br/>
                            {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/IDES" &&
                                <div className={classes.subtittle}><strong>{translate('dynatest.resources.otros_elementos.subTitle.caracteristicasPasoDesnivel')}</strong></div>
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/IDES" &&
                                <Divider fullWidth />
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/IDES" &&
                                <TextField label="resources.otros_elementos.fields.largo" source="largo" formClassName={classes.grid_cont4s}/>
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/IDES" &&
                                <TextField label="resources.otros_elementos.fields.carriles" source="carriles" formClassName={classes.grid_cont4}/>
                            }
                            {controllerProps.record && controllerProps.record.tipoElemento  === "/tipo_elementos/IDES" &&
                                <TextField label="resources.otros_elementos.fields.sentidoCarac" source="sentidoDesnivel" formClassName={classes.grid_cont4}/> 
                            }
                        </FormTab> 
                        <FormTab label="dynatest.resources.otros_elementos.tabs.tabsTitle.detalles">
                            <br></br>
                            <TextField label="resources.otros_elementos.fields.estado" fullWidth source="estado" formClassName={classes.grid_cont4s}/>                          
                            {controllerProps.record && controllerProps.record.estado  === translate('dynatest.resources.otros_elementos.select.estado.Reemplazo') &&
                                <TextField label="resources.otros_elementos.fields.motivo" source="motivo" fullWidth formClassName={classes.grid_cont4}/>                          
                            }
                            {controllerProps.record && controllerProps.record.estado  === translate('dynatest.resources.otros_elementos.select.estado.Reemplazo') &&
                                <ReferenceField
                                    label="resources.otros_elementos.fields.elementoReemplazo_id"
                                    source="elementoReemplazo_id"
                                    reference="otros_elementos"
                                    linkType={false}
                                    fullWidth
                                    formClassName={classes.grid_cont3}
                                >
                                    <TextField source="fullCodigoConsecutivo" />
                                </ReferenceField>
                            }
                            {controllerProps.record && controllerProps.record.estado  !== translate('dynatest.resources.otros_elementos.select.estado.Reemplazo') &&
                                <br/>
                            }
                            <br/>
                            
                            <TextField label="resources.otros_elementos.fields.estados" source="estados" fullWidth formClassName={classes.grid_cont4_f}/>
                            <br/>
                            <DateField  label="resources.otros_elementos.fields.fecha_instalacion" source="fecha_instalacion" fullWidth formClassName={classes.grid_cont4s}/>                          
                            <TextField  label="resources.otros_elementos.fields.proveedor" source="proveedor_.razonSocial" fullWidth formClassName={classes.grid_cont4}/>                          
                            <NumberField label="resources.otros_elementos.fields.garantia" source="garantia" fullWidth style={{display: "flex", justifyContent: "flex-end", marginRight : "30%"}} formClassName={classes.grid_cont4}/>
                            <br/>
                            <BooleanField label="dynatest.resources.otros_elementos.boolean.mantenimiento" source="mantenimiento"  formClassName={classes.grid_cont12}/>
                            <br></br>
                            <TextField label="resources.otros_elementos.fields.observaciones" source="observaciones" fullWidth formClassName={classes.grid_cont12}/> 

                            
                        </FormTab> 
                        <FormTab label="dynatest.resources.otros_elementos.tabs.tabsTitle.auditoria">
                            <br></br>
                            <DateField source="createdAt" label="resources.otros_elementos.fields.createdAt" fullWidth formClassName={classes.grid_cont4} showTime/> 
                            <TextField source="createdBy" label="resources.otros_elementos.fields.createdBy" fullWidth formClassName={classes.grid_cont4}/>
                            <TextField source="createdIn" label="resources.otros_elementos.fields.createdEn" fullWidth formClassName={classes.grid_cont3} />
                            <span></span>               
                            <DateField source="updatedAt" label="resources.otros_elementos.fields.updatedAt" fullWidth formClassName={classes.grid_cont4} showTime/>
                            <TextField source="updatedBy" label="resources.otros_elementos.fields.updatedBy" fullWidth formClassName={classes.grid_cont4}/>
                            <TextField source="updatedIn" label="resources.otros_elementos.fields.updatedEn" fullWidth formClassName={classes.grid_cont3} />
                            <span></span>    
                        </FormTab> 
        
                    </TabbedForm>
                </ShowView>
            }
        </ShowController>
)}));