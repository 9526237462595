import React, { useState, Fragment } from 'react';
import {
    BooleanInput, ReferenceInput, SelectInput, Toolbar, SaveButton, DateInput, FormTab, TabbedForm, required,
    TextInput, Query, Loading, NumberInput, FormDataConsumer, ArrayInput, ReferenceField, SimpleFormIterator, AutocompleteInput
} from 'react-admin';
import { styles } from './../../../EstilosGrid';
import { Edit } from './../../Edit';
import Grid from '@material-ui/core/Grid';
import CancelIcon from '@material-ui/icons/Cancel';
import { withStyles } from '@material-ui/core';
import config from '../../../config/config';
import { REDUX_FORM_NAME } from 'react-admin';
import { PRInput } from '../../../inputs/PRInput';
import { change } from 'redux-form';
import EditElementosMantenimientoParticular from './../localHelpers/EditElementosMantenimientoParticular';
import { translate } from 'react-admin';

import EditElements from './../EditElements';
let urlAppjs = config.UrlServer;

export const PoliticasMantenimientoParticularEdit = translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;

    const LinkCancelar = () => {
        let redirect = "elemento_reds"
        return <a href={urlAppjs + redirect} class="cancelar" ><CancelIcon style={{ width: '0.8em', paddingRight: '8px' }} />CANCELAR</a>;
    }
    const CustomToolbar = ({ basePath, data, ...props }) => (
        <Toolbar {...props} >
            <SaveButton />
            <LinkCancelar />
        </Toolbar>
    );

    const [getSistema, setSistema] = useState();
    const regSistema = (value) => {
        // let lenghtObject = Object.keys(value);
        // let valor = '';
        // for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        // console.log("sistema->", valor)
        setSistema(value);
    }

    const [getTipoElemento, setTipoElemento] = useState();
    const regTipoElemento = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setTipoElemento(valor)
    }

    const [getUnidadFuncional, setUnidadFuncional] = useState();
    const validarUnidadFuncional = (value) => {
        setUnidadFuncional(value);
    }
    const [getTramo, setTramo] = useState();
    const validarTramo = (value) => {
        setTramo(value);
    }

    const [getAbscisaInicial, setAbscisaInicial] = useState(0);
    const validarAbscisaInicial = (value) => {
        if (value) {
            console.log(value)
            var val = value.split("K")[1].split("+")
            val = val[0] + "." + val[1];
            val = parseFloat(val);
            setAbscisaInicial(val);
        }
    }

    const [getAbscisaFinal, setAbscisaFinal] = useState(0);
    const validarAbscisaFinal = (value) => {
        if (value) {
            var val = value.split("K")[1].split("+")
            val = val[0] + "." + val[1];
            val = parseFloat(val);
            setAbscisaFinal(val);
        }
    }

    return (
        <Edit  {...props} title="dynatest.resources.politicas_mantenimiento_particulars.title.editTitle" redirect="list">
            <TabbedForm
                redirect="list"
            //toolbar={<CustomToolbar />}
            >
                {/* ------ ELEMENTO DE MANTENIMIENTO ------ */}
                <FormTab label="dynatest.resources.politicas_mantenimiento_particulars.tabs.tabsTitle.identificacion" redirect="list" >
                    <Grid container justify="flex-start" alignItems="flex-end" spacing={24}>
                        <Grid item xs={6}>
                            <TextInput label="resources.politicas_mantenimiento_particulars.fields.nombre" source="nombre" validate={[required()]} fullWidth />
                        </Grid>
                        <FormDataConsumer>
                            {
                                ({ formData, dispatch, ...rest }) => (
                                    <Fragment>
                                        <Grid item xs={3}>
                                            <ReferenceInput
                                                label="resources.politicas_mantenimiento_particulars.fields.sistema"
                                                source="sistema"
                                                reference="sistemas"
                                                disabled
                                                filter={{
                                                    visibleOnPolicies: 1
                                                }}
                                                validate={[required(), regSistema]}
                                                onChange={value => dispatch(
                                                    change(REDUX_FORM_NAME, 'tipoElemento', null)
                                                )}
                                                fullWidth
                                            >
                                                <SelectInput optionText="nombreEnPoliticas" fullWidth />
                                            </ReferenceInput>
                                        </Grid>
                                        <Grid item xs={3}>
                                            {formData.sistema != "/sistemas/SEN" ?
                                                <ReferenceInput
                                                    disabled
                                                    label="resources.politicas_mantenimiento_particulars.fields.tipoElemento"
                                                    source="tipoElemento"
                                                    reference="tipo_elementos"
                                                    sort={{ field: 'nombre', order: 'ASC' }}
                                                    fullWidth
                                                    validate={[required()]}
                                                    onChange={regTipoElemento}
                                                    filter={{
                                                        visibleOnPolicies: 1,
                                                        sistema: getSistema
                                                    }}
                                                    formClassName={classes.fieldCol9}
                                                >
                                                    <AutocompleteInput optionText="nombre" options={{ fullWidth: true, disabled: true }} />
                                                </ReferenceInput>
                                                :
                                                <ReferenceInput
                                                    disabled
                                                    label="resources.politicas_mantenimiento_particulars.fields.tipoElementoSenal"
                                                    source="tipoElementoSenal"
                                                    reference="tipo_elemento_senals"
                                                    fullWidth
                                                    validate={[required()]}
                                                    onChange={regTipoElemento}
                                                    formClassName={classes.fieldCol9}
                                                >
                                                    <AutocompleteInput optionText="nombre" options={{ fullWidth: true, disabled: true }} />
                                                </ReferenceInput>
                                            }
                                        </Grid>
                                    </Fragment>
                                )
                            }
                        </FormDataConsumer>
                    </Grid>
                    <FormDataConsumer>
                        {
                            ({ formData, dispatch, ...rest }) => (
                                <Fragment>
                                    <Grid container spacing={24}>
                                        <Grid item xs={3}>
                                            <ReferenceInput
                                                label="resources.politicas_mantenimiento_particulars.fields.unidadFuncional"
                                                source="unidadFuncional"
                                                reference="unidad_funcionals"
                                                disabled
                                                onChange={value => dispatch(
                                                    change(REDUX_FORM_NAME, 'tramo', null)
                                                )}
                                                validate={[required(), validarUnidadFuncional]}
                                            >
                                                <AutocompleteInput optionText="fullCodigos" options={{ fullWidth: true, disabled: true }} fullWidth />
                                            </ReferenceInput>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <ReferenceInput
                                                label="resources.politicas_mantenimiento_particulars.fields.tramo"
                                                source="tramo"
                                                reference="tramos"
                                                disabled
                                                validate={[validarTramo]}
                                                filter={{
                                                    unidadFuncional: getUnidadFuncional
                                                }}
                                            >
                                                <AutocompleteInput optionText="fullCodigos" options={{ fullWidth: true, disabled: true }} fullWidth />
                                            </ReferenceInput>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <PRInput label="resources.politicas_mantenimiento_particulars.fields.abInic" source="abInic" disabled fullWidth
                                            // validate={[validarAbscisaInicial]} 
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <PRInput label="resources.politicas_mantenimiento_particulars.fields.abFin" source="abFin" disabled fullWidth
                                            // validate={[validarAbscisaFinal]} 
                                            />
                                        </Grid>
                                    </Grid>
                                </Fragment>
                            )
                        }
                    </FormDataConsumer>
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <FormDataConsumer>
                                {
                                    ({ formData, dispatch, ...rest }) => (
                                        <EditElementosMantenimientoParticular
                                            parentProps={formData}
                                            unidadFuncional={formData.unidadFuncional}
                                            tramo={formData.tramo}
                                            tipoElemento={formData.tipoElemento}
                                            tipoElementoSenal={formData.tipoElementoSenal}
                                            abInicio={formData.abInic}
                                            abFin={formData.abFin}
                                        />
                                    )}
                            </FormDataConsumer>
                        </Grid>
                    </Grid>
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <div className={classes.fieldCol12}>

                            </div>
                        </Grid>
                    </Grid>
                </FormTab>
            </TabbedForm>
        </Edit>
    )

}));
