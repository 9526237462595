import React from 'react';
import { TabbedShowLayout, Tab, TextField, DateField, NumberField, BooleanField, SelectField } from 'react-admin';
import { Show } from './../../Show';
import { BulkDeleteWithConfirmButton } from 'react-admin';
import { Fragment } from 'react';
import { translate } from 'react-admin';
const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props} />
    </Fragment>
);


export const DeterioroPersShow = translate(props => (
    <Show  {...props} title='dynatest.resources.tipo_deterioro_pers.title.showTitle' bulkActionButtons={<PostBulkActionButtons />}>
        <TabbedShowLayout>
            <Tab label="dynatest.resources.tipo_deterioro_pers.tabs.reparacionPers">
                <SelectField source='tipoSuperficie' choices={[
                    { id: 'PFLEX', name: 'dynatest.resources.tipo_deterioro_pers.select.tipoSuperficie.PFLEX' },
                    { id: 'PRIGD', name: 'dynatest.resources.tipo_deterioro_pers.select.tipoSuperficie.PRIGD' },
                    { id: 'PART', name: 'dynatest.resources.tipo_deterioro_pers.select.tipoSuperficie.PART'},
                    { id: 'POTR', name: 'dynatest.resources.tipo_deterioro_pers.select.tipoSuperficie.POTR'},
                ]} />

                <NumberField source="codigoAstm" />
                <SelectField source='nivelSeveridad' choices={[
                    { id: 1, name: 'dynatest.resources.tipo_deterioro_pers.select.nivelSeveridad.1' },
                    //{ id: 2, name: 'dynatest.resources.tipo_deterioro_pers.select.nivelSeveridad.2' },
                    { id: 3, name: 'dynatest.resources.tipo_deterioro_pers.select.nivelSeveridad.3' },
                ]} />
                

                <BooleanField label="resources.tipo_deterioro_pers.fields.estado" source="estado" />
                <TextField label="resources.tipo_deterioro_pers.fields.nombre" source="nombre" />
                <TextField label="resources.tipo_deterioro_pers.fields.unidad" source="unidad" />
                <TextField label="resources.tipo_deterioro_pers.fields.tipoReparacionMedia" source="tipoReparacionMedia_.nombre" />
                <TextField label="resources.tipo_deterioro_pers.fields.tipoReparacionAlta" source="tipoReparacionAlta_.nombre" />
            </Tab>
            <Tab label="dynatest.resources.tipo_deterioro_pers.tabs.auditoria">
                <DateField label="resources.tipo_deterioro_pers.fields.createdAt" source="createdAt" showTime/>
                <TextField label="resources.tipo_deterioro_pers.fields.createdBy" source="createdBy" />
                <div></div>
                <DateField label="resources.tipo_deterioro_pers.fields.updatedAt" source="updatedAt" showTime/>
                <TextField label="resources.tipo_deterioro_pers.fields.updatedBy" source="updatedBy" />
                <div></div>
            </Tab>
        </TabbedShowLayout>
    </Show>
));