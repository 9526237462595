import React from 'react';
import { TextField, NumberField, BooleanField, SelectField } from 'react-admin';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Switch from '@material-ui/core/Switch';
import config from '../../config/config';
import { translate } from 'react-admin';
let urlAppjs = config.UrlServer;

class TablaTuneles extends React.Component {

  constructor(props) {
    super(props)
    this.state = { TablaEstructura: [] }


  }

  componentWillMount() {
    var urlApi = urlAppjs + "/" + this.props.tabla + "/" + this.props.id;

    fetch(urlApi)
      .then((response) => {
        return response.json()
      })
      .then((TablaEstructura) => {
        this.setState({ TablaEstructura: TablaEstructura })
      })
  }

  render() {
    let Datos = Object.values(this.state.TablaEstructura);

    // console.log(Datos);
    console.log(this.state.TablaEstructura)

    if (Datos.length > 0) {
      const { translate } = this.props;
      return (
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="right">{translate('dynatest.resources.tuneles.table.titles.Costado')}</TableCell>
              <TableCell align="right">{translate('dynatest.resources.tuneles.table.titles.Cantidad')}</TableCell>
              <TableCell align="right">{translate('dynatest.resources.tuneles.table.titles.Separacion')}</TableCell>
              <TableCell align="right">{translate('dynatest.resources.tuneles.table.titles.Longitud')}</TableCell>
              <TableCell align="right">{translate('dynatest.resources.tuneles.table.titles.Ancho')}</TableCell>
              <TableCell align="right">{translate('dynatest.resources.tuneles.table.titles.Altura')}</TableCell>
              <TableCell align="right">{translate('dynatest.resources.tuneles.table.titles.Pendiente')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>

            <TableRow >
              <TableCell component="th"  >
              {translate('dynatest.resources.tuneles.table.nameRows.BahiasParqueo')}
              </TableCell>
              <TableCell align="right">
                {this.state.TablaEstructura.costado1}
              </TableCell>
              <TableCell align="right">
                {this.state.TablaEstructura.cantidad1}
              </TableCell>
              <TableCell align="right">
                {this.state.TablaEstructura.separacion1}
              </TableCell>
              <TableCell align="right">
                {this.state.TablaEstructura.longitud1}
              </TableCell>
              <TableCell align="right">
                {this.state.TablaEstructura.ancho1}
              </TableCell>
              <TableCell align="right">
                {this.state.TablaEstructura.altura1}
              </TableCell>
              <TableCell align="right">
                {this.state.TablaEstructura.pendiente1}
              </TableCell>
            </TableRow>
            <TableRow >
              <TableCell component="th"  >
              {translate('dynatest.resources.tuneles.table.nameRows.CarrilEmergencia')}
              </TableCell>
              <TableCell align="right">
                {this.state.TablaEstructura.costado2}
              </TableCell>
              <TableCell align="right">
                {this.state.TablaEstructura.cantidad2}
              </TableCell>
              <TableCell align="right">
                {this.state.TablaEstructura.separacion2}
              </TableCell>
              <TableCell align="right">
                {this.state.TablaEstructura.longitud2}
              </TableCell>
              <TableCell align="right">
                {this.state.TablaEstructura.ancho2}
              </TableCell>
              <TableCell align="right">
                {this.state.TablaEstructura.altura2}
              </TableCell>
              <TableCell align="right">
                {this.state.TablaEstructura.pendiente2}
              </TableCell>
            </TableRow>
            <TableRow >
              <TableCell component="th"  >
                {translate('dynatest.resources.tuneles.table.nameRows.GaleriasEvacuacion')}
            </TableCell>
            <TableCell align="right">
                {this.state.TablaEstructura.costado3}
              </TableCell>
              <TableCell align="right">
                {this.state.TablaEstructura.cantidad3}
              </TableCell>
              <TableCell align="right">
                {this.state.TablaEstructura.separacion3}
              </TableCell>
              <TableCell align="right">
                {this.state.TablaEstructura.longitud3}
              </TableCell>
              <TableCell align="right">
                {this.state.TablaEstructura.ancho3}
              </TableCell>
              <TableCell align="right">
                {this.state.TablaEstructura.altura3}
              </TableCell>
              <TableCell align="right">
                {this.state.TablaEstructura.pendiente3}
              </TableCell>
            </TableRow>
            <TableRow >
              <TableCell component="th"  >
                {translate('dynatest.resources.tuneles.table.nameRows.NichoEmergenciaSOS')}
            </TableCell>
            <TableCell align="right">
                {this.state.TablaEstructura.costado4}
              </TableCell>
              <TableCell align="right">
                {this.state.TablaEstructura.cantidad4}
              </TableCell>
              <TableCell align="right">
                {this.state.TablaEstructura.separacion4}
              </TableCell>
              <TableCell align="right">
                {this.state.TablaEstructura.longitud4}
              </TableCell>
              <TableCell align="right">
                {this.state.TablaEstructura.ancho4}
              </TableCell>
              <TableCell align="right">
                {this.state.TablaEstructura.altura4}
              </TableCell>
              <TableCell align="right">
                {this.state.TablaEstructura.pendiente4}
              </TableCell>
            </TableRow>
            <TableRow >
              <TableCell component="th"  >
                {translate('dynatest.resources.tuneles.table.nameRows.NichoControlIncendios')}
              </TableCell>
              <TableCell align="right">
                {this.state.TablaEstructura.costado5}
              </TableCell>
              <TableCell align="right">
                {this.state.TablaEstructura.cantidad5}
              </TableCell>
              <TableCell align="right">
                {this.state.TablaEstructura.separacion5}
              </TableCell>
              <TableCell align="right">
                {this.state.TablaEstructura.longitud5}
              </TableCell>
              <TableCell align="right">
                {this.state.TablaEstructura.ancho5}
              </TableCell>
              <TableCell align="right">
                {this.state.TablaEstructura.altura5}
              </TableCell>
              <TableCell align="right">
                {this.state.TablaEstructura.pendiente5}
              </TableCell>
            </TableRow>
            <TableRow >
              <TableCell component="th"  >
                {translate('dynatest.resources.tuneles.table.nameRows.NichoEquiposElectricos')}
              </TableCell>
              <TableCell align="right">
                {this.state.TablaEstructura.costado6}
              </TableCell>
              <TableCell align="right">
                {this.state.TablaEstructura.cantidad6}
              </TableCell>
              <TableCell align="right">
                {this.state.TablaEstructura.separacion6}
              </TableCell>
              <TableCell align="right">
                {this.state.TablaEstructura.longitud6}
              </TableCell>
              <TableCell align="right">
                {this.state.TablaEstructura.ancho6}
              </TableCell>
              <TableCell align="right">
                {this.state.TablaEstructura.altura6}
              </TableCell>
              <TableCell align="right">
                {this.state.TablaEstructura.pendiente6}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )
    } else {
      const { translate } = this.props;
      return (
        <p className="text-center">{translate('dynatest.resources.tuneles.table.message')}</p>
      )
    }

  }
}

export default translate(TablaTuneles);