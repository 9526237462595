import React, { useState } from 'react';
import {
    Filter, List, Datagrid, TextField, NumberField, EditButton, ShowButton, CloneButton, SelectInput,
    ReferenceInput, ReferenceField, AutocompleteInput, FormDataConsumer, DateField
} from 'react-admin';
import Button from '@material-ui/core/Button';
import { BulkDeleteWithConfirmButton, Loading } from 'react-admin';
import { Fragment } from 'react';
import config from '../../config/config';
import { PRField } from '../../fields/PRField';
import { translate } from 'react-admin';
let urlAppjs = config.UrlServer;

const FiltroRespuestaEvaluacionNovedades = translate((props) => {
    const { translate } = props;

    const [uf, setUf] = useState('');
    const ufSet = (value) => {
        setUf(value);
        //console.log(value);
    }

    const [tramo, setTramo] = useState('none');
    const tramoSet = (value) => {
        setTramo(value);
    }

    const [acc, setAcc] = useState('');
    const accSet = (value) => {
        setAcc(value);
        //console.log(value);
    }

    return (
        <Filter {...props}>
            <ReferenceInput
                label="resources.respuesta_evaluacion_novedads.fields.unidadFuncional"
                source="unidadFuncional"
                reference="unidad_funcionals"
                filterToQuery={searchText => ({ codigo: searchText })}
                sort={{ field: 'codigo', order: 'asc' }}
                validate={[ufSet]}
                alwaysOn
                fullWidth
            >
                <AutocompleteInput options={{ fullWidth: true }} optionText="fullCodigos" />
            </ReferenceInput>
            <ReferenceInput
                label="resources.respuesta_evaluacion_novedads.fields.tramo"
                source="tramo"
                alwaysOn
                reference="tramos"
                filter={{ unidadFuncional: uf }}
                validate={[tramoSet]}
                filterToQuery={searchText => ({ codigo: searchText })}
                sort={{
                    field: 'codigo',
                    order: 'ASC',

                }}>
                <AutocompleteInput options={{ fullWidth: true }} optionText="fullCodigos" />
            </ReferenceInput>

            <ReferenceInput
                label="resources.respuesta_evaluacion_novedads.fields.segmento"
                source="segmento"
                reference="segmentos"
                alwaysOn
                sort={{ field: 'codigo', order: 'ASC' }}
                filter={{ tramo: tramo }}
                filterToQuery={searchText => ({ codigo: searchText })}
            >
                <AutocompleteInput options={{ fullWidth: true }} optionText="fullCodigos" />
            </ReferenceInput>
            <ReferenceInput
                label="resources.respuesta_evaluacion_novedads.fields.sistema"
                source="sistema"
                reference="sistemas"
                filter={{ id: ["ALR", "CLZ", "DRE", "OTR", "PTS", "RDS", "SEG", "SEN"] }}
                filterToQuery={searchText => ({ nombre: searchText })}
                sort={{ field: 'nombre', order: 'asc' }}
                validate={[ufSet]}
                alwaysOn
                fullWidth
            >
                <AutocompleteInput options={{ fullWidth: true }} optionText="nombre" />
            </ReferenceInput>
            
            <SelectInput
                label="resources.respuesta_evaluacion_novedads.fields.estado"
                source="estado"
                sort={{ field: 'name', order: 'ASC' }}
                validate={[accSet]}
                choices={[
                    { id: translate('dynatest.resources.respuesta_evaluacion_novedads.select.estado.activo'), name: "dynatest.resources.respuesta_evaluacion_novedads.select.estado.activo" },
                    { id: translate('dynatest.resources.respuesta_evaluacion_novedads.select.estado.proceso'), name: "dynatest.resources.respuesta_evaluacion_novedads.select.estado.proceso" },
                    { id: translate('dynatest.resources.respuesta_evaluacion_novedads.select.estado.cerrado'), name: 'dynatest.resources.respuesta_evaluacion_novedads.select.estado.cerrado' }
                ]}
                fullWidth
                alwaysOn
                resettable
            />
            <SelectInput
                label="Tipo de Registro"
                source="tipoRegistro"
                sort={{ field: 'name', order: 'ASC' }}
                validate={[accSet]}
                choices={[
                    { id: translate('dynatest.resources.respuesta_evaluacion_novedads.select.tipoRegistro.novedad'), name: "dynatest.resources.respuesta_evaluacion_novedads.select.tipoRegistro.novedad" },
                    { id: translate('dynatest.resources.respuesta_evaluacion_novedads.select.tipoRegistro.medicionId'), name: "dynatest.resources.respuesta_evaluacion_novedads.select.tipoRegistro.medicion" },
                ]}
                fullWidth
                alwaysOn
                resettable
            />
            {/* <SelectInput
                label="Aprobación"
                source="aprobacion"
                sort={{ field: 'name', order: 'ASC' }}
                validate={[accSet]}
                choices={[
                    { id: "Aprobado", name: "Aprobado" },
                    { id: "Rechazado", name: "Rechazado" },
                ]}
                fullWidth
                alwaysOn
                resettable
            /> */}
        </Filter>
    )
});

export const RespuestaEvaluacionNovedadsList = translate(({ permissions, ...props }) => {
    const { translate } = props;
    const PostBulkActionButtons = props => (
        <Fragment>
            <BulkDeleteWithConfirmButton {...props} label="dynatest.resources.respuesta_evaluacion_novedads.mensajes.borrar" />
        </Fragment>
    );

    const { codigo } = props;
    if (!permissions) return <Loading />;
    return (

        <List
            title="dynatest.resources.respuesta_evaluacion_novedads.title.listTitle" {...props}
            sort={{ field: 'updatedAt', order: 'DESC' }}
            filters={<FiltroRespuestaEvaluacionNovedades />}
            bulkActionButtons={permissions['respuestaEvaluacionNovedad']  == 1 ? <PostBulkActionButtons />: null}>
            <Datagrid>
                {/* <ReferenceField label="Sistema" source="sistema" reference="sistemas" linkType={false} >
                    <TextField source="nombre" />
                </ReferenceField> */}
                <TextField label="resources.respuesta_evaluacion_novedads.fields.smartCode" source="smartCode" linkType={false} fullWidth />
                <ReferenceField label="resources.respuesta_evaluacion_novedads.fields.sistema" source="sistema" reference="sistemas" linkType={false} >
                    <TextField source="nombre" />
                </ReferenceField>
                <PRField label="resources.respuesta_evaluacion_novedads.fields.abscisaIni" source="abscisaIni" linkType={false} fullWidth />
                <TextField label="resources.respuesta_evaluacion_novedads.fields.tipoRegistro" source="tipoRegistro" linkType={false} fullWidth />
                <DateField label="resources.respuesta_evaluacion_novedads.fields.fechaRegistro" source="fechaRegistro" linkType={false} fullWidth />
                <TextField label="resources.respuesta_evaluacion_novedads.fields.estado" source="estado" linkType={false} fullWidth />
                {/* <TextField label="Tipo de registro" source="tipoRegistroGeneric" linkType={false} fullWidth /> */}
                {/* <TextField label="Aprobación" source="aprobacion" linkType={false} fullWidth /> */}
                {permissions['respuestaEvaluacionNovedad'] == 1 || permissions['respuestaEvaluacionNovedad'] == 2 || permissions['respuestaEvaluacionNovedad'] == 4 ? <ShowButton/>: null}
                {permissions['respuestaEvaluacionNovedad'] == 1 || permissions['respuestaEvaluacionNovedad'] == 2 ? <EditButton/>: null}
            </Datagrid>
        </List>
    )
});