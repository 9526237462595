import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';


/**
 * @param {titulo de la modal} title //string
 * @param {evento al guardar} onSave //function
 * @param {controlador para devolver un ref} controller //function that returns a ref
 * @param {content} children 
 * @param {se puede cancelar} isCancellable //boolean
 */
export default function SimpleModal(props) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (typeof props.controller == "function") {
    props.controller({
      handleOpen: handleOpen,
      handleClose: handleClose
    })
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.children}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {props.isCancellable &&
            <Button
              onClick={() => { handleClose() }}
              variant="outlined"
            >
              <CancelIcon color="default" />
            </Button>
          }
          <Button
            // onClick={() => { handleClose(); props.onSave() }} //DEBE VALIDAR CAMPOS ANTES DE CERRARCE
            onClick={() => { props.onSave() }}
            variant="outlined"
          >
            <CheckCircleIcon color="primary" />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}