import React, { useState, Fragment } from 'react';
import {REDUX_FORM_NAME} from 'react-admin';
import {change} from 'redux-form';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
    FormDataConsumer, AutocompleteInput, required, SelectInput
} from 'react-admin';
import { styles } from './../../EstilosGrid';
import Grid from '@material-ui/core/Grid';
import config from '../../config/config';
import { translate } from 'react-admin';
let urlAppjs = config.UrlServer;

/**
 * @param tipoElemento = la entidad a apuntar en el fetcher
 * @param abInicio = la abscisa inicial para el filtro
 * @param abFin = la abscisa final para el filtro
 * 
 */

class AsociarServiciosEmergencia extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            datos : {
                contacto_emergencias: [],
            }
        }
        
        this.fetcher.bind(this);

    }

    componentDidMount() {

        // this.fetcher("departamentos", "order[nombre]=ASC");
        this.fetcher("contacto_emergencias", "order[empresa]=ASC")
        
    }
    componentDidUpdate(){
        
    }

    fetcher(target, otherFilters = null) {

        var urlPart = "/" + target + "?pagination=off";

        if(Array.isArray(otherFilters)) {
            otherFilters.map(filter => {
                urlPart = urlPart + "&" + filter;
            })
        } else if(otherFilters !== null) {
            urlPart = urlPart + "&" + otherFilters;
        }

        urlPart += "&proyecto=" + localStorage.getItem('id_project')
        fetch(urlAppjs + urlPart,
            {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                return response.json()
            })
            .then((Elementos) => {
                var datos = [];
                Elementos["hydra:member"].map(item => {
                    datos.push({
                        id : item["@id"],
                        name : item.empresa
                    })
                })
                // console.log(datos);
                this.state.datos[target] = datos;
            }).then(()=> {
                this.forceUpdate();
            })
    }
    

    render() {
        const { translate } = this.props;
        const { classes } = this.props;
        const { getSource } = this.props;
        const changedEmergencia = (value) => {
            let lenghtObject = Object.keys(value);
            let valor = '';        
            for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; } 
            // console.log(valor);
            this.fetcher("contacto_emergencias", [
                "order[empresa]=ASC",
                "grupo=" + valor
            ])
        }
        return(
            <FormDataConsumer >
                {({formData, scopedFormData, dispatch, ...rest}) => (
                    <Grid container spacing={24}>
                        <Grid item xs={12}>
                            <SelectInput label="resources.disponibilidad_vias.fields.grupoEmergencias" source={getSource('serviciosGrupoEmergencias')} choices={[
                                { id: translate('dynatest.resources.disponibilidad_vias.select.grupoEmergencias.POBSupervisionVial'), name: 'dynatest.resources.disponibilidad_vias.select.grupoEmergencias.POBSupervisionVial' },
                                { id: translate('dynatest.resources.disponibilidad_vias.select.grupoEmergencias.POBAdministrativa'), name: 'dynatest.resources.disponibilidad_vias.select.grupoEmergencias.POBAdministrativa' },
                                { id: translate('dynatest.resources.disponibilidad_vias.select.grupoEmergencias.POBRadioOperacion'), name: 'dynatest.resources.disponibilidad_vias.select.grupoEmergencias.POBRadioOperacion' },
                                { id: translate('dynatest.resources.disponibilidad_vias.select.grupoEmergencias.POBGruas'), name: 'dynatest.resources.disponibilidad_vias.select.grupoEmergencias.POBGruas' },
                                { id: translate('dynatest.resources.disponibilidad_vias.select.grupoEmergencias.POBCarroTaller'), name: 'dynatest.resources.disponibilidad_vias.select.grupoEmergencias.POBCarroTaller' },
                                { id: translate('dynatest.resources.disponibilidad_vias.select.grupoEmergencias.POBAmbulancia'), name: 'dynatest.resources.disponibilidad_vias.select.grupoEmergencias.POBAmbulancia' },
                                { id: translate('dynatest.resources.disponibilidad_vias.select.grupoEmergencias.POBPeajes'), name: 'dynatest.resources.disponibilidad_vias.select.grupoEmergencias.POBPeajes' },
                                { id: translate('dynatest.resources.disponibilidad_vias.select.grupoEmergencias.Interventoria'), name: 'dynatest.resources.disponibilidad_vias.select.grupoEmergencias.Interventoria' },
                                { id: translate('dynatest.resources.disponibilidad_vias.select.grupoEmergencias.ServiciosMedicos'), name: 'dynatest.resources.disponibilidad_vias.select.grupoEmergencias.ServiciosMedicos' },
                                { id: translate('dynatest.resources.disponibilidad_vias.select.grupoEmergencias.Policia'), name: 'dynatest.resources.disponibilidad_vias.select.grupoEmergencias.Policia' },
                                { id: translate('dynatest.resources.disponibilidad_vias.select.grupoEmergencias.Ejercito'), name: 'dynatest.resources.disponibilidad_vias.select.grupoEmergencias.Ejercito' },
                                { id: translate('dynatest.resources.disponibilidad_vias.select.grupoEmergencias.DefensaCivil'), name: 'dynatest.resources.disponibilidad_vias.select.grupoEmergencias.DefensaCivil' },
                                { id: translate('dynatest.resources.disponibilidad_vias.select.grupoEmergencias.Bomberos'), name: 'dynatest.resources.disponibilidad_vias.select.grupoEmergencias.Bomberos' },
                                { id: translate('dynatest.resources.disponibilidad_vias.select.grupoEmergencias.CentroEducativo'), name: 'dynatest.resources.disponibilidad_vias.select.grupoEmergencias.CentroEducativo' },
                                { id: translate('dynatest.resources.disponibilidad_vias.select.grupoEmergencias.Alcaldia'), name: 'dynatest.resources.disponibilidad_vias.select.grupoEmergencias.Alcaldia' },
                                { id: translate('dynatest.resources.disponibilidad_vias.select.grupoEmergencias.EmisoraRadial'), name: 'dynatest.resources.disponibilidad_vias.select.grupoEmergencias.EmisoraRadial' },
                                { id: translate('dynatest.resources.disponibilidad_vias.select.grupoEmergencias.Talleres'), name: 'dynatest.resources.disponibilidad_vias.select.grupoEmergencias.Talleres' },
                                { id: translate('dynatest.resources.disponibilidad_vias.select.grupoEmergencias.Maquinaria'), name: 'dynatest.resources.disponibilidad_vias.select.grupoEmergencias.Maquinaria' },
                                { id: translate('dynatest.resources.disponibilidad_vias.select.grupoEmergencias.Otros'), name: 'dynatest.resources.disponibilidad_vias.select.grupoEmergencias.Otros' },
                            ]} validate={[required()]} formClassName={classes.grid_cont3}
                            options={{fullWidth : true}}
                            onChange={value => dispatch(
                                change(REDUX_FORM_NAME, getSource('serviciosElementoEmergencias'), null)
                            ), changedEmergencia}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <SelectInput 
                                label="resources.disponibilidad_vias.fields.servElemento"
                                source={getSource('serviciosElementoEmergencias')}
                                options={{fullWidth : true}}
                                choices={this.state.datos.contacto_emergencias}
                                validate={required()}
                                formClassName={classes.grid_cont3}
                            />
                        </Grid>
                    </Grid>
                )}
                </FormDataConsumer>
        )
    }
}

AsociarServiciosEmergencia.propTypes = {
    classes: PropTypes.object.isRequired,
}
const AsociarServiciosEmergencias = withStyles(styles)(AsociarServiciosEmergencia);


export default translate(AsociarServiciosEmergencias);