import React, { useState, Fragment } from 'react';
import {
    NumberInput, SelectInput, ReferenceInput, TextInput, AutocompleteInput, number, required, minLength, maxLength, minValue,
    maxValue, REDUX_FORM_NAME, FormDataConsumer, ReferenceArrayInput, AutocompleteArrayInput, email
} from 'react-admin';
import { change } from 'redux-form';
import { SimpleForm } from './../SimpleForm';
import { Edit } from './../Edit';
import Grid from '@material-ui/core/Grid';
import { translate } from 'react-admin';
import { Toolbar, SaveButton } from 'react-admin';
import CancelIcon from '@material-ui/icons/Cancel';
import MunicipioArray from './../proveedores/municipioArray';

export const Servicio_publicoEdit = translate(props => {
    const { translate } = props;
    const [departamentoss, setDepartamento] = useState('');

    const ValidarDepartamento = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setDepartamento(value)
    }

    const Departamento = { departamento: departamentoss };
    return (
        <Edit  {...props} title="dynatest.resources.servicios_publicos.title.editTitle" undoable={false}>
            <SimpleForm submitOnEnter={false} redirect="list" >
                <Grid container spacing={24}>
                    <Grid item xs={4}>
                        <ReferenceInput
                            label="resources.servicios_publicos.fileds.proyecto"
                            source="proyecto"
                            reference="proyectos"
                            disabled
                            fullWidth
                            validate={[required(), ValidarDepartamento]}
                            filterToQuery={searchText => ({ nombre: searchText })}
                        >
                            <AutocompleteInput options={{ fullWidth: true, disabled: true }} optionText="nombre" fullWidth />
                        </ReferenceInput>
                    </Grid>
                    <Grid item xs={4}>
                        <SelectInput label="resources.servicios_publicos.fileds.grupo" source="grupo" choices={[
                            { id: 'Acueducto', name: 'dynatest.resources.servicios_publicos.select.grupo.acueducto' },
                            { id: 'Aseo', name: 'dynatest.resources.servicios_publicos.select.grupo.aseo' },
                            { id: 'Energía', name: 'dynatest.resources.servicios_publicos.select.grupo.energia' },
                            { id: 'Gas natural', name: 'dynatest.resources.servicios_publicos.select.grupo.gasNatural' },
                            { id: 'Otros', name: 'dynatest.resources.servicios_publicos.select.grupo.otros' },
                            { id: 'Telecomunicaciones', name: 'dynatest.resources.servicios_publicos.select.grupo.telecomunicaciones' },
                        ]} fullWidth validate={[required()]} />
                    </Grid>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={3}>
                        <SelectInput label="resources.servicios_publicos.fileds.identificacion" source="identificacion" fullWidth allowEmpty emptyValue="" choices={[
                            { id: 'CC', name: 'dynatest.resources.servicios_publicos.select.identificacion.CC' },
                            { id: 'NIT', name: 'dynatest.resources.servicios_publicos.select.identificacion.NIT' },
                            { id: 'Otro', name: 'dynatest.resources.servicios_publicos.select.identificacion.Otro' },

                        ]} validate={[required()]} />
                    </Grid>
                    <Grid item xs={3}>
                        <NumberInput label="resources.servicios_publicos.fileds.numero_identificacion" source="numero_identificacion" fullWidth validate={[required(), minLength(2), maxLength(10), minValue(1), maxValue(9999999999)]} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput label="resources.servicios_publicos.fileds.empresa" source="empresa" fullWidth validate={[required(), minLength(2), maxLength(100)]} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput label="resources.servicios_publicos.fileds.nombre" source="nombre" fullWidth validate={[minLength(2), maxLength(100)]} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput label="resources.servicios_publicos.fileds.apellido" source="apellido" fullWidth validate={[minLength(2), maxLength(100)]} />
                    </Grid>

                    <Grid item xs={4}>
                        <TextInput label="resources.servicios_publicos.fileds.direccion" source="direccion" fullWidth validate={[required(), minLength(2), maxLength(100)]} />
                    </Grid>
                    <Grid item xs={8}>
                        <FormDataConsumer>
                            {
                                ({ formData, dispatch, ...rest }) => (
                                    <Fragment>
                                        <Grid container spacing={24}>
                                            <Grid item xs={6}>
                                                <ReferenceInput
                                                    id="departamento"
                                                    value=""
                                                    label="resources.servicios_publicos.fileds.departamento"
                                                    source="departamento"
                                                    reference="departamentos"
                                                    fullWidth
                                                    sort={{ field: "nombre", order: "ASC" }}
                                                    filterToQuery={searchText => ({ nombre: searchText })}
                                                    onChange={value => dispatch(
                                                        change(REDUX_FORM_NAME, 'municipio', null)
                                                    )}
                                                    validate={[required(), ValidarDepartamento]}
                                                >
                                                    <AutocompleteInput options={{ fullWidth: true }} optionText="nombre" fullWidth />
                                                </ReferenceInput>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <ReferenceArrayInput
                                                    label="resources.servicios_publicos.fileds.municipio1"
                                                    source="municipio"
                                                    reference="municipios"
                                                    validate={[required()]}
                                                    sort={{ field: "nombre", order: "ASC" }}
                                                    filterToQuery={searchText => ({ nombre: searchText })}
                                                    filter={{
                                                        departamento: departamentoss
                                                    }}
                                                >
                                                    <AutocompleteArrayInput options={{ fullWidth: true }} optionText="nombre" fullWidth />
                                                </ReferenceArrayInput>
                                            </Grid>
                                        </Grid>
                                    </Fragment>
                                )
                            }
                        </FormDataConsumer>

                    </Grid>
                    <Grid item xs={6}>
                        <TextInput label="resources.servicios_publicos.fileds.telefono_1" source="telefono_1" fullWidth className="labelNumber" validate={[required(), number("Solo Números")]} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextInput label="resources.servicios_publicos.fileds.telefono_2" source="telefono_2" fullWidth className="labelNumber" validate={[number("Solo Números")]} />
                    </Grid>
                    <Grid item xs={12}>
                        <TextInput label="resources.servicios_publicos.fileds.correo" source="correo" fullWidth validate={[minLength(2), maxLength(100), email()]} />
                    </Grid>

                    <Grid item xs={12}>
                        <TextInput label="resources.servicios_publicos.fileds.observaciones" source="observaciones" fullWidth validate={[minLength(2), maxLength(250)]} />
                    </Grid>

                </Grid>
            </SimpleForm>
        </Edit>
    )
});