import React from 'react';
import { SelectInput,ReferenceInput, required, minLength, maxLength } from 'react-admin';
import config from '../../../../config/config';
import { func } from 'prop-types';
import { translate } from 'react-admin';
let urlAppjs = config.UrlServer;
/**
 * Atributos requeridos
 * id
 * label
 * source
 * reference
 * operation
 * validate
 */

class UniqueUF extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            fetch: [],
            error: false,
            ano:"",
            peaje:"",
            sentido:"",
            mes:"",
            oldValue : null,
            reference : "mensuals",
            id : this.props.source

        }
        this.algo = this.algo.bind(this);
        this.unique  = this.unique.bind(this);
        
    }
    algo(valor){
        
       this.setState({ mes: valor }) 
       console.log(valor);
        
    }


    // componentDidUpdate(){
    //     this.setState({ mes: this.props.source }) 
    //     var jsmes = "";
    //     jsmes = document.getElementById(this.props.source).value;    
    //     this.setState({ mes: jsmes })
    // }   
    unique(valor) {
        const { mes } = this.state;
        console.log(this.state);
        var jspeaje = "";
        if(document.getElementById("peajes_id")) {
            jspeaje = document.getElementById("peajes_id").value;
        }
        var jssentido = "";
        if(document.getElementById("sentido_id")) {
            jssentido = document.getElementById("sentido_id").value;
        }
        var jsano = ""; 
        jsano = document.getElementById("ano_id").parentNode.firstChild.innerHTML;
        var jsmes = "";
        jsmes = document.getElementById(this.props.source).value;
        // console.log("peaje->" + jspeaje);
        // console.log("sentido->" + jssentido);
        // console.log("año->" + jsano);
        // console.log("mes->" + jsmes);
        fetch(urlAppjs + "/"+this.state.reference+".json?inventario_peajes.unidadFuncional.ruta.proyecto="+localStorage.getItem('id_project')+"&pagination=false&inventario_peajes="+jspeaje+"&sentido="+jssentido+"&ano="+jsano+"&mes="+valor,

            {
                headers: {
                    'authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            .then((response) => {
                return response.json()
            })
            .then((response) => {
                this.state.fetch = response;
            });
        if (this.state.fetch.length) {
            return "Registro ya Realizado";
        }

        
        // if (document.getElementById(this.props.id)) {
        //     var texto = document.getElementById(this.props.id).value;
        //     if (this.state.oldValue == null) {
        //         this.state.oldValue = texto;
        //     }
        //     var find = this.state.fetch.find(data => data[this.props.source].toLowerCase().trim() == texto.toLowerCase().trim());
        //     switch (this.props.operation) {
        //         case "tipo":
        //             if (typeof find === 'object' && find[this.props.source].toLowerCase().trim() != this.state.oldValue.toLowerCase().trim()) {
        //                 this.state.error = true;
        //             } else {
        //                 this.state.error = false;
        //             }
        //         break;
                
        //         default:
        //             if (typeof find === 'object') {
        //                 this.state.error = true;
        //             } else {
        //                 this.state.error = false;
        //             }
        //             break;
        //     }
                
        // }
        // if (this.state.error == true) {
        //     return "El dato digitado ya se encuentra en uso.";
        // }
    }
     
         
    render() {
        const { translate } = this.props;
        const { mes } = this.state;
        console.log(mes);
                return (
                    <SelectInput
                        id={this.props.source}
                        label={this.props.label}
                        source={this.props.source}
                        style={{width:"300px"}}
                        choices={[
                            { id: '1', name: 'dynatest.resources.mensuals.select.mes.Enero' },
                            { id: '2', name: 'dynatest.resources.mensuals.select.mes.Febrero' },
                            { id: '3', name: 'dynatest.resources.mensuals.select.mes.Marzo' },
                            { id: '4', name: 'dynatest.resources.mensuals.select.mes.Abril' },
                            { id: '5', name: 'dynatest.resources.mensuals.select.mes.Mayo' },
                            { id: '6', name: 'dynatest.resources.mensuals.select.mes.Junio' },
                            { id: '7', name: 'dynatest.resources.mensuals.select.mes.Julio' },
                            { id: '8', name: 'dynatest.resources.mensuals.select.mes.Agosto' },
                            { id: '9', name: 'dynatest.resources.mensuals.select.mes.Septiembre' },
                            { id: '91', name: 'dynatest.resources.mensuals.select.mes.Octubre' },
                            { id: '92', name: 'dynatest.resources.mensuals.select.mes.Noviembre' },
                            { id: '93', name: 'dynatest.resources.mensuals.select.mes.Diciembre' }                                    
                        ]}
                        validate={[
                            this.algo,
                            this.unique,
                            required(), 
                        ]}
                        formClassName={this.props.formClassName}
                    />
                )
    }
}

export default translate(UniqueUF);