import React, {useState} from 'react';
import { List, Datagrid, EditButton, ShowButton, BulkDeleteWithConfirmButton, CardActions, FormDataConsumer, CreateButton, Loading, ExportButton, Toolbar, TextField } from 'react-admin';
import { Fragment } from 'react';
import { FField } from './../../fields/FField';
import { translate } from 'react-admin';
import config from '../../config/config';
let urlAppjs = config.UrlServer;


const PostBulkActionButtons = (props) => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
); 

const ListAction = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
        <ExportButton basePath={basePath}/>
    </CardActions>
);

// const YesActions=(state, basePath, props)=>{
//     const [valorl, setValorl] = useState(0);
//     Object.size = function(obj) {
//         var size = 0, key;
//         for (key in obj) {
//             if (obj.hasOwnProperty(key)) size++;
//         }
//         return size;
//     };
//     let largoob=Object.size(state.data);
//     //console.log(largoob)
//     //setValorl(largoob)
//     if(largoob>=1){
//         return(
//             <CardActions />
//         )
//     }
//     else{
//         return  <CreateButton basePath={state.basePath} style={{paddingTop:"13px", marginRight:"20px"}}/> 
//     } 
// }
        
export const ParametrosList = translate(({ permissions, ...props }) => {
    const { translate } = props;
    const [registros, setRegistros] = useState('Inicial');
    let idproyecto=localStorage.getItem("id_project");
    if(idproyecto){
        let urlser = window.location.hostname;
        fetch(urlAppjs+"/parametros_grupos?perPage=40&proyecto="+idproyecto)
        .then((response) => {
            return response.json()
        })
        .then((response) => {
            let largo= response["hydra:totalItems"];
            setRegistros(largo)
        })
    }
    console.log(registros)
    if (!permissions) return <Loading />; 

    if(registros != 'Inicial'){
        return(
            <List {...props}  perPage={25} tittle="Lista de Parámetros de Evaluación" sort={{ field: 'updatedAt', order: 'DESC' }} actions={ registros == 0 ? <ListAction/> : null} bulkActionButtons={permissions['parametrosEvaluacion']  == 1 ? <PostBulkActionButtons /> : null}>
                <Datagrid>                        
                   <TextField label="resources.parametros_evaluacion.fields.proyecto" source="proyectoNombre"/>
                    {permissions['parametrosEvaluacion'] == 1 || permissions['parametrosEvaluacion'] == 2 || permissions['parametrosEvaluacion'] == 4 ? <ShowButton/>: null}
                    {permissions['parametrosEvaluacion'] == 1 || permissions['parametrosEvaluacion'] == 2 ? <EditButton/>: null} 
                 </Datagrid>
             </List>   
        )
    } else {
        return <Loading />
    }   
})
{/* <WithProps>{({record,...props})=><ParametrosList record={record}/>}</WithProps> */}
const WithProps = ({children,...props}) => children(props);