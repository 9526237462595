import React from 'react';
import { List, Datagrid, TextField, EditButton, ShowButton, Filter, ReferenceInput, AutocompleteInput
} from 'react-admin';
import { Fragment } from 'react';
import {  BulkDeleteWithConfirmButton, Loading  } from 'react-admin';

const RutaFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput 
            label="segmento" 
            source="segmento"
            reference="segmentos" 
            alwaysOn 
            filter={{tipoSegmento: {id:"PFLEX"}}}
            filterToQuery={searchText => ({ codigo: searchText })} 
            sort={{ 
                field: 'codigo', 
                order: 'ASC' ,
                
            }}>
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
    </Filter>
);

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);

export const ElementoDeterioroList = ({ permissions, ...props }) => {
    if (!permissions) return <Loading />;  
    return (
    <List {...props} sort={{ field: 'id', order: 'DESC' }} title="Lista Deterioros" filters={<RutaFilter/>} bulkActionButtons={permissions['colaDePeaje']  == 1 ? <PostBulkActionButtons />: null}>
        <Datagrid>                                                    
            <TextField source="deterioros_pci" label="PCI" /> 
            <TextField source="deterioros_fecha_ultima" label="Fecha de última evaluación" />
            <TextField source="deterioros_periocidad" label="Periodicidad de medición" />
            <TextField source="fechaCalculada" label="Fecha próxima medición" />                
            {permissions['colaDePeaje'] == 1 || permissions['colaDePeaje'] == 2 || permissions['colaDePeaje'] == 4 ? <ShowButton/>: null}
            {permissions['colaDePeaje'] == 1 || permissions['colaDePeaje'] == 2 ? <EditButton/>: null}
        </Datagrid>
    </List>
)};