import React, { Fragment } from 'react';
import { List, Datagrid, TextField, EditButton, ShowButton, NumberField } from 'react-admin';
import {  BulkDeleteWithConfirmButton  } from 'react-admin';

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);

export const PreguntasList = props => (
    <List {...props} title="Lista de preguntas" bulkActionButtons={<PostBulkActionButtons />}>
        <Datagrid>          
            <TextField source="elemento_indicador_.elemento" label="Tipo elemento"/>                      
            <TextField source="pregunta" label="Pregunta"/>     
            <NumberField source="peso" label="Peso"/>
            <EditButton />
        </Datagrid>
    </List>
);