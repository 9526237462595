
import PropTypes from 'prop-types';
import dFormatter from './../format/DFormat';

/**
 * 
 * , isNumericString:true
 */

export const DField = ({ source, record = {}, props }) => dFormatter({value: record[source], displayType:'text', ...props});

DField.propTypes = {
    label: PropTypes.string,
    record: PropTypes.object,
    source: PropTypes.string.isRequired,
};

DField.defaultProps = {
    addLabel: true,
};
