import React, { useState } from 'react';
import {
  DateInput, BooleanInput, required, TabbedForm, FormTab, NumberInput, SelectInput, ReferenceInput,
  TextInput, ArrayInput, SimpleFormIterator, AutocompleteInput, ImageInput, ImageField
} from 'react-admin';
import { Edit } from './../Edit';
import { withStyles } from '@material-ui/core';
import { FormDataConsumer, minValue, maxValue, minLength, maxLength } from 'ra-core';
import { PRInput } from '../../inputs/PRInput';
import { PInput } from '../../inputs/PInput';
import { DInput } from '../../inputs/DInput';
import { DEInput } from '../../inputs/DEInput';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { styles } from './../../EstilosGrid';
import {
  abValidationInicio, abValidationFin, latMininima, lngMininima, Entero, isFechaInvalida, prValidationFin, prValidationInicio, validacionmaxfechaact
} from '../validacionesInputs';
import Divider from '@material-ui/core/Divider';
import CoordenadasProyecto from '../CoordenadasInventario';
import AbsSegmento from '../AbsSegmento';
import config from '../../config/config';
import { ButtonCarousel } from './../images/ButtonCarouselPeru';
import { ArrayInputImagesEdit } from './../images/ArrayInputImagesEdit';
import { translate } from 'react-admin';
let urlAppjs = config.UrlServer;


export const TunelEdit = translate(withStyles(styles)(({ classes, ...props }) => {
  const { translate } = props;
  const [unidads, setUnidad] = useState('');
  const [tramos, setTramo] = useState('');

  const validateDecimalc = (valor) => {
    if (valor) {
      var n = valor.replace(",", ".");
      if (n <= 100) {
        if (n <= 0) { return translate('dynatest.resources.tuneles.messagesVal.valNumMayorA0'); }
        // console.log(n)
      } else {
        return translate('dynatest.resources.tuneles.messagesVal.valNumMenorA100')
      }
    }
  }

  const validateDecimal = (valor) => {
    if (valor) {
      var n = valor.replace(",", ".");
      if (n <= 90) {
        // console.log(n)
        if (n >= -90) {
        } else {
          return translate('resources.validaciones.mayor90')
        }
  
      } else {
        return translate('dynatest.resources.tuneles.messagesVal.valNumMenorA90')
      }
    }
  }

  // ----------- VALIDACIÓN NÚMEROS ENTEROS -------- 
  const validarEnteroNT = (value) => {
    if (!/^([0-9]+)*$/.test(value)) {
      return "translate('dynatest.resources.tuneles.messagesVal.valNumEntero')";
    }
  }

  const ValidarUnidad = (value) => {
    let lenghtObject = Object.keys(value);
    let valor = '';
    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setUnidad(value);
  }
  const ValidarTramo = (value) => {
    let lenghtObject = Object.keys(value);
    let valor = '';
    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setTramo(value);
  }
  /* VALIDAR LOS RANGOS QUE PUEDEN TENER LAS LATITUDES Y LONGITUDES */
  const [latMin, setLatMin] = useState('');
  const [lngMin, setLngMin] = useState('');
  const [latMax, setLatMax] = useState('');
  const [lngMax, setLngMax] = useState('');
  const [contador, setContador] = useState(0);
  const [segmentos, setSegmento] = useState('');
  const ValidarSegmento = (value) => {
    setSegmento(value)

  }
  const LngIni = (value) => {
    if (lngMax !== '') {
      if (parseFloat(value) > parseFloat(lngMin) || parseFloat(value) < parseFloat(lngMax)) {
        return translate('resources.validaciones.rangoEntre') + lngMin + " y " + lngMax;
      }
    }
  }
  const LatIni = (value) => {
    if (latMax !== '') {
      if (parseFloat(value) < parseFloat(latMin) || parseFloat(value) > parseFloat(latMax)) {
        return translate('resources.validaciones.rangoEntre') + latMin + " y " + latMax;
      }
    }
  }
  /* FIN DE VALIDACIÓN DE LOS RANGOS QUE PUEDEN TENER LAS LATITUDES Y LONGITUDES */

  /*VALORES POR DEFECTO DEL ARRAY DE COORDENADAS*/
  const [latitudMinimaArray, setLatitudArray] = useState('');
  const LatitudMinimaArray = (value) => {
    setLatitudArray(value)
  }
  const [longitudMinimaArray, setLongitudArray] = useState('');
  const LongitudMinimaArray = (value) => {
    setLongitudArray(value)
  }
  const [longitudMaximaArray, setLongitudMaxdArray] = useState('');
  const LongitudMaximaArray = (value) => {
    setLongitudMaxdArray(value)
  }
  const [latitudMaximaArray, setLatitudMaxdArray] = useState('');
  const LatitudMaximaArray = (value) => {
    setLatitudMaxdArray(value)
  }
  /*FIN DE LOS VALORES POR DEFECTO DEL ARRAY DE COORDENADAS*/
  /*VALIDAR IMÁGENES ACTUALES */
  const [imagenesOpen, setImagenesOpen] = useState('');
  const ImagenesEdit = (value) => {
    setImagenesOpen(value);

  }
  return (
    <Edit title="dynatest.resources.tuneles.title.editTitle" {...props} undoable={false}>
      <TabbedForm redirect="list">
        <FormTab label="dynatest.resources.tuneles.tabs.tabsTitle.identificacion">
          <CoordenadasProyecto />
          <SelectInput label="resources.tuneles.fields.elementos" source="elementos" fullWidth validate={[required()]}
            choices={[{ id: 'TNEL', name: 'resources.tuneles.fields.TNEL' }]}
            formClassName={classes.grid_cont6_f} />
          <SelectInput label="resources.tuneles.fields.sentido" source="sentido" fullWidth validate={[required()]}
            choices={[
              { id: translate('dynatest.resources.tuneles.select.sentido.Ascendente'), name: 'dynatest.resources.tuneles.select.sentido.Ascendente' },
              { id: translate('dynatest.resources.tuneles.select.sentido.Descendente'), name: 'dynatest.resources.tuneles.select.sentido.Descendente' }
            ]}
            formClassName={classes.grid_cont6_s} />
            <br></br>
          <SelectInput label="resources.tuneles.fields.clasificacion" source="clasificacion" validate={[required()]} fullWidth
            choices={[
              { id: 'Clase A', name: 'dynatest.resources.tuneles.select.clasificacion.ClaseA' },
              { id: 'Clase B', name: 'dynatest.resources.tuneles.select.clasificacion.ClaseB' },
              { id: 'Clase C', name: 'dynatest.resources.tuneles.select.clasificacion.ClaseC' },
              { id: 'Clase D', name: 'dynatest.resources.tuneles.select.clasificacion.ClaseD' },
              { id: 'Clase E', name: 'dynatest.resources.tuneles.select.clasificacion.ClaseE' }
            ]}
            formClassName={classes.grid_cont4_f} />
          <SelectInput label="resources.tuneles.fields.cobertura" source="cobertura" validate={[required()]} fullWidth
            choices={[
              { id: translate('dynatest.resources.tuneles.select.cobertura.Somero'), name: 'dynatest.resources.tuneles.select.cobertura.Somero' },
              { id: translate('dynatest.resources.tuneles.select.cobertura.Semiprofundo'), name: 'dynatest.resources.tuneles.select.cobertura.Semiprofundo' },
              { id: translate('dynatest.resources.tuneles.select.cobertura.Profundo'), name: 'dynatest.resources.tuneles.select.cobertura.Profundo' }
            ]}
            formClassName={classes.grid_cont4_s} />
          <NumberInput source="carriles" label="resources.tuneles.fields.carriles" validate={[required(), minValue(1), maxValue(10), Entero]} fullWidth formClassName={classes.grid_cont4_s} />
          <h3 style={{ marginBottom: 0 }}>{translate('dynatest.resources.tuneles.subTitle.localizacion')}</h3><hr fullWidth />
          <Grid container spacing={24}>
            <Grid item xs={4}>
              <ReferenceInput
                label="resources.tuneles.fields.unidadFuncional"
                source="unidadFuncional"
                reference="unidad_funcionals"
                filterToQuery={searchText => ({ codigo: searchText })}
                sort={{ field: 'codigo', order: 'asc' }}
                validate={[required(), ValidarUnidad]}
                fullWidth
              >
                <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
              </ReferenceInput>
            </Grid>

            {unidads &&
              <Grid item xs={4}>
                <ReferenceInput
                  label="resources.tuneles.fields.tramo"
                  source="tramo"
                  alwaysOn
                  reference="tramos"
                  filter={{ unidadFuncional: unidads }}
                  filterToQuery={searchText => ({ codigo: searchText })}
                  sort={{ field: 'codigo', order: 'ASC', }}
                  validate={[required(), ValidarTramo]}
                  formClassName={classes.grid_cont4}
                >
                  <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
                </ReferenceInput>
              </Grid>
            }
            {tramos &&
              <Grid item xs={4}>
                <ReferenceInput
                  label="resources.tuneles.fields.segmento"
                  source="segmento"
                  reference="segmentos"
                  sort={{ field: 'codigo', order: 'ASC' }}
                  filter={{ tramo: tramos }}
                  filterToQuery={searchText => ({ codigo: searchText })}
                  validate={[required(), ValidarSegmento]}
                  formClassName={classes.grid_cont4}
                >

                  <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
                </ReferenceInput>
              </Grid>
            }
          </Grid>

          {/* DATOS OCULTOS */}
          {segmentos && <AbsSegmento segmento={segmentos} />}

          <Grid container spacing={24}>
            <Grid item xs={12}>
              <Typography variant="h3" component="h3" className={classes.coord}>{translate('dynatest.resources.tuneles.subTitle.coordenadasIniciales')}</Typography>
              <Divider fullWidth style={{ marginTop: '10px' }} />
            </Grid>
          </Grid>
          <PRInput label="resources.tuneles.fields.abscisa" id="ingresoAbInicio" source="abInicio" fullWidth validate={[abValidationInicio, required()]} formClassName={classes.grid_cont3s} />
          <PInput label="resources.tuneles.fields.pr" id="ingresoPrInicio" source="pInicio" fullWidth validate={[prValidationInicio]} formClassName={classes.grid_cont3} />
          <NumberInput label="resources.tuneles.fields.latitud" id="LatitudInicial" source="ubicaciones[0][lat]" fullWidth validate={[required(), latMininima, LatitudMinimaArray]} formClassName={classes.grid_cont3} />
          <NumberInput label="resources.tuneles.fields.longitud" id="LongitudInicial" source="ubicaciones[0][lng]" fullWidth validate={[required(), lngMininima, LongitudMinimaArray]} formClassName={classes.grid_cont3} />
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <Typography variant="h3" component="h3" className={classes.coord}>{translate('dynatest.resources.tuneles.subTitle.coordenadasFinales')}</Typography>
              <Divider fullWidth style={{ marginTop: '10px' }} />
            </Grid>
          </Grid>
          <PRInput label="resources.tuneles.fields.abscisa" id="ingresoAbFin" source="abFin" fullWidth validate={[abValidationFin]} formClassName={classes.grid_cont3s} />
          <PInput label="resources.tuneles.fields.pr" id="ingresoAbFin" source="pFin" fullWidth validate={[prValidationFin]} formClassName={classes.grid_cont3}/>
          <NumberInput label="resources.tuneles.fields.latitud" source="ubicaciones[1][lat]" fullWidth validate={[latMininima, LatitudMaximaArray]} formClassName={classes.grid_cont3} />
          <NumberInput label="resources.tuneles.fields.longitud" source="ubicaciones[1][lng]" fullWidth validate={[lngMininima, LongitudMaximaArray]} formClassName={classes.grid_cont3} />
        </FormTab>

        <FormTab label="dynatest.resources.tuneles.tabs.tabsTitle.datosAdministrativos">
          <h3 style={{ marginBottom: 0 }}>{translate('dynatest.resources.tuneles.subTitle.detallesConstructivos')}</h3><hr fullWidth />
          <DateInput label="resources.tuneles.fields.ano" source="ano" fullWidth formClassName={classes.grid_cont3_f} validate={[required(), isFechaInvalida]} />
          <DInput label="resources.tuneles.fields.area" source="area" className="labelNumber" fullWidth validate={[required(), minValue(1), maxValue(90), validateDecimal]} formClassName={classes.grid_cont3_s} />
          <TextInput label="resources.tuneles.fields.diseno" source="diseno" fullWidth validate={[required(), minLength(0), maxLength(60)]} formClassName={classes.grid_cont3_s} />
          <TextInput label="resources.tuneles.fields.disenador" source="disenador" fullWidth validate={[required(), minLength(0), maxLength(60)]} formClassName={classes.grid_cont3_s} />
          <TextInput label="resources.tuneles.fields.informe" source="informe" fullWidth validate={[required(), minLength(0), maxLength(200)]} formClassName={classes.grid_cont12} />
          <h3 style={{ marginBottom: 0 }}>{translate('dynatest.resources.tuneles.subTitle.detRefuerzoRehabilit')}</h3><hr fullWidth />
          <DateInput label="resources.tuneles.fields.anoa" source="anoa" fullWidth formClassName={classes.grid_cont3_f} validate={[required(), isFechaInvalida]} />
          <DInput label="resources.tuneles.fields.areaa" source="areaa" className="labelNumber" fullWidth validate={[required(), minValue(1), maxValue(90), validateDecimal]} formClassName={classes.grid_cont3_s} />
          <TextInput label="resources.tuneles.fields.disenoa" source="disenoa" fullWidth validate={[required(), minLength(0), maxLength(60)]} formClassName={classes.grid_cont3_s} />
          <TextInput label="resources.tuneles.fields.disenadora" source="disenadora" fullWidth validate={[required(), minLength(0), maxLength(60)]} formClassName={classes.grid_cont3_s} />
          <TextInput label="resources.tuneles.fields.informea" source="informea" fullWidth validate={[required(), minLength(0), maxLength(200)]} formClassName={classes.grid_cont12} />
          <TextInput label="resources.tuneles.fields.observacionesa" source="observacionesa" fullWidth validate={[required(), minLength(0), maxLength(200)]} formClassName={classes.grid_cont12} />
          <h3 style={{ marginBottom: 0 }}>{translate('dynatest.resources.tuneles.subTitle.estudioHidrogeologico')}</h3><hr fullWidth />
          <DateInput label="resources.tuneles.fields.anob" source="anob" fullWidth validate={[required(), isFechaInvalida]} formClassName={classes.grid_cont4_f} />
          <TextInput label="resources.tuneles.fields.consultor" source="consultor" fullWidth validate={[required(), minLength(0), maxLength(60)]} formClassName={classes.grid_cont4_s} />
          <TextInput label="resources.tuneles.fields.estudio" source="estudio" fullWidth validate={[required(), minLength(0), maxLength(60)]} formClassName={classes.grid_cont4_s} />
          <TextInput label="resources.tuneles.fields.nom_estudio" source="nom_estudio" fullWidth validate={[required(), minLength(0), maxLength(200)]} formClassName={classes.grid_cont12} />
          <h3 style={{ marginBottom: 0 }}>{translate('dynatest.resources.tuneles.subTitle.estProyeccionTrafico')}</h3><hr fullWidth />
          <DateInput label="resources.tuneles.fields.anoc" source="anoc" fullWidth validate={[required(), isFechaInvalida]} formClassName={classes.grid_cont4_f} />
          <TextInput label="resources.tuneles.fields.consultora" source="consultora" fullWidth validate={[required(), minLength(0), maxLength(60)]} formClassName={classes.grid_cont4_s} />
          <TextInput label="resources.tuneles.fields.estudioa" source="estudioa" fullWidth validate={[required(), minLength(0), maxLength(60)]} formClassName={classes.grid_cont4_s} />
          <TextInput label="resources.tuneles.fields.nom_estudioa" source="nom_estudioa" fullWidth validate={[required(), minLength(0), maxLength(200)]} formClassName={classes.grid_cont12} />
          <h3 style={{ marginBottom: 0 }}>{translate('dynatest.resources.tuneles.subTitle.miembrosInteresados')}</h3><hr fullWidth />
          <TextInput label="resources.tuneles.fields.propietario" source="propietario" fullWidth validate={[minLength(0), maxLength(20)]} formClassName={classes.grid_cont4_f} />
          <TextInput label="resources.tuneles.fields.vial" source="vial" fullWidth validate={[minLength(0), maxLength(20)]} formClassName={classes.grid_cont4_s} />
          <TextInput label="resources.tuneles.fields.proyectista" source="proyectista" fullWidth validate={[minLength(0), maxLength(30)]} formClassName={classes.grid_cont4_s} />
          <h3 style={{ marginBottom: 0 }}>{translate('dynatest.resources.tuneles.subTitle.caractOperacionales')}</h3><hr fullWidth />
          <DInput label="resources.tuneles.fields.opacidad" source="opacidad" className="labelNumber" fullWidth validate={[minLength(0), maxLength(30), validateDecimalc]} formClassName={classes.grid_cont6_f} />
          <DInput label="resources.tuneles.fields.concentracion" source="concentracion" className="labelNumber" fullWidth validate={[minLength(0), maxLength(30), validateDecimalc]} formClassName={classes.grid_cont6_s} />
          <SelectInput label="resources.tuneles.fields.existe" source="existe" fullWidth
            choices={[
              { id: translate('dynatest.resources.tuneles.select.existe.Si'), name: 'dynatest.resources.tuneles.select.existe.Si' },
              { id: translate('dynatest.resources.tuneles.select.existe.No'), name: 'dynatest.resources.tuneles.select.existe.No' },
            ]}
            formClassName={classes.grid_cont4_f} />
          <FormDataConsumer formClassName={classes.grid_cont4_s}>
            {({ formData, ...rest }) =>
              formData.existe === translate('dynatest.resources.tuneles.select.existe.Si') && (
                <DEInput label="resources.tuneles.fields.long_km" source="long_km" className="labelNumber" fullWidth validate={[validateDecimal]} />
              )
            }
          </FormDataConsumer>
          <SelectInput label="resources.tuneles.fields.estadoa" source="estadoa" fullWidth
            choices={[
              { id: translate('dynatest.resources.tuneles.select.estadoa.Bueno'), name: 'dynatest.resources.tuneles.select.estadoa.Bueno' },
              { id: translate('dynatest.resources.tuneles.select.estadoa.Regular'), name: 'dynatest.resources.tuneles.select.estadoa.Regular' },
              { id: translate('dynatest.resources.tuneles.select.estadoa.Malo'), name: 'dynatest.resources.tuneles.select.estadoa.Malo' },
            ]}
            formClassName={classes.grid_cont4_s} />
          <TextInput label="resources.tuneles.fields.observacioness" source="observacioness" fullWidth validate={[minLength(0), maxLength(200)]} formClassName={classes.grid_cont12} />
        </FormTab>
        <FormTab label="dynatest.resources.tuneles.tabs.tabsTitle.geometria">
          <h3 style={{ marginBottom: 0 }}>{translate('dynatest.resources.tuneles.subTitle.disenoPlanta')}</h3><hr fullWidth />
          <DInput label="resources.tuneles.fields.radio_min" source="radio_min" className="labelNumber" fullWidth validate={[minLength(0), maxLength(30), validateDecimalc]} formClassName={classes.grid_cont2} />
          <DInput label="resources.tuneles.fields.radio_max" source="radio_max" className="labelNumber" fullWidth validate={[minLength(0), maxLength(30), validateDecimalc]} formClassName={classes.grid_cont2} />
          <DInput label="resources.tuneles.fields.ancho_carril" source="ancho_carril" className="labelNumber" fullWidth validate={[minLength(0), maxLength(30), validateDecimalc]} formClassName={classes.grid_cont2} />
          <br />
          <h3 style={{ marginBottom: 0 }}>{translate('dynatest.resources.tuneles.subTitle.disenoPerfil')}</h3><hr fullWidth />
          <DInput label="resources.tuneles.fields.pend_longi" source="pend_longi" className="labelNumber" fullWidth validate={[minLength(0), maxLength(30), validateDecimalc]} formClassName={classes.grid_cont2} />
          <DInput label="resources.tuneles.fields.radio_min_curva" source="radio_min_curva" className="labelNumber" fullWidth validate={[minLength(0), maxLength(30)]} formClassName={classes.grid_cont2} />
          <DInput label="resources.tuneles.fields.radio_max_curva" source="radio_max_curva" className="labelNumber" fullWidth validate={[minLength(0), maxLength(30)]} formClassName={classes.grid_cont2} />
          <br />
          <DInput label="resources.tuneles.fields.long_tunel" source="long_tunel" className="labelNumber" fullWidth validate={[minLength(0), maxLength(30)]} formClassName={classes.grid_cont2} />
          <DInput label="resources.tuneles.fields.galibo" source="galibo" className="labelNumber" fullWidth validate={[minLength(0), maxLength(30)]} formClassName={classes.grid_cont2} />
          <DInput label="resources.tuneles.fields.ancho_anden" source="ancho_anden" className="labelNumber" fullWidth validate={[minLength(0), maxLength(30)]} formClassName={classes.grid_cont2} />
          <br />
          <DInput label="resources.tuneles.fields.ancho_berma" source="ancho_berma" className="labelNumber" fullWidth validate={[minLength(0), maxLength(30)]} formClassName={classes.grid_cont2} />
          <h3 style={{ marginBottom: 0 }}>{translate('dynatest.resources.tuneles.subTitle.SeccTransverServicio')}</h3><hr fullWidth />
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell align="right">{translate('dynatest.resources.tuneles.table.titles.Costado')}</TableCell>
                <TableCell align="right">{translate('dynatest.resources.tuneles.table.titles.Cantidad')}</TableCell>
                <TableCell align="right">{translate('dynatest.resources.tuneles.table.titles.Separacion')}</TableCell>
                <TableCell align="right">{translate('dynatest.resources.tuneles.table.titles.Longitud')}</TableCell>
                <TableCell align="right">{translate('dynatest.resources.tuneles.table.titles.Ancho')}</TableCell>
                <TableCell align="right">{translate('dynatest.resources.tuneles.table.titles.Altura')}</TableCell>
                <TableCell align="right">{translate('dynatest.resources.tuneles.table.titles.Pendiente')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow >
                <TableCell component="th">{translate('dynatest.resources.tuneles.table.nameRows.BahiasParqueo')}</TableCell>
                <TableCell align="right">
                  <SelectInput label="" source="costado1"
                    choices={[
                      { id: translate('dynatest.resources.tuneles.table.choicesCostado.Izquierdo'), name: 'dynatest.resources.tuneles.table.choicesCostado.Izquierdo' },
                      { id: translate('dynatest.resources.tuneles.table.choicesCostado.Derecho'), name: 'dynatest.resources.tuneles.table.choicesCostado.Derecho' },
                      { id: translate('dynatest.resources.tuneles.table.choicesCostado.Ambos'), name: 'dynatest.resources.tuneles.table.choicesCostado.Ambos' },
                    ]}
                  />
                </TableCell>
                <TableCell align="right">
                  <NumberInput label="" source="cantidad1" className="labelNumber" fullWidth validate={[minLength(0), maxLength(30), Entero]} />
                </TableCell>
                <TableCell align="right">
                  <DInput label="" source="separacion1" className="labelNumber" fullWidth validate={[minLength(0), maxLength(30)]} />
                </TableCell>
                <TableCell align="right">
                  <DInput label="" source="longitud1" className="labelNumber" fullWidth validate={[minLength(0), maxLength(30)]} />
                </TableCell>
                <TableCell align="right">
                  <DInput label="" source="ancho1" className="labelNumber" fullWidth validate={[minLength(0), maxLength(30), validateDecimalc]} />
                </TableCell>
                <TableCell align="right">
                  <DInput label="" source="altura1" className="labelNumber" fullWidth validate={[minLength(0), maxLength(30), validateDecimalc]} />
                </TableCell>
                <TableCell align="right">
                  <DInput label="" source="pendiente1" className="labelNumber" fullWidth validate={[minLength(0), maxLength(30), validateDecimalc]} />
                </TableCell>
              </TableRow>
              <TableRow >
                <TableCell component="th">{translate('dynatest.resources.tuneles.table.nameRows.CarrilEmergencia')}</TableCell>
                <TableCell align="right">
                  <SelectInput label="" source="costado2"
                    choices={[
                      { id: translate('dynatest.resources.tuneles.table.choicesCostado.Izquierdo'), name: 'dynatest.resources.tuneles.table.choicesCostado.Izquierdo' },
                      { id: translate('dynatest.resources.tuneles.table.choicesCostado.Derecho'), name: 'dynatest.resources.tuneles.table.choicesCostado.Derecho' },
                      { id: translate('dynatest.resources.tuneles.table.choicesCostado.Ambos'), name: 'dynatest.resources.tuneles.table.choicesCostado.Ambos' },
                    ]}
                  />
                </TableCell>
                <TableCell align="right">
                  <NumberInput label="" source="cantidad2" className="labelNumber" fullWidth validate={[minLength(0), maxLength(30), Entero]} />
                </TableCell>
                <TableCell align="right">
                  <DInput label="" source="separacion2" className="labelNumber" fullWidth validate={[minLength(0), maxLength(30)]} />
                </TableCell>
                <TableCell align="right">
                  <DInput label="" source="longitud2" className="labelNumber" fullWidth validate={[minLength(0), maxLength(30)]} />
                </TableCell>
                <TableCell align="right">
                  <DInput label="" source="ancho2" className="labelNumber" fullWidth validate={[minLength(0), maxLength(30), validateDecimalc]} />
                </TableCell>
                <TableCell align="right">
                  <DInput label="" source="altura2" className="labelNumber" fullWidth validate={[minLength(0), maxLength(30), validateDecimalc]} />
                </TableCell>
                <TableCell align="right">
                  <DInput label="" source="pendiente2" className="labelNumber" fullWidth validate={[minLength(0), maxLength(30), validateDecimalc]} />
                </TableCell>
              </TableRow>
              <TableRow >
                <TableCell component="th">{translate('dynatest.resources.tuneles.table.nameRows.GaleriasEvacuacion')}</TableCell>
                <TableCell align="right">
                  <SelectInput label="" source="costado3"
                    choices={[
                      { id: translate('dynatest.resources.tuneles.table.choicesCostado.Izquierdo'), name: 'dynatest.resources.tuneles.table.choicesCostado.Izquierdo' },
                      { id: translate('dynatest.resources.tuneles.table.choicesCostado.Derecho'), name: 'dynatest.resources.tuneles.table.choicesCostado.Derecho' },
                      { id: translate('dynatest.resources.tuneles.table.choicesCostado.Ambos'), name: 'dynatest.resources.tuneles.table.choicesCostado.Ambos' },
                    ]}
                  />
                </TableCell>
                <TableCell align="right">
                  <NumberInput label="" source="cantidad3" className="labelNumber" fullWidth validate={[minLength(0), maxLength(30), Entero]} />
                </TableCell>
                <TableCell align="right">
                  <DInput label="" source="separacion3" className="labelNumber" fullWidth validate={[minLength(0), maxLength(30)]} />
                </TableCell>
                <TableCell align="right">
                  <DInput label="" source="longitud3" className="labelNumber" fullWidth validate={[minLength(0), maxLength(30)]} />
                </TableCell>
                <TableCell align="right">
                  <DInput label="" source="ancho3" className="labelNumber" fullWidth validate={[minLength(0), maxLength(30), validateDecimalc]} />
                </TableCell>
                <TableCell align="right">
                  <DInput label="" source="altura3" className="labelNumber" fullWidth validate={[minLength(0), maxLength(30), validateDecimalc]} />
                </TableCell>
                <TableCell align="right">
                  <DInput label="" source="pendiente3" className="labelNumber" fullWidth validate={[minLength(0), maxLength(30), validateDecimalc]} />
                </TableCell>
              </TableRow>
              <TableRow >
                <TableCell component="th">{translate('dynatest.resources.tuneles.table.nameRows.NichoEmergenciaSOS')}</TableCell>
                <TableCell align="right">
                  <SelectInput label="" source="costado4"
                    choices={[
                      { id: translate('dynatest.resources.tuneles.table.choicesCostado.Izquierdo'), name: 'dynatest.resources.tuneles.table.choicesCostado.Izquierdo' },
                      { id: translate('dynatest.resources.tuneles.table.choicesCostado.Derecho'), name: 'dynatest.resources.tuneles.table.choicesCostado.Derecho' },
                      { id: translate('dynatest.resources.tuneles.table.choicesCostado.Ambos'), name: 'dynatest.resources.tuneles.table.choicesCostado.Ambos' },
                    ]}
                  />
                </TableCell>
                <TableCell align="right">
                  <NumberInput label="" source="cantidad4" className="labelNumber" fullWidth validate={[minLength(0), maxLength(30), Entero]} />
                </TableCell>
                <TableCell align="right">
                  <DInput label="" source="separacion4" className="labelNumber" fullWidth validate={[minLength(0), maxLength(30)]} />
                </TableCell>
                <TableCell align="right">
                  <DInput label="" source="longitud4" className="labelNumber" fullWidth validate={[minLength(0), maxLength(30)]} />
                </TableCell>
                <TableCell align="right">
                  <DInput label="" source="ancho4" className="labelNumber" fullWidth validate={[minLength(0), maxLength(30), validateDecimalc]} />
                </TableCell>
                <TableCell align="right">
                  <DInput label="" source="altura4" className="labelNumber" fullWidth validate={[minLength(0), maxLength(30), validateDecimalc]} />
                </TableCell>
                <TableCell align="right">
                  <DInput label="" source="pendiente4" className="labelNumber" fullWidth validate={[minLength(0), maxLength(30), validateDecimalc]} />
                </TableCell>
              </TableRow>
              <TableRow >
                <TableCell component="th">{translate('dynatest.resources.tuneles.table.nameRows.NichoControlIncendios')}</TableCell>
                <TableCell align="right">
                  <SelectInput label="" source="costado5"
                    choices={[
                      { id: translate('dynatest.resources.tuneles.table.choicesCostado.Izquierdo'), name: 'dynatest.resources.tuneles.table.choicesCostado.Izquierdo' },
                      { id: translate('dynatest.resources.tuneles.table.choicesCostado.Derecho'), name: 'dynatest.resources.tuneles.table.choicesCostado.Derecho' },
                      { id: translate('dynatest.resources.tuneles.table.choicesCostado.Ambos'), name: 'dynatest.resources.tuneles.table.choicesCostado.Ambos' },
                    ]}
                  />
                </TableCell>
                <TableCell align="right">
                  <NumberInput label="" source="cantidad5" className="labelNumber" fullWidth validate={[minLength(0), maxLength(30), Entero]} />
                </TableCell>
                <TableCell align="right">
                  <DInput label="" source="separacion5" className="labelNumber" fullWidth validate={[minLength(0), maxLength(30)]} />
                </TableCell>
                <TableCell align="right">
                  <DInput label="" source="longitud5" className="labelNumber" fullWidth validate={[minLength(0), maxLength(30)]} />
                </TableCell>
                <TableCell align="right">
                  <DInput label="" source="ancho5" className="labelNumber" fullWidth validate={[minLength(0), maxLength(30), validateDecimalc]} />
                </TableCell>
                <TableCell align="right">
                  <DInput label="" source="altura5" className="labelNumber" fullWidth validate={[minLength(0), maxLength(30), validateDecimalc]} />
                </TableCell>
                <TableCell align="right">
                  <DInput label="" source="pendiente5" className="labelNumber" fullWidth validate={[minLength(0), maxLength(30), validateDecimalc]} />
                </TableCell>
              </TableRow>
              <TableRow >
                <TableCell component="th">{translate('dynatest.resources.tuneles.table.nameRows.NichoEquiposElectricos')}</TableCell>
                <TableCell align="right">
                  <SelectInput label="" source="costado6"
                    choices={[
                      { id: translate('dynatest.resources.tuneles.table.choicesCostado.Izquierdo'), name: 'dynatest.resources.tuneles.table.choicesCostado.Izquierdo' },
                      { id: translate('dynatest.resources.tuneles.table.choicesCostado.Derecho'), name: 'dynatest.resources.tuneles.table.choicesCostado.Derecho' },
                      { id: translate('dynatest.resources.tuneles.table.choicesCostado.Ambos'), name: 'dynatest.resources.tuneles.table.choicesCostado.Ambos' },
                    ]}
                  />
                </TableCell>
                <TableCell align="right">
                  <NumberInput label="" source="cantidad6" className="labelNumber" fullWidth validate={[minLength(0), maxLength(30), Entero]} />
                </TableCell>
                <TableCell align="right">
                  <DInput label="" source="separacion6" className="labelNumber" fullWidth validate={[minLength(0), maxLength(30)]} />
                </TableCell>
                <TableCell align="right">
                  <DInput label="" source="longitud6" className="labelNumber" fullWidth validate={[minLength(0), maxLength(30)]} />
                </TableCell>
                <TableCell align="right">
                  <DInput label="" source="ancho6" className="labelNumber" fullWidth validate={[minLength(0), maxLength(30), validateDecimalc]} />
                </TableCell>
                <TableCell align="right">
                  <DInput label="" source="altura6" className="labelNumber" fullWidth validate={[minLength(0), maxLength(30), validateDecimalc]} />
                </TableCell>
                <TableCell align="right">
                  <DInput label="" source="pendiente6" className="labelNumber" fullWidth validate={[minLength(0), maxLength(30), validateDecimalc]} />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <h3 style={{ marginBottom: 0 }}>{translate('dynatest.resources.tuneles.subTitle.InfoComplementaria')}</h3><hr fullWidth />
          <SelectInput label="resources.tuneles.fields.soporte" source="soporte" fullWidth
            choices={[
              { id: translate('dynatest.resources.tuneles.select.soporte.ArcMetalicReticulado'), name: 'dynatest.resources.tuneles.select.soporte.ArcMetalicReticulado' },
              { id: translate('dynatest.resources.tuneles.select.soporte.ConcretoLanzado'), name: 'dynatest.resources.tuneles.select.soporte.ConcretoLanzado' },
              { id: translate('dynatest.resources.tuneles.select.soporte.ElementosDuctiles'), name: 'dynatest.resources.tuneles.select.soporte.ElementosDuctiles' },
              { id: translate('dynatest.resources.tuneles.select.soporte.MejoramientoTerreno'), name: 'dynatest.resources.tuneles.select.soporte.MejoramientoTerreno' },
              { id: translate('dynatest.resources.tuneles.select.soporte.PernosAnclaje'), name: 'dynatest.resources.tuneles.select.soporte.PernosAnclaje' },
              { id: translate('dynatest.resources.tuneles.select.soporte.RefuerzoTerreno'), name: 'dynatest.resources.tuneles.select.soporte.RefuerzoTerreno' },

            ]}
            formClassName={classes.grid_cont3_f} />
          <SelectInput label="resources.tuneles.fields.codigo_soporte" source="codigo_soporte" fullWidth disabled
            choices={[
              { id: 'RCS', name: 'RCS' },
              { id: 'RCC', name: 'RCC' },
              { id: 'RCD', name: 'RCD' },
            ]}
            formClassName={classes.grid_cont3_s} />
          <SelectInput label="resources.tuneles.fields.codigo_soporteName" source="codigo_soporte" fullWidth
            choices={[
              { id: 'RCS', name: 'dynatest.resources.tuneles.select.codigo_soporte.RCSname' },
              { id: 'RCC', name: 'dynatest.resources.tuneles.select.codigo_soporte.RCCname' },
              { id: 'RCD', name: 'dynatest.resources.tuneles.select.codigo_soporte.RCDname' },
            ]}
            formClassName={classes.grid_cont3_s} />
          <SelectInput label="resources.tuneles.fields.tipologia" source="tipologia" fullWidth
            choices={[
              { id: translate('dynatest.resources.tuneles.select.tipologia.EnVoEncajonado'), name: 'dynatest.resources.tuneles.select.tipologia.EnVoEncajonado' },
              { id: translate('dynatest.resources.tuneles.select.tipologia.EnLoMediaLadera'), name: 'dynatest.resources.tuneles.select.tipologia.EnLoMediaLadera' },
              { id: translate('dynatest.resources.tuneles.select.tipologia.EnCoSesgados'), name: 'dynatest.resources.tuneles.select.tipologia.EnCoSesgados' },
              { id: translate('dynatest.resources.tuneles.select.tipologia.EnToPerpendiculares'), name: 'dynatest.resources.tuneles.select.tipologia.EnToPerpendiculares' },
              { id: translate('dynatest.resources.tuneles.select.tipologia.EnUoHerradura'), name: 'dynatest.resources.tuneles.select.tipologia.EnUoHerradura' },
              { id: translate('dynatest.resources.tuneles.select.tipologia.EnEoEstructurales'), name: 'dynatest.resources.tuneles.select.tipologia.EnEoEstructurales' },
            ]}
            formClassName={classes.grid_cont3_s} />
        </FormTab>
        <FormTab label="dynatest.resources.tuneles.tabs.tabsTitle.detalles">
          <SelectInput fullWidth label="resources.tuneles.fields.estado" source="estado" validate={[required()]}
            choices={[
              { id: translate('dynatest.resources.tuneles.select.estado.Existente'), name: 'dynatest.resources.tuneles.select.estado.Existente' },
              { id: translate('dynatest.resources.tuneles.select.estado.Reemplazo'), name: 'dynatest.resources.tuneles.select.estado.Reemplazo' },
              { id: translate('dynatest.resources.tuneles.select.estado.Nuevo'), name: 'dynatest.resources.tuneles.select.estado.Nuevo' },
            ]}
            formClassName={classes.grid_cont4_f} />
          <FormDataConsumer formClassName={classes.grid_cont4_s}>
            {({ formData, ...rest }) =>
              formData.estado === translate('dynatest.resources.tuneles.select.estado.Reemplazo') && (
                <SelectInput fullWidth label="resources.tuneles.fields.mot_remplazo" source="mot_remplazo" allowEmpty emptyValue="" validate={[required()]}
                  choices={[
                    { id: translate('dynatest.resources.tuneles.select.mot_remplazo.ReemplazoProgramado'), name: 'dynatest.resources.tuneles.select.mot_remplazo.ReemplazoProgramado' },
                    { id: translate('dynatest.resources.tuneles.select.mot_remplazo.Accidente'), name: 'dynatest.resources.tuneles.select.mot_remplazo.Accidente' },
                    { id: translate('dynatest.resources.tuneles.select.mot_remplazo.Vandalismo'), name: 'dynatest.resources.tuneles.select.mot_remplazo.Vandalismo' },
                    { id: translate('dynatest.resources.tuneles.select.mot_remplazo.Otro'), name: 'dynatest.resources.tuneles.select.mot_remplazo.Otro' }
                  ]}
                />
              )
            }
          </FormDataConsumer>
          <FormDataConsumer formClassName={classes.grid_cont4_s}>
            {({ formData, ...rest }) =>
              formData.estado === translate('dynatest.resources.tuneles.select.estado.Reemplazo') && (
                <ReferenceInput
                  label="resources.tuneles.fields.elemnto_remplazo"
                  source="elemnto_remplazo"
                  reference="tuneles"
                  filter={{
                    tipoElemento: "TNEL",
                    estados: "Activo",
                    unidadFuncional: unidads,
                    tramo: tramos,
                    segmento : segmentos,
                    exclude : (formData) ? formData.id : null

                  }}
                  filterToQuery={searchText => ({ fullCodea: searchText })}
                  fullWidth
                  formClassName={classes.grid_cont4}
                >
                  <AutocompleteInput options={{ fullWidth: true }} optionText="fullCodigoConsecutivo" />
                </ReferenceInput>
              )
            }
          </FormDataConsumer>
          <SelectInput label="resources.tuneles.fields.sentido" source='sentidoB' choices={[
                { id: translate('dynatest.resources.tuneles.select.sentidoB.NORTEId'), name: 'dynatest.resources.tuneles.select.sentidoB.NORTEName' },
                { id: translate('dynatest.resources.tuneles.select.sentidoB.SURId'), name: 'dynatest.resources.tuneles.select.sentidoB.SURName' },
                { id: translate('dynatest.resources.tuneles.select.sentidoB.ESTEId'), name: 'dynatest.resources.tuneles.select.sentidoB.ESTEName' },
                { id: translate('dynatest.resources.tuneles.select.sentidoB.OESTEId'), name: 'dynatest.resources.tuneles.select.sentidoB.OESTEName' }
                ]}
                fullWidth
                formClassName={classes.grid_cont4_f}/>
          <SelectInput label="resources.tuneles.fields.estados" source="estados" allowEmpty emptyValue="" choices={[
            { id: translate('dynatest.resources.tuneles.select.estados.Activo'), name: "dynatest.resources.tuneles.select.estados.Activo" },
            { id: translate('dynatest.resources.tuneles.select.estados.Inactivo'), name: "dynatest.resources.tuneles.select.estados.Inactivo" },
          ]} validate={[required()]} fullWidth formClassName={classes.grid_cont4_s} />
          <br />
          <br />
          <DateInput fullWidth label="resources.tuneles.fields.fecha" source="fecha" validate={[validacionmaxfechaact]} formClassName={classes.grid_cont4_f} />
          <ReferenceInput
            label="resources.tuneles.fields.proveedor"
            source="proveedor"
            reference="proveedors"
            filter={{ sistema: 'PTS' }}
            sort={{ field: 'razonSocial', order: 'ASC' }}
            filterToQuery={searchText => ({ razonSocial: searchText })}
            validate={[required()]}
            fullWidth
            formClassName={classes.grid_cont4_s}
          >
            <AutocompleteInput options={{ fullWidth: true }} optionText="razonSocial" />
          </ReferenceInput>
          <NumberInput
            label="resources.tuneles.fields.garantia"
            source="garantia"
            fullWidth
            validate={[required(), minValue(0), maxValue(99999999), validarEnteroNT]}
            formClassName={classes.grid_cont4_s}
          />
          <br />
          <BooleanInput
            label="dynatest.resources.tuneles.boolean.incluir"
            source="incluir" fullWidth
            formClassName={classes.grid_cont6_f}
          />
          <br />
          <TextInput
            label="resources.tuneles.fields.observaciones"
            source="observaciones"
            fullWidth
            validate={[required(), minLength(1), maxLength(200)]}
            formClassName={classes.grid_cont12}
          />

        </FormTab>
        <FormTab label="dynatest.resources.tuneles.tabs.tabsTitle.fotografias">
          {/* <ImageInput multiple={true} style={{display :'none'}} source="images" label=" " maxSize={1000000000} accept="image/jpeg, image/png">
              <ImageField source="src" title="title" />
          </ImageInput> */}
          <Grid container spacing={24}>
            <Grid item xs={8}>
              {/* <ArrayInput label="" source="images" validate={ImagenesEdit} >
                <SimpleFormIterator disableAdd disableRemove >
                  <ImageInput multiple={true} source="images" label=" " maxSize={1000000000} accept="image/jpeg, image/png">
                    <ImageField source="src" title="title" />
                  </ImageInput>
                  <ReferenceInput disabled label="Tipo de elemento" source="tipoImagen" reference="tipo_images" fullWidth validate={[required()]} sort={{ field: 'nombre', order: 'ASC' }} formClassName={classes.grid_cont6}>
                    <SelectInput optionText="nombre" />
                  </ReferenceInput>
                </SimpleFormIterator>
              </ArrayInput> */}
              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  <ArrayInputImagesEdit record={formData} {...rest} />
                }
              </FormDataConsumer>

            </Grid>
            <Grid item xs={4}>
              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  <ButtonCarousel record={formData} {...rest} />
                }
              </FormDataConsumer>
            </Grid>
          </Grid>
          <FormDataConsumer>
              {({ formData, ...rest }) => { formData.updatedIn = "api"; }}
          </FormDataConsumer> 
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}));