import React, { useState, Fragment } from 'react';
import { List, Datagrid, TextField, EditButton, ShowButton, Filter, ReferenceInput, SelectInput, AutocompleteInput, ReferenceField
} from 'react-admin';
import {  BulkDeleteWithConfirmButton, Loading  } from 'react-admin';
import { PRField } from './../../../fields/PRField';
import { translate } from 'react-admin';
import { CardActions, CreateButton, ExportButton } from 'react-admin';

const FiltrosElementos = translate((props) => {
    const { translate } = props;
    const [uf, setUf] = useState('none');
    const ufSet = (value) => {
        setUf(value);
    }
    const [tramo, setTramo] = useState('none');
    const tramoSet = (value) => {
        setTramo(value);
    }
    return(
    <Filter {...props}>
       <ReferenceInput 
            id="unidadFuncional"
            label="resources.otros_elementos.fields.unidadFuncional" 
            source="unidadFuncional"  
            reference="unidad_funcionals" 
            filterToQuery={searchText => ({ codigo: searchText })} 
            sort={{field : 'codigo', order : 'asc'}}
            validate={[ufSet]}
            alwaysOn
            fullWidth 
        >
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
        <ReferenceInput 
            label="resources.otros_elementos.fields.tramo" 
            source="tramo" 
            alwaysOn 
            reference="tramos" 
            filter={{ unidadFuncional: uf}}
            validate={[tramoSet]}
            filterToQuery={searchText => ({ codigo: searchText })} 
            sort={{ 
                field: 'codigo', 
                order: 'ASC' ,
                
            }}>
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>
        
        <ReferenceInput 
            label="resources.otros_elementos.fields.segmento" 
            source="segmento" 
            alwaysOn 
            reference="segmentos" 
            sort={{ field: 'codigo', order: 'ASC' }}
            filter={{tramo : tramo}}
            filterToQuery={searchText => ({ codigo: searchText })} 
        >
            <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
        </ReferenceInput>

        <ReferenceInput 
            label="resources.otros_elementos.fields.tipoElemento" 
            source="tipoElemento"
            reference="tipo_elementos" 
            filter={{puntos: 1, sistema: "OTR"}} 
            sort={{field : 'name' , order :'ASC'}} 
            filterToQuery={searchText => ({ nombre: searchText })} 
            alwaysOn
            resettable
        >
            <AutocompleteInput  options={{fullWidth : true}} optionText="nombre"  />
        </ReferenceInput>
        
        <SelectInput label="resources.otros_elementos.fields.estados" source="estados" choices={[
            { id: translate('dynatest.resources.otros_elementos.select.estados.Activo'), name: "dynatest.resources.otros_elementos.select.estados.Activo" },
            { id: translate('dynatest.resources.otros_elementos.select.estados.Inactivo'), name: "dynatest.resources.otros_elementos.select.estados.Inactivo" },
        ]}  fullWidth alwaysOn
            />
    </Filter>
    )
});

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteWithConfirmButton {...props}/>
    </Fragment>
);
const ListAction = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
        <ExportButton basePath={basePath}/>
    </CardActions>
);
const ListAction2 = ({ basePath, data, resource }) => (
    <CardActions>
        <CreateButton basePath={basePath} />
    </CardActions>
);
export const OtrosElementosList = ({ permissions, ...props }) => {
    if (!permissions) return <Loading />;  
    return (
    <List {...props} 
        sort={{ field: 'updatedAt', order: 'DESC' }} 
        filters={<FiltrosElementos/>} 
        actions={permissions['otroElemento']  == 1 ? <ListAction /> : permissions['otroElemento']  == 2 ? <ListAction2 />: null}
        bulkActionButtons={permissions['otroElemento']  == 1 ? <PostBulkActionButtons />: null}>
        <Datagrid>    
            <TextField source="smartCode" label="resources.otros_elementos.fields.smartCode" />       
            <ReferenceField label="resources.otros_elementos.fields.tipoElemento" source="tipoElemento" sortable={false} reference="tipo_elementos" linkType={false}>
                <TextField source="nombre" />
            </ReferenceField>  
            <TextField source="interseccion" label="resources.otros_elementos.fields.interseccion" />
            <TextField source="clase_via_intersecta" label="resources.otros_elementos.fields.clase_via_intersecta" />
            <PRField label="resources.otros_elementos.fields.abInicio" source="abInicio"/>
            <PRField label="resources.otros_elementos.fields.abFin" source="abFin"/> 
            <TextField source="margen" label="resources.otros_elementos.fields.margen" />
            <TextField label="resources.otros_elementos.fields.estados" source="estados" sortable/>
            {permissions['otroElemento'] == 1 || permissions['otroElemento'] == 2 || permissions['otroElemento'] == 4 ? <ShowButton/>: null}
            {permissions['otroElemento'] == 1 || permissions['otroElemento'] == 2 ? <EditButton/>: null}
        </Datagrid>
    </List>
)};