import React from 'react';
import { SelectInput, AutocompleteInput } from 'react-admin';  
import loader from './../loading-dots.gif';
import config from '../config/config';
let urlAppjs = config.UrlServer;

/**
 * Props esperados:
 * -> tabla
 * -> tipo
 * -> elementos (el filtro de tipo de elemento)
 * 
 */

class ElementosCreados extends React.Component{

    constructor(props){
        super(props)
        this.state = { 
            Elementos: [],  
            Respuesta: []
        }
    }
    componentWillMount() {
        if(this.props.tipo === 'create'){

            fetch(urlAppjs+"/"+this.props.tabla+".json?pagination=off&status=1",
            {headers:{
                'authorization': 'Bearer '+localStorage.getItem('token')
              }
            })
            .then((response) => {
                return response.json()
            })
            .then((Elementos) => {
                this.setState({ Elementos: Elementos })
            })
        
        }else{
            // let id = window.location.hash;
            // id = id.replace(`#/${this.props.tabla}/%2F${this.props.tabla}%2F`, '');
            // if (id.search('/1') != -1) {
            //     id =id.replace('/1', '');
            // } else if(id.search('/2') != -1) {
            //     id =id.replace('/2', '');
            // }
            //fetch(urlAppjs+`/${this.props.tabla}/${id}.json`,

            fetch(urlAppjs+"/"+this.props.tabla+".json?pagination=off&status=1",
            {headers:{
                'authorization': 'Bearer '+localStorage.getItem('token')
              }
            })
            .then((response) => {
                return response.json()
            })
            .then((Respuesta) => {
                this.setState({ Respuesta: Respuesta })
            })

            fetch(urlAppjs+"/"+this.props.tabla+".json", 
            {headers:{
                'authorization': 'Bearer '+localStorage.getItem('token')
              }
            })
            .then((response) => {
                return response.json()
            })
            .then((Elementos) => {
                this.setState({ Elementos: Elementos })
            })
        }

      }

      render() {
        const { Elementos } = this.state;
        const { Respuesta } = this.state;
        const choices = [];
        const tipoElem =  this.props.elementos;
        if(Elementos.length > 0){
            if(this.props.tipo === "create"){
                Elementos.map(choice => {
                    if (tipoElem) {
                        const tipoElem2 =  tipoElem.split("/");
                        let tipoElemento="";
                        if (choice.hasOwnProperty("tipoElementoSenal")) {
                            tipoElemento = choice["tipoElementoSenal"]['id'];
                        } else {

                            tipoElemento = choice["tipoElemento"]['id'];
                        }

                        if (tipoElem2[2] == tipoElemento) {
                            let dato = {};
                            
                            dato = {
                                id: choice["id"], 
                                nombre: choice["fullCodigo"]
                            }

                            choices.push(dato);
                        }


                    } else {
                        let dato = {
                            id: choice["id"], 
                            nombre: choice["fullCodigo"]
                        }
                        choices.push(dato);
                    }
                }) 
                return(
                    <AutocompleteInput 
                        source="elementoReemplazo_id"
                        label="Elemento que reemplazo"
                        optionText="nombre"
                        optionValue="id"
                        choices={choices}                        
                        style={{ display: 'inline-block', width: '95%' }}
                        options={{fullWidth: true}}
                    />
                )
            }else if(this.props.tipo === 'editar'){

                Elementos.map(choice => {

                    // if(choice["id"] !== Respuesta.id){
                        if (tipoElem) {
                            const tipoElem2 =  tipoElem.split("/");
                            let tipoElemento = choice["tipoElemento"]['id'];
                            if (tipoElem2[2] == tipoElemento) {
                                let dato = {
                                    id: choice["id"], 
                                    nombre: choice["fullCodigo"]
                                }
                                choices.push(dato)
                            }
                        } else {
                            let dato = {
                                id: choice["id"], 
                                nombre: choice["fullCodigo"]
                            }
            
                            choices.push(dato)
                        }
                    // }
    
                }) 

                return(
                    <AutocompleteInput 
                        source="elementoReemplazo_id"
                        label="Elemento que reemplazo"
                        choices={choices}  
                        optionText="nombre"
                        optionValue="id"                        
                        style={{ display: 'inline-block', width: '100%', marginLeft: '20px' }}
                        defaultValue = {Respuesta.id}
                        options={{fullWidth: true}}
                    />
                )
            }

        }else{
            return(
                <div className="loader-municipios">
                    <img src={loader}/>
                </div>
            )
        }
    }
}

export default ElementosCreados;