import React, { useState, Fragment } from 'react';
import { TabbedForm, FormTab, TextInput, DateInput, SelectInput, FormDataConsumer,
     NumberInput, ReferenceInput, DisabledInput, ArrayInput, SimpleFormIterator, AutocompleteInput,BooleanInput
 } from 'react-admin';
import { PercentageInput } from './../../../../inputs/PercentageInput';
import { ImagenesInventario } from '../../../images/ImagenesInventario';
import { withStyles } from '@material-ui/core';
import { PimsCreate } from './../../../Create';
import { SegmentoActions } from './../SegmentoActions';

import {REDUX_FORM_NAME} from 'react-admin';
import {change} from 'redux-form';
import Grid from '@material-ui/core/Grid';


import Divider from '@material-ui/core/Divider';

import { required, minValue, maxValue, minLength, maxLength } from 'react-admin';
import { Entero, isFechaInvalida } from '../../../validacionesInputs';
import { translate } from 'react-admin';

//MOSTRAR IMAGEN
const showBtnImage =() =>{
    let btn = document.getElementById("Valbtn");        
    btn.style.display = "inline-flex";
}

//ESCONDER IMAGEN
const HideBtnImage =() =>{ 
    let btn = document.getElementById("Valbtn");    
    btn.style.display = "none";    
}

const styles = {
    first_field: {display: 'inline-block', width: '30%' },
    observaciones_field: {display: 'inline-block', width: '40%'},
    last_filed: {display: 'inline-block', marginLeft: 32, width: '30%' },   
    separador: {marginTop: 40, marginLeft: 100, marginRight: 100, marginBottom: 0}, 
    subtittle: {marginTop: '15px',marginBottom: '15px'},     
    tabla:  {display: 'inline-block', width: '30%', padding: '5px'}       
};
 
export const ElementoAdoquinCreate = translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;
    
    //----------------------------------------------
    //-----------------VALIDACIÓNES-----------------
    //----------------------------------------------
    let defecto = 0;

    //DETALLES
    //------------------->   

    //MOSTRAR/OCULTAR CAMPO RAZON
    const [estado, setEstado] = useState('');

    const Validationrazon =(value) =>{        
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                
        setEstado(valor)        
    }

    //ESAL
    let Esal10 = 0;
    const value10 = (value) =>{
        if(value){
            Esal10 = value;
        }
    }

    const value20 = (value) =>{
        if(Esal10 !== 0){
            if(value <= Esal10){            
                return translate('dynatest.resources.adoquin_detalles.messagesVal.value20')
            }
        }
    }

    //GEOMETRIA
    //------------------->   

    //HOOKS VALOR AUTOMATICO AREA DE CARRILES(LARGO CALZADA) Y AREA VERDADERA CARRILES
    const [LargoCal, setLargoCal] = useState(1);
    const [AnchoCal, setAnchoCal] = useState(1);
    const [NumCarr, setNumCarr] = useState(1);
    const [AnchoCarr, setAnchoCarr] = useState(1);    

    //CAMBIAR EL VALOR AREA DE CARRILES (LARGO)
    const ValDetallesCalzada = (value) =>{   
        if(value){
            //Si ingresa un decimal VAlidar solo 2     
            if(value !== 0){                
                if (value % 1 !== 0) {                     
                    var pattern = new RegExp("^[0-9]+([.][0-9]{2})$");
                    var pattern2 = new RegExp("^[0-9]+([.][0-9]{1})$");
                    if(!pattern.test(value) && !pattern2.test(value)){
                        if(!pattern.test(value)){ return translate('dynatest.resources.adoquin_detalles.messagesVal.valNumDecimals'); }                            
                    }                                                            
                }                
            }

            setLargoCal(value) 
            var largom = document.getElementById("largom").value;
            var anchom = document.getElementById("anchom").value;
            var largoCar = document.getElementById("anchoCar").value;
            var total = ((parseFloat(largom)*100) * (parseFloat(anchom)*100))/10000;
            var totalVerdadero = ((parseFloat(largom)*100) * (parseFloat(anchom)*100) * (largoCar*100))/1000000;           
            if (!isNaN(total)) {
                document.getElementById("AreaCarrilesAC").value = total
                // document.getElementById("AreaVerdaderaAC").value = totalVerdadero
            }

        }        
    }

    //VALIDAR NUMERO CON DECIMALES
    const valNumDecimals = (value) =>{    
        if(value){            
            if(value !== 0){                
                if (value % 1 !== 0) {                     
                    var pattern = new RegExp("^[0-9]+([.][0-9]{2})$");
                    var pattern2 = new RegExp("^[0-9]+([.][0-9]{1})$");
                    if(!pattern.test(value) && !pattern2.test(value)){
                        if(!pattern.test(value)){ return translate('dynatest.resources.adoquin_detalles.messagesVal.valNumDecimals'); }                            
                    }                                                            
                }                
            }
        } 
    }

    //CAMBIAR EL VALOR AREA DE CARRILES (ANCHO)
    const ValDetallesCalzadaAncho = (value) =>{    
        if(value){
            setAnchoCal(value)
            var largom = document.getElementById("largom").value;
            var anchom = document.getElementById("anchom").value;
            var largoCar = document.getElementById("anchoCar").value;
            var total = ((parseFloat(largom)*100) * (parseFloat(anchom)*100))/10000;
            var totalVerdadero = ((parseFloat(largom)*100) * (parseFloat(anchom)*100) * (largoCar*100))/1000000;           
            if (!isNaN(total)) {
                document.getElementById("AreaCarrilesAC").value = total
                // document.getElementById("AreaVerdaderaAC").value = totalVerdadero
            }
        }
    }    

    //VALOR AUTOMATICO AREA VERDADERA CARRILES(NUMERO CARRILES)    
    const ValNumeroCarriles = (value) =>{    
        if (value){
            setNumCarr(value)
            var largom = document.getElementById("largom").value;
            var anchom = document.getElementById("anchom").value;
            var largoCar = document.getElementById("anchoCar").value;
            var total = ((parseFloat(largom)*100) * (parseFloat(anchom)*100))/10000;
            var totalVerdadero = ((parseFloat(largom)*100) * (parseFloat(anchom)*100) * (largoCar*100))/1000000;           
            if (!isNaN(total)) {
                document.getElementById("AreaCarrilesAC").value = total
                // document.getElementById("AreaVerdaderaAC").value = totalVerdadero
            }
        }        
    }

    //ASIGNAR VALOR AUTOMATICO VERDADERA AREA CARRILES (ANCHO CARRIL)
    const ValAnchoCarril = (value) =>{ 
        if (value){
            if(value !== 0){
                if (value % 1 !== 0) { 
                    var pattern = new RegExp("^[0-9]+([.][0-9]{1})$");
                    if(!pattern.test(value)){ return translate('dynatest.resources.adoquin_detalles.messagesVal.valNumDecimal'); }       
                }                
            }
            setAnchoCarr(value)
            var largom = document.getElementById("largom").value;
            var anchom = document.getElementById("anchom").value;
            var largoCar = document.getElementById("anchoCar").value;
            var total = ((parseFloat(largom)*100) * (parseFloat(anchom)*100))/10000;
            var totalVerdadero = ((parseFloat(largom)*100) * (parseFloat(anchom)*100) * (largoCar*100))/1000000;           
            if (!isNaN(total)) {
                document.getElementById("AreaCarrilesAC").value = total
                // document.getElementById("AreaVerdaderaAC").value = totalVerdadero
            }
        }
    }

    //OCULTAR/MOSTRAR ANCHO DEL SEPARADOR
    const [anchosep, setAnchosep] = useState('');

    const ValidationConSeparador = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) {  valor += value[index]; }                
        setAnchosep(valor)  
    }

    //VALIDACION PENDIENTE 0-20
    const valPendiente20 = (value) =>{
        if(value){
            let longitud20 = value.split("%");
            //VALIDAR ENTERO
            let entero = longitud20[0].split(".");
            if(entero[1]){
                if(entero[1] !== "") { return translate('dynatest.resources.adoquin_detalles.messagesVal.valNumEntero') }
            }  
            //VALIDAR MINIMO 0
            if(parseInt(longitud20[0]) < 0){ return translate('dynatest.resources.adoquin_detalles.messagesVal.valLongitudMin0'); }
            //VALIDAR MAXIMO 5
            if(parseInt(longitud20[0]) > 20){ return translate('dynatest.resources.adoquin_detalles.messagesVal.valLongitudMax20'); }            
        }        
    }

    //VALIDACION PENDIENTE 0-5  
    const valPendiente5 = (value) =>{
        if(value){
            let longitud5 = value.split("%");
            //VALIDAR ENTERO
            let entero = longitud5[0].split(".");
            if(entero[1]){
                if(entero[1] !== "") { return translate('dynatest.resources.adoquin_detalles.messagesVal.valNumEntero') }
            }            
            //VALIDAR MINIMO 0
            if(parseInt(longitud5[0]) < 0){ return translate('dynatest.resources.adoquin_detalles.messagesVal.valLongitudMin0'); }
            //VALIDAR MAXIMO 5
            if(parseInt(longitud5[0]) > 5){ return translate('dynatest.resources.adoquin_detalles.messagesVal.valLongitudMax5'); }            
        }
    }

    //ESTRUCTURA
    //-------------------> 

    let espensor1 = 0, valEspensor1;
    let espensor2 = 0, valEspensor2;
    let espensor3 = 0, valEspensor3;
    let espensor4 = 0, valEspensor4;
    let espensor5 = 0, valEspensor5;
    let espensor6 = 0, valEspensor6;
    let espensor7 = 0, valEspensor7;
    const [espensortotal, setEspensortotal] = useState(defecto);

    //VALOR ESPENSOR
    const ValEspensor = (value) => {
        
        if(value){      
            if (value % 1 !== 0) { return translate('dynatest.resources.adoquin_detalles.messagesVal.valNumEntero'); }
            //ESPENSOR 1
            valEspensor1 = document.getElementById('tablaSegmentos[0].estructuraEspesor');
            valEspensor2 = document.getElementById('tablaSegmentos[1].estructuraEspesor');
            valEspensor3 = document.getElementById('tablaSegmentos[2].estructuraEspesor');
            valEspensor4 = document.getElementById('tablaSegmentos[3].estructuraEspesor');
            valEspensor5 = document.getElementById('tablaSegmentos[4].estructuraEspesor');
            valEspensor6 = document.getElementById('tablaSegmentos[5].estructuraEspesor');
            valEspensor7 = document.getElementById('tablaSegmentos[6].estructuraEspesor');

            //ESPENSOR 1
            if(valEspensor1) { if(valEspensor1.value !== ''){ espensor1 = valEspensor1.value } }
            //ESPENSOR 2
            if(valEspensor2) { if(valEspensor2.value !== ''){ espensor2 = valEspensor2.value } }
            //ESPENSOR 2
            if(valEspensor3) { if(valEspensor3.value !== ''){ espensor3 = valEspensor3.value } }
            //ESPENSOR 2
            if(valEspensor4) { if(valEspensor4.value !== ''){ espensor4 = valEspensor4.value } }
            //ESPENSOR 2
            if(valEspensor5) { if(valEspensor5.value !== ''){ espensor5 = valEspensor5.value } }
            //ESPENSOR 2
            if(valEspensor6) { if(valEspensor6.value !== ''){ espensor6 = valEspensor6.value } }
            //ESPENSOR 2
            if(valEspensor7) { if(valEspensor7.value !== ''){ espensor7 = valEspensor7.value } }
            
            setEspensortotal(parseInt(espensor1) + parseInt(espensor2) + parseInt(espensor3) + parseInt(espensor4) + parseInt(espensor5) + parseInt(espensor6) + parseInt(espensor7));                  

        }  else {
            setEspensortotal(0);
        }
    }

    //VALOR MODULO
    const ValModulo = (value) =>{
        if(value){  
            if (value % 1 !== 0) { return translate('dynatest.resources.adoquin_detalles.messagesVal.valNumEntero'); }          
        } 
    }

    //VALOR ESTRUCTURA
    const ValEstructura = (value) =>{
        if(value){
            if(value !== 0){
                if (value % 1 !== 0) {
                    var pattern = new RegExp("^[0-9]+([.][0-9]{2})$");
                    if(!pattern.test(value)){ return translate('dynatest.resources.adoquin_detalles.messagesVal.valNumDecimals'); }            
                }                
            }
        } 
    }

    //VALIDAR NUMERO ENTERO
    const valentero = (value) =>{
        if(value){
            if (value % 1 !== 0) { return translate('dynatest.resources.adoquin_detalles.messagesVal.valNumEntero'); }
        }
    }
    
    //VALIDAR NUMERO CON 1 DECIMALES
    const valNum1Decimal = (value) =>{    
        if(value){
            if(typeof value === 'string'){
                value = parseFloat(value.replace(",", "."));        
            }   
            if(value !== 0){
                if (value % 1 !== 0) { 
                    var pattern = new RegExp("^[0-9]+([.][0-9]{1})$");
                    if(!pattern.test(value)){ return translate('dynatest.resources.adoquin_detalles.messagesVal.valNumDecimal'); }       
                }                
            }
        } 
    }

    const valNum2Decimales = (value) => {
        if(value){
            //Si ingresa un decimal VAlidar solo 2     
            if(value !== 0){
                if (value % 1 !== 0) {
                    var pattern = new RegExp("^[0-9]+([.][0-9]{1,2})$");
                    if(!pattern.test(value)){ return translate('dynatest.resources.adoquin_detalles.messagesVal.valNumDecimals'); }            
                }                
            }            
        }        
    }

    //VALIDAR ESPECIFICACIÓN
    const [material , setMaterial]= useState('');
    const ValidarEspecificacion = (value)  => { 
        let lenghtObject = Object.keys(value);
        let valor = '';
        
        if(value){
            for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
            var filtroGra = valor.split('/materials/');  
            setMaterial(filtroGra[1]);
        }
        
    } 

    const [condicionberma, setCondicionberma] = useState(true);
    const validarCondicionBerma = (value) => {

        if(value){
            if (value === 'Inexistente'){
                setCondicionberma(false);
            }else{
                setCondicionberma(true);
            }
        }

    }
    const [arrayMax, setArrayMax] = useState(false);
    const arrayCount5 = (value) => {
        if(typeof value !== 'undefined'){
            //console.log(value);
            //console.log(value.length > 0);
            if(value.length > 0) {
                //console.log(value.length);
                if("estructuraEspesor" in value[value.length -1]) {
                    if(value[value.length -1].estructuraEspesor > 0) {
                        return translate('dynatest.resources.adoquin_detalles.messagesVal.messageIniCapa') + value.length+ translate('dynatest.resources.adoquin_detalles.messagesVal.messageFinCapa');
                    }
                }
                if (value.length > 4) {
                    //console.log("mas de 4");
                    setArrayMax(true);
                } else {
                    //console.log("menos o igual a 4");
                    setArrayMax(false);
                }
            } else {
                return translate('dynatest.resources.adoquin_detalles.messagesVal.valMinCapa')
            }
        } else {
            return translate('dynatest.resources.adoquin_detalles.messagesVal.valMinCapa')
        } 
    }
    /*OPCIONES PARA TIPO DE IMÁGENES*/
    const [defaultTipoImagen,setDefaultTipoImagen] = useState([{id: 'Cargando...' ,name :'Cargando...'}]);
    const OpcionTipoImagen = () =>{
        setDefaultTipoImagen([
            {
                tipoImagen : '/tipo_images/1',
            },
            {
                tipoImagen : '/tipo_images/10',
            },
            {
                tipoImagen : '/tipo_images/11',
            },
            {
                tipoImagen : '/tipo_images/12',
            },
            {
                tipoImagen : '/tipo_images/5',
            },
            {
                tipoImagen : '/tipo_images/6',
            }
        ])
    }

    /*CALCULAR CONCEITO EN ESTRUCTURA*/
    const [conceitoEstado, setConceito] = useState();
        
    const CalcularCoceito = (value) => {
        var conceito = '';
        if(value > 0 && value <= 20){
            conceito = 'Ótimo';
        }else if(value > 20 && value <= 40){
            conceito = 'Bom';
        }else if(value >40 && value <= 80){
            conceito = 'Regular';
        }else if(value >80 && value <= 160){
            conceito = 'Ruim';
        }else if(value > 160){
            conceito = 'Péssimo';
        }
        setConceito(conceito);
    }   
    /*FIN DEL CALCULO*/

    return(
    <PimsCreate {...props} actions={<SegmentoActions/>}>
        <TabbedForm redirect="list">
            <FormTab label="dynatest.resources.adoquin_detalles.tabs.detalles" onClick={showBtnImage}>
            <FormDataConsumer >
                    {({formData, dispatch, ...rest}) => (
                        <Fragment>
                            <Grid container spacing={24}>
                                <Grid item xs={4}>
                                    <ReferenceInput 
                                        label="resources.adoquin_detalles.fields.unidadFuncional" 
                                        source="unidadFuncional" 
                                        reference="unidad_funcionals" 
                                        sort={{ field: 'codigo', order: 'ASC' }}
                                        onChange={value => dispatch(
                                            change(REDUX_FORM_NAME, "tramo", null)
                                        )}
                                        filterToQuery={searchText => ({ codigo: searchText })} 
                                        validate={[required()]}
                                    >
                                        <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
                                    </ReferenceInput>
                                </Grid>
                                <Grid item xs={4}>
                                {formData !== null && formData !== undefined && formData.unidadFuncional &&
                                <ReferenceInput 
                                        label="resources.adoquin_detalles.fields.tramo" 
                                        source="tramo" 
                                        reference="tramos" 
                                        filter={{unidadFuncional: formData.unidadFuncional}} 
                                        sort={{ field: 'codigo', order: 'ASC' }}
                                        onChange={value => dispatch(
                                            change(REDUX_FORM_NAME, "segmento", null)
                                        )}
                                        filterToQuery={searchText => ({ codigo: searchText })} 
                                        validate={[required()]}
                                    >
                                        <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
                                    </ReferenceInput>
                                }
                                </Grid>
                                <Grid item xs={4}>
                                {formData !== null && formData !== undefined && formData.tramo &&
                                    <ReferenceInput 
                                        label="resources.adoquin_detalles.fields.segmento" 
                                        source="segmento" 
                                        reference="segmentos" 
                                        filter={{
                                            tramo: formData.tramo,
                                            tipoSuperficie : "PART"
                                        }} 
                                        sort={{ field: 'codigo', order: 'ASC' }}
                                        filterToQuery={searchText => ({ codigo: searchText })} 
                                        validate={[required(), OpcionTipoImagen]}
                                    >
                                        <AutocompleteInput  options={{fullWidth : true}} optionText="codigo"  />
                                    </ReferenceInput>
                                }
                                </Grid>
                            </Grid>
                        </Fragment>
                        
                    )}
                </FormDataConsumer>
                <div></div>
                <ArrayInput source="detallesSegmentos" style={{width: '100%'}} label=" " 
                    defaultValue={[
                        {              
                            sentido: null,          
                            estadoDetalles: null,
                            elementoMantenimiento: false,
                            estadoRazon: null,
                            dateInicioOperacion: null,
                            proveedor: null,
                            garantiaMeses: null,
                            esalTime: null,
                            nivelTrafico: null,
                            esalTime10: null,
                            esalTime20: null,
                            detallesObservaciones: null
                        }
                    ]} className="inventariosSegmentos">
                    <SimpleFormIterator disableRemove disableAdd>
                        <SelectInput label="resources.adoquin_detalles.fields.sentido" source='sentido' choices={[
                            { id: translate('dynatest.resources.adoquin_detalles.select.sentido.NORTEId'), name: 'dynatest.resources.adoquin_detalles.select.sentido.NORTEName' },
                            { id: translate('dynatest.resources.adoquin_detalles.select.sentido.SURId'), name: 'dynatest.resources.adoquin_detalles.select.sentido.SURName' },
                            { id: translate('dynatest.resources.adoquin_detalles.select.sentido.ESTEId'), name: 'dynatest.resources.adoquin_detalles.select.sentido.ESTEName' },
                            { id: translate('dynatest.resources.adoquin_detalles.select.sentido.OESTEId'), name: 'dynatest.resources.adoquin_detalles.select.sentido.OESTEName' }
                        ]}
                        formClassName={classes.first_field}/> 
                        <SelectInput source="estadoDetalles" label="resources.adoquin_detalles.fields.estadoDetalles" choices={[
                            { id: translate('dynatest.resources.adoquin_detalles.select.estadoDetalles.Activo'), name: 'dynatest.resources.adoquin_detalles.select.estadoDetalles.Activo' },
                            { id: translate('dynatest.resources.adoquin_detalles.select.estadoDetalles.Inactivo'), name: 'dynatest.resources.adoquin_detalles.select.estadoDetalles.Inactivo' },                    
                        ]} formClassName={classes.last_filed} validate={[required()]} onChange={Validationrazon}/>                                
                        { estado ===  translate('dynatest.resources.adoquin_detalles.select.estadoDetalles.Inactivo') &&            
                            <TextInput source="estadoRazon" label="resources.adoquin_detalles.fields.estadoRazon" formClassName={classes.last_filed} validate={[required()]}/>
                        }
                        <br/>
                        <BooleanInput label="dynatest.resources.adoquin_detalles.boolean.elementoMantenimiento" source="elementoMantenimiento" formClassName={classes.last_filed}/>
                        <div ></div>     
                        <DateInput source="dateInicioOperacion" label="resources.adoquin_detalles.fields.dateInicioOperacion" formClassName={classes.first_field} validate={[required(), isFechaInvalida]} />                         
                        <ReferenceInput 
                            label="resources.adoquin_detalles.fields.proveedor" 
                            source="proveedor" 
                            reference="proveedors" 
                            filter={{sistema: 'CLZ', suministros: 1}} 
                            sort={{ field: 'razonSocial', order: 'ASC' }}
                            formClassName={classes.last_filed}
                            filterToQuery={searchText => ({ razonSocial: searchText })} 
                            validate={[required()]}
                        >
                            <AutocompleteInput  options={{fullWidth : true}} optionText="razonSocial"  />
                        </ReferenceInput>
                        <NumberInput source="garantiaMeses" label="resources.adoquin_detalles.fields.garantiaMeses" validate={[minValue(0), Entero]} formClassName={classes.last_filed}/>
                        <div className={classes.subtittle}><strong>{translate('resources.adoquin_detalles.fields.trafico')}</strong></div>                
                        <Divider fullWidth />
                        <NumberInput source="esalTime" label="resources.adoquin_detalles.fields.esalTime" formClassName={classes.first_field} validate={[required(),minValue(0), Entero]}/>
                        <SelectInput source="nivelTrafico" label="resources.adoquin_detalles.fields.nivelTrafico" choices={[
                            { id: translate('dynatest.resources.adoquin_detalles.select.nivelTrafico.NT1'), name: 'dynatest.resources.adoquin_detalles.select.nivelTrafico.NT1' },
                            { id: translate('dynatest.resources.adoquin_detalles.select.nivelTrafico.NT2'), name: 'dynatest.resources.adoquin_detalles.select.nivelTrafico.NT2' },
                            { id: translate('dynatest.resources.adoquin_detalles.select.nivelTrafico.NT3'), name: 'dynatest.resources.adoquin_detalles.select.nivelTrafico.NT3' },
                        ]} formClassName={classes.last_filed} validate={[required()]} />  
                        <div></div>                
                        <NumberInput source="esalTime10" label="resources.adoquin_detalles.fields.esalTime10" formClassName={classes.first_field} validate={[minValue(0), value10, Entero]}/>
                        <NumberInput source="esalTime20" label="resources.adoquin_detalles.fields.esalTime20" formClassName={classes.last_filed} validate={[minValue(0), value20, Entero]}/>
                        <Divider fullWidth formClassName={classes.separador} />                
                        <TextInput source="detallesObservaciones" label="resources.adoquin_detalles.fields.detallesObservaciones" formClassName={classes.observaciones_field} validate={[minLength(0), maxLength(200)]} fullWidth/>
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
            <FormTab label="dynatest.resources.adoquin_detalles.tabs.geometria" onClick={HideBtnImage} redirect="list">
                <ArrayInput source="geometriaSegmentos" style={{width: '100%'}} label=" " 
                    defaultValue={[
                        {
                            geometriaLargo: null,
                            geometriaAncho: null,
                            geometriaNumCarriles: null,
                            geometriaAnchoCarril: null,
                            geometriaAnchoBerma: null,
                            geometriaTipoBerma: null,
                            geometriaCondicionBerma: null,
                            aux1: 0,
                            aux2: 0,
                            geometriaLongitudinal: null,
                            geometriaLongitudinalMa: null,
                            geometriaLongitudinalMi: null,
                            geometriaTransversal: null,
                            geometriaRadioMi	: null,
                            geometriaRadioMa: null,
                            geometriaRadioCurvatura: null,
                            geometriaVelDiseno: null,
                            geometriaSeparador: null,
                            geometriaSeparadorAncho: null,
                            geometriaCondicionesGeometrica: null,
                            geometriaAlineamiento: null,
                            geometriaTipoTerreno: null,
                            geometriaAdoLargo: null,
                            geometriaAdoAncho: null,
                            geometriaAdoAlto: null,
                        }                    
                    ]} className="inventariosSegmentos">
                    <SimpleFormIterator disableRemove disableAdd>
                        <div className={classes.subtittle}><strong>{translate('resources.adoquin_detalles.fields.detallesCalzada')}</strong></div>
                        <Divider fullWidth />
                        <NumberInput 
                            id="largom"
                            source="geometriaLargo" 
                            label="resources.adoquin_detalles.fields.geometriaLargo" 
                            formClassName={classes.first_field} 
                            validate={[required(), minValue(0), maxValue(1999.99), ValDetallesCalzada]}
                        />
                        <NumberInput 
                            id="anchom"
                            source="geometriaAncho" 
                            label="resources.adoquin_detalles.fields.geometriaAncho" 
                            formClassName={classes.last_filed} 
                            validate={[required(), minValue(3), maxValue(40), ValDetallesCalzadaAncho]}
                        />
                        <div className={classes.subtittle}><strong>{translate('resources.adoquin_detalles.fields.detallesCarriles')}</strong></div>
                        <Divider fullWidth />
                        <NumberInput source="geometriaNumCarriles" label="resources.adoquin_detalles.fields.geometriaNumCarriles" formClassName={classes.first_field} validate={[required(), minValue(1), maxValue(5), ValNumeroCarriles, Entero]}/>
                        <NumberInput 
                            id="anchoCar"
                            source="geometriaAnchoCarril" 
                            label="resources.adoquin_detalles.fields.geometriaAnchoCarril" 
                            formClassName={classes.last_filed} 
                            validate={[required(), minValue(1), maxValue(5), ValAnchoCarril]}
                        />
                        <div></div>
                        <SelectInput source="geometriaTipoBerma" label="resources.adoquin_detalles.fields.geometriaTipoBerma" choices={[
                            { id: translate('dynatest.resources.adoquin_detalles.select.geometriaTipoBerma.bermaCuneta'), name: 'dynatest.resources.adoquin_detalles.select.geometriaTipoBerma.bermaCuneta' },
                            { id: translate('dynatest.resources.adoquin_detalles.select.geometriaTipoBerma.flexible'), name: 'dynatest.resources.adoquin_detalles.select.geometriaTipoBerma.flexible' },
                            { id: translate('dynatest.resources.adoquin_detalles.select.geometriaTipoBerma.inexistente'), name: 'dynatest.resources.adoquin_detalles.select.geometriaTipoBerma.inexistente' },
                            { id: translate('dynatest.resources.adoquin_detalles.select.geometriaTipoBerma.rigida'), name: 'dynatest.resources.adoquin_detalles.select.geometriaTipoBerma.rigida' },
                        ]} formClassName={classes.first_field} validate={[validarCondicionBerma]}/>
                        {condicionberma  &&(
                            <SelectInput source="geometriaCondicionBerma" label="resources.adoquin_detalles.fields.geometriaCondicionBerma" choices={[
                                { id: translate('dynatest.resources.adoquin_detalles.select.geometriaCondicionBerma.bueno'), name: 'dynatest.resources.adoquin_detalles.select.geometriaCondicionBerma.bueno' },
                                { id: translate('dynatest.resources.adoquin_detalles.select.geometriaCondicionBerma.regular'), name: 'dynatest.resources.adoquin_detalles.select.geometriaCondicionBerma.regular' },
                                { id: translate('dynatest.resources.adoquin_detalles.select.geometriaCondicionBerma.deficiente'), name: 'dynatest.resources.adoquin_detalles.select.geometriaCondicionBerma.deficiente' },
                            ]} formClassName={classes.last_filed} /> 
                        )} 
                        {condicionberma  &&(
                            <NumberInput source="geometriaAnchoBerma" label="resources.adoquin_detalles.fields.geometriaAnchoBerma" formClassName={classes.last_filed} validate={[required(), minValue(0), maxValue(10), valNum1Decimal]}/>

                        )} 
                        <div></div>
                        <DisabledInput 
                            id="AreaCarrilesAC" 
                            source="aux1" 
                            label="resources.adoquin_detalles.fields.aux1" 
                            formClassName={classes.first_field} 
                            className="align-number"
                            defaultValue ={0}
                        />                
                        <NumberInput 
                            formClassName={classes.last_filed} 
                            source="areaCarrilesVerdadera" 
                            label="resources.adoquin_detalles.fields.areaCarrilesVerdadera" 
                            id="AreaVerdaderaAC" 
                            className="align-number"
                            validate={[required()]}
                        />
                        <div className={classes.subtittle}><strong>{translate('resources.adoquin_detalles.fields.propiedadesGeometricas')}</strong></div>
                        <Divider fullWidth />
                        <PercentageInput className="Longitudes_segmentos" source="geometriaLongitudinal" label="resources.adoquin_detalles.fields.geometriaLongitudinal" formClassName={classes.first_field} validate={[valPendiente20]}/>
                        <PercentageInput className="Longitudes_segmentos" source="geometriaLongitudinalMa" label="resources.adoquin_detalles.fields.geometriaLongitudinalMa" formClassName={classes.last_filed} validate={[valPendiente20]}/>
                        <PercentageInput className="Longitudes_segmentos" source="geometriaLongitudinalMi" label="resources.adoquin_detalles.fields.geometriaLongitudinalMi" formClassName={classes.last_filed} validate={[valPendiente20]}/>
                        {/* -----------RADIO--------- */}
                        <PercentageInput className="Longitudes_segmentos" source="geometriaTransversal" label="resources.adoquin_detalles.fields.geometriaTransversal" formClassName={classes.first_field} validate={[valPendiente5]}/>
                        <NumberInput source="geometriaRadioMi" label="resources.adoquin_detalles.fields.geometriaRadioMi" formClassName={classes.last_filed} validate={[minValue(0), maxValue(5), Entero]}/>
                        <NumberInput source="geometriaRadioMa" label="resources.adoquin_detalles.fields.geometriaRadioMa" formClassName={classes.last_filed} validate={[minValue(0), maxValue(5000), Entero]}/>
                        {/* -------------------- */}
                        <NumberInput source="geometriaRadioCurvatura" label="resources.adoquin_detalles.fields.geometriaRadioCurvatura" formClassName={classes.first_field} validate={[required(), minValue(0), maxValue(5000), valNum1Decimal]} />
                        <NumberInput source="geometriaVelDiseno" label="resources.adoquin_detalles.fields.geometriaVelDiseno" formClassName={classes.last_filed} validate={[required(), minValue(10), maxValue(150), valentero]} />
                        <div></div>
                        <SelectInput source="geometriaSeparador" label="resources.adoquin_detalles.fields.geometriaSeparador" choices={[
                            { id: translate('dynatest.resources.adoquin_detalles.select.geometriaSeparador.Si'), name: 'dynatest.resources.adoquin_detalles.select.geometriaSeparador.Si' },
                            { id: translate('dynatest.resources.adoquin_detalles.select.geometriaSeparador.No'), name: 'dynatest.resources.adoquin_detalles.select.geometriaSeparador.No' },                    
                        ]} formClassName={classes.first_field} onChange={ValidationConSeparador} validate={[required()]}/>
                        { anchosep ===  translate('dynatest.resources.adoquin_detalles.select.geometriaSeparador.Si') &&   
                            <NumberInput source="geometriaSeparadorAncho" label="resources.adoquin_detalles.fields.geometriaSeparadorAncho" formClassName={classes.last_filed} validate={[required(), minValue(0), maxValue(50)]}/>
                        }                               
                        <div></div>
                        <SelectInput source="geometriaCondicionesGeometrica" label="resources.adoquin_detalles.fields.geometriaCondicionesGeometrica" choices={[
                            { id: translate('dynatest.resources.adoquin_detalles.select.geometriaCondicionesGeometrica.bueno'), name: 'dynatest.resources.adoquin_detalles.select.geometriaCondicionesGeometrica.bueno' },
                            { id: translate('dynatest.resources.adoquin_detalles.select.geometriaCondicionesGeometrica.regular'), name: 'dynatest.resources.adoquin_detalles.select.geometriaCondicionesGeometrica.regular' },
                            { id: translate('dynatest.resources.adoquin_detalles.select.geometriaCondicionesGeometrica.deficiente'), name: 'dynatest.resources.adoquin_detalles.select.geometriaCondicionesGeometrica.deficiente' },
                        ]} formClassName={classes.first_field} />
                        <SelectInput source="geometriaAlineamiento" label="resources.adoquin_detalles.fields.geometriaAlineamiento" choices={[
                            { id: translate('dynatest.resources.adoquin_detalles.select.geometriaAlineamiento.curvo'), name: 'dynatest.resources.adoquin_detalles.select.geometriaAlineamiento.curvo' },
                            { id: translate('dynatest.resources.adoquin_detalles.select.geometriaAlineamiento.predominantementeCurvo'), name: 'dynatest.resources.adoquin_detalles.select.geometriaAlineamiento.predominantementeCurvo' },
                            { id: translate('dynatest.resources.adoquin_detalles.select.geometriaAlineamiento.predominantementeRecto'), name: 'dynatest.resources.adoquin_detalles.select.geometriaAlineamiento.predominantementeRecto' },
                            { id: translate('dynatest.resources.adoquin_detalles.select.geometriaAlineamiento.recto'), name: 'dynatest.resources.adoquin_detalles.select.geometriaAlineamiento.recto' },
                        ]} formClassName={classes.last_filed} />
                        <SelectInput source="geometriaTipoTerreno" label="resources.adoquin_detalles.fields.geometriaTipoTerreno" choices={[
                            { id: translate('dynatest.resources.adoquin_detalles.select.geometriaTipoTerreno.escarpado'), name: 'dynatest.resources.adoquin_detalles.select.geometriaTipoTerreno.escarpado' },
                            { id: translate('dynatest.resources.adoquin_detalles.select.geometriaTipoTerreno.montañoso'), name: 'dynatest.resources.adoquin_detalles.select.geometriaTipoTerreno.montañoso' },
                            { id: translate('dynatest.resources.adoquin_detalles.select.geometriaTipoTerreno.ondulado'), name: 'dynatest.resources.adoquin_detalles.select.geometriaTipoTerreno.ondulado' },
                            { id: translate('dynatest.resources.adoquin_detalles.select.geometriaTipoTerreno.plano'), name: 'dynatest.resources.adoquin_detalles.select.geometriaTipoTerreno.plano' },
                        ]} formClassName={classes.last_filed} />
                        <div className={classes.subtittle}><strong>{translate('resources.adoquin_detalles.fields.dimAdoquinTipico')}</strong></div>
                        <NumberInput source="geometriaAdoLargo" label="resources.adoquin_detalles.fields.geometriaAdoLargo" validate={[required(), minValue(0), maxValue(10), valNum2Decimales]} formClassName={classes.first_field}/> 
                        <NumberInput source="geometriaAdoAncho" label="resources.adoquin_detalles.fields.geometriaAdoAncho" validate={[required(), minValue(0), maxValue(10), valNum2Decimales]} formClassName={classes.last_filed}/> 
                        <NumberInput source="geometriaAdoAlto" label="resources.adoquin_detalles.fields.geometriaAdoAlto" validate={[required(), valNum2Decimales]} formClassName={classes.last_filed}/> 
                        <Divider fullWidth formClassName={classes.separador} />                
                        <TextInput source="detallesGeometria" label="resources.adoquin_detalles.fields.detallesGeometria" validate={[minLength(0), maxLength(200)]}/>   
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
            <FormTab label="dynatest.resources.adoquin_detalles.tabs.estructura" onClick={HideBtnImage}>
                <div className={classes.subtittle}><strong>{translate('resources.adoquin_detalles.fields.informacionEstructura')}</strong></div>
                <Divider fullWidth />
                <ArrayInput 
                    source="tablaSegmentos" 
                    style={{width: '100%'}} 
                    label=" " 
                    validate={[required(), arrayCount5]}
                >
                    <SimpleFormIterator disableAdd={arrayMax}>
                        <NumberInput 
                            source="estructuraEspesor" 
                            label="resources.adoquin_detalles.fields.estructuraEspesor" 
                            validate={[ValEspensor, required(), minValue(0), maxValue(20000), Entero]} 
                            formClassName={classes.tabla} 
                            style={{width: '100%'}}
                        />                          
                        <NumberInput 
                            source="estructuraModulo" 
                            label="resources.adoquin_detalles.fields.estructuraModulo" 
                            validate={[required(), minValue(0), maxValue(100000), ValModulo, Entero]} 
                            formClassName={classes.tabla} 
                            style={{width: '100%'}}
                        />   
                        <NumberInput 
                            source="estructuraDs" 
                            label="resources.adoquin_detalles.fields.estructuraDs" 
                            validate={[required(), minValue(0.01), maxValue(4), valNumDecimals]} 
                            formClassName={classes.tabla} 
                            style={{width: '100%'}} 
                            className="Longitudes_segmentos"
                        />                       
                        <ReferenceInput 
                            label="resources.adoquin_detalles.fields.material" 
                            source="material"  
                            reference="materials" 
                            filterToQuery={searchText => ({ material: searchText })} 
                            sort={{field : 'material', order : 'ASC'}}
                            onChange={ValidarEspecificacion}
                            validate={[required()]} 
                            fullWidth 
                            formClassName={classes.tabla} 
                        >
                            <AutocompleteInput  options={{fullWidth : true}} optionText="material"  />
                        </ReferenceInput>  
                                             
                        <ReferenceInput 
                            label="resources.adoquin_detalles.fields.materialEsp" 
                            source="material" 
                            reference="materials"
                            sort={{field : 'material', order : 'ASC'}}
                            formClassName={classes.tabla} style={{width: '100%'}}
                        >
                            <SelectInput  optionText="especificacion" disabled fullWidth />
                        </ReferenceInput>                               
                          
                        <ReferenceInput 
                            label="resources.adoquin_detalles.fields.granulometria" 
                            source="granulometria" 
                            reference="granulometrias" 
                            filterToQuery={searchText => ({ granulometria: searchText })} 
                            filter={{material: material}} 
                            sort={{field : 'granulometria', order : 'ASC'}}
                            formClassName={classes.tabla} 
                            style={{width: '100%'}}
                        >
                            <AutocompleteInput  options={{fullWidth : true}} optionText="granulometria" fullWidth  />
                        </ReferenceInput>   
                    </SimpleFormIterator>
                </ArrayInput>
                <div></div><br/>                                               
                <DisabledInput 
                    label="resources.adoquin_detalles.fields.espesorTotal" 
                    formClassName={classes.first_field} 
                    defaultValue ={espensortotal}
                    className="align-number"
                />                
                <ArrayInput source="EstructuraSegmentos" style={{width: '100%'}} label=" " 
                    defaultValue={[
                    {
                        igg: null,
                        conceito: conceitoEstado,
                        estructuraNumDiseno: null,
                        estructuraFecha: null,
                        estructuraNumero: null,
                        estructuraNumeroFecha: null,
                        estructuraCbr: null,
                        estructuraCbrFecha: null,
                        estructuraFechaConst: null,
                        estructuraFechaRehab: null,
                        detallesEstructura: null
                    }
                    ]} className="inventariosSegmentos">
                    <SimpleFormIterator disableRemove disableAdd>
                        <NumberInput 
                            source="igg" 
                            label="resources.adoquin_detalles.fields.igg" 
                            validate={[minValue(1), maxValue(200), CalcularCoceito]}
                            formClassName={classes.first_field}
                        />
                        <DisabledInput 
                            id="Conceito" 
                            source="conceito" 
                            label="resources.adoquin_detalles.fields.conceito"
                            formClassName={classes.last_filed}
                        />   
                        <div className={classes.subtittle}><strong>{translate('resources.adoquin_detalles.fields.capacidadEstructural')}</strong></div>
                        <Divider fullWidth />
                        <NumberInput source="estructuraNumDiseno" label="resources.adoquin_detalles.fields.estructuraNumDiseno" formClassName={classes.first_field} validate={[required(), minValue(0.1), maxValue(20), valNum1Decimal]}/>
                        <DateInput source="estructuraFecha" label="resources.adoquin_detalles.fields.estructuraFecha" formClassName={classes.last_filed} validate={[required(), isFechaInvalida]} /> 
                        <div></div>
                        <NumberInput source="estructuraNumero" label="resources.adoquin_detalles.fields.estructuraNumero" formClassName={classes.first_field} validate={[required(), minValue(0.1), maxValue(20), valNum1Decimal]}/>
                        <DateInput source="estructuraNumeroFecha" label="resources.adoquin_detalles.fields.estructuraNumeroFecha" formClassName={classes.last_filed} validate={[required(), isFechaInvalida]} /> 
                        <div></div>
                        <NumberInput source="estructuraCbr" label="resources.adoquin_detalles.fields.estructuraCbr" className="align-number" formClassName={classes.first_field} validate={[required(), minValue(0), maxValue(100), valNum1Decimal]}/>
                        <DateInput source="estructuraCbrFecha" label="resources.adoquin_detalles.fields.estructuraCbrFecha" formClassName={classes.last_filed} validate={[required(), isFechaInvalida]} /> 
                        <div className={classes.subtittle}><strong>{translate('resources.adoquin_detalles.fields.fechasImportantes')}</strong></div>
                        <Divider fullWidth />
                        <DateInput source="estructuraFechaConst" label="resources.adoquin_detalles.fields.estructuraFechaConst" formClassName={classes.first_field} validate={[required(),isFechaInvalida]} /> 
                        <DateInput source="estructuraFechaRehab" label="resources.adoquin_detalles.fields.estructuraFechaRehab" formClassName={classes.last_filed} validate={[required(), isFechaInvalida]} /> 
                        <Divider fullWidth formClassName={classes.separador} />
                        <TextInput source="detallesEstructura" label="resources.adoquin_detalles.fields.detallesEstructura" validate={[minLength(0), maxLength(200)]} formClassName={classes.observaciones_field} fullWidth/>   
                    </SimpleFormIterator>
                </ArrayInput>
                <TextInput source="createdIn" style={{ display: 'none' }} defaultValue={"api"} />
                <TextInput source="updatedIn" style={{ display: 'none' }} defaultValue={"api"} />
            </FormTab>
            <FormTab label="dynatest.resources.adoquin_detalles.tabs.fotografias">
                <ImagenesInventario type='create' defaultTipoImagen={defaultTipoImagen}/>                                   
            </FormTab>
        </TabbedForm>
    </PimsCreate>
    )
}));