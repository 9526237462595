import React from 'react';
import { CardActions, ListButton } from 'react-admin';
import { ReferenceImageButton } from './ReferenceImageButton';

export const PimsElementoActions = ({
    basePath,
    className,
    data,
    hasList,
    hasShow,
    resource,
}) => (
    <CardActions className={className}>
        {hasList && <ListButton basePath={basePath} />}
        <ReferenceImageButton/>              
    </CardActions>
);