import React, { Fragment, useState } from 'react';
import {
    NumberInput, ReferenceInput, ArrayInput, SimpleFormIterator, AutocompleteInput,
    SelectInput, FormDataConsumer, DateInput, maxLength,ImageInput, ImageField,
    TextInput, BooleanInput
} from 'react-admin';
import { PimsCreate } from './../../Create';
import { PRInput } from '../../../inputs/PRInput';
import { PInput } from '../../../inputs/PInput';
import Grid from '@material-ui/core/Grid';
import { styles } from './../../../EstilosGrid';
import { withStyles } from '@material-ui/core';
import { abValidationInicio, abValidationFin, validarUnDecimal, validarDosDecimales, Entero, latMininima, lngMininima, isFechaInvalida, validarPR, prValidationInicio, prValidationFin } from '../../validacionesInputs';
import { PimsElementoActions } from './../PimsElementoActions';
import { TabbedForm } from './../../TabbedForm';
import ElementosCreados from './../../ElementosCreados';

import {
    required,
    FormTab,
    minLength,
} from 'react-admin';
import { minValue } from 'ra-core';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CoordenadasProyecto from '../../CoordenadasInventario';
import AbsSegmento from '../../AbsSegmento';
import config from '../../../config/config';
import { translate } from 'react-admin';
import { REDUX_FORM_NAME } from 'react-admin';
import { change } from 'redux-form';
let urlAppjs = config.UrlServer;


// ----------- VALIDACIÓN NÚMEROS ENTEROS -------- 

const garantia = (value) => {
    if (!/^([0-9]+)*$/.test(value)) {
        return translate('dynatest.resources.elemento_senalizacions.messageWarning.numEntero');
    }
}

export const ElementoSenalizacionCreate = translate(withStyles(styles)(({ classes, ...props }) => {
    const { translate } = props;

    /* VALIDAR LOS RANGOS QUE PUEDEN TENER LAS LATITUDES Y LONGITUDES */
    const [latMin, setLatMin] = useState('');
    const [lngMin, setLngMin] = useState('');
    const [latMax, setLatMax] = useState('');
    const [lngMax, setLngMax] = useState('');
    const [segmentos, setSegmento] = useState('');
    const ValidarSegmento = (value) => {
        let valor = value;
        setSegmento(valor);
        let idSegmento = valor;
        idSegmento = idSegmento.split('/');
    }
    const LngIni = (value) => {
        if (lngMax !== '') {
            if (parseFloat(value) > parseFloat(lngMin) || parseFloat(value) < parseFloat(lngMax)) {
                return translate('dynatest.resources.elemento_senalizacions.messageWarning.rangolng') + lngMin + " y " + lngMax;
            }
        }
    }
    const LatIni = (value) => {
        if (latMax !== '') {
            if (parseFloat(value) < parseFloat(latMin) || parseFloat(value) > parseFloat(latMax)) {
                return translate('dynatest.resources.elemento_senalizacions.messageWarning.rangolng') + latMin + " y " + latMax;
            }
        }
    }
    /* FIN DE VALIDACIÓN DE LOS RANGOS QUE PUEDEN TENER LAS LATITUDES Y LONGITUDES */

    /*VALORES POR DEFECTO DEL ARRAY DE COORDENADAS*/
    const [latitudMinimaArray, setLatitudArray] = useState('');
    const LatitudMinimaArray = (value) => {
        setLatitudArray(value)
    }
    const [longitudMinimaArray, setLongitudArray] = useState('');
    const LongitudMinimaArray = (value) => {
        setLongitudArray(value)
    }
    const [longitudMaximaArray, setLongitudMaxdArray] = useState('');
    const LongitudMaximaArray = (value) => {
        setLongitudMaxdArray(value)
    }
    const [latitudMaximaArray, setLatitudMaxdArray] = useState('');
    const LatitudMaximaArray = (value) => {
        setLatitudMaxdArray(value)
    }
    /*FIN DE LOS VALORES POR DEFECTO DEL ARRAY DE COORDENADAS*/

    const [opciones, setOpcion] = useState([{ id: '', name: 'Cargando...' }]);
    const [elemento, setElemento] = useState('');
    const ValidarElemento = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setElemento(valor)

        // fetch(urlAppjs + '/elemento_senalizacions.json?pagination=off&tipoElementoSenal='+valor,
        //     {headers:{
        //         'authorization': 'Bearer '+localStorage.getItem('token')
        //     }
        // }).then((response) => {
        //         return response.json()
        //     })
        //     .then((response) => {

        //         console.log(response)
        //         setOpcion(response);
        //     })
    }
    const [tipoSenal, setTipoSenal] = useState('');
    const ValidarTipoSenal = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setTipoSenal(valor)
    }
    const choices = opciones;


    const optionRenderer = choice => `${choice.name}`;

    const choice = [
        { id: translate('dynatest.resources.elemento_senalizacions.select.choice.amarillo'), name: 'dynatest.resources.elemento_senalizacions.select.choice.amarillo' },
        { id: translate('dynatest.resources.elemento_senalizacions.select.choice.amariFluorescente'), name: 'dynatest.resources.elemento_senalizacions.select.choice.amariFluorescente' },
        { id: translate('dynatest.resources.elemento_senalizacions.select.choice.amariVerdeFluores'), name: 'dynatest.resources.elemento_senalizacions.select.choice.amariVerdeFluores' },
        { id: translate('dynatest.resources.elemento_senalizacions.select.choice.azul'), name: 'dynatest.resources.elemento_senalizacions.select.choice.azul' },
        { id: translate('dynatest.resources.elemento_senalizacions.select.choice.blanco'), name: 'dynatest.resources.elemento_senalizacions.select.choice.blanco' },
        { id: translate('dynatest.resources.elemento_senalizacions.select.choice.marrón'), name: 'dynatest.resources.elemento_senalizacions.select.choice.marrón' },
        { id: translate('dynatest.resources.elemento_senalizacions.select.choice.naranja'), name: 'dynatest.resources.elemento_senalizacions.select.choice.naranja' },
        { id: translate('dynatest.resources.elemento_senalizacions.select.choice.naranjaFluorescente'), name: 'dynatest.resources.elemento_senalizacions.select.choice.naranjaFluorescente' },
        { id: translate('dynatest.resources.elemento_senalizacions.select.choice.rojo'), name: 'dynatest.resources.elemento_senalizacions.select.choice.rojo' },
        { id: translate('dynatest.resources.elemento_senalizacions.select.choice.verde'), name: 'dynatest.resources.elemento_senalizacions.select.choice.verde' },
        { id: translate('dynatest.resources.elemento_senalizacions.select.choice.noAplica'), name: 'dynatest.resources.elemento_senalizacions.select.choice.noAplica' },
        // { id: 'Negro', name: 'Negro' },
    ];

    const choicess = [
        { id: translate('dynatest.resources.elemento_senalizacions.select.choicess.amarillo'), name: 'dynatest.resources.elemento_senalizacions.select.choicess.amarillo' },
        { id: translate('dynatest.resources.elemento_senalizacions.select.choicess.amariFluorescente'), name: 'dynatest.resources.elemento_senalizacions.select.choicess.amariFluorescente' },
        { id: translate('dynatest.resources.elemento_senalizacions.select.choicess.amariVerdeFluores'), name: 'dynatest.resources.elemento_senalizacions.select.choicess.amariVerdeFluores' },
        { id: translate('dynatest.resources.elemento_senalizacions.select.choicess.azul'), name: 'dynatest.resources.elemento_senalizacions.select.choicess.azul' },
        { id: translate('dynatest.resources.elemento_senalizacions.select.choicess.blanco'), name: 'dynatest.resources.elemento_senalizacions.select.choicess.blanco' },
        { id: translate('dynatest.resources.elemento_senalizacions.select.choicess.marrón'), name: 'dynatest.resources.elemento_senalizacions.select.choicess.marrón' },
        { id: translate('dynatest.resources.elemento_senalizacions.select.choicess.naranja'), name: 'dynatest.resources.elemento_senalizacions.select.choicess.naranja' },
        { id: translate('dynatest.resources.elemento_senalizacions.select.choicess.naranjaFluorescente'), name: 'dynatest.resources.elemento_senalizacions.select.choicess.naranjaFluorescente' },
        { id: translate('dynatest.resources.elemento_senalizacions.select.choicess.negro'), name: 'dynatest.resources.elemento_senalizacions.select.choicess.negro' },
        { id: translate('dynatest.resources.elemento_senalizacions.select.choicess.rojo'), name: 'dynatest.resources.elemento_senalizacions.select.choicess.rojo' },
        { id: translate('dynatest.resources.elemento_senalizacions.select.choicess.verde'), name: 'dynatest.resources.elemento_senalizacions.select.choicess.verde' },        
        { id: translate('dynatest.resources.elemento_senalizacions.select.choicess.noAplica'), name: 'dynatest.resources.elemento_senalizacions.select.choicess.noAplica' },
    ];

    /*VALIDACIÓN DE IMAGENES PREDETERMINADAS */
    const [images, setImagen] = useState('')
    const Imagenes = (value) => {
        if (value !== undefined) {
            let valor = value.split('/')
            valor = valor[2]
            setImagen('/imagenes/senalesColombia/senales_informativas/' + valor + '.png')
        }

    }
    const Imagenes1 = (value) => {
        if (value !== undefined) {
            let valor = value.split('/')
            valor = valor[2]
            setImagen('/imagenes/senalesPeru/' + valor + '.png')
        }

    }

    const Imagenes2 = (value) => {
        if (value !== undefined) {
            let valor = value.split('/')
            valor = valor[2]
            setImagen('/imagenes/senalesPeru/TiposSenal/' + valor + '.png')
        }

    }
    /* FIN DE VALIDACIÓN DE IMAGENES PREDETERMINADAS */

    const [unidads, setUnidad] = useState('');
    const [tramos, setTramo] = useState('');

    const ValidarUnidad = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setUnidad(value);
    }
    const ValidarTramo = (value) => {
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setTramo(value);
    }

    /*OPCIONES PARA TIPO DE IMÁGENES*/
    const [opcionTipoImagen,setOpcionTipoImagen] = useState([{id: 'Cargando...' ,name :'Cargando...'}]);
    const [defaultTipoImagen,setDefaultTipoImagen] = useState([{id: 'Cargando...' ,name :'Cargando...'}]);
    const OpcionTipoImagen = (value) =>{
        if(value !== '/tipo_elementos/DESC'){
            setDefaultTipoImagen([
                {
                    tipoImagen : '/tipo_images/1',
                },
                {
                    tipoImagen : '/tipo_images/7',
                },
                {
                    tipoImagen : '/tipo_images/8',
                },
                {
                    tipoImagen : '/tipo_images/9',
                },
                {
                    tipoImagen : '/tipo_images/5',
                },
                {
                    tipoImagen : '/tipo_images/6',
                }
            ])
           
        }else{
            setDefaultTipoImagen([
                {
                    tipoImagen : '/tipo_images/1',
                },
                {
                    tipoImagen : '/tipo_images/8',
                },
                {
                    tipoImagen : '/tipo_images/9',
                },
                {
                    tipoImagen : '/tipo_images/5',
                },
                {
                    tipoImagen : '/tipo_images/6',
                }
            ])
        } 
        setOpcionTipoImagen([
            {id:'/tipo_images/1'  , name:'Panorámica'},
            {id:'/tipo_images/7'  , name:'Elemento completo'},
            {id:'/tipo_images/8'  , name:'Detalle 1'},
            {id:'/tipo_images/9'  , name:'Detalle 2'},
            {id:'/tipo_images/5'  , name:'Deterioro 1'},
            {id:'/tipo_images/6'  , name:'Deterioro 2'},
        ])       
    }

    /*VALIDACIÓN DE DIÁMETRO EN FORMA DE TRABLERO*/
    const [FormaTablero,setFormaTablero] = useState('');
    const ValidarFormaTablero = (value) =>{
        setFormaTablero(value);        
    }

    const [shouldShowOnReduxChange, setShouldShowOnReduxChange] = useState(true);
    return (
         <PimsCreate  {...props} /*actions={<PimsElementoActions value={images} permissionImagen={true} >}*/ >
            <TabbedForm redirect="list"  >
                {/* ------ ELEMENTO SEÑAL ------ */}
                <FormTab label="dynatest.resources.elemento_senalizacions.tabs.tabsTitle.identificacion" >
                    <CoordenadasProyecto/>
                    <Grid container spasing={24}>
                        <ReferenceInput 
                            label="resources.elemento_senalizacions.fields.tipoElementoSenal" 
                            source="tipoElementoSenal" 
                            reference="tipo_elemento_senals" 
                            validate={[required(), Imagenes1, OpcionTipoImagen]} 
                            onChange={ValidarElemento} 
                            sort={{ field: 'customOrder', order: 'ASC' }}
                        >
                            <SelectInput optionText="nombre"  fullWidth />
                        </ReferenceInput>
                    </Grid>
                    <Grid container spacing={24}>
                        <Grid item xs={6}>
                            <SelectInput label="resources.elemento_senalizacions.fields.margen" source="margen" allowEmpty emptyValue="" validate={[required()]} fullWidth choices={[
                                { id: translate('dynatest.resources.elemento_senalizacions.select.margen.derecha'), name: 'dynatest.resources.elemento_senalizacions.select.margen.derecha' },
                                { id: translate('dynatest.resources.elemento_senalizacions.select.margen.izquierda'), name: 'dynatest.resources.elemento_senalizacions.select.margen.izquierda' },
                                { id: translate('dynatest.resources.elemento_senalizacions.select.margen.centro'), name: 'dynatest.resources.elemento_senalizacions.select.margen.centro' },
                            ]} />
                        </Grid>
                        <Grid item xs={6}>
                            <SelectInput label="resources.elemento_senalizacions.fields.sentido" source='sentido' choices={[
                                { id: translate('dynatest.resources.elemento_senalizacions.select.sentido.NORTEId'), name: 'dynatest.resources.elemento_senalizacions.select.sentido.NORTEName' },
                                { id: translate('dynatest.resources.elemento_senalizacions.select.sentido.SURId'), name: 'dynatest.resources.elemento_senalizacions.select.sentido.SURName' },
                                { id: translate('dynatest.resources.elemento_senalizacions.select.sentido.ESTEId'), name: 'dynatest.resources.elemento_senalizacions.select.sentido.ESTEName' },
                                { id: translate('dynatest.resources.elemento_senalizacions.select.sentido.OESTEId'), name: 'dynatest.resources.elemento_senalizacions.select.sentido.OESTEName' }
                                ]}
                                fullWidth
                                formClassName={classes.grid_cont6_f}/>
                        </Grid>

                        <FormDataConsumer>
                        {
                            ({ formData, dispatch, ...rest }) => (
                                <Fragment>
                                    <Grid item xs={6}>
                                        {elemento &&
                                            <ReferenceInput 
                                                label="resources.elemento_senalizacions.fields.tipoSenal" 
                                                source="tipoSenal" 
                                                reference="tipo_senals"  
                                                filter={{ tipoElementoSenal: elemento }}
                                                sort={{ field: 'nombre', order: 'ASC' }} 
                                                filterToQuery={searchText => ({ nombre: searchText })} 
                                                validate={[required(), Imagenes2, setTipoSenal]} 
                                                onChange={value => {
                                                    dispatch(change(REDUX_FORM_NAME, 'senal', null))
                                                    setShouldShowOnReduxChange(false)
                                                }}
                                                >
                                                <AutocompleteInput  options={{fullWidth : true}} optionText="nombre"  />
                                            </ReferenceInput>
                                        }
                                    </Grid>
                                    <Fragment>
                                        {shouldShowOnReduxChange ?
                                            <Grid item xs={6}>
                                                {elemento === '/tipo_elemento_senals/SV' && tipoSenal &&
                                                    <ReferenceInput 
                                                        label="resources.elemento_senalizacions.fields.senal"
                                                        source="senal" 
                                                        reference="senals" 
                                                        validate={[required(), Imagenes]} 
                                                        filter={{ tipoSenal: tipoSenal }}
                                                        filterToQuery={searchText => ({ id: searchText })}
                                                    >
                                                        <AutocompleteInput optionText="ids" options={{fullWidth: true}} />
                                                    </ReferenceInput>
                                                }
                                            </Grid>
                                            :
                                            setShouldShowOnReduxChange(true)
                                        }
                                    </Fragment>
                                </Fragment>
                            )
                        }
                        </FormDataConsumer>
                        <Grid item xs={6}>
                            {elemento === '/tipo_elemento_senals/SV' && tipoSenal &&
                                <ReferenceInput 
                                    label="resources.elemento_senalizacions.fields.modelo"
                                    source="senal" 
                                    reference="senals" 
                                    validate={[required(), Imagenes]} 
                                    filter={{ tipoSenal: tipoSenal }}
                                    filterToQuery={searchText => ({ nombre: searchText })}
                                >
                                    <AutocompleteInput optionText="nombre" options={{fullWidth: true}} />
                                </ReferenceInput>
                            }

                        </Grid>
                        
                        {/* ---------- LOCALIZACIÓN --------- */}
                        <Grid item xs={12}>
                            <Typography variant="h3" component="h3">{translate('dynatest.resources.elemento_senalizacions.subTitle.localizacion')}</Typography>
                            <Divider fullWidth style={{ marginTop: '10px' }} />
                        </Grid>
                        <Grid item xs={4}>
                            <ReferenceInput
                                label="resources.elemento_senalizacions.fields.unidadFuncional"
                                source="unidadFuncional"
                                reference="unidad_funcionals"
                                filterToQuery={searchText => ({ codigo: searchText })}
                                sort={{ field: 'codigo', order: 'asc' }}
                                validate={[required(), ValidarUnidad]}
                                fullWidth
                            >
                                <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
                            </ReferenceInput>
                        </Grid>

                        {unidads &&
                            <Grid item xs={4}>
                                <ReferenceInput
                                    label="resources.elemento_senalizacions.fields.tramo"
                                    source="tramo"
                                    alwaysOn
                                    reference="tramos"
                                    filter={{ unidadFuncional: unidads }}
                                    filterToQuery={searchText => ({ codigo: searchText })}
                                    sort={{ field: 'codigo', order: 'ASC', }}
                                    validate={[required(), ValidarTramo]}
                                    formClassName={classes.grid_cont4}
                                >
                                    <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
                                </ReferenceInput>
                            </Grid>
                        }
                        {tramos &&
                            <Grid item xs={4}>
                                <ReferenceInput
                                    label="resources.elemento_senalizacions.fields.segmento"
                                    source="segmento"
                                    reference="segmentos"
                                    sort={{ field: 'codigo', order: 'ASC' }}
                                    filter={{ tramo: tramos }}
                                    filterToQuery={searchText => ({ codigo: searchText })}
                                    validate={[required(), ValidarSegmento]}
                                    formClassName={classes.grid_cont4}
                                >

                                    <AutocompleteInput options={{ fullWidth: true }} optionText="codigo" />
                                </ReferenceInput>
                            </Grid>
                        }
                    </Grid>
                    {segmentos &&
                        <AbsSegmento segmento={segmentos} />
                    }
                    {segmentos &&
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                            {segmentos && elemento === '/tipo_elemento_senals/SV' && 
                                <Typography variant="h3" component="h3" className={classes.coord}>{translate('dynatest.resources.elemento_senalizacions.subTitle.coordenadas')}</Typography>

                            }
                            {segmentos && elemento !== '/tipo_elemento_senals/SV' && 
                                <Typography variant="h3" component="h3" className={classes.coord}>{translate('dynatest.resources.elemento_senalizacions.subTitle.coordenadasIniciales')}</Typography>
                            }
                                <Divider fullWidth style={{ marginTop: '10px' }} />
                            </Grid>
                        </Grid>
                    }
                    {segmentos && <PRInput label="resources.elemento_senalizacions.fields.abscisa" id="ingresoAbInicio" source="abInicio" fullWidth validate={[abValidationInicio, required()]} formClassName={classes.grid_cont3s} />}
                    {segmentos && <PInput label="resources.elemento_senalizacions.fields.pr" id="ingresoPrInicio" source="pInicio" fullWidth validate={[prValidationInicio]} formClassName={classes.grid_cont3} />}
                    {segmentos && <NumberInput label="resources.elemento_senalizacions.fields.latitud" id="LatitudInicial" source="latitudInicial" fullWidth validate={[required(), latMininima, LatitudMinimaArray]} formClassName={classes.grid_cont3} />}
                    {segmentos && <NumberInput label="resources.elemento_senalizacions.fields.longitud" id="LongitudInicial" source="longitudInicial" fullWidth validate={[required(), lngMininima, LongitudMinimaArray]} formClassName={classes.grid_cont3} />}
                    {segmentos && elemento !== '/tipo_elemento_senals/SV' &&
                        <Grid container spacing={24}>
                            <Grid item xs={12}>
                                <Typography variant="h3" component="h3" className={classes.coord}>{translate('dynatest.resources.elemento_senalizacions.subTitle.coordenadasFinales')}</Typography>
                                <Divider fullWidth style={{ marginTop: '10px' }} />
                            </Grid>
                        </Grid>
                    }
                    {segmentos && elemento !== '/tipo_elemento_senals/SV' && <PRInput label="resources.elemento_senalizacions.fields.abscisa" id="ingresoAbFin" source="abFin" fullWidth validate={[abValidationFin]} formClassName={classes.grid_cont3s} />}
                    {segmentos && elemento !== '/tipo_elemento_senals/SV' && <PInput label="resources.elemento_senalizacions.fields.pr" id="ingresoPrFin" source="pFin" fullWidth validate={[prValidationFin]} formClassName={classes.grid_cont3} />}
                    {segmentos && elemento !== '/tipo_elemento_senals/SV' && <NumberInput label="resources.elemento_senalizacions.fields.latitud" source="latitudFinal" fullWidth validate={[latMininima, LatitudMaximaArray]} formClassName={classes.grid_cont3} />}
                    {segmentos && elemento !== '/tipo_elemento_senals/SV' && <NumberInput label="resources.elemento_senalizacions.fields.longitud" source="longitudFinal" fullWidth validate={[lngMininima, LongitudMaximaArray]} formClassName={classes.grid_cont3} />}
                    {segmentos && elemento !== '/tipo_elemento_senals/SV' &&
                        <ArrayInput label="" source="ubicaciones" className={classes.ocultar}
                            defaultValue={[
                                {
                                    lat: latitudMinimaArray,
                                    lng: longitudMinimaArray
                                },
                                {
                                    lat: latitudMaximaArray,
                                    lng: longitudMaximaArray
                                }
                            ]}
                        >
                            <SimpleFormIterator disableAdd disableRemove >
                                <NumberInput label="Latitud" source="lat" fullWidth />
                                <NumberInput label="Longitud" source="lng" fullWidth />
                            </SimpleFormIterator>
                        </ArrayInput>
                    }
                    {segmentos && elemento === '/tipo_elemento_senals/SV' &&
                        <ArrayInput label="" source="ubicaciones" className={classes.ocultar}
                            defaultValue={[
                                {
                                    lat: latitudMinimaArray,
                                    lng: longitudMinimaArray
                                }
                            ]}
                        >
                            <SimpleFormIterator disableAdd disableRemove >
                                <NumberInput label="Latitud" source="lat" fullWidth />
                                <NumberInput label="Longitud" source="lng" fullWidth />
                            </SimpleFormIterator>
                        </ArrayInput>
                    }
                </FormTab>
                {/* -------------- CARACTERÍSTICAS ---------- */}
                <FormTab label="dynatest.resources.elemento_senalizacions.tabs.tabsTitle.caracteristicas" redirect="list" >
                    <FormDataConsumer>
                        {
                            ({ formData, ...rest }) => (formData !== null && formData !== undefined && formData.tipoElementoSenal === '/tipo_elemento_senals/SV' && formData.tipoSenal === '/tipo_senals/SMV' &&
                                <Grid item xs={12}>
                                    <Typography fullWidth variant="p" component="p">
                                        <br/>{translate('dynatest.resources.elemento_senalizacions.messageWarning.warningMensajeVariable')}
                            </Typography>
                                </Grid>
                            )
                        }
                    </FormDataConsumer>
                    
                    <FormDataConsumer>
                        {
                            ({ formData, ...rest }) => (formData !== null && formData !== undefined && 
                                formData.tipoSenal !== '/tipo_senals/SMV' && 

                                <FormDataConsumer>
                                    {
                                        ({ formData, ...rest }) => (formData &&formData !== null  && formData.tipoElementoSenal === '/tipo_elemento_senals/SV' &&
                                            <Grid container spacing={24}>
                                                <Grid item xs={4}>
                                                    <SelectInput fullWidth label="resources.elemento_senalizacions.fields.materialPlaca" source="material" allowEmpty emptyValue="" choices={[
                                                        { id: translate('dynatest.resources.elemento_senalizacions.select.material.aceroGalvanizado'), name: 'dynatest.resources.elemento_senalizacions.select.material.aceroGalvanizado' },
                                                        { id: translate('dynatest.resources.elemento_senalizacions.select.material.aluminio'), name: 'dynatest.resources.elemento_senalizacions.select.material.aluminio' },
                                                        { id: translate('dynatest.resources.elemento_senalizacions.select.material.fibra'), name: 'dynatest.resources.elemento_senalizacions.select.material.fibra' },
                                                    ]} validate={[required()]} />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <SelectInput source="tipoPapel" label="resources.elemento_senalizacions.fields.tipoPapel" allowEmpty emptyValue="" fullWidth choices={[
                                                        { id: translate('dynatest.resources.elemento_senalizacions.select.tipoPapel.altaAmarillo'), name: 'dynatest.resources.elemento_senalizacions.select.tipoPapel.altaAmarillo' },
                                                        { id: translate('dynatest.resources.elemento_senalizacions.select.tipoPapel.altaBlanco'), name: 'dynatest.resources.elemento_senalizacions.select.tipoPapel.altaBlanco' },
                                                        { id: translate('dynatest.resources.elemento_senalizacions.select.tipoPapel.diamanteAmariVerdeFluores'), name: 'dynatest.resources.elemento_senalizacions.select.tipoPapel.diamanteAmariVerdeFluores' },
                                                        { id: translate('dynatest.resources.elemento_senalizacions.select.tipoPapel.diamanteAzul'), name: 'dynatest.resources.elemento_senalizacions.select.tipoPapel.diamanteAzul' },
                                                        { id: translate('dynatest.resources.elemento_senalizacions.select.tipoPapel.diamanteBlanco'), name: 'dynatest.resources.elemento_senalizacions.select.tipoPapel.diamanteBlanco' },
                                                        { id: translate('dynatest.resources.elemento_senalizacions.select.tipoPapel.diamanteMarron'), name: 'dynatest.resources.elemento_senalizacions.select.tipoPapel.diamanteMarron' },
                                                        { id: translate('dynatest.resources.elemento_senalizacions.select.tipoPapel.diamanteRojo'), name: 'dynatest.resources.elemento_senalizacions.select.tipoPapel.diamanteRojo' },
                                                        { id: translate('dynatest.resources.elemento_senalizacions.select.tipoPapel.diamanteVerde'), name: 'dynatest.resources.elemento_senalizacions.select.tipoPapel.diamanteVerde' },
                                                        { id: translate('dynatest.resources.elemento_senalizacions.select.tipoPapel.ingenieriaAmarillo'), name: 'dynatest.resources.elemento_senalizacions.select.tipoPapel.ingenieriaAmarillo' },
                                                        { id: translate('dynatest.resources.elemento_senalizacions.select.tipoPapel.ingenieriaBlanco'), name: 'dynatest.resources.elemento_senalizacions.select.tipoPapel.ingenieriaBlanco' },
                                                        { id: translate('dynatest.resources.elemento_senalizacions.select.tipoPapel.t11Amarillo700'), name: 'dynatest.resources.elemento_senalizacions.select.tipoPapel.t11Amarillo700' },
                                                    ]} validate={[required()]} />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <SelectInput label="resources.elemento_senalizacions.fields.tipoLamina" source="tipoLamina" allowEmpty emptyValue="" fullWidth choices={[
                                                        { id: 'I', name: 'I' },
                                                        { id: 'II', name: 'II' },
                                                        { id: 'III', name: 'III' },
                                                        { id: 'IV', name: 'IV' },
                                                        { id: 'V', name: 'V' },
                                                        { id: 'VI', name: 'VI' },
                                                        { id: 'VII', name: 'VII' },
                                                        { id: 'VIII', name: 'VIII' },
                                                        { id: 'IX', name: 'IX' },
                                                        { id: 'X', name: 'X' },
                                                        { id: 'XI', name: 'XI' },

                                                    ]} validate={[required()]} />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <SelectInput label="resources.elemento_senalizacions.fields.tipoTablero" source="tipoTablero" allowEmpty emptyValue="" fullWidth choices={[
                                                        { id: translate('dynatest.resources.elemento_senalizacions.select.tipoTablero.circular'), name: 'dynatest.resources.elemento_senalizacions.select.tipoTablero.circular' },
                                                        { id: translate('dynatest.resources.elemento_senalizacions.select.tipoTablero.cuadrada'), name: 'dynatest.resources.elemento_senalizacions.select.tipoTablero.cuadrada' },
                                                        { id: translate('dynatest.resources.elemento_senalizacions.select.tipoTablero.rectangular'), name: 'dynatest.resources.elemento_senalizacions.select.tipoTablero.rectangular' },
                                                        { id: translate('dynatest.resources.elemento_senalizacions.select.tipoTablero.otro'), name: 'dynatest.resources.elemento_senalizacions.select.tipoTablero.otro' },
                                                    ]} validate={[required(), ValidarFormaTablero]} />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <SelectInput label="resources.elemento_senalizacions.fields.dimensionTablero" source="dimensionTablero" allowEmpty emptyValue="" fullWidth choices={[
                                                        { id: translate('dynatest.resources.elemento_senalizacions.select.dimensionTablero.x80'), name: 'dynatest.resources.elemento_senalizacions.select.dimensionTablero.x80' },
                                                        { id: translate('dynatest.resources.elemento_senalizacions.select.dimensionTablero.x100'), name: 'dynatest.resources.elemento_senalizacions.select.dimensionTablero.x100' },
                                                        { id: translate('dynatest.resources.elemento_senalizacions.select.dimensionTablero.x120'), name: 'dynatest.resources.elemento_senalizacions.select.dimensionTablero.x120' },
                                                        { id: translate('dynatest.resources.elemento_senalizacions.select.dimensionTablero.x140'), name: 'dynatest.resources.elemento_senalizacions.select.dimensionTablero.x140' },
                                                        { id: translate('dynatest.resources.elemento_senalizacions.select.dimensionTablero.Otra'), name: 'dynatest.resources.elemento_senalizacions.select.dimensionTablero.Otra' },

                                                    ]} validate={[required()]} />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <NumberInput label="resources.elemento_senalizacions.fields.anchoTablero" source="anchoTablero" fullWidth validate={[minValue(0), validarDosDecimales]} />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <NumberInput label="resources.elemento_senalizacions.fields.altoTablero" source="altoTablero" fullWidth validate={[minValue(0), validarDosDecimales]} />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <SelectInput label="resources.elemento_senalizacions.fields.estadoTablero" source="estadoTablero" allowEmpty emptyValue="" fullWidth choices={[
                                                        { id: translate('dynatest.resources.elemento_senalizacions.select.estadoTablero.Afectada'), name: 'dynatest.resources.elemento_senalizacions.select.estadoTablero.Afectada' },
                                                        { id: translate('dynatest.resources.elemento_senalizacions.select.estadoTablero.Buena'), name: 'dynatest.resources.elemento_senalizacions.select.estadoTablero.Buena' },
                                                        ]} />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <SelectInput label="resources.elemento_senalizacions.fields.tipoSoporte" source="tipoSoporte" allowEmpty emptyValue="" fullWidth choices={[
                                                        { id: translate('dynatest.resources.elemento_senalizacions.select.tipoSoporte.Bandera'), name: 'dynatest.resources.elemento_senalizacions.select.tipoSoporte.Bandera' },
                                                        { id: translate('dynatest.resources.elemento_senalizacions.select.tipoSoporte.Pasacalle'), name: 'dynatest.resources.elemento_senalizacions.select.tipoSoporte.Pasacalle' },
                                                        { id: translate('dynatest.resources.elemento_senalizacions.select.tipoSoporte.Vastago'), name: 'dynatest.resources.elemento_senalizacions.select.tipoSoporte.Vastago' },

                                                    ]} validate={[/* required() */]} />
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <SelectInput label="resources.elemento_senalizacions.fields.estadoSoporte" source="estadoSoporte" allowEmpty emptyValue="" fullWidth choices={[
                                                        { id: translate('dynatest.resources.elemento_senalizacions.select.estadoSoporte.Afectada'), name: 'dynatest.resources.elemento_senalizacions.select.estadoSoporte.Afectada' },
                                                        { id: translate('dynatest.resources.elemento_senalizacions.select.estadoSoporte.Buena'), name: 'dynatest.resources.elemento_senalizacions.select.estadoSoporte.Buena' },
                                                        ]} />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <SelectInput label="resources.elemento_senalizacions.fields.materialSoporte" source="materialSoporte" allowEmpty emptyValue="" fullWidth choices={[
                                                        { id: translate('dynatest.resources.elemento_senalizacions.select.materialSoporte.Acero'), name: 'dynatest.resources.elemento_senalizacions.select.materialSoporte.Acero' },
                                                        { id: translate('dynatest.resources.elemento_senalizacions.select.materialSoporte.Madera'), name: 'dynatest.resources.elemento_senalizacions.select.materialSoporte.Madera' },
                                                        ]} />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <SelectInput label="resources.elemento_senalizacions.fields.velocidadDimension" source="velocidadDimension" allowEmpty emptyValue="" fullWidth choices={[
                                                        { id: '< 70 km/h', name: '< 70 km/h' },
                                                        { id: '< 90 km/h', name: '< 90 km/h' },
                                                        { id: '< 100 km/h', name: '< 100 km/h' },
                                                        { id: '> 100 km/h', name: '> 100 km/h' },

                                                    ]} validate={[required()]} />
                                                </Grid>

                                                <Grid item xs={4}>
                                                    <NumberInput label="resources.elemento_senalizacions.fields.altura" source="altura" validate={[required(), minValue(0, "dynatest.resources.elemento_senalizacions.messageWarning.altura"), validarUnDecimal]} fullWidth />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <NumberInput label="resources.elemento_senalizacions.fields.valorLimite" source="valorLimite" fullWidth />
                                                    <Typography className={classes.infoAdicional} variant="body2">{translate('resources.elemento_senalizacions.fields.valorLimiteDescrip')}</Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <NumberInput label="resources.elemento_senalizacions.fields.ABNT" source="ABNT" fullWidth validate={[minValue(1), Entero]} />
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <SelectInput label="resources.elemento_senalizacions.fields.color" sort={{field:'name', order:'ASC'}} source="color" allowEmpty emptyValue="" fullWidth choices={choice} />
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <NumberInput label="resources.elemento_senalizacions.fields.retroflectividadInicial" source="retroflectividadInicial" fullWidth validate={[minValue(0), Entero]} />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <SelectInput label="resources.elemento_senalizacions.fields.colorSecundario" sort={{field:'name', order:'ASC'}} source="colorSecundario" allowEmpty emptyValue="" fullWidth choices={choicess} />
                                                </Grid>

                                                <FormDataConsumer>
                                                    {({ formData, ...rest }) => (formData !== null && formData !== undefined && formData.colorSecundario !== 'No aplica' &&
                                                        <Grid item xs={6}>
                                                            <NumberInput label="resources.elemento_senalizacions.fields.retroflectividadFinal" source="retroflectividadFinal" fullWidth validate={[minValue(0), Entero]} />
                                                        </Grid>
                                                    )
                                                    }
                                                </FormDataConsumer>
                                                <Grid item xs={6}>
                                                    <SelectInput label="resources.elemento_senalizacions.fields.colorTerciario" sort={{field:'name', order:'ASC'}} source="colorTerciario" allowEmpty emptyValue="" fullWidth choices={choicess} />
                                                </Grid>

                                                <Grid item xs={6}>
                                                    <NumberInput label="resources.elemento_senalizacions.fields.retroflectividadTerciaria" source="retroflectividadTerciaria" fullWidth />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    {/*FormaTablero === 'Circular' &&                                                        
                                                        <NumberInput label="Diámetro (m)" source="area" validate={[minValue(0, "Debe ser al menos 0"), validarUnDecimal]} fullWidth/>                                                        
                                                    */}
                                                </Grid>
                                                <br/>
                                                
                                            </Grid>
                                        )
                                    }
                                </FormDataConsumer>
                            )
                        }
                    </FormDataConsumer>



                    {/* --------- VALIDACIÓN DE SEÑAL HORIZONTAL --------------- */}

                    <FormDataConsumer>
                        {
                            ({ formData, ...rest }) => (formData !== null && formData !== undefined && formData.tipoElementoSenal === '/tipo_elemento_senals/SH' &&
                                <Grid container spacing={24}>
                                    <Grid item xs={4}>
                                        <SelectInput label="resources.elemento_senalizacions.fields.material" source="material" allowEmpty emptyValue="" fullWidth choices={[
                                            { id: translate('dynatest.resources.elemento_senalizacions.select.material.cintasPreformadas'), name: 'dynatest.resources.elemento_senalizacions.select.material.cintasPreformadas' },
                                            { id: translate('dynatest.resources.elemento_senalizacions.select.material.pinturas'), name: 'dynatest.resources.elemento_senalizacions.select.material.pinturas' },
                                            { id: translate('dynatest.resources.elemento_senalizacions.select.material.plasticosFrio'), name: 'dynatest.resources.elemento_senalizacions.select.material.plasticosFrio' },
                                            { id: translate('dynatest.resources.elemento_senalizacions.select.material.termoplasticas'), name: 'dynatest.resources.elemento_senalizacions.select.material.termoplasticas' },

                                        ]} validate={[required()]} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <SelectInput source="color" label="resources.elemento_senalizacions.fields.colorOtrosElem" allowEmpty emptyValue="" fullWidth choices={[
                                            { id: translate('dynatest.resources.elemento_senalizacions.select.colorOtrosElem.Amarilla'), name: 'dynatest.resources.elemento_senalizacions.select.colorOtrosElem.Amarilla' },
                                            { id: translate('dynatest.resources.elemento_senalizacions.select.colorOtrosElem.Blanca'), name: 'dynatest.resources.elemento_senalizacions.select.colorOtrosElem.Blanca' },

                                        ]} validate={[required()]} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <NumberInput label="resources.elemento_senalizacions.fields.numeroTachas" fullWidth source="numeroTachas" validate={[required(), garantia]} />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <NumberInput label="resources.elemento_senalizacions.fields.longitud" source="longitud" validate={[required(), validarUnDecimal, minValue(0)]} fullWidth />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <NumberInput label="resources.elemento_senalizacions.fields.ancho" source="ancho" validate={[required(), validarUnDecimal, minValue(0)]} fullWidth />
                                    </Grid>

                                </Grid>

                            )
                        }
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => (formData !== null && formData !== undefined && formData.tipoElementoSenal === '/tipo_elemento_senals/DEM' &&
                            <Grid container spacing={24}>
                                <Grid item xs={6}>
                                    <SelectInput source="material" label="resources.elemento_senalizacions.fields.material" allowEmpty emptyValue="" fullWidth choices={[
                                        { id: translate('dynatest.resources.elemento_senalizacions.select.material.cintasPreformadas'), name: 'dynatest.resources.elemento_senalizacions.select.material.cintasPreformadas' },
                                        { id: translate('dynatest.resources.elemento_senalizacions.select.material.pinturas'), name: 'dynatest.resources.elemento_senalizacions.select.material.pinturas' },
                                        { id: translate('dynatest.resources.elemento_senalizacions.select.material.plasticosFrio'), name: 'dynatest.resources.elemento_senalizacions.select.material.plasticosFrio' },
                                        { id: translate('dynatest.resources.elemento_senalizacions.select.material.termoplasticas'), name: 'dynatest.resources.elemento_senalizacions.select.material.termoplasticas' },

                                    ]} validate={[required()]} />
                                </Grid>
                                <Grid item xs={6}>
                                    <SelectInput source="color" label="resources.elemento_senalizacions.fields.colorOtrosElem" allowEmpty emptyValue="" fullWidth choices={[
                                        { id: translate('dynatest.resources.elemento_senalizacions.select.colorOtrosElem.Amarilla'), name: 'dynatest.resources.elemento_senalizacions.select.colorOtrosElem.Amarilla' },
                                        { id: translate('dynatest.resources.elemento_senalizacions.select.colorOtrosElem.Blanca'), name: 'dynatest.resources.elemento_senalizacions.select.colorOtrosElem.Blanca' },

                                    ]} validate={[required()]} />
                                </Grid>
                                <Grid item xs={4}>
                                    <NumberInput label="resources.elemento_senalizacions.fields.longitud" source="longitud" validate={[required(), validarUnDecimal, minValue(0)]} fullWidth />
                                </Grid>
                                <Grid item xs={4}>
                                    <NumberInput label="resources.elemento_senalizacions.fields.ancho" source="ancho" validate={[required(), validarUnDecimal, minValue(0)]} fullWidth />
                                </Grid>
                                <Grid item xs={4}>
                                    <NumberInput label="resources.elemento_senalizacions.fields.area" source="area" validate={[required(), validarUnDecimal, minValue(0)]} fullWidth />
                                </Grid>
                            </Grid>
                        )
                        }
                    </FormDataConsumer>
                    <FormDataConsumer>
                        {({ formData, ...rest }) => (formData !== null && formData !== undefined && formData.tipoElementoSenal === '/tipo_elemento_senals/OTR' &&
                            <Grid container spacing={24}>
                                <Grid item xs={6}>
                                    <NumberInput source="cantidad" label="resources.elemento_senalizacions.fields.cantidad" validate={[required(), minValue(0), Entero]} fullWidth />
                                </Grid>
                                <Grid item xs={6}>
                                    <NumberInput label="resources.elemento_senalizacions.fields.espaciamientoPromedio" source="espaciamientoPromedio" validate={[minValue(0), validarUnDecimal]} fullWidth />
                                </Grid>

                            </Grid>
                        )
                        }
                    </FormDataConsumer>
                </FormTab>
                {/* ------ DETALLES  ------ */}
                <FormTab label="dynatest.resources.elemento_senalizacions.tabs.tabsTitle.detalles" redirect="list" >
                    <Grid container spacing={24}>
                        <Grid item xs={4}>
                            <FormDataConsumer>
                                {({ formData, ...rest }) => (formData !== null && formData !== undefined && formData.tipoElementoSenal === '/tipo_elemento_senals/SV' &&
                                    <SelectInput label="resources.elemento_senalizacions.fields.estado" source="estado" choices={[
                                        { id: translate('dynatest.resources.elemento_senalizacions.select.estado.existente'), name: 'dynatest.resources.elemento_senalizacions.select.estado.existente' },
                                        { id: translate('dynatest.resources.elemento_senalizacions.select.estado.reemplazo'), name: 'dynatest.resources.elemento_senalizacions.select.estado.reemplazo' },
                                        { id: translate('dynatest.resources.elemento_senalizacions.select.estado.nuevo'), name: 'dynatest.resources.elemento_senalizacions.select.estado.nuevo' },
                                    ]} fullWidth 
                                    validate={[required()]}
                                    />
                                )
                                } 
                            </FormDataConsumer>
                            <FormDataConsumer>
                                {({ formData, ...rest }) => (formData !== null && formData !== undefined && formData.tipoElementoSenal !== '/tipo_elemento_senals/SV' &&
                                
                                        <SelectInput label="resources.elemento_senalizacions.fields.estado" source="estado" choices={[
                                            { id: translate('dynatest.resources.elemento_senalizacions.select.estado.nuevo'), name: 'dynatest.resources.elemento_senalizacions.select.estado.nuevo' },
                                            { id: translate('dynatest.resources.elemento_senalizacions.select.estado.bueno'), name: 'dynatest.resources.elemento_senalizacions.select.estado.bueno' },
                                            { id: translate('dynatest.resources.elemento_senalizacions.select.estado.regular'), name: 'dynatest.resources.elemento_senalizacions.select.estado.regular' },
                                            { id: translate('dynatest.resources.elemento_senalizacions.select.estado.malo'), name: 'dynatest.resources.elemento_senalizacions.select.estado.malo' },
                                        ]} fullWidth validate={[required()]}
                                        />
                                )
                                }
                            </FormDataConsumer>
                        </Grid>
                        <Grid item xs={4}>
                            <FormDataConsumer>
                                {({ formData, ...rest }) => (formData !== null && formData !== undefined && formData.tipoElementoSenal === '/tipo_elemento_senals/SV' && formData.estado === translate('dynatest.resources.elemento_senalizacions.select.estado.reemplazo') &&

                                    <SelectInput source="motivoReemplazo" label="resources.elemento_senalizacions.fields.motivoReemplazo" choices={[
                                        { id: translate('dynatest.resources.elemento_senalizacions.select.motivoReemplazo.accidente'), name: 'dynatest.resources.elemento_senalizacions.select.motivoReemplazo.accidente' },
                                        { id: translate('dynatest.resources.elemento_senalizacions.select.motivoReemplazo.instalacion'), name: 'dynatest.resources.elemento_senalizacions.select.motivoReemplazo.instalacion' },
                                        { id: translate('dynatest.resources.elemento_senalizacions.select.motivoReemplazo.otro'), name: 'dynatest.resources.elemento_senalizacions.select.motivoReemplazo.otro' },
                                        { id: translate('dynatest.resources.elemento_senalizacions.select.motivoReemplazo.reemplazoProgramado'), name: 'dynatest.resources.elemento_senalizacions.select.motivoReemplazo.reemplazoProgramado' },
                                        { id: translate('dynatest.resources.elemento_senalizacions.select.motivoReemplazo.vandalismo'), name: 'dynatest.resources.elemento_senalizacions.select.motivoReemplazo.vandalismo' },
                                    ]} fullWidth validate={[required()]}
                                    />
                                )
                                }
                            </FormDataConsumer>

                            <FormDataConsumer>
                            {
                                ({ formData, ...rest }) => (formData !== null && formData !== undefined && formData.estado === translate('dynatest.resources.elemento_senalizacions.select.estado.nuevo') && formData.tipoElementoSenal !== '/tipo_elemento_senals/SV' &&
                                    
                                    <SelectInput label="resources.elemento_senalizacions.fields.condicion" source="condicion" choices={[
                                        { id: translate('dynatest.resources.elemento_senalizacions.select.condicion.instalacion'), name: 'dynatest.resources.elemento_senalizacions.select.condicion.instalacion' },
                                        { id: translate('dynatest.resources.elemento_senalizacions.select.condicion.mantenimiento'), name: 'dynatest.resources.elemento_senalizacions.select.condicion.mantenimiento' },
                                    ]} fullWidth validate={[required()]}
                                    />
                                )
                            }
                        </FormDataConsumer>
                        </Grid>
                        <Grid item xs={4}>
                            <FormDataConsumer>
                                {
                                    ({ formData, ...rest }) => (formData !== null && formData !== undefined && formData.estado === translate('dynatest.resources.elemento_senalizacions.select.estado.reemplazo') &&

                                        //console.log(formData)  
                                        // <ElementosCreados 
                                        //     tabla="elemento_senalizacions" 
                                        //     tipo="create" 
                                        //     elementos={formData.tipoElementoSenal} 
                                        // />

                                        <ReferenceInput
                                            label="resources.elemento_senalizacions.fields.elementoReemplazo_id"
                                            source="elementoReemplazo_id"
                                            reference="elemento_senalizacions"
                                            filter={{
                                                status: 1,
                                                tipoElementoSenal : formData.tipoElementoSenal,
                                                unidadFuncional: formData.unidadFuncional,
                                                tramo: formData.tramo,
                                                segmento : formData.segmento
                                            }}
                                            fullWidth
                                            validate={[required()]}
                                            formClassName={classes.grid_cont4}
                                        >
                                            <AutocompleteInput options={{ fullWidth: true }} optionText="fullCodigoConsecutivo" />
                                        </ReferenceInput>

                                    )
                                }
                            </FormDataConsumer>
                        </Grid>
                        {/* ------------------ VALIDACIÓN DE ESTADO -----------------  */}
                        
                        <Grid item xs={4}>
                        <SelectInput label="resources.elemento_senalizacions.fields.estados" source="estados" allowEmpty emptyValue="" choices={[
                            { id: translate('dynatest.resources.elemento_senalizacions.select.estados.Activo'), name: "dynatest.resources.elemento_senalizacions.select.estados.Activo" },
                            { id: translate('dynatest.resources.elemento_senalizacions.select.estados.Inactivo'), name: "dynatest.resources.elemento_senalizacions.select.estados.Inactivo" },
                        ]} validate={[required()]} fullWidth formClassName={classes.grid_cont4} />
                        </Grid>
                        <br/>
                        <Grid item xs={8}>

                        </Grid>
                        <Grid item xs={4}>
                            <FormDataConsumer>
                                {
                                    ({ formData, ...rest }) => (
                                        <DateInput label="resources.elemento_senalizacions.fields.fechaInstalacion" source="fechaInstalacion" fullWidth validate={[required(), isFechaInvalida]} />
                                    )
                                }
                            </FormDataConsumer>

                        </Grid>
                        <Grid item xs={4}>
                            <FormDataConsumer>
                                {
                                    ({ formData, ...rest }) => (
                                        <ReferenceInput 
                                        label="resources.elemento_senalizacions.fields.proveedor" 
                                        source="proveedor" 
                                        reference="proveedors" 
                                        filter={{ 
                                            proyecto: props.proyecto, sistema: 'SEN' 
                                        }}
                                        sort={{ field: 'razonSocial', order: 'ASC' }}
                                        filterToQuery={searchText => ({ razonSocial: searchText })} 
                                        validate={[required()]}
                                    >
                                        <AutocompleteInput  options={{fullWidth : true}} optionText="razonSocial"  />
                                    </ReferenceInput>
                                    )
                                }
                            </FormDataConsumer>
                        </Grid>
                        <Grid item xs={4}>
                            <FormDataConsumer>
                                {
                                    ({ formData, ...rest }) => (
                                        <NumberInput label="resources.elemento_senalizacions.fields.garantia" source="garantia" fullWidth validate={[required(), minValue(0), garantia]} />
                                    )
                                }
                            </FormDataConsumer>
                        </Grid>
                        <Grid item xs={6}>
                            <BooleanInput label="dynatest.resources.elemento_senalizacions.boolean.mantenimiento" source="mantenimiento" fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput source="observaciones" label="resources.elemento_senalizacions.fields.observaciones" fullWidth validate={[required(), minLength(5), maxLength(200, "Máximo 200 caracteres")]} />
                        </Grid>
                    </Grid>
                    <TextInput source="createdIn" style={{ display: 'none' }} defaultValue={"api"} />
                    <TextInput source="updatedIn" style={{ display: 'none' }} defaultValue={"api"} />
                    <TextInput source="aprobacion" style={{ display: 'none' }} defaultValue={"Aprobado"} />
                </FormTab>
                <FormTab label="dynatest.resources.elemento_senalizacions.tabs.tabsTitle.fotografias">
                    <ArrayInput label="" source="images"  fullWidth
                        defaultValue={defaultTipoImagen}
                    >
                        <SimpleFormIterator disableAdd disableRemove >
                            <ImageInput multiple={true} source="images" label=" " maxSize={1000000000} accept="image/jpeg, image/png">
                                <ImageField source="src" title="title" />
                            </ImageInput>
                            <ReferenceInput disabled label="resources.elemento_senalizacions.fields.tipoImagen" source="tipoImagen" reference="tipo_images" fullWidth validate={[required()]} sort={{ field: 'nombre', order: 'ASC' }}  formClassName={classes.grid_cont6}>
                                <SelectInput  optionText="nombre"  />
                            </ReferenceInput>                         
                        </SimpleFormIterator>
                    </ArrayInput>                
                </FormTab>                
            </TabbedForm>
        </PimsCreate>
    )
}));