import React, {useState} from 'react';
import { required,TextInput,SelectInput ,NumberInput, FormDataConsumer} from 'react-admin';
import { Grid } from '@material-ui/core';
import { translate } from 'react-admin';
import { minValue } from 'ra-core';

export const ComponenteDeterioroPers = translate((props) =>
{
    const { translate } = props;
    const [elemento, setElemento] = useState();
    const ValorElemento = (value) =>{
        let lenghtObject = Object.keys(value);
        let valor = '';
        for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
        setElemento(valor)
        
    }

    const ElementoSeleccionado = (value) => {
        setElemento(value.source)
        return (<></>);
    }

    return(
        <Grid container spacing={24}>
            <FormDataConsumer style={{ display: 'none' }}>
                {
                  ({ formData, scopedFormData, getSource, ...rest }) => {
                    let tipoReparacion =""  
                    if(formData){
                          tipoReparacion = formData.tipoReparacion ?? null;
                      }
                      return (
                        <ElementoSeleccionado source={tipoReparacion} />
                      )
                  }
                }
            </FormDataConsumer>
            <Grid item xs={12}>
                <SelectInput label="resources.reparacion_localizadas.fields.tipoReparacion" source='tipoReparacion' choices={[
                    { id:'PFLEX', name:'dynatest.resources.reparacion_localizadas.select.tipoReparacion.PFLEX'},
                    { id:'PRIGD', name:'dynatest.resources.reparacion_localizadas.select.tipoReparacion.PRIGD'},
                    { id:'PART', name:'dynatest.resources.reparacion_localizadas.select.tipoReparacion.PART'},
                    { id:'POTR', name:'dynatest.resources.reparacion_localizadas.select.tipoReparacion.POTR'},
                ]} validate={[required()]} onChange={ValorElemento} fullWidth/>
                
            </Grid>            
            <Grid item xs={4}>   
                <TextInput label="resources.reparacion_localizadas.fields.nombre" source="nombre" validate={[required()]} fullWidth/>             
            </Grid>
            <Grid item xs={4}>  
                {elemento === 'PFLEX' &&
                    <SelectInput label="resources.reparacion_localizadas.fields.unidad" source="unidad" choices={[
                        {id : '$/m', name:'$/m'},
                        {id : '$/m²', name:'$/m²'}
                    ]} validate={[required()]} fullWidth/>
                }
                {elemento !== 'PFLEX' && elemento !== 'PART' && elemento !== 'POTR' &&
                    <SelectInput label="resources.reparacion_localizadas.fields.unidad" source="unidad" choices={[ 
                        {id : '$/m', name:'$/m'},
                        {id : '$/m²', name:'$/m²'},
                        {id : translate('resources.reparacion_localizadas.fields.unidadPlaca'), name:'resources.reparacion_localizadas.fields.unidadPlaca'}
                    ]} validate={[required()]} fullWidth/>
                }
                {elemento === 'PART' &&
                    <SelectInput label="resources.reparacion_localizadas.fields.unidad" source="unidad" choices={[
                        {id : '$/m', name:'$/m'},
                        {id : '$/m²', name:'$/m²'}
                    ]} validate={[required()]} fullWidth/>
                }
                {elemento === 'POTR' &&
                    <SelectInput label="resources.reparacion_localizadas.fields.unidad" source="unidad" choices={[
                        {id : '$/m', name:'$/m'},
                        {id : '$/m²', name:'$/m²'}
                    ]} validate={[required()]} fullWidth/>
                }
            </Grid>
            <Grid item xs={4}>   
                <NumberInput label="resources.reparacion_localizadas.fields.costo" source="costo" validate={[required(), minValue(0)]} fullWidth/>           
            </Grid>   
            <Grid item xs={12}>   
                <TextInput label="resources.reparacion_localizadas.fields.observaciones" source="observaciones"  fullWidth/>           
            </Grid>                                                                                         
        </Grid>             
    )
})
