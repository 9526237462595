import * as React from 'react';
import Paper from '@material-ui/core/Paper';
import DataTable from 'react-data-table-component';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import MenuIcon from '@material-ui/icons/Menu';
import { func } from 'prop-types';

//datos de Referencia
const data = [
    {
        "@id": "/costo_elementos/509",
        "@type": "CostoElementos",
        "codigo": "UF1A::RDS::TR1::SG01::LUM::001",
        "tipoElemento": {
            "@id": "/tipo_elementos/LUM",
            "@type": "TipoElemento",
            "nombre": "Luminaria"
        },
        "abInicio": null,
        "abFin": null,
        "costoMantenimiento": 1500,
        "tipoMantenimiento": "ru",
        "fechaMantenimiento": "2021-06-09T00:00:00+02:00",
        "proveedor": {
            "@id": "/proveedors/38",
            "@type": "Proveedor",
            "razonSocial": "Siemens",
            "createdAt": "2020-07-29T22:04:04+02:00",
            "updatedAt": "2020-07-29T22:04:04+02:00",
            "createdBy": "DSilva@dynatest.com",
            "updatedBy": "DSilva@dynatest.com"
        },
        "calificacion": 4,
        "observaciones": "Ninguno",
        "tipoElementoSenal": null,
        "tipoSenal": null,
        "razonSocial": "Siemens",
        "fechaMantenimiento2": "2021-06-09T00:00:00+02:00"
    },
    {
        "@id": "/costo_elementos/510",
        "@type": "CostoElementos",
        "codigo": "UF1A::RDS::TR1::SG01::LUM::002",
        "tipoElemento": {
            "@id": "/tipo_elementos/LUM",
            "@type": "TipoElemento",
            "nombre": "Luminaria"
        },
        "abInicio": null,
        "abFin": null,
        "costoMantenimiento": 1500,
        "tipoMantenimiento": "ru",
        "fechaMantenimiento": "2021-06-09T00:00:00+02:00",
        "proveedor": {
            "@id": "/proveedors/38",
            "@type": "Proveedor",
            "razonSocial": "Siemens",
            "createdAt": "2020-07-29T22:04:04+02:00",
            "updatedAt": "2020-07-29T22:04:04+02:00",
            "createdBy": "DSilva@dynatest.com",
            "updatedBy": "DSilva@dynatest.com"
        },
        "calificacion": 4,
        "observaciones": "Ninguno",
        "tipoElementoSenal": null,
        "tipoSenal": null,
        "razonSocial": "Siemens",
        "fechaMantenimiento2": "2021-06-09T00:00:00+02:00"
    },
    {
        "@id": "/costo_elementos/511",
        "@type": "CostoElementos",
        "codigo": "UF1A::RDS::TR1::SG01::LUM::003",
        "tipoElemento": {
            "@id": "/tipo_elementos/LUM",
            "@type": "TipoElemento",
            "nombre": "Luminaria"
        },
        "abInicio": null,
        "abFin": null,
        "costoMantenimiento": 1500,
        "tipoMantenimiento": "ru",
        "fechaMantenimiento": "2021-06-09T00:00:00+02:00",
        "proveedor": {
            "@id": "/proveedors/38",
            "@type": "Proveedor",
            "razonSocial": "Siemens",
            "createdAt": "2020-07-29T22:04:04+02:00",
            "updatedAt": "2020-07-29T22:04:04+02:00",
            "createdBy": "DSilva@dynatest.com",
            "updatedBy": "DSilva@dynatest.com"
        },
        "calificacion": 4,
        "observaciones": "Ninguno",
        "tipoElementoSenal": null,
        "tipoSenal": null,
        "razonSocial": "Siemens",
        "fechaMantenimiento2": "2021-06-09T00:00:00+02:00"
    }
];
//columnas de Referencia
const columns = [
    {
        name: 'Código',
        selector: 'codigo',
    },
    {
        name: 'Tipo de Mantenimiento',
        selector: 'tipoMantenimiento',
    },
    {
        name: 'Fecha de Mantenimiento',
        selector: 'fechaMantenimiento',
    },
    {
        name: 'Costo de Mantenimiento',
        selector: 'costoMantenimiento',
    },
    {
        name: 'Proveedor',
        selector: 'fechaMantenimiento',
    },
    {
        name: 'Calificación',
        selector: 'calificacion',
    },
    {
        name: 'Observaciones',
        selector: 'observaciones',
    },
];

const tipoMantenimientos = {
    ru: "Rutinario",
    pe: "Periódico",
    re: "Rehabilitación",
    ca: "Cambio"

}

const formatter = (format, value) => {
    let valueToReturn = value
    // console.log(format, value)
    switch (format) {
        case "date":
            if (typeof value == "object") {
                let day = value.getDate()
                let month = value.getMonth() + 1
                let year = value.getFullYear()
                valueToReturn = `${day}/${month}/${year}`
            } else if (typeof value == "string") {
                let splittedValue = value.split("/")
                let valueToDate = ""
                if (splittedValue.length > 1) {
                    valueToDate = splittedValue[0].length == 4 ? `${splittedValue[0]}-${splittedValue[1]}-${splittedValue[2]}` : `${splittedValue[2]}-${splittedValue[1]}-${splittedValue[0]}`
                }
                let valueDate = new Date(valueToDate != "" ? valueToDate : value)
                let day = valueDate.getDate()
                let month = valueDate.getMonth() + 1
                let year = valueDate.getFullYear()
                valueToReturn = `${day}/${month}/${year}`
            }
            break;
        case "currency":
            var numericValue = typeof value == "string" ? value.replace(/\./g, "").replace(",", ".").replace("$", "") : value
            numericValue = parseFloat(numericValue)
            valueToReturn = new Intl.NumberFormat("es-CO", { style: "currency", currency: "COP", minimumFractionDigits: 0 }).format(numericValue)
            break;
        case "mantenimiento":
            valueToReturn = tipoMantenimientos.hasOwnProperty(value) ? tipoMantenimientos[value] : value
            break;
        case "abs":
            if (value != null && value !== "") {
                valueToReturn = "K" + parseFloat(value).toFixed(3).toString().replace(".", "+")
            } else {
                valueToReturn = "-"
            }
            break;
    }
    return valueToReturn
}

const getFunctionalIcon = (type, onClickEvent, props) => {
    switch (type) {
        case "edit":
            return <IconButton onClick={() => onClickEvent(props)}><EditIcon onClick={() => onClickEvent(props)} /></IconButton>
        default: return <IconButton onClick={() => onClickEvent(props)}><MenuIcon onClick={() => onClickEvent(props)} /></IconButton>
    }
}


const getColumns = (columnNames, formatColumn, actions) => {
    let columns = []

    columnNames.map(columnName => {
        let shouldFormat = formatColumn.find(column => column.id == columnName.id)
        columns.push({
            name: columnName.name,
            selector: columnName.id,
            cell: (row, index, column, id) => {
                // console.log(row[column.selector])
                return (<div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: shouldFormat ? getAlignmentCssProp(shouldFormat.formatTo) : "center",
                        alignItems: "center"
                    }}
                >
                    {shouldFormat ? formatter(shouldFormat.formatTo, row[column.selector]) : row[column.selector]}
                </div>)
            }
        })
    })
    if (Array.isArray(actions)) {
        actions.map(action => {
            columns.push({
                name: action.name,
                selector: action.name,
                cell: (row, index, column, id) => getFunctionalIcon(action.element, action.onClick, { row: row, index: index, column: column, id: id })
            })
        })
    }

    return columns
}

const getAlignmentCssProp = (type) => {
    switch (type) {
        case "number":
        case "currency": return "flex-end"
        default: return "center"
    }
}

/**
 label="Elementos"
    identityColumn="@id"
    columnNames={[
        { id: 'name', name: 'Código', },
        { id: 'tipoElemento', name: 'Elemento', },
        { id: 'abInicio', name: 'Abscisa Inicial' },
        { id: 'abFin', name: 'Abscisa Final', },
    ]}
    formatColumn={[
        { id: 'abInicio', formatTo: 'abs' },
        { id: 'abFin', formatTo: 'abs' },
    ]}
    arrayData={this.state.datosAutoComplete}
    actions={[
        {
            name: "Editar",
            element: "edit",
            onClick: (props) => {
                console.log(props)
                // setRowToEdit(props.index);
                // if (modalProps.hasOwnProperty("handleOpen")) { modalProps.handleOpen() }
            }
        }
    ]}
    selectableRows={true}
    onSelectedRowsChange={props => console.log(props)}
 * @returns 
 */
export default function ElementsTable(props) {
    // console.log(props.arrayData)

    let data = Array.isArray(props.arrayData) ? props.arrayData : []
    let columns = Array.isArray(props.arrayData) ? getColumns(props.columnNames, props.formatColumn, props.actions) : []

    return (
        <Paper elevation={3}>
            <DataTable
                title={props.label}
                columns={columns}
                data={data}
                keyField={props.identityColumn}
                pagination={true}
                selectableRows={props.selectableRows ? true : false}
                onSelectedRowsChange={typeof props.onSelectedRowsChange == "function" ? props.onSelectedRowsChange : undefined}
                noDataComponent="Sin Elementos para Mostrar"
                contextMessage={{ singular: 'item seleccionado', plural: 'items seleccionados', message: '' }}
                paginationComponentOptions={{ rowsPerPageText: 'Filas por Pag:', rangeSeparatorText: 'de', noRowsPerPage: false, selectAllRowsItem: false, selectAllRowsItemText: 'Todo' }}
            />
        </Paper>
    );
}