import React, { useState, useEffect } from 'react';
import { BooleanField, Tab, TabbedShowLayout, NumberField, TextField, DateField, ReferenceField } from 'react-admin';
import { Show } from './../Show';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core';
import TablaCalificacion from './TablaCalificacion';


const styles = {
    first_field: { color: 'blue !important' }
};

export const CalificacionShow = withStyles(styles)(({ classes, record, ...props }) => {

    const [IdCalificacion, setIdCalificacion] = useState(0);

    useEffect(() => {
        var url = document.location.hash;
        var auxId = url.split("calificacions%2F");
        var id = auxId[1].split("/");
        setIdCalificacion(id[0])
    });

    return (
        <Show  {...props} title="Información Calificación">
            <TabbedShowLayout>
                <Tab label="Detalles">

                    <b>Detalles de inspección</b>
                    <span></span>
                    <span></span>
                    <Divider />
                    <Divider />
                    <Divider />

                    <ReferenceField label="Peaje" source="puente" reference="puentes" linkType={false}>
                        <TextField source="smartCode" />
                    </ReferenceField>

                    <DateField label="Fecha de inicio de inspección" source="fecha1" fullWidth />


                    <NumberField label="Consecutivo de inspección" source="consecutivo" fullWidth />


                    <TextField label="Inspector" source="inspector" fullWidth />


                    <DateField label="Fecha de reporte de inspección" source="fecha2" fullWidth />


                    <TextField label="Nombre de reporte de inspección" source="nombre_inspector" fullWidth />
                    <span></span>

                </Tab>
                <Tab label="Componentes">


                    <b>Calificación de Componentes</b>
                    <span></span>
                    <span></span>
                    <Divider />
                    <Divider />
                    <Divider />


                    <TablaCalificacion tabla="calificacions" id={IdCalificacion} />

                </Tab>
                <Tab label="Auditoria">
                    <DateField source="createdAt" label="Fecha creación" showTime />
                    <TextField source="createdBy" label="Creado por" />
                    <span></span>
                    <DateField source="updatedAt" label="Fecha actualización" showTime />
                    <TextField source="updatedBy" label="Actualizado por" />
                    <span></span>
                </Tab>
            </TabbedShowLayout>
        </Show>
    )
});