import React, { Fragment, useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import 
{
  DateInput, BooleanInput, required, TabbedForm, FormTab, AutocompleteInput, SimpleFormIterator,
  ArrayInput, NumberInput, SelectInput, ReferenceInput, TextInput, ShowButton, FormDataConsumer, Toolbar, SaveButton, SimpleForm, Query
} from 'react-admin';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import ReplyIcon from '@material-ui/icons/Reply';

import Dialog from '@material-ui/core/Dialog';
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import CloseIcon from "@material-ui/icons/Close";
//import ListColores from '../colores/color/ListColores';

import { PimsCreate } from './../Create';
import { styles } from './../../EstilosGrid';
import {
  abValidationInicio, validarAB, abValidationFin, validarDosDecimales, validarUnDecimal, isFechaInvalida
} from '../../../src/resources/validacionesInputs';
import { latMininima, lngMininima, Entero } from '../validacionesInputs';
import { withStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { minValue, maxValue, minLength, maxLength } from 'ra-core';
import { PRInput } from '../../inputs/PRInput';
import { DInput } from '../../inputs/DInput';
import { DEInput } from '../../inputs/DEInput';
import CoordenadasProyecto from '../CoordenadasInventario';
import AbsSegmento from '../AbsSegmento';
//import Reparaciones from './campos/reparaciones';
import config from '../../config/config';
import { ImagenesInventario } from '../images/ImagenesInventario';
import { REDUX_FORM_NAME } from 'react-admin';
import { change } from 'redux-form';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { translate } from 'react-admin';
import '../../App.css';

let urlAppjs = config.UrlServer;

export const IndicadorComplementarioCreate = translate(withStyles(styles)(({ classes, ...props }) => {
  const { translate } = props;

  const styles = {
    ocultar: { display: "none" },

  };
  let defecto = 0;
  const [segmentos, setSegmento] = useState('');
  

  /* NEW ANDERSON */
  const [tipo_elementos, setTipoElementos] = useState('');
  const [tipo_acceso, setTipoAcceso] = useState('');

  const ValidarTipoElementos = (value) => {
    let lenghtObject = Object.keys(value);
    let valor = '';
    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setTipoElementos(value);
  }
const [estado, setEstado] = useState('');
const ValidarEstado=(value)=>{
  setEstado(value);
}
  const ValidarTipoAcceso = (value) => {
    let lenghtObject = Object.keys(value);
    let valor = '';
    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setTipoAcceso(value);
  }

  /* END ANDERSON */

  /* NEW EDER */
  const [sistema, setSistema] = useState(null);
  
  const ValidarSistema = (value) => {
    let lenghtObject = Object.keys(value);
    let valor = '';
    for (let index = 0; index < (lenghtObject.length - 1); index++) { valor += value[index]; }
    setSistema(value);
  }

  const validateDecimal = (valor) => {
    if (valor) {
      let n = valor.replace(",", ".");
      if (n <= 5) {
        if (n <= 0) { return translate('resources.validaciones.mayor_a_0'); }
      } else {
        return translate('resources.validaciones.menor_a_5');
      }
    }
  }

  const [peso, setPeso] = useState(0);
  const updatePeso = (e) => {
    //console.log(e);
  }
  const validarUmbral = (value) => {
    //debugger
    var errorMsg = [];
    var returnMsg = "";
    let tam = document.getElementsByClassName("peso").length;
    let umbral = document.getElementsByName("umbralFallo")[0].value;
    let suma = 0.0;
    for (let i = 0; i < tam; i++) {
        let elements = document.getElementsByName("preguntaIndicadorComplementario["+i+"].peso");
        let dato = document.getElementsByName("preguntaIndicadorComplementario["+i+"].peso")[0].defaultValue;
        suma += parseFloat(dato);
    }
    //debugger
    if (umbral > suma) {
        errorMsg.push(translate('resources.validaciones.umbralSumatoria'));
      }
    if (errorMsg.length > 0) {
      for (var i = 0; i < errorMsg.length; i++) {
          returnMsg = returnMsg + (i + 1) + ")." + errorMsg[i];
      }
      // errorMsg.map( msg => {returnMsg = returnMsg + msg})
      return returnMsg;
    }
  }
  /* END EDER */
  
  const validarUmbralnuevo = (value) => {
    var array = []; 
    var errorMsg = [];
    var returnMsg = "";
    if (typeof value !== 'undefined') {
      // console.log("Este es el valuede umbral nuevo",value)
      value.map((item, index) => {
        let pesopregunta = 0;
        if (item.hasOwnProperty("peso")) {
          pesopregunta = item.peso;
        }
        if(item.hasOwnProperty("tipoCampo")){
          if(item.tipoCampo == 'Selección Múltiple'){
            if (typeof item.respuestaIndicadorComplementario !== 'undefined') {
              let pesoTotal = 0;
              let pesorespuesta = 0;
              let numero = index + 1;
              for (var j = 0; j < item.respuestaIndicadorComplementario.length; j++) {
                pesorespuesta = item.respuestaIndicadorComplementario[j].peso;
                pesoTotal=pesoTotal+pesorespuesta;

                if (pesorespuesta > pesopregunta) {
                  errorMsg.push(translate('resources.validaciones.pesoRespuesta') + numero + "." );
                }
              }     
            }
          }
        }
      })
    }
    if (errorMsg.length > 0) {
      for (var i = 0; i < errorMsg.length; i++) {
          returnMsg = returnMsg + (i + 1) + ")." + errorMsg[i];
      }
      // errorMsg.map( msg => {returnMsg = returnMsg + msg})
      return returnMsg;
    }
  }

  const [elemento, setElemento] = useState('');
  const ValidarElemento = (value) => {
      setElemento(value)
  }

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = value => {
    setOpen(false);
  };

  const [shouldShowOnReduxChange, setShouldShowOnReduxChange] = useState(true);

  return (
    <PimsCreate {...props} submitOnEnter={[validarUmbral]}>
      <TabbedForm redirect="list">
        {/* ......:::.... IDENTIFICACION ......:::....*/}
        <FormTab label="dynatest.resources.indicador_complementarios.tabs.identificacion" >
          <Grid container spacing={24}>
            <Grid item xs={4}>
              <TextInput label="resources.indicador_complementarios.fields.nombre"  source="nombre" fullWidth validate={[required(), minLength(1), maxLength(100)]} formClassName={classes.grid_cont3} />
            </Grid>
            <Grid item xs={4}>
              <SelectInput name="estado" source="estado" label="resources.indicador_complementarios.fields.estado" validate={[required(), ValidarEstado]} fullWidth choices={[
                { id: translate('dynatest.resources.indicador_complementarios.select.estado.Activo'), name: 'dynatest.resources.indicador_complementarios.select.estado.Activo' },
                { id: translate('dynatest.resources.indicador_complementarios.select.estado.Inactivo'), name: 'dynatest.resources.indicador_complementarios.select.estado.Inactivo' },
              ]} />
            </Grid>
            <Grid item xs={4}>
              {/* <DInput label="Umbral de fallo" source="umbralFallo" id="umbralFallo" className="align-number" fullWidth validate={[required(), minValue(1), maxValue(5), validateDecimal]} formClassName={classes.grid_cont3} /> */}
              
              <NumberInput label="resources.indicador_complementarios.fields.umbralFallo"  className="align-number" source="umbralFallo" id="umbralFallo" fullWidth validate={[required(), validarDosDecimales,validarUmbral, minValue(0)]} formClassName={classes.grid_cont3} />
            </Grid>
            <Grid item xs={4}>
              <ReferenceInput label="resources.indicador_complementarios.fields.proyecto" name="proyecto" source="proyecto" reference="proyectos" fullWidth validate={[required()]} defaultValue={window.localStorage.getItem('id_project2')}>
                <SelectInput optionText="nombre" disabled/>
              </ReferenceInput>
            </Grid>
            <FormDataConsumer>
            {
              ({ formData, dispatch, ...rest }) => (
                <Fragment>
                  <Grid item xs={4}>
                    <ReferenceInput 
                      label="resources.indicador_complementarios.fields.sistema" 
                      name="sistema" 
                      source="sistema"
                      id="sistema"
                      reference="sistemas" 
                      filter={{ id: ["ALR", "CLZ", "DRE", "OTR", "PTS", "RDS", "SEG", "SEN"]}} 
                      onChange={value => {
                        dispatch(change(REDUX_FORM_NAME, 'tipoElemento', null))
                        setShouldShowOnReduxChange(false)
                      }}
                      fullWidth validate={[required(), ValidarTipoElementos, ValidarSistema]}
                    >
                      <SelectInput optionText="nombre" /> 
                    </ReferenceInput>
                  </Grid>
                  {sistema != null && sistema != '/sistemas/SEN' &&
                   <Fragment>
                    {shouldShowOnReduxChange ?
                      <Grid item xs={4}>
                        {(sistema == '/sistemas/OTR') ?
                          <ReferenceInput 
                            label="resources.indicador_complementarios.fields.tipoElemento" 
                            allowEmpty emptyValue="" 
                            name="tipoElemento"
                            source="tipoElemento" 
                            reference="tipo_elementos"
                            fullWidth filter={{puntos: 1, sistema: "OTR"}} 
                            validate={[required()]} 
                            sort={{ field: 'nombre', order: 'asc' }}
                          >
                            <SelectInput optionText="nombre" />
                          </ReferenceInput>
                        :
                        <ReferenceInput 
                          label="resources.indicador_complementarios.fields.tipoElemento" 
                          allowEmpty emptyValue="" 
                          name="tipoElemento"
                          source="tipoElemento" 
                          reference="tipo_elementos" 
                          fullWidth filter={{ sistema: tipo_elementos }} 
                          validate={[required()]} 
                          sort={{ field: 'nombre', order: 'asc' }}>
                          <SelectInput optionText="nombre" />
                        </ReferenceInput>
                        }
                      </Grid>
                      :
                      setShouldShowOnReduxChange(true)
                    }
                    </Fragment>
                  }
                </Fragment>
              )
            }
            </FormDataConsumer>
            {sistema != null && sistema == '/sistemas/SEN' &&
              <Grid item xs={4}>
                <ReferenceInput label="resources.indicador_complementarios.fields.tipoElementoSenal" source="tipoElementoSenal" reference="tipo_elemento_senals" fullWidth validate={[required(), ValidarElemento]} sort={{ field: 'nombre', order: 'asc' }} alwaysOn>
                  <SelectInput optionText="nombre" />
                </ReferenceInput>
              </Grid>
            }
            {(sistema != null) && (sistema == '/sistemas/SEN') && (elemento != null) && (
              <Grid item xs={4}>
                <ReferenceInput label="resources.indicador_complementarios.fields.tipoSenal" source="tipoSenal" reference="tipo_senals" fullWidth filter={{ tipoElementoSenal: elemento }} validate={[required()]} sort={{ field: 'nombre', order: 'asc' }}>
                  <SelectInput optionText="nombre" />
                </ReferenceInput>
              </Grid>
            )}

          </Grid>
        </FormTab>
        {/* ......:::.... PREGUNTAS ......:::....*/}
        
        <FormTab label="dynatest.resources.indicador_complementarios.tabs.preguntas" >
          <Typography style={{ marginBottom: "-25px", marginTop: "5px" }}><b>{translate("dynatest.resources.indicador_complementarios.subTitle.preguntas")}</b></Typography>
          <ArrayInput fullWidth label=" " source="preguntaIndicadorComplementario" validate={[validarUmbralnuevo]} defaultValue={[
            {
              pregunta: "", peso: "", tipoCampo: "", estado: "",
            }
          ]}>
            <SimpleFormIterator>
              <TextInput label="resources.indicador_complementarios.fields.pregunta" source="pregunta" id="pregunta" fullWidth validate={[required(), minLength(1), maxLength(60)]} formClassName={classes.grid_cont3} />
              {/* <DInput label="Peso" source="peso" id="peso" className="align-number" fullWidth validate={[required(), minValue(1), maxValue(5), validateDecimal]} formClassName={classes.grid_cont3} /> */}
              <NumberInput label="resources.indicador_complementarios.fields.peso" source="peso" id="peso" className="align-number peso" fullWidth validate={[required(), validarDosDecimales, minValue(0)]} formClassName={classes.grid_cont3} />
              <SelectInput label="resources.indicador_complementarios.fields.tipoCampo" source="tipoCampo" id="tipoCampo" allowEmpty emptyValue="" validate={[required()]} choices={[
                { id: translate('dynatest.resources.indicador_complementarios.select.tipoCampo.seleccionMultiple'), name: "dynatest.resources.indicador_complementarios.select.tipoCampo.seleccionMultiple" },
                { id: translate('dynatest.resources.indicador_complementarios.select.tipoCampo.numerico'), name: "dynatest.resources.indicador_complementarios.select.tipoCampo.numerico" },
              ]} fullWidth formClassName={classes.grid_cont3} />
              <SelectInput label="resources.indicador_complementarios.fields.estado" source="estado" id="estado" allowEmpty emptyValue="" validate={[required()]} choices={[
                { id: translate('dynatest.resources.indicador_complementarios.select.estado.Activo'), name: 'dynatest.resources.indicador_complementarios.select.estado.Activo' },
                { id: translate('dynatest.resources.indicador_complementarios.select.estado.Inactivo'), name: 'dynatest.resources.indicador_complementarios.select.estado.Inactivo' },
              ]} fullWidth formClassName={classes.grid_cont3} />
                  
                <FormDataConsumer style={{ width: '50%' }}>
                {
                  ({ formData, scopedFormData, getSource, ...rest }) => (scopedFormData != null &&
                   scopedFormData.tipoCampo != null && scopedFormData.tipoCampo === translate("dynatest.resources.indicador_complementarios.select.tipoCampo.numerico") &&
                   <NumberInput defaultValue="0" label="resources.indicador_complementarios.fields.decimales" source={getSource("decimales")} validate={[required(), Entero, minValue(0)]}  style={{ width: '370px' }} />
                  )
                }
              </FormDataConsumer>
              <FormDataConsumer style={{ width: '33%', marginTop: '1rem' }}>
                {
                  ({ formData, scopedFormData, getSource, ...rest }) => (scopedFormData != null &&
                   scopedFormData.tipoCampo != null && scopedFormData.tipoCampo === translate("dynatest.resources.indicador_complementarios.select.tipoCampo.numerico") &&
                    <Typography style={{marginTop: '1rem'}}><b>{translate("dynatest.resources.indicador_complementarios.subTitle.consideracionesPeso")}</b></Typography>
                  )
                }
              </FormDataConsumer>
              <FormDataConsumer >
                {
                  ({ formData, scopedFormData, getSource, ...rest }) => (scopedFormData != null &&
                    scopedFormData.tipoCampo != null && scopedFormData.tipoCampo === translate("dynatest.resources.indicador_complementarios.select.tipoCampo.numerico") &&
                    <Fragment>
                      <NumberInput defaultValue="0" label="resources.indicador_complementarios.fields.rangoInicial" source={getSource("rangoInicial")} validate={[required(), Entero, minValue(0)]} formClassName={classes.grid_cont3} style={{marginRight: '0.5rem'}} />
                      <NumberInput defaultValue="0" label="resources.indicador_complementarios.fields.rangoFinal" source={getSource("rangoFinal")} validate={[required(), Entero, minValue(0)]} formClassName={classes.grid_cont3s} />
                    </Fragment>
                  )
                }
              </FormDataConsumer>
              <FormDataConsumer style={{ width: '33%' }}>
                {
                  ({ formData, scopedFormData, getSource, ...rest }) => (scopedFormData != null &&
                   scopedFormData.tipoCampo != null && scopedFormData.tipoCampo === translate("dynatest.resources.indicador_complementarios.select.tipoCampo.seleccionMultiple") &&
                    <Typography><b>{translate("dynatest.resources.indicador_complementarios.subTitle.respuestas")}</b></Typography>
                  )
                }
              </FormDataConsumer>
              <FormDataConsumer style={{ width: '33%' }}>
                {
                  ({ formData, scopedFormData, getSource, ...rest }) => (scopedFormData != null &&
                    scopedFormData.tipoCampo != null && scopedFormData.tipoCampo === translate("dynatest.resources.indicador_complementarios.select.tipoCampo.seleccionMultiple") &&
                    
                    <ArrayInput fullWidth label=" " source={getSource("respuestaIndicadorComplementario")} defaultValue={[
                      {
                        respuesta: "0", peso: "0",
                      }
                    ]}>
                      <SimpleFormIterator fullWidth>
                        <TextInput label="resources.indicador_complementarios.fields.respuesta" source="respuesta" id="respuesta" fullWidth validate={[required(), minLength(1), maxLength(60)]} formClassName={classes.grid_cont6} />
                        <NumberInput label="resources.indicador_complementarios.fields.peso" source="peso" className="align-number" fullWidth validate={[required(), validarDosDecimales, minValue(0)]} formClassName={classes.grid_cont6} />
                      </SimpleFormIterator>
                    </ArrayInput>
                  )
                }
              </FormDataConsumer>
              <hr fullWidth style={{ marginBottom: '-2px', border:'2' }}></hr>
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </PimsCreate>
  )
}));
